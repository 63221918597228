export const counties_ia = {
  "type":"FeatureCollection","name":"ia_counties","features":[
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "013",
      "COUNTYNS": "00465196",
      "AFFGEOID": "0500000US19013",
      "GEOID": "19013",
      "NAME": "Black Hawk",
      "LSAD": "06",
      "ALAND": 1465369216,
      "AWATER": 17862718,
      "County_state": "Black Hawk,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.554492,
              42.642314
            ],
            [
              -92.5543,
              42.555854
            ],
            [
              -92.55433,
              42.469601
            ],
            [
              -92.534015,
              42.469592
            ],
            [
              -92.53351,
              42.297001
            ],
            [
              -92.416949,
              42.297284
            ],
            [
              -92.298792,
              42.297527
            ],
            [
              -92.064766,
              42.297259
            ],
            [
              -92.064744,
              42.299116
            ],
            [
              -92.064187,
              42.468221
            ],
            [
              -92.081446,
              42.468192
            ],
            [
              -92.081661,
              42.642056
            ],
            [
              -92.554492,
              42.642314
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "081",
      "COUNTYNS": "00465229",
      "AFFGEOID": "0500000US19081",
      "GEOID": "19081",
      "NAME": "Hancock",
      "LSAD": "06",
      "ALAND": 1478895055,
      "AWATER": 5265568,
      "County_state": "Hancock,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.970415,
              43.255358
            ],
            [
              -93.971238,
              42.907762
            ],
            [
              -93.498617,
              42.908512
            ],
            [
              -93.497635,
              43.255468
            ],
            [
              -93.970415,
              43.255358
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "179",
      "COUNTYNS": "00465278",
      "AFFGEOID": "0500000US19179",
      "GEOID": "19179",
      "NAME": "Wapello",
      "LSAD": "06",
      "ALAND": 1118460312,
      "AWATER": 10829143,
      "County_state": "Wapello,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.640361,
              41.161298
            ],
            [
              -92.639091,
              40.898886
            ],
            [
              -92.52476,
              40.899115
            ],
            [
              -92.179072,
              40.89972
            ],
            [
              -92.179974,
              41.162662
            ],
            [
              -92.295933,
              41.162303
            ],
            [
              -92.410233,
              41.161942
            ],
            [
              -92.640361,
              41.161298
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "031",
      "COUNTYNS": "00465205",
      "AFFGEOID": "0500000US19031",
      "GEOID": "19031",
      "NAME": "Cedar",
      "LSAD": "06",
      "ALAND": 1500730782,
      "AWATER": 6385832,
      "County_state": "Cedar,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.366079,
              41.860068
            ],
            [
              -91.366448,
              41.598373
            ],
            [
              -91.038231,
              41.597405
            ],
            [
              -91.00529,
              41.597384
            ],
            [
              -90.899853,
              41.597133
            ],
            [
              -90.898373,
              41.771392
            ],
            [
              -90.898484,
              41.946245
            ],
            [
              -91.365789,
              41.94741
            ],
            [
              -91.366079,
              41.860068
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "089",
      "COUNTYNS": "00465233",
      "AFFGEOID": "0500000US19089",
      "GEOID": "19089",
      "NAME": "Howard",
      "LSAD": "06",
      "ALAND": 1225706339,
      "AWATER": 927963,
      "County_state": "Howard,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.55312804163,
              43.500300016729
            ],
            [
              -92.554381,
              43.212813
            ],
            [
              -92.080976,
              43.212947
            ],
            [
              -92.080955,
              43.255619
            ],
            [
              -92.0798019678545,
              43.5006468106538
            ],
            [
              -92.079954,
              43.500647
            ],
            [
              -92.08997,
              43.500684
            ],
            [
              -92.103886,
              43.500735
            ],
            [
              -92.178863,
              43.500713
            ],
            [
              -92.198788,
              43.500527
            ],
            [
              -92.277425,
              43.500466
            ],
            [
              -92.279084,
              43.500436
            ],
            [
              -92.368908,
              43.500454
            ],
            [
              -92.388298,
              43.500483
            ],
            [
              -92.40613,
              43.500476
            ],
            [
              -92.408832,
              43.500614
            ],
            [
              -92.44894781237689,
              43.5004201689952
            ],
            [
              -92.464505,
              43.500345
            ],
            [
              -92.55312804163,
              43.500300016729
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "181",
      "COUNTYNS": "00465279",
      "AFFGEOID": "0500000US19181",
      "GEOID": "19181",
      "NAME": "Warren",
      "LSAD": "06",
      "ALAND": 1475875019,
      "AWATER": 9095218,
      "County_state": "Warren,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.790612,
              41.511916
            ],
            [
              -93.790606,
              41.479548
            ],
            [
              -93.790563,
              41.425178
            ],
            [
              -93.790129,
              41.337234
            ],
            [
              -93.789196,
              41.162035
            ],
            [
              -93.557556,
              41.161271
            ],
            [
              -93.441764,
              41.160537
            ],
            [
              -93.327886,
              41.160659
            ],
            [
              -93.328407,
              41.490921
            ],
            [
              -93.340787,
              41.481998
            ],
            [
              -93.352531,
              41.496889
            ],
            [
              -93.370763,
              41.486346
            ],
            [
              -93.382048,
              41.49566
            ],
            [
              -93.396759,
              41.48664
            ],
            [
              -93.398114,
              41.501837
            ],
            [
              -93.414265,
              41.49236
            ],
            [
              -93.41226,
              41.505549
            ],
            [
              -93.422854,
              41.511444
            ],
            [
              -93.441286,
              41.507589
            ],
            [
              -93.544134,
              41.511161
            ],
            [
              -93.577338,
              41.511646
            ],
            [
              -93.644926,
              41.512094
            ],
            [
              -93.65934,
              41.512064
            ],
            [
              -93.698102,
              41.511979
            ],
            [
              -93.731959,
              41.512087
            ],
            [
              -93.790612,
              41.511916
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "185",
      "COUNTYNS": "00465281",
      "AFFGEOID": "0500000US19185",
      "GEOID": "19185",
      "NAME": "Wayne",
      "LSAD": "06",
      "ALAND": 1360940440,
      "AWATER": 4347639,
      "County_state": "Wayne,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.55654,
              40.898295
            ],
            [
              -93.556899,
              40.580235
            ],
            [
              -93.553986,
              40.580303
            ],
            [
              -93.548284,
              40.580417
            ],
            [
              -93.528177,
              40.580367
            ],
            [
              -93.527607,
              40.580436
            ],
            [
              -93.524124,
              40.580481
            ],
            [
              -93.466887,
              40.580072
            ],
            [
              -93.465297,
              40.580164
            ],
            [
              -93.441767,
              40.579916
            ],
            [
              -93.3743860081406,
              40.5803343112705
            ],
            [
              -93.345442,
              40.580514
            ],
            [
              -93.317605,
              40.580671
            ],
            [
              -93.260612,
              40.580797
            ],
            [
              -93.135802,
              40.582854
            ],
            [
              -93.098507,
              40.583973
            ],
            [
              -93.097296,
              40.584014
            ],
            [
              -93.097595,
              40.898451
            ],
            [
              -93.327417,
              40.897884
            ],
            [
              -93.55654,
              40.898295
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "127",
      "COUNTYNS": "00465252",
      "AFFGEOID": "0500000US19127",
      "GEOID": "19127",
      "NAME": "Marshall",
      "LSAD": "06",
      "ALAND": 1482776070,
      "AWATER": 1803080,
      "County_state": "Marshall,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.231722,
              42.208886
            ],
            [
              -93.231858,
              41.862711
            ],
            [
              -92.765999,
              41.862373
            ],
            [
              -92.766306,
              42.035044
            ],
            [
              -92.767463,
              42.21014
            ],
            [
              -93.001674,
              42.209267
            ],
            [
              -93.117879,
              42.208733
            ],
            [
              -93.231722,
              42.208886
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "069",
      "COUNTYNS": "00465223",
      "AFFGEOID": "0500000US19069",
      "GEOID": "19069",
      "NAME": "Franklin",
      "LSAD": "06",
      "ALAND": 1507301123,
      "AWATER": 1428932,
      "County_state": "Franklin,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.498617,
              42.908512
            ],
            [
              -93.499485,
              42.5577
            ],
            [
              -93.027,
              42.55681
            ],
            [
              -93.025144,
              42.907549
            ],
            [
              -93.143172,
              42.907628
            ],
            [
              -93.261912,
              42.907727
            ],
            [
              -93.498617,
              42.908512
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "055",
      "COUNTYNS": "00465216",
      "AFFGEOID": "0500000US19055",
      "GEOID": "19055",
      "NAME": "Delaware",
      "LSAD": "06",
      "ALAND": 1496278852,
      "AWATER": 3185282,
      "County_state": "Delaware,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.607059,
              42.643973
            ],
            [
              -91.608508,
              42.469275
            ],
            [
              -91.597397,
              42.469242
            ],
            [
              -91.596981,
              42.296408
            ],
            [
              -91.363263,
              42.296445
            ],
            [
              -91.130079,
              42.295761
            ],
            [
              -91.130496,
              42.382639
            ],
            [
              -91.134195,
              42.471269
            ],
            [
              -91.132766,
              42.645844
            ],
            [
              -91.607059,
              42.643973
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "019",
      "COUNTYNS": "00465199",
      "AFFGEOID": "0500000US19019",
      "GEOID": "19019",
      "NAME": "Buchanan",
      "LSAD": "06",
      "ALAND": 1478934776,
      "AWATER": 5427666,
      "County_state": "Buchanan,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.081661,
              42.642056
            ],
            [
              -92.081446,
              42.468192
            ],
            [
              -92.064187,
              42.468221
            ],
            [
              -92.064744,
              42.299116
            ],
            [
              -92.064766,
              42.297259
            ],
            [
              -91.948106,
              42.29749
            ],
            [
              -91.831089,
              42.299063
            ],
            [
              -91.596981,
              42.296408
            ],
            [
              -91.597397,
              42.469242
            ],
            [
              -91.608508,
              42.469275
            ],
            [
              -91.607059,
              42.643973
            ],
            [
              -91.844289,
              42.643638
            ],
            [
              -91.924169,
              42.641827
            ],
            [
              -92.081661,
              42.642056
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "187",
      "COUNTYNS": "00465282",
      "AFFGEOID": "0500000US19187",
      "GEOID": "19187",
      "NAME": "Webster",
      "LSAD": "06",
      "ALAND": 1853586077,
      "AWATER": 7278162,
      "County_state": "Webster,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.443083,
              42.645164
            ],
            [
              -94.443024,
              42.558843
            ],
            [
              -94.443033,
              42.473378
            ],
            [
              -94.397671,
              42.4733
            ],
            [
              -94.397526,
              42.209161
            ],
            [
              -94.164704,
              42.20992
            ],
            [
              -93.97978,
              42.209846
            ],
            [
              -93.93158,
              42.209908
            ],
            [
              -93.931684,
              42.472011
            ],
            [
              -93.971599,
              42.472082
            ],
            [
              -93.971583,
              42.558139
            ],
            [
              -93.971714,
              42.644707
            ],
            [
              -94.443083,
              42.645164
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "015",
      "COUNTYNS": "00465197",
      "AFFGEOID": "0500000US19015",
      "GEOID": "19015",
      "NAME": "Boone",
      "LSAD": "06",
      "ALAND": 1480381942,
      "AWATER": 5358723,
      "County_state": "Boone,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.164704,
              42.20992
            ],
            [
              -94.164138,
              41.863244
            ],
            [
              -94.048029,
              41.863128
            ],
            [
              -93.815721,
              41.863419
            ],
            [
              -93.698032,
              41.86337
            ],
            [
              -93.698072,
              41.883404
            ],
            [
              -93.698073,
              41.950478
            ],
            [
              -93.698713,
              42.03456
            ],
            [
              -93.69839,
              42.209339
            ],
            [
              -93.93158,
              42.209908
            ],
            [
              -93.97978,
              42.209846
            ],
            [
              -94.164704,
              42.20992
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "159",
      "COUNTYNS": "00465268",
      "AFFGEOID": "0500000US19159",
      "GEOID": "19159",
      "NAME": "Ringgold",
      "LSAD": "06",
      "ALAND": 1386935176,
      "AWATER": 8720332,
      "County_state": "Ringgold,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.470779,
              40.899502
            ],
            [
              -94.471213,
              40.570825
            ],
            [
              -94.470648,
              40.57083
            ],
            [
              -94.460088,
              40.570947
            ],
            [
              -94.429725,
              40.571041
            ],
            [
              -94.358307,
              40.571363
            ],
            [
              -94.336706,
              40.571452
            ],
            [
              -94.336556,
              40.571475
            ],
            [
              -94.324765,
              40.571477
            ],
            [
              -94.310724,
              40.571524
            ],
            [
              -94.294813,
              40.571341
            ],
            [
              -94.28735,
              40.571521
            ],
            [
              -94.23224004467359,
              40.5719073719895
            ],
            [
              -94.091085,
              40.572897
            ],
            [
              -94.089194,
              40.572806
            ],
            [
              -94.080463,
              40.572899
            ],
            [
              -94.080223,
              40.572899
            ],
            [
              -94.034134,
              40.573585
            ],
            [
              -94.015492,
              40.573914
            ],
            [
              -94.014803,
              40.897031
            ],
            [
              -94.470779,
              40.899502
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "165",
      "COUNTYNS": "00465271",
      "AFFGEOID": "0500000US19165",
      "GEOID": "19165",
      "NAME": "Shelby",
      "LSAD": "06",
      "ALAND": 1530110554,
      "AWATER": 1486125,
      "County_state": "Shelby,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.557277,
              41.863449
            ],
            [
              -95.558143,
              41.602383
            ],
            [
              -95.497793,
              41.602333
            ],
            [
              -95.497997,
              41.506074
            ],
            [
              -95.269357,
              41.505672
            ],
            [
              -95.154722,
              41.505211
            ],
            [
              -95.04077,
              41.50469
            ],
            [
              -95.040748,
              41.602152
            ],
            [
              -95.09252,
              41.602055
            ],
            [
              -95.092861,
              41.863374
            ],
            [
              -95.32484,
              41.864121
            ],
            [
              -95.557277,
              41.863449
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "175",
      "COUNTYNS": "00465276",
      "AFFGEOID": "0500000US19175",
      "GEOID": "19175",
      "NAME": "Union",
      "LSAD": "06",
      "ALAND": 1097235765,
      "AWATER": 5575853,
      "County_state": "Union,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.470603,
              41.157566
            ],
            [
              -94.470779,
              40.899502
            ],
            [
              -94.014803,
              40.897031
            ],
            [
              -94.014189,
              41.156762
            ],
            [
              -94.241637,
              41.157134
            ],
            [
              -94.470603,
              41.157566
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "003",
      "COUNTYNS": "00465191",
      "AFFGEOID": "0500000US19003",
      "GEOID": "19003",
      "NAME": "Adams",
      "LSAD": "06",
      "ALAND": 1096700779,
      "AWATER": 5353407,
      "County_state": "Adams,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.928041,
              40.98621
            ],
            [
              -94.928459,
              40.900653
            ],
            [
              -94.470779,
              40.899502
            ],
            [
              -94.470603,
              41.157566
            ],
            [
              -94.700589,
              41.158085
            ],
            [
              -94.704982,
              41.157903
            ],
            [
              -94.927587,
              41.158508
            ],
            [
              -94.928041,
              40.98621
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "123",
      "COUNTYNS": "00465250",
      "AFFGEOID": "0500000US19123",
      "GEOID": "19123",
      "NAME": "Mahaska",
      "LSAD": "06",
      "ALAND": 1478527855,
      "AWATER": 6564804,
      "County_state": "Mahaska,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.871421,
              41.508522
            ],
            [
              -92.870824,
              41.299342
            ],
            [
              -92.869771,
              41.161066
            ],
            [
              -92.640361,
              41.161298
            ],
            [
              -92.410233,
              41.161942
            ],
            [
              -92.411995,
              41.509548
            ],
            [
              -92.64225,
              41.509144
            ],
            [
              -92.756508,
              41.508794
            ],
            [
              -92.871421,
              41.508522
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "001",
      "COUNTYNS": "00465190",
      "AFFGEOID": "0500000US19001",
      "GEOID": "19001",
      "NAME": "Adair",
      "LSAD": "06",
      "ALAND": 1474404199,
      "AWATER": 2597996,
      "County_state": "Adair,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.700629,
              41.504148
            ],
            [
              -94.700097,
              41.331044
            ],
            [
              -94.700589,
              41.158085
            ],
            [
              -94.470603,
              41.157566
            ],
            [
              -94.241637,
              41.157134
            ],
            [
              -94.241593,
              41.503679
            ],
            [
              -94.700629,
              41.504148
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "079",
      "COUNTYNS": "00465228",
      "AFFGEOID": "0500000US19079",
      "GEOID": "19079",
      "NAME": "Hamilton",
      "LSAD": "06",
      "ALAND": 1493777024,
      "AWATER": 1965341,
      "County_state": "Hamilton,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.971583,
              42.558139
            ],
            [
              -93.971599,
              42.472082
            ],
            [
              -93.931684,
              42.472011
            ],
            [
              -93.93158,
              42.209908
            ],
            [
              -93.69839,
              42.209339
            ],
            [
              -93.581658,
              42.209184
            ],
            [
              -93.463043,
              42.209302
            ],
            [
              -93.462895,
              42.383688
            ],
            [
              -93.462635,
              42.470802
            ],
            [
              -93.499098,
              42.470975
            ],
            [
              -93.499485,
              42.5577
            ],
            [
              -93.971583,
              42.558139
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "083",
      "COUNTYNS": "00465230",
      "AFFGEOID": "0500000US19083",
      "GEOID": "19083",
      "NAME": "Hardin",
      "LSAD": "06",
      "ALAND": 1474507918,
      "AWATER": 1773877,
      "County_state": "Hardin,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.499485,
              42.5577
            ],
            [
              -93.499098,
              42.470975
            ],
            [
              -93.462635,
              42.470802
            ],
            [
              -93.462895,
              42.383688
            ],
            [
              -93.463043,
              42.209302
            ],
            [
              -93.231722,
              42.208886
            ],
            [
              -93.117879,
              42.208733
            ],
            [
              -93.001674,
              42.209267
            ],
            [
              -93.002368,
              42.383452
            ],
            [
              -93.002902,
              42.470638
            ],
            [
              -93.02702,
              42.470756
            ],
            [
              -93.027,
              42.55681
            ],
            [
              -93.499485,
              42.5577
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "093",
      "COUNTYNS": "00465235",
      "AFFGEOID": "0500000US19093",
      "GEOID": "19093",
      "NAME": "Ida",
      "LSAD": "06",
      "ALAND": 1117599859,
      "AWATER": 1406461,
      "County_state": "Ida,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.741611,
              42.561285
            ],
            [
              -95.741821,
              42.474865
            ],
            [
              -95.669265,
              42.474645
            ],
            [
              -95.670822,
              42.211408
            ],
            [
              -95.323497,
              42.210932
            ],
            [
              -95.322357,
              42.474737
            ],
            [
              -95.387445,
              42.474796
            ],
            [
              -95.38801,
              42.561742
            ],
            [
              -95.741611,
              42.561285
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "125",
      "COUNTYNS": "00465251",
      "AFFGEOID": "0500000US19125",
      "GEOID": "19125",
      "NAME": "Marion",
      "LSAD": "06",
      "ALAND": 1436124182,
      "AWATER": 41487001,
      "County_state": "Marion,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.328614,
              41.507824
            ],
            [
              -93.328407,
              41.490921
            ],
            [
              -93.327886,
              41.160659
            ],
            [
              -93.099217,
              41.160867
            ],
            [
              -92.869771,
              41.161066
            ],
            [
              -92.870824,
              41.299342
            ],
            [
              -92.871421,
              41.508522
            ],
            [
              -92.986305,
              41.508261
            ],
            [
              -93.178296,
              41.508077
            ],
            [
              -93.328614,
              41.507824
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "157",
      "COUNTYNS": "00465267",
      "AFFGEOID": "0500000US19157",
      "GEOID": "19157",
      "NAME": "Poweshiek",
      "LSAD": "06",
      "ALAND": 1514929499,
      "AWATER": 2977605,
      "County_state": "Poweshiek,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.765999,
              41.862373
            ],
            [
              -92.766759,
              41.601355
            ],
            [
              -92.757068,
              41.601411
            ],
            [
              -92.756508,
              41.508794
            ],
            [
              -92.64225,
              41.509144
            ],
            [
              -92.411995,
              41.509548
            ],
            [
              -92.297494,
              41.50979
            ],
            [
              -92.299191,
              41.776094
            ],
            [
              -92.298164,
              41.862779
            ],
            [
              -92.765999,
              41.862373
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "153",
      "COUNTYNS": "00465265",
      "AFFGEOID": "0500000US19153",
      "GEOID": "19153",
      "NAME": "Polk",
      "LSAD": "06",
      "ALAND": 1482734960,
      "AWATER": 49332276,
      "County_state": "Polk,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.815721,
              41.863419
            ],
            [
              -93.815032,
              41.803985
            ],
            [
              -93.814326,
              41.632979
            ],
            [
              -93.814297,
              41.614868
            ],
            [
              -93.814282,
              41.600456
            ],
            [
              -93.790632,
              41.600425
            ],
            [
              -93.790547,
              41.5844
            ],
            [
              -93.790523,
              41.533385
            ],
            [
              -93.790612,
              41.511916
            ],
            [
              -93.731959,
              41.512087
            ],
            [
              -93.698102,
              41.511979
            ],
            [
              -93.65934,
              41.512064
            ],
            [
              -93.644926,
              41.512094
            ],
            [
              -93.577338,
              41.511646
            ],
            [
              -93.544134,
              41.511161
            ],
            [
              -93.441286,
              41.507589
            ],
            [
              -93.422854,
              41.511444
            ],
            [
              -93.41226,
              41.505549
            ],
            [
              -93.414265,
              41.49236
            ],
            [
              -93.398114,
              41.501837
            ],
            [
              -93.396759,
              41.48664
            ],
            [
              -93.382048,
              41.49566
            ],
            [
              -93.370763,
              41.486346
            ],
            [
              -93.352531,
              41.496889
            ],
            [
              -93.340787,
              41.481998
            ],
            [
              -93.328407,
              41.490921
            ],
            [
              -93.328614,
              41.507824
            ],
            [
              -93.328681,
              41.601148
            ],
            [
              -93.348681,
              41.600999
            ],
            [
              -93.347933,
              41.863104
            ],
            [
              -93.580878,
              41.863218
            ],
            [
              -93.688036,
              41.863371
            ],
            [
              -93.698032,
              41.86337
            ],
            [
              -93.815721,
              41.863419
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "121",
      "COUNTYNS": "00465249",
      "AFFGEOID": "0500000US19121",
      "GEOID": "19121",
      "NAME": "Madison",
      "LSAD": "06",
      "ALAND": 1452999208,
      "AWATER": 2983840,
      "County_state": "Madison,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.241593,
              41.503679
            ],
            [
              -94.241637,
              41.157134
            ],
            [
              -94.014189,
              41.156762
            ],
            [
              -93.899659,
              41.156597
            ],
            [
              -93.789196,
              41.162035
            ],
            [
              -93.790129,
              41.337234
            ],
            [
              -93.790563,
              41.425178
            ],
            [
              -93.790606,
              41.479548
            ],
            [
              -93.790612,
              41.511916
            ],
            [
              -93.896807,
              41.503098
            ],
            [
              -94.241593,
              41.503679
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "177",
      "COUNTYNS": "00465277",
      "AFFGEOID": "0500000US19177",
      "GEOID": "19177",
      "NAME": "Van Buren",
      "LSAD": "06",
      "ALAND": 1255646356,
      "AWATER": 14839917,
      "County_state": "Van Buren,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.179072,
              40.89972
            ],
            [
              -92.17978,
              40.600529
            ],
            [
              -92.096387,
              40.60183
            ],
            [
              -92.092875,
              40.602082
            ],
            [
              -92.0832,
              40.602244
            ],
            [
              -92.082339,
              40.602176
            ],
            [
              -92.069521,
              40.602772
            ],
            [
              -92.067904,
              40.602648
            ],
            [
              -92.029649,
              40.603713
            ],
            [
              -91.998683,
              40.604433
            ],
            [
              -91.970988,
              40.605112
            ],
            [
              -91.947708,
              40.605471
            ],
            [
              -91.94311318663401,
              40.60584170797
            ],
            [
              -91.939292,
              40.60615
            ],
            [
              -91.868401,
              40.608059
            ],
            [
              -91.832481,
              40.609797
            ],
            [
              -91.824826,
              40.610191
            ],
            [
              -91.813968,
              40.610526
            ],
            [
              -91.800133,
              40.610953
            ],
            [
              -91.795374,
              40.611101
            ],
            [
              -91.785916,
              40.611488
            ],
            [
              -91.729115,
              40.61364
            ],
            [
              -91.720058,
              40.601527
            ],
            [
              -91.716769,
              40.59853
            ],
            [
              -91.718209,
              40.638601
            ],
            [
              -91.718787,
              40.813544
            ],
            [
              -91.71818,
              40.901087
            ],
            [
              -91.832812,
              40.901163
            ],
            [
              -92.179072,
              40.89972
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "087",
      "COUNTYNS": "00465232",
      "AFFGEOID": "0500000US19087",
      "GEOID": "19087",
      "NAME": "Henry",
      "LSAD": "06",
      "ALAND": 1124792081,
      "AWATER": 5768130,
      "County_state": "Henry,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.71818,
              40.901087
            ],
            [
              -91.718787,
              40.813544
            ],
            [
              -91.409453,
              40.812905
            ],
            [
              -91.372095,
              40.812631
            ],
            [
              -91.37071,
              41.074112
            ],
            [
              -91.370298,
              41.161247
            ],
            [
              -91.485717,
              41.162405
            ],
            [
              -91.716042,
              41.162808
            ],
            [
              -91.71818,
              40.901087
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "033",
      "COUNTYNS": "00465206",
      "AFFGEOID": "0500000US19033",
      "GEOID": "19033",
      "NAME": "Cerro Gordo",
      "LSAD": "06",
      "ALAND": 1471881731,
      "AWATER": 17668265,
      "County_state": "Cerro Gordo,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.497635,
              43.255468
            ],
            [
              -93.498617,
              42.908512
            ],
            [
              -93.261912,
              42.907727
            ],
            [
              -93.143172,
              42.907628
            ],
            [
              -93.025144,
              42.907549
            ],
            [
              -93.024092,
              43.212812
            ],
            [
              -93.024143,
              43.255538
            ],
            [
              -93.141565,
              43.255106
            ],
            [
              -93.260374,
              43.255186
            ],
            [
              -93.497635,
              43.255468
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "197",
      "COUNTYNS": "00465287",
      "AFFGEOID": "0500000US19197",
      "GEOID": "19197",
      "NAME": "Wright",
      "LSAD": "06",
      "ALAND": 1503286935,
      "AWATER": 4703964,
      "County_state": "Wright,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.971714,
              42.644707
            ],
            [
              -93.971583,
              42.558139
            ],
            [
              -93.499485,
              42.5577
            ],
            [
              -93.498617,
              42.908512
            ],
            [
              -93.971238,
              42.907762
            ],
            [
              -93.971714,
              42.644707
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "105",
      "COUNTYNS": "00465241",
      "AFFGEOID": "0500000US19105",
      "GEOID": "19105",
      "NAME": "Jones",
      "LSAD": "06",
      "ALAND": 1490771974,
      "AWATER": 3661438,
      "County_state": "Jones,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.364642,
              42.121908
            ],
            [
              -91.365789,
              41.94741
            ],
            [
              -90.898484,
              41.946245
            ],
            [
              -90.898373,
              42.033548
            ],
            [
              -90.89802,
              42.295236
            ],
            [
              -91.130079,
              42.295761
            ],
            [
              -91.363263,
              42.296445
            ],
            [
              -91.364642,
              42.121908
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "027",
      "COUNTYNS": "00465203",
      "AFFGEOID": "0500000US19027",
      "GEOID": "19027",
      "NAME": "Carroll",
      "LSAD": "06",
      "ALAND": 1474835092,
      "AWATER": 2145349,
      "County_state": "Carroll,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.090851,
              42.210405
            ],
            [
              -95.092861,
              41.863374
            ],
            [
              -94.744876,
              41.862394
            ],
            [
              -94.628724,
              41.862763
            ],
            [
              -94.628806,
              42.209396
            ],
            [
              -94.858412,
              42.209692
            ],
            [
              -95.090851,
              42.210405
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "073",
      "COUNTYNS": "00465225",
      "AFFGEOID": "0500000US19073",
      "GEOID": "19073",
      "NAME": "Greene",
      "LSAD": "06",
      "ALAND": 1475260906,
      "AWATER": 4255701,
      "County_state": "Greene,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.628806,
              42.209396
            ],
            [
              -94.628724,
              41.862763
            ],
            [
              -94.28034,
              41.862999
            ],
            [
              -94.164138,
              41.863244
            ],
            [
              -94.164704,
              42.20992
            ],
            [
              -94.397526,
              42.209161
            ],
            [
              -94.628806,
              42.209396
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "063",
      "COUNTYNS": "00465220",
      "AFFGEOID": "0500000US19063",
      "GEOID": "19063",
      "NAME": "Emmet",
      "LSAD": "06",
      "ALAND": 1025328490,
      "AWATER": 17196403,
      "County_state": "Emmet,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.914523,
              43.50045
            ],
            [
              -94.91463375313809,
              43.50045
            ],
            [
              -94.913985,
              43.341458
            ],
            [
              -94.913723,
              43.255054
            ],
            [
              -94.443137,
              43.255014
            ],
            [
              -94.4428478576855,
              43.5005831709068
            ],
            [
              -94.447048,
              43.500639
            ],
            [
              -94.47042,
              43.50034
            ],
            [
              -94.560838,
              43.500377
            ],
            [
              -94.565665,
              43.50033
            ],
            [
              -94.615916,
              43.500544
            ],
            [
              -94.854555091254,
              43.5006140281275
            ],
            [
              -94.857867,
              43.500615
            ],
            [
              -94.860192,
              43.500546
            ],
            [
              -94.872725,
              43.500564
            ],
            [
              -94.874235,
              43.500557
            ],
            [
              -94.887291,
              43.500502
            ],
            [
              -94.914523,
              43.50045
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "131",
      "COUNTYNS": "00465254",
      "AFFGEOID": "0500000US19131",
      "GEOID": "19131",
      "NAME": "Mitchell",
      "LSAD": "06",
      "ALAND": 1215049040,
      "AWATER": 1118415,
      "County_state": "Mitchell,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.0243476382082,
              43.499572157239804
            ],
            [
              -93.024143,
              43.255538
            ],
            [
              -93.024092,
              43.212812
            ],
            [
              -92.554381,
              43.212813
            ],
            [
              -92.55312804163,
              43.500300016729
            ],
            [
              -92.553161,
              43.5003
            ],
            [
              -92.649194,
              43.500049
            ],
            [
              -92.653318,
              43.50005
            ],
            [
              -92.67258,
              43.500055
            ],
            [
              -92.689033,
              43.500062
            ],
            [
              -92.692786,
              43.500063
            ],
            [
              -92.707312,
              43.500069
            ],
            [
              -92.752088,
              43.500084
            ],
            [
              -92.790317,
              43.499567
            ],
            [
              -92.870277,
              43.499548
            ],
            [
              -93.007871,
              43.499604
            ],
            [
              -93.0243476382082,
              43.499572157239804
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "137",
      "COUNTYNS": "00465257",
      "AFFGEOID": "0500000US19137",
      "GEOID": "19137",
      "NAME": "Montgomery",
      "LSAD": "06",
      "ALAND": 1098405722,
      "AWATER": 2410445,
      "County_state": "Montgomery,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.384746,
              40.987448
            ],
            [
              -95.384964,
              40.90155
            ],
            [
              -94.928459,
              40.900653
            ],
            [
              -94.928041,
              40.98621
            ],
            [
              -94.927587,
              41.158508
            ],
            [
              -95.155851,
              41.159236
            ],
            [
              -95.384349,
              41.160011
            ],
            [
              -95.384746,
              40.987448
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "017",
      "COUNTYNS": "00465198",
      "AFFGEOID": "0500000US19017",
      "GEOID": "19017",
      "NAME": "Bremer",
      "LSAD": "06",
      "ALAND": 1127885581,
      "AWATER": 10166470,
      "County_state": "Bremer,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.554495,
              42.730127
            ],
            [
              -92.554492,
              42.642314
            ],
            [
              -92.081661,
              42.642056
            ],
            [
              -92.081568,
              42.907013
            ],
            [
              -92.554211,
              42.907112
            ],
            [
              -92.554495,
              42.730127
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "183",
      "COUNTYNS": "00465280",
      "AFFGEOID": "0500000US19183",
      "GEOID": "19183",
      "NAME": "Washington",
      "LSAD": "06",
      "ALAND": 1473243904,
      "AWATER": 5353677,
      "County_state": "Washington,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.946043,
              41.510749
            ],
            [
              -91.945571,
              41.163578
            ],
            [
              -91.830335,
              41.161536
            ],
            [
              -91.716042,
              41.162808
            ],
            [
              -91.485717,
              41.162405
            ],
            [
              -91.48537,
              41.249646
            ],
            [
              -91.483995,
              41.423848
            ],
            [
              -91.500698,
              41.446675
            ],
            [
              -91.499639,
              41.459767
            ],
            [
              -91.516012,
              41.469189
            ],
            [
              -91.502103,
              41.47745
            ],
            [
              -91.506494,
              41.500751
            ],
            [
              -91.518579,
              41.497273
            ],
            [
              -91.519828,
              41.51101
            ],
            [
              -91.829231,
              41.511457
            ],
            [
              -91.946043,
              41.510749
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "141",
      "COUNTYNS": "00465259",
      "AFFGEOID": "0500000US19141",
      "GEOID": "19141",
      "NAME": "O'Brien",
      "LSAD": "06",
      "ALAND": 1484154165,
      "AWATER": 447448,
      "County_state": "O'Brien,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.861914,
              43.257566
            ],
            [
              -95.859951,
              42.996661
            ],
            [
              -95.859377,
              42.909097
            ],
            [
              -95.388182,
              42.909897
            ],
            [
              -95.388078,
              43.255221
            ],
            [
              -95.861914,
              43.257566
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "151",
      "COUNTYNS": "00465264",
      "AFFGEOID": "0500000US19151",
      "GEOID": "19151",
      "NAME": "Pocahontas",
      "LSAD": "06",
      "ALAND": 1495047142,
      "AWATER": 3666530,
      "County_state": "Pocahontas,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.91389,
              42.9097
            ],
            [
              -94.914485,
              42.560309
            ],
            [
              -94.443024,
              42.558843
            ],
            [
              -94.443083,
              42.645164
            ],
            [
              -94.442954,
              42.908073
            ],
            [
              -94.91389,
              42.9097
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "051",
      "COUNTYNS": "00465214",
      "AFFGEOID": "0500000US19051",
      "GEOID": "19051",
      "NAME": "Davis",
      "LSAD": "06",
      "ALAND": 1300661857,
      "AWATER": 6904096,
      "County_state": "Davis,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.639091,
              40.898886
            ],
            [
              -92.637898,
              40.590853
            ],
            [
              -92.580278,
              40.592151
            ],
            [
              -92.484588,
              40.594924
            ],
            [
              -92.482394,
              40.594894
            ],
            [
              -92.481692,
              40.594941
            ],
            [
              -92.461609,
              40.595355
            ],
            [
              -92.453745,
              40.595288
            ],
            [
              -92.379691,
              40.596509
            ],
            [
              -92.35080671229349,
              40.597273187449304
            ],
            [
              -92.350776,
              40.597274
            ],
            [
              -92.331445,
              40.597714
            ],
            [
              -92.331205,
              40.597805
            ],
            [
              -92.298754,
              40.598469
            ],
            [
              -92.236484,
              40.599531
            ],
            [
              -92.217603,
              40.599832
            ],
            [
              -92.201669,
              40.59998
            ],
            [
              -92.196162,
              40.600069
            ],
            [
              -92.17978,
              40.600529
            ],
            [
              -92.179072,
              40.89972
            ],
            [
              -92.52476,
              40.899115
            ],
            [
              -92.639091,
              40.898886
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "047",
      "COUNTYNS": "00465212",
      "AFFGEOID": "0500000US19047",
      "GEOID": "19047",
      "NAME": "Crawford",
      "LSAD": "06",
      "ALAND": 1849742884,
      "AWATER": 2194272,
      "County_state": "Crawford,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.670822,
              42.211408
            ],
            [
              -95.672771,
              41.863147
            ],
            [
              -95.557277,
              41.863449
            ],
            [
              -95.32484,
              41.864121
            ],
            [
              -95.092861,
              41.863374
            ],
            [
              -95.090851,
              42.210405
            ],
            [
              -95.323497,
              42.210932
            ],
            [
              -95.670822,
              42.211408
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "075",
      "COUNTYNS": "00465226",
      "AFFGEOID": "0500000US19075",
      "GEOID": "19075",
      "NAME": "Grundy",
      "LSAD": "06",
      "ALAND": 1299797785,
      "AWATER": 94406,
      "County_state": "Grundy,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.027,
              42.55681
            ],
            [
              -93.02702,
              42.470756
            ],
            [
              -93.002902,
              42.470638
            ],
            [
              -93.002368,
              42.383452
            ],
            [
              -93.001674,
              42.209267
            ],
            [
              -92.767463,
              42.21014
            ],
            [
              -92.76809,
              42.29668
            ],
            [
              -92.53351,
              42.297001
            ],
            [
              -92.534015,
              42.469592
            ],
            [
              -92.55433,
              42.469601
            ],
            [
              -92.5543,
              42.555854
            ],
            [
              -92.90795,
              42.556199
            ],
            [
              -93.027,
              42.55681
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "009",
      "COUNTYNS": "00465194",
      "AFFGEOID": "0500000US19009",
      "GEOID": "19009",
      "NAME": "Audubon",
      "LSAD": "06",
      "ALAND": 1147264553,
      "AWATER": 1152260,
      "County_state": "Audubon,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.092861,
              41.863374
            ],
            [
              -95.09252,
              41.602055
            ],
            [
              -95.040748,
              41.602152
            ],
            [
              -95.04077,
              41.50469
            ],
            [
              -94.92638,
              41.504524
            ],
            [
              -94.700629,
              41.504148
            ],
            [
              -94.700745,
              41.602184
            ],
            [
              -94.744204,
              41.602185
            ],
            [
              -94.744876,
              41.862394
            ],
            [
              -95.092861,
              41.863374
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "113",
      "COUNTYNS": "00465245",
      "AFFGEOID": "0500000US19113",
      "GEOID": "19113",
      "NAME": "Linn",
      "LSAD": "06",
      "ALAND": 1856758894,
      "AWATER": 19699801,
      "County_state": "Linn,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.835262,
              42.036954
            ],
            [
              -91.833289,
              41.949373
            ],
            [
              -91.831379,
              41.861851
            ],
            [
              -91.597895,
              41.860726
            ],
            [
              -91.481921,
              41.859893
            ],
            [
              -91.366079,
              41.860068
            ],
            [
              -91.365789,
              41.94741
            ],
            [
              -91.364642,
              42.121908
            ],
            [
              -91.363263,
              42.296445
            ],
            [
              -91.596981,
              42.296408
            ],
            [
              -91.831089,
              42.299063
            ],
            [
              -91.835262,
              42.036954
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "107",
      "COUNTYNS": "00465242",
      "AFFGEOID": "0500000US19107",
      "GEOID": "19107",
      "NAME": "Keokuk",
      "LSAD": "06",
      "ALAND": 1500067253,
      "AWATER": 1929323,
      "County_state": "Keokuk,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.411995,
              41.509548
            ],
            [
              -92.410233,
              41.161942
            ],
            [
              -92.295933,
              41.162303
            ],
            [
              -92.179974,
              41.162662
            ],
            [
              -91.945571,
              41.163578
            ],
            [
              -91.946043,
              41.510749
            ],
            [
              -92.297494,
              41.50979
            ],
            [
              -92.411995,
              41.509548
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "161",
      "COUNTYNS": "00465269",
      "AFFGEOID": "0500000US19161",
      "GEOID": "19161",
      "NAME": "Sac",
      "LSAD": "06",
      "ALAND": 1489300182,
      "AWATER": 8569441,
      "County_state": "Sac,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.38801,
              42.561742
            ],
            [
              -95.387445,
              42.474796
            ],
            [
              -95.322357,
              42.474737
            ],
            [
              -95.323497,
              42.210932
            ],
            [
              -95.090851,
              42.210405
            ],
            [
              -94.858412,
              42.209692
            ],
            [
              -94.856689,
              42.473996
            ],
            [
              -94.914487,
              42.47419
            ],
            [
              -94.914485,
              42.560309
            ],
            [
              -95.38801,
              42.561742
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "193",
      "COUNTYNS": "00465285",
      "AFFGEOID": "0500000US19193",
      "GEOID": "19193",
      "NAME": "Woodbury",
      "LSAD": "06",
      "ALAND": 2260790978,
      "AWATER": 12793719,
      "County_state": "Woodbury,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.498997,
              42.560832
            ],
            [
              -96.494699,
              42.556745
            ],
            [
              -96.476952,
              42.556079
            ],
            [
              -96.476962,
              42.546434
            ],
            [
              -96.477709,
              42.535595
            ],
            [
              -96.479809,
              42.529595
            ],
            [
              -96.479009,
              42.526395
            ],
            [
              -96.479909,
              42.524195
            ],
            [
              -96.490802,
              42.520331
            ],
            [
              -96.49297,
              42.517282
            ],
            [
              -96.490089,
              42.512441
            ],
            [
              -96.483592,
              42.510345
            ],
            [
              -96.479384,
              42.511138
            ],
            [
              -96.477454,
              42.509589
            ],
            [
              -96.473339,
              42.503537
            ],
            [
              -96.456348,
              42.492478
            ],
            [
              -96.445508,
              42.49063
            ],
            [
              -96.423892,
              42.48898
            ],
            [
              -96.4134338764416,
              42.487979965401195
            ],
            [
              -96.409408,
              42.487595
            ],
            [
              -96.3961883611554,
              42.4841164092206
            ],
            [
              -96.396107,
              42.484095
            ],
            [
              -96.386007,
              42.474495
            ],
            [
              -96.385407,
              42.473094
            ],
            [
              -96.381307,
              42.461694
            ],
            [
              -96.380107,
              42.451494
            ],
            [
              -96.380707,
              42.446394
            ],
            [
              -96.384307,
              42.437294
            ],
            [
              -96.387608,
              42.432494
            ],
            [
              -96.411808,
              42.410894
            ],
            [
              -96.413609,
              42.407894
            ],
            [
              -96.415509,
              42.400294
            ],
            [
              -96.41498,
              42.393442
            ],
            [
              -96.409153,
              42.381491
            ],
            [
              -96.408436,
              42.376092
            ],
            [
              -96.413994,
              42.365932
            ],
            [
              -96.417093,
              42.361443
            ],
            [
              -96.417918,
              42.3587
            ],
            [
              -96.418168,
              42.354678
            ],
            [
              -96.417786,
              42.351449
            ],
            [
              -96.413895,
              42.343393
            ],
            [
              -96.407998,
              42.337408
            ],
            [
              -96.402957,
              42.334156
            ],
            [
              -96.396269,
              42.330857
            ],
            [
              -96.384169,
              42.325874
            ],
            [
              -96.375307,
              42.318339
            ],
            [
              -96.37179,
              42.314172
            ],
            [
              -96.369969,
              42.310878
            ],
            [
              -96.369212,
              42.308344
            ],
            [
              -96.368507,
              42.303622
            ],
            [
              -96.368454,
              42.291848
            ],
            [
              -96.365792,
              42.285875
            ],
            [
              -96.3608,
              42.279867
            ],
            [
              -96.3564060678816,
              42.2764931056257
            ],
            [
              -96.356389,
              42.27648
            ],
            [
              -96.34145,
              42.269115
            ],
            [
              -96.336003,
              42.264806
            ],
            [
              -96.331331,
              42.25943
            ],
            [
              -96.328905,
              42.254734
            ],
            [
              -96.327706,
              42.249992
            ],
            [
              -96.328955,
              42.241885
            ],
            [
              -96.330004,
              42.240224
            ],
            [
              -96.322868,
              42.233637
            ],
            [
              -96.322827,
              42.231461
            ],
            [
              -96.323723,
              42.229887
            ],
            [
              -96.332044,
              42.221585
            ],
            [
              -96.336323,
              42.218922
            ],
            [
              -96.339086,
              42.218087
            ],
            [
              -96.345055,
              42.21749
            ],
            [
              -96.356591,
              42.215182
            ],
            [
              -96.35665510863,
              42.2151367517153
            ],
            [
              -96.25144,
              42.214644
            ],
            [
              -95.670822,
              42.211408
            ],
            [
              -95.669265,
              42.474645
            ],
            [
              -95.741821,
              42.474865
            ],
            [
              -95.741611,
              42.561285
            ],
            [
              -95.859949,
              42.56062
            ],
            [
              -95.978187,
              42.561085
            ],
            [
              -96.214085,
              42.561924
            ],
            [
              -96.233629,
              42.561421
            ],
            [
              -96.401133,
              42.561995
            ],
            [
              -96.498997,
              42.560832
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "155",
      "COUNTYNS": "00465266",
      "AFFGEOID": "0500000US19155",
      "GEOID": "19155",
      "NAME": "Pottawattamie",
      "LSAD": "06",
      "ALAND": 2461903582,
      "AWATER": 22261204,
      "County_state": "Pottawattamie,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.019817,
              41.48803
            ],
            [
              -96.019542,
              41.486617
            ],
            [
              -96.016389,
              41.481556
            ],
            [
              -96.011757,
              41.476212
            ],
            [
              -96.008833,
              41.474039
            ],
            [
              -96.004708,
              41.472342
            ],
            [
              -95.991018,
              41.470374
            ],
            [
              -95.982962,
              41.469778
            ],
            [
              -95.973449,
              41.467318
            ],
            [
              -95.965481,
              41.46351
            ],
            [
              -95.962329,
              41.46281
            ],
            [
              -95.957017,
              41.462814
            ],
            [
              -95.946465,
              41.466166
            ],
            [
              -95.941969,
              41.466262
            ],
            [
              -95.936801,
              41.46519
            ],
            [
              -95.932921,
              41.463798
            ],
            [
              -95.925713,
              41.459382
            ],
            [
              -95.922529,
              41.455766
            ],
            [
              -95.920281,
              41.451566
            ],
            [
              -95.919865,
              41.447922
            ],
            [
              -95.920577,
              41.444302
            ],
            [
              -95.921833,
              41.442062
            ],
            [
              -95.923905,
              41.439742
            ],
            [
              -95.930705,
              41.433894
            ],
            [
              -95.932193,
              41.431914
            ],
            [
              -95.933169,
              41.42943
            ],
            [
              -95.932297,
              41.422123
            ],
            [
              -95.929889,
              41.415155
            ],
            [
              -95.929721,
              41.411331
            ],
            [
              -95.930778,
              41.406179
            ],
            [
              -95.93689,
              41.396387
            ],
            [
              -95.93749,
              41.393095
            ],
            [
              -95.93693071202759,
              41.390979432452404
            ],
            [
              -95.93519,
              41.384395
            ],
            [
              -95.92929,
              41.374896
            ],
            [
              -95.92879,
              41.370096
            ],
            [
              -95.93099,
              41.364696
            ],
            [
              -95.93549,
              41.360596
            ],
            [
              -95.94099,
              41.357496
            ],
            [
              -95.952191,
              41.353496
            ],
            [
              -95.954891,
              41.351796
            ],
            [
              -95.956791,
              41.349196
            ],
            [
              -95.956691,
              41.345496
            ],
            [
              -95.953091,
              41.339896
            ],
            [
              -95.946891,
              41.334096
            ],
            [
              -95.939291,
              41.328897
            ],
            [
              -95.92569,
              41.322197
            ],
            [
              -95.92209,
              41.321097
            ],
            [
              -95.91379,
              41.320197
            ],
            [
              -95.89929,
              41.321197
            ],
            [
              -95.88869,
              41.319097
            ],
            [
              -95.883089,
              41.316697
            ],
            [
              -95.878189,
              41.312497
            ],
            [
              -95.874689,
              41.307097
            ],
            [
              -95.871489,
              41.295797
            ],
            [
              -95.87276441176469,
              41.290057647058795
            ],
            [
              -95.872889,
              41.289497
            ],
            [
              -95.87689,
              41.285097
            ],
            [
              -95.88239,
              41.281397
            ],
            [
              -95.890152,
              41.278308
            ],
            [
              -95.899069,
              41.283861
            ],
            [
              -95.90429,
              41.293497
            ],
            [
              -95.90429,
              41.299597
            ],
            [
              -95.90589,
              41.300897
            ],
            [
              -95.920291,
              41.301097
            ],
            [
              -95.927491,
              41.298397
            ],
            [
              -95.929591,
              41.292297
            ],
            [
              -95.929591,
              41.290449
            ],
            [
              -95.929591,
              41.285097
            ],
            [
              -95.928691,
              41.281398
            ],
            [
              -95.924976,
              41.274572
            ],
            [
              -95.92447208196201,
              41.2729428011309
            ],
            [
              -95.921891,
              41.264598
            ],
            [
              -95.921291,
              41.258498
            ],
            [
              -95.91708718057889,
              41.2497931214007
            ],
            [
              -95.911391,
              41.237998
            ],
            [
              -95.910891,
              41.233998
            ],
            [
              -95.910891,
              41.231798
            ],
            [
              -95.912591,
              41.226998
            ],
            [
              -95.915091,
              41.222998
            ],
            [
              -95.924891,
              41.211198
            ],
            [
              -95.927491,
              41.202198
            ],
            [
              -95.92599,
              41.195698
            ],
            [
              -95.9232187016371,
              41.1910461777481
            ],
            [
              -95.92319,
              41.190998
            ],
            [
              -95.91829,
              41.186698
            ],
            [
              -95.91459,
              41.185098
            ],
            [
              -95.90969,
              41.184398
            ],
            [
              -95.864789,
              41.188298
            ],
            [
              -95.856788,
              41.187098
            ],
            [
              -95.850188,
              41.184798
            ],
            [
              -95.844088,
              41.180598
            ],
            [
              -95.841288,
              41.174998
            ],
            [
              -95.8413038459581,
              41.1748950012721
            ],
            [
              -95.841888,
              41.171098
            ],
            [
              -95.846188,
              41.166698
            ],
            [
              -95.852788,
              41.165398
            ],
            [
              -95.865072,
              41.167802
            ],
            [
              -95.867344,
              41.168734
            ],
            [
              -95.86964,
              41.16883
            ],
            [
              -95.871912,
              41.168122
            ],
            [
              -95.876289,
              41.165146
            ],
            [
              -95.8809355605553,
              41.160268970041194
            ],
            [
              -95.878696,
              41.159828
            ],
            [
              -95.384349,
              41.160011
            ],
            [
              -95.155851,
              41.159236
            ],
            [
              -95.155293,
              41.500035
            ],
            [
              -95.154722,
              41.505211
            ],
            [
              -95.269357,
              41.505672
            ],
            [
              -95.497997,
              41.506074
            ],
            [
              -95.823054,
              41.506565
            ],
            [
              -95.99617450065989,
              41.50695922694919
            ],
            [
              -95.997903,
              41.504789
            ],
            [
              -96.007334,
              41.497631
            ],
            [
              -96.015986,
              41.492659
            ],
            [
              -96.019224,
              41.489296
            ],
            [
              -96.019817,
              41.48803
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "139",
      "COUNTYNS": "00465258",
      "AFFGEOID": "0500000US19139",
      "GEOID": "19139",
      "NAME": "Muscatine",
      "LSAD": "06",
      "ALAND": 1132977189,
      "AWATER": 30262373,
      "County_state": "Muscatine,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.368521,
              41.423178
            ],
            [
              -91.369332,
              41.336063
            ],
            [
              -91.151787,
              41.334447
            ],
            [
              -91.071940564762,
              41.333592302215294
            ],
            [
              -91.071552,
              41.339651
            ],
            [
              -91.06652,
              41.365246
            ],
            [
              -91.065058,
              41.369101
            ],
            [
              -91.05158,
              41.385283
            ],
            [
              -91.05101,
              41.387556
            ],
            [
              -91.050328,
              41.400049
            ],
            [
              -91.047819,
              41.4109
            ],
            [
              -91.04589,
              41.414085
            ],
            [
              -91.043988,
              41.415897
            ],
            [
              -91.039872,
              41.418523
            ],
            [
              -91.037131,
              41.420017
            ],
            [
              -91.027787,
              41.423603
            ],
            [
              -91.01198,
              41.425024
            ],
            [
              -91.005846,
              41.426135
            ],
            [
              -90.984898,
              41.433869
            ],
            [
              -90.979815,
              41.434321
            ],
            [
              -90.975168,
              41.433985
            ],
            [
              -90.966662,
              41.430051
            ],
            [
              -90.953198,
              41.425075
            ],
            [
              -90.949791,
              41.424163
            ],
            [
              -90.930016,
              41.421404
            ],
            [
              -90.924343,
              41.42286
            ],
            [
              -90.919351,
              41.425589
            ],
            [
              -90.900471,
              41.431154
            ],
            [
              -90.890787,
              41.435432
            ],
            [
              -90.879778,
              41.441065
            ],
            [
              -90.867282,
              41.448215
            ],
            [
              -90.857554,
              41.452751
            ],
            [
              -90.853604,
              41.453909
            ],
            [
              -90.846558,
              41.455141
            ],
            [
              -90.837414,
              41.455623
            ],
            [
              -90.824736,
              41.454467
            ],
            [
              -90.807283,
              41.454466
            ],
            [
              -90.786282,
              41.452888
            ],
            [
              -90.785999,
              41.46575
            ],
            [
              -90.783812,
              41.596531
            ],
            [
              -90.899853,
              41.597133
            ],
            [
              -91.00529,
              41.597384
            ],
            [
              -91.038231,
              41.597405
            ],
            [
              -91.366448,
              41.598373
            ],
            [
              -91.368521,
              41.423178
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "129",
      "COUNTYNS": "00465253",
      "AFFGEOID": "0500000US19129",
      "GEOID": "19129",
      "NAME": "Mills",
      "LSAD": "06",
      "ALAND": 1132950323,
      "AWATER": 8306492,
      "County_state": "Mills,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.883489,
              41.154898
            ],
            [
              -95.883389,
              41.150898
            ],
            [
              -95.882088,
              41.143998
            ],
            [
              -95.878888,
              41.138098
            ],
            [
              -95.868688,
              41.124698
            ],
            [
              -95.865888,
              41.117898
            ],
            [
              -95.86545,
              41.101266
            ],
            [
              -95.863268,
              41.093765
            ],
            [
              -95.862587,
              41.088399
            ],
            [
              -95.863839,
              41.083507
            ],
            [
              -95.865463,
              41.080367
            ],
            [
              -95.870631,
              41.075231
            ],
            [
              -95.878103,
              41.069587
            ],
            [
              -95.881375,
              41.065203
            ],
            [
              -95.882415,
              41.060411
            ],
            [
              -95.881855,
              41.057211
            ],
            [
              -95.879487,
              41.053299
            ],
            [
              -95.869807,
              41.045199
            ],
            [
              -95.861782,
              41.039427
            ],
            [
              -95.860462,
              41.037887
            ],
            [
              -95.859654,
              41.035695
            ],
            [
              -95.859102,
              41.031599
            ],
            [
              -95.859918,
              41.025403
            ],
            [
              -95.865878,
              41.017403
            ],
            [
              -95.868374,
              41.012703
            ],
            [
              -95.869486,
              41.009399
            ],
            [
              -95.869198,
              41.005951
            ],
            [
              -95.867286,
              41.001599
            ],
            [
              -95.863492,
              40.99734
            ],
            [
              -95.860116,
              40.995242
            ],
            [
              -95.852547,
              40.991738
            ],
            [
              -95.844351,
              40.989524
            ],
            [
              -95.838908,
              40.986484
            ],
            [
              -95.833537,
              40.98266
            ],
            [
              -95.830297,
              40.978332
            ],
            [
              -95.829074,
              40.975688
            ],
            [
              -95.828329,
              40.972378
            ],
            [
              -95.829829,
              40.963857
            ],
            [
              -95.837951,
              40.950618
            ],
            [
              -95.840275,
              40.939942
            ],
            [
              -95.839743,
              40.93278
            ],
            [
              -95.837774,
              40.924712
            ],
            [
              -95.836438,
              40.921642
            ],
            [
              -95.833041,
              40.917243
            ],
            [
              -95.830699,
              40.915004
            ],
            [
              -95.818709,
              40.906818
            ],
            [
              -95.814302,
              40.902936
            ],
            [
              -95.8134577460103,
              40.901693097976
            ],
            [
              -95.704299,
              40.901045
            ],
            [
              -95.384964,
              40.90155
            ],
            [
              -95.384746,
              40.987448
            ],
            [
              -95.384349,
              41.160011
            ],
            [
              -95.878696,
              41.159828
            ],
            [
              -95.8809355605553,
              41.160268970041194
            ],
            [
              -95.881289,
              41.159898
            ],
            [
              -95.883489,
              41.154898
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "143",
      "COUNTYNS": "00465260",
      "AFFGEOID": "0500000US19143",
      "GEOID": "19143",
      "NAME": "Osceola",
      "LSAD": "06",
      "ALAND": 1032593860,
      "AWATER": 1835265,
      "County_state": "Osceola,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.8609457037037,
              43.499966
            ],
            [
              -95.861914,
              43.257566
            ],
            [
              -95.388078,
              43.255221
            ],
            [
              -95.38759,
              43.342237
            ],
            [
              -95.38778709258969,
              43.5002401628325
            ],
            [
              -95.387812,
              43.50024
            ],
            [
              -95.387851,
              43.50024
            ],
            [
              -95.434199,
              43.500314
            ],
            [
              -95.434293,
              43.50036
            ],
            [
              -95.45443302611929,
              43.5006441487053
            ],
            [
              -95.454706,
              43.500648
            ],
            [
              -95.454706,
              43.500563
            ],
            [
              -95.475065,
              43.500335
            ],
            [
              -95.486737,
              43.500274
            ],
            [
              -95.486803,
              43.500246
            ],
            [
              -95.514774,
              43.499865
            ],
            [
              -95.740813,
              43.499894
            ],
            [
              -95.741569,
              43.499891
            ],
            [
              -95.821277,
              43.499965
            ],
            [
              -95.834421,
              43.499966
            ],
            [
              -95.8609457037037,
              43.499966
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "065",
      "COUNTYNS": "00465221",
      "AFFGEOID": "0500000US19065",
      "GEOID": "19065",
      "NAME": "Fayette",
      "LSAD": "06",
      "ALAND": 1892840950,
      "AWATER": 1320705,
      "County_state": "Fayette,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.081568,
              42.907013
            ],
            [
              -92.081661,
              42.642056
            ],
            [
              -91.924169,
              42.641827
            ],
            [
              -91.844289,
              42.643638
            ],
            [
              -91.607059,
              42.643973
            ],
            [
              -91.608423,
              42.81278
            ],
            [
              -91.606597,
              42.818549
            ],
            [
              -91.607328,
              42.821222
            ],
            [
              -91.605736,
              42.993234
            ],
            [
              -91.605307,
              43.081653
            ],
            [
              -92.081146,
              43.082794
            ],
            [
              -92.081568,
              42.907013
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "099",
      "COUNTYNS": "00465238",
      "AFFGEOID": "0500000US19099",
      "GEOID": "19099",
      "NAME": "Jasper",
      "LSAD": "06",
      "ALAND": 1891773617,
      "AWATER": 6565650,
      "County_state": "Jasper,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.347933,
              41.863104
            ],
            [
              -93.348681,
              41.600999
            ],
            [
              -93.328681,
              41.601148
            ],
            [
              -93.328614,
              41.507824
            ],
            [
              -93.178296,
              41.508077
            ],
            [
              -92.986305,
              41.508261
            ],
            [
              -92.871421,
              41.508522
            ],
            [
              -92.756508,
              41.508794
            ],
            [
              -92.757068,
              41.601411
            ],
            [
              -92.766759,
              41.601355
            ],
            [
              -92.765999,
              41.862373
            ],
            [
              -93.231858,
              41.862711
            ],
            [
              -93.347933,
              41.863104
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "057",
      "COUNTYNS": "00465217",
      "AFFGEOID": "0500000US19057",
      "GEOID": "19057",
      "NAME": "Des Moines",
      "LSAD": "06",
      "ALAND": 1077784093,
      "AWATER": 35342793,
      "County_state": "Des Moines,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.372095,
              40.812631
            ],
            [
              -91.409453,
              40.812905
            ],
            [
              -91.363872,
              40.790665
            ],
            [
              -91.293391,
              40.772577
            ],
            [
              -91.280566,
              40.753263
            ],
            [
              -91.258026,
              40.752019
            ],
            [
              -91.217118,
              40.732286
            ],
            [
              -91.20955,
              40.713993
            ],
            [
              -91.182551,
              40.7081
            ],
            [
              -91.176521,
              40.721092
            ],
            [
              -91.143474,
              40.704895
            ],
            [
              -91.138818,
              40.700706
            ],
            [
              -91.11243358457939,
              40.6962786908794
            ],
            [
              -91.11194,
              40.697018
            ],
            [
              -91.110927,
              40.703262
            ],
            [
              -91.111095,
              40.708282
            ],
            [
              -91.113885,
              40.719532
            ],
            [
              -91.115158,
              40.721895
            ],
            [
              -91.115735,
              40.725168
            ],
            [
              -91.110424,
              40.745528
            ],
            [
              -91.1082,
              40.750935
            ],
            [
              -91.102486,
              40.757076
            ],
            [
              -91.098105,
              40.763233
            ],
            [
              -91.096133,
              40.767134
            ],
            [
              -91.091703,
              40.779708
            ],
            [
              -91.091246,
              40.786724
            ],
            [
              -91.092256,
              40.792909
            ],
            [
              -91.097031,
              40.802471
            ],
            [
              -91.097649,
              40.805575
            ],
            [
              -91.096846,
              40.811617
            ],
            [
              -91.092993,
              40.821079
            ],
            [
              -91.090072,
              40.824638
            ],
            [
              -91.077521,
              40.833405
            ],
            [
              -91.067159,
              40.841997
            ],
            [
              -91.058749,
              40.846309
            ],
            [
              -91.05643,
              40.848387
            ],
            [
              -91.050241,
              40.858514
            ],
            [
              -91.047344,
              40.864654
            ],
            [
              -91.044653,
              40.868356
            ],
            [
              -91.039097,
              40.873565
            ],
            [
              -91.036789,
              40.875038
            ],
            [
              -91.027489,
              40.879173
            ],
            [
              -91.021562,
              40.884021
            ],
            [
              -91.01324,
              40.896622
            ],
            [
              -91.0117926988919,
              40.898162687977596
            ],
            [
              -91.009536,
              40.900565
            ],
            [
              -91.003536,
              40.905146
            ],
            [
              -90.9985,
              40.90812
            ],
            [
              -90.985462,
              40.912141
            ],
            [
              -90.97919,
              40.915522
            ],
            [
              -90.968995,
              40.919127
            ],
            [
              -90.965344,
              40.921633
            ],
            [
              -90.962916,
              40.924957
            ],
            [
              -90.960462,
              40.936356
            ],
            [
              -90.952233,
              40.954047
            ],
            [
              -90.951967,
              40.958238
            ],
            [
              -90.952715,
              40.962087
            ],
            [
              -90.955111,
              40.969858
            ],
            [
              -90.958089,
              40.976643
            ],
            [
              -90.958142,
              40.979767
            ],
            [
              -90.955201,
              40.986805
            ],
            [
              -90.949634,
              40.995248
            ],
            [
              -90.945949,
              41.006495
            ],
            [
              -90.945054,
              41.011917
            ],
            [
              -90.945324,
              41.019279
            ],
            [
              -90.942253,
              41.034702
            ],
            [
              -90.94232,
              41.038472
            ],
            [
              -90.943652,
              41.048637
            ],
            [
              -90.944577,
              41.052255
            ],
            [
              -90.945549,
              41.06173
            ],
            [
              -90.94899002615979,
              41.0702495855382
            ],
            [
              -90.949136,
              41.070611
            ],
            [
              -90.9493828838387,
              41.0727100123937
            ],
            [
              -91.37071,
              41.074112
            ],
            [
              -91.372095,
              40.812631
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "169",
      "COUNTYNS": "00465273",
      "AFFGEOID": "0500000US19169",
      "GEOID": "19169",
      "NAME": "Story",
      "LSAD": "06",
      "ALAND": 1483235295,
      "AWATER": 2509634,
      "County_state": "Story,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.69839,
              42.209339
            ],
            [
              -93.698713,
              42.03456
            ],
            [
              -93.698073,
              41.950478
            ],
            [
              -93.698072,
              41.883404
            ],
            [
              -93.698032,
              41.86337
            ],
            [
              -93.688036,
              41.863371
            ],
            [
              -93.580878,
              41.863218
            ],
            [
              -93.347933,
              41.863104
            ],
            [
              -93.231858,
              41.862711
            ],
            [
              -93.231722,
              42.208886
            ],
            [
              -93.463043,
              42.209302
            ],
            [
              -93.581658,
              42.209184
            ],
            [
              -93.69839,
              42.209339
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "149",
      "COUNTYNS": "00465263",
      "AFFGEOID": "0500000US19149",
      "GEOID": "19149",
      "NAME": "Plymouth",
      "LSAD": "06",
      "ALAND": 2234715192,
      "AWATER": 2176851,
      "County_state": "Plymouth,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.639704,
              42.737071
            ],
            [
              -96.638621,
              42.734921
            ],
            [
              -96.631931,
              42.725086
            ],
            [
              -96.626317,
              42.725951
            ],
            [
              -96.624704,
              42.725497
            ],
            [
              -96.624446,
              42.714294
            ],
            [
              -96.627233,
              42.709947
            ],
            [
              -96.629777,
              42.708852
            ],
            [
              -96.630617,
              42.70588
            ],
            [
              -96.629625,
              42.705102
            ],
            [
              -96.619536,
              42.700189
            ],
            [
              -96.61017,
              42.694568
            ],
            [
              -96.596405,
              42.688514
            ],
            [
              -96.591602,
              42.688081
            ],
            [
              -96.58562,
              42.687076
            ],
            [
              -96.575299,
              42.682665
            ],
            [
              -96.568078,
              42.676241
            ],
            [
              -96.566684,
              42.675942
            ],
            [
              -96.56055,
              42.669198
            ],
            [
              -96.546827,
              42.661491
            ],
            [
              -96.543698,
              42.661377
            ],
            [
              -96.542366,
              42.660736
            ],
            [
              -96.537877,
              42.655431
            ],
            [
              -96.5376,
              42.652161
            ],
            [
              -96.538468,
              42.648092
            ],
            [
              -96.537881,
              42.646446
            ],
            [
              -96.533701,
              42.643541
            ],
            [
              -96.526766,
              42.641184
            ],
            [
              -96.516338,
              42.630435
            ],
            [
              -96.51535,
              42.627645
            ],
            [
              -96.515918,
              42.624994
            ],
            [
              -96.509468,
              42.61273
            ],
            [
              -96.504654,
              42.605001
            ],
            [
              -96.500183,
              42.594106
            ],
            [
              -96.500243,
              42.592731
            ],
            [
              -96.501434,
              42.59061
            ],
            [
              -96.501037,
              42.589247
            ],
            [
              -96.499885,
              42.588539
            ],
            [
              -96.496792,
              42.587655
            ],
            [
              -96.494777,
              42.585741
            ],
            [
              -96.494676,
              42.584028
            ],
            [
              -96.49557,
              42.582722
            ],
            [
              -96.496066,
              42.580872
            ],
            [
              -96.49545,
              42.579474
            ],
            [
              -96.491402,
              42.577023
            ],
            [
              -96.486606,
              42.576062
            ],
            [
              -96.485796,
              42.575001
            ],
            [
              -96.485937,
              42.573524
            ],
            [
              -96.486855,
              42.572198
            ],
            [
              -96.489328,
              42.5708
            ],
            [
              -96.498997,
              42.560832
            ],
            [
              -96.401133,
              42.561995
            ],
            [
              -96.233629,
              42.561421
            ],
            [
              -96.214085,
              42.561924
            ],
            [
              -95.978187,
              42.561085
            ],
            [
              -95.859949,
              42.56062
            ],
            [
              -95.859377,
              42.909097
            ],
            [
              -95.97737,
              42.909772
            ],
            [
              -96.537353873885,
              42.9087912967329
            ],
            [
              -96.536564,
              42.905656
            ],
            [
              -96.536007,
              42.900901
            ],
            [
              -96.528886,
              42.89795
            ],
            [
              -96.526563,
              42.893755
            ],
            [
              -96.526357,
              42.891852
            ],
            [
              -96.52774,
              42.890588
            ],
            [
              -96.534395,
              42.890659
            ],
            [
              -96.540116,
              42.889678
            ],
            [
              -96.540396,
              42.888877
            ],
            [
              -96.538438,
              42.886111
            ],
            [
              -96.537851,
              42.878475
            ],
            [
              -96.543908,
              42.874614
            ],
            [
              -96.546394,
              42.874464
            ],
            [
              -96.547327,
              42.87371
            ],
            [
              -96.549659,
              42.870281
            ],
            [
              -96.550469,
              42.863742
            ],
            [
              -96.550439,
              42.863171
            ],
            [
              -96.546556,
              42.857273
            ],
            [
              -96.544321,
              42.851282
            ],
            [
              -96.545502,
              42.849956
            ],
            [
              -96.550847,
              42.847648
            ],
            [
              -96.553772,
              42.847501
            ],
            [
              -96.554709,
              42.846142
            ],
            [
              -96.554203,
              42.843648
            ],
            [
              -96.552184,
              42.841864
            ],
            [
              -96.558584,
              42.839487
            ],
            [
              -96.560572,
              42.839373
            ],
            [
              -96.56284,
              42.836309
            ],
            [
              -96.571353,
              42.837155
            ],
            [
              -96.579772,
              42.838093
            ],
            [
              -96.581604,
              42.837521
            ],
            [
              -96.58238,
              42.833657
            ],
            [
              -96.577813,
              42.828719
            ],
            [
              -96.577937,
              42.827645
            ],
            [
              -96.584488,
              42.818979
            ],
            [
              -96.585699,
              42.818041
            ],
            [
              -96.591039,
              42.815365
            ],
            [
              -96.594983,
              42.815844
            ],
            [
              -96.596008,
              42.815044
            ],
            [
              -96.595664,
              42.810426
            ],
            [
              -96.592155,
              42.809924
            ],
            [
              -96.590913,
              42.808987
            ],
            [
              -96.590757,
              42.808255
            ],
            [
              -96.592493,
              42.801122
            ],
            [
              -96.595283,
              42.792982
            ],
            [
              -96.602575,
              42.787767
            ],
            [
              -96.603784,
              42.78372
            ],
            [
              -96.604559,
              42.783034
            ],
            [
              -96.615579,
              42.784996
            ],
            [
              -96.61949,
              42.784034
            ],
            [
              -96.621875,
              42.779255
            ],
            [
              -96.626406,
              42.773518
            ],
            [
              -96.630311,
              42.770885
            ],
            [
              -96.632142,
              42.770863
            ],
            [
              -96.633168,
              42.768325
            ],
            [
              -96.632212,
              42.761512
            ],
            [
              -96.628741,
              42.757532
            ],
            [
              -96.630485,
              42.750378
            ],
            [
              -96.632314,
              42.745641
            ],
            [
              -96.635886,
              42.741002
            ],
            [
              -96.639704,
              42.737071
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "007",
      "COUNTYNS": "00465193",
      "AFFGEOID": "0500000US19007",
      "GEOID": "19007",
      "NAME": "Appanoose",
      "LSAD": "06",
      "ALAND": 1287982562,
      "AWATER": 49084378,
      "County_state": "Appanoose,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.097595,
              40.898451
            ],
            [
              -93.097296,
              40.584014
            ],
            [
              -93.085517,
              40.584403
            ],
            [
              -92.957747,
              40.58743
            ],
            [
              -92.941595,
              40.587743
            ],
            [
              -92.903544,
              40.58786
            ],
            [
              -92.889796,
              40.588039
            ],
            [
              -92.879178,
              40.588341
            ],
            [
              -92.863034,
              40.588175
            ],
            [
              -92.857391,
              40.58836
            ],
            [
              -92.835074,
              40.588484
            ],
            [
              -92.827992,
              40.588515
            ],
            [
              -92.828061,
              40.588593
            ],
            [
              -92.757407,
              40.588908
            ],
            [
              -92.742232,
              40.589207
            ],
            [
              -92.7145977730885,
              40.5895641799538
            ],
            [
              -92.689854,
              40.589884
            ],
            [
              -92.686693,
              40.589809
            ],
            [
              -92.639223,
              40.590825
            ],
            [
              -92.637898,
              40.590853
            ],
            [
              -92.639091,
              40.898886
            ],
            [
              -93.097595,
              40.898451
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "061",
      "COUNTYNS": "00465219",
      "AFFGEOID": "0500000US19061",
      "GEOID": "19061",
      "NAME": "Dubuque",
      "LSAD": "06",
      "ALAND": 1575488090,
      "AWATER": 21138790,
      "County_state": "Dubuque,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.132766,
              42.645844
            ],
            [
              -91.134195,
              42.471269
            ],
            [
              -91.130496,
              42.382639
            ],
            [
              -91.130079,
              42.295761
            ],
            [
              -90.89802,
              42.295236
            ],
            [
              -90.665551,
              42.294699
            ],
            [
              -90.665029,
              42.382155
            ],
            [
              -90.47381216765301,
              42.38145820989109
            ],
            [
              -90.474121,
              42.381729
            ],
            [
              -90.477279,
              42.383794
            ],
            [
              -90.480148,
              42.384616
            ],
            [
              -90.484621,
              42.38453
            ],
            [
              -90.487154,
              42.385141
            ],
            [
              -90.490334,
              42.387093
            ],
            [
              -90.495766,
              42.392406
            ],
            [
              -90.500128,
              42.395539
            ],
            [
              -90.506829,
              42.398792
            ],
            [
              -90.517516,
              42.403019
            ],
            [
              -90.548068,
              42.413115
            ],
            [
              -90.555018,
              42.416138
            ],
            [
              -90.55755,
              42.419258
            ],
            [
              -90.558168,
              42.420984
            ],
            [
              -90.558801,
              42.428517
            ],
            [
              -90.560439,
              42.432897
            ],
            [
              -90.565248,
              42.438742
            ],
            [
              -90.567968,
              42.440389
            ],
            [
              -90.570736,
              42.441701
            ],
            [
              -90.582128,
              42.444437
            ],
            [
              -90.590416,
              42.447493
            ],
            [
              -90.606328,
              42.451505
            ],
            [
              -90.624328,
              42.458904
            ],
            [
              -90.64090030956581,
              42.468522287618
            ],
            [
              -90.646727,
              42.471904
            ],
            [
              -90.654027,
              42.478503
            ],
            [
              -90.656327,
              42.483603
            ],
            [
              -90.656527,
              42.489203
            ],
            [
              -90.6559328003026,
              42.4916788320726
            ],
            [
              -90.655927,
              42.491703
            ],
            [
              -90.648627,
              42.498102
            ],
            [
              -90.642843,
              42.508481
            ],
            [
              -90.636927,
              42.513202
            ],
            [
              -90.636727,
              42.518702
            ],
            [
              -90.640627,
              42.527701
            ],
            [
              -90.643927,
              42.540401
            ],
            [
              -90.645627,
              42.5441
            ],
            [
              -90.654127,
              42.5499
            ],
            [
              -90.659127,
              42.5579
            ],
            [
              -90.661527,
              42.567999
            ],
            [
              -90.672727,
              42.576599
            ],
            [
              -90.677055,
              42.579215
            ],
            [
              -90.679375,
              42.581503
            ],
            [
              -90.685487,
              42.589614
            ],
            [
              -90.686975,
              42.591774
            ],
            [
              -90.687775,
              42.594606
            ],
            [
              -90.687999,
              42.599198
            ],
            [
              -90.692031,
              42.610366
            ],
            [
              -90.693999,
              42.614509
            ],
            [
              -90.700095,
              42.622461
            ],
            [
              -90.700856,
              42.626445
            ],
            [
              -90.702671,
              42.630756
            ],
            [
              -90.706303,
              42.634169
            ],
            [
              -90.709204,
              42.636078
            ],
            [
              -90.720209,
              42.640758
            ],
            [
              -90.731132,
              42.643437
            ],
            [
              -90.743677,
              42.64556
            ],
            [
              -90.760389,
              42.649131
            ],
            [
              -90.769495,
              42.651443
            ],
            [
              -90.778752,
              42.652965
            ],
            [
              -90.788226,
              42.653888
            ],
            [
              -90.797017,
              42.655772
            ],
            [
              -90.832702,
              42.661662
            ],
            [
              -90.84391,
              42.663071
            ],
            [
              -90.852497,
              42.664822
            ],
            [
              -90.867125,
              42.668728
            ],
            [
              -90.88743,
              42.67247
            ],
            [
              -90.8968978644181,
              42.6752621751195
            ],
            [
              -90.897415,
              42.645714
            ],
            [
              -91.132766,
              42.645844
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "071",
      "COUNTYNS": "00465224",
      "AFFGEOID": "0500000US19071",
      "GEOID": "19071",
      "NAME": "Fremont",
      "LSAD": "06",
      "ALAND": 1323807065,
      "AWATER": 14280096,
      "County_state": "Fremont,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.888697,
              40.736292
            ],
            [
              -95.888907,
              40.731855
            ],
            [
              -95.885349,
              40.721093
            ],
            [
              -95.883178,
              40.717579
            ],
            [
              -95.877015,
              40.714287
            ],
            [
              -95.87528,
              40.71412
            ],
            [
              -95.870481,
              40.71248
            ],
            [
              -95.859378,
              40.708055
            ],
            [
              -95.852615,
              40.702262
            ],
            [
              -95.849828,
              40.698147
            ],
            [
              -95.847931,
              40.694197
            ],
            [
              -95.846034,
              40.682605
            ],
            [
              -95.844827,
              40.679867
            ],
            [
              -95.842801,
              40.677496
            ],
            [
              -95.832177,
              40.6712
            ],
            [
              -95.8308293305508,
              40.670623923681
            ],
            [
              -95.822913,
              40.66724
            ],
            [
              -95.81415,
              40.66557
            ],
            [
              -95.804307,
              40.664886
            ],
            [
              -95.795489,
              40.662384
            ],
            [
              -95.789485,
              40.659388
            ],
            [
              -95.786568,
              40.657253
            ],
            [
              -95.781909,
              40.653272
            ],
            [
              -95.776251,
              40.647463
            ],
            [
              -95.772832,
              40.642496
            ],
            [
              -95.771325,
              40.639393
            ],
            [
              -95.770442,
              40.635285
            ],
            [
              -95.770083,
              40.624425
            ],
            [
              -95.768926,
              40.621264
            ],
            [
              -95.766823,
              40.61878
            ],
            [
              -95.764412,
              40.61709
            ],
            [
              -95.758045,
              40.613759
            ],
            [
              -95.751271,
              40.609057
            ],
            [
              -95.749685,
              40.606842
            ],
            [
              -95.748626,
              40.603355
            ],
            [
              -95.748858,
              40.599965
            ],
            [
              -95.750053,
              40.597052
            ],
            [
              -95.753148,
              40.59284
            ],
            [
              -95.758895,
              40.588973
            ],
            [
              -95.765645,
              40.585208
            ],
            [
              -95.746443,
              40.584935
            ],
            [
              -95.6875,
              40.584381
            ],
            [
              -95.687442,
              40.58438
            ],
            [
              -95.64184,
              40.584234
            ],
            [
              -95.611069,
              40.583495
            ],
            [
              -95.574046,
              40.582963
            ],
            [
              -95.554959,
              40.582629
            ],
            [
              -95.533182,
              40.582249
            ],
            [
              -95.526682,
              40.582136
            ],
            [
              -95.525392,
              40.58209
            ],
            [
              -95.469319,
              40.58154
            ],
            [
              -95.415406,
              40.581014
            ],
            [
              -95.37392302013079,
              40.580501370976
            ],
            [
              -95.373883,
              40.655527
            ],
            [
              -95.370618,
              40.728241
            ],
            [
              -95.385254,
              40.728378
            ],
            [
              -95.384964,
              40.90155
            ],
            [
              -95.704299,
              40.901045
            ],
            [
              -95.8134577460103,
              40.901693097976
            ],
            [
              -95.810886,
              40.897907
            ],
            [
              -95.809775,
              40.895447
            ],
            [
              -95.809474,
              40.891228
            ],
            [
              -95.810709,
              40.886681
            ],
            [
              -95.812083,
              40.884239
            ],
            [
              -95.815933,
              40.879846
            ],
            [
              -95.81959,
              40.877439
            ],
            [
              -95.824989,
              40.875
            ],
            [
              -95.838735,
              40.872191
            ],
            [
              -95.842521,
              40.870266
            ],
            [
              -95.844073,
              40.869248
            ],
            [
              -95.846938,
              40.865745
            ],
            [
              -95.847785,
              40.864328
            ],
            [
              -95.84859,
              40.861061
            ],
            [
              -95.84849,
              40.858607
            ],
            [
              -95.847084,
              40.854174
            ],
            [
              -95.841309,
              40.845604
            ],
            [
              -95.837186,
              40.835347
            ],
            [
              -95.837303,
              40.831164
            ],
            [
              -95.838601,
              40.826175
            ],
            [
              -95.843921,
              40.817686
            ],
            [
              -95.844852,
              40.815307
            ],
            [
              -95.845342,
              40.811324
            ],
            [
              -95.843745,
              40.803783
            ],
            [
              -95.835815,
              40.79063
            ],
            [
              -95.834523,
              40.787778
            ],
            [
              -95.8342151936933,
              40.7837840663969
            ],
            [
              -95.834156,
              40.783016
            ],
            [
              -95.835232,
              40.779151
            ],
            [
              -95.836903,
              40.776477
            ],
            [
              -95.838879,
              40.774545
            ],
            [
              -95.842824,
              40.771093
            ],
            [
              -95.84662,
              40.768619
            ],
            [
              -95.852776,
              40.765631
            ],
            [
              -95.861695,
              40.762871
            ],
            [
              -95.869982,
              40.759645
            ],
            [
              -95.873335,
              40.757616
            ],
            [
              -95.879027,
              40.753081
            ],
            [
              -95.883643,
              40.747831
            ],
            [
              -95.88669,
              40.742101
            ],
            [
              -95.888697,
              40.736292
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "053",
      "COUNTYNS": "00465215",
      "AFFGEOID": "0500000US19053",
      "GEOID": "19053",
      "NAME": "Decatur",
      "LSAD": "06",
      "ALAND": 1377567314,
      "AWATER": 4140764,
      "County_state": "Decatur,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.014803,
              40.897031
            ],
            [
              -94.015492,
              40.573914
            ],
            [
              -93.976766,
              40.574635
            ],
            [
              -93.963863,
              40.574754
            ],
            [
              -93.939857,
              40.575192
            ],
            [
              -93.938627,
              40.575284
            ],
            [
              -93.937097,
              40.575421
            ],
            [
              -93.936317,
              40.575284
            ],
            [
              -93.935687,
              40.57533
            ],
            [
              -93.913961,
              40.575672
            ],
            [
              -93.900877,
              40.575874
            ],
            [
              -93.899317,
              40.575942
            ],
            [
              -93.898327,
              40.576011
            ],
            [
              -93.853656,
              40.576606
            ],
            [
              -93.84093,
              40.576791
            ],
            [
              -93.818725,
              40.577086
            ],
            [
              -93.815485,
              40.577278
            ],
            [
              -93.7743442708352,
              40.5775843690664
            ],
            [
              -93.770231,
              40.577615
            ],
            [
              -93.750223,
              40.57772
            ],
            [
              -93.742759,
              40.577518
            ],
            [
              -93.737259,
              40.577542
            ],
            [
              -93.728355,
              40.577547
            ],
            [
              -93.722443,
              40.577641
            ],
            [
              -93.690333,
              40.577875
            ],
            [
              -93.677099,
              40.578127
            ],
            [
              -93.668845,
              40.578241
            ],
            [
              -93.661913,
              40.578354
            ],
            [
              -93.659272,
              40.57833
            ],
            [
              -93.656211,
              40.578352
            ],
            [
              -93.597352,
              40.579496
            ],
            [
              -93.566189,
              40.580117
            ],
            [
              -93.56581,
              40.580075
            ],
            [
              -93.56524,
              40.580143
            ],
            [
              -93.560798,
              40.580304
            ],
            [
              -93.558938,
              40.580189
            ],
            [
              -93.556899,
              40.580235
            ],
            [
              -93.55654,
              40.898295
            ],
            [
              -93.904967,
              40.89667
            ],
            [
              -94.014803,
              40.897031
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "191",
      "COUNTYNS": "00465284",
      "AFFGEOID": "0500000US19191",
      "GEOID": "19191",
      "NAME": "Winneshiek",
      "LSAD": "06",
      "ALAND": 1786713778,
      "AWATER": 698598,
      "County_state": "Winneshiek,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.080976,
              43.212947
            ],
            [
              -92.081146,
              43.082794
            ],
            [
              -91.605307,
              43.081653
            ],
            [
              -91.610885,
              43.342137
            ],
            [
              -91.6108315885754,
              43.5005300191943
            ],
            [
              -91.610895,
              43.50053
            ],
            [
              -91.615293,
              43.50055
            ],
            [
              -91.616895,
              43.500663
            ],
            [
              -91.617407,
              43.500687
            ],
            [
              -91.620785,
              43.500677
            ],
            [
              -91.625611,
              43.500727
            ],
            [
              -91.634244,
              43.500479
            ],
            [
              -91.634495,
              43.500439
            ],
            [
              -91.635626,
              43.500463
            ],
            [
              -91.639772,
              43.500573
            ],
            [
              -91.644924,
              43.500529
            ],
            [
              -91.651396,
              43.500454
            ],
            [
              -91.658401,
              43.500533
            ],
            [
              -91.670872,
              43.500513
            ],
            [
              -91.700749,
              43.500581
            ],
            [
              -91.7302174228592,
              43.5006795266418
            ],
            [
              -91.730359,
              43.50068
            ],
            [
              -91.73333,
              43.500623
            ],
            [
              -91.736558,
              43.500561
            ],
            [
              -91.738446,
              43.500525
            ],
            [
              -91.761414,
              43.500637
            ],
            [
              -91.777688,
              43.500711
            ],
            [
              -91.77929,
              43.500803
            ],
            [
              -91.804925,
              43.500716
            ],
            [
              -91.807156,
              43.500648
            ],
            [
              -91.824848,
              43.500684
            ],
            [
              -91.850940149364,
              43.500655005997
            ],
            [
              -91.941837,
              43.500554
            ],
            [
              -91.949879,
              43.500485
            ],
            [
              -92.0798019678545,
              43.5006468106538
            ],
            [
              -92.080955,
              43.255619
            ],
            [
              -92.080976,
              43.212947
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "041",
      "COUNTYNS": "00465625",
      "AFFGEOID": "0500000US19041",
      "GEOID": "19041",
      "NAME": "Clay",
      "LSAD": "06",
      "ALAND": 1469139466,
      "AWATER": 13866649,
      "County_state": "Clay,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.388078,
              43.255221
            ],
            [
              -95.388182,
              42.909897
            ],
            [
              -94.91389,
              42.9097
            ],
            [
              -94.913723,
              43.255054
            ],
            [
              -95.388078,
              43.255221
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "103",
      "COUNTYNS": "00465240",
      "AFFGEOID": "0500000US19103",
      "GEOID": "19103",
      "NAME": "Johnson",
      "LSAD": "06",
      "ALAND": 1590377745,
      "AWATER": 23572945,
      "County_state": "Johnson,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.831379,
              41.861851
            ],
            [
              -91.82706,
              41.600452
            ],
            [
              -91.829231,
              41.511457
            ],
            [
              -91.519828,
              41.51101
            ],
            [
              -91.518579,
              41.497273
            ],
            [
              -91.506494,
              41.500751
            ],
            [
              -91.502103,
              41.47745
            ],
            [
              -91.516012,
              41.469189
            ],
            [
              -91.499639,
              41.459767
            ],
            [
              -91.500698,
              41.446675
            ],
            [
              -91.483995,
              41.423848
            ],
            [
              -91.368521,
              41.423178
            ],
            [
              -91.366448,
              41.598373
            ],
            [
              -91.366079,
              41.860068
            ],
            [
              -91.481921,
              41.859893
            ],
            [
              -91.597895,
              41.860726
            ],
            [
              -91.831379,
              41.861851
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "117",
      "COUNTYNS": "00465247",
      "AFFGEOID": "0500000US19117",
      "GEOID": "19117",
      "NAME": "Lucas",
      "LSAD": "06",
      "ALAND": 1115257644,
      "AWATER": 9852833,
      "County_state": "Lucas,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.557556,
              41.161271
            ],
            [
              -93.55654,
              40.898295
            ],
            [
              -93.327417,
              40.897884
            ],
            [
              -93.097595,
              40.898451
            ],
            [
              -93.099217,
              41.160867
            ],
            [
              -93.327886,
              41.160659
            ],
            [
              -93.441764,
              41.160537
            ],
            [
              -93.557556,
              41.161271
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "135",
      "COUNTYNS": "00465256",
      "AFFGEOID": "0500000US19135",
      "GEOID": "19135",
      "NAME": "Monroe",
      "LSAD": "06",
      "ALAND": 1123317229,
      "AWATER": 1522790,
      "County_state": "Monroe,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.099217,
              41.160867
            ],
            [
              -93.097595,
              40.898451
            ],
            [
              -92.639091,
              40.898886
            ],
            [
              -92.640361,
              41.161298
            ],
            [
              -92.869771,
              41.161066
            ],
            [
              -93.099217,
              41.160867
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "095",
      "COUNTYNS": "00465236",
      "AFFGEOID": "0500000US19095",
      "GEOID": "19095",
      "NAME": "Iowa",
      "LSAD": "06",
      "ALAND": 1518916008,
      "AWATER": 2686847,
      "County_state": "Iowa,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.298164,
              41.862779
            ],
            [
              -92.299191,
              41.776094
            ],
            [
              -92.297494,
              41.50979
            ],
            [
              -91.946043,
              41.510749
            ],
            [
              -91.829231,
              41.511457
            ],
            [
              -91.82706,
              41.600452
            ],
            [
              -91.831379,
              41.861851
            ],
            [
              -91.947732,
              41.861856
            ],
            [
              -92.298164,
              41.862779
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "011",
      "COUNTYNS": "00465195",
      "AFFGEOID": "0500000US19011",
      "GEOID": "19011",
      "NAME": "Benton",
      "LSAD": "06",
      "ALAND": 1855117319,
      "AWATER": 5760770,
      "County_state": "Benton,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.298792,
              42.297527
            ],
            [
              -92.298164,
              41.862779
            ],
            [
              -91.947732,
              41.861856
            ],
            [
              -91.831379,
              41.861851
            ],
            [
              -91.833289,
              41.949373
            ],
            [
              -91.835262,
              42.036954
            ],
            [
              -91.831089,
              42.299063
            ],
            [
              -91.948106,
              42.29749
            ],
            [
              -92.064766,
              42.297259
            ],
            [
              -92.298792,
              42.297527
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "109",
      "COUNTYNS": "00465243",
      "AFFGEOID": "0500000US19109",
      "GEOID": "19109",
      "NAME": "Kossuth",
      "LSAD": "06",
      "ALAND": 2519361813,
      "AWATER": 4125592,
      "County_state": "Kossuth,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.4428478576855,
              43.5005831709068
            ],
            [
              -94.443137,
              43.255014
            ],
            [
              -94.442751,
              43.168885
            ],
            [
              -94.442954,
              42.908073
            ],
            [
              -93.971238,
              42.907762
            ],
            [
              -93.970415,
              43.255358
            ],
            [
              -93.970693,
              43.342245
            ],
            [
              -93.97076155853709,
              43.49960500889429
            ],
            [
              -94.092894,
              43.500302
            ],
            [
              -94.094339,
              43.500302
            ],
            [
              -94.108068,
              43.5003
            ],
            [
              -94.10988,
              43.500283
            ],
            [
              -94.2479653766318,
              43.5003325399466
            ],
            [
              -94.377466,
              43.500379
            ],
            [
              -94.390597,
              43.500469
            ],
            [
              -94.442835,
              43.500583
            ],
            [
              -94.4428478576855,
              43.5005831709068
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "163",
      "COUNTYNS": "00465270",
      "AFFGEOID": "0500000US19163",
      "GEOID": "19163",
      "NAME": "Scott",
      "LSAD": "06",
      "ALAND": 1186437866,
      "AWATER": 26222175,
      "County_state": "Scott,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.898373,
              41.771392
            ],
            [
              -90.899853,
              41.597133
            ],
            [
              -90.783812,
              41.596531
            ],
            [
              -90.785999,
              41.46575
            ],
            [
              -90.786282,
              41.452888
            ],
            [
              -90.777583,
              41.451261
            ],
            [
              -90.771672,
              41.450761
            ],
            [
              -90.750142,
              41.449632
            ],
            [
              -90.737537,
              41.450127
            ],
            [
              -90.723545,
              41.452248
            ],
            [
              -90.701159,
              41.454743
            ],
            [
              -90.690951,
              41.456643
            ],
            [
              -90.676439,
              41.460832
            ],
            [
              -90.666239,
              41.460632
            ],
            [
              -90.655839,
              41.462132
            ],
            [
              -90.650238,
              41.465032
            ],
            [
              -90.640238,
              41.473332
            ],
            [
              -90.632538,
              41.478732
            ],
            [
              -90.618537,
              41.485032
            ],
            [
              -90.605937,
              41.494232
            ],
            [
              -90.604237,
              41.497032
            ],
            [
              -90.602137,
              41.506032
            ],
            [
              -90.595237,
              41.511032
            ],
            [
              -90.591037,
              41.512832
            ],
            [
              -90.582036,
              41.515132
            ],
            [
              -90.571136,
              41.516332
            ],
            [
              -90.567236,
              41.517532
            ],
            [
              -90.556235,
              41.524232
            ],
            [
              -90.5455141325201,
              41.525564050266595
            ],
            [
              -90.540935,
              41.526133
            ],
            [
              -90.533035,
              41.524933
            ],
            [
              -90.513134,
              41.519533
            ],
            [
              -90.500633,
              41.518033
            ],
            [
              -90.499851992491,
              41.518047598271195
            ],
            [
              -90.489933,
              41.518233
            ],
            [
              -90.474332,
              41.519733
            ],
            [
              -90.461432,
              41.523533
            ],
            [
              -90.445231,
              41.536133
            ],
            [
              -90.438431,
              41.544133
            ],
            [
              -90.432731,
              41.549533
            ],
            [
              -90.427231,
              41.551533
            ],
            [
              -90.42516586823469,
              41.5526479481636
            ],
            [
              -90.42223,
              41.554233
            ],
            [
              -90.41583,
              41.562933
            ],
            [
              -90.41283,
              41.565333
            ],
            [
              -90.40573,
              41.56862091946309
            ],
            [
              -90.39793,
              41.572233
            ],
            [
              -90.381329,
              41.576633
            ],
            [
              -90.364128,
              41.579633
            ],
            [
              -90.343228,
              41.587833
            ],
            [
              -90.341528,
              41.590633
            ],
            [
              -90.339528,
              41.598633
            ],
            [
              -90.34333,
              41.640855
            ],
            [
              -90.343452,
              41.646959
            ],
            [
              -90.336729,
              41.664532
            ],
            [
              -90.334525,
              41.679559
            ],
            [
              -90.332481,
              41.682146
            ],
            [
              -90.330222,
              41.683954
            ],
            [
              -90.319924,
              41.689721
            ],
            [
              -90.317315,
              41.69167
            ],
            [
              -90.314687,
              41.69483
            ],
            [
              -90.313435,
              41.698082
            ],
            [
              -90.31277,
              41.702426
            ],
            [
              -90.312893,
              41.707528
            ],
            [
              -90.31332,
              41.709494
            ],
            [
              -90.317421,
              41.718333
            ],
            [
              -90.317668,
              41.72269
            ],
            [
              -90.31635774707429,
              41.728884798759104
            ],
            [
              -90.333874,
              41.739404
            ],
            [
              -90.364178,
              41.727803
            ],
            [
              -90.389642,
              41.736948
            ],
            [
              -90.407931,
              41.729296
            ],
            [
              -90.41168,
              41.740638
            ],
            [
              -90.424809,
              41.736113
            ],
            [
              -90.424201,
              41.750559
            ],
            [
              -90.451379,
              41.76446
            ],
            [
              -90.458462,
              41.758263
            ],
            [
              -90.471723,
              41.773603
            ],
            [
              -90.495786,
              41.765887
            ],
            [
              -90.537616,
              41.776395
            ],
            [
              -90.542816,
              41.765919
            ],
            [
              -90.579131,
              41.759129
            ],
            [
              -90.59242,
              41.770639
            ],
            [
              -90.616353,
              41.756465
            ],
            [
              -90.630679,
              41.765354
            ],
            [
              -90.651454,
              41.755416
            ],
            [
              -90.657436,
              41.764084
            ],
            [
              -90.676373,
              41.761023
            ],
            [
              -90.693059,
              41.742963
            ],
            [
              -90.713902,
              41.734578
            ],
            [
              -90.753034,
              41.748998
            ],
            [
              -90.761883,
              41.762815
            ],
            [
              -90.782174,
              41.768008
            ],
            [
              -90.786212,
              41.771369
            ],
            [
              -90.898373,
              41.771392
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "085",
      "COUNTYNS": "00465231",
      "AFFGEOID": "0500000US19085",
      "GEOID": "19085",
      "NAME": "Harrison",
      "LSAD": "06",
      "ALAND": 1804808356,
      "AWATER": 12417698,
      "County_state": "Harrison,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.1390095052264,
              41.8663010080218
            ],
            [
              -96.135253,
              41.863128
            ],
            [
              -96.13062,
              41.860809
            ],
            [
              -96.123215,
              41.85858
            ],
            [
              -96.116202,
              41.854869
            ],
            [
              -96.113962,
              41.853102
            ],
            [
              -96.110246,
              41.84885
            ],
            [
              -96.108029,
              41.844397
            ],
            [
              -96.107911,
              41.840339
            ],
            [
              -96.110907,
              41.830818
            ],
            [
              -96.11081,
              41.828172
            ],
            [
              -96.109347,
              41.823735
            ],
            [
              -96.107592,
              41.820685
            ],
            [
              -96.103749,
              41.817151
            ],
            [
              -96.09827,
              41.814206
            ],
            [
              -96.093835,
              41.812785
            ],
            [
              -96.081026,
              41.810144
            ],
            [
              -96.075548,
              41.807811
            ],
            [
              -96.069662,
              41.803509
            ],
            [
              -96.067329,
              41.800628
            ],
            [
              -96.064879,
              41.79623
            ],
            [
              -96.064537,
              41.793002
            ],
            [
              -96.066413,
              41.788913
            ],
            [
              -96.073197,
              41.783009
            ],
            [
              -96.077543,
              41.777824
            ],
            [
              -96.078939,
              41.771353
            ],
            [
              -96.0783,
              41.761598
            ],
            [
              -96.079915,
              41.757895
            ],
            [
              -96.084673,
              41.753314
            ],
            [
              -96.091687,
              41.750419
            ],
            [
              -96.097511,
              41.749076
            ],
            [
              -96.102772,
              41.746339
            ],
            [
              -96.104622,
              41.744211
            ],
            [
              -96.106425,
              41.73789
            ],
            [
              -96.106326,
              41.734591
            ],
            [
              -96.105582,
              41.731647
            ],
            [
              -96.10261,
              41.728016
            ],
            [
              -96.0876,
              41.72218
            ],
            [
              -96.079682,
              41.717962
            ],
            [
              -96.075151,
              41.713265
            ],
            [
              -96.073376,
              41.710674
            ],
            [
              -96.072494,
              41.708794
            ],
            [
              -96.072321,
              41.706858
            ],
            [
              -96.073063,
              41.705004
            ],
            [
              -96.075955,
              41.701661
            ],
            [
              -96.082429,
              41.698159
            ],
            [
              -96.090579,
              41.697425
            ],
            [
              -96.105119,
              41.699917
            ],
            [
              -96.111968,
              41.697773
            ],
            [
              -96.117751,
              41.694221
            ],
            [
              -96.120157,
              41.69115
            ],
            [
              -96.121401,
              41.688522
            ],
            [
              -96.12171195568601,
              41.6829898591487
            ],
            [
              -96.121726,
              41.68274
            ],
            [
              -96.120983,
              41.677861
            ],
            [
              -96.11812,
              41.674399
            ],
            [
              -96.114978,
              41.67122
            ],
            [
              -96.111483,
              41.668548
            ],
            [
              -96.099837,
              41.66103
            ],
            [
              -96.097933,
              41.658682
            ],
            [
              -96.095415,
              41.652736
            ],
            [
              -96.095046,
              41.647365
            ],
            [
              -96.097728,
              41.639633
            ],
            [
              -96.100701,
              41.635507
            ],
            [
              -96.114146,
              41.623975
            ],
            [
              -96.116233,
              41.621574
            ],
            [
              -96.11795,
              41.617356
            ],
            [
              -96.118105,
              41.613495
            ],
            [
              -96.117558,
              41.609999
            ],
            [
              -96.11583,
              41.60576
            ],
            [
              -96.113833,
              41.602277
            ],
            [
              -96.109387,
              41.596871
            ],
            [
              -96.104465,
              41.593169
            ],
            [
              -96.101496,
              41.59158
            ],
            [
              -96.088683,
              41.58752
            ],
            [
              -96.085771,
              41.585746
            ],
            [
              -96.083417,
              41.583339
            ],
            [
              -96.081843,
              41.580407
            ],
            [
              -96.081152,
              41.577289
            ],
            [
              -96.081178,
              41.574274
            ],
            [
              -96.082406,
              41.571229
            ],
            [
              -96.084786,
              41.567831
            ],
            [
              -96.09182,
              41.561086
            ],
            [
              -96.093613,
              41.558271
            ],
            [
              -96.095851,
              41.55088
            ],
            [
              -96.096186,
              41.547192
            ],
            [
              -96.09409,
              41.539265
            ],
            [
              -96.089714,
              41.531778
            ],
            [
              -96.08822,
              41.530595
            ],
            [
              -96.080493,
              41.528199
            ],
            [
              -96.07307,
              41.525052
            ],
            [
              -96.067527,
              41.52034
            ],
            [
              -96.063638,
              41.516162
            ],
            [
              -96.057935,
              41.51149
            ],
            [
              -96.05369,
              41.508859
            ],
            [
              -96.048118,
              41.507271
            ],
            [
              -96.040701,
              41.507076
            ],
            [
              -96.038101,
              41.50799
            ],
            [
              -96.036603,
              41.509047
            ],
            [
              -96.034305,
              41.512853
            ],
            [
              -96.030593,
              41.527292
            ],
            [
              -96.028439,
              41.539616
            ],
            [
              -96.027289,
              41.541081
            ],
            [
              -96.023182,
              41.544364
            ],
            [
              -96.019686,
              41.545743
            ],
            [
              -96.016474,
              41.546085
            ],
            [
              -96.010028,
              41.545533
            ],
            [
              -96.005079,
              41.544004
            ],
            [
              -96.001161,
              41.541146
            ],
            [
              -95.999529,
              41.538679
            ],
            [
              -95.993891,
              41.523412
            ],
            [
              -95.99267,
              41.51729
            ],
            [
              -95.992599,
              41.514174
            ],
            [
              -95.992833,
              41.512002
            ],
            [
              -95.993943,
              41.509761
            ],
            [
              -95.99617450065989,
              41.50695922694919
            ],
            [
              -95.823054,
              41.506565
            ],
            [
              -95.497997,
              41.506074
            ],
            [
              -95.497793,
              41.602333
            ],
            [
              -95.558143,
              41.602383
            ],
            [
              -95.557277,
              41.863449
            ],
            [
              -95.672771,
              41.863147
            ],
            [
              -96.1390095052264,
              41.8663010080218
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "115",
      "COUNTYNS": "00465246",
      "AFFGEOID": "0500000US19115",
      "GEOID": "19115",
      "NAME": "Louisa",
      "LSAD": "06",
      "ALAND": 1040583090,
      "AWATER": 40784329,
      "County_state": "Louisa,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.48537,
              41.249646
            ],
            [
              -91.485717,
              41.162405
            ],
            [
              -91.370298,
              41.161247
            ],
            [
              -91.37071,
              41.074112
            ],
            [
              -90.9493828838387,
              41.0727100123937
            ],
            [
              -90.949383,
              41.072711
            ],
            [
              -90.948207,
              41.084413
            ],
            [
              -90.946259,
              41.094734
            ],
            [
              -90.946627,
              41.096632
            ],
            [
              -90.957246,
              41.111085
            ],
            [
              -90.965905,
              41.119559
            ],
            [
              -90.970851,
              41.130107
            ],
            [
              -90.981311,
              41.142659
            ],
            [
              -90.989663,
              41.155716
            ],
            [
              -90.99496,
              41.160624
            ],
            [
              -90.997906,
              41.162564
            ],
            [
              -91.007586,
              41.166183
            ],
            [
              -91.012557,
              41.165922
            ],
            [
              -91.027214,
              41.163373
            ],
            [
              -91.030029,
              41.16354
            ],
            [
              -91.041536,
              41.166138
            ],
            [
              -91.055069,
              41.185766
            ],
            [
              -91.065899,
              41.199517
            ],
            [
              -91.07298,
              41.207151
            ],
            [
              -91.081445,
              41.214429
            ],
            [
              -91.093018,
              41.222635
            ],
            [
              -91.100829,
              41.230532
            ],
            [
              -91.109562,
              41.236567
            ],
            [
              -91.112333,
              41.239003
            ],
            [
              -91.113648,
              41.241401
            ],
            [
              -91.114186,
              41.250029
            ],
            [
              -91.110304,
              41.256088
            ],
            [
              -91.104462,
              41.262104
            ],
            [
              -91.101142,
              41.267169
            ],
            [
              -91.092034,
              41.286911
            ],
            [
              -91.08688,
              41.294371
            ],
            [
              -91.077505,
              41.301828
            ],
            [
              -91.074841,
              41.305578
            ],
            [
              -91.073233,
              41.31344
            ],
            [
              -91.0719555691705,
              41.33335834590849
            ],
            [
              -91.071940564762,
              41.333592302215294
            ],
            [
              -91.151787,
              41.334447
            ],
            [
              -91.369332,
              41.336063
            ],
            [
              -91.368521,
              41.423178
            ],
            [
              -91.483995,
              41.423848
            ],
            [
              -91.48537,
              41.249646
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "145",
      "COUNTYNS": "00465261",
      "AFFGEOID": "0500000US19145",
      "GEOID": "19145",
      "NAME": "Page",
      "LSAD": "06",
      "ALAND": 1385497544,
      "AWATER": 1359120,
      "County_state": "Page,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.384964,
              40.90155
            ],
            [
              -95.385254,
              40.728378
            ],
            [
              -95.370618,
              40.728241
            ],
            [
              -95.373883,
              40.655527
            ],
            [
              -95.37392302013079,
              40.580501370976
            ],
            [
              -95.373893,
              40.580501
            ],
            [
              -95.357802,
              40.5801
            ],
            [
              -95.335588,
              40.579871
            ],
            [
              -95.221525,
              40.578827
            ],
            [
              -95.218783,
              40.578781
            ],
            [
              -95.217455,
              40.578759
            ],
            [
              -95.213327,
              40.578689
            ],
            [
              -95.212715,
              40.578679
            ],
            [
              -95.21159,
              40.578654
            ],
            [
              -95.211408,
              40.57865
            ],
            [
              -95.2022644684083,
              40.578527764403404
            ],
            [
              -95.164058,
              40.578017
            ],
            [
              -95.154499,
              40.57786
            ],
            [
              -95.120829,
              40.577413
            ],
            [
              -95.112222,
              40.577228
            ],
            [
              -95.110663,
              40.577206
            ],
            [
              -95.110303,
              40.57716
            ],
            [
              -95.107213,
              40.577116
            ],
            [
              -95.097607,
              40.577168
            ],
            [
              -95.079742,
              40.577007
            ],
            [
              -95.068921,
              40.57688
            ],
            [
              -94.991661,
              40.575692
            ],
            [
              -94.966491,
              40.575839
            ],
            [
              -94.955134,
              40.575669
            ],
            [
              -94.914896,
              40.575068
            ],
            [
              -94.913124,
              40.727285
            ],
            [
              -94.928529,
              40.727399
            ],
            [
              -94.928459,
              40.900653
            ],
            [
              -95.384964,
              40.90155
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "043",
      "COUNTYNS": "00465210",
      "AFFGEOID": "0500000US19043",
      "GEOID": "19043",
      "NAME": "Clayton",
      "LSAD": "06",
      "ALAND": 2016295994,
      "AWATER": 36695627,
      "County_state": "Clayton,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.608423,
              42.81278
            ],
            [
              -91.607059,
              42.643973
            ],
            [
              -91.132766,
              42.645844
            ],
            [
              -90.897415,
              42.645714
            ],
            [
              -90.8968978644181,
              42.6752621751195
            ],
            [
              -90.900261,
              42.676254
            ],
            [
              -90.9134,
              42.682949
            ],
            [
              -90.921155,
              42.685406
            ],
            [
              -90.923634,
              42.6855
            ],
            [
              -90.929881,
              42.684128
            ],
            [
              -90.937045,
              42.683399
            ],
            [
              -90.941567,
              42.683844
            ],
            [
              -90.949213,
              42.685573
            ],
            [
              -90.952415,
              42.686778
            ],
            [
              -90.965048,
              42.693233
            ],
            [
              -90.974237,
              42.695249
            ],
            [
              -90.977735,
              42.696816
            ],
            [
              -90.980578,
              42.698932
            ],
            [
              -90.988776,
              42.708724
            ],
            [
              -90.995536,
              42.713704
            ],
            [
              -91.000128,
              42.716189
            ],
            [
              -91.009577,
              42.720123
            ],
            [
              -91.015687,
              42.719229
            ],
            [
              -91.017239,
              42.719566
            ],
            [
              -91.026786,
              42.724228
            ],
            [
              -91.029692,
              42.726774
            ],
            [
              -91.030718,
              42.729684
            ],
            [
              -91.030984,
              42.73255
            ],
            [
              -91.032013,
              42.734484
            ],
            [
              -91.035418,
              42.73734
            ],
            [
              -91.039383,
              42.738478
            ],
            [
              -91.044139,
              42.738605
            ],
            [
              -91.046571,
              42.737167
            ],
            [
              -91.049972,
              42.736905
            ],
            [
              -91.051275,
              42.737001
            ],
            [
              -91.053733,
              42.738238
            ],
            [
              -91.054801,
              42.740529
            ],
            [
              -91.05481,
              42.744686
            ],
            [
              -91.056297,
              42.747341
            ],
            [
              -91.058091,
              42.749246
            ],
            [
              -91.060172,
              42.750481
            ],
            [
              -91.06468,
              42.750914
            ],
            [
              -91.065783,
              42.753387
            ],
            [
              -91.065492,
              42.757081
            ],
            [
              -91.06312,
              42.757273
            ],
            [
              -91.061432,
              42.757974
            ],
            [
              -91.060129,
              42.759986
            ],
            [
              -91.060261,
              42.761847
            ],
            [
              -91.063254,
              42.763947
            ],
            [
              -91.069549,
              42.769628
            ],
            [
              -91.070716,
              42.775502
            ],
            [
              -91.071138,
              42.783004
            ],
            [
              -91.072447,
              42.787732
            ],
            [
              -91.075481,
              42.795466
            ],
            [
              -91.078097,
              42.806526
            ],
            [
              -91.079314,
              42.820309
            ],
            [
              -91.078665,
              42.827678
            ],
            [
              -91.08277,
              42.829977
            ],
            [
              -91.090136,
              42.829237
            ],
            [
              -91.09406,
              42.830813
            ],
            [
              -91.095114,
              42.834966
            ],
            [
              -91.091402,
              42.84986
            ],
            [
              -91.091837,
              42.851225
            ],
            [
              -91.095329,
              42.85532
            ],
            [
              -91.097656,
              42.859871
            ],
            [
              -91.09882,
              42.864421
            ],
            [
              -91.098238,
              42.875798
            ],
            [
              -91.100565,
              42.883078
            ],
            [
              -91.104051,
              42.885971
            ],
            [
              -91.112158,
              42.891149
            ],
            [
              -91.115512,
              42.894672
            ],
            [
              -91.117411,
              42.895837
            ],
            [
              -91.138,
              42.903772
            ],
            [
              -91.143375,
              42.90467
            ],
            [
              -91.144706,
              42.905964
            ],
            [
              -91.14556,
              42.90798
            ],
            [
              -91.146177,
              42.90985
            ],
            [
              -91.146182,
              42.912338
            ],
            [
              -91.145868,
              42.914967
            ],
            [
              -91.143878,
              42.920646
            ],
            [
              -91.1438,
              42.922877
            ],
            [
              -91.144315,
              42.926592
            ],
            [
              -91.145517,
              42.930378
            ],
            [
              -91.149784,
              42.940244
            ],
            [
              -91.14988,
              42.941955
            ],
            [
              -91.14909,
              42.946554
            ],
            [
              -91.14554,
              42.95651
            ],
            [
              -91.14543,
              42.958211
            ],
            [
              -91.14655,
              42.963345
            ],
            [
              -91.148001,
              42.966155
            ],
            [
              -91.150906,
              42.970514
            ],
            [
              -91.155519,
              42.975774
            ],
            [
              -91.156562,
              42.978226
            ],
            [
              -91.156743,
              42.98783
            ],
            [
              -91.1568126516435,
              42.9881698664533
            ],
            [
              -91.15749,
              42.991475
            ],
            [
              -91.168283,
              43.019426
            ],
            [
              -91.174692,
              43.038713
            ],
            [
              -91.178087,
              43.062044
            ],
            [
              -91.177894,
              43.064206
            ],
            [
              -91.179457,
              43.067427
            ],
            [
              -91.178761,
              43.070578
            ],
            [
              -91.177264,
              43.072983
            ],
            [
              -91.177222,
              43.080247
            ],
            [
              -91.605307,
              43.081653
            ],
            [
              -91.605736,
              42.993234
            ],
            [
              -91.607328,
              42.821222
            ],
            [
              -91.606597,
              42.818549
            ],
            [
              -91.608423,
              42.81278
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "091",
      "COUNTYNS": "00465234",
      "AFFGEOID": "0500000US19091",
      "GEOID": "19091",
      "NAME": "Humboldt",
      "LSAD": "06",
      "ALAND": 1124996886,
      "AWATER": 3376514,
      "County_state": "Humboldt,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.442954,
              42.908073
            ],
            [
              -94.443083,
              42.645164
            ],
            [
              -93.971714,
              42.644707
            ],
            [
              -93.971238,
              42.907762
            ],
            [
              -94.442954,
              42.908073
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "101",
      "COUNTYNS": "00465239",
      "AFFGEOID": "0500000US19101",
      "GEOID": "19101",
      "NAME": "Jefferson",
      "LSAD": "06",
      "ALAND": 1127999784,
      "AWATER": 3524824,
      "County_state": "Jefferson,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.179974,
              41.162662
            ],
            [
              -92.179072,
              40.89972
            ],
            [
              -91.832812,
              40.901163
            ],
            [
              -91.71818,
              40.901087
            ],
            [
              -91.716042,
              41.162808
            ],
            [
              -91.830335,
              41.161536
            ],
            [
              -91.945571,
              41.163578
            ],
            [
              -92.179974,
              41.162662
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "167",
      "COUNTYNS": "00465272",
      "AFFGEOID": "0500000US19167",
      "GEOID": "19167",
      "NAME": "Sioux",
      "LSAD": "06",
      "ALAND": 1988797652,
      "AWATER": 2885657,
      "County_state": "Sioux,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.571194,
              43.238961
            ],
            [
              -96.568505,
              43.231554
            ],
            [
              -96.56044,
              43.224219
            ],
            [
              -96.558995,
              43.224126
            ],
            [
              -96.557317,
              43.224778
            ],
            [
              -96.556313,
              43.226135
            ],
            [
              -96.554937,
              43.226775
            ],
            [
              -96.548184,
              43.226912
            ],
            [
              -96.544902,
              43.225928
            ],
            [
              -96.540088,
              43.225698
            ],
            [
              -96.535741,
              43.22764
            ],
            [
              -96.526865,
              43.224071
            ],
            [
              -96.522084,
              43.22096
            ],
            [
              -96.520961,
              43.21824
            ],
            [
              -96.519273,
              43.21769
            ],
            [
              -96.512458,
              43.218556
            ],
            [
              -96.500759,
              43.220767
            ],
            [
              -96.496454,
              43.223652
            ],
            [
              -96.485264,
              43.224183
            ],
            [
              -96.476697,
              43.222014
            ],
            [
              -96.475571,
              43.221054
            ],
            [
              -96.474912,
              43.217351
            ],
            [
              -96.472158,
              43.209534
            ],
            [
              -96.470626,
              43.207225
            ],
            [
              -96.470781,
              43.205099
            ],
            [
              -96.473777,
              43.198766
            ],
            [
              -96.473834,
              43.189804
            ],
            [
              -96.472395,
              43.185644
            ],
            [
              -96.468802,
              43.184525
            ],
            [
              -96.467146,
              43.184502
            ],
            [
              -96.465146,
              43.182971
            ],
            [
              -96.464896,
              43.182034
            ],
            [
              -96.467292,
              43.164066
            ],
            [
              -96.467384,
              43.159608
            ],
            [
              -96.466537,
              43.150281
            ],
            [
              -96.465099,
              43.147515
            ],
            [
              -96.459978,
              43.143516
            ],
            [
              -96.458854,
              43.143356
            ],
            [
              -96.455544,
              43.144157
            ],
            [
              -96.450361,
              43.142237
            ],
            [
              -96.443431,
              43.133825
            ],
            [
              -96.442711,
              43.128841
            ],
            [
              -96.441644,
              43.124687
            ],
            [
              -96.440801,
              43.123129
            ],
            [
              -96.439615,
              43.121963
            ],
            [
              -96.436589,
              43.120842
            ],
            [
              -96.439335,
              43.113916
            ],
            [
              -96.448134,
              43.104452
            ],
            [
              -96.451877,
              43.100474
            ],
            [
              -96.460516,
              43.09494
            ],
            [
              -96.462855,
              43.091419
            ],
            [
              -96.462636,
              43.089614
            ],
            [
              -96.455337,
              43.088129
            ],
            [
              -96.454526,
              43.086826
            ],
            [
              -96.454088,
              43.084197
            ],
            [
              -96.4541882587125,
              43.083379189414
            ],
            [
              -96.455209,
              43.075053
            ],
            [
              -96.458201,
              43.067554
            ],
            [
              -96.46085,
              43.064033
            ],
            [
              -96.463094,
              43.062981
            ],
            [
              -96.468207,
              43.06186
            ],
            [
              -96.469953,
              43.062088
            ],
            [
              -96.473165,
              43.06355
            ],
            [
              -96.476905,
              43.062383
            ],
            [
              -96.486722,
              43.055498
            ],
            [
              -96.488155,
              43.054013
            ],
            [
              -96.488839,
              43.051475
            ],
            [
              -96.490365,
              43.050789
            ],
            [
              -96.501748,
              43.048632
            ],
            [
              -96.505239,
              43.048726
            ],
            [
              -96.508916,
              43.049985
            ],
            [
              -96.510256,
              43.049917
            ],
            [
              -96.518431,
              43.042068
            ],
            [
              -96.517319,
              43.040247
            ],
            [
              -96.515752,
              43.039388
            ],
            [
              -96.513873,
              43.039814
            ],
            [
              -96.511605,
              43.039927
            ],
            [
              -96.509145,
              43.037297
            ],
            [
              -96.509146,
              43.03668
            ],
            [
              -96.510802,
              43.031902
            ],
            [
              -96.512916,
              43.029962
            ],
            [
              -96.513085,
              43.028437
            ],
            [
              -96.511804,
              43.025799
            ],
            [
              -96.510995,
              43.024701
            ],
            [
              -96.503209,
              43.019805
            ],
            [
              -96.499187,
              43.019213
            ],
            [
              -96.494416,
              43.014551
            ],
            [
              -96.49167,
              43.009707
            ],
            [
              -96.492693,
              43.005089
            ],
            [
              -96.494341,
              43.001819
            ],
            [
              -96.496699,
              42.998807
            ],
            [
              -96.49782,
              42.998143
            ],
            [
              -96.502728,
              42.997066
            ],
            [
              -96.507337,
              42.996519
            ],
            [
              -96.509986,
              42.995126
            ],
            [
              -96.512886,
              42.991424
            ],
            [
              -96.512203,
              42.988818
            ],
            [
              -96.512237,
              42.985937
            ],
            [
              -96.516724,
              42.981458
            ],
            [
              -96.520773,
              42.980385
            ],
            [
              -96.520246,
              42.977643
            ],
            [
              -96.515922,
              42.972886
            ],
            [
              -96.510693,
              42.97126
            ],
            [
              -96.506148,
              42.971348
            ],
            [
              -96.505028,
              42.970844
            ],
            [
              -96.503132,
              42.968192
            ],
            [
              -96.500308,
              42.959391
            ],
            [
              -96.504857,
              42.954659
            ],
            [
              -96.508069,
              42.948534
            ],
            [
              -96.509472,
              42.945151
            ],
            [
              -96.510749,
              42.944397
            ],
            [
              -96.514888,
              42.943668
            ],
            [
              -96.519994,
              42.93976
            ],
            [
              -96.525536,
              42.935511
            ],
            [
              -96.541098,
              42.924496
            ],
            [
              -96.541689,
              42.922576
            ],
            [
              -96.541628,
              42.920678
            ],
            [
              -96.540229,
              42.918666
            ],
            [
              -96.537837,
              42.910709
            ],
            [
              -96.537353873885,
              42.9087912967329
            ],
            [
              -95.97737,
              42.909772
            ],
            [
              -95.859377,
              42.909097
            ],
            [
              -95.859951,
              42.996661
            ],
            [
              -95.861914,
              43.257566
            ],
            [
              -96.039157,
              43.258891
            ],
            [
              -96.098063,
              43.257399
            ],
            [
              -96.545263,
              43.260031
            ],
            [
              -96.545523,
              43.259963
            ],
            [
              -96.552718,
              43.260107
            ],
            [
              -96.554967,
              43.260151
            ],
            [
              -96.5549681830449,
              43.2599975664391
            ],
            [
              -96.554965,
              43.259999
            ],
            [
              -96.553217,
              43.259141
            ],
            [
              -96.552591,
              43.257769
            ],
            [
              -96.55203,
              43.251117
            ],
            [
              -96.552963,
              43.247281
            ],
            [
              -96.559186,
              43.245155
            ],
            [
              -96.565253,
              43.244241
            ],
            [
              -96.571194,
              43.238961
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "049",
      "COUNTYNS": "00465213",
      "AFFGEOID": "0500000US19049",
      "GEOID": "19049",
      "NAME": "Dallas",
      "LSAD": "06",
      "ALAND": 1523691141,
      "AWATER": 8561943,
      "County_state": "Dallas,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.28034,
              41.862999
            ],
            [
              -94.280057,
              41.601312
            ],
            [
              -94.241356,
              41.600971
            ],
            [
              -94.241593,
              41.503679
            ],
            [
              -93.896807,
              41.503098
            ],
            [
              -93.790612,
              41.511916
            ],
            [
              -93.790523,
              41.533385
            ],
            [
              -93.790547,
              41.5844
            ],
            [
              -93.790632,
              41.600425
            ],
            [
              -93.814282,
              41.600456
            ],
            [
              -93.814297,
              41.614868
            ],
            [
              -93.814326,
              41.632979
            ],
            [
              -93.815032,
              41.803985
            ],
            [
              -93.815721,
              41.863419
            ],
            [
              -94.048029,
              41.863128
            ],
            [
              -94.164138,
              41.863244
            ],
            [
              -94.28034,
              41.862999
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "029",
      "COUNTYNS": "00465204",
      "AFFGEOID": "0500000US19029",
      "GEOID": "19029",
      "NAME": "Cass",
      "LSAD": "06",
      "ALAND": 1461450798,
      "AWATER": 1758815,
      "County_state": "Cass,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.155293,
              41.500035
            ],
            [
              -95.155851,
              41.159236
            ],
            [
              -94.927587,
              41.158508
            ],
            [
              -94.704982,
              41.157903
            ],
            [
              -94.700589,
              41.158085
            ],
            [
              -94.700097,
              41.331044
            ],
            [
              -94.700629,
              41.504148
            ],
            [
              -94.92638,
              41.504524
            ],
            [
              -95.04077,
              41.50469
            ],
            [
              -95.154722,
              41.505211
            ],
            [
              -95.155293,
              41.500035
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "039",
      "COUNTYNS": "00465209",
      "AFFGEOID": "0500000US19039",
      "GEOID": "19039",
      "NAME": "Clarke",
      "LSAD": "06",
      "ALAND": 1116716130,
      "AWATER": 1524746,
      "County_state": "Clarke,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.014189,
              41.156762
            ],
            [
              -94.014803,
              40.897031
            ],
            [
              -93.904967,
              40.89667
            ],
            [
              -93.55654,
              40.898295
            ],
            [
              -93.557556,
              41.161271
            ],
            [
              -93.789196,
              41.162035
            ],
            [
              -93.899659,
              41.156597
            ],
            [
              -94.014189,
              41.156762
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "133",
      "COUNTYNS": "00465255",
      "AFFGEOID": "0500000US19133",
      "GEOID": "19133",
      "NAME": "Monona",
      "LSAD": "06",
      "ALAND": 1797637874,
      "AWATER": 12592242,
      "County_state": "Monona,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.35987,
              42.210545
            ],
            [
              -96.359087,
              42.207799
            ],
            [
              -96.351515,
              42.200485
            ],
            [
              -96.349166,
              42.197253
            ],
            [
              -96.348066,
              42.194747
            ],
            [
              -96.347243,
              42.186721
            ],
            [
              -96.350323,
              42.17744
            ],
            [
              -96.349688,
              42.172043
            ],
            [
              -96.347752,
              42.166806
            ],
            [
              -96.342395,
              42.160491
            ],
            [
              -96.33798,
              42.157197
            ],
            [
              -96.325872,
              42.151487
            ],
            [
              -96.319528,
              42.146647
            ],
            [
              -96.316979,
              42.143171
            ],
            [
              -96.313819,
              42.136338
            ],
            [
              -96.310085,
              42.132523
            ],
            [
              -96.305884,
              42.129826
            ],
            [
              -96.301023,
              42.128042
            ],
            [
              -96.28567,
              42.125619
            ],
            [
              -96.279203,
              42.12348
            ],
            [
              -96.275002,
              42.120779
            ],
            [
              -96.272299,
              42.118396
            ],
            [
              -96.2689,
              42.11359
            ],
            [
              -96.267318,
              42.110265
            ],
            [
              -96.266594,
              42.103262
            ],
            [
              -96.267636,
              42.096177
            ],
            [
              -96.271777,
              42.088697
            ],
            [
              -96.274135,
              42.085934
            ],
            [
              -96.276758,
              42.081416
            ],
            [
              -96.279079,
              42.074026
            ],
            [
              -96.279342,
              42.07028
            ],
            [
              -96.278445,
              42.060399
            ],
            [
              -96.275548,
              42.051976
            ],
            [
              -96.2729013791207,
              42.047281245329
            ],
            [
              -96.272877,
              42.047238
            ],
            [
              -96.271427,
              42.044988
            ],
            [
              -96.268637,
              42.042314
            ],
            [
              -96.263886,
              42.039858
            ],
            [
              -96.261132,
              42.038974
            ],
            [
              -96.256087,
              42.03808
            ],
            [
              -96.254542,
              42.039454
            ],
            [
              -96.246832,
              42.041616
            ],
            [
              -96.238392,
              42.041088
            ],
            [
              -96.232125,
              42.039145
            ],
            [
              -96.225656,
              42.035217
            ],
            [
              -96.223822,
              42.033346
            ],
            [
              -96.221901,
              42.029558
            ],
            [
              -96.22173,
              42.026205
            ],
            [
              -96.223611,
              42.022652
            ],
            [
              -96.227867,
              42.018651
            ],
            [
              -96.238859,
              42.012315
            ],
            [
              -96.241932,
              42.006965
            ],
            [
              -96.24238,
              42.002899
            ],
            [
              -96.242035,
              42.000911
            ],
            [
              -96.240713,
              41.999351
            ],
            [
              -96.236487,
              41.996428
            ],
            [
              -96.229739,
              41.99441
            ],
            [
              -96.225463,
              41.994734
            ],
            [
              -96.223896,
              41.995456
            ],
            [
              -96.221813,
              41.997382
            ],
            [
              -96.217637,
              42.003862
            ],
            [
              -96.215225,
              42.006701
            ],
            [
              -96.206083,
              42.009267
            ],
            [
              -96.194556,
              42.008662
            ],
            [
              -96.188067,
              42.006323
            ],
            [
              -96.184644,
              42.002633
            ],
            [
              -96.183568,
              41.999987
            ],
            [
              -96.183801,
              41.99776
            ],
            [
              -96.184784,
              41.99546
            ],
            [
              -96.189516,
              41.989314
            ],
            [
              -96.192141,
              41.984461
            ],
            [
              -96.191549,
              41.982032
            ],
            [
              -96.190602,
              41.980721
            ],
            [
              -96.186265,
              41.977417
            ],
            [
              -96.184243,
              41.976696
            ],
            [
              -96.177203,
              41.976325
            ],
            [
              -96.174154,
              41.976864
            ],
            [
              -96.168071,
              41.978996
            ],
            [
              -96.16068,
              41.980114
            ],
            [
              -96.156538,
              41.980137
            ],
            [
              -96.141228,
              41.978063
            ],
            [
              -96.132537,
              41.974625
            ],
            [
              -96.129505,
              41.971673
            ],
            [
              -96.1289,
              41.969727
            ],
            [
              -96.129186,
              41.965136
            ],
            [
              -96.133318,
              41.955732
            ],
            [
              -96.135393,
              41.952223
            ],
            [
              -96.142597,
              41.945908
            ],
            [
              -96.143603,
              41.944512
            ],
            [
              -96.144583,
              41.941544
            ],
            [
              -96.143493,
              41.937387
            ],
            [
              -96.136613,
              41.927167
            ],
            [
              -96.136133,
              41.92353
            ],
            [
              -96.136743,
              41.920826
            ],
            [
              -96.139653,
              41.916838
            ],
            [
              -96.142265,
              41.915379
            ],
            [
              -96.154301,
              41.912421
            ],
            [
              -96.159098,
              41.910057
            ],
            [
              -96.160767,
              41.908044
            ],
            [
              -96.161988,
              41.905553
            ],
            [
              -96.161756,
              41.90182
            ],
            [
              -96.158204,
              41.897173
            ],
            [
              -96.152179,
              41.892085
            ],
            [
              -96.148826,
              41.888132
            ],
            [
              -96.14735,
              41.884811
            ],
            [
              -96.146757,
              41.877538
            ],
            [
              -96.146083,
              41.874988
            ],
            [
              -96.144483,
              41.871854
            ],
            [
              -96.142045,
              41.868865
            ],
            [
              -96.1390095052264,
              41.8663010080218
            ],
            [
              -95.672771,
              41.863147
            ],
            [
              -95.670822,
              42.211408
            ],
            [
              -96.25144,
              42.214644
            ],
            [
              -96.35665510863,
              42.2151367517153
            ],
            [
              -96.358141,
              42.214088
            ],
            [
              -96.35987,
              42.210545
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "025",
      "COUNTYNS": "00465202",
      "AFFGEOID": "0500000US19025",
      "GEOID": "19025",
      "NAME": "Calhoun",
      "LSAD": "06",
      "ALAND": 1475894622,
      "AWATER": 5873828,
      "County_state": "Calhoun,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.914485,
              42.560309
            ],
            [
              -94.914487,
              42.47419
            ],
            [
              -94.856689,
              42.473996
            ],
            [
              -94.858412,
              42.209692
            ],
            [
              -94.628806,
              42.209396
            ],
            [
              -94.397526,
              42.209161
            ],
            [
              -94.397671,
              42.4733
            ],
            [
              -94.443033,
              42.473378
            ],
            [
              -94.443024,
              42.558843
            ],
            [
              -94.914485,
              42.560309
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "097",
      "COUNTYNS": "00465237",
      "AFFGEOID": "0500000US19097",
      "GEOID": "19097",
      "NAME": "Jackson",
      "LSAD": "06",
      "ALAND": 1647332487,
      "AWATER": 35141496,
      "County_state": "Jackson,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.89802,
              42.295236
            ],
            [
              -90.898373,
              42.033548
            ],
            [
              -90.548659,
              42.032838
            ],
            [
              -90.154221,
              42.033073
            ],
            [
              -90.158829,
              42.037769
            ],
            [
              -90.163446,
              42.040407
            ],
            [
              -90.164485,
              42.042105
            ],
            [
              -90.165294,
              42.050973
            ],
            [
              -90.165555,
              42.062638
            ],
            [
              -90.168358,
              42.075779
            ],
            [
              -90.163405,
              42.087613
            ],
            [
              -90.161504,
              42.098912
            ],
            [
              -90.161159,
              42.106372
            ],
            [
              -90.161884,
              42.11378
            ],
            [
              -90.162895,
              42.116718
            ],
            [
              -90.167533,
              42.122475
            ],
            [
              -90.17097,
              42.125198
            ],
            [
              -90.187474,
              42.125423
            ],
            [
              -90.190452,
              42.125779
            ],
            [
              -90.197342,
              42.128163
            ],
            [
              -90.201404,
              42.130937
            ],
            [
              -90.20536,
              42.139079
            ],
            [
              -90.206369,
              42.1455
            ],
            [
              -90.207421,
              42.149109
            ],
            [
              -90.209479,
              42.15268
            ],
            [
              -90.216107,
              42.15673
            ],
            [
              -90.224244,
              42.160028
            ],
            [
              -90.234919,
              42.165431
            ],
            [
              -90.250129,
              42.171469
            ],
            [
              -90.255456,
              42.171821
            ],
            [
              -90.26908,
              42.1745
            ],
            [
              -90.282173,
              42.178846
            ],
            [
              -90.298442,
              42.187576
            ],
            [
              -90.317774,
              42.193789
            ],
            [
              -90.31794761822601,
              42.1939090229626
            ],
            [
              -90.328273,
              42.201047
            ],
            [
              -90.338169,
              42.203321
            ],
            [
              -90.356964,
              42.205445
            ],
            [
              -90.365138,
              42.210526
            ],
            [
              -90.375129,
              42.214811
            ],
            [
              -90.391108,
              42.225473
            ],
            [
              -90.394749,
              42.229059
            ],
            [
              -90.395883,
              42.233133
            ],
            [
              -90.400653,
              42.239293
            ],
            [
              -90.410471,
              42.247749
            ],
            [
              -90.416315,
              42.251679
            ],
            [
              -90.419326,
              42.254467
            ],
            [
              -90.422181,
              42.259899
            ],
            [
              -90.424098,
              42.266364
            ],
            [
              -90.430884,
              42.27823
            ],
            [
              -90.430735,
              42.284211
            ],
            [
              -90.426909,
              42.290719
            ],
            [
              -90.424326,
              42.293326
            ],
            [
              -90.420454,
              42.305374
            ],
            [
              -90.4203,
              42.31169
            ],
            [
              -90.421047,
              42.316109
            ],
            [
              -90.420075,
              42.317681
            ],
            [
              -90.417125,
              42.319943
            ],
            [
              -90.4162,
              42.321314
            ],
            [
              -90.415937,
              42.322699
            ],
            [
              -90.416535,
              42.325109
            ],
            [
              -90.419027,
              42.328505
            ],
            [
              -90.42135,
              42.330472
            ],
            [
              -90.425363,
              42.332615
            ],
            [
              -90.430546,
              42.33686
            ],
            [
              -90.443874,
              42.355218
            ],
            [
              -90.44632,
              42.357041
            ],
            [
              -90.452724,
              42.359303
            ],
            [
              -90.462619,
              42.367253
            ],
            [
              -90.464788,
              42.369452
            ],
            [
              -90.470273,
              42.378355
            ],
            [
              -90.47381216765301,
              42.38145820989109
            ],
            [
              -90.665029,
              42.382155
            ],
            [
              -90.665551,
              42.294699
            ],
            [
              -90.89802,
              42.295236
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "037",
      "COUNTYNS": "00465208",
      "AFFGEOID": "0500000US19037",
      "GEOID": "19037",
      "NAME": "Chickasaw",
      "LSAD": "06",
      "ALAND": 1306338760,
      "AWATER": 3309323,
      "County_state": "Chickasaw,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.554381,
              43.212813
            ],
            [
              -92.554211,
              42.907112
            ],
            [
              -92.081568,
              42.907013
            ],
            [
              -92.081146,
              43.082794
            ],
            [
              -92.080976,
              43.212947
            ],
            [
              -92.554381,
              43.212813
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "077",
      "COUNTYNS": "00465227",
      "AFFGEOID": "0500000US19077",
      "GEOID": "19077",
      "NAME": "Guthrie",
      "LSAD": "06",
      "ALAND": 1529698836,
      "AWATER": 6355533,
      "County_state": "Guthrie,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.744876,
              41.862394
            ],
            [
              -94.744204,
              41.602185
            ],
            [
              -94.700745,
              41.602184
            ],
            [
              -94.700629,
              41.504148
            ],
            [
              -94.241593,
              41.503679
            ],
            [
              -94.241356,
              41.600971
            ],
            [
              -94.280057,
              41.601312
            ],
            [
              -94.28034,
              41.862999
            ],
            [
              -94.628724,
              41.862763
            ],
            [
              -94.744876,
              41.862394
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "189",
      "COUNTYNS": "00465283",
      "AFFGEOID": "0500000US19189",
      "GEOID": "19189",
      "NAME": "Winnebago",
      "LSAD": "06",
      "ALAND": 1037261944,
      "AWATER": 3182052,
      "County_state": "Winnebago,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.97076,
              43.499605
            ],
            [
              -93.97076155853709,
              43.49960500889429
            ],
            [
              -93.970693,
              43.342245
            ],
            [
              -93.970415,
              43.255358
            ],
            [
              -93.497635,
              43.255468
            ],
            [
              -93.4973499498203,
              43.499455765205894
            ],
            [
              -93.497405,
              43.499456
            ],
            [
              -93.528482,
              43.499471
            ],
            [
              -93.532178,
              43.499472
            ],
            [
              -93.558631,
              43.499521
            ],
            [
              -93.576728,
              43.49952
            ],
            [
              -93.617131,
              43.499548
            ],
            [
              -93.6485330413135,
              43.4995586947376
            ],
            [
              -93.699345,
              43.499576
            ],
            [
              -93.704916,
              43.499568
            ],
            [
              -93.708771,
              43.499564
            ],
            [
              -93.716217,
              43.499563
            ],
            [
              -93.76849795694349,
              43.4995489366181
            ],
            [
              -93.794285,
              43.499542
            ],
            [
              -93.795793,
              43.49952
            ],
            [
              -93.97076,
              43.499605
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "059",
      "COUNTYNS": "00465218",
      "AFFGEOID": "0500000US19059",
      "GEOID": "19059",
      "NAME": "Dickinson",
      "LSAD": "06",
      "ALAND": 985539078,
      "AWATER": 60716111,
      "County_state": "Dickinson,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.38759,
              43.342237
            ],
            [
              -95.388078,
              43.255221
            ],
            [
              -94.913723,
              43.255054
            ],
            [
              -94.913985,
              43.341458
            ],
            [
              -94.91463375313809,
              43.50045
            ],
            [
              -94.914905,
              43.50045
            ],
            [
              -94.914955,
              43.50045
            ],
            [
              -94.934625,
              43.50049
            ],
            [
              -94.954477,
              43.500467
            ],
            [
              -94.974359,
              43.500508
            ],
            [
              -94.99446,
              43.500523
            ],
            [
              -95.014245,
              43.500872
            ],
            [
              -95.034,
              43.500811
            ],
            [
              -95.053504,
              43.500769
            ],
            [
              -95.054289,
              43.50086
            ],
            [
              -95.114874,
              43.500667
            ],
            [
              -95.122633,
              43.500755
            ],
            [
              -95.154936,
              43.500449
            ],
            [
              -95.167294,
              43.500771
            ],
            [
              -95.167891,
              43.500885
            ],
            [
              -95.180423,
              43.500774
            ],
            [
              -95.2149304213735,
              43.5008849756272
            ],
            [
              -95.214938,
              43.500885
            ],
            [
              -95.244844,
              43.501196
            ],
            [
              -95.250762,
              43.500406
            ],
            [
              -95.250969,
              43.500464
            ],
            [
              -95.374737,
              43.500314
            ],
            [
              -95.375269,
              43.500322
            ],
            [
              -95.38778709258969,
              43.5002401628325
            ],
            [
              -95.38759,
              43.342237
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "111",
      "COUNTYNS": "00465244",
      "AFFGEOID": "0500000US19111",
      "GEOID": "19111",
      "NAME": "Lee",
      "LSAD": "06",
      "ALAND": 1340422373,
      "AWATER": 55292890,
      "County_state": "Lee,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.718787,
              40.813544
            ],
            [
              -91.718209,
              40.638601
            ],
            [
              -91.716769,
              40.59853
            ],
            [
              -91.712025,
              40.595046
            ],
            [
              -91.696359,
              40.588148
            ],
            [
              -91.68882,
              40.583409
            ],
            [
              -91.686357,
              40.580875
            ],
            [
              -91.685381,
              40.578892
            ],
            [
              -91.685723,
              40.576785
            ],
            [
              -91.691557,
              40.564867
            ],
            [
              -91.691591,
              40.562222
            ],
            [
              -91.690804,
              40.559893
            ],
            [
              -91.6887,
              40.55739
            ],
            [
              -91.681714,
              40.553035
            ],
            [
              -91.670993,
              40.550937
            ],
            [
              -91.654345,
              40.549189
            ],
            [
              -91.638082,
              40.545541
            ],
            [
              -91.625161,
              40.5435
            ],
            [
              -91.6219,
              40.542292
            ],
            [
              -91.620071,
              40.540817
            ],
            [
              -91.618999,
              40.539084
            ],
            [
              -91.618028,
              40.53403
            ],
            [
              -91.618793,
              40.526286
            ],
            [
              -91.622196,
              40.51704
            ],
            [
              -91.622362,
              40.514362
            ],
            [
              -91.621353,
              40.510072
            ],
            [
              -91.619486,
              40.507134
            ],
            [
              -91.616948,
              40.504794
            ],
            [
              -91.612821,
              40.502377
            ],
            [
              -91.608347,
              40.50004
            ],
            [
              -91.594644,
              40.494997
            ],
            [
              -91.590817,
              40.492292
            ],
            [
              -91.586884,
              40.487233
            ],
            [
              -91.583315,
              40.479118
            ],
            [
              -91.582437,
              40.474703
            ],
            [
              -91.581528,
              40.472876
            ],
            [
              -91.580355,
              40.471015
            ],
            [
              -91.574746,
              40.465664
            ],
            [
              -91.567743,
              40.46229
            ],
            [
              -91.563844,
              40.460988
            ],
            [
              -91.552691,
              40.458769
            ],
            [
              -91.543785,
              40.458149
            ],
            [
              -91.5286,
              40.459002
            ],
            [
              -91.526155,
              40.458625
            ],
            [
              -91.52509,
              40.457845
            ],
            [
              -91.523864,
              40.456331
            ],
            [
              -91.523072,
              40.452254
            ],
            [
              -91.523271,
              40.450061
            ],
            [
              -91.524053,
              40.448437
            ],
            [
              -91.526108,
              40.446634
            ],
            [
              -91.531912,
              40.44273
            ],
            [
              -91.533548,
              40.440804
            ],
            [
              -91.533623,
              40.43832
            ],
            [
              -91.532807,
              40.436784
            ],
            [
              -91.529132,
              40.434272
            ],
            [
              -91.525,
              40.433483
            ],
            [
              -91.519935,
              40.433673
            ],
            [
              -91.519134,
              40.432822
            ],
            [
              -91.519012,
              40.431298
            ],
            [
              -91.521388,
              40.426488
            ],
            [
              -91.526555,
              40.419872
            ],
            [
              -91.527057,
              40.416689
            ],
            [
              -91.526425,
              40.413404
            ],
            [
              -91.524612,
              40.410765
            ],
            [
              -91.522333,
              40.409648
            ],
            [
              -91.518392,
              40.408682
            ],
            [
              -91.513993,
              40.408537
            ],
            [
              -91.509063,
              40.406775
            ],
            [
              -91.507427,
              40.405524
            ],
            [
              -91.506745,
              40.404335
            ],
            [
              -91.505272,
              40.403512
            ],
            [
              -91.498093,
              40.401926
            ],
            [
              -91.493644,
              40.402433
            ],
            [
              -91.489816,
              40.404317
            ],
            [
              -91.488481,
              40.404317
            ],
            [
              -91.487829,
              40.403866
            ],
            [
              -91.487955,
              40.402465
            ],
            [
              -91.488597,
              40.400009
            ],
            [
              -91.484507,
              40.3839
            ],
            [
              -91.465116,
              40.385257
            ],
            [
              -91.441243,
              40.386255
            ],
            [
              -91.425662,
              40.382491
            ],
            [
              -91.422324,
              40.380939
            ],
            [
              -91.419422,
              40.378264
            ],
            [
              -91.415695,
              40.381381
            ],
            [
              -91.413011,
              40.382277
            ],
            [
              -91.396996,
              40.383127
            ],
            [
              -91.38836,
              40.384929
            ],
            [
              -91.381958,
              40.387632
            ],
            [
              -91.378422,
              40.38967
            ],
            [
              -91.375746,
              40.391879
            ],
            [
              -91.372921,
              40.399108
            ],
            [
              -91.372554,
              40.4012
            ],
            [
              -91.37245,
              40.411475
            ],
            [
              -91.373721,
              40.417891
            ],
            [
              -91.377625,
              40.426335
            ],
            [
              -91.380965,
              40.435395
            ],
            [
              -91.381769,
              40.442555
            ],
            [
              -91.381468,
              40.44604
            ],
            [
              -91.379907,
              40.45211
            ],
            [
              -91.378144,
              40.456394
            ],
            [
              -91.368074,
              40.474642
            ],
            [
              -91.364915,
              40.484168
            ],
            [
              -91.36391,
              40.490122
            ],
            [
              -91.363683,
              40.494211
            ],
            [
              -91.364211,
              40.500043
            ],
            [
              -91.367876,
              40.510479
            ],
            [
              -91.369059,
              40.512532
            ],
            [
              -91.381857,
              40.528247
            ],
            [
              -91.384531,
              40.530948
            ],
            [
              -91.388067,
              40.533069
            ],
            [
              -91.394475,
              40.534543
            ],
            [
              -91.400725,
              40.536789
            ],
            [
              -91.404125,
              40.539127
            ],
            [
              -91.406202,
              40.542698
            ],
            [
              -91.406851,
              40.547557
            ],
            [
              -91.406373,
              40.551831
            ],
            [
              -91.405241,
              40.554641
            ],
            [
              -91.401482,
              40.559458
            ],
            [
              -91.379752,
              40.57445
            ],
            [
              -91.374252,
              40.58259
            ],
            [
              -91.359873,
              40.601805
            ],
            [
              -91.353989,
              40.606553
            ],
            [
              -91.348733,
              40.609695
            ],
            [
              -91.339719,
              40.613488
            ],
            [
              -91.306524,
              40.626231
            ],
            [
              -91.276175,
              40.63224
            ],
            [
              -91.264953,
              40.633893
            ],
            [
              -91.258249,
              40.636672
            ],
            [
              -91.253074,
              40.637962
            ],
            [
              -91.247851,
              40.63839
            ],
            [
              -91.218437,
              40.638437
            ],
            [
              -91.197906,
              40.636107
            ],
            [
              -91.18698,
              40.637297
            ],
            [
              -91.185428463588,
              40.6380706559482
            ],
            [
              -91.154293,
              40.653596
            ],
            [
              -91.138055,
              40.660893
            ],
            [
              -91.123928,
              40.669152
            ],
            [
              -91.122421,
              40.670675
            ],
            [
              -91.12082,
              40.672777
            ],
            [
              -91.119632,
              40.675892
            ],
            [
              -91.115407,
              40.691825
            ],
            [
              -91.11243358457939,
              40.6962786908794
            ],
            [
              -91.138818,
              40.700706
            ],
            [
              -91.143474,
              40.704895
            ],
            [
              -91.176521,
              40.721092
            ],
            [
              -91.182551,
              40.7081
            ],
            [
              -91.20955,
              40.713993
            ],
            [
              -91.217118,
              40.732286
            ],
            [
              -91.258026,
              40.752019
            ],
            [
              -91.280566,
              40.753263
            ],
            [
              -91.293391,
              40.772577
            ],
            [
              -91.363872,
              40.790665
            ],
            [
              -91.409453,
              40.812905
            ],
            [
              -91.718787,
              40.813544
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "173",
      "COUNTYNS": "00465275",
      "AFFGEOID": "0500000US19173",
      "GEOID": "19173",
      "NAME": "Taylor",
      "LSAD": "06",
      "ALAND": 1377618873,
      "AWATER": 7133364,
      "County_state": "Taylor,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.928459,
              40.900653
            ],
            [
              -94.928529,
              40.727399
            ],
            [
              -94.913124,
              40.727285
            ],
            [
              -94.914896,
              40.575068
            ],
            [
              -94.901451,
              40.574877
            ],
            [
              -94.896801,
              40.574738
            ],
            [
              -94.823758,
              40.573942
            ],
            [
              -94.819978,
              40.573714
            ],
            [
              -94.811188,
              40.573532
            ],
            [
              -94.773988,
              40.572977
            ],
            [
              -94.716665,
              40.572201
            ],
            [
              -94.714925,
              40.572201
            ],
            [
              -94.682601,
              40.571787
            ],
            [
              -94.632035122503,
              40.571186037110195
            ],
            [
              -94.632032,
              40.571186
            ],
            [
              -94.594001,
              40.570966
            ],
            [
              -94.542154,
              40.570809
            ],
            [
              -94.541828,
              40.570809
            ],
            [
              -94.538318,
              40.570763
            ],
            [
              -94.537058,
              40.570763
            ],
            [
              -94.533878,
              40.570739
            ],
            [
              -94.48928,
              40.570707
            ],
            [
              -94.471213,
              40.570825
            ],
            [
              -94.470779,
              40.899502
            ],
            [
              -94.928459,
              40.900653
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "023",
      "COUNTYNS": "00465201",
      "AFFGEOID": "0500000US19023",
      "GEOID": "19023",
      "NAME": "Butler",
      "LSAD": "06",
      "ALAND": 1502550906,
      "AWATER": 4194911,
      "County_state": "Butler,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.025144,
              42.907549
            ],
            [
              -93.027,
              42.55681
            ],
            [
              -92.90795,
              42.556199
            ],
            [
              -92.5543,
              42.555854
            ],
            [
              -92.554492,
              42.642314
            ],
            [
              -92.554495,
              42.730127
            ],
            [
              -92.554211,
              42.907112
            ],
            [
              -93.025144,
              42.907549
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "067",
      "COUNTYNS": "00465222",
      "AFFGEOID": "0500000US19067",
      "GEOID": "19067",
      "NAME": "Floyd",
      "LSAD": "06",
      "ALAND": 1296631080,
      "AWATER": 1942548,
      "County_state": "Floyd,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.024092,
              43.212812
            ],
            [
              -93.025144,
              42.907549
            ],
            [
              -92.554211,
              42.907112
            ],
            [
              -92.554381,
              43.212813
            ],
            [
              -93.024092,
              43.212812
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "195",
      "COUNTYNS": "00465286",
      "AFFGEOID": "0500000US19195",
      "GEOID": "19195",
      "NAME": "Worth",
      "LSAD": "06",
      "ALAND": 1036314926,
      "AWATER": 4709127,
      "County_state": "Worth,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.4973499498203,
              43.499455765205894
            ],
            [
              -93.497635,
              43.255468
            ],
            [
              -93.260374,
              43.255186
            ],
            [
              -93.141565,
              43.255106
            ],
            [
              -93.024143,
              43.255538
            ],
            [
              -93.0243476382082,
              43.499572157239804
            ],
            [
              -93.024429,
              43.499572
            ],
            [
              -93.04919199673229,
              43.4995713943464
            ],
            [
              -93.16917299993789,
              43.4995684598497
            ],
            [
              -93.228861,
              43.499567
            ],
            [
              -93.2718,
              43.499356
            ],
            [
              -93.399035,
              43.499485
            ],
            [
              -93.428509,
              43.499478
            ],
            [
              -93.468563,
              43.499473
            ],
            [
              -93.472804,
              43.4994
            ],
            [
              -93.482009,
              43.499482
            ],
            [
              -93.488261,
              43.499417
            ],
            [
              -93.4973499498203,
              43.499455765205894
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "035",
      "COUNTYNS": "00465207",
      "AFFGEOID": "0500000US19035",
      "GEOID": "19035",
      "NAME": "Cherokee",
      "LSAD": "06",
      "ALAND": 1494180443,
      "AWATER": 423160,
      "County_state": "Cherokee,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.859377,
              42.909097
            ],
            [
              -95.859949,
              42.56062
            ],
            [
              -95.741611,
              42.561285
            ],
            [
              -95.38801,
              42.561742
            ],
            [
              -95.388182,
              42.909897
            ],
            [
              -95.859377,
              42.909097
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "147",
      "COUNTYNS": "00465262",
      "AFFGEOID": "0500000US19147",
      "GEOID": "19147",
      "NAME": "Palo Alto",
      "LSAD": "06",
      "ALAND": 1460391661,
      "AWATER": 14437623,
      "County_state": "Palo Alto,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.913723,
              43.255054
            ],
            [
              -94.91389,
              42.9097
            ],
            [
              -94.442954,
              42.908073
            ],
            [
              -94.442751,
              43.168885
            ],
            [
              -94.443137,
              43.255014
            ],
            [
              -94.913723,
              43.255054
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "171",
      "COUNTYNS": "00465274",
      "AFFGEOID": "0500000US19171",
      "GEOID": "19171",
      "NAME": "Tama",
      "LSAD": "06",
      "ALAND": 1867411148,
      "AWATER": 2769421,
      "County_state": "Tama,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.76809,
              42.29668
            ],
            [
              -92.767463,
              42.21014
            ],
            [
              -92.766306,
              42.035044
            ],
            [
              -92.765999,
              41.862373
            ],
            [
              -92.298164,
              41.862779
            ],
            [
              -92.298792,
              42.297527
            ],
            [
              -92.416949,
              42.297284
            ],
            [
              -92.53351,
              42.297001
            ],
            [
              -92.76809,
              42.29668
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "021",
      "COUNTYNS": "00465200",
      "AFFGEOID": "0500000US19021",
      "GEOID": "19021",
      "NAME": "Buena Vista",
      "LSAD": "06",
      "ALAND": 1488989049,
      "AWATER": 13791660,
      "County_state": "Buena Vista,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.388182,
              42.909897
            ],
            [
              -95.38801,
              42.561742
            ],
            [
              -94.914485,
              42.560309
            ],
            [
              -94.91389,
              42.9097
            ],
            [
              -95.388182,
              42.909897
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "119",
      "COUNTYNS": "00465248",
      "AFFGEOID": "0500000US19119",
      "GEOID": "19119",
      "NAME": "Lyon",
      "LSAD": "06",
      "ALAND": 1522007138,
      "AWATER": 334399,
      "County_state": "Lyon,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.60286,
              43.450907
            ],
            [
              -96.602608,
              43.449649
            ],
            [
              -96.594254,
              43.434153
            ],
            [
              -96.592905,
              43.43317
            ],
            [
              -96.5896197170834,
              43.4321983518958
            ],
            [
              -96.587884,
              43.431685
            ],
            [
              -96.581956,
              43.432212
            ],
            [
              -96.575181,
              43.431756
            ],
            [
              -96.570224,
              43.428601
            ],
            [
              -96.569628,
              43.427527
            ],
            [
              -96.570788,
              43.423755
            ],
            [
              -96.573579,
              43.419228
            ],
            [
              -96.568499,
              43.417217
            ],
            [
              -96.562728,
              43.412782
            ],
            [
              -96.557586,
              43.406792
            ],
            [
              -96.553008,
              43.404117
            ],
            [
              -96.537116,
              43.395063
            ],
            [
              -96.531159,
              43.39561
            ],
            [
              -96.530124,
              43.39641
            ],
            [
              -96.530124,
              43.397553
            ],
            [
              -96.529152,
              43.397735
            ],
            [
              -96.525453,
              43.396317
            ],
            [
              -96.524044,
              43.394762
            ],
            [
              -96.521572,
              43.38564
            ],
            [
              -96.521297,
              43.375947
            ],
            [
              -96.521323,
              43.374607
            ],
            [
              -96.522203,
              43.371947
            ],
            [
              -96.526467,
              43.368314
            ],
            [
              -96.527345,
              43.368109
            ],
            [
              -96.527223,
              43.362257
            ],
            [
              -96.526635,
              43.351833
            ],
            [
              -96.52551,
              43.348335
            ],
            [
              -96.524476,
              43.348151
            ],
            [
              -96.524289,
              43.347214
            ],
            [
              -96.531905,
              43.33869
            ],
            [
              -96.534913,
              43.336473
            ],
            [
              -96.533101,
              43.328587
            ],
            [
              -96.528817,
              43.316561
            ],
            [
              -96.525564,
              43.312467
            ],
            [
              -96.526004,
              43.309999
            ],
            [
              -96.530392,
              43.300034
            ],
            [
              -96.541037,
              43.295556
            ],
            [
              -96.553087,
              43.29286
            ],
            [
              -96.555246,
              43.294803
            ],
            [
              -96.563523,
              43.294804
            ],
            [
              -96.56429,
              43.294804
            ],
            [
              -96.56911,
              43.295535
            ],
            [
              -96.570707,
              43.296701
            ],
            [
              -96.571646,
              43.298187
            ],
            [
              -96.573556,
              43.29917
            ],
            [
              -96.580346,
              43.298204
            ],
            [
              -96.581052,
              43.297118
            ],
            [
              -96.580409,
              43.295854
            ],
            [
              -96.579478,
              43.29511
            ],
            [
              -96.579094,
              43.293797
            ],
            [
              -96.578823,
              43.291095
            ],
            [
              -96.577588,
              43.2788
            ],
            [
              -96.580904,
              43.2748
            ],
            [
              -96.582876,
              43.274594
            ],
            [
              -96.582939,
              43.276536
            ],
            [
              -96.583533,
              43.276879
            ],
            [
              -96.586317,
              43.274319
            ],
            [
              -96.58522,
              43.268878
            ],
            [
              -96.582904,
              43.26769
            ],
            [
              -96.576804,
              43.268308
            ],
            [
              -96.568576,
              43.262662
            ],
            [
              -96.564165,
              43.260239
            ],
            [
              -96.560099,
              43.259279
            ],
            [
              -96.55697,
              43.259096
            ],
            [
              -96.5549681830449,
              43.2599975664391
            ],
            [
              -96.554967,
              43.260151
            ],
            [
              -96.552718,
              43.260107
            ],
            [
              -96.545523,
              43.259963
            ],
            [
              -96.545263,
              43.260031
            ],
            [
              -96.098063,
              43.257399
            ],
            [
              -96.039157,
              43.258891
            ],
            [
              -95.861914,
              43.257566
            ],
            [
              -95.8609457037037,
              43.499966
            ],
            [
              -95.861152,
              43.499966
            ],
            [
              -96.0531625367105,
              43.500176036071394
            ],
            [
              -96.198484,
              43.500335
            ],
            [
              -96.198766,
              43.500312
            ],
            [
              -96.208814,
              43.500391
            ],
            [
              -96.332062,
              43.500415
            ],
            [
              -96.351059,
              43.500333
            ],
            [
              -96.45326,
              43.50039
            ],
            [
              -96.591213,
              43.500514
            ],
            [
              -96.598928,
              43.500457
            ],
            [
              -96.5989289125409,
              43.5004410269423
            ],
            [
              -96.599182,
              43.496011
            ],
            [
              -96.598396,
              43.495074
            ],
            [
              -96.594722,
              43.493314
            ],
            [
              -96.591676,
              43.494367
            ],
            [
              -96.590452,
              43.494298
            ],
            [
              -96.586274,
              43.491099
            ],
            [
              -96.585049,
              43.489887
            ],
            [
              -96.580997,
              43.481384
            ],
            [
              -96.586364,
              43.478251
            ],
            [
              -96.585137,
              43.471141
            ],
            [
              -96.584603,
              43.46961
            ],
            [
              -96.587929,
              43.464878
            ],
            [
              -96.600039,
              43.45708
            ],
            [
              -96.60286,
              43.450907
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "005",
      "COUNTYNS": "00465192",
      "AFFGEOID": "0500000US19005",
      "GEOID": "19005",
      "NAME": "Allamakee",
      "LSAD": "06",
      "ALAND": 1655214176,
      "AWATER": 50995532,
      "County_state": "Allamakee,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.6108315885754,
              43.5005300191943
            ],
            [
              -91.610885,
              43.342137
            ],
            [
              -91.605307,
              43.081653
            ],
            [
              -91.177222,
              43.080247
            ],
            [
              -91.175193,
              43.103771
            ],
            [
              -91.177728,
              43.118733
            ],
            [
              -91.178251,
              43.124982
            ],
            [
              -91.177932,
              43.128875
            ],
            [
              -91.177003,
              43.131846
            ],
            [
              -91.175253,
              43.134665
            ],
            [
              -91.170372,
              43.137384
            ],
            [
              -91.160449,
              43.140575
            ],
            [
              -91.1562,
              43.142945
            ],
            [
              -91.1462,
              43.152405
            ],
            [
              -91.143283,
              43.156413
            ],
            [
              -91.141356,
              43.163537
            ],
            [
              -91.138649,
              43.169993
            ],
            [
              -91.135917,
              43.173422
            ],
            [
              -91.134173,
              43.174405
            ],
            [
              -91.124428,
              43.187886
            ],
            [
              -91.123896,
              43.193536
            ],
            [
              -91.12217,
              43.197255
            ],
            [
              -91.119115,
              43.200366
            ],
            [
              -91.113749,
              43.202908
            ],
            [
              -91.107931,
              43.206578
            ],
            [
              -91.087456,
              43.221891
            ],
            [
              -91.079278,
              43.228259
            ],
            [
              -91.071857,
              43.235164
            ],
            [
              -91.066398,
              43.239293
            ],
            [
              -91.062562,
              43.243165
            ],
            [
              -91.059684,
              43.248566
            ],
            [
              -91.05791,
              43.253968
            ],
            [
              -91.057918,
              43.255366
            ],
            [
              -91.058644,
              43.257679
            ],
            [
              -91.05975,
              43.259074
            ],
            [
              -91.061798,
              43.259952
            ],
            [
              -91.069937,
              43.260272
            ],
            [
              -91.071698,
              43.261014
            ],
            [
              -91.072649,
              43.262129
            ],
            [
              -91.072782,
              43.264363
            ],
            [
              -91.071574,
              43.268193
            ],
            [
              -91.071724,
              43.271392
            ],
            [
              -91.07371,
              43.274746
            ],
            [
              -91.079875,
              43.282773
            ],
            [
              -91.085652,
              43.29187
            ],
            [
              -91.107237,
              43.313645
            ],
            [
              -91.117661,
              43.319332
            ],
            [
              -91.129121,
              43.32635
            ],
            [
              -91.132813,
              43.32803
            ],
            [
              -91.137343,
              43.329757
            ],
            [
              -91.154806,
              43.334826
            ],
            [
              -91.171055,
              43.340967
            ],
            [
              -91.181115,
              43.345926
            ],
            [
              -91.188014,
              43.347602
            ],
            [
              -91.201847,
              43.349103
            ],
            [
              -91.203964,
              43.349852
            ],
            [
              -91.20662,
              43.352524
            ],
            [
              -91.21477,
              43.365874
            ],
            [
              -91.21499,
              43.368006
            ],
            [
              -91.21336,
              43.370097
            ],
            [
              -91.207367,
              43.373659
            ],
            [
              -91.206072,
              43.374976
            ],
            [
              -91.204831,
              43.378887
            ],
            [
              -91.200701,
              43.38593
            ],
            [
              -91.198953,
              43.389835
            ],
            [
              -91.19767,
              43.395334
            ],
            [
              -91.198048,
              43.399223
            ],
            [
              -91.199408,
              43.403032
            ],
            [
              -91.200527,
              43.408486
            ],
            [
              -91.200359,
              43.412701
            ],
            [
              -91.201224,
              43.415903
            ],
            [
              -91.203144,
              43.419805
            ],
            [
              -91.2055506500066,
              43.422948502358096
            ],
            [
              -91.207145,
              43.425031
            ],
            [
              -91.22875,
              43.445537
            ],
            [
              -91.232276,
              43.450952
            ],
            [
              -91.233367,
              43.455168
            ],
            [
              -91.233187,
              43.457784
            ],
            [
              -91.232241,
              43.460018
            ],
            [
              -91.229503,
              43.462607
            ],
            [
              -91.224586,
              43.465525
            ],
            [
              -91.220399,
              43.471306
            ],
            [
              -91.216035,
              43.481142
            ],
            [
              -91.215282,
              43.484798
            ],
            [
              -91.217615,
              43.491008
            ],
            [
              -91.21827,
              43.497228
            ],
            [
              -91.217706,
              43.50055
            ],
            [
              -91.246715,
              43.500488
            ],
            [
              -91.261781,
              43.500993
            ],
            [
              -91.369325,
              43.500827
            ],
            [
              -91.371608,
              43.500945
            ],
            [
              -91.37695,
              43.500482
            ],
            [
              -91.397319,
              43.500887
            ],
            [
              -91.441786,
              43.500438
            ],
            [
              -91.445932,
              43.500588
            ],
            [
              -91.461403,
              43.500642
            ],
            [
              -91.465063,
              43.500608
            ],
            [
              -91.491042,
              43.50069
            ],
            [
              -91.533806,
              43.50056
            ],
            [
              -91.54122,
              43.500515
            ],
            [
              -91.551021,
              43.500539
            ],
            [
              -91.591073,
              43.500536
            ],
            [
              -91.6108315885754,
              43.5005300191943
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "19",
      "COUNTYFP": "045",
      "COUNTYNS": "00465211",
      "AFFGEOID": "0500000US19045",
      "GEOID": "19045",
      "NAME": "Clinton",
      "LSAD": "06",
      "ALAND": 1799830900,
      "AWATER": 39514681,
      "County_state": "Clinton,19"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.898373,
              42.033548
            ],
            [
              -90.898484,
              41.946245
            ],
            [
              -90.898373,
              41.771392
            ],
            [
              -90.786212,
              41.771369
            ],
            [
              -90.782174,
              41.768008
            ],
            [
              -90.761883,
              41.762815
            ],
            [
              -90.753034,
              41.748998
            ],
            [
              -90.713902,
              41.734578
            ],
            [
              -90.693059,
              41.742963
            ],
            [
              -90.676373,
              41.761023
            ],
            [
              -90.657436,
              41.764084
            ],
            [
              -90.651454,
              41.755416
            ],
            [
              -90.630679,
              41.765354
            ],
            [
              -90.616353,
              41.756465
            ],
            [
              -90.59242,
              41.770639
            ],
            [
              -90.579131,
              41.759129
            ],
            [
              -90.542816,
              41.765919
            ],
            [
              -90.537616,
              41.776395
            ],
            [
              -90.495786,
              41.765887
            ],
            [
              -90.471723,
              41.773603
            ],
            [
              -90.458462,
              41.758263
            ],
            [
              -90.451379,
              41.76446
            ],
            [
              -90.424201,
              41.750559
            ],
            [
              -90.424809,
              41.736113
            ],
            [
              -90.41168,
              41.740638
            ],
            [
              -90.407931,
              41.729296
            ],
            [
              -90.389642,
              41.736948
            ],
            [
              -90.364178,
              41.727803
            ],
            [
              -90.333874,
              41.739404
            ],
            [
              -90.31635774707429,
              41.728884798759104
            ],
            [
              -90.31522,
              41.734264
            ],
            [
              -90.310708,
              41.742214
            ],
            [
              -90.302782,
              41.750031
            ],
            [
              -90.278633,
              41.767358
            ],
            [
              -90.263286,
              41.772112
            ],
            [
              -90.258622,
              41.775295
            ],
            [
              -90.248631,
              41.779805
            ],
            [
              -90.2423802567555,
              41.7829645079628
            ],
            [
              -90.2423797480977,
              41.7829647650696
            ],
            [
              -90.222263,
              41.793133
            ],
            [
              -90.216889,
              41.795335
            ],
            [
              -90.187969,
              41.803163
            ],
            [
              -90.181973,
              41.80707
            ],
            [
              -90.180954,
              41.809354
            ],
            [
              -90.180643,
              41.811979
            ],
            [
              -90.18172,
              41.822599
            ],
            [
              -90.183973,
              41.83307
            ],
            [
              -90.183765,
              41.83624
            ],
            [
              -90.181901,
              41.843216
            ],
            [
              -90.181401,
              41.844647
            ],
            [
              -90.175051,
              41.853629
            ],
            [
              -90.173006,
              41.857402
            ],
            [
              -90.172765,
              41.866149
            ],
            [
              -90.170041,
              41.876439
            ],
            [
              -90.165065,
              41.883777
            ],
            [
              -90.157019,
              41.898019
            ],
            [
              -90.153584,
              41.906614
            ],
            [
              -90.153362,
              41.915593
            ],
            [
              -90.1518383787674,
              41.928917335739996
            ],
            [
              -90.1516,
              41.931002
            ],
            [
              -90.152659,
              41.933058
            ],
            [
              -90.156902,
              41.938181
            ],
            [
              -90.160648,
              41.940845
            ],
            [
              -90.163847,
              41.944934
            ],
            [
              -90.164939,
              41.948861
            ],
            [
              -90.164135,
              41.956178
            ],
            [
              -90.162141,
              41.961293
            ],
            [
              -90.153834,
              41.974116
            ],
            [
              -90.148599,
              41.978269
            ],
            [
              -90.146225,
              41.981329
            ],
            [
              -90.146033,
              41.988139
            ],
            [
              -90.140613,
              41.995999
            ],
            [
              -90.140061,
              42.003252
            ],
            [
              -90.141167,
              42.008931
            ],
            [
              -90.143776,
              42.014881
            ],
            [
              -90.148096,
              42.020014
            ],
            [
              -90.149112,
              42.022679
            ],
            [
              -90.149733,
              42.026564
            ],
            [
              -90.150916,
              42.02944
            ],
            [
              -90.151579,
              42.030633
            ],
            [
              -90.154221,
              42.033073
            ],
            [
              -90.548659,
              42.032838
            ],
            [
              -90.898373,
              42.033548
            ]
          ]
        ]
      ]
    }
  }
]
};