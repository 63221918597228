export const counties_md = {
  "type":"FeatureCollection","name":"md_counties","features":[
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "24",
      "COUNTYFP": "043",
      "COUNTYNS": "01714220",
      "AFFGEOID": "0500000US24043",
      "GEOID": "24043",
      "NAME": "Washington",
      "LSAD": "06",
      "ALAND": 1185609331,
      "AWATER": 24803926,
      "County_state": "Washington,24"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.363458,
              39.659793
            ],
            [
              -78.340434,
              39.653024
            ],
            [
              -78.33388026831301,
              39.6364859193412
            ],
            [
              -78.331934,
              39.636054
            ],
            [
              -78.313033,
              39.631001
            ],
            [
              -78.308152,
              39.629606
            ],
            [
              -78.283039,
              39.62047
            ],
            [
              -78.271122,
              39.619642
            ],
            [
              -78.266833,
              39.618818
            ],
            [
              -78.265088,
              39.619274
            ],
            [
              -78.263371,
              39.621675
            ],
            [
              -78.263344,
              39.626417
            ],
            [
              -78.262189,
              39.630464
            ],
            [
              -78.254077,
              39.640089
            ],
            [
              -78.246722,
              39.644758
            ],
            [
              -78.238059,
              39.652081
            ],
            [
              -78.227677,
              39.656796
            ],
            [
              -78.225075,
              39.658878
            ],
            [
              -78.223597,
              39.661097
            ],
            [
              -78.223864,
              39.662607
            ],
            [
              -78.233012,
              39.670471
            ],
            [
              -78.233138,
              39.672875
            ],
            [
              -78.231564,
              39.674382
            ],
            [
              -78.227333,
              39.676121
            ],
            [
              -78.206763,
              39.67599
            ],
            [
              -78.202945,
              39.676653
            ],
            [
              -78.201081,
              39.677866
            ],
            [
              -78.196701,
              39.682074
            ],
            [
              -78.192439,
              39.689118
            ],
            [
              -78.191107,
              39.690262
            ],
            [
              -78.182759,
              39.69511
            ],
            [
              -78.176625,
              39.695967
            ],
            [
              -78.171361,
              39.695612
            ],
            [
              -78.162126,
              39.693643
            ],
            [
              -78.154164,
              39.690531
            ],
            [
              -78.143478,
              39.690412
            ],
            [
              -78.135221,
              39.688305
            ],
            [
              -78.132706,
              39.686977
            ],
            [
              -78.123939,
              39.685652
            ],
            [
              -78.11183,
              39.682593
            ],
            [
              -78.107834,
              39.682137
            ],
            [
              -78.101737,
              39.680286
            ],
            [
              -78.097118,
              39.678161
            ],
            [
              -78.089835,
              39.671668
            ],
            [
              -78.088592,
              39.671211
            ],
            [
              -78.08226,
              39.671166
            ],
            [
              -78.077525,
              39.66888
            ],
            [
              -78.074595,
              39.666686
            ],
            [
              -78.06898805131749,
              39.6616820829176
            ],
            [
              -78.068291,
              39.66106
            ],
            [
              -78.051932,
              39.648207
            ],
            [
              -78.04995,
              39.645349
            ],
            [
              -78.047672,
              39.643107
            ],
            [
              -78.0456843725835,
              39.641982361972396
            ],
            [
              -78.03886,
              39.638121
            ],
            [
              -78.035992,
              39.63572
            ],
            [
              -78.03014,
              39.627462
            ],
            [
              -78.023896,
              39.621697
            ],
            [
              -78.0224018601498,
              39.619600466954395
            ],
            [
              -78.011343,
              39.604083
            ],
            [
              -78.009985,
              39.602893
            ],
            [
              -78.006734,
              39.601337
            ],
            [
              -78.00233,
              39.600488
            ],
            [
              -77.991437,
              39.600194
            ],
            [
              -77.984815,
              39.59942
            ],
            [
              -77.976686,
              39.599744
            ],
            [
              -77.973967,
              39.601071
            ],
            [
              -77.97015,
              39.605091
            ],
            [
              -77.966223,
              39.607435
            ],
            [
              -77.962092,
              39.608702
            ],
            [
              -77.957642,
              39.608614
            ],
            [
              -77.955337,
              39.600336
            ],
            [
              -77.956122,
              39.591268
            ],
            [
              -77.946182,
              39.584814
            ],
            [
              -77.93905,
              39.587139
            ],
            [
              -77.936371,
              39.594508
            ],
            [
              -77.940431,
              39.605776
            ],
            [
              -77.944622,
              39.616772
            ],
            [
              -77.94194,
              39.61879
            ],
            [
              -77.937492,
              39.61915
            ],
            [
              -77.932862,
              39.617676
            ],
            [
              -77.928738,
              39.613908
            ],
            [
              -77.925988,
              39.607642
            ],
            [
              -77.923298,
              39.604852
            ],
            [
              -77.916836,
              39.602942
            ],
            [
              -77.91641,
              39.602816
            ],
            [
              -77.8919216798459,
              39.5980179268892
            ],
            [
              -77.888477,
              39.597343
            ],
            [
              -77.885077,
              39.597937
            ],
            [
              -77.882977,
              39.598828
            ],
            [
              -77.881823,
              39.600039
            ],
            [
              -77.880993,
              39.602852
            ],
            [
              -77.88111,
              39.606214
            ],
            [
              -77.881936,
              39.608112
            ],
            [
              -77.886959,
              39.613329
            ],
            [
              -77.887017,
              39.614518
            ],
            [
              -77.885124,
              39.615775
            ],
            [
              -77.874718,
              39.614293
            ],
            [
              -77.868679,
              39.611138
            ],
            [
              -77.8578,
              39.60788
            ],
            [
              -77.853436,
              39.607117
            ],
            [
              -77.842785,
              39.607255
            ],
            [
              -77.838008,
              39.606125
            ],
            [
              -77.833568,
              39.602936
            ],
            [
              -77.831813,
              39.601105
            ],
            [
              -77.829753,
              39.59105
            ],
            [
              -77.829814,
              39.587288
            ],
            [
              -77.830775,
              39.581178
            ],
            [
              -77.833217,
              39.571016
            ],
            [
              -77.8347328891263,
              39.5687536097396
            ],
            [
              -77.83633,
              39.56637
            ],
            [
              -77.842174,
              39.564333
            ],
            [
              -77.855847,
              39.564607
            ],
            [
              -77.865734,
              39.563547
            ],
            [
              -77.872723,
              39.563895
            ],
            [
              -77.878451,
              39.563493
            ],
            [
              -77.886135,
              39.560432
            ],
            [
              -77.887968,
              39.559198
            ],
            [
              -77.888648,
              39.558054
            ],
            [
              -77.888945,
              39.55595
            ],
            [
              -77.886436,
              39.551947
            ],
            [
              -77.87153,
              39.544278
            ],
            [
              -77.865351,
              39.538381
            ],
            [
              -77.864434,
              39.536483
            ],
            [
              -77.864315,
              39.534813
            ],
            [
              -77.865078,
              39.528226
            ],
            [
              -77.866138,
              39.524727
            ],
            [
              -77.866518,
              39.520039
            ],
            [
              -77.866132,
              39.517661
            ],
            [
              -77.8649397818319,
              39.5163827204063
            ],
            [
              -77.86368,
              39.515032
            ],
            [
              -77.860195,
              39.514325
            ],
            [
              -77.850747,
              39.515403
            ],
            [
              -77.84192,
              39.51847
            ],
            [
              -77.840651,
              39.520941
            ],
            [
              -77.840536,
              39.529196
            ],
            [
              -77.839061,
              39.531117
            ],
            [
              -77.836935,
              39.53217
            ],
            [
              -77.833509,
              39.532628
            ],
            [
              -77.827188,
              39.530458
            ],
            [
              -77.825357,
              39.529177
            ],
            [
              -77.823762,
              39.525907
            ],
            [
              -77.823555,
              39.524077
            ],
            [
              -77.82565,
              39.516895
            ],
            [
              -77.829045,
              39.514425
            ],
            [
              -77.845103,
              39.505845
            ],
            [
              -77.847611,
              39.503351
            ],
            [
              -77.848112,
              39.502093
            ],
            [
              -77.8478531897793,
              39.5013297013575
            ],
            [
              -77.847639,
              39.500698
            ],
            [
              -77.845666,
              39.498628
            ],
            [
              -77.831909,
              39.494744
            ],
            [
              -77.8254985217078,
              39.4942577990943
            ],
            [
              -77.820781,
              39.4939
            ],
            [
              -77.807821,
              39.490241
            ],
            [
              -77.786539,
              39.496598
            ],
            [
              -77.784442,
              39.498061
            ],
            [
              -77.781608,
              39.499067
            ],
            [
              -77.774374,
              39.4995
            ],
            [
              -77.77095,
              39.499087
            ],
            [
              -77.768442,
              39.497783
            ],
            [
              -77.765993,
              39.495724
            ],
            [
              -77.765403,
              39.494397
            ],
            [
              -77.765551,
              39.493025
            ],
            [
              -77.767087,
              39.491333
            ],
            [
              -77.769125,
              39.490281
            ],
            [
              -77.771723,
              39.489207
            ],
            [
              -77.78176,
              39.487128
            ],
            [
              -77.788519,
              39.485048
            ],
            [
              -77.795485,
              39.481824
            ],
            [
              -77.796695,
              39.480498
            ],
            [
              -77.797787,
              39.47876
            ],
            [
              -77.798201,
              39.475719
            ],
            [
              -77.796755,
              39.472448
            ],
            [
              -77.795634,
              39.471259
            ],
            [
              -77.789645,
              39.467827
            ],
            [
              -77.778522,
              39.463663
            ],
            [
              -77.777815,
              39.462816
            ],
            [
              -77.777815,
              39.461924
            ],
            [
              -77.779202,
              39.460392
            ],
            [
              -77.780471,
              39.459867
            ],
            [
              -77.78611,
              39.447197
            ],
            [
              -77.78558,
              39.445367
            ],
            [
              -77.786052,
              39.444224
            ],
            [
              -77.786768,
              39.435442
            ],
            [
              -77.783847,
              39.430456
            ],
            [
              -77.77485,
              39.427845
            ],
            [
              -77.763319,
              39.428436
            ],
            [
              -77.75872,
              39.42681
            ],
            [
              -77.754681,
              39.424658
            ],
            [
              -77.75309,
              39.423262
            ],
            [
              -77.75268,
              39.420174
            ],
            [
              -77.747478,
              39.41093
            ],
            [
              -77.740012,
              39.401694
            ],
            [
              -77.736409,
              39.392684
            ],
            [
              -77.735905,
              39.389665
            ],
            [
              -77.736317,
              39.387744
            ],
            [
              -77.738084,
              39.386211
            ],
            [
              -77.740765,
              39.385409
            ],
            [
              -77.749715,
              39.384171
            ],
            [
              -77.752209,
              39.383328
            ],
            [
              -77.753389,
              39.382094
            ],
            [
              -77.753804,
              39.379624
            ],
            [
              -77.753274,
              39.37832
            ],
            [
              -77.744144,
              39.365139
            ],
            [
              -77.743874,
              39.359947
            ],
            [
              -77.74593,
              39.353221
            ],
            [
              -77.750387,
              39.34945
            ],
            [
              -77.759315,
              39.345314
            ],
            [
              -77.760435,
              39.344171
            ],
            [
              -77.761084,
              39.342524
            ],
            [
              -77.761115,
              39.339757
            ],
            [
              -77.759615,
              39.337331
            ],
            [
              -77.755789,
              39.333899
            ],
            [
              -77.735009,
              39.327015
            ],
            [
              -77.730914,
              39.324684
            ],
            [
              -77.727379,
              39.321666
            ],
            [
              -77.719519,
              39.321314
            ],
            [
              -77.715865,
              39.320641
            ],
            [
              -77.707709,
              39.321559
            ],
            [
              -77.697461,
              39.318633
            ],
            [
              -77.692984,
              39.31845
            ],
            [
              -77.687124,
              39.319914
            ],
            [
              -77.681706,
              39.323666
            ],
            [
              -77.67712301274629,
              39.32407737394119
            ],
            [
              -77.67396,
              39.343061
            ],
            [
              -77.656177,
              39.368397
            ],
            [
              -77.640411,
              39.402538
            ],
            [
              -77.633842,
              39.446819
            ],
            [
              -77.616049,
              39.469157
            ],
            [
              -77.622024,
              39.481754
            ],
            [
              -77.61441,
              39.491551
            ],
            [
              -77.622604,
              39.507598
            ],
            [
              -77.60879,
              39.534158
            ],
            [
              -77.597704,
              39.540781
            ],
            [
              -77.593971,
              39.563919
            ],
            [
              -77.586761,
              39.571365
            ],
            [
              -77.570182,
              39.619998
            ],
            [
              -77.53276,
              39.631183
            ],
            [
              -77.520164,
              39.640678
            ],
            [
              -77.492874,
              39.676425
            ],
            [
              -77.50836,
              39.684072
            ],
            [
              -77.490355,
              39.695842
            ],
            [
              -77.4691450037375,
              39.720229002284896
            ],
            [
              -77.469274,
              39.720229
            ],
            [
              -77.533496,
              39.72037
            ],
            [
              -77.534883,
              39.720338
            ],
            [
              -77.66955148856,
              39.720955107314396
            ],
            [
              -77.672365,
              39.720968
            ],
            [
              -77.674638,
              39.721037
            ],
            [
              -77.724228,
              39.721078
            ],
            [
              -77.732727,
              39.721277
            ],
            [
              -77.743315,
              39.721387
            ],
            [
              -77.768644,
              39.721538
            ],
            [
              -77.7895928365247,
              39.7217055070373
            ],
            [
              -77.7901995700963,
              39.721710358483
            ],
            [
              -77.874822,
              39.722387
            ],
            [
              -78.073826,
              39.722462
            ],
            [
              -78.075861,
              39.722448
            ],
            [
              -78.09897028757709,
              39.722466543278095
            ],
            [
              -78.202977,
              39.72255
            ],
            [
              -78.204532,
              39.722654
            ],
            [
              -78.240414,
              39.722628
            ],
            [
              -78.243182,
              39.722611
            ],
            [
              -78.269026,
              39.722717
            ],
            [
              -78.269098,
              39.72274
            ],
            [
              -78.330789,
              39.722809
            ],
            [
              -78.337184,
              39.722581
            ],
            [
              -78.339612,
              39.722672
            ],
            [
              -78.340571,
              39.722633
            ],
            [
              -78.342593,
              39.722658
            ],
            [
              -78.3428342787621,
              39.7226578296496
            ],
            [
              -78.313325,
              39.692474
            ],
            [
              -78.320787,
              39.676047
            ],
            [
              -78.343493,
              39.675652
            ],
            [
              -78.334139,
              39.662397
            ],
            [
              -78.363458,
              39.659793
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "24",
      "COUNTYFP": "001",
      "COUNTYNS": "01713506",
      "AFFGEOID": "0500000US24001",
      "GEOID": "24001",
      "NAME": "Allegany",
      "LSAD": "06",
      "ALAND": 1093331712,
      "AWATER": 14931771,
      "County_state": "Allegany,24"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.067559,
              39.479443
            ],
            [
              -79.068627,
              39.474515
            ],
            [
              -79.056583,
              39.471014
            ],
            [
              -79.054989,
              39.473096
            ],
            [
              -79.0544297162916,
              39.4766251861057
            ],
            [
              -79.05388,
              39.480094
            ],
            [
              -79.052447,
              39.482315
            ],
            [
              -79.050528,
              39.483299
            ],
            [
              -79.046276,
              39.483801
            ],
            [
              -79.036915,
              39.476795
            ],
            [
              -79.035623,
              39.473344
            ],
            [
              -79.035712,
              39.471331
            ],
            [
              -79.033884,
              39.467761
            ],
            [
              -79.030343,
              39.465403
            ],
            [
              -79.028159,
              39.46506
            ],
            [
              -79.020542,
              39.467002
            ],
            [
              -79.017147,
              39.466977
            ],
            [
              -79.010097,
              39.461048
            ],
            [
              -78.99695,
              39.454961
            ],
            [
              -78.978826,
              39.448678
            ],
            [
              -78.970118,
              39.443327
            ],
            [
              -78.967461,
              39.439804
            ],
            [
              -78.965484,
              39.438455
            ],
            [
              -78.9579587921713,
              39.4400138115152
            ],
            [
              -78.956751,
              39.440264
            ],
            [
              -78.955483,
              39.442277
            ],
            [
              -78.953333,
              39.463645
            ],
            [
              -78.946603,
              39.46614
            ],
            [
              -78.941969,
              39.469959
            ],
            [
              -78.938869,
              39.4741
            ],
            [
              -78.939164,
              39.475267
            ],
            [
              -78.941526,
              39.476869
            ],
            [
              -78.942618,
              39.479614
            ],
            [
              -78.942293,
              39.480987
            ],
            [
              -78.938751,
              39.483732
            ],
            [
              -78.933613,
              39.48618
            ],
            [
              -78.926999,
              39.487003
            ],
            [
              -78.918142,
              39.485858
            ],
            [
              -78.916488,
              39.486544
            ],
            [
              -78.908719,
              39.496699
            ],
            [
              -78.895307,
              39.512085
            ],
            [
              -78.891197,
              39.5189
            ],
            [
              -78.885996,
              39.522581
            ],
            [
              -78.879084,
              39.521205
            ],
            [
              -78.87681,
              39.52125
            ],
            [
              -78.874744,
              39.522611
            ],
            [
              -78.868966,
              39.531366
            ],
            [
              -78.868908,
              39.532487
            ],
            [
              -78.851931,
              39.551848
            ],
            [
              -78.851016,
              39.554044
            ],
            [
              -78.851196,
              39.559924
            ],
            [
              -78.840055958723,
              39.5664231651079
            ],
            [
              -78.838553,
              39.5673
            ],
            [
              -78.830298,
              39.565355
            ],
            [
              -78.826407,
              39.562589
            ],
            [
              -78.821404,
              39.560616
            ],
            [
              -78.816764,
              39.561691
            ],
            [
              -78.813512,
              39.56772
            ],
            [
              -78.815114,
              39.571351
            ],
            [
              -78.820104,
              39.576287
            ],
            [
              -78.82636,
              39.577333
            ],
            [
              -78.826009,
              39.588829
            ],
            [
              -78.824788,
              39.590233
            ],
            [
              -78.818899,
              39.59037
            ],
            [
              -78.812215,
              39.597717
            ],
            [
              -78.812154,
              39.60054
            ],
            [
              -78.809347,
              39.608063
            ],
            [
              -78.801792,
              39.606812
            ],
            [
              -78.800434,
              39.605232
            ],
            [
              -78.79784,
              39.604897
            ],
            [
              -78.795857,
              39.606934
            ],
            [
              -78.795368,
              39.61071
            ],
            [
              -78.795964,
              39.614205
            ],
            [
              -78.801741,
              39.627488
            ],
            [
              -78.795941,
              39.637287
            ],
            [
              -78.794597107557,
              39.6375557784886
            ],
            [
              -78.790941,
              39.638287
            ],
            [
              -78.775241,
              39.645687
            ],
            [
              -78.76584,
              39.648487
            ],
            [
              -78.76504,
              39.646087
            ],
            [
              -78.76534,
              39.643987
            ],
            [
              -78.76814,
              39.639287
            ],
            [
              -78.77114,
              39.638387
            ],
            [
              -78.77264,
              39.636887
            ],
            [
              -78.778477,
              39.624405
            ],
            [
              -78.778477,
              39.62265
            ],
            [
              -78.777516,
              39.621712
            ],
            [
              -78.769565,
              39.619431
            ],
            [
              -78.763171,
              39.618897
            ],
            [
              -78.756686,
              39.622971
            ],
            [
              -78.748499,
              39.626262
            ],
            [
              -78.74288,
              39.625088
            ],
            [
              -78.736189,
              39.621708
            ],
            [
              -78.733553,
              39.615533
            ],
            [
              -78.733759,
              39.613931
            ],
            [
              -78.73905,
              39.609697
            ],
            [
              -78.747063,
              39.60569
            ],
            [
              -78.749222,
              39.606536
            ],
            [
              -78.74935,
              39.608572
            ],
            [
              -78.751514,
              39.609947
            ],
            [
              -78.760497,
              39.609984
            ],
            [
              -78.768115,
              39.608704
            ],
            [
              -78.77686,
              39.604027
            ],
            [
              -78.778096,
              39.602097
            ],
            [
              -78.778141,
              39.601364
            ],
            [
              -78.774281,
              39.597328
            ],
            [
              -78.772128,
              39.596497
            ],
            [
              -78.770511,
              39.594994
            ],
            [
              -78.768481,
              39.591583
            ],
            [
              -78.768314,
              39.589394
            ],
            [
              -78.76749,
              39.587487
            ],
            [
              -78.760196,
              39.582154
            ],
            [
              -78.756747,
              39.58069
            ],
            [
              -78.746421,
              39.579544
            ],
            [
              -78.743318,
              39.580712
            ],
            [
              -78.740246,
              39.585655
            ],
            [
              -78.738502,
              39.586319
            ],
            [
              -78.733979,
              39.586618
            ],
            [
              -78.733149,
              39.58369
            ],
            [
              -78.731992,
              39.575364
            ],
            [
              -78.726342,
              39.567587
            ],
            [
              -78.72501,
              39.563973
            ],
            [
              -78.72459061271309,
              39.5639149682172
            ],
            [
              -78.714784,
              39.562558
            ],
            [
              -78.713335,
              39.562032
            ],
            [
              -78.708664,
              39.556795
            ],
            [
              -78.707098,
              39.555857
            ],
            [
              -78.694626,
              39.553251
            ],
            [
              -78.692824,
              39.55197
            ],
            [
              -78.691996,
              39.55078
            ],
            [
              -78.691494,
              39.547646
            ],
            [
              -78.689455,
              39.54577
            ],
            [
              -78.682423,
              39.543848
            ],
            [
              -78.675629,
              39.540371
            ],
            [
              -78.668745,
              39.540164
            ],
            [
              -78.66399,
              39.536778
            ],
            [
              -78.6574165631331,
              39.5350677240827
            ],
            [
              -78.655984,
              39.534695
            ],
            [
              -78.630842,
              39.537109
            ],
            [
              -78.628744,
              39.537863
            ],
            [
              -78.628566,
              39.53919
            ],
            [
              -78.623037,
              39.539512
            ],
            [
              -78.614526,
              39.537595
            ],
            [
              -78.606873,
              39.535082
            ],
            [
              -78.605868,
              39.534304
            ],
            [
              -78.600511,
              39.533434
            ],
            [
              -78.597659,
              39.53505
            ],
            [
              -78.595603,
              39.535483
            ],
            [
              -78.593871,
              39.535158
            ],
            [
              -78.593114,
              39.534401
            ],
            [
              -78.592131,
              39.531816
            ],
            [
              -78.590654,
              39.530192
            ],
            [
              -78.5873312363446,
              39.52817324680849
            ],
            [
              -78.587079,
              39.52802
            ],
            [
              -78.578956,
              39.526695
            ],
            [
              -78.572692,
              39.522372
            ],
            [
              -78.567937,
              39.519902
            ],
            [
              -78.56691088080801,
              39.5196679548855
            ],
            [
              -78.565929,
              39.519444
            ],
            [
              -78.557127,
              39.521526
            ],
            [
              -78.552756,
              39.521388
            ],
            [
              -78.550128,
              39.520702
            ],
            [
              -78.546584,
              39.520998
            ],
            [
              -78.527886,
              39.524654
            ],
            [
              -78.521388,
              39.52479
            ],
            [
              -78.513622,
              39.522545
            ],
            [
              -78.5032,
              39.518652
            ],
            [
              -78.499017,
              39.518906
            ],
            [
              -78.489742,
              39.517789
            ],
            [
              -78.485697,
              39.519392
            ],
            [
              -78.484044,
              39.519507
            ],
            [
              -78.480677,
              39.51896
            ],
            [
              -78.474178,
              39.51624
            ],
            [
              -78.471166,
              39.516103
            ],
            [
              -78.468639,
              39.516789
            ],
            [
              -78.462899,
              39.52084
            ],
            [
              -78.460951,
              39.525987
            ],
            [
              -78.461071,
              39.529304
            ],
            [
              -78.461911,
              39.532971
            ],
            [
              -78.461291,
              39.534678
            ],
            [
              -78.459274,
              39.535919
            ],
            [
              -78.45105,
              39.536695
            ],
            [
              -78.449499,
              39.538092
            ],
            [
              -78.449654,
              39.539643
            ],
            [
              -78.449964,
              39.54104
            ],
            [
              -78.449499,
              39.542281
            ],
            [
              -78.447171,
              39.543367
            ],
            [
              -78.445309,
              39.543367
            ],
            [
              -78.441961,
              39.541223
            ],
            [
              -78.438357,
              39.538753
            ],
            [
              -78.436378,
              39.539302
            ],
            [
              -78.435107,
              39.541658
            ],
            [
              -78.434759,
              39.543988
            ],
            [
              -78.434759,
              39.54678
            ],
            [
              -78.433828,
              39.548953
            ],
            [
              -78.430414,
              39.549418
            ],
            [
              -78.426953,
              39.546598
            ],
            [
              -78.424053,
              39.546315
            ],
            [
              -78.421105,
              39.54678
            ],
            [
              -78.419398,
              39.547401
            ],
            [
              -78.418777,
              39.548953
            ],
            [
              -78.420019,
              39.551745
            ],
            [
              -78.423287,
              39.556319
            ],
            [
              -78.426537,
              39.559155
            ],
            [
              -78.438179,
              39.563524
            ],
            [
              -78.450207,
              39.570889
            ],
            [
              -78.454376,
              39.574319
            ],
            [
              -78.458338,
              39.580426
            ],
            [
              -78.458052,
              39.585241
            ],
            [
              -78.457187,
              39.587379
            ],
            [
              -78.454527,
              39.588958
            ],
            [
              -78.451186,
              39.590193
            ],
            [
              -78.445983,
              39.591223
            ],
            [
              -78.443175,
              39.591155
            ],
            [
              -78.428246,
              39.586717
            ],
            [
              -78.422985,
              39.584109
            ],
            [
              -78.420059,
              39.581706
            ],
            [
              -78.41867,
              39.581111
            ],
            [
              -78.408031,
              39.578593
            ],
            [
              -78.400936,
              39.580214
            ],
            [
              -78.397446,
              39.581952
            ],
            [
              -78.395317,
              39.584215
            ],
            [
              -78.395463,
              39.587372
            ],
            [
              -78.397471,
              39.590232
            ],
            [
              -78.402702,
              39.593596
            ],
            [
              -78.41287,
              39.598311
            ],
            [
              -78.420644,
              39.603183
            ],
            [
              -78.425581,
              39.607599
            ],
            [
              -78.431524,
              39.614484
            ],
            [
              -78.433002,
              39.61652
            ],
            [
              -78.433623,
              39.618259
            ],
            [
              -78.433297,
              39.620569
            ],
            [
              -78.43025,
              39.62329
            ],
            [
              -78.425902,
              39.624548
            ],
            [
              -78.420549,
              39.624021
            ],
            [
              -78.41286,
              39.621091
            ],
            [
              -78.395828,
              39.616076
            ],
            [
              -78.390774,
              39.612117
            ],
            [
              -78.383591,
              39.608912
            ],
            [
              -78.378181,
              39.608178
            ],
            [
              -78.374732,
              39.608635
            ],
            [
              -78.3732,
              39.60953
            ],
            [
              -78.372255,
              39.6112
            ],
            [
              -78.372404,
              39.612297
            ],
            [
              -78.379118,
              39.618127
            ],
            [
              -78.382959,
              39.622246
            ],
            [
              -78.383461,
              39.623321
            ],
            [
              -78.383447,
              39.625091
            ],
            [
              -78.382487,
              39.628216
            ],
            [
              -78.380504,
              39.629359
            ],
            [
              -78.373166,
              39.630459
            ],
            [
              -78.367959,
              39.628929
            ],
            [
              -78.366212,
              39.627534
            ],
            [
              -78.362485,
              39.626049
            ],
            [
              -78.358343,
              39.625581
            ],
            [
              -78.35577,
              39.626258
            ],
            [
              -78.353878,
              39.627722
            ],
            [
              -78.353465,
              39.628912
            ],
            [
              -78.353673,
              39.630787
            ],
            [
              -78.355567,
              39.633463
            ],
            [
              -78.358735,
              39.635589
            ],
            [
              -78.359506,
              39.638081
            ],
            [
              -78.358264,
              39.63966
            ],
            [
              -78.355218,
              39.640576
            ],
            [
              -78.351905,
              39.640486
            ],
            [
              -78.33388026831301,
              39.6364859193412
            ],
            [
              -78.340434,
              39.653024
            ],
            [
              -78.363458,
              39.659793
            ],
            [
              -78.334139,
              39.662397
            ],
            [
              -78.343493,
              39.675652
            ],
            [
              -78.320787,
              39.676047
            ],
            [
              -78.313325,
              39.692474
            ],
            [
              -78.3428342787621,
              39.7226578296496
            ],
            [
              -78.3805616640433,
              39.7226311929318
            ],
            [
              -78.438906,
              39.72259
            ],
            [
              -78.461488,
              39.722976
            ],
            [
              -78.537763,
              39.722589
            ],
            [
              -78.546475,
              39.722967
            ],
            [
              -78.575951,
              39.722656
            ],
            [
              -78.61031867837701,
              39.722764485156006
            ],
            [
              -78.65821322789421,
              39.7229156692895
            ],
            [
              -78.723578,
              39.723122
            ],
            [
              -78.8083363526072,
              39.7230036184217
            ],
            [
              -78.9284160738903,
              39.7228359036597
            ],
            [
              -78.99867,
              39.599942
            ],
            [
              -79.067559,
              39.479443
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "24",
      "COUNTYFP": "011",
      "COUNTYNS": "00595737",
      "AFFGEOID": "0500000US24011",
      "GEOID": "24011",
      "NAME": "Caroline",
      "LSAD": "06",
      "ALAND": 827350251,
      "AWATER": 16777064,
      "County_state": "Caroline,24"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.015045,
              38.728687
            ],
            [
              -76.00753,
              38.720981
            ],
            [
              -75.993247,
              38.707977
            ],
            [
              -75.993049,
              38.696723
            ],
            [
              -75.976791,
              38.697837
            ],
            [
              -75.971672,
              38.684654
            ],
            [
              -75.945248,
              38.674109
            ],
            [
              -75.937728,
              38.677112
            ],
            [
              -75.904861,
              38.687648
            ],
            [
              -75.897089,
              38.701322
            ],
            [
              -75.888985,
              38.695474
            ],
            [
              -75.870467,
              38.694252
            ],
            [
              -75.864318,
              38.697937
            ],
            [
              -75.837974,
              38.70399
            ],
            [
              -75.800277,
              38.695983
            ],
            [
              -75.798408,
              38.676338
            ],
            [
              -75.763008,
              38.686203
            ],
            [
              -75.7075549587544,
              38.6353853617286
            ],
            [
              -75.7075551268783,
              38.635387500534705
            ],
            [
              -75.7130753987384,
              38.705614243345494
            ],
            [
              -75.7177845673772,
              38.7655224477304
            ],
            [
              -75.7206411412939,
              38.8018626655411
            ],
            [
              -75.722239,
              38.82219
            ],
            [
              -75.72280102322149,
              38.8298507048386
            ],
            [
              -75.722821,
              38.830123
            ],
            [
              -75.723093,
              38.833272
            ],
            [
              -75.724214,
              38.846802
            ],
            [
              -75.724273,
              38.847901
            ],
            [
              -75.725777,
              38.868279
            ],
            [
              -75.726041,
              38.869423
            ],
            [
              -75.728119928006,
              38.8954771487671
            ],
            [
              -75.742408676419,
              39.074550775064196
            ],
            [
              -75.74403,
              39.09487
            ],
            [
              -75.746013,
              39.115137
            ],
            [
              -75.746341,
              39.120522
            ],
            [
              -75.7478730007744,
              39.1432793370846
            ],
            [
              -75.791079,
              39.132828
            ],
            [
              -75.834948,
              39.108437
            ],
            [
              -75.848257,
              39.085765
            ],
            [
              -75.848721,
              39.071654
            ],
            [
              -75.86968,
              39.057347
            ],
            [
              -75.890821,
              39.018254
            ],
            [
              -75.919641,
              39.000112
            ],
            [
              -75.940933,
              38.976295
            ],
            [
              -75.938378,
              38.960723
            ],
            [
              -75.953055,
              38.93866
            ],
            [
              -75.949335,
              38.918333
            ],
            [
              -75.949638,
              38.884807
            ],
            [
              -75.922797,
              38.854785
            ],
            [
              -75.927771,
              38.838893
            ],
            [
              -75.896316,
              38.812454
            ],
            [
              -75.910003,
              38.806446
            ],
            [
              -75.932518,
              38.796138
            ],
            [
              -75.949455,
              38.778419
            ],
            [
              -75.966369,
              38.779762
            ],
            [
              -75.999336,
              38.752075
            ],
            [
              -75.99459,
              38.741886
            ],
            [
              -76.015045,
              38.728687
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "24",
      "COUNTYFP": "045",
      "COUNTYNS": "01668606",
      "AFFGEOID": "0500000US24045",
      "GEOID": "24045",
      "NAME": "Wicomico",
      "LSAD": "06",
      "ALAND": 969736578,
      "AWATER": 66797773,
      "County_state": "Wicomico,24"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -75.917297,
              38.263126
            ],
            [
              -75.911143,
              38.257951
            ],
            [
              -75.908272,
              38.252045
            ],
            [
              -75.90845,
              38.246648
            ],
            [
              -75.90004,
              38.232133
            ],
            [
              -75.895689,
              38.228561
            ],
            [
              -75.890669,
              38.228009
            ],
            [
              -75.885676,
              38.231006
            ],
            [
              -75.889356,
              38.2395
            ],
            [
              -75.888513,
              38.241423
            ],
            [
              -75.883435,
              38.244445
            ],
            [
              -75.874653,
              38.244514
            ],
            [
              -75.870318,
              38.243432
            ],
            [
              -75.8703104194948,
              38.243425189483794
            ],
            [
              -75.8646282601049,
              38.2383201947882
            ],
            [
              -75.839,
              38.253478
            ],
            [
              -75.802409,
              38.254813
            ],
            [
              -75.798945,
              38.259376
            ],
            [
              -75.795647,
              38.266151
            ],
            [
              -75.77276,
              38.272866
            ],
            [
              -75.768671,
              38.285183
            ],
            [
              -75.765587,
              38.285807
            ],
            [
              -75.762029,
              38.285991
            ],
            [
              -75.758027,
              38.285785
            ],
            [
              -75.754406,
              38.285444
            ],
            [
              -75.75295,
              38.285133
            ],
            [
              -75.752342,
              38.284788
            ],
            [
              -75.752172,
              38.283884
            ],
            [
              -75.753099,
              38.282055
            ],
            [
              -75.75297,
              38.281159
            ],
            [
              -75.751803,
              38.280697
            ],
            [
              -75.749651,
              38.281108
            ],
            [
              -75.749182,
              38.281667
            ],
            [
              -75.749238,
              38.282594
            ],
            [
              -75.749926,
              38.283822
            ],
            [
              -75.749867,
              38.284314
            ],
            [
              -75.748969,
              38.284713
            ],
            [
              -75.74855,
              38.284606
            ],
            [
              -75.747647,
              38.283553
            ],
            [
              -75.746993,
              38.281745
            ],
            [
              -75.745937,
              38.280426
            ],
            [
              -75.741649,
              38.279155
            ],
            [
              -75.740411,
              38.279359
            ],
            [
              -75.73749,
              38.2819
            ],
            [
              -75.735714,
              38.282834
            ],
            [
              -75.734516,
              38.283038
            ],
            [
              -75.731134,
              38.281014
            ],
            [
              -75.728455,
              38.280952
            ],
            [
              -75.727931,
              38.279756
            ],
            [
              -75.729164,
              38.277231
            ],
            [
              -75.728815,
              38.275892
            ],
            [
              -75.727427,
              38.275409
            ],
            [
              -75.724354,
              38.276248
            ],
            [
              -75.723477,
              38.275784
            ],
            [
              -75.723065,
              38.274694
            ],
            [
              -75.723822,
              38.271594
            ],
            [
              -75.720419,
              38.266644
            ],
            [
              -75.694432,
              38.266904
            ],
            [
              -75.693576,
              38.268782
            ],
            [
              -75.696405,
              38.270675
            ],
            [
              -75.696404,
              38.274194
            ],
            [
              -75.663404,
              38.2989
            ],
            [
              -75.612764,
              38.278626
            ],
            [
              -75.45652,
              38.286199
            ],
            [
              -75.372587,
              38.28971
            ],
            [
              -75.356276,
              38.313164
            ],
            [
              -75.323205,
              38.331897
            ],
            [
              -75.307011,
              38.35476
            ],
            [
              -75.329739,
              38.38129
            ],
            [
              -75.317104,
              38.404243
            ],
            [
              -75.3412457132725,
              38.4519684371695
            ],
            [
              -75.341341,
              38.451969
            ],
            [
              -75.355892,
              38.452007
            ],
            [
              -75.371154,
              38.452106
            ],
            [
              -75.39367,
              38.452113
            ],
            [
              -75.394893,
              38.452159
            ],
            [
              -75.410996,
              38.452399
            ],
            [
              -75.424948,
              38.452609
            ],
            [
              -75.4286445672873,
              38.4526668477692
            ],
            [
              -75.428846,
              38.45267
            ],
            [
              -75.47753923468429,
              38.4536624588151
            ],
            [
              -75.479283,
              38.453698
            ],
            [
              -75.500282,
              38.454143
            ],
            [
              -75.503102,
              38.454219
            ],
            [
              -75.521451,
              38.454656
            ],
            [
              -75.522877,
              38.454656
            ],
            [
              -75.533913,
              38.454957
            ],
            [
              -75.55937,
              38.455562
            ],
            [
              -75.560093,
              38.455578
            ],
            [
              -75.574273,
              38.455991
            ],
            [
              -75.583767,
              38.456424
            ],
            [
              -75.589475,
              38.456286
            ],
            [
              -75.593251,
              38.456404
            ],
            [
              -75.598239,
              38.456855
            ],
            [
              -75.630637,
              38.457904
            ],
            [
              -75.64808157468919,
              38.45836438557149
            ],
            [
              -75.663034,
              38.458759
            ],
            [
              -75.665776,
              38.4589
            ],
            [
              -75.693721,
              38.460128
            ],
            [
              -75.69657,
              38.492383
            ],
            [
              -75.696889,
              38.496479
            ],
            [
              -75.698979,
              38.522021
            ],
            [
              -75.7003726990414,
              38.5426056268973
            ],
            [
              -75.700382,
              38.542743
            ],
            [
              -75.7017643841958,
              38.56072097628319
            ],
            [
              -75.7017678069245,
              38.560765489042794
            ],
            [
              -75.72449,
              38.54123
            ],
            [
              -75.74489,
              38.537775
            ],
            [
              -75.752648,
              38.529754
            ],
            [
              -75.757059,
              38.513363
            ],
            [
              -75.79466,
              38.492373
            ],
            [
              -75.807773,
              38.489962
            ],
            [
              -75.815193,
              38.488794
            ],
            [
              -75.821086,
              38.483662
            ],
            [
              -75.814901,
              38.466459
            ],
            [
              -75.836104,
              38.445631
            ],
            [
              -75.819829,
              38.435886
            ],
            [
              -75.845573,
              38.421868
            ],
            [
              -75.851258,
              38.400348
            ],
            [
              -75.838369,
              38.387476
            ],
            [
              -75.860851,
              38.383577
            ],
            [
              -75.86248163096751,
              38.352132949389194
            ],
            [
              -75.8618,
              38.34757
            ],
            [
              -75.871649,
              38.332259
            ],
            [
              -75.882478,
              38.329114
            ],
            [
              -75.893055,
              38.309161
            ],
            [
              -75.903156,
              38.297877
            ],
            [
              -75.901373,
              38.286031
            ],
            [
              -75.907672,
              38.272597
            ],
            [
              -75.917297,
              38.263126
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "24",
      "COUNTYFP": "037",
      "COUNTYNS": "01697853",
      "AFFGEOID": "0500000US24037",
      "GEOID": "24037",
      "NAME": "St. Mary's",
      "LSAD": "06",
      "ALAND": 928885370,
      "AWATER": 1050517131,
      "County_state": "St. Mary's,24"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.87183,
              38.388865
            ],
            [
              -76.874134,
              38.380715
            ],
            [
              -76.849933,
              38.365815
            ],
            [
              -76.84788460590079,
              38.355397489319195
            ],
            [
              -76.8382913055499,
              38.3516977314882
            ],
            [
              -76.8373640467522,
              38.3513401243173
            ],
            [
              -76.827106,
              38.347384
            ],
            [
              -76.841605,
              38.336758
            ],
            [
              -76.83280519830409,
              38.3302230636794
            ],
            [
              -76.832692,
              38.330139
            ],
            [
              -76.825205,
              38.311399
            ],
            [
              -76.818431,
              38.295918
            ],
            [
              -76.802347,
              38.280743
            ],
            [
              -76.80514680505401,
              38.2543750062778
            ],
            [
              -76.805224,
              38.253648
            ],
            [
              -76.805949,
              38.252275
            ],
            [
              -76.811927,
              38.252115
            ],
            [
              -76.811647,
              38.250129
            ],
            [
              -76.797452,
              38.236918
            ],
            [
              -76.7798651400913,
              38.2290264722734
            ],
            [
              -76.778625,
              38.22847
            ],
            [
              -76.752286,
              38.222121
            ],
            [
              -76.74426454882911,
              38.230716302023204
            ],
            [
              -76.740055,
              38.235227
            ],
            [
              -76.71960223628429,
              38.2335029526848
            ],
            [
              -76.716376,
              38.233231
            ],
            [
              -76.7125942748302,
              38.2333341043121
            ],
            [
              -76.68707537472969,
              38.2340298472658
            ],
            [
              -76.673462,
              38.234401
            ],
            [
              -76.643929,
              38.22508
            ],
            [
              -76.632544,
              38.225657
            ],
            [
              -76.590637,
              38.214212
            ],
            [
              -76.5715673161264,
              38.201895836051996
            ],
            [
              -76.566297,
              38.198492
            ],
            [
              -76.552957,
              38.187209
            ],
            [
              -76.547333,
              38.175673
            ],
            [
              -76.545335,
              38.165373
            ],
            [
              -76.54038,
              38.152991
            ],
            [
              -76.529868,
              38.134083
            ],
            [
              -76.522418,
              38.139391
            ],
            [
              -76.514824,
              38.141219
            ],
            [
              -76.508825,
              38.140713
            ],
            [
              -76.4999218925434,
              38.1372203415699
            ],
            [
              -76.499842,
              38.137189
            ],
            [
              -76.49288456924289,
              38.1293029745816
            ],
            [
              -76.481036,
              38.115873
            ],
            [
              -76.476222,
              38.104709
            ],
            [
              -76.473266,
              38.103035
            ],
            [
              -76.46533,
              38.10583
            ],
            [
              -76.466404,
              38.111392
            ],
            [
              -76.469738,
              38.115534
            ],
            [
              -76.4697947292535,
              38.1190606685936
            ],
            [
              -76.469798,
              38.119264
            ],
            [
              -76.4653251781718,
              38.12746468116529
            ],
            [
              -76.46099,
              38.135413
            ],
            [
              -76.469666,
              38.153358
            ],
            [
              -76.4586429369345,
              38.156035336565196
            ],
            [
              -76.438886,
              38.160834
            ],
            [
              -76.43700636637149,
              38.1530858294566
            ],
            [
              -76.43544,
              38.146629
            ],
            [
              -76.439841,
              38.138933
            ],
            [
              -76.429581,
              38.126165
            ],
            [
              -76.430425,
              38.119383
            ],
            [
              -76.421214,
              38.106039
            ],
            [
              -76.41655,
              38.104459
            ],
            [
              -76.4144749643034,
              38.105943354443696
            ],
            [
              -76.41316,
              38.106884
            ],
            [
              -76.4059717661951,
              38.1069670263145
            ],
            [
              -76.405368,
              38.106974
            ],
            [
              -76.392335,
              38.102896
            ],
            [
              -76.37179,
              38.079565
            ],
            [
              -76.361237,
              38.059542
            ],
            [
              -76.35275,
              38.053182
            ],
            [
              -76.341404,
              38.053697
            ],
            [
              -76.332812,
              38.049938
            ],
            [
              -76.326994,
              38.045105
            ],
            [
              -76.322093,
              38.036503
            ],
            [
              -76.319476,
              38.043315
            ],
            [
              -76.329308,
              38.07166
            ],
            [
              -76.330794,
              38.099331
            ],
            [
              -76.337402,
              38.11082
            ],
            [
              -76.337410956422,
              38.1108883944954
            ],
            [
              -76.338535,
              38.119472
            ],
            [
              -76.3381606110536,
              38.1198559119654
            ],
            [
              -76.320136,
              38.138339
            ],
            [
              -76.329705,
              38.155184
            ],
            [
              -76.353516,
              38.178135
            ],
            [
              -76.361877,
              38.192048
            ],
            [
              -76.385244,
              38.217751
            ],
            [
              -76.39932,
              38.259284
            ],
            [
              -76.398852,
              38.266997
            ],
            [
              -76.394171,
              38.278233
            ],
            [
              -76.374481,
              38.296348
            ],
            [
              -76.375023,
              38.299419
            ],
            [
              -76.381493,
              38.30313
            ],
            [
              -76.402894,
              38.311402
            ],
            [
              -76.408938,
              38.304498
            ],
            [
              -76.423912,
              38.300675
            ],
            [
              -76.437579,
              38.292654
            ],
            [
              -76.4506218493638,
              38.293133321069895
            ],
            [
              -76.45279,
              38.293213
            ],
            [
              -76.463842,
              38.299276
            ],
            [
              -76.4757292171369,
              38.312867378644896
            ],
            [
              -76.476321,
              38.313544
            ],
            [
              -76.47085347042719,
              38.323950707178696
            ],
            [
              -76.487575,
              38.326257
            ],
            [
              -76.48313,
              38.342446
            ],
            [
              -76.501475,
              38.372497
            ],
            [
              -76.534075,
              38.397766
            ],
            [
              -76.553435,
              38.395043
            ],
            [
              -76.563902,
              38.406002
            ],
            [
              -76.597528,
              38.423425
            ],
            [
              -76.608373,
              38.424445
            ],
            [
              -76.624192,
              38.443498
            ],
            [
              -76.654345,
              38.465174
            ],
            [
              -76.652142,
              38.473753
            ],
            [
              -76.674118,
              38.499622
            ],
            [
              -76.685446,
              38.49497
            ],
            [
              -76.699317,
              38.497125
            ],
            [
              -76.700082,
              38.498591
            ],
            [
              -76.700012,
              38.499129
            ],
            [
              -76.701475,
              38.500195
            ],
            [
              -76.702203,
              38.501668
            ],
            [
              -76.703316,
              38.502401
            ],
            [
              -76.705271,
              38.501663
            ],
            [
              -76.705307,
              38.502547
            ],
            [
              -76.706614,
              38.504294
            ],
            [
              -76.708448,
              38.504838
            ],
            [
              -76.708702,
              38.506466
            ],
            [
              -76.70995,
              38.508065
            ],
            [
              -76.710413,
              38.507453
            ],
            [
              -76.71053,
              38.50917
            ],
            [
              -76.71184,
              38.509871
            ],
            [
              -76.713459,
              38.5095
            ],
            [
              -76.71354,
              38.51034
            ],
            [
              -76.774639,
              38.508994
            ],
            [
              -76.820861,
              38.434016
            ],
            [
              -76.850317,
              38.398336
            ],
            [
              -76.87181,
              38.388973
            ],
            [
              -76.87183,
              38.388865
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "24",
      "COUNTYFP": "013",
      "COUNTYNS": "01696228",
      "AFFGEOID": "0500000US24013",
      "GEOID": "24013",
      "NAME": "Carroll",
      "LSAD": "06",
      "ALAND": 1159279222,
      "AWATER": 13186154,
      "County_state": "Carroll,24"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.311161,
              39.639733
            ],
            [
              -77.300552,
              39.625156
            ],
            [
              -77.307416,
              39.619101
            ],
            [
              -77.287747,
              39.604516
            ],
            [
              -77.271574,
              39.606221
            ],
            [
              -77.263701,
              39.594767
            ],
            [
              -77.256509,
              39.603771
            ],
            [
              -77.222103,
              39.576398
            ],
            [
              -77.198024,
              39.57543
            ],
            [
              -77.169445,
              39.554118
            ],
            [
              -77.170746,
              39.534753
            ],
            [
              -77.15234,
              39.532498
            ],
            [
              -77.109998,
              39.49748
            ],
            [
              -77.106747,
              39.491859
            ],
            [
              -77.117686,
              39.478619
            ],
            [
              -77.11605,
              39.460344
            ],
            [
              -77.14717,
              39.419005
            ],
            [
              -77.158185,
              39.373451
            ],
            [
              -77.16808,
              39.353957
            ],
            [
              -77.15177,
              39.348631
            ],
            [
              -77.10136,
              39.369323
            ],
            [
              -77.064961,
              39.361935
            ],
            [
              -77.025374,
              39.350593
            ],
            [
              -76.969697,
              39.363982
            ],
            [
              -76.929313,
              39.353951
            ],
            [
              -76.882274,
              39.350185
            ],
            [
              -76.873378,
              39.35715
            ],
            [
              -76.888949,
              39.370569
            ],
            [
              -76.891332,
              39.382229
            ],
            [
              -76.873439,
              39.388865
            ],
            [
              -76.884117,
              39.396568
            ],
            [
              -76.881429,
              39.417403
            ],
            [
              -76.89556,
              39.429487
            ],
            [
              -76.8873,
              39.440502
            ],
            [
              -76.8931,
              39.457402
            ],
            [
              -76.869309,
              39.482459
            ],
            [
              -76.8667,
              39.500902
            ],
            [
              -76.831244,
              39.60218
            ],
            [
              -76.804304,
              39.675882
            ],
            [
              -76.7870972309155,
              39.721085744246494
            ],
            [
              -76.78727,
              39.721085
            ],
            [
              -76.806568,
              39.720883
            ],
            [
              -76.809368,
              39.720983
            ],
            [
              -76.88124901711859,
              39.7207075529518
            ],
            [
              -76.890266,
              39.720673
            ],
            [
              -76.897732,
              39.720672
            ],
            [
              -76.936764,
              39.720968
            ],
            [
              -76.991062,
              39.720062
            ],
            [
              -76.9993180535053,
              39.7200905005584
            ],
            [
              -77.04726,
              39.720256
            ],
            [
              -77.058359,
              39.720455
            ],
            [
              -77.059059,
              39.720354
            ],
            [
              -77.216951,
              39.720236
            ],
            [
              -77.2170240489139,
              39.720235990471494
            ],
            [
              -77.226338,
              39.706238
            ],
            [
              -77.21527,
              39.699018
            ],
            [
              -77.242465,
              39.695282
            ],
            [
              -77.234312,
              39.678196
            ],
            [
              -77.262151,
              39.665168
            ],
            [
              -77.275617,
              39.647125
            ],
            [
              -77.275846,
              39.63266
            ],
            [
              -77.291457,
              39.63058
            ],
            [
              -77.311161,
              39.639733
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "24",
      "COUNTYFP": "019",
      "COUNTYNS": "00596495",
      "AFFGEOID": "0500000US24019",
      "GEOID": "24019",
      "NAME": "Dorchester",
      "LSAD": "06",
      "ALAND": 1400542024,
      "AWATER": 1145384767,
      "County_state": "Dorchester,24"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.095548,
              38.125123
            ],
            [
              -76.090872,
              38.11446
            ],
            [
              -76.085947,
              38.116658
            ],
            [
              -76.085311,
              38.118561
            ],
            [
              -76.08112659721901,
              38.1200138626496
            ],
            [
              -76.061,
              38.127002
            ],
            [
              -76.056811,
              38.125123
            ],
            [
              -76.0483727775301,
              38.1205503002675
            ],
            [
              -76.0314419397404,
              38.147612216406394
            ],
            [
              -76.031868,
              38.148288
            ],
            [
              -76.03194547044629,
              38.148631226207804
            ],
            [
              -76.034038,
              38.157902
            ],
            [
              -76.032816,
              38.169141
            ],
            [
              -76.021941,
              38.171976
            ],
            [
              -76.02217,
              38.177882
            ],
            [
              -76.048786,
              38.203731
            ],
            [
              -76.0677965815324,
              38.2035390839724
            ],
            [
              -76.07147,
              38.203502
            ],
            [
              -76.088639,
              38.192649
            ],
            [
              -76.092334,
              38.151355
            ],
            [
              -76.089017,
              38.141033
            ],
            [
              -76.09052733049239,
              38.1319191919797
            ],
            [
              -76.090649,
              38.131185
            ],
            [
              -76.095548,
              38.125123
            ]
          ]
        ],
        [
          [
            [
              -76.33636,
              38.492235
            ],
            [
              -76.33198992299589,
              38.4752252876536
            ],
            [
              -76.331559,
              38.473548
            ],
            [
              -76.320843,
              38.459862
            ],
            [
              -76.301488,
              38.439767
            ],
            [
              -76.28302,
              38.413512
            ],
            [
              -76.280551,
              38.403143
            ],
            [
              -76.282271,
              38.393118
            ],
            [
              -76.270195,
              38.374591
            ],
            [
              -76.25,
              38.362304
            ],
            [
              -76.265548,
              38.34093
            ],
            [
              -76.257667,
              38.324855
            ],
            [
              -76.23011,
              38.319778
            ],
            [
              -76.2283590906131,
              38.317803200623494
            ],
            [
              -76.22463394727589,
              38.3136017206413
            ],
            [
              -76.217613,
              38.305683
            ],
            [
              -76.2014411601434,
              38.2880694599424
            ],
            [
              -76.199744,
              38.286221
            ],
            [
              -76.19060583238101,
              38.274167733338295
            ],
            [
              -76.189277,
              38.272415
            ],
            [
              -76.18254,
              38.26107
            ],
            [
              -76.165489,
              38.242797
            ],
            [
              -76.1651882734745,
              38.242637960828695
            ],
            [
              -76.15098086763179,
              38.2351243766918
            ],
            [
              -76.149787,
              38.234493
            ],
            [
              -76.135513,
              38.232185
            ],
            [
              -76.125717,
              38.239017
            ],
            [
              -76.09972,
              38.253647
            ],
            [
              -76.09468863968519,
              38.2533498572605
            ],
            [
              -76.074897,
              38.252181
            ],
            [
              -76.069502,
              38.238455
            ],
            [
              -76.05801,
              38.227079
            ],
            [
              -76.032044,
              38.216684
            ],
            [
              -76.035695,
              38.230556
            ],
            [
              -76.044108,
              38.241682
            ],
            [
              -76.044251,
              38.249373
            ],
            [
              -76.038935,
              38.254932
            ],
            [
              -76.027487,
              38.280108
            ],
            [
              -76.030532,
              38.28796
            ],
            [
              -76.05022,
              38.304101
            ],
            [
              -76.04958,
              38.309594
            ],
            [
              -76.045599,
              38.318246
            ],
            [
              -76.041618,
              38.322137
            ],
            [
              -76.033947,
              38.323211
            ],
            [
              -76.016682,
              38.332429
            ],
            [
              -76.010217,
              38.353211
            ],
            [
              -76.012149,
              38.357077
            ],
            [
              -76.011869,
              38.360582
            ],
            [
              -76.006949,
              38.370216
            ],
            [
              -76.002282,
              38.374477
            ],
            [
              -75.973876,
              38.36585
            ],
            [
              -75.961948,
              38.341431
            ],
            [
              -75.961944,
              38.332572
            ],
            [
              -75.964237,
              38.324285
            ],
            [
              -75.96929,
              38.320164
            ],
            [
              -75.981345,
              38.315147
            ],
            [
              -76.008647,
              38.312261
            ],
            [
              -76.017364,
              38.309106
            ],
            [
              -76.016291,
              38.307206
            ],
            [
              -76.007375,
              38.304318
            ],
            [
              -75.990385,
              38.282915
            ],
            [
              -75.9878144164712,
              38.2792874960224
            ],
            [
              -75.985815,
              38.276466
            ],
            [
              -75.984274,
              38.265155
            ],
            [
              -75.972395,
              38.25364
            ],
            [
              -75.968749,
              38.245908
            ],
            [
              -75.972212,
              38.2333
            ],
            [
              -75.97148711733179,
              38.233457070087404
            ],
            [
              -75.946414,
              38.23889
            ],
            [
              -75.940697,
              38.246902
            ],
            [
              -75.9445,
              38.249145
            ],
            [
              -75.948796,
              38.248802
            ],
            [
              -75.954542,
              38.252894
            ],
            [
              -75.954908,
              38.263998
            ],
            [
              -75.951497,
              38.266949
            ],
            [
              -75.938577,
              38.272329
            ],
            [
              -75.940828,
              38.298436
            ],
            [
              -75.92728,
              38.321376
            ],
            [
              -75.914565,
              38.339275
            ],
            [
              -75.889846,
              38.351671
            ],
            [
              -75.863582,
              38.359499
            ],
            [
              -75.8635690913394,
              38.3594125873108
            ],
            [
              -75.86248163096751,
              38.352132949389194
            ],
            [
              -75.860851,
              38.383577
            ],
            [
              -75.838369,
              38.387476
            ],
            [
              -75.851258,
              38.400348
            ],
            [
              -75.845573,
              38.421868
            ],
            [
              -75.819829,
              38.435886
            ],
            [
              -75.836104,
              38.445631
            ],
            [
              -75.814901,
              38.466459
            ],
            [
              -75.821086,
              38.483662
            ],
            [
              -75.815193,
              38.488794
            ],
            [
              -75.807773,
              38.489962
            ],
            [
              -75.79466,
              38.492373
            ],
            [
              -75.757059,
              38.513363
            ],
            [
              -75.752648,
              38.529754
            ],
            [
              -75.74489,
              38.537775
            ],
            [
              -75.72449,
              38.54123
            ],
            [
              -75.7017678069245,
              38.560765489042794
            ],
            [
              -75.701768,
              38.560768
            ],
            [
              -75.703649,
              38.585159
            ],
            [
              -75.704185,
              38.592107
            ],
            [
              -75.705979,
              38.614788
            ],
            [
              -75.706065,
              38.616317
            ],
            [
              -75.70644,
              38.621346
            ],
            [
              -75.70679,
              38.626177
            ],
            [
              -75.707551,
              38.635335
            ],
            [
              -75.7075549587544,
              38.6353853617286
            ],
            [
              -75.763008,
              38.686203
            ],
            [
              -75.798408,
              38.676338
            ],
            [
              -75.800277,
              38.695983
            ],
            [
              -75.837974,
              38.70399
            ],
            [
              -75.864318,
              38.697937
            ],
            [
              -75.870467,
              38.694252
            ],
            [
              -75.888985,
              38.695474
            ],
            [
              -75.897089,
              38.701322
            ],
            [
              -75.904861,
              38.687648
            ],
            [
              -75.937728,
              38.677112
            ],
            [
              -75.945248,
              38.674109
            ],
            [
              -75.954368,
              38.665691
            ],
            [
              -75.957923,
              38.648036
            ],
            [
              -75.983512,
              38.625114
            ],
            [
              -75.980934,
              38.610054
            ],
            [
              -75.991127,
              38.592483
            ],
            [
              -76.0291249890344,
              38.572267444822295
            ],
            [
              -76.026512,
              38.56682
            ],
            [
              -76.0281363364008,
              38.565754804579896
            ],
            [
              -76.041046,
              38.557289
            ],
            [
              -76.056816,
              38.568028
            ],
            [
              -76.078445,
              38.582986
            ],
            [
              -76.088903,
              38.590232
            ],
            [
              -76.111126,
              38.584008
            ],
            [
              -76.126931,
              38.591439
            ],
            [
              -76.138697,
              38.601749
            ],
            [
              -76.151079,
              38.594711
            ],
            [
              -76.165911,
              38.598684
            ],
            [
              -76.165435,
              38.6102
            ],
            [
              -76.170066,
              38.629225
            ],
            [
              -76.174969,
              38.628791
            ],
            [
              -76.190902,
              38.621092
            ],
            [
              -76.202598,
              38.613011
            ],
            [
              -76.203065,
              38.610741
            ],
            [
              -76.212427,
              38.606738
            ],
            [
              -76.231187,
              38.61401
            ],
            [
              -76.23665,
              38.628598
            ],
            [
              -76.24651,
              38.626282
            ],
            [
              -76.264155,
              38.615109
            ],
            [
              -76.271827,
              38.615661
            ],
            [
              -76.279589,
              38.60952
            ],
            [
              -76.2725843720512,
              38.6019968659116
            ],
            [
              -76.268633,
              38.597753
            ],
            [
              -76.273496,
              38.59139
            ],
            [
              -76.29089532479509,
              38.5825480713718
            ],
            [
              -76.305172,
              38.575293
            ],
            [
              -76.308321,
              38.571769
            ],
            [
              -76.290043,
              38.569158
            ],
            [
              -76.28318863858689,
              38.5612995006658
            ],
            [
              -76.27964,
              38.557231
            ],
            [
              -76.275913,
              38.548809
            ],
            [
              -76.277461,
              38.541851
            ],
            [
              -76.281047,
              38.53613
            ],
            [
              -76.278106,
              38.532468
            ],
            [
              -76.274057,
              38.531207
            ],
            [
              -76.253624,
              38.539393
            ],
            [
              -76.2510332931782,
              38.5391907291572
            ],
            [
              -76.2502650674391,
              38.5391307495152
            ],
            [
              -76.248885,
              38.539023
            ],
            [
              -76.244396,
              38.536966
            ],
            [
              -76.2473,
              38.523818
            ],
            [
              -76.24789353599068,
              38.523019205164395
            ],
            [
              -76.26035,
              38.506255
            ],
            [
              -76.26213290815559,
              38.5048737143283
            ],
            [
              -76.263968,
              38.503452
            ],
            [
              -76.2816973708371,
              38.50216960241289
            ],
            [
              -76.281761,
              38.502165
            ],
            [
              -76.283595,
              38.504157
            ],
            [
              -76.289507,
              38.503906
            ],
            [
              -76.318054,
              38.498199
            ],
            [
              -76.327257,
              38.500121
            ],
            [
              -76.33636,
              38.492235
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "24",
      "COUNTYFP": "023",
      "COUNTYNS": "01711058",
      "AFFGEOID": "0500000US24023",
      "GEOID": "24023",
      "NAME": "Garrett",
      "LSAD": "06",
      "ALAND": 1681276216,
      "AWATER": 22324651,
      "County_state": "Garrett,24"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.486812,
              39.296367
            ],
            [
              -79.487651,
              39.279933
            ],
            [
              -79.486737,
              39.278149
            ],
            [
              -79.487274,
              39.265205
            ],
            [
              -79.486179,
              39.26497
            ],
            [
              -79.485874,
              39.264905
            ],
            [
              -79.486873,
              39.205961
            ],
            [
              -79.486862069471,
              39.20595874752019
            ],
            [
              -79.476037,
              39.203728
            ],
            [
              -79.469156,
              39.2073
            ],
            [
              -79.452685,
              39.211719
            ],
            [
              -79.43983,
              39.217074
            ],
            [
              -79.424413,
              39.228171
            ],
            [
              -79.425059,
              39.233686
            ],
            [
              -79.42035,
              39.23888
            ],
            [
              -79.412051,
              39.240546
            ],
            [
              -79.387023,
              39.26554
            ],
            [
              -79.376154,
              39.273154
            ],
            [
              -79.361343,
              39.274924
            ],
            [
              -79.35375,
              39.278039
            ],
            [
              -79.343801,
              39.286096
            ],
            [
              -79.343625,
              39.287148
            ],
            [
              -79.345599,
              39.289733
            ],
            [
              -79.344344,
              39.293534
            ],
            [
              -79.33238,
              39.299919
            ],
            [
              -79.314768,
              39.304381
            ],
            [
              -79.302311,
              39.299554
            ],
            [
              -79.29271,
              39.298729
            ],
            [
              -79.290236,
              39.299323
            ],
            [
              -79.283723,
              39.30964
            ],
            [
              -79.282037,
              39.323048
            ],
            [
              -79.2722802951283,
              39.3289642342356
            ],
            [
              -79.269365,
              39.330732
            ],
            [
              -79.255306,
              39.335874
            ],
            [
              -79.253891,
              39.337222
            ],
            [
              -79.253928,
              39.354085
            ],
            [
              -79.25227,
              39.356663
            ],
            [
              -79.235878,
              39.358689
            ],
            [
              -79.229247,
              39.363662
            ],
            [
              -79.220357,
              39.363157
            ],
            [
              -79.213961,
              39.36532
            ],
            [
              -79.202943,
              39.377872
            ],
            [
              -79.197937,
              39.386132
            ],
            [
              -79.195543,
              39.38779
            ],
            [
              -79.193332,
              39.387974
            ],
            [
              -79.189465,
              39.3865
            ],
            [
              -79.179335,
              39.388342
            ],
            [
              -79.176977,
              39.39213
            ],
            [
              -79.1746,
              39.392756
            ],
            [
              -79.170494,
              39.392026
            ],
            [
              -79.16722,
              39.393256
            ],
            [
              -79.165593,
              39.397134
            ],
            [
              -79.166497,
              39.400888
            ],
            [
              -79.16134,
              39.411895
            ],
            [
              -79.159213,
              39.413021
            ],
            [
              -79.157212,
              39.413021
            ],
            [
              -79.153584,
              39.41202
            ],
            [
              -79.151583,
              39.408768
            ],
            [
              -79.149581,
              39.407767
            ],
            [
              -79.147455,
              39.407767
            ],
            [
              -79.145453,
              39.407767
            ],
            [
              -79.143827,
              39.408517
            ],
            [
              -79.142701,
              39.410519
            ],
            [
              -79.14195,
              39.414272
            ],
            [
              -79.140699,
              39.416649
            ],
            [
              -79.136696,
              39.417649
            ],
            [
              -79.132193,
              39.418275
            ],
            [
              -79.129816,
              39.419901
            ],
            [
              -79.128941,
              39.423279
            ],
            [
              -79.129404,
              39.426637
            ],
            [
              -79.129047,
              39.429542
            ],
            [
              -79.124036,
              39.433204
            ],
            [
              -79.12156,
              39.432786
            ],
            [
              -79.119433,
              39.433161
            ],
            [
              -79.117932,
              39.434412
            ],
            [
              -79.116932,
              39.435788
            ],
            [
              -79.116574,
              39.438058
            ],
            [
              -79.116369,
              39.440482
            ],
            [
              -79.11407,
              39.443321
            ],
            [
              -79.107933,
              39.445748
            ],
            [
              -79.104217,
              39.448358
            ],
            [
              -79.095428,
              39.462548
            ],
            [
              -79.096154,
              39.465542
            ],
            [
              -79.09824,
              39.468445
            ],
            [
              -79.099057,
              39.470259
            ],
            [
              -79.098875,
              39.471438
            ],
            [
              -79.098059,
              39.472073
            ],
            [
              -79.096517,
              39.472799
            ],
            [
              -79.094702,
              39.473253
            ],
            [
              -79.091329,
              39.472407
            ],
            [
              -79.08327,
              39.471379
            ],
            [
              -79.068627,
              39.474515
            ],
            [
              -79.067559,
              39.479443
            ],
            [
              -78.99867,
              39.599942
            ],
            [
              -78.9284160738903,
              39.7228359036597
            ],
            [
              -78.931211,
              39.722832
            ],
            [
              -79.045576,
              39.722928
            ],
            [
              -79.056531326389,
              39.722880985358294
            ],
            [
              -79.3924584050224,
              39.7214393586401
            ],
            [
              -79.476662,
              39.721078
            ],
            [
              -79.476574,
              39.644206
            ],
            [
              -79.476968,
              39.642986
            ],
            [
              -79.477764,
              39.642282
            ],
            [
              -79.477888644888,
              39.6297730598018
            ],
            [
              -79.478866,
              39.531689
            ],
            [
              -79.482366,
              39.531689
            ],
            [
              -79.482354,
              39.524682
            ],
            [
              -79.482648,
              39.521364
            ],
            [
              -79.484372,
              39.3443
            ],
            [
              -79.486072,
              39.3443
            ],
            [
              -79.486812,
              39.296367
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "24",
      "COUNTYFP": "029",
      "COUNTYNS": "00593907",
      "AFFGEOID": "0500000US24029",
      "GEOID": "24029",
      "NAME": "Kent",
      "LSAD": "06",
      "ALAND": 717515665,
      "AWATER": 353303064,
      "County_state": "Kent,24"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.274637,
              39.16549
            ],
            [
              -76.278527,
              39.145764
            ],
            [
              -76.260894,
              39.143402
            ],
            [
              -76.252968,
              39.133626
            ],
            [
              -76.233457,
              39.091385
            ],
            [
              -76.231748,
              39.082826
            ],
            [
              -76.231117,
              39.061017
            ],
            [
              -76.2312120630761,
              39.0607693373524
            ],
            [
              -76.237065,
              39.045521
            ],
            [
              -76.2375002635461,
              39.0448722986265
            ],
            [
              -76.240905,
              39.039798
            ],
            [
              -76.242687,
              39.028926
            ],
            [
              -76.231765,
              39.018518
            ],
            [
              -76.209114,
              39.01001
            ],
            [
              -76.200666,
              39.01452
            ],
            [
              -76.208502,
              39.024818
            ],
            [
              -76.212616,
              39.041158
            ],
            [
              -76.2118379274381,
              39.048183919692804
            ],
            [
              -76.2108755559905,
              39.0568740404307
            ],
            [
              -76.210041,
              39.06441
            ],
            [
              -76.203383,
              39.085626
            ],
            [
              -76.183908,
              39.096344
            ],
            [
              -76.16734708652659,
              39.0948390065724
            ],
            [
              -76.1580904714523,
              39.0939978002662
            ],
            [
              -76.117058,
              39.107098
            ],
            [
              -76.094154,
              39.126854
            ],
            [
              -76.072418,
              39.138206
            ],
            [
              -76.081596,
              39.147659
            ],
            [
              -76.04763,
              39.162666
            ],
            [
              -76.039955,
              39.169971
            ],
            [
              -76.046661,
              39.180751
            ],
            [
              -76.064438,
              39.184643
            ],
            [
              -76.068794,
              39.192345
            ],
            [
              -76.056556,
              39.210338
            ],
            [
              -76.009437,
              39.240097
            ],
            [
              -75.987808,
              39.239295
            ],
            [
              -75.987083,
              39.239646
            ],
            [
              -75.986213,
              39.240451
            ],
            [
              -75.985855,
              39.241359
            ],
            [
              -75.985748,
              39.242385
            ],
            [
              -75.986145,
              39.244232
            ],
            [
              -75.986068,
              39.245086
            ],
            [
              -75.985778,
              39.245632
            ],
            [
              -75.985298,
              39.246021
            ],
            [
              -75.984771,
              39.246082
            ],
            [
              -75.984039,
              39.24596
            ],
            [
              -75.982955,
              39.245658
            ],
            [
              -75.981758,
              39.245273
            ],
            [
              -75.980407,
              39.244934
            ],
            [
              -75.978965,
              39.244731
            ],
            [
              -75.977264,
              39.244789
            ],
            [
              -75.972999,
              39.246139
            ],
            [
              -75.97039,
              39.246356
            ],
            [
              -75.962615,
              39.241989
            ],
            [
              -75.940711,
              39.238494
            ],
            [
              -75.92363,
              39.244102
            ],
            [
              -75.921249,
              39.243462
            ],
            [
              -75.919563,
              39.243172
            ],
            [
              -75.917557,
              39.243298
            ],
            [
              -75.912643,
              39.244499
            ],
            [
              -75.897852,
              39.246888
            ],
            [
              -75.895035,
              39.247105
            ],
            [
              -75.894257,
              39.247425
            ],
            [
              -75.893906,
              39.247906
            ],
            [
              -75.894447,
              39.248905
            ],
            [
              -75.894562,
              39.250206
            ],
            [
              -75.894249,
              39.25248
            ],
            [
              -75.893135,
              39.253651
            ],
            [
              -75.891113,
              39.254074
            ],
            [
              -75.88987,
              39.253872
            ],
            [
              -75.888535,
              39.252911
            ],
            [
              -75.887581,
              39.25021
            ],
            [
              -75.886276,
              39.249168
            ],
            [
              -75.885658,
              39.249199
            ],
            [
              -75.882286,
              39.250717
            ],
            [
              -75.880051,
              39.251526
            ],
            [
              -75.878983,
              39.251129
            ],
            [
              -75.878448,
              39.25008
            ],
            [
              -75.876953,
              39.249664
            ],
            [
              -75.874084,
              39.249798
            ],
            [
              -75.867722,
              39.259483
            ],
            [
              -75.839736,
              39.2577
            ],
            [
              -75.806343,
              39.241989
            ],
            [
              -75.75617871424829,
              39.246221999941795
            ],
            [
              -75.7603074473469,
              39.2967999434366
            ],
            [
              -75.766895,
              39.377499
            ],
            [
              -75.7669076051649,
              39.3776534737305
            ],
            [
              -75.806073,
              39.375258
            ],
            [
              -75.809817,
              39.379768
            ],
            [
              -75.816313,
              39.380654
            ],
            [
              -75.818829,
              39.382299
            ],
            [
              -75.823658,
              39.381413
            ],
            [
              -75.831173,
              39.374173
            ],
            [
              -75.842385,
              39.370978
            ],
            [
              -75.85543,
              39.364562
            ],
            [
              -75.867614,
              39.367813
            ],
            [
              -75.885864,
              39.360797
            ],
            [
              -75.926589,
              39.370994
            ],
            [
              -75.942337,
              39.367752
            ],
            [
              -75.964065,
              39.374725
            ],
            [
              -75.982696,
              39.367492
            ],
            [
              -76.0024625818479,
              39.3764396702885
            ],
            [
              -76.002408,
              39.367501
            ],
            [
              -76.0065463182854,
              39.3663740310956
            ],
            [
              -76.02299,
              39.361896
            ],
            [
              -76.032923,
              39.367414
            ],
            [
              -76.049846,
              39.370644
            ],
            [
              -76.110527,
              39.372257
            ],
            [
              -76.116356,
              39.360925
            ],
            [
              -76.13495,
              39.35107
            ],
            [
              -76.136971,
              39.344414
            ],
            [
              -76.133225,
              39.340491
            ],
            [
              -76.145524,
              39.334399
            ],
            [
              -76.159673,
              39.335909
            ],
            [
              -76.170422,
              39.332094
            ],
            [
              -76.185581,
              39.319334
            ],
            [
              -76.186647,
              39.315475
            ],
            [
              -76.186024,
              39.312462
            ],
            [
              -76.179092,
              39.310098
            ],
            [
              -76.176778,
              39.306447
            ],
            [
              -76.177704,
              39.298701
            ],
            [
              -76.181496,
              39.291797
            ],
            [
              -76.185674,
              39.285367
            ],
            [
              -76.203031,
              39.269871
            ],
            [
              -76.211253,
              39.269812
            ],
            [
              -76.219338,
              39.261997
            ],
            [
              -76.232051,
              39.233341
            ],
            [
              -76.251032,
              39.199214
            ],
            [
              -76.255831,
              39.191595
            ],
            [
              -76.266602,
              39.180523
            ],
            [
              -76.274637,
              39.16549
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "24",
      "COUNTYFP": "031",
      "COUNTYNS": "01712500",
      "AFFGEOID": "0500000US24031",
      "GEOID": "24031",
      "NAME": "Montgomery",
      "LSAD": "06",
      "ALAND": 1276943098,
      "AWATER": 35936935,
      "County_state": "Montgomery,24"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.527282,
              39.146236
            ],
            [
              -77.52718420999359,
              39.1446612984714
            ],
            [
              -77.526728,
              39.137315
            ],
            [
              -77.524559,
              39.127821
            ],
            [
              -77.5209858903006,
              39.1224991502187
            ],
            [
              -77.519929,
              39.120925
            ],
            [
              -77.51532,
              39.118591
            ],
            [
              -77.499711,
              39.11395
            ],
            [
              -77.494955,
              39.113038
            ],
            [
              -77.4858,
              39.109303
            ],
            [
              -77.481279,
              39.105658
            ],
            [
              -77.47701,
              39.100331
            ],
            [
              -77.472414,
              39.092524
            ],
            [
              -77.469443,
              39.086387
            ],
            [
              -77.46914754630019,
              39.085948147808004
            ],
            [
              -77.462617,
              39.076248
            ],
            [
              -77.46145,
              39.075151
            ],
            [
              -77.458202,
              39.073723
            ],
            [
              -77.452827,
              39.072468
            ],
            [
              -77.4374,
              39.070611
            ],
            [
              -77.42318,
              39.066878
            ],
            [
              -77.41543,
              39.066435
            ],
            [
              -77.404593,
              39.064496
            ],
            [
              -77.4019135386037,
              39.064639196304
            ],
            [
              -77.399204,
              39.064784
            ],
            [
              -77.38568,
              39.061987
            ],
            [
              -77.380108,
              39.062808
            ],
            [
              -77.375079,
              39.061297
            ],
            [
              -77.367529,
              39.061087
            ],
            [
              -77.359702,
              39.062004
            ],
            [
              -77.350311,
              39.062133
            ],
            [
              -77.340287,
              39.062991
            ],
            [
              -77.335511,
              39.061771
            ],
            [
              -77.33401,
              39.060989
            ],
            [
              -77.333706,
              39.059508
            ],
            [
              -77.32828109319901,
              39.0577948715365
            ],
            [
              -77.324206,
              39.056508
            ],
            [
              -77.314905,
              39.052208
            ],
            [
              -77.310705,
              39.052008
            ],
            [
              -77.301005,
              39.049508
            ],
            [
              -77.293105,
              39.046508
            ],
            [
              -77.274706,
              39.034091
            ],
            [
              -77.266004,
              39.031909
            ],
            [
              -77.255303,
              39.030009
            ],
            [
              -77.248403,
              39.026909
            ],
            [
              -77.24838303158039,
              39.0268923596504
            ],
            [
              -77.246003,
              39.024909
            ],
            [
              -77.244603,
              39.020109
            ],
            [
              -77.246903,
              39.014809
            ],
            [
              -77.251803,
              39.011409
            ],
            [
              -77.255703,
              39.002409
            ],
            [
              -77.253003,
              38.995709
            ],
            [
              -77.249203,
              38.993709
            ],
            [
              -77.248303,
              38.992309
            ],
            [
              -77.249803,
              38.985909
            ],
            [
              -77.234803,
              38.97631
            ],
            [
              -77.232268,
              38.979502
            ],
            [
              -77.231601,
              38.979917
            ],
            [
              -77.229992,
              38.979858
            ],
            [
              -77.228395,
              38.978404
            ],
            [
              -77.224969,
              38.973349
            ],
            [
              -77.221502,
              38.97131
            ],
            [
              -77.211502,
              38.96941
            ],
            [
              -77.209302,
              38.97041
            ],
            [
              -77.203602,
              38.96891
            ],
            [
              -77.202502,
              38.96791
            ],
            [
              -77.197502,
              38.96681
            ],
            [
              -77.188302,
              38.96751
            ],
            [
              -77.183002,
              38.96881
            ],
            [
              -77.171901,
              38.96751
            ],
            [
              -77.168001,
              38.96741
            ],
            [
              -77.166901,
              38.96811
            ],
            [
              -77.165301,
              38.96801
            ],
            [
              -77.151084,
              38.965832
            ],
            [
              -77.148179,
              38.965002
            ],
            [
              -77.146601,
              38.96421
            ],
            [
              -77.137701,
              38.95531
            ],
            [
              -77.131901,
              38.94741
            ],
            [
              -77.127601,
              38.94001
            ],
            [
              -77.119759,
              38.934343
            ],
            [
              -77.1045,
              38.94641
            ],
            [
              -77.1007,
              38.94891
            ],
            [
              -77.0915,
              38.95651
            ],
            [
              -77.0857801216917,
              38.9609074226397
            ],
            [
              -77.0716409840087,
              38.971777542736795
            ],
            [
              -77.054299,
              38.98511
            ],
            [
              -77.0425182478171,
              38.9943689030408
            ],
            [
              -77.041018,
              38.995548
            ],
            [
              -77.036299,
              38.99171
            ],
            [
              -77.015598,
              38.97591
            ],
            [
              -77.013798,
              38.97441
            ],
            [
              -77.008298,
              38.97011
            ],
            [
              -77.0025892201687,
              38.9654839197919
            ],
            [
              -76.990536,
              38.975643
            ],
            [
              -76.991098,
              38.992405
            ],
            [
              -76.974565,
              39.014714
            ],
            [
              -76.971723,
              39.018556
            ],
            [
              -76.951772,
              39.045307
            ],
            [
              -76.917382,
              39.09153
            ],
            [
              -76.907292,
              39.117908
            ],
            [
              -76.888505,
              39.130967
            ],
            [
              -76.915002,
              39.126697
            ],
            [
              -76.917643,
              39.130135
            ],
            [
              -76.929102,
              39.138392
            ],
            [
              -76.958517,
              39.134023
            ],
            [
              -76.951694,
              39.146207
            ],
            [
              -76.975307,
              39.149537
            ],
            [
              -76.973666,
              39.162209
            ],
            [
              -76.996772,
              39.166364
            ],
            [
              -77.008418,
              39.18157
            ],
            [
              -77.00942,
              39.206732
            ],
            [
              -77.032325,
              39.220317
            ],
            [
              -77.044812,
              39.237666
            ],
            [
              -77.061525,
              39.241271
            ],
            [
              -77.070112,
              39.254104
            ],
            [
              -77.103454,
              39.265953
            ],
            [
              -77.130587,
              39.268412
            ],
            [
              -77.134466,
              39.276834
            ],
            [
              -77.140059,
              39.292095
            ],
            [
              -77.170347,
              39.313109
            ],
            [
              -77.187113,
              39.340595
            ],
            [
              -77.168801,
              39.353502
            ],
            [
              -77.262529,
              39.31028
            ],
            [
              -77.28373,
              39.300379
            ],
            [
              -77.285719,
              39.299377
            ],
            [
              -77.454754,
              39.221663
            ],
            [
              -77.4589223494682,
              39.220336398673496
            ],
            [
              -77.459883,
              39.218682
            ],
            [
              -77.470113,
              39.21184
            ],
            [
              -77.47361,
              39.208407
            ],
            [
              -77.475929,
              39.202024
            ],
            [
              -77.475013,
              39.194934
            ],
            [
              -77.477362,
              39.190495
            ],
            [
              -77.478596,
              39.189168
            ],
            [
              -77.485971,
              39.185665
            ],
            [
              -77.505162,
              39.18205
            ],
            [
              -77.510631,
              39.178484
            ],
            [
              -77.516426,
              39.170891
            ],
            [
              -77.521222,
              39.161057
            ],
            [
              -77.524872,
              39.148455
            ],
            [
              -77.527282,
              39.146236
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "24",
      "COUNTYFP": "041",
      "COUNTYNS": "00592947",
      "AFFGEOID": "0500000US24041",
      "GEOID": "24041",
      "NAME": "Talbot",
      "LSAD": "06",
      "ALAND": 695519250,
      "AWATER": 539405840,
      "County_state": "Talbot,24"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.340543,
              38.730338
            ],
            [
              -76.347998,
              38.686234
            ],
            [
              -76.340341,
              38.671245
            ],
            [
              -76.322418,
              38.679304
            ],
            [
              -76.321865,
              38.689512
            ],
            [
              -76.327841,
              38.703727
            ],
            [
              -76.325546,
              38.717698
            ],
            [
              -76.3240275489766,
              38.7196183559325
            ],
            [
              -76.316146,
              38.729586
            ],
            [
              -76.312756,
              38.730708
            ],
            [
              -76.299401,
              38.727395
            ],
            [
              -76.2993495965319,
              38.7273467458152
            ],
            [
              -76.295957,
              38.724162
            ],
            [
              -76.299171,
              38.719287
            ],
            [
              -76.298499,
              38.718005
            ],
            [
              -76.275015,
              38.712714
            ],
            [
              -76.271596,
              38.713216
            ],
            [
              -76.270277,
              38.724385
            ],
            [
              -76.2553481209489,
              38.736272847511
            ],
            [
              -76.255093,
              38.736476
            ],
            [
              -76.238685,
              38.735434
            ],
            [
              -76.23704,
              38.724518
            ],
            [
              -76.239841,
              38.719756
            ],
            [
              -76.23942677127239,
              38.71719082550519
            ],
            [
              -76.238725,
              38.712845
            ],
            [
              -76.212808,
              38.681892
            ],
            [
              -76.200334,
              38.670774
            ],
            [
              -76.196716,
              38.67286
            ],
            [
              -76.175159,
              38.673236
            ],
            [
              -76.155611,
              38.658083
            ],
            [
              -76.147158,
              38.63684
            ],
            [
              -76.127644,
              38.635268
            ],
            [
              -76.115285,
              38.623385
            ],
            [
              -76.085219,
              38.624592
            ],
            [
              -76.052775,
              38.602399
            ],
            [
              -76.034712,
              38.583915
            ],
            [
              -76.0345538796977,
              38.5835853577356
            ],
            [
              -76.0291249890344,
              38.572267444822295
            ],
            [
              -75.991127,
              38.592483
            ],
            [
              -75.980934,
              38.610054
            ],
            [
              -75.983512,
              38.625114
            ],
            [
              -75.957923,
              38.648036
            ],
            [
              -75.954368,
              38.665691
            ],
            [
              -75.945248,
              38.674109
            ],
            [
              -75.971672,
              38.684654
            ],
            [
              -75.976791,
              38.697837
            ],
            [
              -75.993049,
              38.696723
            ],
            [
              -75.993247,
              38.707977
            ],
            [
              -76.00753,
              38.720981
            ],
            [
              -76.015045,
              38.728687
            ],
            [
              -75.99459,
              38.741886
            ],
            [
              -75.999336,
              38.752075
            ],
            [
              -75.966369,
              38.779762
            ],
            [
              -75.949455,
              38.778419
            ],
            [
              -75.932518,
              38.796138
            ],
            [
              -75.910003,
              38.806446
            ],
            [
              -75.896316,
              38.812454
            ],
            [
              -75.927771,
              38.838893
            ],
            [
              -75.922797,
              38.854785
            ],
            [
              -75.949638,
              38.884807
            ],
            [
              -75.949335,
              38.918333
            ],
            [
              -76.093049,
              38.944608
            ],
            [
              -76.101363,
              38.941352
            ],
            [
              -76.101635,
              38.939864
            ],
            [
              -76.103674,
              38.939002
            ],
            [
              -76.105628,
              38.935585
            ],
            [
              -76.106235,
              38.933073
            ],
            [
              -76.108227,
              38.929735
            ],
            [
              -76.110972,
              38.926651
            ],
            [
              -76.111358,
              38.922947
            ],
            [
              -76.110741,
              38.920598
            ],
            [
              -76.112338,
              38.918263
            ],
            [
              -76.114639,
              38.916254
            ],
            [
              -76.115502,
              38.913716
            ],
            [
              -76.103512,
              38.882308
            ],
            [
              -76.122568,
              38.88204
            ],
            [
              -76.128003,
              38.875992
            ],
            [
              -76.132066,
              38.879467
            ],
            [
              -76.14984,
              38.882818
            ],
            [
              -76.16372,
              38.879525
            ],
            [
              -76.169918,
              38.865649
            ],
            [
              -76.18224,
              38.858611
            ],
            [
              -76.191058,
              38.864285
            ],
            [
              -76.19954396930419,
              38.8508168182562
            ],
            [
              -76.1990605468864,
              38.8489491261684
            ],
            [
              -76.19773476298549,
              38.8438269886526
            ],
            [
              -76.197705,
              38.843712
            ],
            [
              -76.19109,
              38.82966
            ],
            [
              -76.19343,
              38.821787
            ],
            [
              -76.1974322718457,
              38.8155413136681
            ],
            [
              -76.198138,
              38.81444
            ],
            [
              -76.219328,
              38.812371
            ],
            [
              -76.22116169440139,
              38.8130518517393
            ],
            [
              -76.245886,
              38.822232
            ],
            [
              -76.250364,
              38.825438
            ],
            [
              -76.265808,
              38.847512
            ],
            [
              -76.264221,
              38.851572
            ],
            [
              -76.271575,
              38.851771
            ],
            [
              -76.278151,
              38.835494
            ],
            [
              -76.277411,
              38.831419
            ],
            [
              -76.288455,
              38.827347
            ],
            [
              -76.2978,
              38.828314
            ],
            [
              -76.300889,
              38.82619
            ],
            [
              -76.308922,
              38.813346
            ],
            [
              -76.310081,
              38.796846
            ],
            [
              -76.323768,
              38.779287
            ],
            [
              -76.334619,
              38.772911
            ],
            [
              -76.341302,
              38.751901
            ],
            [
              -76.340543,
              38.730338
            ]
          ]
        ],
        [
          [
            [
              -76.397565,
              38.770712
            ],
            [
              -76.3904402180182,
              38.757175407982494
            ],
            [
              -76.39035,
              38.757004
            ],
            [
              -76.381249,
              38.743797
            ],
            [
              -76.363261,
              38.746611
            ],
            [
              -76.362511,
              38.748409
            ],
            [
              -76.370836,
              38.765642
            ],
            [
              -76.3700577719098,
              38.7762993785678
            ],
            [
              -76.369887,
              38.778638
            ],
            [
              -76.379739,
              38.788314
            ],
            [
              -76.397565,
              38.770712
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "24",
      "COUNTYFP": "510",
      "COUNTYNS": "01702381",
      "AFFGEOID": "0500000US24510",
      "GEOID": "24510",
      "NAME": "Baltimore",
      "LSAD": "25",
      "ALAND": 209643563,
      "AWATER": 28767622,
      "County_state": "Baltimore,24"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.711313,
              39.371933
            ],
            [
              -76.711191,
              39.362798
            ],
            [
              -76.711186,
              39.354383
            ],
            [
              -76.711186,
              39.287394
            ],
            [
              -76.711054,
              39.277889
            ],
            [
              -76.698892,
              39.272705
            ],
            [
              -76.684649,
              39.266458
            ],
            [
              -76.65986,
              39.255509
            ],
            [
              -76.644212,
              39.248924
            ],
            [
              -76.618612,
              39.237398
            ],
            [
              -76.611695,
              39.2344
            ],
            [
              -76.589162,
              39.213105
            ],
            [
              -76.582386,
              39.207151
            ],
            [
              -76.580287,
              39.206607
            ],
            [
              -76.577295,
              39.205586
            ],
            [
              -76.550086,
              39.197207
            ],
            [
              -76.5342572352474,
              39.2069331659163
            ],
            [
              -76.534285,
              39.213208
            ],
            [
              -76.5546890563477,
              39.2168540723553
            ],
            [
              -76.555349,
              39.216972
            ],
            [
              -76.56444454645549,
              39.2245676907457
            ],
            [
              -76.5645,
              39.224614
            ],
            [
              -76.559301,
              39.233349
            ],
            [
              -76.578523,
              39.248822
            ],
            [
              -76.5793120045198,
              39.2500621898474
            ],
            [
              -76.5864422910983,
              39.2612698683867
            ],
            [
              -76.586485,
              39.261337
            ],
            [
              -76.57760514073101,
              39.2645027602797
            ],
            [
              -76.577453,
              39.264557
            ],
            [
              -76.57136249458419,
              39.2624153212792
            ],
            [
              -76.5453381166633,
              39.2532640517851
            ],
            [
              -76.545301,
              39.253251
            ],
            [
              -76.535943,
              39.24148
            ],
            [
              -76.5295087321405,
              39.2413137696621
            ],
            [
              -76.529517,
              39.297056
            ],
            [
              -76.529786,
              39.310391
            ],
            [
              -76.529773,
              39.355575
            ],
            [
              -76.529785,
              39.356552
            ],
            [
              -76.529793,
              39.372058
            ],
            [
              -76.544243,
              39.372181
            ],
            [
              -76.569997,
              39.372121
            ],
            [
              -76.582208,
              39.372106
            ],
            [
              -76.626193,
              39.372017
            ],
            [
              -76.711313,
              39.371933
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "24",
      "COUNTYFP": "015",
      "COUNTYNS": "00596115",
      "AFFGEOID": "0500000US24015",
      "GEOID": "24015",
      "NAME": "Cecil",
      "LSAD": "06",
      "ALAND": 896857451,
      "AWATER": 185336236,
      "County_state": "Cecil,24"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.23327974765701,
              39.7216465489498
            ],
            [
              -76.212676,
              39.686405
            ],
            [
              -76.173575,
              39.660006
            ],
            [
              -76.147474,
              39.619407
            ],
            [
              -76.106083,
              39.580525
            ],
            [
              -76.094472,
              39.56781
            ],
            [
              -76.085972,
              39.559011
            ],
            [
              -76.07571439122009,
              39.5430317138944
            ],
            [
              -76.063811,
              39.54661
            ],
            [
              -76.06337939727939,
              39.546638102616704
            ],
            [
              -76.04687636287069,
              39.547712652029496
            ],
            [
              -76.03041965569359,
              39.548784184972895
            ],
            [
              -76.006341,
              39.550352
            ],
            [
              -76.00019023082581,
              39.55969615412909
            ],
            [
              -75.999669,
              39.560488
            ],
            [
              -75.992633,
              39.563098
            ],
            [
              -75.9805692697898,
              39.560143208527194
            ],
            [
              -75.970337,
              39.557637
            ],
            [
              -75.967221,
              39.55014
            ],
            [
              -75.967185973007,
              39.5486269919204
            ],
            [
              -75.966955,
              39.53865
            ],
            [
              -75.976057,
              39.529968
            ],
            [
              -75.986298,
              39.510398
            ],
            [
              -75.994135,
              39.488743
            ],
            [
              -75.99657,
              39.476658
            ],
            [
              -76.002926,
              39.469642
            ],
            [
              -76.012312,
              39.453115
            ],
            [
              -76.009452,
              39.449201
            ],
            [
              -76.002513,
              39.450204
            ],
            [
              -75.998276,
              39.457182
            ],
            [
              -75.9901278394547,
              39.4586242569264
            ],
            [
              -75.990005,
              39.458646
            ],
            [
              -75.976601,
              39.447808
            ],
            [
              -75.9766982061703,
              39.4456901039194
            ],
            [
              -75.976747,
              39.444627
            ],
            [
              -75.982585,
              39.435287
            ],
            [
              -75.997396,
              39.430314
            ],
            [
              -76.00688,
              39.414527
            ],
            [
              -76.016531,
              39.408465
            ],
            [
              -76.035002,
              39.401994
            ],
            [
              -76.040962,
              39.394237
            ],
            [
              -76.039932,
              39.38808
            ],
            [
              -76.035568,
              39.38618
            ],
            [
              -76.002515,
              39.385024
            ],
            [
              -76.00251366511979,
              39.3848053915462
            ],
            [
              -76.0024625818479,
              39.3764396702885
            ],
            [
              -75.982696,
              39.367492
            ],
            [
              -75.964065,
              39.374725
            ],
            [
              -75.942337,
              39.367752
            ],
            [
              -75.926589,
              39.370994
            ],
            [
              -75.885864,
              39.360797
            ],
            [
              -75.867614,
              39.367813
            ],
            [
              -75.85543,
              39.364562
            ],
            [
              -75.842385,
              39.370978
            ],
            [
              -75.831173,
              39.374173
            ],
            [
              -75.823658,
              39.381413
            ],
            [
              -75.818829,
              39.382299
            ],
            [
              -75.816313,
              39.380654
            ],
            [
              -75.809817,
              39.379768
            ],
            [
              -75.806073,
              39.375258
            ],
            [
              -75.7669076051649,
              39.3776534737305
            ],
            [
              -75.7700344831288,
              39.415972726733294
            ],
            [
              -75.7753341711683,
              39.480919324960006
            ],
            [
              -75.7797240184808,
              39.5347160102669
            ],
            [
              -75.779789684394,
              39.535520732666996
            ],
            [
              -75.7798688816967,
              39.5364912794919
            ],
            [
              -75.78102,
              39.550598
            ],
            [
              -75.78561658130309,
              39.6110754897058
            ],
            [
              -75.7864211340645,
              39.6216610375593
            ],
            [
              -75.787126,
              39.630935
            ],
            [
              -75.787686,
              39.637817
            ],
            [
              -75.788299266797,
              39.64834905831869
            ],
            [
              -75.788895,
              39.65858
            ],
            [
              -75.7888710853167,
              39.6714125051951
            ],
            [
              -75.788853,
              39.681117
            ],
            [
              -75.788895,
              39.682287
            ],
            [
              -75.788632,
              39.700412
            ],
            [
              -75.788632,
              39.700668
            ],
            [
              -75.788596,
              39.722199
            ],
            [
              -75.799799,
              39.722269
            ],
            [
              -75.810304,
              39.722292
            ],
            [
              -75.8121525719633,
              39.722288568876
            ],
            [
              -75.998872,
              39.721942
            ],
            [
              -76.01329,
              39.722284
            ],
            [
              -76.02784,
              39.722196
            ],
            [
              -76.1240338741063,
              39.7219387283388
            ],
            [
              -76.1357639058503,
              39.7219073562293
            ],
            [
              -76.23327974765701,
              39.7216465489498
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "24",
      "COUNTYFP": "027",
      "COUNTYNS": "01709077",
      "AFFGEOID": "0500000US24027",
      "GEOID": "24027",
      "NAME": "Howard",
      "LSAD": "06",
      "ALAND": 649942723,
      "AWATER": 6345542,
      "County_state": "Howard,24"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.187113,
              39.340595
            ],
            [
              -77.170347,
              39.313109
            ],
            [
              -77.140059,
              39.292095
            ],
            [
              -77.134466,
              39.276834
            ],
            [
              -77.130587,
              39.268412
            ],
            [
              -77.103454,
              39.265953
            ],
            [
              -77.070112,
              39.254104
            ],
            [
              -77.061525,
              39.241271
            ],
            [
              -77.044812,
              39.237666
            ],
            [
              -77.032325,
              39.220317
            ],
            [
              -77.00942,
              39.206732
            ],
            [
              -77.008418,
              39.18157
            ],
            [
              -76.996772,
              39.166364
            ],
            [
              -76.973666,
              39.162209
            ],
            [
              -76.975307,
              39.149537
            ],
            [
              -76.951694,
              39.146207
            ],
            [
              -76.958517,
              39.134023
            ],
            [
              -76.929102,
              39.138392
            ],
            [
              -76.917643,
              39.130135
            ],
            [
              -76.915002,
              39.126697
            ],
            [
              -76.888505,
              39.130967
            ],
            [
              -76.870109,
              39.112712
            ],
            [
              -76.840362,
              39.103142
            ],
            [
              -76.826665,
              39.109948
            ],
            [
              -76.813312,
              39.122464
            ],
            [
              -76.786966,
              39.128782
            ],
            [
              -76.781511,
              39.145498
            ],
            [
              -76.746544,
              39.179592
            ],
            [
              -76.72195,
              39.183031
            ],
            [
              -76.720239,
              39.189712
            ],
            [
              -76.712164,
              39.201933
            ],
            [
              -76.697564,
              39.212032
            ],
            [
              -76.697085,
              39.214092
            ],
            [
              -76.701078,
              39.213244
            ],
            [
              -76.717258,
              39.225479
            ],
            [
              -76.722851,
              39.227187
            ],
            [
              -76.739528,
              39.232163
            ],
            [
              -76.764575,
              39.249078
            ],
            [
              -76.76383,
              39.251312
            ],
            [
              -76.794495,
              39.266615
            ],
            [
              -76.778595,
              39.294989
            ],
            [
              -76.7946,
              39.314232
            ],
            [
              -76.822937,
              39.318834
            ],
            [
              -76.833568,
              39.311514
            ],
            [
              -76.874285,
              39.331693
            ],
            [
              -76.882274,
              39.350185
            ],
            [
              -76.929313,
              39.353951
            ],
            [
              -76.969697,
              39.363982
            ],
            [
              -77.025374,
              39.350593
            ],
            [
              -77.064961,
              39.361935
            ],
            [
              -77.10136,
              39.369323
            ],
            [
              -77.15177,
              39.348631
            ],
            [
              -77.16808,
              39.353957
            ],
            [
              -77.168801,
              39.353502
            ],
            [
              -77.187113,
              39.340595
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "24",
      "COUNTYFP": "009",
      "COUNTYNS": "01676636",
      "AFFGEOID": "0500000US24009",
      "GEOID": "24009",
      "NAME": "Calvert",
      "LSAD": "06",
      "ALAND": 552178309,
      "AWATER": 341560885,
      "County_state": "Calvert,24"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.70196,
              38.710864
            ],
            [
              -76.687147,
              38.681371
            ],
            [
              -76.690461,
              38.673683
            ],
            [
              -76.701003,
              38.668362
            ],
            [
              -76.683866,
              38.662171
            ],
            [
              -76.684304,
              38.656485
            ],
            [
              -76.69163,
              38.648506
            ],
            [
              -76.692853,
              38.636437
            ],
            [
              -76.678468,
              38.627321
            ],
            [
              -76.672334,
              38.617533
            ],
            [
              -76.672557,
              38.599271
            ],
            [
              -76.680702,
              38.580499
            ],
            [
              -76.672274,
              38.552846
            ],
            [
              -76.675457,
              38.535876
            ],
            [
              -76.662514,
              38.52549
            ],
            [
              -76.674118,
              38.499622
            ],
            [
              -76.652142,
              38.473753
            ],
            [
              -76.654345,
              38.465174
            ],
            [
              -76.624192,
              38.443498
            ],
            [
              -76.608373,
              38.424445
            ],
            [
              -76.597528,
              38.423425
            ],
            [
              -76.563902,
              38.406002
            ],
            [
              -76.553435,
              38.395043
            ],
            [
              -76.534075,
              38.397766
            ],
            [
              -76.501475,
              38.372497
            ],
            [
              -76.48313,
              38.342446
            ],
            [
              -76.487575,
              38.326257
            ],
            [
              -76.47085347042719,
              38.323950707178696
            ],
            [
              -76.468239,
              38.328927
            ],
            [
              -76.4680607781738,
              38.328841893888594
            ],
            [
              -76.4570799640419,
              38.3235982354053
            ],
            [
              -76.45184,
              38.321096
            ],
            [
              -76.4340289091304,
              38.3243479191099
            ],
            [
              -76.432944,
              38.324546
            ],
            [
              -76.43263326345401,
              38.324509091119296
            ],
            [
              -76.419221,
              38.322916
            ],
            [
              -76.40494,
              38.341089
            ],
            [
              -76.387002,
              38.361267
            ],
            [
              -76.386229,
              38.382013
            ],
            [
              -76.388348,
              38.387781
            ],
            [
              -76.393378,
              38.389477
            ],
            [
              -76.40271,
              38.396003
            ],
            [
              -76.415384,
              38.414682
            ],
            [
              -76.436271,
              38.433429
            ],
            [
              -76.450937,
              38.442422
            ],
            [
              -76.4546510396949,
              38.4464322829401
            ],
            [
              -76.456002,
              38.447891
            ],
            [
              -76.455799,
              38.451233
            ],
            [
              -76.492699,
              38.482849
            ],
            [
              -76.506023,
              38.50461
            ],
            [
              -76.515706,
              38.528988
            ],
            [
              -76.517506,
              38.539149
            ],
            [
              -76.515106,
              38.555763
            ],
            [
              -76.51634,
              38.590229
            ],
            [
              -76.511278,
              38.615745
            ],
            [
              -76.515554,
              38.629361
            ],
            [
              -76.52462,
              38.645956
            ],
            [
              -76.532398,
              38.678363
            ],
            [
              -76.532537,
              38.699669
            ],
            [
              -76.52923687797269,
              38.7135612681941
            ],
            [
              -76.535584,
              38.72148
            ],
            [
              -76.583286,
              38.721285
            ],
            [
              -76.622937,
              38.726591
            ],
            [
              -76.623079,
              38.767278
            ],
            [
              -76.643072,
              38.769275
            ],
            [
              -76.685215,
              38.756087
            ],
            [
              -76.686358,
              38.748475
            ],
            [
              -76.70196,
              38.710864
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "24",
      "COUNTYFP": "035",
      "COUNTYNS": "00596089",
      "AFFGEOID": "0500000US24035",
      "GEOID": "24035",
      "NAME": "Queen Anne's",
      "LSAD": "06",
      "ALAND": 962649223,
      "AWATER": 360044740,
      "County_state": "Queen Anne's,24"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.376202,
              38.850461
            ],
            [
              -76.375086,
              38.839474
            ],
            [
              -76.368195,
              38.836125
            ],
            [
              -76.361141,
              38.851992
            ],
            [
              -76.35039,
              38.857399
            ],
            [
              -76.340587,
              38.85574
            ],
            [
              -76.334019,
              38.860238
            ],
            [
              -76.33103,
              38.86432
            ],
            [
              -76.335364,
              38.886021
            ],
            [
              -76.338501,
              38.892474
            ],
            [
              -76.33611634768069,
              38.9059074419139
            ],
            [
              -76.336104,
              38.905977
            ],
            [
              -76.3241024799521,
              38.911171628107695
            ],
            [
              -76.324097,
              38.911174
            ],
            [
              -76.3179770332188,
              38.9113113260839
            ],
            [
              -76.317947,
              38.911312
            ],
            [
              -76.31623391478139,
              38.9089897458515
            ],
            [
              -76.308425,
              38.898404
            ],
            [
              -76.293254,
              38.902568
            ],
            [
              -76.29325510443199,
              38.9025816081152
            ],
            [
              -76.293867,
              38.910121
            ],
            [
              -76.299431,
              38.918542
            ],
            [
              -76.295911,
              38.928663
            ],
            [
              -76.273083,
              38.941927
            ],
            [
              -76.264943,
              38.930297
            ],
            [
              -76.264683,
              38.924576
            ],
            [
              -76.262226,
              38.919976
            ],
            [
              -76.256397,
              38.918829
            ],
            [
              -76.249674,
              38.920907
            ],
            [
              -76.248023,
              38.92379
            ],
            [
              -76.2508383918929,
              38.928203584478894
            ],
            [
              -76.250868,
              38.92825
            ],
            [
              -76.250157,
              38.938667
            ],
            [
              -76.2346805257049,
              38.9419563593747
            ],
            [
              -76.232038,
              38.942518
            ],
            [
              -76.213842,
              38.937366
            ],
            [
              -76.203638,
              38.928382
            ],
            [
              -76.205063,
              38.892726
            ],
            [
              -76.200082,
              38.882885
            ],
            [
              -76.202598,
              38.862616
            ],
            [
              -76.2007275174401,
              38.85538943096
            ],
            [
              -76.19954396930419,
              38.8508168182562
            ],
            [
              -76.191058,
              38.864285
            ],
            [
              -76.18224,
              38.858611
            ],
            [
              -76.169918,
              38.865649
            ],
            [
              -76.16372,
              38.879525
            ],
            [
              -76.14984,
              38.882818
            ],
            [
              -76.132066,
              38.879467
            ],
            [
              -76.128003,
              38.875992
            ],
            [
              -76.122568,
              38.88204
            ],
            [
              -76.103512,
              38.882308
            ],
            [
              -76.115502,
              38.913716
            ],
            [
              -76.114639,
              38.916254
            ],
            [
              -76.112338,
              38.918263
            ],
            [
              -76.110741,
              38.920598
            ],
            [
              -76.111358,
              38.922947
            ],
            [
              -76.110972,
              38.926651
            ],
            [
              -76.108227,
              38.929735
            ],
            [
              -76.106235,
              38.933073
            ],
            [
              -76.105628,
              38.935585
            ],
            [
              -76.103674,
              38.939002
            ],
            [
              -76.101635,
              38.939864
            ],
            [
              -76.101363,
              38.941352
            ],
            [
              -76.093049,
              38.944608
            ],
            [
              -75.949335,
              38.918333
            ],
            [
              -75.953055,
              38.93866
            ],
            [
              -75.938378,
              38.960723
            ],
            [
              -75.940933,
              38.976295
            ],
            [
              -75.919641,
              39.000112
            ],
            [
              -75.890821,
              39.018254
            ],
            [
              -75.86968,
              39.057347
            ],
            [
              -75.848721,
              39.071654
            ],
            [
              -75.848257,
              39.085765
            ],
            [
              -75.834948,
              39.108437
            ],
            [
              -75.791079,
              39.132828
            ],
            [
              -75.7478730007744,
              39.1432793370846
            ],
            [
              -75.747889,
              39.143517
            ],
            [
              -75.749578,
              39.165033
            ],
            [
              -75.75125,
              39.177984
            ],
            [
              -75.756177,
              39.246201
            ],
            [
              -75.75617871424829,
              39.246221999941795
            ],
            [
              -75.806343,
              39.241989
            ],
            [
              -75.839736,
              39.2577
            ],
            [
              -75.867722,
              39.259483
            ],
            [
              -75.874084,
              39.249798
            ],
            [
              -75.876953,
              39.249664
            ],
            [
              -75.878448,
              39.25008
            ],
            [
              -75.878983,
              39.251129
            ],
            [
              -75.880051,
              39.251526
            ],
            [
              -75.882286,
              39.250717
            ],
            [
              -75.885658,
              39.249199
            ],
            [
              -75.886276,
              39.249168
            ],
            [
              -75.887581,
              39.25021
            ],
            [
              -75.888535,
              39.252911
            ],
            [
              -75.88987,
              39.253872
            ],
            [
              -75.891113,
              39.254074
            ],
            [
              -75.893135,
              39.253651
            ],
            [
              -75.894249,
              39.25248
            ],
            [
              -75.894562,
              39.250206
            ],
            [
              -75.894447,
              39.248905
            ],
            [
              -75.893906,
              39.247906
            ],
            [
              -75.894257,
              39.247425
            ],
            [
              -75.895035,
              39.247105
            ],
            [
              -75.897852,
              39.246888
            ],
            [
              -75.912643,
              39.244499
            ],
            [
              -75.917557,
              39.243298
            ],
            [
              -75.919563,
              39.243172
            ],
            [
              -75.921249,
              39.243462
            ],
            [
              -75.92363,
              39.244102
            ],
            [
              -75.940711,
              39.238494
            ],
            [
              -75.962615,
              39.241989
            ],
            [
              -75.97039,
              39.246356
            ],
            [
              -75.972999,
              39.246139
            ],
            [
              -75.977264,
              39.244789
            ],
            [
              -75.978965,
              39.244731
            ],
            [
              -75.980407,
              39.244934
            ],
            [
              -75.981758,
              39.245273
            ],
            [
              -75.982955,
              39.245658
            ],
            [
              -75.984039,
              39.24596
            ],
            [
              -75.984771,
              39.246082
            ],
            [
              -75.985298,
              39.246021
            ],
            [
              -75.985778,
              39.245632
            ],
            [
              -75.986068,
              39.245086
            ],
            [
              -75.986145,
              39.244232
            ],
            [
              -75.985748,
              39.242385
            ],
            [
              -75.985855,
              39.241359
            ],
            [
              -75.986213,
              39.240451
            ],
            [
              -75.987083,
              39.239646
            ],
            [
              -75.987808,
              39.239295
            ],
            [
              -76.009437,
              39.240097
            ],
            [
              -76.056556,
              39.210338
            ],
            [
              -76.068794,
              39.192345
            ],
            [
              -76.064438,
              39.184643
            ],
            [
              -76.046661,
              39.180751
            ],
            [
              -76.039955,
              39.169971
            ],
            [
              -76.04763,
              39.162666
            ],
            [
              -76.081596,
              39.147659
            ],
            [
              -76.072418,
              39.138206
            ],
            [
              -76.094154,
              39.126854
            ],
            [
              -76.117058,
              39.107098
            ],
            [
              -76.1580904714523,
              39.0939978002662
            ],
            [
              -76.145174,
              39.092824
            ],
            [
              -76.150528,
              39.079421
            ],
            [
              -76.1534638513341,
              39.074569115709096
            ],
            [
              -76.15896,
              39.065486
            ],
            [
              -76.16906,
              39.062787
            ],
            [
              -76.175284,
              39.058805
            ],
            [
              -76.184207,
              39.046264
            ],
            [
              -76.178281,
              39.031663
            ],
            [
              -76.167574,
              39.018273
            ],
            [
              -76.163616,
              39.010057
            ],
            [
              -76.163988,
              38.999541
            ],
            [
              -76.20236,
              38.973079
            ],
            [
              -76.218929,
              38.970538
            ],
            [
              -76.229277,
              38.97758
            ],
            [
              -76.229993127681,
              38.977727512216
            ],
            [
              -76.258813,
              38.983664
            ],
            [
              -76.2660829392395,
              38.9832075109141
            ],
            [
              -76.277478,
              38.982492
            ],
            [
              -76.293962,
              39.003948
            ],
            [
              -76.302846,
              39.025828
            ],
            [
              -76.301027,
              39.031595
            ],
            [
              -76.301847,
              39.039651
            ],
            [
              -76.311766,
              39.035257
            ],
            [
              -76.320274,
              39.023013
            ],
            [
              -76.323557,
              39.008961
            ],
            [
              -76.322296,
              39.006375
            ],
            [
              -76.322679,
              38.999602
            ],
            [
              -76.353828,
              38.957234
            ],
            [
              -76.361727,
              38.939175
            ],
            [
              -76.365658,
              38.907477
            ],
            [
              -76.364678,
              38.873831
            ],
            [
              -76.376202,
              38.850461
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "24",
      "COUNTYFP": "017",
      "COUNTYNS": "01676992",
      "AFFGEOID": "0500000US24017",
      "GEOID": "24017",
      "NAME": "Charles",
      "LSAD": "06",
      "ALAND": 1185656912,
      "AWATER": 479539652,
      "County_state": "Charles,24"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.263599,
              38.512344
            ],
            [
              -77.27422,
              38.48177
            ],
            [
              -77.259962,
              38.435821
            ],
            [
              -77.259962,
              38.427902
            ],
            [
              -77.264238,
              38.414282
            ],
            [
              -77.250172,
              38.382781
            ],
            [
              -77.216729,
              38.363159
            ],
            [
              -77.207312,
              38.359867
            ],
            [
              -77.18668,
              38.365636
            ],
            [
              -77.136728,
              38.391799
            ],
            [
              -77.127737,
              38.400833
            ],
            [
              -77.1259330101864,
              38.4048453644698
            ],
            [
              -77.123325,
              38.410646
            ],
            [
              -77.11062347639579,
              38.4092142245156
            ],
            [
              -77.110586,
              38.40921
            ],
            [
              -77.106571,
              38.406237
            ],
            [
              -77.091073,
              38.407546
            ],
            [
              -77.086393,
              38.414755
            ],
            [
              -77.075489,
              38.42471
            ],
            [
              -77.044188,
              38.443016
            ],
            [
              -77.0420460884466,
              38.4439825752982
            ],
            [
              -77.040638,
              38.444618
            ],
            [
              -77.0166741816562,
              38.4455600811266
            ],
            [
              -77.016371,
              38.445572
            ],
            [
              -77.001638,
              38.421952
            ],
            [
              -76.998585,
              38.409294
            ],
            [
              -76.98828,
              38.394975
            ],
            [
              -76.983582,
              38.362999
            ],
            [
              -76.975492,
              38.347327
            ],
            [
              -76.953928,
              38.333282
            ],
            [
              -76.942132,
              38.329601
            ],
            [
              -76.929554,
              38.321088
            ],
            [
              -76.92466796677199,
              38.31601147332459
            ],
            [
              -76.923629,
              38.314932
            ],
            [
              -76.922177,
              38.311339
            ],
            [
              -76.92216109322,
              38.3110863952236
            ],
            [
              -76.920932,
              38.291568
            ],
            [
              -76.908506,
              38.28843
            ],
            [
              -76.886535,
              38.277004
            ],
            [
              -76.86630189178801,
              38.269673216424
            ],
            [
              -76.864292,
              38.268945
            ],
            [
              -76.847074,
              38.25616
            ],
            [
              -76.842139,
              38.254491
            ],
            [
              -76.837789,
              38.261609
            ],
            [
              -76.83513621324289,
              38.2726279263723
            ],
            [
              -76.834803,
              38.274012
            ],
            [
              -76.840383,
              38.289184
            ],
            [
              -76.846715,
              38.300301
            ],
            [
              -76.853845,
              38.314289
            ],
            [
              -76.858718,
              38.324825
            ],
            [
              -76.869889,
              38.331817
            ],
            [
              -76.863828,
              38.343842
            ],
            [
              -76.8590349224109,
              38.3529783194174
            ],
            [
              -76.856103,
              38.358567
            ],
            [
              -76.84788460590079,
              38.355397489319195
            ],
            [
              -76.849933,
              38.365815
            ],
            [
              -76.874134,
              38.380715
            ],
            [
              -76.87183,
              38.388865
            ],
            [
              -76.87181,
              38.388973
            ],
            [
              -76.850317,
              38.398336
            ],
            [
              -76.820861,
              38.434016
            ],
            [
              -76.774639,
              38.508994
            ],
            [
              -76.71354,
              38.51034
            ],
            [
              -76.713459,
              38.5095
            ],
            [
              -76.71184,
              38.509871
            ],
            [
              -76.71053,
              38.50917
            ],
            [
              -76.710413,
              38.507453
            ],
            [
              -76.70995,
              38.508065
            ],
            [
              -76.708702,
              38.506466
            ],
            [
              -76.708448,
              38.504838
            ],
            [
              -76.706614,
              38.504294
            ],
            [
              -76.705307,
              38.502547
            ],
            [
              -76.705271,
              38.501663
            ],
            [
              -76.703316,
              38.502401
            ],
            [
              -76.702203,
              38.501668
            ],
            [
              -76.701475,
              38.500195
            ],
            [
              -76.700012,
              38.499129
            ],
            [
              -76.700082,
              38.498591
            ],
            [
              -76.699317,
              38.497125
            ],
            [
              -76.685446,
              38.49497
            ],
            [
              -76.674118,
              38.499622
            ],
            [
              -76.662514,
              38.52549
            ],
            [
              -76.675457,
              38.535876
            ],
            [
              -76.700403,
              38.542302
            ],
            [
              -76.702016,
              38.545541
            ],
            [
              -76.740526,
              38.558673
            ],
            [
              -76.742682,
              38.594217
            ],
            [
              -76.748495,
              38.618197
            ],
            [
              -76.757951,
              38.619713
            ],
            [
              -76.862955,
              38.658649
            ],
            [
              -76.913516,
              38.652928
            ],
            [
              -76.965748,
              38.66127
            ],
            [
              -76.998596,
              38.654664
            ],
            [
              -77.021116,
              38.646444
            ],
            [
              -77.047656,
              38.616303
            ],
            [
              -77.077027,
              38.690168
            ],
            [
              -77.0863289190155,
              38.70612807975
            ],
            [
              -77.0918,
              38.703415
            ],
            [
              -77.099,
              38.698615
            ],
            [
              -77.1027,
              38.698315
            ],
            [
              -77.1059,
              38.696815
            ],
            [
              -77.122001,
              38.685816
            ],
            [
              -77.132501,
              38.673816
            ],
            [
              -77.1344628993383,
              38.6599678758177
            ],
            [
              -77.135901,
              38.649817
            ],
            [
              -77.131901,
              38.644217
            ],
            [
              -77.132201,
              38.641217
            ],
            [
              -77.1302,
              38.635017
            ],
            [
              -77.1108166541698,
              38.6343966169137
            ],
            [
              -77.106142,
              38.634247
            ],
            [
              -77.110539,
              38.62682
            ],
            [
              -77.12463,
              38.619778
            ],
            [
              -77.129084,
              38.614364
            ],
            [
              -77.148651,
              38.6056
            ],
            [
              -77.169671,
              38.60687
            ],
            [
              -77.183767,
              38.600699
            ],
            [
              -77.2099047771368,
              38.5688703954555
            ],
            [
              -77.221117,
              38.555217
            ],
            [
              -77.2264649741469,
              38.5541391611688
            ],
            [
              -77.237724,
              38.55187
            ],
            [
              -77.2460885402238,
              38.539092538477796
            ],
            [
              -77.25488179623069,
              38.525660181495006
            ],
            [
              -77.263599,
              38.512344
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "24",
      "COUNTYFP": "039",
      "COUNTYNS": "00596907",
      "AFFGEOID": "0500000US24039",
      "GEOID": "24039",
      "NAME": "Somerset",
      "LSAD": "06",
      "ALAND": 828090979,
      "AWATER": 752714478,
      "County_state": "Somerset,24"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -75.951273,
              38.161887
            ],
            [
              -75.959616,
              38.137141
            ],
            [
              -75.949557,
              38.118127
            ],
            [
              -75.945297,
              38.113091
            ],
            [
              -75.938484,
              38.109976
            ],
            [
              -75.936663,
              38.109956
            ],
            [
              -75.937089,
              38.124209
            ],
            [
              -75.923797045286,
              38.130338988697396
            ],
            [
              -75.9072641753598,
              38.1379636238969
            ],
            [
              -75.90649689299279,
              38.1383174794688
            ],
            [
              -75.900355,
              38.14115
            ],
            [
              -75.8809778059978,
              38.1370148057568
            ],
            [
              -75.868636,
              38.134381
            ],
            [
              -75.866,
              38.134886
            ],
            [
              -75.85954,
              38.140542
            ],
            [
              -75.8545071716347,
              38.1425674767104
            ],
            [
              -75.849919,
              38.144414
            ],
            [
              -75.843862,
              38.144599
            ],
            [
              -75.82771213704119,
              38.1334642940151
            ],
            [
              -75.827674,
              38.133438
            ],
            [
              -75.8372038058154,
              38.114468010367396
            ],
            [
              -75.837563,
              38.113753
            ],
            [
              -75.842604,
              38.113111
            ],
            [
              -75.857196844626,
              38.1047548313546
            ],
            [
              -75.86381,
              38.100968
            ],
            [
              -75.8651456132398,
              38.0988926625043
            ],
            [
              -75.8713943315446,
              38.0891831155998
            ],
            [
              -75.880515,
              38.075011
            ],
            [
              -75.874189,
              38.060288
            ],
            [
              -75.871503,
              38.05887
            ],
            [
              -75.8688986344958,
              38.0591310142896
            ],
            [
              -75.858881,
              38.060135
            ],
            [
              -75.860072,
              38.065743
            ],
            [
              -75.858944,
              38.067323
            ],
            [
              -75.844265,
              38.072272
            ],
            [
              -75.819591,
              38.066814
            ],
            [
              -75.8194150767327,
              38.0666063589109
            ],
            [
              -75.812913,
              38.058932
            ],
            [
              -75.8259869665201,
              38.0463996715048
            ],
            [
              -75.829375,
              38.043152
            ],
            [
              -75.8300232492564,
              38.042845054134396
            ],
            [
              -75.8376059429891,
              38.0392546504917
            ],
            [
              -75.847715696463,
              38.0344676846747
            ],
            [
              -75.847922,
              38.03437
            ],
            [
              -75.84998,
              38.034294
            ],
            [
              -75.850531,
              38.036697
            ],
            [
              -75.857507,
              38.038778
            ],
            [
              -75.8695125381478,
              38.0354074468874
            ],
            [
              -75.87319,
              38.034375
            ],
            [
              -75.875399,
              38.028241
            ],
            [
              -75.875297,
              38.011965
            ],
            [
              -75.882768,
              38.002995
            ],
            [
              -75.898956,
              37.974514
            ],
            [
              -75.890871,
              37.954847
            ],
            [
              -75.8928104260085,
              37.940239369603596
            ],
            [
              -75.894065,
              37.93079
            ],
            [
              -75.898316,
              37.925114
            ],
            [
              -75.892686,
              37.916848
            ],
            [
              -75.885032,
              37.911717
            ],
            [
              -75.8819133914126,
              37.912562957063
            ],
            [
              -75.860727,
              37.91831
            ],
            [
              -75.8491028904245,
              37.924469591564396
            ],
            [
              -75.832414,
              37.933313
            ],
            [
              -75.829901,
              37.938556
            ],
            [
              -75.81908821459949,
              37.9465420606141
            ],
            [
              -75.8077554208816,
              37.9549121867385
            ],
            [
              -75.783815,
              37.972594
            ],
            [
              -75.7767730023014,
              37.9720440041435
            ],
            [
              -75.759091,
              37.970663
            ],
            [
              -75.75587604984301,
              37.97001252404419
            ],
            [
              -75.750244,
              37.968873
            ],
            [
              -75.737997,
              37.963526
            ],
            [
              -75.73751418592029,
              37.9637052828788
            ],
            [
              -75.727952,
              37.967256
            ],
            [
              -75.722662,
              37.97131
            ],
            [
              -75.722085,
              37.973416
            ],
            [
              -75.71315,
              37.976623
            ],
            [
              -75.685995,
              37.967607
            ],
            [
              -75.671681,
              37.966576
            ],
            [
              -75.6650196166012,
              37.9624011916994
            ],
            [
              -75.663095,
              37.961195
            ],
            [
              -75.665057,
              37.956282
            ],
            [
              -75.669711,
              37.950796
            ],
            [
              -75.665012,
              37.949387
            ],
            [
              -75.655681,
              37.945435
            ],
            [
              -75.647606,
              37.947027
            ],
            [
              -75.648229,
              37.966775
            ],
            [
              -75.644725,
              37.969779
            ],
            [
              -75.641823,
              37.975967
            ],
            [
              -75.638221,
              37.979397
            ],
            [
              -75.63753864924719,
              37.9801934905551
            ],
            [
              -75.633833,
              37.984519
            ],
            [
              -75.632532,
              37.986693
            ],
            [
              -75.630869,
              37.987818
            ],
            [
              -75.627607,
              37.988521
            ],
            [
              -75.625612,
              37.9898
            ],
            [
              -75.624341,
              37.994211
            ],
            [
              -75.639288,
              38.022978
            ],
            [
              -75.655822,
              38.029757
            ],
            [
              -75.660614,
              38.046328
            ],
            [
              -75.655746,
              38.052599
            ],
            [
              -75.638854,
              38.044707
            ],
            [
              -75.622665,
              38.054972
            ],
            [
              -75.617522,
              38.073016
            ],
            [
              -75.581222,
              38.068301
            ],
            [
              -75.571708,
              38.085173
            ],
            [
              -75.54087,
              38.08948
            ],
            [
              -75.542236,
              38.109271
            ],
            [
              -75.57682,
              38.144297
            ],
            [
              -75.550354,
              38.164069
            ],
            [
              -75.545898,
              38.17874
            ],
            [
              -75.561157,
              38.199423
            ],
            [
              -75.575836,
              38.211283
            ],
            [
              -75.605826,
              38.249125
            ],
            [
              -75.612764,
              38.278626
            ],
            [
              -75.663404,
              38.2989
            ],
            [
              -75.696404,
              38.274194
            ],
            [
              -75.696405,
              38.270675
            ],
            [
              -75.693576,
              38.268782
            ],
            [
              -75.694432,
              38.266904
            ],
            [
              -75.720419,
              38.266644
            ],
            [
              -75.723822,
              38.271594
            ],
            [
              -75.723065,
              38.274694
            ],
            [
              -75.723477,
              38.275784
            ],
            [
              -75.724354,
              38.276248
            ],
            [
              -75.727427,
              38.275409
            ],
            [
              -75.728815,
              38.275892
            ],
            [
              -75.729164,
              38.277231
            ],
            [
              -75.727931,
              38.279756
            ],
            [
              -75.728455,
              38.280952
            ],
            [
              -75.731134,
              38.281014
            ],
            [
              -75.734516,
              38.283038
            ],
            [
              -75.735714,
              38.282834
            ],
            [
              -75.73749,
              38.2819
            ],
            [
              -75.740411,
              38.279359
            ],
            [
              -75.741649,
              38.279155
            ],
            [
              -75.745937,
              38.280426
            ],
            [
              -75.746993,
              38.281745
            ],
            [
              -75.747647,
              38.283553
            ],
            [
              -75.74855,
              38.284606
            ],
            [
              -75.748969,
              38.284713
            ],
            [
              -75.749867,
              38.284314
            ],
            [
              -75.749926,
              38.283822
            ],
            [
              -75.749238,
              38.282594
            ],
            [
              -75.749182,
              38.281667
            ],
            [
              -75.749651,
              38.281108
            ],
            [
              -75.751803,
              38.280697
            ],
            [
              -75.75297,
              38.281159
            ],
            [
              -75.753099,
              38.282055
            ],
            [
              -75.752172,
              38.283884
            ],
            [
              -75.752342,
              38.284788
            ],
            [
              -75.75295,
              38.285133
            ],
            [
              -75.754406,
              38.285444
            ],
            [
              -75.758027,
              38.285785
            ],
            [
              -75.762029,
              38.285991
            ],
            [
              -75.765587,
              38.285807
            ],
            [
              -75.768671,
              38.285183
            ],
            [
              -75.77276,
              38.272866
            ],
            [
              -75.795647,
              38.266151
            ],
            [
              -75.798945,
              38.259376
            ],
            [
              -75.802409,
              38.254813
            ],
            [
              -75.839,
              38.253478
            ],
            [
              -75.8646282601049,
              38.2383201947882
            ],
            [
              -75.85915961102509,
              38.2334070231174
            ],
            [
              -75.85152777143828,
              38.2265503867694
            ],
            [
              -75.851396,
              38.226432
            ],
            [
              -75.846377,
              38.210477
            ],
            [
              -75.8467129561864,
              38.210294703923
            ],
            [
              -75.864104,
              38.200858
            ],
            [
              -75.877751,
              38.198292
            ],
            [
              -75.884603,
              38.199751
            ],
            [
              -75.886217,
              38.203309
            ],
            [
              -75.888073,
              38.203813
            ],
            [
              -75.942375,
              38.187066
            ],
            [
              -75.951566,
              38.178093
            ],
            [
              -75.951972,
              38.176239
            ],
            [
              -75.94728,
              38.170792
            ],
            [
              -75.94741651685949,
              38.1694386556995
            ],
            [
              -75.9474873762026,
              38.1687361996921
            ],
            [
              -75.947534,
              38.168274
            ],
            [
              -75.951273,
              38.161887
            ]
          ]
        ],
        [
          [
            [
              -76.0493957129027,
              37.990314722241195
            ],
            [
              -76.049608,
              37.983628
            ],
            [
              -76.04653,
              37.953586
            ],
            [
              -76.041691,
              37.954
            ],
            [
              -76.041402,
              37.954006
            ],
            [
              -76.030122,
              37.953655
            ],
            [
              -76.029463,
              37.953775
            ],
            [
              -76.0223246348406,
              37.9538781742028
            ],
            [
              -76.021714,
              37.953887
            ],
            [
              -76.020932,
              37.953879
            ],
            [
              -76.0058878051011,
              37.9536619120505
            ],
            [
              -75.993905,
              37.953489
            ],
            [
              -75.988879,
              37.960337
            ],
            [
              -75.99473,
              37.974694
            ],
            [
              -75.982121,
              37.988548
            ],
            [
              -75.980089,
              38.004891
            ],
            [
              -75.9863,
              38.021946
            ],
            [
              -76.002744,
              38.024937
            ],
            [
              -76.007337,
              38.036706
            ],
            [
              -76.0197622023396,
              38.0389331415313
            ],
            [
              -76.020827,
              38.039124
            ],
            [
              -76.041668,
              38.032148
            ],
            [
              -76.046213,
              38.025533
            ],
            [
              -76.048617,
              38.014843
            ],
            [
              -76.0488102071936,
              38.008757265844295
            ],
            [
              -76.0493957129027,
              37.990314722241195
            ]
          ]
        ],
        [
          [
            [
              -76.059304,
              38.095751
            ],
            [
              -76.05831003781319,
              38.094905771946294
            ],
            [
              -76.036676,
              38.076509
            ],
            [
              -76.0233,
              38.07076
            ],
            [
              -76.011544,
              38.072312
            ],
            [
              -76.005904,
              38.07717
            ],
            [
              -76.008168,
              38.095385
            ],
            [
              -76.02137,
              38.107934
            ],
            [
              -76.020496,
              38.117044
            ],
            [
              -76.011916,
              38.122214
            ],
            [
              -76.012487,
              38.131731
            ],
            [
              -76.01779,
              38.131367
            ],
            [
              -76.0209821625516,
              38.1327762806524
            ],
            [
              -76.022515,
              38.133453
            ],
            [
              -76.0314419397404,
              38.147612216406394
            ],
            [
              -76.0483727775301,
              38.1205503002675
            ],
            [
              -76.043423,
              38.117868
            ],
            [
              -76.04066536525148,
              38.1136057394026
            ],
            [
              -76.03962,
              38.11199
            ],
            [
              -76.042083,
              38.109862
            ],
            [
              -76.0472913481277,
              38.108504590801296
            ],
            [
              -76.050156,
              38.107758
            ],
            [
              -76.059304,
              38.095751
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "24",
      "COUNTYFP": "047",
      "COUNTYNS": "01668802",
      "AFFGEOID": "0500000US24047",
      "GEOID": "24047",
      "NAME": "Worcester",
      "LSAD": "06",
      "ALAND": 1212832597,
      "AWATER": 586845178,
      "County_state": "Worcester,24"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -75.660614,
              38.046328
            ],
            [
              -75.655822,
              38.029757
            ],
            [
              -75.639288,
              38.022978
            ],
            [
              -75.624341,
              37.994211
            ],
            [
              -75.435956,
              38.010282
            ],
            [
              -75.42881,
              38.010854
            ],
            [
              -75.398839,
              38.013277
            ],
            [
              -75.3778511771824,
              38.015144514498004
            ],
            [
              -75.2606353145422,
              38.025574482617
            ],
            [
              -75.242266,
              38.027209
            ],
            [
              -75.216117,
              38.061786
            ],
            [
              -75.193796,
              38.096013
            ],
            [
              -75.177394,
              38.130014
            ],
            [
              -75.15975491152639,
              38.1819317426046
            ],
            [
              -75.15897,
              38.184242
            ],
            [
              -75.143229,
              38.220475
            ],
            [
              -75.116837,
              38.274229
            ],
            [
              -75.102947,
              38.311525
            ],
            [
              -75.093888,
              38.323432
            ],
            [
              -75.09214235442039,
              38.3232517815292
            ],
            [
              -75.087466,
              38.322769
            ],
            [
              -75.085518,
              38.32427
            ],
            [
              -75.08546793549709,
              38.324389030831696
            ],
            [
              -75.072476,
              38.355278
            ],
            [
              -75.06137,
              38.389466
            ],
            [
              -75.054591,
              38.41483
            ],
            [
              -75.048939,
              38.451263
            ],
            [
              -75.052511,
              38.451273
            ],
            [
              -75.053484,
              38.451274
            ],
            [
              -75.0647189393122,
              38.451288865657595
            ],
            [
              -75.066332,
              38.451291
            ],
            [
              -75.069916,
              38.451276
            ],
            [
              -75.0896490323041,
              38.45125407654
            ],
            [
              -75.141923,
              38.451196
            ],
            [
              -75.185455,
              38.451012
            ],
            [
              -75.20513351197421,
              38.4511242298584
            ],
            [
              -75.252786,
              38.451396
            ],
            [
              -75.260416,
              38.451491
            ],
            [
              -75.27306316431009,
              38.451565703052694
            ],
            [
              -75.3412457132725,
              38.4519684371695
            ],
            [
              -75.317104,
              38.404243
            ],
            [
              -75.329739,
              38.38129
            ],
            [
              -75.307011,
              38.35476
            ],
            [
              -75.323205,
              38.331897
            ],
            [
              -75.356276,
              38.313164
            ],
            [
              -75.372587,
              38.28971
            ],
            [
              -75.45652,
              38.286199
            ],
            [
              -75.612764,
              38.278626
            ],
            [
              -75.605826,
              38.249125
            ],
            [
              -75.575836,
              38.211283
            ],
            [
              -75.561157,
              38.199423
            ],
            [
              -75.545898,
              38.17874
            ],
            [
              -75.550354,
              38.164069
            ],
            [
              -75.57682,
              38.144297
            ],
            [
              -75.542236,
              38.109271
            ],
            [
              -75.54087,
              38.08948
            ],
            [
              -75.571708,
              38.085173
            ],
            [
              -75.581222,
              38.068301
            ],
            [
              -75.617522,
              38.073016
            ],
            [
              -75.622665,
              38.054972
            ],
            [
              -75.638854,
              38.044707
            ],
            [
              -75.655746,
              38.052599
            ],
            [
              -75.660614,
              38.046328
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "24",
      "COUNTYFP": "003",
      "COUNTYNS": "01710958",
      "AFFGEOID": "0500000US24003",
      "GEOID": "24003",
      "NAME": "Anne Arundel",
      "LSAD": "06",
      "ALAND": 1074289193,
      "AWATER": 448097535,
      "County_state": "Anne Arundel,24"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.840362,
              39.103142
            ],
            [
              -76.827415,
              39.092979
            ],
            [
              -76.832719,
              39.075943
            ],
            [
              -76.837724,
              39.071802
            ],
            [
              -76.831424,
              39.069034
            ],
            [
              -76.803941,
              39.062446
            ],
            [
              -76.790741,
              39.045722
            ],
            [
              -76.776528,
              39.04531
            ],
            [
              -76.751258,
              39.034714
            ],
            [
              -76.744517,
              39.01712
            ],
            [
              -76.698459,
              38.983783
            ],
            [
              -76.690533,
              38.924763
            ],
            [
              -76.670111,
              38.906919
            ],
            [
              -76.676276,
              38.895163
            ],
            [
              -76.672145,
              38.886627
            ],
            [
              -76.691527,
              38.8598
            ],
            [
              -76.696371,
              38.822724
            ],
            [
              -76.710639,
              38.815694
            ],
            [
              -76.702403,
              38.787945
            ],
            [
              -76.714317,
              38.778226
            ],
            [
              -76.699271,
              38.75341
            ],
            [
              -76.686358,
              38.748475
            ],
            [
              -76.685215,
              38.756087
            ],
            [
              -76.643072,
              38.769275
            ],
            [
              -76.623079,
              38.767278
            ],
            [
              -76.622937,
              38.726591
            ],
            [
              -76.583286,
              38.721285
            ],
            [
              -76.535584,
              38.72148
            ],
            [
              -76.52923687797269,
              38.7135612681941
            ],
            [
              -76.526655,
              38.72443
            ],
            [
              -76.52718,
              38.727062
            ],
            [
              -76.529868,
              38.728435
            ],
            [
              -76.544475,
              38.727705
            ],
            [
              -76.552743,
              38.73535
            ],
            [
              -76.557535,
              38.744687
            ],
            [
              -76.559884,
              38.767361
            ],
            [
              -76.535379,
              38.778116
            ],
            [
              -76.526979,
              38.787016
            ],
            [
              -76.527479,
              38.791816
            ],
            [
              -76.524679,
              38.795016
            ],
            [
              -76.510078,
              38.801216
            ],
            [
              -76.498878,
              38.817516
            ],
            [
              -76.489878,
              38.838715
            ],
            [
              -76.489878,
              38.842815
            ],
            [
              -76.496579,
              38.853115
            ],
            [
              -76.509285,
              38.848388
            ],
            [
              -76.5165821696068,
              38.851026185486496
            ],
            [
              -76.516944,
              38.851157
            ],
            [
              -76.519442,
              38.863135
            ],
            [
              -76.5111347406356,
              38.86939649349
            ],
            [
              -76.49068,
              38.884814
            ],
            [
              -76.48938,
              38.887414
            ],
            [
              -76.49088,
              38.894514
            ],
            [
              -76.49278,
              38.895614
            ],
            [
              -76.49388,
              38.899614
            ],
            [
              -76.49380636385169,
              38.903442711531895
            ],
            [
              -76.49368,
              38.910013
            ],
            [
              -76.4898419148159,
              38.9109674342642
            ],
            [
              -76.475761,
              38.914469
            ],
            [
              -76.46948,
              38.911513
            ],
            [
              -76.46938,
              38.907613
            ],
            [
              -76.46528002942759,
              38.9074059516931
            ],
            [
              -76.459479,
              38.907113
            ],
            [
              -76.45808,
              38.914313
            ],
            [
              -76.46188,
              38.924013
            ],
            [
              -76.45028,
              38.941113
            ],
            [
              -76.457781,
              38.948412
            ],
            [
              -76.463081,
              38.948612
            ],
            [
              -76.471281,
              38.956512
            ],
            [
              -76.474882,
              38.967312
            ],
            [
              -76.474198,
              38.972647
            ],
            [
              -76.46645237002929,
              38.9733833817044
            ],
            [
              -76.454581,
              38.974512
            ],
            [
              -76.4515992045258,
              38.976766528285296
            ],
            [
              -76.450481,
              38.977612
            ],
            [
              -76.448981,
              38.982811
            ],
            [
              -76.4489275772128,
              38.98282335900301
            ],
            [
              -76.42730087519651,
              38.9878265512605
            ],
            [
              -76.422181,
              38.989011
            ],
            [
              -76.39408,
              39.011311
            ],
            [
              -76.39778,
              39.022611
            ],
            [
              -76.405081,
              39.033211
            ],
            [
              -76.438928,
              39.052788
            ],
            [
              -76.4388447897918,
              39.0529004836928
            ],
            [
              -76.4323735454456,
              39.061648322172296
            ],
            [
              -76.423081,
              39.07421
            ],
            [
              -76.42186,
              39.081442
            ],
            [
              -76.4220798668262,
              39.0820700468905
            ],
            [
              -76.4236088837995,
              39.0864376651074
            ],
            [
              -76.432981,
              39.113209
            ],
            [
              -76.432481,
              39.126709
            ],
            [
              -76.428681,
              39.131709
            ],
            [
              -76.43094605902859,
              39.132817987035295
            ],
            [
              -76.44011023648409,
              39.1373048255057
            ],
            [
              -76.452782,
              39.143509
            ],
            [
              -76.471483,
              39.154709
            ],
            [
              -76.475983,
              39.161109
            ],
            [
              -76.4838447527482,
              39.1643338831547
            ],
            [
              -76.484023,
              39.164407
            ],
            [
              -76.5005117428082,
              39.161362489185095
            ],
            [
              -76.500926,
              39.161286
            ],
            [
              -76.508384,
              39.169408
            ],
            [
              -76.51183403977659,
              39.171093267404196
            ],
            [
              -76.5192916528589,
              39.174736144894
            ],
            [
              -76.525785,
              39.177908
            ],
            [
              -76.534185,
              39.190608
            ],
            [
              -76.5342572352474,
              39.2069331659163
            ],
            [
              -76.550086,
              39.197207
            ],
            [
              -76.577295,
              39.205586
            ],
            [
              -76.580287,
              39.206607
            ],
            [
              -76.582386,
              39.207151
            ],
            [
              -76.589162,
              39.213105
            ],
            [
              -76.611695,
              39.2344
            ],
            [
              -76.618612,
              39.237398
            ],
            [
              -76.631527,
              39.223861
            ],
            [
              -76.641409,
              39.22558
            ],
            [
              -76.650522,
              39.229466
            ],
            [
              -76.69675,
              39.217399
            ],
            [
              -76.697085,
              39.214092
            ],
            [
              -76.697564,
              39.212032
            ],
            [
              -76.712164,
              39.201933
            ],
            [
              -76.720239,
              39.189712
            ],
            [
              -76.72195,
              39.183031
            ],
            [
              -76.746544,
              39.179592
            ],
            [
              -76.781511,
              39.145498
            ],
            [
              -76.786966,
              39.128782
            ],
            [
              -76.813312,
              39.122464
            ],
            [
              -76.826665,
              39.109948
            ],
            [
              -76.840362,
              39.103142
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "24",
      "COUNTYFP": "025",
      "COUNTYNS": "01698178",
      "AFFGEOID": "0500000US24025",
      "GEOID": "24025",
      "NAME": "Harford",
      "LSAD": "06",
      "ALAND": 1132095114,
      "AWATER": 231904976,
      "County_state": "Harford,24"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.5694756327762,
              39.721509203425
            ],
            [
              -76.56671,
              39.662689
            ],
            [
              -76.565189,
              39.614504
            ],
            [
              -76.559464,
              39.59766
            ],
            [
              -76.547825,
              39.588219
            ],
            [
              -76.532174,
              39.543977
            ],
            [
              -76.510387,
              39.524388
            ],
            [
              -76.494569,
              39.52655
            ],
            [
              -76.43698,
              39.501964
            ],
            [
              -76.433114,
              39.488268
            ],
            [
              -76.420896,
              39.478414
            ],
            [
              -76.386697,
              39.459705
            ],
            [
              -76.373493,
              39.421418
            ],
            [
              -76.371941,
              39.405102
            ],
            [
              -76.360113,
              39.394778
            ],
            [
              -76.359269,
              39.394046
            ],
            [
              -76.358781,
              39.390305
            ],
            [
              -76.3578403919927,
              39.3898339001833
            ],
            [
              -76.357124,
              39.394079
            ],
            [
              -76.35063177756969,
              39.3925844428329
            ],
            [
              -76.329197,
              39.38765
            ],
            [
              -76.3166071964853,
              39.3891467595807
            ],
            [
              -76.315293,
              39.389303
            ],
            [
              -76.304003,
              39.381496
            ],
            [
              -76.305904,
              39.364409
            ],
            [
              -76.320759,
              39.358161
            ],
            [
              -76.3199868727267,
              39.3579100070707
            ],
            [
              -76.295678,
              39.350008
            ],
            [
              -76.298778,
              39.329208
            ],
            [
              -76.289623,
              39.317167
            ],
            [
              -76.296609,
              39.301137
            ],
            [
              -76.281747,
              39.29966
            ],
            [
              -76.276078,
              39.322908
            ],
            [
              -76.256586,
              39.338862
            ],
            [
              -76.266925,
              39.356139
            ],
            [
              -76.285701,
              39.367716
            ],
            [
              -76.268826,
              39.38866
            ],
            [
              -76.269064,
              39.403079
            ],
            [
              -76.254803,
              39.411894
            ],
            [
              -76.250525,
              39.431081
            ],
            [
              -76.251594,
              39.44962
            ],
            [
              -76.231035,
              39.45641
            ],
            [
              -76.224618,
              39.425665
            ],
            [
              -76.241612,
              39.403997
            ],
            [
              -76.250525,
              39.379291
            ],
            [
              -76.239877,
              39.361408
            ],
            [
              -76.224161,
              39.35278
            ],
            [
              -76.197852,
              39.367598
            ],
            [
              -76.190767,
              39.375924
            ],
            [
              -76.180074,
              39.377609
            ],
            [
              -76.171474,
              39.39221
            ],
            [
              -76.158774,
              39.40631
            ],
            [
              -76.146373,
              39.40531
            ],
            [
              -76.121889,
              39.421226
            ],
            [
              -76.11682,
              39.427614
            ],
            [
              -76.102232,
              39.435659
            ],
            [
              -76.083269,
              39.438321
            ],
            [
              -76.081176,
              39.436712
            ],
            [
              -76.060989,
              39.447722
            ],
            [
              -76.060931,
              39.452208
            ],
            [
              -76.071975,
              39.475047
            ],
            [
              -76.083286,
              39.47786
            ],
            [
              -76.098315,
              39.476116
            ],
            [
              -76.104665,
              39.478792
            ],
            [
              -76.113929,
              39.486701
            ],
            [
              -76.117253,
              39.496068
            ],
            [
              -76.096072,
              39.536912
            ],
            [
              -76.07571439122009,
              39.5430317138944
            ],
            [
              -76.085972,
              39.559011
            ],
            [
              -76.094472,
              39.56781
            ],
            [
              -76.106083,
              39.580525
            ],
            [
              -76.147474,
              39.619407
            ],
            [
              -76.173575,
              39.660006
            ],
            [
              -76.212676,
              39.686405
            ],
            [
              -76.23327974765701,
              39.7216465489498
            ],
            [
              -76.233485,
              39.721646
            ],
            [
              -76.2396824239736,
              39.721645324517596
            ],
            [
              -76.380282,
              39.72163
            ],
            [
              -76.380782,
              39.72163
            ],
            [
              -76.395781,
              39.721528
            ],
            [
              -76.40454774625779,
              39.721565193867
            ],
            [
              -76.41888,
              39.721626
            ],
            [
              -76.41898,
              39.721526
            ],
            [
              -76.4424425714211,
              39.7215555292216
            ],
            [
              -76.492079,
              39.721618
            ],
            [
              -76.517277,
              39.721615
            ],
            [
              -76.5694756327762,
              39.721509203425
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "24",
      "COUNTYFP": "005",
      "COUNTYNS": "01695314",
      "AFFGEOID": "0500000US24005",
      "GEOID": "24005",
      "NAME": "Baltimore",
      "LSAD": "06",
      "ALAND": 1549830329,
      "AWATER": 215909963,
      "County_state": "Baltimore,24"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.8873,
              39.440502
            ],
            [
              -76.89556,
              39.429487
            ],
            [
              -76.881429,
              39.417403
            ],
            [
              -76.884117,
              39.396568
            ],
            [
              -76.873439,
              39.388865
            ],
            [
              -76.891332,
              39.382229
            ],
            [
              -76.888949,
              39.370569
            ],
            [
              -76.873378,
              39.35715
            ],
            [
              -76.882274,
              39.350185
            ],
            [
              -76.874285,
              39.331693
            ],
            [
              -76.833568,
              39.311514
            ],
            [
              -76.822937,
              39.318834
            ],
            [
              -76.7946,
              39.314232
            ],
            [
              -76.778595,
              39.294989
            ],
            [
              -76.794495,
              39.266615
            ],
            [
              -76.76383,
              39.251312
            ],
            [
              -76.764575,
              39.249078
            ],
            [
              -76.739528,
              39.232163
            ],
            [
              -76.722851,
              39.227187
            ],
            [
              -76.717258,
              39.225479
            ],
            [
              -76.701078,
              39.213244
            ],
            [
              -76.697085,
              39.214092
            ],
            [
              -76.69675,
              39.217399
            ],
            [
              -76.650522,
              39.229466
            ],
            [
              -76.641409,
              39.22558
            ],
            [
              -76.631527,
              39.223861
            ],
            [
              -76.618612,
              39.237398
            ],
            [
              -76.644212,
              39.248924
            ],
            [
              -76.65986,
              39.255509
            ],
            [
              -76.684649,
              39.266458
            ],
            [
              -76.698892,
              39.272705
            ],
            [
              -76.711054,
              39.277889
            ],
            [
              -76.711186,
              39.287394
            ],
            [
              -76.711186,
              39.354383
            ],
            [
              -76.711191,
              39.362798
            ],
            [
              -76.711313,
              39.371933
            ],
            [
              -76.626193,
              39.372017
            ],
            [
              -76.582208,
              39.372106
            ],
            [
              -76.569997,
              39.372121
            ],
            [
              -76.544243,
              39.372181
            ],
            [
              -76.529793,
              39.372058
            ],
            [
              -76.529785,
              39.356552
            ],
            [
              -76.529773,
              39.355575
            ],
            [
              -76.529786,
              39.310391
            ],
            [
              -76.529517,
              39.297056
            ],
            [
              -76.5295087321405,
              39.2413137696621
            ],
            [
              -76.520886,
              39.241091
            ],
            [
              -76.512567,
              39.22848
            ],
            [
              -76.503178,
              39.232071
            ],
            [
              -76.5031512696809,
              39.232022679231
            ],
            [
              -76.49884475219949,
              39.224237727783894
            ],
            [
              -76.496048,
              39.219182
            ],
            [
              -76.500984,
              39.209376
            ],
            [
              -76.498384,
              39.204808
            ],
            [
              -76.488883,
              39.202208
            ],
            [
              -76.480083,
              39.205108
            ],
            [
              -76.463483,
              39.205908
            ],
            [
              -76.46156043515879,
              39.2049467633526
            ],
            [
              -76.4476212155832,
              39.1979774854352
            ],
            [
              -76.442482,
              39.195408
            ],
            [
              -76.425281,
              39.205708
            ],
            [
              -76.417681,
              39.219808
            ],
            [
              -76.3987421577894,
              39.2299675972061
            ],
            [
              -76.395509,
              39.231702
            ],
            [
              -76.371477,
              39.237951
            ],
            [
              -76.349994,
              39.248822
            ],
            [
              -76.343826,
              39.257256
            ],
            [
              -76.356609,
              39.260856
            ],
            [
              -76.3844763515242,
              39.2757017769749
            ],
            [
              -76.384901,
              39.275928
            ],
            [
              -76.381621,
              39.284278
            ],
            [
              -76.3815906303691,
              39.2845794849282
            ],
            [
              -76.380179,
              39.298593
            ],
            [
              -76.3799577563476,
              39.2987786238307
            ],
            [
              -76.3657189579888,
              39.310725001806496
            ],
            [
              -76.36439,
              39.31184
            ],
            [
              -76.3402206806284,
              39.305991490576396
            ],
            [
              -76.336582,
              39.305111
            ],
            [
              -76.329184,
              39.315036
            ],
            [
              -76.350945,
              39.331141
            ],
            [
              -76.350141833378,
              39.3340520512683
            ],
            [
              -76.3486938081234,
              39.3393003716523
            ],
            [
              -76.347659,
              39.343051
            ],
            [
              -76.335563,
              39.342279
            ],
            [
              -76.343339,
              39.358712
            ],
            [
              -76.341794,
              39.374147
            ],
            [
              -76.35894093806239,
              39.3812702085133
            ],
            [
              -76.359263,
              39.381404
            ],
            [
              -76.3578403919927,
              39.3898339001833
            ],
            [
              -76.358781,
              39.390305
            ],
            [
              -76.359269,
              39.394046
            ],
            [
              -76.360113,
              39.394778
            ],
            [
              -76.371941,
              39.405102
            ],
            [
              -76.373493,
              39.421418
            ],
            [
              -76.386697,
              39.459705
            ],
            [
              -76.420896,
              39.478414
            ],
            [
              -76.433114,
              39.488268
            ],
            [
              -76.43698,
              39.501964
            ],
            [
              -76.494569,
              39.52655
            ],
            [
              -76.510387,
              39.524388
            ],
            [
              -76.532174,
              39.543977
            ],
            [
              -76.547825,
              39.588219
            ],
            [
              -76.559464,
              39.59766
            ],
            [
              -76.565189,
              39.614504
            ],
            [
              -76.56671,
              39.662689
            ],
            [
              -76.5694756327762,
              39.721509203425
            ],
            [
              -76.569576,
              39.721509
            ],
            [
              -76.60977146442329,
              39.7214765606444
            ],
            [
              -76.6976582563422,
              39.7214056324705
            ],
            [
              -76.712072,
              39.721394
            ],
            [
              -76.715771,
              39.721393
            ],
            [
              -76.7870972309155,
              39.721085744246494
            ],
            [
              -76.804304,
              39.675882
            ],
            [
              -76.831244,
              39.60218
            ],
            [
              -76.8667,
              39.500902
            ],
            [
              -76.869309,
              39.482459
            ],
            [
              -76.8931,
              39.457402
            ],
            [
              -76.8873,
              39.440502
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "24",
      "COUNTYFP": "021",
      "COUNTYNS": "01711211",
      "AFFGEOID": "0500000US24021",
      "GEOID": "24021",
      "NAME": "Frederick",
      "LSAD": "06",
      "ALAND": 1710553993,
      "AWATER": 18042333,
      "County_state": "Frederick,24"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.67712301274629,
              39.32407737394119
            ],
            [
              -77.675846,
              39.324192
            ],
            [
              -77.671341,
              39.321675
            ],
            [
              -77.66613,
              39.317008
            ],
            [
              -77.650997,
              39.310784
            ],
            [
              -77.640282,
              39.308241
            ],
            [
              -77.636101,
              39.307782
            ],
            [
              -77.615939,
              39.302722
            ],
            [
              -77.6059,
              39.303688
            ],
            [
              -77.598892,
              39.301883
            ],
            [
              -77.592739,
              39.30129
            ],
            [
              -77.588235,
              39.301955
            ],
            [
              -77.578491,
              39.305228
            ],
            [
              -77.570041,
              39.30635
            ],
            [
              -77.566596,
              39.306121
            ],
            [
              -77.5658310251171,
              39.3056199030448
            ],
            [
              -77.56321,
              39.303903
            ],
            [
              -77.561826,
              39.301913
            ],
            [
              -77.562768,
              39.294501
            ],
            [
              -77.560854,
              39.286152
            ],
            [
              -77.553114,
              39.279268
            ],
            [
              -77.551054,
              39.275882
            ],
            [
              -77.545846,
              39.271535
            ],
            [
              -77.544258,
              39.26966
            ],
            [
              -77.543228,
              39.266937
            ],
            [
              -77.540581,
              39.264947
            ],
            [
              -77.534461,
              39.262361
            ],
            [
              -77.522486,
              39.259086
            ],
            [
              -77.520986,
              39.258491
            ],
            [
              -77.519634,
              39.257232
            ],
            [
              -77.508427,
              39.25263
            ],
            [
              -77.496606,
              39.251045
            ],
            [
              -77.486813,
              39.247586
            ],
            [
              -77.484664,
              39.246123
            ],
            [
              -77.480807,
              39.241664
            ],
            [
              -77.471213,
              39.234509
            ],
            [
              -77.46021,
              39.228359
            ],
            [
              -77.45812,
              39.22614
            ],
            [
              -77.45768,
              39.22502
            ],
            [
              -77.457943,
              39.222023
            ],
            [
              -77.4589223494682,
              39.220336398673496
            ],
            [
              -77.454754,
              39.221663
            ],
            [
              -77.285719,
              39.299377
            ],
            [
              -77.28373,
              39.300379
            ],
            [
              -77.262529,
              39.31028
            ],
            [
              -77.168801,
              39.353502
            ],
            [
              -77.16808,
              39.353957
            ],
            [
              -77.158185,
              39.373451
            ],
            [
              -77.14717,
              39.419005
            ],
            [
              -77.11605,
              39.460344
            ],
            [
              -77.117686,
              39.478619
            ],
            [
              -77.106747,
              39.491859
            ],
            [
              -77.109998,
              39.49748
            ],
            [
              -77.15234,
              39.532498
            ],
            [
              -77.170746,
              39.534753
            ],
            [
              -77.169445,
              39.554118
            ],
            [
              -77.198024,
              39.57543
            ],
            [
              -77.222103,
              39.576398
            ],
            [
              -77.256509,
              39.603771
            ],
            [
              -77.263701,
              39.594767
            ],
            [
              -77.271574,
              39.606221
            ],
            [
              -77.287747,
              39.604516
            ],
            [
              -77.307416,
              39.619101
            ],
            [
              -77.300552,
              39.625156
            ],
            [
              -77.311161,
              39.639733
            ],
            [
              -77.291457,
              39.63058
            ],
            [
              -77.275846,
              39.63266
            ],
            [
              -77.275617,
              39.647125
            ],
            [
              -77.262151,
              39.665168
            ],
            [
              -77.234312,
              39.678196
            ],
            [
              -77.242465,
              39.695282
            ],
            [
              -77.21527,
              39.699018
            ],
            [
              -77.226338,
              39.706238
            ],
            [
              -77.2170240489139,
              39.720235990471494
            ],
            [
              -77.23995,
              39.720233
            ],
            [
              -77.24345,
              39.720233
            ],
            [
              -77.459433419622,
              39.720229174305295
            ],
            [
              -77.4691450037375,
              39.720229002284896
            ],
            [
              -77.490355,
              39.695842
            ],
            [
              -77.50836,
              39.684072
            ],
            [
              -77.492874,
              39.676425
            ],
            [
              -77.520164,
              39.640678
            ],
            [
              -77.53276,
              39.631183
            ],
            [
              -77.570182,
              39.619998
            ],
            [
              -77.586761,
              39.571365
            ],
            [
              -77.593971,
              39.563919
            ],
            [
              -77.597704,
              39.540781
            ],
            [
              -77.60879,
              39.534158
            ],
            [
              -77.622604,
              39.507598
            ],
            [
              -77.61441,
              39.491551
            ],
            [
              -77.622024,
              39.481754
            ],
            [
              -77.616049,
              39.469157
            ],
            [
              -77.633842,
              39.446819
            ],
            [
              -77.640411,
              39.402538
            ],
            [
              -77.656177,
              39.368397
            ],
            [
              -77.67396,
              39.343061
            ],
            [
              -77.67712301274629,
              39.32407737394119
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "24",
      "COUNTYFP": "033",
      "COUNTYNS": "01714670",
      "AFFGEOID": "0500000US24033",
      "GEOID": "24033",
      "NAME": "Prince George's",
      "LSAD": "06",
      "ALAND": 1250087042,
      "AWATER": 41892810,
      "County_state": "Prince George's,24"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.079499,
              38.709515
            ],
            [
              -77.0863289190155,
              38.70612807975
            ],
            [
              -77.077027,
              38.690168
            ],
            [
              -77.047656,
              38.616303
            ],
            [
              -77.021116,
              38.646444
            ],
            [
              -76.998596,
              38.654664
            ],
            [
              -76.965748,
              38.66127
            ],
            [
              -76.913516,
              38.652928
            ],
            [
              -76.862955,
              38.658649
            ],
            [
              -76.757951,
              38.619713
            ],
            [
              -76.748495,
              38.618197
            ],
            [
              -76.742682,
              38.594217
            ],
            [
              -76.740526,
              38.558673
            ],
            [
              -76.702016,
              38.545541
            ],
            [
              -76.700403,
              38.542302
            ],
            [
              -76.675457,
              38.535876
            ],
            [
              -76.672274,
              38.552846
            ],
            [
              -76.680702,
              38.580499
            ],
            [
              -76.672557,
              38.599271
            ],
            [
              -76.672334,
              38.617533
            ],
            [
              -76.678468,
              38.627321
            ],
            [
              -76.692853,
              38.636437
            ],
            [
              -76.69163,
              38.648506
            ],
            [
              -76.684304,
              38.656485
            ],
            [
              -76.683866,
              38.662171
            ],
            [
              -76.701003,
              38.668362
            ],
            [
              -76.690461,
              38.673683
            ],
            [
              -76.687147,
              38.681371
            ],
            [
              -76.70196,
              38.710864
            ],
            [
              -76.686358,
              38.748475
            ],
            [
              -76.699271,
              38.75341
            ],
            [
              -76.714317,
              38.778226
            ],
            [
              -76.702403,
              38.787945
            ],
            [
              -76.710639,
              38.815694
            ],
            [
              -76.696371,
              38.822724
            ],
            [
              -76.691527,
              38.8598
            ],
            [
              -76.672145,
              38.886627
            ],
            [
              -76.676276,
              38.895163
            ],
            [
              -76.670111,
              38.906919
            ],
            [
              -76.690533,
              38.924763
            ],
            [
              -76.698459,
              38.983783
            ],
            [
              -76.744517,
              39.01712
            ],
            [
              -76.751258,
              39.034714
            ],
            [
              -76.776528,
              39.04531
            ],
            [
              -76.790741,
              39.045722
            ],
            [
              -76.803941,
              39.062446
            ],
            [
              -76.831424,
              39.069034
            ],
            [
              -76.837724,
              39.071802
            ],
            [
              -76.832719,
              39.075943
            ],
            [
              -76.827415,
              39.092979
            ],
            [
              -76.840362,
              39.103142
            ],
            [
              -76.870109,
              39.112712
            ],
            [
              -76.888505,
              39.130967
            ],
            [
              -76.907292,
              39.117908
            ],
            [
              -76.917382,
              39.09153
            ],
            [
              -76.951772,
              39.045307
            ],
            [
              -76.971723,
              39.018556
            ],
            [
              -76.974565,
              39.014714
            ],
            [
              -76.991098,
              38.992405
            ],
            [
              -76.990536,
              38.975643
            ],
            [
              -77.0025892201687,
              38.9654839197919
            ],
            [
              -77.002498,
              38.96541
            ],
            [
              -76.9918925205442,
              38.9572123964546
            ],
            [
              -76.9419210679896,
              38.918586499498396
            ],
            [
              -76.935096,
              38.913311
            ],
            [
              -76.92754893665301,
              38.9073037102278
            ],
            [
              -76.909393,
              38.892852
            ],
            [
              -76.910795,
              38.891712
            ],
            [
              -76.919295,
              38.885112
            ],
            [
              -76.920195,
              38.884412
            ],
            [
              -76.949696,
              38.861312
            ],
            [
              -76.953696,
              38.858512
            ],
            [
              -76.9581401027746,
              38.8549465208544
            ],
            [
              -76.96194148624339,
              38.8518966918631
            ],
            [
              -76.979497,
              38.837812
            ],
            [
              -76.992697,
              38.828213
            ],
            [
              -76.999997,
              38.821913
            ],
            [
              -77.001397,
              38.821513
            ],
            [
              -77.0244236516675,
              38.8032258870216
            ],
            [
              -77.039006,
              38.791645
            ],
            [
              -77.039498,
              38.791113
            ],
            [
              -77.040098,
              38.789913
            ],
            [
              -77.040372554527,
              38.785355394851
            ],
            [
              -77.041098,
              38.773313
            ],
            [
              -77.0412178195569,
              38.7685503223889
            ],
            [
              -77.04176714029269,
              38.7467155097909
            ],
            [
              -77.041898,
              38.741514
            ],
            [
              -77.040998,
              38.737914
            ],
            [
              -77.041398,
              38.724515
            ],
            [
              -77.042298,
              38.718515
            ],
            [
              -77.045498,
              38.714315
            ],
            [
              -77.053199,
              38.709915
            ],
            [
              -77.065322,
              38.709564
            ],
            [
              -77.071861,
              38.710581
            ],
            [
              -77.072807,
              38.711526
            ],
            [
              -77.073714031001,
              38.7112534298651
            ],
            [
              -77.079499,
              38.709515
            ]
          ]
        ]
      ]
    }
  }
]
};