export const counties_me = {
  "type":"FeatureCollection","name":"me_counties","features":[
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "23",
      "COUNTYFP": "003",
      "COUNTYNS": "00581287",
      "AFFGEOID": "0500000US23003",
      "GEOID": "23003",
      "NAME": "Aroostook",
      "LSAD": "06",
      "ALAND": 17278038056,
      "AWATER": 405253238,
      "County_state": "Aroostook,23"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -70.019747,
              46.592166
            ],
            [
              -70.023362666359,
              46.5735117449688
            ],
            [
              -69.721109,
              46.574053
            ],
            [
              -68.821414,
              46.57251
            ],
            [
              -68.819401,
              46.395782
            ],
            [
              -68.69492,
              46.395505
            ],
            [
              -68.694877,
              46.382612
            ],
            [
              -68.436443,
              46.382121
            ],
            [
              -68.436285,
              46.124744
            ],
            [
              -68.436902,
              46.04289
            ],
            [
              -68.432273,
              46.035568
            ],
            [
              -68.431682,
              45.952442
            ],
            [
              -68.427598,
              45.944591
            ],
            [
              -68.426169,
              45.870829
            ],
            [
              -68.426621,
              45.85338
            ],
            [
              -68.427551,
              45.759867
            ],
            [
              -68.432463,
              45.667827
            ],
            [
              -68.433001,
              45.581204
            ],
            [
              -68.430853,
              45.578084
            ],
            [
              -68.410884,
              45.573337
            ],
            [
              -68.257502,
              45.597949
            ],
            [
              -68.260136,
              45.604483
            ],
            [
              -68.048012,
              45.639897
            ],
            [
              -67.896955,
              45.665302
            ],
            [
              -67.902264,
              45.680115
            ],
            [
              -67.873581,
              45.669296
            ],
            [
              -67.8054831422691,
              45.6802407212124
            ],
            [
              -67.817892,
              45.693705
            ],
            [
              -67.803148,
              45.696127
            ],
            [
              -67.809833,
              45.729274
            ],
            [
              -67.781892,
              45.731189
            ],
            [
              -67.793083,
              45.750559
            ],
            [
              -67.806308,
              45.755405
            ],
            [
              -67.809083,
              45.767497
            ],
            [
              -67.805457,
              45.76976
            ],
            [
              -67.803626,
              45.781624
            ],
            [
              -67.806598,
              45.794723
            ],
            [
              -67.801989,
              45.803546
            ],
            [
              -67.780443,
              45.816206
            ],
            [
              -67.780507,
              45.817622
            ],
            [
              -67.780082,
              45.818194
            ],
            [
              -67.77763,
              45.819222
            ],
            [
              -67.772758,
              45.81938
            ],
            [
              -67.766842,
              45.818533
            ],
            [
              -67.765534,
              45.818807
            ],
            [
              -67.763767,
              45.820315
            ],
            [
              -67.755068,
              45.82367
            ],
            [
              -67.762812,
              45.82852
            ],
            [
              -67.763955,
              45.829983
            ],
            [
              -67.76503,
              45.83403
            ],
            [
              -67.769179,
              45.839335
            ],
            [
              -67.772056,
              45.841348
            ],
            [
              -67.777975,
              45.843704
            ],
            [
              -67.783108,
              45.846517
            ],
            [
              -67.78968,
              45.851754
            ],
            [
              -67.796514,
              45.859961
            ],
            [
              -67.803678,
              45.869379
            ],
            [
              -67.804724,
              45.874043
            ],
            [
              -67.803908,
              45.882895
            ],
            [
              -67.803318,
              45.883718
            ],
            [
              -67.785344,
              45.895648
            ],
            [
              -67.783314,
              45.895167
            ],
            [
              -67.779157,
              45.89512
            ],
            [
              -67.768745,
              45.897951
            ],
            [
              -67.767827,
              45.898568
            ],
            [
              -67.768709,
              45.901997
            ],
            [
              -67.765002,
              45.909791
            ],
            [
              -67.763725,
              45.91043
            ],
            [
              -67.759665,
              45.909925
            ],
            [
              -67.755701,
              45.911684
            ],
            [
              -67.751866,
              45.914973
            ],
            [
              -67.750422,
              45.917898
            ],
            [
              -67.75752,
              45.925926
            ],
            [
              -67.773564,
              45.934823
            ],
            [
              -67.774907,
              45.935007
            ],
            [
              -67.776873,
              45.93407
            ],
            [
              -67.777626,
              45.934207
            ],
            [
              -67.779984,
              45.938163
            ],
            [
              -67.781095,
              45.943032
            ],
            [
              -67.781378,
              45.963948
            ],
            [
              -67.780462,
              46.004447
            ],
            [
              -67.780438,
              46.038452
            ],
            [
              -67.7814655798686,
              46.186169058573796
            ],
            [
              -67.782114,
              46.279381
            ],
            [
              -67.784509,
              46.437576
            ],
            [
              -67.78519770397129,
              46.4665978828503
            ],
            [
              -67.7883611593976,
              46.59990542368801
            ],
            [
              -67.788406,
              46.601795
            ],
            [
              -67.789284,
              46.758219
            ],
            [
              -67.78938,
              46.765528
            ],
            [
              -67.789799,
              46.794868
            ],
            [
              -67.78928822670801,
              46.87217968676919
            ],
            [
              -67.78923,
              46.880993
            ],
            [
              -67.789766,
              47.000044
            ],
            [
              -67.78926,
              47.003644
            ],
            [
              -67.78956,
              47.015444
            ],
            [
              -67.78896,
              47.025844
            ],
            [
              -67.78966,
              47.040544
            ],
            [
              -67.789461,
              47.062544
            ],
            [
              -67.789761,
              47.065744
            ],
            [
              -67.790515,
              47.067921
            ],
            [
              -67.794446,
              47.069871
            ],
            [
              -67.804512,
              47.07339
            ],
            [
              -67.810716,
              47.07609
            ],
            [
              -67.816345,
              47.081188
            ],
            [
              -67.820828,
              47.083518
            ],
            [
              -67.823304,
              47.084318
            ],
            [
              -67.832373,
              47.085551
            ],
            [
              -67.83689,
              47.087036
            ],
            [
              -67.849008,
              47.093455
            ],
            [
              -67.864574,
              47.099207
            ],
            [
              -67.870367,
              47.100896
            ],
            [
              -67.878124,
              47.102426
            ],
            [
              -67.881302,
              47.103913
            ],
            [
              -67.883844,
              47.105834
            ],
            [
              -67.88565,
              47.10732
            ],
            [
              -67.888658,
              47.111664
            ],
            [
              -67.889193,
              47.113401
            ],
            [
              -67.889155,
              47.118772
            ],
            [
              -67.890026,
              47.124678
            ],
            [
              -67.892166,
              47.128643
            ],
            [
              -67.893266,
              47.129943
            ],
            [
              -67.901166,
              47.135143
            ],
            [
              -67.903366,
              47.137643
            ],
            [
              -67.910367,
              47.148143
            ],
            [
              -67.919767,
              47.151243
            ],
            [
              -67.925768,
              47.154243
            ],
            [
              -67.9269829211177,
              47.1555180657275
            ],
            [
              -67.935868,
              47.164843
            ],
            [
              -67.939168,
              47.171442
            ],
            [
              -67.943069,
              47.176142
            ],
            [
              -67.944669,
              47.177042
            ],
            [
              -67.949369,
              47.182542
            ],
            [
              -67.950569,
              47.185142
            ],
            [
              -67.951269,
              47.192442
            ],
            [
              -67.952269,
              47.196142
            ],
            [
              -67.955669,
              47.199542
            ],
            [
              -67.96347,
              47.202842
            ],
            [
              -67.96907,
              47.206342
            ],
            [
              -67.97137,
              47.207142
            ],
            [
              -67.986071,
              47.209342
            ],
            [
              -67.990171,
              47.211042
            ],
            [
              -67.991871,
              47.212042
            ],
            [
              -67.998171,
              47.217842
            ],
            [
              -68.006314,
              47.226444
            ],
            [
              -68.016103,
              47.234446
            ],
            [
              -68.019724,
              47.238036
            ],
            [
              -68.033916,
              47.24364
            ],
            [
              -68.037335,
              47.245678
            ],
            [
              -68.061842,
              47.256451
            ],
            [
              -68.074061,
              47.259764
            ],
            [
              -68.076009,
              47.262095
            ],
            [
              -68.077521,
              47.266666
            ],
            [
              -68.080175,
              47.26991
            ],
            [
              -68.082896,
              47.271921
            ],
            [
              -68.092501,
              47.276696
            ],
            [
              -68.119682,
              47.28729
            ],
            [
              -68.121463,
              47.288272
            ],
            [
              -68.125407,
              47.292155
            ],
            [
              -68.128226,
              47.29419
            ],
            [
              -68.137059,
              47.296068
            ],
            [
              -68.145452,
              47.304093
            ],
            [
              -68.152302,
              47.309878
            ],
            [
              -68.153275,
              47.311729
            ],
            [
              -68.153509,
              47.314038
            ],
            [
              -68.152462,
              47.321534
            ],
            [
              -68.15357,
              47.324162
            ],
            [
              -68.15515,
              47.32542
            ],
            [
              -68.161333,
              47.327752
            ],
            [
              -68.176055,
              47.32924
            ],
            [
              -68.202346,
              47.33941
            ],
            [
              -68.204263,
              47.33973
            ],
            [
              -68.211222,
              47.339112
            ],
            [
              -68.214551,
              47.339637
            ],
            [
              -68.217712,
              47.340847
            ],
            [
              -68.222893,
              47.344526
            ],
            [
              -68.224139,
              47.346696
            ],
            [
              -68.224174,
              47.349827
            ],
            [
              -68.224646,
              47.350787
            ],
            [
              -68.227607,
              47.353049
            ],
            [
              -68.234604,
              47.355035
            ],
            [
              -68.240168,
              47.354217
            ],
            [
              -68.247987,
              47.352514
            ],
            [
              -68.265138,
              47.352543
            ],
            [
              -68.26971,
              47.353733
            ],
            [
              -68.272501,
              47.354923
            ],
            [
              -68.278284,
              47.358604
            ],
            [
              -68.284101,
              47.360389
            ],
            [
              -68.289315,
              47.360207
            ],
            [
              -68.292679,
              47.359476
            ],
            [
              -68.300718,
              47.356095
            ],
            [
              -68.303778,
              47.355524
            ],
            [
              -68.309933,
              47.356233
            ],
            [
              -68.323186,
              47.359888
            ],
            [
              -68.329879,
              47.36023
            ],
            [
              -68.336236,
              47.359795
            ],
            [
              -68.355171,
              47.35707
            ],
            [
              -68.361559,
              47.355605
            ],
            [
              -68.365728,
              47.353797
            ],
            [
              -68.370265,
              47.351052
            ],
            [
              -68.374487,
              47.347508
            ],
            [
              -68.378616,
              47.343144
            ],
            [
              -68.380334,
              47.340242
            ],
            [
              -68.384281,
              47.326943
            ],
            [
              -68.384622,
              47.322303
            ],
            [
              -68.381308,
              47.309161
            ],
            [
              -68.381746,
              47.307813
            ],
            [
              -68.384706,
              47.305094
            ],
            [
              -68.384943,
              47.302923
            ],
            [
              -68.384105,
              47.301506
            ],
            [
              -68.378667,
              47.297412
            ],
            [
              -68.376955,
              47.295628
            ],
            [
              -68.376319,
              47.294257
            ],
            [
              -68.375615,
              47.292268
            ],
            [
              -68.375848,
              47.290065
            ],
            [
              -68.376829,
              47.28852
            ],
            [
              -68.378678,
              47.287561
            ],
            [
              -68.383146,
              47.286672
            ],
            [
              -68.389863,
              47.286652
            ],
            [
              -68.41314,
              47.288488
            ],
            [
              -68.415861,
              47.287803
            ],
            [
              -68.425233,
              47.283188
            ],
            [
              -68.428861,
              47.281954
            ],
            [
              -68.432555,
              47.28193
            ],
            [
              -68.443235,
              47.283484
            ],
            [
              -68.448844,
              47.282547
            ],
            [
              -68.45825,
              47.284625
            ],
            [
              -68.460064,
              47.286065
            ],
            [
              -68.46190492174169,
              47.2889986740063
            ],
            [
              -68.463964,
              47.29228
            ],
            [
              -68.46628,
              47.294873
            ],
            [
              -68.470282,
              47.296804
            ],
            [
              -68.474851,
              47.297534
            ],
            [
              -68.478983,
              47.296893
            ],
            [
              -68.486742,
              47.29465
            ],
            [
              -68.491075,
              47.294099
            ],
            [
              -68.496719,
              47.294462
            ],
            [
              -68.504006,
              47.296337
            ],
            [
              -68.507432,
              47.296636
            ],
            [
              -68.517982,
              47.296092
            ],
            [
              -68.523492,
              47.294768
            ],
            [
              -68.52907,
              47.292644
            ],
            [
              -68.539587,
              47.285173
            ],
            [
              -68.546641,
              47.28298
            ],
            [
              -68.551747,
              47.282226
            ],
            [
              -68.553896,
              47.28225
            ],
            [
              -68.560378,
              47.28369
            ],
            [
              -68.571094,
              47.287049
            ],
            [
              -68.578551,
              47.287551
            ],
            [
              -68.582984,
              47.285493
            ],
            [
              -68.588725,
              47.281721
            ],
            [
              -68.590605,
              47.280052
            ],
            [
              -68.592516,
              47.274726
            ],
            [
              -68.59688,
              47.271731
            ],
            [
              -68.598726,
              47.269879
            ],
            [
              -68.598657,
              47.267868
            ],
            [
              -68.595028,
              47.263161
            ],
            [
              -68.594053,
              47.261218
            ],
            [
              -68.594085,
              47.259275
            ],
            [
              -68.595427,
              47.257698
            ],
            [
              -68.604819,
              47.249418
            ],
            [
              -68.607906,
              47.247497
            ],
            [
              -68.619749,
              47.243218
            ],
            [
              -68.624964,
              47.242392
            ],
            [
              -68.639,
              47.241394
            ],
            [
              -68.648029,
              47.239706
            ],
            [
              -68.653902,
              47.239479
            ],
            [
              -68.658467,
              47.237149
            ],
            [
              -68.661957,
              47.236327
            ],
            [
              -68.664071,
              47.236762
            ],
            [
              -68.675913,
              47.242626
            ],
            [
              -68.687662,
              47.244215
            ],
            [
              -68.690414,
              47.243987
            ],
            [
              -68.693434,
              47.243072
            ],
            [
              -68.705314,
              47.238066
            ],
            [
              -68.70857,
              47.238705
            ],
            [
              -68.710919,
              47.240327
            ],
            [
              -68.713638,
              47.240989
            ],
            [
              -68.717867,
              47.240919
            ],
            [
              -68.73538,
              47.235793
            ],
            [
              -68.745241,
              47.23108
            ],
            [
              -68.752104,
              47.226645
            ],
            [
              -68.764487,
              47.222331
            ],
            [
              -68.769721,
              47.22135
            ],
            [
              -68.780557,
              47.221605
            ],
            [
              -68.803537,
              47.216033
            ],
            [
              -68.812157,
              47.215461
            ],
            [
              -68.83097,
              47.204945
            ],
            [
              -68.848505,
              47.197558
            ],
            [
              -68.85343,
              47.193443
            ],
            [
              -68.857519,
              47.19095
            ],
            [
              -68.862848,
              47.18953
            ],
            [
              -68.871096,
              47.189252
            ],
            [
              -68.874487,
              47.188405
            ],
            [
              -68.877914,
              47.186601
            ],
            [
              -68.88241,
              47.183357
            ],
            [
              -68.890154,
              47.182241
            ],
            [
              -68.893204,
              47.182974
            ],
            [
              -68.895685,
              47.182883
            ],
            [
              -68.89877,
              47.181353
            ],
            [
              -68.900985,
              47.178519
            ],
            [
              -68.902425,
              47.178839
            ],
            [
              -68.90524,
              47.180919
            ],
            [
              -68.909729,
              47.186292
            ],
            [
              -68.916869,
              47.189333
            ],
            [
              -68.919752,
              47.189859
            ],
            [
              -68.920253,
              47.195048
            ],
            [
              -68.924645,
              47.197791
            ],
            [
              -68.926421,
              47.19786
            ],
            [
              -68.92793,
              47.196969
            ],
            [
              -68.929808,
              47.197266
            ],
            [
              -68.93571,
              47.202249
            ],
            [
              -68.942484,
              47.206386
            ],
            [
              -68.948923,
              47.202819
            ],
            [
              -68.950397,
              47.202476
            ],
            [
              -68.951769,
              47.20292
            ],
            [
              -68.952511,
              47.206087
            ],
            [
              -68.95335,
              47.206749
            ],
            [
              -68.958414,
              47.205491
            ],
            [
              -68.96113,
              47.205582
            ],
            [
              -68.966433,
              47.212712
            ],
            [
              -68.972169,
              47.21431
            ],
            [
              -68.975089,
              47.216687
            ],
            [
              -68.981096,
              47.219884
            ],
            [
              -68.992641,
              47.224564
            ],
            [
              -69.004102,
              47.230162
            ],
            [
              -69.023826,
              47.238353
            ],
            [
              -69.030067,
              47.240549
            ],
            [
              -69.033456,
              47.240984
            ],
            [
              -69.0402,
              47.2451
            ],
            [
              -69.050334,
              47.256621
            ],
            [
              -69.050737,
              47.25733
            ],
            [
              -69.050367,
              47.259821
            ],
            [
              -69.049326,
              47.262107
            ],
            [
              -69.048083,
              47.263044
            ],
            [
              -69.047076,
              47.267089
            ],
            [
              -69.047846,
              47.274243
            ],
            [
              -69.049593,
              47.276804
            ],
            [
              -69.050465,
              47.280575
            ],
            [
              -69.04939,
              47.284689
            ],
            [
              -69.050094,
              47.2915
            ],
            [
              -69.050699,
              47.292552
            ],
            [
              -69.052278,
              47.293078
            ],
            [
              -69.052748,
              47.294403
            ],
            [
              -69.052614,
              47.298289
            ],
            [
              -69.051337,
              47.299774
            ],
            [
              -69.049118,
              47.306471
            ],
            [
              -69.054628,
              47.315911
            ],
            [
              -69.053284,
              47.327568
            ],
            [
              -69.053885,
              47.377878
            ],
            [
              -69.046448,
              47.383934
            ],
            [
              -69.043958,
              47.383682
            ],
            [
              -69.03928,
              47.385052
            ],
            [
              -69.039818,
              47.386309
            ],
            [
              -69.04288,
              47.387841
            ],
            [
              -69.044259,
              47.389259
            ],
            [
              -69.045403,
              47.39191
            ],
            [
              -69.043248,
              47.397189
            ],
            [
              -69.041733,
              47.397326
            ],
            [
              -69.04079,
              47.39808
            ],
            [
              -69.042002,
              47.399269
            ],
            [
              -69.042371,
              47.401143
            ],
            [
              -69.039138,
              47.404068
            ],
            [
              -69.036882,
              47.407977
            ],
            [
              -69.039301,
              47.42217
            ],
            [
              -69.042702,
              47.426651
            ],
            [
              -69.043947,
              47.427634
            ],
            [
              -69.055465,
              47.432274
            ],
            [
              -69.061192,
              47.433052
            ],
            [
              -69.066715,
              47.43024
            ],
            [
              -69.080656,
              47.424068
            ],
            [
              -69.082508,
              47.423976
            ],
            [
              -69.096756,
              47.427013
            ],
            [
              -69.097364,
              47.430326
            ],
            [
              -69.098511,
              47.431217
            ],
            [
              -69.108215,
              47.435831
            ],
            [
              -69.122404,
              47.441881
            ],
            [
              -69.146439,
              47.44886
            ],
            [
              -69.156074,
              47.451035
            ],
            [
              -69.164362,
              47.451037
            ],
            [
              -69.178412,
              47.456615
            ],
            [
              -69.181951,
              47.45561
            ],
            [
              -69.203886,
              47.452203
            ],
            [
              -69.219996,
              47.457159
            ],
            [
              -69.22442,
              47.459686
            ],
            [
              -69.439198,
              47.250033
            ],
            [
              -69.566383,
              47.125032
            ],
            [
              -69.818552,
              46.87503
            ],
            [
              -69.874513,
              46.818027
            ],
            [
              -69.943121,
              46.750034
            ],
            [
              -69.95,
              46.742523
            ],
            [
              -69.994248,
              46.698564
            ],
            [
              -69.997086,
              46.69523
            ],
            [
              -70.019747,
              46.592166
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "23",
      "COUNTYFP": "029",
      "COUNTYNS": "00581300",
      "AFFGEOID": "0500000US23029",
      "GEOID": "23029",
      "NAME": "Washington",
      "LSAD": "06",
      "ALAND": 6637326498,
      "AWATER": 1800003936,
      "County_state": "Washington,23"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -68.091771,
              44.940973
            ],
            [
              -68.105017,
              44.939088
            ],
            [
              -68.034637,
              44.674142
            ],
            [
              -68.016062,
              44.676676
            ],
            [
              -67.998037,
              44.608772
            ],
            [
              -67.993551,
              44.592162
            ],
            [
              -68.017219,
              44.589087
            ],
            [
              -68.010718,
              44.568883
            ],
            [
              -67.991395,
              44.50281
            ],
            [
              -67.972968,
              44.488633
            ],
            [
              -67.981988,
              44.473756
            ],
            [
              -67.95708933421129,
              44.4154085107811
            ],
            [
              -67.955737,
              44.416278
            ],
            [
              -67.947342,
              44.415858
            ],
            [
              -67.936531,
              44.411187
            ],
            [
              -67.931453,
              44.411848
            ],
            [
              -67.927616,
              44.421314
            ],
            [
              -67.930554,
              44.428869
            ],
            [
              -67.926357,
              44.431807
            ],
            [
              -67.92132,
              44.433066
            ],
            [
              -67.913346,
              44.430128
            ],
            [
              -67.911667,
              44.419216
            ],
            [
              -67.899571,
              44.394078
            ],
            [
              -67.892033,
              44.409668
            ],
            [
              -67.887323,
              44.426351
            ],
            [
              -67.887323,
              44.433066
            ],
            [
              -67.88088663298501,
              44.4349054836069
            ],
            [
              -67.878509,
              44.435585
            ],
            [
              -67.868856,
              44.424672
            ],
            [
              -67.855108,
              44.419434
            ],
            [
              -67.851697,
              44.424282
            ],
            [
              -67.851764,
              44.428695
            ],
            [
              -67.830634,
              44.456655
            ],
            [
              -67.837938,
              44.46467
            ],
            [
              -67.825196,
              44.482846
            ],
            [
              -67.806182,
              44.485898
            ],
            [
              -67.795955,
              44.476177
            ],
            [
              -67.782414,
              44.475045
            ],
            [
              -67.793589,
              44.494779
            ],
            [
              -67.776472,
              44.514209
            ],
            [
              -67.780881,
              44.531151
            ],
            [
              -67.775046,
              44.543183
            ],
            [
              -67.767285,
              44.548278
            ],
            [
              -67.758891,
              44.546599
            ],
            [
              -67.753854,
              44.543661
            ],
            [
              -67.742942,
              44.526453
            ],
            [
              -67.740076,
              44.508944
            ],
            [
              -67.742789,
              44.506176
            ],
            [
              -67.743353,
              44.497418
            ],
            [
              -67.733986,
              44.496252
            ],
            [
              -67.722876,
              44.498524
            ],
            [
              -67.71419,
              44.495238
            ],
            [
              -67.703489,
              44.504837
            ],
            [
              -67.698872,
              44.51575
            ],
            [
              -67.702649,
              44.527922
            ],
            [
              -67.696354,
              44.533798
            ],
            [
              -67.685861,
              44.537155
            ],
            [
              -67.660678,
              44.537575
            ],
            [
              -67.656901,
              44.535896
            ],
            [
              -67.653123,
              44.525823
            ],
            [
              -67.648851,
              44.50669
            ],
            [
              -67.634806,
              44.487054
            ],
            [
              -67.595286,
              44.462195
            ],
            [
              -67.587725,
              44.447446
            ],
            [
              -67.547243,
              44.463066
            ],
            [
              -67.531444,
              44.474212
            ],
            [
              -67.509208,
              44.496986
            ],
            [
              -67.521168,
              44.50991
            ],
            [
              -67.568159,
              44.531117
            ],
            [
              -67.562321,
              44.539435
            ],
            [
              -67.569836,
              44.556788
            ],
            [
              -67.575056,
              44.560659
            ],
            [
              -67.537653,
              44.571538
            ],
            [
              -67.533279,
              44.564354
            ],
            [
              -67.51841,
              44.554241
            ],
            [
              -67.491751,
              44.556123
            ],
            [
              -67.4908,
              44.575258
            ],
            [
              -67.481529,
              44.580414
            ],
            [
              -67.4681139135035,
              44.59034190860059
            ],
            [
              -67.457747,
              44.598014
            ],
            [
              -67.448513,
              44.600322
            ],
            [
              -67.447464,
              44.60326
            ],
            [
              -67.443686,
              44.605779
            ],
            [
              -67.428367,
              44.609136
            ],
            [
              -67.420602,
              44.607877
            ],
            [
              -67.418923,
              44.60347
            ],
            [
              -67.411815,
              44.596954
            ],
            [
              -67.405492,
              44.594236
            ],
            [
              -67.398987,
              44.602631
            ],
            [
              -67.395839,
              44.612914
            ],
            [
              -67.388704,
              44.626554
            ],
            [
              -67.386605,
              44.626974
            ],
            [
              -67.377554,
              44.619757
            ],
            [
              -67.368269,
              44.624672
            ],
            [
              -67.363158,
              44.631825
            ],
            [
              -67.367298,
              44.652472
            ],
            [
              -67.374014,
              44.662965
            ],
            [
              -67.37905,
              44.665483
            ],
            [
              -67.381149,
              44.66947
            ],
            [
              -67.404998,
              44.681654
            ],
            [
              -67.396441,
              44.693313
            ],
            [
              -67.372673,
              44.691624
            ],
            [
              -67.355966,
              44.69906
            ],
            [
              -67.347782,
              44.69948
            ],
            [
              -67.308538,
              44.707454
            ],
            [
              -67.299176,
              44.705705
            ],
            [
              -67.300144,
              44.696752
            ],
            [
              -67.307909,
              44.691295
            ],
            [
              -67.309627,
              44.659316
            ],
            [
              -67.298449,
              44.654377
            ],
            [
              -67.292462,
              44.648455
            ],
            [
              -67.293665,
              44.634316
            ],
            [
              -67.310745,
              44.613212
            ],
            [
              -67.322491,
              44.612458
            ],
            [
              -67.32297,
              44.609394
            ],
            [
              -67.314938,
              44.598215
            ],
            [
              -67.302427,
              44.597323
            ],
            [
              -67.293403,
              44.599265
            ],
            [
              -67.261668,
              44.604598
            ],
            [
              -67.275141,
              44.623433
            ],
            [
              -67.246373,
              44.626384
            ],
            [
              -67.234275,
              44.637201
            ],
            [
              -67.213025,
              44.63922
            ],
            [
              -67.189427,
              44.645533
            ],
            [
              -67.191438,
              44.64775
            ],
            [
              -67.192068,
              44.655515
            ],
            [
              -67.186612,
              44.66265
            ],
            [
              -67.181785,
              44.663699
            ],
            [
              -67.169857,
              44.662105
            ],
            [
              -67.154479,
              44.668114
            ],
            [
              -67.155119,
              44.66944
            ],
            [
              -67.148061,
              44.684114
            ],
            [
              -67.139209,
              44.693849
            ],
            [
              -67.128792,
              44.695421
            ],
            [
              -67.103957,
              44.717444
            ],
            [
              -67.098931,
              44.741311
            ],
            [
              -67.092542,
              44.742693
            ],
            [
              -67.083477,
              44.739899
            ],
            [
              -67.073439,
              44.741957
            ],
            [
              -67.063308,
              44.758238
            ],
            [
              -67.062239,
              44.769543
            ],
            [
              -67.05516,
              44.771442
            ],
            [
              -67.04335,
              44.765071
            ],
            [
              -67.02615,
              44.768199
            ],
            [
              -67.01995,
              44.771427
            ],
            [
              -66.995154,
              44.791073
            ],
            [
              -66.989351,
              44.79878
            ],
            [
              -66.986207,
              44.801749
            ],
            [
              -66.980874,
              44.803673
            ],
            [
              -66.972933,
              44.805932
            ],
            [
              -66.970026,
              44.805713
            ],
            [
              -66.961068,
              44.807269
            ],
            [
              -66.950569,
              44.814539
            ],
            [
              -66.949895,
              44.817419
            ],
            [
              -66.952112,
              44.82007
            ],
            [
              -66.966468,
              44.819063
            ],
            [
              -66.975009,
              44.815495
            ],
            [
              -66.975035,
              44.812983
            ],
            [
              -66.982403,
              44.811466
            ],
            [
              -66.986318,
              44.820657
            ],
            [
              -66.996523,
              44.844654
            ],
            [
              -66.99296,
              44.849181
            ],
            [
              -66.978142,
              44.856963
            ],
            [
              -66.981008,
              44.862813
            ],
            [
              -66.990351,
              44.882551
            ],
            [
              -66.989235,
              44.89648
            ],
            [
              -66.985901,
              44.89715
            ],
            [
              -66.983558,
              44.903277
            ],
            [
              -66.984466,
              44.912557
            ],
            [
              -66.990937,
              44.917835
            ],
            [
              -67.002118,
              44.918836
            ],
            [
              -67.033474,
              44.939923
            ],
            [
              -67.038299,
              44.945433
            ],
            [
              -67.0392728062641,
              44.9479005609527
            ],
            [
              -67.05461,
              44.986764
            ],
            [
              -67.068274,
              45.001014
            ],
            [
              -67.072753,
              45.008329
            ],
            [
              -67.074914,
              45.019254
            ],
            [
              -67.082074,
              45.029608
            ],
            [
              -67.099749,
              45.04501
            ],
            [
              -67.117688,
              45.05673
            ],
            [
              -67.105899,
              45.065786
            ],
            [
              -67.090786,
              45.068721
            ],
            [
              -67.094735,
              45.075458
            ],
            [
              -67.112414,
              45.112323
            ],
            [
              -67.128935,
              45.132168
            ],
            [
              -67.145652,
              45.146667
            ],
            [
              -67.157919,
              45.161004
            ],
            [
              -67.159406,
              45.162193
            ],
            [
              -67.161247,
              45.162879
            ],
            [
              -67.16787,
              45.164595
            ],
            [
              -67.191167,
              45.165876
            ],
            [
              -67.203933,
              45.171407
            ],
            [
              -67.223156,
              45.1637
            ],
            [
              -67.227324,
              45.163652
            ],
            [
              -67.230201,
              45.165549
            ],
            [
              -67.233047,
              45.168587
            ],
            [
              -67.240643,
              45.171167
            ],
            [
              -67.242293,
              45.17224
            ],
            [
              -67.244012,
              45.178274
            ],
            [
              -67.246697,
              45.180765
            ],
            [
              -67.257795,
              45.185132
            ],
            [
              -67.261542,
              45.187785
            ],
            [
              -67.271076,
              45.191081
            ],
            [
              -67.283619,
              45.192022
            ],
            [
              -67.289794,
              45.188663
            ],
            [
              -67.290603,
              45.187589
            ],
            [
              -67.293742,
              45.177966
            ],
            [
              -67.293484,
              45.176114
            ],
            [
              -67.291707,
              45.174491
            ],
            [
              -67.291417,
              45.17145
            ],
            [
              -67.29591,
              45.1704
            ],
            [
              -67.299238,
              45.168937
            ],
            [
              -67.302568,
              45.161348
            ],
            [
              -67.301729,
              45.157119
            ],
            [
              -67.296979,
              45.155267
            ],
            [
              -67.294363,
              45.153506
            ],
            [
              -67.294881,
              45.149666
            ],
            [
              -67.296174,
              45.147952
            ],
            [
              -67.298209,
              45.146672
            ],
            [
              -67.305472,
              45.144826
            ],
            [
              -67.318462,
              45.139403
            ],
            [
              -67.329829,
              45.131654
            ],
            [
              -67.339869,
              45.125594
            ],
            [
              -67.340806,
              45.125435
            ],
            [
              -67.345585,
              45.126392
            ],
            [
              -67.353434,
              45.129475
            ],
            [
              -67.357117,
              45.131782
            ],
            [
              -67.383635,
              45.152259
            ],
            [
              -67.390579,
              45.154114
            ],
            [
              -67.404629,
              45.159926
            ],
            [
              -67.40537,
              45.162852
            ],
            [
              -67.40537,
              45.164864
            ],
            [
              -67.404658,
              45.166944
            ],
            [
              -67.407139,
              45.179425
            ],
            [
              -67.428889,
              45.193213
            ],
            [
              -67.429083,
              45.199066
            ],
            [
              -67.43141,
              45.210039
            ],
            [
              -67.437101,
              45.222658
            ],
            [
              -67.43998,
              45.227047
            ],
            [
              -67.453473,
              45.241127
            ],
            [
              -67.460236,
              45.241926
            ],
            [
              -67.462081,
              45.242748
            ],
            [
              -67.46357,
              45.244097
            ],
            [
              -67.4876700541052,
              45.2788817887243
            ],
            [
              -67.489333,
              45.281282
            ],
            [
              -67.489464,
              45.282653
            ],
            [
              -67.486524,
              45.290633
            ],
            [
              -67.485683,
              45.291433
            ],
            [
              -67.484258,
              45.291868
            ],
            [
              -67.482315,
              45.291412
            ],
            [
              -67.47969,
              45.289767
            ],
            [
              -67.466479,
              45.293817
            ],
            [
              -67.466091,
              45.29416
            ],
            [
              -67.465833,
              45.297223
            ],
            [
              -67.460554,
              45.300379
            ],
            [
              -67.453336,
              45.314256
            ],
            [
              -67.452267,
              45.316839
            ],
            [
              -67.452268,
              45.319628
            ],
            [
              -67.456384,
              45.323925
            ],
            [
              -67.456676,
              45.32564
            ],
            [
              -67.456288,
              45.32644
            ],
            [
              -67.453469,
              45.328246
            ],
            [
              -67.442029,
              45.334601
            ],
            [
              -67.434996,
              45.340133
            ],
            [
              -67.430489,
              45.348751
            ],
            [
              -67.427797,
              45.355471
            ],
            [
              -67.433536,
              45.361324
            ],
            [
              -67.434281,
              45.365438
            ],
            [
              -67.431232,
              45.370787
            ],
            [
              -67.430097,
              45.371862
            ],
            [
              -67.427243,
              45.37369
            ],
            [
              -67.423773,
              45.373461
            ],
            [
              -67.421501,
              45.374078
            ],
            [
              -67.419007,
              45.376026
            ],
            [
              -67.418747,
              45.37726
            ],
            [
              -67.430001,
              45.392965
            ],
            [
              -67.448106,
              45.407823
            ],
            [
              -67.458495,
              45.41596
            ],
            [
              -67.471255,
              45.423477
            ],
            [
              -67.473366,
              45.425328
            ],
            [
              -67.4772,
              45.43159
            ],
            [
              -67.484328,
              45.451955
            ],
            [
              -67.484851,
              45.456001
            ],
            [
              -67.481929,
              45.458288
            ],
            [
              -67.482353,
              45.460825
            ],
            [
              -67.486907,
              45.468024
            ],
            [
              -67.494351,
              45.473048
            ],
            [
              -67.499767,
              45.47805
            ],
            [
              -67.503157,
              45.485367
            ],
            [
              -67.503771,
              45.488522
            ],
            [
              -67.503088,
              45.489688
            ],
            [
              -67.499445,
              45.491013
            ],
            [
              -67.488001,
              45.493762
            ],
            [
              -67.476855,
              45.49724
            ],
            [
              -67.470732,
              45.500067
            ],
            [
              -67.47091,
              45.504279
            ],
            [
              -67.470416,
              45.505307
            ],
            [
              -67.462882,
              45.508691
            ],
            [
              -67.449968,
              45.504933
            ],
            [
              -67.429681,
              45.501592
            ],
            [
              -67.427713,
              45.501336
            ],
            [
              -67.425674,
              45.502917
            ],
            [
              -67.424242,
              45.505907
            ],
            [
              -67.422649,
              45.505863
            ],
            [
              -67.420966,
              45.505054
            ],
            [
              -67.417417,
              45.501985
            ],
            [
              -67.416462,
              45.502147
            ],
            [
              -67.416416,
              45.503515
            ],
            [
              -67.420543,
              45.511113
            ],
            [
              -67.432207,
              45.519996
            ],
            [
              -67.435044,
              45.528783
            ],
            [
              -67.435275,
              45.530781
            ],
            [
              -67.434559,
              45.535912
            ],
            [
              -67.432236,
              45.541023
            ],
            [
              -67.43067,
              45.54139
            ],
            [
              -67.429057,
              45.541207
            ],
            [
              -67.428338,
              45.540626
            ],
            [
              -67.425399,
              45.540795
            ],
            [
              -67.420976,
              45.550029
            ],
            [
              -67.423646,
              45.572153
            ],
            [
              -67.425452,
              45.579086
            ],
            [
              -67.429716,
              45.583773
            ],
            [
              -67.449674,
              45.60286
            ],
            [
              -67.455406,
              45.604665
            ],
            [
              -67.476704,
              45.604157
            ],
            [
              -67.489793,
              45.60118
            ],
            [
              -67.491061,
              45.598917
            ],
            [
              -67.489018,
              45.596944
            ],
            [
              -67.488452,
              45.594643
            ],
            [
              -67.490923,
              45.591488
            ],
            [
              -67.499444,
              45.587014
            ],
            [
              -67.51858,
              45.587925
            ],
            [
              -67.534919,
              45.595428
            ],
            [
              -67.54612,
              45.598059
            ],
            [
              -67.556345,
              45.59726
            ],
            [
              -67.556931,
              45.595066
            ],
            [
              -67.561359,
              45.594906
            ],
            [
              -67.583341,
              45.602493
            ],
            [
              -67.606172,
              45.606533
            ],
            [
              -67.631762,
              45.621409
            ],
            [
              -67.639741,
              45.624771
            ],
            [
              -67.644206,
              45.62322
            ],
            [
              -67.640238,
              45.616178
            ],
            [
              -67.64581,
              45.613597
            ],
            [
              -67.666456,
              45.624461
            ],
            [
              -67.675417,
              45.630959
            ],
            [
              -67.692623,
              45.650366
            ],
            [
              -67.69797,
              45.659738
            ],
            [
              -67.706092,
              45.673635
            ],
            [
              -67.710464,
              45.679372
            ],
            [
              -67.727648,
              45.688468
            ],
            [
              -67.729908,
              45.689012
            ],
            [
              -67.734605,
              45.688987
            ],
            [
              -67.73372,
              45.684233
            ],
            [
              -67.730845,
              45.678223
            ],
            [
              -67.7236,
              45.670384
            ],
            [
              -67.718056,
              45.667986
            ],
            [
              -67.71799,
              45.665243
            ],
            [
              -67.719651,
              45.662819
            ],
            [
              -67.720401,
              45.662522
            ],
            [
              -67.73035,
              45.663239
            ],
            [
              -67.754245,
              45.667791
            ],
            [
              -67.768648,
              45.677581
            ],
            [
              -67.803313,
              45.677886
            ],
            [
              -67.8054831422691,
              45.6802407212124
            ],
            [
              -67.873581,
              45.669296
            ],
            [
              -67.902264,
              45.680115
            ],
            [
              -67.896955,
              45.665302
            ],
            [
              -68.048012,
              45.639897
            ],
            [
              -67.957928,
              45.353247
            ],
            [
              -67.966881,
              45.351879
            ],
            [
              -67.93976,
              45.267845
            ],
            [
              -68.054985,
              45.252577
            ],
            [
              -68.024248,
              45.143161
            ],
            [
              -67.998356,
              45.023225
            ],
            [
              -67.981851,
              44.955885
            ],
            [
              -68.091771,
              44.940973
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "23",
      "COUNTYFP": "031",
      "COUNTYNS": "00581301",
      "AFFGEOID": "0500000US23031",
      "GEOID": "23031",
      "NAME": "York",
      "LSAD": "06",
      "ALAND": 2566235154,
      "AWATER": 722302593,
      "County_state": "York,23"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -70.9890367618669,
              43.7921536087332
            ],
            [
              -70.9890301198621,
              43.792090701007496
            ],
            [
              -70.984901,
              43.752983
            ],
            [
              -70.982083,
              43.715043
            ],
            [
              -70.982238,
              43.711865
            ],
            [
              -70.981978,
              43.701965
            ],
            [
              -70.981946,
              43.70096
            ],
            [
              -70.975705,
              43.625078
            ],
            [
              -70.975123330997,
              43.614409268065195
            ],
            [
              -70.972716,
              43.570255
            ],
            [
              -70.970124,
              43.568544
            ],
            [
              -70.968546,
              43.568856
            ],
            [
              -70.961848,
              43.562964
            ],
            [
              -70.957234,
              43.561358
            ],
            [
              -70.95586,
              43.559787
            ],
            [
              -70.955017,
              43.554239
            ],
            [
              -70.953322,
              43.552718
            ],
            [
              -70.951876,
              43.552238
            ],
            [
              -70.950838,
              43.551026
            ],
            [
              -70.955252,
              43.540887
            ],
            [
              -70.955337,
              43.54098
            ],
            [
              -70.955928,
              43.541925
            ],
            [
              -70.96214686301958,
              43.5410370171608
            ],
            [
              -70.962182,
              43.541032
            ],
            [
              -70.963281,
              43.538929
            ],
            [
              -70.963531,
              43.536756
            ],
            [
              -70.963342,
              43.535247
            ],
            [
              -70.962556,
              43.53431
            ],
            [
              -70.95822,
              43.531586
            ],
            [
              -70.957046,
              43.528743
            ],
            [
              -70.957214,
              43.524994
            ],
            [
              -70.956787,
              43.524216
            ],
            [
              -70.954066,
              43.52261
            ],
            [
              -70.954045,
              43.518797
            ],
            [
              -70.956856,
              43.512719
            ],
            [
              -70.955386,
              43.511357
            ],
            [
              -70.954755,
              43.509802
            ],
            [
              -70.957958,
              43.508041
            ],
            [
              -70.959185,
              43.499351
            ],
            [
              -70.961948,
              43.49775
            ],
            [
              -70.966718,
              43.491278
            ],
            [
              -70.969572,
              43.486201
            ],
            [
              -70.968975,
              43.483961
            ],
            [
              -70.967404,
              43.482635
            ],
            [
              -70.967968,
              43.480783
            ],
            [
              -70.974245,
              43.47742
            ],
            [
              -70.972956,
              43.47502
            ],
            [
              -70.970946,
              43.4739
            ],
            [
              -70.966017,
              43.472918
            ],
            [
              -70.964542,
              43.473262
            ],
            [
              -70.964433,
              43.473276
            ],
            [
              -70.961428,
              43.469696
            ],
            [
              -70.96045,
              43.466592
            ],
            [
              -70.96399,
              43.456752
            ],
            [
              -70.966266,
              43.453627
            ],
            [
              -70.9669,
              43.450458
            ],
            [
              -70.96581,
              43.447557
            ],
            [
              -70.96164,
              43.443039
            ],
            [
              -70.961046,
              43.440475
            ],
            [
              -70.96115,
              43.438321
            ],
            [
              -70.968782,
              43.434891
            ],
            [
              -70.969362,
              43.432731
            ],
            [
              -70.968359,
              43.429283
            ],
            [
              -70.971039,
              43.425606
            ],
            [
              -70.97462,
              43.423022
            ],
            [
              -70.978052,
              43.421954
            ],
            [
              -70.982898,
              43.419332
            ],
            [
              -70.986812,
              43.414264
            ],
            [
              -70.987249,
              43.411863
            ],
            [
              -70.986677,
              43.403541
            ],
            [
              -70.985767,
              43.40162
            ],
            [
              -70.982817,
              43.399312
            ],
            [
              -70.982565,
              43.39778
            ],
            [
              -70.982876,
              43.394808
            ],
            [
              -70.98739,
              43.393457
            ],
            [
              -70.987733,
              43.391513
            ],
            [
              -70.987649,
              43.389521
            ],
            [
              -70.985205,
              43.386745
            ],
            [
              -70.985958,
              43.38424
            ],
            [
              -70.985965,
              43.380023
            ],
            [
              -70.9855356237213,
              43.3791929587481
            ],
            [
              -70.984305,
              43.376814
            ],
            [
              -70.984335,
              43.376128
            ],
            [
              -70.981859,
              43.373862
            ],
            [
              -70.976408,
              43.367272
            ],
            [
              -70.974156,
              43.362925
            ],
            [
              -70.974863,
              43.357969
            ],
            [
              -70.97329039172,
              43.3550454400433
            ],
            [
              -70.967229,
              43.343777
            ],
            [
              -70.964028,
              43.341888
            ],
            [
              -70.960439,
              43.341048
            ],
            [
              -70.95786,
              43.337776
            ],
            [
              -70.956528,
              43.334691
            ],
            [
              -70.953034,
              43.333257
            ],
            [
              -70.951525,
              43.334672
            ],
            [
              -70.93711,
              43.337367
            ],
            [
              -70.932735,
              43.33676
            ],
            [
              -70.932198,
              43.33456
            ],
            [
              -70.931641,
              43.331019
            ],
            [
              -70.930783,
              43.329569
            ],
            [
              -70.923949,
              43.324768
            ],
            [
              -70.92359415228529,
              43.3245564017812
            ],
            [
              -70.916421,
              43.320279
            ],
            [
              -70.915406,
              43.319858
            ],
            [
              -70.912004,
              43.319821
            ],
            [
              -70.911625,
              43.309952
            ],
            [
              -70.91246,
              43.308289
            ],
            [
              -70.909805,
              43.306682
            ],
            [
              -70.907405,
              43.304782
            ],
            [
              -70.904485,
              43.30529
            ],
            [
              -70.90231,
              43.304872
            ],
            [
              -70.900386,
              43.301358
            ],
            [
              -70.90139,
              43.298764
            ],
            [
              -70.903905,
              43.296882
            ],
            [
              -70.907405,
              43.293582
            ],
            [
              -70.906005,
              43.291682
            ],
            [
              -70.89882057483361,
              43.286942249349
            ],
            [
              -70.896304,
              43.285282
            ],
            [
              -70.890204,
              43.284182
            ],
            [
              -70.886504,
              43.282783
            ],
            [
              -70.883704,
              43.277483
            ],
            [
              -70.882804,
              43.273183
            ],
            [
              -70.881704,
              43.272483
            ],
            [
              -70.872585,
              43.270152
            ],
            [
              -70.8637496134913,
              43.2653891080531
            ],
            [
              -70.86323,
              43.265109
            ],
            [
              -70.863231,
              43.265098
            ],
            [
              -70.861384,
              43.263143
            ],
            [
              -70.859853,
              43.259763
            ],
            [
              -70.859607,
              43.257342
            ],
            [
              -70.858207,
              43.256286
            ],
            [
              -70.855082,
              43.255191
            ],
            [
              -70.852015,
              43.256808
            ],
            [
              -70.843302,
              43.254321
            ],
            [
              -70.839213,
              43.251224
            ],
            [
              -70.839717,
              43.250393
            ],
            [
              -70.841059,
              43.249699
            ],
            [
              -70.841273,
              43.248653
            ],
            [
              -70.838678,
              43.242931
            ],
            [
              -70.837274,
              43.242321
            ],
            [
              -70.83365,
              43.242868
            ],
            [
              -70.828022,
              43.241597
            ],
            [
              -70.826711,
              43.241301
            ],
            [
              -70.825071,
              43.24093
            ],
            [
              -70.823309,
              43.240343
            ],
            [
              -70.822959,
              43.240187
            ],
            [
              -70.817865,
              43.237911
            ],
            [
              -70.8178247635243,
              43.2376910114428
            ],
            [
              -70.817773,
              43.237408
            ],
            [
              -70.816232,
              43.234997
            ],
            [
              -70.815453,
              43.229023
            ],
            [
              -70.814019,
              43.229053
            ],
            [
              -70.811852,
              43.228306
            ],
            [
              -70.8111675533474,
              43.2274089788219
            ],
            [
              -70.80964,
              43.225407
            ],
            [
              -70.80967,
              43.224561
            ],
            [
              -70.813119,
              43.217252
            ],
            [
              -70.816033,
              43.21568
            ],
            [
              -70.816903,
              43.214604
            ],
            [
              -70.820763,
              43.19978
            ],
            [
              -70.820366,
              43.197629
            ],
            [
              -70.8199772172799,
              43.1968412369802
            ],
            [
              -70.819146,
              43.195157
            ],
            [
              -70.819344,
              43.193036
            ],
            [
              -70.820702,
              43.191663
            ],
            [
              -70.823754,
              43.191075
            ],
            [
              -70.827201,
              43.189485
            ],
            [
              -70.827901,
              43.188685
            ],
            [
              -70.828301,
              43.186685
            ],
            [
              -70.824801,
              43.179685
            ],
            [
              -70.823501,
              43.174585
            ],
            [
              -70.824001,
              43.173285
            ],
            [
              -70.8250151719745,
              43.172731815286596
            ],
            [
              -70.826201,
              43.172085
            ],
            [
              -70.828301,
              43.168985
            ],
            [
              -70.827801,
              43.166385
            ],
            [
              -70.829101,
              43.157886
            ],
            [
              -70.8338,
              43.146886
            ],
            [
              -70.8329,
              43.143286
            ],
            [
              -70.83,
              43.138386
            ],
            [
              -70.8293,
              43.132486
            ],
            [
              -70.8281,
              43.129086
            ],
            [
              -70.8268,
              43.127086
            ],
            [
              -70.82,
              43.122586
            ],
            [
              -70.8199991556947,
              43.1225855871936
            ],
            [
              -70.808708,
              43.117065
            ],
            [
              -70.793906,
              43.106712
            ],
            [
              -70.78388,
              43.100867
            ],
            [
              -70.7826969713611,
              43.0996349877412
            ],
            [
              -70.779098,
              43.095887
            ],
            [
              -70.767998,
              43.093588
            ],
            [
              -70.76784146587539,
              43.093499949554904
            ],
            [
              -70.766398,
              43.092688
            ],
            [
              -70.762997,
              43.089488
            ],
            [
              -70.757597,
              43.080888
            ],
            [
              -70.756397,
              43.079988
            ],
            [
              -70.7526445792199,
              43.079315152136004
            ],
            [
              -70.741897,
              43.077388
            ],
            [
              -70.737897,
              43.073488
            ],
            [
              -70.73779383920369,
              43.073483310872895
            ],
            [
              -70.733497,
              43.073288
            ],
            [
              -70.720296,
              43.074688
            ],
            [
              -70.708896,
              43.074989
            ],
            [
              -70.705996,
              43.073089
            ],
            [
              -70.704696,
              43.070989
            ],
            [
              -70.703818,
              43.059825
            ],
            [
              -70.673114,
              43.070314
            ],
            [
              -70.665958,
              43.076234
            ],
            [
              -70.656223,
              43.093164
            ],
            [
              -70.655322,
              43.098008
            ],
            [
              -70.654212952007,
              43.0990652135462
            ],
            [
              -70.638355,
              43.114182
            ],
            [
              -70.634311,
              43.122162
            ],
            [
              -70.634455,
              43.127603
            ],
            [
              -70.62251,
              43.134573
            ],
            [
              -70.623579,
              43.152907
            ],
            [
              -70.611105,
              43.168431
            ],
            [
              -70.596185,
              43.163466
            ],
            [
              -70.590423,
              43.166563
            ],
            [
              -70.603135,
              43.182045
            ],
            [
              -70.587814,
              43.199858
            ],
            [
              -70.576692,
              43.217651
            ],
            [
              -70.575787,
              43.221859
            ],
            [
              -70.5839645078829,
              43.2304428336767
            ],
            [
              -70.591022,
              43.237851
            ],
            [
              -70.593907,
              43.249295
            ],
            [
              -70.5862558097959,
              43.267555057052505
            ],
            [
              -70.585184,
              43.270113
            ],
            [
              -70.570041,
              43.285566
            ],
            [
              -70.562779,
              43.310614
            ],
            [
              -70.553854,
              43.321886
            ],
            [
              -70.5367023241991,
              43.3356045757279
            ],
            [
              -70.535244,
              43.336771
            ],
            [
              -70.517695,
              43.344037
            ],
            [
              -70.5153787662558,
              43.3434866064234
            ],
            [
              -70.501312,
              43.340144
            ],
            [
              -70.485312,
              43.346391
            ],
            [
              -70.4757328738117,
              43.3445191277769
            ],
            [
              -70.472933,
              43.343972
            ],
            [
              -70.465975,
              43.340246
            ],
            [
              -70.460717,
              43.34325
            ],
            [
              -70.442438,
              43.356487
            ],
            [
              -70.428534,
              43.354818
            ],
            [
              -70.416311,
              43.361059
            ],
            [
              -70.418461,
              43.372538
            ],
            [
              -70.424421,
              43.379656
            ],
            [
              -70.427672,
              43.389254
            ],
            [
              -70.421282,
              43.395777
            ],
            [
              -70.406416,
              43.400942
            ],
            [
              -70.401666,
              43.401262
            ],
            [
              -70.400035,
              43.399927
            ],
            [
              -70.39089,
              43.402607
            ],
            [
              -70.383981,
              43.41294
            ],
            [
              -70.384949,
              43.418839
            ],
            [
              -70.370514,
              43.434133
            ],
            [
              -70.362015,
              43.439077
            ],
            [
              -70.343813,
              43.440114
            ],
            [
              -70.332836,
              43.446432
            ],
            [
              -70.327303,
              43.458521
            ],
            [
              -70.357549,
              43.457833
            ],
            [
              -70.367364,
              43.452264
            ],
            [
              -70.37697357394849,
              43.4611992833839
            ],
            [
              -70.380233,
              43.46423
            ],
            [
              -70.382928,
              43.469674
            ],
            [
              -70.385615,
              43.487031
            ],
            [
              -70.384885,
              43.49604
            ],
            [
              -70.3845266011234,
              43.4967342811976
            ],
            [
              -70.379123,
              43.507202
            ],
            [
              -70.361214,
              43.52919
            ],
            [
              -70.35339193799001,
              43.535405312744004
            ],
            [
              -70.39009,
              43.55371
            ],
            [
              -70.390653,
              43.553997
            ],
            [
              -70.492009,
              43.606769
            ],
            [
              -70.468462,
              43.631319
            ],
            [
              -70.457743,
              43.642372
            ],
            [
              -70.490749,
              43.668795
            ],
            [
              -70.546605,
              43.71316
            ],
            [
              -70.577401,
              43.687185
            ],
            [
              -70.598173,
              43.694581
            ],
            [
              -70.612074,
              43.687099
            ],
            [
              -70.614493,
              43.698331
            ],
            [
              -70.633528,
              43.703913
            ],
            [
              -70.657371,
              43.701544
            ],
            [
              -70.652359,
              43.733521
            ],
            [
              -70.644023,
              43.755696
            ],
            [
              -70.653533,
              43.787738
            ],
            [
              -70.668199,
              43.792531
            ],
            [
              -70.695153,
              43.805203
            ],
            [
              -70.733234,
              43.789551
            ],
            [
              -70.766073,
              43.792416
            ],
            [
              -70.780335,
              43.803516
            ],
            [
              -70.782984,
              43.814147
            ],
            [
              -70.796924,
              43.806295
            ],
            [
              -70.841207,
              43.809894
            ],
            [
              -70.888577,
              43.80707
            ],
            [
              -70.927071,
              43.790299
            ],
            [
              -70.957548,
              43.785123
            ],
            [
              -70.9890367618669,
              43.7921536087332
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "23",
      "COUNTYFP": "009",
      "COUNTYNS": "00581290",
      "AFFGEOID": "0500000US23009",
      "GEOID": "23009",
      "NAME": "Hancock",
      "LSAD": "06",
      "ALAND": 4110284976,
      "AWATER": 1963056852,
      "County_state": "Hancock,23"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -68.376591,
              44.113762
            ],
            [
              -68.376593,
              44.112207
            ],
            [
              -68.365176,
              44.101464
            ],
            [
              -68.338012,
              44.101473
            ],
            [
              -68.331032,
              44.10758
            ],
            [
              -68.330716,
              44.110598
            ],
            [
              -68.346724,
              44.127749
            ],
            [
              -68.35301,
              44.127884
            ],
            [
              -68.358388,
              44.125082
            ],
            [
              -68.365514,
              44.124079
            ],
            [
              -68.375382,
              44.11646
            ],
            [
              -68.376591,
              44.113762
            ]
          ]
        ],
        [
          [
            [
              -68.371235,
              44.193003
            ],
            [
              -68.378872,
              44.184222
            ],
            [
              -68.347416,
              44.169459
            ],
            [
              -68.339029,
              44.171839
            ],
            [
              -68.332639,
              44.192131
            ],
            [
              -68.321178,
              44.199032
            ],
            [
              -68.318476,
              44.196608
            ],
            [
              -68.314789,
              44.197157
            ],
            [
              -68.31606,
              44.200244
            ],
            [
              -68.32423,
              44.205732
            ],
            [
              -68.333227,
              44.207308
            ],
            [
              -68.355279,
              44.199096
            ],
            [
              -68.364469,
              44.197534
            ],
            [
              -68.371235,
              44.193003
            ]
          ]
        ],
        [
          [
            [
              -68.500817,
              44.160026
            ],
            [
              -68.502096,
              44.152388
            ],
            [
              -68.496639,
              44.146855
            ],
            [
              -68.484696,
              44.146495
            ],
            [
              -68.479934,
              44.1478
            ],
            [
              -68.456813,
              44.145268
            ],
            [
              -68.447505,
              44.133493
            ],
            [
              -68.448646,
              44.125581
            ],
            [
              -68.438518,
              44.11618
            ],
            [
              -68.427534,
              44.119266
            ],
            [
              -68.396634,
              44.14069
            ],
            [
              -68.384903,
              44.154955
            ],
            [
              -68.408207,
              44.176298
            ],
            [
              -68.416434,
              44.187047
            ],
            [
              -68.424441,
              44.190753
            ],
            [
              -68.453843,
              44.201683
            ],
            [
              -68.467645,
              44.224693
            ],
            [
              -68.475963,
              44.231165
            ],
            [
              -68.483569,
              44.224522
            ],
            [
              -68.486118,
              44.213428
            ],
            [
              -68.4907,
              44.202717
            ],
            [
              -68.495511,
              44.162429
            ],
            [
              -68.500817,
              44.160026
            ]
          ]
        ],
        [
          [
            [
              -68.518703,
              44.113222
            ],
            [
              -68.51706,
              44.10341
            ],
            [
              -68.502942,
              44.099722
            ],
            [
              -68.491521,
              44.109833
            ],
            [
              -68.492892,
              44.116921
            ],
            [
              -68.499465,
              44.12419
            ],
            [
              -68.506979,
              44.127237
            ],
            [
              -68.511266,
              44.125082
            ],
            [
              -68.518703,
              44.113222
            ]
          ]
        ],
        [
          [
            [
              -68.528635,
              44.344605
            ],
            [
              -68.530394,
              44.333583
            ],
            [
              -68.515173,
              44.324797
            ],
            [
              -68.489641,
              44.313705
            ],
            [
              -68.478785,
              44.319563
            ],
            [
              -68.498637,
              44.369686
            ],
            [
              -68.501364,
              44.382281
            ],
            [
              -68.50766,
              44.385219
            ],
            [
              -68.512697,
              44.384799
            ],
            [
              -68.518573,
              44.381022
            ],
            [
              -68.52193,
              44.367591
            ],
            [
              -68.520671,
              44.358357
            ],
            [
              -68.528635,
              44.344605
            ]
          ]
        ],
        [
          [
            [
              -68.839422,
              44.236547
            ],
            [
              -68.837849,
              44.227112
            ],
            [
              -68.829593,
              44.21689
            ],
            [
              -68.822909,
              44.216496
            ],
            [
              -68.815439,
              44.214137
            ],
            [
              -68.809149,
              44.212565
            ],
            [
              -68.801285,
              44.208633
            ],
            [
              -68.789884,
              44.203915
            ],
            [
              -68.780055,
              44.203129
            ],
            [
              -68.769047,
              44.213351
            ],
            [
              -68.769833,
              44.222787
            ],
            [
              -68.792139,
              44.237819
            ],
            [
              -68.825631,
              44.242556
            ],
            [
              -68.827627,
              44.242838
            ],
            [
              -68.833524,
              44.240872
            ],
            [
              -68.839422,
              44.236547
            ]
          ]
        ],
        [
          [
            [
              -68.8475,
              44.619839
            ],
            [
              -68.841821,
              44.611062
            ],
            [
              -68.810851,
              44.573114
            ],
            [
              -68.798272,
              44.568096
            ],
            [
              -68.806089,
              44.553634
            ],
            [
              -68.8023408031601,
              44.52491971417329
            ],
            [
              -68.797306,
              44.525817
            ],
            [
              -68.78566,
              44.51209
            ],
            [
              -68.778728,
              44.489886
            ],
            [
              -68.783679,
              44.473879
            ],
            [
              -68.785898,
              44.462611
            ],
            [
              -68.7832860278211,
              44.447156914512
            ],
            [
              -68.783283,
              44.447139
            ],
            [
              -68.799514,
              44.448921
            ],
            [
              -68.801634,
              44.434803
            ],
            [
              -68.815325,
              44.42808
            ],
            [
              -68.821767,
              44.40894
            ],
            [
              -68.81877325183629,
              44.3758534157745
            ],
            [
              -68.818703,
              44.375077
            ],
            [
              -68.814811,
              44.362194
            ],
            [
              -68.816852991741,
              44.355641049776004
            ],
            [
              -68.817647,
              44.353093
            ],
            [
              -68.821311,
              44.349594
            ],
            [
              -68.825419,
              44.334547
            ],
            [
              -68.828377,
              44.316549
            ],
            [
              -68.827197,
              44.31216
            ],
            [
              -68.795063,
              44.30786
            ],
            [
              -68.771489,
              44.320523
            ],
            [
              -68.766197,
              44.327015
            ],
            [
              -68.762021,
              44.329597
            ],
            [
              -68.746164,
              44.331148
            ],
            [
              -68.725657,
              44.321591
            ],
            [
              -68.7336145983279,
              44.3160677287594
            ],
            [
              -68.742165,
              44.310133
            ],
            [
              -68.748984,
              44.299407
            ],
            [
              -68.72456,
              44.28166
            ],
            [
              -68.700242,
              44.270999
            ],
            [
              -68.678704,
              44.264705
            ],
            [
              -68.69731,
              44.247076
            ],
            [
              -68.71123,
              44.231795
            ],
            [
              -68.733774,
              44.221006
            ],
            [
              -68.731666,
              44.202246
            ],
            [
              -68.717829,
              44.18709
            ],
            [
              -68.718531,
              44.165634
            ],
            [
              -68.681899,
              44.138212
            ],
            [
              -68.657635,
              44.127062
            ],
            [
              -68.65811796527919,
              44.1200404599176
            ],
            [
              -68.651208,
              44.120137
            ],
            [
              -68.645914,
              44.11923
            ],
            [
              -68.634017,
              44.115051
            ],
            [
              -68.631382,
              44.114335
            ],
            [
              -68.6215549812924,
              44.11287522215039
            ],
            [
              -68.623099,
              44.119406
            ],
            [
              -68.610888,
              44.124674
            ],
            [
              -68.605499,
              44.135461
            ],
            [
              -68.578879,
              44.146036
            ],
            [
              -68.611743,
              44.155246
            ],
            [
              -68.598634,
              44.174602
            ],
            [
              -68.579445,
              44.185263
            ],
            [
              -68.564143,
              44.191641
            ],
            [
              -68.564618,
              44.211917
            ],
            [
              -68.55272734435549,
              44.21521718844679
            ],
            [
              -68.529103,
              44.221774
            ],
            [
              -68.525302,
              44.227554
            ],
            [
              -68.52348,
              44.235819
            ],
            [
              -68.528153,
              44.241263
            ],
            [
              -68.529802,
              44.249594
            ],
            [
              -68.519819,
              44.260209
            ],
            [
              -68.519516,
              44.265046
            ],
            [
              -68.528611,
              44.276117
            ],
            [
              -68.532266,
              44.28634
            ],
            [
              -68.531532,
              44.290388
            ],
            [
              -68.538595,
              44.299902
            ],
            [
              -68.556236,
              44.300819
            ],
            [
              -68.564005,
              44.308022
            ],
            [
              -68.566203,
              44.313007
            ],
            [
              -68.566936,
              44.317603
            ],
            [
              -68.563209,
              44.333039
            ],
            [
              -68.553873,
              44.346256
            ],
            [
              -68.545434,
              44.355
            ],
            [
              -68.550051,
              44.371788
            ],
            [
              -68.559285,
              44.374307
            ],
            [
              -68.564741,
              44.385219
            ],
            [
              -68.56642,
              44.394453
            ],
            [
              -68.565161,
              44.39907
            ],
            [
              -68.560964,
              44.402847
            ],
            [
              -68.555088,
              44.403687
            ],
            [
              -68.534522,
              44.397811
            ],
            [
              -68.529905,
              44.39907
            ],
            [
              -68.51452,
              44.41334
            ],
            [
              -68.505562,
              44.411661
            ],
            [
              -68.499686,
              44.414179
            ],
            [
              -68.494649,
              44.429709
            ],
            [
              -68.485415,
              44.434326
            ],
            [
              -68.480379,
              44.432647
            ],
            [
              -68.472824,
              44.404106
            ],
            [
              -68.480798,
              44.397391
            ],
            [
              -68.483317,
              44.388157
            ],
            [
              -68.47828,
              44.378084
            ],
            [
              -68.466109,
              44.377245
            ],
            [
              -68.461072,
              44.378504
            ],
            [
              -68.461072,
              44.385639
            ],
            [
              -68.464262,
              44.391081
            ],
            [
              -68.464106,
              44.398078
            ],
            [
              -68.458849,
              44.412141
            ],
            [
              -68.46382,
              44.436592
            ],
            [
              -68.460003,
              44.443317
            ],
            [
              -68.455095,
              44.447498
            ],
            [
              -68.448006,
              44.449497
            ],
            [
              -68.4428259617749,
              44.4486337592459
            ],
            [
              -68.439281,
              44.448043
            ],
            [
              -68.429648,
              44.439136
            ],
            [
              -68.432556,
              44.426594
            ],
            [
              -68.433901,
              44.401534
            ],
            [
              -68.427874,
              44.3968
            ],
            [
              -68.421783,
              44.396411
            ],
            [
              -68.416412,
              44.397973
            ],
            [
              -68.392559,
              44.41807
            ],
            [
              -68.390932,
              44.427387
            ],
            [
              -68.387678,
              44.430936
            ],
            [
              -68.3791,
              44.430049
            ],
            [
              -68.372445,
              44.42369
            ],
            [
              -68.37121454200759,
              44.4217707875524
            ],
            [
              -68.359082,
              44.402847
            ],
            [
              -68.3581,
              44.392337
            ],
            [
              -68.360318,
              44.389674
            ],
            [
              -68.36372,
              44.388935
            ],
            [
              -68.367565,
              44.39071
            ],
            [
              -68.398035,
              44.376191
            ],
            [
              -68.422227,
              44.373795
            ],
            [
              -68.4354,
              44.369718
            ],
            [
              -68.446717,
              44.360113
            ],
            [
              -68.456879,
              44.344288
            ],
            [
              -68.452122,
              44.336171
            ],
            [
              -68.445724,
              44.324921
            ],
            [
              -68.4358753237777,
              44.3167670828694
            ],
            [
              -68.430853,
              44.312609
            ],
            [
              -68.430946,
              44.298624
            ],
            [
              -68.426107,
              44.295102
            ],
            [
              -68.421302,
              44.284468
            ],
            [
              -68.41965,
              44.274612
            ],
            [
              -68.401268,
              44.252244
            ],
            [
              -68.389848,
              44.247066
            ],
            [
              -68.377982,
              44.247563
            ],
            [
              -68.369759,
              44.243311
            ],
            [
              -68.365364,
              44.237871
            ],
            [
              -68.343132,
              44.229505
            ],
            [
              -68.339498,
              44.222893
            ],
            [
              -68.317588,
              44.225101
            ],
            [
              -68.297704,
              44.242574
            ],
            [
              -68.274427,
              44.237099
            ],
            [
              -68.266669,
              44.234033
            ],
            [
              -68.248913,
              44.235443
            ],
            [
              -68.198985,
              44.236698
            ],
            [
              -68.193068,
              44.23901
            ],
            [
              -68.207122,
              44.263516
            ],
            [
              -68.22949,
              44.266918
            ],
            [
              -68.2251613937005,
              44.2862166801796
            ],
            [
              -68.224261,
              44.290231
            ],
            [
              -68.191924,
              44.306675
            ],
            [
              -68.173608,
              44.328397
            ],
            [
              -68.174687,
              44.343604
            ],
            [
              -68.182667,
              44.356885
            ],
            [
              -68.189155,
              44.373833
            ],
            [
              -68.1854685827976,
              44.3796823268927
            ],
            [
              -68.181954,
              44.385259
            ],
            [
              -68.149154,
              44.386957
            ],
            [
              -68.140549,
              44.376804
            ],
            [
              -68.125624,
              44.387127
            ],
            [
              -68.105658,
              44.395619
            ],
            [
              -68.10138,
              44.379653
            ],
            [
              -68.103757,
              44.364362
            ],
            [
              -68.103282,
              44.358245
            ],
            [
              -68.094725,
              44.351447
            ],
            [
              -68.076066,
              44.347925
            ],
            [
              -68.067047,
              44.335692
            ],
            [
              -68.060356,
              44.331988
            ],
            [
              -68.049334,
              44.33073
            ],
            [
              -68.043037,
              44.343667
            ],
            [
              -68.044716,
              44.351222
            ],
            [
              -68.044296,
              44.357938
            ],
            [
              -68.039679,
              44.360876
            ],
            [
              -68.034223,
              44.360456
            ],
            [
              -68.01399,
              44.390255
            ],
            [
              -68.019533,
              44.396971
            ],
            [
              -68.010719,
              44.407464
            ],
            [
              -68.006102,
              44.409562
            ],
            [
              -68.000646,
              44.406624
            ],
            [
              -67.997288,
              44.399909
            ],
            [
              -67.985668,
              44.386917
            ],
            [
              -67.978876,
              44.387034
            ],
            [
              -67.961613,
              44.39907
            ],
            [
              -67.961613,
              44.4125
            ],
            [
              -67.95708933421129,
              44.4154085107811
            ],
            [
              -67.981988,
              44.473756
            ],
            [
              -67.972968,
              44.488633
            ],
            [
              -67.991395,
              44.50281
            ],
            [
              -68.010718,
              44.568883
            ],
            [
              -68.017219,
              44.589087
            ],
            [
              -67.993551,
              44.592162
            ],
            [
              -67.998037,
              44.608772
            ],
            [
              -68.016062,
              44.676676
            ],
            [
              -68.034637,
              44.674142
            ],
            [
              -68.105017,
              44.939088
            ],
            [
              -68.091771,
              44.940973
            ],
            [
              -67.981851,
              44.955885
            ],
            [
              -67.998356,
              45.023225
            ],
            [
              -68.024248,
              45.143161
            ],
            [
              -68.054985,
              45.252577
            ],
            [
              -68.303714,
              45.221531
            ],
            [
              -68.295438,
              45.190443
            ],
            [
              -68.270497,
              45.100121
            ],
            [
              -68.396903,
              45.083296
            ],
            [
              -68.375892,
              44.997373
            ],
            [
              -68.501095,
              44.980718
            ],
            [
              -68.491837,
              44.944026
            ],
            [
              -68.477952,
              44.889091
            ],
            [
              -68.473014,
              44.870763
            ],
            [
              -68.454889,
              44.80155
            ],
            [
              -68.445219,
              44.763014
            ],
            [
              -68.528542,
              44.752167
            ],
            [
              -68.616673,
              44.740992
            ],
            [
              -68.647134,
              44.703636
            ],
            [
              -68.69093,
              44.721668
            ],
            [
              -68.713867,
              44.703624
            ],
            [
              -68.720777,
              44.699667
            ],
            [
              -68.743953,
              44.660579
            ],
            [
              -68.771901,
              44.664448
            ],
            [
              -68.766336,
              44.675706
            ],
            [
              -68.797799,
              44.688828
            ],
            [
              -68.815793,
              44.683754
            ],
            [
              -68.8475,
              44.619839
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "23",
      "COUNTYFP": "027",
      "COUNTYNS": "00581299",
      "AFFGEOID": "0500000US23027",
      "GEOID": "23027",
      "NAME": "Waldo",
      "LSAD": "06",
      "ALAND": 1890577548,
      "AWATER": 318053597,
      "County_state": "Waldo,23"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -68.967074,
              44.251968
            ],
            [
              -68.965896,
              44.249754
            ],
            [
              -68.959468,
              44.247439
            ],
            [
              -68.955332,
              44.243873
            ],
            [
              -68.94709,
              44.226792
            ],
            [
              -68.95189,
              44.218719
            ],
            [
              -68.945976,
              44.220824
            ],
            [
              -68.92648,
              44.233035
            ],
            [
              -68.916872,
              44.242866
            ],
            [
              -68.904255,
              44.279889
            ],
            [
              -68.899445,
              44.283775
            ],
            [
              -68.88746,
              44.303094
            ],
            [
              -68.896587,
              44.321986
            ],
            [
              -68.89285,
              44.334653
            ],
            [
              -68.888706,
              44.338196
            ],
            [
              -68.883065,
              44.338193
            ],
            [
              -68.87169,
              44.344662
            ],
            [
              -68.864338,
              44.355002
            ],
            [
              -68.860649,
              44.364425
            ],
            [
              -68.868444,
              44.38144
            ],
            [
              -68.87894,
              44.386584
            ],
            [
              -68.90353,
              44.378613
            ],
            [
              -68.911634,
              44.365027
            ],
            [
              -68.919325,
              44.335392
            ],
            [
              -68.919301,
              44.309872
            ],
            [
              -68.942826,
              44.281073
            ],
            [
              -68.953686,
              44.272346
            ],
            [
              -68.965264,
              44.259332
            ],
            [
              -68.967074,
              44.251968
            ]
          ]
        ],
        [
          [
            [
              -69.445498,
              44.463839
            ],
            [
              -69.506382,
              44.34287
            ],
            [
              -69.409635,
              44.327998
            ],
            [
              -69.402269,
              44.343059
            ],
            [
              -69.346616,
              44.308337
            ],
            [
              -69.268882,
              44.364653
            ],
            [
              -69.121622,
              44.255644
            ],
            [
              -69.043105,
              44.25955
            ],
            [
              -69.02859006825909,
              44.2491392040609
            ],
            [
              -69.017051,
              44.257086
            ],
            [
              -69.005071,
              44.274071
            ],
            [
              -69.003682,
              44.294582
            ],
            [
              -68.9940791517811,
              44.295261052159596
            ],
            [
              -68.979005,
              44.296327
            ],
            [
              -68.958889,
              44.314353
            ],
            [
              -68.954465,
              44.32405
            ],
            [
              -68.948164,
              44.355882
            ],
            [
              -68.961111,
              44.375076
            ],
            [
              -68.9673,
              44.381106
            ],
            [
              -68.978815,
              44.38634
            ],
            [
              -68.984404,
              44.396879
            ],
            [
              -68.990767,
              44.415033
            ],
            [
              -68.998384,
              44.425586
            ],
            [
              -68.982449,
              44.426195
            ],
            [
              -68.9577349606673,
              44.4282021931462
            ],
            [
              -68.946582,
              44.429108
            ],
            [
              -68.931934,
              44.43869
            ],
            [
              -68.927452,
              44.448039
            ],
            [
              -68.921613,
              44.45664
            ],
            [
              -68.900934,
              44.452062
            ],
            [
              -68.897104,
              44.450643
            ],
            [
              -68.892073,
              44.445075
            ],
            [
              -68.8928,
              44.443415
            ],
            [
              -68.890826,
              44.437995
            ],
            [
              -68.886491,
              44.430676
            ],
            [
              -68.880271,
              44.428112
            ],
            [
              -68.8647231918519,
              44.440395776801296
            ],
            [
              -68.858993,
              44.444923
            ],
            [
              -68.829153,
              44.462242
            ],
            [
              -68.826303,
              44.471568
            ],
            [
              -68.834622,
              44.484965
            ],
            [
              -68.814419,
              44.496664
            ],
            [
              -68.805377,
              44.501515
            ],
            [
              -68.808715,
              44.509039
            ],
            [
              -68.805863,
              44.524292
            ],
            [
              -68.8023408031601,
              44.52491971417329
            ],
            [
              -68.806089,
              44.553634
            ],
            [
              -68.798272,
              44.568096
            ],
            [
              -68.810851,
              44.573114
            ],
            [
              -68.841821,
              44.611062
            ],
            [
              -68.8475,
              44.619839
            ],
            [
              -68.815793,
              44.683754
            ],
            [
              -68.837025,
              44.69233
            ],
            [
              -68.837529,
              44.702125
            ],
            [
              -68.865357,
              44.694217
            ],
            [
              -68.896726,
              44.691724
            ],
            [
              -69.014167,
              44.671794
            ],
            [
              -69.060998,
              44.663946
            ],
            [
              -69.183732,
              44.644023
            ],
            [
              -69.2052,
              44.729586
            ],
            [
              -69.266785,
              44.721928
            ],
            [
              -69.327407,
              44.714442
            ],
            [
              -69.343545,
              44.749819
            ],
            [
              -69.385741,
              44.753704
            ],
            [
              -69.394839,
              44.732563
            ],
            [
              -69.41455,
              44.718701
            ],
            [
              -69.416844,
              44.700981
            ],
            [
              -69.471712,
              44.692899
            ],
            [
              -69.475599,
              44.676678
            ],
            [
              -69.442936,
              44.672381
            ],
            [
              -69.460606,
              44.647054
            ],
            [
              -69.393123,
              44.640375
            ],
            [
              -69.41684,
              44.55108
            ],
            [
              -69.372419,
              44.544585
            ],
            [
              -69.395987,
              44.457078
            ],
            [
              -69.445498,
              44.463839
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "23",
      "COUNTYFP": "025",
      "COUNTYNS": "00581298",
      "AFFGEOID": "0500000US23025",
      "GEOID": "23025",
      "NAME": "Somerset",
      "LSAD": "06",
      "ALAND": 10164120486,
      "AWATER": 437867472,
      "County_state": "Somerset,23"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -70.552793,
              45.667836
            ],
            [
              -70.5528309091743,
              45.6678281233836
            ],
            [
              -70.505775,
              45.513774
            ],
            [
              -70.518696,
              45.512127
            ],
            [
              -70.418519,
              45.144005
            ],
            [
              -70.312485,
              45.164681
            ],
            [
              -70.293826,
              45.109903
            ],
            [
              -70.159375,
              45.128396
            ],
            [
              -70.122429,
              45.000062
            ],
            [
              -70.110365,
              44.947311
            ],
            [
              -70.15211,
              44.941847
            ],
            [
              -70.12993,
              44.850765
            ],
            [
              -70.03327,
              44.86454
            ],
            [
              -70.015597,
              44.786947
            ],
            [
              -70.003154,
              44.78829
            ],
            [
              -70.000831,
              44.773817
            ],
            [
              -70.00066,
              44.759043
            ],
            [
              -70.013529,
              44.757699
            ],
            [
              -70.002986,
              44.708855
            ],
            [
              -69.996191,
              44.677497
            ],
            [
              -69.960149,
              44.681226
            ],
            [
              -69.968768,
              44.661339
            ],
            [
              -69.947081,
              44.648022
            ],
            [
              -69.930451,
              44.610852
            ],
            [
              -69.853273,
              44.621506
            ],
            [
              -69.822438,
              44.607585
            ],
            [
              -69.818468,
              44.581312
            ],
            [
              -69.792813,
              44.57733
            ],
            [
              -69.776294,
              44.608236
            ],
            [
              -69.744282,
              44.602607
            ],
            [
              -69.742091,
              44.598246
            ],
            [
              -69.712421,
              44.593679
            ],
            [
              -69.665525,
              44.586402
            ],
            [
              -69.605746,
              44.577264
            ],
            [
              -69.591628,
              44.583415
            ],
            [
              -69.593069,
              44.601341
            ],
            [
              -69.579952,
              44.621466
            ],
            [
              -69.587342,
              44.634076
            ],
            [
              -69.62848,
              44.671969
            ],
            [
              -69.632971,
              44.698763
            ],
            [
              -69.62728,
              44.701216
            ],
            [
              -69.603624,
              44.70462
            ],
            [
              -69.483395,
              44.720271
            ],
            [
              -69.470382,
              44.718161
            ],
            [
              -69.471712,
              44.692899
            ],
            [
              -69.416844,
              44.700981
            ],
            [
              -69.41455,
              44.718701
            ],
            [
              -69.394839,
              44.732563
            ],
            [
              -69.385741,
              44.753704
            ],
            [
              -69.343545,
              44.749819
            ],
            [
              -69.327407,
              44.714442
            ],
            [
              -69.266785,
              44.721928
            ],
            [
              -69.286336,
              44.807988
            ],
            [
              -69.281534,
              44.80962
            ],
            [
              -69.330949,
              44.986901
            ],
            [
              -69.355667,
              45.073469
            ],
            [
              -69.499861,
              45.053778
            ],
            [
              -69.495192,
              45.037352
            ],
            [
              -69.515649,
              45.025043
            ],
            [
              -69.620967,
              45.010956
            ],
            [
              -69.65476,
              45.099989
            ],
            [
              -69.6828,
              45.194878
            ],
            [
              -69.683646,
              45.212399
            ],
            [
              -69.708324,
              45.291354
            ],
            [
              -69.779928,
              45.542766
            ],
            [
              -69.731534,
              45.566912
            ],
            [
              -69.713481,
              45.595622
            ],
            [
              -69.720791,
              45.602183
            ],
            [
              -69.701688,
              45.627982
            ],
            [
              -69.718271,
              45.639751
            ],
            [
              -69.71198,
              45.65043
            ],
            [
              -69.691808,
              45.644941
            ],
            [
              -69.723568,
              45.657386
            ],
            [
              -69.743752,
              45.652005
            ],
            [
              -69.738487,
              45.679033
            ],
            [
              -69.754726,
              45.679413
            ],
            [
              -69.785257,
              45.69278
            ],
            [
              -69.790244,
              45.710342
            ],
            [
              -69.804603,
              45.716295
            ],
            [
              -69.791909,
              45.725682
            ],
            [
              -69.824484,
              45.742179
            ],
            [
              -69.788245,
              45.746778
            ],
            [
              -69.793175,
              45.761554
            ],
            [
              -69.78223,
              45.769537
            ],
            [
              -69.785908,
              45.783643
            ],
            [
              -69.801994,
              45.786191
            ],
            [
              -69.786639,
              45.784903
            ],
            [
              -69.768523,
              45.761322
            ],
            [
              -69.740827,
              45.755511
            ],
            [
              -69.7266,
              45.762051
            ],
            [
              -69.737667,
              45.782781
            ],
            [
              -69.722876,
              45.783263
            ],
            [
              -69.708642,
              45.811677
            ],
            [
              -69.718472,
              45.829923
            ],
            [
              -69.705743,
              45.853975
            ],
            [
              -69.738216,
              45.883026
            ],
            [
              -69.697985,
              45.885723
            ],
            [
              -69.702607,
              45.877099
            ],
            [
              -69.683391,
              45.850965
            ],
            [
              -69.673449,
              45.862406
            ],
            [
              -69.647025,
              45.863053
            ],
            [
              -69.683564,
              45.983632
            ],
            [
              -69.728577,
              45.976882
            ],
            [
              -69.73232,
              46.394008
            ],
            [
              -69.719246,
              46.394109
            ],
            [
              -69.721109,
              46.574053
            ],
            [
              -70.023362666359,
              46.5735117449688
            ],
            [
              -70.026521,
              46.557217
            ],
            [
              -70.056433,
              46.41659
            ],
            [
              -70.056433,
              46.415561
            ],
            [
              -70.057061,
              46.415036
            ],
            [
              -70.076128,
              46.409389
            ],
            [
              -70.080292,
              46.410531
            ],
            [
              -70.089248,
              46.410666
            ],
            [
              -70.096286,
              46.40943
            ],
            [
              -70.100413,
              46.405017
            ],
            [
              -70.100607,
              46.399303
            ],
            [
              -70.100177,
              46.398435
            ],
            [
              -70.11044,
              46.38611
            ],
            [
              -70.118597,
              46.384233
            ],
            [
              -70.125459,
              46.381352
            ],
            [
              -70.127542,
              46.378747
            ],
            [
              -70.127552,
              46.371943
            ],
            [
              -70.129734,
              46.369384
            ],
            [
              -70.133367,
              46.368906
            ],
            [
              -70.141164,
              46.362669
            ],
            [
              -70.148529,
              46.358923
            ],
            [
              -70.161337,
              46.360984
            ],
            [
              -70.171969,
              46.359294
            ],
            [
              -70.174709,
              46.358472
            ],
            [
              -70.188739,
              46.349993
            ],
            [
              -70.191412,
              46.348072
            ],
            [
              -70.207415,
              46.331316
            ],
            [
              -70.208733,
              46.328961
            ],
            [
              -70.207046,
              46.319247
            ],
            [
              -70.204373,
              46.316505
            ],
            [
              -70.203119,
              46.31438
            ],
            [
              -70.205719,
              46.299865
            ],
            [
              -70.214423,
              46.295314
            ],
            [
              -70.232682,
              46.284428
            ],
            [
              -70.248421,
              46.267072
            ],
            [
              -70.252906,
              46.259075
            ],
            [
              -70.254591,
              46.254231
            ],
            [
              -70.255492,
              46.246444
            ],
            [
              -70.258599,
              46.235588
            ],
            [
              -70.260645,
              46.231452
            ],
            [
              -70.272054,
              46.209833
            ],
            [
              -70.285526,
              46.196991
            ],
            [
              -70.292736,
              46.191599
            ],
            [
              -70.290896,
              46.185838
            ],
            [
              -70.278034,
              46.175001
            ],
            [
              -70.273594,
              46.172485
            ],
            [
              -70.271192,
              46.172072
            ],
            [
              -70.26626,
              46.16935
            ],
            [
              -70.240264,
              46.150076
            ],
            [
              -70.237947,
              46.147378
            ],
            [
              -70.239566,
              46.142762
            ],
            [
              -70.243629,
              46.128794
            ],
            [
              -70.251282,
              46.117947
            ],
            [
              -70.255069,
              46.1102
            ],
            [
              -70.255038,
              46.108348
            ],
            [
              -70.252411,
              46.106221
            ],
            [
              -70.252413,
              46.100625
            ],
            [
              -70.254021,
              46.0996
            ],
            [
              -70.266349,
              46.100993
            ],
            [
              -70.272689,
              46.102298
            ],
            [
              -70.284554,
              46.098713
            ],
            [
              -70.28978,
              46.094325
            ],
            [
              -70.310609,
              46.064544
            ],
            [
              -70.308967,
              46.062464
            ],
            [
              -70.306734,
              46.061344
            ],
            [
              -70.292845,
              46.060679
            ],
            [
              -70.287295,
              46.062576
            ],
            [
              -70.284176,
              46.062758
            ],
            [
              -70.27889,
              46.060654
            ],
            [
              -70.278169,
              46.059671
            ],
            [
              -70.278334,
              46.057019
            ],
            [
              -70.27965,
              46.052196
            ],
            [
              -70.293639,
              46.041845
            ],
            [
              -70.317596,
              46.019492
            ],
            [
              -70.317629,
              46.01908
            ],
            [
              -70.303625,
              45.999479
            ],
            [
              -70.303034,
              45.998976
            ],
            [
              -70.292868,
              45.997397
            ],
            [
              -70.284932,
              45.995613
            ],
            [
              -70.284571,
              45.995384
            ],
            [
              -70.287754,
              45.99182
            ],
            [
              -70.295986,
              45.985969
            ],
            [
              -70.301298,
              45.985353
            ],
            [
              -70.307463,
              45.982541
            ],
            [
              -70.309725,
              45.98021
            ],
            [
              -70.312055,
              45.971544
            ],
            [
              -70.31687,
              45.96389
            ],
            [
              -70.316936,
              45.963456
            ],
            [
              -70.31628,
              45.963113
            ],
            [
              -70.31297,
              45.961856
            ],
            [
              -70.289632,
              45.963201
            ],
            [
              -70.280814,
              45.965211
            ],
            [
              -70.274325,
              45.964295
            ],
            [
              -70.26541,
              45.962692
            ],
            [
              -70.252963,
              45.955234
            ],
            [
              -70.240177,
              45.943729
            ],
            [
              -70.240162,
              45.940944
            ],
            [
              -70.24092,
              45.939095
            ],
            [
              -70.252526,
              45.933176
            ],
            [
              -70.262198,
              45.925203
            ],
            [
              -70.263313,
              45.923832
            ],
            [
              -70.263315,
              45.920152
            ],
            [
              -70.262824,
              45.919832
            ],
            [
              -70.261055,
              45.920311
            ],
            [
              -70.25788,
              45.918138
            ],
            [
              -70.253897,
              45.906524
            ],
            [
              -70.253704,
              45.902981
            ],
            [
              -70.259117,
              45.890755
            ],
            [
              -70.262655,
              45.887853
            ],
            [
              -70.284204,
              45.872034
            ],
            [
              -70.306162,
              45.85974
            ],
            [
              -70.317154,
              45.856448
            ],
            [
              -70.329748,
              45.853795
            ],
            [
              -70.34244,
              45.852192
            ],
            [
              -70.36702,
              45.834993
            ],
            [
              -70.380752,
              45.824639
            ],
            [
              -70.387916,
              45.819043
            ],
            [
              -70.39662,
              45.808486
            ],
            [
              -70.398159,
              45.80412
            ],
            [
              -70.396362,
              45.802703
            ],
            [
              -70.395907,
              45.798885
            ],
            [
              -70.399634,
              45.796235
            ],
            [
              -70.401857,
              45.795915
            ],
            [
              -70.404275,
              45.796259
            ],
            [
              -70.406334,
              45.797494
            ],
            [
              -70.408,
              45.797586
            ],
            [
              -70.416922,
              45.795279
            ],
            [
              -70.417674,
              45.79457
            ],
            [
              -70.417641,
              45.79377
            ],
            [
              -70.415684,
              45.786158
            ],
            [
              -70.406548,
              45.761813
            ],
            [
              -70.401749,
              45.75788
            ],
            [
              -70.396133,
              45.756255
            ],
            [
              -70.388501,
              45.749717
            ],
            [
              -70.383552,
              45.734869
            ],
            [
              -70.390379,
              45.728539
            ],
            [
              -70.400404,
              45.719834
            ],
            [
              -70.430787,
              45.707222
            ],
            [
              -70.438878,
              45.704387
            ],
            [
              -70.446903,
              45.704044
            ],
            [
              -70.451503,
              45.704432
            ],
            [
              -70.469869,
              45.701639
            ],
            [
              -70.496603,
              45.687021
            ],
            [
              -70.510171,
              45.679323
            ],
            [
              -70.518948,
              45.672286
            ],
            [
              -70.51947,
              45.671738
            ],
            [
              -70.519537,
              45.670001
            ],
            [
              -70.525831,
              45.666551
            ],
            [
              -70.541415,
              45.666715
            ],
            [
              -70.552793,
              45.667836
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "23",
      "COUNTYFP": "023",
      "COUNTYNS": "00581297",
      "AFFGEOID": "0500000US23023",
      "GEOID": "23023",
      "NAME": "Sagadahoc",
      "LSAD": "06",
      "ALAND": 657803472,
      "AWATER": 300569614,
      "County_state": "Sagadahoc,23"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -70.051824,
              43.990982
            ],
            [
              -70.047252,
              43.988364
            ],
            [
              -70.034238,
              43.975661
            ],
            [
              -70.020001,
              43.945817
            ],
            [
              -69.977692,
              43.915057
            ],
            [
              -69.967683,
              43.921102
            ],
            [
              -69.95413,
              43.923004
            ],
            [
              -69.931291,
              43.911924
            ],
            [
              -69.913938,
              43.921234
            ],
            [
              -69.9047,
              43.925589
            ],
            [
              -69.874657,
              43.95359
            ],
            [
              -69.857032,
              43.944742
            ],
            [
              -69.86248,
              43.931579
            ],
            [
              -69.864825,
              43.919384
            ],
            [
              -69.868655,
              43.910916
            ],
            [
              -69.892506,
              43.880311
            ],
            [
              -69.895953,
              43.866238
            ],
            [
              -69.874729,
              43.851106
            ],
            [
              -69.871322,
              43.814468
            ],
            [
              -69.887345,
              43.787937
            ],
            [
              -69.8873902869984,
              43.7770524049583
            ],
            [
              -69.884066,
              43.778035
            ],
            [
              -69.869732,
              43.775656
            ],
            [
              -69.862155,
              43.758962
            ],
            [
              -69.868673,
              43.742701
            ],
            [
              -69.858947,
              43.740531
            ],
            [
              -69.855595,
              43.73266
            ],
            [
              -69.857927,
              43.723915
            ],
            [
              -69.855081,
              43.704746
            ],
            [
              -69.851297,
              43.703581
            ],
            [
              -69.833471,
              43.701281
            ],
            [
              -69.828955,
              43.70678
            ],
            [
              -69.835323,
              43.721125
            ],
            [
              -69.770724,
              43.737183
            ],
            [
              -69.754091,
              43.743866
            ],
            [
              -69.741014,
              43.769288
            ],
            [
              -69.728046,
              43.776318
            ],
            [
              -69.719723,
              43.786685
            ],
            [
              -69.717074,
              43.792403
            ],
            [
              -69.717804,
              43.801047
            ],
            [
              -69.714873,
              43.810264
            ],
            [
              -69.705838,
              43.823024
            ],
            [
              -69.6978577217234,
              43.824918144431
            ],
            [
              -69.692076,
              43.857175
            ],
            [
              -69.718399,
              43.850588
            ],
            [
              -69.743385,
              43.893241
            ],
            [
              -69.707421,
              43.927251
            ],
            [
              -69.721218,
              43.9315
            ],
            [
              -69.719628,
              43.971287
            ],
            [
              -69.700152,
              44.006585
            ],
            [
              -69.746329,
              44.013802
            ],
            [
              -69.754381,
              44.016009
            ],
            [
              -69.813549,
              44.026011
            ],
            [
              -69.790306,
              44.042466
            ],
            [
              -69.782868,
              44.090661
            ],
            [
              -69.774175,
              44.099351
            ],
            [
              -69.758208,
              44.139334
            ],
            [
              -69.763566,
              44.153217
            ],
            [
              -69.766977,
              44.153759
            ],
            [
              -69.854983,
              44.167862
            ],
            [
              -69.878072,
              44.153371
            ],
            [
              -69.897783,
              44.107662
            ],
            [
              -70.005471,
              44.124519
            ],
            [
              -70.025228,
              44.050921
            ],
            [
              -70.037829,
              44.017261
            ],
            [
              -70.035532,
              44.00709
            ],
            [
              -70.031771,
              44.002918
            ],
            [
              -70.051824,
              43.990982
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "23",
      "COUNTYFP": "001",
      "COUNTYNS": "00581286",
      "AFFGEOID": "0500000US23001",
      "GEOID": "23001",
      "NAME": "Androscoggin",
      "LSAD": "06",
      "ALAND": 1211961731,
      "AWATER": 75575386,
      "County_state": "Androscoggin,23"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -70.485288,
              44.060398
            ],
            [
              -70.480078,
              44.032078
            ],
            [
              -70.479645,
              44.02629
            ],
            [
              -70.401954,
              43.990108
            ],
            [
              -70.395324,
              44.003091
            ],
            [
              -70.363874,
              43.986445
            ],
            [
              -70.314834,
              44.03739
            ],
            [
              -70.229244,
              43.974064
            ],
            [
              -70.200284,
              43.951993
            ],
            [
              -70.203647,
              43.947928
            ],
            [
              -70.196992,
              43.94595
            ],
            [
              -70.118523,
              43.907967
            ],
            [
              -70.092329,
              43.931953
            ],
            [
              -70.080839,
              43.926458
            ],
            [
              -70.034238,
              43.975661
            ],
            [
              -70.047252,
              43.988364
            ],
            [
              -70.051824,
              43.990982
            ],
            [
              -70.031771,
              44.002918
            ],
            [
              -70.035532,
              44.00709
            ],
            [
              -70.037829,
              44.017261
            ],
            [
              -70.025228,
              44.050921
            ],
            [
              -70.005471,
              44.124519
            ],
            [
              -70.011732,
              44.128011
            ],
            [
              -70.027386,
              44.130577
            ],
            [
              -70.010329,
              44.168651
            ],
            [
              -69.993792,
              44.180594
            ],
            [
              -70.060498,
              44.191775
            ],
            [
              -70.075747,
              44.206168
            ],
            [
              -70.07426,
              44.290154
            ],
            [
              -70.074464,
              44.298471
            ],
            [
              -70.09905,
              44.319547
            ],
            [
              -70.100459,
              44.333254
            ],
            [
              -70.132595,
              44.371382
            ],
            [
              -70.122172,
              44.381131
            ],
            [
              -70.100491,
              44.383854
            ],
            [
              -70.12939,
              44.487217
            ],
            [
              -70.201276,
              44.477976
            ],
            [
              -70.238904,
              44.458942
            ],
            [
              -70.274412,
              44.440632
            ],
            [
              -70.278692,
              44.415173
            ],
            [
              -70.265223,
              44.362913
            ],
            [
              -70.281511,
              44.322394
            ],
            [
              -70.325218,
              44.208399
            ],
            [
              -70.334094,
              44.210753
            ],
            [
              -70.391735,
              44.153562
            ],
            [
              -70.401809,
              44.143657
            ],
            [
              -70.451157,
              44.094549
            ],
            [
              -70.485288,
              44.060398
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "23",
      "COUNTYFP": "017",
      "COUNTYNS": "00581294",
      "AFFGEOID": "0500000US23017",
      "GEOID": "23017",
      "NAME": "Oxford",
      "LSAD": "06",
      "ALAND": 5379066664,
      "AWATER": 256039480,
      "County_state": "Oxford,23"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -71.078407,
              45.306232
            ],
            [
              -71.083924,
              45.305451
            ],
            [
              -71.076914,
              45.246912
            ],
            [
              -71.0726,
              45.180935
            ],
            [
              -71.069387,
              45.144037
            ],
            [
              -71.059004,
              45.004918
            ],
            [
              -71.057861,
              45.000049
            ],
            [
              -71.052707,
              44.93084
            ],
            [
              -71.044646,
              44.845039
            ],
            [
              -71.037518,
              44.755607
            ],
            [
              -71.036705,
              44.736498
            ],
            [
              -71.031039,
              44.655455
            ],
            [
              -71.0290473414933,
              44.6112487220218
            ],
            [
              -71.026655,
              44.558149
            ],
            [
              -71.022992,
              44.500058
            ],
            [
              -71.020773,
              44.473737
            ],
            [
              -71.015363,
              44.378113
            ],
            [
              -71.012749,
              44.340784
            ],
            [
              -71.01127,
              44.301846
            ],
            [
              -71.0102711678683,
              44.284888321572005
            ],
            [
              -71.008736,
              44.258825
            ],
            [
              -71.008764,
              44.258443
            ],
            [
              -71.008567,
              44.245491
            ],
            [
              -71.0021619977753,
              44.1106192952439
            ],
            [
              -71.001335,
              44.093205
            ],
            [
              -71.001367,
              44.092931
            ],
            [
              -70.998444,
              44.041099
            ],
            [
              -70.992842,
              43.916269
            ],
            [
              -70.992653,
              43.89624
            ],
            [
              -70.99174897262161,
              43.877322795670096
            ],
            [
              -70.989929,
              43.839239
            ],
            [
              -70.989067,
              43.79244
            ],
            [
              -70.9890367618669,
              43.7921536087332
            ],
            [
              -70.957548,
              43.785123
            ],
            [
              -70.927071,
              43.790299
            ],
            [
              -70.888577,
              43.80707
            ],
            [
              -70.841207,
              43.809894
            ],
            [
              -70.796924,
              43.806295
            ],
            [
              -70.782984,
              43.814147
            ],
            [
              -70.794721,
              43.835441
            ],
            [
              -70.798193,
              43.863304
            ],
            [
              -70.765288,
              43.871538
            ],
            [
              -70.743878,
              43.885849
            ],
            [
              -70.755218,
              43.888889
            ],
            [
              -70.757934,
              43.906864
            ],
            [
              -70.72978,
              43.92833
            ],
            [
              -70.747507,
              43.945901
            ],
            [
              -70.715418,
              43.961815
            ],
            [
              -70.792531,
              44.04014
            ],
            [
              -70.848077,
              44.049522
            ],
            [
              -70.866583,
              44.079706
            ],
            [
              -70.858546,
              44.095269
            ],
            [
              -70.821803,
              44.077089
            ],
            [
              -70.69592,
              44.134217
            ],
            [
              -70.624329,
              44.171036
            ],
            [
              -70.615053,
              44.161437
            ],
            [
              -70.607383,
              44.154404
            ],
            [
              -70.587233,
              44.124097
            ],
            [
              -70.606173,
              44.101293
            ],
            [
              -70.611904,
              44.070625
            ],
            [
              -70.595631,
              44.032057
            ],
            [
              -70.581463,
              44.018236
            ],
            [
              -70.563851,
              44.024992
            ],
            [
              -70.544094,
              44.005647
            ],
            [
              -70.508997,
              44.041454
            ],
            [
              -70.502371,
              44.042568
            ],
            [
              -70.480078,
              44.032078
            ],
            [
              -70.485288,
              44.060398
            ],
            [
              -70.451157,
              44.094549
            ],
            [
              -70.401809,
              44.143657
            ],
            [
              -70.391735,
              44.153562
            ],
            [
              -70.334094,
              44.210753
            ],
            [
              -70.325218,
              44.208399
            ],
            [
              -70.281511,
              44.322394
            ],
            [
              -70.265223,
              44.362913
            ],
            [
              -70.278692,
              44.415173
            ],
            [
              -70.274412,
              44.440632
            ],
            [
              -70.238904,
              44.458942
            ],
            [
              -70.250895,
              44.503402
            ],
            [
              -70.280908,
              44.505095
            ],
            [
              -70.289888,
              44.540248
            ],
            [
              -70.307659,
              44.537876
            ],
            [
              -70.280748,
              44.569619
            ],
            [
              -70.34758,
              44.580175
            ],
            [
              -70.549985,
              44.610727
            ],
            [
              -70.510598,
              44.627216
            ],
            [
              -70.563799,
              44.713193
            ],
            [
              -70.61503,
              44.795634
            ],
            [
              -70.769228,
              44.732228
            ],
            [
              -70.773902,
              44.839777
            ],
            [
              -70.796412,
              44.925877
            ],
            [
              -70.804537,
              44.924934
            ],
            [
              -70.823634,
              45.01379
            ],
            [
              -70.779842,
              45.018379
            ],
            [
              -70.8347140662076,
              45.2751422184342
            ],
            [
              -70.839042,
              45.269132
            ],
            [
              -70.84108,
              45.267144
            ],
            [
              -70.84328,
              45.265658
            ],
            [
              -70.848554,
              45.263325
            ],
            [
              -70.849261,
              45.256398
            ],
            [
              -70.848319,
              45.244707
            ],
            [
              -70.8411,
              45.238926
            ],
            [
              -70.83877,
              45.237555
            ],
            [
              -70.84443,
              45.234513
            ],
            [
              -70.857042,
              45.22916
            ],
            [
              -70.878495,
              45.23327
            ],
            [
              -70.885029,
              45.234873
            ],
            [
              -70.892822,
              45.239172
            ],
            [
              -70.896898,
              45.242031
            ],
            [
              -70.898482,
              45.244088
            ],
            [
              -70.896627,
              45.253107
            ],
            [
              -70.898565,
              45.258502
            ],
            [
              -70.907978,
              45.269316
            ],
            [
              -70.915194,
              45.274735
            ],
            [
              -70.921474,
              45.278873
            ],
            [
              -70.9217,
              45.279445
            ],
            [
              -70.919951,
              45.284953
            ],
            [
              -70.918753,
              45.287033
            ],
            [
              -70.913732,
              45.292746
            ],
            [
              -70.912111,
              45.296197
            ],
            [
              -70.917904,
              45.311924
            ],
            [
              -70.921435,
              45.313867
            ],
            [
              -70.929923,
              45.318462
            ],
            [
              -70.939188,
              45.320177
            ],
            [
              -70.943887,
              45.324336
            ],
            [
              -70.949365,
              45.331536
            ],
            [
              -70.950824,
              45.33453
            ],
            [
              -70.967188,
              45.332401
            ],
            [
              -70.985595,
              45.332188
            ],
            [
              -70.989324,
              45.334655
            ],
            [
              -70.990233,
              45.336277
            ],
            [
              -70.99273,
              45.338173
            ],
            [
              -71.001771,
              45.343658
            ],
            [
              -71.004848,
              45.345419
            ],
            [
              -71.01081,
              45.34725
            ],
            [
              -71.012757,
              45.34476
            ],
            [
              -71.01292,
              45.343297
            ],
            [
              -71.011144,
              45.334679
            ],
            [
              -71.005087,
              45.331545
            ],
            [
              -71.0024,
              45.328482
            ],
            [
              -71.002563,
              45.327819
            ],
            [
              -71.00905,
              45.319022
            ],
            [
              -71.014268,
              45.316761
            ],
            [
              -71.021753,
              45.314409
            ],
            [
              -71.030565,
              45.312652
            ],
            [
              -71.03821,
              45.311922
            ],
            [
              -71.042485,
              45.313043
            ],
            [
              -71.059265,
              45.313753
            ],
            [
              -71.066197,
              45.311353
            ],
            [
              -71.073743,
              45.307718
            ],
            [
              -71.078407,
              45.306232
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "23",
      "COUNTYFP": "005",
      "COUNTYNS": "00581288",
      "AFFGEOID": "0500000US23005",
      "GEOID": "23005",
      "NAME": "Cumberland",
      "LSAD": "06",
      "ALAND": 2164393695,
      "AWATER": 988818232,
      "County_state": "Cumberland,23"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -70.858546,
              44.095269
            ],
            [
              -70.866583,
              44.079706
            ],
            [
              -70.848077,
              44.049522
            ],
            [
              -70.792531,
              44.04014
            ],
            [
              -70.715418,
              43.961815
            ],
            [
              -70.747507,
              43.945901
            ],
            [
              -70.72978,
              43.92833
            ],
            [
              -70.757934,
              43.906864
            ],
            [
              -70.755218,
              43.888889
            ],
            [
              -70.743878,
              43.885849
            ],
            [
              -70.765288,
              43.871538
            ],
            [
              -70.798193,
              43.863304
            ],
            [
              -70.794721,
              43.835441
            ],
            [
              -70.782984,
              43.814147
            ],
            [
              -70.780335,
              43.803516
            ],
            [
              -70.766073,
              43.792416
            ],
            [
              -70.733234,
              43.789551
            ],
            [
              -70.695153,
              43.805203
            ],
            [
              -70.668199,
              43.792531
            ],
            [
              -70.653533,
              43.787738
            ],
            [
              -70.644023,
              43.755696
            ],
            [
              -70.652359,
              43.733521
            ],
            [
              -70.657371,
              43.701544
            ],
            [
              -70.633528,
              43.703913
            ],
            [
              -70.614493,
              43.698331
            ],
            [
              -70.612074,
              43.687099
            ],
            [
              -70.598173,
              43.694581
            ],
            [
              -70.577401,
              43.687185
            ],
            [
              -70.546605,
              43.71316
            ],
            [
              -70.490749,
              43.668795
            ],
            [
              -70.457743,
              43.642372
            ],
            [
              -70.468462,
              43.631319
            ],
            [
              -70.492009,
              43.606769
            ],
            [
              -70.390653,
              43.553997
            ],
            [
              -70.39009,
              43.55371
            ],
            [
              -70.35339193799001,
              43.535405312744004
            ],
            [
              -70.352826,
              43.535855
            ],
            [
              -70.328796,
              43.540749
            ],
            [
              -70.321116,
              43.527262
            ],
            [
              -70.30874328842579,
              43.528859235758596
            ],
            [
              -70.307405,
              43.529032
            ],
            [
              -70.307764,
              43.544315
            ],
            [
              -70.2772623852143,
              43.56014311274539
            ],
            [
              -70.272497,
              43.562616
            ],
            [
              -70.27099787521419,
              43.561350812361695
            ],
            [
              -70.261917,
              43.553687
            ],
            [
              -70.244331,
              43.551849
            ],
            [
              -70.245499,
              43.539635
            ],
            [
              -70.227842,
              43.538261
            ],
            [
              -70.216782,
              43.556874
            ],
            [
              -70.206123,
              43.557627
            ],
            [
              -70.196911,
              43.565146
            ],
            [
              -70.20112,
              43.586515
            ],
            [
              -70.214369,
              43.590445
            ],
            [
              -70.217087,
              43.596717
            ],
            [
              -70.207491,
              43.623483
            ],
            [
              -70.206610718898,
              43.6292215055781
            ],
            [
              -70.205934,
              43.633633
            ],
            [
              -70.190704,
              43.645582
            ],
            [
              -70.176664,
              43.659768
            ],
            [
              -70.1723197064725,
              43.66768113297739
            ],
            [
              -70.168227,
              43.675136
            ],
            [
              -70.151724,
              43.681689
            ],
            [
              -70.13170700079179,
              43.682409401831
            ],
            [
              -70.115908,
              43.682978
            ],
            [
              -70.096039,
              43.672276
            ],
            [
              -70.088455,
              43.681454
            ],
            [
              -70.091929,
              43.698111
            ],
            [
              -70.0854759026811,
              43.702642050142096
            ],
            [
              -70.079285,
              43.706989
            ],
            [
              -70.071304,
              43.713772
            ],
            [
              -70.06345039508291,
              43.71581494472309
            ],
            [
              -70.04667,
              43.72018
            ],
            [
              -70.036212,
              43.712621
            ],
            [
              -70.001273,
              43.710388
            ],
            [
              -69.992615,
              43.724793
            ],
            [
              -69.983685,
              43.744395
            ],
            [
              -69.97184,
              43.750642
            ],
            [
              -69.958056,
              43.767786
            ],
            [
              -69.953246,
              43.768806
            ],
            [
              -69.948539,
              43.765948
            ],
            [
              -69.927011,
              43.780174
            ],
            [
              -69.915593,
              43.775112
            ],
            [
              -69.903164,
              43.77239
            ],
            [
              -69.8873902869984,
              43.7770524049583
            ],
            [
              -69.887345,
              43.787937
            ],
            [
              -69.871322,
              43.814468
            ],
            [
              -69.874729,
              43.851106
            ],
            [
              -69.895953,
              43.866238
            ],
            [
              -69.892506,
              43.880311
            ],
            [
              -69.868655,
              43.910916
            ],
            [
              -69.864825,
              43.919384
            ],
            [
              -69.86248,
              43.931579
            ],
            [
              -69.857032,
              43.944742
            ],
            [
              -69.874657,
              43.95359
            ],
            [
              -69.9047,
              43.925589
            ],
            [
              -69.913938,
              43.921234
            ],
            [
              -69.931291,
              43.911924
            ],
            [
              -69.95413,
              43.923004
            ],
            [
              -69.967683,
              43.921102
            ],
            [
              -69.977692,
              43.915057
            ],
            [
              -70.020001,
              43.945817
            ],
            [
              -70.034238,
              43.975661
            ],
            [
              -70.080839,
              43.926458
            ],
            [
              -70.092329,
              43.931953
            ],
            [
              -70.118523,
              43.907967
            ],
            [
              -70.196992,
              43.94595
            ],
            [
              -70.203647,
              43.947928
            ],
            [
              -70.200284,
              43.951993
            ],
            [
              -70.229244,
              43.974064
            ],
            [
              -70.314834,
              44.03739
            ],
            [
              -70.363874,
              43.986445
            ],
            [
              -70.395324,
              44.003091
            ],
            [
              -70.401954,
              43.990108
            ],
            [
              -70.479645,
              44.02629
            ],
            [
              -70.480078,
              44.032078
            ],
            [
              -70.502371,
              44.042568
            ],
            [
              -70.508997,
              44.041454
            ],
            [
              -70.544094,
              44.005647
            ],
            [
              -70.563851,
              44.024992
            ],
            [
              -70.581463,
              44.018236
            ],
            [
              -70.595631,
              44.032057
            ],
            [
              -70.611904,
              44.070625
            ],
            [
              -70.606173,
              44.101293
            ],
            [
              -70.587233,
              44.124097
            ],
            [
              -70.607383,
              44.154404
            ],
            [
              -70.615053,
              44.161437
            ],
            [
              -70.624329,
              44.171036
            ],
            [
              -70.69592,
              44.134217
            ],
            [
              -70.821803,
              44.077089
            ],
            [
              -70.858546,
              44.095269
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "23",
      "COUNTYFP": "013",
      "COUNTYNS": "00581292",
      "AFFGEOID": "0500000US23013",
      "GEOID": "23013",
      "NAME": "Knox",
      "LSAD": "06",
      "ALAND": 945693972,
      "AWATER": 2017106965,
      "County_state": "Knox,23"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -68.669383,
              44.076359
            ],
            [
              -68.660825,
              44.047892
            ],
            [
              -68.661801,
              44.021386
            ],
            [
              -68.657031,
              44.003823
            ],
            [
              -68.634355,
              44.017603
            ],
            [
              -68.617085,
              44.010097
            ],
            [
              -68.601696,
              44.012514
            ],
            [
              -68.604741,
              44.038857
            ],
            [
              -68.584101,
              44.071589
            ],
            [
              -68.601325,
              44.08632
            ],
            [
              -68.617858,
              44.097238
            ],
            [
              -68.6215549812924,
              44.11287522215039
            ],
            [
              -68.631382,
              44.114335
            ],
            [
              -68.634017,
              44.115051
            ],
            [
              -68.645914,
              44.11923
            ],
            [
              -68.651208,
              44.120137
            ],
            [
              -68.65811796527919,
              44.1200404599176
            ],
            [
              -68.658794,
              44.110212
            ],
            [
              -68.651267,
              44.101422
            ],
            [
              -68.646414,
              44.079914
            ],
            [
              -68.669383,
              44.076359
            ]
          ]
        ],
        [
          [
            [
              -68.913427,
              43.85306
            ],
            [
              -68.913427,
              43.832709
            ],
            [
              -68.898319,
              43.820987
            ],
            [
              -68.881891,
              43.820735
            ],
            [
              -68.869442,
              43.837498
            ],
            [
              -68.869442,
              43.851265
            ],
            [
              -68.871102,
              43.870413
            ],
            [
              -68.882721,
              43.885368
            ],
            [
              -68.895999,
              43.881181
            ],
            [
              -68.910108,
              43.868618
            ],
            [
              -68.913427,
              43.85306
            ]
          ]
        ],
        [
          [
            [
              -68.944597,
              44.11284
            ],
            [
              -68.943105,
              44.10973
            ],
            [
              -68.908984,
              44.110001
            ],
            [
              -68.907812,
              44.105518
            ],
            [
              -68.913406,
              44.08519
            ],
            [
              -68.905098,
              44.077344
            ],
            [
              -68.899997,
              44.06696
            ],
            [
              -68.908541,
              44.058051
            ],
            [
              -68.918048,
              44.045752
            ],
            [
              -68.889717,
              44.032516
            ],
            [
              -68.874139,
              44.025359
            ],
            [
              -68.862845,
              44.025037
            ],
            [
              -68.828465,
              44.032118
            ],
            [
              -68.818441,
              44.032046
            ],
            [
              -68.807315,
              44.035796
            ],
            [
              -68.790595,
              44.053832
            ],
            [
              -68.785601,
              44.053503
            ],
            [
              -68.77965,
              44.057754
            ],
            [
              -68.77029,
              44.069566
            ],
            [
              -68.772639,
              44.078439
            ],
            [
              -68.781772,
              44.084274
            ],
            [
              -68.790525,
              44.09292
            ],
            [
              -68.806832,
              44.116339
            ],
            [
              -68.815562,
              44.115836
            ],
            [
              -68.81953,
              44.122056
            ],
            [
              -68.820515,
              44.130198
            ],
            [
              -68.819659,
              44.135434
            ],
            [
              -68.818039,
              44.136852
            ],
            [
              -68.802162,
              44.137857
            ],
            [
              -68.792065,
              44.136759
            ],
            [
              -68.780693,
              44.143274
            ],
            [
              -68.782375,
              44.14531
            ],
            [
              -68.786886,
              44.143961
            ],
            [
              -68.792221,
              44.145998
            ],
            [
              -68.80824,
              44.161298
            ],
            [
              -68.818423,
              44.160978
            ],
            [
              -68.82284,
              44.173693
            ],
            [
              -68.822206,
              44.178815
            ],
            [
              -68.819156,
              44.180462
            ],
            [
              -68.825067,
              44.186338
            ],
            [
              -68.847249,
              44.183017
            ],
            [
              -68.87868,
              44.166612
            ],
            [
              -68.888597,
              44.15955
            ],
            [
              -68.917286,
              44.148239
            ],
            [
              -68.935327,
              44.13038
            ],
            [
              -68.944597,
              44.11284
            ]
          ]
        ],
        [
          [
            [
              -69.451251,
              44.31395
            ],
            [
              -69.440665,
              44.284434
            ],
            [
              -69.449481,
              44.26227
            ],
            [
              -69.432837,
              44.22674
            ],
            [
              -69.403633,
              44.219145
            ],
            [
              -69.40602,
              44.210674
            ],
            [
              -69.372175,
              44.205405
            ],
            [
              -69.337672,
              44.20937
            ],
            [
              -69.321529,
              44.1729
            ],
            [
              -69.303661,
              44.132677
            ],
            [
              -69.311502,
              44.09549
            ],
            [
              -69.282225,
              44.054772
            ],
            [
              -69.282268,
              44.052584
            ],
            [
              -69.320379,
              44.033414
            ],
            [
              -69.327832,
              44.006185
            ],
            [
              -69.346093,
              44.018808
            ],
            [
              -69.363996,
              44.012873
            ],
            [
              -69.3761515503429,
              43.96179246324719
            ],
            [
              -69.366702,
              43.964755
            ],
            [
              -69.362802,
              43.946174
            ],
            [
              -69.375225,
              43.924588
            ],
            [
              -69.384717,
              43.909183
            ],
            [
              -69.383544,
              43.89565
            ],
            [
              -69.37166,
              43.914487
            ],
            [
              -69.340854,
              43.920396
            ],
            [
              -69.340576,
              43.933846
            ],
            [
              -69.327689,
              43.945811
            ],
            [
              -69.322101107392,
              43.9435117843032
            ],
            [
              -69.315372,
              43.940743
            ],
            [
              -69.307776,
              43.943451
            ],
            [
              -69.296077,
              43.932804
            ],
            [
              -69.314666,
              43.917285
            ],
            [
              -69.322935,
              43.901644
            ],
            [
              -69.315245,
              43.882835
            ],
            [
              -69.321443,
              43.866426
            ],
            [
              -69.321031,
              43.856708
            ],
            [
              -69.282501,
              43.864579
            ],
            [
              -69.273949,
              43.914215
            ],
            [
              -69.242812,
              43.918818
            ],
            [
              -69.220195,
              43.914937
            ],
            [
              -69.212939,
              43.921404
            ],
            [
              -69.203668,
              43.941806
            ],
            [
              -69.184724,
              43.959671
            ],
            [
              -69.17498,
              43.976949
            ],
            [
              -69.14622,
              43.981567
            ],
            [
              -69.131536,
              43.976089
            ],
            [
              -69.094999,
              43.979391
            ],
            [
              -69.077028,
              43.973654
            ],
            [
              -69.067156,
              43.990529
            ],
            [
              -69.050347,
              43.99859
            ],
            [
              -69.043912,
              44.006336
            ],
            [
              -69.046954,
              44.013794
            ],
            [
              -69.06620479776579,
              44.0374266789473
            ],
            [
              -69.068112,
              44.039768
            ],
            [
              -69.073767,
              44.046135
            ],
            [
              -69.079805,
              44.055256
            ],
            [
              -69.067876,
              44.067596
            ],
            [
              -69.064299,
              44.069911
            ],
            [
              -69.056093,
              44.06949
            ],
            [
              -69.050622,
              44.068017
            ],
            [
              -69.050566,
              44.063152
            ],
            [
              -69.048917,
              44.062506
            ],
            [
              -69.031878,
              44.079036
            ],
            [
              -69.043403,
              44.092164
            ],
            [
              -69.050814,
              44.094888
            ],
            [
              -69.056303,
              44.095162
            ],
            [
              -69.076452,
              44.090634
            ],
            [
              -69.089078,
              44.085326
            ],
            [
              -69.092,
              44.085734
            ],
            [
              -69.101107,
              44.093601
            ],
            [
              -69.100863,
              44.104529
            ],
            [
              -69.0828618011101,
              44.1161852409527
            ],
            [
              -69.080331,
              44.117824
            ],
            [
              -69.075667,
              44.129991
            ],
            [
              -69.079608,
              44.143962
            ],
            [
              -69.080978,
              44.156768
            ],
            [
              -69.079835,
              44.160953
            ],
            [
              -69.077776,
              44.165043
            ],
            [
              -69.06124,
              44.165498
            ],
            [
              -69.054546,
              44.171542
            ],
            [
              -69.05181,
              44.19592
            ],
            [
              -69.042807,
              44.215173
            ],
            [
              -69.043599,
              44.225029
            ],
            [
              -69.040193,
              44.233673
            ],
            [
              -69.029434,
              44.248558
            ],
            [
              -69.02859006825909,
              44.2491392040609
            ],
            [
              -69.043105,
              44.25955
            ],
            [
              -69.121622,
              44.255644
            ],
            [
              -69.268882,
              44.364653
            ],
            [
              -69.346616,
              44.308337
            ],
            [
              -69.402269,
              44.343059
            ],
            [
              -69.409635,
              44.327998
            ],
            [
              -69.436988,
              44.324485
            ],
            [
              -69.451251,
              44.31395
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "23",
      "COUNTYFP": "011",
      "COUNTYNS": "00581291",
      "AFFGEOID": "0500000US23011",
      "GEOID": "23011",
      "NAME": "Kennebec",
      "LSAD": "06",
      "ALAND": 2246806176,
      "AWATER": 216724439,
      "County_state": "Kennebec,23"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -70.122172,
              44.381131
            ],
            [
              -70.132595,
              44.371382
            ],
            [
              -70.100459,
              44.333254
            ],
            [
              -70.09905,
              44.319547
            ],
            [
              -70.074464,
              44.298471
            ],
            [
              -70.07426,
              44.290154
            ],
            [
              -70.075747,
              44.206168
            ],
            [
              -70.060498,
              44.191775
            ],
            [
              -69.993792,
              44.180594
            ],
            [
              -70.010329,
              44.168651
            ],
            [
              -70.027386,
              44.130577
            ],
            [
              -70.011732,
              44.128011
            ],
            [
              -70.005471,
              44.124519
            ],
            [
              -69.897783,
              44.107662
            ],
            [
              -69.878072,
              44.153371
            ],
            [
              -69.854983,
              44.167862
            ],
            [
              -69.766977,
              44.153759
            ],
            [
              -69.763566,
              44.153217
            ],
            [
              -69.758208,
              44.139334
            ],
            [
              -69.666298,
              44.124003
            ],
            [
              -69.656942,
              44.125461
            ],
            [
              -69.665441,
              44.227576
            ],
            [
              -69.651544,
              44.275008
            ],
            [
              -69.650171,
              44.279625
            ],
            [
              -69.547398,
              44.260988
            ],
            [
              -69.528223,
              44.275447
            ],
            [
              -69.521424,
              44.28056
            ],
            [
              -69.506382,
              44.34287
            ],
            [
              -69.445498,
              44.463839
            ],
            [
              -69.395987,
              44.457078
            ],
            [
              -69.372419,
              44.544585
            ],
            [
              -69.41684,
              44.55108
            ],
            [
              -69.393123,
              44.640375
            ],
            [
              -69.460606,
              44.647054
            ],
            [
              -69.442936,
              44.672381
            ],
            [
              -69.475599,
              44.676678
            ],
            [
              -69.471712,
              44.692899
            ],
            [
              -69.470382,
              44.718161
            ],
            [
              -69.483395,
              44.720271
            ],
            [
              -69.603624,
              44.70462
            ],
            [
              -69.62728,
              44.701216
            ],
            [
              -69.632971,
              44.698763
            ],
            [
              -69.62848,
              44.671969
            ],
            [
              -69.587342,
              44.634076
            ],
            [
              -69.579952,
              44.621466
            ],
            [
              -69.593069,
              44.601341
            ],
            [
              -69.591628,
              44.583415
            ],
            [
              -69.605746,
              44.577264
            ],
            [
              -69.665525,
              44.586402
            ],
            [
              -69.712421,
              44.593679
            ],
            [
              -69.742091,
              44.598246
            ],
            [
              -69.744282,
              44.602607
            ],
            [
              -69.776294,
              44.608236
            ],
            [
              -69.792813,
              44.57733
            ],
            [
              -69.818468,
              44.581312
            ],
            [
              -69.822438,
              44.607585
            ],
            [
              -69.853273,
              44.621506
            ],
            [
              -69.930451,
              44.610852
            ],
            [
              -69.927661,
              44.604123
            ],
            [
              -69.946761,
              44.582346
            ],
            [
              -69.953495,
              44.574688
            ],
            [
              -70.040215,
              44.588722
            ],
            [
              -70.041163,
              44.571253
            ],
            [
              -70.072226,
              44.529324
            ],
            [
              -70.035546,
              44.508083
            ],
            [
              -70.04235,
              44.485235
            ],
            [
              -70.12939,
              44.487217
            ],
            [
              -70.100491,
              44.383854
            ],
            [
              -70.122172,
              44.381131
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "23",
      "COUNTYFP": "019",
      "COUNTYNS": "00581295",
      "AFFGEOID": "0500000US23019",
      "GEOID": "23019",
      "NAME": "Penobscot",
      "LSAD": "06",
      "ALAND": 8798662789,
      "AWATER": 414019982,
      "County_state": "Penobscot,23"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -69.355667,
              45.073469
            ],
            [
              -69.330949,
              44.986901
            ],
            [
              -69.281534,
              44.80962
            ],
            [
              -69.286336,
              44.807988
            ],
            [
              -69.266785,
              44.721928
            ],
            [
              -69.2052,
              44.729586
            ],
            [
              -69.183732,
              44.644023
            ],
            [
              -69.060998,
              44.663946
            ],
            [
              -69.014167,
              44.671794
            ],
            [
              -68.896726,
              44.691724
            ],
            [
              -68.865357,
              44.694217
            ],
            [
              -68.837529,
              44.702125
            ],
            [
              -68.837025,
              44.69233
            ],
            [
              -68.815793,
              44.683754
            ],
            [
              -68.797799,
              44.688828
            ],
            [
              -68.766336,
              44.675706
            ],
            [
              -68.771901,
              44.664448
            ],
            [
              -68.743953,
              44.660579
            ],
            [
              -68.720777,
              44.699667
            ],
            [
              -68.713867,
              44.703624
            ],
            [
              -68.69093,
              44.721668
            ],
            [
              -68.647134,
              44.703636
            ],
            [
              -68.616673,
              44.740992
            ],
            [
              -68.528542,
              44.752167
            ],
            [
              -68.445219,
              44.763014
            ],
            [
              -68.454889,
              44.80155
            ],
            [
              -68.473014,
              44.870763
            ],
            [
              -68.477952,
              44.889091
            ],
            [
              -68.491837,
              44.944026
            ],
            [
              -68.501095,
              44.980718
            ],
            [
              -68.375892,
              44.997373
            ],
            [
              -68.396903,
              45.083296
            ],
            [
              -68.270497,
              45.100121
            ],
            [
              -68.295438,
              45.190443
            ],
            [
              -68.303714,
              45.221531
            ],
            [
              -68.054985,
              45.252577
            ],
            [
              -67.93976,
              45.267845
            ],
            [
              -67.966881,
              45.351879
            ],
            [
              -67.957928,
              45.353247
            ],
            [
              -68.048012,
              45.639897
            ],
            [
              -68.260136,
              45.604483
            ],
            [
              -68.257502,
              45.597949
            ],
            [
              -68.410884,
              45.573337
            ],
            [
              -68.430853,
              45.578084
            ],
            [
              -68.433001,
              45.581204
            ],
            [
              -68.432463,
              45.667827
            ],
            [
              -68.427551,
              45.759867
            ],
            [
              -68.426621,
              45.85338
            ],
            [
              -68.426169,
              45.870829
            ],
            [
              -68.427598,
              45.944591
            ],
            [
              -68.431682,
              45.952442
            ],
            [
              -68.432273,
              46.035568
            ],
            [
              -68.436902,
              46.04289
            ],
            [
              -68.436285,
              46.124744
            ],
            [
              -68.436443,
              46.382121
            ],
            [
              -68.694877,
              46.382612
            ],
            [
              -68.69492,
              46.395505
            ],
            [
              -68.819401,
              46.395782
            ],
            [
              -68.826558,
              45.715986
            ],
            [
              -68.827039,
              45.684826
            ],
            [
              -68.95891,
              45.662195
            ],
            [
              -68.950699,
              45.638434
            ],
            [
              -68.951719,
              45.581782
            ],
            [
              -68.964653,
              45.512367
            ],
            [
              -68.857297,
              45.527301
            ],
            [
              -68.831206,
              45.44243
            ],
            [
              -68.776061,
              45.240317
            ],
            [
              -68.880923,
              45.224947
            ],
            [
              -68.856587,
              45.142783
            ],
            [
              -68.983599,
              45.125841
            ],
            [
              -69.232021,
              45.090166
            ],
            [
              -69.355667,
              45.073469
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "23",
      "COUNTYFP": "021",
      "COUNTYNS": "00581296",
      "AFFGEOID": "0500000US23021",
      "GEOID": "23021",
      "NAME": "Piscataquis",
      "LSAD": "06",
      "ALAND": 10259016062,
      "AWATER": 1080049539,
      "County_state": "Piscataquis,23"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -69.824484,
              45.742179
            ],
            [
              -69.791909,
              45.725682
            ],
            [
              -69.804603,
              45.716295
            ],
            [
              -69.790244,
              45.710342
            ],
            [
              -69.785257,
              45.69278
            ],
            [
              -69.754726,
              45.679413
            ],
            [
              -69.738487,
              45.679033
            ],
            [
              -69.743752,
              45.652005
            ],
            [
              -69.723568,
              45.657386
            ],
            [
              -69.691808,
              45.644941
            ],
            [
              -69.71198,
              45.65043
            ],
            [
              -69.718271,
              45.639751
            ],
            [
              -69.701688,
              45.627982
            ],
            [
              -69.720791,
              45.602183
            ],
            [
              -69.713481,
              45.595622
            ],
            [
              -69.731534,
              45.566912
            ],
            [
              -69.779928,
              45.542766
            ],
            [
              -69.708324,
              45.291354
            ],
            [
              -69.683646,
              45.212399
            ],
            [
              -69.6828,
              45.194878
            ],
            [
              -69.65476,
              45.099989
            ],
            [
              -69.620967,
              45.010956
            ],
            [
              -69.515649,
              45.025043
            ],
            [
              -69.495192,
              45.037352
            ],
            [
              -69.499861,
              45.053778
            ],
            [
              -69.355667,
              45.073469
            ],
            [
              -69.232021,
              45.090166
            ],
            [
              -68.983599,
              45.125841
            ],
            [
              -68.856587,
              45.142783
            ],
            [
              -68.880923,
              45.224947
            ],
            [
              -68.776061,
              45.240317
            ],
            [
              -68.831206,
              45.44243
            ],
            [
              -68.857297,
              45.527301
            ],
            [
              -68.964653,
              45.512367
            ],
            [
              -68.951719,
              45.581782
            ],
            [
              -68.950699,
              45.638434
            ],
            [
              -68.95891,
              45.662195
            ],
            [
              -68.827039,
              45.684826
            ],
            [
              -68.826558,
              45.715986
            ],
            [
              -68.819401,
              46.395782
            ],
            [
              -68.821414,
              46.57251
            ],
            [
              -69.721109,
              46.574053
            ],
            [
              -69.719246,
              46.394109
            ],
            [
              -69.73232,
              46.394008
            ],
            [
              -69.728577,
              45.976882
            ],
            [
              -69.683564,
              45.983632
            ],
            [
              -69.647025,
              45.863053
            ],
            [
              -69.673449,
              45.862406
            ],
            [
              -69.683391,
              45.850965
            ],
            [
              -69.702607,
              45.877099
            ],
            [
              -69.697985,
              45.885723
            ],
            [
              -69.738216,
              45.883026
            ],
            [
              -69.705743,
              45.853975
            ],
            [
              -69.718472,
              45.829923
            ],
            [
              -69.708642,
              45.811677
            ],
            [
              -69.722876,
              45.783263
            ],
            [
              -69.737667,
              45.782781
            ],
            [
              -69.7266,
              45.762051
            ],
            [
              -69.740827,
              45.755511
            ],
            [
              -69.768523,
              45.761322
            ],
            [
              -69.786639,
              45.784903
            ],
            [
              -69.801994,
              45.786191
            ],
            [
              -69.785908,
              45.783643
            ],
            [
              -69.78223,
              45.769537
            ],
            [
              -69.793175,
              45.761554
            ],
            [
              -69.788245,
              45.746778
            ],
            [
              -69.824484,
              45.742179
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "23",
      "COUNTYFP": "015",
      "COUNTYNS": "00581293",
      "AFFGEOID": "0500000US23015",
      "GEOID": "23015",
      "NAME": "Lincoln",
      "LSAD": "06",
      "ALAND": 1180630792,
      "AWATER": 631357625,
      "County_state": "Lincoln,23"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -69.332475,
              43.767908
            ],
            [
              -69.333663,
              43.757609
            ],
            [
              -69.309895,
              43.752459
            ],
            [
              -69.298011,
              43.760184
            ],
            [
              -69.293258,
              43.771341
            ],
            [
              -69.307518,
              43.778206
            ],
            [
              -69.320591,
              43.775632
            ],
            [
              -69.332475,
              43.767908
            ]
          ]
        ],
        [
          [
            [
              -69.790306,
              44.042466
            ],
            [
              -69.813549,
              44.026011
            ],
            [
              -69.754381,
              44.016009
            ],
            [
              -69.746329,
              44.013802
            ],
            [
              -69.700152,
              44.006585
            ],
            [
              -69.719628,
              43.971287
            ],
            [
              -69.721218,
              43.9315
            ],
            [
              -69.707421,
              43.927251
            ],
            [
              -69.743385,
              43.893241
            ],
            [
              -69.718399,
              43.850588
            ],
            [
              -69.692076,
              43.857175
            ],
            [
              -69.6978577217234,
              43.824918144431
            ],
            [
              -69.697239,
              43.825065
            ],
            [
              -69.692429,
              43.824336
            ],
            [
              -69.685579,
              43.820546
            ],
            [
              -69.685473,
              43.816328
            ],
            [
              -69.664922,
              43.791033
            ],
            [
              -69.658189,
              43.779163
            ],
            [
              -69.648616,
              43.791206
            ],
            [
              -69.650818,
              43.803785
            ],
            [
              -69.65315,
              43.817194
            ],
            [
              -69.649798,
              43.836287
            ],
            [
              -69.634932,
              43.845907
            ],
            [
              -69.629685,
              43.843429
            ],
            [
              -69.630268,
              43.837016
            ],
            [
              -69.621086,
              43.826814
            ],
            [
              -69.61511,
              43.831623
            ],
            [
              -69.613069,
              43.837453
            ],
            [
              -69.613215,
              43.845032
            ],
            [
              -69.604616,
              43.858004
            ],
            [
              -69.594705,
              43.858878
            ],
            [
              -69.589167,
              43.851299
            ],
            [
              -69.592373,
              43.830895
            ],
            [
              -69.598495,
              43.825502
            ],
            [
              -69.604616,
              43.825793
            ],
            [
              -69.605928,
              43.814862
            ],
            [
              -69.604179,
              43.813551
            ],
            [
              -69.588551,
              43.81836
            ],
            [
              -69.578527,
              43.823316
            ],
            [
              -69.575466,
              43.841972
            ],
            [
              -69.572697,
              43.844012
            ],
            [
              -69.568325,
              43.844449
            ],
            [
              -69.558122,
              43.84066
            ],
            [
              -69.552606,
              43.841347
            ],
            [
              -69.545028,
              43.861241
            ],
            [
              -69.550616,
              43.872579
            ],
            [
              -69.550908,
              43.877971
            ],
            [
              -69.54945,
              43.880012
            ],
            [
              -69.543912,
              43.881615
            ],
            [
              -69.5437920851829,
              43.8815777521728
            ],
            [
              -69.524673,
              43.875639
            ],
            [
              -69.520301,
              43.868498
            ],
            [
              -69.513267,
              43.84479
            ],
            [
              -69.516212,
              43.837222
            ],
            [
              -69.514889,
              43.831298
            ],
            [
              -69.50329,
              43.837673
            ],
            [
              -69.486243,
              43.869118
            ],
            [
              -69.483498,
              43.88028
            ],
            [
              -69.438066,
              43.909539
            ],
            [
              -69.427277,
              43.912261
            ],
            [
              -69.422048,
              43.923047
            ],
            [
              -69.420146,
              43.935885
            ],
            [
              -69.428227,
              43.957276
            ],
            [
              -69.393288,
              43.95642
            ],
            [
              -69.3761515503429,
              43.96179246324719
            ],
            [
              -69.363996,
              44.012873
            ],
            [
              -69.346093,
              44.018808
            ],
            [
              -69.327832,
              44.006185
            ],
            [
              -69.320379,
              44.033414
            ],
            [
              -69.282268,
              44.052584
            ],
            [
              -69.282225,
              44.054772
            ],
            [
              -69.311502,
              44.09549
            ],
            [
              -69.303661,
              44.132677
            ],
            [
              -69.321529,
              44.1729
            ],
            [
              -69.337672,
              44.20937
            ],
            [
              -69.372175,
              44.205405
            ],
            [
              -69.40602,
              44.210674
            ],
            [
              -69.403633,
              44.219145
            ],
            [
              -69.432837,
              44.22674
            ],
            [
              -69.449481,
              44.26227
            ],
            [
              -69.440665,
              44.284434
            ],
            [
              -69.451251,
              44.31395
            ],
            [
              -69.436988,
              44.324485
            ],
            [
              -69.409635,
              44.327998
            ],
            [
              -69.506382,
              44.34287
            ],
            [
              -69.521424,
              44.28056
            ],
            [
              -69.528223,
              44.275447
            ],
            [
              -69.547398,
              44.260988
            ],
            [
              -69.650171,
              44.279625
            ],
            [
              -69.651544,
              44.275008
            ],
            [
              -69.665441,
              44.227576
            ],
            [
              -69.656942,
              44.125461
            ],
            [
              -69.666298,
              44.124003
            ],
            [
              -69.758208,
              44.139334
            ],
            [
              -69.774175,
              44.099351
            ],
            [
              -69.782868,
              44.090661
            ],
            [
              -69.790306,
              44.042466
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "23",
      "COUNTYFP": "007",
      "COUNTYNS": "00581289",
      "AFFGEOID": "0500000US23007",
      "GEOID": "23007",
      "NAME": "Franklin",
      "LSAD": "06",
      "ALAND": 4395190350,
      "AWATER": 121369621,
      "County_state": "Franklin,23"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -70.831535,
              45.279557
            ],
            [
              -70.8347140662076,
              45.2751422184342
            ],
            [
              -70.779842,
              45.018379
            ],
            [
              -70.823634,
              45.01379
            ],
            [
              -70.804537,
              44.924934
            ],
            [
              -70.796412,
              44.925877
            ],
            [
              -70.773902,
              44.839777
            ],
            [
              -70.769228,
              44.732228
            ],
            [
              -70.61503,
              44.795634
            ],
            [
              -70.563799,
              44.713193
            ],
            [
              -70.510598,
              44.627216
            ],
            [
              -70.549985,
              44.610727
            ],
            [
              -70.34758,
              44.580175
            ],
            [
              -70.280748,
              44.569619
            ],
            [
              -70.307659,
              44.537876
            ],
            [
              -70.289888,
              44.540248
            ],
            [
              -70.280908,
              44.505095
            ],
            [
              -70.250895,
              44.503402
            ],
            [
              -70.238904,
              44.458942
            ],
            [
              -70.201276,
              44.477976
            ],
            [
              -70.12939,
              44.487217
            ],
            [
              -70.04235,
              44.485235
            ],
            [
              -70.035546,
              44.508083
            ],
            [
              -70.072226,
              44.529324
            ],
            [
              -70.041163,
              44.571253
            ],
            [
              -70.040215,
              44.588722
            ],
            [
              -69.953495,
              44.574688
            ],
            [
              -69.946761,
              44.582346
            ],
            [
              -69.927661,
              44.604123
            ],
            [
              -69.930451,
              44.610852
            ],
            [
              -69.947081,
              44.648022
            ],
            [
              -69.968768,
              44.661339
            ],
            [
              -69.960149,
              44.681226
            ],
            [
              -69.996191,
              44.677497
            ],
            [
              -70.002986,
              44.708855
            ],
            [
              -70.013529,
              44.757699
            ],
            [
              -70.00066,
              44.759043
            ],
            [
              -70.000831,
              44.773817
            ],
            [
              -70.003154,
              44.78829
            ],
            [
              -70.015597,
              44.786947
            ],
            [
              -70.03327,
              44.86454
            ],
            [
              -70.12993,
              44.850765
            ],
            [
              -70.15211,
              44.941847
            ],
            [
              -70.110365,
              44.947311
            ],
            [
              -70.122429,
              45.000062
            ],
            [
              -70.159375,
              45.128396
            ],
            [
              -70.293826,
              45.109903
            ],
            [
              -70.312485,
              45.164681
            ],
            [
              -70.418519,
              45.144005
            ],
            [
              -70.518696,
              45.512127
            ],
            [
              -70.505775,
              45.513774
            ],
            [
              -70.5528309091743,
              45.6678281233836
            ],
            [
              -70.5584,
              45.666671
            ],
            [
              -70.563779,
              45.662693
            ],
            [
              -70.563747,
              45.661939
            ],
            [
              -70.562019,
              45.660591
            ],
            [
              -70.564789,
              45.65515
            ],
            [
              -70.591275,
              45.630551
            ],
            [
              -70.592252,
              45.629865
            ],
            [
              -70.601275,
              45.627165
            ],
            [
              -70.606193,
              45.626386
            ],
            [
              -70.617198,
              45.616379
            ],
            [
              -70.635306,
              45.610211
            ],
            [
              -70.644687,
              45.607083
            ],
            [
              -70.650424,
              45.599267
            ],
            [
              -70.649578,
              45.598147
            ],
            [
              -70.659286,
              45.58688
            ],
            [
              -70.678462,
              45.570677
            ],
            [
              -70.685686,
              45.565419
            ],
            [
              -70.688214,
              45.563981
            ],
            [
              -70.687605,
              45.549099
            ],
            [
              -70.700613,
              45.531543
            ],
            [
              -70.702433,
              45.529554
            ],
            [
              -70.716606,
              45.518488
            ],
            [
              -70.721611,
              45.515058
            ],
            [
              -70.722845,
              45.512772
            ],
            [
              -70.723396,
              45.510394
            ],
            [
              -70.723167,
              45.507606
            ],
            [
              -70.717047,
              45.487732
            ],
            [
              -70.707945,
              45.480877
            ],
            [
              -70.70463,
              45.479575
            ],
            [
              -70.691762,
              45.471233
            ],
            [
              -70.681074,
              45.45841
            ],
            [
              -70.674903,
              45.452399
            ],
            [
              -70.662954,
              45.446546
            ],
            [
              -70.649739,
              45.442771
            ],
            [
              -70.635498,
              45.427817
            ],
            [
              -70.631354,
              45.41634
            ],
            [
              -70.634661,
              45.383608
            ],
            [
              -70.645952,
              45.378675
            ],
            [
              -70.651175,
              45.377123
            ],
            [
              -70.660029,
              45.377901
            ],
            [
              -70.660775,
              45.378176
            ],
            [
              -70.66116,
              45.386039
            ],
            [
              -70.677995,
              45.394362
            ],
            [
              -70.683543,
              45.395208
            ],
            [
              -70.684614,
              45.395071
            ],
            [
              -70.687307,
              45.393243
            ],
            [
              -70.692497,
              45.392579
            ],
            [
              -70.712286,
              45.390611
            ],
            [
              -70.722734,
              45.39527
            ],
            [
              -70.729972,
              45.399359
            ],
            [
              -70.743775,
              45.411925
            ],
            [
              -70.744787,
              45.417182
            ],
            [
              -70.744077,
              45.421091
            ],
            [
              -70.753977,
              45.427789
            ],
            [
              -70.755567,
              45.428361
            ],
            [
              -70.781471,
              45.431159
            ],
            [
              -70.795009,
              45.428145
            ],
            [
              -70.798028,
              45.426706
            ],
            [
              -70.798677,
              45.424146
            ],
            [
              -70.802216,
              45.417975
            ],
            [
              -70.812797,
              45.411369
            ],
            [
              -70.825612,
              45.400305
            ],
            [
              -70.826033,
              45.398408
            ],
            [
              -70.825156,
              45.393265
            ],
            [
              -70.824053,
              45.391094
            ],
            [
              -70.822268,
              45.390522
            ],
            [
              -70.812406,
              45.383346
            ],
            [
              -70.807664,
              45.378118
            ],
            [
              -70.806244,
              45.376558
            ],
            [
              -70.802745,
              45.366556
            ],
            [
              -70.802648,
              45.364933
            ],
            [
              -70.803848,
              45.364247
            ],
            [
              -70.807577,
              45.363425
            ],
            [
              -70.808712,
              45.362647
            ],
            [
              -70.81445,
              45.356544
            ],
            [
              -70.819471,
              45.341435
            ],
            [
              -70.819828,
              45.340109
            ],
            [
              -70.819049,
              45.335057
            ],
            [
              -70.818141,
              45.332589
            ],
            [
              -70.816585,
              45.330554
            ],
            [
              -70.813021,
              45.328018
            ],
            [
              -70.810266,
              45.327744
            ],
            [
              -70.808322,
              45.325824
            ],
            [
              -70.807058,
              45.322464
            ],
            [
              -70.808613,
              45.311606
            ],
            [
              -70.812338,
              45.302006
            ],
            [
              -70.816224,
              45.29812
            ],
            [
              -70.817099,
              45.297777
            ],
            [
              -70.829661,
              45.290369
            ],
            [
              -70.82979,
              45.286941
            ],
            [
              -70.831535,
              45.279557
            ]
          ]
        ]
      ]
    }
  }
]
};