export const counties_ne = {
  "type":"FeatureCollection","name":"ne_counties","features":[
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "029",
      "COUNTYNS": "00835836",
      "AFFGEOID": "0500000US31029",
      "GEOID": "31029",
      "NAME": "Chase",
      "LSAD": "06",
      "ALAND": 2316533465,
      "AWATER": 7978174,
      "County_state": "Chase,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -102.05184,
              40.396396
            ],
            [
              -102.051572,
              40.39308
            ],
            [
              -102.051798,
              40.360069
            ],
            [
              -102.051553251676,
              40.3492139863953
            ],
            [
              -101.342788,
              40.350444
            ],
            [
              -101.345365,
              40.698093
            ],
            [
              -102.05139843250801,
              40.697541816873404
            ],
            [
              -102.051725,
              40.537839
            ],
            [
              -102.051519,
              40.520094
            ],
            [
              -102.051465,
              40.440008
            ],
            [
              -102.05184,
              40.396396
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "125",
      "COUNTYNS": "00835884",
      "AFFGEOID": "0500000US31125",
      "GEOID": "31125",
      "NAME": "Nance",
      "LSAD": "06",
      "ALAND": 1143826263,
      "AWATER": 17085923,
      "County_state": "Nance,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.291344,
              41.480727
            ],
            [
              -98.291419,
              41.393894
            ],
            [
              -98.286584,
              41.393898
            ],
            [
              -98.273892,
              41.393855
            ],
            [
              -98.275408,
              41.264341
            ],
            [
              -98.101546,
              41.264041
            ],
            [
              -98.10166,
              41.278654
            ],
            [
              -98.053133,
              41.278532
            ],
            [
              -98.053137,
              41.293123
            ],
            [
              -97.897943,
              41.29363
            ],
            [
              -97.898268,
              41.308224
            ],
            [
              -97.705497,
              41.309134
            ],
            [
              -97.703765,
              41.394876
            ],
            [
              -97.703627,
              41.526796
            ],
            [
              -97.829846,
              41.526174
            ],
            [
              -97.885492,
              41.525868
            ],
            [
              -97.885339,
              41.511427
            ],
            [
              -98.041328,
              41.510534
            ],
            [
              -98.04146,
              41.495988
            ],
            [
              -98.099797,
              41.495389
            ],
            [
              -98.099523,
              41.480777
            ],
            [
              -98.291344,
              41.480727
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "035",
      "COUNTYNS": "00835839",
      "AFFGEOID": "0500000US31035",
      "GEOID": "31035",
      "NAME": "Clay",
      "LSAD": "06",
      "ALAND": 1482222903,
      "AWATER": 3234878,
      "County_state": "Clay,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.278103,
              40.698291
            ],
            [
              -98.27809,
              40.350347
            ],
            [
              -98.273571,
              40.350359
            ],
            [
              -97.824114,
              40.350541
            ],
            [
              -97.82478,
              40.698537
            ],
            [
              -97.825723,
              40.698555
            ],
            [
              -98.278103,
              40.698291
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "171",
      "COUNTYNS": "00835907",
      "AFFGEOID": "0500000US31171",
      "GEOID": "31171",
      "NAME": "Thomas",
      "LSAD": "06",
      "ALAND": 1846403658,
      "AWATER": 2028358,
      "County_state": "Thomas,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.846129,
              42.088166
            ],
            [
              -100.842459,
              41.740404
            ],
            [
              -100.711711,
              41.739761
            ],
            [
              -100.265474,
              41.739856
            ],
            [
              -100.267622,
              42.086147
            ],
            [
              -100.846129,
              42.088166
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "071",
      "COUNTYNS": "00835857",
      "AFFGEOID": "0500000US31071",
      "GEOID": "31071",
      "NAME": "Garfield",
      "LSAD": "06",
      "ALAND": 1474558345,
      "AWATER": 5321554,
      "County_state": "Garfield,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.222733,
              42.087916
            ],
            [
              -99.22183,
              41.740792
            ],
            [
              -99.212016,
              41.740613
            ],
            [
              -98.759133,
              41.740382
            ],
            [
              -98.761155,
              42.088518
            ],
            [
              -99.222733,
              42.087916
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "041",
      "COUNTYNS": "00835842",
      "AFFGEOID": "0500000US31041",
      "GEOID": "31041",
      "NAME": "Custer",
      "LSAD": "06",
      "ALAND": 6670757281,
      "AWATER": 932956,
      "County_state": "Custer,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.251224,
              41.739817
            ],
            [
              -100.251098,
              41.393298
            ],
            [
              -100.236106,
              41.393324
            ],
            [
              -100.238155,
              41.046536
            ],
            [
              -100.224235,
              41.046597
            ],
            [
              -99.425986,
              41.046471
            ],
            [
              -99.207564,
              41.047003
            ],
            [
              -99.203261,
              41.39414
            ],
            [
              -99.212465,
              41.394235
            ],
            [
              -99.212016,
              41.740613
            ],
            [
              -99.22183,
              41.740792
            ],
            [
              -99.686834,
              41.740326
            ],
            [
              -100.002413,
              41.738459
            ],
            [
              -100.251224,
              41.739817
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "003",
      "COUNTYNS": "00835824",
      "AFFGEOID": "0500000US31003",
      "GEOID": "31003",
      "NAME": "Antelope",
      "LSAD": "06",
      "ALAND": 2220183804,
      "AWATER": 3558463,
      "County_state": "Antelope,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.300235,
              42.436913
            ],
            [
              -98.300592,
              42.088853
            ],
            [
              -98.295423,
              42.081436
            ],
            [
              -98.295602,
              41.914954
            ],
            [
              -97.833288,
              41.916286
            ],
            [
              -97.834536,
              42.08975
            ],
            [
              -97.834431,
              42.437684
            ],
            [
              -98.300235,
              42.436913
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "093",
      "COUNTYNS": "00835868",
      "AFFGEOID": "0500000US31093",
      "GEOID": "31093",
      "NAME": "Howard",
      "LSAD": "06",
      "ALAND": 1474578440,
      "AWATER": 16199404,
      "County_state": "Howard,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.74433,
              41.394178
            ],
            [
              -98.748526,
              41.04664
            ],
            [
              -98.721975,
              41.046674
            ],
            [
              -98.49279,
              41.046492
            ],
            [
              -98.287168,
              41.046363
            ],
            [
              -98.286584,
              41.393898
            ],
            [
              -98.291419,
              41.393894
            ],
            [
              -98.74433,
              41.394178
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "005",
      "COUNTYNS": "00835825",
      "AFFGEOID": "0500000US31005",
      "GEOID": "31005",
      "NAME": "Arthur",
      "LSAD": "06",
      "ALAND": 1852759732,
      "AWATER": 7644719,
      "County_state": "Arthur,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -101.985523,
              41.74229
            ],
            [
              -101.98463,
              41.394712
            ],
            [
              -101.40726,
              41.395416
            ],
            [
              -101.406466,
              41.7432
            ],
            [
              -101.42592,
              41.743059
            ],
            [
              -101.985523,
              41.74229
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "143",
      "COUNTYNS": "00835893",
      "AFFGEOID": "0500000US31143",
      "GEOID": "31143",
      "NAME": "Polk",
      "LSAD": "06",
      "ALAND": 1135309852,
      "AWATER": 5947394,
      "County_state": "Polk,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.828256,
              41.173439
            ],
            [
              -97.827957,
              41.050115
            ],
            [
              -97.826287,
              41.046581
            ],
            [
              -97.368118,
              41.046947
            ],
            [
              -97.368186,
              41.396416
            ],
            [
              -97.450149,
              41.36878
            ],
            [
              -97.522412,
              41.361934
            ],
            [
              -97.570595,
              41.337282
            ],
            [
              -97.598253,
              41.333119
            ],
            [
              -97.598205,
              41.317247
            ],
            [
              -97.630991,
              41.306745
            ],
            [
              -97.658554,
              41.304862
            ],
            [
              -97.711847,
              41.267207
            ],
            [
              -97.765999,
              41.23489
            ],
            [
              -97.800529,
              41.193314
            ],
            [
              -97.828256,
              41.173439
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "159",
      "COUNTYNS": "00835901",
      "AFFGEOID": "0500000US31159",
      "GEOID": "31159",
      "NAME": "Seward",
      "LSAD": "06",
      "ALAND": 1479990669,
      "AWATER": 11547536,
      "County_state": "Seward,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.368118,
              41.046947
            ],
            [
              -97.368401,
              40.698625
            ],
            [
              -96.913493,
              40.697948
            ],
            [
              -96.911116,
              40.697975
            ],
            [
              -96.91069,
              40.813923
            ],
            [
              -96.91094,
              41.046116
            ],
            [
              -97.368118,
              41.046947
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "137",
      "COUNTYNS": "00835890",
      "AFFGEOID": "0500000US31137",
      "GEOID": "31137",
      "NAME": "Phelps",
      "LSAD": "06",
      "ALAND": 1398048570,
      "AWATER": 1646534,
      "County_state": "Phelps,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.643462,
              40.684911
            ],
            [
              -99.642209,
              40.3512
            ],
            [
              -99.630459,
              40.35112
            ],
            [
              -99.179403,
              40.350681
            ],
            [
              -99.179248,
              40.660541
            ],
            [
              -99.244659,
              40.659391
            ],
            [
              -99.302596,
              40.664141
            ],
            [
              -99.331627,
              40.671439
            ],
            [
              -99.376036,
              40.664262
            ],
            [
              -99.411674,
              40.66757
            ],
            [
              -99.417316,
              40.670825
            ],
            [
              -99.444401,
              40.677892
            ],
            [
              -99.528563,
              40.675128
            ],
            [
              -99.543078,
              40.680806
            ],
            [
              -99.583465,
              40.677194
            ],
            [
              -99.643462,
              40.684911
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "039",
      "COUNTYNS": "00835841",
      "AFFGEOID": "0500000US31039",
      "GEOID": "31039",
      "NAME": "Cuming",
      "LSAD": "06",
      "ALAND": 1477641638,
      "AWATER": 10701538,
      "County_state": "Cuming,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.019359,
              42.090577
            ],
            [
              -97.019911,
              41.74298
            ],
            [
              -96.905922,
              41.742763
            ],
            [
              -96.555172,
              41.742018
            ],
            [
              -96.554866,
              42.015875
            ],
            [
              -96.555511,
              42.089957
            ],
            [
              -96.82367,
              42.090411
            ],
            [
              -97.019359,
              42.090577
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "087",
      "COUNTYNS": "00835865",
      "AFFGEOID": "0500000US31087",
      "GEOID": "31087",
      "NAME": "Hitchcock",
      "LSAD": "06",
      "ALAND": 1838742723,
      "AWATER": 22103380,
      "County_state": "Hitchcock,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -101.324036,
              40.00271
            ],
            [
              -101.32551390689501,
              40.0026868921426
            ],
            [
              -101.324036,
              40.002696
            ],
            [
              -101.293991,
              40.002559
            ],
            [
              -101.286555,
              40.002559
            ],
            [
              -101.248673,
              40.002543
            ],
            [
              -101.215033,
              40.002555
            ],
            [
              -101.192219,
              40.002491
            ],
            [
              -101.178805,
              40.002468
            ],
            [
              -101.168704,
              40.002547
            ],
            [
              -101.130907,
              40.002427
            ],
            [
              -101.060317,
              40.002307
            ],
            [
              -101.027686,
              40.002256
            ],
            [
              -100.937427,
              40.002145
            ],
            [
              -100.75883,
              40.002302
            ],
            [
              -100.758497,
              40.156303
            ],
            [
              -100.761019,
              40.174924
            ],
            [
              -100.758435,
              40.349507
            ],
            [
              -100.777376,
              40.349466
            ],
            [
              -101.323353,
              40.350553
            ],
            [
              -101.325042,
              40.329456
            ],
            [
              -101.324036,
              40.00271
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "167",
      "COUNTYNS": "00835905",
      "AFFGEOID": "0500000US31167",
      "GEOID": "31167",
      "NAME": "Stanton",
      "LSAD": "06",
      "ALAND": 1107496313,
      "AWATER": 8793984,
      "County_state": "Stanton,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.368404,
              42.090922
            ],
            [
              -97.368237,
              42.003966
            ],
            [
              -97.36807,
              41.743207
            ],
            [
              -97.252425,
              41.743085
            ],
            [
              -97.058546,
              41.742998
            ],
            [
              -97.019911,
              41.74298
            ],
            [
              -97.019359,
              42.090577
            ],
            [
              -97.252433,
              42.090802
            ],
            [
              -97.368404,
              42.090922
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "179",
      "COUNTYNS": "00835911",
      "AFFGEOID": "0500000US31179",
      "GEOID": "31179",
      "NAME": "Wayne",
      "LSAD": "06",
      "ALAND": 1147143905,
      "AWATER": 1087702,
      "County_state": "Wayne,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.367724,
              42.351861
            ],
            [
              -97.368404,
              42.090922
            ],
            [
              -97.252433,
              42.090802
            ],
            [
              -97.019359,
              42.090577
            ],
            [
              -96.82367,
              42.090411
            ],
            [
              -96.822497,
              42.264292
            ],
            [
              -96.823077,
              42.264292
            ],
            [
              -97.01773,
              42.264453
            ],
            [
              -97.017789,
              42.351347
            ],
            [
              -97.367724,
              42.351861
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "001",
      "COUNTYNS": "00835823",
      "AFFGEOID": "0500000US31001",
      "GEOID": "31001",
      "NAME": "Adams",
      "LSAD": "06",
      "ALAND": 1458869099,
      "AWATER": 2481410,
      "County_state": "Adams,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.724387,
              40.689772
            ],
            [
              -98.723948,
              40.350391
            ],
            [
              -98.27809,
              40.350347
            ],
            [
              -98.278103,
              40.698291
            ],
            [
              -98.282851,
              40.698284
            ],
            [
              -98.72143,
              40.698903
            ],
            [
              -98.724387,
              40.689772
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "073",
      "COUNTYNS": "00835858",
      "AFFGEOID": "0500000US31073",
      "GEOID": "31073",
      "NAME": "Gosper",
      "LSAD": "06",
      "ALAND": 1186616240,
      "AWATER": 11831826,
      "County_state": "Gosper,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.095095,
              40.438656
            ],
            [
              -100.094798,
              40.351245
            ],
            [
              -99.642209,
              40.3512
            ],
            [
              -99.643462,
              40.684911
            ],
            [
              -99.699269,
              40.699969
            ],
            [
              -99.981743,
              40.700003
            ],
            [
              -99.981193,
              40.438291
            ],
            [
              -100.095095,
              40.438656
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "059",
      "COUNTYNS": "00835851",
      "AFFGEOID": "0500000US31059",
      "GEOID": "31059",
      "NAME": "Fillmore",
      "LSAD": "06",
      "ALAND": 1490202783,
      "AWATER": 2989990,
      "County_state": "Fillmore,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.82478,
              40.698537
            ],
            [
              -97.824114,
              40.350541
            ],
            [
              -97.820816,
              40.350545
            ],
            [
              -97.368688,
              40.350392
            ],
            [
              -97.368401,
              40.698625
            ],
            [
              -97.82478,
              40.698537
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "155",
      "COUNTYNS": "00835899",
      "AFFGEOID": "0500000US31155",
      "GEOID": "31155",
      "NAME": "Saunders",
      "LSAD": "06",
      "ALAND": 1943097562,
      "AWATER": 24827641,
      "County_state": "Saunders,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.908233,
              41.394094
            ],
            [
              -96.908507,
              41.046091
            ],
            [
              -96.60111,
              41.045444
            ],
            [
              -96.463869,
              41.045083
            ],
            [
              -96.463861,
              41.016069
            ],
            [
              -96.349471,
              41.015211
            ],
            [
              -96.349079,
              41.044525
            ],
            [
              -96.319191,
              41.044998
            ],
            [
              -96.339805,
              41.082502
            ],
            [
              -96.33287,
              41.099622
            ],
            [
              -96.310983,
              41.115338
            ],
            [
              -96.316296,
              41.125199
            ],
            [
              -96.31944,
              41.129799
            ],
            [
              -96.315929,
              41.143955
            ],
            [
              -96.327399,
              41.189999
            ],
            [
              -96.350676,
              41.204191
            ],
            [
              -96.365558,
              41.234136
            ],
            [
              -96.350807,
              41.256248
            ],
            [
              -96.350598,
              41.265192
            ],
            [
              -96.377914,
              41.298298
            ],
            [
              -96.407608,
              41.314614
            ],
            [
              -96.406973,
              41.334308
            ],
            [
              -96.421439,
              41.364165
            ],
            [
              -96.459336,
              41.38014
            ],
            [
              -96.47072,
              41.393239
            ],
            [
              -96.503558,
              41.396385
            ],
            [
              -96.519431,
              41.407837
            ],
            [
              -96.568677,
              41.432986
            ],
            [
              -96.634,
              41.434364
            ],
            [
              -96.691344,
              41.425663
            ],
            [
              -96.710436,
              41.430806
            ],
            [
              -96.746722,
              41.449749
            ],
            [
              -96.773397,
              41.450129
            ],
            [
              -96.819118,
              41.440183
            ],
            [
              -96.837655,
              41.445917
            ],
            [
              -96.905862,
              41.453389
            ],
            [
              -96.905788,
              41.397323
            ],
            [
              -96.908233,
              41.394094
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "045",
      "COUNTYNS": "00835844",
      "AFFGEOID": "0500000US31045",
      "GEOID": "31045",
      "NAME": "Dawes",
      "LSAD": "06",
      "ALAND": 3616823834,
      "AWATER": 11921995,
      "County_state": "Dawes,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -103.505099997425,
              43.0007699609789
            ],
            [
              -103.503232,
              42.784787
            ],
            [
              -103.480403,
              42.784966
            ],
            [
              -103.480082,
              42.437267
            ],
            [
              -103.444532,
              42.437354
            ],
            [
              -102.773366,
              42.439922
            ],
            [
              -102.773369,
              42.785265
            ],
            [
              -102.792659,
              42.785165
            ],
            [
              -102.792111,
              42.99998
            ],
            [
              -103.000609657376,
              43.000472962679495
            ],
            [
              -103.13174,
              43.000783
            ],
            [
              -103.132955,
              43.000784
            ],
            [
              -103.340829,
              43.000879
            ],
            [
              -103.404579,
              43.000737
            ],
            [
              -103.505099997425,
              43.0007699609789
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "151",
      "COUNTYNS": "00835897",
      "AFFGEOID": "0500000US31151",
      "GEOID": "31151",
      "NAME": "Saline",
      "LSAD": "06",
      "ALAND": 1486698710,
      "AWATER": 5934626,
      "County_state": "Saline,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.368401,
              40.698625
            ],
            [
              -97.368688,
              40.350392
            ],
            [
              -96.916055,
              40.349727
            ],
            [
              -96.913433,
              40.353282
            ],
            [
              -96.912637,
              40.523625
            ],
            [
              -96.913493,
              40.697948
            ],
            [
              -97.368401,
              40.698625
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "019",
      "COUNTYNS": "00835831",
      "AFFGEOID": "0500000US31019",
      "GEOID": "31019",
      "NAME": "Buffalo",
      "LSAD": "06",
      "ALAND": 2507344546,
      "AWATER": 18927907,
      "County_state": "Buffalo,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.425759,
              40.872567
            ],
            [
              -99.426353,
              40.699772
            ],
            [
              -99.416414,
              40.699738
            ],
            [
              -99.417316,
              40.670825
            ],
            [
              -99.411674,
              40.66757
            ],
            [
              -99.376036,
              40.664262
            ],
            [
              -99.331627,
              40.671439
            ],
            [
              -99.302596,
              40.664141
            ],
            [
              -99.244659,
              40.659391
            ],
            [
              -99.179248,
              40.660541
            ],
            [
              -99.170498,
              40.658489
            ],
            [
              -99.153915,
              40.659012
            ],
            [
              -99.114603,
              40.654818
            ],
            [
              -99.080982,
              40.656979
            ],
            [
              -98.997168,
              40.652138
            ],
            [
              -98.975448,
              40.656815
            ],
            [
              -98.906096,
              40.652722
            ],
            [
              -98.868382,
              40.659166
            ],
            [
              -98.82659,
              40.674785
            ],
            [
              -98.724387,
              40.689772
            ],
            [
              -98.72143,
              40.698903
            ],
            [
              -98.721895,
              40.916829
            ],
            [
              -98.721975,
              41.046674
            ],
            [
              -98.748526,
              41.04664
            ],
            [
              -99.207564,
              41.047003
            ],
            [
              -99.425986,
              41.046471
            ],
            [
              -99.425759,
              40.872567
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "083",
      "COUNTYNS": "00835863",
      "AFFGEOID": "0500000US31083",
      "GEOID": "31083",
      "NAME": "Harlan",
      "LSAD": "06",
      "ALAND": 1433471291,
      "AWATER": 53339808,
      "County_state": "Harlan,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.630459,
              40.35112
            ],
            [
              -99.6282545420969,
              40.0018659613449
            ],
            [
              -99.62598,
              40.001865
            ],
            [
              -99.6253240088811,
              40.001865850441
            ],
            [
              -99.501792,
              40.002026
            ],
            [
              -99.498999,
              40.001957
            ],
            [
              -99.49766,
              40.001912
            ],
            [
              -99.493465,
              40.001937
            ],
            [
              -99.480728,
              40.001942
            ],
            [
              -99.423565,
              40.00227
            ],
            [
              -99.412645,
              40.001868
            ],
            [
              -99.403389,
              40.001969
            ],
            [
              -99.290703,
              40.001949
            ],
            [
              -99.286656,
              40.002017
            ],
            [
              -99.282967,
              40.001879
            ],
            [
              -99.254012,
              40.002074
            ],
            [
              -99.25037,
              40.001957
            ],
            [
              -99.216376,
              40.002016
            ],
            [
              -99.197592,
              40.002033
            ],
            [
              -99.188905,
              40.002023
            ],
            [
              -99.186962,
              40.001977
            ],
            [
              -99.179134,
              40.001977
            ],
            [
              -99.179403,
              40.350681
            ],
            [
              -99.630459,
              40.35112
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "047",
      "COUNTYNS": "00835845",
      "AFFGEOID": "0500000US31047",
      "GEOID": "31047",
      "NAME": "Dawson",
      "LSAD": "06",
      "ALAND": 2623913292,
      "AWATER": 16405784,
      "County_state": "Dawson,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.224235,
              41.046597
            ],
            [
              -100.223311,
              40.700254
            ],
            [
              -99.981743,
              40.700003
            ],
            [
              -99.699269,
              40.699969
            ],
            [
              -99.643462,
              40.684911
            ],
            [
              -99.583465,
              40.677194
            ],
            [
              -99.543078,
              40.680806
            ],
            [
              -99.528563,
              40.675128
            ],
            [
              -99.444401,
              40.677892
            ],
            [
              -99.417316,
              40.670825
            ],
            [
              -99.416414,
              40.699738
            ],
            [
              -99.426353,
              40.699772
            ],
            [
              -99.425759,
              40.872567
            ],
            [
              -99.425986,
              41.046471
            ],
            [
              -100.224235,
              41.046597
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "175",
      "COUNTYNS": "00835909",
      "AFFGEOID": "0500000US31175",
      "GEOID": "31175",
      "NAME": "Valley",
      "LSAD": "06",
      "ALAND": 1471402787,
      "AWATER": 6076915,
      "County_state": "Valley,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.212016,
              41.740613
            ],
            [
              -99.212465,
              41.394235
            ],
            [
              -99.203261,
              41.39414
            ],
            [
              -98.751558,
              41.394084
            ],
            [
              -98.752225,
              41.740372
            ],
            [
              -98.759133,
              41.740382
            ],
            [
              -99.212016,
              41.740613
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "009",
      "COUNTYNS": "00835827",
      "AFFGEOID": "0500000US31009",
      "GEOID": "31009",
      "NAME": "Blaine",
      "LSAD": "06",
      "ALAND": 1840674086,
      "AWATER": 9443894,
      "County_state": "Blaine,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.267622,
              42.086147
            ],
            [
              -100.265474,
              41.739856
            ],
            [
              -100.251224,
              41.739817
            ],
            [
              -100.002413,
              41.738459
            ],
            [
              -99.686834,
              41.740326
            ],
            [
              -99.686959,
              42.086075
            ],
            [
              -100.167605,
              42.085848
            ],
            [
              -100.267622,
              42.086147
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "099",
      "COUNTYNS": "00835871",
      "AFFGEOID": "0500000US31099",
      "GEOID": "31099",
      "NAME": "Kearney",
      "LSAD": "06",
      "ALAND": 1337063632,
      "AWATER": 274634,
      "County_state": "Kearney,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.179248,
              40.660541
            ],
            [
              -99.179403,
              40.350681
            ],
            [
              -98.726827,
              40.350398
            ],
            [
              -98.723948,
              40.350391
            ],
            [
              -98.724387,
              40.689772
            ],
            [
              -98.82659,
              40.674785
            ],
            [
              -98.868382,
              40.659166
            ],
            [
              -98.906096,
              40.652722
            ],
            [
              -98.975448,
              40.656815
            ],
            [
              -98.997168,
              40.652138
            ],
            [
              -99.080982,
              40.656979
            ],
            [
              -99.114603,
              40.654818
            ],
            [
              -99.153915,
              40.659012
            ],
            [
              -99.170498,
              40.658489
            ],
            [
              -99.179248,
              40.660541
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "133",
      "COUNTYNS": "00835888",
      "AFFGEOID": "0500000US31133",
      "GEOID": "31133",
      "NAME": "Pawnee",
      "LSAD": "06",
      "ALAND": 1116478185,
      "AWATER": 4852361,
      "County_state": "Pawnee,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.463667,
              40.262173
            ],
            [
              -96.463712955188,
              40.0009682733452
            ],
            [
              -96.46364,
              40.000967
            ],
            [
              -96.304555,
              40.000629
            ],
            [
              -96.301066,
              40.000632
            ],
            [
              -96.2392078816029,
              40.000690965796096
            ],
            [
              -96.239172,
              40.000691
            ],
            [
              -96.223839,
              40.000729
            ],
            [
              -96.220171,
              40.00072
            ],
            [
              -96.154365,
              40.000495
            ],
            [
              -96.154246,
              40.00045
            ],
            [
              -96.147167,
              40.000479
            ],
            [
              -96.125937,
              40.000432
            ],
            [
              -96.125788,
              40.000467
            ],
            [
              -96.089781,
              40.000519
            ],
            [
              -96.081395,
              40.000603
            ],
            [
              -96.051691,
              40.000727
            ],
            [
              -96.02409,
              40.000719
            ],
            [
              -96.01068007843601,
              40.00063801255239
            ],
            [
              -96.010176,
              40.261482
            ],
            [
              -96.066736,
              40.262038
            ],
            [
              -96.463667,
              40.262173
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "185",
      "COUNTYNS": "00835914",
      "AFFGEOID": "0500000US31185",
      "GEOID": "31185",
      "NAME": "York",
      "LSAD": "06",
      "ALAND": 1482793604,
      "AWATER": 8595507,
      "County_state": "York,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.826287,
              41.046581
            ],
            [
              -97.825723,
              40.698555
            ],
            [
              -97.82478,
              40.698537
            ],
            [
              -97.368401,
              40.698625
            ],
            [
              -97.368118,
              41.046947
            ],
            [
              -97.826287,
              41.046581
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "077",
      "COUNTYNS": "00835860",
      "AFFGEOID": "0500000US31077",
      "GEOID": "31077",
      "NAME": "Greeley",
      "LSAD": "06",
      "ALAND": 1475804206,
      "AWATER": 2188873,
      "County_state": "Greeley,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.752225,
              41.740372
            ],
            [
              -98.751558,
              41.394084
            ],
            [
              -98.74433,
              41.394178
            ],
            [
              -98.291419,
              41.393894
            ],
            [
              -98.291344,
              41.480727
            ],
            [
              -98.290964,
              41.737235
            ],
            [
              -98.29576,
              41.741178
            ],
            [
              -98.752225,
              41.740372
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "097",
      "COUNTYNS": "00835870",
      "AFFGEOID": "0500000US31097",
      "GEOID": "31097",
      "NAME": "Johnson",
      "LSAD": "06",
      "ALAND": 973968228,
      "AWATER": 1766531,
      "County_state": "Johnson,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.463632,
              40.523013
            ],
            [
              -96.463667,
              40.262173
            ],
            [
              -96.066736,
              40.262038
            ],
            [
              -96.067012,
              40.523148
            ],
            [
              -96.463632,
              40.523013
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "183",
      "COUNTYNS": "00835913",
      "AFFGEOID": "0500000US31183",
      "GEOID": "31183",
      "NAME": "Wheeler",
      "LSAD": "06",
      "ALAND": 1489717867,
      "AWATER": 1059454,
      "County_state": "Wheeler,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.761155,
              42.088518
            ],
            [
              -98.759133,
              41.740382
            ],
            [
              -98.752225,
              41.740372
            ],
            [
              -98.29576,
              41.741178
            ],
            [
              -98.295602,
              41.914954
            ],
            [
              -98.295423,
              42.081436
            ],
            [
              -98.300592,
              42.088853
            ],
            [
              -98.761155,
              42.088518
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "105",
      "COUNTYNS": "00835874",
      "AFFGEOID": "0500000US31105",
      "GEOID": "31105",
      "NAME": "Kimball",
      "LSAD": "06",
      "ALAND": 2465269097,
      "AWATER": 1448446,
      "County_state": "Kimball,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -104.053514,
              41.157257
            ],
            [
              -104.053142,
              41.114457
            ],
            [
              -104.053083,
              41.104985
            ],
            [
              -104.053025,
              41.090274
            ],
            [
              -104.053177,
              41.089725
            ],
            [
              -104.053097,
              41.018045
            ],
            [
              -104.053158,
              41.016809
            ],
            [
              -104.053249,
              41.001406
            ],
            [
              -104.039238,
              41.001502
            ],
            [
              -104.023383,
              41.001887
            ],
            [
              -104.018223,
              41.001617
            ],
            [
              -103.972642,
              41.001615
            ],
            [
              -103.971373,
              41.001524
            ],
            [
              -103.953525,
              41.001596
            ],
            [
              -103.906324,
              41.001387
            ],
            [
              -103.896207,
              41.00175
            ],
            [
              -103.877967,
              41.001673
            ],
            [
              -103.858449,
              41.001681
            ],
            [
              -103.750498,
              41.002054
            ],
            [
              -103.574522,
              41.001721
            ],
            [
              -103.573773995352,
              41.0017201653792
            ],
            [
              -103.497447,
              41.001635
            ],
            [
              -103.486697,
              41.001914
            ],
            [
              -103.421975,
              41.002007
            ],
            [
              -103.421925,
              41.001969
            ],
            [
              -103.396991,
              41.002558
            ],
            [
              -103.382492446889,
              41.00223211782
            ],
            [
              -103.376021,
              41.047771
            ],
            [
              -103.37762,
              41.394633
            ],
            [
              -103.45276,
              41.393528
            ],
            [
              -104.052287592918,
              41.3932140037803
            ],
            [
              -104.052687,
              41.330569
            ],
            [
              -104.052324,
              41.321144
            ],
            [
              -104.052476,
              41.320961
            ],
            [
              -104.052568,
              41.316202
            ],
            [
              -104.052453,
              41.278202
            ],
            [
              -104.052574,
              41.278019
            ],
            [
              -104.052666,
              41.275251
            ],
            [
              -104.053514,
              41.157257
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "147",
      "COUNTYNS": "00835895",
      "AFFGEOID": "0500000US31147",
      "GEOID": "31147",
      "NAME": "Richardson",
      "LSAD": "06",
      "ALAND": 1429261293,
      "AWATER": 7077617,
      "County_state": "Richardson,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.01068007843601,
              40.00063801255239
            ],
            [
              -96.010678,
              40.000638
            ],
            [
              -95.958139,
              40.000521
            ],
            [
              -95.882524,
              40.00047
            ],
            [
              -95.7881114096839,
              40.0004520166495
            ],
            [
              -95.788024,
              40.000452
            ],
            [
              -95.784575,
              40.000463
            ],
            [
              -95.375257,
              40
            ],
            [
              -95.339896,
              39.999999
            ],
            [
              -95.30829,
              39.999998
            ],
            [
              -95.308469,
              40.002194
            ],
            [
              -95.311163,
              40.007806
            ],
            [
              -95.312211,
              40.009395
            ],
            [
              -95.315271,
              40.01207
            ],
            [
              -95.318015,
              40.013216
            ],
            [
              -95.328501,
              40.015657
            ],
            [
              -95.336242,
              40.019104
            ],
            [
              -95.346573,
              40.028272
            ],
            [
              -95.348777,
              40.029297
            ],
            [
              -95.356876,
              40.031522
            ],
            [
              -95.363983,
              40.031498
            ],
            [
              -95.382957,
              40.027112
            ],
            [
              -95.387195,
              40.02677
            ],
            [
              -95.391527,
              40.027058
            ],
            [
              -95.395858,
              40.028038
            ],
            [
              -95.402665,
              40.030567
            ],
            [
              -95.40726,
              40.033112
            ],
            [
              -95.413588,
              40.038424
            ],
            [
              -95.416824,
              40.043235
            ],
            [
              -95.41932,
              40.048442
            ],
            [
              -95.42164,
              40.058952
            ],
            [
              -95.420643,
              40.062599
            ],
            [
              -95.418345,
              40.066509
            ],
            [
              -95.414734,
              40.06982
            ],
            [
              -95.409856,
              40.07432
            ],
            [
              -95.408455,
              40.079158
            ],
            [
              -95.409243,
              40.084166
            ],
            [
              -95.410643,
              40.091531
            ],
            [
              -95.407591,
              40.09803
            ],
            [
              -95.400139,
              40.103238
            ],
            [
              -95.397146,
              40.105183
            ],
            [
              -95.394216,
              40.108263
            ],
            [
              -95.393062,
              40.111175
            ],
            [
              -95.39284,
              40.115887
            ],
            [
              -95.393347,
              40.119212
            ],
            [
              -95.395369,
              40.122811
            ],
            [
              -95.398667,
              40.126419
            ],
            [
              -95.404395,
              40.129018
            ],
            [
              -95.409481,
              40.130052
            ],
            [
              -95.419186,
              40.130586
            ],
            [
              -95.42478,
              40.132765
            ],
            [
              -95.428749,
              40.135577
            ],
            [
              -95.431022,
              40.138411
            ],
            [
              -95.432165,
              40.141025
            ],
            [
              -95.433374,
              40.146114
            ],
            [
              -95.433822,
              40.152935
            ],
            [
              -95.434817,
              40.156017
            ],
            [
              -95.436348,
              40.15872
            ],
            [
              -95.440694,
              40.162282
            ],
            [
              -95.442818,
              40.163261
            ],
            [
              -95.454919,
              40.166577
            ],
            [
              -95.460746,
              40.169173
            ],
            [
              -95.471054,
              40.17691
            ],
            [
              -95.476301,
              40.181988
            ],
            [
              -95.479193,
              40.185652
            ],
            [
              -95.48102,
              40.188524
            ],
            [
              -95.48254,
              40.192283
            ],
            [
              -95.482757,
              40.197346
            ],
            [
              -95.482319,
              40.200667
            ],
            [
              -95.477948,
              40.208643
            ],
            [
              -95.473469,
              40.213482
            ],
            [
              -95.471393,
              40.217333
            ],
            [
              -95.470061,
              40.221507
            ],
            [
              -95.469718,
              40.227908
            ],
            [
              -95.470349,
              40.230819
            ],
            [
              -95.472548,
              40.236078
            ],
            [
              -95.477501,
              40.24272
            ],
            [
              -95.482778,
              40.246273
            ],
            [
              -95.485994,
              40.247825
            ],
            [
              -95.490333,
              40.248966
            ],
            [
              -95.50026,
              40.249629
            ],
            [
              -95.515455,
              40.248505
            ],
            [
              -95.521925,
              40.24947
            ],
            [
              -95.54716,
              40.259066
            ],
            [
              -95.552473,
              40.261904
            ],
            [
              -96.010176,
              40.261482
            ],
            [
              -96.01068007843601,
              40.00063801255239
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "145",
      "COUNTYNS": "00835894",
      "AFFGEOID": "0500000US31145",
      "GEOID": "31145",
      "NAME": "Red Willow",
      "LSAD": "06",
      "ALAND": 1856984023,
      "AWATER": 2643458,
      "County_state": "Red Willow,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.761019,
              40.174924
            ],
            [
              -100.758497,
              40.156303
            ],
            [
              -100.75883,
              40.002302
            ],
            [
              -100.752183,
              40.002128
            ],
            [
              -100.73882584525201,
              40.002228424417495
            ],
            [
              -100.733296,
              40.00227
            ],
            [
              -100.729904,
              40.002111
            ],
            [
              -100.721128,
              40.002069
            ],
            [
              -100.683435,
              40.002234
            ],
            [
              -100.66023,
              40.002162
            ],
            [
              -100.645445,
              40.001883
            ],
            [
              -100.600945,
              40.001906
            ],
            [
              -100.594757,
              40.001977
            ],
            [
              -100.567238,
              40.001889
            ],
            [
              -100.551886,
              40.001889
            ],
            [
              -100.511065,
              40.00184
            ],
            [
              -100.487159,
              40.001767
            ],
            [
              -100.477018,
              40.001752
            ],
            [
              -100.475854,
              40.001768
            ],
            [
              -100.468773,
              40.001724
            ],
            [
              -100.447072,
              40.001795
            ],
            [
              -100.439081,
              40.001774
            ],
            [
              -100.39008,
              40.001809
            ],
            [
              -100.231652,
              40.001623
            ],
            [
              -100.229479,
              40.001693
            ],
            [
              -100.215406,
              40.001629
            ],
            [
              -100.196959,
              40.001494
            ],
            [
              -100.193596885795,
              40.001572838534294
            ],
            [
              -100.197807,
              40.350027
            ],
            [
              -100.26893,
              40.349239
            ],
            [
              -100.758435,
              40.349507
            ],
            [
              -100.761019,
              40.174924
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "011",
      "COUNTYNS": "00835828",
      "AFFGEOID": "0500000US31011",
      "GEOID": "31011",
      "NAME": "Boone",
      "LSAD": "06",
      "ALAND": 1778166034,
      "AWATER": 1718583,
      "County_state": "Boone,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.295602,
              41.914954
            ],
            [
              -98.29576,
              41.741178
            ],
            [
              -98.290964,
              41.737235
            ],
            [
              -98.291344,
              41.480727
            ],
            [
              -98.099523,
              41.480777
            ],
            [
              -98.099797,
              41.495389
            ],
            [
              -98.04146,
              41.495988
            ],
            [
              -98.041328,
              41.510534
            ],
            [
              -97.885339,
              41.511427
            ],
            [
              -97.885492,
              41.525868
            ],
            [
              -97.829846,
              41.526174
            ],
            [
              -97.830493,
              41.742238
            ],
            [
              -97.832462,
              41.747165
            ],
            [
              -97.833288,
              41.916286
            ],
            [
              -98.295602,
              41.914954
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "163",
      "COUNTYNS": "00835903",
      "AFFGEOID": "0500000US31163",
      "GEOID": "31163",
      "NAME": "Sherman",
      "LSAD": "06",
      "ALAND": 1465489230,
      "AWATER": 15062146,
      "County_state": "Sherman,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.203261,
              41.39414
            ],
            [
              -99.207564,
              41.047003
            ],
            [
              -98.748526,
              41.04664
            ],
            [
              -98.74433,
              41.394178
            ],
            [
              -98.751558,
              41.394084
            ],
            [
              -99.203261,
              41.39414
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "079",
      "COUNTYNS": "00835861",
      "AFFGEOID": "0500000US31079",
      "GEOID": "31079",
      "NAME": "Hall",
      "LSAD": "06",
      "ALAND": 1414817966,
      "AWATER": 15229160,
      "County_state": "Hall,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.721975,
              41.046674
            ],
            [
              -98.721895,
              40.916829
            ],
            [
              -98.72143,
              40.698903
            ],
            [
              -98.282851,
              40.698284
            ],
            [
              -98.282578,
              40.868677
            ],
            [
              -98.282565,
              40.873548
            ],
            [
              -98.282545,
              40.91583
            ],
            [
              -98.282516,
              40.944814
            ],
            [
              -98.282355,
              41.042367
            ],
            [
              -98.287168,
              41.046363
            ],
            [
              -98.49279,
              41.046492
            ],
            [
              -98.721975,
              41.046674
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "017",
      "COUNTYNS": "00835830",
      "AFFGEOID": "0500000US31017",
      "GEOID": "31017",
      "NAME": "Brown",
      "LSAD": "06",
      "ALAND": 3163296235,
      "AWATER": 9814399,
      "County_state": "Brown,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.198478,
              42.846102
            ],
            [
              -100.198575,
              42.781021
            ],
            [
              -100.18327,
              42.781005
            ],
            [
              -100.183167,
              42.43343
            ],
            [
              -100.167539,
              42.433281
            ],
            [
              -100.167605,
              42.085848
            ],
            [
              -99.686959,
              42.086075
            ],
            [
              -99.662379,
              42.086009
            ],
            [
              -99.663752,
              42.434609
            ],
            [
              -99.676005,
              42.43445
            ],
            [
              -99.677684,
              42.730903
            ],
            [
              -99.711197,
              42.727108
            ],
            [
              -99.752491,
              42.737633
            ],
            [
              -99.778334,
              42.735173
            ],
            [
              -99.816833,
              42.742979
            ],
            [
              -99.837262,
              42.754108
            ],
            [
              -99.855047,
              42.752853
            ],
            [
              -99.862162,
              42.769002
            ],
            [
              -99.898039,
              42.765462
            ],
            [
              -99.947273,
              42.772994
            ],
            [
              -99.968856,
              42.772474
            ],
            [
              -100.007809,
              42.788566
            ],
            [
              -100.055921,
              42.785438
            ],
            [
              -100.11334,
              42.799683
            ],
            [
              -100.133978,
              42.807819
            ],
            [
              -100.145363,
              42.830215
            ],
            [
              -100.187778,
              42.83761
            ],
            [
              -100.198478,
              42.846102
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "131",
      "COUNTYNS": "00835887",
      "AFFGEOID": "0500000US31131",
      "GEOID": "31131",
      "NAME": "Otoe",
      "LSAD": "06",
      "ALAND": 1594610575,
      "AWATER": 8640423,
      "County_state": "Otoe,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.463764,
              40.78396
            ],
            [
              -96.463807,
              40.754956
            ],
            [
              -96.463632,
              40.523013
            ],
            [
              -96.067012,
              40.523148
            ],
            [
              -95.709974,
              40.523798
            ],
            [
              -95.714291,
              40.527208
            ],
            [
              -95.722444,
              40.528118
            ],
            [
              -95.725214,
              40.527773
            ],
            [
              -95.731179,
              40.525436
            ],
            [
              -95.73725,
              40.52393
            ],
            [
              -95.74868,
              40.524275
            ],
            [
              -95.75711,
              40.52599
            ],
            [
              -95.762857,
              40.528371
            ],
            [
              -95.76692,
              40.531563
            ],
            [
              -95.768693,
              40.534106
            ],
            [
              -95.769281,
              40.536656
            ],
            [
              -95.768412,
              40.540347
            ],
            [
              -95.764696,
              40.545721
            ],
            [
              -95.763624,
              40.548298
            ],
            [
              -95.763366,
              40.550797
            ],
            [
              -95.763833,
              40.553873
            ],
            [
              -95.76503,
              40.556921
            ],
            [
              -95.771776,
              40.566133
            ],
            [
              -95.774704,
              40.573574
            ],
            [
              -95.773549,
              40.578205
            ],
            [
              -95.768527,
              40.583296
            ],
            [
              -95.765645,
              40.585208
            ],
            [
              -95.758895,
              40.588973
            ],
            [
              -95.753148,
              40.59284
            ],
            [
              -95.750053,
              40.597052
            ],
            [
              -95.748858,
              40.599965
            ],
            [
              -95.748626,
              40.603355
            ],
            [
              -95.749685,
              40.606842
            ],
            [
              -95.751271,
              40.609057
            ],
            [
              -95.758045,
              40.613759
            ],
            [
              -95.764412,
              40.61709
            ],
            [
              -95.766823,
              40.61878
            ],
            [
              -95.768926,
              40.621264
            ],
            [
              -95.770083,
              40.624425
            ],
            [
              -95.770442,
              40.635285
            ],
            [
              -95.771325,
              40.639393
            ],
            [
              -95.772832,
              40.642496
            ],
            [
              -95.776251,
              40.647463
            ],
            [
              -95.781909,
              40.653272
            ],
            [
              -95.786568,
              40.657253
            ],
            [
              -95.789485,
              40.659388
            ],
            [
              -95.795489,
              40.662384
            ],
            [
              -95.804307,
              40.664886
            ],
            [
              -95.81415,
              40.66557
            ],
            [
              -95.822913,
              40.66724
            ],
            [
              -95.8308293305508,
              40.670623923681
            ],
            [
              -95.832177,
              40.6712
            ],
            [
              -95.842801,
              40.677496
            ],
            [
              -95.844827,
              40.679867
            ],
            [
              -95.846034,
              40.682605
            ],
            [
              -95.847931,
              40.694197
            ],
            [
              -95.849828,
              40.698147
            ],
            [
              -95.852615,
              40.702262
            ],
            [
              -95.859378,
              40.708055
            ],
            [
              -95.870481,
              40.71248
            ],
            [
              -95.87528,
              40.71412
            ],
            [
              -95.877015,
              40.714287
            ],
            [
              -95.883178,
              40.717579
            ],
            [
              -95.885349,
              40.721093
            ],
            [
              -95.888907,
              40.731855
            ],
            [
              -95.888697,
              40.736292
            ],
            [
              -95.88669,
              40.742101
            ],
            [
              -95.883643,
              40.747831
            ],
            [
              -95.879027,
              40.753081
            ],
            [
              -95.873335,
              40.757616
            ],
            [
              -95.869982,
              40.759645
            ],
            [
              -95.861695,
              40.762871
            ],
            [
              -95.852776,
              40.765631
            ],
            [
              -95.84662,
              40.768619
            ],
            [
              -95.842824,
              40.771093
            ],
            [
              -95.838879,
              40.774545
            ],
            [
              -95.836903,
              40.776477
            ],
            [
              -95.835232,
              40.779151
            ],
            [
              -95.834156,
              40.783016
            ],
            [
              -95.8342151936933,
              40.7837840663969
            ],
            [
              -95.911753,
              40.784154
            ],
            [
              -96.463764,
              40.78396
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "089",
      "COUNTYNS": "00835866",
      "AFFGEOID": "0500000US31089",
      "GEOID": "31089",
      "NAME": "Holt",
      "LSAD": "06",
      "ALAND": 6248083634,
      "AWATER": 13201503,
      "County_state": "Holt,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.257039,
              42.804296
            ],
            [
              -99.256846,
              42.782655
            ],
            [
              -99.245803,
              42.782499
            ],
            [
              -99.244591,
              42.434639
            ],
            [
              -99.234064,
              42.434524
            ],
            [
              -99.234629,
              42.087995
            ],
            [
              -99.222733,
              42.087916
            ],
            [
              -98.761155,
              42.088518
            ],
            [
              -98.300592,
              42.088853
            ],
            [
              -98.300235,
              42.436913
            ],
            [
              -98.305456,
              42.43693
            ],
            [
              -98.305149,
              42.761207
            ],
            [
              -98.340605,
              42.770661
            ],
            [
              -98.359869,
              42.767636
            ],
            [
              -98.416025,
              42.775674
            ],
            [
              -98.462932,
              42.766214
            ],
            [
              -98.490926,
              42.7731
            ],
            [
              -98.531191,
              42.774618
            ],
            [
              -98.548473,
              42.781649
            ],
            [
              -98.58376,
              42.779736
            ],
            [
              -98.656835,
              42.809021
            ],
            [
              -98.67462,
              42.8027
            ],
            [
              -98.710048,
              42.80348
            ],
            [
              -98.763223,
              42.827899
            ],
            [
              -98.797171,
              42.824364
            ],
            [
              -98.818283,
              42.830153
            ],
            [
              -98.840056,
              42.845588
            ],
            [
              -98.8778,
              42.84118
            ],
            [
              -98.888441,
              42.84348
            ],
            [
              -98.954634,
              42.894077
            ],
            [
              -98.982527,
              42.896436
            ],
            [
              -99.016792,
              42.886262
            ],
            [
              -99.05676,
              42.862595
            ],
            [
              -99.096293,
              42.856281
            ],
            [
              -99.128038,
              42.84427
            ],
            [
              -99.153803,
              42.841884
            ],
            [
              -99.180169,
              42.829129
            ],
            [
              -99.208541,
              42.822831
            ],
            [
              -99.257039,
              42.804296
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "177",
      "COUNTYNS": "00835910",
      "AFFGEOID": "0500000US31177",
      "GEOID": "31177",
      "NAME": "Washington",
      "LSAD": "06",
      "ALAND": 1009985069,
      "AWATER": 8388604,
      "County_state": "Washington,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.444785,
              41.683633
            ],
            [
              -96.443898,
              41.523933
            ],
            [
              -96.43446,
              41.523931
            ],
            [
              -96.434792,
              41.494928
            ],
            [
              -96.42522,
              41.494865
            ],
            [
              -96.425211,
              41.480342
            ],
            [
              -96.405936,
              41.48046
            ],
            [
              -96.405622,
              41.465821
            ],
            [
              -96.38642,
              41.465749
            ],
            [
              -96.386478,
              41.451253
            ],
            [
              -96.3672,
              41.450953
            ],
            [
              -96.367336,
              41.436698
            ],
            [
              -96.328923,
              41.436621
            ],
            [
              -96.329012,
              41.393136
            ],
            [
              -96.232744,
              41.393122
            ],
            [
              -96.062303,
              41.393156
            ],
            [
              -95.9393,
              41.393184
            ],
            [
              -95.93693071202759,
              41.390979432452404
            ],
            [
              -95.93749,
              41.393095
            ],
            [
              -95.93689,
              41.396387
            ],
            [
              -95.930778,
              41.406179
            ],
            [
              -95.929721,
              41.411331
            ],
            [
              -95.929889,
              41.415155
            ],
            [
              -95.932297,
              41.422123
            ],
            [
              -95.933169,
              41.42943
            ],
            [
              -95.932193,
              41.431914
            ],
            [
              -95.930705,
              41.433894
            ],
            [
              -95.923905,
              41.439742
            ],
            [
              -95.921833,
              41.442062
            ],
            [
              -95.920577,
              41.444302
            ],
            [
              -95.919865,
              41.447922
            ],
            [
              -95.920281,
              41.451566
            ],
            [
              -95.922529,
              41.455766
            ],
            [
              -95.925713,
              41.459382
            ],
            [
              -95.932921,
              41.463798
            ],
            [
              -95.936801,
              41.46519
            ],
            [
              -95.941969,
              41.466262
            ],
            [
              -95.946465,
              41.466166
            ],
            [
              -95.957017,
              41.462814
            ],
            [
              -95.962329,
              41.46281
            ],
            [
              -95.965481,
              41.46351
            ],
            [
              -95.973449,
              41.467318
            ],
            [
              -95.982962,
              41.469778
            ],
            [
              -95.991018,
              41.470374
            ],
            [
              -96.004708,
              41.472342
            ],
            [
              -96.008833,
              41.474039
            ],
            [
              -96.011757,
              41.476212
            ],
            [
              -96.016389,
              41.481556
            ],
            [
              -96.019542,
              41.486617
            ],
            [
              -96.019817,
              41.48803
            ],
            [
              -96.019224,
              41.489296
            ],
            [
              -96.015986,
              41.492659
            ],
            [
              -96.007334,
              41.497631
            ],
            [
              -95.997903,
              41.504789
            ],
            [
              -95.99617450065989,
              41.50695922694919
            ],
            [
              -95.993943,
              41.509761
            ],
            [
              -95.992833,
              41.512002
            ],
            [
              -95.992599,
              41.514174
            ],
            [
              -95.99267,
              41.51729
            ],
            [
              -95.993891,
              41.523412
            ],
            [
              -95.999529,
              41.538679
            ],
            [
              -96.001161,
              41.541146
            ],
            [
              -96.005079,
              41.544004
            ],
            [
              -96.010028,
              41.545533
            ],
            [
              -96.016474,
              41.546085
            ],
            [
              -96.019686,
              41.545743
            ],
            [
              -96.023182,
              41.544364
            ],
            [
              -96.027289,
              41.541081
            ],
            [
              -96.028439,
              41.539616
            ],
            [
              -96.030593,
              41.527292
            ],
            [
              -96.034305,
              41.512853
            ],
            [
              -96.036603,
              41.509047
            ],
            [
              -96.038101,
              41.50799
            ],
            [
              -96.040701,
              41.507076
            ],
            [
              -96.048118,
              41.507271
            ],
            [
              -96.05369,
              41.508859
            ],
            [
              -96.057935,
              41.51149
            ],
            [
              -96.063638,
              41.516162
            ],
            [
              -96.067527,
              41.52034
            ],
            [
              -96.07307,
              41.525052
            ],
            [
              -96.080493,
              41.528199
            ],
            [
              -96.08822,
              41.530595
            ],
            [
              -96.089714,
              41.531778
            ],
            [
              -96.09409,
              41.539265
            ],
            [
              -96.096186,
              41.547192
            ],
            [
              -96.095851,
              41.55088
            ],
            [
              -96.093613,
              41.558271
            ],
            [
              -96.09182,
              41.561086
            ],
            [
              -96.084786,
              41.567831
            ],
            [
              -96.082406,
              41.571229
            ],
            [
              -96.081178,
              41.574274
            ],
            [
              -96.081152,
              41.577289
            ],
            [
              -96.081843,
              41.580407
            ],
            [
              -96.083417,
              41.583339
            ],
            [
              -96.085771,
              41.585746
            ],
            [
              -96.088683,
              41.58752
            ],
            [
              -96.101496,
              41.59158
            ],
            [
              -96.104465,
              41.593169
            ],
            [
              -96.109387,
              41.596871
            ],
            [
              -96.113833,
              41.602277
            ],
            [
              -96.11583,
              41.60576
            ],
            [
              -96.117558,
              41.609999
            ],
            [
              -96.118105,
              41.613495
            ],
            [
              -96.11795,
              41.617356
            ],
            [
              -96.116233,
              41.621574
            ],
            [
              -96.114146,
              41.623975
            ],
            [
              -96.100701,
              41.635507
            ],
            [
              -96.097728,
              41.639633
            ],
            [
              -96.095046,
              41.647365
            ],
            [
              -96.095415,
              41.652736
            ],
            [
              -96.097933,
              41.658682
            ],
            [
              -96.099837,
              41.66103
            ],
            [
              -96.111483,
              41.668548
            ],
            [
              -96.114978,
              41.67122
            ],
            [
              -96.11812,
              41.674399
            ],
            [
              -96.120983,
              41.677861
            ],
            [
              -96.121726,
              41.68274
            ],
            [
              -96.12171195568601,
              41.6829898591487
            ],
            [
              -96.444785,
              41.683633
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "037",
      "COUNTYNS": "00835840",
      "AFFGEOID": "0500000US31037",
      "GEOID": "31037",
      "NAME": "Colfax",
      "LSAD": "06",
      "ALAND": 1066152076,
      "AWATER": 12619739,
      "County_state": "Colfax,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.252554,
              41.395197
            ],
            [
              -97.253519,
              41.384212
            ],
            [
              -97.199268,
              41.38584
            ],
            [
              -97.178464,
              41.376816
            ],
            [
              -97.091208,
              41.39289
            ],
            [
              -97.056831,
              41.412531
            ],
            [
              -97.01573,
              41.413852
            ],
            [
              -96.998721,
              41.419881
            ],
            [
              -96.964703,
              41.441801
            ],
            [
              -96.920964,
              41.456121
            ],
            [
              -96.90591,
              41.456436
            ],
            [
              -96.905922,
              41.742763
            ],
            [
              -97.019911,
              41.74298
            ],
            [
              -97.058546,
              41.742998
            ],
            [
              -97.252425,
              41.743085
            ],
            [
              -97.252592,
              41.612672
            ],
            [
              -97.252554,
              41.395197
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "013",
      "COUNTYNS": "00835991",
      "AFFGEOID": "0500000US31013",
      "GEOID": "31013",
      "NAME": "Box Butte",
      "LSAD": "06",
      "ALAND": 2784994726,
      "AWATER": 6588657,
      "County_state": "Box Butte,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -103.444532,
              42.437354
            ],
            [
              -103.444108,
              42.090708
            ],
            [
              -103.401667,
              42.090562
            ],
            [
              -103.401639,
              42.00354
            ],
            [
              -103.363337,
              42.00293
            ],
            [
              -102.952292,
              42.001776
            ],
            [
              -102.862321,
              42.000665
            ],
            [
              -102.697835,
              42.004843
            ],
            [
              -102.7021,
              42.051323
            ],
            [
              -102.702383,
              42.092747
            ],
            [
              -102.742269,
              42.092388
            ],
            [
              -102.741581,
              42.177721
            ],
            [
              -102.746468,
              42.249967
            ],
            [
              -102.751533,
              42.357112
            ],
            [
              -102.749848,
              42.418126
            ],
            [
              -102.749431,
              42.439934
            ],
            [
              -102.773366,
              42.439922
            ],
            [
              -103.444532,
              42.437354
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "085",
      "COUNTYNS": "00835864",
      "AFFGEOID": "0500000US31085",
      "GEOID": "31085",
      "NAME": "Hayes",
      "LSAD": "06",
      "ALAND": 1846821514,
      "AWATER": 570786,
      "County_state": "Hayes,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -101.345365,
              40.698093
            ],
            [
              -101.342788,
              40.350444
            ],
            [
              -101.323353,
              40.350553
            ],
            [
              -100.777376,
              40.349466
            ],
            [
              -100.779571,
              40.375005
            ],
            [
              -100.781229,
              40.655593
            ],
            [
              -100.777833,
              40.700135
            ],
            [
              -101.247963,
              40.697802
            ],
            [
              -101.345365,
              40.698093
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "031",
      "COUNTYNS": "00835837",
      "AFFGEOID": "0500000US31031",
      "GEOID": "31031",
      "NAME": "Cherry",
      "LSAD": "06",
      "ALAND": 15436697272,
      "AWATER": 127623807,
      "County_state": "Cherry,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -102.082546,
              42.999356
            ],
            [
              -102.084961,
              42.785509
            ],
            [
              -102.066905,
              42.78549
            ],
            [
              -102.066689,
              42.443463
            ],
            [
              -102.038169,
              42.443307
            ],
            [
              -102.04028,
              42.096744
            ],
            [
              -102.006204,
              42.096338
            ],
            [
              -101.426557,
              42.092194
            ],
            [
              -100.846129,
              42.088166
            ],
            [
              -100.267622,
              42.086147
            ],
            [
              -100.167605,
              42.085848
            ],
            [
              -100.167539,
              42.433281
            ],
            [
              -100.183167,
              42.43343
            ],
            [
              -100.18327,
              42.781005
            ],
            [
              -100.198575,
              42.781021
            ],
            [
              -100.198478,
              42.846102
            ],
            [
              -100.19841203687501,
              42.9985420518853
            ],
            [
              -100.198412688285,
              42.9985420503464
            ],
            [
              -100.198434,
              42.998542
            ],
            [
              -100.277793,
              42.998674
            ],
            [
              -100.283713,
              42.998767
            ],
            [
              -100.349548,
              42.99874
            ],
            [
              -100.355406,
              42.99876
            ],
            [
              -100.472742,
              42.999288
            ],
            [
              -100.534335,
              42.999017
            ],
            [
              -100.544018,
              42.998795
            ],
            [
              -100.553131,
              42.998721
            ],
            [
              -100.625414,
              42.998584
            ],
            [
              -100.631728,
              42.998092
            ],
            [
              -100.867473,
              42.998266
            ],
            [
              -100.887898,
              42.997881
            ],
            [
              -100.906714,
              42.99791
            ],
            [
              -100.958365,
              42.997796
            ],
            [
              -100.96419,
              42.997886
            ],
            [
              -101.000429,
              42.99753
            ],
            [
              -101.043147,
              42.99796
            ],
            [
              -101.226494,
              42.997901
            ],
            [
              -101.226853,
              42.997896
            ],
            [
              -101.22800834159801,
              42.9978753513416
            ],
            [
              -101.229203,
              42.997854
            ],
            [
              -101.230325,
              42.997899
            ],
            [
              -101.500424,
              42.997115
            ],
            [
              -101.625424,
              42.996238
            ],
            [
              -101.713573,
              42.99662
            ],
            [
              -101.849982,
              42.999329
            ],
            [
              -102.082486038791,
              42.9993559930387
            ],
            [
              -102.082546,
              42.999356
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "055",
      "COUNTYNS": "00835849",
      "AFFGEOID": "0500000US31055",
      "GEOID": "31055",
      "NAME": "Douglas",
      "LSAD": "06",
      "ALAND": 850764861,
      "AWATER": 28139501,
      "County_state": "Douglas,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.47072,
              41.393239
            ],
            [
              -96.459336,
              41.38014
            ],
            [
              -96.421439,
              41.364165
            ],
            [
              -96.406973,
              41.334308
            ],
            [
              -96.407608,
              41.314614
            ],
            [
              -96.377914,
              41.298298
            ],
            [
              -96.350598,
              41.265192
            ],
            [
              -96.350807,
              41.256248
            ],
            [
              -96.365558,
              41.234136
            ],
            [
              -96.350676,
              41.204191
            ],
            [
              -96.327399,
              41.189999
            ],
            [
              -96.196677,
              41.190654
            ],
            [
              -96.158333,
              41.190762
            ],
            [
              -96.081396,
              41.190756
            ],
            [
              -96.023825,
              41.19065
            ],
            [
              -96.009134,
              41.1907
            ],
            [
              -96.005918,
              41.190668
            ],
            [
              -95.947933,
              41.190953
            ],
            [
              -95.939532,
              41.191097
            ],
            [
              -95.9232187016371,
              41.1910461777481
            ],
            [
              -95.92599,
              41.195698
            ],
            [
              -95.927491,
              41.202198
            ],
            [
              -95.924891,
              41.211198
            ],
            [
              -95.915091,
              41.222998
            ],
            [
              -95.912591,
              41.226998
            ],
            [
              -95.910891,
              41.231798
            ],
            [
              -95.910891,
              41.233998
            ],
            [
              -95.911391,
              41.237998
            ],
            [
              -95.91708718057889,
              41.2497931214007
            ],
            [
              -95.921291,
              41.258498
            ],
            [
              -95.921891,
              41.264598
            ],
            [
              -95.92447208196201,
              41.2729428011309
            ],
            [
              -95.924976,
              41.274572
            ],
            [
              -95.928691,
              41.281398
            ],
            [
              -95.929591,
              41.285097
            ],
            [
              -95.929591,
              41.290449
            ],
            [
              -95.929591,
              41.292297
            ],
            [
              -95.927491,
              41.298397
            ],
            [
              -95.920291,
              41.301097
            ],
            [
              -95.90589,
              41.300897
            ],
            [
              -95.90429,
              41.299597
            ],
            [
              -95.90429,
              41.293497
            ],
            [
              -95.899069,
              41.283861
            ],
            [
              -95.890152,
              41.278308
            ],
            [
              -95.88239,
              41.281397
            ],
            [
              -95.87689,
              41.285097
            ],
            [
              -95.872889,
              41.289497
            ],
            [
              -95.87276441176469,
              41.290057647058795
            ],
            [
              -95.871489,
              41.295797
            ],
            [
              -95.874689,
              41.307097
            ],
            [
              -95.878189,
              41.312497
            ],
            [
              -95.883089,
              41.316697
            ],
            [
              -95.88869,
              41.319097
            ],
            [
              -95.89929,
              41.321197
            ],
            [
              -95.91379,
              41.320197
            ],
            [
              -95.92209,
              41.321097
            ],
            [
              -95.92569,
              41.322197
            ],
            [
              -95.939291,
              41.328897
            ],
            [
              -95.946891,
              41.334096
            ],
            [
              -95.953091,
              41.339896
            ],
            [
              -95.956691,
              41.345496
            ],
            [
              -95.956791,
              41.349196
            ],
            [
              -95.954891,
              41.351796
            ],
            [
              -95.952191,
              41.353496
            ],
            [
              -95.94099,
              41.357496
            ],
            [
              -95.93549,
              41.360596
            ],
            [
              -95.93099,
              41.364696
            ],
            [
              -95.92879,
              41.370096
            ],
            [
              -95.92929,
              41.374896
            ],
            [
              -95.93519,
              41.384395
            ],
            [
              -95.93693071202759,
              41.390979432452404
            ],
            [
              -95.9393,
              41.393184
            ],
            [
              -96.062303,
              41.393156
            ],
            [
              -96.232744,
              41.393122
            ],
            [
              -96.329012,
              41.393136
            ],
            [
              -96.47072,
              41.393239
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "053",
      "COUNTYNS": "00835848",
      "AFFGEOID": "0500000US31053",
      "GEOID": "31053",
      "NAME": "Dodge",
      "LSAD": "06",
      "ALAND": 1370405061,
      "AWATER": 35956200,
      "County_state": "Dodge,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.905922,
              41.742763
            ],
            [
              -96.90591,
              41.456436
            ],
            [
              -96.905862,
              41.453389
            ],
            [
              -96.837655,
              41.445917
            ],
            [
              -96.819118,
              41.440183
            ],
            [
              -96.773397,
              41.450129
            ],
            [
              -96.746722,
              41.449749
            ],
            [
              -96.710436,
              41.430806
            ],
            [
              -96.691344,
              41.425663
            ],
            [
              -96.634,
              41.434364
            ],
            [
              -96.568677,
              41.432986
            ],
            [
              -96.519431,
              41.407837
            ],
            [
              -96.503558,
              41.396385
            ],
            [
              -96.47072,
              41.393239
            ],
            [
              -96.329012,
              41.393136
            ],
            [
              -96.328923,
              41.436621
            ],
            [
              -96.367336,
              41.436698
            ],
            [
              -96.3672,
              41.450953
            ],
            [
              -96.386478,
              41.451253
            ],
            [
              -96.38642,
              41.465749
            ],
            [
              -96.405622,
              41.465821
            ],
            [
              -96.405936,
              41.48046
            ],
            [
              -96.425211,
              41.480342
            ],
            [
              -96.42522,
              41.494865
            ],
            [
              -96.434792,
              41.494928
            ],
            [
              -96.43446,
              41.523931
            ],
            [
              -96.443898,
              41.523933
            ],
            [
              -96.444785,
              41.683633
            ],
            [
              -96.444217,
              41.741775
            ],
            [
              -96.555172,
              41.742018
            ],
            [
              -96.905922,
              41.742763
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "149",
      "COUNTYNS": "00835896",
      "AFFGEOID": "0500000US31149",
      "GEOID": "31149",
      "NAME": "Rock",
      "LSAD": "06",
      "ALAND": 2611466209,
      "AWATER": 8807750,
      "County_state": "Rock,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.677684,
              42.730903
            ],
            [
              -99.676005,
              42.43445
            ],
            [
              -99.663752,
              42.434609
            ],
            [
              -99.662379,
              42.086009
            ],
            [
              -99.234629,
              42.087995
            ],
            [
              -99.234064,
              42.434524
            ],
            [
              -99.244591,
              42.434639
            ],
            [
              -99.245803,
              42.782499
            ],
            [
              -99.256846,
              42.782655
            ],
            [
              -99.257039,
              42.804296
            ],
            [
              -99.274937,
              42.794827
            ],
            [
              -99.30016,
              42.796171
            ],
            [
              -99.336428,
              42.780386
            ],
            [
              -99.377971,
              42.782214
            ],
            [
              -99.399571,
              42.771378
            ],
            [
              -99.415222,
              42.772098
            ],
            [
              -99.437057,
              42.753615
            ],
            [
              -99.4625,
              42.751626
            ],
            [
              -99.470428,
              42.738317
            ],
            [
              -99.537491,
              42.717626
            ],
            [
              -99.58169,
              42.724144
            ],
            [
              -99.625393,
              42.724084
            ],
            [
              -99.659861,
              42.735106
            ],
            [
              -99.677684,
              42.730903
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "139",
      "COUNTYNS": "00835891",
      "AFFGEOID": "0500000US31139",
      "GEOID": "31139",
      "NAME": "Pierce",
      "LSAD": "06",
      "ALAND": 1484707285,
      "AWATER": 3481943,
      "County_state": "Pierce,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.834431,
              42.437684
            ],
            [
              -97.834536,
              42.08975
            ],
            [
              -97.368404,
              42.090922
            ],
            [
              -97.367724,
              42.351861
            ],
            [
              -97.36759,
              42.438775
            ],
            [
              -97.485298,
              42.438578
            ],
            [
              -97.834431,
              42.437684
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "109",
      "COUNTYNS": "00835876",
      "AFFGEOID": "0500000US31109",
      "GEOID": "31109",
      "NAME": "Lancaster",
      "LSAD": "06",
      "ALAND": 2169252486,
      "AWATER": 22867561,
      "County_state": "Lancaster,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.913493,
              40.697948
            ],
            [
              -96.912637,
              40.523625
            ],
            [
              -96.707154,
              40.523077
            ],
            [
              -96.463632,
              40.523013
            ],
            [
              -96.463807,
              40.754956
            ],
            [
              -96.463764,
              40.78396
            ],
            [
              -96.463861,
              41.016069
            ],
            [
              -96.463869,
              41.045083
            ],
            [
              -96.60111,
              41.045444
            ],
            [
              -96.908507,
              41.046091
            ],
            [
              -96.91094,
              41.046116
            ],
            [
              -96.91069,
              40.813923
            ],
            [
              -96.911116,
              40.697975
            ],
            [
              -96.913493,
              40.697948
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "081",
      "COUNTYNS": "00835862",
      "AFFGEOID": "0500000US31081",
      "GEOID": "31081",
      "NAME": "Hamilton",
      "LSAD": "06",
      "ALAND": 1403509100,
      "AWATER": 7360265,
      "County_state": "Hamilton,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.282578,
              40.868677
            ],
            [
              -98.282851,
              40.698284
            ],
            [
              -98.278103,
              40.698291
            ],
            [
              -97.825723,
              40.698555
            ],
            [
              -97.826287,
              41.046581
            ],
            [
              -97.827957,
              41.050115
            ],
            [
              -97.828256,
              41.173439
            ],
            [
              -97.874073,
              41.123148
            ],
            [
              -97.88575,
              41.114648
            ],
            [
              -97.966797,
              41.085616
            ],
            [
              -97.970382,
              41.090841
            ],
            [
              -98.000733,
              41.07815
            ],
            [
              -98.026265,
              41.05351
            ],
            [
              -98.054169,
              41.037788
            ],
            [
              -98.088957,
              41.009659
            ],
            [
              -98.100648,
              41.008723
            ],
            [
              -98.146268,
              40.984283
            ],
            [
              -98.169777,
              40.954766
            ],
            [
              -98.206939,
              40.936593
            ],
            [
              -98.275126,
              40.879579
            ],
            [
              -98.282578,
              40.868677
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "119",
      "COUNTYNS": "00835880",
      "AFFGEOID": "0500000US31119",
      "GEOID": "31119",
      "NAME": "Madison",
      "LSAD": "06",
      "ALAND": 1483412284,
      "AWATER": 6998082,
      "County_state": "Madison,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.834536,
              42.08975
            ],
            [
              -97.833288,
              41.916286
            ],
            [
              -97.832462,
              41.747165
            ],
            [
              -97.830493,
              41.742238
            ],
            [
              -97.36807,
              41.743207
            ],
            [
              -97.368237,
              42.003966
            ],
            [
              -97.368404,
              42.090922
            ],
            [
              -97.834536,
              42.08975
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "121",
      "COUNTYNS": "00835882",
      "AFFGEOID": "0500000US31121",
      "GEOID": "31121",
      "NAME": "Merrick",
      "LSAD": "06",
      "ALAND": 1258350306,
      "AWATER": 28301129,
      "County_state": "Merrick,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.286584,
              41.393898
            ],
            [
              -98.287168,
              41.046363
            ],
            [
              -98.282355,
              41.042367
            ],
            [
              -98.282516,
              40.944814
            ],
            [
              -98.282545,
              40.91583
            ],
            [
              -98.282565,
              40.873548
            ],
            [
              -98.282578,
              40.868677
            ],
            [
              -98.275126,
              40.879579
            ],
            [
              -98.206939,
              40.936593
            ],
            [
              -98.169777,
              40.954766
            ],
            [
              -98.146268,
              40.984283
            ],
            [
              -98.100648,
              41.008723
            ],
            [
              -98.088957,
              41.009659
            ],
            [
              -98.054169,
              41.037788
            ],
            [
              -98.026265,
              41.05351
            ],
            [
              -98.000733,
              41.07815
            ],
            [
              -97.970382,
              41.090841
            ],
            [
              -97.966797,
              41.085616
            ],
            [
              -97.88575,
              41.114648
            ],
            [
              -97.874073,
              41.123148
            ],
            [
              -97.828256,
              41.173439
            ],
            [
              -97.800529,
              41.193314
            ],
            [
              -97.765999,
              41.23489
            ],
            [
              -97.711847,
              41.267207
            ],
            [
              -97.658554,
              41.304862
            ],
            [
              -97.630991,
              41.306745
            ],
            [
              -97.598205,
              41.317247
            ],
            [
              -97.598253,
              41.333119
            ],
            [
              -97.598461,
              41.395068
            ],
            [
              -97.703765,
              41.394876
            ],
            [
              -97.705497,
              41.309134
            ],
            [
              -97.898268,
              41.308224
            ],
            [
              -97.897943,
              41.29363
            ],
            [
              -98.053137,
              41.293123
            ],
            [
              -98.053133,
              41.278532
            ],
            [
              -98.10166,
              41.278654
            ],
            [
              -98.101546,
              41.264041
            ],
            [
              -98.275408,
              41.264341
            ],
            [
              -98.273892,
              41.393855
            ],
            [
              -98.286584,
              41.393898
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "015",
      "COUNTYNS": "00835829",
      "AFFGEOID": "0500000US31015",
      "GEOID": "31015",
      "NAME": "Boyd",
      "LSAD": "06",
      "ALAND": 1398425584,
      "AWATER": 11890320,
      "County_state": "Boyd,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.253965,
              42.904854
            ],
            [
              -99.257039,
              42.804296
            ],
            [
              -99.208541,
              42.822831
            ],
            [
              -99.180169,
              42.829129
            ],
            [
              -99.153803,
              42.841884
            ],
            [
              -99.128038,
              42.84427
            ],
            [
              -99.096293,
              42.856281
            ],
            [
              -99.05676,
              42.862595
            ],
            [
              -99.016792,
              42.886262
            ],
            [
              -98.982527,
              42.896436
            ],
            [
              -98.954634,
              42.894077
            ],
            [
              -98.888441,
              42.84348
            ],
            [
              -98.8778,
              42.84118
            ],
            [
              -98.840056,
              42.845588
            ],
            [
              -98.818283,
              42.830153
            ],
            [
              -98.797171,
              42.824364
            ],
            [
              -98.763223,
              42.827899
            ],
            [
              -98.710048,
              42.80348
            ],
            [
              -98.67462,
              42.8027
            ],
            [
              -98.656835,
              42.809021
            ],
            [
              -98.58376,
              42.779736
            ],
            [
              -98.548473,
              42.781649
            ],
            [
              -98.531191,
              42.774618
            ],
            [
              -98.490926,
              42.7731
            ],
            [
              -98.462932,
              42.766214
            ],
            [
              -98.416025,
              42.775674
            ],
            [
              -98.359869,
              42.767636
            ],
            [
              -98.340605,
              42.770661
            ],
            [
              -98.305149,
              42.761207
            ],
            [
              -98.310239,
              42.784618
            ],
            [
              -98.30976908505669,
              42.882559662424704
            ],
            [
              -98.319513,
              42.88454
            ],
            [
              -98.325864,
              42.8865
            ],
            [
              -98.329663,
              42.888441
            ],
            [
              -98.332423,
              42.890501
            ],
            [
              -98.333497,
              42.891532
            ],
            [
              -98.335846,
              42.895654
            ],
            [
              -98.33799,
              42.89776
            ],
            [
              -98.342408,
              42.900847
            ],
            [
              -98.34623,
              42.902747
            ],
            [
              -98.358047,
              42.907516
            ],
            [
              -98.375358,
              42.913132
            ],
            [
              -98.386445,
              42.918407
            ],
            [
              -98.399298,
              42.922465
            ],
            [
              -98.407824,
              42.92575
            ],
            [
              -98.42074,
              42.931924
            ],
            [
              -98.426287,
              42.9321
            ],
            [
              -98.430934,
              42.931504
            ],
            [
              -98.434503,
              42.929227
            ],
            [
              -98.437285,
              42.928393
            ],
            [
              -98.439743,
              42.928195
            ],
            [
              -98.444145,
              42.929242
            ],
            [
              -98.445861,
              42.93062
            ],
            [
              -98.447047,
              42.935117
            ],
            [
              -98.448309,
              42.936428
            ],
            [
              -98.45222,
              42.938389
            ],
            [
              -98.458515,
              42.943374
            ],
            [
              -98.461673,
              42.944427
            ],
            [
              -98.467356,
              42.947556
            ],
            [
              -98.478919,
              42.963539
            ],
            [
              -98.490483,
              42.977948
            ],
            [
              -98.495747,
              42.988032
            ],
            [
              -98.49855,
              42.99856
            ],
            [
              -98.565072,
              42.9984
            ],
            [
              -98.568936,
              42.998537
            ],
            [
              -98.663712,
              42.998444
            ],
            [
              -98.665613,
              42.998536
            ],
            [
              -98.742394,
              42.998343
            ],
            [
              -98.764378,
              42.998323
            ],
            [
              -98.801304,
              42.998241
            ],
            [
              -98.823989,
              42.99831
            ],
            [
              -98.899944,
              42.998122
            ],
            [
              -98.903154,
              42.998306
            ],
            [
              -98.919136,
              42.998242
            ],
            [
              -98.919234,
              42.998241
            ],
            [
              -98.962081,
              42.998286
            ],
            [
              -99.00037,
              42.998273
            ],
            [
              -99.021909,
              42.998365
            ],
            [
              -99.0223,
              42.998237
            ],
            [
              -99.080011,
              42.998357
            ],
            [
              -99.08188,
              42.998288
            ],
            [
              -99.135961,
              42.998301
            ],
            [
              -99.139045,
              42.998508
            ],
            [
              -99.151143,
              42.998344
            ],
            [
              -99.161388,
              42.998465
            ],
            [
              -99.195199,
              42.998107
            ],
            [
              -99.234462,
              42.998281
            ],
            [
              -99.254297,
              42.998138
            ],
            [
              -99.2544542492264,
              42.998139794357
            ],
            [
              -99.253965,
              42.904854
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "025",
      "COUNTYNS": "00835834",
      "AFFGEOID": "0500000US31025",
      "GEOID": "31025",
      "NAME": "Cass",
      "LSAD": "06",
      "ALAND": 1443578140,
      "AWATER": 22073649,
      "County_state": "Cass,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.463861,
              41.016069
            ],
            [
              -96.463764,
              40.78396
            ],
            [
              -95.911753,
              40.784154
            ],
            [
              -95.8342151936933,
              40.7837840663969
            ],
            [
              -95.834523,
              40.787778
            ],
            [
              -95.835815,
              40.79063
            ],
            [
              -95.843745,
              40.803783
            ],
            [
              -95.845342,
              40.811324
            ],
            [
              -95.844852,
              40.815307
            ],
            [
              -95.843921,
              40.817686
            ],
            [
              -95.838601,
              40.826175
            ],
            [
              -95.837303,
              40.831164
            ],
            [
              -95.837186,
              40.835347
            ],
            [
              -95.841309,
              40.845604
            ],
            [
              -95.847084,
              40.854174
            ],
            [
              -95.84849,
              40.858607
            ],
            [
              -95.84859,
              40.861061
            ],
            [
              -95.847785,
              40.864328
            ],
            [
              -95.846938,
              40.865745
            ],
            [
              -95.844073,
              40.869248
            ],
            [
              -95.842521,
              40.870266
            ],
            [
              -95.838735,
              40.872191
            ],
            [
              -95.824989,
              40.875
            ],
            [
              -95.81959,
              40.877439
            ],
            [
              -95.815933,
              40.879846
            ],
            [
              -95.812083,
              40.884239
            ],
            [
              -95.810709,
              40.886681
            ],
            [
              -95.809474,
              40.891228
            ],
            [
              -95.809775,
              40.895447
            ],
            [
              -95.810886,
              40.897907
            ],
            [
              -95.8134577460103,
              40.901693097976
            ],
            [
              -95.814302,
              40.902936
            ],
            [
              -95.818709,
              40.906818
            ],
            [
              -95.830699,
              40.915004
            ],
            [
              -95.833041,
              40.917243
            ],
            [
              -95.836438,
              40.921642
            ],
            [
              -95.837774,
              40.924712
            ],
            [
              -95.839743,
              40.93278
            ],
            [
              -95.840275,
              40.939942
            ],
            [
              -95.837951,
              40.950618
            ],
            [
              -95.829829,
              40.963857
            ],
            [
              -95.828329,
              40.972378
            ],
            [
              -95.829074,
              40.975688
            ],
            [
              -95.830297,
              40.978332
            ],
            [
              -95.833537,
              40.98266
            ],
            [
              -95.838908,
              40.986484
            ],
            [
              -95.844351,
              40.989524
            ],
            [
              -95.852547,
              40.991738
            ],
            [
              -95.860116,
              40.995242
            ],
            [
              -95.863492,
              40.99734
            ],
            [
              -95.867286,
              41.001599
            ],
            [
              -95.869198,
              41.005951
            ],
            [
              -95.869486,
              41.009399
            ],
            [
              -95.868374,
              41.012703
            ],
            [
              -95.865878,
              41.017403
            ],
            [
              -95.859918,
              41.025403
            ],
            [
              -95.859102,
              41.031599
            ],
            [
              -95.859654,
              41.035695
            ],
            [
              -95.860462,
              41.037887
            ],
            [
              -95.861782,
              41.039427
            ],
            [
              -95.869807,
              41.045199
            ],
            [
              -95.879487,
              41.053299
            ],
            [
              -95.889215,
              41.058443
            ],
            [
              -95.944852,
              41.057914
            ],
            [
              -95.946137,
              41.057616
            ],
            [
              -95.947374,
              41.057616
            ],
            [
              -95.948439,
              41.057925
            ],
            [
              -95.949531,
              41.058433
            ],
            [
              -95.987337,
              41.052717
            ],
            [
              -96.005191,
              41.060624
            ],
            [
              -96.034062,
              41.066001
            ],
            [
              -96.056256,
              41.065356
            ],
            [
              -96.109865,
              41.048404
            ],
            [
              -96.144693,
              41.017598
            ],
            [
              -96.207648,
              40.995628
            ],
            [
              -96.218939,
              40.994308
            ],
            [
              -96.232961,
              41.000855
            ],
            [
              -96.25078,
              41.018754
            ],
            [
              -96.284387,
              41.021746
            ],
            [
              -96.299796,
              41.028002
            ],
            [
              -96.319191,
              41.044998
            ],
            [
              -96.349079,
              41.044525
            ],
            [
              -96.349471,
              41.015211
            ],
            [
              -96.463861,
              41.016069
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "135",
      "COUNTYNS": "00835889",
      "AFFGEOID": "0500000US31135",
      "GEOID": "31135",
      "NAME": "Perkins",
      "LSAD": "06",
      "ALAND": 2287828022,
      "AWATER": 2840176,
      "County_state": "Perkins,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -102.051761,
              41.00389
            ],
            [
              -102.05171752451801,
              41.0023772508495
            ],
            [
              -102.051614,
              41.002377
            ],
            [
              -102.051292,
              40.749591
            ],
            [
              -102.05129201074301,
              40.74958574646119
            ],
            [
              -102.05139843250801,
              40.697541816873404
            ],
            [
              -101.345365,
              40.698093
            ],
            [
              -101.247963,
              40.697802
            ],
            [
              -101.249975,
              41.00395
            ],
            [
              -102.051761,
              41.00389
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "129",
      "COUNTYNS": "00835886",
      "AFFGEOID": "0500000US31129",
      "GEOID": "31129",
      "NAME": "Nuckolls",
      "LSAD": "06",
      "ALAND": 1489645186,
      "AWATER": 1718484,
      "County_state": "Nuckolls,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.2740168698569,
              40.002515998333195
            ],
            [
              -98.274015,
              40.002516
            ],
            [
              -98.268218,
              40.00249
            ],
            [
              -98.250008,
              40.002307
            ],
            [
              -98.193483,
              40.002614
            ],
            [
              -98.179315,
              40.002483
            ],
            [
              -98.172269,
              40.002438
            ],
            [
              -98.15684409451629,
              40.0024451416323
            ],
            [
              -98.142031,
              40.002452
            ],
            [
              -98.099659,
              40.002227
            ],
            [
              -98.076034,
              40.002301
            ],
            [
              -98.068701,
              40.002355
            ],
            [
              -98.050057,
              40.002278
            ],
            [
              -98.047469,
              40.002186
            ],
            [
              -98.014412,
              40.002223
            ],
            [
              -98.010157,
              40.002153
            ],
            [
              -97.972186,
              40.002114
            ],
            [
              -97.93182554601101,
              40.0020500230574
            ],
            [
              -97.931811,
              40.00205
            ],
            [
              -97.876261,
              40.002102
            ],
            [
              -97.85745,
              40.002065
            ],
            [
              -97.838379,
              40.00191
            ],
            [
              -97.821598,
              40.002004
            ],
            [
              -97.8214959564515,
              40.0020018388567
            ],
            [
              -97.820816,
              40.350545
            ],
            [
              -97.824114,
              40.350541
            ],
            [
              -98.273571,
              40.350359
            ],
            [
              -98.2740168698569,
              40.002515998333195
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "065",
      "COUNTYNS": "00835854",
      "AFFGEOID": "0500000US31065",
      "GEOID": "31065",
      "NAME": "Furnas",
      "LSAD": "06",
      "ALAND": 1862542307,
      "AWATER": 3915843,
      "County_state": "Furnas,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.197807,
              40.350027
            ],
            [
              -100.193596885795,
              40.001572838534294
            ],
            [
              -100.19359,
              40.001573
            ],
            [
              -100.190323,
              40.001586
            ],
            [
              -100.188181,
              40.001541
            ],
            [
              -100.177823,
              40.001593
            ],
            [
              -100.177794883236,
              40.001592986460395
            ],
            [
              -99.990926,
              40.001503
            ],
            [
              -99.986611,
              40.00155
            ],
            [
              -99.948167,
              40.001813
            ],
            [
              -99.944417,
              40.001584
            ],
            [
              -99.930433,
              40.001516
            ],
            [
              -99.906658,
              40.001512
            ],
            [
              -99.813401,
              40.0014
            ],
            [
              -99.77564,
              40.001647
            ],
            [
              -99.772121,
              40.001804
            ],
            [
              -99.764214,
              40.001551
            ],
            [
              -99.756835,
              40.001342
            ],
            [
              -99.746628,
              40.00182
            ],
            [
              -99.731959,
              40.001827
            ],
            [
              -99.719639,
              40.001808
            ],
            [
              -99.628346,
              40.001866
            ],
            [
              -99.6282545420969,
              40.0018659613449
            ],
            [
              -99.630459,
              40.35112
            ],
            [
              -99.642209,
              40.3512
            ],
            [
              -100.094798,
              40.351245
            ],
            [
              -100.197807,
              40.350027
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "051",
      "COUNTYNS": "00835847",
      "AFFGEOID": "0500000US31051",
      "GEOID": "31051",
      "NAME": "Dixon",
      "LSAD": "06",
      "ALAND": 1233156423,
      "AWATER": 17075472,
      "County_state": "Dixon,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.017789,
              42.351347
            ],
            [
              -97.01773,
              42.264453
            ],
            [
              -96.823077,
              42.264292
            ],
            [
              -96.822497,
              42.264292
            ],
            [
              -96.726188,
              42.264104
            ],
            [
              -96.726661,
              42.278005
            ],
            [
              -96.726618,
              42.280417
            ],
            [
              -96.726411,
              42.429566
            ],
            [
              -96.721951,
              42.437461
            ],
            [
              -96.722231,
              42.524652
            ],
            [
              -96.63149355043069,
              42.5243180024038
            ],
            [
              -96.631494,
              42.524319
            ],
            [
              -96.632882,
              42.528987
            ],
            [
              -96.633343,
              42.531984
            ],
            [
              -96.633321,
              42.540211
            ],
            [
              -96.63533,
              42.54764
            ],
            [
              -96.638033,
              42.55196
            ],
            [
              -96.643589,
              42.557604
            ],
            [
              -96.648135,
              42.560877
            ],
            [
              -96.658754,
              42.566426
            ],
            [
              -96.675952,
              42.5716
            ],
            [
              -96.681369,
              42.574486
            ],
            [
              -96.685746,
              42.577944
            ],
            [
              -96.697313,
              42.590412
            ],
            [
              -96.706416,
              42.599413
            ],
            [
              -96.7093,
              42.603753
            ],
            [
              -96.710995,
              42.608128
            ],
            [
              -96.711546,
              42.614758
            ],
            [
              -96.711312,
              42.617375
            ],
            [
              -96.709485,
              42.621932
            ],
            [
              -96.70729,
              42.625317
            ],
            [
              -96.696852,
              42.637596
            ],
            [
              -96.692599,
              42.64204
            ],
            [
              -96.689083,
              42.644081
            ],
            [
              -96.687788,
              42.645992
            ],
            [
              -96.686982,
              42.649783
            ],
            [
              -96.687082,
              42.652093
            ],
            [
              -96.687669,
              42.653126
            ],
            [
              -96.691269,
              42.6562
            ],
            [
              -96.697639,
              42.659143
            ],
            [
              -96.728024,
              42.666882
            ],
            [
              -96.73546,
              42.667164
            ],
            [
              -96.746949,
              42.666223
            ],
            [
              -96.749372,
              42.665733
            ],
            [
              -96.751239,
              42.66436
            ],
            [
              -96.76406,
              42.661985
            ],
            [
              -96.778182,
              42.662993
            ],
            [
              -96.793238,
              42.666024
            ],
            [
              -96.798745,
              42.668243
            ],
            [
              -96.800986,
              42.669758
            ],
            [
              -96.802178,
              42.672237
            ],
            [
              -96.800193,
              42.684346
            ],
            [
              -96.800485,
              42.692466
            ],
            [
              -96.801652,
              42.698774
            ],
            [
              -96.80621880764551,
              42.7041490656603
            ],
            [
              -96.806223,
              42.704154
            ],
            [
              -96.813148,
              42.706397
            ],
            [
              -96.819452,
              42.707774
            ],
            [
              -96.829554,
              42.708441
            ],
            [
              -96.843419,
              42.712024
            ],
            [
              -96.849956,
              42.715034
            ],
            [
              -96.860436,
              42.720797
            ],
            [
              -96.872789,
              42.724096
            ],
            [
              -96.886845,
              42.725222
            ],
            [
              -96.906797,
              42.7338
            ],
            [
              -96.920494,
              42.731432
            ],
            [
              -96.924156,
              42.730327
            ],
            [
              -96.930247,
              42.726441
            ],
            [
              -96.936773,
              42.723428
            ],
            [
              -96.941111,
              42.721569
            ],
            [
              -96.948902,
              42.719465
            ],
            [
              -96.955862,
              42.719178
            ],
            [
              -96.961576,
              42.719841
            ],
            [
              -96.963531,
              42.720643
            ],
            [
              -96.964776,
              42.722455
            ],
            [
              -96.965679,
              42.724532
            ],
            [
              -96.965833,
              42.727096
            ],
            [
              -96.961291,
              42.736569
            ],
            [
              -96.960866,
              42.739089
            ],
            [
              -96.96123,
              42.740623
            ],
            [
              -96.96888,
              42.754278
            ],
            [
              -96.975339,
              42.758321
            ],
            [
              -96.97912,
              42.76009
            ],
            [
              -96.982197,
              42.760554
            ],
            [
              -96.99282,
              42.759481
            ],
            [
              -97.0158249637164,
              42.761599065501095
            ],
            [
              -97.017705,
              42.437913
            ],
            [
              -97.017789,
              42.351347
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "067",
      "COUNTYNS": "00835855",
      "AFFGEOID": "0500000US31067",
      "GEOID": "31067",
      "NAME": "Gage",
      "LSAD": "06",
      "ALAND": 2205275212,
      "AWATER": 22010737,
      "County_state": "Gage,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.9164069976229,
              40.0015058549306
            ],
            [
              -96.916093,
              40.001506
            ],
            [
              -96.880459,
              40.001448
            ],
            [
              -96.878253,
              40.001466
            ],
            [
              -96.875057,
              40.001448
            ],
            [
              -96.873812,
              40.00145
            ],
            [
              -96.805768,
              40.0013709706497
            ],
            [
              -96.622401,
              40.001158
            ],
            [
              -96.610349,
              40.000881
            ],
            [
              -96.604884,
              40.000891
            ],
            [
              -96.58178800024619,
              40.000963078894
            ],
            [
              -96.580852,
              40.000966
            ],
            [
              -96.570854,
              40.001091
            ],
            [
              -96.557863,
              40.000968
            ],
            [
              -96.538977,
              40.000851
            ],
            [
              -96.527111,
              40.001031
            ],
            [
              -96.469945,
              40.000966
            ],
            [
              -96.467536,
              40.001035
            ],
            [
              -96.463712955188,
              40.0009682733452
            ],
            [
              -96.463667,
              40.262173
            ],
            [
              -96.463632,
              40.523013
            ],
            [
              -96.707154,
              40.523077
            ],
            [
              -96.912637,
              40.523625
            ],
            [
              -96.913433,
              40.353282
            ],
            [
              -96.916055,
              40.349727
            ],
            [
              -96.9164069976229,
              40.0015058549306
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "113",
      "COUNTYNS": "00835878",
      "AFFGEOID": "0500000US31113",
      "GEOID": "31113",
      "NAME": "Logan",
      "LSAD": "06",
      "ALAND": 1478003937,
      "AWATER": 1203586,
      "County_state": "Logan,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.714847,
              41.544806
            ],
            [
              -100.713617,
              41.523535
            ],
            [
              -100.713243,
              41.393511
            ],
            [
              -100.251098,
              41.393298
            ],
            [
              -100.251224,
              41.739817
            ],
            [
              -100.265474,
              41.739856
            ],
            [
              -100.711711,
              41.739761
            ],
            [
              -100.714847,
              41.544806
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "043",
      "COUNTYNS": "00835843",
      "AFFGEOID": "0500000US31043",
      "GEOID": "31043",
      "NAME": "Dakota",
      "LSAD": "06",
      "ALAND": 684416197,
      "AWATER": 8199748,
      "County_state": "Dakota,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.726618,
              42.280417
            ],
            [
              -96.726661,
              42.278005
            ],
            [
              -96.3564060678816,
              42.2764931056257
            ],
            [
              -96.3608,
              42.279867
            ],
            [
              -96.365792,
              42.285875
            ],
            [
              -96.368454,
              42.291848
            ],
            [
              -96.368507,
              42.303622
            ],
            [
              -96.369212,
              42.308344
            ],
            [
              -96.369969,
              42.310878
            ],
            [
              -96.37179,
              42.314172
            ],
            [
              -96.375307,
              42.318339
            ],
            [
              -96.384169,
              42.325874
            ],
            [
              -96.396269,
              42.330857
            ],
            [
              -96.402957,
              42.334156
            ],
            [
              -96.407998,
              42.337408
            ],
            [
              -96.413895,
              42.343393
            ],
            [
              -96.417786,
              42.351449
            ],
            [
              -96.418168,
              42.354678
            ],
            [
              -96.417918,
              42.3587
            ],
            [
              -96.417093,
              42.361443
            ],
            [
              -96.413994,
              42.365932
            ],
            [
              -96.408436,
              42.376092
            ],
            [
              -96.409153,
              42.381491
            ],
            [
              -96.41498,
              42.393442
            ],
            [
              -96.415509,
              42.400294
            ],
            [
              -96.413609,
              42.407894
            ],
            [
              -96.411808,
              42.410894
            ],
            [
              -96.387608,
              42.432494
            ],
            [
              -96.384307,
              42.437294
            ],
            [
              -96.380707,
              42.446394
            ],
            [
              -96.380107,
              42.451494
            ],
            [
              -96.381307,
              42.461694
            ],
            [
              -96.385407,
              42.473094
            ],
            [
              -96.386007,
              42.474495
            ],
            [
              -96.396107,
              42.484095
            ],
            [
              -96.3961883611554,
              42.4841164092206
            ],
            [
              -96.409408,
              42.487595
            ],
            [
              -96.4134338764416,
              42.487979965401195
            ],
            [
              -96.423892,
              42.48898
            ],
            [
              -96.445508,
              42.49063
            ],
            [
              -96.459709,
              42.486037
            ],
            [
              -96.465671,
              42.483132
            ],
            [
              -96.475565,
              42.480036
            ],
            [
              -96.478792,
              42.479635
            ],
            [
              -96.489497,
              42.480112
            ],
            [
              -96.501321,
              42.482749
            ],
            [
              -96.505704,
              42.484723
            ],
            [
              -96.508587,
              42.486691
            ],
            [
              -96.515891,
              42.49427
            ],
            [
              -96.517557,
              42.496902
            ],
            [
              -96.518752,
              42.500839
            ],
            [
              -96.520683,
              42.504761
            ],
            [
              -96.525142,
              42.510234
            ],
            [
              -96.528753,
              42.513273
            ],
            [
              -96.531616,
              42.51517
            ],
            [
              -96.538036,
              42.518131
            ],
            [
              -96.548791,
              42.520547
            ],
            [
              -96.557775,
              42.52038
            ],
            [
              -96.567896,
              42.517877
            ],
            [
              -96.57251,
              42.515737
            ],
            [
              -96.584348,
              42.507834
            ],
            [
              -96.591121,
              42.50541
            ],
            [
              -96.595992,
              42.504621
            ],
            [
              -96.603468,
              42.50446
            ],
            [
              -96.608883,
              42.505218
            ],
            [
              -96.611489,
              42.506088
            ],
            [
              -96.625958,
              42.513576
            ],
            [
              -96.628179,
              42.516963
            ],
            [
              -96.63149355043069,
              42.5243180024038
            ],
            [
              -96.722231,
              42.524652
            ],
            [
              -96.721951,
              42.437461
            ],
            [
              -96.726411,
              42.429566
            ],
            [
              -96.726618,
              42.280417
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "127",
      "COUNTYNS": "00835885",
      "AFFGEOID": "0500000US31127",
      "GEOID": "31127",
      "NAME": "Nemaha",
      "LSAD": "06",
      "ALAND": 1055100608,
      "AWATER": 5769943,
      "County_state": "Nemaha,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.067012,
              40.523148
            ],
            [
              -96.066736,
              40.262038
            ],
            [
              -96.010176,
              40.261482
            ],
            [
              -95.552473,
              40.261904
            ],
            [
              -95.554324,
              40.263395
            ],
            [
              -95.554817342733,
              40.26445984121119
            ],
            [
              -95.556325,
              40.267714
            ],
            [
              -95.556275,
              40.270761
            ],
            [
              -95.551488,
              40.281061
            ],
            [
              -95.550966,
              40.285947
            ],
            [
              -95.55162,
              40.288666
            ],
            [
              -95.553292,
              40.291158
            ],
            [
              -95.558732,
              40.295774
            ],
            [
              -95.562157,
              40.297359
            ],
            [
              -95.56814,
              40.299129
            ],
            [
              -95.581787,
              40.29958
            ],
            [
              -95.587371,
              40.301649
            ],
            [
              -95.590165,
              40.303199
            ],
            [
              -95.598657,
              40.309809
            ],
            [
              -95.60511,
              40.312559
            ],
            [
              -95.610439,
              40.31397
            ],
            [
              -95.613479,
              40.314233
            ],
            [
              -95.617931,
              40.313728
            ],
            [
              -95.623213,
              40.312469
            ],
            [
              -95.63631,
              40.307675
            ],
            [
              -95.642262,
              40.306025
            ],
            [
              -95.645329,
              40.305693
            ],
            [
              -95.651507,
              40.306684
            ],
            [
              -95.654294,
              40.307906
            ],
            [
              -95.657328,
              40.310856
            ],
            [
              -95.658025,
              40.3127
            ],
            [
              -95.657764,
              40.315788
            ],
            [
              -95.656612,
              40.319465
            ],
            [
              -95.653729,
              40.322582
            ],
            [
              -95.647931,
              40.325556
            ],
            [
              -95.633807,
              40.329297
            ],
            [
              -95.629936,
              40.330994
            ],
            [
              -95.625204,
              40.334288
            ],
            [
              -95.623182,
              40.338367
            ],
            [
              -95.622704,
              40.340856
            ],
            [
              -95.623728,
              40.346567
            ],
            [
              -95.624815,
              40.349214
            ],
            [
              -95.627124,
              40.3528
            ],
            [
              -95.631481,
              40.35731
            ],
            [
              -95.636991,
              40.36136
            ],
            [
              -95.641027,
              40.366399
            ],
            [
              -95.642414,
              40.369829
            ],
            [
              -95.642679,
              40.375001
            ],
            [
              -95.642147,
              40.378243
            ],
            [
              -95.643934,
              40.386849
            ],
            [
              -95.649418,
              40.396149
            ],
            [
              -95.659134,
              40.40869
            ],
            [
              -95.661463,
              40.415947
            ],
            [
              -95.660721,
              40.418841
            ],
            [
              -95.65831,
              40.424538
            ],
            [
              -95.656288,
              40.428765
            ],
            [
              -95.65563,
              40.434736
            ],
            [
              -95.65819,
              40.44188
            ],
            [
              -95.665413,
              40.451182
            ],
            [
              -95.671742,
              40.456695
            ],
            [
              -95.677174,
              40.460158
            ],
            [
              -95.684363,
              40.463366
            ],
            [
              -95.693133,
              40.469396
            ],
            [
              -95.694651,
              40.471452
            ],
            [
              -95.696365,
              40.475897
            ],
            [
              -95.696756,
              40.478849
            ],
            [
              -95.696206,
              40.482113
            ],
            [
              -95.695247,
              40.486587
            ],
            [
              -95.694726,
              40.493602
            ],
            [
              -95.695945,
              40.499051
            ],
            [
              -95.699969,
              40.505275
            ],
            [
              -95.695604,
              40.506473
            ],
            [
              -95.692083,
              40.508133
            ],
            [
              -95.667981,
              40.51496
            ],
            [
              -95.661687,
              40.517309
            ],
            [
              -95.655674,
              40.523557
            ],
            [
              -95.652262,
              40.538114
            ],
            [
              -95.65341,
              40.541893
            ],
            [
              -95.655848,
              40.546609
            ],
            [
              -95.662097,
              40.549959
            ],
            [
              -95.665486,
              40.556686
            ],
            [
              -95.671754,
              40.562626
            ],
            [
              -95.678718,
              40.56256
            ],
            [
              -95.687109,
              40.560664
            ],
            [
              -95.694147,
              40.556942
            ],
            [
              -95.694881,
              40.55072
            ],
            [
              -95.696673,
              40.545137
            ],
            [
              -95.697281,
              40.536985
            ],
            [
              -95.69505,
              40.533124
            ],
            [
              -95.69721,
              40.528477
            ],
            [
              -95.708591,
              40.521551
            ],
            [
              -95.709974,
              40.523798
            ],
            [
              -96.067012,
              40.523148
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "049",
      "COUNTYNS": "00835846",
      "AFFGEOID": "0500000US31049",
      "GEOID": "31049",
      "NAME": "Deuel",
      "LSAD": "06",
      "ALAND": 1139208969,
      "AWATER": 2230956,
      "County_state": "Deuel,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -102.620872,
              41.048
            ],
            [
              -102.621033,
              41.002597
            ],
            [
              -102.578696,
              41.002291
            ],
            [
              -102.575738,
              41.002268
            ],
            [
              -102.575496,
              41.0022
            ],
            [
              -102.566048,
              41.0022
            ],
            [
              -102.556789,
              41.002219
            ],
            [
              -102.487955,
              41.002445
            ],
            [
              -102.470537,
              41.002382
            ],
            [
              -102.469223,
              41.002424
            ],
            [
              -102.379593,
              41.002301
            ],
            [
              -102.364066,
              41.002174
            ],
            [
              -102.292833,
              41.002207
            ],
            [
              -102.292622,
              41.00223
            ],
            [
              -102.292553,
              41.002207
            ],
            [
              -102.291354,
              41.002207
            ],
            [
              -102.2721,
              41.002245
            ],
            [
              -102.267812,
              41.002383
            ],
            [
              -102.231931,
              41.002327
            ],
            [
              -102.2122,
              41.002462
            ],
            [
              -102.209361,
              41.002442
            ],
            [
              -102.19121,
              41.002326
            ],
            [
              -102.124972,
              41.002338
            ],
            [
              -102.070598,
              41.002423
            ],
            [
              -102.05171752451801,
              41.0023772508495
            ],
            [
              -102.051761,
              41.00389
            ],
            [
              -102.055528,
              41.047457
            ],
            [
              -102.055535,
              41.221547
            ],
            [
              -102.610413,
              41.221614
            ],
            [
              -102.610213,
              41.048207
            ],
            [
              -102.620872,
              41.048
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "173",
      "COUNTYNS": "00835908",
      "AFFGEOID": "0500000US31173",
      "GEOID": "31173",
      "NAME": "Thurston",
      "LSAD": "06",
      "ALAND": 1019377255,
      "AWATER": 6458661,
      "County_state": "Thurston,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.822497,
              42.264292
            ],
            [
              -96.82367,
              42.090411
            ],
            [
              -96.555511,
              42.089957
            ],
            [
              -96.554866,
              42.015875
            ],
            [
              -96.460374,
              42.017053
            ],
            [
              -96.309645,
              42.015187
            ],
            [
              -96.309389,
              42.046692
            ],
            [
              -96.2729013791207,
              42.047281245329
            ],
            [
              -96.275548,
              42.051976
            ],
            [
              -96.278445,
              42.060399
            ],
            [
              -96.279342,
              42.07028
            ],
            [
              -96.279079,
              42.074026
            ],
            [
              -96.276758,
              42.081416
            ],
            [
              -96.274135,
              42.085934
            ],
            [
              -96.271777,
              42.088697
            ],
            [
              -96.267636,
              42.096177
            ],
            [
              -96.266594,
              42.103262
            ],
            [
              -96.267318,
              42.110265
            ],
            [
              -96.2689,
              42.11359
            ],
            [
              -96.272299,
              42.118396
            ],
            [
              -96.275002,
              42.120779
            ],
            [
              -96.279203,
              42.12348
            ],
            [
              -96.28567,
              42.125619
            ],
            [
              -96.301023,
              42.128042
            ],
            [
              -96.305884,
              42.129826
            ],
            [
              -96.310085,
              42.132523
            ],
            [
              -96.313819,
              42.136338
            ],
            [
              -96.316979,
              42.143171
            ],
            [
              -96.319528,
              42.146647
            ],
            [
              -96.325872,
              42.151487
            ],
            [
              -96.33798,
              42.157197
            ],
            [
              -96.342395,
              42.160491
            ],
            [
              -96.347752,
              42.166806
            ],
            [
              -96.349688,
              42.172043
            ],
            [
              -96.350323,
              42.17744
            ],
            [
              -96.347243,
              42.186721
            ],
            [
              -96.348066,
              42.194747
            ],
            [
              -96.349166,
              42.197253
            ],
            [
              -96.351515,
              42.200485
            ],
            [
              -96.359087,
              42.207799
            ],
            [
              -96.35987,
              42.210545
            ],
            [
              -96.358141,
              42.214088
            ],
            [
              -96.35665510863,
              42.2151367517153
            ],
            [
              -96.356591,
              42.215182
            ],
            [
              -96.345055,
              42.21749
            ],
            [
              -96.339086,
              42.218087
            ],
            [
              -96.336323,
              42.218922
            ],
            [
              -96.332044,
              42.221585
            ],
            [
              -96.323723,
              42.229887
            ],
            [
              -96.322827,
              42.231461
            ],
            [
              -96.322868,
              42.233637
            ],
            [
              -96.330004,
              42.240224
            ],
            [
              -96.328955,
              42.241885
            ],
            [
              -96.327706,
              42.249992
            ],
            [
              -96.328905,
              42.254734
            ],
            [
              -96.331331,
              42.25943
            ],
            [
              -96.336003,
              42.264806
            ],
            [
              -96.34145,
              42.269115
            ],
            [
              -96.356389,
              42.27648
            ],
            [
              -96.3564060678816,
              42.2764931056257
            ],
            [
              -96.726661,
              42.278005
            ],
            [
              -96.726188,
              42.264104
            ],
            [
              -96.822497,
              42.264292
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "161",
      "COUNTYNS": "00835902",
      "AFFGEOID": "0500000US31161",
      "GEOID": "31161",
      "NAME": "Sheridan",
      "LSAD": "06",
      "ALAND": 6321833793,
      "AWATER": 74941797,
      "County_state": "Sheridan,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -102.792111,
              42.99998
            ],
            [
              -102.792659,
              42.785165
            ],
            [
              -102.773369,
              42.785265
            ],
            [
              -102.773366,
              42.439922
            ],
            [
              -102.749431,
              42.439934
            ],
            [
              -102.749848,
              42.418126
            ],
            [
              -102.751533,
              42.357112
            ],
            [
              -102.746468,
              42.249967
            ],
            [
              -102.741581,
              42.177721
            ],
            [
              -102.742269,
              42.092388
            ],
            [
              -102.702383,
              42.092747
            ],
            [
              -102.7021,
              42.051323
            ],
            [
              -102.697835,
              42.004843
            ],
            [
              -102.677582,
              42.005282
            ],
            [
              -102.06665,
              42.009195
            ],
            [
              -102.008562,
              42.008901
            ],
            [
              -102.006204,
              42.096338
            ],
            [
              -102.04028,
              42.096744
            ],
            [
              -102.038169,
              42.443307
            ],
            [
              -102.066689,
              42.443463
            ],
            [
              -102.066905,
              42.78549
            ],
            [
              -102.084961,
              42.785509
            ],
            [
              -102.082546,
              42.999356
            ],
            [
              -102.440547,
              42.999609
            ],
            [
              -102.487329,
              42.999559
            ],
            [
              -102.792111,
              42.99998
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "165",
      "COUNTYNS": "00835904",
      "AFFGEOID": "0500000US31165",
      "GEOID": "31165",
      "NAME": "Sioux",
      "LSAD": "06",
      "ALAND": 5352538105,
      "AWATER": 1872668,
      "County_state": "Sioux,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -104.053127,
              43.000585
            ],
            [
              -104.052863,
              42.754569
            ],
            [
              -104.052809,
              42.749966
            ],
            [
              -104.052583,
              42.650062
            ],
            [
              -104.052741,
              42.633982
            ],
            [
              -104.052586,
              42.630917
            ],
            [
              -104.052773275295,
              42.611766367052894
            ],
            [
              -104.052775,
              42.61159
            ],
            [
              -104.052775,
              42.610813
            ],
            [
              -104.053107,
              42.499964
            ],
            [
              -104.052776,
              42.25822
            ],
            [
              -104.052793,
              42.249962
            ],
            [
              -104.053125,
              42.249962
            ],
            [
              -104.052761,
              42.170278
            ],
            [
              -104.052547,
              42.166801
            ],
            [
              -104.053001,
              42.137254
            ],
            [
              -104.052738,
              42.133769
            ],
            [
              -104.0526,
              42.124963
            ],
            [
              -104.052954,
              42.089077
            ],
            [
              -104.052966926101,
              42.075083999008
            ],
            [
              -104.052967,
              42.075004
            ],
            [
              -104.05288,
              42.021761
            ],
            [
              -104.052729,
              42.016318
            ],
            [
              -104.052704176933,
              42.0017178993875
            ],
            [
              -103.401639,
              42.00354
            ],
            [
              -103.401667,
              42.090562
            ],
            [
              -103.444108,
              42.090708
            ],
            [
              -103.444532,
              42.437354
            ],
            [
              -103.480082,
              42.437267
            ],
            [
              -103.480403,
              42.784966
            ],
            [
              -103.503232,
              42.784787
            ],
            [
              -103.505099997425,
              43.0007699609789
            ],
            [
              -103.505219,
              43.00077
            ],
            [
              -103.506151,
              43.000771
            ],
            [
              -103.506556,
              43.000771
            ],
            [
              -103.576329,
              43.000807
            ],
            [
              -103.576966,
              43.000746
            ],
            [
              -103.618334,
              43.000679
            ],
            [
              -103.652919,
              43.001409
            ],
            [
              -103.715084,
              43.000983
            ],
            [
              -103.813939,
              43.001378
            ],
            [
              -103.815573,
              43.001279
            ],
            [
              -103.924921,
              43.000918
            ],
            [
              -103.96627,
              43.001708
            ],
            [
              -103.991077,
              43.001691
            ],
            [
              -104.053127,
              43.000585
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "153",
      "COUNTYNS": "00835898",
      "AFFGEOID": "0500000US31153",
      "GEOID": "31153",
      "NAME": "Sarpy",
      "LSAD": "06",
      "ALAND": 618872493,
      "AWATER": 22209229,
      "County_state": "Sarpy,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.33287,
              41.099622
            ],
            [
              -96.339805,
              41.082502
            ],
            [
              -96.319191,
              41.044998
            ],
            [
              -96.299796,
              41.028002
            ],
            [
              -96.284387,
              41.021746
            ],
            [
              -96.25078,
              41.018754
            ],
            [
              -96.232961,
              41.000855
            ],
            [
              -96.218939,
              40.994308
            ],
            [
              -96.207648,
              40.995628
            ],
            [
              -96.144693,
              41.017598
            ],
            [
              -96.109865,
              41.048404
            ],
            [
              -96.056256,
              41.065356
            ],
            [
              -96.034062,
              41.066001
            ],
            [
              -96.005191,
              41.060624
            ],
            [
              -95.987337,
              41.052717
            ],
            [
              -95.949531,
              41.058433
            ],
            [
              -95.948439,
              41.057925
            ],
            [
              -95.947374,
              41.057616
            ],
            [
              -95.946137,
              41.057616
            ],
            [
              -95.944852,
              41.057914
            ],
            [
              -95.889215,
              41.058443
            ],
            [
              -95.879487,
              41.053299
            ],
            [
              -95.881855,
              41.057211
            ],
            [
              -95.882415,
              41.060411
            ],
            [
              -95.881375,
              41.065203
            ],
            [
              -95.878103,
              41.069587
            ],
            [
              -95.870631,
              41.075231
            ],
            [
              -95.865463,
              41.080367
            ],
            [
              -95.863839,
              41.083507
            ],
            [
              -95.862587,
              41.088399
            ],
            [
              -95.863268,
              41.093765
            ],
            [
              -95.86545,
              41.101266
            ],
            [
              -95.865888,
              41.117898
            ],
            [
              -95.868688,
              41.124698
            ],
            [
              -95.878888,
              41.138098
            ],
            [
              -95.882088,
              41.143998
            ],
            [
              -95.883389,
              41.150898
            ],
            [
              -95.883489,
              41.154898
            ],
            [
              -95.881289,
              41.159898
            ],
            [
              -95.8809355605553,
              41.160268970041194
            ],
            [
              -95.876289,
              41.165146
            ],
            [
              -95.871912,
              41.168122
            ],
            [
              -95.86964,
              41.16883
            ],
            [
              -95.867344,
              41.168734
            ],
            [
              -95.865072,
              41.167802
            ],
            [
              -95.852788,
              41.165398
            ],
            [
              -95.846188,
              41.166698
            ],
            [
              -95.841888,
              41.171098
            ],
            [
              -95.8413038459581,
              41.1748950012721
            ],
            [
              -95.841288,
              41.174998
            ],
            [
              -95.844088,
              41.180598
            ],
            [
              -95.850188,
              41.184798
            ],
            [
              -95.856788,
              41.187098
            ],
            [
              -95.864789,
              41.188298
            ],
            [
              -95.90969,
              41.184398
            ],
            [
              -95.91459,
              41.185098
            ],
            [
              -95.91829,
              41.186698
            ],
            [
              -95.92319,
              41.190998
            ],
            [
              -95.9232187016371,
              41.1910461777481
            ],
            [
              -95.939532,
              41.191097
            ],
            [
              -95.947933,
              41.190953
            ],
            [
              -96.005918,
              41.190668
            ],
            [
              -96.009134,
              41.1907
            ],
            [
              -96.023825,
              41.19065
            ],
            [
              -96.081396,
              41.190756
            ],
            [
              -96.158333,
              41.190762
            ],
            [
              -96.196677,
              41.190654
            ],
            [
              -96.327399,
              41.189999
            ],
            [
              -96.315929,
              41.143955
            ],
            [
              -96.31944,
              41.129799
            ],
            [
              -96.316296,
              41.125199
            ],
            [
              -96.310983,
              41.115338
            ],
            [
              -96.33287,
              41.099622
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "069",
      "COUNTYNS": "00835856",
      "AFFGEOID": "0500000US31069",
      "GEOID": "31069",
      "NAME": "Garden",
      "LSAD": "06",
      "ALAND": 4414069977,
      "AWATER": 68874632,
      "County_state": "Garden,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -102.677582,
              42.005282
            ],
            [
              -102.678384,
              41.801434
            ],
            [
              -102.674194,
              41.772002
            ],
            [
              -102.66487,
              41.743457
            ],
            [
              -102.636476,
              41.743139
            ],
            [
              -102.636695,
              41.439053
            ],
            [
              -102.636672,
              41.395256
            ],
            [
              -102.609386,
              41.395115
            ],
            [
              -102.610413,
              41.221614
            ],
            [
              -102.055535,
              41.221547
            ],
            [
              -102.055256,
              41.395162
            ],
            [
              -101.98463,
              41.394712
            ],
            [
              -101.985523,
              41.74229
            ],
            [
              -102.067918,
              41.742099
            ],
            [
              -102.06665,
              42.009195
            ],
            [
              -102.677582,
              42.005282
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "057",
      "COUNTYNS": "00835850",
      "AFFGEOID": "0500000US31057",
      "GEOID": "31057",
      "NAME": "Dundy",
      "LSAD": "06",
      "ALAND": 2381956152,
      "AWATER": 3046330,
      "County_state": "Dundy,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -102.051909,
              40.162674
            ],
            [
              -102.052001,
              40.148359
            ],
            [
              -102.051744,
              40.003078
            ],
            [
              -101.916696,
              40.003142
            ],
            [
              -101.904176,
              40.003162
            ],
            [
              -101.841025,
              40.002784
            ],
            [
              -101.832161,
              40.002933
            ],
            [
              -101.807687,
              40.002798
            ],
            [
              -101.804862,
              40.002752
            ],
            [
              -101.627071,
              40.00262
            ],
            [
              -101.625809,
              40.002711
            ],
            [
              -101.542273,
              40.002609
            ],
            [
              -101.417209,
              40.002424
            ],
            [
              -101.411042549953,
              40.0023645110938
            ],
            [
              -101.409953,
              40.002354
            ],
            [
              -101.374326,
              40.002521
            ],
            [
              -101.342859,
              40.00258
            ],
            [
              -101.32551390689501,
              40.0026868921426
            ],
            [
              -101.324036,
              40.00271
            ],
            [
              -101.325042,
              40.329456
            ],
            [
              -101.323353,
              40.350553
            ],
            [
              -101.342788,
              40.350444
            ],
            [
              -102.051553251676,
              40.3492139863953
            ],
            [
              -102.051309,
              40.338381
            ],
            [
              -102.051922,
              40.235344
            ],
            [
              -102.051894,
              40.229193
            ],
            [
              -102.051909,
              40.162674
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "023",
      "COUNTYNS": "00835833",
      "AFFGEOID": "0500000US31023",
      "GEOID": "31023",
      "NAME": "Butler",
      "LSAD": "06",
      "ALAND": 1514884892,
      "AWATER": 15309107,
      "County_state": "Butler,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.368186,
              41.396416
            ],
            [
              -97.368118,
              41.046947
            ],
            [
              -96.91094,
              41.046116
            ],
            [
              -96.908507,
              41.046091
            ],
            [
              -96.908233,
              41.394094
            ],
            [
              -96.905788,
              41.397323
            ],
            [
              -96.905862,
              41.453389
            ],
            [
              -96.90591,
              41.456436
            ],
            [
              -96.920964,
              41.456121
            ],
            [
              -96.964703,
              41.441801
            ],
            [
              -96.998721,
              41.419881
            ],
            [
              -97.01573,
              41.413852
            ],
            [
              -97.056831,
              41.412531
            ],
            [
              -97.091208,
              41.39289
            ],
            [
              -97.178464,
              41.376816
            ],
            [
              -97.199268,
              41.38584
            ],
            [
              -97.253519,
              41.384212
            ],
            [
              -97.287761,
              41.400638
            ],
            [
              -97.336869,
              41.391691
            ],
            [
              -97.368186,
              41.396416
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "169",
      "COUNTYNS": "00835906",
      "AFFGEOID": "0500000US31169",
      "GEOID": "31169",
      "NAME": "Thayer",
      "LSAD": "06",
      "ALAND": 1486153357,
      "AWATER": 3025340,
      "County_state": "Thayer,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.820816,
              40.350545
            ],
            [
              -97.8214959564515,
              40.0020018388567
            ],
            [
              -97.819426,
              40.001958
            ],
            [
              -97.777155,
              40.002167
            ],
            [
              -97.770776,
              40.001977
            ],
            [
              -97.769204,
              40.001995
            ],
            [
              -97.767746,
              40.001994
            ],
            [
              -97.515308,
              40.001901
            ],
            [
              -97.511381,
              40.001899
            ],
            [
              -97.510264,
              40.001835
            ],
            [
              -97.463285,
              40.002047
            ],
            [
              -97.444662,
              40.001958
            ],
            [
              -97.425443,
              40.002048
            ],
            [
              -97.417826,
              40.002024
            ],
            [
              -97.415833,
              40.002001
            ],
            [
              -97.3691991185285,
              40.002059878643394
            ],
            [
              -97.3691985101944,
              40.0020598794115
            ],
            [
              -97.368688,
              40.350392
            ],
            [
              -97.820816,
              40.350545
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "063",
      "COUNTYNS": "00835853",
      "AFFGEOID": "0500000US31063",
      "GEOID": "31063",
      "NAME": "Frontier",
      "LSAD": "06",
      "ALAND": 2524180462,
      "AWATER": 14212842,
      "County_state": "Frontier,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.781229,
              40.655593
            ],
            [
              -100.779571,
              40.375005
            ],
            [
              -100.777376,
              40.349466
            ],
            [
              -100.758435,
              40.349507
            ],
            [
              -100.26893,
              40.349239
            ],
            [
              -100.197807,
              40.350027
            ],
            [
              -100.094798,
              40.351245
            ],
            [
              -100.095095,
              40.438656
            ],
            [
              -99.981193,
              40.438291
            ],
            [
              -99.981743,
              40.700003
            ],
            [
              -100.223311,
              40.700254
            ],
            [
              -100.777833,
              40.700135
            ],
            [
              -100.781229,
              40.655593
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "115",
      "COUNTYNS": "00835879",
      "AFFGEOID": "0500000US31115",
      "GEOID": "31115",
      "NAME": "Loup",
      "LSAD": "06",
      "ALAND": 1459513672,
      "AWATER": 19565477,
      "County_state": "Loup,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.686959,
              42.086075
            ],
            [
              -99.686834,
              41.740326
            ],
            [
              -99.22183,
              41.740792
            ],
            [
              -99.222733,
              42.087916
            ],
            [
              -99.234629,
              42.087995
            ],
            [
              -99.662379,
              42.086009
            ],
            [
              -99.686959,
              42.086075
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "157",
      "COUNTYNS": "00835900",
      "AFFGEOID": "0500000US31157",
      "GEOID": "31157",
      "NAME": "Scotts Bluff",
      "LSAD": "06",
      "ALAND": 1915079274,
      "AWATER": 15397684,
      "County_state": "Scotts Bluff,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -104.052931,
              41.906143
            ],
            [
              -104.053026,
              41.885464
            ],
            [
              -104.052774,
              41.733401
            ],
            [
              -104.052829855868,
              41.697954223531795
            ],
            [
              -103.370391,
              41.69921
            ],
            [
              -103.370392,
              41.743473
            ],
            [
              -103.36262,
              41.743548
            ],
            [
              -103.363337,
              42.00293
            ],
            [
              -103.401639,
              42.00354
            ],
            [
              -104.052704176933,
              42.0017178993875
            ],
            [
              -104.052699,
              41.998673
            ],
            [
              -104.052761,
              41.994967
            ],
            [
              -104.05283,
              41.9946
            ],
            [
              -104.052856,
              41.975958
            ],
            [
              -104.052734,
              41.973007
            ],
            [
              -104.052991,
              41.914973
            ],
            [
              -104.052931,
              41.906143
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "075",
      "COUNTYNS": "00835859",
      "AFFGEOID": "0500000US31075",
      "GEOID": "31075",
      "NAME": "Grant",
      "LSAD": "06",
      "ALAND": 2010410702,
      "AWATER": 18130867,
      "County_state": "Grant,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -102.06665,
              42.009195
            ],
            [
              -102.067918,
              41.742099
            ],
            [
              -101.985523,
              41.74229
            ],
            [
              -101.42592,
              41.743059
            ],
            [
              -101.426557,
              42.092194
            ],
            [
              -102.006204,
              42.096338
            ],
            [
              -102.008562,
              42.008901
            ],
            [
              -102.06665,
              42.009195
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "027",
      "COUNTYNS": "00835835",
      "AFFGEOID": "0500000US31027",
      "GEOID": "31027",
      "NAME": "Cedar",
      "LSAD": "06",
      "ALAND": 1917219988,
      "AWATER": 14513825,
      "County_state": "Cedar,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.484921,
              42.850368
            ],
            [
              -97.485298,
              42.438578
            ],
            [
              -97.36759,
              42.438775
            ],
            [
              -97.367724,
              42.351861
            ],
            [
              -97.017789,
              42.351347
            ],
            [
              -97.017705,
              42.437913
            ],
            [
              -97.0158249637164,
              42.761599065501095
            ],
            [
              -97.02485,
              42.76243
            ],
            [
              -97.030189,
              42.763712
            ],
            [
              -97.033229,
              42.765904
            ],
            [
              -97.05218,
              42.770187
            ],
            [
              -97.065592,
              42.772189
            ],
            [
              -97.071849,
              42.772305
            ],
            [
              -97.079356,
              42.771406
            ],
            [
              -97.085463,
              42.770061
            ],
            [
              -97.096128,
              42.76934
            ],
            [
              -97.101265,
              42.769697
            ],
            [
              -97.111622,
              42.76939
            ],
            [
              -97.131331,
              42.771929
            ],
            [
              -97.134461,
              42.774494
            ],
            [
              -97.137101,
              42.778932
            ],
            [
              -97.137028,
              42.780963
            ],
            [
              -97.138216,
              42.783428
            ],
            [
              -97.144595,
              42.790113
            ],
            [
              -97.150763,
              42.795566
            ],
            [
              -97.160351504817,
              42.799733418734796
            ],
            [
              -97.163857,
              42.801257
            ],
            [
              -97.166978,
              42.802087
            ],
            [
              -97.172083,
              42.802925
            ],
            [
              -97.178488,
              42.80323
            ],
            [
              -97.1876,
              42.804835
            ],
            [
              -97.200431,
              42.805485
            ],
            [
              -97.204726,
              42.806505
            ],
            [
              -97.210126,
              42.809296
            ],
            [
              -97.211654,
              42.810684
            ],
            [
              -97.213084,
              42.813007
            ],
            [
              -97.213957,
              42.820143
            ],
            [
              -97.215059,
              42.822977
            ],
            [
              -97.21783,
              42.827766
            ],
            [
              -97.218269,
              42.829561
            ],
            [
              -97.217411,
              42.843519
            ],
            [
              -97.218825,
              42.845848
            ],
            [
              -97.231929,
              42.851335
            ],
            [
              -97.237868,
              42.853139
            ],
            [
              -97.248556,
              42.855386
            ],
            [
              -97.251764,
              42.855432
            ],
            [
              -97.256752,
              42.853913
            ],
            [
              -97.267946,
              42.852583
            ],
            [
              -97.289859,
              42.855499
            ],
            [
              -97.302075,
              42.86566
            ],
            [
              -97.306677,
              42.867604
            ],
            [
              -97.308853,
              42.867307
            ],
            [
              -97.311091,
              42.865821
            ],
            [
              -97.318066,
              42.863247
            ],
            [
              -97.324457,
              42.861998
            ],
            [
              -97.326348,
              42.861289
            ],
            [
              -97.328511,
              42.859501
            ],
            [
              -97.330749,
              42.858406
            ],
            [
              -97.336156,
              42.856802
            ],
            [
              -97.341181,
              42.855882
            ],
            [
              -97.359569,
              42.854816
            ],
            [
              -97.361784,
              42.855123
            ],
            [
              -97.368643,
              42.858419
            ],
            [
              -97.375337,
              42.862991
            ],
            [
              -97.376695,
              42.865195
            ],
            [
              -97.393966,
              42.86425
            ],
            [
              -97.399303,
              42.864835
            ],
            [
              -97.404442,
              42.86775
            ],
            [
              -97.408315,
              42.868334
            ],
            [
              -97.413422,
              42.867351
            ],
            [
              -97.417066,
              42.865918
            ],
            [
              -97.42319,
              42.861168
            ],
            [
              -97.425087,
              42.858221
            ],
            [
              -97.425543,
              42.856658
            ],
            [
              -97.431951,
              42.851542
            ],
            [
              -97.439114,
              42.84711
            ],
            [
              -97.442279,
              42.846224
            ],
            [
              -97.452177,
              42.846048
            ],
            [
              -97.456383,
              42.846937
            ],
            [
              -97.458772,
              42.848322
            ],
            [
              -97.461666,
              42.849176
            ],
            [
              -97.470529,
              42.850455
            ],
            [
              -97.484921,
              42.850368
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "021",
      "COUNTYNS": "00835832",
      "AFFGEOID": "0500000US31021",
      "GEOID": "31021",
      "NAME": "Burt",
      "LSAD": "06",
      "ALAND": 1273201346,
      "AWATER": 14186271,
      "County_state": "Burt,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.554866,
              42.015875
            ],
            [
              -96.555172,
              41.742018
            ],
            [
              -96.444217,
              41.741775
            ],
            [
              -96.444785,
              41.683633
            ],
            [
              -96.12171195568601,
              41.6829898591487
            ],
            [
              -96.121401,
              41.688522
            ],
            [
              -96.120157,
              41.69115
            ],
            [
              -96.117751,
              41.694221
            ],
            [
              -96.111968,
              41.697773
            ],
            [
              -96.105119,
              41.699917
            ],
            [
              -96.090579,
              41.697425
            ],
            [
              -96.082429,
              41.698159
            ],
            [
              -96.075955,
              41.701661
            ],
            [
              -96.073063,
              41.705004
            ],
            [
              -96.072321,
              41.706858
            ],
            [
              -96.072494,
              41.708794
            ],
            [
              -96.073376,
              41.710674
            ],
            [
              -96.075151,
              41.713265
            ],
            [
              -96.079682,
              41.717962
            ],
            [
              -96.0876,
              41.72218
            ],
            [
              -96.10261,
              41.728016
            ],
            [
              -96.105582,
              41.731647
            ],
            [
              -96.106326,
              41.734591
            ],
            [
              -96.106425,
              41.73789
            ],
            [
              -96.104622,
              41.744211
            ],
            [
              -96.102772,
              41.746339
            ],
            [
              -96.097511,
              41.749076
            ],
            [
              -96.091687,
              41.750419
            ],
            [
              -96.084673,
              41.753314
            ],
            [
              -96.079915,
              41.757895
            ],
            [
              -96.0783,
              41.761598
            ],
            [
              -96.078939,
              41.771353
            ],
            [
              -96.077543,
              41.777824
            ],
            [
              -96.073197,
              41.783009
            ],
            [
              -96.066413,
              41.788913
            ],
            [
              -96.064537,
              41.793002
            ],
            [
              -96.064879,
              41.79623
            ],
            [
              -96.067329,
              41.800628
            ],
            [
              -96.069662,
              41.803509
            ],
            [
              -96.075548,
              41.807811
            ],
            [
              -96.081026,
              41.810144
            ],
            [
              -96.093835,
              41.812785
            ],
            [
              -96.09827,
              41.814206
            ],
            [
              -96.103749,
              41.817151
            ],
            [
              -96.107592,
              41.820685
            ],
            [
              -96.109347,
              41.823735
            ],
            [
              -96.11081,
              41.828172
            ],
            [
              -96.110907,
              41.830818
            ],
            [
              -96.107911,
              41.840339
            ],
            [
              -96.108029,
              41.844397
            ],
            [
              -96.110246,
              41.84885
            ],
            [
              -96.113962,
              41.853102
            ],
            [
              -96.116202,
              41.854869
            ],
            [
              -96.123215,
              41.85858
            ],
            [
              -96.13062,
              41.860809
            ],
            [
              -96.135253,
              41.863128
            ],
            [
              -96.1390095052264,
              41.8663010080218
            ],
            [
              -96.142045,
              41.868865
            ],
            [
              -96.144483,
              41.871854
            ],
            [
              -96.146083,
              41.874988
            ],
            [
              -96.146757,
              41.877538
            ],
            [
              -96.14735,
              41.884811
            ],
            [
              -96.148826,
              41.888132
            ],
            [
              -96.152179,
              41.892085
            ],
            [
              -96.158204,
              41.897173
            ],
            [
              -96.161756,
              41.90182
            ],
            [
              -96.161988,
              41.905553
            ],
            [
              -96.160767,
              41.908044
            ],
            [
              -96.159098,
              41.910057
            ],
            [
              -96.154301,
              41.912421
            ],
            [
              -96.142265,
              41.915379
            ],
            [
              -96.139653,
              41.916838
            ],
            [
              -96.136743,
              41.920826
            ],
            [
              -96.136133,
              41.92353
            ],
            [
              -96.136613,
              41.927167
            ],
            [
              -96.143493,
              41.937387
            ],
            [
              -96.144583,
              41.941544
            ],
            [
              -96.143603,
              41.944512
            ],
            [
              -96.142597,
              41.945908
            ],
            [
              -96.135393,
              41.952223
            ],
            [
              -96.133318,
              41.955732
            ],
            [
              -96.129186,
              41.965136
            ],
            [
              -96.1289,
              41.969727
            ],
            [
              -96.129505,
              41.971673
            ],
            [
              -96.132537,
              41.974625
            ],
            [
              -96.141228,
              41.978063
            ],
            [
              -96.156538,
              41.980137
            ],
            [
              -96.16068,
              41.980114
            ],
            [
              -96.168071,
              41.978996
            ],
            [
              -96.174154,
              41.976864
            ],
            [
              -96.177203,
              41.976325
            ],
            [
              -96.184243,
              41.976696
            ],
            [
              -96.186265,
              41.977417
            ],
            [
              -96.190602,
              41.980721
            ],
            [
              -96.191549,
              41.982032
            ],
            [
              -96.192141,
              41.984461
            ],
            [
              -96.189516,
              41.989314
            ],
            [
              -96.184784,
              41.99546
            ],
            [
              -96.183801,
              41.99776
            ],
            [
              -96.183568,
              41.999987
            ],
            [
              -96.184644,
              42.002633
            ],
            [
              -96.188067,
              42.006323
            ],
            [
              -96.194556,
              42.008662
            ],
            [
              -96.206083,
              42.009267
            ],
            [
              -96.215225,
              42.006701
            ],
            [
              -96.217637,
              42.003862
            ],
            [
              -96.221813,
              41.997382
            ],
            [
              -96.223896,
              41.995456
            ],
            [
              -96.225463,
              41.994734
            ],
            [
              -96.229739,
              41.99441
            ],
            [
              -96.236487,
              41.996428
            ],
            [
              -96.240713,
              41.999351
            ],
            [
              -96.242035,
              42.000911
            ],
            [
              -96.24238,
              42.002899
            ],
            [
              -96.241932,
              42.006965
            ],
            [
              -96.238859,
              42.012315
            ],
            [
              -96.227867,
              42.018651
            ],
            [
              -96.223611,
              42.022652
            ],
            [
              -96.22173,
              42.026205
            ],
            [
              -96.221901,
              42.029558
            ],
            [
              -96.223822,
              42.033346
            ],
            [
              -96.225656,
              42.035217
            ],
            [
              -96.232125,
              42.039145
            ],
            [
              -96.238392,
              42.041088
            ],
            [
              -96.246832,
              42.041616
            ],
            [
              -96.254542,
              42.039454
            ],
            [
              -96.256087,
              42.03808
            ],
            [
              -96.261132,
              42.038974
            ],
            [
              -96.263886,
              42.039858
            ],
            [
              -96.268637,
              42.042314
            ],
            [
              -96.271427,
              42.044988
            ],
            [
              -96.272877,
              42.047238
            ],
            [
              -96.2729013791207,
              42.047281245329
            ],
            [
              -96.309389,
              42.046692
            ],
            [
              -96.309645,
              42.015187
            ],
            [
              -96.460374,
              42.017053
            ],
            [
              -96.554866,
              42.015875
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "095",
      "COUNTYNS": "00835869",
      "AFFGEOID": "0500000US31095",
      "GEOID": "31095",
      "NAME": "Jefferson",
      "LSAD": "06",
      "ALAND": 1476736217,
      "AWATER": 14232350,
      "County_state": "Jefferson,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.3691985101944,
              40.0020598794115
            ],
            [
              -97.369103,
              40.00206
            ],
            [
              -97.350896,
              40.00193
            ],
            [
              -97.350272,
              40.001976
            ],
            [
              -97.245169,
              40.001513
            ],
            [
              -97.24508,
              40.001467
            ],
            [
              -97.20231,
              40.001442
            ],
            [
              -97.20019,
              40.001549
            ],
            [
              -97.181775,
              40.00155
            ],
            [
              -97.142448,
              40.001495
            ],
            [
              -97.137866,
              40.001814
            ],
            [
              -97.049663,
              40.001323
            ],
            [
              -97.030803,
              40.001342
            ],
            [
              -97.009165,
              40.001463
            ],
            [
              -96.9164069976229,
              40.0015058549306
            ],
            [
              -96.916055,
              40.349727
            ],
            [
              -97.368688,
              40.350392
            ],
            [
              -97.3691985101944,
              40.0020598794115
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "111",
      "COUNTYNS": "00835877",
      "AFFGEOID": "0500000US31111",
      "GEOID": "31111",
      "NAME": "Lincoln",
      "LSAD": "06",
      "ALAND": 6640952057,
      "AWATER": 28570547,
      "County_state": "Lincoln,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -101.269695,
              41.394934
            ],
            [
              -101.270381,
              41.047392
            ],
            [
              -101.250162,
              41.047385
            ],
            [
              -101.249975,
              41.00395
            ],
            [
              -101.247963,
              40.697802
            ],
            [
              -100.777833,
              40.700135
            ],
            [
              -100.223311,
              40.700254
            ],
            [
              -100.224235,
              41.046597
            ],
            [
              -100.238155,
              41.046536
            ],
            [
              -100.236106,
              41.393324
            ],
            [
              -100.251098,
              41.393298
            ],
            [
              -100.713243,
              41.393511
            ],
            [
              -101.269695,
              41.394934
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "061",
      "COUNTYNS": "00835852",
      "AFFGEOID": "0500000US31061",
      "GEOID": "31061",
      "NAME": "Franklin",
      "LSAD": "06",
      "ALAND": 1491355768,
      "AWATER": 487899,
      "County_state": "Franklin,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.179403,
              40.350681
            ],
            [
              -99.179134,
              40.001977
            ],
            [
              -99.178965,
              40.001977
            ],
            [
              -99.169816,
              40.001925
            ],
            [
              -99.123033,
              40.002165
            ],
            [
              -99.11351,
              40.002193
            ],
            [
              -99.085597,
              40.002133
            ],
            [
              -99.067021970224,
              40.0021702872539
            ],
            [
              -99.020338,
              40.002264
            ],
            [
              -99.018701,
              40.002333
            ],
            [
              -98.992135,
              40.002192
            ],
            [
              -98.972287,
              40.002245
            ],
            [
              -98.971721,
              40.002268
            ],
            [
              -98.961009,
              40.002317
            ],
            [
              -98.960919,
              40.002271
            ],
            [
              -98.934792,
              40.002205
            ],
            [
              -98.834456,
              40.002363
            ],
            [
              -98.82059,
              40.002319
            ],
            [
              -98.777203,
              40.002359
            ],
            [
              -98.774941,
              40.002336
            ],
            [
              -98.7263734167054,
              40.0022221837665
            ],
            [
              -98.726827,
              40.350398
            ],
            [
              -99.179403,
              40.350681
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "141",
      "COUNTYNS": "00835892",
      "AFFGEOID": "0500000US31141",
      "GEOID": "31141",
      "NAME": "Platte",
      "LSAD": "06",
      "ALAND": 1745910545,
      "AWATER": 27167485,
      "County_state": "Platte,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.830493,
              41.742238
            ],
            [
              -97.829846,
              41.526174
            ],
            [
              -97.703627,
              41.526796
            ],
            [
              -97.703765,
              41.394876
            ],
            [
              -97.598461,
              41.395068
            ],
            [
              -97.598253,
              41.333119
            ],
            [
              -97.570595,
              41.337282
            ],
            [
              -97.522412,
              41.361934
            ],
            [
              -97.450149,
              41.36878
            ],
            [
              -97.368186,
              41.396416
            ],
            [
              -97.336869,
              41.391691
            ],
            [
              -97.287761,
              41.400638
            ],
            [
              -97.253519,
              41.384212
            ],
            [
              -97.252554,
              41.395197
            ],
            [
              -97.252592,
              41.612672
            ],
            [
              -97.252425,
              41.743085
            ],
            [
              -97.36807,
              41.743207
            ],
            [
              -97.830493,
              41.742238
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "123",
      "COUNTYNS": "00835883",
      "AFFGEOID": "0500000US31123",
      "GEOID": "31123",
      "NAME": "Morrill",
      "LSAD": "06",
      "ALAND": 3687723999,
      "AWATER": 15598670,
      "County_state": "Morrill,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -103.370392,
              41.743473
            ],
            [
              -103.370391,
              41.69921
            ],
            [
              -103.369024,
              41.437655
            ],
            [
              -102.636695,
              41.439053
            ],
            [
              -102.636476,
              41.743139
            ],
            [
              -102.66487,
              41.743457
            ],
            [
              -102.674194,
              41.772002
            ],
            [
              -102.678384,
              41.801434
            ],
            [
              -102.677582,
              42.005282
            ],
            [
              -102.697835,
              42.004843
            ],
            [
              -102.862321,
              42.000665
            ],
            [
              -102.952292,
              42.001776
            ],
            [
              -103.363337,
              42.00293
            ],
            [
              -103.36262,
              41.743548
            ],
            [
              -103.370392,
              41.743473
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "103",
      "COUNTYNS": "00835873",
      "AFFGEOID": "0500000US31103",
      "GEOID": "31103",
      "NAME": "Keya Paha",
      "LSAD": "06",
      "ALAND": 2002257567,
      "AWATER": 2192458,
      "County_state": "Keya Paha,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.19841203687501,
              42.9985420518853
            ],
            [
              -100.198478,
              42.846102
            ],
            [
              -100.187778,
              42.83761
            ],
            [
              -100.145363,
              42.830215
            ],
            [
              -100.133978,
              42.807819
            ],
            [
              -100.11334,
              42.799683
            ],
            [
              -100.055921,
              42.785438
            ],
            [
              -100.007809,
              42.788566
            ],
            [
              -99.968856,
              42.772474
            ],
            [
              -99.947273,
              42.772994
            ],
            [
              -99.898039,
              42.765462
            ],
            [
              -99.862162,
              42.769002
            ],
            [
              -99.855047,
              42.752853
            ],
            [
              -99.837262,
              42.754108
            ],
            [
              -99.816833,
              42.742979
            ],
            [
              -99.778334,
              42.735173
            ],
            [
              -99.752491,
              42.737633
            ],
            [
              -99.711197,
              42.727108
            ],
            [
              -99.677684,
              42.730903
            ],
            [
              -99.659861,
              42.735106
            ],
            [
              -99.625393,
              42.724084
            ],
            [
              -99.58169,
              42.724144
            ],
            [
              -99.537491,
              42.717626
            ],
            [
              -99.470428,
              42.738317
            ],
            [
              -99.4625,
              42.751626
            ],
            [
              -99.437057,
              42.753615
            ],
            [
              -99.415222,
              42.772098
            ],
            [
              -99.399571,
              42.771378
            ],
            [
              -99.377971,
              42.782214
            ],
            [
              -99.336428,
              42.780386
            ],
            [
              -99.30016,
              42.796171
            ],
            [
              -99.274937,
              42.794827
            ],
            [
              -99.257039,
              42.804296
            ],
            [
              -99.253965,
              42.904854
            ],
            [
              -99.2544542492264,
              42.998139794357
            ],
            [
              -99.26271,
              42.998234
            ],
            [
              -99.288045,
              42.998152
            ],
            [
              -99.347283,
              42.998217
            ],
            [
              -99.368628,
              42.99814
            ],
            [
              -99.371121,
              42.998093
            ],
            [
              -99.374268,
              42.998047
            ],
            [
              -99.395568,
              42.99817
            ],
            [
              -99.471353,
              42.997967
            ],
            [
              -99.474531,
              42.998081
            ],
            [
              -99.490798,
              42.998143
            ],
            [
              -99.494287,
              42.998118
            ],
            [
              -99.5340492772214,
              42.9980405812359
            ],
            [
              -99.535375,
              42.998038
            ],
            [
              -99.569277,
              42.997995
            ],
            [
              -99.699234,
              42.99788
            ],
            [
              -99.701446,
              42.997994
            ],
            [
              -99.719177,
              42.997899
            ],
            [
              -99.726788,
              42.997892
            ],
            [
              -99.743138,
              42.997912
            ],
            [
              -99.768524,
              42.998125
            ],
            [
              -99.788247,
              42.998016
            ],
            [
              -99.800306,
              42.997972
            ],
            [
              -99.803328,
              42.998064
            ],
            [
              -99.809373,
              42.998178
            ],
            [
              -99.821868,
              42.997995
            ],
            [
              -99.850037,
              42.998171
            ],
            [
              -99.859945,
              42.997962
            ],
            [
              -99.869885,
              42.998094
            ],
            [
              -99.877697,
              42.998094
            ],
            [
              -99.918401,
              42.998057
            ],
            [
              -99.927645,
              42.998113
            ],
            [
              -99.950411,
              42.998286
            ],
            [
              -99.950921,
              42.998291
            ],
            [
              -99.961204,
              42.998335
            ],
            [
              -100.004757,
              42.998392
            ],
            [
              -100.027815,
              42.998424
            ],
            [
              -100.034389,
              42.998425
            ],
            [
              -100.119297,
              42.998689
            ],
            [
              -100.126427,
              42.99871
            ],
            [
              -100.126896,
              42.998711
            ],
            [
              -100.19841203687501,
              42.9985420518853
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "117",
      "COUNTYNS": "00835881",
      "AFFGEOID": "0500000US31117",
      "GEOID": "31117",
      "NAME": "McPherson",
      "LSAD": "06",
      "ALAND": 2224733585,
      "AWATER": 2680669,
      "County_state": "McPherson,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -101.406466,
              41.7432
            ],
            [
              -101.40726,
              41.395416
            ],
            [
              -101.269695,
              41.394934
            ],
            [
              -100.713243,
              41.393511
            ],
            [
              -100.713617,
              41.523535
            ],
            [
              -100.714847,
              41.544806
            ],
            [
              -100.711711,
              41.739761
            ],
            [
              -100.842459,
              41.740404
            ],
            [
              -101.406466,
              41.7432
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "033",
      "COUNTYNS": "00835838",
      "AFFGEOID": "0500000US31033",
      "GEOID": "31033",
      "NAME": "Cheyenne",
      "LSAD": "06",
      "ALAND": 3098367767,
      "AWATER": 292549,
      "County_state": "Cheyenne,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -103.376021,
              41.047771
            ],
            [
              -103.382492446889,
              41.00223211782
            ],
            [
              -103.365314,
              41.001846
            ],
            [
              -103.362979,
              41.001844
            ],
            [
              -103.077804,
              41.002298
            ],
            [
              -103.076536,
              41.002253
            ],
            [
              -103.059538,
              41.002368
            ],
            [
              -103.057998,
              41.002368
            ],
            [
              -103.043444,
              41.002344
            ],
            [
              -103.038704,
              41.002251
            ],
            [
              -103.002026,
              41.002486
            ],
            [
              -103.000102,
              41.0024
            ],
            [
              -102.98269,
              41.002157
            ],
            [
              -102.981483,
              41.002112
            ],
            [
              -102.963669,
              41.002186
            ],
            [
              -102.962522,
              41.002072
            ],
            [
              -102.960706,
              41.002059
            ],
            [
              -102.959624,
              41.002095
            ],
            [
              -102.94483,
              41.002303
            ],
            [
              -102.943109,
              41.002051
            ],
            [
              -102.925568,
              41.00228
            ],
            [
              -102.924029,
              41.002142
            ],
            [
              -102.906547,
              41.002276
            ],
            [
              -102.904796,
              41.002207
            ],
            [
              -102.887407,
              41.002178
            ],
            [
              -102.885746,
              41.002131
            ],
            [
              -102.867822,
              41.002183
            ],
            [
              -102.865784,
              41.001988
            ],
            [
              -102.849263,
              41.002301
            ],
            [
              -102.846455,
              41.002256
            ],
            [
              -102.830303,
              41.002351
            ],
            [
              -102.82728,
              41.002143
            ],
            [
              -102.773546,
              41.002414
            ],
            [
              -102.766723,
              41.002275
            ],
            [
              -102.754617,
              41.002361
            ],
            [
              -102.739624,
              41.00223
            ],
            [
              -102.653463,
              41.002332
            ],
            [
              -102.621033,
              41.002597
            ],
            [
              -102.620872,
              41.048
            ],
            [
              -102.610213,
              41.048207
            ],
            [
              -102.610413,
              41.221614
            ],
            [
              -102.609386,
              41.395115
            ],
            [
              -102.636672,
              41.395256
            ],
            [
              -102.636695,
              41.439053
            ],
            [
              -103.369024,
              41.437655
            ],
            [
              -103.370452,
              41.394677
            ],
            [
              -103.37762,
              41.394633
            ],
            [
              -103.376021,
              41.047771
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "007",
      "COUNTYNS": "00835826",
      "AFFGEOID": "0500000US31007",
      "GEOID": "31007",
      "NAME": "Banner",
      "LSAD": "06",
      "ALAND": 1932676697,
      "AWATER": 397069,
      "County_state": "Banner,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -104.052945,
              41.638167
            ],
            [
              -104.052975,
              41.622931
            ],
            [
              -104.052735,
              41.613676
            ],
            [
              -104.052859,
              41.592254
            ],
            [
              -104.05254,
              41.564274
            ],
            [
              -104.052531,
              41.552723
            ],
            [
              -104.052584,
              41.55265
            ],
            [
              -104.052692,
              41.541154
            ],
            [
              -104.052686,
              41.539111
            ],
            [
              -104.052476,
              41.522343
            ],
            [
              -104.052478,
              41.515754
            ],
            [
              -104.05234,
              41.417865
            ],
            [
              -104.05216,
              41.407662
            ],
            [
              -104.052287,
              41.393307
            ],
            [
              -104.052287592918,
              41.3932140037803
            ],
            [
              -103.45276,
              41.393528
            ],
            [
              -103.37762,
              41.394633
            ],
            [
              -103.370452,
              41.394677
            ],
            [
              -103.369024,
              41.437655
            ],
            [
              -103.370391,
              41.69921
            ],
            [
              -104.052829855868,
              41.697954223531795
            ],
            [
              -104.052913,
              41.64519
            ],
            [
              -104.052945,
              41.638167
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "107",
      "COUNTYNS": "00835875",
      "AFFGEOID": "0500000US31107",
      "GEOID": "31107",
      "NAME": "Knox",
      "LSAD": "06",
      "ALAND": 2870799577,
      "AWATER": 81066532,
      "County_state": "Knox,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.30976908505669,
              42.882559662424704
            ],
            [
              -98.310239,
              42.784618
            ],
            [
              -98.305149,
              42.761207
            ],
            [
              -98.305456,
              42.43693
            ],
            [
              -98.300235,
              42.436913
            ],
            [
              -97.834431,
              42.437684
            ],
            [
              -97.485298,
              42.438578
            ],
            [
              -97.484921,
              42.850368
            ],
            [
              -97.49149,
              42.851625
            ],
            [
              -97.49623,
              42.853231
            ],
            [
              -97.499088,
              42.855197
            ],
            [
              -97.500341,
              42.85722
            ],
            [
              -97.504847,
              42.858477
            ],
            [
              -97.515948,
              42.853752
            ],
            [
              -97.531867,
              42.850105
            ],
            [
              -97.547473,
              42.848028
            ],
            [
              -97.561928,
              42.847552
            ],
            [
              -97.574551,
              42.849653
            ],
            [
              -97.591916,
              42.853837
            ],
            [
              -97.59926,
              42.856229
            ],
            [
              -97.603762,
              42.858329
            ],
            [
              -97.611811,
              42.858367
            ],
            [
              -97.620276,
              42.856598
            ],
            [
              -97.6354116902609,
              42.851448785856896
            ],
            [
              -97.646719,
              42.847602
            ],
            [
              -97.657846,
              42.844626
            ],
            [
              -97.668294,
              42.843031
            ],
            [
              -97.686506,
              42.842435
            ],
            [
              -97.70103,
              42.843797
            ],
            [
              -97.72045,
              42.847439
            ],
            [
              -97.750343,
              42.849493
            ],
            [
              -97.753801,
              42.849012
            ],
            [
              -97.76473,
              42.8491
            ],
            [
              -97.774456,
              42.849774
            ],
            [
              -97.788462,
              42.853375
            ],
            [
              -97.801344,
              42.858003
            ],
            [
              -97.804838301439,
              42.858842200994005
            ],
            [
              -97.817075,
              42.861781
            ],
            [
              -97.825804,
              42.867532
            ],
            [
              -97.828496,
              42.868797
            ],
            [
              -97.834172,
              42.868794
            ],
            [
              -97.84527,
              42.867734
            ],
            [
              -97.857957,
              42.865093
            ],
            [
              -97.865695,
              42.86286
            ],
            [
              -97.875345,
              42.858724
            ],
            [
              -97.877003,
              42.854394
            ],
            [
              -97.876887,
              42.852663
            ],
            [
              -97.875651,
              42.850307
            ],
            [
              -97.875849,
              42.847725
            ],
            [
              -97.878976,
              42.843673
            ],
            [
              -97.879878,
              42.835395
            ],
            [
              -97.884864,
              42.826231
            ],
            [
              -97.888562,
              42.817251
            ],
            [
              -97.890241,
              42.815113
            ],
            [
              -97.89439,
              42.811682
            ],
            [
              -97.905001,
              42.798872
            ],
            [
              -97.908983,
              42.794909
            ],
            [
              -97.915947,
              42.789901
            ],
            [
              -97.921434,
              42.788352
            ],
            [
              -97.932962,
              42.778203
            ],
            [
              -97.936716,
              42.775754
            ],
            [
              -97.950147,
              42.769619
            ],
            [
              -97.953492,
              42.76904
            ],
            [
              -97.962044,
              42.768708
            ],
            [
              -97.977588,
              42.769923
            ],
            [
              -97.992507,
              42.765111
            ],
            [
              -98.000348,
              42.763256
            ],
            [
              -98.002532,
              42.763264
            ],
            [
              -98.005739,
              42.764167
            ],
            [
              -98.013046,
              42.762299
            ],
            [
              -98.017228,
              42.762411
            ],
            [
              -98.035034,
              42.764205
            ],
            [
              -98.037114,
              42.765724
            ],
            [
              -98.042011,
              42.767316
            ],
            [
              -98.044688,
              42.768029
            ],
            [
              -98.051624,
              42.768769
            ],
            [
              -98.056625,
              42.770781
            ],
            [
              -98.059838,
              42.772772
            ],
            [
              -98.061254,
              42.777954
            ],
            [
              -98.062913,
              42.781119
            ],
            [
              -98.067388,
              42.784759
            ],
            [
              -98.082782,
              42.794342
            ],
            [
              -98.087819,
              42.795789
            ],
            [
              -98.094574,
              42.799309
            ],
            [
              -98.1047,
              42.808475
            ],
            [
              -98.107688,
              42.810633
            ],
            [
              -98.127489,
              42.820127
            ],
            [
              -98.129038,
              42.821228
            ],
            [
              -98.137912,
              42.832728
            ],
            [
              -98.146933,
              42.839823
            ],
            [
              -98.14806,
              42.840013
            ],
            [
              -98.15307905341639,
              42.839065448144595
            ],
            [
              -98.163262,
              42.837143
            ],
            [
              -98.167523,
              42.836925
            ],
            [
              -98.171113,
              42.837114
            ],
            [
              -98.189765,
              42.841628
            ],
            [
              -98.204506,
              42.846845
            ],
            [
              -98.219826,
              42.853157
            ],
            [
              -98.224231,
              42.855521
            ],
            [
              -98.226512,
              42.857742
            ],
            [
              -98.231922,
              42.86114
            ],
            [
              -98.24683,
              42.868397
            ],
            [
              -98.24982,
              42.871843
            ],
            [
              -98.25181,
              42.872824
            ],
            [
              -98.258276,
              42.87439
            ],
            [
              -98.268363,
              42.874152
            ],
            [
              -98.280007,
              42.874996
            ],
            [
              -98.297465,
              42.880059
            ],
            [
              -98.30976908505669,
              42.882559662424704
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "181",
      "COUNTYNS": "00835912",
      "AFFGEOID": "0500000US31181",
      "GEOID": "31181",
      "NAME": "Webster",
      "LSAD": "06",
      "ALAND": 1489017971,
      "AWATER": 337241,
      "County_state": "Webster,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.726827,
              40.350398
            ],
            [
              -98.7263734167054,
              40.0022221837665
            ],
            [
              -98.726295,
              40.002222
            ],
            [
              -98.710404,
              40.00218
            ],
            [
              -98.693096,
              40.002373
            ],
            [
              -98.691443,
              40.002505
            ],
            [
              -98.690287,
              40.002548
            ],
            [
              -98.672819,
              40.002364
            ],
            [
              -98.669724,
              40.00241
            ],
            [
              -98.653833,
              40.002269
            ],
            [
              -98.652494,
              40.002245
            ],
            [
              -98.64071,
              40.002493
            ],
            [
              -98.613755,
              40.0024
            ],
            [
              -98.593342,
              40.002476
            ],
            [
              -98.575219,
              40.00248
            ],
            [
              -98.560578,
              40.002274
            ],
            [
              -98.543186,
              40.002285
            ],
            [
              -98.523053,
              40.002336
            ],
            [
              -98.5044549849831,
              40.0023285653691
            ],
            [
              -98.490533,
              40.002323
            ],
            [
              -98.2740168698569,
              40.002515998333195
            ],
            [
              -98.273571,
              40.350359
            ],
            [
              -98.27809,
              40.350347
            ],
            [
              -98.723948,
              40.350391
            ],
            [
              -98.726827,
              40.350398
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "091",
      "COUNTYNS": "00835867",
      "AFFGEOID": "0500000US31091",
      "GEOID": "31091",
      "NAME": "Hooker",
      "LSAD": "06",
      "ALAND": 1867796192,
      "AWATER": 882994,
      "County_state": "Hooker,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -101.426557,
              42.092194
            ],
            [
              -101.42592,
              41.743059
            ],
            [
              -101.406466,
              41.7432
            ],
            [
              -100.842459,
              41.740404
            ],
            [
              -100.846129,
              42.088166
            ],
            [
              -101.426557,
              42.092194
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "31",
      "COUNTYFP": "101",
      "COUNTYNS": "00835872",
      "AFFGEOID": "0500000US31101",
      "GEOID": "31101",
      "NAME": "Keith",
      "LSAD": "06",
      "ALAND": 2749531887,
      "AWATER": 124672175,
      "County_state": "Keith,31"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -102.055535,
              41.221547
            ],
            [
              -102.055528,
              41.047457
            ],
            [
              -102.051761,
              41.00389
            ],
            [
              -101.249975,
              41.00395
            ],
            [
              -101.250162,
              41.047385
            ],
            [
              -101.270381,
              41.047392
            ],
            [
              -101.269695,
              41.394934
            ],
            [
              -101.40726,
              41.395416
            ],
            [
              -101.98463,
              41.394712
            ],
            [
              -102.055256,
              41.395162
            ],
            [
              -102.055535,
              41.221547
            ]
          ]
        ]
      ]
    }
  }
]
};