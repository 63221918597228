export const counties_wy = {
  "type":"FeatureCollection","name":"wy_counties","features":[
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "56",
      "COUNTYFP": "025",
      "COUNTYNS": "01605077",
      "AFFGEOID": "0500000US56025",
      "GEOID": "56025",
      "NAME": "Natrona",
      "LSAD": "06",
      "ALAND": 13831948351,
      "AWATER": 90949279,
      "County_state": "Natrona,56"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -107.543526,
              42.781558
            ],
            [
              -107.542025,
              42.434356
            ],
            [
              -107.522923,
              42.434356
            ],
            [
              -106.624983,
              42.430488
            ],
            [
              -106.075852,
              42.433218
            ],
            [
              -106.074807,
              42.503131
            ],
            [
              -106.075669,
              42.718464
            ],
            [
              -106.070669,
              42.722065
            ],
            [
              -106.076169,
              42.737665
            ],
            [
              -106.077069,
              42.777965
            ],
            [
              -106.072668,
              42.778531
            ],
            [
              -106.07283,
              42.839794
            ],
            [
              -106.073837,
              42.979497
            ],
            [
              -106.073768,
              43.126667
            ],
            [
              -106.073869,
              43.47257
            ],
            [
              -106.078136,
              43.477567
            ],
            [
              -106.078068,
              43.494471
            ],
            [
              -106.446803,
              43.492716
            ],
            [
              -107.11092,
              43.492362
            ],
            [
              -107.110734,
              43.500286
            ],
            [
              -107.230481,
              43.500344
            ],
            [
              -107.250624,
              43.501624
            ],
            [
              -107.534897,
              43.501362
            ],
            [
              -107.535032,
              43.472759
            ],
            [
              -107.517031,
              43.472659
            ],
            [
              -107.516728,
              43.12796
            ],
            [
              -107.502327,
              43.12806
            ],
            [
              -107.501916,
              42.86889
            ],
            [
              -107.501425,
              42.781458
            ],
            [
              -107.543526,
              42.781558
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "56",
      "COUNTYFP": "009",
      "COUNTYNS": "01605070",
      "AFFGEOID": "0500000US56009",
      "GEOID": "56009",
      "NAME": "Converse",
      "LSAD": "06",
      "ALAND": 11020250155,
      "AWATER": 26204242,
      "County_state": "Converse,56"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -106.078068,
              43.494471
            ],
            [
              -106.078136,
              43.477567
            ],
            [
              -106.073869,
              43.47257
            ],
            [
              -106.073768,
              43.126667
            ],
            [
              -106.073837,
              42.979497
            ],
            [
              -106.07283,
              42.839794
            ],
            [
              -106.072668,
              42.778531
            ],
            [
              -106.077069,
              42.777965
            ],
            [
              -106.076169,
              42.737665
            ],
            [
              -106.070669,
              42.722065
            ],
            [
              -106.075669,
              42.718464
            ],
            [
              -106.074807,
              42.503131
            ],
            [
              -106.075852,
              42.433218
            ],
            [
              -106.073403,
              42.433236
            ],
            [
              -105.556042,
              42.430583
            ],
            [
              -105.556184,
              42.406253
            ],
            [
              -105.575546,
              42.406228
            ],
            [
              -105.575642,
              42.377314
            ],
            [
              -105.614656,
              42.377436
            ],
            [
              -105.613865,
              42.304455
            ],
            [
              -105.599416,
              42.297536
            ],
            [
              -105.535878,
              42.29455
            ],
            [
              -105.526399,
              42.288859
            ],
            [
              -105.500112,
              42.289954
            ],
            [
              -105.375541,
              42.290139
            ],
            [
              -105.378682,
              42.319018
            ],
            [
              -105.358592,
              42.319265
            ],
            [
              -105.361737,
              42.376319
            ],
            [
              -105.352229,
              42.376393
            ],
            [
              -105.351964,
              42.404892
            ],
            [
              -105.34206,
              42.404929
            ],
            [
              -105.342101,
              42.419065
            ],
            [
              -105.332638,
              42.431106
            ],
            [
              -105.28391,
              42.431402
            ],
            [
              -105.282258,
              42.431328
            ],
            [
              -105.285023,
              42.585425
            ],
            [
              -105.286511,
              42.605826
            ],
            [
              -105.155907,
              42.606762
            ],
            [
              -105.118137,
              42.608054
            ],
            [
              -104.989243,
              42.608567
            ],
            [
              -104.892437,
              42.608939
            ],
            [
              -104.893479,
              42.78282
            ],
            [
              -104.898374,
              42.787685
            ],
            [
              -104.899941,
              43.49964
            ],
            [
              -105.079797,
              43.498445
            ],
            [
              -105.685006,
              43.495823
            ],
            [
              -106.017366,
              43.494969
            ],
            [
              -106.078068,
              43.494471
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "56",
      "COUNTYFP": "029",
      "COUNTYNS": "01605079",
      "AFFGEOID": "0500000US56029",
      "GEOID": "56029",
      "NAME": "Park",
      "LSAD": "06",
      "ALAND": 17974153917,
      "AWATER": 65512584,
      "County_state": "Park,56"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -111.056888,
              44.866658
            ],
            [
              -111.056416,
              44.749928
            ],
            [
              -111.055511,
              44.725343
            ],
            [
              -111.055332727124,
              44.6662623099175
            ],
            [
              -111.000755,
              44.666113
            ],
            [
              -110.968673,
              44.666109
            ],
            [
              -110.888962,
              44.666158
            ],
            [
              -110.772091,
              44.666742
            ],
            [
              -110.667867,
              44.666291
            ],
            [
              -110.66735,
              44.624936
            ],
            [
              -110.6679,
              44.582813
            ],
            [
              -110.522317,
              44.582756
            ],
            [
              -110.379844,
              44.583022
            ],
            [
              -110.375799,
              44.577665
            ],
            [
              -110.375755,
              44.568607
            ],
            [
              -110.38073,
              44.566945
            ],
            [
              -110.379903,
              44.562971
            ],
            [
              -110.374508,
              44.561274
            ],
            [
              -110.3699,
              44.55934
            ],
            [
              -110.36537,
              44.557064
            ],
            [
              -110.36014,
              44.553548
            ],
            [
              -110.352039,
              44.552137
            ],
            [
              -110.344476,
              44.550339
            ],
            [
              -110.339222,
              44.548686
            ],
            [
              -110.335287,
              44.548366
            ],
            [
              -110.332879,
              44.548276
            ],
            [
              -110.331605,
              44.549631
            ],
            [
              -110.331803,
              44.551605
            ],
            [
              -110.329033,
              44.55426
            ],
            [
              -110.325026,
              44.554535
            ],
            [
              -110.319631,
              44.554782
            ],
            [
              -110.316077,
              44.555918
            ],
            [
              -110.309523,
              44.554541
            ],
            [
              -110.3028,
              44.551643
            ],
            [
              -110.29528,
              44.547213
            ],
            [
              -110.289964,
              44.542471
            ],
            [
              -110.289228,
              44.5397
            ],
            [
              -110.291769,
              44.537933
            ],
            [
              -110.29371,
              44.537549
            ],
            [
              -110.295069,
              44.535678
            ],
            [
              -110.296381,
              44.533631
            ],
            [
              -110.297479,
              44.5312
            ],
            [
              -110.298078,
              44.529905
            ],
            [
              -110.296782,
              44.52855
            ],
            [
              -110.293844,
              44.527918
            ],
            [
              -110.291013,
              44.528681
            ],
            [
              -110.286863,
              44.526918
            ],
            [
              -110.281833,
              44.522938
            ],
            [
              -110.277708,
              44.518574
            ],
            [
              -110.276967,
              44.514744
            ],
            [
              -110.278978,
              44.511479
            ],
            [
              -110.278819,
              44.50812
            ],
            [
              -110.279749,
              44.505185
            ],
            [
              -110.278202,
              44.500849
            ],
            [
              -110.278126,
              44.498136
            ],
            [
              -110.281877,
              44.49441
            ],
            [
              -110.284026,
              44.4903
            ],
            [
              -110.283844,
              44.487002
            ],
            [
              -110.282475,
              44.481541
            ],
            [
              -110.281924,
              44.478874
            ],
            [
              -110.282627,
              44.476079
            ],
            [
              -110.283651,
              44.474232
            ],
            [
              -110.286396,
              44.472279
            ],
            [
              -110.287561,
              44.47246
            ],
            [
              -110.289715,
              44.471928
            ],
            [
              -110.290988,
              44.470447
            ],
            [
              -110.290054,
              44.467467
            ],
            [
              -110.289856,
              44.464004
            ],
            [
              -110.290172,
              44.459998
            ],
            [
              -110.288964,
              44.453939
            ],
            [
              -110.290146,
              44.448429
            ],
            [
              -110.29273,
              44.441811
            ],
            [
              -110.292985,
              44.437053
            ],
            [
              -110.296172,
              44.435567
            ],
            [
              -110.296648,
              44.431341
            ],
            [
              -110.292444,
              44.427372
            ],
            [
              -110.28916,
              44.42476
            ],
            [
              -110.287393,
              44.420385
            ],
            [
              -110.283635,
              44.417415
            ],
            [
              -110.28042,
              44.415735
            ],
            [
              -110.276715,
              44.413594
            ],
            [
              -110.272841,
              44.413022
            ],
            [
              -110.269787,
              44.412406
            ],
            [
              -110.266266,
              44.411296
            ],
            [
              -110.260968,
              44.409922
            ],
            [
              -110.258215,
              44.407489
            ],
            [
              -110.254774,
              44.402911
            ],
            [
              -110.254386,
              44.399986
            ],
            [
              -110.253648,
              44.395688
            ],
            [
              -110.253683,
              44.39217
            ],
            [
              -110.251825,
              44.389464
            ],
            [
              -110.247102,
              44.388594
            ],
            [
              -110.241597,
              44.386313
            ],
            [
              -110.238893,
              44.383795
            ],
            [
              -110.234192,
              44.380477
            ],
            [
              -110.230608,
              44.376363
            ],
            [
              -110.228759,
              44.372906
            ],
            [
              -110.228106,
              44.368444
            ],
            [
              -110.22707,
              44.363324
            ],
            [
              -110.227894,
              44.359625
            ],
            [
              -110.226536,
              44.354429
            ],
            [
              -110.226052,
              44.351026
            ],
            [
              -110.224038,
              44.345352
            ],
            [
              -110.225817,
              44.341598
            ],
            [
              -110.224591,
              44.337285
            ],
            [
              -110.221905,
              44.334037
            ],
            [
              -110.215344,
              44.331229
            ],
            [
              -110.208278,
              44.329595
            ],
            [
              -110.20324,
              44.327812
            ],
            [
              -110.20252,
              44.325276
            ],
            [
              -110.201641,
              44.323888
            ],
            [
              -110.202543,
              44.32352
            ],
            [
              -110.201679,
              44.322955
            ],
            [
              -110.200975,
              44.322022
            ],
            [
              -110.200753,
              44.320294
            ],
            [
              -110.200929,
              44.320047
            ],
            [
              -110.201289,
              44.320562
            ],
            [
              -110.201273,
              44.320864
            ],
            [
              -110.201365,
              44.321386
            ],
            [
              -110.201732,
              44.321473
            ],
            [
              -110.20174,
              44.320612
            ],
            [
              -110.201846,
              44.319805
            ],
            [
              -110.202473,
              44.319371
            ],
            [
              -110.203612,
              44.319657
            ],
            [
              -110.204017,
              44.319014
            ],
            [
              -110.20472,
              44.318411
            ],
            [
              -110.204988,
              44.317933
            ],
            [
              -110.205676,
              44.317843
            ],
            [
              -110.205905,
              44.317867
            ],
            [
              -110.205347,
              44.317203
            ],
            [
              -110.203688,
              44.315781
            ],
            [
              -110.203259,
              44.31441
            ],
            [
              -110.20371,
              44.314234
            ],
            [
              -110.204299,
              44.314767
            ],
            [
              -110.206401,
              44.315238
            ],
            [
              -110.2078,
              44.315128
            ],
            [
              -110.206466,
              44.31114
            ],
            [
              -110.203373,
              44.307845
            ],
            [
              -110.197841,
              44.306176
            ],
            [
              -110.186831,
              44.307524
            ],
            [
              -110.183426,
              44.30616
            ],
            [
              -110.183599,
              44.303014
            ],
            [
              -110.180082,
              44.301567
            ],
            [
              -110.172383,
              44.301471
            ],
            [
              -110.168216,
              44.297262
            ],
            [
              -110.159495,
              44.295958
            ],
            [
              -110.147708,
              44.287445
            ],
            [
              -110.143848,
              44.277312
            ],
            [
              -110.146397,
              44.272104
            ],
            [
              -110.147891,
              44.267611
            ],
            [
              -110.142717,
              44.264163
            ],
            [
              -110.143225,
              44.258212
            ],
            [
              -110.13971,
              44.251863
            ],
            [
              -110.138352,
              44.249949
            ],
            [
              -110.132868,
              44.247383
            ],
            [
              -110.134489,
              44.243221
            ],
            [
              -110.135711,
              44.234847
            ],
            [
              -110.131736,
              44.224785
            ],
            [
              -110.126245,
              44.218935
            ],
            [
              -110.11957,
              44.215086
            ],
            [
              -110.116578,
              44.216446
            ],
            [
              -110.113463,
              44.21287
            ],
            [
              -110.114248,
              44.21007
            ],
            [
              -110.119658,
              44.207739
            ],
            [
              -110.123121,
              44.202649
            ],
            [
              -110.115638,
              44.200422
            ],
            [
              -110.110019,
              44.196316
            ],
            [
              -110.115517,
              44.192581
            ],
            [
              -110.117139,
              44.193314
            ],
            [
              -110.11849,
              44.187472
            ],
            [
              -110.119882,
              44.185268
            ],
            [
              -110.117148,
              44.183548
            ],
            [
              -110.115104,
              44.183078
            ],
            [
              -110.111497,
              44.184885
            ],
            [
              -110.108134,
              44.180592
            ],
            [
              -110.115737,
              44.178498
            ],
            [
              -110.120199,
              44.175878
            ],
            [
              -110.120773,
              44.172057
            ],
            [
              -110.118535,
              44.169981
            ],
            [
              -110.117806,
              44.166686
            ],
            [
              -110.120249,
              44.162935
            ],
            [
              -110.126123,
              44.1601
            ],
            [
              -110.126129,
              44.154967
            ],
            [
              -110.12665,
              44.152189
            ],
            [
              -110.124687,
              44.149134
            ],
            [
              -110.125851,
              44.143325
            ],
            [
              -110.123909,
              44.142008
            ],
            [
              -110.121499,
              44.137224
            ],
            [
              -110.118644,
              44.134902
            ],
            [
              -110.078047,
              44.132598
            ],
            [
              -110.053336,
              44.110136
            ],
            [
              -110.053193,
              44.021058
            ],
            [
              -110.053179,
              44.008022
            ],
            [
              -110.048462,
              44.008144
            ],
            [
              -110.033492,
              44.00249
            ],
            [
              -110.032139,
              43.998549
            ],
            [
              -110.032739,
              43.996449
            ],
            [
              -110.030539,
              43.993749
            ],
            [
              -110.027639,
              43.990849
            ],
            [
              -110.026039,
              43.987949
            ],
            [
              -110.023539,
              43.985249
            ],
            [
              -110.023839,
              43.983249
            ],
            [
              -110.021138,
              43.981149
            ],
            [
              -110.018838,
              43.979349
            ],
            [
              -110.019038,
              43.976249
            ],
            [
              -110.018738,
              43.972649
            ],
            [
              -110.018338,
              43.968149
            ],
            [
              -110.016138,
              43.966949
            ],
            [
              -110.012038,
              43.967349
            ],
            [
              -110.007138,
              43.966849
            ],
            [
              -110.004438,
              43.967149
            ],
            [
              -110.001637,
              43.966249
            ],
            [
              -110.001437,
              43.965149
            ],
            [
              -110.000496,
              43.963124
            ],
            [
              -110.001437,
              43.959649
            ],
            [
              -110.001837,
              43.957449
            ],
            [
              -110.001837,
              43.955049
            ],
            [
              -110.001037,
              43.954249
            ],
            [
              -109.995633,
              43.950259
            ],
            [
              -109.980257,
              43.954485
            ],
            [
              -109.947402,
              43.95913
            ],
            [
              -109.918351,
              43.946446
            ],
            [
              -109.880254,
              43.950136
            ],
            [
              -109.869564,
              43.953807
            ],
            [
              -109.857756,
              43.954946
            ],
            [
              -109.858189,
              43.946782
            ],
            [
              -109.867834,
              43.937982
            ],
            [
              -109.867534,
              43.927184
            ],
            [
              -109.859015,
              43.925319
            ],
            [
              -109.850929,
              43.921818
            ],
            [
              -109.849265,
              43.914722
            ],
            [
              -109.851284,
              43.906012
            ],
            [
              -109.846355,
              43.89955
            ],
            [
              -109.830787,
              43.88979
            ],
            [
              -109.824949,
              43.886782
            ],
            [
              -109.82511,
              43.876202
            ],
            [
              -109.829285,
              43.874952
            ],
            [
              -109.830858,
              43.870185
            ],
            [
              -109.827009,
              43.866708
            ],
            [
              -109.818468,
              43.864763
            ],
            [
              -109.81636,
              43.862986
            ],
            [
              -109.817679,
              43.860196
            ],
            [
              -109.821633,
              43.857576
            ],
            [
              -109.830232,
              43.855172
            ],
            [
              -109.838978,
              43.85416
            ],
            [
              -109.847701,
              43.853195
            ],
            [
              -109.854319,
              43.848673
            ],
            [
              -109.85657,
              43.842846
            ],
            [
              -109.852112,
              43.836824
            ],
            [
              -109.855583,
              43.831598
            ],
            [
              -109.851378,
              43.824156
            ],
            [
              -109.841747,
              43.819324
            ],
            [
              -109.833227,
              43.822155
            ],
            [
              -109.830551,
              43.823419
            ],
            [
              -109.827135,
              43.820954
            ],
            [
              -109.826777,
              43.818211
            ],
            [
              -109.823128,
              43.815844
            ],
            [
              -109.818505,
              43.815458
            ],
            [
              -109.813087,
              43.812736
            ],
            [
              -109.805589,
              43.80909
            ],
            [
              -109.79695,
              43.805413
            ],
            [
              -109.792029,
              43.803843
            ],
            [
              -109.786838,
              43.803963
            ],
            [
              -109.783874,
              43.805856
            ],
            [
              -109.780539,
              43.809581
            ],
            [
              -109.773184,
              43.813146
            ],
            [
              -109.765431,
              43.816852
            ],
            [
              -109.764386,
              43.818825
            ],
            [
              -109.759544,
              43.818689
            ],
            [
              -109.757791,
              43.817715
            ],
            [
              -109.750722,
              43.821002
            ],
            [
              -109.743849,
              43.826631
            ],
            [
              -109.732262,
              43.832514
            ],
            [
              -109.720879,
              43.836038
            ],
            [
              -109.709958,
              43.835851
            ],
            [
              -109.698145,
              43.840023
            ],
            [
              -109.684942,
              43.844026
            ],
            [
              -109.672845,
              43.844491
            ],
            [
              -109.666144,
              43.850171
            ],
            [
              -109.669623,
              43.85996
            ],
            [
              -109.67133,
              43.87012
            ],
            [
              -109.664482,
              43.879715
            ],
            [
              -109.654124,
              43.90268
            ],
            [
              -109.624514,
              43.926582
            ],
            [
              -109.608886,
              43.933752
            ],
            [
              -109.592959,
              43.943647
            ],
            [
              -109.588721,
              43.939104
            ],
            [
              -109.574856,
              43.944225
            ],
            [
              -109.559914,
              43.954636
            ],
            [
              -109.535124,
              43.954423
            ],
            [
              -109.512272,
              43.939129
            ],
            [
              -109.476047,
              43.948835
            ],
            [
              -109.472393,
              43.932177
            ],
            [
              -109.442361,
              43.930434
            ],
            [
              -109.4339,
              43.91404
            ],
            [
              -109.413418,
              43.909252
            ],
            [
              -109.398464,
              43.884341
            ],
            [
              -109.38017,
              43.882799
            ],
            [
              -109.366219,
              43.842805
            ],
            [
              -109.334956,
              43.841836
            ],
            [
              -109.321608,
              43.834034
            ],
            [
              -109.312949,
              43.813293
            ],
            [
              -109.272548,
              43.81612
            ],
            [
              -109.133171,
              43.816
            ],
            [
              -109.093603,
              43.817619
            ],
            [
              -109.092878,
              43.904474
            ],
            [
              -108.912783,
              43.904754
            ],
            [
              -108.915985,
              43.990854
            ],
            [
              -108.791779,
              43.991053
            ],
            [
              -108.792277,
              44.079616
            ],
            [
              -108.550758,
              44.079403
            ],
            [
              -108.550562,
              44.168455
            ],
            [
              -108.573797,
              44.168468
            ],
            [
              -108.573464,
              44.429038
            ],
            [
              -108.57342,
              44.520811
            ],
            [
              -108.592494,
              44.52093
            ],
            [
              -108.592596,
              44.592595
            ],
            [
              -108.592457,
              44.757825
            ],
            [
              -108.592296,
              44.871444
            ],
            [
              -108.620462,
              44.871481
            ],
            [
              -108.62131508324201,
              45.0004077717245
            ],
            [
              -108.683057040733,
              45.000297886972795
            ],
            [
              -108.95023735661601,
              44.999822374957
            ],
            [
              -109.062262,
              44.999623
            ],
            [
              -109.08301,
              44.99961
            ],
            [
              -109.103445,
              45.005904
            ],
            [
              -109.263431,
              45.005345
            ],
            [
              -109.269294,
              45.005283
            ],
            [
              -109.375713,
              45.00461
            ],
            [
              -109.386432,
              45.004887
            ],
            [
              -109.574321,
              45.002631
            ],
            [
              -109.663673,
              45.002536
            ],
            [
              -109.75073,
              45.001605
            ],
            [
              -109.79848479260801,
              45.002185541820594
            ],
            [
              -109.798687,
              45.002188
            ],
            [
              -109.875735,
              45.003275
            ],
            [
              -109.99505,
              45.003174
            ],
            [
              -110.001504976459,
              45.0032645991318
            ],
            [
              -110.025544,
              45.003602
            ],
            [
              -110.026347,
              45.003665
            ],
            [
              -110.110103,
              45.003905
            ],
            [
              -110.199503,
              44.996188
            ],
            [
              -110.28677,
              44.99685
            ],
            [
              -110.324441,
              44.999156
            ],
            [
              -110.342131,
              44.999053
            ],
            [
              -110.362698,
              45.000593
            ],
            [
              -110.402927,
              44.99381
            ],
            [
              -110.48807,
              44.992361
            ],
            [
              -110.547165,
              44.992459
            ],
            [
              -110.552433,
              44.992237
            ],
            [
              -110.705272,
              44.992324
            ],
            [
              -110.750767,
              44.997948
            ],
            [
              -110.761554,
              44.999934
            ],
            [
              -110.785008,
              45.002952
            ],
            [
              -111.044275,
              45.001345
            ],
            [
              -111.045065708123,
              45.0013432628163
            ],
            [
              -111.055199,
              45.001321
            ],
            [
              -111.056207,
              44.935901
            ],
            [
              -111.055629,
              44.933578
            ],
            [
              -111.056888,
              44.866658
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "56",
      "COUNTYFP": "023",
      "COUNTYNS": "01605076",
      "AFFGEOID": "0500000US56023",
      "GEOID": "56023",
      "NAME": "Lincoln",
      "LSAD": "06",
      "ALAND": 10555073751,
      "AWATER": 49947392,
      "County_state": "Lincoln,56"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -111.047107,
              42.148971
            ],
            [
              -111.047109,
              42.142497
            ],
            [
              -111.046689,
              42.001567
            ],
            [
              -111.046230993972,
              41.7796905597888
            ],
            [
              -111.045818464842,
              41.5798449482258
            ],
            [
              -110.579563,
              41.579166
            ],
            [
              -110.048,
              41.578015
            ],
            [
              -110.04654,
              41.835295
            ],
            [
              -110.046408,
              42.006948
            ],
            [
              -110.054262,
              42.030129
            ],
            [
              -110.053708,
              42.270744
            ],
            [
              -110.194498,
              42.270836
            ],
            [
              -110.206516,
              42.276541
            ],
            [
              -110.424923,
              42.276138
            ],
            [
              -110.541634,
              42.277491
            ],
            [
              -110.543329,
              42.448536
            ],
            [
              -110.590131,
              42.448435
            ],
            [
              -110.590074,
              42.710087
            ],
            [
              -110.623477,
              42.710181
            ],
            [
              -110.623434,
              42.779205
            ],
            [
              -110.587662,
              42.779188
            ],
            [
              -110.587955,
              42.842911
            ],
            [
              -110.587993,
              43.126147
            ],
            [
              -110.578584,
              43.126307
            ],
            [
              -110.578656,
              43.235224
            ],
            [
              -110.813993,
              43.235178
            ],
            [
              -110.813996,
              43.31394
            ],
            [
              -110.927902,
              43.31596
            ],
            [
              -111.044617,
              43.31572
            ],
            [
              -111.044229,
              43.195579
            ],
            [
              -111.044168,
              43.189244
            ],
            [
              -111.044232,
              43.18444
            ],
            [
              -111.044266,
              43.177236
            ],
            [
              -111.044235,
              43.177121
            ],
            [
              -111.044143,
              43.072364
            ],
            [
              -111.044162,
              43.068222
            ],
            [
              -111.04415,
              43.066172
            ],
            [
              -111.044117,
              43.060309
            ],
            [
              -111.044086,
              43.054819
            ],
            [
              -111.044063,
              43.046302
            ],
            [
              -111.044058,
              43.04464
            ],
            [
              -111.043997,
              43.041415
            ],
            [
              -111.044094,
              43.02927
            ],
            [
              -111.044033,
              43.026411
            ],
            [
              -111.044034,
              43.024844
            ],
            [
              -111.044034,
              43.024581
            ],
            [
              -111.044206,
              43.022614
            ],
            [
              -111.044142896217,
              43.019408
            ],
            [
              -111.044129,
              43.018702
            ],
            [
              -111.043924,
              42.975063
            ],
            [
              -111.043957,
              42.969482
            ],
            [
              -111.043959,
              42.96445
            ],
            [
              -111.043980556144,
              42.9534850266501
            ],
            [
              -111.044135,
              42.874924
            ],
            [
              -111.043564,
              42.722624
            ],
            [
              -111.046017,
              42.582723
            ],
            [
              -111.046710501596,
              42.5139239264854
            ],
            [
              -111.046801,
              42.504946
            ],
            [
              -111.04708,
              42.34942
            ],
            [
              -111.047074,
              42.280787
            ],
            [
              -111.047097,
              42.194773
            ],
            [
              -111.047058,
              42.182672
            ],
            [
              -111.047107,
              42.148971
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "56",
      "COUNTYFP": "035",
      "COUNTYNS": "01605082",
      "AFFGEOID": "0500000US56035",
      "GEOID": "56035",
      "NAME": "Sublette",
      "LSAD": "06",
      "ALAND": 12655929146,
      "AWATER": 127353628,
      "County_state": "Sublette,56"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -110.623434,
              42.779205
            ],
            [
              -110.623477,
              42.710181
            ],
            [
              -110.590074,
              42.710087
            ],
            [
              -110.590131,
              42.448435
            ],
            [
              -110.543329,
              42.448536
            ],
            [
              -110.541634,
              42.277491
            ],
            [
              -110.424923,
              42.276138
            ],
            [
              -110.206516,
              42.276541
            ],
            [
              -110.194498,
              42.270836
            ],
            [
              -110.053708,
              42.270744
            ],
            [
              -109.542998,
              42.264036
            ],
            [
              -109.043864,
              42.263684
            ],
            [
              -109.043801,
              42.433644
            ],
            [
              -109.069477,
              42.433477
            ],
            [
              -109.072851,
              42.693514
            ],
            [
              -109.134466,
              42.694289
            ],
            [
              -109.128412,
              42.708387
            ],
            [
              -109.147908,
              42.727562
            ],
            [
              -109.132315,
              42.737485
            ],
            [
              -109.179467,
              42.751045
            ],
            [
              -109.195257,
              42.768537
            ],
            [
              -109.209797,
              42.770424
            ],
            [
              -109.217449,
              42.760048
            ],
            [
              -109.236096,
              42.768458
            ],
            [
              -109.226716,
              42.788002
            ],
            [
              -109.210211,
              42.794079
            ],
            [
              -109.236875,
              42.809523
            ],
            [
              -109.247051,
              42.805686
            ],
            [
              -109.268497,
              42.824345
            ],
            [
              -109.282858,
              42.847179
            ],
            [
              -109.29166,
              42.845797
            ],
            [
              -109.323552,
              42.863868
            ],
            [
              -109.331911,
              42.89542
            ],
            [
              -109.346779,
              42.920164
            ],
            [
              -109.370341,
              42.945574
            ],
            [
              -109.397757,
              42.939198
            ],
            [
              -109.411206,
              42.955459
            ],
            [
              -109.432364,
              42.968151
            ],
            [
              -109.454103,
              42.96999
            ],
            [
              -109.460178,
              42.988059
            ],
            [
              -109.492059,
              42.995354
            ],
            [
              -109.502407,
              43.010511
            ],
            [
              -109.552579,
              43.012538
            ],
            [
              -109.554307,
              43.031952
            ],
            [
              -109.565307,
              43.050345
            ],
            [
              -109.552211,
              43.059829
            ],
            [
              -109.560451,
              43.083538
            ],
            [
              -109.59155,
              43.115381
            ],
            [
              -109.620634,
              43.125602
            ],
            [
              -109.630396,
              43.163382
            ],
            [
              -109.651451,
              43.167036
            ],
            [
              -109.660013,
              43.202939
            ],
            [
              -109.6761,
              43.21526
            ],
            [
              -109.670094,
              43.228701
            ],
            [
              -109.676798,
              43.248569
            ],
            [
              -109.692716,
              43.26503
            ],
            [
              -109.678869,
              43.274934
            ],
            [
              -109.685508,
              43.281921
            ],
            [
              -109.672372,
              43.310015
            ],
            [
              -109.698674,
              43.3306
            ],
            [
              -109.694942,
              43.366152
            ],
            [
              -109.708037,
              43.374223
            ],
            [
              -109.732899,
              43.36604
            ],
            [
              -109.754462,
              43.365905
            ],
            [
              -109.754612,
              43.430287
            ],
            [
              -109.754717,
              43.4632
            ],
            [
              -110.05157,
              43.464783
            ],
            [
              -110.05731,
              43.377768
            ],
            [
              -110.088934,
              43.377925
            ],
            [
              -110.146006,
              43.378131
            ],
            [
              -110.344218,
              43.377918
            ],
            [
              -110.343959,
              43.291165
            ],
            [
              -110.57838,
              43.29164
            ],
            [
              -110.578656,
              43.235224
            ],
            [
              -110.578584,
              43.126307
            ],
            [
              -110.587993,
              43.126147
            ],
            [
              -110.587955,
              42.842911
            ],
            [
              -110.587662,
              42.779188
            ],
            [
              -110.623434,
              42.779205
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "56",
      "COUNTYFP": "001",
      "COUNTYNS": "01605066",
      "AFFGEOID": "0500000US56001",
      "GEOID": "56001",
      "NAME": "Albany",
      "LSAD": "06",
      "ALAND": 11070455501,
      "AWATER": 89161368,
      "County_state": "Albany,56"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -106.322885,
              41.395141
            ],
            [
              -106.321165,
              40.999123
            ],
            [
              -106.217573,
              40.997734
            ],
            [
              -106.190553863626,
              40.9976070173843
            ],
            [
              -106.061181,
              40.996999
            ],
            [
              -105.730421,
              40.996886
            ],
            [
              -105.724804,
              40.99691
            ],
            [
              -105.277138,
              40.998173
            ],
            [
              -105.27685999192101,
              40.99817324279
            ],
            [
              -105.278875,
              41.116397
            ],
            [
              -105.280084,
              41.280435
            ],
            [
              -105.280636,
              41.331
            ],
            [
              -105.278236,
              41.656655
            ],
            [
              -105.278973,
              41.741995
            ],
            [
              -105.27375,
              42.088824
            ],
            [
              -105.28391,
              42.431402
            ],
            [
              -105.332638,
              42.431106
            ],
            [
              -105.342101,
              42.419065
            ],
            [
              -105.34206,
              42.404929
            ],
            [
              -105.351964,
              42.404892
            ],
            [
              -105.352229,
              42.376393
            ],
            [
              -105.361737,
              42.376319
            ],
            [
              -105.358592,
              42.319265
            ],
            [
              -105.378682,
              42.319018
            ],
            [
              -105.375541,
              42.290139
            ],
            [
              -105.500112,
              42.289954
            ],
            [
              -105.526399,
              42.288859
            ],
            [
              -105.535878,
              42.29455
            ],
            [
              -105.599416,
              42.297536
            ],
            [
              -105.613865,
              42.304455
            ],
            [
              -105.614656,
              42.377436
            ],
            [
              -105.575642,
              42.377314
            ],
            [
              -105.575546,
              42.406228
            ],
            [
              -105.556184,
              42.406253
            ],
            [
              -105.556042,
              42.430583
            ],
            [
              -106.073403,
              42.433236
            ],
            [
              -106.074919,
              42.086649
            ],
            [
              -106.073959,
              42.07297
            ],
            [
              -106.073253,
              41.983221
            ],
            [
              -106.07147,
              41.545631
            ],
            [
              -106.068406,
              41.395028
            ],
            [
              -106.208075,
              41.393767
            ],
            [
              -106.322885,
              41.395141
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "56",
      "COUNTYFP": "031",
      "COUNTYNS": "01605080",
      "AFFGEOID": "0500000US56031",
      "GEOID": "56031",
      "NAME": "Platte",
      "LSAD": "06",
      "ALAND": 5398019715,
      "AWATER": 69035953,
      "County_state": "Platte,56"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -105.286511,
              42.605826
            ],
            [
              -105.285023,
              42.585425
            ],
            [
              -105.282258,
              42.431328
            ],
            [
              -105.28391,
              42.431402
            ],
            [
              -105.27375,
              42.088824
            ],
            [
              -105.278973,
              41.741995
            ],
            [
              -105.278236,
              41.656655
            ],
            [
              -104.653338,
              41.653007
            ],
            [
              -104.653826,
              41.937963
            ],
            [
              -104.655639,
              42.609468
            ],
            [
              -104.892437,
              42.608939
            ],
            [
              -104.989243,
              42.608567
            ],
            [
              -105.118137,
              42.608054
            ],
            [
              -105.155907,
              42.606762
            ],
            [
              -105.286511,
              42.605826
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "56",
      "COUNTYFP": "011",
      "COUNTYNS": "01605071",
      "AFFGEOID": "0500000US56011",
      "GEOID": "56011",
      "NAME": "Crook",
      "LSAD": "06",
      "ALAND": 7393068933,
      "AWATER": 28186053,
      "County_state": "Crook,56"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -105.085451,
              44.786801
            ],
            [
              -105.086151,
              44.527708
            ],
            [
              -105.082358,
              44.179786
            ],
            [
              -105.079281,
              44.176182
            ],
            [
              -104.375484,
              44.181622
            ],
            [
              -104.368156,
              44.180913
            ],
            [
              -104.054487,
              44.180381
            ],
            [
              -104.055389,
              44.249983
            ],
            [
              -104.055927,
              44.51773
            ],
            [
              -104.055892,
              44.543341
            ],
            [
              -104.05587666691001,
              44.57101573190359
            ],
            [
              -104.05581,
              44.691343
            ],
            [
              -104.055938,
              44.693881
            ],
            [
              -104.055777,
              44.700466
            ],
            [
              -104.05587,
              44.723422
            ],
            [
              -104.055934,
              44.72372
            ],
            [
              -104.055963,
              44.767962
            ],
            [
              -104.055963,
              44.768236
            ],
            [
              -104.056496,
              44.867034
            ],
            [
              -104.055914,
              44.874986
            ],
            [
              -104.057698,
              44.997431
            ],
            [
              -104.250145,
              44.99822
            ],
            [
              -104.470117,
              44.998453
            ],
            [
              -104.470422,
              44.998453
            ],
            [
              -104.663882,
              44.998869
            ],
            [
              -104.665171,
              44.998618
            ],
            [
              -104.72637,
              44.999518
            ],
            [
              -104.759855,
              44.999066
            ],
            [
              -104.765063,
              44.999183
            ],
            [
              -105.01824,
              45.000437
            ],
            [
              -105.019284,
              45.000329
            ],
            [
              -105.025266,
              45.00029
            ],
            [
              -105.038252994286,
              45.000344363702396
            ],
            [
              -105.038405,
              45.000345
            ],
            [
              -105.076600973456,
              45.000347405941596
            ],
            [
              -105.076696,
              44.871072
            ],
            [
              -105.076861,
              44.786193
            ],
            [
              -105.085451,
              44.786801
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "56",
      "COUNTYFP": "015",
      "COUNTYNS": "01605073",
      "AFFGEOID": "0500000US56015",
      "GEOID": "56015",
      "NAME": "Goshen",
      "LSAD": "06",
      "ALAND": 5764311447,
      "AWATER": 16969239,
      "County_state": "Goshen,56"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -104.655639,
              42.609468
            ],
            [
              -104.653826,
              41.937963
            ],
            [
              -104.653338,
              41.653007
            ],
            [
              -104.652992,
              41.565014
            ],
            [
              -104.592495,
              41.564686
            ],
            [
              -104.131235,
              41.564447
            ],
            [
              -104.05254,
              41.564274
            ],
            [
              -104.052859,
              41.592254
            ],
            [
              -104.052735,
              41.613676
            ],
            [
              -104.052975,
              41.622931
            ],
            [
              -104.052945,
              41.638167
            ],
            [
              -104.052913,
              41.64519
            ],
            [
              -104.052829855868,
              41.697954223531795
            ],
            [
              -104.052774,
              41.733401
            ],
            [
              -104.053026,
              41.885464
            ],
            [
              -104.052931,
              41.906143
            ],
            [
              -104.052991,
              41.914973
            ],
            [
              -104.052734,
              41.973007
            ],
            [
              -104.052856,
              41.975958
            ],
            [
              -104.05283,
              41.9946
            ],
            [
              -104.052761,
              41.994967
            ],
            [
              -104.052699,
              41.998673
            ],
            [
              -104.052704176933,
              42.0017178993875
            ],
            [
              -104.052729,
              42.016318
            ],
            [
              -104.05288,
              42.021761
            ],
            [
              -104.052967,
              42.075004
            ],
            [
              -104.052966926101,
              42.075083999008
            ],
            [
              -104.052954,
              42.089077
            ],
            [
              -104.0526,
              42.124963
            ],
            [
              -104.052738,
              42.133769
            ],
            [
              -104.053001,
              42.137254
            ],
            [
              -104.052547,
              42.166801
            ],
            [
              -104.052761,
              42.170278
            ],
            [
              -104.053125,
              42.249962
            ],
            [
              -104.052793,
              42.249962
            ],
            [
              -104.052776,
              42.25822
            ],
            [
              -104.053107,
              42.499964
            ],
            [
              -104.052775,
              42.610813
            ],
            [
              -104.052775,
              42.61159
            ],
            [
              -104.052773275295,
              42.611766367052894
            ],
            [
              -104.531736,
              42.609284
            ],
            [
              -104.655639,
              42.609468
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "56",
      "COUNTYFP": "019",
      "COUNTYNS": "01609191",
      "AFFGEOID": "0500000US56019",
      "GEOID": "56019",
      "NAME": "Johnson",
      "LSAD": "06",
      "ALAND": 10759208728,
      "AWATER": 52947545,
      "County_state": "Johnson,56"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -107.369621,
              44.559837
            ],
            [
              -107.353778,
              44.542192
            ],
            [
              -107.33484,
              44.540518
            ],
            [
              -107.327128,
              44.523682
            ],
            [
              -107.294159,
              44.500707
            ],
            [
              -107.304293,
              44.463779
            ],
            [
              -107.30058,
              44.459081
            ],
            [
              -107.27841,
              44.459077
            ],
            [
              -107.278729,
              44.450819
            ],
            [
              -107.242507,
              44.438359
            ],
            [
              -107.202253,
              44.446391
            ],
            [
              -107.204152,
              44.429305
            ],
            [
              -107.199575,
              44.399399
            ],
            [
              -107.179205,
              44.403336
            ],
            [
              -107.169212,
              44.393507
            ],
            [
              -107.176505,
              44.376645
            ],
            [
              -107.161764,
              44.368208
            ],
            [
              -107.184891,
              44.3588
            ],
            [
              -107.188982,
              44.348172
            ],
            [
              -107.172614,
              44.335163
            ],
            [
              -107.145001,
              44.324004
            ],
            [
              -107.146808,
              44.165666
            ],
            [
              -107.128584,
              44.165692
            ],
            [
              -107.127622,
              43.815961
            ],
            [
              -107.113655,
              43.816092
            ],
            [
              -107.110799,
              43.555571
            ],
            [
              -107.110734,
              43.500286
            ],
            [
              -107.11092,
              43.492362
            ],
            [
              -106.446803,
              43.492716
            ],
            [
              -106.078068,
              43.494471
            ],
            [
              -106.017366,
              43.494969
            ],
            [
              -106.017598,
              43.821489
            ],
            [
              -106.009201,
              43.821579
            ],
            [
              -106.016504,
              44.169729
            ],
            [
              -106.014477,
              44.52098
            ],
            [
              -106.008643,
              44.527054
            ],
            [
              -106.009676,
              44.563981
            ],
            [
              -106.179485,
              44.56378
            ],
            [
              -106.428897,
              44.561777
            ],
            [
              -106.550001,
              44.556874
            ],
            [
              -106.814484,
              44.557872
            ],
            [
              -107.0332,
              44.559197
            ],
            [
              -107.369621,
              44.559837
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "56",
      "COUNTYFP": "037",
      "COUNTYNS": "01609192",
      "AFFGEOID": "0500000US56037",
      "GEOID": "56037",
      "NAME": "Sweetwater",
      "LSAD": "06",
      "ALAND": 27005738206,
      "AWATER": 166246301,
      "County_state": "Sweetwater,56"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -110.053708,
              42.270744
            ],
            [
              -110.054262,
              42.030129
            ],
            [
              -110.046408,
              42.006948
            ],
            [
              -110.04654,
              41.835295
            ],
            [
              -110.048,
              41.578015
            ],
            [
              -110.048482511407,
              40.9975546383386
            ],
            [
              -110.006495,
              40.997815
            ],
            [
              -110.00071595432901,
              40.9973526364013
            ],
            [
              -110.000708,
              40.997352
            ],
            [
              -109.999838,
              40.99733
            ],
            [
              -109.97553,
              40.997912
            ],
            [
              -109.855299,
              40.997614
            ],
            [
              -109.854302,
              40.997661
            ],
            [
              -109.715409,
              40.998191
            ],
            [
              -109.713877,
              40.998266
            ],
            [
              -109.676421,
              40.998395
            ],
            [
              -109.54369742276602,
              40.998158621743094
            ],
            [
              -109.534926,
              40.998143
            ],
            [
              -109.500694,
              40.999127
            ],
            [
              -109.250735,
              41.001009
            ],
            [
              -109.231985,
              41.002059
            ],
            [
              -109.173682,
              41.000859
            ],
            [
              -109.050076,
              41.000659
            ],
            [
              -108.884138,
              41.000094
            ],
            [
              -108.631108,
              41.000156
            ],
            [
              -108.526667,
              40.999608
            ],
            [
              -108.500659,
              41.000112
            ],
            [
              -108.250649,
              41.000114
            ],
            [
              -108.181227,
              41.000455
            ],
            [
              -108.046539,
              41.002064
            ],
            [
              -107.918421,
              41.002036
            ],
            [
              -107.917104,
              41.398499
            ],
            [
              -107.92988,
              41.39853
            ],
            [
              -107.929768,
              41.654185
            ],
            [
              -107.50972,
              41.657452
            ],
            [
              -107.510054,
              41.740513
            ],
            [
              -107.503821,
              42.087957
            ],
            [
              -107.522321,
              42.087957
            ],
            [
              -107.522722,
              42.261756
            ],
            [
              -108.440542,
              42.263399
            ],
            [
              -109.043864,
              42.263684
            ],
            [
              -109.542998,
              42.264036
            ],
            [
              -110.053708,
              42.270744
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "56",
      "COUNTYFP": "039",
      "COUNTYNS": "01605083",
      "AFFGEOID": "0500000US56039",
      "GEOID": "56039",
      "NAME": "Teton",
      "LSAD": "06",
      "ALAND": 10351785153,
      "AWATER": 570864021,
      "County_state": "Teton,56"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -111.055332727124,
              44.6662623099175
            ],
            [
              -111.055208,
              44.624927
            ],
            [
              -111.048974,
              44.474072
            ],
            [
              -111.049194,
              44.438058
            ],
            [
              -111.049216,
              44.435811
            ],
            [
              -111.049148,
              44.374925
            ],
            [
              -111.049695,
              44.353626
            ],
            [
              -111.049119,
              44.124923
            ],
            [
              -111.048452,
              44.114831
            ],
            [
              -111.048633,
              44.062903
            ],
            [
              -111.048751,
              44.060838
            ],
            [
              -111.048751,
              44.060403
            ],
            [
              -111.049077,
              44.020072
            ],
            [
              -111.047349,
              43.999921
            ],
            [
              -111.04706391450401,
              43.9834606121932
            ],
            [
              -111.046917,
              43.974978
            ],
            [
              -111.046515,
              43.908376
            ],
            [
              -111.046715,
              43.815832
            ],
            [
              -111.04634,
              43.726957
            ],
            [
              -111.046435,
              43.726545
            ],
            [
              -111.046421,
              43.722059
            ],
            [
              -111.04611,
              43.687848
            ],
            [
              -111.046051,
              43.685812
            ],
            [
              -111.046118,
              43.684902
            ],
            [
              -111.04588,
              43.681033
            ],
            [
              -111.045706,
              43.659112
            ],
            [
              -111.045464910528,
              43.5830897814638
            ],
            [
              -111.045204743411,
              43.501051850722895
            ],
            [
              -111.044617,
              43.31572
            ],
            [
              -110.927902,
              43.31596
            ],
            [
              -110.813996,
              43.31394
            ],
            [
              -110.813993,
              43.235178
            ],
            [
              -110.578656,
              43.235224
            ],
            [
              -110.57838,
              43.29164
            ],
            [
              -110.343959,
              43.291165
            ],
            [
              -110.344218,
              43.377918
            ],
            [
              -110.146006,
              43.378131
            ],
            [
              -110.088934,
              43.377925
            ],
            [
              -110.05731,
              43.377768
            ],
            [
              -110.05157,
              43.464783
            ],
            [
              -110.051437,
              43.814449
            ],
            [
              -110.053634,
              43.820908
            ],
            [
              -110.053179,
              44.008022
            ],
            [
              -110.053193,
              44.021058
            ],
            [
              -110.053336,
              44.110136
            ],
            [
              -110.078047,
              44.132598
            ],
            [
              -110.118644,
              44.134902
            ],
            [
              -110.121499,
              44.137224
            ],
            [
              -110.123909,
              44.142008
            ],
            [
              -110.125851,
              44.143325
            ],
            [
              -110.124687,
              44.149134
            ],
            [
              -110.12665,
              44.152189
            ],
            [
              -110.126129,
              44.154967
            ],
            [
              -110.126123,
              44.1601
            ],
            [
              -110.120249,
              44.162935
            ],
            [
              -110.117806,
              44.166686
            ],
            [
              -110.118535,
              44.169981
            ],
            [
              -110.120773,
              44.172057
            ],
            [
              -110.120199,
              44.175878
            ],
            [
              -110.115737,
              44.178498
            ],
            [
              -110.108134,
              44.180592
            ],
            [
              -110.111497,
              44.184885
            ],
            [
              -110.115104,
              44.183078
            ],
            [
              -110.117148,
              44.183548
            ],
            [
              -110.119882,
              44.185268
            ],
            [
              -110.11849,
              44.187472
            ],
            [
              -110.117139,
              44.193314
            ],
            [
              -110.115517,
              44.192581
            ],
            [
              -110.110019,
              44.196316
            ],
            [
              -110.115638,
              44.200422
            ],
            [
              -110.123121,
              44.202649
            ],
            [
              -110.119658,
              44.207739
            ],
            [
              -110.114248,
              44.21007
            ],
            [
              -110.113463,
              44.21287
            ],
            [
              -110.116578,
              44.216446
            ],
            [
              -110.11957,
              44.215086
            ],
            [
              -110.126245,
              44.218935
            ],
            [
              -110.131736,
              44.224785
            ],
            [
              -110.135711,
              44.234847
            ],
            [
              -110.134489,
              44.243221
            ],
            [
              -110.132868,
              44.247383
            ],
            [
              -110.138352,
              44.249949
            ],
            [
              -110.13971,
              44.251863
            ],
            [
              -110.143225,
              44.258212
            ],
            [
              -110.142717,
              44.264163
            ],
            [
              -110.147891,
              44.267611
            ],
            [
              -110.146397,
              44.272104
            ],
            [
              -110.143848,
              44.277312
            ],
            [
              -110.147708,
              44.287445
            ],
            [
              -110.159495,
              44.295958
            ],
            [
              -110.168216,
              44.297262
            ],
            [
              -110.172383,
              44.301471
            ],
            [
              -110.180082,
              44.301567
            ],
            [
              -110.183599,
              44.303014
            ],
            [
              -110.183426,
              44.30616
            ],
            [
              -110.186831,
              44.307524
            ],
            [
              -110.197841,
              44.306176
            ],
            [
              -110.203373,
              44.307845
            ],
            [
              -110.206466,
              44.31114
            ],
            [
              -110.2078,
              44.315128
            ],
            [
              -110.206401,
              44.315238
            ],
            [
              -110.204299,
              44.314767
            ],
            [
              -110.20371,
              44.314234
            ],
            [
              -110.203259,
              44.31441
            ],
            [
              -110.203688,
              44.315781
            ],
            [
              -110.205347,
              44.317203
            ],
            [
              -110.205905,
              44.317867
            ],
            [
              -110.205676,
              44.317843
            ],
            [
              -110.204988,
              44.317933
            ],
            [
              -110.20472,
              44.318411
            ],
            [
              -110.204017,
              44.319014
            ],
            [
              -110.203612,
              44.319657
            ],
            [
              -110.202473,
              44.319371
            ],
            [
              -110.201846,
              44.319805
            ],
            [
              -110.20174,
              44.320612
            ],
            [
              -110.201732,
              44.321473
            ],
            [
              -110.201365,
              44.321386
            ],
            [
              -110.201273,
              44.320864
            ],
            [
              -110.201289,
              44.320562
            ],
            [
              -110.200929,
              44.320047
            ],
            [
              -110.200753,
              44.320294
            ],
            [
              -110.200975,
              44.322022
            ],
            [
              -110.201679,
              44.322955
            ],
            [
              -110.202543,
              44.32352
            ],
            [
              -110.201641,
              44.323888
            ],
            [
              -110.20252,
              44.325276
            ],
            [
              -110.20324,
              44.327812
            ],
            [
              -110.208278,
              44.329595
            ],
            [
              -110.215344,
              44.331229
            ],
            [
              -110.221905,
              44.334037
            ],
            [
              -110.224591,
              44.337285
            ],
            [
              -110.225817,
              44.341598
            ],
            [
              -110.224038,
              44.345352
            ],
            [
              -110.226052,
              44.351026
            ],
            [
              -110.226536,
              44.354429
            ],
            [
              -110.227894,
              44.359625
            ],
            [
              -110.22707,
              44.363324
            ],
            [
              -110.228106,
              44.368444
            ],
            [
              -110.228759,
              44.372906
            ],
            [
              -110.230608,
              44.376363
            ],
            [
              -110.234192,
              44.380477
            ],
            [
              -110.238893,
              44.383795
            ],
            [
              -110.241597,
              44.386313
            ],
            [
              -110.247102,
              44.388594
            ],
            [
              -110.251825,
              44.389464
            ],
            [
              -110.253683,
              44.39217
            ],
            [
              -110.253648,
              44.395688
            ],
            [
              -110.254386,
              44.399986
            ],
            [
              -110.254774,
              44.402911
            ],
            [
              -110.258215,
              44.407489
            ],
            [
              -110.260968,
              44.409922
            ],
            [
              -110.266266,
              44.411296
            ],
            [
              -110.269787,
              44.412406
            ],
            [
              -110.272841,
              44.413022
            ],
            [
              -110.276715,
              44.413594
            ],
            [
              -110.28042,
              44.415735
            ],
            [
              -110.283635,
              44.417415
            ],
            [
              -110.287393,
              44.420385
            ],
            [
              -110.28916,
              44.42476
            ],
            [
              -110.292444,
              44.427372
            ],
            [
              -110.296648,
              44.431341
            ],
            [
              -110.296172,
              44.435567
            ],
            [
              -110.292985,
              44.437053
            ],
            [
              -110.29273,
              44.441811
            ],
            [
              -110.290146,
              44.448429
            ],
            [
              -110.288964,
              44.453939
            ],
            [
              -110.290172,
              44.459998
            ],
            [
              -110.289856,
              44.464004
            ],
            [
              -110.290054,
              44.467467
            ],
            [
              -110.290988,
              44.470447
            ],
            [
              -110.289715,
              44.471928
            ],
            [
              -110.287561,
              44.47246
            ],
            [
              -110.286396,
              44.472279
            ],
            [
              -110.283651,
              44.474232
            ],
            [
              -110.282627,
              44.476079
            ],
            [
              -110.281924,
              44.478874
            ],
            [
              -110.282475,
              44.481541
            ],
            [
              -110.283844,
              44.487002
            ],
            [
              -110.284026,
              44.4903
            ],
            [
              -110.281877,
              44.49441
            ],
            [
              -110.278126,
              44.498136
            ],
            [
              -110.278202,
              44.500849
            ],
            [
              -110.279749,
              44.505185
            ],
            [
              -110.278819,
              44.50812
            ],
            [
              -110.278978,
              44.511479
            ],
            [
              -110.276967,
              44.514744
            ],
            [
              -110.277708,
              44.518574
            ],
            [
              -110.281833,
              44.522938
            ],
            [
              -110.286863,
              44.526918
            ],
            [
              -110.291013,
              44.528681
            ],
            [
              -110.293844,
              44.527918
            ],
            [
              -110.296782,
              44.52855
            ],
            [
              -110.298078,
              44.529905
            ],
            [
              -110.297479,
              44.5312
            ],
            [
              -110.296381,
              44.533631
            ],
            [
              -110.295069,
              44.535678
            ],
            [
              -110.29371,
              44.537549
            ],
            [
              -110.291769,
              44.537933
            ],
            [
              -110.289228,
              44.5397
            ],
            [
              -110.289964,
              44.542471
            ],
            [
              -110.29528,
              44.547213
            ],
            [
              -110.3028,
              44.551643
            ],
            [
              -110.309523,
              44.554541
            ],
            [
              -110.316077,
              44.555918
            ],
            [
              -110.319631,
              44.554782
            ],
            [
              -110.325026,
              44.554535
            ],
            [
              -110.329033,
              44.55426
            ],
            [
              -110.331803,
              44.551605
            ],
            [
              -110.331605,
              44.549631
            ],
            [
              -110.332879,
              44.548276
            ],
            [
              -110.335287,
              44.548366
            ],
            [
              -110.339222,
              44.548686
            ],
            [
              -110.344476,
              44.550339
            ],
            [
              -110.352039,
              44.552137
            ],
            [
              -110.36014,
              44.553548
            ],
            [
              -110.36537,
              44.557064
            ],
            [
              -110.3699,
              44.55934
            ],
            [
              -110.374508,
              44.561274
            ],
            [
              -110.379903,
              44.562971
            ],
            [
              -110.38073,
              44.566945
            ],
            [
              -110.375755,
              44.568607
            ],
            [
              -110.375799,
              44.577665
            ],
            [
              -110.379844,
              44.583022
            ],
            [
              -110.522317,
              44.582756
            ],
            [
              -110.6679,
              44.582813
            ],
            [
              -110.66735,
              44.624936
            ],
            [
              -110.667867,
              44.666291
            ],
            [
              -110.772091,
              44.666742
            ],
            [
              -110.888962,
              44.666158
            ],
            [
              -110.968673,
              44.666109
            ],
            [
              -111.000755,
              44.666113
            ],
            [
              -111.055332727124,
              44.6662623099175
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "56",
      "COUNTYFP": "033",
      "COUNTYNS": "01605081",
      "AFFGEOID": "0500000US56033",
      "GEOID": "56033",
      "NAME": "Sheridan",
      "LSAD": "06",
      "ALAND": 6535475121,
      "AWATER": 7986323,
      "County_state": "Sheridan,56"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -107.911521043863,
              45.0012911855562
            ],
            [
              -107.911154,
              45.000235
            ],
            [
              -107.906047,
              44.996104
            ],
            [
              -107.894628,
              44.991727
            ],
            [
              -107.885673,
              44.988406
            ],
            [
              -107.882705,
              44.982691
            ],
            [
              -107.88162,
              44.97697
            ],
            [
              -107.879375,
              44.970082
            ],
            [
              -107.8774,
              44.96545
            ],
            [
              -107.87748,
              44.96047
            ],
            [
              -107.878272,
              44.956217
            ],
            [
              -107.879133,
              44.950207
            ],
            [
              -107.881713,
              44.939503
            ],
            [
              -107.875657,
              44.930669
            ],
            [
              -107.869479,
              44.924967
            ],
            [
              -107.857153,
              44.916856
            ],
            [
              -107.853436,
              44.912534
            ],
            [
              -107.851091,
              44.906263
            ],
            [
              -107.85021,
              44.902032
            ],
            [
              -107.851581,
              44.898685
            ],
            [
              -107.854838,
              44.897064
            ],
            [
              -107.860817,
              44.893853
            ],
            [
              -107.860766,
              44.881691
            ],
            [
              -107.863842,
              44.876105
            ],
            [
              -107.858596,
              44.867751
            ],
            [
              -107.852651,
              44.865091
            ],
            [
              -107.84591,
              44.86059
            ],
            [
              -107.83915,
              44.84825
            ],
            [
              -107.832963,
              44.845224
            ],
            [
              -107.83158,
              44.842926
            ],
            [
              -107.828944,
              44.836061
            ],
            [
              -107.829125,
              44.829922
            ],
            [
              -107.828912,
              44.825439
            ],
            [
              -107.825718,
              44.825309
            ],
            [
              -107.825319,
              44.823139
            ],
            [
              -107.826212,
              44.820505
            ],
            [
              -107.822789,
              44.820992
            ],
            [
              -107.81873,
              44.820458
            ],
            [
              -107.816749,
              44.815954
            ],
            [
              -107.817082,
              44.811803
            ],
            [
              -107.82435,
              44.8052
            ],
            [
              -107.829572,
              44.801972
            ],
            [
              -107.832401,
              44.796342
            ],
            [
              -107.819244,
              44.790829
            ],
            [
              -107.799796,
              44.783476
            ],
            [
              -107.795279,
              44.780163
            ],
            [
              -107.78543,
              44.774981
            ],
            [
              -107.766374,
              44.77338
            ],
            [
              -107.752136,
              44.765627
            ],
            [
              -107.752288,
              44.758636
            ],
            [
              -107.749436,
              44.755682
            ],
            [
              -107.746844,
              44.7512
            ],
            [
              -107.746306,
              44.74377
            ],
            [
              -107.745542,
              44.73654
            ],
            [
              -107.745675,
              44.727686
            ],
            [
              -107.748424,
              44.72482
            ],
            [
              -107.749953,
              44.722581
            ],
            [
              -107.748308,
              44.721269
            ],
            [
              -107.744016,
              44.71605
            ],
            [
              -107.735086,
              44.714697
            ],
            [
              -107.726932,
              44.715506
            ],
            [
              -107.716505,
              44.710456
            ],
            [
              -107.705509,
              44.707878
            ],
            [
              -107.699167,
              44.705153
            ],
            [
              -107.696505,
              44.707272
            ],
            [
              -107.686697,
              44.705857
            ],
            [
              -107.681367,
              44.701263
            ],
            [
              -107.683404,
              44.69151
            ],
            [
              -107.677025,
              44.68708
            ],
            [
              -107.673243,
              44.682599
            ],
            [
              -107.666176,
              44.681179
            ],
            [
              -107.663656,
              44.681267
            ],
            [
              -107.657138,
              44.683566
            ],
            [
              -107.655711,
              44.688258
            ],
            [
              -107.64766,
              44.692576
            ],
            [
              -107.637398,
              44.69152
            ],
            [
              -107.627354,
              44.69288
            ],
            [
              -107.619863,
              44.697246
            ],
            [
              -107.607947,
              44.696208
            ],
            [
              -107.593715,
              44.693296
            ],
            [
              -107.58631,
              44.688711
            ],
            [
              -107.574156,
              44.683384
            ],
            [
              -107.557526,
              44.679249
            ],
            [
              -107.549176,
              44.672735
            ],
            [
              -107.548312,
              44.669205
            ],
            [
              -107.537426,
              44.664898
            ],
            [
              -107.531926,
              44.66642
            ],
            [
              -107.525319,
              44.66301
            ],
            [
              -107.522656,
              44.661535
            ],
            [
              -107.524379,
              44.655407
            ],
            [
              -107.531149,
              44.651758
            ],
            [
              -107.531576,
              44.644799
            ],
            [
              -107.529886,
              44.639363
            ],
            [
              -107.521785,
              44.633828
            ],
            [
              -107.511754,
              44.633487
            ],
            [
              -107.503356,
              44.640044
            ],
            [
              -107.49713,
              44.641552
            ],
            [
              -107.492106,
              44.63649
            ],
            [
              -107.488558,
              44.629234
            ],
            [
              -107.481586,
              44.623891
            ],
            [
              -107.471963,
              44.613883
            ],
            [
              -107.467298,
              44.612662
            ],
            [
              -107.458002,
              44.608903
            ],
            [
              -107.45037,
              44.607088
            ],
            [
              -107.442514,
              44.60585
            ],
            [
              -107.438328,
              44.606758
            ],
            [
              -107.433287,
              44.609259
            ],
            [
              -107.430362,
              44.611559
            ],
            [
              -107.423528,
              44.610268
            ],
            [
              -107.422619,
              44.605191
            ],
            [
              -107.417595,
              44.600353
            ],
            [
              -107.409713,
              44.599361
            ],
            [
              -107.402107,
              44.599477
            ],
            [
              -107.394442,
              44.596836
            ],
            [
              -107.383865,
              44.593393
            ],
            [
              -107.381711,
              44.586479
            ],
            [
              -107.375633,
              44.582488
            ],
            [
              -107.375347,
              44.578043
            ],
            [
              -107.373438,
              44.56973
            ],
            [
              -107.369834,
              44.560921
            ],
            [
              -107.369621,
              44.559837
            ],
            [
              -107.0332,
              44.559197
            ],
            [
              -106.814484,
              44.557872
            ],
            [
              -106.550001,
              44.556874
            ],
            [
              -106.428897,
              44.561777
            ],
            [
              -106.179485,
              44.56378
            ],
            [
              -106.009676,
              44.563981
            ],
            [
              -106.010592,
              44.636134
            ],
            [
              -106.010677,
              44.693182
            ],
            [
              -106.014977,
              44.707082
            ],
            [
              -106.014275,
              44.857784
            ],
            [
              -106.023103,
              44.86882
            ],
            [
              -106.024814919023,
              44.9936876227738
            ],
            [
              -106.263586,
              44.993788
            ],
            [
              -106.263715092465,
              44.9937884330015
            ],
            [
              -106.56673983220502,
              44.9948048375
            ],
            [
              -106.888773,
              44.995885
            ],
            [
              -106.892875,
              44.995947
            ],
            [
              -106.98756544712701,
              44.9962330032121
            ],
            [
              -107.050801,
              44.996424
            ],
            [
              -107.074996,
              44.997004
            ],
            [
              -107.084939,
              44.996599
            ],
            [
              -107.105685,
              44.998734
            ],
            [
              -107.125633,
              44.999388
            ],
            [
              -107.13418,
              45.000109
            ],
            [
              -107.351441,
              45.001407
            ],
            [
              -107.49205,
              45.00148
            ],
            [
              -107.568293011909,
              45.0011171387396
            ],
            [
              -107.607824,
              45.000929
            ],
            [
              -107.608854,
              45.00086
            ],
            [
              -107.750654,
              45.000778
            ],
            [
              -107.911521043863,
              45.0012911855562
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "56",
      "COUNTYFP": "027",
      "COUNTYNS": "01605078",
      "AFFGEOID": "0500000US56027",
      "GEOID": "56027",
      "NAME": "Niobrara",
      "LSAD": "06",
      "ALAND": 6801380679,
      "AWATER": 4969450,
      "County_state": "Niobrara,56"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -104.899941,
              43.49964
            ],
            [
              -104.898374,
              42.787685
            ],
            [
              -104.893479,
              42.78282
            ],
            [
              -104.892437,
              42.608939
            ],
            [
              -104.655639,
              42.609468
            ],
            [
              -104.531736,
              42.609284
            ],
            [
              -104.052773275295,
              42.611766367052894
            ],
            [
              -104.052586,
              42.630917
            ],
            [
              -104.052741,
              42.633982
            ],
            [
              -104.052583,
              42.650062
            ],
            [
              -104.052809,
              42.749966
            ],
            [
              -104.052863,
              42.754569
            ],
            [
              -104.053127,
              43.000585
            ],
            [
              -104.053876,
              43.289801
            ],
            [
              -104.053884,
              43.297047
            ],
            [
              -104.054218,
              43.30437
            ],
            [
              -104.054403,
              43.325914
            ],
            [
              -104.054614,
              43.390949
            ],
            [
              -104.054766,
              43.428914
            ],
            [
              -104.05477918835702,
              43.4778151089155
            ],
            [
              -104.054786,
              43.503072
            ],
            [
              -104.05478713378501,
              43.503327935732
            ],
            [
              -104.899941,
              43.49964
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "56",
      "COUNTYFP": "041",
      "COUNTYNS": "01605084",
      "AFFGEOID": "0500000US56041",
      "GEOID": "56041",
      "NAME": "Uinta",
      "LSAD": "06",
      "ALAND": 5391911952,
      "AWATER": 16345637,
      "County_state": "Uinta,56"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -111.04655106042401,
              41.251626854311006
            ],
            [
              -111.046723,
              40.997959
            ],
            [
              -110.750727,
              40.996847
            ],
            [
              -110.715026,
              40.996347
            ],
            [
              -110.539819,
              40.996346
            ],
            [
              -110.500718,
              40.994746
            ],
            [
              -110.375714,
              40.994947
            ],
            [
              -110.250709,
              40.996089
            ],
            [
              -110.237848,
              40.995427
            ],
            [
              -110.125709,
              40.99655
            ],
            [
              -110.121639,
              40.997101
            ],
            [
              -110.048482511407,
              40.9975546383386
            ],
            [
              -110.048,
              41.578015
            ],
            [
              -110.579563,
              41.579166
            ],
            [
              -111.045818464842,
              41.5798449482258
            ],
            [
              -111.045789,
              41.565571
            ],
            [
              -111.046264,
              41.377731
            ],
            [
              -111.0466,
              41.360692
            ],
            [
              -111.04656630533401,
              41.285755063172594
            ],
            [
              -111.046551,
              41.251716
            ],
            [
              -111.04655106042401,
              41.251626854311006
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "56",
      "COUNTYFP": "043",
      "COUNTYNS": "01605085",
      "AFFGEOID": "0500000US56043",
      "GEOID": "56043",
      "NAME": "Washakie",
      "LSAD": "06",
      "ALAND": 5798120186,
      "AWATER": 10455585,
      "County_state": "Washakie,56"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -108.550562,
              44.168455
            ],
            [
              -108.550758,
              44.079403
            ],
            [
              -108.490254,
              44.07976
            ],
            [
              -108.489431,
              43.991866
            ],
            [
              -108.427064,
              43.992054
            ],
            [
              -108.429563,
              43.903355
            ],
            [
              -108.30936,
              43.903255
            ],
            [
              -108.309694,
              43.846619
            ],
            [
              -108.185255,
              43.848255
            ],
            [
              -108.19619,
              43.828361
            ],
            [
              -108.174938,
              43.818968
            ],
            [
              -107.956448,
              43.818666
            ],
            [
              -107.956577,
              43.733446
            ],
            [
              -107.715876,
              43.733343
            ],
            [
              -107.716218,
              43.645766
            ],
            [
              -107.595157,
              43.646427
            ],
            [
              -107.595105,
              43.501397
            ],
            [
              -107.534897,
              43.501362
            ],
            [
              -107.250624,
              43.501624
            ],
            [
              -107.230481,
              43.500344
            ],
            [
              -107.110734,
              43.500286
            ],
            [
              -107.110799,
              43.555571
            ],
            [
              -107.113655,
              43.816092
            ],
            [
              -107.127622,
              43.815961
            ],
            [
              -107.128584,
              44.165692
            ],
            [
              -107.146808,
              44.165666
            ],
            [
              -107.684857,
              44.166401
            ],
            [
              -107.722019,
              44.167877
            ],
            [
              -108.090773,
              44.168173
            ],
            [
              -108.550562,
              44.168455
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "56",
      "COUNTYFP": "013",
      "COUNTYNS": "01605072",
      "AFFGEOID": "0500000US56013",
      "GEOID": "56013",
      "NAME": "Fremont",
      "LSAD": "06",
      "ALAND": 23784954724,
      "AWATER": 213202754,
      "County_state": "Fremont,56"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -110.053179,
              44.008022
            ],
            [
              -110.053634,
              43.820908
            ],
            [
              -110.051437,
              43.814449
            ],
            [
              -110.05157,
              43.464783
            ],
            [
              -109.754717,
              43.4632
            ],
            [
              -109.754612,
              43.430287
            ],
            [
              -109.754462,
              43.365905
            ],
            [
              -109.732899,
              43.36604
            ],
            [
              -109.708037,
              43.374223
            ],
            [
              -109.694942,
              43.366152
            ],
            [
              -109.698674,
              43.3306
            ],
            [
              -109.672372,
              43.310015
            ],
            [
              -109.685508,
              43.281921
            ],
            [
              -109.678869,
              43.274934
            ],
            [
              -109.692716,
              43.26503
            ],
            [
              -109.676798,
              43.248569
            ],
            [
              -109.670094,
              43.228701
            ],
            [
              -109.6761,
              43.21526
            ],
            [
              -109.660013,
              43.202939
            ],
            [
              -109.651451,
              43.167036
            ],
            [
              -109.630396,
              43.163382
            ],
            [
              -109.620634,
              43.125602
            ],
            [
              -109.59155,
              43.115381
            ],
            [
              -109.560451,
              43.083538
            ],
            [
              -109.552211,
              43.059829
            ],
            [
              -109.565307,
              43.050345
            ],
            [
              -109.554307,
              43.031952
            ],
            [
              -109.552579,
              43.012538
            ],
            [
              -109.502407,
              43.010511
            ],
            [
              -109.492059,
              42.995354
            ],
            [
              -109.460178,
              42.988059
            ],
            [
              -109.454103,
              42.96999
            ],
            [
              -109.432364,
              42.968151
            ],
            [
              -109.411206,
              42.955459
            ],
            [
              -109.397757,
              42.939198
            ],
            [
              -109.370341,
              42.945574
            ],
            [
              -109.346779,
              42.920164
            ],
            [
              -109.331911,
              42.89542
            ],
            [
              -109.323552,
              42.863868
            ],
            [
              -109.29166,
              42.845797
            ],
            [
              -109.282858,
              42.847179
            ],
            [
              -109.268497,
              42.824345
            ],
            [
              -109.247051,
              42.805686
            ],
            [
              -109.236875,
              42.809523
            ],
            [
              -109.210211,
              42.794079
            ],
            [
              -109.226716,
              42.788002
            ],
            [
              -109.236096,
              42.768458
            ],
            [
              -109.217449,
              42.760048
            ],
            [
              -109.209797,
              42.770424
            ],
            [
              -109.195257,
              42.768537
            ],
            [
              -109.179467,
              42.751045
            ],
            [
              -109.132315,
              42.737485
            ],
            [
              -109.147908,
              42.727562
            ],
            [
              -109.128412,
              42.708387
            ],
            [
              -109.134466,
              42.694289
            ],
            [
              -109.072851,
              42.693514
            ],
            [
              -109.069477,
              42.433477
            ],
            [
              -109.043801,
              42.433644
            ],
            [
              -109.043864,
              42.263684
            ],
            [
              -108.440542,
              42.263399
            ],
            [
              -107.522722,
              42.261756
            ],
            [
              -107.522923,
              42.434356
            ],
            [
              -107.542025,
              42.434356
            ],
            [
              -107.543526,
              42.781558
            ],
            [
              -107.501425,
              42.781458
            ],
            [
              -107.501916,
              42.86889
            ],
            [
              -107.502327,
              43.12806
            ],
            [
              -107.516728,
              43.12796
            ],
            [
              -107.517031,
              43.472659
            ],
            [
              -107.535032,
              43.472759
            ],
            [
              -107.534897,
              43.501362
            ],
            [
              -107.595105,
              43.501397
            ],
            [
              -107.595137,
              43.472279
            ],
            [
              -108.156285,
              43.470628
            ],
            [
              -108.156305,
              43.459611
            ],
            [
              -108.17145,
              43.459742
            ],
            [
              -108.337861,
              43.459765
            ],
            [
              -108.33777,
              43.474061
            ],
            [
              -108.456973,
              43.474279
            ],
            [
              -108.456962,
              43.503156
            ],
            [
              -108.576167,
              43.503256
            ],
            [
              -108.576167,
              43.532556
            ],
            [
              -108.695072,
              43.532255
            ],
            [
              -108.694672,
              43.575755
            ],
            [
              -108.814276,
              43.575555
            ],
            [
              -108.814276,
              43.605255
            ],
            [
              -108.933831,
              43.604719
            ],
            [
              -108.934141,
              43.619163
            ],
            [
              -109.171638,
              43.619764
            ],
            [
              -109.171448,
              43.699198
            ],
            [
              -109.196528,
              43.711668
            ],
            [
              -109.234904,
              43.76389
            ],
            [
              -109.262135,
              43.774146
            ],
            [
              -109.274597,
              43.796873
            ],
            [
              -109.310059,
              43.806028
            ],
            [
              -109.312949,
              43.813293
            ],
            [
              -109.321608,
              43.834034
            ],
            [
              -109.334956,
              43.841836
            ],
            [
              -109.366219,
              43.842805
            ],
            [
              -109.38017,
              43.882799
            ],
            [
              -109.398464,
              43.884341
            ],
            [
              -109.413418,
              43.909252
            ],
            [
              -109.4339,
              43.91404
            ],
            [
              -109.442361,
              43.930434
            ],
            [
              -109.472393,
              43.932177
            ],
            [
              -109.476047,
              43.948835
            ],
            [
              -109.512272,
              43.939129
            ],
            [
              -109.535124,
              43.954423
            ],
            [
              -109.559914,
              43.954636
            ],
            [
              -109.574856,
              43.944225
            ],
            [
              -109.588721,
              43.939104
            ],
            [
              -109.592959,
              43.943647
            ],
            [
              -109.608886,
              43.933752
            ],
            [
              -109.624514,
              43.926582
            ],
            [
              -109.654124,
              43.90268
            ],
            [
              -109.664482,
              43.879715
            ],
            [
              -109.67133,
              43.87012
            ],
            [
              -109.669623,
              43.85996
            ],
            [
              -109.666144,
              43.850171
            ],
            [
              -109.672845,
              43.844491
            ],
            [
              -109.684942,
              43.844026
            ],
            [
              -109.698145,
              43.840023
            ],
            [
              -109.709958,
              43.835851
            ],
            [
              -109.720879,
              43.836038
            ],
            [
              -109.732262,
              43.832514
            ],
            [
              -109.743849,
              43.826631
            ],
            [
              -109.750722,
              43.821002
            ],
            [
              -109.757791,
              43.817715
            ],
            [
              -109.759544,
              43.818689
            ],
            [
              -109.764386,
              43.818825
            ],
            [
              -109.765431,
              43.816852
            ],
            [
              -109.773184,
              43.813146
            ],
            [
              -109.780539,
              43.809581
            ],
            [
              -109.783874,
              43.805856
            ],
            [
              -109.786838,
              43.803963
            ],
            [
              -109.792029,
              43.803843
            ],
            [
              -109.79695,
              43.805413
            ],
            [
              -109.805589,
              43.80909
            ],
            [
              -109.813087,
              43.812736
            ],
            [
              -109.818505,
              43.815458
            ],
            [
              -109.823128,
              43.815844
            ],
            [
              -109.826777,
              43.818211
            ],
            [
              -109.827135,
              43.820954
            ],
            [
              -109.830551,
              43.823419
            ],
            [
              -109.833227,
              43.822155
            ],
            [
              -109.841747,
              43.819324
            ],
            [
              -109.851378,
              43.824156
            ],
            [
              -109.855583,
              43.831598
            ],
            [
              -109.852112,
              43.836824
            ],
            [
              -109.85657,
              43.842846
            ],
            [
              -109.854319,
              43.848673
            ],
            [
              -109.847701,
              43.853195
            ],
            [
              -109.838978,
              43.85416
            ],
            [
              -109.830232,
              43.855172
            ],
            [
              -109.821633,
              43.857576
            ],
            [
              -109.817679,
              43.860196
            ],
            [
              -109.81636,
              43.862986
            ],
            [
              -109.818468,
              43.864763
            ],
            [
              -109.827009,
              43.866708
            ],
            [
              -109.830858,
              43.870185
            ],
            [
              -109.829285,
              43.874952
            ],
            [
              -109.82511,
              43.876202
            ],
            [
              -109.824949,
              43.886782
            ],
            [
              -109.830787,
              43.88979
            ],
            [
              -109.846355,
              43.89955
            ],
            [
              -109.851284,
              43.906012
            ],
            [
              -109.849265,
              43.914722
            ],
            [
              -109.850929,
              43.921818
            ],
            [
              -109.859015,
              43.925319
            ],
            [
              -109.867534,
              43.927184
            ],
            [
              -109.867834,
              43.937982
            ],
            [
              -109.858189,
              43.946782
            ],
            [
              -109.857756,
              43.954946
            ],
            [
              -109.869564,
              43.953807
            ],
            [
              -109.880254,
              43.950136
            ],
            [
              -109.918351,
              43.946446
            ],
            [
              -109.947402,
              43.95913
            ],
            [
              -109.980257,
              43.954485
            ],
            [
              -109.995633,
              43.950259
            ],
            [
              -110.001037,
              43.954249
            ],
            [
              -110.001837,
              43.955049
            ],
            [
              -110.001837,
              43.957449
            ],
            [
              -110.001437,
              43.959649
            ],
            [
              -110.000496,
              43.963124
            ],
            [
              -110.001437,
              43.965149
            ],
            [
              -110.001637,
              43.966249
            ],
            [
              -110.004438,
              43.967149
            ],
            [
              -110.007138,
              43.966849
            ],
            [
              -110.012038,
              43.967349
            ],
            [
              -110.016138,
              43.966949
            ],
            [
              -110.018338,
              43.968149
            ],
            [
              -110.018738,
              43.972649
            ],
            [
              -110.019038,
              43.976249
            ],
            [
              -110.018838,
              43.979349
            ],
            [
              -110.021138,
              43.981149
            ],
            [
              -110.023839,
              43.983249
            ],
            [
              -110.023539,
              43.985249
            ],
            [
              -110.026039,
              43.987949
            ],
            [
              -110.027639,
              43.990849
            ],
            [
              -110.030539,
              43.993749
            ],
            [
              -110.032739,
              43.996449
            ],
            [
              -110.032139,
              43.998549
            ],
            [
              -110.033492,
              44.00249
            ],
            [
              -110.048462,
              44.008144
            ],
            [
              -110.053179,
              44.008022
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "56",
      "COUNTYFP": "045",
      "COUNTYNS": "01605086",
      "AFFGEOID": "0500000US56045",
      "GEOID": "56045",
      "NAME": "Weston",
      "LSAD": "06",
      "ALAND": 6210804109,
      "AWATER": 5225498,
      "County_state": "Weston,56"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -105.080777,
              43.966216
            ],
            [
              -105.081407,
              43.826928
            ],
            [
              -105.080688,
              43.739945
            ],
            [
              -105.080558,
              43.5024
            ],
            [
              -105.079797,
              43.498445
            ],
            [
              -104.899941,
              43.49964
            ],
            [
              -104.05478713378501,
              43.503327935732
            ],
            [
              -104.055032,
              43.558603
            ],
            [
              -104.05484,
              43.579368
            ],
            [
              -104.054885,
              43.583512
            ],
            [
              -104.054902,
              43.583852
            ],
            [
              -104.055133,
              43.747105
            ],
            [
              -104.055138,
              43.750421
            ],
            [
              -104.05517607847001,
              43.761633042195896
            ],
            [
              -104.055487996592,
              43.853475996615394
            ],
            [
              -104.055488,
              43.853477
            ],
            [
              -104.055077,
              43.936535
            ],
            [
              -104.05495,
              43.93809
            ],
            [
              -104.054562099418,
              44.141080971847195
            ],
            [
              -104.054487,
              44.180381
            ],
            [
              -104.368156,
              44.180913
            ],
            [
              -104.375484,
              44.181622
            ],
            [
              -105.079281,
              44.176182
            ],
            [
              -105.080777,
              43.966216
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "56",
      "COUNTYFP": "003",
      "COUNTYNS": "01605067",
      "AFFGEOID": "0500000US56003",
      "GEOID": "56003",
      "NAME": "Big Horn",
      "LSAD": "06",
      "ALAND": 8123500143,
      "AWATER": 56402796,
      "County_state": "Big Horn,56"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -108.62131508324201,
              45.0004077717245
            ],
            [
              -108.620462,
              44.871481
            ],
            [
              -108.592296,
              44.871444
            ],
            [
              -108.592457,
              44.757825
            ],
            [
              -108.592596,
              44.592595
            ],
            [
              -108.592494,
              44.52093
            ],
            [
              -108.57342,
              44.520811
            ],
            [
              -108.573464,
              44.429038
            ],
            [
              -108.573797,
              44.168468
            ],
            [
              -108.550562,
              44.168455
            ],
            [
              -108.090773,
              44.168173
            ],
            [
              -107.722019,
              44.167877
            ],
            [
              -107.684857,
              44.166401
            ],
            [
              -107.146808,
              44.165666
            ],
            [
              -107.145001,
              44.324004
            ],
            [
              -107.172614,
              44.335163
            ],
            [
              -107.188982,
              44.348172
            ],
            [
              -107.184891,
              44.3588
            ],
            [
              -107.161764,
              44.368208
            ],
            [
              -107.176505,
              44.376645
            ],
            [
              -107.169212,
              44.393507
            ],
            [
              -107.179205,
              44.403336
            ],
            [
              -107.199575,
              44.399399
            ],
            [
              -107.204152,
              44.429305
            ],
            [
              -107.202253,
              44.446391
            ],
            [
              -107.242507,
              44.438359
            ],
            [
              -107.278729,
              44.450819
            ],
            [
              -107.27841,
              44.459077
            ],
            [
              -107.30058,
              44.459081
            ],
            [
              -107.304293,
              44.463779
            ],
            [
              -107.294159,
              44.500707
            ],
            [
              -107.327128,
              44.523682
            ],
            [
              -107.33484,
              44.540518
            ],
            [
              -107.353778,
              44.542192
            ],
            [
              -107.369621,
              44.559837
            ],
            [
              -107.369834,
              44.560921
            ],
            [
              -107.373438,
              44.56973
            ],
            [
              -107.375347,
              44.578043
            ],
            [
              -107.375633,
              44.582488
            ],
            [
              -107.381711,
              44.586479
            ],
            [
              -107.383865,
              44.593393
            ],
            [
              -107.394442,
              44.596836
            ],
            [
              -107.402107,
              44.599477
            ],
            [
              -107.409713,
              44.599361
            ],
            [
              -107.417595,
              44.600353
            ],
            [
              -107.422619,
              44.605191
            ],
            [
              -107.423528,
              44.610268
            ],
            [
              -107.430362,
              44.611559
            ],
            [
              -107.433287,
              44.609259
            ],
            [
              -107.438328,
              44.606758
            ],
            [
              -107.442514,
              44.60585
            ],
            [
              -107.45037,
              44.607088
            ],
            [
              -107.458002,
              44.608903
            ],
            [
              -107.467298,
              44.612662
            ],
            [
              -107.471963,
              44.613883
            ],
            [
              -107.481586,
              44.623891
            ],
            [
              -107.488558,
              44.629234
            ],
            [
              -107.492106,
              44.63649
            ],
            [
              -107.49713,
              44.641552
            ],
            [
              -107.503356,
              44.640044
            ],
            [
              -107.511754,
              44.633487
            ],
            [
              -107.521785,
              44.633828
            ],
            [
              -107.529886,
              44.639363
            ],
            [
              -107.531576,
              44.644799
            ],
            [
              -107.531149,
              44.651758
            ],
            [
              -107.524379,
              44.655407
            ],
            [
              -107.522656,
              44.661535
            ],
            [
              -107.525319,
              44.66301
            ],
            [
              -107.531926,
              44.66642
            ],
            [
              -107.537426,
              44.664898
            ],
            [
              -107.548312,
              44.669205
            ],
            [
              -107.549176,
              44.672735
            ],
            [
              -107.557526,
              44.679249
            ],
            [
              -107.574156,
              44.683384
            ],
            [
              -107.58631,
              44.688711
            ],
            [
              -107.593715,
              44.693296
            ],
            [
              -107.607947,
              44.696208
            ],
            [
              -107.619863,
              44.697246
            ],
            [
              -107.627354,
              44.69288
            ],
            [
              -107.637398,
              44.69152
            ],
            [
              -107.64766,
              44.692576
            ],
            [
              -107.655711,
              44.688258
            ],
            [
              -107.657138,
              44.683566
            ],
            [
              -107.663656,
              44.681267
            ],
            [
              -107.666176,
              44.681179
            ],
            [
              -107.673243,
              44.682599
            ],
            [
              -107.677025,
              44.68708
            ],
            [
              -107.683404,
              44.69151
            ],
            [
              -107.681367,
              44.701263
            ],
            [
              -107.686697,
              44.705857
            ],
            [
              -107.696505,
              44.707272
            ],
            [
              -107.699167,
              44.705153
            ],
            [
              -107.705509,
              44.707878
            ],
            [
              -107.716505,
              44.710456
            ],
            [
              -107.726932,
              44.715506
            ],
            [
              -107.735086,
              44.714697
            ],
            [
              -107.744016,
              44.71605
            ],
            [
              -107.748308,
              44.721269
            ],
            [
              -107.749953,
              44.722581
            ],
            [
              -107.748424,
              44.72482
            ],
            [
              -107.745675,
              44.727686
            ],
            [
              -107.745542,
              44.73654
            ],
            [
              -107.746306,
              44.74377
            ],
            [
              -107.746844,
              44.7512
            ],
            [
              -107.749436,
              44.755682
            ],
            [
              -107.752288,
              44.758636
            ],
            [
              -107.752136,
              44.765627
            ],
            [
              -107.766374,
              44.77338
            ],
            [
              -107.78543,
              44.774981
            ],
            [
              -107.795279,
              44.780163
            ],
            [
              -107.799796,
              44.783476
            ],
            [
              -107.819244,
              44.790829
            ],
            [
              -107.832401,
              44.796342
            ],
            [
              -107.829572,
              44.801972
            ],
            [
              -107.82435,
              44.8052
            ],
            [
              -107.817082,
              44.811803
            ],
            [
              -107.816749,
              44.815954
            ],
            [
              -107.81873,
              44.820458
            ],
            [
              -107.822789,
              44.820992
            ],
            [
              -107.826212,
              44.820505
            ],
            [
              -107.825319,
              44.823139
            ],
            [
              -107.825718,
              44.825309
            ],
            [
              -107.828912,
              44.825439
            ],
            [
              -107.829125,
              44.829922
            ],
            [
              -107.828944,
              44.836061
            ],
            [
              -107.83158,
              44.842926
            ],
            [
              -107.832963,
              44.845224
            ],
            [
              -107.83915,
              44.84825
            ],
            [
              -107.84591,
              44.86059
            ],
            [
              -107.852651,
              44.865091
            ],
            [
              -107.858596,
              44.867751
            ],
            [
              -107.863842,
              44.876105
            ],
            [
              -107.860766,
              44.881691
            ],
            [
              -107.860817,
              44.893853
            ],
            [
              -107.854838,
              44.897064
            ],
            [
              -107.851581,
              44.898685
            ],
            [
              -107.85021,
              44.902032
            ],
            [
              -107.851091,
              44.906263
            ],
            [
              -107.853436,
              44.912534
            ],
            [
              -107.857153,
              44.916856
            ],
            [
              -107.869479,
              44.924967
            ],
            [
              -107.875657,
              44.930669
            ],
            [
              -107.881713,
              44.939503
            ],
            [
              -107.879133,
              44.950207
            ],
            [
              -107.878272,
              44.956217
            ],
            [
              -107.87748,
              44.96047
            ],
            [
              -107.8774,
              44.96545
            ],
            [
              -107.879375,
              44.970082
            ],
            [
              -107.88162,
              44.97697
            ],
            [
              -107.882705,
              44.982691
            ],
            [
              -107.885673,
              44.988406
            ],
            [
              -107.894628,
              44.991727
            ],
            [
              -107.906047,
              44.996104
            ],
            [
              -107.911154,
              45.000235
            ],
            [
              -107.911521043863,
              45.0012911855562
            ],
            [
              -107.997353,
              45.001565
            ],
            [
              -108.000663,
              45.001223
            ],
            [
              -108.14939,
              45.001062
            ],
            [
              -108.218479,
              45.000541
            ],
            [
              -108.238139,
              45.000206
            ],
            [
              -108.249345,
              44.999458
            ],
            [
              -108.24942807283101,
              44.999461014126794
            ],
            [
              -108.271201,
              45.000251
            ],
            [
              -108.500679,
              44.999691
            ],
            [
              -108.565921,
              45.000578
            ],
            [
              -108.578484,
              45.000484
            ],
            [
              -108.62131508324201,
              45.0004077717245
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "56",
      "COUNTYFP": "017",
      "COUNTYNS": "01605074",
      "AFFGEOID": "0500000US56017",
      "GEOID": "56017",
      "NAME": "Hot Springs",
      "LSAD": "06",
      "ALAND": 5190589691,
      "AWATER": 5964358,
      "County_state": "Hot Springs,56"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -109.312949,
              43.813293
            ],
            [
              -109.310059,
              43.806028
            ],
            [
              -109.274597,
              43.796873
            ],
            [
              -109.262135,
              43.774146
            ],
            [
              -109.234904,
              43.76389
            ],
            [
              -109.196528,
              43.711668
            ],
            [
              -109.171448,
              43.699198
            ],
            [
              -109.171638,
              43.619764
            ],
            [
              -108.934141,
              43.619163
            ],
            [
              -108.933831,
              43.604719
            ],
            [
              -108.814276,
              43.605255
            ],
            [
              -108.814276,
              43.575555
            ],
            [
              -108.694672,
              43.575755
            ],
            [
              -108.695072,
              43.532255
            ],
            [
              -108.576167,
              43.532556
            ],
            [
              -108.576167,
              43.503256
            ],
            [
              -108.456962,
              43.503156
            ],
            [
              -108.456973,
              43.474279
            ],
            [
              -108.33777,
              43.474061
            ],
            [
              -108.337861,
              43.459765
            ],
            [
              -108.17145,
              43.459742
            ],
            [
              -108.156305,
              43.459611
            ],
            [
              -108.156285,
              43.470628
            ],
            [
              -107.595137,
              43.472279
            ],
            [
              -107.595105,
              43.501397
            ],
            [
              -107.595157,
              43.646427
            ],
            [
              -107.716218,
              43.645766
            ],
            [
              -107.715876,
              43.733343
            ],
            [
              -107.956577,
              43.733446
            ],
            [
              -107.956448,
              43.818666
            ],
            [
              -108.174938,
              43.818968
            ],
            [
              -108.19619,
              43.828361
            ],
            [
              -108.185255,
              43.848255
            ],
            [
              -108.309694,
              43.846619
            ],
            [
              -108.30936,
              43.903255
            ],
            [
              -108.429563,
              43.903355
            ],
            [
              -108.427064,
              43.992054
            ],
            [
              -108.489431,
              43.991866
            ],
            [
              -108.490254,
              44.07976
            ],
            [
              -108.550758,
              44.079403
            ],
            [
              -108.792277,
              44.079616
            ],
            [
              -108.791779,
              43.991053
            ],
            [
              -108.915985,
              43.990854
            ],
            [
              -108.912783,
              43.904754
            ],
            [
              -109.092878,
              43.904474
            ],
            [
              -109.093603,
              43.817619
            ],
            [
              -109.133171,
              43.816
            ],
            [
              -109.272548,
              43.81612
            ],
            [
              -109.312949,
              43.813293
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "56",
      "COUNTYFP": "021",
      "COUNTYNS": "01605075",
      "AFFGEOID": "0500000US56021",
      "GEOID": "56021",
      "NAME": "Laramie",
      "LSAD": "06",
      "ALAND": 6956482527,
      "AWATER": 4273816,
      "County_state": "Laramie,56"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -105.280636,
              41.331
            ],
            [
              -105.280084,
              41.280435
            ],
            [
              -105.278875,
              41.116397
            ],
            [
              -105.27685999192101,
              40.99817324279
            ],
            [
              -105.256527,
              40.998191
            ],
            [
              -105.254779,
              40.99821
            ],
            [
              -104.943371,
              40.998083723808904
            ],
            [
              -104.855273,
              40.998048
            ],
            [
              -104.829504,
              40.99927
            ],
            [
              -104.77302720016701,
              40.9998906726903
            ],
            [
              -104.675999,
              41.000957
            ],
            [
              -104.599525227751,
              41.0013294274846
            ],
            [
              -104.497149,
              41.001828
            ],
            [
              -104.497058,
              41.001805
            ],
            [
              -104.467672,
              41.001473
            ],
            [
              -104.214692,
              41.001657
            ],
            [
              -104.214191,
              41.001568
            ],
            [
              -104.211473,
              41.001591
            ],
            [
              -104.123586,
              41.001626
            ],
            [
              -104.10459,
              41.001543
            ],
            [
              -104.086068,
              41.001563
            ],
            [
              -104.066961,
              41.001504
            ],
            [
              -104.053249,
              41.001406
            ],
            [
              -104.053158,
              41.016809
            ],
            [
              -104.053097,
              41.018045
            ],
            [
              -104.053177,
              41.089725
            ],
            [
              -104.053025,
              41.090274
            ],
            [
              -104.053083,
              41.104985
            ],
            [
              -104.053142,
              41.114457
            ],
            [
              -104.053514,
              41.157257
            ],
            [
              -104.052666,
              41.275251
            ],
            [
              -104.052574,
              41.278019
            ],
            [
              -104.052453,
              41.278202
            ],
            [
              -104.052568,
              41.316202
            ],
            [
              -104.052476,
              41.320961
            ],
            [
              -104.052324,
              41.321144
            ],
            [
              -104.052687,
              41.330569
            ],
            [
              -104.052287592918,
              41.3932140037803
            ],
            [
              -104.052287,
              41.393307
            ],
            [
              -104.05216,
              41.407662
            ],
            [
              -104.05234,
              41.417865
            ],
            [
              -104.052478,
              41.515754
            ],
            [
              -104.052476,
              41.522343
            ],
            [
              -104.052686,
              41.539111
            ],
            [
              -104.052692,
              41.541154
            ],
            [
              -104.052584,
              41.55265
            ],
            [
              -104.052531,
              41.552723
            ],
            [
              -104.05254,
              41.564274
            ],
            [
              -104.131235,
              41.564447
            ],
            [
              -104.592495,
              41.564686
            ],
            [
              -104.652992,
              41.565014
            ],
            [
              -104.653338,
              41.653007
            ],
            [
              -105.278236,
              41.656655
            ],
            [
              -105.280636,
              41.331
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "56",
      "COUNTYFP": "007",
      "COUNTYNS": "01605069",
      "AFFGEOID": "0500000US56007",
      "GEOID": "56007",
      "NAME": "Carbon",
      "LSAD": "06",
      "ALAND": 20455215011,
      "AWATER": 171386237,
      "County_state": "Carbon,56"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -107.929768,
              41.654185
            ],
            [
              -107.92988,
              41.39853
            ],
            [
              -107.917104,
              41.398499
            ],
            [
              -107.918421,
              41.002036
            ],
            [
              -107.625624,
              41.002124
            ],
            [
              -107.367443,
              41.003073
            ],
            [
              -107.317793899405,
              41.0029672121675
            ],
            [
              -107.241194,
              41.002804
            ],
            [
              -107.000606,
              41.003444
            ],
            [
              -106.857773,
              41.002663
            ],
            [
              -106.453859,
              41.002057
            ],
            [
              -106.439563,
              41.001978
            ],
            [
              -106.437419,
              41.001795
            ],
            [
              -106.43095,
              41.001752
            ],
            [
              -106.391852,
              41.001176
            ],
            [
              -106.386356,
              41.001144
            ],
            [
              -106.321165,
              40.999123
            ],
            [
              -106.322885,
              41.395141
            ],
            [
              -106.208075,
              41.393767
            ],
            [
              -106.068406,
              41.395028
            ],
            [
              -106.07147,
              41.545631
            ],
            [
              -106.073253,
              41.983221
            ],
            [
              -106.073959,
              42.07297
            ],
            [
              -106.074919,
              42.086649
            ],
            [
              -106.073403,
              42.433236
            ],
            [
              -106.075852,
              42.433218
            ],
            [
              -106.624983,
              42.430488
            ],
            [
              -107.522923,
              42.434356
            ],
            [
              -107.522722,
              42.261756
            ],
            [
              -107.522321,
              42.087957
            ],
            [
              -107.503821,
              42.087957
            ],
            [
              -107.510054,
              41.740513
            ],
            [
              -107.50972,
              41.657452
            ],
            [
              -107.929768,
              41.654185
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "56",
      "COUNTYFP": "005",
      "COUNTYNS": "01605068",
      "AFFGEOID": "0500000US56005",
      "GEOID": "56005",
      "NAME": "Campbell",
      "LSAD": "06",
      "ALAND": 12437264300,
      "AWATER": 11038017,
      "County_state": "Campbell,56"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -106.024814919023,
              44.9936876227738
            ],
            [
              -106.023103,
              44.86882
            ],
            [
              -106.014275,
              44.857784
            ],
            [
              -106.014977,
              44.707082
            ],
            [
              -106.010677,
              44.693182
            ],
            [
              -106.010592,
              44.636134
            ],
            [
              -106.009676,
              44.563981
            ],
            [
              -106.008643,
              44.527054
            ],
            [
              -106.014477,
              44.52098
            ],
            [
              -106.016504,
              44.169729
            ],
            [
              -106.009201,
              43.821579
            ],
            [
              -106.017598,
              43.821489
            ],
            [
              -106.017366,
              43.494969
            ],
            [
              -105.685006,
              43.495823
            ],
            [
              -105.079797,
              43.498445
            ],
            [
              -105.080558,
              43.5024
            ],
            [
              -105.080688,
              43.739945
            ],
            [
              -105.081407,
              43.826928
            ],
            [
              -105.080777,
              43.966216
            ],
            [
              -105.079281,
              44.176182
            ],
            [
              -105.082358,
              44.179786
            ],
            [
              -105.086151,
              44.527708
            ],
            [
              -105.085451,
              44.786801
            ],
            [
              -105.076861,
              44.786193
            ],
            [
              -105.076696,
              44.871072
            ],
            [
              -105.076600973456,
              45.000347405941596
            ],
            [
              -105.371313283991,
              45.000365969694
            ],
            [
              -105.848065,
              45.000396
            ],
            [
              -105.913382,
              45.000941
            ],
            [
              -105.914258,
              44.999986
            ],
            [
              -105.928184,
              44.993647
            ],
            [
              -106.024814919023,
              44.9936876227738
            ]
          ]
        ]
      ]
    }
  }
]
};