export const counties_wi = {
  "type":"FeatureCollection","name":"wi_counties","features":[
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "075",
      "COUNTYNS": "01581097",
      "AFFGEOID": "0500000US55075",
      "GEOID": "55075",
      "NAME": "Marinette",
      "LSAD": "06",
      "ALAND": 3624558795,
      "AWATER": 389917044,
      "County_state": "Marinette,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.42595,
              45.621453
            ],
            [
              -88.428102,
              45.37701
            ],
            [
              -88.305811,
              45.37461
            ],
            [
              -88.309063,
              45.20158
            ],
            [
              -88.186355,
              45.197974
            ],
            [
              -88.189247,
              45.111844
            ],
            [
              -88.118879,
              45.110197
            ],
            [
              -88.121152,
              45.023581
            ],
            [
              -88.004308,
              45.021455
            ],
            [
              -87.942995,
              45.020418
            ],
            [
              -87.944436,
              44.993057
            ],
            [
              -87.883386,
              44.99046
            ],
            [
              -87.760071,
              44.99053
            ],
            [
              -87.7626200275805,
              44.965796461784
            ],
            [
              -87.696492,
              44.974233
            ],
            [
              -87.661964,
              44.973035
            ],
            [
              -87.630298,
              44.976865
            ],
            [
              -87.624693,
              45.014176
            ],
            [
              -87.625748,
              45.045157
            ],
            [
              -87.610395,
              45.075617
            ],
            [
              -87.601849,
              45.082297
            ],
            [
              -87.594718,
              45.085134
            ],
            [
              -87.591583,
              45.083792
            ],
            [
              -87.587992,
              45.085271
            ],
            [
              -87.587147,
              45.089495
            ],
            [
              -87.590208,
              45.095264
            ],
            [
              -87.59188,
              45.094689
            ],
            [
              -87.614897,
              45.100064
            ],
            [
              -87.621609,
              45.102399
            ],
            [
              -87.62764,
              45.103328
            ],
            [
              -87.628829,
              45.104039
            ],
            [
              -87.629571,
              45.105324
            ],
            [
              -87.631535,
              45.106224
            ],
            [
              -87.63611,
              45.105918
            ],
            [
              -87.648191,
              45.106368
            ],
            [
              -87.652512,
              45.108633
            ],
            [
              -87.657135,
              45.107568
            ],
            [
              -87.659952,
              45.107512
            ],
            [
              -87.661211,
              45.108279
            ],
            [
              -87.661296,
              45.112566
            ],
            [
              -87.662087985458,
              45.1133221101264
            ],
            [
              -87.667102,
              45.118109
            ],
            [
              -87.671,
              45.120069
            ],
            [
              -87.672447,
              45.121294
            ],
            [
              -87.678209,
              45.130084
            ],
            [
              -87.678511,
              45.131204
            ],
            [
              -87.676024,
              45.134089
            ],
            [
              -87.675816,
              45.135059
            ],
            [
              -87.683902,
              45.144135
            ],
            [
              -87.688425,
              45.147433
            ],
            [
              -87.692375,
              45.149505
            ],
            [
              -87.695055,
              45.150522
            ],
            [
              -87.700618,
              45.151188
            ],
            [
              -87.703492,
              45.152206
            ],
            [
              -87.707391,
              45.154679
            ],
            [
              -87.708134,
              45.156004
            ],
            [
              -87.711322,
              45.158946
            ],
            [
              -87.717945,
              45.161156
            ],
            [
              -87.723121,
              45.165141
            ],
            [
              -87.724601,
              45.167452
            ],
            [
              -87.727768,
              45.169596
            ],
            [
              -87.730866,
              45.170913
            ],
            [
              -87.735135,
              45.171538
            ],
            [
              -87.736104,
              45.172244
            ],
            [
              -87.736509,
              45.173389
            ],
            [
              -87.73521,
              45.177642
            ],
            [
              -87.741805,
              45.197051
            ],
            [
              -87.741732,
              45.198201
            ],
            [
              -87.739492,
              45.202126
            ],
            [
              -87.736339,
              45.204653
            ],
            [
              -87.72796,
              45.207956
            ],
            [
              -87.726198,
              45.209391
            ],
            [
              -87.726175,
              45.21264
            ],
            [
              -87.727276,
              45.216129
            ],
            [
              -87.726952,
              45.218949
            ],
            [
              -87.722473,
              45.223309
            ],
            [
              -87.721354,
              45.226847
            ],
            [
              -87.721935,
              45.228444
            ],
            [
              -87.72492,
              45.229977
            ],
            [
              -87.725205,
              45.231539
            ],
            [
              -87.724156,
              45.233236
            ],
            [
              -87.718264,
              45.238333
            ],
            [
              -87.717051,
              45.238743
            ],
            [
              -87.713398,
              45.238564
            ],
            [
              -87.712184,
              45.239014
            ],
            [
              -87.711339,
              45.239965
            ],
            [
              -87.71148,
              45.245224
            ],
            [
              -87.709145,
              45.254649
            ],
            [
              -87.707779,
              45.258343
            ],
            [
              -87.709137,
              45.260341
            ],
            [
              -87.703053,
              45.267041
            ],
            [
              -87.69878,
              45.26942
            ],
            [
              -87.698456,
              45.272072
            ],
            [
              -87.699492,
              45.276659
            ],
            [
              -87.698248,
              45.281512
            ],
            [
              -87.693468,
              45.287675
            ],
            [
              -87.690364,
              45.29027
            ],
            [
              -87.687578,
              45.296283
            ],
            [
              -87.687498,
              45.298055
            ],
            [
              -87.679085,
              45.305841
            ],
            [
              -87.675328,
              45.307907
            ],
            [
              -87.667423,
              45.31636
            ],
            [
              -87.665243,
              45.317115
            ],
            [
              -87.663666,
              45.318257
            ],
            [
              -87.6615,
              45.321386
            ],
            [
              -87.662029,
              45.326434
            ],
            [
              -87.661603,
              45.327608
            ],
            [
              -87.65983,
              45.329144
            ],
            [
              -87.655775,
              45.330847
            ],
            [
              -87.648126,
              45.339396
            ],
            [
              -87.647454,
              45.345232
            ],
            [
              -87.647729,
              45.350721
            ],
            [
              -87.648476,
              45.352243
            ],
            [
              -87.650661,
              45.353798
            ],
            [
              -87.653568,
              45.354204
            ],
            [
              -87.656632,
              45.358617
            ],
            [
              -87.655807,
              45.362706
            ],
            [
              -87.656624,
              45.367295
            ],
            [
              -87.657349,
              45.368752
            ],
            [
              -87.673513,
              45.376946
            ],
            [
              -87.674403,
              45.378065
            ],
            [
              -87.67455,
              45.381649
            ],
            [
              -87.675017,
              45.382454
            ],
            [
              -87.682866,
              45.38495
            ],
            [
              -87.685934,
              45.388711
            ],
            [
              -87.690281,
              45.389822
            ],
            [
              -87.693956,
              45.389893
            ],
            [
              -87.699797,
              45.387927
            ],
            [
              -87.704337,
              45.385462
            ],
            [
              -87.706767,
              45.383827
            ],
            [
              -87.708329,
              45.381218
            ],
            [
              -87.718891,
              45.377462
            ],
            [
              -87.733409,
              45.364432
            ],
            [
              -87.737801,
              45.359635
            ],
            [
              -87.738352,
              45.358243
            ],
            [
              -87.750928,
              45.355037
            ],
            [
              -87.751626,
              45.354169
            ],
            [
              -87.751452,
              45.351755
            ],
            [
              -87.754104,
              45.349442
            ],
            [
              -87.762128,
              45.348401
            ],
            [
              -87.769172,
              45.351195
            ],
            [
              -87.771384,
              45.35121
            ],
            [
              -87.773901,
              45.351226
            ],
            [
              -87.783076,
              45.349725
            ],
            [
              -87.787967,
              45.352612
            ],
            [
              -87.790324,
              45.353444
            ],
            [
              -87.800464,
              45.353608
            ],
            [
              -87.810076,
              45.351269
            ],
            [
              -87.823028,
              45.35265
            ],
            [
              -87.823554,
              45.351637
            ],
            [
              -87.824855,
              45.350713
            ],
            [
              -87.826918,
              45.350538
            ],
            [
              -87.829775,
              45.352005
            ],
            [
              -87.832612,
              45.352249
            ],
            [
              -87.835303,
              45.35098
            ],
            [
              -87.836782,
              45.346451
            ],
            [
              -87.838141,
              45.345101
            ],
            [
              -87.848368,
              45.340676
            ],
            [
              -87.850133,
              45.340435
            ],
            [
              -87.851318,
              45.341346
            ],
            [
              -87.851475,
              45.342335
            ],
            [
              -87.849899,
              45.344651
            ],
            [
              -87.850418,
              45.347492
            ],
            [
              -87.852784,
              45.349497
            ],
            [
              -87.858617,
              45.350378
            ],
            [
              -87.860871,
              45.351192
            ],
            [
              -87.863489,
              45.35302
            ],
            [
              -87.864873,
              45.354767
            ],
            [
              -87.865274,
              45.355969
            ],
            [
              -87.865675,
              45.358213
            ],
            [
              -87.867037,
              45.360137
            ],
            [
              -87.86856,
              45.360537
            ],
            [
              -87.871485,
              45.371546
            ],
            [
              -87.871789,
              45.373557
            ],
            [
              -87.875692,
              45.377052
            ],
            [
              -87.875424,
              45.379373
            ],
            [
              -87.873568,
              45.381357
            ],
            [
              -87.870905,
              45.383116
            ],
            [
              -87.864677,
              45.385232
            ],
            [
              -87.859418,
              45.388227
            ],
            [
              -87.85683,
              45.393106
            ],
            [
              -87.859603,
              45.396409
            ],
            [
              -87.859773,
              45.397278
            ],
            [
              -87.859131,
              45.398967
            ],
            [
              -87.850969,
              45.401925
            ],
            [
              -87.849322,
              45.403872
            ],
            [
              -87.849668,
              45.409518
            ],
            [
              -87.850533,
              45.411685
            ],
            [
              -87.85181,
              45.413103
            ],
            [
              -87.856216,
              45.416101
            ],
            [
              -87.860432,
              45.423504
            ],
            [
              -87.860127,
              45.429584
            ],
            [
              -87.86195,
              45.433072
            ],
            [
              -87.861697,
              45.434473
            ],
            [
              -87.855298,
              45.441379
            ],
            [
              -87.847429,
              45.444177
            ],
            [
              -87.844815,
              45.448411
            ],
            [
              -87.836008,
              45.450877
            ],
            [
              -87.833042,
              45.453596
            ],
            [
              -87.832456,
              45.45502
            ],
            [
              -87.82743,
              45.458076
            ],
            [
              -87.821057,
              45.459955
            ],
            [
              -87.812976,
              45.464159
            ],
            [
              -87.812971,
              45.4661
            ],
            [
              -87.811469,
              45.467991
            ],
            [
              -87.805773,
              45.473139
            ],
            [
              -87.805873,
              45.47438
            ],
            [
              -87.807388,
              45.477031
            ],
            [
              -87.806891,
              45.479092
            ],
            [
              -87.79896,
              45.485147
            ],
            [
              -87.798362,
              45.486564
            ],
            [
              -87.797824,
              45.491468
            ],
            [
              -87.796409,
              45.494679
            ],
            [
              -87.793447,
              45.498372
            ],
            [
              -87.792769,
              45.499967
            ],
            [
              -87.793215,
              45.505028
            ],
            [
              -87.798794,
              45.506287
            ],
            [
              -87.802267,
              45.514233
            ],
            [
              -87.804203,
              45.524676
            ],
            [
              -87.80472,
              45.531244
            ],
            [
              -87.804528,
              45.534373
            ],
            [
              -87.803364,
              45.537016
            ],
            [
              -87.80339,
              45.538272
            ],
            [
              -87.807159,
              45.543523
            ],
            [
              -87.813737,
              45.548616
            ],
            [
              -87.818791,
              45.5521
            ],
            [
              -87.827215,
              45.55562
            ],
            [
              -87.832296,
              45.558767
            ],
            [
              -87.832968,
              45.559461
            ],
            [
              -87.833591,
              45.562529
            ],
            [
              -87.831689,
              45.568035
            ],
            [
              -87.829346,
              45.568776
            ],
            [
              -87.813745,
              45.565175
            ],
            [
              -87.806104,
              45.562863
            ],
            [
              -87.797536,
              45.562124
            ],
            [
              -87.792372,
              45.563055
            ],
            [
              -87.790874,
              45.564096
            ],
            [
              -87.788798,
              45.565947
            ],
            [
              -87.788326,
              45.567941
            ],
            [
              -87.787292,
              45.574906
            ],
            [
              -87.787534,
              45.581376
            ],
            [
              -87.786767,
              45.58283
            ],
            [
              -87.785647,
              45.58396
            ],
            [
              -87.781255,
              45.585682
            ],
            [
              -87.777199,
              45.588499
            ],
            [
              -87.776238,
              45.597797
            ],
            [
              -87.777671,
              45.609204
            ],
            [
              -87.780845,
              45.614599
            ],
            [
              -87.792016,
              45.616756
            ],
            [
              -87.79588,
              45.618846
            ],
            [
              -87.796179,
              45.622074
            ],
            [
              -87.796983,
              45.623613
            ],
            [
              -87.804481,
              45.628933
            ],
            [
              -87.810194,
              45.638732
            ],
            [
              -87.817277,
              45.643926
            ],
            [
              -87.821818,
              45.645589
            ],
            [
              -87.824102,
              45.647138
            ],
            [
              -87.824676,
              45.653211
            ],
            [
              -87.822693,
              45.656077
            ],
            [
              -87.822425,
              45.658012
            ],
            [
              -87.823672,
              45.659817
            ],
            [
              -87.823868,
              45.66192
            ],
            [
              -87.823164,
              45.662732
            ],
            [
              -87.80329,
              45.666494
            ],
            [
              -87.798903,
              45.67014
            ],
            [
              -87.795355,
              45.671334
            ],
            [
              -87.781623,
              45.67328
            ],
            [
              -87.781007,
              45.673934
            ],
            [
              -87.780737,
              45.675458
            ],
            [
              -87.780808,
              45.680349
            ],
            [
              -87.782226,
              45.683053
            ],
            [
              -87.787727,
              45.68718
            ],
            [
              -87.80188,
              45.693862
            ],
            [
              -87.804993,
              45.695796
            ],
            [
              -87.809075,
              45.699717
            ],
            [
              -87.809181,
              45.700337
            ],
            [
              -87.805076,
              45.703556
            ],
            [
              -87.805081,
              45.704974
            ],
            [
              -87.805867,
              45.706841
            ],
            [
              -87.810144,
              45.71023
            ],
            [
              -87.812338,
              45.711303
            ],
            [
              -87.831442,
              45.714938
            ],
            [
              -87.837343,
              45.716919
            ],
            [
              -87.846816947262,
              45.7221550835504
            ],
            [
              -87.85548,
              45.726943
            ],
            [
              -87.86432,
              45.737139
            ],
            [
              -87.863874,
              45.74266
            ],
            [
              -87.86305,
              45.74309
            ],
            [
              -87.864141,
              45.745697
            ],
            [
              -87.868111,
              45.749477
            ],
            [
              -87.873339,
              45.750439
            ],
            [
              -87.875813,
              45.753888
            ],
            [
              -87.879812,
              45.754843
            ],
            [
              -87.882261,
              45.754779
            ],
            [
              -87.891905,
              45.754055
            ],
            [
              -87.896032,
              45.752285
            ],
            [
              -87.898363,
              45.752503
            ],
            [
              -87.900005,
              45.753497
            ],
            [
              -87.901299,
              45.756553
            ],
            [
              -87.902707,
              45.757932
            ],
            [
              -87.904657,
              45.759163
            ],
            [
              -87.905873,
              45.759364
            ],
            [
              -87.907771,
              45.75928
            ],
            [
              -87.908933,
              45.758297
            ],
            [
              -87.921999,
              45.756989
            ],
            [
              -87.926611,
              45.75959
            ],
            [
              -87.92913,
              45.760364
            ],
            [
              -87.934585,
              45.758094
            ],
            [
              -87.944113,
              45.757422
            ],
            [
              -87.954459,
              45.758414
            ],
            [
              -87.959277,
              45.757367
            ],
            [
              -87.963452,
              45.75822
            ],
            [
              -87.964725,
              45.759461
            ],
            [
              -87.963996,
              45.760794
            ],
            [
              -87.96697,
              45.764021
            ],
            [
              -87.972451,
              45.766319
            ],
            [
              -87.976835,
              45.767015
            ],
            [
              -87.986429,
              45.769596
            ],
            [
              -87.983392,
              45.774696
            ],
            [
              -87.981789,
              45.775081
            ],
            [
              -87.98087,
              45.776977
            ],
            [
              -87.982617,
              45.782944
            ],
            [
              -87.987942,
              45.793075
            ],
            [
              -87.989831,
              45.794827
            ],
            [
              -87.991447,
              45.795393
            ],
            [
              -87.995876,
              45.795435
            ],
            [
              -88.001593,
              45.794091
            ],
            [
              -88.007043,
              45.792192
            ],
            [
              -88.017588,
              45.792455
            ],
            [
              -88.0236,
              45.790094
            ],
            [
              -88.027228,
              45.78919
            ],
            [
              -88.031124,
              45.789233
            ],
            [
              -88.033568,
              45.789816
            ],
            [
              -88.039729,
              45.789626
            ],
            [
              -88.040221,
              45.789236
            ],
            [
              -88.040892,
              45.786452
            ],
            [
              -88.044697,
              45.783718
            ],
            [
              -88.048514,
              45.782549
            ],
            [
              -88.050634,
              45.780972
            ],
            [
              -88.0582559967311,
              45.7807194372151
            ],
            [
              -88.059246,
              45.713063
            ],
            [
              -88.425302,
              45.722426
            ],
            [
              -88.42595,
              45.621453
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "137",
      "COUNTYNS": "01581128",
      "AFFGEOID": "0500000US55137",
      "GEOID": "55137",
      "NAME": "Waushara",
      "LSAD": "06",
      "ALAND": 1621695264,
      "AWATER": 29151536,
      "County_state": "Waushara,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.59798,
              44.245725
            ],
            [
              -89.59795,
              43.9821
            ],
            [
              -89.168612,
              43.982834
            ],
            [
              -89.128209,
              43.983186
            ],
            [
              -88.950821,
              43.983342
            ],
            [
              -88.948206,
              43.983449
            ],
            [
              -88.926478,
              43.983408
            ],
            [
              -88.886193,
              43.983233
            ],
            [
              -88.885984,
              44.069025
            ],
            [
              -88.886673,
              44.242622
            ],
            [
              -89.224813,
              44.243393
            ],
            [
              -89.246994,
              44.243692
            ],
            [
              -89.346378,
              44.243847
            ],
            [
              -89.59798,
              44.245725
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "113",
      "COUNTYNS": "01581116",
      "AFFGEOID": "0500000US55113",
      "GEOID": "55113",
      "NAME": "Sawyer",
      "LSAD": "06",
      "ALAND": 3256422227,
      "AWATER": 240678258,
      "County_state": "Sawyer,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.550951,
              46.04111
            ],
            [
              -91.551804,
              45.98334
            ],
            [
              -91.541806,
              45.982586
            ],
            [
              -91.540294,
              45.637605
            ],
            [
              -90.678749,
              45.638264
            ],
            [
              -90.678666,
              45.953155
            ],
            [
              -90.676968,
              45.981554
            ],
            [
              -90.925174,
              45.981055
            ],
            [
              -90.924634,
              46.068
            ],
            [
              -90.924583,
              46.154616
            ],
            [
              -91.238381,
              46.157908
            ],
            [
              -91.551282,
              46.157045
            ],
            [
              -91.550951,
              46.04111
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "135",
      "COUNTYNS": "01581127",
      "AFFGEOID": "0500000US55135",
      "GEOID": "55135",
      "NAME": "Waupaca",
      "LSAD": "06",
      "ALAND": 1936513136,
      "AWATER": 45278485,
      "County_state": "Waupaca,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.223745,
              44.681365
            ],
            [
              -89.224813,
              44.243393
            ],
            [
              -88.886673,
              44.242622
            ],
            [
              -88.7662,
              44.243366
            ],
            [
              -88.73977,
              44.243303
            ],
            [
              -88.738565,
              44.316038
            ],
            [
              -88.736834,
              44.373558
            ],
            [
              -88.736732,
              44.416155
            ],
            [
              -88.736802,
              44.590605
            ],
            [
              -88.606083,
              44.590515
            ],
            [
              -88.605159,
              44.678331
            ],
            [
              -88.736338,
              44.679066
            ],
            [
              -88.885481,
              44.679673
            ],
            [
              -88.905141,
              44.679743
            ],
            [
              -89.223745,
              44.681365
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "071",
      "COUNTYNS": "01581095",
      "AFFGEOID": "0500000US55071",
      "GEOID": "55071",
      "NAME": "Manitowoc",
      "LSAD": "06",
      "ALAND": 1526268667,
      "AWATER": 2343369333,
      "County_state": "Manitowoc,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.04324,
              44.241018
            ],
            [
              -88.04365,
              44.153497
            ],
            [
              -88.042138,
              43.979181
            ],
            [
              -88.042138,
              43.97917
            ],
            [
              -88.041775,
              43.921165
            ],
            [
              -88.041829,
              43.906496
            ],
            [
              -88.041794,
              43.891692
            ],
            [
              -87.7313223858007,
              43.8921842757007
            ],
            [
              -87.726803,
              43.903133
            ],
            [
              -87.719041,
              43.937781
            ],
            [
              -87.716037,
              43.943705
            ],
            [
              -87.703951,
              43.956651
            ],
            [
              -87.69892,
              43.965936
            ],
            [
              -87.6969592548105,
              43.9795045632865
            ],
            [
              -87.695503,
              43.989582
            ],
            [
              -87.683361,
              44.020139
            ],
            [
              -87.671316,
              44.03735
            ],
            [
              -87.656083,
              44.051794
            ],
            [
              -87.653483,
              44.067194
            ],
            [
              -87.655183,
              44.081894
            ],
            [
              -87.646583,
              44.104694
            ],
            [
              -87.621082,
              44.121895
            ],
            [
              -87.62010777769801,
              44.122367642502894
            ],
            [
              -87.600882,
              44.131695
            ],
            [
              -87.563181,
              44.144195
            ],
            [
              -87.53994,
              44.15969
            ],
            [
              -87.51966,
              44.17987
            ],
            [
              -87.512903,
              44.192808
            ],
            [
              -87.507419,
              44.210803
            ],
            [
              -87.508419,
              44.229669
            ],
            [
              -87.51313812731802,
              44.2403868260505
            ],
            [
              -87.521755,
              44.259957
            ],
            [
              -87.541155,
              44.293143
            ],
            [
              -87.544716,
              44.306864
            ],
            [
              -87.545382,
              44.321385
            ],
            [
              -87.54294984058659,
              44.3275124998493
            ],
            [
              -87.58336,
              44.327563
            ],
            [
              -87.766026,
              44.327184
            ],
            [
              -87.887526,
              44.327589
            ],
            [
              -87.888087,
              44.240461
            ],
            [
              -87.923755,
              44.240402
            ],
            [
              -88.04324,
              44.241018
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "069",
      "COUNTYNS": "01581094",
      "AFFGEOID": "0500000US55069",
      "GEOID": "55069",
      "NAME": "Lincoln",
      "LSAD": "06",
      "ALAND": 2276114436,
      "AWATER": 72463206,
      "County_state": "Lincoln,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.046143,
              45.340309
            ],
            [
              -90.043685,
              45.120214
            ],
            [
              -89.964494,
              45.120162
            ],
            [
              -89.726836,
              45.119966
            ],
            [
              -89.492023,
              45.119451
            ],
            [
              -89.425971,
              45.119098
            ],
            [
              -89.424824,
              45.467166
            ],
            [
              -89.428258,
              45.555353
            ],
            [
              -89.548504,
              45.55573
            ],
            [
              -90.043414,
              45.555082
            ],
            [
              -90.042273,
              45.381866
            ],
            [
              -90.046143,
              45.340309
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "119",
      "COUNTYNS": "01581119",
      "AFFGEOID": "0500000US55119",
      "GEOID": "55119",
      "NAME": "Taylor",
      "LSAD": "06",
      "ALAND": 2525318829,
      "AWATER": 24686414,
      "County_state": "Taylor,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.925222,
              45.292061
            ],
            [
              -90.925903,
              45.120101
            ],
            [
              -90.923362,
              45.031143
            ],
            [
              -90.800082,
              45.031601
            ],
            [
              -90.557053,
              45.031121
            ],
            [
              -90.433612,
              45.030977
            ],
            [
              -90.315038,
              45.033829
            ],
            [
              -90.197514,
              45.03364
            ],
            [
              -90.197765,
              45.120604
            ],
            [
              -90.043685,
              45.120214
            ],
            [
              -90.046143,
              45.340309
            ],
            [
              -90.042273,
              45.381866
            ],
            [
              -90.457848,
              45.376909
            ],
            [
              -90.678773,
              45.377907
            ],
            [
              -90.925343,
              45.37935
            ],
            [
              -90.925222,
              45.292061
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "133",
      "COUNTYNS": "01581126",
      "AFFGEOID": "0500000US55133",
      "GEOID": "55133",
      "NAME": "Waukesha",
      "LSAD": "06",
      "ALAND": 1423675192,
      "AWATER": 79851458,
      "County_state": "Waukesha,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.541579,
              43.019387
            ],
            [
              -88.542026,
              42.93094
            ],
            [
              -88.541535,
              42.842996
            ],
            [
              -88.42407,
              42.842587
            ],
            [
              -88.375504,
              42.842792
            ],
            [
              -88.306384,
              42.842095
            ],
            [
              -88.229309,
              42.841915
            ],
            [
              -88.188016,
              42.84284
            ],
            [
              -88.069924,
              42.843323
            ],
            [
              -88.069587,
              42.921952
            ],
            [
              -88.069496,
              42.929957
            ],
            [
              -88.06929,
              42.952374
            ],
            [
              -88.069247,
              42.959179
            ],
            [
              -88.068887,
              42.973666
            ],
            [
              -88.06862,
              42.98096
            ],
            [
              -88.067252,
              43.016399
            ],
            [
              -88.067113,
              43.023597
            ],
            [
              -88.066911,
              43.0593
            ],
            [
              -88.066825,
              43.059935
            ],
            [
              -88.066143,
              43.098409
            ],
            [
              -88.066025,
              43.104221
            ],
            [
              -88.065589,
              43.120591
            ],
            [
              -88.065621,
              43.125416
            ],
            [
              -88.064403,
              43.147993
            ],
            [
              -88.064317,
              43.159666
            ],
            [
              -88.063353,
              43.192117
            ],
            [
              -88.136409,
              43.192003
            ],
            [
              -88.182228,
              43.192571
            ],
            [
              -88.300085,
              43.193139
            ],
            [
              -88.417987,
              43.194697
            ],
            [
              -88.535843,
              43.196048
            ],
            [
              -88.539619,
              43.107605
            ],
            [
              -88.541579,
              43.019387
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "073",
      "COUNTYNS": "01581096",
      "AFFGEOID": "0500000US55073",
      "GEOID": "55073",
      "NAME": "Marathon",
      "LSAD": "06",
      "ALAND": 4001465174,
      "AWATER": 81219897,
      "County_state": "Marathon,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.316574,
              44.953278
            ],
            [
              -90.316253,
              44.685154
            ],
            [
              -90.139696,
              44.68518
            ],
            [
              -90.079442,
              44.685167
            ],
            [
              -89.963861,
              44.684828
            ],
            [
              -89.844931,
              44.684945
            ],
            [
              -89.490264,
              44.685852
            ],
            [
              -89.345917,
              44.68138
            ],
            [
              -89.223745,
              44.681365
            ],
            [
              -89.224008,
              44.856265
            ],
            [
              -89.22345,
              44.942853
            ],
            [
              -89.223814,
              45.029246
            ],
            [
              -89.224214,
              45.118567
            ],
            [
              -89.425971,
              45.119098
            ],
            [
              -89.492023,
              45.119451
            ],
            [
              -89.726836,
              45.119966
            ],
            [
              -89.964494,
              45.120162
            ],
            [
              -90.043685,
              45.120214
            ],
            [
              -90.197765,
              45.120604
            ],
            [
              -90.197514,
              45.03364
            ],
            [
              -90.315038,
              45.033829
            ],
            [
              -90.316574,
              44.953278
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "019",
      "COUNTYNS": "01581069",
      "AFFGEOID": "0500000US55019",
      "GEOID": "55019",
      "NAME": "Clark",
      "LSAD": "06",
      "ALAND": 3133093098,
      "AWATER": 23242138,
      "County_state": "Clark,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.923362,
              45.031143
            ],
            [
              -90.922668,
              44.973379
            ],
            [
              -90.922669,
              44.944428
            ],
            [
              -90.922247,
              44.857309
            ],
            [
              -90.92235,
              44.596293
            ],
            [
              -90.922889,
              44.50984
            ],
            [
              -90.801627,
              44.509683
            ],
            [
              -90.801918,
              44.422442
            ],
            [
              -90.558746,
              44.42221
            ],
            [
              -90.316055,
              44.424502
            ],
            [
              -90.31605,
              44.424673
            ],
            [
              -90.316253,
              44.685154
            ],
            [
              -90.316574,
              44.953278
            ],
            [
              -90.315038,
              45.033829
            ],
            [
              -90.433612,
              45.030977
            ],
            [
              -90.557053,
              45.031121
            ],
            [
              -90.800082,
              45.031601
            ],
            [
              -90.923362,
              45.031143
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "097",
      "COUNTYNS": "01581109",
      "AFFGEOID": "0500000US55097",
      "GEOID": "55097",
      "NAME": "Portage",
      "LSAD": "06",
      "ALAND": 2074125052,
      "AWATER": 56939082,
      "County_state": "Portage,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.844931,
              44.684945
            ],
            [
              -89.84441,
              44.511542
            ],
            [
              -89.726622,
              44.511191
            ],
            [
              -89.72639,
              44.44935
            ],
            [
              -89.724752,
              44.373385
            ],
            [
              -89.724746,
              44.247678
            ],
            [
              -89.59798,
              44.245725
            ],
            [
              -89.346378,
              44.243847
            ],
            [
              -89.246994,
              44.243692
            ],
            [
              -89.224813,
              44.243393
            ],
            [
              -89.223745,
              44.681365
            ],
            [
              -89.345917,
              44.68138
            ],
            [
              -89.490264,
              44.685852
            ],
            [
              -89.844931,
              44.684945
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "141",
      "COUNTYNS": "01581130",
      "AFFGEOID": "0500000US55141",
      "GEOID": "55141",
      "NAME": "Wood",
      "LSAD": "06",
      "ALAND": 2053928984,
      "AWATER": 42054151,
      "County_state": "Wood,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.316055,
              44.424502
            ],
            [
              -90.317939,
              44.254276
            ],
            [
              -90.31268,
              44.24875
            ],
            [
              -90.080691,
              44.249011
            ],
            [
              -89.902783,
              44.249473
            ],
            [
              -89.902363,
              44.24947
            ],
            [
              -89.724746,
              44.247678
            ],
            [
              -89.724752,
              44.373385
            ],
            [
              -89.72639,
              44.44935
            ],
            [
              -89.726622,
              44.511191
            ],
            [
              -89.84441,
              44.511542
            ],
            [
              -89.844931,
              44.684945
            ],
            [
              -89.963861,
              44.684828
            ],
            [
              -90.079442,
              44.685167
            ],
            [
              -90.139696,
              44.68518
            ],
            [
              -90.316253,
              44.685154
            ],
            [
              -90.31605,
              44.424673
            ],
            [
              -90.316055,
              44.424502
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "078",
      "COUNTYNS": "01581099",
      "AFFGEOID": "0500000US55078",
      "GEOID": "55078",
      "NAME": "Menominee",
      "LSAD": "06",
      "ALAND": 926201674,
      "AWATER": 19038372,
      "County_state": "Menominee,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.982184,
              45.118027
            ],
            [
              -88.98168,
              45.028917
            ],
            [
              -88.981319,
              44.942745
            ],
            [
              -88.735548,
              44.943178
            ],
            [
              -88.736194,
              44.856376
            ],
            [
              -88.489149,
              44.855445
            ],
            [
              -88.483732,
              45.116887
            ],
            [
              -88.641159,
              45.117346
            ],
            [
              -88.930554,
              45.11781
            ],
            [
              -88.982184,
              45.118027
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "047",
      "COUNTYNS": "01581083",
      "AFFGEOID": "0500000US55047",
      "GEOID": "55047",
      "NAME": "Green Lake",
      "LSAD": "06",
      "ALAND": 904711548,
      "AWATER": 79805895,
      "County_state": "Green Lake,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.24559,
              43.730631
            ],
            [
              -89.245576,
              43.715849
            ],
            [
              -89.245437,
              43.643083
            ],
            [
              -89.126921,
              43.64367
            ],
            [
              -89.12717,
              43.631724
            ],
            [
              -89.006848,
              43.633045
            ],
            [
              -88.886052,
              43.63354
            ],
            [
              -88.885704,
              43.808032
            ],
            [
              -88.885697,
              43.895184
            ],
            [
              -88.886193,
              43.983233
            ],
            [
              -88.926478,
              43.983408
            ],
            [
              -88.948206,
              43.983449
            ],
            [
              -88.950821,
              43.983342
            ],
            [
              -89.128209,
              43.983186
            ],
            [
              -89.168612,
              43.982834
            ],
            [
              -89.168121,
              43.87647
            ],
            [
              -89.198324,
              43.876512
            ],
            [
              -89.198181,
              43.812163
            ],
            [
              -89.184992,
              43.804316
            ],
            [
              -89.168018,
              43.810118
            ],
            [
              -89.167577,
              43.765522
            ],
            [
              -89.238602,
              43.766732
            ],
            [
              -89.245375,
              43.759813
            ],
            [
              -89.24559,
              43.730631
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "103",
      "COUNTYNS": "01581112",
      "AFFGEOID": "0500000US55103",
      "GEOID": "55103",
      "NAME": "Richland",
      "LSAD": "06",
      "ALAND": 1518120269,
      "AWATER": 8153437,
      "County_state": "Richland,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.671652,
              43.552856
            ],
            [
              -90.668561,
              43.422994
            ],
            [
              -90.666012,
              43.379423
            ],
            [
              -90.666786,
              43.171777
            ],
            [
              -90.608608,
              43.185372
            ],
            [
              -90.588903,
              43.200688
            ],
            [
              -90.545408,
              43.211455
            ],
            [
              -90.429825,
              43.20094
            ],
            [
              -90.409772,
              43.200145
            ],
            [
              -90.350513,
              43.209786
            ],
            [
              -90.31113,
              43.206677
            ],
            [
              -90.297802,
              43.208211
            ],
            [
              -90.278369,
              43.19409
            ],
            [
              -90.26068,
              43.19777
            ],
            [
              -90.218447,
              43.173234
            ],
            [
              -90.193814,
              43.164464
            ],
            [
              -90.192451,
              43.169293
            ],
            [
              -90.191938,
              43.380083
            ],
            [
              -90.191781,
              43.467383
            ],
            [
              -90.192015,
              43.511148
            ],
            [
              -90.192281,
              43.523082
            ],
            [
              -90.191963,
              43.527319
            ],
            [
              -90.192176,
              43.532868
            ],
            [
              -90.191964,
              43.554996
            ],
            [
              -90.311069,
              43.553991
            ],
            [
              -90.431302,
              43.553718
            ],
            [
              -90.671652,
              43.552856
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "105",
      "COUNTYNS": "01581113",
      "AFFGEOID": "0500000US55105",
      "GEOID": "55105",
      "NAME": "Rock",
      "LSAD": "06",
      "ALAND": 1859979851,
      "AWATER": 20695904,
      "County_state": "Rock,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.369128,
              42.845036
            ],
            [
              -89.369096,
              42.770197
            ],
            [
              -89.3657987753767,
              42.5002603886239
            ],
            [
              -89.361561,
              42.500012
            ],
            [
              -89.290896,
              42.498853
            ],
            [
              -89.26266774014029,
              42.4982488672741
            ],
            [
              -89.250759,
              42.497994
            ],
            [
              -89.246972,
              42.49813
            ],
            [
              -89.228279,
              42.498047
            ],
            [
              -89.22627,
              42.497957
            ],
            [
              -89.166728,
              42.497256
            ],
            [
              -89.164905,
              42.497347
            ],
            [
              -89.125111,
              42.496957
            ],
            [
              -89.120365,
              42.496992
            ],
            [
              -89.116949,
              42.49691
            ],
            [
              -89.099012,
              42.496499
            ],
            [
              -89.071141,
              42.496208
            ],
            [
              -89.042898,
              42.496255
            ],
            [
              -89.013804,
              42.496097
            ],
            [
              -89.013667,
              42.496087
            ],
            [
              -89.01166603513579,
              42.496081094639095
            ],
            [
              -88.992659,
              42.496025
            ],
            [
              -88.9925591348397,
              42.4960231581706
            ],
            [
              -88.943264,
              42.495114
            ],
            [
              -88.940391,
              42.495046
            ],
            [
              -88.9403879812716,
              42.4950459398454
            ],
            [
              -88.786681,
              42.491983
            ],
            [
              -88.7764931385154,
              42.492023615741594
            ],
            [
              -88.778876,
              42.754753
            ],
            [
              -88.777137,
              42.834488
            ],
            [
              -88.777076,
              42.842694
            ],
            [
              -88.918672,
              42.846121
            ],
            [
              -89.013582,
              42.84763
            ],
            [
              -89.250276,
              42.845913
            ],
            [
              -89.363019,
              42.845141
            ],
            [
              -89.369128,
              42.845036
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "059",
      "COUNTYNS": "01581089",
      "AFFGEOID": "0500000US55059",
      "GEOID": "55059",
      "NAME": "Kenosha",
      "LSAD": "06",
      "ALAND": 704208614,
      "AWATER": 1248708387,
      "County_state": "Kenosha,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.305891,
              42.610817
            ],
            [
              -88.305262,
              42.55269
            ],
            [
              -88.304692,
              42.4947727016231
            ],
            [
              -88.271691,
              42.494818
            ],
            [
              -88.2716687936822,
              42.4948190331628
            ],
            [
              -88.25009,
              42.495823
            ],
            [
              -88.2169,
              42.495923
            ],
            [
              -88.200172,
              42.496016
            ],
            [
              -88.1995371176412,
              42.496017518266896
            ],
            [
              -88.115285,
              42.496219
            ],
            [
              -88.0942078450277,
              42.495929403501
            ],
            [
              -88.049782,
              42.495319
            ],
            [
              -87.99018,
              42.494519
            ],
            [
              -87.971279,
              42.494019
            ],
            [
              -87.900242,
              42.49302
            ],
            [
              -87.8979977686293,
              42.4929917529839
            ],
            [
              -87.843594,
              42.492307
            ],
            [
              -87.815872,
              42.49192
            ],
            [
              -87.800477,
              42.49192
            ],
            [
              -87.809672,
              42.51482
            ],
            [
              -87.812273,
              42.52982
            ],
            [
              -87.8128073003271,
              42.5562144361601
            ],
            [
              -87.813273,
              42.57922
            ],
            [
              -87.810873,
              42.58732
            ],
            [
              -87.815074,
              42.59412
            ],
            [
              -87.815752368,
              42.596092
            ],
            [
              -87.819374,
              42.60662
            ],
            [
              -87.819674,
              42.61582
            ],
            [
              -87.814674,
              42.64402
            ],
            [
              -87.8058558866897,
              42.667888960330096
            ],
            [
              -87.845984,
              42.668097
            ],
            [
              -87.863945,
              42.668487
            ],
            [
              -88.188306,
              42.669709
            ],
            [
              -88.188173,
              42.611456
            ],
            [
              -88.269777,
              42.611055
            ],
            [
              -88.305891,
              42.610817
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "077",
      "COUNTYNS": "01581098",
      "AFFGEOID": "0500000US55077",
      "GEOID": "55077",
      "NAME": "Marquette",
      "LSAD": "06",
      "ALAND": 1180275605,
      "AWATER": 22682651,
      "County_state": "Marquette,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.599041,
              43.730801
            ],
            [
              -89.599547,
              43.642636
            ],
            [
              -89.483561,
              43.642575
            ],
            [
              -89.245437,
              43.643083
            ],
            [
              -89.245576,
              43.715849
            ],
            [
              -89.24559,
              43.730631
            ],
            [
              -89.245375,
              43.759813
            ],
            [
              -89.238602,
              43.766732
            ],
            [
              -89.167577,
              43.765522
            ],
            [
              -89.168018,
              43.810118
            ],
            [
              -89.184992,
              43.804316
            ],
            [
              -89.198181,
              43.812163
            ],
            [
              -89.198324,
              43.876512
            ],
            [
              -89.168121,
              43.87647
            ],
            [
              -89.168612,
              43.982834
            ],
            [
              -89.59795,
              43.9821
            ],
            [
              -89.599041,
              43.730801
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "099",
      "COUNTYNS": "01581110",
      "AFFGEOID": "0500000US55099",
      "GEOID": "55099",
      "NAME": "Price",
      "LSAD": "06",
      "ALAND": 3247805080,
      "AWATER": 62281745,
      "County_state": "Price,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.678749,
              45.638264
            ],
            [
              -90.67981,
              45.53769
            ],
            [
              -90.67798,
              45.49363
            ],
            [
              -90.678773,
              45.377907
            ],
            [
              -90.457848,
              45.376909
            ],
            [
              -90.042273,
              45.381866
            ],
            [
              -90.043414,
              45.555082
            ],
            [
              -90.043484,
              45.730362
            ],
            [
              -90.045198,
              45.817652
            ],
            [
              -90.042857,
              45.897263
            ],
            [
              -90.043958,
              45.981948
            ],
            [
              -90.302063,
              45.981349
            ],
            [
              -90.676968,
              45.981554
            ],
            [
              -90.678666,
              45.953155
            ],
            [
              -90.678749,
              45.638264
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "067",
      "COUNTYNS": "01581093",
      "AFFGEOID": "0500000US55067",
      "GEOID": "55067",
      "NAME": "Langlade",
      "LSAD": "06",
      "ALAND": 2254971901,
      "AWATER": 44403904,
      "County_state": "Langlade,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.424824,
              45.467166
            ],
            [
              -89.425971,
              45.119098
            ],
            [
              -89.224214,
              45.118567
            ],
            [
              -89.223814,
              45.029246
            ],
            [
              -88.98168,
              45.028917
            ],
            [
              -88.982184,
              45.118027
            ],
            [
              -88.930554,
              45.11781
            ],
            [
              -88.641159,
              45.117346
            ],
            [
              -88.638174,
              45.204815
            ],
            [
              -88.680066,
              45.204994
            ],
            [
              -88.677996,
              45.378683
            ],
            [
              -88.926025,
              45.378637
            ],
            [
              -88.923062,
              45.465137
            ],
            [
              -89.04649,
              45.464439
            ],
            [
              -89.125117,
              45.467361
            ],
            [
              -89.364049,
              45.469886
            ],
            [
              -89.424824,
              45.467166
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "017",
      "COUNTYNS": "01581068",
      "AFFGEOID": "0500000US55017",
      "GEOID": "55017",
      "NAME": "Chippewa",
      "LSAD": "06",
      "ALAND": 2611629940,
      "AWATER": 85231859,
      "County_state": "Chippewa,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.665647,
              45.207991
            ],
            [
              -91.666402,
              45.120823
            ],
            [
              -91.65158,
              45.120803
            ],
            [
              -91.651396,
              45.030165
            ],
            [
              -91.650455,
              44.855951
            ],
            [
              -91.549087,
              44.857076
            ],
            [
              -91.547052,
              44.85709
            ],
            [
              -91.538652,
              44.857183
            ],
            [
              -91.51447,
              44.857348
            ],
            [
              -91.464481,
              44.857261
            ],
            [
              -90.922247,
              44.857309
            ],
            [
              -90.922669,
              44.944428
            ],
            [
              -90.922668,
              44.973379
            ],
            [
              -90.923362,
              45.031143
            ],
            [
              -90.925903,
              45.120101
            ],
            [
              -90.925222,
              45.292061
            ],
            [
              -91.541322,
              45.29222
            ],
            [
              -91.542192,
              45.210073
            ],
            [
              -91.542234,
              45.206538
            ],
            [
              -91.555316,
              45.206892
            ],
            [
              -91.557933,
              45.206834
            ],
            [
              -91.559563,
              45.206873
            ],
            [
              -91.665647,
              45.207991
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "007",
      "COUNTYNS": "01581063",
      "AFFGEOID": "0500000US55007",
      "GEOID": "55007",
      "NAME": "Bayfield",
      "LSAD": "06",
      "ALAND": 3827691502,
      "AWATER": 1460289843,
      "County_state": "Bayfield,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.982101,
              46.985855
            ],
            [
              -90.983886,
              46.979311
            ],
            [
              -90.980316,
              46.971578
            ],
            [
              -90.961876,
              46.961465
            ],
            [
              -90.932132,
              46.962655
            ],
            [
              -90.923209,
              46.98645
            ],
            [
              -90.928563,
              47.000726
            ],
            [
              -90.939866,
              47.001321
            ],
            [
              -90.949383,
              46.991208
            ],
            [
              -90.982101,
              46.985855
            ]
          ]
        ],
        [
          [
            [
              -91.553853,
              46.517608
            ],
            [
              -91.551622,
              46.502324
            ],
            [
              -91.551084,
              46.331031
            ],
            [
              -91.551282,
              46.157045
            ],
            [
              -91.238381,
              46.157908
            ],
            [
              -90.924583,
              46.154616
            ],
            [
              -90.92755,
              46.50217
            ],
            [
              -90.92705394131201,
              46.58505642233249
            ],
            [
              -90.94193,
              46.588419
            ],
            [
              -90.951476,
              46.597033
            ],
            [
              -90.951543,
              46.600621
            ],
            [
              -90.949621,
              46.602975
            ],
            [
              -90.93868,
              46.608322
            ],
            [
              -90.924487,
              46.625417
            ],
            [
              -90.920936,
              46.631584
            ],
            [
              -90.920835,
              46.637351
            ],
            [
              -90.915152,
              46.65841
            ],
            [
              -90.911281,
              46.663083
            ],
            [
              -90.885869,
              46.670374
            ],
            [
              -90.868468,
              46.680375
            ],
            [
              -90.853829,
              46.693457
            ],
            [
              -90.852704,
              46.699582
            ],
            [
              -90.870396,
              46.723293
            ],
            [
              -90.883396,
              46.746987
            ],
            [
              -90.885021,
              46.756341
            ],
            [
              -90.866586,
              46.764408
            ],
            [
              -90.862333,
              46.768135
            ],
            [
              -90.859445,
              46.773985
            ],
            [
              -90.863542,
              46.780565
            ],
            [
              -90.856531,
              46.788885
            ],
            [
              -90.835607,
              46.789759
            ],
            [
              -90.828057,
              46.797415
            ],
            [
              -90.825696,
              46.803858
            ],
            [
              -90.798936,
              46.823143
            ],
            [
              -90.780972,
              46.858989
            ],
            [
              -90.77017,
              46.876296
            ],
            [
              -90.761567,
              46.88317
            ],
            [
              -90.754734,
              46.88488
            ],
            [
              -90.751031,
              46.887963
            ],
            [
              -90.750858,
              46.893035
            ],
            [
              -90.754552,
              46.89827
            ],
            [
              -90.785606,
              46.926431
            ],
            [
              -90.837716,
              46.957438
            ],
            [
              -90.855874,
              46.962232
            ],
            [
              -90.871126,
              46.961129
            ],
            [
              -90.879621,
              46.958088
            ],
            [
              -90.908598,
              46.941305
            ],
            [
              -90.913838,
              46.9334
            ],
            [
              -90.921811,
              46.931322
            ],
            [
              -90.954537,
              46.938429
            ],
            [
              -90.964865,
              46.94378
            ],
            [
              -90.968419,
              46.94391
            ],
            [
              -90.973755,
              46.941304
            ],
            [
              -90.984617,
              46.925602
            ],
            [
              -90.995149,
              46.917577
            ],
            [
              -90.998848,
              46.915975
            ],
            [
              -91.005199,
              46.916203
            ],
            [
              -91.019141,
              46.911502
            ],
            [
              -91.034518,
              46.903053
            ],
            [
              -91.036622,
              46.893594
            ],
            [
              -91.03989,
              46.88923
            ],
            [
              -91.052991,
              46.881325
            ],
            [
              -91.06822,
              46.878309
            ],
            [
              -91.080951,
              46.883609
            ],
            [
              -91.090916,
              46.88267
            ],
            [
              -91.093714,
              46.879882
            ],
            [
              -91.096565,
              46.86153
            ],
            [
              -91.10549,
              46.85762
            ],
            [
              -91.123109,
              46.856173
            ],
            [
              -91.134948,
              46.858986
            ],
            [
              -91.136512,
              46.860975
            ],
            [
              -91.133337,
              46.870341
            ],
            [
              -91.134668,
              46.87249
            ],
            [
              -91.140165,
              46.873201
            ],
            [
              -91.144266,
              46.870301
            ],
            [
              -91.147837,
              46.863082
            ],
            [
              -91.167601,
              46.84476
            ],
            [
              -91.178292,
              46.844259
            ],
            [
              -91.200107,
              46.854017
            ],
            [
              -91.204439,
              46.858816
            ],
            [
              -91.207524,
              46.865835
            ],
            [
              -91.211647,
              46.866824
            ],
            [
              -91.226796,
              46.86361
            ],
            [
              -91.232733,
              46.860035
            ],
            [
              -91.250806,
              46.841135
            ],
            [
              -91.256705,
              46.836887
            ],
            [
              -91.265866,
              46.833944
            ],
            [
              -91.302295,
              46.830343
            ],
            [
              -91.314815,
              46.826825
            ],
            [
              -91.33825,
              46.817704
            ],
            [
              -91.352191,
              46.807417
            ],
            [
              -91.360804,
              46.798136
            ],
            [
              -91.368819,
              46.793836
            ],
            [
              -91.391469,
              46.790205
            ],
            [
              -91.398256,
              46.791213
            ],
            [
              -91.411799,
              46.78964
            ],
            [
              -91.423713,
              46.78217
            ],
            [
              -91.449327,
              46.773303
            ],
            [
              -91.470181,
              46.768911
            ],
            [
              -91.492429,
              46.766663
            ],
            [
              -91.499696,
              46.761243
            ],
            [
              -91.511077,
              46.757453
            ],
            [
              -91.537115,
              46.754788
            ],
            [
              -91.551408,
              46.755666
            ],
            [
              -91.553853,
              46.517608
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "123",
      "COUNTYNS": "01581121",
      "AFFGEOID": "0500000US55123",
      "GEOID": "55123",
      "NAME": "Vernon",
      "LSAD": "06",
      "ALAND": 2050135759,
      "AWATER": 64289523,
      "County_state": "Vernon,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.272741,
              43.676609
            ],
            [
              -91.273252,
              43.666623
            ],
            [
              -91.271749,
              43.654929
            ],
            [
              -91.270767,
              43.65308
            ],
            [
              -91.265051,
              43.649141
            ],
            [
              -91.263856,
              43.647662
            ],
            [
              -91.262397,
              43.64176
            ],
            [
              -91.263178,
              43.638203
            ],
            [
              -91.268457,
              43.627352
            ],
            [
              -91.268748,
              43.615348
            ],
            [
              -91.265091,
              43.609977
            ],
            [
              -91.261631,
              43.606175
            ],
            [
              -91.258267,
              43.603484
            ],
            [
              -91.252926,
              43.600363
            ],
            [
              -91.239109,
              43.58976
            ],
            [
              -91.234499,
              43.585529
            ],
            [
              -91.232707,
              43.583533
            ],
            [
              -91.231865,
              43.581822
            ],
            [
              -91.23149,
              43.575595
            ],
            [
              -91.232812,
              43.564842
            ],
            [
              -91.234432,
              43.561781
            ],
            [
              -91.240649,
              43.554995
            ],
            [
              -91.243214,
              43.550722
            ],
            [
              -91.24382,
              43.54913
            ],
            [
              -91.244093,
              43.54562
            ],
            [
              -91.243183,
              43.540309
            ],
            [
              -91.236725,
              43.53293
            ],
            [
              -91.232941,
              43.523967
            ],
            [
              -91.230027,
              43.521595
            ],
            [
              -91.222613,
              43.517892
            ],
            [
              -91.218292,
              43.514434
            ],
            [
              -91.217353,
              43.512474
            ],
            [
              -91.217876,
              43.508104
            ],
            [
              -91.217706,
              43.50055
            ],
            [
              -91.21827,
              43.497228
            ],
            [
              -91.217615,
              43.491008
            ],
            [
              -91.215282,
              43.484798
            ],
            [
              -91.216035,
              43.481142
            ],
            [
              -91.220399,
              43.471306
            ],
            [
              -91.224586,
              43.465525
            ],
            [
              -91.229503,
              43.462607
            ],
            [
              -91.232241,
              43.460018
            ],
            [
              -91.233187,
              43.457784
            ],
            [
              -91.233367,
              43.455168
            ],
            [
              -91.232276,
              43.450952
            ],
            [
              -91.22875,
              43.445537
            ],
            [
              -91.207145,
              43.425031
            ],
            [
              -91.2055506500066,
              43.422948502358096
            ],
            [
              -90.746685,
              43.424552
            ],
            [
              -90.668561,
              43.422994
            ],
            [
              -90.671652,
              43.552856
            ],
            [
              -90.431302,
              43.553718
            ],
            [
              -90.311069,
              43.553991
            ],
            [
              -90.312404,
              43.640988
            ],
            [
              -90.312194,
              43.731479
            ],
            [
              -90.432832,
              43.725893
            ],
            [
              -90.584727,
              43.725807
            ],
            [
              -90.587213,
              43.729526
            ],
            [
              -90.607057,
              43.725985
            ],
            [
              -90.910653,
              43.725334
            ],
            [
              -91.25777341547641,
              43.725660823086095
            ],
            [
              -91.258756,
              43.723426
            ],
            [
              -91.261316,
              43.71949
            ],
            [
              -91.266538,
              43.713947
            ],
            [
              -91.268455,
              43.709824
            ],
            [
              -91.267792,
              43.695652
            ],
            [
              -91.272741,
              43.676609
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "037",
      "COUNTYNS": "01581078",
      "AFFGEOID": "0500000US55037",
      "GEOID": "55037",
      "NAME": "Florence",
      "LSAD": "06",
      "ALAND": 1264232435,
      "AWATER": 24111636,
      "County_state": "Florence,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.6833034397318,
              46.01416954199289
            ],
            [
              -88.6842,
              45.982449
            ],
            [
              -88.674604,
              45.982298
            ],
            [
              -88.675821,
              45.7229
            ],
            [
              -88.425302,
              45.722426
            ],
            [
              -88.059246,
              45.713063
            ],
            [
              -88.0582559967311,
              45.7807194372151
            ],
            [
              -88.072091,
              45.780261
            ],
            [
              -88.076375,
              45.781606
            ],
            [
              -88.078361,
              45.784249
            ],
            [
              -88.079764,
              45.78495
            ],
            [
              -88.08859,
              45.784697
            ],
            [
              -88.094047,
              45.785658
            ],
            [
              -88.099616,
              45.790186
            ],
            [
              -88.103247,
              45.791361
            ],
            [
              -88.106351,
              45.797573
            ],
            [
              -88.105518,
              45.798839
            ],
            [
              -88.105355,
              45.800104
            ],
            [
              -88.107506,
              45.802668
            ],
            [
              -88.109506,
              45.803584
            ],
            [
              -88.116024,
              45.804079
            ],
            [
              -88.129461,
              45.809288
            ],
            [
              -88.131834,
              45.811312
            ],
            [
              -88.13611,
              45.819029
            ],
            [
              -88.135067,
              45.821694
            ],
            [
              -88.13364,
              45.823128
            ],
            [
              -88.127808,
              45.827173
            ],
            [
              -88.122947,
              45.829565
            ],
            [
              -88.120723,
              45.832995
            ],
            [
              -88.114267,
              45.837891
            ],
            [
              -88.111726,
              45.839196
            ],
            [
              -88.109089,
              45.839492
            ],
            [
              -88.106622,
              45.841072
            ],
            [
              -88.098326,
              45.850142
            ],
            [
              -88.088825,
              45.85586
            ],
            [
              -88.087419,
              45.857459
            ],
            [
              -88.084985,
              45.862443
            ],
            [
              -88.08259,
              45.864944
            ],
            [
              -88.081641,
              45.865087
            ],
            [
              -88.077534,
              45.863825
            ],
            [
              -88.075146,
              45.864832
            ],
            [
              -88.073134,
              45.871952
            ],
            [
              -88.073944,
              45.875593
            ],
            [
              -88.081781,
              45.880516
            ],
            [
              -88.083965,
              45.881186
            ],
            [
              -88.095841,
              45.880042
            ],
            [
              -88.100218,
              45.881205
            ],
            [
              -88.101814,
              45.883504
            ],
            [
              -88.105447,
              45.896593
            ],
            [
              -88.105981,
              45.897091
            ],
            [
              -88.106136,
              45.900811
            ],
            [
              -88.105677,
              45.904387
            ],
            [
              -88.104576,
              45.906847
            ],
            [
              -88.101973,
              45.91055
            ],
            [
              -88.099172,
              45.912362
            ],
            [
              -88.095354,
              45.913895
            ],
            [
              -88.095409,
              45.915175
            ],
            [
              -88.096496,
              45.917273
            ],
            [
              -88.102908,
              45.921869
            ],
            [
              -88.104686,
              45.922121
            ],
            [
              -88.115346,
              45.922211
            ],
            [
              -88.1168457401249,
              45.9217028628049
            ],
            [
              -88.118507,
              45.92114
            ],
            [
              -88.121864,
              45.92075
            ],
            [
              -88.126382,
              45.921499
            ],
            [
              -88.127594,
              45.922414
            ],
            [
              -88.12743,
              45.923214
            ],
            [
              -88.126122,
              45.924639
            ],
            [
              -88.127428,
              45.926153
            ],
            [
              -88.141001,
              45.930608
            ],
            [
              -88.145928,
              45.933646
            ],
            [
              -88.146419,
              45.934194
            ],
            [
              -88.146352,
              45.935314
            ],
            [
              -88.158704,
              45.939064
            ],
            [
              -88.163105,
              45.939043
            ],
            [
              -88.163959,
              45.93834
            ],
            [
              -88.170096,
              45.93947
            ],
            [
              -88.172628,
              45.941015
            ],
            [
              -88.175532,
              45.944897
            ],
            [
              -88.178008,
              45.947111
            ],
            [
              -88.189789,
              45.952208
            ],
            [
              -88.191991,
              45.95274
            ],
            [
              -88.196316,
              45.953311
            ],
            [
              -88.197627,
              45.953082
            ],
            [
              -88.202116,
              45.949836
            ],
            [
              -88.201852,
              45.945173
            ],
            [
              -88.209585,
              45.94428
            ],
            [
              -88.211158,
              45.944531
            ],
            [
              -88.215025,
              45.946976
            ],
            [
              -88.222167,
              45.948513
            ],
            [
              -88.223773,
              45.948712
            ],
            [
              -88.227988,
              45.947688
            ],
            [
              -88.23314,
              45.947405
            ],
            [
              -88.239672,
              45.948982
            ],
            [
              -88.242518,
              45.950363
            ],
            [
              -88.244452,
              45.952142
            ],
            [
              -88.245752,
              45.954147
            ],
            [
              -88.246579,
              45.956597
            ],
            [
              -88.245937,
              45.958726
            ],
            [
              -88.246307,
              45.962983
            ],
            [
              -88.249117,
              45.963663
            ],
            [
              -88.250133,
              45.963572
            ],
            [
              -88.250133,
              45.963147
            ],
            [
              -88.254816,
              45.963538
            ],
            [
              -88.256455,
              45.962739
            ],
            [
              -88.259343,
              45.959494
            ],
            [
              -88.26839,
              45.957486
            ],
            [
              -88.283335,
              45.955091
            ],
            [
              -88.292381,
              45.951115
            ],
            [
              -88.295264,
              45.951253
            ],
            [
              -88.296968,
              45.953767
            ],
            [
              -88.300965,
              45.956168
            ],
            [
              -88.30952,
              45.959369
            ],
            [
              -88.316894,
              45.960969
            ],
            [
              -88.320531,
              45.959963
            ],
            [
              -88.328333,
              45.964054
            ],
            [
              -88.330137,
              45.965951
            ],
            [
              -88.334628,
              45.968808
            ],
            [
              -88.380183,
              45.991654
            ],
            [
              -88.385234,
              45.990239
            ],
            [
              -88.384318,
              45.988113
            ],
            [
              -88.388847,
              45.982675
            ],
            [
              -88.395308,
              45.980391
            ],
            [
              -88.399046,
              45.980278
            ],
            [
              -88.402848,
              45.981194
            ],
            [
              -88.409864,
              45.979688
            ],
            [
              -88.411077,
              45.979139
            ],
            [
              -88.414849,
              45.975483
            ],
            [
              -88.416914,
              45.975323
            ],
            [
              -88.420356,
              45.976764
            ],
            [
              -88.423044,
              45.978547
            ],
            [
              -88.422322,
              45.98017
            ],
            [
              -88.423437,
              45.98193
            ],
            [
              -88.426125,
              45.984102
            ],
            [
              -88.43406,
              45.986205
            ],
            [
              -88.435798,
              45.988125
            ],
            [
              -88.439733,
              45.990456
            ],
            [
              -88.443078,
              45.990685
            ],
            [
              -88.448751,
              45.98977
            ],
            [
              -88.450325,
              45.990181
            ],
            [
              -88.454261,
              45.993426
            ],
            [
              -88.453868,
              45.996169
            ],
            [
              -88.454361,
              45.997518
            ],
            [
              -88.458658,
              45.999391
            ],
            [
              -88.465542,
              46.000685
            ],
            [
              -88.470855,
              46.001004
            ],
            [
              -88.474695,
              45.99877
            ],
            [
              -88.475152,
              45.996598
            ],
            [
              -88.474036,
              45.994655
            ],
            [
              -88.476002,
              45.992826
            ],
            [
              -88.478984,
              45.991797
            ],
            [
              -88.486755,
              45.990949
            ],
            [
              -88.492495,
              45.992157
            ],
            [
              -88.497417,
              45.995149
            ],
            [
              -88.498108,
              45.99636
            ],
            [
              -88.496897,
              45.998281
            ],
            [
              -88.496898,
              45.999012
            ],
            [
              -88.498765,
              46.000393
            ],
            [
              -88.500133,
              46.000457
            ],
            [
              -88.505946,
              46.013385
            ],
            [
              -88.506205,
              46.017134
            ],
            [
              -88.507188,
              46.0183
            ],
            [
              -88.509516,
              46.019169
            ],
            [
              -88.514601,
              46.019926
            ],
            [
              -88.523131,
              46.019518
            ],
            [
              -88.526673,
              46.020822
            ],
            [
              -88.532414,
              46.021212
            ],
            [
              -88.533825,
              46.020915
            ],
            [
              -88.53353,
              46.019932
            ],
            [
              -88.534876,
              46.018104
            ],
            [
              -88.539011,
              46.014791
            ],
            [
              -88.541078,
              46.013763
            ],
            [
              -88.550756,
              46.012896
            ],
            [
              -88.554987,
              46.014977
            ],
            [
              -88.565485,
              46.015708
            ],
            [
              -88.571553,
              46.013811
            ],
            [
              -88.572995,
              46.011799
            ],
            [
              -88.58067,
              46.006975
            ],
            [
              -88.589,
              46.005077
            ],
            [
              -88.589755,
              46.005602
            ],
            [
              -88.592874,
              46.01159
            ],
            [
              -88.593302,
              46.014447
            ],
            [
              -88.59386,
              46.015132
            ],
            [
              -88.598093,
              46.017623
            ],
            [
              -88.60144,
              46.017599
            ],
            [
              -88.603965,
              46.016181
            ],
            [
              -88.607438,
              46.010991
            ],
            [
              -88.611466,
              46.003332
            ],
            [
              -88.611563,
              45.99881
            ],
            [
              -88.613063,
              45.990627
            ],
            [
              -88.614176,
              45.988775
            ],
            [
              -88.616405,
              45.9877
            ],
            [
              -88.623947,
              45.988633
            ],
            [
              -88.634055,
              45.987999
            ],
            [
              -88.634842,
              45.987565
            ],
            [
              -88.635598,
              45.985119
            ],
            [
              -88.6375,
              45.98496
            ],
            [
              -88.65776,
              45.989287
            ],
            [
              -88.661312,
              45.988819
            ],
            [
              -88.662902,
              45.98873
            ],
            [
              -88.663697,
              45.989084
            ],
            [
              -88.664802,
              45.989835
            ],
            [
              -88.66436,
              45.991337
            ],
            [
              -88.663609,
              45.992397
            ],
            [
              -88.663923,
              45.993242
            ],
            [
              -88.667464,
              45.995048
            ],
            [
              -88.671267,
              45.999026
            ],
            [
              -88.670939,
              45.999957
            ],
            [
              -88.670115,
              45.999957
            ],
            [
              -88.671458,
              46.005104
            ],
            [
              -88.674606,
              46.010567
            ],
            [
              -88.679132,
              46.013538
            ],
            [
              -88.6833034397318,
              46.01416954199289
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "089",
      "COUNTYNS": "01581105",
      "AFFGEOID": "0500000US55089",
      "GEOID": "55089",
      "NAME": "Ozaukee",
      "LSAD": "06",
      "ALAND": 603471584,
      "AWATER": 2287458200,
      "County_state": "Ozaukee,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.063292,
              43.278881
            ],
            [
              -88.063353,
              43.192117
            ],
            [
              -87.994171,
              43.19255
            ],
            [
              -87.959456,
              43.192405
            ],
            [
              -87.924941,
              43.192147
            ],
            [
              -87.924662,
              43.192145
            ],
            [
              -87.8920897893963,
              43.192043607892
            ],
            [
              -87.896286,
              43.197108
            ],
            [
              -87.910087,
              43.235907
            ],
            [
              -87.911787,
              43.250406
            ],
            [
              -87.901847,
              43.284117
            ],
            [
              -87.889207,
              43.307652
            ],
            [
              -87.8825384438553,
              43.3511439024155
            ],
            [
              -87.882392,
              43.352099
            ],
            [
              -87.877448,
              43.369235
            ],
            [
              -87.872504,
              43.380178
            ],
            [
              -87.865048,
              43.39357
            ],
            [
              -87.855608,
              43.405441
            ],
            [
              -87.827319,
              43.434849
            ],
            [
              -87.807799,
              43.461136
            ],
            [
              -87.793239,
              43.492783
            ],
            [
              -87.797336,
              43.510623
            ],
            [
              -87.797608,
              43.52731
            ],
            [
              -87.7943205824754,
              43.543034
            ],
            [
              -88.040528,
              43.542361
            ],
            [
              -88.040152,
              43.367303
            ],
            [
              -88.062426,
              43.367289
            ],
            [
              -88.063292,
              43.278881
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "027",
      "COUNTYNS": "01581073",
      "AFFGEOID": "0500000US55027",
      "GEOID": "55027",
      "NAME": "Dodge",
      "LSAD": "06",
      "ALAND": 2267979574,
      "AWATER": 81401509,
      "County_state": "Dodge,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.009352,
              43.327028
            ],
            [
              -89.009532,
              43.323467
            ],
            [
              -89.009139,
              43.28483
            ],
            [
              -89.00892,
              43.197721
            ],
            [
              -89.008832,
              43.197724
            ],
            [
              -88.970436,
              43.197966
            ],
            [
              -88.759829,
              43.197715
            ],
            [
              -88.693806,
              43.19717
            ],
            [
              -88.654247,
              43.197231
            ],
            [
              -88.535922,
              43.19605
            ],
            [
              -88.535843,
              43.196048
            ],
            [
              -88.417987,
              43.194697
            ],
            [
              -88.419215,
              43.281924
            ],
            [
              -88.418706,
              43.325439
            ],
            [
              -88.418745,
              43.343584
            ],
            [
              -88.418439,
              43.370175
            ],
            [
              -88.401544,
              43.370029
            ],
            [
              -88.400426,
              43.543532
            ],
            [
              -88.401041,
              43.630933
            ],
            [
              -88.644321,
              43.633021
            ],
            [
              -88.644648,
              43.633025
            ],
            [
              -88.715969,
              43.633221
            ],
            [
              -88.758302,
              43.633109
            ],
            [
              -88.76539,
              43.633082
            ],
            [
              -88.886052,
              43.63354
            ],
            [
              -89.006848,
              43.633045
            ],
            [
              -89.006117,
              43.458759
            ],
            [
              -89.004686,
              43.371693
            ],
            [
              -89.00892,
              43.367419
            ],
            [
              -89.009157,
              43.350106
            ],
            [
              -89.009329,
              43.330461
            ],
            [
              -89.009352,
              43.327028
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "093",
      "COUNTYNS": "01581107",
      "AFFGEOID": "0500000US55093",
      "GEOID": "55093",
      "NAME": "Pierce",
      "LSAD": "06",
      "ALAND": 1486126051,
      "AWATER": 46825858,
      "County_state": "Pierce,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.807362,
              44.758909
            ],
            [
              -92.807988,
              44.75147
            ],
            [
              -92.807317,
              44.750364
            ],
            [
              -92.804035,
              44.748433
            ],
            [
              -92.802875,
              44.746847
            ],
            [
              -92.80257940581069,
              44.7457971094334
            ],
            [
              -92.802402,
              44.745167
            ],
            [
              -92.787906,
              44.737432
            ],
            [
              -92.766054,
              44.729604
            ],
            [
              -92.75699,
              44.723829
            ],
            [
              -92.7542,
              44.722767
            ],
            [
              -92.7502,
              44.72212
            ],
            [
              -92.737259,
              44.717155
            ],
            [
              -92.732042,
              44.7136706398321
            ],
            [
              -92.713198,
              44.701085
            ],
            [
              -92.700948,
              44.693751
            ],
            [
              -92.696491,
              44.689436
            ],
            [
              -92.686511,
              44.682096
            ],
            [
              -92.664699,
              44.66338
            ],
            [
              -92.660988,
              44.660884
            ],
            [
              -92.655807,
              44.65804
            ],
            [
              -92.632105,
              44.649027
            ],
            [
              -92.621733,
              44.638983
            ],
            [
              -92.619779,
              44.634195
            ],
            [
              -92.619774,
              44.629214
            ],
            [
              -92.622571,
              44.623518
            ],
            [
              -92.623348,
              44.620713
            ],
            [
              -92.623163,
              44.618224
            ],
            [
              -92.621456,
              44.615017
            ],
            [
              -92.618025,
              44.61287
            ],
            [
              -92.614569,
              44.61173
            ],
            [
              -92.607141,
              44.612433
            ],
            [
              -92.601516,
              44.612052
            ],
            [
              -92.590467,
              44.605936
            ],
            [
              -92.588797,
              44.601698
            ],
            [
              -92.586216,
              44.600088
            ],
            [
              -92.584711,
              44.599861
            ],
            [
              -92.581591,
              44.600863
            ],
            [
              -92.57885,
              44.603939
            ],
            [
              -92.577148,
              44.605054
            ],
            [
              -92.572943,
              44.604649
            ],
            [
              -92.569434,
              44.603539
            ],
            [
              -92.567226,
              44.60177
            ],
            [
              -92.560796,
              44.594956
            ],
            [
              -92.549777,
              44.58113
            ],
            [
              -92.54928,
              44.577704
            ],
            [
              -92.549685,
              44.576
            ],
            [
              -92.551182,
              44.573449
            ],
            [
              -92.55151,
              44.571607
            ],
            [
              -92.549957,
              44.568988
            ],
            [
              -92.54806,
              44.567792
            ],
            [
              -92.544346,
              44.566986
            ],
            [
              -92.540551,
              44.567258
            ],
            [
              -92.527337,
              44.573554
            ],
            [
              -92.520878,
              44.5752
            ],
            [
              -92.518358,
              44.575183
            ],
            [
              -92.512564,
              44.571801
            ],
            [
              -92.508759,
              44.570325
            ],
            [
              -92.493808,
              44.566063
            ],
            [
              -92.490472,
              44.566205
            ],
            [
              -92.48474,
              44.568067
            ],
            [
              -92.481001,
              44.568276
            ],
            [
              -92.470209,
              44.565036
            ],
            [
              -92.455105,
              44.561886
            ],
            [
              -92.440745,
              44.562833
            ],
            [
              -92.433256,
              44.5655
            ],
            [
              -92.431101,
              44.565786
            ],
            [
              -92.425774,
              44.564602
            ],
            [
              -92.420702,
              44.562041
            ],
            [
              -92.415089,
              44.560359
            ],
            [
              -92.399281,
              44.558292
            ],
            [
              -92.38904,
              44.557697
            ],
            [
              -92.368298,
              44.559182
            ],
            [
              -92.361518,
              44.558935
            ],
            [
              -92.347567,
              44.557149
            ],
            [
              -92.336114,
              44.554004
            ],
            [
              -92.329013,
              44.550895
            ],
            [
              -92.319938,
              44.54494
            ],
            [
              -92.317357,
              44.542512
            ],
            [
              -92.3162276738383,
              44.5409661360087
            ],
            [
              -92.1353,
              44.53958
            ],
            [
              -92.135202,
              44.684374
            ],
            [
              -92.136348,
              44.857848
            ],
            [
              -92.232663,
              44.857852
            ],
            [
              -92.236479,
              44.8577
            ],
            [
              -92.251066,
              44.857993
            ],
            [
              -92.374917,
              44.862301
            ],
            [
              -92.497014,
              44.862466
            ],
            [
              -92.65991,
              44.862614
            ],
            [
              -92.76909047947728,
              44.8619968322789
            ],
            [
              -92.768574,
              44.854368
            ],
            [
              -92.765278,
              44.84107
            ],
            [
              -92.765278,
              44.837186
            ],
            [
              -92.766102,
              44.834966
            ],
            [
              -92.769367,
              44.8318
            ],
            [
              -92.772266,
              44.828046
            ],
            [
              -92.772663,
              44.826337
            ],
            [
              -92.771902,
              44.823067
            ],
            [
              -92.772663,
              44.821424
            ],
            [
              -92.78043,
              44.812589
            ],
            [
              -92.781498,
              44.809408
            ],
            [
              -92.782963,
              44.798131
            ],
            [
              -92.785206,
              44.792303
            ],
            [
              -92.788776,
              44.787794
            ],
            [
              -92.796039,
              44.782056
            ],
            [
              -92.800313,
              44.777379
            ],
            [
              -92.805287,
              44.768361
            ],
            [
              -92.807362,
              44.758909
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "095",
      "COUNTYNS": "01581108",
      "AFFGEOID": "0500000US55095",
      "GEOID": "55095",
      "NAME": "Polk",
      "LSAD": "06",
      "ALAND": 2367400716,
      "AWATER": 109704318,
      "County_state": "Polk,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.888114,
              45.628377
            ],
            [
              -92.888035,
              45.624959
            ],
            [
              -92.886669,
              45.61976
            ],
            [
              -92.88297,
              45.613738
            ],
            [
              -92.882529,
              45.610216
            ],
            [
              -92.8849,
              45.605001
            ],
            [
              -92.886442,
              45.598679
            ],
            [
              -92.886421,
              45.594881
            ],
            [
              -92.883277,
              45.589831
            ],
            [
              -92.884954,
              45.578818
            ],
            [
              -92.883749,
              45.575483
            ],
            [
              -92.881136,
              45.573409
            ],
            [
              -92.871082,
              45.567581
            ],
            [
              -92.846447,
              45.566515
            ],
            [
              -92.843783,
              45.566135
            ],
            [
              -92.834156,
              45.563096
            ],
            [
              -92.823309,
              45.560934
            ],
            [
              -92.812083,
              45.561122
            ],
            [
              -92.801503,
              45.562854
            ],
            [
              -92.790143,
              45.566915
            ],
            [
              -92.785741,
              45.567888
            ],
            [
              -92.775988,
              45.568478
            ],
            [
              -92.773412,
              45.568235
            ],
            [
              -92.770223,
              45.566939
            ],
            [
              -92.764574,
              45.563592
            ],
            [
              -92.756906,
              45.557499
            ],
            [
              -92.745591,
              45.553016
            ],
            [
              -92.726082,
              45.541112
            ],
            [
              -92.724762,
              45.538617
            ],
            [
              -92.72465,
              45.536744
            ],
            [
              -92.728023,
              45.525652
            ],
            [
              -92.727744,
              45.518811
            ],
            [
              -92.726677,
              45.514462
            ],
            [
              -92.724337,
              45.512223
            ],
            [
              -92.715814,
              45.506676
            ],
            [
              -92.71189,
              45.503281
            ],
            [
              -92.702224,
              45.493046
            ],
            [
              -92.695212,
              45.482882
            ],
            [
              -92.691619,
              45.476273
            ],
            [
              -92.686793,
              45.472271
            ],
            [
              -92.680234,
              45.464344
            ],
            [
              -92.677219,
              45.462864
            ],
            [
              -92.661131,
              45.458278
            ],
            [
              -92.653549,
              45.455346
            ],
            [
              -92.652698,
              45.454527
            ],
            [
              -92.646602,
              45.441635
            ],
            [
              -92.646768,
              45.437929
            ],
            [
              -92.649152,
              45.429618
            ],
            [
              -92.650269,
              45.419168
            ],
            [
              -92.649467,
              45.416408
            ],
            [
              -92.646943,
              45.414265
            ],
            [
              -92.646676,
              45.413227
            ],
            [
              -92.648157,
              45.407423
            ],
            [
              -92.65057,
              45.403308
            ],
            [
              -92.650422,
              45.398507
            ],
            [
              -92.658486,
              45.396058
            ],
            [
              -92.664102,
              45.393309
            ],
            [
              -92.669505,
              45.389111
            ],
            [
              -92.6746941140289,
              45.382865411306895
            ],
            [
              -92.676961,
              45.380137
            ],
            [
              -92.678756,
              45.376201
            ],
            [
              -92.678223,
              45.373604
            ],
            [
              -92.679193,
              45.37271
            ],
            [
              -92.696499,
              45.363529
            ],
            [
              -92.70272,
              45.358472
            ],
            [
              -92.703705,
              45.35633
            ],
            [
              -92.704054,
              45.35366
            ],
            [
              -92.699524,
              45.342421
            ],
            [
              -92.69892,
              45.339364
            ],
            [
              -92.698967,
              45.336374
            ],
            [
              -92.699956,
              45.333716
            ],
            [
              -92.704794,
              45.326526
            ],
            [
              -92.709968,
              45.321302
            ],
            [
              -92.727737,
              45.309288
            ],
            [
              -92.732594,
              45.304224
            ],
            [
              -92.737122,
              45.300459
            ],
            [
              -92.74557465605889,
              45.295843579494395
            ],
            [
              -92.750819,
              45.29298
            ],
            [
              -92.75871,
              45.290965
            ],
            [
              -92.761013,
              45.289028
            ],
            [
              -92.761868,
              45.287013
            ],
            [
              -92.761868,
              45.284938
            ],
            [
              -92.760615,
              45.278827
            ],
            [
              -92.758022,
              45.274822
            ],
            [
              -92.752666,
              45.269565
            ],
            [
              -92.751659,
              45.26591
            ],
            [
              -92.751709,
              45.261666
            ],
            [
              -92.755199,
              45.256733
            ],
            [
              -92.758907,
              45.253407
            ],
            [
              -92.760249,
              45.2496
            ],
            [
              -92.757503,
              45.238308
            ],
            [
              -92.757456,
              45.230526
            ],
            [
              -92.755732,
              45.225949
            ],
            [
              -92.753931,
              45.222905
            ],
            [
              -92.752192,
              45.221051
            ],
            [
              -92.751708,
              45.218666
            ],
            [
              -92.754008,
              45.212766
            ],
            [
              -92.758008,
              45.209566
            ],
            [
              -92.651543,
              45.21014
            ],
            [
              -92.283932,
              45.209279
            ],
            [
              -92.156465,
              45.209554
            ],
            [
              -92.156757,
              45.294911
            ],
            [
              -92.156152,
              45.467361
            ],
            [
              -92.154888,
              45.639742
            ],
            [
              -92.154443,
              45.725616
            ],
            [
              -92.283847,
              45.725932
            ],
            [
              -92.528198,
              45.72868
            ],
            [
              -92.529111,
              45.642171
            ],
            [
              -92.887067,
              45.644148
            ],
            [
              -92.887929,
              45.639006
            ],
            [
              -92.886963,
              45.636777
            ],
            [
              -92.886827,
              45.633403
            ],
            [
              -92.888114,
              45.628377
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "109",
      "COUNTYNS": "01581833",
      "AFFGEOID": "0500000US55109",
      "GEOID": "55109",
      "NAME": "St. Croix",
      "LSAD": "06",
      "ALAND": 1871226040,
      "AWATER": 34193433,
      "County_state": "St. Croix,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.802911,
              45.065403
            ],
            [
              -92.803079,
              45.060978
            ],
            [
              -92.802056,
              45.057423
            ],
            [
              -92.797081,
              45.050648
            ],
            [
              -92.793282,
              45.047178
            ],
            [
              -92.78791,
              45.043516
            ],
            [
              -92.778815,
              45.039327
            ],
            [
              -92.770362,
              45.033803
            ],
            [
              -92.764604,
              45.028767
            ],
            [
              -92.76206,
              45.02432
            ],
            [
              -92.761904,
              45.022467
            ],
            [
              -92.762533,
              45.020551
            ],
            [
              -92.768118,
              45.009115
            ],
            [
              -92.771231,
              45.001378
            ],
            [
              -92.770834,
              44.994131
            ],
            [
              -92.769049,
              44.988195
            ],
            [
              -92.770346,
              44.983327
            ],
            [
              -92.770304,
              44.978967
            ],
            [
              -92.769445,
              44.97215
            ],
            [
              -92.768545,
              44.969839
            ],
            [
              -92.767218,
              44.968084
            ],
            [
              -92.760701,
              44.964979
            ],
            [
              -92.754603,
              44.955767
            ],
            [
              -92.750802,
              44.941567
            ],
            [
              -92.750645,
              44.937299
            ],
            [
              -92.75559183382249,
              44.9186302846844
            ],
            [
              -92.757557,
              44.911214
            ],
            [
              -92.758701,
              44.908979
            ],
            [
              -92.759556,
              44.907857
            ],
            [
              -92.761341,
              44.906904
            ],
            [
              -92.773103,
              44.901367
            ],
            [
              -92.774022,
              44.900083
            ],
            [
              -92.774571,
              44.898084
            ],
            [
              -92.774907,
              44.892797
            ],
            [
              -92.773946,
              44.889997
            ],
            [
              -92.769603,
              44.882967
            ],
            [
              -92.764133,
              44.875905
            ],
            [
              -92.763402,
              44.874167
            ],
            [
              -92.763706,
              44.872129
            ],
            [
              -92.767102,
              44.866767
            ],
            [
              -92.769102,
              44.862167
            ],
            [
              -92.7690926333535,
              44.862028646825095
            ],
            [
              -92.76909047947728,
              44.8619968322789
            ],
            [
              -92.65991,
              44.862614
            ],
            [
              -92.497014,
              44.862466
            ],
            [
              -92.374917,
              44.862301
            ],
            [
              -92.251066,
              44.857993
            ],
            [
              -92.236479,
              44.8577
            ],
            [
              -92.232663,
              44.857852
            ],
            [
              -92.136348,
              44.857848
            ],
            [
              -92.136113,
              45.121432
            ],
            [
              -92.157072,
              45.121608
            ],
            [
              -92.156465,
              45.209554
            ],
            [
              -92.283932,
              45.209279
            ],
            [
              -92.651543,
              45.21014
            ],
            [
              -92.758008,
              45.209566
            ],
            [
              -92.762108,
              45.207166
            ],
            [
              -92.763908,
              45.204866
            ],
            [
              -92.766932,
              45.195111
            ],
            [
              -92.767408,
              45.190166
            ],
            [
              -92.766808,
              45.185466
            ],
            [
              -92.764872,
              45.182812
            ],
            [
              -92.752404,
              45.173916
            ],
            [
              -92.752542,
              45.171772
            ],
            [
              -92.756907,
              45.165166
            ],
            [
              -92.757775,
              45.160519
            ],
            [
              -92.757707,
              45.155466
            ],
            [
              -92.756807,
              45.151866
            ],
            [
              -92.749427,
              45.138117
            ],
            [
              -92.745694,
              45.123112
            ],
            [
              -92.7456477664165,
              45.1230586702544
            ],
            [
              -92.742925,
              45.119918
            ],
            [
              -92.740611,
              45.118454
            ],
            [
              -92.739528,
              45.116515
            ],
            [
              -92.739584,
              45.115598
            ],
            [
              -92.740509,
              45.113396
            ],
            [
              -92.744938,
              45.108309
            ],
            [
              -92.746749,
              45.107051
            ],
            [
              -92.754387,
              45.103146
            ],
            [
              -92.765602,
              45.09573
            ],
            [
              -92.77401,
              45.089138
            ],
            [
              -92.791528,
              45.079647
            ],
            [
              -92.79223863282759,
              45.0788718057646
            ],
            [
              -92.79223936985119,
              45.078871001782
            ],
            [
              -92.800851,
              45.069477
            ],
            [
              -92.802163,
              45.067555
            ],
            [
              -92.802911,
              45.065403
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "021",
      "COUNTYNS": "01581070",
      "AFFGEOID": "0500000US55021",
      "GEOID": "55021",
      "NAME": "Columbia",
      "LSAD": "06",
      "ALAND": 1982754434,
      "AWATER": 78836820,
      "County_state": "Columbia,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.784901,
              43.641051
            ],
            [
              -89.785809,
              43.641049
            ],
            [
              -89.774454,
              43.612329
            ],
            [
              -89.761166,
              43.603606
            ],
            [
              -89.758511,
              43.604111
            ],
            [
              -89.717335,
              43.596895
            ],
            [
              -89.732959,
              43.580977
            ],
            [
              -89.732238,
              43.571826
            ],
            [
              -89.707216,
              43.566759
            ],
            [
              -89.673239,
              43.56942
            ],
            [
              -89.642209,
              43.563179
            ],
            [
              -89.617719,
              43.565353
            ],
            [
              -89.599357,
              43.558041
            ],
            [
              -89.60073,
              43.380666
            ],
            [
              -89.617394,
              43.370399
            ],
            [
              -89.655755,
              43.359821
            ],
            [
              -89.677613,
              43.361197
            ],
            [
              -89.703832,
              43.330296
            ],
            [
              -89.71971,
              43.319745
            ],
            [
              -89.727171,
              43.301305
            ],
            [
              -89.720463,
              43.293084
            ],
            [
              -89.600855,
              43.293132
            ],
            [
              -89.581169,
              43.29326
            ],
            [
              -89.48163,
              43.294094
            ],
            [
              -89.363159,
              43.294036
            ],
            [
              -89.363085,
              43.281305
            ],
            [
              -89.245405,
              43.282093
            ],
            [
              -89.009139,
              43.28483
            ],
            [
              -89.009532,
              43.323467
            ],
            [
              -89.009352,
              43.327028
            ],
            [
              -89.009329,
              43.330461
            ],
            [
              -89.009157,
              43.350106
            ],
            [
              -89.00892,
              43.367419
            ],
            [
              -89.004686,
              43.371693
            ],
            [
              -89.006117,
              43.458759
            ],
            [
              -89.006848,
              43.633045
            ],
            [
              -89.12717,
              43.631724
            ],
            [
              -89.126921,
              43.64367
            ],
            [
              -89.245437,
              43.643083
            ],
            [
              -89.483561,
              43.642575
            ],
            [
              -89.599547,
              43.642636
            ],
            [
              -89.718733,
              43.643154
            ],
            [
              -89.749065,
              43.642209
            ],
            [
              -89.784901,
              43.641051
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "129",
      "COUNTYNS": "01581124",
      "AFFGEOID": "0500000US55129",
      "GEOID": "55129",
      "NAME": "Washburn",
      "LSAD": "06",
      "ALAND": 2064513622,
      "AWATER": 145566159,
      "County_state": "Washburn,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.049636,
              46.157597
            ],
            [
              -92.050647,
              45.983921
            ],
            [
              -92.033404,
              45.98387
            ],
            [
              -92.031966,
              45.815328
            ],
            [
              -92.031409,
              45.725465
            ],
            [
              -92.031417,
              45.639928
            ],
            [
              -91.540294,
              45.637605
            ],
            [
              -91.541806,
              45.982586
            ],
            [
              -91.551804,
              45.98334
            ],
            [
              -91.550951,
              46.04111
            ],
            [
              -91.551282,
              46.157045
            ],
            [
              -91.561684,
              46.157846
            ],
            [
              -92.049636,
              46.157597
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "091",
      "COUNTYNS": "01581106",
      "AFFGEOID": "0500000US55091",
      "GEOID": "55091",
      "NAME": "Pepin",
      "LSAD": "06",
      "ALAND": 600892978,
      "AWATER": 43242987,
      "County_state": "Pepin,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.3162276738383,
              44.5409661360087
            ],
            [
              -92.314071,
              44.538014
            ],
            [
              -92.310827,
              44.528756
            ],
            [
              -92.307957,
              44.524475
            ],
            [
              -92.303527,
              44.519822
            ],
            [
              -92.303046,
              44.518646
            ],
            [
              -92.302466,
              44.516487
            ],
            [
              -92.302961,
              44.503601
            ],
            [
              -92.302215,
              44.500298
            ],
            [
              -92.297122,
              44.492732
            ],
            [
              -92.291005,
              44.485464
            ],
            [
              -92.282364,
              44.477707
            ],
            [
              -92.276784,
              44.473649
            ],
            [
              -92.262476,
              44.465149
            ],
            [
              -92.249071,
              44.459524
            ],
            [
              -92.244884,
              44.456842
            ],
            [
              -92.24201,
              44.454254
            ],
            [
              -92.237325,
              44.449417
            ],
            [
              -92.232472,
              44.445434
            ],
            [
              -92.221083,
              44.440386
            ],
            [
              -92.215163,
              44.438503
            ],
            [
              -92.195378,
              44.433792
            ],
            [
              -92.17028,
              44.428598
            ],
            [
              -92.162454,
              44.427208
            ],
            [
              -92.139569,
              44.424673
            ],
            [
              -92.124513,
              44.422115
            ],
            [
              -92.121106,
              44.420572
            ],
            [
              -92.115296,
              44.416056
            ],
            [
              -92.111085,
              44.413948
            ],
            [
              -92.097415,
              44.411464
            ],
            [
              -92.087241,
              44.408848
            ],
            [
              -92.08363983219859,
              44.4071887773643
            ],
            [
              -92.081288,
              44.429756
            ],
            [
              -92.059317,
              44.466289
            ],
            [
              -92.045473,
              44.509976
            ],
            [
              -92.047753,
              44.527016
            ],
            [
              -92.038838,
              44.546969
            ],
            [
              -92.039585,
              44.56421
            ],
            [
              -92.02918,
              44.586292
            ],
            [
              -92.022788,
              44.590519
            ],
            [
              -92.00092,
              44.596696
            ],
            [
              -91.892978,
              44.596678
            ],
            [
              -91.650248,
              44.596649
            ],
            [
              -91.650361,
              44.683633
            ],
            [
              -92.135202,
              44.684374
            ],
            [
              -92.1353,
              44.53958
            ],
            [
              -92.3162276738383,
              44.5409661360087
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "015",
      "COUNTYNS": "01581067",
      "AFFGEOID": "0500000US55015",
      "GEOID": "55015",
      "NAME": "Calumet",
      "LSAD": "06",
      "ALAND": 824226133,
      "AWATER": 204199001,
      "County_state": "Calumet,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.404429,
              44.127685
            ],
            [
              -88.404598,
              44.098344
            ],
            [
              -88.404109,
              44.051286
            ],
            [
              -88.40351,
              44.001286
            ],
            [
              -88.403309,
              43.979987
            ],
            [
              -88.404187,
              43.9382
            ],
            [
              -88.285988,
              43.937683
            ],
            [
              -88.161654,
              43.937683
            ],
            [
              -88.162274,
              43.891511
            ],
            [
              -88.041794,
              43.891692
            ],
            [
              -88.041829,
              43.906496
            ],
            [
              -88.041775,
              43.921165
            ],
            [
              -88.042138,
              43.97917
            ],
            [
              -88.042138,
              43.979181
            ],
            [
              -88.04365,
              44.153497
            ],
            [
              -88.04324,
              44.241018
            ],
            [
              -88.192611,
              44.241947
            ],
            [
              -88.270244,
              44.243004
            ],
            [
              -88.274422,
              44.242952
            ],
            [
              -88.288856,
              44.243177
            ],
            [
              -88.354035,
              44.243854
            ],
            [
              -88.40407,
              44.244102
            ],
            [
              -88.404537,
              44.193699
            ],
            [
              -88.404429,
              44.127685
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "087",
      "COUNTYNS": "01581104",
      "AFFGEOID": "0500000US55087",
      "GEOID": "55087",
      "NAME": "Outagamie",
      "LSAD": "06",
      "ALAND": 1651321050,
      "AWATER": 18205949,
      "County_state": "Outagamie,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.738565,
              44.316038
            ],
            [
              -88.73977,
              44.243303
            ],
            [
              -88.645189,
              44.243597
            ],
            [
              -88.557424,
              44.243709
            ],
            [
              -88.524791,
              44.244028
            ],
            [
              -88.439099,
              44.244286
            ],
            [
              -88.436532,
              44.244308
            ],
            [
              -88.40407,
              44.244102
            ],
            [
              -88.354035,
              44.243854
            ],
            [
              -88.288856,
              44.243177
            ],
            [
              -88.274422,
              44.242952
            ],
            [
              -88.270244,
              44.243004
            ],
            [
              -88.192611,
              44.241947
            ],
            [
              -88.191116,
              44.305898
            ],
            [
              -88.192603,
              44.316088
            ],
            [
              -88.192489,
              44.322852
            ],
            [
              -88.19229,
              44.328157
            ],
            [
              -88.191278,
              44.328155
            ],
            [
              -88.191494,
              44.347253
            ],
            [
              -88.191432,
              44.391586
            ],
            [
              -88.190465,
              44.586799
            ],
            [
              -88.245204,
              44.584746
            ],
            [
              -88.488756,
              44.58951
            ],
            [
              -88.606083,
              44.590515
            ],
            [
              -88.736802,
              44.590605
            ],
            [
              -88.736732,
              44.416155
            ],
            [
              -88.736834,
              44.373558
            ],
            [
              -88.738565,
              44.316038
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "011",
      "COUNTYNS": "01581065",
      "AFFGEOID": "0500000US55011",
      "GEOID": "55011",
      "NAME": "Buffalo",
      "LSAD": "06",
      "ALAND": 1739529649,
      "AWATER": 98310686,
      "County_state": "Buffalo,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.081288,
              44.429756
            ],
            [
              -92.08363983219859,
              44.4071887773643
            ],
            [
              -92.078605,
              44.404869
            ],
            [
              -92.072267,
              44.404017
            ],
            [
              -92.061637,
              44.404124
            ],
            [
              -92.056486,
              44.402729
            ],
            [
              -92.053549,
              44.401375
            ],
            [
              -92.046285,
              44.394398
            ],
            [
              -92.038147,
              44.388731
            ],
            [
              -92.019313,
              44.381217
            ],
            [
              -92.008589,
              44.379626
            ],
            [
              -92.006179,
              44.378825
            ],
            [
              -92.002838,
              44.377118
            ],
            [
              -92.001198663636,
              44.3757981901027
            ],
            [
              -92.000165,
              44.374966
            ],
            [
              -91.993984,
              44.3718
            ],
            [
              -91.987289,
              44.369119
            ],
            [
              -91.983974,
              44.368448
            ],
            [
              -91.978574,
              44.368372
            ],
            [
              -91.974922,
              44.367516
            ],
            [
              -91.970266,
              44.365842
            ],
            [
              -91.97022850758609,
              44.3658210208965
            ],
            [
              -91.9636,
              44.362112
            ],
            [
              -91.959523,
              44.359404
            ],
            [
              -91.95282,
              44.352982
            ],
            [
              -91.949599,
              44.348796
            ],
            [
              -91.941311,
              44.340978
            ],
            [
              -91.928224,
              44.335473
            ],
            [
              -91.92559,
              44.333548
            ],
            [
              -91.918625,
              44.322671
            ],
            [
              -91.916191,
              44.318094
            ],
            [
              -91.913534,
              44.311392
            ],
            [
              -91.913574,
              44.310392
            ],
            [
              -91.91436,
              44.30823
            ],
            [
              -91.921028,
              44.301069
            ],
            [
              -91.924102,
              44.297095
            ],
            [
              -91.924975,
              44.294819
            ],
            [
              -91.924613,
              44.291815
            ],
            [
              -91.922205,
              44.287811
            ],
            [
              -91.920282,
              44.286496
            ],
            [
              -91.905789,
              44.281614
            ],
            [
              -91.898697,
              44.277172
            ],
            [
              -91.896388,
              44.27469
            ],
            [
              -91.895652,
              44.273008
            ],
            [
              -91.89676,
              44.265447
            ],
            [
              -91.896008,
              44.262871
            ],
            [
              -91.889132,
              44.25606
            ],
            [
              -91.887824,
              44.254171
            ],
            [
              -91.88704,
              44.251772
            ],
            [
              -91.887905,
              44.246398
            ],
            [
              -91.892963,
              44.235149
            ],
            [
              -91.892698,
              44.231105
            ],
            [
              -91.88979,
              44.226286
            ],
            [
              -91.880265,
              44.216555
            ],
            [
              -91.877429,
              44.212921
            ],
            [
              -91.876356,
              44.209575
            ],
            [
              -91.876056,
              44.202728
            ],
            [
              -91.875158,
              44.200575
            ],
            [
              -91.872369,
              44.199167
            ],
            [
              -91.864387,
              44.196574
            ],
            [
              -91.8583929427025,
              44.1930031501986
            ],
            [
              -91.844754,
              44.184878
            ],
            [
              -91.832479,
              44.180308
            ],
            [
              -91.829167,
              44.17835
            ],
            [
              -91.817302,
              44.164235
            ],
            [
              -91.808064,
              44.159262
            ],
            [
              -91.796669,
              44.154335
            ],
            [
              -91.774486,
              44.147539
            ],
            [
              -91.768574,
              44.143508
            ],
            [
              -91.756719,
              44.136804
            ],
            [
              -91.751747,
              44.134786
            ],
            [
              -91.730648,
              44.1329
            ],
            [
              -91.721552,
              44.130342
            ],
            [
              -91.71972299802539,
              44.1292326786394
            ],
            [
              -91.719097,
              44.128853
            ],
            [
              -91.710597,
              44.12048
            ],
            [
              -91.709476,
              44.117565
            ],
            [
              -91.708082,
              44.110929
            ],
            [
              -91.708207,
              44.105186
            ],
            [
              -91.707491,
              44.103906
            ],
            [
              -91.69531,
              44.09857
            ],
            [
              -91.691281,
              44.097858
            ],
            [
              -91.685748,
              44.098419
            ],
            [
              -91.68153,
              44.0974
            ],
            [
              -91.667006,
              44.086964
            ],
            [
              -91.665263,
              44.085041
            ],
            [
              -91.663442,
              44.08091
            ],
            [
              -91.659511,
              44.074203
            ],
            [
              -91.657,
              44.071409
            ],
            [
              -91.652247,
              44.068634
            ],
            [
              -91.647873,
              44.064109
            ],
            [
              -91.644717,
              44.062782
            ],
            [
              -91.6434,
              44.062711
            ],
            [
              -91.640535,
              44.063679
            ],
            [
              -91.638115,
              44.063285
            ],
            [
              -91.633365,
              44.060364
            ],
            [
              -91.6279,
              44.055807
            ],
            [
              -91.623784,
              44.054106
            ],
            [
              -91.615375,
              44.051598
            ],
            [
              -91.610487,
              44.04931
            ],
            [
              -91.607339,
              44.047357
            ],
            [
              -91.60355,
              44.043681
            ],
            [
              -91.597617,
              44.034965
            ],
            [
              -91.59207,
              44.031372
            ],
            [
              -91.582604,
              44.027381
            ],
            [
              -91.580019,
              44.026925
            ],
            [
              -91.573283,
              44.026901
            ],
            [
              -91.559004,
              44.025315
            ],
            [
              -91.571157,
              44.037828
            ],
            [
              -91.558276,
              44.062437
            ],
            [
              -91.534245,
              44.096319
            ],
            [
              -91.548367,
              44.107478
            ],
            [
              -91.548752,
              44.12439
            ],
            [
              -91.565664,
              44.14853
            ],
            [
              -91.590792,
              44.155392
            ],
            [
              -91.612971,
              44.172157
            ],
            [
              -91.597223,
              44.187723
            ],
            [
              -91.608647,
              44.200779
            ],
            [
              -91.600956,
              44.211048
            ],
            [
              -91.574409,
              44.222488
            ],
            [
              -91.55712,
              44.224291
            ],
            [
              -91.534049,
              44.241002
            ],
            [
              -91.529741,
              44.246777
            ],
            [
              -91.529101,
              44.596197
            ],
            [
              -91.650248,
              44.596649
            ],
            [
              -91.892978,
              44.596678
            ],
            [
              -92.00092,
              44.596696
            ],
            [
              -92.022788,
              44.590519
            ],
            [
              -92.02918,
              44.586292
            ],
            [
              -92.039585,
              44.56421
            ],
            [
              -92.038838,
              44.546969
            ],
            [
              -92.047753,
              44.527016
            ],
            [
              -92.045473,
              44.509976
            ],
            [
              -92.059317,
              44.466289
            ],
            [
              -92.081288,
              44.429756
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "005",
      "COUNTYNS": "01581062",
      "AFFGEOID": "0500000US55005",
      "GEOID": "55005",
      "NAME": "Barron",
      "LSAD": "06",
      "ALAND": 2234461780,
      "AWATER": 70014121,
      "County_state": "Barron,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.156757,
              45.294911
            ],
            [
              -92.156465,
              45.209554
            ],
            [
              -92.042458,
              45.20868
            ],
            [
              -92.033674,
              45.208851
            ],
            [
              -91.665647,
              45.207991
            ],
            [
              -91.559563,
              45.206873
            ],
            [
              -91.557933,
              45.206834
            ],
            [
              -91.555316,
              45.206892
            ],
            [
              -91.542234,
              45.206538
            ],
            [
              -91.542192,
              45.210073
            ],
            [
              -91.541322,
              45.29222
            ],
            [
              -91.540294,
              45.637605
            ],
            [
              -92.031417,
              45.639928
            ],
            [
              -92.154888,
              45.639742
            ],
            [
              -92.156152,
              45.467361
            ],
            [
              -92.156757,
              45.294911
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "061",
      "COUNTYNS": "01581090",
      "AFFGEOID": "0500000US55061",
      "GEOID": "55061",
      "NAME": "Kewaunee",
      "LSAD": "06",
      "ALAND": 886863420,
      "AWATER": 1921424713,
      "County_state": "Kewaunee,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.765316,
              44.414503
            ],
            [
              -87.766026,
              44.327184
            ],
            [
              -87.58336,
              44.327563
            ],
            [
              -87.54294984058659,
              44.3275124998493
            ],
            [
              -87.533583,
              44.351111
            ],
            [
              -87.521047,
              44.367526
            ],
            [
              -87.517597,
              44.375696
            ],
            [
              -87.517965,
              44.394356
            ],
            [
              -87.511635,
              44.414043
            ],
            [
              -87.506362,
              44.423804
            ],
            [
              -87.498662,
              44.460686
            ],
            [
              -87.490024,
              44.477224
            ],
            [
              -87.483914,
              44.504425
            ],
            [
              -87.483696,
              44.511354
            ],
            [
              -87.468093,
              44.551925
            ],
            [
              -87.446963,
              44.586274
            ],
            [
              -87.437493,
              44.605071
            ],
            [
              -87.418028,
              44.62087
            ],
            [
              -87.40541,
              44.627191
            ],
            [
              -87.401629,
              44.631191
            ],
            [
              -87.393521,
              44.640655
            ],
            [
              -87.37325833223939,
              44.675540674647195
            ],
            [
              -87.7360584417798,
              44.6770254546693
            ],
            [
              -87.748409,
              44.667122
            ],
            [
              -87.750899,
              44.656192
            ],
            [
              -87.756031,
              44.649129
            ],
            [
              -87.762376099947,
              44.644501238507296
            ],
            [
              -87.765316,
              44.414503
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "051",
      "COUNTYNS": "01581085",
      "AFFGEOID": "0500000US55051",
      "GEOID": "55051",
      "NAME": "Iron",
      "LSAD": "06",
      "ALAND": 1963699135,
      "AWATER": 416924547,
      "County_state": "Iron,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.5495962766133,
              46.58413846414
            ],
            [
              -90.552227,
              46.240851
            ],
            [
              -90.425978,
              46.24045
            ],
            [
              -90.426274,
              46.15405
            ],
            [
              -90.303072,
              46.154849
            ],
            [
              -90.303669,
              46.096649
            ],
            [
              -90.300268,
              46.067949
            ],
            [
              -90.302063,
              45.981349
            ],
            [
              -90.043958,
              45.981948
            ],
            [
              -89.928837,
              45.981967
            ],
            [
              -89.928549,
              46.069317
            ],
            [
              -89.92915763295049,
              46.2997498928327
            ],
            [
              -90.120489,
              46.336852
            ],
            [
              -90.121248,
              46.337217
            ],
            [
              -90.12138,
              46.338131
            ],
            [
              -90.121084,
              46.338656
            ],
            [
              -90.119468,
              46.3397
            ],
            [
              -90.118791,
              46.342253
            ],
            [
              -90.119572,
              46.34418
            ],
            [
              -90.120198,
              46.345066
            ],
            [
              -90.120614,
              46.34642
            ],
            [
              -90.119729,
              46.348504
            ],
            [
              -90.117466,
              46.349487
            ],
            [
              -90.116741,
              46.350652
            ],
            [
              -90.116844,
              46.355153
            ],
            [
              -90.118827,
              46.359241
            ],
            [
              -90.119691,
              46.359755
            ],
            [
              -90.119757,
              46.359748
            ],
            [
              -90.120973,
              46.35972
            ],
            [
              -90.122287,
              46.360139
            ],
            [
              -90.122785,
              46.361259
            ],
            [
              -90.122757,
              46.362621
            ],
            [
              -90.122923,
              46.363603
            ],
            [
              -90.126517,
              46.366889
            ],
            [
              -90.131036,
              46.369199
            ],
            [
              -90.133871,
              46.371828
            ],
            [
              -90.134663,
              46.374947
            ],
            [
              -90.134656,
              46.374979
            ],
            [
              -90.13225,
              46.381249
            ],
            [
              -90.133966,
              46.382118
            ],
            [
              -90.135253,
              46.38221
            ],
            [
              -90.13941,
              46.384999
            ],
            [
              -90.144359,
              46.390255
            ],
            [
              -90.146816,
              46.397205
            ],
            [
              -90.148347,
              46.399258
            ],
            [
              -90.152936,
              46.401293
            ],
            [
              -90.157851,
              46.409291
            ],
            [
              -90.158972,
              46.413769
            ],
            [
              -90.158241,
              46.420485
            ],
            [
              -90.158603,
              46.422656
            ],
            [
              -90.163422,
              46.434605
            ],
            [
              -90.166526,
              46.437576
            ],
            [
              -90.166909,
              46.439311
            ],
            [
              -90.166919,
              46.439851
            ],
            [
              -90.174556,
              46.439656
            ],
            [
              -90.17786,
              46.440548
            ],
            [
              -90.179212,
              46.45309
            ],
            [
              -90.180336,
              46.456746
            ],
            [
              -90.189162,
              46.459054
            ],
            [
              -90.190749,
              46.460173
            ],
            [
              -90.193294,
              46.463143
            ],
            [
              -90.192005,
              46.465611
            ],
            [
              -90.189426,
              46.467004
            ],
            [
              -90.188633,
              46.468101
            ],
            [
              -90.188996,
              46.469015
            ],
            [
              -90.193394,
              46.472487
            ],
            [
              -90.201727,
              46.476074
            ],
            [
              -90.204009,
              46.478175
            ],
            [
              -90.211753,
              46.490351
            ],
            [
              -90.214843,
              46.498181
            ],
            [
              -90.214866,
              46.499947
            ],
            [
              -90.216594,
              46.501759
            ],
            [
              -90.220532,
              46.503403
            ],
            [
              -90.222351,
              46.50338
            ],
            [
              -90.228735,
              46.501573
            ],
            [
              -90.230324,
              46.501732
            ],
            [
              -90.23102,
              46.503354
            ],
            [
              -90.230921,
              46.504656
            ],
            [
              -90.229402,
              46.507992
            ],
            [
              -90.230363,
              46.509705
            ],
            [
              -90.231587,
              46.509842
            ],
            [
              -90.236283,
              46.507121
            ],
            [
              -90.243395,
              46.505245
            ],
            [
              -90.246043,
              46.504832
            ],
            [
              -90.248194,
              46.505357
            ],
            [
              -90.25716,
              46.504716
            ],
            [
              -90.25865,
              46.503483
            ],
            [
              -90.260504,
              46.502822
            ],
            [
              -90.263018,
              46.502777
            ],
            [
              -90.265269,
              46.503829
            ],
            [
              -90.265143,
              46.505089
            ],
            [
              -90.265143,
              46.506222
            ],
            [
              -90.266528,
              46.507356
            ],
            [
              -90.26848,
              46.507167
            ],
            [
              -90.27018,
              46.507356
            ],
            [
              -90.270684,
              46.508237
            ],
            [
              -90.270558,
              46.50956
            ],
            [
              -90.270432,
              46.510756
            ],
            [
              -90.270422,
              46.51169
            ],
            [
              -90.274721,
              46.515416
            ],
            [
              -90.271971,
              46.519756
            ],
            [
              -90.272599,
              46.521127
            ],
            [
              -90.277131,
              46.524487
            ],
            [
              -90.278356,
              46.523847
            ],
            [
              -90.27892,
              46.522271
            ],
            [
              -90.283423,
              46.518868
            ],
            [
              -90.285707,
              46.518846
            ],
            [
              -90.292854,
              46.520972
            ],
            [
              -90.294311,
              46.519876
            ],
            [
              -90.294411,
              46.518848
            ],
            [
              -90.298284,
              46.51782
            ],
            [
              -90.303546,
              46.517432
            ],
            [
              -90.306558,
              46.518484
            ],
            [
              -90.307716,
              46.518392
            ],
            [
              -90.312581,
              46.517113
            ],
            [
              -90.313839,
              46.516199
            ],
            [
              -90.313894,
              46.516199
            ],
            [
              -90.316983,
              46.517319
            ],
            [
              -90.317777,
              46.521637
            ],
            [
              -90.314434,
              46.523784
            ],
            [
              -90.311886,
              46.528695
            ],
            [
              -90.310329,
              46.536852
            ],
            [
              -90.310859,
              46.539365
            ],
            [
              -90.320428,
              46.546287
            ],
            [
              -90.324699,
              46.545602
            ],
            [
              -90.326686,
              46.54615
            ],
            [
              -90.328044,
              46.548046
            ],
            [
              -90.327548,
              46.550262
            ],
            [
              -90.331887,
              46.553278
            ],
            [
              -90.336921,
              46.554076
            ],
            [
              -90.344338,
              46.552087
            ],
            [
              -90.347514,
              46.547083
            ],
            [
              -90.349462,
              46.53808
            ],
            [
              -90.350121,
              46.537337
            ],
            [
              -90.35158,
              46.537074
            ],
            [
              -90.353534,
              46.537553
            ],
            [
              -90.355689,
              46.540317
            ],
            [
              -90.357014,
              46.540591
            ],
            [
              -90.357676,
              46.540271
            ],
            [
              -90.3616,
              46.541434
            ],
            [
              -90.369964,
              46.540549
            ],
            [
              -90.374461,
              46.539212
            ],
            [
              -90.387228,
              46.533663
            ],
            [
              -90.39332,
              46.532615
            ],
            [
              -90.395272,
              46.533941
            ],
            [
              -90.395568,
              46.536317
            ],
            [
              -90.398742,
              46.542738
            ],
            [
              -90.400041,
              46.544384
            ],
            [
              -90.400429,
              46.544384
            ],
            [
              -90.402019,
              46.544384
            ],
            [
              -90.405593,
              46.547584
            ],
            [
              -90.407775,
              46.552246
            ],
            [
              -90.414464,
              46.55732
            ],
            [
              -90.414596,
              46.55732
            ],
            [
              -90.41562,
              46.563169
            ],
            [
              -90.418136,
              46.566094
            ],
            [
              -90.437596,
              46.561492
            ],
            [
              -90.47376,
              46.574178
            ],
            [
              -90.505909,
              46.589614
            ],
            [
              -90.525498,
              46.586926
            ],
            [
              -90.537962,
              46.581081
            ],
            [
              -90.5495962766133,
              46.58413846414
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "023",
      "COUNTYNS": "01581071",
      "AFFGEOID": "0500000US55023",
      "GEOID": "55023",
      "NAME": "Crawford",
      "LSAD": "06",
      "ALAND": 1478001876,
      "AWATER": 73945449,
      "County_state": "Crawford,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.21499,
              43.368006
            ],
            [
              -91.21477,
              43.365874
            ],
            [
              -91.20662,
              43.352524
            ],
            [
              -91.203964,
              43.349852
            ],
            [
              -91.201847,
              43.349103
            ],
            [
              -91.188014,
              43.347602
            ],
            [
              -91.181115,
              43.345926
            ],
            [
              -91.171055,
              43.340967
            ],
            [
              -91.154806,
              43.334826
            ],
            [
              -91.137343,
              43.329757
            ],
            [
              -91.132813,
              43.32803
            ],
            [
              -91.129121,
              43.32635
            ],
            [
              -91.117661,
              43.319332
            ],
            [
              -91.107237,
              43.313645
            ],
            [
              -91.085652,
              43.29187
            ],
            [
              -91.079875,
              43.282773
            ],
            [
              -91.07371,
              43.274746
            ],
            [
              -91.071724,
              43.271392
            ],
            [
              -91.071574,
              43.268193
            ],
            [
              -91.072782,
              43.264363
            ],
            [
              -91.072649,
              43.262129
            ],
            [
              -91.071698,
              43.261014
            ],
            [
              -91.069937,
              43.260272
            ],
            [
              -91.061798,
              43.259952
            ],
            [
              -91.05975,
              43.259074
            ],
            [
              -91.058644,
              43.257679
            ],
            [
              -91.057918,
              43.255366
            ],
            [
              -91.05791,
              43.253968
            ],
            [
              -91.059684,
              43.248566
            ],
            [
              -91.062562,
              43.243165
            ],
            [
              -91.066398,
              43.239293
            ],
            [
              -91.071857,
              43.235164
            ],
            [
              -91.079278,
              43.228259
            ],
            [
              -91.087456,
              43.221891
            ],
            [
              -91.107931,
              43.206578
            ],
            [
              -91.113749,
              43.202908
            ],
            [
              -91.119115,
              43.200366
            ],
            [
              -91.12217,
              43.197255
            ],
            [
              -91.123896,
              43.193536
            ],
            [
              -91.124428,
              43.187886
            ],
            [
              -91.134173,
              43.174405
            ],
            [
              -91.135917,
              43.173422
            ],
            [
              -91.138649,
              43.169993
            ],
            [
              -91.141356,
              43.163537
            ],
            [
              -91.143283,
              43.156413
            ],
            [
              -91.1462,
              43.152405
            ],
            [
              -91.1562,
              43.142945
            ],
            [
              -91.160449,
              43.140575
            ],
            [
              -91.170372,
              43.137384
            ],
            [
              -91.175253,
              43.134665
            ],
            [
              -91.177003,
              43.131846
            ],
            [
              -91.177932,
              43.128875
            ],
            [
              -91.178251,
              43.124982
            ],
            [
              -91.177728,
              43.118733
            ],
            [
              -91.175193,
              43.103771
            ],
            [
              -91.177222,
              43.080247
            ],
            [
              -91.177264,
              43.072983
            ],
            [
              -91.178761,
              43.070578
            ],
            [
              -91.179457,
              43.067427
            ],
            [
              -91.177894,
              43.064206
            ],
            [
              -91.178087,
              43.062044
            ],
            [
              -91.174692,
              43.038713
            ],
            [
              -91.168283,
              43.019426
            ],
            [
              -91.15749,
              42.991475
            ],
            [
              -91.1568126516435,
              42.9881698664533
            ],
            [
              -91.136552,
              42.998915
            ],
            [
              -91.101442,
              43.000124
            ],
            [
              -91.091017,
              42.994558
            ],
            [
              -91.062211,
              42.992552
            ],
            [
              -91.053033,
              43.001494
            ],
            [
              -91.012071,
              43.013152
            ],
            [
              -91.004427,
              43.022441
            ],
            [
              -90.985685,
              43.026096
            ],
            [
              -90.953078,
              43.043754
            ],
            [
              -90.940087,
              43.061882
            ],
            [
              -90.916218,
              43.062722
            ],
            [
              -90.882949,
              43.073247
            ],
            [
              -90.845746,
              43.077625
            ],
            [
              -90.822794,
              43.088146
            ],
            [
              -90.798291,
              43.109096
            ],
            [
              -90.7633,
              43.115572
            ],
            [
              -90.742455,
              43.123596
            ],
            [
              -90.719334,
              43.150027
            ],
            [
              -90.698213,
              43.160722
            ],
            [
              -90.689863,
              43.172972
            ],
            [
              -90.666786,
              43.171777
            ],
            [
              -90.666012,
              43.379423
            ],
            [
              -90.668561,
              43.422994
            ],
            [
              -90.746685,
              43.424552
            ],
            [
              -91.2055506500066,
              43.422948502358096
            ],
            [
              -91.203144,
              43.419805
            ],
            [
              -91.201224,
              43.415903
            ],
            [
              -91.200359,
              43.412701
            ],
            [
              -91.200527,
              43.408486
            ],
            [
              -91.199408,
              43.403032
            ],
            [
              -91.198048,
              43.399223
            ],
            [
              -91.19767,
              43.395334
            ],
            [
              -91.198953,
              43.389835
            ],
            [
              -91.200701,
              43.38593
            ],
            [
              -91.204831,
              43.378887
            ],
            [
              -91.206072,
              43.374976
            ],
            [
              -91.207367,
              43.373659
            ],
            [
              -91.21336,
              43.370097
            ],
            [
              -91.21499,
              43.368006
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "013",
      "COUNTYNS": "01581066",
      "AFFGEOID": "0500000US55013",
      "GEOID": "55013",
      "NAME": "Burnett",
      "LSAD": "06",
      "ALAND": 2127825031,
      "AWATER": 151944424,
      "County_state": "Burnett,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.885711,
              45.646017
            ],
            [
              -92.887067,
              45.644148
            ],
            [
              -92.529111,
              45.642171
            ],
            [
              -92.528198,
              45.72868
            ],
            [
              -92.283847,
              45.725932
            ],
            [
              -92.154443,
              45.725616
            ],
            [
              -92.154888,
              45.639742
            ],
            [
              -92.031417,
              45.639928
            ],
            [
              -92.031409,
              45.725465
            ],
            [
              -92.031966,
              45.815328
            ],
            [
              -92.033404,
              45.98387
            ],
            [
              -92.050647,
              45.983921
            ],
            [
              -92.049636,
              46.157597
            ],
            [
              -92.2937055830063,
              46.1573209976466
            ],
            [
              -92.29353,
              46.113824
            ],
            [
              -92.294069,
              46.078346
            ],
            [
              -92.294033,
              46.074377
            ],
            [
              -92.298638,
              46.072989
            ],
            [
              -92.306756,
              46.07241
            ],
            [
              -92.319329,
              46.069289
            ],
            [
              -92.327868,
              46.06618
            ],
            [
              -92.329806,
              46.065216
            ],
            [
              -92.332912,
              46.062697
            ],
            [
              -92.335335,
              46.059422
            ],
            [
              -92.338239,
              46.052149
            ],
            [
              -92.33859,
              46.050111
            ],
            [
              -92.341278,
              46.045424
            ],
            [
              -92.343459,
              46.04299
            ],
            [
              -92.343604,
              46.040917
            ],
            [
              -92.342429,
              46.034541
            ],
            [
              -92.343745,
              46.028525
            ],
            [
              -92.344244,
              46.02743
            ],
            [
              -92.346345,
              46.025429
            ],
            [
              -92.349281,
              46.023624
            ],
            [
              -92.350004,
              46.021888
            ],
            [
              -92.350319,
              46.01898
            ],
            [
              -92.349977,
              46.016982
            ],
            [
              -92.35176,
              46.015685
            ],
            [
              -92.357965,
              46.013413
            ],
            [
              -92.362141,
              46.013103
            ],
            [
              -92.372717,
              46.014198
            ],
            [
              -92.381707,
              46.017034
            ],
            [
              -92.392681,
              46.01954
            ],
            [
              -92.408259,
              46.02663
            ],
            [
              -92.410649,
              46.027259
            ],
            [
              -92.420696,
              46.026769
            ],
            [
              -92.428555,
              46.024241
            ],
            [
              -92.435627,
              46.021232
            ],
            [
              -92.442259,
              46.016177
            ],
            [
              -92.444356,
              46.011777
            ],
            [
              -92.444294,
              46.009161
            ],
            [
              -92.44963,
              46.002252
            ],
            [
              -92.451627,
              46.000441
            ],
            [
              -92.452952,
              45.997782
            ],
            [
              -92.453635,
              45.996171
            ],
            [
              -92.453373,
              45.992913
            ],
            [
              -92.456494,
              45.990243
            ],
            [
              -92.462477,
              45.98785
            ],
            [
              -92.464512,
              45.985038
            ],
            [
              -92.464173,
              45.982423
            ],
            [
              -92.463429,
              45.981507
            ],
            [
              -92.461138,
              45.980216
            ],
            [
              -92.46126,
              45.979427
            ],
            [
              -92.464481,
              45.976267
            ],
            [
              -92.469354,
              45.973811
            ],
            [
              -92.472761,
              45.972952
            ],
            [
              -92.479478,
              45.973992
            ],
            [
              -92.484633,
              45.975872
            ],
            [
              -92.490996,
              45.97556
            ],
            [
              -92.502535,
              45.979995
            ],
            [
              -92.519488,
              45.983917
            ],
            [
              -92.522032,
              45.984203
            ],
            [
              -92.527052,
              45.983245
            ],
            [
              -92.530516,
              45.981918
            ],
            [
              -92.537709,
              45.977818
            ],
            [
              -92.545682,
              45.970118
            ],
            [
              -92.548459,
              45.969056
            ],
            [
              -92.549806,
              45.967986
            ],
            [
              -92.550672,
              45.960759
            ],
            [
              -92.549858,
              45.957039
            ],
            [
              -92.551186,
              45.95224
            ],
            [
              -92.551933,
              45.951651
            ],
            [
              -92.561256,
              45.951006
            ],
            [
              -92.569764,
              45.948146
            ],
            [
              -92.574892,
              45.948103
            ],
            [
              -92.580565,
              45.94625
            ],
            [
              -92.590138,
              45.941773
            ],
            [
              -92.60246,
              45.940815
            ],
            [
              -92.608329,
              45.938112
            ],
            [
              -92.61083482831539,
              45.9366118524889
            ],
            [
              -92.614314,
              45.934529
            ],
            [
              -92.62272,
              45.935186
            ],
            [
              -92.627723,
              45.932682
            ],
            [
              -92.62926,
              45.932404
            ],
            [
              -92.636316,
              45.934634
            ],
            [
              -92.638824,
              45.934166
            ],
            [
              -92.639936,
              45.933541
            ],
            [
              -92.640115,
              45.932478
            ],
            [
              -92.638474,
              45.925971
            ],
            [
              -92.639116,
              45.924555
            ],
            [
              -92.656125,
              45.924442
            ],
            [
              -92.659549,
              45.922937
            ],
            [
              -92.670352,
              45.916247
            ],
            [
              -92.676167,
              45.912072
            ],
            [
              -92.676807,
              45.91093
            ],
            [
              -92.675737,
              45.907478
            ],
            [
              -92.676607,
              45.90637
            ],
            [
              -92.683924,
              45.903939
            ],
            [
              -92.698983,
              45.896451
            ],
            [
              -92.703265,
              45.896155
            ],
            [
              -92.707702,
              45.894901
            ],
            [
              -92.712503,
              45.891705
            ],
            [
              -92.721128,
              45.883805
            ],
            [
              -92.734039,
              45.868108
            ],
            [
              -92.736484,
              45.863356
            ],
            [
              -92.736117,
              45.859129
            ],
            [
              -92.739278,
              45.84758
            ],
            [
              -92.739991,
              45.846283
            ],
            [
              -92.745557,
              45.841455
            ],
            [
              -92.74918,
              45.840717
            ],
            [
              -92.759458,
              45.835341
            ],
            [
              -92.761712,
              45.833861
            ],
            [
              -92.765146,
              45.830183
            ],
            [
              -92.765681,
              45.827252
            ],
            [
              -92.764906,
              45.824859
            ],
            [
              -92.761889,
              45.817928
            ],
            [
              -92.760023,
              45.815475
            ],
            [
              -92.757947,
              45.811216
            ],
            [
              -92.757815,
              45.806574
            ],
            [
              -92.75901,
              45.803965
            ],
            [
              -92.761833,
              45.801258
            ],
            [
              -92.76843,
              45.79801
            ],
            [
              -92.772065,
              45.79523
            ],
            [
              -92.776496,
              45.790014
            ],
            [
              -92.779617,
              45.782563
            ],
            [
              -92.781373,
              45.773062
            ],
            [
              -92.784621,
              45.764196
            ],
            [
              -92.798645,
              45.753654
            ],
            [
              -92.80263,
              45.751888
            ],
            [
              -92.803971,
              45.749805
            ],
            [
              -92.805348,
              45.747493
            ],
            [
              -92.809837,
              45.744172
            ],
            [
              -92.812939,
              45.742709
            ],
            [
              -92.816559,
              45.742037
            ],
            [
              -92.826013,
              45.73665
            ],
            [
              -92.828981,
              45.733714
            ],
            [
              -92.830685,
              45.73312
            ],
            [
              -92.835917,
              45.732802
            ],
            [
              -92.841051,
              45.730024
            ],
            [
              -92.843079,
              45.729163
            ],
            [
              -92.848851,
              45.728751
            ],
            [
              -92.850388,
              45.727576
            ],
            [
              -92.850537,
              45.724376
            ],
            [
              -92.850933,
              45.723831
            ],
            [
              -92.853405,
              45.723152
            ],
            [
              -92.862598,
              45.722241
            ],
            [
              -92.865688,
              45.720623
            ],
            [
              -92.869193,
              45.717568
            ],
            [
              -92.869689,
              45.715142
            ],
            [
              -92.868862,
              45.711993
            ],
            [
              -92.871775,
              45.699774
            ],
            [
              -92.870025,
              45.697272
            ],
            [
              -92.870145,
              45.696757
            ],
            [
              -92.875488,
              45.689014
            ],
            [
              -92.876891,
              45.675289
            ],
            [
              -92.878932,
              45.665606
            ],
            [
              -92.882504,
              45.659471
            ],
            [
              -92.883987,
              45.65487
            ],
            [
              -92.885711,
              45.646017
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "025",
      "COUNTYNS": "01581072",
      "AFFGEOID": "0500000US55025",
      "GEOID": "55025",
      "NAME": "Dane",
      "LSAD": "06",
      "ALAND": 3098774789,
      "AWATER": 106341440,
      "County_state": "Dane,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.838228,
              43.074701
            ],
            [
              -89.838167,
              42.857397
            ],
            [
              -89.369069,
              42.856471
            ],
            [
              -89.369128,
              42.845036
            ],
            [
              -89.363019,
              42.845141
            ],
            [
              -89.250276,
              42.845913
            ],
            [
              -89.013582,
              42.84763
            ],
            [
              -89.013059,
              42.935349
            ],
            [
              -89.012343,
              43.023489
            ],
            [
              -89.009648,
              43.168722
            ],
            [
              -89.008832,
              43.197724
            ],
            [
              -89.00892,
              43.197721
            ],
            [
              -89.009139,
              43.28483
            ],
            [
              -89.245405,
              43.282093
            ],
            [
              -89.363085,
              43.281305
            ],
            [
              -89.363159,
              43.294036
            ],
            [
              -89.48163,
              43.294094
            ],
            [
              -89.581169,
              43.29326
            ],
            [
              -89.600855,
              43.293132
            ],
            [
              -89.720463,
              43.293084
            ],
            [
              -89.714429,
              43.278123
            ],
            [
              -89.731738,
              43.252203
            ],
            [
              -89.77424,
              43.239276
            ],
            [
              -89.805078,
              43.238942
            ],
            [
              -89.838135,
              43.206057
            ],
            [
              -89.837963,
              43.118577
            ],
            [
              -89.838228,
              43.074701
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "031",
      "COUNTYNS": "01581075",
      "AFFGEOID": "0500000US55031",
      "GEOID": "55031",
      "NAME": "Douglas",
      "LSAD": "06",
      "ALAND": 3377709531,
      "AWATER": 454636088,
      "County_state": "Douglas,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.293857,
              46.180073
            ],
            [
              -92.293744,
              46.166838
            ],
            [
              -92.2937055830063,
              46.1573209976466
            ],
            [
              -92.049636,
              46.157597
            ],
            [
              -91.561684,
              46.157846
            ],
            [
              -91.551282,
              46.157045
            ],
            [
              -91.551084,
              46.331031
            ],
            [
              -91.551622,
              46.502324
            ],
            [
              -91.553853,
              46.517608
            ],
            [
              -91.551408,
              46.755666
            ],
            [
              -91.551445,
              46.755674
            ],
            [
              -91.574291,
              46.757488
            ],
            [
              -91.590684,
              46.754331
            ],
            [
              -91.645502,
              46.734733
            ],
            [
              -91.7356321372603,
              46.7125751857893
            ],
            [
              -91.74965,
              46.709129
            ],
            [
              -91.790132,
              46.694675
            ],
            [
              -91.820027,
              46.690176
            ],
            [
              -91.840288,
              46.689693
            ],
            [
              -91.857462,
              46.692362
            ],
            [
              -91.886963,
              46.690211
            ],
            [
              -91.942988,
              46.679939
            ],
            [
              -91.961889,
              46.682539
            ],
            [
              -91.973389,
              46.686439
            ],
            [
              -91.987889,
              46.692739
            ],
            [
              -92.007989,
              46.705039
            ],
            [
              -92.01529,
              46.706469
            ],
            [
              -92.020289,
              46.704039
            ],
            [
              -92.03399,
              46.708939
            ],
            [
              -92.08949,
              46.74924
            ],
            [
              -92.10819,
              46.74914
            ],
            [
              -92.11659,
              46.74864
            ],
            [
              -92.13789,
              46.73954
            ],
            [
              -92.14329,
              46.73464
            ],
            [
              -92.143391,
              46.72814
            ],
            [
              -92.141291,
              46.72524
            ],
            [
              -92.146291,
              46.71594
            ],
            [
              -92.148691,
              46.71514
            ],
            [
              -92.155191,
              46.71594
            ],
            [
              -92.167291,
              46.719941
            ],
            [
              -92.174291,
              46.717241
            ],
            [
              -92.178891,
              46.716741
            ],
            [
              -92.189091,
              46.717541
            ],
            [
              -92.191491,
              46.716241
            ],
            [
              -92.193291,
              46.711241
            ],
            [
              -92.197391,
              46.707641
            ],
            [
              -92.201591,
              46.705941
            ],
            [
              -92.204691,
              46.704041
            ],
            [
              -92.205692,
              46.702541
            ],
            [
              -92.205192,
              46.698341
            ],
            [
              -92.198491,
              46.696141
            ],
            [
              -92.183091,
              46.695241
            ],
            [
              -92.177891,
              46.691841
            ],
            [
              -92.176491,
              46.690241
            ],
            [
              -92.176091,
              46.686341
            ],
            [
              -92.177591,
              46.683441
            ],
            [
              -92.181391,
              46.680241
            ],
            [
              -92.187592,
              46.678941
            ],
            [
              -92.192492,
              46.676741
            ],
            [
              -92.199492,
              46.670241
            ],
            [
              -92.204092,
              46.666941
            ],
            [
              -92.205492,
              46.664741
            ],
            [
              -92.202192,
              46.658941
            ],
            [
              -92.201592,
              46.656641
            ],
            [
              -92.202292,
              46.655041
            ],
            [
              -92.207092,
              46.651941
            ],
            [
              -92.212392,
              46.649941
            ],
            [
              -92.216392,
              46.649841
            ],
            [
              -92.223492,
              46.652641
            ],
            [
              -92.228492,
              46.652941
            ],
            [
              -92.235592,
              46.650041
            ],
            [
              -92.242493,
              46.649241
            ],
            [
              -92.256592,
              46.658741
            ],
            [
              -92.259692,
              46.657141
            ],
            [
              -92.265993,
              46.651041
            ],
            [
              -92.270592,
              46.650741
            ],
            [
              -92.272792,
              46.652841
            ],
            [
              -92.274392,
              46.657441
            ],
            [
              -92.278492,
              46.658641
            ],
            [
              -92.283692,
              46.658841
            ],
            [
              -92.286192,
              46.660342
            ],
            [
              -92.287092,
              46.662842
            ],
            [
              -92.287392,
              46.667342
            ],
            [
              -92.291292,
              46.668142
            ],
            [
              -92.292192,
              46.666042
            ],
            [
              -92.292192,
              46.663318
            ],
            [
              -92.292192,
              46.663242
            ],
            [
              -92.291597,
              46.624941
            ],
            [
              -92.291647,
              46.604649
            ],
            [
              -92.291976,
              46.503997
            ],
            [
              -92.292371,
              46.495585
            ],
            [
              -92.29251,
              46.478761
            ],
            [
              -92.292727,
              46.431993
            ],
            [
              -92.292847,
              46.420876
            ],
            [
              -92.29286,
              46.41722
            ],
            [
              -92.292999,
              46.321894
            ],
            [
              -92.292782,
              46.319312
            ],
            [
              -92.292803,
              46.314628
            ],
            [
              -92.29288,
              46.313752
            ],
            [
              -92.292839,
              46.307107
            ],
            [
              -92.29284,
              46.304319
            ],
            [
              -92.293007,
              46.297987
            ],
            [
              -92.293074,
              46.295129
            ],
            [
              -92.293619,
              46.244043
            ],
            [
              -92.293558,
              46.224578
            ],
            [
              -92.293857,
              46.180073
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "043",
      "COUNTYNS": "01581081",
      "AFFGEOID": "0500000US55043",
      "GEOID": "55043",
      "NAME": "Grant",
      "LSAD": "06",
      "ALAND": 2970364997,
      "AWATER": 94558771,
      "County_state": "Grant,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.1568126516435,
              42.9881698664533
            ],
            [
              -91.156743,
              42.98783
            ],
            [
              -91.156562,
              42.978226
            ],
            [
              -91.155519,
              42.975774
            ],
            [
              -91.150906,
              42.970514
            ],
            [
              -91.148001,
              42.966155
            ],
            [
              -91.14655,
              42.963345
            ],
            [
              -91.14543,
              42.958211
            ],
            [
              -91.14554,
              42.95651
            ],
            [
              -91.14909,
              42.946554
            ],
            [
              -91.14988,
              42.941955
            ],
            [
              -91.149784,
              42.940244
            ],
            [
              -91.145517,
              42.930378
            ],
            [
              -91.144315,
              42.926592
            ],
            [
              -91.1438,
              42.922877
            ],
            [
              -91.143878,
              42.920646
            ],
            [
              -91.145868,
              42.914967
            ],
            [
              -91.146182,
              42.912338
            ],
            [
              -91.146177,
              42.90985
            ],
            [
              -91.14556,
              42.90798
            ],
            [
              -91.144706,
              42.905964
            ],
            [
              -91.143375,
              42.90467
            ],
            [
              -91.138,
              42.903772
            ],
            [
              -91.117411,
              42.895837
            ],
            [
              -91.115512,
              42.894672
            ],
            [
              -91.112158,
              42.891149
            ],
            [
              -91.104051,
              42.885971
            ],
            [
              -91.100565,
              42.883078
            ],
            [
              -91.098238,
              42.875798
            ],
            [
              -91.09882,
              42.864421
            ],
            [
              -91.097656,
              42.859871
            ],
            [
              -91.095329,
              42.85532
            ],
            [
              -91.091837,
              42.851225
            ],
            [
              -91.091402,
              42.84986
            ],
            [
              -91.095114,
              42.834966
            ],
            [
              -91.09406,
              42.830813
            ],
            [
              -91.090136,
              42.829237
            ],
            [
              -91.08277,
              42.829977
            ],
            [
              -91.078665,
              42.827678
            ],
            [
              -91.079314,
              42.820309
            ],
            [
              -91.078097,
              42.806526
            ],
            [
              -91.075481,
              42.795466
            ],
            [
              -91.072447,
              42.787732
            ],
            [
              -91.071138,
              42.783004
            ],
            [
              -91.070716,
              42.775502
            ],
            [
              -91.069549,
              42.769628
            ],
            [
              -91.063254,
              42.763947
            ],
            [
              -91.060261,
              42.761847
            ],
            [
              -91.060129,
              42.759986
            ],
            [
              -91.061432,
              42.757974
            ],
            [
              -91.06312,
              42.757273
            ],
            [
              -91.065492,
              42.757081
            ],
            [
              -91.065783,
              42.753387
            ],
            [
              -91.06468,
              42.750914
            ],
            [
              -91.060172,
              42.750481
            ],
            [
              -91.058091,
              42.749246
            ],
            [
              -91.056297,
              42.747341
            ],
            [
              -91.05481,
              42.744686
            ],
            [
              -91.054801,
              42.740529
            ],
            [
              -91.053733,
              42.738238
            ],
            [
              -91.051275,
              42.737001
            ],
            [
              -91.049972,
              42.736905
            ],
            [
              -91.046571,
              42.737167
            ],
            [
              -91.044139,
              42.738605
            ],
            [
              -91.039383,
              42.738478
            ],
            [
              -91.035418,
              42.73734
            ],
            [
              -91.032013,
              42.734484
            ],
            [
              -91.030984,
              42.73255
            ],
            [
              -91.030718,
              42.729684
            ],
            [
              -91.029692,
              42.726774
            ],
            [
              -91.026786,
              42.724228
            ],
            [
              -91.017239,
              42.719566
            ],
            [
              -91.015687,
              42.719229
            ],
            [
              -91.009577,
              42.720123
            ],
            [
              -91.000128,
              42.716189
            ],
            [
              -90.995536,
              42.713704
            ],
            [
              -90.988776,
              42.708724
            ],
            [
              -90.980578,
              42.698932
            ],
            [
              -90.977735,
              42.696816
            ],
            [
              -90.974237,
              42.695249
            ],
            [
              -90.965048,
              42.693233
            ],
            [
              -90.952415,
              42.686778
            ],
            [
              -90.949213,
              42.685573
            ],
            [
              -90.941567,
              42.683844
            ],
            [
              -90.937045,
              42.683399
            ],
            [
              -90.929881,
              42.684128
            ],
            [
              -90.923634,
              42.6855
            ],
            [
              -90.921155,
              42.685406
            ],
            [
              -90.9134,
              42.682949
            ],
            [
              -90.900261,
              42.676254
            ],
            [
              -90.8968978644181,
              42.6752621751195
            ],
            [
              -90.88743,
              42.67247
            ],
            [
              -90.867125,
              42.668728
            ],
            [
              -90.852497,
              42.664822
            ],
            [
              -90.84391,
              42.663071
            ],
            [
              -90.832702,
              42.661662
            ],
            [
              -90.797017,
              42.655772
            ],
            [
              -90.788226,
              42.653888
            ],
            [
              -90.778752,
              42.652965
            ],
            [
              -90.769495,
              42.651443
            ],
            [
              -90.760389,
              42.649131
            ],
            [
              -90.743677,
              42.64556
            ],
            [
              -90.731132,
              42.643437
            ],
            [
              -90.720209,
              42.640758
            ],
            [
              -90.709204,
              42.636078
            ],
            [
              -90.706303,
              42.634169
            ],
            [
              -90.702671,
              42.630756
            ],
            [
              -90.700856,
              42.626445
            ],
            [
              -90.700095,
              42.622461
            ],
            [
              -90.693999,
              42.614509
            ],
            [
              -90.692031,
              42.610366
            ],
            [
              -90.687999,
              42.599198
            ],
            [
              -90.687775,
              42.594606
            ],
            [
              -90.686975,
              42.591774
            ],
            [
              -90.685487,
              42.589614
            ],
            [
              -90.679375,
              42.581503
            ],
            [
              -90.677055,
              42.579215
            ],
            [
              -90.672727,
              42.576599
            ],
            [
              -90.661527,
              42.567999
            ],
            [
              -90.659127,
              42.5579
            ],
            [
              -90.654127,
              42.5499
            ],
            [
              -90.645627,
              42.5441
            ],
            [
              -90.643927,
              42.540401
            ],
            [
              -90.640627,
              42.527701
            ],
            [
              -90.636727,
              42.518702
            ],
            [
              -90.636927,
              42.513202
            ],
            [
              -90.642843,
              42.508481
            ],
            [
              -90.617731,
              42.508077
            ],
            [
              -90.614589,
              42.508053
            ],
            [
              -90.565441,
              42.5076
            ],
            [
              -90.555862,
              42.507509
            ],
            [
              -90.551165,
              42.507691
            ],
            [
              -90.544799,
              42.507713
            ],
            [
              -90.544347,
              42.507707
            ],
            [
              -90.532254,
              42.507573
            ],
            [
              -90.491716,
              42.507624
            ],
            [
              -90.479446,
              42.507416
            ],
            [
              -90.474955,
              42.507484
            ],
            [
              -90.437011,
              42.507147
            ],
            [
              -90.4263779353442,
              42.507059428755895
            ],
            [
              -90.425874,
              42.63827
            ],
            [
              -90.426902,
              42.812862
            ],
            [
              -90.429577,
              43.182989
            ],
            [
              -90.429617,
              43.186745
            ],
            [
              -90.429825,
              43.20094
            ],
            [
              -90.545408,
              43.211455
            ],
            [
              -90.588903,
              43.200688
            ],
            [
              -90.608608,
              43.185372
            ],
            [
              -90.666786,
              43.171777
            ],
            [
              -90.689863,
              43.172972
            ],
            [
              -90.698213,
              43.160722
            ],
            [
              -90.719334,
              43.150027
            ],
            [
              -90.742455,
              43.123596
            ],
            [
              -90.7633,
              43.115572
            ],
            [
              -90.798291,
              43.109096
            ],
            [
              -90.822794,
              43.088146
            ],
            [
              -90.845746,
              43.077625
            ],
            [
              -90.882949,
              43.073247
            ],
            [
              -90.916218,
              43.062722
            ],
            [
              -90.940087,
              43.061882
            ],
            [
              -90.953078,
              43.043754
            ],
            [
              -90.985685,
              43.026096
            ],
            [
              -91.004427,
              43.022441
            ],
            [
              -91.012071,
              43.013152
            ],
            [
              -91.053033,
              43.001494
            ],
            [
              -91.062211,
              42.992552
            ],
            [
              -91.091017,
              42.994558
            ],
            [
              -91.101442,
              43.000124
            ],
            [
              -91.136552,
              42.998915
            ],
            [
              -91.1568126516435,
              42.9881698664533
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "101",
      "COUNTYNS": "01581111",
      "AFFGEOID": "0500000US55101",
      "GEOID": "55101",
      "NAME": "Racine",
      "LSAD": "06",
      "ALAND": 861408885,
      "AWATER": 1190236349,
      "County_state": "Racine,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.307987,
              42.756778
            ],
            [
              -88.30781,
              42.755488
            ],
            [
              -88.306775,
              42.694139
            ],
            [
              -88.306656,
              42.690175
            ],
            [
              -88.306091,
              42.639903
            ],
            [
              -88.305891,
              42.610817
            ],
            [
              -88.269777,
              42.611055
            ],
            [
              -88.188173,
              42.611456
            ],
            [
              -88.188306,
              42.669709
            ],
            [
              -87.863945,
              42.668487
            ],
            [
              -87.845984,
              42.668097
            ],
            [
              -87.8058558866897,
              42.667888960330096
            ],
            [
              -87.803074,
              42.675419
            ],
            [
              -87.794874,
              42.689919
            ],
            [
              -87.7873513750716,
              42.699949166571194
            ],
            [
              -87.786774,
              42.700719
            ],
            [
              -87.785074,
              42.700819
            ],
            [
              -87.782374,
              42.708219
            ],
            [
              -87.778627,
              42.727299
            ],
            [
              -87.782174,
              42.747719
            ],
            [
              -87.7801016393456,
              42.7555421614704
            ],
            [
              -87.778174,
              42.762819
            ],
            [
              -87.769774,
              42.773719
            ],
            [
              -87.766675,
              42.784896
            ],
            [
              -87.773699,
              42.793481
            ],
            [
              -87.793976,
              42.806218
            ],
            [
              -87.823278,
              42.835318
            ],
            [
              -87.8270210768063,
              42.8422224134625
            ],
            [
              -87.951992,
              42.843547
            ],
            [
              -88.069924,
              42.843323
            ],
            [
              -88.188016,
              42.84284
            ],
            [
              -88.229309,
              42.841915
            ],
            [
              -88.306384,
              42.842095
            ],
            [
              -88.306435,
              42.840675
            ],
            [
              -88.307987,
              42.756778
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "063",
      "COUNTYNS": "01581091",
      "AFFGEOID": "0500000US55063",
      "GEOID": "55063",
      "NAME": "La Crosse",
      "LSAD": "06",
      "ALAND": 1170076495,
      "AWATER": 73110306,
      "County_state": "La Crosse,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.42518797925139,
              43.9843219996781
            ],
            [
              -91.424134,
              43.982631
            ],
            [
              -91.412491,
              43.973411
            ],
            [
              -91.410555,
              43.970892
            ],
            [
              -91.407395,
              43.965148
            ],
            [
              -91.406011,
              43.963929
            ],
            [
              -91.395086,
              43.959409
            ],
            [
              -91.385785,
              43.954239
            ],
            [
              -91.375142,
              43.944289
            ],
            [
              -91.366642,
              43.937463
            ],
            [
              -91.364736,
              43.934884
            ],
            [
              -91.363242,
              43.926563
            ],
            [
              -91.357426,
              43.917231
            ],
            [
              -91.356741,
              43.916564
            ],
            [
              -91.351688,
              43.914545
            ],
            [
              -91.347741,
              43.911964
            ],
            [
              -91.346271,
              43.910074
            ],
            [
              -91.342335,
              43.902697
            ],
            [
              -91.338141,
              43.897664
            ],
            [
              -91.328143,
              43.893435
            ],
            [
              -91.320605,
              43.888491
            ],
            [
              -91.31531,
              43.881808
            ],
            [
              -91.313037,
              43.875757
            ],
            [
              -91.310991,
              43.867381
            ],
            [
              -91.301302,
              43.859515
            ],
            [
              -91.298815,
              43.856555
            ],
            [
              -91.296739,
              43.855165
            ],
            [
              -91.291002,
              43.852733
            ],
            [
              -91.284138,
              43.847065
            ],
            [
              -91.281968,
              43.842738
            ],
            [
              -91.277695,
              43.837741
            ],
            [
              -91.275737,
              43.824866
            ],
            [
              -91.273037,
              43.818566
            ],
            [
              -91.272037,
              43.813766
            ],
            [
              -91.267436,
              43.804166
            ],
            [
              -91.264436,
              43.800366
            ],
            [
              -91.262436,
              43.792166
            ],
            [
              -91.2532447735738,
              43.783377558317504
            ],
            [
              -91.244135,
              43.774667
            ],
            [
              -91.243955,
              43.773046
            ],
            [
              -91.255431,
              43.744876
            ],
            [
              -91.254903,
              43.733533
            ],
            [
              -91.255932,
              43.729849
            ],
            [
              -91.25777341547641,
              43.725660823086095
            ],
            [
              -90.910653,
              43.725334
            ],
            [
              -90.910101,
              43.899253
            ],
            [
              -90.912918,
              44.071522
            ],
            [
              -90.973107,
              44.070882
            ],
            [
              -91.151845,
              44.070995
            ],
            [
              -91.151932,
              44.079665
            ],
            [
              -91.167943,
              44.08787
            ],
            [
              -91.198215,
              44.08738
            ],
            [
              -91.201078,
              44.075392
            ],
            [
              -91.221891,
              44.053083
            ],
            [
              -91.247473,
              44.057513
            ],
            [
              -91.265888,
              44.072211
            ],
            [
              -91.284199,
              44.060365
            ],
            [
              -91.307478,
              44.064876
            ],
            [
              -91.337329,
              44.037499
            ],
            [
              -91.33807,
              44.007374
            ],
            [
              -91.322143,
              43.984462
            ],
            [
              -91.42518797925139,
              43.9843219996781
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "035",
      "COUNTYNS": "01581077",
      "AFFGEOID": "0500000US55035",
      "GEOID": "55035",
      "NAME": "Eau Claire",
      "LSAD": "06",
      "ALAND": 1652211293,
      "AWATER": 18848535,
      "County_state": "Eau Claire,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.650455,
              44.855951
            ],
            [
              -91.649619,
              44.780462
            ],
            [
              -91.650361,
              44.683633
            ],
            [
              -91.650248,
              44.596649
            ],
            [
              -91.529101,
              44.596197
            ],
            [
              -91.407615,
              44.596014
            ],
            [
              -91.286453,
              44.596799
            ],
            [
              -91.165619,
              44.596987
            ],
            [
              -91.043815,
              44.59664
            ],
            [
              -90.92235,
              44.596293
            ],
            [
              -90.922247,
              44.857309
            ],
            [
              -91.464481,
              44.857261
            ],
            [
              -91.51447,
              44.857348
            ],
            [
              -91.538652,
              44.857183
            ],
            [
              -91.547052,
              44.85709
            ],
            [
              -91.549087,
              44.857076
            ],
            [
              -91.650455,
              44.855951
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "079",
      "COUNTYNS": "01581100",
      "AFFGEOID": "0500000US55079",
      "GEOID": "55079",
      "NAME": "Milwaukee",
      "LSAD": "06",
      "ALAND": 625439443,
      "AWATER": 2455436199,
      "County_state": "Milwaukee,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.069587,
              42.921952
            ],
            [
              -88.069924,
              42.843323
            ],
            [
              -87.951992,
              42.843547
            ],
            [
              -87.8270210768063,
              42.8422224134625
            ],
            [
              -87.834879,
              42.856717
            ],
            [
              -87.84778,
              42.889216
            ],
            [
              -87.8474165889155,
              42.893128780651004
            ],
            [
              -87.8439732246942,
              42.9302028531653
            ],
            [
              -87.842681,
              42.944116
            ],
            [
              -87.845181,
              42.962015
            ],
            [
              -87.857182,
              42.978015
            ],
            [
              -87.8722101710389,
              42.9880799115372
            ],
            [
              -87.878683,
              42.992415
            ],
            [
              -87.887683,
              43.000514
            ],
            [
              -87.895784,
              43.015814
            ],
            [
              -87.898684,
              43.028813
            ],
            [
              -87.895084,
              43.042313
            ],
            [
              -87.882084,
              43.051113
            ],
            [
              -87.870184,
              43.064412
            ],
            [
              -87.866484,
              43.074412
            ],
            [
              -87.8694210722746,
              43.0819379417585
            ],
            [
              -87.876084,
              43.099011
            ],
            [
              -87.87610641491669,
              43.0990306610579
            ],
            [
              -87.893185,
              43.114011
            ],
            [
              -87.900485,
              43.12591
            ],
            [
              -87.901385,
              43.13321
            ],
            [
              -87.900285,
              43.13731
            ],
            [
              -87.892285,
              43.14871
            ],
            [
              -87.881085,
              43.170609
            ],
            [
              -87.887586,
              43.186608
            ],
            [
              -87.8920897893963,
              43.192043607892
            ],
            [
              -87.924662,
              43.192145
            ],
            [
              -87.924941,
              43.192147
            ],
            [
              -87.959456,
              43.192405
            ],
            [
              -87.994171,
              43.19255
            ],
            [
              -88.063353,
              43.192117
            ],
            [
              -88.064317,
              43.159666
            ],
            [
              -88.064403,
              43.147993
            ],
            [
              -88.065621,
              43.125416
            ],
            [
              -88.065589,
              43.120591
            ],
            [
              -88.066025,
              43.104221
            ],
            [
              -88.066143,
              43.098409
            ],
            [
              -88.066825,
              43.059935
            ],
            [
              -88.066911,
              43.0593
            ],
            [
              -88.067113,
              43.023597
            ],
            [
              -88.067252,
              43.016399
            ],
            [
              -88.06862,
              42.98096
            ],
            [
              -88.068887,
              42.973666
            ],
            [
              -88.069247,
              42.959179
            ],
            [
              -88.06929,
              42.952374
            ],
            [
              -88.069496,
              42.929957
            ],
            [
              -88.069587,
              42.921952
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "053",
      "COUNTYNS": "01581086",
      "AFFGEOID": "0500000US55053",
      "GEOID": "55053",
      "NAME": "Jackson",
      "LSAD": "06",
      "ALAND": 2558240018,
      "AWATER": 32985363,
      "County_state": "Jackson,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.165619,
              44.596987
            ],
            [
              -91.166,
              44.509863
            ],
            [
              -91.165548,
              44.247445
            ],
            [
              -91.153563,
              44.247583
            ],
            [
              -91.151932,
              44.079665
            ],
            [
              -91.151845,
              44.070995
            ],
            [
              -90.973107,
              44.070882
            ],
            [
              -90.977279,
              44.129608
            ],
            [
              -90.947049,
              44.147237
            ],
            [
              -90.892909,
              44.158515
            ],
            [
              -90.553421,
              44.160215
            ],
            [
              -90.435732,
              44.161129
            ],
            [
              -90.312522,
              44.155198
            ],
            [
              -90.31268,
              44.24875
            ],
            [
              -90.317939,
              44.254276
            ],
            [
              -90.316055,
              44.424502
            ],
            [
              -90.558746,
              44.42221
            ],
            [
              -90.801918,
              44.422442
            ],
            [
              -90.801627,
              44.509683
            ],
            [
              -90.922889,
              44.50984
            ],
            [
              -90.92235,
              44.596293
            ],
            [
              -91.043815,
              44.59664
            ],
            [
              -91.165619,
              44.596987
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "029",
      "COUNTYNS": "01581074",
      "AFFGEOID": "0500000US55029",
      "GEOID": "55029",
      "NAME": "Door",
      "LSAD": "06",
      "ALAND": 1248277387,
      "AWATER": 4890604624,
      "County_state": "Door,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.956198,
              45.352006
            ],
            [
              -86.956129,
              45.342267
            ],
            [
              -86.948087,
              45.335296
            ],
            [
              -86.937368,
              45.333065
            ],
            [
              -86.925681,
              45.3242
            ],
            [
              -86.913995,
              45.31211
            ],
            [
              -86.904898,
              45.296839
            ],
            [
              -86.899891,
              45.295185
            ],
            [
              -86.896928,
              45.2983
            ],
            [
              -86.896667,
              45.307275
            ],
            [
              -86.899488,
              45.322588
            ],
            [
              -86.895055,
              45.329035
            ],
            [
              -86.887802,
              45.332259
            ],
            [
              -86.875117,
              45.33087
            ],
            [
              -86.869041,
              45.333223
            ],
            [
              -86.865499,
              45.340981
            ],
            [
              -86.867743,
              45.353065
            ],
            [
              -86.863563,
              45.364888
            ],
            [
              -86.853103,
              45.370861
            ],
            [
              -86.841432,
              45.389601
            ],
            [
              -86.824383,
              45.406135
            ],
            [
              -86.818073,
              45.408147
            ],
            [
              -86.808303,
              45.406067
            ],
            [
              -86.805415,
              45.407324
            ],
            [
              -86.805868,
              45.412903
            ],
            [
              -86.810055,
              45.422619
            ],
            [
              -86.817069,
              45.426963
            ],
            [
              -86.828661,
              45.428539
            ],
            [
              -86.8309,
              45.426023
            ],
            [
              -86.829143,
              45.41649
            ],
            [
              -86.830353,
              45.410852
            ],
            [
              -86.853145,
              45.405547
            ],
            [
              -86.861472,
              45.412067
            ],
            [
              -86.877502,
              45.413981
            ],
            [
              -86.892893,
              45.40898
            ],
            [
              -86.917686,
              45.40789
            ],
            [
              -86.928045,
              45.411273
            ],
            [
              -86.930511,
              45.417536
            ],
            [
              -86.934276,
              45.421149
            ],
            [
              -86.937393,
              45.420966
            ],
            [
              -86.943041,
              45.41525
            ],
            [
              -86.955204,
              45.383721
            ],
            [
              -86.94751,
              45.366434
            ],
            [
              -86.946297,
              45.35869
            ],
            [
              -86.948272,
              45.355682
            ],
            [
              -86.953389,
              45.354715
            ],
            [
              -86.956198,
              45.352006
            ]
          ]
        ],
        [
          [
            [
              -87.375403,
              45.199296
            ],
            [
              -87.376777,
              45.177298
            ],
            [
              -87.327284,
              45.157363
            ],
            [
              -87.33622,
              45.173174
            ],
            [
              -87.331962,
              45.199251
            ],
            [
              -87.334158,
              45.211669
            ],
            [
              -87.375403,
              45.199296
            ]
          ]
        ],
        [
          [
            [
              -87.729836,
              44.682015
            ],
            [
              -87.7360584417798,
              44.6770254546693
            ],
            [
              -87.37325833223939,
              44.675540674647195
            ],
            [
              -87.368263,
              44.684141
            ],
            [
              -87.353789,
              44.701915
            ],
            [
              -87.343508,
              44.719228
            ],
            [
              -87.337584,
              44.737751
            ],
            [
              -87.318982,
              44.771335
            ],
            [
              -87.320397,
              44.784963
            ],
            [
              -87.313751,
              44.793766
            ],
            [
              -87.304824,
              44.804603
            ],
            [
              -87.282561,
              44.814729
            ],
            [
              -87.27603,
              44.83318
            ],
            [
              -87.267441,
              44.846851
            ],
            [
              -87.204545,
              44.875593
            ],
            [
              -87.206285,
              44.885928
            ],
            [
              -87.217195,
              44.897839
            ],
            [
              -87.215977,
              44.906597
            ],
            [
              -87.204238,
              44.916819
            ],
            [
              -87.186732,
              44.925463
            ],
            [
              -87.17492,
              44.927749
            ],
            [
              -87.1717,
              44.931476
            ],
            [
              -87.17524,
              44.939753
            ],
            [
              -87.188375,
              44.948077
            ],
            [
              -87.189407,
              44.968632
            ],
            [
              -87.181901,
              44.980887
            ],
            [
              -87.163529,
              45.00489
            ],
            [
              -87.154084,
              45.009117
            ],
            [
              -87.139384,
              45.012565
            ],
            [
              -87.124808,
              45.042167
            ],
            [
              -87.124701,
              45.052936
            ],
            [
              -87.121156,
              45.058311
            ],
            [
              -87.091639,
              45.055145
            ],
            [
              -87.081866,
              45.059103
            ],
            [
              -87.079552,
              45.070783
            ],
            [
              -87.063157,
              45.079316
            ],
            [
              -87.057444,
              45.087467
            ],
            [
              -87.048213,
              45.089124
            ],
            [
              -87.048951,
              45.108718
            ],
            [
              -87.051049,
              45.116172
            ],
            [
              -87.054342,
              45.119968
            ],
            [
              -87.045748,
              45.134987
            ],
            [
              -87.033114,
              45.141753
            ],
            [
              -87.028847,
              45.14637
            ],
            [
              -87.045225,
              45.158401
            ],
            [
              -87.045899,
              45.173902
            ],
            [
              -87.040909,
              45.211535
            ],
            [
              -87.032546,
              45.222274
            ],
            [
              -87.00754,
              45.222127
            ],
            [
              -87.005359,
              45.213694
            ],
            [
              -87.002806,
              45.211773
            ],
            [
              -86.985973,
              45.215872
            ],
            [
              -86.978759,
              45.227333
            ],
            [
              -86.973022,
              45.246399
            ],
            [
              -86.976711,
              45.246146
            ],
            [
              -86.982669,
              45.249117
            ],
            [
              -86.984975,
              45.258674
            ],
            [
              -86.984398,
              45.264366
            ],
            [
              -86.974528,
              45.271823
            ],
            [
              -86.970355,
              45.278455
            ],
            [
              -86.97778,
              45.290684
            ],
            [
              -86.983355,
              45.295368
            ],
            [
              -86.994112,
              45.298061
            ],
            [
              -87.017036,
              45.299254
            ],
            [
              -87.034206,
              45.287758
            ],
            [
              -87.043895,
              45.284767
            ],
            [
              -87.0517,
              45.285888
            ],
            [
              -87.057627,
              45.292838
            ],
            [
              -87.071035,
              45.280355
            ],
            [
              -87.078316,
              45.265723
            ],
            [
              -87.108743,
              45.257003
            ],
            [
              -87.116432,
              45.24152
            ],
            [
              -87.122819,
              45.222997
            ],
            [
              -87.121609,
              45.209783
            ],
            [
              -87.119405,
              45.20544
            ],
            [
              -87.119972,
              45.191103
            ],
            [
              -87.123689,
              45.18989
            ],
            [
              -87.13303,
              45.192843
            ],
            [
              -87.147709,
              45.190711
            ],
            [
              -87.163169,
              45.185331
            ],
            [
              -87.167179,
              45.183594
            ],
            [
              -87.175068,
              45.17305
            ],
            [
              -87.195213,
              45.16311
            ],
            [
              -87.21437,
              45.165735
            ],
            [
              -87.221971,
              45.175039
            ],
            [
              -87.231214,
              45.172887
            ],
            [
              -87.238224,
              45.167259
            ],
            [
              -87.242924,
              45.149377
            ],
            [
              -87.240308,
              45.137886
            ],
            [
              -87.250487,
              45.131289
            ],
            [
              -87.257449,
              45.121644
            ],
            [
              -87.260595,
              45.106007
            ],
            [
              -87.260542,
              45.092585
            ],
            [
              -87.264877,
              45.081361
            ],
            [
              -87.28428,
              45.063694
            ],
            [
              -87.302831,
              45.052447
            ],
            [
              -87.322117,
              45.034201
            ],
            [
              -87.336457,
              45.01353
            ],
            [
              -87.360288,
              44.987643
            ],
            [
              -87.374805,
              44.956631
            ],
            [
              -87.387253,
              44.939421
            ],
            [
              -87.393405,
              44.934393
            ],
            [
              -87.398368,
              44.925226
            ],
            [
              -87.405005,
              44.911806
            ],
            [
              -87.406199,
              44.90449
            ],
            [
              -87.393399,
              44.898199
            ],
            [
              -87.385396,
              44.889964
            ],
            [
              -87.383631,
              44.885115
            ],
            [
              -87.384821,
              44.865532
            ],
            [
              -87.405541,
              44.860047
            ],
            [
              -87.410015,
              44.86199
            ],
            [
              -87.419951,
              44.87594
            ],
            [
              -87.419106,
              44.885378
            ],
            [
              -87.420327,
              44.887596
            ],
            [
              -87.433128,
              44.892741
            ],
            [
              -87.437084,
              44.892718
            ],
            [
              -87.478489,
              44.863572
            ],
            [
              -87.501578,
              44.864285
            ],
            [
              -87.515142,
              44.869596
            ],
            [
              -87.530999,
              44.857437
            ],
            [
              -87.550288,
              44.85129
            ],
            [
              -87.573175,
              44.853118
            ],
            [
              -87.581306,
              44.851791
            ],
            [
              -87.610063,
              44.838384
            ],
            [
              -87.637104,
              44.813575
            ],
            [
              -87.6463,
              44.798739
            ],
            [
              -87.688207,
              44.758892
            ],
            [
              -87.705852,
              44.738225
            ],
            [
              -87.720889,
              44.724548
            ],
            [
              -87.721816,
              44.718969
            ],
            [
              -87.718409,
              44.707811
            ],
            [
              -87.71978,
              44.693246
            ],
            [
              -87.729836,
              44.682015
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "083",
      "COUNTYNS": "01581102",
      "AFFGEOID": "0500000US55083",
      "GEOID": "55083",
      "NAME": "Oconto",
      "LSAD": "06",
      "ALAND": 2583464874,
      "AWATER": 392038134,
      "County_state": "Oconto,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.677996,
              45.378683
            ],
            [
              -88.680066,
              45.204994
            ],
            [
              -88.638174,
              45.204815
            ],
            [
              -88.641159,
              45.117346
            ],
            [
              -88.483732,
              45.116887
            ],
            [
              -88.489149,
              44.855445
            ],
            [
              -88.248213,
              44.852739
            ],
            [
              -88.252362,
              44.766805
            ],
            [
              -88.242687,
              44.679631
            ],
            [
              -88.230958,
              44.679558
            ],
            [
              -88.231178,
              44.672361
            ],
            [
              -88.227976,
              44.672361
            ],
            [
              -88.131182,
              44.676928
            ],
            [
              -87.989912015149,
              44.6775869882811
            ],
            [
              -87.989717,
              44.686582
            ],
            [
              -87.983494,
              44.720196
            ],
            [
              -87.964714,
              44.74357
            ],
            [
              -87.95156,
              44.753107
            ],
            [
              -87.941453,
              44.75608
            ],
            [
              -87.926816,
              44.778555
            ],
            [
              -87.904484,
              44.818723
            ],
            [
              -87.901137,
              44.827365
            ],
            [
              -87.878218,
              44.839016
            ],
            [
              -87.866237,
              44.840481
            ],
            [
              -87.854681,
              44.857771
            ],
            [
              -87.852789,
              44.86486
            ],
            [
              -87.848324,
              44.87044
            ],
            [
              -87.838359,
              44.873987
            ],
            [
              -87.832764,
              44.880939
            ],
            [
              -87.827751,
              44.891229
            ],
            [
              -87.839561,
              44.905607
            ],
            [
              -87.842719,
              44.912077
            ],
            [
              -87.844299,
              44.918524
            ],
            [
              -87.843433,
              44.924355
            ],
            [
              -87.839028,
              44.931718
            ],
            [
              -87.819525,
              44.951109
            ],
            [
              -87.812989,
              44.954013
            ],
            [
              -87.766872,
              44.965254
            ],
            [
              -87.7626200275805,
              44.965796461784
            ],
            [
              -87.760071,
              44.99053
            ],
            [
              -87.883386,
              44.99046
            ],
            [
              -87.944436,
              44.993057
            ],
            [
              -87.942995,
              45.020418
            ],
            [
              -88.004308,
              45.021455
            ],
            [
              -88.121152,
              45.023581
            ],
            [
              -88.118879,
              45.110197
            ],
            [
              -88.189247,
              45.111844
            ],
            [
              -88.186355,
              45.197974
            ],
            [
              -88.309063,
              45.20158
            ],
            [
              -88.305811,
              45.37461
            ],
            [
              -88.428102,
              45.37701
            ],
            [
              -88.677996,
              45.378683
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "065",
      "COUNTYNS": "01581092",
      "AFFGEOID": "0500000US55065",
      "GEOID": "55065",
      "NAME": "Lafayette",
      "LSAD": "06",
      "ALAND": 1640985307,
      "AWATER": 2539100,
      "County_state": "Lafayette,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.426902,
              42.812862
            ],
            [
              -90.425874,
              42.63827
            ],
            [
              -90.4263779353442,
              42.507059428755895
            ],
            [
              -90.405927,
              42.506891
            ],
            [
              -90.370673,
              42.507111
            ],
            [
              -90.36796275766869,
              42.5071139048685
            ],
            [
              -90.367874,
              42.507114
            ],
            [
              -90.362652,
              42.507048
            ],
            [
              -90.303823,
              42.507469
            ],
            [
              -90.272864,
              42.507531
            ],
            [
              -90.269335,
              42.507726
            ],
            [
              -90.267143,
              42.507642
            ],
            [
              -90.253121,
              42.50734
            ],
            [
              -90.250622,
              42.507521
            ],
            [
              -90.22319,
              42.507765
            ],
            [
              -90.206073,
              42.507747
            ],
            [
              -90.181572,
              42.508068
            ],
            [
              -90.164363,
              42.508272
            ],
            [
              -90.142922,
              42.508151
            ],
            [
              -90.095004,
              42.507885
            ],
            [
              -90.093026,
              42.50816
            ],
            [
              -90.07367,
              42.508275
            ],
            [
              -90.018665,
              42.507288
            ],
            [
              -90.017028,
              42.507127
            ],
            [
              -89.999314,
              42.506914
            ],
            [
              -89.997213,
              42.506755
            ],
            [
              -89.985645,
              42.506431
            ],
            [
              -89.985072,
              42.506464
            ],
            [
              -89.955291,
              42.505626
            ],
            [
              -89.92700706749518,
              42.5057840766179
            ],
            [
              -89.926484,
              42.505787
            ],
            [
              -89.926374,
              42.505788
            ],
            [
              -89.8375874625297,
              42.5055426328327
            ],
            [
              -89.837226,
              42.683176
            ],
            [
              -89.83841,
              42.813799
            ],
            [
              -90.073092,
              42.813837
            ],
            [
              -90.426902,
              42.812862
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "045",
      "COUNTYNS": "01581082",
      "AFFGEOID": "0500000US55045",
      "GEOID": "55045",
      "NAME": "Green",
      "LSAD": "06",
      "ALAND": 1512664667,
      "AWATER": 1315749,
      "County_state": "Green,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.838167,
              42.857397
            ],
            [
              -89.83841,
              42.813799
            ],
            [
              -89.837226,
              42.683176
            ],
            [
              -89.8375874625297,
              42.5055426328327
            ],
            [
              -89.801897,
              42.505444
            ],
            [
              -89.799704,
              42.505421
            ],
            [
              -89.793957,
              42.505466
            ],
            [
              -89.780302,
              42.505349
            ],
            [
              -89.769643,
              42.505322
            ],
            [
              -89.742395,
              42.505382
            ],
            [
              -89.693487,
              42.505099
            ],
            [
              -89.690088,
              42.505191
            ],
            [
              -89.667596,
              42.50496
            ],
            [
              -89.650324,
              42.504613
            ],
            [
              -89.644176,
              42.50452
            ],
            [
              -89.61341,
              42.503942
            ],
            [
              -89.603523,
              42.503557
            ],
            [
              -89.6014368365924,
              42.503625117203796
            ],
            [
              -89.600001,
              42.503672
            ],
            [
              -89.594779,
              42.503468
            ],
            [
              -89.564407,
              42.502628
            ],
            [
              -89.522542,
              42.501889
            ],
            [
              -89.493216,
              42.501514
            ],
            [
              -89.492612,
              42.501514
            ],
            [
              -89.4843,
              42.501426
            ],
            [
              -89.425162,
              42.500726
            ],
            [
              -89.423926,
              42.500818
            ],
            [
              -89.422567,
              42.50068
            ],
            [
              -89.420991,
              42.500589
            ],
            [
              -89.401432,
              42.500433
            ],
            [
              -89.40141639302949,
              42.5004329299029
            ],
            [
              -89.366031,
              42.500274
            ],
            [
              -89.3657987753767,
              42.5002603886239
            ],
            [
              -89.369096,
              42.770197
            ],
            [
              -89.369128,
              42.845036
            ],
            [
              -89.369069,
              42.856471
            ],
            [
              -89.838167,
              42.857397
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "125",
      "COUNTYNS": "01581122",
      "AFFGEOID": "0500000US55125",
      "GEOID": "55125",
      "NAME": "Vilas",
      "LSAD": "06",
      "ALAND": 2221447085,
      "AWATER": 414426793,
      "County_state": "Vilas,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.043958,
              45.981948
            ],
            [
              -90.042857,
              45.897263
            ],
            [
              -90.023234,
              45.896916
            ],
            [
              -89.756801,
              45.898487
            ],
            [
              -89.425278,
              45.898347
            ],
            [
              -89.300473,
              45.901463
            ],
            [
              -89.300943,
              45.860689
            ],
            [
              -89.258009,
              45.861926
            ],
            [
              -89.175245,
              45.856579
            ],
            [
              -89.17785,
              45.899312
            ],
            [
              -89.121617,
              45.895463
            ],
            [
              -89.047601,
              45.895355
            ],
            [
              -89.047795,
              45.982345
            ],
            [
              -88.933222,
              45.982276
            ],
            [
              -88.9327522855634,
              46.073679640474396
            ],
            [
              -88.943279,
              46.077943
            ],
            [
              -88.948698,
              46.080205
            ],
            [
              -88.990807,
              46.097298
            ],
            [
              -88.99088490869069,
              46.09732984177639
            ],
            [
              -89.09163,
              46.138505
            ],
            [
              -89.125136,
              46.144531
            ],
            [
              -89.161757,
              46.151816
            ],
            [
              -89.166887,
              46.152868
            ],
            [
              -89.194508,
              46.157942
            ],
            [
              -89.201283,
              46.159426
            ],
            [
              -89.203289,
              46.16002
            ],
            [
              -89.205657,
              46.160408
            ],
            [
              -89.218156,
              46.162988
            ],
            [
              -89.219964,
              46.163319
            ],
            [
              -89.276489,
              46.174047
            ],
            [
              -89.276883,
              46.174116
            ],
            [
              -89.495723,
              46.216301
            ],
            [
              -89.533801,
              46.224119
            ],
            [
              -89.638416,
              46.243804
            ],
            [
              -89.667617,
              46.249797
            ],
            [
              -89.764506,
              46.268082
            ],
            [
              -89.908196,
              46.296037
            ],
            [
              -89.90991,
              46.296402
            ],
            [
              -89.918798,
              46.297741
            ],
            [
              -89.92915763295049,
              46.2997498928327
            ],
            [
              -89.928549,
              46.069317
            ],
            [
              -89.928837,
              45.981967
            ],
            [
              -90.043958,
              45.981948
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "115",
      "COUNTYNS": "01581117",
      "AFFGEOID": "0500000US55115",
      "GEOID": "55115",
      "NAME": "Shawano",
      "LSAD": "06",
      "ALAND": 2313442856,
      "AWATER": 41883375,
      "County_state": "Shawano,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.224008,
              44.856265
            ],
            [
              -89.223745,
              44.681365
            ],
            [
              -88.905141,
              44.679743
            ],
            [
              -88.885481,
              44.679673
            ],
            [
              -88.736338,
              44.679066
            ],
            [
              -88.605159,
              44.678331
            ],
            [
              -88.606083,
              44.590515
            ],
            [
              -88.488756,
              44.58951
            ],
            [
              -88.245204,
              44.584746
            ],
            [
              -88.242833,
              44.660773
            ],
            [
              -88.252562,
              44.679813
            ],
            [
              -88.242687,
              44.679631
            ],
            [
              -88.252362,
              44.766805
            ],
            [
              -88.248213,
              44.852739
            ],
            [
              -88.489149,
              44.855445
            ],
            [
              -88.736194,
              44.856376
            ],
            [
              -88.735548,
              44.943178
            ],
            [
              -88.981319,
              44.942745
            ],
            [
              -88.98168,
              45.028917
            ],
            [
              -89.223814,
              45.029246
            ],
            [
              -89.22345,
              44.942853
            ],
            [
              -89.224008,
              44.856265
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "033",
      "COUNTYNS": "01581076",
      "AFFGEOID": "0500000US55033",
      "GEOID": "55033",
      "NAME": "Dunn",
      "LSAD": "06",
      "ALAND": 2201882935,
      "AWATER": 35592324,
      "County_state": "Dunn,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.156465,
              45.209554
            ],
            [
              -92.157072,
              45.121608
            ],
            [
              -92.136113,
              45.121432
            ],
            [
              -92.136348,
              44.857848
            ],
            [
              -92.135202,
              44.684374
            ],
            [
              -91.650361,
              44.683633
            ],
            [
              -91.649619,
              44.780462
            ],
            [
              -91.650455,
              44.855951
            ],
            [
              -91.651396,
              45.030165
            ],
            [
              -91.65158,
              45.120803
            ],
            [
              -91.666402,
              45.120823
            ],
            [
              -91.665647,
              45.207991
            ],
            [
              -92.033674,
              45.208851
            ],
            [
              -92.042458,
              45.20868
            ],
            [
              -92.156465,
              45.209554
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "121",
      "COUNTYNS": "01581120",
      "AFFGEOID": "0500000US55121",
      "GEOID": "55121",
      "NAME": "Trempealeau",
      "LSAD": "06",
      "ALAND": 1898471750,
      "AWATER": 23372072,
      "County_state": "Trempealeau,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.597223,
              44.187723
            ],
            [
              -91.612971,
              44.172157
            ],
            [
              -91.590792,
              44.155392
            ],
            [
              -91.565664,
              44.14853
            ],
            [
              -91.548752,
              44.12439
            ],
            [
              -91.548367,
              44.107478
            ],
            [
              -91.534245,
              44.096319
            ],
            [
              -91.558276,
              44.062437
            ],
            [
              -91.571157,
              44.037828
            ],
            [
              -91.559004,
              44.025315
            ],
            [
              -91.547028,
              44.022226
            ],
            [
              -91.533778,
              44.021433
            ],
            [
              -91.524315,
              44.021433
            ],
            [
              -91.507121,
              44.01898
            ],
            [
              -91.502163,
              44.016856
            ],
            [
              -91.494988,
              44.012536
            ],
            [
              -91.48087,
              44.008145
            ],
            [
              -91.478498,
              44.00803
            ],
            [
              -91.468472,
              44.00948
            ],
            [
              -91.463515,
              44.009041
            ],
            [
              -91.457378,
              44.006301
            ],
            [
              -91.440536,
              44.001501
            ],
            [
              -91.43738,
              43.999962
            ],
            [
              -91.432522,
              43.996827
            ],
            [
              -91.429878,
              43.993888
            ],
            [
              -91.42672,
              43.9885
            ],
            [
              -91.425681,
              43.985113
            ],
            [
              -91.42518797925139,
              43.9843219996781
            ],
            [
              -91.322143,
              43.984462
            ],
            [
              -91.33807,
              44.007374
            ],
            [
              -91.337329,
              44.037499
            ],
            [
              -91.307478,
              44.064876
            ],
            [
              -91.284199,
              44.060365
            ],
            [
              -91.265888,
              44.072211
            ],
            [
              -91.247473,
              44.057513
            ],
            [
              -91.221891,
              44.053083
            ],
            [
              -91.201078,
              44.075392
            ],
            [
              -91.198215,
              44.08738
            ],
            [
              -91.167943,
              44.08787
            ],
            [
              -91.151932,
              44.079665
            ],
            [
              -91.153563,
              44.247583
            ],
            [
              -91.165548,
              44.247445
            ],
            [
              -91.166,
              44.509863
            ],
            [
              -91.165619,
              44.596987
            ],
            [
              -91.286453,
              44.596799
            ],
            [
              -91.407615,
              44.596014
            ],
            [
              -91.529101,
              44.596197
            ],
            [
              -91.529741,
              44.246777
            ],
            [
              -91.534049,
              44.241002
            ],
            [
              -91.55712,
              44.224291
            ],
            [
              -91.574409,
              44.222488
            ],
            [
              -91.600956,
              44.211048
            ],
            [
              -91.608647,
              44.200779
            ],
            [
              -91.597223,
              44.187723
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "039",
      "COUNTYNS": "01581079",
      "AFFGEOID": "0500000US55039",
      "GEOID": "55039",
      "NAME": "Fond du Lac",
      "LSAD": "06",
      "ALAND": 1863635236,
      "AWATER": 119885776,
      "County_state": "Fond du Lac,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.885704,
              43.808032
            ],
            [
              -88.886052,
              43.63354
            ],
            [
              -88.76539,
              43.633082
            ],
            [
              -88.758302,
              43.633109
            ],
            [
              -88.715969,
              43.633221
            ],
            [
              -88.644648,
              43.633025
            ],
            [
              -88.644321,
              43.633021
            ],
            [
              -88.401041,
              43.630933
            ],
            [
              -88.400426,
              43.543532
            ],
            [
              -88.160873,
              43.542943
            ],
            [
              -88.162274,
              43.891511
            ],
            [
              -88.161654,
              43.937683
            ],
            [
              -88.285988,
              43.937683
            ],
            [
              -88.404187,
              43.9382
            ],
            [
              -88.403195,
              43.892977
            ],
            [
              -88.764839,
              43.894899
            ],
            [
              -88.885697,
              43.895184
            ],
            [
              -88.885704,
              43.808032
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "003",
      "COUNTYNS": "01581061",
      "AFFGEOID": "0500000US55003",
      "GEOID": "55003",
      "NAME": "Ashland",
      "LSAD": "06",
      "ALAND": 2706535135,
      "AWATER": 3230651989,
      "County_state": "Ashland,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.465465,
              47.002593
            ],
            [
              -90.464079,
              46.994636
            ],
            [
              -90.429397,
              47.00561
            ],
            [
              -90.413428,
              47.01317
            ],
            [
              -90.403499,
              47.025366
            ],
            [
              -90.400409,
              47.046588
            ],
            [
              -90.394312,
              47.060909
            ],
            [
              -90.393035,
              47.067877
            ],
            [
              -90.39383,
              47.075941
            ],
            [
              -90.395367,
              47.077175
            ],
            [
              -90.417272,
              47.07757
            ],
            [
              -90.437271,
              47.073483
            ],
            [
              -90.449075,
              47.066013
            ],
            [
              -90.455024,
              47.05347
            ],
            [
              -90.457128,
              47.044058
            ],
            [
              -90.455251,
              47.024
            ],
            [
              -90.457453,
              47.012783
            ],
            [
              -90.465465,
              47.002593
            ]
          ]
        ],
        [
          [
            [
              -90.525367,
              46.876205
            ],
            [
              -90.499655,
              46.86412
            ],
            [
              -90.465908,
              46.87291
            ],
            [
              -90.438589,
              46.894877
            ],
            [
              -90.448231,
              46.904759
            ],
            [
              -90.496441,
              46.89268
            ],
            [
              -90.525367,
              46.876205
            ]
          ]
        ],
        [
          [
            [
              -90.651834,
              46.925267
            ],
            [
              -90.654805,
              46.919259
            ],
            [
              -90.644144,
              46.908379
            ],
            [
              -90.637124,
              46.906724
            ],
            [
              -90.569169,
              46.920309
            ],
            [
              -90.557319,
              46.918693
            ],
            [
              -90.55328,
              46.916674
            ],
            [
              -90.549104,
              46.915461
            ],
            [
              -90.545872,
              46.916673
            ],
            [
              -90.543852,
              46.918289
            ],
            [
              -90.543583,
              46.923002
            ],
            [
              -90.539947,
              46.92785
            ],
            [
              -90.535368,
              46.931486
            ],
            [
              -90.524056,
              46.935664
            ],
            [
              -90.508157,
              46.956836
            ],
            [
              -90.511623,
              46.961407
            ],
            [
              -90.523298,
              46.967604
            ],
            [
              -90.528804,
              46.968497
            ],
            [
              -90.601153,
              46.952457
            ],
            [
              -90.634507,
              46.942944
            ],
            [
              -90.651834,
              46.925267
            ]
          ]
        ],
        [
          [
            [
              -90.761406,
              46.838923
            ],
            [
              -90.756052,
              46.830595
            ],
            [
              -90.745356,
              46.83566
            ],
            [
              -90.718547,
              46.864531
            ],
            [
              -90.675239,
              46.881029
            ],
            [
              -90.667678,
              46.889965
            ],
            [
              -90.677989,
              46.897527
            ],
            [
              -90.730233,
              46.873467
            ],
            [
              -90.74948,
              46.862469
            ],
            [
              -90.761406,
              46.838923
            ]
          ]
        ],
        [
          [
            [
              -90.740544,
              46.964206
            ],
            [
              -90.765291,
              46.947021
            ],
            [
              -90.737107,
              46.914712
            ],
            [
              -90.688988,
              46.917462
            ],
            [
              -90.694487,
              46.93671
            ],
            [
              -90.71511,
              46.957332
            ],
            [
              -90.740544,
              46.964206
            ]
          ]
        ],
        [
          [
            [
              -90.776921,
              47.024324
            ],
            [
              -90.767985,
              47.002327
            ],
            [
              -90.712032,
              46.98526
            ],
            [
              -90.671581,
              46.948973
            ],
            [
              -90.634105,
              46.970983
            ],
            [
              -90.609715,
              46.991208
            ],
            [
              -90.553203,
              46.998942
            ],
            [
              -90.544875,
              47.017383
            ],
            [
              -90.560936,
              47.037013
            ],
            [
              -90.575506,
              47.033864
            ],
            [
              -90.608526,
              47.00727
            ],
            [
              -90.643623,
              47.041177
            ],
            [
              -90.650422,
              47.054678
            ],
            [
              -90.687048,
              47.043557
            ],
            [
              -90.760423,
              47.03326
            ],
            [
              -90.776921,
              47.024324
            ]
          ]
        ],
        [
          [
            [
              -90.791562,
              46.784983
            ],
            [
              -90.790965,
              46.781373
            ],
            [
              -90.783086,
              46.772939
            ],
            [
              -90.788515,
              46.753313
            ],
            [
              -90.771228,
              46.753034
            ],
            [
              -90.763647,
              46.754927
            ],
            [
              -90.739425,
              46.768021
            ],
            [
              -90.723938,
              46.781737
            ],
            [
              -90.716456,
              46.785418
            ],
            [
              -90.696465,
              46.78204
            ],
            [
              -90.657179,
              46.7888
            ],
            [
              -90.652219,
              46.794511
            ],
            [
              -90.652916,
              46.797755
            ],
            [
              -90.65531,
              46.799173
            ],
            [
              -90.670049,
              46.799496
            ],
            [
              -90.685753,
              46.805003
            ],
            [
              -90.683356,
              46.813275
            ],
            [
              -90.676133,
              46.818986
            ],
            [
              -90.613822,
              46.837942
            ],
            [
              -90.584489,
              46.839825
            ],
            [
              -90.578263,
              46.841653
            ],
            [
              -90.568874,
              46.847252
            ],
            [
              -90.570006,
              46.849696
            ],
            [
              -90.587392,
              46.858056
            ],
            [
              -90.602552,
              46.872706
            ],
            [
              -90.616452,
              46.874466
            ],
            [
              -90.622048,
              46.872872
            ],
            [
              -90.634048,
              46.864396
            ],
            [
              -90.643218,
              46.853844
            ],
            [
              -90.656946,
              46.843476
            ],
            [
              -90.675364,
              46.833929
            ],
            [
              -90.712381,
              46.820743
            ],
            [
              -90.720932,
              46.815897
            ],
            [
              -90.727818,
              46.810434
            ],
            [
              -90.728546,
              46.804356
            ],
            [
              -90.733001,
              46.800219
            ],
            [
              -90.758586,
              46.796214
            ],
            [
              -90.790231,
              46.786103
            ],
            [
              -90.791562,
              46.784983
            ]
          ]
        ],
        [
          [
            [
              -90.92705394131201,
              46.58505642233249
            ],
            [
              -90.92755,
              46.50217
            ],
            [
              -90.924583,
              46.154616
            ],
            [
              -90.924634,
              46.068
            ],
            [
              -90.925174,
              45.981055
            ],
            [
              -90.676968,
              45.981554
            ],
            [
              -90.302063,
              45.981349
            ],
            [
              -90.300268,
              46.067949
            ],
            [
              -90.303669,
              46.096649
            ],
            [
              -90.303072,
              46.154849
            ],
            [
              -90.426274,
              46.15405
            ],
            [
              -90.425978,
              46.24045
            ],
            [
              -90.552227,
              46.240851
            ],
            [
              -90.5495962766133,
              46.58413846414
            ],
            [
              -90.558141,
              46.586384
            ],
            [
              -90.586249,
              46.599863
            ],
            [
              -90.627885,
              46.623839
            ],
            [
              -90.694721,
              46.664402
            ],
            [
              -90.73726,
              46.692267
            ],
            [
              -90.739549,
              46.689981
            ],
            [
              -90.74809,
              46.669817
            ],
            [
              -90.756495,
              46.664591
            ],
            [
              -90.755287,
              46.646289
            ],
            [
              -90.770192,
              46.636127
            ],
            [
              -90.794775,
              46.624941
            ],
            [
              -90.829031,
              46.616066
            ],
            [
              -90.86712,
              46.601911
            ],
            [
              -90.873154,
              46.601223
            ],
            [
              -90.905572,
              46.583524
            ],
            [
              -90.909815,
              46.582703
            ],
            [
              -90.918266,
              46.58307
            ],
            [
              -90.92705394131201,
              46.58505642233249
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "131",
      "COUNTYNS": "01581125",
      "AFFGEOID": "0500000US55131",
      "GEOID": "55131",
      "NAME": "Washington",
      "LSAD": "06",
      "ALAND": 1115370272,
      "AWATER": 12999403,
      "County_state": "Washington,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.418706,
              43.325439
            ],
            [
              -88.419215,
              43.281924
            ],
            [
              -88.417987,
              43.194697
            ],
            [
              -88.300085,
              43.193139
            ],
            [
              -88.182228,
              43.192571
            ],
            [
              -88.136409,
              43.192003
            ],
            [
              -88.063353,
              43.192117
            ],
            [
              -88.063292,
              43.278881
            ],
            [
              -88.062426,
              43.367289
            ],
            [
              -88.040152,
              43.367303
            ],
            [
              -88.040528,
              43.542361
            ],
            [
              -88.160873,
              43.542943
            ],
            [
              -88.400426,
              43.543532
            ],
            [
              -88.401544,
              43.370029
            ],
            [
              -88.418439,
              43.370175
            ],
            [
              -88.418745,
              43.343584
            ],
            [
              -88.418706,
              43.325439
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "127",
      "COUNTYNS": "01581123",
      "AFFGEOID": "0500000US55127",
      "GEOID": "55127",
      "NAME": "Walworth",
      "LSAD": "06",
      "ALAND": 1438141978,
      "AWATER": 55217124,
      "County_state": "Walworth,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.777137,
              42.834488
            ],
            [
              -88.778876,
              42.754753
            ],
            [
              -88.7764931385154,
              42.492023615741594
            ],
            [
              -88.76536,
              42.492068
            ],
            [
              -88.70738,
              42.493587
            ],
            [
              -88.7073777163598,
              42.493587048379496
            ],
            [
              -88.638653,
              42.495043
            ],
            [
              -88.5404800500758,
              42.4949237685384
            ],
            [
              -88.506912,
              42.494883
            ],
            [
              -88.461397,
              42.494618
            ],
            [
              -88.417396,
              42.494618
            ],
            [
              -88.304692,
              42.4947727016231
            ],
            [
              -88.305262,
              42.55269
            ],
            [
              -88.305891,
              42.610817
            ],
            [
              -88.306091,
              42.639903
            ],
            [
              -88.306656,
              42.690175
            ],
            [
              -88.306775,
              42.694139
            ],
            [
              -88.30781,
              42.755488
            ],
            [
              -88.307987,
              42.756778
            ],
            [
              -88.306435,
              42.840675
            ],
            [
              -88.306384,
              42.842095
            ],
            [
              -88.375504,
              42.842792
            ],
            [
              -88.42407,
              42.842587
            ],
            [
              -88.541535,
              42.842996
            ],
            [
              -88.659279,
              42.842541
            ],
            [
              -88.69812,
              42.842634
            ],
            [
              -88.771719,
              42.842825
            ],
            [
              -88.777076,
              42.842694
            ],
            [
              -88.777137,
              42.834488
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "001",
      "COUNTYNS": "01581060",
      "AFFGEOID": "0500000US55001",
      "GEOID": "55001",
      "NAME": "Adams",
      "LSAD": "06",
      "ALAND": 1672140731,
      "AWATER": 108432130,
      "County_state": "Adams,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.026272,
              44.089593
            ],
            [
              -90.018767,
              44.047719
            ],
            [
              -89.998977,
              44.024045
            ],
            [
              -90.005887,
              44.008391
            ],
            [
              -89.974295,
              43.99606
            ],
            [
              -89.962601,
              43.979236
            ],
            [
              -89.962711,
              43.966282
            ],
            [
              -89.952101,
              43.924197
            ],
            [
              -89.98316,
              43.915667
            ],
            [
              -89.980676,
              43.905623
            ],
            [
              -89.962948,
              43.883399
            ],
            [
              -89.961221,
              43.864027
            ],
            [
              -89.922308,
              43.838468
            ],
            [
              -89.916193,
              43.82152
            ],
            [
              -89.902839,
              43.814086
            ],
            [
              -89.889778,
              43.796714
            ],
            [
              -89.865132,
              43.783414
            ],
            [
              -89.843374,
              43.75805
            ],
            [
              -89.84125,
              43.736677
            ],
            [
              -89.819192,
              43.692583
            ],
            [
              -89.807672,
              43.677194
            ],
            [
              -89.787005,
              43.667091
            ],
            [
              -89.784901,
              43.641051
            ],
            [
              -89.749065,
              43.642209
            ],
            [
              -89.718733,
              43.643154
            ],
            [
              -89.599547,
              43.642636
            ],
            [
              -89.599041,
              43.730801
            ],
            [
              -89.59795,
              43.9821
            ],
            [
              -89.59798,
              44.245725
            ],
            [
              -89.724746,
              44.247678
            ],
            [
              -89.902363,
              44.24947
            ],
            [
              -89.902783,
              44.249473
            ],
            [
              -89.897585,
              44.229844
            ],
            [
              -89.924833,
              44.213533
            ],
            [
              -89.905013,
              44.201604
            ],
            [
              -89.923811,
              44.190573
            ],
            [
              -89.903595,
              44.191434
            ],
            [
              -89.901502,
              44.180576
            ],
            [
              -89.92043,
              44.178581
            ],
            [
              -89.923387,
              44.152416
            ],
            [
              -89.93299,
              44.15036
            ],
            [
              -89.941713,
              44.163434
            ],
            [
              -89.970482,
              44.167114
            ],
            [
              -89.976919,
              44.122538
            ],
            [
              -89.991416,
              44.126725
            ],
            [
              -90.007056,
              44.121607
            ],
            [
              -90.004801,
              44.102677
            ],
            [
              -90.026272,
              44.089593
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "107",
      "COUNTYNS": "01581114",
      "AFFGEOID": "0500000US55107",
      "GEOID": "55107",
      "NAME": "Rusk",
      "LSAD": "06",
      "ALAND": 2366092049,
      "AWATER": 44813688,
      "County_state": "Rusk,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.540294,
              45.637605
            ],
            [
              -91.541322,
              45.29222
            ],
            [
              -90.925222,
              45.292061
            ],
            [
              -90.925343,
              45.37935
            ],
            [
              -90.678773,
              45.377907
            ],
            [
              -90.67798,
              45.49363
            ],
            [
              -90.67981,
              45.53769
            ],
            [
              -90.678749,
              45.638264
            ],
            [
              -91.540294,
              45.637605
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "111",
      "COUNTYNS": "01581115",
      "AFFGEOID": "0500000US55111",
      "GEOID": "55111",
      "NAME": "Sauk",
      "LSAD": "06",
      "ALAND": 2153665639,
      "AWATER": 45713371,
      "County_state": "Sauk,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.312404,
              43.640988
            ],
            [
              -90.311069,
              43.553991
            ],
            [
              -90.191964,
              43.554996
            ],
            [
              -90.192176,
              43.532868
            ],
            [
              -90.191963,
              43.527319
            ],
            [
              -90.192281,
              43.523082
            ],
            [
              -90.192015,
              43.511148
            ],
            [
              -90.191781,
              43.467383
            ],
            [
              -90.191938,
              43.380083
            ],
            [
              -90.192451,
              43.169293
            ],
            [
              -90.193814,
              43.164464
            ],
            [
              -90.184312,
              43.15756
            ],
            [
              -90.158983,
              43.166794
            ],
            [
              -90.113726,
              43.160344
            ],
            [
              -90.082387,
              43.161143
            ],
            [
              -90.05438,
              43.146301
            ],
            [
              -90.048613,
              43.166882
            ],
            [
              -90.035005,
              43.172891
            ],
            [
              -90.024924,
              43.183322
            ],
            [
              -89.994771,
              43.195354
            ],
            [
              -89.903925,
              43.18814
            ],
            [
              -89.858889,
              43.195581
            ],
            [
              -89.838135,
              43.206057
            ],
            [
              -89.805078,
              43.238942
            ],
            [
              -89.77424,
              43.239276
            ],
            [
              -89.731738,
              43.252203
            ],
            [
              -89.714429,
              43.278123
            ],
            [
              -89.720463,
              43.293084
            ],
            [
              -89.727171,
              43.301305
            ],
            [
              -89.71971,
              43.319745
            ],
            [
              -89.703832,
              43.330296
            ],
            [
              -89.677613,
              43.361197
            ],
            [
              -89.655755,
              43.359821
            ],
            [
              -89.617394,
              43.370399
            ],
            [
              -89.60073,
              43.380666
            ],
            [
              -89.599357,
              43.558041
            ],
            [
              -89.617719,
              43.565353
            ],
            [
              -89.642209,
              43.563179
            ],
            [
              -89.673239,
              43.56942
            ],
            [
              -89.707216,
              43.566759
            ],
            [
              -89.732238,
              43.571826
            ],
            [
              -89.732959,
              43.580977
            ],
            [
              -89.717335,
              43.596895
            ],
            [
              -89.758511,
              43.604111
            ],
            [
              -89.761166,
              43.603606
            ],
            [
              -89.774454,
              43.612329
            ],
            [
              -89.785809,
              43.641049
            ],
            [
              -89.789891,
              43.640995
            ],
            [
              -89.799663,
              43.640992
            ],
            [
              -89.838112,
              43.64093
            ],
            [
              -90.312404,
              43.640988
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "055",
      "COUNTYNS": "01581087",
      "AFFGEOID": "0500000US55055",
      "GEOID": "55055",
      "NAME": "Jefferson",
      "LSAD": "06",
      "ALAND": 1441198727,
      "AWATER": 68095317,
      "County_state": "Jefferson,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.013059,
              42.935349
            ],
            [
              -89.013582,
              42.84763
            ],
            [
              -88.918672,
              42.846121
            ],
            [
              -88.777076,
              42.842694
            ],
            [
              -88.771719,
              42.842825
            ],
            [
              -88.69812,
              42.842634
            ],
            [
              -88.659279,
              42.842541
            ],
            [
              -88.541535,
              42.842996
            ],
            [
              -88.542026,
              42.93094
            ],
            [
              -88.541579,
              43.019387
            ],
            [
              -88.539619,
              43.107605
            ],
            [
              -88.535843,
              43.196048
            ],
            [
              -88.535922,
              43.19605
            ],
            [
              -88.654247,
              43.197231
            ],
            [
              -88.693806,
              43.19717
            ],
            [
              -88.759829,
              43.197715
            ],
            [
              -88.970436,
              43.197966
            ],
            [
              -89.008832,
              43.197724
            ],
            [
              -89.009648,
              43.168722
            ],
            [
              -89.012343,
              43.023489
            ],
            [
              -89.013059,
              42.935349
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "085",
      "COUNTYNS": "01581103",
      "AFFGEOID": "0500000US55085",
      "GEOID": "55085",
      "NAME": "Oneida",
      "LSAD": "06",
      "ALAND": 2885082781,
      "AWATER": 317622270,
      "County_state": "Oneida,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.045198,
              45.817652
            ],
            [
              -90.043484,
              45.730362
            ],
            [
              -90.043414,
              45.555082
            ],
            [
              -89.548504,
              45.55573
            ],
            [
              -89.428258,
              45.555353
            ],
            [
              -89.424824,
              45.467166
            ],
            [
              -89.364049,
              45.469886
            ],
            [
              -89.125117,
              45.467361
            ],
            [
              -89.04649,
              45.464439
            ],
            [
              -89.046566,
              45.550518
            ],
            [
              -89.047601,
              45.895355
            ],
            [
              -89.121617,
              45.895463
            ],
            [
              -89.17785,
              45.899312
            ],
            [
              -89.175245,
              45.856579
            ],
            [
              -89.258009,
              45.861926
            ],
            [
              -89.300943,
              45.860689
            ],
            [
              -89.300473,
              45.901463
            ],
            [
              -89.425278,
              45.898347
            ],
            [
              -89.756801,
              45.898487
            ],
            [
              -90.023234,
              45.896916
            ],
            [
              -90.042857,
              45.897263
            ],
            [
              -90.045198,
              45.817652
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "081",
      "COUNTYNS": "01581101",
      "AFFGEOID": "0500000US55081",
      "GEOID": "55081",
      "NAME": "Monroe",
      "LSAD": "06",
      "ALAND": 2333327981,
      "AWATER": 18589237,
      "County_state": "Monroe,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.977279,
              44.129608
            ],
            [
              -90.973107,
              44.070882
            ],
            [
              -90.912918,
              44.071522
            ],
            [
              -90.910101,
              43.899253
            ],
            [
              -90.910653,
              43.725334
            ],
            [
              -90.607057,
              43.725985
            ],
            [
              -90.587213,
              43.729526
            ],
            [
              -90.584727,
              43.725807
            ],
            [
              -90.432832,
              43.725893
            ],
            [
              -90.312194,
              43.731479
            ],
            [
              -90.31224,
              43.894466
            ],
            [
              -90.312693,
              43.981335
            ],
            [
              -90.312694,
              43.981384
            ],
            [
              -90.312522,
              44.155198
            ],
            [
              -90.435732,
              44.161129
            ],
            [
              -90.553421,
              44.160215
            ],
            [
              -90.892909,
              44.158515
            ],
            [
              -90.947049,
              44.147237
            ],
            [
              -90.977279,
              44.129608
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "041",
      "COUNTYNS": "01581080",
      "AFFGEOID": "0500000US55041",
      "GEOID": "55041",
      "NAME": "Forest",
      "LSAD": "06",
      "ALAND": 2626878499,
      "AWATER": 83594186,
      "County_state": "Forest,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.047795,
              45.982345
            ],
            [
              -89.047601,
              45.895355
            ],
            [
              -89.046566,
              45.550518
            ],
            [
              -89.04649,
              45.464439
            ],
            [
              -88.923062,
              45.465137
            ],
            [
              -88.926025,
              45.378637
            ],
            [
              -88.677996,
              45.378683
            ],
            [
              -88.428102,
              45.37701
            ],
            [
              -88.42595,
              45.621453
            ],
            [
              -88.425302,
              45.722426
            ],
            [
              -88.675821,
              45.7229
            ],
            [
              -88.674604,
              45.982298
            ],
            [
              -88.6842,
              45.982449
            ],
            [
              -88.6833034397318,
              46.01416954199289
            ],
            [
              -88.691662,
              46.015435
            ],
            [
              -88.698716,
              46.017903
            ],
            [
              -88.704687,
              46.018154
            ],
            [
              -88.710328,
              46.016303
            ],
            [
              -88.713049,
              46.012668
            ],
            [
              -88.718397,
              46.013284
            ],
            [
              -88.721319,
              46.018608
            ],
            [
              -88.721125,
              46.022013
            ],
            [
              -88.724801,
              46.024503
            ],
            [
              -88.730675,
              46.026535
            ],
            [
              -88.739994,
              46.027308
            ],
            [
              -88.746422,
              46.025798
            ],
            [
              -88.752176,
              46.023584
            ],
            [
              -88.754033,
              46.02246
            ],
            [
              -88.756295,
              46.020173
            ],
            [
              -88.758618,
              46.019542
            ],
            [
              -88.760044,
              46.019815
            ],
            [
              -88.763767,
              46.021943
            ],
            [
              -88.765208,
              46.022086
            ],
            [
              -88.766156,
              46.022149
            ],
            [
              -88.767104,
              46.021896
            ],
            [
              -88.76761,
              46.021643
            ],
            [
              -88.768305,
              46.021201
            ],
            [
              -88.768692,
              46.020571
            ],
            [
              -88.769712,
              46.018968
            ],
            [
              -88.776187,
              46.015931
            ],
            [
              -88.779915,
              46.015436
            ],
            [
              -88.782104,
              46.016558
            ],
            [
              -88.783891,
              46.020934
            ],
            [
              -88.784007,
              46.022984
            ],
            [
              -88.783635,
              46.024357
            ],
            [
              -88.778734,
              46.028875
            ],
            [
              -88.778628,
              46.031271
            ],
            [
              -88.779221,
              46.031869
            ],
            [
              -88.784411,
              46.032709
            ],
            [
              -88.791796,
              46.032057
            ],
            [
              -88.796182,
              46.033712
            ],
            [
              -88.80067,
              46.030036
            ],
            [
              -88.796242,
              46.026853
            ],
            [
              -88.79579,
              46.024864
            ],
            [
              -88.79646,
              46.023605
            ],
            [
              -88.801761,
              46.023737
            ],
            [
              -88.811948,
              46.021609
            ],
            [
              -88.815629,
              46.02232
            ],
            [
              -88.815427,
              46.022954
            ],
            [
              -88.816489,
              46.023924
            ],
            [
              -88.820592,
              46.026261
            ],
            [
              -88.831544,
              46.02962
            ],
            [
              -88.835249,
              46.03033
            ],
            [
              -88.837991,
              46.030176
            ],
            [
              -88.840584,
              46.031112
            ],
            [
              -88.843903,
              46.03305
            ],
            [
              -88.847599,
              46.037161
            ],
            [
              -88.848464,
              46.038858
            ],
            [
              -88.85027,
              46.040274
            ],
            [
              -88.9327522855634,
              46.073679640474396
            ],
            [
              -88.933222,
              45.982276
            ],
            [
              -89.047795,
              45.982345
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "057",
      "COUNTYNS": "01581088",
      "AFFGEOID": "0500000US55057",
      "GEOID": "55057",
      "NAME": "Juneau",
      "LSAD": "06",
      "ALAND": 1986622513,
      "AWATER": 98737513,
      "County_state": "Juneau,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.312694,
              43.981384
            ],
            [
              -90.312693,
              43.981335
            ],
            [
              -90.31224,
              43.894466
            ],
            [
              -90.312194,
              43.731479
            ],
            [
              -90.312404,
              43.640988
            ],
            [
              -89.838112,
              43.64093
            ],
            [
              -89.799663,
              43.640992
            ],
            [
              -89.789891,
              43.640995
            ],
            [
              -89.785809,
              43.641049
            ],
            [
              -89.784901,
              43.641051
            ],
            [
              -89.787005,
              43.667091
            ],
            [
              -89.807672,
              43.677194
            ],
            [
              -89.819192,
              43.692583
            ],
            [
              -89.84125,
              43.736677
            ],
            [
              -89.843374,
              43.75805
            ],
            [
              -89.865132,
              43.783414
            ],
            [
              -89.889778,
              43.796714
            ],
            [
              -89.902839,
              43.814086
            ],
            [
              -89.916193,
              43.82152
            ],
            [
              -89.922308,
              43.838468
            ],
            [
              -89.961221,
              43.864027
            ],
            [
              -89.962948,
              43.883399
            ],
            [
              -89.980676,
              43.905623
            ],
            [
              -89.98316,
              43.915667
            ],
            [
              -89.952101,
              43.924197
            ],
            [
              -89.962711,
              43.966282
            ],
            [
              -89.962601,
              43.979236
            ],
            [
              -89.974295,
              43.99606
            ],
            [
              -90.005887,
              44.008391
            ],
            [
              -89.998977,
              44.024045
            ],
            [
              -90.018767,
              44.047719
            ],
            [
              -90.026272,
              44.089593
            ],
            [
              -90.004801,
              44.102677
            ],
            [
              -90.007056,
              44.121607
            ],
            [
              -89.991416,
              44.126725
            ],
            [
              -89.976919,
              44.122538
            ],
            [
              -89.970482,
              44.167114
            ],
            [
              -89.941713,
              44.163434
            ],
            [
              -89.93299,
              44.15036
            ],
            [
              -89.923387,
              44.152416
            ],
            [
              -89.92043,
              44.178581
            ],
            [
              -89.901502,
              44.180576
            ],
            [
              -89.903595,
              44.191434
            ],
            [
              -89.923811,
              44.190573
            ],
            [
              -89.905013,
              44.201604
            ],
            [
              -89.924833,
              44.213533
            ],
            [
              -89.897585,
              44.229844
            ],
            [
              -89.902783,
              44.249473
            ],
            [
              -90.080691,
              44.249011
            ],
            [
              -90.31268,
              44.24875
            ],
            [
              -90.312522,
              44.155198
            ],
            [
              -90.312694,
              43.981384
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "139",
      "COUNTYNS": "01581129",
      "AFFGEOID": "0500000US55139",
      "GEOID": "55139",
      "NAME": "Winnebago",
      "LSAD": "06",
      "ALAND": 1125258751,
      "AWATER": 373232751,
      "County_state": "Winnebago,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.886673,
              44.242622
            ],
            [
              -88.885984,
              44.069025
            ],
            [
              -88.886193,
              43.983233
            ],
            [
              -88.885697,
              43.895184
            ],
            [
              -88.764839,
              43.894899
            ],
            [
              -88.403195,
              43.892977
            ],
            [
              -88.404187,
              43.9382
            ],
            [
              -88.403309,
              43.979987
            ],
            [
              -88.40351,
              44.001286
            ],
            [
              -88.404109,
              44.051286
            ],
            [
              -88.404598,
              44.098344
            ],
            [
              -88.404429,
              44.127685
            ],
            [
              -88.404537,
              44.193699
            ],
            [
              -88.40407,
              44.244102
            ],
            [
              -88.436532,
              44.244308
            ],
            [
              -88.439099,
              44.244286
            ],
            [
              -88.524791,
              44.244028
            ],
            [
              -88.557424,
              44.243709
            ],
            [
              -88.645189,
              44.243597
            ],
            [
              -88.73977,
              44.243303
            ],
            [
              -88.7662,
              44.243366
            ],
            [
              -88.886673,
              44.242622
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "009",
      "COUNTYNS": "01581064",
      "AFFGEOID": "0500000US55009",
      "GEOID": "55009",
      "NAME": "Brown",
      "LSAD": "06",
      "ALAND": 1372929582,
      "AWATER": 221812520,
      "County_state": "Brown,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.252562,
              44.679813
            ],
            [
              -88.242833,
              44.660773
            ],
            [
              -88.245204,
              44.584746
            ],
            [
              -88.190465,
              44.586799
            ],
            [
              -88.191432,
              44.391586
            ],
            [
              -88.191494,
              44.347253
            ],
            [
              -88.191278,
              44.328155
            ],
            [
              -88.19229,
              44.328157
            ],
            [
              -88.192489,
              44.322852
            ],
            [
              -88.192603,
              44.316088
            ],
            [
              -88.191116,
              44.305898
            ],
            [
              -88.192611,
              44.241947
            ],
            [
              -88.04324,
              44.241018
            ],
            [
              -87.923755,
              44.240402
            ],
            [
              -87.888087,
              44.240461
            ],
            [
              -87.887526,
              44.327589
            ],
            [
              -87.766026,
              44.327184
            ],
            [
              -87.765316,
              44.414503
            ],
            [
              -87.762376099947,
              44.644501238507296
            ],
            [
              -87.765774,
              44.642023
            ],
            [
              -87.77516,
              44.639281
            ],
            [
              -87.808819,
              44.636338
            ],
            [
              -87.830848,
              44.623583
            ],
            [
              -87.866884,
              44.608434
            ],
            [
              -87.891717,
              44.588982
            ],
            [
              -87.901179,
              44.584545
            ],
            [
              -87.903689,
              44.581317
            ],
            [
              -87.89924044076139,
              44.574662229649796
            ],
            [
              -87.898888,
              44.574135
            ],
            [
              -87.901177,
              44.568925
            ],
            [
              -87.917,
              44.548093
            ],
            [
              -87.929001,
              44.535993
            ],
            [
              -87.9394993392888,
              44.5315241123297
            ],
            [
              -87.943801,
              44.529693
            ],
            [
              -87.970702,
              44.530292
            ],
            [
              -88.005518,
              44.539216
            ],
            [
              -88.0353636490184,
              44.5613583718175
            ],
            [
              -88.042414,
              44.566589
            ],
            [
              -88.041202,
              44.572581
            ],
            [
              -88.036103,
              44.576792
            ],
            [
              -88.027103,
              44.578992
            ],
            [
              -88.016404,
              44.592092
            ],
            [
              -88.012395,
              44.602438
            ],
            [
              -88.001943,
              44.603909
            ],
            [
              -87.998716,
              44.609288
            ],
            [
              -88.009463,
              44.630398
            ],
            [
              -88.009766,
              44.637081
            ],
            [
              -88.002085,
              44.664035
            ],
            [
              -87.99011,
              44.668455
            ],
            [
              -87.989912015149,
              44.6775869882811
            ],
            [
              -88.131182,
              44.676928
            ],
            [
              -88.227976,
              44.672361
            ],
            [
              -88.231178,
              44.672361
            ],
            [
              -88.230958,
              44.679558
            ],
            [
              -88.242687,
              44.679631
            ],
            [
              -88.252562,
              44.679813
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "049",
      "COUNTYNS": "01581084",
      "AFFGEOID": "0500000US55049",
      "GEOID": "55049",
      "NAME": "Iowa",
      "LSAD": "06",
      "ALAND": 1975379853,
      "AWATER": 13870296,
      "County_state": "Iowa,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.429825,
              43.20094
            ],
            [
              -90.429617,
              43.186745
            ],
            [
              -90.429577,
              43.182989
            ],
            [
              -90.426902,
              42.812862
            ],
            [
              -90.073092,
              42.813837
            ],
            [
              -89.83841,
              42.813799
            ],
            [
              -89.838167,
              42.857397
            ],
            [
              -89.838228,
              43.074701
            ],
            [
              -89.837963,
              43.118577
            ],
            [
              -89.838135,
              43.206057
            ],
            [
              -89.858889,
              43.195581
            ],
            [
              -89.903925,
              43.18814
            ],
            [
              -89.994771,
              43.195354
            ],
            [
              -90.024924,
              43.183322
            ],
            [
              -90.035005,
              43.172891
            ],
            [
              -90.048613,
              43.166882
            ],
            [
              -90.05438,
              43.146301
            ],
            [
              -90.082387,
              43.161143
            ],
            [
              -90.113726,
              43.160344
            ],
            [
              -90.158983,
              43.166794
            ],
            [
              -90.184312,
              43.15756
            ],
            [
              -90.193814,
              43.164464
            ],
            [
              -90.218447,
              43.173234
            ],
            [
              -90.26068,
              43.19777
            ],
            [
              -90.278369,
              43.19409
            ],
            [
              -90.297802,
              43.208211
            ],
            [
              -90.31113,
              43.206677
            ],
            [
              -90.350513,
              43.209786
            ],
            [
              -90.409772,
              43.200145
            ],
            [
              -90.429825,
              43.20094
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "55",
      "COUNTYFP": "117",
      "COUNTYNS": "01581118",
      "AFFGEOID": "0500000US55117",
      "GEOID": "55117",
      "NAME": "Sheboygan",
      "LSAD": "06",
      "ALAND": 1324905935,
      "AWATER": 1967592839,
      "County_state": "Sheboygan,55"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.162274,
              43.891511
            ],
            [
              -88.160873,
              43.542943
            ],
            [
              -88.040528,
              43.542361
            ],
            [
              -87.7943205824754,
              43.543034
            ],
            [
              -87.790135,
              43.563054
            ],
            [
              -87.781255,
              43.578493
            ],
            [
              -87.734312,
              43.63919
            ],
            [
              -87.706204,
              43.679542
            ],
            [
              -87.702685,
              43.687596
            ],
            [
              -87.702985,
              43.706395
            ],
            [
              -87.708185,
              43.722895
            ],
            [
              -87.709885,
              43.735795
            ],
            [
              -87.708285,
              43.742895
            ],
            [
              -87.705185,
              43.745095
            ],
            [
              -87.702985,
              43.749695
            ],
            [
              -87.7002650271973,
              43.760668683376394
            ],
            [
              -87.700085,
              43.761395
            ],
            [
              -87.700251,
              43.76735
            ],
            [
              -87.726407,
              43.810445
            ],
            [
              -87.7296,
              43.831782
            ],
            [
              -87.728698,
              43.852524
            ],
            [
              -87.736017,
              43.873721
            ],
            [
              -87.736178,
              43.880421
            ],
            [
              -87.7313223858007,
              43.8921842757007
            ],
            [
              -88.041794,
              43.891692
            ],
            [
              -88.162274,
              43.891511
            ]
          ]
        ]
      ]
    }
  }
]
};