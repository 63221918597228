export const counties_az = {
  "type":"FeatureCollection","name":"az_counties","features":[
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "04",
      "COUNTYFP": "015",
      "COUNTYNS": "00025445",
      "AFFGEOID": "0500000US04015",
      "GEOID": "04015",
      "NAME": "Mohave",
      "LSAD": "06",
      "ALAND": 34475503964,
      "AWATER": 387344307,
      "County_state": "Mohave,04"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -114.755618,
              36.087166
            ],
            [
              -114.755491,
              36.081601
            ],
            [
              -114.754099,
              36.07944
            ],
            [
              -114.743342,
              36.070535
            ],
            [
              -114.740554803334,
              36.0657913792102
            ],
            [
              -114.736253,
              36.05847
            ],
            [
              -114.736738,
              36.054349
            ],
            [
              -114.740375,
              36.049258
            ],
            [
              -114.740375,
              36.043682
            ],
            [
              -114.740617,
              36.041015
            ],
            [
              -114.739405,
              36.037863
            ],
            [
              -114.734314,
              36.035681
            ],
            [
              -114.730435,
              36.031317
            ],
            [
              -114.729707,
              36.028166
            ],
            [
              -114.731162,
              36.021862
            ],
            [
              -114.737346952926,
              36.016228142238695
            ],
            [
              -114.740522,
              36.013336
            ],
            [
              -114.742779,
              36.009963
            ],
            [
              -114.743243,
              36.00653
            ],
            [
              -114.743756,
              35.985095
            ],
            [
              -114.740595,
              35.975656
            ],
            [
              -114.729941,
              35.962183
            ],
            [
              -114.728318,
              35.95629
            ],
            [
              -114.731159,
              35.943916
            ],
            [
              -114.729356,
              35.941413
            ],
            [
              -114.715692,
              35.934709
            ],
            [
              -114.707526,
              35.92806
            ],
            [
              -114.708516,
              35.912313
            ],
            [
              -114.700271,
              35.901772
            ],
            [
              -114.667194,
              35.874787
            ],
            [
              -114.669687,
              35.865084
            ],
            [
              -114.68201,
              35.863284
            ],
            [
              -114.697767,
              35.854844
            ],
            [
              -114.699848,
              35.84837
            ],
            [
              -114.699848,
              35.843283
            ],
            [
              -114.69641,
              35.833784
            ],
            [
              -114.69571,
              35.830601
            ],
            [
              -114.70371,
              35.814585
            ],
            [
              -114.70991,
              35.810185
            ],
            [
              -114.71211,
              35.806185
            ],
            [
              -114.69891,
              35.790185
            ],
            [
              -114.701409,
              35.769086
            ],
            [
              -114.695709,
              35.755986
            ],
            [
              -114.697309,
              35.733686
            ],
            [
              -114.705309,
              35.711587
            ],
            [
              -114.705409,
              35.708287
            ],
            [
              -114.701208,
              35.701187
            ],
            [
              -114.694108,
              35.695187
            ],
            [
              -114.683208,
              35.689387
            ],
            [
              -114.680607,
              35.685488
            ],
            [
              -114.682207,
              35.678188
            ],
            [
              -114.690008,
              35.664688
            ],
            [
              -114.689407,
              35.651412
            ],
            [
              -114.677107,
              35.641489
            ],
            [
              -114.658206,
              35.619089
            ],
            [
              -114.653406,
              35.610789
            ],
            [
              -114.654306,
              35.59759
            ],
            [
              -114.659606,
              35.58749
            ],
            [
              -114.665649,
              35.580428
            ],
            [
              -114.666184,
              35.577576
            ],
            [
              -114.663005,
              35.56369
            ],
            [
              -114.662005,
              35.545491
            ],
            [
              -114.660205,
              35.539291
            ],
            [
              -114.657405,
              35.536391
            ],
            [
              -114.656905,
              35.534391
            ],
            [
              -114.658005,
              35.530491
            ],
            [
              -114.663105,
              35.524491
            ],
            [
              -114.673805,
              35.517891
            ],
            [
              -114.677205,
              35.513491
            ],
            [
              -114.679205,
              35.499992
            ],
            [
              -114.677643,
              35.489742
            ],
            [
              -114.672901,
              35.481708
            ],
            [
              -114.666377,
              35.466856
            ],
            [
              -114.6645,
              35.449497
            ],
            [
              -114.662125,
              35.444241
            ],
            [
              -114.652005,
              35.429165
            ],
            [
              -114.627137,
              35.409504
            ],
            [
              -114.611435,
              35.369056
            ],
            [
              -114.604314,
              35.353584
            ],
            [
              -114.595931,
              35.325234
            ],
            [
              -114.597503,
              35.296954
            ],
            [
              -114.587129,
              35.262376
            ],
            [
              -114.583111,
              35.23809
            ],
            [
              -114.583559,
              35.22993
            ],
            [
              -114.579963,
              35.20964
            ],
            [
              -114.574835,
              35.205898
            ],
            [
              -114.572119,
              35.200591
            ],
            [
              -114.569238,
              35.18348
            ],
            [
              -114.569569,
              35.163053
            ],
            [
              -114.572747,
              35.138725
            ],
            [
              -114.578524,
              35.12875
            ],
            [
              -114.58774,
              35.123729
            ],
            [
              -114.59912,
              35.12105
            ],
            [
              -114.619905,
              35.121632
            ],
            [
              -114.629934,
              35.118272
            ],
            [
              -114.644352,
              35.105904
            ],
            [
              -114.646759,
              35.101872
            ],
            [
              -114.642831,
              35.096503
            ],
            [
              -114.622517,
              35.088703
            ],
            [
              -114.613132,
              35.083097
            ],
            [
              -114.604736,
              35.07483
            ],
            [
              -114.602908,
              35.068588
            ],
            [
              -114.603619,
              35.064226
            ],
            [
              -114.606694,
              35.058941
            ],
            [
              -114.627124,
              35.044721
            ],
            [
              -114.632429,
              35.037586
            ],
            [
              -114.636893,
              35.028367
            ],
            [
              -114.638023,
              35.020556
            ],
            [
              -114.636674,
              35.008807
            ],
            [
              -114.633487,
              35.001857
            ],
            [
              -114.629928,
              34.99474
            ],
            [
              -114.62919,
              34.991887
            ],
            [
              -114.629015,
              34.986148
            ],
            [
              -114.629907,
              34.980791
            ],
            [
              -114.634607,
              34.96906
            ],
            [
              -114.635237,
              34.965149
            ],
            [
              -114.634953,
              34.958918
            ],
            [
              -114.631681,
              34.95131
            ],
            [
              -114.629811,
              34.94481
            ],
            [
              -114.629769,
              34.94304
            ],
            [
              -114.629753,
              34.938684
            ],
            [
              -114.632196,
              34.930628
            ],
            [
              -114.633253,
              34.924608
            ],
            [
              -114.633237,
              34.92123
            ],
            [
              -114.630552,
              34.911852
            ],
            [
              -114.630877,
              34.907263
            ],
            [
              -114.635425,
              34.895192
            ],
            [
              -114.636725,
              34.889107
            ],
            [
              -114.636768,
              34.885705
            ],
            [
              -114.635176,
              34.875003
            ],
            [
              -114.634382,
              34.87289
            ],
            [
              -114.630682,
              34.866352
            ],
            [
              -114.623939,
              34.859738
            ],
            [
              -114.619878,
              34.856873
            ],
            [
              -114.604255,
              34.849573
            ],
            [
              -114.600653,
              34.847361
            ],
            [
              -114.592339,
              34.841153
            ],
            [
              -114.586842,
              34.835672
            ],
            [
              -114.581126,
              34.826115
            ],
            [
              -114.576452,
              34.8153
            ],
            [
              -114.574569,
              34.805746
            ],
            [
              -114.57101,
              34.794294
            ],
            [
              -114.558653,
              34.773852
            ],
            [
              -114.552682,
              34.766871
            ],
            [
              -114.546884,
              34.761802
            ],
            [
              -114.540306,
              34.757109
            ],
            [
              -114.529615,
              34.750822
            ],
            [
              -114.525611,
              34.747005
            ],
            [
              -114.522619,
              34.74373
            ],
            [
              -114.521048,
              34.741173
            ],
            [
              -114.516619,
              34.736745
            ],
            [
              -114.510292,
              34.733582
            ],
            [
              -114.503361,
              34.731247
            ],
            [
              -114.495858,
              34.727956
            ],
            [
              -114.492017,
              34.725702
            ],
            [
              -114.490971,
              34.724848
            ],
            [
              -114.486768,
              34.7191
            ],
            [
              -114.481954,
              34.716036
            ],
            [
              -114.477297,
              34.714514
            ],
            [
              -114.473682,
              34.713964
            ],
            [
              -114.47162,
              34.712966
            ],
            [
              -114.470477,
              34.711368
            ],
            [
              -114.46862,
              34.707573
            ],
            [
              -114.46809,
              34.701786
            ],
            [
              -114.465246,
              34.691202
            ],
            [
              -114.462178,
              34.6858
            ],
            [
              -114.456567,
              34.677956
            ],
            [
              -114.455473,
              34.675768
            ],
            [
              -114.45491,
              34.673092
            ],
            [
              -114.454305,
              34.671234
            ],
            [
              -114.452628,
              34.668546
            ],
            [
              -114.451971,
              34.666795
            ],
            [
              -114.451753,
              34.665044
            ],
            [
              -114.451785,
              34.663891
            ],
            [
              -114.452628,
              34.659573
            ],
            [
              -114.451971,
              34.657166
            ],
            [
              -114.451753,
              34.654321
            ],
            [
              -114.449549,
              34.651423
            ],
            [
              -114.444276,
              34.646542
            ],
            [
              -114.441465,
              34.64253
            ],
            [
              -114.440294,
              34.63824
            ],
            [
              -114.441525,
              34.631529
            ],
            [
              -114.441398,
              34.630171
            ],
            [
              -114.438739,
              34.621455
            ],
            [
              -114.428648,
              34.614641
            ],
            [
              -114.424202,
              34.610453
            ],
            [
              -114.424326,
              34.602338
            ],
            [
              -114.425338,
              34.600842
            ],
            [
              -114.427502,
              34.599227
            ],
            [
              -114.43009,
              34.596874
            ],
            [
              -114.429747,
              34.595846
            ],
            [
              -114.429747,
              34.591734
            ],
            [
              -114.422382,
              34.580711
            ],
            [
              -114.405228,
              34.569637
            ],
            [
              -114.389603,
              34.542982
            ],
            [
              -114.380838,
              34.529724
            ],
            [
              -114.378223,
              34.516521
            ],
            [
              -114.378124,
              34.507288
            ],
            [
              -114.381402,
              34.499242
            ],
            [
              -114.382358,
              34.495757
            ],
            [
              -114.383038,
              34.488903
            ],
            [
              -114.381555,
              34.477883
            ],
            [
              -114.381701,
              34.47604
            ],
            [
              -114.383525,
              34.470405
            ],
            [
              -114.387187,
              34.462021
            ],
            [
              -114.387407,
              34.460492
            ],
            [
              -114.386699,
              34.457911
            ],
            [
              -114.378852,
              34.450376
            ],
            [
              -114.375789,
              34.447798
            ],
            [
              -114.373719,
              34.446938
            ],
            [
              -114.363404,
              34.447773
            ],
            [
              -114.356025,
              34.449744
            ],
            [
              -114.342615,
              34.451442
            ],
            [
              -114.339627,
              34.451435
            ],
            [
              -114.335372,
              34.450038
            ],
            [
              -114.332991,
              34.448082
            ],
            [
              -114.330669,
              34.445295
            ],
            [
              -114.32613,
              34.437251
            ],
            [
              -114.319054,
              34.435831
            ],
            [
              -114.312251,
              34.432726
            ],
            [
              -114.301016,
              34.426807
            ],
            [
              -114.294836,
              34.421389
            ],
            [
              -114.292226,
              34.417606
            ],
            [
              -114.291751,
              34.411104
            ],
            [
              -114.290219,
              34.408291
            ],
            [
              -114.288663,
              34.406623
            ],
            [
              -114.286802,
              34.40534
            ],
            [
              -114.280108,
              34.403147
            ],
            [
              -114.267521,
              34.402486
            ],
            [
              -114.264317,
              34.401329
            ],
            [
              -114.252739,
              34.3901
            ],
            [
              -114.248649,
              34.388113
            ],
            [
              -114.245261,
              34.385659
            ],
            [
              -114.234275,
              34.376662
            ],
            [
              -114.229686,
              34.368908
            ],
            [
              -114.226107,
              34.365916
            ],
            [
              -114.213774,
              34.36246
            ],
            [
              -114.199482,
              34.361373
            ],
            [
              -114.191094,
              34.356125
            ],
            [
              -114.185556,
              34.354386
            ],
            [
              -114.181145,
              34.352186
            ],
            [
              -114.176909,
              34.349306
            ],
            [
              -114.172845,
              34.344979
            ],
            [
              -114.168807,
              34.339513
            ],
            [
              -114.157206,
              34.317862
            ],
            [
              -114.14093,
              34.305919
            ],
            [
              -114.138282,
              34.30323
            ],
            [
              -114.137522,
              34.303772
            ],
            [
              -114.126392,
              34.301915
            ],
            [
              -114.116681,
              34.296531
            ],
            [
              -114.102494,
              34.298421
            ],
            [
              -114.088855,
              34.298906
            ],
            [
              -114.079725,
              34.292114
            ],
            [
              -114.074133,
              34.292323
            ],
            [
              -114.067296,
              34.289022
            ],
            [
              -114.06183,
              34.283359
            ],
            [
              -114.057212,
              34.279294
            ],
            [
              -114.05568,
              34.27844
            ],
            [
              -114.052507,
              34.277986
            ],
            [
              -114.049841,
              34.274509
            ],
            [
              -114.047101,
              34.272141
            ],
            [
              -114.04647,
              34.269592
            ],
            [
              -114.044655,
              34.268315
            ],
            [
              -114.043232,
              34.267563
            ],
            [
              -114.039534,
              34.264857
            ],
            [
              -114.03735,
              34.263557
            ],
            [
              -114.037259,
              34.262224
            ],
            [
              -114.036732,
              34.260606
            ],
            [
              -114.035458,
              34.259918
            ],
            [
              -114.032399,
              34.259508
            ],
            [
              -114.029099,
              34.261334
            ],
            [
              -114.027194,
              34.263244
            ],
            [
              -114.025972,
              34.2671
            ],
            [
              -114.02313,
              34.267869
            ],
            [
              -114.02022,
              34.266006
            ],
            [
              -114.017975,
              34.264247
            ],
            [
              -114.014154,
              34.259547
            ],
            [
              -114.013089,
              34.258552
            ],
            [
              -114.011751,
              34.256286
            ],
            [
              -114.007882,
              34.2547
            ],
            [
              -114.003489,
              34.253639
            ],
            [
              -114.00107,
              34.25426
            ],
            [
              -113.999664,
              34.254957
            ],
            [
              -113.99649,
              34.254933
            ],
            [
              -113.98922,
              34.254744
            ],
            [
              -113.98304,
              34.255956
            ],
            [
              -113.972919,
              34.257637
            ],
            [
              -113.952206,
              34.257218
            ],
            [
              -113.943678,
              34.255733
            ],
            [
              -113.933135,
              34.251099
            ],
            [
              -113.92716,
              34.249343
            ],
            [
              -113.922813,
              34.249378
            ],
            [
              -113.908374,
              34.24861
            ],
            [
              -113.903358,
              34.24845
            ],
            [
              -113.899518,
              34.248636
            ],
            [
              -113.89425,
              34.249092
            ],
            [
              -113.886333,
              34.251011
            ],
            [
              -113.880156,
              34.252293
            ],
            [
              -113.878719,
              34.252971
            ],
            [
              -113.875983,
              34.254414
            ],
            [
              -113.867796,
              34.254612
            ],
            [
              -113.861614,
              34.25352
            ],
            [
              -113.859152,
              34.25351
            ],
            [
              -113.853287,
              34.252087
            ],
            [
              -113.851529,
              34.250745
            ],
            [
              -113.849846,
              34.244037
            ],
            [
              -113.843556,
              34.235
            ],
            [
              -113.840436,
              34.227865
            ],
            [
              -113.838713,
              34.224525
            ],
            [
              -113.833002,
              34.223441
            ],
            [
              -113.825956,
              34.225344
            ],
            [
              -113.822716,
              34.226673
            ],
            [
              -113.820701,
              34.22804
            ],
            [
              -113.819813,
              34.229363
            ],
            [
              -113.819576,
              34.232274
            ],
            [
              -113.817854,
              34.234524
            ],
            [
              -113.814468,
              34.234032
            ],
            [
              -113.813194,
              34.232565
            ],
            [
              -113.810198,
              34.232751
            ],
            [
              -113.808127,
              34.233107
            ],
            [
              -113.804904,
              34.234573
            ],
            [
              -113.801019,
              34.236227
            ],
            [
              -113.79862,
              34.235812
            ],
            [
              -113.792578,
              34.235708
            ],
            [
              -113.786204,
              34.232306
            ],
            [
              -113.778158,
              34.228956
            ],
            [
              -113.774015,
              34.22927
            ],
            [
              -113.772472,
              34.230086
            ],
            [
              -113.770307,
              34.231703
            ],
            [
              -113.767488,
              34.232959
            ],
            [
              -113.763797,
              34.233485
            ],
            [
              -113.76157,
              34.234658
            ],
            [
              -113.759342,
              34.235812
            ],
            [
              -113.757606,
              34.236648
            ],
            [
              -113.756486,
              34.236776
            ],
            [
              -113.754243,
              34.237284
            ],
            [
              -113.752738,
              34.23778
            ],
            [
              -113.751683,
              34.238107
            ],
            [
              -113.749729,
              34.238129
            ],
            [
              -113.748254,
              34.238375
            ],
            [
              -113.746901,
              34.238872
            ],
            [
              -113.740509,
              34.240296
            ],
            [
              -113.727965,
              34.235236
            ],
            [
              -113.724315,
              34.228444
            ],
            [
              -113.724317,
              34.226178
            ],
            [
              -113.723421,
              34.224635
            ],
            [
              -113.722954,
              34.223348
            ],
            [
              -113.721705,
              34.222821
            ],
            [
              -113.718637,
              34.222341
            ],
            [
              -113.715896,
              34.221871
            ],
            [
              -113.714573,
              34.22103
            ],
            [
              -113.714761,
              34.218891
            ],
            [
              -113.713689,
              34.217502
            ],
            [
              -113.711347,
              34.216867
            ],
            [
              -113.709485,
              34.216128
            ],
            [
              -113.706529,
              34.213412
            ],
            [
              -113.703035,
              34.210143
            ],
            [
              -113.697458,
              34.210336
            ],
            [
              -113.692729,
              34.21037
            ],
            [
              -113.685656,
              34.210194
            ],
            [
              -113.68061,
              34.21011
            ],
            [
              -113.678766,
              34.210398
            ],
            [
              -113.676985,
              34.211303
            ],
            [
              -113.675685,
              34.212805
            ],
            [
              -113.672853,
              34.213075
            ],
            [
              -113.67031,
              34.212975
            ],
            [
              -113.668538,
              34.213476
            ],
            [
              -113.666274,
              34.21357
            ],
            [
              -113.664844,
              34.212511
            ],
            [
              -113.663579,
              34.212523
            ],
            [
              -113.661779,
              34.214594
            ],
            [
              -113.659696,
              34.216317
            ],
            [
              -113.65899,
              34.217382
            ],
            [
              -113.657488,
              34.217182
            ],
            [
              -113.656865,
              34.216541
            ],
            [
              -113.655376,
              34.216524
            ],
            [
              -113.654005,
              34.217267
            ],
            [
              -113.651792,
              34.219499
            ],
            [
              -113.649618,
              34.221468
            ],
            [
              -113.648306,
              34.222022
            ],
            [
              -113.646719,
              34.221727
            ],
            [
              -113.646442,
              34.221246
            ],
            [
              -113.64515,
              34.221275
            ],
            [
              -113.644747,
              34.221783
            ],
            [
              -113.643972,
              34.223254
            ],
            [
              -113.643614,
              34.224559
            ],
            [
              -113.644284,
              34.225294
            ],
            [
              -113.643795,
              34.226693
            ],
            [
              -113.642794,
              34.22716
            ],
            [
              -113.641958,
              34.227508
            ],
            [
              -113.640739,
              34.227355
            ],
            [
              -113.640221,
              34.226059
            ],
            [
              -113.639404,
              34.224166
            ],
            [
              -113.639201,
              34.223539
            ],
            [
              -113.637909,
              34.223404
            ],
            [
              -113.636899,
              34.224226
            ],
            [
              -113.635437,
              34.224733
            ],
            [
              -113.633326,
              34.224758
            ],
            [
              -113.63112,
              34.226139
            ],
            [
              -113.62965,
              34.227125
            ],
            [
              -113.6277,
              34.228569
            ],
            [
              -113.626153,
              34.229123
            ],
            [
              -113.62463,
              34.228273
            ],
            [
              -113.622074,
              34.227885
            ],
            [
              -113.618785,
              34.227574
            ],
            [
              -113.616913,
              34.227528
            ],
            [
              -113.614347,
              34.228915
            ],
            [
              -113.612729,
              34.229645
            ],
            [
              -113.612394,
              34.230617
            ],
            [
              -113.610853,
              34.231215
            ],
            [
              -113.609199,
              34.231237
            ],
            [
              -113.608137,
              34.231523
            ],
            [
              -113.607061,
              34.230898
            ],
            [
              -113.606587,
              34.23006
            ],
            [
              -113.605575,
              34.229375
            ],
            [
              -113.604436,
              34.229864
            ],
            [
              -113.603715,
              34.230571
            ],
            [
              -113.60265,
              34.23136
            ],
            [
              -113.601583,
              34.232435
            ],
            [
              -113.59854,
              34.23496
            ],
            [
              -113.593753,
              34.23885
            ],
            [
              -113.58699,
              34.243274
            ],
            [
              -113.583584,
              34.256628
            ],
            [
              -113.570938,
              34.268492
            ],
            [
              -113.568758,
              34.274227
            ],
            [
              -113.563713,
              34.277637
            ],
            [
              -113.55243,
              34.288404
            ],
            [
              -113.54861,
              34.293771
            ],
            [
              -113.547095,
              34.296028
            ],
            [
              -113.539468,
              34.306404
            ],
            [
              -113.537488,
              34.307359
            ],
            [
              -113.535012,
              34.308275
            ],
            [
              -113.533556,
              34.308723
            ],
            [
              -113.532476,
              34.309466
            ],
            [
              -113.530282,
              34.310264
            ],
            [
              -113.525854,
              34.310379
            ],
            [
              -113.522882,
              34.308888
            ],
            [
              -113.519933,
              34.309535
            ],
            [
              -113.519794,
              34.310103
            ],
            [
              -113.518979,
              34.310718
            ],
            [
              -113.518159,
              34.310572
            ],
            [
              -113.516428,
              34.310041
            ],
            [
              -113.514463,
              34.30826
            ],
            [
              -113.513354,
              34.306774
            ],
            [
              -113.511579,
              34.305076
            ],
            [
              -113.509425,
              34.30473
            ],
            [
              -113.50545,
              34.30393
            ],
            [
              -113.503809,
              34.303259
            ],
            [
              -113.501095,
              34.302826
            ],
            [
              -113.493405,
              34.303373
            ],
            [
              -113.480404,
              34.303611
            ],
            [
              -113.472918,
              34.302563
            ],
            [
              -113.463021,
              34.302853
            ],
            [
              -113.452318,
              34.300003
            ],
            [
              -113.442112,
              34.299648
            ],
            [
              -113.433143,
              34.301514
            ],
            [
              -113.420621,
              34.299133
            ],
            [
              -113.416904,
              34.296642
            ],
            [
              -113.415589,
              34.295398
            ],
            [
              -113.412623,
              34.294545
            ],
            [
              -113.407932,
              34.295998
            ],
            [
              -113.393431,
              34.298541
            ],
            [
              -113.362441,
              34.294051
            ],
            [
              -113.345837,
              34.308662
            ],
            [
              -113.342584,
              34.314524
            ],
            [
              -113.336376,
              34.317501
            ],
            [
              -113.333508,
              34.317877
            ],
            [
              -113.333026,
              34.951631
            ],
            [
              -113.334105,
              35.508416
            ],
            [
              -113.334161,
              35.528037
            ],
            [
              -113.334024,
              35.803031
            ],
            [
              -113.333153,
              35.805369
            ],
            [
              -113.331764,
              35.816187
            ],
            [
              -113.333103,
              35.820429
            ],
            [
              -113.328193,
              35.826997
            ],
            [
              -113.3258,
              35.830136
            ],
            [
              -113.327105,
              35.83368
            ],
            [
              -113.327073,
              35.837862
            ],
            [
              -113.321648,
              35.848234
            ],
            [
              -113.313197,
              35.857695
            ],
            [
              -113.311094,
              35.861771
            ],
            [
              -113.312246,
              35.869876
            ],
            [
              -113.309902,
              35.88349
            ],
            [
              -113.316412,
              35.895588
            ],
            [
              -113.322611,
              35.898563
            ],
            [
              -113.324285,
              35.902945
            ],
            [
              -113.328222,
              35.908107
            ],
            [
              -113.33442,
              35.913133
            ],
            [
              -113.334141,
              35.918719
            ],
            [
              -113.330129,
              35.923999
            ],
            [
              -113.328467,
              35.927809
            ],
            [
              -113.327246,
              35.931596
            ],
            [
              -113.325355,
              35.937841
            ],
            [
              -113.328691,
              35.949029
            ],
            [
              -113.323952,
              35.955961
            ],
            [
              -113.317197,
              35.961531
            ],
            [
              -113.318673,
              35.966929
            ],
            [
              -113.317304,
              35.968453
            ],
            [
              -113.317964,
              35.970159
            ],
            [
              -113.319036,
              35.972875
            ],
            [
              -113.321502,
              35.975669
            ],
            [
              -113.335076,
              35.980079
            ],
            [
              -113.339777,
              35.98515
            ],
            [
              -113.341379,
              35.991333
            ],
            [
              -113.340946,
              35.994648
            ],
            [
              -113.340025,
              35.99937
            ],
            [
              -113.342286,
              36.005454
            ],
            [
              -113.343078,
              36.008771
            ],
            [
              -113.348769,
              36.016874
            ],
            [
              -113.350175,
              36.021373
            ],
            [
              -113.351507,
              36.033256
            ],
            [
              -113.350337,
              36.050465
            ],
            [
              -113.34744,
              36.055039
            ],
            [
              -113.341384,
              36.06025
            ],
            [
              -113.331965,
              36.067199
            ],
            [
              -113.326173,
              36.078424
            ],
            [
              -113.324254,
              36.087031
            ],
            [
              -113.323156,
              36.091625
            ],
            [
              -113.308826,
              36.099558
            ],
            [
              -113.2964,
              36.095811
            ],
            [
              -113.279247,
              36.097336
            ],
            [
              -113.268258,
              36.092313
            ],
            [
              -113.26195,
              36.090991
            ],
            [
              -113.258773,
              36.089323
            ],
            [
              -113.253588,
              36.089636
            ],
            [
              -113.250887,
              36.091406
            ],
            [
              -113.242609,
              36.092705
            ],
            [
              -113.232904,
              36.089948
            ],
            [
              -113.222887,
              36.092972
            ],
            [
              -113.221475,
              36.096741
            ],
            [
              -113.2167,
              36.104988
            ],
            [
              -113.212715,
              36.110247
            ],
            [
              -113.20538,
              36.11519
            ],
            [
              -113.202028,
              36.121202
            ],
            [
              -113.205277,
              36.133132
            ],
            [
              -113.20263,
              36.141149
            ],
            [
              -113.200697,
              36.145647
            ],
            [
              -113.200279,
              36.150454
            ],
            [
              -113.18719,
              36.157056
            ],
            [
              -113.176497,
              36.160681
            ],
            [
              -113.167712,
              36.165923
            ],
            [
              -113.165685,
              36.169755
            ],
            [
              -113.157354,
              36.174125
            ],
            [
              -113.145632,
              36.172823
            ],
            [
              -113.140933,
              36.168887
            ],
            [
              -113.137707,
              36.166589
            ],
            [
              -113.133643,
              36.167655
            ],
            [
              -113.1258,
              36.17051
            ],
            [
              -113.119728,
              36.175603
            ],
            [
              -113.115353,
              36.182181
            ],
            [
              -113.10679,
              36.187115
            ],
            [
              -113.10438,
              36.188364
            ],
            [
              -113.093208,
              36.194226
            ],
            [
              -113.085236,
              36.196714
            ],
            [
              -113.081911,
              36.198099
            ],
            [
              -113.072655,
              36.202874
            ],
            [
              -113.056491,
              36.207991
            ],
            [
              -113.05335,
              36.209075
            ],
            [
              -113.050882,
              36.211655
            ],
            [
              -113.047754,
              36.215558
            ],
            [
              -113.043218,
              36.221625
            ],
            [
              -113.039399,
              36.225588
            ],
            [
              -113.036406,
              36.228072
            ],
            [
              -113.031357,
              36.230666
            ],
            [
              -113.023824,
              36.23391
            ],
            [
              -113.019111,
              36.236705
            ],
            [
              -113.016317,
              36.237402
            ],
            [
              -113.012822,
              36.238473
            ],
            [
              -113.00579,
              36.238505
            ],
            [
              -112.987963,
              36.232371
            ],
            [
              -112.98121,
              36.231769
            ],
            [
              -112.970061,
              36.234485
            ],
            [
              -112.955306,
              36.23844
            ],
            [
              -112.939365,
              36.241596
            ],
            [
              -112.932001,
              36.24442
            ],
            [
              -112.927884,
              36.251818
            ],
            [
              -112.924369,
              36.25708
            ],
            [
              -112.916076,
              36.258193
            ],
            [
              -112.910035,
              36.256003
            ],
            [
              -112.904941,
              36.25437
            ],
            [
              -112.897216,
              36.255434
            ],
            [
              -112.891284,
              36.258847
            ],
            [
              -112.882829,
              36.265477
            ],
            [
              -112.879538,
              36.27397
            ],
            [
              -112.876025,
              36.278331
            ],
            [
              -112.873551,
              36.279962
            ],
            [
              -112.86642,
              36.282505
            ],
            [
              -112.851648,
              36.278455
            ],
            [
              -112.840867,
              36.277245
            ],
            [
              -112.832495,
              36.284946
            ],
            [
              -112.826855,
              36.287827
            ],
            [
              -112.818827,
              36.288672
            ],
            [
              -112.809988,
              36.287878
            ],
            [
              -112.804002,
              36.285832
            ],
            [
              -112.796337,
              36.283846
            ],
            [
              -112.789087,
              36.290926
            ],
            [
              -112.789129,
              36.297471
            ],
            [
              -112.786831,
              36.301461
            ],
            [
              -112.771715,
              36.303109
            ],
            [
              -112.763519,
              36.308043
            ],
            [
              -112.760665,
              36.31264
            ],
            [
              -112.758713,
              36.320721
            ],
            [
              -112.750749,
              36.325449
            ],
            [
              -112.745027,
              36.32351
            ],
            [
              -112.731794,
              36.317762
            ],
            [
              -112.720135,
              36.324015
            ],
            [
              -112.722459,
              36.330551
            ],
            [
              -112.726209,
              36.339636
            ],
            [
              -112.719084,
              36.348026
            ],
            [
              -112.708946,
              36.351083
            ],
            [
              -112.701899,
              36.354145
            ],
            [
              -112.695591,
              36.355335
            ],
            [
              -112.691577,
              36.354802
            ],
            [
              -112.687132,
              36.34808
            ],
            [
              -112.679469,
              36.341943
            ],
            [
              -112.673526,
              36.344044
            ],
            [
              -112.662401,
              36.351872
            ],
            [
              -112.65258,
              36.362854
            ],
            [
              -112.64992,
              36.37195
            ],
            [
              -112.634374,
              36.390766
            ],
            [
              -112.631564,
              36.391347
            ],
            [
              -112.630433,
              36.393579
            ],
            [
              -112.63133,
              36.393845
            ],
            [
              -112.631864,
              36.395711
            ],
            [
              -112.630701,
              36.396191
            ],
            [
              -112.630437,
              36.397949
            ],
            [
              -112.631467,
              36.399741
            ],
            [
              -112.629896,
              36.403042
            ],
            [
              -112.627876,
              36.404915
            ],
            [
              -112.625124,
              36.406702
            ],
            [
              -112.6241,
              36.410241
            ],
            [
              -112.625679,
              36.41234
            ],
            [
              -112.624416,
              36.413741
            ],
            [
              -112.62511,
              36.415137
            ],
            [
              -112.626564,
              36.41662
            ],
            [
              -112.628367,
              36.421409
            ],
            [
              -112.630785,
              36.423987
            ],
            [
              -112.629288,
              36.425943
            ],
            [
              -112.632048,
              36.42751
            ],
            [
              -112.635223,
              36.426923
            ],
            [
              -112.636876,
              36.425726
            ],
            [
              -112.638786,
              36.427112
            ],
            [
              -112.639954,
              36.429013
            ],
            [
              -112.640605,
              36.432684
            ],
            [
              -112.64246,
              36.434349
            ],
            [
              -112.644309,
              36.435504
            ],
            [
              -112.643676,
              36.43635
            ],
            [
              -112.644212,
              36.438207
            ],
            [
              -112.645293,
              36.438751
            ],
            [
              -112.646796,
              36.439433
            ],
            [
              -112.646441,
              36.440587
            ],
            [
              -112.644,
              36.44085
            ],
            [
              -112.642817,
              36.439739
            ],
            [
              -112.641478,
              36.439354
            ],
            [
              -112.638955,
              36.440414
            ],
            [
              -112.638152,
              36.441534
            ],
            [
              -112.637376,
              36.442342
            ],
            [
              -112.638994,
              36.443133
            ],
            [
              -112.642406,
              36.446195
            ],
            [
              -112.645228,
              36.446141
            ],
            [
              -112.645417,
              36.447998
            ],
            [
              -112.643703,
              36.448163
            ],
            [
              -112.641873,
              36.448541
            ],
            [
              -112.641887,
              36.449926
            ],
            [
              -112.641743,
              36.452464
            ],
            [
              -112.642218,
              36.454898
            ],
            [
              -112.643148,
              36.457063
            ],
            [
              -112.643808,
              36.456635
            ],
            [
              -112.64478,
              36.457949
            ],
            [
              -112.64346,
              36.459794
            ],
            [
              -112.641643,
              36.460942
            ],
            [
              -112.635526,
              36.463742
            ],
            [
              -112.632908,
              36.462691
            ],
            [
              -112.629683,
              36.464371
            ],
            [
              -112.629422,
              36.466986
            ],
            [
              -112.63121,
              36.46874
            ],
            [
              -112.630501,
              36.471058
            ],
            [
              -112.62682,
              36.472474
            ],
            [
              -112.624629,
              36.473647
            ],
            [
              -112.624082,
              36.475639
            ],
            [
              -112.625386,
              36.476592
            ],
            [
              -112.625272,
              36.478251
            ],
            [
              -112.625614,
              36.480509
            ],
            [
              -112.623778,
              36.481939
            ],
            [
              -112.622887,
              36.481758
            ],
            [
              -112.622344,
              36.483176
            ],
            [
              -112.622093,
              36.48416
            ],
            [
              -112.623134,
              36.484544
            ],
            [
              -112.624454,
              36.483878
            ],
            [
              -112.626672,
              36.484728
            ],
            [
              -112.627375,
              36.486161
            ],
            [
              -112.629688,
              36.486481
            ],
            [
              -112.63258,
              36.485559
            ],
            [
              -112.636139,
              36.486005
            ],
            [
              -112.63856,
              36.48782
            ],
            [
              -112.638381,
              36.490578
            ],
            [
              -112.636102,
              36.490818
            ],
            [
              -112.635999,
              36.49245
            ],
            [
              -112.636623,
              36.494478
            ],
            [
              -112.640365,
              36.496227
            ],
            [
              -112.642284,
              36.496788
            ],
            [
              -112.644475,
              36.496519
            ],
            [
              -112.645496,
              36.496657
            ],
            [
              -112.643181,
              36.498728
            ],
            [
              -112.641693,
              36.500648
            ],
            [
              -112.644856,
              36.502397
            ],
            [
              -112.646857,
              36.50287
            ],
            [
              -112.647067,
              36.504315
            ],
            [
              -112.645747,
              36.5046
            ],
            [
              -112.644786,
              36.506578
            ],
            [
              -112.645819,
              36.508754
            ],
            [
              -112.64803,
              36.510425
            ],
            [
              -112.650154,
              36.50975
            ],
            [
              -112.652162,
              36.510481
            ],
            [
              -112.653359,
              36.51202
            ],
            [
              -112.655501,
              36.513888
            ],
            [
              -112.654357,
              36.517026
            ],
            [
              -112.652805,
              36.517888
            ],
            [
              -112.653363,
              36.519597
            ],
            [
              -112.655424,
              36.521086
            ],
            [
              -112.655403,
              36.524411
            ],
            [
              -112.654217,
              36.526339
            ],
            [
              -112.654509,
              36.528916
            ],
            [
              -112.656307,
              36.528515
            ],
            [
              -112.659118,
              36.529571
            ],
            [
              -112.659505,
              36.532203
            ],
            [
              -112.657482,
              36.535812
            ],
            [
              -112.656589,
              36.538037
            ],
            [
              -112.658128,
              36.537675
            ],
            [
              -112.660394,
              36.539411
            ],
            [
              -112.659481,
              36.541966
            ],
            [
              -112.657717,
              36.543152
            ],
            [
              -112.655838,
              36.543278
            ],
            [
              -112.653434,
              36.543684
            ],
            [
              -112.653202,
              36.545305
            ],
            [
              -112.651696,
              36.548425
            ],
            [
              -112.650578,
              36.550282
            ],
            [
              -112.651544,
              36.552365
            ],
            [
              -112.653914,
              36.553091
            ],
            [
              -112.655044,
              36.552948
            ],
            [
              -112.65488,
              36.555162
            ],
            [
              -112.653953,
              36.557173
            ],
            [
              -112.649014,
              36.559919
            ],
            [
              -112.648264,
              36.563402
            ],
            [
              -112.64624,
              36.564396
            ],
            [
              -112.642078,
              36.565537
            ],
            [
              -112.641607,
              36.567411
            ],
            [
              -112.642055,
              36.569872
            ],
            [
              -112.643233,
              36.570197
            ],
            [
              -112.643478,
              36.571406
            ],
            [
              -112.642136,
              36.57146
            ],
            [
              -112.637594,
              36.570173
            ],
            [
              -112.634999,
              36.570018
            ],
            [
              -112.632273,
              36.571258
            ],
            [
              -112.632272,
              36.573291
            ],
            [
              -112.634049,
              36.575034
            ],
            [
              -112.635233,
              36.576254
            ],
            [
              -112.634606,
              36.5771
            ],
            [
              -112.632814,
              36.577429
            ],
            [
              -112.631745,
              36.576961
            ],
            [
              -112.629565,
              36.576444
            ],
            [
              -112.628446,
              36.578916
            ],
            [
              -112.628739,
              36.581691
            ],
            [
              -112.627777,
              36.581377
            ],
            [
              -112.626619,
              36.581376
            ],
            [
              -112.626794,
              36.583755
            ],
            [
              -112.627965,
              36.585031
            ],
            [
              -112.628651,
              36.588432
            ],
            [
              -112.627035,
              36.590316
            ],
            [
              -112.624328,
              36.590759
            ],
            [
              -112.623968,
              36.592116
            ],
            [
              -112.625406,
              36.592318
            ],
            [
              -112.625543,
              36.593983
            ],
            [
              -112.623153,
              36.595078
            ],
            [
              -112.62128,
              36.596436
            ],
            [
              -112.621962,
              36.597617
            ],
            [
              -112.623081,
              36.598578
            ],
            [
              -112.622626,
              36.600853
            ],
            [
              -112.621529,
              36.601155
            ],
            [
              -112.61995,
              36.603118
            ],
            [
              -112.621362,
              36.604611
            ],
            [
              -112.621726,
              36.608023
            ],
            [
              -112.619057,
              36.611678
            ],
            [
              -112.619804,
              36.614499
            ],
            [
              -112.623395,
              36.617489
            ],
            [
              -112.624245,
              36.621098
            ],
            [
              -112.623525,
              36.624022
            ],
            [
              -112.623735,
              36.627489
            ],
            [
              -112.625221,
              36.627845
            ],
            [
              -112.62707,
              36.630372
            ],
            [
              -112.625315,
              36.633691
            ],
            [
              -112.627345,
              36.63665
            ],
            [
              -112.627499,
              36.639666
            ],
            [
              -112.628907,
              36.6442
            ],
            [
              -112.62763,
              36.646732
            ],
            [
              -112.627628,
              36.648957
            ],
            [
              -112.629925,
              36.650014
            ],
            [
              -112.629685,
              36.652546
            ],
            [
              -112.628102,
              36.653573
            ],
            [
              -112.630398,
              36.656189
            ],
            [
              -112.633382,
              36.659993
            ],
            [
              -112.634926,
              36.66612
            ],
            [
              -112.634589,
              36.670965
            ],
            [
              -112.637847,
              36.67384
            ],
            [
              -112.636665,
              36.677356
            ],
            [
              -112.633356,
              36.678893
            ],
            [
              -112.634343,
              36.682195
            ],
            [
              -112.635037,
              36.685207
            ],
            [
              -112.632088,
              36.687854
            ],
            [
              -112.629186,
              36.691968
            ],
            [
              -112.630431,
              36.696078
            ],
            [
              -112.628717,
              36.698555
            ],
            [
              -112.629376,
              36.703055
            ],
            [
              -112.63104,
              36.704314
            ],
            [
              -112.629592,
              36.706192
            ],
            [
              -112.626671,
              36.706889
            ],
            [
              -112.624226,
              36.710315
            ],
            [
              -112.625561,
              36.712773
            ],
            [
              -112.627635,
              36.714345
            ],
            [
              -112.626418,
              36.71681
            ],
            [
              -112.623632,
              36.717644
            ],
            [
              -112.62319,
              36.719375
            ],
            [
              -112.620338,
              36.720553
            ],
            [
              -112.618563,
              36.720525
            ],
            [
              -112.619821,
              36.722614
            ],
            [
              -112.620763,
              36.72263
            ],
            [
              -112.62228,
              36.725068
            ],
            [
              -112.620821,
              36.726937
            ],
            [
              -112.620563,
              36.72891
            ],
            [
              -112.617273,
              36.729159
            ],
            [
              -112.616822,
              36.72778
            ],
            [
              -112.61597,
              36.728633
            ],
            [
              -112.617186,
              36.73141
            ],
            [
              -112.621091,
              36.734228
            ],
            [
              -112.622137,
              36.736643
            ],
            [
              -112.619453,
              36.738192
            ],
            [
              -112.617975,
              36.737751
            ],
            [
              -112.616357,
              36.73614
            ],
            [
              -112.612349,
              36.737623
            ],
            [
              -112.613743,
              36.739896
            ],
            [
              -112.615758,
              36.740983
            ],
            [
              -112.617948,
              36.74253
            ],
            [
              -112.617319,
              36.743455
            ],
            [
              -112.614657,
              36.744115
            ],
            [
              -112.613789,
              36.742995
            ],
            [
              -112.612793,
              36.74422
            ],
            [
              -112.613942,
              36.746885
            ],
            [
              -112.615869,
              36.749592
            ],
            [
              -112.616346,
              36.753133
            ],
            [
              -112.616476,
              36.753968
            ],
            [
              -112.613766,
              36.753952
            ],
            [
              -112.612986,
              36.752431
            ],
            [
              -112.610495,
              36.75391
            ],
            [
              -112.610483,
              36.756333
            ],
            [
              -112.612771,
              36.757299
            ],
            [
              -112.615011,
              36.757431
            ],
            [
              -112.615825,
              36.759352
            ],
            [
              -112.616202,
              36.761149
            ],
            [
              -112.617043,
              36.763148
            ],
            [
              -112.619071,
              36.763405
            ],
            [
              -112.619776,
              36.765734
            ],
            [
              -112.620536,
              36.767393
            ],
            [
              -112.620639,
              36.768657
            ],
            [
              -112.619581,
              36.769443
            ],
            [
              -112.619021,
              36.769004
            ],
            [
              -112.617519,
              36.770197
            ],
            [
              -112.616456,
              36.772505
            ],
            [
              -112.61363,
              36.774017
            ],
            [
              -112.612217,
              36.775309
            ],
            [
              -112.61458,
              36.775786
            ],
            [
              -112.616828,
              36.776367
            ],
            [
              -112.618669,
              36.781209
            ],
            [
              -112.619251,
              36.783431
            ],
            [
              -112.61667,
              36.785344
            ],
            [
              -112.614957,
              36.786318
            ],
            [
              -112.613872,
              36.78812
            ],
            [
              -112.614085,
              36.790719
            ],
            [
              -112.613329,
              36.792724
            ],
            [
              -112.612087,
              36.795258
            ],
            [
              -112.614048,
              36.796268
            ],
            [
              -112.61338,
              36.798306
            ],
            [
              -112.61157,
              36.798285
            ],
            [
              -112.6092,
              36.799138
            ],
            [
              -112.609904,
              36.800126
            ],
            [
              -112.60871,
              36.801418
            ],
            [
              -112.607623,
              36.800611
            ],
            [
              -112.605566,
              36.799601
            ],
            [
              -112.604111,
              36.800975
            ],
            [
              -112.605465,
              36.802298
            ],
            [
              -112.60447,
              36.806079
            ],
            [
              -112.605598,
              36.808073
            ],
            [
              -112.604266,
              36.808386
            ],
            [
              -112.602278,
              36.807904
            ],
            [
              -112.600454,
              36.807619
            ],
            [
              -112.599798,
              36.808201
            ],
            [
              -112.598417,
              36.808388
            ],
            [
              -112.598733,
              36.809866
            ],
            [
              -112.60012,
              36.810668
            ],
            [
              -112.599485,
              36.811476
            ],
            [
              -112.599281,
              36.813135
            ],
            [
              -112.601005,
              36.816343
            ],
            [
              -112.599934,
              36.819546
            ],
            [
              -112.600058,
              36.822771
            ],
            [
              -112.599601,
              36.824172
            ],
            [
              -112.596596,
              36.826525
            ],
            [
              -112.597623,
              36.830052
            ],
            [
              -112.600381,
              36.831928
            ],
            [
              -112.593624,
              36.832293
            ],
            [
              -112.584766,
              36.847455
            ],
            [
              -112.587149,
              36.857124
            ],
            [
              -112.573103,
              36.872851
            ],
            [
              -112.571509,
              36.892649
            ],
            [
              -112.546511,
              36.916432
            ],
            [
              -112.544959,
              36.938759
            ],
            [
              -112.529299,
              36.952437
            ],
            [
              -112.530967,
              36.967184
            ],
            [
              -112.543763,
              36.982657
            ],
            [
              -112.538582856208,
              37.0006737486645
            ],
            [
              -112.540368,
              37.000669
            ],
            [
              -112.545094,
              37.000734
            ],
            [
              -112.558974,
              37.000692
            ],
            [
              -112.609787,
              37.000753
            ],
            [
              -112.82950225110801,
              37.000424059133294
            ],
            [
              -112.89919090227801,
              37.0003197266157
            ],
            [
              -112.966471,
              37.000219
            ],
            [
              -113.332654298143,
              37.0001299672441
            ],
            [
              -113.494884,
              37.0000905231801
            ],
            [
              -113.55806774779501,
              37.000075160865
            ],
            [
              -113.965907,
              36.999976
            ],
            [
              -113.965907,
              37.000025
            ],
            [
              -114.0506,
              37.000396
            ],
            [
              -114.049995,
              36.957769
            ],
            [
              -114.050618928072,
              36.8431412145167
            ],
            [
              -114.050619,
              36.843128
            ],
            [
              -114.050562,
              36.656259
            ],
            [
              -114.050167,
              36.624978
            ],
            [
              -114.04966,
              36.621113
            ],
            [
              -114.048476,
              36.49998
            ],
            [
              -114.046488,
              36.473449
            ],
            [
              -114.045829,
              36.442973
            ],
            [
              -114.045806,
              36.391071
            ],
            [
              -114.047584,
              36.325573
            ],
            [
              -114.046935,
              36.315449
            ],
            [
              -114.048515,
              36.289598
            ],
            [
              -114.048226,
              36.268874
            ],
            [
              -114.047106,
              36.250591
            ],
            [
              -114.046743,
              36.245246
            ],
            [
              -114.046838,
              36.194069
            ],
            [
              -114.060302,
              36.189363
            ],
            [
              -114.068027,
              36.180663
            ],
            [
              -114.088954,
              36.144381
            ],
            [
              -114.09987,
              36.121654
            ],
            [
              -114.103222,
              36.120176
            ],
            [
              -114.111011,
              36.119875
            ],
            [
              -114.120862,
              36.114596
            ],
            [
              -114.123144,
              36.111576
            ],
            [
              -114.123975,
              36.106515
            ],
            [
              -114.123221,
              36.104746
            ],
            [
              -114.117459,
              36.100893
            ],
            [
              -114.114165,
              36.096982
            ],
            [
              -114.114531,
              36.095217
            ],
            [
              -114.136896,
              36.059467
            ],
            [
              -114.138203,
              36.053161
            ],
            [
              -114.137188,
              36.046785
            ],
            [
              -114.138202,
              36.041284
            ],
            [
              -114.148191,
              36.028013
            ],
            [
              -114.151725,
              36.024563
            ],
            [
              -114.15413,
              36.023862
            ],
            [
              -114.166465,
              36.027738
            ],
            [
              -114.176824,
              36.027651
            ],
            [
              -114.19238,
              36.020993
            ],
            [
              -114.21369,
              36.015613
            ],
            [
              -114.233289,
              36.014289
            ],
            [
              -114.238799,
              36.014561
            ],
            [
              -114.252651,
              36.020193
            ],
            [
              -114.263146,
              36.025937
            ],
            [
              -114.266721,
              36.029238
            ],
            [
              -114.270645,
              36.03572
            ],
            [
              -114.280202,
              36.046362
            ],
            [
              -114.314028,
              36.058165
            ],
            [
              -114.315557,
              36.059494
            ],
            [
              -114.316109,
              36.063109
            ],
            [
              -114.314206,
              36.066619
            ],
            [
              -114.307879,
              36.071291
            ],
            [
              -114.305738,
              36.074882
            ],
            [
              -114.30843,
              36.082443
            ],
            [
              -114.328777,
              36.105501
            ],
            [
              -114.337273,
              36.10802
            ],
            [
              -114.363109,
              36.130246
            ],
            [
              -114.372106,
              36.143114
            ],
            [
              -114.405475,
              36.147371
            ],
            [
              -114.412373,
              36.147254
            ],
            [
              -114.41695,
              36.145761
            ],
            [
              -114.427169,
              36.136305
            ],
            [
              -114.446605,
              36.12597
            ],
            [
              -114.448654,
              36.12641
            ],
            [
              -114.453325,
              36.130726
            ],
            [
              -114.458369,
              36.138586
            ],
            [
              -114.463637,
              36.139695
            ],
            [
              -114.470152,
              36.138801
            ],
            [
              -114.487034,
              36.129396
            ],
            [
              -114.49612,
              36.12785
            ],
            [
              -114.502172,
              36.128796
            ],
            [
              -114.504442,
              36.129741
            ],
            [
              -114.505766,
              36.131444
            ],
            [
              -114.506144,
              36.134659
            ],
            [
              -114.505387,
              36.137496
            ],
            [
              -114.50482,
              36.142414
            ],
            [
              -114.504631,
              36.145629
            ],
            [
              -114.506711,
              36.148277
            ],
            [
              -114.511721,
              36.150956
            ],
            [
              -114.545789,
              36.152248
            ],
            [
              -114.572031,
              36.15161
            ],
            [
              -114.597212,
              36.142103
            ],
            [
              -114.608264,
              36.133949
            ],
            [
              -114.616694,
              36.130101
            ],
            [
              -114.621883,
              36.13213
            ],
            [
              -114.627855,
              36.141012
            ],
            [
              -114.631716,
              36.142306
            ],
            [
              -114.65995,
              36.124145
            ],
            [
              -114.66289,
              36.119932
            ],
            [
              -114.666538,
              36.117343
            ],
            [
              -114.709771,
              36.107742
            ],
            [
              -114.717293,
              36.107686
            ],
            [
              -114.736165,
              36.104367
            ],
            [
              -114.747079,
              36.097005
            ],
            [
              -114.753638,
              36.090705
            ],
            [
              -114.755618,
              36.087166
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "04",
      "COUNTYFP": "023",
      "COUNTYNS": "00040472",
      "AFFGEOID": "0500000US04023",
      "GEOID": "04023",
      "NAME": "Santa Cruz",
      "LSAD": "06",
      "ALAND": 3203613249,
      "AWATER": 3088046,
      "County_state": "Santa Cruz,04"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -111.366613,
              31.521291
            ],
            [
              -111.366572295468,
              31.4261324300682
            ],
            [
              -111.125646,
              31.348978
            ],
            [
              -111.074825,
              31.332239
            ],
            [
              -111.000643,
              31.332177
            ],
            [
              -110.795467,
              31.33363
            ],
            [
              -110.460172,
              31.332827
            ],
            [
              -110.460917,
              31.469699
            ],
            [
              -110.460564,
              31.614037
            ],
            [
              -110.452247,
              31.613936
            ],
            [
              -110.451736,
              31.731267
            ],
            [
              -110.855632,
              31.731696
            ],
            [
              -110.855593,
              31.726653
            ],
            [
              -111.05804,
              31.72623
            ],
            [
              -111.164183,
              31.725983
            ],
            [
              -111.163725,
              31.521826
            ],
            [
              -111.366613,
              31.521291
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "04",
      "COUNTYFP": "005",
      "COUNTYNS": "00025443",
      "AFFGEOID": "0500000US04005",
      "GEOID": "04005",
      "NAME": "Coconino",
      "LSAD": "06",
      "ALAND": 48222181220,
      "AWATER": 110085803,
      "County_state": "Coconino,04"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -113.350337,
              36.050465
            ],
            [
              -113.351507,
              36.033256
            ],
            [
              -113.350175,
              36.021373
            ],
            [
              -113.348769,
              36.016874
            ],
            [
              -113.343078,
              36.008771
            ],
            [
              -113.342286,
              36.005454
            ],
            [
              -113.340025,
              35.99937
            ],
            [
              -113.340946,
              35.994648
            ],
            [
              -113.341379,
              35.991333
            ],
            [
              -113.339777,
              35.98515
            ],
            [
              -113.335076,
              35.980079
            ],
            [
              -113.321502,
              35.975669
            ],
            [
              -113.319036,
              35.972875
            ],
            [
              -113.317964,
              35.970159
            ],
            [
              -113.317304,
              35.968453
            ],
            [
              -113.318673,
              35.966929
            ],
            [
              -113.317197,
              35.961531
            ],
            [
              -113.323952,
              35.955961
            ],
            [
              -113.328691,
              35.949029
            ],
            [
              -113.325355,
              35.937841
            ],
            [
              -113.327246,
              35.931596
            ],
            [
              -113.328467,
              35.927809
            ],
            [
              -113.330129,
              35.923999
            ],
            [
              -113.334141,
              35.918719
            ],
            [
              -113.33442,
              35.913133
            ],
            [
              -113.328222,
              35.908107
            ],
            [
              -113.324285,
              35.902945
            ],
            [
              -113.322611,
              35.898563
            ],
            [
              -113.316412,
              35.895588
            ],
            [
              -113.309902,
              35.88349
            ],
            [
              -113.312246,
              35.869876
            ],
            [
              -113.311094,
              35.861771
            ],
            [
              -113.313197,
              35.857695
            ],
            [
              -113.321648,
              35.848234
            ],
            [
              -113.327073,
              35.837862
            ],
            [
              -113.327105,
              35.83368
            ],
            [
              -113.3258,
              35.830136
            ],
            [
              -113.328193,
              35.826997
            ],
            [
              -113.333103,
              35.820429
            ],
            [
              -113.331764,
              35.816187
            ],
            [
              -113.333153,
              35.805369
            ],
            [
              -113.334024,
              35.803031
            ],
            [
              -113.334161,
              35.528037
            ],
            [
              -113.299271,
              35.530979
            ],
            [
              -113.26219,
              35.529626
            ],
            [
              -113.218796,
              35.508872
            ],
            [
              -113.199134,
              35.510969
            ],
            [
              -113.174458,
              35.506445
            ],
            [
              -113.157956,
              35.495994
            ],
            [
              -113.148055,
              35.478595
            ],
            [
              -113.086051,
              35.452997
            ],
            [
              -112.975855,
              35.392904
            ],
            [
              -112.961392,
              35.376746
            ],
            [
              -112.95341,
              35.35611
            ],
            [
              -112.944789,
              35.352603
            ],
            [
              -112.880973,
              35.342071
            ],
            [
              -112.79693,
              35.322951
            ],
            [
              -112.754838,
              35.306301
            ],
            [
              -112.734644,
              35.305054
            ],
            [
              -112.694846,
              35.311586
            ],
            [
              -112.676373,
              35.306722
            ],
            [
              -112.655457,
              35.291909
            ],
            [
              -112.630954,
              35.299962
            ],
            [
              -112.618756,
              35.289961
            ],
            [
              -112.620617,
              35.274861
            ],
            [
              -112.603997,
              35.269224
            ],
            [
              -112.577399,
              35.243394
            ],
            [
              -112.541805,
              35.236338
            ],
            [
              -112.499636,
              35.242664
            ],
            [
              -112.4727,
              35.238312
            ],
            [
              -112.440751,
              35.260468
            ],
            [
              -112.442627,
              35.221748
            ],
            [
              -112.4423,
              35.149408
            ],
            [
              -112.335716,
              35.148279
            ],
            [
              -112.334536,
              34.973024
            ],
            [
              -112.02194,
              34.974088
            ],
            [
              -112.00807,
              34.975898
            ],
            [
              -111.809026,
              34.976295
            ],
            [
              -111.777815,
              34.981005
            ],
            [
              -111.78,
              34.818192
            ],
            [
              -111.77791,
              34.803318
            ],
            [
              -111.685588,
              34.803403
            ],
            [
              -111.552106,
              34.80252
            ],
            [
              -111.552064,
              34.636408
            ],
            [
              -111.553387,
              34.636413
            ],
            [
              -111.553137,
              34.500037
            ],
            [
              -111.554681,
              34.471733
            ],
            [
              -111.548785,
              34.460872
            ],
            [
              -111.521391,
              34.477818
            ],
            [
              -111.524562,
              34.494968
            ],
            [
              -111.502415,
              34.498057
            ],
            [
              -111.513654,
              34.49193
            ],
            [
              -111.512445,
              34.467836
            ],
            [
              -111.537761,
              34.459286
            ],
            [
              -111.528487,
              34.454513
            ],
            [
              -111.508965,
              34.454661
            ],
            [
              -111.485894,
              34.463782
            ],
            [
              -111.471448,
              34.457301
            ],
            [
              -111.508191,
              34.440959
            ],
            [
              -111.517657,
              34.430392
            ],
            [
              -111.550328,
              34.419057
            ],
            [
              -111.537548,
              34.412503
            ],
            [
              -111.512419,
              34.41566
            ],
            [
              -111.494971,
              34.433888
            ],
            [
              -111.486954,
              34.429817
            ],
            [
              -111.467466,
              34.437759
            ],
            [
              -111.480617,
              34.419546
            ],
            [
              -111.464588,
              34.409312
            ],
            [
              -111.451273,
              34.434856
            ],
            [
              -111.435634,
              34.446103
            ],
            [
              -111.3987,
              34.443827
            ],
            [
              -111.405249,
              34.428507
            ],
            [
              -111.423066,
              34.437225
            ],
            [
              -111.424729,
              34.394645
            ],
            [
              -111.418533,
              34.377287
            ],
            [
              -111.400332,
              34.378893
            ],
            [
              -111.386421,
              34.397801
            ],
            [
              -111.403397,
              34.397753
            ],
            [
              -111.398158,
              34.428029
            ],
            [
              -111.36685,
              34.440314
            ],
            [
              -111.351881,
              34.440997
            ],
            [
              -111.354063,
              34.424608
            ],
            [
              -111.311044,
              34.42795
            ],
            [
              -111.26146,
              34.443682
            ],
            [
              -111.250302,
              34.453274
            ],
            [
              -111.239899,
              34.431436
            ],
            [
              -111.222153,
              34.425648
            ],
            [
              -111.209571,
              34.408357
            ],
            [
              -111.184697,
              34.409992
            ],
            [
              -111.152066,
              34.39426
            ],
            [
              -111.147094,
              34.375185
            ],
            [
              -111.128736,
              34.390367
            ],
            [
              -111.118338,
              34.383041
            ],
            [
              -111.096661,
              34.396431
            ],
            [
              -111.039732,
              34.373292
            ],
            [
              -111.056698,
              34.35308
            ],
            [
              -111.033176,
              34.340934
            ],
            [
              -111.020034,
              34.362957
            ],
            [
              -111.003764,
              34.356777
            ],
            [
              -110.997311,
              34.344127
            ],
            [
              -110.975862,
              34.336837
            ],
            [
              -110.958863,
              34.314469
            ],
            [
              -110.946098,
              34.31728
            ],
            [
              -110.907619,
              34.300764
            ],
            [
              -110.896921,
              34.302447
            ],
            [
              -110.877411,
              34.275809
            ],
            [
              -110.847558,
              34.265638
            ],
            [
              -110.825941,
              34.275796
            ],
            [
              -110.837571,
              34.280754
            ],
            [
              -110.820306,
              34.288108
            ],
            [
              -110.845866,
              34.306766
            ],
            [
              -110.811029,
              34.295692
            ],
            [
              -110.788078,
              34.30906
            ],
            [
              -110.783111,
              34.299861
            ],
            [
              -110.761259,
              34.287872
            ],
            [
              -110.766816,
              34.258978
            ],
            [
              -110.750678,
              34.263314
            ],
            [
              -110.750678,
              34.264671
            ],
            [
              -110.750678,
              34.281909
            ],
            [
              -110.750686,
              34.71821
            ],
            [
              -110.750686,
              34.720755
            ],
            [
              -110.750692,
              35.064868
            ],
            [
              -110.750696,
              36.713125
            ],
            [
              -110.75069,
              37.003197
            ],
            [
              -111.066496,
              37.002389
            ],
            [
              -111.133718,
              37.000779
            ],
            [
              -111.254853,
              37.001077
            ],
            [
              -111.278286,
              37.000465
            ],
            [
              -111.35019712738301,
              37.0010482877479
            ],
            [
              -111.405517,
              37.001497
            ],
            [
              -111.405869,
              37.001481
            ],
            [
              -111.412783987873,
              37.001477687156004
            ],
            [
              -112.35769,
              37.001025
            ],
            [
              -112.368946,
              37.001125
            ],
            [
              -112.538582856208,
              37.0006737486645
            ],
            [
              -112.543763,
              36.982657
            ],
            [
              -112.530967,
              36.967184
            ],
            [
              -112.529299,
              36.952437
            ],
            [
              -112.544959,
              36.938759
            ],
            [
              -112.546511,
              36.916432
            ],
            [
              -112.571509,
              36.892649
            ],
            [
              -112.573103,
              36.872851
            ],
            [
              -112.587149,
              36.857124
            ],
            [
              -112.584766,
              36.847455
            ],
            [
              -112.593624,
              36.832293
            ],
            [
              -112.600381,
              36.831928
            ],
            [
              -112.597623,
              36.830052
            ],
            [
              -112.596596,
              36.826525
            ],
            [
              -112.599601,
              36.824172
            ],
            [
              -112.600058,
              36.822771
            ],
            [
              -112.599934,
              36.819546
            ],
            [
              -112.601005,
              36.816343
            ],
            [
              -112.599281,
              36.813135
            ],
            [
              -112.599485,
              36.811476
            ],
            [
              -112.60012,
              36.810668
            ],
            [
              -112.598733,
              36.809866
            ],
            [
              -112.598417,
              36.808388
            ],
            [
              -112.599798,
              36.808201
            ],
            [
              -112.600454,
              36.807619
            ],
            [
              -112.602278,
              36.807904
            ],
            [
              -112.604266,
              36.808386
            ],
            [
              -112.605598,
              36.808073
            ],
            [
              -112.60447,
              36.806079
            ],
            [
              -112.605465,
              36.802298
            ],
            [
              -112.604111,
              36.800975
            ],
            [
              -112.605566,
              36.799601
            ],
            [
              -112.607623,
              36.800611
            ],
            [
              -112.60871,
              36.801418
            ],
            [
              -112.609904,
              36.800126
            ],
            [
              -112.6092,
              36.799138
            ],
            [
              -112.61157,
              36.798285
            ],
            [
              -112.61338,
              36.798306
            ],
            [
              -112.614048,
              36.796268
            ],
            [
              -112.612087,
              36.795258
            ],
            [
              -112.613329,
              36.792724
            ],
            [
              -112.614085,
              36.790719
            ],
            [
              -112.613872,
              36.78812
            ],
            [
              -112.614957,
              36.786318
            ],
            [
              -112.61667,
              36.785344
            ],
            [
              -112.619251,
              36.783431
            ],
            [
              -112.618669,
              36.781209
            ],
            [
              -112.616828,
              36.776367
            ],
            [
              -112.61458,
              36.775786
            ],
            [
              -112.612217,
              36.775309
            ],
            [
              -112.61363,
              36.774017
            ],
            [
              -112.616456,
              36.772505
            ],
            [
              -112.617519,
              36.770197
            ],
            [
              -112.619021,
              36.769004
            ],
            [
              -112.619581,
              36.769443
            ],
            [
              -112.620639,
              36.768657
            ],
            [
              -112.620536,
              36.767393
            ],
            [
              -112.619776,
              36.765734
            ],
            [
              -112.619071,
              36.763405
            ],
            [
              -112.617043,
              36.763148
            ],
            [
              -112.616202,
              36.761149
            ],
            [
              -112.615825,
              36.759352
            ],
            [
              -112.615011,
              36.757431
            ],
            [
              -112.612771,
              36.757299
            ],
            [
              -112.610483,
              36.756333
            ],
            [
              -112.610495,
              36.75391
            ],
            [
              -112.612986,
              36.752431
            ],
            [
              -112.613766,
              36.753952
            ],
            [
              -112.616476,
              36.753968
            ],
            [
              -112.616346,
              36.753133
            ],
            [
              -112.615869,
              36.749592
            ],
            [
              -112.613942,
              36.746885
            ],
            [
              -112.612793,
              36.74422
            ],
            [
              -112.613789,
              36.742995
            ],
            [
              -112.614657,
              36.744115
            ],
            [
              -112.617319,
              36.743455
            ],
            [
              -112.617948,
              36.74253
            ],
            [
              -112.615758,
              36.740983
            ],
            [
              -112.613743,
              36.739896
            ],
            [
              -112.612349,
              36.737623
            ],
            [
              -112.616357,
              36.73614
            ],
            [
              -112.617975,
              36.737751
            ],
            [
              -112.619453,
              36.738192
            ],
            [
              -112.622137,
              36.736643
            ],
            [
              -112.621091,
              36.734228
            ],
            [
              -112.617186,
              36.73141
            ],
            [
              -112.61597,
              36.728633
            ],
            [
              -112.616822,
              36.72778
            ],
            [
              -112.617273,
              36.729159
            ],
            [
              -112.620563,
              36.72891
            ],
            [
              -112.620821,
              36.726937
            ],
            [
              -112.62228,
              36.725068
            ],
            [
              -112.620763,
              36.72263
            ],
            [
              -112.619821,
              36.722614
            ],
            [
              -112.618563,
              36.720525
            ],
            [
              -112.620338,
              36.720553
            ],
            [
              -112.62319,
              36.719375
            ],
            [
              -112.623632,
              36.717644
            ],
            [
              -112.626418,
              36.71681
            ],
            [
              -112.627635,
              36.714345
            ],
            [
              -112.625561,
              36.712773
            ],
            [
              -112.624226,
              36.710315
            ],
            [
              -112.626671,
              36.706889
            ],
            [
              -112.629592,
              36.706192
            ],
            [
              -112.63104,
              36.704314
            ],
            [
              -112.629376,
              36.703055
            ],
            [
              -112.628717,
              36.698555
            ],
            [
              -112.630431,
              36.696078
            ],
            [
              -112.629186,
              36.691968
            ],
            [
              -112.632088,
              36.687854
            ],
            [
              -112.635037,
              36.685207
            ],
            [
              -112.634343,
              36.682195
            ],
            [
              -112.633356,
              36.678893
            ],
            [
              -112.636665,
              36.677356
            ],
            [
              -112.637847,
              36.67384
            ],
            [
              -112.634589,
              36.670965
            ],
            [
              -112.634926,
              36.66612
            ],
            [
              -112.633382,
              36.659993
            ],
            [
              -112.630398,
              36.656189
            ],
            [
              -112.628102,
              36.653573
            ],
            [
              -112.629685,
              36.652546
            ],
            [
              -112.629925,
              36.650014
            ],
            [
              -112.627628,
              36.648957
            ],
            [
              -112.62763,
              36.646732
            ],
            [
              -112.628907,
              36.6442
            ],
            [
              -112.627499,
              36.639666
            ],
            [
              -112.627345,
              36.63665
            ],
            [
              -112.625315,
              36.633691
            ],
            [
              -112.62707,
              36.630372
            ],
            [
              -112.625221,
              36.627845
            ],
            [
              -112.623735,
              36.627489
            ],
            [
              -112.623525,
              36.624022
            ],
            [
              -112.624245,
              36.621098
            ],
            [
              -112.623395,
              36.617489
            ],
            [
              -112.619804,
              36.614499
            ],
            [
              -112.619057,
              36.611678
            ],
            [
              -112.621726,
              36.608023
            ],
            [
              -112.621362,
              36.604611
            ],
            [
              -112.61995,
              36.603118
            ],
            [
              -112.621529,
              36.601155
            ],
            [
              -112.622626,
              36.600853
            ],
            [
              -112.623081,
              36.598578
            ],
            [
              -112.621962,
              36.597617
            ],
            [
              -112.62128,
              36.596436
            ],
            [
              -112.623153,
              36.595078
            ],
            [
              -112.625543,
              36.593983
            ],
            [
              -112.625406,
              36.592318
            ],
            [
              -112.623968,
              36.592116
            ],
            [
              -112.624328,
              36.590759
            ],
            [
              -112.627035,
              36.590316
            ],
            [
              -112.628651,
              36.588432
            ],
            [
              -112.627965,
              36.585031
            ],
            [
              -112.626794,
              36.583755
            ],
            [
              -112.626619,
              36.581376
            ],
            [
              -112.627777,
              36.581377
            ],
            [
              -112.628739,
              36.581691
            ],
            [
              -112.628446,
              36.578916
            ],
            [
              -112.629565,
              36.576444
            ],
            [
              -112.631745,
              36.576961
            ],
            [
              -112.632814,
              36.577429
            ],
            [
              -112.634606,
              36.5771
            ],
            [
              -112.635233,
              36.576254
            ],
            [
              -112.634049,
              36.575034
            ],
            [
              -112.632272,
              36.573291
            ],
            [
              -112.632273,
              36.571258
            ],
            [
              -112.634999,
              36.570018
            ],
            [
              -112.637594,
              36.570173
            ],
            [
              -112.642136,
              36.57146
            ],
            [
              -112.643478,
              36.571406
            ],
            [
              -112.643233,
              36.570197
            ],
            [
              -112.642055,
              36.569872
            ],
            [
              -112.641607,
              36.567411
            ],
            [
              -112.642078,
              36.565537
            ],
            [
              -112.64624,
              36.564396
            ],
            [
              -112.648264,
              36.563402
            ],
            [
              -112.649014,
              36.559919
            ],
            [
              -112.653953,
              36.557173
            ],
            [
              -112.65488,
              36.555162
            ],
            [
              -112.655044,
              36.552948
            ],
            [
              -112.653914,
              36.553091
            ],
            [
              -112.651544,
              36.552365
            ],
            [
              -112.650578,
              36.550282
            ],
            [
              -112.651696,
              36.548425
            ],
            [
              -112.653202,
              36.545305
            ],
            [
              -112.653434,
              36.543684
            ],
            [
              -112.655838,
              36.543278
            ],
            [
              -112.657717,
              36.543152
            ],
            [
              -112.659481,
              36.541966
            ],
            [
              -112.660394,
              36.539411
            ],
            [
              -112.658128,
              36.537675
            ],
            [
              -112.656589,
              36.538037
            ],
            [
              -112.657482,
              36.535812
            ],
            [
              -112.659505,
              36.532203
            ],
            [
              -112.659118,
              36.529571
            ],
            [
              -112.656307,
              36.528515
            ],
            [
              -112.654509,
              36.528916
            ],
            [
              -112.654217,
              36.526339
            ],
            [
              -112.655403,
              36.524411
            ],
            [
              -112.655424,
              36.521086
            ],
            [
              -112.653363,
              36.519597
            ],
            [
              -112.652805,
              36.517888
            ],
            [
              -112.654357,
              36.517026
            ],
            [
              -112.655501,
              36.513888
            ],
            [
              -112.653359,
              36.51202
            ],
            [
              -112.652162,
              36.510481
            ],
            [
              -112.650154,
              36.50975
            ],
            [
              -112.64803,
              36.510425
            ],
            [
              -112.645819,
              36.508754
            ],
            [
              -112.644786,
              36.506578
            ],
            [
              -112.645747,
              36.5046
            ],
            [
              -112.647067,
              36.504315
            ],
            [
              -112.646857,
              36.50287
            ],
            [
              -112.644856,
              36.502397
            ],
            [
              -112.641693,
              36.500648
            ],
            [
              -112.643181,
              36.498728
            ],
            [
              -112.645496,
              36.496657
            ],
            [
              -112.644475,
              36.496519
            ],
            [
              -112.642284,
              36.496788
            ],
            [
              -112.640365,
              36.496227
            ],
            [
              -112.636623,
              36.494478
            ],
            [
              -112.635999,
              36.49245
            ],
            [
              -112.636102,
              36.490818
            ],
            [
              -112.638381,
              36.490578
            ],
            [
              -112.63856,
              36.48782
            ],
            [
              -112.636139,
              36.486005
            ],
            [
              -112.63258,
              36.485559
            ],
            [
              -112.629688,
              36.486481
            ],
            [
              -112.627375,
              36.486161
            ],
            [
              -112.626672,
              36.484728
            ],
            [
              -112.624454,
              36.483878
            ],
            [
              -112.623134,
              36.484544
            ],
            [
              -112.622093,
              36.48416
            ],
            [
              -112.622344,
              36.483176
            ],
            [
              -112.622887,
              36.481758
            ],
            [
              -112.623778,
              36.481939
            ],
            [
              -112.625614,
              36.480509
            ],
            [
              -112.625272,
              36.478251
            ],
            [
              -112.625386,
              36.476592
            ],
            [
              -112.624082,
              36.475639
            ],
            [
              -112.624629,
              36.473647
            ],
            [
              -112.62682,
              36.472474
            ],
            [
              -112.630501,
              36.471058
            ],
            [
              -112.63121,
              36.46874
            ],
            [
              -112.629422,
              36.466986
            ],
            [
              -112.629683,
              36.464371
            ],
            [
              -112.632908,
              36.462691
            ],
            [
              -112.635526,
              36.463742
            ],
            [
              -112.641643,
              36.460942
            ],
            [
              -112.64346,
              36.459794
            ],
            [
              -112.64478,
              36.457949
            ],
            [
              -112.643808,
              36.456635
            ],
            [
              -112.643148,
              36.457063
            ],
            [
              -112.642218,
              36.454898
            ],
            [
              -112.641743,
              36.452464
            ],
            [
              -112.641887,
              36.449926
            ],
            [
              -112.641873,
              36.448541
            ],
            [
              -112.643703,
              36.448163
            ],
            [
              -112.645417,
              36.447998
            ],
            [
              -112.645228,
              36.446141
            ],
            [
              -112.642406,
              36.446195
            ],
            [
              -112.638994,
              36.443133
            ],
            [
              -112.637376,
              36.442342
            ],
            [
              -112.638152,
              36.441534
            ],
            [
              -112.638955,
              36.440414
            ],
            [
              -112.641478,
              36.439354
            ],
            [
              -112.642817,
              36.439739
            ],
            [
              -112.644,
              36.44085
            ],
            [
              -112.646441,
              36.440587
            ],
            [
              -112.646796,
              36.439433
            ],
            [
              -112.645293,
              36.438751
            ],
            [
              -112.644212,
              36.438207
            ],
            [
              -112.643676,
              36.43635
            ],
            [
              -112.644309,
              36.435504
            ],
            [
              -112.64246,
              36.434349
            ],
            [
              -112.640605,
              36.432684
            ],
            [
              -112.639954,
              36.429013
            ],
            [
              -112.638786,
              36.427112
            ],
            [
              -112.636876,
              36.425726
            ],
            [
              -112.635223,
              36.426923
            ],
            [
              -112.632048,
              36.42751
            ],
            [
              -112.629288,
              36.425943
            ],
            [
              -112.630785,
              36.423987
            ],
            [
              -112.628367,
              36.421409
            ],
            [
              -112.626564,
              36.41662
            ],
            [
              -112.62511,
              36.415137
            ],
            [
              -112.624416,
              36.413741
            ],
            [
              -112.625679,
              36.41234
            ],
            [
              -112.6241,
              36.410241
            ],
            [
              -112.625124,
              36.406702
            ],
            [
              -112.627876,
              36.404915
            ],
            [
              -112.629896,
              36.403042
            ],
            [
              -112.631467,
              36.399741
            ],
            [
              -112.630437,
              36.397949
            ],
            [
              -112.630701,
              36.396191
            ],
            [
              -112.631864,
              36.395711
            ],
            [
              -112.63133,
              36.393845
            ],
            [
              -112.630433,
              36.393579
            ],
            [
              -112.631564,
              36.391347
            ],
            [
              -112.634374,
              36.390766
            ],
            [
              -112.64992,
              36.37195
            ],
            [
              -112.65258,
              36.362854
            ],
            [
              -112.662401,
              36.351872
            ],
            [
              -112.673526,
              36.344044
            ],
            [
              -112.679469,
              36.341943
            ],
            [
              -112.687132,
              36.34808
            ],
            [
              -112.691577,
              36.354802
            ],
            [
              -112.695591,
              36.355335
            ],
            [
              -112.701899,
              36.354145
            ],
            [
              -112.708946,
              36.351083
            ],
            [
              -112.719084,
              36.348026
            ],
            [
              -112.726209,
              36.339636
            ],
            [
              -112.722459,
              36.330551
            ],
            [
              -112.720135,
              36.324015
            ],
            [
              -112.731794,
              36.317762
            ],
            [
              -112.745027,
              36.32351
            ],
            [
              -112.750749,
              36.325449
            ],
            [
              -112.758713,
              36.320721
            ],
            [
              -112.760665,
              36.31264
            ],
            [
              -112.763519,
              36.308043
            ],
            [
              -112.771715,
              36.303109
            ],
            [
              -112.786831,
              36.301461
            ],
            [
              -112.789129,
              36.297471
            ],
            [
              -112.789087,
              36.290926
            ],
            [
              -112.796337,
              36.283846
            ],
            [
              -112.804002,
              36.285832
            ],
            [
              -112.809988,
              36.287878
            ],
            [
              -112.818827,
              36.288672
            ],
            [
              -112.826855,
              36.287827
            ],
            [
              -112.832495,
              36.284946
            ],
            [
              -112.840867,
              36.277245
            ],
            [
              -112.851648,
              36.278455
            ],
            [
              -112.86642,
              36.282505
            ],
            [
              -112.873551,
              36.279962
            ],
            [
              -112.876025,
              36.278331
            ],
            [
              -112.879538,
              36.27397
            ],
            [
              -112.882829,
              36.265477
            ],
            [
              -112.891284,
              36.258847
            ],
            [
              -112.897216,
              36.255434
            ],
            [
              -112.904941,
              36.25437
            ],
            [
              -112.910035,
              36.256003
            ],
            [
              -112.916076,
              36.258193
            ],
            [
              -112.924369,
              36.25708
            ],
            [
              -112.927884,
              36.251818
            ],
            [
              -112.932001,
              36.24442
            ],
            [
              -112.939365,
              36.241596
            ],
            [
              -112.955306,
              36.23844
            ],
            [
              -112.970061,
              36.234485
            ],
            [
              -112.98121,
              36.231769
            ],
            [
              -112.987963,
              36.232371
            ],
            [
              -113.00579,
              36.238505
            ],
            [
              -113.012822,
              36.238473
            ],
            [
              -113.016317,
              36.237402
            ],
            [
              -113.019111,
              36.236705
            ],
            [
              -113.023824,
              36.23391
            ],
            [
              -113.031357,
              36.230666
            ],
            [
              -113.036406,
              36.228072
            ],
            [
              -113.039399,
              36.225588
            ],
            [
              -113.043218,
              36.221625
            ],
            [
              -113.047754,
              36.215558
            ],
            [
              -113.050882,
              36.211655
            ],
            [
              -113.05335,
              36.209075
            ],
            [
              -113.056491,
              36.207991
            ],
            [
              -113.072655,
              36.202874
            ],
            [
              -113.081911,
              36.198099
            ],
            [
              -113.085236,
              36.196714
            ],
            [
              -113.093208,
              36.194226
            ],
            [
              -113.10438,
              36.188364
            ],
            [
              -113.10679,
              36.187115
            ],
            [
              -113.115353,
              36.182181
            ],
            [
              -113.119728,
              36.175603
            ],
            [
              -113.1258,
              36.17051
            ],
            [
              -113.133643,
              36.167655
            ],
            [
              -113.137707,
              36.166589
            ],
            [
              -113.140933,
              36.168887
            ],
            [
              -113.145632,
              36.172823
            ],
            [
              -113.157354,
              36.174125
            ],
            [
              -113.165685,
              36.169755
            ],
            [
              -113.167712,
              36.165923
            ],
            [
              -113.176497,
              36.160681
            ],
            [
              -113.18719,
              36.157056
            ],
            [
              -113.200279,
              36.150454
            ],
            [
              -113.200697,
              36.145647
            ],
            [
              -113.20263,
              36.141149
            ],
            [
              -113.205277,
              36.133132
            ],
            [
              -113.202028,
              36.121202
            ],
            [
              -113.20538,
              36.11519
            ],
            [
              -113.212715,
              36.110247
            ],
            [
              -113.2167,
              36.104988
            ],
            [
              -113.221475,
              36.096741
            ],
            [
              -113.222887,
              36.092972
            ],
            [
              -113.232904,
              36.089948
            ],
            [
              -113.242609,
              36.092705
            ],
            [
              -113.250887,
              36.091406
            ],
            [
              -113.253588,
              36.089636
            ],
            [
              -113.258773,
              36.089323
            ],
            [
              -113.26195,
              36.090991
            ],
            [
              -113.268258,
              36.092313
            ],
            [
              -113.279247,
              36.097336
            ],
            [
              -113.2964,
              36.095811
            ],
            [
              -113.308826,
              36.099558
            ],
            [
              -113.323156,
              36.091625
            ],
            [
              -113.324254,
              36.087031
            ],
            [
              -113.326173,
              36.078424
            ],
            [
              -113.331965,
              36.067199
            ],
            [
              -113.341384,
              36.06025
            ],
            [
              -113.34744,
              36.055039
            ],
            [
              -113.350337,
              36.050465
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "04",
      "COUNTYFP": "012",
      "COUNTYNS": "00043540",
      "AFFGEOID": "0500000US04012",
      "GEOID": "04012",
      "NAME": "La Paz",
      "LSAD": "06",
      "ALAND": 11653980557,
      "AWATER": 36567994,
      "County_state": "La Paz,04"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -114.731222,
              33.304039
            ],
            [
              -114.731223,
              33.302434
            ],
            [
              -114.723259,
              33.288079
            ],
            [
              -114.72167,
              33.286982
            ],
            [
              -114.717875,
              33.285157
            ],
            [
              -114.711197,
              33.283342
            ],
            [
              -114.702873,
              33.281916
            ],
            [
              -114.694449,
              33.279786
            ],
            [
              -114.684363,
              33.276025
            ],
            [
              -114.680507,
              33.273577
            ],
            [
              -114.677032,
              33.27017
            ],
            [
              -114.672401,
              33.26047
            ],
            [
              -114.672088,
              33.258499
            ],
            [
              -114.674491,
              33.255597
            ],
            [
              -114.688205,
              33.247966
            ],
            [
              -114.689541,
              33.246428
            ],
            [
              -114.689421,
              33.24525
            ],
            [
              -114.682731,
              33.234918
            ],
            [
              -114.678097,
              33.2303
            ],
            [
              -114.674479,
              33.225504
            ],
            [
              -114.673626,
              33.223121
            ],
            [
              -114.673715,
              33.219245
            ],
            [
              -114.676072,
              33.210835
            ],
            [
              -114.678749,
              33.203448
            ],
            [
              -114.678178,
              33.199584
            ],
            [
              -114.67519,
              33.188179
            ],
            [
              -114.67536,
              33.185489
            ],
            [
              -114.675831,
              33.18152
            ],
            [
              -114.679034,
              33.174738
            ],
            [
              -114.680248,
              33.169717
            ],
            [
              -114.678729,
              33.162948
            ],
            [
              -114.679359,
              33.159519
            ],
            [
              -114.682253,
              33.155214
            ],
            [
              -114.684489,
              33.148121
            ],
            [
              -114.687074,
              33.142196
            ],
            [
              -114.689995,
              33.137883
            ],
            [
              -114.696829,
              33.131209
            ],
            [
              -114.703682,
              33.113769
            ],
            [
              -114.706175,
              33.105335
            ],
            [
              -114.707896,
              33.097432
            ],
            [
              -114.707819,
              33.091102
            ],
            [
              -114.706488,
              33.08816
            ],
            [
              -114.70473,
              33.087051
            ],
            [
              -114.701165,
              33.086368
            ],
            [
              -114.694628,
              33.086226
            ],
            [
              -114.692548,
              33.085786
            ],
            [
              -114.68902,
              33.084036
            ],
            [
              -114.688597,
              33.082869
            ],
            [
              -114.689307,
              33.079179
            ],
            [
              -114.68912,
              33.076122
            ],
            [
              -114.686991,
              33.070969
            ],
            [
              -114.674296,
              33.057171
            ],
            [
              -114.675104,
              33.047532
            ],
            [
              -114.673659,
              33.041897
            ],
            [
              -114.670803,
              33.037984
            ],
            [
              -114.66506,
              33.033908
            ],
            [
              -114.662317,
              33.032671
            ],
            [
              -114.659832,
              33.032665
            ],
            [
              -114.657827,
              33.033825
            ],
            [
              -114.655038,
              33.037107
            ],
            [
              -114.649001,
              33.046763
            ],
            [
              -114.64598,
              33.048903
            ],
            [
              -114.641622,
              33.046896
            ],
            [
              -114.639553,
              33.045291
            ],
            [
              -114.63419,
              33.039025
            ],
            [
              -114.628293,
              33.031052
            ],
            [
              -114.625787,
              33.029436
            ],
            [
              -114.618788,
              33.027202
            ],
            [
              -114.601014,
              33.02541
            ],
            [
              -114.589778,
              33.026228
            ],
            [
              -114.586982,
              33.026945
            ],
            [
              -114.584765,
              33.028231
            ],
            [
              -114.581404,
              33.032545
            ],
            [
              -114.578287,
              33.035375
            ],
            [
              -114.575161,
              33.036542
            ],
            [
              -114.571653,
              33.036624
            ],
            [
              -114.553189,
              33.033974
            ],
            [
              -114.538459,
              33.033422
            ],
            [
              -114.523578,
              33.030961
            ],
            [
              -114.52013,
              33.029984
            ],
            [
              -114.51648387559901,
              33.0275718412186
            ],
            [
              -114.371131,
              33.027513
            ],
            [
              -114.339153,
              33.029997
            ],
            [
              -114.268748,
              33.029815
            ],
            [
              -114.267815,
              33.463574
            ],
            [
              -114.136428,
              33.463681
            ],
            [
              -113.958042,
              33.464655
            ],
            [
              -113.958046,
              33.377578
            ],
            [
              -113.33392,
              33.377425
            ],
            [
              -113.333331,
              33.566289
            ],
            [
              -113.333753,
              33.999227
            ],
            [
              -113.333313,
              34.000027
            ],
            [
              -113.333508,
              34.317877
            ],
            [
              -113.336376,
              34.317501
            ],
            [
              -113.342584,
              34.314524
            ],
            [
              -113.345837,
              34.308662
            ],
            [
              -113.362441,
              34.294051
            ],
            [
              -113.393431,
              34.298541
            ],
            [
              -113.407932,
              34.295998
            ],
            [
              -113.412623,
              34.294545
            ],
            [
              -113.415589,
              34.295398
            ],
            [
              -113.416904,
              34.296642
            ],
            [
              -113.420621,
              34.299133
            ],
            [
              -113.433143,
              34.301514
            ],
            [
              -113.442112,
              34.299648
            ],
            [
              -113.452318,
              34.300003
            ],
            [
              -113.463021,
              34.302853
            ],
            [
              -113.472918,
              34.302563
            ],
            [
              -113.480404,
              34.303611
            ],
            [
              -113.493405,
              34.303373
            ],
            [
              -113.501095,
              34.302826
            ],
            [
              -113.503809,
              34.303259
            ],
            [
              -113.50545,
              34.30393
            ],
            [
              -113.509425,
              34.30473
            ],
            [
              -113.511579,
              34.305076
            ],
            [
              -113.513354,
              34.306774
            ],
            [
              -113.514463,
              34.30826
            ],
            [
              -113.516428,
              34.310041
            ],
            [
              -113.518159,
              34.310572
            ],
            [
              -113.518979,
              34.310718
            ],
            [
              -113.519794,
              34.310103
            ],
            [
              -113.519933,
              34.309535
            ],
            [
              -113.522882,
              34.308888
            ],
            [
              -113.525854,
              34.310379
            ],
            [
              -113.530282,
              34.310264
            ],
            [
              -113.532476,
              34.309466
            ],
            [
              -113.533556,
              34.308723
            ],
            [
              -113.535012,
              34.308275
            ],
            [
              -113.537488,
              34.307359
            ],
            [
              -113.539468,
              34.306404
            ],
            [
              -113.547095,
              34.296028
            ],
            [
              -113.54861,
              34.293771
            ],
            [
              -113.55243,
              34.288404
            ],
            [
              -113.563713,
              34.277637
            ],
            [
              -113.568758,
              34.274227
            ],
            [
              -113.570938,
              34.268492
            ],
            [
              -113.583584,
              34.256628
            ],
            [
              -113.58699,
              34.243274
            ],
            [
              -113.593753,
              34.23885
            ],
            [
              -113.59854,
              34.23496
            ],
            [
              -113.601583,
              34.232435
            ],
            [
              -113.60265,
              34.23136
            ],
            [
              -113.603715,
              34.230571
            ],
            [
              -113.604436,
              34.229864
            ],
            [
              -113.605575,
              34.229375
            ],
            [
              -113.606587,
              34.23006
            ],
            [
              -113.607061,
              34.230898
            ],
            [
              -113.608137,
              34.231523
            ],
            [
              -113.609199,
              34.231237
            ],
            [
              -113.610853,
              34.231215
            ],
            [
              -113.612394,
              34.230617
            ],
            [
              -113.612729,
              34.229645
            ],
            [
              -113.614347,
              34.228915
            ],
            [
              -113.616913,
              34.227528
            ],
            [
              -113.618785,
              34.227574
            ],
            [
              -113.622074,
              34.227885
            ],
            [
              -113.62463,
              34.228273
            ],
            [
              -113.626153,
              34.229123
            ],
            [
              -113.6277,
              34.228569
            ],
            [
              -113.62965,
              34.227125
            ],
            [
              -113.63112,
              34.226139
            ],
            [
              -113.633326,
              34.224758
            ],
            [
              -113.635437,
              34.224733
            ],
            [
              -113.636899,
              34.224226
            ],
            [
              -113.637909,
              34.223404
            ],
            [
              -113.639201,
              34.223539
            ],
            [
              -113.639404,
              34.224166
            ],
            [
              -113.640221,
              34.226059
            ],
            [
              -113.640739,
              34.227355
            ],
            [
              -113.641958,
              34.227508
            ],
            [
              -113.642794,
              34.22716
            ],
            [
              -113.643795,
              34.226693
            ],
            [
              -113.644284,
              34.225294
            ],
            [
              -113.643614,
              34.224559
            ],
            [
              -113.643972,
              34.223254
            ],
            [
              -113.644747,
              34.221783
            ],
            [
              -113.64515,
              34.221275
            ],
            [
              -113.646442,
              34.221246
            ],
            [
              -113.646719,
              34.221727
            ],
            [
              -113.648306,
              34.222022
            ],
            [
              -113.649618,
              34.221468
            ],
            [
              -113.651792,
              34.219499
            ],
            [
              -113.654005,
              34.217267
            ],
            [
              -113.655376,
              34.216524
            ],
            [
              -113.656865,
              34.216541
            ],
            [
              -113.657488,
              34.217182
            ],
            [
              -113.65899,
              34.217382
            ],
            [
              -113.659696,
              34.216317
            ],
            [
              -113.661779,
              34.214594
            ],
            [
              -113.663579,
              34.212523
            ],
            [
              -113.664844,
              34.212511
            ],
            [
              -113.666274,
              34.21357
            ],
            [
              -113.668538,
              34.213476
            ],
            [
              -113.67031,
              34.212975
            ],
            [
              -113.672853,
              34.213075
            ],
            [
              -113.675685,
              34.212805
            ],
            [
              -113.676985,
              34.211303
            ],
            [
              -113.678766,
              34.210398
            ],
            [
              -113.68061,
              34.21011
            ],
            [
              -113.685656,
              34.210194
            ],
            [
              -113.692729,
              34.21037
            ],
            [
              -113.697458,
              34.210336
            ],
            [
              -113.703035,
              34.210143
            ],
            [
              -113.706529,
              34.213412
            ],
            [
              -113.709485,
              34.216128
            ],
            [
              -113.711347,
              34.216867
            ],
            [
              -113.713689,
              34.217502
            ],
            [
              -113.714761,
              34.218891
            ],
            [
              -113.714573,
              34.22103
            ],
            [
              -113.715896,
              34.221871
            ],
            [
              -113.718637,
              34.222341
            ],
            [
              -113.721705,
              34.222821
            ],
            [
              -113.722954,
              34.223348
            ],
            [
              -113.723421,
              34.224635
            ],
            [
              -113.724317,
              34.226178
            ],
            [
              -113.724315,
              34.228444
            ],
            [
              -113.727965,
              34.235236
            ],
            [
              -113.740509,
              34.240296
            ],
            [
              -113.746901,
              34.238872
            ],
            [
              -113.748254,
              34.238375
            ],
            [
              -113.749729,
              34.238129
            ],
            [
              -113.751683,
              34.238107
            ],
            [
              -113.752738,
              34.23778
            ],
            [
              -113.754243,
              34.237284
            ],
            [
              -113.756486,
              34.236776
            ],
            [
              -113.757606,
              34.236648
            ],
            [
              -113.759342,
              34.235812
            ],
            [
              -113.76157,
              34.234658
            ],
            [
              -113.763797,
              34.233485
            ],
            [
              -113.767488,
              34.232959
            ],
            [
              -113.770307,
              34.231703
            ],
            [
              -113.772472,
              34.230086
            ],
            [
              -113.774015,
              34.22927
            ],
            [
              -113.778158,
              34.228956
            ],
            [
              -113.786204,
              34.232306
            ],
            [
              -113.792578,
              34.235708
            ],
            [
              -113.79862,
              34.235812
            ],
            [
              -113.801019,
              34.236227
            ],
            [
              -113.804904,
              34.234573
            ],
            [
              -113.808127,
              34.233107
            ],
            [
              -113.810198,
              34.232751
            ],
            [
              -113.813194,
              34.232565
            ],
            [
              -113.814468,
              34.234032
            ],
            [
              -113.817854,
              34.234524
            ],
            [
              -113.819576,
              34.232274
            ],
            [
              -113.819813,
              34.229363
            ],
            [
              -113.820701,
              34.22804
            ],
            [
              -113.822716,
              34.226673
            ],
            [
              -113.825956,
              34.225344
            ],
            [
              -113.833002,
              34.223441
            ],
            [
              -113.838713,
              34.224525
            ],
            [
              -113.840436,
              34.227865
            ],
            [
              -113.843556,
              34.235
            ],
            [
              -113.849846,
              34.244037
            ],
            [
              -113.851529,
              34.250745
            ],
            [
              -113.853287,
              34.252087
            ],
            [
              -113.859152,
              34.25351
            ],
            [
              -113.861614,
              34.25352
            ],
            [
              -113.867796,
              34.254612
            ],
            [
              -113.875983,
              34.254414
            ],
            [
              -113.878719,
              34.252971
            ],
            [
              -113.880156,
              34.252293
            ],
            [
              -113.886333,
              34.251011
            ],
            [
              -113.89425,
              34.249092
            ],
            [
              -113.899518,
              34.248636
            ],
            [
              -113.903358,
              34.24845
            ],
            [
              -113.908374,
              34.24861
            ],
            [
              -113.922813,
              34.249378
            ],
            [
              -113.92716,
              34.249343
            ],
            [
              -113.933135,
              34.251099
            ],
            [
              -113.943678,
              34.255733
            ],
            [
              -113.952206,
              34.257218
            ],
            [
              -113.972919,
              34.257637
            ],
            [
              -113.98304,
              34.255956
            ],
            [
              -113.98922,
              34.254744
            ],
            [
              -113.99649,
              34.254933
            ],
            [
              -113.999664,
              34.254957
            ],
            [
              -114.00107,
              34.25426
            ],
            [
              -114.003489,
              34.253639
            ],
            [
              -114.007882,
              34.2547
            ],
            [
              -114.011751,
              34.256286
            ],
            [
              -114.013089,
              34.258552
            ],
            [
              -114.014154,
              34.259547
            ],
            [
              -114.017975,
              34.264247
            ],
            [
              -114.02022,
              34.266006
            ],
            [
              -114.02313,
              34.267869
            ],
            [
              -114.025972,
              34.2671
            ],
            [
              -114.027194,
              34.263244
            ],
            [
              -114.029099,
              34.261334
            ],
            [
              -114.032399,
              34.259508
            ],
            [
              -114.035458,
              34.259918
            ],
            [
              -114.036732,
              34.260606
            ],
            [
              -114.037259,
              34.262224
            ],
            [
              -114.03735,
              34.263557
            ],
            [
              -114.039534,
              34.264857
            ],
            [
              -114.043232,
              34.267563
            ],
            [
              -114.044655,
              34.268315
            ],
            [
              -114.04647,
              34.269592
            ],
            [
              -114.047101,
              34.272141
            ],
            [
              -114.049841,
              34.274509
            ],
            [
              -114.052507,
              34.277986
            ],
            [
              -114.05568,
              34.27844
            ],
            [
              -114.057212,
              34.279294
            ],
            [
              -114.06183,
              34.283359
            ],
            [
              -114.067296,
              34.289022
            ],
            [
              -114.074133,
              34.292323
            ],
            [
              -114.079725,
              34.292114
            ],
            [
              -114.088855,
              34.298906
            ],
            [
              -114.102494,
              34.298421
            ],
            [
              -114.116681,
              34.296531
            ],
            [
              -114.126392,
              34.301915
            ],
            [
              -114.137522,
              34.303772
            ],
            [
              -114.138282,
              34.30323
            ],
            [
              -114.138167,
              34.300936
            ],
            [
              -114.139534,
              34.295844
            ],
            [
              -114.138365,
              34.288564
            ],
            [
              -114.13605,
              34.280833
            ],
            [
              -114.137045,
              34.277018
            ],
            [
              -114.136671,
              34.274377
            ],
            [
              -114.134768,
              34.268965
            ],
            [
              -114.134427,
              34.266387
            ],
            [
              -114.134612,
              34.263518
            ],
            [
              -114.136185,
              34.261296
            ],
            [
              -114.139055,
              34.259538
            ],
            [
              -114.144779,
              34.259623
            ],
            [
              -114.147159,
              34.259564
            ],
            [
              -114.153346,
              34.258289
            ],
            [
              -114.156853,
              34.258415
            ],
            [
              -114.159697,
              34.258242
            ],
            [
              -114.161826,
              34.257038
            ],
            [
              -114.163122,
              34.255187
            ],
            [
              -114.163867,
              34.253349
            ],
            [
              -114.164476,
              34.251667
            ],
            [
              -114.166536,
              34.249647
            ],
            [
              -114.173119,
              34.247226
            ],
            [
              -114.174322,
              34.245468
            ],
            [
              -114.175948,
              34.242695
            ],
            [
              -114.176403,
              34.241512
            ],
            [
              -114.17805,
              34.239969
            ],
            [
              -114.190876,
              34.230858
            ],
            [
              -114.208253,
              34.215505
            ],
            [
              -114.211761,
              34.211539
            ],
            [
              -114.215454,
              34.208956
            ],
            [
              -114.223384,
              34.205136
            ],
            [
              -114.225194,
              34.203642
            ],
            [
              -114.225861,
              34.201774
            ],
            [
              -114.224941,
              34.193896
            ],
            [
              -114.227034,
              34.188866
            ],
            [
              -114.229715,
              34.186928
            ],
            [
              -114.240712,
              34.183232
            ],
            [
              -114.244191,
              34.179625
            ],
            [
              -114.254141,
              34.173831
            ],
            [
              -114.268267,
              34.17021
            ],
            [
              -114.275267,
              34.17215
            ],
            [
              -114.287294,
              34.170529
            ],
            [
              -114.292806,
              34.166725
            ],
            [
              -114.312206,
              34.144776
            ],
            [
              -114.320777,
              34.138635
            ],
            [
              -114.324576,
              34.136759
            ],
            [
              -114.336112,
              34.134035
            ],
            [
              -114.348052,
              34.134458
            ],
            [
              -114.353031,
              34.133121
            ],
            [
              -114.356373,
              34.130429
            ],
            [
              -114.360402,
              34.123577
            ],
            [
              -114.366521,
              34.118575
            ],
            [
              -114.369297,
              34.117517
            ],
            [
              -114.379234,
              34.115988
            ],
            [
              -114.390565,
              34.110084
            ],
            [
              -114.401352,
              34.111652
            ],
            [
              -114.405941,
              34.11154
            ],
            [
              -114.411681,
              34.110031
            ],
            [
              -114.415908,
              34.107636
            ],
            [
              -114.420499,
              34.103466
            ],
            [
              -114.426168,
              34.097042
            ],
            [
              -114.428026,
              34.092787
            ],
            [
              -114.43338,
              34.088413
            ],
            [
              -114.434181,
              34.087379
            ],
            [
              -114.435429,
              34.079727
            ],
            [
              -114.437683,
              34.071937
            ],
            [
              -114.43934,
              34.057893
            ],
            [
              -114.439406,
              34.05381
            ],
            [
              -114.438602,
              34.050205
            ],
            [
              -114.435504,
              34.042615
            ],
            [
              -114.434949,
              34.037784
            ],
            [
              -114.436171,
              34.028083
            ],
            [
              -114.438266,
              34.022609
            ],
            [
              -114.44054,
              34.019329
            ],
            [
              -114.443821,
              34.016176
            ],
            [
              -114.450206,
              34.012574
            ],
            [
              -114.454807,
              34.010968
            ],
            [
              -114.458906,
              34.010835
            ],
            [
              -114.46117,
              34.010081
            ],
            [
              -114.46283,
              34.008421
            ],
            [
              -114.463132,
              34.00661
            ],
            [
              -114.46283,
              34.004497
            ],
            [
              -114.460415,
              33.999215
            ],
            [
              -114.460264,
              33.996649
            ],
            [
              -114.46117,
              33.994687
            ],
            [
              -114.462377,
              33.993781
            ],
            [
              -114.466187,
              33.993465
            ],
            [
              -114.467932,
              33.992877
            ],
            [
              -114.471138,
              33.98804
            ],
            [
              -114.475907,
              33.984424
            ],
            [
              -114.481455,
              33.981261
            ],
            [
              -114.482333,
              33.980181
            ],
            [
              -114.483097,
              33.977745
            ],
            [
              -114.484784,
              33.975519
            ],
            [
              -114.495047,
              33.966835
            ],
            [
              -114.499883,
              33.961789
            ],
            [
              -114.509568,
              33.957264
            ],
            [
              -114.511231,
              33.95704
            ],
            [
              -114.51586,
              33.958106
            ],
            [
              -114.522002,
              33.955623
            ],
            [
              -114.52868,
              33.947817
            ],
            [
              -114.535478,
              33.934651
            ],
            [
              -114.534987,
              33.928499
            ],
            [
              -114.533679,
              33.926072
            ],
            [
              -114.528385,
              33.923674
            ],
            [
              -114.525361,
              33.922272
            ],
            [
              -114.518434,
              33.917518
            ],
            [
              -114.511511,
              33.911092
            ],
            [
              -114.508558,
              33.906098
            ],
            [
              -114.50792,
              33.903807
            ],
            [
              -114.507988,
              33.901813
            ],
            [
              -114.508708,
              33.90064
            ],
            [
              -114.510944,
              33.899099
            ],
            [
              -114.513715,
              33.897959
            ],
            [
              -114.516314,
              33.896196
            ],
            [
              -114.517808,
              33.894889
            ],
            [
              -114.518741,
              33.893208
            ],
            [
              -114.518928,
              33.891714
            ],
            [
              -114.518555,
              33.889847
            ],
            [
              -114.517808,
              33.888167
            ],
            [
              -114.516501,
              33.885926
            ],
            [
              -114.512467,
              33.882884
            ],
            [
              -114.50434,
              33.876882
            ],
            [
              -114.503395,
              33.875018
            ],
            [
              -114.503017,
              33.867998
            ],
            [
              -114.503887,
              33.865754
            ],
            [
              -114.505638,
              33.864276
            ],
            [
              -114.514673,
              33.858638
            ],
            [
              -114.516811,
              33.85812
            ],
            [
              -114.52453,
              33.858477
            ],
            [
              -114.526771,
              33.857357
            ],
            [
              -114.528451,
              33.854929
            ],
            [
              -114.529385,
              33.851755
            ],
            [
              -114.529597,
              33.848063
            ],
            [
              -114.525539,
              33.838614
            ],
            [
              -114.523409,
              33.835323
            ],
            [
              -114.520465,
              33.827778
            ],
            [
              -114.51997,
              33.825381
            ],
            [
              -114.520733,
              33.822031
            ],
            [
              -114.522714,
              33.818979
            ],
            [
              -114.527161,
              33.816191
            ],
            [
              -114.52805,
              33.814963
            ],
            [
              -114.520094,
              33.799473
            ],
            [
              -114.516734,
              33.788345
            ],
            [
              -114.507089,
              33.76793
            ],
            [
              -114.504863,
              33.760465
            ],
            [
              -114.50434,
              33.756381
            ],
            [
              -114.504483,
              33.750998
            ],
            [
              -114.506,
              33.746344
            ],
            [
              -114.508206,
              33.741587
            ],
            [
              -114.512348,
              33.734214
            ],
            [
              -114.510265,
              33.732146
            ],
            [
              -114.506799,
              33.730518
            ],
            [
              -114.504176,
              33.728055
            ],
            [
              -114.502661,
              33.724584
            ],
            [
              -114.500788,
              33.722204
            ],
            [
              -114.496565,
              33.719155
            ],
            [
              -114.494901,
              33.71443
            ],
            [
              -114.494197,
              33.707922
            ],
            [
              -114.495719,
              33.698454
            ],
            [
              -114.496489,
              33.696901
            ],
            [
              -114.504993,
              33.693022
            ],
            [
              -114.512409,
              33.691282
            ],
            [
              -114.519113,
              33.688473
            ],
            [
              -114.523959,
              33.685879
            ],
            [
              -114.527782,
              33.682684
            ],
            [
              -114.530348,
              33.679245
            ],
            [
              -114.531523,
              33.675108
            ],
            [
              -114.530999,
              33.671102
            ],
            [
              -114.529706,
              33.668031
            ],
            [
              -114.528304,
              33.666044
            ],
            [
              -114.526947,
              33.664298
            ],
            [
              -114.525977,
              33.662941
            ],
            [
              -114.525201,
              33.661583
            ],
            [
              -114.525007,
              33.659643
            ],
            [
              -114.525201,
              33.658092
            ],
            [
              -114.525783,
              33.657122
            ],
            [
              -114.526947,
              33.655571
            ],
            [
              -114.528304,
              33.653049
            ],
            [
              -114.530244,
              33.65014
            ],
            [
              -114.53005,
              33.647619
            ],
            [
              -114.52908,
              33.644128
            ],
            [
              -114.527917,
              33.641413
            ],
            [
              -114.526947,
              33.637534
            ],
            [
              -114.526947,
              33.633073
            ],
            [
              -114.528498,
              33.630164
            ],
            [
              -114.529856,
              33.627448
            ],
            [
              -114.529662,
              33.622794
            ],
            [
              -114.527938,
              33.618839
            ],
            [
              -114.525783,
              33.616588
            ],
            [
              -114.524619,
              33.61426
            ],
            [
              -114.524813,
              33.611351
            ],
            [
              -114.526782,
              33.608831
            ],
            [
              -114.529186,
              33.60665
            ],
            [
              -114.540617,
              33.591412
            ],
            [
              -114.5403,
              33.580615
            ],
            [
              -114.535965,
              33.569154
            ],
            [
              -114.535664,
              33.568788
            ],
            [
              -114.526834,
              33.557466
            ],
            [
              -114.524391,
              33.553683
            ],
            [
              -114.524599,
              33.552231
            ],
            [
              -114.542011,
              33.542481
            ],
            [
              -114.558898,
              33.531819
            ],
            [
              -114.559507,
              33.530724
            ],
            [
              -114.560835,
              33.524334
            ],
            [
              -114.560552,
              33.518272
            ],
            [
              -114.560963,
              33.516739
            ],
            [
              -114.569533,
              33.509219
            ],
            [
              -114.573757,
              33.507543
            ],
            [
              -114.580468,
              33.506465
            ],
            [
              -114.588239,
              33.502453
            ],
            [
              -114.591554,
              33.499443
            ],
            [
              -114.597283,
              33.490653
            ],
            [
              -114.599713,
              33.484315
            ],
            [
              -114.601696,
              33.481394
            ],
            [
              -114.607843,
              33.474834
            ],
            [
              -114.612472,
              33.470768
            ],
            [
              -114.622918,
              33.456561
            ],
            [
              -114.623395,
              33.45449
            ],
            [
              -114.622283,
              33.447558
            ],
            [
              -114.627125,
              33.433554
            ],
            [
              -114.62964,
              33.428138
            ],
            [
              -114.635183,
              33.422726
            ],
            [
              -114.643302,
              33.416745
            ],
            [
              -114.64954,
              33.413633
            ],
            [
              -114.652828,
              33.412922
            ],
            [
              -114.658382,
              33.413036
            ],
            [
              -114.673901,
              33.418299
            ],
            [
              -114.687953,
              33.417944
            ],
            [
              -114.695655,
              33.415127
            ],
            [
              -114.696504,
              33.414059
            ],
            [
              -114.696805,
              33.412087
            ],
            [
              -114.697707,
              33.410942
            ],
            [
              -114.701732,
              33.408388
            ],
            [
              -114.710878,
              33.407254
            ],
            [
              -114.720065,
              33.407891
            ],
            [
              -114.723829,
              33.406531
            ],
            [
              -114.725282,
              33.405048
            ],
            [
              -114.725535,
              33.404056
            ],
            [
              -114.725292,
              33.402342
            ],
            [
              -114.722872,
              33.398779
            ],
            [
              -114.713602,
              33.388257
            ],
            [
              -114.708408,
              33.384147
            ],
            [
              -114.70731,
              33.382542
            ],
            [
              -114.707009,
              33.380634
            ],
            [
              -114.707348,
              33.376628
            ],
            [
              -114.699053,
              33.361148
            ],
            [
              -114.69817,
              33.356575
            ],
            [
              -114.698035,
              33.352442
            ],
            [
              -114.700938,
              33.337014
            ],
            [
              -114.705241,
              33.327767
            ],
            [
              -114.707962,
              33.323421
            ],
            [
              -114.710792,
              33.320607
            ],
            [
              -114.723623,
              33.31211
            ],
            [
              -114.729904,
              33.305745
            ],
            [
              -114.731222,
              33.304039
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "04",
      "COUNTYFP": "007",
      "COUNTYNS": "00040471",
      "AFFGEOID": "0500000US04007",
      "GEOID": "04007",
      "NAME": "Gila",
      "LSAD": "06",
      "ALAND": 12323146398,
      "AWATER": 97119865,
      "County_state": "Gila,04"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -111.72057,
              34.161429
            ],
            [
              -111.712118,
              34.151271
            ],
            [
              -111.477853,
              34.151108
            ],
            [
              -111.493648,
              34.133978
            ],
            [
              -111.48198,
              34.131587
            ],
            [
              -111.471372,
              34.11154
            ],
            [
              -111.485909,
              34.095007
            ],
            [
              -111.464391,
              34.07266
            ],
            [
              -111.473471,
              34.041303
            ],
            [
              -111.493241,
              34.038101
            ],
            [
              -111.494778,
              33.999817
            ],
            [
              -111.466532,
              34.005943
            ],
            [
              -111.445079,
              34.001176
            ],
            [
              -111.433271,
              33.983321
            ],
            [
              -111.452925,
              33.962204
            ],
            [
              -111.447389,
              33.941735
            ],
            [
              -111.450904,
              33.919877
            ],
            [
              -111.437487,
              33.916174
            ],
            [
              -111.425749,
              33.926099
            ],
            [
              -111.405356,
              33.919582
            ],
            [
              -111.412331,
              33.884725
            ],
            [
              -111.397723,
              33.877823
            ],
            [
              -111.387459,
              33.862957
            ],
            [
              -111.395787,
              33.844276
            ],
            [
              -111.372317,
              33.823924
            ],
            [
              -111.387912,
              33.803214
            ],
            [
              -111.378909,
              33.791877
            ],
            [
              -111.389444,
              33.776663
            ],
            [
              -111.386812,
              33.750044
            ],
            [
              -111.359345,
              33.74097
            ],
            [
              -111.338125,
              33.718676
            ],
            [
              -111.331394,
              33.688338
            ],
            [
              -111.313811,
              33.68047
            ],
            [
              -111.28649,
              33.657375
            ],
            [
              -111.26173,
              33.622571
            ],
            [
              -111.249538,
              33.624331
            ],
            [
              -111.222055,
              33.602446
            ],
            [
              -111.207453,
              33.620572
            ],
            [
              -111.209444,
              33.633499
            ],
            [
              -111.180619,
              33.659792
            ],
            [
              -111.153354,
              33.677606
            ],
            [
              -111.058369,
              33.500051
            ],
            [
              -111.039976,
              33.466019
            ],
            [
              -110.974706,
              33.342095
            ],
            [
              -110.955497,
              33.279139
            ],
            [
              -110.946684,
              33.250053
            ],
            [
              -110.783136,
              32.98466
            ],
            [
              -110.766386,
              32.985354
            ],
            [
              -110.765106,
              33.002859
            ],
            [
              -110.74659,
              33.010832
            ],
            [
              -110.729344,
              33.027305
            ],
            [
              -110.716826,
              33.052819
            ],
            [
              -110.725583,
              33.069896
            ],
            [
              -110.706223,
              33.083731
            ],
            [
              -110.693334,
              33.077052
            ],
            [
              -110.683286,
              33.088118
            ],
            [
              -110.677893,
              33.111365
            ],
            [
              -110.656777,
              33.11354
            ],
            [
              -110.632529,
              33.142771
            ],
            [
              -110.61126,
              33.143121
            ],
            [
              -110.593459,
              33.133926
            ],
            [
              -110.579445,
              33.147132
            ],
            [
              -110.549703,
              33.153094
            ],
            [
              -110.545957,
              33.168764
            ],
            [
              -110.534323,
              33.167713
            ],
            [
              -110.504733,
              33.186248
            ],
            [
              -110.463294,
              33.18334
            ],
            [
              -110.449021,
              33.194526
            ],
            [
              -110.448257,
              33.19391
            ],
            [
              -110.444694,
              33.189352
            ],
            [
              -110.434412,
              33.188577
            ],
            [
              -110.427978,
              33.191612
            ],
            [
              -110.420122,
              33.192352
            ],
            [
              -110.413878,
              33.191777
            ],
            [
              -110.407236,
              33.193482
            ],
            [
              -110.405824,
              33.197231
            ],
            [
              -110.408201,
              33.204203
            ],
            [
              -110.420104,
              33.220917
            ],
            [
              -110.424903,
              33.231879
            ],
            [
              -110.436623,
              33.249739
            ],
            [
              -110.438915,
              33.253051
            ],
            [
              -110.44462,
              33.257512
            ],
            [
              -110.44419,
              33.262504
            ],
            [
              -110.449006,
              33.266556
            ],
            [
              -110.44883,
              33.271934
            ],
            [
              -110.45175,
              33.279797
            ],
            [
              -110.454972,
              33.282328
            ],
            [
              -110.453598,
              33.28618
            ],
            [
              -110.454928,
              33.291151
            ],
            [
              -110.45701,
              33.29142
            ],
            [
              -110.457435,
              33.294978
            ],
            [
              -110.453021,
              33.296864
            ],
            [
              -110.449658,
              33.297777
            ],
            [
              -110.448035,
              33.301796
            ],
            [
              -110.448893,
              33.303033
            ],
            [
              -110.448913,
              33.30791
            ],
            [
              -110.449104,
              33.312969
            ],
            [
              -110.450388,
              33.317709
            ],
            [
              -110.446519,
              33.323465
            ],
            [
              -110.446833,
              33.330575
            ],
            [
              -110.447299,
              33.336101
            ],
            [
              -110.448332,
              33.342276
            ],
            [
              -110.448034,
              33.346324
            ],
            [
              -110.445092,
              33.350535
            ],
            [
              -110.44713,
              33.355438
            ],
            [
              -110.449869,
              33.358573
            ],
            [
              -110.451672,
              33.363269
            ],
            [
              -110.447616,
              33.365935
            ],
            [
              -110.443084,
              33.367868
            ],
            [
              -110.442477,
              33.370275
            ],
            [
              -110.442653,
              33.372355
            ],
            [
              -110.442982,
              33.377376
            ],
            [
              -110.436414,
              33.384518
            ],
            [
              -110.429976,
              33.387442
            ],
            [
              -110.424831,
              33.38714
            ],
            [
              -110.421783,
              33.385968
            ],
            [
              -110.41825,
              33.388871
            ],
            [
              -110.417076,
              33.393236
            ],
            [
              -110.414269,
              33.398349
            ],
            [
              -110.41086,
              33.400317
            ],
            [
              -110.40711,
              33.399881
            ],
            [
              -110.39843,
              33.402095
            ],
            [
              -110.389134,
              33.402999
            ],
            [
              -110.386852,
              33.405565
            ],
            [
              -110.382708,
              33.405656
            ],
            [
              -110.378725,
              33.402428
            ],
            [
              -110.369257,
              33.398383
            ],
            [
              -110.366544,
              33.399413
            ],
            [
              -110.359891,
              33.399921
            ],
            [
              -110.354006,
              33.404069
            ],
            [
              -110.351143,
              33.408562
            ],
            [
              -110.349341,
              33.408683
            ],
            [
              -110.346773,
              33.413241
            ],
            [
              -110.346597,
              33.417795
            ],
            [
              -110.341059,
              33.422189
            ],
            [
              -110.341191,
              33.426929
            ],
            [
              -110.334385,
              33.428085
            ],
            [
              -110.326871,
              33.428404
            ],
            [
              -110.319757,
              33.432985
            ],
            [
              -110.310432,
              33.431533
            ],
            [
              -110.304012,
              33.430939
            ],
            [
              -110.297894,
              33.428899
            ],
            [
              -110.290353,
              33.430783
            ],
            [
              -110.282548,
              33.435988
            ],
            [
              -110.275582,
              33.440731
            ],
            [
              -110.27088,
              33.439557
            ],
            [
              -110.261065,
              33.436388
            ],
            [
              -110.251207,
              33.438214
            ],
            [
              -110.240929,
              33.440154
            ],
            [
              -110.235059,
              33.440123
            ],
            [
              -110.231598,
              33.442583
            ],
            [
              -110.22399,
              33.440463
            ],
            [
              -110.220133,
              33.438809
            ],
            [
              -110.213562,
              33.437755
            ],
            [
              -110.211766,
              33.440378
            ],
            [
              -110.208185,
              33.441131
            ],
            [
              -110.206283,
              33.439372
            ],
            [
              -110.201397,
              33.440128
            ],
            [
              -110.197837,
              33.44304
            ],
            [
              -110.193311,
              33.442194
            ],
            [
              -110.191803,
              33.444553
            ],
            [
              -110.19324,
              33.447307
            ],
            [
              -110.187849,
              33.450381
            ],
            [
              -110.18632,
              33.448753
            ],
            [
              -110.182477,
              33.448973
            ],
            [
              -110.180299,
              33.449835
            ],
            [
              -110.174088,
              33.450072
            ],
            [
              -110.169168,
              33.454816
            ],
            [
              -110.16924,
              33.458945
            ],
            [
              -110.143502,
              33.465753
            ],
            [
              -110.125649,
              33.465736
            ],
            [
              -110.079136,
              33.466181
            ],
            [
              -110.076375,
              33.466181
            ],
            [
              -110.056554,
              33.46613
            ],
            [
              -110.000647,
              33.488318
            ],
            [
              -110.000647,
              33.521259
            ],
            [
              -110.000649,
              33.57693
            ],
            [
              -110.00066,
              34.000046
            ],
            [
              -110.750672,
              34.000048
            ],
            [
              -110.750678,
              34.263314
            ],
            [
              -110.766816,
              34.258978
            ],
            [
              -110.761259,
              34.287872
            ],
            [
              -110.783111,
              34.299861
            ],
            [
              -110.788078,
              34.30906
            ],
            [
              -110.811029,
              34.295692
            ],
            [
              -110.845866,
              34.306766
            ],
            [
              -110.820306,
              34.288108
            ],
            [
              -110.837571,
              34.280754
            ],
            [
              -110.825941,
              34.275796
            ],
            [
              -110.847558,
              34.265638
            ],
            [
              -110.877411,
              34.275809
            ],
            [
              -110.896921,
              34.302447
            ],
            [
              -110.907619,
              34.300764
            ],
            [
              -110.946098,
              34.31728
            ],
            [
              -110.958863,
              34.314469
            ],
            [
              -110.975862,
              34.336837
            ],
            [
              -110.997311,
              34.344127
            ],
            [
              -111.003764,
              34.356777
            ],
            [
              -111.020034,
              34.362957
            ],
            [
              -111.033176,
              34.340934
            ],
            [
              -111.056698,
              34.35308
            ],
            [
              -111.039732,
              34.373292
            ],
            [
              -111.096661,
              34.396431
            ],
            [
              -111.118338,
              34.383041
            ],
            [
              -111.128736,
              34.390367
            ],
            [
              -111.147094,
              34.375185
            ],
            [
              -111.152066,
              34.39426
            ],
            [
              -111.184697,
              34.409992
            ],
            [
              -111.209571,
              34.408357
            ],
            [
              -111.222153,
              34.425648
            ],
            [
              -111.239899,
              34.431436
            ],
            [
              -111.250302,
              34.453274
            ],
            [
              -111.26146,
              34.443682
            ],
            [
              -111.311044,
              34.42795
            ],
            [
              -111.354063,
              34.424608
            ],
            [
              -111.351881,
              34.440997
            ],
            [
              -111.36685,
              34.440314
            ],
            [
              -111.398158,
              34.428029
            ],
            [
              -111.403397,
              34.397753
            ],
            [
              -111.386421,
              34.397801
            ],
            [
              -111.400332,
              34.378893
            ],
            [
              -111.418533,
              34.377287
            ],
            [
              -111.424729,
              34.394645
            ],
            [
              -111.423066,
              34.437225
            ],
            [
              -111.405249,
              34.428507
            ],
            [
              -111.3987,
              34.443827
            ],
            [
              -111.435634,
              34.446103
            ],
            [
              -111.451273,
              34.434856
            ],
            [
              -111.464588,
              34.409312
            ],
            [
              -111.480617,
              34.419546
            ],
            [
              -111.467466,
              34.437759
            ],
            [
              -111.486954,
              34.429817
            ],
            [
              -111.494971,
              34.433888
            ],
            [
              -111.512419,
              34.41566
            ],
            [
              -111.537548,
              34.412503
            ],
            [
              -111.550328,
              34.419057
            ],
            [
              -111.517657,
              34.430392
            ],
            [
              -111.508191,
              34.440959
            ],
            [
              -111.471448,
              34.457301
            ],
            [
              -111.485894,
              34.463782
            ],
            [
              -111.508965,
              34.454661
            ],
            [
              -111.528487,
              34.454513
            ],
            [
              -111.537761,
              34.459286
            ],
            [
              -111.512445,
              34.467836
            ],
            [
              -111.513654,
              34.49193
            ],
            [
              -111.502415,
              34.498057
            ],
            [
              -111.524562,
              34.494968
            ],
            [
              -111.521391,
              34.477818
            ],
            [
              -111.548785,
              34.460872
            ],
            [
              -111.554681,
              34.471733
            ],
            [
              -111.554599,
              34.439384
            ],
            [
              -111.581218,
              34.419709
            ],
            [
              -111.604807,
              34.416203
            ],
            [
              -111.618071,
              34.401329
            ],
            [
              -111.659644,
              34.386251
            ],
            [
              -111.656937,
              34.368392
            ],
            [
              -111.667262,
              34.348253
            ],
            [
              -111.662433,
              34.324642
            ],
            [
              -111.667869,
              34.30819
            ],
            [
              -111.674412,
              34.306163
            ],
            [
              -111.684575,
              34.297487
            ],
            [
              -111.675816,
              34.289383
            ],
            [
              -111.663522,
              34.294913
            ],
            [
              -111.665773,
              34.285567
            ],
            [
              -111.68348,
              34.282956
            ],
            [
              -111.677442,
              34.270106
            ],
            [
              -111.699858,
              34.240254
            ],
            [
              -111.704828,
              34.212204
            ],
            [
              -111.690186,
              34.200001
            ],
            [
              -111.69497,
              34.181203
            ],
            [
              -111.72057,
              34.161429
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "04",
      "COUNTYFP": "019",
      "COUNTYNS": "00025446",
      "AFFGEOID": "0500000US04019",
      "GEOID": "04019",
      "NAME": "Pima",
      "LSAD": "06",
      "ALAND": 23794788865,
      "AWATER": 5259325,
      "County_state": "Pima,04"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -113.333897,
              32.504938
            ],
            [
              -113.333767,
              32.038762576625295
            ],
            [
              -113.217308,
              32.002107
            ],
            [
              -113.125961,
              31.97278
            ],
            [
              -112.867074,
              31.895488
            ],
            [
              -112.39942,
              31.751757
            ],
            [
              -112.375758,
              31.743988
            ],
            [
              -112.365043,
              31.74113
            ],
            [
              -112.246102,
              31.704195
            ],
            [
              -111.979417,
              31.620683
            ],
            [
              -111.623115,
              31.5080316221176
            ],
            [
              -111.560194,
              31.488138
            ],
            [
              -111.366572295468,
              31.4261324300682
            ],
            [
              -111.366613,
              31.521291
            ],
            [
              -111.163725,
              31.521826
            ],
            [
              -111.164183,
              31.725983
            ],
            [
              -111.05804,
              31.72623
            ],
            [
              -110.855593,
              31.726653
            ],
            [
              -110.855632,
              31.731696
            ],
            [
              -110.451736,
              31.731267
            ],
            [
              -110.44769,
              32.250073
            ],
            [
              -110.451016,
              32.253799
            ],
            [
              -110.451503,
              32.427354
            ],
            [
              -110.451218,
              32.513995
            ],
            [
              -110.635458,
              32.513795
            ],
            [
              -110.785662,
              32.513837
            ],
            [
              -110.845097,
              32.513866
            ],
            [
              -110.85872,
              32.511223
            ],
            [
              -111.157493,
              32.511255
            ],
            [
              -111.157562,
              32.501655
            ],
            [
              -111.351531,
              32.501355
            ],
            [
              -111.569256,
              32.506769
            ],
            [
              -111.792534,
              32.506779
            ],
            [
              -111.794563,
              32.506766
            ],
            [
              -112.203719,
              32.50676
            ],
            [
              -113.109665,
              32.505166
            ],
            [
              -113.333897,
              32.504938
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "04",
      "COUNTYFP": "011",
      "COUNTYNS": "00042807",
      "AFFGEOID": "0500000US04011",
      "GEOID": "04011",
      "NAME": "Greenlee",
      "LSAD": "06",
      "ALAND": 4770701078,
      "AWATER": 13750461,
      "County_state": "Greenlee,04"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -109.49544,
              33.652697
            ],
            [
              -109.49574,
              33.078409
            ],
            [
              -109.333425,
              32.843266
            ],
            [
              -109.318149,
              32.842779
            ],
            [
              -109.252367,
              32.750239
            ],
            [
              -109.245005,
              32.741985
            ],
            [
              -109.213829,
              32.680661
            ],
            [
              -109.212959,
              32.653629
            ],
            [
              -109.206249,
              32.635803
            ],
            [
              -109.219485,
              32.601689
            ],
            [
              -109.211265,
              32.584293
            ],
            [
              -109.225953,
              32.543031
            ],
            [
              -109.22607,
              32.529096
            ],
            [
              -109.113987,
              32.426177
            ],
            [
              -109.047612003221,
              32.4263753884837
            ],
            [
              -109.047612,
              32.426377
            ],
            [
              -109.04764748009501,
              32.647047615744995
            ],
            [
              -109.047653,
              32.681379
            ],
            [
              -109.047653,
              32.686327
            ],
            [
              -109.047645,
              32.689988
            ],
            [
              -109.047638,
              32.693439
            ],
            [
              -109.047117,
              32.77757
            ],
            [
              -109.047117279572,
              32.777794004252
            ],
            [
              -109.04748,
              33.06842
            ],
            [
              -109.047453,
              33.069427
            ],
            [
              -109.046905,
              33.091931
            ],
            [
              -109.047013,
              33.092917
            ],
            [
              -109.047117,
              33.137559
            ],
            [
              -109.047116,
              33.137995
            ],
            [
              -109.04723688070601,
              33.2088950306819
            ],
            [
              -109.047237,
              33.208965
            ],
            [
              -109.04747,
              33.250063
            ],
            [
              -109.046827,
              33.365272
            ],
            [
              -109.046909,
              33.36557
            ],
            [
              -109.047045,
              33.36928
            ],
            [
              -109.04687,
              33.372654
            ],
            [
              -109.046564,
              33.37506
            ],
            [
              -109.047298,
              33.409783
            ],
            [
              -109.046662,
              33.625055
            ],
            [
              -109.047145,
              33.74001
            ],
            [
              -109.04694152920001,
              33.7782257216099
            ],
            [
              -109.347968,
              33.777456
            ],
            [
              -109.362758,
              33.752142
            ],
            [
              -109.364621,
              33.738756
            ],
            [
              -109.352975,
              33.724911
            ],
            [
              -109.378472,
              33.724641
            ],
            [
              -109.412471,
              33.708444
            ],
            [
              -109.441782,
              33.713052
            ],
            [
              -109.461738,
              33.696412
            ],
            [
              -109.480135,
              33.69141
            ],
            [
              -109.4817,
              33.654836
            ],
            [
              -109.49544,
              33.652697
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "04",
      "COUNTYFP": "021",
      "COUNTYNS": "00025447",
      "AFFGEOID": "0500000US04021",
      "GEOID": "04021",
      "NAME": "Pinal",
      "LSAD": "06",
      "ALAND": 13897471114,
      "AWATER": 22332471,
      "County_state": "Pinal,04"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -112.203509,
              32.683596
            ],
            [
              -112.203719,
              32.50676
            ],
            [
              -111.794563,
              32.506766
            ],
            [
              -111.792534,
              32.506779
            ],
            [
              -111.569256,
              32.506769
            ],
            [
              -111.351531,
              32.501355
            ],
            [
              -111.157562,
              32.501655
            ],
            [
              -111.157493,
              32.511255
            ],
            [
              -110.85872,
              32.511223
            ],
            [
              -110.845097,
              32.513866
            ],
            [
              -110.785662,
              32.513837
            ],
            [
              -110.635458,
              32.513795
            ],
            [
              -110.451218,
              32.513995
            ],
            [
              -110.449151,
              32.519469
            ],
            [
              -110.449407,
              32.987416
            ],
            [
              -110.44941,
              32.990169
            ],
            [
              -110.449021,
              33.194526
            ],
            [
              -110.463294,
              33.18334
            ],
            [
              -110.504733,
              33.186248
            ],
            [
              -110.534323,
              33.167713
            ],
            [
              -110.545957,
              33.168764
            ],
            [
              -110.549703,
              33.153094
            ],
            [
              -110.579445,
              33.147132
            ],
            [
              -110.593459,
              33.133926
            ],
            [
              -110.61126,
              33.143121
            ],
            [
              -110.632529,
              33.142771
            ],
            [
              -110.656777,
              33.11354
            ],
            [
              -110.677893,
              33.111365
            ],
            [
              -110.683286,
              33.088118
            ],
            [
              -110.693334,
              33.077052
            ],
            [
              -110.706223,
              33.083731
            ],
            [
              -110.725583,
              33.069896
            ],
            [
              -110.716826,
              33.052819
            ],
            [
              -110.729344,
              33.027305
            ],
            [
              -110.74659,
              33.010832
            ],
            [
              -110.765106,
              33.002859
            ],
            [
              -110.766386,
              32.985354
            ],
            [
              -110.783136,
              32.98466
            ],
            [
              -110.946684,
              33.250053
            ],
            [
              -110.955497,
              33.279139
            ],
            [
              -110.974706,
              33.342095
            ],
            [
              -111.039976,
              33.466019
            ],
            [
              -111.442165,
              33.465898
            ],
            [
              -111.580634,
              33.4658
            ],
            [
              -111.580636,
              33.44384
            ],
            [
              -111.580696,
              33.37874
            ],
            [
              -111.583183,
              33.277243
            ],
            [
              -111.582683,
              33.218888
            ],
            [
              -111.583047,
              33.20545
            ],
            [
              -111.634445,
              33.205067
            ],
            [
              -111.686035,
              33.204637
            ],
            [
              -111.755426,
              33.204782
            ],
            [
              -111.8889,
              33.203381
            ],
            [
              -111.893085,
              33.204811
            ],
            [
              -112.083488,
              33.20456
            ],
            [
              -112.095812,
              33.230356
            ],
            [
              -112.113683,
              33.227474
            ],
            [
              -112.149119,
              33.253812
            ],
            [
              -112.191784,
              33.265039
            ],
            [
              -112.197512,
              33.278436
            ],
            [
              -112.193691,
              33.298944
            ],
            [
              -112.203288,
              33.310533
            ],
            [
              -112.202163,
              33.246862
            ],
            [
              -112.201965,
              33.08702
            ],
            [
              -112.203509,
              32.683596
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "04",
      "COUNTYFP": "017",
      "COUNTYNS": "00042808",
      "AFFGEOID": "0500000US04017",
      "GEOID": "04017",
      "NAME": "Navajo",
      "LSAD": "06",
      "ALAND": 25770094249,
      "AWATER": 24078111,
      "County_state": "Navajo,04"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -110.750696,
              36.713125
            ],
            [
              -110.750692,
              35.064868
            ],
            [
              -110.750686,
              34.720755
            ],
            [
              -110.750686,
              34.71821
            ],
            [
              -110.750678,
              34.281909
            ],
            [
              -110.750678,
              34.264671
            ],
            [
              -110.750678,
              34.263314
            ],
            [
              -110.750672,
              34.000048
            ],
            [
              -110.00066,
              34.000046
            ],
            [
              -110.000649,
              33.57693
            ],
            [
              -109.995967,
              33.58226
            ],
            [
              -109.961109,
              33.579576
            ],
            [
              -109.923331,
              33.567514
            ],
            [
              -109.916033,
              33.574868
            ],
            [
              -109.891759,
              33.567829
            ],
            [
              -109.891766,
              33.777777
            ],
            [
              -109.859274,
              33.777683
            ],
            [
              -109.859393,
              34.125991
            ],
            [
              -109.849387,
              34.125945
            ],
            [
              -109.848684,
              34.283922
            ],
            [
              -109.848246,
              34.473435
            ],
            [
              -109.849025,
              34.488272
            ],
            [
              -109.865231,
              34.559689
            ],
            [
              -109.850508,
              34.647301
            ],
            [
              -109.851801,
              34.822519
            ],
            [
              -109.844624,
              34.822562
            ],
            [
              -109.843663,
              34.966242
            ],
            [
              -109.842903,
              35.170402
            ],
            [
              -109.843764,
              35.517875
            ],
            [
              -109.826893,
              35.517874
            ],
            [
              -109.826936,
              35.662484
            ],
            [
              -110.000674,
              35.662657
            ],
            [
              -110.00068203791601,
              36.997981518830194
            ],
            [
              -110.000876,
              36.998502
            ],
            [
              -110.021778,
              36.998602
            ],
            [
              -110.47019,
              36.997997
            ],
            [
              -110.490908,
              37.003566
            ],
            [
              -110.50069,
              37.00426
            ],
            [
              -110.599512,
              37.003448
            ],
            [
              -110.625605,
              37.003416
            ],
            [
              -110.62569,
              37.003721
            ],
            [
              -110.75069,
              37.003197
            ],
            [
              -110.750696,
              36.713125
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "04",
      "COUNTYFP": "027",
      "COUNTYNS": "00023901",
      "AFFGEOID": "0500000US04027",
      "GEOID": "04027",
      "NAME": "Yuma",
      "LSAD": "06",
      "ALAND": 14281265651,
      "AWATER": 13171316,
      "County_state": "Yuma,04"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -114.799683,
              32.593621
            ],
            [
              -114.814185,
              32.564788
            ],
            [
              -114.795635,
              32.550956
            ],
            [
              -114.811536,
              32.522834
            ],
            [
              -114.812316,
              32.500054
            ],
            [
              -114.813991,
              32.497231
            ],
            [
              -114.813613,
              32.494277
            ],
            [
              -114.50078,
              32.400057
            ],
            [
              -114.250775,
              32.32391
            ],
            [
              -113.78168,
              32.179034
            ],
            [
              -113.750756,
              32.169005
            ],
            [
              -113.493196,
              32.088943
            ],
            [
              -113.333767,
              32.038762576625295
            ],
            [
              -113.333897,
              32.504938
            ],
            [
              -113.333709,
              32.767851
            ],
            [
              -113.333786,
              33.023476
            ],
            [
              -113.33392,
              33.377425
            ],
            [
              -113.958046,
              33.377578
            ],
            [
              -113.958042,
              33.464655
            ],
            [
              -114.136428,
              33.463681
            ],
            [
              -114.267815,
              33.463574
            ],
            [
              -114.268748,
              33.029815
            ],
            [
              -114.339153,
              33.029997
            ],
            [
              -114.371131,
              33.027513
            ],
            [
              -114.51648387559901,
              33.0275718412186
            ],
            [
              -114.5149,
              33.026524
            ],
            [
              -114.511343,
              33.023455
            ],
            [
              -114.50613,
              33.01701
            ],
            [
              -114.502871,
              33.011153
            ],
            [
              -114.499797,
              33.003905
            ],
            [
              -114.497315,
              32.991474
            ],
            [
              -114.495712,
              32.980076
            ],
            [
              -114.494212,
              32.974262
            ],
            [
              -114.492938,
              32.971781
            ],
            [
              -114.490129,
              32.969885
            ],
            [
              -114.488625,
              32.969946
            ],
            [
              -114.481315,
              32.972064
            ],
            [
              -114.480417,
              32.973665
            ],
            [
              -114.476156,
              32.975168
            ],
            [
              -114.470988,
              32.97406
            ],
            [
              -114.469039,
              32.972295
            ],
            [
              -114.467664,
              32.966861
            ],
            [
              -114.467272,
              32.960675
            ],
            [
              -114.46773,
              32.956323
            ],
            [
              -114.469113,
              32.952673
            ],
            [
              -114.470833,
              32.949333
            ],
            [
              -114.474042,
              32.94515
            ],
            [
              -114.478456,
              32.940555
            ],
            [
              -114.48074,
              32.937027
            ],
            [
              -114.48092,
              32.935252
            ],
            [
              -114.479005,
              32.928291
            ],
            [
              -114.47664,
              32.923628
            ],
            [
              -114.473713,
              32.920594
            ],
            [
              -114.464448,
              32.913129
            ],
            [
              -114.46365,
              32.911682
            ],
            [
              -114.462929,
              32.907944
            ],
            [
              -114.463127,
              32.901884
            ],
            [
              -114.465715,
              32.87942
            ],
            [
              -114.465715,
              32.879191
            ],
            [
              -114.465546,
              32.874809
            ],
            [
              -114.468971,
              32.845155
            ],
            [
              -114.475892,
              32.838694
            ],
            [
              -114.494116,
              32.823288
            ],
            [
              -114.496284,
              32.822326
            ],
            [
              -114.496827,
              32.822119
            ],
            [
              -114.510217,
              32.816417
            ],
            [
              -114.515389,
              32.811439
            ],
            [
              -114.519758,
              32.805676
            ],
            [
              -114.520385,
              32.803577
            ],
            [
              -114.522031,
              32.801675
            ],
            [
              -114.528849,
              32.796307
            ],
            [
              -114.530755,
              32.793485
            ],
            [
              -114.531669,
              32.791185
            ],
            [
              -114.531746,
              32.782503
            ],
            [
              -114.532432,
              32.776923
            ],
            [
              -114.531831,
              32.774264
            ],
            [
              -114.528443,
              32.767276
            ],
            [
              -114.526856,
              32.757094
            ],
            [
              -114.539093,
              32.756949
            ],
            [
              -114.614772,
              32.734089
            ],
            [
              -114.615733,
              32.729427
            ],
            [
              -114.615585,
              32.728446
            ],
            [
              -114.615669227736,
              32.7284399276274
            ],
            [
              -114.618373,
              32.728245
            ],
            [
              -114.632686,
              32.730846
            ],
            [
              -114.65826,
              32.733799
            ],
            [
              -114.65884,
              32.73383
            ],
            [
              -114.667493,
              32.734226
            ],
            [
              -114.677091,
              32.736218
            ],
            [
              -114.678632,
              32.736614
            ],
            [
              -114.684278,
              32.737537
            ],
            [
              -114.688779,
              32.737675
            ],
            [
              -114.69879,
              32.744846
            ],
            [
              -114.700743,
              32.745617
            ],
            [
              -114.701918,
              32.745548
            ],
            [
              -114.705717,
              32.741581
            ],
            [
              -114.714522,
              32.73039
            ],
            [
              -114.715788,
              32.727758
            ],
            [
              -114.717665,
              32.721654
            ],
            [
              -114.719633,
              32.718763
            ],
            [
              -114.722746,
              32.713071
            ],
            [
              -114.730086,
              32.704298
            ],
            [
              -114.730041,
              32.699675
            ],
            [
              -114.730453,
              32.698843
            ],
            [
              -114.76495,
              32.649391
            ],
            [
              -114.782235,
              32.630215
            ],
            [
              -114.797565,
              32.624578
            ],
            [
              -114.809393,
              32.617119
            ],
            [
              -114.799683,
              32.593621
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "04",
      "COUNTYFP": "013",
      "COUNTYNS": "00037026",
      "AFFGEOID": "0500000US04013",
      "GEOID": "04013",
      "NAME": "Maricopa",
      "LSAD": "06",
      "ALAND": 23825875275,
      "AWATER": 64310266,
      "County_state": "Maricopa,04"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -113.33392,
              33.377425
            ],
            [
              -113.333786,
              33.023476
            ],
            [
              -113.333709,
              32.767851
            ],
            [
              -113.333897,
              32.504938
            ],
            [
              -113.109665,
              32.505166
            ],
            [
              -112.203719,
              32.50676
            ],
            [
              -112.203509,
              32.683596
            ],
            [
              -112.201965,
              33.08702
            ],
            [
              -112.202163,
              33.246862
            ],
            [
              -112.203288,
              33.310533
            ],
            [
              -112.193691,
              33.298944
            ],
            [
              -112.197512,
              33.278436
            ],
            [
              -112.191784,
              33.265039
            ],
            [
              -112.149119,
              33.253812
            ],
            [
              -112.113683,
              33.227474
            ],
            [
              -112.095812,
              33.230356
            ],
            [
              -112.083488,
              33.20456
            ],
            [
              -111.893085,
              33.204811
            ],
            [
              -111.8889,
              33.203381
            ],
            [
              -111.755426,
              33.204782
            ],
            [
              -111.686035,
              33.204637
            ],
            [
              -111.634445,
              33.205067
            ],
            [
              -111.583047,
              33.20545
            ],
            [
              -111.582683,
              33.218888
            ],
            [
              -111.583183,
              33.277243
            ],
            [
              -111.580696,
              33.37874
            ],
            [
              -111.580636,
              33.44384
            ],
            [
              -111.580634,
              33.4658
            ],
            [
              -111.442165,
              33.465898
            ],
            [
              -111.039976,
              33.466019
            ],
            [
              -111.058369,
              33.500051
            ],
            [
              -111.153354,
              33.677606
            ],
            [
              -111.180619,
              33.659792
            ],
            [
              -111.209444,
              33.633499
            ],
            [
              -111.207453,
              33.620572
            ],
            [
              -111.222055,
              33.602446
            ],
            [
              -111.249538,
              33.624331
            ],
            [
              -111.26173,
              33.622571
            ],
            [
              -111.28649,
              33.657375
            ],
            [
              -111.313811,
              33.68047
            ],
            [
              -111.331394,
              33.688338
            ],
            [
              -111.338125,
              33.718676
            ],
            [
              -111.359345,
              33.74097
            ],
            [
              -111.386812,
              33.750044
            ],
            [
              -111.389444,
              33.776663
            ],
            [
              -111.378909,
              33.791877
            ],
            [
              -111.387912,
              33.803214
            ],
            [
              -111.372317,
              33.823924
            ],
            [
              -111.395787,
              33.844276
            ],
            [
              -111.387459,
              33.862957
            ],
            [
              -111.397723,
              33.877823
            ],
            [
              -111.412331,
              33.884725
            ],
            [
              -111.405356,
              33.919582
            ],
            [
              -111.425749,
              33.926099
            ],
            [
              -111.437487,
              33.916174
            ],
            [
              -111.450904,
              33.919877
            ],
            [
              -111.447389,
              33.941735
            ],
            [
              -111.452925,
              33.962204
            ],
            [
              -111.433271,
              33.983321
            ],
            [
              -111.445079,
              34.001176
            ],
            [
              -111.466532,
              34.005943
            ],
            [
              -111.494778,
              33.999817
            ],
            [
              -111.72531,
              33.999849
            ],
            [
              -111.861997,
              34.015108
            ],
            [
              -112.164758,
              34.04817
            ],
            [
              -112.153647,
              34.040909
            ],
            [
              -112.16535,
              34.033132
            ],
            [
              -112.161745,
              34.019273
            ],
            [
              -112.183239,
              34.01194
            ],
            [
              -112.180391,
              34.005742
            ],
            [
              -112.193374,
              33.990303
            ],
            [
              -112.195658,
              33.974502
            ],
            [
              -112.214482,
              33.96809
            ],
            [
              -112.241262,
              33.94111
            ],
            [
              -112.231896,
              33.936531
            ],
            [
              -112.235528,
              33.915475
            ],
            [
              -112.242962,
              33.905577
            ],
            [
              -112.264425,
              33.899094
            ],
            [
              -112.27604,
              33.882312
            ],
            [
              -112.427931,
              33.920839
            ],
            [
              -112.743951,
              33.999898
            ],
            [
              -113.333753,
              33.999227
            ],
            [
              -113.333331,
              33.566289
            ],
            [
              -113.33392,
              33.377425
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "04",
      "COUNTYFP": "009",
      "COUNTYNS": "00025444",
      "AFFGEOID": "0500000US04009",
      "GEOID": "04009",
      "NAME": "Graham",
      "LSAD": "06",
      "ALAND": 11972778533,
      "AWATER": 47436491,
      "County_state": "Graham,04"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -110.457435,
              33.294978
            ],
            [
              -110.45701,
              33.29142
            ],
            [
              -110.454928,
              33.291151
            ],
            [
              -110.453598,
              33.28618
            ],
            [
              -110.454972,
              33.282328
            ],
            [
              -110.45175,
              33.279797
            ],
            [
              -110.44883,
              33.271934
            ],
            [
              -110.449006,
              33.266556
            ],
            [
              -110.44419,
              33.262504
            ],
            [
              -110.44462,
              33.257512
            ],
            [
              -110.438915,
              33.253051
            ],
            [
              -110.436623,
              33.249739
            ],
            [
              -110.424903,
              33.231879
            ],
            [
              -110.420104,
              33.220917
            ],
            [
              -110.408201,
              33.204203
            ],
            [
              -110.405824,
              33.197231
            ],
            [
              -110.407236,
              33.193482
            ],
            [
              -110.413878,
              33.191777
            ],
            [
              -110.420122,
              33.192352
            ],
            [
              -110.427978,
              33.191612
            ],
            [
              -110.434412,
              33.188577
            ],
            [
              -110.444694,
              33.189352
            ],
            [
              -110.448257,
              33.19391
            ],
            [
              -110.449021,
              33.194526
            ],
            [
              -110.44941,
              32.990169
            ],
            [
              -110.449407,
              32.987416
            ],
            [
              -110.449151,
              32.519469
            ],
            [
              -110.451218,
              32.513995
            ],
            [
              -110.451503,
              32.427354
            ],
            [
              -110.349061,
              32.426505
            ],
            [
              -110.170441,
              32.426378
            ],
            [
              -109.227815,
              32.424475
            ],
            [
              -109.222202,
              32.426462
            ],
            [
              -109.113987,
              32.426177
            ],
            [
              -109.22607,
              32.529096
            ],
            [
              -109.225953,
              32.543031
            ],
            [
              -109.211265,
              32.584293
            ],
            [
              -109.219485,
              32.601689
            ],
            [
              -109.206249,
              32.635803
            ],
            [
              -109.212959,
              32.653629
            ],
            [
              -109.213829,
              32.680661
            ],
            [
              -109.245005,
              32.741985
            ],
            [
              -109.252367,
              32.750239
            ],
            [
              -109.318149,
              32.842779
            ],
            [
              -109.333425,
              32.843266
            ],
            [
              -109.49574,
              33.078409
            ],
            [
              -109.49544,
              33.652697
            ],
            [
              -109.514832,
              33.641715
            ],
            [
              -109.528184,
              33.622918
            ],
            [
              -109.555093,
              33.620182
            ],
            [
              -109.559394,
              33.626423
            ],
            [
              -109.581743,
              33.612851
            ],
            [
              -109.602881,
              33.612875
            ],
            [
              -109.604156,
              33.601378
            ],
            [
              -109.621271,
              33.583212
            ],
            [
              -109.660484,
              33.583726
            ],
            [
              -109.675528,
              33.573238
            ],
            [
              -109.671937,
              33.55934
            ],
            [
              -109.684528,
              33.543781
            ],
            [
              -109.713101,
              33.525194
            ],
            [
              -109.731461,
              33.521057
            ],
            [
              -109.744044,
              33.503272
            ],
            [
              -109.74657,
              33.484896
            ],
            [
              -109.767273,
              33.475417
            ],
            [
              -109.784023,
              33.476556
            ],
            [
              -109.799782,
              33.488314
            ],
            [
              -109.812297,
              33.48369
            ],
            [
              -109.804107,
              33.503501
            ],
            [
              -109.822617,
              33.508882
            ],
            [
              -109.8195,
              33.52098
            ],
            [
              -109.833537,
              33.535037
            ],
            [
              -109.877072,
              33.559027
            ],
            [
              -109.881552,
              33.571251
            ],
            [
              -109.891759,
              33.567829
            ],
            [
              -109.916033,
              33.574868
            ],
            [
              -109.923331,
              33.567514
            ],
            [
              -109.961109,
              33.579576
            ],
            [
              -109.995967,
              33.58226
            ],
            [
              -110.000649,
              33.57693
            ],
            [
              -110.000647,
              33.521259
            ],
            [
              -110.000647,
              33.488318
            ],
            [
              -110.056554,
              33.46613
            ],
            [
              -110.076375,
              33.466181
            ],
            [
              -110.079136,
              33.466181
            ],
            [
              -110.125649,
              33.465736
            ],
            [
              -110.143502,
              33.465753
            ],
            [
              -110.16924,
              33.458945
            ],
            [
              -110.169168,
              33.454816
            ],
            [
              -110.174088,
              33.450072
            ],
            [
              -110.180299,
              33.449835
            ],
            [
              -110.182477,
              33.448973
            ],
            [
              -110.18632,
              33.448753
            ],
            [
              -110.187849,
              33.450381
            ],
            [
              -110.19324,
              33.447307
            ],
            [
              -110.191803,
              33.444553
            ],
            [
              -110.193311,
              33.442194
            ],
            [
              -110.197837,
              33.44304
            ],
            [
              -110.201397,
              33.440128
            ],
            [
              -110.206283,
              33.439372
            ],
            [
              -110.208185,
              33.441131
            ],
            [
              -110.211766,
              33.440378
            ],
            [
              -110.213562,
              33.437755
            ],
            [
              -110.220133,
              33.438809
            ],
            [
              -110.22399,
              33.440463
            ],
            [
              -110.231598,
              33.442583
            ],
            [
              -110.235059,
              33.440123
            ],
            [
              -110.240929,
              33.440154
            ],
            [
              -110.251207,
              33.438214
            ],
            [
              -110.261065,
              33.436388
            ],
            [
              -110.27088,
              33.439557
            ],
            [
              -110.275582,
              33.440731
            ],
            [
              -110.282548,
              33.435988
            ],
            [
              -110.290353,
              33.430783
            ],
            [
              -110.297894,
              33.428899
            ],
            [
              -110.304012,
              33.430939
            ],
            [
              -110.310432,
              33.431533
            ],
            [
              -110.319757,
              33.432985
            ],
            [
              -110.326871,
              33.428404
            ],
            [
              -110.334385,
              33.428085
            ],
            [
              -110.341191,
              33.426929
            ],
            [
              -110.341059,
              33.422189
            ],
            [
              -110.346597,
              33.417795
            ],
            [
              -110.346773,
              33.413241
            ],
            [
              -110.349341,
              33.408683
            ],
            [
              -110.351143,
              33.408562
            ],
            [
              -110.354006,
              33.404069
            ],
            [
              -110.359891,
              33.399921
            ],
            [
              -110.366544,
              33.399413
            ],
            [
              -110.369257,
              33.398383
            ],
            [
              -110.378725,
              33.402428
            ],
            [
              -110.382708,
              33.405656
            ],
            [
              -110.386852,
              33.405565
            ],
            [
              -110.389134,
              33.402999
            ],
            [
              -110.39843,
              33.402095
            ],
            [
              -110.40711,
              33.399881
            ],
            [
              -110.41086,
              33.400317
            ],
            [
              -110.414269,
              33.398349
            ],
            [
              -110.417076,
              33.393236
            ],
            [
              -110.41825,
              33.388871
            ],
            [
              -110.421783,
              33.385968
            ],
            [
              -110.424831,
              33.38714
            ],
            [
              -110.429976,
              33.387442
            ],
            [
              -110.436414,
              33.384518
            ],
            [
              -110.442982,
              33.377376
            ],
            [
              -110.442653,
              33.372355
            ],
            [
              -110.442477,
              33.370275
            ],
            [
              -110.443084,
              33.367868
            ],
            [
              -110.447616,
              33.365935
            ],
            [
              -110.451672,
              33.363269
            ],
            [
              -110.449869,
              33.358573
            ],
            [
              -110.44713,
              33.355438
            ],
            [
              -110.445092,
              33.350535
            ],
            [
              -110.448034,
              33.346324
            ],
            [
              -110.448332,
              33.342276
            ],
            [
              -110.447299,
              33.336101
            ],
            [
              -110.446833,
              33.330575
            ],
            [
              -110.446519,
              33.323465
            ],
            [
              -110.450388,
              33.317709
            ],
            [
              -110.449104,
              33.312969
            ],
            [
              -110.448913,
              33.30791
            ],
            [
              -110.448893,
              33.303033
            ],
            [
              -110.448035,
              33.301796
            ],
            [
              -110.449658,
              33.297777
            ],
            [
              -110.453021,
              33.296864
            ],
            [
              -110.457435,
              33.294978
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "04",
      "COUNTYFP": "003",
      "COUNTYNS": "00025442",
      "AFFGEOID": "0500000US04003",
      "GEOID": "04003",
      "NAME": "Cochise",
      "LSAD": "06",
      "ALAND": 15966166113,
      "AWATER": 137024922,
      "County_state": "Cochise,04"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -110.460564,
              31.614037
            ],
            [
              -110.460917,
              31.469699
            ],
            [
              -110.460172,
              31.332827
            ],
            [
              -110.140512,
              31.333965
            ],
            [
              -110.000613,
              31.333145
            ],
            [
              -109.829689,
              31.334067
            ],
            [
              -109.278489,
              31.333959
            ],
            [
              -109.050044,
              31.332502
            ],
            [
              -109.050173,
              31.480004
            ],
            [
              -109.049843,
              31.499515
            ],
            [
              -109.049813,
              31.499528
            ],
            [
              -109.049112,
              31.636598
            ],
            [
              -109.049195,
              31.796551
            ],
            [
              -109.048763,
              31.810776
            ],
            [
              -109.049106,
              31.843715
            ],
            [
              -109.048769,
              31.861383
            ],
            [
              -109.04859,
              31.870791
            ],
            [
              -109.048599,
              32.013651
            ],
            [
              -109.048731,
              32.028174
            ],
            [
              -109.048296,
              32.084093
            ],
            [
              -109.048286,
              32.089114
            ],
            [
              -109.047612003221,
              32.4263753884837
            ],
            [
              -109.113987,
              32.426177
            ],
            [
              -109.222202,
              32.426462
            ],
            [
              -109.227815,
              32.424475
            ],
            [
              -110.170441,
              32.426378
            ],
            [
              -110.349061,
              32.426505
            ],
            [
              -110.451503,
              32.427354
            ],
            [
              -110.451016,
              32.253799
            ],
            [
              -110.44769,
              32.250073
            ],
            [
              -110.451736,
              31.731267
            ],
            [
              -110.452247,
              31.613936
            ],
            [
              -110.460564,
              31.614037
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "04",
      "COUNTYFP": "001",
      "COUNTYNS": "00025441",
      "AFFGEOID": "0500000US04001",
      "GEOID": "04001",
      "NAME": "Apache",
      "LSAD": "06",
      "ALAND": 29001298929,
      "AWATER": 54174161,
      "County_state": "Apache,04"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -110.000677,
              36.997968
            ],
            [
              -110.00068203791601,
              36.997981518830194
            ],
            [
              -110.000674,
              35.662657
            ],
            [
              -109.826936,
              35.662484
            ],
            [
              -109.826893,
              35.517874
            ],
            [
              -109.843764,
              35.517875
            ],
            [
              -109.842903,
              35.170402
            ],
            [
              -109.843663,
              34.966242
            ],
            [
              -109.844624,
              34.822562
            ],
            [
              -109.851801,
              34.822519
            ],
            [
              -109.850508,
              34.647301
            ],
            [
              -109.865231,
              34.559689
            ],
            [
              -109.849025,
              34.488272
            ],
            [
              -109.848246,
              34.473435
            ],
            [
              -109.848684,
              34.283922
            ],
            [
              -109.849387,
              34.125945
            ],
            [
              -109.859393,
              34.125991
            ],
            [
              -109.859274,
              33.777683
            ],
            [
              -109.891766,
              33.777777
            ],
            [
              -109.891759,
              33.567829
            ],
            [
              -109.881552,
              33.571251
            ],
            [
              -109.877072,
              33.559027
            ],
            [
              -109.833537,
              33.535037
            ],
            [
              -109.8195,
              33.52098
            ],
            [
              -109.822617,
              33.508882
            ],
            [
              -109.804107,
              33.503501
            ],
            [
              -109.812297,
              33.48369
            ],
            [
              -109.799782,
              33.488314
            ],
            [
              -109.784023,
              33.476556
            ],
            [
              -109.767273,
              33.475417
            ],
            [
              -109.74657,
              33.484896
            ],
            [
              -109.744044,
              33.503272
            ],
            [
              -109.731461,
              33.521057
            ],
            [
              -109.713101,
              33.525194
            ],
            [
              -109.684528,
              33.543781
            ],
            [
              -109.671937,
              33.55934
            ],
            [
              -109.675528,
              33.573238
            ],
            [
              -109.660484,
              33.583726
            ],
            [
              -109.621271,
              33.583212
            ],
            [
              -109.604156,
              33.601378
            ],
            [
              -109.602881,
              33.612875
            ],
            [
              -109.581743,
              33.612851
            ],
            [
              -109.559394,
              33.626423
            ],
            [
              -109.555093,
              33.620182
            ],
            [
              -109.528184,
              33.622918
            ],
            [
              -109.514832,
              33.641715
            ],
            [
              -109.49544,
              33.652697
            ],
            [
              -109.4817,
              33.654836
            ],
            [
              -109.480135,
              33.69141
            ],
            [
              -109.461738,
              33.696412
            ],
            [
              -109.441782,
              33.713052
            ],
            [
              -109.412471,
              33.708444
            ],
            [
              -109.378472,
              33.724641
            ],
            [
              -109.352975,
              33.724911
            ],
            [
              -109.364621,
              33.738756
            ],
            [
              -109.362758,
              33.752142
            ],
            [
              -109.347968,
              33.777456
            ],
            [
              -109.04694152920001,
              33.7782257216099
            ],
            [
              -109.046426,
              33.875052
            ],
            [
              -109.047006,
              34.00005
            ],
            [
              -109.046182,
              34.522393
            ],
            [
              -109.046182,
              34.522553
            ],
            [
              -109.046156,
              34.579291
            ],
            [
              -109.046086,
              34.771016
            ],
            [
              -109.045363,
              34.785406
            ],
            [
              -109.046104,
              34.799981
            ],
            [
              -109.045624,
              34.814226
            ],
            [
              -109.046072,
              34.828566
            ],
            [
              -109.045851,
              34.959718
            ],
            [
              -109.045851080673,
              34.95981851532
            ],
            [
              -109.046024192748,
              35.1755079878063
            ],
            [
              -109.046084,
              35.250025
            ],
            [
              -109.04650442603601,
              35.3170929910607
            ],
            [
              -109.04666527206801,
              35.3427517735473
            ],
            [
              -109.046796,
              35.363606
            ],
            [
              -109.046481,
              35.546326
            ],
            [
              -109.046509,
              35.54644
            ],
            [
              -109.046296,
              35.614251
            ],
            [
              -109.046295,
              35.616517
            ],
            [
              -109.046024,
              35.8798
            ],
            [
              -109.046055,
              35.888721
            ],
            [
              -109.046054,
              35.92586
            ],
            [
              -109.046011,
              35.925896
            ],
            [
              -109.045973,
              36.002338
            ],
            [
              -109.045972227078,
              36.0027013051401
            ],
            [
              -109.045729,
              36.117028
            ],
            [
              -109.046183,
              36.181751
            ],
            [
              -109.045868336264,
              36.31491819928
            ],
            [
              -109.045431,
              36.500001
            ],
            [
              -109.0454311461,
              36.5273646779981
            ],
            [
              -109.045433,
              36.874589
            ],
            [
              -109.045407,
              36.874998
            ],
            [
              -109.045272,
              36.968871
            ],
            [
              -109.045244,
              36.969489
            ],
            [
              -109.045223,
              36.999084
            ],
            [
              -109.181196,
              36.999271
            ],
            [
              -109.233848,
              36.999266
            ],
            [
              -109.246917,
              36.999346
            ],
            [
              -109.26339,
              36.999263
            ],
            [
              -109.268213,
              36.999242
            ],
            [
              -109.270097,
              36.999266
            ],
            [
              -109.378039,
              36.999135
            ],
            [
              -109.381226,
              36.999148
            ],
            [
              -109.495338,
              36.999105
            ],
            [
              -109.625668,
              36.998308
            ],
            [
              -109.875673,
              36.998504
            ],
            [
              -110.000677,
              36.997968
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "04",
      "COUNTYFP": "025",
      "COUNTYNS": "00042809",
      "AFFGEOID": "0500000US04025",
      "GEOID": "04025",
      "NAME": "Yavapai",
      "LSAD": "06",
      "ALAND": 21039796372,
      "AWATER": 11501575,
      "County_state": "Yavapai,04"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -113.334161,
              35.528037
            ],
            [
              -113.334105,
              35.508416
            ],
            [
              -113.333026,
              34.951631
            ],
            [
              -113.333508,
              34.317877
            ],
            [
              -113.333313,
              34.000027
            ],
            [
              -113.333753,
              33.999227
            ],
            [
              -112.743951,
              33.999898
            ],
            [
              -112.427931,
              33.920839
            ],
            [
              -112.27604,
              33.882312
            ],
            [
              -112.264425,
              33.899094
            ],
            [
              -112.242962,
              33.905577
            ],
            [
              -112.235528,
              33.915475
            ],
            [
              -112.231896,
              33.936531
            ],
            [
              -112.241262,
              33.94111
            ],
            [
              -112.214482,
              33.96809
            ],
            [
              -112.195658,
              33.974502
            ],
            [
              -112.193374,
              33.990303
            ],
            [
              -112.180391,
              34.005742
            ],
            [
              -112.183239,
              34.01194
            ],
            [
              -112.161745,
              34.019273
            ],
            [
              -112.16535,
              34.033132
            ],
            [
              -112.153647,
              34.040909
            ],
            [
              -112.164758,
              34.04817
            ],
            [
              -111.861997,
              34.015108
            ],
            [
              -111.72531,
              33.999849
            ],
            [
              -111.494778,
              33.999817
            ],
            [
              -111.493241,
              34.038101
            ],
            [
              -111.473471,
              34.041303
            ],
            [
              -111.464391,
              34.07266
            ],
            [
              -111.485909,
              34.095007
            ],
            [
              -111.471372,
              34.11154
            ],
            [
              -111.48198,
              34.131587
            ],
            [
              -111.493648,
              34.133978
            ],
            [
              -111.477853,
              34.151108
            ],
            [
              -111.712118,
              34.151271
            ],
            [
              -111.72057,
              34.161429
            ],
            [
              -111.69497,
              34.181203
            ],
            [
              -111.690186,
              34.200001
            ],
            [
              -111.704828,
              34.212204
            ],
            [
              -111.699858,
              34.240254
            ],
            [
              -111.677442,
              34.270106
            ],
            [
              -111.68348,
              34.282956
            ],
            [
              -111.665773,
              34.285567
            ],
            [
              -111.663522,
              34.294913
            ],
            [
              -111.675816,
              34.289383
            ],
            [
              -111.684575,
              34.297487
            ],
            [
              -111.674412,
              34.306163
            ],
            [
              -111.667869,
              34.30819
            ],
            [
              -111.662433,
              34.324642
            ],
            [
              -111.667262,
              34.348253
            ],
            [
              -111.656937,
              34.368392
            ],
            [
              -111.659644,
              34.386251
            ],
            [
              -111.618071,
              34.401329
            ],
            [
              -111.604807,
              34.416203
            ],
            [
              -111.581218,
              34.419709
            ],
            [
              -111.554599,
              34.439384
            ],
            [
              -111.554681,
              34.471733
            ],
            [
              -111.553137,
              34.500037
            ],
            [
              -111.553387,
              34.636413
            ],
            [
              -111.552064,
              34.636408
            ],
            [
              -111.552106,
              34.80252
            ],
            [
              -111.685588,
              34.803403
            ],
            [
              -111.77791,
              34.803318
            ],
            [
              -111.78,
              34.818192
            ],
            [
              -111.777815,
              34.981005
            ],
            [
              -111.809026,
              34.976295
            ],
            [
              -112.00807,
              34.975898
            ],
            [
              -112.02194,
              34.974088
            ],
            [
              -112.334536,
              34.973024
            ],
            [
              -112.335716,
              35.148279
            ],
            [
              -112.4423,
              35.149408
            ],
            [
              -112.442627,
              35.221748
            ],
            [
              -112.440751,
              35.260468
            ],
            [
              -112.4727,
              35.238312
            ],
            [
              -112.499636,
              35.242664
            ],
            [
              -112.541805,
              35.236338
            ],
            [
              -112.577399,
              35.243394
            ],
            [
              -112.603997,
              35.269224
            ],
            [
              -112.620617,
              35.274861
            ],
            [
              -112.618756,
              35.289961
            ],
            [
              -112.630954,
              35.299962
            ],
            [
              -112.655457,
              35.291909
            ],
            [
              -112.676373,
              35.306722
            ],
            [
              -112.694846,
              35.311586
            ],
            [
              -112.734644,
              35.305054
            ],
            [
              -112.754838,
              35.306301
            ],
            [
              -112.79693,
              35.322951
            ],
            [
              -112.880973,
              35.342071
            ],
            [
              -112.944789,
              35.352603
            ],
            [
              -112.95341,
              35.35611
            ],
            [
              -112.961392,
              35.376746
            ],
            [
              -112.975855,
              35.392904
            ],
            [
              -113.086051,
              35.452997
            ],
            [
              -113.148055,
              35.478595
            ],
            [
              -113.157956,
              35.495994
            ],
            [
              -113.174458,
              35.506445
            ],
            [
              -113.199134,
              35.510969
            ],
            [
              -113.218796,
              35.508872
            ],
            [
              -113.26219,
              35.529626
            ],
            [
              -113.299271,
              35.530979
            ],
            [
              -113.334161,
              35.528037
            ]
          ]
        ]
      ]
    }
  }
]
};