export const counties_nv = {
  "type":"FeatureCollection","name":"nv_counties","features":[
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "32",
      "COUNTYFP": "009",
      "COUNTYNS": "00858641",
      "AFFGEOID": "0500000US32009",
      "GEOID": "32009",
      "NAME": "Esmeralda",
      "LSAD": "06",
      "ALAND": 9277050041,
      "AWATER": 18552011,
      "County_state": "Esmeralda,32"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -118.351484,
              37.893705
            ],
            [
              -118.42780036384801,
              37.8962298945818
            ],
            [
              -118.250947,
              37.768616
            ],
            [
              -118.052189,
              37.62493
            ],
            [
              -118.039798,
              37.615273
            ],
            [
              -118.039849,
              37.615245
            ],
            [
              -117.975776,
              37.569293
            ],
            [
              -117.904625,
              37.515836
            ],
            [
              -117.875927,
              37.497267
            ],
            [
              -117.832726,
              37.464929
            ],
            [
              -117.712358,
              37.374931
            ],
            [
              -117.68061,
              37.353399
            ],
            [
              -117.581418,
              37.278936
            ],
            [
              -117.540885,
              37.249931
            ],
            [
              -117.500909,
              37.220282
            ],
            [
              -117.500117,
              37.22038
            ],
            [
              -117.375905,
              37.126843
            ],
            [
              -117.266046,
              37.04491
            ],
            [
              -117.244917,
              37.030244
            ],
            [
              -117.166000489853,
              36.9712239643835
            ],
            [
              -117.165478,
              37.368843
            ],
            [
              -117.164765,
              37.987856
            ],
            [
              -117.165612,
              38.001692
            ],
            [
              -117.21641,
              38.047894
            ],
            [
              -117.241063,
              38.050116
            ],
            [
              -117.24091,
              38.069902
            ],
            [
              -117.691021,
              38.473797
            ],
            [
              -118.234433,
              37.99993
            ],
            [
              -118.250953,
              37.983062
            ],
            [
              -118.351484,
              37.893705
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "32",
      "COUNTYFP": "017",
      "COUNTYNS": "00863992",
      "AFFGEOID": "0500000US32017",
      "GEOID": "32017",
      "NAME": "Lincoln",
      "LSAD": "06",
      "ALAND": 27540342244,
      "AWATER": 9802713,
      "County_state": "Lincoln,32"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -115.89446,
              36.861717
            ],
            [
              -115.896925,
              36.842085
            ],
            [
              -115.740748,
              36.8419
            ],
            [
              -115.740846,
              36.853084
            ],
            [
              -114.762507,
              36.853473
            ],
            [
              -114.762301,
              36.843856
            ],
            [
              -114.16549,
              36.843393
            ],
            [
              -114.050618928072,
              36.8431412145167
            ],
            [
              -114.049995,
              36.957769
            ],
            [
              -114.0506,
              37.000396
            ],
            [
              -114.051749,
              37.088434
            ],
            [
              -114.051822,
              37.090976
            ],
            [
              -114.052827,
              37.103961
            ],
            [
              -114.051867,
              37.134292
            ],
            [
              -114.052179,
              37.14711
            ],
            [
              -114.051673,
              37.172368
            ],
            [
              -114.051405,
              37.233854
            ],
            [
              -114.051974,
              37.283848
            ],
            [
              -114.051974,
              37.284511
            ],
            [
              -114.0518,
              37.293044
            ],
            [
              -114.0518,
              37.293548
            ],
            [
              -114.051927,
              37.370459
            ],
            [
              -114.051927,
              37.370734
            ],
            [
              -114.051765,
              37.418083
            ],
            [
              -114.052448,
              37.43144
            ],
            [
              -114.05261850687002,
              37.4722632534348
            ],
            [
              -114.052701,
              37.492014
            ],
            [
              -114.052685,
              37.502513
            ],
            [
              -114.052718,
              37.517264
            ],
            [
              -114.052689,
              37.517859
            ],
            [
              -114.052962,
              37.592783
            ],
            [
              -114.052472,
              37.604776
            ],
            [
              -114.052471996614,
              37.6047766427194
            ],
            [
              -114.052305834287,
              37.6363164410974
            ],
            [
              -114.051728,
              37.745997
            ],
            [
              -114.051785,
              37.746249
            ],
            [
              -114.05167,
              37.746958
            ],
            [
              -114.051109,
              37.756276
            ],
            [
              -114.049919,
              37.765586
            ],
            [
              -114.048473,
              37.809861
            ],
            [
              -114.049677,
              37.823645
            ],
            [
              -114.049928,
              37.852508
            ],
            [
              -114.049658,
              37.881368
            ],
            [
              -114.050423,
              37.999961
            ],
            [
              -114.049903,
              38.148601
            ],
            [
              -114.049903375658,
              38.14876302667059
            ],
            [
              -114.050138,
              38.24996
            ],
            [
              -114.049417,
              38.2647
            ],
            [
              -114.05012,
              38.404536
            ],
            [
              -114.050091,
              38.404673
            ],
            [
              -114.050485,
              38.499955
            ],
            [
              -114.049834,
              38.543784
            ],
            [
              -114.049862,
              38.547764
            ],
            [
              -114.050154,
              38.57292
            ],
            [
              -114.05015385888,
              38.5729744583009
            ],
            [
              -114.049883347847,
              38.677365
            ],
            [
              -114.158503,
              38.677317
            ],
            [
              -114.164808,
              38.677614
            ],
            [
              -115.000846,
              38.677322
            ],
            [
              -115.000846,
              38.050757
            ],
            [
              -115.893857,
              38.050531
            ],
            [
              -115.894817,
              37.275198
            ],
            [
              -115.89446,
              36.861717
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "32",
      "COUNTYFP": "021",
      "COUNTYNS": "00858644",
      "AFFGEOID": "0500000US32021",
      "GEOID": "32021",
      "NAME": "Mineral",
      "LSAD": "06",
      "ALAND": 9719815219,
      "AWATER": 155746682,
      "County_state": "Mineral,32"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -119.156982753766,
              38.414739167545896
            ],
            [
              -119.125982,
              38.39317
            ],
            [
              -119.097161,
              38.372853
            ],
            [
              -119.082358,
              38.361267
            ],
            [
              -119.030078,
              38.325181
            ],
            [
              -119.000975,
              38.303675
            ],
            [
              -118.949673,
              38.26894
            ],
            [
              -118.922518,
              38.249919
            ],
            [
              -118.859087,
              38.204808
            ],
            [
              -118.771867,
              38.141871
            ],
            [
              -118.746598,
              38.124926
            ],
            [
              -118.714312,
              38.102185
            ],
            [
              -118.62159,
              38.034389
            ],
            [
              -118.571958,
              37.99993
            ],
            [
              -118.500958,
              37.949019
            ],
            [
              -118.42780036384801,
              37.8962298945818
            ],
            [
              -118.351484,
              37.893705
            ],
            [
              -118.250953,
              37.983062
            ],
            [
              -118.234433,
              37.99993
            ],
            [
              -117.691021,
              38.473797
            ],
            [
              -118.196235,
              38.919058
            ],
            [
              -118.196274,
              38.999926
            ],
            [
              -117.865116,
              39.073654
            ],
            [
              -118.753835,
              39.07463
            ],
            [
              -118.923472,
              39.073806
            ],
            [
              -118.93035,
              39.072768
            ],
            [
              -119.014837,
              38.943323
            ],
            [
              -119.014528,
              38.8513
            ],
            [
              -118.902124,
              38.852158
            ],
            [
              -118.907023,
              38.76508
            ],
            [
              -118.906861,
              38.414677
            ],
            [
              -119.12598,
              38.41404
            ],
            [
              -119.156982753766,
              38.414739167545896
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "32",
      "COUNTYFP": "011",
      "COUNTYNS": "00858640",
      "AFFGEOID": "0500000US32011",
      "GEOID": "32011",
      "NAME": "Eureka",
      "LSAD": "06",
      "ALAND": 10814958250,
      "AWATER": 11082875,
      "County_state": "Eureka,32"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -116.597109,
              39.176241
            ],
            [
              -116.600946,
              39.161463
            ],
            [
              -116.002271,
              39.161228
            ],
            [
              -116.000886,
              39.161845
            ],
            [
              -115.907071,
              39.161848
            ],
            [
              -115.907137,
              39.220851
            ],
            [
              -115.907607,
              39.364025
            ],
            [
              -115.907325,
              39.420042
            ],
            [
              -115.907616,
              39.457992
            ],
            [
              -115.903473,
              39.456581
            ],
            [
              -115.895996,
              39.457366
            ],
            [
              -115.891003,
              39.46179
            ],
            [
              -115.875359,
              39.467018
            ],
            [
              -115.869441,
              39.47203
            ],
            [
              -115.869481,
              39.484539
            ],
            [
              -115.866303,
              39.48807
            ],
            [
              -115.86308,
              39.494157
            ],
            [
              -115.857594,
              39.496916
            ],
            [
              -115.852541,
              39.499929
            ],
            [
              -115.844309,
              39.508551
            ],
            [
              -115.840639,
              39.520343
            ],
            [
              -115.832827,
              39.546074
            ],
            [
              -115.831601,
              39.570236
            ],
            [
              -115.815882,
              39.587976
            ],
            [
              -115.818855,
              39.610412
            ],
            [
              -115.825422,
              39.634654
            ],
            [
              -115.823383,
              39.658552
            ],
            [
              -115.824025,
              39.678614
            ],
            [
              -115.829621,
              39.696753
            ],
            [
              -115.833271,
              39.728134
            ],
            [
              -115.817404,
              39.747181
            ],
            [
              -115.810971,
              39.763764
            ],
            [
              -115.811713,
              39.789186
            ],
            [
              -115.814416,
              39.815208
            ],
            [
              -115.800807,
              39.833643
            ],
            [
              -115.794865,
              39.853942
            ],
            [
              -115.8037,
              39.872107
            ],
            [
              -115.805459,
              39.890726
            ],
            [
              -115.80205,
              39.910976
            ],
            [
              -115.816929,
              39.934764
            ],
            [
              -115.818507,
              39.959968
            ],
            [
              -115.828552,
              39.982795
            ],
            [
              -115.832317,
              40.001721
            ],
            [
              -115.82654,
              40.026055
            ],
            [
              -115.82663,
              40.053118
            ],
            [
              -115.832537,
              40.074894
            ],
            [
              -115.837944,
              40.099589
            ],
            [
              -115.827597,
              40.114882
            ],
            [
              -115.832109,
              40.125255
            ],
            [
              -115.834969,
              40.127842
            ],
            [
              -116.000897,
              40.127376
            ],
            [
              -116.157834,
              40.666389
            ],
            [
              -116.158155,
              40.999912
            ],
            [
              -116.586786,
              41.000398
            ],
            [
              -116.587104,
              40.947021
            ],
            [
              -116.596815,
              40.94646
            ],
            [
              -116.594214,
              40.320251
            ],
            [
              -116.600095,
              40.073996
            ],
            [
              -116.592855,
              39.397355
            ],
            [
              -116.596828,
              39.372793
            ],
            [
              -116.597109,
              39.176241
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "32",
      "COUNTYFP": "013",
      "COUNTYNS": "00863310",
      "AFFGEOID": "0500000US32013",
      "GEOID": "32013",
      "NAME": "Humboldt",
      "LSAD": "06",
      "ALAND": 24969533279,
      "AWATER": 44196979,
      "County_state": "Humboldt,32"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -119.331068,
              41.235118
            ],
            [
              -119.329791,
              40.960394
            ],
            [
              -119.309598,
              40.960378
            ],
            [
              -118.786705,
              40.96085
            ],
            [
              -118.786738,
              40.855929
            ],
            [
              -118.43819,
              40.855696
            ],
            [
              -117.64422,
              40.857824
            ],
            [
              -117.644399,
              40.684343
            ],
            [
              -117.299971,
              40.676264
            ],
            [
              -117.300597,
              40.526136
            ],
            [
              -117.246788,
              40.642445
            ],
            [
              -117.018664,
              40.643035
            ],
            [
              -117.018419,
              41.000254
            ],
            [
              -117.018264,
              41.499897
            ],
            [
              -117.018208514199,
              41.9993463578912
            ],
            [
              -117.018294,
              41.999358
            ],
            [
              -117.026197,
              41.99989
            ],
            [
              -117.040906,
              41.99989
            ],
            [
              -117.055402,
              41.99989
            ],
            [
              -117.068613,
              42.000035
            ],
            [
              -117.197798,
              42.00038
            ],
            [
              -117.217551,
              41.999887
            ],
            [
              -117.403613,
              41.99929
            ],
            [
              -117.443062,
              41.999659
            ],
            [
              -117.623731,
              41.998467
            ],
            [
              -117.625973,
              41.998102
            ],
            [
              -117.873467,
              41.998335
            ],
            [
              -118.197189,
              41.996995
            ],
            [
              -118.197369215928,
              41.9969940811635
            ],
            [
              -118.501002,
              41.995446
            ],
            [
              -118.601806,
              41.993895
            ],
            [
              -118.696409,
              41.991794
            ],
            [
              -118.775869,
              41.992692
            ],
            [
              -118.777228,
              41.992671
            ],
            [
              -118.795612,
              41.992394
            ],
            [
              -119.001022,
              41.993793
            ],
            [
              -119.20828,
              41.993177
            ],
            [
              -119.231876,
              41.994212
            ],
            [
              -119.251033,
              41.993843
            ],
            [
              -119.324182599298,
              41.9942055845967
            ],
            [
              -119.32482,
              41.856904
            ],
            [
              -119.327027,
              41.590584
            ],
            [
              -119.323955,
              41.414519
            ],
            [
              -119.304635,
              41.414695
            ],
            [
              -119.305713,
              41.234542
            ],
            [
              -119.331068,
              41.235118
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "32",
      "COUNTYFP": "031",
      "COUNTYNS": "00858608",
      "AFFGEOID": "0500000US32031",
      "GEOID": "32031",
      "NAME": "Washoe",
      "LSAD": "06",
      "ALAND": 16323099293,
      "AWATER": 621146261,
      "County_state": "Washoe,32"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -120.005743,
              39.228664
            ],
            [
              -120.005746,
              39.22521
            ],
            [
              -120.004504420333,
              39.1655986798664
            ],
            [
              -119.880787,
              39.165509
            ],
            [
              -119.862103,
              39.171148
            ],
            [
              -119.862517,
              39.185639
            ],
            [
              -119.826731,
              39.207988
            ],
            [
              -119.804106,
              39.208459
            ],
            [
              -119.770871,
              39.215285
            ],
            [
              -119.770872,
              39.229832
            ],
            [
              -119.752168,
              39.229892
            ],
            [
              -119.752093,
              39.244385
            ],
            [
              -119.712645,
              39.250871
            ],
            [
              -119.71407,
              39.259175
            ],
            [
              -119.69652,
              39.259145
            ],
            [
              -119.695957,
              39.280903
            ],
            [
              -119.677476,
              39.280871
            ],
            [
              -119.677414,
              39.331655
            ],
            [
              -119.658501,
              39.331544
            ],
            [
              -119.658821,
              39.346088
            ],
            [
              -119.668134,
              39.346216
            ],
            [
              -119.668102,
              39.360798
            ],
            [
              -119.685464,
              39.389575
            ],
            [
              -119.677,
              39.404471
            ],
            [
              -119.657145,
              39.404111
            ],
            [
              -119.655069,
              39.447479
            ],
            [
              -119.69609,
              39.447463
            ],
            [
              -119.696186,
              39.452551
            ],
            [
              -119.695658,
              39.516421
            ],
            [
              -119.688257,
              39.520028
            ],
            [
              -119.664926,
              39.508516
            ],
            [
              -119.621697,
              39.511602
            ],
            [
              -119.61584,
              39.522629
            ],
            [
              -119.582952,
              39.547302
            ],
            [
              -119.558843,
              39.550421
            ],
            [
              -119.531021,
              39.565974
            ],
            [
              -119.511744,
              39.562281
            ],
            [
              -119.472651,
              39.565954
            ],
            [
              -119.467141,
              39.584502
            ],
            [
              -119.413453,
              39.590649
            ],
            [
              -119.370604,
              39.586882
            ],
            [
              -119.357868,
              39.597857
            ],
            [
              -119.339532,
              39.596612
            ],
            [
              -119.279801,
              39.627112
            ],
            [
              -119.283637,
              39.621561
            ],
            [
              -119.25876,
              39.649213
            ],
            [
              -119.189342,
              39.65087
            ],
            [
              -119.189327,
              39.68048
            ],
            [
              -119.170641,
              39.680453
            ],
            [
              -119.170899,
              39.788288
            ],
            [
              -119.189707,
              39.788221
            ],
            [
              -119.189761,
              39.809994
            ],
            [
              -119.208543,
              39.809967
            ],
            [
              -119.208628,
              39.899459
            ],
            [
              -119.226139,
              39.899449
            ],
            [
              -119.225176,
              39.999385
            ],
            [
              -119.339296,
              39.998609
            ],
            [
              -119.338504,
              40.517805
            ],
            [
              -119.313037,
              40.518314
            ],
            [
              -119.309598,
              40.960378
            ],
            [
              -119.329791,
              40.960394
            ],
            [
              -119.331068,
              41.235118
            ],
            [
              -119.305713,
              41.234542
            ],
            [
              -119.304635,
              41.414695
            ],
            [
              -119.323955,
              41.414519
            ],
            [
              -119.327027,
              41.590584
            ],
            [
              -119.32482,
              41.856904
            ],
            [
              -119.324182599298,
              41.9942055845967
            ],
            [
              -119.360121480893,
              41.9943837248054
            ],
            [
              -119.360177,
              41.994384
            ],
            [
              -119.444598,
              41.995478
            ],
            [
              -119.72573,
              41.996296
            ],
            [
              -119.790087,
              41.997544
            ],
            [
              -119.848907,
              41.997281
            ],
            [
              -119.872336,
              41.997477
            ],
            [
              -119.886511,
              41.997473
            ],
            [
              -119.986889,
              41.996077
            ],
            [
              -119.999171,
              41.994836
            ],
            [
              -119.999168,
              41.99454
            ],
            [
              -119.999276,
              41.874891
            ],
            [
              -119.998287,
              41.749892
            ],
            [
              -119.998855,
              41.624893
            ],
            [
              -119.99828,
              41.618765
            ],
            [
              -119.999471,
              41.499894
            ],
            [
              -119.999866,
              41.183974
            ],
            [
              -119.999358,
              40.873101
            ],
            [
              -119.999232,
              40.867454
            ],
            [
              -119.999231,
              40.865899
            ],
            [
              -119.998479,
              40.749899
            ],
            [
              -119.997533,
              40.720992
            ],
            [
              -119.995926,
              40.499901
            ],
            [
              -119.996155,
              40.321838
            ],
            [
              -119.996155,
              40.32125
            ],
            [
              -119.996182,
              40.263532
            ],
            [
              -119.996183,
              40.262461
            ],
            [
              -119.997124,
              40.126363
            ],
            [
              -119.997234,
              40.091591
            ],
            [
              -119.997175,
              40.077245
            ],
            [
              -119.997291,
              40.071803
            ],
            [
              -119.997634,
              39.956505
            ],
            [
              -119.999733,
              39.851406
            ],
            [
              -120.000607,
              39.780779
            ],
            [
              -120.000502,
              39.779956
            ],
            [
              -120.001151854493,
              39.734190971706795
            ],
            [
              -120.001319,
              39.72242
            ],
            [
              -120.001319008439,
              39.72241632020629
            ],
            [
              -120.00174,
              39.538852
            ],
            [
              -120.003116,
              39.445113
            ],
            [
              -120.003117296548,
              39.445043727450496
            ],
            [
              -120.00443,
              39.374908
            ],
            [
              -120.00471,
              39.330488
            ],
            [
              -120.00531557607401,
              39.3164525335553
            ],
            [
              -120.00532,
              39.31635
            ],
            [
              -120.005413,
              39.313848
            ],
            [
              -120.005414,
              39.313345
            ],
            [
              -120.005142,
              39.291258
            ],
            [
              -120.00526394636,
              39.278557317057796
            ],
            [
              -120.005743,
              39.228664
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "32",
      "COUNTYFP": "005",
      "COUNTYNS": "00858643",
      "AFFGEOID": "0500000US32005",
      "GEOID": "32005",
      "NAME": "Douglas",
      "LSAD": "06",
      "ALAND": 1838170316,
      "AWATER": 72317570,
      "County_state": "Douglas,32"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -120.00340238137,
              39.112687055111
            ],
            [
              -120.002461,
              39.067489
            ],
            [
              -120.001014,
              38.999574
            ],
            [
              -119.904315,
              38.933324
            ],
            [
              -119.587679,
              38.714734
            ],
            [
              -119.587066,
              38.714345
            ],
            [
              -119.585437,
              38.713212
            ],
            [
              -119.494183,
              38.649852
            ],
            [
              -119.494022,
              38.649734
            ],
            [
              -119.450612,
              38.619964
            ],
            [
              -119.450623,
              38.619965
            ],
            [
              -119.375994,
              38.566793
            ],
            [
              -119.370117,
              38.563281
            ],
            [
              -119.333423,
              38.538328
            ],
            [
              -119.328411217387,
              38.5347733644266
            ],
            [
              -119.329262,
              38.648471
            ],
            [
              -119.347812,
              38.64852
            ],
            [
              -119.349884,
              38.729193
            ],
            [
              -119.357491,
              38.736085
            ],
            [
              -119.406279,
              38.733362
            ],
            [
              -119.403908,
              38.809111
            ],
            [
              -119.421778,
              38.82331
            ],
            [
              -119.421884,
              38.852703
            ],
            [
              -119.43989,
              38.852605
            ],
            [
              -119.439534,
              38.882001
            ],
            [
              -119.421903,
              38.882136
            ],
            [
              -119.420837,
              38.954715
            ],
            [
              -119.402495,
              38.954647
            ],
            [
              -119.401648,
              38.983323
            ],
            [
              -119.348039,
              38.983637
            ],
            [
              -119.310702,
              38.990719
            ],
            [
              -119.310416,
              39.019378
            ],
            [
              -119.311714,
              39.083615
            ],
            [
              -119.397722,
              39.085172
            ],
            [
              -119.55102,
              39.085791
            ],
            [
              -119.752116,
              39.084904
            ],
            [
              -119.75219,
              39.099526
            ],
            [
              -119.761213,
              39.114009
            ],
            [
              -120.00340238137,
              39.112687055111
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "32",
      "COUNTYFP": "003",
      "COUNTYNS": "00863600",
      "AFFGEOID": "0500000US32003",
      "GEOID": "32003",
      "NAME": "Clark",
      "LSAD": "06",
      "ALAND": 20439281450,
      "AWATER": 438376844,
      "County_state": "Clark,32"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -115.896925,
              36.842085
            ],
            [
              -115.895753,
              36.172227
            ],
            [
              -115.845762,
              36.170764
            ],
            [
              -115.845803,
              36.122048
            ],
            [
              -115.845983929593,
              35.9642074573715
            ],
            [
              -115.750844,
              35.889287
            ],
            [
              -115.689302,
              35.842003
            ],
            [
              -115.669005,
              35.826515
            ],
            [
              -115.64802031181802,
              35.8096294219517
            ],
            [
              -115.647683,
              35.809358
            ],
            [
              -115.647202,
              35.808995
            ],
            [
              -115.627386,
              35.793846
            ],
            [
              -115.625838,
              35.792013
            ],
            [
              -115.500832,
              35.693382
            ],
            [
              -115.412908,
              35.624981
            ],
            [
              -115.406079,
              35.618613
            ],
            [
              -115.404537,
              35.617605
            ],
            [
              -115.393996,
              35.609344
            ],
            [
              -115.391535,
              35.607271
            ],
            [
              -115.366825365988,
              35.587832484379
            ],
            [
              -115.303743,
              35.538207
            ],
            [
              -115.271342,
              35.51266
            ],
            [
              -115.160599,
              35.424313
            ],
            [
              -115.160068,
              35.424129
            ],
            [
              -115.146788,
              35.413662
            ],
            [
              -115.145813,
              35.413182
            ],
            [
              -115.125816,
              35.39694
            ],
            [
              -115.102881,
              35.379371
            ],
            [
              -115.098018,
              35.37499
            ],
            [
              -115.043812,
              35.332012
            ],
            [
              -114.942216,
              35.249994
            ],
            [
              -114.92548,
              35.237054
            ],
            [
              -114.925381,
              35.237039
            ],
            [
              -114.80503,
              35.140284
            ],
            [
              -114.804249,
              35.139689
            ],
            [
              -114.633487,
              35.001857
            ],
            [
              -114.636674,
              35.008807
            ],
            [
              -114.638023,
              35.020556
            ],
            [
              -114.636893,
              35.028367
            ],
            [
              -114.632429,
              35.037586
            ],
            [
              -114.627124,
              35.044721
            ],
            [
              -114.606694,
              35.058941
            ],
            [
              -114.603619,
              35.064226
            ],
            [
              -114.602908,
              35.068588
            ],
            [
              -114.604736,
              35.07483
            ],
            [
              -114.613132,
              35.083097
            ],
            [
              -114.622517,
              35.088703
            ],
            [
              -114.642831,
              35.096503
            ],
            [
              -114.646759,
              35.101872
            ],
            [
              -114.644352,
              35.105904
            ],
            [
              -114.629934,
              35.118272
            ],
            [
              -114.619905,
              35.121632
            ],
            [
              -114.59912,
              35.12105
            ],
            [
              -114.58774,
              35.123729
            ],
            [
              -114.578524,
              35.12875
            ],
            [
              -114.572747,
              35.138725
            ],
            [
              -114.569569,
              35.163053
            ],
            [
              -114.569238,
              35.18348
            ],
            [
              -114.572119,
              35.200591
            ],
            [
              -114.574835,
              35.205898
            ],
            [
              -114.579963,
              35.20964
            ],
            [
              -114.583559,
              35.22993
            ],
            [
              -114.583111,
              35.23809
            ],
            [
              -114.587129,
              35.262376
            ],
            [
              -114.597503,
              35.296954
            ],
            [
              -114.595931,
              35.325234
            ],
            [
              -114.604314,
              35.353584
            ],
            [
              -114.611435,
              35.369056
            ],
            [
              -114.627137,
              35.409504
            ],
            [
              -114.652005,
              35.429165
            ],
            [
              -114.662125,
              35.444241
            ],
            [
              -114.6645,
              35.449497
            ],
            [
              -114.666377,
              35.466856
            ],
            [
              -114.672901,
              35.481708
            ],
            [
              -114.677643,
              35.489742
            ],
            [
              -114.679205,
              35.499992
            ],
            [
              -114.677205,
              35.513491
            ],
            [
              -114.673805,
              35.517891
            ],
            [
              -114.663105,
              35.524491
            ],
            [
              -114.658005,
              35.530491
            ],
            [
              -114.656905,
              35.534391
            ],
            [
              -114.657405,
              35.536391
            ],
            [
              -114.660205,
              35.539291
            ],
            [
              -114.662005,
              35.545491
            ],
            [
              -114.663005,
              35.56369
            ],
            [
              -114.666184,
              35.577576
            ],
            [
              -114.665649,
              35.580428
            ],
            [
              -114.659606,
              35.58749
            ],
            [
              -114.654306,
              35.59759
            ],
            [
              -114.653406,
              35.610789
            ],
            [
              -114.658206,
              35.619089
            ],
            [
              -114.677107,
              35.641489
            ],
            [
              -114.689407,
              35.651412
            ],
            [
              -114.690008,
              35.664688
            ],
            [
              -114.682207,
              35.678188
            ],
            [
              -114.680607,
              35.685488
            ],
            [
              -114.683208,
              35.689387
            ],
            [
              -114.694108,
              35.695187
            ],
            [
              -114.701208,
              35.701187
            ],
            [
              -114.705409,
              35.708287
            ],
            [
              -114.705309,
              35.711587
            ],
            [
              -114.697309,
              35.733686
            ],
            [
              -114.695709,
              35.755986
            ],
            [
              -114.701409,
              35.769086
            ],
            [
              -114.69891,
              35.790185
            ],
            [
              -114.71211,
              35.806185
            ],
            [
              -114.70991,
              35.810185
            ],
            [
              -114.70371,
              35.814585
            ],
            [
              -114.69571,
              35.830601
            ],
            [
              -114.69641,
              35.833784
            ],
            [
              -114.699848,
              35.843283
            ],
            [
              -114.699848,
              35.84837
            ],
            [
              -114.697767,
              35.854844
            ],
            [
              -114.68201,
              35.863284
            ],
            [
              -114.669687,
              35.865084
            ],
            [
              -114.667194,
              35.874787
            ],
            [
              -114.700271,
              35.901772
            ],
            [
              -114.708516,
              35.912313
            ],
            [
              -114.707526,
              35.92806
            ],
            [
              -114.715692,
              35.934709
            ],
            [
              -114.729356,
              35.941413
            ],
            [
              -114.731159,
              35.943916
            ],
            [
              -114.728318,
              35.95629
            ],
            [
              -114.729941,
              35.962183
            ],
            [
              -114.740595,
              35.975656
            ],
            [
              -114.743756,
              35.985095
            ],
            [
              -114.743243,
              36.00653
            ],
            [
              -114.742779,
              36.009963
            ],
            [
              -114.740522,
              36.013336
            ],
            [
              -114.737346952926,
              36.016228142238695
            ],
            [
              -114.731162,
              36.021862
            ],
            [
              -114.729707,
              36.028166
            ],
            [
              -114.730435,
              36.031317
            ],
            [
              -114.734314,
              36.035681
            ],
            [
              -114.739405,
              36.037863
            ],
            [
              -114.740617,
              36.041015
            ],
            [
              -114.740375,
              36.043682
            ],
            [
              -114.740375,
              36.049258
            ],
            [
              -114.736738,
              36.054349
            ],
            [
              -114.736253,
              36.05847
            ],
            [
              -114.740554803334,
              36.0657913792102
            ],
            [
              -114.743342,
              36.070535
            ],
            [
              -114.754099,
              36.07944
            ],
            [
              -114.755491,
              36.081601
            ],
            [
              -114.755618,
              36.087166
            ],
            [
              -114.753638,
              36.090705
            ],
            [
              -114.747079,
              36.097005
            ],
            [
              -114.736165,
              36.104367
            ],
            [
              -114.717293,
              36.107686
            ],
            [
              -114.709771,
              36.107742
            ],
            [
              -114.666538,
              36.117343
            ],
            [
              -114.66289,
              36.119932
            ],
            [
              -114.65995,
              36.124145
            ],
            [
              -114.631716,
              36.142306
            ],
            [
              -114.627855,
              36.141012
            ],
            [
              -114.621883,
              36.13213
            ],
            [
              -114.616694,
              36.130101
            ],
            [
              -114.608264,
              36.133949
            ],
            [
              -114.597212,
              36.142103
            ],
            [
              -114.572031,
              36.15161
            ],
            [
              -114.545789,
              36.152248
            ],
            [
              -114.511721,
              36.150956
            ],
            [
              -114.506711,
              36.148277
            ],
            [
              -114.504631,
              36.145629
            ],
            [
              -114.50482,
              36.142414
            ],
            [
              -114.505387,
              36.137496
            ],
            [
              -114.506144,
              36.134659
            ],
            [
              -114.505766,
              36.131444
            ],
            [
              -114.504442,
              36.129741
            ],
            [
              -114.502172,
              36.128796
            ],
            [
              -114.49612,
              36.12785
            ],
            [
              -114.487034,
              36.129396
            ],
            [
              -114.470152,
              36.138801
            ],
            [
              -114.463637,
              36.139695
            ],
            [
              -114.458369,
              36.138586
            ],
            [
              -114.453325,
              36.130726
            ],
            [
              -114.448654,
              36.12641
            ],
            [
              -114.446605,
              36.12597
            ],
            [
              -114.427169,
              36.136305
            ],
            [
              -114.41695,
              36.145761
            ],
            [
              -114.412373,
              36.147254
            ],
            [
              -114.405475,
              36.147371
            ],
            [
              -114.372106,
              36.143114
            ],
            [
              -114.363109,
              36.130246
            ],
            [
              -114.337273,
              36.10802
            ],
            [
              -114.328777,
              36.105501
            ],
            [
              -114.30843,
              36.082443
            ],
            [
              -114.305738,
              36.074882
            ],
            [
              -114.307879,
              36.071291
            ],
            [
              -114.314206,
              36.066619
            ],
            [
              -114.316109,
              36.063109
            ],
            [
              -114.315557,
              36.059494
            ],
            [
              -114.314028,
              36.058165
            ],
            [
              -114.280202,
              36.046362
            ],
            [
              -114.270645,
              36.03572
            ],
            [
              -114.266721,
              36.029238
            ],
            [
              -114.263146,
              36.025937
            ],
            [
              -114.252651,
              36.020193
            ],
            [
              -114.238799,
              36.014561
            ],
            [
              -114.233289,
              36.014289
            ],
            [
              -114.21369,
              36.015613
            ],
            [
              -114.19238,
              36.020993
            ],
            [
              -114.176824,
              36.027651
            ],
            [
              -114.166465,
              36.027738
            ],
            [
              -114.15413,
              36.023862
            ],
            [
              -114.151725,
              36.024563
            ],
            [
              -114.148191,
              36.028013
            ],
            [
              -114.138202,
              36.041284
            ],
            [
              -114.137188,
              36.046785
            ],
            [
              -114.138203,
              36.053161
            ],
            [
              -114.136896,
              36.059467
            ],
            [
              -114.114531,
              36.095217
            ],
            [
              -114.114165,
              36.096982
            ],
            [
              -114.117459,
              36.100893
            ],
            [
              -114.123221,
              36.104746
            ],
            [
              -114.123975,
              36.106515
            ],
            [
              -114.123144,
              36.111576
            ],
            [
              -114.120862,
              36.114596
            ],
            [
              -114.111011,
              36.119875
            ],
            [
              -114.103222,
              36.120176
            ],
            [
              -114.09987,
              36.121654
            ],
            [
              -114.088954,
              36.144381
            ],
            [
              -114.068027,
              36.180663
            ],
            [
              -114.060302,
              36.189363
            ],
            [
              -114.046838,
              36.194069
            ],
            [
              -114.046743,
              36.245246
            ],
            [
              -114.047106,
              36.250591
            ],
            [
              -114.048226,
              36.268874
            ],
            [
              -114.048515,
              36.289598
            ],
            [
              -114.046935,
              36.315449
            ],
            [
              -114.047584,
              36.325573
            ],
            [
              -114.045806,
              36.391071
            ],
            [
              -114.045829,
              36.442973
            ],
            [
              -114.046488,
              36.473449
            ],
            [
              -114.048476,
              36.49998
            ],
            [
              -114.04966,
              36.621113
            ],
            [
              -114.050167,
              36.624978
            ],
            [
              -114.050562,
              36.656259
            ],
            [
              -114.050619,
              36.843128
            ],
            [
              -114.050618928072,
              36.8431412145167
            ],
            [
              -114.16549,
              36.843393
            ],
            [
              -114.762301,
              36.843856
            ],
            [
              -114.762507,
              36.853473
            ],
            [
              -115.740846,
              36.853084
            ],
            [
              -115.740748,
              36.8419
            ],
            [
              -115.896925,
              36.842085
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "32",
      "COUNTYFP": "027",
      "COUNTYNS": "00858646",
      "AFFGEOID": "0500000US32027",
      "GEOID": "32027",
      "NAME": "Pershing",
      "LSAD": "06",
      "ALAND": 15634691937,
      "AWATER": 79934815,
      "County_state": "Pershing,32"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -119.338504,
              40.517805
            ],
            [
              -119.339296,
              39.998609
            ],
            [
              -119.225176,
              39.999385
            ],
            [
              -118.245185,
              40.002379
            ],
            [
              -117.541749,
              40.001034
            ],
            [
              -117.300597,
              40.526136
            ],
            [
              -117.299971,
              40.676264
            ],
            [
              -117.644399,
              40.684343
            ],
            [
              -117.64422,
              40.857824
            ],
            [
              -118.43819,
              40.855696
            ],
            [
              -118.786738,
              40.855929
            ],
            [
              -118.786705,
              40.96085
            ],
            [
              -119.309598,
              40.960378
            ],
            [
              -119.313037,
              40.518314
            ],
            [
              -119.338504,
              40.517805
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "32",
      "COUNTYFP": "015",
      "COUNTYNS": "00863368",
      "AFFGEOID": "0500000US32015",
      "GEOID": "32015",
      "NAME": "Lander",
      "LSAD": "06",
      "ALAND": 14219341058,
      "AWATER": 76104322,
      "County_state": "Lander,32"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -117.806786,
              39.145375
            ],
            [
              -117.791497,
              39.131382
            ],
            [
              -117.801668,
              39.115916
            ],
            [
              -117.783086,
              39.106089
            ],
            [
              -117.775468,
              39.093425
            ],
            [
              -117.331857,
              39.16343
            ],
            [
              -116.827065,
              39.162077
            ],
            [
              -116.600946,
              39.161463
            ],
            [
              -116.597109,
              39.176241
            ],
            [
              -116.596828,
              39.372793
            ],
            [
              -116.592855,
              39.397355
            ],
            [
              -116.600095,
              40.073996
            ],
            [
              -116.594214,
              40.320251
            ],
            [
              -116.596815,
              40.94646
            ],
            [
              -116.587104,
              40.947021
            ],
            [
              -116.586786,
              41.000398
            ],
            [
              -117.018419,
              41.000254
            ],
            [
              -117.018664,
              40.643035
            ],
            [
              -117.246788,
              40.642445
            ],
            [
              -117.300597,
              40.526136
            ],
            [
              -117.541749,
              40.001034
            ],
            [
              -117.507143,
              39.968816
            ],
            [
              -117.500943,
              39.951566
            ],
            [
              -117.478042,
              39.939617
            ],
            [
              -117.467941,
              39.922117
            ],
            [
              -117.46164,
              39.886817
            ],
            [
              -117.46464,
              39.858218
            ],
            [
              -117.47234,
              39.839218
            ],
            [
              -117.488242,
              39.826625
            ],
            [
              -117.48574,
              39.786518
            ],
            [
              -117.494949,
              39.7742
            ],
            [
              -117.495344,
              39.748364
            ],
            [
              -117.475212,
              39.735851
            ],
            [
              -117.487824,
              39.703903
            ],
            [
              -117.465086,
              39.675969
            ],
            [
              -117.486071,
              39.644966
            ],
            [
              -117.463174,
              39.632902
            ],
            [
              -117.461587,
              39.622644
            ],
            [
              -117.482135,
              39.598222
            ],
            [
              -117.47609,
              39.583641
            ],
            [
              -117.485236,
              39.545622
            ],
            [
              -117.510543,
              39.531349
            ],
            [
              -117.518514,
              39.538212
            ],
            [
              -117.534138,
              39.528322
            ],
            [
              -117.560839,
              39.525921
            ],
            [
              -117.565739,
              39.519222
            ],
            [
              -117.59024,
              39.517521
            ],
            [
              -117.58684,
              39.503022
            ],
            [
              -117.615458,
              39.489794
            ],
            [
              -117.623237,
              39.476209
            ],
            [
              -117.656397,
              39.468043
            ],
            [
              -117.653755,
              39.45777
            ],
            [
              -117.637231,
              39.451821
            ],
            [
              -117.646011,
              39.426602
            ],
            [
              -117.67309,
              39.423839
            ],
            [
              -117.696305,
              39.385934
            ],
            [
              -117.731896,
              39.380218
            ],
            [
              -117.746483,
              39.385736
            ],
            [
              -117.759795,
              39.369225
            ],
            [
              -117.763046,
              39.35283
            ],
            [
              -117.752673,
              39.311781
            ],
            [
              -117.73298,
              39.281348
            ],
            [
              -117.722805,
              39.250781
            ],
            [
              -117.714056,
              39.245558
            ],
            [
              -117.719897,
              39.213377
            ],
            [
              -117.734611,
              39.210074
            ],
            [
              -117.744234,
              39.191425
            ],
            [
              -117.770992,
              39.17416
            ],
            [
              -117.782407,
              39.175849
            ],
            [
              -117.793046,
              39.161099
            ],
            [
              -117.785025,
              39.150538
            ],
            [
              -117.806786,
              39.145375
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "32",
      "COUNTYFP": "029",
      "COUNTYNS": "00858642",
      "AFFGEOID": "0500000US32029",
      "GEOID": "32029",
      "NAME": "Storey",
      "LSAD": "06",
      "ALAND": 680962190,
      "AWATER": 1871089,
      "County_state": "Storey,32"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -119.71407,
              39.259175
            ],
            [
              -119.712645,
              39.250871
            ],
            [
              -119.64735,
              39.266568
            ],
            [
              -119.557382,
              39.304438
            ],
            [
              -119.478806,
              39.337416
            ],
            [
              -119.283637,
              39.621561
            ],
            [
              -119.279801,
              39.627112
            ],
            [
              -119.339532,
              39.596612
            ],
            [
              -119.357868,
              39.597857
            ],
            [
              -119.370604,
              39.586882
            ],
            [
              -119.413453,
              39.590649
            ],
            [
              -119.467141,
              39.584502
            ],
            [
              -119.472651,
              39.565954
            ],
            [
              -119.511744,
              39.562281
            ],
            [
              -119.531021,
              39.565974
            ],
            [
              -119.558843,
              39.550421
            ],
            [
              -119.582952,
              39.547302
            ],
            [
              -119.61584,
              39.522629
            ],
            [
              -119.621697,
              39.511602
            ],
            [
              -119.664926,
              39.508516
            ],
            [
              -119.688257,
              39.520028
            ],
            [
              -119.695658,
              39.516421
            ],
            [
              -119.696186,
              39.452551
            ],
            [
              -119.69609,
              39.447463
            ],
            [
              -119.655069,
              39.447479
            ],
            [
              -119.657145,
              39.404111
            ],
            [
              -119.677,
              39.404471
            ],
            [
              -119.685464,
              39.389575
            ],
            [
              -119.668102,
              39.360798
            ],
            [
              -119.668134,
              39.346216
            ],
            [
              -119.658821,
              39.346088
            ],
            [
              -119.658501,
              39.331544
            ],
            [
              -119.677414,
              39.331655
            ],
            [
              -119.677476,
              39.280871
            ],
            [
              -119.695957,
              39.280903
            ],
            [
              -119.69652,
              39.259145
            ],
            [
              -119.71407,
              39.259175
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "32",
      "COUNTYFP": "510",
      "COUNTYNS": "00863219",
      "AFFGEOID": "0500000US32510",
      "GEOID": "32510",
      "NAME": "Carson City",
      "LSAD": "00",
      "ALAND": 374669607,
      "AWATER": 32587484,
      "County_state": "Carson City,32"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -120.004504420333,
              39.1655986798664
            ],
            [
              -120.00340238137,
              39.112687055111
            ],
            [
              -119.761213,
              39.114009
            ],
            [
              -119.75219,
              39.099526
            ],
            [
              -119.752116,
              39.084904
            ],
            [
              -119.55102,
              39.085791
            ],
            [
              -119.561302,
              39.137008
            ],
            [
              -119.571549,
              39.144675
            ],
            [
              -119.571161,
              39.157871
            ],
            [
              -119.560654,
              39.178547
            ],
            [
              -119.561748,
              39.19471
            ],
            [
              -119.648977,
              39.195287
            ],
            [
              -119.682298,
              39.20936
            ],
            [
              -119.712645,
              39.250871
            ],
            [
              -119.752093,
              39.244385
            ],
            [
              -119.752168,
              39.229892
            ],
            [
              -119.770872,
              39.229832
            ],
            [
              -119.770871,
              39.215285
            ],
            [
              -119.804106,
              39.208459
            ],
            [
              -119.826731,
              39.207988
            ],
            [
              -119.862517,
              39.185639
            ],
            [
              -119.862103,
              39.171148
            ],
            [
              -119.880787,
              39.165509
            ],
            [
              -120.004504420333,
              39.1655986798664
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "32",
      "COUNTYFP": "033",
      "COUNTYNS": "00858615",
      "AFFGEOID": "0500000US32033",
      "GEOID": "32033",
      "NAME": "White Pine",
      "LSAD": "06",
      "ALAND": 22985104192,
      "AWATER": 54678877,
      "County_state": "White Pine,32"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -115.907616,
              39.457992
            ],
            [
              -115.907325,
              39.420042
            ],
            [
              -115.907607,
              39.364025
            ],
            [
              -115.907137,
              39.220851
            ],
            [
              -115.907071,
              39.161848
            ],
            [
              -115.601585,
              38.999933
            ],
            [
              -115.599878,
              38.999933
            ],
            [
              -115.000846,
              38.677322
            ],
            [
              -114.164808,
              38.677614
            ],
            [
              -114.158503,
              38.677317
            ],
            [
              -114.049883347847,
              38.677365
            ],
            [
              -114.049749,
              38.72921
            ],
            [
              -114.049168,
              38.749951
            ],
            [
              -114.049465,
              38.874949
            ],
            [
              -114.048521,
              38.876197
            ],
            [
              -114.048054,
              38.878693
            ],
            [
              -114.049104,
              39.005509
            ],
            [
              -114.047079,
              39.499943
            ],
            [
              -114.047727981839,
              39.5427408023268
            ],
            [
              -114.047728,
              39.542742
            ],
            [
              -114.047273,
              39.759413
            ],
            [
              -114.047783,
              39.79416
            ],
            [
              -114.047214,
              39.821024
            ],
            [
              -114.047134,
              39.906037
            ],
            [
              -114.047133613292,
              39.90609768574429
            ],
            [
              -114.046555,
              39.996899
            ],
            [
              -114.046835,
              40.030131
            ],
            [
              -114.046386,
              40.097896
            ],
            [
              -114.046741,
              40.104231
            ],
            [
              -114.046682520363,
              40.1169354028727
            ],
            [
              -114.529719,
              40.119087
            ],
            [
              -115.500879,
              40.127903
            ],
            [
              -115.834969,
              40.127842
            ],
            [
              -115.832109,
              40.125255
            ],
            [
              -115.827597,
              40.114882
            ],
            [
              -115.837944,
              40.099589
            ],
            [
              -115.832537,
              40.074894
            ],
            [
              -115.82663,
              40.053118
            ],
            [
              -115.82654,
              40.026055
            ],
            [
              -115.832317,
              40.001721
            ],
            [
              -115.828552,
              39.982795
            ],
            [
              -115.818507,
              39.959968
            ],
            [
              -115.816929,
              39.934764
            ],
            [
              -115.80205,
              39.910976
            ],
            [
              -115.805459,
              39.890726
            ],
            [
              -115.8037,
              39.872107
            ],
            [
              -115.794865,
              39.853942
            ],
            [
              -115.800807,
              39.833643
            ],
            [
              -115.814416,
              39.815208
            ],
            [
              -115.811713,
              39.789186
            ],
            [
              -115.810971,
              39.763764
            ],
            [
              -115.817404,
              39.747181
            ],
            [
              -115.833271,
              39.728134
            ],
            [
              -115.829621,
              39.696753
            ],
            [
              -115.824025,
              39.678614
            ],
            [
              -115.823383,
              39.658552
            ],
            [
              -115.825422,
              39.634654
            ],
            [
              -115.818855,
              39.610412
            ],
            [
              -115.815882,
              39.587976
            ],
            [
              -115.831601,
              39.570236
            ],
            [
              -115.832827,
              39.546074
            ],
            [
              -115.840639,
              39.520343
            ],
            [
              -115.844309,
              39.508551
            ],
            [
              -115.852541,
              39.499929
            ],
            [
              -115.857594,
              39.496916
            ],
            [
              -115.86308,
              39.494157
            ],
            [
              -115.866303,
              39.48807
            ],
            [
              -115.869481,
              39.484539
            ],
            [
              -115.869441,
              39.47203
            ],
            [
              -115.875359,
              39.467018
            ],
            [
              -115.891003,
              39.46179
            ],
            [
              -115.895996,
              39.457366
            ],
            [
              -115.903473,
              39.456581
            ],
            [
              -115.907616,
              39.457992
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "32",
      "COUNTYFP": "007",
      "COUNTYNS": "00857664",
      "AFFGEOID": "0500000US32007",
      "GEOID": "32007",
      "NAME": "Elko",
      "LSAD": "06",
      "ALAND": 44468067633,
      "AWATER": 85736268,
      "County_state": "Elko,32"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -117.018264,
              41.499897
            ],
            [
              -117.018419,
              41.000254
            ],
            [
              -116.586786,
              41.000398
            ],
            [
              -116.158155,
              40.999912
            ],
            [
              -116.157834,
              40.666389
            ],
            [
              -116.000897,
              40.127376
            ],
            [
              -115.834969,
              40.127842
            ],
            [
              -115.500879,
              40.127903
            ],
            [
              -114.529719,
              40.119087
            ],
            [
              -114.046682520363,
              40.1169354028727
            ],
            [
              -114.046153,
              40.231971
            ],
            [
              -114.046178,
              40.398313
            ],
            [
              -114.045826,
              40.424823
            ],
            [
              -114.045218,
              40.430282
            ],
            [
              -114.045518,
              40.494474
            ],
            [
              -114.045577,
              40.495801
            ],
            [
              -114.045281,
              40.506586
            ],
            [
              -114.043505,
              40.726292
            ],
            [
              -114.043831,
              40.758666
            ],
            [
              -114.043803,
              40.759205
            ],
            [
              -114.043176,
              40.771675
            ],
            [
              -114.042145133139,
              40.9998965245684
            ],
            [
              -114.042145,
              40.999926
            ],
            [
              -114.041447,
              41.207752
            ],
            [
              -114.042553,
              41.210923
            ],
            [
              -114.041396,
              41.219958
            ],
            [
              -114.040231,
              41.49169
            ],
            [
              -114.040942,
              41.499921
            ],
            [
              -114.040437,
              41.615377
            ],
            [
              -114.039968,
              41.62492
            ],
            [
              -114.039901,
              41.753781
            ],
            [
              -114.041152,
              41.850595
            ],
            [
              -114.041107,
              41.850573
            ],
            [
              -114.039648,
              41.884816
            ],
            [
              -114.041723,
              41.99372
            ],
            [
              -114.048246,
              41.993721
            ],
            [
              -114.048257,
              41.993814
            ],
            [
              -114.061774,
              41.993797
            ],
            [
              -114.061763,
              41.993939
            ],
            [
              -114.107259,
              41.993831
            ],
            [
              -114.107428,
              41.993965
            ],
            [
              -114.281799324163,
              41.994213920521
            ],
            [
              -114.281855,
              41.994214
            ],
            [
              -114.467581,
              41.995492
            ],
            [
              -114.498243,
              41.994636
            ],
            [
              -114.498259,
              41.994599
            ],
            [
              -114.598267,
              41.994511
            ],
            [
              -114.720715,
              41.998231
            ],
            [
              -114.806384,
              42.001822
            ],
            [
              -114.831077,
              42.002207
            ],
            [
              -114.875877,
              42.001319
            ],
            [
              -114.89921,
              41.999909
            ],
            [
              -114.914187,
              41.999909
            ],
            [
              -115.031783,
              41.996008
            ],
            [
              -115.038157180221,
              41.9960123074291
            ],
            [
              -115.250795,
              41.996156
            ],
            [
              -115.254333,
              41.996721
            ],
            [
              -115.313877,
              41.996103
            ],
            [
              -115.586849,
              41.996884
            ],
            [
              -115.625914,
              41.997415
            ],
            [
              -115.870181,
              41.996766
            ],
            [
              -115.879596,
              41.997891
            ],
            [
              -115.887612,
              41.998048
            ],
            [
              -115.98688,
              41.998534
            ],
            [
              -116.012212,
              41.998035
            ],
            [
              -116.012219,
              41.998048
            ],
            [
              -116.018945,
              41.997722
            ],
            [
              -116.01896,
              41.997762
            ],
            [
              -116.030758,
              41.997383
            ],
            [
              -116.030754,
              41.997399
            ],
            [
              -116.03857,
              41.997413
            ],
            [
              -116.038602,
              41.99746
            ],
            [
              -116.160833,
              41.997508
            ],
            [
              -116.163931,
              41.997555
            ],
            [
              -116.332763,
              41.997283
            ],
            [
              -116.368478,
              41.996281
            ],
            [
              -116.463528,
              41.996547
            ],
            [
              -116.483094,
              41.996885
            ],
            [
              -116.485823,
              41.996861
            ],
            [
              -116.499777,
              41.99674
            ],
            [
              -116.501741,
              41.997334
            ],
            [
              -116.510452,
              41.997096
            ],
            [
              -116.525319,
              41.997558
            ],
            [
              -116.582217,
              41.997834
            ],
            [
              -116.586937,
              41.99737
            ],
            [
              -116.625947,
              41.997379
            ],
            [
              -116.62677,
              41.99775
            ],
            [
              -116.969156,
              41.998991
            ],
            [
              -117.009255,
              41.998127
            ],
            [
              -117.018208514199,
              41.9993463578912
            ],
            [
              -117.018264,
              41.499897
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "32",
      "COUNTYFP": "023",
      "COUNTYNS": "00863599",
      "AFFGEOID": "0500000US32023",
      "GEOID": "32023",
      "NAME": "Nye",
      "LSAD": "06",
      "ALAND": 47090938928,
      "AWATER": 43207981,
      "County_state": "Nye,32"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -118.196274,
              38.999926
            ],
            [
              -118.196235,
              38.919058
            ],
            [
              -117.691021,
              38.473797
            ],
            [
              -117.24091,
              38.069902
            ],
            [
              -117.241063,
              38.050116
            ],
            [
              -117.21641,
              38.047894
            ],
            [
              -117.165612,
              38.001692
            ],
            [
              -117.164765,
              37.987856
            ],
            [
              -117.165478,
              37.368843
            ],
            [
              -117.166000489853,
              36.9712239643835
            ],
            [
              -117.131975,
              36.945777
            ],
            [
              -117.066728,
              36.896354
            ],
            [
              -117.000895,
              36.847694
            ],
            [
              -116.541983,
              36.499952
            ],
            [
              -116.500882,
              36.468223
            ],
            [
              -116.488233,
              36.459097
            ],
            [
              -116.38034,
              36.374955
            ],
            [
              -116.375875,
              36.372562
            ],
            [
              -116.250869,
              36.276979
            ],
            [
              -116.097216,
              36.158346
            ],
            [
              -116.093601,
              36.155805
            ],
            [
              -115.912858,
              36.015359
            ],
            [
              -115.892975,
              35.999967
            ],
            [
              -115.852908,
              35.96966
            ],
            [
              -115.845983929593,
              35.9642074573715
            ],
            [
              -115.845803,
              36.122048
            ],
            [
              -115.845762,
              36.170764
            ],
            [
              -115.895753,
              36.172227
            ],
            [
              -115.896925,
              36.842085
            ],
            [
              -115.89446,
              36.861717
            ],
            [
              -115.894817,
              37.275198
            ],
            [
              -115.893857,
              38.050531
            ],
            [
              -115.000846,
              38.050757
            ],
            [
              -115.000846,
              38.677322
            ],
            [
              -115.599878,
              38.999933
            ],
            [
              -115.601585,
              38.999933
            ],
            [
              -115.907071,
              39.161848
            ],
            [
              -116.000886,
              39.161845
            ],
            [
              -116.002271,
              39.161228
            ],
            [
              -116.600946,
              39.161463
            ],
            [
              -116.827065,
              39.162077
            ],
            [
              -117.331857,
              39.16343
            ],
            [
              -117.775468,
              39.093425
            ],
            [
              -117.865116,
              39.073654
            ],
            [
              -118.196274,
              38.999926
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "32",
      "COUNTYFP": "019",
      "COUNTYNS": "00858645",
      "AFFGEOID": "0500000US32019",
      "GEOID": "32019",
      "NAME": "Lyon",
      "LSAD": "06",
      "ALAND": 5183173279,
      "AWATER": 59602530,
      "County_state": "Lyon,32"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -119.712645,
              39.250871
            ],
            [
              -119.682298,
              39.20936
            ],
            [
              -119.648977,
              39.195287
            ],
            [
              -119.561748,
              39.19471
            ],
            [
              -119.560654,
              39.178547
            ],
            [
              -119.571161,
              39.157871
            ],
            [
              -119.571549,
              39.144675
            ],
            [
              -119.561302,
              39.137008
            ],
            [
              -119.55102,
              39.085791
            ],
            [
              -119.397722,
              39.085172
            ],
            [
              -119.311714,
              39.083615
            ],
            [
              -119.310416,
              39.019378
            ],
            [
              -119.310702,
              38.990719
            ],
            [
              -119.348039,
              38.983637
            ],
            [
              -119.401648,
              38.983323
            ],
            [
              -119.402495,
              38.954647
            ],
            [
              -119.420837,
              38.954715
            ],
            [
              -119.421903,
              38.882136
            ],
            [
              -119.439534,
              38.882001
            ],
            [
              -119.43989,
              38.852605
            ],
            [
              -119.421884,
              38.852703
            ],
            [
              -119.421778,
              38.82331
            ],
            [
              -119.403908,
              38.809111
            ],
            [
              -119.406279,
              38.733362
            ],
            [
              -119.357491,
              38.736085
            ],
            [
              -119.349884,
              38.729193
            ],
            [
              -119.347812,
              38.64852
            ],
            [
              -119.329262,
              38.648471
            ],
            [
              -119.328411217387,
              38.5347733644266
            ],
            [
              -119.279262,
              38.499914
            ],
            [
              -119.250988,
              38.48078
            ],
            [
              -119.234966,
              38.468997
            ],
            [
              -119.156982753766,
              38.414739167545896
            ],
            [
              -119.12598,
              38.41404
            ],
            [
              -118.906861,
              38.414677
            ],
            [
              -118.907023,
              38.76508
            ],
            [
              -118.902124,
              38.852158
            ],
            [
              -119.014528,
              38.8513
            ],
            [
              -119.014837,
              38.943323
            ],
            [
              -118.93035,
              39.072768
            ],
            [
              -118.923472,
              39.073806
            ],
            [
              -118.753835,
              39.07463
            ],
            [
              -118.754014,
              39.112023
            ],
            [
              -118.792319,
              39.112425
            ],
            [
              -118.792751,
              39.141572
            ],
            [
              -118.810536,
              39.141774
            ],
            [
              -118.810399,
              39.15548
            ],
            [
              -118.831022,
              39.155598
            ],
            [
              -118.831015,
              39.171128
            ],
            [
              -118.848902,
              39.171235
            ],
            [
              -118.849102,
              39.186037
            ],
            [
              -118.868031,
              39.186104
            ],
            [
              -118.8679,
              39.197807
            ],
            [
              -118.890972,
              39.198188
            ],
            [
              -118.890479,
              39.227753
            ],
            [
              -118.909911,
              39.227721
            ],
            [
              -118.909919,
              39.242526
            ],
            [
              -118.928385,
              39.242422
            ],
            [
              -118.928626,
              39.25668
            ],
            [
              -118.947262,
              39.256514
            ],
            [
              -118.947239,
              39.271069
            ],
            [
              -118.965916,
              39.271159
            ],
            [
              -118.965903,
              39.285897
            ],
            [
              -118.988528,
              39.285795
            ],
            [
              -118.988598,
              39.300436
            ],
            [
              -119.006583,
              39.299971
            ],
            [
              -119.006336,
              39.315783
            ],
            [
              -119.025129,
              39.315649
            ],
            [
              -119.025011,
              39.330203
            ],
            [
              -119.043792,
              39.33015
            ],
            [
              -119.043711,
              39.344709
            ],
            [
              -119.062267,
              39.344746
            ],
            [
              -119.062261,
              39.359293
            ],
            [
              -119.080777,
              39.359381
            ],
            [
              -119.081135,
              39.372222
            ],
            [
              -119.099551,
              39.373865
            ],
            [
              -119.099892,
              39.387916
            ],
            [
              -119.118193,
              39.388516
            ],
            [
              -119.117635,
              39.518742
            ],
            [
              -119.090106,
              39.518741
            ],
            [
              -119.089147,
              39.636777
            ],
            [
              -119.077054,
              39.636758
            ],
            [
              -119.077988,
              39.722441
            ],
            [
              -119.05857,
              39.729873
            ],
            [
              -119.059922,
              39.740385
            ],
            [
              -119.111601,
              39.690257
            ],
            [
              -119.162799,
              39.654612
            ],
            [
              -119.179999,
              39.651812
            ],
            [
              -119.190676,
              39.631787
            ],
            [
              -119.189342,
              39.65087
            ],
            [
              -119.25876,
              39.649213
            ],
            [
              -119.283637,
              39.621561
            ],
            [
              -119.478806,
              39.337416
            ],
            [
              -119.557382,
              39.304438
            ],
            [
              -119.64735,
              39.266568
            ],
            [
              -119.712645,
              39.250871
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "32",
      "COUNTYFP": "001",
      "COUNTYNS": "00858638",
      "AFFGEOID": "0500000US32001",
      "GEOID": "32001",
      "NAME": "Churchill",
      "LSAD": "06",
      "ALAND": 12770128151,
      "AWATER": 242405502,
      "County_state": "Churchill,32"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -119.225176,
              39.999385
            ],
            [
              -119.226139,
              39.899449
            ],
            [
              -119.208628,
              39.899459
            ],
            [
              -119.208543,
              39.809967
            ],
            [
              -119.189761,
              39.809994
            ],
            [
              -119.189707,
              39.788221
            ],
            [
              -119.170899,
              39.788288
            ],
            [
              -119.170641,
              39.680453
            ],
            [
              -119.189327,
              39.68048
            ],
            [
              -119.189342,
              39.65087
            ],
            [
              -119.190676,
              39.631787
            ],
            [
              -119.179999,
              39.651812
            ],
            [
              -119.162799,
              39.654612
            ],
            [
              -119.111601,
              39.690257
            ],
            [
              -119.059922,
              39.740385
            ],
            [
              -119.05857,
              39.729873
            ],
            [
              -119.077988,
              39.722441
            ],
            [
              -119.077054,
              39.636758
            ],
            [
              -119.089147,
              39.636777
            ],
            [
              -119.090106,
              39.518741
            ],
            [
              -119.117635,
              39.518742
            ],
            [
              -119.118193,
              39.388516
            ],
            [
              -119.099892,
              39.387916
            ],
            [
              -119.099551,
              39.373865
            ],
            [
              -119.081135,
              39.372222
            ],
            [
              -119.080777,
              39.359381
            ],
            [
              -119.062261,
              39.359293
            ],
            [
              -119.062267,
              39.344746
            ],
            [
              -119.043711,
              39.344709
            ],
            [
              -119.043792,
              39.33015
            ],
            [
              -119.025011,
              39.330203
            ],
            [
              -119.025129,
              39.315649
            ],
            [
              -119.006336,
              39.315783
            ],
            [
              -119.006583,
              39.299971
            ],
            [
              -118.988598,
              39.300436
            ],
            [
              -118.988528,
              39.285795
            ],
            [
              -118.965903,
              39.285897
            ],
            [
              -118.965916,
              39.271159
            ],
            [
              -118.947239,
              39.271069
            ],
            [
              -118.947262,
              39.256514
            ],
            [
              -118.928626,
              39.25668
            ],
            [
              -118.928385,
              39.242422
            ],
            [
              -118.909919,
              39.242526
            ],
            [
              -118.909911,
              39.227721
            ],
            [
              -118.890479,
              39.227753
            ],
            [
              -118.890972,
              39.198188
            ],
            [
              -118.8679,
              39.197807
            ],
            [
              -118.868031,
              39.186104
            ],
            [
              -118.849102,
              39.186037
            ],
            [
              -118.848902,
              39.171235
            ],
            [
              -118.831015,
              39.171128
            ],
            [
              -118.831022,
              39.155598
            ],
            [
              -118.810399,
              39.15548
            ],
            [
              -118.810536,
              39.141774
            ],
            [
              -118.792751,
              39.141572
            ],
            [
              -118.792319,
              39.112425
            ],
            [
              -118.754014,
              39.112023
            ],
            [
              -118.753835,
              39.07463
            ],
            [
              -117.865116,
              39.073654
            ],
            [
              -117.775468,
              39.093425
            ],
            [
              -117.783086,
              39.106089
            ],
            [
              -117.801668,
              39.115916
            ],
            [
              -117.791497,
              39.131382
            ],
            [
              -117.806786,
              39.145375
            ],
            [
              -117.785025,
              39.150538
            ],
            [
              -117.793046,
              39.161099
            ],
            [
              -117.782407,
              39.175849
            ],
            [
              -117.770992,
              39.17416
            ],
            [
              -117.744234,
              39.191425
            ],
            [
              -117.734611,
              39.210074
            ],
            [
              -117.719897,
              39.213377
            ],
            [
              -117.714056,
              39.245558
            ],
            [
              -117.722805,
              39.250781
            ],
            [
              -117.73298,
              39.281348
            ],
            [
              -117.752673,
              39.311781
            ],
            [
              -117.763046,
              39.35283
            ],
            [
              -117.759795,
              39.369225
            ],
            [
              -117.746483,
              39.385736
            ],
            [
              -117.731896,
              39.380218
            ],
            [
              -117.696305,
              39.385934
            ],
            [
              -117.67309,
              39.423839
            ],
            [
              -117.646011,
              39.426602
            ],
            [
              -117.637231,
              39.451821
            ],
            [
              -117.653755,
              39.45777
            ],
            [
              -117.656397,
              39.468043
            ],
            [
              -117.623237,
              39.476209
            ],
            [
              -117.615458,
              39.489794
            ],
            [
              -117.58684,
              39.503022
            ],
            [
              -117.59024,
              39.517521
            ],
            [
              -117.565739,
              39.519222
            ],
            [
              -117.560839,
              39.525921
            ],
            [
              -117.534138,
              39.528322
            ],
            [
              -117.518514,
              39.538212
            ],
            [
              -117.510543,
              39.531349
            ],
            [
              -117.485236,
              39.545622
            ],
            [
              -117.47609,
              39.583641
            ],
            [
              -117.482135,
              39.598222
            ],
            [
              -117.461587,
              39.622644
            ],
            [
              -117.463174,
              39.632902
            ],
            [
              -117.486071,
              39.644966
            ],
            [
              -117.465086,
              39.675969
            ],
            [
              -117.487824,
              39.703903
            ],
            [
              -117.475212,
              39.735851
            ],
            [
              -117.495344,
              39.748364
            ],
            [
              -117.494949,
              39.7742
            ],
            [
              -117.48574,
              39.786518
            ],
            [
              -117.488242,
              39.826625
            ],
            [
              -117.47234,
              39.839218
            ],
            [
              -117.46464,
              39.858218
            ],
            [
              -117.46164,
              39.886817
            ],
            [
              -117.467941,
              39.922117
            ],
            [
              -117.478042,
              39.939617
            ],
            [
              -117.500943,
              39.951566
            ],
            [
              -117.507143,
              39.968816
            ],
            [
              -117.541749,
              40.001034
            ],
            [
              -118.245185,
              40.002379
            ],
            [
              -119.225176,
              39.999385
            ]
          ]
        ]
      ]
    }
  }
]
};