export const counties_ok = {
  "type":"FeatureCollection","name":"ok_counties","features":[
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "011",
      "COUNTYNS": "01101793",
      "AFFGEOID": "0500000US40011",
      "GEOID": "40011",
      "NAME": "Blaine",
      "LSAD": "06",
      "ALAND": 2404605819,
      "AWATER": 26851760,
      "County_state": "Blaine,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.636899,
              36.164894
            ],
            [
              -98.636149,
              35.898808
            ],
            [
              -98.631985,
              35.812402
            ],
            [
              -98.631833,
              35.744699
            ],
            [
              -98.632185,
              35.566032
            ],
            [
              -98.63207,
              35.551617
            ],
            [
              -98.623149,
              35.551597
            ],
            [
              -98.386196,
              35.551445
            ],
            [
              -98.313251,
              35.551523
            ],
            [
              -98.313196,
              35.710716
            ],
            [
              -98.31337,
              35.725204
            ],
            [
              -98.207104,
              35.72514
            ],
            [
              -98.206927,
              35.841023
            ],
            [
              -98.206841,
              35.898602
            ],
            [
              -98.210544,
              36.16489
            ],
            [
              -98.601244,
              36.165771
            ],
            [
              -98.636899,
              36.164894
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "031",
      "COUNTYNS": "01101803",
      "AFFGEOID": "0500000US40031",
      "GEOID": "40031",
      "NAME": "Comanche",
      "LSAD": "06",
      "ALAND": 2769447440,
      "AWATER": 37331078,
      "County_state": "Comanche,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.826124,
              34.855466
            ],
            [
              -98.826008,
              34.594413
            ],
            [
              -98.82607,
              34.50737
            ],
            [
              -98.685529,
              34.507468
            ],
            [
              -98.661982,
              34.50746
            ],
            [
              -98.661956,
              34.405948
            ],
            [
              -98.609536,
              34.406143
            ],
            [
              -98.609561,
              34.420623
            ],
            [
              -98.504988,
              34.420452
            ],
            [
              -98.504958,
              34.449414
            ],
            [
              -98.295928,
              34.449043
            ],
            [
              -98.295887,
              34.463535
            ],
            [
              -98.244168,
              34.463723
            ],
            [
              -98.24412,
              34.507121
            ],
            [
              -98.142148,
              34.506994
            ],
            [
              -98.141799,
              34.681158
            ],
            [
              -98.089098,
              34.681119
            ],
            [
              -98.089036,
              34.840718
            ],
            [
              -98.092645,
              34.854919
            ],
            [
              -98.387202,
              34.855165
            ],
            [
              -98.545351,
              34.855104
            ],
            [
              -98.619816,
              34.854953
            ],
            [
              -98.826124,
              34.855466
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "009",
      "COUNTYNS": "01101792",
      "AFFGEOID": "0500000US40009",
      "GEOID": "40009",
      "NAME": "Beckham",
      "LSAD": "06",
      "ALAND": 2335665266,
      "AWATER": 5527154,
      "County_state": "Beckham,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.000388844143,
              35.4223639883094
            ],
            [
              -100.000385,
              35.182702
            ],
            [
              -100.00038360336801,
              35.0303849961589
            ],
            [
              -99.888571,
              35.030262
            ],
            [
              -99.782949,
              35.030111
            ],
            [
              -99.782727,
              35.11678
            ],
            [
              -99.439998,
              35.109104
            ],
            [
              -99.409291,
              35.114509
            ],
            [
              -99.408338,
              35.11649
            ],
            [
              -99.360226,
              35.116738
            ],
            [
              -99.360219,
              35.204464
            ],
            [
              -99.364282,
              35.421312
            ],
            [
              -99.364217,
              35.465328
            ],
            [
              -99.364141,
              35.508793
            ],
            [
              -99.452607,
              35.508913
            ],
            [
              -99.575598,
              35.508861
            ],
            [
              -99.57582,
              35.421655
            ],
            [
              -100.000388844143,
              35.4223639883094
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "073",
      "COUNTYNS": "01101824",
      "AFFGEOID": "0500000US40073",
      "GEOID": "40073",
      "NAME": "Kingfisher",
      "LSAD": "06",
      "ALAND": 2326224060,
      "AWATER": 20348781,
      "County_state": "Kingfisher,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.210544,
              36.16489
            ],
            [
              -98.206841,
              35.898602
            ],
            [
              -98.206927,
              35.841023
            ],
            [
              -98.207104,
              35.72514
            ],
            [
              -98.065908,
              35.7254
            ],
            [
              -97.994771,
              35.7255
            ],
            [
              -97.958986,
              35.726066
            ],
            [
              -97.780558,
              35.725722
            ],
            [
              -97.674026,
              35.72597
            ],
            [
              -97.674376,
              35.861022
            ],
            [
              -97.675617,
              36.164663
            ],
            [
              -97.85374,
              36.16463
            ],
            [
              -98.103904,
              36.164877
            ],
            [
              -98.210544,
              36.16489
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "039",
      "COUNTYNS": "01101807",
      "AFFGEOID": "0500000US40039",
      "GEOID": "40039",
      "NAME": "Custer",
      "LSAD": "06",
      "ALAND": 2561032754,
      "AWATER": 34321688,
      "County_state": "Custer,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.375817,
              35.812188
            ],
            [
              -99.377052,
              35.551741
            ],
            [
              -99.364129,
              35.55172
            ],
            [
              -99.364141,
              35.508793
            ],
            [
              -99.364217,
              35.465328
            ],
            [
              -99.046253,
              35.465173
            ],
            [
              -98.623336,
              35.464225
            ],
            [
              -98.623255,
              35.53626
            ],
            [
              -98.623149,
              35.551597
            ],
            [
              -98.63207,
              35.551617
            ],
            [
              -98.632185,
              35.566032
            ],
            [
              -98.631833,
              35.744699
            ],
            [
              -98.631985,
              35.812402
            ],
            [
              -98.709097,
              35.81244
            ],
            [
              -98.709084,
              35.811917
            ],
            [
              -99.375817,
              35.812188
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "055",
      "COUNTYNS": "01101815",
      "AFFGEOID": "0500000US40055",
      "GEOID": "40055",
      "NAME": "Greer",
      "LSAD": "06",
      "ALAND": 1655842972,
      "AWATER": 11157422,
      "County_state": "Greer,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.888571,
              35.030262
            ],
            [
              -99.888425,
              34.943946
            ],
            [
              -99.731092,
              34.944058
            ],
            [
              -99.723217,
              34.85698
            ],
            [
              -99.72,
              34.857033
            ],
            [
              -99.720066,
              34.76845
            ],
            [
              -99.684839,
              34.768378
            ],
            [
              -99.684712,
              34.753744
            ],
            [
              -99.667145,
              34.753742
            ],
            [
              -99.666849,
              34.724555
            ],
            [
              -99.42319,
              34.725306
            ],
            [
              -99.44261,
              34.730231
            ],
            [
              -99.437041,
              34.736464
            ],
            [
              -99.437316,
              34.769133
            ],
            [
              -99.405711,
              34.769025
            ],
            [
              -99.405821,
              34.813038
            ],
            [
              -99.250361,
              34.812735
            ],
            [
              -99.246462,
              34.822114
            ],
            [
              -99.256441,
              34.836593
            ],
            [
              -99.277942,
              34.839373
            ],
            [
              -99.282244,
              34.84897
            ],
            [
              -99.30622,
              34.857632
            ],
            [
              -99.31728,
              34.871489
            ],
            [
              -99.315863,
              34.885628
            ],
            [
              -99.291296,
              34.886233
            ],
            [
              -99.289519,
              34.902356
            ],
            [
              -99.298389,
              34.924067
            ],
            [
              -99.330818,
              34.944761
            ],
            [
              -99.334329,
              34.972769
            ],
            [
              -99.348092,
              34.983285
            ],
            [
              -99.335055,
              34.988453
            ],
            [
              -99.328173,
              35.021473
            ],
            [
              -99.327788,
              35.041697
            ],
            [
              -99.350202,
              35.043704
            ],
            [
              -99.365256,
              35.060155
            ],
            [
              -99.368367,
              35.087867
            ],
            [
              -99.398572,
              35.094791
            ],
            [
              -99.409291,
              35.114509
            ],
            [
              -99.439998,
              35.109104
            ],
            [
              -99.782727,
              35.11678
            ],
            [
              -99.782949,
              35.030111
            ],
            [
              -99.888571,
              35.030262
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "021",
      "COUNTYNS": "01101798",
      "AFFGEOID": "0500000US40021",
      "GEOID": "40021",
      "NAME": "Cherokee",
      "LSAD": "06",
      "ALAND": 1940958254,
      "AWATER": 69624105,
      "County_state": "Cherokee,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.280669,
              35.918935
            ],
            [
              -95.281565,
              35.904081
            ],
            [
              -95.257702,
              35.877297
            ],
            [
              -95.232494,
              35.872752
            ],
            [
              -95.227498,
              35.862166
            ],
            [
              -95.231459,
              35.851195
            ],
            [
              -95.253162,
              35.833956
            ],
            [
              -95.265679,
              35.813266
            ],
            [
              -95.127163,
              35.812755
            ],
            [
              -95.127118,
              35.798458
            ],
            [
              -95.127189,
              35.795995
            ],
            [
              -95.127464,
              35.638894
            ],
            [
              -95.012741,
              35.638397
            ],
            [
              -94.917316,
              35.63862
            ],
            [
              -94.807297,
              35.638604
            ],
            [
              -94.807333,
              35.900926
            ],
            [
              -94.797201,
              35.900917
            ],
            [
              -94.79726,
              35.976019
            ],
            [
              -94.797279,
              36.16139
            ],
            [
              -95.011303,
              36.161815
            ],
            [
              -95.1185,
              36.16163
            ],
            [
              -95.118377,
              36.074544
            ],
            [
              -95.134155,
              36.074568
            ],
            [
              -95.207946,
              36.074708
            ],
            [
              -95.20838,
              36.039875
            ],
            [
              -95.225341,
              36.039841
            ],
            [
              -95.225466,
              36.0092
            ],
            [
              -95.238437,
              35.987999
            ],
            [
              -95.252188,
              35.987845
            ],
            [
              -95.278701,
              35.964102
            ],
            [
              -95.269909,
              35.947432
            ],
            [
              -95.269281,
              35.929404
            ],
            [
              -95.280669,
              35.918935
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "051",
      "COUNTYNS": "01101813",
      "AFFGEOID": "0500000US40051",
      "GEOID": "40051",
      "NAME": "Grady",
      "LSAD": "06",
      "ALAND": 2850272919,
      "AWATER": 11403868,
      "County_state": "Grady,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.096014,
              35.377753
            ],
            [
              -98.09134,
              35.20373
            ],
            [
              -98.092804,
              35.088638
            ],
            [
              -98.093057,
              34.985228
            ],
            [
              -98.093146,
              34.883934
            ],
            [
              -98.092645,
              34.854919
            ],
            [
              -98.089036,
              34.840718
            ],
            [
              -98.089098,
              34.681119
            ],
            [
              -98.072074,
              34.681296
            ],
            [
              -98.037028,
              34.681522
            ],
            [
              -97.887712,
              34.681561
            ],
            [
              -97.668425,
              34.681344
            ],
            [
              -97.668099,
              34.855164
            ],
            [
              -97.670662,
              34.857762
            ],
            [
              -97.67057,
              34.927857
            ],
            [
              -97.670465,
              35.160337
            ],
            [
              -97.670833,
              35.291009
            ],
            [
              -97.670942,
              35.332893
            ],
            [
              -97.671595,
              35.335934
            ],
            [
              -97.706159,
              35.324989
            ],
            [
              -97.737305,
              35.323762
            ],
            [
              -97.761499,
              35.340347
            ],
            [
              -97.769273,
              35.34565
            ],
            [
              -97.792022,
              35.332702
            ],
            [
              -97.807186,
              35.340846
            ],
            [
              -97.828705,
              35.334359
            ],
            [
              -97.833675,
              35.335362
            ],
            [
              -97.85682,
              35.344909
            ],
            [
              -97.862805,
              35.36183
            ],
            [
              -97.884098,
              35.349012
            ],
            [
              -97.903086,
              35.345404
            ],
            [
              -97.901566,
              35.353442
            ],
            [
              -97.920835,
              35.353518
            ],
            [
              -97.932252,
              35.373383
            ],
            [
              -97.947114,
              35.374917
            ],
            [
              -97.946843,
              35.356412
            ],
            [
              -97.955124,
              35.346048
            ],
            [
              -97.99971,
              35.362776
            ],
            [
              -98.006399,
              35.360381
            ],
            [
              -98.017453,
              35.377911
            ],
            [
              -98.096014,
              35.377753
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "019",
      "COUNTYNS": "01101797",
      "AFFGEOID": "0500000US40019",
      "GEOID": "40019",
      "NAME": "Carter",
      "LSAD": "06",
      "ALAND": 2129428214,
      "AWATER": 30012909,
      "County_state": "Carter,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.562616,
              34.172921
            ],
            [
              -97.562616,
              34.082921
            ],
            [
              -97.560764,
              34.071089
            ],
            [
              -96.969627,
              34.07102
            ],
            [
              -96.968415,
              34.172617
            ],
            [
              -96.933671,
              34.172553
            ],
            [
              -96.933457,
              34.332661
            ],
            [
              -97.028704,
              34.332638
            ],
            [
              -97.030744,
              34.334875
            ],
            [
              -97.040644,
              34.3385
            ],
            [
              -97.042639,
              34.341783
            ],
            [
              -97.035399,
              34.347808
            ],
            [
              -97.033734,
              34.372915
            ],
            [
              -97.049131,
              34.375547
            ],
            [
              -97.352158,
              34.376227
            ],
            [
              -97.35211,
              34.506788
            ],
            [
              -97.562323,
              34.507036
            ],
            [
              -97.562399,
              34.419301
            ],
            [
              -97.562575,
              34.289182
            ],
            [
              -97.562616,
              34.172921
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "127",
      "COUNTYNS": "01101851",
      "AFFGEOID": "0500000US40127",
      "GEOID": "40127",
      "NAME": "Pushmataha",
      "LSAD": "06",
      "ALAND": 3615205224,
      "AWATER": 69981947,
      "County_state": "Pushmataha,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.779425,
              34.506556
            ],
            [
              -95.779002,
              34.500097
            ],
            [
              -95.779376,
              34.156699
            ],
            [
              -95.730006,
              34.156451
            ],
            [
              -95.729739,
              34.156716
            ],
            [
              -95.156521,
              34.156965
            ],
            [
              -95.151233,
              34.243906
            ],
            [
              -95.046585,
              34.243671
            ],
            [
              -95.046746,
              34.507229
            ],
            [
              -94.937605,
              34.506831
            ],
            [
              -94.937752,
              34.680877
            ],
            [
              -95.059616,
              34.680736
            ],
            [
              -95.514514,
              34.681142
            ],
            [
              -95.514315,
              34.594153
            ],
            [
              -95.671976,
              34.593852
            ],
            [
              -95.6717,
              34.506799
            ],
            [
              -95.779425,
              34.506556
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "037",
      "COUNTYNS": "01101806",
      "AFFGEOID": "0500000US40037",
      "GEOID": "40037",
      "NAME": "Creek",
      "LSAD": "06",
      "ALAND": 2460877094,
      "AWATER": 50957689,
      "County_state": "Creek,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.621441,
              36.159929
            ],
            [
              -96.621353,
              35.95923
            ],
            [
              -96.621097,
              35.941519
            ],
            [
              -96.620648,
              35.639005
            ],
            [
              -96.192563,
              35.639087
            ],
            [
              -96.192382,
              35.857048
            ],
            [
              -96.141561,
              35.857262
            ],
            [
              -96.086128,
              35.857197
            ],
            [
              -96.033118,
              35.856824
            ],
            [
              -96.033226,
              35.871469
            ],
            [
              -96.02957,
              35.901106
            ],
            [
              -96.029487,
              35.944723
            ],
            [
              -96.029544,
              35.988387
            ],
            [
              -96.029518,
              36.031966
            ],
            [
              -96.02956,
              36.046427
            ],
            [
              -96.029583,
              36.075366
            ],
            [
              -96.074102,
              36.075565
            ],
            [
              -96.101073,
              36.075571
            ],
            [
              -96.176255,
              36.075678
            ],
            [
              -96.297786,
              36.075779
            ],
            [
              -96.297888,
              36.162279
            ],
            [
              -96.400822,
              36.163218
            ],
            [
              -96.619244,
              36.163545
            ],
            [
              -96.621441,
              36.159929
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "003",
      "COUNTYNS": "01101789",
      "AFFGEOID": "0500000US40003",
      "GEOID": "40003",
      "NAME": "Alfalfa",
      "LSAD": "06",
      "ALAND": 2244107192,
      "AWATER": 38546675,
      "County_state": "Alfalfa,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.5446600848551,
              36.9989961823461
            ],
            [
              -98.540237,
              36.942228
            ],
            [
              -98.5334,
              36.594217
            ],
            [
              -98.533617,
              36.46323
            ],
            [
              -98.104427,
              36.463105
            ],
            [
              -98.104286,
              36.593578
            ],
            [
              -98.109296,
              36.594618
            ],
            [
              -98.111542,
              36.949335
            ],
            [
              -98.111985,
              36.998133
            ],
            [
              -98.147452,
              36.998162
            ],
            [
              -98.177596,
              36.998009
            ],
            [
              -98.208218,
              36.997997
            ],
            [
              -98.219499,
              36.997824
            ],
            [
              -98.237712,
              36.997972
            ],
            [
              -98.346188,
              36.997962
            ],
            [
              -98.3471489755077,
              36.9979618781261
            ],
            [
              -98.354073,
              36.997961
            ],
            [
              -98.408991,
              36.998513
            ],
            [
              -98.418268,
              36.998538
            ],
            [
              -98.420209,
              36.998516
            ],
            [
              -98.5446600848551,
              36.9989961823461
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "033",
      "COUNTYNS": "01101804",
      "AFFGEOID": "0500000US40033",
      "GEOID": "40033",
      "NAME": "Cotton",
      "LSAD": "06",
      "ALAND": 1638566730,
      "AWATER": 24095321,
      "County_state": "Cotton,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.661956,
              34.405948
            ],
            [
              -98.662004,
              34.333431
            ],
            [
              -98.60966,
              34.33372
            ],
            [
              -98.6101579900004,
              34.157098719969596
            ],
            [
              -98.608853,
              34.157521
            ],
            [
              -98.603978,
              34.160249
            ],
            [
              -98.599789,
              34.160571
            ],
            [
              -98.577136,
              34.148962
            ],
            [
              -98.572451,
              34.145091
            ],
            [
              -98.560191,
              34.133202
            ],
            [
              -98.558593,
              34.128254
            ],
            [
              -98.550917,
              34.119334
            ],
            [
              -98.536257,
              34.107343
            ],
            [
              -98.530611,
              34.099843
            ],
            [
              -98.5282,
              34.094961
            ],
            [
              -98.504182,
              34.072371
            ],
            [
              -98.486328,
              34.062598
            ],
            [
              -98.48204,
              34.06227
            ],
            [
              -98.475066,
              34.064269
            ],
            [
              -98.449034,
              34.073462
            ],
            [
              -98.446379,
              34.07543
            ],
            [
              -98.445784,
              34.076827
            ],
            [
              -98.445585,
              34.079298
            ],
            [
              -98.443724,
              34.082152
            ],
            [
              -98.442808,
              34.083144
            ],
            [
              -98.440092,
              34.084311
            ],
            [
              -98.432127,
              34.085622
            ],
            [
              -98.42848,
              34.085523
            ],
            [
              -98.42523,
              34.084799
            ],
            [
              -98.4231158951185,
              34.0835477226062
            ],
            [
              -98.422253,
              34.083037
            ],
            [
              -98.419995,
              34.082488
            ],
            [
              -98.417813,
              34.083029
            ],
            [
              -98.414426,
              34.085074
            ],
            [
              -98.399777,
              34.099973
            ],
            [
              -98.398389,
              34.104566
            ],
            [
              -98.39816,
              34.121396
            ],
            [
              -98.400494,
              34.121778
            ],
            [
              -98.400967,
              34.122236
            ],
            [
              -98.398441,
              34.128456
            ],
            [
              -98.384381,
              34.146317
            ],
            [
              -98.381238,
              34.149454
            ],
            [
              -98.367494,
              34.156191
            ],
            [
              -98.364023,
              34.157109
            ],
            [
              -98.325445,
              34.151025
            ],
            [
              -98.32258,
              34.14972
            ],
            [
              -98.31875,
              34.146421
            ],
            [
              -98.300209,
              34.134579
            ],
            [
              -98.293901,
              34.13302
            ],
            [
              -98.280321,
              34.13075
            ],
            [
              -98.256467,
              34.129481
            ],
            [
              -98.247954,
              34.130717
            ],
            [
              -98.241013,
              34.133103
            ],
            [
              -98.225282,
              34.127245
            ],
            [
              -98.2236,
              34.125093
            ],
            [
              -98.216463,
              34.121821
            ],
            [
              -98.203711,
              34.117676
            ],
            [
              -98.200075,
              34.116783
            ],
            [
              -98.191455,
              34.115753
            ],
            [
              -98.16912,
              34.114171
            ],
            [
              -98.157412,
              34.120467
            ],
            [
              -98.154354,
              34.122734
            ],
            [
              -98.142754,
              34.136359
            ],
            [
              -98.1389788043646,
              34.141805401056196
            ],
            [
              -98.139226,
              34.289775
            ],
            [
              -98.139164,
              34.391304
            ],
            [
              -98.139232,
              34.504777
            ],
            [
              -98.142148,
              34.506994
            ],
            [
              -98.24412,
              34.507121
            ],
            [
              -98.244168,
              34.463723
            ],
            [
              -98.295887,
              34.463535
            ],
            [
              -98.295928,
              34.449043
            ],
            [
              -98.504958,
              34.449414
            ],
            [
              -98.504988,
              34.420452
            ],
            [
              -98.609561,
              34.420623
            ],
            [
              -98.609536,
              34.406143
            ],
            [
              -98.661956,
              34.405948
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "145",
      "COUNTYNS": "01101860",
      "AFFGEOID": "0500000US40145",
      "GEOID": "40145",
      "NAME": "Wagoner",
      "LSAD": "06",
      "ALAND": 1454425176,
      "AWATER": 75403442,
      "County_state": "Wagoner,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.819455,
              35.901095
            ],
            [
              -95.819964,
              35.8559
            ],
            [
              -95.766166,
              35.856403
            ],
            [
              -95.65042,
              35.856756
            ],
            [
              -95.640455,
              35.834945
            ],
            [
              -95.638313,
              35.787596
            ],
            [
              -95.621518,
              35.777641
            ],
            [
              -95.60195,
              35.778087
            ],
            [
              -95.590149,
              35.765428
            ],
            [
              -95.579872,
              35.769743
            ],
            [
              -95.563091,
              35.787908
            ],
            [
              -95.556066,
              35.788699
            ],
            [
              -95.518172,
              35.792764
            ],
            [
              -95.477552,
              35.804199
            ],
            [
              -95.420248,
              35.799599
            ],
            [
              -95.362317,
              35.822574
            ],
            [
              -95.324002,
              35.813394
            ],
            [
              -95.287267,
              35.813162
            ],
            [
              -95.265679,
              35.813266
            ],
            [
              -95.253162,
              35.833956
            ],
            [
              -95.231459,
              35.851195
            ],
            [
              -95.227498,
              35.862166
            ],
            [
              -95.232494,
              35.872752
            ],
            [
              -95.257702,
              35.877297
            ],
            [
              -95.281565,
              35.904081
            ],
            [
              -95.280669,
              35.918935
            ],
            [
              -95.269281,
              35.929404
            ],
            [
              -95.269909,
              35.947432
            ],
            [
              -95.278701,
              35.964102
            ],
            [
              -95.252188,
              35.987845
            ],
            [
              -95.238437,
              35.987999
            ],
            [
              -95.225466,
              36.0092
            ],
            [
              -95.225341,
              36.039841
            ],
            [
              -95.20838,
              36.039875
            ],
            [
              -95.207946,
              36.074708
            ],
            [
              -95.364483,
              36.075289
            ],
            [
              -95.43997,
              36.0752
            ],
            [
              -95.571525,
              36.075282
            ],
            [
              -95.552587,
              36.091486
            ],
            [
              -95.568309,
              36.107401
            ],
            [
              -95.567844,
              36.129378
            ],
            [
              -95.604696,
              36.13346
            ],
            [
              -95.619187,
              36.162529
            ],
            [
              -95.672684,
              36.162333
            ],
            [
              -95.71961,
              36.16199
            ],
            [
              -95.726408,
              36.162496
            ],
            [
              -95.76165,
              36.16275
            ],
            [
              -95.761664,
              36.133471
            ],
            [
              -95.761641,
              36.118981
            ],
            [
              -95.761667,
              36.10445
            ],
            [
              -95.761656,
              36.090289
            ],
            [
              -95.761632,
              36.046435
            ],
            [
              -95.761629,
              36.031919
            ],
            [
              -95.76165,
              36.017439
            ],
            [
              -95.76163,
              35.993413
            ],
            [
              -95.761656,
              35.988387
            ],
            [
              -95.761564,
              35.909206
            ],
            [
              -95.761687,
              35.900811
            ],
            [
              -95.819455,
              35.901095
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "027",
      "COUNTYNS": "01101801",
      "AFFGEOID": "0500000US40027",
      "GEOID": "40027",
      "NAME": "Cleveland",
      "LSAD": "06",
      "ALAND": 1395529148,
      "AWATER": 49908184,
      "County_state": "Cleveland,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.671369,
              35.377151
            ],
            [
              -97.671529,
              35.337508
            ],
            [
              -97.652537,
              35.333381
            ],
            [
              -97.617799,
              35.305211
            ],
            [
              -97.599013,
              35.303351
            ],
            [
              -97.579004,
              35.30182
            ],
            [
              -97.559281,
              35.290686
            ],
            [
              -97.554779,
              35.261978
            ],
            [
              -97.566482,
              35.247436
            ],
            [
              -97.562218,
              35.23463
            ],
            [
              -97.54146,
              35.214165
            ],
            [
              -97.513976,
              35.202018
            ],
            [
              -97.443785,
              35.160615
            ],
            [
              -97.438253,
              35.147039
            ],
            [
              -97.407082,
              35.138144
            ],
            [
              -97.402685,
              35.117141
            ],
            [
              -97.402076,
              35.08637
            ],
            [
              -97.368319,
              35.08524
            ],
            [
              -97.354192,
              35.076656
            ],
            [
              -97.350491,
              35.051093
            ],
            [
              -97.358009,
              35.033715
            ],
            [
              -97.341894,
              34.990256
            ],
            [
              -97.341509,
              34.964001
            ],
            [
              -97.30771,
              34.953983
            ],
            [
              -97.299633,
              34.964611
            ],
            [
              -97.273851,
              34.962994
            ],
            [
              -97.261592,
              34.945441
            ],
            [
              -97.232689,
              34.9419
            ],
            [
              -97.227903,
              34.932138
            ],
            [
              -97.217896,
              34.944277
            ],
            [
              -97.198413,
              34.939844
            ],
            [
              -97.196172,
              34.92825
            ],
            [
              -97.154556,
              34.931385
            ],
            [
              -97.142349,
              34.928176
            ],
            [
              -97.142084,
              35.014818
            ],
            [
              -97.14202,
              35.101718
            ],
            [
              -97.14206,
              35.145213
            ],
            [
              -97.142184,
              35.192086
            ],
            [
              -97.142026,
              35.33321
            ],
            [
              -97.142114,
              35.37686
            ],
            [
              -97.300174,
              35.377322
            ],
            [
              -97.317832,
              35.377286
            ],
            [
              -97.423566,
              35.377316
            ],
            [
              -97.441222,
              35.377274
            ],
            [
              -97.459227,
              35.37734
            ],
            [
              -97.476985,
              35.377316
            ],
            [
              -97.503515,
              35.377241
            ],
            [
              -97.521151,
              35.377181
            ],
            [
              -97.54438,
              35.377135
            ],
            [
              -97.565564,
              35.377103
            ],
            [
              -97.57501,
              35.377124
            ],
            [
              -97.671369,
              35.377151
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "099",
      "COUNTYNS": "01101837",
      "AFFGEOID": "0500000US40099",
      "GEOID": "40099",
      "NAME": "Murray",
      "LSAD": "06",
      "ALAND": 1078624775,
      "AWATER": 22365113,
      "County_state": "Murray,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.35211,
              34.506788
            ],
            [
              -97.352158,
              34.376227
            ],
            [
              -97.049131,
              34.375547
            ],
            [
              -97.033734,
              34.372915
            ],
            [
              -97.035399,
              34.347808
            ],
            [
              -97.042639,
              34.341783
            ],
            [
              -97.040644,
              34.3385
            ],
            [
              -97.030744,
              34.334875
            ],
            [
              -97.028704,
              34.332638
            ],
            [
              -96.933457,
              34.332661
            ],
            [
              -96.881005,
              34.332977
            ],
            [
              -96.880814,
              34.506053
            ],
            [
              -96.827288,
              34.505989
            ],
            [
              -96.82729,
              34.59322
            ],
            [
              -96.932423,
              34.593261
            ],
            [
              -96.932328,
              34.636811
            ],
            [
              -97.142671,
              34.637126
            ],
            [
              -97.142371,
              34.622594
            ],
            [
              -97.200073,
              34.622762
            ],
            [
              -97.18935,
              34.596011
            ],
            [
              -97.167061,
              34.588181
            ],
            [
              -97.176091,
              34.57143
            ],
            [
              -97.157545,
              34.550974
            ],
            [
              -97.164932,
              34.530699
            ],
            [
              -97.143872,
              34.521316
            ],
            [
              -97.153095,
              34.516788
            ],
            [
              -97.148765,
              34.512186
            ],
            [
              -97.143958,
              34.506532
            ],
            [
              -97.35211,
              34.506788
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "111",
      "COUNTYNS": "01101843",
      "AFFGEOID": "0500000US40111",
      "GEOID": "40111",
      "NAME": "Okmulgee",
      "LSAD": "06",
      "ALAND": 1806123424,
      "AWATER": 12915763,
      "County_state": "Okmulgee,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.192563,
              35.639087
            ],
            [
              -96.192844,
              35.551902
            ],
            [
              -96.087795,
              35.552119
            ],
            [
              -96.08753,
              35.376877
            ],
            [
              -95.981307,
              35.376828
            ],
            [
              -95.960786,
              35.376807
            ],
            [
              -95.875626,
              35.37669
            ],
            [
              -95.875572,
              35.463625
            ],
            [
              -95.823396,
              35.463969
            ],
            [
              -95.822361,
              35.551693
            ],
            [
              -95.712957,
              35.551802
            ],
            [
              -95.713081,
              35.725807
            ],
            [
              -95.766265,
              35.72575
            ],
            [
              -95.766166,
              35.856403
            ],
            [
              -95.819964,
              35.8559
            ],
            [
              -95.90837,
              35.856399
            ],
            [
              -96.033118,
              35.856824
            ],
            [
              -96.086128,
              35.857197
            ],
            [
              -96.141561,
              35.857262
            ],
            [
              -96.192382,
              35.857048
            ],
            [
              -96.192563,
              35.639087
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "023",
      "COUNTYNS": "01101799",
      "AFFGEOID": "0500000US40023",
      "GEOID": "40023",
      "NAME": "Choctaw",
      "LSAD": "06",
      "ALAND": 1995215215,
      "AWATER": 75666999,
      "County_state": "Choctaw,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.991578,
              34.156805
            ],
            [
              -95.953447,
              34.148336
            ],
            [
              -95.949783,
              34.129157
            ],
            [
              -95.93815,
              34.1251
            ],
            [
              -95.937947,
              33.948163
            ],
            [
              -95.935311,
              33.941405
            ],
            [
              -95.918871,
              33.925057
            ],
            [
              -95.878595,
              33.915762
            ],
            [
              -95.868083,
              33.905932
            ],
            [
              -95.861069,
              33.887357
            ],
            [
              -95.840297,
              33.87417
            ],
            [
              -95.805095,
              33.870116
            ],
            [
              -95.76183973777901,
              33.8729497991814
            ],
            [
              -95.762559,
              33.874367
            ],
            [
              -95.761916,
              33.883402
            ],
            [
              -95.758344,
              33.890611
            ],
            [
              -95.756367,
              33.892625
            ],
            [
              -95.747335,
              33.895756
            ],
            [
              -95.737508,
              33.895967
            ],
            [
              -95.728449,
              33.893704
            ],
            [
              -95.71354,
              33.885124
            ],
            [
              -95.710878,
              33.884552
            ],
            [
              -95.696962,
              33.885218
            ],
            [
              -95.684831,
              33.890232
            ],
            [
              -95.676925,
              33.897237
            ],
            [
              -95.669978,
              33.905844
            ],
            [
              -95.665338,
              33.908132
            ],
            [
              -95.659818,
              33.909092
            ],
            [
              -95.647273,
              33.905976
            ],
            [
              -95.636978,
              33.906613
            ],
            [
              -95.603657,
              33.927195
            ],
            [
              -95.599678,
              33.934247
            ],
            [
              -95.585945,
              33.93448
            ],
            [
              -95.563424,
              33.932193
            ],
            [
              -95.561007,
              33.931552
            ],
            [
              -95.559414,
              33.930179
            ],
            [
              -95.556915,
              33.92702
            ],
            [
              -95.551148,
              33.914566
            ],
            [
              -95.549145,
              33.90795
            ],
            [
              -95.549475,
              33.901311
            ],
            [
              -95.552331,
              33.89442
            ],
            [
              -95.552085,
              33.888422
            ],
            [
              -95.548325,
              33.882744
            ],
            [
              -95.545197,
              33.880294
            ],
            [
              -95.53979,
              33.879904
            ],
            [
              -95.533283,
              33.881162
            ],
            [
              -95.525322,
              33.885487
            ],
            [
              -95.520138,
              33.889329
            ],
            [
              -95.515302,
              33.891142
            ],
            [
              -95.510063,
              33.890135
            ],
            [
              -95.506234,
              33.886306
            ],
            [
              -95.506495,
              33.878589
            ],
            [
              -95.506085,
              33.87639
            ],
            [
              -95.502304,
              33.874742
            ],
            [
              -95.492028,
              33.874822
            ],
            [
              -95.478575,
              33.879301
            ],
            [
              -95.469962,
              33.886105
            ],
            [
              -95.464925,
              33.886709
            ],
            [
              -95.46291,
              33.885903
            ],
            [
              -95.461499,
              33.883686
            ],
            [
              -95.464211,
              33.873372
            ],
            [
              -95.463346,
              33.872313
            ],
            [
              -95.44737,
              33.86885
            ],
            [
              -95.407795,
              33.866308
            ],
            [
              -95.375233,
              33.868243
            ],
            [
              -95.352338,
              33.867789
            ],
            [
              -95.339122,
              33.868873
            ],
            [
              -95.334854,
              33.876831
            ],
            [
              -95.334523,
              33.885788
            ],
            [
              -95.333452,
              33.886286
            ],
            [
              -95.325572,
              33.885704
            ],
            [
              -95.30945815240901,
              33.8803039268509
            ],
            [
              -95.294789,
              33.875388
            ],
            [
              -95.287865,
              33.874946
            ],
            [
              -95.283445,
              33.877746
            ],
            [
              -95.281677,
              33.882902
            ],
            [
              -95.28153,
              33.887617
            ],
            [
              -95.280351,
              33.896751
            ],
            [
              -95.279762,
              33.899109
            ],
            [
              -95.277846,
              33.900877
            ],
            [
              -95.275342,
              33.901761
            ],
            [
              -95.272542,
              33.902055
            ],
            [
              -95.26385,
              33.899256
            ],
            [
              -95.261051,
              33.899993
            ],
            [
              -95.255747,
              33.902939
            ],
            [
              -95.253095,
              33.905444
            ],
            [
              -95.250885,
              33.913105
            ],
            [
              -95.250737,
              33.917083
            ],
            [
              -95.251327,
              33.924155
            ],
            [
              -95.25302,
              33.927237
            ],
            [
              -95.253623,
              33.92971
            ],
            [
              -95.252906,
              33.933648
            ],
            [
              -95.230491,
              33.960764
            ],
            [
              -95.226393,
              33.961954
            ],
            [
              -95.219358,
              33.961567
            ],
            [
              -95.184075,
              33.950353
            ],
            [
              -95.168746,
              33.941606
            ],
            [
              -95.166686,
              33.939728
            ],
            [
              -95.161109,
              33.937598
            ],
            [
              -95.1558884157265,
              33.9370323283804
            ],
            [
              -95.156521,
              34.156965
            ],
            [
              -95.729739,
              34.156716
            ],
            [
              -95.730006,
              34.156451
            ],
            [
              -95.779376,
              34.156699
            ],
            [
              -95.919013,
              34.156625
            ],
            [
              -95.991578,
              34.156805
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "067",
      "COUNTYNS": "01101821",
      "AFFGEOID": "0500000US40067",
      "GEOID": "40067",
      "NAME": "Jefferson",
      "LSAD": "06",
      "ALAND": 1965365168,
      "AWATER": 39497494,
      "County_state": "Jefferson,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.139226,
              34.289775
            ],
            [
              -98.1389788043646,
              34.141805401056196
            ],
            [
              -98.13677,
              34.144992
            ],
            [
              -98.130816,
              34.150532
            ],
            [
              -98.123377,
              34.15454
            ],
            [
              -98.114506,
              34.154727
            ],
            [
              -98.109462,
              34.154111
            ],
            [
              -98.107065,
              34.152531
            ],
            [
              -98.101937,
              34.14683
            ],
            [
              -98.090224,
              34.130181
            ],
            [
              -98.089755,
              34.128211
            ],
            [
              -98.09066,
              34.12198
            ],
            [
              -98.092421,
              34.116917
            ],
            [
              -98.095118,
              34.11119
            ],
            [
              -98.099328,
              34.104295
            ],
            [
              -98.104309,
              34.0982
            ],
            [
              -98.119417,
              34.084474
            ],
            [
              -98.121039,
              34.081266
            ],
            [
              -98.120208,
              34.072127
            ],
            [
              -98.11803,
              34.067065
            ],
            [
              -98.114587,
              34.06228
            ],
            [
              -98.099096,
              34.048639
            ],
            [
              -98.096177,
              34.044625
            ],
            [
              -98.096542,
              34.040976
            ],
            [
              -98.097272,
              34.038969
            ],
            [
              -98.098001,
              34.03824
            ],
            [
              -98.102015,
              34.037327
            ],
            [
              -98.104022,
              34.036233
            ],
            [
              -98.105482,
              34.033861
            ],
            [
              -98.105482,
              34.031307
            ],
            [
              -98.103617,
              34.029207
            ],
            [
              -98.088203,
              34.005481
            ],
            [
              -98.08526,
              34.003259
            ],
            [
              -98.082839,
              34.002412
            ],
            [
              -98.055197,
              33.995841
            ],
            [
              -98.041117,
              33.993456
            ],
            [
              -98.027672,
              33.993357
            ],
            [
              -98.019485,
              33.993804
            ],
            [
              -98.005667,
              33.995964
            ],
            [
              -97.987388,
              33.999823
            ],
            [
              -97.982806,
              34.001949
            ],
            [
              -97.978243,
              34.005387
            ],
            [
              -97.974173,
              34.006716
            ],
            [
              -97.97167,
              34.005434
            ],
            [
              -97.96834,
              34.00053
            ],
            [
              -97.963028,
              33.994235
            ],
            [
              -97.958325,
              33.990846
            ],
            [
              -97.95585,
              33.990136
            ],
            [
              -97.952688,
              33.990114
            ],
            [
              -97.947572,
              33.991053
            ],
            [
              -97.946473,
              33.990732
            ],
            [
              -97.94573,
              33.989839
            ],
            [
              -97.94595,
              33.988396
            ],
            [
              -97.956917,
              33.958502
            ],
            [
              -97.960351,
              33.951928
            ],
            [
              -97.965737,
              33.947392
            ],
            [
              -97.972662,
              33.944527
            ],
            [
              -97.974173,
              33.942832
            ],
            [
              -97.974062,
              33.940289
            ],
            [
              -97.972494,
              33.937907
            ],
            [
              -97.971175,
              33.937129
            ],
            [
              -97.965953,
              33.936191
            ],
            [
              -97.963425,
              33.936237
            ],
            [
              -97.955511,
              33.938186
            ],
            [
              -97.954467,
              33.937774
            ],
            [
              -97.953395,
              33.936445
            ],
            [
              -97.952679,
              33.929482
            ],
            [
              -97.953695,
              33.924373
            ],
            [
              -97.957155,
              33.914454
            ],
            [
              -97.960615,
              33.910354
            ],
            [
              -97.964461,
              33.907398
            ],
            [
              -97.969873,
              33.905999
            ],
            [
              -97.973143,
              33.908014
            ],
            [
              -97.976963,
              33.912549
            ],
            [
              -97.978804,
              33.912548
            ],
            [
              -97.979985,
              33.911402
            ],
            [
              -97.983552,
              33.904002
            ],
            [
              -97.98454,
              33.900703
            ],
            [
              -97.984566,
              33.899077
            ],
            [
              -97.983769,
              33.8972
            ],
            [
              -97.977859,
              33.889929
            ],
            [
              -97.97780788974269,
              33.8898833742718
            ],
            [
              -97.974178,
              33.886643
            ],
            [
              -97.967777,
              33.88243
            ],
            [
              -97.958438,
              33.879179
            ],
            [
              -97.951215,
              33.878424
            ],
            [
              -97.946464,
              33.878883
            ],
            [
              -97.94273,
              33.879845
            ],
            [
              -97.938802,
              33.879891
            ],
            [
              -97.936743,
              33.879204
            ],
            [
              -97.905467,
              33.863531
            ],
            [
              -97.896738,
              33.857985
            ],
            [
              -97.877387,
              33.850236
            ],
            [
              -97.871447,
              33.849001
            ],
            [
              -97.865765,
              33.849393
            ],
            [
              -97.834333,
              33.857671
            ],
            [
              -97.805423,
              33.877167
            ],
            [
              -97.803473,
              33.88019
            ],
            [
              -97.801578,
              33.885138
            ],
            [
              -97.784657,
              33.890632
            ],
            [
              -97.780618,
              33.895533
            ],
            [
              -97.779683,
              33.899243
            ],
            [
              -97.78034,
              33.904833
            ],
            [
              -97.783717,
              33.91056
            ],
            [
              -97.772672,
              33.914382
            ],
            [
              -97.765446,
              33.913532
            ],
            [
              -97.76377,
              33.914241
            ],
            [
              -97.760224,
              33.917194
            ],
            [
              -97.759399,
              33.91882
            ],
            [
              -97.759834,
              33.92521
            ],
            [
              -97.762661,
              33.930846
            ],
            [
              -97.762768,
              33.934396
            ],
            [
              -97.752957,
              33.937049
            ],
            [
              -97.738478,
              33.937421
            ],
            [
              -97.736554,
              33.936575
            ],
            [
              -97.733723,
              33.936392
            ],
            [
              -97.732267,
              33.936691
            ],
            [
              -97.725289,
              33.941045
            ],
            [
              -97.716772,
              33.947666
            ],
            [
              -97.709684,
              33.954997
            ],
            [
              -97.704159,
              33.963336
            ],
            [
              -97.697921,
              33.977331
            ],
            [
              -97.69311,
              33.983699
            ],
            [
              -97.688023,
              33.986607
            ],
            [
              -97.671772,
              33.99137
            ],
            [
              -97.661489,
              33.990818
            ],
            [
              -97.65621,
              33.989488
            ],
            [
              -97.633778,
              33.981257
            ],
            [
              -97.609091,
              33.968093
            ],
            [
              -97.589598,
              33.953554
            ],
            [
              -97.588828,
              33.951882
            ],
            [
              -97.591514,
              33.9282
            ],
            [
              -97.595084,
              33.922954
            ],
            [
              -97.596155,
              33.922106
            ],
            [
              -97.596979,
              33.920228
            ],
            [
              -97.597115,
              33.917868
            ],
            [
              -97.596289,
              33.913769
            ],
            [
              -97.589254,
              33.903922
            ],
            [
              -97.587441,
              33.902479
            ],
            [
              -97.582744,
              33.900785
            ],
            [
              -97.56117554354529,
              33.8975366004539
            ],
            [
              -97.560928,
              33.983964
            ],
            [
              -97.536818,
              33.983877
            ],
            [
              -97.560872,
              34.002978
            ],
            [
              -97.560764,
              34.071089
            ],
            [
              -97.562616,
              34.082921
            ],
            [
              -97.562616,
              34.172921
            ],
            [
              -97.562575,
              34.289182
            ],
            [
              -97.982166,
              34.289104
            ],
            [
              -98.139226,
              34.289775
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "105",
      "COUNTYNS": "01101840",
      "AFFGEOID": "0500000US40105",
      "GEOID": "40105",
      "NAME": "Nowata",
      "LSAD": "06",
      "ALAND": 1465358455,
      "AWATER": 38869450,
      "County_state": "Nowata,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.80982,
              36.94193
            ],
            [
              -95.809687,
              36.75696
            ],
            [
              -95.809617,
              36.597476
            ],
            [
              -95.43157,
              36.59754
            ],
            [
              -95.431731,
              36.942776
            ],
            [
              -95.407844,
              36.94271
            ],
            [
              -95.40757195069268,
              36.9992412018471
            ],
            [
              -95.407683,
              36.999241
            ],
            [
              -95.511578,
              36.999235
            ],
            [
              -95.5224149880945,
              36.9992810582678
            ],
            [
              -95.534401,
              36.999332
            ],
            [
              -95.573598,
              36.99931
            ],
            [
              -95.61214,
              36.999321
            ],
            [
              -95.615934,
              36.999365
            ],
            [
              -95.62435,
              36.99936
            ],
            [
              -95.630079,
              36.99932
            ],
            [
              -95.664301,
              36.999322
            ],
            [
              -95.6740419235188,
              36.9993338732759
            ],
            [
              -95.686452,
              36.999349
            ],
            [
              -95.696659,
              36.999215
            ],
            [
              -95.71038,
              36.999371
            ],
            [
              -95.714887,
              36.999279
            ],
            [
              -95.718054,
              36.999255
            ],
            [
              -95.741908,
              36.999244
            ],
            [
              -95.7464701516561,
              36.9992508443682
            ],
            [
              -95.759905,
              36.999271
            ],
            [
              -95.768719,
              36.999205
            ],
            [
              -95.786762,
              36.99931
            ],
            [
              -95.78666,
              36.94194
            ],
            [
              -95.80982,
              36.94193
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "151",
      "COUNTYNS": "01101863",
      "AFFGEOID": "0500000US40151",
      "GEOID": "40151",
      "NAME": "Woods",
      "LSAD": "06",
      "ALAND": 3331900449,
      "AWATER": 9091699,
      "County_state": "Woods,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.456203,
              36.999471
            ],
            [
              -99.443415,
              36.995491
            ],
            [
              -99.420519,
              36.941137
            ],
            [
              -99.398092,
              36.921539
            ],
            [
              -99.374218,
              36.879597
            ],
            [
              -99.314288,
              36.856191
            ],
            [
              -99.317419,
              36.842331
            ],
            [
              -99.295186,
              36.819566
            ],
            [
              -99.294259,
              36.805939
            ],
            [
              -99.270217,
              36.789727
            ],
            [
              -99.250383,
              36.785015
            ],
            [
              -99.210563,
              36.79069
            ],
            [
              -99.18909,
              36.787363
            ],
            [
              -99.16666,
              36.790602
            ],
            [
              -99.139855,
              36.78304
            ],
            [
              -99.12656,
              36.762566
            ],
            [
              -99.097449,
              36.742059
            ],
            [
              -99.08449,
              36.721582
            ],
            [
              -99.061515,
              36.71545
            ],
            [
              -99.044347,
              36.694948
            ],
            [
              -99.048105,
              36.686468
            ],
            [
              -99.023506,
              36.661131
            ],
            [
              -99.02254,
              36.642434
            ],
            [
              -99.012958,
              36.640241
            ],
            [
              -98.971484,
              36.60032
            ],
            [
              -98.959973,
              36.598991
            ],
            [
              -98.960334,
              36.506914
            ],
            [
              -98.843646,
              36.506248
            ],
            [
              -98.81946,
              36.470995
            ],
            [
              -98.80782,
              36.460744
            ],
            [
              -98.777705,
              36.448442
            ],
            [
              -98.72681,
              36.445332
            ],
            [
              -98.709224,
              36.421958
            ],
            [
              -98.699493,
              36.416013
            ],
            [
              -98.625361,
              36.392595
            ],
            [
              -98.593447,
              36.392031
            ],
            [
              -98.552542,
              36.402318
            ],
            [
              -98.53355,
              36.397592
            ],
            [
              -98.533617,
              36.46323
            ],
            [
              -98.5334,
              36.594217
            ],
            [
              -98.540237,
              36.942228
            ],
            [
              -98.5446600848551,
              36.9989961823461
            ],
            [
              -98.544872,
              36.998997
            ],
            [
              -98.714512,
              36.99906
            ],
            [
              -98.718465,
              36.99918
            ],
            [
              -98.761597,
              36.999425
            ],
            [
              -98.791936,
              36.999255
            ],
            [
              -98.793711,
              36.999227
            ],
            [
              -98.797452,
              36.999229
            ],
            [
              -98.869449,
              36.999286
            ],
            [
              -98.880009,
              36.999263
            ],
            [
              -98.88058,
              36.999309
            ],
            [
              -98.994371,
              36.999493
            ],
            [
              -99.00030291303248,
              36.999510304099104
            ],
            [
              -99.029337,
              36.999595
            ],
            [
              -99.049695,
              36.999221
            ],
            [
              -99.124883,
              36.99942
            ],
            [
              -99.129449,
              36.999422
            ],
            [
              -99.24812,
              36.999565
            ],
            [
              -99.277506,
              36.999579
            ],
            [
              -99.375391,
              37.000177
            ],
            [
              -99.407015,
              36.999579
            ],
            [
              -99.456203,
              36.999471
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "153",
      "COUNTYNS": "01101864",
      "AFFGEOID": "0500000US40153",
      "GEOID": "40153",
      "NAME": "Woodward",
      "LSAD": "06",
      "ALAND": 3217798488,
      "AWATER": 9693376,
      "County_state": "Woodward,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.605565,
              36.592753
            ],
            [
              -99.60539,
              36.565433
            ],
            [
              -99.604786,
              36.246629
            ],
            [
              -99.595825,
              36.246701
            ],
            [
              -99.596079,
              36.164061
            ],
            [
              -99.382074,
              36.164301
            ],
            [
              -98.955849,
              36.161577
            ],
            [
              -98.95596,
              36.230901
            ],
            [
              -98.960885,
              36.246772
            ],
            [
              -98.960334,
              36.506914
            ],
            [
              -98.959973,
              36.598991
            ],
            [
              -98.971484,
              36.60032
            ],
            [
              -99.012958,
              36.640241
            ],
            [
              -99.02254,
              36.642434
            ],
            [
              -99.023506,
              36.661131
            ],
            [
              -99.048105,
              36.686468
            ],
            [
              -99.044347,
              36.694948
            ],
            [
              -99.061515,
              36.71545
            ],
            [
              -99.08449,
              36.721582
            ],
            [
              -99.097449,
              36.742059
            ],
            [
              -99.12656,
              36.762566
            ],
            [
              -99.139855,
              36.78304
            ],
            [
              -99.16666,
              36.790602
            ],
            [
              -99.18909,
              36.787363
            ],
            [
              -99.210563,
              36.79069
            ],
            [
              -99.250383,
              36.785015
            ],
            [
              -99.270217,
              36.789727
            ],
            [
              -99.294259,
              36.805939
            ],
            [
              -99.295186,
              36.819566
            ],
            [
              -99.295257,
              36.595123
            ],
            [
              -99.569309,
              36.592936
            ],
            [
              -99.605565,
              36.592753
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "125",
      "COUNTYNS": "01101850",
      "AFFGEOID": "0500000US40125",
      "GEOID": "40125",
      "NAME": "Pottawatomie",
      "LSAD": "06",
      "ALAND": 2040060549,
      "AWATER": 14842439,
      "County_state": "Pottawatomie,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.142084,
              35.014818
            ],
            [
              -97.142349,
              34.928176
            ],
            [
              -97.132867,
              34.934642
            ],
            [
              -97.07972,
              34.928922
            ],
            [
              -97.07179,
              34.91986
            ],
            [
              -97.052477,
              34.921254
            ],
            [
              -97.01593,
              34.907348
            ],
            [
              -96.995926,
              34.914937
            ],
            [
              -96.967564,
              34.934547
            ],
            [
              -96.957037,
              34.950979
            ],
            [
              -96.930586,
              34.964364
            ],
            [
              -96.929872,
              34.965109
            ],
            [
              -96.910609,
              34.958659
            ],
            [
              -96.8854,
              34.926251
            ],
            [
              -96.852375,
              34.92877
            ],
            [
              -96.813452,
              34.944649
            ],
            [
              -96.798208,
              34.929992
            ],
            [
              -96.798583,
              34.905939
            ],
            [
              -96.775681,
              34.899958
            ],
            [
              -96.775901,
              35.144642
            ],
            [
              -96.776916,
              35.173615
            ],
            [
              -96.777024,
              35.289992
            ],
            [
              -96.776694,
              35.405417
            ],
            [
              -96.744271,
              35.406589
            ],
            [
              -96.724807,
              35.425007
            ],
            [
              -96.718812,
              35.411967
            ],
            [
              -96.702399,
              35.416048
            ],
            [
              -96.653747,
              35.392112
            ],
            [
              -96.623699,
              35.400722
            ],
            [
              -96.624865,
              35.462706
            ],
            [
              -96.965156,
              35.46355
            ],
            [
              -97.14149,
              35.463977
            ],
            [
              -97.141668,
              35.434906
            ],
            [
              -97.142114,
              35.37686
            ],
            [
              -97.142026,
              35.33321
            ],
            [
              -97.142184,
              35.192086
            ],
            [
              -97.14206,
              35.145213
            ],
            [
              -97.14202,
              35.101718
            ],
            [
              -97.142084,
              35.014818
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "091",
      "COUNTYNS": "01101836",
      "AFFGEOID": "0500000US40091",
      "GEOID": "40091",
      "NAME": "McIntosh",
      "LSAD": "06",
      "ALAND": 1601897950,
      "AWATER": 242913899,
      "County_state": "McIntosh,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.981376,
              35.289899
            ],
            [
              -95.98371,
              35.151557
            ],
            [
              -95.962477,
              35.152396
            ],
            [
              -95.928458,
              35.143594
            ],
            [
              -95.907966,
              35.143494
            ],
            [
              -95.888374,
              35.155696
            ],
            [
              -95.870663,
              35.185986
            ],
            [
              -95.839673,
              35.184397
            ],
            [
              -95.793271,
              35.197298
            ],
            [
              -95.768471,
              35.178998
            ],
            [
              -95.75597,
              35.177198
            ],
            [
              -95.742286,
              35.192509
            ],
            [
              -95.716157,
              35.189352
            ],
            [
              -95.707153,
              35.202301
            ],
            [
              -95.674436,
              35.208467
            ],
            [
              -95.682767,
              35.216398
            ],
            [
              -95.672066,
              35.227298
            ],
            [
              -95.628064,
              35.246798
            ],
            [
              -95.609163,
              35.249298
            ],
            [
              -95.606763,
              35.235098
            ],
            [
              -95.622464,
              35.224698
            ],
            [
              -95.608136,
              35.220404
            ],
            [
              -95.596201,
              35.223438
            ],
            [
              -95.572662,
              35.244298
            ],
            [
              -95.512959,
              35.266097
            ],
            [
              -95.519051,
              35.279497
            ],
            [
              -95.500366,
              35.300892
            ],
            [
              -95.45125,
              35.296019
            ],
            [
              -95.427054,
              35.305497
            ],
            [
              -95.411153,
              35.323397
            ],
            [
              -95.390252,
              35.327497
            ],
            [
              -95.34465,
              35.293097
            ],
            [
              -95.344837,
              35.467919
            ],
            [
              -95.345033,
              35.551755
            ],
            [
              -95.34915,
              35.552207
            ],
            [
              -95.571072,
              35.55171
            ],
            [
              -95.624471,
              35.55172
            ],
            [
              -95.661993,
              35.551724
            ],
            [
              -95.712957,
              35.551802
            ],
            [
              -95.822361,
              35.551693
            ],
            [
              -95.823396,
              35.463969
            ],
            [
              -95.875572,
              35.463625
            ],
            [
              -95.875626,
              35.37669
            ],
            [
              -95.960786,
              35.376807
            ],
            [
              -95.981307,
              35.376828
            ],
            [
              -95.981376,
              35.289899
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "107",
      "COUNTYNS": "01101841",
      "AFFGEOID": "0500000US40107",
      "GEOID": "40107",
      "NAME": "Okfuskee",
      "LSAD": "06",
      "ALAND": 1602086606,
      "AWATER": 26680140,
      "County_state": "Okfuskee,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.624865,
              35.462706
            ],
            [
              -96.623699,
              35.400722
            ],
            [
              -96.599717,
              35.396955
            ],
            [
              -96.585778,
              35.411513
            ],
            [
              -96.576671,
              35.401934
            ],
            [
              -96.554935,
              35.401692
            ],
            [
              -96.55673,
              35.394974
            ],
            [
              -96.535453,
              35.391172
            ],
            [
              -96.537163,
              35.41094
            ],
            [
              -96.56082,
              35.422691
            ],
            [
              -96.555866,
              35.434701
            ],
            [
              -96.568476,
              35.438165
            ],
            [
              -96.553641,
              35.454309
            ],
            [
              -96.521552,
              35.438493
            ],
            [
              -96.518633,
              35.431205
            ],
            [
              -96.496594,
              35.428896
            ],
            [
              -96.483016,
              35.439842
            ],
            [
              -96.487735,
              35.449159
            ],
            [
              -96.473097,
              35.463958
            ],
            [
              -96.465588,
              35.45811
            ],
            [
              -96.445584,
              35.458771
            ],
            [
              -96.44129,
              35.467712
            ],
            [
              -96.44125,
              35.387229
            ],
            [
              -96.441368,
              35.290122
            ],
            [
              -96.034507,
              35.289795
            ],
            [
              -95.981376,
              35.289899
            ],
            [
              -95.981307,
              35.376828
            ],
            [
              -96.08753,
              35.376877
            ],
            [
              -96.087795,
              35.552119
            ],
            [
              -96.192844,
              35.551902
            ],
            [
              -96.192563,
              35.639087
            ],
            [
              -96.620648,
              35.639005
            ],
            [
              -96.624865,
              35.462706
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "047",
      "COUNTYNS": "01101811",
      "AFFGEOID": "0500000US40047",
      "GEOID": "40047",
      "NAME": "Garfield",
      "LSAD": "06",
      "ALAND": 2741479095,
      "AWATER": 4075930,
      "County_state": "Garfield,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.104286,
              36.593578
            ],
            [
              -98.104427,
              36.463105
            ],
            [
              -98.103904,
              36.164877
            ],
            [
              -97.85374,
              36.16463
            ],
            [
              -97.675617,
              36.164663
            ],
            [
              -97.460792,
              36.164462
            ],
            [
              -97.461603,
              36.593637
            ],
            [
              -97.462459,
              36.593627
            ],
            [
              -97.926115,
              36.593575
            ],
            [
              -98.104286,
              36.593578
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "045",
      "COUNTYNS": "01101810",
      "AFFGEOID": "0500000US40045",
      "GEOID": "40045",
      "NAME": "Ellis",
      "LSAD": "06",
      "ALAND": 3189606147,
      "AWATER": 952235,
      "County_state": "Ellis,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.00316,
              36.59325
            ],
            [
              -100.003761886524,
              36.4996994535481
            ],
            [
              -100.000406,
              36.499702
            ],
            [
              -100.000399,
              36.055677
            ],
            [
              -100.00039619832901,
              35.8809479999968
            ],
            [
              -99.97211,
              35.888776
            ],
            [
              -99.959628,
              35.900036
            ],
            [
              -99.925147,
              35.913664
            ],
            [
              -99.905601,
              35.936727
            ],
            [
              -99.912531,
              35.954986
            ],
            [
              -99.908271,
              35.966634
            ],
            [
              -99.888309,
              35.987847
            ],
            [
              -99.875394,
              35.987798
            ],
            [
              -99.855014,
              36.003685
            ],
            [
              -99.837948,
              36.006794
            ],
            [
              -99.809444,
              36.000044
            ],
            [
              -99.802287,
              35.971603
            ],
            [
              -99.807921,
              35.960342
            ],
            [
              -99.810219,
              35.928909
            ],
            [
              -99.801389,
              35.910645
            ],
            [
              -99.779103,
              35.896393
            ],
            [
              -99.776052,
              35.888132
            ],
            [
              -99.761084,
              35.880745
            ],
            [
              -99.746429,
              35.881961
            ],
            [
              -99.717101,
              35.859769
            ],
            [
              -99.677824,
              35.851008
            ],
            [
              -99.653183,
              35.850123
            ],
            [
              -99.619813,
              35.842089
            ],
            [
              -99.601795,
              35.855905
            ],
            [
              -99.558486,
              35.865988
            ],
            [
              -99.549632,
              35.90022
            ],
            [
              -99.515306,
              35.925796
            ],
            [
              -99.495812,
              35.978732
            ],
            [
              -99.477493,
              35.985731
            ],
            [
              -99.452812,
              36.005241
            ],
            [
              -99.42215,
              36.011926
            ],
            [
              -99.381022,
              36.012925
            ],
            [
              -99.382074,
              36.164301
            ],
            [
              -99.596079,
              36.164061
            ],
            [
              -99.595825,
              36.246701
            ],
            [
              -99.604786,
              36.246629
            ],
            [
              -99.60539,
              36.565433
            ],
            [
              -99.605565,
              36.592753
            ],
            [
              -100.00316,
              36.59325
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "065",
      "COUNTYNS": "01101820",
      "AFFGEOID": "0500000US40065",
      "GEOID": "40065",
      "NAME": "Jackson",
      "LSAD": "06",
      "ALAND": 2078855934,
      "AWATER": 4114419,
      "County_state": "Jackson,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.8446582036581,
              34.5067869364725
            ],
            [
              -99.832904,
              34.500068
            ],
            [
              -99.825325,
              34.497596
            ],
            [
              -99.818186,
              34.48784
            ],
            [
              -99.818739,
              34.484976
            ],
            [
              -99.814313,
              34.476204
            ],
            [
              -99.793684,
              34.453894
            ],
            [
              -99.782986,
              34.444364
            ],
            [
              -99.775743,
              34.444225
            ],
            [
              -99.765599,
              34.437488
            ],
            [
              -99.764826,
              34.436434
            ],
            [
              -99.764882,
              34.435266
            ],
            [
              -99.767648,
              34.431854
            ],
            [
              -99.767234,
              34.430502
            ],
            [
              -99.754248,
              34.421289
            ],
            [
              -99.740907,
              34.414763
            ],
            [
              -99.730348,
              34.41124
            ],
            [
              -99.720259,
              34.406295
            ],
            [
              -99.716416,
              34.402815
            ],
            [
              -99.715089,
              34.400754
            ],
            [
              -99.714232,
              34.397822
            ],
            [
              -99.714838,
              34.394524
            ],
            [
              -99.712682,
              34.390928
            ],
            [
              -99.707901,
              34.387539
            ],
            [
              -99.696462,
              34.381036
            ],
            [
              -99.678283,
              34.379799
            ],
            [
              -99.671377,
              34.377714
            ],
            [
              -99.665992,
              34.374185
            ],
            [
              -99.662705,
              34.37368
            ],
            [
              -99.659362,
              34.37439
            ],
            [
              -99.654194,
              34.376519
            ],
            [
              -99.649662,
              34.379885
            ],
            [
              -99.630905,
              34.376007
            ],
            [
              -99.624197,
              34.373577
            ],
            [
              -99.600026,
              34.374688
            ],
            [
              -99.596323,
              34.377137
            ],
            [
              -99.587596,
              34.385867
            ],
            [
              -99.585442,
              34.388914
            ],
            [
              -99.584531,
              34.391205
            ],
            [
              -99.585306,
              34.398122
            ],
            [
              -99.58448,
              34.407673
            ],
            [
              -99.58006,
              34.416653
            ],
            [
              -99.574367,
              34.418281
            ],
            [
              -99.569696,
              34.418418
            ],
            [
              -99.562204,
              34.417319
            ],
            [
              -99.555986,
              34.41464
            ],
            [
              -99.549242,
              34.412715
            ],
            [
              -99.529786,
              34.411452
            ],
            [
              -99.52365,
              34.412206
            ],
            [
              -99.517624,
              34.414494
            ],
            [
              -99.51428,
              34.414035
            ],
            [
              -99.499875,
              34.409608
            ],
            [
              -99.497091,
              34.407731
            ],
            [
              -99.494104,
              34.404755
            ],
            [
              -99.490426,
              34.399694
            ],
            [
              -99.487219,
              34.397955
            ],
            [
              -99.477547,
              34.396355
            ],
            [
              -99.4751232484102,
              34.3963977417428
            ],
            [
              -99.470969,
              34.396471
            ],
            [
              -99.452648,
              34.388252
            ],
            [
              -99.445021,
              34.379892
            ],
            [
              -99.44076,
              34.374123
            ],
            [
              -99.430995,
              34.373414
            ],
            [
              -99.420432,
              34.380464
            ],
            [
              -99.408848,
              34.372776
            ],
            [
              -99.407168,
              34.372605
            ],
            [
              -99.40296,
              34.373481
            ],
            [
              -99.399603,
              34.375079
            ],
            [
              -99.397253,
              34.377871
            ],
            [
              -99.391492,
              34.405631
            ],
            [
              -99.393919,
              34.415274
            ],
            [
              -99.396488,
              34.417291
            ],
            [
              -99.396902,
              34.418688
            ],
            [
              -99.39701,
              34.424003
            ],
            [
              -99.394956,
              34.442099
            ],
            [
              -99.381011,
              34.456936
            ],
            [
              -99.375365,
              34.458845
            ],
            [
              -99.36961,
              34.458699
            ],
            [
              -99.358795,
              34.455863
            ],
            [
              -99.354672,
              34.451857
            ],
            [
              -99.354837,
              34.449658
            ],
            [
              -99.356771,
              34.446542
            ],
            [
              -99.357102,
              34.444915
            ],
            [
              -99.356713,
              34.442144
            ],
            [
              -99.350407,
              34.437083
            ],
            [
              -99.341337,
              34.431061
            ],
            [
              -99.334037,
              34.427536
            ],
            [
              -99.328674,
              34.422383
            ],
            [
              -99.324222,
              34.414458
            ],
            [
              -99.319606,
              34.408869
            ],
            [
              -99.318363,
              34.408296
            ],
            [
              -99.316373,
              34.408205
            ],
            [
              -99.308274,
              34.410014
            ],
            [
              -99.299098,
              34.414228
            ],
            [
              -99.294648,
              34.415373
            ],
            [
              -99.289922,
              34.414731
            ],
            [
              -99.264167,
              34.405149
            ],
            [
              -99.261321,
              34.403499
            ],
            [
              -99.25898,
              34.391243
            ],
            [
              -99.261191,
              34.389548
            ],
            [
              -99.264508,
              34.388085
            ],
            [
              -99.273958,
              34.38756
            ],
            [
              -99.27534,
              34.386599
            ],
            [
              -99.274926,
              34.384904
            ],
            [
              -99.271281,
              34.381604
            ],
            [
              -99.258696,
              34.372634
            ],
            [
              -99.254722,
              34.372405
            ],
            [
              -99.251408,
              34.37508
            ],
            [
              -99.248969,
              34.375984
            ],
            [
              -99.242945,
              34.372668
            ],
            [
              -99.237233,
              34.362717
            ],
            [
              -99.234252,
              34.353459
            ],
            [
              -99.233274,
              34.344101
            ],
            [
              -99.232606,
              34.34238
            ],
            [
              -99.229994,
              34.340538
            ],
            [
              -99.226153,
              34.339726
            ],
            [
              -99.221975,
              34.34002
            ],
            [
              -99.219769,
              34.341377
            ],
            [
              -99.217335,
              34.34152
            ],
            [
              -99.213135,
              34.340369
            ],
            [
              -99.210716,
              34.336304
            ],
            [
              -99.198281,
              34.339622
            ],
            [
              -99.192747,
              34.354861
            ],
            [
              -99.207639,
              34.374238
            ],
            [
              -99.21974,
              34.374304
            ],
            [
              -99.209162,
              34.388619
            ],
            [
              -99.213862,
              34.394734
            ],
            [
              -99.228255,
              34.387194
            ],
            [
              -99.226631,
              34.401281
            ],
            [
              -99.235143,
              34.401072
            ],
            [
              -99.23806,
              34.425009
            ],
            [
              -99.228022,
              34.419561
            ],
            [
              -99.222969,
              34.432847
            ],
            [
              -99.228038,
              34.451836
            ],
            [
              -99.213383,
              34.456169
            ],
            [
              -99.221158,
              34.464184
            ],
            [
              -99.220707,
              34.494484
            ],
            [
              -99.210106,
              34.501658
            ],
            [
              -99.196801,
              34.542386
            ],
            [
              -99.177978,
              34.540097
            ],
            [
              -99.18451,
              34.562315
            ],
            [
              -99.16332,
              34.588036
            ],
            [
              -99.132213,
              34.59208
            ],
            [
              -99.121796,
              34.587887
            ],
            [
              -99.099668,
              34.594126
            ],
            [
              -99.082881,
              34.590854
            ],
            [
              -99.088457,
              34.615248
            ],
            [
              -99.079258,
              34.618983
            ],
            [
              -99.10315,
              34.63795
            ],
            [
              -99.095321,
              34.668912
            ],
            [
              -99.044001,
              34.676955
            ],
            [
              -99.037094,
              34.687215
            ],
            [
              -99.052511,
              34.701557
            ],
            [
              -99.098576,
              34.69523
            ],
            [
              -99.149114,
              34.702352
            ],
            [
              -99.158735,
              34.710326
            ],
            [
              -99.15468,
              34.724022
            ],
            [
              -99.173039,
              34.755293
            ],
            [
              -99.152029,
              34.760443
            ],
            [
              -99.165569,
              34.773684
            ],
            [
              -99.162592,
              34.793108
            ],
            [
              -99.148985,
              34.798212
            ],
            [
              -99.153665,
              34.827669
            ],
            [
              -99.144442,
              34.82943
            ],
            [
              -99.14457,
              34.84954
            ],
            [
              -99.15915,
              34.847738
            ],
            [
              -99.17066,
              34.848333
            ],
            [
              -99.181912,
              34.859556
            ],
            [
              -99.213052,
              34.82616
            ],
            [
              -99.246462,
              34.822114
            ],
            [
              -99.250361,
              34.812735
            ],
            [
              -99.405821,
              34.813038
            ],
            [
              -99.405711,
              34.769025
            ],
            [
              -99.437316,
              34.769133
            ],
            [
              -99.437041,
              34.736464
            ],
            [
              -99.44261,
              34.730231
            ],
            [
              -99.42319,
              34.725306
            ],
            [
              -99.666849,
              34.724555
            ],
            [
              -99.666512,
              34.507121
            ],
            [
              -99.8446582036581,
              34.5067869364725
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "139",
      "COUNTYNS": "01101857",
      "AFFGEOID": "0500000US40139",
      "GEOID": "40139",
      "NAME": "Texas",
      "LSAD": "06",
      "ALAND": 5286926133,
      "AWATER": 19215617,
      "County_state": "Texas,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -102.032173,
              36.849182
            ],
            [
              -102.03233903405601,
              36.5006100932816
            ],
            [
              -101.930245,
              36.500526
            ],
            [
              -101.826565,
              36.499654
            ],
            [
              -101.826498,
              36.499535
            ],
            [
              -101.78811,
              36.499678
            ],
            [
              -101.783359,
              36.499709
            ],
            [
              -101.781987,
              36.499718
            ],
            [
              -101.78061,
              36.499727
            ],
            [
              -101.779435,
              36.499734
            ],
            [
              -101.709314,
              36.499722
            ],
            [
              -101.698685,
              36.499508
            ],
            [
              -101.653708,
              36.499573
            ],
            [
              -101.649966,
              36.499573
            ],
            [
              -101.623915,
              36.499528
            ],
            [
              -101.085156,
              36.499244
            ],
            [
              -101.052418,
              36.499563
            ],
            [
              -101.045331,
              36.49954
            ],
            [
              -100.977088,
              36.499595
            ],
            [
              -100.954152945903,
              36.4995989128779
            ],
            [
              -100.953774,
              36.848365
            ],
            [
              -100.945551,
              36.848654
            ],
            [
              -100.945468897552,
              36.998152969176196
            ],
            [
              -100.945566,
              36.998152
            ],
            [
              -100.996502,
              36.998044
            ],
            [
              -101.012641,
              36.998176
            ],
            [
              -101.053589,
              36.997967
            ],
            [
              -101.066451000186,
              36.9979220177139
            ],
            [
              -101.066742,
              36.997921
            ],
            [
              -101.211486,
              36.997124
            ],
            [
              -101.212909,
              36.997044
            ],
            [
              -101.357797,
              36.996271
            ],
            [
              -101.359674,
              36.996232
            ],
            [
              -101.37818,
              36.996164
            ],
            [
              -101.413868,
              36.996008
            ],
            [
              -101.415005,
              36.995966
            ],
            [
              -101.485326,
              36.995611
            ],
            [
              -101.519066,
              36.995546
            ],
            [
              -101.555239,
              36.995414
            ],
            [
              -101.555260303913,
              36.9954138768669
            ],
            [
              -101.600396,
              36.995153
            ],
            [
              -101.601593,
              36.995095
            ],
            [
              -101.90244,
              36.993702
            ],
            [
              -102.000447,
              36.993249
            ],
            [
              -102.000447,
              36.993272
            ],
            [
              -102.02820412187499,
              36.9931250152408
            ],
            [
              -102.028248,
              36.853048
            ],
            [
              -102.032173,
              36.849182
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "071",
      "COUNTYNS": "01101823",
      "AFFGEOID": "0500000US40071",
      "GEOID": "40071",
      "NAME": "Kay",
      "LSAD": "06",
      "ALAND": 2382077972,
      "AWATER": 65369682,
      "County_state": "Kay,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.46222,
              36.767311
            ],
            [
              -97.462459,
              36.593627
            ],
            [
              -97.461603,
              36.593637
            ],
            [
              -97.139428,
              36.594028
            ],
            [
              -97.057414,
              36.593647
            ],
            [
              -97.044685,
              36.602721
            ],
            [
              -97.053007,
              36.643934
            ],
            [
              -97.061988,
              36.650733
            ],
            [
              -97.064107,
              36.684207
            ],
            [
              -97.030678,
              36.70159
            ],
            [
              -97.024188,
              36.693404
            ],
            [
              -97.003032,
              36.693454
            ],
            [
              -96.992035,
              36.687596
            ],
            [
              -96.968089,
              36.688599
            ],
            [
              -96.950175,
              36.683634
            ],
            [
              -96.931943,
              36.686097
            ],
            [
              -96.92091,
              36.717102
            ],
            [
              -96.907432,
              36.735842
            ],
            [
              -96.889475,
              36.75123
            ],
            [
              -96.866371,
              36.752943
            ],
            [
              -96.834103,
              36.743438
            ],
            [
              -96.821675,
              36.750036
            ],
            [
              -96.822585,
              36.767559
            ],
            [
              -96.788687,
              36.767597
            ],
            [
              -96.788661,
              36.781991
            ],
            [
              -96.752375,
              36.782092
            ],
            [
              -96.750027,
              36.941329
            ],
            [
              -96.749838,
              36.998988
            ],
            [
              -96.79206,
              36.99918
            ],
            [
              -96.795199,
              36.99886
            ],
            [
              -96.822791,
              36.999182
            ],
            [
              -96.867517,
              36.999217
            ],
            [
              -96.87629,
              36.999233
            ],
            [
              -96.902083,
              36.999155
            ],
            [
              -96.90351,
              36.999132
            ],
            [
              -96.917093,
              36.999182
            ],
            [
              -96.921915,
              36.999151
            ],
            [
              -96.9252940031478,
              36.999129494597696
            ],
            [
              -96.934642,
              36.99907
            ],
            [
              -96.967371,
              36.999067
            ],
            [
              -96.975562,
              36.999019
            ],
            [
              -97.030082,
              36.998929
            ],
            [
              -97.039784,
              36.999
            ],
            [
              -97.100652,
              36.998998
            ],
            [
              -97.104276,
              36.99902
            ],
            [
              -97.120285,
              36.999014
            ],
            [
              -97.122597,
              36.999036
            ],
            [
              -97.147721,
              36.999111
            ],
            [
              -97.30079008310649,
              36.9989406961692
            ],
            [
              -97.372421,
              36.998861
            ],
            [
              -97.384925,
              36.998843
            ],
            [
              -97.46228,
              36.998685
            ],
            [
              -97.4623463219255,
              36.9986852256487
            ],
            [
              -97.46222,
              36.767311
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "081",
      "COUNTYNS": "01101828",
      "AFFGEOID": "0500000US40081",
      "GEOID": "40081",
      "NAME": "Lincoln",
      "LSAD": "06",
      "ALAND": 2466490647,
      "AWATER": 34606636,
      "County_state": "Lincoln,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.141072,
              35.724441
            ],
            [
              -97.14149,
              35.463977
            ],
            [
              -96.965156,
              35.46355
            ],
            [
              -96.624865,
              35.462706
            ],
            [
              -96.620648,
              35.639005
            ],
            [
              -96.621097,
              35.941519
            ],
            [
              -97.140583,
              35.941991
            ],
            [
              -97.140578,
              35.927517
            ],
            [
              -97.141072,
              35.724441
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "083",
      "COUNTYNS": "01101829",
      "AFFGEOID": "0500000US40083",
      "GEOID": "40083",
      "NAME": "Logan",
      "LSAD": "06",
      "ALAND": 1926521247,
      "AWATER": 12926653,
      "County_state": "Logan,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.675617,
              36.164663
            ],
            [
              -97.674376,
              35.861022
            ],
            [
              -97.674026,
              35.72597
            ],
            [
              -97.638468,
              35.725908
            ],
            [
              -97.531857,
              35.725654
            ],
            [
              -97.514049,
              35.725564
            ],
            [
              -97.460514,
              35.72534
            ],
            [
              -97.371579,
              35.725294
            ],
            [
              -97.247139,
              35.724799
            ],
            [
              -97.247132,
              35.724864
            ],
            [
              -97.141072,
              35.724441
            ],
            [
              -97.140578,
              35.927517
            ],
            [
              -97.140583,
              35.941991
            ],
            [
              -97.14289,
              35.954219
            ],
            [
              -97.172378,
              35.941006
            ],
            [
              -97.203592,
              35.944795
            ],
            [
              -97.221192,
              35.964573
            ],
            [
              -97.243174,
              35.972182
            ],
            [
              -97.275325,
              35.973146
            ],
            [
              -97.285885,
              35.98049
            ],
            [
              -97.294601,
              36.005557
            ],
            [
              -97.32362,
              36.010877
            ],
            [
              -97.353249,
              35.985334
            ],
            [
              -97.353864,
              35.989412
            ],
            [
              -97.354132,
              36.159072
            ],
            [
              -97.353946,
              36.164304
            ],
            [
              -97.460792,
              36.164462
            ],
            [
              -97.675617,
              36.164663
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "001",
      "COUNTYNS": "01101788",
      "AFFGEOID": "0500000US40001",
      "GEOID": "40001",
      "NAME": "Adair",
      "LSAD": "06",
      "ALAND": 1485304967,
      "AWATER": 9254749,
      "County_state": "Adair,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.807333,
              35.900926
            ],
            [
              -94.807297,
              35.638604
            ],
            [
              -94.472647,
              35.638556
            ],
            [
              -94.487585,
              35.726147
            ],
            [
              -94.48821,
              35.72924
            ],
            [
              -94.4929318527681,
              35.7591658417281
            ],
            [
              -94.493362,
              35.761892
            ],
            [
              -94.494549,
              35.768303
            ],
            [
              -94.499045,
              35.79346
            ],
            [
              -94.499647,
              35.79691
            ],
            [
              -94.500526,
              35.802642
            ],
            [
              -94.500764,
              35.80382
            ],
            [
              -94.501162,
              35.80643
            ],
            [
              -94.503011,
              35.81721
            ],
            [
              -94.504438,
              35.826369
            ],
            [
              -94.505642,
              35.833628
            ],
            [
              -94.507631,
              35.845901
            ],
            [
              -94.517571,
              35.901866
            ],
            [
              -94.522658,
              35.93425
            ],
            [
              -94.522658,
              35.934799
            ],
            [
              -94.522634,
              35.934892
            ],
            [
              -94.52291,
              35.936127
            ],
            [
              -94.524344,
              35.94405
            ],
            [
              -94.52464,
              35.945727
            ],
            [
              -94.528162,
              35.965665
            ],
            [
              -94.528305,
              35.966054
            ],
            [
              -94.532071,
              35.987852
            ],
            [
              -94.533646,
              35.996804
            ],
            [
              -94.534852,
              36.002678
            ],
            [
              -94.535724,
              36.007807
            ],
            [
              -94.547715,
              36.077271
            ],
            [
              -94.547871,
              36.078281
            ],
            [
              -94.55218431116519,
              36.1022352237111
            ],
            [
              -94.561165,
              36.15211
            ],
            [
              -94.562803,
              36.161749
            ],
            [
              -94.5628276914512,
              36.1618934952038
            ],
            [
              -94.797279,
              36.16139
            ],
            [
              -94.79726,
              35.976019
            ],
            [
              -94.797201,
              35.900917
            ],
            [
              -94.807333,
              35.900926
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "049",
      "COUNTYNS": "01101812",
      "AFFGEOID": "0500000US40049",
      "GEOID": "40049",
      "NAME": "Garvin",
      "LSAD": "06",
      "ALAND": 2077332600,
      "AWATER": 30111858,
      "County_state": "Garvin,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.668099,
              34.855164
            ],
            [
              -97.668425,
              34.681344
            ],
            [
              -97.566456,
              34.681156
            ],
            [
              -97.562757,
              34.67761
            ],
            [
              -97.56286,
              34.56518
            ],
            [
              -97.563116,
              34.510736
            ],
            [
              -97.562323,
              34.507036
            ],
            [
              -97.35211,
              34.506788
            ],
            [
              -97.143958,
              34.506532
            ],
            [
              -97.148765,
              34.512186
            ],
            [
              -97.153095,
              34.516788
            ],
            [
              -97.143872,
              34.521316
            ],
            [
              -97.164932,
              34.530699
            ],
            [
              -97.157545,
              34.550974
            ],
            [
              -97.176091,
              34.57143
            ],
            [
              -97.167061,
              34.588181
            ],
            [
              -97.18935,
              34.596011
            ],
            [
              -97.200073,
              34.622762
            ],
            [
              -97.142371,
              34.622594
            ],
            [
              -97.142671,
              34.637126
            ],
            [
              -96.932328,
              34.636811
            ],
            [
              -96.932438,
              34.81128
            ],
            [
              -96.932471,
              34.854455
            ],
            [
              -97.286404,
              34.855165
            ],
            [
              -97.474163,
              34.855303
            ],
            [
              -97.668099,
              34.855164
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "075",
      "COUNTYNS": "01101825",
      "AFFGEOID": "0500000US40075",
      "GEOID": "40075",
      "NAME": "Kiowa",
      "LSAD": "06",
      "ALAND": 2629209766,
      "AWATER": 40296743,
      "County_state": "Kiowa,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.408338,
              35.11649
            ],
            [
              -99.409291,
              35.114509
            ],
            [
              -99.398572,
              35.094791
            ],
            [
              -99.368367,
              35.087867
            ],
            [
              -99.365256,
              35.060155
            ],
            [
              -99.350202,
              35.043704
            ],
            [
              -99.327788,
              35.041697
            ],
            [
              -99.328173,
              35.021473
            ],
            [
              -99.335055,
              34.988453
            ],
            [
              -99.348092,
              34.983285
            ],
            [
              -99.334329,
              34.972769
            ],
            [
              -99.330818,
              34.944761
            ],
            [
              -99.298389,
              34.924067
            ],
            [
              -99.289519,
              34.902356
            ],
            [
              -99.291296,
              34.886233
            ],
            [
              -99.315863,
              34.885628
            ],
            [
              -99.31728,
              34.871489
            ],
            [
              -99.30622,
              34.857632
            ],
            [
              -99.282244,
              34.84897
            ],
            [
              -99.277942,
              34.839373
            ],
            [
              -99.256441,
              34.836593
            ],
            [
              -99.246462,
              34.822114
            ],
            [
              -99.213052,
              34.82616
            ],
            [
              -99.181912,
              34.859556
            ],
            [
              -99.17066,
              34.848333
            ],
            [
              -99.15915,
              34.847738
            ],
            [
              -99.14457,
              34.84954
            ],
            [
              -99.144442,
              34.82943
            ],
            [
              -99.153665,
              34.827669
            ],
            [
              -99.148985,
              34.798212
            ],
            [
              -99.162592,
              34.793108
            ],
            [
              -99.165569,
              34.773684
            ],
            [
              -99.152029,
              34.760443
            ],
            [
              -99.173039,
              34.755293
            ],
            [
              -99.15468,
              34.724022
            ],
            [
              -99.158735,
              34.710326
            ],
            [
              -99.149114,
              34.702352
            ],
            [
              -99.098576,
              34.69523
            ],
            [
              -99.052511,
              34.701557
            ],
            [
              -99.037094,
              34.687215
            ],
            [
              -99.044001,
              34.676955
            ],
            [
              -99.095321,
              34.668912
            ],
            [
              -99.10315,
              34.63795
            ],
            [
              -99.001025,
              34.637872
            ],
            [
              -99.001107,
              34.594366
            ],
            [
              -98.826008,
              34.594413
            ],
            [
              -98.826124,
              34.855466
            ],
            [
              -98.619816,
              34.854953
            ],
            [
              -98.620836,
              35.080039
            ],
            [
              -98.621054,
              35.097352
            ],
            [
              -98.629975,
              35.116385
            ],
            [
              -98.639894,
              35.122849
            ],
            [
              -98.650435,
              35.114836
            ],
            [
              -98.650932,
              35.123929
            ],
            [
              -98.672232,
              35.119009
            ],
            [
              -98.674993,
              35.108955
            ],
            [
              -98.696315,
              35.111909
            ],
            [
              -98.712452,
              35.104761
            ],
            [
              -98.716775,
              35.113349
            ],
            [
              -98.730487,
              35.112177
            ],
            [
              -98.73469,
              35.101846
            ],
            [
              -98.750344,
              35.116343
            ],
            [
              -99.060658,
              35.116604
            ],
            [
              -99.360226,
              35.116738
            ],
            [
              -99.408338,
              35.11649
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "117",
      "COUNTYNS": "01101846",
      "AFFGEOID": "0500000US40117",
      "GEOID": "40117",
      "NAME": "Pawnee",
      "LSAD": "06",
      "ALAND": 1471527632,
      "AWATER": 68888617,
      "County_state": "Pawnee,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.032118,
              36.506957
            ],
            [
              -97.03211,
              36.404893
            ],
            [
              -97.032106,
              36.333354
            ],
            [
              -96.924936,
              36.333344
            ],
            [
              -96.925069,
              36.246474
            ],
            [
              -96.818968,
              36.246478
            ],
            [
              -96.819005,
              36.158886
            ],
            [
              -96.626393,
              36.160161
            ],
            [
              -96.621441,
              36.159929
            ],
            [
              -96.619244,
              36.163545
            ],
            [
              -96.400822,
              36.163218
            ],
            [
              -96.297888,
              36.162279
            ],
            [
              -96.268287,
              36.16198
            ],
            [
              -96.269183,
              36.173684
            ],
            [
              -96.242799,
              36.202601
            ],
            [
              -96.256798,
              36.225727
            ],
            [
              -96.301474,
              36.228222
            ],
            [
              -96.326732,
              36.226551
            ],
            [
              -96.339996,
              36.230024
            ],
            [
              -96.386793,
              36.267137
            ],
            [
              -96.422427,
              36.277456
            ],
            [
              -96.433812,
              36.285889
            ],
            [
              -96.445764,
              36.321268
            ],
            [
              -96.456832,
              36.332173
            ],
            [
              -96.48677,
              36.32985
            ],
            [
              -96.501423,
              36.307359
            ],
            [
              -96.515229,
              36.298332
            ],
            [
              -96.547026,
              36.298126
            ],
            [
              -96.573481,
              36.315709
            ],
            [
              -96.572883,
              36.331856
            ],
            [
              -96.559027,
              36.348543
            ],
            [
              -96.534179,
              36.355957
            ],
            [
              -96.523144,
              36.365215
            ],
            [
              -96.522397,
              36.387061
            ],
            [
              -96.540725,
              36.400402
            ],
            [
              -96.575697,
              36.374347
            ],
            [
              -96.60188,
              36.382258
            ],
            [
              -96.606449,
              36.403268
            ],
            [
              -96.620108,
              36.409783
            ],
            [
              -96.635727,
              36.425908
            ],
            [
              -96.674514,
              36.420422
            ],
            [
              -96.689534,
              36.423851
            ],
            [
              -96.710769,
              36.437656
            ],
            [
              -96.717742,
              36.455311
            ],
            [
              -96.706195,
              36.477564
            ],
            [
              -96.709665,
              36.49118
            ],
            [
              -96.724851,
              36.503204
            ],
            [
              -96.727926,
              36.530548
            ],
            [
              -96.737239,
              36.550088
            ],
            [
              -96.753554,
              36.562601
            ],
            [
              -96.76947,
              36.565407
            ],
            [
              -96.798149,
              36.553557
            ],
            [
              -96.821031,
              36.538771
            ],
            [
              -96.837714,
              36.520767
            ],
            [
              -96.837911,
              36.511749
            ],
            [
              -96.851678,
              36.491258
            ],
            [
              -96.875321,
              36.470915
            ],
            [
              -96.912476,
              36.467841
            ],
            [
              -96.940235,
              36.455525
            ],
            [
              -96.968393,
              36.46039
            ],
            [
              -97.005473,
              36.486026
            ],
            [
              -97.009785,
              36.506935
            ],
            [
              -97.032118,
              36.506957
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "069",
      "COUNTYNS": "01101822",
      "AFFGEOID": "0500000US40069",
      "GEOID": "40069",
      "NAME": "Johnston",
      "LSAD": "06",
      "ALAND": 1665212040,
      "AWATER": 39633300,
      "County_state": "Johnston,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.933457,
              34.332661
            ],
            [
              -96.933671,
              34.172553
            ],
            [
              -96.669965,
              34.172254
            ],
            [
              -96.653728,
              34.161402
            ],
            [
              -96.641897,
              34.168243
            ],
            [
              -96.619783,
              34.161191
            ],
            [
              -96.606821,
              34.139873
            ],
            [
              -96.582251,
              34.125327
            ],
            [
              -96.585355,
              34.114459
            ],
            [
              -96.453839,
              34.114406
            ],
            [
              -96.453901,
              34.157348
            ],
            [
              -96.40764,
              34.157317
            ],
            [
              -96.40733,
              34.418391
            ],
            [
              -96.453317,
              34.418503
            ],
            [
              -96.471726,
              34.41956
            ],
            [
              -96.513786,
              34.419569
            ],
            [
              -96.513865,
              34.505389
            ],
            [
              -96.827288,
              34.505989
            ],
            [
              -96.880814,
              34.506053
            ],
            [
              -96.881005,
              34.332977
            ],
            [
              -96.933457,
              34.332661
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "087",
      "COUNTYNS": "01101834",
      "AFFGEOID": "0500000US40087",
      "GEOID": "40087",
      "NAME": "McClain",
      "LSAD": "06",
      "ALAND": 1478114523,
      "AWATER": 24734523,
      "County_state": "McClain,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.671529,
              35.337508
            ],
            [
              -97.671595,
              35.335934
            ],
            [
              -97.670942,
              35.332893
            ],
            [
              -97.670833,
              35.291009
            ],
            [
              -97.670465,
              35.160337
            ],
            [
              -97.67057,
              34.927857
            ],
            [
              -97.670662,
              34.857762
            ],
            [
              -97.668099,
              34.855164
            ],
            [
              -97.474163,
              34.855303
            ],
            [
              -97.286404,
              34.855165
            ],
            [
              -96.932471,
              34.854455
            ],
            [
              -96.93084,
              34.857563
            ],
            [
              -96.930586,
              34.964364
            ],
            [
              -96.957037,
              34.950979
            ],
            [
              -96.967564,
              34.934547
            ],
            [
              -96.995926,
              34.914937
            ],
            [
              -97.01593,
              34.907348
            ],
            [
              -97.052477,
              34.921254
            ],
            [
              -97.07179,
              34.91986
            ],
            [
              -97.07972,
              34.928922
            ],
            [
              -97.132867,
              34.934642
            ],
            [
              -97.142349,
              34.928176
            ],
            [
              -97.154556,
              34.931385
            ],
            [
              -97.196172,
              34.92825
            ],
            [
              -97.198413,
              34.939844
            ],
            [
              -97.217896,
              34.944277
            ],
            [
              -97.227903,
              34.932138
            ],
            [
              -97.232689,
              34.9419
            ],
            [
              -97.261592,
              34.945441
            ],
            [
              -97.273851,
              34.962994
            ],
            [
              -97.299633,
              34.964611
            ],
            [
              -97.30771,
              34.953983
            ],
            [
              -97.341509,
              34.964001
            ],
            [
              -97.341894,
              34.990256
            ],
            [
              -97.358009,
              35.033715
            ],
            [
              -97.350491,
              35.051093
            ],
            [
              -97.354192,
              35.076656
            ],
            [
              -97.368319,
              35.08524
            ],
            [
              -97.402076,
              35.08637
            ],
            [
              -97.402685,
              35.117141
            ],
            [
              -97.407082,
              35.138144
            ],
            [
              -97.438253,
              35.147039
            ],
            [
              -97.443785,
              35.160615
            ],
            [
              -97.513976,
              35.202018
            ],
            [
              -97.54146,
              35.214165
            ],
            [
              -97.562218,
              35.23463
            ],
            [
              -97.566482,
              35.247436
            ],
            [
              -97.554779,
              35.261978
            ],
            [
              -97.559281,
              35.290686
            ],
            [
              -97.579004,
              35.30182
            ],
            [
              -97.599013,
              35.303351
            ],
            [
              -97.617799,
              35.305211
            ],
            [
              -97.652537,
              35.333381
            ],
            [
              -97.671529,
              35.337508
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "109",
      "COUNTYNS": "01101842",
      "AFFGEOID": "0500000US40109",
      "GEOID": "40109",
      "NAME": "Oklahoma",
      "LSAD": "06",
      "ALAND": 1835846202,
      "AWATER": 24730011,
      "County_state": "Oklahoma,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.674036,
              35.594822
            ],
            [
              -97.674035,
              35.551183
            ],
            [
              -97.67122,
              35.537199
            ],
            [
              -97.67122,
              35.530103
            ],
            [
              -97.67122,
              35.514919
            ],
            [
              -97.67122,
              35.507456
            ],
            [
              -97.671449,
              35.486074
            ],
            [
              -97.671363,
              35.471353
            ],
            [
              -97.671321,
              35.469214
            ],
            [
              -97.671419,
              35.460204
            ],
            [
              -97.671411,
              35.435471
            ],
            [
              -97.671383,
              35.406352
            ],
            [
              -97.671369,
              35.377151
            ],
            [
              -97.57501,
              35.377124
            ],
            [
              -97.565564,
              35.377103
            ],
            [
              -97.54438,
              35.377135
            ],
            [
              -97.521151,
              35.377181
            ],
            [
              -97.503515,
              35.377241
            ],
            [
              -97.476985,
              35.377316
            ],
            [
              -97.459227,
              35.37734
            ],
            [
              -97.441222,
              35.377274
            ],
            [
              -97.423566,
              35.377316
            ],
            [
              -97.317832,
              35.377286
            ],
            [
              -97.300174,
              35.377322
            ],
            [
              -97.142114,
              35.37686
            ],
            [
              -97.141668,
              35.434906
            ],
            [
              -97.14149,
              35.463977
            ],
            [
              -97.141072,
              35.724441
            ],
            [
              -97.247132,
              35.724864
            ],
            [
              -97.247139,
              35.724799
            ],
            [
              -97.371579,
              35.725294
            ],
            [
              -97.460514,
              35.72534
            ],
            [
              -97.514049,
              35.725564
            ],
            [
              -97.531857,
              35.725654
            ],
            [
              -97.638468,
              35.725908
            ],
            [
              -97.674026,
              35.72597
            ],
            [
              -97.673999,
              35.623962
            ],
            [
              -97.674036,
              35.594822
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "113",
      "COUNTYNS": "01101844",
      "AFFGEOID": "0500000US40113",
      "GEOID": "40113",
      "NAME": "Osage",
      "LSAD": "06",
      "ALAND": 5818542165,
      "AWATER": 148734982,
      "County_state": "Osage,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.064107,
              36.684207
            ],
            [
              -97.061988,
              36.650733
            ],
            [
              -97.053007,
              36.643934
            ],
            [
              -97.044685,
              36.602721
            ],
            [
              -97.057414,
              36.593647
            ],
            [
              -97.035868,
              36.586215
            ],
            [
              -97.002857,
              36.582564
            ],
            [
              -96.948628,
              36.589997
            ],
            [
              -96.920492,
              36.600663
            ],
            [
              -96.912756,
              36.598949
            ],
            [
              -96.888017,
              36.574886
            ],
            [
              -96.902643,
              36.551388
            ],
            [
              -96.92471,
              36.552558
            ],
            [
              -96.958521,
              36.534307
            ],
            [
              -96.978973,
              36.535211
            ],
            [
              -96.996194,
              36.528979
            ],
            [
              -97.009785,
              36.506935
            ],
            [
              -97.005473,
              36.486026
            ],
            [
              -96.968393,
              36.46039
            ],
            [
              -96.940235,
              36.455525
            ],
            [
              -96.912476,
              36.467841
            ],
            [
              -96.875321,
              36.470915
            ],
            [
              -96.851678,
              36.491258
            ],
            [
              -96.837911,
              36.511749
            ],
            [
              -96.837714,
              36.520767
            ],
            [
              -96.821031,
              36.538771
            ],
            [
              -96.798149,
              36.553557
            ],
            [
              -96.76947,
              36.565407
            ],
            [
              -96.753554,
              36.562601
            ],
            [
              -96.737239,
              36.550088
            ],
            [
              -96.727926,
              36.530548
            ],
            [
              -96.724851,
              36.503204
            ],
            [
              -96.709665,
              36.49118
            ],
            [
              -96.706195,
              36.477564
            ],
            [
              -96.717742,
              36.455311
            ],
            [
              -96.710769,
              36.437656
            ],
            [
              -96.689534,
              36.423851
            ],
            [
              -96.674514,
              36.420422
            ],
            [
              -96.635727,
              36.425908
            ],
            [
              -96.620108,
              36.409783
            ],
            [
              -96.606449,
              36.403268
            ],
            [
              -96.60188,
              36.382258
            ],
            [
              -96.575697,
              36.374347
            ],
            [
              -96.540725,
              36.400402
            ],
            [
              -96.522397,
              36.387061
            ],
            [
              -96.523144,
              36.365215
            ],
            [
              -96.534179,
              36.355957
            ],
            [
              -96.559027,
              36.348543
            ],
            [
              -96.572883,
              36.331856
            ],
            [
              -96.573481,
              36.315709
            ],
            [
              -96.547026,
              36.298126
            ],
            [
              -96.515229,
              36.298332
            ],
            [
              -96.501423,
              36.307359
            ],
            [
              -96.48677,
              36.32985
            ],
            [
              -96.456832,
              36.332173
            ],
            [
              -96.445764,
              36.321268
            ],
            [
              -96.433812,
              36.285889
            ],
            [
              -96.422427,
              36.277456
            ],
            [
              -96.386793,
              36.267137
            ],
            [
              -96.339996,
              36.230024
            ],
            [
              -96.326732,
              36.226551
            ],
            [
              -96.301474,
              36.228222
            ],
            [
              -96.256798,
              36.225727
            ],
            [
              -96.242799,
              36.202601
            ],
            [
              -96.269183,
              36.173684
            ],
            [
              -96.268287,
              36.16198
            ],
            [
              -96.154182,
              36.160985
            ],
            [
              -96.141497,
              36.160981
            ],
            [
              -96.090796,
              36.161084
            ],
            [
              -96.043752,
              36.161127
            ],
            [
              -96.019852,
              36.161252
            ],
            [
              -96.001055,
              36.161294
            ],
            [
              -96.001466,
              36.219302
            ],
            [
              -96.001396,
              36.242135
            ],
            [
              -96.001405,
              36.244403
            ],
            [
              -96.001276,
              36.250012
            ],
            [
              -96.001463,
              36.307448
            ],
            [
              -96.001171,
              36.423686
            ],
            [
              -96.001145,
              36.439763
            ],
            [
              -96.000956,
              36.736228
            ],
            [
              -96.00066,
              36.770275
            ],
            [
              -96.00081,
              36.99886
            ],
            [
              -96.14121,
              36.998973
            ],
            [
              -96.143207,
              36.999134
            ],
            [
              -96.147143,
              36.999022
            ],
            [
              -96.149709,
              36.99904
            ],
            [
              -96.152384,
              36.999051
            ],
            [
              -96.154017,
              36.999161
            ],
            [
              -96.1847130166869,
              36.999210910599096
            ],
            [
              -96.184768,
              36.999211
            ],
            [
              -96.200028,
              36.999028
            ],
            [
              -96.217571,
              36.99907
            ],
            [
              -96.276368,
              36.999271
            ],
            [
              -96.279079,
              36.999272
            ],
            [
              -96.394272,
              36.999221
            ],
            [
              -96.415412,
              36.999113
            ],
            [
              -96.500288,
              36.998643
            ],
            [
              -96.5255776738603,
              36.9987120358304
            ],
            [
              -96.705431,
              36.999203
            ],
            [
              -96.710482,
              36.999271
            ],
            [
              -96.73659,
              36.999286
            ],
            [
              -96.74127,
              36.999239
            ],
            [
              -96.749838,
              36.998988
            ],
            [
              -96.750027,
              36.941329
            ],
            [
              -96.752375,
              36.782092
            ],
            [
              -96.788661,
              36.781991
            ],
            [
              -96.788687,
              36.767597
            ],
            [
              -96.822585,
              36.767559
            ],
            [
              -96.821675,
              36.750036
            ],
            [
              -96.834103,
              36.743438
            ],
            [
              -96.866371,
              36.752943
            ],
            [
              -96.889475,
              36.75123
            ],
            [
              -96.907432,
              36.735842
            ],
            [
              -96.92091,
              36.717102
            ],
            [
              -96.931943,
              36.686097
            ],
            [
              -96.950175,
              36.683634
            ],
            [
              -96.968089,
              36.688599
            ],
            [
              -96.992035,
              36.687596
            ],
            [
              -97.003032,
              36.693454
            ],
            [
              -97.024188,
              36.693404
            ],
            [
              -97.030678,
              36.70159
            ],
            [
              -97.064107,
              36.684207
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "119",
      "COUNTYNS": "01101847",
      "AFFGEOID": "0500000US40119",
      "GEOID": "40119",
      "NAME": "Payne",
      "LSAD": "06",
      "ALAND": 1773393745,
      "AWATER": 31771425,
      "County_state": "Payne,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.354132,
              36.159072
            ],
            [
              -97.353864,
              35.989412
            ],
            [
              -97.353249,
              35.985334
            ],
            [
              -97.32362,
              36.010877
            ],
            [
              -97.294601,
              36.005557
            ],
            [
              -97.285885,
              35.98049
            ],
            [
              -97.275325,
              35.973146
            ],
            [
              -97.243174,
              35.972182
            ],
            [
              -97.221192,
              35.964573
            ],
            [
              -97.203592,
              35.944795
            ],
            [
              -97.172378,
              35.941006
            ],
            [
              -97.14289,
              35.954219
            ],
            [
              -97.140583,
              35.941991
            ],
            [
              -96.621097,
              35.941519
            ],
            [
              -96.621353,
              35.95923
            ],
            [
              -96.621441,
              36.159929
            ],
            [
              -96.626393,
              36.160161
            ],
            [
              -96.819005,
              36.158886
            ],
            [
              -96.818968,
              36.246478
            ],
            [
              -96.925069,
              36.246474
            ],
            [
              -97.140968,
              36.246435
            ],
            [
              -97.140668,
              36.159231
            ],
            [
              -97.354132,
              36.159072
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "029",
      "COUNTYNS": "01101802",
      "AFFGEOID": "0500000US40029",
      "GEOID": "40029",
      "NAME": "Coal",
      "LSAD": "06",
      "ALAND": 1338200617,
      "AWATER": 12079759,
      "County_state": "Coal,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.513865,
              34.505389
            ],
            [
              -96.513786,
              34.419569
            ],
            [
              -96.471726,
              34.41956
            ],
            [
              -96.453317,
              34.418503
            ],
            [
              -96.40733,
              34.418391
            ],
            [
              -96.145096,
              34.418677
            ],
            [
              -96.145342,
              34.506102
            ],
            [
              -96.092624,
              34.506163
            ],
            [
              -96.09199,
              34.514019
            ],
            [
              -96.092043,
              34.680556
            ],
            [
              -96.092001,
              34.767479
            ],
            [
              -96.406483,
              34.76759
            ],
            [
              -96.406357,
              34.680023
            ],
            [
              -96.512171,
              34.680065
            ],
            [
              -96.512099,
              34.505562
            ],
            [
              -96.513865,
              34.505389
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "063",
      "COUNTYNS": "01101819",
      "AFFGEOID": "0500000US40063",
      "GEOID": "40063",
      "NAME": "Hughes",
      "LSAD": "06",
      "ALAND": 2084026137,
      "AWATER": 26504863,
      "County_state": "Hughes,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.490434,
              35.115857
            ],
            [
              -96.490786,
              34.910585
            ],
            [
              -96.459987,
              34.916438
            ],
            [
              -96.424701,
              34.903687
            ],
            [
              -96.408639,
              34.930673
            ],
            [
              -96.406178,
              34.908369
            ],
            [
              -96.406171,
              34.898103
            ],
            [
              -96.406483,
              34.76759
            ],
            [
              -96.092001,
              34.767479
            ],
            [
              -96.091877,
              34.854536
            ],
            [
              -96.088861,
              35.049758
            ],
            [
              -96.069239,
              35.056585
            ],
            [
              -96.062707,
              35.08807
            ],
            [
              -96.039212,
              35.107645
            ],
            [
              -96.029969,
              35.120336
            ],
            [
              -96.010362,
              35.117438
            ],
            [
              -96.013639,
              35.141787
            ],
            [
              -95.98371,
              35.151557
            ],
            [
              -95.981376,
              35.289899
            ],
            [
              -96.034507,
              35.289795
            ],
            [
              -96.441368,
              35.290122
            ],
            [
              -96.441401,
              35.11577
            ],
            [
              -96.490434,
              35.115857
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "123",
      "COUNTYNS": "01101849",
      "AFFGEOID": "0500000US40123",
      "GEOID": "40123",
      "NAME": "Pontotoc",
      "LSAD": "06",
      "ALAND": 1865885740,
      "AWATER": 12421415,
      "County_state": "Pontotoc,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.932471,
              34.854455
            ],
            [
              -96.932438,
              34.81128
            ],
            [
              -96.932328,
              34.636811
            ],
            [
              -96.932423,
              34.593261
            ],
            [
              -96.82729,
              34.59322
            ],
            [
              -96.827288,
              34.505989
            ],
            [
              -96.513865,
              34.505389
            ],
            [
              -96.512099,
              34.505562
            ],
            [
              -96.512171,
              34.680065
            ],
            [
              -96.406357,
              34.680023
            ],
            [
              -96.406483,
              34.76759
            ],
            [
              -96.406171,
              34.898103
            ],
            [
              -96.406178,
              34.908369
            ],
            [
              -96.408639,
              34.930673
            ],
            [
              -96.424701,
              34.903687
            ],
            [
              -96.459987,
              34.916438
            ],
            [
              -96.490786,
              34.910585
            ],
            [
              -96.512599,
              34.875466
            ],
            [
              -96.528587,
              34.869586
            ],
            [
              -96.543762,
              34.880097
            ],
            [
              -96.555888,
              34.898084
            ],
            [
              -96.556889,
              34.914184
            ],
            [
              -96.577089,
              34.916584
            ],
            [
              -96.59579,
              34.905984
            ],
            [
              -96.62189,
              34.898984
            ],
            [
              -96.64699,
              34.902184
            ],
            [
              -96.667692,
              34.929583
            ],
            [
              -96.699488,
              34.930273
            ],
            [
              -96.710934,
              34.911715
            ],
            [
              -96.695689,
              34.903614
            ],
            [
              -96.68647,
              34.883185
            ],
            [
              -96.694182,
              34.868495
            ],
            [
              -96.727591,
              34.857182
            ],
            [
              -96.742093,
              34.869383
            ],
            [
              -96.755517,
              34.899809
            ],
            [
              -96.775681,
              34.899958
            ],
            [
              -96.798583,
              34.905939
            ],
            [
              -96.798208,
              34.929992
            ],
            [
              -96.813452,
              34.944649
            ],
            [
              -96.852375,
              34.92877
            ],
            [
              -96.8854,
              34.926251
            ],
            [
              -96.910609,
              34.958659
            ],
            [
              -96.929872,
              34.965109
            ],
            [
              -96.930586,
              34.964364
            ],
            [
              -96.93084,
              34.857563
            ],
            [
              -96.932471,
              34.854455
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "061",
      "COUNTYNS": "01101818",
      "AFFGEOID": "0500000US40061",
      "GEOID": "40061",
      "NAME": "Haskell",
      "LSAD": "06",
      "ALAND": 1493182633,
      "AWATER": 126266425,
      "County_state": "Haskell,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.450756,
              35.202797
            ],
            [
              -95.454954,
              35.160577
            ],
            [
              -95.393676,
              35.160195
            ],
            [
              -95.349339,
              35.160296
            ],
            [
              -95.349265,
              35.126051
            ],
            [
              -95.349233,
              35.058514
            ],
            [
              -94.927863,
              35.058279
            ],
            [
              -94.927936,
              35.202611
            ],
            [
              -94.814284,
              35.202331
            ],
            [
              -94.813629,
              35.323344
            ],
            [
              -94.82746,
              35.337412
            ],
            [
              -94.828972,
              35.361546
            ],
            [
              -94.845184,
              35.370785
            ],
            [
              -94.865894,
              35.356599
            ],
            [
              -94.90198,
              35.346709
            ],
            [
              -94.916629,
              35.348731
            ],
            [
              -94.915314,
              35.35865
            ],
            [
              -94.894739,
              35.373088
            ],
            [
              -94.8923,
              35.384345
            ],
            [
              -94.901832,
              35.39794
            ],
            [
              -94.925745,
              35.392845
            ],
            [
              -94.944608,
              35.402457
            ],
            [
              -94.951328,
              35.408334
            ],
            [
              -95.004348,
              35.413471
            ],
            [
              -95.024233,
              35.431395
            ],
            [
              -95.024933,
              35.453095
            ],
            [
              -95.049933,
              35.458894
            ],
            [
              -95.068835,
              35.448895
            ],
            [
              -95.079935,
              35.432996
            ],
            [
              -95.086036,
              35.405896
            ],
            [
              -95.101837,
              35.397596
            ],
            [
              -95.123279,
              35.372324
            ],
            [
              -95.139238,
              35.379996
            ],
            [
              -95.16077,
              35.369358
            ],
            [
              -95.141296,
              35.355565
            ],
            [
              -95.150497,
              35.345871
            ],
            [
              -95.174958,
              35.350554
            ],
            [
              -95.182549,
              35.336857
            ],
            [
              -95.170462,
              35.310106
            ],
            [
              -95.176474,
              35.301344
            ],
            [
              -95.19919,
              35.303592
            ],
            [
              -95.205385,
              35.289418
            ],
            [
              -95.222605,
              35.271722
            ],
            [
              -95.240718,
              35.26231
            ],
            [
              -95.26215,
              35.268994
            ],
            [
              -95.288347,
              35.295197
            ],
            [
              -95.311748,
              35.302697
            ],
            [
              -95.34465,
              35.293097
            ],
            [
              -95.390252,
              35.327497
            ],
            [
              -95.411153,
              35.323397
            ],
            [
              -95.427054,
              35.305497
            ],
            [
              -95.45125,
              35.296019
            ],
            [
              -95.450756,
              35.202797
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "077",
      "COUNTYNS": "01101826",
      "AFFGEOID": "0500000US40077",
      "GEOID": "40077",
      "NAME": "Latimer",
      "LSAD": "06",
      "ALAND": 1870180320,
      "AWATER": 18213565,
      "County_state": "Latimer,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.507523,
              34.854937
            ],
            [
              -95.514514,
              34.681142
            ],
            [
              -95.059616,
              34.680736
            ],
            [
              -95.059946,
              34.738926
            ],
            [
              -95.059512,
              34.855019
            ],
            [
              -94.99781,
              34.855078
            ],
            [
              -94.99812,
              34.942157
            ],
            [
              -94.927668,
              34.942212
            ],
            [
              -94.927706,
              35.013473
            ],
            [
              -94.927863,
              35.058279
            ],
            [
              -95.349233,
              35.058514
            ],
            [
              -95.349437,
              35.029299
            ],
            [
              -95.507658,
              35.029197
            ],
            [
              -95.507523,
              34.854937
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "089",
      "COUNTYNS": "01101835",
      "AFFGEOID": "0500000US40089",
      "GEOID": "40089",
      "NAME": "McCurtain",
      "LSAD": "06",
      "ALAND": 4793134981,
      "AWATER": 133993739,
      "County_state": "McCurtain,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.156521,
              34.156965
            ],
            [
              -95.1558884157265,
              33.9370323283804
            ],
            [
              -95.149462,
              33.936336
            ],
            [
              -95.131056,
              33.936925
            ],
            [
              -95.1247,
              33.934675
            ],
            [
              -95.121184,
              33.931307
            ],
            [
              -95.1225,
              33.921717
            ],
            [
              -95.122365,
              33.918632
            ],
            [
              -95.119951,
              33.915815
            ],
            [
              -95.110964,
              33.912998
            ],
            [
              -95.103318,
              33.913669
            ],
            [
              -95.10077,
              33.912193
            ],
            [
              -95.098489,
              33.909913
            ],
            [
              -95.095002,
              33.904816
            ],
            [
              -95.09527,
              33.899316
            ],
            [
              -95.093929,
              33.895963
            ],
            [
              -95.090441,
              33.89328
            ],
            [
              -95.084002,
              33.89328
            ],
            [
              -95.078905,
              33.898377
            ],
            [
              -95.07126,
              33.901597
            ],
            [
              -95.065492,
              33.899585
            ],
            [
              -95.061065,
              33.895292
            ],
            [
              -95.058834,
              33.886813
            ],
            [
              -95.049025,
              33.86409
            ],
            [
              -95.046568,
              33.862565
            ],
            [
              -95.037207,
              33.86025
            ],
            [
              -95.022325,
              33.859813
            ],
            [
              -95.016422,
              33.861392
            ],
            [
              -95.008376,
              33.866089
            ],
            [
              -95.000223,
              33.862505
            ],
            [
              -94.995524,
              33.857438
            ],
            [
              -94.992671,
              33.852455
            ],
            [
              -94.988487,
              33.851
            ],
            [
              -94.983303,
              33.851354
            ],
            [
              -94.98165,
              33.852284
            ],
            [
              -94.976208,
              33.859847
            ],
            [
              -94.973411,
              33.861731
            ],
            [
              -94.971435,
              33.862123
            ],
            [
              -94.968895,
              33.860916
            ],
            [
              -94.965888,
              33.848422
            ],
            [
              -94.964401,
              33.837021
            ],
            [
              -94.957676,
              33.835004
            ],
            [
              -94.949533,
              33.825708
            ],
            [
              -94.948716,
              33.818023
            ],
            [
              -94.944302,
              33.812138
            ],
            [
              -94.93956,
              33.810503
            ],
            [
              -94.9358,
              33.810339
            ],
            [
              -94.932366,
              33.810993
            ],
            [
              -94.928442,
              33.812628
            ],
            [
              -94.924518,
              33.812792
            ],
            [
              -94.921902,
              33.811811
            ],
            [
              -94.91945,
              33.810176
            ],
            [
              -94.917815,
              33.808704
            ],
            [
              -94.916834,
              33.804617
            ],
            [
              -94.916998,
              33.80151
            ],
            [
              -94.919614,
              33.794153
            ],
            [
              -94.920104,
              33.789575
            ],
            [
              -94.919614,
              33.786305
            ],
            [
              -94.911427,
              33.778383
            ],
            [
              -94.906245,
              33.778192
            ],
            [
              -94.902276,
              33.776289
            ],
            [
              -94.888368,
              33.76724
            ],
            [
              -94.886226,
              33.764594
            ],
            [
              -94.881448,
              33.765549
            ],
            [
              -94.879218,
              33.764912
            ],
            [
              -94.876033,
              33.760771
            ],
            [
              -94.875497,
              33.755483
            ],
            [
              -94.87708,
              33.75222
            ],
            [
              -94.874668,
              33.749164
            ],
            [
              -94.8693,
              33.745871
            ],
            [
              -94.849296,
              33.739585
            ],
            [
              -94.841634,
              33.739431
            ],
            [
              -94.830804,
              33.740068
            ],
            [
              -94.827938,
              33.741342
            ],
            [
              -94.826027,
              33.74389
            ],
            [
              -94.824753,
              33.749305
            ],
            [
              -94.821886,
              33.750897
            ],
            [
              -94.817427,
              33.752172
            ],
            [
              -94.812012,
              33.751853
            ],
            [
              -94.809145,
              33.749305
            ],
            [
              -94.798634,
              33.744527
            ],
            [
              -94.789716,
              33.74612
            ],
            [
              -94.775064,
              33.755038
            ],
            [
              -94.770924,
              33.754401
            ],
            [
              -94.768057,
              33.753446
            ],
            [
              -94.766465,
              33.750897
            ],
            [
              -94.766146,
              33.748031
            ],
            [
              -94.768057,
              33.742616
            ],
            [
              -94.767739,
              33.73752
            ],
            [
              -94.762961,
              33.731787
            ],
            [
              -94.759139,
              33.729557
            ],
            [
              -94.753087,
              33.729557
            ],
            [
              -94.742576,
              33.727009
            ],
            [
              -94.739391,
              33.72255
            ],
            [
              -94.73748,
              33.716179
            ],
            [
              -94.739072,
              33.710128
            ],
            [
              -94.7376180019138,
              33.7060079583273
            ],
            [
              -94.737161,
              33.704713
            ],
            [
              -94.732384,
              33.700254
            ],
            [
              -94.728243,
              33.699617
            ],
            [
              -94.725695,
              33.702483
            ],
            [
              -94.724102,
              33.705669
            ],
            [
              -94.721873,
              33.707261
            ],
            [
              -94.719006,
              33.708217
            ],
            [
              -94.714865,
              33.707261
            ],
            [
              -94.711043,
              33.705669
            ],
            [
              -94.709451,
              33.699617
            ],
            [
              -94.710725,
              33.696113
            ],
            [
              -94.710725,
              33.691654
            ],
            [
              -94.710088,
              33.68815
            ],
            [
              -94.707858,
              33.686876
            ],
            [
              -94.684792,
              33.684353
            ],
            [
              -94.659167,
              33.692138
            ],
            [
              -94.652265,
              33.690979
            ],
            [
              -94.649628,
              33.688049
            ],
            [
              -94.648457,
              33.684534
            ],
            [
              -94.647871,
              33.680432
            ],
            [
              -94.648457,
              33.673401
            ],
            [
              -94.646113,
              33.6693
            ],
            [
              -94.64289,
              33.668421
            ],
            [
              -94.635273,
              33.669886
            ],
            [
              -94.630586,
              33.673401
            ],
            [
              -94.627656,
              33.677796
            ],
            [
              -94.621211,
              33.681018
            ],
            [
              -94.616817,
              33.679554
            ],
            [
              -94.611543,
              33.674866
            ],
            [
              -94.607442,
              33.67223
            ],
            [
              -94.603047,
              33.671351
            ],
            [
              -94.596895,
              33.671351
            ],
            [
              -94.593673,
              33.673987
            ],
            [
              -94.59045,
              33.677503
            ],
            [
              -94.586641,
              33.678968
            ],
            [
              -94.57962,
              33.677623
            ],
            [
              -94.576974,
              33.673401
            ],
            [
              -94.572872,
              33.669886
            ],
            [
              -94.569943,
              33.66637
            ],
            [
              -94.569357,
              33.663441
            ],
            [
              -94.571993,
              33.659632
            ],
            [
              -94.572286,
              33.656995
            ],
            [
              -94.570821,
              33.654945
            ],
            [
              -94.568771,
              33.654652
            ],
            [
              -94.564669,
              33.655824
            ],
            [
              -94.557052,
              33.656702
            ],
            [
              -94.552072,
              33.65348
            ],
            [
              -94.551193,
              33.650257
            ],
            [
              -94.551312,
              33.64457
            ],
            [
              -94.553537,
              33.642054
            ],
            [
              -94.552658,
              33.638246
            ],
            [
              -94.549142,
              33.635902
            ],
            [
              -94.543869,
              33.635902
            ],
            [
              -94.538889,
              33.637953
            ],
            [
              -94.533322,
              33.63766
            ],
            [
              -94.529221,
              33.634437
            ],
            [
              -94.528342,
              33.62975
            ],
            [
              -94.529807,
              33.627406
            ],
            [
              -94.528928,
              33.62184
            ],
            [
              -94.526291,
              33.619203
            ],
            [
              -94.520725,
              33.616567
            ],
            [
              -94.504615,
              33.620682
            ],
            [
              -94.491503,
              33.625115
            ],
            [
              -94.487514,
              33.628939
            ],
            [
              -94.485875,
              33.637867
            ],
            [
              -94.485577,
              33.65331
            ],
            [
              -94.485528,
              33.663388
            ],
            [
              -94.48452,
              33.687909
            ],
            [
              -94.484616,
              33.691592
            ],
            [
              -94.48384,
              33.711332
            ],
            [
              -94.483874,
              33.716733
            ],
            [
              -94.48287,
              33.750564
            ],
            [
              -94.482862,
              33.75078
            ],
            [
              -94.482777,
              33.753638
            ],
            [
              -94.482682,
              33.756286
            ],
            [
              -94.481842,
              33.789008
            ],
            [
              -94.481543,
              33.795719
            ],
            [
              -94.481361,
              33.802649
            ],
            [
              -94.481355,
              33.802887
            ],
            [
              -94.480574,
              33.830166
            ],
            [
              -94.479954,
              33.85133
            ],
            [
              -94.478994,
              33.881197
            ],
            [
              -94.478842,
              33.881485
            ],
            [
              -94.477387,
              33.937759
            ],
            [
              -94.4773181199803,
              33.9409324150055
            ],
            [
              -94.477038,
              33.953838
            ],
            [
              -94.476957,
              33.957365
            ],
            [
              -94.474895,
              34.019655
            ],
            [
              -94.47489594037029,
              34.0217078283334
            ],
            [
              -94.474896,
              34.021838
            ],
            [
              -94.474896,
              34.021877
            ],
            [
              -94.4702923271421,
              34.189864
            ],
            [
              -94.465847,
              34.352073
            ],
            [
              -94.465425,
              34.359548
            ],
            [
              -94.464176,
              34.402713
            ],
            [
              -94.463816,
              34.414465
            ],
            [
              -94.463671,
              34.419585
            ],
            [
              -94.461149,
              34.507457
            ],
            [
              -94.932702,
              34.506465
            ],
            [
              -94.937605,
              34.506831
            ],
            [
              -95.046746,
              34.507229
            ],
            [
              -95.046585,
              34.243671
            ],
            [
              -95.151233,
              34.243906
            ],
            [
              -95.156521,
              34.156965
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "137",
      "COUNTYNS": "01101856",
      "AFFGEOID": "0500000US40137",
      "GEOID": "40137",
      "NAME": "Stephens",
      "LSAD": "06",
      "ALAND": 2253930695,
      "AWATER": 54690951,
      "County_state": "Stephens,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.141799,
              34.681158
            ],
            [
              -98.142148,
              34.506994
            ],
            [
              -98.139232,
              34.504777
            ],
            [
              -98.139164,
              34.391304
            ],
            [
              -98.139226,
              34.289775
            ],
            [
              -97.982166,
              34.289104
            ],
            [
              -97.562575,
              34.289182
            ],
            [
              -97.562399,
              34.419301
            ],
            [
              -97.562323,
              34.507036
            ],
            [
              -97.563116,
              34.510736
            ],
            [
              -97.56286,
              34.56518
            ],
            [
              -97.562757,
              34.67761
            ],
            [
              -97.566456,
              34.681156
            ],
            [
              -97.668425,
              34.681344
            ],
            [
              -97.887712,
              34.681561
            ],
            [
              -98.037028,
              34.681522
            ],
            [
              -98.072074,
              34.681296
            ],
            [
              -98.089098,
              34.681119
            ],
            [
              -98.141799,
              34.681158
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "101",
      "COUNTYNS": "01101838",
      "AFFGEOID": "0500000US40101",
      "GEOID": "40101",
      "NAME": "Muskogee",
      "LSAD": "06",
      "ALAND": 2099053124,
      "AWATER": 75966890,
      "County_state": "Muskogee,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.766166,
              35.856403
            ],
            [
              -95.766265,
              35.72575
            ],
            [
              -95.713081,
              35.725807
            ],
            [
              -95.712957,
              35.551802
            ],
            [
              -95.661993,
              35.551724
            ],
            [
              -95.624471,
              35.55172
            ],
            [
              -95.571072,
              35.55171
            ],
            [
              -95.34915,
              35.552207
            ],
            [
              -95.345033,
              35.551755
            ],
            [
              -95.344837,
              35.467919
            ],
            [
              -95.34465,
              35.293097
            ],
            [
              -95.311748,
              35.302697
            ],
            [
              -95.288347,
              35.295197
            ],
            [
              -95.26215,
              35.268994
            ],
            [
              -95.240718,
              35.26231
            ],
            [
              -95.222605,
              35.271722
            ],
            [
              -95.205385,
              35.289418
            ],
            [
              -95.19919,
              35.303592
            ],
            [
              -95.176474,
              35.301344
            ],
            [
              -95.170462,
              35.310106
            ],
            [
              -95.182549,
              35.336857
            ],
            [
              -95.174958,
              35.350554
            ],
            [
              -95.150497,
              35.345871
            ],
            [
              -95.141296,
              35.355565
            ],
            [
              -95.16077,
              35.369358
            ],
            [
              -95.139238,
              35.379996
            ],
            [
              -95.123279,
              35.372324
            ],
            [
              -95.101837,
              35.397596
            ],
            [
              -95.086036,
              35.405896
            ],
            [
              -95.079935,
              35.432996
            ],
            [
              -95.068835,
              35.448895
            ],
            [
              -95.049933,
              35.458894
            ],
            [
              -95.084035,
              35.460396
            ],
            [
              -95.097268,
              35.486827
            ],
            [
              -95.120836,
              35.505696
            ],
            [
              -95.132437,
              35.526196
            ],
            [
              -95.127242,
              35.595845
            ],
            [
              -95.127464,
              35.638894
            ],
            [
              -95.127189,
              35.795995
            ],
            [
              -95.127118,
              35.798458
            ],
            [
              -95.127163,
              35.812755
            ],
            [
              -95.265679,
              35.813266
            ],
            [
              -95.287267,
              35.813162
            ],
            [
              -95.324002,
              35.813394
            ],
            [
              -95.362317,
              35.822574
            ],
            [
              -95.420248,
              35.799599
            ],
            [
              -95.477552,
              35.804199
            ],
            [
              -95.518172,
              35.792764
            ],
            [
              -95.556066,
              35.788699
            ],
            [
              -95.563091,
              35.787908
            ],
            [
              -95.579872,
              35.769743
            ],
            [
              -95.590149,
              35.765428
            ],
            [
              -95.60195,
              35.778087
            ],
            [
              -95.621518,
              35.777641
            ],
            [
              -95.638313,
              35.787596
            ],
            [
              -95.640455,
              35.834945
            ],
            [
              -95.65042,
              35.856756
            ],
            [
              -95.766166,
              35.856403
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "115",
      "COUNTYNS": "01101845",
      "AFFGEOID": "0500000US40115",
      "GEOID": "40115",
      "NAME": "Ottawa",
      "LSAD": "06",
      "ALAND": 1219467497,
      "AWATER": 35708902,
      "County_state": "Ottawa,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.034123,
              36.994225
            ],
            [
              -95.030707,
              36.972433
            ],
            [
              -95.022231,
              36.9608
            ],
            [
              -94.993385,
              36.949941
            ],
            [
              -94.999153,
              36.943238
            ],
            [
              -94.999403,
              36.670631
            ],
            [
              -94.840798,
              36.670557
            ],
            [
              -94.782235,
              36.670622
            ],
            [
              -94.77494,
              36.667806
            ],
            [
              -94.61801942428959,
              36.6679212127705
            ],
            [
              -94.618025,
              36.66943
            ],
            [
              -94.61813,
              36.701423
            ],
            [
              -94.618307,
              36.76656
            ],
            [
              -94.61838,
              36.84732
            ],
            [
              -94.618658,
              36.880064
            ],
            [
              -94.618243,
              36.897027
            ],
            [
              -94.618282,
              36.911472
            ],
            [
              -94.6182075266432,
              36.926132328535495
            ],
            [
              -94.618207,
              36.926236
            ],
            [
              -94.618295,
              36.929647
            ],
            [
              -94.618166,
              36.937584
            ],
            [
              -94.618109,
              36.946564
            ],
            [
              -94.618026,
              36.950158
            ],
            [
              -94.618031,
              36.994704
            ],
            [
              -94.618049,
              36.996208
            ],
            [
              -94.617964,
              36.998905
            ],
            [
              -94.625224,
              36.998672
            ],
            [
              -94.699735,
              36.998805
            ],
            [
              -94.701797,
              36.998814
            ],
            [
              -94.71277,
              36.998794
            ],
            [
              -94.737183,
              36.998665
            ],
            [
              -94.739324,
              36.998687
            ],
            [
              -94.777257,
              36.998764
            ],
            [
              -94.83128,
              36.998812
            ],
            [
              -94.840926,
              36.998833
            ],
            [
              -94.849801,
              36.998876
            ],
            [
              -94.853197,
              36.998874
            ],
            [
              -94.995293,
              36.999529
            ],
            [
              -95.00762,
              36.999514
            ],
            [
              -95.034123,
              36.994225
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "041",
      "COUNTYNS": "01101808",
      "AFFGEOID": "0500000US40041",
      "GEOID": "40041",
      "NAME": "Delaware",
      "LSAD": "06",
      "ALAND": 1911798952,
      "AWATER": 140253641,
      "County_state": "Delaware,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.011331,
              36.219609
            ],
            [
              -95.011303,
              36.161815
            ],
            [
              -94.797279,
              36.16139
            ],
            [
              -94.5628276914512,
              36.1618934952038
            ],
            [
              -94.565655,
              36.178439
            ],
            [
              -94.566588,
              36.183774
            ],
            [
              -94.571253,
              36.210901
            ],
            [
              -94.57126713660419,
              36.21097377922649
            ],
            [
              -94.571806,
              36.213748
            ],
            [
              -94.574395,
              36.229996
            ],
            [
              -94.57488,
              36.232741
            ],
            [
              -94.575071,
              36.233682
            ],
            [
              -94.576003,
              36.24007
            ],
            [
              -94.577899,
              36.249548
            ],
            [
              -94.577883,
              36.25008
            ],
            [
              -94.58473089720769,
              36.2911566795471
            ],
            [
              -94.5862,
              36.299969
            ],
            [
              -94.5913180588467,
              36.3325199111557
            ],
            [
              -94.593397,
              36.345742
            ],
            [
              -94.599723,
              36.387587
            ],
            [
              -94.601984,
              36.40212
            ],
            [
              -94.602623,
              36.405283
            ],
            [
              -94.605408,
              36.421949
            ],
            [
              -94.611609,
              36.461528
            ],
            [
              -94.61383,
              36.476248
            ],
            [
              -94.615311,
              36.484992
            ],
            [
              -94.617919,
              36.499414
            ],
            [
              -94.617877,
              36.514999
            ],
            [
              -94.617883,
              36.517799
            ],
            [
              -94.617997,
              36.53428
            ],
            [
              -94.617868,
              36.53609
            ],
            [
              -94.617897,
              36.536983
            ],
            [
              -94.617832380978,
              36.5687078256061
            ],
            [
              -94.617814,
              36.577732
            ],
            [
              -94.617853,
              36.59912
            ],
            [
              -94.617865,
              36.606851
            ],
            [
              -94.617815,
              36.612604
            ],
            [
              -94.61801942428959,
              36.6679212127705
            ],
            [
              -94.77494,
              36.667806
            ],
            [
              -94.782235,
              36.670622
            ],
            [
              -94.840798,
              36.670557
            ],
            [
              -94.999403,
              36.670631
            ],
            [
              -95.00569,
              36.59766
            ],
            [
              -95.00573,
              36.50989
            ],
            [
              -95.005895,
              36.278296
            ],
            [
              -95.005933,
              36.249549
            ],
            [
              -95.011238,
              36.234369
            ],
            [
              -95.011331,
              36.219609
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "057",
      "COUNTYNS": "01101816",
      "AFFGEOID": "0500000US40057",
      "GEOID": "40057",
      "NAME": "Harmon",
      "LSAD": "06",
      "ALAND": 1391326397,
      "AWATER": 3800411,
      "County_state": "Harmon,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.00038360336801,
              35.0303849961589
            ],
            [
              -100.000381,
              34.746461
            ],
            [
              -100.000381,
              34.746358
            ],
            [
              -100.000381,
              34.560509
            ],
            [
              -99.9975014109467,
              34.5604238870434
            ],
            [
              -99.985833,
              34.560079
            ],
            [
              -99.974762,
              34.561318
            ],
            [
              -99.971555,
              34.562179
            ],
            [
              -99.965608,
              34.565844
            ],
            [
              -99.958898,
              34.571271
            ],
            [
              -99.957541,
              34.572709
            ],
            [
              -99.957553,
              34.574169
            ],
            [
              -99.956717,
              34.576524
            ],
            [
              -99.954567,
              34.578195
            ],
            [
              -99.94572,
              34.579273
            ],
            [
              -99.929334,
              34.576714
            ],
            [
              -99.923211,
              34.574552
            ],
            [
              -99.921801,
              34.570253
            ],
            [
              -99.915771,
              34.565975
            ],
            [
              -99.898943,
              34.555804
            ],
            [
              -99.896007,
              34.55553
            ],
            [
              -99.89376,
              34.554219
            ],
            [
              -99.887147,
              34.549047
            ],
            [
              -99.874403,
              34.537095
            ],
            [
              -99.873254,
              34.535351
            ],
            [
              -99.872357,
              34.532096
            ],
            [
              -99.868953,
              34.527615
            ],
            [
              -99.853066,
              34.511593
            ],
            [
              -99.8446582036581,
              34.5067869364725
            ],
            [
              -99.666512,
              34.507121
            ],
            [
              -99.666849,
              34.724555
            ],
            [
              -99.667145,
              34.753742
            ],
            [
              -99.684712,
              34.753744
            ],
            [
              -99.684839,
              34.768378
            ],
            [
              -99.720066,
              34.76845
            ],
            [
              -99.72,
              34.857033
            ],
            [
              -99.723217,
              34.85698
            ],
            [
              -99.731092,
              34.944058
            ],
            [
              -99.888425,
              34.943946
            ],
            [
              -99.888571,
              35.030262
            ],
            [
              -100.00038360336801,
              35.0303849961589
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "097",
      "COUNTYNS": "01101833",
      "AFFGEOID": "0500000US40097",
      "GEOID": "40097",
      "NAME": "Mayes",
      "LSAD": "06",
      "ALAND": 1697432145,
      "AWATER": 72855332,
      "County_state": "Mayes,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.439972,
              36.248411
            ],
            [
              -95.43998,
              36.19142
            ],
            [
              -95.43997,
              36.0752
            ],
            [
              -95.364483,
              36.075289
            ],
            [
              -95.207946,
              36.074708
            ],
            [
              -95.134155,
              36.074568
            ],
            [
              -95.118377,
              36.074544
            ],
            [
              -95.1185,
              36.16163
            ],
            [
              -95.011303,
              36.161815
            ],
            [
              -95.011331,
              36.219609
            ],
            [
              -95.011238,
              36.234369
            ],
            [
              -95.005933,
              36.249549
            ],
            [
              -95.005895,
              36.278296
            ],
            [
              -95.00573,
              36.50989
            ],
            [
              -95.184816,
              36.510308
            ],
            [
              -95.32817,
              36.51024
            ],
            [
              -95.43561,
              36.51016
            ],
            [
              -95.43558,
              36.42319
            ],
            [
              -95.435454,
              36.352029
            ],
            [
              -95.435427,
              36.346498
            ],
            [
              -95.435518,
              36.24932
            ],
            [
              -95.439972,
              36.248411
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "135",
      "COUNTYNS": "01101855",
      "AFFGEOID": "0500000US40135",
      "GEOID": "40135",
      "NAME": "Sequoyah",
      "LSAD": "06",
      "ALAND": 1743779422,
      "AWATER": 105832791,
      "County_state": "Sequoyah,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.127242,
              35.595845
            ],
            [
              -95.132437,
              35.526196
            ],
            [
              -95.120836,
              35.505696
            ],
            [
              -95.097268,
              35.486827
            ],
            [
              -95.084035,
              35.460396
            ],
            [
              -95.049933,
              35.458894
            ],
            [
              -95.024933,
              35.453095
            ],
            [
              -95.024233,
              35.431395
            ],
            [
              -95.004348,
              35.413471
            ],
            [
              -94.951328,
              35.408334
            ],
            [
              -94.944608,
              35.402457
            ],
            [
              -94.925745,
              35.392845
            ],
            [
              -94.901832,
              35.39794
            ],
            [
              -94.8923,
              35.384345
            ],
            [
              -94.894739,
              35.373088
            ],
            [
              -94.915314,
              35.35865
            ],
            [
              -94.916629,
              35.348731
            ],
            [
              -94.90198,
              35.346709
            ],
            [
              -94.865894,
              35.356599
            ],
            [
              -94.845184,
              35.370785
            ],
            [
              -94.828972,
              35.361546
            ],
            [
              -94.82746,
              35.337412
            ],
            [
              -94.813629,
              35.323344
            ],
            [
              -94.794374,
              35.327086
            ],
            [
              -94.778444,
              35.350895
            ],
            [
              -94.756413,
              35.356807
            ],
            [
              -94.725697,
              35.349702
            ],
            [
              -94.69086,
              35.329869
            ],
            [
              -94.678889,
              35.318409
            ],
            [
              -94.678342,
              35.3048
            ],
            [
              -94.668796,
              35.295029
            ],
            [
              -94.647967,
              35.29123
            ],
            [
              -94.615879,
              35.304111
            ],
            [
              -94.594801,
              35.327974
            ],
            [
              -94.569148,
              35.325641
            ],
            [
              -94.55463,
              35.310219
            ],
            [
              -94.533528,
              35.304331
            ],
            [
              -94.516956,
              35.309517
            ],
            [
              -94.493889,
              35.301223
            ],
            [
              -94.481147,
              35.309507
            ],
            [
              -94.494797,
              35.329809
            ],
            [
              -94.494771,
              35.342566
            ],
            [
              -94.480521,
              35.352291
            ],
            [
              -94.486688,
              35.36488
            ],
            [
              -94.472302,
              35.375981
            ],
            [
              -94.446008,
              35.380101
            ],
            [
              -94.4337424273008,
              35.3864671046955
            ],
            [
              -94.433915,
              35.387391
            ],
            [
              -94.4321813745028,
              35.3918207341872
            ],
            [
              -94.4313807766676,
              35.39386640991479
            ],
            [
              -94.431215,
              35.39429
            ],
            [
              -94.4373214645191,
              35.4281344377591
            ],
            [
              -94.4496195316777,
              35.496295182361095
            ],
            [
              -94.449696,
              35.496719
            ],
            [
              -94.4566165247859,
              35.5403804902825
            ],
            [
              -94.463318,
              35.58266
            ],
            [
              -94.464097,
              35.587265
            ],
            [
              -94.464457,
              35.588909
            ],
            [
              -94.465272,
              35.594037
            ],
            [
              -94.472647,
              35.638556
            ],
            [
              -94.807297,
              35.638604
            ],
            [
              -94.917316,
              35.63862
            ],
            [
              -95.012741,
              35.638397
            ],
            [
              -95.127464,
              35.638894
            ],
            [
              -95.127242,
              35.595845
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "017",
      "COUNTYNS": "01101796",
      "AFFGEOID": "0500000US40017",
      "GEOID": "40017",
      "NAME": "Canadian",
      "LSAD": "06",
      "ALAND": 2322265669,
      "AWATER": 23229300,
      "County_state": "Canadian,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.31337,
              35.725204
            ],
            [
              -98.313196,
              35.710716
            ],
            [
              -98.313251,
              35.551523
            ],
            [
              -98.308297,
              35.551312
            ],
            [
              -98.306288,
              35.516333
            ],
            [
              -98.305837,
              35.377173
            ],
            [
              -98.300783,
              35.377245
            ],
            [
              -98.096014,
              35.377753
            ],
            [
              -98.017453,
              35.377911
            ],
            [
              -98.006399,
              35.360381
            ],
            [
              -97.99971,
              35.362776
            ],
            [
              -97.955124,
              35.346048
            ],
            [
              -97.946843,
              35.356412
            ],
            [
              -97.947114,
              35.374917
            ],
            [
              -97.932252,
              35.373383
            ],
            [
              -97.920835,
              35.353518
            ],
            [
              -97.901566,
              35.353442
            ],
            [
              -97.903086,
              35.345404
            ],
            [
              -97.884098,
              35.349012
            ],
            [
              -97.862805,
              35.36183
            ],
            [
              -97.85682,
              35.344909
            ],
            [
              -97.833675,
              35.335362
            ],
            [
              -97.828705,
              35.334359
            ],
            [
              -97.807186,
              35.340846
            ],
            [
              -97.792022,
              35.332702
            ],
            [
              -97.769273,
              35.34565
            ],
            [
              -97.761499,
              35.340347
            ],
            [
              -97.737305,
              35.323762
            ],
            [
              -97.706159,
              35.324989
            ],
            [
              -97.671595,
              35.335934
            ],
            [
              -97.671529,
              35.337508
            ],
            [
              -97.671369,
              35.377151
            ],
            [
              -97.671383,
              35.406352
            ],
            [
              -97.671411,
              35.435471
            ],
            [
              -97.671419,
              35.460204
            ],
            [
              -97.671321,
              35.469214
            ],
            [
              -97.671363,
              35.471353
            ],
            [
              -97.671449,
              35.486074
            ],
            [
              -97.67122,
              35.507456
            ],
            [
              -97.67122,
              35.514919
            ],
            [
              -97.67122,
              35.530103
            ],
            [
              -97.67122,
              35.537199
            ],
            [
              -97.674035,
              35.551183
            ],
            [
              -97.674036,
              35.594822
            ],
            [
              -97.673999,
              35.623962
            ],
            [
              -97.674026,
              35.72597
            ],
            [
              -97.780558,
              35.725722
            ],
            [
              -97.958986,
              35.726066
            ],
            [
              -97.994771,
              35.7255
            ],
            [
              -98.065908,
              35.7254
            ],
            [
              -98.207104,
              35.72514
            ],
            [
              -98.31337,
              35.725204
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "005",
      "COUNTYNS": "01101790",
      "AFFGEOID": "0500000US40005",
      "GEOID": "40005",
      "NAME": "Atoka",
      "LSAD": "06",
      "ALAND": 2526582870,
      "AWATER": 37283827,
      "County_state": "Atoka,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.40733,
              34.418391
            ],
            [
              -96.40764,
              34.157317
            ],
            [
              -96.248718,
              34.15723
            ],
            [
              -96.102543,
              34.157108
            ],
            [
              -95.991578,
              34.156805
            ],
            [
              -95.919013,
              34.156625
            ],
            [
              -95.779376,
              34.156699
            ],
            [
              -95.779002,
              34.500097
            ],
            [
              -95.779425,
              34.506556
            ],
            [
              -95.6717,
              34.506799
            ],
            [
              -95.671976,
              34.593852
            ],
            [
              -95.881987,
              34.593528
            ],
            [
              -95.882103,
              34.680475
            ],
            [
              -96.092043,
              34.680556
            ],
            [
              -96.09199,
              34.514019
            ],
            [
              -96.092624,
              34.506163
            ],
            [
              -96.145342,
              34.506102
            ],
            [
              -96.145096,
              34.418677
            ],
            [
              -96.40733,
              34.418391
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "121",
      "COUNTYNS": "01101848",
      "AFFGEOID": "0500000US40121",
      "GEOID": "40121",
      "NAME": "Pittsburg",
      "LSAD": "06",
      "ALAND": 3381112561,
      "AWATER": 187439435,
      "County_state": "Pittsburg,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.092001,
              34.767479
            ],
            [
              -96.092043,
              34.680556
            ],
            [
              -95.882103,
              34.680475
            ],
            [
              -95.881987,
              34.593528
            ],
            [
              -95.671976,
              34.593852
            ],
            [
              -95.514315,
              34.594153
            ],
            [
              -95.514514,
              34.681142
            ],
            [
              -95.507523,
              34.854937
            ],
            [
              -95.507658,
              35.029197
            ],
            [
              -95.349437,
              35.029299
            ],
            [
              -95.349233,
              35.058514
            ],
            [
              -95.349265,
              35.126051
            ],
            [
              -95.349339,
              35.160296
            ],
            [
              -95.393676,
              35.160195
            ],
            [
              -95.454954,
              35.160577
            ],
            [
              -95.450756,
              35.202797
            ],
            [
              -95.45125,
              35.296019
            ],
            [
              -95.500366,
              35.300892
            ],
            [
              -95.519051,
              35.279497
            ],
            [
              -95.512959,
              35.266097
            ],
            [
              -95.572662,
              35.244298
            ],
            [
              -95.596201,
              35.223438
            ],
            [
              -95.608136,
              35.220404
            ],
            [
              -95.622464,
              35.224698
            ],
            [
              -95.606763,
              35.235098
            ],
            [
              -95.609163,
              35.249298
            ],
            [
              -95.628064,
              35.246798
            ],
            [
              -95.672066,
              35.227298
            ],
            [
              -95.682767,
              35.216398
            ],
            [
              -95.674436,
              35.208467
            ],
            [
              -95.707153,
              35.202301
            ],
            [
              -95.716157,
              35.189352
            ],
            [
              -95.742286,
              35.192509
            ],
            [
              -95.75597,
              35.177198
            ],
            [
              -95.768471,
              35.178998
            ],
            [
              -95.793271,
              35.197298
            ],
            [
              -95.839673,
              35.184397
            ],
            [
              -95.870663,
              35.185986
            ],
            [
              -95.888374,
              35.155696
            ],
            [
              -95.907966,
              35.143494
            ],
            [
              -95.928458,
              35.143594
            ],
            [
              -95.962477,
              35.152396
            ],
            [
              -95.98371,
              35.151557
            ],
            [
              -96.013639,
              35.141787
            ],
            [
              -96.010362,
              35.117438
            ],
            [
              -96.029969,
              35.120336
            ],
            [
              -96.039212,
              35.107645
            ],
            [
              -96.062707,
              35.08807
            ],
            [
              -96.069239,
              35.056585
            ],
            [
              -96.088861,
              35.049758
            ],
            [
              -96.091877,
              34.854536
            ],
            [
              -96.092001,
              34.767479
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "141",
      "COUNTYNS": "01101858",
      "AFFGEOID": "0500000US40141",
      "GEOID": "40141",
      "NAME": "Tillman",
      "LSAD": "06",
      "ALAND": 2256226760,
      "AWATER": 20894360,
      "County_state": "Tillman,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.23806,
              34.425009
            ],
            [
              -99.235143,
              34.401072
            ],
            [
              -99.226631,
              34.401281
            ],
            [
              -99.228255,
              34.387194
            ],
            [
              -99.213862,
              34.394734
            ],
            [
              -99.209162,
              34.388619
            ],
            [
              -99.21974,
              34.374304
            ],
            [
              -99.207639,
              34.374238
            ],
            [
              -99.192747,
              34.354861
            ],
            [
              -99.198281,
              34.339622
            ],
            [
              -99.210716,
              34.336304
            ],
            [
              -99.209724,
              34.324935
            ],
            [
              -99.2116,
              34.31397
            ],
            [
              -99.213476,
              34.310672
            ],
            [
              -99.211648,
              34.292232
            ],
            [
              -99.209742,
              34.286345
            ],
            [
              -99.207561,
              34.283505
            ],
            [
              -99.203681,
              34.281926
            ],
            [
              -99.200222,
              34.281152
            ],
            [
              -99.19626,
              34.281463
            ],
            [
              -99.195605,
              34.280839
            ],
            [
              -99.19457,
              34.272424
            ],
            [
              -99.196926,
              34.260929
            ],
            [
              -99.197153,
              34.244298
            ],
            [
              -99.195553,
              34.24006
            ],
            [
              -99.191139,
              34.23234
            ],
            [
              -99.190146,
              34.22966
            ],
            [
              -99.190036,
              34.227186
            ],
            [
              -99.192076,
              34.222192
            ],
            [
              -99.192683,
              34.218825
            ],
            [
              -99.192104,
              34.216694
            ],
            [
              -99.189511,
              34.214312
            ],
            [
              -99.159016,
              34.20888
            ],
            [
              -99.143985,
              34.214763
            ],
            [
              -99.13822,
              34.219159
            ],
            [
              -99.130609,
              34.219408
            ],
            [
              -99.128514,
              34.218766
            ],
            [
              -99.127549,
              34.217986
            ],
            [
              -99.126614,
              34.215329
            ],
            [
              -99.127525,
              34.213771
            ],
            [
              -99.13009,
              34.212192
            ],
            [
              -99.131553,
              34.209352
            ],
            [
              -99.131885,
              34.207382
            ],
            [
              -99.129792,
              34.204403
            ],
            [
              -99.126567,
              34.203004
            ],
            [
              -99.119204,
              34.201747
            ],
            [
              -99.108758,
              34.203401
            ],
            [
              -99.092191,
              34.209316
            ],
            [
              -99.079535,
              34.211518
            ],
            [
              -99.075978,
              34.211221
            ],
            [
              -99.066465,
              34.208404
            ],
            [
              -99.060344,
              34.204761
            ],
            [
              -99.059159,
              34.202929
            ],
            [
              -99.0588,
              34.201256
            ],
            [
              -99.058084,
              34.200569
            ],
            [
              -99.048792,
              34.198209
            ],
            [
              -99.043471,
              34.198208
            ],
            [
              -99.040962,
              34.200842
            ],
            [
              -99.039004,
              34.204667
            ],
            [
              -99.037459,
              34.206454
            ],
            [
              -99.036273,
              34.206912
            ],
            [
              -99.013075,
              34.203222
            ],
            [
              -99.00579,
              34.206647
            ],
            [
              -99.002916,
              34.208782
            ],
            [
              -99.003433,
              34.214466
            ],
            [
              -99.000761,
              34.217643
            ],
            [
              -98.990852,
              34.221633
            ],
            [
              -98.987294,
              34.221223
            ],
            [
              -98.981364,
              34.217583
            ],
            [
              -98.978685,
              34.210231
            ],
            [
              -98.976587,
              34.206291
            ],
            [
              -98.974132,
              34.203566
            ],
            [
              -98.969003,
              34.201299
            ],
            [
              -98.966302,
              34.201323
            ],
            [
              -98.96247,
              34.204668
            ],
            [
              -98.962085,
              34.206386
            ],
            [
              -98.962307,
              34.211312
            ],
            [
              -98.960791,
              34.21303
            ],
            [
              -98.958475,
              34.213855
            ],
            [
              -98.952513,
              34.21265
            ],
            [
              -98.9523575349127,
              34.2125787665873
            ],
            [
              -98.950396,
              34.21168
            ],
            [
              -98.94022,
              34.203686
            ],
            [
              -98.928145,
              34.192689
            ],
            [
              -98.927456,
              34.191155
            ],
            [
              -98.923129,
              34.185978
            ],
            [
              -98.920704,
              34.183435
            ],
            [
              -98.918333,
              34.181831
            ],
            [
              -98.909349,
              34.177499
            ],
            [
              -98.872922,
              34.166584
            ],
            [
              -98.871543,
              34.165027
            ],
            [
              -98.871211,
              34.163012
            ],
            [
              -98.872229,
              34.160446
            ],
            [
              -98.874955,
              34.157031
            ],
            [
              -98.874872,
              34.155657
            ],
            [
              -98.873271,
              34.153596
            ],
            [
              -98.868116,
              34.149635
            ],
            [
              -98.86255,
              34.149111
            ],
            [
              -98.860125,
              34.149913
            ],
            [
              -98.858419,
              34.152732
            ],
            [
              -98.8579,
              34.159627
            ],
            [
              -98.857322,
              34.161094
            ],
            [
              -98.855585,
              34.161621
            ],
            [
              -98.831115,
              34.162154
            ],
            [
              -98.812954,
              34.158444
            ],
            [
              -98.80681,
              34.155901
            ],
            [
              -98.792015,
              34.143736
            ],
            [
              -98.76557,
              34.136376
            ],
            [
              -98.761797,
              34.133785
            ],
            [
              -98.760558,
              34.132388
            ],
            [
              -98.759486,
              34.128882
            ],
            [
              -98.759653,
              34.126912
            ],
            [
              -98.757037,
              34.124633
            ],
            [
              -98.749291,
              34.124238
            ],
            [
              -98.741966,
              34.12553
            ],
            [
              -98.739461,
              34.127394
            ],
            [
              -98.737232,
              34.130992
            ],
            [
              -98.73682,
              34.133374
            ],
            [
              -98.735471,
              34.135208
            ],
            [
              -98.734287,
              34.135758
            ],
            [
              -98.717537,
              34.13645
            ],
            [
              -98.716104,
              34.135947
            ],
            [
              -98.696518,
              34.133521
            ],
            [
              -98.690072,
              34.133155
            ],
            [
              -98.655655,
              34.158258
            ],
            [
              -98.650583,
              34.163113
            ],
            [
              -98.648073,
              34.164441
            ],
            [
              -98.643223,
              34.164531
            ],
            [
              -98.63573,
              34.161618
            ],
            [
              -98.621666,
              34.157195
            ],
            [
              -98.616733,
              34.156418
            ],
            [
              -98.611829,
              34.156558
            ],
            [
              -98.6101579900004,
              34.157098719969596
            ],
            [
              -98.60966,
              34.33372
            ],
            [
              -98.662004,
              34.333431
            ],
            [
              -98.661956,
              34.405948
            ],
            [
              -98.661982,
              34.50746
            ],
            [
              -98.685529,
              34.507468
            ],
            [
              -98.82607,
              34.50737
            ],
            [
              -98.826008,
              34.594413
            ],
            [
              -99.001107,
              34.594366
            ],
            [
              -99.001025,
              34.637872
            ],
            [
              -99.10315,
              34.63795
            ],
            [
              -99.079258,
              34.618983
            ],
            [
              -99.088457,
              34.615248
            ],
            [
              -99.082881,
              34.590854
            ],
            [
              -99.099668,
              34.594126
            ],
            [
              -99.121796,
              34.587887
            ],
            [
              -99.132213,
              34.59208
            ],
            [
              -99.16332,
              34.588036
            ],
            [
              -99.18451,
              34.562315
            ],
            [
              -99.177978,
              34.540097
            ],
            [
              -99.196801,
              34.542386
            ],
            [
              -99.210106,
              34.501658
            ],
            [
              -99.220707,
              34.494484
            ],
            [
              -99.221158,
              34.464184
            ],
            [
              -99.213383,
              34.456169
            ],
            [
              -99.228038,
              34.451836
            ],
            [
              -99.222969,
              34.432847
            ],
            [
              -99.228022,
              34.419561
            ],
            [
              -99.23806,
              34.425009
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "013",
      "COUNTYNS": "01101794",
      "AFFGEOID": "0500000US40013",
      "GEOID": "40013",
      "NAME": "Bryan",
      "LSAD": "06",
      "ALAND": 2342528103,
      "AWATER": 102267324,
      "County_state": "Bryan,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.629747,
              33.850866
            ],
            [
              -96.630022,
              33.847541
            ],
            [
              -96.62929,
              33.845488
            ],
            [
              -96.623155,
              33.841483
            ],
            [
              -96.601258,
              33.834327
            ],
            [
              -96.592926,
              33.830916
            ],
            [
              -96.587067,
              33.828009
            ],
            [
              -96.572937,
              33.819098
            ],
            [
              -96.566298,
              33.818511
            ],
            [
              -96.551223,
              33.819129
            ],
            [
              -96.532865,
              33.823005
            ],
            [
              -96.529234,
              33.822127
            ],
            [
              -96.526655,
              33.820891
            ],
            [
              -96.523863,
              33.818114
            ],
            [
              -96.519911,
              33.811347
            ],
            [
              -96.516584,
              33.803168
            ],
            [
              -96.515959,
              33.798934
            ],
            [
              -96.515912,
              33.787795
            ],
            [
              -96.511914,
              33.781478
            ],
            [
              -96.502286,
              33.77346
            ],
            [
              -96.500268,
              33.772583
            ],
            [
              -96.48606,
              33.77301
            ],
            [
              -96.459154,
              33.775232
            ],
            [
              -96.456254,
              33.776035
            ],
            [
              -96.45051,
              33.780588
            ],
            [
              -96.448045,
              33.781031
            ],
            [
              -96.436455,
              33.78005
            ],
            [
              -96.430214,
              33.778654
            ],
            [
              -96.422643,
              33.776041
            ],
            [
              -96.419961,
              33.773034
            ],
            [
              -96.417562,
              33.769038
            ],
            [
              -96.416146,
              33.766099
            ],
            [
              -96.413408,
              33.757714
            ],
            [
              -96.408469,
              33.751192
            ],
            [
              -96.403507,
              33.746289
            ],
            [
              -96.384116,
              33.730141
            ],
            [
              -96.37940399227689,
              33.7258163072446
            ],
            [
              -96.36959,
              33.716809
            ],
            [
              -96.366945,
              33.711222
            ],
            [
              -96.363253,
              33.70105
            ],
            [
              -96.363135,
              33.694215
            ],
            [
              -96.362198,
              33.691818
            ],
            [
              -96.355946,
              33.687155
            ],
            [
              -96.348306,
              33.686379
            ],
            [
              -96.342665,
              33.686975
            ],
            [
              -96.321103,
              33.6951
            ],
            [
              -96.31876,
              33.696753
            ],
            [
              -96.316925,
              33.698997
            ],
            [
              -96.309964,
              33.710489
            ],
            [
              -96.307035,
              33.719987
            ],
            [
              -96.306596,
              33.726786
            ],
            [
              -96.307389,
              33.735005
            ],
            [
              -96.3061,
              33.741002
            ],
            [
              -96.303009,
              33.750878
            ],
            [
              -96.301706,
              33.753756
            ],
            [
              -96.294867,
              33.764771
            ],
            [
              -96.292482,
              33.766419
            ],
            [
              -96.277269,
              33.769735
            ],
            [
              -96.269896,
              33.768405
            ],
            [
              -96.248232,
              33.758986
            ],
            [
              -96.229023,
              33.748021
            ],
            [
              -96.220521,
              33.74739
            ],
            [
              -96.1999,
              33.752117
            ],
            [
              -96.1963803242589,
              33.7532399416533
            ],
            [
              -96.186554,
              33.756375
            ],
            [
              -96.178059,
              33.760518
            ],
            [
              -96.174633,
              33.763699
            ],
            [
              -96.169452,
              33.770131
            ],
            [
              -96.162757,
              33.788769
            ],
            [
              -96.162123,
              33.79614
            ],
            [
              -96.166837,
              33.797908
            ],
            [
              -96.170373,
              33.799382
            ],
            [
              -96.173025,
              33.80056
            ],
            [
              -96.17515,
              33.801951
            ],
            [
              -96.17734,
              33.805117
            ],
            [
              -96.178964,
              33.810553
            ],
            [
              -96.17691,
              33.813934
            ],
            [
              -96.17589,
              33.814627
            ],
            [
              -96.164217,
              33.817001
            ],
            [
              -96.150765,
              33.816987
            ],
            [
              -96.148792,
              33.819197
            ],
            [
              -96.15163,
              33.831946
            ],
            [
              -96.150147,
              33.835856
            ],
            [
              -96.14807,
              33.837799
            ],
            [
              -96.138905,
              33.839159
            ],
            [
              -96.122951,
              33.839964
            ],
            [
              -96.118169,
              33.837884
            ],
            [
              -96.109993,
              33.832396
            ],
            [
              -96.104075,
              33.83073
            ],
            [
              -96.09936,
              33.83047
            ],
            [
              -96.097448,
              33.832725
            ],
            [
              -96.097638,
              33.837935
            ],
            [
              -96.099153,
              33.842409
            ],
            [
              -96.100785,
              33.84423
            ],
            [
              -96.101349,
              33.845721
            ],
            [
              -96.101473,
              33.846709
            ],
            [
              -96.100095,
              33.847971
            ],
            [
              -96.084626,
              33.846656
            ],
            [
              -96.063924,
              33.841523
            ],
            [
              -96.055358,
              33.838262
            ],
            [
              -96.048834,
              33.836468
            ],
            [
              -96.037191,
              33.841245
            ],
            [
              -96.031271,
              33.850758
            ],
            [
              -96.029463,
              33.852402
            ],
            [
              -96.025188,
              33.852073
            ],
            [
              -96.022229,
              33.850923
            ],
            [
              -96.0219,
              33.849114
            ],
            [
              -96.022507,
              33.84613
            ],
            [
              -96.022065,
              33.843196
            ],
            [
              -96.021407,
              33.841881
            ],
            [
              -96.019599,
              33.840566
            ],
            [
              -96.005296,
              33.845505
            ],
            [
              -95.998351,
              33.851049
            ],
            [
              -95.997709,
              33.852182
            ],
            [
              -95.997405,
              33.855526
            ],
            [
              -95.997734,
              33.860951
            ],
            [
              -95.996748,
              33.864403
            ],
            [
              -95.993624,
              33.866211
            ],
            [
              -95.991487,
              33.866869
            ],
            [
              -95.988857,
              33.866869
            ],
            [
              -95.984254,
              33.864403
            ],
            [
              -95.980966,
              33.859307
            ],
            [
              -95.972156,
              33.856371
            ],
            [
              -95.951609,
              33.857017
            ],
            [
              -95.944284,
              33.859811
            ],
            [
              -95.941267,
              33.861619
            ],
            [
              -95.936631,
              33.870615
            ],
            [
              -95.935325,
              33.875099
            ],
            [
              -95.935308,
              33.878724
            ],
            [
              -95.935637,
              33.880371
            ],
            [
              -95.936817,
              33.882386
            ],
            [
              -95.937202,
              33.884652
            ],
            [
              -95.936132,
              33.886826
            ],
            [
              -95.935198,
              33.887101
            ],
            [
              -95.922712,
              33.883758
            ],
            [
              -95.915961,
              33.881148
            ],
            [
              -95.905343,
              33.875629
            ],
            [
              -95.893306,
              33.868161
            ],
            [
              -95.887491,
              33.863856
            ],
            [
              -95.881292,
              33.860627
            ],
            [
              -95.859469,
              33.852456
            ],
            [
              -95.849864,
              33.844952
            ],
            [
              -95.8456275554846,
              33.8407767617097
            ],
            [
              -95.843773,
              33.838949
            ],
            [
              -95.840012,
              33.836486
            ],
            [
              -95.837516,
              33.83564
            ],
            [
              -95.831948,
              33.835161
            ],
            [
              -95.828245,
              33.836054
            ],
            [
              -95.822787,
              33.838756
            ],
            [
              -95.820784,
              33.840564
            ],
            [
              -95.819358,
              33.842785
            ],
            [
              -95.818976,
              33.844456
            ],
            [
              -95.819525,
              33.848439
            ],
            [
              -95.820677,
              33.850751
            ],
            [
              -95.821666,
              33.855443
            ],
            [
              -95.821666,
              33.856633
            ],
            [
              -95.820596,
              33.858465
            ],
            [
              -95.805149,
              33.861304
            ],
            [
              -95.800842,
              33.861212
            ],
            [
              -95.789867,
              33.857686
            ],
            [
              -95.787891,
              33.856336
            ],
            [
              -95.776255,
              33.845145
            ],
            [
              -95.773282,
              33.843834
            ],
            [
              -95.772067,
              33.843817
            ],
            [
              -95.763622,
              33.847954
            ],
            [
              -95.758016,
              33.85008
            ],
            [
              -95.75431,
              33.853992
            ],
            [
              -95.753513,
              33.856464
            ],
            [
              -95.757458,
              33.867957
            ],
            [
              -95.760805,
              33.870911
            ],
            [
              -95.76183973777901,
              33.8729497991814
            ],
            [
              -95.805095,
              33.870116
            ],
            [
              -95.840297,
              33.87417
            ],
            [
              -95.861069,
              33.887357
            ],
            [
              -95.868083,
              33.905932
            ],
            [
              -95.878595,
              33.915762
            ],
            [
              -95.918871,
              33.925057
            ],
            [
              -95.935311,
              33.941405
            ],
            [
              -95.937947,
              33.948163
            ],
            [
              -95.93815,
              34.1251
            ],
            [
              -95.949783,
              34.129157
            ],
            [
              -95.953447,
              34.148336
            ],
            [
              -95.991578,
              34.156805
            ],
            [
              -96.102543,
              34.157108
            ],
            [
              -96.248718,
              34.15723
            ],
            [
              -96.40764,
              34.157317
            ],
            [
              -96.453901,
              34.157348
            ],
            [
              -96.453839,
              34.114406
            ],
            [
              -96.585355,
              34.114459
            ],
            [
              -96.565716,
              34.089691
            ],
            [
              -96.560344,
              34.073436
            ],
            [
              -96.547515,
              34.064346
            ],
            [
              -96.574494,
              34.037971
            ],
            [
              -96.573649,
              34.020985
            ],
            [
              -96.582949,
              34.006875
            ],
            [
              -96.593423,
              34.016728
            ],
            [
              -96.604294,
              34.007869
            ],
            [
              -96.624942,
              34.008852
            ],
            [
              -96.615638,
              33.990729
            ],
            [
              -96.594332,
              33.971655
            ],
            [
              -96.585296,
              33.974541
            ],
            [
              -96.57701,
              33.964406
            ],
            [
              -96.595823,
              33.944051
            ],
            [
              -96.58253,
              33.908299
            ],
            [
              -96.5885189157485,
              33.8948810582175
            ],
            [
              -96.587934,
              33.894784
            ],
            [
              -96.585452,
              33.891281
            ],
            [
              -96.58536,
              33.888948
            ],
            [
              -96.587494,
              33.884251
            ],
            [
              -96.590112,
              33.880665
            ],
            [
              -96.597348,
              33.875101
            ],
            [
              -96.601686,
              33.872823
            ],
            [
              -96.61197,
              33.869016
            ],
            [
              -96.625399,
              33.856542
            ],
            [
              -96.628969,
              33.852407
            ],
            [
              -96.629747,
              33.850866
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "079",
      "COUNTYNS": "01101827",
      "AFFGEOID": "0500000US40079",
      "GEOID": "40079",
      "NAME": "Le Flore",
      "LSAD": "06",
      "ALAND": 4116222830,
      "AWATER": 50024113,
      "County_state": "Le Flore,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.059946,
              34.738926
            ],
            [
              -95.059616,
              34.680736
            ],
            [
              -94.937752,
              34.680877
            ],
            [
              -94.937605,
              34.506831
            ],
            [
              -94.932702,
              34.506465
            ],
            [
              -94.461149,
              34.507457
            ],
            [
              -94.460058,
              34.545264
            ],
            [
              -94.460052,
              34.547869
            ],
            [
              -94.4575,
              34.634945
            ],
            [
              -94.45753,
              34.642961
            ],
            [
              -94.45579465995209,
              34.6934853886321
            ],
            [
              -94.4545761452896,
              34.7289624171492
            ],
            [
              -94.4511886196818,
              34.8275901532647
            ],
            [
              -94.450233,
              34.855413
            ],
            [
              -94.45014,
              34.858694
            ],
            [
              -94.450065,
              34.861335
            ],
            [
              -94.44963,
              34.875253
            ],
            [
              -94.449058,
              34.890556
            ],
            [
              -94.449086,
              34.894152
            ],
            [
              -94.449253,
              34.895869
            ],
            [
              -94.4478888293082,
              34.933941114475395
            ],
            [
              -94.4463019390597,
              34.9782290232881
            ],
            [
              -94.4432818799616,
              35.06251468959
            ],
            [
              -94.441232,
              35.119724
            ],
            [
              -94.440754,
              35.128806
            ],
            [
              -94.4397174421235,
              35.163442013231496
            ],
            [
              -94.43955,
              35.169037
            ],
            [
              -94.439509,
              35.171807
            ],
            [
              -94.43886,
              35.187183
            ],
            [
              -94.439056,
              35.193588
            ],
            [
              -94.439084,
              35.197298
            ],
            [
              -94.43847,
              35.208587
            ],
            [
              -94.438247,
              35.210992
            ],
            [
              -94.437578,
              35.230936
            ],
            [
              -94.437774,
              35.239271
            ],
            [
              -94.437578,
              35.242202
            ],
            [
              -94.436595,
              35.250094
            ],
            [
              -94.435812,
              35.2713
            ],
            [
              -94.4357930292852,
              35.2718310010462
            ],
            [
              -94.435706,
              35.274267
            ],
            [
              -94.435316,
              35.275893
            ],
            [
              -94.43528,
              35.287485
            ],
            [
              -94.43517,
              35.291494
            ],
            [
              -94.434115,
              35.306493
            ],
            [
              -94.431815,
              35.362891
            ],
            [
              -94.432015,
              35.367391
            ],
            [
              -94.431515,
              35.369591
            ],
            [
              -94.433415,
              35.376291
            ],
            [
              -94.432685,
              35.380806
            ],
            [
              -94.4337424273008,
              35.3864671046955
            ],
            [
              -94.446008,
              35.380101
            ],
            [
              -94.472302,
              35.375981
            ],
            [
              -94.486688,
              35.36488
            ],
            [
              -94.480521,
              35.352291
            ],
            [
              -94.494771,
              35.342566
            ],
            [
              -94.494797,
              35.329809
            ],
            [
              -94.481147,
              35.309507
            ],
            [
              -94.493889,
              35.301223
            ],
            [
              -94.516956,
              35.309517
            ],
            [
              -94.533528,
              35.304331
            ],
            [
              -94.55463,
              35.310219
            ],
            [
              -94.569148,
              35.325641
            ],
            [
              -94.594801,
              35.327974
            ],
            [
              -94.615879,
              35.304111
            ],
            [
              -94.647967,
              35.29123
            ],
            [
              -94.668796,
              35.295029
            ],
            [
              -94.678342,
              35.3048
            ],
            [
              -94.678889,
              35.318409
            ],
            [
              -94.69086,
              35.329869
            ],
            [
              -94.725697,
              35.349702
            ],
            [
              -94.756413,
              35.356807
            ],
            [
              -94.778444,
              35.350895
            ],
            [
              -94.794374,
              35.327086
            ],
            [
              -94.813629,
              35.323344
            ],
            [
              -94.814284,
              35.202331
            ],
            [
              -94.927936,
              35.202611
            ],
            [
              -94.927863,
              35.058279
            ],
            [
              -94.927706,
              35.013473
            ],
            [
              -94.927668,
              34.942212
            ],
            [
              -94.99812,
              34.942157
            ],
            [
              -94.99781,
              34.855078
            ],
            [
              -95.059512,
              34.855019
            ],
            [
              -95.059946,
              34.738926
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "103",
      "COUNTYNS": "01101839",
      "AFFGEOID": "0500000US40103",
      "GEOID": "40103",
      "NAME": "Noble",
      "LSAD": "06",
      "ALAND": 1895593648,
      "AWATER": 27552185,
      "County_state": "Noble,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.461603,
              36.593637
            ],
            [
              -97.460792,
              36.164462
            ],
            [
              -97.353946,
              36.164304
            ],
            [
              -97.354132,
              36.159072
            ],
            [
              -97.140668,
              36.159231
            ],
            [
              -97.140968,
              36.246435
            ],
            [
              -96.925069,
              36.246474
            ],
            [
              -96.924936,
              36.333344
            ],
            [
              -97.032106,
              36.333354
            ],
            [
              -97.03211,
              36.404893
            ],
            [
              -97.032118,
              36.506957
            ],
            [
              -97.009785,
              36.506935
            ],
            [
              -96.996194,
              36.528979
            ],
            [
              -96.978973,
              36.535211
            ],
            [
              -96.958521,
              36.534307
            ],
            [
              -96.92471,
              36.552558
            ],
            [
              -96.902643,
              36.551388
            ],
            [
              -96.888017,
              36.574886
            ],
            [
              -96.912756,
              36.598949
            ],
            [
              -96.920492,
              36.600663
            ],
            [
              -96.948628,
              36.589997
            ],
            [
              -97.002857,
              36.582564
            ],
            [
              -97.035868,
              36.586215
            ],
            [
              -97.057414,
              36.593647
            ],
            [
              -97.139428,
              36.594028
            ],
            [
              -97.461603,
              36.593637
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "133",
      "COUNTYNS": "01101854",
      "AFFGEOID": "0500000US40133",
      "GEOID": "40133",
      "NAME": "Seminole",
      "LSAD": "06",
      "ALAND": 1639040857,
      "AWATER": 19778906,
      "County_state": "Seminole,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.777024,
              35.289992
            ],
            [
              -96.776916,
              35.173615
            ],
            [
              -96.775901,
              35.144642
            ],
            [
              -96.775681,
              34.899958
            ],
            [
              -96.755517,
              34.899809
            ],
            [
              -96.742093,
              34.869383
            ],
            [
              -96.727591,
              34.857182
            ],
            [
              -96.694182,
              34.868495
            ],
            [
              -96.68647,
              34.883185
            ],
            [
              -96.695689,
              34.903614
            ],
            [
              -96.710934,
              34.911715
            ],
            [
              -96.699488,
              34.930273
            ],
            [
              -96.667692,
              34.929583
            ],
            [
              -96.64699,
              34.902184
            ],
            [
              -96.62189,
              34.898984
            ],
            [
              -96.59579,
              34.905984
            ],
            [
              -96.577089,
              34.916584
            ],
            [
              -96.556889,
              34.914184
            ],
            [
              -96.555888,
              34.898084
            ],
            [
              -96.543762,
              34.880097
            ],
            [
              -96.528587,
              34.869586
            ],
            [
              -96.512599,
              34.875466
            ],
            [
              -96.490786,
              34.910585
            ],
            [
              -96.490434,
              35.115857
            ],
            [
              -96.441401,
              35.11577
            ],
            [
              -96.441368,
              35.290122
            ],
            [
              -96.44125,
              35.387229
            ],
            [
              -96.44129,
              35.467712
            ],
            [
              -96.445584,
              35.458771
            ],
            [
              -96.465588,
              35.45811
            ],
            [
              -96.473097,
              35.463958
            ],
            [
              -96.487735,
              35.449159
            ],
            [
              -96.483016,
              35.439842
            ],
            [
              -96.496594,
              35.428896
            ],
            [
              -96.518633,
              35.431205
            ],
            [
              -96.521552,
              35.438493
            ],
            [
              -96.553641,
              35.454309
            ],
            [
              -96.568476,
              35.438165
            ],
            [
              -96.555866,
              35.434701
            ],
            [
              -96.56082,
              35.422691
            ],
            [
              -96.537163,
              35.41094
            ],
            [
              -96.535453,
              35.391172
            ],
            [
              -96.55673,
              35.394974
            ],
            [
              -96.554935,
              35.401692
            ],
            [
              -96.576671,
              35.401934
            ],
            [
              -96.585778,
              35.411513
            ],
            [
              -96.599717,
              35.396955
            ],
            [
              -96.623699,
              35.400722
            ],
            [
              -96.653747,
              35.392112
            ],
            [
              -96.702399,
              35.416048
            ],
            [
              -96.718812,
              35.411967
            ],
            [
              -96.724807,
              35.425007
            ],
            [
              -96.744271,
              35.406589
            ],
            [
              -96.776694,
              35.405417
            ],
            [
              -96.777024,
              35.289992
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "043",
      "COUNTYNS": "01101809",
      "AFFGEOID": "0500000US40043",
      "GEOID": "40043",
      "NAME": "Dewey",
      "LSAD": "06",
      "ALAND": 2588642249,
      "AWATER": 22679881,
      "County_state": "Dewey,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.382074,
              36.164301
            ],
            [
              -99.381022,
              36.012925
            ],
            [
              -99.381082,
              35.89883
            ],
            [
              -99.375817,
              35.812188
            ],
            [
              -98.709084,
              35.811917
            ],
            [
              -98.709097,
              35.81244
            ],
            [
              -98.631985,
              35.812402
            ],
            [
              -98.636149,
              35.898808
            ],
            [
              -98.636899,
              36.164894
            ],
            [
              -98.955849,
              36.161577
            ],
            [
              -99.382074,
              36.164301
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "085",
      "COUNTYNS": "01101830",
      "AFFGEOID": "0500000US40085",
      "GEOID": "40085",
      "NAME": "Love",
      "LSAD": "06",
      "ALAND": 1331240598,
      "AWATER": 47493019,
      "County_state": "Love,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.560928,
              33.983964
            ],
            [
              -97.56117554354529,
              33.8975366004539
            ],
            [
              -97.55827,
              33.897099
            ],
            [
              -97.555002,
              33.897282
            ],
            [
              -97.551541,
              33.897947
            ],
            [
              -97.543246,
              33.901289
            ],
            [
              -97.532723,
              33.906577
            ],
            [
              -97.525277,
              33.911751
            ],
            [
              -97.519171,
              33.913638
            ],
            [
              -97.50096,
              33.919643
            ],
            [
              -97.494858,
              33.919258
            ],
            [
              -97.486505,
              33.916994
            ],
            [
              -97.48403724377,
              33.9157618691195
            ],
            [
              -97.460376,
              33.903948
            ],
            [
              -97.458069,
              33.901635
            ],
            [
              -97.450954,
              33.891398
            ],
            [
              -97.451469,
              33.87093
            ],
            [
              -97.457617,
              33.855126
            ],
            [
              -97.459566,
              33.853316
            ],
            [
              -97.461486,
              33.84956
            ],
            [
              -97.462857,
              33.841772
            ],
            [
              -97.459068,
              33.834581
            ],
            [
              -97.453057,
              33.828536
            ],
            [
              -97.444193,
              33.823773
            ],
            [
              -97.426493,
              33.819398
            ],
            [
              -97.410387,
              33.818845
            ],
            [
              -97.372941,
              33.819454
            ],
            [
              -97.368744,
              33.821471
            ],
            [
              -97.365507,
              33.823763
            ],
            [
              -97.358513,
              33.830018
            ],
            [
              -97.348338,
              33.843876
            ],
            [
              -97.3409,
              33.860236
            ],
            [
              -97.339392,
              33.86763
            ],
            [
              -97.336524,
              33.872827
            ],
            [
              -97.33294,
              33.87444
            ],
            [
              -97.329176,
              33.87444
            ],
            [
              -97.327563,
              33.873903
            ],
            [
              -97.326487,
              33.872648
            ],
            [
              -97.324158,
              33.866017
            ],
            [
              -97.322365,
              33.864941
            ],
            [
              -97.318243,
              33.865121
            ],
            [
              -97.315913,
              33.865838
            ],
            [
              -97.314413,
              33.866989
            ],
            [
              -97.30749,
              33.878204
            ],
            [
              -97.302471,
              33.880175
            ],
            [
              -97.299245,
              33.880175
            ],
            [
              -97.294227,
              33.876412
            ],
            [
              -97.285983,
              33.868526
            ],
            [
              -97.279108,
              33.864555
            ],
            [
              -97.275348,
              33.863225
            ],
            [
              -97.271532,
              33.86256
            ],
            [
              -97.256625,
              33.863286
            ],
            [
              -97.255636,
              33.863698
            ],
            [
              -97.254235,
              33.865323
            ],
            [
              -97.249209,
              33.875101
            ],
            [
              -97.24618,
              33.900344
            ],
            [
              -97.244946,
              33.903092
            ],
            [
              -97.242092,
              33.906277
            ],
            [
              -97.226522,
              33.914642
            ],
            [
              -97.210921,
              33.916064
            ],
            [
              -97.206141,
              33.91428
            ],
            [
              -97.185458,
              33.9007
            ],
            [
              -97.180845,
              33.895204
            ],
            [
              -97.179609,
              33.89225
            ],
            [
              -97.166629,
              33.847311
            ],
            [
              -97.166824,
              33.840395
            ],
            [
              -97.171627,
              33.835335
            ],
            [
              -97.18137,
              33.831375
            ],
            [
              -97.186254,
              33.830894
            ],
            [
              -97.19369,
              33.831307
            ],
            [
              -97.195831,
              33.830803
            ],
            [
              -97.197477,
              33.829795
            ],
            [
              -97.1997,
              33.827322
            ],
            [
              -97.203514,
              33.821825
            ],
            [
              -97.204995,
              33.81887
            ],
            [
              -97.205652,
              33.809824
            ],
            [
              -97.205431,
              33.801488
            ],
            [
              -97.203236,
              33.797343
            ],
            [
              -97.194786,
              33.785344
            ],
            [
              -97.190397,
              33.781153
            ],
            [
              -97.187792,
              33.769702
            ],
            [
              -97.181843,
              33.75587
            ],
            [
              -97.172192,
              33.737545
            ],
            [
              -97.163149,
              33.729322
            ],
            [
              -97.155066,
              33.724442
            ],
            [
              -97.149394,
              33.721967
            ],
            [
              -97.13753,
              33.718664
            ],
            [
              -97.126102,
              33.716941
            ],
            [
              -97.121102,
              33.717174
            ],
            [
              -97.113265,
              33.718804
            ],
            [
              -97.108936,
              33.720294
            ],
            [
              -97.104525,
              33.722608
            ],
            [
              -97.097154,
              33.727809
            ],
            [
              -97.094085,
              33.730992
            ],
            [
              -97.091072,
              33.735115
            ],
            [
              -97.086195,
              33.743933
            ],
            [
              -97.084693,
              33.753147
            ],
            [
              -97.084613,
              33.759993
            ],
            [
              -97.085218,
              33.765512
            ],
            [
              -97.087852,
              33.774099
            ],
            [
              -97.093917,
              33.789052
            ],
            [
              -97.095236,
              33.794136
            ],
            [
              -97.094771,
              33.798532
            ],
            [
              -97.092112,
              33.804097
            ],
            [
              -97.087999,
              33.808747
            ],
            [
              -97.07859,
              33.812756
            ],
            [
              -97.067977,
              33.814476
            ],
            [
              -97.048146,
              33.817456
            ],
            [
              -97.048881,
              33.82521
            ],
            [
              -97.057821,
              33.83452
            ],
            [
              -97.08345,
              33.840456
            ],
            [
              -97.090479,
              33.844841
            ],
            [
              -97.087247,
              33.853448
            ],
            [
              -97.068071,
              33.860465
            ],
            [
              -97.055838,
              33.855741
            ],
            [
              -97.050952,
              33.846568
            ],
            [
              -97.041245,
              33.837761
            ],
            [
              -97.038858,
              33.838264
            ],
            [
              -97.023899,
              33.844213
            ],
            [
              -97.017857,
              33.850142
            ],
            [
              -96.985567,
              33.886522
            ],
            [
              -96.983971,
              33.892083
            ],
            [
              -96.984939,
              33.904866
            ],
            [
              -96.988745,
              33.918468
            ],
            [
              -96.993997,
              33.928979
            ],
            [
              -96.995023,
              33.932035
            ],
            [
              -96.996183,
              33.941728
            ],
            [
              -96.995368,
              33.947302
            ],
            [
              -96.994288,
              33.949206
            ],
            [
              -96.990835,
              33.952701
            ],
            [
              -96.987892,
              33.954671
            ],
            [
              -96.981337,
              33.956378
            ],
            [
              -96.979415,
              33.956178
            ],
            [
              -96.979347,
              33.95513
            ],
            [
              -96.980676,
              33.951814
            ],
            [
              -96.981031,
              33.94916
            ],
            [
              -96.979818,
              33.941588
            ],
            [
              -96.976955,
              33.937453
            ],
            [
              -96.973807,
              33.935697
            ],
            [
              -96.972542,
              33.935795
            ],
            [
              -96.952313,
              33.944582
            ],
            [
              -96.944611,
              33.949217
            ],
            [
              -96.93479067141789,
              33.954358787058396
            ],
            [
              -96.934791,
              34.012942
            ],
            [
              -96.96972,
              34.013009
            ],
            [
              -96.969627,
              34.07102
            ],
            [
              -97.560764,
              34.071089
            ],
            [
              -97.560872,
              34.002978
            ],
            [
              -97.536818,
              33.983877
            ],
            [
              -97.560928,
              33.983964
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "129",
      "COUNTYNS": "01101852",
      "AFFGEOID": "0500000US40129",
      "GEOID": "40129",
      "NAME": "Roger Mills",
      "LSAD": "06",
      "ALAND": 2955528251,
      "AWATER": 13695174,
      "County_state": "Roger Mills,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.00039619832901,
              35.8809479999968
            ],
            [
              -100.000392,
              35.619115
            ],
            [
              -100.000388844143,
              35.4223639883094
            ],
            [
              -99.57582,
              35.421655
            ],
            [
              -99.575598,
              35.508861
            ],
            [
              -99.452607,
              35.508913
            ],
            [
              -99.364141,
              35.508793
            ],
            [
              -99.364129,
              35.55172
            ],
            [
              -99.377052,
              35.551741
            ],
            [
              -99.375817,
              35.812188
            ],
            [
              -99.381082,
              35.89883
            ],
            [
              -99.381022,
              36.012925
            ],
            [
              -99.42215,
              36.011926
            ],
            [
              -99.452812,
              36.005241
            ],
            [
              -99.477493,
              35.985731
            ],
            [
              -99.495812,
              35.978732
            ],
            [
              -99.515306,
              35.925796
            ],
            [
              -99.549632,
              35.90022
            ],
            [
              -99.558486,
              35.865988
            ],
            [
              -99.601795,
              35.855905
            ],
            [
              -99.619813,
              35.842089
            ],
            [
              -99.653183,
              35.850123
            ],
            [
              -99.677824,
              35.851008
            ],
            [
              -99.717101,
              35.859769
            ],
            [
              -99.746429,
              35.881961
            ],
            [
              -99.761084,
              35.880745
            ],
            [
              -99.776052,
              35.888132
            ],
            [
              -99.779103,
              35.896393
            ],
            [
              -99.801389,
              35.910645
            ],
            [
              -99.810219,
              35.928909
            ],
            [
              -99.807921,
              35.960342
            ],
            [
              -99.802287,
              35.971603
            ],
            [
              -99.809444,
              36.000044
            ],
            [
              -99.837948,
              36.006794
            ],
            [
              -99.855014,
              36.003685
            ],
            [
              -99.875394,
              35.987798
            ],
            [
              -99.888309,
              35.987847
            ],
            [
              -99.908271,
              35.966634
            ],
            [
              -99.912531,
              35.954986
            ],
            [
              -99.905601,
              35.936727
            ],
            [
              -99.925147,
              35.913664
            ],
            [
              -99.959628,
              35.900036
            ],
            [
              -99.97211,
              35.888776
            ],
            [
              -100.00039619832901,
              35.8809479999968
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "143",
      "COUNTYNS": "01101859",
      "AFFGEOID": "0500000US40143",
      "GEOID": "40143",
      "NAME": "Tulsa",
      "LSAD": "06",
      "ALAND": 1477021879,
      "AWATER": 43361035,
      "County_state": "Tulsa,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.297888,
              36.162279
            ],
            [
              -96.297786,
              36.075779
            ],
            [
              -96.176255,
              36.075678
            ],
            [
              -96.101073,
              36.075571
            ],
            [
              -96.074102,
              36.075565
            ],
            [
              -96.029583,
              36.075366
            ],
            [
              -96.02956,
              36.046427
            ],
            [
              -96.029518,
              36.031966
            ],
            [
              -96.029544,
              35.988387
            ],
            [
              -96.029487,
              35.944723
            ],
            [
              -96.02957,
              35.901106
            ],
            [
              -96.033226,
              35.871469
            ],
            [
              -96.033118,
              35.856824
            ],
            [
              -95.90837,
              35.856399
            ],
            [
              -95.819964,
              35.8559
            ],
            [
              -95.819455,
              35.901095
            ],
            [
              -95.761687,
              35.900811
            ],
            [
              -95.761564,
              35.909206
            ],
            [
              -95.761656,
              35.988387
            ],
            [
              -95.76163,
              35.993413
            ],
            [
              -95.76165,
              36.017439
            ],
            [
              -95.761629,
              36.031919
            ],
            [
              -95.761632,
              36.046435
            ],
            [
              -95.761656,
              36.090289
            ],
            [
              -95.761667,
              36.10445
            ],
            [
              -95.761641,
              36.118981
            ],
            [
              -95.761664,
              36.133471
            ],
            [
              -95.76165,
              36.16275
            ],
            [
              -95.77958,
              36.16261
            ],
            [
              -95.79442,
              36.16262
            ],
            [
              -95.8154,
              36.16263
            ],
            [
              -95.81531,
              36.1877
            ],
            [
              -95.81527,
              36.22445
            ],
            [
              -95.81534,
              36.24953
            ],
            [
              -95.81218,
              36.26401
            ],
            [
              -95.8122,
              36.32205
            ],
            [
              -95.81221,
              36.35101
            ],
            [
              -95.812189,
              36.394339
            ],
            [
              -95.79425,
              36.39449
            ],
            [
              -95.79437,
              36.42358
            ],
            [
              -95.81268,
              36.42356
            ],
            [
              -95.884194,
              36.423722
            ],
            [
              -95.920323,
              36.423703
            ],
            [
              -96.001171,
              36.423686
            ],
            [
              -96.001463,
              36.307448
            ],
            [
              -96.001276,
              36.250012
            ],
            [
              -96.001405,
              36.244403
            ],
            [
              -96.001396,
              36.242135
            ],
            [
              -96.001466,
              36.219302
            ],
            [
              -96.001055,
              36.161294
            ],
            [
              -96.019852,
              36.161252
            ],
            [
              -96.043752,
              36.161127
            ],
            [
              -96.090796,
              36.161084
            ],
            [
              -96.141497,
              36.160981
            ],
            [
              -96.154182,
              36.160985
            ],
            [
              -96.268287,
              36.16198
            ],
            [
              -96.297888,
              36.162279
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "149",
      "COUNTYNS": "01101862",
      "AFFGEOID": "0500000US40149",
      "GEOID": "40149",
      "NAME": "Washita",
      "LSAD": "06",
      "ALAND": 2598189859,
      "AWATER": 14579002,
      "County_state": "Washita,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.364217,
              35.465328
            ],
            [
              -99.364282,
              35.421312
            ],
            [
              -99.360219,
              35.204464
            ],
            [
              -99.360226,
              35.116738
            ],
            [
              -99.060658,
              35.116604
            ],
            [
              -98.750344,
              35.116343
            ],
            [
              -98.73469,
              35.101846
            ],
            [
              -98.730487,
              35.112177
            ],
            [
              -98.716775,
              35.113349
            ],
            [
              -98.712452,
              35.104761
            ],
            [
              -98.696315,
              35.111909
            ],
            [
              -98.674993,
              35.108955
            ],
            [
              -98.672232,
              35.119009
            ],
            [
              -98.650932,
              35.123929
            ],
            [
              -98.650435,
              35.114836
            ],
            [
              -98.639894,
              35.122849
            ],
            [
              -98.629975,
              35.116385
            ],
            [
              -98.621054,
              35.097352
            ],
            [
              -98.618012,
              35.101703
            ],
            [
              -98.623857,
              35.204237
            ],
            [
              -98.623336,
              35.464225
            ],
            [
              -99.046253,
              35.465173
            ],
            [
              -99.364217,
              35.465328
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "015",
      "COUNTYNS": "01101795",
      "AFFGEOID": "0500000US40015",
      "GEOID": "40015",
      "NAME": "Caddo",
      "LSAD": "06",
      "ALAND": 3310745123,
      "AWATER": 30820525,
      "County_state": "Caddo,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.623336,
              35.464225
            ],
            [
              -98.623857,
              35.204237
            ],
            [
              -98.618012,
              35.101703
            ],
            [
              -98.621054,
              35.097352
            ],
            [
              -98.620836,
              35.080039
            ],
            [
              -98.619816,
              34.854953
            ],
            [
              -98.545351,
              34.855104
            ],
            [
              -98.387202,
              34.855165
            ],
            [
              -98.092645,
              34.854919
            ],
            [
              -98.093146,
              34.883934
            ],
            [
              -98.093057,
              34.985228
            ],
            [
              -98.092804,
              35.088638
            ],
            [
              -98.09134,
              35.20373
            ],
            [
              -98.096014,
              35.377753
            ],
            [
              -98.300783,
              35.377245
            ],
            [
              -98.305837,
              35.377173
            ],
            [
              -98.306288,
              35.516333
            ],
            [
              -98.308297,
              35.551312
            ],
            [
              -98.313251,
              35.551523
            ],
            [
              -98.386196,
              35.551445
            ],
            [
              -98.623149,
              35.551597
            ],
            [
              -98.623255,
              35.53626
            ],
            [
              -98.623336,
              35.464225
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "025",
      "COUNTYNS": "01101800",
      "AFFGEOID": "0500000US40025",
      "GEOID": "40025",
      "NAME": "Cimarron",
      "LSAD": "06",
      "ALAND": 4752113920,
      "AWATER": 15923999,
      "County_state": "Cimarron,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -103.002565,
              36.526588
            ],
            [
              -103.002434,
              36.500397
            ],
            [
              -102.250453,
              36.500369
            ],
            [
              -102.24499,
              36.500704
            ],
            [
              -102.162463,
              36.500326
            ],
            [
              -102.12545,
              36.500324
            ],
            [
              -102.122066,
              36.500684
            ],
            [
              -102.03233903405601,
              36.5006100932816
            ],
            [
              -102.032173,
              36.849182
            ],
            [
              -102.028248,
              36.853048
            ],
            [
              -102.02820412187499,
              36.9931250152408
            ],
            [
              -102.028207,
              36.993125
            ],
            [
              -102.04224,
              36.993083
            ],
            [
              -102.054503,
              36.993109
            ],
            [
              -102.184271,
              36.993593
            ],
            [
              -102.208316,
              36.99373
            ],
            [
              -102.260789,
              36.994388
            ],
            [
              -102.355288,
              36.994506
            ],
            [
              -102.355367,
              36.994575
            ],
            [
              -102.698142,
              36.995149
            ],
            [
              -102.74206,
              36.997689
            ],
            [
              -102.759882,
              36.998529
            ],
            [
              -102.778569,
              36.999242
            ],
            [
              -102.799802,
              36.999489
            ],
            [
              -102.817142,
              36.999768
            ],
            [
              -102.841989,
              36.999598
            ],
            [
              -102.979613,
              36.998549
            ],
            [
              -102.985807,
              36.998571
            ],
            [
              -102.986976,
              36.998524
            ],
            [
              -103.002199,
              37.000104
            ],
            [
              -103.002247,
              36.911587
            ],
            [
              -103.001964,
              36.909573
            ],
            [
              -103.002198,
              36.719427
            ],
            [
              -103.002518,
              36.675186
            ],
            [
              -103.002252,
              36.61718
            ],
            [
              -103.002188,
              36.602716
            ],
            [
              -103.002565,
              36.526588
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "147",
      "COUNTYNS": "01101861",
      "AFFGEOID": "0500000US40147",
      "GEOID": "40147",
      "NAME": "Washington",
      "LSAD": "06",
      "ALAND": 1076019752,
      "AWATER": 22829398,
      "County_state": "Washington,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.001145,
              36.439763
            ],
            [
              -96.001171,
              36.423686
            ],
            [
              -95.920323,
              36.423703
            ],
            [
              -95.884194,
              36.423722
            ],
            [
              -95.81268,
              36.42356
            ],
            [
              -95.812449,
              36.597516
            ],
            [
              -95.809617,
              36.597476
            ],
            [
              -95.809687,
              36.75696
            ],
            [
              -95.80982,
              36.94193
            ],
            [
              -95.78666,
              36.94194
            ],
            [
              -95.786762,
              36.99931
            ],
            [
              -95.80798,
              36.999124
            ],
            [
              -95.866899,
              36.999261
            ],
            [
              -95.873944,
              36.9993
            ],
            [
              -95.875257,
              36.999302
            ],
            [
              -95.877151,
              36.999304
            ],
            [
              -95.91018,
              36.999336
            ],
            [
              -95.928122,
              36.999245
            ],
            [
              -95.936992,
              36.999268
            ],
            [
              -95.9642699335572,
              36.9990936072442
            ],
            [
              -96.00081,
              36.99886
            ],
            [
              -96.00066,
              36.770275
            ],
            [
              -96.000956,
              36.736228
            ],
            [
              -96.001145,
              36.439763
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "053",
      "COUNTYNS": "01101814",
      "AFFGEOID": "0500000US40053",
      "GEOID": "40053",
      "NAME": "Grant",
      "LSAD": "06",
      "ALAND": 2592244840,
      "AWATER": 7021951,
      "County_state": "Grant,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.111985,
              36.998133
            ],
            [
              -98.111542,
              36.949335
            ],
            [
              -98.109296,
              36.594618
            ],
            [
              -98.104286,
              36.593578
            ],
            [
              -97.926115,
              36.593575
            ],
            [
              -97.462459,
              36.593627
            ],
            [
              -97.46222,
              36.767311
            ],
            [
              -97.4623463219255,
              36.9986852256487
            ],
            [
              -97.472861,
              36.998721
            ],
            [
              -97.527292,
              36.99875
            ],
            [
              -97.5459,
              36.998709
            ],
            [
              -97.546498,
              36.998747
            ],
            [
              -97.564536,
              36.998711
            ],
            [
              -97.606549,
              36.998682
            ],
            [
              -97.637137,
              36.99909
            ],
            [
              -97.650466,
              36.999004
            ],
            [
              -97.697104,
              36.998826
            ],
            [
              -97.768704,
              36.99875
            ],
            [
              -97.783432,
              36.998961
            ],
            [
              -97.783489,
              36.998847
            ],
            [
              -97.802298,
              36.998713
            ],
            [
              -97.8023129924768,
              36.998712977542695
            ],
            [
              -98.033955,
              36.998366
            ],
            [
              -98.03989,
              36.998349
            ],
            [
              -98.045342,
              36.998327
            ],
            [
              -98.111985,
              36.998133
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "095",
      "COUNTYNS": "01101832",
      "AFFGEOID": "0500000US40095",
      "GEOID": "40095",
      "NAME": "Marshall",
      "LSAD": "06",
      "ALAND": 962336585,
      "AWATER": 143420175,
      "County_state": "Marshall,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.969627,
              34.07102
            ],
            [
              -96.96972,
              34.013009
            ],
            [
              -96.934791,
              34.012942
            ],
            [
              -96.93479067141789,
              33.954358787058396
            ],
            [
              -96.932252,
              33.955688
            ],
            [
              -96.924268,
              33.959159
            ],
            [
              -96.922114,
              33.959579
            ],
            [
              -96.918618,
              33.958926
            ],
            [
              -96.9163,
              33.957798
            ],
            [
              -96.911336,
              33.95396
            ],
            [
              -96.907387,
              33.950025
            ],
            [
              -96.905253,
              33.947219
            ],
            [
              -96.902434,
              33.942018
            ],
            [
              -96.899442,
              33.933728
            ],
            [
              -96.896469,
              33.913318
            ],
            [
              -96.897194,
              33.902954
            ],
            [
              -96.895728,
              33.896414
            ],
            [
              -96.88301,
              33.868019
            ],
            [
              -96.875281,
              33.860505
            ],
            [
              -96.866438,
              33.853149
            ],
            [
              -96.85609,
              33.84749
            ],
            [
              -96.850593,
              33.847211
            ],
            [
              -96.845896,
              33.848975
            ],
            [
              -96.841592,
              33.852894
            ],
            [
              -96.840819,
              33.863645
            ],
            [
              -96.839778,
              33.868396
            ],
            [
              -96.837413,
              33.871349
            ],
            [
              -96.832157,
              33.874835
            ],
            [
              -96.812778,
              33.872646
            ],
            [
              -96.794276,
              33.868886
            ],
            [
              -96.783485,
              33.863534
            ],
            [
              -96.780569,
              33.860098
            ],
            [
              -96.779588,
              33.857939
            ],
            [
              -96.777202,
              33.848162
            ],
            [
              -96.776766,
              33.841976
            ],
            [
              -96.770676,
              33.829621
            ],
            [
              -96.769378,
              33.827477
            ],
            [
              -96.766235,
              33.825458
            ],
            [
              -96.761588,
              33.824406
            ],
            [
              -96.754041,
              33.824658
            ],
            [
              -96.746038,
              33.825699
            ],
            [
              -96.712422,
              33.831633
            ],
            [
              -96.708134,
              33.83306
            ],
            [
              -96.704457,
              33.835021
            ],
            [
              -96.699574,
              33.839049
            ],
            [
              -96.690708,
              33.849959
            ],
            [
              -96.688191,
              33.854613
            ],
            [
              -96.684727,
              33.862905
            ],
            [
              -96.682209,
              33.873876
            ],
            [
              -96.682103,
              33.876645
            ],
            [
              -96.683464,
              33.884217
            ],
            [
              -96.680947,
              33.896204
            ],
            [
              -96.675306,
              33.909114
            ],
            [
              -96.673449,
              33.912278
            ],
            [
              -96.670618,
              33.914914
            ],
            [
              -96.667187,
              33.91694
            ],
            [
              -96.66441,
              33.917267
            ],
            [
              -96.659896,
              33.916666
            ],
            [
              -96.64405,
              33.905962
            ],
            [
              -96.630117,
              33.895422
            ],
            [
              -96.628294,
              33.894477
            ],
            [
              -96.607562,
              33.894735
            ],
            [
              -96.592948,
              33.895616
            ],
            [
              -96.5885189157485,
              33.8948810582175
            ],
            [
              -96.58253,
              33.908299
            ],
            [
              -96.595823,
              33.944051
            ],
            [
              -96.57701,
              33.964406
            ],
            [
              -96.585296,
              33.974541
            ],
            [
              -96.594332,
              33.971655
            ],
            [
              -96.615638,
              33.990729
            ],
            [
              -96.624942,
              34.008852
            ],
            [
              -96.604294,
              34.007869
            ],
            [
              -96.593423,
              34.016728
            ],
            [
              -96.582949,
              34.006875
            ],
            [
              -96.573649,
              34.020985
            ],
            [
              -96.574494,
              34.037971
            ],
            [
              -96.547515,
              34.064346
            ],
            [
              -96.560344,
              34.073436
            ],
            [
              -96.565716,
              34.089691
            ],
            [
              -96.585355,
              34.114459
            ],
            [
              -96.582251,
              34.125327
            ],
            [
              -96.606821,
              34.139873
            ],
            [
              -96.619783,
              34.161191
            ],
            [
              -96.641897,
              34.168243
            ],
            [
              -96.653728,
              34.161402
            ],
            [
              -96.669965,
              34.172254
            ],
            [
              -96.933671,
              34.172553
            ],
            [
              -96.968415,
              34.172617
            ],
            [
              -96.969627,
              34.07102
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "059",
      "COUNTYNS": "01101817",
      "AFFGEOID": "0500000US40059",
      "GEOID": "40059",
      "NAME": "Harper",
      "LSAD": "06",
      "ALAND": 2691041192,
      "AWATER": 5259447,
      "County_state": "Harper,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.002563,
              37.001706
            ],
            [
              -100.00316,
              36.59325
            ],
            [
              -99.605565,
              36.592753
            ],
            [
              -99.569309,
              36.592936
            ],
            [
              -99.295257,
              36.595123
            ],
            [
              -99.295186,
              36.819566
            ],
            [
              -99.317419,
              36.842331
            ],
            [
              -99.314288,
              36.856191
            ],
            [
              -99.374218,
              36.879597
            ],
            [
              -99.398092,
              36.921539
            ],
            [
              -99.420519,
              36.941137
            ],
            [
              -99.443415,
              36.995491
            ],
            [
              -99.456203,
              36.999471
            ],
            [
              -99.484333,
              36.999626
            ],
            [
              -99.500395,
              36.999576
            ],
            [
              -99.500395,
              36.999637
            ],
            [
              -99.502665,
              36.999645
            ],
            [
              -99.504093,
              36.999648
            ],
            [
              -99.508574,
              36.999658
            ],
            [
              -99.54111590157169,
              36.9995725260596
            ],
            [
              -99.558068,
              36.999528
            ],
            [
              -99.625399,
              36.999671
            ],
            [
              -99.648652,
              36.999604
            ],
            [
              -99.657658,
              37.000197
            ],
            [
              -99.774255,
              37.000837
            ],
            [
              -99.774816,
              37.000841
            ],
            [
              -99.786016,
              37.000931
            ],
            [
              -99.875409,
              37.001659
            ],
            [
              -99.995201,
              37.001631
            ],
            [
              -100.001286,
              37.001699
            ],
            [
              -100.002563,
              37.001706
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "007",
      "COUNTYNS": "01101791",
      "AFFGEOID": "0500000US40007",
      "GEOID": "40007",
      "NAME": "Beaver",
      "LSAD": "06",
      "ALAND": 4700042738,
      "AWATER": 7347161,
      "County_state": "Beaver,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.953774,
              36.848365
            ],
            [
              -100.954152945903,
              36.4995989128779
            ],
            [
              -100.936058,
              36.499602
            ],
            [
              -100.918513,
              36.499621
            ],
            [
              -100.884174,
              36.499682
            ],
            [
              -100.88408,
              36.499682
            ],
            [
              -100.859657,
              36.499687
            ],
            [
              -100.85084,
              36.4997
            ],
            [
              -100.824236,
              36.499618
            ],
            [
              -100.824218,
              36.499618
            ],
            [
              -100.80619,
              36.499674
            ],
            [
              -100.806172,
              36.499634
            ],
            [
              -100.802909,
              36.499621
            ],
            [
              -100.802886,
              36.499621
            ],
            [
              -100.761811,
              36.499618
            ],
            [
              -100.761811,
              36.49958
            ],
            [
              -100.724362,
              36.49958
            ],
            [
              -100.724361,
              36.499558
            ],
            [
              -100.708626,
              36.499553
            ],
            [
              -100.708628,
              36.499521
            ],
            [
              -100.657763,
              36.499483
            ],
            [
              -100.657763,
              36.4995
            ],
            [
              -100.648343,
              36.499495
            ],
            [
              -100.648344,
              36.499463
            ],
            [
              -100.592614,
              36.499469
            ],
            [
              -100.592556,
              36.499469
            ],
            [
              -100.592551,
              36.499429
            ],
            [
              -100.583539,
              36.499483
            ],
            [
              -100.583379,
              36.499443
            ],
            [
              -100.578114,
              36.499463
            ],
            [
              -100.578114,
              36.499439
            ],
            [
              -100.546145,
              36.499343
            ],
            [
              -100.531215,
              36.499341
            ],
            [
              -100.531215,
              36.49929
            ],
            [
              -100.530478,
              36.49924
            ],
            [
              -100.530314,
              36.499357
            ],
            [
              -100.522227,
              36.499291
            ],
            [
              -100.441065,
              36.49949
            ],
            [
              -100.441064,
              36.499462
            ],
            [
              -100.433959,
              36.499456
            ],
            [
              -100.421328,
              36.499447
            ],
            [
              -100.421301,
              36.499488
            ],
            [
              -100.413634,
              36.499444
            ],
            [
              -100.41355,
              36.499469
            ],
            [
              -100.378634,
              36.499517
            ],
            [
              -100.378592,
              36.499445
            ],
            [
              -100.351852,
              36.499487
            ],
            [
              -100.351842,
              36.499473
            ],
            [
              -100.334464,
              36.49942
            ],
            [
              -100.334441,
              36.49944
            ],
            [
              -100.32415,
              36.499679
            ],
            [
              -100.311245,
              36.499631
            ],
            [
              -100.311018,
              36.499688
            ],
            [
              -100.310643,
              36.499642
            ],
            [
              -100.181221,
              36.499633
            ],
            [
              -100.090021,
              36.499634
            ],
            [
              -100.003761886524,
              36.4996994535481
            ],
            [
              -100.00316,
              36.59325
            ],
            [
              -100.002563,
              37.001706
            ],
            [
              -100.005706,
              37.001726
            ],
            [
              -100.089483739431,
              37.0020915224233
            ],
            [
              -100.115722,
              37.002206
            ],
            [
              -100.187547,
              37.002082
            ],
            [
              -100.192371,
              37.002036
            ],
            [
              -100.193754,
              37.002133
            ],
            [
              -100.201676,
              37.002081
            ],
            [
              -100.551598,
              37.00062
            ],
            [
              -100.552683,
              37.000735
            ],
            [
              -100.591328,
              37.000376
            ],
            [
              -100.591413,
              37.000399
            ],
            [
              -100.62977,
              37.000025
            ],
            [
              -100.633322824093,
              36.9999361044857
            ],
            [
              -100.633327,
              36.999936
            ],
            [
              -100.675552,
              36.999688
            ],
            [
              -100.734517,
              36.999059
            ],
            [
              -100.756894,
              36.999357
            ],
            [
              -100.765484,
              36.999177
            ],
            [
              -100.806116,
              36.999091
            ],
            [
              -100.814277,
              36.999085
            ],
            [
              -100.855634,
              36.998626
            ],
            [
              -100.89166,
              36.998604
            ],
            [
              -100.904274,
              36.998745
            ],
            [
              -100.904588,
              36.998561
            ],
            [
              -100.945468897552,
              36.998152969176196
            ],
            [
              -100.945551,
              36.848654
            ],
            [
              -100.953774,
              36.848365
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "035",
      "COUNTYNS": "01101805",
      "AFFGEOID": "0500000US40035",
      "GEOID": "40035",
      "NAME": "Craig",
      "LSAD": "06",
      "ALAND": 1971897017,
      "AWATER": 3697828,
      "County_state": "Craig,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.431731,
              36.942776
            ],
            [
              -95.43157,
              36.59754
            ],
            [
              -95.32837,
              36.59763
            ],
            [
              -95.32817,
              36.51024
            ],
            [
              -95.184816,
              36.510308
            ],
            [
              -95.00573,
              36.50989
            ],
            [
              -95.00569,
              36.59766
            ],
            [
              -94.999403,
              36.670631
            ],
            [
              -94.999153,
              36.943238
            ],
            [
              -94.993385,
              36.949941
            ],
            [
              -95.022231,
              36.9608
            ],
            [
              -95.030707,
              36.972433
            ],
            [
              -95.034123,
              36.994225
            ],
            [
              -95.00762,
              36.999514
            ],
            [
              -95.011433,
              36.999535
            ],
            [
              -95.030324,
              36.999517
            ],
            [
              -95.037857,
              36.999497
            ],
            [
              -95.049499,
              36.99958
            ],
            [
              -95.0735038728895,
              36.999509015161394
            ],
            [
              -95.073509,
              36.999509
            ],
            [
              -95.1221400136406,
              36.9995268619752
            ],
            [
              -95.155187,
              36.999539
            ],
            [
              -95.155372,
              36.99954
            ],
            [
              -95.1589689477582,
              36.9995367194603
            ],
            [
              -95.177301,
              36.99952
            ],
            [
              -95.195307,
              36.999565
            ],
            [
              -95.322565,
              36.999358
            ],
            [
              -95.328058,
              36.999365
            ],
            [
              -95.328327,
              36.999366
            ],
            [
              -95.33121,
              36.99938
            ],
            [
              -95.40757195069268,
              36.9992412018471
            ],
            [
              -95.407844,
              36.94271
            ],
            [
              -95.431731,
              36.942776
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "093",
      "COUNTYNS": "01101831",
      "AFFGEOID": "0500000US40093",
      "GEOID": "40093",
      "NAME": "Major",
      "LSAD": "06",
      "ALAND": 2473407963,
      "AWATER": 7651201,
      "County_state": "Major,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.960334,
              36.506914
            ],
            [
              -98.960885,
              36.246772
            ],
            [
              -98.95596,
              36.230901
            ],
            [
              -98.955849,
              36.161577
            ],
            [
              -98.636899,
              36.164894
            ],
            [
              -98.601244,
              36.165771
            ],
            [
              -98.210544,
              36.16489
            ],
            [
              -98.103904,
              36.164877
            ],
            [
              -98.104427,
              36.463105
            ],
            [
              -98.533617,
              36.46323
            ],
            [
              -98.53355,
              36.397592
            ],
            [
              -98.552542,
              36.402318
            ],
            [
              -98.593447,
              36.392031
            ],
            [
              -98.625361,
              36.392595
            ],
            [
              -98.699493,
              36.416013
            ],
            [
              -98.709224,
              36.421958
            ],
            [
              -98.72681,
              36.445332
            ],
            [
              -98.777705,
              36.448442
            ],
            [
              -98.80782,
              36.460744
            ],
            [
              -98.81946,
              36.470995
            ],
            [
              -98.843646,
              36.506248
            ],
            [
              -98.960334,
              36.506914
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "40",
      "COUNTYFP": "131",
      "COUNTYNS": "01101853",
      "AFFGEOID": "0500000US40131",
      "GEOID": "40131",
      "NAME": "Rogers",
      "LSAD": "06",
      "ALAND": 1749945924,
      "AWATER": 92823523,
      "County_state": "Rogers,40"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.81531,
              36.1877
            ],
            [
              -95.8154,
              36.16263
            ],
            [
              -95.79442,
              36.16262
            ],
            [
              -95.77958,
              36.16261
            ],
            [
              -95.76165,
              36.16275
            ],
            [
              -95.726408,
              36.162496
            ],
            [
              -95.71961,
              36.16199
            ],
            [
              -95.672684,
              36.162333
            ],
            [
              -95.619187,
              36.162529
            ],
            [
              -95.604696,
              36.13346
            ],
            [
              -95.567844,
              36.129378
            ],
            [
              -95.568309,
              36.107401
            ],
            [
              -95.552587,
              36.091486
            ],
            [
              -95.571525,
              36.075282
            ],
            [
              -95.43997,
              36.0752
            ],
            [
              -95.43998,
              36.19142
            ],
            [
              -95.439972,
              36.248411
            ],
            [
              -95.435518,
              36.24932
            ],
            [
              -95.435427,
              36.346498
            ],
            [
              -95.435454,
              36.352029
            ],
            [
              -95.43558,
              36.42319
            ],
            [
              -95.43561,
              36.51016
            ],
            [
              -95.32817,
              36.51024
            ],
            [
              -95.32837,
              36.59763
            ],
            [
              -95.43157,
              36.59754
            ],
            [
              -95.809617,
              36.597476
            ],
            [
              -95.812449,
              36.597516
            ],
            [
              -95.81268,
              36.42356
            ],
            [
              -95.79437,
              36.42358
            ],
            [
              -95.79425,
              36.39449
            ],
            [
              -95.812189,
              36.394339
            ],
            [
              -95.81221,
              36.35101
            ],
            [
              -95.8122,
              36.32205
            ],
            [
              -95.81218,
              36.26401
            ],
            [
              -95.81534,
              36.24953
            ],
            [
              -95.81527,
              36.22445
            ],
            [
              -95.81531,
              36.1877
            ]
          ]
        ]
      ]
    }
  }
]
};