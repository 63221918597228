export const counties_wa = {
  "type":"FeatureCollection","name":"wa_counties","features":[
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "53",
      "COUNTYFP": "053",
      "COUNTYNS": "01529159",
      "AFFGEOID": "0500000US53053",
      "GEOID": "53053",
      "NAME": "Pierce",
      "LSAD": "06",
      "ALAND": 4320445281,
      "AWATER": 355666719,
      "County_state": "Pierce,53"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -122.707544,
              47.085332
            ],
            [
              -122.703887,
              47.071688
            ],
            [
              -122.675495,
              47.037195
            ],
            [
              -122.671611,
              47.01485
            ],
            [
              -122.629835,
              46.997401
            ],
            [
              -122.638211,
              46.978139
            ],
            [
              -122.629117,
              46.971463
            ],
            [
              -122.611602,
              46.967213
            ],
            [
              -122.611047,
              46.9767
            ],
            [
              -122.565163,
              46.966573
            ],
            [
              -122.572115,
              46.960219
            ],
            [
              -122.560436,
              46.933457
            ],
            [
              -122.527869,
              46.924975
            ],
            [
              -122.494116,
              46.905218
            ],
            [
              -122.491079,
              46.867965
            ],
            [
              -122.458392,
              46.863845
            ],
            [
              -122.450631,
              46.85251
            ],
            [
              -122.378516,
              46.839357
            ],
            [
              -122.37119,
              46.845408
            ],
            [
              -122.338261,
              46.837956
            ],
            [
              -122.327681,
              46.844343
            ],
            [
              -122.325106,
              46.830489
            ],
            [
              -122.303576,
              46.828117
            ],
            [
              -122.306259,
              46.806311
            ],
            [
              -122.308854,
              46.78943
            ],
            [
              -122.269624,
              46.766796
            ],
            [
              -122.238096,
              46.775321
            ],
            [
              -122.203115,
              46.763061
            ],
            [
              -122.177877,
              46.755202
            ],
            [
              -122.12903,
              46.754663
            ],
            [
              -122.11556,
              46.748901
            ],
            [
              -122.082298,
              46.75222
            ],
            [
              -122.041301,
              46.742522
            ],
            [
              -122.023412,
              46.742264
            ],
            [
              -122.008101,
              46.752567
            ],
            [
              -121.984521,
              46.744334
            ],
            [
              -121.957121,
              46.755041
            ],
            [
              -121.931247,
              46.740372
            ],
            [
              -121.867436,
              46.729142
            ],
            [
              -121.84189,
              46.728455
            ],
            [
              -121.831764,
              46.730246
            ],
            [
              -121.817399,
              46.747287
            ],
            [
              -121.777042,
              46.776464
            ],
            [
              -121.758799,
              46.783571
            ],
            [
              -121.455218,
              46.783797
            ],
            [
              -121.442517,
              46.792661
            ],
            [
              -121.474376,
              46.820394
            ],
            [
              -121.497345,
              46.862866
            ],
            [
              -121.52307,
              46.872783
            ],
            [
              -121.497774,
              46.90739
            ],
            [
              -121.456831,
              46.923146
            ],
            [
              -121.449464,
              46.934353
            ],
            [
              -121.45838,
              46.96057
            ],
            [
              -121.442143,
              46.972936
            ],
            [
              -121.445841,
              46.980224
            ],
            [
              -121.406457,
              46.998744
            ],
            [
              -121.406193,
              47.012176
            ],
            [
              -121.39474,
              47.023271
            ],
            [
              -121.399801,
              47.033464
            ],
            [
              -121.374115,
              47.057005
            ],
            [
              -121.379961,
              47.087248
            ],
            [
              -121.381943,
              47.088133
            ],
            [
              -121.392268,
              47.088876
            ],
            [
              -121.405348,
              47.088614
            ],
            [
              -121.411493,
              47.090568
            ],
            [
              -121.416469,
              47.089601
            ],
            [
              -121.423025,
              47.087498
            ],
            [
              -121.432799,
              47.086432
            ],
            [
              -121.44476,
              47.085382
            ],
            [
              -121.448474,
              47.08747
            ],
            [
              -121.45522,
              47.089869
            ],
            [
              -121.462954,
              47.094419
            ],
            [
              -121.468198,
              47.098488
            ],
            [
              -121.473647,
              47.102027
            ],
            [
              -121.476211,
              47.104836
            ],
            [
              -121.478187,
              47.108156
            ],
            [
              -121.480431,
              47.109987
            ],
            [
              -121.484404,
              47.111305
            ],
            [
              -121.487578,
              47.114411
            ],
            [
              -121.489411,
              47.11611
            ],
            [
              -121.492291,
              47.116577
            ],
            [
              -121.494383,
              47.116139
            ],
            [
              -121.498178,
              47.116946
            ],
            [
              -121.504341,
              47.118096
            ],
            [
              -121.506526,
              47.119582
            ],
            [
              -121.509103,
              47.122291
            ],
            [
              -121.514218,
              47.122767
            ],
            [
              -121.519461,
              47.122886
            ],
            [
              -121.524824,
              47.124073
            ],
            [
              -121.532546,
              47.123907
            ],
            [
              -121.539251,
              47.122666
            ],
            [
              -121.544132,
              47.123047
            ],
            [
              -121.552024,
              47.122751
            ],
            [
              -121.556358,
              47.121456
            ],
            [
              -121.559897,
              47.119624
            ],
            [
              -121.564125,
              47.119762
            ],
            [
              -121.569088,
              47.119418
            ],
            [
              -121.573997,
              47.120228
            ],
            [
              -121.576999,
              47.119569
            ],
            [
              -121.580817,
              47.118787
            ],
            [
              -121.583849,
              47.119076
            ],
            [
              -121.589634,
              47.12059
            ],
            [
              -121.593746,
              47.121803
            ],
            [
              -121.594865,
              47.124638
            ],
            [
              -121.598677,
              47.128628
            ],
            [
              -121.603338,
              47.133129
            ],
            [
              -121.60765,
              47.13803
            ],
            [
              -121.611819,
              47.143166
            ],
            [
              -121.617359,
              47.144562
            ],
            [
              -121.619163,
              47.147104
            ],
            [
              -121.623814,
              47.150782
            ],
            [
              -121.627522,
              47.152243
            ],
            [
              -121.631682,
              47.153363
            ],
            [
              -121.636686,
              47.153241
            ],
            [
              -121.643368,
              47.15549
            ],
            [
              -121.649298,
              47.155127
            ],
            [
              -121.653707,
              47.157153
            ],
            [
              -121.658557,
              47.158658
            ],
            [
              -121.661485,
              47.159969
            ],
            [
              -121.66565,
              47.158651
            ],
            [
              -121.668034,
              47.156351
            ],
            [
              -121.673651,
              47.155157
            ],
            [
              -121.67809,
              47.153555
            ],
            [
              -121.682931,
              47.15041
            ],
            [
              -121.687569,
              47.149586
            ],
            [
              -121.69471,
              47.150872
            ],
            [
              -121.702729,
              47.151323
            ],
            [
              -121.713749,
              47.151643
            ],
            [
              -121.721045,
              47.15179
            ],
            [
              -121.726264,
              47.155794
            ],
            [
              -121.731413,
              47.158569
            ],
            [
              -121.74061,
              47.162503
            ],
            [
              -121.742484,
              47.164231
            ],
            [
              -121.745465,
              47.163493
            ],
            [
              -121.747203,
              47.162357
            ],
            [
              -121.752943,
              47.163494
            ],
            [
              -121.756985,
              47.164899
            ],
            [
              -121.761694,
              47.167336
            ],
            [
              -121.768353,
              47.165811
            ],
            [
              -121.773347,
              47.166492
            ],
            [
              -121.777557,
              47.171933
            ],
            [
              -121.781905,
              47.172527
            ],
            [
              -121.787704,
              47.172858
            ],
            [
              -121.791812,
              47.172636
            ],
            [
              -121.7947,
              47.173077
            ],
            [
              -121.798943,
              47.170175
            ],
            [
              -121.804929,
              47.167116
            ],
            [
              -121.810399,
              47.163867
            ],
            [
              -121.812703,
              47.160072
            ],
            [
              -121.8197,
              47.158512
            ],
            [
              -121.825842,
              47.15359
            ],
            [
              -121.832102,
              47.150238
            ],
            [
              -121.835098,
              47.146546
            ],
            [
              -121.83732,
              47.144411
            ],
            [
              -121.8435,
              47.14358
            ],
            [
              -121.847587,
              47.14562
            ],
            [
              -121.854231,
              47.147529
            ],
            [
              -121.857326,
              47.147072
            ],
            [
              -121.861053,
              47.149478
            ],
            [
              -121.86285,
              47.151821
            ],
            [
              -121.867591,
              47.152274
            ],
            [
              -121.870698,
              47.151017
            ],
            [
              -121.876219,
              47.151965
            ],
            [
              -121.878032,
              47.153785
            ],
            [
              -121.886187,
              47.152947
            ],
            [
              -121.895716,
              47.154805
            ],
            [
              -121.903974,
              47.153859
            ],
            [
              -121.907628,
              47.151326
            ],
            [
              -121.914194,
              47.150063
            ],
            [
              -121.923639,
              47.14917
            ],
            [
              -121.927858,
              47.144816
            ],
            [
              -121.931102,
              47.14185
            ],
            [
              -121.936426,
              47.138458
            ],
            [
              -121.940749,
              47.1396
            ],
            [
              -121.949771,
              47.144345
            ],
            [
              -121.951575,
              47.154009
            ],
            [
              -121.960694,
              47.157137
            ],
            [
              -121.96574,
              47.156029
            ],
            [
              -121.971843,
              47.158791
            ],
            [
              -121.974188,
              47.161346
            ],
            [
              -121.979507,
              47.164095
            ],
            [
              -121.984293,
              47.164599
            ],
            [
              -121.987875,
              47.163152
            ],
            [
              -121.992293,
              47.165225
            ],
            [
              -121.993809,
              47.168837
            ],
            [
              -121.993669,
              47.170885
            ],
            [
              -121.997451,
              47.172192
            ],
            [
              -122.000122,
              47.170628
            ],
            [
              -122.006622,
              47.171413
            ],
            [
              -122.007897,
              47.173756
            ],
            [
              -122.014758,
              47.175606
            ],
            [
              -122.021368,
              47.174123
            ],
            [
              -122.028883,
              47.174919
            ],
            [
              -122.034795,
              47.173955
            ],
            [
              -122.041041,
              47.172679
            ],
            [
              -122.04355,
              47.171706
            ],
            [
              -122.048664,
              47.171677
            ],
            [
              -122.051903,
              47.174455
            ],
            [
              -122.054297,
              47.178601
            ],
            [
              -122.060163,
              47.18211
            ],
            [
              -122.063385,
              47.185879
            ],
            [
              -122.069759,
              47.187626
            ],
            [
              -122.079426,
              47.189574
            ],
            [
              -122.087813,
              47.194063
            ],
            [
              -122.094227,
              47.196256
            ],
            [
              -122.098224,
              47.199839
            ],
            [
              -122.103437,
              47.203438
            ],
            [
              -122.108436,
              47.208717
            ],
            [
              -122.11254,
              47.213715
            ],
            [
              -122.113385,
              47.218135
            ],
            [
              -122.115546,
              47.22128
            ],
            [
              -122.116309,
              47.224882
            ],
            [
              -122.115767,
              47.227747
            ],
            [
              -122.118768,
              47.230773
            ],
            [
              -122.121786,
              47.231415
            ],
            [
              -122.123849,
              47.232078
            ],
            [
              -122.124294,
              47.234815
            ],
            [
              -122.12281,
              47.236484
            ],
            [
              -122.124385,
              47.240131
            ],
            [
              -122.127703,
              47.241015
            ],
            [
              -122.13462,
              47.244318
            ],
            [
              -122.137681,
              47.246953
            ],
            [
              -122.13797,
              47.251008
            ],
            [
              -122.137566,
              47.253355
            ],
            [
              -122.13879,
              47.25545
            ],
            [
              -122.143894,
              47.257152
            ],
            [
              -122.165242,
              47.257549
            ],
            [
              -122.197397,
              47.25733
            ],
            [
              -122.216016,
              47.257429
            ],
            [
              -122.226434,
              47.257537
            ],
            [
              -122.229495,
              47.257556
            ],
            [
              -122.229792,
              47.257559
            ],
            [
              -122.294853,
              47.257246
            ],
            [
              -122.331322,
              47.257365
            ],
            [
              -122.33492,
              47.257594
            ],
            [
              -122.33483,
              47.26311
            ],
            [
              -122.41827865949999,
              47.3206113014013
            ],
            [
              -122.423535,
              47.319121
            ],
            [
              -122.443008,
              47.306333
            ],
            [
              -122.444635,
              47.300421
            ],
            [
              -122.432335,
              47.296021
            ],
            [
              -122.424235,
              47.297521
            ],
            [
              -122.413735,
              47.293921
            ],
            [
              -122.409199,
              47.288556
            ],
            [
              -122.418074,
              47.281765
            ],
            [
              -122.429605,
              47.269707
            ],
            [
              -122.43772689118401,
              47.268046450271
            ],
            [
              -122.4442,
              47.266723
            ],
            [
              -122.471652,
              47.277321
            ],
            [
              -122.533338,
              47.31662
            ],
            [
              -122.540238,
              47.31852
            ],
            [
              -122.547408,
              47.317734
            ],
            [
              -122.547747,
              47.316403
            ],
            [
              -122.527586,
              47.291531
            ],
            [
              -122.537999716252,
              47.277160065513094
            ],
            [
              -122.56083278139,
              47.2456504223363
            ],
            [
              -122.561957,
              47.244099
            ],
            [
              -122.580517,
              47.210416
            ],
            [
              -122.590829,
              47.178107
            ],
            [
              -122.614855,
              47.169143
            ],
            [
              -122.63113,
              47.167675
            ],
            [
              -122.635884,
              47.163959
            ],
            [
              -122.640638,
              47.149122
            ],
            [
              -122.636169,
              47.135574
            ],
            [
              -122.645053,
              47.135088
            ],
            [
              -122.650634,
              47.132447
            ],
            [
              -122.67813,
              47.103866
            ],
            [
              -122.693719109151,
              47.103618702381
            ],
            [
              -122.707544,
              47.085332
            ]
          ]
        ],
        [
          [
            [
              -122.842329,
              47.267563
            ],
            [
              -122.843289,
              47.257465
            ],
            [
              -122.832799,
              47.243412
            ],
            [
              -122.771619,
              47.167109
            ],
            [
              -122.763803,
              47.166124
            ],
            [
              -122.75058958653601,
              47.156850873904496
            ],
            [
              -122.742928,
              47.151474
            ],
            [
              -122.722162,
              47.137127
            ],
            [
              -122.711998,
              47.127682
            ],
            [
              -122.702434,
              47.123867
            ],
            [
              -122.694748,
              47.125896
            ],
            [
              -122.692963,
              47.130653
            ],
            [
              -122.684249,
              47.139857
            ],
            [
              -122.675498,
              47.141964
            ],
            [
              -122.673437,
              47.149898
            ],
            [
              -122.673927,
              47.174675
            ],
            [
              -122.67977,
              47.179621
            ],
            [
              -122.677716,
              47.1911
            ],
            [
              -122.657275,
              47.193523
            ],
            [
              -122.644263,
              47.199604
            ],
            [
              -122.6418,
              47.205013
            ],
            [
              -122.648719,
              47.215645
            ],
            [
              -122.659645,
              47.219292
            ],
            [
              -122.667495,
              47.223394
            ],
            [
              -122.679871,
              47.231186
            ],
            [
              -122.685226,
              47.232376
            ],
            [
              -122.704573,
              47.23098
            ],
            [
              -122.708713419771,
              47.2319830566095
            ],
            [
              -122.724824,
              47.235886
            ],
            [
              -122.718124,
              47.250045
            ],
            [
              -122.749621,
              47.276408
            ],
            [
              -122.74525,
              47.297158
            ],
            [
              -122.725738,
              47.33047
            ],
            [
              -122.671486,
              47.366876
            ],
            [
              -122.639126,
              47.377822
            ],
            [
              -122.632463,
              47.376394
            ],
            [
              -122.634823,
              47.370583
            ],
            [
              -122.671256,
              47.343774
            ],
            [
              -122.697378,
              47.283969
            ],
            [
              -122.692426,
              47.280026
            ],
            [
              -122.668571,
              47.270447
            ],
            [
              -122.626997,
              47.235629
            ],
            [
              -122.614236,
              47.220872
            ],
            [
              -122.602539,
              47.217506
            ],
            [
              -122.590487,
              47.227752
            ],
            [
              -122.58886,
              47.237137
            ],
            [
              -122.578211,
              47.254804
            ],
            [
              -122.547521,
              47.285344
            ],
            [
              -122.550134,
              47.290496
            ],
            [
              -122.571239,
              47.315619
            ],
            [
              -122.573739,
              47.318419
            ],
            [
              -122.575985,
              47.32642
            ],
            [
              -122.57134,
              47.327219
            ],
            [
              -122.55584,
              47.347519
            ],
            [
              -122.551536,
              47.35954
            ],
            [
              -122.537044,
              47.375896
            ],
            [
              -122.551136,
              47.394456
            ],
            [
              -122.54612263956201,
              47.4034087828546
            ],
            [
              -122.664071,
              47.403311
            ],
            [
              -122.801199,
              47.403578
            ],
            [
              -122.80106,
              47.358454
            ],
            [
              -122.802831094246,
              47.3534366472272
            ],
            [
              -122.796646,
              47.341654
            ],
            [
              -122.799025,
              47.289306
            ],
            [
              -122.816633,
              47.276457
            ],
            [
              -122.833689,
              47.273751
            ],
            [
              -122.842329,
              47.267563
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "53",
      "COUNTYFP": "001",
      "COUNTYNS": "01531601",
      "AFFGEOID": "0500000US53001",
      "GEOID": "53001",
      "NAME": "Adams",
      "LSAD": "06",
      "ALAND": 4985663373,
      "AWATER": 12499935,
      "County_state": "Adams,53"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -119.368662,
              46.912562
            ],
            [
              -119.369429,
              46.737695
            ],
            [
              -118.44385,
              46.736865
            ],
            [
              -118.210243,
              46.738833
            ],
            [
              -118.189921,
              46.751467
            ],
            [
              -118.161482,
              46.747082
            ],
            [
              -118.156023,
              46.759468
            ],
            [
              -118.126095,
              46.761389
            ],
            [
              -118.0954,
              46.778986
            ],
            [
              -118.047978,
              46.768224
            ],
            [
              -118.035861,
              46.785017
            ],
            [
              -118.015567,
              46.784873
            ],
            [
              -118.019881,
              46.812154
            ],
            [
              -117.997524,
              46.822544
            ],
            [
              -117.992226,
              46.838065
            ],
            [
              -118.00412,
              46.848372
            ],
            [
              -117.970907,
              46.867216
            ],
            [
              -117.967099,
              46.914831
            ],
            [
              -117.96093,
              47.260568
            ],
            [
              -118.079372,
              47.26026
            ],
            [
              -118.100781,
              47.260981
            ],
            [
              -118.979777,
              47.261709
            ],
            [
              -118.982652,
              46.911344
            ],
            [
              -119.368662,
              46.912562
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "53",
      "COUNTYFP": "051",
      "COUNTYNS": "01529157",
      "AFFGEOID": "0500000US53051",
      "GEOID": "53051",
      "NAME": "Pend Oreille",
      "LSAD": "06",
      "ALAND": 3626633160,
      "AWATER": 65385333,
      "County_state": "Pend Oreille,53"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -117.629421,
              48.742634
            ],
            [
              -117.629241,
              48.655852
            ],
            [
              -117.499975,
              48.656187
            ],
            [
              -117.500143,
              48.238115
            ],
            [
              -117.502238,
              48.223362
            ],
            [
              -117.502371,
              48.134708
            ],
            [
              -117.437629,
              48.134688
            ],
            [
              -117.436993,
              48.047207
            ],
            [
              -117.04167682656801,
              48.045464271600395
            ],
            [
              -117.041676,
              48.04556
            ],
            [
              -117.041401,
              48.0855
            ],
            [
              -117.041107,
              48.124904
            ],
            [
              -117.039552,
              48.17396
            ],
            [
              -117.039413,
              48.17725
            ],
            [
              -117.039618,
              48.178142
            ],
            [
              -117.039583,
              48.180313
            ],
            [
              -117.039582,
              48.180853
            ],
            [
              -117.039582,
              48.181124
            ],
            [
              -117.039615,
              48.184015
            ],
            [
              -117.039599,
              48.184387
            ],
            [
              -117.038602,
              48.207939
            ],
            [
              -117.035178,
              48.370878
            ],
            [
              -117.035178,
              48.371221
            ],
            [
              -117.035289,
              48.422732
            ],
            [
              -117.035254,
              48.423144
            ],
            [
              -117.035285,
              48.429816
            ],
            [
              -117.035285,
              48.430113
            ],
            [
              -117.035425,
              48.499914
            ],
            [
              -117.034499,
              48.620769
            ],
            [
              -117.034358,
              48.628523
            ],
            [
              -117.033671,
              48.656902
            ],
            [
              -117.033335,
              48.749921
            ],
            [
              -117.03238462208,
              48.84666529306679
            ],
            [
              -117.032107,
              48.874926
            ],
            [
              -117.032351,
              48.999188
            ],
            [
              -117.126075,
              48.998888
            ],
            [
              -117.268247,
              48.999818
            ],
            [
              -117.268192,
              48.999928
            ],
            [
              -117.429968,
              49.0003644833648
            ],
            [
              -117.430377,
              48.923168
            ],
            [
              -117.498957,
              48.923159
            ],
            [
              -117.498496,
              48.8369
            ],
            [
              -117.563858,
              48.836671
            ],
            [
              -117.563811,
              48.742961
            ],
            [
              -117.629421,
              48.742634
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "53",
      "COUNTYFP": "033",
      "COUNTYNS": "01531933",
      "AFFGEOID": "0500000US53033",
      "GEOID": "53033",
      "NAME": "King",
      "LSAD": "06",
      "ALAND": 5478366437,
      "AWATER": 497909625,
      "County_state": "King,53"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -122.429841,
              47.658919
            ],
            [
              -122.414645,
              47.639766
            ],
            [
              -122.40424,
              47.63392
            ],
            [
              -122.393739,
              47.63102
            ],
            [
              -122.386039,
              47.63172
            ],
            [
              -122.367819,
              47.624213
            ],
            [
              -122.344937,
              47.60912
            ],
            [
              -122.34419097993101,
              47.6077436314842
            ],
            [
              -122.339570996203,
              47.59922
            ],
            [
              -122.339513,
              47.599113
            ],
            [
              -122.342937,
              47.59122
            ],
            [
              -122.344332321504,
              47.5906363742485
            ],
            [
              -122.358238,
              47.58482
            ],
            [
              -122.370167,
              47.583087
            ],
            [
              -122.375421,
              47.585181
            ],
            [
              -122.387139,
              47.59572
            ],
            [
              -122.401839,
              47.58392
            ],
            [
              -122.421139,
              47.57602
            ],
            [
              -122.409839,
              47.56892
            ],
            [
              -122.398338,
              47.55012
            ],
            [
              -122.393938,
              47.52482
            ],
            [
              -122.396538,
              47.51522
            ],
            [
              -122.386637,
              47.50222
            ],
            [
              -122.365236,
              47.48842
            ],
            [
              -122.361336,
              47.481421
            ],
            [
              -122.368036,
              47.459221
            ],
            [
              -122.383136,
              47.450521
            ],
            [
              -122.367036,
              47.447621
            ],
            [
              -122.362025052632,
              47.445221
            ],
            [
              -122.355135,
              47.441921
            ],
            [
              -122.351086549524,
              47.4270956883991
            ],
            [
              -122.348035,
              47.415921
            ],
            [
              -122.335237972506,
              47.4084233274586
            ],
            [
              -122.335234,
              47.408421
            ],
            [
              -122.328434,
              47.400621
            ],
            [
              -122.325734,
              47.391521
            ],
            [
              -122.32518038198401,
              47.365099718424005
            ],
            [
              -122.324833,
              47.348521
            ],
            [
              -122.336934,
              47.341421
            ],
            [
              -122.364168,
              47.335953
            ],
            [
              -122.41827865949999,
              47.3206113014013
            ],
            [
              -122.33483,
              47.26311
            ],
            [
              -122.33492,
              47.257594
            ],
            [
              -122.331322,
              47.257365
            ],
            [
              -122.294853,
              47.257246
            ],
            [
              -122.229792,
              47.257559
            ],
            [
              -122.229495,
              47.257556
            ],
            [
              -122.226434,
              47.257537
            ],
            [
              -122.216016,
              47.257429
            ],
            [
              -122.197397,
              47.25733
            ],
            [
              -122.165242,
              47.257549
            ],
            [
              -122.143894,
              47.257152
            ],
            [
              -122.13879,
              47.25545
            ],
            [
              -122.137566,
              47.253355
            ],
            [
              -122.13797,
              47.251008
            ],
            [
              -122.137681,
              47.246953
            ],
            [
              -122.13462,
              47.244318
            ],
            [
              -122.127703,
              47.241015
            ],
            [
              -122.124385,
              47.240131
            ],
            [
              -122.12281,
              47.236484
            ],
            [
              -122.124294,
              47.234815
            ],
            [
              -122.123849,
              47.232078
            ],
            [
              -122.121786,
              47.231415
            ],
            [
              -122.118768,
              47.230773
            ],
            [
              -122.115767,
              47.227747
            ],
            [
              -122.116309,
              47.224882
            ],
            [
              -122.115546,
              47.22128
            ],
            [
              -122.113385,
              47.218135
            ],
            [
              -122.11254,
              47.213715
            ],
            [
              -122.108436,
              47.208717
            ],
            [
              -122.103437,
              47.203438
            ],
            [
              -122.098224,
              47.199839
            ],
            [
              -122.094227,
              47.196256
            ],
            [
              -122.087813,
              47.194063
            ],
            [
              -122.079426,
              47.189574
            ],
            [
              -122.069759,
              47.187626
            ],
            [
              -122.063385,
              47.185879
            ],
            [
              -122.060163,
              47.18211
            ],
            [
              -122.054297,
              47.178601
            ],
            [
              -122.051903,
              47.174455
            ],
            [
              -122.048664,
              47.171677
            ],
            [
              -122.04355,
              47.171706
            ],
            [
              -122.041041,
              47.172679
            ],
            [
              -122.034795,
              47.173955
            ],
            [
              -122.028883,
              47.174919
            ],
            [
              -122.021368,
              47.174123
            ],
            [
              -122.014758,
              47.175606
            ],
            [
              -122.007897,
              47.173756
            ],
            [
              -122.006622,
              47.171413
            ],
            [
              -122.000122,
              47.170628
            ],
            [
              -121.997451,
              47.172192
            ],
            [
              -121.993669,
              47.170885
            ],
            [
              -121.993809,
              47.168837
            ],
            [
              -121.992293,
              47.165225
            ],
            [
              -121.987875,
              47.163152
            ],
            [
              -121.984293,
              47.164599
            ],
            [
              -121.979507,
              47.164095
            ],
            [
              -121.974188,
              47.161346
            ],
            [
              -121.971843,
              47.158791
            ],
            [
              -121.96574,
              47.156029
            ],
            [
              -121.960694,
              47.157137
            ],
            [
              -121.951575,
              47.154009
            ],
            [
              -121.949771,
              47.144345
            ],
            [
              -121.940749,
              47.1396
            ],
            [
              -121.936426,
              47.138458
            ],
            [
              -121.931102,
              47.14185
            ],
            [
              -121.927858,
              47.144816
            ],
            [
              -121.923639,
              47.14917
            ],
            [
              -121.914194,
              47.150063
            ],
            [
              -121.907628,
              47.151326
            ],
            [
              -121.903974,
              47.153859
            ],
            [
              -121.895716,
              47.154805
            ],
            [
              -121.886187,
              47.152947
            ],
            [
              -121.878032,
              47.153785
            ],
            [
              -121.876219,
              47.151965
            ],
            [
              -121.870698,
              47.151017
            ],
            [
              -121.867591,
              47.152274
            ],
            [
              -121.86285,
              47.151821
            ],
            [
              -121.861053,
              47.149478
            ],
            [
              -121.857326,
              47.147072
            ],
            [
              -121.854231,
              47.147529
            ],
            [
              -121.847587,
              47.14562
            ],
            [
              -121.8435,
              47.14358
            ],
            [
              -121.83732,
              47.144411
            ],
            [
              -121.835098,
              47.146546
            ],
            [
              -121.832102,
              47.150238
            ],
            [
              -121.825842,
              47.15359
            ],
            [
              -121.8197,
              47.158512
            ],
            [
              -121.812703,
              47.160072
            ],
            [
              -121.810399,
              47.163867
            ],
            [
              -121.804929,
              47.167116
            ],
            [
              -121.798943,
              47.170175
            ],
            [
              -121.7947,
              47.173077
            ],
            [
              -121.791812,
              47.172636
            ],
            [
              -121.787704,
              47.172858
            ],
            [
              -121.781905,
              47.172527
            ],
            [
              -121.777557,
              47.171933
            ],
            [
              -121.773347,
              47.166492
            ],
            [
              -121.768353,
              47.165811
            ],
            [
              -121.761694,
              47.167336
            ],
            [
              -121.756985,
              47.164899
            ],
            [
              -121.752943,
              47.163494
            ],
            [
              -121.747203,
              47.162357
            ],
            [
              -121.745465,
              47.163493
            ],
            [
              -121.742484,
              47.164231
            ],
            [
              -121.74061,
              47.162503
            ],
            [
              -121.731413,
              47.158569
            ],
            [
              -121.726264,
              47.155794
            ],
            [
              -121.721045,
              47.15179
            ],
            [
              -121.713749,
              47.151643
            ],
            [
              -121.702729,
              47.151323
            ],
            [
              -121.69471,
              47.150872
            ],
            [
              -121.687569,
              47.149586
            ],
            [
              -121.682931,
              47.15041
            ],
            [
              -121.67809,
              47.153555
            ],
            [
              -121.673651,
              47.155157
            ],
            [
              -121.668034,
              47.156351
            ],
            [
              -121.66565,
              47.158651
            ],
            [
              -121.661485,
              47.159969
            ],
            [
              -121.658557,
              47.158658
            ],
            [
              -121.653707,
              47.157153
            ],
            [
              -121.649298,
              47.155127
            ],
            [
              -121.643368,
              47.15549
            ],
            [
              -121.636686,
              47.153241
            ],
            [
              -121.631682,
              47.153363
            ],
            [
              -121.627522,
              47.152243
            ],
            [
              -121.623814,
              47.150782
            ],
            [
              -121.619163,
              47.147104
            ],
            [
              -121.617359,
              47.144562
            ],
            [
              -121.611819,
              47.143166
            ],
            [
              -121.60765,
              47.13803
            ],
            [
              -121.603338,
              47.133129
            ],
            [
              -121.598677,
              47.128628
            ],
            [
              -121.594865,
              47.124638
            ],
            [
              -121.593746,
              47.121803
            ],
            [
              -121.589634,
              47.12059
            ],
            [
              -121.583849,
              47.119076
            ],
            [
              -121.580817,
              47.118787
            ],
            [
              -121.576999,
              47.119569
            ],
            [
              -121.573997,
              47.120228
            ],
            [
              -121.569088,
              47.119418
            ],
            [
              -121.564125,
              47.119762
            ],
            [
              -121.559897,
              47.119624
            ],
            [
              -121.556358,
              47.121456
            ],
            [
              -121.552024,
              47.122751
            ],
            [
              -121.544132,
              47.123047
            ],
            [
              -121.539251,
              47.122666
            ],
            [
              -121.532546,
              47.123907
            ],
            [
              -121.524824,
              47.124073
            ],
            [
              -121.519461,
              47.122886
            ],
            [
              -121.514218,
              47.122767
            ],
            [
              -121.509103,
              47.122291
            ],
            [
              -121.506526,
              47.119582
            ],
            [
              -121.504341,
              47.118096
            ],
            [
              -121.498178,
              47.116946
            ],
            [
              -121.494383,
              47.116139
            ],
            [
              -121.492291,
              47.116577
            ],
            [
              -121.489411,
              47.11611
            ],
            [
              -121.487578,
              47.114411
            ],
            [
              -121.484404,
              47.111305
            ],
            [
              -121.480431,
              47.109987
            ],
            [
              -121.478187,
              47.108156
            ],
            [
              -121.476211,
              47.104836
            ],
            [
              -121.473647,
              47.102027
            ],
            [
              -121.468198,
              47.098488
            ],
            [
              -121.462954,
              47.094419
            ],
            [
              -121.45522,
              47.089869
            ],
            [
              -121.448474,
              47.08747
            ],
            [
              -121.44476,
              47.085382
            ],
            [
              -121.432799,
              47.086432
            ],
            [
              -121.423025,
              47.087498
            ],
            [
              -121.416469,
              47.089601
            ],
            [
              -121.411493,
              47.090568
            ],
            [
              -121.405348,
              47.088614
            ],
            [
              -121.392268,
              47.088876
            ],
            [
              -121.381943,
              47.088133
            ],
            [
              -121.379961,
              47.087248
            ],
            [
              -121.379682,
              47.087495
            ],
            [
              -121.402526,
              47.098013
            ],
            [
              -121.408081,
              47.1181
            ],
            [
              -121.393968,
              47.132762
            ],
            [
              -121.364677,
              47.141759
            ],
            [
              -121.335004,
              47.132684
            ],
            [
              -121.303355,
              47.138942
            ],
            [
              -121.297639,
              47.148557
            ],
            [
              -121.317748,
              47.17434
            ],
            [
              -121.306297,
              47.187711
            ],
            [
              -121.3176,
              47.212131
            ],
            [
              -121.365138,
              47.224563
            ],
            [
              -121.360816,
              47.240403
            ],
            [
              -121.339767,
              47.247199
            ],
            [
              -121.33585,
              47.260562
            ],
            [
              -121.341203,
              47.281261
            ],
            [
              -121.411709,
              47.289949
            ],
            [
              -121.427492,
              47.289075
            ],
            [
              -121.432682,
              47.304955
            ],
            [
              -121.444362,
              47.308247
            ],
            [
              -121.430578,
              47.334617
            ],
            [
              -121.444655,
              47.343761
            ],
            [
              -121.465979,
              47.367631
            ],
            [
              -121.437788,
              47.371967
            ],
            [
              -121.426212,
              47.389592
            ],
            [
              -121.427417,
              47.420678
            ],
            [
              -121.410878,
              47.424539
            ],
            [
              -121.384996,
              47.434601
            ],
            [
              -121.37912,
              47.454411
            ],
            [
              -121.338706,
              47.476377
            ],
            [
              -121.303135,
              47.486023
            ],
            [
              -121.283693,
              47.518757
            ],
            [
              -121.263363,
              47.523424
            ],
            [
              -121.235394,
              47.544649
            ],
            [
              -121.217039,
              47.569752
            ],
            [
              -121.176577,
              47.566932
            ],
            [
              -121.17225,
              47.590054
            ],
            [
              -121.113569,
              47.597288
            ],
            [
              -121.133697,
              47.645605
            ],
            [
              -121.121584,
              47.685213
            ],
            [
              -121.105513,
              47.688815
            ],
            [
              -121.066005,
              47.713603
            ],
            [
              -121.087367,
              47.728497
            ],
            [
              -121.078995,
              47.73919
            ],
            [
              -121.094018,
              47.758184
            ],
            [
              -121.110858,
              47.758417
            ],
            [
              -121.119179,
              47.779933
            ],
            [
              -121.455535,
              47.780576
            ],
            [
              -121.456287,
              47.778415
            ],
            [
              -121.584541,
              47.777054
            ],
            [
              -121.927821,
              47.776962
            ],
            [
              -121.991775,
              47.775349
            ],
            [
              -122.023486,
              47.775584
            ],
            [
              -122.166746,
              47.776249
            ],
            [
              -122.227607,
              47.776745
            ],
            [
              -122.243612,
              47.776959
            ],
            [
              -122.271033,
              47.777095
            ],
            [
              -122.297594,
              47.77742
            ],
            [
              -122.317652,
              47.777618
            ],
            [
              -122.327392,
              47.777646
            ],
            [
              -122.39642167179,
              47.777927396164095
            ],
            [
              -122.394442,
              47.772219
            ],
            [
              -122.380241,
              47.758519
            ],
            [
              -122.382641,
              47.749119
            ],
            [
              -122.37314,
              47.729219
            ],
            [
              -122.37644,
              47.716519
            ],
            [
              -122.37870484261201,
              47.7123290411676
            ],
            [
              -122.38044,
              47.709119
            ],
            [
              -122.393248,
              47.701602
            ],
            [
              -122.403841,
              47.689419
            ],
            [
              -122.407841,
              47.680119
            ],
            [
              -122.429841,
              47.658919
            ]
          ]
        ],
        [
          [
            [
              -122.526029,
              47.358907
            ],
            [
              -122.528129,
              47.345542
            ],
            [
              -122.51885,
              47.33332
            ],
            [
              -122.504913,
              47.33068
            ],
            [
              -122.493123,
              47.330253
            ],
            [
              -122.491237,
              47.335172
            ],
            [
              -122.469702,
              47.344623
            ],
            [
              -122.457494,
              47.342567
            ],
            [
              -122.453998,
              47.343337
            ],
            [
              -122.448399,
              47.354987
            ],
            [
              -122.437809,
              47.365606
            ],
            [
              -122.401767,
              47.381325
            ],
            [
              -122.378482,
              47.38533
            ],
            [
              -122.373629,
              47.388718
            ],
            [
              -122.395054,
              47.399277
            ],
            [
              -122.427327,
              47.402129
            ],
            [
              -122.437656,
              47.407424
            ],
            [
              -122.439931,
              47.417007
            ],
            [
              -122.439415,
              47.458633
            ],
            [
              -122.433386,
              47.46643
            ],
            [
              -122.460027,
              47.48686
            ],
            [
              -122.460442,
              47.493764
            ],
            [
              -122.452399,
              47.503471
            ],
            [
              -122.474684,
              47.511068
            ],
            [
              -122.482739,
              47.483133
            ],
            [
              -122.497862,
              47.475915
            ],
            [
              -122.513328,
              47.449106
            ],
            [
              -122.514703,
              47.414048
            ],
            [
              -122.526733,
              47.398581
            ],
            [
              -122.517797,
              47.368678
            ],
            [
              -122.526029,
              47.358907
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "53",
      "COUNTYFP": "007",
      "COUNTYNS": "01531932",
      "AFFGEOID": "0500000US53007",
      "GEOID": "53007",
      "NAME": "Chelan",
      "LSAD": "06",
      "ALAND": 7565788660,
      "AWATER": 189679746,
      "County_state": "Chelan,53"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -121.178123,
              47.902606
            ],
            [
              -121.176732,
              47.894996
            ],
            [
              -121.175724,
              47.888153
            ],
            [
              -121.171014,
              47.883377
            ],
            [
              -121.167333,
              47.88
            ],
            [
              -121.161834,
              47.880344
            ],
            [
              -121.159812,
              47.881132
            ],
            [
              -121.157667,
              47.875639
            ],
            [
              -121.153065,
              47.869941
            ],
            [
              -121.150912,
              47.853933
            ],
            [
              -121.141991,
              47.843052
            ],
            [
              -121.121193,
              47.834799
            ],
            [
              -121.095808,
              47.832692
            ],
            [
              -121.082613,
              47.833678
            ],
            [
              -121.074509,
              47.822836
            ],
            [
              -121.082752,
              47.811803
            ],
            [
              -121.098511,
              47.797933
            ],
            [
              -121.116241,
              47.786872
            ],
            [
              -121.119179,
              47.779933
            ],
            [
              -121.110858,
              47.758417
            ],
            [
              -121.094018,
              47.758184
            ],
            [
              -121.078995,
              47.73919
            ],
            [
              -121.087367,
              47.728497
            ],
            [
              -121.066005,
              47.713603
            ],
            [
              -121.105513,
              47.688815
            ],
            [
              -121.121584,
              47.685213
            ],
            [
              -121.133697,
              47.645605
            ],
            [
              -121.113569,
              47.597288
            ],
            [
              -121.113445,
              47.597065
            ],
            [
              -121.11253,
              47.596306
            ],
            [
              -121.111639,
              47.595843
            ],
            [
              -121.11098,
              47.595065
            ],
            [
              -121.110711,
              47.594084
            ],
            [
              -121.110657,
              47.592935
            ],
            [
              -121.110175,
              47.592412
            ],
            [
              -121.109083,
              47.592246
            ],
            [
              -121.108785,
              47.592437
            ],
            [
              -121.107935,
              47.592202
            ],
            [
              -121.107517,
              47.591715
            ],
            [
              -121.106785,
              47.590818
            ],
            [
              -121.105642,
              47.590136
            ],
            [
              -121.104322,
              47.58963
            ],
            [
              -121.102804,
              47.589576
            ],
            [
              -121.101654,
              47.590369
            ],
            [
              -121.100145,
              47.590492
            ],
            [
              -121.098821,
              47.590112
            ],
            [
              -121.097388,
              47.589777
            ],
            [
              -121.096053,
              47.589308
            ],
            [
              -121.095375,
              47.588631
            ],
            [
              -121.094889,
              47.587584
            ],
            [
              -121.09393,
              47.586731
            ],
            [
              -121.092732,
              47.586161
            ],
            [
              -121.092144,
              47.585287
            ],
            [
              -121.092228,
              47.584268
            ],
            [
              -121.092324,
              47.583244
            ],
            [
              -121.092396,
              47.582218
            ],
            [
              -121.091555,
              47.581467
            ],
            [
              -121.090665,
              47.58075
            ],
            [
              -121.091099,
              47.579748
            ],
            [
              -121.090776,
              47.578765
            ],
            [
              -121.089811,
              47.577996
            ],
            [
              -121.089254,
              47.577143
            ],
            [
              -121.088052,
              47.576257
            ],
            [
              -121.087585,
              47.575279
            ],
            [
              -121.08746,
              47.574664
            ],
            [
              -121.086729,
              47.574143
            ],
            [
              -121.0863,
              47.574112
            ],
            [
              -121.08633,
              47.573755
            ],
            [
              -121.087032,
              47.573482
            ],
            [
              -121.088265,
              47.572684
            ],
            [
              -121.089345,
              47.571996
            ],
            [
              -121.090081,
              47.570999
            ],
            [
              -121.09017,
              47.569989
            ],
            [
              -121.089238,
              47.568932
            ],
            [
              -121.08869,
              47.568052
            ],
            [
              -121.088034,
              47.567126
            ],
            [
              -121.087395,
              47.566224
            ],
            [
              -121.086337,
              47.565594
            ],
            [
              -121.085255,
              47.564707
            ],
            [
              -121.084205,
              47.563736
            ],
            [
              -121.08319,
              47.562974
            ],
            [
              -121.082142,
              47.562197
            ],
            [
              -121.081663,
              47.561377
            ],
            [
              -121.080892,
              47.56058
            ],
            [
              -121.0799,
              47.559954
            ],
            [
              -121.078738,
              47.559323
            ],
            [
              -121.078562,
              47.558313
            ],
            [
              -121.078318,
              47.557295
            ],
            [
              -121.077732,
              47.556368
            ],
            [
              -121.077707,
              47.555389
            ],
            [
              -121.076896,
              47.554607
            ],
            [
              -121.076347,
              47.553726
            ],
            [
              -121.076086,
              47.552783
            ],
            [
              -121.075739,
              47.551782
            ],
            [
              -121.075343,
              47.551222
            ],
            [
              -121.074426,
              47.550747
            ],
            [
              -121.073457,
              47.550897
            ],
            [
              -121.072111,
              47.551165
            ],
            [
              -121.070664,
              47.550866
            ],
            [
              -121.069553,
              47.550161
            ],
            [
              -121.068314,
              47.549585
            ],
            [
              -121.066931,
              47.549167
            ],
            [
              -121.065725,
              47.548532
            ],
            [
              -121.065246,
              47.547584
            ],
            [
              -121.064242,
              47.546815
            ],
            [
              -121.062859,
              47.546398
            ],
            [
              -121.061656,
              47.545899
            ],
            [
              -121.060677,
              47.545146
            ],
            [
              -121.060311,
              47.544162
            ],
            [
              -121.060143,
              47.543157
            ],
            [
              -121.060296,
              47.542557
            ],
            [
              -121.060576,
              47.542184
            ],
            [
              -121.059701,
              47.542031
            ],
            [
              -121.058471,
              47.542008
            ],
            [
              -121.057504,
              47.540937
            ],
            [
              -121.056514,
              47.540033
            ],
            [
              -121.055451,
              47.539401
            ],
            [
              -121.054104,
              47.538137
            ],
            [
              -121.052782,
              47.537917
            ],
            [
              -121.051572,
              47.537309
            ],
            [
              -121.050508,
              47.536596
            ],
            [
              -121.049407,
              47.535878
            ],
            [
              -121.048394,
              47.535317
            ],
            [
              -121.047922,
              47.534792
            ],
            [
              -121.047473,
              47.534333
            ],
            [
              -121.046623,
              47.534552
            ],
            [
              -121.045568,
              47.535273
            ],
            [
              -121.044086,
              47.535124
            ],
            [
              -121.042706,
              47.535008
            ],
            [
              -121.04143,
              47.535225
            ],
            [
              -121.040242,
              47.535482
            ],
            [
              -121.039148,
              47.534886
            ],
            [
              -121.037959,
              47.534365
            ],
            [
              -121.036644,
              47.533863
            ],
            [
              -121.035255,
              47.53345
            ],
            [
              -121.033952,
              47.532921
            ],
            [
              -121.032735,
              47.532308
            ],
            [
              -121.031447,
              47.531774
            ],
            [
              -121.030368,
              47.531182
            ],
            [
              -121.029514,
              47.530333
            ],
            [
              -121.028196,
              47.529825
            ],
            [
              -121.02701,
              47.529219
            ],
            [
              -121.025793,
              47.52881
            ],
            [
              -121.025671,
              47.527767
            ],
            [
              -121.02516,
              47.526801
            ],
            [
              -121.025069,
              47.525779
            ],
            [
              -121.024688,
              47.524832
            ],
            [
              -121.023709,
              47.523879
            ],
            [
              -121.022289,
              47.523555
            ],
            [
              -121.020981,
              47.523171
            ],
            [
              -121.019785,
              47.522541
            ],
            [
              -121.018339,
              47.522275
            ],
            [
              -121.016983,
              47.521868
            ],
            [
              -121.015365,
              47.521854
            ],
            [
              -121.014005,
              47.521488
            ],
            [
              -121.012591,
              47.521648
            ],
            [
              -121.011089,
              47.521756
            ],
            [
              -121.00971,
              47.521556
            ],
            [
              -121.008407,
              47.521041
            ],
            [
              -121.007,
              47.521177
            ],
            [
              -121.005917,
              47.520492
            ],
            [
              -121.004561,
              47.520225
            ],
            [
              -121.003259,
              47.519796
            ],
            [
              -121.001402,
              47.519354
            ],
            [
              -120.999903,
              47.519107
            ],
            [
              -120.998422,
              47.518906
            ],
            [
              -120.996949,
              47.518645
            ],
            [
              -120.996504,
              47.517816
            ],
            [
              -120.996375,
              47.516793
            ],
            [
              -120.996016,
              47.515806
            ],
            [
              -120.994919,
              47.5151
            ],
            [
              -120.993751,
              47.514449
            ],
            [
              -120.993079,
              47.513532
            ],
            [
              -120.99248,
              47.5126
            ],
            [
              -120.991616,
              47.511756
            ],
            [
              -120.99133,
              47.510771
            ],
            [
              -120.992212,
              47.509947
            ],
            [
              -120.992129,
              47.508972
            ],
            [
              -120.993236,
              47.508334
            ],
            [
              -120.994149,
              47.507578
            ],
            [
              -120.994661,
              47.506669
            ],
            [
              -120.994587,
              47.505659
            ],
            [
              -120.994826,
              47.504647
            ],
            [
              -120.993907,
              47.503927
            ],
            [
              -120.992745,
              47.503289
            ],
            [
              -120.99188,
              47.502415
            ],
            [
              -120.991589,
              47.501343
            ],
            [
              -120.989166,
              47.499202
            ],
            [
              -120.988394,
              47.498329
            ],
            [
              -120.987576,
              47.497508
            ],
            [
              -120.986366,
              47.496871
            ],
            [
              -120.986249,
              47.495889
            ],
            [
              -120.985356,
              47.495055
            ],
            [
              -120.984471,
              47.494227
            ],
            [
              -120.983473,
              47.493463
            ],
            [
              -120.982641,
              47.492667
            ],
            [
              -120.981523,
              47.491979
            ],
            [
              -120.980676,
              47.491151
            ],
            [
              -120.979472,
              47.490525
            ],
            [
              -120.977941,
              47.490515
            ],
            [
              -120.976575,
              47.490182
            ],
            [
              -120.975428,
              47.489533
            ],
            [
              -120.974142,
              47.488998
            ],
            [
              -120.972839,
              47.488476
            ],
            [
              -120.97136,
              47.488387
            ],
            [
              -120.969847,
              47.488335
            ],
            [
              -120.968465,
              47.488152
            ],
            [
              -120.96732,
              47.487478
            ],
            [
              -120.965968,
              47.486761
            ],
            [
              -120.964646,
              47.486262
            ],
            [
              -120.963533,
              47.485564
            ],
            [
              -120.96239,
              47.484895
            ],
            [
              -120.961072,
              47.484574
            ],
            [
              -120.959691,
              47.484374
            ],
            [
              -120.958975,
              47.483939
            ],
            [
              -120.958212,
              47.483201
            ],
            [
              -120.958772,
              47.482244
            ],
            [
              -120.959021,
              47.481309
            ],
            [
              -120.95822,
              47.480452
            ],
            [
              -120.956907,
              47.479986
            ],
            [
              -120.956289,
              47.479039
            ],
            [
              -120.955607,
              47.478119
            ],
            [
              -120.954824,
              47.47725
            ],
            [
              -120.953695,
              47.476646
            ],
            [
              -120.95219,
              47.476551
            ],
            [
              -120.950944,
              47.476101
            ],
            [
              -120.950274,
              47.475289
            ],
            [
              -120.949406,
              47.474478
            ],
            [
              -120.948374,
              47.473742
            ],
            [
              -120.947179,
              47.473121
            ],
            [
              -120.946689,
              47.472766
            ],
            [
              -120.946746,
              47.47202
            ],
            [
              -120.947266,
              47.47151
            ],
            [
              -120.94819,
              47.470783
            ],
            [
              -120.94915,
              47.469988
            ],
            [
              -120.949401,
              47.469059
            ],
            [
              -120.950208,
              47.468186
            ],
            [
              -120.950964,
              47.467394
            ],
            [
              -120.95177,
              47.46658
            ],
            [
              -120.951757,
              47.465592
            ],
            [
              -120.951995,
              47.464577
            ],
            [
              -120.95214,
              47.46356
            ],
            [
              -120.952633,
              47.463038
            ],
            [
              -120.952827,
              47.462441
            ],
            [
              -120.952254,
              47.461958
            ],
            [
              -120.950905,
              47.461522
            ],
            [
              -120.949238,
              47.461245
            ],
            [
              -120.947895,
              47.460949
            ],
            [
              -120.946498,
              47.460802
            ],
            [
              -120.944988,
              47.460713
            ],
            [
              -120.943505,
              47.460499
            ],
            [
              -120.942047,
              47.460219
            ],
            [
              -120.940748,
              47.459733
            ],
            [
              -120.939234,
              47.459656
            ],
            [
              -120.937738,
              47.459526
            ],
            [
              -120.936225,
              47.459519
            ],
            [
              -120.934713,
              47.459436
            ],
            [
              -120.933246,
              47.459233
            ],
            [
              -120.931983,
              47.458672
            ],
            [
              -120.930924,
              47.457934
            ],
            [
              -120.930757,
              47.457094
            ],
            [
              -120.930903,
              47.456083
            ],
            [
              -120.930739,
              47.455061
            ],
            [
              -120.930877,
              47.454039
            ],
            [
              -120.930569,
              47.453055
            ],
            [
              -120.929454,
              47.451877
            ],
            [
              -120.92728,
              47.450499
            ],
            [
              -120.926405,
              47.449749
            ],
            [
              -120.925268,
              47.449258
            ],
            [
              -120.923873,
              47.448874
            ],
            [
              -120.923048,
              47.448607
            ],
            [
              -120.922679,
              47.447948
            ],
            [
              -120.92296,
              47.447357
            ],
            [
              -120.923476,
              47.446464
            ],
            [
              -120.923831,
              47.44547
            ],
            [
              -120.924099,
              47.44454
            ],
            [
              -120.92446,
              47.443602
            ],
            [
              -120.924535,
              47.442663
            ],
            [
              -120.923811,
              47.441847
            ],
            [
              -120.923939,
              47.440823
            ],
            [
              -120.924268,
              47.43982
            ],
            [
              -120.924344,
              47.438809
            ],
            [
              -120.924467,
              47.437783
            ],
            [
              -120.924063,
              47.436876
            ],
            [
              -120.924041,
              47.435851
            ],
            [
              -120.924486,
              47.434891
            ],
            [
              -120.924292,
              47.433993
            ],
            [
              -120.923646,
              47.433115
            ],
            [
              -120.922417,
              47.432548
            ],
            [
              -120.921292,
              47.43186
            ],
            [
              -120.920363,
              47.43114
            ],
            [
              -120.919244,
              47.430488
            ],
            [
              -120.918329,
              47.429683
            ],
            [
              -120.91715,
              47.429067
            ],
            [
              -120.915772,
              47.428665
            ],
            [
              -120.914474,
              47.428152
            ],
            [
              -120.913149,
              47.428062
            ],
            [
              -120.911969,
              47.427462
            ],
            [
              -120.91096,
              47.426729
            ],
            [
              -120.909782,
              47.426118
            ],
            [
              -120.908619,
              47.425471
            ],
            [
              -120.90716,
              47.425241
            ],
            [
              -120.905796,
              47.425676
            ],
            [
              -120.904776,
              47.426416
            ],
            [
              -120.903372,
              47.426816
            ],
            [
              -120.901892,
              47.427009
            ],
            [
              -120.900437,
              47.426711
            ],
            [
              -120.899032,
              47.426331
            ],
            [
              -120.897544,
              47.426191
            ],
            [
              -120.89607,
              47.42623
            ],
            [
              -120.894212,
              47.426156
            ],
            [
              -120.892868,
              47.426573
            ],
            [
              -120.892088,
              47.427379
            ],
            [
              -120.891031,
              47.427474
            ],
            [
              -120.88993,
              47.426904
            ],
            [
              -120.888789,
              47.426229
            ],
            [
              -120.887595,
              47.42561
            ],
            [
              -120.886814,
              47.424739
            ],
            [
              -120.88612,
              47.423852
            ],
            [
              -120.885375,
              47.42293
            ],
            [
              -120.884246,
              47.422269
            ],
            [
              -120.88299,
              47.421692
            ],
            [
              -120.881896,
              47.421114
            ],
            [
              -120.880574,
              47.420592
            ],
            [
              -120.879235,
              47.420178
            ],
            [
              -120.878104,
              47.419504
            ],
            [
              -120.876932,
              47.41842
            ],
            [
              -120.876163,
              47.417543
            ],
            [
              -120.874942,
              47.416862
            ],
            [
              -120.873768,
              47.416213
            ],
            [
              -120.872662,
              47.415512
            ],
            [
              -120.871225,
              47.415324
            ],
            [
              -120.86976,
              47.41562
            ],
            [
              -120.868394,
              47.416058
            ],
            [
              -120.867211,
              47.416732
            ],
            [
              -120.866701,
              47.418039
            ],
            [
              -120.865449,
              47.418753
            ],
            [
              -120.86349,
              47.419536
            ],
            [
              -120.862362,
              47.420325
            ],
            [
              -120.861209,
              47.420459
            ],
            [
              -120.860045,
              47.419797
            ],
            [
              -120.858648,
              47.419413
            ],
            [
              -120.857654,
              47.418726
            ],
            [
              -120.856619,
              47.417999
            ],
            [
              -120.855687,
              47.417199
            ],
            [
              -120.854873,
              47.416351
            ],
            [
              -120.853556,
              47.415851
            ],
            [
              -120.852269,
              47.415318
            ],
            [
              -120.851213,
              47.41458
            ],
            [
              -120.850093,
              47.413887
            ],
            [
              -120.848393,
              47.413307
            ],
            [
              -120.846657,
              47.412937
            ],
            [
              -120.844513,
              47.412009
            ],
            [
              -120.842944,
              47.41173
            ],
            [
              -120.841474,
              47.412383
            ],
            [
              -120.839311,
              47.413609
            ],
            [
              -120.838102,
              47.414441
            ],
            [
              -120.837243,
              47.415285
            ],
            [
              -120.837037,
              47.416297
            ],
            [
              -120.837179,
              47.417323
            ],
            [
              -120.836644,
              47.418273
            ],
            [
              -120.835366,
              47.419054
            ],
            [
              -120.834119,
              47.419644
            ],
            [
              -120.833212,
              47.42045
            ],
            [
              -120.832303,
              47.421348
            ],
            [
              -120.830963,
              47.422466
            ],
            [
              -120.829726,
              47.423169
            ],
            [
              -120.829091,
              47.423625
            ],
            [
              -120.827878,
              47.423695
            ],
            [
              -120.826725,
              47.423264
            ],
            [
              -120.824592,
              47.422643
            ],
            [
              -120.822714,
              47.422155
            ],
            [
              -120.820752,
              47.421303
            ],
            [
              -120.819203,
              47.420796
            ],
            [
              -120.81753,
              47.420612
            ],
            [
              -120.81593,
              47.42051
            ],
            [
              -120.814594,
              47.42002
            ],
            [
              -120.812901,
              47.419725
            ],
            [
              -120.811295,
              47.419909
            ],
            [
              -120.809118,
              47.420205
            ],
            [
              -120.807717,
              47.420595
            ],
            [
              -120.806358,
              47.421049
            ],
            [
              -120.805226,
              47.421719
            ],
            [
              -120.804401,
              47.422668
            ],
            [
              -120.803731,
              47.423076
            ],
            [
              -120.802678,
              47.42272
            ],
            [
              -120.802196,
              47.422207
            ],
            [
              -120.801802,
              47.421077
            ],
            [
              -120.800999,
              47.420216
            ],
            [
              -120.800266,
              47.419322
            ],
            [
              -120.79993,
              47.418387
            ],
            [
              -120.799396,
              47.417428
            ],
            [
              -120.798537,
              47.416586
            ],
            [
              -120.797533,
              47.415813
            ],
            [
              -120.796413,
              47.415122
            ],
            [
              -120.79524,
              47.414466
            ],
            [
              -120.79424,
              47.413749
            ],
            [
              -120.79425,
              47.412708
            ],
            [
              -120.794042,
              47.410662
            ],
            [
              -120.792368,
              47.407412
            ],
            [
              -120.790262,
              47.406026
            ],
            [
              -120.787749,
              47.405155
            ],
            [
              -120.786437,
              47.403668
            ],
            [
              -120.785298,
              47.401359
            ],
            [
              -120.783081,
              47.399514
            ],
            [
              -120.78148,
              47.399429
            ],
            [
              -120.779581,
              47.399614
            ],
            [
              -120.777979,
              47.399734
            ],
            [
              -120.775017,
              47.399803
            ],
            [
              -120.77143,
              47.39926
            ],
            [
              -120.76709,
              47.399085
            ],
            [
              -120.762911,
              47.398024
            ],
            [
              -120.758482,
              47.39763
            ],
            [
              -120.756702,
              47.397404
            ],
            [
              -120.755414,
              47.395966
            ],
            [
              -120.752591,
              47.395086
            ],
            [
              -120.750141,
              47.394475
            ],
            [
              -120.747003,
              47.393751
            ],
            [
              -120.74403,
              47.394133
            ],
            [
              -120.741846,
              47.393643
            ],
            [
              -120.739447,
              47.3933
            ],
            [
              -120.735468,
              47.393771
            ],
            [
              -120.73313,
              47.393043
            ],
            [
              -120.731929,
              47.391969
            ],
            [
              -120.731508,
              47.390992
            ],
            [
              -120.729729,
              47.390103
            ],
            [
              -120.728621,
              47.389552
            ],
            [
              -120.727652,
              47.388769
            ],
            [
              -120.726582,
              47.388062
            ],
            [
              -120.726594,
              47.387077
            ],
            [
              -120.72728,
              47.385931
            ],
            [
              -120.727645,
              47.384933
            ],
            [
              -120.72743,
              47.384054
            ],
            [
              -120.726706,
              47.383245
            ],
            [
              -120.727071,
              47.381987
            ],
            [
              -120.727169,
              47.380565
            ],
            [
              -120.726125,
              47.379578
            ],
            [
              -120.72534,
              47.378629
            ],
            [
              -120.724559,
              47.377591
            ],
            [
              -120.724206,
              47.376385
            ],
            [
              -120.723554,
              47.37525
            ],
            [
              -120.723096,
              47.374152
            ],
            [
              -120.723182,
              47.373136
            ],
            [
              -120.722671,
              47.372312
            ],
            [
              -120.721675,
              47.371562
            ],
            [
              -120.721336,
              47.370571
            ],
            [
              -120.720637,
              47.369755
            ],
            [
              -120.719324,
              47.369242
            ],
            [
              -120.717995,
              47.368786
            ],
            [
              -120.716505,
              47.368603
            ],
            [
              -120.715091,
              47.368236
            ],
            [
              -120.713331,
              47.367966
            ],
            [
              -120.711743,
              47.367778
            ],
            [
              -120.710818,
              47.367011
            ],
            [
              -120.710247,
              47.366135
            ],
            [
              -120.708952,
              47.365526
            ],
            [
              -120.707544,
              47.365358
            ],
            [
              -120.706047,
              47.365471
            ],
            [
              -120.704559,
              47.365605
            ],
            [
              -120.703007,
              47.365629
            ],
            [
              -120.701527,
              47.36551
            ],
            [
              -120.700204,
              47.365192
            ],
            [
              -120.699042,
              47.364546
            ],
            [
              -120.697767,
              47.363782
            ],
            [
              -120.696693,
              47.36303
            ],
            [
              -120.695438,
              47.3625
            ],
            [
              -120.69441,
              47.361834
            ],
            [
              -120.693207,
              47.361298
            ],
            [
              -120.692498,
              47.36039
            ],
            [
              -120.691927,
              47.359441
            ],
            [
              -120.691612,
              47.358368
            ],
            [
              -120.691197,
              47.35731
            ],
            [
              -120.689182,
              47.356097
            ],
            [
              -120.687879,
              47.3555
            ],
            [
              -120.686599,
              47.355021
            ],
            [
              -120.685617,
              47.354264
            ],
            [
              -120.684533,
              47.353428
            ],
            [
              -120.683341,
              47.352963
            ],
            [
              -120.682349,
              47.352199
            ],
            [
              -120.681989,
              47.351213
            ],
            [
              -120.681575,
              47.350313
            ],
            [
              -120.680248,
              47.349831
            ],
            [
              -120.679388,
              47.349919
            ],
            [
              -120.678477,
              47.350638
            ],
            [
              -120.67732,
              47.351291
            ],
            [
              -120.675907,
              47.351173
            ],
            [
              -120.674764,
              47.350309
            ],
            [
              -120.67404,
              47.349368
            ],
            [
              -120.673025,
              47.34805
            ],
            [
              -120.672256,
              47.34743
            ],
            [
              -120.671195,
              47.346753
            ],
            [
              -120.670218,
              47.345986
            ],
            [
              -120.668938,
              47.345496
            ],
            [
              -120.667471,
              47.345251
            ],
            [
              -120.666276,
              47.344618
            ],
            [
              -120.665249,
              47.343838
            ],
            [
              -120.664186,
              47.343259
            ],
            [
              -120.662475,
              47.343432
            ],
            [
              -120.661018,
              47.343718
            ],
            [
              -120.659479,
              47.343892
            ],
            [
              -120.658508,
              47.344159
            ],
            [
              -120.657418,
              47.343616
            ],
            [
              -120.656959,
              47.343081
            ],
            [
              -120.655866,
              47.342365
            ],
            [
              -120.654347,
              47.342371
            ],
            [
              -120.652907,
              47.342147
            ],
            [
              -120.651409,
              47.342156
            ],
            [
              -120.649972,
              47.341854
            ],
            [
              -120.648493,
              47.342042
            ],
            [
              -120.647133,
              47.342491
            ],
            [
              -120.645415,
              47.343062
            ],
            [
              -120.643193,
              47.343507
            ],
            [
              -120.641206,
              47.343513
            ],
            [
              -120.639714,
              47.34355
            ],
            [
              -120.638228,
              47.343446
            ],
            [
              -120.636717,
              47.343443
            ],
            [
              -120.635461,
              47.342899
            ],
            [
              -120.634362,
              47.342209
            ],
            [
              -120.632976,
              47.341814
            ],
            [
              -120.630659,
              47.34191
            ],
            [
              -120.62811,
              47.342516
            ],
            [
              -120.626835,
              47.34229
            ],
            [
              -120.624248,
              47.34364
            ],
            [
              -120.615247,
              47.344369
            ],
            [
              -120.604766,
              47.339127
            ],
            [
              -120.594793,
              47.339572
            ],
            [
              -120.585063,
              47.338277
            ],
            [
              -120.578146,
              47.33575
            ],
            [
              -120.576842,
              47.329604
            ],
            [
              -120.570537,
              47.322286
            ],
            [
              -120.568635,
              47.315466
            ],
            [
              -120.568543,
              47.310426
            ],
            [
              -120.561945,
              47.307501
            ],
            [
              -120.554452,
              47.307729
            ],
            [
              -120.547207,
              47.310023
            ],
            [
              -120.53631,
              47.314603
            ],
            [
              -120.531697,
              47.315313
            ],
            [
              -120.528838,
              47.318972
            ],
            [
              -120.530663,
              47.323939
            ],
            [
              -120.531844,
              47.329869
            ],
            [
              -120.527475,
              47.333615
            ],
            [
              -120.524165,
              47.333162
            ],
            [
              -120.52082,
              47.329014
            ],
            [
              -120.514958,
              47.320044
            ],
            [
              -120.50221,
              47.317714
            ],
            [
              -120.496082,
              47.316044
            ],
            [
              -120.489704,
              47.312583
            ],
            [
              -120.488213,
              47.307561
            ],
            [
              -120.481705,
              47.305393
            ],
            [
              -120.478939,
              47.306623
            ],
            [
              -120.47619,
              47.307139
            ],
            [
              -120.471712,
              47.304947
            ],
            [
              -120.467246,
              47.30296
            ],
            [
              -120.461222,
              47.300135
            ],
            [
              -120.455896,
              47.29794
            ],
            [
              -120.449232,
              47.293796
            ],
            [
              -120.445502,
              47.287555
            ],
            [
              -120.435974,
              47.281856
            ],
            [
              -120.427485,
              47.274816
            ],
            [
              -120.421081,
              47.275147
            ],
            [
              -120.412975,
              47.274048
            ],
            [
              -120.401337,
              47.270987
            ],
            [
              -120.397276,
              47.268316
            ],
            [
              -120.394509,
              47.264918
            ],
            [
              -120.390057,
              47.262067
            ],
            [
              -120.376874,
              47.260906
            ],
            [
              -120.334812,
              47.261088
            ],
            [
              -120.303803,
              47.261134
            ],
            [
              -120.276869,
              47.261249
            ],
            [
              -120.239658,
              47.262139
            ],
            [
              -120.20947,
              47.262288
            ],
            [
              -120.167161,
              47.262261
            ],
            [
              -120.132602,
              47.261941
            ],
            [
              -120.103426,
              47.262114
            ],
            [
              -120.094737,
              47.262158
            ],
            [
              -120.09446,
              47.262159
            ],
            [
              -120.086046,
              47.300299
            ],
            [
              -120.086308,
              47.338942
            ],
            [
              -120.129573,
              47.366251
            ],
            [
              -120.165577,
              47.367706
            ],
            [
              -120.260288,
              47.383892
            ],
            [
              -120.28653,
              47.393111
            ],
            [
              -120.293175,
              47.400767
            ],
            [
              -120.296404,
              47.413141
            ],
            [
              -120.308482,
              47.432348
            ],
            [
              -120.311883,
              47.445248
            ],
            [
              -120.317984,
              47.456948
            ],
            [
              -120.316594,
              47.471273
            ],
            [
              -120.302931,
              47.520059
            ],
            [
              -120.292814,
              47.538172
            ],
            [
              -120.259438,
              47.55849
            ],
            [
              -120.233584,
              47.59763
            ],
            [
              -120.236935,
              47.631779
            ],
            [
              -120.216776,
              47.66368
            ],
            [
              -120.199048,
              47.682276
            ],
            [
              -120.205219,
              47.702059
            ],
            [
              -120.222858,
              47.723314
            ],
            [
              -120.209395,
              47.748356
            ],
            [
              -120.191405,
              47.759504
            ],
            [
              -120.130914,
              47.776601
            ],
            [
              -120.094232,
              47.763583
            ],
            [
              -120.038251,
              47.775949
            ],
            [
              -119.994952,
              47.781353
            ],
            [
              -119.978062,
              47.801292
            ],
            [
              -119.964684,
              47.826193
            ],
            [
              -119.960127,
              47.845806
            ],
            [
              -119.919294,
              47.862323
            ],
            [
              -119.908428,
              47.879405
            ],
            [
              -119.915059,
              47.901372
            ],
            [
              -119.889315,
              47.91716
            ],
            [
              -119.859734,
              47.941942
            ],
            [
              -119.870595,
              47.960455
            ],
            [
              -119.871206,
              47.960492
            ],
            [
              -119.885072,
              47.960457
            ],
            [
              -119.914103,
              47.96045
            ],
            [
              -119.937514,
              47.960435
            ],
            [
              -119.969504,
              47.960563
            ],
            [
              -120.04821,
              47.960626
            ],
            [
              -120.055587,
              47.962637
            ],
            [
              -120.064481,
              47.966749
            ],
            [
              -120.067011,
              47.966821
            ],
            [
              -120.07011,
              47.970135
            ],
            [
              -120.067159,
              47.973444
            ],
            [
              -120.065019,
              47.977106
            ],
            [
              -120.065804,
              47.982159
            ],
            [
              -120.070011,
              47.985122
            ],
            [
              -120.077586,
              47.990942
            ],
            [
              -120.080016,
              47.997372
            ],
            [
              -120.07809,
              47.999424
            ],
            [
              -120.081832,
              48.006223
            ],
            [
              -120.086272,
              48.012778
            ],
            [
              -120.090176,
              48.018006
            ],
            [
              -120.098112,
              48.020858
            ],
            [
              -120.104473,
              48.022375
            ],
            [
              -120.112073,
              48.024853
            ],
            [
              -120.12021,
              48.029034
            ],
            [
              -120.124739,
              48.032124
            ],
            [
              -120.13969,
              48.03516
            ],
            [
              -120.149204,
              48.044081
            ],
            [
              -120.14867,
              48.050766
            ],
            [
              -120.140363,
              48.054142
            ],
            [
              -120.137212,
              48.053612
            ],
            [
              -120.131954,
              48.055722
            ],
            [
              -120.133535,
              48.057595
            ],
            [
              -120.142613,
              48.063943
            ],
            [
              -120.14915,
              48.065747
            ],
            [
              -120.15785,
              48.068161
            ],
            [
              -120.164744,
              48.068623
            ],
            [
              -120.180277,
              48.07327
            ],
            [
              -120.187694,
              48.078516
            ],
            [
              -120.202897,
              48.077162
            ],
            [
              -120.216295,
              48.081257
            ],
            [
              -120.224295,
              48.08419
            ],
            [
              -120.233303,
              48.088385
            ],
            [
              -120.235741,
              48.093897
            ],
            [
              -120.243778,
              48.100341
            ],
            [
              -120.252208,
              48.105102
            ],
            [
              -120.260568,
              48.110432
            ],
            [
              -120.272924,
              48.116708
            ],
            [
              -120.278108,
              48.1212
            ],
            [
              -120.284505,
              48.123749
            ],
            [
              -120.289547,
              48.127301
            ],
            [
              -120.296016,
              48.129526
            ],
            [
              -120.300721,
              48.127484
            ],
            [
              -120.315765,
              48.124717
            ],
            [
              -120.32279,
              48.126494
            ],
            [
              -120.32672,
              48.132147
            ],
            [
              -120.348184,
              48.144212
            ],
            [
              -120.362204,
              48.164378
            ],
            [
              -120.35561,
              48.183239
            ],
            [
              -120.349518,
              48.207192
            ],
            [
              -120.36975,
              48.218423
            ],
            [
              -120.386247,
              48.231808
            ],
            [
              -120.414349,
              48.24858
            ],
            [
              -120.44321,
              48.260165
            ],
            [
              -120.467722,
              48.270589
            ],
            [
              -120.488317,
              48.287254
            ],
            [
              -120.503971,
              48.302381
            ],
            [
              -120.522077,
              48.309774
            ],
            [
              -120.547534,
              48.307003
            ],
            [
              -120.580118,
              48.318926
            ],
            [
              -120.583984,
              48.341613
            ],
            [
              -120.566366,
              48.35815
            ],
            [
              -120.578926,
              48.378282
            ],
            [
              -120.601898,
              48.387123
            ],
            [
              -120.621101,
              48.398772
            ],
            [
              -120.636481,
              48.397286
            ],
            [
              -120.646786,
              48.40378
            ],
            [
              -120.643873,
              48.411937
            ],
            [
              -120.653859,
              48.430912
            ],
            [
              -120.658976,
              48.441442
            ],
            [
              -120.66191,
              48.451695
            ],
            [
              -120.654228,
              48.454643
            ],
            [
              -120.645507,
              48.463889
            ],
            [
              -120.640339,
              48.473856
            ],
            [
              -120.648618,
              48.476772
            ],
            [
              -120.650105,
              48.488479
            ],
            [
              -120.637208,
              48.496102
            ],
            [
              -120.633498,
              48.496091
            ],
            [
              -120.636565,
              48.501754
            ],
            [
              -120.649277,
              48.505578
            ],
            [
              -120.657021,
              48.516554
            ],
            [
              -120.649554,
              48.526207
            ],
            [
              -120.648991,
              48.532842
            ],
            [
              -120.659517,
              48.53516
            ],
            [
              -120.673088,
              48.532731
            ],
            [
              -120.698616,
              48.532217
            ],
            [
              -120.702069,
              48.531589
            ],
            [
              -120.765627,
              48.508873
            ],
            [
              -120.787932,
              48.5071
            ],
            [
              -120.816787,
              48.519604
            ],
            [
              -120.809312,
              48.528626
            ],
            [
              -120.823947,
              48.54503
            ],
            [
              -120.857427,
              48.547676
            ],
            [
              -120.863213,
              48.530074
            ],
            [
              -120.885401,
              48.538187
            ],
            [
              -120.90396,
              48.533401
            ],
            [
              -120.904596,
              48.523653
            ],
            [
              -120.950502,
              48.529581
            ],
            [
              -120.951068,
              48.499637
            ],
            [
              -120.99293,
              48.499045
            ],
            [
              -121.001089,
              48.494203
            ],
            [
              -121.036234,
              48.495297
            ],
            [
              -121.049714,
              48.485399
            ],
            [
              -121.063302,
              48.460033
            ],
            [
              -121.040994,
              48.445737
            ],
            [
              -121.037972,
              48.431847
            ],
            [
              -121.065763,
              48.391789
            ],
            [
              -121.043504,
              48.363993
            ],
            [
              -121.043032,
              48.345726
            ],
            [
              -121.059447,
              48.34668
            ],
            [
              -121.071483,
              48.317956
            ],
            [
              -121.001658,
              48.296009
            ],
            [
              -121.000685,
              48.294481
            ],
            [
              -121.007198,
              48.285075
            ],
            [
              -121.011766,
              48.282793
            ],
            [
              -121.010132,
              48.277529
            ],
            [
              -121.006618,
              48.27464
            ],
            [
              -121.005033,
              48.269167
            ],
            [
              -120.990411,
              48.255629
            ],
            [
              -120.975889,
              48.240166
            ],
            [
              -120.960404,
              48.228279
            ],
            [
              -120.965268,
              48.218755
            ],
            [
              -120.958728,
              48.2103
            ],
            [
              -120.951576,
              48.20731
            ],
            [
              -120.937585,
              48.198586
            ],
            [
              -120.920537,
              48.188082
            ],
            [
              -120.910543,
              48.173591
            ],
            [
              -120.912756,
              48.161739
            ],
            [
              -120.927037,
              48.161139
            ],
            [
              -120.943526,
              48.155465
            ],
            [
              -120.960329,
              48.145518
            ],
            [
              -120.959435,
              48.129465
            ],
            [
              -120.951458,
              48.116599
            ],
            [
              -120.957954,
              48.108037
            ],
            [
              -120.971776,
              48.10517
            ],
            [
              -120.986543,
              48.093831
            ],
            [
              -120.998822,
              48.088259
            ],
            [
              -121.012791,
              48.077369
            ],
            [
              -121.03012,
              48.078145
            ],
            [
              -121.048403,
              48.07032
            ],
            [
              -121.067004,
              48.06941
            ],
            [
              -121.086339,
              48.063078
            ],
            [
              -121.101766,
              48.061762
            ],
            [
              -121.117513,
              48.056968
            ],
            [
              -121.130519,
              48.04518
            ],
            [
              -121.147745,
              48.042208
            ],
            [
              -121.150622,
              48.036711
            ],
            [
              -121.142355,
              48.026873
            ],
            [
              -121.130111,
              48.02103
            ],
            [
              -121.125943,
              48.012369
            ],
            [
              -121.118451,
              48.006765
            ],
            [
              -121.117723,
              47.998797
            ],
            [
              -121.128249,
              47.989865
            ],
            [
              -121.135427,
              47.983277
            ],
            [
              -121.141683,
              47.983325
            ],
            [
              -121.147733,
              47.979657
            ],
            [
              -121.14879,
              47.974902
            ],
            [
              -121.152866,
              47.966754
            ],
            [
              -121.156426,
              47.961586
            ],
            [
              -121.164603,
              47.95693
            ],
            [
              -121.161784,
              47.945834
            ],
            [
              -121.166414,
              47.938163
            ],
            [
              -121.173013,
              47.932346
            ],
            [
              -121.17077,
              47.924994
            ],
            [
              -121.175955,
              47.921549
            ],
            [
              -121.177015,
              47.916551
            ],
            [
              -121.173763,
              47.915352
            ],
            [
              -121.17195,
              47.91167
            ],
            [
              -121.173262,
              47.908779
            ],
            [
              -121.178123,
              47.902606
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "53",
      "COUNTYFP": "037",
      "COUNTYNS": "01531926",
      "AFFGEOID": "0500000US53037",
      "GEOID": "53037",
      "NAME": "Kittitas",
      "LSAD": "06",
      "ALAND": 5949976954,
      "AWATER": 92436005,
      "County_state": "Kittitas,53"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -121.465979,
              47.367631
            ],
            [
              -121.444655,
              47.343761
            ],
            [
              -121.430578,
              47.334617
            ],
            [
              -121.444362,
              47.308247
            ],
            [
              -121.432682,
              47.304955
            ],
            [
              -121.427492,
              47.289075
            ],
            [
              -121.411709,
              47.289949
            ],
            [
              -121.341203,
              47.281261
            ],
            [
              -121.33585,
              47.260562
            ],
            [
              -121.339767,
              47.247199
            ],
            [
              -121.360816,
              47.240403
            ],
            [
              -121.365138,
              47.224563
            ],
            [
              -121.3176,
              47.212131
            ],
            [
              -121.306297,
              47.187711
            ],
            [
              -121.317748,
              47.17434
            ],
            [
              -121.297639,
              47.148557
            ],
            [
              -121.303355,
              47.138942
            ],
            [
              -121.335004,
              47.132684
            ],
            [
              -121.364677,
              47.141759
            ],
            [
              -121.393968,
              47.132762
            ],
            [
              -121.408081,
              47.1181
            ],
            [
              -121.402526,
              47.098013
            ],
            [
              -121.379682,
              47.087495
            ],
            [
              -121.359236,
              47.07074
            ],
            [
              -121.347136,
              47.078739
            ],
            [
              -121.311259,
              47.076915
            ],
            [
              -121.280716,
              47.088731
            ],
            [
              -121.256887,
              47.082147
            ],
            [
              -121.222406,
              47.066851
            ],
            [
              -121.210159,
              47.0535
            ],
            [
              -121.165569,
              47.035496
            ],
            [
              -121.135765,
              47.017233
            ],
            [
              -121.12376,
              46.999119
            ],
            [
              -121.090054,
              46.991007
            ],
            [
              -121.089957,
              46.969295
            ],
            [
              -121.06885,
              46.958465
            ],
            [
              -121.068815,
              46.947576
            ],
            [
              -121.02662,
              46.911308
            ],
            [
              -120.634562,
              46.91213
            ],
            [
              -120.633981,
              46.825776
            ],
            [
              -120.625149,
              46.825791
            ],
            [
              -120.508605,
              46.824862
            ],
            [
              -120.51,
              46.737946
            ],
            [
              -120.448943,
              46.737576
            ],
            [
              -119.973036,
              46.737126
            ],
            [
              -119.969975,
              46.754973
            ],
            [
              -119.943054,
              46.774702
            ],
            [
              -119.927436,
              46.798445
            ],
            [
              -119.927537,
              46.817092
            ],
            [
              -119.964943,
              46.858804
            ],
            [
              -119.963191,
              46.918817
            ],
            [
              -119.966129,
              46.943757
            ],
            [
              -120.001122,
              46.993641
            ],
            [
              -120.011165,
              47.039353
            ],
            [
              -120.032966,
              47.054253
            ],
            [
              -120.042766,
              47.071553
            ],
            [
              -120.026066,
              47.106853
            ],
            [
              -120.011966,
              47.111853
            ],
            [
              -120.002065,
              47.126153
            ],
            [
              -120.001166,
              47.14593
            ],
            [
              -120.010585,
              47.161275
            ],
            [
              -120.004175,
              47.182661
            ],
            [
              -120.002631,
              47.212267
            ],
            [
              -120.007074,
              47.220133
            ],
            [
              -120.019088,
              47.226645
            ],
            [
              -120.075969,
              47.225898
            ],
            [
              -120.09446,
              47.262159
            ],
            [
              -120.094737,
              47.262158
            ],
            [
              -120.103426,
              47.262114
            ],
            [
              -120.132602,
              47.261941
            ],
            [
              -120.167161,
              47.262261
            ],
            [
              -120.20947,
              47.262288
            ],
            [
              -120.239658,
              47.262139
            ],
            [
              -120.276869,
              47.261249
            ],
            [
              -120.303803,
              47.261134
            ],
            [
              -120.334812,
              47.261088
            ],
            [
              -120.376874,
              47.260906
            ],
            [
              -120.390057,
              47.262067
            ],
            [
              -120.394509,
              47.264918
            ],
            [
              -120.397276,
              47.268316
            ],
            [
              -120.401337,
              47.270987
            ],
            [
              -120.412975,
              47.274048
            ],
            [
              -120.421081,
              47.275147
            ],
            [
              -120.427485,
              47.274816
            ],
            [
              -120.435974,
              47.281856
            ],
            [
              -120.445502,
              47.287555
            ],
            [
              -120.449232,
              47.293796
            ],
            [
              -120.455896,
              47.29794
            ],
            [
              -120.461222,
              47.300135
            ],
            [
              -120.467246,
              47.30296
            ],
            [
              -120.471712,
              47.304947
            ],
            [
              -120.47619,
              47.307139
            ],
            [
              -120.478939,
              47.306623
            ],
            [
              -120.481705,
              47.305393
            ],
            [
              -120.488213,
              47.307561
            ],
            [
              -120.489704,
              47.312583
            ],
            [
              -120.496082,
              47.316044
            ],
            [
              -120.50221,
              47.317714
            ],
            [
              -120.514958,
              47.320044
            ],
            [
              -120.52082,
              47.329014
            ],
            [
              -120.524165,
              47.333162
            ],
            [
              -120.527475,
              47.333615
            ],
            [
              -120.531844,
              47.329869
            ],
            [
              -120.530663,
              47.323939
            ],
            [
              -120.528838,
              47.318972
            ],
            [
              -120.531697,
              47.315313
            ],
            [
              -120.53631,
              47.314603
            ],
            [
              -120.547207,
              47.310023
            ],
            [
              -120.554452,
              47.307729
            ],
            [
              -120.561945,
              47.307501
            ],
            [
              -120.568543,
              47.310426
            ],
            [
              -120.568635,
              47.315466
            ],
            [
              -120.570537,
              47.322286
            ],
            [
              -120.576842,
              47.329604
            ],
            [
              -120.578146,
              47.33575
            ],
            [
              -120.585063,
              47.338277
            ],
            [
              -120.594793,
              47.339572
            ],
            [
              -120.604766,
              47.339127
            ],
            [
              -120.615247,
              47.344369
            ],
            [
              -120.624248,
              47.34364
            ],
            [
              -120.626835,
              47.34229
            ],
            [
              -120.62811,
              47.342516
            ],
            [
              -120.630659,
              47.34191
            ],
            [
              -120.632976,
              47.341814
            ],
            [
              -120.634362,
              47.342209
            ],
            [
              -120.635461,
              47.342899
            ],
            [
              -120.636717,
              47.343443
            ],
            [
              -120.638228,
              47.343446
            ],
            [
              -120.639714,
              47.34355
            ],
            [
              -120.641206,
              47.343513
            ],
            [
              -120.643193,
              47.343507
            ],
            [
              -120.645415,
              47.343062
            ],
            [
              -120.647133,
              47.342491
            ],
            [
              -120.648493,
              47.342042
            ],
            [
              -120.649972,
              47.341854
            ],
            [
              -120.651409,
              47.342156
            ],
            [
              -120.652907,
              47.342147
            ],
            [
              -120.654347,
              47.342371
            ],
            [
              -120.655866,
              47.342365
            ],
            [
              -120.656959,
              47.343081
            ],
            [
              -120.657418,
              47.343616
            ],
            [
              -120.658508,
              47.344159
            ],
            [
              -120.659479,
              47.343892
            ],
            [
              -120.661018,
              47.343718
            ],
            [
              -120.662475,
              47.343432
            ],
            [
              -120.664186,
              47.343259
            ],
            [
              -120.665249,
              47.343838
            ],
            [
              -120.666276,
              47.344618
            ],
            [
              -120.667471,
              47.345251
            ],
            [
              -120.668938,
              47.345496
            ],
            [
              -120.670218,
              47.345986
            ],
            [
              -120.671195,
              47.346753
            ],
            [
              -120.672256,
              47.34743
            ],
            [
              -120.673025,
              47.34805
            ],
            [
              -120.67404,
              47.349368
            ],
            [
              -120.674764,
              47.350309
            ],
            [
              -120.675907,
              47.351173
            ],
            [
              -120.67732,
              47.351291
            ],
            [
              -120.678477,
              47.350638
            ],
            [
              -120.679388,
              47.349919
            ],
            [
              -120.680248,
              47.349831
            ],
            [
              -120.681575,
              47.350313
            ],
            [
              -120.681989,
              47.351213
            ],
            [
              -120.682349,
              47.352199
            ],
            [
              -120.683341,
              47.352963
            ],
            [
              -120.684533,
              47.353428
            ],
            [
              -120.685617,
              47.354264
            ],
            [
              -120.686599,
              47.355021
            ],
            [
              -120.687879,
              47.3555
            ],
            [
              -120.689182,
              47.356097
            ],
            [
              -120.691197,
              47.35731
            ],
            [
              -120.691612,
              47.358368
            ],
            [
              -120.691927,
              47.359441
            ],
            [
              -120.692498,
              47.36039
            ],
            [
              -120.693207,
              47.361298
            ],
            [
              -120.69441,
              47.361834
            ],
            [
              -120.695438,
              47.3625
            ],
            [
              -120.696693,
              47.36303
            ],
            [
              -120.697767,
              47.363782
            ],
            [
              -120.699042,
              47.364546
            ],
            [
              -120.700204,
              47.365192
            ],
            [
              -120.701527,
              47.36551
            ],
            [
              -120.703007,
              47.365629
            ],
            [
              -120.704559,
              47.365605
            ],
            [
              -120.706047,
              47.365471
            ],
            [
              -120.707544,
              47.365358
            ],
            [
              -120.708952,
              47.365526
            ],
            [
              -120.710247,
              47.366135
            ],
            [
              -120.710818,
              47.367011
            ],
            [
              -120.711743,
              47.367778
            ],
            [
              -120.713331,
              47.367966
            ],
            [
              -120.715091,
              47.368236
            ],
            [
              -120.716505,
              47.368603
            ],
            [
              -120.717995,
              47.368786
            ],
            [
              -120.719324,
              47.369242
            ],
            [
              -120.720637,
              47.369755
            ],
            [
              -120.721336,
              47.370571
            ],
            [
              -120.721675,
              47.371562
            ],
            [
              -120.722671,
              47.372312
            ],
            [
              -120.723182,
              47.373136
            ],
            [
              -120.723096,
              47.374152
            ],
            [
              -120.723554,
              47.37525
            ],
            [
              -120.724206,
              47.376385
            ],
            [
              -120.724559,
              47.377591
            ],
            [
              -120.72534,
              47.378629
            ],
            [
              -120.726125,
              47.379578
            ],
            [
              -120.727169,
              47.380565
            ],
            [
              -120.727071,
              47.381987
            ],
            [
              -120.726706,
              47.383245
            ],
            [
              -120.72743,
              47.384054
            ],
            [
              -120.727645,
              47.384933
            ],
            [
              -120.72728,
              47.385931
            ],
            [
              -120.726594,
              47.387077
            ],
            [
              -120.726582,
              47.388062
            ],
            [
              -120.727652,
              47.388769
            ],
            [
              -120.728621,
              47.389552
            ],
            [
              -120.729729,
              47.390103
            ],
            [
              -120.731508,
              47.390992
            ],
            [
              -120.731929,
              47.391969
            ],
            [
              -120.73313,
              47.393043
            ],
            [
              -120.735468,
              47.393771
            ],
            [
              -120.739447,
              47.3933
            ],
            [
              -120.741846,
              47.393643
            ],
            [
              -120.74403,
              47.394133
            ],
            [
              -120.747003,
              47.393751
            ],
            [
              -120.750141,
              47.394475
            ],
            [
              -120.752591,
              47.395086
            ],
            [
              -120.755414,
              47.395966
            ],
            [
              -120.756702,
              47.397404
            ],
            [
              -120.758482,
              47.39763
            ],
            [
              -120.762911,
              47.398024
            ],
            [
              -120.76709,
              47.399085
            ],
            [
              -120.77143,
              47.39926
            ],
            [
              -120.775017,
              47.399803
            ],
            [
              -120.777979,
              47.399734
            ],
            [
              -120.779581,
              47.399614
            ],
            [
              -120.78148,
              47.399429
            ],
            [
              -120.783081,
              47.399514
            ],
            [
              -120.785298,
              47.401359
            ],
            [
              -120.786437,
              47.403668
            ],
            [
              -120.787749,
              47.405155
            ],
            [
              -120.790262,
              47.406026
            ],
            [
              -120.792368,
              47.407412
            ],
            [
              -120.794042,
              47.410662
            ],
            [
              -120.79425,
              47.412708
            ],
            [
              -120.79424,
              47.413749
            ],
            [
              -120.79524,
              47.414466
            ],
            [
              -120.796413,
              47.415122
            ],
            [
              -120.797533,
              47.415813
            ],
            [
              -120.798537,
              47.416586
            ],
            [
              -120.799396,
              47.417428
            ],
            [
              -120.79993,
              47.418387
            ],
            [
              -120.800266,
              47.419322
            ],
            [
              -120.800999,
              47.420216
            ],
            [
              -120.801802,
              47.421077
            ],
            [
              -120.802196,
              47.422207
            ],
            [
              -120.802678,
              47.42272
            ],
            [
              -120.803731,
              47.423076
            ],
            [
              -120.804401,
              47.422668
            ],
            [
              -120.805226,
              47.421719
            ],
            [
              -120.806358,
              47.421049
            ],
            [
              -120.807717,
              47.420595
            ],
            [
              -120.809118,
              47.420205
            ],
            [
              -120.811295,
              47.419909
            ],
            [
              -120.812901,
              47.419725
            ],
            [
              -120.814594,
              47.42002
            ],
            [
              -120.81593,
              47.42051
            ],
            [
              -120.81753,
              47.420612
            ],
            [
              -120.819203,
              47.420796
            ],
            [
              -120.820752,
              47.421303
            ],
            [
              -120.822714,
              47.422155
            ],
            [
              -120.824592,
              47.422643
            ],
            [
              -120.826725,
              47.423264
            ],
            [
              -120.827878,
              47.423695
            ],
            [
              -120.829091,
              47.423625
            ],
            [
              -120.829726,
              47.423169
            ],
            [
              -120.830963,
              47.422466
            ],
            [
              -120.832303,
              47.421348
            ],
            [
              -120.833212,
              47.42045
            ],
            [
              -120.834119,
              47.419644
            ],
            [
              -120.835366,
              47.419054
            ],
            [
              -120.836644,
              47.418273
            ],
            [
              -120.837179,
              47.417323
            ],
            [
              -120.837037,
              47.416297
            ],
            [
              -120.837243,
              47.415285
            ],
            [
              -120.838102,
              47.414441
            ],
            [
              -120.839311,
              47.413609
            ],
            [
              -120.841474,
              47.412383
            ],
            [
              -120.842944,
              47.41173
            ],
            [
              -120.844513,
              47.412009
            ],
            [
              -120.846657,
              47.412937
            ],
            [
              -120.848393,
              47.413307
            ],
            [
              -120.850093,
              47.413887
            ],
            [
              -120.851213,
              47.41458
            ],
            [
              -120.852269,
              47.415318
            ],
            [
              -120.853556,
              47.415851
            ],
            [
              -120.854873,
              47.416351
            ],
            [
              -120.855687,
              47.417199
            ],
            [
              -120.856619,
              47.417999
            ],
            [
              -120.857654,
              47.418726
            ],
            [
              -120.858648,
              47.419413
            ],
            [
              -120.860045,
              47.419797
            ],
            [
              -120.861209,
              47.420459
            ],
            [
              -120.862362,
              47.420325
            ],
            [
              -120.86349,
              47.419536
            ],
            [
              -120.865449,
              47.418753
            ],
            [
              -120.866701,
              47.418039
            ],
            [
              -120.867211,
              47.416732
            ],
            [
              -120.868394,
              47.416058
            ],
            [
              -120.86976,
              47.41562
            ],
            [
              -120.871225,
              47.415324
            ],
            [
              -120.872662,
              47.415512
            ],
            [
              -120.873768,
              47.416213
            ],
            [
              -120.874942,
              47.416862
            ],
            [
              -120.876163,
              47.417543
            ],
            [
              -120.876932,
              47.41842
            ],
            [
              -120.878104,
              47.419504
            ],
            [
              -120.879235,
              47.420178
            ],
            [
              -120.880574,
              47.420592
            ],
            [
              -120.881896,
              47.421114
            ],
            [
              -120.88299,
              47.421692
            ],
            [
              -120.884246,
              47.422269
            ],
            [
              -120.885375,
              47.42293
            ],
            [
              -120.88612,
              47.423852
            ],
            [
              -120.886814,
              47.424739
            ],
            [
              -120.887595,
              47.42561
            ],
            [
              -120.888789,
              47.426229
            ],
            [
              -120.88993,
              47.426904
            ],
            [
              -120.891031,
              47.427474
            ],
            [
              -120.892088,
              47.427379
            ],
            [
              -120.892868,
              47.426573
            ],
            [
              -120.894212,
              47.426156
            ],
            [
              -120.89607,
              47.42623
            ],
            [
              -120.897544,
              47.426191
            ],
            [
              -120.899032,
              47.426331
            ],
            [
              -120.900437,
              47.426711
            ],
            [
              -120.901892,
              47.427009
            ],
            [
              -120.903372,
              47.426816
            ],
            [
              -120.904776,
              47.426416
            ],
            [
              -120.905796,
              47.425676
            ],
            [
              -120.90716,
              47.425241
            ],
            [
              -120.908619,
              47.425471
            ],
            [
              -120.909782,
              47.426118
            ],
            [
              -120.91096,
              47.426729
            ],
            [
              -120.911969,
              47.427462
            ],
            [
              -120.913149,
              47.428062
            ],
            [
              -120.914474,
              47.428152
            ],
            [
              -120.915772,
              47.428665
            ],
            [
              -120.91715,
              47.429067
            ],
            [
              -120.918329,
              47.429683
            ],
            [
              -120.919244,
              47.430488
            ],
            [
              -120.920363,
              47.43114
            ],
            [
              -120.921292,
              47.43186
            ],
            [
              -120.922417,
              47.432548
            ],
            [
              -120.923646,
              47.433115
            ],
            [
              -120.924292,
              47.433993
            ],
            [
              -120.924486,
              47.434891
            ],
            [
              -120.924041,
              47.435851
            ],
            [
              -120.924063,
              47.436876
            ],
            [
              -120.924467,
              47.437783
            ],
            [
              -120.924344,
              47.438809
            ],
            [
              -120.924268,
              47.43982
            ],
            [
              -120.923939,
              47.440823
            ],
            [
              -120.923811,
              47.441847
            ],
            [
              -120.924535,
              47.442663
            ],
            [
              -120.92446,
              47.443602
            ],
            [
              -120.924099,
              47.44454
            ],
            [
              -120.923831,
              47.44547
            ],
            [
              -120.923476,
              47.446464
            ],
            [
              -120.92296,
              47.447357
            ],
            [
              -120.922679,
              47.447948
            ],
            [
              -120.923048,
              47.448607
            ],
            [
              -120.923873,
              47.448874
            ],
            [
              -120.925268,
              47.449258
            ],
            [
              -120.926405,
              47.449749
            ],
            [
              -120.92728,
              47.450499
            ],
            [
              -120.929454,
              47.451877
            ],
            [
              -120.930569,
              47.453055
            ],
            [
              -120.930877,
              47.454039
            ],
            [
              -120.930739,
              47.455061
            ],
            [
              -120.930903,
              47.456083
            ],
            [
              -120.930757,
              47.457094
            ],
            [
              -120.930924,
              47.457934
            ],
            [
              -120.931983,
              47.458672
            ],
            [
              -120.933246,
              47.459233
            ],
            [
              -120.934713,
              47.459436
            ],
            [
              -120.936225,
              47.459519
            ],
            [
              -120.937738,
              47.459526
            ],
            [
              -120.939234,
              47.459656
            ],
            [
              -120.940748,
              47.459733
            ],
            [
              -120.942047,
              47.460219
            ],
            [
              -120.943505,
              47.460499
            ],
            [
              -120.944988,
              47.460713
            ],
            [
              -120.946498,
              47.460802
            ],
            [
              -120.947895,
              47.460949
            ],
            [
              -120.949238,
              47.461245
            ],
            [
              -120.950905,
              47.461522
            ],
            [
              -120.952254,
              47.461958
            ],
            [
              -120.952827,
              47.462441
            ],
            [
              -120.952633,
              47.463038
            ],
            [
              -120.95214,
              47.46356
            ],
            [
              -120.951995,
              47.464577
            ],
            [
              -120.951757,
              47.465592
            ],
            [
              -120.95177,
              47.46658
            ],
            [
              -120.950964,
              47.467394
            ],
            [
              -120.950208,
              47.468186
            ],
            [
              -120.949401,
              47.469059
            ],
            [
              -120.94915,
              47.469988
            ],
            [
              -120.94819,
              47.470783
            ],
            [
              -120.947266,
              47.47151
            ],
            [
              -120.946746,
              47.47202
            ],
            [
              -120.946689,
              47.472766
            ],
            [
              -120.947179,
              47.473121
            ],
            [
              -120.948374,
              47.473742
            ],
            [
              -120.949406,
              47.474478
            ],
            [
              -120.950274,
              47.475289
            ],
            [
              -120.950944,
              47.476101
            ],
            [
              -120.95219,
              47.476551
            ],
            [
              -120.953695,
              47.476646
            ],
            [
              -120.954824,
              47.47725
            ],
            [
              -120.955607,
              47.478119
            ],
            [
              -120.956289,
              47.479039
            ],
            [
              -120.956907,
              47.479986
            ],
            [
              -120.95822,
              47.480452
            ],
            [
              -120.959021,
              47.481309
            ],
            [
              -120.958772,
              47.482244
            ],
            [
              -120.958212,
              47.483201
            ],
            [
              -120.958975,
              47.483939
            ],
            [
              -120.959691,
              47.484374
            ],
            [
              -120.961072,
              47.484574
            ],
            [
              -120.96239,
              47.484895
            ],
            [
              -120.963533,
              47.485564
            ],
            [
              -120.964646,
              47.486262
            ],
            [
              -120.965968,
              47.486761
            ],
            [
              -120.96732,
              47.487478
            ],
            [
              -120.968465,
              47.488152
            ],
            [
              -120.969847,
              47.488335
            ],
            [
              -120.97136,
              47.488387
            ],
            [
              -120.972839,
              47.488476
            ],
            [
              -120.974142,
              47.488998
            ],
            [
              -120.975428,
              47.489533
            ],
            [
              -120.976575,
              47.490182
            ],
            [
              -120.977941,
              47.490515
            ],
            [
              -120.979472,
              47.490525
            ],
            [
              -120.980676,
              47.491151
            ],
            [
              -120.981523,
              47.491979
            ],
            [
              -120.982641,
              47.492667
            ],
            [
              -120.983473,
              47.493463
            ],
            [
              -120.984471,
              47.494227
            ],
            [
              -120.985356,
              47.495055
            ],
            [
              -120.986249,
              47.495889
            ],
            [
              -120.986366,
              47.496871
            ],
            [
              -120.987576,
              47.497508
            ],
            [
              -120.988394,
              47.498329
            ],
            [
              -120.989166,
              47.499202
            ],
            [
              -120.991589,
              47.501343
            ],
            [
              -120.99188,
              47.502415
            ],
            [
              -120.992745,
              47.503289
            ],
            [
              -120.993907,
              47.503927
            ],
            [
              -120.994826,
              47.504647
            ],
            [
              -120.994587,
              47.505659
            ],
            [
              -120.994661,
              47.506669
            ],
            [
              -120.994149,
              47.507578
            ],
            [
              -120.993236,
              47.508334
            ],
            [
              -120.992129,
              47.508972
            ],
            [
              -120.992212,
              47.509947
            ],
            [
              -120.99133,
              47.510771
            ],
            [
              -120.991616,
              47.511756
            ],
            [
              -120.99248,
              47.5126
            ],
            [
              -120.993079,
              47.513532
            ],
            [
              -120.993751,
              47.514449
            ],
            [
              -120.994919,
              47.5151
            ],
            [
              -120.996016,
              47.515806
            ],
            [
              -120.996375,
              47.516793
            ],
            [
              -120.996504,
              47.517816
            ],
            [
              -120.996949,
              47.518645
            ],
            [
              -120.998422,
              47.518906
            ],
            [
              -120.999903,
              47.519107
            ],
            [
              -121.001402,
              47.519354
            ],
            [
              -121.003259,
              47.519796
            ],
            [
              -121.004561,
              47.520225
            ],
            [
              -121.005917,
              47.520492
            ],
            [
              -121.007,
              47.521177
            ],
            [
              -121.008407,
              47.521041
            ],
            [
              -121.00971,
              47.521556
            ],
            [
              -121.011089,
              47.521756
            ],
            [
              -121.012591,
              47.521648
            ],
            [
              -121.014005,
              47.521488
            ],
            [
              -121.015365,
              47.521854
            ],
            [
              -121.016983,
              47.521868
            ],
            [
              -121.018339,
              47.522275
            ],
            [
              -121.019785,
              47.522541
            ],
            [
              -121.020981,
              47.523171
            ],
            [
              -121.022289,
              47.523555
            ],
            [
              -121.023709,
              47.523879
            ],
            [
              -121.024688,
              47.524832
            ],
            [
              -121.025069,
              47.525779
            ],
            [
              -121.02516,
              47.526801
            ],
            [
              -121.025671,
              47.527767
            ],
            [
              -121.025793,
              47.52881
            ],
            [
              -121.02701,
              47.529219
            ],
            [
              -121.028196,
              47.529825
            ],
            [
              -121.029514,
              47.530333
            ],
            [
              -121.030368,
              47.531182
            ],
            [
              -121.031447,
              47.531774
            ],
            [
              -121.032735,
              47.532308
            ],
            [
              -121.033952,
              47.532921
            ],
            [
              -121.035255,
              47.53345
            ],
            [
              -121.036644,
              47.533863
            ],
            [
              -121.037959,
              47.534365
            ],
            [
              -121.039148,
              47.534886
            ],
            [
              -121.040242,
              47.535482
            ],
            [
              -121.04143,
              47.535225
            ],
            [
              -121.042706,
              47.535008
            ],
            [
              -121.044086,
              47.535124
            ],
            [
              -121.045568,
              47.535273
            ],
            [
              -121.046623,
              47.534552
            ],
            [
              -121.047473,
              47.534333
            ],
            [
              -121.047922,
              47.534792
            ],
            [
              -121.048394,
              47.535317
            ],
            [
              -121.049407,
              47.535878
            ],
            [
              -121.050508,
              47.536596
            ],
            [
              -121.051572,
              47.537309
            ],
            [
              -121.052782,
              47.537917
            ],
            [
              -121.054104,
              47.538137
            ],
            [
              -121.055451,
              47.539401
            ],
            [
              -121.056514,
              47.540033
            ],
            [
              -121.057504,
              47.540937
            ],
            [
              -121.058471,
              47.542008
            ],
            [
              -121.059701,
              47.542031
            ],
            [
              -121.060576,
              47.542184
            ],
            [
              -121.060296,
              47.542557
            ],
            [
              -121.060143,
              47.543157
            ],
            [
              -121.060311,
              47.544162
            ],
            [
              -121.060677,
              47.545146
            ],
            [
              -121.061656,
              47.545899
            ],
            [
              -121.062859,
              47.546398
            ],
            [
              -121.064242,
              47.546815
            ],
            [
              -121.065246,
              47.547584
            ],
            [
              -121.065725,
              47.548532
            ],
            [
              -121.066931,
              47.549167
            ],
            [
              -121.068314,
              47.549585
            ],
            [
              -121.069553,
              47.550161
            ],
            [
              -121.070664,
              47.550866
            ],
            [
              -121.072111,
              47.551165
            ],
            [
              -121.073457,
              47.550897
            ],
            [
              -121.074426,
              47.550747
            ],
            [
              -121.075343,
              47.551222
            ],
            [
              -121.075739,
              47.551782
            ],
            [
              -121.076086,
              47.552783
            ],
            [
              -121.076347,
              47.553726
            ],
            [
              -121.076896,
              47.554607
            ],
            [
              -121.077707,
              47.555389
            ],
            [
              -121.077732,
              47.556368
            ],
            [
              -121.078318,
              47.557295
            ],
            [
              -121.078562,
              47.558313
            ],
            [
              -121.078738,
              47.559323
            ],
            [
              -121.0799,
              47.559954
            ],
            [
              -121.080892,
              47.56058
            ],
            [
              -121.081663,
              47.561377
            ],
            [
              -121.082142,
              47.562197
            ],
            [
              -121.08319,
              47.562974
            ],
            [
              -121.084205,
              47.563736
            ],
            [
              -121.085255,
              47.564707
            ],
            [
              -121.086337,
              47.565594
            ],
            [
              -121.087395,
              47.566224
            ],
            [
              -121.088034,
              47.567126
            ],
            [
              -121.08869,
              47.568052
            ],
            [
              -121.089238,
              47.568932
            ],
            [
              -121.09017,
              47.569989
            ],
            [
              -121.090081,
              47.570999
            ],
            [
              -121.089345,
              47.571996
            ],
            [
              -121.088265,
              47.572684
            ],
            [
              -121.087032,
              47.573482
            ],
            [
              -121.08633,
              47.573755
            ],
            [
              -121.0863,
              47.574112
            ],
            [
              -121.086729,
              47.574143
            ],
            [
              -121.08746,
              47.574664
            ],
            [
              -121.087585,
              47.575279
            ],
            [
              -121.088052,
              47.576257
            ],
            [
              -121.089254,
              47.577143
            ],
            [
              -121.089811,
              47.577996
            ],
            [
              -121.090776,
              47.578765
            ],
            [
              -121.091099,
              47.579748
            ],
            [
              -121.090665,
              47.58075
            ],
            [
              -121.091555,
              47.581467
            ],
            [
              -121.092396,
              47.582218
            ],
            [
              -121.092324,
              47.583244
            ],
            [
              -121.092228,
              47.584268
            ],
            [
              -121.092144,
              47.585287
            ],
            [
              -121.092732,
              47.586161
            ],
            [
              -121.09393,
              47.586731
            ],
            [
              -121.094889,
              47.587584
            ],
            [
              -121.095375,
              47.588631
            ],
            [
              -121.096053,
              47.589308
            ],
            [
              -121.097388,
              47.589777
            ],
            [
              -121.098821,
              47.590112
            ],
            [
              -121.100145,
              47.590492
            ],
            [
              -121.101654,
              47.590369
            ],
            [
              -121.102804,
              47.589576
            ],
            [
              -121.104322,
              47.58963
            ],
            [
              -121.105642,
              47.590136
            ],
            [
              -121.106785,
              47.590818
            ],
            [
              -121.107517,
              47.591715
            ],
            [
              -121.107935,
              47.592202
            ],
            [
              -121.108785,
              47.592437
            ],
            [
              -121.109083,
              47.592246
            ],
            [
              -121.110175,
              47.592412
            ],
            [
              -121.110657,
              47.592935
            ],
            [
              -121.110711,
              47.594084
            ],
            [
              -121.11098,
              47.595065
            ],
            [
              -121.111639,
              47.595843
            ],
            [
              -121.11253,
              47.596306
            ],
            [
              -121.113445,
              47.597065
            ],
            [
              -121.113569,
              47.597288
            ],
            [
              -121.17225,
              47.590054
            ],
            [
              -121.176577,
              47.566932
            ],
            [
              -121.217039,
              47.569752
            ],
            [
              -121.235394,
              47.544649
            ],
            [
              -121.263363,
              47.523424
            ],
            [
              -121.283693,
              47.518757
            ],
            [
              -121.303135,
              47.486023
            ],
            [
              -121.338706,
              47.476377
            ],
            [
              -121.37912,
              47.454411
            ],
            [
              -121.384996,
              47.434601
            ],
            [
              -121.410878,
              47.424539
            ],
            [
              -121.427417,
              47.420678
            ],
            [
              -121.426212,
              47.389592
            ],
            [
              -121.437788,
              47.371967
            ],
            [
              -121.465979,
              47.367631
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "53",
      "COUNTYFP": "023",
      "COUNTYNS": "01533500",
      "AFFGEOID": "0500000US53023",
      "GEOID": "53023",
      "NAME": "Garfield",
      "LSAD": "06",
      "ALAND": 1841054914,
      "AWATER": 19294817,
      "County_state": "Garfield,53"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -117.853168,
              46.560191
            ],
            [
              -117.863347,
              46.470667
            ],
            [
              -117.737303,
              46.471454
            ],
            [
              -117.737422,
              46.441995
            ],
            [
              -117.716629,
              46.441796
            ],
            [
              -117.71645,
              46.397608
            ],
            [
              -117.695085,
              46.397679
            ],
            [
              -117.694942,
              46.383254
            ],
            [
              -117.673946,
              46.383284
            ],
            [
              -117.674946,
              46.337651
            ],
            [
              -117.611903,
              46.338478
            ],
            [
              -117.612603,
              46.121323
            ],
            [
              -117.603296,
              46.121313
            ],
            [
              -117.60342451872,
              45.9988892323573
            ],
            [
              -117.603163,
              45.998887
            ],
            [
              -117.504833,
              45.998317
            ],
            [
              -117.480103,
              45.99787
            ],
            [
              -117.479935132756,
              45.997869469110505
            ],
            [
              -117.479988,
              46.121994
            ],
            [
              -117.419482,
              46.121956
            ],
            [
              -117.419334,
              46.383139
            ],
            [
              -117.398341,
              46.383457
            ],
            [
              -117.398429,
              46.397996
            ],
            [
              -117.355764,
              46.397645
            ],
            [
              -117.355982,
              46.412187
            ],
            [
              -117.228796,
              46.411306
            ],
            [
              -117.22812,
              46.462245
            ],
            [
              -117.238765,
              46.481187
            ],
            [
              -117.231337,
              46.500584
            ],
            [
              -117.254262,
              46.545082
            ],
            [
              -117.311447,
              46.580766
            ],
            [
              -117.337537,
              46.581972
            ],
            [
              -117.359074,
              46.602268
            ],
            [
              -117.382742,
              46.614684
            ],
            [
              -117.388638,
              46.625087
            ],
            [
              -117.385204,
              46.647928
            ],
            [
              -117.393028,
              46.654019
            ],
            [
              -117.4283,
              46.657107
            ],
            [
              -117.448346,
              46.674865
            ],
            [
              -117.464769,
              46.699626
            ],
            [
              -117.490004,
              46.695375
            ],
            [
              -117.500647,
              46.682053
            ],
            [
              -117.517164,
              46.676964
            ],
            [
              -117.551915,
              46.680133
            ],
            [
              -117.597792,
              46.672543
            ],
            [
              -117.626294,
              46.679197
            ],
            [
              -117.648253,
              46.699805
            ],
            [
              -117.68748,
              46.702594
            ],
            [
              -117.740868,
              46.694583
            ],
            [
              -117.757592,
              46.682346
            ],
            [
              -117.772468,
              46.64538
            ],
            [
              -117.800714,
              46.625645
            ],
            [
              -117.829091,
              46.622054
            ],
            [
              -117.85185,
              46.62467
            ],
            [
              -117.853168,
              46.560191
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "53",
      "COUNTYFP": "025",
      "COUNTYNS": "01531924",
      "AFFGEOID": "0500000US53025",
      "GEOID": "53025",
      "NAME": "Grant",
      "LSAD": "06",
      "ALAND": 6939832890,
      "AWATER": 289982469,
      "County_state": "Grant,53"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -120.042766,
              47.071553
            ],
            [
              -120.032966,
              47.054253
            ],
            [
              -120.011165,
              47.039353
            ],
            [
              -120.001122,
              46.993641
            ],
            [
              -119.966129,
              46.943757
            ],
            [
              -119.963191,
              46.918817
            ],
            [
              -119.964943,
              46.858804
            ],
            [
              -119.927537,
              46.817092
            ],
            [
              -119.927436,
              46.798445
            ],
            [
              -119.943054,
              46.774702
            ],
            [
              -119.969975,
              46.754973
            ],
            [
              -119.973036,
              46.737126
            ],
            [
              -119.972706,
              46.723239
            ],
            [
              -119.94644,
              46.675935
            ],
            [
              -119.917301,
              46.655423
            ],
            [
              -119.901483,
              46.635602
            ],
            [
              -119.897582,
              46.633271
            ],
            [
              -119.881548,
              46.630631
            ],
            [
              -119.874042,
              46.628283
            ],
            [
              -119.836401,
              46.625464
            ],
            [
              -119.750972,
              46.635662
            ],
            [
              -119.710104,
              46.648886
            ],
            [
              -119.659189,
              46.640333
            ],
            [
              -119.618479,
              46.647583
            ],
            [
              -119.581684,
              46.669906
            ],
            [
              -119.511388,
              46.727687
            ],
            [
              -119.485763,
              46.715668
            ],
            [
              -119.449693,
              46.685516
            ],
            [
              -119.45319,
              46.679243
            ],
            [
              -119.369165,
              46.678179
            ],
            [
              -119.369429,
              46.737695
            ],
            [
              -119.368662,
              46.912562
            ],
            [
              -118.982652,
              46.911344
            ],
            [
              -118.979777,
              47.261709
            ],
            [
              -118.976111,
              47.68962
            ],
            [
              -118.973504,
              47.943414
            ],
            [
              -118.981664,
              47.947977
            ],
            [
              -118.982234,
              47.962025
            ],
            [
              -119.103873,
              47.961763
            ],
            [
              -119.103966,
              47.946693
            ],
            [
              -119.125581,
              47.946803
            ],
            [
              -119.125904,
              47.917301
            ],
            [
              -119.1474,
              47.917388
            ],
            [
              -119.147399,
              47.90265
            ],
            [
              -119.190552,
              47.902465
            ],
            [
              -119.190631,
              47.887812
            ],
            [
              -119.212225,
              47.887708
            ],
            [
              -119.21241,
              47.800695
            ],
            [
              -119.2339,
              47.800662
            ],
            [
              -119.234166,
              47.771981
            ],
            [
              -119.255862,
              47.771687
            ],
            [
              -119.255783,
              47.742514
            ],
            [
              -119.277167,
              47.742603
            ],
            [
              -119.277238,
              47.728238
            ],
            [
              -119.298649,
              47.728328
            ],
            [
              -119.298843,
              47.670403
            ],
            [
              -119.320144,
              47.670232
            ],
            [
              -119.320304,
              47.626797
            ],
            [
              -119.341485,
              47.626748
            ],
            [
              -119.341617,
              47.621423
            ],
            [
              -119.405196,
              47.612636
            ],
            [
              -119.4478,
              47.584899
            ],
            [
              -119.447682,
              47.570455
            ],
            [
              -119.469099,
              47.57076
            ],
            [
              -119.490467,
              47.54202
            ],
            [
              -119.512092,
              47.54204
            ],
            [
              -119.511691,
              47.527633
            ],
            [
              -119.532889,
              47.527756
            ],
            [
              -119.533123,
              47.441207
            ],
            [
              -119.87576,
              47.437492
            ],
            [
              -119.875822,
              47.408305
            ],
            [
              -119.897173,
              47.407969
            ],
            [
              -119.897383,
              47.393472
            ],
            [
              -119.918746,
              47.393449
            ],
            [
              -119.918915,
              47.364475
            ],
            [
              -119.940236,
              47.364359
            ],
            [
              -119.940442,
              47.349435
            ],
            [
              -119.961341,
              47.348779
            ],
            [
              -119.961823,
              47.334952
            ],
            [
              -119.983234,
              47.334623
            ],
            [
              -119.983125,
              47.320428
            ],
            [
              -120.004704,
              47.319994
            ],
            [
              -120.004783,
              47.230602
            ],
            [
              -120.007074,
              47.220133
            ],
            [
              -120.002631,
              47.212267
            ],
            [
              -120.004175,
              47.182661
            ],
            [
              -120.010585,
              47.161275
            ],
            [
              -120.001166,
              47.14593
            ],
            [
              -120.002065,
              47.126153
            ],
            [
              -120.011966,
              47.111853
            ],
            [
              -120.026066,
              47.106853
            ],
            [
              -120.042766,
              47.071553
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "53",
      "COUNTYFP": "047",
      "COUNTYNS": "01531928",
      "AFFGEOID": "0500000US53047",
      "GEOID": "53047",
      "NAME": "Okanogan",
      "LSAD": "06",
      "ALAND": 13639309300,
      "AWATER": 120452753,
      "County_state": "Okanogan,53"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -120.88564,
              48.975212
            ],
            [
              -120.873379,
              48.962734
            ],
            [
              -120.848979,
              48.955911
            ],
            [
              -120.835436,
              48.939307
            ],
            [
              -120.792072,
              48.959406
            ],
            [
              -120.768515,
              48.95449
            ],
            [
              -120.754047,
              48.936262
            ],
            [
              -120.770362,
              48.927404
            ],
            [
              -120.772132,
              48.906705
            ],
            [
              -120.762704,
              48.899518
            ],
            [
              -120.766947,
              48.876412
            ],
            [
              -120.735281,
              48.864374
            ],
            [
              -120.731351,
              48.855015
            ],
            [
              -120.742399,
              48.822069
            ],
            [
              -120.735427,
              48.786487
            ],
            [
              -120.725523,
              48.77339
            ],
            [
              -120.709918,
              48.771814
            ],
            [
              -120.678163,
              48.735052
            ],
            [
              -120.655857,
              48.724342
            ],
            [
              -120.678195,
              48.717826
            ],
            [
              -120.678089,
              48.696197
            ],
            [
              -120.703432,
              48.696915
            ],
            [
              -120.718663,
              48.674492
            ],
            [
              -120.751904,
              48.657002
            ],
            [
              -120.788307,
              48.625115
            ],
            [
              -120.783659,
              48.601864
            ],
            [
              -120.760458,
              48.582761
            ],
            [
              -120.73066,
              48.582304
            ],
            [
              -120.722585,
              48.596143
            ],
            [
              -120.688515,
              48.575705
            ],
            [
              -120.708676,
              48.541802
            ],
            [
              -120.702069,
              48.531589
            ],
            [
              -120.698616,
              48.532217
            ],
            [
              -120.673088,
              48.532731
            ],
            [
              -120.659517,
              48.53516
            ],
            [
              -120.648991,
              48.532842
            ],
            [
              -120.649554,
              48.526207
            ],
            [
              -120.657021,
              48.516554
            ],
            [
              -120.649277,
              48.505578
            ],
            [
              -120.636565,
              48.501754
            ],
            [
              -120.633498,
              48.496091
            ],
            [
              -120.637208,
              48.496102
            ],
            [
              -120.650105,
              48.488479
            ],
            [
              -120.648618,
              48.476772
            ],
            [
              -120.640339,
              48.473856
            ],
            [
              -120.645507,
              48.463889
            ],
            [
              -120.654228,
              48.454643
            ],
            [
              -120.66191,
              48.451695
            ],
            [
              -120.658976,
              48.441442
            ],
            [
              -120.653859,
              48.430912
            ],
            [
              -120.643873,
              48.411937
            ],
            [
              -120.646786,
              48.40378
            ],
            [
              -120.636481,
              48.397286
            ],
            [
              -120.621101,
              48.398772
            ],
            [
              -120.601898,
              48.387123
            ],
            [
              -120.578926,
              48.378282
            ],
            [
              -120.566366,
              48.35815
            ],
            [
              -120.583984,
              48.341613
            ],
            [
              -120.580118,
              48.318926
            ],
            [
              -120.547534,
              48.307003
            ],
            [
              -120.522077,
              48.309774
            ],
            [
              -120.503971,
              48.302381
            ],
            [
              -120.488317,
              48.287254
            ],
            [
              -120.467722,
              48.270589
            ],
            [
              -120.44321,
              48.260165
            ],
            [
              -120.414349,
              48.24858
            ],
            [
              -120.386247,
              48.231808
            ],
            [
              -120.36975,
              48.218423
            ],
            [
              -120.349518,
              48.207192
            ],
            [
              -120.35561,
              48.183239
            ],
            [
              -120.362204,
              48.164378
            ],
            [
              -120.348184,
              48.144212
            ],
            [
              -120.32672,
              48.132147
            ],
            [
              -120.32279,
              48.126494
            ],
            [
              -120.315765,
              48.124717
            ],
            [
              -120.300721,
              48.127484
            ],
            [
              -120.296016,
              48.129526
            ],
            [
              -120.289547,
              48.127301
            ],
            [
              -120.284505,
              48.123749
            ],
            [
              -120.278108,
              48.1212
            ],
            [
              -120.272924,
              48.116708
            ],
            [
              -120.260568,
              48.110432
            ],
            [
              -120.252208,
              48.105102
            ],
            [
              -120.243778,
              48.100341
            ],
            [
              -120.235741,
              48.093897
            ],
            [
              -120.233303,
              48.088385
            ],
            [
              -120.224295,
              48.08419
            ],
            [
              -120.216295,
              48.081257
            ],
            [
              -120.202897,
              48.077162
            ],
            [
              -120.187694,
              48.078516
            ],
            [
              -120.180277,
              48.07327
            ],
            [
              -120.164744,
              48.068623
            ],
            [
              -120.15785,
              48.068161
            ],
            [
              -120.14915,
              48.065747
            ],
            [
              -120.142613,
              48.063943
            ],
            [
              -120.133535,
              48.057595
            ],
            [
              -120.131954,
              48.055722
            ],
            [
              -120.137212,
              48.053612
            ],
            [
              -120.140363,
              48.054142
            ],
            [
              -120.14867,
              48.050766
            ],
            [
              -120.149204,
              48.044081
            ],
            [
              -120.13969,
              48.03516
            ],
            [
              -120.124739,
              48.032124
            ],
            [
              -120.12021,
              48.029034
            ],
            [
              -120.112073,
              48.024853
            ],
            [
              -120.104473,
              48.022375
            ],
            [
              -120.098112,
              48.020858
            ],
            [
              -120.090176,
              48.018006
            ],
            [
              -120.086272,
              48.012778
            ],
            [
              -120.081832,
              48.006223
            ],
            [
              -120.07809,
              47.999424
            ],
            [
              -120.080016,
              47.997372
            ],
            [
              -120.077586,
              47.990942
            ],
            [
              -120.070011,
              47.985122
            ],
            [
              -120.065804,
              47.982159
            ],
            [
              -120.065019,
              47.977106
            ],
            [
              -120.067159,
              47.973444
            ],
            [
              -120.07011,
              47.970135
            ],
            [
              -120.067011,
              47.966821
            ],
            [
              -120.064481,
              47.966749
            ],
            [
              -120.055587,
              47.962637
            ],
            [
              -120.04821,
              47.960626
            ],
            [
              -119.969504,
              47.960563
            ],
            [
              -119.937514,
              47.960435
            ],
            [
              -119.914103,
              47.96045
            ],
            [
              -119.885072,
              47.960457
            ],
            [
              -119.871206,
              47.960492
            ],
            [
              -119.870595,
              47.960455
            ],
            [
              -119.882109,
              47.969872
            ],
            [
              -119.87245,
              47.993136
            ],
            [
              -119.869543,
              48.018853
            ],
            [
              -119.89535,
              48.050044
            ],
            [
              -119.891656,
              48.055053
            ],
            [
              -119.8433,
              48.077058
            ],
            [
              -119.808441,
              48.086855
            ],
            [
              -119.781452,
              48.086787
            ],
            [
              -119.774593,
              48.104213
            ],
            [
              -119.746845,
              48.102567
            ],
            [
              -119.726707,
              48.085028
            ],
            [
              -119.700875,
              48.084023
            ],
            [
              -119.684293,
              48.093254
            ],
            [
              -119.670466,
              48.067921
            ],
            [
              -119.693712,
              48.032056
            ],
            [
              -119.688678,
              48.022732
            ],
            [
              -119.652468,
              48.000221
            ],
            [
              -119.631053,
              47.996278
            ],
            [
              -119.614447,
              48.000089
            ],
            [
              -119.595103,
              48.020856
            ],
            [
              -119.517571,
              48.062615
            ],
            [
              -119.516111,
              48.074784
            ],
            [
              -119.500994,
              48.082429
            ],
            [
              -119.436257,
              48.076688
            ],
            [
              -119.404108,
              48.050964
            ],
            [
              -119.370477,
              48.052669
            ],
            [
              -119.343523,
              48.049432
            ],
            [
              -119.327934,
              48.070274
            ],
            [
              -119.327521,
              48.096656
            ],
            [
              -119.298585,
              48.115485
            ],
            [
              -119.25685,
              48.102027
            ],
            [
              -119.233335,
              48.113173
            ],
            [
              -119.215301,
              48.115313
            ],
            [
              -119.200186,
              48.125197
            ],
            [
              -119.179346,
              48.12789
            ],
            [
              -119.150983,
              48.146067
            ],
            [
              -119.123772,
              48.149709
            ],
            [
              -119.107268,
              48.140426
            ],
            [
              -119.05697,
              48.136974
            ],
            [
              -119.028858,
              48.113133
            ],
            [
              -119.018794,
              48.069784
            ],
            [
              -119.00319,
              48.043263
            ],
            [
              -118.980238,
              48.037227
            ],
            [
              -118.950782,
              48.018884
            ],
            [
              -118.965324,
              47.983752
            ],
            [
              -118.982234,
              47.962025
            ],
            [
              -118.981664,
              47.947977
            ],
            [
              -118.973504,
              47.943414
            ],
            [
              -118.957051,
              47.940857
            ],
            [
              -118.85196,
              47.957906
            ],
            [
              -118.845785,
              47.963478
            ],
            [
              -118.843888,
              48.481739
            ],
            [
              -118.86952,
              48.481626
            ],
            [
              -118.869633,
              48.653826
            ],
            [
              -118.837006,
              48.653869
            ],
            [
              -118.83661303558,
              49.0003077592976
            ],
            [
              -119.132102,
              49.000262
            ],
            [
              -119.137274,
              49.000297
            ],
            [
              -119.428678,
              49.000253
            ],
            [
              -119.4577,
              49.000261
            ],
            [
              -119.701218,
              49.000258
            ],
            [
              -119.702016,
              49.000269
            ],
            [
              -119.876195,
              49.000448
            ],
            [
              -120.001199,
              48.999418
            ],
            [
              -120.035509685132,
              48.9995357489334
            ],
            [
              -120.376216,
              49.000705
            ],
            [
              -120.716604,
              49.000188
            ],
            [
              -120.851560900318,
              49.000280080019294
            ],
            [
              -120.865656,
              48.979833
            ],
            [
              -120.88564,
              48.975212
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "53",
      "COUNTYFP": "021",
      "COUNTYNS": "01531822",
      "AFFGEOID": "0500000US53021",
      "GEOID": "53021",
      "NAME": "Franklin",
      "LSAD": "06",
      "ALAND": 3215718022,
      "AWATER": 60509234,
      "County_state": "Franklin,53"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -119.45319,
              46.679243
            ],
            [
              -119.45441,
              46.67004
            ],
            [
              -119.413046,
              46.652631
            ],
            [
              -119.403042,
              46.613043
            ],
            [
              -119.367361,
              46.582656
            ],
            [
              -119.326732,
              46.563247
            ],
            [
              -119.305233,
              46.539361
            ],
            [
              -119.269042,
              46.51917
            ],
            [
              -119.259852,
              46.496554
            ],
            [
              -119.263886,
              46.480666
            ],
            [
              -119.259161,
              46.435726
            ],
            [
              -119.266856,
              46.403284
            ],
            [
              -119.269336,
              46.370147
            ],
            [
              -119.256932,
              46.345408
            ],
            [
              -119.250201,
              46.315001
            ],
            [
              -119.267202,
              46.284625
            ],
            [
              -119.265156,
              46.273286
            ],
            [
              -119.238231,
              46.262084
            ],
            [
              -119.203363,
              46.235341
            ],
            [
              -119.15905,
              46.226688
            ],
            [
              -119.124256,
              46.224212
            ],
            [
              -119.104502,
              46.218488
            ],
            [
              -119.047241,
              46.196002
            ],
            [
              -119.041498,
              46.192667
            ],
            [
              -119.031916,
              46.207459
            ],
            [
              -119.023453,
              46.216616
            ],
            [
              -118.972827,
              46.238705
            ],
            [
              -118.863464,
              46.251389
            ],
            [
              -118.830074,
              46.281625
            ],
            [
              -118.766898,
              46.297916
            ],
            [
              -118.774316,
              46.3166
            ],
            [
              -118.762335,
              46.324983
            ],
            [
              -118.746199,
              46.359512
            ],
            [
              -118.704897,
              46.359593
            ],
            [
              -118.69117,
              46.381099
            ],
            [
              -118.668922,
              46.389155
            ],
            [
              -118.660776,
              46.402505
            ],
            [
              -118.633826,
              46.414022
            ],
            [
              -118.638033,
              46.433415
            ],
            [
              -118.63195,
              46.454315
            ],
            [
              -118.618991,
              46.474362
            ],
            [
              -118.615592,
              46.504543
            ],
            [
              -118.592409,
              46.525857
            ],
            [
              -118.565433,
              46.534952
            ],
            [
              -118.548011,
              46.555929
            ],
            [
              -118.527994,
              46.567664
            ],
            [
              -118.49652,
              46.575534
            ],
            [
              -118.471646,
              46.597262
            ],
            [
              -118.452637,
              46.596973
            ],
            [
              -118.423181,
              46.583744
            ],
            [
              -118.389726,
              46.580602
            ],
            [
              -118.361717,
              46.598014
            ],
            [
              -118.322539,
              46.606812
            ],
            [
              -118.305679,
              46.605477
            ],
            [
              -118.273861,
              46.593816
            ],
            [
              -118.244257,
              46.600732
            ],
            [
              -118.228635,
              46.593362
            ],
            [
              -118.21566,
              46.588924
            ],
            [
              -118.199796,
              46.624186
            ],
            [
              -118.230759,
              46.657042
            ],
            [
              -118.217536,
              46.679278
            ],
            [
              -118.249122,
              46.734138
            ],
            [
              -118.210243,
              46.738833
            ],
            [
              -118.44385,
              46.736865
            ],
            [
              -119.369429,
              46.737695
            ],
            [
              -119.369165,
              46.678179
            ],
            [
              -119.45319,
              46.679243
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "53",
      "COUNTYFP": "039",
      "COUNTYNS": "01529219",
      "AFFGEOID": "0500000US53039",
      "GEOID": "53039",
      "NAME": "Klickitat",
      "LSAD": "06",
      "ALAND": 4847516021,
      "AWATER": 84930620,
      "County_state": "Klickitat,53"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -121.612232,
              46.044093
            ],
            [
              -121.608618,
              45.870357
            ],
            [
              -121.609246,
              45.782519
            ],
            [
              -121.517391,
              45.781989
            ],
            [
              -121.53835,
              45.770126
            ],
            [
              -121.521376,
              45.748152
            ],
            [
              -121.523685853632,
              45.7249021020319
            ],
            [
              -121.522392,
              45.724677
            ],
            [
              -121.499153,
              45.720846
            ],
            [
              -121.462849,
              45.701367
            ],
            [
              -121.441224595532,
              45.6973034385521
            ],
            [
              -121.423592,
              45.69399
            ],
            [
              -121.401739,
              45.692887
            ],
            [
              -121.372574,
              45.703111
            ],
            [
              -121.33777,
              45.704949
            ],
            [
              -121.312198,
              45.699925
            ],
            [
              -121.287323,
              45.687019
            ],
            [
              -121.251183,
              45.67839
            ],
            [
              -121.215779,
              45.671238
            ],
            [
              -121.200367,
              45.649829
            ],
            [
              -121.195233,
              45.629513
            ],
            [
              -121.196556,
              45.616689
            ],
            [
              -121.183841,
              45.606441
            ],
            [
              -121.167852,
              45.606098
            ],
            [
              -121.145534,
              45.607886
            ],
            [
              -121.139483,
              45.611962
            ],
            [
              -121.131953,
              45.609762
            ],
            [
              -121.127724471392,
              45.6124956073897
            ],
            [
              -121.1222,
              45.616067
            ],
            [
              -121.117052,
              45.618117
            ],
            [
              -121.120064,
              45.623134
            ],
            [
              -121.084933,
              45.647893
            ],
            [
              -121.06437,
              45.652549
            ],
            [
              -121.033582,
              45.650998
            ],
            [
              -121.007449,
              45.653217
            ],
            [
              -120.983478,
              45.648344
            ],
            [
              -120.977978,
              45.649345
            ],
            [
              -120.953077,
              45.656745
            ],
            [
              -120.943977,
              45.656445
            ],
            [
              -120.915876,
              45.641345
            ],
            [
              -120.915795173154,
              45.641301218792
            ],
            [
              -120.913476,
              45.640045
            ],
            [
              -120.895575,
              45.642945
            ],
            [
              -120.870042,
              45.661242
            ],
            [
              -120.855674,
              45.671545
            ],
            [
              -120.788872,
              45.686246
            ],
            [
              -120.724171,
              45.706446
            ],
            [
              -120.68937,
              45.715847
            ],
            [
              -120.668869,
              45.730147
            ],
            [
              -120.65353434410001,
              45.7372486812962
            ],
            [
              -120.634968,
              45.745847
            ],
            [
              -120.591166,
              45.746547
            ],
            [
              -120.559465,
              45.738348
            ],
            [
              -120.521964,
              45.709848
            ],
            [
              -120.505863,
              45.700048
            ],
            [
              -120.482362,
              45.694449
            ],
            [
              -120.40396,
              45.699249
            ],
            [
              -120.329057,
              45.71105
            ],
            [
              -120.288656,
              45.72015
            ],
            [
              -120.282156,
              45.72125
            ],
            [
              -120.210754,
              45.725951
            ],
            [
              -120.170453,
              45.761951
            ],
            [
              -120.141352,
              45.773152
            ],
            [
              -120.07015,
              45.785152
            ],
            [
              -120.001148,
              45.811902
            ],
            [
              -119.999501176112,
              45.812481718848495
            ],
            [
              -119.965744,
              45.824365
            ],
            [
              -119.907461,
              45.828135
            ],
            [
              -119.876144,
              45.834718
            ],
            [
              -119.868272037091,
              45.8359407146783
            ],
            [
              -119.865829,
              46.040858
            ],
            [
              -120.386767,
              46.041533
            ],
            [
              -120.428326,
              46.040169
            ],
            [
              -120.449333,
              46.041478
            ],
            [
              -120.57421,
              46.041503
            ],
            [
              -121.068709,
              46.042173
            ],
            [
              -121.132977,
              46.044523
            ],
            [
              -121.522321,
              46.044006
            ],
            [
              -121.612232,
              46.044093
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "53",
      "COUNTYFP": "011",
      "COUNTYNS": "01531820",
      "AFFGEOID": "0500000US53011",
      "GEOID": "53011",
      "NAME": "Clark",
      "LSAD": "06",
      "ALAND": 1627728322,
      "AWATER": 70264672,
      "County_state": "Clark,53"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -122.795963,
              45.825024
            ],
            [
              -122.795605,
              45.81
            ],
            [
              -122.769532,
              45.780583
            ],
            [
              -122.761451,
              45.759163
            ],
            [
              -122.76097413795301,
              45.7503749615244
            ],
            [
              -122.760108,
              45.734413
            ],
            [
              -122.762189526428,
              45.7285767375573
            ],
            [
              -122.772511,
              45.699637
            ],
            [
              -122.774511,
              45.680437
            ],
            [
              -122.76381,
              45.657138
            ],
            [
              -122.762414110309,
              45.656431935411796
            ],
            [
              -122.738109,
              45.644138
            ],
            [
              -122.713309,
              45.637438
            ],
            [
              -122.691008,
              45.624739
            ],
            [
              -122.675008,
              45.618039
            ],
            [
              -122.643907,
              45.609739
            ],
            [
              -122.602606,
              45.607639
            ],
            [
              -122.58600555751501,
              45.60474253600229
            ],
            [
              -122.581406,
              45.60394
            ],
            [
              -122.54995527692701,
              45.597157530568595
            ],
            [
              -122.548149,
              45.596768
            ],
            [
              -122.523668,
              45.589632
            ],
            [
              -122.505353096256,
              45.585928668035294
            ],
            [
              -122.492259,
              45.583281
            ],
            [
              -122.479315,
              45.579761
            ],
            [
              -122.474659,
              45.578305
            ],
            [
              -122.453891,
              45.567313
            ],
            [
              -122.438674,
              45.563585
            ],
            [
              -122.410706,
              45.567633
            ],
            [
              -122.401222955157,
              45.5710983445711
            ],
            [
              -122.391802,
              45.574541
            ],
            [
              -122.380302,
              45.575941
            ],
            [
              -122.352802,
              45.569441
            ],
            [
              -122.331502,
              45.548241
            ],
            [
              -122.294901,
              45.543541
            ],
            [
              -122.266701,
              45.543841
            ],
            [
              -122.262625,
              45.544321
            ],
            [
              -122.248993,
              45.547745
            ],
            [
              -122.24891194766401,
              45.5477731000169
            ],
            [
              -122.248995,
              45.614597
            ],
            [
              -122.249841,
              45.818941
            ],
            [
              -122.244515,
              45.866561
            ],
            [
              -122.245856,
              46.053853
            ],
            [
              -122.272974,
              46.059404
            ],
            [
              -122.328885,
              46.010054
            ],
            [
              -122.3148,
              45.990829
            ],
            [
              -122.332663,
              45.965064
            ],
            [
              -122.372915,
              45.960758
            ],
            [
              -122.398515,
              45.972496
            ],
            [
              -122.442208,
              45.975849
            ],
            [
              -122.446446,
              45.983182
            ],
            [
              -122.477104,
              45.988089
            ],
            [
              -122.496959,
              45.980747
            ],
            [
              -122.510669,
              45.984935
            ],
            [
              -122.534496,
              45.979217
            ],
            [
              -122.557421,
              45.953845
            ],
            [
              -122.578026,
              45.954696
            ],
            [
              -122.61573,
              45.935702
            ],
            [
              -122.64619,
              45.937256
            ],
            [
              -122.657632,
              45.928134
            ],
            [
              -122.669259,
              45.936991
            ],
            [
              -122.690877,
              45.941576
            ],
            [
              -122.68926,
              45.929111
            ],
            [
              -122.72091,
              45.931892
            ],
            [
              -122.725933,
              45.915729
            ],
            [
              -122.738996,
              45.915287
            ],
            [
              -122.737275,
              45.902538
            ],
            [
              -122.748456,
              45.898808
            ],
            [
              -122.732406,
              45.894245
            ],
            [
              -122.721949,
              45.872784
            ],
            [
              -122.719752,
              45.867613
            ],
            [
              -122.743205,
              45.870121
            ],
            [
              -122.785515,
              45.850536
            ],
            [
              -122.785696,
              45.844216
            ],
            [
              -122.795963,
              45.825024
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "53",
      "COUNTYFP": "057",
      "COUNTYNS": "01531402",
      "AFFGEOID": "0500000US53057",
      "GEOID": "53057",
      "NAME": "Skagit",
      "LSAD": "06",
      "ALAND": 4481040001,
      "AWATER": 484058876,
      "County_state": "Skagit,53"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -122.653612,
              48.548975
            ],
            [
              -122.654342,
              48.537956
            ],
            [
              -122.652041,
              48.531329
            ],
            [
              -122.649256,
              48.528769
            ],
            [
              -122.640414,
              48.52586
            ],
            [
              -122.635738,
              48.526021
            ],
            [
              -122.619858,
              48.529246
            ],
            [
              -122.599948,
              48.536904
            ],
            [
              -122.590194,
              48.536259
            ],
            [
              -122.583565,
              48.53234
            ],
            [
              -122.572967,
              48.529028
            ],
            [
              -122.578856,
              48.54813
            ],
            [
              -122.583985,
              48.551534
            ],
            [
              -122.592901,
              48.553635
            ],
            [
              -122.610841,
              48.561146
            ],
            [
              -122.629321,
              48.5722
            ],
            [
              -122.642597,
              48.588339
            ],
            [
              -122.649405,
              48.588457
            ],
            [
              -122.652385,
              48.583432
            ],
            [
              -122.650786,
              48.554019
            ],
            [
              -122.653612,
              48.548975
            ]
          ]
        ],
        [
          [
            [
              -122.69806,
              48.62308
            ],
            [
              -122.699266,
              48.621115
            ],
            [
              -122.686136,
              48.613267
            ],
            [
              -122.676796,
              48.610055
            ],
            [
              -122.666149,
              48.608088
            ],
            [
              -122.657016,
              48.609891
            ],
            [
              -122.674173,
              48.629944
            ],
            [
              -122.69806,
              48.62308
            ]
          ]
        ],
        [
          [
            [
              -122.712981,
              48.47879
            ],
            [
              -122.712322,
              48.464143
            ],
            [
              -122.710362,
              48.461584
            ],
            [
              -122.700603,
              48.457632
            ],
            [
              -122.695587,
              48.460558
            ],
            [
              -122.695725,
              48.464785
            ],
            [
              -122.689121,
              48.476849
            ],
            [
              -122.664623,
              48.478128
            ],
            [
              -122.657753,
              48.47294
            ],
            [
              -122.654844,
              48.454087
            ],
            [
              -122.667249,
              48.442503
            ],
            [
              -122.674085,
              48.441979
            ],
            [
              -122.674188,
              48.443327
            ],
            [
              -122.677072,
              48.444059
            ],
            [
              -122.678928,
              48.439466
            ],
            [
              -122.674158,
              48.424726
            ],
            [
              -122.665338,
              48.416453
            ],
            [
              -122.654805,
              48.411066
            ],
            [
              -122.612655,
              48.418328
            ],
            [
              -122.581607,
              48.429244
            ],
            [
              -122.575254,
              48.443333
            ],
            [
              -122.568348,
              48.44499
            ],
            [
              -122.557298,
              48.444438
            ],
            [
              -122.551221,
              48.439465
            ],
            [
              -122.558403,
              48.426758
            ],
            [
              -122.554536,
              48.40604
            ],
            [
              -122.547492,
              48.399889
            ],
            [
              -122.539449,
              48.39719
            ],
            [
              -122.533452,
              48.383409
            ],
            [
              -122.507437,
              48.364666
            ],
            [
              -122.497686,
              48.361837
            ],
            [
              -122.482423,
              48.361737
            ],
            [
              -122.475529,
              48.359912
            ],
            [
              -122.442678,
              48.337934
            ],
            [
              -122.424102,
              48.334346
            ],
            [
              -122.408718,
              48.326413
            ],
            [
              -122.38431,
              48.304123
            ],
            [
              -122.378296248755,
              48.2976822178335
            ],
            [
              -122.201274,
              48.297634
            ],
            [
              -122.18245,
              48.297249
            ],
            [
              -121.996069,
              48.297499
            ],
            [
              -121.96665,
              48.297434
            ],
            [
              -121.577501,
              48.298967
            ],
            [
              -121.574049,
              48.297369
            ],
            [
              -121.001658,
              48.296009
            ],
            [
              -121.071483,
              48.317956
            ],
            [
              -121.059447,
              48.34668
            ],
            [
              -121.043032,
              48.345726
            ],
            [
              -121.043504,
              48.363993
            ],
            [
              -121.065763,
              48.391789
            ],
            [
              -121.037972,
              48.431847
            ],
            [
              -121.040994,
              48.445737
            ],
            [
              -121.063302,
              48.460033
            ],
            [
              -121.049714,
              48.485399
            ],
            [
              -121.036234,
              48.495297
            ],
            [
              -121.001089,
              48.494203
            ],
            [
              -120.99293,
              48.499045
            ],
            [
              -120.951068,
              48.499637
            ],
            [
              -120.950502,
              48.529581
            ],
            [
              -120.904596,
              48.523653
            ],
            [
              -120.90396,
              48.533401
            ],
            [
              -120.885401,
              48.538187
            ],
            [
              -120.863213,
              48.530074
            ],
            [
              -120.857427,
              48.547676
            ],
            [
              -120.823947,
              48.54503
            ],
            [
              -120.809312,
              48.528626
            ],
            [
              -120.816787,
              48.519604
            ],
            [
              -120.787932,
              48.5071
            ],
            [
              -120.765627,
              48.508873
            ],
            [
              -120.702069,
              48.531589
            ],
            [
              -120.708676,
              48.541802
            ],
            [
              -120.688515,
              48.575705
            ],
            [
              -120.722585,
              48.596143
            ],
            [
              -120.73066,
              48.582304
            ],
            [
              -120.760458,
              48.582761
            ],
            [
              -120.783659,
              48.601864
            ],
            [
              -120.788307,
              48.625115
            ],
            [
              -120.751904,
              48.657002
            ],
            [
              -120.908357,
              48.657003
            ],
            [
              -120.908358,
              48.640901
            ],
            [
              -121.564067,
              48.642332
            ],
            [
              -122.210321,
              48.645689
            ],
            [
              -122.215836,
              48.64569
            ],
            [
              -122.323328,
              48.645349
            ],
            [
              -122.488793968804,
              48.645404890839
            ],
            [
              -122.486878,
              48.643122
            ],
            [
              -122.46425,
              48.625717
            ],
            [
              -122.448702,
              48.622624
            ],
            [
              -122.425271,
              48.599522
            ],
            [
              -122.433059,
              48.581609
            ],
            [
              -122.44456,
              48.570115
            ],
            [
              -122.478431,
              48.559303
            ],
            [
              -122.482406,
              48.559653
            ],
            [
              -122.488421,
              48.564665
            ],
            [
              -122.495904,
              48.575927
            ],
            [
              -122.512372,
              48.578067
            ],
            [
              -122.534787,
              48.57596
            ],
            [
              -122.534719,
              48.574246
            ],
            [
              -122.531978,
              48.568644
            ],
            [
              -122.52537,
              48.567344
            ],
            [
              -122.504428,
              48.564775
            ],
            [
              -122.498463,
              48.556206
            ],
            [
              -122.485288,
              48.528106
            ],
            [
              -122.483872,
              48.521891
            ],
            [
              -122.484996,
              48.50962
            ],
            [
              -122.483501,
              48.49243
            ],
            [
              -122.478851,
              48.481736
            ],
            [
              -122.473763,
              48.47975
            ],
            [
              -122.46967,
              48.474975
            ],
            [
              -122.469634,
              48.472187
            ],
            [
              -122.471832,
              48.470724
            ],
            [
              -122.500721,
              48.460887
            ],
            [
              -122.511348,
              48.461825
            ],
            [
              -122.515056,
              48.465554
            ],
            [
              -122.526943,
              48.468004
            ],
            [
              -122.532845,
              48.466057
            ],
            [
              -122.537355,
              48.466749
            ],
            [
              -122.556834,
              48.498812
            ],
            [
              -122.568071,
              48.50821
            ],
            [
              -122.598469,
              48.512169
            ],
            [
              -122.599951,
              48.520946
            ],
            [
              -122.606961,
              48.522152
            ],
            [
              -122.615183,
              48.521427
            ],
            [
              -122.671386,
              48.50398
            ],
            [
              -122.676922,
              48.504484
            ],
            [
              -122.679122,
              48.507797
            ],
            [
              -122.684521,
              48.509123
            ],
            [
              -122.701644,
              48.497622
            ],
            [
              -122.70713297974201,
              48.4885042028315
            ],
            [
              -122.712981,
              48.47879
            ]
          ]
        ],
        [
          [
            [
              -122.739898,
              48.583949
            ],
            [
              -122.73944,
              48.573893
            ],
            [
              -122.736199,
              48.569005
            ],
            [
              -122.73048,
              48.565602
            ],
            [
              -122.724031,
              48.549906
            ],
            [
              -122.722407,
              48.540606
            ],
            [
              -122.717278,
              48.539739
            ],
            [
              -122.68944,
              48.543903
            ],
            [
              -122.670638,
              48.568812
            ],
            [
              -122.691745,
              48.590612
            ],
            [
              -122.694672,
              48.596602
            ],
            [
              -122.714512,
              48.60878
            ],
            [
              -122.72493,
              48.603263
            ],
            [
              -122.739898,
              48.583949
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "53",
      "COUNTYFP": "071",
      "COUNTYNS": "01531819",
      "AFFGEOID": "0500000US53071",
      "GEOID": "53071",
      "NAME": "Walla Walla",
      "LSAD": "06",
      "ALAND": 3289366719,
      "AWATER": 74935920,
      "County_state": "Walla Walla,53"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -119.031916,
              46.207459
            ],
            [
              -119.041498,
              46.192667
            ],
            [
              -119.014206,
              46.176199
            ],
            [
              -118.974664,
              46.139523
            ],
            [
              -118.96784,
              46.115605
            ],
            [
              -118.946499,
              46.082882
            ],
            [
              -118.94195,
              46.065325
            ],
            [
              -118.947188,
              46.05144
            ],
            [
              -118.941323,
              46.027528
            ],
            [
              -118.961703,
              46.019333
            ],
            [
              -118.982549,
              46.003545
            ],
            [
              -118.98385,
              46.002465
            ],
            [
              -118.987258,
              45.999855
            ],
            [
              -118.98719609037701,
              45.9997905520367
            ],
            [
              -118.987129,
              45.999855
            ],
            [
              -118.941242,
              46.000574
            ],
            [
              -118.67787,
              46.000935
            ],
            [
              -118.658717,
              46.000955
            ],
            [
              -118.639332,
              46.000994
            ],
            [
              -118.637725,
              46.00097
            ],
            [
              -118.606789334728,
              46.000888673427006
            ],
            [
              -118.579906,
              46.000818
            ],
            [
              -118.57571,
              46.000718
            ],
            [
              -118.569392,
              46.000773
            ],
            [
              -118.537119,
              46.00084
            ],
            [
              -118.497027,
              46.00062
            ],
            [
              -118.470756,
              46.000632
            ],
            [
              -118.403788052999,
              46.000589962023
            ],
            [
              -118.37836,
              46.000574
            ],
            [
              -118.36779,
              46.000622
            ],
            [
              -118.314982,
              46.000453
            ],
            [
              -118.283526,
              46.000787
            ],
            [
              -118.256368,
              46.000439
            ],
            [
              -118.25253,
              46.000459
            ],
            [
              -118.236584,
              46.000418
            ],
            [
              -118.228941,
              46.000421
            ],
            [
              -118.146028,
              46.000701
            ],
            [
              -118.131019,
              46.00028
            ],
            [
              -118.126197,
              46.000282
            ],
            [
              -117.99694971271,
              46.000786848785495
            ],
            [
              -117.993109,
              46.019722
            ],
            [
              -117.991481,
              46.207702
            ],
            [
              -118.116624,
              46.20805
            ],
            [
              -118.116117,
              46.295455
            ],
            [
              -118.241872,
              46.295064
            ],
            [
              -118.242518,
              46.56008
            ],
            [
              -118.228013,
              46.560413
            ],
            [
              -118.228635,
              46.593362
            ],
            [
              -118.244257,
              46.600732
            ],
            [
              -118.273861,
              46.593816
            ],
            [
              -118.305679,
              46.605477
            ],
            [
              -118.322539,
              46.606812
            ],
            [
              -118.361717,
              46.598014
            ],
            [
              -118.389726,
              46.580602
            ],
            [
              -118.423181,
              46.583744
            ],
            [
              -118.452637,
              46.596973
            ],
            [
              -118.471646,
              46.597262
            ],
            [
              -118.49652,
              46.575534
            ],
            [
              -118.527994,
              46.567664
            ],
            [
              -118.548011,
              46.555929
            ],
            [
              -118.565433,
              46.534952
            ],
            [
              -118.592409,
              46.525857
            ],
            [
              -118.615592,
              46.504543
            ],
            [
              -118.618991,
              46.474362
            ],
            [
              -118.63195,
              46.454315
            ],
            [
              -118.638033,
              46.433415
            ],
            [
              -118.633826,
              46.414022
            ],
            [
              -118.660776,
              46.402505
            ],
            [
              -118.668922,
              46.389155
            ],
            [
              -118.69117,
              46.381099
            ],
            [
              -118.704897,
              46.359593
            ],
            [
              -118.746199,
              46.359512
            ],
            [
              -118.762335,
              46.324983
            ],
            [
              -118.774316,
              46.3166
            ],
            [
              -118.766898,
              46.297916
            ],
            [
              -118.830074,
              46.281625
            ],
            [
              -118.863464,
              46.251389
            ],
            [
              -118.972827,
              46.238705
            ],
            [
              -119.023453,
              46.216616
            ],
            [
              -119.031916,
              46.207459
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "53",
      "COUNTYFP": "049",
      "COUNTYNS": "01513274",
      "AFFGEOID": "0500000US53049",
      "GEOID": "53049",
      "NAME": "Pacific",
      "LSAD": "06",
      "ALAND": 2415872174,
      "AWATER": 760845734,
      "County_state": "Pacific,53"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -124.09834303160001,
              46.7940669968361
            ],
            [
              -124.096655,
              46.784374
            ],
            [
              -124.095041,
              46.756812
            ],
            [
              -124.096515,
              46.746202
            ],
            [
              -124.092176,
              46.741624
            ],
            [
              -124.080983,
              46.735003
            ],
            [
              -124.063117,
              46.733664
            ],
            [
              -124.046399,
              46.725686
            ],
            [
              -124.042111,
              46.722783
            ],
            [
              -124.042478,
              46.72004
            ],
            [
              -124.022413,
              46.708973
            ],
            [
              -124.003458,
              46.702337
            ],
            [
              -123.994242,
              46.707929
            ],
            [
              -123.987521,
              46.707507
            ],
            [
              -123.973663,
              46.703353
            ],
            [
              -123.966886,
              46.705184
            ],
            [
              -123.975157,
              46.713971
            ],
            [
              -123.979655,
              46.724658
            ],
            [
              -123.974994,
              46.733391
            ],
            [
              -123.968564,
              46.736106
            ],
            [
              -123.948683,
              46.725369
            ],
            [
              -123.929073,
              46.725278
            ],
            [
              -123.916874,
              46.726739
            ],
            [
              -123.91285,
              46.730647
            ],
            [
              -123.91584,
              46.737322
            ],
            [
              -123.916371,
              46.741322
            ],
            [
              -123.910716,
              46.746715
            ],
            [
              -123.898641,
              46.750205
            ],
            [
              -123.893054,
              46.750204
            ],
            [
              -123.87668,
              46.730657
            ],
            [
              -123.870782,
              46.728327
            ],
            [
              -123.862149,
              46.727749
            ],
            [
              -123.848725,
              46.719898
            ],
            [
              -123.83347,
              46.718322
            ],
            [
              -123.82924,
              46.708985
            ],
            [
              -123.833007,
              46.702467
            ],
            [
              -123.851356,
              46.70256
            ],
            [
              -123.864902,
              46.698685
            ],
            [
              -123.895601,
              46.683672
            ],
            [
              -123.915596,
              46.678649
            ],
            [
              -123.923269,
              46.672708
            ],
            [
              -123.920916,
              46.653576
            ],
            [
              -123.921913,
              46.650262
            ],
            [
              -123.940616,
              46.640862
            ],
            [
              -123.960642,
              46.636364
            ],
            [
              -123.959175,
              46.613581
            ],
            [
              -123.955556,
              46.60357
            ],
            [
              -123.939139,
              46.596326
            ],
            [
              -123.928861,
              46.588875
            ],
            [
              -123.922332,
              46.577057
            ],
            [
              -123.920247,
              46.567343
            ],
            [
              -123.916902,
              46.562633
            ],
            [
              -123.903321,
              46.55191
            ],
            [
              -123.894254,
              46.537028
            ],
            [
              -123.897242,
              46.52848
            ],
            [
              -123.896703,
              46.522665
            ],
            [
              -123.921192,
              46.507731
            ],
            [
              -123.943667,
              46.477197
            ],
            [
              -123.939439,
              46.467155
            ],
            [
              -123.942528,
              46.46519
            ],
            [
              -123.968044,
              46.473497
            ],
            [
              -123.97083,
              46.47537
            ],
            [
              -123.979213,
              46.489949
            ],
            [
              -123.979053,
              46.497378
            ],
            [
              -123.983688,
              46.498542
            ],
            [
              -123.988386,
              46.497008
            ],
            [
              -123.99268,
              46.488617
            ],
            [
              -123.994181,
              46.468868
            ],
            [
              -123.99087,
              46.465738
            ],
            [
              -123.990615,
              46.463019
            ],
            [
              -123.988401,
              46.44505
            ],
            [
              -123.97661,
              46.441252
            ],
            [
              -123.965346,
              46.433257
            ],
            [
              -123.967009,
              46.415727
            ],
            [
              -123.961972,
              46.408898
            ],
            [
              -123.949896,
              46.410319
            ],
            [
              -123.953699,
              46.378845
            ],
            [
              -123.96392,
              46.376714
            ],
            [
              -123.984835,
              46.378681
            ],
            [
              -124.007177,
              46.371466
            ],
            [
              -124.015258,
              46.378681
            ],
            [
              -124.026032,
              46.462978
            ],
            [
              -124.031737,
              46.496375
            ],
            [
              -124.026019,
              46.531589
            ],
            [
              -124.023148,
              46.564113
            ],
            [
              -124.023566,
              46.582559
            ],
            [
              -124.028799,
              46.59104
            ],
            [
              -124.050842,
              46.617421
            ],
            [
              -124.052708,
              46.622796
            ],
            [
              -124.035874,
              46.630822
            ],
            [
              -124.048444,
              46.645827
            ],
            [
              -124.056476,
              46.645645
            ],
            [
              -124.062715,
              46.642582
            ],
            [
              -124.068655,
              46.634879
            ],
            [
              -124.069583,
              46.630651
            ],
            [
              -124.06842,
              46.601397
            ],
            [
              -124.061953,
              46.556165
            ],
            [
              -124.057024,
              46.493338
            ],
            [
              -124.057425,
              46.409315
            ],
            [
              -124.058351,
              46.386503
            ],
            [
              -124.064624,
              46.326899
            ],
            [
              -124.071384,
              46.305504
            ],
            [
              -124.076262,
              46.296498
            ],
            [
              -124.081729,
              46.274714
            ],
            [
              -124.082187,
              46.269159
            ],
            [
              -124.080671,
              46.267239
            ],
            [
              -124.060961,
              46.278761
            ],
            [
              -124.044018,
              46.275925
            ],
            [
              -124.038797,
              46.283675
            ],
            [
              -124.035599,
              46.296843
            ],
            [
              -124.029924,
              46.308312
            ],
            [
              -124.020551,
              46.315737
            ],
            [
              -124.001264,
              46.31326
            ],
            [
              -123.985204,
              46.309039
            ],
            [
              -123.974509,
              46.303063
            ],
            [
              -123.970355,
              46.299352
            ],
            [
              -123.970912,
              46.293866
            ],
            [
              -123.969427,
              46.291398
            ],
            [
              -123.954353,
              46.277001
            ],
            [
              -123.919581,
              46.251217
            ],
            [
              -123.909306,
              46.245491
            ],
            [
              -123.875525,
              46.239787
            ],
            [
              -123.806139,
              46.283588
            ],
            [
              -123.795556,
              46.284501
            ],
            [
              -123.782654,
              46.280227
            ],
            [
              -123.775054,
              46.274599
            ],
            [
              -123.766682,
              46.273499
            ],
            [
              -123.75956,
              46.275073
            ],
            [
              -123.741478,
              46.290274
            ],
            [
              -123.728585,
              46.288725
            ],
            [
              -123.727895566158,
              46.289685234115
            ],
            [
              -123.726557,
              46.384872
            ],
            [
              -123.358334,
              46.384025
            ],
            [
              -123.358921,
              46.552073
            ],
            [
              -123.359475,
              46.562042
            ],
            [
              -123.365007,
              46.562459
            ],
            [
              -123.365113,
              46.649321
            ],
            [
              -123.370445,
              46.734721
            ],
            [
              -123.37095,
              46.792129
            ],
            [
              -123.841243,
              46.791864
            ],
            [
              -123.856488,
              46.790916
            ],
            [
              -123.897052,
              46.792495
            ],
            [
              -124.09834303160001,
              46.7940669968361
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "53",
      "COUNTYFP": "015",
      "COUNTYNS": "01529156",
      "AFFGEOID": "0500000US53015",
      "GEOID": "53015",
      "NAME": "Cowlitz",
      "LSAD": "06",
      "ALAND": 2953721040,
      "AWATER": 67320045,
      "County_state": "Cowlitz,53"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -123.21795,
              46.385617
            ],
            [
              -123.21492,
              46.297977
            ],
            [
              -123.216826,
              46.242001
            ],
            [
              -123.213054,
              46.172541
            ],
            [
              -123.166414,
              46.188973
            ],
            [
              -123.115904,
              46.185268
            ],
            [
              -123.105021,
              46.177676
            ],
            [
              -123.051064,
              46.153599
            ],
            [
              -123.041297,
              46.146351
            ],
            [
              -123.03382,
              46.144336
            ],
            [
              -123.022147,
              46.13911
            ],
            [
              -123.009436,
              46.136043
            ],
            [
              -123.004233,
              46.133823
            ],
            [
              -122.962681,
              46.104817
            ],
            [
              -122.904119,
              46.083734
            ],
            [
              -122.884478,
              46.06028
            ],
            [
              -122.88346281138001,
              46.055670000814196
            ],
            [
              -122.878092,
              46.031281
            ],
            [
              -122.856158,
              46.014469
            ],
            [
              -122.837638,
              45.98082
            ],
            [
              -122.813998,
              45.960984
            ],
            [
              -122.806193,
              45.932416
            ],
            [
              -122.81151,
              45.912725
            ],
            [
              -122.798091,
              45.884333
            ],
            [
              -122.785026,
              45.867699
            ],
            [
              -122.785515,
              45.850536
            ],
            [
              -122.743205,
              45.870121
            ],
            [
              -122.719752,
              45.867613
            ],
            [
              -122.721949,
              45.872784
            ],
            [
              -122.732406,
              45.894245
            ],
            [
              -122.748456,
              45.898808
            ],
            [
              -122.737275,
              45.902538
            ],
            [
              -122.738996,
              45.915287
            ],
            [
              -122.725933,
              45.915729
            ],
            [
              -122.72091,
              45.931892
            ],
            [
              -122.68926,
              45.929111
            ],
            [
              -122.690877,
              45.941576
            ],
            [
              -122.669259,
              45.936991
            ],
            [
              -122.657632,
              45.928134
            ],
            [
              -122.64619,
              45.937256
            ],
            [
              -122.61573,
              45.935702
            ],
            [
              -122.578026,
              45.954696
            ],
            [
              -122.557421,
              45.953845
            ],
            [
              -122.534496,
              45.979217
            ],
            [
              -122.510669,
              45.984935
            ],
            [
              -122.496959,
              45.980747
            ],
            [
              -122.477104,
              45.988089
            ],
            [
              -122.446446,
              45.983182
            ],
            [
              -122.442208,
              45.975849
            ],
            [
              -122.398515,
              45.972496
            ],
            [
              -122.372915,
              45.960758
            ],
            [
              -122.332663,
              45.965064
            ],
            [
              -122.3148,
              45.990829
            ],
            [
              -122.328885,
              46.010054
            ],
            [
              -122.272974,
              46.059404
            ],
            [
              -122.245856,
              46.053853
            ],
            [
              -122.246677,
              46.200575
            ],
            [
              -122.242008,
              46.21162
            ],
            [
              -122.2396,
              46.38288
            ],
            [
              -122.240966,
              46.385361
            ],
            [
              -122.360794,
              46.386131
            ],
            [
              -122.363705,
              46.387345
            ],
            [
              -122.943712,
              46.387389
            ],
            [
              -123.114228,
              46.388043
            ],
            [
              -123.21795,
              46.385617
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "53",
      "COUNTYFP": "069",
      "COUNTYNS": "01513275",
      "AFFGEOID": "0500000US53069",
      "GEOID": "53069",
      "NAME": "Wahkiakum",
      "LSAD": "06",
      "ALAND": 680956787,
      "AWATER": 61588406,
      "County_state": "Wahkiakum,53"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -123.726557,
              46.384872
            ],
            [
              -123.727895566158,
              46.289685234115
            ],
            [
              -123.724038,
              46.295058
            ],
            [
              -123.724273,
              46.301161
            ],
            [
              -123.700764,
              46.305278
            ],
            [
              -123.687763,
              46.299235
            ],
            [
              -123.680574,
              46.296025
            ],
            [
              -123.67876,
              46.290721
            ],
            [
              -123.678069,
              46.286469
            ],
            [
              -123.68008,
              46.277943
            ],
            [
              -123.679125,
              46.272502
            ],
            [
              -123.669501,
              46.266832
            ],
            [
              -123.613544,
              46.259988
            ],
            [
              -123.581642,
              46.260502
            ],
            [
              -123.564405,
              46.262172
            ],
            [
              -123.559923,
              46.265098
            ],
            [
              -123.547636,
              46.265595
            ],
            [
              -123.547659,
              46.259109
            ],
            [
              -123.538092,
              46.26061
            ],
            [
              -123.526391,
              46.263404
            ],
            [
              -123.516188,
              46.266153
            ],
            [
              -123.501245,
              46.271004
            ],
            [
              -123.479644,
              46.269131
            ],
            [
              -123.474844,
              46.267831
            ],
            [
              -123.468743,
              46.264531
            ],
            [
              -123.447592,
              46.249832
            ],
            [
              -123.427629,
              46.229348
            ],
            [
              -123.430847,
              46.181827
            ],
            [
              -123.371433,
              46.146372
            ],
            [
              -123.36374313768701,
              46.1463247963846
            ],
            [
              -123.332335,
              46.146132
            ],
            [
              -123.301034,
              46.144632
            ],
            [
              -123.280166,
              46.144843
            ],
            [
              -123.251233,
              46.156452
            ],
            [
              -123.231196,
              46.16615
            ],
            [
              -123.213054,
              46.172541
            ],
            [
              -123.216826,
              46.242001
            ],
            [
              -123.21492,
              46.297977
            ],
            [
              -123.21795,
              46.385617
            ],
            [
              -123.259068,
              46.383458
            ],
            [
              -123.358334,
              46.384025
            ],
            [
              -123.726557,
              46.384872
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "53",
      "COUNTYFP": "041",
      "COUNTYNS": "01531927",
      "AFFGEOID": "0500000US53041",
      "GEOID": "53041",
      "NAME": "Lewis",
      "LSAD": "06",
      "ALAND": 6223151001,
      "AWATER": 86954130,
      "County_state": "Lewis,53"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -123.37095,
              46.792129
            ],
            [
              -123.370445,
              46.734721
            ],
            [
              -123.365113,
              46.649321
            ],
            [
              -123.365007,
              46.562459
            ],
            [
              -123.359475,
              46.562042
            ],
            [
              -123.358921,
              46.552073
            ],
            [
              -123.358334,
              46.384025
            ],
            [
              -123.259068,
              46.383458
            ],
            [
              -123.21795,
              46.385617
            ],
            [
              -123.114228,
              46.388043
            ],
            [
              -122.943712,
              46.387389
            ],
            [
              -122.363705,
              46.387345
            ],
            [
              -122.360794,
              46.386131
            ],
            [
              -122.240966,
              46.385361
            ],
            [
              -122.236759,
              46.385893
            ],
            [
              -122.114291,
              46.38648
            ],
            [
              -121.522324,
              46.388224
            ],
            [
              -121.397016,
              46.388083
            ],
            [
              -121.393759,
              46.39004
            ],
            [
              -121.386557,
              46.410601
            ],
            [
              -121.40776,
              46.430928
            ],
            [
              -121.411978,
              46.459529
            ],
            [
              -121.435856,
              46.475907
            ],
            [
              -121.423202,
              46.492686
            ],
            [
              -121.45014,
              46.503526
            ],
            [
              -121.451256,
              46.533894
            ],
            [
              -121.430083,
              46.539162
            ],
            [
              -121.41271,
              46.558668
            ],
            [
              -121.424601,
              46.57001
            ],
            [
              -121.405711,
              46.582344
            ],
            [
              -121.407589,
              46.601475
            ],
            [
              -121.386708,
              46.621204
            ],
            [
              -121.395189,
              46.645825
            ],
            [
              -121.413741,
              46.648714
            ],
            [
              -121.408811,
              46.683479
            ],
            [
              -121.37668,
              46.686486
            ],
            [
              -121.375866,
              46.705276
            ],
            [
              -121.353182,
              46.71195
            ],
            [
              -121.368838,
              46.726169
            ],
            [
              -121.408028,
              46.728262
            ],
            [
              -121.43117,
              46.741887
            ],
            [
              -121.425288,
              46.757426
            ],
            [
              -121.441827,
              46.758183
            ],
            [
              -121.455218,
              46.783797
            ],
            [
              -121.758799,
              46.783571
            ],
            [
              -121.777042,
              46.776464
            ],
            [
              -121.817399,
              46.747287
            ],
            [
              -121.831764,
              46.730246
            ],
            [
              -121.84189,
              46.728455
            ],
            [
              -121.867436,
              46.729142
            ],
            [
              -121.931247,
              46.740372
            ],
            [
              -121.957121,
              46.755041
            ],
            [
              -121.984521,
              46.744334
            ],
            [
              -122.008101,
              46.752567
            ],
            [
              -122.023412,
              46.742264
            ],
            [
              -122.041301,
              46.742522
            ],
            [
              -122.082298,
              46.75222
            ],
            [
              -122.11556,
              46.748901
            ],
            [
              -122.12903,
              46.754663
            ],
            [
              -122.177877,
              46.755202
            ],
            [
              -122.203115,
              46.763061
            ],
            [
              -122.476366,
              46.763042
            ],
            [
              -122.486766,
              46.762038
            ],
            [
              -122.54974,
              46.763433
            ],
            [
              -122.615787,
              46.762155
            ],
            [
              -123.160909,
              46.76433
            ],
            [
              -123.160588,
              46.793383
            ],
            [
              -123.351391,
              46.793509
            ],
            [
              -123.37095,
              46.792129
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "53",
      "COUNTYFP": "009",
      "COUNTYNS": "01531341",
      "AFFGEOID": "0500000US53009",
      "GEOID": "53009",
      "NAME": "Clallam",
      "LSAD": "06",
      "ALAND": 4503153141,
      "AWATER": 2413877536,
      "County_state": "Clallam,53"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -124.731746,
              48.169997
            ],
            [
              -124.733174,
              48.163393
            ],
            [
              -124.731703,
              48.160402
            ],
            [
              -124.721725,
              48.153185
            ],
            [
              -124.695088,
              48.114878
            ],
            [
              -124.687101,
              48.098657
            ],
            [
              -124.688602,
              48.092466
            ],
            [
              -124.695114,
              48.087096
            ],
            [
              -124.696542,
              48.069274
            ],
            [
              -124.693676,
              48.058697
            ],
            [
              -124.688359,
              48.054927
            ],
            [
              -124.685393,
              48.049238
            ],
            [
              -124.682157,
              48.035987
            ],
            [
              -124.679024,
              48.015697
            ],
            [
              -124.67083,
              47.982366
            ],
            [
              -124.672427,
              47.964414
            ],
            [
              -124.662334,
              47.951451
            ],
            [
              -124.651966,
              47.943177
            ],
            [
              -124.645442,
              47.935338
            ],
            [
              -124.629706,
              47.896968
            ],
            [
              -124.630153,
              47.892467
            ],
            [
              -124.625512,
              47.887963
            ],
            [
              -124.61076310088701,
              47.8806070165746
            ],
            [
              -124.438456,
              47.88313
            ],
            [
              -124.375958,
              47.881126
            ],
            [
              -123.505917,
              47.88108
            ],
            [
              -123.505917,
              47.866897
            ],
            [
              -122.949167,
              47.866822
            ],
            [
              -122.951253,
              47.925138
            ],
            [
              -122.94918,
              48.06515
            ],
            [
              -122.927239425436,
              48.0653038461935
            ],
            [
              -122.927975,
              48.06665
            ],
            [
              -122.926644,
              48.0741
            ],
            [
              -122.920911,
              48.088199
            ],
            [
              -122.917942,
              48.091535
            ],
            [
              -122.929095,
              48.096244
            ],
            [
              -122.946119,
              48.098552
            ],
            [
              -122.979413,
              48.09594
            ],
            [
              -123.004128,
              48.090516
            ],
            [
              -123.016651,
              48.08538
            ],
            [
              -123.038727,
              48.081138
            ],
            [
              -123.050446,
              48.102825
            ],
            [
              -123.06621,
              48.120469
            ],
            [
              -123.085154,
              48.127137
            ],
            [
              -123.116479,
              48.150208
            ],
            [
              -123.124816,
              48.153472
            ],
            [
              -123.131422,
              48.152736
            ],
            [
              -123.143229,
              48.156633
            ],
            [
              -123.139258,
              48.16648
            ],
            [
              -123.132417,
              48.174704
            ],
            [
              -123.133445,
              48.177276
            ],
            [
              -123.144783,
              48.175943
            ],
            [
              -123.1644,
              48.165894
            ],
            [
              -123.191521,
              48.143821
            ],
            [
              -123.21719,
              48.127203
            ],
            [
              -123.239129,
              48.118217
            ],
            [
              -123.248615,
              48.115745
            ],
            [
              -123.268917,
              48.116094
            ],
            [
              -123.280178,
              48.117309
            ],
            [
              -123.288265,
              48.121036
            ],
            [
              -123.314578,
              48.113725
            ],
            [
              -123.332699,
              48.11297
            ],
            [
              -123.360923,
              48.115864
            ],
            [
              -123.395048,
              48.114243
            ],
            [
              -123.424668,
              48.118065
            ],
            [
              -123.441972,
              48.124259
            ],
            [
              -123.439127,
              48.141278
            ],
            [
              -123.440128,
              48.142014
            ],
            [
              -123.455458,
              48.140047
            ],
            [
              -123.473379,
              48.134079
            ],
            [
              -123.507235,
              48.131807
            ],
            [
              -123.52232,
              48.135539
            ],
            [
              -123.534879,
              48.14578
            ],
            [
              -123.551131,
              48.151382
            ],
            [
              -123.560591,
              48.150697
            ],
            [
              -123.574214,
              48.140756
            ],
            [
              -123.590839,
              48.134949
            ],
            [
              -123.628819,
              48.139279
            ],
            [
              -123.641108,
              48.146127
            ],
            [
              -123.636967,
              48.150319
            ],
            [
              -123.651408,
              48.156952
            ],
            [
              -123.672445,
              48.162715
            ],
            [
              -123.702743,
              48.166783
            ],
            [
              -123.706432,
              48.165822
            ],
            [
              -123.706226,
              48.1634
            ],
            [
              -123.71835,
              48.158713
            ],
            [
              -123.725352,
              48.159191
            ],
            [
              -123.72829,
              48.160858
            ],
            [
              -123.728736,
              48.1628
            ],
            [
              -123.756395,
              48.161057
            ],
            [
              -123.778122,
              48.155466
            ],
            [
              -123.831571,
              48.157937
            ],
            [
              -123.858821,
              48.154273
            ],
            [
              -123.866677,
              48.154796
            ],
            [
              -123.880068,
              48.160621
            ],
            [
              -123.915589,
              48.159352
            ],
            [
              -123.934921,
              48.16084
            ],
            [
              -123.955347,
              48.165455
            ],
            [
              -123.981032,
              48.164761
            ],
            [
              -124.050734,
              48.177747
            ],
            [
              -124.072124,
              48.189903
            ],
            [
              -124.090717,
              48.196458
            ],
            [
              -124.107215,
              48.200082
            ],
            [
              -124.101773,
              48.216883
            ],
            [
              -124.110974,
              48.220557
            ],
            [
              -124.14129,
              48.227413
            ],
            [
              -124.192692,
              48.246316
            ],
            [
              -124.217873,
              48.253294
            ],
            [
              -124.238582,
              48.262471
            ],
            [
              -124.250882,
              48.264773
            ],
            [
              -124.252267,
              48.261004
            ],
            [
              -124.255109,
              48.258972
            ],
            [
              -124.265824,
              48.254842
            ],
            [
              -124.272017,
              48.25441
            ],
            [
              -124.295693,
              48.259282
            ],
            [
              -124.297643,
              48.260676
            ],
            [
              -124.296924,
              48.261796
            ],
            [
              -124.295589,
              48.262983
            ],
            [
              -124.299146,
              48.268239
            ],
            [
              -124.342412,
              48.277695
            ],
            [
              -124.361351,
              48.287582
            ],
            [
              -124.380874,
              48.284699
            ],
            [
              -124.395593,
              48.288772
            ],
            [
              -124.414007,
              48.300887
            ],
            [
              -124.510582,
              48.343236
            ],
            [
              -124.525453,
              48.349022
            ],
            [
              -124.538821,
              48.349893
            ],
            [
              -124.546259,
              48.353594
            ],
            [
              -124.564841,
              48.367921
            ],
            [
              -124.572864,
              48.366228
            ],
            [
              -124.590733,
              48.373604
            ],
            [
              -124.599356,
              48.370512
            ],
            [
              -124.61029,
              48.366723
            ],
            [
              -124.623817,
              48.3691
            ],
            [
              -124.631108,
              48.376522
            ],
            [
              -124.639389,
              48.385524
            ],
            [
              -124.653243,
              48.390691
            ],
            [
              -124.694511,
              48.389004
            ],
            [
              -124.716947,
              48.389776
            ],
            [
              -124.725839,
              48.386012
            ],
            [
              -124.731828,
              48.381157
            ],
            [
              -124.730863,
              48.3762
            ],
            [
              -124.727022,
              48.371101
            ],
            [
              -124.713817,
              48.366309
            ],
            [
              -124.696703,
              48.349748
            ],
            [
              -124.670072,
              48.341341
            ],
            [
              -124.65894,
              48.331057
            ],
            [
              -124.662068,
              48.31045
            ],
            [
              -124.665908,
              48.299324
            ],
            [
              -124.669265,
              48.296353
            ],
            [
              -124.676319,
              48.295143
            ],
            [
              -124.680877,
              48.26535
            ],
            [
              -124.684677,
              48.255228
            ],
            [
              -124.699663,
              48.245812
            ],
            [
              -124.70592,
              48.239894
            ],
            [
              -124.705031,
              48.238774
            ],
            [
              -124.690389,
              48.219745
            ],
            [
              -124.6909,
              48.212617
            ],
            [
              -124.696111,
              48.198599
            ],
            [
              -124.704153,
              48.184422
            ],
            [
              -124.731746,
              48.169997
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "53",
      "COUNTYFP": "027",
      "COUNTYNS": "01531823",
      "AFFGEOID": "0500000US53027",
      "GEOID": "53027",
      "NAME": "Grays Harbor",
      "LSAD": "06",
      "ALAND": 4924343838,
      "AWATER": 834767680,
      "County_state": "Grays Harbor,53"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -124.353637336447,
              47.5335371334958
            ],
            [
              -124.345155,
              47.48903
            ],
            [
              -124.336724,
              47.415996
            ],
            [
              -124.32665,
              47.388759
            ],
            [
              -124.324091,
              47.367602
            ],
            [
              -124.319379,
              47.355559
            ],
            [
              -124.307509,
              47.352268
            ],
            [
              -124.299943,
              47.34836
            ],
            [
              -124.293288,
              47.339309
            ],
            [
              -124.286369,
              47.325162
            ],
            [
              -124.271193,
              47.305025
            ],
            [
              -124.257452,
              47.304059
            ],
            [
              -124.25359,
              47.30248
            ],
            [
              -124.242234,
              47.295101
            ],
            [
              -124.236349,
              47.287287
            ],
            [
              -124.209017,
              47.218151
            ],
            [
              -124.195893,
              47.174
            ],
            [
              -124.189725,
              47.146827
            ],
            [
              -124.185806,
              47.136017
            ],
            [
              -124.182802,
              47.134041
            ],
            [
              -124.183833,
              47.124807
            ],
            [
              -124.176745,
              47.092999
            ],
            [
              -124.173501,
              47.06637
            ],
            [
              -124.169113,
              46.994508
            ],
            [
              -124.171161,
              46.958443
            ],
            [
              -124.174503,
              46.941623
            ],
            [
              -124.180111,
              46.926357
            ],
            [
              -124.158624,
              46.929439
            ],
            [
              -124.141267,
              46.940266
            ],
            [
              -124.124386,
              46.94387
            ],
            [
              -124.138035,
              46.970959
            ],
            [
              -124.139733,
              46.98837
            ],
            [
              -124.151288,
              47.021112
            ],
            [
              -124.149043,
              47.029294
            ],
            [
              -124.141517,
              47.035142
            ],
            [
              -124.122057,
              47.04165
            ],
            [
              -124.106378,
              47.04264
            ],
            [
              -124.065856,
              47.04114
            ],
            [
              -124.026345,
              47.030187
            ],
            [
              -124.016999,
              47.014848
            ],
            [
              -124.017035,
              47.011717
            ],
            [
              -124.005248,
              47.003915
            ],
            [
              -124.010068,
              46.997882
            ],
            [
              -124.019727,
              46.991189
            ],
            [
              -124.012218,
              46.985176
            ],
            [
              -123.991612,
              46.980215
            ],
            [
              -123.959185,
              46.981759
            ],
            [
              -123.947996,
              46.971818
            ],
            [
              -123.939214,
              46.969739
            ],
            [
              -123.921617,
              46.971864
            ],
            [
              -123.898245,
              46.971927
            ],
            [
              -123.889402,
              46.968904
            ],
            [
              -123.876136,
              46.961054
            ],
            [
              -123.864079007868,
              46.951610011051194
            ],
            [
              -123.86018,
              46.948556
            ],
            [
              -123.882884,
              46.939946
            ],
            [
              -123.915256,
              46.932964
            ],
            [
              -123.957493,
              46.921261
            ],
            [
              -123.979378,
              46.923038
            ],
            [
              -123.985082,
              46.921916
            ],
            [
              -124.009519,
              46.910325
            ],
            [
              -124.01366,
              46.90363
            ],
            [
              -124.03624,
              46.898473
            ],
            [
              -124.046344,
              46.893972
            ],
            [
              -124.049279,
              46.891253
            ],
            [
              -124.055085,
              46.870429
            ],
            [
              -124.061051,
              46.865127
            ],
            [
              -124.066349,
              46.863504
            ],
            [
              -124.073113,
              46.861493
            ],
            [
              -124.089286,
              46.867716
            ],
            [
              -124.090422,
              46.8955
            ],
            [
              -124.093392,
              46.901168
            ],
            [
              -124.110641,
              46.91252
            ],
            [
              -124.117712,
              46.91238
            ],
            [
              -124.138225,
              46.905534
            ],
            [
              -124.122979,
              46.879809
            ],
            [
              -124.108078,
              46.836388
            ],
            [
              -124.101232,
              46.810656
            ],
            [
              -124.09834303160001,
              46.7940669968361
            ],
            [
              -123.897052,
              46.792495
            ],
            [
              -123.856488,
              46.790916
            ],
            [
              -123.841243,
              46.791864
            ],
            [
              -123.37095,
              46.792129
            ],
            [
              -123.351391,
              46.793509
            ],
            [
              -123.160588,
              46.793383
            ],
            [
              -123.1605,
              46.827161
            ],
            [
              -123.160516,
              46.837479
            ],
            [
              -123.158364,
              46.995871
            ],
            [
              -123.203101,
              46.996973
            ],
            [
              -123.200887,
              47.010407
            ],
            [
              -123.201891,
              47.085059
            ],
            [
              -123.368698,
              47.082448
            ],
            [
              -123.488943,
              47.082646
            ],
            [
              -123.490805,
              47.258208
            ],
            [
              -123.505021,
              47.258535
            ],
            [
              -123.505917,
              47.517716
            ],
            [
              -124.04674,
              47.518525
            ],
            [
              -124.046504,
              47.53367
            ],
            [
              -124.353637336447,
              47.5335371334958
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "53",
      "COUNTYFP": "031",
      "COUNTYNS": "01531936",
      "AFFGEOID": "0500000US53031",
      "GEOID": "53031",
      "NAME": "Jefferson",
      "LSAD": "06",
      "ALAND": 4671790710,
      "AWATER": 994835543,
      "County_state": "Jefferson,53"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -124.61076310088701,
              47.8806070165746
            ],
            [
              -124.609538,
              47.879996
            ],
            [
              -124.588172,
              47.877878
            ],
            [
              -124.562363,
              47.866216
            ],
            [
              -124.559034,
              47.863085
            ],
            [
              -124.558254,
              47.855979
            ],
            [
              -124.539927,
              47.836967
            ],
            [
              -124.51278,
              47.822518
            ],
            [
              -124.50668,
              47.82391
            ],
            [
              -124.497987,
              47.822605
            ],
            [
              -124.489737,
              47.816988
            ],
            [
              -124.482154,
              47.797454
            ],
            [
              -124.47657,
              47.769671
            ],
            [
              -124.471687,
              47.766907
            ],
            [
              -124.453927,
              47.765334
            ],
            [
              -124.430546,
              47.746249
            ],
            [
              -124.425195,
              47.738434
            ],
            [
              -124.420219,
              47.725294
            ],
            [
              -124.412106,
              47.691199
            ],
            [
              -124.395983,
              47.665534
            ],
            [
              -124.382215,
              47.632302
            ],
            [
              -124.374927,
              47.603891
            ],
            [
              -124.371746,
              47.599575
            ],
            [
              -124.366221,
              47.582439
            ],
            [
              -124.359028,
              47.547616
            ],
            [
              -124.355955,
              47.545698
            ],
            [
              -124.353637336447,
              47.5335371334958
            ],
            [
              -124.046504,
              47.53367
            ],
            [
              -124.04674,
              47.518525
            ],
            [
              -123.505917,
              47.517716
            ],
            [
              -123.505917,
              47.604615
            ],
            [
              -123.367025,
              47.607458
            ],
            [
              -122.97948566843601,
              47.6066620248723
            ],
            [
              -122.97244,
              47.6149
            ],
            [
              -122.896524,
              47.674838
            ],
            [
              -122.880462,
              47.720643
            ],
            [
              -122.845612,
              47.777474
            ],
            [
              -122.815027,
              47.807493
            ],
            [
              -122.820178,
              47.835904
            ],
            [
              -122.812616,
              47.840029
            ],
            [
              -122.790619,
              47.792597
            ],
            [
              -122.832139,
              47.695511
            ],
            [
              -122.811929,
              47.679861
            ],
            [
              -122.781682,
              47.70392
            ],
            [
              -122.758498,
              47.746036
            ],
            [
              -122.757885,
              47.757744
            ],
            [
              -122.75054,
              47.773966
            ],
            [
              -122.748061,
              47.800546
            ],
            [
              -122.731956,
              47.809741
            ],
            [
              -122.719609,
              47.813036
            ],
            [
              -122.688596,
              47.831438
            ],
            [
              -122.683742,
              47.838773
            ],
            [
              -122.681602,
              47.850405
            ],
            [
              -122.690974,
              47.860118
            ],
            [
              -122.69376,
              47.868002
            ],
            [
              -122.666417,
              47.867497
            ],
            [
              -122.650083,
              47.86386
            ],
            [
              -122.63636,
              47.866186
            ],
            [
              -122.633879,
              47.868401
            ],
            [
              -122.631857,
              47.874815
            ],
            [
              -122.610341,
              47.887343
            ],
            [
              -122.618873,
              47.890242
            ],
            [
              -122.637425,
              47.889945
            ],
            [
              -122.646494,
              47.894771
            ],
            [
              -122.655085,
              47.905058
            ],
            [
              -122.65399,
              47.91589
            ],
            [
              -122.651063,
              47.920985
            ],
            [
              -122.657722,
              47.931156
            ],
            [
              -122.66238,
              47.9307
            ],
            [
              -122.681924,
              47.936415
            ],
            [
              -122.68445,
              47.939593
            ],
            [
              -122.684688,
              47.944049
            ],
            [
              -122.676215,
              47.958743
            ],
            [
              -122.6788,
              47.96793
            ],
            [
              -122.683223,
              47.972226
            ],
            [
              -122.701294,
              47.972979
            ],
            [
              -122.718082,
              47.987739
            ],
            [
              -122.723374,
              48.008095
            ],
            [
              -122.70184,
              48.016106
            ],
            [
              -122.697185,
              48.014978
            ],
            [
              -122.690066,
              48.00842
            ],
            [
              -122.686898,
              48.008305
            ],
            [
              -122.669868,
              48.017217
            ],
            [
              -122.668942,
              48.032026
            ],
            [
              -122.677153,
              48.036346
            ],
            [
              -122.682264,
              48.042723
            ],
            [
              -122.69222,
              48.087081
            ],
            [
              -122.69164,
              48.096726
            ],
            [
              -122.68724,
              48.101662
            ],
            [
              -122.698465,
              48.103102
            ],
            [
              -122.718558,
              48.097567
            ],
            [
              -122.733257,
              48.091232
            ],
            [
              -122.748345,
              48.072097
            ],
            [
              -122.747389,
              48.070795
            ],
            [
              -122.741184,
              48.070958
            ],
            [
              -122.739271,
              48.069153
            ],
            [
              -122.740007,
              48.054116
            ],
            [
              -122.74229,
              48.049324
            ],
            [
              -122.766648,
              48.04429
            ],
            [
              -122.770496,
              48.047897
            ],
            [
              -122.770559,
              48.053432
            ],
            [
              -122.801399,
              48.087561
            ],
            [
              -122.798464,
              48.092451
            ],
            [
              -122.792902,
              48.09718
            ],
            [
              -122.778466,
              48.106135
            ],
            [
              -122.773177,
              48.106864
            ],
            [
              -122.748911,
              48.117026
            ],
            [
              -122.760448,
              48.14324
            ],
            [
              -122.784076,
              48.142974
            ],
            [
              -122.833173,
              48.134406
            ],
            [
              -122.876282,
              48.110877
            ],
            [
              -122.882013,
              48.100779
            ],
            [
              -122.878255,
              48.076072
            ],
            [
              -122.857727,
              48.065774
            ],
            [
              -122.849273,
              48.053808
            ],
            [
              -122.827482,
              48.046768
            ],
            [
              -122.831851,
              48.019418
            ],
            [
              -122.839457,
              48.001768
            ],
            [
              -122.850854,
              47.995899
            ],
            [
              -122.875822,
              47.996043
            ],
            [
              -122.860956,
              48.021325
            ],
            [
              -122.877641,
              48.047025
            ],
            [
              -122.918602,
              48.058238
            ],
            [
              -122.926851,
              48.064593
            ],
            [
              -122.927239425436,
              48.0653038461935
            ],
            [
              -122.94918,
              48.06515
            ],
            [
              -122.951253,
              47.925138
            ],
            [
              -122.949167,
              47.866822
            ],
            [
              -123.505917,
              47.866897
            ],
            [
              -123.505917,
              47.88108
            ],
            [
              -124.375958,
              47.881126
            ],
            [
              -124.438456,
              47.88313
            ],
            [
              -124.61076310088701,
              47.8806070165746
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "53",
      "COUNTYFP": "043",
      "COUNTYNS": "01514052",
      "AFFGEOID": "0500000US53043",
      "GEOID": "53043",
      "NAME": "Lincoln",
      "LSAD": "06",
      "ALAND": 5984258952,
      "AWATER": 75428245,
      "County_state": "Lincoln,53"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -118.976111,
              47.68962
            ],
            [
              -118.979777,
              47.261709
            ],
            [
              -118.100781,
              47.260981
            ],
            [
              -118.079372,
              47.26026
            ],
            [
              -117.96093,
              47.260568
            ],
            [
              -117.823629,
              47.26022
            ],
            [
              -117.822963,
              47.409427
            ],
            [
              -117.821773,
              47.614588
            ],
            [
              -117.819712,
              47.657465
            ],
            [
              -117.820947,
              47.825775
            ],
            [
              -117.830867,
              47.834341
            ],
            [
              -117.881473,
              47.84749
            ],
            [
              -117.886306,
              47.838335
            ],
            [
              -117.912904,
              47.835218
            ],
            [
              -117.935532,
              47.823221
            ],
            [
              -117.96609,
              47.832234
            ],
            [
              -117.990074,
              47.8344
            ],
            [
              -118.004212,
              47.823248
            ],
            [
              -118.013403,
              47.805582
            ],
            [
              -118.035954,
              47.802716
            ],
            [
              -118.048153,
              47.792295
            ],
            [
              -118.05624,
              47.799252
            ],
            [
              -118.078182,
              47.800062
            ],
            [
              -118.099259,
              47.823624
            ],
            [
              -118.117812,
              47.832631
            ],
            [
              -118.120617,
              47.851883
            ],
            [
              -118.143769,
              47.884711
            ],
            [
              -118.159883,
              47.884478
            ],
            [
              -118.160206,
              47.89721
            ],
            [
              -118.176518,
              47.901255
            ],
            [
              -118.176613,
              47.910455
            ],
            [
              -118.200031,
              47.928384
            ],
            [
              -118.212323,
              47.945493
            ],
            [
              -118.229552,
              47.94023
            ],
            [
              -118.246103,
              47.943604
            ],
            [
              -118.259978,
              47.934782
            ],
            [
              -118.317163,
              47.908775
            ],
            [
              -118.321711,
              47.896289
            ],
            [
              -118.340938,
              47.894316
            ],
            [
              -118.349911,
              47.882572
            ],
            [
              -118.357065,
              47.841599
            ],
            [
              -118.369064,
              47.832685
            ],
            [
              -118.392659,
              47.830684
            ],
            [
              -118.44582,
              47.845496
            ],
            [
              -118.456165,
              47.854474
            ],
            [
              -118.456978,
              47.874879
            ],
            [
              -118.466978,
              47.882386
            ],
            [
              -118.501109,
              47.873845
            ],
            [
              -118.531297,
              47.874878
            ],
            [
              -118.54409,
              47.883973
            ],
            [
              -118.533498,
              47.914877
            ],
            [
              -118.570396,
              47.932318
            ],
            [
              -118.651995,
              47.927108
            ],
            [
              -118.661953,
              47.937407
            ],
            [
              -118.688394,
              47.942718
            ],
            [
              -118.707625,
              47.933579
            ],
            [
              -118.711682,
              47.903347
            ],
            [
              -118.730325,
              47.900169
            ],
            [
              -118.783216,
              47.915716
            ],
            [
              -118.811877,
              47.920285
            ],
            [
              -118.829403,
              47.933139
            ],
            [
              -118.833036,
              47.950467
            ],
            [
              -118.85196,
              47.957906
            ],
            [
              -118.957051,
              47.940857
            ],
            [
              -118.973504,
              47.943414
            ],
            [
              -118.976111,
              47.68962
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "53",
      "COUNTYFP": "045",
      "COUNTYNS": "01529221",
      "AFFGEOID": "0500000US53045",
      "GEOID": "53045",
      "NAME": "Mason",
      "LSAD": "06",
      "ALAND": 2484806998,
      "AWATER": 237381766,
      "County_state": "Mason,53"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -123.505917,
              47.604615
            ],
            [
              -123.505917,
              47.517716
            ],
            [
              -123.505021,
              47.258535
            ],
            [
              -123.490805,
              47.258208
            ],
            [
              -123.488943,
              47.082646
            ],
            [
              -123.368698,
              47.082448
            ],
            [
              -123.201891,
              47.085059
            ],
            [
              -123.075096,
              47.084795
            ],
            [
              -123.074957,
              47.090844
            ],
            [
              -123.073654,
              47.106242
            ],
            [
              -123.060583,
              47.114025
            ],
            [
              -123.034109,
              47.115041
            ],
            [
              -123.015389,
              47.123792
            ],
            [
              -123.010456,
              47.143326
            ],
            [
              -123.002435,
              47.151302
            ],
            [
              -122.969123,
              47.161525
            ],
            [
              -122.952819,
              47.170103
            ],
            [
              -122.947046,
              47.185641
            ],
            [
              -122.936283,
              47.189457
            ],
            [
              -122.911341,
              47.175956
            ],
            [
              -122.907497,
              47.164903
            ],
            [
              -122.903521,
              47.157533
            ],
            [
              -122.85899674744101,
              47.167438495019795
            ],
            [
              -122.838298,
              47.208353
            ],
            [
              -122.856171,
              47.233788
            ],
            [
              -122.863732,
              47.270221
            ],
            [
              -122.84586,
              47.298405
            ],
            [
              -122.822344,
              47.319763
            ],
            [
              -122.819738,
              47.327964
            ],
            [
              -122.825237,
              47.353398
            ],
            [
              -122.821868,
              47.363069
            ],
            [
              -122.804615,
              47.356835
            ],
            [
              -122.802831094246,
              47.3534366472272
            ],
            [
              -122.80106,
              47.358454
            ],
            [
              -122.801199,
              47.403578
            ],
            [
              -122.801012,
              47.520406
            ],
            [
              -122.939665,
              47.522512
            ],
            [
              -123.01641268704,
              47.520298015202
            ],
            [
              -123.033938,
              47.496973
            ],
            [
              -123.120234,
              47.39149
            ],
            [
              -123.115466,
              47.376087
            ],
            [
              -123.085645,
              47.374771
            ],
            [
              -123.056886,
              47.368499
            ],
            [
              -123.013153,
              47.378639
            ],
            [
              -122.977495,
              47.39616
            ],
            [
              -122.916655,
              47.413713
            ],
            [
              -122.901206,
              47.421594
            ],
            [
              -122.872447,
              47.423524
            ],
            [
              -122.877913,
              47.410979
            ],
            [
              -122.911426,
              47.389099
            ],
            [
              -122.938759,
              47.383306
            ],
            [
              -122.983919,
              47.374615
            ],
            [
              -123.030266,
              47.350949
            ],
            [
              -123.072098,
              47.351593
            ],
            [
              -123.111298,
              47.362619
            ],
            [
              -123.140169,
              47.347496
            ],
            [
              -123.15598,
              47.355745
            ],
            [
              -123.106486,
              47.45817
            ],
            [
              -122.97948566843601,
              47.6066620248723
            ],
            [
              -123.367025,
              47.607458
            ],
            [
              -123.505917,
              47.604615
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "53",
      "COUNTYFP": "055",
      "COUNTYNS": "01531931",
      "AFFGEOID": "0500000US53055",
      "GEOID": "53055",
      "NAME": "San Juan",
      "LSAD": "06",
      "ALAND": 450428674,
      "AWATER": 1157428261,
      "County_state": "San Juan,53"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -122.977142,
              48.793447
            ],
            [
              -122.979519,
              48.781702
            ],
            [
              -122.923607,
              48.754597
            ],
            [
              -122.896797,
              48.746002
            ],
            [
              -122.84523,
              48.737831
            ],
            [
              -122.818436,
              48.744629
            ],
            [
              -122.877268,
              48.764184
            ],
            [
              -122.937925,
              48.790315
            ],
            [
              -122.977142,
              48.793447
            ]
          ]
        ],
        [
          [
            [
              -123.070427,
              48.699971
            ],
            [
              -123.047058,
              48.695772
            ],
            [
              -123.03636,
              48.69008
            ],
            [
              -123.035672,
              48.68535
            ],
            [
              -123.041645,
              48.678633
            ],
            [
              -123.042337,
              48.675663
            ],
            [
              -123.021215,
              48.681416
            ],
            [
              -123.014449,
              48.684978
            ],
            [
              -123.005086,
              48.694342
            ],
            [
              -123.007511,
              48.718863
            ],
            [
              -123.009787,
              48.722291
            ],
            [
              -123.019699,
              48.721312
            ],
            [
              -123.025486,
              48.717966
            ],
            [
              -123.040179,
              48.717296
            ],
            [
              -123.070427,
              48.699971
            ]
          ]
        ],
        [
          [
            [
              -123.203026,
              48.596178
            ],
            [
              -123.20268,
              48.590214
            ],
            [
              -123.197754,
              48.586216
            ],
            [
              -123.184941,
              48.58697
            ],
            [
              -123.173061,
              48.579086
            ],
            [
              -123.171958,
              48.572255
            ],
            [
              -123.175852,
              48.568483
            ],
            [
              -123.176266,
              48.562131
            ],
            [
              -123.172412,
              48.556486
            ],
            [
              -123.16147,
              48.547618
            ],
            [
              -123.161853,
              48.539255
            ],
            [
              -123.164057,
              48.535622
            ],
            [
              -123.163234,
              48.529544
            ],
            [
              -123.151065,
              48.513955
            ],
            [
              -123.141478,
              48.505291
            ],
            [
              -123.119451,
              48.492576
            ],
            [
              -123.067675,
              48.479497
            ],
            [
              -123.058154,
              48.471522
            ],
            [
              -123.039156,
              48.460003
            ],
            [
              -122.962009,
              48.451161
            ],
            [
              -122.937881,
              48.456221
            ],
            [
              -122.926901,
              48.460874
            ],
            [
              -122.920099,
              48.458428
            ],
            [
              -122.91646,
              48.453263
            ],
            [
              -122.928004,
              48.439966
            ],
            [
              -122.917771,
              48.439781
            ],
            [
              -122.913888,
              48.443231
            ],
            [
              -122.903214,
              48.436979
            ],
            [
              -122.889016,
              48.435947
            ],
            [
              -122.893646,
              48.422655
            ],
            [
              -122.883759,
              48.418793
            ],
            [
              -122.874135,
              48.418196
            ],
            [
              -122.825803,
              48.424131
            ],
            [
              -122.812208,
              48.422326
            ],
            [
              -122.803521,
              48.428748
            ],
            [
              -122.802509,
              48.433098
            ],
            [
              -122.807708,
              48.444058
            ],
            [
              -122.8131,
              48.452856
            ],
            [
              -122.81973,
              48.458843
            ],
            [
              -122.817912,
              48.483888
            ],
            [
              -122.816332,
              48.487841
            ],
            [
              -122.800414,
              48.494467
            ],
            [
              -122.779124,
              48.508911
            ],
            [
              -122.777467,
              48.517799
            ],
            [
              -122.787347,
              48.523012
            ],
            [
              -122.788503,
              48.530393
            ],
            [
              -122.782618,
              48.545191
            ],
            [
              -122.772384,
              48.552143
            ],
            [
              -122.770349,
              48.558106
            ],
            [
              -122.771206,
              48.562426
            ],
            [
              -122.786586,
              48.576666
            ],
            [
              -122.801096,
              48.585425
            ],
            [
              -122.804869,
              48.595932
            ],
            [
              -122.800217,
              48.60169
            ],
            [
              -122.79877,
              48.602352
            ],
            [
              -122.79901,
              48.604683
            ],
            [
              -122.808864,
              48.61531
            ],
            [
              -122.809622,
              48.619035
            ],
            [
              -122.792147,
              48.633502
            ],
            [
              -122.783875,
              48.635419
            ],
            [
              -122.755031,
              48.649512
            ],
            [
              -122.742082,
              48.660689
            ],
            [
              -122.743049,
              48.661991
            ],
            [
              -122.800267,
              48.67962
            ],
            [
              -122.802545,
              48.682682
            ],
            [
              -122.833124,
              48.698173
            ],
            [
              -122.875938,
              48.71212
            ],
            [
              -122.894599,
              48.71503
            ],
            [
              -122.918252,
              48.713505
            ],
            [
              -122.942367,
              48.706723
            ],
            [
              -122.941316,
              48.702904
            ],
            [
              -122.949116,
              48.693398
            ],
            [
              -122.984853,
              48.672686
            ],
            [
              -122.988884,
              48.667246
            ],
            [
              -123.009924,
              48.655064
            ],
            [
              -123.014829,
              48.647503
            ],
            [
              -123.015592,
              48.642567
            ],
            [
              -123.023495,
              48.634001
            ],
            [
              -123.048652,
              48.621002
            ],
            [
              -123.04653,
              48.61149
            ],
            [
              -123.041189,
              48.611947
            ],
            [
              -123.023433,
              48.599477
            ],
            [
              -123.024902,
              48.594484
            ],
            [
              -123.034101,
              48.591767
            ],
            [
              -123.016647,
              48.580244
            ],
            [
              -123.0048,
              48.580788
            ],
            [
              -122.995026,
              48.578162
            ],
            [
              -122.989649,
              48.574668
            ],
            [
              -122.98611,
              48.569984
            ],
            [
              -122.987296,
              48.561895
            ],
            [
              -123.015046,
              48.560821
            ],
            [
              -123.033669,
              48.563409
            ],
            [
              -123.048403,
              48.569216
            ],
            [
              -123.06004,
              48.582105
            ],
            [
              -123.074611,
              48.591816
            ],
            [
              -123.101552,
              48.59782
            ],
            [
              -123.102074,
              48.604035
            ],
            [
              -123.098254,
              48.610092
            ],
            [
              -123.098462,
              48.612834
            ],
            [
              -123.107362,
              48.622451
            ],
            [
              -123.135645,
              48.620171
            ],
            [
              -123.139705,
              48.622786
            ],
            [
              -123.151643,
              48.623686
            ],
            [
              -123.178425,
              48.622115
            ],
            [
              -123.195725,
              48.607055
            ],
            [
              -123.203026,
              48.596178
            ]
          ]
        ],
        [
          [
            [
              -123.236567,
              48.68895
            ],
            [
              -123.237148,
              48.683466
            ],
            [
              -123.215917,
              48.669352
            ],
            [
              -123.134956,
              48.63724
            ],
            [
              -123.119677,
              48.632972
            ],
            [
              -123.106165,
              48.633473
            ],
            [
              -123.122016,
              48.647065
            ],
            [
              -123.130962,
              48.656789
            ],
            [
              -123.14799,
              48.668001
            ],
            [
              -123.172066,
              48.679866
            ],
            [
              -123.186076,
              48.684917
            ],
            [
              -123.197953,
              48.68466
            ],
            [
              -123.212892,
              48.689713
            ],
            [
              -123.236567,
              48.68895
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "53",
      "COUNTYFP": "065",
      "COUNTYNS": "01531930",
      "AFFGEOID": "0500000US53065",
      "GEOID": "53065",
      "NAME": "Stevens",
      "LSAD": "06",
      "ALAND": 6416413114,
      "AWATER": 163008888,
      "County_state": "Stevens,53"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -118.400807,
              48.028682
            ],
            [
              -118.398007,
              48.020382
            ],
            [
              -118.391506,
              48.014241
            ],
            [
              -118.382538,
              48.00697
            ],
            [
              -118.378457,
              48.003434
            ],
            [
              -118.373429,
              47.994653
            ],
            [
              -118.352143,
              47.974758
            ],
            [
              -118.353041,
              47.949357
            ],
            [
              -118.343721,
              47.906257
            ],
            [
              -118.340938,
              47.894316
            ],
            [
              -118.321711,
              47.896289
            ],
            [
              -118.317163,
              47.908775
            ],
            [
              -118.259978,
              47.934782
            ],
            [
              -118.246103,
              47.943604
            ],
            [
              -118.229552,
              47.94023
            ],
            [
              -118.212323,
              47.945493
            ],
            [
              -118.200031,
              47.928384
            ],
            [
              -118.176613,
              47.910455
            ],
            [
              -118.176518,
              47.901255
            ],
            [
              -118.160206,
              47.89721
            ],
            [
              -118.159883,
              47.884478
            ],
            [
              -118.143769,
              47.884711
            ],
            [
              -118.120617,
              47.851883
            ],
            [
              -118.117812,
              47.832631
            ],
            [
              -118.099259,
              47.823624
            ],
            [
              -118.078182,
              47.800062
            ],
            [
              -118.05624,
              47.799252
            ],
            [
              -118.048153,
              47.792295
            ],
            [
              -118.035954,
              47.802716
            ],
            [
              -118.013403,
              47.805582
            ],
            [
              -118.004212,
              47.823248
            ],
            [
              -117.990074,
              47.8344
            ],
            [
              -117.96609,
              47.832234
            ],
            [
              -117.935532,
              47.823221
            ],
            [
              -117.912904,
              47.835218
            ],
            [
              -117.886306,
              47.838335
            ],
            [
              -117.881473,
              47.84749
            ],
            [
              -117.830867,
              47.834341
            ],
            [
              -117.820947,
              47.825775
            ],
            [
              -117.806,
              47.812228
            ],
            [
              -117.790459,
              47.811356
            ],
            [
              -117.782497,
              47.825557
            ],
            [
              -117.767113,
              47.83182
            ],
            [
              -117.754243,
              47.826832
            ],
            [
              -117.697774,
              47.856278
            ],
            [
              -117.701794,
              47.865116
            ],
            [
              -117.692207,
              47.883421
            ],
            [
              -117.675207,
              47.889099
            ],
            [
              -117.666511,
              47.880439
            ],
            [
              -117.660887,
              47.843634
            ],
            [
              -117.651111,
              47.83472
            ],
            [
              -117.630065,
              47.834225
            ],
            [
              -117.600119,
              47.803019
            ],
            [
              -117.575802,
              47.793522
            ],
            [
              -117.551273,
              47.801468
            ],
            [
              -117.535262,
              47.794458
            ],
            [
              -117.535262,
              48.047779
            ],
            [
              -117.436993,
              48.047207
            ],
            [
              -117.437629,
              48.134688
            ],
            [
              -117.502371,
              48.134708
            ],
            [
              -117.502238,
              48.223362
            ],
            [
              -117.500143,
              48.238115
            ],
            [
              -117.499975,
              48.656187
            ],
            [
              -117.629241,
              48.655852
            ],
            [
              -117.629421,
              48.742634
            ],
            [
              -117.563811,
              48.742961
            ],
            [
              -117.563858,
              48.836671
            ],
            [
              -117.498496,
              48.8369
            ],
            [
              -117.498957,
              48.923159
            ],
            [
              -117.430377,
              48.923168
            ],
            [
              -117.429968,
              49.0003644833648
            ],
            [
              -117.607323,
              49.000843
            ],
            [
              -117.8761,
              49.000546
            ],
            [
              -117.884398,
              48.999912
            ],
            [
              -118.001106,
              48.999911
            ],
            [
              -118.002046,
              49.000437
            ],
            [
              -118.19737505245901,
              49.0004067514246
            ],
            [
              -118.195238,
              48.995945
            ],
            [
              -118.202737,
              48.994365
            ],
            [
              -118.207713,
              48.99704
            ],
            [
              -118.216119,
              48.993708
            ],
            [
              -118.211126,
              48.990267
            ],
            [
              -118.210032,
              48.985628
            ],
            [
              -118.216244,
              48.983835
            ],
            [
              -118.219829,
              48.971972
            ],
            [
              -118.214529,
              48.968415
            ],
            [
              -118.207597,
              48.962029
            ],
            [
              -118.210726,
              48.956994
            ],
            [
              -118.217281,
              48.947276
            ],
            [
              -118.220987,
              48.938387
            ],
            [
              -118.21901,
              48.931544
            ],
            [
              -118.208383,
              48.920432
            ],
            [
              -118.201322,
              48.914888
            ],
            [
              -118.197641,
              48.911764
            ],
            [
              -118.200144,
              48.907619
            ],
            [
              -118.206172,
              48.900621
            ],
            [
              -118.212627,
              48.890087
            ],
            [
              -118.212751,
              48.883396
            ],
            [
              -118.211034,
              48.874903
            ],
            [
              -118.205415,
              48.870104
            ],
            [
              -118.200242,
              48.86268
            ],
            [
              -118.199268,
              48.855481
            ],
            [
              -118.193815,
              48.848781
            ],
            [
              -118.195517,
              48.843993
            ],
            [
              -118.186627,
              48.838099
            ],
            [
              -118.179317,
              48.837639
            ],
            [
              -118.170432,
              48.826413
            ],
            [
              -118.167097,
              48.816087
            ],
            [
              -118.160285,
              48.805333
            ],
            [
              -118.159418,
              48.797881
            ],
            [
              -118.152708,
              48.789451
            ],
            [
              -118.145477,
              48.782978
            ],
            [
              -118.139922,
              48.779329
            ],
            [
              -118.135793,
              48.783002
            ],
            [
              -118.125346,
              48.784637
            ],
            [
              -118.124335,
              48.782706
            ],
            [
              -118.122014,
              48.777904
            ],
            [
              -118.125337,
              48.774968
            ],
            [
              -118.133987,
              48.771595
            ],
            [
              -118.133866,
              48.7682
            ],
            [
              -118.132195,
              48.765548
            ],
            [
              -118.128211,
              48.764879
            ],
            [
              -118.127331,
              48.765121
            ],
            [
              -118.125305,
              48.763629
            ],
            [
              -118.125217,
              48.762391
            ],
            [
              -118.128148,
              48.760562
            ],
            [
              -118.131355,
              48.760483
            ],
            [
              -118.132919,
              48.756741
            ],
            [
              -118.128874,
              48.754836
            ],
            [
              -118.124691,
              48.752654
            ],
            [
              -118.122636,
              48.750406
            ],
            [
              -118.122344,
              48.747049
            ],
            [
              -118.126982,
              48.745171
            ],
            [
              -118.129392,
              48.741793
            ],
            [
              -118.127062,
              48.7382
            ],
            [
              -118.122933,
              48.73687
            ],
            [
              -118.117852,
              48.734453
            ],
            [
              -118.122741,
              48.730109
            ],
            [
              -118.123206,
              48.721889
            ],
            [
              -118.120417,
              48.711529
            ],
            [
              -118.120898,
              48.702802
            ],
            [
              -118.115469,
              48.687809
            ],
            [
              -118.115468,
              48.680352
            ],
            [
              -118.117326,
              48.677136
            ],
            [
              -118.116708,
              48.674239
            ],
            [
              -118.112912,
              48.674199
            ],
            [
              -118.109128,
              48.667023
            ],
            [
              -118.10829,
              48.661206
            ],
            [
              -118.104729,
              48.651821
            ],
            [
              -118.107645,
              48.640497
            ],
            [
              -118.116185,
              48.636396
            ],
            [
              -118.117518,
              48.630729
            ],
            [
              -118.119915,
              48.629204
            ],
            [
              -118.118703,
              48.627673
            ],
            [
              -118.118122,
              48.625819
            ],
            [
              -118.119154,
              48.621858
            ],
            [
              -118.124394,
              48.61734
            ],
            [
              -118.128008,
              48.608651
            ],
            [
              -118.127246,
              48.596561
            ],
            [
              -118.126081,
              48.586959
            ],
            [
              -118.131867,
              48.576527
            ],
            [
              -118.138619,
              48.561674
            ],
            [
              -118.143259,
              48.54741
            ],
            [
              -118.141909,
              48.53521
            ],
            [
              -118.141597,
              48.527254
            ],
            [
              -118.151464,
              48.520337
            ],
            [
              -118.160931,
              48.521666
            ],
            [
              -118.170122,
              48.523138
            ],
            [
              -118.178879,
              48.515653
            ],
            [
              -118.180424,
              48.509263
            ],
            [
              -118.181006,
              48.499197
            ],
            [
              -118.184706,
              48.492097
            ],
            [
              -118.188406,
              48.483697
            ],
            [
              -118.194406,
              48.478496
            ],
            [
              -118.203307,
              48.472796
            ],
            [
              -118.211607,
              48.459896
            ],
            [
              -118.211507,
              48.449395
            ],
            [
              -118.202706,
              48.439795
            ],
            [
              -118.201006,
              48.428595
            ],
            [
              -118.202444,
              48.418722
            ],
            [
              -118.201805,
              48.405394
            ],
            [
              -118.195851,
              48.398466
            ],
            [
              -118.189704,
              48.391494
            ],
            [
              -118.186904,
              48.381994
            ],
            [
              -118.181443,
              48.369854
            ],
            [
              -118.180203,
              48.358293
            ],
            [
              -118.179902,
              48.345593
            ],
            [
              -118.179805,
              48.334301
            ],
            [
              -118.176377,
              48.327397
            ],
            [
              -118.17393,
              48.317389
            ],
            [
              -118.166701,
              48.308392
            ],
            [
              -118.1617,
              48.302592
            ],
            [
              -118.15286,
              48.291798
            ],
            [
              -118.148458,
              48.27801
            ],
            [
              -118.140624,
              48.269751
            ],
            [
              -118.14188,
              48.259438
            ],
            [
              -118.144296,
              48.254628
            ],
            [
              -118.145398,
              48.251791
            ],
            [
              -118.148218,
              48.245051
            ],
            [
              -118.160499,
              48.23359
            ],
            [
              -118.174199,
              48.22539
            ],
            [
              -118.1866,
              48.21829
            ],
            [
              -118.191449,
              48.215275
            ],
            [
              -118.1918,
              48.210389
            ],
            [
              -118.189399,
              48.205189
            ],
            [
              -118.194999,
              48.192789
            ],
            [
              -118.195999,
              48.179088
            ],
            [
              -118.191943,
              48.165159
            ],
            [
              -118.193079,
              48.151899
            ],
            [
              -118.200498,
              48.141187
            ],
            [
              -118.2253,
              48.137187
            ],
            [
              -118.242589,
              48.135475
            ],
            [
              -118.251801,
              48.132042
            ],
            [
              -118.253583,
              48.121742
            ],
            [
              -118.253289,
              48.112481
            ],
            [
              -118.2462,
              48.109086
            ],
            [
              -118.235499,
              48.100186
            ],
            [
              -118.231098,
              48.086885
            ],
            [
              -118.235298,
              48.077085
            ],
            [
              -118.233398,
              48.064185
            ],
            [
              -118.239098,
              48.057684
            ],
            [
              -118.248198,
              48.054784
            ],
            [
              -118.253423,
              48.05769
            ],
            [
              -118.266282,
              48.054556
            ],
            [
              -118.28332,
              48.04073
            ],
            [
              -118.305155,
              48.041232
            ],
            [
              -118.354417,
              48.050119
            ],
            [
              -118.374957,
              48.041044
            ],
            [
              -118.383343,
              48.037438
            ],
            [
              -118.394807,
              48.032582
            ],
            [
              -118.400807,
              48.028682
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "53",
      "COUNTYFP": "073",
      "COUNTYNS": "01529224",
      "AFFGEOID": "0500000US53073",
      "GEOID": "53073",
      "NAME": "Whatcom",
      "LSAD": "06",
      "ALAND": 5459395487,
      "AWATER": 1028184768,
      "County_state": "Whatcom,53"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -122.722262,
              48.731624
            ],
            [
              -122.721981,
              48.723375
            ],
            [
              -122.718833,
              48.716818
            ],
            [
              -122.702223,
              48.717004
            ],
            [
              -122.691795,
              48.711498
            ],
            [
              -122.673538,
              48.680809
            ],
            [
              -122.635299,
              48.651846
            ],
            [
              -122.616956,
              48.645563
            ],
            [
              -122.609576,
              48.645018
            ],
            [
              -122.618225,
              48.670721
            ],
            [
              -122.644901,
              48.691389
            ],
            [
              -122.663259,
              48.697077
            ],
            [
              -122.668947,
              48.706644
            ],
            [
              -122.695907,
              48.737273
            ],
            [
              -122.70306,
              48.743602
            ],
            [
              -122.715709,
              48.748672
            ],
            [
              -122.722262,
              48.731624
            ]
          ]
        ],
        [
          [
            [
              -122.822464,
              48.944911
            ],
            [
              -122.821631,
              48.941369
            ],
            [
              -122.818232,
              48.939062
            ],
            [
              -122.787539,
              48.931702
            ],
            [
              -122.770432,
              48.942528
            ],
            [
              -122.766096,
              48.941955
            ],
            [
              -122.755624,
              48.93866
            ],
            [
              -122.746596,
              48.930731
            ],
            [
              -122.745371,
              48.921227
            ],
            [
              -122.747514,
              48.915582
            ],
            [
              -122.751289,
              48.911239
            ],
            [
              -122.783747,
              48.894639
            ],
            [
              -122.792584,
              48.894732
            ],
            [
              -122.793175,
              48.892927
            ],
            [
              -122.785659,
              48.885066
            ],
            [
              -122.722685,
              48.852855
            ],
            [
              -122.717073,
              48.84719
            ],
            [
              -122.711805,
              48.832408
            ],
            [
              -122.709169,
              48.817829
            ],
            [
              -122.7112,
              48.79146
            ],
            [
              -122.709815,
              48.786205
            ],
            [
              -122.703106,
              48.786321
            ],
            [
              -122.699303,
              48.789063
            ],
            [
              -122.699507,
              48.794906
            ],
            [
              -122.698675,
              48.800522
            ],
            [
              -122.697219,
              48.80281
            ],
            [
              -122.693683,
              48.804475
            ],
            [
              -122.680246,
              48.80275
            ],
            [
              -122.659708,
              48.786523
            ],
            [
              -122.656528,
              48.784969
            ],
            [
              -122.646777,
              48.785011
            ],
            [
              -122.645743,
              48.781538
            ],
            [
              -122.647443,
              48.773998
            ],
            [
              -122.661111,
              48.753962
            ],
            [
              -122.666953,
              48.748445
            ],
            [
              -122.673472,
              48.733082
            ],
            [
              -122.646323,
              48.708001
            ],
            [
              -122.630422,
              48.696625
            ],
            [
              -122.620338,
              48.693651
            ],
            [
              -122.615169,
              48.693839
            ],
            [
              -122.606105,
              48.698556
            ],
            [
              -122.605733,
              48.701066
            ],
            [
              -122.612562,
              48.714932
            ],
            [
              -122.626287,
              48.72093
            ],
            [
              -122.638082,
              48.732486
            ],
            [
              -122.637146,
              48.735708
            ],
            [
              -122.627808,
              48.74466
            ],
            [
              -122.606787,
              48.759143
            ],
            [
              -122.598033,
              48.769489
            ],
            [
              -122.596844,
              48.771492
            ],
            [
              -122.567498,
              48.779185
            ],
            [
              -122.535803,
              48.776128
            ],
            [
              -122.528203,
              48.768428
            ],
            [
              -122.513039913971,
              48.75905021718369
            ],
            [
              -122.510902,
              48.757728
            ],
            [
              -122.490401,
              48.751128
            ],
            [
              -122.49275876218199,
              48.7444877718132
            ],
            [
              -122.495301,
              48.737328
            ],
            [
              -122.505684,
              48.724524
            ],
            [
              -122.515511,
              48.720992
            ],
            [
              -122.519172,
              48.713095
            ],
            [
              -122.506718,
              48.669692
            ],
            [
              -122.500308,
              48.656163
            ],
            [
              -122.49399,
              48.651596
            ],
            [
              -122.488793968804,
              48.645404890839
            ],
            [
              -122.323328,
              48.645349
            ],
            [
              -122.215836,
              48.64569
            ],
            [
              -122.210321,
              48.645689
            ],
            [
              -121.564067,
              48.642332
            ],
            [
              -120.908358,
              48.640901
            ],
            [
              -120.908357,
              48.657003
            ],
            [
              -120.751904,
              48.657002
            ],
            [
              -120.718663,
              48.674492
            ],
            [
              -120.703432,
              48.696915
            ],
            [
              -120.678089,
              48.696197
            ],
            [
              -120.678195,
              48.717826
            ],
            [
              -120.655857,
              48.724342
            ],
            [
              -120.678163,
              48.735052
            ],
            [
              -120.709918,
              48.771814
            ],
            [
              -120.725523,
              48.77339
            ],
            [
              -120.735427,
              48.786487
            ],
            [
              -120.742399,
              48.822069
            ],
            [
              -120.731351,
              48.855015
            ],
            [
              -120.735281,
              48.864374
            ],
            [
              -120.766947,
              48.876412
            ],
            [
              -120.762704,
              48.899518
            ],
            [
              -120.772132,
              48.906705
            ],
            [
              -120.770362,
              48.927404
            ],
            [
              -120.754047,
              48.936262
            ],
            [
              -120.768515,
              48.95449
            ],
            [
              -120.792072,
              48.959406
            ],
            [
              -120.835436,
              48.939307
            ],
            [
              -120.848979,
              48.955911
            ],
            [
              -120.873379,
              48.962734
            ],
            [
              -120.88564,
              48.975212
            ],
            [
              -120.865656,
              48.979833
            ],
            [
              -120.851560900318,
              49.000280080019294
            ],
            [
              -120.978955,
              49.000367
            ],
            [
              -121.059966,
              49.000621
            ],
            [
              -121.12624,
              49.001412
            ],
            [
              -121.2299,
              49.001158
            ],
            [
              -121.248949,
              49.000925
            ],
            [
              -121.251244,
              49.000167
            ],
            [
              -121.256933,
              49.000088
            ],
            [
              -121.345295,
              49.000843
            ],
            [
              -121.395543,
              48.999851
            ],
            [
              -121.751252,
              48.997399
            ],
            [
              -122.098357,
              49.002146
            ],
            [
              -122.251063,
              49.002494
            ],
            [
              -122.405989,
              49.002239
            ],
            [
              -122.407829,
              49.002193
            ],
            [
              -122.75802,
              49.002357
            ],
            [
              -122.756037,
              48.999512
            ],
            [
              -122.756318,
              48.996881
            ],
            [
              -122.766307,
              48.991672
            ],
            [
              -122.774276,
              48.991038
            ],
            [
              -122.796887,
              48.975026
            ],
            [
              -122.817226,
              48.95597
            ],
            [
              -122.822464,
              48.944911
            ]
          ]
        ],
        [
          [
            [
              -123.090546,
              49.001976
            ],
            [
              -123.084498,
              48.986535
            ],
            [
              -123.083834,
              48.976139
            ],
            [
              -123.060717,
              48.975388
            ],
            [
              -123.040967,
              48.977305
            ],
            [
              -123.028091,
              48.973943
            ],
            [
              -123.021459,
              48.977299
            ],
            [
              -123.035393,
              49.002154
            ],
            [
              -123.090546,
              49.001976
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "53",
      "COUNTYFP": "017",
      "COUNTYNS": "01531818",
      "AFFGEOID": "0500000US53017",
      "GEOID": "53017",
      "NAME": "Douglas",
      "LSAD": "06",
      "ALAND": 4711869329,
      "AWATER": 76507816,
      "County_state": "Douglas,53"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -120.316594,
              47.471273
            ],
            [
              -120.317984,
              47.456948
            ],
            [
              -120.311883,
              47.445248
            ],
            [
              -120.308482,
              47.432348
            ],
            [
              -120.296404,
              47.413141
            ],
            [
              -120.293175,
              47.400767
            ],
            [
              -120.28653,
              47.393111
            ],
            [
              -120.260288,
              47.383892
            ],
            [
              -120.165577,
              47.367706
            ],
            [
              -120.129573,
              47.366251
            ],
            [
              -120.086308,
              47.338942
            ],
            [
              -120.086046,
              47.300299
            ],
            [
              -120.09446,
              47.262159
            ],
            [
              -120.075969,
              47.225898
            ],
            [
              -120.019088,
              47.226645
            ],
            [
              -120.007074,
              47.220133
            ],
            [
              -120.004783,
              47.230602
            ],
            [
              -120.004704,
              47.319994
            ],
            [
              -119.983125,
              47.320428
            ],
            [
              -119.983234,
              47.334623
            ],
            [
              -119.961823,
              47.334952
            ],
            [
              -119.961341,
              47.348779
            ],
            [
              -119.940442,
              47.349435
            ],
            [
              -119.940236,
              47.364359
            ],
            [
              -119.918915,
              47.364475
            ],
            [
              -119.918746,
              47.393449
            ],
            [
              -119.897383,
              47.393472
            ],
            [
              -119.897173,
              47.407969
            ],
            [
              -119.875822,
              47.408305
            ],
            [
              -119.87576,
              47.437492
            ],
            [
              -119.533123,
              47.441207
            ],
            [
              -119.532889,
              47.527756
            ],
            [
              -119.511691,
              47.527633
            ],
            [
              -119.512092,
              47.54204
            ],
            [
              -119.490467,
              47.54202
            ],
            [
              -119.469099,
              47.57076
            ],
            [
              -119.447682,
              47.570455
            ],
            [
              -119.4478,
              47.584899
            ],
            [
              -119.405196,
              47.612636
            ],
            [
              -119.341617,
              47.621423
            ],
            [
              -119.341485,
              47.626748
            ],
            [
              -119.320304,
              47.626797
            ],
            [
              -119.320144,
              47.670232
            ],
            [
              -119.298843,
              47.670403
            ],
            [
              -119.298649,
              47.728328
            ],
            [
              -119.277238,
              47.728238
            ],
            [
              -119.277167,
              47.742603
            ],
            [
              -119.255783,
              47.742514
            ],
            [
              -119.255862,
              47.771687
            ],
            [
              -119.234166,
              47.771981
            ],
            [
              -119.2339,
              47.800662
            ],
            [
              -119.21241,
              47.800695
            ],
            [
              -119.212225,
              47.887708
            ],
            [
              -119.190631,
              47.887812
            ],
            [
              -119.190552,
              47.902465
            ],
            [
              -119.147399,
              47.90265
            ],
            [
              -119.1474,
              47.917388
            ],
            [
              -119.125904,
              47.917301
            ],
            [
              -119.125581,
              47.946803
            ],
            [
              -119.103966,
              47.946693
            ],
            [
              -119.103873,
              47.961763
            ],
            [
              -118.982234,
              47.962025
            ],
            [
              -118.965324,
              47.983752
            ],
            [
              -118.950782,
              48.018884
            ],
            [
              -118.980238,
              48.037227
            ],
            [
              -119.00319,
              48.043263
            ],
            [
              -119.018794,
              48.069784
            ],
            [
              -119.028858,
              48.113133
            ],
            [
              -119.05697,
              48.136974
            ],
            [
              -119.107268,
              48.140426
            ],
            [
              -119.123772,
              48.149709
            ],
            [
              -119.150983,
              48.146067
            ],
            [
              -119.179346,
              48.12789
            ],
            [
              -119.200186,
              48.125197
            ],
            [
              -119.215301,
              48.115313
            ],
            [
              -119.233335,
              48.113173
            ],
            [
              -119.25685,
              48.102027
            ],
            [
              -119.298585,
              48.115485
            ],
            [
              -119.327521,
              48.096656
            ],
            [
              -119.327934,
              48.070274
            ],
            [
              -119.343523,
              48.049432
            ],
            [
              -119.370477,
              48.052669
            ],
            [
              -119.404108,
              48.050964
            ],
            [
              -119.436257,
              48.076688
            ],
            [
              -119.500994,
              48.082429
            ],
            [
              -119.516111,
              48.074784
            ],
            [
              -119.517571,
              48.062615
            ],
            [
              -119.595103,
              48.020856
            ],
            [
              -119.614447,
              48.000089
            ],
            [
              -119.631053,
              47.996278
            ],
            [
              -119.652468,
              48.000221
            ],
            [
              -119.688678,
              48.022732
            ],
            [
              -119.693712,
              48.032056
            ],
            [
              -119.670466,
              48.067921
            ],
            [
              -119.684293,
              48.093254
            ],
            [
              -119.700875,
              48.084023
            ],
            [
              -119.726707,
              48.085028
            ],
            [
              -119.746845,
              48.102567
            ],
            [
              -119.774593,
              48.104213
            ],
            [
              -119.781452,
              48.086787
            ],
            [
              -119.808441,
              48.086855
            ],
            [
              -119.8433,
              48.077058
            ],
            [
              -119.891656,
              48.055053
            ],
            [
              -119.89535,
              48.050044
            ],
            [
              -119.869543,
              48.018853
            ],
            [
              -119.87245,
              47.993136
            ],
            [
              -119.882109,
              47.969872
            ],
            [
              -119.870595,
              47.960455
            ],
            [
              -119.859734,
              47.941942
            ],
            [
              -119.889315,
              47.91716
            ],
            [
              -119.915059,
              47.901372
            ],
            [
              -119.908428,
              47.879405
            ],
            [
              -119.919294,
              47.862323
            ],
            [
              -119.960127,
              47.845806
            ],
            [
              -119.964684,
              47.826193
            ],
            [
              -119.978062,
              47.801292
            ],
            [
              -119.994952,
              47.781353
            ],
            [
              -120.038251,
              47.775949
            ],
            [
              -120.094232,
              47.763583
            ],
            [
              -120.130914,
              47.776601
            ],
            [
              -120.191405,
              47.759504
            ],
            [
              -120.209395,
              47.748356
            ],
            [
              -120.222858,
              47.723314
            ],
            [
              -120.205219,
              47.702059
            ],
            [
              -120.199048,
              47.682276
            ],
            [
              -120.216776,
              47.66368
            ],
            [
              -120.236935,
              47.631779
            ],
            [
              -120.233584,
              47.59763
            ],
            [
              -120.259438,
              47.55849
            ],
            [
              -120.292814,
              47.538172
            ],
            [
              -120.302931,
              47.520059
            ],
            [
              -120.316594,
              47.471273
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "53",
      "COUNTYFP": "061",
      "COUNTYNS": "01529222",
      "AFFGEOID": "0500000US53061",
      "GEOID": "53061",
      "NAME": "Snohomish",
      "LSAD": "06",
      "ALAND": 5404083801,
      "AWATER": 282887163,
      "County_state": "Snohomish,53"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -122.328343,
              48.021335
            ],
            [
              -122.334524,
              48.018916
            ],
            [
              -122.326115,
              48.010295
            ],
            [
              -122.306629,
              48.004397
            ],
            [
              -122.303455,
              48.005603
            ],
            [
              -122.321721,
              48.019977
            ],
            [
              -122.328343,
              48.021335
            ]
          ]
        ],
        [
          [
            [
              -122.405615307939,
              48.2522612663007
            ],
            [
              -122.403129,
              48.24794
            ],
            [
              -122.400833,
              48.246329
            ],
            [
              -122.399452,
              48.242419
            ],
            [
              -122.393678,
              48.238758
            ],
            [
              -122.392977,
              48.23743
            ],
            [
              -122.393734,
              48.233053
            ],
            [
              -122.39541447361401,
              48.2284583844901
            ],
            [
              -122.385703,
              48.217811
            ],
            [
              -122.382102,
              48.207106
            ],
            [
              -122.372492,
              48.193022
            ],
            [
              -122.362044,
              48.187568
            ],
            [
              -122.363479,
              48.174438
            ],
            [
              -122.370253,
              48.164809
            ],
            [
              -122.364744,
              48.151304
            ],
            [
              -122.363797,
              48.142759
            ],
            [
              -122.365078,
              48.125822
            ],
            [
              -122.363842,
              48.12393
            ],
            [
              -122.36335297573501,
              48.123305717239894
            ],
            [
              -122.343241,
              48.097631
            ],
            [
              -122.326119,
              48.092877
            ],
            [
              -122.321709,
              48.085507
            ],
            [
              -122.305838,
              48.073415
            ],
            [
              -122.281087,
              48.049793
            ],
            [
              -122.231761,
              48.029876
            ],
            [
              -122.224979,
              48.016626
            ],
            [
              -122.228767,
              48.012468
            ],
            [
              -122.23022,
              48.007154
            ],
            [
              -122.232391,
              47.987713
            ],
            [
              -122.226346,
              47.976417
            ],
            [
              -122.230046,
              47.970917
            ],
            [
              -122.249007,
              47.959507
            ],
            [
              -122.278047,
              47.956517
            ],
            [
              -122.29559544775401,
              47.952039274632696
            ],
            [
              -122.307048,
              47.949117
            ],
            [
              -122.311148,
              47.936717
            ],
            [
              -122.309747,
              47.929117
            ],
            [
              -122.310747,
              47.925117
            ],
            [
              -122.321847,
              47.911817
            ],
            [
              -122.328546,
              47.897917
            ],
            [
              -122.333543,
              47.880246
            ],
            [
              -122.330145,
              47.875318
            ],
            [
              -122.329545,
              47.869418
            ],
            [
              -122.33333199093401,
              47.8593004373357
            ],
            [
              -122.33595,
              47.852306
            ],
            [
              -122.339944,
              47.846718
            ],
            [
              -122.346544,
              47.842418
            ],
            [
              -122.353244,
              47.840618
            ],
            [
              -122.392044,
              47.807718
            ],
            [
              -122.394944,
              47.803318
            ],
            [
              -122.39495525544301,
              47.8031914553652
            ],
            [
              -122.397043,
              47.779719
            ],
            [
              -122.39642167179,
              47.777927396164095
            ],
            [
              -122.327392,
              47.777646
            ],
            [
              -122.317652,
              47.777618
            ],
            [
              -122.297594,
              47.77742
            ],
            [
              -122.271033,
              47.777095
            ],
            [
              -122.243612,
              47.776959
            ],
            [
              -122.227607,
              47.776745
            ],
            [
              -122.166746,
              47.776249
            ],
            [
              -122.023486,
              47.775584
            ],
            [
              -121.991775,
              47.775349
            ],
            [
              -121.927821,
              47.776962
            ],
            [
              -121.584541,
              47.777054
            ],
            [
              -121.456287,
              47.778415
            ],
            [
              -121.455535,
              47.780576
            ],
            [
              -121.119179,
              47.779933
            ],
            [
              -121.116241,
              47.786872
            ],
            [
              -121.098511,
              47.797933
            ],
            [
              -121.082752,
              47.811803
            ],
            [
              -121.074509,
              47.822836
            ],
            [
              -121.082613,
              47.833678
            ],
            [
              -121.095808,
              47.832692
            ],
            [
              -121.121193,
              47.834799
            ],
            [
              -121.141991,
              47.843052
            ],
            [
              -121.150912,
              47.853933
            ],
            [
              -121.153065,
              47.869941
            ],
            [
              -121.157667,
              47.875639
            ],
            [
              -121.159812,
              47.881132
            ],
            [
              -121.161834,
              47.880344
            ],
            [
              -121.167333,
              47.88
            ],
            [
              -121.171014,
              47.883377
            ],
            [
              -121.175724,
              47.888153
            ],
            [
              -121.176732,
              47.894996
            ],
            [
              -121.178123,
              47.902606
            ],
            [
              -121.173262,
              47.908779
            ],
            [
              -121.17195,
              47.91167
            ],
            [
              -121.173763,
              47.915352
            ],
            [
              -121.177015,
              47.916551
            ],
            [
              -121.175955,
              47.921549
            ],
            [
              -121.17077,
              47.924994
            ],
            [
              -121.173013,
              47.932346
            ],
            [
              -121.166414,
              47.938163
            ],
            [
              -121.161784,
              47.945834
            ],
            [
              -121.164603,
              47.95693
            ],
            [
              -121.156426,
              47.961586
            ],
            [
              -121.152866,
              47.966754
            ],
            [
              -121.14879,
              47.974902
            ],
            [
              -121.147733,
              47.979657
            ],
            [
              -121.141683,
              47.983325
            ],
            [
              -121.135427,
              47.983277
            ],
            [
              -121.128249,
              47.989865
            ],
            [
              -121.117723,
              47.998797
            ],
            [
              -121.118451,
              48.006765
            ],
            [
              -121.125943,
              48.012369
            ],
            [
              -121.130111,
              48.02103
            ],
            [
              -121.142355,
              48.026873
            ],
            [
              -121.150622,
              48.036711
            ],
            [
              -121.147745,
              48.042208
            ],
            [
              -121.130519,
              48.04518
            ],
            [
              -121.117513,
              48.056968
            ],
            [
              -121.101766,
              48.061762
            ],
            [
              -121.086339,
              48.063078
            ],
            [
              -121.067004,
              48.06941
            ],
            [
              -121.048403,
              48.07032
            ],
            [
              -121.03012,
              48.078145
            ],
            [
              -121.012791,
              48.077369
            ],
            [
              -120.998822,
              48.088259
            ],
            [
              -120.986543,
              48.093831
            ],
            [
              -120.971776,
              48.10517
            ],
            [
              -120.957954,
              48.108037
            ],
            [
              -120.951458,
              48.116599
            ],
            [
              -120.959435,
              48.129465
            ],
            [
              -120.960329,
              48.145518
            ],
            [
              -120.943526,
              48.155465
            ],
            [
              -120.927037,
              48.161139
            ],
            [
              -120.912756,
              48.161739
            ],
            [
              -120.910543,
              48.173591
            ],
            [
              -120.920537,
              48.188082
            ],
            [
              -120.937585,
              48.198586
            ],
            [
              -120.951576,
              48.20731
            ],
            [
              -120.958728,
              48.2103
            ],
            [
              -120.965268,
              48.218755
            ],
            [
              -120.960404,
              48.228279
            ],
            [
              -120.975889,
              48.240166
            ],
            [
              -120.990411,
              48.255629
            ],
            [
              -121.005033,
              48.269167
            ],
            [
              -121.006618,
              48.27464
            ],
            [
              -121.010132,
              48.277529
            ],
            [
              -121.011766,
              48.282793
            ],
            [
              -121.007198,
              48.285075
            ],
            [
              -121.000685,
              48.294481
            ],
            [
              -121.001658,
              48.296009
            ],
            [
              -121.574049,
              48.297369
            ],
            [
              -121.577501,
              48.298967
            ],
            [
              -121.96665,
              48.297434
            ],
            [
              -121.996069,
              48.297499
            ],
            [
              -122.18245,
              48.297249
            ],
            [
              -122.201274,
              48.297634
            ],
            [
              -122.378296248755,
              48.2976822178335
            ],
            [
              -122.376818,
              48.296099
            ],
            [
              -122.371693,
              48.287839
            ],
            [
              -122.392058,
              48.269628
            ],
            [
              -122.395328,
              48.257187
            ],
            [
              -122.405615307939,
              48.2522612663007
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "53",
      "COUNTYFP": "067",
      "COUNTYNS": "01529226",
      "AFFGEOID": "0500000US53067",
      "GEOID": "53067",
      "NAME": "Thurston",
      "LSAD": "06",
      "ALAND": 1871173425,
      "AWATER": 133711607,
      "County_state": "Thurston,53"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -123.200887,
              47.010407
            ],
            [
              -123.203101,
              46.996973
            ],
            [
              -123.158364,
              46.995871
            ],
            [
              -123.160516,
              46.837479
            ],
            [
              -123.1605,
              46.827161
            ],
            [
              -123.160588,
              46.793383
            ],
            [
              -123.160909,
              46.76433
            ],
            [
              -122.615787,
              46.762155
            ],
            [
              -122.54974,
              46.763433
            ],
            [
              -122.486766,
              46.762038
            ],
            [
              -122.476366,
              46.763042
            ],
            [
              -122.203115,
              46.763061
            ],
            [
              -122.238096,
              46.775321
            ],
            [
              -122.269624,
              46.766796
            ],
            [
              -122.308854,
              46.78943
            ],
            [
              -122.306259,
              46.806311
            ],
            [
              -122.303576,
              46.828117
            ],
            [
              -122.325106,
              46.830489
            ],
            [
              -122.327681,
              46.844343
            ],
            [
              -122.338261,
              46.837956
            ],
            [
              -122.37119,
              46.845408
            ],
            [
              -122.378516,
              46.839357
            ],
            [
              -122.450631,
              46.85251
            ],
            [
              -122.458392,
              46.863845
            ],
            [
              -122.491079,
              46.867965
            ],
            [
              -122.494116,
              46.905218
            ],
            [
              -122.527869,
              46.924975
            ],
            [
              -122.560436,
              46.933457
            ],
            [
              -122.572115,
              46.960219
            ],
            [
              -122.565163,
              46.966573
            ],
            [
              -122.611047,
              46.9767
            ],
            [
              -122.611602,
              46.967213
            ],
            [
              -122.629117,
              46.971463
            ],
            [
              -122.638211,
              46.978139
            ],
            [
              -122.629835,
              46.997401
            ],
            [
              -122.671611,
              47.01485
            ],
            [
              -122.675495,
              47.037195
            ],
            [
              -122.703887,
              47.071688
            ],
            [
              -122.707544,
              47.085332
            ],
            [
              -122.693719109151,
              47.103618702381
            ],
            [
              -122.721437,
              47.103179
            ],
            [
              -122.775056,
              47.123114
            ],
            [
              -122.814238,
              47.179482
            ],
            [
              -122.852046,
              47.164359
            ],
            [
              -122.85994,
              47.165574
            ],
            [
              -122.85899674744101,
              47.167438495019795
            ],
            [
              -122.903521,
              47.157533
            ],
            [
              -122.907497,
              47.164903
            ],
            [
              -122.911341,
              47.175956
            ],
            [
              -122.936283,
              47.189457
            ],
            [
              -122.947046,
              47.185641
            ],
            [
              -122.952819,
              47.170103
            ],
            [
              -122.969123,
              47.161525
            ],
            [
              -123.002435,
              47.151302
            ],
            [
              -123.010456,
              47.143326
            ],
            [
              -123.015389,
              47.123792
            ],
            [
              -123.034109,
              47.115041
            ],
            [
              -123.060583,
              47.114025
            ],
            [
              -123.073654,
              47.106242
            ],
            [
              -123.074957,
              47.090844
            ],
            [
              -123.075096,
              47.084795
            ],
            [
              -123.201891,
              47.085059
            ],
            [
              -123.200887,
              47.010407
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "53",
      "COUNTYFP": "013",
      "COUNTYNS": "01513273",
      "AFFGEOID": "0500000US53013",
      "GEOID": "53013",
      "NAME": "Columbia",
      "LSAD": "06",
      "ALAND": 2249624732,
      "AWATER": 12561762,
      "County_state": "Columbia,53"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -118.242518,
              46.56008
            ],
            [
              -118.241872,
              46.295064
            ],
            [
              -118.116117,
              46.295455
            ],
            [
              -118.116624,
              46.20805
            ],
            [
              -117.991481,
              46.207702
            ],
            [
              -117.993109,
              46.019722
            ],
            [
              -117.99694971271,
              46.000786848785495
            ],
            [
              -117.996911,
              46.000787
            ],
            [
              -117.97766333505501,
              46.000723475442804
            ],
            [
              -117.717852,
              45.999866
            ],
            [
              -117.60342451872,
              45.9988892323573
            ],
            [
              -117.603296,
              46.121313
            ],
            [
              -117.612603,
              46.121323
            ],
            [
              -117.611903,
              46.338478
            ],
            [
              -117.674946,
              46.337651
            ],
            [
              -117.673946,
              46.383284
            ],
            [
              -117.694942,
              46.383254
            ],
            [
              -117.695085,
              46.397679
            ],
            [
              -117.71645,
              46.397608
            ],
            [
              -117.716629,
              46.441796
            ],
            [
              -117.737422,
              46.441995
            ],
            [
              -117.737303,
              46.471454
            ],
            [
              -117.863347,
              46.470667
            ],
            [
              -117.853168,
              46.560191
            ],
            [
              -117.85185,
              46.62467
            ],
            [
              -117.869546,
              46.619472
            ],
            [
              -117.881857,
              46.592193
            ],
            [
              -117.909049,
              46.584771
            ],
            [
              -117.952853,
              46.59207
            ],
            [
              -117.985255,
              46.586869
            ],
            [
              -118.035156,
              46.58516
            ],
            [
              -118.069095,
              46.574675
            ],
            [
              -118.092895,
              46.574529
            ],
            [
              -118.118448,
              46.556783
            ],
            [
              -118.165292,
              46.555135
            ],
            [
              -118.178843,
              46.560535
            ],
            [
              -118.21566,
              46.588924
            ],
            [
              -118.228635,
              46.593362
            ],
            [
              -118.228013,
              46.560413
            ],
            [
              -118.242518,
              46.56008
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "53",
      "COUNTYFP": "077",
      "COUNTYNS": "01531929",
      "AFFGEOID": "0500000US53077",
      "GEOID": "53077",
      "NAME": "Yakima",
      "LSAD": "06",
      "ALAND": 11122757370,
      "AWATER": 41168172,
      "County_state": "Yakima,53"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -121.497774,
              46.90739
            ],
            [
              -121.52307,
              46.872783
            ],
            [
              -121.497345,
              46.862866
            ],
            [
              -121.474376,
              46.820394
            ],
            [
              -121.442517,
              46.792661
            ],
            [
              -121.455218,
              46.783797
            ],
            [
              -121.441827,
              46.758183
            ],
            [
              -121.425288,
              46.757426
            ],
            [
              -121.43117,
              46.741887
            ],
            [
              -121.408028,
              46.728262
            ],
            [
              -121.368838,
              46.726169
            ],
            [
              -121.353182,
              46.71195
            ],
            [
              -121.375866,
              46.705276
            ],
            [
              -121.37668,
              46.686486
            ],
            [
              -121.408811,
              46.683479
            ],
            [
              -121.413741,
              46.648714
            ],
            [
              -121.395189,
              46.645825
            ],
            [
              -121.386708,
              46.621204
            ],
            [
              -121.407589,
              46.601475
            ],
            [
              -121.405711,
              46.582344
            ],
            [
              -121.424601,
              46.57001
            ],
            [
              -121.41271,
              46.558668
            ],
            [
              -121.430083,
              46.539162
            ],
            [
              -121.451256,
              46.533894
            ],
            [
              -121.45014,
              46.503526
            ],
            [
              -121.423202,
              46.492686
            ],
            [
              -121.435856,
              46.475907
            ],
            [
              -121.411978,
              46.459529
            ],
            [
              -121.40776,
              46.430928
            ],
            [
              -121.386557,
              46.410601
            ],
            [
              -121.393759,
              46.39004
            ],
            [
              -121.397016,
              46.388083
            ],
            [
              -121.522324,
              46.388224
            ],
            [
              -121.522321,
              46.044006
            ],
            [
              -121.132977,
              46.044523
            ],
            [
              -121.068709,
              46.042173
            ],
            [
              -120.57421,
              46.041503
            ],
            [
              -120.449333,
              46.041478
            ],
            [
              -120.428326,
              46.040169
            ],
            [
              -120.386767,
              46.041533
            ],
            [
              -119.865829,
              46.040858
            ],
            [
              -119.866537,
              46.214577
            ],
            [
              -119.873562,
              46.214605
            ],
            [
              -119.874201,
              46.534003
            ],
            [
              -119.876725,
              46.562733
            ],
            [
              -119.874042,
              46.628283
            ],
            [
              -119.881548,
              46.630631
            ],
            [
              -119.897582,
              46.633271
            ],
            [
              -119.901483,
              46.635602
            ],
            [
              -119.917301,
              46.655423
            ],
            [
              -119.94644,
              46.675935
            ],
            [
              -119.972706,
              46.723239
            ],
            [
              -119.973036,
              46.737126
            ],
            [
              -120.448943,
              46.737576
            ],
            [
              -120.51,
              46.737946
            ],
            [
              -120.508605,
              46.824862
            ],
            [
              -120.625149,
              46.825791
            ],
            [
              -120.633981,
              46.825776
            ],
            [
              -120.634562,
              46.91213
            ],
            [
              -121.02662,
              46.911308
            ],
            [
              -121.068815,
              46.947576
            ],
            [
              -121.06885,
              46.958465
            ],
            [
              -121.089957,
              46.969295
            ],
            [
              -121.090054,
              46.991007
            ],
            [
              -121.12376,
              46.999119
            ],
            [
              -121.135765,
              47.017233
            ],
            [
              -121.165569,
              47.035496
            ],
            [
              -121.210159,
              47.0535
            ],
            [
              -121.222406,
              47.066851
            ],
            [
              -121.256887,
              47.082147
            ],
            [
              -121.280716,
              47.088731
            ],
            [
              -121.311259,
              47.076915
            ],
            [
              -121.347136,
              47.078739
            ],
            [
              -121.359236,
              47.07074
            ],
            [
              -121.379682,
              47.087495
            ],
            [
              -121.379961,
              47.087248
            ],
            [
              -121.374115,
              47.057005
            ],
            [
              -121.399801,
              47.033464
            ],
            [
              -121.39474,
              47.023271
            ],
            [
              -121.406193,
              47.012176
            ],
            [
              -121.406457,
              46.998744
            ],
            [
              -121.445841,
              46.980224
            ],
            [
              -121.442143,
              46.972936
            ],
            [
              -121.45838,
              46.96057
            ],
            [
              -121.449464,
              46.934353
            ],
            [
              -121.456831,
              46.923146
            ],
            [
              -121.497774,
              46.90739
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "53",
      "COUNTYFP": "005",
      "COUNTYNS": "01513302",
      "AFFGEOID": "0500000US53005",
      "GEOID": "53005",
      "NAME": "Benton",
      "LSAD": "06",
      "ALAND": 4402976584,
      "AWATER": 154626802,
      "County_state": "Benton,53"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -119.876725,
              46.562733
            ],
            [
              -119.874201,
              46.534003
            ],
            [
              -119.873562,
              46.214605
            ],
            [
              -119.866537,
              46.214577
            ],
            [
              -119.865829,
              46.040858
            ],
            [
              -119.868272037091,
              45.8359407146783
            ],
            [
              -119.868135,
              45.835962
            ],
            [
              -119.802655,
              45.84753
            ],
            [
              -119.772927,
              45.845578
            ],
            [
              -119.669877,
              45.856867
            ],
            [
              -119.623393,
              45.905639
            ],
            [
              -119.600549,
              45.919581
            ],
            [
              -119.571584,
              45.925456
            ],
            [
              -119.524632,
              45.908605
            ],
            [
              -119.487829,
              45.906307
            ],
            [
              -119.450256,
              45.917354
            ],
            [
              -119.432149953782,
              45.918250445405
            ],
            [
              -119.364396,
              45.921605
            ],
            [
              -119.322509,
              45.933183
            ],
            [
              -119.25715,
              45.939926
            ],
            [
              -119.225745,
              45.932725
            ],
            [
              -119.19553,
              45.92787
            ],
            [
              -119.169496,
              45.927603
            ],
            [
              -119.12612,
              45.932859
            ],
            [
              -119.093221,
              45.942745
            ],
            [
              -119.061462,
              45.958527
            ],
            [
              -119.027056,
              45.969134
            ],
            [
              -119.008558,
              45.97927
            ],
            [
              -118.98719609037701,
              45.9997905520367
            ],
            [
              -118.987258,
              45.999855
            ],
            [
              -118.98385,
              46.002465
            ],
            [
              -118.982549,
              46.003545
            ],
            [
              -118.961703,
              46.019333
            ],
            [
              -118.941323,
              46.027528
            ],
            [
              -118.947188,
              46.05144
            ],
            [
              -118.94195,
              46.065325
            ],
            [
              -118.946499,
              46.082882
            ],
            [
              -118.96784,
              46.115605
            ],
            [
              -118.974664,
              46.139523
            ],
            [
              -119.014206,
              46.176199
            ],
            [
              -119.041498,
              46.192667
            ],
            [
              -119.047241,
              46.196002
            ],
            [
              -119.104502,
              46.218488
            ],
            [
              -119.124256,
              46.224212
            ],
            [
              -119.15905,
              46.226688
            ],
            [
              -119.203363,
              46.235341
            ],
            [
              -119.238231,
              46.262084
            ],
            [
              -119.265156,
              46.273286
            ],
            [
              -119.267202,
              46.284625
            ],
            [
              -119.250201,
              46.315001
            ],
            [
              -119.256932,
              46.345408
            ],
            [
              -119.269336,
              46.370147
            ],
            [
              -119.266856,
              46.403284
            ],
            [
              -119.259161,
              46.435726
            ],
            [
              -119.263886,
              46.480666
            ],
            [
              -119.259852,
              46.496554
            ],
            [
              -119.269042,
              46.51917
            ],
            [
              -119.305233,
              46.539361
            ],
            [
              -119.326732,
              46.563247
            ],
            [
              -119.367361,
              46.582656
            ],
            [
              -119.403042,
              46.613043
            ],
            [
              -119.413046,
              46.652631
            ],
            [
              -119.45441,
              46.67004
            ],
            [
              -119.45319,
              46.679243
            ],
            [
              -119.449693,
              46.685516
            ],
            [
              -119.485763,
              46.715668
            ],
            [
              -119.511388,
              46.727687
            ],
            [
              -119.581684,
              46.669906
            ],
            [
              -119.618479,
              46.647583
            ],
            [
              -119.659189,
              46.640333
            ],
            [
              -119.710104,
              46.648886
            ],
            [
              -119.750972,
              46.635662
            ],
            [
              -119.836401,
              46.625464
            ],
            [
              -119.874042,
              46.628283
            ],
            [
              -119.876725,
              46.562733
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "53",
      "COUNTYFP": "029",
      "COUNTYNS": "01513272",
      "AFFGEOID": "0500000US53029",
      "GEOID": "53029",
      "NAME": "Island",
      "LSAD": "06",
      "ALAND": 539709170,
      "AWATER": 800155316,
      "County_state": "Island,53"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -122.538916,
              48.209683
            ],
            [
              -122.53722,
              48.183745
            ],
            [
              -122.522576,
              48.161712
            ],
            [
              -122.512031,
              48.133931
            ],
            [
              -122.489986,
              48.120617
            ],
            [
              -122.486736,
              48.12095
            ],
            [
              -122.477983,
              48.129048
            ],
            [
              -122.4675,
              48.130353
            ],
            [
              -122.44966,
              48.114041
            ],
            [
              -122.423703,
              48.102941
            ],
            [
              -122.400692,
              48.085255
            ],
            [
              -122.393413,
              48.078472
            ],
            [
              -122.390787,
              48.069477
            ],
            [
              -122.38769,
              48.065189
            ],
            [
              -122.377114,
              48.057568
            ],
            [
              -122.363107,
              48.054546
            ],
            [
              -122.358375,
              48.056133
            ],
            [
              -122.360345,
              48.061527
            ],
            [
              -122.379481,
              48.087384
            ],
            [
              -122.411649,
              48.11321
            ],
            [
              -122.442383,
              48.130841
            ],
            [
              -122.475803,
              48.166792
            ],
            [
              -122.479008,
              48.175703
            ],
            [
              -122.478535,
              48.188087
            ],
            [
              -122.47025,
              48.194007
            ],
            [
              -122.464801,
              48.194767
            ],
            [
              -122.461888,
              48.193137
            ],
            [
              -122.45493,
              48.196639
            ],
            [
              -122.442051,
              48.20935
            ],
            [
              -122.441731,
              48.211776
            ],
            [
              -122.444508,
              48.214522
            ],
            [
              -122.449513,
              48.214736
            ],
            [
              -122.453618,
              48.22683
            ],
            [
              -122.45371,
              48.228859
            ],
            [
              -122.449605,
              48.232598
            ],
            [
              -122.433767,
              48.23655
            ],
            [
              -122.430578,
              48.236237
            ],
            [
              -122.425572,
              48.232887
            ],
            [
              -122.396121,
              48.229233
            ],
            [
              -122.39541447361401,
              48.2284583844901
            ],
            [
              -122.393734,
              48.233053
            ],
            [
              -122.392977,
              48.23743
            ],
            [
              -122.393678,
              48.238758
            ],
            [
              -122.399452,
              48.242419
            ],
            [
              -122.400833,
              48.246329
            ],
            [
              -122.403129,
              48.24794
            ],
            [
              -122.405615307939,
              48.2522612663007
            ],
            [
              -122.406516,
              48.25183
            ],
            [
              -122.463962,
              48.270541
            ],
            [
              -122.466803,
              48.269604
            ],
            [
              -122.474494,
              48.255227
            ],
            [
              -122.480925,
              48.251706
            ],
            [
              -122.493448,
              48.252043
            ],
            [
              -122.497727,
              48.253389
            ],
            [
              -122.499648,
              48.256611
            ],
            [
              -122.503786,
              48.257045
            ],
            [
              -122.530996,
              48.249821
            ],
            [
              -122.535209,
              48.240213
            ],
            [
              -122.534431,
              48.223005
            ],
            [
              -122.538916,
              48.209683
            ]
          ]
        ],
        [
          [
            [
              -122.769939,
              48.227548
            ],
            [
              -122.770045,
              48.224395
            ],
            [
              -122.763042,
              48.215342
            ],
            [
              -122.744612,
              48.20965
            ],
            [
              -122.73503,
              48.199964
            ],
            [
              -122.711508,
              48.193573
            ],
            [
              -122.693084,
              48.181509
            ],
            [
              -122.686626,
              48.174653
            ],
            [
              -122.679556,
              48.155113
            ],
            [
              -122.677337,
              48.154587
            ],
            [
              -122.671235,
              48.157312
            ],
            [
              -122.65602,
              48.162513
            ],
            [
              -122.633167,
              48.163281
            ],
            [
              -122.617464,
              48.159055
            ],
            [
              -122.609568,
              48.15186
            ],
            [
              -122.602109,
              48.135249
            ],
            [
              -122.598301,
              48.110616
            ],
            [
              -122.607291,
              48.088034
            ],
            [
              -122.613217,
              48.079485
            ],
            [
              -122.614028,
              48.072788
            ],
            [
              -122.594922,
              48.056318
            ],
            [
              -122.593621,
              48.0472
            ],
            [
              -122.596786,
              48.038834
            ],
            [
              -122.607342,
              48.030992
            ],
            [
              -122.58178,
              48.010386
            ],
            [
              -122.560018,
              48.006502
            ],
            [
              -122.542924,
              47.996404
            ],
            [
              -122.541564,
              47.992998
            ],
            [
              -122.543063,
              47.985983
            ],
            [
              -122.551032,
              47.977346
            ],
            [
              -122.552053,
              47.973644
            ],
            [
              -122.546824,
              47.967215
            ],
            [
              -122.521219,
              47.972997
            ],
            [
              -122.51778,
              47.974916
            ],
            [
              -122.514813,
              47.981152
            ],
            [
              -122.501257,
              47.987089
            ],
            [
              -122.487505,
              47.990729
            ],
            [
              -122.47266,
              47.988449
            ],
            [
              -122.446682,
              47.963155
            ],
            [
              -122.44076,
              47.951845
            ],
            [
              -122.44079,
              47.946594
            ],
            [
              -122.445759,
              47.93619
            ],
            [
              -122.445519,
              47.930226
            ],
            [
              -122.431035,
              47.914732
            ],
            [
              -122.419274,
              47.912125
            ],
            [
              -122.397349,
              47.912401
            ],
            [
              -122.39042,
              47.905696
            ],
            [
              -122.380497,
              47.904023
            ],
            [
              -122.3773,
              47.905941
            ],
            [
              -122.37578,
              47.910252
            ],
            [
              -122.376837,
              47.923703
            ],
            [
              -122.367876,
              47.932415
            ],
            [
              -122.358812,
              47.93742
            ],
            [
              -122.350741,
              47.953235
            ],
            [
              -122.349597,
              47.958796
            ],
            [
              -122.350254,
              47.969355
            ],
            [
              -122.353611,
              47.981433
            ],
            [
              -122.369161,
              47.995295
            ],
            [
              -122.373263,
              48.000791
            ],
            [
              -122.376259,
              48.034457
            ],
            [
              -122.387382,
              48.03403
            ],
            [
              -122.400628,
              48.036563
            ],
            [
              -122.431266,
              48.045001
            ],
            [
              -122.448419,
              48.054323
            ],
            [
              -122.461606,
              48.068501
            ],
            [
              -122.491104,
              48.094242
            ],
            [
              -122.513276,
              48.097538
            ],
            [
              -122.525422,
              48.096537
            ],
            [
              -122.516906,
              48.081085
            ],
            [
              -122.511081,
              48.075301
            ],
            [
              -122.513994,
              48.059077
            ],
            [
              -122.509652,
              48.039756
            ],
            [
              -122.517971,
              48.022432
            ],
            [
              -122.537936,
              48.016072
            ],
            [
              -122.54512,
              48.05255
            ],
            [
              -122.554559,
              48.077392
            ],
            [
              -122.571853,
              48.102143
            ],
            [
              -122.571615,
              48.105113
            ],
            [
              -122.559911,
              48.114186
            ],
            [
              -122.558205,
              48.119579
            ],
            [
              -122.567936,
              48.148624
            ],
            [
              -122.574905,
              48.155593
            ],
            [
              -122.582595,
              48.170424
            ],
            [
              -122.585778,
              48.182352
            ],
            [
              -122.588138,
              48.18594
            ],
            [
              -122.606406,
              48.208262
            ],
            [
              -122.628352,
              48.222467
            ],
            [
              -122.63126,
              48.220686
            ],
            [
              -122.668385,
              48.223967
            ],
            [
              -122.710967,
              48.214109
            ],
            [
              -122.726179,
              48.222661
            ],
            [
              -122.721769,
              48.234407
            ],
            [
              -122.66921,
              48.240614
            ],
            [
              -122.65343,
              48.25934
            ],
            [
              -122.652639,
              48.265081
            ],
            [
              -122.623779,
              48.269431
            ],
            [
              -122.620748,
              48.282961
            ],
            [
              -122.626757,
              48.288991
            ],
            [
              -122.618466,
              48.294159
            ],
            [
              -122.599532,
              48.298303
            ],
            [
              -122.584086,
              48.297987
            ],
            [
              -122.574872,
              48.294903
            ],
            [
              -122.558332,
              48.282061
            ],
            [
              -122.551793,
              48.281512
            ],
            [
              -122.530976,
              48.282445
            ],
            [
              -122.522756,
              48.285504
            ],
            [
              -122.519535,
              48.288314
            ],
            [
              -122.505828,
              48.297677
            ],
            [
              -122.504729,
              48.300373
            ],
            [
              -122.506568,
              48.310041
            ],
            [
              -122.515979,
              48.320419
            ],
            [
              -122.551334,
              48.342138
            ],
            [
              -122.565525,
              48.348217
            ],
            [
              -122.585162,
              48.353304
            ],
            [
              -122.588891,
              48.363005
            ],
            [
              -122.585038,
              48.395166
            ],
            [
              -122.595351,
              48.3972
            ],
            [
              -122.596732,
              48.405626
            ],
            [
              -122.60198,
              48.409907
            ],
            [
              -122.609715,
              48.411565
            ],
            [
              -122.617174,
              48.407145
            ],
            [
              -122.624293,
              48.400488
            ],
            [
              -122.634991,
              48.404244
            ],
            [
              -122.644798,
              48.405488
            ],
            [
              -122.664659,
              48.401508
            ],
            [
              -122.664928,
              48.374823
            ],
            [
              -122.673731,
              48.354683
            ],
            [
              -122.707077,
              48.315286
            ],
            [
              -122.72259,
              48.304268
            ],
            [
              -122.732022,
              48.279425
            ],
            [
              -122.752563,
              48.260061
            ],
            [
              -122.769939,
              48.227548
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "53",
      "COUNTYFP": "063",
      "COUNTYNS": "01529225",
      "AFFGEOID": "0500000US53063",
      "GEOID": "53063",
      "NAME": "Spokane",
      "LSAD": "06",
      "ALAND": 4568458718,
      "AWATER": 43434957,
      "County_state": "Spokane,53"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -117.822963,
              47.409427
            ],
            [
              -117.823629,
              47.26022
            ],
            [
              -117.040019,
              47.259272
            ],
            [
              -117.039843,
              47.347201
            ],
            [
              -117.04006933827101,
              47.366027858166596
            ],
            [
              -117.040176,
              47.3749
            ],
            [
              -117.039882,
              47.399085
            ],
            [
              -117.03995,
              47.412412
            ],
            [
              -117.039948,
              47.434885
            ],
            [
              -117.039971,
              47.463309
            ],
            [
              -117.039945,
              47.477823
            ],
            [
              -117.040514,
              47.522351
            ],
            [
              -117.040545,
              47.527562
            ],
            [
              -117.040745,
              47.532909
            ],
            [
              -117.041276,
              47.55821
            ],
            [
              -117.041174,
              47.55853
            ],
            [
              -117.04085,
              47.574124
            ],
            [
              -117.041126241252,
              47.6235792669663
            ],
            [
              -117.041431,
              47.67814
            ],
            [
              -117.041431,
              47.678185
            ],
            [
              -117.041431,
              47.68
            ],
            [
              -117.041532,
              47.683194
            ],
            [
              -117.041633,
              47.7064
            ],
            [
              -117.041678,
              47.72271
            ],
            [
              -117.041634,
              47.7353
            ],
            [
              -117.041669370094,
              47.7359212711082
            ],
            [
              -117.042135,
              47.7441
            ],
            [
              -117.042059,
              47.7451
            ],
            [
              -117.042657,
              47.760857
            ],
            [
              -117.042623,
              47.761223
            ],
            [
              -117.042521,
              47.764896
            ],
            [
              -117.042485,
              47.766525
            ],
            [
              -117.042064,
              47.77863
            ],
            [
              -117.041999,
              47.822399
            ],
            [
              -117.04247,
              47.839009
            ],
            [
              -117.04236689216701,
              47.958364752787794
            ],
            [
              -117.04236,
              47.966343
            ],
            [
              -117.04226408344101,
              47.977451511759796
            ],
            [
              -117.04167682656801,
              48.045464271600395
            ],
            [
              -117.436993,
              48.047207
            ],
            [
              -117.535262,
              48.047779
            ],
            [
              -117.535262,
              47.794458
            ],
            [
              -117.551273,
              47.801468
            ],
            [
              -117.575802,
              47.793522
            ],
            [
              -117.600119,
              47.803019
            ],
            [
              -117.630065,
              47.834225
            ],
            [
              -117.651111,
              47.83472
            ],
            [
              -117.660887,
              47.843634
            ],
            [
              -117.666511,
              47.880439
            ],
            [
              -117.675207,
              47.889099
            ],
            [
              -117.692207,
              47.883421
            ],
            [
              -117.701794,
              47.865116
            ],
            [
              -117.697774,
              47.856278
            ],
            [
              -117.754243,
              47.826832
            ],
            [
              -117.767113,
              47.83182
            ],
            [
              -117.782497,
              47.825557
            ],
            [
              -117.790459,
              47.811356
            ],
            [
              -117.806,
              47.812228
            ],
            [
              -117.820947,
              47.825775
            ],
            [
              -117.819712,
              47.657465
            ],
            [
              -117.821773,
              47.614588
            ],
            [
              -117.822963,
              47.409427
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "53",
      "COUNTYFP": "075",
      "COUNTYNS": "01533501",
      "AFFGEOID": "0500000US53075",
      "GEOID": "53075",
      "NAME": "Whitman",
      "LSAD": "06",
      "ALAND": 5592428648,
      "AWATER": 48281266,
      "County_state": "Whitman,53"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -118.249122,
              46.734138
            ],
            [
              -118.217536,
              46.679278
            ],
            [
              -118.230759,
              46.657042
            ],
            [
              -118.199796,
              46.624186
            ],
            [
              -118.21566,
              46.588924
            ],
            [
              -118.178843,
              46.560535
            ],
            [
              -118.165292,
              46.555135
            ],
            [
              -118.118448,
              46.556783
            ],
            [
              -118.092895,
              46.574529
            ],
            [
              -118.069095,
              46.574675
            ],
            [
              -118.035156,
              46.58516
            ],
            [
              -117.985255,
              46.586869
            ],
            [
              -117.952853,
              46.59207
            ],
            [
              -117.909049,
              46.584771
            ],
            [
              -117.881857,
              46.592193
            ],
            [
              -117.869546,
              46.619472
            ],
            [
              -117.85185,
              46.62467
            ],
            [
              -117.829091,
              46.622054
            ],
            [
              -117.800714,
              46.625645
            ],
            [
              -117.772468,
              46.64538
            ],
            [
              -117.757592,
              46.682346
            ],
            [
              -117.740868,
              46.694583
            ],
            [
              -117.68748,
              46.702594
            ],
            [
              -117.648253,
              46.699805
            ],
            [
              -117.626294,
              46.679197
            ],
            [
              -117.597792,
              46.672543
            ],
            [
              -117.551915,
              46.680133
            ],
            [
              -117.517164,
              46.676964
            ],
            [
              -117.500647,
              46.682053
            ],
            [
              -117.490004,
              46.695375
            ],
            [
              -117.464769,
              46.699626
            ],
            [
              -117.448346,
              46.674865
            ],
            [
              -117.4283,
              46.657107
            ],
            [
              -117.393028,
              46.654019
            ],
            [
              -117.385204,
              46.647928
            ],
            [
              -117.388638,
              46.625087
            ],
            [
              -117.382742,
              46.614684
            ],
            [
              -117.359074,
              46.602268
            ],
            [
              -117.337537,
              46.581972
            ],
            [
              -117.311447,
              46.580766
            ],
            [
              -117.254262,
              46.545082
            ],
            [
              -117.231337,
              46.500584
            ],
            [
              -117.238765,
              46.481187
            ],
            [
              -117.22812,
              46.462245
            ],
            [
              -117.206532,
              46.448874
            ],
            [
              -117.208628,
              46.423707
            ],
            [
              -117.179624,
              46.42219
            ],
            [
              -117.151591,
              46.430561
            ],
            [
              -117.086154,
              46.417354
            ],
            [
              -117.057915,
              46.429733
            ],
            [
              -117.03981207463,
              46.425904123257894
            ],
            [
              -117.039741,
              46.462704
            ],
            [
              -117.039763,
              46.46957
            ],
            [
              -117.039771,
              46.471779
            ],
            [
              -117.039656800448,
              46.541709233636894
            ],
            [
              -117.039398,
              46.700186
            ],
            [
              -117.039828,
              46.815443
            ],
            [
              -117.039657,
              46.825798
            ],
            [
              -117.039821054109,
              47.1272689624222
            ],
            [
              -117.039836,
              47.154734
            ],
            [
              -117.039871,
              47.181858
            ],
            [
              -117.039888,
              47.203282
            ],
            [
              -117.039899,
              47.225515
            ],
            [
              -117.040019,
              47.259272
            ],
            [
              -117.823629,
              47.26022
            ],
            [
              -117.96093,
              47.260568
            ],
            [
              -117.967099,
              46.914831
            ],
            [
              -117.970907,
              46.867216
            ],
            [
              -118.00412,
              46.848372
            ],
            [
              -117.992226,
              46.838065
            ],
            [
              -117.997524,
              46.822544
            ],
            [
              -118.019881,
              46.812154
            ],
            [
              -118.015567,
              46.784873
            ],
            [
              -118.035861,
              46.785017
            ],
            [
              -118.047978,
              46.768224
            ],
            [
              -118.0954,
              46.778986
            ],
            [
              -118.126095,
              46.761389
            ],
            [
              -118.156023,
              46.759468
            ],
            [
              -118.161482,
              46.747082
            ],
            [
              -118.189921,
              46.751467
            ],
            [
              -118.210243,
              46.738833
            ],
            [
              -118.249122,
              46.734138
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "53",
      "COUNTYFP": "019",
      "COUNTYNS": "01531821",
      "AFFGEOID": "0500000US53019",
      "GEOID": "53019",
      "NAME": "Ferry",
      "LSAD": "06",
      "ALAND": 5706360692,
      "AWATER": 140294376,
      "County_state": "Ferry,53"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -118.869633,
              48.653826
            ],
            [
              -118.86952,
              48.481626
            ],
            [
              -118.843888,
              48.481739
            ],
            [
              -118.845785,
              47.963478
            ],
            [
              -118.85196,
              47.957906
            ],
            [
              -118.833036,
              47.950467
            ],
            [
              -118.829403,
              47.933139
            ],
            [
              -118.811877,
              47.920285
            ],
            [
              -118.783216,
              47.915716
            ],
            [
              -118.730325,
              47.900169
            ],
            [
              -118.711682,
              47.903347
            ],
            [
              -118.707625,
              47.933579
            ],
            [
              -118.688394,
              47.942718
            ],
            [
              -118.661953,
              47.937407
            ],
            [
              -118.651995,
              47.927108
            ],
            [
              -118.570396,
              47.932318
            ],
            [
              -118.533498,
              47.914877
            ],
            [
              -118.54409,
              47.883973
            ],
            [
              -118.531297,
              47.874878
            ],
            [
              -118.501109,
              47.873845
            ],
            [
              -118.466978,
              47.882386
            ],
            [
              -118.456978,
              47.874879
            ],
            [
              -118.456165,
              47.854474
            ],
            [
              -118.44582,
              47.845496
            ],
            [
              -118.392659,
              47.830684
            ],
            [
              -118.369064,
              47.832685
            ],
            [
              -118.357065,
              47.841599
            ],
            [
              -118.349911,
              47.882572
            ],
            [
              -118.340938,
              47.894316
            ],
            [
              -118.343721,
              47.906257
            ],
            [
              -118.353041,
              47.949357
            ],
            [
              -118.352143,
              47.974758
            ],
            [
              -118.373429,
              47.994653
            ],
            [
              -118.378457,
              48.003434
            ],
            [
              -118.382538,
              48.00697
            ],
            [
              -118.391506,
              48.014241
            ],
            [
              -118.398007,
              48.020382
            ],
            [
              -118.400807,
              48.028682
            ],
            [
              -118.394807,
              48.032582
            ],
            [
              -118.383343,
              48.037438
            ],
            [
              -118.374957,
              48.041044
            ],
            [
              -118.354417,
              48.050119
            ],
            [
              -118.305155,
              48.041232
            ],
            [
              -118.28332,
              48.04073
            ],
            [
              -118.266282,
              48.054556
            ],
            [
              -118.253423,
              48.05769
            ],
            [
              -118.248198,
              48.054784
            ],
            [
              -118.239098,
              48.057684
            ],
            [
              -118.233398,
              48.064185
            ],
            [
              -118.235298,
              48.077085
            ],
            [
              -118.231098,
              48.086885
            ],
            [
              -118.235499,
              48.100186
            ],
            [
              -118.2462,
              48.109086
            ],
            [
              -118.253289,
              48.112481
            ],
            [
              -118.253583,
              48.121742
            ],
            [
              -118.251801,
              48.132042
            ],
            [
              -118.242589,
              48.135475
            ],
            [
              -118.2253,
              48.137187
            ],
            [
              -118.200498,
              48.141187
            ],
            [
              -118.193079,
              48.151899
            ],
            [
              -118.191943,
              48.165159
            ],
            [
              -118.195999,
              48.179088
            ],
            [
              -118.194999,
              48.192789
            ],
            [
              -118.189399,
              48.205189
            ],
            [
              -118.1918,
              48.210389
            ],
            [
              -118.191449,
              48.215275
            ],
            [
              -118.1866,
              48.21829
            ],
            [
              -118.174199,
              48.22539
            ],
            [
              -118.160499,
              48.23359
            ],
            [
              -118.148218,
              48.245051
            ],
            [
              -118.145398,
              48.251791
            ],
            [
              -118.144296,
              48.254628
            ],
            [
              -118.14188,
              48.259438
            ],
            [
              -118.140624,
              48.269751
            ],
            [
              -118.148458,
              48.27801
            ],
            [
              -118.15286,
              48.291798
            ],
            [
              -118.1617,
              48.302592
            ],
            [
              -118.166701,
              48.308392
            ],
            [
              -118.17393,
              48.317389
            ],
            [
              -118.176377,
              48.327397
            ],
            [
              -118.179805,
              48.334301
            ],
            [
              -118.179902,
              48.345593
            ],
            [
              -118.180203,
              48.358293
            ],
            [
              -118.181443,
              48.369854
            ],
            [
              -118.186904,
              48.381994
            ],
            [
              -118.189704,
              48.391494
            ],
            [
              -118.195851,
              48.398466
            ],
            [
              -118.201805,
              48.405394
            ],
            [
              -118.202444,
              48.418722
            ],
            [
              -118.201006,
              48.428595
            ],
            [
              -118.202706,
              48.439795
            ],
            [
              -118.211507,
              48.449395
            ],
            [
              -118.211607,
              48.459896
            ],
            [
              -118.203307,
              48.472796
            ],
            [
              -118.194406,
              48.478496
            ],
            [
              -118.188406,
              48.483697
            ],
            [
              -118.184706,
              48.492097
            ],
            [
              -118.181006,
              48.499197
            ],
            [
              -118.180424,
              48.509263
            ],
            [
              -118.178879,
              48.515653
            ],
            [
              -118.170122,
              48.523138
            ],
            [
              -118.160931,
              48.521666
            ],
            [
              -118.151464,
              48.520337
            ],
            [
              -118.141597,
              48.527254
            ],
            [
              -118.141909,
              48.53521
            ],
            [
              -118.143259,
              48.54741
            ],
            [
              -118.138619,
              48.561674
            ],
            [
              -118.131867,
              48.576527
            ],
            [
              -118.126081,
              48.586959
            ],
            [
              -118.127246,
              48.596561
            ],
            [
              -118.128008,
              48.608651
            ],
            [
              -118.124394,
              48.61734
            ],
            [
              -118.119154,
              48.621858
            ],
            [
              -118.118122,
              48.625819
            ],
            [
              -118.118703,
              48.627673
            ],
            [
              -118.119915,
              48.629204
            ],
            [
              -118.117518,
              48.630729
            ],
            [
              -118.116185,
              48.636396
            ],
            [
              -118.107645,
              48.640497
            ],
            [
              -118.104729,
              48.651821
            ],
            [
              -118.10829,
              48.661206
            ],
            [
              -118.109128,
              48.667023
            ],
            [
              -118.112912,
              48.674199
            ],
            [
              -118.116708,
              48.674239
            ],
            [
              -118.117326,
              48.677136
            ],
            [
              -118.115468,
              48.680352
            ],
            [
              -118.115469,
              48.687809
            ],
            [
              -118.120898,
              48.702802
            ],
            [
              -118.120417,
              48.711529
            ],
            [
              -118.123206,
              48.721889
            ],
            [
              -118.122741,
              48.730109
            ],
            [
              -118.117852,
              48.734453
            ],
            [
              -118.122933,
              48.73687
            ],
            [
              -118.127062,
              48.7382
            ],
            [
              -118.129392,
              48.741793
            ],
            [
              -118.126982,
              48.745171
            ],
            [
              -118.122344,
              48.747049
            ],
            [
              -118.122636,
              48.750406
            ],
            [
              -118.124691,
              48.752654
            ],
            [
              -118.128874,
              48.754836
            ],
            [
              -118.132919,
              48.756741
            ],
            [
              -118.131355,
              48.760483
            ],
            [
              -118.128148,
              48.760562
            ],
            [
              -118.125217,
              48.762391
            ],
            [
              -118.125305,
              48.763629
            ],
            [
              -118.127331,
              48.765121
            ],
            [
              -118.128211,
              48.764879
            ],
            [
              -118.132195,
              48.765548
            ],
            [
              -118.133866,
              48.7682
            ],
            [
              -118.133987,
              48.771595
            ],
            [
              -118.125337,
              48.774968
            ],
            [
              -118.122014,
              48.777904
            ],
            [
              -118.124335,
              48.782706
            ],
            [
              -118.125346,
              48.784637
            ],
            [
              -118.135793,
              48.783002
            ],
            [
              -118.139922,
              48.779329
            ],
            [
              -118.145477,
              48.782978
            ],
            [
              -118.152708,
              48.789451
            ],
            [
              -118.159418,
              48.797881
            ],
            [
              -118.160285,
              48.805333
            ],
            [
              -118.167097,
              48.816087
            ],
            [
              -118.170432,
              48.826413
            ],
            [
              -118.179317,
              48.837639
            ],
            [
              -118.186627,
              48.838099
            ],
            [
              -118.195517,
              48.843993
            ],
            [
              -118.193815,
              48.848781
            ],
            [
              -118.199268,
              48.855481
            ],
            [
              -118.200242,
              48.86268
            ],
            [
              -118.205415,
              48.870104
            ],
            [
              -118.211034,
              48.874903
            ],
            [
              -118.212751,
              48.883396
            ],
            [
              -118.212627,
              48.890087
            ],
            [
              -118.206172,
              48.900621
            ],
            [
              -118.200144,
              48.907619
            ],
            [
              -118.197641,
              48.911764
            ],
            [
              -118.201322,
              48.914888
            ],
            [
              -118.208383,
              48.920432
            ],
            [
              -118.21901,
              48.931544
            ],
            [
              -118.220987,
              48.938387
            ],
            [
              -118.217281,
              48.947276
            ],
            [
              -118.210726,
              48.956994
            ],
            [
              -118.207597,
              48.962029
            ],
            [
              -118.214529,
              48.968415
            ],
            [
              -118.219829,
              48.971972
            ],
            [
              -118.216244,
              48.983835
            ],
            [
              -118.210032,
              48.985628
            ],
            [
              -118.211126,
              48.990267
            ],
            [
              -118.216119,
              48.993708
            ],
            [
              -118.207713,
              48.99704
            ],
            [
              -118.202737,
              48.994365
            ],
            [
              -118.195238,
              48.995945
            ],
            [
              -118.19737505245901,
              49.0004067514246
            ],
            [
              -118.83661303558,
              49.0003077592976
            ],
            [
              -118.837006,
              48.653869
            ],
            [
              -118.869633,
              48.653826
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "53",
      "COUNTYFP": "059",
      "COUNTYNS": "01529220",
      "AFFGEOID": "0500000US53059",
      "GEOID": "53059",
      "NAME": "Skamania",
      "LSAD": "06",
      "ALAND": 4294875666,
      "AWATER": 73344946,
      "County_state": "Skamania,53"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -122.249841,
              45.818941
            ],
            [
              -122.248995,
              45.614597
            ],
            [
              -122.24891194766401,
              45.5477731000169
            ],
            [
              -122.2017,
              45.564141
            ],
            [
              -122.183695,
              45.577696
            ],
            [
              -122.14075,
              45.584508
            ],
            [
              -122.129548,
              45.582945
            ],
            [
              -122.12949,
              45.582967
            ],
            [
              -122.126197,
              45.582573
            ],
            [
              -122.126197,
              45.582617
            ],
            [
              -122.112356,
              45.581409
            ],
            [
              -122.101675,
              45.583516
            ],
            [
              -122.044374,
              45.609516
            ],
            [
              -122.022571,
              45.615151
            ],
            [
              -122.00369,
              45.61593
            ],
            [
              -121.983038,
              45.622812
            ],
            [
              -121.979797,
              45.624839
            ],
            [
              -121.963547,
              45.632784
            ],
            [
              -121.955734,
              45.643559
            ],
            [
              -121.951838,
              45.644951
            ],
            [
              -121.935149,
              45.644169
            ],
            [
              -121.922028852172,
              45.649161899050796
            ],
            [
              -121.908267,
              45.654399
            ],
            [
              -121.900858,
              45.662009
            ],
            [
              -121.901855,
              45.670716
            ],
            [
              -121.867167,
              45.693277
            ],
            [
              -121.811304,
              45.706761
            ],
            [
              -121.735104,
              45.694039
            ],
            [
              -121.707358,
              45.694809
            ],
            [
              -121.668362,
              45.705082
            ],
            [
              -121.631167,
              45.704657
            ],
            [
              -121.533106,
              45.726541
            ],
            [
              -121.523685853632,
              45.7249021020319
            ],
            [
              -121.521376,
              45.748152
            ],
            [
              -121.53835,
              45.770126
            ],
            [
              -121.517391,
              45.781989
            ],
            [
              -121.609246,
              45.782519
            ],
            [
              -121.608618,
              45.870357
            ],
            [
              -121.612232,
              46.044093
            ],
            [
              -121.522321,
              46.044006
            ],
            [
              -121.522324,
              46.388224
            ],
            [
              -122.114291,
              46.38648
            ],
            [
              -122.236759,
              46.385893
            ],
            [
              -122.240966,
              46.385361
            ],
            [
              -122.2396,
              46.38288
            ],
            [
              -122.242008,
              46.21162
            ],
            [
              -122.246677,
              46.200575
            ],
            [
              -122.245856,
              46.053853
            ],
            [
              -122.244515,
              45.866561
            ],
            [
              -122.249841,
              45.818941
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "53",
      "COUNTYFP": "003",
      "COUNTYNS": "01533502",
      "AFFGEOID": "0500000US53003",
      "GEOID": "53003",
      "NAME": "Asotin",
      "LSAD": "06",
      "ALAND": 1647443944,
      "AWATER": 11275706,
      "County_state": "Asotin,53"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -117.479988,
              46.121994
            ],
            [
              -117.479935132756,
              45.997869469110505
            ],
            [
              -117.47536,
              45.997855
            ],
            [
              -117.475148,
              45.997893
            ],
            [
              -117.439943,
              45.998633
            ],
            [
              -117.390738,
              45.998598
            ],
            [
              -117.353928,
              45.996349
            ],
            [
              -117.337668,
              45.998662
            ],
            [
              -117.216731,
              45.998356
            ],
            [
              -117.214534,
              45.99832
            ],
            [
              -117.212616,
              45.998321
            ],
            [
              -117.070047,
              45.99751
            ],
            [
              -117.051304,
              45.996849
            ],
            [
              -116.985882,
              45.996974
            ],
            [
              -116.940681,
              45.996274
            ],
            [
              -116.915989,
              45.995413
            ],
            [
              -116.91718,
              45.996575
            ],
            [
              -116.91868,
              45.999875
            ],
            [
              -116.923005,
              46.018293
            ],
            [
              -116.931706,
              46.039651
            ],
            [
              -116.942656,
              46.061
            ],
            [
              -116.948564,
              46.067933
            ],
            [
              -116.957372,
              46.075449
            ],
            [
              -116.960416,
              46.076346
            ],
            [
              -116.96319,
              46.075905
            ],
            [
              -116.970009,
              46.076769
            ],
            [
              -116.978938,
              46.080007
            ],
            [
              -116.981962,
              46.084915
            ],
            [
              -116.982479,
              46.089389
            ],
            [
              -116.982498,
              46.091347
            ],
            [
              -116.981747,
              46.092881
            ],
            [
              -116.978823,
              46.095731
            ],
            [
              -116.976957,
              46.09667
            ],
            [
              -116.974058,
              46.097707
            ],
            [
              -116.959548,
              46.099058
            ],
            [
              -116.955263,
              46.102237
            ],
            [
              -116.951265,
              46.111161
            ],
            [
              -116.95098,
              46.118853
            ],
            [
              -116.950276,
              46.123464
            ],
            [
              -116.948336,
              46.125885
            ],
            [
              -116.935473,
              46.142448
            ],
            [
              -116.922648,
              46.160744
            ],
            [
              -116.921258,
              46.164795
            ],
            [
              -116.92187,
              46.167808
            ],
            [
              -116.923958,
              46.17092
            ],
            [
              -116.941724,
              46.185034
            ],
            [
              -116.952416,
              46.193514
            ],
            [
              -116.962966,
              46.19968
            ],
            [
              -116.965841,
              46.203417
            ],
            [
              -116.966569,
              46.207501
            ],
            [
              -116.96613,
              46.209453
            ],
            [
              -116.959428,
              46.219812
            ],
            [
              -116.956031,
              46.225976
            ],
            [
              -116.955264,
              46.23088
            ],
            [
              -116.958801,
              46.24232
            ],
            [
              -116.964379,
              46.253282
            ],
            [
              -116.966742,
              46.256923
            ],
            [
              -116.970298,
              46.261233
            ],
            [
              -116.972591,
              46.263271
            ],
            [
              -116.976054,
              46.26601
            ],
            [
              -116.987391,
              46.272865
            ],
            [
              -116.991134,
              46.276342
            ],
            [
              -116.991422,
              46.278467
            ],
            [
              -116.990894,
              46.280372
            ],
            [
              -116.98491,
              46.289738
            ],
            [
              -116.98463,
              46.292705
            ],
            [
              -116.986688,
              46.296662
            ],
            [
              -116.989794,
              46.299395
            ],
            [
              -116.99726,
              46.303151
            ],
            [
              -117.007486,
              46.305302
            ],
            [
              -117.016413,
              46.311236
            ],
            [
              -117.020663,
              46.314793
            ],
            [
              -117.022293,
              46.31747
            ],
            [
              -117.022939,
              46.320175
            ],
            [
              -117.023424,
              46.326427
            ],
            [
              -117.022706,
              46.32899
            ],
            [
              -117.023149,
              46.334759
            ],
            [
              -117.023844,
              46.335976
            ],
            [
              -117.027744,
              46.338751
            ],
            [
              -117.030672,
              46.340315
            ],
            [
              -117.03445,
              46.34132
            ],
            [
              -117.045469,
              46.34249
            ],
            [
              -117.051735,
              46.343833
            ],
            [
              -117.055983,
              46.345531
            ],
            [
              -117.060703,
              46.349015
            ],
            [
              -117.06263,
              46.352522
            ],
            [
              -117.062748,
              46.353624
            ],
            [
              -117.062785,
              46.365287
            ],
            [
              -117.061045,
              46.367747
            ],
            [
              -117.057516,
              46.371396
            ],
            [
              -117.051775,
              46.375641
            ],
            [
              -117.049474,
              46.37682
            ],
            [
              -117.046915,
              46.379577
            ],
            [
              -117.04195,
              46.39216
            ],
            [
              -117.041737,
              46.395195
            ],
            [
              -117.038282,
              46.40604
            ],
            [
              -117.036455,
              46.407792
            ],
            [
              -117.035545,
              46.410012
            ],
            [
              -117.034696,
              46.418318
            ],
            [
              -117.036562,
              46.422596
            ],
            [
              -117.039813,
              46.425425
            ],
            [
              -117.03981207463,
              46.425904123257894
            ],
            [
              -117.057915,
              46.429733
            ],
            [
              -117.086154,
              46.417354
            ],
            [
              -117.151591,
              46.430561
            ],
            [
              -117.179624,
              46.42219
            ],
            [
              -117.208628,
              46.423707
            ],
            [
              -117.206532,
              46.448874
            ],
            [
              -117.22812,
              46.462245
            ],
            [
              -117.228796,
              46.411306
            ],
            [
              -117.355982,
              46.412187
            ],
            [
              -117.355764,
              46.397645
            ],
            [
              -117.398429,
              46.397996
            ],
            [
              -117.398341,
              46.383457
            ],
            [
              -117.419334,
              46.383139
            ],
            [
              -117.419482,
              46.121956
            ],
            [
              -117.479988,
              46.121994
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "53",
      "COUNTYFP": "035",
      "COUNTYNS": "01529223",
      "AFFGEOID": "0500000US53035",
      "GEOID": "53035",
      "NAME": "Kitsap",
      "LSAD": "06",
      "ALAND": 1023306116,
      "AWATER": 442189824,
      "County_state": "Kitsap,53"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -122.504462,
              47.551674
            ],
            [
              -122.511593,
              47.547664
            ],
            [
              -122.502085,
              47.528409
            ],
            [
              -122.490201,
              47.525199
            ],
            [
              -122.477129,
              47.532421
            ],
            [
              -122.477129,
              47.546059
            ],
            [
              -122.504462,
              47.551674
            ]
          ]
        ],
        [
          [
            [
              -122.967284,
              47.585685
            ],
            [
              -123.01641268704,
              47.520298015202
            ],
            [
              -122.939665,
              47.522512
            ],
            [
              -122.801012,
              47.520406
            ],
            [
              -122.801199,
              47.403578
            ],
            [
              -122.664071,
              47.403311
            ],
            [
              -122.54612263956201,
              47.4034087828546
            ],
            [
              -122.531889,
              47.428827
            ],
            [
              -122.530514,
              47.469041
            ],
            [
              -122.494882,
              47.510265
            ],
            [
              -122.500543,
              47.51528
            ],
            [
              -122.52305,
              47.524
            ],
            [
              -122.532909,
              47.522184
            ],
            [
              -122.546611,
              47.52355
            ],
            [
              -122.547207,
              47.528257
            ],
            [
              -122.542355,
              47.53784
            ],
            [
              -122.543118,
              47.556326
            ],
            [
              -122.534664,
              47.566122
            ],
            [
              -122.52991471617301,
              47.5684411262623
            ],
            [
              -122.518367,
              47.57408
            ],
            [
              -122.503672,
              47.575178
            ],
            [
              -122.479089,
              47.583654
            ],
            [
              -122.483805,
              47.586721
            ],
            [
              -122.493933,
              47.588963
            ],
            [
              -122.49824,
              47.598242
            ],
            [
              -122.500357,
              47.617816
            ],
            [
              -122.494518,
              47.623625
            ],
            [
              -122.492809,
              47.629591
            ],
            [
              -122.493205,
              47.635122
            ],
            [
              -122.502116,
              47.639074
            ],
            [
              -122.518277,
              47.65132
            ],
            [
              -122.508709,
              47.670843
            ],
            [
              -122.504452,
              47.685888
            ],
            [
              -122.504604,
              47.699136
            ],
            [
              -122.511196,
              47.708715
            ],
            [
              -122.523962,
              47.708034
            ],
            [
              -122.525851,
              47.705095
            ],
            [
              -122.53094,
              47.704814
            ],
            [
              -122.543161,
              47.710941
            ],
            [
              -122.554454,
              47.745704
            ],
            [
              -122.537318,
              47.74714
            ],
            [
              -122.519325,
              47.74622
            ],
            [
              -122.515193,
              47.743911
            ],
            [
              -122.507638,
              47.74304
            ],
            [
              -122.488491,
              47.743605
            ],
            [
              -122.477344,
              47.746019
            ],
            [
              -122.471844,
              47.749819
            ],
            [
              -122.470333,
              47.757109
            ],
            [
              -122.471402,
              47.765965
            ],
            [
              -122.495458,
              47.786692
            ],
            [
              -122.495346,
              47.79704
            ],
            [
              -122.485214,
              47.804128
            ],
            [
              -122.482437,
              47.809255
            ],
            [
              -122.482529,
              47.815511
            ],
            [
              -122.502224,
              47.826395
            ],
            [
              -122.506122,
              47.831745
            ],
            [
              -122.512778,
              47.863879
            ],
            [
              -122.513986,
              47.880807
            ],
            [
              -122.527593,
              47.905882
            ],
            [
              -122.549072,
              47.919072
            ],
            [
              -122.578931,
              47.925839
            ],
            [
              -122.603861,
              47.940478
            ],
            [
              -122.611956,
              47.940772
            ],
            [
              -122.617022,
              47.938987
            ],
            [
              -122.620316,
              47.931553
            ],
            [
              -122.616701,
              47.925139
            ],
            [
              -122.596228,
              47.92021
            ],
            [
              -122.588235,
              47.912923
            ],
            [
              -122.586339,
              47.902023
            ],
            [
              -122.573098,
              47.874081
            ],
            [
              -122.573672,
              47.857582
            ],
            [
              -122.608105,
              47.856728
            ],
            [
              -122.614585,
              47.850806
            ],
            [
              -122.623192,
              47.836199
            ],
            [
              -122.648108,
              47.825123
            ],
            [
              -122.682015,
              47.800882
            ],
            [
              -122.684085,
              47.798574
            ],
            [
              -122.690562,
              47.778372
            ],
            [
              -122.714801,
              47.768176
            ],
            [
              -122.719712,
              47.760976
            ],
            [
              -122.722686,
              47.748827
            ],
            [
              -122.733012,
              47.737625
            ],
            [
              -122.740159,
              47.736228
            ],
            [
              -122.75377430216001,
              47.6735085044304
            ],
            [
              -122.754186,
              47.671612
            ],
            [
              -122.804498,
              47.653363
            ],
            [
              -122.856611,
              47.649615
            ],
            [
              -122.917103,
              47.620743
            ],
            [
              -122.967284,
              47.585685
            ]
          ]
        ]
      ]
    }
  }
]
};