export const counties_id = {
  "type":"FeatureCollection","name":"id_counties","features":[
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "16",
      "COUNTYFP": "063",
      "COUNTYNS": "00395624",
      "AFFGEOID": "0500000US16063",
      "GEOID": "16063",
      "NAME": "Lincoln",
      "LSAD": "06",
      "ALAND": 3111451190,
      "AWATER": 11606076,
      "County_state": "Lincoln,16"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -114.594605,
              43.198345
            ],
            [
              -114.594375,
              42.850901
            ],
            [
              -114.365366,
              42.85079
            ],
            [
              -114.365327,
              42.823611
            ],
            [
              -114.345652,
              42.823562
            ],
            [
              -114.345659,
              42.809055
            ],
            [
              -114.049849,
              42.808444
            ],
            [
              -114.049961,
              42.765006
            ],
            [
              -113.931687,
              42.765026
            ],
            [
              -113.763862,
              42.764508
            ],
            [
              -113.76382,
              42.849823
            ],
            [
              -113.714044,
              42.849734
            ],
            [
              -113.714642,
              43.199783
            ],
            [
              -114.374962,
              43.19964
            ],
            [
              -114.594605,
              43.198345
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "16",
      "COUNTYFP": "009",
      "COUNTYNS": "00395190",
      "AFFGEOID": "0500000US16009",
      "GEOID": "16009",
      "NAME": "Benewah",
      "LSAD": "06",
      "ALAND": 2012070462,
      "AWATER": 18975652,
      "County_state": "Benewah,16"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -117.04006933827101,
              47.366027858166596
            ],
            [
              -117.039843,
              47.347201
            ],
            [
              -117.040019,
              47.259272
            ],
            [
              -117.039899,
              47.225515
            ],
            [
              -117.039888,
              47.203282
            ],
            [
              -117.039871,
              47.181858
            ],
            [
              -117.039836,
              47.154734
            ],
            [
              -117.039821054109,
              47.1272689624222
            ],
            [
              -117.001018,
              47.130487
            ],
            [
              -116.967735,
              47.115232
            ],
            [
              -116.977444,
              47.089121
            ],
            [
              -116.957449,
              47.070897
            ],
            [
              -116.944371,
              47.067227
            ],
            [
              -116.925186,
              47.081565
            ],
            [
              -116.896191,
              47.077896
            ],
            [
              -116.883079,
              47.066392
            ],
            [
              -116.847007,
              47.047218
            ],
            [
              -116.835104,
              47.04547
            ],
            [
              -116.835269,
              47.032452
            ],
            [
              -116.771126,
              47.032511
            ],
            [
              -116.45812,
              47.036596
            ],
            [
              -116.458221,
              47.022096
            ],
            [
              -116.329418,
              47.021915
            ],
            [
              -116.329406,
              47.414011
            ],
            [
              -116.43624,
              47.412724
            ],
            [
              -116.586549,
              47.414695
            ],
            [
              -116.586486,
              47.400276
            ],
            [
              -116.629108,
              47.400333
            ],
            [
              -116.629059,
              47.371438
            ],
            [
              -116.735395,
              47.371455
            ],
            [
              -116.735215,
              47.385917
            ],
            [
              -116.749616,
              47.385888
            ],
            [
              -116.810814,
              47.380658
            ],
            [
              -116.8108,
              47.366308
            ],
            [
              -117.04006933827101,
              47.366027858166596
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "16",
      "COUNTYFP": "021",
      "COUNTYNS": "00395159",
      "AFFGEOID": "0500000US16021",
      "GEOID": "16021",
      "NAME": "Boundary",
      "LSAD": "06",
      "ALAND": 3285990181,
      "AWATER": 24160674,
      "County_state": "Boundary,16"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -117.032107,
              48.874926
            ],
            [
              -117.03238462208,
              48.84666529306679
            ],
            [
              -116.785174,
              48.847402
            ],
            [
              -116.785542,
              48.686544
            ],
            [
              -116.786625,
              48.674195
            ],
            [
              -116.78484,
              48.674197
            ],
            [
              -116.784878,
              48.500472
            ],
            [
              -116.049156520649,
              48.502042481758096
            ],
            [
              -116.049157492578,
              48.515333990311596
            ],
            [
              -116.049193,
              49.000912
            ],
            [
              -116.369128,
              49.001146
            ],
            [
              -116.376039,
              49.000518
            ],
            [
              -116.417503,
              49.000099
            ],
            [
              -116.757234,
              48.999943
            ],
            [
              -116.757185,
              48.999791
            ],
            [
              -117.032351,
              48.999188
            ],
            [
              -117.032107,
              48.874926
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "16",
      "COUNTYFP": "037",
      "COUNTYNS": "00399758",
      "AFFGEOID": "0500000US16037",
      "GEOID": "16037",
      "NAME": "Custer",
      "LSAD": "06",
      "ALAND": 12748200986,
      "AWATER": 42566923,
      "County_state": "Custer,16"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -115.303742,
              44.581043
            ],
            [
              -115.304044,
              44.575056
            ],
            [
              -115.300803,
              44.568383
            ],
            [
              -115.300966,
              44.56329
            ],
            [
              -115.298636,
              44.556093
            ],
            [
              -115.297686,
              44.550986
            ],
            [
              -115.294932,
              44.549414
            ],
            [
              -115.294297,
              44.54544
            ],
            [
              -115.290431,
              44.541377
            ],
            [
              -115.292116,
              44.535804
            ],
            [
              -115.290824,
              44.53132
            ],
            [
              -115.285278,
              44.52927
            ],
            [
              -115.282096,
              44.523465
            ],
            [
              -115.281453,
              44.520091
            ],
            [
              -115.277371,
              44.518462
            ],
            [
              -115.273489,
              44.518774
            ],
            [
              -115.267639,
              44.520042
            ],
            [
              -115.263515,
              44.51803
            ],
            [
              -115.255934,
              44.517086
            ],
            [
              -115.252558,
              44.518904
            ],
            [
              -115.246882,
              44.519167
            ],
            [
              -115.245968,
              44.515534
            ],
            [
              -115.240666,
              44.51238
            ],
            [
              -115.238571,
              44.507009
            ],
            [
              -115.238583,
              44.500816
            ],
            [
              -115.236931,
              44.49703
            ],
            [
              -115.231908,
              44.492472
            ],
            [
              -115.228093,
              44.487223
            ],
            [
              -115.227276,
              44.48239
            ],
            [
              -115.226432,
              44.475418
            ],
            [
              -115.233345,
              44.470944
            ],
            [
              -115.234503,
              44.467965
            ],
            [
              -115.230056,
              44.466088
            ],
            [
              -115.227135,
              44.465588
            ],
            [
              -115.225121,
              44.460405
            ],
            [
              -115.229583,
              44.459547
            ],
            [
              -115.23106,
              44.455095
            ],
            [
              -115.229463,
              44.452426
            ],
            [
              -115.22898,
              44.441839
            ],
            [
              -115.22822,
              44.42583
            ],
            [
              -115.233253,
              44.412603
            ],
            [
              -115.240564,
              44.405517
            ],
            [
              -115.248642,
              44.386441
            ],
            [
              -115.256596,
              44.379034
            ],
            [
              -115.263257,
              44.36844
            ],
            [
              -115.271956,
              44.361556
            ],
            [
              -115.281225,
              44.357077
            ],
            [
              -115.285133,
              44.354185
            ],
            [
              -115.284148,
              44.349963
            ],
            [
              -115.289089,
              44.344212
            ],
            [
              -115.294007,
              44.342791
            ],
            [
              -115.294614,
              44.339445
            ],
            [
              -115.28772,
              44.324685
            ],
            [
              -115.249734,
              44.311087
            ],
            [
              -115.247849,
              44.296699
            ],
            [
              -115.235985,
              44.308114
            ],
            [
              -115.21268,
              44.303959
            ],
            [
              -115.203028,
              44.282529
            ],
            [
              -115.178964,
              44.296521
            ],
            [
              -115.168795,
              44.283057
            ],
            [
              -115.16054,
              44.239624
            ],
            [
              -115.174831,
              44.235464
            ],
            [
              -115.162765,
              44.213706
            ],
            [
              -115.167684,
              44.20284
            ],
            [
              -115.143669,
              44.190311
            ],
            [
              -115.130464,
              44.192208
            ],
            [
              -115.107931,
              44.17006
            ],
            [
              -115.08315,
              44.176176
            ],
            [
              -115.061735,
              44.160133
            ],
            [
              -115.051284,
              44.165271
            ],
            [
              -115.024181,
              44.155478
            ],
            [
              -115.017146,
              44.141349
            ],
            [
              -115.02812,
              44.127395
            ],
            [
              -115.046725,
              44.119971
            ],
            [
              -115.020525,
              44.098386
            ],
            [
              -114.998038,
              44.098227
            ],
            [
              -114.999666,
              44.085714
            ],
            [
              -115.019612,
              44.079954
            ],
            [
              -115.04356,
              44.05882
            ],
            [
              -115.048135,
              44.037803
            ],
            [
              -115.032353,
              44.019492
            ],
            [
              -115.007358,
              44.013328
            ],
            [
              -114.998601,
              44.020662
            ],
            [
              -114.982558,
              44.011188
            ],
            [
              -114.9849,
              43.988844
            ],
            [
              -114.967343,
              43.982088
            ],
            [
              -114.990777,
              43.949723
            ],
            [
              -114.978878,
              43.94682
            ],
            [
              -114.971351,
              43.938308
            ],
            [
              -114.939511,
              43.953176
            ],
            [
              -114.931017,
              43.968729
            ],
            [
              -114.873372,
              43.992949
            ],
            [
              -114.813873,
              43.992941
            ],
            [
              -114.81379,
              43.927772
            ],
            [
              -114.681168,
              43.927705
            ],
            [
              -114.644414,
              43.914255
            ],
            [
              -114.633305,
              43.902383
            ],
            [
              -114.606151,
              43.89362
            ],
            [
              -114.583469,
              43.875103
            ],
            [
              -114.581992,
              43.85145
            ],
            [
              -114.56235,
              43.844368
            ],
            [
              -114.528447,
              43.845662
            ],
            [
              -114.513254,
              43.863216
            ],
            [
              -114.519673,
              43.871679
            ],
            [
              -114.507778,
              43.89909
            ],
            [
              -114.485066,
              43.904504
            ],
            [
              -114.474541,
              43.896517
            ],
            [
              -114.443708,
              43.897193
            ],
            [
              -114.427482,
              43.891509
            ],
            [
              -114.410002,
              43.895651
            ],
            [
              -114.359293,
              43.874177
            ],
            [
              -114.343532,
              43.890279
            ],
            [
              -114.292684,
              43.887143
            ],
            [
              -114.278814,
              43.878849
            ],
            [
              -114.283922,
              43.860893
            ],
            [
              -114.275725,
              43.831138
            ],
            [
              -114.24162,
              43.810776
            ],
            [
              -114.179999,
              43.789901
            ],
            [
              -114.159877,
              43.774429
            ],
            [
              -114.142131,
              43.77424
            ],
            [
              -114.137707,
              43.756291
            ],
            [
              -114.116996,
              43.740347
            ],
            [
              -114.070019,
              43.734696
            ],
            [
              -114.053049,
              43.746592
            ],
            [
              -114.053446,
              43.762146
            ],
            [
              -114.029718,
              43.773361
            ],
            [
              -114.006229,
              43.765883
            ],
            [
              -113.984107,
              43.733687
            ],
            [
              -113.97447,
              43.708186
            ],
            [
              -113.951987,
              43.697743
            ],
            [
              -113.933516,
              43.679365
            ],
            [
              -113.911432,
              43.67158
            ],
            [
              -113.904918,
              43.649719
            ],
            [
              -113.875329,
              43.650504
            ],
            [
              -113.843889,
              43.624972
            ],
            [
              -113.835607,
              43.604909
            ],
            [
              -113.813355,
              43.600059
            ],
            [
              -113.806069,
              43.571845
            ],
            [
              -113.798441,
              43.569217
            ],
            [
              -113.78432,
              43.580329
            ],
            [
              -113.759998,
              43.622681
            ],
            [
              -113.724058,
              43.629013
            ],
            [
              -113.692544,
              43.652527
            ],
            [
              -113.617065,
              43.683937
            ],
            [
              -113.598473,
              43.695422
            ],
            [
              -113.566157,
              43.703785
            ],
            [
              -113.543525,
              43.719439
            ],
            [
              -113.528063,
              43.719091
            ],
            [
              -113.505509,
              43.738359
            ],
            [
              -113.486882,
              43.739668
            ],
            [
              -113.448013,
              43.775289
            ],
            [
              -113.443219,
              43.808551
            ],
            [
              -113.41444,
              43.816926
            ],
            [
              -113.377877,
              43.8097
            ],
            [
              -113.36399,
              43.794649
            ],
            [
              -113.372913,
              43.821455
            ],
            [
              -113.382405,
              43.82247
            ],
            [
              -113.410567,
              43.8602
            ],
            [
              -113.456893,
              43.876964
            ],
            [
              -113.456815,
              43.893744
            ],
            [
              -113.445637,
              43.914554
            ],
            [
              -113.446264,
              43.983666
            ],
            [
              -113.454457,
              43.990501
            ],
            [
              -113.457816,
              44.059528
            ],
            [
              -113.416272,
              44.059338
            ],
            [
              -113.416525,
              44.233364
            ],
            [
              -113.320609,
              44.233164
            ],
            [
              -113.332822,
              44.247479
            ],
            [
              -113.326005,
              44.273521
            ],
            [
              -113.336983,
              44.293945
            ],
            [
              -113.339445,
              44.316077
            ],
            [
              -113.356414,
              44.329026
            ],
            [
              -113.361159,
              44.345502
            ],
            [
              -113.404821,
              44.382388
            ],
            [
              -113.425443,
              44.422896
            ],
            [
              -113.43585,
              44.426762
            ],
            [
              -113.447361,
              44.451579
            ],
            [
              -113.477444,
              44.448035
            ],
            [
              -113.502065,
              44.422098
            ],
            [
              -113.554416,
              44.426527
            ],
            [
              -113.589263,
              44.44358
            ],
            [
              -113.659449,
              44.450627
            ],
            [
              -113.68953,
              44.467912
            ],
            [
              -113.789074,
              44.494185
            ],
            [
              -113.818,
              44.495127
            ],
            [
              -113.844346,
              44.523406
            ],
            [
              -113.899399,
              44.558391
            ],
            [
              -113.920281,
              44.565754
            ],
            [
              -113.93161,
              44.581768
            ],
            [
              -113.974499,
              44.619447
            ],
            [
              -114.003915,
              44.634701
            ],
            [
              -114.035425,
              44.671299
            ],
            [
              -114.051588,
              44.697323
            ],
            [
              -114.05088,
              44.711489
            ],
            [
              -114.084861,
              44.755947
            ],
            [
              -114.124169,
              44.741484
            ],
            [
              -114.135573,
              44.730344
            ],
            [
              -114.15483,
              44.745283
            ],
            [
              -114.216782,
              44.746879
            ],
            [
              -114.216084,
              44.762293
            ],
            [
              -114.201544,
              44.778316
            ],
            [
              -114.204282,
              44.800033
            ],
            [
              -114.196129,
              44.803224
            ],
            [
              -114.191722,
              44.837933
            ],
            [
              -114.197218,
              44.862823
            ],
            [
              -114.21165,
              44.863102
            ],
            [
              -114.221534,
              44.879077
            ],
            [
              -114.224931,
              44.864334
            ],
            [
              -114.262283,
              44.849805
            ],
            [
              -114.278152,
              44.823123
            ],
            [
              -114.299989,
              44.819599
            ],
            [
              -114.315438,
              44.801896
            ],
            [
              -114.344275,
              44.793781
            ],
            [
              -114.353947,
              44.774545
            ],
            [
              -114.35293,
              44.758713
            ],
            [
              -114.379821,
              44.743485
            ],
            [
              -114.404154,
              44.739547
            ],
            [
              -114.406633,
              44.719466
            ],
            [
              -114.372843,
              44.709717
            ],
            [
              -114.386147,
              44.672967
            ],
            [
              -114.408739,
              44.656378
            ],
            [
              -114.435269,
              44.651293
            ],
            [
              -114.448386,
              44.631488
            ],
            [
              -114.45184,
              44.611741
            ],
            [
              -114.477399,
              44.594175
            ],
            [
              -114.495391,
              44.604023
            ],
            [
              -114.516448,
              44.604051
            ],
            [
              -114.540393,
              44.582492
            ],
            [
              -114.566975,
              44.575732
            ],
            [
              -114.604067,
              44.597576
            ],
            [
              -114.595895,
              44.619088
            ],
            [
              -114.607396,
              44.636163
            ],
            [
              -114.62889,
              44.652697
            ],
            [
              -114.662687,
              44.662109
            ],
            [
              -114.710871,
              44.650822
            ],
            [
              -114.741685,
              44.653143
            ],
            [
              -114.735525,
              44.661948
            ],
            [
              -114.756639,
              44.694296
            ],
            [
              -114.781844,
              44.713953
            ],
            [
              -114.793044,
              44.712982
            ],
            [
              -114.820083,
              44.747377
            ],
            [
              -114.797714,
              44.79433
            ],
            [
              -114.812407,
              44.808346
            ],
            [
              -114.812786,
              44.807445
            ],
            [
              -114.818717,
              44.805809
            ],
            [
              -114.823817,
              44.803896
            ],
            [
              -114.827777,
              44.801977
            ],
            [
              -114.835441,
              44.800577
            ],
            [
              -114.838456,
              44.797154
            ],
            [
              -114.842331,
              44.789574
            ],
            [
              -114.8492,
              44.787701
            ],
            [
              -114.850832,
              44.784927
            ],
            [
              -114.855711,
              44.783494
            ],
            [
              -114.856444,
              44.78473
            ],
            [
              -114.864359,
              44.781421
            ],
            [
              -114.871086,
              44.77968
            ],
            [
              -114.879401,
              44.775122
            ],
            [
              -114.888139,
              44.769801
            ],
            [
              -114.895576,
              44.762342
            ],
            [
              -114.901292,
              44.758338
            ],
            [
              -114.907571,
              44.75669
            ],
            [
              -114.910598,
              44.757159
            ],
            [
              -114.915031,
              44.753091
            ],
            [
              -114.916526,
              44.748997
            ],
            [
              -114.918049,
              44.743103
            ],
            [
              -114.922281,
              44.738703
            ],
            [
              -114.925251,
              44.737124
            ],
            [
              -114.931683,
              44.732854
            ],
            [
              -114.940943,
              44.731089
            ],
            [
              -114.944498,
              44.727703
            ],
            [
              -114.947408,
              44.722898
            ],
            [
              -114.955719,
              44.720919
            ],
            [
              -114.963182,
              44.723018
            ],
            [
              -114.97009,
              44.724865
            ],
            [
              -114.977445,
              44.72957
            ],
            [
              -114.984085,
              44.729982
            ],
            [
              -114.988798,
              44.728412
            ],
            [
              -114.993095,
              44.728579
            ],
            [
              -114.996394,
              44.728714
            ],
            [
              -115.000381,
              44.725223
            ],
            [
              -114.998641,
              44.72213
            ],
            [
              -115.004798,
              44.716776
            ],
            [
              -115.012269,
              44.716005
            ],
            [
              -115.01467,
              44.72018
            ],
            [
              -115.016107,
              44.728205
            ],
            [
              -115.016849,
              44.733274
            ],
            [
              -115.015118,
              44.738672
            ],
            [
              -115.017011,
              44.742189
            ],
            [
              -115.023698,
              44.740279
            ],
            [
              -115.029217,
              44.736317
            ],
            [
              -115.034215,
              44.741204
            ],
            [
              -115.035466,
              44.746785
            ],
            [
              -115.045835,
              44.752803
            ],
            [
              -115.056738,
              44.756154
            ],
            [
              -115.062569,
              44.756471
            ],
            [
              -115.068875,
              44.759849
            ],
            [
              -115.075576,
              44.767388
            ],
            [
              -115.078916,
              44.769744
            ],
            [
              -115.090521,
              44.7694
            ],
            [
              -115.096631,
              44.764203
            ],
            [
              -115.111803,
              44.757742
            ],
            [
              -115.122278,
              44.751616
            ],
            [
              -115.134605,
              44.744409
            ],
            [
              -115.145568,
              44.73247
            ],
            [
              -115.150827,
              44.729767
            ],
            [
              -115.148971,
              44.722871
            ],
            [
              -115.143963,
              44.719093
            ],
            [
              -115.139755,
              44.713124
            ],
            [
              -115.149363,
              44.699262
            ],
            [
              -115.147687,
              44.69513
            ],
            [
              -115.151255,
              44.68654
            ],
            [
              -115.153109,
              44.67996
            ],
            [
              -115.155033,
              44.673857
            ],
            [
              -115.159827,
              44.668125
            ],
            [
              -115.163166,
              44.66271
            ],
            [
              -115.165461,
              44.654367
            ],
            [
              -115.167852,
              44.648162
            ],
            [
              -115.172701,
              44.647195
            ],
            [
              -115.175991,
              44.645858
            ],
            [
              -115.180921,
              44.643398
            ],
            [
              -115.181438,
              44.639762
            ],
            [
              -115.186401,
              44.637162
            ],
            [
              -115.19575,
              44.632018
            ],
            [
              -115.19631,
              44.628235
            ],
            [
              -115.201608,
              44.624651
            ],
            [
              -115.204639,
              44.626651
            ],
            [
              -115.211561,
              44.62672
            ],
            [
              -115.218503,
              44.62911
            ],
            [
              -115.221187,
              44.630451
            ],
            [
              -115.226066,
              44.627353
            ],
            [
              -115.224558,
              44.623828
            ],
            [
              -115.223579,
              44.618762
            ],
            [
              -115.230414,
              44.618737
            ],
            [
              -115.236609,
              44.621488
            ],
            [
              -115.24949,
              44.618142
            ],
            [
              -115.256821,
              44.615487
            ],
            [
              -115.258989,
              44.609275
            ],
            [
              -115.259904,
              44.607063
            ],
            [
              -115.263206,
              44.6059
            ],
            [
              -115.267285,
              44.607233
            ],
            [
              -115.272999,
              44.61056
            ],
            [
              -115.281647,
              44.608556
            ],
            [
              -115.283419,
              44.605408
            ],
            [
              -115.287931,
              44.600176
            ],
            [
              -115.29222,
              44.594108
            ],
            [
              -115.296055,
              44.586842
            ],
            [
              -115.303742,
              44.581043
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "16",
      "COUNTYFP": "051",
      "COUNTYNS": "00399751",
      "AFFGEOID": "0500000US16051",
      "GEOID": "16051",
      "NAME": "Jefferson",
      "LSAD": "06",
      "ALAND": 2832617277,
      "AWATER": 31168379,
      "County_state": "Jefferson,16"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -112.696915,
              43.79789
            ],
            [
              -112.697122,
              43.62314
            ],
            [
              -112.639238,
              43.626574
            ],
            [
              -112.519861,
              43.626623
            ],
            [
              -111.983643,
              43.626346
            ],
            [
              -111.626043,
              43.626756
            ],
            [
              -111.625938,
              43.655227
            ],
            [
              -111.717356,
              43.655326
            ],
            [
              -111.743734,
              43.668165
            ],
            [
              -111.760761,
              43.667346
            ],
            [
              -111.778098,
              43.675995
            ],
            [
              -111.80385,
              43.701974
            ],
            [
              -111.820299,
              43.704238
            ],
            [
              -111.83098,
              43.71669
            ],
            [
              -111.858078,
              43.718603
            ],
            [
              -111.873152,
              43.737008
            ],
            [
              -111.902054,
              43.74717
            ],
            [
              -111.908234,
              43.760316
            ],
            [
              -111.919279,
              43.756086
            ],
            [
              -111.929901,
              43.767097
            ],
            [
              -111.946802,
              43.750715
            ],
            [
              -111.967005,
              43.748165
            ],
            [
              -111.983527,
              43.753806
            ],
            [
              -111.977469,
              43.797235
            ],
            [
              -111.976849,
              43.927385
            ],
            [
              -111.977016,
              43.970909
            ],
            [
              -112.15637,
              43.971247
            ],
            [
              -112.155935,
              44.05778
            ],
            [
              -112.694744,
              44.058464
            ],
            [
              -112.695242,
              43.971941
            ],
            [
              -112.696915,
              43.79789
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "16",
      "COUNTYFP": "085",
      "COUNTYNS": "00399756",
      "AFFGEOID": "0500000US16085",
      "GEOID": "16085",
      "NAME": "Valley",
      "LSAD": "06",
      "ALAND": 9492623106,
      "AWATER": 177058013,
      "County_state": "Valley,16"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -116.21291,
              44.324373
            ],
            [
              -116.213871,
              44.151387
            ],
            [
              -116.193229,
              44.151398
            ],
            [
              -116.155972,
              44.151352
            ],
            [
              -116.132681,
              44.147734
            ],
            [
              -116.119291,
              44.147806
            ],
            [
              -116.114025,
              44.151465
            ],
            [
              -116.116802,
              44.15456
            ],
            [
              -116.116919,
              44.159884
            ],
            [
              -116.119423,
              44.166477
            ],
            [
              -116.120005,
              44.171468
            ],
            [
              -116.117001,
              44.176885
            ],
            [
              -116.116061,
              44.183002
            ],
            [
              -116.114477,
              44.190281
            ],
            [
              -116.113158,
              44.196862
            ],
            [
              -116.109196,
              44.203215
            ],
            [
              -116.107631,
              44.208258
            ],
            [
              -116.106226,
              44.211693
            ],
            [
              -116.106631,
              44.218307
            ],
            [
              -116.105855,
              44.223602
            ],
            [
              -116.103107,
              44.230765
            ],
            [
              -116.097412,
              44.236401
            ],
            [
              -116.05239,
              44.236453
            ],
            [
              -116.01445,
              44.235637
            ],
            [
              -115.988497,
              44.23564
            ],
            [
              -115.93841,
              44.235549
            ],
            [
              -115.899577,
              44.23576
            ],
            [
              -115.861571,
              44.235662
            ],
            [
              -115.818382,
              44.235489
            ],
            [
              -115.759462,
              44.235469
            ],
            [
              -115.689926,
              44.235617
            ],
            [
              -115.650172,
              44.235483
            ],
            [
              -115.625196,
              44.235524
            ],
            [
              -115.56514,
              44.235565
            ],
            [
              -115.518354,
              44.238563
            ],
            [
              -115.510741,
              44.242809
            ],
            [
              -115.504438,
              44.238543
            ],
            [
              -115.50136,
              44.234216
            ],
            [
              -115.496754,
              44.233832
            ],
            [
              -115.4864,
              44.239048
            ],
            [
              -115.474809,
              44.238833
            ],
            [
              -115.465827,
              44.242106
            ],
            [
              -115.450893,
              44.246651
            ],
            [
              -115.442924,
              44.253016
            ],
            [
              -115.43274,
              44.260205
            ],
            [
              -115.422494,
              44.265955
            ],
            [
              -115.411105,
              44.264739
            ],
            [
              -115.406775,
              44.261811
            ],
            [
              -115.401851,
              44.261631
            ],
            [
              -115.401511,
              44.263166
            ],
            [
              -115.398516,
              44.265446
            ],
            [
              -115.393341,
              44.267406
            ],
            [
              -115.387869,
              44.279693
            ],
            [
              -115.387835,
              44.28691
            ],
            [
              -115.376745,
              44.294043
            ],
            [
              -115.364649,
              44.299251
            ],
            [
              -115.35237,
              44.305252
            ],
            [
              -115.349451,
              44.314731
            ],
            [
              -115.350816,
              44.3264
            ],
            [
              -115.350614,
              44.335401
            ],
            [
              -115.354484,
              44.341172
            ],
            [
              -115.352358,
              44.347785
            ],
            [
              -115.344799,
              44.347083
            ],
            [
              -115.334073,
              44.349327
            ],
            [
              -115.318145,
              44.347933
            ],
            [
              -115.301929,
              44.342249
            ],
            [
              -115.294614,
              44.339445
            ],
            [
              -115.294007,
              44.342791
            ],
            [
              -115.289089,
              44.344212
            ],
            [
              -115.284148,
              44.349963
            ],
            [
              -115.285133,
              44.354185
            ],
            [
              -115.281225,
              44.357077
            ],
            [
              -115.271956,
              44.361556
            ],
            [
              -115.263257,
              44.36844
            ],
            [
              -115.256596,
              44.379034
            ],
            [
              -115.248642,
              44.386441
            ],
            [
              -115.240564,
              44.405517
            ],
            [
              -115.233253,
              44.412603
            ],
            [
              -115.22822,
              44.42583
            ],
            [
              -115.22898,
              44.441839
            ],
            [
              -115.229463,
              44.452426
            ],
            [
              -115.23106,
              44.455095
            ],
            [
              -115.229583,
              44.459547
            ],
            [
              -115.225121,
              44.460405
            ],
            [
              -115.227135,
              44.465588
            ],
            [
              -115.230056,
              44.466088
            ],
            [
              -115.234503,
              44.467965
            ],
            [
              -115.233345,
              44.470944
            ],
            [
              -115.226432,
              44.475418
            ],
            [
              -115.227276,
              44.48239
            ],
            [
              -115.228093,
              44.487223
            ],
            [
              -115.231908,
              44.492472
            ],
            [
              -115.236931,
              44.49703
            ],
            [
              -115.238583,
              44.500816
            ],
            [
              -115.238571,
              44.507009
            ],
            [
              -115.240666,
              44.51238
            ],
            [
              -115.245968,
              44.515534
            ],
            [
              -115.246882,
              44.519167
            ],
            [
              -115.252558,
              44.518904
            ],
            [
              -115.255934,
              44.517086
            ],
            [
              -115.263515,
              44.51803
            ],
            [
              -115.267639,
              44.520042
            ],
            [
              -115.273489,
              44.518774
            ],
            [
              -115.277371,
              44.518462
            ],
            [
              -115.281453,
              44.520091
            ],
            [
              -115.282096,
              44.523465
            ],
            [
              -115.285278,
              44.52927
            ],
            [
              -115.290824,
              44.53132
            ],
            [
              -115.292116,
              44.535804
            ],
            [
              -115.290431,
              44.541377
            ],
            [
              -115.294297,
              44.54544
            ],
            [
              -115.294932,
              44.549414
            ],
            [
              -115.297686,
              44.550986
            ],
            [
              -115.298636,
              44.556093
            ],
            [
              -115.300966,
              44.56329
            ],
            [
              -115.300803,
              44.568383
            ],
            [
              -115.304044,
              44.575056
            ],
            [
              -115.303742,
              44.581043
            ],
            [
              -115.296055,
              44.586842
            ],
            [
              -115.29222,
              44.594108
            ],
            [
              -115.287931,
              44.600176
            ],
            [
              -115.283419,
              44.605408
            ],
            [
              -115.281647,
              44.608556
            ],
            [
              -115.272999,
              44.61056
            ],
            [
              -115.267285,
              44.607233
            ],
            [
              -115.263206,
              44.6059
            ],
            [
              -115.259904,
              44.607063
            ],
            [
              -115.258989,
              44.609275
            ],
            [
              -115.256821,
              44.615487
            ],
            [
              -115.24949,
              44.618142
            ],
            [
              -115.236609,
              44.621488
            ],
            [
              -115.230414,
              44.618737
            ],
            [
              -115.223579,
              44.618762
            ],
            [
              -115.224558,
              44.623828
            ],
            [
              -115.226066,
              44.627353
            ],
            [
              -115.221187,
              44.630451
            ],
            [
              -115.218503,
              44.62911
            ],
            [
              -115.211561,
              44.62672
            ],
            [
              -115.204639,
              44.626651
            ],
            [
              -115.201608,
              44.624651
            ],
            [
              -115.19631,
              44.628235
            ],
            [
              -115.19575,
              44.632018
            ],
            [
              -115.186401,
              44.637162
            ],
            [
              -115.181438,
              44.639762
            ],
            [
              -115.180921,
              44.643398
            ],
            [
              -115.175991,
              44.645858
            ],
            [
              -115.172701,
              44.647195
            ],
            [
              -115.167852,
              44.648162
            ],
            [
              -115.165461,
              44.654367
            ],
            [
              -115.163166,
              44.66271
            ],
            [
              -115.159827,
              44.668125
            ],
            [
              -115.155033,
              44.673857
            ],
            [
              -115.153109,
              44.67996
            ],
            [
              -115.151255,
              44.68654
            ],
            [
              -115.147687,
              44.69513
            ],
            [
              -115.149363,
              44.699262
            ],
            [
              -115.139755,
              44.713124
            ],
            [
              -115.143963,
              44.719093
            ],
            [
              -115.148971,
              44.722871
            ],
            [
              -115.150827,
              44.729767
            ],
            [
              -115.145568,
              44.73247
            ],
            [
              -115.134605,
              44.744409
            ],
            [
              -115.122278,
              44.751616
            ],
            [
              -115.111803,
              44.757742
            ],
            [
              -115.096631,
              44.764203
            ],
            [
              -115.090521,
              44.7694
            ],
            [
              -115.078916,
              44.769744
            ],
            [
              -115.075576,
              44.767388
            ],
            [
              -115.068875,
              44.759849
            ],
            [
              -115.062569,
              44.756471
            ],
            [
              -115.056738,
              44.756154
            ],
            [
              -115.045835,
              44.752803
            ],
            [
              -115.035466,
              44.746785
            ],
            [
              -115.034215,
              44.741204
            ],
            [
              -115.029217,
              44.736317
            ],
            [
              -115.023698,
              44.740279
            ],
            [
              -115.017011,
              44.742189
            ],
            [
              -115.015118,
              44.738672
            ],
            [
              -115.016849,
              44.733274
            ],
            [
              -115.016107,
              44.728205
            ],
            [
              -115.01467,
              44.72018
            ],
            [
              -115.012269,
              44.716005
            ],
            [
              -115.004798,
              44.716776
            ],
            [
              -114.998641,
              44.72213
            ],
            [
              -115.000381,
              44.725223
            ],
            [
              -114.996394,
              44.728714
            ],
            [
              -114.993095,
              44.728579
            ],
            [
              -114.988798,
              44.728412
            ],
            [
              -114.984085,
              44.729982
            ],
            [
              -114.977445,
              44.72957
            ],
            [
              -114.97009,
              44.724865
            ],
            [
              -114.963182,
              44.723018
            ],
            [
              -114.955719,
              44.720919
            ],
            [
              -114.947408,
              44.722898
            ],
            [
              -114.944498,
              44.727703
            ],
            [
              -114.940943,
              44.731089
            ],
            [
              -114.931683,
              44.732854
            ],
            [
              -114.925251,
              44.737124
            ],
            [
              -114.922281,
              44.738703
            ],
            [
              -114.918049,
              44.743103
            ],
            [
              -114.916526,
              44.748997
            ],
            [
              -114.915031,
              44.753091
            ],
            [
              -114.910598,
              44.757159
            ],
            [
              -114.907571,
              44.75669
            ],
            [
              -114.901292,
              44.758338
            ],
            [
              -114.895576,
              44.762342
            ],
            [
              -114.888139,
              44.769801
            ],
            [
              -114.879401,
              44.775122
            ],
            [
              -114.871086,
              44.77968
            ],
            [
              -114.864359,
              44.781421
            ],
            [
              -114.856444,
              44.78473
            ],
            [
              -114.855711,
              44.783494
            ],
            [
              -114.850832,
              44.784927
            ],
            [
              -114.8492,
              44.787701
            ],
            [
              -114.842331,
              44.789574
            ],
            [
              -114.838456,
              44.797154
            ],
            [
              -114.835441,
              44.800577
            ],
            [
              -114.827777,
              44.801977
            ],
            [
              -114.823817,
              44.803896
            ],
            [
              -114.818717,
              44.805809
            ],
            [
              -114.812786,
              44.807445
            ],
            [
              -114.812407,
              44.808346
            ],
            [
              -114.794459,
              44.834324
            ],
            [
              -114.767625,
              44.841677
            ],
            [
              -114.761659,
              44.858618
            ],
            [
              -114.769801,
              44.86515
            ],
            [
              -114.762282,
              44.875575
            ],
            [
              -114.734551,
              44.878746
            ],
            [
              -114.723294,
              44.891177
            ],
            [
              -114.735574,
              44.924299
            ],
            [
              -114.723272,
              44.934387
            ],
            [
              -114.734801,
              44.948962
            ],
            [
              -114.735476,
              44.972309
            ],
            [
              -114.723115,
              45.005278
            ],
            [
              -114.727601,
              45.025966
            ],
            [
              -114.722527,
              45.041704
            ],
            [
              -114.727236,
              45.079256
            ],
            [
              -114.720725,
              45.088929
            ],
            [
              -114.733191,
              45.09449
            ],
            [
              -114.721482,
              45.121023
            ],
            [
              -114.73227,
              45.151155
            ],
            [
              -114.723553,
              45.160055
            ],
            [
              -114.722057,
              45.180056
            ],
            [
              -114.694001,
              45.197182
            ],
            [
              -115.000948,
              45.196877
            ],
            [
              -115.975787,
              45.195282
            ],
            [
              -115.96507,
              45.203516
            ],
            [
              -115.976659,
              45.219223
            ],
            [
              -116.019329,
              45.18282
            ],
            [
              -116.045452,
              45.177417
            ],
            [
              -116.048028,
              45.166298
            ],
            [
              -116.063852,
              45.165282
            ],
            [
              -116.081496,
              45.152858
            ],
            [
              -116.121662,
              45.158549
            ],
            [
              -116.136481,
              45.137096
            ],
            [
              -116.135265,
              45.124069
            ],
            [
              -116.144386,
              45.107651
            ],
            [
              -116.143828,
              45.105323
            ],
            [
              -116.140908,
              45.096084
            ],
            [
              -116.139528,
              45.087212
            ],
            [
              -116.14132,
              45.082081
            ],
            [
              -116.130765,
              45.076426
            ],
            [
              -116.126318,
              45.075249
            ],
            [
              -116.108029,
              45.072893
            ],
            [
              -116.093964,
              45.067168
            ],
            [
              -116.091281,
              45.061824
            ],
            [
              -116.094542,
              45.057096
            ],
            [
              -116.099971,
              45.053072
            ],
            [
              -116.104448,
              45.049663
            ],
            [
              -116.101705,
              45.042084
            ],
            [
              -116.104544,
              45.033599
            ],
            [
              -116.110348,
              45.030683
            ],
            [
              -116.114056,
              45.025462
            ],
            [
              -116.122723,
              45.021101
            ],
            [
              -116.127823,
              45.015504
            ],
            [
              -116.133495,
              45.008982
            ],
            [
              -116.134981,
              45.000791
            ],
            [
              -116.13345,
              44.998628
            ],
            [
              -116.128457,
              44.994695
            ],
            [
              -116.127658,
              44.987199
            ],
            [
              -116.124082,
              44.98574
            ],
            [
              -116.119447,
              44.982297
            ],
            [
              -116.118739,
              44.98048
            ],
            [
              -116.114307,
              44.97652
            ],
            [
              -116.114381,
              44.97077
            ],
            [
              -116.115359,
              44.966985
            ],
            [
              -116.119658,
              44.959118
            ],
            [
              -116.122243,
              44.952309
            ],
            [
              -116.127663,
              44.945466
            ],
            [
              -116.135069,
              44.94069
            ],
            [
              -116.143134,
              44.941028
            ],
            [
              -116.149026,
              44.943269
            ],
            [
              -116.154783,
              44.94174
            ],
            [
              -116.154562,
              44.93967
            ],
            [
              -116.155163,
              44.936456
            ],
            [
              -116.156213,
              44.931341
            ],
            [
              -116.155444,
              44.927905
            ],
            [
              -116.158138,
              44.922039
            ],
            [
              -116.159059,
              44.917617
            ],
            [
              -116.15936,
              44.911643
            ],
            [
              -116.160952,
              44.904842
            ],
            [
              -116.160959,
              44.902936
            ],
            [
              -116.164523,
              44.901402
            ],
            [
              -116.168552,
              44.900895
            ],
            [
              -116.173759,
              44.896531
            ],
            [
              -116.174057,
              44.889528
            ],
            [
              -116.174265,
              44.888216
            ],
            [
              -116.177368,
              44.888039
            ],
            [
              -116.179937,
              44.88868
            ],
            [
              -116.183984,
              44.887974
            ],
            [
              -116.186424,
              44.884698
            ],
            [
              -116.188607,
              44.878608
            ],
            [
              -116.189172,
              44.872622
            ],
            [
              -116.190589,
              44.867224
            ],
            [
              -116.1943,
              44.864139
            ],
            [
              -116.197047,
              44.86236
            ],
            [
              -116.197081,
              44.858086
            ],
            [
              -116.196039,
              44.854332
            ],
            [
              -116.195921,
              44.849861
            ],
            [
              -116.196986,
              44.848354
            ],
            [
              -116.197971,
              44.843487
            ],
            [
              -116.198016,
              44.838497
            ],
            [
              -116.198714,
              44.832016
            ],
            [
              -116.198832,
              44.824267
            ],
            [
              -116.2,
              44.818918
            ],
            [
              -116.200651,
              44.813597
            ],
            [
              -116.200898,
              44.810503
            ],
            [
              -116.201853,
              44.807861
            ],
            [
              -116.203984,
              44.803005
            ],
            [
              -116.203374,
              44.799921
            ],
            [
              -116.202945,
              44.797852
            ],
            [
              -116.202884,
              44.795329
            ],
            [
              -116.204388,
              44.792132
            ],
            [
              -116.205231,
              44.789713
            ],
            [
              -116.205994,
              44.786809
            ],
            [
              -116.20848,
              44.782794
            ],
            [
              -116.209944,
              44.779177
            ],
            [
              -116.20833,
              44.773424
            ],
            [
              -116.206486,
              44.767698
            ],
            [
              -116.20083,
              44.766043
            ],
            [
              -116.199233,
              44.763438
            ],
            [
              -116.192177,
              44.753583
            ],
            [
              -116.185179,
              44.748532
            ],
            [
              -116.178736,
              44.739702
            ],
            [
              -116.179018,
              44.729287
            ],
            [
              -116.181991,
              44.727889
            ],
            [
              -116.18183,
              44.724178
            ],
            [
              -116.179566,
              44.722778
            ],
            [
              -116.178518,
              44.721443
            ],
            [
              -116.177358,
              44.720173
            ],
            [
              -116.17466,
              44.718034
            ],
            [
              -116.171914,
              44.71582
            ],
            [
              -116.17057,
              44.714463
            ],
            [
              -116.16938,
              44.712899
            ],
            [
              -116.16891,
              44.71196
            ],
            [
              -116.167699,
              44.711374
            ],
            [
              -116.166126,
              44.71133
            ],
            [
              -116.160989,
              44.708595
            ],
            [
              -116.159349,
              44.700716
            ],
            [
              -116.160405,
              44.693205
            ],
            [
              -116.163292,
              44.690492
            ],
            [
              -116.163428,
              44.6883
            ],
            [
              -116.163137,
              44.684942
            ],
            [
              -116.163543,
              44.678442
            ],
            [
              -116.167872,
              44.665271
            ],
            [
              -116.174174,
              44.654358
            ],
            [
              -116.176269,
              44.645
            ],
            [
              -116.173543,
              44.636122
            ],
            [
              -116.170209,
              44.625303
            ],
            [
              -116.170431,
              44.619231
            ],
            [
              -116.176495,
              44.611344
            ],
            [
              -116.176618,
              44.602387
            ],
            [
              -116.179548,
              44.594715
            ],
            [
              -116.182183,
              44.587809
            ],
            [
              -116.183366,
              44.586566
            ],
            [
              -116.183857,
              44.5845
            ],
            [
              -116.181192,
              44.582348
            ],
            [
              -116.176022,
              44.577613
            ],
            [
              -116.17414,
              44.56924
            ],
            [
              -116.17587,
              44.561814
            ],
            [
              -116.172315,
              44.553466
            ],
            [
              -116.168689,
              44.545147
            ],
            [
              -116.16432,
              44.535155
            ],
            [
              -116.162026,
              44.525347
            ],
            [
              -116.16088,
              44.515704
            ],
            [
              -116.158579,
              44.508026
            ],
            [
              -116.157739,
              44.49971
            ],
            [
              -116.157022,
              44.498928
            ],
            [
              -116.152295,
              44.324519
            ],
            [
              -116.21291,
              44.324373
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "16",
      "COUNTYFP": "045",
      "COUNTYNS": "00395442",
      "AFFGEOID": "0500000US16045",
      "GEOID": "16045",
      "NAME": "Gem",
      "LSAD": "06",
      "ALAND": 1449792319,
      "AWATER": 12495804,
      "County_state": "Gem,16"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -116.712501,
              43.981326
            ],
            [
              -116.712672,
              43.807161
            ],
            [
              -116.592575,
              43.807132
            ],
            [
              -116.512502,
              43.807161
            ],
            [
              -116.282019,
              43.807365
            ],
            [
              -116.273069,
              44.151227
            ],
            [
              -116.213871,
              44.151387
            ],
            [
              -116.21291,
              44.324373
            ],
            [
              -116.152295,
              44.324519
            ],
            [
              -116.157022,
              44.498928
            ],
            [
              -116.193278,
              44.493666
            ],
            [
              -116.213314,
              44.510813
            ],
            [
              -116.233874,
              44.481262
            ],
            [
              -116.236136,
              44.464713
            ],
            [
              -116.255973,
              44.466296
            ],
            [
              -116.299876,
              44.444576
            ],
            [
              -116.308294,
              44.423441
            ],
            [
              -116.319338,
              44.421183
            ],
            [
              -116.322316,
              44.387028
            ],
            [
              -116.333911,
              44.376
            ],
            [
              -116.32864,
              44.357712
            ],
            [
              -116.347394,
              44.322103
            ],
            [
              -116.341666,
              44.310691
            ],
            [
              -116.351187,
              44.287764
            ],
            [
              -116.341794,
              44.276999
            ],
            [
              -116.351477,
              44.237823
            ],
            [
              -116.343323,
              44.217804
            ],
            [
              -116.357027,
              44.207233
            ],
            [
              -116.354106,
              44.167662
            ],
            [
              -116.358492,
              44.151158
            ],
            [
              -116.452068,
              44.151465
            ],
            [
              -116.452551,
              44.065414
            ],
            [
              -116.532117,
              44.066024
            ],
            [
              -116.53217,
              44.008843
            ],
            [
              -116.572142,
              44.009214
            ],
            [
              -116.572224,
              43.980485
            ],
            [
              -116.712501,
              43.981326
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "16",
      "COUNTYFP": "059",
      "COUNTYNS": "00399754",
      "AFFGEOID": "0500000US16059",
      "GEOID": "16059",
      "NAME": "Lemhi",
      "LSAD": "06",
      "ALAND": 11820055094,
      "AWATER": 14109855,
      "County_state": "Lemhi,16"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -114.797714,
              44.79433
            ],
            [
              -114.820083,
              44.747377
            ],
            [
              -114.793044,
              44.712982
            ],
            [
              -114.781844,
              44.713953
            ],
            [
              -114.756639,
              44.694296
            ],
            [
              -114.735525,
              44.661948
            ],
            [
              -114.741685,
              44.653143
            ],
            [
              -114.710871,
              44.650822
            ],
            [
              -114.662687,
              44.662109
            ],
            [
              -114.62889,
              44.652697
            ],
            [
              -114.607396,
              44.636163
            ],
            [
              -114.595895,
              44.619088
            ],
            [
              -114.604067,
              44.597576
            ],
            [
              -114.566975,
              44.575732
            ],
            [
              -114.540393,
              44.582492
            ],
            [
              -114.516448,
              44.604051
            ],
            [
              -114.495391,
              44.604023
            ],
            [
              -114.477399,
              44.594175
            ],
            [
              -114.45184,
              44.611741
            ],
            [
              -114.448386,
              44.631488
            ],
            [
              -114.435269,
              44.651293
            ],
            [
              -114.408739,
              44.656378
            ],
            [
              -114.386147,
              44.672967
            ],
            [
              -114.372843,
              44.709717
            ],
            [
              -114.406633,
              44.719466
            ],
            [
              -114.404154,
              44.739547
            ],
            [
              -114.379821,
              44.743485
            ],
            [
              -114.35293,
              44.758713
            ],
            [
              -114.353947,
              44.774545
            ],
            [
              -114.344275,
              44.793781
            ],
            [
              -114.315438,
              44.801896
            ],
            [
              -114.299989,
              44.819599
            ],
            [
              -114.278152,
              44.823123
            ],
            [
              -114.262283,
              44.849805
            ],
            [
              -114.224931,
              44.864334
            ],
            [
              -114.221534,
              44.879077
            ],
            [
              -114.21165,
              44.863102
            ],
            [
              -114.197218,
              44.862823
            ],
            [
              -114.191722,
              44.837933
            ],
            [
              -114.196129,
              44.803224
            ],
            [
              -114.204282,
              44.800033
            ],
            [
              -114.201544,
              44.778316
            ],
            [
              -114.216084,
              44.762293
            ],
            [
              -114.216782,
              44.746879
            ],
            [
              -114.15483,
              44.745283
            ],
            [
              -114.135573,
              44.730344
            ],
            [
              -114.124169,
              44.741484
            ],
            [
              -114.084861,
              44.755947
            ],
            [
              -114.05088,
              44.711489
            ],
            [
              -114.051588,
              44.697323
            ],
            [
              -114.035425,
              44.671299
            ],
            [
              -114.003915,
              44.634701
            ],
            [
              -113.974499,
              44.619447
            ],
            [
              -113.93161,
              44.581768
            ],
            [
              -113.920281,
              44.565754
            ],
            [
              -113.899399,
              44.558391
            ],
            [
              -113.844346,
              44.523406
            ],
            [
              -113.818,
              44.495127
            ],
            [
              -113.789074,
              44.494185
            ],
            [
              -113.68953,
              44.467912
            ],
            [
              -113.659449,
              44.450627
            ],
            [
              -113.589263,
              44.44358
            ],
            [
              -113.554416,
              44.426527
            ],
            [
              -113.502065,
              44.422098
            ],
            [
              -113.477444,
              44.448035
            ],
            [
              -113.447361,
              44.451579
            ],
            [
              -113.43585,
              44.426762
            ],
            [
              -113.425443,
              44.422896
            ],
            [
              -113.404821,
              44.382388
            ],
            [
              -113.361159,
              44.345502
            ],
            [
              -113.356414,
              44.329026
            ],
            [
              -113.339445,
              44.316077
            ],
            [
              -113.336983,
              44.293945
            ],
            [
              -113.326005,
              44.273521
            ],
            [
              -113.332822,
              44.247479
            ],
            [
              -113.320609,
              44.233164
            ],
            [
              -112.995091,
              44.234126
            ],
            [
              -112.940161,
              44.234181
            ],
            [
              -112.813792,
              44.230281
            ],
            [
              -112.814350040863,
              44.3770070471164
            ],
            [
              -112.820489,
              44.370946
            ],
            [
              -112.844859,
              44.358221
            ],
            [
              -112.855395,
              44.359975
            ],
            [
              -112.881769,
              44.380315
            ],
            [
              -112.886041,
              44.395874
            ],
            [
              -112.915602,
              44.402699
            ],
            [
              -112.951146,
              44.416699
            ],
            [
              -112.981682,
              44.434279
            ],
            [
              -113.003544,
              44.450814
            ],
            [
              -113.006846,
              44.471715
            ],
            [
              -113.01864,
              44.481673
            ],
            [
              -113.026148,
              44.497016
            ],
            [
              -113.017047,
              44.50398
            ],
            [
              -113.006828,
              44.518439
            ],
            [
              -113.007194,
              44.52596
            ],
            [
              -113.04235,
              44.54215
            ],
            [
              -113.046102,
              44.550972
            ],
            [
              -113.038522,
              44.558551
            ],
            [
              -113.037512,
              44.564362
            ],
            [
              -113.061071,
              44.577329
            ],
            [
              -113.070356,
              44.590132
            ],
            [
              -113.082483,
              44.59569
            ],
            [
              -113.083819,
              44.60222
            ],
            [
              -113.07376,
              44.613928
            ],
            [
              -113.065593,
              44.617391
            ],
            [
              -113.05677,
              44.618657
            ],
            [
              -113.053529,
              44.621187
            ],
            [
              -113.049349,
              44.62938
            ],
            [
              -113.051504,
              44.63695
            ],
            [
              -113.065589,
              44.649371
            ],
            [
              -113.068306,
              44.656374
            ],
            [
              -113.07042,
              44.667844
            ],
            [
              -113.067756,
              44.672807
            ],
            [
              -113.06776,
              44.679474
            ],
            [
              -113.081906,
              44.691392
            ],
            [
              -113.098064,
              44.697477
            ],
            [
              -113.101154,
              44.708578
            ],
            [
              -113.102138,
              44.729027
            ],
            [
              -113.116874,
              44.738104
            ],
            [
              -113.134824,
              44.752763
            ],
            [
              -113.137704,
              44.760109
            ],
            [
              -113.131387,
              44.764738
            ],
            [
              -113.131453,
              44.772837
            ],
            [
              -113.140618,
              44.776698
            ],
            [
              -113.158206,
              44.780847
            ],
            [
              -113.163806,
              44.778921
            ],
            [
              -113.179366,
              44.787142
            ],
            [
              -113.183395,
              44.793565
            ],
            [
              -113.19436,
              44.802151
            ],
            [
              -113.209624,
              44.80907
            ],
            [
              -113.238729,
              44.814144
            ],
            [
              -113.247166,
              44.82295
            ],
            [
              -113.278382,
              44.812706
            ],
            [
              -113.29683,
              44.803358
            ],
            [
              -113.301508,
              44.798985
            ],
            [
              -113.341704,
              44.784853
            ],
            [
              -113.354034,
              44.791745
            ],
            [
              -113.354763,
              44.795468
            ],
            [
              -113.346692,
              44.798898
            ],
            [
              -113.3461,
              44.800611
            ],
            [
              -113.356062,
              44.819798
            ],
            [
              -113.377153,
              44.834858
            ],
            [
              -113.383984,
              44.8374
            ],
            [
              -113.422376,
              44.842595
            ],
            [
              -113.455071,
              44.865424
            ],
            [
              -113.474573,
              44.910846
            ],
            [
              -113.491121,
              44.927548
            ],
            [
              -113.498745,
              44.942314
            ],
            [
              -113.494446,
              44.948597
            ],
            [
              -113.480836,
              44.95031
            ],
            [
              -113.474781,
              44.948795
            ],
            [
              -113.467467,
              44.948061
            ],
            [
              -113.448958,
              44.953544
            ],
            [
              -113.443782,
              44.95989
            ],
            [
              -113.444862,
              44.976141
            ],
            [
              -113.447013,
              44.984637
            ],
            [
              -113.446884,
              44.998545
            ],
            [
              -113.437726,
              45.006967
            ],
            [
              -113.449909,
              45.035167
            ],
            [
              -113.44912,
              45.046098
            ],
            [
              -113.45197,
              45.059247
            ],
            [
              -113.460578,
              45.064879
            ],
            [
              -113.465073,
              45.062755
            ],
            [
              -113.47377,
              45.0617
            ],
            [
              -113.485278,
              45.063519
            ],
            [
              -113.520134,
              45.093033
            ],
            [
              -113.510819,
              45.099902
            ],
            [
              -113.506638,
              45.107288
            ],
            [
              -113.513342,
              45.115225
            ],
            [
              -113.538037,
              45.11503
            ],
            [
              -113.546488,
              45.112285
            ],
            [
              -113.554744,
              45.112901
            ],
            [
              -113.57467,
              45.128411
            ],
            [
              -113.594632,
              45.166034
            ],
            [
              -113.589891,
              45.176986
            ],
            [
              -113.599506,
              45.191114
            ],
            [
              -113.636889,
              45.212983
            ],
            [
              -113.647399,
              45.228282
            ],
            [
              -113.650064,
              45.23471
            ],
            [
              -113.657027,
              45.241436
            ],
            [
              -113.665633,
              45.246265
            ],
            [
              -113.674409,
              45.249411
            ],
            [
              -113.678749,
              45.24927
            ],
            [
              -113.684946,
              45.253706
            ],
            [
              -113.692039,
              45.265191
            ],
            [
              -113.691557,
              45.270912
            ],
            [
              -113.688077,
              45.276407
            ],
            [
              -113.689359,
              45.28355
            ],
            [
              -113.735601,
              45.325265
            ],
            [
              -113.738729,
              45.329741
            ],
            [
              -113.7402,
              45.34559
            ],
            [
              -113.73553,
              45.364738
            ],
            [
              -113.73239,
              45.385058
            ],
            [
              -113.733092,
              45.390173
            ],
            [
              -113.734402,
              45.392353
            ],
            [
              -113.750546,
              45.40272
            ],
            [
              -113.760924,
              45.406501
            ],
            [
              -113.765203,
              45.410601
            ],
            [
              -113.768058,
              45.418147
            ],
            [
              -113.763368,
              45.427732
            ],
            [
              -113.764591,
              45.431403
            ],
            [
              -113.783272,
              45.451839
            ],
            [
              -113.78416,
              45.454946
            ],
            [
              -113.759986,
              45.480735
            ],
            [
              -113.766022,
              45.520621
            ],
            [
              -113.778361,
              45.523415
            ],
            [
              -113.796579,
              45.523462
            ],
            [
              -113.802849,
              45.523159
            ],
            [
              -113.809144,
              45.519908
            ],
            [
              -113.834555,
              45.520729
            ],
            [
              -113.819868,
              45.566326
            ],
            [
              -113.804796,
              45.580358
            ],
            [
              -113.803261,
              45.584193
            ],
            [
              -113.802955,
              45.592631
            ],
            [
              -113.806729,
              45.602146
            ],
            [
              -113.823068,
              45.612486
            ],
            [
              -113.861404,
              45.62366
            ],
            [
              -113.886006,
              45.61702
            ],
            [
              -113.904691,
              45.622007
            ],
            [
              -113.902539,
              45.636945
            ],
            [
              -113.898883,
              45.644167
            ],
            [
              -113.900588,
              45.648259
            ],
            [
              -113.903582,
              45.651165
            ],
            [
              -113.919752,
              45.658536
            ],
            [
              -113.930403,
              45.671878
            ],
            [
              -113.93422,
              45.682232
            ],
            [
              -113.942693894537,
              45.6864080223607
            ],
            [
              -113.971565,
              45.700636
            ],
            [
              -113.986656,
              45.704564
            ],
            [
              -114.015633,
              45.696127
            ],
            [
              -114.019315,
              45.692937
            ],
            [
              -114.020533,
              45.681223
            ],
            [
              -114.02007,
              45.670332
            ],
            [
              -114.013786,
              45.658238
            ],
            [
              -114.014973,
              45.654008
            ],
            [
              -114.018731,
              45.648616
            ],
            [
              -114.033456,
              45.648629
            ],
            [
              -114.067619,
              45.627706
            ],
            [
              -114.08179,
              45.611329
            ],
            [
              -114.083149,
              45.603996
            ],
            [
              -114.0821,
              45.596958
            ],
            [
              -114.086584,
              45.59118
            ],
            [
              -114.100308,
              45.586354
            ],
            [
              -114.122322,
              45.58426
            ],
            [
              -114.131469,
              45.574444
            ],
            [
              -114.132359,
              45.572531
            ],
            [
              -114.128601,
              45.568996
            ],
            [
              -114.129099,
              45.565491
            ],
            [
              -114.135249,
              45.557465
            ],
            [
              -114.154837,
              45.552916
            ],
            [
              -114.180043,
              45.551432
            ],
            [
              -114.18647,
              45.545539
            ],
            [
              -114.192802,
              45.536596
            ],
            [
              -114.203665,
              45.53557
            ],
            [
              -114.227942,
              45.546423
            ],
            [
              -114.248121,
              45.545877
            ],
            [
              -114.251836,
              45.537812
            ],
            [
              -114.248183,
              45.533226
            ],
            [
              -114.247824,
              45.524283
            ],
            [
              -114.261616,
              45.495816
            ],
            [
              -114.270717,
              45.486116
            ],
            [
              -114.279217,
              45.480616
            ],
            [
              -114.333218,
              45.459316
            ],
            [
              -114.345019,
              45.459916
            ],
            [
              -114.360719,
              45.474116
            ],
            [
              -114.36562,
              45.490416
            ],
            [
              -114.36852,
              45.492716
            ],
            [
              -114.388618,
              45.502903
            ],
            [
              -114.415804,
              45.509753
            ],
            [
              -114.438991,
              45.536076
            ],
            [
              -114.450863,
              45.54253
            ],
            [
              -114.456764,
              45.543983
            ],
            [
              -114.460542,
              45.561283
            ],
            [
              -114.473759,
              45.563278
            ],
            [
              -114.498176,
              45.555473
            ],
            [
              -114.506341,
              45.559216
            ],
            [
              -114.517761,
              45.568129
            ],
            [
              -114.526075,
              45.570771
            ],
            [
              -114.549508,
              45.56059
            ],
            [
              -114.559038,
              45.565706
            ],
            [
              -114.571537,
              45.549603
            ],
            [
              -114.595445,
              45.534166
            ],
            [
              -114.631996,
              45.519177
            ],
            [
              -114.663725,
              45.471005
            ],
            [
              -114.680611,
              45.473949
            ],
            [
              -114.680626,
              45.484147
            ],
            [
              -114.70523,
              45.492555
            ],
            [
              -114.73415,
              45.489897
            ],
            [
              -114.767447,
              45.511463
            ],
            [
              -114.793778,
              45.508309
            ],
            [
              -114.79861,
              45.476255
            ],
            [
              -114.790378,
              45.466178
            ],
            [
              -114.793207,
              45.436334
            ],
            [
              -114.769127,
              45.434557
            ],
            [
              -114.760131,
              45.412049
            ],
            [
              -114.73491,
              45.395415
            ],
            [
              -114.717946,
              45.396338
            ],
            [
              -114.69548,
              45.368898
            ],
            [
              -114.674548,
              45.362562
            ],
            [
              -114.669993,
              45.342932
            ],
            [
              -114.640786,
              45.319149
            ],
            [
              -114.60394,
              45.295826
            ],
            [
              -114.593325,
              45.297415
            ],
            [
              -114.60747,
              45.281253
            ],
            [
              -114.62402,
              45.274785
            ],
            [
              -114.674876,
              45.233661
            ],
            [
              -114.694001,
              45.197182
            ],
            [
              -114.722057,
              45.180056
            ],
            [
              -114.723553,
              45.160055
            ],
            [
              -114.73227,
              45.151155
            ],
            [
              -114.721482,
              45.121023
            ],
            [
              -114.733191,
              45.09449
            ],
            [
              -114.720725,
              45.088929
            ],
            [
              -114.727236,
              45.079256
            ],
            [
              -114.722527,
              45.041704
            ],
            [
              -114.727601,
              45.025966
            ],
            [
              -114.723115,
              45.005278
            ],
            [
              -114.735476,
              44.972309
            ],
            [
              -114.734801,
              44.948962
            ],
            [
              -114.723272,
              44.934387
            ],
            [
              -114.735574,
              44.924299
            ],
            [
              -114.723294,
              44.891177
            ],
            [
              -114.734551,
              44.878746
            ],
            [
              -114.762282,
              44.875575
            ],
            [
              -114.769801,
              44.86515
            ],
            [
              -114.761659,
              44.858618
            ],
            [
              -114.767625,
              44.841677
            ],
            [
              -114.794459,
              44.834324
            ],
            [
              -114.812407,
              44.808346
            ],
            [
              -114.797714,
              44.79433
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "16",
      "COUNTYFP": "049",
      "COUNTYNS": "00395699",
      "AFFGEOID": "0500000US16049",
      "GEOID": "16049",
      "NAME": "Idaho",
      "LSAD": "06",
      "ALAND": 21956596295,
      "AWATER": 67553776,
      "County_state": "Idaho,16"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -116.79445497532701,
              45.856140639876195
            ],
            [
              -116.790151,
              45.849851
            ],
            [
              -116.787792,
              45.844267
            ],
            [
              -116.78752,
              45.840204
            ],
            [
              -116.788923,
              45.836741
            ],
            [
              -116.789066,
              45.833471
            ],
            [
              -116.788329,
              45.831928
            ],
            [
              -116.782676,
              45.825376
            ],
            [
              -116.7634,
              45.81658
            ],
            [
              -116.759787,
              45.816167
            ],
            [
              -116.755288,
              45.817061
            ],
            [
              -116.750978,
              45.818537
            ],
            [
              -116.745219,
              45.821394
            ],
            [
              -116.740486,
              45.82446
            ],
            [
              -116.736268,
              45.826179
            ],
            [
              -116.715527,
              45.826773
            ],
            [
              -116.711822,
              45.826267
            ],
            [
              -116.70845,
              45.825117
            ],
            [
              -116.698079,
              45.820852
            ],
            [
              -116.697192,
              45.820135
            ],
            [
              -116.687007,
              45.806319
            ],
            [
              -116.680139,
              45.79359
            ],
            [
              -116.665344,
              45.781998
            ],
            [
              -116.659629,
              45.780016
            ],
            [
              -116.646342,
              45.779815
            ],
            [
              -116.639641,
              45.781274
            ],
            [
              -116.635814,
              45.783642
            ],
            [
              -116.632032,
              45.784979
            ],
            [
              -116.60504,
              45.781018
            ],
            [
              -116.593004,
              45.778541
            ],
            [
              -116.577422,
              45.76753
            ],
            [
              -116.559444,
              45.755189
            ],
            [
              -116.553548,
              45.753388
            ],
            [
              -116.549085,
              45.752735
            ],
            [
              -116.546643,
              45.750972
            ],
            [
              -116.537173,
              45.737288
            ],
            [
              -116.535698,
              45.734231
            ],
            [
              -116.538014,
              45.714929
            ],
            [
              -116.536395,
              45.69665
            ],
            [
              -116.535396,
              45.691734
            ],
            [
              -116.528272,
              45.681473
            ],
            [
              -116.523961,
              45.677639
            ],
            [
              -116.512326,
              45.670224
            ],
            [
              -116.49451,
              45.655679
            ],
            [
              -116.487894,
              45.649769
            ],
            [
              -116.482495,
              45.639916
            ],
            [
              -116.477452,
              45.631267
            ],
            [
              -116.472882,
              45.624884
            ],
            [
              -116.469813,
              45.620604
            ],
            [
              -116.46517,
              45.617986
            ],
            [
              -116.463504,
              45.615785
            ],
            [
              -116.463635,
              45.602785
            ],
            [
              -116.481943,
              45.577898
            ],
            [
              -116.48297,
              45.577008
            ],
            [
              -116.490279,
              45.574499
            ],
            [
              -116.502756,
              45.566608
            ],
            [
              -116.523638,
              45.54661
            ],
            [
              -116.535482,
              45.525079
            ],
            [
              -116.543837,
              45.514193
            ],
            [
              -116.548676,
              45.510385
            ],
            [
              -116.553473,
              45.499107
            ],
            [
              -116.558804,
              45.481188
            ],
            [
              -116.558803,
              45.480076
            ],
            [
              -116.55498,
              45.472801
            ],
            [
              -116.554829,
              45.46293
            ],
            [
              -116.561744,
              45.461213
            ],
            [
              -116.563985,
              45.460169
            ],
            [
              -116.575949,
              45.452522
            ],
            [
              -116.581382,
              45.448984
            ],
            [
              -116.588195,
              45.44292
            ],
            [
              -116.592416,
              45.427356
            ],
            [
              -116.597447,
              45.41277
            ],
            [
              -116.619057,
              45.39821
            ],
            [
              -116.626633,
              45.388037
            ],
            [
              -116.653252,
              45.351084
            ],
            [
              -116.673793,
              45.321511
            ],
            [
              -116.674648,
              45.314342
            ],
            [
              -116.672594,
              45.298023
            ],
            [
              -116.672163,
              45.288938
            ],
            [
              -116.672733,
              45.283183
            ],
            [
              -116.674493,
              45.276349
            ],
            [
              -116.675587,
              45.274867
            ],
            [
              -116.681013,
              45.27072
            ],
            [
              -116.687027,
              45.267857
            ],
            [
              -116.687213119705,
              45.26768125110139
            ],
            [
              -116.342876,
              45.267872
            ],
            [
              -116.312733,
              45.211389
            ],
            [
              -116.301084,
              45.184521
            ],
            [
              -116.303762,
              45.172073
            ],
            [
              -116.295071,
              45.147794
            ],
            [
              -116.285969,
              45.128732
            ],
            [
              -116.304153,
              45.119164
            ],
            [
              -116.30475,
              45.108793
            ],
            [
              -116.144386,
              45.107651
            ],
            [
              -116.135265,
              45.124069
            ],
            [
              -116.136481,
              45.137096
            ],
            [
              -116.121662,
              45.158549
            ],
            [
              -116.081496,
              45.152858
            ],
            [
              -116.063852,
              45.165282
            ],
            [
              -116.048028,
              45.166298
            ],
            [
              -116.045452,
              45.177417
            ],
            [
              -116.019329,
              45.18282
            ],
            [
              -115.976659,
              45.219223
            ],
            [
              -115.96507,
              45.203516
            ],
            [
              -115.975787,
              45.195282
            ],
            [
              -115.000948,
              45.196877
            ],
            [
              -114.694001,
              45.197182
            ],
            [
              -114.674876,
              45.233661
            ],
            [
              -114.62402,
              45.274785
            ],
            [
              -114.60747,
              45.281253
            ],
            [
              -114.593325,
              45.297415
            ],
            [
              -114.60394,
              45.295826
            ],
            [
              -114.640786,
              45.319149
            ],
            [
              -114.669993,
              45.342932
            ],
            [
              -114.674548,
              45.362562
            ],
            [
              -114.69548,
              45.368898
            ],
            [
              -114.717946,
              45.396338
            ],
            [
              -114.73491,
              45.395415
            ],
            [
              -114.760131,
              45.412049
            ],
            [
              -114.769127,
              45.434557
            ],
            [
              -114.793207,
              45.436334
            ],
            [
              -114.790378,
              45.466178
            ],
            [
              -114.79861,
              45.476255
            ],
            [
              -114.793778,
              45.508309
            ],
            [
              -114.767447,
              45.511463
            ],
            [
              -114.73415,
              45.489897
            ],
            [
              -114.70523,
              45.492555
            ],
            [
              -114.680626,
              45.484147
            ],
            [
              -114.680611,
              45.473949
            ],
            [
              -114.663725,
              45.471005
            ],
            [
              -114.631996,
              45.519177
            ],
            [
              -114.595445,
              45.534166
            ],
            [
              -114.571537,
              45.549603
            ],
            [
              -114.559038,
              45.565706
            ],
            [
              -114.558253,
              45.585104
            ],
            [
              -114.553999,
              45.591279
            ],
            [
              -114.538132,
              45.606834
            ],
            [
              -114.544905,
              45.616673
            ],
            [
              -114.553937,
              45.619299
            ],
            [
              -114.563305,
              45.631612
            ],
            [
              -114.563652,
              45.637412
            ],
            [
              -114.561046,
              45.639906
            ],
            [
              -114.53577,
              45.650613
            ],
            [
              -114.529678,
              45.65232
            ],
            [
              -114.522142,
              45.64934
            ],
            [
              -114.507645,
              45.658949
            ],
            [
              -114.499637,
              45.669035
            ],
            [
              -114.495421,
              45.703321
            ],
            [
              -114.497553,
              45.710677
            ],
            [
              -114.504869,
              45.722176
            ],
            [
              -114.535634,
              45.739095
            ],
            [
              -114.566172,
              45.773864
            ],
            [
              -114.562509,
              45.779927
            ],
            [
              -114.555487,
              45.786249
            ],
            [
              -114.544692,
              45.791447
            ],
            [
              -114.512973,
              45.828825
            ],
            [
              -114.51704,
              45.833148
            ],
            [
              -114.517143,
              45.835993
            ],
            [
              -114.514596,
              45.840785
            ],
            [
              -114.509303,
              45.845531
            ],
            [
              -114.498809,
              45.850676
            ],
            [
              -114.470296,
              45.851343
            ],
            [
              -114.455532,
              45.855012
            ],
            [
              -114.44868,
              45.858891
            ],
            [
              -114.422963,
              45.855381
            ],
            [
              -114.409477,
              45.85164
            ],
            [
              -114.388243,
              45.88234
            ],
            [
              -114.387166,
              45.889164
            ],
            [
              -114.395059,
              45.901458
            ],
            [
              -114.404314,
              45.903497
            ],
            [
              -114.413168,
              45.911479
            ],
            [
              -114.431159,
              45.935737
            ],
            [
              -114.431328,
              45.938023
            ],
            [
              -114.427717,
              45.939625
            ],
            [
              -114.423681,
              45.9441
            ],
            [
              -114.415977,
              45.947891
            ],
            [
              -114.411933,
              45.952358
            ],
            [
              -114.404708,
              45.9559
            ],
            [
              -114.402261,
              45.961489
            ],
            [
              -114.403712,
              45.967049
            ],
            [
              -114.409353,
              45.97141
            ],
            [
              -114.411892,
              45.977883
            ],
            [
              -114.419899,
              45.981106
            ],
            [
              -114.425843,
              45.984984
            ],
            [
              -114.441185,
              45.988453
            ],
            [
              -114.470965,
              45.995742
            ],
            [
              -114.47729,
              46.000802
            ],
            [
              -114.477922,
              46.009025
            ],
            [
              -114.473811,
              46.016614
            ],
            [
              -114.480241,
              46.030325
            ],
            [
              -114.485793,
              46.030022
            ],
            [
              -114.490572,
              46.032427
            ],
            [
              -114.493418,
              46.03717
            ],
            [
              -114.494683,
              46.042546
            ],
            [
              -114.492153,
              46.04729
            ],
            [
              -114.468529,
              46.062484
            ],
            [
              -114.461864,
              46.078571
            ],
            [
              -114.460049,
              46.097104
            ],
            [
              -114.474415,
              46.112515
            ],
            [
              -114.488303,
              46.113106
            ],
            [
              -114.5213,
              46.125287
            ],
            [
              -114.527096,
              46.146218
            ],
            [
              -114.514706,
              46.167726
            ],
            [
              -114.489254,
              46.167684
            ],
            [
              -114.478333,
              46.160876
            ],
            [
              -114.472643,
              46.162202
            ],
            [
              -114.457549,
              46.170231
            ],
            [
              -114.445928,
              46.173933
            ],
            [
              -114.443215,
              46.202943
            ],
            [
              -114.445497,
              46.220227
            ],
            [
              -114.449819,
              46.237119
            ],
            [
              -114.451912,
              46.241253
            ],
            [
              -114.468254,
              46.248796
            ],
            [
              -114.470479,
              46.26732
            ],
            [
              -114.465024,
              46.273127
            ],
            [
              -114.453257,
              46.270939
            ],
            [
              -114.441326,
              46.2738
            ],
            [
              -114.43544,
              46.27661
            ],
            [
              -114.427309,
              46.283624
            ],
            [
              -114.425587,
              46.287899
            ],
            [
              -114.433478,
              46.305502
            ],
            [
              -114.431708,
              46.310744
            ],
            [
              -114.413758,
              46.335945
            ],
            [
              -114.413066870269,
              46.341500999999894
            ],
            [
              -114.410682,
              46.360673
            ],
            [
              -114.411592,
              46.366688
            ],
            [
              -114.422458,
              46.387097
            ],
            [
              -114.408974,
              46.400438
            ],
            [
              -114.384756,
              46.411784
            ],
            [
              -114.376413,
              46.442983
            ],
            [
              -114.379338,
              46.460166
            ],
            [
              -114.383051,
              46.466402
            ],
            [
              -114.394447,
              46.469549
            ],
            [
              -114.400068,
              46.47718
            ],
            [
              -114.403019,
              46.498675
            ],
            [
              -114.400257,
              46.502143
            ],
            [
              -114.395204,
              46.503148
            ],
            [
              -114.385871,
              46.50437
            ],
            [
              -114.375348,
              46.501855
            ],
            [
              -114.35874,
              46.505306
            ],
            [
              -114.351655,
              46.508119
            ],
            [
              -114.342072,
              46.519679
            ],
            [
              -114.349208,
              46.529514
            ],
            [
              -114.348733,
              46.533792
            ],
            [
              -114.34534,
              46.548444
            ],
            [
              -114.339533,
              46.564039
            ],
            [
              -114.33175,
              46.571914
            ],
            [
              -114.33161059250301,
              46.573899203359694
            ],
            [
              -114.331338,
              46.577781
            ],
            [
              -114.333931,
              46.582732
            ],
            [
              -114.334992,
              46.588154
            ],
            [
              -114.333931,
              46.592162
            ],
            [
              -114.322519,
              46.611066
            ],
            [
              -114.322912,
              46.642938
            ],
            [
              -114.320665,
              46.646963
            ],
            [
              -114.32456,
              46.653579
            ],
            [
              -114.33268369823399,
              46.660580775216395
            ],
            [
              -114.332887,
              46.660756
            ],
            [
              -114.360709,
              46.669059
            ],
            [
              -114.394514,
              46.664846
            ],
            [
              -114.403383,
              46.659633
            ],
            [
              -114.410907,
              46.657466
            ],
            [
              -114.424424,
              46.660648
            ],
            [
              -114.453239,
              46.649266
            ],
            [
              -114.45425,
              46.640974
            ],
            [
              -114.466902,
              46.631695
            ],
            [
              -114.486218,
              46.632829
            ],
            [
              -114.498007,
              46.637655
            ],
            [
              -114.547321,
              46.644485
            ],
            [
              -114.561582,
              46.642043
            ],
            [
              -114.583385,
              46.633227
            ],
            [
              -114.593292,
              46.632848
            ],
            [
              -114.595008042198,
              46.63339136601049
            ],
            [
              -115.248432,
              46.533675
            ],
            [
              -115.630708,
              46.473038
            ],
            [
              -115.639282,
              46.450768
            ],
            [
              -115.6342,
              46.437581
            ],
            [
              -115.666383,
              46.413573
            ],
            [
              -115.681084,
              46.39423
            ],
            [
              -115.724971,
              46.373555
            ],
            [
              -115.733477,
              46.362882
            ],
            [
              -115.730844,
              46.341204
            ],
            [
              -115.7394,
              46.319907
            ],
            [
              -115.754184,
              46.309509
            ],
            [
              -115.748433,
              46.297213
            ],
            [
              -115.756662,
              46.284583
            ],
            [
              -115.773458,
              46.280142
            ],
            [
              -115.778831,
              46.267351
            ],
            [
              -115.806808,
              46.271823
            ],
            [
              -115.814741,
              46.265755
            ],
            [
              -115.848474,
              46.282552
            ],
            [
              -115.938055,
              46.292483
            ],
            [
              -115.957268,
              46.288931
            ],
            [
              -115.999438,
              46.30652
            ],
            [
              -116.038657,
              46.34689
            ],
            [
              -116.076088,
              46.361988
            ],
            [
              -116.114258,
              46.363647
            ],
            [
              -116.131623,
              46.354158
            ],
            [
              -116.148782,
              46.369565
            ],
            [
              -116.170272,
              46.371588
            ],
            [
              -116.168157,
              46.37005
            ],
            [
              -116.164651,
              46.357654
            ],
            [
              -116.157402,
              46.351409
            ],
            [
              -116.152394,
              46.342749
            ],
            [
              -116.148428,
              46.338057
            ],
            [
              -116.146522,
              46.333674
            ],
            [
              -116.139466,
              46.323168
            ],
            [
              -116.133159,
              46.31067
            ],
            [
              -116.127586,
              46.30277
            ],
            [
              -116.120529,
              46.292884
            ],
            [
              -116.109475,
              46.284075
            ],
            [
              -116.100245,
              46.278756
            ],
            [
              -116.092633,
              46.268065
            ],
            [
              -116.078203,
              46.260184
            ],
            [
              -116.068865,
              46.256396
            ],
            [
              -116.053913,
              46.25349
            ],
            [
              -116.041138,
              46.248898
            ],
            [
              -116.036352,
              46.240583
            ],
            [
              -116.028316,
              46.237344
            ],
            [
              -116.016568,
              46.230286
            ],
            [
              -116.013723,
              46.227587
            ],
            [
              -116.017561,
              46.223998
            ],
            [
              -116.022495,
              46.222397
            ],
            [
              -116.026612,
              46.219851
            ],
            [
              -116.033662,
              46.216269
            ],
            [
              -116.039363,
              46.213884
            ],
            [
              -116.047672,
              46.211484
            ],
            [
              -116.054986,
              46.209982
            ],
            [
              -116.062637,
              46.210864
            ],
            [
              -116.069287,
              46.211131
            ],
            [
              -116.07309,
              46.210023
            ],
            [
              -116.079275,
              46.208927
            ],
            [
              -116.08717,
              46.2083
            ],
            [
              -116.097929,
              46.209164
            ],
            [
              -116.110766,
              46.210535
            ],
            [
              -116.121827,
              46.211641
            ],
            [
              -116.126406,
              46.214869
            ],
            [
              -116.136365,
              46.21421
            ],
            [
              -116.140987,
              46.21261
            ],
            [
              -116.147726,
              46.210632
            ],
            [
              -116.152594,
              46.206804
            ],
            [
              -116.155255,
              46.202918
            ],
            [
              -116.161463,
              46.197578
            ],
            [
              -116.164642,
              46.192928
            ],
            [
              -116.166744,
              46.188962
            ],
            [
              -116.170745,
              46.186585
            ],
            [
              -116.175647,
              46.185396
            ],
            [
              -116.179395,
              46.182849
            ],
            [
              -116.184537,
              46.178638
            ],
            [
              -116.186939,
              46.172393
            ],
            [
              -116.186227,
              46.166377
            ],
            [
              -116.188021,
              46.158577
            ],
            [
              -116.191344,
              46.154444
            ],
            [
              -116.194432,
              46.153208
            ],
            [
              -116.200476,
              46.152761
            ],
            [
              -116.209697,
              46.157116
            ],
            [
              -116.216177,
              46.159038
            ],
            [
              -116.222027,
              46.160837
            ],
            [
              -116.22927,
              46.158801
            ],
            [
              -116.234362,
              46.159883
            ],
            [
              -116.239167,
              46.16185
            ],
            [
              -116.242505,
              46.166508
            ],
            [
              -116.248184,
              46.1691
            ],
            [
              -116.255157,
              46.170525
            ],
            [
              -116.259963,
              46.169148
            ],
            [
              -116.266572,
              46.167957
            ],
            [
              -116.274087,
              46.170958
            ],
            [
              -116.277479,
              46.172446
            ],
            [
              -116.284124,
              46.173866
            ],
            [
              -116.291905,
              46.170095
            ],
            [
              -116.300925,
              46.166979
            ],
            [
              -116.306843,
              46.168201
            ],
            [
              -116.31244,
              46.169742
            ],
            [
              -116.31652,
              46.172383
            ],
            [
              -116.321552,
              46.175727
            ],
            [
              -116.324559,
              46.179313
            ],
            [
              -116.32918,
              46.182725
            ],
            [
              -116.332607,
              46.184089
            ],
            [
              -116.338301,
              46.184132
            ],
            [
              -116.344991,
              46.185166
            ],
            [
              -116.3516,
              46.185734
            ],
            [
              -116.355814,
              46.185383
            ],
            [
              -116.359684,
              46.185027
            ],
            [
              -116.366176,
              46.184905
            ],
            [
              -116.371619,
              46.184468
            ],
            [
              -116.376925,
              46.186685
            ],
            [
              -116.381135,
              46.188125
            ],
            [
              -116.385326,
              46.187938
            ],
            [
              -116.391168,
              46.188501
            ],
            [
              -116.397388,
              46.19034
            ],
            [
              -116.402437,
              46.191531
            ],
            [
              -116.407897,
              46.194003
            ],
            [
              -116.412381,
              46.196453
            ],
            [
              -116.416434,
              46.199383
            ],
            [
              -116.419864,
              46.201232
            ],
            [
              -116.424573,
              46.201869
            ],
            [
              -116.429119,
              46.202128
            ],
            [
              -116.433162,
              46.199843
            ],
            [
              -116.43726,
              46.197675
            ],
            [
              -116.440977,
              46.196298
            ],
            [
              -116.444329,
              46.196025
            ],
            [
              -116.447516,
              46.196912
            ],
            [
              -116.451276,
              46.195412
            ],
            [
              -116.452465,
              46.19364
            ],
            [
              -116.453598,
              46.190737
            ],
            [
              -116.454797,
              46.185596
            ],
            [
              -116.456477,
              46.182161
            ],
            [
              -116.4571,
              46.178117
            ],
            [
              -116.459113,
              46.173366
            ],
            [
              -116.463266,
              46.170011
            ],
            [
              -116.46328,
              46.16655
            ],
            [
              -116.464733,
              46.163661
            ],
            [
              -116.466098,
              46.160614
            ],
            [
              -116.469739,
              46.157864
            ],
            [
              -116.472664,
              46.153891
            ],
            [
              -116.473836,
              46.149737
            ],
            [
              -116.477362,
              46.146743
            ],
            [
              -116.480134,
              46.144022
            ],
            [
              -116.478743,
              46.140872
            ],
            [
              -116.478566,
              46.136865
            ],
            [
              -116.479997,
              46.135617
            ],
            [
              -116.479372,
              46.134047
            ],
            [
              -116.478052,
              46.132975
            ],
            [
              -116.476555,
              46.129284
            ],
            [
              -116.476084,
              46.125155
            ],
            [
              -116.47769,
              46.122804
            ],
            [
              -116.481499,
              46.121177
            ],
            [
              -116.486658,
              46.122005
            ],
            [
              -116.491202,
              46.123076
            ],
            [
              -116.496032,
              46.122894
            ],
            [
              -116.499763,
              46.123216
            ],
            [
              -116.504823,
              46.124688
            ],
            [
              -116.508625,
              46.127228
            ],
            [
              -116.510976,
              46.128564
            ],
            [
              -116.512015,
              46.130057
            ],
            [
              -116.514623,
              46.128663
            ],
            [
              -116.514527,
              46.124867
            ],
            [
              -116.514464,
              46.103353
            ],
            [
              -116.514564,
              46.082806
            ],
            [
              -116.514601,
              46.068665
            ],
            [
              -116.514565,
              46.059659
            ],
            [
              -116.514713,
              46.050195
            ],
            [
              -116.516679,
              46.046552
            ],
            [
              -116.51655,
              46.043691
            ],
            [
              -116.515517,
              46.040298
            ],
            [
              -116.515394,
              46.037899
            ],
            [
              -116.516619,
              46.03634
            ],
            [
              -116.518046,
              46.034196
            ],
            [
              -116.518733,
              46.031102
            ],
            [
              -116.522419,
              46.027749
            ],
            [
              -116.52469,
              46.024522
            ],
            [
              -116.527817,
              46.019945
            ],
            [
              -116.527317,
              46.01473
            ],
            [
              -116.524759,
              46.01053
            ],
            [
              -116.526499,
              46.005801
            ],
            [
              -116.528485,
              46.001949
            ],
            [
              -116.533527,
              45.999683
            ],
            [
              -116.537579,
              45.99883
            ],
            [
              -116.542616,
              45.999692
            ],
            [
              -116.543687,
              46.002417
            ],
            [
              -116.549027,
              46.00506
            ],
            [
              -116.555129,
              46.006632
            ],
            [
              -116.558902,
              46.009925
            ],
            [
              -116.5634,
              46.013043
            ],
            [
              -116.565003,
              46.016215
            ],
            [
              -116.570483,
              46.019886
            ],
            [
              -116.576621,
              46.023472
            ],
            [
              -116.579553,
              46.026816
            ],
            [
              -116.584086,
              46.028665
            ],
            [
              -116.590158,
              46.030415
            ],
            [
              -116.596638,
              46.032667
            ],
            [
              -116.605446,
              46.034637
            ],
            [
              -116.608478,
              46.034807
            ],
            [
              -116.614176,
              46.033246
            ],
            [
              -116.617613,
              46.035118
            ],
            [
              -116.62637,
              46.037219
            ],
            [
              -116.637913,
              46.036259
            ],
            [
              -116.648516,
              46.030597
            ],
            [
              -116.647438,
              46.017449
            ],
            [
              -116.64343,
              46.008029
            ],
            [
              -116.645477,
              45.999877
            ],
            [
              -116.648259,
              45.995547
            ],
            [
              -116.654699,
              45.993112
            ],
            [
              -116.662813,
              45.991841
            ],
            [
              -116.668734,
              45.995282
            ],
            [
              -116.681126,
              45.998031
            ],
            [
              -116.689669,
              45.998704
            ],
            [
              -116.696341,
              45.999443
            ],
            [
              -116.700112,
              45.998073
            ],
            [
              -116.701975,
              45.996217
            ],
            [
              -116.749132,
              45.961191
            ],
            [
              -116.757042,
              45.942103
            ],
            [
              -116.730343,
              45.894105
            ],
            [
              -116.750235,
              45.869287
            ],
            [
              -116.79445497532701,
              45.856140639876195
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "16",
      "COUNTYFP": "031",
      "COUNTYNS": "00395698",
      "AFFGEOID": "0500000US16031",
      "GEOID": "16031",
      "NAME": "Cassia",
      "LSAD": "06",
      "ALAND": 6644895767,
      "AWATER": 39160685,
      "County_state": "Cassia,16"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -114.285448,
              42.417333
            ],
            [
              -114.286652,
              42.330526
            ],
            [
              -114.281799324163,
              41.994213920521
            ],
            [
              -114.107428,
              41.993965
            ],
            [
              -114.107259,
              41.993831
            ],
            [
              -114.061763,
              41.993939
            ],
            [
              -114.061774,
              41.993797
            ],
            [
              -114.048257,
              41.993814
            ],
            [
              -114.048246,
              41.993721
            ],
            [
              -114.041723,
              41.99372
            ],
            [
              -113.993903,
              41.992698
            ],
            [
              -113.893261,
              41.988057
            ],
            [
              -113.822163,
              41.988479
            ],
            [
              -113.796082,
              41.989104
            ],
            [
              -113.76453,
              41.989459
            ],
            [
              -113.500837,
              41.992799
            ],
            [
              -113.496548,
              41.993305
            ],
            [
              -113.431563,
              41.993799
            ],
            [
              -113.40223,
              41.994161
            ],
            [
              -113.396497,
              41.99425
            ],
            [
              -113.357611,
              41.993859
            ],
            [
              -113.340072,
              41.994747
            ],
            [
              -113.250829,
              41.99561
            ],
            [
              -113.249159,
              41.996203
            ],
            [
              -113.000821,
              41.998223
            ],
            [
              -113.000040255018,
              41.9982244456233
            ],
            [
              -112.999965,
              42.327427
            ],
            [
              -113.001673,
              42.327431
            ],
            [
              -113.002792,
              42.588003
            ],
            [
              -113.175678,
              42.589464
            ],
            [
              -113.175182,
              42.627273
            ],
            [
              -113.195692,
              42.633887
            ],
            [
              -113.236968,
              42.625254
            ],
            [
              -113.257285,
              42.618062
            ],
            [
              -113.262172,
              42.628303
            ],
            [
              -113.3044,
              42.628749
            ],
            [
              -113.360707,
              42.672118
            ],
            [
              -113.373117,
              42.687638
            ],
            [
              -113.411559,
              42.674448
            ],
            [
              -113.472177,
              42.669352
            ],
            [
              -113.493681,
              42.673594
            ],
            [
              -113.557193,
              42.656799
            ],
            [
              -113.575261,
              42.645575
            ],
            [
              -113.584486,
              42.625155
            ],
            [
              -113.607095,
              42.596291
            ],
            [
              -113.621329,
              42.586529
            ],
            [
              -113.644167,
              42.54549
            ],
            [
              -113.654149,
              42.536155
            ],
            [
              -113.699044,
              42.51919
            ],
            [
              -113.739278,
              42.522854
            ],
            [
              -113.758328,
              42.534076
            ],
            [
              -113.763556,
              42.547209
            ],
            [
              -113.780157,
              42.556902
            ],
            [
              -113.804043,
              42.550458
            ],
            [
              -113.829088,
              42.535546
            ],
            [
              -113.840385,
              42.535978
            ],
            [
              -113.876322,
              42.54952
            ],
            [
              -113.889787,
              42.549375
            ],
            [
              -113.931799,
              42.535243
            ],
            [
              -113.986118,
              42.533841
            ],
            [
              -114.000388,
              42.526373
            ],
            [
              -114.067073,
              42.514453
            ],
            [
              -114.06908,
              42.503179
            ],
            [
              -114.069162,
              42.417124
            ],
            [
              -114.285448,
              42.417333
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "16",
      "COUNTYFP": "013",
      "COUNTYNS": "00395696",
      "AFFGEOID": "0500000US16013",
      "GEOID": "16013",
      "NAME": "Blaine",
      "LSAD": "06",
      "ALAND": 6831709855,
      "AWATER": 44656291,
      "County_state": "Blaine,16"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -114.996687,
              43.914407
            ],
            [
              -114.973887,
              43.867358
            ],
            [
              -114.989258,
              43.858563
            ],
            [
              -114.969282,
              43.848585
            ],
            [
              -114.951885,
              43.854275
            ],
            [
              -114.93103,
              43.840038
            ],
            [
              -114.8858,
              43.824279
            ],
            [
              -114.847781,
              43.816381
            ],
            [
              -114.854389,
              43.791655
            ],
            [
              -114.828909,
              43.788002
            ],
            [
              -114.800474,
              43.802732
            ],
            [
              -114.760227,
              43.791641
            ],
            [
              -114.740374,
              43.806837
            ],
            [
              -114.714648,
              43.814669
            ],
            [
              -114.70205,
              43.805413
            ],
            [
              -114.692627,
              43.78426
            ],
            [
              -114.690171,
              43.75038
            ],
            [
              -114.666202,
              43.737962
            ],
            [
              -114.680102,
              43.724105
            ],
            [
              -114.676211,
              43.698058
            ],
            [
              -114.688032,
              43.686059
            ],
            [
              -114.67915,
              43.669946
            ],
            [
              -114.662311,
              43.660705
            ],
            [
              -114.668697,
              43.629489
            ],
            [
              -114.679081,
              43.616262
            ],
            [
              -114.682355,
              43.590349
            ],
            [
              -114.651649,
              43.592996
            ],
            [
              -114.636134,
              43.573608
            ],
            [
              -114.586743,
              43.558826
            ],
            [
              -114.595698,
              43.531235
            ],
            [
              -114.561881,
              43.498907
            ],
            [
              -114.529433,
              43.490722
            ],
            [
              -114.506087,
              43.477904
            ],
            [
              -114.514497,
              43.4576
            ],
            [
              -114.546546,
              43.435445
            ],
            [
              -114.546383,
              43.398404
            ],
            [
              -114.526511,
              43.398365
            ],
            [
              -114.526944,
              43.368602
            ],
            [
              -114.534488,
              43.333383
            ],
            [
              -114.503107,
              43.329105
            ],
            [
              -114.480211,
              43.332422
            ],
            [
              -114.463104,
              43.325062
            ],
            [
              -114.432368,
              43.33416
            ],
            [
              -114.394606,
              43.326467
            ],
            [
              -114.394849,
              43.257561
            ],
            [
              -114.374758,
              43.257514
            ],
            [
              -114.374962,
              43.19964
            ],
            [
              -113.714642,
              43.199783
            ],
            [
              -113.413867,
              43.199859
            ],
            [
              -113.413138,
              42.849169
            ],
            [
              -113.472155,
              42.849201
            ],
            [
              -113.472177,
              42.669352
            ],
            [
              -113.411559,
              42.674448
            ],
            [
              -113.373117,
              42.687638
            ],
            [
              -113.360707,
              42.672118
            ],
            [
              -113.3044,
              42.628749
            ],
            [
              -113.262172,
              42.628303
            ],
            [
              -113.257285,
              42.618062
            ],
            [
              -113.236968,
              42.625254
            ],
            [
              -113.236841,
              42.675308
            ],
            [
              -113.178633,
              42.67546
            ],
            [
              -113.178877,
              42.762355
            ],
            [
              -113.236943,
              42.762212
            ],
            [
              -113.24372,
              42.848207
            ],
            [
              -113.243478,
              43.110946
            ],
            [
              -113.007712,
              43.110933
            ],
            [
              -113.007318,
              43.284778
            ],
            [
              -113.361958,
              43.284678
            ],
            [
              -113.36191,
              43.36716
            ],
            [
              -113.63523,
              43.367546
            ],
            [
              -113.634325,
              43.497474
            ],
            [
              -113.653631,
              43.49373
            ],
            [
              -113.67283,
              43.500359
            ],
            [
              -113.665557,
              43.511766
            ],
            [
              -113.684294,
              43.530955
            ],
            [
              -113.687013,
              43.553112
            ],
            [
              -113.701153,
              43.584553
            ],
            [
              -113.727656,
              43.59014
            ],
            [
              -113.748347,
              43.587204
            ],
            [
              -113.752859,
              43.575242
            ],
            [
              -113.77713,
              43.566853
            ],
            [
              -113.798441,
              43.569217
            ],
            [
              -113.806069,
              43.571845
            ],
            [
              -113.813355,
              43.600059
            ],
            [
              -113.835607,
              43.604909
            ],
            [
              -113.843889,
              43.624972
            ],
            [
              -113.875329,
              43.650504
            ],
            [
              -113.904918,
              43.649719
            ],
            [
              -113.911432,
              43.67158
            ],
            [
              -113.933516,
              43.679365
            ],
            [
              -113.951987,
              43.697743
            ],
            [
              -113.97447,
              43.708186
            ],
            [
              -113.984107,
              43.733687
            ],
            [
              -114.006229,
              43.765883
            ],
            [
              -114.029718,
              43.773361
            ],
            [
              -114.053446,
              43.762146
            ],
            [
              -114.053049,
              43.746592
            ],
            [
              -114.070019,
              43.734696
            ],
            [
              -114.116996,
              43.740347
            ],
            [
              -114.137707,
              43.756291
            ],
            [
              -114.142131,
              43.77424
            ],
            [
              -114.159877,
              43.774429
            ],
            [
              -114.179999,
              43.789901
            ],
            [
              -114.24162,
              43.810776
            ],
            [
              -114.275725,
              43.831138
            ],
            [
              -114.283922,
              43.860893
            ],
            [
              -114.278814,
              43.878849
            ],
            [
              -114.292684,
              43.887143
            ],
            [
              -114.343532,
              43.890279
            ],
            [
              -114.359293,
              43.874177
            ],
            [
              -114.410002,
              43.895651
            ],
            [
              -114.427482,
              43.891509
            ],
            [
              -114.443708,
              43.897193
            ],
            [
              -114.474541,
              43.896517
            ],
            [
              -114.485066,
              43.904504
            ],
            [
              -114.507778,
              43.89909
            ],
            [
              -114.519673,
              43.871679
            ],
            [
              -114.513254,
              43.863216
            ],
            [
              -114.528447,
              43.845662
            ],
            [
              -114.56235,
              43.844368
            ],
            [
              -114.581992,
              43.85145
            ],
            [
              -114.583469,
              43.875103
            ],
            [
              -114.606151,
              43.89362
            ],
            [
              -114.633305,
              43.902383
            ],
            [
              -114.644414,
              43.914255
            ],
            [
              -114.681168,
              43.927705
            ],
            [
              -114.81379,
              43.927772
            ],
            [
              -114.813873,
              43.992941
            ],
            [
              -114.873372,
              43.992949
            ],
            [
              -114.931017,
              43.968729
            ],
            [
              -114.939511,
              43.953176
            ],
            [
              -114.971351,
              43.938308
            ],
            [
              -114.996687,
              43.914407
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "16",
      "COUNTYFP": "047",
      "COUNTYNS": "00395441",
      "AFFGEOID": "0500000US16047",
      "GEOID": "16047",
      "NAME": "Gooding",
      "LSAD": "06",
      "ALAND": 1888836285,
      "AWATER": 12568998,
      "County_state": "Gooding,16"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -115.086155,
              43.198064
            ],
            [
              -115.086852,
              42.91456
            ],
            [
              -115.037774,
              42.911864
            ],
            [
              -115.026017,
              42.917753
            ],
            [
              -114.960322,
              42.914925
            ],
            [
              -114.954762,
              42.905133
            ],
            [
              -114.918769,
              42.881113
            ],
            [
              -114.901636,
              42.859108
            ],
            [
              -114.903188,
              42.843731
            ],
            [
              -114.914366,
              42.826337
            ],
            [
              -114.936678,
              42.813253
            ],
            [
              -114.931386,
              42.80593
            ],
            [
              -114.940816,
              42.791409
            ],
            [
              -114.933622,
              42.766931
            ],
            [
              -114.908164,
              42.770447
            ],
            [
              -114.876958,
              42.756872
            ],
            [
              -114.857807,
              42.755301
            ],
            [
              -114.843978,
              42.735102
            ],
            [
              -114.850399,
              42.713668
            ],
            [
              -114.824634,
              42.704035
            ],
            [
              -114.825792,
              42.686114
            ],
            [
              -114.812167,
              42.664415
            ],
            [
              -114.777689,
              42.664357
            ],
            [
              -114.745743,
              42.673344
            ],
            [
              -114.682324,
              42.65957
            ],
            [
              -114.651814,
              42.660948
            ],
            [
              -114.616487,
              42.649298
            ],
            [
              -114.617401,
              42.850888
            ],
            [
              -114.594375,
              42.850901
            ],
            [
              -114.594605,
              43.198345
            ],
            [
              -115.086155,
              43.198064
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "16",
      "COUNTYFP": "071",
      "COUNTYNS": "00399759",
      "AFFGEOID": "0500000US16071",
      "GEOID": "16071",
      "NAME": "Oneida",
      "LSAD": "06",
      "ALAND": 3105269253,
      "AWATER": 3995238,
      "County_state": "Oneida,16"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -112.999965,
              42.327427
            ],
            [
              -113.000040255018,
              41.9982244456233
            ],
            [
              -112.979218,
              41.998263
            ],
            [
              -112.909587,
              41.998791
            ],
            [
              -112.882367,
              41.998922
            ],
            [
              -112.880619,
              41.998921
            ],
            [
              -112.833125,
              41.999345
            ],
            [
              -112.833084,
              41.999305
            ],
            [
              -112.788542,
              41.999681
            ],
            [
              -112.709375,
              42.000309
            ],
            [
              -112.648019,
              42.000307
            ],
            [
              -112.450814,
              42.000953
            ],
            [
              -112.450567,
              42.001092
            ],
            [
              -112.38617,
              42.001126
            ],
            [
              -112.264936,
              42.000991
            ],
            [
              -112.239107,
              42.001217
            ],
            [
              -112.192976,
              42.001167
            ],
            [
              -112.173352,
              41.996568
            ],
            [
              -112.164723711068,
              41.9966965611378
            ],
            [
              -112.163956,
              41.996708
            ],
            [
              -112.109532,
              41.997598
            ],
            [
              -112.10944249133001,
              41.9975986914138
            ],
            [
              -112.109271,
              42.169911
            ],
            [
              -112.070266,
              42.169646
            ],
            [
              -112.070959,
              42.238263
            ],
            [
              -112.083426,
              42.245963
            ],
            [
              -112.098293,
              42.26994
            ],
            [
              -112.113323,
              42.269345
            ],
            [
              -112.126194,
              42.285229
            ],
            [
              -112.137805,
              42.295749
            ],
            [
              -112.156013,
              42.294473
            ],
            [
              -112.160956,
              42.308476
            ],
            [
              -112.185897,
              42.337392
            ],
            [
              -112.190656,
              42.350839
            ],
            [
              -112.259281,
              42.350721
            ],
            [
              -112.275651,
              42.318628
            ],
            [
              -112.291247,
              42.316825
            ],
            [
              -112.305058,
              42.326497
            ],
            [
              -112.327102,
              42.323836
            ],
            [
              -112.328868,
              42.347207
            ],
            [
              -112.304747,
              42.379694
            ],
            [
              -112.293857,
              42.386723
            ],
            [
              -112.290785,
              42.427114
            ],
            [
              -112.297244,
              42.453874
            ],
            [
              -112.342931,
              42.447667
            ],
            [
              -112.345984,
              42.466945
            ],
            [
              -112.365464,
              42.467002
            ],
            [
              -112.380208,
              42.481475
            ],
            [
              -112.384498,
              42.503055
            ],
            [
              -112.419825,
              42.503038
            ],
            [
              -112.498139,
              42.502967
            ],
            [
              -112.497849,
              42.415998
            ],
            [
              -112.653989,
              42.416169
            ],
            [
              -112.654028,
              42.328872
            ],
            [
              -112.999965,
              42.327427
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "16",
      "COUNTYFP": "015",
      "COUNTYNS": "00395157",
      "AFFGEOID": "0500000US16015",
      "GEOID": "16015",
      "NAME": "Boise",
      "LSAD": "06",
      "ALAND": 4919826480,
      "AWATER": 19104287,
      "County_state": "Boise,16"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -116.273069,
              44.151227
            ],
            [
              -116.282019,
              43.807365
            ],
            [
              -116.263384,
              43.793792
            ],
            [
              -116.038706,
              43.629228
            ],
            [
              -115.980178,
              43.585874
            ],
            [
              -115.975719,
              43.591438
            ],
            [
              -115.956502,
              43.589547
            ],
            [
              -115.955784,
              43.601667
            ],
            [
              -115.935128,
              43.608236
            ],
            [
              -115.929883,
              43.595567
            ],
            [
              -115.912357,
              43.592097
            ],
            [
              -115.897034,
              43.598843
            ],
            [
              -115.875927,
              43.591265
            ],
            [
              -115.847213,
              43.607992
            ],
            [
              -115.830872,
              43.60525
            ],
            [
              -115.797108,
              43.638608
            ],
            [
              -115.770019,
              43.650272
            ],
            [
              -115.754319,
              43.643019
            ],
            [
              -115.730542,
              43.653722
            ],
            [
              -115.726387,
              43.667988
            ],
            [
              -115.697508,
              43.665373
            ],
            [
              -115.664538,
              43.69733
            ],
            [
              -115.645802,
              43.70026
            ],
            [
              -115.625615,
              43.728255
            ],
            [
              -115.633945,
              43.745652
            ],
            [
              -115.616603,
              43.763474
            ],
            [
              -115.613436,
              43.782831
            ],
            [
              -115.592796,
              43.798289
            ],
            [
              -115.547538,
              43.792667
            ],
            [
              -115.530066,
              43.815973
            ],
            [
              -115.539093,
              43.84741
            ],
            [
              -115.525667,
              43.857316
            ],
            [
              -115.513114,
              43.881477
            ],
            [
              -115.500374,
              43.889902
            ],
            [
              -115.470985,
              43.894029
            ],
            [
              -115.425201,
              43.909395
            ],
            [
              -115.366238,
              43.924821
            ],
            [
              -115.337786,
              43.921
            ],
            [
              -115.286534,
              43.935879
            ],
            [
              -115.275942,
              43.960692
            ],
            [
              -115.251898,
              43.995724
            ],
            [
              -115.234241,
              44.00682
            ],
            [
              -115.21589,
              44.032599
            ],
            [
              -115.209853,
              44.056278
            ],
            [
              -115.19706,
              44.068993
            ],
            [
              -115.207273,
              44.083298
            ],
            [
              -115.230528,
              44.098766
            ],
            [
              -115.181931,
              44.090533
            ],
            [
              -115.164384,
              44.075854
            ],
            [
              -115.149124,
              44.041164
            ],
            [
              -115.147842,
              44.002842
            ],
            [
              -115.136209,
              43.983859
            ],
            [
              -115.10591,
              43.970248
            ],
            [
              -115.08547,
              43.976593
            ],
            [
              -115.092575,
              43.960563
            ],
            [
              -115.067428,
              43.940416
            ],
            [
              -115.038453,
              43.957675
            ],
            [
              -115.011955,
              43.949823
            ],
            [
              -114.990777,
              43.949723
            ],
            [
              -114.967343,
              43.982088
            ],
            [
              -114.9849,
              43.988844
            ],
            [
              -114.982558,
              44.011188
            ],
            [
              -114.998601,
              44.020662
            ],
            [
              -115.007358,
              44.013328
            ],
            [
              -115.032353,
              44.019492
            ],
            [
              -115.048135,
              44.037803
            ],
            [
              -115.04356,
              44.05882
            ],
            [
              -115.019612,
              44.079954
            ],
            [
              -114.999666,
              44.085714
            ],
            [
              -114.998038,
              44.098227
            ],
            [
              -115.020525,
              44.098386
            ],
            [
              -115.046725,
              44.119971
            ],
            [
              -115.02812,
              44.127395
            ],
            [
              -115.017146,
              44.141349
            ],
            [
              -115.024181,
              44.155478
            ],
            [
              -115.051284,
              44.165271
            ],
            [
              -115.061735,
              44.160133
            ],
            [
              -115.08315,
              44.176176
            ],
            [
              -115.107931,
              44.17006
            ],
            [
              -115.130464,
              44.192208
            ],
            [
              -115.143669,
              44.190311
            ],
            [
              -115.167684,
              44.20284
            ],
            [
              -115.162765,
              44.213706
            ],
            [
              -115.174831,
              44.235464
            ],
            [
              -115.16054,
              44.239624
            ],
            [
              -115.168795,
              44.283057
            ],
            [
              -115.178964,
              44.296521
            ],
            [
              -115.203028,
              44.282529
            ],
            [
              -115.21268,
              44.303959
            ],
            [
              -115.235985,
              44.308114
            ],
            [
              -115.247849,
              44.296699
            ],
            [
              -115.249734,
              44.311087
            ],
            [
              -115.28772,
              44.324685
            ],
            [
              -115.294614,
              44.339445
            ],
            [
              -115.301929,
              44.342249
            ],
            [
              -115.318145,
              44.347933
            ],
            [
              -115.334073,
              44.349327
            ],
            [
              -115.344799,
              44.347083
            ],
            [
              -115.352358,
              44.347785
            ],
            [
              -115.354484,
              44.341172
            ],
            [
              -115.350614,
              44.335401
            ],
            [
              -115.350816,
              44.3264
            ],
            [
              -115.349451,
              44.314731
            ],
            [
              -115.35237,
              44.305252
            ],
            [
              -115.364649,
              44.299251
            ],
            [
              -115.376745,
              44.294043
            ],
            [
              -115.387835,
              44.28691
            ],
            [
              -115.387869,
              44.279693
            ],
            [
              -115.393341,
              44.267406
            ],
            [
              -115.398516,
              44.265446
            ],
            [
              -115.401511,
              44.263166
            ],
            [
              -115.401851,
              44.261631
            ],
            [
              -115.406775,
              44.261811
            ],
            [
              -115.411105,
              44.264739
            ],
            [
              -115.422494,
              44.265955
            ],
            [
              -115.43274,
              44.260205
            ],
            [
              -115.442924,
              44.253016
            ],
            [
              -115.450893,
              44.246651
            ],
            [
              -115.465827,
              44.242106
            ],
            [
              -115.474809,
              44.238833
            ],
            [
              -115.4864,
              44.239048
            ],
            [
              -115.496754,
              44.233832
            ],
            [
              -115.50136,
              44.234216
            ],
            [
              -115.504438,
              44.238543
            ],
            [
              -115.510741,
              44.242809
            ],
            [
              -115.518354,
              44.238563
            ],
            [
              -115.56514,
              44.235565
            ],
            [
              -115.625196,
              44.235524
            ],
            [
              -115.650172,
              44.235483
            ],
            [
              -115.689926,
              44.235617
            ],
            [
              -115.759462,
              44.235469
            ],
            [
              -115.818382,
              44.235489
            ],
            [
              -115.861571,
              44.235662
            ],
            [
              -115.899577,
              44.23576
            ],
            [
              -115.93841,
              44.235549
            ],
            [
              -115.988497,
              44.23564
            ],
            [
              -116.01445,
              44.235637
            ],
            [
              -116.05239,
              44.236453
            ],
            [
              -116.097412,
              44.236401
            ],
            [
              -116.103107,
              44.230765
            ],
            [
              -116.105855,
              44.223602
            ],
            [
              -116.106631,
              44.218307
            ],
            [
              -116.106226,
              44.211693
            ],
            [
              -116.107631,
              44.208258
            ],
            [
              -116.109196,
              44.203215
            ],
            [
              -116.113158,
              44.196862
            ],
            [
              -116.114477,
              44.190281
            ],
            [
              -116.116061,
              44.183002
            ],
            [
              -116.117001,
              44.176885
            ],
            [
              -116.120005,
              44.171468
            ],
            [
              -116.119423,
              44.166477
            ],
            [
              -116.116919,
              44.159884
            ],
            [
              -116.116802,
              44.15456
            ],
            [
              -116.114025,
              44.151465
            ],
            [
              -116.119291,
              44.147806
            ],
            [
              -116.132681,
              44.147734
            ],
            [
              -116.155972,
              44.151352
            ],
            [
              -116.193229,
              44.151398
            ],
            [
              -116.213871,
              44.151387
            ],
            [
              -116.273069,
              44.151227
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "16",
      "COUNTYFP": "019",
      "COUNTYNS": "00395407",
      "AFFGEOID": "0500000US16019",
      "GEOID": "16019",
      "NAME": "Bonneville",
      "LSAD": "06",
      "ALAND": 4832874977,
      "AWATER": 88853019,
      "County_state": "Bonneville,16"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -112.519861,
              43.626623
            ],
            [
              -112.520024,
              43.42517
            ],
            [
              -112.103298,
              43.425057
            ],
            [
              -112.103396,
              43.41026
            ],
            [
              -112.043672,
              43.410143
            ],
            [
              -112.043821,
              43.366708
            ],
            [
              -111.945775,
              43.366306
            ],
            [
              -111.822475,
              43.366035
            ],
            [
              -111.822494,
              43.282286
            ],
            [
              -111.588049,
              43.281841
            ],
            [
              -111.589489,
              43.020052
            ],
            [
              -111.359887,
              43.021127
            ],
            [
              -111.353175,
              43.020474
            ],
            [
              -111.044142896217,
              43.019408
            ],
            [
              -111.044206,
              43.022614
            ],
            [
              -111.044034,
              43.024581
            ],
            [
              -111.044034,
              43.024844
            ],
            [
              -111.044033,
              43.026411
            ],
            [
              -111.044094,
              43.02927
            ],
            [
              -111.043997,
              43.041415
            ],
            [
              -111.044058,
              43.04464
            ],
            [
              -111.044063,
              43.046302
            ],
            [
              -111.044086,
              43.054819
            ],
            [
              -111.044117,
              43.060309
            ],
            [
              -111.04415,
              43.066172
            ],
            [
              -111.044162,
              43.068222
            ],
            [
              -111.044143,
              43.072364
            ],
            [
              -111.044235,
              43.177121
            ],
            [
              -111.044266,
              43.177236
            ],
            [
              -111.044232,
              43.18444
            ],
            [
              -111.044168,
              43.189244
            ],
            [
              -111.044229,
              43.195579
            ],
            [
              -111.044617,
              43.31572
            ],
            [
              -111.045204743411,
              43.501051850722895
            ],
            [
              -111.055831,
              43.519504
            ],
            [
              -111.07321,
              43.527391
            ],
            [
              -111.085901,
              43.521426
            ],
            [
              -111.126092,
              43.531937
            ],
            [
              -111.143501,
              43.546657
            ],
            [
              -111.171973,
              43.547043
            ],
            [
              -111.198841,
              43.539731
            ],
            [
              -111.207561,
              43.543857
            ],
            [
              -111.22635,
              43.590155
            ],
            [
              -111.226081,
              43.606378
            ],
            [
              -111.243839,
              43.621853
            ],
            [
              -111.399765,
              43.621976
            ],
            [
              -111.531781,
              43.622024
            ],
            [
              -111.566042,
              43.626836
            ],
            [
              -111.626043,
              43.626756
            ],
            [
              -111.983643,
              43.626346
            ],
            [
              -112.519861,
              43.626623
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "16",
      "COUNTYFP": "079",
      "COUNTYNS": "01702379",
      "AFFGEOID": "0500000US16079",
      "GEOID": "16079",
      "NAME": "Shoshone",
      "LSAD": "06",
      "ALAND": 6830709342,
      "AWATER": 14151673,
      "County_state": "Shoshone,16"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -116.329601,
              48.017722
            ],
            [
              -116.329538,
              47.890393
            ],
            [
              -116.329406,
              47.414011
            ],
            [
              -116.329418,
              47.021915
            ],
            [
              -116.329436,
              46.934669
            ],
            [
              -115.827006,
              46.934158
            ],
            [
              -114.96397803595102,
              46.9328888722194
            ],
            [
              -114.986539,
              46.952099
            ],
            [
              -115.00091,
              46.967703
            ],
            [
              -115.001274,
              46.971901
            ],
            [
              -115.028386,
              46.975659
            ],
            [
              -115.028994,
              46.973159
            ],
            [
              -115.031651,
              46.971548
            ],
            [
              -115.047857,
              46.969533
            ],
            [
              -115.049538,
              46.970774
            ],
            [
              -115.057098,
              46.986758
            ],
            [
              -115.066223,
              46.996375
            ],
            [
              -115.071254,
              47.022083
            ],
            [
              -115.087806,
              47.045519
            ],
            [
              -115.098136,
              47.048897
            ],
            [
              -115.102681,
              47.047239
            ],
            [
              -115.107132,
              47.049041
            ],
            [
              -115.120917,
              47.061237
            ],
            [
              -115.136671,
              47.078276
            ],
            [
              -115.139515,
              47.08456
            ],
            [
              -115.140375,
              47.093013
            ],
            [
              -115.170436,
              47.106265
            ],
            [
              -115.172938,
              47.112881
            ],
            [
              -115.189451,
              47.131032
            ],
            [
              -115.200547,
              47.139154
            ],
            [
              -115.223246,
              47.148974
            ],
            [
              -115.243707,
              47.150347
            ],
            [
              -115.255146,
              47.162876
            ],
            [
              -115.255786,
              47.174725
            ],
            [
              -115.261885,
              47.181742
            ],
            [
              -115.286353,
              47.18327
            ],
            [
              -115.300504,
              47.188139
            ],
            [
              -115.300805,
              47.19393
            ],
            [
              -115.295986,
              47.205658
            ],
            [
              -115.29211,
              47.209861
            ],
            [
              -115.294785,
              47.220914
            ],
            [
              -115.298794,
              47.225245
            ],
            [
              -115.307239,
              47.229892
            ],
            [
              -115.311875,
              47.229673
            ],
            [
              -115.317124,
              47.233305
            ],
            [
              -115.324832,
              47.244841
            ],
            [
              -115.326903,
              47.255912
            ],
            [
              -115.339201,
              47.261623
            ],
            [
              -115.3593,
              47.259461
            ],
            [
              -115.36628,
              47.261485
            ],
            [
              -115.371825,
              47.265213
            ],
            [
              -115.410685,
              47.264228
            ],
            [
              -115.421645,
              47.271736
            ],
            [
              -115.423173,
              47.276222
            ],
            [
              -115.428359,
              47.278722
            ],
            [
              -115.443566,
              47.277309
            ],
            [
              -115.457077,
              47.277794
            ],
            [
              -115.470959,
              47.284873
            ],
            [
              -115.487314,
              47.286518
            ],
            [
              -115.51186,
              47.295219
            ],
            [
              -115.526751,
              47.303219
            ],
            [
              -115.531971,
              47.314121
            ],
            [
              -115.548658,
              47.332213
            ],
            [
              -115.551079,
              47.349856
            ],
            [
              -115.556318,
              47.353076
            ],
            [
              -115.564766,
              47.353476
            ],
            [
              -115.570887,
              47.356375
            ],
            [
              -115.578619,
              47.367007
            ],
            [
              -115.609492,
              47.380799
            ],
            [
              -115.617247,
              47.382521
            ],
            [
              -115.639186,
              47.378605
            ],
            [
              -115.644341,
              47.381826
            ],
            [
              -115.648479,
              47.390293
            ],
            [
              -115.657681,
              47.400651
            ],
            [
              -115.69057,
              47.415059
            ],
            [
              -115.71034,
              47.417784
            ],
            [
              -115.718934,
              47.420967
            ],
            [
              -115.72148,
              47.424469
            ],
            [
              -115.739287,
              47.425943
            ],
            [
              -115.759347,
              47.422233
            ],
            [
              -115.748284,
              47.441703
            ],
            [
              -115.728801,
              47.445159
            ],
            [
              -115.718247,
              47.45316
            ],
            [
              -115.69293,
              47.457237
            ],
            [
              -115.671188,
              47.45439
            ],
            [
              -115.648807,
              47.456228
            ],
            [
              -115.639512,
              47.460812
            ],
            [
              -115.631955,
              47.473409
            ],
            [
              -115.628695,
              47.479641
            ],
            [
              -115.62887367061101,
              47.4797042164009
            ],
            [
              -115.634684,
              47.48176
            ],
            [
              -115.655272,
              47.477944
            ],
            [
              -115.667058,
              47.487592
            ],
            [
              -115.686704,
              47.485596
            ],
            [
              -115.694106,
              47.498634
            ],
            [
              -115.708748,
              47.51264
            ],
            [
              -115.709989,
              47.523778
            ],
            [
              -115.698228,
              47.527786
            ],
            [
              -115.703365,
              47.534148
            ],
            [
              -115.717024,
              47.532693
            ],
            [
              -115.741371,
              47.538645
            ],
            [
              -115.747263,
              47.543197
            ],
            [
              -115.748536,
              47.549245
            ],
            [
              -115.746945,
              47.555293
            ],
            [
              -115.734674,
              47.567401
            ],
            [
              -115.721207,
              47.576323
            ],
            [
              -115.706473,
              47.577299
            ],
            [
              -115.689404,
              47.595402
            ],
            [
              -115.694284,
              47.62346
            ],
            [
              -115.708537,
              47.635356
            ],
            [
              -115.715193,
              47.63634
            ],
            [
              -115.72993,
              47.642442
            ],
            [
              -115.73627,
              47.654762
            ],
            [
              -115.726613,
              47.672093
            ],
            [
              -115.72377,
              47.696671
            ],
            [
              -115.730764,
              47.704426
            ],
            [
              -115.752349,
              47.716743
            ],
            [
              -115.758623,
              47.719041
            ],
            [
              -115.763424,
              47.717313
            ],
            [
              -115.77177,
              47.717412
            ],
            [
              -115.776219,
              47.719818
            ],
            [
              -115.783504,
              47.729305
            ],
            [
              -115.780441,
              47.743447
            ],
            [
              -115.797299,
              47.75752
            ],
            [
              -115.803917,
              47.75848
            ],
            [
              -115.824597,
              47.752154
            ],
            [
              -115.831755,
              47.755785
            ],
            [
              -115.835365,
              47.760957
            ],
            [
              -115.835069,
              47.77006
            ],
            [
              -115.837438,
              47.774846
            ],
            [
              -115.84044,
              47.780172
            ],
            [
              -115.847487,
              47.785227
            ],
            [
              -115.848509,
              47.809331
            ],
            [
              -115.845474,
              47.814967
            ],
            [
              -115.852291,
              47.827991
            ],
            [
              -115.870861,
              47.834939
            ],
            [
              -115.875262,
              47.843272
            ],
            [
              -115.881522,
              47.849672
            ],
            [
              -115.900934,
              47.843064
            ],
            [
              -115.906409,
              47.846261
            ],
            [
              -115.919291,
              47.857406
            ],
            [
              -115.939993,
              47.883153
            ],
            [
              -115.959946,
              47.898142
            ],
            [
              -115.965153,
              47.910131
            ],
            [
              -115.969076,
              47.914256
            ],
            [
              -115.982791,
              47.915994
            ],
            [
              -115.993678,
              47.926183
            ],
            [
              -115.995121,
              47.933827
            ],
            [
              -115.998236,
              47.938779
            ],
            [
              -116.007246,
              47.950087
            ],
            [
              -116.030751,
              47.973349
            ],
            [
              -116.03834,
              47.971318
            ],
            [
              -116.048421,
              47.97682
            ],
            [
              -116.04885,
              47.977186
            ],
            [
              -116.048852017752,
              47.977337411295295
            ],
            [
              -116.062132,
              47.995549
            ],
            [
              -116.103429,
              48.012277
            ],
            [
              -116.120163,
              48.033448
            ],
            [
              -116.161863,
              48.021577
            ],
            [
              -116.207126,
              48.046415
            ],
            [
              -116.233113,
              48.06889
            ],
            [
              -116.273131,
              48.061867
            ],
            [
              -116.329601,
              48.017722
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "16",
      "COUNTYFP": "053",
      "COUNTYNS": "00395662",
      "AFFGEOID": "0500000US16053",
      "GEOID": "16053",
      "NAME": "Jerome",
      "LSAD": "06",
      "ALAND": 1547386409,
      "AWATER": 13152108,
      "County_state": "Jerome,16"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -114.617401,
              42.850888
            ],
            [
              -114.616487,
              42.649298
            ],
            [
              -114.601678,
              42.641788
            ],
            [
              -114.539344,
              42.633309
            ],
            [
              -114.465709,
              42.599673
            ],
            [
              -114.440807,
              42.595868
            ],
            [
              -114.375217,
              42.598132
            ],
            [
              -114.350517,
              42.588562
            ],
            [
              -114.332917,
              42.572566
            ],
            [
              -114.307325,
              42.571755
            ],
            [
              -114.287207,
              42.561808
            ],
            [
              -114.244703,
              42.559244
            ],
            [
              -114.17947,
              42.532331
            ],
            [
              -114.172734,
              42.516688
            ],
            [
              -114.149969,
              42.497826
            ],
            [
              -114.13035,
              42.494906
            ],
            [
              -114.114358,
              42.506621
            ],
            [
              -114.09574,
              42.508116
            ],
            [
              -114.066939,
              42.522858
            ],
            [
              -114.042455,
              42.528765
            ],
            [
              -114.028949,
              42.524202
            ],
            [
              -114.000388,
              42.526373
            ],
            [
              -113.986118,
              42.533841
            ],
            [
              -113.931799,
              42.535243
            ],
            [
              -113.931687,
              42.765026
            ],
            [
              -114.049961,
              42.765006
            ],
            [
              -114.049849,
              42.808444
            ],
            [
              -114.345659,
              42.809055
            ],
            [
              -114.345652,
              42.823562
            ],
            [
              -114.365327,
              42.823611
            ],
            [
              -114.365366,
              42.85079
            ],
            [
              -114.594375,
              42.850901
            ],
            [
              -114.617401,
              42.850888
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "16",
      "COUNTYFP": "055",
      "COUNTYNS": "00395661",
      "AFFGEOID": "0500000US16055",
      "GEOID": "16055",
      "NAME": "Kootenai",
      "LSAD": "06",
      "ALAND": 3205258021,
      "AWATER": 185023431,
      "County_state": "Kootenai,16"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -117.042623,
              47.761223
            ],
            [
              -117.042657,
              47.760857
            ],
            [
              -117.042059,
              47.7451
            ],
            [
              -117.042135,
              47.7441
            ],
            [
              -117.041669370094,
              47.7359212711082
            ],
            [
              -117.041634,
              47.7353
            ],
            [
              -117.041678,
              47.72271
            ],
            [
              -117.041633,
              47.7064
            ],
            [
              -117.041532,
              47.683194
            ],
            [
              -117.041431,
              47.68
            ],
            [
              -117.041431,
              47.678185
            ],
            [
              -117.041431,
              47.67814
            ],
            [
              -117.041126241252,
              47.6235792669663
            ],
            [
              -117.04085,
              47.574124
            ],
            [
              -117.041174,
              47.55853
            ],
            [
              -117.041276,
              47.55821
            ],
            [
              -117.040745,
              47.532909
            ],
            [
              -117.040545,
              47.527562
            ],
            [
              -117.040514,
              47.522351
            ],
            [
              -117.039945,
              47.477823
            ],
            [
              -117.039971,
              47.463309
            ],
            [
              -117.039948,
              47.434885
            ],
            [
              -117.03995,
              47.412412
            ],
            [
              -117.039882,
              47.399085
            ],
            [
              -117.040176,
              47.3749
            ],
            [
              -117.04006933827101,
              47.366027858166596
            ],
            [
              -116.8108,
              47.366308
            ],
            [
              -116.810814,
              47.380658
            ],
            [
              -116.749616,
              47.385888
            ],
            [
              -116.735215,
              47.385917
            ],
            [
              -116.735395,
              47.371455
            ],
            [
              -116.629059,
              47.371438
            ],
            [
              -116.629108,
              47.400333
            ],
            [
              -116.586486,
              47.400276
            ],
            [
              -116.586549,
              47.414695
            ],
            [
              -116.43624,
              47.412724
            ],
            [
              -116.329406,
              47.414011
            ],
            [
              -116.329538,
              47.890393
            ],
            [
              -116.505104,
              47.890142
            ],
            [
              -116.504329,
              47.991386
            ],
            [
              -116.63302,
              47.991496
            ],
            [
              -116.633337,
              47.97723
            ],
            [
              -116.698282,
              47.97729
            ],
            [
              -117.04226408344101,
              47.977451511759796
            ],
            [
              -117.04236,
              47.966343
            ],
            [
              -117.04236689216701,
              47.958364752787794
            ],
            [
              -117.04247,
              47.839009
            ],
            [
              -117.041999,
              47.822399
            ],
            [
              -117.042064,
              47.77863
            ],
            [
              -117.042485,
              47.766525
            ],
            [
              -117.042521,
              47.764896
            ],
            [
              -117.042623,
              47.761223
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "16",
      "COUNTYFP": "039",
      "COUNTYNS": "00400252",
      "AFFGEOID": "0500000US16039",
      "GEOID": "16039",
      "NAME": "Elmore",
      "LSAD": "06",
      "ALAND": 7964192255,
      "AWATER": 68555510,
      "County_state": "Elmore,16"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -116.266207,
              43.112269
            ],
            [
              -116.26684,
              43.106099
            ],
            [
              -116.237384,
              43.094464
            ],
            [
              -116.205981,
              43.093232
            ],
            [
              -116.191672,
              43.082473
            ],
            [
              -116.206359,
              43.070686
            ],
            [
              -116.20174,
              43.04893
            ],
            [
              -116.167155,
              43.037808
            ],
            [
              -116.141584,
              43.034124
            ],
            [
              -116.145817,
              43.013416
            ],
            [
              -116.11554,
              43.010829
            ],
            [
              -116.090743,
              42.987883
            ],
            [
              -116.050082,
              42.985314
            ],
            [
              -116.032169,
              42.961878
            ],
            [
              -116.01106,
              42.949681
            ],
            [
              -115.964966,
              42.943673
            ],
            [
              -115.960913,
              42.953018
            ],
            [
              -115.97211,
              42.970485
            ],
            [
              -115.960999,
              42.989084
            ],
            [
              -115.935655,
              43.001733
            ],
            [
              -115.91744,
              42.995231
            ],
            [
              -115.917101,
              42.987405
            ],
            [
              -115.893469,
              42.982665
            ],
            [
              -115.878432,
              42.98891
            ],
            [
              -115.8647,
              42.974781
            ],
            [
              -115.846266,
              42.969258
            ],
            [
              -115.832122,
              42.972959
            ],
            [
              -115.791812,
              42.972348
            ],
            [
              -115.786071,
              42.960395
            ],
            [
              -115.79631,
              42.948712
            ],
            [
              -115.771505,
              42.938664
            ],
            [
              -115.740817,
              42.943003
            ],
            [
              -115.694474,
              42.939357
            ],
            [
              -115.670963,
              42.943477
            ],
            [
              -115.647668,
              42.953246
            ],
            [
              -115.622852,
              42.955698
            ],
            [
              -115.60443,
              42.937697
            ],
            [
              -115.55976,
              42.934898
            ],
            [
              -115.530388,
              42.942688
            ],
            [
              -115.500613,
              42.927229
            ],
            [
              -115.441522,
              42.931127
            ],
            [
              -115.441414,
              42.851105
            ],
            [
              -115.454233,
              42.851132
            ],
            [
              -115.454224,
              42.767936
            ],
            [
              -115.037697,
              42.768414
            ],
            [
              -115.037774,
              42.911864
            ],
            [
              -115.086852,
              42.91456
            ],
            [
              -115.086155,
              43.198064
            ],
            [
              -115.08359,
              43.370454
            ],
            [
              -115.083071,
              43.604399
            ],
            [
              -115.065499,
              43.599721
            ],
            [
              -115.043649,
              43.619064
            ],
            [
              -115.0339,
              43.61877
            ],
            [
              -115.036922,
              43.646802
            ],
            [
              -115.030074,
              43.66058
            ],
            [
              -115.036263,
              43.673038
            ],
            [
              -115.00884,
              43.696912
            ],
            [
              -115.009699,
              43.715585
            ],
            [
              -115.028851,
              43.727987
            ],
            [
              -115.04234,
              43.745582
            ],
            [
              -115.038188,
              43.767995
            ],
            [
              -115.019756,
              43.822956
            ],
            [
              -115.004164,
              43.828757
            ],
            [
              -115.000501,
              43.851244
            ],
            [
              -114.989258,
              43.858563
            ],
            [
              -114.973887,
              43.867358
            ],
            [
              -114.996687,
              43.914407
            ],
            [
              -114.971351,
              43.938308
            ],
            [
              -114.978878,
              43.94682
            ],
            [
              -114.990777,
              43.949723
            ],
            [
              -115.011955,
              43.949823
            ],
            [
              -115.038453,
              43.957675
            ],
            [
              -115.067428,
              43.940416
            ],
            [
              -115.092575,
              43.960563
            ],
            [
              -115.08547,
              43.976593
            ],
            [
              -115.10591,
              43.970248
            ],
            [
              -115.136209,
              43.983859
            ],
            [
              -115.147842,
              44.002842
            ],
            [
              -115.149124,
              44.041164
            ],
            [
              -115.164384,
              44.075854
            ],
            [
              -115.181931,
              44.090533
            ],
            [
              -115.230528,
              44.098766
            ],
            [
              -115.207273,
              44.083298
            ],
            [
              -115.19706,
              44.068993
            ],
            [
              -115.209853,
              44.056278
            ],
            [
              -115.21589,
              44.032599
            ],
            [
              -115.234241,
              44.00682
            ],
            [
              -115.251898,
              43.995724
            ],
            [
              -115.275942,
              43.960692
            ],
            [
              -115.286534,
              43.935879
            ],
            [
              -115.337786,
              43.921
            ],
            [
              -115.366238,
              43.924821
            ],
            [
              -115.425201,
              43.909395
            ],
            [
              -115.470985,
              43.894029
            ],
            [
              -115.500374,
              43.889902
            ],
            [
              -115.513114,
              43.881477
            ],
            [
              -115.525667,
              43.857316
            ],
            [
              -115.539093,
              43.84741
            ],
            [
              -115.530066,
              43.815973
            ],
            [
              -115.547538,
              43.792667
            ],
            [
              -115.592796,
              43.798289
            ],
            [
              -115.613436,
              43.782831
            ],
            [
              -115.616603,
              43.763474
            ],
            [
              -115.633945,
              43.745652
            ],
            [
              -115.625615,
              43.728255
            ],
            [
              -115.645802,
              43.70026
            ],
            [
              -115.664538,
              43.69733
            ],
            [
              -115.697508,
              43.665373
            ],
            [
              -115.726387,
              43.667988
            ],
            [
              -115.730542,
              43.653722
            ],
            [
              -115.754319,
              43.643019
            ],
            [
              -115.770019,
              43.650272
            ],
            [
              -115.797108,
              43.638608
            ],
            [
              -115.830872,
              43.60525
            ],
            [
              -115.847213,
              43.607992
            ],
            [
              -115.875927,
              43.591265
            ],
            [
              -115.897034,
              43.598843
            ],
            [
              -115.912357,
              43.592097
            ],
            [
              -115.929883,
              43.595567
            ],
            [
              -115.935128,
              43.608236
            ],
            [
              -115.955784,
              43.601667
            ],
            [
              -115.956502,
              43.589547
            ],
            [
              -115.975719,
              43.591438
            ],
            [
              -115.974881,
              43.36502
            ],
            [
              -115.978651,
              43.113432
            ],
            [
              -116.266207,
              43.112269
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "16",
      "COUNTYFP": "043",
      "COUNTYNS": "00395408",
      "AFFGEOID": "0500000US16043",
      "GEOID": "16043",
      "NAME": "Fremont",
      "LSAD": "06",
      "ALAND": 4827632203,
      "AWATER": 83441579,
      "County_state": "Fremont,16"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -112.155935,
              44.05778
            ],
            [
              -112.15637,
              43.971247
            ],
            [
              -111.977016,
              43.970909
            ],
            [
              -111.976849,
              43.927385
            ],
            [
              -111.837308,
              43.927158
            ],
            [
              -111.83759,
              43.898261
            ],
            [
              -111.698046,
              43.897901
            ],
            [
              -111.697826,
              43.88344
            ],
            [
              -111.558833,
              43.883305
            ],
            [
              -111.558776,
              43.901052
            ],
            [
              -111.531732,
              43.919254
            ],
            [
              -111.501232,
              43.929206
            ],
            [
              -111.478723,
              43.927886
            ],
            [
              -111.467182,
              43.916533
            ],
            [
              -111.42522,
              43.910358
            ],
            [
              -111.398781,
              43.92289
            ],
            [
              -111.354543,
              43.948104
            ],
            [
              -111.335902,
              43.940107
            ],
            [
              -111.327975,
              43.925409
            ],
            [
              -111.309685,
              43.919554
            ],
            [
              -111.280123,
              43.926774
            ],
            [
              -111.271279,
              43.938802
            ],
            [
              -111.254069,
              43.938607
            ],
            [
              -111.237362,
              43.948646
            ],
            [
              -111.187256,
              43.932114
            ],
            [
              -111.162838,
              43.947012
            ],
            [
              -111.113677,
              43.968572
            ],
            [
              -111.102884,
              43.968443
            ],
            [
              -111.05854,
              43.984307
            ],
            [
              -111.04706391450401,
              43.9834606121932
            ],
            [
              -111.047349,
              43.999921
            ],
            [
              -111.049077,
              44.020072
            ],
            [
              -111.048751,
              44.060403
            ],
            [
              -111.048751,
              44.060838
            ],
            [
              -111.048633,
              44.062903
            ],
            [
              -111.048452,
              44.114831
            ],
            [
              -111.049119,
              44.124923
            ],
            [
              -111.049695,
              44.353626
            ],
            [
              -111.049148,
              44.374925
            ],
            [
              -111.049216,
              44.435811
            ],
            [
              -111.049194,
              44.438058
            ],
            [
              -111.048974,
              44.474072
            ],
            [
              -111.062729,
              44.476073
            ],
            [
              -111.122654,
              44.493659
            ],
            [
              -111.131379,
              44.499925
            ],
            [
              -111.139455,
              44.517112
            ],
            [
              -111.143557,
              44.535732
            ],
            [
              -111.15959,
              44.546376
            ],
            [
              -111.166892,
              44.54722
            ],
            [
              -111.175747,
              44.552219
            ],
            [
              -111.182551,
              44.566874
            ],
            [
              -111.189617,
              44.571062
            ],
            [
              -111.201459,
              44.575696
            ],
            [
              -111.225208,
              44.581006
            ],
            [
              -111.23018,
              44.587025
            ],
            [
              -111.231227,
              44.606915
            ],
            [
              -111.224161,
              44.623402
            ],
            [
              -111.25268,
              44.651092
            ],
            [
              -111.262839,
              44.649658
            ],
            [
              -111.276956,
              44.655626
            ],
            [
              -111.26875,
              44.668279
            ],
            [
              -111.29626,
              44.702271
            ],
            [
              -111.323669,
              44.724474
            ],
            [
              -111.341351,
              44.7293
            ],
            [
              -111.348184,
              44.725459
            ],
            [
              -111.355768,
              44.727602
            ],
            [
              -111.366723,
              44.738361
            ],
            [
              -111.36627,
              44.742234
            ],
            [
              -111.37476,
              44.750295
            ],
            [
              -111.37704749842301,
              44.7513741097978
            ],
            [
              -111.385005,
              44.755128
            ],
            [
              -111.393854,
              44.752549
            ],
            [
              -111.397805,
              44.746738
            ],
            [
              -111.394459,
              44.744578
            ],
            [
              -111.398575,
              44.723343
            ],
            [
              -111.414271,
              44.710741
            ],
            [
              -111.424214,
              44.714024
            ],
            [
              -111.429604,
              44.720149
            ],
            [
              -111.438793,
              44.720546
            ],
            [
              -111.482206819713,
              44.7086946688743
            ],
            [
              -111.486019,
              44.707654
            ],
            [
              -111.489339,
              44.704946
            ],
            [
              -111.490228,
              44.700221
            ],
            [
              -111.484898,
              44.687578
            ],
            [
              -111.47798,
              44.682393
            ],
            [
              -111.468833,
              44.679335
            ],
            [
              -111.473178,
              44.665479
            ],
            [
              -111.50494,
              44.635746
            ],
            [
              -111.521688,
              44.613371
            ],
            [
              -111.525764,
              44.604883
            ],
            [
              -111.524213,
              44.595585
            ],
            [
              -111.519126,
              44.582916
            ],
            [
              -111.492024,
              44.56081
            ],
            [
              -111.469185,
              44.552044
            ],
            [
              -111.467736,
              44.544521
            ],
            [
              -111.471682,
              44.540824
            ],
            [
              -111.500792,
              44.540062
            ],
            [
              -111.518095,
              44.544177
            ],
            [
              -111.524006,
              44.548385
            ],
            [
              -111.546637,
              44.557099
            ],
            [
              -111.556577,
              44.554495
            ],
            [
              -111.562814,
              44.555209
            ],
            [
              -111.585763,
              44.562843
            ],
            [
              -111.591768,
              44.561502
            ],
            [
              -111.601249,
              44.55421
            ],
            [
              -111.614405,
              44.548991
            ],
            [
              -111.61729585677601,
              44.5494589791222
            ],
            [
              -111.617437,
              44.401254
            ],
            [
              -111.791624,
              44.40161
            ],
            [
              -111.791541,
              44.317021
            ],
            [
              -111.852276,
              44.316996
            ],
            [
              -111.857871,
              44.231422
            ],
            [
              -111.857851,
              44.187759
            ],
            [
              -111.913744,
              44.18779
            ],
            [
              -111.913601,
              44.144337
            ],
            [
              -112.033664,
              44.144049
            ],
            [
              -112.034317,
              44.057358
            ],
            [
              -112.155935,
              44.05778
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "16",
      "COUNTYFP": "017",
      "COUNTYNS": "00395158",
      "AFFGEOID": "0500000US16017",
      "GEOID": "16017",
      "NAME": "Bonner",
      "LSAD": "06",
      "ALAND": 4488460265,
      "AWATER": 479480478,
      "County_state": "Bonner,16"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -117.04167682656801,
              48.045464271600395
            ],
            [
              -117.04226408344101,
              47.977451511759796
            ],
            [
              -116.698282,
              47.97729
            ],
            [
              -116.633337,
              47.97723
            ],
            [
              -116.63302,
              47.991496
            ],
            [
              -116.504329,
              47.991386
            ],
            [
              -116.505104,
              47.890142
            ],
            [
              -116.329538,
              47.890393
            ],
            [
              -116.329601,
              48.017722
            ],
            [
              -116.273131,
              48.061867
            ],
            [
              -116.233113,
              48.06889
            ],
            [
              -116.207126,
              48.046415
            ],
            [
              -116.161863,
              48.021577
            ],
            [
              -116.120163,
              48.033448
            ],
            [
              -116.103429,
              48.012277
            ],
            [
              -116.062132,
              47.995549
            ],
            [
              -116.048852017752,
              47.977337411295295
            ],
            [
              -116.049153,
              47.999923
            ],
            [
              -116.048739,
              48.060093
            ],
            [
              -116.04932,
              48.066644
            ],
            [
              -116.049415,
              48.07722
            ],
            [
              -116.049296939457,
              48.088395929540695
            ],
            [
              -116.048911,
              48.12493
            ],
            [
              -116.049773718343,
              48.216117548345196
            ],
            [
              -116.049977,
              48.237604
            ],
            [
              -116.049353,
              48.249936
            ],
            [
              -116.049735,
              48.274668
            ],
            [
              -116.048948,
              48.309847
            ],
            [
              -116.050003,
              48.413492
            ],
            [
              -116.049155,
              48.481247
            ],
            [
              -116.049156520649,
              48.502042481758096
            ],
            [
              -116.784878,
              48.500472
            ],
            [
              -116.78484,
              48.674197
            ],
            [
              -116.786625,
              48.674195
            ],
            [
              -116.785542,
              48.686544
            ],
            [
              -116.785174,
              48.847402
            ],
            [
              -117.03238462208,
              48.84666529306679
            ],
            [
              -117.033335,
              48.749921
            ],
            [
              -117.033671,
              48.656902
            ],
            [
              -117.034358,
              48.628523
            ],
            [
              -117.034499,
              48.620769
            ],
            [
              -117.035425,
              48.499914
            ],
            [
              -117.035285,
              48.430113
            ],
            [
              -117.035285,
              48.429816
            ],
            [
              -117.035254,
              48.423144
            ],
            [
              -117.035289,
              48.422732
            ],
            [
              -117.035178,
              48.371221
            ],
            [
              -117.035178,
              48.370878
            ],
            [
              -117.038602,
              48.207939
            ],
            [
              -117.039599,
              48.184387
            ],
            [
              -117.039615,
              48.184015
            ],
            [
              -117.039582,
              48.181124
            ],
            [
              -117.039582,
              48.180853
            ],
            [
              -117.039583,
              48.180313
            ],
            [
              -117.039618,
              48.178142
            ],
            [
              -117.039413,
              48.17725
            ],
            [
              -117.039552,
              48.17396
            ],
            [
              -117.041107,
              48.124904
            ],
            [
              -117.041401,
              48.0855
            ],
            [
              -117.041676,
              48.04556
            ],
            [
              -117.04167682656801,
              48.045464271600395
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "16",
      "COUNTYFP": "025",
      "COUNTYNS": "00399408",
      "AFFGEOID": "0500000US16025",
      "GEOID": "16025",
      "NAME": "Camas",
      "LSAD": "06",
      "ALAND": 2782253889,
      "AWATER": 11548052,
      "County_state": "Camas,16"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -115.08359,
              43.370454
            ],
            [
              -115.086155,
              43.198064
            ],
            [
              -114.594605,
              43.198345
            ],
            [
              -114.374962,
              43.19964
            ],
            [
              -114.374758,
              43.257514
            ],
            [
              -114.394849,
              43.257561
            ],
            [
              -114.394606,
              43.326467
            ],
            [
              -114.432368,
              43.33416
            ],
            [
              -114.463104,
              43.325062
            ],
            [
              -114.480211,
              43.332422
            ],
            [
              -114.503107,
              43.329105
            ],
            [
              -114.534488,
              43.333383
            ],
            [
              -114.526944,
              43.368602
            ],
            [
              -114.526511,
              43.398365
            ],
            [
              -114.546383,
              43.398404
            ],
            [
              -114.546546,
              43.435445
            ],
            [
              -114.514497,
              43.4576
            ],
            [
              -114.506087,
              43.477904
            ],
            [
              -114.529433,
              43.490722
            ],
            [
              -114.561881,
              43.498907
            ],
            [
              -114.595698,
              43.531235
            ],
            [
              -114.586743,
              43.558826
            ],
            [
              -114.636134,
              43.573608
            ],
            [
              -114.651649,
              43.592996
            ],
            [
              -114.682355,
              43.590349
            ],
            [
              -114.679081,
              43.616262
            ],
            [
              -114.668697,
              43.629489
            ],
            [
              -114.662311,
              43.660705
            ],
            [
              -114.67915,
              43.669946
            ],
            [
              -114.688032,
              43.686059
            ],
            [
              -114.676211,
              43.698058
            ],
            [
              -114.680102,
              43.724105
            ],
            [
              -114.666202,
              43.737962
            ],
            [
              -114.690171,
              43.75038
            ],
            [
              -114.692627,
              43.78426
            ],
            [
              -114.70205,
              43.805413
            ],
            [
              -114.714648,
              43.814669
            ],
            [
              -114.740374,
              43.806837
            ],
            [
              -114.760227,
              43.791641
            ],
            [
              -114.800474,
              43.802732
            ],
            [
              -114.828909,
              43.788002
            ],
            [
              -114.854389,
              43.791655
            ],
            [
              -114.847781,
              43.816381
            ],
            [
              -114.8858,
              43.824279
            ],
            [
              -114.93103,
              43.840038
            ],
            [
              -114.951885,
              43.854275
            ],
            [
              -114.969282,
              43.848585
            ],
            [
              -114.989258,
              43.858563
            ],
            [
              -115.000501,
              43.851244
            ],
            [
              -115.004164,
              43.828757
            ],
            [
              -115.019756,
              43.822956
            ],
            [
              -115.038188,
              43.767995
            ],
            [
              -115.04234,
              43.745582
            ],
            [
              -115.028851,
              43.727987
            ],
            [
              -115.009699,
              43.715585
            ],
            [
              -115.00884,
              43.696912
            ],
            [
              -115.036263,
              43.673038
            ],
            [
              -115.030074,
              43.66058
            ],
            [
              -115.036922,
              43.646802
            ],
            [
              -115.0339,
              43.61877
            ],
            [
              -115.043649,
              43.619064
            ],
            [
              -115.065499,
              43.599721
            ],
            [
              -115.083071,
              43.604399
            ],
            [
              -115.08359,
              43.370454
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "16",
      "COUNTYFP": "067",
      "COUNTYNS": "00394807",
      "AFFGEOID": "0500000US16067",
      "GEOID": "16067",
      "NAME": "Minidoka",
      "LSAD": "06",
      "ALAND": 1960659281,
      "AWATER": 13928460,
      "County_state": "Minidoka,16"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -113.931687,
              42.765026
            ],
            [
              -113.931799,
              42.535243
            ],
            [
              -113.889787,
              42.549375
            ],
            [
              -113.876322,
              42.54952
            ],
            [
              -113.840385,
              42.535978
            ],
            [
              -113.829088,
              42.535546
            ],
            [
              -113.804043,
              42.550458
            ],
            [
              -113.780157,
              42.556902
            ],
            [
              -113.763556,
              42.547209
            ],
            [
              -113.758328,
              42.534076
            ],
            [
              -113.739278,
              42.522854
            ],
            [
              -113.699044,
              42.51919
            ],
            [
              -113.654149,
              42.536155
            ],
            [
              -113.644167,
              42.54549
            ],
            [
              -113.621329,
              42.586529
            ],
            [
              -113.607095,
              42.596291
            ],
            [
              -113.584486,
              42.625155
            ],
            [
              -113.575261,
              42.645575
            ],
            [
              -113.557193,
              42.656799
            ],
            [
              -113.493681,
              42.673594
            ],
            [
              -113.472177,
              42.669352
            ],
            [
              -113.472155,
              42.849201
            ],
            [
              -113.413138,
              42.849169
            ],
            [
              -113.413867,
              43.199859
            ],
            [
              -113.714642,
              43.199783
            ],
            [
              -113.714044,
              42.849734
            ],
            [
              -113.76382,
              42.849823
            ],
            [
              -113.763862,
              42.764508
            ],
            [
              -113.931687,
              42.765026
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "16",
      "COUNTYFP": "005",
      "COUNTYNS": "00395091",
      "AFFGEOID": "0500000US16005",
      "GEOID": "16005",
      "NAME": "Bannock",
      "LSAD": "06",
      "ALAND": 2881317883,
      "AWATER": 92094662,
      "County_state": "Bannock,16"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -112.751066,
              42.963382
            ],
            [
              -112.750562,
              42.951528
            ],
            [
              -112.732083,
              42.940087
            ],
            [
              -112.747491,
              42.912735
            ],
            [
              -112.737496,
              42.905408
            ],
            [
              -112.727384,
              42.915831
            ],
            [
              -112.702152,
              42.925953
            ],
            [
              -112.702152,
              42.935337
            ],
            [
              -112.643896,
              42.94196
            ],
            [
              -112.61952,
              42.960961
            ],
            [
              -112.60255,
              42.959556
            ],
            [
              -112.58905,
              42.973603
            ],
            [
              -112.559335,
              42.961004
            ],
            [
              -112.520638,
              42.91275
            ],
            [
              -112.516739,
              42.790824
            ],
            [
              -112.497227,
              42.790727
            ],
            [
              -112.49691,
              42.733013
            ],
            [
              -112.457753,
              42.732965
            ],
            [
              -112.457519,
              42.696761
            ],
            [
              -112.437936,
              42.696782
            ],
            [
              -112.437514,
              42.675077
            ],
            [
              -112.418027,
              42.675126
            ],
            [
              -112.418042,
              42.646234
            ],
            [
              -112.37967,
              42.646142
            ],
            [
              -112.389418,
              42.624382
            ],
            [
              -112.386177,
              42.571779
            ],
            [
              -112.415556,
              42.568187
            ],
            [
              -112.419825,
              42.503038
            ],
            [
              -112.384498,
              42.503055
            ],
            [
              -112.380208,
              42.481475
            ],
            [
              -112.365464,
              42.467002
            ],
            [
              -112.345984,
              42.466945
            ],
            [
              -112.342931,
              42.447667
            ],
            [
              -112.297244,
              42.453874
            ],
            [
              -112.290785,
              42.427114
            ],
            [
              -112.293857,
              42.386723
            ],
            [
              -112.304747,
              42.379694
            ],
            [
              -112.328868,
              42.347207
            ],
            [
              -112.327102,
              42.323836
            ],
            [
              -112.305058,
              42.326497
            ],
            [
              -112.291247,
              42.316825
            ],
            [
              -112.275651,
              42.318628
            ],
            [
              -112.259281,
              42.350721
            ],
            [
              -112.190656,
              42.350839
            ],
            [
              -112.185897,
              42.337392
            ],
            [
              -112.160956,
              42.308476
            ],
            [
              -112.156013,
              42.294473
            ],
            [
              -112.137805,
              42.295749
            ],
            [
              -112.126194,
              42.285229
            ],
            [
              -111.983848,
              42.28555
            ],
            [
              -111.983916,
              42.256486
            ],
            [
              -111.896728,
              42.256276
            ],
            [
              -111.89532,
              42.416193
            ],
            [
              -111.875636,
              42.416333
            ],
            [
              -111.875475,
              42.4453
            ],
            [
              -111.885188,
              42.445233
            ],
            [
              -111.885372,
              42.488808
            ],
            [
              -111.895149,
              42.488817
            ],
            [
              -111.896726,
              42.524125
            ],
            [
              -111.916298,
              42.524277
            ],
            [
              -111.916927,
              42.617264
            ],
            [
              -111.921924,
              42.632008
            ],
            [
              -111.946466,
              42.63554
            ],
            [
              -111.946381,
              42.674685
            ],
            [
              -111.966081,
              42.674689
            ],
            [
              -111.956899,
              42.690539
            ],
            [
              -111.967767,
              42.696517
            ],
            [
              -111.969449,
              42.718039
            ],
            [
              -112.057274,
              42.718033
            ],
            [
              -112.08493,
              42.698165
            ],
            [
              -112.10588,
              42.699203
            ],
            [
              -112.114176,
              42.72595
            ],
            [
              -112.13739,
              42.751265
            ],
            [
              -112.141034,
              42.763093
            ],
            [
              -112.145089,
              42.790664
            ],
            [
              -112.138892,
              42.810391
            ],
            [
              -112.126501,
              42.810461
            ],
            [
              -112.120335,
              42.831008
            ],
            [
              -112.135038,
              42.849072
            ],
            [
              -112.136244,
              42.879651
            ],
            [
              -112.149835,
              42.895558
            ],
            [
              -112.133913,
              42.912678
            ],
            [
              -112.143623,
              42.91766
            ],
            [
              -112.127424,
              42.944498
            ],
            [
              -112.102742,
              42.951641
            ],
            [
              -112.082053,
              42.950425
            ],
            [
              -112.088706,
              42.973477
            ],
            [
              -112.063849,
              42.97988
            ],
            [
              -112.052883,
              42.991044
            ],
            [
              -112.063023,
              43.022364
            ],
            [
              -112.656552,
              43.021944
            ],
            [
              -112.664762,
              43.015624
            ],
            [
              -112.678364,
              43.02173
            ],
            [
              -112.680409,
              43.012267
            ],
            [
              -112.707547,
              42.990549
            ],
            [
              -112.741566,
              42.988858
            ],
            [
              -112.735659,
              42.973234
            ],
            [
              -112.751066,
              42.963382
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "16",
      "COUNTYFP": "081",
      "COUNTYNS": "00399297",
      "AFFGEOID": "0500000US16081",
      "GEOID": "16081",
      "NAME": "Teton",
      "LSAD": "06",
      "ALAND": 1163139958,
      "AWATER": 2868476,
      "County_state": "Teton,16"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -111.400797,
              43.882826
            ],
            [
              -111.399765,
              43.621976
            ],
            [
              -111.243839,
              43.621853
            ],
            [
              -111.226081,
              43.606378
            ],
            [
              -111.22635,
              43.590155
            ],
            [
              -111.207561,
              43.543857
            ],
            [
              -111.198841,
              43.539731
            ],
            [
              -111.171973,
              43.547043
            ],
            [
              -111.143501,
              43.546657
            ],
            [
              -111.126092,
              43.531937
            ],
            [
              -111.085901,
              43.521426
            ],
            [
              -111.07321,
              43.527391
            ],
            [
              -111.055831,
              43.519504
            ],
            [
              -111.045204743411,
              43.501051850722895
            ],
            [
              -111.045464910528,
              43.5830897814638
            ],
            [
              -111.045706,
              43.659112
            ],
            [
              -111.04588,
              43.681033
            ],
            [
              -111.046118,
              43.684902
            ],
            [
              -111.046051,
              43.685812
            ],
            [
              -111.04611,
              43.687848
            ],
            [
              -111.046421,
              43.722059
            ],
            [
              -111.046435,
              43.726545
            ],
            [
              -111.04634,
              43.726957
            ],
            [
              -111.046715,
              43.815832
            ],
            [
              -111.046515,
              43.908376
            ],
            [
              -111.046917,
              43.974978
            ],
            [
              -111.04706391450401,
              43.9834606121932
            ],
            [
              -111.05854,
              43.984307
            ],
            [
              -111.102884,
              43.968443
            ],
            [
              -111.113677,
              43.968572
            ],
            [
              -111.162838,
              43.947012
            ],
            [
              -111.187256,
              43.932114
            ],
            [
              -111.237362,
              43.948646
            ],
            [
              -111.254069,
              43.938607
            ],
            [
              -111.271279,
              43.938802
            ],
            [
              -111.280123,
              43.926774
            ],
            [
              -111.309685,
              43.919554
            ],
            [
              -111.327975,
              43.925409
            ],
            [
              -111.335902,
              43.940107
            ],
            [
              -111.354543,
              43.948104
            ],
            [
              -111.398781,
              43.92289
            ],
            [
              -111.398751,
              43.886426
            ],
            [
              -111.400797,
              43.882826
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "16",
      "COUNTYFP": "075",
      "COUNTYNS": "00394896",
      "AFFGEOID": "0500000US16075",
      "GEOID": "16075",
      "NAME": "Payette",
      "LSAD": "06",
      "ALAND": 1053857311,
      "AWATER": 8870733,
      "County_state": "Payette,16"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -116.976024,
              43.895548
            ],
            [
              -116.97967445865001,
              43.8801286658834
            ],
            [
              -116.954422,
              43.880154
            ],
            [
              -116.95452,
              43.836487
            ],
            [
              -116.913963,
              43.8362
            ],
            [
              -116.913796,
              43.821721
            ],
            [
              -116.894086,
              43.821763
            ],
            [
              -116.893746,
              43.807381
            ],
            [
              -116.853132,
              43.807387
            ],
            [
              -116.853154,
              43.79271
            ],
            [
              -116.793189,
              43.792687
            ],
            [
              -116.793092,
              43.799856
            ],
            [
              -116.753114,
              43.799926
            ],
            [
              -116.75314,
              43.807194
            ],
            [
              -116.712672,
              43.807161
            ],
            [
              -116.712501,
              43.981326
            ],
            [
              -116.572224,
              43.980485
            ],
            [
              -116.572142,
              44.009214
            ],
            [
              -116.53217,
              44.008843
            ],
            [
              -116.532117,
              44.066024
            ],
            [
              -116.452551,
              44.065414
            ],
            [
              -116.452068,
              44.151465
            ],
            [
              -116.89713301012901,
              44.1525971517028
            ],
            [
              -116.927688,
              44.109438
            ],
            [
              -116.928306,
              44.107326
            ],
            [
              -116.933704,
              44.100039
            ],
            [
              -116.937835,
              44.096943
            ],
            [
              -116.943132,
              44.09406
            ],
            [
              -116.957009,
              44.091743
            ],
            [
              -116.967203,
              44.090936
            ],
            [
              -116.974253,
              44.088295
            ],
            [
              -116.977351,
              44.085364
            ],
            [
              -116.974016,
              44.053663
            ],
            [
              -116.973185,
              44.049425
            ],
            [
              -116.972504,
              44.048771
            ],
            [
              -116.956246,
              44.042888
            ],
            [
              -116.943361,
              44.035645
            ],
            [
              -116.937342,
              44.029376
            ],
            [
              -116.934727,
              44.023806
            ],
            [
              -116.934485,
              44.021249
            ],
            [
              -116.936765,
              44.010608
            ],
            [
              -116.943159,
              44.001536
            ],
            [
              -116.936384,
              43.991574
            ],
            [
              -116.938195,
              43.98459
            ],
            [
              -116.959871,
              43.982925
            ],
            [
              -116.968743,
              43.977477
            ],
            [
              -116.975856,
              43.973913
            ],
            [
              -116.969842,
              43.967588
            ],
            [
              -116.963552,
              43.968457
            ],
            [
              -116.959206,
              43.96636
            ],
            [
              -116.959808,
              43.963815
            ],
            [
              -116.961268,
              43.959192
            ],
            [
              -116.961482,
              43.952663
            ],
            [
              -116.96518,
              43.943741
            ],
            [
              -116.969004,
              43.939207
            ],
            [
              -116.967641,
              43.934792
            ],
            [
              -116.959993,
              43.93065
            ],
            [
              -116.960103,
              43.921229
            ],
            [
              -116.965828,
              43.917141
            ],
            [
              -116.97958,
              43.915253
            ],
            [
              -116.977332,
              43.905812
            ],
            [
              -116.976429,
              43.901293
            ],
            [
              -116.976024,
              43.895548
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "16",
      "COUNTYFP": "033",
      "COUNTYNS": "00399755",
      "AFFGEOID": "0500000US16033",
      "GEOID": "16033",
      "NAME": "Clark",
      "LSAD": "06",
      "ALAND": 4566524491,
      "AWATER": 2476454,
      "County_state": "Clark,16"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -112.995091,
              44.234126
            ],
            [
              -112.994323,
              43.972362
            ],
            [
              -112.695242,
              43.971941
            ],
            [
              -112.694744,
              44.058464
            ],
            [
              -112.155935,
              44.05778
            ],
            [
              -112.034317,
              44.057358
            ],
            [
              -112.033664,
              44.144049
            ],
            [
              -111.913601,
              44.144337
            ],
            [
              -111.913744,
              44.18779
            ],
            [
              -111.857851,
              44.187759
            ],
            [
              -111.857871,
              44.231422
            ],
            [
              -111.852276,
              44.316996
            ],
            [
              -111.791541,
              44.317021
            ],
            [
              -111.791624,
              44.40161
            ],
            [
              -111.617437,
              44.401254
            ],
            [
              -111.61729585677601,
              44.5494589791222
            ],
            [
              -111.681571,
              44.559864
            ],
            [
              -111.704218,
              44.560205
            ],
            [
              -111.709553,
              44.550206
            ],
            [
              -111.715474,
              44.543543
            ],
            [
              -111.737191,
              44.54306
            ],
            [
              -111.746401,
              44.540766
            ],
            [
              -111.758966,
              44.533766
            ],
            [
              -111.761904,
              44.529841
            ],
            [
              -111.806512,
              44.516264
            ],
            [
              -111.807914,
              44.511716
            ],
            [
              -111.821488,
              44.509286
            ],
            [
              -111.842542,
              44.526069
            ],
            [
              -111.849293,
              44.539837
            ],
            [
              -111.870504,
              44.564033
            ],
            [
              -111.887852,
              44.563413
            ],
            [
              -111.903566,
              44.55723
            ],
            [
              -111.947941,
              44.556776
            ],
            [
              -111.951522,
              44.550062
            ],
            [
              -111.980833,
              44.536682
            ],
            [
              -111.995231,
              44.535444
            ],
            [
              -112.01385,
              44.542348
            ],
            [
              -112.032707,
              44.546642
            ],
            [
              -112.035025,
              44.542691
            ],
            [
              -112.034133,
              44.537716
            ],
            [
              -112.036943,
              44.530323
            ],
            [
              -112.053434,
              44.535089
            ],
            [
              -112.069011,
              44.537104
            ],
            [
              -112.093304,
              44.530002
            ],
            [
              -112.096299,
              44.523212
            ],
            [
              -112.101564,
              44.520847
            ],
            [
              -112.106755,
              44.520829
            ],
            [
              -112.125101,
              44.528527
            ],
            [
              -112.129078,
              44.5363
            ],
            [
              -112.136454,
              44.539911
            ],
            [
              -112.164597,
              44.541666
            ],
            [
              -112.179703,
              44.533021
            ],
            [
              -112.183937,
              44.533067
            ],
            [
              -112.221698,
              44.543519
            ],
            [
              -112.229477,
              44.549494
            ],
            [
              -112.226841,
              44.555239
            ],
            [
              -112.230117,
              44.562759
            ],
            [
              -112.242785,
              44.568091
            ],
            [
              -112.258665,
              44.569516
            ],
            [
              -112.286187,
              44.568472
            ],
            [
              -112.307642,
              44.557651
            ],
            [
              -112.312899,
              44.553536
            ],
            [
              -112.315047,
              44.550049
            ],
            [
              -112.315008,
              44.5419
            ],
            [
              -112.319198,
              44.53911
            ],
            [
              -112.348794,
              44.538691
            ],
            [
              -112.35421,
              44.535638
            ],
            [
              -112.358917,
              44.528847
            ],
            [
              -112.3566,
              44.493127
            ],
            [
              -112.358926,
              44.48628
            ],
            [
              -112.368764,
              44.467153
            ],
            [
              -112.387389,
              44.448058
            ],
            [
              -112.435342,
              44.462216
            ],
            [
              -112.460347,
              44.47571
            ],
            [
              -112.473207,
              44.480027
            ],
            [
              -112.50031,
              44.463051
            ],
            [
              -112.511713,
              44.466445
            ],
            [
              -112.512036,
              44.47042
            ],
            [
              -112.518871,
              44.475784
            ],
            [
              -112.541989,
              44.483971
            ],
            [
              -112.550557,
              44.484928
            ],
            [
              -112.573513,
              44.480983
            ],
            [
              -112.584197,
              44.481368
            ],
            [
              -112.601863,
              44.491015
            ],
            [
              -112.660696,
              44.485756
            ],
            [
              -112.664485,
              44.48645
            ],
            [
              -112.671169,
              44.491265
            ],
            [
              -112.707815,
              44.503023
            ],
            [
              -112.71911,
              44.504344
            ],
            [
              -112.735084,
              44.499159
            ],
            [
              -112.749011,
              44.491233
            ],
            [
              -112.781294,
              44.484888
            ],
            [
              -112.797863,
              44.466112
            ],
            [
              -112.828191,
              44.442472
            ],
            [
              -112.836034,
              44.422653
            ],
            [
              -112.821896,
              44.407436
            ],
            [
              -112.812608,
              44.392275
            ],
            [
              -112.81324,
              44.378103
            ],
            [
              -112.814350040863,
              44.3770070471164
            ],
            [
              -112.813792,
              44.230281
            ],
            [
              -112.940161,
              44.234181
            ],
            [
              -112.995091,
              44.234126
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "16",
      "COUNTYFP": "077",
      "COUNTYNS": "00394951",
      "AFFGEOID": "0500000US16077",
      "GEOID": "16077",
      "NAME": "Power",
      "LSAD": "06",
      "ALAND": 3635879902,
      "AWATER": 99218111,
      "County_state": "Power,16"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -113.243478,
              43.110946
            ],
            [
              -113.24372,
              42.848207
            ],
            [
              -113.236943,
              42.762212
            ],
            [
              -113.178877,
              42.762355
            ],
            [
              -113.178633,
              42.67546
            ],
            [
              -113.236841,
              42.675308
            ],
            [
              -113.236968,
              42.625254
            ],
            [
              -113.195692,
              42.633887
            ],
            [
              -113.175182,
              42.627273
            ],
            [
              -113.175678,
              42.589464
            ],
            [
              -113.002792,
              42.588003
            ],
            [
              -113.001673,
              42.327431
            ],
            [
              -112.999965,
              42.327427
            ],
            [
              -112.654028,
              42.328872
            ],
            [
              -112.653989,
              42.416169
            ],
            [
              -112.497849,
              42.415998
            ],
            [
              -112.498139,
              42.502967
            ],
            [
              -112.419825,
              42.503038
            ],
            [
              -112.415556,
              42.568187
            ],
            [
              -112.386177,
              42.571779
            ],
            [
              -112.389418,
              42.624382
            ],
            [
              -112.37967,
              42.646142
            ],
            [
              -112.418042,
              42.646234
            ],
            [
              -112.418027,
              42.675126
            ],
            [
              -112.437514,
              42.675077
            ],
            [
              -112.437936,
              42.696782
            ],
            [
              -112.457519,
              42.696761
            ],
            [
              -112.457753,
              42.732965
            ],
            [
              -112.49691,
              42.733013
            ],
            [
              -112.497227,
              42.790727
            ],
            [
              -112.516739,
              42.790824
            ],
            [
              -112.520638,
              42.91275
            ],
            [
              -112.559335,
              42.961004
            ],
            [
              -112.58905,
              42.973603
            ],
            [
              -112.60255,
              42.959556
            ],
            [
              -112.61952,
              42.960961
            ],
            [
              -112.643896,
              42.94196
            ],
            [
              -112.702152,
              42.935337
            ],
            [
              -112.702152,
              42.925953
            ],
            [
              -112.727384,
              42.915831
            ],
            [
              -112.737496,
              42.905408
            ],
            [
              -112.747491,
              42.912735
            ],
            [
              -112.732083,
              42.940087
            ],
            [
              -112.750562,
              42.951528
            ],
            [
              -112.766126,
              42.934308
            ],
            [
              -112.761214,
              42.923614
            ],
            [
              -112.779202,
              42.913831
            ],
            [
              -112.779654,
              42.893203
            ],
            [
              -112.794581,
              42.877472
            ],
            [
              -112.807138,
              42.882587
            ],
            [
              -112.816578,
              42.863231
            ],
            [
              -113.00776,
              42.863051
            ],
            [
              -113.007712,
              43.110933
            ],
            [
              -113.243478,
              43.110946
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "16",
      "COUNTYFP": "061",
      "COUNTYNS": "00395622",
      "AFFGEOID": "0500000US16061",
      "GEOID": "16061",
      "NAME": "Lewis",
      "LSAD": "06",
      "ALAND": 1240137223,
      "AWATER": 2345525,
      "County_state": "Lewis,16"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -116.713496,
              46.325527
            ],
            [
              -116.710295,
              46.297404
            ],
            [
              -116.702674,
              46.291699
            ],
            [
              -116.683395,
              46.242955
            ],
            [
              -116.702543,
              46.237792
            ],
            [
              -116.701581,
              46.063717
            ],
            [
              -116.701975,
              45.996217
            ],
            [
              -116.700112,
              45.998073
            ],
            [
              -116.696341,
              45.999443
            ],
            [
              -116.689669,
              45.998704
            ],
            [
              -116.681126,
              45.998031
            ],
            [
              -116.668734,
              45.995282
            ],
            [
              -116.662813,
              45.991841
            ],
            [
              -116.654699,
              45.993112
            ],
            [
              -116.648259,
              45.995547
            ],
            [
              -116.645477,
              45.999877
            ],
            [
              -116.64343,
              46.008029
            ],
            [
              -116.647438,
              46.017449
            ],
            [
              -116.648516,
              46.030597
            ],
            [
              -116.637913,
              46.036259
            ],
            [
              -116.62637,
              46.037219
            ],
            [
              -116.617613,
              46.035118
            ],
            [
              -116.614176,
              46.033246
            ],
            [
              -116.608478,
              46.034807
            ],
            [
              -116.605446,
              46.034637
            ],
            [
              -116.596638,
              46.032667
            ],
            [
              -116.590158,
              46.030415
            ],
            [
              -116.584086,
              46.028665
            ],
            [
              -116.579553,
              46.026816
            ],
            [
              -116.576621,
              46.023472
            ],
            [
              -116.570483,
              46.019886
            ],
            [
              -116.565003,
              46.016215
            ],
            [
              -116.5634,
              46.013043
            ],
            [
              -116.558902,
              46.009925
            ],
            [
              -116.555129,
              46.006632
            ],
            [
              -116.549027,
              46.00506
            ],
            [
              -116.543687,
              46.002417
            ],
            [
              -116.542616,
              45.999692
            ],
            [
              -116.537579,
              45.99883
            ],
            [
              -116.533527,
              45.999683
            ],
            [
              -116.528485,
              46.001949
            ],
            [
              -116.526499,
              46.005801
            ],
            [
              -116.524759,
              46.01053
            ],
            [
              -116.527317,
              46.01473
            ],
            [
              -116.527817,
              46.019945
            ],
            [
              -116.52469,
              46.024522
            ],
            [
              -116.522419,
              46.027749
            ],
            [
              -116.518733,
              46.031102
            ],
            [
              -116.518046,
              46.034196
            ],
            [
              -116.516619,
              46.03634
            ],
            [
              -116.515394,
              46.037899
            ],
            [
              -116.515517,
              46.040298
            ],
            [
              -116.51655,
              46.043691
            ],
            [
              -116.516679,
              46.046552
            ],
            [
              -116.514713,
              46.050195
            ],
            [
              -116.514565,
              46.059659
            ],
            [
              -116.514601,
              46.068665
            ],
            [
              -116.514564,
              46.082806
            ],
            [
              -116.514464,
              46.103353
            ],
            [
              -116.514527,
              46.124867
            ],
            [
              -116.514623,
              46.128663
            ],
            [
              -116.512015,
              46.130057
            ],
            [
              -116.510976,
              46.128564
            ],
            [
              -116.508625,
              46.127228
            ],
            [
              -116.504823,
              46.124688
            ],
            [
              -116.499763,
              46.123216
            ],
            [
              -116.496032,
              46.122894
            ],
            [
              -116.491202,
              46.123076
            ],
            [
              -116.486658,
              46.122005
            ],
            [
              -116.481499,
              46.121177
            ],
            [
              -116.47769,
              46.122804
            ],
            [
              -116.476084,
              46.125155
            ],
            [
              -116.476555,
              46.129284
            ],
            [
              -116.478052,
              46.132975
            ],
            [
              -116.479372,
              46.134047
            ],
            [
              -116.479997,
              46.135617
            ],
            [
              -116.478566,
              46.136865
            ],
            [
              -116.478743,
              46.140872
            ],
            [
              -116.480134,
              46.144022
            ],
            [
              -116.477362,
              46.146743
            ],
            [
              -116.473836,
              46.149737
            ],
            [
              -116.472664,
              46.153891
            ],
            [
              -116.469739,
              46.157864
            ],
            [
              -116.466098,
              46.160614
            ],
            [
              -116.464733,
              46.163661
            ],
            [
              -116.46328,
              46.16655
            ],
            [
              -116.463266,
              46.170011
            ],
            [
              -116.459113,
              46.173366
            ],
            [
              -116.4571,
              46.178117
            ],
            [
              -116.456477,
              46.182161
            ],
            [
              -116.454797,
              46.185596
            ],
            [
              -116.453598,
              46.190737
            ],
            [
              -116.452465,
              46.19364
            ],
            [
              -116.451276,
              46.195412
            ],
            [
              -116.447516,
              46.196912
            ],
            [
              -116.444329,
              46.196025
            ],
            [
              -116.440977,
              46.196298
            ],
            [
              -116.43726,
              46.197675
            ],
            [
              -116.433162,
              46.199843
            ],
            [
              -116.429119,
              46.202128
            ],
            [
              -116.424573,
              46.201869
            ],
            [
              -116.419864,
              46.201232
            ],
            [
              -116.416434,
              46.199383
            ],
            [
              -116.412381,
              46.196453
            ],
            [
              -116.407897,
              46.194003
            ],
            [
              -116.402437,
              46.191531
            ],
            [
              -116.397388,
              46.19034
            ],
            [
              -116.391168,
              46.188501
            ],
            [
              -116.385326,
              46.187938
            ],
            [
              -116.381135,
              46.188125
            ],
            [
              -116.376925,
              46.186685
            ],
            [
              -116.371619,
              46.184468
            ],
            [
              -116.366176,
              46.184905
            ],
            [
              -116.359684,
              46.185027
            ],
            [
              -116.355814,
              46.185383
            ],
            [
              -116.3516,
              46.185734
            ],
            [
              -116.344991,
              46.185166
            ],
            [
              -116.338301,
              46.184132
            ],
            [
              -116.332607,
              46.184089
            ],
            [
              -116.32918,
              46.182725
            ],
            [
              -116.324559,
              46.179313
            ],
            [
              -116.321552,
              46.175727
            ],
            [
              -116.31652,
              46.172383
            ],
            [
              -116.31244,
              46.169742
            ],
            [
              -116.306843,
              46.168201
            ],
            [
              -116.300925,
              46.166979
            ],
            [
              -116.291905,
              46.170095
            ],
            [
              -116.284124,
              46.173866
            ],
            [
              -116.277479,
              46.172446
            ],
            [
              -116.274087,
              46.170958
            ],
            [
              -116.266572,
              46.167957
            ],
            [
              -116.259963,
              46.169148
            ],
            [
              -116.255157,
              46.170525
            ],
            [
              -116.248184,
              46.1691
            ],
            [
              -116.242505,
              46.166508
            ],
            [
              -116.239167,
              46.16185
            ],
            [
              -116.234362,
              46.159883
            ],
            [
              -116.22927,
              46.158801
            ],
            [
              -116.222027,
              46.160837
            ],
            [
              -116.216177,
              46.159038
            ],
            [
              -116.209697,
              46.157116
            ],
            [
              -116.200476,
              46.152761
            ],
            [
              -116.194432,
              46.153208
            ],
            [
              -116.191344,
              46.154444
            ],
            [
              -116.188021,
              46.158577
            ],
            [
              -116.186227,
              46.166377
            ],
            [
              -116.186939,
              46.172393
            ],
            [
              -116.184537,
              46.178638
            ],
            [
              -116.179395,
              46.182849
            ],
            [
              -116.175647,
              46.185396
            ],
            [
              -116.170745,
              46.186585
            ],
            [
              -116.166744,
              46.188962
            ],
            [
              -116.164642,
              46.192928
            ],
            [
              -116.161463,
              46.197578
            ],
            [
              -116.155255,
              46.202918
            ],
            [
              -116.152594,
              46.206804
            ],
            [
              -116.147726,
              46.210632
            ],
            [
              -116.140987,
              46.21261
            ],
            [
              -116.136365,
              46.21421
            ],
            [
              -116.126406,
              46.214869
            ],
            [
              -116.121827,
              46.211641
            ],
            [
              -116.110766,
              46.210535
            ],
            [
              -116.097929,
              46.209164
            ],
            [
              -116.08717,
              46.2083
            ],
            [
              -116.079275,
              46.208927
            ],
            [
              -116.07309,
              46.210023
            ],
            [
              -116.069287,
              46.211131
            ],
            [
              -116.062637,
              46.210864
            ],
            [
              -116.054986,
              46.209982
            ],
            [
              -116.047672,
              46.211484
            ],
            [
              -116.039363,
              46.213884
            ],
            [
              -116.033662,
              46.216269
            ],
            [
              -116.026612,
              46.219851
            ],
            [
              -116.022495,
              46.222397
            ],
            [
              -116.017561,
              46.223998
            ],
            [
              -116.013723,
              46.227587
            ],
            [
              -116.016568,
              46.230286
            ],
            [
              -116.028316,
              46.237344
            ],
            [
              -116.036352,
              46.240583
            ],
            [
              -116.041138,
              46.248898
            ],
            [
              -116.053913,
              46.25349
            ],
            [
              -116.068865,
              46.256396
            ],
            [
              -116.078203,
              46.260184
            ],
            [
              -116.092633,
              46.268065
            ],
            [
              -116.100245,
              46.278756
            ],
            [
              -116.109475,
              46.284075
            ],
            [
              -116.120529,
              46.292884
            ],
            [
              -116.127586,
              46.30277
            ],
            [
              -116.133159,
              46.31067
            ],
            [
              -116.139466,
              46.323168
            ],
            [
              -116.146522,
              46.333674
            ],
            [
              -116.148428,
              46.338057
            ],
            [
              -116.152394,
              46.342749
            ],
            [
              -116.157402,
              46.351409
            ],
            [
              -116.164651,
              46.357654
            ],
            [
              -116.168157,
              46.37005
            ],
            [
              -116.170272,
              46.371588
            ],
            [
              -116.184198,
              46.403378
            ],
            [
              -116.197113,
              46.413102
            ],
            [
              -116.308621,
              46.412998
            ],
            [
              -116.31998,
              46.437543
            ],
            [
              -116.369122,
              46.466923
            ],
            [
              -116.418242,
              46.467981
            ],
            [
              -116.398552,
              46.437854
            ],
            [
              -116.367947,
              46.416374
            ],
            [
              -116.360637,
              46.361079
            ],
            [
              -116.368801,
              46.343217
            ],
            [
              -116.386753,
              46.340766
            ],
            [
              -116.401996,
              46.346826
            ],
            [
              -116.42209,
              46.342796
            ],
            [
              -116.468874,
              46.350985
            ],
            [
              -116.492509,
              46.338772
            ],
            [
              -116.489671,
              46.325586
            ],
            [
              -116.713496,
              46.325527
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "16",
      "COUNTYFP": "069",
      "COUNTYNS": "00399394",
      "AFFGEOID": "0500000US16069",
      "GEOID": "16069",
      "NAME": "Nez Perce",
      "LSAD": "06",
      "ALAND": 2197056674,
      "AWATER": 21339031,
      "County_state": "Nez Perce,16"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -117.062785,
              46.365287
            ],
            [
              -117.062748,
              46.353624
            ],
            [
              -117.06263,
              46.352522
            ],
            [
              -117.060703,
              46.349015
            ],
            [
              -117.055983,
              46.345531
            ],
            [
              -117.051735,
              46.343833
            ],
            [
              -117.045469,
              46.34249
            ],
            [
              -117.03445,
              46.34132
            ],
            [
              -117.030672,
              46.340315
            ],
            [
              -117.027744,
              46.338751
            ],
            [
              -117.023844,
              46.335976
            ],
            [
              -117.023149,
              46.334759
            ],
            [
              -117.022706,
              46.32899
            ],
            [
              -117.023424,
              46.326427
            ],
            [
              -117.022939,
              46.320175
            ],
            [
              -117.022293,
              46.31747
            ],
            [
              -117.020663,
              46.314793
            ],
            [
              -117.016413,
              46.311236
            ],
            [
              -117.007486,
              46.305302
            ],
            [
              -116.99726,
              46.303151
            ],
            [
              -116.989794,
              46.299395
            ],
            [
              -116.986688,
              46.296662
            ],
            [
              -116.98463,
              46.292705
            ],
            [
              -116.98491,
              46.289738
            ],
            [
              -116.990894,
              46.280372
            ],
            [
              -116.991422,
              46.278467
            ],
            [
              -116.991134,
              46.276342
            ],
            [
              -116.987391,
              46.272865
            ],
            [
              -116.976054,
              46.26601
            ],
            [
              -116.972591,
              46.263271
            ],
            [
              -116.970298,
              46.261233
            ],
            [
              -116.966742,
              46.256923
            ],
            [
              -116.964379,
              46.253282
            ],
            [
              -116.958801,
              46.24232
            ],
            [
              -116.955264,
              46.23088
            ],
            [
              -116.956031,
              46.225976
            ],
            [
              -116.959428,
              46.219812
            ],
            [
              -116.96613,
              46.209453
            ],
            [
              -116.966569,
              46.207501
            ],
            [
              -116.965841,
              46.203417
            ],
            [
              -116.962966,
              46.19968
            ],
            [
              -116.952416,
              46.193514
            ],
            [
              -116.941724,
              46.185034
            ],
            [
              -116.923958,
              46.17092
            ],
            [
              -116.92187,
              46.167808
            ],
            [
              -116.921258,
              46.164795
            ],
            [
              -116.922648,
              46.160744
            ],
            [
              -116.935473,
              46.142448
            ],
            [
              -116.948336,
              46.125885
            ],
            [
              -116.950276,
              46.123464
            ],
            [
              -116.95098,
              46.118853
            ],
            [
              -116.951265,
              46.111161
            ],
            [
              -116.955263,
              46.102237
            ],
            [
              -116.959548,
              46.099058
            ],
            [
              -116.974058,
              46.097707
            ],
            [
              -116.976957,
              46.09667
            ],
            [
              -116.978823,
              46.095731
            ],
            [
              -116.981747,
              46.092881
            ],
            [
              -116.982498,
              46.091347
            ],
            [
              -116.982479,
              46.089389
            ],
            [
              -116.981962,
              46.084915
            ],
            [
              -116.978938,
              46.080007
            ],
            [
              -116.970009,
              46.076769
            ],
            [
              -116.96319,
              46.075905
            ],
            [
              -116.960416,
              46.076346
            ],
            [
              -116.957372,
              46.075449
            ],
            [
              -116.948564,
              46.067933
            ],
            [
              -116.942656,
              46.061
            ],
            [
              -116.931706,
              46.039651
            ],
            [
              -116.923005,
              46.018293
            ],
            [
              -116.91868,
              45.999875
            ],
            [
              -116.91718,
              45.996575
            ],
            [
              -116.915989,
              45.995413
            ],
            [
              -116.911409,
              45.988912
            ],
            [
              -116.892935,
              45.974396
            ],
            [
              -116.886843,
              45.958617
            ],
            [
              -116.875706,
              45.945008
            ],
            [
              -116.869655,
              45.923799
            ],
            [
              -116.866544,
              45.916958
            ],
            [
              -116.859795,
              45.907264
            ],
            [
              -116.857254,
              45.904159
            ],
            [
              -116.84355,
              45.892273
            ],
            [
              -116.830003,
              45.886405
            ],
            [
              -116.819182,
              45.880938
            ],
            [
              -116.814142,
              45.877551
            ],
            [
              -116.796051,
              45.858473
            ],
            [
              -116.79445497532701,
              45.856140639876195
            ],
            [
              -116.750235,
              45.869287
            ],
            [
              -116.730343,
              45.894105
            ],
            [
              -116.757042,
              45.942103
            ],
            [
              -116.749132,
              45.961191
            ],
            [
              -116.701975,
              45.996217
            ],
            [
              -116.701581,
              46.063717
            ],
            [
              -116.702543,
              46.237792
            ],
            [
              -116.683395,
              46.242955
            ],
            [
              -116.702674,
              46.291699
            ],
            [
              -116.710295,
              46.297404
            ],
            [
              -116.713496,
              46.325527
            ],
            [
              -116.489671,
              46.325586
            ],
            [
              -116.492509,
              46.338772
            ],
            [
              -116.468874,
              46.350985
            ],
            [
              -116.42209,
              46.342796
            ],
            [
              -116.401996,
              46.346826
            ],
            [
              -116.386753,
              46.340766
            ],
            [
              -116.368801,
              46.343217
            ],
            [
              -116.360637,
              46.361079
            ],
            [
              -116.367947,
              46.416374
            ],
            [
              -116.398552,
              46.437854
            ],
            [
              -116.418242,
              46.467981
            ],
            [
              -116.369122,
              46.466923
            ],
            [
              -116.369311,
              46.500492
            ],
            [
              -116.447862,
              46.497963
            ],
            [
              -116.451662,
              46.490736
            ],
            [
              -116.451553,
              46.55658
            ],
            [
              -116.456098,
              46.629097
            ],
            [
              -116.582034,
              46.62848
            ],
            [
              -116.621015,
              46.62969
            ],
            [
              -116.658647,
              46.61209
            ],
            [
              -116.687932,
              46.588556
            ],
            [
              -116.696329,
              46.587646
            ],
            [
              -116.719085,
              46.542874
            ],
            [
              -117.039656800448,
              46.541709233636894
            ],
            [
              -117.039771,
              46.471779
            ],
            [
              -117.039763,
              46.46957
            ],
            [
              -117.039741,
              46.462704
            ],
            [
              -117.03981207463,
              46.425904123257894
            ],
            [
              -117.039813,
              46.425425
            ],
            [
              -117.036562,
              46.422596
            ],
            [
              -117.034696,
              46.418318
            ],
            [
              -117.035545,
              46.410012
            ],
            [
              -117.036455,
              46.407792
            ],
            [
              -117.038282,
              46.40604
            ],
            [
              -117.041737,
              46.395195
            ],
            [
              -117.04195,
              46.39216
            ],
            [
              -117.046915,
              46.379577
            ],
            [
              -117.049474,
              46.37682
            ],
            [
              -117.051775,
              46.375641
            ],
            [
              -117.057516,
              46.371396
            ],
            [
              -117.061045,
              46.367747
            ],
            [
              -117.062785,
              46.365287
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "16",
      "COUNTYFP": "083",
      "COUNTYNS": "00399334",
      "AFFGEOID": "0500000US16083",
      "GEOID": "16083",
      "NAME": "Twin Falls",
      "LSAD": "06",
      "ALAND": 4977213745,
      "AWATER": 18063174,
      "County_state": "Twin Falls,16"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -115.037726,
              42.301273
            ],
            [
              -115.038157180221,
              41.9960123074291
            ],
            [
              -115.031783,
              41.996008
            ],
            [
              -114.914187,
              41.999909
            ],
            [
              -114.89921,
              41.999909
            ],
            [
              -114.875877,
              42.001319
            ],
            [
              -114.831077,
              42.002207
            ],
            [
              -114.806384,
              42.001822
            ],
            [
              -114.720715,
              41.998231
            ],
            [
              -114.598267,
              41.994511
            ],
            [
              -114.498259,
              41.994599
            ],
            [
              -114.498243,
              41.994636
            ],
            [
              -114.467581,
              41.995492
            ],
            [
              -114.281855,
              41.994214
            ],
            [
              -114.281799324163,
              41.994213920521
            ],
            [
              -114.286652,
              42.330526
            ],
            [
              -114.285448,
              42.417333
            ],
            [
              -114.069162,
              42.417124
            ],
            [
              -114.06908,
              42.503179
            ],
            [
              -114.067073,
              42.514453
            ],
            [
              -114.000388,
              42.526373
            ],
            [
              -114.028949,
              42.524202
            ],
            [
              -114.042455,
              42.528765
            ],
            [
              -114.066939,
              42.522858
            ],
            [
              -114.09574,
              42.508116
            ],
            [
              -114.114358,
              42.506621
            ],
            [
              -114.13035,
              42.494906
            ],
            [
              -114.149969,
              42.497826
            ],
            [
              -114.172734,
              42.516688
            ],
            [
              -114.17947,
              42.532331
            ],
            [
              -114.244703,
              42.559244
            ],
            [
              -114.287207,
              42.561808
            ],
            [
              -114.307325,
              42.571755
            ],
            [
              -114.332917,
              42.572566
            ],
            [
              -114.350517,
              42.588562
            ],
            [
              -114.375217,
              42.598132
            ],
            [
              -114.440807,
              42.595868
            ],
            [
              -114.465709,
              42.599673
            ],
            [
              -114.539344,
              42.633309
            ],
            [
              -114.601678,
              42.641788
            ],
            [
              -114.616487,
              42.649298
            ],
            [
              -114.651814,
              42.660948
            ],
            [
              -114.682324,
              42.65957
            ],
            [
              -114.745743,
              42.673344
            ],
            [
              -114.777689,
              42.664357
            ],
            [
              -114.812167,
              42.664415
            ],
            [
              -114.825792,
              42.686114
            ],
            [
              -114.824634,
              42.704035
            ],
            [
              -114.850399,
              42.713668
            ],
            [
              -114.843978,
              42.735102
            ],
            [
              -114.857807,
              42.755301
            ],
            [
              -114.876958,
              42.756872
            ],
            [
              -114.908164,
              42.770447
            ],
            [
              -114.933622,
              42.766931
            ],
            [
              -114.940816,
              42.791409
            ],
            [
              -114.931386,
              42.80593
            ],
            [
              -114.936678,
              42.813253
            ],
            [
              -114.914366,
              42.826337
            ],
            [
              -114.903188,
              42.843731
            ],
            [
              -114.901636,
              42.859108
            ],
            [
              -114.918769,
              42.881113
            ],
            [
              -114.954762,
              42.905133
            ],
            [
              -114.960322,
              42.914925
            ],
            [
              -115.026017,
              42.917753
            ],
            [
              -115.037774,
              42.911864
            ],
            [
              -115.037697,
              42.768414
            ],
            [
              -115.037726,
              42.301273
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "16",
      "COUNTYFP": "023",
      "COUNTYNS": "00401703",
      "AFFGEOID": "0500000US16023",
      "GEOID": "16023",
      "NAME": "Butte",
      "LSAD": "06",
      "ALAND": 5792509562,
      "AWATER": 5260331,
      "County_state": "Butte,16"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -113.78432,
              43.580329
            ],
            [
              -113.798441,
              43.569217
            ],
            [
              -113.77713,
              43.566853
            ],
            [
              -113.752859,
              43.575242
            ],
            [
              -113.748347,
              43.587204
            ],
            [
              -113.727656,
              43.59014
            ],
            [
              -113.701153,
              43.584553
            ],
            [
              -113.687013,
              43.553112
            ],
            [
              -113.684294,
              43.530955
            ],
            [
              -113.665557,
              43.511766
            ],
            [
              -113.67283,
              43.500359
            ],
            [
              -113.653631,
              43.49373
            ],
            [
              -113.634325,
              43.497474
            ],
            [
              -113.63523,
              43.367546
            ],
            [
              -113.36191,
              43.36716
            ],
            [
              -113.361958,
              43.284678
            ],
            [
              -113.007318,
              43.284778
            ],
            [
              -112.947016,
              43.284821
            ],
            [
              -112.947009,
              43.367339
            ],
            [
              -112.936835,
              43.449386
            ],
            [
              -112.816704,
              43.449164
            ],
            [
              -112.816278,
              43.535949
            ],
            [
              -112.758293,
              43.535998
            ],
            [
              -112.698891,
              43.540052
            ],
            [
              -112.699052,
              43.62313
            ],
            [
              -112.697122,
              43.62314
            ],
            [
              -112.696915,
              43.79789
            ],
            [
              -112.695242,
              43.971941
            ],
            [
              -112.994323,
              43.972362
            ],
            [
              -112.995091,
              44.234126
            ],
            [
              -113.320609,
              44.233164
            ],
            [
              -113.416525,
              44.233364
            ],
            [
              -113.416272,
              44.059338
            ],
            [
              -113.457816,
              44.059528
            ],
            [
              -113.454457,
              43.990501
            ],
            [
              -113.446264,
              43.983666
            ],
            [
              -113.445637,
              43.914554
            ],
            [
              -113.456815,
              43.893744
            ],
            [
              -113.456893,
              43.876964
            ],
            [
              -113.410567,
              43.8602
            ],
            [
              -113.382405,
              43.82247
            ],
            [
              -113.372913,
              43.821455
            ],
            [
              -113.36399,
              43.794649
            ],
            [
              -113.377877,
              43.8097
            ],
            [
              -113.41444,
              43.816926
            ],
            [
              -113.443219,
              43.808551
            ],
            [
              -113.448013,
              43.775289
            ],
            [
              -113.486882,
              43.739668
            ],
            [
              -113.505509,
              43.738359
            ],
            [
              -113.528063,
              43.719091
            ],
            [
              -113.543525,
              43.719439
            ],
            [
              -113.566157,
              43.703785
            ],
            [
              -113.598473,
              43.695422
            ],
            [
              -113.617065,
              43.683937
            ],
            [
              -113.692544,
              43.652527
            ],
            [
              -113.724058,
              43.629013
            ],
            [
              -113.759998,
              43.622681
            ],
            [
              -113.78432,
              43.580329
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "16",
      "COUNTYFP": "035",
      "COUNTYNS": "00395697",
      "AFFGEOID": "0500000US16035",
      "GEOID": "16035",
      "NAME": "Clearwater",
      "LSAD": "06",
      "ALAND": 6364399894,
      "AWATER": 79605439,
      "County_state": "Clearwater,16"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -116.456098,
              46.629097
            ],
            [
              -116.451553,
              46.55658
            ],
            [
              -116.451662,
              46.490736
            ],
            [
              -116.447862,
              46.497963
            ],
            [
              -116.369311,
              46.500492
            ],
            [
              -116.369122,
              46.466923
            ],
            [
              -116.31998,
              46.437543
            ],
            [
              -116.308621,
              46.412998
            ],
            [
              -116.197113,
              46.413102
            ],
            [
              -116.184198,
              46.403378
            ],
            [
              -116.170272,
              46.371588
            ],
            [
              -116.148782,
              46.369565
            ],
            [
              -116.131623,
              46.354158
            ],
            [
              -116.114258,
              46.363647
            ],
            [
              -116.076088,
              46.361988
            ],
            [
              -116.038657,
              46.34689
            ],
            [
              -115.999438,
              46.30652
            ],
            [
              -115.957268,
              46.288931
            ],
            [
              -115.938055,
              46.292483
            ],
            [
              -115.848474,
              46.282552
            ],
            [
              -115.814741,
              46.265755
            ],
            [
              -115.806808,
              46.271823
            ],
            [
              -115.778831,
              46.267351
            ],
            [
              -115.773458,
              46.280142
            ],
            [
              -115.756662,
              46.284583
            ],
            [
              -115.748433,
              46.297213
            ],
            [
              -115.754184,
              46.309509
            ],
            [
              -115.7394,
              46.319907
            ],
            [
              -115.730844,
              46.341204
            ],
            [
              -115.733477,
              46.362882
            ],
            [
              -115.724971,
              46.373555
            ],
            [
              -115.681084,
              46.39423
            ],
            [
              -115.666383,
              46.413573
            ],
            [
              -115.6342,
              46.437581
            ],
            [
              -115.639282,
              46.450768
            ],
            [
              -115.630708,
              46.473038
            ],
            [
              -115.248432,
              46.533675
            ],
            [
              -114.595008042198,
              46.63339136601049
            ],
            [
              -114.615036,
              46.639733
            ],
            [
              -114.616354,
              46.643646
            ],
            [
              -114.611676,
              46.647704
            ],
            [
              -114.614716,
              46.655256
            ],
            [
              -114.621483,
              46.658143
            ],
            [
              -114.635713,
              46.659375
            ],
            [
              -114.642713,
              46.673145
            ],
            [
              -114.641745,
              46.679286
            ],
            [
              -114.631898,
              46.68397
            ],
            [
              -114.623198,
              46.691511
            ],
            [
              -114.620859,
              46.707415
            ],
            [
              -114.626695,
              46.712889
            ],
            [
              -114.632954,
              46.715495
            ],
            [
              -114.649388,
              46.73289
            ],
            [
              -114.674941612313,
              46.737042975581495
            ],
            [
              -114.696656,
              46.740572
            ],
            [
              -114.699008,
              46.740223
            ],
            [
              -114.710425,
              46.717704
            ],
            [
              -114.713516,
              46.715138
            ],
            [
              -114.727445,
              46.714604
            ],
            [
              -114.740115,
              46.711771
            ],
            [
              -114.747758,
              46.702649
            ],
            [
              -114.749257,
              46.699688
            ],
            [
              -114.751921,
              46.697207
            ],
            [
              -114.76689,
              46.696901
            ],
            [
              -114.787065,
              46.711255
            ],
            [
              -114.788656,
              46.714033
            ],
            [
              -114.779668,
              46.730411
            ],
            [
              -114.773765,
              46.731805
            ],
            [
              -114.76718,
              46.738828
            ],
            [
              -114.765127,
              46.745383
            ],
            [
              -114.765106,
              46.758153
            ],
            [
              -114.79004,
              46.778729
            ],
            [
              -114.808587,
              46.78235
            ],
            [
              -114.818161,
              46.781139
            ],
            [
              -114.829117,
              46.782503
            ],
            [
              -114.835917,
              46.791111
            ],
            [
              -114.844794,
              46.794305
            ],
            [
              -114.856874,
              46.801633
            ],
            [
              -114.860067,
              46.804988
            ],
            [
              -114.861376,
              46.81196
            ],
            [
              -114.864342,
              46.813858
            ],
            [
              -114.880588,
              46.811791
            ],
            [
              -114.888146,
              46.808573
            ],
            [
              -114.897857,
              46.813184
            ],
            [
              -114.904505,
              46.822851
            ],
            [
              -114.920459,
              46.827697
            ],
            [
              -114.927837,
              46.83599
            ],
            [
              -114.92845,
              46.843242
            ],
            [
              -114.92349,
              46.847594
            ],
            [
              -114.928615,
              46.854815
            ],
            [
              -114.940398,
              46.85605
            ],
            [
              -114.947413,
              46.859324
            ],
            [
              -114.943281,
              46.867971
            ],
            [
              -114.938713,
              46.869021
            ],
            [
              -114.931608,
              46.876799
            ],
            [
              -114.931058,
              46.882108
            ],
            [
              -114.936805,
              46.897378
            ],
            [
              -114.935035,
              46.901749
            ],
            [
              -114.927948,
              46.909948
            ],
            [
              -114.927432,
              46.914185
            ],
            [
              -114.929997,
              46.919625
            ],
            [
              -114.960597,
              46.93001
            ],
            [
              -114.96397803595102,
              46.9328888722194
            ],
            [
              -115.827006,
              46.934158
            ],
            [
              -116.329436,
              46.934669
            ],
            [
              -116.32954,
              46.627957
            ],
            [
              -116.456098,
              46.629097
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "16",
      "COUNTYFP": "003",
      "COUNTYNS": "00395067",
      "AFFGEOID": "0500000US16003",
      "GEOID": "16003",
      "NAME": "Adams",
      "LSAD": "06",
      "ALAND": 3529648932,
      "AWATER": 17359848,
      "County_state": "Adams,16"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -116.896249,
              44.84833
            ],
            [
              -116.901028451124,
              44.8415357708036
            ],
            [
              -116.625034,
              44.838952
            ],
            [
              -116.624434,
              44.662859
            ],
            [
              -116.599263,
              44.636862
            ],
            [
              -116.603212,
              44.615192
            ],
            [
              -116.529167,
              44.615154
            ],
            [
              -116.528898,
              44.491545
            ],
            [
              -116.407648,
              44.444436
            ],
            [
              -116.299876,
              44.444576
            ],
            [
              -116.255973,
              44.466296
            ],
            [
              -116.236136,
              44.464713
            ],
            [
              -116.233874,
              44.481262
            ],
            [
              -116.213314,
              44.510813
            ],
            [
              -116.193278,
              44.493666
            ],
            [
              -116.157022,
              44.498928
            ],
            [
              -116.157739,
              44.49971
            ],
            [
              -116.158579,
              44.508026
            ],
            [
              -116.16088,
              44.515704
            ],
            [
              -116.162026,
              44.525347
            ],
            [
              -116.16432,
              44.535155
            ],
            [
              -116.168689,
              44.545147
            ],
            [
              -116.172315,
              44.553466
            ],
            [
              -116.17587,
              44.561814
            ],
            [
              -116.17414,
              44.56924
            ],
            [
              -116.176022,
              44.577613
            ],
            [
              -116.181192,
              44.582348
            ],
            [
              -116.183857,
              44.5845
            ],
            [
              -116.183366,
              44.586566
            ],
            [
              -116.182183,
              44.587809
            ],
            [
              -116.179548,
              44.594715
            ],
            [
              -116.176618,
              44.602387
            ],
            [
              -116.176495,
              44.611344
            ],
            [
              -116.170431,
              44.619231
            ],
            [
              -116.170209,
              44.625303
            ],
            [
              -116.173543,
              44.636122
            ],
            [
              -116.176269,
              44.645
            ],
            [
              -116.174174,
              44.654358
            ],
            [
              -116.167872,
              44.665271
            ],
            [
              -116.163543,
              44.678442
            ],
            [
              -116.163137,
              44.684942
            ],
            [
              -116.163428,
              44.6883
            ],
            [
              -116.163292,
              44.690492
            ],
            [
              -116.160405,
              44.693205
            ],
            [
              -116.159349,
              44.700716
            ],
            [
              -116.160989,
              44.708595
            ],
            [
              -116.166126,
              44.71133
            ],
            [
              -116.167699,
              44.711374
            ],
            [
              -116.16891,
              44.71196
            ],
            [
              -116.16938,
              44.712899
            ],
            [
              -116.17057,
              44.714463
            ],
            [
              -116.171914,
              44.71582
            ],
            [
              -116.17466,
              44.718034
            ],
            [
              -116.177358,
              44.720173
            ],
            [
              -116.178518,
              44.721443
            ],
            [
              -116.179566,
              44.722778
            ],
            [
              -116.18183,
              44.724178
            ],
            [
              -116.181991,
              44.727889
            ],
            [
              -116.179018,
              44.729287
            ],
            [
              -116.178736,
              44.739702
            ],
            [
              -116.185179,
              44.748532
            ],
            [
              -116.192177,
              44.753583
            ],
            [
              -116.199233,
              44.763438
            ],
            [
              -116.20083,
              44.766043
            ],
            [
              -116.206486,
              44.767698
            ],
            [
              -116.20833,
              44.773424
            ],
            [
              -116.209944,
              44.779177
            ],
            [
              -116.20848,
              44.782794
            ],
            [
              -116.205994,
              44.786809
            ],
            [
              -116.205231,
              44.789713
            ],
            [
              -116.204388,
              44.792132
            ],
            [
              -116.202884,
              44.795329
            ],
            [
              -116.202945,
              44.797852
            ],
            [
              -116.203374,
              44.799921
            ],
            [
              -116.203984,
              44.803005
            ],
            [
              -116.201853,
              44.807861
            ],
            [
              -116.200898,
              44.810503
            ],
            [
              -116.200651,
              44.813597
            ],
            [
              -116.2,
              44.818918
            ],
            [
              -116.198832,
              44.824267
            ],
            [
              -116.198714,
              44.832016
            ],
            [
              -116.198016,
              44.838497
            ],
            [
              -116.197971,
              44.843487
            ],
            [
              -116.196986,
              44.848354
            ],
            [
              -116.195921,
              44.849861
            ],
            [
              -116.196039,
              44.854332
            ],
            [
              -116.197081,
              44.858086
            ],
            [
              -116.197047,
              44.86236
            ],
            [
              -116.1943,
              44.864139
            ],
            [
              -116.190589,
              44.867224
            ],
            [
              -116.189172,
              44.872622
            ],
            [
              -116.188607,
              44.878608
            ],
            [
              -116.186424,
              44.884698
            ],
            [
              -116.183984,
              44.887974
            ],
            [
              -116.179937,
              44.88868
            ],
            [
              -116.177368,
              44.888039
            ],
            [
              -116.174265,
              44.888216
            ],
            [
              -116.174057,
              44.889528
            ],
            [
              -116.173759,
              44.896531
            ],
            [
              -116.168552,
              44.900895
            ],
            [
              -116.164523,
              44.901402
            ],
            [
              -116.160959,
              44.902936
            ],
            [
              -116.160952,
              44.904842
            ],
            [
              -116.15936,
              44.911643
            ],
            [
              -116.159059,
              44.917617
            ],
            [
              -116.158138,
              44.922039
            ],
            [
              -116.155444,
              44.927905
            ],
            [
              -116.156213,
              44.931341
            ],
            [
              -116.155163,
              44.936456
            ],
            [
              -116.154562,
              44.93967
            ],
            [
              -116.154783,
              44.94174
            ],
            [
              -116.149026,
              44.943269
            ],
            [
              -116.143134,
              44.941028
            ],
            [
              -116.135069,
              44.94069
            ],
            [
              -116.127663,
              44.945466
            ],
            [
              -116.122243,
              44.952309
            ],
            [
              -116.119658,
              44.959118
            ],
            [
              -116.115359,
              44.966985
            ],
            [
              -116.114381,
              44.97077
            ],
            [
              -116.114307,
              44.97652
            ],
            [
              -116.118739,
              44.98048
            ],
            [
              -116.119447,
              44.982297
            ],
            [
              -116.124082,
              44.98574
            ],
            [
              -116.127658,
              44.987199
            ],
            [
              -116.128457,
              44.994695
            ],
            [
              -116.13345,
              44.998628
            ],
            [
              -116.134981,
              45.000791
            ],
            [
              -116.133495,
              45.008982
            ],
            [
              -116.127823,
              45.015504
            ],
            [
              -116.122723,
              45.021101
            ],
            [
              -116.114056,
              45.025462
            ],
            [
              -116.110348,
              45.030683
            ],
            [
              -116.104544,
              45.033599
            ],
            [
              -116.101705,
              45.042084
            ],
            [
              -116.104448,
              45.049663
            ],
            [
              -116.099971,
              45.053072
            ],
            [
              -116.094542,
              45.057096
            ],
            [
              -116.091281,
              45.061824
            ],
            [
              -116.093964,
              45.067168
            ],
            [
              -116.108029,
              45.072893
            ],
            [
              -116.126318,
              45.075249
            ],
            [
              -116.130765,
              45.076426
            ],
            [
              -116.14132,
              45.082081
            ],
            [
              -116.139528,
              45.087212
            ],
            [
              -116.140908,
              45.096084
            ],
            [
              -116.143828,
              45.105323
            ],
            [
              -116.144386,
              45.107651
            ],
            [
              -116.30475,
              45.108793
            ],
            [
              -116.304153,
              45.119164
            ],
            [
              -116.285969,
              45.128732
            ],
            [
              -116.295071,
              45.147794
            ],
            [
              -116.303762,
              45.172073
            ],
            [
              -116.301084,
              45.184521
            ],
            [
              -116.312733,
              45.211389
            ],
            [
              -116.342876,
              45.267872
            ],
            [
              -116.687213119705,
              45.26768125110139
            ],
            [
              -116.691388,
              45.263739
            ],
            [
              -116.696047,
              45.254679
            ],
            [
              -116.703607,
              45.239757
            ],
            [
              -116.709373,
              45.219463
            ],
            [
              -116.70975,
              45.217243
            ],
            [
              -116.708546,
              45.207356
            ],
            [
              -116.709536,
              45.203015
            ],
            [
              -116.724205,
              45.171501
            ],
            [
              -116.724188,
              45.162924
            ],
            [
              -116.728757,
              45.144381
            ],
            [
              -116.729607,
              45.142091
            ],
            [
              -116.731216,
              45.139934
            ],
            [
              -116.754643,
              45.113972
            ],
            [
              -116.774847,
              45.105536
            ],
            [
              -116.782492,
              45.09579
            ],
            [
              -116.783537,
              45.093605
            ],
            [
              -116.784244,
              45.088128
            ],
            [
              -116.78377192657202,
              45.078265731912005
            ],
            [
              -116.78371,
              45.076972
            ],
            [
              -116.797329,
              45.060267
            ],
            [
              -116.808576,
              45.050652
            ],
            [
              -116.825133,
              45.03784
            ],
            [
              -116.830115,
              45.035317
            ],
            [
              -116.841314,
              45.030907
            ],
            [
              -116.847944,
              45.022602
            ],
            [
              -116.848037,
              45.021728
            ],
            [
              -116.845847,
              45.01847
            ],
            [
              -116.844796,
              45.015312
            ],
            [
              -116.844625,
              45.001435
            ],
            [
              -116.846103,
              44.999878
            ],
            [
              -116.856754,
              44.984298
            ],
            [
              -116.858313,
              44.978761
            ],
            [
              -116.851406,
              44.959841
            ],
            [
              -116.850737,
              44.958113
            ],
            [
              -116.846461,
              44.951521
            ],
            [
              -116.835702,
              44.940633
            ],
            [
              -116.83199,
              44.933007
            ],
            [
              -116.832176,
              44.931373
            ],
            [
              -116.833632,
              44.928976
            ],
            [
              -116.838467,
              44.923601
            ],
            [
              -116.842108,
              44.914922
            ],
            [
              -116.846061,
              44.905249
            ],
            [
              -116.850512,
              44.893523
            ],
            [
              -116.852427,
              44.887577
            ],
            [
              -116.857038,
              44.880769
            ],
            [
              -116.865338,
              44.870599
            ],
            [
              -116.883598,
              44.858268
            ],
            [
              -116.896249,
              44.84833
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "16",
      "COUNTYFP": "007",
      "COUNTYNS": "00395090",
      "AFFGEOID": "0500000US16007",
      "GEOID": "16007",
      "NAME": "Bear Lake",
      "LSAD": "06",
      "ALAND": 2527122948,
      "AWATER": 191364283,
      "County_state": "Bear Lake,16"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -111.63491,
              42.570026
            ],
            [
              -111.621822,
              42.51838
            ],
            [
              -111.610232,
              42.509789
            ],
            [
              -111.579764,
              42.429908
            ],
            [
              -111.599227,
              42.415395
            ],
            [
              -111.599139,
              42.390754
            ],
            [
              -111.584179,
              42.394229
            ],
            [
              -111.582125,
              42.375829
            ],
            [
              -111.558678,
              42.362891
            ],
            [
              -111.552151,
              42.351386
            ],
            [
              -111.588277,
              42.32566
            ],
            [
              -111.582128,
              42.310014
            ],
            [
              -111.586462,
              42.287916
            ],
            [
              -111.562195,
              42.276792
            ],
            [
              -111.591235,
              42.262917
            ],
            [
              -111.599631,
              42.248482
            ],
            [
              -111.614485,
              42.245683
            ],
            [
              -111.613607,
              42.22777
            ],
            [
              -111.626104,
              42.207542
            ],
            [
              -111.607963,
              42.154119
            ],
            [
              -111.587205,
              42.141976
            ],
            [
              -111.599116,
              42.099006
            ],
            [
              -111.566592,
              42.083063
            ],
            [
              -111.521577,
              42.074437
            ],
            [
              -111.515292,
              42.06853
            ],
            [
              -111.507805973428,
              41.9995175815805
            ],
            [
              -111.507789674782,
              41.9995175941635
            ],
            [
              -111.507264,
              41.999518
            ],
            [
              -111.471381,
              41.999739
            ],
            [
              -111.425535,
              42.00084
            ],
            [
              -111.420898,
              42.000793
            ],
            [
              -111.415873,
              42.000748
            ],
            [
              -111.046689,
              42.001567
            ],
            [
              -111.047109,
              42.142497
            ],
            [
              -111.047107,
              42.148971
            ],
            [
              -111.047058,
              42.182672
            ],
            [
              -111.047097,
              42.194773
            ],
            [
              -111.047074,
              42.280787
            ],
            [
              -111.04708,
              42.34942
            ],
            [
              -111.046801,
              42.504946
            ],
            [
              -111.046710501596,
              42.5139239264854
            ],
            [
              -111.066734,
              42.508935
            ],
            [
              -111.062858,
              42.500817
            ],
            [
              -111.095301,
              42.489301
            ],
            [
              -111.109793,
              42.491571
            ],
            [
              -111.131601,
              42.481781
            ],
            [
              -111.159373,
              42.490612
            ],
            [
              -111.171174,
              42.4732
            ],
            [
              -111.189298,
              42.479367
            ],
            [
              -111.234827,
              42.478329
            ],
            [
              -111.248946,
              42.495291
            ],
            [
              -111.236714,
              42.509112
            ],
            [
              -111.222382,
              42.572643
            ],
            [
              -111.240774,
              42.585043
            ],
            [
              -111.242455,
              42.60303
            ],
            [
              -111.279388,
              42.552861
            ],
            [
              -111.318685,
              42.561287
            ],
            [
              -111.341752,
              42.571036
            ],
            [
              -111.340223,
              42.586148
            ],
            [
              -111.368062,
              42.590294
            ],
            [
              -111.599995,
              42.590387
            ],
            [
              -111.61763,
              42.583037
            ],
            [
              -111.63491,
              42.570026
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "16",
      "COUNTYFP": "041",
      "COUNTYNS": "00395585",
      "AFFGEOID": "0500000US16041",
      "GEOID": "16041",
      "NAME": "Franklin",
      "LSAD": "06",
      "ALAND": 1717209197,
      "AWATER": 12155421,
      "County_state": "Franklin,16"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -112.126194,
              42.285229
            ],
            [
              -112.113323,
              42.269345
            ],
            [
              -112.098293,
              42.26994
            ],
            [
              -112.083426,
              42.245963
            ],
            [
              -112.070959,
              42.238263
            ],
            [
              -112.070266,
              42.169646
            ],
            [
              -112.109271,
              42.169911
            ],
            [
              -112.10944249133001,
              41.9975986914138
            ],
            [
              -112.01218,
              41.99835
            ],
            [
              -111.94997920191899,
              41.998459109482496
            ],
            [
              -111.915837,
              41.998519
            ],
            [
              -111.915622,
              41.998496
            ],
            [
              -111.876491,
              41.998528
            ],
            [
              -111.750778,
              41.99933
            ],
            [
              -111.73970108689299,
              41.9993385517041
            ],
            [
              -111.507805973428,
              41.9995175815805
            ],
            [
              -111.515292,
              42.06853
            ],
            [
              -111.521577,
              42.074437
            ],
            [
              -111.566592,
              42.083063
            ],
            [
              -111.599116,
              42.099006
            ],
            [
              -111.587205,
              42.141976
            ],
            [
              -111.607963,
              42.154119
            ],
            [
              -111.626104,
              42.207542
            ],
            [
              -111.613607,
              42.22777
            ],
            [
              -111.614485,
              42.245683
            ],
            [
              -111.599631,
              42.248482
            ],
            [
              -111.591235,
              42.262917
            ],
            [
              -111.562195,
              42.276792
            ],
            [
              -111.586462,
              42.287916
            ],
            [
              -111.582128,
              42.310014
            ],
            [
              -111.588277,
              42.32566
            ],
            [
              -111.552151,
              42.351386
            ],
            [
              -111.558678,
              42.362891
            ],
            [
              -111.582125,
              42.375829
            ],
            [
              -111.584179,
              42.394229
            ],
            [
              -111.599139,
              42.390754
            ],
            [
              -111.599227,
              42.415395
            ],
            [
              -111.711043,
              42.41589
            ],
            [
              -111.716006,
              42.430569
            ],
            [
              -111.733742,
              42.430635
            ],
            [
              -111.727922,
              42.428812
            ],
            [
              -111.738396,
              42.41616
            ],
            [
              -111.875636,
              42.416333
            ],
            [
              -111.89532,
              42.416193
            ],
            [
              -111.896728,
              42.256276
            ],
            [
              -111.983916,
              42.256486
            ],
            [
              -111.983848,
              42.28555
            ],
            [
              -112.126194,
              42.285229
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "16",
      "COUNTYFP": "057",
      "COUNTYNS": "00395613",
      "AFFGEOID": "0500000US16057",
      "GEOID": "16057",
      "NAME": "Latah",
      "LSAD": "06",
      "ALAND": 2786482704,
      "AWATER": 2370320,
      "County_state": "Latah,16"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -117.039657,
              46.825798
            ],
            [
              -117.039828,
              46.815443
            ],
            [
              -117.039398,
              46.700186
            ],
            [
              -117.039656800448,
              46.541709233636894
            ],
            [
              -116.719085,
              46.542874
            ],
            [
              -116.696329,
              46.587646
            ],
            [
              -116.687932,
              46.588556
            ],
            [
              -116.658647,
              46.61209
            ],
            [
              -116.621015,
              46.62969
            ],
            [
              -116.582034,
              46.62848
            ],
            [
              -116.456098,
              46.629097
            ],
            [
              -116.32954,
              46.627957
            ],
            [
              -116.329436,
              46.934669
            ],
            [
              -116.329418,
              47.021915
            ],
            [
              -116.458221,
              47.022096
            ],
            [
              -116.45812,
              47.036596
            ],
            [
              -116.771126,
              47.032511
            ],
            [
              -116.835269,
              47.032452
            ],
            [
              -116.835104,
              47.04547
            ],
            [
              -116.847007,
              47.047218
            ],
            [
              -116.883079,
              47.066392
            ],
            [
              -116.896191,
              47.077896
            ],
            [
              -116.925186,
              47.081565
            ],
            [
              -116.944371,
              47.067227
            ],
            [
              -116.957449,
              47.070897
            ],
            [
              -116.977444,
              47.089121
            ],
            [
              -116.967735,
              47.115232
            ],
            [
              -117.001018,
              47.130487
            ],
            [
              -117.039821054109,
              47.1272689624222
            ],
            [
              -117.039657,
              46.825798
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "16",
      "COUNTYFP": "073",
      "COUNTYNS": "00399760",
      "AFFGEOID": "0500000US16073",
      "GEOID": "16073",
      "NAME": "Owyhee",
      "LSAD": "06",
      "ALAND": 19860561623,
      "AWATER": 74125616,
      "County_state": "Owyhee,16"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -117.027001,
              43.621032
            ],
            [
              -117.026937,
              43.617614
            ],
            [
              -117.026789,
              43.610669
            ],
            [
              -117.02676,
              43.601912
            ],
            [
              -117.026824,
              43.600357
            ],
            [
              -117.026889,
              43.596033
            ],
            [
              -117.026922,
              43.593632
            ],
            [
              -117.026774,
              43.578674
            ],
            [
              -117.026746,
              43.577526
            ],
            [
              -117.026652,
              43.025128
            ],
            [
              -117.026683,
              43.024876
            ],
            [
              -117.026253,
              42.807447
            ],
            [
              -117.026303,
              42.80717
            ],
            [
              -117.026331,
              42.807015
            ],
            [
              -117.026665,
              42.624878
            ],
            [
              -117.026551,
              42.378557
            ],
            [
              -117.026129,
              42.357193
            ],
            [
              -117.026195,
              42.166404
            ],
            [
              -117.02659,
              42.133258
            ],
            [
              -117.026098,
              42.117647
            ],
            [
              -117.026197,
              41.99989
            ],
            [
              -117.018294,
              41.999358
            ],
            [
              -117.018208514199,
              41.9993463578912
            ],
            [
              -117.009255,
              41.998127
            ],
            [
              -116.969156,
              41.998991
            ],
            [
              -116.62677,
              41.99775
            ],
            [
              -116.625947,
              41.997379
            ],
            [
              -116.586937,
              41.99737
            ],
            [
              -116.582217,
              41.997834
            ],
            [
              -116.525319,
              41.997558
            ],
            [
              -116.510452,
              41.997096
            ],
            [
              -116.501741,
              41.997334
            ],
            [
              -116.499777,
              41.99674
            ],
            [
              -116.485823,
              41.996861
            ],
            [
              -116.483094,
              41.996885
            ],
            [
              -116.463528,
              41.996547
            ],
            [
              -116.368478,
              41.996281
            ],
            [
              -116.332763,
              41.997283
            ],
            [
              -116.163931,
              41.997555
            ],
            [
              -116.160833,
              41.997508
            ],
            [
              -116.038602,
              41.99746
            ],
            [
              -116.03857,
              41.997413
            ],
            [
              -116.030754,
              41.997399
            ],
            [
              -116.030758,
              41.997383
            ],
            [
              -116.01896,
              41.997762
            ],
            [
              -116.018945,
              41.997722
            ],
            [
              -116.012219,
              41.998048
            ],
            [
              -116.012212,
              41.998035
            ],
            [
              -115.98688,
              41.998534
            ],
            [
              -115.887612,
              41.998048
            ],
            [
              -115.879596,
              41.997891
            ],
            [
              -115.870181,
              41.996766
            ],
            [
              -115.625914,
              41.997415
            ],
            [
              -115.586849,
              41.996884
            ],
            [
              -115.313877,
              41.996103
            ],
            [
              -115.254333,
              41.996721
            ],
            [
              -115.250795,
              41.996156
            ],
            [
              -115.038157180221,
              41.9960123074291
            ],
            [
              -115.037726,
              42.301273
            ],
            [
              -115.037697,
              42.768414
            ],
            [
              -115.454224,
              42.767936
            ],
            [
              -115.454233,
              42.851132
            ],
            [
              -115.441414,
              42.851105
            ],
            [
              -115.441522,
              42.931127
            ],
            [
              -115.500613,
              42.927229
            ],
            [
              -115.530388,
              42.942688
            ],
            [
              -115.55976,
              42.934898
            ],
            [
              -115.60443,
              42.937697
            ],
            [
              -115.622852,
              42.955698
            ],
            [
              -115.647668,
              42.953246
            ],
            [
              -115.670963,
              42.943477
            ],
            [
              -115.694474,
              42.939357
            ],
            [
              -115.740817,
              42.943003
            ],
            [
              -115.771505,
              42.938664
            ],
            [
              -115.79631,
              42.948712
            ],
            [
              -115.786071,
              42.960395
            ],
            [
              -115.791812,
              42.972348
            ],
            [
              -115.832122,
              42.972959
            ],
            [
              -115.846266,
              42.969258
            ],
            [
              -115.8647,
              42.974781
            ],
            [
              -115.878432,
              42.98891
            ],
            [
              -115.893469,
              42.982665
            ],
            [
              -115.917101,
              42.987405
            ],
            [
              -115.91744,
              42.995231
            ],
            [
              -115.935655,
              43.001733
            ],
            [
              -115.960999,
              42.989084
            ],
            [
              -115.97211,
              42.970485
            ],
            [
              -115.960913,
              42.953018
            ],
            [
              -115.964966,
              42.943673
            ],
            [
              -116.01106,
              42.949681
            ],
            [
              -116.032169,
              42.961878
            ],
            [
              -116.050082,
              42.985314
            ],
            [
              -116.090743,
              42.987883
            ],
            [
              -116.11554,
              43.010829
            ],
            [
              -116.145817,
              43.013416
            ],
            [
              -116.141584,
              43.034124
            ],
            [
              -116.167155,
              43.037808
            ],
            [
              -116.20174,
              43.04893
            ],
            [
              -116.206359,
              43.070686
            ],
            [
              -116.191672,
              43.082473
            ],
            [
              -116.205981,
              43.093232
            ],
            [
              -116.237384,
              43.094464
            ],
            [
              -116.26684,
              43.106099
            ],
            [
              -116.266207,
              43.112269
            ],
            [
              -116.269363,
              43.117213
            ],
            [
              -116.299945,
              43.119216
            ],
            [
              -116.319411,
              43.143972
            ],
            [
              -116.332572,
              43.148431
            ],
            [
              -116.336044,
              43.161372
            ],
            [
              -116.351745,
              43.164808
            ],
            [
              -116.385955,
              43.193577
            ],
            [
              -116.384352,
              43.217108
            ],
            [
              -116.376059,
              43.231088
            ],
            [
              -116.388585,
              43.253172
            ],
            [
              -116.400944,
              43.260205
            ],
            [
              -116.410247,
              43.284421
            ],
            [
              -116.425275,
              43.293543
            ],
            [
              -116.47208,
              43.291216
            ],
            [
              -116.501165,
              43.282129
            ],
            [
              -116.511897,
              43.29052
            ],
            [
              -116.521832,
              43.297906
            ],
            [
              -116.550396,
              43.299201
            ],
            [
              -116.579167,
              43.306178
            ],
            [
              -116.600335,
              43.331692
            ],
            [
              -116.60716,
              43.351332
            ],
            [
              -116.622569,
              43.370009
            ],
            [
              -116.667349,
              43.386601
            ],
            [
              -116.676483,
              43.400536
            ],
            [
              -116.728721,
              43.436523
            ],
            [
              -116.744331,
              43.4552
            ],
            [
              -116.781336,
              43.478633
            ],
            [
              -116.784918,
              43.514945
            ],
            [
              -116.780266,
              43.526938
            ],
            [
              -116.800028,
              43.54797
            ],
            [
              -116.816511,
              43.577921
            ],
            [
              -116.844978,
              43.5944
            ],
            [
              -116.871466,
              43.592131
            ],
            [
              -116.911538,
              43.603076
            ],
            [
              -116.924039,
              43.619811
            ],
            [
              -116.955309,
              43.635676
            ],
            [
              -116.968202,
              43.667412
            ],
            [
              -117.004612,
              43.673028
            ],
            [
              -117.026624495654,
              43.6808035152354
            ],
            [
              -117.026717,
              43.675523
            ],
            [
              -117.026661,
              43.664385
            ],
            [
              -117.026705,
              43.631659
            ],
            [
              -117.026905,
              43.62488
            ],
            [
              -117.027001,
              43.621032
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "16",
      "COUNTYFP": "065",
      "COUNTYNS": "00394803",
      "AFFGEOID": "0500000US16065",
      "GEOID": "16065",
      "NAME": "Madison",
      "LSAD": "06",
      "ALAND": 1215396379,
      "AWATER": 10500827,
      "County_state": "Madison,16"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -111.977469,
              43.797235
            ],
            [
              -111.983527,
              43.753806
            ],
            [
              -111.967005,
              43.748165
            ],
            [
              -111.946802,
              43.750715
            ],
            [
              -111.929901,
              43.767097
            ],
            [
              -111.919279,
              43.756086
            ],
            [
              -111.908234,
              43.760316
            ],
            [
              -111.902054,
              43.74717
            ],
            [
              -111.873152,
              43.737008
            ],
            [
              -111.858078,
              43.718603
            ],
            [
              -111.83098,
              43.71669
            ],
            [
              -111.820299,
              43.704238
            ],
            [
              -111.80385,
              43.701974
            ],
            [
              -111.778098,
              43.675995
            ],
            [
              -111.760761,
              43.667346
            ],
            [
              -111.743734,
              43.668165
            ],
            [
              -111.717356,
              43.655326
            ],
            [
              -111.625938,
              43.655227
            ],
            [
              -111.626043,
              43.626756
            ],
            [
              -111.566042,
              43.626836
            ],
            [
              -111.531781,
              43.622024
            ],
            [
              -111.399765,
              43.621976
            ],
            [
              -111.400797,
              43.882826
            ],
            [
              -111.398751,
              43.886426
            ],
            [
              -111.398781,
              43.92289
            ],
            [
              -111.42522,
              43.910358
            ],
            [
              -111.467182,
              43.916533
            ],
            [
              -111.478723,
              43.927886
            ],
            [
              -111.501232,
              43.929206
            ],
            [
              -111.531732,
              43.919254
            ],
            [
              -111.558776,
              43.901052
            ],
            [
              -111.558833,
              43.883305
            ],
            [
              -111.697826,
              43.88344
            ],
            [
              -111.698046,
              43.897901
            ],
            [
              -111.83759,
              43.898261
            ],
            [
              -111.837308,
              43.927158
            ],
            [
              -111.976849,
              43.927385
            ],
            [
              -111.977469,
              43.797235
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "16",
      "COUNTYFP": "011",
      "COUNTYNS": "00400593",
      "AFFGEOID": "0500000US16011",
      "GEOID": "16011",
      "NAME": "Bingham",
      "LSAD": "06",
      "ALAND": 5422887230,
      "AWATER": 68487087,
      "County_state": "Bingham,16"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -113.007712,
              43.110933
            ],
            [
              -113.00776,
              42.863051
            ],
            [
              -112.816578,
              42.863231
            ],
            [
              -112.807138,
              42.882587
            ],
            [
              -112.794581,
              42.877472
            ],
            [
              -112.779654,
              42.893203
            ],
            [
              -112.779202,
              42.913831
            ],
            [
              -112.761214,
              42.923614
            ],
            [
              -112.766126,
              42.934308
            ],
            [
              -112.750562,
              42.951528
            ],
            [
              -112.751066,
              42.963382
            ],
            [
              -112.735659,
              42.973234
            ],
            [
              -112.741566,
              42.988858
            ],
            [
              -112.707547,
              42.990549
            ],
            [
              -112.680409,
              43.012267
            ],
            [
              -112.678364,
              43.02173
            ],
            [
              -112.664762,
              43.015624
            ],
            [
              -112.656552,
              43.021944
            ],
            [
              -112.063023,
              43.022364
            ],
            [
              -111.947212,
              43.021012
            ],
            [
              -111.589489,
              43.020052
            ],
            [
              -111.588049,
              43.281841
            ],
            [
              -111.822494,
              43.282286
            ],
            [
              -111.822475,
              43.366035
            ],
            [
              -111.945775,
              43.366306
            ],
            [
              -112.043821,
              43.366708
            ],
            [
              -112.043672,
              43.410143
            ],
            [
              -112.103396,
              43.41026
            ],
            [
              -112.103298,
              43.425057
            ],
            [
              -112.520024,
              43.42517
            ],
            [
              -112.519861,
              43.626623
            ],
            [
              -112.639238,
              43.626574
            ],
            [
              -112.697122,
              43.62314
            ],
            [
              -112.699052,
              43.62313
            ],
            [
              -112.698891,
              43.540052
            ],
            [
              -112.758293,
              43.535998
            ],
            [
              -112.816278,
              43.535949
            ],
            [
              -112.816704,
              43.449164
            ],
            [
              -112.936835,
              43.449386
            ],
            [
              -112.947009,
              43.367339
            ],
            [
              -112.947016,
              43.284821
            ],
            [
              -113.007318,
              43.284778
            ],
            [
              -113.007712,
              43.110933
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "16",
      "COUNTYFP": "087",
      "COUNTYNS": "00399757",
      "AFFGEOID": "0500000US16087",
      "GEOID": "16087",
      "NAME": "Washington",
      "LSAD": "06",
      "ALAND": 3762870366,
      "AWATER": 53228012,
      "County_state": "Washington,16"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -117.242675,
              44.396548
            ],
            [
              -117.243027,
              44.390974
            ],
            [
              -117.235117,
              44.373853
            ],
            [
              -117.227938,
              44.367975
            ],
            [
              -117.216911,
              44.360163
            ],
            [
              -117.210587,
              44.357703
            ],
            [
              -117.206962,
              44.355206
            ],
            [
              -117.197339,
              44.347406
            ],
            [
              -117.196149,
              44.346362
            ],
            [
              -117.189769,
              44.336585
            ],
            [
              -117.189842,
              44.335007
            ],
            [
              -117.191546,
              44.329621
            ],
            [
              -117.192203,
              44.32863
            ],
            [
              -117.203323,
              44.313024
            ],
            [
              -117.2055,
              44.311789
            ],
            [
              -117.21521,
              44.309116
            ],
            [
              -117.216795,
              44.308236
            ],
            [
              -117.217843,
              44.30718
            ],
            [
              -117.220060563209,
              44.3014039750731
            ],
            [
              -117.220069,
              44.301382
            ],
            [
              -117.222451,
              44.298963
            ],
            [
              -117.222647,
              44.297578
            ],
            [
              -117.216974,
              44.288357
            ],
            [
              -117.198147,
              44.273828
            ],
            [
              -117.193129,
              44.270963
            ],
            [
              -117.170342,
              44.25889
            ],
            [
              -117.15706,
              44.25749
            ],
            [
              -117.143394,
              44.258262
            ],
            [
              -117.138523,
              44.25937
            ],
            [
              -117.133984,
              44.262972
            ],
            [
              -117.133104,
              44.264236
            ],
            [
              -117.13253,
              44.267045
            ],
            [
              -117.130904,
              44.269453
            ],
            [
              -117.121037,
              44.277585
            ],
            [
              -117.118018,
              44.278945
            ],
            [
              -117.111617,
              44.280667
            ],
            [
              -117.107673,
              44.280763
            ],
            [
              -117.104208,
              44.27994
            ],
            [
              -117.102242,
              44.278799
            ],
            [
              -117.098531,
              44.275533
            ],
            [
              -117.09457,
              44.270978
            ],
            [
              -117.093578,
              44.269383
            ],
            [
              -117.090933,
              44.260311
            ],
            [
              -117.089503,
              44.258234
            ],
            [
              -117.07835,
              44.249885
            ],
            [
              -117.067284,
              44.24401
            ],
            [
              -117.059352,
              44.237244
            ],
            [
              -117.05651,
              44.230874
            ],
            [
              -117.05303,
              44.229076
            ],
            [
              -117.050057,
              44.22883
            ],
            [
              -117.047062,
              44.229742
            ],
            [
              -117.045513,
              44.232005
            ],
            [
              -117.042283,
              44.242775
            ],
            [
              -117.03585,
              44.246805
            ],
            [
              -117.031862,
              44.248635
            ],
            [
              -117.027558,
              44.248881
            ],
            [
              -117.025277,
              44.248505
            ],
            [
              -117.020231,
              44.246063
            ],
            [
              -117.016921,
              44.245391
            ],
            [
              -117.001,
              44.245386
            ],
            [
              -116.98687,
              44.245477
            ],
            [
              -116.975905,
              44.242844
            ],
            [
              -116.973542,
              44.23998
            ],
            [
              -116.971958,
              44.235677
            ],
            [
              -116.973945,
              44.225932
            ],
            [
              -116.973701,
              44.208017
            ],
            [
              -116.971675,
              44.197256
            ],
            [
              -116.967259,
              44.194581
            ],
            [
              -116.965498,
              44.194126
            ],
            [
              -116.947591,
              44.191264
            ],
            [
              -116.945256,
              44.191677
            ],
            [
              -116.940534,
              44.19371
            ],
            [
              -116.935443,
              44.193962
            ],
            [
              -116.925392,
              44.191544
            ],
            [
              -116.902752,
              44.179467
            ],
            [
              -116.900103,
              44.176851
            ],
            [
              -116.895757,
              44.171267
            ],
            [
              -116.894083,
              44.160191
            ],
            [
              -116.894309,
              44.158114
            ],
            [
              -116.895931,
              44.154295
            ],
            [
              -116.89713301012901,
              44.1525971517028
            ],
            [
              -116.452068,
              44.151465
            ],
            [
              -116.358492,
              44.151158
            ],
            [
              -116.354106,
              44.167662
            ],
            [
              -116.357027,
              44.207233
            ],
            [
              -116.343323,
              44.217804
            ],
            [
              -116.351477,
              44.237823
            ],
            [
              -116.341794,
              44.276999
            ],
            [
              -116.351187,
              44.287764
            ],
            [
              -116.341666,
              44.310691
            ],
            [
              -116.347394,
              44.322103
            ],
            [
              -116.32864,
              44.357712
            ],
            [
              -116.333911,
              44.376
            ],
            [
              -116.322316,
              44.387028
            ],
            [
              -116.319338,
              44.421183
            ],
            [
              -116.308294,
              44.423441
            ],
            [
              -116.299876,
              44.444576
            ],
            [
              -116.407648,
              44.444436
            ],
            [
              -116.528898,
              44.491545
            ],
            [
              -116.529167,
              44.615154
            ],
            [
              -116.603212,
              44.615192
            ],
            [
              -116.599263,
              44.636862
            ],
            [
              -116.624434,
              44.662859
            ],
            [
              -116.625034,
              44.838952
            ],
            [
              -116.901028451124,
              44.8415357708036
            ],
            [
              -116.905771,
              44.834794
            ],
            [
              -116.920498,
              44.81438
            ],
            [
              -116.928099,
              44.808381
            ],
            [
              -116.931099,
              44.804781
            ],
            [
              -116.933699,
              44.798781
            ],
            [
              -116.933799,
              44.796781
            ],
            [
              -116.933099,
              44.794481
            ],
            [
              -116.9308,
              44.790981
            ],
            [
              -116.9307,
              44.789881
            ],
            [
              -116.9318,
              44.787181
            ],
            [
              -116.9347,
              44.783881
            ],
            [
              -116.9368,
              44.782881
            ],
            [
              -116.949001,
              44.777981
            ],
            [
              -116.966801,
              44.775181
            ],
            [
              -116.970902,
              44.773881
            ],
            [
              -116.972902,
              44.772581
            ],
            [
              -116.977802,
              44.767981
            ],
            [
              -116.986502,
              44.762381
            ],
            [
              -116.992003,
              44.759182
            ],
            [
              -116.998903,
              44.756382
            ],
            [
              -117.006045,
              44.756024
            ],
            [
              -117.013802,
              44.756841
            ],
            [
              -117.03827,
              44.748179
            ],
            [
              -117.044217,
              44.74514
            ],
            [
              -117.062273,
              44.727143
            ],
            [
              -117.060454,
              44.721668
            ],
            [
              -117.061799,
              44.706654
            ],
            [
              -117.063824,
              44.703623
            ],
            [
              -117.072221,
              44.700517
            ],
            [
              -117.07912,
              44.692175
            ],
            [
              -117.080555,
              44.686714
            ],
            [
              -117.080772,
              44.684161
            ],
            [
              -117.091223,
              44.668807
            ],
            [
              -117.095868,
              44.664737
            ],
            [
              -117.096791,
              44.657385
            ],
            [
              -117.094968,
              44.652011
            ],
            [
              -117.098221,
              44.640689
            ],
            [
              -117.108231,
              44.62711
            ],
            [
              -117.114754,
              44.624883
            ],
            [
              -117.117809,
              44.620139
            ],
            [
              -117.120522,
              44.614658
            ],
            [
              -117.125267,
              44.593818
            ],
            [
              -117.124754,
              44.583834
            ],
            [
              -117.126009,
              44.581553
            ],
            [
              -117.133963,
              44.57524
            ],
            [
              -117.138066,
              44.572996
            ],
            [
              -117.14248,
              44.57143
            ],
            [
              -117.146032,
              44.568603
            ],
            [
              -117.148255,
              44.564371
            ],
            [
              -117.147934,
              44.562143
            ],
            [
              -117.14293,
              44.557236
            ],
            [
              -117.144161,
              44.545647
            ],
            [
              -117.149242,
              44.536151
            ],
            [
              -117.152406,
              44.531802
            ],
            [
              -117.161033,
              44.525166
            ],
            [
              -117.167187,
              44.523431
            ],
            [
              -117.181583,
              44.52296
            ],
            [
              -117.185386,
              44.519261
            ],
            [
              -117.189759,
              44.513385
            ],
            [
              -117.19163,
              44.509886
            ],
            [
              -117.191329,
              44.506784
            ],
            [
              -117.192494,
              44.503272
            ],
            [
              -117.194317,
              44.499884
            ],
            [
              -117.200237,
              44.492027
            ],
            [
              -117.208936,
              44.485661
            ],
            [
              -117.211148,
              44.485359
            ],
            [
              -117.216372,
              44.48616
            ],
            [
              -117.224104,
              44.483734
            ],
            [
              -117.225076,
              44.482346
            ],
            [
              -117.225932,
              44.479389
            ],
            [
              -117.225758,
              44.477223
            ],
            [
              -117.224445,
              44.473884
            ],
            [
              -117.221548,
              44.470146
            ],
            [
              -117.217015,
              44.459042
            ],
            [
              -117.215573,
              44.453746
            ],
            [
              -117.214637,
              44.44803
            ],
            [
              -117.215072,
              44.427162
            ],
            [
              -117.218285,
              44.420664
            ],
            [
              -117.225461,
              44.407729
            ],
            [
              -117.22698,
              44.405583
            ],
            [
              -117.234835,
              44.399669
            ],
            [
              -117.242675,
              44.396548
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "16",
      "COUNTYFP": "001",
      "COUNTYNS": "00395066",
      "AFFGEOID": "0500000US16001",
      "GEOID": "16001",
      "NAME": "Ada",
      "LSAD": "06",
      "ALAND": 2724902770,
      "AWATER": 21961614,
      "County_state": "Ada,16"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -116.513364,
              43.45932
            ],
            [
              -116.511897,
              43.29052
            ],
            [
              -116.501165,
              43.282129
            ],
            [
              -116.47208,
              43.291216
            ],
            [
              -116.425275,
              43.293543
            ],
            [
              -116.410247,
              43.284421
            ],
            [
              -116.400944,
              43.260205
            ],
            [
              -116.388585,
              43.253172
            ],
            [
              -116.376059,
              43.231088
            ],
            [
              -116.384352,
              43.217108
            ],
            [
              -116.385955,
              43.193577
            ],
            [
              -116.351745,
              43.164808
            ],
            [
              -116.336044,
              43.161372
            ],
            [
              -116.332572,
              43.148431
            ],
            [
              -116.319411,
              43.143972
            ],
            [
              -116.299945,
              43.119216
            ],
            [
              -116.269363,
              43.117213
            ],
            [
              -116.266207,
              43.112269
            ],
            [
              -115.978651,
              43.113432
            ],
            [
              -115.974881,
              43.36502
            ],
            [
              -115.975719,
              43.591438
            ],
            [
              -115.980178,
              43.585874
            ],
            [
              -116.038706,
              43.629228
            ],
            [
              -116.263384,
              43.793792
            ],
            [
              -116.282019,
              43.807365
            ],
            [
              -116.512502,
              43.807161
            ],
            [
              -116.513123,
              43.634183
            ],
            [
              -116.473492,
              43.634103
            ],
            [
              -116.473613,
              43.619461
            ],
            [
              -116.473535,
              43.575774
            ],
            [
              -116.473597,
              43.459218
            ],
            [
              -116.513364,
              43.45932
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "16",
      "COUNTYFP": "027",
      "COUNTYNS": "00399406",
      "AFFGEOID": "0500000US16027",
      "GEOID": "16027",
      "NAME": "Canyon",
      "LSAD": "06",
      "ALAND": 1520439790,
      "AWATER": 43128953,
      "County_state": "Canyon,16"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -117.029559,
              43.83906
            ],
            [
              -117.032881,
              43.830025
            ],
            [
              -117.023577,
              43.823811
            ],
            [
              -117.021884,
              43.818163
            ],
            [
              -117.02223245857301,
              43.8174250747811
            ],
            [
              -117.026634,
              43.808104
            ],
            [
              -117.026651,
              43.733935
            ],
            [
              -117.026841,
              43.732905
            ],
            [
              -117.026725,
              43.714815
            ],
            [
              -117.026825,
              43.706193
            ],
            [
              -117.026586,
              43.683001
            ],
            [
              -117.026624495654,
              43.6808035152354
            ],
            [
              -117.004612,
              43.673028
            ],
            [
              -116.968202,
              43.667412
            ],
            [
              -116.955309,
              43.635676
            ],
            [
              -116.924039,
              43.619811
            ],
            [
              -116.911538,
              43.603076
            ],
            [
              -116.871466,
              43.592131
            ],
            [
              -116.844978,
              43.5944
            ],
            [
              -116.816511,
              43.577921
            ],
            [
              -116.800028,
              43.54797
            ],
            [
              -116.780266,
              43.526938
            ],
            [
              -116.784918,
              43.514945
            ],
            [
              -116.781336,
              43.478633
            ],
            [
              -116.744331,
              43.4552
            ],
            [
              -116.728721,
              43.436523
            ],
            [
              -116.676483,
              43.400536
            ],
            [
              -116.667349,
              43.386601
            ],
            [
              -116.622569,
              43.370009
            ],
            [
              -116.60716,
              43.351332
            ],
            [
              -116.600335,
              43.331692
            ],
            [
              -116.579167,
              43.306178
            ],
            [
              -116.550396,
              43.299201
            ],
            [
              -116.521832,
              43.297906
            ],
            [
              -116.511897,
              43.29052
            ],
            [
              -116.513364,
              43.45932
            ],
            [
              -116.473597,
              43.459218
            ],
            [
              -116.473535,
              43.575774
            ],
            [
              -116.473613,
              43.619461
            ],
            [
              -116.473492,
              43.634103
            ],
            [
              -116.513123,
              43.634183
            ],
            [
              -116.512502,
              43.807161
            ],
            [
              -116.592575,
              43.807132
            ],
            [
              -116.712672,
              43.807161
            ],
            [
              -116.75314,
              43.807194
            ],
            [
              -116.753114,
              43.799926
            ],
            [
              -116.793092,
              43.799856
            ],
            [
              -116.793189,
              43.792687
            ],
            [
              -116.853154,
              43.79271
            ],
            [
              -116.853132,
              43.807387
            ],
            [
              -116.893746,
              43.807381
            ],
            [
              -116.894086,
              43.821763
            ],
            [
              -116.913796,
              43.821721
            ],
            [
              -116.913963,
              43.8362
            ],
            [
              -116.95452,
              43.836487
            ],
            [
              -116.954422,
              43.880154
            ],
            [
              -116.97967445865001,
              43.8801286658834
            ],
            [
              -116.982347,
              43.86884
            ],
            [
              -116.98294,
              43.86771
            ],
            [
              -116.989598,
              43.864301
            ],
            [
              -116.991415,
              43.863864
            ],
            [
              -116.997391,
              43.864874
            ],
            [
              -116.999061,
              43.864637
            ],
            [
              -117.01077,
              43.862269
            ],
            [
              -117.021075,
              43.858964
            ],
            [
              -117.022707,
              43.854396
            ],
            [
              -117.019444,
              43.852112
            ],
            [
              -117.010701,
              43.849754
            ],
            [
              -117.007697,
              43.846239
            ],
            [
              -117.009002,
              43.842323
            ],
            [
              -117.012831,
              43.840001
            ],
            [
              -117.018465,
              43.841344
            ],
            [
              -117.025156,
              43.841334
            ],
            [
              -117.029559,
              43.83906
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "16",
      "COUNTYFP": "029",
      "COUNTYNS": "00395406",
      "AFFGEOID": "0500000US16029",
      "GEOID": "16029",
      "NAME": "Caribou",
      "LSAD": "06",
      "ALAND": 4569240963,
      "AWATER": 89216874,
      "County_state": "Caribou,16"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -112.149835,
              42.895558
            ],
            [
              -112.136244,
              42.879651
            ],
            [
              -112.135038,
              42.849072
            ],
            [
              -112.120335,
              42.831008
            ],
            [
              -112.126501,
              42.810461
            ],
            [
              -112.138892,
              42.810391
            ],
            [
              -112.145089,
              42.790664
            ],
            [
              -112.141034,
              42.763093
            ],
            [
              -112.13739,
              42.751265
            ],
            [
              -112.114176,
              42.72595
            ],
            [
              -112.10588,
              42.699203
            ],
            [
              -112.08493,
              42.698165
            ],
            [
              -112.057274,
              42.718033
            ],
            [
              -111.969449,
              42.718039
            ],
            [
              -111.967767,
              42.696517
            ],
            [
              -111.956899,
              42.690539
            ],
            [
              -111.966081,
              42.674689
            ],
            [
              -111.946381,
              42.674685
            ],
            [
              -111.946466,
              42.63554
            ],
            [
              -111.921924,
              42.632008
            ],
            [
              -111.916927,
              42.617264
            ],
            [
              -111.916298,
              42.524277
            ],
            [
              -111.896726,
              42.524125
            ],
            [
              -111.895149,
              42.488817
            ],
            [
              -111.885372,
              42.488808
            ],
            [
              -111.885188,
              42.445233
            ],
            [
              -111.875475,
              42.4453
            ],
            [
              -111.875636,
              42.416333
            ],
            [
              -111.738396,
              42.41616
            ],
            [
              -111.727922,
              42.428812
            ],
            [
              -111.733742,
              42.430635
            ],
            [
              -111.716006,
              42.430569
            ],
            [
              -111.711043,
              42.41589
            ],
            [
              -111.599227,
              42.415395
            ],
            [
              -111.579764,
              42.429908
            ],
            [
              -111.610232,
              42.509789
            ],
            [
              -111.621822,
              42.51838
            ],
            [
              -111.63491,
              42.570026
            ],
            [
              -111.61763,
              42.583037
            ],
            [
              -111.599995,
              42.590387
            ],
            [
              -111.368062,
              42.590294
            ],
            [
              -111.340223,
              42.586148
            ],
            [
              -111.341752,
              42.571036
            ],
            [
              -111.318685,
              42.561287
            ],
            [
              -111.279388,
              42.552861
            ],
            [
              -111.242455,
              42.60303
            ],
            [
              -111.240774,
              42.585043
            ],
            [
              -111.222382,
              42.572643
            ],
            [
              -111.236714,
              42.509112
            ],
            [
              -111.248946,
              42.495291
            ],
            [
              -111.234827,
              42.478329
            ],
            [
              -111.189298,
              42.479367
            ],
            [
              -111.171174,
              42.4732
            ],
            [
              -111.159373,
              42.490612
            ],
            [
              -111.131601,
              42.481781
            ],
            [
              -111.109793,
              42.491571
            ],
            [
              -111.095301,
              42.489301
            ],
            [
              -111.062858,
              42.500817
            ],
            [
              -111.066734,
              42.508935
            ],
            [
              -111.046710501596,
              42.5139239264854
            ],
            [
              -111.046017,
              42.582723
            ],
            [
              -111.043564,
              42.722624
            ],
            [
              -111.044135,
              42.874924
            ],
            [
              -111.043980556144,
              42.9534850266501
            ],
            [
              -111.043959,
              42.96445
            ],
            [
              -111.043957,
              42.969482
            ],
            [
              -111.043924,
              42.975063
            ],
            [
              -111.044129,
              43.018702
            ],
            [
              -111.044142896217,
              43.019408
            ],
            [
              -111.353175,
              43.020474
            ],
            [
              -111.359887,
              43.021127
            ],
            [
              -111.589489,
              43.020052
            ],
            [
              -111.947212,
              43.021012
            ],
            [
              -112.063023,
              43.022364
            ],
            [
              -112.052883,
              42.991044
            ],
            [
              -112.063849,
              42.97988
            ],
            [
              -112.088706,
              42.973477
            ],
            [
              -112.082053,
              42.950425
            ],
            [
              -112.102742,
              42.951641
            ],
            [
              -112.127424,
              42.944498
            ],
            [
              -112.143623,
              42.91766
            ],
            [
              -112.133913,
              42.912678
            ],
            [
              -112.149835,
              42.895558
            ]
          ]
        ]
      ]
    }
  }
]
};