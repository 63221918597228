export const counties_nc = {
  "type":"FeatureCollection","name":"nc_counties","features":[
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "157",
      "COUNTYNS": "01008582",
      "AFFGEOID": "0500000US37157",
      "GEOID": "37157",
      "NAME": "Rockingham",
      "LSAD": "06",
      "ALAND": 1464974390,
      "AWATER": 18468477,
      "County_state": "Rockingham,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.03273,
              36.344982
            ],
            [
              -80.035119,
              36.257184
            ],
            [
              -79.867407,
              36.251783
            ],
            [
              -79.666953,
              36.245118
            ],
            [
              -79.53241,
              36.241463
            ],
            [
              -79.531865,
              36.249673
            ],
            [
              -79.5136470557863,
              36.5407578620674
            ],
            [
              -79.666827,
              36.541772
            ],
            [
              -79.667309,
              36.541772
            ],
            [
              -79.71484019625919,
              36.5420023594641
            ],
            [
              -79.82918159040429,
              36.542556513879596
            ],
            [
              -79.887262,
              36.542838
            ],
            [
              -79.8915710847606,
              36.5427389405802
            ],
            [
              -79.904662,
              36.542438
            ],
            [
              -79.9200801435931,
              36.5424471543449
            ],
            [
              -79.966979,
              36.542475
            ],
            [
              -79.967511,
              36.542502
            ],
            [
              -80.027269,
              36.542495
            ],
            [
              -80.0273389759592,
              36.54249511132569
            ],
            [
              -80.03273,
              36.344982
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "069",
      "COUNTYNS": "01008553",
      "AFFGEOID": "0500000US37069",
      "GEOID": "37069",
      "NAME": "Franklin",
      "LSAD": "06",
      "ALAND": 1273631713,
      "AWATER": 7304032,
      "County_state": "Franklin,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.544812,
              36.080846
            ],
            [
              -78.546414,
              36.021826
            ],
            [
              -78.486656,
              35.999873
            ],
            [
              -78.426864,
              35.975012
            ],
            [
              -78.394331,
              35.936876
            ],
            [
              -78.353277,
              35.931056
            ],
            [
              -78.351206,
              35.910845
            ],
            [
              -78.319631,
              35.894529
            ],
            [
              -78.307409,
              35.896738
            ],
            [
              -78.284316,
              35.874578
            ],
            [
              -78.269824,
              35.84499
            ],
            [
              -78.253753,
              35.827609
            ],
            [
              -78.255973,
              35.81812
            ],
            [
              -78.087749,
              36.08159
            ],
            [
              -78.073291,
              36.103138
            ],
            [
              -78.052353,
              36.134327
            ],
            [
              -78.047526,
              36.141507
            ],
            [
              -78.034511,
              36.160871
            ],
            [
              -78.006551,
              36.202633
            ],
            [
              -78.045153,
              36.215387
            ],
            [
              -78.071267,
              36.21638
            ],
            [
              -78.103202,
              36.209062
            ],
            [
              -78.124641,
              36.225648
            ],
            [
              -78.132829,
              36.24633
            ],
            [
              -78.156983,
              36.244416
            ],
            [
              -78.172627,
              36.255326
            ],
            [
              -78.19428,
              36.246839
            ],
            [
              -78.22488,
              36.256553
            ],
            [
              -78.306965,
              36.266191
            ],
            [
              -78.338135,
              36.234448
            ],
            [
              -78.37814,
              36.235065
            ],
            [
              -78.412095,
              36.169597
            ],
            [
              -78.42188,
              36.164309
            ],
            [
              -78.48574,
              36.175353
            ],
            [
              -78.496614,
              36.175199
            ],
            [
              -78.522557,
              36.124393
            ],
            [
              -78.544812,
              36.080846
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "151",
      "COUNTYNS": "01008580",
      "AFFGEOID": "0500000US37151",
      "GEOID": "37151",
      "NAME": "Randolph",
      "LSAD": "06",
      "ALAND": 2026174233,
      "AWATER": 19822770,
      "County_state": "Randolph,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.053437,
              35.801643
            ],
            [
              -80.066842,
              35.505661
            ],
            [
              -79.767957,
              35.511484
            ],
            [
              -79.724457,
              35.512269
            ],
            [
              -79.685683,
              35.512926
            ],
            [
              -79.555804,
              35.515039
            ],
            [
              -79.542428,
              35.843303
            ],
            [
              -79.541728,
              35.899851
            ],
            [
              -79.713233,
              35.90679
            ],
            [
              -79.763309,
              35.908842
            ],
            [
              -80.046869,
              35.920693
            ],
            [
              -80.053437,
              35.801643
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "197",
      "COUNTYNS": "01008597",
      "AFFGEOID": "0500000US37197",
      "GEOID": "37197",
      "NAME": "Yadkin",
      "LSAD": "06",
      "ALAND": 867492667,
      "AWATER": 7236018,
      "County_state": "Yadkin,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.878807,
              36.120025
            ],
            [
              -80.881591,
              36.055718
            ],
            [
              -80.693197,
              36.05126
            ],
            [
              -80.595274,
              36.047683
            ],
            [
              -80.496283,
              36.046545
            ],
            [
              -80.508545,
              36.057954
            ],
            [
              -80.515607,
              36.093303
            ],
            [
              -80.505532,
              36.109155
            ],
            [
              -80.454382,
              36.122139
            ],
            [
              -80.438899,
              36.138755
            ],
            [
              -80.436881,
              36.168465
            ],
            [
              -80.446372,
              36.206548
            ],
            [
              -80.432241,
              36.217995
            ],
            [
              -80.452322,
              36.241419
            ],
            [
              -80.466728,
              36.25982
            ],
            [
              -80.482504,
              36.256125
            ],
            [
              -80.50517,
              36.259916
            ],
            [
              -80.535893,
              36.25968
            ],
            [
              -80.53703,
              36.279957
            ],
            [
              -80.547885,
              36.285419
            ],
            [
              -80.57973,
              36.27772
            ],
            [
              -80.593253,
              36.269845
            ],
            [
              -80.624245,
              36.286822
            ],
            [
              -80.628505,
              36.273033
            ],
            [
              -80.642986,
              36.272649
            ],
            [
              -80.65329,
              36.252635
            ],
            [
              -80.669783,
              36.251035
            ],
            [
              -80.686016,
              36.268822
            ],
            [
              -80.733992,
              36.267528
            ],
            [
              -80.741811,
              36.277798
            ],
            [
              -80.751778,
              36.263379
            ],
            [
              -80.769291,
              36.275158
            ],
            [
              -80.778697,
              36.27177
            ],
            [
              -80.778112,
              36.25763
            ],
            [
              -80.79662,
              36.24808
            ],
            [
              -80.808357,
              36.254341
            ],
            [
              -80.849156,
              36.24088
            ],
            [
              -80.873205,
              36.236305
            ],
            [
              -80.878807,
              36.120025
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "003",
      "COUNTYNS": "01008532",
      "AFFGEOID": "0500000US37003",
      "GEOID": "37003",
      "NAME": "Alexander",
      "LSAD": "06",
      "ALAND": 673358173,
      "AWATER": 9445986,
      "County_state": "Alexander,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.343993,
              35.824163
            ],
            [
              -81.334272,
              35.796281
            ],
            [
              -81.299539,
              35.798338
            ],
            [
              -81.255043,
              35.808272
            ],
            [
              -81.248998,
              35.815716
            ],
            [
              -81.226246,
              35.809652
            ],
            [
              -81.190407,
              35.822385
            ],
            [
              -81.143053,
              35.827887
            ],
            [
              -81.128414,
              35.789383
            ],
            [
              -81.109507,
              35.776594
            ],
            [
              -81.078943,
              35.802775
            ],
            [
              -81.052138,
              35.844995
            ],
            [
              -81.041339,
              35.857855
            ],
            [
              -81.005541,
              35.958287
            ],
            [
              -81.0034,
              35.996737
            ],
            [
              -81.028833,
              36.045652
            ],
            [
              -81.057572,
              36.03155
            ],
            [
              -81.098113,
              36.028162
            ],
            [
              -81.13283,
              36.036923
            ],
            [
              -81.166664,
              36.025668
            ],
            [
              -81.19028,
              36.037212
            ],
            [
              -81.244377,
              36.024832
            ],
            [
              -81.263596,
              36.025818
            ],
            [
              -81.301418,
              36.004291
            ],
            [
              -81.328324,
              35.996583
            ],
            [
              -81.342858,
              35.923362
            ],
            [
              -81.333379,
              35.87915
            ],
            [
              -81.343993,
              35.824163
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "133",
      "COUNTYNS": "01026341",
      "AFFGEOID": "0500000US37133",
      "GEOID": "37133",
      "NAME": "Onslow",
      "LSAD": "06",
      "ALAND": 1973376983,
      "AWATER": 371063373,
      "County_state": "Onslow,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.678882,
              34.78455
            ],
            [
              -77.680127,
              34.720595
            ],
            [
              -77.640365,
              34.63472
            ],
            [
              -77.61512,
              34.579057
            ],
            [
              -77.575961,
              34.476366
            ],
            [
              -77.552079,
              34.471035
            ],
            [
              -77.53217,
              34.457253
            ],
            [
              -77.5179602863695,
              34.4404830452968
            ],
            [
              -77.491796,
              34.456098
            ],
            [
              -77.462922,
              34.471354
            ],
            [
              -77.322524,
              34.535574
            ],
            [
              -77.240991,
              34.587507
            ],
            [
              -77.209161,
              34.605032
            ],
            [
              -77.17603854445619,
              34.619294129806
            ],
            [
              -77.169701,
              34.622023
            ],
            [
              -77.136843,
              34.632926
            ],
            [
              -77.1081661345391,
              34.6405897619796
            ],
            [
              -77.113355,
              34.649951
            ],
            [
              -77.096972,
              34.672401
            ],
            [
              -77.112901,
              34.67969
            ],
            [
              -77.105153,
              34.694712
            ],
            [
              -77.109153,
              34.717874
            ],
            [
              -77.122228,
              34.732993
            ],
            [
              -77.125646,
              34.756707
            ],
            [
              -77.139946,
              34.75974
            ],
            [
              -77.165986,
              34.785032
            ],
            [
              -77.172104,
              34.780385
            ],
            [
              -77.175131,
              34.795534
            ],
            [
              -77.196257,
              34.810991
            ],
            [
              -77.1794,
              34.829931
            ],
            [
              -77.200266,
              34.833284
            ],
            [
              -77.219466,
              34.871498
            ],
            [
              -77.221954,
              34.886882
            ],
            [
              -77.234165,
              34.886331
            ],
            [
              -77.237357,
              34.899962
            ],
            [
              -77.248333,
              34.900101
            ],
            [
              -77.267647,
              34.92579
            ],
            [
              -77.307466,
              34.937237
            ],
            [
              -77.331554,
              34.93915
            ],
            [
              -77.365167,
              34.92355
            ],
            [
              -77.366768,
              34.90865
            ],
            [
              -77.401365,
              34.924194
            ],
            [
              -77.502771,
              34.969635
            ],
            [
              -77.638967,
              34.98446
            ],
            [
              -77.674805,
              34.9739
            ],
            [
              -77.653352,
              34.940535
            ],
            [
              -77.651045,
              34.923341
            ],
            [
              -77.66606,
              34.839651
            ],
            [
              -77.678882,
              34.78455
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "135",
      "COUNTYNS": "01008576",
      "AFFGEOID": "0500000US37135",
      "GEOID": "37135",
      "NAME": "Orange",
      "LSAD": "06",
      "ALAND": 1029600383,
      "AWATER": 8876665,
      "County_state": "Orange,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.264027,
              35.914114
            ],
            [
              -79.268243,
              35.909182
            ],
            [
              -79.257629,
              35.903039
            ],
            [
              -79.249538,
              35.87681
            ],
            [
              -79.221534,
              35.875467
            ],
            [
              -79.148579,
              35.866898
            ],
            [
              -79.082036,
              35.860776
            ],
            [
              -79.016305,
              35.86321
            ],
            [
              -79.0095,
              35.902401
            ],
            [
              -79.004389,
              35.9315
            ],
            [
              -78.982633,
              36.056544
            ],
            [
              -78.971617,
              36.119456
            ],
            [
              -78.970666,
              36.125018
            ],
            [
              -78.968401,
              36.137509
            ],
            [
              -78.9675,
              36.142508
            ],
            [
              -78.950597,
              36.23932
            ],
            [
              -79.153997,
              36.241875
            ],
            [
              -79.257952,
              36.243445
            ],
            [
              -79.261379,
              36.071067
            ],
            [
              -79.264027,
              35.914114
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "025",
      "COUNTYNS": "01008540",
      "AFFGEOID": "0500000US37025",
      "GEOID": "37025",
      "NAME": "Cabarrus",
      "LSAD": "06",
      "ALAND": 935603275,
      "AWATER": 7006691,
      "County_state": "Cabarrus,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.784167,
              35.506017
            ],
            [
              -80.786864,
              35.493156
            ],
            [
              -80.752167,
              35.41405
            ],
            [
              -80.766414,
              35.401478
            ],
            [
              -80.732757,
              35.371931
            ],
            [
              -80.693061,
              35.346379
            ],
            [
              -80.682774,
              35.315662
            ],
            [
              -80.672489,
              35.284568
            ],
            [
              -80.66648,
              35.267934
            ],
            [
              -80.627934,
              35.248669
            ],
            [
              -80.599733,
              35.234105
            ],
            [
              -80.550349,
              35.208412
            ],
            [
              -80.504985,
              35.184998
            ],
            [
              -80.506171,
              35.192447
            ],
            [
              -80.484356,
              35.216368
            ],
            [
              -80.445342,
              35.275719
            ],
            [
              -80.295421,
              35.50292
            ],
            [
              -80.570602,
              35.503318
            ],
            [
              -80.609201,
              35.504748
            ],
            [
              -80.645898,
              35.505843
            ],
            [
              -80.675478,
              35.50651
            ],
            [
              -80.688275,
              35.505424
            ],
            [
              -80.737379,
              35.505804
            ],
            [
              -80.784167,
              35.506017
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "097",
      "COUNTYNS": "01008565",
      "AFFGEOID": "0500000US37097",
      "GEOID": "37097",
      "NAME": "Iredell",
      "LSAD": "06",
      "ALAND": 1487115275,
      "AWATER": 61893483,
      "County_state": "Iredell,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.078943,
              35.802775
            ],
            [
              -81.109507,
              35.776594
            ],
            [
              -81.067863,
              35.7381
            ],
            [
              -81.061328,
              35.710641
            ],
            [
              -81.005321,
              35.710209
            ],
            [
              -80.966305,
              35.670313
            ],
            [
              -80.951241,
              35.641413
            ],
            [
              -80.92754,
              35.622978
            ],
            [
              -80.925288,
              35.619483
            ],
            [
              -80.943556,
              35.60539
            ],
            [
              -80.933361,
              35.59239
            ],
            [
              -80.951295,
              35.581273
            ],
            [
              -80.960025,
              35.54702
            ],
            [
              -80.939685,
              35.529529
            ],
            [
              -80.95658,
              35.531571
            ],
            [
              -80.962142,
              35.498656
            ],
            [
              -80.948122,
              35.491167
            ],
            [
              -80.907512,
              35.514839
            ],
            [
              -80.784167,
              35.506017
            ],
            [
              -80.737379,
              35.505804
            ],
            [
              -80.736909,
              35.525428
            ],
            [
              -80.743617,
              35.559549
            ],
            [
              -80.757684,
              35.616239
            ],
            [
              -80.771582,
              35.672163
            ],
            [
              -80.744691,
              35.748502
            ],
            [
              -80.707826,
              35.852929
            ],
            [
              -80.693197,
              36.05126
            ],
            [
              -80.881591,
              36.055718
            ],
            [
              -80.975675,
              36.05881
            ],
            [
              -81.028833,
              36.045652
            ],
            [
              -81.0034,
              35.996737
            ],
            [
              -81.005541,
              35.958287
            ],
            [
              -81.041339,
              35.857855
            ],
            [
              -81.052138,
              35.844995
            ],
            [
              -81.078943,
              35.802775
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "169",
      "COUNTYNS": "01008586",
      "AFFGEOID": "0500000US37169",
      "GEOID": "37169",
      "NAME": "Stokes",
      "LSAD": "06",
      "ALAND": 1162938788,
      "AWATER": 17584342,
      "County_state": "Stokes,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.4401,
              36.55063
            ],
            [
              -80.451696,
              36.261502
            ],
            [
              -80.035119,
              36.257184
            ],
            [
              -80.03273,
              36.344982
            ],
            [
              -80.0273389759592,
              36.54249511132569
            ],
            [
              -80.0534585356035,
              36.5425366652957
            ],
            [
              -80.122183,
              36.542646
            ],
            [
              -80.169535,
              36.54319
            ],
            [
              -80.171636,
              36.543219
            ],
            [
              -80.225408,
              36.543748
            ],
            [
              -80.228263,
              36.543867
            ],
            [
              -80.295243,
              36.543973
            ],
            [
              -80.431605,
              36.550219
            ],
            [
              -80.432628,
              36.550302
            ],
            [
              -80.4401,
              36.55063
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "081",
      "COUNTYNS": "01008558",
      "AFFGEOID": "0500000US37081",
      "GEOID": "37081",
      "NAME": "Guilford",
      "LSAD": "06",
      "ALAND": 1672545660,
      "AWATER": 30723331,
      "County_state": "Guilford,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.043238,
              36.010758
            ],
            [
              -80.046869,
              35.920693
            ],
            [
              -79.763309,
              35.908842
            ],
            [
              -79.713233,
              35.90679
            ],
            [
              -79.541728,
              35.899851
            ],
            [
              -79.53241,
              36.241463
            ],
            [
              -79.666953,
              36.245118
            ],
            [
              -79.867407,
              36.251783
            ],
            [
              -80.035119,
              36.257184
            ],
            [
              -80.038554,
              36.146463
            ],
            [
              -80.040217,
              36.105419
            ],
            [
              -80.04022,
              36.105268
            ],
            [
              -80.043238,
              36.010758
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "145",
      "COUNTYNS": "01008577",
      "AFFGEOID": "0500000US37145",
      "GEOID": "37145",
      "NAME": "Person",
      "LSAD": "06",
      "ALAND": 1016141549,
      "AWATER": 31218720,
      "County_state": "Person,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.1383311871639,
              36.541738061604995
            ],
            [
              -79.153997,
              36.241875
            ],
            [
              -78.950597,
              36.23932
            ],
            [
              -78.802335,
              36.235794
            ],
            [
              -78.797482,
              36.432806
            ],
            [
              -78.794845,
              36.526439
            ],
            [
              -78.7962710014091,
              36.5417130131513
            ],
            [
              -78.7963,
              36.541713
            ],
            [
              -78.914543,
              36.541972
            ],
            [
              -78.91542,
              36.541974
            ],
            [
              -78.942009,
              36.542113
            ],
            [
              -78.942254,
              36.542079
            ],
            [
              -78.970577,
              36.542154
            ],
            [
              -78.971814,
              36.542123
            ],
            [
              -78.9791747288393,
              36.5420962856979
            ],
            [
              -79.124736,
              36.541568
            ],
            [
              -79.126078,
              36.541533
            ],
            [
              -79.137936,
              36.541739
            ],
            [
              -79.1383311871639,
              36.541738061604995
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "181",
      "COUNTYNS": "01008591",
      "AFFGEOID": "0500000US37181",
      "GEOID": "37181",
      "NAME": "Vance",
      "LSAD": "06",
      "ALAND": 653705784,
      "AWATER": 42187365,
      "County_state": "Vance,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.511224,
              36.454808
            ],
            [
              -78.510657,
              36.401714
            ],
            [
              -78.508332,
              36.182923
            ],
            [
              -78.496614,
              36.175199
            ],
            [
              -78.48574,
              36.175353
            ],
            [
              -78.42188,
              36.164309
            ],
            [
              -78.412095,
              36.169597
            ],
            [
              -78.37814,
              36.235065
            ],
            [
              -78.338135,
              36.234448
            ],
            [
              -78.306965,
              36.266191
            ],
            [
              -78.277165,
              36.306132
            ],
            [
              -78.3239883198677,
              36.5438082240453
            ],
            [
              -78.436333,
              36.542666
            ],
            [
              -78.441199,
              36.542687
            ],
            [
              -78.4569669669848,
              36.5424740409633
            ],
            [
              -78.472907,
              36.521232
            ],
            [
              -78.497783,
              36.514477
            ],
            [
              -78.511224,
              36.454808
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "107",
      "COUNTYNS": "01008568",
      "AFFGEOID": "0500000US37107",
      "GEOID": "37107",
      "NAME": "Lenoir",
      "LSAD": "06",
      "ALAND": 1033630023,
      "AWATER": 5900300,
      "County_state": "Lenoir,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.827998,
              35.28092
            ],
            [
              -77.834249,
              35.177846
            ],
            [
              -77.769177,
              35.145576
            ],
            [
              -77.749572,
              35.10861
            ],
            [
              -77.746037,
              35.02234
            ],
            [
              -77.731026,
              35.008138
            ],
            [
              -77.601282,
              35.071368
            ],
            [
              -77.507463,
              35.172814
            ],
            [
              -77.527182,
              35.243001
            ],
            [
              -77.505843,
              35.237414
            ],
            [
              -77.473692,
              35.228985
            ],
            [
              -77.464316,
              35.239155
            ],
            [
              -77.431643,
              35.251049
            ],
            [
              -77.426172,
              35.262375
            ],
            [
              -77.449136,
              35.301839
            ],
            [
              -77.431091,
              35.313008
            ],
            [
              -77.431388,
              35.329685
            ],
            [
              -77.410498,
              35.331868
            ],
            [
              -77.410923,
              35.337799
            ],
            [
              -77.393934,
              35.34263
            ],
            [
              -77.391026,
              35.339533
            ],
            [
              -77.391667,
              35.345386
            ],
            [
              -77.408074,
              35.353347
            ],
            [
              -77.426191,
              35.349524
            ],
            [
              -77.429017,
              35.362951
            ],
            [
              -77.448013,
              35.371389
            ],
            [
              -77.451201,
              35.394037
            ],
            [
              -77.463431,
              35.399628
            ],
            [
              -77.469904,
              35.419924
            ],
            [
              -77.475515,
              35.426655
            ],
            [
              -77.481966,
              35.414451
            ],
            [
              -77.49786,
              35.413254
            ],
            [
              -77.506701,
              35.421896
            ],
            [
              -77.528338,
              35.414336
            ],
            [
              -77.534173,
              35.398104
            ],
            [
              -77.589323,
              35.373763
            ],
            [
              -77.610962,
              35.378633
            ],
            [
              -77.639101,
              35.354086
            ],
            [
              -77.677679,
              35.347324
            ],
            [
              -77.697516,
              35.377097
            ],
            [
              -77.741176,
              35.371966
            ],
            [
              -77.777669,
              35.367664
            ],
            [
              -77.806248,
              35.368754
            ],
            [
              -77.818047,
              35.357435
            ],
            [
              -77.82418,
              35.343811
            ],
            [
              -77.827998,
              35.28092
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "045",
      "COUNTYNS": "01008547",
      "AFFGEOID": "0500000US37045",
      "GEOID": "37045",
      "NAME": "Cleveland",
      "LSAD": "06",
      "ALAND": 1202395052,
      "AWATER": 10205340,
      "County_state": "Cleveland,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.759541,
              35.222646
            ],
            [
              -81.7681085961812,
              35.180388327917804
            ],
            [
              -81.738492,
              35.179511
            ],
            [
              -81.716259,
              35.178852
            ],
            [
              -81.680801,
              35.177331
            ],
            [
              -81.646707,
              35.175869
            ],
            [
              -81.614877,
              35.174504
            ],
            [
              -81.581681,
              35.17308
            ],
            [
              -81.54515,
              35.171744
            ],
            [
              -81.494265,
              35.169882
            ],
            [
              -81.493401,
              35.169951
            ],
            [
              -81.461408,
              35.168657
            ],
            [
              -81.452398,
              35.168293
            ],
            [
              -81.445627,
              35.168024
            ],
            [
              -81.366691,
              35.164893
            ],
            [
              -81.3665997502835,
              35.164889487764
            ],
            [
              -81.3280393754573,
              35.163405284384005
            ],
            [
              -81.323668,
              35.189605
            ],
            [
              -81.321729,
              35.260449
            ],
            [
              -81.353507,
              35.277351
            ],
            [
              -81.366121,
              35.313413
            ],
            [
              -81.356682,
              35.330604
            ],
            [
              -81.366023,
              35.336129
            ],
            [
              -81.398924,
              35.353138
            ],
            [
              -81.4556,
              35.419811
            ],
            [
              -81.458803,
              35.423681
            ],
            [
              -81.481383,
              35.471503
            ],
            [
              -81.513004,
              35.523317
            ],
            [
              -81.515421,
              35.558297
            ],
            [
              -81.537599,
              35.564228
            ],
            [
              -81.56678,
              35.558448
            ],
            [
              -81.607193,
              35.566831
            ],
            [
              -81.648967,
              35.56161
            ],
            [
              -81.674224,
              35.568804
            ],
            [
              -81.690949,
              35.584546
            ],
            [
              -81.691989,
              35.580041
            ],
            [
              -81.702853,
              35.357304
            ],
            [
              -81.759541,
              35.222646
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "175",
      "COUNTYNS": "01008589",
      "AFFGEOID": "0500000US37175",
      "GEOID": "37175",
      "NAME": "Transylvania",
      "LSAD": "06",
      "ALAND": 980105287,
      "AWATER": 5145714,
      "County_state": "Transylvania,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.044942,
              35.077127
            ],
            [
              -83.056889,
              35.053827
            ],
            [
              -83.039187,
              35.048109
            ],
            [
              -83.032458,
              35.037734
            ],
            [
              -83.00866626840349,
              35.027579743047006
            ],
            [
              -82.999867,
              35.02995
            ],
            [
              -82.897499,
              35.056021
            ],
            [
              -82.809766,
              35.078748
            ],
            [
              -82.787867,
              35.085024
            ],
            [
              -82.783283,
              35.0856
            ],
            [
              -82.78113,
              35.084585
            ],
            [
              -82.781062,
              35.084492
            ],
            [
              -82.778651,
              35.083575
            ],
            [
              -82.776357,
              35.081349
            ],
            [
              -82.777407,
              35.076885
            ],
            [
              -82.779116,
              35.073674
            ],
            [
              -82.779928,
              35.072206
            ],
            [
              -82.779928,
              35.070435
            ],
            [
              -82.780546,
              35.069043
            ],
            [
              -82.781973,
              35.066817
            ],
            [
              -82.777376,
              35.064143
            ],
            [
              -82.770046,
              35.065476
            ],
            [
              -82.764464,
              35.068177
            ],
            [
              -82.757704,
              35.068019
            ],
            [
              -82.754162,
              35.069629
            ],
            [
              -82.751265,
              35.073463
            ],
            [
              -82.751102,
              35.075749
            ],
            [
              -82.749491,
              35.078487
            ],
            [
              -82.746431,
              35.079131
            ],
            [
              -82.741761,
              35.078326
            ],
            [
              -82.738379,
              35.079453
            ],
            [
              -82.735904,
              35.082701
            ],
            [
              -82.730971,
              35.086378
            ],
            [
              -82.729683,
              35.087827
            ],
            [
              -82.729517,
              35.09059
            ],
            [
              -82.728961,
              35.091978
            ],
            [
              -82.72701,
              35.094142
            ],
            [
              -82.723462,
              35.094341
            ],
            [
              -82.720442,
              35.093265
            ],
            [
              -82.715297,
              35.092943
            ],
            [
              -82.707152,
              35.096542
            ],
            [
              -82.703916,
              35.097651
            ],
            [
              -82.701017,
              35.09749
            ],
            [
              -82.698602,
              35.097168
            ],
            [
              -82.694898,
              35.098456
            ],
            [
              -82.689634,
              35.104117
            ],
            [
              -82.688456,
              35.106347
            ],
            [
              -82.688778,
              35.108602
            ],
            [
              -82.690711,
              35.111501
            ],
            [
              -82.691355,
              35.113272
            ],
            [
              -82.691194,
              35.114721
            ],
            [
              -82.690549,
              35.116171
            ],
            [
              -82.688939,
              35.118103
            ],
            [
              -82.687641,
              35.119287
            ],
            [
              -82.686738,
              35.11979
            ],
            [
              -82.686496,
              35.121822
            ],
            [
              -82.68604,
              35.124545
            ],
            [
              -82.683625,
              35.125833
            ],
            [
              -82.680887,
              35.126155
            ],
            [
              -82.676861,
              35.12535
            ],
            [
              -82.675089,
              35.123257
            ],
            [
              -82.673318,
              35.121002
            ],
            [
              -82.672513,
              35.119392
            ],
            [
              -82.669614,
              35.118103
            ],
            [
              -82.662381,
              35.118123
            ],
            [
              -82.657858,
              35.119392
            ],
            [
              -82.65351,
              35.121968
            ],
            [
              -82.651416,
              35.124867
            ],
            [
              -82.648694,
              35.12677
            ],
            [
              -82.645296,
              35.12841
            ],
            [
              -82.642237,
              35.129215
            ],
            [
              -82.63821,
              35.128088
            ],
            [
              -82.634668,
              35.126317
            ],
            [
              -82.632574,
              35.125833
            ],
            [
              -82.629031,
              35.126155
            ],
            [
              -82.626436,
              35.127903
            ],
            [
              -82.624847,
              35.130432
            ],
            [
              -82.621185,
              35.134635
            ],
            [
              -82.617993,
              35.13527
            ],
            [
              -82.614402,
              35.136701
            ],
            [
              -82.613866,
              35.137529
            ],
            [
              -82.612444,
              35.138234
            ],
            [
              -82.609706,
              35.139039
            ],
            [
              -82.602358,
              35.139036
            ],
            [
              -82.59814,
              35.137729
            ],
            [
              -82.59243,
              35.139002
            ],
            [
              -82.588158,
              35.142928
            ],
            [
              -82.586035,
              35.143142
            ],
            [
              -82.581836,
              35.142352
            ],
            [
              -82.580687,
              35.141742
            ],
            [
              -82.578316,
              35.142104
            ],
            [
              -82.5742297924061,
              35.1436424056196
            ],
            [
              -82.591464,
              35.164866
            ],
            [
              -82.603968,
              35.221791
            ],
            [
              -82.604267,
              35.25894
            ],
            [
              -82.616194,
              35.260245
            ],
            [
              -82.610186,
              35.288199
            ],
            [
              -82.619248,
              35.303708
            ],
            [
              -82.647137,
              35.328721
            ],
            [
              -82.745139,
              35.422967
            ],
            [
              -82.74562,
              35.407345
            ],
            [
              -82.774539,
              35.393308
            ],
            [
              -82.78777,
              35.380994
            ],
            [
              -82.805116,
              35.347966
            ],
            [
              -82.814964,
              35.343923
            ],
            [
              -82.83199,
              35.318343
            ],
            [
              -82.876056,
              35.314625
            ],
            [
              -82.920881,
              35.292037
            ],
            [
              -82.919241,
              35.271121
            ],
            [
              -82.927234,
              35.237303
            ],
            [
              -82.935913,
              35.22486
            ],
            [
              -82.959866,
              35.215168
            ],
            [
              -82.968805,
              35.194592
            ],
            [
              -82.986919,
              35.187638
            ],
            [
              -82.981211,
              35.16764
            ],
            [
              -82.996627,
              35.163578
            ],
            [
              -82.994809,
              35.153521
            ],
            [
              -82.980903,
              35.148351
            ],
            [
              -82.983216,
              35.13192
            ],
            [
              -83.021032,
              35.095635
            ],
            [
              -83.044942,
              35.077127
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "049",
      "COUNTYNS": "01026123",
      "AFFGEOID": "0500000US37049",
      "GEOID": "37049",
      "NAME": "Craven",
      "LSAD": "06",
      "ALAND": 1830335129,
      "AWATER": 172457083,
      "County_state": "Craven,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.464316,
              35.239155
            ],
            [
              -77.473692,
              35.228985
            ],
            [
              -77.451745,
              35.218717
            ],
            [
              -77.329244,
              35.149829
            ],
            [
              -77.187679,
              35.081086
            ],
            [
              -77.189422,
              35.06667
            ],
            [
              -77.180592,
              35.05522
            ],
            [
              -77.164146,
              35.065406
            ],
            [
              -77.148345,
              35.054675
            ],
            [
              -77.130361,
              35.072371
            ],
            [
              -77.113487,
              35.072442
            ],
            [
              -77.110237,
              35.046694
            ],
            [
              -77.098889,
              35.027152
            ],
            [
              -77.103101,
              35.01707
            ],
            [
              -77.108851,
              34.83078
            ],
            [
              -77.047409,
              34.836177
            ],
            [
              -77.078263,
              34.811322
            ],
            [
              -76.954438,
              34.809066
            ],
            [
              -76.954773,
              34.824508
            ],
            [
              -76.882195,
              34.836704
            ],
            [
              -76.883201,
              34.844893
            ],
            [
              -76.758008,
              34.846285
            ],
            [
              -76.693018,
              34.86918
            ],
            [
              -76.682974,
              34.899269
            ],
            [
              -76.662675,
              34.911853
            ],
            [
              -76.652571,
              34.937595
            ],
            [
              -76.682427,
              34.961899
            ],
            [
              -76.6837426596136,
              34.9629487494884
            ],
            [
              -76.762931,
              34.920374
            ],
            [
              -76.89354,
              34.957495
            ],
            [
              -76.977404,
              35.004926
            ],
            [
              -76.989778,
              35.045484
            ],
            [
              -76.982904,
              35.060607
            ],
            [
              -76.95846526039449,
              35.0476471072188
            ],
            [
              -76.941203,
              35.053
            ],
            [
              -76.936149,
              35.06191
            ],
            [
              -76.938575,
              35.089725
            ],
            [
              -76.931113,
              35.117198
            ],
            [
              -76.968465,
              35.129273
            ],
            [
              -76.987537,
              35.154267
            ],
            [
              -76.970302,
              35.163657
            ],
            [
              -76.96293,
              35.197857
            ],
            [
              -76.942629,
              35.213324
            ],
            [
              -76.93259,
              35.230286
            ],
            [
              -76.906081,
              35.235945
            ],
            [
              -76.895919,
              35.253889
            ],
            [
              -76.958887,
              35.301013
            ],
            [
              -77.189115,
              35.418362
            ],
            [
              -77.203319,
              35.398284
            ],
            [
              -77.22716,
              35.399786
            ],
            [
              -77.239649,
              35.381361
            ],
            [
              -77.239072,
              35.360906
            ],
            [
              -77.260924,
              35.351865
            ],
            [
              -77.279283,
              35.375618
            ],
            [
              -77.29434,
              35.379137
            ],
            [
              -77.35038,
              35.328431
            ],
            [
              -77.391026,
              35.339533
            ],
            [
              -77.393934,
              35.34263
            ],
            [
              -77.410923,
              35.337799
            ],
            [
              -77.410498,
              35.331868
            ],
            [
              -77.431388,
              35.329685
            ],
            [
              -77.431091,
              35.313008
            ],
            [
              -77.449136,
              35.301839
            ],
            [
              -77.426172,
              35.262375
            ],
            [
              -77.431643,
              35.251049
            ],
            [
              -77.464316,
              35.239155
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "065",
      "COUNTYNS": "01008551",
      "AFFGEOID": "0500000US37065",
      "GEOID": "37065",
      "NAME": "Edgecombe",
      "LSAD": "06",
      "ALAND": 1309062991,
      "AWATER": 3717083,
      "County_state": "Edgecombe,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.819578,
              35.884063
            ],
            [
              -77.828444,
              35.867208
            ],
            [
              -77.751339,
              35.827794
            ],
            [
              -77.750406,
              35.817831
            ],
            [
              -77.73823,
              35.783964
            ],
            [
              -77.718436,
              35.728823
            ],
            [
              -77.674837,
              35.689104
            ],
            [
              -77.665131,
              35.674935
            ],
            [
              -77.531262,
              35.753528
            ],
            [
              -77.516261,
              35.761334
            ],
            [
              -77.472286,
              35.801095
            ],
            [
              -77.446088,
              35.808728
            ],
            [
              -77.425489,
              35.805371
            ],
            [
              -77.409614,
              35.826404
            ],
            [
              -77.390154,
              35.833062
            ],
            [
              -77.350369,
              35.819228
            ],
            [
              -77.349433,
              35.867414
            ],
            [
              -77.342418,
              35.905262
            ],
            [
              -77.402614,
              36.004966
            ],
            [
              -77.421138,
              36.003609
            ],
            [
              -77.441638,
              36.014683
            ],
            [
              -77.439301,
              36.021212
            ],
            [
              -77.475072,
              36.037818
            ],
            [
              -77.517646,
              36.036775
            ],
            [
              -77.529202,
              36.039267
            ],
            [
              -77.539983,
              36.060357
            ],
            [
              -77.528352,
              36.069389
            ],
            [
              -77.5407,
              36.096085
            ],
            [
              -77.593948,
              36.102052
            ],
            [
              -77.6056,
              36.11041
            ],
            [
              -77.630148,
              36.115565
            ],
            [
              -77.631628,
              36.126667
            ],
            [
              -77.644676,
              36.138187
            ],
            [
              -77.670295,
              36.137288
            ],
            [
              -77.677895,
              36.153808
            ],
            [
              -77.697956,
              36.15315
            ],
            [
              -77.706966,
              36.115503
            ],
            [
              -77.740451,
              36.063485
            ],
            [
              -77.770381,
              36.009638
            ],
            [
              -77.779058,
              35.981296
            ],
            [
              -77.819578,
              35.884063
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "027",
      "COUNTYNS": "01008541",
      "AFFGEOID": "0500000US37027",
      "GEOID": "37027",
      "NAME": "Caldwell",
      "LSAD": "06",
      "ALAND": 1222192511,
      "AWATER": 7038401,
      "County_state": "Caldwell,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.810516,
              36.111511
            ],
            [
              -81.76114,
              36.090129
            ],
            [
              -81.758517,
              36.077164
            ],
            [
              -81.735673,
              36.067584
            ],
            [
              -81.807162,
              35.961955
            ],
            [
              -81.796171,
              35.948788
            ],
            [
              -81.777668,
              35.947439
            ],
            [
              -81.776077,
              35.927014
            ],
            [
              -81.758733,
              35.928466
            ],
            [
              -81.744587,
              35.908683
            ],
            [
              -81.709749,
              35.875003
            ],
            [
              -81.639217,
              35.848346
            ],
            [
              -81.630065,
              35.830377
            ],
            [
              -81.610385,
              35.819612
            ],
            [
              -81.600809,
              35.825096
            ],
            [
              -81.555994,
              35.777538
            ],
            [
              -81.444197,
              35.784301
            ],
            [
              -81.440562,
              35.773409
            ],
            [
              -81.426452,
              35.772004
            ],
            [
              -81.399218,
              35.75856
            ],
            [
              -81.377487,
              35.757498
            ],
            [
              -81.363796,
              35.767802
            ],
            [
              -81.353514,
              35.776268
            ],
            [
              -81.355682,
              35.779721
            ],
            [
              -81.334272,
              35.796281
            ],
            [
              -81.343993,
              35.824163
            ],
            [
              -81.333379,
              35.87915
            ],
            [
              -81.342858,
              35.923362
            ],
            [
              -81.328324,
              35.996583
            ],
            [
              -81.343814,
              36.023309
            ],
            [
              -81.381479,
              36.044611
            ],
            [
              -81.396487,
              36.040885
            ],
            [
              -81.389463,
              36.050128
            ],
            [
              -81.397773,
              36.066431
            ],
            [
              -81.424141,
              36.077801
            ],
            [
              -81.44477,
              36.072131
            ],
            [
              -81.458782,
              36.091382
            ],
            [
              -81.467274,
              36.085657
            ],
            [
              -81.47729,
              36.09685
            ],
            [
              -81.508413,
              36.096977
            ],
            [
              -81.545488,
              36.117475
            ],
            [
              -81.660632,
              36.118399
            ],
            [
              -81.675149,
              36.12253
            ],
            [
              -81.810516,
              36.111511
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "147",
      "COUNTYNS": "01008578",
      "AFFGEOID": "0500000US37147",
      "GEOID": "37147",
      "NAME": "Pitt",
      "LSAD": "06",
      "ALAND": 1689619168,
      "AWATER": 8248766,
      "County_state": "Pitt,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.700692,
              35.652295
            ],
            [
              -77.689025,
              35.649594
            ],
            [
              -77.681622,
              35.630762
            ],
            [
              -77.662438,
              35.619252
            ],
            [
              -77.61037,
              35.567552
            ],
            [
              -77.593045,
              35.564143
            ],
            [
              -77.570833,
              35.548842
            ],
            [
              -77.560323,
              35.533491
            ],
            [
              -77.52501,
              35.528139
            ],
            [
              -77.523888,
              35.518535
            ],
            [
              -77.503408,
              35.517975
            ],
            [
              -77.511785,
              35.497836
            ],
            [
              -77.494116,
              35.477423
            ],
            [
              -77.492659,
              35.461467
            ],
            [
              -77.476743,
              35.445412
            ],
            [
              -77.475515,
              35.426655
            ],
            [
              -77.469904,
              35.419924
            ],
            [
              -77.463431,
              35.399628
            ],
            [
              -77.451201,
              35.394037
            ],
            [
              -77.448013,
              35.371389
            ],
            [
              -77.429017,
              35.362951
            ],
            [
              -77.426191,
              35.349524
            ],
            [
              -77.408074,
              35.353347
            ],
            [
              -77.391667,
              35.345386
            ],
            [
              -77.391026,
              35.339533
            ],
            [
              -77.35038,
              35.328431
            ],
            [
              -77.29434,
              35.379137
            ],
            [
              -77.279283,
              35.375618
            ],
            [
              -77.260924,
              35.351865
            ],
            [
              -77.239072,
              35.360906
            ],
            [
              -77.239649,
              35.381361
            ],
            [
              -77.22716,
              35.399786
            ],
            [
              -77.203319,
              35.398284
            ],
            [
              -77.189115,
              35.418362
            ],
            [
              -77.19123,
              35.435026
            ],
            [
              -77.173841,
              35.456596
            ],
            [
              -77.17621,
              35.5191
            ],
            [
              -77.150605,
              35.537287
            ],
            [
              -77.127254,
              35.553973
            ],
            [
              -77.100803,
              35.551455
            ],
            [
              -77.08472,
              35.55275
            ],
            [
              -77.087435,
              35.564119
            ],
            [
              -77.110453,
              35.56963
            ],
            [
              -77.109897,
              35.583123
            ],
            [
              -77.125507,
              35.584659
            ],
            [
              -77.121915,
              35.592197
            ],
            [
              -77.150823,
              35.602598
            ],
            [
              -77.140506,
              35.605301
            ],
            [
              -77.14239,
              35.61788
            ],
            [
              -77.172766,
              35.6391
            ],
            [
              -77.177453,
              35.669366
            ],
            [
              -77.167093,
              35.672622
            ],
            [
              -77.169849,
              35.684339
            ],
            [
              -77.195772,
              35.699779
            ],
            [
              -77.185706,
              35.719715
            ],
            [
              -77.170256,
              35.719045
            ],
            [
              -77.174,
              35.732831
            ],
            [
              -77.216849,
              35.760243
            ],
            [
              -77.259792,
              35.765222
            ],
            [
              -77.2552,
              35.786352
            ],
            [
              -77.314101,
              35.806694
            ],
            [
              -77.350369,
              35.819228
            ],
            [
              -77.390154,
              35.833062
            ],
            [
              -77.409614,
              35.826404
            ],
            [
              -77.425489,
              35.805371
            ],
            [
              -77.446088,
              35.808728
            ],
            [
              -77.472286,
              35.801095
            ],
            [
              -77.516261,
              35.761334
            ],
            [
              -77.531262,
              35.753528
            ],
            [
              -77.665131,
              35.674935
            ],
            [
              -77.671408,
              35.671478
            ],
            [
              -77.687927,
              35.661061
            ],
            [
              -77.700692,
              35.652295
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "103",
      "COUNTYNS": "01026338",
      "AFFGEOID": "0500000US37103",
      "GEOID": "37103",
      "NAME": "Jones",
      "LSAD": "06",
      "ALAND": 1220837016,
      "AWATER": 6031827,
      "County_state": "Jones,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.731026,
              35.008138
            ],
            [
              -77.674805,
              34.9739
            ],
            [
              -77.638967,
              34.98446
            ],
            [
              -77.502771,
              34.969635
            ],
            [
              -77.401365,
              34.924194
            ],
            [
              -77.366768,
              34.90865
            ],
            [
              -77.365167,
              34.92355
            ],
            [
              -77.331554,
              34.93915
            ],
            [
              -77.307466,
              34.937237
            ],
            [
              -77.267647,
              34.92579
            ],
            [
              -77.248333,
              34.900101
            ],
            [
              -77.237357,
              34.899962
            ],
            [
              -77.234165,
              34.886331
            ],
            [
              -77.221954,
              34.886882
            ],
            [
              -77.219466,
              34.871498
            ],
            [
              -77.200266,
              34.833284
            ],
            [
              -77.1794,
              34.829931
            ],
            [
              -77.196257,
              34.810991
            ],
            [
              -77.175131,
              34.795534
            ],
            [
              -77.172104,
              34.780385
            ],
            [
              -77.165986,
              34.785032
            ],
            [
              -77.167769,
              34.791
            ],
            [
              -77.078263,
              34.811322
            ],
            [
              -77.047409,
              34.836177
            ],
            [
              -77.108851,
              34.83078
            ],
            [
              -77.103101,
              35.01707
            ],
            [
              -77.098889,
              35.027152
            ],
            [
              -77.110237,
              35.046694
            ],
            [
              -77.113487,
              35.072442
            ],
            [
              -77.130361,
              35.072371
            ],
            [
              -77.148345,
              35.054675
            ],
            [
              -77.164146,
              35.065406
            ],
            [
              -77.180592,
              35.05522
            ],
            [
              -77.189422,
              35.06667
            ],
            [
              -77.187679,
              35.081086
            ],
            [
              -77.329244,
              35.149829
            ],
            [
              -77.451745,
              35.218717
            ],
            [
              -77.473692,
              35.228985
            ],
            [
              -77.505843,
              35.237414
            ],
            [
              -77.527182,
              35.243001
            ],
            [
              -77.507463,
              35.172814
            ],
            [
              -77.601282,
              35.071368
            ],
            [
              -77.731026,
              35.008138
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "067",
      "COUNTYNS": "01008552",
      "AFFGEOID": "0500000US37067",
      "GEOID": "37067",
      "NAME": "Forsyth",
      "LSAD": "06",
      "ALAND": 1056115821,
      "AWATER": 11818425,
      "County_state": "Forsyth,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.515607,
              36.093303
            ],
            [
              -80.508545,
              36.057954
            ],
            [
              -80.496283,
              36.046545
            ],
            [
              -80.463592,
              36.062541
            ],
            [
              -80.453148,
              36.043865
            ],
            [
              -80.417916,
              36.015549
            ],
            [
              -80.420939,
              35.987038
            ],
            [
              -80.394307,
              35.972762
            ],
            [
              -80.330298,
              35.996556
            ],
            [
              -80.238314,
              35.996677
            ],
            [
              -80.213842,
              35.996699
            ],
            [
              -80.213823,
              36.014189
            ],
            [
              -80.21373,
              36.026808
            ],
            [
              -80.19685,
              36.026805
            ],
            [
              -80.149407,
              36.026768
            ],
            [
              -80.043238,
              36.010758
            ],
            [
              -80.04022,
              36.105268
            ],
            [
              -80.040217,
              36.105419
            ],
            [
              -80.038554,
              36.146463
            ],
            [
              -80.035119,
              36.257184
            ],
            [
              -80.451696,
              36.261502
            ],
            [
              -80.452322,
              36.241419
            ],
            [
              -80.432241,
              36.217995
            ],
            [
              -80.446372,
              36.206548
            ],
            [
              -80.436881,
              36.168465
            ],
            [
              -80.438899,
              36.138755
            ],
            [
              -80.454382,
              36.122139
            ],
            [
              -80.505532,
              36.109155
            ],
            [
              -80.515607,
              36.093303
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "191",
      "COUNTYNS": "01026128",
      "AFFGEOID": "0500000US37191",
      "GEOID": "37191",
      "NAME": "Wayne",
      "LSAD": "06",
      "ALAND": 1434638257,
      "AWATER": 9855192,
      "County_state": "Wayne,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.289711,
              35.315077
            ],
            [
              -78.30658,
              35.287604
            ],
            [
              -78.257213,
              35.237154
            ],
            [
              -78.245021,
              35.225951
            ],
            [
              -78.238276,
              35.223389
            ],
            [
              -78.19125,
              35.211893
            ],
            [
              -78.161845,
              35.204323
            ],
            [
              -78.16342,
              35.189716
            ],
            [
              -78.077389,
              35.175135
            ],
            [
              -78.044451,
              35.192535
            ],
            [
              -78.021001,
              35.193918
            ],
            [
              -77.983939,
              35.183438
            ],
            [
              -77.970598,
              35.166115
            ],
            [
              -77.929738,
              35.173568
            ],
            [
              -77.892199,
              35.146315
            ],
            [
              -77.883732,
              35.162353
            ],
            [
              -77.834249,
              35.177846
            ],
            [
              -77.827998,
              35.28092
            ],
            [
              -77.82418,
              35.343811
            ],
            [
              -77.818047,
              35.357435
            ],
            [
              -77.806248,
              35.368754
            ],
            [
              -77.802815,
              35.372971
            ],
            [
              -77.799861,
              35.382282
            ],
            [
              -77.804494,
              35.404648
            ],
            [
              -77.826744,
              35.42409
            ],
            [
              -77.825344,
              35.477506
            ],
            [
              -77.822511,
              35.585383
            ],
            [
              -77.835727,
              35.586401
            ],
            [
              -77.873751,
              35.586241
            ],
            [
              -77.998348,
              35.585597
            ],
            [
              -78.058006,
              35.597806
            ],
            [
              -78.064784,
              35.585263
            ],
            [
              -78.15491,
              35.41781
            ],
            [
              -78.153672,
              35.391987
            ],
            [
              -78.144202,
              35.386894
            ],
            [
              -78.16221,
              35.366037
            ],
            [
              -78.153243,
              35.350671
            ],
            [
              -78.180025,
              35.343707
            ],
            [
              -78.215478,
              35.341527
            ],
            [
              -78.215671,
              35.333457
            ],
            [
              -78.24263,
              35.319799
            ],
            [
              -78.256751,
              35.325579
            ],
            [
              -78.276034,
              35.315136
            ],
            [
              -78.289711,
              35.315077
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "017",
      "COUNTYNS": "01026336",
      "AFFGEOID": "0500000US37017",
      "GEOID": "37017",
      "NAME": "Bladen",
      "LSAD": "06",
      "ALAND": 2265870801,
      "AWATER": 33027800,
      "County_state": "Bladen,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.901998,
              34.835268
            ],
            [
              -78.883313,
              34.812021
            ],
            [
              -78.854417,
              34.736835
            ],
            [
              -78.82142,
              34.70933
            ],
            [
              -78.805834,
              34.68956
            ],
            [
              -78.809431,
              34.668276
            ],
            [
              -78.834434,
              34.621583
            ],
            [
              -78.831786,
              34.611921
            ],
            [
              -78.843617,
              34.578476
            ],
            [
              -78.86167,
              34.563579
            ],
            [
              -78.856646,
              34.524141
            ],
            [
              -78.846369,
              34.50017
            ],
            [
              -78.863414,
              34.498034
            ],
            [
              -78.868961,
              34.484778
            ],
            [
              -78.863365,
              34.484747
            ],
            [
              -78.858063,
              34.474411
            ],
            [
              -78.825218,
              34.461762
            ],
            [
              -78.667588,
              34.469645
            ],
            [
              -78.648373,
              34.460869
            ],
            [
              -78.60377,
              34.441134
            ],
            [
              -78.59283,
              34.431003
            ],
            [
              -78.552713,
              34.420778
            ],
            [
              -78.44532,
              34.3774
            ],
            [
              -78.420101,
              34.378108
            ],
            [
              -78.396915,
              34.370747
            ],
            [
              -78.365702,
              34.372803
            ],
            [
              -78.334569,
              34.367536
            ],
            [
              -78.256085,
              34.399469
            ],
            [
              -78.176501,
              34.465356
            ],
            [
              -78.202874,
              34.475605
            ],
            [
              -78.212107,
              34.488394
            ],
            [
              -78.235621,
              34.492529
            ],
            [
              -78.255468,
              34.508614
            ],
            [
              -78.254598,
              34.520408
            ],
            [
              -78.272227,
              34.538381
            ],
            [
              -78.254441,
              34.553595
            ],
            [
              -78.251728,
              34.562021
            ],
            [
              -78.265935,
              34.583588
            ],
            [
              -78.304989,
              34.602438
            ],
            [
              -78.31399,
              34.633133
            ],
            [
              -78.312737,
              34.643605
            ],
            [
              -78.324562,
              34.649709
            ],
            [
              -78.323538,
              34.665662
            ],
            [
              -78.338689,
              34.674882
            ],
            [
              -78.346721,
              34.690187
            ],
            [
              -78.369118,
              34.69674
            ],
            [
              -78.368624,
              34.704309
            ],
            [
              -78.391911,
              34.729577
            ],
            [
              -78.391978,
              34.741265
            ],
            [
              -78.390748,
              34.749463
            ],
            [
              -78.398784,
              34.750104
            ],
            [
              -78.40971,
              34.765038
            ],
            [
              -78.420386,
              34.765876
            ],
            [
              -78.43694,
              34.784169
            ],
            [
              -78.449756,
              34.813507
            ],
            [
              -78.469462,
              34.830355
            ],
            [
              -78.493451,
              34.842997
            ],
            [
              -78.494705,
              34.856182
            ],
            [
              -78.516123,
              34.845919
            ],
            [
              -78.533633,
              34.851129
            ],
            [
              -78.799597,
              34.850857
            ],
            [
              -78.842858,
              34.847411
            ],
            [
              -78.901998,
              34.835268
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "149",
      "COUNTYNS": "01008579",
      "AFFGEOID": "0500000US37149",
      "GEOID": "37149",
      "NAME": "Polk",
      "LSAD": "06",
      "ALAND": 615606300,
      "AWATER": 1964035,
      "County_state": "Polk,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.359152,
              35.244489
            ],
            [
              -82.350931,
              35.228288
            ],
            [
              -82.35365431473379,
              35.1922225810449
            ],
            [
              -82.350086,
              35.192858
            ],
            [
              -82.344554,
              35.193115
            ],
            [
              -82.341194,
              35.19151
            ],
            [
              -82.340133,
              35.189188
            ],
            [
              -82.339508,
              35.18893
            ],
            [
              -82.338013,
              35.18901
            ],
            [
              -82.333934,
              35.190661
            ],
            [
              -82.332975,
              35.190645
            ],
            [
              -82.330779,
              35.189032
            ],
            [
              -82.330549,
              35.186767
            ],
            [
              -82.326917,
              35.185056
            ],
            [
              -82.32335,
              35.184789
            ],
            [
              -82.317871,
              35.187858
            ],
            [
              -82.315871,
              35.190678
            ],
            [
              -82.314863,
              35.191089
            ],
            [
              -82.307166,
              35.193012
            ],
            [
              -82.295354,
              35.194965
            ],
            [
              -82.288453,
              35.198605
            ],
            [
              -82.282516,
              35.199858
            ],
            [
              -82.280858603372,
              35.199904475181896
            ],
            [
              -82.27492,
              35.200071
            ],
            [
              -82.257515,
              35.198636
            ],
            [
              -82.230915,
              35.196784
            ],
            [
              -82.230517,
              35.196764
            ],
            [
              -82.216217,
              35.196044
            ],
            [
              -82.195483,
              35.194951
            ],
            [
              -82.185513,
              35.194355
            ],
            [
              -82.176874,
              35.19379
            ],
            [
              -82.167676,
              35.193699
            ],
            [
              -82.138947,
              35.193122
            ],
            [
              -82.1060847260646,
              35.1915082127587
            ],
            [
              -82.089586,
              35.190698
            ],
            [
              -82.0484052363051,
              35.189667965478
            ],
            [
              -82.039651,
              35.189449
            ],
            [
              -82.001422,
              35.188493
            ],
            [
              -81.9693458232069,
              35.1872287391339
            ],
            [
              -81.970882,
              35.211589
            ],
            [
              -81.962806,
              35.22686
            ],
            [
              -81.968788,
              35.257764
            ],
            [
              -81.995719,
              35.270615
            ],
            [
              -82.001167,
              35.283009
            ],
            [
              -82.029517,
              35.301502
            ],
            [
              -82.056883,
              35.336946
            ],
            [
              -82.077075,
              35.341973
            ],
            [
              -82.079662,
              35.352978
            ],
            [
              -82.129209,
              35.396852
            ],
            [
              -82.151916,
              35.407719
            ],
            [
              -82.171514,
              35.40943
            ],
            [
              -82.18515,
              35.396619
            ],
            [
              -82.204552,
              35.40363
            ],
            [
              -82.226817,
              35.395475
            ],
            [
              -82.239115,
              35.402557
            ],
            [
              -82.261305,
              35.393198
            ],
            [
              -82.305265,
              35.33263
            ],
            [
              -82.310429,
              35.314708
            ],
            [
              -82.32768,
              35.308919
            ],
            [
              -82.34685,
              35.279725
            ],
            [
              -82.349653,
              35.244305
            ],
            [
              -82.359152,
              35.244489
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "121",
      "COUNTYNS": "01008571",
      "AFFGEOID": "0500000US37121",
      "GEOID": "37121",
      "NAME": "Mitchell",
      "LSAD": "06",
      "ALAND": 573038008,
      "AWATER": 1629508,
      "County_state": "Mitchell,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.409458,
              36.083409
            ],
            [
              -82.41666077270189,
              36.072773655932195
            ],
            [
              -82.399418,
              36.071636
            ],
            [
              -82.399075,
              36.081918
            ],
            [
              -82.380081,
              36.07074
            ],
            [
              -82.352408,
              36.084596
            ],
            [
              -82.336448,
              36.052711
            ],
            [
              -82.338201,
              36.030088
            ],
            [
              -82.312435,
              36.026104
            ],
            [
              -82.301867,
              36.045245
            ],
            [
              -82.2886,
              36.02813
            ],
            [
              -82.26807,
              36.016625
            ],
            [
              -82.27332,
              36.002935
            ],
            [
              -82.250565,
              35.988907
            ],
            [
              -82.253966,
              36.005321
            ],
            [
              -82.237981,
              36.004724
            ],
            [
              -82.234319,
              36.013009
            ],
            [
              -82.197834,
              36.015332
            ],
            [
              -82.193203,
              36.002475
            ],
            [
              -82.20412,
              36.003603
            ],
            [
              -82.191705,
              35.990636
            ],
            [
              -82.191825,
              35.969453
            ],
            [
              -82.186312,
              35.960958
            ],
            [
              -82.19774,
              35.952672
            ],
            [
              -82.169724,
              35.928617
            ],
            [
              -82.152719,
              35.928302
            ],
            [
              -82.154804,
              35.914771
            ],
            [
              -82.142693,
              35.90839
            ],
            [
              -82.149925,
              35.892345
            ],
            [
              -82.140292,
              35.842615
            ],
            [
              -82.15008,
              35.841656
            ],
            [
              -82.143507,
              35.825451
            ],
            [
              -82.133484,
              35.823309
            ],
            [
              -82.120579,
              35.825012
            ],
            [
              -82.099405,
              35.851671
            ],
            [
              -82.073713,
              35.848305
            ],
            [
              -82.048039,
              35.853229
            ],
            [
              -82.013607,
              35.885218
            ],
            [
              -81.999409,
              35.874007
            ],
            [
              -81.980168,
              35.885046
            ],
            [
              -81.981954,
              35.911361
            ],
            [
              -82.034104,
              35.942057
            ],
            [
              -82.058764,
              35.98716
            ],
            [
              -82.049298,
              36.007927
            ],
            [
              -82.039175,
              36.007774
            ],
            [
              -82.068685,
              36.06527
            ],
            [
              -82.080944957901,
              36.1057614805811
            ],
            [
              -82.085943,
              36.10602
            ],
            [
              -82.098544,
              36.105719
            ],
            [
              -82.101644,
              36.106219
            ],
            [
              -82.105444,
              36.108119
            ],
            [
              -82.109145,
              36.107218
            ],
            [
              -82.115245,
              36.104618
            ],
            [
              -82.127146,
              36.104417
            ],
            [
              -82.130646,
              36.106417
            ],
            [
              -82.137974,
              36.119576
            ],
            [
              -82.136546,
              36.123717
            ],
            [
              -82.136547,
              36.128817
            ],
            [
              -82.140847,
              36.136216
            ],
            [
              -82.144147,
              36.144216
            ],
            [
              -82.147948,
              36.149516
            ],
            [
              -82.155948,
              36.148115
            ],
            [
              -82.160883,
              36.146548
            ],
            [
              -82.169249,
              36.146614
            ],
            [
              -82.172149,
              36.146414
            ],
            [
              -82.173849,
              36.145314
            ],
            [
              -82.17561,
              36.14387
            ],
            [
              -82.178861,
              36.143296
            ],
            [
              -82.182549,
              36.143714
            ],
            [
              -82.18365,
              36.144414
            ],
            [
              -82.18475,
              36.145414
            ],
            [
              -82.18785,
              36.147886
            ],
            [
              -82.19195,
              36.148813
            ],
            [
              -82.19535,
              36.150013
            ],
            [
              -82.199251,
              36.152713
            ],
            [
              -82.201812,
              36.154963
            ],
            [
              -82.204872,
              36.157067
            ],
            [
              -82.211251,
              36.159012
            ],
            [
              -82.213852,
              36.159112
            ],
            [
              -82.218451,
              36.157832
            ],
            [
              -82.222052,
              36.156911
            ],
            [
              -82.223232,
              36.154772
            ],
            [
              -82.224852,
              36.150011
            ],
            [
              -82.228288,
              36.146622
            ],
            [
              -82.234807,
              36.14172
            ],
            [
              -82.235479,
              36.140748
            ],
            [
              -82.236415,
              36.139926
            ],
            [
              -82.237737,
              36.139189
            ],
            [
              -82.241553,
              36.137111
            ],
            [
              -82.243353,
              36.134311
            ],
            [
              -82.244461,
              36.132777
            ],
            [
              -82.247521,
              36.130865
            ],
            [
              -82.251853,
              36.13221
            ],
            [
              -82.253253,
              36.13371
            ],
            [
              -82.256319,
              36.133925
            ],
            [
              -82.260353,
              36.13371
            ],
            [
              -82.263354,
              36.13011
            ],
            [
              -82.26569,
              36.127614
            ],
            [
              -82.26875,
              36.12704
            ],
            [
              -82.270954,
              36.12761
            ],
            [
              -82.274054,
              36.12941
            ],
            [
              -82.278654,
              36.12851
            ],
            [
              -82.280354,
              36.12881
            ],
            [
              -82.288455,
              36.13541
            ],
            [
              -82.289455,
              36.13571
            ],
            [
              -82.297655,
              36.13351
            ],
            [
              -82.302855,
              36.13131
            ],
            [
              -82.307255,
              36.12851
            ],
            [
              -82.308655,
              36.12651
            ],
            [
              -82.318156,
              36.12091
            ],
            [
              -82.321448,
              36.119551
            ],
            [
              -82.325169,
              36.119363
            ],
            [
              -82.329177,
              36.117427
            ],
            [
              -82.332289,
              36.116935
            ],
            [
              -82.336756,
              36.114909
            ],
            [
              -82.346857,
              36.115209
            ],
            [
              -82.348422,
              36.115929
            ],
            [
              -82.349957,
              36.117109
            ],
            [
              -82.355157,
              36.115609
            ],
            [
              -82.360357,
              36.111609
            ],
            [
              -82.360919,
              36.110614
            ],
            [
              -82.366566,
              36.10765
            ],
            [
              -82.371383,
              36.106388
            ],
            [
              -82.375558,
              36.105609
            ],
            [
              -82.378758,
              36.102809
            ],
            [
              -82.380458,
              36.099309
            ],
            [
              -82.389958,
              36.096909
            ],
            [
              -82.404458,
              36.087609
            ],
            [
              -82.409458,
              36.083409
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "053",
      "COUNTYNS": "01026125",
      "AFFGEOID": "0500000US37053",
      "GEOID": "37053",
      "NAME": "Currituck",
      "LSAD": "06",
      "ALAND": 678415822,
      "AWATER": 685024028,
      "County_state": "Currituck,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.313215,
              36.550551
            ],
            [
              -76.3133029178411,
              36.5505512312821
            ],
            [
              -76.158532,
              36.425333
            ],
            [
              -76.157472,
              36.398178
            ],
            [
              -76.147626,
              36.394463
            ],
            [
              -76.12181,
              36.371088
            ],
            [
              -76.114361,
              36.357583
            ],
            [
              -76.078284,
              36.347064
            ],
            [
              -76.063689,
              36.347451
            ],
            [
              -76.050755,
              36.359819
            ],
            [
              -76.028607,
              36.348768
            ],
            [
              -76.030798,
              36.338501
            ],
            [
              -76.016707,
              36.344324
            ],
            [
              -76.020578,
              36.333379
            ],
            [
              -76.007914,
              36.328648
            ],
            [
              -75.994539,
              36.308794
            ],
            [
              -75.969625,
              36.306312
            ],
            [
              -75.964851,
              36.288443
            ],
            [
              -75.948426,
              36.285268
            ],
            [
              -75.949087,
              36.273844
            ],
            [
              -75.961058,
              36.268171
            ],
            [
              -75.959381720968,
              36.2556511302458
            ],
            [
              -75.94984,
              36.25787
            ],
            [
              -75.922344,
              36.244122
            ],
            [
              -75.910658,
              36.212157
            ],
            [
              -75.882987,
              36.186807
            ],
            [
              -75.863914,
              36.159226
            ],
            [
              -75.866323,
              36.14141
            ],
            [
              -75.867792,
              36.127262
            ],
            [
              -75.847785,
              36.10199
            ],
            [
              -75.836084,
              36.092616
            ],
            [
              -75.799779,
              36.07264
            ],
            [
              -75.793974,
              36.07171
            ],
            [
              -75.791637,
              36.082267
            ],
            [
              -75.800378,
              36.112728
            ],
            [
              -75.813444,
              36.136871
            ],
            [
              -75.822531,
              36.145957
            ],
            [
              -75.823915,
              36.158332
            ],
            [
              -75.839924,
              36.17711
            ],
            [
              -75.841222,
              36.193812
            ],
            [
              -75.838367,
              36.200129
            ],
            [
              -75.848838,
              36.21657
            ],
            [
              -75.858703,
              36.222628
            ],
            [
              -75.864154,
              36.235522
            ],
            [
              -75.867356,
              36.252483
            ],
            [
              -75.861818,
              36.266415
            ],
            [
              -75.86052,
              36.280607
            ],
            [
              -75.864933,
              36.284674
            ],
            [
              -75.872721,
              36.28277
            ],
            [
              -75.882154,
              36.284674
            ],
            [
              -75.888211,
              36.293414
            ],
            [
              -75.895285,
              36.319615
            ],
            [
              -75.915331,
              36.352335
            ],
            [
              -75.917758,
              36.353593
            ],
            [
              -75.923331,
              36.361863
            ],
            [
              -75.923511,
              36.367796
            ],
            [
              -75.916949,
              36.383167
            ],
            [
              -75.916409,
              36.38901
            ],
            [
              -75.923601,
              36.425788
            ],
            [
              -75.928369,
              36.428588
            ],
            [
              -75.932694,
              36.427627
            ],
            [
              -75.936446,
              36.423079
            ],
            [
              -75.940676,
              36.41885
            ],
            [
              -75.962285,
              36.41724
            ],
            [
              -75.98005,
              36.435464
            ],
            [
              -75.989869,
              36.436808
            ],
            [
              -76.003912,
              36.441864
            ],
            [
              -76.012337,
              36.447462
            ],
            [
              -76.020216,
              36.45862
            ],
            [
              -76.031949,
              36.482496
            ],
            [
              -76.042556,
              36.510565
            ],
            [
              -76.026394,
              36.516486
            ],
            [
              -76.022475,
              36.52709
            ],
            [
              -75.996208,
              36.528138
            ],
            [
              -75.986226,
              36.525655
            ],
            [
              -75.98054,
              36.51278
            ],
            [
              -75.989791,
              36.493943
            ],
            [
              -75.975293,
              36.485535
            ],
            [
              -75.972545,
              36.494671
            ],
            [
              -75.960069,
              36.495025
            ],
            [
              -75.935473,
              36.490601
            ],
            [
              -75.927333,
              36.482815
            ],
            [
              -75.924127,
              36.482124
            ],
            [
              -75.917283,
              36.485809
            ],
            [
              -75.913071,
              36.486336
            ],
            [
              -75.907279,
              36.485809
            ],
            [
              -75.899908,
              36.482124
            ],
            [
              -75.891484,
              36.460537
            ],
            [
              -75.888325,
              36.441583
            ],
            [
              -75.880428,
              36.435792
            ],
            [
              -75.864106,
              36.430527
            ],
            [
              -75.85147,
              36.415785
            ],
            [
              -75.852523,
              36.384721
            ],
            [
              -75.85147,
              36.379456
            ],
            [
              -75.847258,
              36.372085
            ],
            [
              -75.843046,
              36.371032
            ],
            [
              -75.836201,
              36.363135
            ],
            [
              -75.831595,
              36.346418
            ],
            [
              -75.831858,
              36.339047
            ],
            [
              -75.841335,
              36.328517
            ],
            [
              -75.845284,
              36.305614
            ],
            [
              -75.837913,
              36.294558
            ],
            [
              -75.833964,
              36.292188
            ],
            [
              -75.822907,
              36.291662
            ],
            [
              -75.814483,
              36.285344
            ],
            [
              -75.808165,
              36.259545
            ],
            [
              -75.811851,
              36.247699
            ],
            [
              -75.811588,
              36.244014
            ],
            [
              -75.80369,
              36.235853
            ],
            [
              -75.798528,
              36.2308
            ],
            [
              -75.7943606364923,
              36.227327462075294
            ],
            [
              -75.773329478967,
              36.23152850558
            ],
            [
              -75.79641,
              36.290351
            ],
            [
              -75.818735,
              36.357579
            ],
            [
              -75.834975,
              36.42265
            ],
            [
              -75.856901,
              36.500155
            ],
            [
              -75.867044,
              36.550754
            ],
            [
              -75.879744,
              36.550754
            ],
            [
              -75.880644,
              36.550754
            ],
            [
              -75.885945,
              36.550754
            ],
            [
              -75.886545,
              36.550754
            ],
            [
              -75.891945,
              36.550754
            ],
            [
              -75.893245,
              36.550654
            ],
            [
              -75.894145,
              36.550754
            ],
            [
              -75.9010274253405,
              36.55067999542639
            ],
            [
              -75.903445,
              36.550654
            ],
            [
              -75.904745,
              36.550654
            ],
            [
              -75.909046,
              36.550654
            ],
            [
              -75.911446,
              36.550654
            ],
            [
              -75.922046,
              36.550654
            ],
            [
              -75.952847,
              36.550553
            ],
            [
              -75.953447,
              36.550553
            ],
            [
              -75.955748,
              36.550553
            ],
            [
              -75.957648,
              36.550553
            ],
            [
              -76.02675,
              36.550553
            ],
            [
              -76.034751,
              36.550653
            ],
            [
              -76.1223521611215,
              36.55062091212349
            ],
            [
              -76.313215,
              36.550551
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "109",
      "COUNTYNS": "01008569",
      "AFFGEOID": "0500000US37109",
      "GEOID": "37109",
      "NAME": "Lincoln",
      "LSAD": "06",
      "ALAND": 766177348,
      "AWATER": 23377500,
      "County_state": "Lincoln,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.535403,
              35.568137
            ],
            [
              -81.537599,
              35.564228
            ],
            [
              -81.515421,
              35.558297
            ],
            [
              -81.513004,
              35.523317
            ],
            [
              -81.481383,
              35.471503
            ],
            [
              -81.458803,
              35.423681
            ],
            [
              -81.4556,
              35.419811
            ],
            [
              -81.094164,
              35.406632
            ],
            [
              -80.95486,
              35.400078
            ],
            [
              -80.955708,
              35.401623
            ],
            [
              -80.958636,
              35.431697
            ],
            [
              -80.94238,
              35.455147
            ],
            [
              -80.95159,
              35.470178
            ],
            [
              -80.948122,
              35.491167
            ],
            [
              -80.962142,
              35.498656
            ],
            [
              -80.95658,
              35.531571
            ],
            [
              -80.939685,
              35.529529
            ],
            [
              -80.960025,
              35.54702
            ],
            [
              -81.535403,
              35.568137
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "087",
      "COUNTYNS": "01008561",
      "AFFGEOID": "0500000US37087",
      "GEOID": "37087",
      "NAME": "Haywood",
      "LSAD": "06",
      "ALAND": 1433738456,
      "AWATER": 2414518,
      "County_state": "Haywood,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.256111,
              35.703961
            ],
            [
              -83.255126,
              35.701493
            ],
            [
              -83.25561,
              35.696061
            ],
            [
              -83.214907,
              35.692112
            ],
            [
              -83.207665,
              35.681599
            ],
            [
              -83.181977,
              35.671327
            ],
            [
              -83.202323,
              35.650252
            ],
            [
              -83.175871,
              35.6275
            ],
            [
              -83.17598,
              35.61245
            ],
            [
              -83.185519,
              35.598271
            ],
            [
              -83.182109,
              35.584537
            ],
            [
              -83.166964,
              35.57869
            ],
            [
              -83.155835,
              35.552622
            ],
            [
              -83.171609,
              35.537646
            ],
            [
              -83.174414,
              35.52139
            ],
            [
              -83.186322,
              35.514368
            ],
            [
              -83.163476,
              35.50895
            ],
            [
              -83.16139,
              35.496494
            ],
            [
              -83.137409,
              35.488818
            ],
            [
              -83.137714,
              35.464076
            ],
            [
              -83.109149,
              35.464116
            ],
            [
              -83.091435,
              35.457531
            ],
            [
              -83.090253,
              35.449041
            ],
            [
              -83.079473,
              35.447565
            ],
            [
              -83.080089,
              35.434749
            ],
            [
              -83.056281,
              35.425975
            ],
            [
              -83.044234,
              35.414692
            ],
            [
              -83.043724,
              35.394395
            ],
            [
              -83.025007,
              35.380316
            ],
            [
              -83.006568,
              35.377327
            ],
            [
              -82.990358,
              35.367616
            ],
            [
              -82.96469,
              35.33292
            ],
            [
              -82.964725,
              35.315289
            ],
            [
              -82.927321,
              35.302802
            ],
            [
              -82.920881,
              35.292037
            ],
            [
              -82.876056,
              35.314625
            ],
            [
              -82.83199,
              35.318343
            ],
            [
              -82.814964,
              35.343923
            ],
            [
              -82.805116,
              35.347966
            ],
            [
              -82.78777,
              35.380994
            ],
            [
              -82.774539,
              35.393308
            ],
            [
              -82.74562,
              35.407345
            ],
            [
              -82.745139,
              35.422967
            ],
            [
              -82.781691,
              35.439542
            ],
            [
              -82.799493,
              35.471227
            ],
            [
              -82.778063,
              35.519652
            ],
            [
              -82.780211,
              35.525711
            ],
            [
              -82.765871,
              35.540791
            ],
            [
              -82.777524,
              35.555619
            ],
            [
              -82.765893,
              35.567495
            ],
            [
              -82.784267,
              35.591377
            ],
            [
              -82.797124,
              35.596146
            ],
            [
              -82.813915,
              35.627918
            ],
            [
              -82.84609,
              35.614309
            ],
            [
              -82.853561,
              35.63682
            ],
            [
              -82.864383,
              35.634651
            ],
            [
              -82.883909,
              35.678235
            ],
            [
              -82.901071,
              35.684929
            ],
            [
              -82.898614,
              35.703736
            ],
            [
              -82.910409,
              35.716184
            ],
            [
              -82.91765,
              35.744808
            ],
            [
              -82.947879,
              35.738945
            ],
            [
              -82.957176,
              35.746843
            ],
            [
              -82.950078,
              35.766359
            ],
            [
              -82.9628979027128,
              35.791734792758696
            ],
            [
              -82.964088,
              35.78998
            ],
            [
              -82.969648,
              35.789663
            ],
            [
              -82.974463,
              35.78679
            ],
            [
              -82.978414,
              35.78261
            ],
            [
              -82.98397,
              35.77801
            ],
            [
              -82.992053,
              35.773948
            ],
            [
              -82.995803,
              35.773128
            ],
            [
              -83.001473,
              35.773752
            ],
            [
              -83.006067,
              35.778404
            ],
            [
              -83.012377,
              35.779818
            ],
            [
              -83.036209,
              35.787405
            ],
            [
              -83.03951,
              35.786777
            ],
            [
              -83.042666,
              35.785407
            ],
            [
              -83.044108,
              35.785347
            ],
            [
              -83.046307,
              35.785853
            ],
            [
              -83.04853,
              35.787706
            ],
            [
              -83.052677,
              35.789548
            ],
            [
              -83.05834,
              35.788241
            ],
            [
              -83.061507,
              35.786774
            ],
            [
              -83.063975,
              35.786643
            ],
            [
              -83.072221,
              35.78831
            ],
            [
              -83.07403,
              35.790016
            ],
            [
              -83.078732,
              35.789472
            ],
            [
              -83.085205,
              35.785794
            ],
            [
              -83.086054,
              35.783627
            ],
            [
              -83.097193,
              35.776067
            ],
            [
              -83.100225,
              35.774765
            ],
            [
              -83.100329,
              35.774804
            ],
            [
              -83.100233,
              35.774745
            ],
            [
              -83.10232,
              35.775071
            ],
            [
              -83.104584,
              35.77423
            ],
            [
              -83.104805,
              35.77348
            ],
            [
              -83.110491,
              35.770913
            ],
            [
              -83.113662,
              35.770211
            ],
            [
              -83.120183,
              35.766234
            ],
            [
              -83.127707,
              35.768093
            ],
            [
              -83.14697,
              35.765124
            ],
            [
              -83.14808,
              35.764295
            ],
            [
              -83.15408,
              35.76428
            ],
            [
              -83.159208,
              35.764892
            ],
            [
              -83.161537,
              35.763363
            ],
            [
              -83.164909,
              35.759965
            ],
            [
              -83.165427,
              35.7587
            ],
            [
              -83.16477,
              35.754618
            ],
            [
              -83.170173,
              35.746107
            ],
            [
              -83.171867,
              35.745978
            ],
            [
              -83.177499,
              35.743913
            ],
            [
              -83.180836,
              35.738882
            ],
            [
              -83.182097,
              35.735492
            ],
            [
              -83.185685,
              35.72989
            ],
            [
              -83.18837,
              35.729798
            ],
            [
              -83.198267,
              35.725494
            ],
            [
              -83.200126,
              35.725331
            ],
            [
              -83.203752,
              35.726553
            ],
            [
              -83.214501,
              35.724434
            ],
            [
              -83.216972,
              35.725752
            ],
            [
              -83.219981,
              35.726601
            ],
            [
              -83.222627,
              35.726138
            ],
            [
              -83.232042,
              35.726098
            ],
            [
              -83.240669,
              35.72676
            ],
            [
              -83.242132,
              35.723638
            ],
            [
              -83.243501,
              35.722533
            ],
            [
              -83.251247,
              35.719916
            ],
            [
              -83.255351,
              35.71623
            ],
            [
              -83.255476179897,
              35.7150906815168
            ],
            [
              -83.255489,
              35.714974
            ],
            [
              -83.254481,
              35.712362
            ],
            [
              -83.25423,
              35.709478
            ],
            [
              -83.255108,
              35.707096
            ],
            [
              -83.256111,
              35.703961
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "063",
      "COUNTYNS": "01008550",
      "AFFGEOID": "0500000US37063",
      "GEOID": "37063",
      "NAME": "Durham",
      "LSAD": "06",
      "ALAND": 741761173,
      "AWATER": 29701774,
      "County_state": "Durham,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.0095,
              35.902401
            ],
            [
              -79.016305,
              35.86321
            ],
            [
              -78.906097,
              35.867918
            ],
            [
              -78.890277,
              35.867872
            ],
            [
              -78.870298,
              35.86756
            ],
            [
              -78.829965,
              35.866978
            ],
            [
              -78.806756,
              35.922925
            ],
            [
              -78.804836,
              35.927552
            ],
            [
              -78.801972,
              35.926972
            ],
            [
              -78.760406,
              35.918655
            ],
            [
              -78.75821,
              35.921558
            ],
            [
              -78.717014,
              35.961265
            ],
            [
              -78.699316,
              36.011448
            ],
            [
              -78.719951,
              36.02959
            ],
            [
              -78.740187,
              36.023561
            ],
            [
              -78.754026,
              36.047812
            ],
            [
              -78.754284,
              36.056338
            ],
            [
              -78.751273,
              36.070834
            ],
            [
              -78.779141,
              36.071547
            ],
            [
              -78.807484,
              36.087295
            ],
            [
              -78.804901,
              36.091977
            ],
            [
              -78.802335,
              36.235794
            ],
            [
              -78.950597,
              36.23932
            ],
            [
              -78.9675,
              36.142508
            ],
            [
              -78.968401,
              36.137509
            ],
            [
              -78.970666,
              36.125018
            ],
            [
              -78.971617,
              36.119456
            ],
            [
              -78.982633,
              36.056544
            ],
            [
              -79.004389,
              35.9315
            ],
            [
              -79.0095,
              35.902401
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "079",
      "COUNTYNS": "01008557",
      "AFFGEOID": "0500000US37079",
      "GEOID": "37079",
      "NAME": "Greene",
      "LSAD": "06",
      "ALAND": 690851081,
      "AWATER": 1217389,
      "County_state": "Greene,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.825344,
              35.477506
            ],
            [
              -77.826744,
              35.42409
            ],
            [
              -77.804494,
              35.404648
            ],
            [
              -77.799861,
              35.382282
            ],
            [
              -77.802815,
              35.372971
            ],
            [
              -77.806248,
              35.368754
            ],
            [
              -77.777669,
              35.367664
            ],
            [
              -77.741176,
              35.371966
            ],
            [
              -77.697516,
              35.377097
            ],
            [
              -77.677679,
              35.347324
            ],
            [
              -77.639101,
              35.354086
            ],
            [
              -77.610962,
              35.378633
            ],
            [
              -77.589323,
              35.373763
            ],
            [
              -77.534173,
              35.398104
            ],
            [
              -77.528338,
              35.414336
            ],
            [
              -77.506701,
              35.421896
            ],
            [
              -77.49786,
              35.413254
            ],
            [
              -77.481966,
              35.414451
            ],
            [
              -77.475515,
              35.426655
            ],
            [
              -77.476743,
              35.445412
            ],
            [
              -77.492659,
              35.461467
            ],
            [
              -77.494116,
              35.477423
            ],
            [
              -77.511785,
              35.497836
            ],
            [
              -77.503408,
              35.517975
            ],
            [
              -77.523888,
              35.518535
            ],
            [
              -77.52501,
              35.528139
            ],
            [
              -77.560323,
              35.533491
            ],
            [
              -77.570833,
              35.548842
            ],
            [
              -77.593045,
              35.564143
            ],
            [
              -77.61037,
              35.567552
            ],
            [
              -77.662438,
              35.619252
            ],
            [
              -77.681622,
              35.630762
            ],
            [
              -77.689025,
              35.649594
            ],
            [
              -77.700692,
              35.652295
            ],
            [
              -77.806291,
              35.583436
            ],
            [
              -77.822511,
              35.585383
            ],
            [
              -77.825344,
              35.477506
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "101",
      "COUNTYNS": "01026327",
      "AFFGEOID": "0500000US37101",
      "GEOID": "37101",
      "NAME": "Johnston",
      "LSAD": "06",
      "ALAND": 2049282622,
      "AWATER": 11562555,
      "County_state": "Johnston,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.708893,
              35.519474
            ],
            [
              -78.708345,
              35.519296
            ],
            [
              -78.689517,
              35.515934
            ],
            [
              -78.687835,
              35.504208
            ],
            [
              -78.660555,
              35.496245
            ],
            [
              -78.650471,
              35.473255
            ],
            [
              -78.627536,
              35.458462
            ],
            [
              -78.598856,
              35.418394
            ],
            [
              -78.595094,
              35.401948
            ],
            [
              -78.573945,
              35.388149
            ],
            [
              -78.567695,
              35.367688
            ],
            [
              -78.552182,
              35.356684
            ],
            [
              -78.529851,
              35.334935
            ],
            [
              -78.538349,
              35.31641
            ],
            [
              -78.52194,
              35.294521
            ],
            [
              -78.509817,
              35.281665
            ],
            [
              -78.492042,
              35.263421
            ],
            [
              -78.411734,
              35.254663
            ],
            [
              -78.397725,
              35.26296
            ],
            [
              -78.364213,
              35.272123
            ],
            [
              -78.319296,
              35.28321
            ],
            [
              -78.30658,
              35.287604
            ],
            [
              -78.289711,
              35.315077
            ],
            [
              -78.276034,
              35.315136
            ],
            [
              -78.256751,
              35.325579
            ],
            [
              -78.24263,
              35.319799
            ],
            [
              -78.215671,
              35.333457
            ],
            [
              -78.215478,
              35.341527
            ],
            [
              -78.180025,
              35.343707
            ],
            [
              -78.153243,
              35.350671
            ],
            [
              -78.16221,
              35.366037
            ],
            [
              -78.144202,
              35.386894
            ],
            [
              -78.153672,
              35.391987
            ],
            [
              -78.15491,
              35.41781
            ],
            [
              -78.064784,
              35.585263
            ],
            [
              -78.124501,
              35.602828
            ],
            [
              -78.127312,
              35.607299
            ],
            [
              -78.160825,
              35.696267
            ],
            [
              -78.161648,
              35.713072
            ],
            [
              -78.192117,
              35.730545
            ],
            [
              -78.215472,
              35.735837
            ],
            [
              -78.219769,
              35.755069
            ],
            [
              -78.23251,
              35.751256
            ],
            [
              -78.248458,
              35.77479
            ],
            [
              -78.243876,
              35.791949
            ],
            [
              -78.255973,
              35.81812
            ],
            [
              -78.256162,
              35.818002
            ],
            [
              -78.460106,
              35.705657
            ],
            [
              -78.468503,
              35.707072
            ],
            [
              -78.473008,
              35.701917
            ],
            [
              -78.560497,
              35.633772
            ],
            [
              -78.566639,
              35.629002
            ],
            [
              -78.597924,
              35.604676
            ],
            [
              -78.642702,
              35.570065
            ],
            [
              -78.708893,
              35.519474
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "129",
      "COUNTYNS": "01026329",
      "AFFGEOID": "0500000US37129",
      "GEOID": "37129",
      "NAME": "New Hanover",
      "LSAD": "06",
      "ALAND": 497789754,
      "AWATER": 353951640,
      "County_state": "New Hanover,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.029922,
              34.331768
            ],
            [
              -78.027457,
              34.319656
            ],
            [
              -78.007217,
              34.321368
            ],
            [
              -78.017113,
              34.292769
            ],
            [
              -77.987512,
              34.268269
            ],
            [
              -77.95661,
              34.234432
            ],
            [
              -77.960941,
              34.206301
            ],
            [
              -77.959631,
              34.201567
            ],
            [
              -77.956608,
              34.192488
            ],
            [
              -77.958438,
              34.183124
            ],
            [
              -77.953502,
              34.153547
            ],
            [
              -77.936914,
              34.122822
            ],
            [
              -77.931103,
              34.058884
            ],
            [
              -77.932416,
              34.028489
            ],
            [
              -77.941779,
              33.981037
            ],
            [
              -77.948479,
              33.971274
            ],
            [
              -77.93715949974589,
              33.9289179114036
            ],
            [
              -77.927926,
              33.945265
            ],
            [
              -77.915536,
              33.971723
            ],
            [
              -77.878161,
              34.067963
            ],
            [
              -77.874384,
              34.075671
            ],
            [
              -77.870327,
              34.079221
            ],
            [
              -77.841785,
              34.140747
            ],
            [
              -77.829209,
              34.162618
            ],
            [
              -77.76456570463989,
              34.2449485307911
            ],
            [
              -77.764022,
              34.245641
            ],
            [
              -77.740136,
              34.272546
            ],
            [
              -77.713322,
              34.294879
            ],
            [
              -77.71090187414619,
              34.297249881263504
            ],
            [
              -77.739198,
              34.292752
            ],
            [
              -77.811091,
              34.358024
            ],
            [
              -77.814005,
              34.368282
            ],
            [
              -77.80472,
              34.376741
            ],
            [
              -77.823102,
              34.389287
            ],
            [
              -77.856454,
              34.380297
            ],
            [
              -77.8799,
              34.366282
            ],
            [
              -77.895774,
              34.363813
            ],
            [
              -77.899353,
              34.375168
            ],
            [
              -77.918493,
              34.37484
            ],
            [
              -77.928781,
              34.361544
            ],
            [
              -77.940446,
              34.370024
            ],
            [
              -77.957807,
              34.368632
            ],
            [
              -77.962194,
              34.382855
            ],
            [
              -77.975553,
              34.372344
            ],
            [
              -77.986995,
              34.372056
            ],
            [
              -77.978358,
              34.358921
            ],
            [
              -77.993202,
              34.336828
            ],
            [
              -78.029922,
              34.331768
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "037",
      "COUNTYNS": "01008544",
      "AFFGEOID": "0500000US37037",
      "GEOID": "37037",
      "NAME": "Chatham",
      "LSAD": "06",
      "ALAND": 1765011149,
      "AWATER": 71264075,
      "County_state": "Chatham,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.542428,
              35.843303
            ],
            [
              -79.555804,
              35.515039
            ],
            [
              -79.350073,
              35.518002
            ],
            [
              -79.321927,
              35.524638
            ],
            [
              -79.311234,
              35.532733
            ],
            [
              -79.323046,
              35.544419
            ],
            [
              -79.285175,
              35.555148
            ],
            [
              -79.291759,
              35.543349
            ],
            [
              -79.275541,
              35.531779
            ],
            [
              -79.262705,
              35.535728
            ],
            [
              -79.245199,
              35.552961
            ],
            [
              -79.244663,
              35.569116
            ],
            [
              -79.21874,
              35.565675
            ],
            [
              -79.225634,
              35.551745
            ],
            [
              -79.199886,
              35.558558
            ],
            [
              -79.201864,
              35.57414
            ],
            [
              -79.194195,
              35.578398
            ],
            [
              -79.186772,
              35.579896
            ],
            [
              -79.185507,
              35.58099
            ],
            [
              -79.184446,
              35.58328
            ],
            [
              -79.180234,
              35.586446
            ],
            [
              -79.177698,
              35.588909
            ],
            [
              -79.171235,
              35.593896
            ],
            [
              -79.148496,
              35.618749
            ],
            [
              -79.118771,
              35.628323
            ],
            [
              -79.077747,
              35.614037
            ],
            [
              -79.05249,
              35.596075
            ],
            [
              -79.047027,
              35.570458
            ],
            [
              -79.0166,
              35.544268
            ],
            [
              -78.995638,
              35.542119
            ],
            [
              -78.96964,
              35.521669
            ],
            [
              -78.914734,
              35.583672
            ],
            [
              -78.916911,
              35.5854
            ],
            [
              -78.938888,
              35.592349
            ],
            [
              -78.995059,
              35.610135
            ],
            [
              -78.936271,
              35.771898
            ],
            [
              -78.930891,
              35.794377
            ],
            [
              -78.909425,
              35.842937
            ],
            [
              -78.906097,
              35.867918
            ],
            [
              -79.016305,
              35.86321
            ],
            [
              -79.082036,
              35.860776
            ],
            [
              -79.148579,
              35.866898
            ],
            [
              -79.221534,
              35.875467
            ],
            [
              -79.249538,
              35.87681
            ],
            [
              -79.250745,
              35.858679
            ],
            [
              -79.237295,
              35.844247
            ],
            [
              -79.542428,
              35.843303
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "153",
      "COUNTYNS": "01008581",
      "AFFGEOID": "0500000US37153",
      "GEOID": "37153",
      "NAME": "Richmond",
      "LSAD": "06",
      "ALAND": 1226882608,
      "AWATER": 15537990,
      "County_state": "Richmond,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.075365,
              35.143081
            ],
            [
              -80.066801,
              35.111134
            ],
            [
              -80.039694,
              35.101582
            ],
            [
              -80.022915,
              35.090044
            ],
            [
              -79.992941,
              35.08559
            ],
            [
              -79.975778,
              35.102972
            ],
            [
              -79.946122,
              35.118593
            ],
            [
              -79.923972,
              35.11543
            ],
            [
              -79.912936,
              35.097127
            ],
            [
              -79.908983,
              35.066963
            ],
            [
              -79.883596,
              35.051722
            ],
            [
              -79.877197,
              35.03531
            ],
            [
              -79.892751,
              35.024399
            ],
            [
              -79.89929,
              35.003674
            ],
            [
              -79.867138,
              34.98226
            ],
            [
              -79.863813,
              34.974208
            ],
            [
              -79.872668,
              34.952838
            ],
            [
              -79.84961,
              34.911362
            ],
            [
              -79.848688,
              34.902608
            ],
            [
              -79.863019,
              34.882479
            ],
            [
              -79.880145,
              34.866798
            ],
            [
              -79.902963,
              34.854545
            ],
            [
              -79.913755,
              34.818922
            ],
            [
              -79.9274157876858,
              34.8065508188027
            ],
            [
              -79.891443,
              34.805807
            ],
            [
              -79.870693,
              34.805378
            ],
            [
              -79.8122754663295,
              34.805710745155004
            ],
            [
              -79.773607,
              34.805931
            ],
            [
              -79.772829,
              34.805954
            ],
            [
              -79.744925,
              34.805686
            ],
            [
              -79.744116,
              34.805651
            ],
            [
              -79.6929464055027,
              34.804973357591194
            ],
            [
              -79.654038,
              34.815064
            ],
            [
              -79.634571,
              34.825493
            ],
            [
              -79.614168,
              34.815338
            ],
            [
              -79.622929,
              34.826817
            ],
            [
              -79.617367,
              34.839282
            ],
            [
              -79.626557,
              34.852284
            ],
            [
              -79.616,
              34.865928
            ],
            [
              -79.561856,
              34.91035
            ],
            [
              -79.568388,
              34.927501
            ],
            [
              -79.58173,
              34.935442
            ],
            [
              -79.585534,
              34.954958
            ],
            [
              -79.577993,
              34.972386
            ],
            [
              -79.566667,
              34.979415
            ],
            [
              -79.573423,
              34.99221
            ],
            [
              -79.510506,
              35.026152
            ],
            [
              -79.46864,
              35.036662
            ],
            [
              -79.458751,
              35.043639
            ],
            [
              -79.481872,
              35.058878
            ],
            [
              -79.501304,
              35.064235
            ],
            [
              -79.575931,
              35.069904
            ],
            [
              -79.582779,
              35.08396
            ],
            [
              -79.573312,
              35.12245
            ],
            [
              -79.589079,
              35.138974
            ],
            [
              -79.609409,
              35.148971
            ],
            [
              -79.614304,
              35.163679
            ],
            [
              -79.642129,
              35.157717
            ],
            [
              -79.673494,
              35.175374
            ],
            [
              -79.694365,
              35.179642
            ],
            [
              -79.712721,
              35.173168
            ],
            [
              -79.733198,
              35.178176
            ],
            [
              -79.749463,
              35.172999
            ],
            [
              -79.768378,
              35.177174
            ],
            [
              -79.792011,
              35.175952
            ],
            [
              -79.812862,
              35.1799
            ],
            [
              -79.836139,
              35.177232
            ],
            [
              -79.851268,
              35.183855
            ],
            [
              -79.89861,
              35.161714
            ],
            [
              -79.944271,
              35.164169
            ],
            [
              -79.949573,
              35.157502
            ],
            [
              -79.983399,
              35.15851
            ],
            [
              -80.022232,
              35.137378
            ],
            [
              -80.075365,
              35.143081
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "165",
      "COUNTYNS": "01008585",
      "AFFGEOID": "0500000US37165",
      "GEOID": "37165",
      "NAME": "Scotland",
      "LSAD": "06",
      "ALAND": 826556054,
      "AWATER": 3841985,
      "County_state": "Scotland,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.6929464055027,
              34.804973357591194
            ],
            [
              -79.690201,
              34.804937
            ],
            [
              -79.688088,
              34.804897
            ],
            [
              -79.675299,
              34.804744
            ],
            [
              -79.634216,
              34.771012
            ],
            [
              -79.631577,
              34.768835
            ],
            [
              -79.561691,
              34.711996
            ],
            [
              -79.554454,
              34.706363
            ],
            [
              -79.53249202544909,
              34.6883514183558
            ],
            [
              -79.520269,
              34.678327
            ],
            [
              -79.519043,
              34.677321
            ],
            [
              -79.490201,
              34.653819
            ],
            [
              -79.479305,
              34.64464
            ],
            [
              -79.4792780633414,
              34.6446139931559
            ],
            [
              -79.471599,
              34.6372
            ],
            [
              -79.468717,
              34.635323
            ],
            [
              -79.4615628883712,
              34.6302980076855
            ],
            [
              -79.337078,
              34.76709
            ],
            [
              -79.357624,
              34.820306
            ],
            [
              -79.356167,
              34.82193
            ],
            [
              -79.354334,
              34.822631
            ],
            [
              -79.352445,
              34.825804
            ],
            [
              -79.351749,
              34.827902
            ],
            [
              -79.35306,
              34.827589
            ],
            [
              -79.353616,
              34.82875
            ],
            [
              -79.352019,
              34.830361
            ],
            [
              -79.351964,
              34.832638
            ],
            [
              -79.350971,
              34.833013
            ],
            [
              -79.35095,
              34.835068
            ],
            [
              -79.349972,
              34.835391
            ],
            [
              -79.349173,
              34.838478
            ],
            [
              -79.347823,
              34.838617
            ],
            [
              -79.337639,
              34.852702
            ],
            [
              -79.348854,
              34.887446
            ],
            [
              -79.350207,
              34.922367
            ],
            [
              -79.356088,
              34.932951
            ],
            [
              -79.353124,
              34.944242
            ],
            [
              -79.378489,
              34.975665
            ],
            [
              -79.398496,
              34.995225
            ],
            [
              -79.417752,
              35.004729
            ],
            [
              -79.428471,
              35.016079
            ],
            [
              -79.442695,
              35.02298
            ],
            [
              -79.457198,
              35.037558
            ],
            [
              -79.458751,
              35.043639
            ],
            [
              -79.46864,
              35.036662
            ],
            [
              -79.510506,
              35.026152
            ],
            [
              -79.573423,
              34.99221
            ],
            [
              -79.566667,
              34.979415
            ],
            [
              -79.577993,
              34.972386
            ],
            [
              -79.585534,
              34.954958
            ],
            [
              -79.58173,
              34.935442
            ],
            [
              -79.568388,
              34.927501
            ],
            [
              -79.561856,
              34.91035
            ],
            [
              -79.616,
              34.865928
            ],
            [
              -79.626557,
              34.852284
            ],
            [
              -79.617367,
              34.839282
            ],
            [
              -79.622929,
              34.826817
            ],
            [
              -79.614168,
              34.815338
            ],
            [
              -79.634571,
              34.825493
            ],
            [
              -79.654038,
              34.815064
            ],
            [
              -79.6929464055027,
              34.804973357591194
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "057",
      "COUNTYNS": "01008548",
      "AFFGEOID": "0500000US37057",
      "GEOID": "37057",
      "NAME": "Davidson",
      "LSAD": "06",
      "ALAND": 1432711780,
      "AWATER": 37452228,
      "County_state": "Davidson,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.478391,
              35.843329
            ],
            [
              -80.487586,
              35.83291
            ],
            [
              -80.472337,
              35.825543
            ],
            [
              -80.464449,
              35.804983
            ],
            [
              -80.476702,
              35.789093
            ],
            [
              -80.450658,
              35.765757
            ],
            [
              -80.458884,
              35.743031
            ],
            [
              -80.454635,
              35.73537
            ],
            [
              -80.423226,
              35.725864
            ],
            [
              -80.394389,
              35.724856
            ],
            [
              -80.391649,
              35.722273
            ],
            [
              -80.373118,
              35.716636
            ],
            [
              -80.334509,
              35.720471
            ],
            [
              -80.325572,
              35.70282
            ],
            [
              -80.330602,
              35.678799
            ],
            [
              -80.308321,
              35.666901
            ],
            [
              -80.284345,
              35.633588
            ],
            [
              -80.267129,
              35.630732
            ],
            [
              -80.250366,
              35.619388
            ],
            [
              -80.23109,
              35.59601
            ],
            [
              -80.208563,
              35.580609
            ],
            [
              -80.209794,
              35.552263
            ],
            [
              -80.182559,
              35.504151
            ],
            [
              -80.066842,
              35.505661
            ],
            [
              -80.053437,
              35.801643
            ],
            [
              -80.046869,
              35.920693
            ],
            [
              -80.043238,
              36.010758
            ],
            [
              -80.149407,
              36.026768
            ],
            [
              -80.19685,
              36.026805
            ],
            [
              -80.21373,
              36.026808
            ],
            [
              -80.213823,
              36.014189
            ],
            [
              -80.213842,
              35.996699
            ],
            [
              -80.238314,
              35.996677
            ],
            [
              -80.330298,
              35.996556
            ],
            [
              -80.394307,
              35.972762
            ],
            [
              -80.377751,
              35.961845
            ],
            [
              -80.375576,
              35.941435
            ],
            [
              -80.368387,
              35.928398
            ],
            [
              -80.375074,
              35.912366
            ],
            [
              -80.36508,
              35.893908
            ],
            [
              -80.370009,
              35.885747
            ],
            [
              -80.407859,
              35.891481
            ],
            [
              -80.416533,
              35.880469
            ],
            [
              -80.391592,
              35.869845
            ],
            [
              -80.385784,
              35.854587
            ],
            [
              -80.398823,
              35.841347
            ],
            [
              -80.42332,
              35.851964
            ],
            [
              -80.429257,
              35.829947
            ],
            [
              -80.446992,
              35.836528
            ],
            [
              -80.461527,
              35.826716
            ],
            [
              -80.478391,
              35.843329
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "007",
      "COUNTYNS": "01008534",
      "AFFGEOID": "0500000US37007",
      "GEOID": "37007",
      "NAME": "Anson",
      "LSAD": "06",
      "ALAND": 1376469217,
      "AWATER": 14612134,
      "County_state": "Anson,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.3208073604558,
              34.814081463212
            ],
            [
              -80.30469,
              34.813868
            ],
            [
              -80.283627,
              34.813589
            ],
            [
              -80.23396,
              34.812931
            ],
            [
              -80.229705,
              34.812843
            ],
            [
              -80.159252,
              34.81139
            ],
            [
              -80.131169,
              34.810811
            ],
            [
              -80.098994,
              34.810147
            ],
            [
              -80.098022,
              34.810147
            ],
            [
              -80.077223,
              34.809716
            ],
            [
              -80.072912,
              34.809736
            ],
            [
              -80.042764,
              34.809097
            ],
            [
              -80.027464,
              34.808726
            ],
            [
              -80.0132736813764,
              34.8084176637672
            ],
            [
              -80.000541,
              34.808141
            ],
            [
              -79.92800950600581,
              34.8065635148516
            ],
            [
              -79.927618,
              34.806555
            ],
            [
              -79.9274157876858,
              34.8065508188027
            ],
            [
              -79.913755,
              34.818922
            ],
            [
              -79.902963,
              34.854545
            ],
            [
              -79.880145,
              34.866798
            ],
            [
              -79.863019,
              34.882479
            ],
            [
              -79.848688,
              34.902608
            ],
            [
              -79.84961,
              34.911362
            ],
            [
              -79.872668,
              34.952838
            ],
            [
              -79.863813,
              34.974208
            ],
            [
              -79.867138,
              34.98226
            ],
            [
              -79.89929,
              35.003674
            ],
            [
              -79.892751,
              35.024399
            ],
            [
              -79.877197,
              35.03531
            ],
            [
              -79.883596,
              35.051722
            ],
            [
              -79.908983,
              35.066963
            ],
            [
              -79.912936,
              35.097127
            ],
            [
              -79.923972,
              35.11543
            ],
            [
              -79.946122,
              35.118593
            ],
            [
              -79.975778,
              35.102972
            ],
            [
              -79.992941,
              35.08559
            ],
            [
              -80.022915,
              35.090044
            ],
            [
              -80.039694,
              35.101582
            ],
            [
              -80.066801,
              35.111134
            ],
            [
              -80.075365,
              35.143081
            ],
            [
              -80.093319,
              35.163926
            ],
            [
              -80.107444,
              35.173664
            ],
            [
              -80.112644,
              35.193882
            ],
            [
              -80.129052,
              35.187581
            ],
            [
              -80.124481,
              35.176953
            ],
            [
              -80.136233,
              35.167486
            ],
            [
              -80.153889,
              35.174947
            ],
            [
              -80.165379,
              35.164787
            ],
            [
              -80.159851,
              35.151176
            ],
            [
              -80.172819,
              35.147847
            ],
            [
              -80.190638,
              35.155538
            ],
            [
              -80.19492,
              35.165381
            ],
            [
              -80.207127,
              35.156576
            ],
            [
              -80.225002,
              35.156241
            ],
            [
              -80.2421,
              35.181573
            ],
            [
              -80.254019,
              35.211251
            ],
            [
              -80.276829,
              35.195722
            ],
            [
              -80.3208073604558,
              34.814081463212
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "161",
      "COUNTYNS": "01008584",
      "AFFGEOID": "0500000US37161",
      "GEOID": "37161",
      "NAME": "Rutherford",
      "LSAD": "06",
      "ALAND": 1464487608,
      "AWATER": 4681435,
      "County_state": "Rutherford,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.280563,
              35.443069
            ],
            [
              -82.270228,
              35.400549
            ],
            [
              -82.261305,
              35.393198
            ],
            [
              -82.239115,
              35.402557
            ],
            [
              -82.226817,
              35.395475
            ],
            [
              -82.204552,
              35.40363
            ],
            [
              -82.18515,
              35.396619
            ],
            [
              -82.171514,
              35.40943
            ],
            [
              -82.151916,
              35.407719
            ],
            [
              -82.129209,
              35.396852
            ],
            [
              -82.079662,
              35.352978
            ],
            [
              -82.077075,
              35.341973
            ],
            [
              -82.056883,
              35.336946
            ],
            [
              -82.029517,
              35.301502
            ],
            [
              -82.001167,
              35.283009
            ],
            [
              -81.995719,
              35.270615
            ],
            [
              -81.968788,
              35.257764
            ],
            [
              -81.962806,
              35.22686
            ],
            [
              -81.970882,
              35.211589
            ],
            [
              -81.9693458232069,
              35.1872287391339
            ],
            [
              -81.925612,
              35.185505
            ],
            [
              -81.874433,
              35.184113
            ],
            [
              -81.8743859859076,
              35.1841112338525
            ],
            [
              -81.802081,
              35.181395
            ],
            [
              -81.772351,
              35.180514
            ],
            [
              -81.7681085961812,
              35.180388327917804
            ],
            [
              -81.759541,
              35.222646
            ],
            [
              -81.702853,
              35.357304
            ],
            [
              -81.691989,
              35.580041
            ],
            [
              -81.705653,
              35.581214
            ],
            [
              -81.721701,
              35.606308
            ],
            [
              -81.732971,
              35.601778
            ],
            [
              -81.753407,
              35.613588
            ],
            [
              -81.772182,
              35.592623
            ],
            [
              -81.796693,
              35.583786
            ],
            [
              -81.822268,
              35.580434
            ],
            [
              -81.824122,
              35.574983
            ],
            [
              -81.842652,
              35.542533
            ],
            [
              -81.904954,
              35.541917
            ],
            [
              -81.920899,
              35.533661
            ],
            [
              -81.933121,
              35.536074
            ],
            [
              -81.967619,
              35.526007
            ],
            [
              -82.002783,
              35.551847
            ],
            [
              -82.0245,
              35.537361
            ],
            [
              -82.039843,
              35.532704
            ],
            [
              -82.095766,
              35.531666
            ],
            [
              -82.150538,
              35.518002
            ],
            [
              -82.169049,
              35.527811
            ],
            [
              -82.233203,
              35.519402
            ],
            [
              -82.224336,
              35.498269
            ],
            [
              -82.251437,
              35.460685
            ],
            [
              -82.265795,
              35.467818
            ],
            [
              -82.280563,
              35.443069
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "199",
      "COUNTYNS": "01008598",
      "AFFGEOID": "0500000US37199",
      "GEOID": "37199",
      "NAME": "Yancey",
      "LSAD": "06",
      "ALAND": 809602887,
      "AWATER": 1531168,
      "County_state": "Yancey,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.505384,
              35.97768
            ],
            [
              -82.5054541504531,
              35.9776714603071
            ],
            [
              -82.496214,
              35.956644
            ],
            [
              -82.480098,
              35.948211
            ],
            [
              -82.498548,
              35.930193
            ],
            [
              -82.49036,
              35.921215
            ],
            [
              -82.494781,
              35.909484
            ],
            [
              -82.474775,
              35.909245
            ],
            [
              -82.453953,
              35.899872
            ],
            [
              -82.439228,
              35.87922
            ],
            [
              -82.417552,
              35.859363
            ],
            [
              -82.419636,
              35.843335
            ],
            [
              -82.408602,
              35.818178
            ],
            [
              -82.361956,
              35.821245
            ],
            [
              -82.338299,
              35.796149
            ],
            [
              -82.346191,
              35.786179
            ],
            [
              -82.335054,
              35.749396
            ],
            [
              -82.313065,
              35.733603
            ],
            [
              -82.284733,
              35.725526
            ],
            [
              -82.275399,
              35.704438
            ],
            [
              -82.257842,
              35.698852
            ],
            [
              -82.242765,
              35.712448
            ],
            [
              -82.193402,
              35.735314
            ],
            [
              -82.158307,
              35.779738
            ],
            [
              -82.167895,
              35.789138
            ],
            [
              -82.133484,
              35.823309
            ],
            [
              -82.143507,
              35.825451
            ],
            [
              -82.15008,
              35.841656
            ],
            [
              -82.140292,
              35.842615
            ],
            [
              -82.149925,
              35.892345
            ],
            [
              -82.142693,
              35.90839
            ],
            [
              -82.154804,
              35.914771
            ],
            [
              -82.152719,
              35.928302
            ],
            [
              -82.169724,
              35.928617
            ],
            [
              -82.19774,
              35.952672
            ],
            [
              -82.186312,
              35.960958
            ],
            [
              -82.191825,
              35.969453
            ],
            [
              -82.191705,
              35.990636
            ],
            [
              -82.20412,
              36.003603
            ],
            [
              -82.193203,
              36.002475
            ],
            [
              -82.197834,
              36.015332
            ],
            [
              -82.234319,
              36.013009
            ],
            [
              -82.237981,
              36.004724
            ],
            [
              -82.253966,
              36.005321
            ],
            [
              -82.250565,
              35.988907
            ],
            [
              -82.27332,
              36.002935
            ],
            [
              -82.26807,
              36.016625
            ],
            [
              -82.2886,
              36.02813
            ],
            [
              -82.301867,
              36.045245
            ],
            [
              -82.312435,
              36.026104
            ],
            [
              -82.338201,
              36.030088
            ],
            [
              -82.336448,
              36.052711
            ],
            [
              -82.352408,
              36.084596
            ],
            [
              -82.380081,
              36.07074
            ],
            [
              -82.399075,
              36.081918
            ],
            [
              -82.399418,
              36.071636
            ],
            [
              -82.41666077270189,
              36.072773655932195
            ],
            [
              -82.460658,
              36.007809
            ],
            [
              -82.464558,
              36.006508
            ],
            [
              -82.47419,
              36.000108
            ],
            [
              -82.482292,
              35.997823
            ],
            [
              -82.483498,
              35.996284
            ],
            [
              -82.483666,
              35.993866
            ],
            [
              -82.484678,
              35.992849
            ],
            [
              -82.487411,
              35.991634
            ],
            [
              -82.487451,
              35.991557
            ],
            [
              -82.500206,
              35.982561
            ],
            [
              -82.505384,
              35.97768
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "173",
      "COUNTYNS": "01008588",
      "AFFGEOID": "0500000US37173",
      "GEOID": "37173",
      "NAME": "Swain",
      "LSAD": "06",
      "ALAND": 1366812262,
      "AWATER": 32414382,
      "County_state": "Swain,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.949389,
              35.461164
            ],
            [
              -83.952882,
              35.460635
            ],
            [
              -83.9443,
              35.450788
            ],
            [
              -83.927698,
              35.450647
            ],
            [
              -83.914077,
              35.465054
            ],
            [
              -83.873794,
              35.460413
            ],
            [
              -83.864579,
              35.448312
            ],
            [
              -83.844907,
              35.453121
            ],
            [
              -83.806711,
              35.444824
            ],
            [
              -83.803636,
              35.455093
            ],
            [
              -83.774475,
              35.443475
            ],
            [
              -83.757233,
              35.441703
            ],
            [
              -83.731873,
              35.447195
            ],
            [
              -83.65789,
              35.432261
            ],
            [
              -83.645014,
              35.432029
            ],
            [
              -83.643447,
              35.443196
            ],
            [
              -83.618694,
              35.436113
            ],
            [
              -83.610814,
              35.427045
            ],
            [
              -83.585756,
              35.434832
            ],
            [
              -83.582558,
              35.410489
            ],
            [
              -83.593092,
              35.376986
            ],
            [
              -83.621251,
              35.345123
            ],
            [
              -83.660507,
              35.335626
            ],
            [
              -83.684534,
              35.324191
            ],
            [
              -83.695611,
              35.299614
            ],
            [
              -83.680114,
              35.2795
            ],
            [
              -83.672815,
              35.282796
            ],
            [
              -83.495347,
              35.299633
            ],
            [
              -83.490276,
              35.305547
            ],
            [
              -83.467181,
              35.297861
            ],
            [
              -83.452066,
              35.314204
            ],
            [
              -83.432454,
              35.325183
            ],
            [
              -83.410749,
              35.316557
            ],
            [
              -83.399564,
              35.32777
            ],
            [
              -83.364228,
              35.325993
            ],
            [
              -83.339609,
              35.330993
            ],
            [
              -83.331508,
              35.354262
            ],
            [
              -83.341143,
              35.397378
            ],
            [
              -83.360704,
              35.411813
            ],
            [
              -83.363732,
              35.426646
            ],
            [
              -83.351726,
              35.468092
            ],
            [
              -83.335629,
              35.474709
            ],
            [
              -83.318342,
              35.465262
            ],
            [
              -83.308931,
              35.479323
            ],
            [
              -83.285278,
              35.4889
            ],
            [
              -83.281518,
              35.499009
            ],
            [
              -83.236073,
              35.524564
            ],
            [
              -83.216248,
              35.526005
            ],
            [
              -83.210607,
              35.519325
            ],
            [
              -83.186322,
              35.514368
            ],
            [
              -83.174414,
              35.52139
            ],
            [
              -83.171609,
              35.537646
            ],
            [
              -83.155835,
              35.552622
            ],
            [
              -83.166964,
              35.57869
            ],
            [
              -83.182109,
              35.584537
            ],
            [
              -83.185519,
              35.598271
            ],
            [
              -83.17598,
              35.61245
            ],
            [
              -83.175871,
              35.6275
            ],
            [
              -83.202323,
              35.650252
            ],
            [
              -83.181977,
              35.671327
            ],
            [
              -83.207665,
              35.681599
            ],
            [
              -83.214907,
              35.692112
            ],
            [
              -83.25561,
              35.696061
            ],
            [
              -83.258117,
              35.691924
            ],
            [
              -83.261252,
              35.689165
            ],
            [
              -83.26539,
              35.687535
            ],
            [
              -83.269277,
              35.685403
            ],
            [
              -83.271378,
              35.681476
            ],
            [
              -83.27548,
              35.679463
            ],
            [
              -83.281178,
              35.677802
            ],
            [
              -83.289165,
              35.674509
            ],
            [
              -83.290682,
              35.672638
            ],
            [
              -83.291075,
              35.667131
            ],
            [
              -83.293676,
              35.661919
            ],
            [
              -83.297154,
              35.65775
            ],
            [
              -83.302279,
              35.656064
            ],
            [
              -83.31049,
              35.654452
            ],
            [
              -83.312757,
              35.654809
            ],
            [
              -83.317905,
              35.659015
            ],
            [
              -83.321101,
              35.662815
            ],
            [
              -83.334965,
              35.665471
            ],
            [
              -83.337683,
              35.663074
            ],
            [
              -83.347262,
              35.660474
            ],
            [
              -83.349255,
              35.660854
            ],
            [
              -83.35156,
              35.659858
            ],
            [
              -83.353776,
              35.657478
            ],
            [
              -83.355537,
              35.654632
            ],
            [
              -83.355367,
              35.652338
            ],
            [
              -83.356202,
              35.650019
            ],
            [
              -83.358209,
              35.647277
            ],
            [
              -83.366941,
              35.638728
            ],
            [
              -83.368162,
              35.638202
            ],
            [
              -83.370369,
              35.638204
            ],
            [
              -83.372174,
              35.63931
            ],
            [
              -83.373712,
              35.638935
            ],
            [
              -83.376785,
              35.636638
            ],
            [
              -83.377984,
              35.634496
            ],
            [
              -83.380251,
              35.634705
            ],
            [
              -83.388722,
              35.633584
            ],
            [
              -83.388602,
              35.632352
            ],
            [
              -83.392652,
              35.625095
            ],
            [
              -83.396626,
              35.62272
            ],
            [
              -83.403569,
              35.621313
            ],
            [
              -83.406061,
              35.620185
            ],
            [
              -83.411852,
              35.61692
            ],
            [
              -83.42037,
              35.613467
            ],
            [
              -83.420964,
              35.611596
            ],
            [
              -83.421576,
              35.611186
            ],
            [
              -83.432298,
              35.609941
            ],
            [
              -83.441197,
              35.611739
            ],
            [
              -83.445802,
              35.611803
            ],
            [
              -83.447137,
              35.608664
            ],
            [
              -83.452431,
              35.602918
            ],
            [
              -83.455722,
              35.598045
            ],
            [
              -83.462678,
              35.5926
            ],
            [
              -83.471362,
              35.590304
            ],
            [
              -83.472668,
              35.589125
            ],
            [
              -83.472684,
              35.586552
            ],
            [
              -83.475367,
              35.584775
            ],
            [
              -83.479082,
              35.583316
            ],
            [
              -83.479317,
              35.582764
            ],
            [
              -83.478523,
              35.579202
            ],
            [
              -83.480617,
              35.576633
            ],
            [
              -83.485527,
              35.568204
            ],
            [
              -83.491647,
              35.566867
            ],
            [
              -83.498335,
              35.562981
            ],
            [
              -83.517564,
              35.562871
            ],
            [
              -83.520469,
              35.565602
            ],
            [
              -83.534169,
              35.564668
            ],
            [
              -83.540826,
              35.565702
            ],
            [
              -83.552167,
              35.564346
            ],
            [
              -83.559264,
              35.564796
            ],
            [
              -83.56609,
              35.565993
            ],
            [
              -83.572424,
              35.565518
            ],
            [
              -83.576345,
              35.564019
            ],
            [
              -83.582,
              35.562684
            ],
            [
              -83.58559,
              35.562941
            ],
            [
              -83.58714,
              35.564017
            ],
            [
              -83.587827,
              35.566963
            ],
            [
              -83.59427,
              35.572912
            ],
            [
              -83.601854,
              35.578228
            ],
            [
              -83.604806,
              35.57934
            ],
            [
              -83.608889,
              35.579451
            ],
            [
              -83.615312,
              35.574026
            ],
            [
              -83.629734,
              35.567889
            ],
            [
              -83.632358,
              35.569093
            ],
            [
              -83.635832,
              35.568169
            ],
            [
              -83.637182,
              35.567096
            ],
            [
              -83.640498,
              35.566075
            ],
            [
              -83.645481,
              35.565825
            ],
            [
              -83.653159,
              35.568309
            ],
            [
              -83.657933,
              35.569211
            ],
            [
              -83.660925,
              35.568207
            ],
            [
              -83.662957,
              35.569138
            ],
            [
              -83.66302360458259,
              35.5691430430619
            ],
            [
              -83.666272,
              35.569389
            ],
            [
              -83.673093,
              35.568974
            ],
            [
              -83.676268,
              35.570289
            ],
            [
              -83.684154,
              35.568848
            ],
            [
              -83.697827,
              35.568352
            ],
            [
              -83.700663,
              35.567621
            ],
            [
              -83.702099,
              35.567634
            ],
            [
              -83.703846,
              35.568476
            ],
            [
              -83.707199,
              35.568533
            ],
            [
              -83.720787,
              35.563347
            ],
            [
              -83.723459,
              35.561874
            ],
            [
              -83.732947,
              35.563149
            ],
            [
              -83.735669,
              35.565455
            ],
            [
              -83.749894,
              35.561146
            ],
            [
              -83.756917,
              35.563604
            ],
            [
              -83.759675,
              35.562492
            ],
            [
              -83.764606,
              35.561538
            ],
            [
              -83.771736,
              35.562118
            ],
            [
              -83.773092,
              35.557465
            ],
            [
              -83.780129,
              35.550387
            ],
            [
              -83.786802,
              35.5472
            ],
            [
              -83.802434,
              35.541588
            ],
            [
              -83.808713,
              35.536415
            ],
            [
              -83.809798,
              35.53431
            ],
            [
              -83.82559,
              35.523829
            ],
            [
              -83.827428,
              35.524653
            ],
            [
              -83.831895,
              35.524766
            ],
            [
              -83.840203,
              35.52156
            ],
            [
              -83.848502,
              35.519259
            ],
            [
              -83.853898,
              35.521059
            ],
            [
              -83.859261,
              35.521851
            ],
            [
              -83.866413,
              35.52091
            ],
            [
              -83.87263,
              35.521145
            ],
            [
              -83.880074,
              35.518745
            ],
            [
              -83.882563,
              35.517182
            ],
            [
              -83.884262,
              35.512754
            ],
            [
              -83.892074,
              35.503089
            ],
            [
              -83.893031,
              35.502253
            ],
            [
              -83.895669,
              35.501868
            ],
            [
              -83.901381,
              35.496553
            ],
            [
              -83.901403,
              35.495278
            ],
            [
              -83.9002,
              35.494191
            ],
            [
              -83.900338,
              35.493095
            ],
            [
              -83.901527,
              35.491026
            ],
            [
              -83.905612,
              35.48906
            ],
            [
              -83.90804,
              35.484397
            ],
            [
              -83.909265,
              35.479714
            ],
            [
              -83.911773,
              35.476028
            ],
            [
              -83.916801,
              35.473612
            ],
            [
              -83.919564,
              35.473367
            ],
            [
              -83.924895,
              35.473884
            ],
            [
              -83.929743,
              35.47333
            ],
            [
              -83.937015,
              35.471511
            ],
            [
              -83.942172,
              35.467254
            ],
            [
              -83.942987,
              35.465084
            ],
            [
              -83.949389,
              35.461164
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "091",
      "COUNTYNS": "01026127",
      "AFFGEOID": "0500000US37091",
      "GEOID": "37091",
      "NAME": "Hertford",
      "LSAD": "06",
      "ALAND": 914685904,
      "AWATER": 18740844,
      "County_state": "Hertford,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.208468,
              36.246578
            ],
            [
              -77.183144,
              36.242825
            ],
            [
              -77.008384,
              36.239113
            ],
            [
              -76.986159,
              36.242074
            ],
            [
              -76.971399,
              36.23813
            ],
            [
              -76.903383,
              36.242986
            ],
            [
              -76.7201982142909,
              36.2438051311307
            ],
            [
              -76.693253,
              36.278357
            ],
            [
              -76.6916399439569,
              36.2773165965322
            ],
            [
              -76.694813,
              36.285023
            ],
            [
              -76.696571,
              36.296138
            ],
            [
              -76.735329,
              36.324061
            ],
            [
              -76.761314,
              36.352412
            ],
            [
              -76.779467,
              36.362469
            ],
            [
              -76.795409,
              36.359717
            ],
            [
              -76.821442,
              36.364127
            ],
            [
              -76.896113,
              36.390444
            ],
            [
              -76.907887,
              36.390732
            ],
            [
              -76.93347,
              36.40187
            ],
            [
              -76.946258,
              36.413822
            ],
            [
              -76.945262,
              36.440203
            ],
            [
              -76.950606,
              36.47079
            ],
            [
              -76.933375,
              36.472623
            ],
            [
              -76.919357,
              36.505403
            ],
            [
              -76.906462,
              36.510692
            ],
            [
              -76.903964,
              36.521391
            ],
            [
              -76.921464,
              36.538449
            ],
            [
              -76.917149,
              36.538426
            ],
            [
              -76.916143,
              36.538943
            ],
            [
              -76.914002,
              36.542661
            ],
            [
              -76.914532,
              36.543738
            ],
            [
              -76.916060288835,
              36.5438365877093
            ],
            [
              -76.916048,
              36.543815
            ],
            [
              -77.095062,
              36.544626
            ],
            [
              -77.124812,
              36.543986
            ],
            [
              -77.152691,
              36.544078
            ],
            [
              -77.164370064468,
              36.54630522103329
            ],
            [
              -77.148685,
              36.534142
            ],
            [
              -77.14787,
              36.524985
            ],
            [
              -77.093224,
              36.518216
            ],
            [
              -77.082474,
              36.511469
            ],
            [
              -77.085432,
              36.499961
            ],
            [
              -77.066463,
              36.496511
            ],
            [
              -77.070434,
              36.479908
            ],
            [
              -77.091502,
              36.471751
            ],
            [
              -77.09993,
              36.479095
            ],
            [
              -77.122959,
              36.471218
            ],
            [
              -77.137228,
              36.455383
            ],
            [
              -77.133038,
              36.433637
            ],
            [
              -77.208468,
              36.246578
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "051",
      "COUNTYNS": "01026124",
      "AFFGEOID": "0500000US37051",
      "GEOID": "37051",
      "NAME": "Cumberland",
      "LSAD": "06",
      "ALAND": 1690029281,
      "AWATER": 15379703,
      "County_state": "Cumberland,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.112921,
              35.125651
            ],
            [
              -79.101162,
              35.114332
            ],
            [
              -79.089398,
              35.070442
            ],
            [
              -79.090291,
              35.042031
            ],
            [
              -79.056831,
              34.999824
            ],
            [
              -79.034775,
              34.963119
            ],
            [
              -79.037538,
              34.957742
            ],
            [
              -79.036917,
              34.956953
            ],
            [
              -79.038747,
              34.952715
            ],
            [
              -78.95486,
              34.913266
            ],
            [
              -78.926165,
              34.885595
            ],
            [
              -78.927018,
              34.863957
            ],
            [
              -78.90541,
              34.836837
            ],
            [
              -78.901998,
              34.835268
            ],
            [
              -78.842858,
              34.847411
            ],
            [
              -78.799597,
              34.850857
            ],
            [
              -78.533633,
              34.851129
            ],
            [
              -78.516123,
              34.845919
            ],
            [
              -78.494705,
              34.856182
            ],
            [
              -78.513107,
              34.869088
            ],
            [
              -78.512699,
              34.880596
            ],
            [
              -78.547516,
              34.902346
            ],
            [
              -78.546074,
              34.915851
            ],
            [
              -78.557797,
              34.91538
            ],
            [
              -78.572832,
              34.937515
            ],
            [
              -78.614897,
              34.96283
            ],
            [
              -78.647033,
              34.992254
            ],
            [
              -78.644994,
              35.008401
            ],
            [
              -78.658378,
              35.041989
            ],
            [
              -78.662955,
              35.077962
            ],
            [
              -78.672292,
              35.085281
            ],
            [
              -78.658238,
              35.094466
            ],
            [
              -78.653809,
              35.111416
            ],
            [
              -78.639478,
              35.115774
            ],
            [
              -78.651929,
              35.118962
            ],
            [
              -78.634742,
              35.140119
            ],
            [
              -78.638676,
              35.165887
            ],
            [
              -78.629524,
              35.171187
            ],
            [
              -78.638836,
              35.18205
            ],
            [
              -78.635717,
              35.191685
            ],
            [
              -78.646385,
              35.196679
            ],
            [
              -78.624376,
              35.203983
            ],
            [
              -78.631365,
              35.213406
            ],
            [
              -78.61706,
              35.228267
            ],
            [
              -78.617127,
              35.245578
            ],
            [
              -78.718506,
              35.259689
            ],
            [
              -78.725564,
              35.2669
            ],
            [
              -78.781852,
              35.260897
            ],
            [
              -78.79428,
              35.266101
            ],
            [
              -78.804776,
              35.257167
            ],
            [
              -78.824915,
              35.259648
            ],
            [
              -78.847158,
              35.252986
            ],
            [
              -78.859265,
              35.242694
            ],
            [
              -78.876057,
              35.241536
            ],
            [
              -78.880408,
              35.23044
            ],
            [
              -78.898329,
              35.23099
            ],
            [
              -78.935845,
              35.218775
            ],
            [
              -78.941654,
              35.219248
            ],
            [
              -78.976351,
              35.212239
            ],
            [
              -78.998037,
              35.21413
            ],
            [
              -79.027039,
              35.207879
            ],
            [
              -79.095808,
              35.192068
            ],
            [
              -79.098261,
              35.187729
            ],
            [
              -79.097602,
              35.174194
            ],
            [
              -79.112921,
              35.125651
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "193",
      "COUNTYNS": "01008595",
      "AFFGEOID": "0500000US37193",
      "GEOID": "37193",
      "NAME": "Wilkes",
      "LSAD": "06",
      "ALAND": 1954249751,
      "AWATER": 6862434,
      "County_state": "Wilkes,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.545488,
              36.117475
            ],
            [
              -81.508413,
              36.096977
            ],
            [
              -81.47729,
              36.09685
            ],
            [
              -81.467274,
              36.085657
            ],
            [
              -81.458782,
              36.091382
            ],
            [
              -81.44477,
              36.072131
            ],
            [
              -81.424141,
              36.077801
            ],
            [
              -81.397773,
              36.066431
            ],
            [
              -81.389463,
              36.050128
            ],
            [
              -81.396487,
              36.040885
            ],
            [
              -81.381479,
              36.044611
            ],
            [
              -81.343814,
              36.023309
            ],
            [
              -81.328324,
              35.996583
            ],
            [
              -81.301418,
              36.004291
            ],
            [
              -81.263596,
              36.025818
            ],
            [
              -81.244377,
              36.024832
            ],
            [
              -81.19028,
              36.037212
            ],
            [
              -81.166664,
              36.025668
            ],
            [
              -81.13283,
              36.036923
            ],
            [
              -81.098113,
              36.028162
            ],
            [
              -81.057572,
              36.03155
            ],
            [
              -81.028833,
              36.045652
            ],
            [
              -80.975675,
              36.05881
            ],
            [
              -80.881591,
              36.055718
            ],
            [
              -80.878807,
              36.120025
            ],
            [
              -80.873205,
              36.236305
            ],
            [
              -80.869752,
              36.306629
            ],
            [
              -80.868746,
              36.326447
            ],
            [
              -80.892653,
              36.340647
            ],
            [
              -80.905784,
              36.361099
            ],
            [
              -80.942136,
              36.373604
            ],
            [
              -80.967153,
              36.40202
            ],
            [
              -80.98131,
              36.376492
            ],
            [
              -80.990358,
              36.378352
            ],
            [
              -81.013613,
              36.37127
            ],
            [
              -81.026688,
              36.373195
            ],
            [
              -81.043789,
              36.393323
            ],
            [
              -81.044272,
              36.411063
            ],
            [
              -81.077794,
              36.417806
            ],
            [
              -81.095112,
              36.440773
            ],
            [
              -81.128398,
              36.425899
            ],
            [
              -81.138242,
              36.412479
            ],
            [
              -81.162956,
              36.432515
            ],
            [
              -81.189256,
              36.399351
            ],
            [
              -81.220168,
              36.377603
            ],
            [
              -81.243523,
              36.366057
            ],
            [
              -81.253649,
              36.366601
            ],
            [
              -81.259327,
              36.356312
            ],
            [
              -81.282005,
              36.354592
            ],
            [
              -81.291219,
              36.36443
            ],
            [
              -81.306485,
              36.364426
            ],
            [
              -81.321935,
              36.373233
            ],
            [
              -81.359255,
              36.366433
            ],
            [
              -81.366601,
              36.359101
            ],
            [
              -81.363029,
              36.31325
            ],
            [
              -81.366725,
              36.284447
            ],
            [
              -81.388309,
              36.284682
            ],
            [
              -81.415853,
              36.29223
            ],
            [
              -81.413125,
              36.281457
            ],
            [
              -81.442731,
              36.259847
            ],
            [
              -81.464606,
              36.256885
            ],
            [
              -81.477516,
              36.24025
            ],
            [
              -81.455792,
              36.200441
            ],
            [
              -81.500402,
              36.142512
            ],
            [
              -81.545488,
              36.117475
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "163",
      "COUNTYNS": "01026131",
      "AFFGEOID": "0500000US37163",
      "GEOID": "37163",
      "NAME": "Sampson",
      "LSAD": "06",
      "ALAND": 2449357429,
      "AWATER": 4923826,
      "County_state": "Sampson,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.672292,
              35.085281
            ],
            [
              -78.662955,
              35.077962
            ],
            [
              -78.658378,
              35.041989
            ],
            [
              -78.644994,
              35.008401
            ],
            [
              -78.647033,
              34.992254
            ],
            [
              -78.614897,
              34.96283
            ],
            [
              -78.572832,
              34.937515
            ],
            [
              -78.557797,
              34.91538
            ],
            [
              -78.546074,
              34.915851
            ],
            [
              -78.547516,
              34.902346
            ],
            [
              -78.512699,
              34.880596
            ],
            [
              -78.513107,
              34.869088
            ],
            [
              -78.494705,
              34.856182
            ],
            [
              -78.493451,
              34.842997
            ],
            [
              -78.469462,
              34.830355
            ],
            [
              -78.449756,
              34.813507
            ],
            [
              -78.43694,
              34.784169
            ],
            [
              -78.420386,
              34.765876
            ],
            [
              -78.40971,
              34.765038
            ],
            [
              -78.398784,
              34.750104
            ],
            [
              -78.390748,
              34.749463
            ],
            [
              -78.391978,
              34.741265
            ],
            [
              -78.391911,
              34.729577
            ],
            [
              -78.368624,
              34.704309
            ],
            [
              -78.369118,
              34.69674
            ],
            [
              -78.346721,
              34.690187
            ],
            [
              -78.338689,
              34.674882
            ],
            [
              -78.323538,
              34.665662
            ],
            [
              -78.324562,
              34.649709
            ],
            [
              -78.312737,
              34.643605
            ],
            [
              -78.31399,
              34.633133
            ],
            [
              -78.304989,
              34.602438
            ],
            [
              -78.265935,
              34.583588
            ],
            [
              -78.251728,
              34.562021
            ],
            [
              -78.254441,
              34.553595
            ],
            [
              -78.15652,
              34.67907
            ],
            [
              -78.113472,
              34.707219
            ],
            [
              -78.114007,
              34.721795
            ],
            [
              -78.135194,
              34.710201
            ],
            [
              -78.150553,
              34.721964
            ],
            [
              -78.198642,
              34.741637
            ],
            [
              -78.147129,
              34.902506
            ],
            [
              -78.159818,
              35.0798
            ],
            [
              -78.171845,
              35.093528
            ],
            [
              -78.165146,
              35.131015
            ],
            [
              -78.163013,
              35.137266
            ],
            [
              -78.16342,
              35.189716
            ],
            [
              -78.161845,
              35.204323
            ],
            [
              -78.19125,
              35.211893
            ],
            [
              -78.238276,
              35.223389
            ],
            [
              -78.245021,
              35.225951
            ],
            [
              -78.257213,
              35.237154
            ],
            [
              -78.30658,
              35.287604
            ],
            [
              -78.319296,
              35.28321
            ],
            [
              -78.364213,
              35.272123
            ],
            [
              -78.397725,
              35.26296
            ],
            [
              -78.411734,
              35.254663
            ],
            [
              -78.492042,
              35.263421
            ],
            [
              -78.509817,
              35.281665
            ],
            [
              -78.52194,
              35.294521
            ],
            [
              -78.538349,
              35.31641
            ],
            [
              -78.583451,
              35.296779
            ],
            [
              -78.584639,
              35.273312
            ],
            [
              -78.617127,
              35.245578
            ],
            [
              -78.61706,
              35.228267
            ],
            [
              -78.631365,
              35.213406
            ],
            [
              -78.624376,
              35.203983
            ],
            [
              -78.646385,
              35.196679
            ],
            [
              -78.635717,
              35.191685
            ],
            [
              -78.638836,
              35.18205
            ],
            [
              -78.629524,
              35.171187
            ],
            [
              -78.638676,
              35.165887
            ],
            [
              -78.634742,
              35.140119
            ],
            [
              -78.651929,
              35.118962
            ],
            [
              -78.639478,
              35.115774
            ],
            [
              -78.653809,
              35.111416
            ],
            [
              -78.658238,
              35.094466
            ],
            [
              -78.672292,
              35.085281
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "137",
      "COUNTYNS": "01023355",
      "AFFGEOID": "0500000US37137",
      "GEOID": "37137",
      "NAME": "Pamlico",
      "LSAD": "06",
      "ALAND": 871556645,
      "AWATER": 583050685,
      "County_state": "Pamlico,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.970302,
              35.163657
            ],
            [
              -76.987537,
              35.154267
            ],
            [
              -76.968465,
              35.129273
            ],
            [
              -76.931113,
              35.117198
            ],
            [
              -76.938575,
              35.089725
            ],
            [
              -76.936149,
              35.06191
            ],
            [
              -76.941203,
              35.053
            ],
            [
              -76.95846526039449,
              35.0476471072188
            ],
            [
              -76.801426,
              34.964369
            ],
            [
              -76.639764,
              35.051006
            ],
            [
              -76.631895,
              35.056626
            ],
            [
              -76.622902,
              35.061123
            ],
            [
              -76.61391,
              35.061123
            ],
            [
              -76.60042,
              35.067867
            ],
            [
              -76.57569,
              35.092598
            ],
            [
              -76.568945,
              35.097094
            ],
            [
              -76.561077,
              35.108335
            ],
            [
              -76.557704,
              35.116204
            ],
            [
              -76.546463,
              35.122948
            ],
            [
              -76.536346,
              35.142058
            ],
            [
              -76.536346,
              35.149927
            ],
            [
              -76.539719,
              35.166788
            ],
            [
              -76.536346,
              35.174657
            ],
            [
              -76.521733,
              35.192643
            ],
            [
              -76.504872,
              35.207256
            ],
            [
              -76.494755,
              35.212877
            ],
            [
              -76.491382,
              35.220745
            ],
            [
              -76.490258,
              35.233111
            ],
            [
              -76.483514,
              35.240979
            ],
            [
              -76.477893,
              35.243228
            ],
            [
              -76.467776,
              35.261213
            ],
            [
              -76.467776,
              35.276951
            ],
            [
              -76.472273,
              35.294936
            ],
            [
              -76.482389,
              35.314046
            ],
            [
              -76.500375,
              35.321915
            ],
            [
              -76.548712,
              35.328659
            ],
            [
              -76.554332,
              35.332032
            ],
            [
              -76.588055,
              35.333156
            ],
            [
              -76.602669,
              35.336528
            ],
            [
              -76.6067536154207,
              35.3371383157563
            ],
            [
              -76.617496,
              35.328893
            ],
            [
              -76.61886,
              35.300143
            ],
            [
              -76.612186,
              35.27932
            ],
            [
              -76.632561,
              35.235324
            ],
            [
              -76.845505,
              35.216059
            ],
            [
              -76.895919,
              35.253889
            ],
            [
              -76.906081,
              35.235945
            ],
            [
              -76.93259,
              35.230286
            ],
            [
              -76.942629,
              35.213324
            ],
            [
              -76.96293,
              35.197857
            ],
            [
              -76.970302,
              35.163657
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "187",
      "COUNTYNS": "01026331",
      "AFFGEOID": "0500000US37187",
      "GEOID": "37187",
      "NAME": "Washington",
      "LSAD": "06",
      "ALAND": 897456200,
      "AWATER": 195282200,
      "County_state": "Washington,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.847264,
              35.719982
            ],
            [
              -76.84462,
              35.705719
            ],
            [
              -76.637513,
              35.705215
            ],
            [
              -76.545621,
              35.705084
            ],
            [
              -76.405971,
              35.697579
            ],
            [
              -76.405603,
              35.786294
            ],
            [
              -76.353554,
              35.861301
            ],
            [
              -76.369646,
              35.883233
            ],
            [
              -76.380009,
              35.914666
            ],
            [
              -76.367703,
              35.929999
            ],
            [
              -76.36521008469319,
              35.9446974227809
            ],
            [
              -76.381394,
              35.96273
            ],
            [
              -76.38192,
              35.971681
            ],
            [
              -76.389818,
              35.980105
            ],
            [
              -76.398242,
              35.984317
            ],
            [
              -76.460632,
              35.970365
            ],
            [
              -76.473795,
              35.960888
            ],
            [
              -76.496961,
              35.955096
            ],
            [
              -76.507491,
              35.949831
            ],
            [
              -76.528551,
              35.944039
            ],
            [
              -76.586992,
              35.941933
            ],
            [
              -76.60384,
              35.939827
            ],
            [
              -76.608052,
              35.936668
            ],
            [
              -76.657017,
              35.935089
            ],
            [
              -76.667547,
              35.933509
            ],
            [
              -76.673865,
              35.935089
            ],
            [
              -76.691766,
              35.944566
            ],
            [
              -76.6919133712548,
              35.9447535472236
            ],
            [
              -76.701832,
              35.922783
            ],
            [
              -76.719989,
              35.917259
            ],
            [
              -76.731543,
              35.887575
            ],
            [
              -76.745411,
              35.870829
            ],
            [
              -76.760598,
              35.864887
            ],
            [
              -76.777404,
              35.857839
            ],
            [
              -76.789238,
              35.838108
            ],
            [
              -76.782007,
              35.82698
            ],
            [
              -76.802396,
              35.804837
            ],
            [
              -76.792961,
              35.789905
            ],
            [
              -76.804288,
              35.781952
            ],
            [
              -76.817037,
              35.748834
            ],
            [
              -76.831328,
              35.730702
            ],
            [
              -76.847264,
              35.719982
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "125",
      "COUNTYNS": "01008573",
      "AFFGEOID": "0500000US37125",
      "GEOID": "37125",
      "NAME": "Moore",
      "LSAD": "06",
      "ALAND": 1806961891,
      "AWATER": 20736340,
      "County_state": "Moore,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.767957,
              35.511484
            ],
            [
              -79.764192,
              35.475735
            ],
            [
              -79.75173,
              35.443994
            ],
            [
              -79.709913,
              35.301275
            ],
            [
              -79.711623,
              35.275231
            ],
            [
              -79.704978,
              35.263308
            ],
            [
              -79.673005,
              35.245396
            ],
            [
              -79.655067,
              35.189783
            ],
            [
              -79.614304,
              35.163679
            ],
            [
              -79.609409,
              35.148971
            ],
            [
              -79.589079,
              35.138974
            ],
            [
              -79.573312,
              35.12245
            ],
            [
              -79.582779,
              35.08396
            ],
            [
              -79.575931,
              35.069904
            ],
            [
              -79.501304,
              35.064235
            ],
            [
              -79.481872,
              35.058878
            ],
            [
              -79.458751,
              35.043639
            ],
            [
              -79.335477,
              35.161588
            ],
            [
              -79.31054,
              35.172964
            ],
            [
              -79.281284,
              35.195292
            ],
            [
              -79.255659,
              35.202926
            ],
            [
              -79.246383,
              35.212964
            ],
            [
              -79.229712,
              35.210602
            ],
            [
              -79.211347,
              35.193977
            ],
            [
              -79.175214,
              35.19306
            ],
            [
              -79.167641,
              35.180942
            ],
            [
              -79.142711,
              35.168984
            ],
            [
              -79.132952,
              35.177749
            ],
            [
              -79.124782,
              35.166573
            ],
            [
              -79.097602,
              35.174194
            ],
            [
              -79.098261,
              35.187729
            ],
            [
              -79.095808,
              35.192068
            ],
            [
              -79.15208,
              35.214501
            ],
            [
              -79.165108,
              35.240925
            ],
            [
              -79.19375,
              35.25023
            ],
            [
              -79.223021,
              35.268133
            ],
            [
              -79.183556,
              35.307161
            ],
            [
              -79.265476,
              35.345307
            ],
            [
              -79.289561,
              35.400439
            ],
            [
              -79.328888,
              35.423105
            ],
            [
              -79.329563,
              35.43979
            ],
            [
              -79.359765,
              35.469675
            ],
            [
              -79.335878,
              35.497973
            ],
            [
              -79.350073,
              35.518002
            ],
            [
              -79.555804,
              35.515039
            ],
            [
              -79.685683,
              35.512926
            ],
            [
              -79.724457,
              35.512269
            ],
            [
              -79.767957,
              35.511484
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "155",
      "COUNTYNS": "01026130",
      "AFFGEOID": "0500000US37155",
      "GEOID": "37155",
      "NAME": "Robeson",
      "LSAD": "06",
      "ALAND": 2453425443,
      "AWATER": 5097482,
      "County_state": "Robeson,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.4615628883712,
              34.6302980076855
            ],
            [
              -79.461318,
              34.630126
            ],
            [
              -79.459766,
              34.629027
            ],
            [
              -79.450034,
              34.621036
            ],
            [
              -79.358317,
              34.545358
            ],
            [
              -79.331328,
              34.521869
            ],
            [
              -79.324854,
              34.516282
            ],
            [
              -79.323249,
              34.514634
            ],
            [
              -79.306653,
              34.500426
            ],
            [
              -79.286703,
              34.482664
            ],
            [
              -79.249763,
              34.449774
            ],
            [
              -79.244886,
              34.445637
            ],
            [
              -79.2356025955262,
              34.4377625087099
            ],
            [
              -79.215993,
              34.421129
            ],
            [
              -79.21529988810791,
              34.4205410507552
            ],
            [
              -79.198982,
              34.406699
            ],
            [
              -79.192041,
              34.40104
            ],
            [
              -79.190739,
              34.399751
            ],
            [
              -79.151485,
              34.366753
            ],
            [
              -79.143242,
              34.359817
            ],
            [
              -79.10880981613849,
              34.330876919778895
            ],
            [
              -79.07125513793659,
              34.2993123985096
            ],
            [
              -79.0712438523822,
              34.299302913057005
            ],
            [
              -79.052881,
              34.314635
            ],
            [
              -79.045405,
              34.310711
            ],
            [
              -79.02519,
              34.351984
            ],
            [
              -79.010893,
              34.352156
            ],
            [
              -79.001637,
              34.363498
            ],
            [
              -79.011399,
              34.381063
            ],
            [
              -78.998444,
              34.391098
            ],
            [
              -78.968406,
              34.397137
            ],
            [
              -78.971187,
              34.42747
            ],
            [
              -78.960011,
              34.442684
            ],
            [
              -78.935355,
              34.455162
            ],
            [
              -78.917074,
              34.453279
            ],
            [
              -78.895838,
              34.462116
            ],
            [
              -78.868961,
              34.484778
            ],
            [
              -78.863414,
              34.498034
            ],
            [
              -78.846369,
              34.50017
            ],
            [
              -78.856646,
              34.524141
            ],
            [
              -78.86167,
              34.563579
            ],
            [
              -78.843617,
              34.578476
            ],
            [
              -78.831786,
              34.611921
            ],
            [
              -78.834434,
              34.621583
            ],
            [
              -78.809431,
              34.668276
            ],
            [
              -78.805834,
              34.68956
            ],
            [
              -78.82142,
              34.70933
            ],
            [
              -78.854417,
              34.736835
            ],
            [
              -78.883313,
              34.812021
            ],
            [
              -78.901998,
              34.835268
            ],
            [
              -78.90541,
              34.836837
            ],
            [
              -78.927018,
              34.863957
            ],
            [
              -78.926165,
              34.885595
            ],
            [
              -78.95486,
              34.913266
            ],
            [
              -79.038747,
              34.952715
            ],
            [
              -79.111708,
              34.895911
            ],
            [
              -79.184815,
              34.838858
            ],
            [
              -79.191391,
              34.833717
            ],
            [
              -79.216221,
              34.834499
            ],
            [
              -79.347823,
              34.838617
            ],
            [
              -79.349173,
              34.838478
            ],
            [
              -79.349972,
              34.835391
            ],
            [
              -79.35095,
              34.835068
            ],
            [
              -79.350971,
              34.833013
            ],
            [
              -79.351964,
              34.832638
            ],
            [
              -79.352019,
              34.830361
            ],
            [
              -79.353616,
              34.82875
            ],
            [
              -79.35306,
              34.827589
            ],
            [
              -79.351749,
              34.827902
            ],
            [
              -79.352445,
              34.825804
            ],
            [
              -79.354334,
              34.822631
            ],
            [
              -79.356167,
              34.82193
            ],
            [
              -79.357624,
              34.820306
            ],
            [
              -79.337078,
              34.76709
            ],
            [
              -79.4615628883712,
              34.6302980076855
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "171",
      "COUNTYNS": "01008587",
      "AFFGEOID": "0500000US37171",
      "GEOID": "37171",
      "NAME": "Surry",
      "LSAD": "06",
      "ALAND": 1382436644,
      "AWATER": 10286511,
      "County_state": "Surry,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.973643,
              36.453474
            ],
            [
              -80.947182,
              36.419074
            ],
            [
              -80.967153,
              36.40202
            ],
            [
              -80.942136,
              36.373604
            ],
            [
              -80.905784,
              36.361099
            ],
            [
              -80.892653,
              36.340647
            ],
            [
              -80.868746,
              36.326447
            ],
            [
              -80.869752,
              36.306629
            ],
            [
              -80.873205,
              36.236305
            ],
            [
              -80.849156,
              36.24088
            ],
            [
              -80.808357,
              36.254341
            ],
            [
              -80.79662,
              36.24808
            ],
            [
              -80.778112,
              36.25763
            ],
            [
              -80.778697,
              36.27177
            ],
            [
              -80.769291,
              36.275158
            ],
            [
              -80.751778,
              36.263379
            ],
            [
              -80.741811,
              36.277798
            ],
            [
              -80.733992,
              36.267528
            ],
            [
              -80.686016,
              36.268822
            ],
            [
              -80.669783,
              36.251035
            ],
            [
              -80.65329,
              36.252635
            ],
            [
              -80.642986,
              36.272649
            ],
            [
              -80.628505,
              36.273033
            ],
            [
              -80.624245,
              36.286822
            ],
            [
              -80.593253,
              36.269845
            ],
            [
              -80.57973,
              36.27772
            ],
            [
              -80.547885,
              36.285419
            ],
            [
              -80.53703,
              36.279957
            ],
            [
              -80.535893,
              36.25968
            ],
            [
              -80.50517,
              36.259916
            ],
            [
              -80.482504,
              36.256125
            ],
            [
              -80.466728,
              36.25982
            ],
            [
              -80.452322,
              36.241419
            ],
            [
              -80.451696,
              36.261502
            ],
            [
              -80.4401,
              36.55063
            ],
            [
              -80.61205809962209,
              36.557871889558896
            ],
            [
              -80.624788,
              36.558408
            ],
            [
              -80.653349,
              36.559221
            ],
            [
              -80.704831,
              36.562319
            ],
            [
              -80.730351,
              36.562349
            ],
            [
              -80.773663,
              36.560307
            ],
            [
              -80.80392,
              36.560813
            ],
            [
              -80.837089,
              36.559154
            ],
            [
              -80.837641,
              36.559118
            ],
            [
              -80.8379544979468,
              36.5591308803947
            ],
            [
              -80.9016647889236,
              36.561748485078205
            ],
            [
              -80.918593,
              36.540352
            ],
            [
              -80.92689,
              36.501258
            ],
            [
              -80.973643,
              36.453474
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "031",
      "COUNTYNS": "01026337",
      "AFFGEOID": "0500000US37031",
      "GEOID": "37031",
      "NAME": "Carteret",
      "LSAD": "06",
      "ALAND": 1314749435,
      "AWATER": 2131001182,
      "County_state": "Carteret,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.167769,
              34.791
            ],
            [
              -77.165986,
              34.785032
            ],
            [
              -77.139946,
              34.75974
            ],
            [
              -77.125646,
              34.756707
            ],
            [
              -77.122228,
              34.732993
            ],
            [
              -77.109153,
              34.717874
            ],
            [
              -77.105153,
              34.694712
            ],
            [
              -77.112901,
              34.67969
            ],
            [
              -77.096972,
              34.672401
            ],
            [
              -77.113355,
              34.649951
            ],
            [
              -77.1081661345391,
              34.6405897619796
            ],
            [
              -77.031105,
              34.661184
            ],
            [
              -76.990262,
              34.669623
            ],
            [
              -76.906257,
              34.68282
            ],
            [
              -76.817453,
              34.693722
            ],
            [
              -76.770044,
              34.696899
            ],
            [
              -76.726969,
              34.69669
            ],
            [
              -76.693751,
              34.692509
            ],
            [
              -76.676312,
              34.693151
            ],
            [
              -76.662645,
              34.685524
            ],
            [
              -76.642939,
              34.677618
            ],
            [
              -76.618719,
              34.67255
            ],
            [
              -76.579467,
              34.660174
            ],
            [
              -76.549343,
              34.645585
            ],
            [
              -76.550423,
              34.630789
            ],
            [
              -76.553806,
              34.628252
            ],
            [
              -76.555196,
              34.615993
            ],
            [
              -76.535946,
              34.588577
            ],
            [
              -76.524199,
              34.615416
            ],
            [
              -76.494068,
              34.66197
            ],
            [
              -76.450454,
              34.71445
            ],
            [
              -76.386804,
              34.784579
            ],
            [
              -76.31021,
              34.852309
            ],
            [
              -76.233088,
              34.905477
            ],
            [
              -76.137269,
              34.987858
            ],
            [
              -76.073,
              35.030509
            ],
            [
              -76.035933,
              35.058987
            ],
            [
              -76.038648,
              35.065045
            ],
            [
              -76.043621,
              35.070017
            ],
            [
              -76.064933,
              35.077121
            ],
            [
              -76.069906,
              35.075701
            ],
            [
              -76.093349,
              35.048705
            ],
            [
              -76.11182,
              35.034497
            ],
            [
              -76.160127,
              34.991163
            ],
            [
              -76.194936,
              34.962747
            ],
            [
              -76.233672,
              34.925926
            ],
            [
              -76.273986,
              34.897298
            ],
            [
              -76.322808,
              34.86116
            ],
            [
              -76.341279,
              34.842689
            ],
            [
              -76.362591,
              34.824219
            ],
            [
              -76.373247,
              34.817115
            ],
            [
              -76.383827,
              34.807906
            ],
            [
              -76.402559,
              34.791357
            ],
            [
              -76.4133,
              34.778994
            ],
            [
              -76.432317,
              34.765119
            ],
            [
              -76.439732,
              34.75848
            ],
            [
              -76.443776,
              34.746431
            ],
            [
              -76.523303,
              34.652271
            ],
            [
              -76.673537,
              34.70757
            ],
            [
              -76.673619,
              34.71491
            ],
            [
              -76.616567,
              34.714059
            ],
            [
              -76.620606,
              34.784389
            ],
            [
              -76.604796,
              34.787482
            ],
            [
              -76.582421,
              34.767757
            ],
            [
              -76.586236,
              34.698805
            ],
            [
              -76.524712,
              34.681964
            ],
            [
              -76.519521,
              34.707108
            ],
            [
              -76.512686,
              34.720333
            ],
            [
              -76.499848,
              34.729345
            ],
            [
              -76.498426,
              34.732054
            ],
            [
              -76.501925,
              34.742688
            ],
            [
              -76.485353,
              34.754905
            ],
            [
              -76.472529,
              34.770685
            ],
            [
              -76.463016,
              34.785076
            ],
            [
              -76.449464,
              34.814833
            ],
            [
              -76.436867,
              34.818736
            ],
            [
              -76.429376,
              34.830231
            ],
            [
              -76.424032,
              34.832588
            ],
            [
              -76.418565,
              34.826735
            ],
            [
              -76.415238,
              34.827711
            ],
            [
              -76.412148,
              34.832198
            ],
            [
              -76.411609,
              34.841268
            ],
            [
              -76.410009,
              34.861457
            ],
            [
              -76.398575,
              34.866527
            ],
            [
              -76.393134,
              34.857556
            ],
            [
              -76.386479,
              34.855411
            ],
            [
              -76.3822,
              34.856971
            ],
            [
              -76.379641,
              34.86258
            ],
            [
              -76.377154,
              34.867553
            ],
            [
              -76.368274,
              34.872881
            ],
            [
              -76.347673,
              34.872171
            ],
            [
              -76.33382,
              34.882116
            ],
            [
              -76.319967,
              34.897745
            ],
            [
              -76.311442,
              34.910177
            ],
            [
              -76.284092,
              34.936817
            ],
            [
              -76.277698,
              34.940014
            ],
            [
              -76.274856,
              34.953867
            ],
            [
              -76.275567,
              34.960971
            ],
            [
              -76.290691,
              34.969059
            ],
            [
              -76.296524,
              34.976245
            ],
            [
              -76.296188,
              34.98669
            ],
            [
              -76.293851,
              34.991948
            ],
            [
              -76.29093,
              34.994285
            ],
            [
              -76.288709,
              34.997201
            ],
            [
              -76.288354,
              35.005726
            ],
            [
              -76.293682,
              35.009633
            ],
            [
              -76.318546,
              35.020645
            ],
            [
              -76.35229,
              35.033077
            ],
            [
              -76.364367,
              35.034853
            ],
            [
              -76.364367,
              35.031301
            ],
            [
              -76.360815,
              35.025973
            ],
            [
              -76.350159,
              35.016737
            ],
            [
              -76.329557,
              34.986901
            ],
            [
              -76.326361,
              34.976245
            ],
            [
              -76.332044,
              34.970917
            ],
            [
              -76.395625,
              34.975179
            ],
            [
              -76.398466,
              34.9766
            ],
            [
              -76.406281,
              34.987256
            ],
            [
              -76.425461,
              35.001464
            ],
            [
              -76.431855,
              35.030945
            ],
            [
              -76.432565,
              35.049061
            ],
            [
              -76.435762,
              35.057941
            ],
            [
              -76.463468,
              35.076411
            ],
            [
              -76.468796,
              35.075345
            ],
            [
              -76.474521,
              35.070116
            ],
            [
              -76.480141,
              35.05213
            ],
            [
              -76.490258,
              35.034144
            ],
            [
              -76.491382,
              35.017283
            ],
            [
              -76.495879,
              35.011662
            ],
            [
              -76.502623,
              35.007166
            ],
            [
              -76.539719,
              35.000421
            ],
            [
              -76.566697,
              34.998173
            ],
            [
              -76.588055,
              34.991428
            ],
            [
              -76.635072,
              34.989116
            ],
            [
              -76.6837426596136,
              34.9629487494884
            ],
            [
              -76.682427,
              34.961899
            ],
            [
              -76.652571,
              34.937595
            ],
            [
              -76.662675,
              34.911853
            ],
            [
              -76.682974,
              34.899269
            ],
            [
              -76.693018,
              34.86918
            ],
            [
              -76.758008,
              34.846285
            ],
            [
              -76.883201,
              34.844893
            ],
            [
              -76.882195,
              34.836704
            ],
            [
              -76.954773,
              34.824508
            ],
            [
              -76.954438,
              34.809066
            ],
            [
              -77.078263,
              34.811322
            ],
            [
              -77.167769,
              34.791
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "005",
      "COUNTYNS": "01008533",
      "AFFGEOID": "0500000US37005",
      "GEOID": "37005",
      "NAME": "Alleghany",
      "LSAD": "06",
      "ALAND": 607101004,
      "AWATER": 3715040,
      "County_state": "Alleghany,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.35325920639549,
              36.5747234125838
            ],
            [
              -81.35315,
              36.546077
            ],
            [
              -81.326742,
              36.512552
            ],
            [
              -81.32259,
              36.49683
            ],
            [
              -81.29694,
              36.465241
            ],
            [
              -81.272081,
              36.446848
            ],
            [
              -81.263761,
              36.428929
            ],
            [
              -81.266578,
              36.415248
            ],
            [
              -81.244137,
              36.382102
            ],
            [
              -81.253649,
              36.366601
            ],
            [
              -81.243523,
              36.366057
            ],
            [
              -81.220168,
              36.377603
            ],
            [
              -81.189256,
              36.399351
            ],
            [
              -81.162956,
              36.432515
            ],
            [
              -81.138242,
              36.412479
            ],
            [
              -81.128398,
              36.425899
            ],
            [
              -81.095112,
              36.440773
            ],
            [
              -81.077794,
              36.417806
            ],
            [
              -81.044272,
              36.411063
            ],
            [
              -81.043789,
              36.393323
            ],
            [
              -81.026688,
              36.373195
            ],
            [
              -81.013613,
              36.37127
            ],
            [
              -80.990358,
              36.378352
            ],
            [
              -80.98131,
              36.376492
            ],
            [
              -80.967153,
              36.40202
            ],
            [
              -80.947182,
              36.419074
            ],
            [
              -80.973643,
              36.453474
            ],
            [
              -80.92689,
              36.501258
            ],
            [
              -80.918593,
              36.540352
            ],
            [
              -80.9016647889236,
              36.561748485078205
            ],
            [
              -80.901726,
              36.561751
            ],
            [
              -80.944338,
              36.563058
            ],
            [
              -80.945988,
              36.563196
            ],
            [
              -81.003802,
              36.563629
            ],
            [
              -81.011402,
              36.564429
            ],
            [
              -81.058844,
              36.566976
            ],
            [
              -81.061866,
              36.56702
            ],
            [
              -81.083206,
              36.567328
            ],
            [
              -81.124809,
              36.569227
            ],
            [
              -81.14181,
              36.569527
            ],
            [
              -81.171212,
              36.571026
            ],
            [
              -81.176712,
              36.571926
            ],
            [
              -81.249816,
              36.573225
            ],
            [
              -81.262303,
              36.573924
            ],
            [
              -81.307511,
              36.575024
            ],
            [
              -81.35325920639549,
              36.5747234125838
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "085",
      "COUNTYNS": "01008560",
      "AFFGEOID": "0500000US37085",
      "GEOID": "37085",
      "NAME": "Harnett",
      "LSAD": "06",
      "ALAND": 1541116844,
      "AWATER": 16361414,
      "County_state": "Harnett,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.223021,
              35.268133
            ],
            [
              -79.19375,
              35.25023
            ],
            [
              -79.165108,
              35.240925
            ],
            [
              -79.15208,
              35.214501
            ],
            [
              -79.095808,
              35.192068
            ],
            [
              -79.027039,
              35.207879
            ],
            [
              -78.998037,
              35.21413
            ],
            [
              -78.976351,
              35.212239
            ],
            [
              -78.941654,
              35.219248
            ],
            [
              -78.935845,
              35.218775
            ],
            [
              -78.898329,
              35.23099
            ],
            [
              -78.880408,
              35.23044
            ],
            [
              -78.876057,
              35.241536
            ],
            [
              -78.859265,
              35.242694
            ],
            [
              -78.847158,
              35.252986
            ],
            [
              -78.824915,
              35.259648
            ],
            [
              -78.804776,
              35.257167
            ],
            [
              -78.79428,
              35.266101
            ],
            [
              -78.781852,
              35.260897
            ],
            [
              -78.725564,
              35.2669
            ],
            [
              -78.718506,
              35.259689
            ],
            [
              -78.617127,
              35.245578
            ],
            [
              -78.584639,
              35.273312
            ],
            [
              -78.583451,
              35.296779
            ],
            [
              -78.538349,
              35.31641
            ],
            [
              -78.529851,
              35.334935
            ],
            [
              -78.552182,
              35.356684
            ],
            [
              -78.567695,
              35.367688
            ],
            [
              -78.573945,
              35.388149
            ],
            [
              -78.595094,
              35.401948
            ],
            [
              -78.598856,
              35.418394
            ],
            [
              -78.627536,
              35.458462
            ],
            [
              -78.650471,
              35.473255
            ],
            [
              -78.660555,
              35.496245
            ],
            [
              -78.687835,
              35.504208
            ],
            [
              -78.689517,
              35.515934
            ],
            [
              -78.708345,
              35.519296
            ],
            [
              -78.744302,
              35.529744
            ],
            [
              -78.779013,
              35.540659
            ],
            [
              -78.914734,
              35.583672
            ],
            [
              -78.96964,
              35.521669
            ],
            [
              -79.183556,
              35.307161
            ],
            [
              -79.223021,
              35.268133
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "093",
      "COUNTYNS": "01008563",
      "AFFGEOID": "0500000US37093",
      "GEOID": "37093",
      "NAME": "Hoke",
      "LSAD": "06",
      "ALAND": 1010380313,
      "AWATER": 3981006,
      "County_state": "Hoke,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.458751,
              35.043639
            ],
            [
              -79.457198,
              35.037558
            ],
            [
              -79.442695,
              35.02298
            ],
            [
              -79.428471,
              35.016079
            ],
            [
              -79.417752,
              35.004729
            ],
            [
              -79.398496,
              34.995225
            ],
            [
              -79.378489,
              34.975665
            ],
            [
              -79.353124,
              34.944242
            ],
            [
              -79.356088,
              34.932951
            ],
            [
              -79.350207,
              34.922367
            ],
            [
              -79.348854,
              34.887446
            ],
            [
              -79.337639,
              34.852702
            ],
            [
              -79.347823,
              34.838617
            ],
            [
              -79.216221,
              34.834499
            ],
            [
              -79.191391,
              34.833717
            ],
            [
              -79.184815,
              34.838858
            ],
            [
              -79.111708,
              34.895911
            ],
            [
              -79.038747,
              34.952715
            ],
            [
              -79.036917,
              34.956953
            ],
            [
              -79.037538,
              34.957742
            ],
            [
              -79.034775,
              34.963119
            ],
            [
              -79.056831,
              34.999824
            ],
            [
              -79.090291,
              35.042031
            ],
            [
              -79.089398,
              35.070442
            ],
            [
              -79.101162,
              35.114332
            ],
            [
              -79.112921,
              35.125651
            ],
            [
              -79.097602,
              35.174194
            ],
            [
              -79.124782,
              35.166573
            ],
            [
              -79.132952,
              35.177749
            ],
            [
              -79.142711,
              35.168984
            ],
            [
              -79.167641,
              35.180942
            ],
            [
              -79.175214,
              35.19306
            ],
            [
              -79.211347,
              35.193977
            ],
            [
              -79.229712,
              35.210602
            ],
            [
              -79.246383,
              35.212964
            ],
            [
              -79.255659,
              35.202926
            ],
            [
              -79.281284,
              35.195292
            ],
            [
              -79.31054,
              35.172964
            ],
            [
              -79.335477,
              35.161588
            ],
            [
              -79.458751,
              35.043639
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "143",
      "COUNTYNS": "01025841",
      "AFFGEOID": "0500000US37143",
      "GEOID": "37143",
      "NAME": "Perquimans",
      "LSAD": "06",
      "ALAND": 640099676,
      "AWATER": 211829497,
      "County_state": "Perquimans,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.573255,
              36.257171
            ],
            [
              -76.590107,
              36.238862
            ],
            [
              -76.57085,
              36.10788
            ],
            [
              -76.539064,
              36.094572
            ],
            [
              -76.517532,
              36.097401
            ],
            [
              -76.49123,
              36.084892
            ],
            [
              -76.480594,
              36.093159
            ],
            [
              -76.461046,
              36.087658
            ],
            [
              -76.445591,
              36.075903
            ],
            [
              -76.41185969354139,
              36.0753345758316
            ],
            [
              -76.410878,
              36.078034
            ],
            [
              -76.355069,
              36.086458
            ],
            [
              -76.337168,
              36.086458
            ],
            [
              -76.331902,
              36.083826
            ],
            [
              -76.323478,
              36.084879
            ],
            [
              -76.303998,
              36.092776
            ],
            [
              -76.298733,
              36.1012
            ],
            [
              -76.334965,
              36.110903
            ],
            [
              -76.346418,
              36.121023
            ],
            [
              -76.375892,
              36.12042
            ],
            [
              -76.456061,
              36.183577
            ],
            [
              -76.454414,
              36.189901
            ],
            [
              -76.447812,
              36.192514
            ],
            [
              -76.3935,
              36.163251
            ],
            [
              -76.373571,
              36.138208
            ],
            [
              -76.329921,
              36.133396
            ],
            [
              -76.265037,
              36.104886
            ],
            [
              -76.238712,
              36.098568
            ],
            [
              -76.216599,
              36.095409
            ],
            [
              -76.191715,
              36.107197
            ],
            [
              -76.228527,
              36.130647
            ],
            [
              -76.247401,
              36.161823
            ],
            [
              -76.263582,
              36.171817
            ],
            [
              -76.27699,
              36.184952
            ],
            [
              -76.274051,
              36.188949
            ],
            [
              -76.2733160918186,
              36.189061796641795
            ],
            [
              -76.288309,
              36.213821
            ],
            [
              -76.2864,
              36.216737
            ],
            [
              -76.303322,
              36.221714
            ],
            [
              -76.31723,
              36.24017
            ],
            [
              -76.337292,
              36.24079
            ],
            [
              -76.347449,
              36.249789
            ],
            [
              -76.37488,
              36.258654
            ],
            [
              -76.376639,
              36.275321
            ],
            [
              -76.391945,
              36.287485
            ],
            [
              -76.447632,
              36.363738
            ],
            [
              -76.453711,
              36.378092
            ],
            [
              -76.556881,
              36.355204
            ],
            [
              -76.559646,
              36.351056
            ],
            [
              -76.562029,
              36.321584
            ],
            [
              -76.568748,
              36.309269
            ],
            [
              -76.565631,
              36.292992
            ],
            [
              -76.573255,
              36.257171
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "077",
      "COUNTYNS": "01008556",
      "AFFGEOID": "0500000US37077",
      "GEOID": "37077",
      "NAME": "Granville",
      "LSAD": "06",
      "ALAND": 1377849157,
      "AWATER": 14503651,
      "County_state": "Granville,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.804901,
              36.091977
            ],
            [
              -78.807484,
              36.087295
            ],
            [
              -78.779141,
              36.071547
            ],
            [
              -78.751273,
              36.070834
            ],
            [
              -78.718788,
              36.075918
            ],
            [
              -78.683224,
              36.074484
            ],
            [
              -78.546414,
              36.021826
            ],
            [
              -78.544812,
              36.080846
            ],
            [
              -78.522557,
              36.124393
            ],
            [
              -78.496614,
              36.175199
            ],
            [
              -78.508332,
              36.182923
            ],
            [
              -78.510657,
              36.401714
            ],
            [
              -78.511224,
              36.454808
            ],
            [
              -78.497783,
              36.514477
            ],
            [
              -78.472907,
              36.521232
            ],
            [
              -78.4569669669848,
              36.5424740409633
            ],
            [
              -78.45697,
              36.542474
            ],
            [
              -78.470792,
              36.542316
            ],
            [
              -78.471022,
              36.542307
            ],
            [
              -78.509965,
              36.541065
            ],
            [
              -78.529722,
              36.540981
            ],
            [
              -78.533013,
              36.541004
            ],
            [
              -78.605304,
              36.541092
            ],
            [
              -78.663317,
              36.542011
            ],
            [
              -78.670051,
              36.542035
            ],
            [
              -78.7341219239254,
              36.5419022759072
            ],
            [
              -78.758392,
              36.541852
            ],
            [
              -78.76543,
              36.541727
            ],
            [
              -78.7962710014091,
              36.5417130131513
            ],
            [
              -78.794845,
              36.526439
            ],
            [
              -78.797482,
              36.432806
            ],
            [
              -78.802335,
              36.235794
            ],
            [
              -78.804901,
              36.091977
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "095",
      "COUNTYNS": "01008564",
      "AFFGEOID": "0500000US37095",
      "GEOID": "37095",
      "NAME": "Hyde",
      "LSAD": "06",
      "ALAND": 1585933592,
      "AWATER": 2192961833,
      "County_state": "Hyde,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.013561,
              35.068832
            ],
            [
              -76.014954,
              35.065349
            ],
            [
              -76.013145,
              35.061855
            ],
            [
              -76.00151,
              35.06723
            ],
            [
              -75.982812,
              35.081513
            ],
            [
              -75.963768,
              35.092395
            ],
            [
              -75.944725,
              35.105091
            ],
            [
              -75.912985,
              35.1196
            ],
            [
              -75.840438,
              35.15134
            ],
            [
              -75.789655,
              35.172197
            ],
            [
              -75.769705,
              35.180359
            ],
            [
              -75.757916,
              35.183079
            ],
            [
              -75.74940632077059,
              35.1852064931186
            ],
            [
              -75.7537651648491,
              35.1996115571648
            ],
            [
              -75.754289,
              35.199402
            ],
            [
              -75.785729,
              35.194244
            ],
            [
              -75.793283,
              35.18852
            ],
            [
              -75.801444,
              35.183079
            ],
            [
              -75.812902,
              35.178568
            ],
            [
              -75.819172,
              35.176826
            ],
            [
              -75.839531,
              35.172197
            ],
            [
              -75.893942,
              35.150433
            ],
            [
              -75.910265,
              35.142271
            ],
            [
              -75.923867,
              35.135017
            ],
            [
              -75.9547,
              35.1196
            ],
            [
              -75.966489,
              35.117787
            ],
            [
              -75.973499,
              35.121087
            ],
            [
              -75.98395,
              35.120042
            ],
            [
              -75.989175,
              35.115165
            ],
            [
              -75.990569,
              35.108546
            ],
            [
              -75.989175,
              35.100882
            ],
            [
              -75.99188,
              35.092395
            ],
            [
              -76.000949,
              35.084234
            ],
            [
              -76.013561,
              35.068832
            ]
          ]
        ],
        [
          [
            [
              -76.637513,
              35.705215
            ],
            [
              -76.601982,
              35.670055
            ],
            [
              -76.606119,
              35.646733
            ],
            [
              -76.60052,
              35.635654
            ],
            [
              -76.577569,
              35.611243
            ],
            [
              -76.551061,
              35.596632
            ],
            [
              -76.534313,
              35.595594
            ],
            [
              -76.539011,
              35.582937
            ],
            [
              -76.506019,
              35.581014
            ],
            [
              -76.46776327064201,
              35.555818362949495
            ],
            [
              -76.456427,
              35.550546
            ],
            [
              -76.476706,
              35.511707
            ],
            [
              -76.586349,
              35.508957
            ],
            [
              -76.540292,
              35.410657
            ],
            [
              -76.499251,
              35.381492
            ],
            [
              -76.485762,
              35.371375
            ],
            [
              -76.472273,
              35.371375
            ],
            [
              -76.462156,
              35.380368
            ],
            [
              -76.455411,
              35.383741
            ],
            [
              -76.448666,
              35.383741
            ],
            [
              -76.436301,
              35.37812
            ],
            [
              -76.431805,
              35.362383
            ],
            [
              -76.420564,
              35.35901
            ],
            [
              -76.409323,
              35.35339
            ],
            [
              -76.408199,
              35.350017
            ],
            [
              -76.399206,
              35.348893
            ],
            [
              -76.387965,
              35.356762
            ],
            [
              -76.382344,
              35.356762
            ],
            [
              -76.374475,
              35.355638
            ],
            [
              -76.365483,
              35.348893
            ],
            [
              -76.349745,
              35.345521
            ],
            [
              -76.340752,
              35.346645
            ],
            [
              -76.335132,
              35.355638
            ],
            [
              -76.327263,
              35.356762
            ],
            [
              -76.304781,
              35.355638
            ],
            [
              -76.282299,
              35.345521
            ],
            [
              -76.265437,
              35.343273
            ],
            [
              -76.257569,
              35.344397
            ],
            [
              -76.253072,
              35.350017
            ],
            [
              -76.235087,
              35.350017
            ],
            [
              -76.20586,
              35.336528
            ],
            [
              -76.182254,
              35.336528
            ],
            [
              -76.168764,
              35.332032
            ],
            [
              -76.165392,
              35.328659
            ],
            [
              -76.149655,
              35.326411
            ],
            [
              -76.14291,
              35.32866
            ],
            [
              -76.14291,
              35.338776
            ],
            [
              -76.132793,
              35.349455
            ],
            [
              -76.123238,
              35.351142
            ],
            [
              -76.092887,
              35.361259
            ],
            [
              -76.069281,
              35.370813
            ],
            [
              -76.059726,
              35.383741
            ],
            [
              -76.06085,
              35.392733
            ],
            [
              -76.063661,
              35.398354
            ],
            [
              -76.063661,
              35.405099
            ],
            [
              -76.059726,
              35.410157
            ],
            [
              -76.050171,
              35.415778
            ],
            [
              -76.037244,
              35.414091
            ],
            [
              -76.025441,
              35.408471
            ],
            [
              -76.020945,
              35.410719
            ],
            [
              -76.014762,
              35.416902
            ],
            [
              -76.01139,
              35.423084
            ],
            [
              -76.012514,
              35.432639
            ],
            [
              -76.009704,
              35.442194
            ],
            [
              -75.997901,
              35.453435
            ],
            [
              -75.994528,
              35.463552
            ],
            [
              -75.995652,
              35.475355
            ],
            [
              -75.987222,
              35.484348
            ],
            [
              -75.963053,
              35.493903
            ],
            [
              -75.961929,
              35.496713
            ],
            [
              -75.96474,
              35.504582
            ],
            [
              -75.964178,
              35.511326
            ],
            [
              -75.954623,
              35.526502
            ],
            [
              -75.950126,
              35.530998
            ],
            [
              -75.94563,
              35.53437
            ],
            [
              -75.916403,
              35.538305
            ],
            [
              -75.908534,
              35.546174
            ],
            [
              -75.908534,
              35.555166
            ],
            [
              -75.906848,
              35.559101
            ],
            [
              -75.895045,
              35.573152
            ],
            [
              -75.8698693905032,
              35.582742606809
            ],
            [
              -75.87982,
              35.606856
            ],
            [
              -75.876703,
              35.625177
            ],
            [
              -75.891953,
              35.643328
            ],
            [
              -75.890496,
              35.652259
            ],
            [
              -75.901342,
              35.668952
            ],
            [
              -76.01400112478679,
              35.6688648970815
            ],
            [
              -76.029863,
              35.649443
            ],
            [
              -76.04015,
              35.65131
            ],
            [
              -76.046361,
              35.659067
            ],
            [
              -76.04444539464829,
              35.6650776730122
            ],
            [
              -76.061095,
              35.675222
            ],
            [
              -76.092627,
              35.669764
            ],
            [
              -76.12067,
              35.683316
            ],
            [
              -76.118268,
              35.691908
            ],
            [
              -76.131898,
              35.692173
            ],
            [
              -76.144653,
              35.702488
            ],
            [
              -76.170101,
              35.698421
            ],
            [
              -76.175861,
              35.690698
            ],
            [
              -76.169902,
              35.673746
            ],
            [
              -76.181778,
              35.665513
            ],
            [
              -76.177848,
              35.655574
            ],
            [
              -76.192606,
              35.646359
            ],
            [
              -76.195848,
              35.620119
            ],
            [
              -76.215989,
              35.598131
            ],
            [
              -76.270279,
              35.608026
            ],
            [
              -76.297899,
              35.608895
            ],
            [
              -76.269247,
              35.690754
            ],
            [
              -76.405971,
              35.697579
            ],
            [
              -76.545621,
              35.705084
            ],
            [
              -76.637513,
              35.705215
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "189",
      "COUNTYNS": "01008594",
      "AFFGEOID": "0500000US37189",
      "GEOID": "37189",
      "NAME": "Watauga",
      "LSAD": "06",
      "ALAND": 809160511,
      "AWATER": 2332417,
      "County_state": "Watauga,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.908137,
              36.302013
            ],
            [
              -81.9181112586581,
              36.287113206280196
            ],
            [
              -81.895357,
              36.272116
            ],
            [
              -81.897019,
              36.242943
            ],
            [
              -81.907742,
              36.238638
            ],
            [
              -81.909008,
              36.216392
            ],
            [
              -81.885383,
              36.189451
            ],
            [
              -81.857147,
              36.193675
            ],
            [
              -81.82923,
              36.168791
            ],
            [
              -81.810516,
              36.111511
            ],
            [
              -81.675149,
              36.12253
            ],
            [
              -81.660632,
              36.118399
            ],
            [
              -81.545488,
              36.117475
            ],
            [
              -81.500402,
              36.142512
            ],
            [
              -81.455792,
              36.200441
            ],
            [
              -81.477516,
              36.24025
            ],
            [
              -81.486319,
              36.241256
            ],
            [
              -81.552237,
              36.276387
            ],
            [
              -81.566349,
              36.272202
            ],
            [
              -81.585951,
              36.298844
            ],
            [
              -81.638186,
              36.349606
            ],
            [
              -81.730491,
              36.390407
            ],
            [
              -81.729813,
              36.388033
            ],
            [
              -81.730737,
              36.382943
            ],
            [
              -81.732187,
              36.379894
            ],
            [
              -81.732865,
              36.376502
            ],
            [
              -81.731178,
              36.374062
            ],
            [
              -81.726082,
              36.368893
            ],
            [
              -81.724047,
              36.364293
            ],
            [
              -81.724047,
              36.360901
            ],
            [
              -81.723708,
              36.358527
            ],
            [
              -81.722691,
              36.354797
            ],
            [
              -81.721334,
              36.353101
            ],
            [
              -81.718282,
              36.350388
            ],
            [
              -81.713873,
              36.34937
            ],
            [
              -81.707785,
              36.346007
            ],
            [
              -81.705299,
              36.341852
            ],
            [
              -81.705966,
              36.338496
            ],
            [
              -81.707438,
              36.335171
            ],
            [
              -81.713194,
              36.334108
            ],
            [
              -81.717186,
              36.336169
            ],
            [
              -81.721015,
              36.338645
            ],
            [
              -81.725938,
              36.340364
            ],
            [
              -81.730976,
              36.341187
            ],
            [
              -81.7349,
              36.340891
            ],
            [
              -81.739498,
              36.339757
            ],
            [
              -81.744461,
              36.337778
            ],
            [
              -81.747842,
              36.337356
            ],
            [
              -81.75442,
              36.337044
            ],
            [
              -81.757962,
              36.3375
            ],
            [
              -81.760675,
              36.338178
            ],
            [
              -81.762371,
              36.338856
            ],
            [
              -81.766102,
              36.338517
            ],
            [
              -81.768977,
              36.341042
            ],
            [
              -81.777972,
              36.346318
            ],
            [
              -81.781318,
              36.347656
            ],
            [
              -81.784077,
              36.347674
            ],
            [
              -81.787468,
              36.348692
            ],
            [
              -81.790181,
              36.351744
            ],
            [
              -81.791877,
              36.354797
            ],
            [
              -81.795269,
              36.357849
            ],
            [
              -81.800812,
              36.358073
            ],
            [
              -81.808255,
              36.354121
            ],
            [
              -81.812904,
              36.351066
            ],
            [
              -81.822493,
              36.348819
            ],
            [
              -81.833202,
              36.347339
            ],
            [
              -81.841268,
              36.343321
            ],
            [
              -81.845638,
              36.34036
            ],
            [
              -81.850889,
              36.3375
            ],
            [
              -81.857333,
              36.334787
            ],
            [
              -81.863148,
              36.330209
            ],
            [
              -81.874336,
              36.31919
            ],
            [
              -81.876182,
              36.316075
            ],
            [
              -81.879382,
              36.313767
            ],
            [
              -81.887243,
              36.309193
            ],
            [
              -81.894569,
              36.307183
            ],
            [
              -81.897701,
              36.307446
            ],
            [
              -81.908137,
              36.302013
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "113",
      "COUNTYNS": "01025833",
      "AFFGEOID": "0500000US37113",
      "GEOID": "37113",
      "NAME": "Macon",
      "LSAD": "06",
      "ALAND": 1335355308,
      "AWATER": 10593473,
      "County_state": "Macon,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.731535,
              35.178579
            ],
            [
              -83.738129,
              35.15595
            ],
            [
              -83.726824,
              35.145269
            ],
            [
              -83.714442,
              35.155283
            ],
            [
              -83.699199,
              35.15263
            ],
            [
              -83.684356,
              35.157766
            ],
            [
              -83.666221,
              35.150041
            ],
            [
              -83.659824,
              35.160304
            ],
            [
              -83.641774,
              35.139233
            ],
            [
              -83.635712,
              35.12158
            ],
            [
              -83.61906,
              35.128382
            ],
            [
              -83.617909,
              35.10596
            ],
            [
              -83.586617,
              35.091582
            ],
            [
              -83.58512,
              35.081903
            ],
            [
              -83.562232,
              35.059424
            ],
            [
              -83.566728,
              35.051561
            ],
            [
              -83.557359,
              35.038636
            ],
            [
              -83.542994,
              35.038374
            ],
            [
              -83.52125,
              35.018246
            ],
            [
              -83.527042,
              35.009327
            ],
            [
              -83.519049,
              34.996604
            ],
            [
              -83.510597,
              35.005157
            ],
            [
              -83.4829524283476,
              34.9934833880904
            ],
            [
              -83.322768,
              34.995874
            ],
            [
              -83.19041,
              34.999456
            ],
            [
              -83.10861299848649,
              35.0006588970811
            ],
            [
              -83.108569,
              35.00956
            ],
            [
              -83.126,
              35.015451
            ],
            [
              -83.126215,
              35.022813
            ],
            [
              -83.14814,
              35.053625
            ],
            [
              -83.171212,
              35.060516
            ],
            [
              -83.171763,
              35.069335
            ],
            [
              -83.158258,
              35.077553
            ],
            [
              -83.143257,
              35.077927
            ],
            [
              -83.147714,
              35.093763
            ],
            [
              -83.177556,
              35.1089
            ],
            [
              -83.187077,
              35.133994
            ],
            [
              -83.223796,
              35.157386
            ],
            [
              -83.224483,
              35.187601
            ],
            [
              -83.218625,
              35.193312
            ],
            [
              -83.216612,
              35.222725
            ],
            [
              -83.224332,
              35.238645
            ],
            [
              -83.241312,
              35.238923
            ],
            [
              -83.245214,
              35.231466
            ],
            [
              -83.28242,
              35.229132
            ],
            [
              -83.296806,
              35.242555
            ],
            [
              -83.298476,
              35.255761
            ],
            [
              -83.31593,
              35.264796
            ],
            [
              -83.312036,
              35.281606
            ],
            [
              -83.322642,
              35.301451
            ],
            [
              -83.321087,
              35.311888
            ],
            [
              -83.331882,
              35.315953
            ],
            [
              -83.339609,
              35.330993
            ],
            [
              -83.364228,
              35.325993
            ],
            [
              -83.399564,
              35.32777
            ],
            [
              -83.410749,
              35.316557
            ],
            [
              -83.432454,
              35.325183
            ],
            [
              -83.452066,
              35.314204
            ],
            [
              -83.467181,
              35.297861
            ],
            [
              -83.490276,
              35.305547
            ],
            [
              -83.495347,
              35.299633
            ],
            [
              -83.672815,
              35.282796
            ],
            [
              -83.680114,
              35.2795
            ],
            [
              -83.684064,
              35.276355
            ],
            [
              -83.702772,
              35.24852
            ],
            [
              -83.691664,
              35.242762
            ],
            [
              -83.696783,
              35.225385
            ],
            [
              -83.728359,
              35.199892
            ],
            [
              -83.711338,
              35.195659
            ],
            [
              -83.731535,
              35.178579
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "043",
      "COUNTYNS": "01008546",
      "AFFGEOID": "0500000US37043",
      "GEOID": "37043",
      "NAME": "Clay",
      "LSAD": "06",
      "ALAND": 556804508,
      "AWATER": 15017754,
      "County_state": "Clay,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.004578,
              34.994451
            ],
            [
              -84.0052593484451,
              34.9874404516631
            ],
            [
              -83.936646,
              34.987485
            ],
            [
              -83.936427258766,
              34.9874845938068
            ],
            [
              -83.831097,
              34.987289
            ],
            [
              -83.749893,
              34.987691
            ],
            [
              -83.619985,
              34.986592
            ],
            [
              -83.620185,
              34.992091
            ],
            [
              -83.549381,
              34.992492
            ],
            [
              -83.54937747827239,
              34.992492052558696
            ],
            [
              -83.4829524283476,
              34.9934833880904
            ],
            [
              -83.510597,
              35.005157
            ],
            [
              -83.519049,
              34.996604
            ],
            [
              -83.527042,
              35.009327
            ],
            [
              -83.52125,
              35.018246
            ],
            [
              -83.542994,
              35.038374
            ],
            [
              -83.557359,
              35.038636
            ],
            [
              -83.566728,
              35.051561
            ],
            [
              -83.562232,
              35.059424
            ],
            [
              -83.58512,
              35.081903
            ],
            [
              -83.586617,
              35.091582
            ],
            [
              -83.617909,
              35.10596
            ],
            [
              -83.61906,
              35.128382
            ],
            [
              -83.635712,
              35.12158
            ],
            [
              -83.641774,
              35.139233
            ],
            [
              -83.659824,
              35.160304
            ],
            [
              -83.666221,
              35.150041
            ],
            [
              -83.684356,
              35.157766
            ],
            [
              -83.699199,
              35.15263
            ],
            [
              -83.714442,
              35.155283
            ],
            [
              -83.726824,
              35.145269
            ],
            [
              -83.738129,
              35.15595
            ],
            [
              -83.745961,
              35.160802
            ],
            [
              -83.772941,
              35.155858
            ],
            [
              -83.816561,
              35.139223
            ],
            [
              -83.841297,
              35.143744
            ],
            [
              -83.855493,
              35.137956
            ],
            [
              -83.863345,
              35.121434
            ],
            [
              -83.883102,
              35.106096
            ],
            [
              -83.907483,
              35.078483
            ],
            [
              -83.930654,
              35.069267
            ],
            [
              -83.943504,
              35.056067
            ],
            [
              -83.956378,
              35.054342
            ],
            [
              -83.966083,
              35.043974
            ],
            [
              -83.95645,
              35.026515
            ],
            [
              -83.979847,
              35.005867
            ],
            [
              -84.004578,
              34.994451
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "041",
      "COUNTYNS": "01025822",
      "AFFGEOID": "0500000US37041",
      "GEOID": "37041",
      "NAME": "Chowan",
      "LSAD": "06",
      "ALAND": 447179927,
      "AWATER": 157966162,
      "County_state": "Chowan,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.719401,
              36.199441
            ],
            [
              -76.721445,
              36.147838
            ],
            [
              -76.676484,
              36.043612
            ],
            [
              -76.653332,
              36.035124
            ],
            [
              -76.631745,
              36.038283
            ],
            [
              -76.615423,
              36.037757
            ],
            [
              -76.60384,
              36.033018
            ],
            [
              -76.589625,
              36.015644
            ],
            [
              -76.580674,
              36.00722
            ],
            [
              -76.575936,
              36.006167
            ],
            [
              -76.5633,
              36.009852
            ],
            [
              -76.547505,
              36.009852
            ],
            [
              -76.514335,
              36.00564
            ],
            [
              -76.491959,
              36.018013
            ],
            [
              -76.459316,
              36.024331
            ],
            [
              -76.458789,
              36.028016
            ],
            [
              -76.451418,
              36.039073
            ],
            [
              -76.442994,
              36.042758
            ],
            [
              -76.420881,
              36.06066
            ],
            [
              -76.412984,
              36.072243
            ],
            [
              -76.41185969354139,
              36.0753345758316
            ],
            [
              -76.445591,
              36.075903
            ],
            [
              -76.461046,
              36.087658
            ],
            [
              -76.480594,
              36.093159
            ],
            [
              -76.49123,
              36.084892
            ],
            [
              -76.517532,
              36.097401
            ],
            [
              -76.539064,
              36.094572
            ],
            [
              -76.57085,
              36.10788
            ],
            [
              -76.590107,
              36.238862
            ],
            [
              -76.573255,
              36.257171
            ],
            [
              -76.565631,
              36.292992
            ],
            [
              -76.568748,
              36.309269
            ],
            [
              -76.562029,
              36.321584
            ],
            [
              -76.559646,
              36.351056
            ],
            [
              -76.570756,
              36.334295
            ],
            [
              -76.613072,
              36.323966
            ],
            [
              -76.640402,
              36.328828
            ],
            [
              -76.650375,
              36.311569
            ],
            [
              -76.669975,
              36.315214
            ],
            [
              -76.675576,
              36.301262
            ],
            [
              -76.696571,
              36.296138
            ],
            [
              -76.694813,
              36.285023
            ],
            [
              -76.6916399439569,
              36.2773165965322
            ],
            [
              -76.675462,
              36.266882
            ],
            [
              -76.719401,
              36.199441
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "073",
      "COUNTYNS": "01026126",
      "AFFGEOID": "0500000US37073",
      "GEOID": "37073",
      "NAME": "Gates",
      "LSAD": "06",
      "ALAND": 882181715,
      "AWATER": 13292007,
      "County_state": "Gates,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.950606,
              36.47079
            ],
            [
              -76.945262,
              36.440203
            ],
            [
              -76.946258,
              36.413822
            ],
            [
              -76.93347,
              36.40187
            ],
            [
              -76.907887,
              36.390732
            ],
            [
              -76.896113,
              36.390444
            ],
            [
              -76.821442,
              36.364127
            ],
            [
              -76.795409,
              36.359717
            ],
            [
              -76.779467,
              36.362469
            ],
            [
              -76.761314,
              36.352412
            ],
            [
              -76.735329,
              36.324061
            ],
            [
              -76.696571,
              36.296138
            ],
            [
              -76.675576,
              36.301262
            ],
            [
              -76.669975,
              36.315214
            ],
            [
              -76.650375,
              36.311569
            ],
            [
              -76.640402,
              36.328828
            ],
            [
              -76.613072,
              36.323966
            ],
            [
              -76.570756,
              36.334295
            ],
            [
              -76.559646,
              36.351056
            ],
            [
              -76.556881,
              36.355204
            ],
            [
              -76.453711,
              36.378092
            ],
            [
              -76.467276,
              36.410685
            ],
            [
              -76.491405,
              36.468648
            ],
            [
              -76.491336,
              36.510677
            ],
            [
              -76.5415364676522,
              36.55031325872059
            ],
            [
              -76.541687,
              36.550312
            ],
            [
              -76.5579380488928,
              36.5505196504221
            ],
            [
              -76.575496,
              36.550744
            ],
            [
              -76.738329,
              36.550985
            ],
            [
              -76.749678,
              36.550381
            ],
            [
              -76.781296,
              36.550659
            ],
            [
              -76.807078,
              36.550606
            ],
            [
              -76.915897,
              36.552093
            ],
            [
              -76.916989,
              36.550742
            ],
            [
              -76.917318,
              36.546046
            ],
            [
              -76.916060288835,
              36.5438365877093
            ],
            [
              -76.914532,
              36.543738
            ],
            [
              -76.914002,
              36.542661
            ],
            [
              -76.916143,
              36.538943
            ],
            [
              -76.917149,
              36.538426
            ],
            [
              -76.921464,
              36.538449
            ],
            [
              -76.903964,
              36.521391
            ],
            [
              -76.906462,
              36.510692
            ],
            [
              -76.919357,
              36.505403
            ],
            [
              -76.933375,
              36.472623
            ],
            [
              -76.950606,
              36.47079
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "139",
      "COUNTYNS": "01026330",
      "AFFGEOID": "0500000US37139",
      "GEOID": "37139",
      "NAME": "Pasquotank",
      "LSAD": "06",
      "ALAND": 587616451,
      "AWATER": 161745166,
      "County_state": "Pasquotank,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.491336,
              36.510677
            ],
            [
              -76.491405,
              36.468648
            ],
            [
              -76.467276,
              36.410685
            ],
            [
              -76.453711,
              36.378092
            ],
            [
              -76.447632,
              36.363738
            ],
            [
              -76.391945,
              36.287485
            ],
            [
              -76.376639,
              36.275321
            ],
            [
              -76.37488,
              36.258654
            ],
            [
              -76.347449,
              36.249789
            ],
            [
              -76.337292,
              36.24079
            ],
            [
              -76.31723,
              36.24017
            ],
            [
              -76.303322,
              36.221714
            ],
            [
              -76.2864,
              36.216737
            ],
            [
              -76.288309,
              36.213821
            ],
            [
              -76.2733160918186,
              36.189061796641795
            ],
            [
              -76.268741,
              36.189764
            ],
            [
              -76.254064,
              36.18419
            ],
            [
              -76.247877,
              36.175148
            ],
            [
              -76.236932,
              36.170389
            ],
            [
              -76.228183,
              36.158487
            ],
            [
              -76.206873,
              36.137521
            ],
            [
              -76.178946,
              36.123424
            ],
            [
              -76.092555,
              36.135794
            ],
            [
              -76.071672,
              36.140183
            ],
            [
              -76.064224,
              36.143775
            ],
            [
              -76.05927,
              36.149285
            ],
            [
              -76.05992,
              36.15514
            ],
            [
              -76.06545,
              36.165224
            ],
            [
              -76.080106,
              36.19944
            ],
            [
              -76.115851,
              36.214219
            ],
            [
              -76.149486,
              36.263902
            ],
            [
              -76.171378,
              36.265806
            ],
            [
              -76.1816225314976,
              36.2749261677258
            ],
            [
              -76.18296677999729,
              36.2761228814833
            ],
            [
              -76.188717,
              36.281242
            ],
            [
              -76.18595055015649,
              36.292903120087296
            ],
            [
              -76.217453,
              36.299374
            ],
            [
              -76.202061,
              36.304709
            ],
            [
              -76.198369,
              36.317805
            ],
            [
              -76.181749,
              36.32271
            ],
            [
              -76.192207,
              36.32814
            ],
            [
              -76.213635,
              36.326859
            ],
            [
              -76.214178,
              36.341599
            ],
            [
              -76.225115,
              36.34643
            ],
            [
              -76.238244,
              36.371588
            ],
            [
              -76.254704,
              36.362827
            ],
            [
              -76.258891,
              36.384274
            ],
            [
              -76.291542,
              36.386629
            ],
            [
              -76.302236,
              36.400874
            ],
            [
              -76.323078,
              36.417389
            ],
            [
              -76.322716,
              36.427564
            ],
            [
              -76.345569,
              36.421402
            ],
            [
              -76.34741,
              36.428166
            ],
            [
              -76.367429,
              36.431222
            ],
            [
              -76.367354,
              36.439353
            ],
            [
              -76.394749,
              36.454549
            ],
            [
              -76.410655,
              36.456332
            ],
            [
              -76.437286,
              36.467846
            ],
            [
              -76.491336,
              36.510677
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "075",
      "COUNTYNS": "01008555",
      "AFFGEOID": "0500000US37075",
      "GEOID": "37075",
      "NAME": "Graham",
      "LSAD": "06",
      "ALAND": 756200800,
      "AWATER": 25045983,
      "County_state": "Graham,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.037494,
              35.34985
            ],
            [
              -84.038081,
              35.348363
            ],
            [
              -84.03243,
              35.336845
            ],
            [
              -84.031272,
              35.336438
            ],
            [
              -84.029377,
              35.333197
            ],
            [
              -84.030409,
              35.330483
            ],
            [
              -84.032209,
              35.328583
            ],
            [
              -84.03245,
              35.32653
            ],
            [
              -84.032479,
              35.325318
            ],
            [
              -84.03551,
              35.317783
            ],
            [
              -84.03571,
              35.312883
            ],
            [
              -84.03501,
              35.311983
            ],
            [
              -84.02871,
              35.310383
            ],
            [
              -84.02651,
              35.309283
            ],
            [
              -84.02141,
              35.301383
            ],
            [
              -84.02351,
              35.295783
            ],
            [
              -84.02791,
              35.292783
            ],
            [
              -84.0290849697824,
              35.292188335624196
            ],
            [
              -84.009757,
              35.273241
            ],
            [
              -84.010219,
              35.25594
            ],
            [
              -84.00092,
              35.249628
            ],
            [
              -84.001047,
              35.233288
            ],
            [
              -83.968532,
              35.218074
            ],
            [
              -83.946146,
              35.219826
            ],
            [
              -83.903572,
              35.235246
            ],
            [
              -83.8933,
              35.233235
            ],
            [
              -83.873738,
              35.244105
            ],
            [
              -83.831429,
              35.256117
            ],
            [
              -83.822766,
              35.250288
            ],
            [
              -83.795159,
              35.257463
            ],
            [
              -83.773547,
              35.245743
            ],
            [
              -83.734314,
              35.244989
            ],
            [
              -83.713144,
              35.257689
            ],
            [
              -83.702772,
              35.24852
            ],
            [
              -83.684064,
              35.276355
            ],
            [
              -83.680114,
              35.2795
            ],
            [
              -83.695611,
              35.299614
            ],
            [
              -83.684534,
              35.324191
            ],
            [
              -83.660507,
              35.335626
            ],
            [
              -83.621251,
              35.345123
            ],
            [
              -83.593092,
              35.376986
            ],
            [
              -83.582558,
              35.410489
            ],
            [
              -83.585756,
              35.434832
            ],
            [
              -83.610814,
              35.427045
            ],
            [
              -83.618694,
              35.436113
            ],
            [
              -83.643447,
              35.443196
            ],
            [
              -83.645014,
              35.432029
            ],
            [
              -83.65789,
              35.432261
            ],
            [
              -83.731873,
              35.447195
            ],
            [
              -83.757233,
              35.441703
            ],
            [
              -83.774475,
              35.443475
            ],
            [
              -83.803636,
              35.455093
            ],
            [
              -83.806711,
              35.444824
            ],
            [
              -83.844907,
              35.453121
            ],
            [
              -83.864579,
              35.448312
            ],
            [
              -83.873794,
              35.460413
            ],
            [
              -83.914077,
              35.465054
            ],
            [
              -83.927698,
              35.450647
            ],
            [
              -83.9443,
              35.450788
            ],
            [
              -83.952882,
              35.460635
            ],
            [
              -83.955416,
              35.463456
            ],
            [
              -83.957821,
              35.464211
            ],
            [
              -83.961053,
              35.464143
            ],
            [
              -83.9610558840119,
              35.463753658400094
            ],
            [
              -83.961056,
              35.463738
            ],
            [
              -83.961054,
              35.462838
            ],
            [
              -83.9614,
              35.459496
            ],
            [
              -83.965229,
              35.455399
            ],
            [
              -83.966656,
              35.454941
            ],
            [
              -83.969845,
              35.455443
            ],
            [
              -83.971439,
              35.455145
            ],
            [
              -83.973171,
              35.452582
            ],
            [
              -83.973057,
              35.448921
            ],
            [
              -83.978286,
              35.44782
            ],
            [
              -83.983233,
              35.44235
            ],
            [
              -83.992568,
              35.438065
            ],
            [
              -83.996619,
              35.433761
            ],
            [
              -83.998154,
              35.430786
            ],
            [
              -83.999242,
              35.42614
            ],
            [
              -83.999906,
              35.425201
            ],
            [
              -84.00225,
              35.422548
            ],
            [
              -84.005306,
              35.420883
            ],
            [
              -84.011706,
              35.415383
            ],
            [
              -84.014707,
              35.411983
            ],
            [
              -84.017607,
              35.411183
            ],
            [
              -84.020633,
              35.409843
            ],
            [
              -84.021782,
              35.407418
            ],
            [
              -84.021507,
              35.404183
            ],
            [
              -84.018807,
              35.399783
            ],
            [
              -84.018107,
              35.399083
            ],
            [
              -84.015207,
              35.398483
            ],
            [
              -84.014107,
              35.397783
            ],
            [
              -84.008207,
              35.390383
            ],
            [
              -84.008207,
              35.389683
            ],
            [
              -84.010607,
              35.386183
            ],
            [
              -84.011207,
              35.384383
            ],
            [
              -84.009807,
              35.382683
            ],
            [
              -84.008307,
              35.378883
            ],
            [
              -84.007586,
              35.371661
            ],
            [
              -84.015121,
              35.364868
            ],
            [
              -84.019193,
              35.359569
            ],
            [
              -84.020188,
              35.357503
            ],
            [
              -84.023456,
              35.354217
            ],
            [
              -84.024756,
              35.353896
            ],
            [
              -84.035343,
              35.350833
            ],
            [
              -84.037494,
              35.34985
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "039",
      "COUNTYNS": "01008545",
      "AFFGEOID": "0500000US37039",
      "GEOID": "37039",
      "NAME": "Cherokee",
      "LSAD": "06",
      "ALAND": 1179401584,
      "AWATER": 29257527,
      "County_state": "Cherokee,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.310022,
              35.078883
            ],
            [
              -84.321869,
              34.988408
            ],
            [
              -84.129555,
              34.987504
            ],
            [
              -84.1294520756424,
              34.9875038108939
            ],
            [
              -84.029954,
              34.987321
            ],
            [
              -84.021357,
              34.98743
            ],
            [
              -84.0052593484451,
              34.9874404516631
            ],
            [
              -84.004578,
              34.994451
            ],
            [
              -83.979847,
              35.005867
            ],
            [
              -83.95645,
              35.026515
            ],
            [
              -83.966083,
              35.043974
            ],
            [
              -83.956378,
              35.054342
            ],
            [
              -83.943504,
              35.056067
            ],
            [
              -83.930654,
              35.069267
            ],
            [
              -83.907483,
              35.078483
            ],
            [
              -83.883102,
              35.106096
            ],
            [
              -83.863345,
              35.121434
            ],
            [
              -83.855493,
              35.137956
            ],
            [
              -83.841297,
              35.143744
            ],
            [
              -83.816561,
              35.139223
            ],
            [
              -83.772941,
              35.155858
            ],
            [
              -83.745961,
              35.160802
            ],
            [
              -83.738129,
              35.15595
            ],
            [
              -83.731535,
              35.178579
            ],
            [
              -83.711338,
              35.195659
            ],
            [
              -83.728359,
              35.199892
            ],
            [
              -83.696783,
              35.225385
            ],
            [
              -83.691664,
              35.242762
            ],
            [
              -83.702772,
              35.24852
            ],
            [
              -83.713144,
              35.257689
            ],
            [
              -83.734314,
              35.244989
            ],
            [
              -83.773547,
              35.245743
            ],
            [
              -83.795159,
              35.257463
            ],
            [
              -83.822766,
              35.250288
            ],
            [
              -83.831429,
              35.256117
            ],
            [
              -83.873738,
              35.244105
            ],
            [
              -83.8933,
              35.233235
            ],
            [
              -83.903572,
              35.235246
            ],
            [
              -83.946146,
              35.219826
            ],
            [
              -83.968532,
              35.218074
            ],
            [
              -84.001047,
              35.233288
            ],
            [
              -84.00092,
              35.249628
            ],
            [
              -84.010219,
              35.25594
            ],
            [
              -84.009757,
              35.273241
            ],
            [
              -84.0290849697824,
              35.292188335624196
            ],
            [
              -84.036011,
              35.288683
            ],
            [
              -84.042711,
              35.278383
            ],
            [
              -84.052612,
              35.269982
            ],
            [
              -84.055712,
              35.268182
            ],
            [
              -84.063712,
              35.266682
            ],
            [
              -84.070612,
              35.263782
            ],
            [
              -84.074713,
              35.263182
            ],
            [
              -84.081117,
              35.261146
            ],
            [
              -84.082813,
              35.258882
            ],
            [
              -84.082913,
              35.257082
            ],
            [
              -84.092213,
              35.249981
            ],
            [
              -84.097508,
              35.247382
            ],
            [
              -84.10227,
              35.248115
            ],
            [
              -84.103135,
              35.248781
            ],
            [
              -84.108449,
              35.25033
            ],
            [
              -84.113314,
              35.248981
            ],
            [
              -84.114414,
              35.249081
            ],
            [
              -84.115048,
              35.249765
            ],
            [
              -84.115279,
              35.250438
            ],
            [
              -84.12115,
              35.250644
            ],
            [
              -84.124915,
              35.24983
            ],
            [
              -84.125615,
              35.249381
            ],
            [
              -84.126815,
              35.246481
            ],
            [
              -84.127315,
              35.244414
            ],
            [
              -84.12889,
              35.243679
            ],
            [
              -84.13057,
              35.243364
            ],
            [
              -84.133299,
              35.243679
            ],
            [
              -84.136415,
              35.24478
            ],
            [
              -84.139715,
              35.24618
            ],
            [
              -84.143124,
              35.246879
            ],
            [
              -84.155316,
              35.24648
            ],
            [
              -84.158916,
              35.24588
            ],
            [
              -84.160416,
              35.24388
            ],
            [
              -84.161316,
              35.24348
            ],
            [
              -84.168616,
              35.24578
            ],
            [
              -84.170416,
              35.245779
            ],
            [
              -84.177016,
              35.242379
            ],
            [
              -84.178516,
              35.240679
            ],
            [
              -84.188417,
              35.239979
            ],
            [
              -84.192217,
              35.243079
            ],
            [
              -84.199117,
              35.243679
            ],
            [
              -84.200117,
              35.244679
            ],
            [
              -84.201717,
              35.247779
            ],
            [
              -84.202879,
              35.255772
            ],
            [
              -84.205317,
              35.258279
            ],
            [
              -84.205517,
              35.259679
            ],
            [
              -84.211818,
              35.266078
            ],
            [
              -84.216318,
              35.267978
            ],
            [
              -84.223718,
              35.269078
            ],
            [
              -84.227818,
              35.267878
            ],
            [
              -84.231818,
              35.264778
            ],
            [
              -84.240219,
              35.255178
            ],
            [
              -84.243019,
              35.253178
            ],
            [
              -84.252819,
              35.249477
            ],
            [
              -84.257719,
              35.246177
            ],
            [
              -84.260319,
              35.241877
            ],
            [
              -84.27542,
              35.234777
            ],
            [
              -84.28252,
              35.227877
            ],
            [
              -84.28322,
              35.226577
            ],
            [
              -84.287982,
              35.224468
            ],
            [
              -84.289621,
              35.224677
            ],
            [
              -84.29024,
              35.225572
            ],
            [
              -84.292319677455,
              35.206689008403494
            ],
            [
              -84.292321,
              35.206677
            ],
            [
              -84.295238,
              35.182442
            ],
            [
              -84.298006,
              35.167468
            ],
            [
              -84.304809,
              35.121702
            ],
            [
              -84.308437,
              35.093173
            ],
            [
              -84.308576,
              35.092761
            ],
            [
              -84.310022,
              35.078883
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "019",
      "COUNTYNS": "01026340",
      "AFFGEOID": "0500000US37019",
      "GEOID": "37019",
      "NAME": "Brunswick",
      "LSAD": "06",
      "ALAND": 2199384961,
      "AWATER": 518918118,
      "County_state": "Brunswick,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.642306,
              33.958479
            ],
            [
              -78.6503363928608,
              33.9443152424589
            ],
            [
              -78.621369,
              33.920073
            ],
            [
              -78.615932,
              33.915523
            ],
            [
              -78.580378,
              33.884925
            ],
            [
              -78.541087,
              33.851112
            ],
            [
              -78.509042,
              33.865515
            ],
            [
              -78.383964,
              33.901946
            ],
            [
              -78.276147,
              33.912364
            ],
            [
              -78.17772,
              33.914272
            ],
            [
              -78.136952,
              33.912178
            ],
            [
              -78.095429,
              33.906031
            ],
            [
              -78.018689,
              33.888289
            ],
            [
              -78.009426,
              33.867823
            ],
            [
              -78.009973,
              33.861406
            ],
            [
              -78.006765,
              33.858704
            ],
            [
              -77.970606,
              33.844517
            ],
            [
              -77.960172,
              33.853315
            ],
            [
              -77.956881,
              33.87779
            ],
            [
              -77.946568,
              33.912261
            ],
            [
              -77.93715949974589,
              33.9289179114036
            ],
            [
              -77.948479,
              33.971274
            ],
            [
              -77.941779,
              33.981037
            ],
            [
              -77.932416,
              34.028489
            ],
            [
              -77.931103,
              34.058884
            ],
            [
              -77.936914,
              34.122822
            ],
            [
              -77.953502,
              34.153547
            ],
            [
              -77.958438,
              34.183124
            ],
            [
              -77.956608,
              34.192488
            ],
            [
              -77.959631,
              34.201567
            ],
            [
              -77.960941,
              34.206301
            ],
            [
              -77.95661,
              34.234432
            ],
            [
              -77.987512,
              34.268269
            ],
            [
              -78.017113,
              34.292769
            ],
            [
              -78.007217,
              34.321368
            ],
            [
              -78.027457,
              34.319656
            ],
            [
              -78.029922,
              34.331768
            ],
            [
              -78.078547,
              34.340233
            ],
            [
              -78.092806,
              34.352382
            ],
            [
              -78.137302,
              34.371711
            ],
            [
              -78.16235,
              34.357007
            ],
            [
              -78.254622,
              34.216305
            ],
            [
              -78.374295,
              34.204893
            ],
            [
              -78.372605,
              34.188588
            ],
            [
              -78.422734,
              34.139631
            ],
            [
              -78.48598,
              34.158819
            ],
            [
              -78.495672,
              34.167678
            ],
            [
              -78.515289,
              34.165865
            ],
            [
              -78.524602,
              34.154106
            ],
            [
              -78.541006,
              34.148294
            ],
            [
              -78.553328,
              34.10678
            ],
            [
              -78.54644,
              34.101831
            ],
            [
              -78.560189,
              34.092052
            ],
            [
              -78.555043,
              34.067071
            ],
            [
              -78.562781,
              34.054288
            ],
            [
              -78.58236,
              34.048538
            ],
            [
              -78.578926,
              34.038446
            ],
            [
              -78.594093,
              34.029214
            ],
            [
              -78.617019,
              34.023355
            ],
            [
              -78.640688,
              34.009854
            ],
            [
              -78.62678,
              33.98091
            ],
            [
              -78.630287,
              33.972293
            ],
            [
              -78.648351,
              33.96554
            ],
            [
              -78.642306,
              33.958479
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "083",
      "COUNTYNS": "01008559",
      "AFFGEOID": "0500000US37083",
      "GEOID": "37083",
      "NAME": "Halifax",
      "LSAD": "06",
      "ALAND": 1874459064,
      "AWATER": 16933522,
      "County_state": "Halifax,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.006551,
              36.202633
            ],
            [
              -77.98765,
              36.189532
            ],
            [
              -77.977663,
              36.175259
            ],
            [
              -77.968089,
              36.194504
            ],
            [
              -77.950735,
              36.180658
            ],
            [
              -77.944004,
              36.192592
            ],
            [
              -77.917958,
              36.181536
            ],
            [
              -77.923032,
              36.166897
            ],
            [
              -77.901139,
              36.162894
            ],
            [
              -77.8864,
              36.143994
            ],
            [
              -77.860523,
              36.148837
            ],
            [
              -77.821892,
              36.144777
            ],
            [
              -77.807517,
              36.138385
            ],
            [
              -77.799263,
              36.144476
            ],
            [
              -77.782119,
              36.141507
            ],
            [
              -77.757527,
              36.14878
            ],
            [
              -77.749009,
              36.15695
            ],
            [
              -77.729676,
              36.149014
            ],
            [
              -77.720742,
              36.133884
            ],
            [
              -77.721149,
              36.141923
            ],
            [
              -77.697956,
              36.15315
            ],
            [
              -77.677895,
              36.153808
            ],
            [
              -77.670295,
              36.137288
            ],
            [
              -77.644676,
              36.138187
            ],
            [
              -77.631628,
              36.126667
            ],
            [
              -77.630148,
              36.115565
            ],
            [
              -77.6056,
              36.11041
            ],
            [
              -77.593948,
              36.102052
            ],
            [
              -77.5407,
              36.096085
            ],
            [
              -77.528352,
              36.069389
            ],
            [
              -77.539983,
              36.060357
            ],
            [
              -77.529202,
              36.039267
            ],
            [
              -77.517646,
              36.036775
            ],
            [
              -77.475072,
              36.037818
            ],
            [
              -77.439301,
              36.021212
            ],
            [
              -77.441638,
              36.014683
            ],
            [
              -77.421138,
              36.003609
            ],
            [
              -77.402614,
              36.004966
            ],
            [
              -77.339809,
              36.066184
            ],
            [
              -77.327569,
              36.071361
            ],
            [
              -77.313225,
              36.089167
            ],
            [
              -77.230275,
              36.104999
            ],
            [
              -77.249485,
              36.113764
            ],
            [
              -77.252166,
              36.136003
            ],
            [
              -77.26455,
              36.139054
            ],
            [
              -77.264469,
              36.159331
            ],
            [
              -77.291519,
              36.168342
            ],
            [
              -77.301472,
              36.165152
            ],
            [
              -77.32837,
              36.191907
            ],
            [
              -77.350971,
              36.191532
            ],
            [
              -77.386595,
              36.213548
            ],
            [
              -77.377566,
              36.234582
            ],
            [
              -77.380559,
              36.249265
            ],
            [
              -77.409684,
              36.2638
            ],
            [
              -77.414418,
              36.293472
            ],
            [
              -77.441663,
              36.324353
            ],
            [
              -77.468785,
              36.321187
            ],
            [
              -77.495096,
              36.308381
            ],
            [
              -77.541108,
              36.303264
            ],
            [
              -77.571386,
              36.314086
            ],
            [
              -77.585698,
              36.33551
            ],
            [
              -77.579921,
              36.350076
            ],
            [
              -77.547012,
              36.373914
            ],
            [
              -77.544509,
              36.391961
            ],
            [
              -77.555275,
              36.417964
            ],
            [
              -77.589288,
              36.423488
            ],
            [
              -77.591217,
              36.430821
            ],
            [
              -77.609365,
              36.43661
            ],
            [
              -77.630743,
              36.4506
            ],
            [
              -77.644367,
              36.480228
            ],
            [
              -77.68114,
              36.479177
            ],
            [
              -77.709667,
              36.487076
            ],
            [
              -77.730717,
              36.486476
            ],
            [
              -77.742615,
              36.479875
            ],
            [
              -77.774026,
              36.481696
            ],
            [
              -77.789133,
              36.49121
            ],
            [
              -77.817453,
              36.500482
            ],
            [
              -77.858293,
              36.506648
            ],
            [
              -77.899524,
              36.504259
            ],
            [
              -77.909245,
              36.473937
            ],
            [
              -77.911795,
              36.442807
            ],
            [
              -77.919641,
              36.432652
            ],
            [
              -77.912064,
              36.424859
            ],
            [
              -77.911963,
              36.382446
            ],
            [
              -77.942406,
              36.298526
            ],
            [
              -78.006551,
              36.202633
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "099",
      "COUNTYNS": "01008566",
      "AFFGEOID": "0500000US37099",
      "GEOID": "37099",
      "NAME": "Jackson",
      "LSAD": "06",
      "ALAND": 1272156138,
      "AWATER": 9781460,
      "County_state": "Jackson,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.363732,
              35.426646
            ],
            [
              -83.360704,
              35.411813
            ],
            [
              -83.341143,
              35.397378
            ],
            [
              -83.331508,
              35.354262
            ],
            [
              -83.339609,
              35.330993
            ],
            [
              -83.331882,
              35.315953
            ],
            [
              -83.321087,
              35.311888
            ],
            [
              -83.322642,
              35.301451
            ],
            [
              -83.312036,
              35.281606
            ],
            [
              -83.31593,
              35.264796
            ],
            [
              -83.298476,
              35.255761
            ],
            [
              -83.296806,
              35.242555
            ],
            [
              -83.28242,
              35.229132
            ],
            [
              -83.245214,
              35.231466
            ],
            [
              -83.241312,
              35.238923
            ],
            [
              -83.224332,
              35.238645
            ],
            [
              -83.216612,
              35.222725
            ],
            [
              -83.218625,
              35.193312
            ],
            [
              -83.224483,
              35.187601
            ],
            [
              -83.223796,
              35.157386
            ],
            [
              -83.187077,
              35.133994
            ],
            [
              -83.177556,
              35.1089
            ],
            [
              -83.147714,
              35.093763
            ],
            [
              -83.143257,
              35.077927
            ],
            [
              -83.158258,
              35.077553
            ],
            [
              -83.171763,
              35.069335
            ],
            [
              -83.171212,
              35.060516
            ],
            [
              -83.14814,
              35.053625
            ],
            [
              -83.126215,
              35.022813
            ],
            [
              -83.126,
              35.015451
            ],
            [
              -83.108569,
              35.00956
            ],
            [
              -83.10861299848649,
              35.0006588970811
            ],
            [
              -83.108606,
              35.000659
            ],
            [
              -83.00866626840349,
              35.027579743047006
            ],
            [
              -83.032458,
              35.037734
            ],
            [
              -83.039187,
              35.048109
            ],
            [
              -83.056889,
              35.053827
            ],
            [
              -83.044942,
              35.077127
            ],
            [
              -83.021032,
              35.095635
            ],
            [
              -82.983216,
              35.13192
            ],
            [
              -82.980903,
              35.148351
            ],
            [
              -82.994809,
              35.153521
            ],
            [
              -82.996627,
              35.163578
            ],
            [
              -82.981211,
              35.16764
            ],
            [
              -82.986919,
              35.187638
            ],
            [
              -82.968805,
              35.194592
            ],
            [
              -82.959866,
              35.215168
            ],
            [
              -82.935913,
              35.22486
            ],
            [
              -82.927234,
              35.237303
            ],
            [
              -82.919241,
              35.271121
            ],
            [
              -82.920881,
              35.292037
            ],
            [
              -82.927321,
              35.302802
            ],
            [
              -82.964725,
              35.315289
            ],
            [
              -82.96469,
              35.33292
            ],
            [
              -82.990358,
              35.367616
            ],
            [
              -83.006568,
              35.377327
            ],
            [
              -83.025007,
              35.380316
            ],
            [
              -83.043724,
              35.394395
            ],
            [
              -83.044234,
              35.414692
            ],
            [
              -83.056281,
              35.425975
            ],
            [
              -83.080089,
              35.434749
            ],
            [
              -83.079473,
              35.447565
            ],
            [
              -83.090253,
              35.449041
            ],
            [
              -83.091435,
              35.457531
            ],
            [
              -83.109149,
              35.464116
            ],
            [
              -83.137714,
              35.464076
            ],
            [
              -83.137409,
              35.488818
            ],
            [
              -83.16139,
              35.496494
            ],
            [
              -83.163476,
              35.50895
            ],
            [
              -83.186322,
              35.514368
            ],
            [
              -83.210607,
              35.519325
            ],
            [
              -83.216248,
              35.526005
            ],
            [
              -83.236073,
              35.524564
            ],
            [
              -83.281518,
              35.499009
            ],
            [
              -83.285278,
              35.4889
            ],
            [
              -83.308931,
              35.479323
            ],
            [
              -83.318342,
              35.465262
            ],
            [
              -83.335629,
              35.474709
            ],
            [
              -83.351726,
              35.468092
            ],
            [
              -83.363732,
              35.426646
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "185",
      "COUNTYNS": "01008593",
      "AFFGEOID": "0500000US37185",
      "GEOID": "37185",
      "NAME": "Warren",
      "LSAD": "06",
      "ALAND": 1111325893,
      "AWATER": 39407120,
      "County_state": "Warren,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.323912,
              36.543809
            ],
            [
              -78.3239883198677,
              36.5438082240453
            ],
            [
              -78.277165,
              36.306132
            ],
            [
              -78.306965,
              36.266191
            ],
            [
              -78.22488,
              36.256553
            ],
            [
              -78.19428,
              36.246839
            ],
            [
              -78.172627,
              36.255326
            ],
            [
              -78.156983,
              36.244416
            ],
            [
              -78.132829,
              36.24633
            ],
            [
              -78.124641,
              36.225648
            ],
            [
              -78.103202,
              36.209062
            ],
            [
              -78.071267,
              36.21638
            ],
            [
              -78.045153,
              36.215387
            ],
            [
              -78.006551,
              36.202633
            ],
            [
              -77.942406,
              36.298526
            ],
            [
              -77.911963,
              36.382446
            ],
            [
              -77.912064,
              36.424859
            ],
            [
              -77.919641,
              36.432652
            ],
            [
              -77.911795,
              36.442807
            ],
            [
              -77.909245,
              36.473937
            ],
            [
              -77.899524,
              36.504259
            ],
            [
              -77.8997732358759,
              36.5446629921276
            ],
            [
              -78.038938,
              36.544173
            ],
            [
              -78.03942,
              36.544196
            ],
            [
              -78.0462112119251,
              36.5441680334942
            ],
            [
              -78.132911,
              36.543811
            ],
            [
              -78.133323,
              36.543847
            ],
            [
              -78.245462,
              36.544411
            ],
            [
              -78.246681,
              36.544341
            ],
            [
              -78.323912,
              36.543809
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "131",
      "COUNTYNS": "01008575",
      "AFFGEOID": "0500000US37131",
      "GEOID": "37131",
      "NAME": "Northampton",
      "LSAD": "06",
      "ALAND": 1389889811,
      "AWATER": 36034131,
      "County_state": "Northampton,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.899771,
              36.544663
            ],
            [
              -77.8997732358759,
              36.5446629921276
            ],
            [
              -77.899524,
              36.504259
            ],
            [
              -77.858293,
              36.506648
            ],
            [
              -77.817453,
              36.500482
            ],
            [
              -77.789133,
              36.49121
            ],
            [
              -77.774026,
              36.481696
            ],
            [
              -77.742615,
              36.479875
            ],
            [
              -77.730717,
              36.486476
            ],
            [
              -77.709667,
              36.487076
            ],
            [
              -77.68114,
              36.479177
            ],
            [
              -77.644367,
              36.480228
            ],
            [
              -77.630743,
              36.4506
            ],
            [
              -77.609365,
              36.43661
            ],
            [
              -77.591217,
              36.430821
            ],
            [
              -77.589288,
              36.423488
            ],
            [
              -77.555275,
              36.417964
            ],
            [
              -77.544509,
              36.391961
            ],
            [
              -77.547012,
              36.373914
            ],
            [
              -77.579921,
              36.350076
            ],
            [
              -77.585698,
              36.33551
            ],
            [
              -77.571386,
              36.314086
            ],
            [
              -77.541108,
              36.303264
            ],
            [
              -77.495096,
              36.308381
            ],
            [
              -77.468785,
              36.321187
            ],
            [
              -77.441663,
              36.324353
            ],
            [
              -77.414418,
              36.293472
            ],
            [
              -77.409684,
              36.2638
            ],
            [
              -77.380559,
              36.249265
            ],
            [
              -77.377566,
              36.234582
            ],
            [
              -77.386595,
              36.213548
            ],
            [
              -77.350971,
              36.191532
            ],
            [
              -77.32837,
              36.191907
            ],
            [
              -77.301472,
              36.165152
            ],
            [
              -77.291519,
              36.168342
            ],
            [
              -77.299717,
              36.181409
            ],
            [
              -77.29825,
              36.207708
            ],
            [
              -77.250772,
              36.218607
            ],
            [
              -77.228799,
              36.217387
            ],
            [
              -77.208468,
              36.246578
            ],
            [
              -77.133038,
              36.433637
            ],
            [
              -77.137228,
              36.455383
            ],
            [
              -77.122959,
              36.471218
            ],
            [
              -77.09993,
              36.479095
            ],
            [
              -77.091502,
              36.471751
            ],
            [
              -77.070434,
              36.479908
            ],
            [
              -77.066463,
              36.496511
            ],
            [
              -77.085432,
              36.499961
            ],
            [
              -77.082474,
              36.511469
            ],
            [
              -77.093224,
              36.518216
            ],
            [
              -77.14787,
              36.524985
            ],
            [
              -77.148685,
              36.534142
            ],
            [
              -77.164370064468,
              36.54630522103329
            ],
            [
              -77.1645,
              36.54633
            ],
            [
              -77.16966,
              36.547315
            ],
            [
              -77.190175,
              36.546164
            ],
            [
              -77.205156,
              36.544581
            ],
            [
              -77.2968751997552,
              36.5447391568792
            ],
            [
              -77.749706,
              36.54552
            ],
            [
              -77.76712325037539,
              36.545413754966894
            ],
            [
              -77.86804674818559,
              36.5447981227554
            ],
            [
              -77.87528,
              36.544754
            ],
            [
              -77.882357,
              36.544737
            ],
            [
              -77.899771,
              36.544663
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "009",
      "COUNTYNS": "01008535",
      "AFFGEOID": "0500000US37009",
      "GEOID": "37009",
      "NAME": "Ashe",
      "LSAD": "06",
      "ALAND": 1100901784,
      "AWATER": 8093124,
      "County_state": "Ashe,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.739648,
              36.406686
            ],
            [
              -81.739648,
              36.401599
            ],
            [
              -81.737952,
              36.39719
            ],
            [
              -81.733877,
              36.39457
            ],
            [
              -81.731509,
              36.392103
            ],
            [
              -81.730491,
              36.390407
            ],
            [
              -81.638186,
              36.349606
            ],
            [
              -81.585951,
              36.298844
            ],
            [
              -81.566349,
              36.272202
            ],
            [
              -81.552237,
              36.276387
            ],
            [
              -81.486319,
              36.241256
            ],
            [
              -81.477516,
              36.24025
            ],
            [
              -81.464606,
              36.256885
            ],
            [
              -81.442731,
              36.259847
            ],
            [
              -81.413125,
              36.281457
            ],
            [
              -81.415853,
              36.29223
            ],
            [
              -81.388309,
              36.284682
            ],
            [
              -81.366725,
              36.284447
            ],
            [
              -81.363029,
              36.31325
            ],
            [
              -81.366601,
              36.359101
            ],
            [
              -81.359255,
              36.366433
            ],
            [
              -81.321935,
              36.373233
            ],
            [
              -81.306485,
              36.364426
            ],
            [
              -81.291219,
              36.36443
            ],
            [
              -81.282005,
              36.354592
            ],
            [
              -81.259327,
              36.356312
            ],
            [
              -81.253649,
              36.366601
            ],
            [
              -81.244137,
              36.382102
            ],
            [
              -81.266578,
              36.415248
            ],
            [
              -81.263761,
              36.428929
            ],
            [
              -81.272081,
              36.446848
            ],
            [
              -81.29694,
              36.465241
            ],
            [
              -81.32259,
              36.49683
            ],
            [
              -81.326742,
              36.512552
            ],
            [
              -81.35315,
              36.546077
            ],
            [
              -81.35325920639549,
              36.5747234125838
            ],
            [
              -81.353322,
              36.574723
            ],
            [
              -81.374824,
              36.574673
            ],
            [
              -81.442228,
              36.576822
            ],
            [
              -81.47643,
              36.580421
            ],
            [
              -81.489387,
              36.579026
            ],
            [
              -81.499831,
              36.57982
            ],
            [
              -81.521032,
              36.58052
            ],
            [
              -81.600934,
              36.587019
            ],
            [
              -81.677535,
              36.588117
            ],
            [
              -81.680137,
              36.585518
            ],
            [
              -81.679036,
              36.578918
            ],
            [
              -81.677236,
              36.574406
            ],
            [
              -81.677036,
              36.570718
            ],
            [
              -81.679936,
              36.568618
            ],
            [
              -81.686436,
              36.567918
            ],
            [
              -81.690236,
              36.568718
            ],
            [
              -81.692506,
              36.565748
            ],
            [
              -81.692167,
              36.562695
            ],
            [
              -81.690132,
              36.559643
            ],
            [
              -81.689115,
              36.555912
            ],
            [
              -81.69003,
              36.552154
            ],
            [
              -81.693844,
              36.549083
            ],
            [
              -81.697539,
              36.544359
            ],
            [
              -81.699962,
              36.539714
            ],
            [
              -81.699962,
              36.536829
            ],
            [
              -81.707963,
              36.536209
            ],
            [
              -81.708262,
              36.532113
            ],
            [
              -81.707573,
              36.526101
            ],
            [
              -81.702543,
              36.520317
            ],
            [
              -81.700553,
              36.51519
            ],
            [
              -81.700093,
              36.514158
            ],
            [
              -81.699601,
              36.512883
            ],
            [
              -81.699446,
              36.511504
            ],
            [
              -81.697744,
              36.508448
            ],
            [
              -81.697829,
              36.507544
            ],
            [
              -81.69729,
              36.504887
            ],
            [
              -81.69797,
              36.504063
            ],
            [
              -81.699923,
              36.500865
            ],
            [
              -81.700238,
              36.500475
            ],
            [
              -81.699928,
              36.498018
            ],
            [
              -81.698265,
              36.497221
            ],
            [
              -81.697261,
              36.496141
            ],
            [
              -81.696835,
              36.493393
            ],
            [
              -81.695907,
              36.49158
            ],
            [
              -81.697287,
              36.484738
            ],
            [
              -81.696281,
              36.475499
            ],
            [
              -81.694829,
              36.474463
            ],
            [
              -81.694533,
              36.473283
            ],
            [
              -81.695311,
              36.467912
            ],
            [
              -81.697975,
              36.464741
            ],
            [
              -81.699223,
              36.463959
            ],
            [
              -81.701076,
              36.464212
            ],
            [
              -81.708247,
              36.462217
            ],
            [
              -81.71489,
              36.45722
            ],
            [
              -81.715082,
              36.453365
            ],
            [
              -81.714277,
              36.450978
            ],
            [
              -81.714212,
              36.447045
            ],
            [
              -81.715229,
              36.444332
            ],
            [
              -81.715569,
              36.44128
            ],
            [
              -81.715229,
              36.438567
            ],
            [
              -81.715229,
              36.436532
            ],
            [
              -81.716925,
              36.43314
            ],
            [
              -81.717939,
              36.428762
            ],
            [
              -81.720734,
              36.422537
            ],
            [
              -81.729924,
              36.415422
            ],
            [
              -81.734312,
              36.413342
            ],
            [
              -81.738292,
              36.410756
            ],
            [
              -81.739648,
              36.406686
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "021",
      "COUNTYNS": "01008538",
      "AFFGEOID": "0500000US37021",
      "GEOID": "37021",
      "NAME": "Buncombe",
      "LSAD": "06",
      "ALAND": 1700042284,
      "AWATER": 8942802,
      "County_state": "Buncombe,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.883909,
              35.678235
            ],
            [
              -82.864383,
              35.634651
            ],
            [
              -82.853561,
              35.63682
            ],
            [
              -82.84609,
              35.614309
            ],
            [
              -82.813915,
              35.627918
            ],
            [
              -82.797124,
              35.596146
            ],
            [
              -82.784267,
              35.591377
            ],
            [
              -82.765893,
              35.567495
            ],
            [
              -82.777524,
              35.555619
            ],
            [
              -82.765871,
              35.540791
            ],
            [
              -82.780211,
              35.525711
            ],
            [
              -82.778063,
              35.519652
            ],
            [
              -82.799493,
              35.471227
            ],
            [
              -82.781691,
              35.439542
            ],
            [
              -82.745139,
              35.422967
            ],
            [
              -82.715768,
              35.447808
            ],
            [
              -82.673343,
              35.459453
            ],
            [
              -82.653038,
              35.455031
            ],
            [
              -82.621749,
              35.435693
            ],
            [
              -82.608068,
              35.443206
            ],
            [
              -82.570608,
              35.431045
            ],
            [
              -82.548825,
              35.444305
            ],
            [
              -82.54695,
              35.425159
            ],
            [
              -82.532713,
              35.416615
            ],
            [
              -82.5351,
              35.444504
            ],
            [
              -82.495208,
              35.445186
            ],
            [
              -82.456127,
              35.44965
            ],
            [
              -82.448278,
              35.457933
            ],
            [
              -82.417511,
              35.474501
            ],
            [
              -82.363133,
              35.46096
            ],
            [
              -82.367509,
              35.465279
            ],
            [
              -82.367304,
              35.465781
            ],
            [
              -82.367248,
              35.465877
            ],
            [
              -82.367218,
              35.466532
            ],
            [
              -82.367306,
              35.466739
            ],
            [
              -82.367521,
              35.466777
            ],
            [
              -82.367761,
              35.466709
            ],
            [
              -82.369323,
              35.485518
            ],
            [
              -82.358932,
              35.489172
            ],
            [
              -82.332822,
              35.50022
            ],
            [
              -82.265795,
              35.467818
            ],
            [
              -82.251437,
              35.460685
            ],
            [
              -82.224336,
              35.498269
            ],
            [
              -82.233203,
              35.519402
            ],
            [
              -82.169049,
              35.527811
            ],
            [
              -82.219981,
              35.553141
            ],
            [
              -82.251251,
              35.559943
            ],
            [
              -82.291026,
              35.591322
            ],
            [
              -82.280432,
              35.608759
            ],
            [
              -82.265368,
              35.613503
            ],
            [
              -82.286707,
              35.653068
            ],
            [
              -82.280547,
              35.66724
            ],
            [
              -82.295007,
              35.684317
            ],
            [
              -82.275399,
              35.704438
            ],
            [
              -82.284733,
              35.725526
            ],
            [
              -82.313065,
              35.733603
            ],
            [
              -82.335054,
              35.749396
            ],
            [
              -82.346191,
              35.786179
            ],
            [
              -82.338299,
              35.796149
            ],
            [
              -82.361956,
              35.821245
            ],
            [
              -82.408602,
              35.818178
            ],
            [
              -82.435335,
              35.80859
            ],
            [
              -82.463828,
              35.809616
            ],
            [
              -82.475845,
              35.798308
            ],
            [
              -82.513161,
              35.80338
            ],
            [
              -82.665178,
              35.738742
            ],
            [
              -82.67703,
              35.734854
            ],
            [
              -82.669109,
              35.726459
            ],
            [
              -82.692756,
              35.734168
            ],
            [
              -82.711862,
              35.733038
            ],
            [
              -82.713764,
              35.717824
            ],
            [
              -82.730975,
              35.722403
            ],
            [
              -82.74998,
              35.706458
            ],
            [
              -82.794782,
              35.695805
            ],
            [
              -82.807056,
              35.685874
            ],
            [
              -82.849216,
              35.701495
            ],
            [
              -82.883909,
              35.678235
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "023",
      "COUNTYNS": "01008539",
      "AFFGEOID": "0500000US37023",
      "GEOID": "37023",
      "NAME": "Burke",
      "LSAD": "06",
      "ALAND": 1311170317,
      "AWATER": 20699390,
      "County_state": "Burke,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.972775,
              35.821311
            ],
            [
              -81.986939,
              35.806923
            ],
            [
              -81.869388,
              35.719624
            ],
            [
              -81.824122,
              35.574983
            ],
            [
              -81.822268,
              35.580434
            ],
            [
              -81.796693,
              35.583786
            ],
            [
              -81.772182,
              35.592623
            ],
            [
              -81.753407,
              35.613588
            ],
            [
              -81.732971,
              35.601778
            ],
            [
              -81.721701,
              35.606308
            ],
            [
              -81.705653,
              35.581214
            ],
            [
              -81.691989,
              35.580041
            ],
            [
              -81.690949,
              35.584546
            ],
            [
              -81.674224,
              35.568804
            ],
            [
              -81.648967,
              35.56161
            ],
            [
              -81.607193,
              35.566831
            ],
            [
              -81.56678,
              35.558448
            ],
            [
              -81.537599,
              35.564228
            ],
            [
              -81.535403,
              35.568137
            ],
            [
              -81.506809,
              35.607956
            ],
            [
              -81.476143,
              35.63678
            ],
            [
              -81.432898,
              35.677847
            ],
            [
              -81.363796,
              35.767802
            ],
            [
              -81.377487,
              35.757498
            ],
            [
              -81.399218,
              35.75856
            ],
            [
              -81.426452,
              35.772004
            ],
            [
              -81.440562,
              35.773409
            ],
            [
              -81.444197,
              35.784301
            ],
            [
              -81.555994,
              35.777538
            ],
            [
              -81.600809,
              35.825096
            ],
            [
              -81.610385,
              35.819612
            ],
            [
              -81.630065,
              35.830377
            ],
            [
              -81.639217,
              35.848346
            ],
            [
              -81.709749,
              35.875003
            ],
            [
              -81.744587,
              35.908683
            ],
            [
              -81.758733,
              35.928466
            ],
            [
              -81.776077,
              35.927014
            ],
            [
              -81.777668,
              35.947439
            ],
            [
              -81.796171,
              35.948788
            ],
            [
              -81.807162,
              35.961955
            ],
            [
              -81.844536,
              35.974976
            ],
            [
              -81.870232,
              35.976365
            ],
            [
              -81.881386,
              35.992932
            ],
            [
              -81.899871,
              35.999909
            ],
            [
              -81.943188,
              35.96005
            ],
            [
              -81.925633,
              35.937016
            ],
            [
              -81.928471,
              35.931531
            ],
            [
              -81.910304,
              35.905554
            ],
            [
              -81.906652,
              35.883376
            ],
            [
              -81.924985,
              35.84307
            ],
            [
              -81.939707,
              35.837712
            ],
            [
              -81.957877,
              35.819315
            ],
            [
              -81.972775,
              35.821311
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "111",
      "COUNTYNS": "01025836",
      "AFFGEOID": "0500000US37111",
      "GEOID": "37111",
      "NAME": "McDowell",
      "LSAD": "06",
      "ALAND": 1139478112,
      "AWATER": 13977177,
      "County_state": "McDowell,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.295007,
              35.684317
            ],
            [
              -82.280547,
              35.66724
            ],
            [
              -82.286707,
              35.653068
            ],
            [
              -82.265368,
              35.613503
            ],
            [
              -82.280432,
              35.608759
            ],
            [
              -82.291026,
              35.591322
            ],
            [
              -82.251251,
              35.559943
            ],
            [
              -82.219981,
              35.553141
            ],
            [
              -82.169049,
              35.527811
            ],
            [
              -82.150538,
              35.518002
            ],
            [
              -82.095766,
              35.531666
            ],
            [
              -82.039843,
              35.532704
            ],
            [
              -82.0245,
              35.537361
            ],
            [
              -82.002783,
              35.551847
            ],
            [
              -81.967619,
              35.526007
            ],
            [
              -81.933121,
              35.536074
            ],
            [
              -81.920899,
              35.533661
            ],
            [
              -81.904954,
              35.541917
            ],
            [
              -81.842652,
              35.542533
            ],
            [
              -81.824122,
              35.574983
            ],
            [
              -81.869388,
              35.719624
            ],
            [
              -81.986939,
              35.806923
            ],
            [
              -81.972775,
              35.821311
            ],
            [
              -81.957877,
              35.819315
            ],
            [
              -81.939707,
              35.837712
            ],
            [
              -81.924985,
              35.84307
            ],
            [
              -81.906652,
              35.883376
            ],
            [
              -81.910304,
              35.905554
            ],
            [
              -81.928471,
              35.931531
            ],
            [
              -81.925633,
              35.937016
            ],
            [
              -81.943188,
              35.96005
            ],
            [
              -81.962987,
              35.941783
            ],
            [
              -81.954602,
              35.926649
            ],
            [
              -81.981954,
              35.911361
            ],
            [
              -81.980168,
              35.885046
            ],
            [
              -81.999409,
              35.874007
            ],
            [
              -82.013607,
              35.885218
            ],
            [
              -82.048039,
              35.853229
            ],
            [
              -82.073713,
              35.848305
            ],
            [
              -82.099405,
              35.851671
            ],
            [
              -82.120579,
              35.825012
            ],
            [
              -82.133484,
              35.823309
            ],
            [
              -82.167895,
              35.789138
            ],
            [
              -82.158307,
              35.779738
            ],
            [
              -82.193402,
              35.735314
            ],
            [
              -82.242765,
              35.712448
            ],
            [
              -82.257842,
              35.698852
            ],
            [
              -82.275399,
              35.704438
            ],
            [
              -82.295007,
              35.684317
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "047",
      "COUNTYNS": "01026339",
      "AFFGEOID": "0500000US37047",
      "GEOID": "37047",
      "NAME": "Columbus",
      "LSAD": "06",
      "ALAND": 2429714643,
      "AWATER": 43719396,
      "County_state": "Columbus,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.0712438523822,
              34.299302913057005
            ],
            [
              -79.071169,
              34.29924
            ],
            [
              -78.99576,
              34.235954
            ],
            [
              -78.963692,
              34.209041
            ],
            [
              -78.909881,
              34.163881
            ],
            [
              -78.8896910841351,
              34.146936733500496
            ],
            [
              -78.874747,
              34.134395
            ],
            [
              -78.855385,
              34.117996
            ],
            [
              -78.81171,
              34.081006
            ],
            [
              -78.7942804437823,
              34.0662441024328
            ],
            [
              -78.769483,
              34.045242
            ],
            [
              -78.712206,
              33.996732
            ],
            [
              -78.710141,
              33.994688
            ],
            [
              -78.702771,
              33.989268
            ],
            [
              -78.66253,
              33.95452
            ],
            [
              -78.651629,
              33.945397
            ],
            [
              -78.6503363928608,
              33.9443152424589
            ],
            [
              -78.642306,
              33.958479
            ],
            [
              -78.648351,
              33.96554
            ],
            [
              -78.630287,
              33.972293
            ],
            [
              -78.62678,
              33.98091
            ],
            [
              -78.640688,
              34.009854
            ],
            [
              -78.617019,
              34.023355
            ],
            [
              -78.594093,
              34.029214
            ],
            [
              -78.578926,
              34.038446
            ],
            [
              -78.58236,
              34.048538
            ],
            [
              -78.562781,
              34.054288
            ],
            [
              -78.555043,
              34.067071
            ],
            [
              -78.560189,
              34.092052
            ],
            [
              -78.54644,
              34.101831
            ],
            [
              -78.553328,
              34.10678
            ],
            [
              -78.541006,
              34.148294
            ],
            [
              -78.524602,
              34.154106
            ],
            [
              -78.515289,
              34.165865
            ],
            [
              -78.495672,
              34.167678
            ],
            [
              -78.48598,
              34.158819
            ],
            [
              -78.422734,
              34.139631
            ],
            [
              -78.372605,
              34.188588
            ],
            [
              -78.374295,
              34.204893
            ],
            [
              -78.254622,
              34.216305
            ],
            [
              -78.16235,
              34.357007
            ],
            [
              -78.202532,
              34.353306
            ],
            [
              -78.209971,
              34.36804
            ],
            [
              -78.235682,
              34.374713
            ],
            [
              -78.256085,
              34.399469
            ],
            [
              -78.334569,
              34.367536
            ],
            [
              -78.365702,
              34.372803
            ],
            [
              -78.396915,
              34.370747
            ],
            [
              -78.420101,
              34.378108
            ],
            [
              -78.44532,
              34.3774
            ],
            [
              -78.552713,
              34.420778
            ],
            [
              -78.59283,
              34.431003
            ],
            [
              -78.60377,
              34.441134
            ],
            [
              -78.648373,
              34.460869
            ],
            [
              -78.667588,
              34.469645
            ],
            [
              -78.825218,
              34.461762
            ],
            [
              -78.858063,
              34.474411
            ],
            [
              -78.863365,
              34.484747
            ],
            [
              -78.868961,
              34.484778
            ],
            [
              -78.895838,
              34.462116
            ],
            [
              -78.917074,
              34.453279
            ],
            [
              -78.935355,
              34.455162
            ],
            [
              -78.960011,
              34.442684
            ],
            [
              -78.971187,
              34.42747
            ],
            [
              -78.968406,
              34.397137
            ],
            [
              -78.998444,
              34.391098
            ],
            [
              -79.011399,
              34.381063
            ],
            [
              -79.001637,
              34.363498
            ],
            [
              -79.010893,
              34.352156
            ],
            [
              -79.02519,
              34.351984
            ],
            [
              -79.045405,
              34.310711
            ],
            [
              -79.052881,
              34.314635
            ],
            [
              -79.0712438523822,
              34.299302913057005
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "033",
      "COUNTYNS": "01008542",
      "AFFGEOID": "0500000US37033",
      "GEOID": "37033",
      "NAME": "Caswell",
      "LSAD": "06",
      "ALAND": 1101692694,
      "AWATER": 8643882,
      "County_state": "Caswell,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.5136470557863,
              36.5407578620674
            ],
            [
              -79.531865,
              36.249673
            ],
            [
              -79.365367,
              36.245887
            ],
            [
              -79.257952,
              36.243445
            ],
            [
              -79.153997,
              36.241875
            ],
            [
              -79.1383311871639,
              36.541738061604995
            ],
            [
              -79.208686,
              36.541571
            ],
            [
              -79.20948,
              36.541594
            ],
            [
              -79.2184541869829,
              36.5414925778669
            ],
            [
              -79.24974,
              36.541139
            ],
            [
              -79.3431171666517,
              36.5411766468952
            ],
            [
              -79.445687,
              36.541218
            ],
            [
              -79.445961,
              36.541195
            ],
            [
              -79.4700322670177,
              36.5410249389508
            ],
            [
              -79.510647,
              36.540738
            ],
            [
              -79.5136470557863,
              36.5407578620674
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "089",
      "COUNTYNS": "01008562",
      "AFFGEOID": "0500000US37089",
      "GEOID": "37089",
      "NAME": "Henderson",
      "LSAD": "06",
      "ALAND": 966142717,
      "AWATER": 5556898,
      "County_state": "Henderson,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.715768,
              35.447808
            ],
            [
              -82.745139,
              35.422967
            ],
            [
              -82.647137,
              35.328721
            ],
            [
              -82.619248,
              35.303708
            ],
            [
              -82.610186,
              35.288199
            ],
            [
              -82.616194,
              35.260245
            ],
            [
              -82.604267,
              35.25894
            ],
            [
              -82.603968,
              35.221791
            ],
            [
              -82.591464,
              35.164866
            ],
            [
              -82.5742297924061,
              35.1436424056196
            ],
            [
              -82.569912,
              35.145268
            ],
            [
              -82.567486,
              35.147694
            ],
            [
              -82.566193,
              35.150119
            ],
            [
              -82.563767,
              35.151575
            ],
            [
              -82.560807,
              35.151644
            ],
            [
              -82.558593,
              35.150928
            ],
            [
              -82.556168,
              35.151736
            ],
            [
              -82.554874,
              35.152868
            ],
            [
              -82.554871,
              35.154639
            ],
            [
              -82.554227,
              35.156911
            ],
            [
              -82.552934,
              35.158042
            ],
            [
              -82.550508,
              35.159498
            ],
            [
              -82.547436,
              35.160306
            ],
            [
              -82.544525,
              35.160306
            ],
            [
              -82.540483,
              35.160306
            ],
            [
              -82.536218,
              35.159259
            ],
            [
              -82.535967,
              35.158664
            ],
            [
              -82.534662,
              35.156911
            ],
            [
              -82.53256,
              35.155617
            ],
            [
              -82.529973,
              35.155617
            ],
            [
              -82.52593,
              35.156749
            ],
            [
              -82.521403,
              35.158851
            ],
            [
              -82.51921,
              35.161044
            ],
            [
              -82.517284,
              35.162643
            ],
            [
              -82.51691,
              35.163029
            ],
            [
              -82.516044,
              35.163442
            ],
            [
              -82.506137,
              35.163894
            ],
            [
              -82.499843,
              35.163675
            ],
            [
              -82.495506,
              35.164312
            ],
            [
              -82.490766,
              35.169715
            ],
            [
              -82.487357,
              35.172494
            ],
            [
              -82.483937,
              35.173798
            ],
            [
              -82.476136,
              35.175486
            ],
            [
              -82.472313,
              35.174619
            ],
            [
              -82.467991,
              35.174633
            ],
            [
              -82.460092,
              35.178143
            ],
            [
              -82.455609,
              35.177425
            ],
            [
              -82.452987,
              35.17469
            ],
            [
              -82.452764,
              35.172626
            ],
            [
              -82.452931,
              35.168999
            ],
            [
              -82.451201,
              35.16526
            ],
            [
              -82.448969,
              35.165037
            ],
            [
              -82.439595,
              35.165863
            ],
            [
              -82.435689,
              35.167715
            ],
            [
              -82.434126,
              35.170784
            ],
            [
              -82.428,
              35.183224
            ],
            [
              -82.424461,
              35.193092
            ],
            [
              -82.419744,
              35.198613
            ],
            [
              -82.417597,
              35.200131
            ],
            [
              -82.411301,
              35.202483
            ],
            [
              -82.403348,
              35.204473
            ],
            [
              -82.395697,
              35.213214
            ],
            [
              -82.39293,
              35.215402
            ],
            [
              -82.390439,
              35.215395
            ],
            [
              -82.384029,
              35.210542
            ],
            [
              -82.383776,
              35.207646
            ],
            [
              -82.380524,
              35.202276
            ],
            [
              -82.378744,
              35.198053
            ],
            [
              -82.379191,
              35.195894
            ],
            [
              -82.380605,
              35.193586
            ],
            [
              -82.381201,
              35.191203
            ],
            [
              -82.380903,
              35.189565
            ],
            [
              -82.379712,
              35.186884
            ],
            [
              -82.376808,
              35.184427
            ],
            [
              -82.373218,
              35.182201
            ],
            [
              -82.371298,
              35.181449
            ],
            [
              -82.36899,
              35.181747
            ],
            [
              -82.364299,
              35.184725
            ],
            [
              -82.363479,
              35.186214
            ],
            [
              -82.363554,
              35.188001
            ],
            [
              -82.363256,
              35.189639
            ],
            [
              -82.361469,
              35.190831
            ],
            [
              -82.35365431473379,
              35.1922225810449
            ],
            [
              -82.350931,
              35.228288
            ],
            [
              -82.359152,
              35.244489
            ],
            [
              -82.349653,
              35.244305
            ],
            [
              -82.34685,
              35.279725
            ],
            [
              -82.32768,
              35.308919
            ],
            [
              -82.310429,
              35.314708
            ],
            [
              -82.305265,
              35.33263
            ],
            [
              -82.261305,
              35.393198
            ],
            [
              -82.270228,
              35.400549
            ],
            [
              -82.280563,
              35.443069
            ],
            [
              -82.265795,
              35.467818
            ],
            [
              -82.332822,
              35.50022
            ],
            [
              -82.358932,
              35.489172
            ],
            [
              -82.369323,
              35.485518
            ],
            [
              -82.367761,
              35.466709
            ],
            [
              -82.367521,
              35.466777
            ],
            [
              -82.367306,
              35.466739
            ],
            [
              -82.367218,
              35.466532
            ],
            [
              -82.367248,
              35.465877
            ],
            [
              -82.367304,
              35.465781
            ],
            [
              -82.367509,
              35.465279
            ],
            [
              -82.363133,
              35.46096
            ],
            [
              -82.417511,
              35.474501
            ],
            [
              -82.448278,
              35.457933
            ],
            [
              -82.456127,
              35.44965
            ],
            [
              -82.495208,
              35.445186
            ],
            [
              -82.5351,
              35.444504
            ],
            [
              -82.532713,
              35.416615
            ],
            [
              -82.54695,
              35.425159
            ],
            [
              -82.548825,
              35.444305
            ],
            [
              -82.570608,
              35.431045
            ],
            [
              -82.608068,
              35.443206
            ],
            [
              -82.621749,
              35.435693
            ],
            [
              -82.653038,
              35.455031
            ],
            [
              -82.673343,
              35.459453
            ],
            [
              -82.715768,
              35.447808
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "071",
      "COUNTYNS": "01008554",
      "AFFGEOID": "0500000US37071",
      "GEOID": "37071",
      "NAME": "Gaston",
      "LSAD": "06",
      "ALAND": 921400770,
      "AWATER": 20560593,
      "County_state": "Gaston,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.4556,
              35.419811
            ],
            [
              -81.398924,
              35.353138
            ],
            [
              -81.366023,
              35.336129
            ],
            [
              -81.356682,
              35.330604
            ],
            [
              -81.366121,
              35.313413
            ],
            [
              -81.353507,
              35.277351
            ],
            [
              -81.321729,
              35.260449
            ],
            [
              -81.323668,
              35.189605
            ],
            [
              -81.3280393754573,
              35.163405284384005
            ],
            [
              -81.290672,
              35.161967
            ],
            [
              -81.2694460893412,
              35.1611497855407
            ],
            [
              -81.26523856687389,
              35.160987792575895
            ],
            [
              -81.241686,
              35.160081
            ],
            [
              -81.239358,
              35.159974
            ],
            [
              -81.138207,
              35.155417
            ],
            [
              -81.11084,
              35.154185
            ],
            [
              -81.109295,
              35.154115
            ],
            [
              -81.077253,
              35.152047
            ],
            [
              -81.043625,
              35.149877
            ],
            [
              -81.04287,
              35.149248
            ],
            [
              -81.007183,
              35.154811
            ],
            [
              -81.004667,
              35.18983
            ],
            [
              -81.005347,
              35.208286
            ],
            [
              -81.013943,
              35.225777
            ],
            [
              -81.008234,
              35.248473
            ],
            [
              -81.014896,
              35.26979
            ],
            [
              -80.993365,
              35.313244
            ],
            [
              -80.984894,
              35.340059
            ],
            [
              -80.97267,
              35.350257
            ],
            [
              -80.942509,
              35.350899
            ],
            [
              -80.923042,
              35.359917
            ],
            [
              -80.962896,
              35.375107
            ],
            [
              -80.97327,
              35.36419
            ],
            [
              -80.986835,
              35.379014
            ],
            [
              -80.95486,
              35.400078
            ],
            [
              -81.094164,
              35.406632
            ],
            [
              -81.4556,
              35.419811
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "177",
      "COUNTYNS": "01026332",
      "AFFGEOID": "0500000US37177",
      "GEOID": "37177",
      "NAME": "Tyrrell",
      "LSAD": "06",
      "ALAND": 1012110887,
      "AWATER": 534568389,
      "County_state": "Tyrrell,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.405603,
              35.786294
            ],
            [
              -76.405971,
              35.697579
            ],
            [
              -76.269247,
              35.690754
            ],
            [
              -76.297899,
              35.608895
            ],
            [
              -76.270279,
              35.608026
            ],
            [
              -76.215989,
              35.598131
            ],
            [
              -76.195848,
              35.620119
            ],
            [
              -76.192606,
              35.646359
            ],
            [
              -76.177848,
              35.655574
            ],
            [
              -76.181778,
              35.665513
            ],
            [
              -76.169902,
              35.673746
            ],
            [
              -76.175861,
              35.690698
            ],
            [
              -76.170101,
              35.698421
            ],
            [
              -76.144653,
              35.702488
            ],
            [
              -76.131898,
              35.692173
            ],
            [
              -76.118268,
              35.691908
            ],
            [
              -76.12067,
              35.683316
            ],
            [
              -76.092627,
              35.669764
            ],
            [
              -76.061095,
              35.675222
            ],
            [
              -76.04444539464829,
              35.6650776730122
            ],
            [
              -76.036393,
              35.690344
            ],
            [
              -76.046813,
              35.717935
            ],
            [
              -76.050485,
              35.806689
            ],
            [
              -76.063203,
              35.853433
            ],
            [
              -76.037116,
              35.88991
            ],
            [
              -76.014353,
              35.920746
            ],
            [
              -76.01995,
              35.934036
            ],
            [
              -76.014159,
              35.957202
            ],
            [
              -76.014685,
              35.960361
            ],
            [
              -76.024162,
              35.970891
            ],
            [
              -76.062071,
              35.993004
            ],
            [
              -76.083131,
              35.989845
            ],
            [
              -76.093697,
              35.993001
            ],
            [
              -76.176585,
              35.993267
            ],
            [
              -76.213966,
              35.988002
            ],
            [
              -76.272408,
              35.972734
            ],
            [
              -76.317687,
              35.946935
            ],
            [
              -76.340327,
              35.94325
            ],
            [
              -76.362966,
              35.942197
            ],
            [
              -76.36521008469319,
              35.9446974227809
            ],
            [
              -76.367703,
              35.929999
            ],
            [
              -76.380009,
              35.914666
            ],
            [
              -76.369646,
              35.883233
            ],
            [
              -76.353554,
              35.861301
            ],
            [
              -76.405603,
              35.786294
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "059",
      "COUNTYNS": "01008549",
      "AFFGEOID": "0500000US37059",
      "GEOID": "37059",
      "NAME": "Davie",
      "LSAD": "06",
      "ALAND": 682967799,
      "AWATER": 7399100,
      "County_state": "Davie,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.707826,
              35.852929
            ],
            [
              -80.693243,
              35.853688
            ],
            [
              -80.691809,
              35.863508
            ],
            [
              -80.668511,
              35.855479
            ],
            [
              -80.671621,
              35.846746
            ],
            [
              -80.632724,
              35.845854
            ],
            [
              -80.609195,
              35.823155
            ],
            [
              -80.598786,
              35.827512
            ],
            [
              -80.570382,
              35.816296
            ],
            [
              -80.534128,
              35.78626
            ],
            [
              -80.508634,
              35.774707
            ],
            [
              -80.496573,
              35.782747
            ],
            [
              -80.487831,
              35.766607
            ],
            [
              -80.483581,
              35.776397
            ],
            [
              -80.47182,
              35.768356
            ],
            [
              -80.458884,
              35.743031
            ],
            [
              -80.450658,
              35.765757
            ],
            [
              -80.476702,
              35.789093
            ],
            [
              -80.464449,
              35.804983
            ],
            [
              -80.472337,
              35.825543
            ],
            [
              -80.487586,
              35.83291
            ],
            [
              -80.478391,
              35.843329
            ],
            [
              -80.461527,
              35.826716
            ],
            [
              -80.446992,
              35.836528
            ],
            [
              -80.429257,
              35.829947
            ],
            [
              -80.42332,
              35.851964
            ],
            [
              -80.398823,
              35.841347
            ],
            [
              -80.385784,
              35.854587
            ],
            [
              -80.391592,
              35.869845
            ],
            [
              -80.416533,
              35.880469
            ],
            [
              -80.407859,
              35.891481
            ],
            [
              -80.370009,
              35.885747
            ],
            [
              -80.36508,
              35.893908
            ],
            [
              -80.375074,
              35.912366
            ],
            [
              -80.368387,
              35.928398
            ],
            [
              -80.375576,
              35.941435
            ],
            [
              -80.377751,
              35.961845
            ],
            [
              -80.394307,
              35.972762
            ],
            [
              -80.420939,
              35.987038
            ],
            [
              -80.417916,
              36.015549
            ],
            [
              -80.453148,
              36.043865
            ],
            [
              -80.463592,
              36.062541
            ],
            [
              -80.496283,
              36.046545
            ],
            [
              -80.595274,
              36.047683
            ],
            [
              -80.693197,
              36.05126
            ],
            [
              -80.707826,
              35.852929
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "011",
      "COUNTYNS": "01008536",
      "AFFGEOID": "0500000US37011",
      "GEOID": "37011",
      "NAME": "Avery",
      "LSAD": "06",
      "ALAND": 640601890,
      "AWATER": 445285,
      "County_state": "Avery,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.080143,
              36.10572
            ],
            [
              -82.080944957901,
              36.1057614805811
            ],
            [
              -82.068685,
              36.06527
            ],
            [
              -82.039175,
              36.007774
            ],
            [
              -82.049298,
              36.007927
            ],
            [
              -82.058764,
              35.98716
            ],
            [
              -82.034104,
              35.942057
            ],
            [
              -81.981954,
              35.911361
            ],
            [
              -81.954602,
              35.926649
            ],
            [
              -81.962987,
              35.941783
            ],
            [
              -81.943188,
              35.96005
            ],
            [
              -81.899871,
              35.999909
            ],
            [
              -81.881386,
              35.992932
            ],
            [
              -81.870232,
              35.976365
            ],
            [
              -81.844536,
              35.974976
            ],
            [
              -81.807162,
              35.961955
            ],
            [
              -81.735673,
              36.067584
            ],
            [
              -81.758517,
              36.077164
            ],
            [
              -81.76114,
              36.090129
            ],
            [
              -81.810516,
              36.111511
            ],
            [
              -81.82923,
              36.168791
            ],
            [
              -81.857147,
              36.193675
            ],
            [
              -81.885383,
              36.189451
            ],
            [
              -81.909008,
              36.216392
            ],
            [
              -81.907742,
              36.238638
            ],
            [
              -81.897019,
              36.242943
            ],
            [
              -81.895357,
              36.272116
            ],
            [
              -81.9181112586581,
              36.287113206280196
            ],
            [
              -81.932994,
              36.264881
            ],
            [
              -81.933019683861,
              36.264842650423404
            ],
            [
              -81.938897,
              36.256067
            ],
            [
              -81.960101,
              36.228131
            ],
            [
              -82.02664,
              36.130222
            ],
            [
              -82.02634,
              36.129222
            ],
            [
              -82.02874,
              36.124322
            ],
            [
              -82.033141,
              36.120422
            ],
            [
              -82.037941,
              36.121122
            ],
            [
              -82.043941,
              36.125421
            ],
            [
              -82.054142,
              36.126821
            ],
            [
              -82.056042,
              36.123921
            ],
            [
              -82.056042,
              36.120721
            ],
            [
              -82.061342,
              36.113121
            ],
            [
              -82.067142,
              36.11202
            ],
            [
              -82.079743,
              36.10652
            ],
            [
              -82.080143,
              36.10572
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "141",
      "COUNTYNS": "01026129",
      "AFFGEOID": "0500000US37141",
      "GEOID": "37141",
      "NAME": "Pender",
      "LSAD": "06",
      "ALAND": 2256645184,
      "AWATER": 162833567,
      "County_state": "Pender,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.272227,
              34.538381
            ],
            [
              -78.254598,
              34.520408
            ],
            [
              -78.255468,
              34.508614
            ],
            [
              -78.235621,
              34.492529
            ],
            [
              -78.212107,
              34.488394
            ],
            [
              -78.202874,
              34.475605
            ],
            [
              -78.176501,
              34.465356
            ],
            [
              -78.256085,
              34.399469
            ],
            [
              -78.235682,
              34.374713
            ],
            [
              -78.209971,
              34.36804
            ],
            [
              -78.202532,
              34.353306
            ],
            [
              -78.16235,
              34.357007
            ],
            [
              -78.137302,
              34.371711
            ],
            [
              -78.092806,
              34.352382
            ],
            [
              -78.078547,
              34.340233
            ],
            [
              -78.029922,
              34.331768
            ],
            [
              -77.993202,
              34.336828
            ],
            [
              -77.978358,
              34.358921
            ],
            [
              -77.986995,
              34.372056
            ],
            [
              -77.975553,
              34.372344
            ],
            [
              -77.962194,
              34.382855
            ],
            [
              -77.957807,
              34.368632
            ],
            [
              -77.940446,
              34.370024
            ],
            [
              -77.928781,
              34.361544
            ],
            [
              -77.918493,
              34.37484
            ],
            [
              -77.899353,
              34.375168
            ],
            [
              -77.895774,
              34.363813
            ],
            [
              -77.8799,
              34.366282
            ],
            [
              -77.856454,
              34.380297
            ],
            [
              -77.823102,
              34.389287
            ],
            [
              -77.80472,
              34.376741
            ],
            [
              -77.814005,
              34.368282
            ],
            [
              -77.811091,
              34.358024
            ],
            [
              -77.739198,
              34.292752
            ],
            [
              -77.71090187414619,
              34.297249881263504
            ],
            [
              -77.687226,
              34.320444
            ],
            [
              -77.661673,
              34.341868
            ],
            [
              -77.635034,
              34.359555
            ],
            [
              -77.582323,
              34.400506
            ],
            [
              -77.556943,
              34.417218
            ],
            [
              -77.5179602863695,
              34.4404830452968
            ],
            [
              -77.53217,
              34.457253
            ],
            [
              -77.552079,
              34.471035
            ],
            [
              -77.575961,
              34.476366
            ],
            [
              -77.61512,
              34.579057
            ],
            [
              -77.640365,
              34.63472
            ],
            [
              -77.680127,
              34.720595
            ],
            [
              -77.921538,
              34.719746
            ],
            [
              -77.938001,
              34.723227
            ],
            [
              -77.958813,
              34.715863
            ],
            [
              -77.973882,
              34.719162
            ],
            [
              -77.992061,
              34.715999
            ],
            [
              -78.012708,
              34.732616
            ],
            [
              -78.030748,
              34.72481
            ],
            [
              -78.066197,
              34.727867
            ],
            [
              -78.114007,
              34.721795
            ],
            [
              -78.113472,
              34.707219
            ],
            [
              -78.15652,
              34.67907
            ],
            [
              -78.254441,
              34.553595
            ],
            [
              -78.272227,
              34.538381
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "035",
      "COUNTYNS": "01008543",
      "AFFGEOID": "0500000US37035",
      "GEOID": "37035",
      "NAME": "Catawba",
      "LSAD": "06",
      "ALAND": 1039550614,
      "AWATER": 37945362,
      "County_state": "Catawba,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.506809,
              35.607956
            ],
            [
              -81.535403,
              35.568137
            ],
            [
              -80.960025,
              35.54702
            ],
            [
              -80.951295,
              35.581273
            ],
            [
              -80.933361,
              35.59239
            ],
            [
              -80.943556,
              35.60539
            ],
            [
              -80.925288,
              35.619483
            ],
            [
              -80.92754,
              35.622978
            ],
            [
              -80.951241,
              35.641413
            ],
            [
              -80.966305,
              35.670313
            ],
            [
              -81.005321,
              35.710209
            ],
            [
              -81.061328,
              35.710641
            ],
            [
              -81.067863,
              35.7381
            ],
            [
              -81.109507,
              35.776594
            ],
            [
              -81.128414,
              35.789383
            ],
            [
              -81.143053,
              35.827887
            ],
            [
              -81.190407,
              35.822385
            ],
            [
              -81.226246,
              35.809652
            ],
            [
              -81.248998,
              35.815716
            ],
            [
              -81.255043,
              35.808272
            ],
            [
              -81.299539,
              35.798338
            ],
            [
              -81.334272,
              35.796281
            ],
            [
              -81.355682,
              35.779721
            ],
            [
              -81.353514,
              35.776268
            ],
            [
              -81.363796,
              35.767802
            ],
            [
              -81.432898,
              35.677847
            ],
            [
              -81.476143,
              35.63678
            ],
            [
              -81.506809,
              35.607956
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "119",
      "COUNTYNS": "01008570",
      "AFFGEOID": "0500000US37119",
      "GEOID": "37119",
      "NAME": "Mecklenburg",
      "LSAD": "06",
      "ALAND": 1356030322,
      "AWATER": 56685361,
      "County_state": "Mecklenburg,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.058029,
              35.07319
            ],
            [
              -81.057648,
              35.062433
            ],
            [
              -81.055695,
              35.060121
            ],
            [
              -81.050018,
              35.055246
            ],
            [
              -81.0414930433063,
              35.04470799807459
            ],
            [
              -81.041489,
              35.044703
            ],
            [
              -81.0233351374323,
              35.0551100661147
            ],
            [
              -80.9967330696895,
              35.0703602354246
            ],
            [
              -80.9891720831187,
              35.074694722746
            ],
            [
              -80.98416,
              35.077568
            ],
            [
              -80.95787,
              35.092639
            ],
            [
              -80.93495,
              35.107409
            ],
            [
              -80.906553,
              35.076763
            ],
            [
              -80.9062325339664,
              35.0764190603399
            ],
            [
              -80.9061772887421,
              35.076359768490796
            ],
            [
              -80.884887,
              35.05351
            ],
            [
              -80.88486293137589,
              35.0534819452132
            ],
            [
              -80.8403843929928,
              35.00163702423549
            ],
            [
              -80.82101,
              35.017326
            ],
            [
              -80.811156,
              35.02148
            ],
            [
              -80.804506,
              35.027749
            ],
            [
              -80.767555,
              35.036286
            ],
            [
              -80.736466,
              35.060113
            ],
            [
              -80.721657,
              35.071559
            ],
            [
              -80.65418,
              35.125041
            ],
            [
              -80.599735,
              35.168645
            ],
            [
              -80.550349,
              35.208412
            ],
            [
              -80.599733,
              35.234105
            ],
            [
              -80.627934,
              35.248669
            ],
            [
              -80.66648,
              35.267934
            ],
            [
              -80.672489,
              35.284568
            ],
            [
              -80.682774,
              35.315662
            ],
            [
              -80.693061,
              35.346379
            ],
            [
              -80.732757,
              35.371931
            ],
            [
              -80.766414,
              35.401478
            ],
            [
              -80.752167,
              35.41405
            ],
            [
              -80.786864,
              35.493156
            ],
            [
              -80.784167,
              35.506017
            ],
            [
              -80.907512,
              35.514839
            ],
            [
              -80.948122,
              35.491167
            ],
            [
              -80.95159,
              35.470178
            ],
            [
              -80.94238,
              35.455147
            ],
            [
              -80.958636,
              35.431697
            ],
            [
              -80.955708,
              35.401623
            ],
            [
              -80.95486,
              35.400078
            ],
            [
              -80.986835,
              35.379014
            ],
            [
              -80.97327,
              35.36419
            ],
            [
              -80.962896,
              35.375107
            ],
            [
              -80.923042,
              35.359917
            ],
            [
              -80.942509,
              35.350899
            ],
            [
              -80.97267,
              35.350257
            ],
            [
              -80.984894,
              35.340059
            ],
            [
              -80.993365,
              35.313244
            ],
            [
              -81.014896,
              35.26979
            ],
            [
              -81.008234,
              35.248473
            ],
            [
              -81.013943,
              35.225777
            ],
            [
              -81.005347,
              35.208286
            ],
            [
              -81.004667,
              35.18983
            ],
            [
              -81.007183,
              35.154811
            ],
            [
              -81.04287,
              35.149248
            ],
            [
              -81.043407,
              35.14839
            ],
            [
              -81.044391,
              35.147918
            ],
            [
              -81.047091,
              35.145157
            ],
            [
              -81.047826,
              35.143743
            ],
            [
              -81.051204,
              35.133237
            ],
            [
              -81.051037,
              35.131654
            ],
            [
              -81.05042,
              35.131048
            ],
            [
              -81.038968,
              35.126299
            ],
            [
              -81.036759,
              35.122552
            ],
            [
              -81.033005,
              35.113747
            ],
            [
              -81.032471,
              35.110033
            ],
            [
              -81.032806,
              35.108049
            ],
            [
              -81.034958,
              35.104105
            ],
            [
              -81.037369,
              35.102541
            ],
            [
              -81.0401844043794,
              35.101949319167
            ],
            [
              -81.046524,
              35.100617
            ],
            [
              -81.050079,
              35.098817
            ],
            [
              -81.052078,
              35.096276
            ],
            [
              -81.057236,
              35.086129
            ],
            [
              -81.058029,
              35.07319
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "127",
      "COUNTYNS": "01008574",
      "AFFGEOID": "0500000US37127",
      "GEOID": "37127",
      "NAME": "Nash",
      "LSAD": "06",
      "ALAND": 1399739716,
      "AWATER": 6177826,
      "County_state": "Nash,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.255973,
              35.81812
            ],
            [
              -78.243876,
              35.791949
            ],
            [
              -78.248458,
              35.77479
            ],
            [
              -78.23251,
              35.751256
            ],
            [
              -78.219769,
              35.755069
            ],
            [
              -78.215472,
              35.735837
            ],
            [
              -78.192117,
              35.730545
            ],
            [
              -78.181972,
              35.737956
            ],
            [
              -78.147102,
              35.745803
            ],
            [
              -77.931361,
              35.830078
            ],
            [
              -77.915657,
              35.83645
            ],
            [
              -77.873867,
              35.849731
            ],
            [
              -77.844737,
              35.837618
            ],
            [
              -77.835178,
              35.85497
            ],
            [
              -77.828444,
              35.867208
            ],
            [
              -77.819578,
              35.884063
            ],
            [
              -77.779058,
              35.981296
            ],
            [
              -77.770381,
              36.009638
            ],
            [
              -77.740451,
              36.063485
            ],
            [
              -77.706966,
              36.115503
            ],
            [
              -77.697956,
              36.15315
            ],
            [
              -77.721149,
              36.141923
            ],
            [
              -77.720742,
              36.133884
            ],
            [
              -77.729676,
              36.149014
            ],
            [
              -77.749009,
              36.15695
            ],
            [
              -77.757527,
              36.14878
            ],
            [
              -77.782119,
              36.141507
            ],
            [
              -77.799263,
              36.144476
            ],
            [
              -77.807517,
              36.138385
            ],
            [
              -77.821892,
              36.144777
            ],
            [
              -77.860523,
              36.148837
            ],
            [
              -77.8864,
              36.143994
            ],
            [
              -77.901139,
              36.162894
            ],
            [
              -77.923032,
              36.166897
            ],
            [
              -77.917958,
              36.181536
            ],
            [
              -77.944004,
              36.192592
            ],
            [
              -77.950735,
              36.180658
            ],
            [
              -77.968089,
              36.194504
            ],
            [
              -77.977663,
              36.175259
            ],
            [
              -77.98765,
              36.189532
            ],
            [
              -78.006551,
              36.202633
            ],
            [
              -78.034511,
              36.160871
            ],
            [
              -78.047526,
              36.141507
            ],
            [
              -78.052353,
              36.134327
            ],
            [
              -78.073291,
              36.103138
            ],
            [
              -78.087749,
              36.08159
            ],
            [
              -78.255973,
              35.81812
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "115",
      "COUNTYNS": "01025834",
      "AFFGEOID": "0500000US37115",
      "GEOID": "37115",
      "NAME": "Madison",
      "LSAD": "06",
      "ALAND": 1164497728,
      "AWATER": 4840762,
      "County_state": "Madison,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.962206,
              35.792755
            ],
            [
              -82.9628979027128,
              35.791734792758696
            ],
            [
              -82.950078,
              35.766359
            ],
            [
              -82.957176,
              35.746843
            ],
            [
              -82.947879,
              35.738945
            ],
            [
              -82.91765,
              35.744808
            ],
            [
              -82.910409,
              35.716184
            ],
            [
              -82.898614,
              35.703736
            ],
            [
              -82.901071,
              35.684929
            ],
            [
              -82.883909,
              35.678235
            ],
            [
              -82.849216,
              35.701495
            ],
            [
              -82.807056,
              35.685874
            ],
            [
              -82.794782,
              35.695805
            ],
            [
              -82.74998,
              35.706458
            ],
            [
              -82.730975,
              35.722403
            ],
            [
              -82.713764,
              35.717824
            ],
            [
              -82.711862,
              35.733038
            ],
            [
              -82.692756,
              35.734168
            ],
            [
              -82.669109,
              35.726459
            ],
            [
              -82.67703,
              35.734854
            ],
            [
              -82.665178,
              35.738742
            ],
            [
              -82.513161,
              35.80338
            ],
            [
              -82.475845,
              35.798308
            ],
            [
              -82.463828,
              35.809616
            ],
            [
              -82.435335,
              35.80859
            ],
            [
              -82.408602,
              35.818178
            ],
            [
              -82.419636,
              35.843335
            ],
            [
              -82.417552,
              35.859363
            ],
            [
              -82.439228,
              35.87922
            ],
            [
              -82.453953,
              35.899872
            ],
            [
              -82.474775,
              35.909245
            ],
            [
              -82.494781,
              35.909484
            ],
            [
              -82.49036,
              35.921215
            ],
            [
              -82.498548,
              35.930193
            ],
            [
              -82.480098,
              35.948211
            ],
            [
              -82.496214,
              35.956644
            ],
            [
              -82.5054541504531,
              35.9776714603071
            ],
            [
              -82.507068,
              35.977475
            ],
            [
              -82.512598,
              35.975664
            ],
            [
              -82.516444,
              35.975958
            ],
            [
              -82.52066,
              35.974633
            ],
            [
              -82.522702,
              35.973436
            ],
            [
              -82.531292,
              35.972188
            ],
            [
              -82.534763,
              35.969887
            ],
            [
              -82.539273,
              35.969115
            ],
            [
              -82.542463,
              35.967994
            ],
            [
              -82.549682,
              35.964275
            ],
            [
              -82.553192,
              35.960627
            ],
            [
              -82.557874,
              35.953901
            ],
            [
              -82.567503,
              35.955552
            ],
            [
              -82.57517,
              35.958384
            ],
            [
              -82.576678,
              35.959255
            ],
            [
              -82.577719,
              35.964196
            ],
            [
              -82.581003,
              35.965557
            ],
            [
              -82.591977,
              35.966385
            ],
            [
              -82.59486,
              35.965347
            ],
            [
              -82.60037,
              35.964626
            ],
            [
              -82.607761,
              35.966023
            ],
            [
              -82.610889,
              35.967409
            ],
            [
              -82.611602,
              35.971418
            ],
            [
              -82.610885,
              35.974442
            ],
            [
              -82.60674,
              35.984446
            ],
            [
              -82.604239,
              35.987319
            ],
            [
              -82.606944,
              35.99217
            ],
            [
              -82.612604,
              35.993488
            ],
            [
              -82.613028,
              35.994
            ],
            [
              -82.615062,
              36.000306
            ],
            [
              -82.614362,
              36.003506
            ],
            [
              -82.613862,
              36.004706
            ],
            [
              -82.611862,
              36.006206
            ],
            [
              -82.604327,
              36.018187
            ],
            [
              -82.600089,
              36.021774
            ],
            [
              -82.595525,
              36.026012
            ],
            [
              -82.594873,
              36.029598
            ],
            [
              -82.596177,
              36.03188
            ],
            [
              -82.598785,
              36.034162
            ],
            [
              -82.600741,
              36.037422
            ],
            [
              -82.602877,
              36.039833
            ],
            [
              -82.602878533975,
              36.0398335475815
            ],
            [
              -82.606163,
              36.041006
            ],
            [
              -82.609663,
              36.044906
            ],
            [
              -82.613563,
              36.046406
            ],
            [
              -82.618164,
              36.047005
            ],
            [
              -82.618064,
              36.051205
            ],
            [
              -82.617264,
              36.054205
            ],
            [
              -82.618664,
              36.056105
            ],
            [
              -82.628365,
              36.062105
            ],
            [
              -82.632265,
              36.065705
            ],
            [
              -82.637165,
              36.065805
            ],
            [
              -82.643565,
              36.062805
            ],
            [
              -82.650165,
              36.057805
            ],
            [
              -82.654815,
              36.056225
            ],
            [
              -82.657249,
              36.056636
            ],
            [
              -82.662665,
              36.055005
            ],
            [
              -82.668365,
              36.052905
            ],
            [
              -82.672965,
              36.050405
            ],
            [
              -82.683565,
              36.046104
            ],
            [
              -82.684765,
              36.045004
            ],
            [
              -82.685565,
              36.042004
            ],
            [
              -82.688865,
              36.038604
            ],
            [
              -82.701065,
              36.034404
            ],
            [
              -82.703165,
              36.032404
            ],
            [
              -82.707465,
              36.030104
            ],
            [
              -82.715165,
              36.028604
            ],
            [
              -82.715565,
              36.026904
            ],
            [
              -82.715365,
              36.024253
            ],
            [
              -82.715965,
              36.022804
            ],
            [
              -82.725065,
              36.018204
            ],
            [
              -82.727865,
              36.018504
            ],
            [
              -82.731865,
              36.017604
            ],
            [
              -82.750065,
              36.006004
            ],
            [
              -82.754465,
              36.004304
            ],
            [
              -82.759165,
              36.004203
            ],
            [
              -82.765365,
              36.003003
            ],
            [
              -82.776001,
              36.000103
            ],
            [
              -82.777283,
              35.998811
            ],
            [
              -82.778589,
              35.997001
            ],
            [
              -82.779397,
              35.992511
            ],
            [
              -82.785267,
              35.987927
            ],
            [
              -82.7852918582795,
              35.987061487670005
            ],
            [
              -82.785558,
              35.977795
            ],
            [
              -82.781809,
              35.974562
            ],
            [
              -82.780319,
              35.974365
            ],
            [
              -82.778625,
              35.974792
            ],
            [
              -82.776434,
              35.973886
            ],
            [
              -82.774905,
              35.971978
            ],
            [
              -82.777751,
              35.966912
            ],
            [
              -82.783085,
              35.964982
            ],
            [
              -82.784536,
              35.963905
            ],
            [
              -82.785356,
              35.96253
            ],
            [
              -82.785191,
              35.959231
            ],
            [
              -82.787465,
              35.952163
            ],
            [
              -82.800431,
              35.944155
            ],
            [
              -82.805851,
              35.937938
            ],
            [
              -82.806174,
              35.936908
            ],
            [
              -82.805771,
              35.935316
            ],
            [
              -82.802769,
              35.930129
            ],
            [
              -82.802892,
              35.929013
            ],
            [
              -82.804997,
              35.927168
            ],
            [
              -82.809038,
              35.927241
            ],
            [
              -82.811067,
              35.926801
            ],
            [
              -82.81613,
              35.923986
            ],
            [
              -82.821861,
              35.921839
            ],
            [
              -82.82257,
              35.922531
            ],
            [
              -82.826045,
              35.929721
            ],
            [
              -82.828933,
              35.932932
            ],
            [
              -82.830112,
              35.932972
            ],
            [
              -82.833268,
              35.934993
            ],
            [
              -82.839994,
              35.940166
            ],
            [
              -82.841259,
              35.941721
            ],
            [
              -82.849849,
              35.947772
            ],
            [
              -82.852554,
              35.949089
            ],
            [
              -82.860724,
              35.94743
            ],
            [
              -82.869315,
              35.950565
            ],
            [
              -82.870666,
              35.95199
            ],
            [
              -82.874159,
              35.952698
            ],
            [
              -82.883933,
              35.949192
            ],
            [
              -82.892659,
              35.945182
            ],
            [
              -82.896947,
              35.944624
            ],
            [
              -82.8984628586303,
              35.945088097924696
            ],
            [
              -82.898505,
              35.945101
            ],
            [
              -82.901713,
              35.937713
            ],
            [
              -82.901577,
              35.931446
            ],
            [
              -82.902374,
              35.929852
            ],
            [
              -82.903436,
              35.928524
            ],
            [
              -82.906358,
              35.927196
            ],
            [
              -82.910608,
              35.92693
            ],
            [
              -82.911405,
              35.925868
            ],
            [
              -82.911936,
              35.921618
            ],
            [
              -82.911671,
              35.914711
            ],
            [
              -82.906917,
              35.907397
            ],
            [
              -82.904543,
              35.897011
            ],
            [
              -82.901843,
              35.89293
            ],
            [
              -82.901843,
              35.890274
            ],
            [
              -82.903702,
              35.887617
            ],
            [
              -82.903968,
              35.885492
            ],
            [
              -82.901046,
              35.882305
            ],
            [
              -82.899718,
              35.879914
            ],
            [
              -82.899186,
              35.877524
            ],
            [
              -82.899718,
              35.874602
            ],
            [
              -82.901301,
              35.872593
            ],
            [
              -82.916452,
              35.866102
            ],
            [
              -82.918312,
              35.863977
            ],
            [
              -82.919374,
              35.860523
            ],
            [
              -82.920974,
              35.851073
            ],
            [
              -82.919108,
              35.844851
            ],
            [
              -82.920171,
              35.841664
            ],
            [
              -82.923358,
              35.839273
            ],
            [
              -82.927569,
              35.838586
            ],
            [
              -82.931859,
              35.836351
            ],
            [
              -82.933221,
              35.832915
            ],
            [
              -82.937437,
              35.82732
            ],
            [
              -82.94383,
              35.825638
            ],
            [
              -82.945515,
              35.824662
            ],
            [
              -82.952026,
              35.816183
            ],
            [
              -82.955751,
              35.809802
            ],
            [
              -82.956127,
              35.807874
            ],
            [
              -82.95895,
              35.803323
            ],
            [
              -82.961724,
              35.800491
            ],
            [
              -82.962842,
              35.795126
            ],
            [
              -82.962206,
              35.792755
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "167",
      "COUNTYNS": "01025844",
      "AFFGEOID": "0500000US37167",
      "GEOID": "37167",
      "NAME": "Stanly",
      "LSAD": "06",
      "ALAND": 1023370455,
      "AWATER": 25242751,
      "County_state": "Stanly,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.506171,
              35.192447
            ],
            [
              -80.504985,
              35.184998
            ],
            [
              -80.481141,
              35.180075
            ],
            [
              -80.468186,
              35.166694
            ],
            [
              -80.439865,
              35.161042
            ],
            [
              -80.428192,
              35.167578
            ],
            [
              -80.388328,
              35.165862
            ],
            [
              -80.368574,
              35.179077
            ],
            [
              -80.356982,
              35.16494
            ],
            [
              -80.322125,
              35.173038
            ],
            [
              -80.296702,
              35.193379
            ],
            [
              -80.276829,
              35.195722
            ],
            [
              -80.254019,
              35.211251
            ],
            [
              -80.2421,
              35.181573
            ],
            [
              -80.225002,
              35.156241
            ],
            [
              -80.207127,
              35.156576
            ],
            [
              -80.19492,
              35.165381
            ],
            [
              -80.190638,
              35.155538
            ],
            [
              -80.172819,
              35.147847
            ],
            [
              -80.159851,
              35.151176
            ],
            [
              -80.165379,
              35.164787
            ],
            [
              -80.153889,
              35.174947
            ],
            [
              -80.136233,
              35.167486
            ],
            [
              -80.124481,
              35.176953
            ],
            [
              -80.129052,
              35.187581
            ],
            [
              -80.112644,
              35.193882
            ],
            [
              -80.107444,
              35.173664
            ],
            [
              -80.093319,
              35.163926
            ],
            [
              -80.075365,
              35.143081
            ],
            [
              -80.053064,
              35.172625
            ],
            [
              -80.062188,
              35.201331
            ],
            [
              -80.078036,
              35.228711
            ],
            [
              -80.090801,
              35.235989
            ],
            [
              -80.098185,
              35.255173
            ],
            [
              -80.092241,
              35.271987
            ],
            [
              -80.094142,
              35.28658
            ],
            [
              -80.080483,
              35.307623
            ],
            [
              -80.081938,
              35.321457
            ],
            [
              -80.065873,
              35.343557
            ],
            [
              -80.066371,
              35.357145
            ],
            [
              -80.051225,
              35.371537
            ],
            [
              -80.062271,
              35.386134
            ],
            [
              -80.093711,
              35.40203
            ],
            [
              -80.090119,
              35.415552
            ],
            [
              -80.100491,
              35.429903
            ],
            [
              -80.108495,
              35.459451
            ],
            [
              -80.139921,
              35.475055
            ],
            [
              -80.161736,
              35.475208
            ],
            [
              -80.176243,
              35.485428
            ],
            [
              -80.182559,
              35.504151
            ],
            [
              -80.295421,
              35.50292
            ],
            [
              -80.445342,
              35.275719
            ],
            [
              -80.484356,
              35.216368
            ],
            [
              -80.506171,
              35.192447
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "055",
      "COUNTYNS": "01026133",
      "AFFGEOID": "0500000US37055",
      "GEOID": "37055",
      "NAME": "Dare",
      "LSAD": "06",
      "ALAND": 992522471,
      "AWATER": 3000549341,
      "County_state": "Dare,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -75.726807,
              35.935844
            ],
            [
              -75.727251,
              35.93362
            ],
            [
              -75.723782,
              35.925569
            ],
            [
              -75.702165,
              35.915428
            ],
            [
              -75.696871,
              35.909556
            ],
            [
              -75.697672,
              35.901639
            ],
            [
              -75.681415,
              35.88398
            ],
            [
              -75.67283,
              35.882423
            ],
            [
              -75.663356,
              35.869835
            ],
            [
              -75.660598,
              35.862541
            ],
            [
              -75.660086,
              35.83861
            ],
            [
              -75.675054,
              35.830204
            ],
            [
              -75.667891,
              35.82354
            ],
            [
              -75.63898,
              35.818639
            ],
            [
              -75.624235,
              35.809387
            ],
            [
              -75.620454,
              35.809253
            ],
            [
              -75.614361,
              35.815659
            ],
            [
              -75.619772,
              35.847606
            ],
            [
              -75.616833,
              35.856331
            ],
            [
              -75.636545,
              35.885603
            ],
            [
              -75.653478,
              35.904686
            ],
            [
              -75.662019,
              35.906522
            ],
            [
              -75.662938,
              35.916166
            ],
            [
              -75.65954,
              35.919564
            ],
            [
              -75.675245,
              35.929024
            ],
            [
              -75.686358,
              35.932973
            ],
            [
              -75.69115,
              35.936932
            ],
            [
              -75.705323,
              35.939403
            ],
            [
              -75.718266,
              35.939714
            ],
            [
              -75.726807,
              35.935844
            ]
          ]
        ],
        [
          [
            [
              -75.7537651648491,
              35.1996115571648
            ],
            [
              -75.74940632077059,
              35.1852064931186
            ],
            [
              -75.728897,
              35.190334
            ],
            [
              -75.672673,
              35.208471
            ],
            [
              -75.635493,
              35.22026
            ],
            [
              -75.610101,
              35.227514
            ],
            [
              -75.580176,
              35.231142
            ],
            [
              -75.560225,
              35.232048
            ],
            [
              -75.544809,
              35.228421
            ],
            [
              -75.533627,
              35.225825
            ],
            [
              -75.52592,
              35.233839
            ],
            [
              -75.502188,
              35.320012
            ],
            [
              -75.486771,
              35.391652
            ],
            [
              -75.471355,
              35.479615
            ],
            [
              -75.462491,
              35.553556
            ],
            [
              -75.460061,
              35.581314
            ],
            [
              -75.458659,
              35.596597
            ],
            [
              -75.479128,
              35.678634
            ],
            [
              -75.496086,
              35.728515
            ],
            [
              -75.502427,
              35.742913
            ],
            [
              -75.51901,
              35.769087
            ],
            [
              -75.523446,
              35.773568
            ],
            [
              -75.528912,
              35.771447
            ],
            [
              -75.517504,
              35.735956
            ],
            [
              -75.515745,
              35.721671
            ],
            [
              -75.507385,
              35.680564
            ],
            [
              -75.498675,
              35.666281
            ],
            [
              -75.489426,
              35.642699
            ],
            [
              -75.483883,
              35.62121
            ],
            [
              -75.47861,
              35.599318
            ],
            [
              -75.47861,
              35.553069
            ],
            [
              -75.482237,
              35.53856
            ],
            [
              -75.487528,
              35.525889
            ],
            [
              -75.489618,
              35.508471
            ],
            [
              -75.488585,
              35.497752
            ],
            [
              -75.487678,
              35.485056
            ],
            [
              -75.494933,
              35.454224
            ],
            [
              -75.500374,
              35.424298
            ],
            [
              -75.506722,
              35.387118
            ],
            [
              -75.51261,
              35.362853
            ],
            [
              -75.518511,
              35.336335
            ],
            [
              -75.523952,
              35.318198
            ],
            [
              -75.529393,
              35.288272
            ],
            [
              -75.535741,
              35.272856
            ],
            [
              -75.561033,
              35.266008
            ],
            [
              -75.581935,
              35.263917
            ],
            [
              -75.585419,
              35.266356
            ],
            [
              -75.596915,
              35.269491
            ],
            [
              -75.59796,
              35.266704
            ],
            [
              -75.598312,
              35.261067
            ],
            [
              -75.599005,
              35.256253
            ],
            [
              -75.615378,
              35.248938
            ],
            [
              -75.630358,
              35.238487
            ],
            [
              -75.640934,
              35.233862
            ],
            [
              -75.664512,
              35.227514
            ],
            [
              -75.675394,
              35.228421
            ],
            [
              -75.681916,
              35.232913
            ],
            [
              -75.684006,
              35.232913
            ],
            [
              -75.68749,
              35.231171
            ],
            [
              -75.694437,
              35.22298
            ],
            [
              -75.698972,
              35.221166
            ],
            [
              -75.708947,
              35.213912
            ],
            [
              -75.718015,
              35.209377
            ],
            [
              -75.734171,
              35.204347
            ],
            [
              -75.74522,
              35.20303
            ],
            [
              -75.7537651648491,
              35.1996115571648
            ]
          ]
        ],
        [
          [
            [
              -75.7943606364923,
              36.227327462075294
            ],
            [
              -75.793286,
              36.226432
            ],
            [
              -75.786221,
              36.210384
            ],
            [
              -75.77684,
              36.189393
            ],
            [
              -75.75893,
              36.152215
            ],
            [
              -75.755518,
              36.140163
            ],
            [
              -75.754239,
              36.129831
            ],
            [
              -75.75025,
              36.121076
            ],
            [
              -75.73972,
              36.07527
            ],
            [
              -75.739457,
              36.066846
            ],
            [
              -75.741563,
              36.055526
            ],
            [
              -75.74051,
              36.046839
            ],
            [
              -75.737088,
              36.040784
            ],
            [
              -75.726821,
              36.040521
            ],
            [
              -75.722609,
              36.037362
            ],
            [
              -75.722082,
              36.03236
            ],
            [
              -75.726558,
              36.02104
            ],
            [
              -75.727084,
              36.01051
            ],
            [
              -75.723662,
              36.003139
            ],
            [
              -75.678909,
              35.993925
            ],
            [
              -75.671801,
              35.985238
            ],
            [
              -75.668379,
              35.978394
            ],
            [
              -75.66707,
              35.971381
            ],
            [
              -75.660224,
              35.969611
            ],
            [
              -75.647205,
              35.954325
            ],
            [
              -75.636545,
              35.936028
            ],
            [
              -75.629722,
              35.924634
            ],
            [
              -75.623325,
              35.909093
            ],
            [
              -75.622909,
              35.892321
            ],
            [
              -75.60125,
              35.867302
            ],
            [
              -75.588878,
              35.844926
            ],
            [
              -75.575991,
              35.823089
            ],
            [
              -75.569336,
              35.81484
            ],
            [
              -75.563061,
              35.799265
            ],
            [
              -75.552017,
              35.79503
            ],
            [
              -75.548329,
              35.785348
            ],
            [
              -75.543259,
              35.779691
            ],
            [
              -75.536428,
              35.780118
            ],
            [
              -75.533012,
              35.787377
            ],
            [
              -75.538739,
              35.797396
            ],
            [
              -75.552299,
              35.822173
            ],
            [
              -75.569794,
              35.863301
            ],
            [
              -75.658537,
              36.02043
            ],
            [
              -75.696742,
              36.077497
            ],
            [
              -75.71831,
              36.113674
            ],
            [
              -75.738431,
              36.154282
            ],
            [
              -75.759637,
              36.204705
            ],
            [
              -75.77251,
              36.22944
            ],
            [
              -75.773329478967,
              36.23152850558
            ],
            [
              -75.7943606364923,
              36.227327462075294
            ]
          ]
        ],
        [
          [
            [
              -76.013021,
              35.670065
            ],
            [
              -76.01400112478679,
              35.6688648970815
            ],
            [
              -75.901342,
              35.668952
            ],
            [
              -75.890496,
              35.652259
            ],
            [
              -75.891953,
              35.643328
            ],
            [
              -75.876703,
              35.625177
            ],
            [
              -75.87982,
              35.606856
            ],
            [
              -75.8698693905032,
              35.582742606809
            ],
            [
              -75.859636,
              35.586641
            ],
            [
              -75.851767,
              35.578773
            ],
            [
              -75.837154,
              35.570904
            ],
            [
              -75.797248,
              35.574276
            ],
            [
              -75.775328,
              35.579335
            ],
            [
              -75.778138,
              35.592262
            ],
            [
              -75.762963,
              35.603503
            ],
            [
              -75.747225,
              35.610248
            ],
            [
              -75.729802,
              35.625985
            ],
            [
              -75.729802,
              35.628795
            ],
            [
              -75.737109,
              35.63835
            ],
            [
              -75.742167,
              35.655212
            ],
            [
              -75.741605,
              35.672073
            ],
            [
              -75.713502,
              35.693993
            ],
            [
              -75.71294,
              35.69849
            ],
            [
              -75.715188,
              35.708045
            ],
            [
              -75.719123,
              35.714227
            ],
            [
              -75.724743,
              35.742892
            ],
            [
              -75.735422,
              35.767622
            ],
            [
              -75.739357,
              35.770994
            ],
            [
              -75.738233,
              35.778301
            ],
            [
              -75.732612,
              35.790666
            ],
            [
              -75.726689,
              35.811361
            ],
            [
              -75.727216,
              35.822703
            ],
            [
              -75.734587,
              35.839266
            ],
            [
              -75.748276,
              35.852428
            ],
            [
              -75.753014,
              35.871382
            ],
            [
              -75.751961,
              35.878227
            ],
            [
              -75.768809,
              35.901393
            ],
            [
              -75.778813,
              35.918241
            ],
            [
              -75.782498,
              35.928244
            ],
            [
              -75.782498,
              35.935615
            ],
            [
              -75.800926,
              35.944566
            ],
            [
              -75.805138,
              35.954043
            ],
            [
              -75.80935,
              35.959308
            ],
            [
              -75.84989,
              35.976156
            ],
            [
              -75.86042,
              35.978262
            ],
            [
              -75.879374,
              35.978789
            ],
            [
              -75.899382,
              35.977209
            ],
            [
              -75.938343,
              35.9651
            ],
            [
              -75.947293,
              35.959835
            ],
            [
              -75.946767,
              35.955623
            ],
            [
              -75.943608,
              35.952464
            ],
            [
              -75.937816,
              35.950884
            ],
            [
              -75.92676,
              35.940354
            ],
            [
              -75.927286,
              35.93193
            ],
            [
              -75.929919,
              35.928771
            ],
            [
              -75.934131,
              35.928244
            ],
            [
              -75.94782,
              35.920347
            ],
            [
              -75.962562,
              35.901393
            ],
            [
              -75.966247,
              35.899287
            ],
            [
              -75.97783,
              35.897181
            ],
            [
              -75.987148,
              35.836967
            ],
            [
              -75.9869,
              35.768194
            ],
            [
              -76.013021,
              35.670065
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "195",
      "COUNTYNS": "01008596",
      "AFFGEOID": "0500000US37195",
      "GEOID": "37195",
      "NAME": "Wilson",
      "LSAD": "06",
      "ALAND": 952010950,
      "AWATER": 14334531,
      "County_state": "Wilson,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.181972,
              35.737956
            ],
            [
              -78.192117,
              35.730545
            ],
            [
              -78.161648,
              35.713072
            ],
            [
              -78.160825,
              35.696267
            ],
            [
              -78.127312,
              35.607299
            ],
            [
              -78.124501,
              35.602828
            ],
            [
              -78.064784,
              35.585263
            ],
            [
              -78.058006,
              35.597806
            ],
            [
              -77.998348,
              35.585597
            ],
            [
              -77.873751,
              35.586241
            ],
            [
              -77.835727,
              35.586401
            ],
            [
              -77.822511,
              35.585383
            ],
            [
              -77.806291,
              35.583436
            ],
            [
              -77.700692,
              35.652295
            ],
            [
              -77.687927,
              35.661061
            ],
            [
              -77.671408,
              35.671478
            ],
            [
              -77.665131,
              35.674935
            ],
            [
              -77.674837,
              35.689104
            ],
            [
              -77.718436,
              35.728823
            ],
            [
              -77.73823,
              35.783964
            ],
            [
              -77.750406,
              35.817831
            ],
            [
              -77.751339,
              35.827794
            ],
            [
              -77.828444,
              35.867208
            ],
            [
              -77.835178,
              35.85497
            ],
            [
              -77.844737,
              35.837618
            ],
            [
              -77.873867,
              35.849731
            ],
            [
              -77.915657,
              35.83645
            ],
            [
              -77.931361,
              35.830078
            ],
            [
              -78.147102,
              35.745803
            ],
            [
              -78.181972,
              35.737956
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "061",
      "COUNTYNS": "01026132",
      "AFFGEOID": "0500000US37061",
      "GEOID": "37061",
      "NAME": "Duplin",
      "LSAD": "06",
      "ALAND": 2110160392,
      "AWATER": 14193280,
      "County_state": "Duplin,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.198642,
              34.741637
            ],
            [
              -78.150553,
              34.721964
            ],
            [
              -78.135194,
              34.710201
            ],
            [
              -78.114007,
              34.721795
            ],
            [
              -78.066197,
              34.727867
            ],
            [
              -78.030748,
              34.72481
            ],
            [
              -78.012708,
              34.732616
            ],
            [
              -77.992061,
              34.715999
            ],
            [
              -77.973882,
              34.719162
            ],
            [
              -77.958813,
              34.715863
            ],
            [
              -77.938001,
              34.723227
            ],
            [
              -77.921538,
              34.719746
            ],
            [
              -77.680127,
              34.720595
            ],
            [
              -77.678882,
              34.78455
            ],
            [
              -77.66606,
              34.839651
            ],
            [
              -77.651045,
              34.923341
            ],
            [
              -77.653352,
              34.940535
            ],
            [
              -77.674805,
              34.9739
            ],
            [
              -77.731026,
              35.008138
            ],
            [
              -77.746037,
              35.02234
            ],
            [
              -77.749572,
              35.10861
            ],
            [
              -77.769177,
              35.145576
            ],
            [
              -77.834249,
              35.177846
            ],
            [
              -77.883732,
              35.162353
            ],
            [
              -77.892199,
              35.146315
            ],
            [
              -77.929738,
              35.173568
            ],
            [
              -77.970598,
              35.166115
            ],
            [
              -77.983939,
              35.183438
            ],
            [
              -78.021001,
              35.193918
            ],
            [
              -78.044451,
              35.192535
            ],
            [
              -78.077389,
              35.175135
            ],
            [
              -78.16342,
              35.189716
            ],
            [
              -78.163013,
              35.137266
            ],
            [
              -78.165146,
              35.131015
            ],
            [
              -78.171845,
              35.093528
            ],
            [
              -78.159818,
              35.0798
            ],
            [
              -78.147129,
              34.902506
            ],
            [
              -78.198642,
              34.741637
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "117",
      "COUNTYNS": "01026328",
      "AFFGEOID": "0500000US37117",
      "GEOID": "37117",
      "NAME": "Martin",
      "LSAD": "06",
      "ALAND": 1182135018,
      "AWATER": 746610,
      "County_state": "Martin,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.402614,
              36.004966
            ],
            [
              -77.342418,
              35.905262
            ],
            [
              -77.349433,
              35.867414
            ],
            [
              -77.350369,
              35.819228
            ],
            [
              -77.314101,
              35.806694
            ],
            [
              -77.2552,
              35.786352
            ],
            [
              -77.259792,
              35.765222
            ],
            [
              -77.216849,
              35.760243
            ],
            [
              -77.174,
              35.732831
            ],
            [
              -77.16384,
              35.739473
            ],
            [
              -77.034646,
              35.678952
            ],
            [
              -76.985842,
              35.658848
            ],
            [
              -76.847264,
              35.719982
            ],
            [
              -76.831328,
              35.730702
            ],
            [
              -76.817037,
              35.748834
            ],
            [
              -76.804288,
              35.781952
            ],
            [
              -76.792961,
              35.789905
            ],
            [
              -76.802396,
              35.804837
            ],
            [
              -76.782007,
              35.82698
            ],
            [
              -76.789238,
              35.838108
            ],
            [
              -76.777404,
              35.857839
            ],
            [
              -76.760598,
              35.864887
            ],
            [
              -76.763166,
              35.864515
            ],
            [
              -76.770161,
              35.863043
            ],
            [
              -76.779417,
              35.863541
            ],
            [
              -76.790151,
              35.869159
            ],
            [
              -76.79833,
              35.87573
            ],
            [
              -76.813852,
              35.885663
            ],
            [
              -76.821234,
              35.886971
            ],
            [
              -76.834474,
              35.883211
            ],
            [
              -76.835724,
              35.871345
            ],
            [
              -76.832675,
              35.853248
            ],
            [
              -76.841085,
              35.843362
            ],
            [
              -76.850027,
              35.835705
            ],
            [
              -76.857748,
              35.827331
            ],
            [
              -76.872102,
              35.815938
            ],
            [
              -76.884973,
              35.812106
            ],
            [
              -76.892824,
              35.812954
            ],
            [
              -76.895421,
              35.816832
            ],
            [
              -76.896768,
              35.819821
            ],
            [
              -76.902592,
              35.822641
            ],
            [
              -76.899319,
              35.826462
            ],
            [
              -76.896204,
              35.827905
            ],
            [
              -76.897919,
              35.834429
            ],
            [
              -76.903162,
              35.836631
            ],
            [
              -76.902152,
              35.843779
            ],
            [
              -76.897621,
              35.848883
            ],
            [
              -76.896244,
              35.860466
            ],
            [
              -76.897693,
              35.866495
            ],
            [
              -76.907623,
              35.879043
            ],
            [
              -76.91593,
              35.89264
            ],
            [
              -76.930748,
              35.900161
            ],
            [
              -76.943698,
              35.899125
            ],
            [
              -76.947969,
              35.892895
            ],
            [
              -76.955336,
              35.887965
            ],
            [
              -76.962822,
              35.882428
            ],
            [
              -76.975934,
              35.87804
            ],
            [
              -76.982391,
              35.874292
            ],
            [
              -76.987949,
              35.870337
            ],
            [
              -76.997645,
              35.863411
            ],
            [
              -77.003339,
              35.859474
            ],
            [
              -77.008071,
              35.860584
            ],
            [
              -77.014075,
              35.862576
            ],
            [
              -77.027399,
              35.858378
            ],
            [
              -77.037833,
              35.858572
            ],
            [
              -77.04246,
              35.860796
            ],
            [
              -77.042731,
              35.864191
            ],
            [
              -77.040962,
              35.86589
            ],
            [
              -77.041299,
              35.870363
            ],
            [
              -77.042822,
              35.871773
            ],
            [
              -77.045099,
              35.873442
            ],
            [
              -77.042282,
              35.875159
            ],
            [
              -77.034959,
              35.878557
            ],
            [
              -77.034639,
              35.884617
            ],
            [
              -77.036941,
              35.887827
            ],
            [
              -77.033129,
              35.892011
            ],
            [
              -77.02739,
              35.892542
            ],
            [
              -77.025738,
              35.891012
            ],
            [
              -77.02428,
              35.893112
            ],
            [
              -77.025254,
              35.902776
            ],
            [
              -77.028818,
              35.90728
            ],
            [
              -77.029117,
              35.917833
            ],
            [
              -77.028935,
              35.924204
            ],
            [
              -77.034626,
              35.929398
            ],
            [
              -77.04221,
              35.939196
            ],
            [
              -77.047011,
              35.944262
            ],
            [
              -77.052939,
              35.944182
            ],
            [
              -77.055752,
              35.94162
            ],
            [
              -77.064212,
              35.939643
            ],
            [
              -77.067581,
              35.942338
            ],
            [
              -77.06907,
              35.940328
            ],
            [
              -77.066747,
              35.937188
            ],
            [
              -77.073494,
              35.934512
            ],
            [
              -77.077355,
              35.935767
            ],
            [
              -77.081032,
              35.934886
            ],
            [
              -77.08601,
              35.93215
            ],
            [
              -77.089548,
              35.928814
            ],
            [
              -77.095798,
              35.927089
            ],
            [
              -77.098885,
              35.929525
            ],
            [
              -77.107564,
              35.931526
            ],
            [
              -77.112629,
              35.928374
            ],
            [
              -77.118432,
              35.927312
            ],
            [
              -77.125301,
              35.927067
            ],
            [
              -77.127027,
              35.931282
            ],
            [
              -77.127809,
              35.935991
            ],
            [
              -77.129507,
              35.939718
            ],
            [
              -77.134461,
              35.938686
            ],
            [
              -77.137807,
              35.935502
            ],
            [
              -77.143768,
              35.935043
            ],
            [
              -77.148119,
              35.936162
            ],
            [
              -77.156888,
              35.936931
            ],
            [
              -77.165408,
              35.93699
            ],
            [
              -77.168662,
              35.933196
            ],
            [
              -77.172479,
              35.929082
            ],
            [
              -77.179802,
              35.929474
            ],
            [
              -77.188771,
              35.932361
            ],
            [
              -77.196521,
              35.93734
            ],
            [
              -77.19996,
              35.943014
            ],
            [
              -77.201126,
              35.946279
            ],
            [
              -77.202029,
              35.948843
            ],
            [
              -77.198999,
              35.950659
            ],
            [
              -77.197416,
              35.949803
            ],
            [
              -77.196618,
              35.952938
            ],
            [
              -77.198578,
              35.954562
            ],
            [
              -77.196315,
              35.957693
            ],
            [
              -77.193075,
              35.957387
            ],
            [
              -77.19076,
              35.956698
            ],
            [
              -77.191435,
              35.959084
            ],
            [
              -77.195051,
              35.960167
            ],
            [
              -77.194774,
              35.96187
            ],
            [
              -77.192977,
              35.960612
            ],
            [
              -77.193046,
              35.96236
            ],
            [
              -77.195267,
              35.964135
            ],
            [
              -77.195294,
              35.96903
            ],
            [
              -77.19316,
              35.970188
            ],
            [
              -77.191645,
              35.97445
            ],
            [
              -77.193501,
              35.975828
            ],
            [
              -77.195944,
              35.981014
            ],
            [
              -77.193025,
              35.984553
            ],
            [
              -77.195475,
              35.989802
            ],
            [
              -77.200435,
              35.993083
            ],
            [
              -77.2091,
              35.991136
            ],
            [
              -77.213215,
              35.992268
            ],
            [
              -77.212668,
              35.995102
            ],
            [
              -77.210157,
              35.997906
            ],
            [
              -77.207461,
              36.002133
            ],
            [
              -77.20989,
              36.009134
            ],
            [
              -77.214447,
              36.013428
            ],
            [
              -77.222303,
              36.015181
            ],
            [
              -77.233441,
              36.01714
            ],
            [
              -77.244615,
              36.022639
            ],
            [
              -77.257555,
              36.026834
            ],
            [
              -77.267836,
              36.031266
            ],
            [
              -77.282424,
              36.038842
            ],
            [
              -77.29279,
              36.045085
            ],
            [
              -77.301005,
              36.048463
            ],
            [
              -77.308034,
              36.051222
            ],
            [
              -77.314725,
              36.053817
            ],
            [
              -77.31671,
              36.054596
            ],
            [
              -77.320431,
              36.056317
            ],
            [
              -77.323356,
              36.059672
            ],
            [
              -77.324958,
              36.063787
            ],
            [
              -77.32649,
              36.066969
            ],
            [
              -77.327506,
              36.070405
            ],
            [
              -77.327569,
              36.071361
            ],
            [
              -77.339809,
              36.066184
            ],
            [
              -77.402614,
              36.004966
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "183",
      "COUNTYNS": "01008592",
      "AFFGEOID": "0500000US37183",
      "GEOID": "37183",
      "NAME": "Wake",
      "LSAD": "06",
      "ALAND": 2162148714,
      "AWATER": 58100780,
      "County_state": "Wake,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.995059,
              35.610135
            ],
            [
              -78.938888,
              35.592349
            ],
            [
              -78.916911,
              35.5854
            ],
            [
              -78.914734,
              35.583672
            ],
            [
              -78.779013,
              35.540659
            ],
            [
              -78.744302,
              35.529744
            ],
            [
              -78.708345,
              35.519296
            ],
            [
              -78.708893,
              35.519474
            ],
            [
              -78.642702,
              35.570065
            ],
            [
              -78.597924,
              35.604676
            ],
            [
              -78.566639,
              35.629002
            ],
            [
              -78.560497,
              35.633772
            ],
            [
              -78.473008,
              35.701917
            ],
            [
              -78.468503,
              35.707072
            ],
            [
              -78.460106,
              35.705657
            ],
            [
              -78.256162,
              35.818002
            ],
            [
              -78.255973,
              35.81812
            ],
            [
              -78.253753,
              35.827609
            ],
            [
              -78.269824,
              35.84499
            ],
            [
              -78.284316,
              35.874578
            ],
            [
              -78.307409,
              35.896738
            ],
            [
              -78.319631,
              35.894529
            ],
            [
              -78.351206,
              35.910845
            ],
            [
              -78.353277,
              35.931056
            ],
            [
              -78.394331,
              35.936876
            ],
            [
              -78.426864,
              35.975012
            ],
            [
              -78.486656,
              35.999873
            ],
            [
              -78.546414,
              36.021826
            ],
            [
              -78.683224,
              36.074484
            ],
            [
              -78.718788,
              36.075918
            ],
            [
              -78.751273,
              36.070834
            ],
            [
              -78.754284,
              36.056338
            ],
            [
              -78.754026,
              36.047812
            ],
            [
              -78.740187,
              36.023561
            ],
            [
              -78.719951,
              36.02959
            ],
            [
              -78.699316,
              36.011448
            ],
            [
              -78.717014,
              35.961265
            ],
            [
              -78.75821,
              35.921558
            ],
            [
              -78.760406,
              35.918655
            ],
            [
              -78.801972,
              35.926972
            ],
            [
              -78.804836,
              35.927552
            ],
            [
              -78.806756,
              35.922925
            ],
            [
              -78.829965,
              35.866978
            ],
            [
              -78.870298,
              35.86756
            ],
            [
              -78.890277,
              35.867872
            ],
            [
              -78.906097,
              35.867918
            ],
            [
              -78.909425,
              35.842937
            ],
            [
              -78.930891,
              35.794377
            ],
            [
              -78.936271,
              35.771898
            ],
            [
              -78.995059,
              35.610135
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "105",
      "COUNTYNS": "01008567",
      "AFFGEOID": "0500000US37105",
      "GEOID": "37105",
      "NAME": "Lee",
      "LSAD": "06",
      "ALAND": 660580685,
      "AWATER": 10752017,
      "County_state": "Lee,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.335878,
              35.497973
            ],
            [
              -79.359765,
              35.469675
            ],
            [
              -79.329563,
              35.43979
            ],
            [
              -79.328888,
              35.423105
            ],
            [
              -79.289561,
              35.400439
            ],
            [
              -79.265476,
              35.345307
            ],
            [
              -79.183556,
              35.307161
            ],
            [
              -78.96964,
              35.521669
            ],
            [
              -78.995638,
              35.542119
            ],
            [
              -79.0166,
              35.544268
            ],
            [
              -79.047027,
              35.570458
            ],
            [
              -79.05249,
              35.596075
            ],
            [
              -79.077747,
              35.614037
            ],
            [
              -79.118771,
              35.628323
            ],
            [
              -79.148496,
              35.618749
            ],
            [
              -79.171235,
              35.593896
            ],
            [
              -79.177698,
              35.588909
            ],
            [
              -79.180234,
              35.586446
            ],
            [
              -79.184446,
              35.58328
            ],
            [
              -79.185507,
              35.58099
            ],
            [
              -79.186772,
              35.579896
            ],
            [
              -79.194195,
              35.578398
            ],
            [
              -79.201864,
              35.57414
            ],
            [
              -79.199886,
              35.558558
            ],
            [
              -79.225634,
              35.551745
            ],
            [
              -79.21874,
              35.565675
            ],
            [
              -79.244663,
              35.569116
            ],
            [
              -79.245199,
              35.552961
            ],
            [
              -79.262705,
              35.535728
            ],
            [
              -79.275541,
              35.531779
            ],
            [
              -79.291759,
              35.543349
            ],
            [
              -79.285175,
              35.555148
            ],
            [
              -79.323046,
              35.544419
            ],
            [
              -79.311234,
              35.532733
            ],
            [
              -79.321927,
              35.524638
            ],
            [
              -79.350073,
              35.518002
            ],
            [
              -79.335878,
              35.497973
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "015",
      "COUNTYNS": "01026334",
      "AFFGEOID": "0500000US37015",
      "GEOID": "37015",
      "NAME": "Bertie",
      "LSAD": "06",
      "ALAND": 1810858584,
      "AWATER": 109088135,
      "County_state": "Bertie,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.327569,
              36.071361
            ],
            [
              -77.327506,
              36.070405
            ],
            [
              -77.32649,
              36.066969
            ],
            [
              -77.324958,
              36.063787
            ],
            [
              -77.323356,
              36.059672
            ],
            [
              -77.320431,
              36.056317
            ],
            [
              -77.31671,
              36.054596
            ],
            [
              -77.314725,
              36.053817
            ],
            [
              -77.308034,
              36.051222
            ],
            [
              -77.301005,
              36.048463
            ],
            [
              -77.29279,
              36.045085
            ],
            [
              -77.282424,
              36.038842
            ],
            [
              -77.267836,
              36.031266
            ],
            [
              -77.257555,
              36.026834
            ],
            [
              -77.244615,
              36.022639
            ],
            [
              -77.233441,
              36.01714
            ],
            [
              -77.222303,
              36.015181
            ],
            [
              -77.214447,
              36.013428
            ],
            [
              -77.20989,
              36.009134
            ],
            [
              -77.207461,
              36.002133
            ],
            [
              -77.210157,
              35.997906
            ],
            [
              -77.212668,
              35.995102
            ],
            [
              -77.213215,
              35.992268
            ],
            [
              -77.2091,
              35.991136
            ],
            [
              -77.200435,
              35.993083
            ],
            [
              -77.195475,
              35.989802
            ],
            [
              -77.193025,
              35.984553
            ],
            [
              -77.195944,
              35.981014
            ],
            [
              -77.193501,
              35.975828
            ],
            [
              -77.191645,
              35.97445
            ],
            [
              -77.19316,
              35.970188
            ],
            [
              -77.195294,
              35.96903
            ],
            [
              -77.195267,
              35.964135
            ],
            [
              -77.193046,
              35.96236
            ],
            [
              -77.192977,
              35.960612
            ],
            [
              -77.194774,
              35.96187
            ],
            [
              -77.195051,
              35.960167
            ],
            [
              -77.191435,
              35.959084
            ],
            [
              -77.19076,
              35.956698
            ],
            [
              -77.193075,
              35.957387
            ],
            [
              -77.196315,
              35.957693
            ],
            [
              -77.198578,
              35.954562
            ],
            [
              -77.196618,
              35.952938
            ],
            [
              -77.197416,
              35.949803
            ],
            [
              -77.198999,
              35.950659
            ],
            [
              -77.202029,
              35.948843
            ],
            [
              -77.201126,
              35.946279
            ],
            [
              -77.19996,
              35.943014
            ],
            [
              -77.196521,
              35.93734
            ],
            [
              -77.188771,
              35.932361
            ],
            [
              -77.179802,
              35.929474
            ],
            [
              -77.172479,
              35.929082
            ],
            [
              -77.168662,
              35.933196
            ],
            [
              -77.165408,
              35.93699
            ],
            [
              -77.156888,
              35.936931
            ],
            [
              -77.148119,
              35.936162
            ],
            [
              -77.143768,
              35.935043
            ],
            [
              -77.137807,
              35.935502
            ],
            [
              -77.134461,
              35.938686
            ],
            [
              -77.129507,
              35.939718
            ],
            [
              -77.127809,
              35.935991
            ],
            [
              -77.127027,
              35.931282
            ],
            [
              -77.125301,
              35.927067
            ],
            [
              -77.118432,
              35.927312
            ],
            [
              -77.112629,
              35.928374
            ],
            [
              -77.107564,
              35.931526
            ],
            [
              -77.098885,
              35.929525
            ],
            [
              -77.095798,
              35.927089
            ],
            [
              -77.089548,
              35.928814
            ],
            [
              -77.08601,
              35.93215
            ],
            [
              -77.081032,
              35.934886
            ],
            [
              -77.077355,
              35.935767
            ],
            [
              -77.073494,
              35.934512
            ],
            [
              -77.066747,
              35.937188
            ],
            [
              -77.06907,
              35.940328
            ],
            [
              -77.067581,
              35.942338
            ],
            [
              -77.064212,
              35.939643
            ],
            [
              -77.055752,
              35.94162
            ],
            [
              -77.052939,
              35.944182
            ],
            [
              -77.047011,
              35.944262
            ],
            [
              -77.04221,
              35.939196
            ],
            [
              -77.034626,
              35.929398
            ],
            [
              -77.028935,
              35.924204
            ],
            [
              -77.029117,
              35.917833
            ],
            [
              -77.028818,
              35.90728
            ],
            [
              -77.025254,
              35.902776
            ],
            [
              -77.02428,
              35.893112
            ],
            [
              -77.025738,
              35.891012
            ],
            [
              -77.02739,
              35.892542
            ],
            [
              -77.033129,
              35.892011
            ],
            [
              -77.036941,
              35.887827
            ],
            [
              -77.034639,
              35.884617
            ],
            [
              -77.034959,
              35.878557
            ],
            [
              -77.042282,
              35.875159
            ],
            [
              -77.045099,
              35.873442
            ],
            [
              -77.042822,
              35.871773
            ],
            [
              -77.041299,
              35.870363
            ],
            [
              -77.040962,
              35.86589
            ],
            [
              -77.042731,
              35.864191
            ],
            [
              -77.04246,
              35.860796
            ],
            [
              -77.037833,
              35.858572
            ],
            [
              -77.027399,
              35.858378
            ],
            [
              -77.014075,
              35.862576
            ],
            [
              -77.008071,
              35.860584
            ],
            [
              -77.003339,
              35.859474
            ],
            [
              -76.997645,
              35.863411
            ],
            [
              -76.987949,
              35.870337
            ],
            [
              -76.982391,
              35.874292
            ],
            [
              -76.975934,
              35.87804
            ],
            [
              -76.962822,
              35.882428
            ],
            [
              -76.955336,
              35.887965
            ],
            [
              -76.947969,
              35.892895
            ],
            [
              -76.943698,
              35.899125
            ],
            [
              -76.930748,
              35.900161
            ],
            [
              -76.91593,
              35.89264
            ],
            [
              -76.907623,
              35.879043
            ],
            [
              -76.897693,
              35.866495
            ],
            [
              -76.896244,
              35.860466
            ],
            [
              -76.897621,
              35.848883
            ],
            [
              -76.902152,
              35.843779
            ],
            [
              -76.903162,
              35.836631
            ],
            [
              -76.897919,
              35.834429
            ],
            [
              -76.896204,
              35.827905
            ],
            [
              -76.899319,
              35.826462
            ],
            [
              -76.902592,
              35.822641
            ],
            [
              -76.896768,
              35.819821
            ],
            [
              -76.895421,
              35.816832
            ],
            [
              -76.892824,
              35.812954
            ],
            [
              -76.884973,
              35.812106
            ],
            [
              -76.872102,
              35.815938
            ],
            [
              -76.857748,
              35.827331
            ],
            [
              -76.850027,
              35.835705
            ],
            [
              -76.841085,
              35.843362
            ],
            [
              -76.832675,
              35.853248
            ],
            [
              -76.835724,
              35.871345
            ],
            [
              -76.834474,
              35.883211
            ],
            [
              -76.821234,
              35.886971
            ],
            [
              -76.813852,
              35.885663
            ],
            [
              -76.79833,
              35.87573
            ],
            [
              -76.790151,
              35.869159
            ],
            [
              -76.779417,
              35.863541
            ],
            [
              -76.770161,
              35.863043
            ],
            [
              -76.763166,
              35.864515
            ],
            [
              -76.760598,
              35.864887
            ],
            [
              -76.745411,
              35.870829
            ],
            [
              -76.731543,
              35.887575
            ],
            [
              -76.719989,
              35.917259
            ],
            [
              -76.701832,
              35.922783
            ],
            [
              -76.6919133712548,
              35.9447535472236
            ],
            [
              -76.697558,
              35.951937
            ],
            [
              -76.70019,
              35.964573
            ],
            [
              -76.695452,
              35.973524
            ],
            [
              -76.684922,
              35.983001
            ],
            [
              -76.679657,
              35.991951
            ],
            [
              -76.683869,
              36.000375
            ],
            [
              -76.722996,
              36.066585
            ],
            [
              -76.7521,
              36.147328
            ],
            [
              -76.744436,
              36.212725
            ],
            [
              -76.7201982142909,
              36.2438051311307
            ],
            [
              -76.903383,
              36.242986
            ],
            [
              -76.971399,
              36.23813
            ],
            [
              -76.986159,
              36.242074
            ],
            [
              -77.008384,
              36.239113
            ],
            [
              -77.183144,
              36.242825
            ],
            [
              -77.208468,
              36.246578
            ],
            [
              -77.228799,
              36.217387
            ],
            [
              -77.250772,
              36.218607
            ],
            [
              -77.29825,
              36.207708
            ],
            [
              -77.299717,
              36.181409
            ],
            [
              -77.291519,
              36.168342
            ],
            [
              -77.264469,
              36.159331
            ],
            [
              -77.26455,
              36.139054
            ],
            [
              -77.252166,
              36.136003
            ],
            [
              -77.249485,
              36.113764
            ],
            [
              -77.230275,
              36.104999
            ],
            [
              -77.313225,
              36.089167
            ],
            [
              -77.327569,
              36.071361
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "159",
      "COUNTYNS": "01008583",
      "AFFGEOID": "0500000US37159",
      "GEOID": "37159",
      "NAME": "Rowan",
      "LSAD": "06",
      "ALAND": 1324722118,
      "AWATER": 32235596,
      "County_state": "Rowan,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.771582,
              35.672163
            ],
            [
              -80.757684,
              35.616239
            ],
            [
              -80.743617,
              35.559549
            ],
            [
              -80.736909,
              35.525428
            ],
            [
              -80.737379,
              35.505804
            ],
            [
              -80.688275,
              35.505424
            ],
            [
              -80.675478,
              35.50651
            ],
            [
              -80.645898,
              35.505843
            ],
            [
              -80.609201,
              35.504748
            ],
            [
              -80.570602,
              35.503318
            ],
            [
              -80.295421,
              35.50292
            ],
            [
              -80.182559,
              35.504151
            ],
            [
              -80.209794,
              35.552263
            ],
            [
              -80.208563,
              35.580609
            ],
            [
              -80.23109,
              35.59601
            ],
            [
              -80.250366,
              35.619388
            ],
            [
              -80.267129,
              35.630732
            ],
            [
              -80.284345,
              35.633588
            ],
            [
              -80.308321,
              35.666901
            ],
            [
              -80.330602,
              35.678799
            ],
            [
              -80.325572,
              35.70282
            ],
            [
              -80.334509,
              35.720471
            ],
            [
              -80.373118,
              35.716636
            ],
            [
              -80.391649,
              35.722273
            ],
            [
              -80.394389,
              35.724856
            ],
            [
              -80.423226,
              35.725864
            ],
            [
              -80.454635,
              35.73537
            ],
            [
              -80.458884,
              35.743031
            ],
            [
              -80.47182,
              35.768356
            ],
            [
              -80.483581,
              35.776397
            ],
            [
              -80.487831,
              35.766607
            ],
            [
              -80.496573,
              35.782747
            ],
            [
              -80.508634,
              35.774707
            ],
            [
              -80.534128,
              35.78626
            ],
            [
              -80.570382,
              35.816296
            ],
            [
              -80.598786,
              35.827512
            ],
            [
              -80.609195,
              35.823155
            ],
            [
              -80.632724,
              35.845854
            ],
            [
              -80.671621,
              35.846746
            ],
            [
              -80.668511,
              35.855479
            ],
            [
              -80.691809,
              35.863508
            ],
            [
              -80.693243,
              35.853688
            ],
            [
              -80.707826,
              35.852929
            ],
            [
              -80.744691,
              35.748502
            ],
            [
              -80.771582,
              35.672163
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "001",
      "COUNTYNS": "01008531",
      "AFFGEOID": "0500000US37001",
      "GEOID": "37001",
      "NAME": "Alamance",
      "LSAD": "06",
      "ALAND": 1096654286,
      "AWATER": 28025249,
      "County_state": "Alamance,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.541728,
              35.899851
            ],
            [
              -79.542428,
              35.843303
            ],
            [
              -79.237295,
              35.844247
            ],
            [
              -79.250745,
              35.858679
            ],
            [
              -79.249538,
              35.87681
            ],
            [
              -79.257629,
              35.903039
            ],
            [
              -79.268243,
              35.909182
            ],
            [
              -79.264027,
              35.914114
            ],
            [
              -79.261379,
              36.071067
            ],
            [
              -79.257952,
              36.243445
            ],
            [
              -79.365367,
              36.245887
            ],
            [
              -79.531865,
              36.249673
            ],
            [
              -79.53241,
              36.241463
            ],
            [
              -79.541728,
              35.899851
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "123",
      "COUNTYNS": "01008572",
      "AFFGEOID": "0500000US37123",
      "GEOID": "37123",
      "NAME": "Montgomery",
      "LSAD": "06",
      "ALAND": 1273046508,
      "AWATER": 25919921,
      "County_state": "Montgomery,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.182559,
              35.504151
            ],
            [
              -80.176243,
              35.485428
            ],
            [
              -80.161736,
              35.475208
            ],
            [
              -80.139921,
              35.475055
            ],
            [
              -80.108495,
              35.459451
            ],
            [
              -80.100491,
              35.429903
            ],
            [
              -80.090119,
              35.415552
            ],
            [
              -80.093711,
              35.40203
            ],
            [
              -80.062271,
              35.386134
            ],
            [
              -80.051225,
              35.371537
            ],
            [
              -80.066371,
              35.357145
            ],
            [
              -80.065873,
              35.343557
            ],
            [
              -80.081938,
              35.321457
            ],
            [
              -80.080483,
              35.307623
            ],
            [
              -80.094142,
              35.28658
            ],
            [
              -80.092241,
              35.271987
            ],
            [
              -80.098185,
              35.255173
            ],
            [
              -80.090801,
              35.235989
            ],
            [
              -80.078036,
              35.228711
            ],
            [
              -80.062188,
              35.201331
            ],
            [
              -80.053064,
              35.172625
            ],
            [
              -80.075365,
              35.143081
            ],
            [
              -80.022232,
              35.137378
            ],
            [
              -79.983399,
              35.15851
            ],
            [
              -79.949573,
              35.157502
            ],
            [
              -79.944271,
              35.164169
            ],
            [
              -79.89861,
              35.161714
            ],
            [
              -79.851268,
              35.183855
            ],
            [
              -79.836139,
              35.177232
            ],
            [
              -79.812862,
              35.1799
            ],
            [
              -79.792011,
              35.175952
            ],
            [
              -79.768378,
              35.177174
            ],
            [
              -79.749463,
              35.172999
            ],
            [
              -79.733198,
              35.178176
            ],
            [
              -79.712721,
              35.173168
            ],
            [
              -79.694365,
              35.179642
            ],
            [
              -79.673494,
              35.175374
            ],
            [
              -79.642129,
              35.157717
            ],
            [
              -79.614304,
              35.163679
            ],
            [
              -79.655067,
              35.189783
            ],
            [
              -79.673005,
              35.245396
            ],
            [
              -79.704978,
              35.263308
            ],
            [
              -79.711623,
              35.275231
            ],
            [
              -79.709913,
              35.301275
            ],
            [
              -79.75173,
              35.443994
            ],
            [
              -79.764192,
              35.475735
            ],
            [
              -79.767957,
              35.511484
            ],
            [
              -80.066842,
              35.505661
            ],
            [
              -80.182559,
              35.504151
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "179",
      "COUNTYNS": "01008590",
      "AFFGEOID": "0500000US37179",
      "GEOID": "37179",
      "NAME": "Union",
      "LSAD": "06",
      "ALAND": 1636332950,
      "AWATER": 20337550,
      "County_state": "Union,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.8403843929928,
              35.00163702423549
            ],
            [
              -80.82156,
              34.979695
            ],
            [
              -80.806784,
              34.963249
            ],
            [
              -80.806461,
              34.962894
            ],
            [
              -80.782042,
              34.935782
            ],
            [
              -80.786517350476,
              34.902292048549896
            ],
            [
              -80.795109,
              34.837999
            ],
            [
              -80.796997,
              34.823874
            ],
            [
              -80.797543,
              34.819786
            ],
            [
              -80.777712,
              34.819697
            ],
            [
              -80.771792,
              34.819646
            ],
            [
              -80.72694647607639,
              34.8192681888075
            ],
            [
              -80.684074,
              34.818907
            ],
            [
              -80.6710408026229,
              34.8187974422605
            ],
            [
              -80.646601,
              34.818592
            ],
            [
              -80.644656,
              34.818568
            ],
            [
              -80.626077,
              34.818217
            ],
            [
              -80.625993,
              34.818239
            ],
            [
              -80.621222,
              34.818174
            ],
            [
              -80.56170938792269,
              34.8174816094994
            ],
            [
              -80.561657,
              34.817481
            ],
            [
              -80.499788,
              34.817261
            ],
            [
              -80.491814,
              34.816798
            ],
            [
              -80.485234,
              34.816732
            ],
            [
              -80.45166,
              34.816396
            ],
            [
              -80.448766,
              34.816332
            ],
            [
              -80.434843,
              34.815968
            ],
            [
              -80.425902,
              34.81581
            ],
            [
              -80.419586,
              34.815581
            ],
            [
              -80.418433,
              34.815622
            ],
            [
              -80.417014,
              34.815508
            ],
            [
              -80.399871,
              34.815128
            ],
            [
              -80.350068,
              34.814469
            ],
            [
              -80.3208073604558,
              34.814081463212
            ],
            [
              -80.276829,
              35.195722
            ],
            [
              -80.296702,
              35.193379
            ],
            [
              -80.322125,
              35.173038
            ],
            [
              -80.356982,
              35.16494
            ],
            [
              -80.368574,
              35.179077
            ],
            [
              -80.388328,
              35.165862
            ],
            [
              -80.428192,
              35.167578
            ],
            [
              -80.439865,
              35.161042
            ],
            [
              -80.468186,
              35.166694
            ],
            [
              -80.481141,
              35.180075
            ],
            [
              -80.504985,
              35.184998
            ],
            [
              -80.550349,
              35.208412
            ],
            [
              -80.599735,
              35.168645
            ],
            [
              -80.65418,
              35.125041
            ],
            [
              -80.721657,
              35.071559
            ],
            [
              -80.736466,
              35.060113
            ],
            [
              -80.767555,
              35.036286
            ],
            [
              -80.804506,
              35.027749
            ],
            [
              -80.811156,
              35.02148
            ],
            [
              -80.82101,
              35.017326
            ],
            [
              -80.8403843929928,
              35.00163702423549
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "029",
      "COUNTYNS": "01026335",
      "AFFGEOID": "0500000US37029",
      "GEOID": "37029",
      "NAME": "Camden",
      "LSAD": "06",
      "ALAND": 622448679,
      "AWATER": 181112904,
      "County_state": "Camden,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.491482764957,
              36.5507317975136
            ],
            [
              -76.5415364676522,
              36.55031325872059
            ],
            [
              -76.491336,
              36.510677
            ],
            [
              -76.437286,
              36.467846
            ],
            [
              -76.410655,
              36.456332
            ],
            [
              -76.394749,
              36.454549
            ],
            [
              -76.367354,
              36.439353
            ],
            [
              -76.367429,
              36.431222
            ],
            [
              -76.34741,
              36.428166
            ],
            [
              -76.345569,
              36.421402
            ],
            [
              -76.322716,
              36.427564
            ],
            [
              -76.323078,
              36.417389
            ],
            [
              -76.302236,
              36.400874
            ],
            [
              -76.291542,
              36.386629
            ],
            [
              -76.258891,
              36.384274
            ],
            [
              -76.254704,
              36.362827
            ],
            [
              -76.238244,
              36.371588
            ],
            [
              -76.225115,
              36.34643
            ],
            [
              -76.214178,
              36.341599
            ],
            [
              -76.213635,
              36.326859
            ],
            [
              -76.192207,
              36.32814
            ],
            [
              -76.181749,
              36.32271
            ],
            [
              -76.198369,
              36.317805
            ],
            [
              -76.202061,
              36.304709
            ],
            [
              -76.217453,
              36.299374
            ],
            [
              -76.18595055015649,
              36.292903120087296
            ],
            [
              -76.184702,
              36.298166
            ],
            [
              -76.161384,
              36.291028
            ],
            [
              -76.132005,
              36.287773
            ],
            [
              -76.08148,
              36.237935
            ],
            [
              -76.054308,
              36.229162
            ],
            [
              -76.043838,
              36.210126
            ],
            [
              -76.029086,
              36.202036
            ],
            [
              -76.018936,
              36.188318
            ],
            [
              -76.016984,
              36.186367
            ],
            [
              -75.995191,
              36.178072
            ],
            [
              -75.939047,
              36.165518
            ],
            [
              -75.924654,
              36.163591
            ],
            [
              -75.920028,
              36.164853
            ],
            [
              -75.904999,
              36.164188
            ],
            [
              -75.917188,
              36.172631
            ],
            [
              -75.936436,
              36.18088
            ],
            [
              -75.956027,
              36.198065
            ],
            [
              -75.945372,
              36.222468
            ],
            [
              -75.957058,
              36.247903
            ],
            [
              -75.96462,
              36.254433
            ],
            [
              -75.959381720968,
              36.2556511302458
            ],
            [
              -75.961058,
              36.268171
            ],
            [
              -75.949087,
              36.273844
            ],
            [
              -75.948426,
              36.285268
            ],
            [
              -75.964851,
              36.288443
            ],
            [
              -75.969625,
              36.306312
            ],
            [
              -75.994539,
              36.308794
            ],
            [
              -76.007914,
              36.328648
            ],
            [
              -76.020578,
              36.333379
            ],
            [
              -76.016707,
              36.344324
            ],
            [
              -76.030798,
              36.338501
            ],
            [
              -76.028607,
              36.348768
            ],
            [
              -76.050755,
              36.359819
            ],
            [
              -76.063689,
              36.347451
            ],
            [
              -76.078284,
              36.347064
            ],
            [
              -76.114361,
              36.357583
            ],
            [
              -76.12181,
              36.371088
            ],
            [
              -76.147626,
              36.394463
            ],
            [
              -76.157472,
              36.398178
            ],
            [
              -76.158532,
              36.425333
            ],
            [
              -76.3133029178411,
              36.5505512312821
            ],
            [
              -76.3766927385896,
              36.5507179884543
            ],
            [
              -76.465268,
              36.550951
            ],
            [
              -76.491482764957,
              36.5507317975136
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "37",
      "COUNTYFP": "013",
      "COUNTYNS": "01026333",
      "AFFGEOID": "0500000US37013",
      "GEOID": "37013",
      "NAME": "Beaufort",
      "LSAD": "06",
      "ALAND": 2154872483,
      "AWATER": 338892301,
      "County_state": "Beaufort,37"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.185706,
              35.719715
            ],
            [
              -77.195772,
              35.699779
            ],
            [
              -77.169849,
              35.684339
            ],
            [
              -77.167093,
              35.672622
            ],
            [
              -77.177453,
              35.669366
            ],
            [
              -77.172766,
              35.6391
            ],
            [
              -77.14239,
              35.61788
            ],
            [
              -77.140506,
              35.605301
            ],
            [
              -77.150823,
              35.602598
            ],
            [
              -77.121915,
              35.592197
            ],
            [
              -77.125507,
              35.584659
            ],
            [
              -77.109897,
              35.583123
            ],
            [
              -77.110453,
              35.56963
            ],
            [
              -77.087435,
              35.564119
            ],
            [
              -77.08472,
              35.55275
            ],
            [
              -77.100803,
              35.551455
            ],
            [
              -77.127254,
              35.553973
            ],
            [
              -77.150605,
              35.537287
            ],
            [
              -77.17621,
              35.5191
            ],
            [
              -77.173841,
              35.456596
            ],
            [
              -77.19123,
              35.435026
            ],
            [
              -77.189115,
              35.418362
            ],
            [
              -76.958887,
              35.301013
            ],
            [
              -76.895919,
              35.253889
            ],
            [
              -76.845505,
              35.216059
            ],
            [
              -76.632561,
              35.235324
            ],
            [
              -76.612186,
              35.27932
            ],
            [
              -76.61886,
              35.300143
            ],
            [
              -76.617496,
              35.328893
            ],
            [
              -76.6067536154207,
              35.3371383157563
            ],
            [
              -76.664027,
              35.345696
            ],
            [
              -76.891938,
              35.433649
            ],
            [
              -76.966661,
              35.43781
            ],
            [
              -76.9672136836292,
              35.4382961702918
            ],
            [
              -77.026638,
              35.490569
            ],
            [
              -77.02626033466959,
              35.495107632352095
            ],
            [
              -77.024593,
              35.515145
            ],
            [
              -77.0239111923103,
              35.5148013671408
            ],
            [
              -76.942022,
              35.473529
            ],
            [
              -76.830897,
              35.447949
            ],
            [
              -76.759234,
              35.418906
            ],
            [
              -76.710083,
              35.427155
            ],
            [
              -76.606041,
              35.387113
            ],
            [
              -76.580187,
              35.387113
            ],
            [
              -76.601472,
              35.460838
            ],
            [
              -76.634468,
              35.510332
            ],
            [
              -76.600441,
              35.538516
            ],
            [
              -76.55679,
              35.528892
            ],
            [
              -76.48358,
              35.538172
            ],
            [
              -76.471207,
              35.55742
            ],
            [
              -76.46776327064201,
              35.555818362949495
            ],
            [
              -76.506019,
              35.581014
            ],
            [
              -76.539011,
              35.582937
            ],
            [
              -76.534313,
              35.595594
            ],
            [
              -76.551061,
              35.596632
            ],
            [
              -76.577569,
              35.611243
            ],
            [
              -76.60052,
              35.635654
            ],
            [
              -76.606119,
              35.646733
            ],
            [
              -76.601982,
              35.670055
            ],
            [
              -76.637513,
              35.705215
            ],
            [
              -76.84462,
              35.705719
            ],
            [
              -76.847264,
              35.719982
            ],
            [
              -76.985842,
              35.658848
            ],
            [
              -77.034646,
              35.678952
            ],
            [
              -77.16384,
              35.739473
            ],
            [
              -77.174,
              35.732831
            ],
            [
              -77.170256,
              35.719045
            ],
            [
              -77.185706,
              35.719715
            ]
          ]
        ]
      ]
    }
  }
]
};