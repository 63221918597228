export const counties_or = {
  "type":"FeatureCollection","name":"or_counties","features":[
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "41",
      "COUNTYFP": "021",
      "COUNTYNS": "01135850",
      "AFFGEOID": "0500000US41021",
      "GEOID": "41021",
      "NAME": "Gilliam",
      "LSAD": "06",
      "ALAND": 3120213951,
      "AWATER": 46914718,
      "County_state": "Gilliam,41"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -120.65353434410001,
              45.7372486812962
            ],
            [
              -120.650208,
              45.733806
            ],
            [
              -120.649168,
              45.731087
            ],
            [
              -120.646068,
              45.723946
            ],
            [
              -120.632968,
              45.720846
            ],
            [
              -120.623267,
              45.721947
            ],
            [
              -120.610467,
              45.716247
            ],
            [
              -120.603466,
              45.706647
            ],
            [
              -120.591166,
              45.701548
            ],
            [
              -120.576165,
              45.697948
            ],
            [
              -120.567365,
              45.690148
            ],
            [
              -120.560065,
              45.682348
            ],
            [
              -120.551664,
              45.675048
            ],
            [
              -120.549373,
              45.670457
            ],
            [
              -120.540418,
              45.670741
            ],
            [
              -120.532162,
              45.671927
            ],
            [
              -120.52001,
              45.667251
            ],
            [
              -120.508224,
              45.663991
            ],
            [
              -120.506388,
              45.661701
            ],
            [
              -120.505078,
              45.660585
            ],
            [
              -120.502032,
              45.657906
            ],
            [
              -120.499047,
              45.656058
            ],
            [
              -120.493853,
              45.656147
            ],
            [
              -120.490482,
              45.65582
            ],
            [
              -120.489248,
              45.653134
            ],
            [
              -120.490801,
              45.651087
            ],
            [
              -120.492099,
              45.648191
            ],
            [
              -120.491518,
              45.645424
            ],
            [
              -120.489585,
              45.643312
            ],
            [
              -120.487183,
              45.644558
            ],
            [
              -120.481185,
              45.645134
            ],
            [
              -120.4789,
              45.64333
            ],
            [
              -120.478435,
              45.641049
            ],
            [
              -120.482367,
              45.639391
            ],
            [
              -120.485326,
              45.638269
            ],
            [
              -120.484468,
              45.634892
            ],
            [
              -120.481951,
              45.634339
            ],
            [
              -120.478343,
              45.631586
            ],
            [
              -120.476979,
              45.627468
            ],
            [
              -120.472435,
              45.626281
            ],
            [
              -120.470075,
              45.624015
            ],
            [
              -120.469588,
              45.621283
            ],
            [
              -120.464725,
              45.619673
            ],
            [
              -120.45931,
              45.621619
            ],
            [
              -120.450212,
              45.626427
            ],
            [
              -120.44422,
              45.627031
            ],
            [
              -120.439985,
              45.625561
            ],
            [
              -120.439493,
              45.624242
            ],
            [
              -120.440821,
              45.620771
            ],
            [
              -120.445185,
              45.617298
            ],
            [
              -120.445332,
              45.610564
            ],
            [
              -120.441686,
              45.605076
            ],
            [
              -120.435158,
              45.599947
            ],
            [
              -120.428886,
              45.597495
            ],
            [
              -120.421029,
              45.597548
            ],
            [
              -120.414231,
              45.596679
            ],
            [
              -120.410257,
              45.594812
            ],
            [
              -120.409233,
              45.590427
            ],
            [
              -120.408817,
              45.582563
            ],
            [
              -120.40536,
              45.576728
            ],
            [
              -120.402701,
              45.567956
            ],
            [
              -120.406099,
              45.56438
            ],
            [
              -120.407591,
              45.559367
            ],
            [
              -120.402107,
              45.555641
            ],
            [
              -120.396825,
              45.553009
            ],
            [
              -120.391839,
              45.550964
            ],
            [
              -120.388305,
              45.548723
            ],
            [
              -120.385843,
              45.544758
            ],
            [
              -120.383381,
              45.540374
            ],
            [
              -120.380033,
              45.538164
            ],
            [
              -120.37651,
              45.537527
            ],
            [
              -120.368936,
              45.537554
            ],
            [
              -120.360879,
              45.535785
            ],
            [
              -120.358185,
              45.53316
            ],
            [
              -120.361575,
              45.530524
            ],
            [
              -120.365972,
              45.531178
            ],
            [
              -120.372855,
              45.530061
            ],
            [
              -120.376156,
              45.52762
            ],
            [
              -120.380552,
              45.522706
            ],
            [
              -120.376304,
              45.518095
            ],
            [
              -120.373211,
              45.517136
            ],
            [
              -120.367013,
              45.515253
            ],
            [
              -120.361847,
              45.510945
            ],
            [
              -120.360138,
              45.506766
            ],
            [
              -120.362021,
              45.503318
            ],
            [
              -120.363892,
              45.499982
            ],
            [
              -120.364655,
              45.49795
            ],
            [
              -120.367155,
              45.49565
            ],
            [
              -120.372655,
              45.49395
            ],
            [
              -120.374955,
              45.49335
            ],
            [
              -120.380456,
              45.49395
            ],
            [
              -120.388256,
              45.49675
            ],
            [
              -120.398356,
              45.49695
            ],
            [
              -120.410557,
              45.49835
            ],
            [
              -120.420556,
              45.49985
            ],
            [
              -120.424556,
              45.49955
            ],
            [
              -120.426683,
              45.495296
            ],
            [
              -120.423557,
              45.49115
            ],
            [
              -120.421457,
              45.48295
            ],
            [
              -120.409856,
              45.47975
            ],
            [
              -120.407656,
              45.47495
            ],
            [
              -120.413802,
              45.469439
            ],
            [
              -120.421964,
              45.468362
            ],
            [
              -120.427187,
              45.47103
            ],
            [
              -120.435673,
              45.474062
            ],
            [
              -120.443688,
              45.480983
            ],
            [
              -120.447767,
              45.484456
            ],
            [
              -120.456017,
              45.4839
            ],
            [
              -120.458932,
              45.48121
            ],
            [
              -120.466152,
              45.477938
            ],
            [
              -120.470559,
              45.476749
            ],
            [
              -120.476659,
              45.473149
            ],
            [
              -120.491859,
              45.468149
            ],
            [
              -120.494559,
              45.463549
            ],
            [
              -120.490959,
              45.462249
            ],
            [
              -120.485659,
              45.461349
            ],
            [
              -120.483459,
              45.455349
            ],
            [
              -120.488959,
              45.453949
            ],
            [
              -120.495659,
              45.449649
            ],
            [
              -120.492159,
              45.443649
            ],
            [
              -120.485752,
              45.441474
            ],
            [
              -120.483358,
              45.436549
            ],
            [
              -120.490059,
              45.434349
            ],
            [
              -120.499759,
              45.434049
            ],
            [
              -120.504359,
              45.432449
            ],
            [
              -120.503759,
              45.427849
            ],
            [
              -120.500759,
              45.427949
            ],
            [
              -120.489058,
              45.426949
            ],
            [
              -120.485658,
              45.424249
            ],
            [
              -120.483158,
              45.420249
            ],
            [
              -120.485658,
              45.418449
            ],
            [
              -120.491658,
              45.417249
            ],
            [
              -120.494859,
              45.418349
            ],
            [
              -120.502659,
              45.417249
            ],
            [
              -120.504259,
              45.412749
            ],
            [
              -120.506259,
              45.407749
            ],
            [
              -120.514759,
              45.404349
            ],
            [
              -120.519859,
              45.405449
            ],
            [
              -120.527959,
              45.402749
            ],
            [
              -120.522559,
              45.397649
            ],
            [
              -120.522259,
              45.390149
            ],
            [
              -120.528159,
              45.386549
            ],
            [
              -120.527659,
              45.380849
            ],
            [
              -120.524259,
              45.381549
            ],
            [
              -120.517259,
              45.381349
            ],
            [
              -120.512758,
              45.377949
            ],
            [
              -120.519959,
              45.377149
            ],
            [
              -120.523659,
              45.374049
            ],
            [
              -120.528159,
              45.371849
            ],
            [
              -120.532659,
              45.371049
            ],
            [
              -120.532959,
              45.367049
            ],
            [
              -120.530859,
              45.364249
            ],
            [
              -120.533559,
              45.358449
            ],
            [
              -120.533859,
              45.352849
            ],
            [
              -120.539459,
              45.350249
            ],
            [
              -120.542759,
              45.347949
            ],
            [
              -120.539459,
              45.344949
            ],
            [
              -120.534359,
              45.344249
            ],
            [
              -120.530658,
              45.340349
            ],
            [
              -120.530858,
              45.335549
            ],
            [
              -120.530758,
              45.332949
            ],
            [
              -120.534958,
              45.331349
            ],
            [
              -120.538558,
              45.330849
            ],
            [
              -120.540858,
              45.329949
            ],
            [
              -120.537858,
              45.327849
            ],
            [
              -120.534158,
              45.328449
            ],
            [
              -120.527658,
              45.325449
            ],
            [
              -120.527758,
              45.320249
            ],
            [
              -120.534958,
              45.316649
            ],
            [
              -120.539558,
              45.315349
            ],
            [
              -120.540558,
              45.311149
            ],
            [
              -120.536558,
              45.309449
            ],
            [
              -120.529658,
              45.307949
            ],
            [
              -120.524958,
              45.305049
            ],
            [
              -120.524257,
              45.30085
            ],
            [
              -120.526357,
              45.29705
            ],
            [
              -120.529958,
              45.295349
            ],
            [
              -120.535558,
              45.295449
            ],
            [
              -120.537758,
              45.298049
            ],
            [
              -120.538258,
              45.301149
            ],
            [
              -120.540958,
              45.304449
            ],
            [
              -120.543758,
              45.303849
            ],
            [
              -120.546858,
              45.299849
            ],
            [
              -120.547458,
              45.296249
            ],
            [
              -120.544158,
              45.291049
            ],
            [
              -120.542758,
              45.288049
            ],
            [
              -120.545058,
              45.284449
            ],
            [
              -120.548358,
              45.284149
            ],
            [
              -120.552958,
              45.283349
            ],
            [
              -120.552858,
              45.279749
            ],
            [
              -120.546158,
              45.280649
            ],
            [
              -120.541758,
              45.279049
            ],
            [
              -120.540756,
              45.274033
            ],
            [
              -120.543857,
              45.270349
            ],
            [
              -120.547057,
              45.265249
            ],
            [
              -120.547057,
              45.262949
            ],
            [
              -120.542857,
              45.261649
            ],
            [
              -120.538957,
              45.262449
            ],
            [
              -120.534857,
              45.26095
            ],
            [
              -120.533757,
              45.25745
            ],
            [
              -120.535757,
              45.25495
            ],
            [
              -120.541357,
              45.253249
            ],
            [
              -120.550957,
              45.253349
            ],
            [
              -120.554157,
              45.252749
            ],
            [
              -120.555757,
              45.249949
            ],
            [
              -120.553457,
              45.247949
            ],
            [
              -120.550957,
              45.244549
            ],
            [
              -120.550357,
              45.240849
            ],
            [
              -120.547257,
              45.237749
            ],
            [
              -120.543557,
              45.235849
            ],
            [
              -120.542056,
              45.232949
            ],
            [
              -120.542356,
              45.230249
            ],
            [
              -120.544556,
              45.226149
            ],
            [
              -120.544856,
              45.222449
            ],
            [
              -120.542756,
              45.220649
            ],
            [
              -120.538556,
              45.220249
            ],
            [
              -120.536656,
              45.22185
            ],
            [
              -120.530856,
              45.22445
            ],
            [
              -120.525856,
              45.22375
            ],
            [
              -120.523855,
              45.22035
            ],
            [
              -120.526555,
              45.21745
            ],
            [
              -120.532255,
              45.21655
            ],
            [
              -120.535556,
              45.21745
            ],
            [
              -120.540856,
              45.216209
            ],
            [
              -120.542856,
              45.213549
            ],
            [
              -120.542155,
              45.211149
            ],
            [
              -120.538955,
              45.210449
            ],
            [
              -120.537055,
              45.21105
            ],
            [
              -120.533355,
              45.21105
            ],
            [
              -120.532255,
              45.20905
            ],
            [
              -120.530755,
              45.20565
            ],
            [
              -120.525855,
              45.20455
            ],
            [
              -120.522955,
              45.20495
            ],
            [
              -120.520555,
              45.20365
            ],
            [
              -120.520154,
              45.20165
            ],
            [
              -120.515254,
              45.19915
            ],
            [
              -120.507954,
              45.19705
            ],
            [
              -120.502554,
              45.19555
            ],
            [
              -120.497054,
              45.19645
            ],
            [
              -120.488024,
              45.197023
            ],
            [
              -120.480953,
              45.19125
            ],
            [
              -120.482953,
              45.18615
            ],
            [
              -120.486453,
              45.18415
            ],
            [
              -120.481352,
              45.17995
            ],
            [
              -120.477052,
              45.17995
            ],
            [
              -120.475252,
              45.17455
            ],
            [
              -120.480152,
              45.17235
            ],
            [
              -120.480252,
              45.16895
            ],
            [
              -120.475152,
              45.16755
            ],
            [
              -120.468951,
              45.16245
            ],
            [
              -120.472351,
              45.15905
            ],
            [
              -120.476751,
              45.15415
            ],
            [
              -120.471551,
              45.14905
            ],
            [
              -120.466995,
              45.146686
            ],
            [
              -120.46185,
              45.14435
            ],
            [
              -120.45565,
              45.14545
            ],
            [
              -120.45045,
              45.14435
            ],
            [
              -120.45345,
              45.13925
            ],
            [
              -120.46265,
              45.13445
            ],
            [
              -120.462409,
              45.12485
            ],
            [
              -120.460449,
              45.11705
            ],
            [
              -120.461249,
              45.10865
            ],
            [
              -120.463648,
              45.10375
            ],
            [
              -120.467448,
              45.10155
            ],
            [
              -120.468549,
              45.10595
            ],
            [
              -120.473749,
              45.11015
            ],
            [
              -120.480949,
              45.10785
            ],
            [
              -120.491949,
              45.10585
            ],
            [
              -120.50115,
              45.1041
            ],
            [
              -120.506864,
              45.102778
            ],
            [
              -120.51285,
              45.09765
            ],
            [
              -120.512649,
              45.09145
            ],
            [
              -120.508749,
              45.08745
            ],
            [
              -120.504049,
              45.08435
            ],
            [
              -120.503733,
              45.083477
            ],
            [
              -120.49516,
              45.068279
            ],
            [
              -120.131926,
              45.067354
            ],
            [
              -119.950613,
              45.065825
            ],
            [
              -119.910536,
              45.067928
            ],
            [
              -119.790699,
              45.067823
            ],
            [
              -119.790831,
              45.081253
            ],
            [
              -119.760731,
              45.081278
            ],
            [
              -119.759759,
              45.169211
            ],
            [
              -119.883914,
              45.169152
            ],
            [
              -119.882346,
              45.256916
            ],
            [
              -120.006555,
              45.257706
            ],
            [
              -120.004762,
              45.517513
            ],
            [
              -119.999501176112,
              45.812481718848495
            ],
            [
              -120.001148,
              45.811902
            ],
            [
              -120.07015,
              45.785152
            ],
            [
              -120.141352,
              45.773152
            ],
            [
              -120.170453,
              45.761951
            ],
            [
              -120.210754,
              45.725951
            ],
            [
              -120.282156,
              45.72125
            ],
            [
              -120.288656,
              45.72015
            ],
            [
              -120.329057,
              45.71105
            ],
            [
              -120.40396,
              45.699249
            ],
            [
              -120.482362,
              45.694449
            ],
            [
              -120.505863,
              45.700048
            ],
            [
              -120.521964,
              45.709848
            ],
            [
              -120.559465,
              45.738348
            ],
            [
              -120.591166,
              45.746547
            ],
            [
              -120.634968,
              45.745847
            ],
            [
              -120.65353434410001,
              45.7372486812962
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "41",
      "COUNTYFP": "023",
      "COUNTYNS": "01135851",
      "AFFGEOID": "0500000US41023",
      "GEOID": "41023",
      "NAME": "Grant",
      "LSAD": "06",
      "ALAND": 11726927790,
      "AWATER": 1893807,
      "County_state": "Grant,41"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -119.672228,
              44.995117
            ],
            [
              -119.671532,
              44.823343
            ],
            [
              -119.652431,
              44.823299
            ],
            [
              -119.655517,
              44.307045
            ],
            [
              -119.654993,
              44.220276
            ],
            [
              -119.657183,
              43.95892
            ],
            [
              -118.816931,
              43.960733
            ],
            [
              -118.81689,
              44.047828
            ],
            [
              -118.587125,
              44.040266
            ],
            [
              -118.347856,
              44.039075
            ],
            [
              -118.227645,
              44.039862
            ],
            [
              -118.228843,
              44.21255
            ],
            [
              -118.232214,
              44.256129
            ],
            [
              -118.246349,
              44.257161
            ],
            [
              -118.313959,
              44.257155
            ],
            [
              -118.354125,
              44.256564
            ],
            [
              -118.364816,
              44.255463
            ],
            [
              -118.408296,
              44.255563
            ],
            [
              -118.456184,
              44.255182
            ],
            [
              -118.481982,
              44.255099
            ],
            [
              -118.49736,
              44.270401
            ],
            [
              -118.498411,
              44.278536
            ],
            [
              -118.496419,
              44.2865
            ],
            [
              -118.492268,
              44.28899
            ],
            [
              -118.490029,
              44.290362
            ],
            [
              -118.487855,
              44.295402
            ],
            [
              -118.485964,
              44.299302
            ],
            [
              -118.483569,
              44.303483
            ],
            [
              -118.480325,
              44.310538
            ],
            [
              -118.479123,
              44.314728
            ],
            [
              -118.475016,
              44.318215
            ],
            [
              -118.471405,
              44.319755
            ],
            [
              -118.46794,
              44.322621
            ],
            [
              -118.468889,
              44.332982
            ],
            [
              -118.462598,
              44.336031
            ],
            [
              -118.458347,
              44.34135
            ],
            [
              -118.450965,
              44.349978
            ],
            [
              -118.442224,
              44.350701
            ],
            [
              -118.434828,
              44.353415
            ],
            [
              -118.433621,
              44.359247
            ],
            [
              -118.433692,
              44.365436
            ],
            [
              -118.429431,
              44.369993
            ],
            [
              -118.415987,
              44.372924
            ],
            [
              -118.410084,
              44.372399
            ],
            [
              -118.409161,
              44.374098
            ],
            [
              -118.409561,
              44.377097
            ],
            [
              -118.415354,
              44.382057
            ],
            [
              -118.422028,
              44.386664
            ],
            [
              -118.42733,
              44.393384
            ],
            [
              -118.427067,
              44.397454
            ],
            [
              -118.428954,
              44.398808
            ],
            [
              -118.43278,
              44.398083
            ],
            [
              -118.435404,
              44.400236
            ],
            [
              -118.434302,
              44.406871
            ],
            [
              -118.429834,
              44.410521
            ],
            [
              -118.428996,
              44.416031
            ],
            [
              -118.425939,
              44.419086
            ],
            [
              -118.423283,
              44.425307
            ],
            [
              -118.419188,
              44.43111
            ],
            [
              -118.420209,
              44.437823
            ],
            [
              -118.421545,
              44.444531
            ],
            [
              -118.421852,
              44.450838
            ],
            [
              -118.41658,
              44.455297
            ],
            [
              -118.411712,
              44.453244
            ],
            [
              -118.404787,
              44.455106
            ],
            [
              -118.400617,
              44.457229
            ],
            [
              -118.395021,
              44.457866
            ],
            [
              -118.389568,
              44.457943
            ],
            [
              -118.386097,
              44.458504
            ],
            [
              -118.383108,
              44.45974
            ],
            [
              -118.37901,
              44.461937
            ],
            [
              -118.377909,
              44.460812
            ],
            [
              -118.373402,
              44.46205
            ],
            [
              -118.369562,
              44.466046
            ],
            [
              -118.36514,
              44.469162
            ],
            [
              -118.361678,
              44.472993
            ],
            [
              -118.365069,
              44.476153
            ],
            [
              -118.361056,
              44.481
            ],
            [
              -118.364217,
              44.489344
            ],
            [
              -118.367299,
              44.49763
            ],
            [
              -118.37017,
              44.502375
            ],
            [
              -118.373018,
              44.50506
            ],
            [
              -118.374632,
              44.507487
            ],
            [
              -118.374509,
              44.512089
            ],
            [
              -118.371346,
              44.519056
            ],
            [
              -118.369393,
              44.522185
            ],
            [
              -118.371829,
              44.527738
            ],
            [
              -118.370467,
              44.531724
            ],
            [
              -118.368011,
              44.53504
            ],
            [
              -118.364332,
              44.537373
            ],
            [
              -118.362764,
              44.536803
            ],
            [
              -118.358434,
              44.539071
            ],
            [
              -118.354533,
              44.54318
            ],
            [
              -118.350726,
              44.548139
            ],
            [
              -118.348359,
              44.549623
            ],
            [
              -118.348045,
              44.551696
            ],
            [
              -118.347722,
              44.553364
            ],
            [
              -118.345958,
              44.559263
            ],
            [
              -118.343301,
              44.562024
            ],
            [
              -118.340168,
              44.565014
            ],
            [
              -118.337664,
              44.566348
            ],
            [
              -118.337479,
              44.565627
            ],
            [
              -118.334252,
              44.564453
            ],
            [
              -118.331272,
              44.564654
            ],
            [
              -118.3281,
              44.562765
            ],
            [
              -118.323581,
              44.562343
            ],
            [
              -118.321743,
              44.564792
            ],
            [
              -118.317618,
              44.569373
            ],
            [
              -118.316398,
              44.57341
            ],
            [
              -118.314421,
              44.575381
            ],
            [
              -118.314378,
              44.578268
            ],
            [
              -118.310917,
              44.582133
            ],
            [
              -118.306961,
              44.58605
            ],
            [
              -118.311312,
              44.593059
            ],
            [
              -118.313116,
              44.598398
            ],
            [
              -118.321168,
              44.605953
            ],
            [
              -118.328825,
              44.608629
            ],
            [
              -118.332294,
              44.608651
            ],
            [
              -118.338615,
              44.610854
            ],
            [
              -118.340253,
              44.615101
            ],
            [
              -118.341744,
              44.620312
            ],
            [
              -118.343669,
              44.6241
            ],
            [
              -118.342194,
              44.626656
            ],
            [
              -118.342891,
              44.630586
            ],
            [
              -118.351047,
              44.63469
            ],
            [
              -118.355851,
              44.639377
            ],
            [
              -118.366348,
              44.641272
            ],
            [
              -118.375601,
              44.646109
            ],
            [
              -118.382035,
              44.645754
            ],
            [
              -118.386283,
              44.643315
            ],
            [
              -118.393159,
              44.642876
            ],
            [
              -118.399249,
              44.643476
            ],
            [
              -118.403581,
              44.641649
            ],
            [
              -118.408386,
              44.642776
            ],
            [
              -118.410619,
              44.64368
            ],
            [
              -118.413797,
              44.645809
            ],
            [
              -118.41909,
              44.648687
            ],
            [
              -118.421934,
              44.652405
            ],
            [
              -118.428746,
              44.654247
            ],
            [
              -118.433626,
              44.652691
            ],
            [
              -118.437484,
              44.653751
            ],
            [
              -118.444785,
              44.650658
            ],
            [
              -118.450373,
              44.65207
            ],
            [
              -118.460251,
              44.656239
            ],
            [
              -118.472223,
              44.656589
            ],
            [
              -118.478181,
              44.661586
            ],
            [
              -118.482581,
              44.662112
            ],
            [
              -118.487793,
              44.664174
            ],
            [
              -118.493619,
              44.663673
            ],
            [
              -118.499862,
              44.665922
            ],
            [
              -118.504158,
              44.666089
            ],
            [
              -118.504437,
              44.669929
            ],
            [
              -118.508883,
              44.677816
            ],
            [
              -118.513986,
              44.688532
            ],
            [
              -118.51749,
              44.695931
            ],
            [
              -118.516923,
              44.700331
            ],
            [
              -118.517027,
              44.705025
            ],
            [
              -118.515374,
              44.705317
            ],
            [
              -118.509324,
              44.706718
            ],
            [
              -118.501329,
              44.708754
            ],
            [
              -118.497422,
              44.711339
            ],
            [
              -118.495729,
              44.712425
            ],
            [
              -118.491584,
              44.712715
            ],
            [
              -118.489873,
              44.7118
            ],
            [
              -118.482557,
              44.709974
            ],
            [
              -118.475313,
              44.707768
            ],
            [
              -118.473427,
              44.705289
            ],
            [
              -118.468925,
              44.705028
            ],
            [
              -118.468018,
              44.705574
            ],
            [
              -118.465042,
              44.706174
            ],
            [
              -118.462059,
              44.707556
            ],
            [
              -118.461378,
              44.709596
            ],
            [
              -118.460029,
              44.71396
            ],
            [
              -118.456648,
              44.716856
            ],
            [
              -118.448431,
              44.721653
            ],
            [
              -118.44459,
              44.723667
            ],
            [
              -118.435673,
              44.721555
            ],
            [
              -118.429464,
              44.719195
            ],
            [
              -118.421859,
              44.719175
            ],
            [
              -118.4186,
              44.722341
            ],
            [
              -118.418141,
              44.725792
            ],
            [
              -118.412635,
              44.726863
            ],
            [
              -118.408731,
              44.726287
            ],
            [
              -118.403292,
              44.725038
            ],
            [
              -118.40088,
              44.725926
            ],
            [
              -118.396956,
              44.72604
            ],
            [
              -118.392296,
              44.730694
            ],
            [
              -118.387177,
              44.735949
            ],
            [
              -118.383481,
              44.740018
            ],
            [
              -118.381485,
              44.742288
            ],
            [
              -118.375048,
              44.745089
            ],
            [
              -118.36654,
              44.747219
            ],
            [
              -118.359384,
              44.745299
            ],
            [
              -118.35243,
              44.744738
            ],
            [
              -118.339207,
              44.743944
            ],
            [
              -118.330171,
              44.743514
            ],
            [
              -118.323964,
              44.741857
            ],
            [
              -118.316235,
              44.744
            ],
            [
              -118.306107,
              44.745763
            ],
            [
              -118.300094,
              44.746025
            ],
            [
              -118.294081,
              44.746602
            ],
            [
              -118.289699,
              44.749916
            ],
            [
              -118.286737,
              44.756001
            ],
            [
              -118.289513,
              44.763879
            ],
            [
              -118.287422,
              44.767165
            ],
            [
              -118.284633,
              44.76946
            ],
            [
              -118.285511,
              44.773801
            ],
            [
              -118.289011,
              44.778003
            ],
            [
              -118.293026,
              44.782166
            ],
            [
              -118.294084,
              44.785476
            ],
            [
              -118.293553,
              44.787656
            ],
            [
              -118.29458,
              44.790367
            ],
            [
              -118.297679,
              44.79409
            ],
            [
              -118.298894,
              44.795552
            ],
            [
              -118.298809,
              44.797894
            ],
            [
              -118.296858,
              44.799127
            ],
            [
              -118.298147,
              44.802249
            ],
            [
              -118.296853,
              44.806968
            ],
            [
              -118.294766,
              44.809633
            ],
            [
              -118.295068,
              44.813191
            ],
            [
              -118.298264,
              44.816338
            ],
            [
              -118.303667,
              44.817746
            ],
            [
              -118.306385,
              44.818795
            ],
            [
              -118.307151,
              44.820946
            ],
            [
              -118.309504,
              44.821856
            ],
            [
              -118.311002,
              44.823553
            ],
            [
              -118.309924,
              44.825145
            ],
            [
              -118.30847,
              44.828794
            ],
            [
              -118.310081,
              44.83113
            ],
            [
              -118.314436,
              44.832686
            ],
            [
              -118.317456,
              44.834564
            ],
            [
              -118.316142,
              44.837118
            ],
            [
              -118.311227,
              44.840357
            ],
            [
              -118.307609,
              44.842946
            ],
            [
              -118.306442,
              44.846717
            ],
            [
              -118.300909,
              44.849202
            ],
            [
              -118.29423,
              44.852653
            ],
            [
              -118.294408,
              44.85505
            ],
            [
              -118.294533,
              44.858681
            ],
            [
              -118.296005,
              44.861045
            ],
            [
              -118.29494,
              44.863352
            ],
            [
              -118.293141,
              44.864421
            ],
            [
              -118.288863,
              44.866112
            ],
            [
              -118.281093,
              44.865662
            ],
            [
              -118.2759,
              44.866573
            ],
            [
              -118.270898,
              44.868352
            ],
            [
              -118.268966,
              44.868972
            ],
            [
              -118.266588,
              44.868204
            ],
            [
              -118.264784,
              44.867051
            ],
            [
              -118.260672,
              44.86527
            ],
            [
              -118.254783,
              44.864368
            ],
            [
              -118.251418,
              44.86454
            ],
            [
              -118.249152,
              44.864364
            ],
            [
              -118.241369,
              44.862727
            ],
            [
              -118.234614,
              44.862831
            ],
            [
              -118.229229,
              44.864863
            ],
            [
              -118.2278,
              44.867141
            ],
            [
              -118.22816,
              44.870332
            ],
            [
              -118.227083,
              44.873476
            ],
            [
              -118.226025,
              44.874627
            ],
            [
              -118.226276,
              44.877583
            ],
            [
              -118.228828,
              44.881131
            ],
            [
              -118.231552,
              44.888244
            ],
            [
              -118.231635,
              44.894335
            ],
            [
              -118.236297,
              44.899273
            ],
            [
              -118.235008,
              44.904743
            ],
            [
              -118.23671,
              44.906737
            ],
            [
              -118.235905,
              44.909854
            ],
            [
              -118.232232,
              44.911868
            ],
            [
              -118.228945,
              44.915096
            ],
            [
              -118.22895,
              44.919461
            ],
            [
              -118.231248,
              44.921243
            ],
            [
              -118.231706,
              44.924206
            ],
            [
              -118.227855,
              44.926502
            ],
            [
              -118.227376,
              44.928204
            ],
            [
              -118.225874,
              44.933276
            ],
            [
              -118.224385,
              44.935382
            ],
            [
              -118.225236,
              44.93815
            ],
            [
              -118.227252,
              44.939659
            ],
            [
              -118.228981,
              44.940977
            ],
            [
              -118.232586,
              44.940557
            ],
            [
              -118.236686,
              44.941568
            ],
            [
              -118.239557,
              44.942946
            ],
            [
              -118.243397,
              44.94399
            ],
            [
              -118.244613,
              44.947001
            ],
            [
              -118.243956,
              44.950747
            ],
            [
              -118.244242,
              44.953344
            ],
            [
              -118.243674,
              44.956834
            ],
            [
              -118.244662,
              44.958396
            ],
            [
              -118.276006,
              44.957896
            ],
            [
              -118.282246,
              44.964726
            ],
            [
              -118.299523,
              44.961029
            ],
            [
              -118.3191,
              44.97599
            ],
            [
              -118.34662,
              44.986988
            ],
            [
              -118.364214,
              44.990763
            ],
            [
              -118.3908,
              44.972107
            ],
            [
              -118.404334,
              44.976469
            ],
            [
              -118.4361,
              44.96242
            ],
            [
              -118.437351,
              44.984781
            ],
            [
              -118.468871,
              44.995176
            ],
            [
              -118.518689,
              44.995877
            ],
            [
              -119.162966,
              44.996241
            ],
            [
              -119.667065,
              44.994664
            ],
            [
              -119.672228,
              44.995117
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "41",
      "COUNTYFP": "033",
      "COUNTYNS": "01155133",
      "AFFGEOID": "0500000US41033",
      "GEOID": "41033",
      "NAME": "Josephine",
      "LSAD": "06",
      "ALAND": 4244087614,
      "AWATER": 5629244,
      "County_state": "Josephine,41"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -124.040949,
              42.388026
            ],
            [
              -124.041679,
              42.386146
            ],
            [
              -124.039586,
              42.384751
            ],
            [
              -124.038867,
              42.38364
            ],
            [
              -124.037296,
              42.38098
            ],
            [
              -124.036147,
              42.378608
            ],
            [
              -124.034996,
              42.376119
            ],
            [
              -124.036366,
              42.371775
            ],
            [
              -124.035496,
              42.369419
            ],
            [
              -124.034332,
              42.368231
            ],
            [
              -124.033093,
              42.366581
            ],
            [
              -124.031199,
              42.364743
            ],
            [
              -124.029887,
              42.362867
            ],
            [
              -124.028712,
              42.361049
            ],
            [
              -124.027275,
              42.359853
            ],
            [
              -124.024658,
              42.360305
            ],
            [
              -124.021087,
              42.36168
            ],
            [
              -124.018169,
              42.360561
            ],
            [
              -124.015153,
              42.359294
            ],
            [
              -124.010476,
              42.358613
            ],
            [
              -124.003386,
              42.358864
            ],
            [
              -123.999835,
              42.358681
            ],
            [
              -123.99623,
              42.358611
            ],
            [
              -123.989576,
              42.3584
            ],
            [
              -123.984686,
              42.358449
            ],
            [
              -123.979725,
              42.358776
            ],
            [
              -123.975472,
              42.358211
            ],
            [
              -123.970693,
              42.357495
            ],
            [
              -123.966162,
              42.357626
            ],
            [
              -123.960099,
              42.357003
            ],
            [
              -123.954132,
              42.357256
            ],
            [
              -123.949392,
              42.356639
            ],
            [
              -123.944749,
              42.358043
            ],
            [
              -123.942432,
              42.359361
            ],
            [
              -123.938715,
              42.361123
            ],
            [
              -123.937249,
              42.361536
            ],
            [
              -123.935327,
              42.359091
            ],
            [
              -123.93464,
              42.356358
            ],
            [
              -123.931902,
              42.353953
            ],
            [
              -123.927905,
              42.352493
            ],
            [
              -123.923685,
              42.349606
            ],
            [
              -123.920253,
              42.346479
            ],
            [
              -123.918276,
              42.346599
            ],
            [
              -123.916087,
              42.346626
            ],
            [
              -123.913034,
              42.347228
            ],
            [
              -123.905339,
              42.348463
            ],
            [
              -123.903056,
              42.349192
            ],
            [
              -123.900837,
              42.349309
            ],
            [
              -123.89972,
              42.34936
            ],
            [
              -123.89763,
              42.350325
            ],
            [
              -123.893745,
              42.352314
            ],
            [
              -123.891108,
              42.352934
            ],
            [
              -123.886993,
              42.350245
            ],
            [
              -123.884229,
              42.346519
            ],
            [
              -123.881707,
              42.345926
            ],
            [
              -123.877858,
              42.342428
            ],
            [
              -123.878604,
              42.337028
            ],
            [
              -123.876594,
              42.333533
            ],
            [
              -123.876146,
              42.330428
            ],
            [
              -123.873486,
              42.327885
            ],
            [
              -123.871803,
              42.325716
            ],
            [
              -123.866394,
              42.322362
            ],
            [
              -123.857646,
              42.318688
            ],
            [
              -123.845645,
              42.318135
            ],
            [
              -123.841912,
              42.313679
            ],
            [
              -123.845181,
              42.306134
            ],
            [
              -123.847609,
              42.302179
            ],
            [
              -123.846637,
              42.298754
            ],
            [
              -123.842733,
              42.297458
            ],
            [
              -123.839246,
              42.295966
            ],
            [
              -123.838031,
              42.289497
            ],
            [
              -123.836215,
              42.286879
            ],
            [
              -123.832827,
              42.284773
            ],
            [
              -123.830718,
              42.280866
            ],
            [
              -123.827201,
              42.278221
            ],
            [
              -123.826159,
              42.270973
            ],
            [
              -123.823708,
              42.267994
            ],
            [
              -123.820948,
              42.264667
            ],
            [
              -123.818341,
              42.264171
            ],
            [
              -123.815251,
              42.259439
            ],
            [
              -123.813847,
              42.254908
            ],
            [
              -123.811604,
              42.251246
            ],
            [
              -123.810733,
              42.246846
            ],
            [
              -123.811448,
              42.244901
            ],
            [
              -123.808558,
              42.241316
            ],
            [
              -123.803515,
              42.239436
            ],
            [
              -123.798206,
              42.238293
            ],
            [
              -123.795116,
              42.237489
            ],
            [
              -123.792449,
              42.236826
            ],
            [
              -123.792389,
              42.235377
            ],
            [
              -123.793287,
              42.232041
            ],
            [
              -123.793244,
              42.227881
            ],
            [
              -123.792834,
              42.224314
            ],
            [
              -123.797063,
              42.218962
            ],
            [
              -123.804148,
              42.21662
            ],
            [
              -123.812836,
              42.210382
            ],
            [
              -123.820324,
              42.19648
            ],
            [
              -123.820822,
              42.189687
            ],
            [
              -123.816711,
              42.187187
            ],
            [
              -123.813624,
              42.177048
            ],
            [
              -123.814536,
              42.172501
            ],
            [
              -123.813822,
              42.168137
            ],
            [
              -123.819176,
              42.163757
            ],
            [
              -123.822465,
              42.159241
            ],
            [
              -123.825182,
              42.157576
            ],
            [
              -123.827328,
              42.15596
            ],
            [
              -123.832351,
              42.155207
            ],
            [
              -123.838208,
              42.153582
            ],
            [
              -123.842871,
              42.149668
            ],
            [
              -123.84783,
              42.1461
            ],
            [
              -123.856163,
              42.138965
            ],
            [
              -123.855141,
              42.131992
            ],
            [
              -123.850176,
              42.126945
            ],
            [
              -123.843718,
              42.125248
            ],
            [
              -123.83976,
              42.120348
            ],
            [
              -123.837985,
              42.112271
            ],
            [
              -123.832052,
              42.107408
            ],
            [
              -123.829835,
              42.09836
            ],
            [
              -123.824298,
              42.091096
            ],
            [
              -123.824208,
              42.08491
            ],
            [
              -123.828112,
              42.073675
            ],
            [
              -123.828768,
              42.070564
            ],
            [
              -123.831452,
              42.06824
            ],
            [
              -123.834527,
              42.064272
            ],
            [
              -123.837033,
              42.062227
            ],
            [
              -123.841301,
              42.058753
            ],
            [
              -123.840521,
              42.052978
            ],
            [
              -123.844159,
              42.048475
            ],
            [
              -123.847758,
              42.040136
            ],
            [
              -123.841723,
              42.031385
            ],
            [
              -123.836984,
              42.022406
            ],
            [
              -123.838686,
              42.019528
            ],
            [
              -123.838849,
              42.016469
            ],
            [
              -123.833287,
              42.012042
            ],
            [
              -123.82937,
              42.01084
            ],
            [
              -123.827167,
              42.007777
            ],
            [
              -123.827502,
              42.006535
            ],
            [
              -123.826539,
              42.002017
            ],
            [
              -123.821615,
              41.999935
            ],
            [
              -123.819766,
              41.997917
            ],
            [
              -123.821307,
              41.996304
            ],
            [
              -123.822044941707,
              41.995501925998596
            ],
            [
              -123.821472,
              41.995473
            ],
            [
              -123.813992,
              41.995096
            ],
            [
              -123.789295,
              41.996111
            ],
            [
              -123.728156,
              41.997007
            ],
            [
              -123.721429255526,
              41.996830224202895
            ],
            [
              -123.656998,
              41.995137
            ],
            [
              -123.624554,
              41.999837
            ],
            [
              -123.55256,
              42.000246
            ],
            [
              -123.525245,
              42.001047
            ],
            [
              -123.51790816171501,
              42.000882893156096
            ],
            [
              -123.501997,
              42.000527
            ],
            [
              -123.498896,
              42.000474
            ],
            [
              -123.49883,
              42.000525
            ],
            [
              -123.43477,
              42.001641
            ],
            [
              -123.381776,
              41.999268
            ],
            [
              -123.347562,
              41.999108
            ],
            [
              -123.23110845560201,
              42.00383094897101
            ],
            [
              -123.229167,
              42.128331
            ],
            [
              -123.229864,
              42.251647
            ],
            [
              -123.229901,
              42.290816
            ],
            [
              -123.229898,
              42.296345
            ],
            [
              -123.229545,
              42.495623
            ],
            [
              -123.229676,
              42.551766
            ],
            [
              -123.229619,
              42.70261
            ],
            [
              -123.249209,
              42.702421
            ],
            [
              -123.249328,
              42.724404
            ],
            [
              -123.274283,
              42.73203
            ],
            [
              -123.371581,
              42.739484
            ],
            [
              -123.371674,
              42.724957
            ],
            [
              -123.400242,
              42.724951
            ],
            [
              -123.403515,
              42.710295
            ],
            [
              -123.425721,
              42.714483
            ],
            [
              -123.465271,
              42.699297
            ],
            [
              -123.494414,
              42.70918
            ],
            [
              -123.542539,
              42.709832
            ],
            [
              -123.562168,
              42.717574
            ],
            [
              -123.566777,
              42.735693
            ],
            [
              -123.581699,
              42.73992
            ],
            [
              -123.677275,
              42.739682
            ],
            [
              -123.677206,
              42.750145
            ],
            [
              -123.697,
              42.753907
            ],
            [
              -123.696843,
              42.739593
            ],
            [
              -123.716505,
              42.739658
            ],
            [
              -123.716492,
              42.784061
            ],
            [
              -123.716853,
              42.783753
            ],
            [
              -123.719166,
              42.782464
            ],
            [
              -123.720422,
              42.78045
            ],
            [
              -123.720212,
              42.77733
            ],
            [
              -123.722049,
              42.77575
            ],
            [
              -123.725305,
              42.774113
            ],
            [
              -123.728293,
              42.7736
            ],
            [
              -123.729809,
              42.771694
            ],
            [
              -123.731233,
              42.769899
            ],
            [
              -123.733383,
              42.768086
            ],
            [
              -123.734344,
              42.765685
            ],
            [
              -123.735347,
              42.76254
            ],
            [
              -123.736595,
              42.759769
            ],
            [
              -123.737911,
              42.75719
            ],
            [
              -123.737506,
              42.753288
            ],
            [
              -123.740001,
              42.750805
            ],
            [
              -123.741647,
              42.748376
            ],
            [
              -123.744373,
              42.746335
            ],
            [
              -123.74584,
              42.744741
            ],
            [
              -123.747493,
              42.742647
            ],
            [
              -123.749615,
              42.741821
            ],
            [
              -123.750743,
              42.739643
            ],
            [
              -123.751666,
              42.73775
            ],
            [
              -123.753166,
              42.735487
            ],
            [
              -123.75345,
              42.733969
            ],
            [
              -123.754326,
              42.732693
            ],
            [
              -123.754954,
              42.73156
            ],
            [
              -123.75571,
              42.730328
            ],
            [
              -123.756,
              42.728619
            ],
            [
              -123.755777,
              42.727351
            ],
            [
              -123.755029,
              42.726344
            ],
            [
              -123.755122,
              42.724467
            ],
            [
              -123.756485,
              42.722321
            ],
            [
              -123.757263,
              42.720584
            ],
            [
              -123.758398,
              42.719369
            ],
            [
              -123.759306,
              42.717662
            ],
            [
              -123.760166,
              42.717319
            ],
            [
              -123.761104,
              42.7164
            ],
            [
              -123.76295,
              42.714956
            ],
            [
              -123.764423,
              42.713689
            ],
            [
              -123.765428,
              42.710926
            ],
            [
              -123.766875,
              42.709109
            ],
            [
              -123.768226,
              42.708502
            ],
            [
              -123.769324,
              42.707619
            ],
            [
              -123.768918,
              42.706834
            ],
            [
              -123.769227,
              42.705498
            ],
            [
              -123.771158,
              42.704452
            ],
            [
              -123.773833,
              42.702858
            ],
            [
              -123.77371,
              42.70162
            ],
            [
              -123.774382,
              42.700532
            ],
            [
              -123.803025,
              42.666282
            ],
            [
              -123.818299,
              42.647908
            ],
            [
              -123.828028,
              42.625052
            ],
            [
              -123.825834,
              42.623544
            ],
            [
              -123.823239,
              42.621294
            ],
            [
              -123.82081,
              42.617911
            ],
            [
              -123.819424,
              42.614387
            ],
            [
              -123.819399,
              42.612703
            ],
            [
              -123.82086,
              42.611139
            ],
            [
              -123.821633,
              42.609134
            ],
            [
              -123.823584,
              42.60794
            ],
            [
              -123.826272,
              42.607151
            ],
            [
              -123.828483,
              42.605636
            ],
            [
              -123.828493,
              42.604424
            ],
            [
              -123.828889,
              42.601057
            ],
            [
              -123.830216,
              42.598436
            ],
            [
              -123.830403,
              42.595873
            ],
            [
              -123.831967,
              42.593947
            ],
            [
              -123.834413,
              42.592718
            ],
            [
              -123.836239,
              42.591543
            ],
            [
              -123.838664,
              42.590116
            ],
            [
              -123.841423,
              42.587759
            ],
            [
              -123.84381,
              42.587187
            ],
            [
              -123.845754,
              42.586399
            ],
            [
              -123.847702,
              42.585353
            ],
            [
              -123.849558,
              42.583718
            ],
            [
              -123.849745,
              42.581051
            ],
            [
              -123.849623,
              42.579886
            ],
            [
              -123.849599,
              42.578622
            ],
            [
              -123.850156,
              42.57659
            ],
            [
              -123.850035,
              42.574596
            ],
            [
              -123.852451,
              42.571998
            ],
            [
              -123.853567,
              42.570103
            ],
            [
              -123.855135,
              42.567844
            ],
            [
              -123.857687,
              42.566074
            ],
            [
              -123.860256,
              42.563056
            ],
            [
              -123.860326,
              42.561592
            ],
            [
              -123.860654,
              42.559437
            ],
            [
              -123.859278,
              42.556921
            ],
            [
              -123.858875,
              42.555582
            ],
            [
              -123.856334,
              42.553151
            ],
            [
              -123.854923,
              42.550759
            ],
            [
              -123.856397,
              42.548419
            ],
            [
              -123.858651,
              42.546875
            ],
            [
              -123.860259,
              42.545242
            ],
            [
              -123.862014,
              42.543448
            ],
            [
              -123.863317,
              42.54267
            ],
            [
              -123.865462,
              42.541515
            ],
            [
              -123.865789,
              42.539396
            ],
            [
              -123.866747,
              42.538195
            ],
            [
              -123.868754,
              42.536235
            ],
            [
              -123.871512,
              42.533769
            ],
            [
              -123.87327,
              42.531954
            ],
            [
              -123.875082,
              42.529847
            ],
            [
              -123.875862,
              42.527148
            ],
            [
              -123.877287,
              42.52561
            ],
            [
              -123.878577,
              42.524272
            ],
            [
              -123.879056,
              42.521823
            ],
            [
              -123.878314,
              42.519816
            ],
            [
              -123.877456,
              42.518499
            ],
            [
              -123.876191,
              42.516735
            ],
            [
              -123.876741,
              42.515204
            ],
            [
              -123.875842,
              42.514274
            ],
            [
              -123.876191,
              42.512934
            ],
            [
              -123.878034,
              42.512771
            ],
            [
              -123.87945,
              42.512292
            ],
            [
              -123.880333,
              42.511493
            ],
            [
              -123.879974,
              42.510791
            ],
            [
              -123.88053,
              42.509393
            ],
            [
              -123.883882,
              42.508656
            ],
            [
              -123.885544,
              42.50731
            ],
            [
              -123.885399,
              42.506167
            ],
            [
              -123.886141,
              42.505162
            ],
            [
              -123.886767,
              42.503446
            ],
            [
              -123.888905,
              42.502828
            ],
            [
              -123.89003,
              42.501047
            ],
            [
              -123.891991,
              42.500231
            ],
            [
              -123.894308,
              42.499844
            ],
            [
              -123.897753,
              42.499051
            ],
            [
              -123.901263,
              42.498546
            ],
            [
              -123.904789,
              42.499221
            ],
            [
              -123.908496,
              42.497066
            ],
            [
              -123.911828,
              42.496948
            ],
            [
              -123.914243,
              42.49589
            ],
            [
              -123.917355,
              42.495798
            ],
            [
              -123.919214,
              42.494752
            ],
            [
              -123.923803,
              42.495012
            ],
            [
              -123.926563,
              42.494413
            ],
            [
              -123.929099,
              42.49514
            ],
            [
              -123.929334,
              42.495971
            ],
            [
              -123.93088,
              42.496112
            ],
            [
              -123.933305,
              42.496074
            ],
            [
              -123.934974,
              42.498078
            ],
            [
              -123.936024,
              42.499704
            ],
            [
              -123.937781,
              42.50068
            ],
            [
              -123.940505,
              42.501054
            ],
            [
              -123.944269,
              42.50141
            ],
            [
              -123.946693,
              42.50094
            ],
            [
              -123.950014,
              42.500395
            ],
            [
              -123.954546,
              42.499047
            ],
            [
              -123.95657,
              42.498715
            ],
            [
              -123.959485,
              42.498425
            ],
            [
              -123.961614,
              42.498434
            ],
            [
              -123.96325,
              42.497501
            ],
            [
              -123.968173,
              42.497059
            ],
            [
              -123.971991,
              42.495392
            ],
            [
              -123.974637,
              42.496379
            ],
            [
              -123.97647,
              42.496899
            ],
            [
              -123.978663,
              42.497483
            ],
            [
              -123.98343,
              42.497564
            ],
            [
              -123.986497,
              42.498429
            ],
            [
              -123.987013,
              42.500136
            ],
            [
              -123.987413,
              42.501042
            ],
            [
              -123.988611,
              42.501975
            ],
            [
              -123.989829,
              42.501683
            ],
            [
              -123.992599,
              42.49978
            ],
            [
              -123.994154,
              42.500128
            ],
            [
              -123.99803,
              42.50045
            ],
            [
              -123.999436,
              42.499083
            ],
            [
              -124.000101,
              42.497885
            ],
            [
              -124.000749,
              42.49714
            ],
            [
              -124.004141,
              42.496862
            ],
            [
              -124.007179,
              42.496323
            ],
            [
              -124.009427,
              42.494956
            ],
            [
              -124.010837,
              42.492508
            ],
            [
              -124.011291,
              42.490846
            ],
            [
              -124.009703,
              42.489458
            ],
            [
              -124.007612,
              42.489019
            ],
            [
              -124.005125,
              42.48719
            ],
            [
              -124.002033,
              42.484072
            ],
            [
              -124.001629,
              42.477902
            ],
            [
              -124.002859,
              42.471233
            ],
            [
              -124.001059,
              42.467028
            ],
            [
              -124.002201,
              42.46332
            ],
            [
              -124.003827,
              42.462452
            ],
            [
              -124.005787,
              42.459833
            ],
            [
              -124.00385,
              42.457274
            ],
            [
              -124.001334,
              42.455054
            ],
            [
              -124.002495,
              42.452642
            ],
            [
              -124.003432,
              42.451698
            ],
            [
              -124.00414,
              42.44903
            ],
            [
              -124.002747,
              42.446867
            ],
            [
              -124.002269,
              42.444161
            ],
            [
              -124.006021,
              42.442673
            ],
            [
              -124.010751,
              42.439762
            ],
            [
              -124.012724,
              42.436422
            ],
            [
              -124.013947,
              42.431349
            ],
            [
              -124.010647,
              42.428261
            ],
            [
              -124.010642,
              42.425775
            ],
            [
              -124.012549,
              42.424297
            ],
            [
              -124.010787,
              42.421756
            ],
            [
              -124.009323,
              42.421447
            ],
            [
              -124.00863,
              42.419622
            ],
            [
              -124.009561,
              42.418158
            ],
            [
              -124.01186,
              42.41561
            ],
            [
              -124.01303,
              42.412929
            ],
            [
              -124.015782,
              42.412302
            ],
            [
              -124.017458,
              42.410981
            ],
            [
              -124.019365,
              42.410293
            ],
            [
              -124.021367,
              42.409082
            ],
            [
              -124.023389,
              42.407623
            ],
            [
              -124.024983,
              42.407744
            ],
            [
              -124.026462,
              42.407024
            ],
            [
              -124.026747,
              42.406233
            ],
            [
              -124.027905,
              42.404021
            ],
            [
              -124.030212,
              42.402883
            ],
            [
              -124.032866,
              42.402959
            ],
            [
              -124.035527,
              42.402174
            ],
            [
              -124.036113,
              42.400056
            ],
            [
              -124.036266,
              42.397416
            ],
            [
              -124.03694,
              42.395591
            ],
            [
              -124.037225,
              42.394003
            ],
            [
              -124.037373,
              42.392389
            ],
            [
              -124.037786,
              42.390913
            ],
            [
              -124.039937,
              42.388526
            ],
            [
              -124.040949,
              42.388026
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "41",
      "COUNTYFP": "001",
      "COUNTYNS": "01135845",
      "AFFGEOID": "0500000US41001",
      "GEOID": "41001",
      "NAME": "Baker",
      "LSAD": "06",
      "ALAND": 7945996928,
      "AWATER": 51832632,
      "County_state": "Baker,41"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -118.516923,
              44.700331
            ],
            [
              -118.51749,
              44.695931
            ],
            [
              -118.513986,
              44.688532
            ],
            [
              -118.508883,
              44.677816
            ],
            [
              -118.504437,
              44.669929
            ],
            [
              -118.504158,
              44.666089
            ],
            [
              -118.499862,
              44.665922
            ],
            [
              -118.493619,
              44.663673
            ],
            [
              -118.487793,
              44.664174
            ],
            [
              -118.482581,
              44.662112
            ],
            [
              -118.478181,
              44.661586
            ],
            [
              -118.472223,
              44.656589
            ],
            [
              -118.460251,
              44.656239
            ],
            [
              -118.450373,
              44.65207
            ],
            [
              -118.444785,
              44.650658
            ],
            [
              -118.437484,
              44.653751
            ],
            [
              -118.433626,
              44.652691
            ],
            [
              -118.428746,
              44.654247
            ],
            [
              -118.421934,
              44.652405
            ],
            [
              -118.41909,
              44.648687
            ],
            [
              -118.413797,
              44.645809
            ],
            [
              -118.410619,
              44.64368
            ],
            [
              -118.408386,
              44.642776
            ],
            [
              -118.403581,
              44.641649
            ],
            [
              -118.399249,
              44.643476
            ],
            [
              -118.393159,
              44.642876
            ],
            [
              -118.386283,
              44.643315
            ],
            [
              -118.382035,
              44.645754
            ],
            [
              -118.375601,
              44.646109
            ],
            [
              -118.366348,
              44.641272
            ],
            [
              -118.355851,
              44.639377
            ],
            [
              -118.351047,
              44.63469
            ],
            [
              -118.342891,
              44.630586
            ],
            [
              -118.342194,
              44.626656
            ],
            [
              -118.343669,
              44.6241
            ],
            [
              -118.341744,
              44.620312
            ],
            [
              -118.340253,
              44.615101
            ],
            [
              -118.338615,
              44.610854
            ],
            [
              -118.332294,
              44.608651
            ],
            [
              -118.328825,
              44.608629
            ],
            [
              -118.321168,
              44.605953
            ],
            [
              -118.313116,
              44.598398
            ],
            [
              -118.311312,
              44.593059
            ],
            [
              -118.306961,
              44.58605
            ],
            [
              -118.310917,
              44.582133
            ],
            [
              -118.314378,
              44.578268
            ],
            [
              -118.314421,
              44.575381
            ],
            [
              -118.316398,
              44.57341
            ],
            [
              -118.317618,
              44.569373
            ],
            [
              -118.321743,
              44.564792
            ],
            [
              -118.323581,
              44.562343
            ],
            [
              -118.3281,
              44.562765
            ],
            [
              -118.331272,
              44.564654
            ],
            [
              -118.334252,
              44.564453
            ],
            [
              -118.337479,
              44.565627
            ],
            [
              -118.337664,
              44.566348
            ],
            [
              -118.340168,
              44.565014
            ],
            [
              -118.343301,
              44.562024
            ],
            [
              -118.345958,
              44.559263
            ],
            [
              -118.347722,
              44.553364
            ],
            [
              -118.348045,
              44.551696
            ],
            [
              -118.348359,
              44.549623
            ],
            [
              -118.350726,
              44.548139
            ],
            [
              -118.354533,
              44.54318
            ],
            [
              -118.358434,
              44.539071
            ],
            [
              -118.362764,
              44.536803
            ],
            [
              -118.364332,
              44.537373
            ],
            [
              -118.368011,
              44.53504
            ],
            [
              -118.370467,
              44.531724
            ],
            [
              -118.371829,
              44.527738
            ],
            [
              -118.369393,
              44.522185
            ],
            [
              -118.371346,
              44.519056
            ],
            [
              -118.374509,
              44.512089
            ],
            [
              -118.374632,
              44.507487
            ],
            [
              -118.373018,
              44.50506
            ],
            [
              -118.37017,
              44.502375
            ],
            [
              -118.367299,
              44.49763
            ],
            [
              -118.364217,
              44.489344
            ],
            [
              -118.361056,
              44.481
            ],
            [
              -118.365069,
              44.476153
            ],
            [
              -118.361678,
              44.472993
            ],
            [
              -118.36514,
              44.469162
            ],
            [
              -118.369562,
              44.466046
            ],
            [
              -118.373402,
              44.46205
            ],
            [
              -118.377909,
              44.460812
            ],
            [
              -118.37901,
              44.461937
            ],
            [
              -118.383108,
              44.45974
            ],
            [
              -118.386097,
              44.458504
            ],
            [
              -118.389568,
              44.457943
            ],
            [
              -118.395021,
              44.457866
            ],
            [
              -118.400617,
              44.457229
            ],
            [
              -118.404787,
              44.455106
            ],
            [
              -118.411712,
              44.453244
            ],
            [
              -118.41658,
              44.455297
            ],
            [
              -118.421852,
              44.450838
            ],
            [
              -118.421545,
              44.444531
            ],
            [
              -118.420209,
              44.437823
            ],
            [
              -118.419188,
              44.43111
            ],
            [
              -118.423283,
              44.425307
            ],
            [
              -118.425939,
              44.419086
            ],
            [
              -118.428996,
              44.416031
            ],
            [
              -118.429834,
              44.410521
            ],
            [
              -118.434302,
              44.406871
            ],
            [
              -118.435404,
              44.400236
            ],
            [
              -118.43278,
              44.398083
            ],
            [
              -118.428954,
              44.398808
            ],
            [
              -118.427067,
              44.397454
            ],
            [
              -118.42733,
              44.393384
            ],
            [
              -118.422028,
              44.386664
            ],
            [
              -118.415354,
              44.382057
            ],
            [
              -118.409561,
              44.377097
            ],
            [
              -118.409161,
              44.374098
            ],
            [
              -118.410084,
              44.372399
            ],
            [
              -118.415987,
              44.372924
            ],
            [
              -118.429431,
              44.369993
            ],
            [
              -118.433692,
              44.365436
            ],
            [
              -118.433621,
              44.359247
            ],
            [
              -118.434828,
              44.353415
            ],
            [
              -118.442224,
              44.350701
            ],
            [
              -118.450965,
              44.349978
            ],
            [
              -118.458347,
              44.34135
            ],
            [
              -118.462598,
              44.336031
            ],
            [
              -118.468889,
              44.332982
            ],
            [
              -118.46794,
              44.322621
            ],
            [
              -118.471405,
              44.319755
            ],
            [
              -118.475016,
              44.318215
            ],
            [
              -118.479123,
              44.314728
            ],
            [
              -118.480325,
              44.310538
            ],
            [
              -118.483569,
              44.303483
            ],
            [
              -118.485964,
              44.299302
            ],
            [
              -118.487855,
              44.295402
            ],
            [
              -118.490029,
              44.290362
            ],
            [
              -118.492268,
              44.28899
            ],
            [
              -118.496419,
              44.2865
            ],
            [
              -118.498411,
              44.278536
            ],
            [
              -118.49736,
              44.270401
            ],
            [
              -118.481982,
              44.255099
            ],
            [
              -118.456184,
              44.255182
            ],
            [
              -118.408296,
              44.255563
            ],
            [
              -118.364816,
              44.255463
            ],
            [
              -118.354125,
              44.256564
            ],
            [
              -118.313959,
              44.257155
            ],
            [
              -118.246349,
              44.257161
            ],
            [
              -118.232214,
              44.256129
            ],
            [
              -118.193345,
              44.2562
            ],
            [
              -118.193405,
              44.270708
            ],
            [
              -118.152945,
              44.270684
            ],
            [
              -118.152456,
              44.299509
            ],
            [
              -118.132141,
              44.299483
            ],
            [
              -118.132347,
              44.31431
            ],
            [
              -118.112092,
              44.314271
            ],
            [
              -118.112068,
              44.328754
            ],
            [
              -118.092122,
              44.328734
            ],
            [
              -118.087016,
              44.357646
            ],
            [
              -118.071877,
              44.357562
            ],
            [
              -118.071916,
              44.372009
            ],
            [
              -118.051902,
              44.372004
            ],
            [
              -118.051956,
              44.390032
            ],
            [
              -118.031808,
              44.389953
            ],
            [
              -118.031671,
              44.415192
            ],
            [
              -118.011485,
              44.415169
            ],
            [
              -118.011402,
              44.429615
            ],
            [
              -117.971947,
              44.429296
            ],
            [
              -117.971787,
              44.443849
            ],
            [
              -117.590849,
              44.444573
            ],
            [
              -117.590858,
              44.43013
            ],
            [
              -117.56962,
              44.430062
            ],
            [
              -117.569664,
              44.415678
            ],
            [
              -117.52936,
              44.415722
            ],
            [
              -117.529408,
              44.401329
            ],
            [
              -117.509202,
              44.401461
            ],
            [
              -117.509214,
              44.387143
            ],
            [
              -117.486744,
              44.387246
            ],
            [
              -117.487014,
              44.300317
            ],
            [
              -117.220060563209,
              44.3014039750731
            ],
            [
              -117.217843,
              44.30718
            ],
            [
              -117.216795,
              44.308236
            ],
            [
              -117.21521,
              44.309116
            ],
            [
              -117.2055,
              44.311789
            ],
            [
              -117.203323,
              44.313024
            ],
            [
              -117.192203,
              44.32863
            ],
            [
              -117.191546,
              44.329621
            ],
            [
              -117.189842,
              44.335007
            ],
            [
              -117.189769,
              44.336585
            ],
            [
              -117.196149,
              44.346362
            ],
            [
              -117.197339,
              44.347406
            ],
            [
              -117.206962,
              44.355206
            ],
            [
              -117.210587,
              44.357703
            ],
            [
              -117.216911,
              44.360163
            ],
            [
              -117.227938,
              44.367975
            ],
            [
              -117.235117,
              44.373853
            ],
            [
              -117.243027,
              44.390974
            ],
            [
              -117.242675,
              44.396548
            ],
            [
              -117.234835,
              44.399669
            ],
            [
              -117.22698,
              44.405583
            ],
            [
              -117.225461,
              44.407729
            ],
            [
              -117.218285,
              44.420664
            ],
            [
              -117.215072,
              44.427162
            ],
            [
              -117.214637,
              44.44803
            ],
            [
              -117.215573,
              44.453746
            ],
            [
              -117.217015,
              44.459042
            ],
            [
              -117.221548,
              44.470146
            ],
            [
              -117.224445,
              44.473884
            ],
            [
              -117.225758,
              44.477223
            ],
            [
              -117.225932,
              44.479389
            ],
            [
              -117.225076,
              44.482346
            ],
            [
              -117.224104,
              44.483734
            ],
            [
              -117.216372,
              44.48616
            ],
            [
              -117.211148,
              44.485359
            ],
            [
              -117.208936,
              44.485661
            ],
            [
              -117.200237,
              44.492027
            ],
            [
              -117.194317,
              44.499884
            ],
            [
              -117.192494,
              44.503272
            ],
            [
              -117.191329,
              44.506784
            ],
            [
              -117.19163,
              44.509886
            ],
            [
              -117.189759,
              44.513385
            ],
            [
              -117.185386,
              44.519261
            ],
            [
              -117.181583,
              44.52296
            ],
            [
              -117.167187,
              44.523431
            ],
            [
              -117.161033,
              44.525166
            ],
            [
              -117.152406,
              44.531802
            ],
            [
              -117.149242,
              44.536151
            ],
            [
              -117.144161,
              44.545647
            ],
            [
              -117.14293,
              44.557236
            ],
            [
              -117.147934,
              44.562143
            ],
            [
              -117.148255,
              44.564371
            ],
            [
              -117.146032,
              44.568603
            ],
            [
              -117.14248,
              44.57143
            ],
            [
              -117.138066,
              44.572996
            ],
            [
              -117.133963,
              44.57524
            ],
            [
              -117.126009,
              44.581553
            ],
            [
              -117.124754,
              44.583834
            ],
            [
              -117.125267,
              44.593818
            ],
            [
              -117.120522,
              44.614658
            ],
            [
              -117.117809,
              44.620139
            ],
            [
              -117.114754,
              44.624883
            ],
            [
              -117.108231,
              44.62711
            ],
            [
              -117.098221,
              44.640689
            ],
            [
              -117.094968,
              44.652011
            ],
            [
              -117.096791,
              44.657385
            ],
            [
              -117.095868,
              44.664737
            ],
            [
              -117.091223,
              44.668807
            ],
            [
              -117.080772,
              44.684161
            ],
            [
              -117.080555,
              44.686714
            ],
            [
              -117.07912,
              44.692175
            ],
            [
              -117.072221,
              44.700517
            ],
            [
              -117.063824,
              44.703623
            ],
            [
              -117.061799,
              44.706654
            ],
            [
              -117.060454,
              44.721668
            ],
            [
              -117.062273,
              44.727143
            ],
            [
              -117.044217,
              44.74514
            ],
            [
              -117.03827,
              44.748179
            ],
            [
              -117.013802,
              44.756841
            ],
            [
              -117.006045,
              44.756024
            ],
            [
              -116.998903,
              44.756382
            ],
            [
              -116.992003,
              44.759182
            ],
            [
              -116.986502,
              44.762381
            ],
            [
              -116.977802,
              44.767981
            ],
            [
              -116.972902,
              44.772581
            ],
            [
              -116.970902,
              44.773881
            ],
            [
              -116.966801,
              44.775181
            ],
            [
              -116.949001,
              44.777981
            ],
            [
              -116.9368,
              44.782881
            ],
            [
              -116.9347,
              44.783881
            ],
            [
              -116.9318,
              44.787181
            ],
            [
              -116.9307,
              44.789881
            ],
            [
              -116.9308,
              44.790981
            ],
            [
              -116.933099,
              44.794481
            ],
            [
              -116.933799,
              44.796781
            ],
            [
              -116.933699,
              44.798781
            ],
            [
              -116.931099,
              44.804781
            ],
            [
              -116.928099,
              44.808381
            ],
            [
              -116.920498,
              44.81438
            ],
            [
              -116.905771,
              44.834794
            ],
            [
              -116.901028451124,
              44.8415357708036
            ],
            [
              -116.896249,
              44.84833
            ],
            [
              -116.883598,
              44.858268
            ],
            [
              -116.865338,
              44.870599
            ],
            [
              -116.857038,
              44.880769
            ],
            [
              -116.852427,
              44.887577
            ],
            [
              -116.850512,
              44.893523
            ],
            [
              -116.846061,
              44.905249
            ],
            [
              -116.842108,
              44.914922
            ],
            [
              -116.838467,
              44.923601
            ],
            [
              -116.833632,
              44.928976
            ],
            [
              -116.832176,
              44.931373
            ],
            [
              -116.83199,
              44.933007
            ],
            [
              -116.835702,
              44.940633
            ],
            [
              -116.846461,
              44.951521
            ],
            [
              -116.850737,
              44.958113
            ],
            [
              -116.851406,
              44.959841
            ],
            [
              -116.858313,
              44.978761
            ],
            [
              -116.856754,
              44.984298
            ],
            [
              -116.846103,
              44.999878
            ],
            [
              -116.844625,
              45.001435
            ],
            [
              -116.844796,
              45.015312
            ],
            [
              -116.845847,
              45.01847
            ],
            [
              -116.848037,
              45.021728
            ],
            [
              -116.847944,
              45.022602
            ],
            [
              -116.841314,
              45.030907
            ],
            [
              -116.830115,
              45.035317
            ],
            [
              -116.825133,
              45.03784
            ],
            [
              -116.808576,
              45.050652
            ],
            [
              -116.797329,
              45.060267
            ],
            [
              -116.78371,
              45.076972
            ],
            [
              -116.78377192657202,
              45.078265731912005
            ],
            [
              -117.266573,
              45.08057
            ],
            [
              -117.563486,
              45.079446
            ],
            [
              -117.564033,
              44.992866
            ],
            [
              -117.777504,
              44.992161
            ],
            [
              -117.781825,
              45.013583
            ],
            [
              -117.793418,
              45.025795
            ],
            [
              -117.818062,
              45.025909
            ],
            [
              -117.84343,
              45.058477
            ],
            [
              -117.862587,
              45.054395
            ],
            [
              -117.873734,
              45.065639
            ],
            [
              -117.887626,
              45.057412
            ],
            [
              -117.906142,
              45.030232
            ],
            [
              -117.969083,
              44.995831
            ],
            [
              -117.998992,
              44.994348
            ],
            [
              -118.037125,
              45.004007
            ],
            [
              -118.06955,
              45.016873
            ],
            [
              -118.09213,
              45.041238
            ],
            [
              -118.130351,
              45.045024
            ],
            [
              -118.142367,
              45.027725
            ],
            [
              -118.161036,
              45.015711
            ],
            [
              -118.194901,
              45.017329
            ],
            [
              -118.214497,
              45.006585
            ],
            [
              -118.213869,
              44.989174
            ],
            [
              -118.244662,
              44.958396
            ],
            [
              -118.243674,
              44.956834
            ],
            [
              -118.244242,
              44.953344
            ],
            [
              -118.243956,
              44.950747
            ],
            [
              -118.244613,
              44.947001
            ],
            [
              -118.243397,
              44.94399
            ],
            [
              -118.239557,
              44.942946
            ],
            [
              -118.236686,
              44.941568
            ],
            [
              -118.232586,
              44.940557
            ],
            [
              -118.228981,
              44.940977
            ],
            [
              -118.227252,
              44.939659
            ],
            [
              -118.225236,
              44.93815
            ],
            [
              -118.224385,
              44.935382
            ],
            [
              -118.225874,
              44.933276
            ],
            [
              -118.227376,
              44.928204
            ],
            [
              -118.227855,
              44.926502
            ],
            [
              -118.231706,
              44.924206
            ],
            [
              -118.231248,
              44.921243
            ],
            [
              -118.22895,
              44.919461
            ],
            [
              -118.228945,
              44.915096
            ],
            [
              -118.232232,
              44.911868
            ],
            [
              -118.235905,
              44.909854
            ],
            [
              -118.23671,
              44.906737
            ],
            [
              -118.235008,
              44.904743
            ],
            [
              -118.236297,
              44.899273
            ],
            [
              -118.231635,
              44.894335
            ],
            [
              -118.231552,
              44.888244
            ],
            [
              -118.228828,
              44.881131
            ],
            [
              -118.226276,
              44.877583
            ],
            [
              -118.226025,
              44.874627
            ],
            [
              -118.227083,
              44.873476
            ],
            [
              -118.22816,
              44.870332
            ],
            [
              -118.2278,
              44.867141
            ],
            [
              -118.229229,
              44.864863
            ],
            [
              -118.234614,
              44.862831
            ],
            [
              -118.241369,
              44.862727
            ],
            [
              -118.249152,
              44.864364
            ],
            [
              -118.251418,
              44.86454
            ],
            [
              -118.254783,
              44.864368
            ],
            [
              -118.260672,
              44.86527
            ],
            [
              -118.264784,
              44.867051
            ],
            [
              -118.266588,
              44.868204
            ],
            [
              -118.268966,
              44.868972
            ],
            [
              -118.270898,
              44.868352
            ],
            [
              -118.2759,
              44.866573
            ],
            [
              -118.281093,
              44.865662
            ],
            [
              -118.288863,
              44.866112
            ],
            [
              -118.293141,
              44.864421
            ],
            [
              -118.29494,
              44.863352
            ],
            [
              -118.296005,
              44.861045
            ],
            [
              -118.294533,
              44.858681
            ],
            [
              -118.294408,
              44.85505
            ],
            [
              -118.29423,
              44.852653
            ],
            [
              -118.300909,
              44.849202
            ],
            [
              -118.306442,
              44.846717
            ],
            [
              -118.307609,
              44.842946
            ],
            [
              -118.311227,
              44.840357
            ],
            [
              -118.316142,
              44.837118
            ],
            [
              -118.317456,
              44.834564
            ],
            [
              -118.314436,
              44.832686
            ],
            [
              -118.310081,
              44.83113
            ],
            [
              -118.30847,
              44.828794
            ],
            [
              -118.309924,
              44.825145
            ],
            [
              -118.311002,
              44.823553
            ],
            [
              -118.309504,
              44.821856
            ],
            [
              -118.307151,
              44.820946
            ],
            [
              -118.306385,
              44.818795
            ],
            [
              -118.303667,
              44.817746
            ],
            [
              -118.298264,
              44.816338
            ],
            [
              -118.295068,
              44.813191
            ],
            [
              -118.294766,
              44.809633
            ],
            [
              -118.296853,
              44.806968
            ],
            [
              -118.298147,
              44.802249
            ],
            [
              -118.296858,
              44.799127
            ],
            [
              -118.298809,
              44.797894
            ],
            [
              -118.298894,
              44.795552
            ],
            [
              -118.297679,
              44.79409
            ],
            [
              -118.29458,
              44.790367
            ],
            [
              -118.293553,
              44.787656
            ],
            [
              -118.294084,
              44.785476
            ],
            [
              -118.293026,
              44.782166
            ],
            [
              -118.289011,
              44.778003
            ],
            [
              -118.285511,
              44.773801
            ],
            [
              -118.284633,
              44.76946
            ],
            [
              -118.287422,
              44.767165
            ],
            [
              -118.289513,
              44.763879
            ],
            [
              -118.286737,
              44.756001
            ],
            [
              -118.289699,
              44.749916
            ],
            [
              -118.294081,
              44.746602
            ],
            [
              -118.300094,
              44.746025
            ],
            [
              -118.306107,
              44.745763
            ],
            [
              -118.316235,
              44.744
            ],
            [
              -118.323964,
              44.741857
            ],
            [
              -118.330171,
              44.743514
            ],
            [
              -118.339207,
              44.743944
            ],
            [
              -118.35243,
              44.744738
            ],
            [
              -118.359384,
              44.745299
            ],
            [
              -118.36654,
              44.747219
            ],
            [
              -118.375048,
              44.745089
            ],
            [
              -118.381485,
              44.742288
            ],
            [
              -118.383481,
              44.740018
            ],
            [
              -118.387177,
              44.735949
            ],
            [
              -118.392296,
              44.730694
            ],
            [
              -118.396956,
              44.72604
            ],
            [
              -118.40088,
              44.725926
            ],
            [
              -118.403292,
              44.725038
            ],
            [
              -118.408731,
              44.726287
            ],
            [
              -118.412635,
              44.726863
            ],
            [
              -118.418141,
              44.725792
            ],
            [
              -118.4186,
              44.722341
            ],
            [
              -118.421859,
              44.719175
            ],
            [
              -118.429464,
              44.719195
            ],
            [
              -118.435673,
              44.721555
            ],
            [
              -118.44459,
              44.723667
            ],
            [
              -118.448431,
              44.721653
            ],
            [
              -118.456648,
              44.716856
            ],
            [
              -118.460029,
              44.71396
            ],
            [
              -118.461378,
              44.709596
            ],
            [
              -118.462059,
              44.707556
            ],
            [
              -118.465042,
              44.706174
            ],
            [
              -118.468018,
              44.705574
            ],
            [
              -118.468925,
              44.705028
            ],
            [
              -118.473427,
              44.705289
            ],
            [
              -118.475313,
              44.707768
            ],
            [
              -118.482557,
              44.709974
            ],
            [
              -118.489873,
              44.7118
            ],
            [
              -118.491584,
              44.712715
            ],
            [
              -118.495729,
              44.712425
            ],
            [
              -118.497422,
              44.711339
            ],
            [
              -118.501329,
              44.708754
            ],
            [
              -118.509324,
              44.706718
            ],
            [
              -118.515374,
              44.705317
            ],
            [
              -118.517027,
              44.705025
            ],
            [
              -118.516923,
              44.700331
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "41",
      "COUNTYFP": "015",
      "COUNTYNS": "01155129",
      "AFFGEOID": "0500000US41015",
      "GEOID": "41015",
      "NAME": "Curry",
      "LSAD": "06",
      "ALAND": 4217568363,
      "AWATER": 934534728,
      "County_state": "Curry,41"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -124.552441,
              42.840568
            ],
            [
              -124.544179,
              42.822958
            ],
            [
              -124.536073,
              42.814175
            ],
            [
              -124.524439,
              42.789793
            ],
            [
              -124.516236,
              42.753632
            ],
            [
              -124.514669,
              42.736806
            ],
            [
              -124.513368,
              42.735068
            ],
            [
              -124.510017,
              42.734746
            ],
            [
              -124.499122,
              42.738606
            ],
            [
              -124.498473,
              42.741077
            ],
            [
              -124.491679,
              42.741789
            ],
            [
              -124.473864,
              42.732671
            ],
            [
              -124.448418,
              42.689909
            ],
            [
              -124.447487,
              42.68474
            ],
            [
              -124.451484,
              42.677787
            ],
            [
              -124.45074,
              42.675798
            ],
            [
              -124.416774,
              42.661594
            ],
            [
              -124.413119,
              42.657934
            ],
            [
              -124.401177,
              42.627192
            ],
            [
              -124.399421,
              42.618079
            ],
            [
              -124.400918,
              42.597518
            ],
            [
              -124.389977,
              42.574758
            ],
            [
              -124.390664,
              42.566593
            ],
            [
              -124.399065,
              42.539928
            ],
            [
              -124.421381,
              42.491737
            ],
            [
              -124.423084,
              42.478952
            ],
            [
              -124.422038,
              42.461226
            ],
            [
              -124.435105,
              42.440163
            ],
            [
              -124.434882,
              42.434916
            ],
            [
              -124.428068,
              42.420333
            ],
            [
              -124.424863,
              42.395426
            ],
            [
              -124.424066,
              42.377242
            ],
            [
              -124.425554,
              42.351874
            ],
            [
              -124.427222,
              42.33488
            ],
            [
              -124.429288,
              42.331746
            ],
            [
              -124.410556,
              42.307431
            ],
            [
              -124.405148,
              42.278107
            ],
            [
              -124.408514,
              42.260588
            ],
            [
              -124.411534,
              42.254115
            ],
            [
              -124.410982,
              42.250547
            ],
            [
              -124.383633,
              42.22716
            ],
            [
              -124.377762,
              42.218809
            ],
            [
              -124.375553,
              42.20882
            ],
            [
              -124.376215,
              42.196381
            ],
            [
              -124.374949,
              42.193129
            ],
            [
              -124.373175,
              42.190218
            ],
            [
              -124.367751,
              42.188321
            ],
            [
              -124.361009,
              42.180752
            ],
            [
              -124.360318,
              42.162272
            ],
            [
              -124.363389,
              42.158588
            ],
            [
              -124.366832,
              42.15845
            ],
            [
              -124.366028,
              42.152343
            ],
            [
              -124.361563,
              42.143767
            ],
            [
              -124.355696,
              42.141964
            ],
            [
              -124.351784,
              42.134965
            ],
            [
              -124.351535,
              42.129796
            ],
            [
              -124.357122,
              42.118016
            ],
            [
              -124.356229,
              42.114952
            ],
            [
              -124.34101,
              42.092929
            ],
            [
              -124.314289,
              42.067864
            ],
            [
              -124.299649,
              42.051736
            ],
            [
              -124.287374,
              42.046016
            ],
            [
              -124.270464,
              42.045553
            ],
            [
              -124.214213,
              42.005939
            ],
            [
              -124.211605,
              41.99846
            ],
            [
              -124.126194,
              41.996992
            ],
            [
              -124.100921,
              41.996956
            ],
            [
              -124.100216,
              41.996842
            ],
            [
              -124.087827,
              41.996891
            ],
            [
              -124.086661,
              41.996869
            ],
            [
              -124.001188,
              41.996146
            ],
            [
              -123.834208,
              41.996116
            ],
            [
              -123.822044941707,
              41.995501925998596
            ],
            [
              -123.821307,
              41.996304
            ],
            [
              -123.819766,
              41.997917
            ],
            [
              -123.821615,
              41.999935
            ],
            [
              -123.826539,
              42.002017
            ],
            [
              -123.827502,
              42.006535
            ],
            [
              -123.827167,
              42.007777
            ],
            [
              -123.82937,
              42.01084
            ],
            [
              -123.833287,
              42.012042
            ],
            [
              -123.838849,
              42.016469
            ],
            [
              -123.838686,
              42.019528
            ],
            [
              -123.836984,
              42.022406
            ],
            [
              -123.841723,
              42.031385
            ],
            [
              -123.847758,
              42.040136
            ],
            [
              -123.844159,
              42.048475
            ],
            [
              -123.840521,
              42.052978
            ],
            [
              -123.841301,
              42.058753
            ],
            [
              -123.837033,
              42.062227
            ],
            [
              -123.834527,
              42.064272
            ],
            [
              -123.831452,
              42.06824
            ],
            [
              -123.828768,
              42.070564
            ],
            [
              -123.828112,
              42.073675
            ],
            [
              -123.824208,
              42.08491
            ],
            [
              -123.824298,
              42.091096
            ],
            [
              -123.829835,
              42.09836
            ],
            [
              -123.832052,
              42.107408
            ],
            [
              -123.837985,
              42.112271
            ],
            [
              -123.83976,
              42.120348
            ],
            [
              -123.843718,
              42.125248
            ],
            [
              -123.850176,
              42.126945
            ],
            [
              -123.855141,
              42.131992
            ],
            [
              -123.856163,
              42.138965
            ],
            [
              -123.84783,
              42.1461
            ],
            [
              -123.842871,
              42.149668
            ],
            [
              -123.838208,
              42.153582
            ],
            [
              -123.832351,
              42.155207
            ],
            [
              -123.827328,
              42.15596
            ],
            [
              -123.825182,
              42.157576
            ],
            [
              -123.822465,
              42.159241
            ],
            [
              -123.819176,
              42.163757
            ],
            [
              -123.813822,
              42.168137
            ],
            [
              -123.814536,
              42.172501
            ],
            [
              -123.813624,
              42.177048
            ],
            [
              -123.816711,
              42.187187
            ],
            [
              -123.820822,
              42.189687
            ],
            [
              -123.820324,
              42.19648
            ],
            [
              -123.812836,
              42.210382
            ],
            [
              -123.804148,
              42.21662
            ],
            [
              -123.797063,
              42.218962
            ],
            [
              -123.792834,
              42.224314
            ],
            [
              -123.793244,
              42.227881
            ],
            [
              -123.793287,
              42.232041
            ],
            [
              -123.792389,
              42.235377
            ],
            [
              -123.792449,
              42.236826
            ],
            [
              -123.795116,
              42.237489
            ],
            [
              -123.798206,
              42.238293
            ],
            [
              -123.803515,
              42.239436
            ],
            [
              -123.808558,
              42.241316
            ],
            [
              -123.811448,
              42.244901
            ],
            [
              -123.810733,
              42.246846
            ],
            [
              -123.811604,
              42.251246
            ],
            [
              -123.813847,
              42.254908
            ],
            [
              -123.815251,
              42.259439
            ],
            [
              -123.818341,
              42.264171
            ],
            [
              -123.820948,
              42.264667
            ],
            [
              -123.823708,
              42.267994
            ],
            [
              -123.826159,
              42.270973
            ],
            [
              -123.827201,
              42.278221
            ],
            [
              -123.830718,
              42.280866
            ],
            [
              -123.832827,
              42.284773
            ],
            [
              -123.836215,
              42.286879
            ],
            [
              -123.838031,
              42.289497
            ],
            [
              -123.839246,
              42.295966
            ],
            [
              -123.842733,
              42.297458
            ],
            [
              -123.846637,
              42.298754
            ],
            [
              -123.847609,
              42.302179
            ],
            [
              -123.845181,
              42.306134
            ],
            [
              -123.841912,
              42.313679
            ],
            [
              -123.845645,
              42.318135
            ],
            [
              -123.857646,
              42.318688
            ],
            [
              -123.866394,
              42.322362
            ],
            [
              -123.871803,
              42.325716
            ],
            [
              -123.873486,
              42.327885
            ],
            [
              -123.876146,
              42.330428
            ],
            [
              -123.876594,
              42.333533
            ],
            [
              -123.878604,
              42.337028
            ],
            [
              -123.877858,
              42.342428
            ],
            [
              -123.881707,
              42.345926
            ],
            [
              -123.884229,
              42.346519
            ],
            [
              -123.886993,
              42.350245
            ],
            [
              -123.891108,
              42.352934
            ],
            [
              -123.893745,
              42.352314
            ],
            [
              -123.89763,
              42.350325
            ],
            [
              -123.89972,
              42.34936
            ],
            [
              -123.900837,
              42.349309
            ],
            [
              -123.903056,
              42.349192
            ],
            [
              -123.905339,
              42.348463
            ],
            [
              -123.913034,
              42.347228
            ],
            [
              -123.916087,
              42.346626
            ],
            [
              -123.918276,
              42.346599
            ],
            [
              -123.920253,
              42.346479
            ],
            [
              -123.923685,
              42.349606
            ],
            [
              -123.927905,
              42.352493
            ],
            [
              -123.931902,
              42.353953
            ],
            [
              -123.93464,
              42.356358
            ],
            [
              -123.935327,
              42.359091
            ],
            [
              -123.937249,
              42.361536
            ],
            [
              -123.938715,
              42.361123
            ],
            [
              -123.942432,
              42.359361
            ],
            [
              -123.944749,
              42.358043
            ],
            [
              -123.949392,
              42.356639
            ],
            [
              -123.954132,
              42.357256
            ],
            [
              -123.960099,
              42.357003
            ],
            [
              -123.966162,
              42.357626
            ],
            [
              -123.970693,
              42.357495
            ],
            [
              -123.975472,
              42.358211
            ],
            [
              -123.979725,
              42.358776
            ],
            [
              -123.984686,
              42.358449
            ],
            [
              -123.989576,
              42.3584
            ],
            [
              -123.99623,
              42.358611
            ],
            [
              -123.999835,
              42.358681
            ],
            [
              -124.003386,
              42.358864
            ],
            [
              -124.010476,
              42.358613
            ],
            [
              -124.015153,
              42.359294
            ],
            [
              -124.018169,
              42.360561
            ],
            [
              -124.021087,
              42.36168
            ],
            [
              -124.024658,
              42.360305
            ],
            [
              -124.027275,
              42.359853
            ],
            [
              -124.028712,
              42.361049
            ],
            [
              -124.029887,
              42.362867
            ],
            [
              -124.031199,
              42.364743
            ],
            [
              -124.033093,
              42.366581
            ],
            [
              -124.034332,
              42.368231
            ],
            [
              -124.035496,
              42.369419
            ],
            [
              -124.036366,
              42.371775
            ],
            [
              -124.034996,
              42.376119
            ],
            [
              -124.036147,
              42.378608
            ],
            [
              -124.037296,
              42.38098
            ],
            [
              -124.038867,
              42.38364
            ],
            [
              -124.039586,
              42.384751
            ],
            [
              -124.041679,
              42.386146
            ],
            [
              -124.040949,
              42.388026
            ],
            [
              -124.039937,
              42.388526
            ],
            [
              -124.037786,
              42.390913
            ],
            [
              -124.037373,
              42.392389
            ],
            [
              -124.037225,
              42.394003
            ],
            [
              -124.03694,
              42.395591
            ],
            [
              -124.036266,
              42.397416
            ],
            [
              -124.036113,
              42.400056
            ],
            [
              -124.035527,
              42.402174
            ],
            [
              -124.032866,
              42.402959
            ],
            [
              -124.030212,
              42.402883
            ],
            [
              -124.027905,
              42.404021
            ],
            [
              -124.026747,
              42.406233
            ],
            [
              -124.026462,
              42.407024
            ],
            [
              -124.024983,
              42.407744
            ],
            [
              -124.023389,
              42.407623
            ],
            [
              -124.021367,
              42.409082
            ],
            [
              -124.019365,
              42.410293
            ],
            [
              -124.017458,
              42.410981
            ],
            [
              -124.015782,
              42.412302
            ],
            [
              -124.01303,
              42.412929
            ],
            [
              -124.01186,
              42.41561
            ],
            [
              -124.009561,
              42.418158
            ],
            [
              -124.00863,
              42.419622
            ],
            [
              -124.009323,
              42.421447
            ],
            [
              -124.010787,
              42.421756
            ],
            [
              -124.012549,
              42.424297
            ],
            [
              -124.010642,
              42.425775
            ],
            [
              -124.010647,
              42.428261
            ],
            [
              -124.013947,
              42.431349
            ],
            [
              -124.012724,
              42.436422
            ],
            [
              -124.010751,
              42.439762
            ],
            [
              -124.006021,
              42.442673
            ],
            [
              -124.002269,
              42.444161
            ],
            [
              -124.002747,
              42.446867
            ],
            [
              -124.00414,
              42.44903
            ],
            [
              -124.003432,
              42.451698
            ],
            [
              -124.002495,
              42.452642
            ],
            [
              -124.001334,
              42.455054
            ],
            [
              -124.00385,
              42.457274
            ],
            [
              -124.005787,
              42.459833
            ],
            [
              -124.003827,
              42.462452
            ],
            [
              -124.002201,
              42.46332
            ],
            [
              -124.001059,
              42.467028
            ],
            [
              -124.002859,
              42.471233
            ],
            [
              -124.001629,
              42.477902
            ],
            [
              -124.002033,
              42.484072
            ],
            [
              -124.005125,
              42.48719
            ],
            [
              -124.007612,
              42.489019
            ],
            [
              -124.009703,
              42.489458
            ],
            [
              -124.011291,
              42.490846
            ],
            [
              -124.010837,
              42.492508
            ],
            [
              -124.009427,
              42.494956
            ],
            [
              -124.007179,
              42.496323
            ],
            [
              -124.004141,
              42.496862
            ],
            [
              -124.000749,
              42.49714
            ],
            [
              -124.000101,
              42.497885
            ],
            [
              -123.999436,
              42.499083
            ],
            [
              -123.99803,
              42.50045
            ],
            [
              -123.994154,
              42.500128
            ],
            [
              -123.992599,
              42.49978
            ],
            [
              -123.989829,
              42.501683
            ],
            [
              -123.988611,
              42.501975
            ],
            [
              -123.987413,
              42.501042
            ],
            [
              -123.987013,
              42.500136
            ],
            [
              -123.986497,
              42.498429
            ],
            [
              -123.98343,
              42.497564
            ],
            [
              -123.978663,
              42.497483
            ],
            [
              -123.97647,
              42.496899
            ],
            [
              -123.974637,
              42.496379
            ],
            [
              -123.971991,
              42.495392
            ],
            [
              -123.968173,
              42.497059
            ],
            [
              -123.96325,
              42.497501
            ],
            [
              -123.961614,
              42.498434
            ],
            [
              -123.959485,
              42.498425
            ],
            [
              -123.95657,
              42.498715
            ],
            [
              -123.954546,
              42.499047
            ],
            [
              -123.950014,
              42.500395
            ],
            [
              -123.946693,
              42.50094
            ],
            [
              -123.944269,
              42.50141
            ],
            [
              -123.940505,
              42.501054
            ],
            [
              -123.937781,
              42.50068
            ],
            [
              -123.936024,
              42.499704
            ],
            [
              -123.934974,
              42.498078
            ],
            [
              -123.933305,
              42.496074
            ],
            [
              -123.93088,
              42.496112
            ],
            [
              -123.929334,
              42.495971
            ],
            [
              -123.929099,
              42.49514
            ],
            [
              -123.926563,
              42.494413
            ],
            [
              -123.923803,
              42.495012
            ],
            [
              -123.919214,
              42.494752
            ],
            [
              -123.917355,
              42.495798
            ],
            [
              -123.914243,
              42.49589
            ],
            [
              -123.911828,
              42.496948
            ],
            [
              -123.908496,
              42.497066
            ],
            [
              -123.904789,
              42.499221
            ],
            [
              -123.901263,
              42.498546
            ],
            [
              -123.897753,
              42.499051
            ],
            [
              -123.894308,
              42.499844
            ],
            [
              -123.891991,
              42.500231
            ],
            [
              -123.89003,
              42.501047
            ],
            [
              -123.888905,
              42.502828
            ],
            [
              -123.886767,
              42.503446
            ],
            [
              -123.886141,
              42.505162
            ],
            [
              -123.885399,
              42.506167
            ],
            [
              -123.885544,
              42.50731
            ],
            [
              -123.883882,
              42.508656
            ],
            [
              -123.88053,
              42.509393
            ],
            [
              -123.879974,
              42.510791
            ],
            [
              -123.880333,
              42.511493
            ],
            [
              -123.87945,
              42.512292
            ],
            [
              -123.878034,
              42.512771
            ],
            [
              -123.876191,
              42.512934
            ],
            [
              -123.875842,
              42.514274
            ],
            [
              -123.876741,
              42.515204
            ],
            [
              -123.876191,
              42.516735
            ],
            [
              -123.877456,
              42.518499
            ],
            [
              -123.878314,
              42.519816
            ],
            [
              -123.879056,
              42.521823
            ],
            [
              -123.878577,
              42.524272
            ],
            [
              -123.877287,
              42.52561
            ],
            [
              -123.875862,
              42.527148
            ],
            [
              -123.875082,
              42.529847
            ],
            [
              -123.87327,
              42.531954
            ],
            [
              -123.871512,
              42.533769
            ],
            [
              -123.868754,
              42.536235
            ],
            [
              -123.866747,
              42.538195
            ],
            [
              -123.865789,
              42.539396
            ],
            [
              -123.865462,
              42.541515
            ],
            [
              -123.863317,
              42.54267
            ],
            [
              -123.862014,
              42.543448
            ],
            [
              -123.860259,
              42.545242
            ],
            [
              -123.858651,
              42.546875
            ],
            [
              -123.856397,
              42.548419
            ],
            [
              -123.854923,
              42.550759
            ],
            [
              -123.856334,
              42.553151
            ],
            [
              -123.858875,
              42.555582
            ],
            [
              -123.859278,
              42.556921
            ],
            [
              -123.860654,
              42.559437
            ],
            [
              -123.860326,
              42.561592
            ],
            [
              -123.860256,
              42.563056
            ],
            [
              -123.857687,
              42.566074
            ],
            [
              -123.855135,
              42.567844
            ],
            [
              -123.853567,
              42.570103
            ],
            [
              -123.852451,
              42.571998
            ],
            [
              -123.850035,
              42.574596
            ],
            [
              -123.850156,
              42.57659
            ],
            [
              -123.849599,
              42.578622
            ],
            [
              -123.849623,
              42.579886
            ],
            [
              -123.849745,
              42.581051
            ],
            [
              -123.849558,
              42.583718
            ],
            [
              -123.847702,
              42.585353
            ],
            [
              -123.845754,
              42.586399
            ],
            [
              -123.84381,
              42.587187
            ],
            [
              -123.841423,
              42.587759
            ],
            [
              -123.838664,
              42.590116
            ],
            [
              -123.836239,
              42.591543
            ],
            [
              -123.834413,
              42.592718
            ],
            [
              -123.831967,
              42.593947
            ],
            [
              -123.830403,
              42.595873
            ],
            [
              -123.830216,
              42.598436
            ],
            [
              -123.828889,
              42.601057
            ],
            [
              -123.828493,
              42.604424
            ],
            [
              -123.828483,
              42.605636
            ],
            [
              -123.826272,
              42.607151
            ],
            [
              -123.823584,
              42.60794
            ],
            [
              -123.821633,
              42.609134
            ],
            [
              -123.82086,
              42.611139
            ],
            [
              -123.819399,
              42.612703
            ],
            [
              -123.819424,
              42.614387
            ],
            [
              -123.82081,
              42.617911
            ],
            [
              -123.823239,
              42.621294
            ],
            [
              -123.825834,
              42.623544
            ],
            [
              -123.828028,
              42.625052
            ],
            [
              -123.818299,
              42.647908
            ],
            [
              -123.803025,
              42.666282
            ],
            [
              -123.774382,
              42.700532
            ],
            [
              -123.77371,
              42.70162
            ],
            [
              -123.773833,
              42.702858
            ],
            [
              -123.771158,
              42.704452
            ],
            [
              -123.769227,
              42.705498
            ],
            [
              -123.768918,
              42.706834
            ],
            [
              -123.769324,
              42.707619
            ],
            [
              -123.768226,
              42.708502
            ],
            [
              -123.766875,
              42.709109
            ],
            [
              -123.765428,
              42.710926
            ],
            [
              -123.764423,
              42.713689
            ],
            [
              -123.76295,
              42.714956
            ],
            [
              -123.761104,
              42.7164
            ],
            [
              -123.760166,
              42.717319
            ],
            [
              -123.759306,
              42.717662
            ],
            [
              -123.758398,
              42.719369
            ],
            [
              -123.757263,
              42.720584
            ],
            [
              -123.756485,
              42.722321
            ],
            [
              -123.755122,
              42.724467
            ],
            [
              -123.755029,
              42.726344
            ],
            [
              -123.755777,
              42.727351
            ],
            [
              -123.756,
              42.728619
            ],
            [
              -123.75571,
              42.730328
            ],
            [
              -123.754954,
              42.73156
            ],
            [
              -123.754326,
              42.732693
            ],
            [
              -123.75345,
              42.733969
            ],
            [
              -123.753166,
              42.735487
            ],
            [
              -123.751666,
              42.73775
            ],
            [
              -123.750743,
              42.739643
            ],
            [
              -123.749615,
              42.741821
            ],
            [
              -123.747493,
              42.742647
            ],
            [
              -123.74584,
              42.744741
            ],
            [
              -123.744373,
              42.746335
            ],
            [
              -123.741647,
              42.748376
            ],
            [
              -123.740001,
              42.750805
            ],
            [
              -123.737506,
              42.753288
            ],
            [
              -123.737911,
              42.75719
            ],
            [
              -123.736595,
              42.759769
            ],
            [
              -123.735347,
              42.76254
            ],
            [
              -123.734344,
              42.765685
            ],
            [
              -123.733383,
              42.768086
            ],
            [
              -123.731233,
              42.769899
            ],
            [
              -123.729809,
              42.771694
            ],
            [
              -123.728293,
              42.7736
            ],
            [
              -123.725305,
              42.774113
            ],
            [
              -123.722049,
              42.77575
            ],
            [
              -123.720212,
              42.77733
            ],
            [
              -123.720422,
              42.78045
            ],
            [
              -123.719166,
              42.782464
            ],
            [
              -123.716853,
              42.783753
            ],
            [
              -123.716492,
              42.784061
            ],
            [
              -123.732315,
              42.780131
            ],
            [
              -123.783304,
              42.799426
            ],
            [
              -123.811553,
              42.788837
            ],
            [
              -123.849858,
              42.788994
            ],
            [
              -123.868491,
              42.77484
            ],
            [
              -123.925717,
              42.774514
            ],
            [
              -123.925942,
              42.73971
            ],
            [
              -123.937288,
              42.729097
            ],
            [
              -123.957026,
              42.729051
            ],
            [
              -123.956985,
              42.714569
            ],
            [
              -123.976631,
              42.714558
            ],
            [
              -123.976677,
              42.700118
            ],
            [
              -123.996296,
              42.7001
            ],
            [
              -123.996291,
              42.685651
            ],
            [
              -124.089872,
              42.685454
            ],
            [
              -124.089872,
              42.678208
            ],
            [
              -124.1192,
              42.678339
            ],
            [
              -124.132918,
              42.666697
            ],
            [
              -124.138655,
              42.739956
            ],
            [
              -124.159966,
              42.739947
            ],
            [
              -124.160163,
              42.790087
            ],
            [
              -124.164889,
              42.817825
            ],
            [
              -124.160658,
              42.828288
            ],
            [
              -124.141185,
              42.828475
            ],
            [
              -124.141475,
              42.850586
            ],
            [
              -124.162074,
              42.86973
            ],
            [
              -124.161308,
              42.883499
            ],
            [
              -124.180704,
              42.88245
            ],
            [
              -124.189984,
              42.896696
            ],
            [
              -124.214201,
              42.904006
            ],
            [
              -124.219623,
              42.933782
            ],
            [
              -124.239394,
              42.933751
            ],
            [
              -124.258388,
              42.954946
            ],
            [
              -124.479636362958,
              42.9543325389089
            ],
            [
              -124.480938,
              42.951495
            ],
            [
              -124.500141,
              42.917502
            ],
            [
              -124.552441,
              42.840568
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "41",
      "COUNTYFP": "043",
      "COUNTYNS": "01135857",
      "AFFGEOID": "0500000US41043",
      "GEOID": "41043",
      "NAME": "Linn",
      "LSAD": "06",
      "ALAND": 5923493230,
      "AWATER": 50497756,
      "County_state": "Linn,41"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -123.253609,
              44.572516
            ],
            [
              -123.260151,
              44.555148
            ],
            [
              -123.241754,
              44.544575
            ],
            [
              -123.253376,
              44.52887
            ],
            [
              -123.238067,
              44.527969
            ],
            [
              -123.212619,
              44.504978
            ],
            [
              -123.214409,
              44.491014
            ],
            [
              -123.216462,
              44.468473
            ],
            [
              -123.205287,
              44.443038
            ],
            [
              -123.209194,
              44.435239
            ],
            [
              -123.228054,
              44.431889
            ],
            [
              -123.21938,
              44.419436
            ],
            [
              -123.229311,
              44.415073
            ],
            [
              -123.223969,
              44.393036
            ],
            [
              -123.244952,
              44.384143
            ],
            [
              -123.219407,
              44.360069
            ],
            [
              -123.235294,
              44.356521
            ],
            [
              -123.228599,
              44.344606
            ],
            [
              -123.245678,
              44.332099
            ],
            [
              -123.21691,
              44.324952
            ],
            [
              -123.216919,
              44.311648
            ],
            [
              -123.197359,
              44.308573
            ],
            [
              -123.191102,
              44.287587
            ],
            [
              -123.180388,
              44.283725
            ],
            [
              -123.178411,
              44.250918
            ],
            [
              -123.15034,
              44.226627
            ],
            [
              -123.165428,
              44.200071
            ],
            [
              -122.905755,
              44.200736
            ],
            [
              -122.903999,
              44.259129
            ],
            [
              -122.865545,
              44.25898
            ],
            [
              -122.865465,
              44.287317
            ],
            [
              -122.830451,
              44.282166
            ],
            [
              -122.823775,
              44.274389
            ],
            [
              -122.807156,
              44.284171
            ],
            [
              -122.776804,
              44.283735
            ],
            [
              -122.762735,
              44.290537
            ],
            [
              -122.750524,
              44.284948
            ],
            [
              -122.725098,
              44.285595
            ],
            [
              -122.724687,
              44.283046
            ],
            [
              -122.668915,
              44.268902
            ],
            [
              -122.646812,
              44.266618
            ],
            [
              -122.64493,
              44.253458
            ],
            [
              -122.593471,
              44.244427
            ],
            [
              -122.575169,
              44.234628
            ],
            [
              -122.577019,
              44.227881
            ],
            [
              -122.533745,
              44.233706
            ],
            [
              -122.513635,
              44.231654
            ],
            [
              -122.503685,
              44.221746
            ],
            [
              -122.465628,
              44.223039
            ],
            [
              -122.448436,
              44.216748
            ],
            [
              -122.434885,
              44.221306
            ],
            [
              -122.384561,
              44.217063
            ],
            [
              -122.379589,
              44.20944
            ],
            [
              -122.367197,
              44.20938
            ],
            [
              -122.352623,
              44.220742
            ],
            [
              -122.327313,
              44.231189
            ],
            [
              -122.32715,
              44.252841
            ],
            [
              -122.02979,
              44.258569
            ],
            [
              -121.908934,
              44.262396
            ],
            [
              -121.819429,
              44.262394
            ],
            [
              -121.824693,
              44.282624
            ],
            [
              -121.841346,
              44.285652
            ],
            [
              -121.83704,
              44.35781
            ],
            [
              -121.84588,
              44.366497
            ],
            [
              -121.843138,
              44.39263
            ],
            [
              -121.840977,
              44.410526
            ],
            [
              -121.846615,
              44.433879
            ],
            [
              -121.841128,
              44.444912
            ],
            [
              -121.849512,
              44.468364
            ],
            [
              -121.84688,
              44.49109
            ],
            [
              -121.809045,
              44.516279
            ],
            [
              -121.816073,
              44.534596
            ],
            [
              -121.803286,
              44.555321
            ],
            [
              -121.793895,
              44.594056
            ],
            [
              -121.81069,
              44.61439
            ],
            [
              -121.797945,
              44.640313
            ],
            [
              -121.800015,
              44.683425
            ],
            [
              -121.816685,
              44.683424
            ],
            [
              -121.923159,
              44.683549
            ],
            [
              -121.962941,
              44.68411
            ],
            [
              -121.96749,
              44.685602
            ],
            [
              -121.97141,
              44.689144
            ],
            [
              -121.976425,
              44.69108
            ],
            [
              -121.983751,
              44.69266
            ],
            [
              -121.993292,
              44.68978
            ],
            [
              -121.999079,
              44.688352
            ],
            [
              -122.003216,
              44.68911
            ],
            [
              -122.006654,
              44.687983
            ],
            [
              -122.011684,
              44.687317
            ],
            [
              -122.015121,
              44.690229
            ],
            [
              -122.024975,
              44.690144
            ],
            [
              -122.030039,
              44.687671
            ],
            [
              -122.035258,
              44.686656
            ],
            [
              -122.039055,
              44.687706
            ],
            [
              -122.04635,
              44.691011
            ],
            [
              -122.051434,
              44.692894
            ],
            [
              -122.0569,
              44.694178
            ],
            [
              -122.061635,
              44.697318
            ],
            [
              -122.069282,
              44.69739
            ],
            [
              -122.074393,
              44.700448
            ],
            [
              -122.078944,
              44.699783
            ],
            [
              -122.083245,
              44.699045
            ],
            [
              -122.088617,
              44.702552
            ],
            [
              -122.100326,
              44.707154
            ],
            [
              -122.11344,
              44.710107
            ],
            [
              -122.122132,
              44.715378
            ],
            [
              -122.12896,
              44.717922
            ],
            [
              -122.135698,
              44.723358
            ],
            [
              -122.150599,
              44.725726
            ],
            [
              -122.155611,
              44.718333
            ],
            [
              -122.164706,
              44.714959
            ],
            [
              -122.180853,
              44.706504
            ],
            [
              -122.192186,
              44.703261
            ],
            [
              -122.202715,
              44.697105
            ],
            [
              -122.214318,
              44.694484
            ],
            [
              -122.227833,
              44.697088
            ],
            [
              -122.237069,
              44.704531
            ],
            [
              -122.246528,
              44.719361
            ],
            [
              -122.254384,
              44.722977
            ],
            [
              -122.261423,
              44.729275
            ],
            [
              -122.274,
              44.738496
            ],
            [
              -122.283098,
              44.750717
            ],
            [
              -122.291324,
              44.753636
            ],
            [
              -122.296097,
              44.754091
            ],
            [
              -122.303524,
              44.755254
            ],
            [
              -122.310549,
              44.756103
            ],
            [
              -122.316446,
              44.758388
            ],
            [
              -122.322718,
              44.759068
            ],
            [
              -122.329284,
              44.756591
            ],
            [
              -122.333683,
              44.75579
            ],
            [
              -122.336684,
              44.755734
            ],
            [
              -122.34242,
              44.756516
            ],
            [
              -122.347857,
              44.75775
            ],
            [
              -122.355524,
              44.757357
            ],
            [
              -122.362732,
              44.757428
            ],
            [
              -122.366389,
              44.756808
            ],
            [
              -122.371577,
              44.755518
            ],
            [
              -122.376045,
              44.752974
            ],
            [
              -122.378865,
              44.751957
            ],
            [
              -122.381036,
              44.752111
            ],
            [
              -122.383081,
              44.752352
            ],
            [
              -122.386845,
              44.753646
            ],
            [
              -122.390156,
              44.754811
            ],
            [
              -122.392407,
              44.754558
            ],
            [
              -122.394738,
              44.752959
            ],
            [
              -122.397203,
              44.75236
            ],
            [
              -122.399446,
              44.752984
            ],
            [
              -122.401858,
              44.754382
            ],
            [
              -122.403779,
              44.755125
            ],
            [
              -122.406205,
              44.755023
            ],
            [
              -122.408809,
              44.754621
            ],
            [
              -122.410359,
              44.754346
            ],
            [
              -122.411097,
              44.753438
            ],
            [
              -122.410937,
              44.752926
            ],
            [
              -122.412695,
              44.752023
            ],
            [
              -122.414492,
              44.752356
            ],
            [
              -122.416994,
              44.753375
            ],
            [
              -122.41958,
              44.753491
            ],
            [
              -122.421376,
              44.751886
            ],
            [
              -122.423647,
              44.75155
            ],
            [
              -122.426632,
              44.752002
            ],
            [
              -122.429338,
              44.753435
            ],
            [
              -122.431572,
              44.754882
            ],
            [
              -122.433819,
              44.755919
            ],
            [
              -122.436137,
              44.755819
            ],
            [
              -122.438783,
              44.754438
            ],
            [
              -122.440091,
              44.753678
            ],
            [
              -122.441905,
              44.753515
            ],
            [
              -122.44243,
              44.75415
            ],
            [
              -122.444231,
              44.755003
            ],
            [
              -122.447091,
              44.755072
            ],
            [
              -122.450813,
              44.755195
            ],
            [
              -122.454774,
              44.755259
            ],
            [
              -122.458002,
              44.75565
            ],
            [
              -122.46124,
              44.755564
            ],
            [
              -122.464101,
              44.754897
            ],
            [
              -122.467866,
              44.754273
            ],
            [
              -122.470417,
              44.753379
            ],
            [
              -122.473156,
              44.752278
            ],
            [
              -122.474541,
              44.751915
            ],
            [
              -122.475835,
              44.752096
            ],
            [
              -122.475675,
              44.75299
            ],
            [
              -122.476467,
              44.754232
            ],
            [
              -122.478258,
              44.755464
            ],
            [
              -122.481846,
              44.755605
            ],
            [
              -122.486483,
              44.754458
            ],
            [
              -122.490695,
              44.75289
            ],
            [
              -122.496195,
              44.751138
            ],
            [
              -122.501572,
              44.749968
            ],
            [
              -122.506784,
              44.750537
            ],
            [
              -122.512655,
              44.752913
            ],
            [
              -122.518744,
              44.753373
            ],
            [
              -122.520625,
              44.755686
            ],
            [
              -122.52245,
              44.758945
            ],
            [
              -122.521256,
              44.761641
            ],
            [
              -122.520334,
              44.762853
            ],
            [
              -122.52161,
              44.763867
            ],
            [
              -122.523344,
              44.764485
            ],
            [
              -122.528749,
              44.76607
            ],
            [
              -122.532911,
              44.766867
            ],
            [
              -122.536119,
              44.766324
            ],
            [
              -122.537755,
              44.765496
            ],
            [
              -122.540638,
              44.765062
            ],
            [
              -122.54266,
              44.766314
            ],
            [
              -122.54898,
              44.767638
            ],
            [
              -122.556019,
              44.768234
            ],
            [
              -122.562071,
              44.767939
            ],
            [
              -122.566683,
              44.769474
            ],
            [
              -122.568409,
              44.772864
            ],
            [
              -122.5701,
              44.774732
            ],
            [
              -122.573794,
              44.775857
            ],
            [
              -122.578702,
              44.776546
            ],
            [
              -122.588104,
              44.777292
            ],
            [
              -122.593578,
              44.777673
            ],
            [
              -122.603101,
              44.782118
            ],
            [
              -122.606978,
              44.783701
            ],
            [
              -122.611503,
              44.786099
            ],
            [
              -122.616984,
              44.788655
            ],
            [
              -122.622786,
              44.78888
            ],
            [
              -122.632606,
              44.788015
            ],
            [
              -122.638823,
              44.787193
            ],
            [
              -122.64073,
              44.784738
            ],
            [
              -122.648746,
              44.78225
            ],
            [
              -122.656936,
              44.780571
            ],
            [
              -122.66754,
              44.778335
            ],
            [
              -122.676463,
              44.777917
            ],
            [
              -122.683555,
              44.776088
            ],
            [
              -122.689209,
              44.775669
            ],
            [
              -122.694438,
              44.775859
            ],
            [
              -122.697446,
              44.777454
            ],
            [
              -122.701388,
              44.777481
            ],
            [
              -122.708391,
              44.779094
            ],
            [
              -122.716993,
              44.78457
            ],
            [
              -122.721243,
              44.790957
            ],
            [
              -122.728135,
              44.791524
            ],
            [
              -122.736741,
              44.789446
            ],
            [
              -122.749974,
              44.78819
            ],
            [
              -122.762124,
              44.791419
            ],
            [
              -122.768114,
              44.792845
            ],
            [
              -122.772931,
              44.793276
            ],
            [
              -122.77858,
              44.792526
            ],
            [
              -122.783465,
              44.794422
            ],
            [
              -122.78898,
              44.793372
            ],
            [
              -122.792196,
              44.792047
            ],
            [
              -122.797648,
              44.791547
            ],
            [
              -122.801282,
              44.791417
            ],
            [
              -122.803071,
              44.789802
            ],
            [
              -122.805408,
              44.786855
            ],
            [
              -122.809493,
              44.785181
            ],
            [
              -122.811391,
              44.785434
            ],
            [
              -122.813617,
              44.783911
            ],
            [
              -122.813565,
              44.782
            ],
            [
              -122.813727,
              44.779735
            ],
            [
              -122.815899,
              44.777942
            ],
            [
              -122.81799,
              44.777914
            ],
            [
              -122.820025,
              44.777457
            ],
            [
              -122.821185,
              44.775889
            ],
            [
              -122.823412,
              44.7737
            ],
            [
              -122.826814,
              44.77104
            ],
            [
              -122.830161,
              44.770554
            ],
            [
              -122.833507,
              44.770298
            ],
            [
              -122.83684,
              44.770537
            ],
            [
              -122.839763,
              44.770896
            ],
            [
              -122.841921,
              44.77368
            ],
            [
              -122.844954,
              44.774686
            ],
            [
              -122.847426,
              44.773846
            ],
            [
              -122.848233,
              44.771538
            ],
            [
              -122.846922,
              44.769073
            ],
            [
              -122.848438,
              44.765261
            ],
            [
              -122.850445,
              44.761822
            ],
            [
              -122.854899,
              44.759736
            ],
            [
              -122.858014,
              44.758773
            ],
            [
              -122.861047,
              44.758075
            ],
            [
              -122.864106,
              44.758157
            ],
            [
              -122.864965,
              44.7583
            ],
            [
              -122.873276,
              44.752196
            ],
            [
              -122.874229,
              44.751208
            ],
            [
              -122.882731,
              44.751034
            ],
            [
              -122.890258,
              44.748623
            ],
            [
              -122.894692,
              44.743955
            ],
            [
              -122.897648,
              44.744958
            ],
            [
              -122.900612,
              44.741044
            ],
            [
              -122.909232,
              44.736472
            ],
            [
              -122.909888,
              44.732507
            ],
            [
              -122.919518,
              44.726906
            ],
            [
              -122.919464,
              44.724935
            ],
            [
              -122.93708,
              44.715087
            ],
            [
              -122.957598,
              44.721032
            ],
            [
              -122.973577,
              44.705549
            ],
            [
              -123.000013,
              44.686916
            ],
            [
              -123.018756,
              44.698855
            ],
            [
              -123.020708,
              44.725619
            ],
            [
              -123.041567,
              44.725243
            ],
            [
              -123.042009,
              44.737719
            ],
            [
              -123.105867,
              44.749306
            ],
            [
              -123.111578,
              44.759049
            ],
            [
              -123.143843,
              44.748914
            ],
            [
              -123.1327,
              44.743363
            ],
            [
              -123.149025,
              44.720225
            ],
            [
              -123.121105,
              44.69108
            ],
            [
              -123.116892,
              44.676966
            ],
            [
              -123.092509,
              44.675887
            ],
            [
              -123.073574,
              44.650495
            ],
            [
              -123.104133,
              44.63933
            ],
            [
              -123.17088,
              44.634245
            ],
            [
              -123.171626,
              44.621011
            ],
            [
              -123.19519,
              44.604953
            ],
            [
              -123.184791,
              44.587451
            ],
            [
              -123.221793,
              44.584597
            ],
            [
              -123.253609,
              44.572516
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "41",
      "COUNTYFP": "047",
      "COUNTYNS": "01135859",
      "AFFGEOID": "0500000US41047",
      "GEOID": "41047",
      "NAME": "Marion",
      "LSAD": "06",
      "ALAND": 3057624577,
      "AWATER": 26554204,
      "County_state": "Marion,41"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -123.179857,
              44.845611
            ],
            [
              -123.176101,
              44.831759
            ],
            [
              -123.131732,
              44.826432
            ],
            [
              -123.1114,
              44.821691
            ],
            [
              -123.091479,
              44.811523
            ],
            [
              -123.097713,
              44.799099
            ],
            [
              -123.122064,
              44.793774
            ],
            [
              -123.145812,
              44.770231
            ],
            [
              -123.143843,
              44.748914
            ],
            [
              -123.111578,
              44.759049
            ],
            [
              -123.105867,
              44.749306
            ],
            [
              -123.042009,
              44.737719
            ],
            [
              -123.041567,
              44.725243
            ],
            [
              -123.020708,
              44.725619
            ],
            [
              -123.018756,
              44.698855
            ],
            [
              -123.000013,
              44.686916
            ],
            [
              -122.973577,
              44.705549
            ],
            [
              -122.957598,
              44.721032
            ],
            [
              -122.93708,
              44.715087
            ],
            [
              -122.919464,
              44.724935
            ],
            [
              -122.919518,
              44.726906
            ],
            [
              -122.909888,
              44.732507
            ],
            [
              -122.909232,
              44.736472
            ],
            [
              -122.900612,
              44.741044
            ],
            [
              -122.897648,
              44.744958
            ],
            [
              -122.894692,
              44.743955
            ],
            [
              -122.890258,
              44.748623
            ],
            [
              -122.882731,
              44.751034
            ],
            [
              -122.874229,
              44.751208
            ],
            [
              -122.873276,
              44.752196
            ],
            [
              -122.864965,
              44.7583
            ],
            [
              -122.864106,
              44.758157
            ],
            [
              -122.861047,
              44.758075
            ],
            [
              -122.858014,
              44.758773
            ],
            [
              -122.854899,
              44.759736
            ],
            [
              -122.850445,
              44.761822
            ],
            [
              -122.848438,
              44.765261
            ],
            [
              -122.846922,
              44.769073
            ],
            [
              -122.848233,
              44.771538
            ],
            [
              -122.847426,
              44.773846
            ],
            [
              -122.844954,
              44.774686
            ],
            [
              -122.841921,
              44.77368
            ],
            [
              -122.839763,
              44.770896
            ],
            [
              -122.83684,
              44.770537
            ],
            [
              -122.833507,
              44.770298
            ],
            [
              -122.830161,
              44.770554
            ],
            [
              -122.826814,
              44.77104
            ],
            [
              -122.823412,
              44.7737
            ],
            [
              -122.821185,
              44.775889
            ],
            [
              -122.820025,
              44.777457
            ],
            [
              -122.81799,
              44.777914
            ],
            [
              -122.815899,
              44.777942
            ],
            [
              -122.813727,
              44.779735
            ],
            [
              -122.813565,
              44.782
            ],
            [
              -122.813617,
              44.783911
            ],
            [
              -122.811391,
              44.785434
            ],
            [
              -122.809493,
              44.785181
            ],
            [
              -122.805408,
              44.786855
            ],
            [
              -122.803071,
              44.789802
            ],
            [
              -122.801282,
              44.791417
            ],
            [
              -122.797648,
              44.791547
            ],
            [
              -122.792196,
              44.792047
            ],
            [
              -122.78898,
              44.793372
            ],
            [
              -122.783465,
              44.794422
            ],
            [
              -122.77858,
              44.792526
            ],
            [
              -122.772931,
              44.793276
            ],
            [
              -122.768114,
              44.792845
            ],
            [
              -122.762124,
              44.791419
            ],
            [
              -122.749974,
              44.78819
            ],
            [
              -122.736741,
              44.789446
            ],
            [
              -122.728135,
              44.791524
            ],
            [
              -122.721243,
              44.790957
            ],
            [
              -122.716993,
              44.78457
            ],
            [
              -122.708391,
              44.779094
            ],
            [
              -122.701388,
              44.777481
            ],
            [
              -122.697446,
              44.777454
            ],
            [
              -122.694438,
              44.775859
            ],
            [
              -122.689209,
              44.775669
            ],
            [
              -122.683555,
              44.776088
            ],
            [
              -122.676463,
              44.777917
            ],
            [
              -122.66754,
              44.778335
            ],
            [
              -122.656936,
              44.780571
            ],
            [
              -122.648746,
              44.78225
            ],
            [
              -122.64073,
              44.784738
            ],
            [
              -122.638823,
              44.787193
            ],
            [
              -122.632606,
              44.788015
            ],
            [
              -122.622786,
              44.78888
            ],
            [
              -122.616984,
              44.788655
            ],
            [
              -122.611503,
              44.786099
            ],
            [
              -122.606978,
              44.783701
            ],
            [
              -122.603101,
              44.782118
            ],
            [
              -122.593578,
              44.777673
            ],
            [
              -122.588104,
              44.777292
            ],
            [
              -122.578702,
              44.776546
            ],
            [
              -122.573794,
              44.775857
            ],
            [
              -122.5701,
              44.774732
            ],
            [
              -122.568409,
              44.772864
            ],
            [
              -122.566683,
              44.769474
            ],
            [
              -122.562071,
              44.767939
            ],
            [
              -122.556019,
              44.768234
            ],
            [
              -122.54898,
              44.767638
            ],
            [
              -122.54266,
              44.766314
            ],
            [
              -122.540638,
              44.765062
            ],
            [
              -122.537755,
              44.765496
            ],
            [
              -122.536119,
              44.766324
            ],
            [
              -122.532911,
              44.766867
            ],
            [
              -122.528749,
              44.76607
            ],
            [
              -122.523344,
              44.764485
            ],
            [
              -122.52161,
              44.763867
            ],
            [
              -122.520334,
              44.762853
            ],
            [
              -122.521256,
              44.761641
            ],
            [
              -122.52245,
              44.758945
            ],
            [
              -122.520625,
              44.755686
            ],
            [
              -122.518744,
              44.753373
            ],
            [
              -122.512655,
              44.752913
            ],
            [
              -122.506784,
              44.750537
            ],
            [
              -122.501572,
              44.749968
            ],
            [
              -122.496195,
              44.751138
            ],
            [
              -122.490695,
              44.75289
            ],
            [
              -122.486483,
              44.754458
            ],
            [
              -122.481846,
              44.755605
            ],
            [
              -122.478258,
              44.755464
            ],
            [
              -122.476467,
              44.754232
            ],
            [
              -122.475675,
              44.75299
            ],
            [
              -122.475835,
              44.752096
            ],
            [
              -122.474541,
              44.751915
            ],
            [
              -122.473156,
              44.752278
            ],
            [
              -122.470417,
              44.753379
            ],
            [
              -122.467866,
              44.754273
            ],
            [
              -122.464101,
              44.754897
            ],
            [
              -122.46124,
              44.755564
            ],
            [
              -122.458002,
              44.75565
            ],
            [
              -122.454774,
              44.755259
            ],
            [
              -122.450813,
              44.755195
            ],
            [
              -122.447091,
              44.755072
            ],
            [
              -122.444231,
              44.755003
            ],
            [
              -122.44243,
              44.75415
            ],
            [
              -122.441905,
              44.753515
            ],
            [
              -122.440091,
              44.753678
            ],
            [
              -122.438783,
              44.754438
            ],
            [
              -122.436137,
              44.755819
            ],
            [
              -122.433819,
              44.755919
            ],
            [
              -122.431572,
              44.754882
            ],
            [
              -122.429338,
              44.753435
            ],
            [
              -122.426632,
              44.752002
            ],
            [
              -122.423647,
              44.75155
            ],
            [
              -122.421376,
              44.751886
            ],
            [
              -122.41958,
              44.753491
            ],
            [
              -122.416994,
              44.753375
            ],
            [
              -122.414492,
              44.752356
            ],
            [
              -122.412695,
              44.752023
            ],
            [
              -122.410937,
              44.752926
            ],
            [
              -122.411097,
              44.753438
            ],
            [
              -122.410359,
              44.754346
            ],
            [
              -122.408809,
              44.754621
            ],
            [
              -122.406205,
              44.755023
            ],
            [
              -122.403779,
              44.755125
            ],
            [
              -122.401858,
              44.754382
            ],
            [
              -122.399446,
              44.752984
            ],
            [
              -122.397203,
              44.75236
            ],
            [
              -122.394738,
              44.752959
            ],
            [
              -122.392407,
              44.754558
            ],
            [
              -122.390156,
              44.754811
            ],
            [
              -122.386845,
              44.753646
            ],
            [
              -122.383081,
              44.752352
            ],
            [
              -122.381036,
              44.752111
            ],
            [
              -122.378865,
              44.751957
            ],
            [
              -122.376045,
              44.752974
            ],
            [
              -122.371577,
              44.755518
            ],
            [
              -122.366389,
              44.756808
            ],
            [
              -122.362732,
              44.757428
            ],
            [
              -122.355524,
              44.757357
            ],
            [
              -122.347857,
              44.75775
            ],
            [
              -122.34242,
              44.756516
            ],
            [
              -122.336684,
              44.755734
            ],
            [
              -122.333683,
              44.75579
            ],
            [
              -122.329284,
              44.756591
            ],
            [
              -122.322718,
              44.759068
            ],
            [
              -122.316446,
              44.758388
            ],
            [
              -122.310549,
              44.756103
            ],
            [
              -122.303524,
              44.755254
            ],
            [
              -122.296097,
              44.754091
            ],
            [
              -122.291324,
              44.753636
            ],
            [
              -122.283098,
              44.750717
            ],
            [
              -122.274,
              44.738496
            ],
            [
              -122.261423,
              44.729275
            ],
            [
              -122.254384,
              44.722977
            ],
            [
              -122.246528,
              44.719361
            ],
            [
              -122.237069,
              44.704531
            ],
            [
              -122.227833,
              44.697088
            ],
            [
              -122.214318,
              44.694484
            ],
            [
              -122.202715,
              44.697105
            ],
            [
              -122.192186,
              44.703261
            ],
            [
              -122.180853,
              44.706504
            ],
            [
              -122.164706,
              44.714959
            ],
            [
              -122.155611,
              44.718333
            ],
            [
              -122.150599,
              44.725726
            ],
            [
              -122.135698,
              44.723358
            ],
            [
              -122.12896,
              44.717922
            ],
            [
              -122.122132,
              44.715378
            ],
            [
              -122.11344,
              44.710107
            ],
            [
              -122.100326,
              44.707154
            ],
            [
              -122.088617,
              44.702552
            ],
            [
              -122.083245,
              44.699045
            ],
            [
              -122.078944,
              44.699783
            ],
            [
              -122.074393,
              44.700448
            ],
            [
              -122.069282,
              44.69739
            ],
            [
              -122.061635,
              44.697318
            ],
            [
              -122.0569,
              44.694178
            ],
            [
              -122.051434,
              44.692894
            ],
            [
              -122.04635,
              44.691011
            ],
            [
              -122.039055,
              44.687706
            ],
            [
              -122.035258,
              44.686656
            ],
            [
              -122.030039,
              44.687671
            ],
            [
              -122.024975,
              44.690144
            ],
            [
              -122.015121,
              44.690229
            ],
            [
              -122.011684,
              44.687317
            ],
            [
              -122.006654,
              44.687983
            ],
            [
              -122.003216,
              44.68911
            ],
            [
              -121.999079,
              44.688352
            ],
            [
              -121.993292,
              44.68978
            ],
            [
              -121.983751,
              44.69266
            ],
            [
              -121.976425,
              44.69108
            ],
            [
              -121.97141,
              44.689144
            ],
            [
              -121.96749,
              44.685602
            ],
            [
              -121.962941,
              44.68411
            ],
            [
              -121.923159,
              44.683549
            ],
            [
              -121.816685,
              44.683424
            ],
            [
              -121.800015,
              44.683425
            ],
            [
              -121.793496,
              44.695775
            ],
            [
              -121.804694,
              44.729169
            ],
            [
              -121.788524,
              44.747923
            ],
            [
              -121.774175,
              44.752723
            ],
            [
              -121.772321,
              44.759964
            ],
            [
              -121.759958,
              44.762326
            ],
            [
              -121.767329,
              44.766981
            ],
            [
              -121.773204,
              44.775728
            ],
            [
              -121.78388,
              44.772362
            ],
            [
              -121.81183,
              44.781453
            ],
            [
              -121.820278,
              44.800134
            ],
            [
              -121.80683,
              44.814106
            ],
            [
              -121.775926,
              44.819846
            ],
            [
              -121.764831,
              44.820267
            ],
            [
              -121.752994,
              44.829919
            ],
            [
              -121.742096,
              44.846434
            ],
            [
              -121.753182,
              44.86124
            ],
            [
              -121.734223,
              44.88572
            ],
            [
              -121.765322,
              44.885719
            ],
            [
              -122.388999,
              44.88572
            ],
            [
              -122.394341,
              44.898972
            ],
            [
              -122.399554,
              44.904077
            ],
            [
              -122.441487,
              44.903958
            ],
            [
              -122.4585,
              44.915212
            ],
            [
              -122.486327,
              44.922669
            ],
            [
              -122.508106,
              44.919599
            ],
            [
              -122.517542,
              44.932797
            ],
            [
              -122.535999,
              44.937161
            ],
            [
              -122.559757,
              44.965195
            ],
            [
              -122.567385,
              44.982564
            ],
            [
              -122.592472,
              44.988875
            ],
            [
              -122.595523,
              45.019679
            ],
            [
              -122.634355,
              45.033852
            ],
            [
              -122.649252,
              45.032454
            ],
            [
              -122.669941,
              45.046674
            ],
            [
              -122.705115,
              45.059023
            ],
            [
              -122.719677,
              45.079641
            ],
            [
              -122.743301,
              45.096291
            ],
            [
              -122.750028,
              45.107871
            ],
            [
              -122.788546,
              45.130164
            ],
            [
              -122.776907,
              45.153233
            ],
            [
              -122.756883,
              45.172802
            ],
            [
              -122.753184,
              45.187517
            ],
            [
              -122.757473,
              45.209145
            ],
            [
              -122.745299,
              45.232772
            ],
            [
              -122.750085,
              45.233603
            ],
            [
              -122.751113,
              45.245342
            ],
            [
              -122.745153,
              45.251078
            ],
            [
              -122.74144,
              45.259049
            ],
            [
              -122.73903,
              45.259359
            ],
            [
              -122.764287,
              45.259551
            ],
            [
              -122.784756,
              45.259562
            ],
            [
              -122.784781,
              45.273888
            ],
            [
              -122.834765,
              45.273822
            ],
            [
              -122.849872,
              45.259717
            ],
            [
              -122.849925,
              45.259697
            ],
            [
              -122.862457,
              45.253283
            ],
            [
              -122.900435,
              45.257063
            ],
            [
              -122.915073,
              45.267163
            ],
            [
              -122.947317,
              45.267485
            ],
            [
              -122.967579,
              45.285083
            ],
            [
              -122.974568,
              45.279932
            ],
            [
              -122.976136,
              45.274232
            ],
            [
              -122.999265,
              45.261554
            ],
            [
              -122.995372,
              45.236617
            ],
            [
              -122.999145,
              45.224105
            ],
            [
              -123.04117,
              45.220788
            ],
            [
              -123.051106,
              45.211305
            ],
            [
              -123.041321,
              45.198918
            ],
            [
              -123.020521,
              45.199841
            ],
            [
              -123.015382,
              45.177017
            ],
            [
              -123.000701,
              45.165416
            ],
            [
              -123.007346,
              45.159498
            ],
            [
              -123.029257,
              45.160547
            ],
            [
              -123.028136,
              45.141313
            ],
            [
              -123.015037,
              45.136757
            ],
            [
              -122.996568,
              45.117286
            ],
            [
              -123.018118,
              45.100961
            ],
            [
              -123.036121,
              45.097729
            ],
            [
              -123.068667,
              45.07486
            ],
            [
              -123.075577,
              45.014513
            ],
            [
              -123.065415,
              44.990718
            ],
            [
              -123.040164,
              44.988153
            ],
            [
              -123.037316,
              44.972795
            ],
            [
              -123.037566,
              44.965581
            ],
            [
              -123.038229,
              44.955937
            ],
            [
              -123.044433,
              44.944231
            ],
            [
              -123.073159,
              44.927933
            ],
            [
              -123.110942,
              44.930455
            ],
            [
              -123.148436,
              44.862009
            ],
            [
              -123.170891,
              44.864462
            ],
            [
              -123.179857,
              44.845611
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "41",
      "COUNTYFP": "037",
      "COUNTYNS": "01135854",
      "AFFGEOID": "0500000US41037",
      "GEOID": "41037",
      "NAME": "Lake",
      "LSAD": "06",
      "ALAND": 21078875989,
      "AWATER": 568514572,
      "County_state": "Lake,41"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -121.348471,
              43.356753
            ],
            [
              -121.349679,
              42.746644
            ],
            [
              -120.996685,
              42.746349
            ],
            [
              -120.967852,
              42.743783
            ],
            [
              -120.883327,
              42.744214
            ],
            [
              -120.880436,
              42.483263
            ],
            [
              -120.881071,
              42.338788
            ],
            [
              -120.881069,
              42.336912
            ],
            [
              -120.879924974291,
              41.993779694142894
            ],
            [
              -120.879481,
              41.993781
            ],
            [
              -120.812279,
              41.994183
            ],
            [
              -120.693941,
              41.993676
            ],
            [
              -120.692219,
              41.993677
            ],
            [
              -120.647173,
              41.993084
            ],
            [
              -120.501069,
              41.993785
            ],
            [
              -120.328540815297,
              41.9931316036052
            ],
            [
              -120.326005,
              41.993122
            ],
            [
              -120.286424,
              41.993058
            ],
            [
              -120.181563,
              41.994588
            ],
            [
              -120.001058,
              41.995139
            ],
            [
              -119.999168,
              41.99454
            ],
            [
              -119.999171,
              41.994836
            ],
            [
              -119.986889,
              41.996077
            ],
            [
              -119.886511,
              41.997473
            ],
            [
              -119.872336,
              41.997477
            ],
            [
              -119.848907,
              41.997281
            ],
            [
              -119.790087,
              41.997544
            ],
            [
              -119.72573,
              41.996296
            ],
            [
              -119.444598,
              41.995478
            ],
            [
              -119.360177,
              41.994384
            ],
            [
              -119.360121480893,
              41.9943837248054
            ],
            [
              -119.359475,
              42.134683
            ],
            [
              -119.363579,
              42.137231
            ],
            [
              -119.365337,
              42.748958
            ],
            [
              -119.705858,
              42.748791
            ],
            [
              -119.734201,
              42.746636
            ],
            [
              -119.943779,
              42.746371
            ],
            [
              -119.943504,
              42.917079
            ],
            [
              -119.931595,
              42.917005
            ],
            [
              -119.932611,
              43.178963
            ],
            [
              -119.896969,
              43.179017
            ],
            [
              -119.894972,
              43.585412
            ],
            [
              -119.896581,
              43.611164
            ],
            [
              -120.37599,
              43.611494
            ],
            [
              -120.974959,
              43.615384
            ],
            [
              -121.004974,
              43.61732
            ],
            [
              -121.332969,
              43.616629
            ],
            [
              -121.332413,
              43.356663
            ],
            [
              -121.348471,
              43.356753
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "41",
      "COUNTYFP": "009",
      "COUNTYNS": "01135847",
      "AFFGEOID": "0500000US41009",
      "GEOID": "41009",
      "NAME": "Columbia",
      "LSAD": "06",
      "ALAND": 1704178258,
      "AWATER": 80260317,
      "County_state": "Columbia,41"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -123.36374313768701,
              46.1463247963846
            ],
            [
              -123.367438,
              46.039528
            ],
            [
              -123.360362,
              45.86598
            ],
            [
              -123.358826,
              45.836657
            ],
            [
              -123.361622,
              45.779579
            ],
            [
              -123.34041,
              45.779849
            ],
            [
              -123.030873,
              45.779159
            ],
            [
              -123.031837,
              45.75142
            ],
            [
              -122.990344,
              45.750835
            ],
            [
              -122.990368,
              45.736459
            ],
            [
              -122.928901,
              45.735688
            ],
            [
              -122.929146,
              45.721482
            ],
            [
              -122.784735,
              45.720946
            ],
            [
              -122.762189526428,
              45.7285767375573
            ],
            [
              -122.760108,
              45.734413
            ],
            [
              -122.76097413795301,
              45.7503749615244
            ],
            [
              -122.761451,
              45.759163
            ],
            [
              -122.769532,
              45.780583
            ],
            [
              -122.795605,
              45.81
            ],
            [
              -122.795963,
              45.825024
            ],
            [
              -122.785696,
              45.844216
            ],
            [
              -122.785515,
              45.850536
            ],
            [
              -122.785026,
              45.867699
            ],
            [
              -122.798091,
              45.884333
            ],
            [
              -122.81151,
              45.912725
            ],
            [
              -122.806193,
              45.932416
            ],
            [
              -122.813998,
              45.960984
            ],
            [
              -122.837638,
              45.98082
            ],
            [
              -122.856158,
              46.014469
            ],
            [
              -122.878092,
              46.031281
            ],
            [
              -122.88346281138001,
              46.055670000814196
            ],
            [
              -122.884478,
              46.06028
            ],
            [
              -122.904119,
              46.083734
            ],
            [
              -122.962681,
              46.104817
            ],
            [
              -123.004233,
              46.133823
            ],
            [
              -123.009436,
              46.136043
            ],
            [
              -123.022147,
              46.13911
            ],
            [
              -123.03382,
              46.144336
            ],
            [
              -123.041297,
              46.146351
            ],
            [
              -123.051064,
              46.153599
            ],
            [
              -123.105021,
              46.177676
            ],
            [
              -123.115904,
              46.185268
            ],
            [
              -123.166414,
              46.188973
            ],
            [
              -123.213054,
              46.172541
            ],
            [
              -123.231196,
              46.16615
            ],
            [
              -123.251233,
              46.156452
            ],
            [
              -123.280166,
              46.144843
            ],
            [
              -123.301034,
              46.144632
            ],
            [
              -123.332335,
              46.146132
            ],
            [
              -123.36374313768701,
              46.1463247963846
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "41",
      "COUNTYFP": "025",
      "COUNTYNS": "01135852",
      "AFFGEOID": "0500000US41025",
      "GEOID": "41025",
      "NAME": "Harney",
      "LSAD": "06",
      "ALAND": 26248071434,
      "AWATER": 241568256,
      "County_state": "Harney,41"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -119.943504,
              42.917079
            ],
            [
              -119.943779,
              42.746371
            ],
            [
              -119.734201,
              42.746636
            ],
            [
              -119.705858,
              42.748791
            ],
            [
              -119.365337,
              42.748958
            ],
            [
              -119.363579,
              42.137231
            ],
            [
              -119.359475,
              42.134683
            ],
            [
              -119.360121480893,
              41.9943837248054
            ],
            [
              -119.324182599298,
              41.9942055845967
            ],
            [
              -119.251033,
              41.993843
            ],
            [
              -119.231876,
              41.994212
            ],
            [
              -119.20828,
              41.993177
            ],
            [
              -119.001022,
              41.993793
            ],
            [
              -118.795612,
              41.992394
            ],
            [
              -118.777228,
              41.992671
            ],
            [
              -118.775869,
              41.992692
            ],
            [
              -118.696409,
              41.991794
            ],
            [
              -118.601806,
              41.993895
            ],
            [
              -118.501002,
              41.995446
            ],
            [
              -118.197369215928,
              41.9969940811635
            ],
            [
              -118.195281,
              42.275813
            ],
            [
              -118.214698,
              42.275891
            ],
            [
              -118.216832,
              42.914703
            ],
            [
              -118.228793,
              42.914625
            ],
            [
              -118.228069,
              43.348964
            ],
            [
              -118.232059,
              43.374901
            ],
            [
              -118.2321,
              43.770829
            ],
            [
              -118.228044,
              43.797042
            ],
            [
              -118.227645,
              44.039862
            ],
            [
              -118.347856,
              44.039075
            ],
            [
              -118.587125,
              44.040266
            ],
            [
              -118.81689,
              44.047828
            ],
            [
              -118.816931,
              43.960733
            ],
            [
              -119.657183,
              43.95892
            ],
            [
              -119.775402,
              43.959086
            ],
            [
              -119.777528,
              43.697957
            ],
            [
              -119.897434,
              43.6982
            ],
            [
              -119.896581,
              43.611164
            ],
            [
              -119.894972,
              43.585412
            ],
            [
              -119.896969,
              43.179017
            ],
            [
              -119.932611,
              43.178963
            ],
            [
              -119.931595,
              42.917005
            ],
            [
              -119.943504,
              42.917079
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "41",
      "COUNTYFP": "049",
      "COUNTYNS": "01135860",
      "AFFGEOID": "0500000US41049",
      "GEOID": "41049",
      "NAME": "Morrow",
      "LSAD": "06",
      "ALAND": 5259049905,
      "AWATER": 44372295,
      "County_state": "Morrow,41"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -120.004762,
              45.517513
            ],
            [
              -120.006555,
              45.257706
            ],
            [
              -119.882346,
              45.256916
            ],
            [
              -119.883914,
              45.169152
            ],
            [
              -119.759759,
              45.169211
            ],
            [
              -119.760731,
              45.081278
            ],
            [
              -119.790831,
              45.081253
            ],
            [
              -119.790699,
              45.067823
            ],
            [
              -119.791162,
              44.994755
            ],
            [
              -119.672228,
              44.995117
            ],
            [
              -119.667065,
              44.994664
            ],
            [
              -119.162966,
              44.996241
            ],
            [
              -119.163037,
              45.082943
            ],
            [
              -119.146424,
              45.08294
            ],
            [
              -119.145144,
              45.515909
            ],
            [
              -119.248214,
              45.516076
            ],
            [
              -119.248547,
              45.601935
            ],
            [
              -119.43464,
              45.603056
            ],
            [
              -119.43478,
              45.80253
            ],
            [
              -119.432149953782,
              45.918250445405
            ],
            [
              -119.450256,
              45.917354
            ],
            [
              -119.487829,
              45.906307
            ],
            [
              -119.524632,
              45.908605
            ],
            [
              -119.571584,
              45.925456
            ],
            [
              -119.600549,
              45.919581
            ],
            [
              -119.623393,
              45.905639
            ],
            [
              -119.669877,
              45.856867
            ],
            [
              -119.772927,
              45.845578
            ],
            [
              -119.802655,
              45.84753
            ],
            [
              -119.868135,
              45.835962
            ],
            [
              -119.868272037091,
              45.8359407146783
            ],
            [
              -119.876144,
              45.834718
            ],
            [
              -119.907461,
              45.828135
            ],
            [
              -119.965744,
              45.824365
            ],
            [
              -119.999501176112,
              45.812481718848495
            ],
            [
              -120.004762,
              45.517513
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "41",
      "COUNTYFP": "003",
      "COUNTYNS": "01155126",
      "AFFGEOID": "0500000US41003",
      "GEOID": "41003",
      "NAME": "Benton",
      "LSAD": "06",
      "ALAND": 1748712527,
      "AWATER": 7740249,
      "County_state": "Benton,41"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -123.816709,
              44.344622
            ],
            [
              -123.816525,
              44.315303
            ],
            [
              -123.775789,
              44.316167
            ],
            [
              -123.775598,
              44.283547
            ],
            [
              -123.715377,
              44.280267
            ],
            [
              -123.593323,
              44.276694
            ],
            [
              -123.59329,
              44.281915
            ],
            [
              -123.371411,
              44.281021
            ],
            [
              -123.328667,
              44.283725
            ],
            [
              -123.180388,
              44.283725
            ],
            [
              -123.191102,
              44.287587
            ],
            [
              -123.197359,
              44.308573
            ],
            [
              -123.216919,
              44.311648
            ],
            [
              -123.21691,
              44.324952
            ],
            [
              -123.245678,
              44.332099
            ],
            [
              -123.228599,
              44.344606
            ],
            [
              -123.235294,
              44.356521
            ],
            [
              -123.219407,
              44.360069
            ],
            [
              -123.244952,
              44.384143
            ],
            [
              -123.223969,
              44.393036
            ],
            [
              -123.229311,
              44.415073
            ],
            [
              -123.21938,
              44.419436
            ],
            [
              -123.228054,
              44.431889
            ],
            [
              -123.209194,
              44.435239
            ],
            [
              -123.205287,
              44.443038
            ],
            [
              -123.216462,
              44.468473
            ],
            [
              -123.214409,
              44.491014
            ],
            [
              -123.212619,
              44.504978
            ],
            [
              -123.238067,
              44.527969
            ],
            [
              -123.253376,
              44.52887
            ],
            [
              -123.241754,
              44.544575
            ],
            [
              -123.260151,
              44.555148
            ],
            [
              -123.253609,
              44.572516
            ],
            [
              -123.221793,
              44.584597
            ],
            [
              -123.184791,
              44.587451
            ],
            [
              -123.19519,
              44.604953
            ],
            [
              -123.171626,
              44.621011
            ],
            [
              -123.17088,
              44.634245
            ],
            [
              -123.104133,
              44.63933
            ],
            [
              -123.073574,
              44.650495
            ],
            [
              -123.092509,
              44.675887
            ],
            [
              -123.116892,
              44.676966
            ],
            [
              -123.121105,
              44.69108
            ],
            [
              -123.149025,
              44.720225
            ],
            [
              -123.182025,
              44.720085
            ],
            [
              -123.301882,
              44.719835
            ],
            [
              -123.521402,
              44.72089
            ],
            [
              -123.580966,
              44.719415
            ],
            [
              -123.602609,
              44.721154
            ],
            [
              -123.602817,
              44.651159
            ],
            [
              -123.597677,
              44.433109
            ],
            [
              -123.720071,
              44.433374
            ],
            [
              -123.72014,
              44.389506
            ],
            [
              -123.715538,
              44.360458
            ],
            [
              -123.735153,
              44.36035
            ],
            [
              -123.734996,
              44.345778
            ],
            [
              -123.816709,
              44.344622
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "41",
      "COUNTYFP": "039",
      "COUNTYNS": "01135855",
      "AFFGEOID": "0500000US41039",
      "GEOID": "41039",
      "NAME": "Lane",
      "LSAD": "06",
      "ALAND": 11799215321,
      "AWATER": 437134106,
      "County_state": "Lane,41"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -124.150267,
              43.91085
            ],
            [
              -124.158646168589,
              43.8637200082681
            ],
            [
              -124.064585,
              43.862277
            ],
            [
              -123.946291,
              43.862682
            ],
            [
              -123.946529,
              43.871689
            ],
            [
              -123.925479,
              43.865947
            ],
            [
              -123.925917,
              43.898476
            ],
            [
              -123.902391,
              43.896281
            ],
            [
              -123.872399,
              43.916733
            ],
            [
              -123.852602,
              43.920553
            ],
            [
              -123.827004,
              43.944837
            ],
            [
              -123.75871,
              43.943882
            ],
            [
              -123.7038,
              43.944319
            ],
            [
              -123.703628,
              43.937399
            ],
            [
              -123.658767,
              43.936474
            ],
            [
              -123.658909,
              43.921672
            ],
            [
              -123.619012,
              43.921117
            ],
            [
              -123.619574,
              43.891672
            ],
            [
              -123.579579,
              43.89113
            ],
            [
              -123.579782,
              43.86829
            ],
            [
              -123.529005,
              43.86833
            ],
            [
              -123.529153,
              43.830061
            ],
            [
              -123.470251,
              43.831183
            ],
            [
              -123.470371,
              43.810056
            ],
            [
              -123.3482,
              43.809212
            ],
            [
              -123.348246,
              43.78017
            ],
            [
              -123.140144,
              43.77966
            ],
            [
              -123.137951,
              43.765073
            ],
            [
              -123.137211,
              43.605906
            ],
            [
              -123.107318,
              43.605889
            ],
            [
              -123.107686,
              43.54001
            ],
            [
              -122.987626,
              43.538988
            ],
            [
              -122.932533,
              43.544357
            ],
            [
              -122.741845,
              43.544654
            ],
            [
              -122.741716,
              43.437395
            ],
            [
              -122.622914,
              43.440262
            ],
            [
              -122.508438,
              43.440226
            ],
            [
              -122.490469,
              43.441481
            ],
            [
              -122.270653,
              43.440011
            ],
            [
              -122.132034,
              43.440221
            ],
            [
              -122.153016,
              43.447571
            ],
            [
              -122.138621,
              43.45514
            ],
            [
              -122.128721,
              43.496887
            ],
            [
              -122.132474,
              43.510434
            ],
            [
              -122.149534,
              43.520693
            ],
            [
              -122.131009,
              43.557284
            ],
            [
              -122.093513,
              43.579215
            ],
            [
              -122.071291,
              43.579585
            ],
            [
              -122.038966,
              43.601615
            ],
            [
              -122.002362,
              43.615498
            ],
            [
              -122.000462,
              43.625944
            ],
            [
              -121.964918,
              43.627045
            ],
            [
              -121.980509,
              43.639685
            ],
            [
              -121.986267,
              43.661708
            ],
            [
              -121.973498,
              43.706227
            ],
            [
              -121.981168,
              43.743368
            ],
            [
              -121.97481,
              43.748039
            ],
            [
              -121.962098,
              43.763537
            ],
            [
              -121.968894,
              43.774753
            ],
            [
              -121.963765,
              43.809745
            ],
            [
              -121.975565,
              43.812541
            ],
            [
              -121.975391,
              43.856908
            ],
            [
              -121.95296,
              43.871681
            ],
            [
              -121.932797,
              43.893816
            ],
            [
              -121.939608,
              43.900941
            ],
            [
              -121.928213,
              43.909181
            ],
            [
              -121.901482,
              43.9159
            ],
            [
              -121.891442,
              43.908658
            ],
            [
              -121.869144,
              43.912057
            ],
            [
              -121.857063,
              43.965248
            ],
            [
              -121.836461,
              43.971444
            ],
            [
              -121.845087,
              43.976239
            ],
            [
              -121.829239,
              43.994496
            ],
            [
              -121.822029,
              44.011659
            ],
            [
              -121.835193,
              44.037024
            ],
            [
              -121.819709,
              44.051441
            ],
            [
              -121.802451,
              44.052368
            ],
            [
              -121.789211,
              44.075019
            ],
            [
              -121.779748,
              44.080121
            ],
            [
              -121.769073,
              44.101586
            ],
            [
              -121.772735,
              44.139831
            ],
            [
              -121.784259,
              44.14809
            ],
            [
              -121.771647,
              44.167583
            ],
            [
              -121.781511,
              44.194809
            ],
            [
              -121.786562,
              44.233215
            ],
            [
              -121.80046,
              44.259339
            ],
            [
              -121.819429,
              44.262394
            ],
            [
              -121.908934,
              44.262396
            ],
            [
              -122.02979,
              44.258569
            ],
            [
              -122.32715,
              44.252841
            ],
            [
              -122.327313,
              44.231189
            ],
            [
              -122.352623,
              44.220742
            ],
            [
              -122.367197,
              44.20938
            ],
            [
              -122.379589,
              44.20944
            ],
            [
              -122.384561,
              44.217063
            ],
            [
              -122.434885,
              44.221306
            ],
            [
              -122.448436,
              44.216748
            ],
            [
              -122.465628,
              44.223039
            ],
            [
              -122.503685,
              44.221746
            ],
            [
              -122.513635,
              44.231654
            ],
            [
              -122.533745,
              44.233706
            ],
            [
              -122.577019,
              44.227881
            ],
            [
              -122.575169,
              44.234628
            ],
            [
              -122.593471,
              44.244427
            ],
            [
              -122.64493,
              44.253458
            ],
            [
              -122.646812,
              44.266618
            ],
            [
              -122.668915,
              44.268902
            ],
            [
              -122.724687,
              44.283046
            ],
            [
              -122.725098,
              44.285595
            ],
            [
              -122.750524,
              44.284948
            ],
            [
              -122.762735,
              44.290537
            ],
            [
              -122.776804,
              44.283735
            ],
            [
              -122.807156,
              44.284171
            ],
            [
              -122.823775,
              44.274389
            ],
            [
              -122.830451,
              44.282166
            ],
            [
              -122.865465,
              44.287317
            ],
            [
              -122.865545,
              44.25898
            ],
            [
              -122.903999,
              44.259129
            ],
            [
              -122.905755,
              44.200736
            ],
            [
              -123.165428,
              44.200071
            ],
            [
              -123.15034,
              44.226627
            ],
            [
              -123.178411,
              44.250918
            ],
            [
              -123.180388,
              44.283725
            ],
            [
              -123.328667,
              44.283725
            ],
            [
              -123.371411,
              44.281021
            ],
            [
              -123.59329,
              44.281915
            ],
            [
              -123.593323,
              44.276694
            ],
            [
              -123.715377,
              44.280267
            ],
            [
              -123.775598,
              44.283547
            ],
            [
              -123.941075,
              44.282951
            ],
            [
              -123.941072,
              44.277375
            ],
            [
              -124.013824,
              44.277329
            ],
            [
              -124.063303,
              44.275094
            ],
            [
              -124.115851774242,
              44.276233322854296
            ],
            [
              -124.115953,
              44.274641
            ],
            [
              -124.114869,
              44.272721
            ],
            [
              -124.109744,
              44.270597
            ],
            [
              -124.108945,
              44.265475
            ],
            [
              -124.111054,
              44.235071
            ],
            [
              -124.115671,
              44.206554
            ],
            [
              -124.114424,
              44.198164
            ],
            [
              -124.117006,
              44.171913
            ],
            [
              -124.125824,
              44.12613
            ],
            [
              -124.122406,
              44.104442
            ],
            [
              -124.133547,
              44.035845
            ],
            [
              -124.142704,
              43.958182
            ],
            [
              -124.150267,
              43.91085
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "41",
      "COUNTYFP": "031",
      "COUNTYNS": "01155132",
      "AFFGEOID": "0500000US41031",
      "GEOID": "41031",
      "NAME": "Jefferson",
      "LSAD": "06",
      "ALAND": 4615975255,
      "AWATER": 26213922,
      "County_state": "Jefferson,41"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -121.84688,
              44.49109
            ],
            [
              -121.849512,
              44.468364
            ],
            [
              -121.841128,
              44.444912
            ],
            [
              -121.846615,
              44.433879
            ],
            [
              -121.840977,
              44.410526
            ],
            [
              -121.843138,
              44.39263
            ],
            [
              -121.2293,
              44.393036
            ],
            [
              -121.226793,
              44.390703
            ],
            [
              -121.107534,
              44.3906
            ],
            [
              -120.988375,
              44.390265
            ],
            [
              -120.988824,
              44.476484
            ],
            [
              -120.826899,
              44.476499
            ],
            [
              -120.827555,
              44.562793
            ],
            [
              -120.386561,
              44.564009
            ],
            [
              -120.385687,
              44.761175
            ],
            [
              -120.404676,
              44.794719
            ],
            [
              -120.371441,
              44.821769
            ],
            [
              -120.915695,
              44.822969
            ],
            [
              -121.08948,
              44.823272
            ],
            [
              -121.725068,
              44.825519
            ],
            [
              -121.752994,
              44.829919
            ],
            [
              -121.764831,
              44.820267
            ],
            [
              -121.775926,
              44.819846
            ],
            [
              -121.80683,
              44.814106
            ],
            [
              -121.820278,
              44.800134
            ],
            [
              -121.81183,
              44.781453
            ],
            [
              -121.78388,
              44.772362
            ],
            [
              -121.773204,
              44.775728
            ],
            [
              -121.767329,
              44.766981
            ],
            [
              -121.759958,
              44.762326
            ],
            [
              -121.772321,
              44.759964
            ],
            [
              -121.774175,
              44.752723
            ],
            [
              -121.788524,
              44.747923
            ],
            [
              -121.804694,
              44.729169
            ],
            [
              -121.793496,
              44.695775
            ],
            [
              -121.800015,
              44.683425
            ],
            [
              -121.797945,
              44.640313
            ],
            [
              -121.81069,
              44.61439
            ],
            [
              -121.793895,
              44.594056
            ],
            [
              -121.803286,
              44.555321
            ],
            [
              -121.816073,
              44.534596
            ],
            [
              -121.809045,
              44.516279
            ],
            [
              -121.84688,
              44.49109
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "41",
      "COUNTYFP": "071",
      "COUNTYNS": "01135866",
      "AFFGEOID": "0500000US41071",
      "GEOID": "41071",
      "NAME": "Yamhill",
      "LSAD": "06",
      "ALAND": 1854169174,
      "AWATER": 6491586,
      "County_state": "Yamhill,41"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -123.78454,
              45.216294
            ],
            [
              -123.784218,
              45.076655
            ],
            [
              -123.729982,
              45.076742
            ],
            [
              -123.724368,
              45.076226
            ],
            [
              -123.495414,
              45.07513
            ],
            [
              -123.49442,
              45.075138
            ],
            [
              -123.068667,
              45.07486
            ],
            [
              -123.036121,
              45.097729
            ],
            [
              -123.018118,
              45.100961
            ],
            [
              -122.996568,
              45.117286
            ],
            [
              -123.015037,
              45.136757
            ],
            [
              -123.028136,
              45.141313
            ],
            [
              -123.029257,
              45.160547
            ],
            [
              -123.007346,
              45.159498
            ],
            [
              -123.000701,
              45.165416
            ],
            [
              -123.015382,
              45.177017
            ],
            [
              -123.020521,
              45.199841
            ],
            [
              -123.041321,
              45.198918
            ],
            [
              -123.051106,
              45.211305
            ],
            [
              -123.04117,
              45.220788
            ],
            [
              -122.999145,
              45.224105
            ],
            [
              -122.995372,
              45.236617
            ],
            [
              -122.999265,
              45.261554
            ],
            [
              -122.976136,
              45.274232
            ],
            [
              -122.974568,
              45.279932
            ],
            [
              -122.967579,
              45.285083
            ],
            [
              -122.947317,
              45.267485
            ],
            [
              -122.915073,
              45.267163
            ],
            [
              -122.900435,
              45.257063
            ],
            [
              -122.862457,
              45.253283
            ],
            [
              -122.849925,
              45.259697
            ],
            [
              -122.868073,
              45.259695
            ],
            [
              -122.867891,
              45.317345
            ],
            [
              -122.908867,
              45.317476
            ],
            [
              -122.923297,
              45.346252
            ],
            [
              -122.965098,
              45.346428
            ],
            [
              -122.97024,
              45.346919
            ],
            [
              -123.011653,
              45.376348
            ],
            [
              -123.011282,
              45.391026
            ],
            [
              -123.032357,
              45.39089
            ],
            [
              -123.032161,
              45.404962
            ],
            [
              -123.062894,
              45.404898
            ],
            [
              -123.114641,
              45.404383
            ],
            [
              -123.114696,
              45.418957
            ],
            [
              -123.135114,
              45.418937
            ],
            [
              -123.139236,
              45.433475
            ],
            [
              -123.140244,
              45.433479
            ],
            [
              -123.46488,
              45.433332
            ],
            [
              -123.464112,
              45.374535
            ],
            [
              -123.463132,
              45.274549
            ],
            [
              -123.463518,
              45.216312
            ],
            [
              -123.78454,
              45.216294
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "41",
      "COUNTYFP": "017",
      "COUNTYNS": "01155130",
      "AFFGEOID": "0500000US41017",
      "GEOID": "41017",
      "NAME": "Deschutes",
      "LSAD": "06",
      "ALAND": 7815563218,
      "AWATER": 94755758,
      "County_state": "Deschutes,41"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -122.000462,
              43.625944
            ],
            [
              -122.002362,
              43.615498
            ],
            [
              -121.332969,
              43.616629
            ],
            [
              -121.004974,
              43.61732
            ],
            [
              -120.974959,
              43.615384
            ],
            [
              -120.37599,
              43.611494
            ],
            [
              -119.896581,
              43.611164
            ],
            [
              -119.897434,
              43.6982
            ],
            [
              -120.217603,
              43.697601
            ],
            [
              -120.257777,
              43.698762
            ],
            [
              -120.258386,
              43.785297
            ],
            [
              -120.378107,
              43.785429
            ],
            [
              -120.37874,
              43.872362
            ],
            [
              -120.747848,
              43.871331
            ],
            [
              -120.748249,
              43.95731
            ],
            [
              -120.986587,
              43.960852
            ],
            [
              -120.987271,
              44.134026
            ],
            [
              -121.102773,
              44.138125
            ],
            [
              -121.102791,
              44.17139
            ],
            [
              -121.108537,
              44.218519
            ],
            [
              -121.108335,
              44.26204
            ],
            [
              -121.107534,
              44.3906
            ],
            [
              -121.226793,
              44.390703
            ],
            [
              -121.2293,
              44.393036
            ],
            [
              -121.843138,
              44.39263
            ],
            [
              -121.84588,
              44.366497
            ],
            [
              -121.83704,
              44.35781
            ],
            [
              -121.841346,
              44.285652
            ],
            [
              -121.824693,
              44.282624
            ],
            [
              -121.819429,
              44.262394
            ],
            [
              -121.80046,
              44.259339
            ],
            [
              -121.786562,
              44.233215
            ],
            [
              -121.781511,
              44.194809
            ],
            [
              -121.771647,
              44.167583
            ],
            [
              -121.784259,
              44.14809
            ],
            [
              -121.772735,
              44.139831
            ],
            [
              -121.769073,
              44.101586
            ],
            [
              -121.779748,
              44.080121
            ],
            [
              -121.789211,
              44.075019
            ],
            [
              -121.802451,
              44.052368
            ],
            [
              -121.819709,
              44.051441
            ],
            [
              -121.835193,
              44.037024
            ],
            [
              -121.822029,
              44.011659
            ],
            [
              -121.829239,
              43.994496
            ],
            [
              -121.845087,
              43.976239
            ],
            [
              -121.836461,
              43.971444
            ],
            [
              -121.857063,
              43.965248
            ],
            [
              -121.869144,
              43.912057
            ],
            [
              -121.891442,
              43.908658
            ],
            [
              -121.901482,
              43.9159
            ],
            [
              -121.928213,
              43.909181
            ],
            [
              -121.939608,
              43.900941
            ],
            [
              -121.932797,
              43.893816
            ],
            [
              -121.95296,
              43.871681
            ],
            [
              -121.975391,
              43.856908
            ],
            [
              -121.975565,
              43.812541
            ],
            [
              -121.963765,
              43.809745
            ],
            [
              -121.968894,
              43.774753
            ],
            [
              -121.962098,
              43.763537
            ],
            [
              -121.97481,
              43.748039
            ],
            [
              -121.981168,
              43.743368
            ],
            [
              -121.973498,
              43.706227
            ],
            [
              -121.986267,
              43.661708
            ],
            [
              -121.980509,
              43.639685
            ],
            [
              -121.964918,
              43.627045
            ],
            [
              -122.000462,
              43.625944
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "41",
      "COUNTYFP": "007",
      "COUNTYNS": "01135846",
      "AFFGEOID": "0500000US41007",
      "GEOID": "41007",
      "NAME": "Clatsop",
      "LSAD": "06",
      "ALAND": 2145196270,
      "AWATER": 661305228,
      "County_state": "Clatsop,41"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -124.011355,
              46.236223
            ],
            [
              -124.024305,
              46.229256
            ],
            [
              -124.010344,
              46.223514
            ],
            [
              -123.996766,
              46.20399
            ],
            [
              -123.974124,
              46.168798
            ],
            [
              -123.95919,
              46.141675
            ],
            [
              -123.947531,
              46.116131
            ],
            [
              -123.933366,
              46.071672
            ],
            [
              -123.92933,
              46.041978
            ],
            [
              -123.927891,
              46.009564
            ],
            [
              -123.937471,
              45.977306
            ],
            [
              -123.941831,
              45.97566
            ],
            [
              -123.957438,
              45.974469
            ],
            [
              -123.969991,
              45.969139
            ],
            [
              -123.993703,
              45.946431
            ],
            [
              -123.99304,
              45.938842
            ],
            [
              -123.979501,
              45.930389
            ],
            [
              -123.96763,
              45.907807
            ],
            [
              -123.962736,
              45.869974
            ],
            [
              -123.961544,
              45.837101
            ],
            [
              -123.96935386195901,
              45.783098
            ],
            [
              -123.71984,
              45.783218
            ],
            [
              -123.720001,
              45.77308
            ],
            [
              -123.485086,
              45.775657
            ],
            [
              -123.48513,
              45.780021
            ],
            [
              -123.361622,
              45.779579
            ],
            [
              -123.358826,
              45.836657
            ],
            [
              -123.360362,
              45.86598
            ],
            [
              -123.367438,
              46.039528
            ],
            [
              -123.36374313768701,
              46.1463247963846
            ],
            [
              -123.371433,
              46.146372
            ],
            [
              -123.430847,
              46.181827
            ],
            [
              -123.427629,
              46.229348
            ],
            [
              -123.447592,
              46.249832
            ],
            [
              -123.468743,
              46.264531
            ],
            [
              -123.474844,
              46.267831
            ],
            [
              -123.479644,
              46.269131
            ],
            [
              -123.501245,
              46.271004
            ],
            [
              -123.516188,
              46.266153
            ],
            [
              -123.526391,
              46.263404
            ],
            [
              -123.538092,
              46.26061
            ],
            [
              -123.547659,
              46.259109
            ],
            [
              -123.548194,
              46.248245
            ],
            [
              -123.586205,
              46.228654
            ],
            [
              -123.60196,
              46.224749
            ],
            [
              -123.619512,
              46.21531
            ],
            [
              -123.632347,
              46.213172
            ],
            [
              -123.644231,
              46.213172
            ],
            [
              -123.660868,
              46.216296
            ],
            [
              -123.666751,
              46.218228
            ],
            [
              -123.673831,
              46.215418
            ],
            [
              -123.67538,
              46.212401
            ],
            [
              -123.674178,
              46.198203
            ],
            [
              -123.69343,
              46.189812
            ],
            [
              -123.718149,
              46.188989
            ],
            [
              -123.727791,
              46.196562
            ],
            [
              -123.735733,
              46.201257
            ],
            [
              -123.74577,
              46.204791
            ],
            [
              -123.757589,
              46.213001
            ],
            [
              -123.763308,
              46.211033
            ],
            [
              -123.766873,
              46.206263
            ],
            [
              -123.771875,
              46.20344
            ],
            [
              -123.783204,
              46.19854
            ],
            [
              -123.793936,
              46.196283
            ],
            [
              -123.821834,
              46.190293
            ],
            [
              -123.838801,
              46.192211
            ],
            [
              -123.864209,
              46.189527
            ],
            [
              -123.866643,
              46.187674
            ],
            [
              -123.863347,
              46.18235
            ],
            [
              -123.841521,
              46.169824
            ],
            [
              -123.842849,
              46.160529
            ],
            [
              -123.854801,
              46.157342
            ],
            [
              -123.891186,
              46.164778
            ],
            [
              -123.9042,
              46.169293
            ],
            [
              -123.912405,
              46.17945
            ],
            [
              -123.927038,
              46.191617
            ],
            [
              -123.950148,
              46.204097
            ],
            [
              -123.972942,
              46.212678
            ],
            [
              -123.988866,
              46.22715
            ],
            [
              -123.998052,
              46.235327
            ],
            [
              -124.001998,
              46.237316
            ],
            [
              -124.011355,
              46.236223
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "41",
      "COUNTYFP": "035",
      "COUNTYNS": "01155134",
      "AFFGEOID": "0500000US41035",
      "GEOID": "41035",
      "NAME": "Klamath",
      "LSAD": "06",
      "ALAND": 15391613763,
      "AWATER": 503712593,
      "County_state": "Klamath,41"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -122.289972,
              42.127156
            ],
            [
              -122.289754518188,
              42.0077685007884
            ],
            [
              -122.289527,
              42.007764
            ],
            [
              -122.261127,
              42.007364
            ],
            [
              -122.161328,
              42.007637
            ],
            [
              -122.160438,
              42.007637
            ],
            [
              -122.156666,
              42.007384
            ],
            [
              -122.155408,
              42.007429
            ],
            [
              -122.101922,
              42.005766
            ],
            [
              -122.000319,
              42.003967
            ],
            [
              -121.846712,
              42.00307
            ],
            [
              -121.708199,
              42.000815
            ],
            [
              -121.705045,
              42.000766
            ],
            [
              -121.689159,
              42.000584
            ],
            [
              -121.675348,
              42.000351
            ],
            [
              -121.580865,
              41.998668
            ],
            [
              -121.52025,
              41.997983
            ],
            [
              -121.44754015233201,
              41.9971688011849
            ],
            [
              -121.43961,
              41.99708
            ],
            [
              -121.434977,
              41.997022
            ],
            [
              -121.376101,
              41.997026
            ],
            [
              -121.360253,
              41.99668
            ],
            [
              -121.340517,
              41.99622
            ],
            [
              -121.335734,
              41.996518
            ],
            [
              -121.334385,
              41.996655
            ],
            [
              -121.309981,
              41.997612
            ],
            [
              -121.251099,
              41.99757
            ],
            [
              -121.247616,
              41.997054
            ],
            [
              -121.126093,
              41.99601
            ],
            [
              -121.094926,
              41.994658
            ],
            [
              -121.035195,
              41.993323
            ],
            [
              -120.879924974291,
              41.993779694142894
            ],
            [
              -120.881069,
              42.336912
            ],
            [
              -120.881071,
              42.338788
            ],
            [
              -120.880436,
              42.483263
            ],
            [
              -120.883327,
              42.744214
            ],
            [
              -120.967852,
              42.743783
            ],
            [
              -120.996685,
              42.746349
            ],
            [
              -121.349679,
              42.746644
            ],
            [
              -121.348471,
              43.356753
            ],
            [
              -121.332413,
              43.356663
            ],
            [
              -121.332969,
              43.616629
            ],
            [
              -122.002362,
              43.615498
            ],
            [
              -122.038966,
              43.601615
            ],
            [
              -122.071291,
              43.579585
            ],
            [
              -122.093513,
              43.579215
            ],
            [
              -122.131009,
              43.557284
            ],
            [
              -122.149534,
              43.520693
            ],
            [
              -122.132474,
              43.510434
            ],
            [
              -122.128721,
              43.496887
            ],
            [
              -122.138621,
              43.45514
            ],
            [
              -122.153016,
              43.447571
            ],
            [
              -122.132034,
              43.440221
            ],
            [
              -122.132008,
              43.439041
            ],
            [
              -122.132908,
              43.437879
            ],
            [
              -122.129916,
              43.436162
            ],
            [
              -122.12675,
              43.435345
            ],
            [
              -122.117102,
              43.431977
            ],
            [
              -122.106198,
              43.429687
            ],
            [
              -122.101859,
              43.427315
            ],
            [
              -122.101785,
              43.426371
            ],
            [
              -122.100151,
              43.425891
            ],
            [
              -122.097966,
              43.425331
            ],
            [
              -122.095125,
              43.427893
            ],
            [
              -122.091183,
              43.426966
            ],
            [
              -122.088744,
              43.42461
            ],
            [
              -122.084549,
              43.421778
            ],
            [
              -122.085345,
              43.419197
            ],
            [
              -122.082041,
              43.415449
            ],
            [
              -122.07345,
              43.4103
            ],
            [
              -122.068934,
              43.40804
            ],
            [
              -122.064757,
              43.406573
            ],
            [
              -122.061616,
              43.40242
            ],
            [
              -122.059822,
              43.400476
            ],
            [
              -122.060069,
              43.397759
            ],
            [
              -122.062769,
              43.394378
            ],
            [
              -122.059602,
              43.388102
            ],
            [
              -122.056799,
              43.382729
            ],
            [
              -122.053686,
              43.380956
            ],
            [
              -122.045098,
              43.375671
            ],
            [
              -122.039166,
              43.370607
            ],
            [
              -122.033547,
              43.367422
            ],
            [
              -122.030687,
              43.364861
            ],
            [
              -122.026737,
              43.361055
            ],
            [
              -122.025284,
              43.361911
            ],
            [
              -122.021391,
              43.359064
            ],
            [
              -122.014732,
              43.349478
            ],
            [
              -122.01155,
              43.34519
            ],
            [
              -122.010144,
              43.340257
            ],
            [
              -122.009138,
              43.33552
            ],
            [
              -122.012564,
              43.328392
            ],
            [
              -122.014709,
              43.322949
            ],
            [
              -122.012305,
              43.317991
            ],
            [
              -122.005705,
              43.315281
            ],
            [
              -122.00141,
              43.311321
            ],
            [
              -122.000225,
              43.308063
            ],
            [
              -122.002408,
              43.305731
            ],
            [
              -122.006446,
              43.302476
            ],
            [
              -122.008564,
              43.297455
            ],
            [
              -122.009008,
              43.292702
            ],
            [
              -122.007766,
              43.288992
            ],
            [
              -122.003776,
              43.283905
            ],
            [
              -121.989275,
              43.278366
            ],
            [
              -121.9839,
              43.27039
            ],
            [
              -121.980011,
              43.264733
            ],
            [
              -121.97796,
              43.260311
            ],
            [
              -121.98269,
              43.258201
            ],
            [
              -121.98966,
              43.253139
            ],
            [
              -121.992797,
              43.249903
            ],
            [
              -121.994308,
              43.2462
            ],
            [
              -121.996717,
              43.241541
            ],
            [
              -121.999949,
              43.238054
            ],
            [
              -122.001632,
              43.235982
            ],
            [
              -122.00501,
              43.234256
            ],
            [
              -122.008038,
              43.234327
            ],
            [
              -122.012774,
              43.2332
            ],
            [
              -122.01452,
              43.230287
            ],
            [
              -122.014873,
              43.228179
            ],
            [
              -122.017981,
              43.226812
            ],
            [
              -122.023811,
              43.223959
            ],
            [
              -122.0272,
              43.220712
            ],
            [
              -122.033312,
              43.218822
            ],
            [
              -122.036275,
              43.217906
            ],
            [
              -122.038467,
              43.217983
            ],
            [
              -122.039252,
              43.218563
            ],
            [
              -122.040718,
              43.216623
            ],
            [
              -122.039763,
              43.214751
            ],
            [
              -122.035588,
              43.209376
            ],
            [
              -122.033737,
              43.206713
            ],
            [
              -122.036108,
              43.20369
            ],
            [
              -122.03749,
              43.201976
            ],
            [
              -122.040146,
              43.198514
            ],
            [
              -122.04031,
              43.196582
            ],
            [
              -122.03976,
              43.195378
            ],
            [
              -122.03945,
              43.194043
            ],
            [
              -122.039358,
              43.192856
            ],
            [
              -122.039618,
              43.190248
            ],
            [
              -122.044183,
              43.186964
            ],
            [
              -122.045709,
              43.182831
            ],
            [
              -122.049397,
              43.177912
            ],
            [
              -122.052318,
              43.174519
            ],
            [
              -122.054339,
              43.171208
            ],
            [
              -122.05465,
              43.169345
            ],
            [
              -122.052515,
              43.165999
            ],
            [
              -122.052947,
              43.163754
            ],
            [
              -122.054032,
              43.160589
            ],
            [
              -122.054693,
              43.157067
            ],
            [
              -122.056887,
              43.155847
            ],
            [
              -122.06083,
              43.154132
            ],
            [
              -122.063541,
              43.153111
            ],
            [
              -122.0646,
              43.152823
            ],
            [
              -122.066466,
              43.152101
            ],
            [
              -122.066037,
              43.151347
            ],
            [
              -122.067027,
              43.149248
            ],
            [
              -122.066498,
              43.147796
            ],
            [
              -122.066118,
              43.146714
            ],
            [
              -122.065232,
              43.145328
            ],
            [
              -122.064263,
              43.140814
            ],
            [
              -122.064956,
              43.137149
            ],
            [
              -122.067455,
              43.13307
            ],
            [
              -122.07287,
              43.128524
            ],
            [
              -122.075727,
              43.124878
            ],
            [
              -122.076086,
              43.122582
            ],
            [
              -122.077133,
              43.120005
            ],
            [
              -122.077561,
              43.117459
            ],
            [
              -122.080954,
              43.114127
            ],
            [
              -122.083368,
              43.108041
            ],
            [
              -122.083714,
              43.1022
            ],
            [
              -122.086395,
              43.097107
            ],
            [
              -122.087965,
              43.093931
            ],
            [
              -122.087985,
              43.090859
            ],
            [
              -122.09126,
              43.088157
            ],
            [
              -122.092061,
              43.083422
            ],
            [
              -122.093702,
              43.079068
            ],
            [
              -122.09102,
              43.073641
            ],
            [
              -122.086152,
              43.072046
            ],
            [
              -122.083097,
              43.069841
            ],
            [
              -122.08045,
              43.069772
            ],
            [
              -122.094795,
              43.067749
            ],
            [
              -122.16784,
              43.067767
            ],
            [
              -122.208437,
              43.067757
            ],
            [
              -122.243124,
              43.067738
            ],
            [
              -122.266686,
              43.067726
            ],
            [
              -122.282636,
              43.050877
            ],
            [
              -122.282689,
              43.03275
            ],
            [
              -122.282719,
              43.006476
            ],
            [
              -122.28273,
              42.996499
            ],
            [
              -122.282682,
              42.794271
            ],
            [
              -122.284689,
              42.4745
            ],
            [
              -122.28776,
              42.440287
            ],
            [
              -122.287686,
              42.395348
            ],
            [
              -122.287177,
              42.288838
            ],
            [
              -122.289972,
              42.127156
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "41",
      "COUNTYFP": "011",
      "COUNTYNS": "01135848",
      "AFFGEOID": "0500000US41011",
      "GEOID": "41011",
      "NAME": "Coos",
      "LSAD": "06",
      "ALAND": 4133690002,
      "AWATER": 544752776,
      "County_state": "Coos,41"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -124.462619,
              42.99143
            ],
            [
              -124.479636362958,
              42.9543325389089
            ],
            [
              -124.258388,
              42.954946
            ],
            [
              -124.239394,
              42.933751
            ],
            [
              -124.219623,
              42.933782
            ],
            [
              -124.214201,
              42.904006
            ],
            [
              -124.189984,
              42.896696
            ],
            [
              -124.180704,
              42.88245
            ],
            [
              -124.161308,
              42.883499
            ],
            [
              -124.162074,
              42.86973
            ],
            [
              -124.141475,
              42.850586
            ],
            [
              -124.141185,
              42.828475
            ],
            [
              -124.160658,
              42.828288
            ],
            [
              -124.164889,
              42.817825
            ],
            [
              -124.160163,
              42.790087
            ],
            [
              -124.159966,
              42.739947
            ],
            [
              -124.138655,
              42.739956
            ],
            [
              -124.132918,
              42.666697
            ],
            [
              -124.1192,
              42.678339
            ],
            [
              -124.089872,
              42.678208
            ],
            [
              -124.089872,
              42.685454
            ],
            [
              -123.996291,
              42.685651
            ],
            [
              -123.996296,
              42.7001
            ],
            [
              -123.976677,
              42.700118
            ],
            [
              -123.976631,
              42.714558
            ],
            [
              -123.956985,
              42.714569
            ],
            [
              -123.957026,
              42.729051
            ],
            [
              -123.937288,
              42.729097
            ],
            [
              -123.925942,
              42.73971
            ],
            [
              -123.925717,
              42.774514
            ],
            [
              -123.868491,
              42.77484
            ],
            [
              -123.849858,
              42.788994
            ],
            [
              -123.811553,
              42.788837
            ],
            [
              -123.811789,
              42.824101
            ],
            [
              -123.820439,
              42.824112
            ],
            [
              -123.819496,
              42.909661
            ],
            [
              -123.821513,
              42.924483
            ],
            [
              -123.821118,
              42.996291
            ],
            [
              -123.761594,
              42.996318
            ],
            [
              -123.761333,
              43.08316
            ],
            [
              -123.707057,
              43.083457
            ],
            [
              -123.702144,
              43.087083
            ],
            [
              -123.704752,
              43.257741
            ],
            [
              -123.764356,
              43.258054
            ],
            [
              -123.762801,
              43.387997
            ],
            [
              -123.764884,
              43.431548
            ],
            [
              -123.817614,
              43.431306
            ],
            [
              -123.816657,
              43.516449
            ],
            [
              -123.875628,
              43.515947
            ],
            [
              -123.875698,
              43.608407
            ],
            [
              -124.183193,
              43.611464
            ],
            [
              -124.185163,
              43.610927
            ],
            [
              -124.21870693461202,
              43.6109319407061
            ],
            [
              -124.233534,
              43.55713
            ],
            [
              -124.255609,
              43.502172
            ],
            [
              -124.286896,
              43.436296
            ],
            [
              -124.315012,
              43.388389
            ],
            [
              -124.341587,
              43.351337
            ],
            [
              -124.341659977831,
              43.351283128753096
            ],
            [
              -124.353332,
              43.342667
            ],
            [
              -124.373037,
              43.338953
            ],
            [
              -124.387642,
              43.325968
            ],
            [
              -124.402814,
              43.305872
            ],
            [
              -124.400404,
              43.302121
            ],
            [
              -124.393988,
              43.29926
            ],
            [
              -124.388891,
              43.290523
            ],
            [
              -124.38246,
              43.270167
            ],
            [
              -124.395607,
              43.223908
            ],
            [
              -124.395302,
              43.211101
            ],
            [
              -124.401726,
              43.184896
            ],
            [
              -124.424113,
              43.126859
            ],
            [
              -124.434451,
              43.115986
            ],
            [
              -124.432236,
              43.097383
            ],
            [
              -124.436198,
              43.071312
            ],
            [
              -124.456918,
              43.000315
            ],
            [
              -124.462619,
              42.99143
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "41",
      "COUNTYFP": "019",
      "COUNTYNS": "01135849",
      "AFFGEOID": "0500000US41019",
      "GEOID": "41019",
      "NAME": "Douglas",
      "LSAD": "06",
      "ALAND": 13042299249,
      "AWATER": 252000831,
      "County_state": "Douglas,41"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -124.203028,
              43.667825
            ],
            [
              -124.21870693461202,
              43.6109319407061
            ],
            [
              -124.185163,
              43.610927
            ],
            [
              -124.183193,
              43.611464
            ],
            [
              -123.875698,
              43.608407
            ],
            [
              -123.875628,
              43.515947
            ],
            [
              -123.816657,
              43.516449
            ],
            [
              -123.817614,
              43.431306
            ],
            [
              -123.764884,
              43.431548
            ],
            [
              -123.762801,
              43.387997
            ],
            [
              -123.764356,
              43.258054
            ],
            [
              -123.704752,
              43.257741
            ],
            [
              -123.702144,
              43.087083
            ],
            [
              -123.707057,
              43.083457
            ],
            [
              -123.761333,
              43.08316
            ],
            [
              -123.761594,
              42.996318
            ],
            [
              -123.821118,
              42.996291
            ],
            [
              -123.821513,
              42.924483
            ],
            [
              -123.819496,
              42.909661
            ],
            [
              -123.820439,
              42.824112
            ],
            [
              -123.811789,
              42.824101
            ],
            [
              -123.811553,
              42.788837
            ],
            [
              -123.783304,
              42.799426
            ],
            [
              -123.732315,
              42.780131
            ],
            [
              -123.716492,
              42.784061
            ],
            [
              -123.716505,
              42.739658
            ],
            [
              -123.696843,
              42.739593
            ],
            [
              -123.697,
              42.753907
            ],
            [
              -123.677206,
              42.750145
            ],
            [
              -123.677275,
              42.739682
            ],
            [
              -123.581699,
              42.73992
            ],
            [
              -123.566777,
              42.735693
            ],
            [
              -123.562168,
              42.717574
            ],
            [
              -123.542539,
              42.709832
            ],
            [
              -123.494414,
              42.70918
            ],
            [
              -123.465271,
              42.699297
            ],
            [
              -123.425721,
              42.714483
            ],
            [
              -123.403515,
              42.710295
            ],
            [
              -123.400242,
              42.724951
            ],
            [
              -123.371674,
              42.724957
            ],
            [
              -123.371581,
              42.739484
            ],
            [
              -123.274283,
              42.73203
            ],
            [
              -123.249328,
              42.724404
            ],
            [
              -123.249209,
              42.702421
            ],
            [
              -123.229619,
              42.70261
            ],
            [
              -123.210392,
              42.709983
            ],
            [
              -123.180512,
              42.737765
            ],
            [
              -123.152023,
              42.737721
            ],
            [
              -123.152233,
              42.755835
            ],
            [
              -123.112688,
              42.752218
            ],
            [
              -123.009765,
              42.752144
            ],
            [
              -122.990728,
              42.759538
            ],
            [
              -122.970829,
              42.768116
            ],
            [
              -122.95119,
              42.767555
            ],
            [
              -122.951199,
              42.774773
            ],
            [
              -122.911925,
              42.774704
            ],
            [
              -122.852943,
              42.774603
            ],
            [
              -122.794776,
              42.778221
            ],
            [
              -122.794811,
              42.792721
            ],
            [
              -122.775044,
              42.806831
            ],
            [
              -122.775223,
              42.8288
            ],
            [
              -122.736367,
              42.823496
            ],
            [
              -122.736329,
              42.837661
            ],
            [
              -122.716699,
              42.837671
            ],
            [
              -122.716657,
              42.85211
            ],
            [
              -122.69701,
              42.852099
            ],
            [
              -122.697017,
              42.866572
            ],
            [
              -122.677198,
              42.866603
            ],
            [
              -122.677181,
              42.88108
            ],
            [
              -122.637695,
              42.881141
            ],
            [
              -122.637812,
              42.89699
            ],
            [
              -122.600251,
              42.895693
            ],
            [
              -122.600202,
              42.911073
            ],
            [
              -122.580094,
              42.9108
            ],
            [
              -122.580049,
              42.924651
            ],
            [
              -122.560299,
              42.92469
            ],
            [
              -122.560273,
              42.939185
            ],
            [
              -122.460224,
              42.939873
            ],
            [
              -122.46021,
              42.954373
            ],
            [
              -122.440505,
              42.95442
            ],
            [
              -122.440535,
              42.968923
            ],
            [
              -122.420863,
              42.968927
            ],
            [
              -122.420844,
              42.983439
            ],
            [
              -122.401077,
              42.983458
            ],
            [
              -122.401025,
              42.996632
            ],
            [
              -122.28273,
              42.996499
            ],
            [
              -122.282719,
              43.006476
            ],
            [
              -122.282689,
              43.03275
            ],
            [
              -122.282636,
              43.050877
            ],
            [
              -122.266686,
              43.067726
            ],
            [
              -122.243124,
              43.067738
            ],
            [
              -122.208437,
              43.067757
            ],
            [
              -122.16784,
              43.067767
            ],
            [
              -122.094795,
              43.067749
            ],
            [
              -122.08045,
              43.069772
            ],
            [
              -122.083097,
              43.069841
            ],
            [
              -122.086152,
              43.072046
            ],
            [
              -122.09102,
              43.073641
            ],
            [
              -122.093702,
              43.079068
            ],
            [
              -122.092061,
              43.083422
            ],
            [
              -122.09126,
              43.088157
            ],
            [
              -122.087985,
              43.090859
            ],
            [
              -122.087965,
              43.093931
            ],
            [
              -122.086395,
              43.097107
            ],
            [
              -122.083714,
              43.1022
            ],
            [
              -122.083368,
              43.108041
            ],
            [
              -122.080954,
              43.114127
            ],
            [
              -122.077561,
              43.117459
            ],
            [
              -122.077133,
              43.120005
            ],
            [
              -122.076086,
              43.122582
            ],
            [
              -122.075727,
              43.124878
            ],
            [
              -122.07287,
              43.128524
            ],
            [
              -122.067455,
              43.13307
            ],
            [
              -122.064956,
              43.137149
            ],
            [
              -122.064263,
              43.140814
            ],
            [
              -122.065232,
              43.145328
            ],
            [
              -122.066118,
              43.146714
            ],
            [
              -122.066498,
              43.147796
            ],
            [
              -122.067027,
              43.149248
            ],
            [
              -122.066037,
              43.151347
            ],
            [
              -122.066466,
              43.152101
            ],
            [
              -122.0646,
              43.152823
            ],
            [
              -122.063541,
              43.153111
            ],
            [
              -122.06083,
              43.154132
            ],
            [
              -122.056887,
              43.155847
            ],
            [
              -122.054693,
              43.157067
            ],
            [
              -122.054032,
              43.160589
            ],
            [
              -122.052947,
              43.163754
            ],
            [
              -122.052515,
              43.165999
            ],
            [
              -122.05465,
              43.169345
            ],
            [
              -122.054339,
              43.171208
            ],
            [
              -122.052318,
              43.174519
            ],
            [
              -122.049397,
              43.177912
            ],
            [
              -122.045709,
              43.182831
            ],
            [
              -122.044183,
              43.186964
            ],
            [
              -122.039618,
              43.190248
            ],
            [
              -122.039358,
              43.192856
            ],
            [
              -122.03945,
              43.194043
            ],
            [
              -122.03976,
              43.195378
            ],
            [
              -122.04031,
              43.196582
            ],
            [
              -122.040146,
              43.198514
            ],
            [
              -122.03749,
              43.201976
            ],
            [
              -122.036108,
              43.20369
            ],
            [
              -122.033737,
              43.206713
            ],
            [
              -122.035588,
              43.209376
            ],
            [
              -122.039763,
              43.214751
            ],
            [
              -122.040718,
              43.216623
            ],
            [
              -122.039252,
              43.218563
            ],
            [
              -122.038467,
              43.217983
            ],
            [
              -122.036275,
              43.217906
            ],
            [
              -122.033312,
              43.218822
            ],
            [
              -122.0272,
              43.220712
            ],
            [
              -122.023811,
              43.223959
            ],
            [
              -122.017981,
              43.226812
            ],
            [
              -122.014873,
              43.228179
            ],
            [
              -122.01452,
              43.230287
            ],
            [
              -122.012774,
              43.2332
            ],
            [
              -122.008038,
              43.234327
            ],
            [
              -122.00501,
              43.234256
            ],
            [
              -122.001632,
              43.235982
            ],
            [
              -121.999949,
              43.238054
            ],
            [
              -121.996717,
              43.241541
            ],
            [
              -121.994308,
              43.2462
            ],
            [
              -121.992797,
              43.249903
            ],
            [
              -121.98966,
              43.253139
            ],
            [
              -121.98269,
              43.258201
            ],
            [
              -121.97796,
              43.260311
            ],
            [
              -121.980011,
              43.264733
            ],
            [
              -121.9839,
              43.27039
            ],
            [
              -121.989275,
              43.278366
            ],
            [
              -122.003776,
              43.283905
            ],
            [
              -122.007766,
              43.288992
            ],
            [
              -122.009008,
              43.292702
            ],
            [
              -122.008564,
              43.297455
            ],
            [
              -122.006446,
              43.302476
            ],
            [
              -122.002408,
              43.305731
            ],
            [
              -122.000225,
              43.308063
            ],
            [
              -122.00141,
              43.311321
            ],
            [
              -122.005705,
              43.315281
            ],
            [
              -122.012305,
              43.317991
            ],
            [
              -122.014709,
              43.322949
            ],
            [
              -122.012564,
              43.328392
            ],
            [
              -122.009138,
              43.33552
            ],
            [
              -122.010144,
              43.340257
            ],
            [
              -122.01155,
              43.34519
            ],
            [
              -122.014732,
              43.349478
            ],
            [
              -122.021391,
              43.359064
            ],
            [
              -122.025284,
              43.361911
            ],
            [
              -122.026737,
              43.361055
            ],
            [
              -122.030687,
              43.364861
            ],
            [
              -122.033547,
              43.367422
            ],
            [
              -122.039166,
              43.370607
            ],
            [
              -122.045098,
              43.375671
            ],
            [
              -122.053686,
              43.380956
            ],
            [
              -122.056799,
              43.382729
            ],
            [
              -122.059602,
              43.388102
            ],
            [
              -122.062769,
              43.394378
            ],
            [
              -122.060069,
              43.397759
            ],
            [
              -122.059822,
              43.400476
            ],
            [
              -122.061616,
              43.40242
            ],
            [
              -122.064757,
              43.406573
            ],
            [
              -122.068934,
              43.40804
            ],
            [
              -122.07345,
              43.4103
            ],
            [
              -122.082041,
              43.415449
            ],
            [
              -122.085345,
              43.419197
            ],
            [
              -122.084549,
              43.421778
            ],
            [
              -122.088744,
              43.42461
            ],
            [
              -122.091183,
              43.426966
            ],
            [
              -122.095125,
              43.427893
            ],
            [
              -122.097966,
              43.425331
            ],
            [
              -122.100151,
              43.425891
            ],
            [
              -122.101785,
              43.426371
            ],
            [
              -122.101859,
              43.427315
            ],
            [
              -122.106198,
              43.429687
            ],
            [
              -122.117102,
              43.431977
            ],
            [
              -122.12675,
              43.435345
            ],
            [
              -122.129916,
              43.436162
            ],
            [
              -122.132908,
              43.437879
            ],
            [
              -122.132008,
              43.439041
            ],
            [
              -122.132034,
              43.440221
            ],
            [
              -122.270653,
              43.440011
            ],
            [
              -122.490469,
              43.441481
            ],
            [
              -122.508438,
              43.440226
            ],
            [
              -122.622914,
              43.440262
            ],
            [
              -122.741716,
              43.437395
            ],
            [
              -122.741845,
              43.544654
            ],
            [
              -122.932533,
              43.544357
            ],
            [
              -122.987626,
              43.538988
            ],
            [
              -123.107686,
              43.54001
            ],
            [
              -123.107318,
              43.605889
            ],
            [
              -123.137211,
              43.605906
            ],
            [
              -123.137951,
              43.765073
            ],
            [
              -123.140144,
              43.77966
            ],
            [
              -123.348246,
              43.78017
            ],
            [
              -123.3482,
              43.809212
            ],
            [
              -123.470371,
              43.810056
            ],
            [
              -123.470251,
              43.831183
            ],
            [
              -123.529153,
              43.830061
            ],
            [
              -123.529005,
              43.86833
            ],
            [
              -123.579782,
              43.86829
            ],
            [
              -123.579579,
              43.89113
            ],
            [
              -123.619574,
              43.891672
            ],
            [
              -123.619012,
              43.921117
            ],
            [
              -123.658909,
              43.921672
            ],
            [
              -123.658767,
              43.936474
            ],
            [
              -123.703628,
              43.937399
            ],
            [
              -123.7038,
              43.944319
            ],
            [
              -123.75871,
              43.943882
            ],
            [
              -123.827004,
              43.944837
            ],
            [
              -123.852602,
              43.920553
            ],
            [
              -123.872399,
              43.916733
            ],
            [
              -123.902391,
              43.896281
            ],
            [
              -123.925917,
              43.898476
            ],
            [
              -123.925479,
              43.865947
            ],
            [
              -123.946529,
              43.871689
            ],
            [
              -123.946291,
              43.862682
            ],
            [
              -124.064585,
              43.862277
            ],
            [
              -124.158646168589,
              43.8637200082681
            ],
            [
              -124.168392,
              43.808903
            ],
            [
              -124.193455,
              43.706085
            ],
            [
              -124.198275,
              43.689481
            ],
            [
              -124.204888,
              43.673976
            ],
            [
              -124.203028,
              43.667825
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "41",
      "COUNTYFP": "005",
      "COUNTYNS": "01155127",
      "AFFGEOID": "0500000US41005",
      "GEOID": "41005",
      "NAME": "Clackamas",
      "LSAD": "06",
      "ALAND": 4845049025,
      "AWATER": 31834351,
      "County_state": "Clackamas,41"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -122.867891,
              45.317345
            ],
            [
              -122.868073,
              45.259695
            ],
            [
              -122.849925,
              45.259697
            ],
            [
              -122.849872,
              45.259717
            ],
            [
              -122.834765,
              45.273822
            ],
            [
              -122.784781,
              45.273888
            ],
            [
              -122.784756,
              45.259562
            ],
            [
              -122.764287,
              45.259551
            ],
            [
              -122.73903,
              45.259359
            ],
            [
              -122.74144,
              45.259049
            ],
            [
              -122.745153,
              45.251078
            ],
            [
              -122.751113,
              45.245342
            ],
            [
              -122.750085,
              45.233603
            ],
            [
              -122.745299,
              45.232772
            ],
            [
              -122.757473,
              45.209145
            ],
            [
              -122.753184,
              45.187517
            ],
            [
              -122.756883,
              45.172802
            ],
            [
              -122.776907,
              45.153233
            ],
            [
              -122.788546,
              45.130164
            ],
            [
              -122.750028,
              45.107871
            ],
            [
              -122.743301,
              45.096291
            ],
            [
              -122.719677,
              45.079641
            ],
            [
              -122.705115,
              45.059023
            ],
            [
              -122.669941,
              45.046674
            ],
            [
              -122.649252,
              45.032454
            ],
            [
              -122.634355,
              45.033852
            ],
            [
              -122.595523,
              45.019679
            ],
            [
              -122.592472,
              44.988875
            ],
            [
              -122.567385,
              44.982564
            ],
            [
              -122.559757,
              44.965195
            ],
            [
              -122.535999,
              44.937161
            ],
            [
              -122.517542,
              44.932797
            ],
            [
              -122.508106,
              44.919599
            ],
            [
              -122.486327,
              44.922669
            ],
            [
              -122.4585,
              44.915212
            ],
            [
              -122.441487,
              44.903958
            ],
            [
              -122.399554,
              44.904077
            ],
            [
              -122.394341,
              44.898972
            ],
            [
              -122.388999,
              44.88572
            ],
            [
              -121.765322,
              44.885719
            ],
            [
              -121.734223,
              44.88572
            ],
            [
              -121.714405,
              44.905901
            ],
            [
              -121.747297,
              44.914636
            ],
            [
              -121.752417,
              44.93057
            ],
            [
              -121.786735,
              44.945223
            ],
            [
              -121.801623,
              44.982743
            ],
            [
              -121.805269,
              45.011496
            ],
            [
              -121.78147,
              45.015411
            ],
            [
              -121.778672,
              45.037277
            ],
            [
              -121.740994,
              45.034428
            ],
            [
              -121.716996,
              45.038037
            ],
            [
              -121.72235,
              45.046023
            ],
            [
              -121.687234,
              45.067371
            ],
            [
              -121.65871,
              45.066355
            ],
            [
              -121.651394,
              45.088803
            ],
            [
              -121.665717,
              45.11972
            ],
            [
              -121.693387,
              45.116934
            ],
            [
              -121.70805,
              45.134055
            ],
            [
              -121.711596,
              45.152824
            ],
            [
              -121.725283,
              45.159339
            ],
            [
              -121.731438,
              45.170633
            ],
            [
              -121.748245,
              45.2051
            ],
            [
              -121.714637,
              45.211232
            ],
            [
              -121.698375,
              45.226448
            ],
            [
              -121.682607,
              45.228988
            ],
            [
              -121.680898,
              45.250581
            ],
            [
              -121.696479,
              45.25792
            ],
            [
              -121.679081,
              45.297177
            ],
            [
              -121.70326,
              45.331267
            ],
            [
              -121.697517,
              45.373663
            ],
            [
              -121.72419,
              45.393648
            ],
            [
              -121.776204,
              45.402672
            ],
            [
              -121.792759,
              45.415215
            ],
            [
              -121.806898,
              45.435286
            ],
            [
              -121.806845,
              45.454649
            ],
            [
              -121.819587,
              45.461675
            ],
            [
              -121.973794,
              45.46024
            ],
            [
              -121.983147,
              45.461403
            ],
            [
              -122.383141,
              45.461276
            ],
            [
              -122.527468,
              45.461273
            ],
            [
              -122.547638,
              45.461058
            ],
            [
              -122.558403,
              45.461062
            ],
            [
              -122.568617,
              45.461365
            ],
            [
              -122.596603,
              45.461458
            ],
            [
              -122.601454,
              45.461464
            ],
            [
              -122.64158,
              45.461482
            ],
            [
              -122.660979,
              45.457819
            ],
            [
              -122.645205,
              45.441641
            ],
            [
              -122.648725,
              45.432718
            ],
            [
              -122.705021,
              45.433037
            ],
            [
              -122.733422,
              45.433199
            ],
            [
              -122.73853,
              45.433238
            ],
            [
              -122.743721,
              45.433294
            ],
            [
              -122.743991,
              45.426023
            ],
            [
              -122.743942,
              45.402702
            ],
            [
              -122.743892,
              45.400764
            ],
            [
              -122.743866,
              45.348463
            ],
            [
              -122.743741,
              45.332067
            ],
            [
              -122.80547,
              45.331772
            ],
            [
              -122.80555,
              45.346283
            ],
            [
              -122.846433,
              45.346112
            ],
            [
              -122.846503,
              45.317192
            ],
            [
              -122.867891,
              45.317345
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "41",
      "COUNTYFP": "053",
      "COUNTYNS": "01135862",
      "AFFGEOID": "0500000US41053",
      "GEOID": "41053",
      "NAME": "Polk",
      "LSAD": "06",
      "ALAND": 1918983329,
      "AWATER": 8153115,
      "County_state": "Polk,41"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -123.724663,
              45.044432
            ],
            [
              -123.725001,
              44.739046
            ],
            [
              -123.704578,
              44.73898
            ],
            [
              -123.704467,
              44.721154
            ],
            [
              -123.602609,
              44.721154
            ],
            [
              -123.580966,
              44.719415
            ],
            [
              -123.521402,
              44.72089
            ],
            [
              -123.301882,
              44.719835
            ],
            [
              -123.182025,
              44.720085
            ],
            [
              -123.149025,
              44.720225
            ],
            [
              -123.1327,
              44.743363
            ],
            [
              -123.143843,
              44.748914
            ],
            [
              -123.145812,
              44.770231
            ],
            [
              -123.122064,
              44.793774
            ],
            [
              -123.097713,
              44.799099
            ],
            [
              -123.091479,
              44.811523
            ],
            [
              -123.1114,
              44.821691
            ],
            [
              -123.131732,
              44.826432
            ],
            [
              -123.176101,
              44.831759
            ],
            [
              -123.179857,
              44.845611
            ],
            [
              -123.170891,
              44.864462
            ],
            [
              -123.148436,
              44.862009
            ],
            [
              -123.110942,
              44.930455
            ],
            [
              -123.073159,
              44.927933
            ],
            [
              -123.044433,
              44.944231
            ],
            [
              -123.038229,
              44.955937
            ],
            [
              -123.037566,
              44.965581
            ],
            [
              -123.037316,
              44.972795
            ],
            [
              -123.040164,
              44.988153
            ],
            [
              -123.065415,
              44.990718
            ],
            [
              -123.075577,
              45.014513
            ],
            [
              -123.068667,
              45.07486
            ],
            [
              -123.49442,
              45.075138
            ],
            [
              -123.495414,
              45.07513
            ],
            [
              -123.724368,
              45.076226
            ],
            [
              -123.724663,
              45.044432
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "41",
      "COUNTYFP": "051",
      "COUNTYNS": "01135861",
      "AFFGEOID": "0500000US41051",
      "GEOID": "41051",
      "NAME": "Multnomah",
      "LSAD": "06",
      "ALAND": 1116597726,
      "AWATER": 88213123,
      "County_state": "Multnomah,41"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -122.929146,
              45.721482
            ],
            [
              -122.929115,
              45.642652
            ],
            [
              -122.908992,
              45.620763
            ],
            [
              -122.887837,
              45.620466
            ],
            [
              -122.887963,
              45.606352
            ],
            [
              -122.868303,
              45.606223
            ],
            [
              -122.867592,
              45.591878
            ],
            [
              -122.846849,
              45.591836
            ],
            [
              -122.846978,
              45.577516
            ],
            [
              -122.82621,
              45.566284
            ],
            [
              -122.805481,
              45.562911
            ],
            [
              -122.784771,
              45.5484
            ],
            [
              -122.764412,
              45.533862
            ],
            [
              -122.764464,
              45.519562
            ],
            [
              -122.743859,
              45.516665
            ],
            [
              -122.743812,
              45.507609
            ],
            [
              -122.743786,
              45.505122
            ],
            [
              -122.743786,
              45.490804
            ],
            [
              -122.743768,
              45.489188
            ],
            [
              -122.743663,
              45.467532
            ],
            [
              -122.743713,
              45.438377
            ],
            [
              -122.743721,
              45.433294
            ],
            [
              -122.73853,
              45.433238
            ],
            [
              -122.733422,
              45.433199
            ],
            [
              -122.705021,
              45.433037
            ],
            [
              -122.648725,
              45.432718
            ],
            [
              -122.645205,
              45.441641
            ],
            [
              -122.660979,
              45.457819
            ],
            [
              -122.64158,
              45.461482
            ],
            [
              -122.601454,
              45.461464
            ],
            [
              -122.596603,
              45.461458
            ],
            [
              -122.568617,
              45.461365
            ],
            [
              -122.558403,
              45.461062
            ],
            [
              -122.547638,
              45.461058
            ],
            [
              -122.527468,
              45.461273
            ],
            [
              -122.383141,
              45.461276
            ],
            [
              -121.983147,
              45.461403
            ],
            [
              -121.973794,
              45.46024
            ],
            [
              -121.819587,
              45.461675
            ],
            [
              -121.863679,
              45.492842
            ],
            [
              -121.869502,
              45.514278
            ],
            [
              -121.893844,
              45.513378
            ],
            [
              -121.90661,
              45.52123
            ],
            [
              -121.916916,
              45.546723
            ],
            [
              -121.903279,
              45.554879
            ],
            [
              -121.918106,
              45.587217
            ],
            [
              -121.911686,
              45.601769
            ],
            [
              -121.922051,
              45.610761
            ],
            [
              -121.922028852172,
              45.649161899050796
            ],
            [
              -121.935149,
              45.644169
            ],
            [
              -121.951838,
              45.644951
            ],
            [
              -121.955734,
              45.643559
            ],
            [
              -121.963547,
              45.632784
            ],
            [
              -121.979797,
              45.624839
            ],
            [
              -121.983038,
              45.622812
            ],
            [
              -122.00369,
              45.61593
            ],
            [
              -122.022571,
              45.615151
            ],
            [
              -122.044374,
              45.609516
            ],
            [
              -122.101675,
              45.583516
            ],
            [
              -122.112356,
              45.581409
            ],
            [
              -122.126197,
              45.582617
            ],
            [
              -122.126197,
              45.582573
            ],
            [
              -122.12949,
              45.582967
            ],
            [
              -122.129548,
              45.582945
            ],
            [
              -122.14075,
              45.584508
            ],
            [
              -122.183695,
              45.577696
            ],
            [
              -122.2017,
              45.564141
            ],
            [
              -122.24891194766401,
              45.5477731000169
            ],
            [
              -122.248993,
              45.547745
            ],
            [
              -122.262625,
              45.544321
            ],
            [
              -122.266701,
              45.543841
            ],
            [
              -122.294901,
              45.543541
            ],
            [
              -122.331502,
              45.548241
            ],
            [
              -122.352802,
              45.569441
            ],
            [
              -122.380302,
              45.575941
            ],
            [
              -122.391802,
              45.574541
            ],
            [
              -122.401222955157,
              45.5710983445711
            ],
            [
              -122.410706,
              45.567633
            ],
            [
              -122.438674,
              45.563585
            ],
            [
              -122.453891,
              45.567313
            ],
            [
              -122.474659,
              45.578305
            ],
            [
              -122.479315,
              45.579761
            ],
            [
              -122.492259,
              45.583281
            ],
            [
              -122.505353096256,
              45.585928668035294
            ],
            [
              -122.523668,
              45.589632
            ],
            [
              -122.548149,
              45.596768
            ],
            [
              -122.54995527692701,
              45.597157530568595
            ],
            [
              -122.581406,
              45.60394
            ],
            [
              -122.58600555751501,
              45.60474253600229
            ],
            [
              -122.602606,
              45.607639
            ],
            [
              -122.643907,
              45.609739
            ],
            [
              -122.675008,
              45.618039
            ],
            [
              -122.691008,
              45.624739
            ],
            [
              -122.713309,
              45.637438
            ],
            [
              -122.738109,
              45.644138
            ],
            [
              -122.762414110309,
              45.656431935411796
            ],
            [
              -122.76381,
              45.657138
            ],
            [
              -122.774511,
              45.680437
            ],
            [
              -122.772511,
              45.699637
            ],
            [
              -122.762189526428,
              45.7285767375573
            ],
            [
              -122.784735,
              45.720946
            ],
            [
              -122.929146,
              45.721482
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "41",
      "COUNTYFP": "041",
      "COUNTYNS": "01135856",
      "AFFGEOID": "0500000US41041",
      "GEOID": "41041",
      "NAME": "Lincoln",
      "LSAD": "06",
      "ALAND": 2539209521,
      "AWATER": 554669104,
      "County_state": "Lincoln,41"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -124.1152,
              44.286486
            ],
            [
              -124.115851774242,
              44.276233322854296
            ],
            [
              -124.063303,
              44.275094
            ],
            [
              -124.013824,
              44.277329
            ],
            [
              -123.941072,
              44.277375
            ],
            [
              -123.941075,
              44.282951
            ],
            [
              -123.775598,
              44.283547
            ],
            [
              -123.775789,
              44.316167
            ],
            [
              -123.816525,
              44.315303
            ],
            [
              -123.816709,
              44.344622
            ],
            [
              -123.734996,
              44.345778
            ],
            [
              -123.735153,
              44.36035
            ],
            [
              -123.715538,
              44.360458
            ],
            [
              -123.72014,
              44.389506
            ],
            [
              -123.720071,
              44.433374
            ],
            [
              -123.597677,
              44.433109
            ],
            [
              -123.602817,
              44.651159
            ],
            [
              -123.602609,
              44.721154
            ],
            [
              -123.704467,
              44.721154
            ],
            [
              -123.704578,
              44.73898
            ],
            [
              -123.725001,
              44.739046
            ],
            [
              -123.724663,
              45.044432
            ],
            [
              -124.004597012963,
              45.0449668587576
            ],
            [
              -124.015243,
              44.982904
            ],
            [
              -124.023834,
              44.949825
            ],
            [
              -124.025678,
              44.936542
            ],
            [
              -124.025136,
              44.928175
            ],
            [
              -124.032296,
              44.900809
            ],
            [
              -124.048814,
              44.850007
            ],
            [
              -124.054151,
              44.838233
            ],
            [
              -124.063155,
              44.835333
            ],
            [
              -124.066746,
              44.831191
            ],
            [
              -124.074066,
              44.798107
            ],
            [
              -124.075473,
              44.771403
            ],
            [
              -124.066325,
              44.762671
            ],
            [
              -124.059077,
              44.737656
            ],
            [
              -124.063406,
              44.703177
            ],
            [
              -124.070394,
              44.683514
            ],
            [
              -124.060043,
              44.669361
            ],
            [
              -124.058281,
              44.658866
            ],
            [
              -124.065008,
              44.632504
            ],
            [
              -124.065202,
              44.622445
            ],
            [
              -124.06914,
              44.612979
            ],
            [
              -124.067251,
              44.60804
            ],
            [
              -124.076387,
              44.531214
            ],
            [
              -124.083601,
              44.501123
            ],
            [
              -124.084429,
              44.486927
            ],
            [
              -124.082061,
              44.478171
            ],
            [
              -124.082113,
              44.441518
            ],
            [
              -124.084401,
              44.415611
            ],
            [
              -124.092101,
              44.370388
            ],
            [
              -124.100587,
              44.331926
            ],
            [
              -124.10406346210002,
              44.3251889738255
            ],
            [
              -124.109556,
              44.314545
            ],
            [
              -124.108088,
              44.309926
            ],
            [
              -124.10907,
              44.303707
            ],
            [
              -124.1152,
              44.286486
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "41",
      "COUNTYFP": "063",
      "COUNTYNS": "01155135",
      "AFFGEOID": "0500000US41063",
      "GEOID": "41063",
      "NAME": "Wallowa",
      "LSAD": "06",
      "ALAND": 8147836572,
      "AWATER": 14191789,
      "County_state": "Wallowa,41"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -117.97766333505501,
              46.000723475442804
            ],
            [
              -117.977639,
              45.860602
            ],
            [
              -117.97298,
              45.860595
            ],
            [
              -117.747327,
              45.861072
            ],
            [
              -117.74765,
              45.773625
            ],
            [
              -117.788395,
              45.773515
            ],
            [
              -117.787623,
              45.689567
            ],
            [
              -117.766865,
              45.689605
            ],
            [
              -117.767063,
              45.660227
            ],
            [
              -117.746137,
              45.660736
            ],
            [
              -117.745959,
              45.616635
            ],
            [
              -117.727225,
              45.61699
            ],
            [
              -117.727235,
              45.51478
            ],
            [
              -117.683986,
              45.51523
            ],
            [
              -117.68379,
              45.428365
            ],
            [
              -117.664146,
              45.428344
            ],
            [
              -117.663391,
              45.365749
            ],
            [
              -117.643485,
              45.365322
            ],
            [
              -117.643494,
              45.33687
            ],
            [
              -117.582344,
              45.338096
            ],
            [
              -117.582166,
              45.310184
            ],
            [
              -117.562208,
              45.310219
            ],
            [
              -117.561969,
              45.295879
            ],
            [
              -117.542058,
              45.295956
            ],
            [
              -117.542216,
              45.267424
            ],
            [
              -117.522249,
              45.267731
            ],
            [
              -117.520941,
              45.209768
            ],
            [
              -117.476762,
              45.209973
            ],
            [
              -117.476684,
              45.166539
            ],
            [
              -117.266006,
              45.166539
            ],
            [
              -117.266573,
              45.08057
            ],
            [
              -116.78377192657202,
              45.078265731912005
            ],
            [
              -116.784244,
              45.088128
            ],
            [
              -116.783537,
              45.093605
            ],
            [
              -116.782492,
              45.09579
            ],
            [
              -116.774847,
              45.105536
            ],
            [
              -116.754643,
              45.113972
            ],
            [
              -116.731216,
              45.139934
            ],
            [
              -116.729607,
              45.142091
            ],
            [
              -116.728757,
              45.144381
            ],
            [
              -116.724188,
              45.162924
            ],
            [
              -116.724205,
              45.171501
            ],
            [
              -116.709536,
              45.203015
            ],
            [
              -116.708546,
              45.207356
            ],
            [
              -116.70975,
              45.217243
            ],
            [
              -116.709373,
              45.219463
            ],
            [
              -116.703607,
              45.239757
            ],
            [
              -116.696047,
              45.254679
            ],
            [
              -116.691388,
              45.263739
            ],
            [
              -116.687213119705,
              45.26768125110139
            ],
            [
              -116.687027,
              45.267857
            ],
            [
              -116.681013,
              45.27072
            ],
            [
              -116.675587,
              45.274867
            ],
            [
              -116.674493,
              45.276349
            ],
            [
              -116.672733,
              45.283183
            ],
            [
              -116.672163,
              45.288938
            ],
            [
              -116.672594,
              45.298023
            ],
            [
              -116.674648,
              45.314342
            ],
            [
              -116.673793,
              45.321511
            ],
            [
              -116.653252,
              45.351084
            ],
            [
              -116.626633,
              45.388037
            ],
            [
              -116.619057,
              45.39821
            ],
            [
              -116.597447,
              45.41277
            ],
            [
              -116.592416,
              45.427356
            ],
            [
              -116.588195,
              45.44292
            ],
            [
              -116.581382,
              45.448984
            ],
            [
              -116.575949,
              45.452522
            ],
            [
              -116.563985,
              45.460169
            ],
            [
              -116.561744,
              45.461213
            ],
            [
              -116.554829,
              45.46293
            ],
            [
              -116.55498,
              45.472801
            ],
            [
              -116.558803,
              45.480076
            ],
            [
              -116.558804,
              45.481188
            ],
            [
              -116.553473,
              45.499107
            ],
            [
              -116.548676,
              45.510385
            ],
            [
              -116.543837,
              45.514193
            ],
            [
              -116.535482,
              45.525079
            ],
            [
              -116.523638,
              45.54661
            ],
            [
              -116.502756,
              45.566608
            ],
            [
              -116.490279,
              45.574499
            ],
            [
              -116.48297,
              45.577008
            ],
            [
              -116.481943,
              45.577898
            ],
            [
              -116.463635,
              45.602785
            ],
            [
              -116.463504,
              45.615785
            ],
            [
              -116.46517,
              45.617986
            ],
            [
              -116.469813,
              45.620604
            ],
            [
              -116.472882,
              45.624884
            ],
            [
              -116.477452,
              45.631267
            ],
            [
              -116.482495,
              45.639916
            ],
            [
              -116.487894,
              45.649769
            ],
            [
              -116.49451,
              45.655679
            ],
            [
              -116.512326,
              45.670224
            ],
            [
              -116.523961,
              45.677639
            ],
            [
              -116.528272,
              45.681473
            ],
            [
              -116.535396,
              45.691734
            ],
            [
              -116.536395,
              45.69665
            ],
            [
              -116.538014,
              45.714929
            ],
            [
              -116.535698,
              45.734231
            ],
            [
              -116.537173,
              45.737288
            ],
            [
              -116.546643,
              45.750972
            ],
            [
              -116.549085,
              45.752735
            ],
            [
              -116.553548,
              45.753388
            ],
            [
              -116.559444,
              45.755189
            ],
            [
              -116.577422,
              45.76753
            ],
            [
              -116.593004,
              45.778541
            ],
            [
              -116.60504,
              45.781018
            ],
            [
              -116.632032,
              45.784979
            ],
            [
              -116.635814,
              45.783642
            ],
            [
              -116.639641,
              45.781274
            ],
            [
              -116.646342,
              45.779815
            ],
            [
              -116.659629,
              45.780016
            ],
            [
              -116.665344,
              45.781998
            ],
            [
              -116.680139,
              45.79359
            ],
            [
              -116.687007,
              45.806319
            ],
            [
              -116.697192,
              45.820135
            ],
            [
              -116.698079,
              45.820852
            ],
            [
              -116.70845,
              45.825117
            ],
            [
              -116.711822,
              45.826267
            ],
            [
              -116.715527,
              45.826773
            ],
            [
              -116.736268,
              45.826179
            ],
            [
              -116.740486,
              45.82446
            ],
            [
              -116.745219,
              45.821394
            ],
            [
              -116.750978,
              45.818537
            ],
            [
              -116.755288,
              45.817061
            ],
            [
              -116.759787,
              45.816167
            ],
            [
              -116.7634,
              45.81658
            ],
            [
              -116.782676,
              45.825376
            ],
            [
              -116.788329,
              45.831928
            ],
            [
              -116.789066,
              45.833471
            ],
            [
              -116.788923,
              45.836741
            ],
            [
              -116.78752,
              45.840204
            ],
            [
              -116.787792,
              45.844267
            ],
            [
              -116.790151,
              45.849851
            ],
            [
              -116.79445497532701,
              45.856140639876195
            ],
            [
              -116.796051,
              45.858473
            ],
            [
              -116.814142,
              45.877551
            ],
            [
              -116.819182,
              45.880938
            ],
            [
              -116.830003,
              45.886405
            ],
            [
              -116.84355,
              45.892273
            ],
            [
              -116.857254,
              45.904159
            ],
            [
              -116.859795,
              45.907264
            ],
            [
              -116.866544,
              45.916958
            ],
            [
              -116.869655,
              45.923799
            ],
            [
              -116.875706,
              45.945008
            ],
            [
              -116.886843,
              45.958617
            ],
            [
              -116.892935,
              45.974396
            ],
            [
              -116.911409,
              45.988912
            ],
            [
              -116.915989,
              45.995413
            ],
            [
              -116.940681,
              45.996274
            ],
            [
              -116.985882,
              45.996974
            ],
            [
              -117.051304,
              45.996849
            ],
            [
              -117.070047,
              45.99751
            ],
            [
              -117.212616,
              45.998321
            ],
            [
              -117.214534,
              45.99832
            ],
            [
              -117.216731,
              45.998356
            ],
            [
              -117.337668,
              45.998662
            ],
            [
              -117.353928,
              45.996349
            ],
            [
              -117.390738,
              45.998598
            ],
            [
              -117.439943,
              45.998633
            ],
            [
              -117.475148,
              45.997893
            ],
            [
              -117.47536,
              45.997855
            ],
            [
              -117.479935132756,
              45.997869469110505
            ],
            [
              -117.480103,
              45.99787
            ],
            [
              -117.504833,
              45.998317
            ],
            [
              -117.603163,
              45.998887
            ],
            [
              -117.60342451872,
              45.9988892323573
            ],
            [
              -117.717852,
              45.999866
            ],
            [
              -117.97766333505501,
              46.000723475442804
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "41",
      "COUNTYFP": "045",
      "COUNTYNS": "01135858",
      "AFFGEOID": "0500000US41045",
      "GEOID": "41045",
      "NAME": "Malheur",
      "LSAD": "06",
      "ALAND": 25608869178,
      "AWATER": 109173277,
      "County_state": "Malheur,41"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -118.232214,
              44.256129
            ],
            [
              -118.228843,
              44.21255
            ],
            [
              -118.227645,
              44.039862
            ],
            [
              -118.228044,
              43.797042
            ],
            [
              -118.2321,
              43.770829
            ],
            [
              -118.232059,
              43.374901
            ],
            [
              -118.228069,
              43.348964
            ],
            [
              -118.228793,
              42.914625
            ],
            [
              -118.216832,
              42.914703
            ],
            [
              -118.214698,
              42.275891
            ],
            [
              -118.195281,
              42.275813
            ],
            [
              -118.197369215928,
              41.9969940811635
            ],
            [
              -118.197189,
              41.996995
            ],
            [
              -117.873467,
              41.998335
            ],
            [
              -117.625973,
              41.998102
            ],
            [
              -117.623731,
              41.998467
            ],
            [
              -117.443062,
              41.999659
            ],
            [
              -117.403613,
              41.99929
            ],
            [
              -117.217551,
              41.999887
            ],
            [
              -117.197798,
              42.00038
            ],
            [
              -117.068613,
              42.000035
            ],
            [
              -117.055402,
              41.99989
            ],
            [
              -117.040906,
              41.99989
            ],
            [
              -117.026197,
              41.99989
            ],
            [
              -117.026098,
              42.117647
            ],
            [
              -117.02659,
              42.133258
            ],
            [
              -117.026195,
              42.166404
            ],
            [
              -117.026129,
              42.357193
            ],
            [
              -117.026551,
              42.378557
            ],
            [
              -117.026665,
              42.624878
            ],
            [
              -117.026331,
              42.807015
            ],
            [
              -117.026303,
              42.80717
            ],
            [
              -117.026253,
              42.807447
            ],
            [
              -117.026683,
              43.024876
            ],
            [
              -117.026652,
              43.025128
            ],
            [
              -117.026746,
              43.577526
            ],
            [
              -117.026774,
              43.578674
            ],
            [
              -117.026922,
              43.593632
            ],
            [
              -117.026889,
              43.596033
            ],
            [
              -117.026824,
              43.600357
            ],
            [
              -117.02676,
              43.601912
            ],
            [
              -117.026789,
              43.610669
            ],
            [
              -117.026937,
              43.617614
            ],
            [
              -117.027001,
              43.621032
            ],
            [
              -117.026905,
              43.62488
            ],
            [
              -117.026705,
              43.631659
            ],
            [
              -117.026661,
              43.664385
            ],
            [
              -117.026717,
              43.675523
            ],
            [
              -117.026624495654,
              43.6808035152354
            ],
            [
              -117.026586,
              43.683001
            ],
            [
              -117.026825,
              43.706193
            ],
            [
              -117.026725,
              43.714815
            ],
            [
              -117.026841,
              43.732905
            ],
            [
              -117.026651,
              43.733935
            ],
            [
              -117.026634,
              43.808104
            ],
            [
              -117.02223245857301,
              43.8174250747811
            ],
            [
              -117.021884,
              43.818163
            ],
            [
              -117.023577,
              43.823811
            ],
            [
              -117.032881,
              43.830025
            ],
            [
              -117.029559,
              43.83906
            ],
            [
              -117.025156,
              43.841334
            ],
            [
              -117.018465,
              43.841344
            ],
            [
              -117.012831,
              43.840001
            ],
            [
              -117.009002,
              43.842323
            ],
            [
              -117.007697,
              43.846239
            ],
            [
              -117.010701,
              43.849754
            ],
            [
              -117.019444,
              43.852112
            ],
            [
              -117.022707,
              43.854396
            ],
            [
              -117.021075,
              43.858964
            ],
            [
              -117.01077,
              43.862269
            ],
            [
              -116.999061,
              43.864637
            ],
            [
              -116.997391,
              43.864874
            ],
            [
              -116.991415,
              43.863864
            ],
            [
              -116.989598,
              43.864301
            ],
            [
              -116.98294,
              43.86771
            ],
            [
              -116.982347,
              43.86884
            ],
            [
              -116.97967445865001,
              43.8801286658834
            ],
            [
              -116.976024,
              43.895548
            ],
            [
              -116.976429,
              43.901293
            ],
            [
              -116.977332,
              43.905812
            ],
            [
              -116.97958,
              43.915253
            ],
            [
              -116.965828,
              43.917141
            ],
            [
              -116.960103,
              43.921229
            ],
            [
              -116.959993,
              43.93065
            ],
            [
              -116.967641,
              43.934792
            ],
            [
              -116.969004,
              43.939207
            ],
            [
              -116.96518,
              43.943741
            ],
            [
              -116.961482,
              43.952663
            ],
            [
              -116.961268,
              43.959192
            ],
            [
              -116.959808,
              43.963815
            ],
            [
              -116.959206,
              43.96636
            ],
            [
              -116.963552,
              43.968457
            ],
            [
              -116.969842,
              43.967588
            ],
            [
              -116.975856,
              43.973913
            ],
            [
              -116.968743,
              43.977477
            ],
            [
              -116.959871,
              43.982925
            ],
            [
              -116.938195,
              43.98459
            ],
            [
              -116.936384,
              43.991574
            ],
            [
              -116.943159,
              44.001536
            ],
            [
              -116.936765,
              44.010608
            ],
            [
              -116.934485,
              44.021249
            ],
            [
              -116.934727,
              44.023806
            ],
            [
              -116.937342,
              44.029376
            ],
            [
              -116.943361,
              44.035645
            ],
            [
              -116.956246,
              44.042888
            ],
            [
              -116.972504,
              44.048771
            ],
            [
              -116.973185,
              44.049425
            ],
            [
              -116.974016,
              44.053663
            ],
            [
              -116.977351,
              44.085364
            ],
            [
              -116.974253,
              44.088295
            ],
            [
              -116.967203,
              44.090936
            ],
            [
              -116.957009,
              44.091743
            ],
            [
              -116.943132,
              44.09406
            ],
            [
              -116.937835,
              44.096943
            ],
            [
              -116.933704,
              44.100039
            ],
            [
              -116.928306,
              44.107326
            ],
            [
              -116.927688,
              44.109438
            ],
            [
              -116.89713301012901,
              44.1525971517028
            ],
            [
              -116.895931,
              44.154295
            ],
            [
              -116.894309,
              44.158114
            ],
            [
              -116.894083,
              44.160191
            ],
            [
              -116.895757,
              44.171267
            ],
            [
              -116.900103,
              44.176851
            ],
            [
              -116.902752,
              44.179467
            ],
            [
              -116.925392,
              44.191544
            ],
            [
              -116.935443,
              44.193962
            ],
            [
              -116.940534,
              44.19371
            ],
            [
              -116.945256,
              44.191677
            ],
            [
              -116.947591,
              44.191264
            ],
            [
              -116.965498,
              44.194126
            ],
            [
              -116.967259,
              44.194581
            ],
            [
              -116.971675,
              44.197256
            ],
            [
              -116.973701,
              44.208017
            ],
            [
              -116.973945,
              44.225932
            ],
            [
              -116.971958,
              44.235677
            ],
            [
              -116.973542,
              44.23998
            ],
            [
              -116.975905,
              44.242844
            ],
            [
              -116.98687,
              44.245477
            ],
            [
              -117.001,
              44.245386
            ],
            [
              -117.016921,
              44.245391
            ],
            [
              -117.020231,
              44.246063
            ],
            [
              -117.025277,
              44.248505
            ],
            [
              -117.027558,
              44.248881
            ],
            [
              -117.031862,
              44.248635
            ],
            [
              -117.03585,
              44.246805
            ],
            [
              -117.042283,
              44.242775
            ],
            [
              -117.045513,
              44.232005
            ],
            [
              -117.047062,
              44.229742
            ],
            [
              -117.050057,
              44.22883
            ],
            [
              -117.05303,
              44.229076
            ],
            [
              -117.05651,
              44.230874
            ],
            [
              -117.059352,
              44.237244
            ],
            [
              -117.067284,
              44.24401
            ],
            [
              -117.07835,
              44.249885
            ],
            [
              -117.089503,
              44.258234
            ],
            [
              -117.090933,
              44.260311
            ],
            [
              -117.093578,
              44.269383
            ],
            [
              -117.09457,
              44.270978
            ],
            [
              -117.098531,
              44.275533
            ],
            [
              -117.102242,
              44.278799
            ],
            [
              -117.104208,
              44.27994
            ],
            [
              -117.107673,
              44.280763
            ],
            [
              -117.111617,
              44.280667
            ],
            [
              -117.118018,
              44.278945
            ],
            [
              -117.121037,
              44.277585
            ],
            [
              -117.130904,
              44.269453
            ],
            [
              -117.13253,
              44.267045
            ],
            [
              -117.133104,
              44.264236
            ],
            [
              -117.133984,
              44.262972
            ],
            [
              -117.138523,
              44.25937
            ],
            [
              -117.143394,
              44.258262
            ],
            [
              -117.15706,
              44.25749
            ],
            [
              -117.170342,
              44.25889
            ],
            [
              -117.193129,
              44.270963
            ],
            [
              -117.198147,
              44.273828
            ],
            [
              -117.216974,
              44.288357
            ],
            [
              -117.222647,
              44.297578
            ],
            [
              -117.222451,
              44.298963
            ],
            [
              -117.220069,
              44.301382
            ],
            [
              -117.220060563209,
              44.3014039750731
            ],
            [
              -117.487014,
              44.300317
            ],
            [
              -117.486744,
              44.387246
            ],
            [
              -117.509214,
              44.387143
            ],
            [
              -117.509202,
              44.401461
            ],
            [
              -117.529408,
              44.401329
            ],
            [
              -117.52936,
              44.415722
            ],
            [
              -117.569664,
              44.415678
            ],
            [
              -117.56962,
              44.430062
            ],
            [
              -117.590858,
              44.43013
            ],
            [
              -117.590849,
              44.444573
            ],
            [
              -117.971787,
              44.443849
            ],
            [
              -117.971947,
              44.429296
            ],
            [
              -118.011402,
              44.429615
            ],
            [
              -118.011485,
              44.415169
            ],
            [
              -118.031671,
              44.415192
            ],
            [
              -118.031808,
              44.389953
            ],
            [
              -118.051956,
              44.390032
            ],
            [
              -118.051902,
              44.372004
            ],
            [
              -118.071916,
              44.372009
            ],
            [
              -118.071877,
              44.357562
            ],
            [
              -118.087016,
              44.357646
            ],
            [
              -118.092122,
              44.328734
            ],
            [
              -118.112068,
              44.328754
            ],
            [
              -118.112092,
              44.314271
            ],
            [
              -118.132347,
              44.31431
            ],
            [
              -118.132141,
              44.299483
            ],
            [
              -118.152456,
              44.299509
            ],
            [
              -118.152945,
              44.270684
            ],
            [
              -118.193405,
              44.270708
            ],
            [
              -118.193345,
              44.2562
            ],
            [
              -118.232214,
              44.256129
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "41",
      "COUNTYFP": "065",
      "COUNTYNS": "01155136",
      "AFFGEOID": "0500000US41065",
      "GEOID": "41065",
      "NAME": "Wasco",
      "LSAD": "06",
      "ALAND": 6167121090,
      "AWATER": 35716361,
      "County_state": "Wasco,41"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -121.805269,
              45.011496
            ],
            [
              -121.801623,
              44.982743
            ],
            [
              -121.786735,
              44.945223
            ],
            [
              -121.752417,
              44.93057
            ],
            [
              -121.747297,
              44.914636
            ],
            [
              -121.714405,
              44.905901
            ],
            [
              -121.734223,
              44.88572
            ],
            [
              -121.753182,
              44.86124
            ],
            [
              -121.742096,
              44.846434
            ],
            [
              -121.752994,
              44.829919
            ],
            [
              -121.725068,
              44.825519
            ],
            [
              -121.08948,
              44.823272
            ],
            [
              -120.915695,
              44.822969
            ],
            [
              -120.371441,
              44.821769
            ],
            [
              -120.400495,
              44.830109
            ],
            [
              -120.412227,
              44.851669
            ],
            [
              -120.455204,
              44.865607
            ],
            [
              -120.458519,
              44.881568
            ],
            [
              -120.436139,
              44.894064
            ],
            [
              -120.450991,
              44.908663
            ],
            [
              -120.468899,
              44.913388
            ],
            [
              -120.472884,
              44.935458
            ],
            [
              -120.488231,
              44.950682
            ],
            [
              -120.47751,
              44.980374
            ],
            [
              -120.486545,
              45.00615
            ],
            [
              -120.482447,
              45.05585
            ],
            [
              -120.49516,
              45.068279
            ],
            [
              -120.503733,
              45.083477
            ],
            [
              -120.519707,
              45.083469
            ],
            [
              -120.555167,
              45.083472
            ],
            [
              -120.585721,
              45.083522
            ],
            [
              -120.62292,
              45.083586
            ],
            [
              -120.658898,
              45.083643
            ],
            [
              -120.682542,
              45.083682
            ],
            [
              -120.722685,
              45.083737
            ],
            [
              -120.729824,
              45.083872
            ],
            [
              -120.729888,
              45.086291
            ],
            [
              -120.729385,
              45.087378
            ],
            [
              -120.728806,
              45.089737
            ],
            [
              -120.726847,
              45.090587
            ],
            [
              -120.726569,
              45.092123
            ],
            [
              -120.727159,
              45.093774
            ],
            [
              -120.727269,
              45.096352
            ],
            [
              -120.728486,
              45.098086
            ],
            [
              -120.726853,
              45.100099
            ],
            [
              -120.725709,
              45.103023
            ],
            [
              -120.723595,
              45.104532
            ],
            [
              -120.721845,
              45.106288
            ],
            [
              -120.720642,
              45.106441
            ],
            [
              -120.71953,
              45.107084
            ],
            [
              -120.719484,
              45.108006
            ],
            [
              -120.7184,
              45.10998
            ],
            [
              -120.718324,
              45.111741
            ],
            [
              -120.718657,
              45.112866
            ],
            [
              -120.718728,
              45.115817
            ],
            [
              -120.718373,
              45.117704
            ],
            [
              -120.718645,
              45.119629
            ],
            [
              -120.720727,
              45.121021
            ],
            [
              -120.72235,
              45.12236
            ],
            [
              -120.72311,
              45.125223
            ],
            [
              -120.723932,
              45.128931
            ],
            [
              -120.723809,
              45.129951
            ],
            [
              -120.725135,
              45.131404
            ],
            [
              -120.727515,
              45.132358
            ],
            [
              -120.731196,
              45.13319
            ],
            [
              -120.73398,
              45.134643
            ],
            [
              -120.739017,
              45.134707
            ],
            [
              -120.743482,
              45.136284
            ],
            [
              -120.746475,
              45.139308
            ],
            [
              -120.74927,
              45.142267
            ],
            [
              -120.752368,
              45.14347
            ],
            [
              -120.755933,
              45.144179
            ],
            [
              -120.758607,
              45.1444
            ],
            [
              -120.76049,
              45.144335
            ],
            [
              -120.761853,
              45.14653
            ],
            [
              -120.760812,
              45.148482
            ],
            [
              -120.762927,
              45.149706
            ],
            [
              -120.765236,
              45.151912
            ],
            [
              -120.767133,
              45.15391
            ],
            [
              -120.768822,
              45.155194
            ],
            [
              -120.769379,
              45.156511
            ],
            [
              -120.768317,
              45.157141
            ],
            [
              -120.767556,
              45.159006
            ],
            [
              -120.768795,
              45.160065
            ],
            [
              -120.771213,
              45.162485
            ],
            [
              -120.775516,
              45.162333
            ],
            [
              -120.779361,
              45.163201
            ],
            [
              -120.782757,
              45.163405
            ],
            [
              -120.786773,
              45.163713
            ],
            [
              -120.78948,
              45.163094
            ],
            [
              -120.792883,
              45.16496
            ],
            [
              -120.79638,
              45.164609
            ],
            [
              -120.798117,
              45.165503
            ],
            [
              -120.80111,
              45.166162
            ],
            [
              -120.802598,
              45.167929
            ],
            [
              -120.80514,
              45.168203
            ],
            [
              -120.80591,
              45.167649
            ],
            [
              -120.807957,
              45.167995
            ],
            [
              -120.808748,
              45.169031
            ],
            [
              -120.811136,
              45.171237
            ],
            [
              -120.815804,
              45.172449
            ],
            [
              -120.819136,
              45.173989
            ],
            [
              -120.822901,
              45.17614
            ],
            [
              -120.825329,
              45.17682
            ],
            [
              -120.828826,
              45.176957
            ],
            [
              -120.831402,
              45.179058
            ],
            [
              -120.834458,
              45.180028
            ],
            [
              -120.836514,
              45.180779
            ],
            [
              -120.837965,
              45.183577
            ],
            [
              -120.840759,
              45.185589
            ],
            [
              -120.843645,
              45.18651
            ],
            [
              -120.847059,
              45.188182
            ],
            [
              -120.848278,
              45.189509
            ],
            [
              -120.850419,
              45.19002
            ],
            [
              -120.850853,
              45.189696
            ],
            [
              -120.852949,
              45.190654
            ],
            [
              -120.854742,
              45.192316
            ],
            [
              -120.856271,
              45.193292
            ],
            [
              -120.857513,
              45.194602
            ],
            [
              -120.861191,
              45.19493
            ],
            [
              -120.864761,
              45.195612
            ],
            [
              -120.86747,
              45.197599
            ],
            [
              -120.869608,
              45.198543
            ],
            [
              -120.872555,
              45.198421
            ],
            [
              -120.878381,
              45.19919
            ],
            [
              -120.883252,
              45.200624
            ],
            [
              -120.886294,
              45.200559
            ],
            [
              -120.888698,
              45.201756
            ],
            [
              -120.891088,
              45.201894
            ],
            [
              -120.89167,
              45.201318
            ],
            [
              -120.894991,
              45.201753
            ],
            [
              -120.896534,
              45.203169
            ],
            [
              -120.902253,
              45.202925
            ],
            [
              -120.904581,
              45.202914
            ],
            [
              -120.907421,
              45.20218
            ],
            [
              -120.907654,
              45.201132
            ],
            [
              -120.910022,
              45.199806
            ],
            [
              -120.91186,
              45.19985
            ],
            [
              -120.915066,
              45.19837
            ],
            [
              -120.918635,
              45.19792
            ],
            [
              -120.920768,
              45.199375
            ],
            [
              -120.919076,
              45.200982
            ],
            [
              -120.920782,
              45.202562
            ],
            [
              -120.922895,
              45.201946
            ],
            [
              -120.925206,
              45.200752
            ],
            [
              -120.927813,
              45.199825
            ],
            [
              -120.930172,
              45.20095
            ],
            [
              -120.930327,
              45.20197
            ],
            [
              -120.932887,
              45.202355
            ],
            [
              -120.933958,
              45.200764
            ],
            [
              -120.936655,
              45.200198
            ],
            [
              -120.939568,
              45.199612
            ],
            [
              -120.942687,
              45.198888
            ],
            [
              -120.943168,
              45.199398
            ],
            [
              -120.943319,
              45.20015
            ],
            [
              -120.945737,
              45.200237
            ],
            [
              -120.948197,
              45.200226
            ],
            [
              -120.951432,
              45.201987
            ],
            [
              -120.953745,
              45.201317
            ],
            [
              -120.953147,
              45.19931
            ],
            [
              -120.952551,
              45.197805
            ],
            [
              -120.954077,
              45.196205
            ],
            [
              -120.956211,
              45.196484
            ],
            [
              -120.960284,
              45.197114
            ],
            [
              -120.96352,
              45.19837
            ],
            [
              -120.967012,
              45.198759
            ],
            [
              -120.971202,
              45.199855
            ],
            [
              -120.975509,
              45.200353
            ],
            [
              -120.980047,
              45.199216
            ],
            [
              -120.983291,
              45.19911
            ],
            [
              -120.984066,
              45.198172
            ],
            [
              -120.987209,
              45.198807
            ],
            [
              -120.987792,
              45.200513
            ],
            [
              -120.989897,
              45.203485
            ],
            [
              -120.992893,
              45.203972
            ],
            [
              -120.995936,
              45.204497
            ],
            [
              -120.996981,
              45.206964
            ],
            [
              -121.000301,
              45.208867
            ],
            [
              -121.003543,
              45.20835
            ],
            [
              -121.006162,
              45.209935
            ],
            [
              -121.009465,
              45.210893
            ],
            [
              -121.010519,
              45.209867
            ],
            [
              -121.013298,
              45.209808
            ],
            [
              -121.013136,
              45.211524
            ],
            [
              -121.014831,
              45.213309
            ],
            [
              -121.016886,
              45.212805
            ],
            [
              -121.019459,
              45.214253
            ],
            [
              -121.018528,
              45.215777
            ],
            [
              -121.018928,
              45.217836
            ],
            [
              -121.020913,
              45.217879
            ],
            [
              -121.02411,
              45.218988
            ],
            [
              -121.022195,
              45.221295
            ],
            [
              -121.022872,
              45.223449
            ],
            [
              -121.022081,
              45.225704
            ],
            [
              -121.020228,
              45.227346
            ],
            [
              -121.021078,
              45.230008
            ],
            [
              -121.023056,
              45.231005
            ],
            [
              -121.023287,
              45.233169
            ],
            [
              -121.021491,
              45.234632
            ],
            [
              -121.022601,
              45.236756
            ],
            [
              -121.023793,
              45.238048
            ],
            [
              -121.023876,
              45.240795
            ],
            [
              -121.02243,
              45.243921
            ],
            [
              -121.022526,
              45.247037
            ],
            [
              -121.022753,
              45.250292
            ],
            [
              -121.023,
              45.255072
            ],
            [
              -121.023275,
              45.262307
            ],
            [
              -121.022217,
              45.264793
            ],
            [
              -121.021001,
              45.270975
            ],
            [
              -121.024347,
              45.273554
            ],
            [
              -121.023499,
              45.276922
            ],
            [
              -121.020653,
              45.279082
            ],
            [
              -121.017771,
              45.284738
            ],
            [
              -121.023718,
              45.285401
            ],
            [
              -121.027371,
              45.284449
            ],
            [
              -121.029163,
              45.287224
            ],
            [
              -121.025574,
              45.289233
            ],
            [
              -121.022556,
              45.291386
            ],
            [
              -121.018588,
              45.301504
            ],
            [
              -121.011801,
              45.308785
            ],
            [
              -121.00293,
              45.30871
            ],
            [
              -120.998269,
              45.306727
            ],
            [
              -120.990473,
              45.309344
            ],
            [
              -120.988287,
              45.313725
            ],
            [
              -120.984519,
              45.320879
            ],
            [
              -120.978491,
              45.322265
            ],
            [
              -120.97499,
              45.320758
            ],
            [
              -120.973278,
              45.317835
            ],
            [
              -120.973839,
              45.316456
            ],
            [
              -120.974381,
              45.312945
            ],
            [
              -120.971195,
              45.308182
            ],
            [
              -120.962148,
              45.307272
            ],
            [
              -120.957366,
              45.309501
            ],
            [
              -120.952688,
              45.318313
            ],
            [
              -120.946612,
              45.324258
            ],
            [
              -120.9459,
              45.327986
            ],
            [
              -120.948172,
              45.331745
            ],
            [
              -120.951298,
              45.332695
            ],
            [
              -120.946372,
              45.336745
            ],
            [
              -120.940771,
              45.332645
            ],
            [
              -120.93708,
              45.331072
            ],
            [
              -120.932269,
              45.335143
            ],
            [
              -120.934255,
              45.339031
            ],
            [
              -120.938067,
              45.341245
            ],
            [
              -120.936472,
              45.347345
            ],
            [
              -120.930471,
              45.347745
            ],
            [
              -120.920198,
              45.350336
            ],
            [
              -120.920071,
              45.354645
            ],
            [
              -120.920571,
              45.357944
            ],
            [
              -120.914371,
              45.362645
            ],
            [
              -120.909271,
              45.362145
            ],
            [
              -120.900171,
              45.362745
            ],
            [
              -120.899771,
              45.368345
            ],
            [
              -120.898471,
              45.373345
            ],
            [
              -120.89284,
              45.379632
            ],
            [
              -120.884388,
              45.386657
            ],
            [
              -120.883088,
              45.389819
            ],
            [
              -120.877522,
              45.391687
            ],
            [
              -120.873202,
              45.388724
            ],
            [
              -120.868566,
              45.38842
            ],
            [
              -120.865836,
              45.391079
            ],
            [
              -120.866014,
              45.39322
            ],
            [
              -120.867783,
              45.396893
            ],
            [
              -120.868269,
              45.402646
            ],
            [
              -120.86691,
              45.407255
            ],
            [
              -120.86657,
              45.412746
            ],
            [
              -120.86427,
              45.417346
            ],
            [
              -120.86157,
              45.422046
            ],
            [
              -120.86287,
              45.426246
            ],
            [
              -120.85917,
              45.428645
            ],
            [
              -120.856671,
              45.427446
            ],
            [
              -120.851553,
              45.427464
            ],
            [
              -120.84947,
              45.429745
            ],
            [
              -120.852571,
              45.433845
            ],
            [
              -120.858519,
              45.435356
            ],
            [
              -120.865471,
              45.441946
            ],
            [
              -120.862576,
              45.44884
            ],
            [
              -120.861345,
              45.455053
            ],
            [
              -120.858372,
              45.460746
            ],
            [
              -120.849131,
              45.461901
            ],
            [
              -120.844471,
              45.463546
            ],
            [
              -120.838771,
              45.466946
            ],
            [
              -120.835571,
              45.471246
            ],
            [
              -120.838871,
              45.478946
            ],
            [
              -120.839171,
              45.482312
            ],
            [
              -120.836574,
              45.487846
            ],
            [
              -120.83362,
              45.492756
            ],
            [
              -120.83568,
              45.498544
            ],
            [
              -120.838681,
              45.501542
            ],
            [
              -120.836683,
              45.506242
            ],
            [
              -120.832885,
              45.508343
            ],
            [
              -120.827465,
              45.510653
            ],
            [
              -120.825738,
              45.514087
            ],
            [
              -120.830274,
              45.51565
            ],
            [
              -120.833333,
              45.515789
            ],
            [
              -120.837972,
              45.515746
            ],
            [
              -120.841912,
              45.517516
            ],
            [
              -120.845393,
              45.518713
            ],
            [
              -120.852642,
              45.521482
            ],
            [
              -120.854599,
              45.523536
            ],
            [
              -120.860273,
              45.526546
            ],
            [
              -120.868837,
              45.52858
            ],
            [
              -120.87519,
              45.526947
            ],
            [
              -120.878759,
              45.526877
            ],
            [
              -120.884777,
              45.531695
            ],
            [
              -120.884644,
              45.538782
            ],
            [
              -120.891407,
              45.541878
            ],
            [
              -120.896342,
              45.542455
            ],
            [
              -120.898972,
              45.546652
            ],
            [
              -120.900639,
              45.550905
            ],
            [
              -120.905093,
              45.555173
            ],
            [
              -120.908487,
              45.558303
            ],
            [
              -120.908824,
              45.562736
            ],
            [
              -120.90702,
              45.565364
            ],
            [
              -120.905928,
              45.568142
            ],
            [
              -120.905922,
              45.571093
            ],
            [
              -120.904815,
              45.576068
            ],
            [
              -120.901532,
              45.580599
            ],
            [
              -120.897365,
              45.585086
            ],
            [
              -120.89543,
              45.589505
            ],
            [
              -120.897304,
              45.593915
            ],
            [
              -120.901854,
              45.598778
            ],
            [
              -120.905627,
              45.600165
            ],
            [
              -120.910379,
              45.603845
            ],
            [
              -120.911675,
              45.607845
            ],
            [
              -120.907465,
              45.612355
            ],
            [
              -120.904175,
              45.616445
            ],
            [
              -120.904599,
              45.621031
            ],
            [
              -120.906035,
              45.624845
            ],
            [
              -120.907274,
              45.62645
            ],
            [
              -120.911877,
              45.632036
            ],
            [
              -120.914386,
              45.634977
            ],
            [
              -120.915459,
              45.639026
            ],
            [
              -120.915795173154,
              45.641301218792
            ],
            [
              -120.915876,
              45.641345
            ],
            [
              -120.943977,
              45.656445
            ],
            [
              -120.953077,
              45.656745
            ],
            [
              -120.977978,
              45.649345
            ],
            [
              -120.983478,
              45.648344
            ],
            [
              -121.007449,
              45.653217
            ],
            [
              -121.033582,
              45.650998
            ],
            [
              -121.06437,
              45.652549
            ],
            [
              -121.084933,
              45.647893
            ],
            [
              -121.120064,
              45.623134
            ],
            [
              -121.117052,
              45.618117
            ],
            [
              -121.1222,
              45.616067
            ],
            [
              -121.127724471392,
              45.6124956073897
            ],
            [
              -121.131953,
              45.609762
            ],
            [
              -121.139483,
              45.611962
            ],
            [
              -121.145534,
              45.607886
            ],
            [
              -121.167852,
              45.606098
            ],
            [
              -121.183841,
              45.606441
            ],
            [
              -121.196556,
              45.616689
            ],
            [
              -121.195233,
              45.629513
            ],
            [
              -121.200367,
              45.649829
            ],
            [
              -121.215779,
              45.671238
            ],
            [
              -121.251183,
              45.67839
            ],
            [
              -121.287323,
              45.687019
            ],
            [
              -121.312198,
              45.699925
            ],
            [
              -121.33777,
              45.704949
            ],
            [
              -121.372574,
              45.703111
            ],
            [
              -121.401739,
              45.692887
            ],
            [
              -121.423592,
              45.69399
            ],
            [
              -121.441224595532,
              45.6973034385521
            ],
            [
              -121.440646,
              45.519394
            ],
            [
              -121.482168,
              45.519604
            ],
            [
              -121.481888,
              45.258128
            ],
            [
              -121.696479,
              45.25792
            ],
            [
              -121.680898,
              45.250581
            ],
            [
              -121.682607,
              45.228988
            ],
            [
              -121.698375,
              45.226448
            ],
            [
              -121.714637,
              45.211232
            ],
            [
              -121.748245,
              45.2051
            ],
            [
              -121.731438,
              45.170633
            ],
            [
              -121.725283,
              45.159339
            ],
            [
              -121.711596,
              45.152824
            ],
            [
              -121.70805,
              45.134055
            ],
            [
              -121.693387,
              45.116934
            ],
            [
              -121.665717,
              45.11972
            ],
            [
              -121.651394,
              45.088803
            ],
            [
              -121.65871,
              45.066355
            ],
            [
              -121.687234,
              45.067371
            ],
            [
              -121.72235,
              45.046023
            ],
            [
              -121.716996,
              45.038037
            ],
            [
              -121.740994,
              45.034428
            ],
            [
              -121.778672,
              45.037277
            ],
            [
              -121.78147,
              45.015411
            ],
            [
              -121.805269,
              45.011496
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "41",
      "COUNTYFP": "027",
      "COUNTYNS": "01155131",
      "AFFGEOID": "0500000US41027",
      "GEOID": "41027",
      "NAME": "Hood River",
      "LSAD": "06",
      "ALAND": 1352203450,
      "AWATER": 29040461,
      "County_state": "Hood River,41"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -121.922028852172,
              45.649161899050796
            ],
            [
              -121.922051,
              45.610761
            ],
            [
              -121.911686,
              45.601769
            ],
            [
              -121.918106,
              45.587217
            ],
            [
              -121.903279,
              45.554879
            ],
            [
              -121.916916,
              45.546723
            ],
            [
              -121.90661,
              45.52123
            ],
            [
              -121.893844,
              45.513378
            ],
            [
              -121.869502,
              45.514278
            ],
            [
              -121.863679,
              45.492842
            ],
            [
              -121.819587,
              45.461675
            ],
            [
              -121.806845,
              45.454649
            ],
            [
              -121.806898,
              45.435286
            ],
            [
              -121.792759,
              45.415215
            ],
            [
              -121.776204,
              45.402672
            ],
            [
              -121.72419,
              45.393648
            ],
            [
              -121.697517,
              45.373663
            ],
            [
              -121.70326,
              45.331267
            ],
            [
              -121.679081,
              45.297177
            ],
            [
              -121.696479,
              45.25792
            ],
            [
              -121.481888,
              45.258128
            ],
            [
              -121.482168,
              45.519604
            ],
            [
              -121.440646,
              45.519394
            ],
            [
              -121.441224595532,
              45.6973034385521
            ],
            [
              -121.462849,
              45.701367
            ],
            [
              -121.499153,
              45.720846
            ],
            [
              -121.522392,
              45.724677
            ],
            [
              -121.523685853632,
              45.7249021020319
            ],
            [
              -121.533106,
              45.726541
            ],
            [
              -121.631167,
              45.704657
            ],
            [
              -121.668362,
              45.705082
            ],
            [
              -121.707358,
              45.694809
            ],
            [
              -121.735104,
              45.694039
            ],
            [
              -121.811304,
              45.706761
            ],
            [
              -121.867167,
              45.693277
            ],
            [
              -121.901855,
              45.670716
            ],
            [
              -121.900858,
              45.662009
            ],
            [
              -121.908267,
              45.654399
            ],
            [
              -121.922028852172,
              45.649161899050796
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "41",
      "COUNTYFP": "067",
      "COUNTYNS": "01155137",
      "AFFGEOID": "0500000US41067",
      "GEOID": "41067",
      "NAME": "Washington",
      "LSAD": "06",
      "ALAND": 1875846816,
      "AWATER": 6127010,
      "County_state": "Washington,41"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -123.485441,
              45.447136
            ],
            [
              -123.48608,
              45.433199
            ],
            [
              -123.46488,
              45.433332
            ],
            [
              -123.140244,
              45.433479
            ],
            [
              -123.139236,
              45.433475
            ],
            [
              -123.135114,
              45.418937
            ],
            [
              -123.114696,
              45.418957
            ],
            [
              -123.114641,
              45.404383
            ],
            [
              -123.062894,
              45.404898
            ],
            [
              -123.032161,
              45.404962
            ],
            [
              -123.032357,
              45.39089
            ],
            [
              -123.011282,
              45.391026
            ],
            [
              -123.011653,
              45.376348
            ],
            [
              -122.97024,
              45.346919
            ],
            [
              -122.965098,
              45.346428
            ],
            [
              -122.923297,
              45.346252
            ],
            [
              -122.908867,
              45.317476
            ],
            [
              -122.867891,
              45.317345
            ],
            [
              -122.846503,
              45.317192
            ],
            [
              -122.846433,
              45.346112
            ],
            [
              -122.80555,
              45.346283
            ],
            [
              -122.80547,
              45.331772
            ],
            [
              -122.743741,
              45.332067
            ],
            [
              -122.743866,
              45.348463
            ],
            [
              -122.743892,
              45.400764
            ],
            [
              -122.743942,
              45.402702
            ],
            [
              -122.743991,
              45.426023
            ],
            [
              -122.743721,
              45.433294
            ],
            [
              -122.743713,
              45.438377
            ],
            [
              -122.743663,
              45.467532
            ],
            [
              -122.743768,
              45.489188
            ],
            [
              -122.743786,
              45.490804
            ],
            [
              -122.743786,
              45.505122
            ],
            [
              -122.743812,
              45.507609
            ],
            [
              -122.743859,
              45.516665
            ],
            [
              -122.764464,
              45.519562
            ],
            [
              -122.764412,
              45.533862
            ],
            [
              -122.784771,
              45.5484
            ],
            [
              -122.805481,
              45.562911
            ],
            [
              -122.82621,
              45.566284
            ],
            [
              -122.846978,
              45.577516
            ],
            [
              -122.846849,
              45.591836
            ],
            [
              -122.867592,
              45.591878
            ],
            [
              -122.868303,
              45.606223
            ],
            [
              -122.887963,
              45.606352
            ],
            [
              -122.887837,
              45.620466
            ],
            [
              -122.908992,
              45.620763
            ],
            [
              -122.929115,
              45.642652
            ],
            [
              -122.929146,
              45.721482
            ],
            [
              -122.928901,
              45.735688
            ],
            [
              -122.990368,
              45.736459
            ],
            [
              -122.990344,
              45.750835
            ],
            [
              -123.031837,
              45.75142
            ],
            [
              -123.030873,
              45.779159
            ],
            [
              -123.34041,
              45.779849
            ],
            [
              -123.361622,
              45.779579
            ],
            [
              -123.361028,
              45.708696
            ],
            [
              -123.484726,
              45.708764
            ],
            [
              -123.485124,
              45.67925
            ],
            [
              -123.464357,
              45.679274
            ],
            [
              -123.464087,
              45.66514
            ],
            [
              -123.443752,
              45.665126
            ],
            [
              -123.443849,
              45.650678
            ],
            [
              -123.423002,
              45.65062
            ],
            [
              -123.423273,
              45.636176
            ],
            [
              -123.380216,
              45.635752
            ],
            [
              -123.380663,
              45.621985
            ],
            [
              -123.340499,
              45.622201
            ],
            [
              -123.340332,
              45.607455
            ],
            [
              -123.299438,
              45.607247
            ],
            [
              -123.299258,
              45.593017
            ],
            [
              -123.320211,
              45.592984
            ],
            [
              -123.319997,
              45.578684
            ],
            [
              -123.361131,
              45.578779
            ],
            [
              -123.361147,
              45.549902
            ],
            [
              -123.40205,
              45.550068
            ],
            [
              -123.402146,
              45.535646
            ],
            [
              -123.421935,
              45.535376
            ],
            [
              -123.421968,
              45.521408
            ],
            [
              -123.442721,
              45.521115
            ],
            [
              -123.441119,
              45.491273
            ],
            [
              -123.461181,
              45.49156
            ],
            [
              -123.463883,
              45.44726
            ],
            [
              -123.485441,
              45.447136
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "41",
      "COUNTYFP": "069",
      "COUNTYNS": "01135865",
      "AFFGEOID": "0500000US41069",
      "GEOID": "41069",
      "NAME": "Wheeler",
      "LSAD": "06",
      "ALAND": 4444476933,
      "AWATER": 1241033,
      "County_state": "Wheeler,41"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -120.49516,
              45.068279
            ],
            [
              -120.482447,
              45.05585
            ],
            [
              -120.486545,
              45.00615
            ],
            [
              -120.47751,
              44.980374
            ],
            [
              -120.488231,
              44.950682
            ],
            [
              -120.472884,
              44.935458
            ],
            [
              -120.468899,
              44.913388
            ],
            [
              -120.450991,
              44.908663
            ],
            [
              -120.436139,
              44.894064
            ],
            [
              -120.458519,
              44.881568
            ],
            [
              -120.455204,
              44.865607
            ],
            [
              -120.412227,
              44.851669
            ],
            [
              -120.400495,
              44.830109
            ],
            [
              -120.371441,
              44.821769
            ],
            [
              -120.404676,
              44.794719
            ],
            [
              -120.385687,
              44.761175
            ],
            [
              -120.386561,
              44.564009
            ],
            [
              -120.386074,
              44.436083
            ],
            [
              -120.021621,
              44.438793
            ],
            [
              -120.020708,
              44.389687
            ],
            [
              -119.899283,
              44.389575
            ],
            [
              -119.899002,
              44.30662
            ],
            [
              -119.655517,
              44.307045
            ],
            [
              -119.652431,
              44.823299
            ],
            [
              -119.671532,
              44.823343
            ],
            [
              -119.672228,
              44.995117
            ],
            [
              -119.791162,
              44.994755
            ],
            [
              -119.790699,
              45.067823
            ],
            [
              -119.910536,
              45.067928
            ],
            [
              -119.950613,
              45.065825
            ],
            [
              -120.131926,
              45.067354
            ],
            [
              -120.49516,
              45.068279
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "41",
      "COUNTYFP": "057",
      "COUNTYNS": "01135864",
      "AFFGEOID": "0500000US41057",
      "GEOID": "41057",
      "NAME": "Tillamook",
      "LSAD": "06",
      "ALAND": 2855122944,
      "AWATER": 595798838,
      "County_state": "Tillamook,41"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -124.015851,
              45.064759
            ],
            [
              -124.017991,
              45.049808
            ],
            [
              -124.00977,
              45.047266
            ],
            [
              -124.004668,
              45.048167
            ],
            [
              -124.004386,
              45.046197
            ],
            [
              -124.004597012963,
              45.0449668587576
            ],
            [
              -123.724663,
              45.044432
            ],
            [
              -123.724368,
              45.076226
            ],
            [
              -123.729982,
              45.076742
            ],
            [
              -123.784218,
              45.076655
            ],
            [
              -123.78454,
              45.216294
            ],
            [
              -123.463518,
              45.216312
            ],
            [
              -123.463132,
              45.274549
            ],
            [
              -123.464112,
              45.374535
            ],
            [
              -123.46488,
              45.433332
            ],
            [
              -123.48608,
              45.433199
            ],
            [
              -123.485441,
              45.447136
            ],
            [
              -123.463883,
              45.44726
            ],
            [
              -123.461181,
              45.49156
            ],
            [
              -123.441119,
              45.491273
            ],
            [
              -123.442721,
              45.521115
            ],
            [
              -123.421968,
              45.521408
            ],
            [
              -123.421935,
              45.535376
            ],
            [
              -123.402146,
              45.535646
            ],
            [
              -123.40205,
              45.550068
            ],
            [
              -123.361147,
              45.549902
            ],
            [
              -123.361131,
              45.578779
            ],
            [
              -123.319997,
              45.578684
            ],
            [
              -123.320211,
              45.592984
            ],
            [
              -123.299258,
              45.593017
            ],
            [
              -123.299438,
              45.607247
            ],
            [
              -123.340332,
              45.607455
            ],
            [
              -123.340499,
              45.622201
            ],
            [
              -123.380663,
              45.621985
            ],
            [
              -123.380216,
              45.635752
            ],
            [
              -123.423273,
              45.636176
            ],
            [
              -123.423002,
              45.65062
            ],
            [
              -123.443849,
              45.650678
            ],
            [
              -123.443752,
              45.665126
            ],
            [
              -123.464087,
              45.66514
            ],
            [
              -123.464357,
              45.679274
            ],
            [
              -123.485124,
              45.67925
            ],
            [
              -123.484726,
              45.708764
            ],
            [
              -123.361028,
              45.708696
            ],
            [
              -123.361622,
              45.779579
            ],
            [
              -123.48513,
              45.780021
            ],
            [
              -123.485086,
              45.775657
            ],
            [
              -123.720001,
              45.77308
            ],
            [
              -123.71984,
              45.783218
            ],
            [
              -123.96935386195901,
              45.783098
            ],
            [
              -123.969459,
              45.782371
            ],
            [
              -123.981864,
              45.768285
            ],
            [
              -123.982578,
              45.761815
            ],
            [
              -123.968563,
              45.757019
            ],
            [
              -123.946027,
              45.733249
            ],
            [
              -123.943121,
              45.727031
            ],
            [
              -123.939448,
              45.708795
            ],
            [
              -123.939005,
              45.661923
            ],
            [
              -123.951246,
              45.585775
            ],
            [
              -123.956711,
              45.571303
            ],
            [
              -123.94850194578201,
              45.565541866919695
            ],
            [
              -123.947556,
              45.564878
            ],
            [
              -123.957568,
              45.510399
            ],
            [
              -123.96634,
              45.493417
            ],
            [
              -123.970794,
              45.493507
            ],
            [
              -123.976544,
              45.489733
            ],
            [
              -123.972953,
              45.467513
            ],
            [
              -123.964074,
              45.449112
            ],
            [
              -123.960557,
              45.430778
            ],
            [
              -123.965728,
              45.386242
            ],
            [
              -123.973398,
              45.354791
            ],
            [
              -123.979715,
              45.347724
            ],
            [
              -124.007494,
              45.33974
            ],
            [
              -124.007756,
              45.336813
            ],
            [
              -123.978671,
              45.338854
            ],
            [
              -123.972899,
              45.33689
            ],
            [
              -123.964169,
              45.317026
            ],
            [
              -123.962887,
              45.280218
            ],
            [
              -123.972919,
              45.216784
            ],
            [
              -123.968187,
              45.201217
            ],
            [
              -123.975425,
              45.145476
            ],
            [
              -123.989529,
              45.094045
            ],
            [
              -124.004863,
              45.084232
            ],
            [
              -124.006057,
              45.084736
            ],
            [
              -124.012163,
              45.076921
            ],
            [
              -124.015851,
              45.064759
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "41",
      "COUNTYFP": "061",
      "COUNTYNS": "01164165",
      "AFFGEOID": "0500000US41061",
      "GEOID": "41061",
      "NAME": "Union",
      "LSAD": "06",
      "ALAND": 5275597066,
      "AWATER": 4989941,
      "County_state": "Union,41"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -118.697766,
              45.345647
            ],
            [
              -118.696985,
              45.257613
            ],
            [
              -118.656102,
              45.257729
            ],
            [
              -118.655992,
              45.196928
            ],
            [
              -118.546632,
              45.196898
            ],
            [
              -118.547652,
              45.080753
            ],
            [
              -118.518687,
              45.08069
            ],
            [
              -118.518689,
              44.995877
            ],
            [
              -118.468871,
              44.995176
            ],
            [
              -118.437351,
              44.984781
            ],
            [
              -118.4361,
              44.96242
            ],
            [
              -118.404334,
              44.976469
            ],
            [
              -118.3908,
              44.972107
            ],
            [
              -118.364214,
              44.990763
            ],
            [
              -118.34662,
              44.986988
            ],
            [
              -118.3191,
              44.97599
            ],
            [
              -118.299523,
              44.961029
            ],
            [
              -118.282246,
              44.964726
            ],
            [
              -118.276006,
              44.957896
            ],
            [
              -118.244662,
              44.958396
            ],
            [
              -118.213869,
              44.989174
            ],
            [
              -118.214497,
              45.006585
            ],
            [
              -118.194901,
              45.017329
            ],
            [
              -118.161036,
              45.015711
            ],
            [
              -118.142367,
              45.027725
            ],
            [
              -118.130351,
              45.045024
            ],
            [
              -118.09213,
              45.041238
            ],
            [
              -118.06955,
              45.016873
            ],
            [
              -118.037125,
              45.004007
            ],
            [
              -117.998992,
              44.994348
            ],
            [
              -117.969083,
              44.995831
            ],
            [
              -117.906142,
              45.030232
            ],
            [
              -117.887626,
              45.057412
            ],
            [
              -117.873734,
              45.065639
            ],
            [
              -117.862587,
              45.054395
            ],
            [
              -117.84343,
              45.058477
            ],
            [
              -117.818062,
              45.025909
            ],
            [
              -117.793418,
              45.025795
            ],
            [
              -117.781825,
              45.013583
            ],
            [
              -117.777504,
              44.992161
            ],
            [
              -117.564033,
              44.992866
            ],
            [
              -117.563486,
              45.079446
            ],
            [
              -117.266573,
              45.08057
            ],
            [
              -117.266006,
              45.166539
            ],
            [
              -117.476684,
              45.166539
            ],
            [
              -117.476762,
              45.209973
            ],
            [
              -117.520941,
              45.209768
            ],
            [
              -117.522249,
              45.267731
            ],
            [
              -117.542216,
              45.267424
            ],
            [
              -117.542058,
              45.295956
            ],
            [
              -117.561969,
              45.295879
            ],
            [
              -117.562208,
              45.310219
            ],
            [
              -117.582166,
              45.310184
            ],
            [
              -117.582344,
              45.338096
            ],
            [
              -117.643494,
              45.33687
            ],
            [
              -117.643485,
              45.365322
            ],
            [
              -117.663391,
              45.365749
            ],
            [
              -117.664146,
              45.428344
            ],
            [
              -117.68379,
              45.428365
            ],
            [
              -117.683986,
              45.51523
            ],
            [
              -117.727235,
              45.51478
            ],
            [
              -117.727225,
              45.61699
            ],
            [
              -117.745959,
              45.616635
            ],
            [
              -117.746137,
              45.660736
            ],
            [
              -117.767063,
              45.660227
            ],
            [
              -117.766865,
              45.689605
            ],
            [
              -117.787623,
              45.689567
            ],
            [
              -117.788395,
              45.773515
            ],
            [
              -117.74765,
              45.773625
            ],
            [
              -117.747327,
              45.861072
            ],
            [
              -117.97298,
              45.860595
            ],
            [
              -117.972948,
              45.816896
            ],
            [
              -118.045003,
              45.817073
            ],
            [
              -118.045101,
              45.773488
            ],
            [
              -118.066506,
              45.77346
            ],
            [
              -118.066383,
              45.688395
            ],
            [
              -118.117194,
              45.688607
            ],
            [
              -118.11836,
              45.514664
            ],
            [
              -118.116461,
              45.470732
            ],
            [
              -118.197955,
              45.4704
            ],
            [
              -118.197876,
              45.427204
            ],
            [
              -118.241665,
              45.428841
            ],
            [
              -118.366326,
              45.428951
            ],
            [
              -118.38422,
              45.44258
            ],
            [
              -118.404849,
              45.44255
            ],
            [
              -118.404806,
              45.429013
            ],
            [
              -118.428539,
              45.429076
            ],
            [
              -118.428329,
              45.35519
            ],
            [
              -118.613107,
              45.355162
            ],
            [
              -118.613243,
              45.339884
            ],
            [
              -118.656251,
              45.345146
            ],
            [
              -118.697766,
              45.345647
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "41",
      "COUNTYFP": "013",
      "COUNTYNS": "01155128",
      "AFFGEOID": "0500000US41013",
      "GEOID": "41013",
      "NAME": "Crook",
      "LSAD": "06",
      "ALAND": 7715298889,
      "AWATER": 21091929,
      "County_state": "Crook,41"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -121.108335,
              44.26204
            ],
            [
              -121.108537,
              44.218519
            ],
            [
              -121.102791,
              44.17139
            ],
            [
              -121.102773,
              44.138125
            ],
            [
              -120.987271,
              44.134026
            ],
            [
              -120.986587,
              43.960852
            ],
            [
              -120.748249,
              43.95731
            ],
            [
              -120.747848,
              43.871331
            ],
            [
              -120.37874,
              43.872362
            ],
            [
              -120.378107,
              43.785429
            ],
            [
              -120.258386,
              43.785297
            ],
            [
              -120.257777,
              43.698762
            ],
            [
              -120.217603,
              43.697601
            ],
            [
              -119.897434,
              43.6982
            ],
            [
              -119.777528,
              43.697957
            ],
            [
              -119.775402,
              43.959086
            ],
            [
              -119.657183,
              43.95892
            ],
            [
              -119.654993,
              44.220276
            ],
            [
              -119.655517,
              44.307045
            ],
            [
              -119.899002,
              44.30662
            ],
            [
              -119.899283,
              44.389575
            ],
            [
              -120.020708,
              44.389687
            ],
            [
              -120.021621,
              44.438793
            ],
            [
              -120.386074,
              44.436083
            ],
            [
              -120.386561,
              44.564009
            ],
            [
              -120.827555,
              44.562793
            ],
            [
              -120.826899,
              44.476499
            ],
            [
              -120.988824,
              44.476484
            ],
            [
              -120.988375,
              44.390265
            ],
            [
              -121.107534,
              44.3906
            ],
            [
              -121.108335,
              44.26204
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "41",
      "COUNTYFP": "059",
      "COUNTYNS": "01156673",
      "AFFGEOID": "0500000US41059",
      "GEOID": "41059",
      "NAME": "Umatilla",
      "LSAD": "06",
      "ALAND": 8327941915,
      "AWATER": 41647897,
      "County_state": "Umatilla,41"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -119.43478,
              45.80253
            ],
            [
              -119.43464,
              45.603056
            ],
            [
              -119.248547,
              45.601935
            ],
            [
              -119.248214,
              45.516076
            ],
            [
              -119.145144,
              45.515909
            ],
            [
              -119.146424,
              45.08294
            ],
            [
              -119.163037,
              45.082943
            ],
            [
              -119.162966,
              44.996241
            ],
            [
              -118.518689,
              44.995877
            ],
            [
              -118.518687,
              45.08069
            ],
            [
              -118.547652,
              45.080753
            ],
            [
              -118.546632,
              45.196898
            ],
            [
              -118.655992,
              45.196928
            ],
            [
              -118.656102,
              45.257729
            ],
            [
              -118.696985,
              45.257613
            ],
            [
              -118.697766,
              45.345647
            ],
            [
              -118.656251,
              45.345146
            ],
            [
              -118.613243,
              45.339884
            ],
            [
              -118.613107,
              45.355162
            ],
            [
              -118.428329,
              45.35519
            ],
            [
              -118.428539,
              45.429076
            ],
            [
              -118.404806,
              45.429013
            ],
            [
              -118.404849,
              45.44255
            ],
            [
              -118.38422,
              45.44258
            ],
            [
              -118.366326,
              45.428951
            ],
            [
              -118.241665,
              45.428841
            ],
            [
              -118.197876,
              45.427204
            ],
            [
              -118.197955,
              45.4704
            ],
            [
              -118.116461,
              45.470732
            ],
            [
              -118.11836,
              45.514664
            ],
            [
              -118.117194,
              45.688607
            ],
            [
              -118.066383,
              45.688395
            ],
            [
              -118.066506,
              45.77346
            ],
            [
              -118.045101,
              45.773488
            ],
            [
              -118.045003,
              45.817073
            ],
            [
              -117.972948,
              45.816896
            ],
            [
              -117.97298,
              45.860595
            ],
            [
              -117.977639,
              45.860602
            ],
            [
              -117.97766333505501,
              46.000723475442804
            ],
            [
              -117.996911,
              46.000787
            ],
            [
              -117.99694971271,
              46.000786848785495
            ],
            [
              -118.126197,
              46.000282
            ],
            [
              -118.131019,
              46.00028
            ],
            [
              -118.146028,
              46.000701
            ],
            [
              -118.228941,
              46.000421
            ],
            [
              -118.236584,
              46.000418
            ],
            [
              -118.25253,
              46.000459
            ],
            [
              -118.256368,
              46.000439
            ],
            [
              -118.283526,
              46.000787
            ],
            [
              -118.314982,
              46.000453
            ],
            [
              -118.36779,
              46.000622
            ],
            [
              -118.37836,
              46.000574
            ],
            [
              -118.403788052999,
              46.000589962023
            ],
            [
              -118.470756,
              46.000632
            ],
            [
              -118.497027,
              46.00062
            ],
            [
              -118.537119,
              46.00084
            ],
            [
              -118.569392,
              46.000773
            ],
            [
              -118.57571,
              46.000718
            ],
            [
              -118.579906,
              46.000818
            ],
            [
              -118.606789334728,
              46.000888673427006
            ],
            [
              -118.637725,
              46.00097
            ],
            [
              -118.639332,
              46.000994
            ],
            [
              -118.658717,
              46.000955
            ],
            [
              -118.67787,
              46.000935
            ],
            [
              -118.941242,
              46.000574
            ],
            [
              -118.987129,
              45.999855
            ],
            [
              -118.98719609037701,
              45.9997905520367
            ],
            [
              -119.008558,
              45.97927
            ],
            [
              -119.027056,
              45.969134
            ],
            [
              -119.061462,
              45.958527
            ],
            [
              -119.093221,
              45.942745
            ],
            [
              -119.12612,
              45.932859
            ],
            [
              -119.169496,
              45.927603
            ],
            [
              -119.19553,
              45.92787
            ],
            [
              -119.225745,
              45.932725
            ],
            [
              -119.25715,
              45.939926
            ],
            [
              -119.322509,
              45.933183
            ],
            [
              -119.364396,
              45.921605
            ],
            [
              -119.432149953782,
              45.918250445405
            ],
            [
              -119.43478,
              45.80253
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "41",
      "COUNTYFP": "055",
      "COUNTYNS": "01135863",
      "AFFGEOID": "0500000US41055",
      "GEOID": "41055",
      "NAME": "Sherman",
      "LSAD": "06",
      "ALAND": 2133151552,
      "AWATER": 19617470,
      "County_state": "Sherman,41"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -121.029163,
              45.287224
            ],
            [
              -121.027371,
              45.284449
            ],
            [
              -121.023718,
              45.285401
            ],
            [
              -121.017771,
              45.284738
            ],
            [
              -121.020653,
              45.279082
            ],
            [
              -121.023499,
              45.276922
            ],
            [
              -121.024347,
              45.273554
            ],
            [
              -121.021001,
              45.270975
            ],
            [
              -121.022217,
              45.264793
            ],
            [
              -121.023275,
              45.262307
            ],
            [
              -121.023,
              45.255072
            ],
            [
              -121.022753,
              45.250292
            ],
            [
              -121.022526,
              45.247037
            ],
            [
              -121.02243,
              45.243921
            ],
            [
              -121.023876,
              45.240795
            ],
            [
              -121.023793,
              45.238048
            ],
            [
              -121.022601,
              45.236756
            ],
            [
              -121.021491,
              45.234632
            ],
            [
              -121.023287,
              45.233169
            ],
            [
              -121.023056,
              45.231005
            ],
            [
              -121.021078,
              45.230008
            ],
            [
              -121.020228,
              45.227346
            ],
            [
              -121.022081,
              45.225704
            ],
            [
              -121.022872,
              45.223449
            ],
            [
              -121.022195,
              45.221295
            ],
            [
              -121.02411,
              45.218988
            ],
            [
              -121.020913,
              45.217879
            ],
            [
              -121.018928,
              45.217836
            ],
            [
              -121.018528,
              45.215777
            ],
            [
              -121.019459,
              45.214253
            ],
            [
              -121.016886,
              45.212805
            ],
            [
              -121.014831,
              45.213309
            ],
            [
              -121.013136,
              45.211524
            ],
            [
              -121.013298,
              45.209808
            ],
            [
              -121.010519,
              45.209867
            ],
            [
              -121.009465,
              45.210893
            ],
            [
              -121.006162,
              45.209935
            ],
            [
              -121.003543,
              45.20835
            ],
            [
              -121.000301,
              45.208867
            ],
            [
              -120.996981,
              45.206964
            ],
            [
              -120.995936,
              45.204497
            ],
            [
              -120.992893,
              45.203972
            ],
            [
              -120.989897,
              45.203485
            ],
            [
              -120.987792,
              45.200513
            ],
            [
              -120.987209,
              45.198807
            ],
            [
              -120.984066,
              45.198172
            ],
            [
              -120.983291,
              45.19911
            ],
            [
              -120.980047,
              45.199216
            ],
            [
              -120.975509,
              45.200353
            ],
            [
              -120.971202,
              45.199855
            ],
            [
              -120.967012,
              45.198759
            ],
            [
              -120.96352,
              45.19837
            ],
            [
              -120.960284,
              45.197114
            ],
            [
              -120.956211,
              45.196484
            ],
            [
              -120.954077,
              45.196205
            ],
            [
              -120.952551,
              45.197805
            ],
            [
              -120.953147,
              45.19931
            ],
            [
              -120.953745,
              45.201317
            ],
            [
              -120.951432,
              45.201987
            ],
            [
              -120.948197,
              45.200226
            ],
            [
              -120.945737,
              45.200237
            ],
            [
              -120.943319,
              45.20015
            ],
            [
              -120.943168,
              45.199398
            ],
            [
              -120.942687,
              45.198888
            ],
            [
              -120.939568,
              45.199612
            ],
            [
              -120.936655,
              45.200198
            ],
            [
              -120.933958,
              45.200764
            ],
            [
              -120.932887,
              45.202355
            ],
            [
              -120.930327,
              45.20197
            ],
            [
              -120.930172,
              45.20095
            ],
            [
              -120.927813,
              45.199825
            ],
            [
              -120.925206,
              45.200752
            ],
            [
              -120.922895,
              45.201946
            ],
            [
              -120.920782,
              45.202562
            ],
            [
              -120.919076,
              45.200982
            ],
            [
              -120.920768,
              45.199375
            ],
            [
              -120.918635,
              45.19792
            ],
            [
              -120.915066,
              45.19837
            ],
            [
              -120.91186,
              45.19985
            ],
            [
              -120.910022,
              45.199806
            ],
            [
              -120.907654,
              45.201132
            ],
            [
              -120.907421,
              45.20218
            ],
            [
              -120.904581,
              45.202914
            ],
            [
              -120.902253,
              45.202925
            ],
            [
              -120.896534,
              45.203169
            ],
            [
              -120.894991,
              45.201753
            ],
            [
              -120.89167,
              45.201318
            ],
            [
              -120.891088,
              45.201894
            ],
            [
              -120.888698,
              45.201756
            ],
            [
              -120.886294,
              45.200559
            ],
            [
              -120.883252,
              45.200624
            ],
            [
              -120.878381,
              45.19919
            ],
            [
              -120.872555,
              45.198421
            ],
            [
              -120.869608,
              45.198543
            ],
            [
              -120.86747,
              45.197599
            ],
            [
              -120.864761,
              45.195612
            ],
            [
              -120.861191,
              45.19493
            ],
            [
              -120.857513,
              45.194602
            ],
            [
              -120.856271,
              45.193292
            ],
            [
              -120.854742,
              45.192316
            ],
            [
              -120.852949,
              45.190654
            ],
            [
              -120.850853,
              45.189696
            ],
            [
              -120.850419,
              45.19002
            ],
            [
              -120.848278,
              45.189509
            ],
            [
              -120.847059,
              45.188182
            ],
            [
              -120.843645,
              45.18651
            ],
            [
              -120.840759,
              45.185589
            ],
            [
              -120.837965,
              45.183577
            ],
            [
              -120.836514,
              45.180779
            ],
            [
              -120.834458,
              45.180028
            ],
            [
              -120.831402,
              45.179058
            ],
            [
              -120.828826,
              45.176957
            ],
            [
              -120.825329,
              45.17682
            ],
            [
              -120.822901,
              45.17614
            ],
            [
              -120.819136,
              45.173989
            ],
            [
              -120.815804,
              45.172449
            ],
            [
              -120.811136,
              45.171237
            ],
            [
              -120.808748,
              45.169031
            ],
            [
              -120.807957,
              45.167995
            ],
            [
              -120.80591,
              45.167649
            ],
            [
              -120.80514,
              45.168203
            ],
            [
              -120.802598,
              45.167929
            ],
            [
              -120.80111,
              45.166162
            ],
            [
              -120.798117,
              45.165503
            ],
            [
              -120.79638,
              45.164609
            ],
            [
              -120.792883,
              45.16496
            ],
            [
              -120.78948,
              45.163094
            ],
            [
              -120.786773,
              45.163713
            ],
            [
              -120.782757,
              45.163405
            ],
            [
              -120.779361,
              45.163201
            ],
            [
              -120.775516,
              45.162333
            ],
            [
              -120.771213,
              45.162485
            ],
            [
              -120.768795,
              45.160065
            ],
            [
              -120.767556,
              45.159006
            ],
            [
              -120.768317,
              45.157141
            ],
            [
              -120.769379,
              45.156511
            ],
            [
              -120.768822,
              45.155194
            ],
            [
              -120.767133,
              45.15391
            ],
            [
              -120.765236,
              45.151912
            ],
            [
              -120.762927,
              45.149706
            ],
            [
              -120.760812,
              45.148482
            ],
            [
              -120.761853,
              45.14653
            ],
            [
              -120.76049,
              45.144335
            ],
            [
              -120.758607,
              45.1444
            ],
            [
              -120.755933,
              45.144179
            ],
            [
              -120.752368,
              45.14347
            ],
            [
              -120.74927,
              45.142267
            ],
            [
              -120.746475,
              45.139308
            ],
            [
              -120.743482,
              45.136284
            ],
            [
              -120.739017,
              45.134707
            ],
            [
              -120.73398,
              45.134643
            ],
            [
              -120.731196,
              45.13319
            ],
            [
              -120.727515,
              45.132358
            ],
            [
              -120.725135,
              45.131404
            ],
            [
              -120.723809,
              45.129951
            ],
            [
              -120.723932,
              45.128931
            ],
            [
              -120.72311,
              45.125223
            ],
            [
              -120.72235,
              45.12236
            ],
            [
              -120.720727,
              45.121021
            ],
            [
              -120.718645,
              45.119629
            ],
            [
              -120.718373,
              45.117704
            ],
            [
              -120.718728,
              45.115817
            ],
            [
              -120.718657,
              45.112866
            ],
            [
              -120.718324,
              45.111741
            ],
            [
              -120.7184,
              45.10998
            ],
            [
              -120.719484,
              45.108006
            ],
            [
              -120.71953,
              45.107084
            ],
            [
              -120.720642,
              45.106441
            ],
            [
              -120.721845,
              45.106288
            ],
            [
              -120.723595,
              45.104532
            ],
            [
              -120.725709,
              45.103023
            ],
            [
              -120.726853,
              45.100099
            ],
            [
              -120.728486,
              45.098086
            ],
            [
              -120.727269,
              45.096352
            ],
            [
              -120.727159,
              45.093774
            ],
            [
              -120.726569,
              45.092123
            ],
            [
              -120.726847,
              45.090587
            ],
            [
              -120.728806,
              45.089737
            ],
            [
              -120.729385,
              45.087378
            ],
            [
              -120.729888,
              45.086291
            ],
            [
              -120.729824,
              45.083872
            ],
            [
              -120.722685,
              45.083737
            ],
            [
              -120.682542,
              45.083682
            ],
            [
              -120.658898,
              45.083643
            ],
            [
              -120.62292,
              45.083586
            ],
            [
              -120.585721,
              45.083522
            ],
            [
              -120.555167,
              45.083472
            ],
            [
              -120.519707,
              45.083469
            ],
            [
              -120.503733,
              45.083477
            ],
            [
              -120.504049,
              45.08435
            ],
            [
              -120.508749,
              45.08745
            ],
            [
              -120.512649,
              45.09145
            ],
            [
              -120.51285,
              45.09765
            ],
            [
              -120.506864,
              45.102778
            ],
            [
              -120.50115,
              45.1041
            ],
            [
              -120.491949,
              45.10585
            ],
            [
              -120.480949,
              45.10785
            ],
            [
              -120.473749,
              45.11015
            ],
            [
              -120.468549,
              45.10595
            ],
            [
              -120.467448,
              45.10155
            ],
            [
              -120.463648,
              45.10375
            ],
            [
              -120.461249,
              45.10865
            ],
            [
              -120.460449,
              45.11705
            ],
            [
              -120.462409,
              45.12485
            ],
            [
              -120.46265,
              45.13445
            ],
            [
              -120.45345,
              45.13925
            ],
            [
              -120.45045,
              45.14435
            ],
            [
              -120.45565,
              45.14545
            ],
            [
              -120.46185,
              45.14435
            ],
            [
              -120.466995,
              45.146686
            ],
            [
              -120.471551,
              45.14905
            ],
            [
              -120.476751,
              45.15415
            ],
            [
              -120.472351,
              45.15905
            ],
            [
              -120.468951,
              45.16245
            ],
            [
              -120.475152,
              45.16755
            ],
            [
              -120.480252,
              45.16895
            ],
            [
              -120.480152,
              45.17235
            ],
            [
              -120.475252,
              45.17455
            ],
            [
              -120.477052,
              45.17995
            ],
            [
              -120.481352,
              45.17995
            ],
            [
              -120.486453,
              45.18415
            ],
            [
              -120.482953,
              45.18615
            ],
            [
              -120.480953,
              45.19125
            ],
            [
              -120.488024,
              45.197023
            ],
            [
              -120.497054,
              45.19645
            ],
            [
              -120.502554,
              45.19555
            ],
            [
              -120.507954,
              45.19705
            ],
            [
              -120.515254,
              45.19915
            ],
            [
              -120.520154,
              45.20165
            ],
            [
              -120.520555,
              45.20365
            ],
            [
              -120.522955,
              45.20495
            ],
            [
              -120.525855,
              45.20455
            ],
            [
              -120.530755,
              45.20565
            ],
            [
              -120.532255,
              45.20905
            ],
            [
              -120.533355,
              45.21105
            ],
            [
              -120.537055,
              45.21105
            ],
            [
              -120.538955,
              45.210449
            ],
            [
              -120.542155,
              45.211149
            ],
            [
              -120.542856,
              45.213549
            ],
            [
              -120.540856,
              45.216209
            ],
            [
              -120.535556,
              45.21745
            ],
            [
              -120.532255,
              45.21655
            ],
            [
              -120.526555,
              45.21745
            ],
            [
              -120.523855,
              45.22035
            ],
            [
              -120.525856,
              45.22375
            ],
            [
              -120.530856,
              45.22445
            ],
            [
              -120.536656,
              45.22185
            ],
            [
              -120.538556,
              45.220249
            ],
            [
              -120.542756,
              45.220649
            ],
            [
              -120.544856,
              45.222449
            ],
            [
              -120.544556,
              45.226149
            ],
            [
              -120.542356,
              45.230249
            ],
            [
              -120.542056,
              45.232949
            ],
            [
              -120.543557,
              45.235849
            ],
            [
              -120.547257,
              45.237749
            ],
            [
              -120.550357,
              45.240849
            ],
            [
              -120.550957,
              45.244549
            ],
            [
              -120.553457,
              45.247949
            ],
            [
              -120.555757,
              45.249949
            ],
            [
              -120.554157,
              45.252749
            ],
            [
              -120.550957,
              45.253349
            ],
            [
              -120.541357,
              45.253249
            ],
            [
              -120.535757,
              45.25495
            ],
            [
              -120.533757,
              45.25745
            ],
            [
              -120.534857,
              45.26095
            ],
            [
              -120.538957,
              45.262449
            ],
            [
              -120.542857,
              45.261649
            ],
            [
              -120.547057,
              45.262949
            ],
            [
              -120.547057,
              45.265249
            ],
            [
              -120.543857,
              45.270349
            ],
            [
              -120.540756,
              45.274033
            ],
            [
              -120.541758,
              45.279049
            ],
            [
              -120.546158,
              45.280649
            ],
            [
              -120.552858,
              45.279749
            ],
            [
              -120.552958,
              45.283349
            ],
            [
              -120.548358,
              45.284149
            ],
            [
              -120.545058,
              45.284449
            ],
            [
              -120.542758,
              45.288049
            ],
            [
              -120.544158,
              45.291049
            ],
            [
              -120.547458,
              45.296249
            ],
            [
              -120.546858,
              45.299849
            ],
            [
              -120.543758,
              45.303849
            ],
            [
              -120.540958,
              45.304449
            ],
            [
              -120.538258,
              45.301149
            ],
            [
              -120.537758,
              45.298049
            ],
            [
              -120.535558,
              45.295449
            ],
            [
              -120.529958,
              45.295349
            ],
            [
              -120.526357,
              45.29705
            ],
            [
              -120.524257,
              45.30085
            ],
            [
              -120.524958,
              45.305049
            ],
            [
              -120.529658,
              45.307949
            ],
            [
              -120.536558,
              45.309449
            ],
            [
              -120.540558,
              45.311149
            ],
            [
              -120.539558,
              45.315349
            ],
            [
              -120.534958,
              45.316649
            ],
            [
              -120.527758,
              45.320249
            ],
            [
              -120.527658,
              45.325449
            ],
            [
              -120.534158,
              45.328449
            ],
            [
              -120.537858,
              45.327849
            ],
            [
              -120.540858,
              45.329949
            ],
            [
              -120.538558,
              45.330849
            ],
            [
              -120.534958,
              45.331349
            ],
            [
              -120.530758,
              45.332949
            ],
            [
              -120.530858,
              45.335549
            ],
            [
              -120.530658,
              45.340349
            ],
            [
              -120.534359,
              45.344249
            ],
            [
              -120.539459,
              45.344949
            ],
            [
              -120.542759,
              45.347949
            ],
            [
              -120.539459,
              45.350249
            ],
            [
              -120.533859,
              45.352849
            ],
            [
              -120.533559,
              45.358449
            ],
            [
              -120.530859,
              45.364249
            ],
            [
              -120.532959,
              45.367049
            ],
            [
              -120.532659,
              45.371049
            ],
            [
              -120.528159,
              45.371849
            ],
            [
              -120.523659,
              45.374049
            ],
            [
              -120.519959,
              45.377149
            ],
            [
              -120.512758,
              45.377949
            ],
            [
              -120.517259,
              45.381349
            ],
            [
              -120.524259,
              45.381549
            ],
            [
              -120.527659,
              45.380849
            ],
            [
              -120.528159,
              45.386549
            ],
            [
              -120.522259,
              45.390149
            ],
            [
              -120.522559,
              45.397649
            ],
            [
              -120.527959,
              45.402749
            ],
            [
              -120.519859,
              45.405449
            ],
            [
              -120.514759,
              45.404349
            ],
            [
              -120.506259,
              45.407749
            ],
            [
              -120.504259,
              45.412749
            ],
            [
              -120.502659,
              45.417249
            ],
            [
              -120.494859,
              45.418349
            ],
            [
              -120.491658,
              45.417249
            ],
            [
              -120.485658,
              45.418449
            ],
            [
              -120.483158,
              45.420249
            ],
            [
              -120.485658,
              45.424249
            ],
            [
              -120.489058,
              45.426949
            ],
            [
              -120.500759,
              45.427949
            ],
            [
              -120.503759,
              45.427849
            ],
            [
              -120.504359,
              45.432449
            ],
            [
              -120.499759,
              45.434049
            ],
            [
              -120.490059,
              45.434349
            ],
            [
              -120.483358,
              45.436549
            ],
            [
              -120.485752,
              45.441474
            ],
            [
              -120.492159,
              45.443649
            ],
            [
              -120.495659,
              45.449649
            ],
            [
              -120.488959,
              45.453949
            ],
            [
              -120.483459,
              45.455349
            ],
            [
              -120.485659,
              45.461349
            ],
            [
              -120.490959,
              45.462249
            ],
            [
              -120.494559,
              45.463549
            ],
            [
              -120.491859,
              45.468149
            ],
            [
              -120.476659,
              45.473149
            ],
            [
              -120.470559,
              45.476749
            ],
            [
              -120.466152,
              45.477938
            ],
            [
              -120.458932,
              45.48121
            ],
            [
              -120.456017,
              45.4839
            ],
            [
              -120.447767,
              45.484456
            ],
            [
              -120.443688,
              45.480983
            ],
            [
              -120.435673,
              45.474062
            ],
            [
              -120.427187,
              45.47103
            ],
            [
              -120.421964,
              45.468362
            ],
            [
              -120.413802,
              45.469439
            ],
            [
              -120.407656,
              45.47495
            ],
            [
              -120.409856,
              45.47975
            ],
            [
              -120.421457,
              45.48295
            ],
            [
              -120.423557,
              45.49115
            ],
            [
              -120.426683,
              45.495296
            ],
            [
              -120.424556,
              45.49955
            ],
            [
              -120.420556,
              45.49985
            ],
            [
              -120.410557,
              45.49835
            ],
            [
              -120.398356,
              45.49695
            ],
            [
              -120.388256,
              45.49675
            ],
            [
              -120.380456,
              45.49395
            ],
            [
              -120.374955,
              45.49335
            ],
            [
              -120.372655,
              45.49395
            ],
            [
              -120.367155,
              45.49565
            ],
            [
              -120.364655,
              45.49795
            ],
            [
              -120.363892,
              45.499982
            ],
            [
              -120.362021,
              45.503318
            ],
            [
              -120.360138,
              45.506766
            ],
            [
              -120.361847,
              45.510945
            ],
            [
              -120.367013,
              45.515253
            ],
            [
              -120.373211,
              45.517136
            ],
            [
              -120.376304,
              45.518095
            ],
            [
              -120.380552,
              45.522706
            ],
            [
              -120.376156,
              45.52762
            ],
            [
              -120.372855,
              45.530061
            ],
            [
              -120.365972,
              45.531178
            ],
            [
              -120.361575,
              45.530524
            ],
            [
              -120.358185,
              45.53316
            ],
            [
              -120.360879,
              45.535785
            ],
            [
              -120.368936,
              45.537554
            ],
            [
              -120.37651,
              45.537527
            ],
            [
              -120.380033,
              45.538164
            ],
            [
              -120.383381,
              45.540374
            ],
            [
              -120.385843,
              45.544758
            ],
            [
              -120.388305,
              45.548723
            ],
            [
              -120.391839,
              45.550964
            ],
            [
              -120.396825,
              45.553009
            ],
            [
              -120.402107,
              45.555641
            ],
            [
              -120.407591,
              45.559367
            ],
            [
              -120.406099,
              45.56438
            ],
            [
              -120.402701,
              45.567956
            ],
            [
              -120.40536,
              45.576728
            ],
            [
              -120.408817,
              45.582563
            ],
            [
              -120.409233,
              45.590427
            ],
            [
              -120.410257,
              45.594812
            ],
            [
              -120.414231,
              45.596679
            ],
            [
              -120.421029,
              45.597548
            ],
            [
              -120.428886,
              45.597495
            ],
            [
              -120.435158,
              45.599947
            ],
            [
              -120.441686,
              45.605076
            ],
            [
              -120.445332,
              45.610564
            ],
            [
              -120.445185,
              45.617298
            ],
            [
              -120.440821,
              45.620771
            ],
            [
              -120.439493,
              45.624242
            ],
            [
              -120.439985,
              45.625561
            ],
            [
              -120.44422,
              45.627031
            ],
            [
              -120.450212,
              45.626427
            ],
            [
              -120.45931,
              45.621619
            ],
            [
              -120.464725,
              45.619673
            ],
            [
              -120.469588,
              45.621283
            ],
            [
              -120.470075,
              45.624015
            ],
            [
              -120.472435,
              45.626281
            ],
            [
              -120.476979,
              45.627468
            ],
            [
              -120.478343,
              45.631586
            ],
            [
              -120.481951,
              45.634339
            ],
            [
              -120.484468,
              45.634892
            ],
            [
              -120.485326,
              45.638269
            ],
            [
              -120.482367,
              45.639391
            ],
            [
              -120.478435,
              45.641049
            ],
            [
              -120.4789,
              45.64333
            ],
            [
              -120.481185,
              45.645134
            ],
            [
              -120.487183,
              45.644558
            ],
            [
              -120.489585,
              45.643312
            ],
            [
              -120.491518,
              45.645424
            ],
            [
              -120.492099,
              45.648191
            ],
            [
              -120.490801,
              45.651087
            ],
            [
              -120.489248,
              45.653134
            ],
            [
              -120.490482,
              45.65582
            ],
            [
              -120.493853,
              45.656147
            ],
            [
              -120.499047,
              45.656058
            ],
            [
              -120.502032,
              45.657906
            ],
            [
              -120.505078,
              45.660585
            ],
            [
              -120.506388,
              45.661701
            ],
            [
              -120.508224,
              45.663991
            ],
            [
              -120.52001,
              45.667251
            ],
            [
              -120.532162,
              45.671927
            ],
            [
              -120.540418,
              45.670741
            ],
            [
              -120.549373,
              45.670457
            ],
            [
              -120.551664,
              45.675048
            ],
            [
              -120.560065,
              45.682348
            ],
            [
              -120.567365,
              45.690148
            ],
            [
              -120.576165,
              45.697948
            ],
            [
              -120.591166,
              45.701548
            ],
            [
              -120.603466,
              45.706647
            ],
            [
              -120.610467,
              45.716247
            ],
            [
              -120.623267,
              45.721947
            ],
            [
              -120.632968,
              45.720846
            ],
            [
              -120.646068,
              45.723946
            ],
            [
              -120.649168,
              45.731087
            ],
            [
              -120.650208,
              45.733806
            ],
            [
              -120.65353434410001,
              45.7372486812962
            ],
            [
              -120.668869,
              45.730147
            ],
            [
              -120.68937,
              45.715847
            ],
            [
              -120.724171,
              45.706446
            ],
            [
              -120.788872,
              45.686246
            ],
            [
              -120.855674,
              45.671545
            ],
            [
              -120.870042,
              45.661242
            ],
            [
              -120.895575,
              45.642945
            ],
            [
              -120.913476,
              45.640045
            ],
            [
              -120.915795173154,
              45.641301218792
            ],
            [
              -120.915459,
              45.639026
            ],
            [
              -120.914386,
              45.634977
            ],
            [
              -120.911877,
              45.632036
            ],
            [
              -120.907274,
              45.62645
            ],
            [
              -120.906035,
              45.624845
            ],
            [
              -120.904599,
              45.621031
            ],
            [
              -120.904175,
              45.616445
            ],
            [
              -120.907465,
              45.612355
            ],
            [
              -120.911675,
              45.607845
            ],
            [
              -120.910379,
              45.603845
            ],
            [
              -120.905627,
              45.600165
            ],
            [
              -120.901854,
              45.598778
            ],
            [
              -120.897304,
              45.593915
            ],
            [
              -120.89543,
              45.589505
            ],
            [
              -120.897365,
              45.585086
            ],
            [
              -120.901532,
              45.580599
            ],
            [
              -120.904815,
              45.576068
            ],
            [
              -120.905922,
              45.571093
            ],
            [
              -120.905928,
              45.568142
            ],
            [
              -120.90702,
              45.565364
            ],
            [
              -120.908824,
              45.562736
            ],
            [
              -120.908487,
              45.558303
            ],
            [
              -120.905093,
              45.555173
            ],
            [
              -120.900639,
              45.550905
            ],
            [
              -120.898972,
              45.546652
            ],
            [
              -120.896342,
              45.542455
            ],
            [
              -120.891407,
              45.541878
            ],
            [
              -120.884644,
              45.538782
            ],
            [
              -120.884777,
              45.531695
            ],
            [
              -120.878759,
              45.526877
            ],
            [
              -120.87519,
              45.526947
            ],
            [
              -120.868837,
              45.52858
            ],
            [
              -120.860273,
              45.526546
            ],
            [
              -120.854599,
              45.523536
            ],
            [
              -120.852642,
              45.521482
            ],
            [
              -120.845393,
              45.518713
            ],
            [
              -120.841912,
              45.517516
            ],
            [
              -120.837972,
              45.515746
            ],
            [
              -120.833333,
              45.515789
            ],
            [
              -120.830274,
              45.51565
            ],
            [
              -120.825738,
              45.514087
            ],
            [
              -120.827465,
              45.510653
            ],
            [
              -120.832885,
              45.508343
            ],
            [
              -120.836683,
              45.506242
            ],
            [
              -120.838681,
              45.501542
            ],
            [
              -120.83568,
              45.498544
            ],
            [
              -120.83362,
              45.492756
            ],
            [
              -120.836574,
              45.487846
            ],
            [
              -120.839171,
              45.482312
            ],
            [
              -120.838871,
              45.478946
            ],
            [
              -120.835571,
              45.471246
            ],
            [
              -120.838771,
              45.466946
            ],
            [
              -120.844471,
              45.463546
            ],
            [
              -120.849131,
              45.461901
            ],
            [
              -120.858372,
              45.460746
            ],
            [
              -120.861345,
              45.455053
            ],
            [
              -120.862576,
              45.44884
            ],
            [
              -120.865471,
              45.441946
            ],
            [
              -120.858519,
              45.435356
            ],
            [
              -120.852571,
              45.433845
            ],
            [
              -120.84947,
              45.429745
            ],
            [
              -120.851553,
              45.427464
            ],
            [
              -120.856671,
              45.427446
            ],
            [
              -120.85917,
              45.428645
            ],
            [
              -120.86287,
              45.426246
            ],
            [
              -120.86157,
              45.422046
            ],
            [
              -120.86427,
              45.417346
            ],
            [
              -120.86657,
              45.412746
            ],
            [
              -120.86691,
              45.407255
            ],
            [
              -120.868269,
              45.402646
            ],
            [
              -120.867783,
              45.396893
            ],
            [
              -120.866014,
              45.39322
            ],
            [
              -120.865836,
              45.391079
            ],
            [
              -120.868566,
              45.38842
            ],
            [
              -120.873202,
              45.388724
            ],
            [
              -120.877522,
              45.391687
            ],
            [
              -120.883088,
              45.389819
            ],
            [
              -120.884388,
              45.386657
            ],
            [
              -120.89284,
              45.379632
            ],
            [
              -120.898471,
              45.373345
            ],
            [
              -120.899771,
              45.368345
            ],
            [
              -120.900171,
              45.362745
            ],
            [
              -120.909271,
              45.362145
            ],
            [
              -120.914371,
              45.362645
            ],
            [
              -120.920571,
              45.357944
            ],
            [
              -120.920071,
              45.354645
            ],
            [
              -120.920198,
              45.350336
            ],
            [
              -120.930471,
              45.347745
            ],
            [
              -120.936472,
              45.347345
            ],
            [
              -120.938067,
              45.341245
            ],
            [
              -120.934255,
              45.339031
            ],
            [
              -120.932269,
              45.335143
            ],
            [
              -120.93708,
              45.331072
            ],
            [
              -120.940771,
              45.332645
            ],
            [
              -120.946372,
              45.336745
            ],
            [
              -120.951298,
              45.332695
            ],
            [
              -120.948172,
              45.331745
            ],
            [
              -120.9459,
              45.327986
            ],
            [
              -120.946612,
              45.324258
            ],
            [
              -120.952688,
              45.318313
            ],
            [
              -120.957366,
              45.309501
            ],
            [
              -120.962148,
              45.307272
            ],
            [
              -120.971195,
              45.308182
            ],
            [
              -120.974381,
              45.312945
            ],
            [
              -120.973839,
              45.316456
            ],
            [
              -120.973278,
              45.317835
            ],
            [
              -120.97499,
              45.320758
            ],
            [
              -120.978491,
              45.322265
            ],
            [
              -120.984519,
              45.320879
            ],
            [
              -120.988287,
              45.313725
            ],
            [
              -120.990473,
              45.309344
            ],
            [
              -120.998269,
              45.306727
            ],
            [
              -121.00293,
              45.30871
            ],
            [
              -121.011801,
              45.308785
            ],
            [
              -121.018588,
              45.301504
            ],
            [
              -121.022556,
              45.291386
            ],
            [
              -121.025574,
              45.289233
            ],
            [
              -121.029163,
              45.287224
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "41",
      "COUNTYFP": "029",
      "COUNTYNS": "01135853",
      "AFFGEOID": "0500000US41029",
      "GEOID": "41029",
      "NAME": "Jackson",
      "LSAD": "06",
      "ALAND": 7208499985,
      "AWATER": 46860048,
      "County_state": "Jackson,41"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -123.23110845560201,
              42.00383094897101
            ],
            [
              -123.230762,
              42.003845
            ],
            [
              -123.192361,
              42.005446
            ],
            [
              -123.154908,
              42.008036
            ],
            [
              -123.145959,
              42.009247
            ],
            [
              -123.083956,
              42.005448
            ],
            [
              -123.065655,
              42.004948
            ],
            [
              -123.045254,
              42.003049
            ],
            [
              -122.941597,
              42.003085
            ],
            [
              -122.893961,
              42.002605
            ],
            [
              -122.876148,
              42.003247
            ],
            [
              -122.80008,
              42.004071
            ],
            [
              -122.712942,
              42.004157
            ],
            [
              -122.634739,
              42.004858
            ],
            [
              -122.501135,
              42.00846
            ],
            [
              -122.397984,
              42.008758
            ],
            [
              -122.378193,
              42.009518
            ],
            [
              -122.289754518188,
              42.0077685007884
            ],
            [
              -122.289972,
              42.127156
            ],
            [
              -122.287177,
              42.288838
            ],
            [
              -122.287686,
              42.395348
            ],
            [
              -122.28776,
              42.440287
            ],
            [
              -122.284689,
              42.4745
            ],
            [
              -122.282682,
              42.794271
            ],
            [
              -122.28273,
              42.996499
            ],
            [
              -122.401025,
              42.996632
            ],
            [
              -122.401077,
              42.983458
            ],
            [
              -122.420844,
              42.983439
            ],
            [
              -122.420863,
              42.968927
            ],
            [
              -122.440535,
              42.968923
            ],
            [
              -122.440505,
              42.95442
            ],
            [
              -122.46021,
              42.954373
            ],
            [
              -122.460224,
              42.939873
            ],
            [
              -122.560273,
              42.939185
            ],
            [
              -122.560299,
              42.92469
            ],
            [
              -122.580049,
              42.924651
            ],
            [
              -122.580094,
              42.9108
            ],
            [
              -122.600202,
              42.911073
            ],
            [
              -122.600251,
              42.895693
            ],
            [
              -122.637812,
              42.89699
            ],
            [
              -122.637695,
              42.881141
            ],
            [
              -122.677181,
              42.88108
            ],
            [
              -122.677198,
              42.866603
            ],
            [
              -122.697017,
              42.866572
            ],
            [
              -122.69701,
              42.852099
            ],
            [
              -122.716657,
              42.85211
            ],
            [
              -122.716699,
              42.837671
            ],
            [
              -122.736329,
              42.837661
            ],
            [
              -122.736367,
              42.823496
            ],
            [
              -122.775223,
              42.8288
            ],
            [
              -122.775044,
              42.806831
            ],
            [
              -122.794811,
              42.792721
            ],
            [
              -122.794776,
              42.778221
            ],
            [
              -122.852943,
              42.774603
            ],
            [
              -122.911925,
              42.774704
            ],
            [
              -122.951199,
              42.774773
            ],
            [
              -122.95119,
              42.767555
            ],
            [
              -122.970829,
              42.768116
            ],
            [
              -122.990728,
              42.759538
            ],
            [
              -123.009765,
              42.752144
            ],
            [
              -123.112688,
              42.752218
            ],
            [
              -123.152233,
              42.755835
            ],
            [
              -123.152023,
              42.737721
            ],
            [
              -123.180512,
              42.737765
            ],
            [
              -123.210392,
              42.709983
            ],
            [
              -123.229619,
              42.70261
            ],
            [
              -123.229676,
              42.551766
            ],
            [
              -123.229545,
              42.495623
            ],
            [
              -123.229898,
              42.296345
            ],
            [
              -123.229901,
              42.290816
            ],
            [
              -123.229864,
              42.251647
            ],
            [
              -123.229167,
              42.128331
            ],
            [
              -123.23110845560201,
              42.00383094897101
            ]
          ]
        ]
      ]
    }
  }
]
};