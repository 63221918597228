export const counties_ga = {
  "type":"FeatureCollection","name":"ga_counties","features":[
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "143",
      "COUNTYNS": "00350637",
      "AFFGEOID": "0500000US13143",
      "GEOID": "13143",
      "NAME": "Haralson",
      "LSAD": "06",
      "ALAND": 730804590,
      "AWATER": 2616530,
      "County_state": "Haralson,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.38651002496731,
              33.901718559000095
            ],
            [
              -85.377464,
              33.856046
            ],
            [
              -85.37643978043509,
              33.8506487133064
            ],
            [
              -85.361885,
              33.77395
            ],
            [
              -85.360532,
              33.767957
            ],
            [
              -85.357443,
              33.750103
            ],
            [
              -85.33824506153,
              33.65311401873009
            ],
            [
              -85.289742,
              33.652087
            ],
            [
              -85.139027,
              33.700017
            ],
            [
              -85.129688,
              33.691423
            ],
            [
              -85.106786,
              33.694611
            ],
            [
              -85.050889,
              33.714632
            ],
            [
              -85.049591,
              33.77996
            ],
            [
              -85.049031,
              33.812322
            ],
            [
              -85.037926,
              33.811942
            ],
            [
              -85.037674,
              33.829027
            ],
            [
              -85.036684,
              33.904327
            ],
            [
              -85.05031,
              33.904488
            ],
            [
              -85.212774,
              33.906302
            ],
            [
              -85.212791,
              33.899212
            ],
            [
              -85.38651002496731,
              33.901718559000095
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "023",
      "COUNTYNS": "00347451",
      "AFFGEOID": "0500000US13023",
      "GEOID": "13023",
      "NAME": "Bleckley",
      "LSAD": "06",
      "ALAND": 559100209,
      "AWATER": 8447343,
      "County_state": "Bleckley,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.484136,
              32.405767
            ],
            [
              -83.498039,
              32.401715
            ],
            [
              -83.367756,
              32.288928
            ],
            [
              -83.346528,
              32.272489
            ],
            [
              -83.346103,
              32.283818
            ],
            [
              -83.332224,
              32.284033
            ],
            [
              -83.283958,
              32.324311
            ],
            [
              -83.30475,
              32.341958
            ],
            [
              -83.173252,
              32.452533
            ],
            [
              -83.138991,
              32.423069
            ],
            [
              -83.179528,
              32.497878
            ],
            [
              -83.226536,
              32.584201
            ],
            [
              -83.400041,
              32.500157
            ],
            [
              -83.412055,
              32.502091
            ],
            [
              -83.408844,
              32.495713
            ],
            [
              -83.497923,
              32.452198
            ],
            [
              -83.482648,
              32.424536
            ],
            [
              -83.484136,
              32.405767
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "261",
      "COUNTYNS": "00343504",
      "AFFGEOID": "0500000US13261",
      "GEOID": "13261",
      "NAME": "Sumter",
      "LSAD": "06",
      "ALAND": 1250167780,
      "AWATER": 25799665,
      "County_state": "Sumter,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.443802,
              31.968982
            ],
            [
              -84.443807,
              31.967456
            ],
            [
              -84.440358,
              31.959617
            ],
            [
              -84.383367,
              31.916938
            ],
            [
              -84.357615,
              31.885156
            ],
            [
              -84.338245,
              31.873591
            ],
            [
              -84.338143,
              31.91619
            ],
            [
              -84.191135,
              31.914909
            ],
            [
              -83.935679,
              31.91184
            ],
            [
              -83.922494,
              31.909653
            ],
            [
              -83.919119,
              31.923092
            ],
            [
              -83.924902,
              31.947725
            ],
            [
              -83.950415,
              31.997197
            ],
            [
              -83.950304,
              32.015378
            ],
            [
              -83.961278,
              32.030595
            ],
            [
              -83.969194,
              32.03855
            ],
            [
              -83.980391,
              32.044908
            ],
            [
              -83.986737,
              32.070687
            ],
            [
              -83.975052,
              32.092379
            ],
            [
              -83.990165,
              32.095442
            ],
            [
              -84.033411,
              32.153216
            ],
            [
              -84.027853,
              32.171083
            ],
            [
              -84.049205,
              32.185062
            ],
            [
              -84.084805,
              32.191262
            ],
            [
              -84.134736,
              32.185392
            ],
            [
              -84.134095,
              32.227972
            ],
            [
              -84.181657,
              32.229703
            ],
            [
              -84.183569,
              32.158493
            ],
            [
              -84.218959,
              32.15929
            ],
            [
              -84.260411,
              32.166061
            ],
            [
              -84.279911,
              32.16376
            ],
            [
              -84.430218,
              32.166257
            ],
            [
              -84.431214,
              32.134058
            ],
            [
              -84.433014,
              32.04196
            ],
            [
              -84.443802,
              31.968982
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "199",
      "COUNTYNS": "00346892",
      "AFFGEOID": "0500000US13199",
      "GEOID": "13199",
      "NAME": "Meriwether",
      "LSAD": "06",
      "ALAND": 1298162528,
      "AWATER": 10832504,
      "County_state": "Meriwether,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.864107,
              32.995612
            ],
            [
              -84.864465,
              32.970143
            ],
            [
              -84.861531,
              32.96081
            ],
            [
              -84.861768,
              32.872495
            ],
            [
              -84.84759,
              32.86837
            ],
            [
              -84.751338,
              32.8692
            ],
            [
              -84.736906,
              32.857872
            ],
            [
              -84.700538,
              32.84464
            ],
            [
              -84.570669,
              32.845179
            ],
            [
              -84.506888,
              32.881788
            ],
            [
              -84.508171,
              32.903366
            ],
            [
              -84.526614,
              32.913477
            ],
            [
              -84.518751,
              32.948301
            ],
            [
              -84.53257,
              32.968975
            ],
            [
              -84.52702,
              32.970548
            ],
            [
              -84.527153,
              32.999785
            ],
            [
              -84.536832,
              33.009359
            ],
            [
              -84.536991,
              33.03673
            ],
            [
              -84.5276,
              33.044156
            ],
            [
              -84.52515,
              33.078463
            ],
            [
              -84.51148,
              33.086016
            ],
            [
              -84.518188,
              33.122155
            ],
            [
              -84.511315,
              33.125914
            ],
            [
              -84.515351,
              33.147881
            ],
            [
              -84.507397,
              33.175269
            ],
            [
              -84.496783,
              33.183866
            ],
            [
              -84.490539,
              33.197586
            ],
            [
              -84.502352,
              33.221055
            ],
            [
              -84.621777,
              33.222149
            ],
            [
              -84.651461,
              33.229481
            ],
            [
              -84.674613,
              33.222735
            ],
            [
              -84.795278,
              33.223346
            ],
            [
              -84.85236,
              33.22359
            ],
            [
              -84.862359,
              33.191173
            ],
            [
              -84.864107,
              32.995612
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "097",
      "COUNTYNS": "01686467",
      "AFFGEOID": "0500000US13097",
      "GEOID": "13097",
      "NAME": "Douglas",
      "LSAD": "06",
      "ALAND": 518261551,
      "AWATER": 2517004,
      "County_state": "Douglas,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.902331,
              33.730599
            ],
            [
              -84.910852,
              33.730976
            ],
            [
              -84.901563,
              33.721991
            ],
            [
              -84.902741,
              33.649999
            ],
            [
              -84.903607,
              33.638831
            ],
            [
              -84.905788,
              33.573378
            ],
            [
              -84.886858,
              33.573423
            ],
            [
              -84.830042,
              33.573774
            ],
            [
              -84.821429,
              33.573608
            ],
            [
              -84.808934,
              33.574085
            ],
            [
              -84.805655,
              33.58642
            ],
            [
              -84.783292,
              33.603572
            ],
            [
              -84.763097,
              33.614211
            ],
            [
              -84.752735,
              33.63021
            ],
            [
              -84.741775,
              33.623763
            ],
            [
              -84.735712,
              33.624613
            ],
            [
              -84.730034,
              33.63651
            ],
            [
              -84.677133,
              33.655409
            ],
            [
              -84.661433,
              33.657009
            ],
            [
              -84.654607,
              33.662858
            ],
            [
              -84.652733,
              33.683609
            ],
            [
              -84.630117,
              33.693116
            ],
            [
              -84.632649,
              33.699286
            ],
            [
              -84.605188,
              33.717094
            ],
            [
              -84.585432,
              33.730507
            ],
            [
              -84.578132,
              33.743507
            ],
            [
              -84.581135,
              33.749765
            ],
            [
              -84.619988,
              33.801547
            ],
            [
              -84.623087,
              33.804786
            ],
            [
              -84.659771,
              33.805406
            ],
            [
              -84.693982,
              33.805706
            ],
            [
              -84.71493,
              33.806264
            ],
            [
              -84.724139,
              33.80617
            ],
            [
              -84.724119,
              33.788604
            ],
            [
              -84.730974,
              33.788568
            ],
            [
              -84.741444,
              33.787719
            ],
            [
              -84.767494,
              33.786895
            ],
            [
              -84.793599,
              33.783241
            ],
            [
              -84.799853,
              33.779909
            ],
            [
              -84.825604,
              33.778822
            ],
            [
              -84.832708,
              33.776019
            ],
            [
              -84.879151,
              33.774758
            ],
            [
              -84.901688,
              33.780703
            ],
            [
              -84.902331,
              33.730599
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "077",
      "COUNTYNS": "00326666",
      "AFFGEOID": "0500000US13077",
      "GEOID": "13077",
      "NAME": "Coweta",
      "LSAD": "06",
      "ALAND": 1141973686,
      "AWATER": 12727950,
      "County_state": "Coweta,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.986542,
              33.441287
            ],
            [
              -85.015358,
              33.425506
            ],
            [
              -84.958646,
              33.277053
            ],
            [
              -84.956894,
              33.282353
            ],
            [
              -84.948815,
              33.282277
            ],
            [
              -84.954578,
              33.265608
            ],
            [
              -84.939015,
              33.224693
            ],
            [
              -84.93226,
              33.224623
            ],
            [
              -84.862135,
              33.223858
            ],
            [
              -84.871954,
              33.207433
            ],
            [
              -84.862359,
              33.191173
            ],
            [
              -84.85236,
              33.22359
            ],
            [
              -84.795278,
              33.223346
            ],
            [
              -84.674613,
              33.222735
            ],
            [
              -84.651461,
              33.229481
            ],
            [
              -84.621777,
              33.222149
            ],
            [
              -84.502352,
              33.221055
            ],
            [
              -84.507951,
              33.248617
            ],
            [
              -84.497527,
              33.257422
            ],
            [
              -84.517416,
              33.293036
            ],
            [
              -84.518243,
              33.318331
            ],
            [
              -84.534371,
              33.335424
            ],
            [
              -84.564903,
              33.344651
            ],
            [
              -84.586047,
              33.357885
            ],
            [
              -84.592758,
              33.379505
            ],
            [
              -84.609683,
              33.3971
            ],
            [
              -84.613944,
              33.402954
            ],
            [
              -84.62614,
              33.439037
            ],
            [
              -84.625609,
              33.476795
            ],
            [
              -84.613508,
              33.492461
            ],
            [
              -84.60954,
              33.502511
            ],
            [
              -84.621031,
              33.510713
            ],
            [
              -84.721003,
              33.510895
            ],
            [
              -84.730537,
              33.51079
            ],
            [
              -84.850713,
              33.511457
            ],
            [
              -84.858345,
              33.493886
            ],
            [
              -84.878874,
              33.490586
            ],
            [
              -84.908788,
              33.469191
            ],
            [
              -84.921762,
              33.445011
            ],
            [
              -84.937426,
              33.436753
            ],
            [
              -84.973902,
              33.452679
            ],
            [
              -84.984767,
              33.457177
            ],
            [
              -84.986542,
              33.441287
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "059",
      "COUNTYNS": "01672699",
      "AFFGEOID": "0500000US13059",
      "GEOID": "13059",
      "NAME": "Clarke",
      "LSAD": "06",
      "ALAND": 308764509,
      "AWATER": 4715185,
      "County_state": "Clarke,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.537385,
              33.965912
            ],
            [
              -83.508452,
              33.931233
            ],
            [
              -83.491684,
              33.932453
            ],
            [
              -83.435415,
              33.922732
            ],
            [
              -83.421933,
              33.927055
            ],
            [
              -83.40514,
              33.913413
            ],
            [
              -83.382472,
              33.898387
            ],
            [
              -83.377696,
              33.890764
            ],
            [
              -83.376712,
              33.890433
            ],
            [
              -83.360973,
              33.87653
            ],
            [
              -83.32753,
              33.8689
            ],
            [
              -83.325197,
              33.854971
            ],
            [
              -83.275933,
              33.847977
            ],
            [
              -83.243254,
              33.900532
            ],
            [
              -83.24086,
              33.90443
            ],
            [
              -83.275415,
              33.942205
            ],
            [
              -83.278645,
              33.951745
            ],
            [
              -83.266225,
              33.970321
            ],
            [
              -83.268992,
              34.000604
            ],
            [
              -83.258413,
              33.999098
            ],
            [
              -83.263454,
              34.006327
            ],
            [
              -83.294327,
              34.00808
            ],
            [
              -83.320241,
              34.026023
            ],
            [
              -83.360028,
              34.040572
            ],
            [
              -83.384863,
              34.037805
            ],
            [
              -83.384847,
              34.029734
            ],
            [
              -83.454025,
              34.004809
            ],
            [
              -83.484201,
              33.992762
            ],
            [
              -83.503054,
              33.999577
            ],
            [
              -83.498243,
              33.987529
            ],
            [
              -83.537385,
              33.965912
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "015",
      "COUNTYNS": "00351604",
      "AFFGEOID": "0500000US13015",
      "GEOID": "13015",
      "NAME": "Bartow",
      "LSAD": "06",
      "ALAND": 1190154332,
      "AWATER": 27368202,
      "County_state": "Bartow,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.046871,
              34.096412
            ],
            [
              -85.047046,
              34.082881
            ],
            [
              -84.922742,
              34.082497
            ],
            [
              -84.896841,
              34.082498
            ],
            [
              -84.859065,
              34.082572
            ],
            [
              -84.740727,
              34.08236
            ],
            [
              -84.737836,
              34.079399
            ],
            [
              -84.702313,
              34.078794
            ],
            [
              -84.659241,
              34.07824
            ],
            [
              -84.657717,
              34.202675
            ],
            [
              -84.653744,
              34.227528
            ],
            [
              -84.653998,
              34.346934
            ],
            [
              -84.644293,
              34.355168
            ],
            [
              -84.654038,
              34.355176
            ],
            [
              -84.653232,
              34.41259
            ],
            [
              -84.733878,
              34.412968
            ],
            [
              -84.734118,
              34.396495
            ],
            [
              -84.798977,
              34.397345
            ],
            [
              -84.837061,
              34.397721
            ],
            [
              -84.862863,
              34.396601
            ],
            [
              -84.952546,
              34.398955
            ],
            [
              -84.953743,
              34.38419
            ],
            [
              -84.979632,
              34.397631
            ],
            [
              -84.980054,
              34.384772
            ],
            [
              -84.992507,
              34.392661
            ],
            [
              -85.005775,
              34.392446
            ],
            [
              -85.005235,
              34.34708
            ],
            [
              -85.023102,
              34.347112
            ],
            [
              -85.023295,
              34.316932
            ],
            [
              -85.023891,
              34.258289
            ],
            [
              -85.015606,
              34.251131
            ],
            [
              -85.023947,
              34.251201
            ],
            [
              -85.023813,
              34.213385
            ],
            [
              -85.044658,
              34.213622
            ],
            [
              -85.045282,
              34.167485
            ],
            [
              -85.046871,
              34.096412
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "087",
      "COUNTYNS": "00352234",
      "AFFGEOID": "0500000US13087",
      "GEOID": "13087",
      "NAME": "Decatur",
      "LSAD": "06",
      "ALAND": 1546667779,
      "AWATER": 67329727,
      "County_state": "Decatur,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.875546,
              30.727481
            ],
            [
              -84.869877,
              30.721887
            ],
            [
              -84.864693,
              30.711542
            ],
            [
              -84.86346069871338,
              30.7115052207476
            ],
            [
              -84.836448,
              30.710699
            ],
            [
              -84.644932,
              30.701982
            ],
            [
              -84.606501,
              30.699855
            ],
            [
              -84.606364,
              30.699862
            ],
            [
              -84.539483,
              30.696765
            ],
            [
              -84.535155,
              30.696513
            ],
            [
              -84.474519,
              30.692783
            ],
            [
              -84.3807060980397,
              30.689956554824498
            ],
            [
              -84.37594,
              31.00439
            ],
            [
              -84.376612,
              31.078883
            ],
            [
              -84.508078,
              31.0784
            ],
            [
              -84.542653,
              31.079029
            ],
            [
              -84.644678,
              31.079504
            ],
            [
              -84.645793,
              31.06801
            ],
            [
              -84.730855,
              31.06919
            ],
            [
              -84.731619,
              31.050087
            ],
            [
              -84.752947,
              31.041212
            ],
            [
              -84.75575,
              30.894928
            ],
            [
              -84.769519,
              30.876393
            ],
            [
              -84.76892,
              30.837089
            ],
            [
              -84.78532,
              30.823589
            ],
            [
              -84.78692,
              30.801189
            ],
            [
              -84.79902,
              30.801989
            ],
            [
              -84.81392,
              30.78139
            ],
            [
              -84.849921,
              30.77219
            ],
            [
              -84.861121,
              30.76199
            ],
            [
              -84.875546,
              30.727481
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "169",
      "COUNTYNS": "00345367",
      "AFFGEOID": "0500000US13169",
      "GEOID": "13169",
      "NAME": "Jones",
      "LSAD": "06",
      "ALAND": 1020296386,
      "AWATER": 3827965,
      "County_state": "Jones,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.816048,
              33.131816
            ],
            [
              -83.816845,
              33.126463
            ],
            [
              -83.799014,
              33.116578
            ],
            [
              -83.794708,
              33.104086
            ],
            [
              -83.773917,
              33.07936
            ],
            [
              -83.747004,
              33.063373
            ],
            [
              -83.737309,
              33.043866
            ],
            [
              -83.72537,
              33.032666
            ],
            [
              -83.721212,
              33.023364
            ],
            [
              -83.729647,
              33.013565
            ],
            [
              -83.722429,
              32.975687
            ],
            [
              -83.708045,
              32.966615
            ],
            [
              -83.710685,
              32.952792
            ],
            [
              -83.700551,
              32.943976
            ],
            [
              -83.696017,
              32.91687
            ],
            [
              -83.674501,
              32.902937
            ],
            [
              -83.663301,
              32.898736
            ],
            [
              -83.658601,
              32.887836
            ],
            [
              -83.599467,
              32.887838
            ],
            [
              -83.578632,
              32.865142
            ],
            [
              -83.56507,
              32.877164
            ],
            [
              -83.523421,
              32.841291
            ],
            [
              -83.513511,
              32.844868
            ],
            [
              -83.40699,
              32.89822
            ],
            [
              -83.357685,
              32.926141
            ],
            [
              -83.380383,
              32.998077
            ],
            [
              -83.394027,
              33.043215
            ],
            [
              -83.412956,
              33.105219
            ],
            [
              -83.42909,
              33.185352
            ],
            [
              -83.437578,
              33.185764
            ],
            [
              -83.545876,
              33.171944
            ],
            [
              -83.816048,
              33.131816
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "277",
      "COUNTYNS": "00344090",
      "AFFGEOID": "0500000US13277",
      "GEOID": "13277",
      "NAME": "Tift",
      "LSAD": "06",
      "ALAND": 675662341,
      "AWATER": 20498270,
      "County_state": "Tift,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.665777,
              31.485135
            ],
            [
              -83.66666,
              31.43417
            ],
            [
              -83.651785,
              31.433069
            ],
            [
              -83.65409,
              31.330661
            ],
            [
              -83.512607,
              31.327405
            ],
            [
              -83.460644,
              31.326263
            ],
            [
              -83.434492,
              31.350361
            ],
            [
              -83.430229,
              31.350664
            ],
            [
              -83.42592,
              31.36372
            ],
            [
              -83.399925,
              31.376423
            ],
            [
              -83.370888,
              31.401142
            ],
            [
              -83.369514,
              31.465055
            ],
            [
              -83.339187,
              31.465061
            ],
            [
              -83.338728,
              31.475991
            ],
            [
              -83.34527,
              31.476314
            ],
            [
              -83.376785,
              31.482248
            ],
            [
              -83.39726,
              31.531624
            ],
            [
              -83.420699,
              31.553567
            ],
            [
              -83.47093,
              31.555957
            ],
            [
              -83.470825,
              31.569391
            ],
            [
              -83.500951,
              31.570117
            ],
            [
              -83.500702,
              31.59399
            ],
            [
              -83.605142,
              31.597921
            ],
            [
              -83.648972,
              31.596466
            ],
            [
              -83.649384,
              31.567977
            ],
            [
              -83.650627,
              31.484737
            ],
            [
              -83.665777,
              31.485135
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "211",
      "COUNTYNS": "00346008",
      "AFFGEOID": "0500000US13211",
      "GEOID": "13211",
      "NAME": "Morgan",
      "LSAD": "06",
      "ALAND": 899632962,
      "AWATER": 18830382,
      "County_state": "Morgan,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.680896,
              33.596919
            ],
            [
              -83.687386,
              33.588559
            ],
            [
              -83.677669,
              33.571176
            ],
            [
              -83.682196,
              33.52622
            ],
            [
              -83.586817,
              33.465577
            ],
            [
              -83.533736,
              33.434472
            ],
            [
              -83.279931,
              33.483438
            ],
            [
              -83.27681,
              33.494198
            ],
            [
              -83.283928,
              33.506237
            ],
            [
              -83.277611,
              33.52763
            ],
            [
              -83.269213,
              33.533226
            ],
            [
              -83.286591,
              33.542675
            ],
            [
              -83.310847,
              33.562981
            ],
            [
              -83.326355,
              33.584202
            ],
            [
              -83.34687,
              33.600498
            ],
            [
              -83.359543,
              33.62233
            ],
            [
              -83.370514,
              33.655914
            ],
            [
              -83.383995,
              33.66658
            ],
            [
              -83.394913,
              33.690514
            ],
            [
              -83.406189,
              33.698307
            ],
            [
              -83.425308,
              33.716015
            ],
            [
              -83.44532,
              33.722599
            ],
            [
              -83.461861,
              33.774128
            ],
            [
              -83.479523,
              33.802265
            ],
            [
              -83.505928,
              33.81776
            ],
            [
              -83.526386,
              33.796119
            ],
            [
              -83.534669,
              33.781544
            ],
            [
              -83.618844,
              33.674944
            ],
            [
              -83.680896,
              33.596919
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "013",
      "COUNTYNS": "00356976",
      "AFFGEOID": "0500000US13013",
      "GEOID": "13013",
      "NAME": "Barrow",
      "LSAD": "06",
      "ALAND": 416283948,
      "AWATER": 5707989,
      "County_state": "Barrow,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.864874,
              34.013482
            ],
            [
              -83.869115,
              34.004316
            ],
            [
              -83.83889,
              33.984249
            ],
            [
              -83.824235,
              33.964167
            ],
            [
              -83.812991,
              33.953139
            ],
            [
              -83.813319,
              33.949055
            ],
            [
              -83.799104,
              33.929844
            ],
            [
              -83.761441,
              33.895137
            ],
            [
              -83.757545,
              33.897167
            ],
            [
              -83.744161,
              33.90568
            ],
            [
              -83.723193,
              33.900162
            ],
            [
              -83.675975,
              33.917542
            ],
            [
              -83.647031,
              33.906198
            ],
            [
              -83.570786,
              33.947936
            ],
            [
              -83.56669,
              33.950138
            ],
            [
              -83.537385,
              33.965912
            ],
            [
              -83.563215,
              34.031757
            ],
            [
              -83.577393,
              34.039563
            ],
            [
              -83.600538,
              34.0353
            ],
            [
              -83.614647,
              34.047161
            ],
            [
              -83.66324,
              34.052273
            ],
            [
              -83.679458,
              34.047412
            ],
            [
              -83.700874,
              34.054316
            ],
            [
              -83.711379,
              34.045984
            ],
            [
              -83.72377,
              34.063423
            ],
            [
              -83.733726,
              34.067345
            ],
            [
              -83.764523,
              34.064933
            ],
            [
              -83.791222,
              34.087513
            ],
            [
              -83.792221,
              34.100304
            ],
            [
              -83.811757,
              34.112851
            ],
            [
              -83.817682,
              34.127493
            ],
            [
              -83.833011,
              34.090426
            ],
            [
              -83.864874,
              34.013482
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "037",
      "COUNTYNS": "00344074",
      "AFFGEOID": "0500000US13037",
      "GEOID": "13037",
      "NAME": "Calhoun",
      "LSAD": "06",
      "ALAND": 726147254,
      "AWATER": 8315719,
      "County_state": "Calhoun,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.819807,
              31.501192
            ],
            [
              -84.819769,
              31.491563
            ],
            [
              -84.806887,
              31.491729
            ],
            [
              -84.796609,
              31.501286
            ],
            [
              -84.797093,
              31.473586
            ],
            [
              -84.807552,
              31.473119
            ],
            [
              -84.789617,
              31.451378
            ],
            [
              -84.789032,
              31.436356
            ],
            [
              -84.637579,
              31.433926
            ],
            [
              -84.546641,
              31.431891
            ],
            [
              -84.490813,
              31.427882
            ],
            [
              -84.490839,
              31.435439
            ],
            [
              -84.468635,
              31.438803
            ],
            [
              -84.468704,
              31.448173
            ],
            [
              -84.447875,
              31.454743
            ],
            [
              -84.436037,
              31.441556
            ],
            [
              -84.448133,
              31.436616
            ],
            [
              -84.429876,
              31.436661
            ],
            [
              -84.412521,
              31.45693
            ],
            [
              -84.432705,
              31.507449
            ],
            [
              -84.447348,
              31.544198
            ],
            [
              -84.454923,
              31.548331
            ],
            [
              -84.456874,
              31.586521
            ],
            [
              -84.447089,
              31.604452
            ],
            [
              -84.450398,
              31.621868
            ],
            [
              -84.455223,
              31.621238
            ],
            [
              -84.546854,
              31.621199
            ],
            [
              -84.568823,
              31.628627
            ],
            [
              -84.578024,
              31.621098
            ],
            [
              -84.577972,
              31.637252
            ],
            [
              -84.586501,
              31.621159
            ],
            [
              -84.59741,
              31.621358
            ],
            [
              -84.59715,
              31.637613
            ],
            [
              -84.606931,
              31.628255
            ],
            [
              -84.622521,
              31.628465
            ],
            [
              -84.622229,
              31.637824
            ],
            [
              -84.649297,
              31.64367
            ],
            [
              -84.656517,
              31.619981
            ],
            [
              -84.817843,
              31.619771
            ],
            [
              -84.818403,
              31.56573
            ],
            [
              -84.799134,
              31.565253
            ],
            [
              -84.818935,
              31.5479
            ],
            [
              -84.80796,
              31.514383
            ],
            [
              -84.819807,
              31.501192
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "195",
      "COUNTYNS": "01688004",
      "AFFGEOID": "0500000US13195",
      "GEOID": "13195",
      "NAME": "Madison",
      "LSAD": "06",
      "ALAND": 731184825,
      "AWATER": 8484621,
      "County_state": "Madison,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.402428,
              34.197499
            ],
            [
              -83.379441,
              34.171304
            ],
            [
              -83.363835,
              34.164059
            ],
            [
              -83.361011,
              34.148355
            ],
            [
              -83.357051,
              34.116199
            ],
            [
              -83.379744,
              34.079524
            ],
            [
              -83.386763,
              34.050241
            ],
            [
              -83.360028,
              34.040572
            ],
            [
              -83.320241,
              34.026023
            ],
            [
              -83.294327,
              34.00808
            ],
            [
              -83.263454,
              34.006327
            ],
            [
              -83.258413,
              33.999098
            ],
            [
              -83.237006,
              34.013557
            ],
            [
              -83.220718,
              34.000501
            ],
            [
              -83.193153,
              34.010468
            ],
            [
              -83.164299,
              34.034519
            ],
            [
              -83.123878,
              34.047558
            ],
            [
              -83.100967,
              34.024792
            ],
            [
              -83.090584,
              34.032616
            ],
            [
              -83.048889,
              34.015804
            ],
            [
              -83.0289,
              34.030975
            ],
            [
              -83.001602,
              34.030899
            ],
            [
              -82.976294,
              34.043219
            ],
            [
              -82.991458,
              34.065054
            ],
            [
              -83.036102,
              34.099148
            ],
            [
              -83.031021,
              34.1133
            ],
            [
              -83.04792,
              34.12109
            ],
            [
              -83.067528,
              34.154804
            ],
            [
              -83.094536,
              34.161659
            ],
            [
              -83.102487,
              34.17305
            ],
            [
              -83.095491,
              34.202488
            ],
            [
              -83.078004,
              34.223606
            ],
            [
              -83.115847,
              34.262125
            ],
            [
              -83.11336,
              34.27353
            ],
            [
              -83.172745,
              34.238522
            ],
            [
              -83.202827,
              34.248337
            ],
            [
              -83.239021,
              34.246227
            ],
            [
              -83.273073,
              34.248901
            ],
            [
              -83.297317,
              34.264587
            ],
            [
              -83.313591,
              34.259144
            ],
            [
              -83.355269,
              34.22373
            ],
            [
              -83.372429,
              34.205933
            ],
            [
              -83.402428,
              34.197499
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "093",
      "COUNTYNS": "00351608",
      "AFFGEOID": "0500000US13093",
      "GEOID": "13093",
      "NAME": "Dooly",
      "LSAD": "06",
      "ALAND": 1015119749,
      "AWATER": 13841085,
      "County_state": "Dooly,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.027853,
              32.171083
            ],
            [
              -84.033411,
              32.153216
            ],
            [
              -83.990165,
              32.095442
            ],
            [
              -83.975052,
              32.092379
            ],
            [
              -83.986737,
              32.070687
            ],
            [
              -83.980391,
              32.044908
            ],
            [
              -83.969194,
              32.03855
            ],
            [
              -83.961278,
              32.030595
            ],
            [
              -83.609663,
              32.027938
            ],
            [
              -83.608547,
              32.118405
            ],
            [
              -83.606397,
              32.288435
            ],
            [
              -83.615579,
              32.288556
            ],
            [
              -83.700362,
              32.289991
            ],
            [
              -83.700403,
              32.28287
            ],
            [
              -83.718434,
              32.290162
            ],
            [
              -83.848379,
              32.29097
            ],
            [
              -83.894777,
              32.292175
            ],
            [
              -83.897481,
              32.280977
            ],
            [
              -83.965121,
              32.248005
            ],
            [
              -83.989543,
              32.216884
            ],
            [
              -84.012641,
              32.201363
            ],
            [
              -84.031087,
              32.178854
            ],
            [
              -84.027853,
              32.171083
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "189",
      "COUNTYNS": "00348794",
      "AFFGEOID": "0500000US13189",
      "GEOID": "13189",
      "NAME": "McDuffie",
      "LSAD": "06",
      "ALAND": 666813913,
      "AWATER": 23119020,
      "County_state": "McDuffie,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.643525,
              33.618034
            ],
            [
              -82.649705,
              33.608768
            ],
            [
              -82.607437,
              33.505922
            ],
            [
              -82.584563,
              33.449654
            ],
            [
              -82.553616,
              33.415802
            ],
            [
              -82.551901,
              33.408425
            ],
            [
              -82.544313,
              33.374721
            ],
            [
              -82.548068,
              33.352783
            ],
            [
              -82.535779,
              33.35746
            ],
            [
              -82.51072,
              33.342045
            ],
            [
              -82.449977,
              33.340505
            ],
            [
              -82.411074,
              33.319922
            ],
            [
              -82.383829,
              33.312106
            ],
            [
              -82.371972,
              33.310879
            ],
            [
              -82.353498,
              33.312318
            ],
            [
              -82.294179,
              33.354635
            ],
            [
              -82.340433,
              33.405434
            ],
            [
              -82.33611,
              33.419732
            ],
            [
              -82.364184,
              33.45068
            ],
            [
              -82.384762,
              33.467281
            ],
            [
              -82.383204,
              33.477651
            ],
            [
              -82.398703,
              33.491907
            ],
            [
              -82.402385,
              33.516481
            ],
            [
              -82.384924,
              33.52995
            ],
            [
              -82.436814,
              33.549658
            ],
            [
              -82.432666,
              33.568838
            ],
            [
              -82.413517,
              33.587802
            ],
            [
              -82.412701,
              33.597596
            ],
            [
              -82.432988,
              33.608147
            ],
            [
              -82.430108,
              33.614466
            ],
            [
              -82.441664,
              33.638344
            ],
            [
              -82.425328,
              33.650629
            ],
            [
              -82.430915,
              33.655076
            ],
            [
              -82.458237,
              33.637471
            ],
            [
              -82.479518,
              33.639535
            ],
            [
              -82.507223,
              33.659994
            ],
            [
              -82.525209,
              33.644698
            ],
            [
              -82.537228,
              33.64916
            ],
            [
              -82.544281,
              33.638573
            ],
            [
              -82.565583,
              33.645918
            ],
            [
              -82.573511,
              33.639505
            ],
            [
              -82.583603,
              33.650477
            ],
            [
              -82.586683,
              33.640492
            ],
            [
              -82.617847,
              33.632203
            ],
            [
              -82.623749,
              33.619394
            ],
            [
              -82.617049,
              33.612171
            ],
            [
              -82.633502,
              33.611132
            ],
            [
              -82.643525,
              33.618034
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "131",
      "COUNTYNS": "00351261",
      "AFFGEOID": "0500000US13131",
      "GEOID": "13131",
      "NAME": "Grady",
      "LSAD": "06",
      "ALAND": 1177152222,
      "AWATER": 14925882,
      "County_state": "Grady,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.37594,
              31.00439
            ],
            [
              -84.3807060980397,
              30.689956554824498
            ],
            [
              -84.375012,
              30.689785
            ],
            [
              -84.2825636638089,
              30.685307571749597
            ],
            [
              -84.281313,
              30.685247
            ],
            [
              -84.250002,
              30.684136
            ],
            [
              -84.124993,
              30.678037
            ],
            [
              -84.107796,
              30.676809
            ],
            [
              -84.08375705716549,
              30.675802634493497
            ],
            [
              -84.080278,
              30.844809
            ],
            [
              -84.082524,
              30.85382
            ],
            [
              -84.07291,
              30.865151
            ],
            [
              -84.079514,
              30.881017
            ],
            [
              -84.076347,
              30.905958
            ],
            [
              -84.118602,
              30.969691
            ],
            [
              -84.116644,
              31.077971
            ],
            [
              -84.376612,
              31.078883
            ],
            [
              -84.37594,
              31.00439
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "111",
      "COUNTYNS": "00351094",
      "AFFGEOID": "0500000US13111",
      "GEOID": "13111",
      "NAME": "Fannin",
      "LSAD": "06",
      "ALAND": 1002610853,
      "AWATER": 13459979,
      "County_state": "Fannin,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.621483,
              34.988329
            ],
            [
              -84.622752,
              34.857131
            ],
            [
              -84.618637,
              34.855398
            ],
            [
              -84.422593,
              34.857456
            ],
            [
              -84.417421,
              34.849816
            ],
            [
              -84.354218,
              34.825019
            ],
            [
              -84.343661,
              34.824781
            ],
            [
              -84.328065,
              34.806403
            ],
            [
              -84.314653,
              34.807328
            ],
            [
              -84.252167,
              34.718161
            ],
            [
              -84.252292,
              34.66461
            ],
            [
              -84.242624,
              34.670908
            ],
            [
              -84.226471,
              34.653878
            ],
            [
              -84.193887,
              34.638834
            ],
            [
              -84.196754,
              34.617924
            ],
            [
              -84.188557,
              34.602692
            ],
            [
              -84.158035,
              34.648243
            ],
            [
              -84.10363,
              34.727837
            ],
            [
              -84.09317,
              34.727779
            ],
            [
              -84.093193,
              34.801411
            ],
            [
              -84.123987,
              34.79604
            ],
            [
              -84.140063,
              34.806885
            ],
            [
              -84.147507,
              34.844643
            ],
            [
              -84.142467,
              34.854044
            ],
            [
              -84.11374,
              34.870369
            ],
            [
              -84.107422,
              34.886896
            ],
            [
              -84.133545,
              34.916473
            ],
            [
              -84.149123,
              34.919606
            ],
            [
              -84.171684,
              34.937904
            ],
            [
              -84.178834,
              34.952086
            ],
            [
              -84.1294520756424,
              34.9875038108939
            ],
            [
              -84.129555,
              34.987504
            ],
            [
              -84.321869,
              34.988408
            ],
            [
              -84.393935,
              34.988068
            ],
            [
              -84.394903,
              34.98803
            ],
            [
              -84.509052,
              34.988033
            ],
            [
              -84.509886,
              34.98801
            ],
            [
              -84.621483,
              34.988329
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "021",
      "COUNTYNS": "01672039",
      "AFFGEOID": "0500000US13021",
      "GEOID": "13021",
      "NAME": "Bibb",
      "LSAD": "06",
      "ALAND": 645699773,
      "AWATER": 14503560,
      "County_state": "Bibb,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.891922,
              32.848351
            ],
            [
              -83.89185,
              32.81908
            ],
            [
              -83.882471,
              32.818392
            ],
            [
              -83.876942,
              32.806944
            ],
            [
              -83.856924,
              32.793743
            ],
            [
              -83.853432,
              32.782016
            ],
            [
              -83.810141,
              32.729942
            ],
            [
              -83.776874,
              32.718809
            ],
            [
              -83.736292,
              32.693633
            ],
            [
              -83.701152,
              32.692169
            ],
            [
              -83.701029,
              32.692159
            ],
            [
              -83.69018,
              32.686991
            ],
            [
              -83.666134,
              32.674518
            ],
            [
              -83.642972,
              32.671509
            ],
            [
              -83.626522,
              32.669562
            ],
            [
              -83.619315,
              32.664046
            ],
            [
              -83.604728,
              32.660643
            ],
            [
              -83.597656,
              32.664338
            ],
            [
              -83.611063,
              32.688543
            ],
            [
              -83.597164,
              32.712035
            ],
            [
              -83.60236,
              32.741267
            ],
            [
              -83.578715,
              32.738118
            ],
            [
              -83.502603,
              32.801119
            ],
            [
              -83.492938,
              32.805149
            ],
            [
              -83.48943,
              32.82406
            ],
            [
              -83.513511,
              32.844868
            ],
            [
              -83.523421,
              32.841291
            ],
            [
              -83.56507,
              32.877164
            ],
            [
              -83.578632,
              32.865142
            ],
            [
              -83.599467,
              32.887838
            ],
            [
              -83.658601,
              32.887836
            ],
            [
              -83.663301,
              32.898736
            ],
            [
              -83.674501,
              32.902937
            ],
            [
              -83.696017,
              32.91687
            ],
            [
              -83.700551,
              32.943976
            ],
            [
              -83.710685,
              32.952792
            ],
            [
              -83.734112,
              32.952393
            ],
            [
              -83.725855,
              32.944905
            ],
            [
              -83.863831,
              32.864683
            ],
            [
              -83.872752,
              32.859489
            ],
            [
              -83.891922,
              32.848351
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "235",
      "COUNTYNS": "00347593",
      "AFFGEOID": "0500000US13235",
      "GEOID": "13235",
      "NAME": "Pulaski",
      "LSAD": "06",
      "ALAND": 645503546,
      "AWATER": 5131020,
      "County_state": "Pulaski,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.615579,
              32.288556
            ],
            [
              -83.606397,
              32.288435
            ],
            [
              -83.608547,
              32.118405
            ],
            [
              -83.435657,
              32.117244
            ],
            [
              -83.435593,
              32.125419
            ],
            [
              -83.416543,
              32.12517
            ],
            [
              -83.41644,
              32.133076
            ],
            [
              -83.396981,
              32.132578
            ],
            [
              -83.397148,
              32.124099
            ],
            [
              -83.361051,
              32.124009
            ],
            [
              -83.350525,
              32.112205
            ],
            [
              -83.3589,
              32.105765
            ],
            [
              -83.337313,
              32.105885
            ],
            [
              -83.308926,
              32.125709
            ],
            [
              -83.304685,
              32.1332
            ],
            [
              -83.324805,
              32.150367
            ],
            [
              -83.291106,
              32.178825
            ],
            [
              -83.373931,
              32.249741
            ],
            [
              -83.346528,
              32.272489
            ],
            [
              -83.367756,
              32.288928
            ],
            [
              -83.498039,
              32.401715
            ],
            [
              -83.503081,
              32.389164
            ],
            [
              -83.523458,
              32.402288
            ],
            [
              -83.579403,
              32.336296
            ],
            [
              -83.615579,
              32.288556
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "035",
      "COUNTYNS": "00352295",
      "AFFGEOID": "0500000US13035",
      "GEOID": "13035",
      "NAME": "Butts",
      "LSAD": "06",
      "ALAND": 475759863,
      "AWATER": 11264566,
      "County_state": "Butts,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.122623,
              33.252668
            ],
            [
              -84.123767,
              33.202824
            ],
            [
              -84.041498,
              33.202629
            ],
            [
              -83.859238,
              33.200495
            ],
            [
              -83.842615,
              33.196773
            ],
            [
              -83.822261,
              33.180238
            ],
            [
              -83.80729,
              33.192423
            ],
            [
              -83.82575,
              33.210275
            ],
            [
              -83.814103,
              33.242134
            ],
            [
              -83.832459,
              33.272781
            ],
            [
              -83.827408,
              33.291987
            ],
            [
              -83.835901,
              33.306325
            ],
            [
              -83.840409,
              33.328902
            ],
            [
              -83.863362,
              33.342317
            ],
            [
              -83.863058,
              33.368278
            ],
            [
              -83.8643,
              33.377054
            ],
            [
              -83.895081,
              33.396725
            ],
            [
              -83.912299,
              33.41768
            ],
            [
              -83.923913,
              33.444194
            ],
            [
              -83.932746,
              33.441865
            ],
            [
              -83.943516,
              33.42529
            ],
            [
              -83.973128,
              33.407556
            ],
            [
              -83.964374,
              33.375154
            ],
            [
              -84.032049,
              33.361641
            ],
            [
              -84.032992,
              33.342796
            ],
            [
              -84.042533,
              33.341113
            ],
            [
              -84.061266,
              33.309583
            ],
            [
              -84.080238,
              33.301873
            ],
            [
              -84.085509,
              33.298008
            ],
            [
              -84.097308,
              33.302025
            ],
            [
              -84.102582,
              33.298191
            ],
            [
              -84.088991,
              33.285646
            ],
            [
              -84.102585,
              33.272301
            ],
            [
              -84.122623,
              33.252668
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "171",
      "COUNTYNS": "00326713",
      "AFFGEOID": "0500000US13171",
      "GEOID": "13171",
      "NAME": "Lamar",
      "LSAD": "06",
      "ALAND": 475262778,
      "AWATER": 6046030,
      "County_state": "Lamar,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.269169,
              33.031438
            ],
            [
              -84.27014,
              32.991011
            ],
            [
              -84.122361,
              32.989576
            ],
            [
              -84.12334,
              32.932184
            ],
            [
              -84.054175,
              32.931497
            ],
            [
              -84.044113,
              32.947874
            ],
            [
              -84.04288,
              33.028716
            ],
            [
              -84.041355,
              33.129603
            ],
            [
              -84.041498,
              33.202629
            ],
            [
              -84.123767,
              33.202824
            ],
            [
              -84.123981,
              33.17862
            ],
            [
              -84.224277,
              33.179388
            ],
            [
              -84.248185,
              33.189019
            ],
            [
              -84.248133,
              33.075488
            ],
            [
              -84.248659,
              33.03933
            ],
            [
              -84.269169,
              33.031438
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "109",
      "COUNTYNS": "00345923",
      "AFFGEOID": "0500000US13109",
      "GEOID": "13109",
      "NAME": "Evans",
      "LSAD": "06",
      "ALAND": 473612756,
      "AWATER": 10377268,
      "County_state": "Evans,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.999843,
              32.276189
            ],
            [
              -82.025339,
              32.278883
            ],
            [
              -81.97979,
              32.081412
            ],
            [
              -81.889118,
              32.050438
            ],
            [
              -81.761735,
              32.0479
            ],
            [
              -81.718658,
              32.089351
            ],
            [
              -81.741043,
              32.1045
            ],
            [
              -81.75634,
              32.104584
            ],
            [
              -81.76334,
              32.11762
            ],
            [
              -81.77729,
              32.131939
            ],
            [
              -81.780858,
              32.15289
            ],
            [
              -81.778471,
              32.162295
            ],
            [
              -81.792594,
              32.193351
            ],
            [
              -81.80194,
              32.196865
            ],
            [
              -81.805582,
              32.220609
            ],
            [
              -81.81628,
              32.23747
            ],
            [
              -81.841651,
              32.240921
            ],
            [
              -81.857389,
              32.257391
            ],
            [
              -81.892725,
              32.274783
            ],
            [
              -81.918592,
              32.274513
            ],
            [
              -81.953213,
              32.266832
            ],
            [
              -81.969069,
              32.268783
            ],
            [
              -81.999843,
              32.276189
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "003",
      "COUNTYNS": "00345784",
      "AFFGEOID": "0500000US13003",
      "GEOID": "13003",
      "NAME": "Atkinson",
      "LSAD": "06",
      "ALAND": 878787518,
      "AWATER": 13550957,
      "County_state": "Atkinson,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.140483,
              31.420395
            ],
            [
              -83.141518,
              31.403894
            ],
            [
              -83.115242,
              31.386076
            ],
            [
              -83.110916,
              31.367971
            ],
            [
              -83.081618,
              31.349814
            ],
            [
              -83.059567,
              31.329857
            ],
            [
              -83.058707,
              31.303925
            ],
            [
              -83.033468,
              31.275041
            ],
            [
              -83.050719,
              31.232088
            ],
            [
              -83.046885,
              31.18368
            ],
            [
              -82.971247,
              31.183988
            ],
            [
              -82.671669,
              31.183739
            ],
            [
              -82.690838,
              31.223364
            ],
            [
              -82.70105,
              31.223217
            ],
            [
              -82.699206,
              31.278104
            ],
            [
              -82.663584,
              31.287913
            ],
            [
              -82.629622,
              31.274726
            ],
            [
              -82.628975,
              31.36393
            ],
            [
              -82.817544,
              31.364486
            ],
            [
              -82.829453,
              31.377516
            ],
            [
              -82.839334,
              31.401712
            ],
            [
              -82.958061,
              31.402998
            ],
            [
              -82.958519,
              31.417274
            ],
            [
              -83.140483,
              31.420395
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "201",
      "COUNTYNS": "00344088",
      "AFFGEOID": "0500000US13201",
      "GEOID": "13201",
      "NAME": "Miller",
      "LSAD": "06",
      "ALAND": 731467676,
      "AWATER": 3215444,
      "County_state": "Miller,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.917421,
              31.25599
            ],
            [
              -84.922423,
              31.07259
            ],
            [
              -84.730855,
              31.06919
            ],
            [
              -84.645793,
              31.06801
            ],
            [
              -84.644678,
              31.079504
            ],
            [
              -84.542653,
              31.079029
            ],
            [
              -84.537101,
              31.255932
            ],
            [
              -84.641673,
              31.258967
            ],
            [
              -84.68322,
              31.257183
            ],
            [
              -84.917421,
              31.25599
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "081",
      "COUNTYNS": "00351607",
      "AFFGEOID": "0500000US13081",
      "GEOID": "13081",
      "NAME": "Crisp",
      "LSAD": "06",
      "ALAND": 705921217,
      "AWATER": 21739092,
      "County_state": "Crisp,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.961278,
              32.030595
            ],
            [
              -83.950304,
              32.015378
            ],
            [
              -83.950415,
              31.997197
            ],
            [
              -83.924902,
              31.947725
            ],
            [
              -83.919119,
              31.923092
            ],
            [
              -83.922494,
              31.909653
            ],
            [
              -83.934061,
              31.88139
            ],
            [
              -83.926524,
              31.863292
            ],
            [
              -83.939437,
              31.847929
            ],
            [
              -83.924263,
              31.848393
            ],
            [
              -83.845481,
              31.831555
            ],
            [
              -83.8262,
              31.809773
            ],
            [
              -83.802723,
              31.803579
            ],
            [
              -83.612642,
              31.804128
            ],
            [
              -83.612256,
              31.854089
            ],
            [
              -83.609663,
              32.027938
            ],
            [
              -83.961278,
              32.030595
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "069",
      "COUNTYNS": "00347456",
      "AFFGEOID": "0500000US13069",
      "GEOID": "13069",
      "NAME": "Coffee",
      "LSAD": "06",
      "ALAND": 1490399436,
      "AWATER": 70456631,
      "County_state": "Coffee,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.145587,
              31.472276
            ],
            [
              -83.151087,
              31.461552
            ],
            [
              -83.140483,
              31.420395
            ],
            [
              -82.958519,
              31.417274
            ],
            [
              -82.958061,
              31.402998
            ],
            [
              -82.839334,
              31.401712
            ],
            [
              -82.829453,
              31.377516
            ],
            [
              -82.817544,
              31.364486
            ],
            [
              -82.628975,
              31.36393
            ],
            [
              -82.628181,
              31.469351
            ],
            [
              -82.597509,
              31.469293
            ],
            [
              -82.596283,
              31.557899
            ],
            [
              -82.628273,
              31.558329
            ],
            [
              -82.627342,
              31.672672
            ],
            [
              -82.814029,
              31.671419
            ],
            [
              -82.836633,
              31.671398
            ],
            [
              -82.836721,
              31.761826
            ],
            [
              -82.836366,
              31.816154
            ],
            [
              -82.868118,
              31.782347
            ],
            [
              -82.886986,
              31.779581
            ],
            [
              -82.907577,
              31.784847
            ],
            [
              -82.94651,
              31.787752
            ],
            [
              -82.955261,
              31.782999
            ],
            [
              -82.97831,
              31.793201
            ],
            [
              -82.995698,
              31.780983
            ],
            [
              -82.99836,
              31.673164
            ],
            [
              -82.998794,
              31.660467
            ],
            [
              -83.01342,
              31.660801
            ],
            [
              -83.014174,
              31.635715
            ],
            [
              -83.029361,
              31.636185
            ],
            [
              -83.030189,
              31.623688
            ],
            [
              -83.044424,
              31.62416
            ],
            [
              -83.044848,
              31.610805
            ],
            [
              -83.058807,
              31.611212
            ],
            [
              -83.060119,
              31.56521
            ],
            [
              -83.083514,
              31.516107
            ],
            [
              -83.096731,
              31.506193
            ],
            [
              -83.11166,
              31.478229
            ],
            [
              -83.119817,
              31.473677
            ],
            [
              -83.145587,
              31.472276
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "017",
      "COUNTYNS": "00351606",
      "AFFGEOID": "0500000US13017",
      "GEOID": "13017",
      "NAME": "Ben Hill",
      "LSAD": "06",
      "ALAND": 647813382,
      "AWATER": 9803106,
      "County_state": "Ben Hill,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.480176,
              31.847312
            ],
            [
              -83.484529,
              31.835521
            ],
            [
              -83.45919,
              31.794298
            ],
            [
              -83.453634,
              31.757861
            ],
            [
              -83.370272,
              31.757056
            ],
            [
              -83.369642,
              31.769651
            ],
            [
              -83.339664,
              31.769552
            ],
            [
              -83.339868,
              31.756599
            ],
            [
              -83.325296,
              31.756584
            ],
            [
              -83.325617,
              31.680603
            ],
            [
              -83.265636,
              31.679859
            ],
            [
              -83.266363,
              31.654203
            ],
            [
              -83.177218,
              31.651691
            ],
            [
              -83.176376,
              31.678382
            ],
            [
              -82.99836,
              31.673164
            ],
            [
              -82.995698,
              31.780983
            ],
            [
              -83.005787,
              31.774629
            ],
            [
              -83.027966,
              31.778291
            ],
            [
              -83.039045,
              31.790566
            ],
            [
              -83.052816,
              31.789224
            ],
            [
              -83.085009,
              31.811451
            ],
            [
              -83.099269,
              31.808295
            ],
            [
              -83.138659,
              31.814446
            ],
            [
              -83.134605,
              31.824115
            ],
            [
              -83.151966,
              31.82488
            ],
            [
              -83.152311,
              31.83166
            ],
            [
              -83.177469,
              31.847857
            ],
            [
              -83.183027,
              31.851974
            ],
            [
              -83.209638,
              31.844646
            ],
            [
              -83.443957,
              31.847008
            ],
            [
              -83.480176,
              31.847312
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "053",
      "COUNTYNS": "00351012",
      "AFFGEOID": "0500000US13053",
      "GEOID": "13053",
      "NAME": "Chattahoochee",
      "LSAD": "06",
      "ALAND": 644222675,
      "AWATER": 6292704,
      "County_state": "Chattahoochee,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.008096,
              32.336677
            ],
            [
              -85.001874,
              32.322015
            ],
            [
              -84.9563588359664,
              32.3061320972527
            ],
            [
              -84.9338,
              32.29826
            ],
            [
              -84.891841,
              32.263398
            ],
            [
              -84.9199421479259,
              32.2308482009333
            ],
            [
              -84.813924,
              32.230851
            ],
            [
              -84.814024,
              32.221051
            ],
            [
              -84.802525,
              32.220951
            ],
            [
              -84.802524,
              32.230851
            ],
            [
              -84.65892,
              32.232852
            ],
            [
              -84.657325,
              32.49494
            ],
            [
              -84.638225,
              32.494841
            ],
            [
              -84.637323,
              32.534855
            ],
            [
              -84.678562,
              32.518869
            ],
            [
              -84.69451,
              32.518732
            ],
            [
              -84.724265,
              32.507756
            ],
            [
              -84.768407,
              32.420591
            ],
            [
              -84.778647,
              32.424975
            ],
            [
              -84.792951,
              32.41384
            ],
            [
              -84.806023,
              32.4196
            ],
            [
              -84.818952,
              32.412816
            ],
            [
              -84.822744,
              32.414032
            ],
            [
              -84.826328,
              32.412784
            ],
            [
              -84.834312,
              32.413744
            ],
            [
              -84.835176,
              32.411631
            ],
            [
              -84.837784,
              32.41328
            ],
            [
              -84.844664,
              32.41328
            ],
            [
              -84.848664,
              32.41464
            ],
            [
              -84.882201,
              32.410463
            ],
            [
              -84.893009,
              32.400813
            ],
            [
              -84.94513,
              32.376018
            ],
            [
              -84.963194,
              32.374066
            ],
            [
              -84.9834252816289,
              32.3777300931769
            ],
            [
              -84.983466,
              32.363186
            ],
            [
              -85.008096,
              32.336677
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "149",
      "COUNTYNS": "00347067",
      "AFFGEOID": "0500000US13149",
      "GEOID": "13149",
      "NAME": "Heard",
      "LSAD": "06",
      "ALAND": 766640653,
      "AWATER": 13190449,
      "County_state": "Heard,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.2939525862265,
              33.4280696299523
            ],
            [
              -85.23656892180279,
              33.129549211458
            ],
            [
              -85.117402,
              33.163654
            ],
            [
              -85.11754,
              33.178496
            ],
            [
              -85.107851,
              33.178458
            ],
            [
              -85.10798,
              33.195098
            ],
            [
              -85.087712,
              33.186588
            ],
            [
              -85.088036,
              33.171701
            ],
            [
              -85.045259,
              33.181618
            ],
            [
              -85.04083,
              33.188281
            ],
            [
              -84.939015,
              33.224693
            ],
            [
              -84.954578,
              33.265608
            ],
            [
              -84.948815,
              33.282277
            ],
            [
              -84.956894,
              33.282353
            ],
            [
              -84.958646,
              33.277053
            ],
            [
              -85.015358,
              33.425506
            ],
            [
              -85.099121,
              33.426631
            ],
            [
              -85.2939525862265,
              33.4280696299523
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "217",
      "COUNTYNS": "01673547",
      "AFFGEOID": "0500000US13217",
      "GEOID": "13217",
      "NAME": "Newton",
      "LSAD": "06",
      "ALAND": 708857834,
      "AWATER": 14261429,
      "County_state": "Newton,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.044493,
              33.525776
            ],
            [
              -84.054396,
              33.514907
            ],
            [
              -84.041928,
              33.518571
            ],
            [
              -84.031526,
              33.497908
            ],
            [
              -83.997394,
              33.494102
            ],
            [
              -83.974451,
              33.483671
            ],
            [
              -83.946526,
              33.481761
            ],
            [
              -83.922546,
              33.45058
            ],
            [
              -83.923913,
              33.444194
            ],
            [
              -83.912299,
              33.41768
            ],
            [
              -83.895081,
              33.396725
            ],
            [
              -83.8643,
              33.377054
            ],
            [
              -83.863058,
              33.368278
            ],
            [
              -83.831024,
              33.393462
            ],
            [
              -83.840191,
              33.399748
            ],
            [
              -83.845207,
              33.414721
            ],
            [
              -83.830792,
              33.419186
            ],
            [
              -83.824922,
              33.437263
            ],
            [
              -83.771278,
              33.452495
            ],
            [
              -83.736687,
              33.480862
            ],
            [
              -83.704743,
              33.488518
            ],
            [
              -83.705197,
              33.500812
            ],
            [
              -83.687601,
              33.483117
            ],
            [
              -83.674644,
              33.487225
            ],
            [
              -83.688143,
              33.510763
            ],
            [
              -83.682196,
              33.52622
            ],
            [
              -83.677669,
              33.571176
            ],
            [
              -83.687386,
              33.588559
            ],
            [
              -83.680896,
              33.596919
            ],
            [
              -83.699417,
              33.609447
            ],
            [
              -83.691551,
              33.616989
            ],
            [
              -83.734353,
              33.645639
            ],
            [
              -83.758286,
              33.646351
            ],
            [
              -83.790705,
              33.666656
            ],
            [
              -83.888133,
              33.727607
            ],
            [
              -83.914823,
              33.744203
            ],
            [
              -83.913499,
              33.712207
            ],
            [
              -83.925054,
              33.710515
            ],
            [
              -83.920548,
              33.691899
            ],
            [
              -83.931268,
              33.651259
            ],
            [
              -83.931597,
              33.650862
            ],
            [
              -83.951645,
              33.628773
            ],
            [
              -83.974921,
              33.60298
            ],
            [
              -83.988648,
              33.587748
            ],
            [
              -84.010452,
              33.563564
            ],
            [
              -84.010681,
              33.563312
            ],
            [
              -84.01462,
              33.559029
            ],
            [
              -84.032117,
              33.539408
            ],
            [
              -84.044493,
              33.525776
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "089",
      "COUNTYNS": "01687424",
      "AFFGEOID": "0500000US13089",
      "GEOID": "13089",
      "NAME": "DeKalb",
      "LSAD": "06",
      "ALAND": 693144670,
      "AWATER": 9191912,
      "County_state": "DeKalb,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.349414,
              33.680346
            ],
            [
              -84.350224,
              33.647908
            ],
            [
              -84.336336,
              33.647508
            ],
            [
              -84.281273,
              33.647411
            ],
            [
              -84.266882,
              33.64717
            ],
            [
              -84.254149,
              33.647045
            ],
            [
              -84.245453,
              33.63073
            ],
            [
              -84.224235,
              33.630657
            ],
            [
              -84.223952,
              33.646572
            ],
            [
              -84.187442,
              33.646173
            ],
            [
              -84.184143,
              33.646157
            ],
            [
              -84.169986,
              33.639293
            ],
            [
              -84.150581,
              33.638577
            ],
            [
              -84.12922,
              33.63137
            ],
            [
              -84.115791,
              33.614666
            ],
            [
              -84.104334,
              33.636025
            ],
            [
              -84.07448,
              33.692609
            ],
            [
              -84.074409,
              33.692743
            ],
            [
              -84.06311,
              33.714127
            ],
            [
              -84.056614,
              33.726608
            ],
            [
              -84.023713,
              33.752808
            ],
            [
              -84.040313,
              33.757708
            ],
            [
              -84.046474,
              33.764664
            ],
            [
              -84.075914,
              33.777507
            ],
            [
              -84.093315,
              33.795106
            ],
            [
              -84.097217,
              33.798371
            ],
            [
              -84.113415,
              33.807505
            ],
            [
              -84.123025,
              33.818534
            ],
            [
              -84.134257,
              33.834467
            ],
            [
              -84.148337,
              33.842564
            ],
            [
              -84.157817,
              33.853204
            ],
            [
              -84.17313,
              33.858115
            ],
            [
              -84.203519,
              33.873003
            ],
            [
              -84.207699,
              33.877931
            ],
            [
              -84.213351,
              33.882851
            ],
            [
              -84.23222,
              33.902002
            ],
            [
              -84.237113,
              33.904447
            ],
            [
              -84.237756,
              33.904859
            ],
            [
              -84.252421,
              33.911601
            ],
            [
              -84.258285,
              33.91789
            ],
            [
              -84.259013,
              33.918824
            ],
            [
              -84.27147,
              33.955892
            ],
            [
              -84.277093,
              33.957614
            ],
            [
              -84.280497,
              33.95602
            ],
            [
              -84.338923,
              33.969778
            ],
            [
              -84.347725,
              33.961082
            ],
            [
              -84.347823,
              33.938017
            ],
            [
              -84.347913,
              33.915004
            ],
            [
              -84.348315,
              33.890903
            ],
            [
              -84.348089,
              33.88209
            ],
            [
              -84.348298,
              33.857486
            ],
            [
              -84.348309,
              33.853945
            ],
            [
              -84.348384,
              33.852541
            ],
            [
              -84.348091,
              33.845293
            ],
            [
              -84.348468,
              33.826353
            ],
            [
              -84.348506,
              33.825117
            ],
            [
              -84.348415,
              33.817394
            ],
            [
              -84.34838,
              33.783047
            ],
            [
              -84.348347,
              33.78187
            ],
            [
              -84.348962,
              33.773844
            ],
            [
              -84.349253,
              33.739117
            ],
            [
              -84.349414,
              33.680346
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "311",
      "COUNTYNS": "00326781",
      "AFFGEOID": "0500000US13311",
      "GEOID": "13311",
      "NAME": "White",
      "LSAD": "06",
      "ALAND": 623386723,
      "AWATER": 3928195,
      "County_state": "White,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.874407,
              34.674893
            ],
            [
              -83.877276,
              34.629597
            ],
            [
              -83.864456,
              34.615581
            ],
            [
              -83.864361,
              34.560668
            ],
            [
              -83.864449,
              34.550593
            ],
            [
              -83.853713,
              34.541996
            ],
            [
              -83.85325,
              34.541455
            ],
            [
              -83.843753,
              34.541432
            ],
            [
              -83.843788,
              34.532397
            ],
            [
              -83.832831,
              34.532307
            ],
            [
              -83.843405,
              34.505494
            ],
            [
              -83.788479,
              34.504754
            ],
            [
              -83.777605,
              34.513696
            ],
            [
              -83.756767,
              34.504457
            ],
            [
              -83.666415,
              34.503602
            ],
            [
              -83.662781,
              34.513538
            ],
            [
              -83.63311,
              34.52689
            ],
            [
              -83.61554,
              34.552864
            ],
            [
              -83.63884,
              34.580029
            ],
            [
              -83.646522,
              34.612209
            ],
            [
              -83.638243,
              34.62238
            ],
            [
              -83.662819,
              34.659801
            ],
            [
              -83.645254,
              34.669623
            ],
            [
              -83.636502,
              34.699599
            ],
            [
              -83.639497,
              34.710494
            ],
            [
              -83.623296,
              34.732194
            ],
            [
              -83.632696,
              34.753994
            ],
            [
              -83.628295,
              34.763394
            ],
            [
              -83.646095,
              34.771794
            ],
            [
              -83.681596,
              34.800593
            ],
            [
              -83.690397,
              34.798991
            ],
            [
              -83.704146,
              34.790982
            ],
            [
              -83.743107,
              34.801191
            ],
            [
              -83.780401,
              34.792893
            ],
            [
              -83.825465,
              34.778972
            ],
            [
              -83.830401,
              34.730097
            ],
            [
              -83.856506,
              34.722191
            ],
            [
              -83.863606,
              34.688593
            ],
            [
              -83.874407,
              34.674893
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "115",
      "COUNTYNS": "00353665",
      "AFFGEOID": "0500000US13115",
      "GEOID": "13115",
      "NAME": "Floyd",
      "LSAD": "06",
      "ALAND": 1320436485,
      "AWATER": 22382107,
      "County_state": "Floyd,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.46210536142519,
              34.28638558983619
            ],
            [
              -85.458717,
              34.269436
            ],
            [
              -85.458095,
              34.265735
            ],
            [
              -85.455396,
              34.252853
            ],
            [
              -85.455082,
              34.250688
            ],
            [
              -85.4447454521673,
              34.199943441036595
            ],
            [
              -85.429499,
              34.125095
            ],
            [
              -85.428251,
              34.114396
            ],
            [
              -85.4218824893099,
              34.0808162176071
            ],
            [
              -85.283318,
              34.079393
            ],
            [
              -85.25807,
              34.079133
            ],
            [
              -85.257624,
              34.100668
            ],
            [
              -85.210034,
              34.097623
            ],
            [
              -85.157361,
              34.097267
            ],
            [
              -85.157521,
              34.083463
            ],
            [
              -85.122472,
              34.083145
            ],
            [
              -85.121958,
              34.096749
            ],
            [
              -85.046871,
              34.096412
            ],
            [
              -85.045282,
              34.167485
            ],
            [
              -85.044658,
              34.213622
            ],
            [
              -85.023813,
              34.213385
            ],
            [
              -85.023947,
              34.251201
            ],
            [
              -85.015606,
              34.251131
            ],
            [
              -85.023891,
              34.258289
            ],
            [
              -85.023295,
              34.316932
            ],
            [
              -85.023102,
              34.347112
            ],
            [
              -85.005235,
              34.34708
            ],
            [
              -85.005775,
              34.392446
            ],
            [
              -85.027031,
              34.393993
            ],
            [
              -85.031709,
              34.401353
            ],
            [
              -85.086761,
              34.401264
            ],
            [
              -85.104444,
              34.404278
            ],
            [
              -85.107403,
              34.415106
            ],
            [
              -85.088712,
              34.42502
            ],
            [
              -85.112324,
              34.461696
            ],
            [
              -85.090067,
              34.463527
            ],
            [
              -85.089997,
              34.541915
            ],
            [
              -85.063507,
              34.542548
            ],
            [
              -85.064169,
              34.574885
            ],
            [
              -85.069159,
              34.587184
            ],
            [
              -85.107748,
              34.587483
            ],
            [
              -85.123549,
              34.553184
            ],
            [
              -85.14765,
              34.514985
            ],
            [
              -85.162853,
              34.472321
            ],
            [
              -85.180321,
              34.436316
            ],
            [
              -85.215078,
              34.429586
            ],
            [
              -85.222437,
              34.415533
            ],
            [
              -85.241341,
              34.415518
            ],
            [
              -85.241444,
              34.408422
            ],
            [
              -85.280215,
              34.389541
            ],
            [
              -85.334881,
              34.369748
            ],
            [
              -85.363197,
              34.33902
            ],
            [
              -85.382818,
              34.303283
            ],
            [
              -85.387351,
              34.286097
            ],
            [
              -85.46210536142519,
              34.28638558983619
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "213",
      "COUNTYNS": "01688005",
      "AFFGEOID": "0500000US13213",
      "GEOID": "13213",
      "NAME": "Murray",
      "LSAD": "06",
      "ALAND": 892235146,
      "AWATER": 5719645,
      "County_state": "Murray,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.929707,
              34.675936
            ],
            [
              -84.944512,
              34.679454
            ],
            [
              -84.92699,
              34.665662
            ],
            [
              -84.913456,
              34.634128
            ],
            [
              -84.90825,
              34.615789
            ],
            [
              -84.863908,
              34.615759
            ],
            [
              -84.802198,
              34.60839
            ],
            [
              -84.779654,
              34.608322
            ],
            [
              -84.746134,
              34.610865
            ],
            [
              -84.715692,
              34.622876
            ],
            [
              -84.70554,
              34.598456
            ],
            [
              -84.681962,
              34.583335
            ],
            [
              -84.654523,
              34.583187
            ],
            [
              -84.657098,
              34.728905
            ],
            [
              -84.635647,
              34.772186
            ],
            [
              -84.641463,
              34.777402
            ],
            [
              -84.627348,
              34.784728
            ],
            [
              -84.609749,
              34.790937
            ],
            [
              -84.582306,
              34.825141
            ],
            [
              -84.618637,
              34.855398
            ],
            [
              -84.622752,
              34.857131
            ],
            [
              -84.621483,
              34.988329
            ],
            [
              -84.624933,
              34.987978
            ],
            [
              -84.727434,
              34.98802
            ],
            [
              -84.731022,
              34.988088
            ],
            [
              -84.7758524571709,
              34.987799615242096
            ],
            [
              -84.808127,
              34.987592
            ],
            [
              -84.809184,
              34.987569
            ],
            [
              -84.8104769358134,
              34.9876071739714
            ],
            [
              -84.813748,
              34.97187
            ],
            [
              -84.835207,
              34.942833
            ],
            [
              -84.842705,
              34.918126
            ],
            [
              -84.824076,
              34.901465
            ],
            [
              -84.833321,
              34.897294
            ],
            [
              -84.818715,
              34.87695
            ],
            [
              -84.82552,
              34.861741
            ],
            [
              -84.842661,
              34.860535
            ],
            [
              -84.848946,
              34.831744
            ],
            [
              -84.862932,
              34.814105
            ],
            [
              -84.859398,
              34.807149
            ],
            [
              -84.838079,
              34.800903
            ],
            [
              -84.862755,
              34.784339
            ],
            [
              -84.87421,
              34.78906
            ],
            [
              -84.867931,
              34.759642
            ],
            [
              -84.854118,
              34.748792
            ],
            [
              -84.857488,
              34.736598
            ],
            [
              -84.872168,
              34.731855
            ],
            [
              -84.856133,
              34.724512
            ],
            [
              -84.864945,
              34.707356
            ],
            [
              -84.882926,
              34.714732
            ],
            [
              -84.890481,
              34.705191
            ],
            [
              -84.924909,
              34.715971
            ],
            [
              -84.933722,
              34.706799
            ],
            [
              -84.888175,
              34.678387
            ],
            [
              -84.888314,
              34.668726
            ],
            [
              -84.901777,
              34.665639
            ],
            [
              -84.910373,
              34.681363
            ],
            [
              -84.929707,
              34.675936
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "295",
      "COUNTYNS": "00354216",
      "AFFGEOID": "0500000US13295",
      "GEOID": "13295",
      "NAME": "Walker",
      "LSAD": "06",
      "ALAND": 1156145994,
      "AWATER": 1672676,
      "County_state": "Walker,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.53410097210269,
              34.62385567623
            ],
            [
              -85.527274,
              34.588683
            ],
            [
              -85.5272733946093,
              34.5886802607335
            ],
            [
              -85.371725,
              34.589584
            ],
            [
              -85.352597,
              34.582316
            ],
            [
              -85.353477,
              34.589256
            ],
            [
              -85.322766,
              34.589145
            ],
            [
              -85.124949,
              34.588135
            ],
            [
              -85.107748,
              34.587483
            ],
            [
              -85.069159,
              34.587184
            ],
            [
              -85.060499,
              34.587184
            ],
            [
              -85.055206,
              34.622383
            ],
            [
              -85.050446,
              34.622482
            ],
            [
              -85.050847,
              34.71998
            ],
            [
              -85.090009,
              34.720114
            ],
            [
              -85.168851,
              34.720279
            ],
            [
              -85.144789,
              34.767639
            ],
            [
              -85.159819,
              34.775197
            ],
            [
              -85.201856,
              34.77508
            ],
            [
              -85.20218,
              34.854451
            ],
            [
              -85.264762,
              34.854277
            ],
            [
              -85.264479,
              34.901006
            ],
            [
              -85.264621,
              34.927422
            ],
            [
              -85.265055,
              34.985075
            ],
            [
              -85.275856,
              34.984975
            ],
            [
              -85.277556,
              34.984975
            ],
            [
              -85.2945,
              34.984651
            ],
            [
              -85.301488,
              34.984475
            ],
            [
              -85.305457,
              34.984475
            ],
            [
              -85.30546199363059,
              34.984474821656
            ],
            [
              -85.308257,
              34.984375
            ],
            [
              -85.32742496464239,
              34.984030636437005
            ],
            [
              -85.363919,
              34.983375
            ],
            [
              -85.374598,
              34.961964
            ],
            [
              -85.418392,
              34.899805
            ],
            [
              -85.43371,
              34.875077
            ],
            [
              -85.450561,
              34.831677
            ],
            [
              -85.450409,
              34.759241
            ],
            [
              -85.459354,
              34.751639
            ],
            [
              -85.461957,
              34.731771
            ],
            [
              -85.49053,
              34.69911
            ],
            [
              -85.490795,
              34.669449
            ],
            [
              -85.53410097210269,
              34.62385567623
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "179",
      "COUNTYNS": "00357095",
      "AFFGEOID": "0500000US13179",
      "GEOID": "13179",
      "NAME": "Liberty",
      "LSAD": "06",
      "ALAND": 1336136727,
      "AWATER": 224402177,
      "County_state": "Liberty,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.813382,
              32.01623
            ],
            [
              -81.824405,
              32.014882
            ],
            [
              -81.793922,
              31.977796
            ],
            [
              -81.779273,
              31.981004
            ],
            [
              -81.74694,
              31.969326
            ],
            [
              -81.745414,
              31.936275
            ],
            [
              -81.765777,
              31.887847
            ],
            [
              -81.765357,
              31.87454
            ],
            [
              -81.73561,
              31.853708
            ],
            [
              -81.68811,
              31.8094
            ],
            [
              -81.660331,
              31.800806
            ],
            [
              -81.64331,
              31.7859
            ],
            [
              -81.641761,
              31.765451
            ],
            [
              -81.627151,
              31.759462
            ],
            [
              -81.499603,
              31.699968
            ],
            [
              -81.491798,
              31.699575
            ],
            [
              -81.465684,
              31.694162
            ],
            [
              -81.467964,
              31.679056
            ],
            [
              -81.455673,
              31.659813
            ],
            [
              -81.434188,
              31.64109
            ],
            [
              -81.417587,
              31.652094
            ],
            [
              -81.391576,
              31.643561
            ],
            [
              -81.362942,
              31.654538
            ],
            [
              -81.340167,
              31.647348
            ],
            [
              -81.314548,
              31.659382
            ],
            [
              -81.302416,
              31.650716
            ],
            [
              -81.261868,
              31.650142
            ],
            [
              -81.22222,
              31.63042
            ],
            [
              -81.21659,
              31.619326
            ],
            [
              -81.197234,
              31.608314
            ],
            [
              -81.190124,
              31.598101
            ],
            [
              -81.1943802746182,
              31.5681005790598
            ],
            [
              -81.186114,
              31.568032
            ],
            [
              -81.183252,
              31.560058
            ],
            [
              -81.178822,
              31.55553
            ],
            [
              -81.173079,
              31.555908
            ],
            [
              -81.160364,
              31.570436
            ],
            [
              -81.14997,
              31.593476
            ],
            [
              -81.133493,
              31.623348
            ],
            [
              -81.131728,
              31.654484
            ],
            [
              -81.136408,
              31.674832
            ],
            [
              -81.135608,
              31.683491
            ],
            [
              -81.131137,
              31.695774
            ],
            [
              -81.139394,
              31.699917
            ],
            [
              -81.149369,
              31.699304
            ],
            [
              -81.151888,
              31.698411
            ],
            [
              -81.154624,
              31.693874
            ],
            [
              -81.161084,
              31.691401
            ],
            [
              -81.186303,
              31.701509
            ],
            [
              -81.203572,
              31.719448
            ],
            [
              -81.198394185031,
              31.7260700164189
            ],
            [
              -81.220862,
              31.743637
            ],
            [
              -81.259887,
              31.753754
            ],
            [
              -81.270743,
              31.766937
            ],
            [
              -81.268426,
              31.784616
            ],
            [
              -81.277426,
              31.794615
            ],
            [
              -81.311027,
              31.789915
            ],
            [
              -81.320028,
              31.808914
            ],
            [
              -81.316278,
              31.818092
            ],
            [
              -81.328224,
              31.823013
            ],
            [
              -81.333333,
              31.840468
            ],
            [
              -81.34477,
              31.823963
            ],
            [
              -81.355008,
              31.829746
            ],
            [
              -81.378097,
              31.848678
            ],
            [
              -81.404926,
              31.936868
            ],
            [
              -81.407737,
              31.944302
            ],
            [
              -81.426894,
              31.938677
            ],
            [
              -81.463844,
              31.950014
            ],
            [
              -81.476112,
              31.974302
            ],
            [
              -81.482971,
              31.966408
            ],
            [
              -81.510261,
              31.968998
            ],
            [
              -81.522323,
              31.958952
            ],
            [
              -81.564231,
              31.95949
            ],
            [
              -81.578712,
              31.97246
            ],
            [
              -81.593292,
              31.971233
            ],
            [
              -81.59423,
              31.991779
            ],
            [
              -81.603665,
              32.008469
            ],
            [
              -81.640251,
              32.049866
            ],
            [
              -81.651214,
              32.058352
            ],
            [
              -81.672798,
              32.063873
            ],
            [
              -81.676414,
              32.074478
            ],
            [
              -81.692695,
              32.088313
            ],
            [
              -81.718658,
              32.089351
            ],
            [
              -81.761735,
              32.0479
            ],
            [
              -81.813382,
              32.01623
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "161",
      "COUNTYNS": "01687999",
      "AFFGEOID": "0500000US13161",
      "GEOID": "13161",
      "NAME": "Jeff Davis",
      "LSAD": "06",
      "ALAND": 856690612,
      "AWATER": 11986249,
      "County_state": "Jeff Davis,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.836721,
              31.761826
            ],
            [
              -82.836633,
              31.671398
            ],
            [
              -82.814029,
              31.671419
            ],
            [
              -82.627342,
              31.672672
            ],
            [
              -82.521393,
              31.672512
            ],
            [
              -82.52142,
              31.710796
            ],
            [
              -82.520567,
              31.736201
            ],
            [
              -82.550714,
              31.736334
            ],
            [
              -82.550691,
              31.749112
            ],
            [
              -82.520158,
              31.74919
            ],
            [
              -82.51888,
              31.838393
            ],
            [
              -82.431362,
              31.837993
            ],
            [
              -82.431531,
              31.966182
            ],
            [
              -82.458152,
              31.96389
            ],
            [
              -82.479607,
              31.971653
            ],
            [
              -82.483131,
              31.968961
            ],
            [
              -82.499496,
              31.960567
            ],
            [
              -82.480224,
              31.954961
            ],
            [
              -82.491485,
              31.948678
            ],
            [
              -82.517077,
              31.957914
            ],
            [
              -82.533097,
              31.961351
            ],
            [
              -82.543655,
              31.958914
            ],
            [
              -82.544455,
              31.955887
            ],
            [
              -82.564956,
              31.957086
            ],
            [
              -82.596457,
              31.931985
            ],
            [
              -82.645659,
              31.918883
            ],
            [
              -82.65826,
              31.923583
            ],
            [
              -82.681763,
              31.920741
            ],
            [
              -82.67626,
              31.913182
            ],
            [
              -82.693961,
              31.898282
            ],
            [
              -82.699861,
              31.903582
            ],
            [
              -82.717562,
              31.885782
            ],
            [
              -82.751076,
              31.869214
            ],
            [
              -82.757192,
              31.857031
            ],
            [
              -82.772774,
              31.861677
            ],
            [
              -82.791937,
              31.846732
            ],
            [
              -82.79581,
              31.835096
            ],
            [
              -82.815291,
              31.818436
            ],
            [
              -82.836366,
              31.816154
            ],
            [
              -82.836721,
              31.761826
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "107",
      "COUNTYNS": "00347508",
      "AFFGEOID": "0500000US13107",
      "GEOID": "13107",
      "NAME": "Emanuel",
      "LSAD": "06",
      "ALAND": 1762766490,
      "AWATER": 24802077,
      "County_state": "Emanuel,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.623056,
              32.521566
            ],
            [
              -82.647733,
              32.512507
            ],
            [
              -82.618649,
              32.504966
            ],
            [
              -82.563046,
              32.505708
            ],
            [
              -82.534524,
              32.497158
            ],
            [
              -82.517486,
              32.499592
            ],
            [
              -82.490524,
              32.496273
            ],
            [
              -82.48929,
              32.492061
            ],
            [
              -82.42515,
              32.462283
            ],
            [
              -82.398031,
              32.451878
            ],
            [
              -82.38194,
              32.440596
            ],
            [
              -82.394316,
              32.411516
            ],
            [
              -82.396564,
              32.386961
            ],
            [
              -82.411201,
              32.365251
            ],
            [
              -82.409118,
              32.35383
            ],
            [
              -82.391679,
              32.335276
            ],
            [
              -82.378936,
              32.336902
            ],
            [
              -82.356378,
              32.313494
            ],
            [
              -82.354546,
              32.292194
            ],
            [
              -82.279845,
              32.294182
            ],
            [
              -82.232788,
              32.318838
            ],
            [
              -82.241993,
              32.347202
            ],
            [
              -82.254526,
              32.351498
            ],
            [
              -82.187944,
              32.433571
            ],
            [
              -82.183495,
              32.460141
            ],
            [
              -82.181145,
              32.474066
            ],
            [
              -82.148338,
              32.520429
            ],
            [
              -82.079842,
              32.560063
            ],
            [
              -82.030233,
              32.53877
            ],
            [
              -82.026842,
              32.555163
            ],
            [
              -82.001236,
              32.60691
            ],
            [
              -82.082871,
              32.663324
            ],
            [
              -82.144977,
              32.812741
            ],
            [
              -82.157638,
              32.816023
            ],
            [
              -82.213745,
              32.803863
            ],
            [
              -82.256299,
              32.823988
            ],
            [
              -82.30083,
              32.826763
            ],
            [
              -82.316512,
              32.835772
            ],
            [
              -82.320435,
              32.840033
            ],
            [
              -82.35482,
              32.827281
            ],
            [
              -82.361637,
              32.816394
            ],
            [
              -82.419129,
              32.810927
            ],
            [
              -82.398331,
              32.777445
            ],
            [
              -82.434149,
              32.762258
            ],
            [
              -82.452751,
              32.728659
            ],
            [
              -82.452751,
              32.71926
            ],
            [
              -82.470763,
              32.710608
            ],
            [
              -82.49856,
              32.684593
            ],
            [
              -82.490315,
              32.662035
            ],
            [
              -82.463854,
              32.647236
            ],
            [
              -82.492924,
              32.634046
            ],
            [
              -82.498921,
              32.642733
            ],
            [
              -82.517717,
              32.633677
            ],
            [
              -82.512002,
              32.625163
            ],
            [
              -82.533897,
              32.612887
            ],
            [
              -82.594329,
              32.578912
            ],
            [
              -82.589764,
              32.556133
            ],
            [
              -82.564358,
              32.532449
            ],
            [
              -82.585322,
              32.52523
            ],
            [
              -82.597855,
              32.532866
            ],
            [
              -82.60281,
              32.509523
            ],
            [
              -82.602955,
              32.521467
            ],
            [
              -82.623056,
              32.521566
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "127",
      "COUNTYNS": "01671513",
      "AFFGEOID": "0500000US13127",
      "GEOID": "13127",
      "NAME": "Glynn",
      "LSAD": "06",
      "ALAND": 1086361034,
      "AWATER": 429423812,
      "County_state": "Glynn,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.766872,
              31.201481
            ],
            [
              -81.780967,
              31.165272
            ],
            [
              -81.766322,
              31.169595
            ],
            [
              -81.763435,
              31.154087
            ],
            [
              -81.72229,
              31.148718
            ],
            [
              -81.713226,
              31.138674
            ],
            [
              -81.686225,
              31.122419
            ],
            [
              -81.662078,
              31.12554
            ],
            [
              -81.631624,
              31.108117
            ],
            [
              -81.622983,
              31.116317
            ],
            [
              -81.611701,
              31.113192
            ],
            [
              -81.577316,
              31.117235
            ],
            [
              -81.562606,
              31.130285
            ],
            [
              -81.558108,
              31.120681
            ],
            [
              -81.572692,
              31.097269
            ],
            [
              -81.556716,
              31.080807
            ],
            [
              -81.539764,
              31.083776
            ],
            [
              -81.526565,
              31.063194
            ],
            [
              -81.492454,
              31.054476
            ],
            [
              -81.486297,
              31.034595
            ],
            [
              -81.456893,
              31.037197
            ],
            [
              -81.44493,
              31.028994
            ],
            [
              -81.443170434232,
              31.0166613102744
            ],
            [
              -81.434923,
              31.017804
            ],
            [
              -81.43471,
              31.014641
            ],
            [
              -81.432475,
              31.012991
            ],
            [
              -81.424732,
              31.013678
            ],
            [
              -81.420474,
              31.016703
            ],
            [
              -81.415123,
              31.026718
            ],
            [
              -81.401267,
              31.072781
            ],
            [
              -81.401209,
              31.086143
            ],
            [
              -81.403732,
              31.107115
            ],
            [
              -81.402096,
              31.125383
            ],
            [
              -81.399677,
              31.134113
            ],
            [
              -81.38683,
              31.133214
            ],
            [
              -81.368241,
              31.136534
            ],
            [
              -81.359349,
              31.149166
            ],
            [
              -81.360791,
              31.155903
            ],
            [
              -81.35488,
              31.167204
            ],
            [
              -81.339028,
              31.186918
            ],
            [
              -81.314183,
              31.207938
            ],
            [
              -81.304957,
              31.206173
            ],
            [
              -81.293359,
              31.206332
            ],
            [
              -81.288403,
              31.211065
            ],
            [
              -81.289136,
              31.225487
            ],
            [
              -81.282842,
              31.24433
            ],
            [
              -81.276862,
              31.254734
            ],
            [
              -81.274688,
              31.289454
            ],
            [
              -81.2699059255329,
              31.294488935797197
            ],
            [
              -81.298232,
              31.292456
            ],
            [
              -81.319122,
              31.309172
            ],
            [
              -81.368804,
              31.318703
            ],
            [
              -81.382995,
              31.317108
            ],
            [
              -81.401534,
              31.295182
            ],
            [
              -81.412307,
              31.294733
            ],
            [
              -81.403465,
              31.307669
            ],
            [
              -81.435874,
              31.313263
            ],
            [
              -81.438888,
              31.332148
            ],
            [
              -81.454094,
              31.326415
            ],
            [
              -81.470687,
              31.339674
            ],
            [
              -81.493072,
              31.343452
            ],
            [
              -81.505546,
              31.35561
            ],
            [
              -81.534255,
              31.367805
            ],
            [
              -81.535903,
              31.3764
            ],
            [
              -81.550521,
              31.375572
            ],
            [
              -81.545669,
              31.384834
            ],
            [
              -81.577575,
              31.399429
            ],
            [
              -81.610549,
              31.434326
            ],
            [
              -81.607192,
              31.440111
            ],
            [
              -81.62429,
              31.452982
            ],
            [
              -81.628092,
              31.449865
            ],
            [
              -81.66484,
              31.399719
            ],
            [
              -81.692011,
              31.400491
            ],
            [
              -81.704778,
              31.374917
            ],
            [
              -81.725013,
              31.327101
            ],
            [
              -81.731694,
              31.330048
            ],
            [
              -81.732667,
              31.301345
            ],
            [
              -81.747198,
              31.281461
            ],
            [
              -81.757915,
              31.241925
            ],
            [
              -81.766872,
              31.201481
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "049",
      "COUNTYNS": "00357747",
      "AFFGEOID": "0500000US13049",
      "GEOID": "13049",
      "NAME": "Charlton",
      "LSAD": "06",
      "ALAND": 2003702672,
      "AWATER": 22676200,
      "County_state": "Charlton,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.415329,
              30.946802
            ],
            [
              -82.420442,
              30.795229
            ],
            [
              -82.17816,
              30.790953
            ],
            [
              -82.158398,
              30.786335
            ],
            [
              -82.149872,
              30.784336
            ],
            [
              -82.21484616135339,
              30.568508999232296
            ],
            [
              -82.214413,
              30.56695
            ],
            [
              -82.218607,
              30.564395
            ],
            [
              -82.223053,
              30.563202
            ],
            [
              -82.227282,
              30.561033
            ],
            [
              -82.231944,
              30.556262
            ],
            [
              -82.235631,
              30.544877
            ],
            [
              -82.235848,
              30.537178
            ],
            [
              -82.23498,
              30.533057
            ],
            [
              -82.23078,
              30.526749
            ],
            [
              -82.229427,
              30.520814
            ],
            [
              -82.230405,
              30.51733
            ],
            [
              -82.226961,
              30.510271
            ],
            [
              -82.225054,
              30.50782
            ],
            [
              -82.218541,
              30.504177
            ],
            [
              -82.212879,
              30.498741
            ],
            [
              -82.206472,
              30.491867
            ],
            [
              -82.201443,
              30.485154
            ],
            [
              -82.200965,
              30.474427
            ],
            [
              -82.204641,
              30.468857
            ],
            [
              -82.207735,
              30.460492
            ],
            [
              -82.207549,
              30.456916
            ],
            [
              -82.206067,
              30.455495
            ],
            [
              -82.20485,
              30.451828
            ],
            [
              -82.204002,
              30.444495
            ],
            [
              -82.206513,
              30.437069
            ],
            [
              -82.209897,
              30.432806
            ],
            [
              -82.210318,
              30.424577
            ],
            [
              -82.204177,
              30.401316
            ],
            [
              -82.192966,
              30.378765
            ],
            [
              -82.189873,
              30.375924
            ],
            [
              -82.183823,
              30.373698
            ],
            [
              -82.180043,
              30.36861
            ],
            [
              -82.171533,
              30.359854
            ],
            [
              -82.170079,
              30.358914
            ],
            [
              -82.165217,
              30.35802
            ],
            [
              -82.161782,
              30.357836
            ],
            [
              -82.158134,
              30.359898
            ],
            [
              -82.143306,
              30.363378
            ],
            [
              -82.116408,
              30.36732
            ],
            [
              -82.104857,
              30.368305
            ],
            [
              -82.102523,
              30.367809
            ],
            [
              -82.101439,
              30.366541
            ],
            [
              -82.101821,
              30.365321
            ],
            [
              -82.094709,
              30.360766
            ],
            [
              -82.081128,
              30.358791
            ],
            [
              -82.068554,
              30.359169
            ],
            [
              -82.060055,
              30.360313
            ],
            [
              -82.05009,
              30.362323
            ],
            [
              -82.04996655046949,
              30.3623762347418
            ],
            [
              -82.047938,
              30.363251
            ],
            [
              -82.040766,
              30.370144
            ],
            [
              -82.036845,
              30.37787
            ],
            [
              -82.035891,
              30.385273
            ],
            [
              -82.041185,
              30.396828
            ],
            [
              -82.042011,
              30.403253
            ],
            [
              -82.041185,
              30.409953
            ],
            [
              -82.039992,
              30.414267
            ],
            [
              -82.034025,
              30.422345
            ],
            [
              -82.034484,
              30.428036
            ],
            [
              -82.03723,
              30.434506
            ],
            [
              -82.036224,
              30.438448
            ],
            [
              -82.030084,
              30.444842
            ],
            [
              -82.028232,
              30.447385
            ],
            [
              -82.025477,
              30.457744
            ],
            [
              -82.023754,
              30.467278
            ],
            [
              -82.017799,
              30.475071
            ],
            [
              -82.017002,
              30.478769
            ],
            [
              -82.017317,
              30.487628
            ],
            [
              -82.018242,
              30.492075
            ],
            [
              -82.015912,
              30.495489
            ],
            [
              -82.01479,
              30.513
            ],
            [
              -82.015846,
              30.518157
            ],
            [
              -82.01701,
              30.519349
            ],
            [
              -82.018888,
              30.523819
            ],
            [
              -82.018381,
              30.531176
            ],
            [
              -82.013236,
              30.550083
            ],
            [
              -82.005497,
              30.563488
            ],
            [
              -82.008111,
              30.577011
            ],
            [
              -82.012129,
              30.593767
            ],
            [
              -82.015728,
              30.601698
            ],
            [
              -82.016523,
              30.602478
            ],
            [
              -82.026962,
              30.606147
            ],
            [
              -82.027359,
              30.60672
            ],
            [
              -82.026562,
              30.613298
            ],
            [
              -82.02852,
              30.621824
            ],
            [
              -82.033948,
              30.629598
            ],
            [
              -82.03763,
              30.633266
            ],
            [
              -82.039962,
              30.63714
            ],
            [
              -82.039113,
              30.641128
            ],
            [
              -82.039616,
              30.643305
            ],
            [
              -82.042293,
              30.649448
            ],
            [
              -82.046136,
              30.651763
            ],
            [
              -82.049529,
              30.655544
            ],
            [
              -82.050454,
              30.676263
            ],
            [
              -82.041834,
              30.692374
            ],
            [
              -82.036448,
              30.706583
            ],
            [
              -82.037585,
              30.718639
            ],
            [
              -82.039176,
              30.723177
            ],
            [
              -82.041032,
              30.725079
            ],
            [
              -82.043817,
              30.72964
            ],
            [
              -82.04119,
              30.734247
            ],
            [
              -82.040048,
              30.737547
            ],
            [
              -82.039656,
              30.747727
            ],
            [
              -82.038989,
              30.749262
            ],
            [
              -82.035986,
              30.750998
            ],
            [
              -82.032667,
              30.750674
            ],
            [
              -82.028421,
              30.750981
            ],
            [
              -82.017938,
              30.755263
            ],
            [
              -82.012681,
              30.761289
            ],
            [
              -82.011618,
              30.763122
            ],
            [
              -82.017902,
              30.775845
            ],
            [
              -82.024056,
              30.783157
            ],
            [
              -82.023869,
              30.786686
            ],
            [
              -82.022887,
              30.787992
            ],
            [
              -82.017072,
              30.791658
            ],
            [
              -82.007886,
              30.792938
            ],
            [
              -82.004994,
              30.791745
            ],
            [
              -81.994992,
              30.786074
            ],
            [
              -81.990875,
              30.781612
            ],
            [
              -81.988625,
              30.780057
            ],
            [
              -81.981293,
              30.776768
            ],
            [
              -81.979081,
              30.776416
            ],
            [
              -81.9789666841348,
              30.776461506719002
            ],
            [
              -81.973876,
              30.778488
            ],
            [
              -81.962553,
              30.796528
            ],
            [
              -81.962008,
              30.800445
            ],
            [
              -81.96246,
              30.808443
            ],
            [
              -81.962758,
              30.813638
            ],
            [
              -81.962194,
              30.818003
            ],
            [
              -81.959778,
              30.821171
            ],
            [
              -81.949806,
              30.827496
            ],
            [
              -81.943187,
              30.827437
            ],
            [
              -81.9384,
              30.825748
            ],
            [
              -81.935462,
              30.821134
            ],
            [
              -81.934673,
              30.820427
            ],
            [
              -81.924466,
              30.817568
            ],
            [
              -81.910943,
              30.815891
            ],
            [
              -81.906296,
              30.817018
            ],
            [
              -81.903762,
              30.818989
            ],
            [
              -81.902354,
              30.82082
            ],
            [
              -81.904855,
              30.82834
            ],
            [
              -81.892139,
              30.90988
            ],
            [
              -81.915128,
              30.912187
            ],
            [
              -81.899167,
              30.9456
            ],
            [
              -81.892028,
              30.960474
            ],
            [
              -81.91458,
              30.975981
            ],
            [
              -81.907116,
              30.999059
            ],
            [
              -81.906036,
              31.039715
            ],
            [
              -81.936006,
              31.047993
            ],
            [
              -82.06087,
              31.075809
            ],
            [
              -82.081055,
              31.011071
            ],
            [
              -82.087319,
              31.010443
            ],
            [
              -82.131698,
              31.010714
            ],
            [
              -82.342466,
              31.013429
            ],
            [
              -82.415603,
              31.01359
            ],
            [
              -82.415329,
              30.946802
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "001",
      "COUNTYNS": "00349113",
      "AFFGEOID": "0500000US13001",
      "GEOID": "13001",
      "NAME": "Appling",
      "LSAD": "06",
      "ALAND": 1313488286,
      "AWATER": 13263005,
      "County_state": "Appling,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.550691,
              31.749112
            ],
            [
              -82.550714,
              31.736334
            ],
            [
              -82.520567,
              31.736201
            ],
            [
              -82.52142,
              31.710796
            ],
            [
              -82.495834,
              31.710194
            ],
            [
              -82.45089,
              31.661381
            ],
            [
              -82.431855,
              31.658139
            ],
            [
              -82.407509,
              31.6392
            ],
            [
              -82.389152,
              31.637814
            ],
            [
              -82.348526,
              31.620661
            ],
            [
              -82.318077,
              31.594286
            ],
            [
              -82.269732,
              31.571882
            ],
            [
              -82.239639,
              31.554724
            ],
            [
              -82.226585,
              31.530699
            ],
            [
              -82.219044,
              31.518709
            ],
            [
              -82.155075,
              31.471116
            ],
            [
              -82.132794,
              31.471262
            ],
            [
              -82.132867,
              31.557514
            ],
            [
              -82.147926,
              31.557308
            ],
            [
              -82.147948,
              31.569123
            ],
            [
              -82.132942,
              31.56931
            ],
            [
              -82.132957,
              31.758148
            ],
            [
              -82.133013,
              31.773404
            ],
            [
              -82.089437,
              31.773434
            ],
            [
              -82.089338,
              31.785903
            ],
            [
              -82.107028,
              31.786101
            ],
            [
              -82.10654,
              31.799798
            ],
            [
              -82.087247,
              31.799509
            ],
            [
              -82.087242,
              31.821003
            ],
            [
              -82.074253,
              31.829296
            ],
            [
              -82.048582,
              31.827075
            ],
            [
              -82.107235,
              31.863797
            ],
            [
              -82.111818,
              31.900487
            ],
            [
              -82.116542,
              31.887201
            ],
            [
              -82.134843,
              31.891
            ],
            [
              -82.132943,
              31.9074
            ],
            [
              -82.142843,
              31.8993
            ],
            [
              -82.165843,
              31.903599
            ],
            [
              -82.190244,
              31.900499
            ],
            [
              -82.192044,
              31.906499
            ],
            [
              -82.225042,
              31.913074
            ],
            [
              -82.233129,
              31.919199
            ],
            [
              -82.24681,
              31.9151
            ],
            [
              -82.259705,
              31.932406
            ],
            [
              -82.291583,
              31.942398
            ],
            [
              -82.314482,
              31.930783
            ],
            [
              -82.323277,
              31.934495
            ],
            [
              -82.37242,
              31.941456
            ],
            [
              -82.372096,
              31.953976
            ],
            [
              -82.402242,
              31.960286
            ],
            [
              -82.407245,
              31.949488
            ],
            [
              -82.408046,
              31.955689
            ],
            [
              -82.431531,
              31.966182
            ],
            [
              -82.431362,
              31.837993
            ],
            [
              -82.51888,
              31.838393
            ],
            [
              -82.520158,
              31.74919
            ],
            [
              -82.550691,
              31.749112
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "233",
      "COUNTYNS": "00343585",
      "AFFGEOID": "0500000US13233",
      "GEOID": "13233",
      "NAME": "Polk",
      "LSAD": "06",
      "ALAND": 803751977,
      "AWATER": 4688357,
      "County_state": "Polk,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.4218824893099,
              34.0808162176071
            ],
            [
              -85.420263,
              34.072277
            ],
            [
              -85.406781,
              34.002313
            ],
            [
              -85.405951,
              34.000099
            ],
            [
              -85.3988712532846,
              33.9641292868502
            ],
            [
              -85.398871,
              33.964128
            ],
            [
              -85.38651002496731,
              33.901718559000095
            ],
            [
              -85.212791,
              33.899212
            ],
            [
              -85.212774,
              33.906302
            ],
            [
              -85.05031,
              33.904488
            ],
            [
              -85.049833,
              33.952635
            ],
            [
              -84.978683,
              33.951393
            ],
            [
              -84.928743,
              34.071698
            ],
            [
              -84.922742,
              34.082497
            ],
            [
              -85.047046,
              34.082881
            ],
            [
              -85.046871,
              34.096412
            ],
            [
              -85.121958,
              34.096749
            ],
            [
              -85.122472,
              34.083145
            ],
            [
              -85.157521,
              34.083463
            ],
            [
              -85.157361,
              34.097267
            ],
            [
              -85.210034,
              34.097623
            ],
            [
              -85.257624,
              34.100668
            ],
            [
              -85.25807,
              34.079133
            ],
            [
              -85.283318,
              34.079393
            ],
            [
              -85.4218824893099,
              34.0808162176071
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "247",
      "COUNTYNS": "00357592",
      "AFFGEOID": "0500000US13247",
      "GEOID": "13247",
      "NAME": "Rockdale",
      "LSAD": "06",
      "ALAND": 336212809,
      "AWATER": 5918272,
      "County_state": "Rockdale,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.184143,
              33.646157
            ],
            [
              -84.181584,
              33.629174
            ],
            [
              -84.171495,
              33.619954
            ],
            [
              -84.125073,
              33.561248
            ],
            [
              -84.097471,
              33.560009
            ],
            [
              -84.080583,
              33.554535
            ],
            [
              -84.04972,
              33.541081
            ],
            [
              -84.044493,
              33.525776
            ],
            [
              -84.032117,
              33.539408
            ],
            [
              -84.01462,
              33.559029
            ],
            [
              -84.010681,
              33.563312
            ],
            [
              -84.010452,
              33.563564
            ],
            [
              -83.988648,
              33.587748
            ],
            [
              -83.974921,
              33.60298
            ],
            [
              -83.951645,
              33.628773
            ],
            [
              -83.931597,
              33.650862
            ],
            [
              -83.931268,
              33.651259
            ],
            [
              -83.920548,
              33.691899
            ],
            [
              -83.925054,
              33.710515
            ],
            [
              -83.913499,
              33.712207
            ],
            [
              -83.914823,
              33.744203
            ],
            [
              -83.915623,
              33.744702
            ],
            [
              -83.982033,
              33.786054
            ],
            [
              -84.01879,
              33.756625
            ],
            [
              -84.023713,
              33.752808
            ],
            [
              -84.056614,
              33.726608
            ],
            [
              -84.06311,
              33.714127
            ],
            [
              -84.074409,
              33.692743
            ],
            [
              -84.07448,
              33.692609
            ],
            [
              -84.104334,
              33.636025
            ],
            [
              -84.115791,
              33.614666
            ],
            [
              -84.12922,
              33.63137
            ],
            [
              -84.150581,
              33.638577
            ],
            [
              -84.169986,
              33.639293
            ],
            [
              -84.184143,
              33.646157
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "057",
      "COUNTYNS": "01685718",
      "AFFGEOID": "0500000US13057",
      "GEOID": "13057",
      "NAME": "Cherokee",
      "LSAD": "06",
      "ALAND": 1090598900,
      "AWATER": 34469349,
      "County_state": "Cherokee,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.657717,
              34.202675
            ],
            [
              -84.659241,
              34.07824
            ],
            [
              -84.626842,
              34.07749
            ],
            [
              -84.624235,
              34.078799
            ],
            [
              -84.618238,
              34.081655
            ],
            [
              -84.588524,
              34.076601
            ],
            [
              -84.571859,
              34.076299
            ],
            [
              -84.571683,
              34.076299
            ],
            [
              -84.538597,
              34.075692
            ],
            [
              -84.521992,
              34.075399
            ],
            [
              -84.515431,
              34.078698
            ],
            [
              -84.484781,
              34.07471
            ],
            [
              -84.418927,
              34.073298
            ],
            [
              -84.418534,
              34.094259
            ],
            [
              -84.418428,
              34.109043
            ],
            [
              -84.41145,
              34.107975
            ],
            [
              -84.379295,
              34.118763
            ],
            [
              -84.362678,
              34.123527
            ],
            [
              -84.361332,
              34.137024
            ],
            [
              -84.352302,
              34.176717
            ],
            [
              -84.328263,
              34.186144
            ],
            [
              -84.282228,
              34.186146
            ],
            [
              -84.258743,
              34.185909
            ],
            [
              -84.258919,
              34.207317
            ],
            [
              -84.258689,
              34.237094
            ],
            [
              -84.258576,
              34.288206
            ],
            [
              -84.258075,
              34.335156
            ],
            [
              -84.257586,
              34.380992
            ],
            [
              -84.392124,
              34.380692
            ],
            [
              -84.400774,
              34.373266
            ],
            [
              -84.411969,
              34.380551
            ],
            [
              -84.424525,
              34.38707
            ],
            [
              -84.442126,
              34.388192
            ],
            [
              -84.442326,
              34.380392
            ],
            [
              -84.58263,
              34.381492
            ],
            [
              -84.58283,
              34.412191
            ],
            [
              -84.653232,
              34.41259
            ],
            [
              -84.654038,
              34.355176
            ],
            [
              -84.644293,
              34.355168
            ],
            [
              -84.653998,
              34.346934
            ],
            [
              -84.653744,
              34.227528
            ],
            [
              -84.657717,
              34.202675
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "121",
      "COUNTYNS": "01694833",
      "AFFGEOID": "0500000US13121",
      "GEOID": "13121",
      "NAME": "Fulton",
      "LSAD": "06",
      "ALAND": 1364081871,
      "AWATER": 19876730,
      "County_state": "Fulton,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.815869,
              33.520249
            ],
            [
              -84.850713,
              33.511457
            ],
            [
              -84.730537,
              33.51079
            ],
            [
              -84.721003,
              33.510895
            ],
            [
              -84.621031,
              33.510713
            ],
            [
              -84.60954,
              33.502511
            ],
            [
              -84.60567,
              33.511071
            ],
            [
              -84.570289,
              33.524918
            ],
            [
              -84.556857,
              33.528405
            ],
            [
              -84.483656,
              33.545732
            ],
            [
              -84.474343,
              33.547776
            ],
            [
              -84.458665,
              33.550933
            ],
            [
              -84.458556,
              33.594436
            ],
            [
              -84.458427,
              33.61877
            ],
            [
              -84.458053,
              33.629241
            ],
            [
              -84.457728,
              33.648477
            ],
            [
              -84.453028,
              33.641209
            ],
            [
              -84.419327,
              33.648609
            ],
            [
              -84.399446,
              33.648309
            ],
            [
              -84.350224,
              33.647908
            ],
            [
              -84.349414,
              33.680346
            ],
            [
              -84.349253,
              33.739117
            ],
            [
              -84.348962,
              33.773844
            ],
            [
              -84.348347,
              33.78187
            ],
            [
              -84.34838,
              33.783047
            ],
            [
              -84.348415,
              33.817394
            ],
            [
              -84.348506,
              33.825117
            ],
            [
              -84.348468,
              33.826353
            ],
            [
              -84.348091,
              33.845293
            ],
            [
              -84.348384,
              33.852541
            ],
            [
              -84.348309,
              33.853945
            ],
            [
              -84.348298,
              33.857486
            ],
            [
              -84.348089,
              33.88209
            ],
            [
              -84.348315,
              33.890903
            ],
            [
              -84.347913,
              33.915004
            ],
            [
              -84.347823,
              33.938017
            ],
            [
              -84.347725,
              33.961082
            ],
            [
              -84.338923,
              33.969778
            ],
            [
              -84.280497,
              33.95602
            ],
            [
              -84.277093,
              33.957614
            ],
            [
              -84.25847,
              33.968429
            ],
            [
              -84.262849,
              33.973676
            ],
            [
              -84.26308,
              33.986588
            ],
            [
              -84.254669,
              33.9861
            ],
            [
              -84.233349,
              34.001658
            ],
            [
              -84.233324,
              34.001666
            ],
            [
              -84.212489,
              34.003785
            ],
            [
              -84.203348,
              33.989701
            ],
            [
              -84.18102,
              33.998922
            ],
            [
              -84.169166,
              34.028344
            ],
            [
              -84.123465,
              34.033489
            ],
            [
              -84.097693,
              34.050708
            ],
            [
              -84.143442,
              34.055315
            ],
            [
              -84.178344,
              34.070402
            ],
            [
              -84.19043,
              34.086317
            ],
            [
              -84.205837,
              34.090603
            ],
            [
              -84.205969,
              34.090679
            ],
            [
              -84.216177,
              34.099819
            ],
            [
              -84.227465,
              34.097143
            ],
            [
              -84.258934,
              34.109539
            ],
            [
              -84.258743,
              34.185909
            ],
            [
              -84.282228,
              34.186146
            ],
            [
              -84.328263,
              34.186144
            ],
            [
              -84.352302,
              34.176717
            ],
            [
              -84.361332,
              34.137024
            ],
            [
              -84.362678,
              34.123527
            ],
            [
              -84.379295,
              34.118763
            ],
            [
              -84.41145,
              34.107975
            ],
            [
              -84.418428,
              34.109043
            ],
            [
              -84.418534,
              34.094259
            ],
            [
              -84.418927,
              34.073298
            ],
            [
              -84.419028,
              34.061374
            ],
            [
              -84.411007,
              34.049445
            ],
            [
              -84.408386,
              34.03798
            ],
            [
              -84.403598,
              34.036472
            ],
            [
              -84.401235,
              34.014396
            ],
            [
              -84.383329,
              33.992804
            ],
            [
              -84.374772,
              33.986626
            ],
            [
              -84.383058,
              33.966921
            ],
            [
              -84.388655,
              33.958041
            ],
            [
              -84.398027,
              33.95121
            ],
            [
              -84.405177,
              33.943734
            ],
            [
              -84.447394,
              33.909935
            ],
            [
              -84.442708,
              33.901543
            ],
            [
              -84.441416,
              33.890151
            ],
            [
              -84.443087,
              33.879216
            ],
            [
              -84.447173,
              33.874757
            ],
            [
              -84.455929,
              33.864703
            ],
            [
              -84.454367,
              33.858764
            ],
            [
              -84.459829,
              33.853204
            ],
            [
              -84.455493,
              33.826808
            ],
            [
              -84.455876,
              33.82577
            ],
            [
              -84.457881,
              33.824578
            ],
            [
              -84.46843,
              33.827404
            ],
            [
              -84.480134,
              33.817319
            ],
            [
              -84.495332,
              33.808404
            ],
            [
              -84.515411,
              33.790334
            ],
            [
              -84.529131,
              33.783306
            ],
            [
              -84.538033,
              33.771104
            ],
            [
              -84.578132,
              33.743507
            ],
            [
              -84.585432,
              33.730507
            ],
            [
              -84.605188,
              33.717094
            ],
            [
              -84.632649,
              33.699286
            ],
            [
              -84.630117,
              33.693116
            ],
            [
              -84.652733,
              33.683609
            ],
            [
              -84.654607,
              33.662858
            ],
            [
              -84.661433,
              33.657009
            ],
            [
              -84.677133,
              33.655409
            ],
            [
              -84.730034,
              33.63651
            ],
            [
              -84.735712,
              33.624613
            ],
            [
              -84.741775,
              33.623763
            ],
            [
              -84.752735,
              33.63021
            ],
            [
              -84.763097,
              33.614211
            ],
            [
              -84.783292,
              33.603572
            ],
            [
              -84.805655,
              33.58642
            ],
            [
              -84.808934,
              33.574085
            ],
            [
              -84.832336,
              33.545267
            ],
            [
              -84.815869,
              33.520249
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "125",
      "COUNTYNS": "00345642",
      "AFFGEOID": "0500000US13125",
      "GEOID": "13125",
      "NAME": "Glascock",
      "LSAD": "06",
      "ALAND": 372284127,
      "AWATER": 1847843,
      "County_state": "Glascock,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.755846,
              33.25344
            ],
            [
              -82.746191,
              33.246105
            ],
            [
              -82.748311,
              33.238348
            ],
            [
              -82.733438,
              33.208123
            ],
            [
              -82.739612,
              33.174709
            ],
            [
              -82.719041,
              33.17005
            ],
            [
              -82.708713,
              33.15204
            ],
            [
              -82.661917,
              33.126331
            ],
            [
              -82.589096,
              33.123555
            ],
            [
              -82.578816,
              33.11984
            ],
            [
              -82.57441,
              33.156357
            ],
            [
              -82.585114,
              33.171231
            ],
            [
              -82.470899,
              33.250124
            ],
            [
              -82.431957,
              33.27483
            ],
            [
              -82.496847,
              33.302991
            ],
            [
              -82.559753,
              33.32727
            ],
            [
              -82.591698,
              33.320985
            ],
            [
              -82.615612,
              33.306809
            ],
            [
              -82.645593,
              33.301448
            ],
            [
              -82.679159,
              33.273926
            ],
            [
              -82.702577,
              33.272883
            ],
            [
              -82.732751,
              33.256489
            ],
            [
              -82.755846,
              33.25344
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "063",
      "COUNTYNS": "01672399",
      "AFFGEOID": "0500000US13063",
      "GEOID": "13063",
      "NAME": "Clayton",
      "LSAD": "06",
      "ALAND": 366881210,
      "AWATER": 7038080,
      "County_state": "Clayton,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.458556,
              33.594436
            ],
            [
              -84.458665,
              33.550933
            ],
            [
              -84.439448,
              33.55072
            ],
            [
              -84.433599,
              33.524445
            ],
            [
              -84.427384,
              33.516941
            ],
            [
              -84.420428,
              33.493992
            ],
            [
              -84.397479,
              33.485738
            ],
            [
              -84.381639,
              33.463043
            ],
            [
              -84.384772,
              33.458713
            ],
            [
              -84.388737,
              33.447937
            ],
            [
              -84.383744,
              33.444407
            ],
            [
              -84.402196,
              33.436368
            ],
            [
              -84.402138,
              33.425278
            ],
            [
              -84.384087,
              33.40247
            ],
            [
              -84.394809,
              33.394368
            ],
            [
              -84.396336,
              33.37326
            ],
            [
              -84.388118,
              33.352465
            ],
            [
              -84.3544,
              33.352514
            ],
            [
              -84.353824,
              33.397291
            ],
            [
              -84.353749,
              33.420537
            ],
            [
              -84.353584,
              33.436165
            ],
            [
              -84.29468,
              33.435715
            ],
            [
              -84.294383,
              33.447367
            ],
            [
              -84.294151,
              33.484325
            ],
            [
              -84.283913,
              33.484341
            ],
            [
              -84.283173,
              33.511444
            ],
            [
              -84.282524,
              33.532879
            ],
            [
              -84.292557,
              33.532981
            ],
            [
              -84.283879,
              33.549078
            ],
            [
              -84.263702,
              33.548923
            ],
            [
              -84.263393,
              33.569333
            ],
            [
              -84.263292,
              33.574885
            ],
            [
              -84.243897,
              33.590294
            ],
            [
              -84.26297,
              33.606953
            ],
            [
              -84.262566,
              33.631004
            ],
            [
              -84.281273,
              33.647411
            ],
            [
              -84.336336,
              33.647508
            ],
            [
              -84.350224,
              33.647908
            ],
            [
              -84.399446,
              33.648309
            ],
            [
              -84.419327,
              33.648609
            ],
            [
              -84.453028,
              33.641209
            ],
            [
              -84.457728,
              33.648477
            ],
            [
              -84.458053,
              33.629241
            ],
            [
              -84.458427,
              33.61877
            ],
            [
              -84.458556,
              33.594436
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "133",
      "COUNTYNS": "00348672",
      "AFFGEOID": "0500000US13133",
      "GEOID": "13133",
      "NAME": "Greene",
      "LSAD": "06",
      "ALAND": 1003451710,
      "AWATER": 48630003,
      "County_state": "Greene,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.406189,
              33.698307
            ],
            [
              -83.394913,
              33.690514
            ],
            [
              -83.383995,
              33.66658
            ],
            [
              -83.370514,
              33.655914
            ],
            [
              -83.359543,
              33.62233
            ],
            [
              -83.34687,
              33.600498
            ],
            [
              -83.326355,
              33.584202
            ],
            [
              -83.310847,
              33.562981
            ],
            [
              -83.286591,
              33.542675
            ],
            [
              -83.269213,
              33.533226
            ],
            [
              -83.277611,
              33.52763
            ],
            [
              -83.283928,
              33.506237
            ],
            [
              -83.27681,
              33.494198
            ],
            [
              -83.279931,
              33.483438
            ],
            [
              -83.274864,
              33.464115
            ],
            [
              -83.257645,
              33.456668
            ],
            [
              -83.262894,
              33.443687
            ],
            [
              -83.239708,
              33.418373
            ],
            [
              -83.244186,
              33.399234
            ],
            [
              -83.227035,
              33.404488
            ],
            [
              -83.212662,
              33.400394
            ],
            [
              -83.224564,
              33.380423
            ],
            [
              -83.197899,
              33.395321
            ],
            [
              -83.169968,
              33.370674
            ],
            [
              -83.164207,
              33.35503
            ],
            [
              -83.012853,
              33.469178
            ],
            [
              -82.985588,
              33.489841
            ],
            [
              -82.986035,
              33.520356
            ],
            [
              -83.014038,
              33.580383
            ],
            [
              -82.989899,
              33.603279
            ],
            [
              -83.00161,
              33.620243
            ],
            [
              -82.987724,
              33.628941
            ],
            [
              -82.980102,
              33.614716
            ],
            [
              -82.970512,
              33.641727
            ],
            [
              -82.950752,
              33.661071
            ],
            [
              -82.963678,
              33.676761
            ],
            [
              -82.978241,
              33.679888
            ],
            [
              -82.995602,
              33.693583
            ],
            [
              -83.015274,
              33.69112
            ],
            [
              -83.049141,
              33.715561
            ],
            [
              -83.118729,
              33.698818
            ],
            [
              -83.28034,
              33.761774
            ],
            [
              -83.297394,
              33.739059
            ],
            [
              -83.29145,
              33.734315
            ],
            [
              -83.406189,
              33.698307
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "123",
      "COUNTYNS": "00351260",
      "AFFGEOID": "0500000US13123",
      "GEOID": "13123",
      "NAME": "Gilmer",
      "LSAD": "06",
      "ALAND": 1103942496,
      "AWATER": 12227724,
      "County_state": "Gilmer,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.657098,
              34.728905
            ],
            [
              -84.654523,
              34.583187
            ],
            [
              -84.654366,
              34.548946
            ],
            [
              -84.52139,
              34.550463
            ],
            [
              -84.50261,
              34.563681
            ],
            [
              -84.502812,
              34.550476
            ],
            [
              -84.476727,
              34.550128
            ],
            [
              -84.485798,
              34.563267
            ],
            [
              -84.467989,
              34.563488
            ],
            [
              -84.467863,
              34.549967
            ],
            [
              -84.437226,
              34.54935
            ],
            [
              -84.371352,
              34.548495
            ],
            [
              -84.371496,
              34.562912
            ],
            [
              -84.345553,
              34.562735
            ],
            [
              -84.334938,
              34.580162
            ],
            [
              -84.307068,
              34.581398
            ],
            [
              -84.295059,
              34.570488
            ],
            [
              -84.255112,
              34.568333
            ],
            [
              -84.254677,
              34.597496
            ],
            [
              -84.223907,
              34.596992
            ],
            [
              -84.196754,
              34.617924
            ],
            [
              -84.193887,
              34.638834
            ],
            [
              -84.226471,
              34.653878
            ],
            [
              -84.242624,
              34.670908
            ],
            [
              -84.252292,
              34.66461
            ],
            [
              -84.252167,
              34.718161
            ],
            [
              -84.314653,
              34.807328
            ],
            [
              -84.328065,
              34.806403
            ],
            [
              -84.343661,
              34.824781
            ],
            [
              -84.354218,
              34.825019
            ],
            [
              -84.417421,
              34.849816
            ],
            [
              -84.422593,
              34.857456
            ],
            [
              -84.618637,
              34.855398
            ],
            [
              -84.582306,
              34.825141
            ],
            [
              -84.609749,
              34.790937
            ],
            [
              -84.627348,
              34.784728
            ],
            [
              -84.641463,
              34.777402
            ],
            [
              -84.635647,
              34.772186
            ],
            [
              -84.657098,
              34.728905
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "299",
      "COUNTYNS": "00357722",
      "AFFGEOID": "0500000US13299",
      "GEOID": "13299",
      "NAME": "Ware",
      "LSAD": "06",
      "ALAND": 2312599086,
      "AWATER": 39076860,
      "County_state": "Ware,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.699206,
              31.278104
            ],
            [
              -82.70105,
              31.223217
            ],
            [
              -82.690838,
              31.223364
            ],
            [
              -82.671669,
              31.183739
            ],
            [
              -82.666473,
              31.172923
            ],
            [
              -82.592071,
              31.018487
            ],
            [
              -82.550339,
              31.000404
            ],
            [
              -82.520124,
              30.963132
            ],
            [
              -82.490558,
              30.963166
            ],
            [
              -82.492204,
              30.832564
            ],
            [
              -82.495476,
              30.819553
            ],
            [
              -82.435852,
              30.820068
            ],
            [
              -82.435242,
              30.729149
            ],
            [
              -82.420128,
              30.729198
            ],
            [
              -82.41999,
              30.716637
            ],
            [
              -82.435112,
              30.716566
            ],
            [
              -82.428902,
              30.690317
            ],
            [
              -82.430862,
              30.612848
            ],
            [
              -82.418793,
              30.613132
            ],
            [
              -82.4189159951919,
              30.5817349193742
            ],
            [
              -82.374877,
              30.578996
            ],
            [
              -82.287373,
              30.573451
            ],
            [
              -82.258129,
              30.571552
            ],
            [
              -82.24987,
              30.570856
            ],
            [
              -82.214867,
              30.568584
            ],
            [
              -82.21484616135339,
              30.568508999232296
            ],
            [
              -82.149872,
              30.784336
            ],
            [
              -82.158398,
              30.786335
            ],
            [
              -82.17816,
              30.790953
            ],
            [
              -82.420442,
              30.795229
            ],
            [
              -82.415329,
              30.946802
            ],
            [
              -82.415603,
              31.01359
            ],
            [
              -82.342466,
              31.013429
            ],
            [
              -82.131698,
              31.010714
            ],
            [
              -82.163566,
              31.048559
            ],
            [
              -82.208262,
              31.084768
            ],
            [
              -82.208732,
              31.170938
            ],
            [
              -82.2341,
              31.170383
            ],
            [
              -82.251762,
              31.18273
            ],
            [
              -82.284256,
              31.192196
            ],
            [
              -82.284561,
              31.224449
            ],
            [
              -82.31504,
              31.225958
            ],
            [
              -82.32247,
              31.238242
            ],
            [
              -82.357979,
              31.263348
            ],
            [
              -82.374603,
              31.290165
            ],
            [
              -82.383705,
              31.291143
            ],
            [
              -82.390217,
              31.310733
            ],
            [
              -82.389451,
              31.364905
            ],
            [
              -82.402633,
              31.364986
            ],
            [
              -82.402631,
              31.378775
            ],
            [
              -82.416987,
              31.378856
            ],
            [
              -82.416985,
              31.390862
            ],
            [
              -82.402554,
              31.390866
            ],
            [
              -82.402561,
              31.404835
            ],
            [
              -82.417412,
              31.404988
            ],
            [
              -82.417246,
              31.417119
            ],
            [
              -82.48146,
              31.417534
            ],
            [
              -82.481438,
              31.443075
            ],
            [
              -82.49365,
              31.442892
            ],
            [
              -82.494718,
              31.46869
            ],
            [
              -82.597509,
              31.469293
            ],
            [
              -82.628181,
              31.469351
            ],
            [
              -82.628975,
              31.36393
            ],
            [
              -82.629622,
              31.274726
            ],
            [
              -82.663584,
              31.287913
            ],
            [
              -82.699206,
              31.278104
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "147",
      "COUNTYNS": "01687995",
      "AFFGEOID": "0500000US13147",
      "GEOID": "13147",
      "NAME": "Hart",
      "LSAD": "06",
      "ALAND": 601908709,
      "AWATER": 63583341,
      "County_state": "Hart,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.11336,
              34.27353
            ],
            [
              -83.115847,
              34.262125
            ],
            [
              -83.078004,
              34.223606
            ],
            [
              -83.018785,
              34.234085
            ],
            [
              -82.988367,
              34.224436
            ],
            [
              -82.99638,
              34.21911
            ],
            [
              -82.980018,
              34.210066
            ],
            [
              -82.958799,
              34.218539
            ],
            [
              -82.915535,
              34.247684
            ],
            [
              -82.82401,
              34.27054
            ],
            [
              -82.7737024263561,
              34.288744030212904
            ],
            [
              -82.780308,
              34.296701
            ],
            [
              -82.781752,
              34.302901
            ],
            [
              -82.78684,
              34.310381
            ],
            [
              -82.790966,
              34.32355
            ],
            [
              -82.791608,
              34.327428
            ],
            [
              -82.791235,
              34.331048
            ],
            [
              -82.794054,
              34.339772
            ],
            [
              -82.795223,
              34.34096
            ],
            [
              -82.798198,
              34.341317
            ],
            [
              -82.809949,
              34.349998
            ],
            [
              -82.8157146886963,
              34.35379613833349
            ],
            [
              -82.82342,
              34.358872
            ],
            [
              -82.833702,
              34.364242
            ],
            [
              -82.835004,
              34.366069
            ],
            [
              -82.835413,
              34.369177
            ],
            [
              -82.835203,
              34.373899
            ],
            [
              -82.836611,
              34.382676
            ],
            [
              -82.841524,
              34.39013
            ],
            [
              -82.841997,
              34.392503
            ],
            [
              -82.841326,
              34.397332
            ],
            [
              -82.841997,
              34.399766
            ],
            [
              -82.8465977180057,
              34.4101368238693
            ],
            [
              -82.847446,
              34.412049
            ],
            [
              -82.847781,
              34.420571
            ],
            [
              -82.848651,
              34.423844
            ],
            [
              -82.851367,
              34.426812
            ],
            [
              -82.854434,
              34.432275
            ],
            [
              -82.855762,
              34.443977
            ],
            [
              -82.860127,
              34.449707
            ],
            [
              -82.860874,
              34.451469
            ],
            [
              -82.860707,
              34.457428
            ],
            [
              -82.862156,
              34.458748
            ],
            [
              -82.865345,
              34.460319
            ],
            [
              -82.875463,
              34.463503
            ],
            [
              -82.876464,
              34.465803
            ],
            [
              -82.875864,
              34.468003
            ],
            [
              -82.874864,
              34.468891
            ],
            [
              -82.873831,
              34.471508
            ],
            [
              -82.876864,
              34.475303
            ],
            [
              -82.882864,
              34.479003
            ],
            [
              -82.902665,
              34.485902
            ],
            [
              -82.908365,
              34.485402
            ],
            [
              -82.922866,
              34.481402
            ],
            [
              -82.925766,
              34.481802
            ],
            [
              -82.928466,
              34.484202
            ],
            [
              -82.940867,
              34.486102
            ],
            [
              -82.947367,
              34.479602
            ],
            [
              -82.954667,
              34.477302
            ],
            [
              -82.960668,
              34.482002
            ],
            [
              -82.979568,
              34.482702
            ],
            [
              -82.9922154353933,
              34.4791982077022
            ],
            [
              -82.992671,
              34.479072
            ],
            [
              -82.995279,
              34.475648
            ],
            [
              -82.99509,
              34.472483
            ],
            [
              -83.002924,
              34.472132
            ],
            [
              -83.029315,
              34.484147
            ],
            [
              -83.032513,
              34.483032
            ],
            [
              -83.034712,
              34.483495
            ],
            [
              -83.043771,
              34.488816
            ],
            [
              -83.048289,
              34.493254
            ],
            [
              -83.0494672080646,
              34.495092874783005
            ],
            [
              -83.094093,
              34.416073
            ],
            [
              -83.107063,
              34.344669
            ],
            [
              -83.094711,
              34.347023
            ],
            [
              -83.105934,
              34.278912
            ],
            [
              -83.11336,
              34.27353
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "231",
      "COUNTYNS": "00346482",
      "AFFGEOID": "0500000US13231",
      "GEOID": "13231",
      "NAME": "Pike",
      "LSAD": "06",
      "ALAND": 559659548,
      "AWATER": 8596853,
      "County_state": "Pike,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.536991,
              33.03673
            ],
            [
              -84.536832,
              33.009359
            ],
            [
              -84.527153,
              32.999785
            ],
            [
              -84.52702,
              32.970548
            ],
            [
              -84.505721,
              32.972551
            ],
            [
              -84.490015,
              32.993487
            ],
            [
              -84.27014,
              32.991011
            ],
            [
              -84.269169,
              33.031438
            ],
            [
              -84.248659,
              33.03933
            ],
            [
              -84.248133,
              33.075488
            ],
            [
              -84.248185,
              33.189019
            ],
            [
              -84.26834,
              33.189234
            ],
            [
              -84.373685,
              33.189625
            ],
            [
              -84.373141,
              33.20036
            ],
            [
              -84.39363,
              33.200271
            ],
            [
              -84.393467,
              33.208311
            ],
            [
              -84.451336,
              33.208889
            ],
            [
              -84.455285,
              33.202636
            ],
            [
              -84.475458,
              33.20064
            ],
            [
              -84.481302,
              33.18846
            ],
            [
              -84.496783,
              33.183866
            ],
            [
              -84.507397,
              33.175269
            ],
            [
              -84.515351,
              33.147881
            ],
            [
              -84.511315,
              33.125914
            ],
            [
              -84.518188,
              33.122155
            ],
            [
              -84.51148,
              33.086016
            ],
            [
              -84.52515,
              33.078463
            ],
            [
              -84.5276,
              33.044156
            ],
            [
              -84.536991,
              33.03673
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "227",
      "COUNTYNS": "01673011",
      "AFFGEOID": "0500000US13227",
      "GEOID": "13227",
      "NAME": "Pickens",
      "LSAD": "06",
      "ALAND": 601027940,
      "AWATER": 1693768,
      "County_state": "Pickens,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.654366,
              34.548946
            ],
            [
              -84.653291,
              34.463197
            ],
            [
              -84.644432,
              34.455789
            ],
            [
              -84.653733,
              34.455789
            ],
            [
              -84.653232,
              34.41259
            ],
            [
              -84.58283,
              34.412191
            ],
            [
              -84.58263,
              34.381492
            ],
            [
              -84.442326,
              34.380392
            ],
            [
              -84.442126,
              34.388192
            ],
            [
              -84.424525,
              34.38707
            ],
            [
              -84.411969,
              34.380551
            ],
            [
              -84.400774,
              34.373266
            ],
            [
              -84.392124,
              34.380692
            ],
            [
              -84.257586,
              34.380992
            ],
            [
              -84.256876,
              34.46564
            ],
            [
              -84.319679,
              34.467857
            ],
            [
              -84.345659,
              34.54841
            ],
            [
              -84.345553,
              34.562735
            ],
            [
              -84.371496,
              34.562912
            ],
            [
              -84.371352,
              34.548495
            ],
            [
              -84.437226,
              34.54935
            ],
            [
              -84.467863,
              34.549967
            ],
            [
              -84.467989,
              34.563488
            ],
            [
              -84.485798,
              34.563267
            ],
            [
              -84.476727,
              34.550128
            ],
            [
              -84.502812,
              34.550476
            ],
            [
              -84.50261,
              34.563681
            ],
            [
              -84.52139,
              34.550463
            ],
            [
              -84.654366,
              34.548946
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "141",
      "COUNTYNS": "00348209",
      "AFFGEOID": "0500000US13141",
      "GEOID": "13141",
      "NAME": "Hancock",
      "LSAD": "06",
      "ALAND": 1220058027,
      "AWATER": 17479750,
      "County_state": "Hancock,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.274108,
              33.187238
            ],
            [
              -83.263658,
              33.189093
            ],
            [
              -83.195373,
              33.176336
            ],
            [
              -83.097515,
              33.15123
            ],
            [
              -83.09886,
              33.13297
            ],
            [
              -83.109152,
              33.129829
            ],
            [
              -83.113962,
              33.094895
            ],
            [
              -83.118935,
              33.083069
            ],
            [
              -83.108712,
              33.071429
            ],
            [
              -83.10131,
              33.050207
            ],
            [
              -83.083524,
              33.063901
            ],
            [
              -83.07346,
              33.064103
            ],
            [
              -83.052197,
              33.080682
            ],
            [
              -83.00874,
              33.080849
            ],
            [
              -82.990936,
              33.090688
            ],
            [
              -82.970138,
              33.10928
            ],
            [
              -82.950596,
              33.107583
            ],
            [
              -82.926674,
              33.11627
            ],
            [
              -82.914519,
              33.126792
            ],
            [
              -82.900998,
              33.128537
            ],
            [
              -82.888866,
              33.138603
            ],
            [
              -82.867717,
              33.178403
            ],
            [
              -82.855046,
              33.196427
            ],
            [
              -82.832047,
              33.201994
            ],
            [
              -82.825928,
              33.210501
            ],
            [
              -82.798866,
              33.221707
            ],
            [
              -82.777995,
              33.238428
            ],
            [
              -82.748311,
              33.238348
            ],
            [
              -82.746191,
              33.246105
            ],
            [
              -82.755846,
              33.25344
            ],
            [
              -82.785591,
              33.268726
            ],
            [
              -82.781574,
              33.304467
            ],
            [
              -82.79245,
              33.309413
            ],
            [
              -82.802916,
              33.365035
            ],
            [
              -82.821706,
              33.374268
            ],
            [
              -82.825807,
              33.39043
            ],
            [
              -82.824105,
              33.427627
            ],
            [
              -82.839663,
              33.43094
            ],
            [
              -82.851954,
              33.443543
            ],
            [
              -82.872597,
              33.446416
            ],
            [
              -82.901795,
              33.438788
            ],
            [
              -82.93966,
              33.442694
            ],
            [
              -82.951222,
              33.451787
            ],
            [
              -82.983829,
              33.447635
            ],
            [
              -83.012853,
              33.469178
            ],
            [
              -83.164207,
              33.35503
            ],
            [
              -83.144121,
              33.339866
            ],
            [
              -83.145856,
              33.310045
            ],
            [
              -83.178444,
              33.292046
            ],
            [
              -83.230031,
              33.276054
            ],
            [
              -83.228674,
              33.25679
            ],
            [
              -83.253461,
              33.25929
            ],
            [
              -83.254359,
              33.243538
            ],
            [
              -83.252396,
              33.238054
            ],
            [
              -83.274108,
              33.187238
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "307",
      "COUNTYNS": "00352287",
      "AFFGEOID": "0500000US13307",
      "GEOID": "13307",
      "NAME": "Webster",
      "LSAD": "06",
      "ALAND": 541613279,
      "AWATER": 2965865,
      "County_state": "Webster,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.655013,
              31.961534
            ],
            [
              -84.65582,
              31.920308
            ],
            [
              -84.599776,
              31.920171
            ],
            [
              -84.45331,
              31.919224
            ],
            [
              -84.445248,
              31.926133
            ],
            [
              -84.443807,
              31.967456
            ],
            [
              -84.443802,
              31.968982
            ],
            [
              -84.433014,
              32.04196
            ],
            [
              -84.431214,
              32.134058
            ],
            [
              -84.527117,
              32.134556
            ],
            [
              -84.527417,
              32.151156
            ],
            [
              -84.546117,
              32.159056
            ],
            [
              -84.545692,
              32.175232
            ],
            [
              -84.56469,
              32.175136
            ],
            [
              -84.564316,
              32.189847
            ],
            [
              -84.600523,
              32.190736
            ],
            [
              -84.600692,
              32.210247
            ],
            [
              -84.620621,
              32.233055
            ],
            [
              -84.649319,
              32.232953
            ],
            [
              -84.64932,
              32.225153
            ],
            [
              -84.64032,
              32.225253
            ],
            [
              -84.63112,
              32.184054
            ],
            [
              -84.64242,
              32.160154
            ],
            [
              -84.644419,
              32.028858
            ],
            [
              -84.654119,
              32.028458
            ],
            [
              -84.655173,
              31.984355
            ],
            [
              -84.644805,
              31.984316
            ],
            [
              -84.645357,
              31.961495
            ],
            [
              -84.655013,
              31.961534
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "101",
      "COUNTYNS": "00348092",
      "AFFGEOID": "0500000US13101",
      "GEOID": "13101",
      "NAME": "Echols",
      "LSAD": "06",
      "ALAND": 1074571647,
      "AWATER": 15087403,
      "County_state": "Echols,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.133009,
              30.789649
            ],
            [
              -83.1366140447318,
              30.623976460785002
            ],
            [
              -83.131431,
              30.623575
            ],
            [
              -82.878831,
              30.609074
            ],
            [
              -82.877311,
              30.609016
            ],
            [
              -82.698947,
              30.598263
            ],
            [
              -82.698663,
              30.598224
            ],
            [
              -82.6895370493431,
              30.597723132054398
            ],
            [
              -82.689316,
              30.597711
            ],
            [
              -82.58400213818601,
              30.5917854612315
            ],
            [
              -82.582778,
              30.658075
            ],
            [
              -82.586463,
              30.675048
            ],
            [
              -82.581202,
              30.696237
            ],
            [
              -82.604005,
              30.713676
            ],
            [
              -82.637361,
              30.717135
            ],
            [
              -82.674819,
              30.725316
            ],
            [
              -82.697921,
              30.734207
            ],
            [
              -82.709976,
              30.729234
            ],
            [
              -82.743934,
              30.733516
            ],
            [
              -82.766159,
              30.732404
            ],
            [
              -82.795356,
              30.74209
            ],
            [
              -82.80529,
              30.749835
            ],
            [
              -82.815963,
              30.7761
            ],
            [
              -82.830543,
              30.781375
            ],
            [
              -82.834831,
              30.799451
            ],
            [
              -82.830482,
              30.808776
            ],
            [
              -82.845909,
              30.827198
            ],
            [
              -82.846353,
              30.834988
            ],
            [
              -82.971336,
              30.869392
            ],
            [
              -83.006577,
              30.85947
            ],
            [
              -83.013962,
              30.844709
            ],
            [
              -83.019419,
              30.849453
            ],
            [
              -83.088082,
              30.848658
            ],
            [
              -83.088099,
              30.831193
            ],
            [
              -83.103223,
              30.829582
            ],
            [
              -83.104365,
              30.803703
            ],
            [
              -83.125797,
              30.803306
            ],
            [
              -83.133009,
              30.789649
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "313",
      "COUNTYNS": "00353513",
      "AFFGEOID": "0500000US13313",
      "GEOID": "13313",
      "NAME": "Whitfield",
      "LSAD": "06",
      "ALAND": 752293512,
      "AWATER": 1533754,
      "County_state": "Whitfield,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.144789,
              34.767639
            ],
            [
              -85.168851,
              34.720279
            ],
            [
              -85.090009,
              34.720114
            ],
            [
              -85.050847,
              34.71998
            ],
            [
              -85.050446,
              34.622482
            ],
            [
              -84.926362,
              34.615719
            ],
            [
              -84.913456,
              34.634128
            ],
            [
              -84.92699,
              34.665662
            ],
            [
              -84.944512,
              34.679454
            ],
            [
              -84.929707,
              34.675936
            ],
            [
              -84.910373,
              34.681363
            ],
            [
              -84.901777,
              34.665639
            ],
            [
              -84.888314,
              34.668726
            ],
            [
              -84.888175,
              34.678387
            ],
            [
              -84.933722,
              34.706799
            ],
            [
              -84.924909,
              34.715971
            ],
            [
              -84.890481,
              34.705191
            ],
            [
              -84.882926,
              34.714732
            ],
            [
              -84.864945,
              34.707356
            ],
            [
              -84.856133,
              34.724512
            ],
            [
              -84.872168,
              34.731855
            ],
            [
              -84.857488,
              34.736598
            ],
            [
              -84.854118,
              34.748792
            ],
            [
              -84.867931,
              34.759642
            ],
            [
              -84.87421,
              34.78906
            ],
            [
              -84.862755,
              34.784339
            ],
            [
              -84.838079,
              34.800903
            ],
            [
              -84.859398,
              34.807149
            ],
            [
              -84.862932,
              34.814105
            ],
            [
              -84.848946,
              34.831744
            ],
            [
              -84.842661,
              34.860535
            ],
            [
              -84.82552,
              34.861741
            ],
            [
              -84.818715,
              34.87695
            ],
            [
              -84.833321,
              34.897294
            ],
            [
              -84.824076,
              34.901465
            ],
            [
              -84.842705,
              34.918126
            ],
            [
              -84.835207,
              34.942833
            ],
            [
              -84.813748,
              34.97187
            ],
            [
              -84.8104769358134,
              34.9876071739714
            ],
            [
              -84.810742,
              34.987615
            ],
            [
              -84.817279,
              34.987753
            ],
            [
              -84.820478,
              34.987913
            ],
            [
              -84.82401,
              34.987707
            ],
            [
              -84.831799,
              34.988004
            ],
            [
              -84.858032,
              34.987746
            ],
            [
              -84.861314,
              34.987791
            ],
            [
              -84.939306,
              34.987916
            ],
            [
              -84.94442,
              34.987864
            ],
            [
              -84.955623,
              34.98783
            ],
            [
              -84.9769733744412,
              34.9876687952914
            ],
            [
              -84.97986,
              34.987647
            ],
            [
              -84.979661,
              34.972755
            ],
            [
              -84.997432,
              34.972435
            ],
            [
              -84.997874,
              34.940207
            ],
            [
              -85.013868,
              34.901502
            ],
            [
              -85.019864,
              34.900787
            ],
            [
              -85.023299,
              34.871131
            ],
            [
              -85.032727,
              34.863731
            ],
            [
              -85.061647,
              34.863576
            ],
            [
              -85.061648,
              34.819478
            ],
            [
              -85.105349,
              34.819178
            ],
            [
              -85.12275,
              34.812078
            ],
            [
              -85.13175,
              34.819678
            ],
            [
              -85.144789,
              34.767639
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "009",
      "COUNTYNS": "00345255",
      "AFFGEOID": "0500000US13009",
      "GEOID": "13009",
      "NAME": "Baldwin",
      "LSAD": "06",
      "ALAND": 670027550,
      "AWATER": 24802965,
      "County_state": "Baldwin,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.418549,
              33.177464
            ],
            [
              -83.42909,
              33.185352
            ],
            [
              -83.412956,
              33.105219
            ],
            [
              -83.394027,
              33.043215
            ],
            [
              -83.380383,
              32.998077
            ],
            [
              -83.357685,
              32.926141
            ],
            [
              -83.287737,
              32.951884
            ],
            [
              -83.276067,
              32.942067
            ],
            [
              -83.268672,
              32.958699
            ],
            [
              -83.20616,
              32.983597
            ],
            [
              -83.16227,
              32.969602
            ],
            [
              -83.179469,
              32.987401
            ],
            [
              -83.147416,
              33.008666
            ],
            [
              -83.133847,
              33.007248
            ],
            [
              -83.123973,
              33.001747
            ],
            [
              -83.114347,
              32.972991
            ],
            [
              -83.073648,
              32.946562
            ],
            [
              -83.068232,
              32.974366
            ],
            [
              -83.049081,
              32.985605
            ],
            [
              -83.051328,
              33.003085
            ],
            [
              -83.067713,
              33.027371
            ],
            [
              -83.044844,
              33.060654
            ],
            [
              -83.052197,
              33.080682
            ],
            [
              -83.07346,
              33.064103
            ],
            [
              -83.083524,
              33.063901
            ],
            [
              -83.10131,
              33.050207
            ],
            [
              -83.108712,
              33.071429
            ],
            [
              -83.118935,
              33.083069
            ],
            [
              -83.113962,
              33.094895
            ],
            [
              -83.109152,
              33.129829
            ],
            [
              -83.09886,
              33.13297
            ],
            [
              -83.097515,
              33.15123
            ],
            [
              -83.195373,
              33.176336
            ],
            [
              -83.263658,
              33.189093
            ],
            [
              -83.274108,
              33.187238
            ],
            [
              -83.290576,
              33.190377
            ],
            [
              -83.318852,
              33.183116
            ],
            [
              -83.331232,
              33.167562
            ],
            [
              -83.36309,
              33.180171
            ],
            [
              -83.363543,
              33.168998
            ],
            [
              -83.391468,
              33.183514
            ],
            [
              -83.418549,
              33.177464
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "029",
      "COUNTYNS": "00350496",
      "AFFGEOID": "0500000US13029",
      "GEOID": "13029",
      "NAME": "Bryan",
      "LSAD": "06",
      "ALAND": 1130182689,
      "AWATER": 46852821,
      "County_state": "Bryan,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.780858,
              32.15289
            ],
            [
              -81.77729,
              32.131939
            ],
            [
              -81.76334,
              32.11762
            ],
            [
              -81.75634,
              32.104584
            ],
            [
              -81.741043,
              32.1045
            ],
            [
              -81.718658,
              32.089351
            ],
            [
              -81.692695,
              32.088313
            ],
            [
              -81.676414,
              32.074478
            ],
            [
              -81.672798,
              32.063873
            ],
            [
              -81.651214,
              32.058352
            ],
            [
              -81.640251,
              32.049866
            ],
            [
              -81.603665,
              32.008469
            ],
            [
              -81.59423,
              31.991779
            ],
            [
              -81.593292,
              31.971233
            ],
            [
              -81.578712,
              31.97246
            ],
            [
              -81.564231,
              31.95949
            ],
            [
              -81.522323,
              31.958952
            ],
            [
              -81.510261,
              31.968998
            ],
            [
              -81.482971,
              31.966408
            ],
            [
              -81.476112,
              31.974302
            ],
            [
              -81.463844,
              31.950014
            ],
            [
              -81.426894,
              31.938677
            ],
            [
              -81.407737,
              31.944302
            ],
            [
              -81.404926,
              31.936868
            ],
            [
              -81.378097,
              31.848678
            ],
            [
              -81.355008,
              31.829746
            ],
            [
              -81.34477,
              31.823963
            ],
            [
              -81.333333,
              31.840468
            ],
            [
              -81.328224,
              31.823013
            ],
            [
              -81.316278,
              31.818092
            ],
            [
              -81.320028,
              31.808914
            ],
            [
              -81.311027,
              31.789915
            ],
            [
              -81.277426,
              31.794615
            ],
            [
              -81.268426,
              31.784616
            ],
            [
              -81.270743,
              31.766937
            ],
            [
              -81.259887,
              31.753754
            ],
            [
              -81.220862,
              31.743637
            ],
            [
              -81.198394185031,
              31.7260700164189
            ],
            [
              -81.192784,
              31.733245
            ],
            [
              -81.16067,
              31.728144
            ],
            [
              -81.154686330339,
              31.726202578964298
            ],
            [
              -81.159528,
              31.746031
            ],
            [
              -81.173124,
              31.763929
            ],
            [
              -81.161917,
              31.779217
            ],
            [
              -81.163326,
              31.787722
            ],
            [
              -81.17973,
              31.791317
            ],
            [
              -81.177027,
              31.816113
            ],
            [
              -81.159726,
              31.823615
            ],
            [
              -81.157827,
              31.834517
            ],
            [
              -81.138533,
              31.855921
            ],
            [
              -81.15263,
              31.862215
            ],
            [
              -81.161187,
              31.887571
            ],
            [
              -81.180429,
              31.90071
            ],
            [
              -81.208655,
              31.888512
            ],
            [
              -81.217201,
              31.897736
            ],
            [
              -81.193681,
              31.908913
            ],
            [
              -81.191302,
              31.919433
            ],
            [
              -81.203586,
              31.922256
            ],
            [
              -81.238735,
              31.887088
            ],
            [
              -81.242853,
              31.891757
            ],
            [
              -81.233014,
              31.906656
            ],
            [
              -81.240961,
              31.911976
            ],
            [
              -81.256391,
              31.905369
            ],
            [
              -81.26568,
              31.910586
            ],
            [
              -81.263132,
              31.927109
            ],
            [
              -81.273034,
              31.925005
            ],
            [
              -81.272349,
              31.93876
            ],
            [
              -81.285467,
              31.942994
            ],
            [
              -81.290323,
              31.978821
            ],
            [
              -81.309399,
              31.984721
            ],
            [
              -81.304269,
              31.999174
            ],
            [
              -81.334635,
              32.027704
            ],
            [
              -81.367004,
              32.047877
            ],
            [
              -81.384727,
              32.079517
            ],
            [
              -81.387143,
              32.090229
            ],
            [
              -81.391698,
              32.095886
            ],
            [
              -81.406776,
              32.101931
            ],
            [
              -81.401931,
              32.150184
            ],
            [
              -81.408004,
              32.151871
            ],
            [
              -81.418601,
              32.196582
            ],
            [
              -81.415771,
              32.21181
            ],
            [
              -81.42842,
              32.215899
            ],
            [
              -81.43583,
              32.241289
            ],
            [
              -81.624809,
              32.189681
            ],
            [
              -81.780858,
              32.15289
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "275",
      "COUNTYNS": "00343835",
      "AFFGEOID": "0500000US13275",
      "GEOID": "13275",
      "NAME": "Thomas",
      "LSAD": "06",
      "ALAND": 1410520995,
      "AWATER": 19717343,
      "County_state": "Thomas,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.116644,
              31.077971
            ],
            [
              -84.118602,
              30.969691
            ],
            [
              -84.076347,
              30.905958
            ],
            [
              -84.079514,
              30.881017
            ],
            [
              -84.07291,
              30.865151
            ],
            [
              -84.082524,
              30.85382
            ],
            [
              -84.080278,
              30.844809
            ],
            [
              -84.08375705716549,
              30.675802634493497
            ],
            [
              -84.057323,
              30.674696
            ],
            [
              -84.0467,
              30.674191
            ],
            [
              -84.041905,
              30.673869
            ],
            [
              -84.039802,
              30.67381
            ],
            [
              -84.007484,
              30.672088
            ],
            [
              -84.007454,
              30.6720865150852
            ],
            [
              -83.880406,
              30.665798
            ],
            [
              -83.880309,
              30.665823
            ],
            [
              -83.8796588473596,
              30.665786078714298
            ],
            [
              -83.855304,
              30.664403
            ],
            [
              -83.820973,
              30.662603
            ],
            [
              -83.810622,
              30.661871
            ],
            [
              -83.7437296744174,
              30.658383065883196
            ],
            [
              -83.739021,
              30.742003
            ],
            [
              -83.736158,
              31.037679
            ],
            [
              -83.78083,
              31.038502
            ],
            [
              -83.795235,
              31.025988
            ],
            [
              -83.795731,
              31.038733
            ],
            [
              -83.918397,
              31.040508
            ],
            [
              -84.003876,
              31.041582
            ],
            [
              -84.003627,
              31.07729
            ],
            [
              -84.116644,
              31.077971
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "099",
      "COUNTYNS": "00345041",
      "AFFGEOID": "0500000US13099",
      "GEOID": "13099",
      "NAME": "Early",
      "LSAD": "06",
      "ALAND": 1327612139,
      "AWATER": 9717177,
      "County_state": "Early,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.114548,
              31.276302
            ],
            [
              -85.098844,
              31.232524
            ],
            [
              -85.09977,
              31.209751
            ],
            [
              -85.107516,
              31.186451
            ],
            [
              -85.093405,
              31.162326
            ],
            [
              -85.076628,
              31.156927
            ],
            [
              -85.052867,
              31.119489
            ],
            [
              -85.035615,
              31.108192
            ],
            [
              -85.026603,
              31.080958
            ],
            [
              -85.0235761810791,
              31.0755033185024
            ],
            [
              -84.922423,
              31.07259
            ],
            [
              -84.917421,
              31.25599
            ],
            [
              -84.68322,
              31.257183
            ],
            [
              -84.641673,
              31.258967
            ],
            [
              -84.638082,
              31.332079
            ],
            [
              -84.627579,
              31.332117
            ],
            [
              -84.638254,
              31.341684
            ],
            [
              -84.637579,
              31.433926
            ],
            [
              -84.789032,
              31.436356
            ],
            [
              -84.789617,
              31.451378
            ],
            [
              -84.807552,
              31.473119
            ],
            [
              -84.797093,
              31.473586
            ],
            [
              -84.796609,
              31.501286
            ],
            [
              -84.806887,
              31.491729
            ],
            [
              -84.819769,
              31.491563
            ],
            [
              -84.819807,
              31.501192
            ],
            [
              -84.937801,
              31.503266
            ],
            [
              -84.959735,
              31.492663
            ],
            [
              -84.971053,
              31.497312
            ],
            [
              -84.98634,
              31.488856
            ],
            [
              -85.029227,
              31.486071
            ],
            [
              -85.029468,
              31.518562
            ],
            [
              -85.04555669828159,
              31.517161968216698
            ],
            [
              -85.071621,
              31.468384
            ],
            [
              -85.066005,
              31.431363
            ],
            [
              -85.079978,
              31.410472
            ],
            [
              -85.077581,
              31.398115
            ],
            [
              -85.092487,
              31.362881
            ],
            [
              -85.086657,
              31.339995
            ],
            [
              -85.08882981560309,
              31.308647760712898
            ],
            [
              -85.089774,
              31.295026
            ],
            [
              -85.114548,
              31.276302
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "065",
      "COUNTYNS": "00351285",
      "AFFGEOID": "0500000US13065",
      "GEOID": "13065",
      "NAME": "Clinch",
      "LSAD": "06",
      "ALAND": 2072566890,
      "AWATER": 61565560,
      "County_state": "Clinch,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.971247,
              31.183988
            ],
            [
              -82.971336,
              30.869392
            ],
            [
              -82.846353,
              30.834988
            ],
            [
              -82.845909,
              30.827198
            ],
            [
              -82.830482,
              30.808776
            ],
            [
              -82.834831,
              30.799451
            ],
            [
              -82.830543,
              30.781375
            ],
            [
              -82.815963,
              30.7761
            ],
            [
              -82.80529,
              30.749835
            ],
            [
              -82.795356,
              30.74209
            ],
            [
              -82.766159,
              30.732404
            ],
            [
              -82.743934,
              30.733516
            ],
            [
              -82.709976,
              30.729234
            ],
            [
              -82.697921,
              30.734207
            ],
            [
              -82.674819,
              30.725316
            ],
            [
              -82.637361,
              30.717135
            ],
            [
              -82.604005,
              30.713676
            ],
            [
              -82.581202,
              30.696237
            ],
            [
              -82.586463,
              30.675048
            ],
            [
              -82.582778,
              30.658075
            ],
            [
              -82.58400213818601,
              30.5917854612315
            ],
            [
              -82.569278,
              30.590957
            ],
            [
              -82.565516,
              30.590614
            ],
            [
              -82.553199,
              30.589926
            ],
            [
              -82.545095,
              30.589353
            ],
            [
              -82.536272,
              30.588877
            ],
            [
              -82.524938,
              30.588181
            ],
            [
              -82.45979242039849,
              30.5842766709258
            ],
            [
              -82.459581,
              30.584264
            ],
            [
              -82.4189159951919,
              30.5817349193742
            ],
            [
              -82.418793,
              30.613132
            ],
            [
              -82.430862,
              30.612848
            ],
            [
              -82.428902,
              30.690317
            ],
            [
              -82.435112,
              30.716566
            ],
            [
              -82.41999,
              30.716637
            ],
            [
              -82.420128,
              30.729198
            ],
            [
              -82.435242,
              30.729149
            ],
            [
              -82.435852,
              30.820068
            ],
            [
              -82.495476,
              30.819553
            ],
            [
              -82.492204,
              30.832564
            ],
            [
              -82.490558,
              30.963166
            ],
            [
              -82.520124,
              30.963132
            ],
            [
              -82.550339,
              31.000404
            ],
            [
              -82.592071,
              31.018487
            ],
            [
              -82.666473,
              31.172923
            ],
            [
              -82.671669,
              31.183739
            ],
            [
              -82.971247,
              31.183988
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "193",
      "COUNTYNS": "00346957",
      "AFFGEOID": "0500000US13193",
      "GEOID": "13193",
      "NAME": "Macon",
      "LSAD": "06",
      "ALAND": 1037653519,
      "AWATER": 13860062,
      "County_state": "Macon,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.052952,
              32.530602
            ],
            [
              -84.05331,
              32.522021
            ],
            [
              -84.00849,
              32.521769
            ],
            [
              -84.003363,
              32.529927
            ],
            [
              -84.052952,
              32.530602
            ]
          ]
        ],
        [
          [
            [
              -84.254613,
              32.372053
            ],
            [
              -84.255912,
              32.296156
            ],
            [
              -84.219211,
              32.295057
            ],
            [
              -84.220144,
              32.23115
            ],
            [
              -84.181657,
              32.229703
            ],
            [
              -84.134095,
              32.227972
            ],
            [
              -84.134736,
              32.185392
            ],
            [
              -84.084805,
              32.191262
            ],
            [
              -84.049205,
              32.185062
            ],
            [
              -84.027853,
              32.171083
            ],
            [
              -84.031087,
              32.178854
            ],
            [
              -84.012641,
              32.201363
            ],
            [
              -83.989543,
              32.216884
            ],
            [
              -83.965121,
              32.248005
            ],
            [
              -83.897481,
              32.280977
            ],
            [
              -83.894777,
              32.292175
            ],
            [
              -83.848379,
              32.29097
            ],
            [
              -83.846831,
              32.357738
            ],
            [
              -83.856587,
              32.357774
            ],
            [
              -83.85654,
              32.382399
            ],
            [
              -83.837041,
              32.382195
            ],
            [
              -83.83696,
              32.393934
            ],
            [
              -83.836673,
              32.439308
            ],
            [
              -83.846203,
              32.439385
            ],
            [
              -83.846676,
              32.468514
            ],
            [
              -83.872089,
              32.479014
            ],
            [
              -83.877066,
              32.489015
            ],
            [
              -83.900503,
              32.496982
            ],
            [
              -83.922832,
              32.497428
            ],
            [
              -83.931122,
              32.483745
            ],
            [
              -83.923363,
              32.511355
            ],
            [
              -83.932472,
              32.50569
            ],
            [
              -84.018147,
              32.506406
            ],
            [
              -84.017809,
              32.496949
            ],
            [
              -84.034109,
              32.497449
            ],
            [
              -84.034326,
              32.513478
            ],
            [
              -84.05331,
              32.522021
            ],
            [
              -84.07216,
              32.521904
            ],
            [
              -84.111226,
              32.514124
            ],
            [
              -84.122347,
              32.505921
            ],
            [
              -84.197912,
              32.400852
            ],
            [
              -84.242845,
              32.401034
            ],
            [
              -84.254111,
              32.395764
            ],
            [
              -84.254613,
              32.372053
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "007",
      "COUNTYNS": "00342832",
      "AFFGEOID": "0500000US13007",
      "GEOID": "13007",
      "NAME": "Baker",
      "LSAD": "06",
      "ALAND": 885665382,
      "AWATER": 18598655,
      "County_state": "Baker,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.638082,
              31.332079
            ],
            [
              -84.641673,
              31.258967
            ],
            [
              -84.537101,
              31.255932
            ],
            [
              -84.542653,
              31.079029
            ],
            [
              -84.508078,
              31.0784
            ],
            [
              -84.506777,
              31.123242
            ],
            [
              -84.460531,
              31.166143
            ],
            [
              -84.420169,
              31.19759
            ],
            [
              -84.41216,
              31.190139
            ],
            [
              -84.40435,
              31.199143
            ],
            [
              -84.409044,
              31.210051
            ],
            [
              -84.393956,
              31.229693
            ],
            [
              -84.366588,
              31.239775
            ],
            [
              -84.359736,
              31.258983
            ],
            [
              -84.340672,
              31.278714
            ],
            [
              -84.335478,
              31.291586
            ],
            [
              -84.340752,
              31.305144
            ],
            [
              -84.31312,
              31.32234
            ],
            [
              -84.270155,
              31.332791
            ],
            [
              -84.267461,
              31.341621
            ],
            [
              -84.255017,
              31.335757
            ],
            [
              -84.233268,
              31.341444
            ],
            [
              -84.225112,
              31.356033
            ],
            [
              -84.201019,
              31.355558
            ],
            [
              -84.194552,
              31.392575
            ],
            [
              -84.175871,
              31.398006
            ],
            [
              -84.177118,
              31.410646
            ],
            [
              -84.153266,
              31.413699
            ],
            [
              -84.141124,
              31.440179
            ],
            [
              -84.211593,
              31.435856
            ],
            [
              -84.429876,
              31.436661
            ],
            [
              -84.448133,
              31.436616
            ],
            [
              -84.436037,
              31.441556
            ],
            [
              -84.447875,
              31.454743
            ],
            [
              -84.468704,
              31.448173
            ],
            [
              -84.468635,
              31.438803
            ],
            [
              -84.490839,
              31.435439
            ],
            [
              -84.490813,
              31.427882
            ],
            [
              -84.546641,
              31.431891
            ],
            [
              -84.637579,
              31.433926
            ],
            [
              -84.638254,
              31.341684
            ],
            [
              -84.627579,
              31.332117
            ],
            [
              -84.638082,
              31.332079
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "083",
      "COUNTYNS": "00347465",
      "AFFGEOID": "0500000US13083",
      "GEOID": "13083",
      "NAME": "Dade",
      "LSAD": "06",
      "ALAND": 450605326,
      "AWATER": 467844,
      "County_state": "Dade,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.605165,
              34.984678
            ],
            [
              -85.599386,
              34.951766
            ],
            [
              -85.598782,
              34.944915
            ],
            [
              -85.595193,
              34.924331
            ],
            [
              -85.595165,
              34.924171
            ],
            [
              -85.583149,
              34.860371
            ],
            [
              -85.583148376479,
              34.8603678345509
            ],
            [
              -85.561424,
              34.750079
            ],
            [
              -85.552491,
              34.708321
            ],
            [
              -85.552463,
              34.708138
            ],
            [
              -85.541278,
              34.656783
            ],
            [
              -85.541275,
              34.656701
            ],
            [
              -85.534339,
              34.625082
            ],
            [
              -85.53410097210269,
              34.62385567623
            ],
            [
              -85.490795,
              34.669449
            ],
            [
              -85.49053,
              34.69911
            ],
            [
              -85.461957,
              34.731771
            ],
            [
              -85.459354,
              34.751639
            ],
            [
              -85.450409,
              34.759241
            ],
            [
              -85.450561,
              34.831677
            ],
            [
              -85.43371,
              34.875077
            ],
            [
              -85.418392,
              34.899805
            ],
            [
              -85.374598,
              34.961964
            ],
            [
              -85.363919,
              34.983375
            ],
            [
              -85.384967,
              34.982987
            ],
            [
              -85.466713,
              34.982972
            ],
            [
              -85.47410531586509,
              34.9830630878201
            ],
            [
              -85.55259257343901,
              34.984030204665096
            ],
            [
              -85.605165,
              34.984678
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "095",
      "COUNTYNS": "00351259",
      "AFFGEOID": "0500000US13095",
      "GEOID": "13095",
      "NAME": "Dougherty",
      "LSAD": "06",
      "ALAND": 851140307,
      "AWATER": 15396055,
      "County_state": "Dougherty,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.456874,
              31.586521
            ],
            [
              -84.454923,
              31.548331
            ],
            [
              -84.447348,
              31.544198
            ],
            [
              -84.432705,
              31.507449
            ],
            [
              -84.412521,
              31.45693
            ],
            [
              -84.429876,
              31.436661
            ],
            [
              -84.211593,
              31.435856
            ],
            [
              -84.141124,
              31.440179
            ],
            [
              -84.137769,
              31.442677
            ],
            [
              -84.115916,
              31.442677
            ],
            [
              -83.997796,
              31.443753
            ],
            [
              -83.997679,
              31.462773
            ],
            [
              -83.982582,
              31.462678
            ],
            [
              -83.997468,
              31.47601
            ],
            [
              -83.997087,
              31.507574
            ],
            [
              -83.996246,
              31.625169
            ],
            [
              -84.012509,
              31.633619
            ],
            [
              -83.993374,
              31.650034
            ],
            [
              -84.018404,
              31.650274
            ],
            [
              -84.02891,
              31.64836
            ],
            [
              -84.043213,
              31.623573
            ],
            [
              -84.261066,
              31.622416
            ],
            [
              -84.297801,
              31.621951
            ],
            [
              -84.450398,
              31.621868
            ],
            [
              -84.447089,
              31.604452
            ],
            [
              -84.456874,
              31.586521
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "205",
      "COUNTYNS": "00343645",
      "AFFGEOID": "0500000US13205",
      "GEOID": "13205",
      "NAME": "Mitchell",
      "LSAD": "06",
      "ALAND": 1326342585,
      "AWATER": 4454719,
      "County_state": "Mitchell,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.506777,
              31.123242
            ],
            [
              -84.508078,
              31.0784
            ],
            [
              -84.376612,
              31.078883
            ],
            [
              -84.116644,
              31.077971
            ],
            [
              -84.003627,
              31.07729
            ],
            [
              -84.003201,
              31.102349
            ],
            [
              -84.013851,
              31.102436
            ],
            [
              -84.002977,
              31.11189
            ],
            [
              -83.99943,
              31.334966
            ],
            [
              -83.997796,
              31.443753
            ],
            [
              -84.115916,
              31.442677
            ],
            [
              -84.137769,
              31.442677
            ],
            [
              -84.141124,
              31.440179
            ],
            [
              -84.153266,
              31.413699
            ],
            [
              -84.177118,
              31.410646
            ],
            [
              -84.175871,
              31.398006
            ],
            [
              -84.194552,
              31.392575
            ],
            [
              -84.201019,
              31.355558
            ],
            [
              -84.225112,
              31.356033
            ],
            [
              -84.233268,
              31.341444
            ],
            [
              -84.255017,
              31.335757
            ],
            [
              -84.267461,
              31.341621
            ],
            [
              -84.270155,
              31.332791
            ],
            [
              -84.31312,
              31.32234
            ],
            [
              -84.340752,
              31.305144
            ],
            [
              -84.335478,
              31.291586
            ],
            [
              -84.340672,
              31.278714
            ],
            [
              -84.359736,
              31.258983
            ],
            [
              -84.366588,
              31.239775
            ],
            [
              -84.393956,
              31.229693
            ],
            [
              -84.409044,
              31.210051
            ],
            [
              -84.40435,
              31.199143
            ],
            [
              -84.41216,
              31.190139
            ],
            [
              -84.420169,
              31.19759
            ],
            [
              -84.460531,
              31.166143
            ],
            [
              -84.506777,
              31.123242
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "253",
      "COUNTYNS": "00351263",
      "AFFGEOID": "0500000US13253",
      "GEOID": "13253",
      "NAME": "Seminole",
      "LSAD": "06",
      "ALAND": 609245346,
      "AWATER": 55231610,
      "County_state": "Seminole,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.0235761810791,
              31.0755033185024
            ],
            [
              -85.011392,
              31.053546
            ],
            [
              -85.001497,
              31.005044
            ],
            [
              -85.002499,
              31.000682
            ],
            [
              -85.0019,
              31.000681
            ],
            [
              -85.001846,
              30.997073
            ],
            [
              -85.002671,
              30.986898
            ],
            [
              -85.006065,
              30.979803
            ],
            [
              -85.006062,
              30.977039
            ],
            [
              -85.005236,
              30.974703
            ],
            [
              -85.004157,
              30.973467
            ],
            [
              -85.000059,
              30.971185
            ],
            [
              -84.999959,
              30.971485
            ],
            [
              -84.997759,
              30.971185
            ],
            [
              -84.988157,
              30.968785
            ],
            [
              -84.984957,
              30.967485
            ],
            [
              -84.982657,
              30.965585
            ],
            [
              -84.980257,
              30.961285
            ],
            [
              -84.979757,
              30.958985
            ],
            [
              -84.979757,
              30.954684
            ],
            [
              -84.982357,
              30.946884
            ],
            [
              -84.983157,
              30.942584
            ],
            [
              -84.983757,
              30.936984
            ],
            [
              -84.983257,
              30.934784
            ],
            [
              -84.980757,
              30.932685
            ],
            [
              -84.975356,
              30.930785
            ],
            [
              -84.971156,
              30.928184
            ],
            [
              -84.969555,
              30.921984
            ],
            [
              -84.966855,
              30.917284
            ],
            [
              -84.956254,
              30.907584
            ],
            [
              -84.952454,
              30.902284
            ],
            [
              -84.949754,
              30.897383
            ],
            [
              -84.942653,
              30.888484
            ],
            [
              -84.941453,
              30.887684
            ],
            [
              -84.940102,
              30.886724
            ],
            [
              -84.938215,
              30.885623
            ],
            [
              -84.936956,
              30.884679
            ],
            [
              -84.935541,
              30.882477
            ],
            [
              -84.935698,
              30.878703
            ],
            [
              -84.937743,
              30.875872
            ],
            [
              -84.938529,
              30.873041
            ],
            [
              -84.9379,
              30.870523
            ],
            [
              -84.935856,
              30.867535
            ],
            [
              -84.934755,
              30.86549
            ],
            [
              -84.934125,
              30.863288
            ],
            [
              -84.934755,
              30.860615
            ],
            [
              -84.935541,
              30.858413
            ],
            [
              -84.935384,
              30.854323
            ],
            [
              -84.933352,
              30.851483
            ],
            [
              -84.930193,
              30.848819
            ],
            [
              -84.928935,
              30.846774
            ],
            [
              -84.928463,
              30.844258
            ],
            [
              -84.928463,
              30.842526
            ],
            [
              -84.929564,
              30.840325
            ],
            [
              -84.932081,
              30.837493
            ],
            [
              -84.934283,
              30.834033
            ],
            [
              -84.935384,
              30.830888
            ],
            [
              -84.935698,
              30.824597
            ],
            [
              -84.93617,
              30.820665
            ],
            [
              -84.935541,
              30.817203
            ],
            [
              -84.931051,
              30.810482
            ],
            [
              -84.928451,
              30.805083
            ],
            [
              -84.92805,
              30.802783
            ],
            [
              -84.92915,
              30.797283
            ],
            [
              -84.92845,
              30.793083
            ],
            [
              -84.92685,
              30.790183
            ],
            [
              -84.91815,
              30.778082
            ],
            [
              -84.91755,
              30.775882
            ],
            [
              -84.91815,
              30.772082
            ],
            [
              -84.92025,
              30.765982
            ],
            [
              -84.915149,
              30.761183
            ],
            [
              -84.914449,
              30.753582
            ],
            [
              -84.913649,
              30.752282
            ],
            [
              -84.911249,
              30.751182
            ],
            [
              -84.906448,
              30.750582
            ],
            [
              -84.903248,
              30.751782
            ],
            [
              -84.900348,
              30.751882
            ],
            [
              -84.897748,
              30.751382
            ],
            [
              -84.896248,
              30.750582
            ],
            [
              -84.887648,
              30.741782
            ],
            [
              -84.885347,
              30.734982
            ],
            [
              -84.883947,
              30.732582
            ],
            [
              -84.875546,
              30.727481
            ],
            [
              -84.861121,
              30.76199
            ],
            [
              -84.849921,
              30.77219
            ],
            [
              -84.81392,
              30.78139
            ],
            [
              -84.79902,
              30.801989
            ],
            [
              -84.78692,
              30.801189
            ],
            [
              -84.78532,
              30.823589
            ],
            [
              -84.76892,
              30.837089
            ],
            [
              -84.769519,
              30.876393
            ],
            [
              -84.75575,
              30.894928
            ],
            [
              -84.752947,
              31.041212
            ],
            [
              -84.731619,
              31.050087
            ],
            [
              -84.730855,
              31.06919
            ],
            [
              -84.922423,
              31.07259
            ],
            [
              -85.0235761810791,
              31.0755033185024
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "249",
      "COUNTYNS": "00344129",
      "AFFGEOID": "0500000US13249",
      "GEOID": "13249",
      "NAME": "Schley",
      "LSAD": "06",
      "ALAND": 432287212,
      "AWATER": 2373636,
      "County_state": "Schley,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.420515,
              32.174357
            ],
            [
              -84.430218,
              32.166257
            ],
            [
              -84.279911,
              32.16376
            ],
            [
              -84.260411,
              32.166061
            ],
            [
              -84.218959,
              32.15929
            ],
            [
              -84.183569,
              32.158493
            ],
            [
              -84.181657,
              32.229703
            ],
            [
              -84.220144,
              32.23115
            ],
            [
              -84.219211,
              32.295057
            ],
            [
              -84.255912,
              32.296156
            ],
            [
              -84.254613,
              32.372053
            ],
            [
              -84.323815,
              32.37245
            ],
            [
              -84.323315,
              32.39785
            ],
            [
              -84.363216,
              32.397649
            ],
            [
              -84.362416,
              32.428047
            ],
            [
              -84.381817,
              32.428047
            ],
            [
              -84.392316,
              32.414046
            ],
            [
              -84.389927,
              32.297237
            ],
            [
              -84.413304,
              32.297531
            ],
            [
              -84.410101,
              32.289454
            ],
            [
              -84.410415,
              32.231856
            ],
            [
              -84.429451,
              32.232151
            ],
            [
              -84.429832,
              32.194153
            ],
            [
              -84.42008,
              32.191677
            ],
            [
              -84.420515,
              32.174357
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "151",
      "COUNTYNS": "01671894",
      "AFFGEOID": "0500000US13151",
      "GEOID": "13151",
      "NAME": "Henry",
      "LSAD": "06",
      "ALAND": 825201574,
      "AWATER": 20443113,
      "County_state": "Henry,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.353824,
              33.397291
            ],
            [
              -84.3544,
              33.352514
            ],
            [
              -84.249164,
              33.352539
            ],
            [
              -84.247304,
              33.33597
            ],
            [
              -84.216329,
              33.335821
            ],
            [
              -84.150581,
              33.335639
            ],
            [
              -84.150909,
              33.312982
            ],
            [
              -84.102582,
              33.298191
            ],
            [
              -84.097308,
              33.302025
            ],
            [
              -84.085509,
              33.298008
            ],
            [
              -84.080238,
              33.301873
            ],
            [
              -84.061266,
              33.309583
            ],
            [
              -84.042533,
              33.341113
            ],
            [
              -84.032992,
              33.342796
            ],
            [
              -84.032049,
              33.361641
            ],
            [
              -83.964374,
              33.375154
            ],
            [
              -83.973128,
              33.407556
            ],
            [
              -83.943516,
              33.42529
            ],
            [
              -83.932746,
              33.441865
            ],
            [
              -83.923913,
              33.444194
            ],
            [
              -83.922546,
              33.45058
            ],
            [
              -83.946526,
              33.481761
            ],
            [
              -83.974451,
              33.483671
            ],
            [
              -83.997394,
              33.494102
            ],
            [
              -84.031526,
              33.497908
            ],
            [
              -84.041928,
              33.518571
            ],
            [
              -84.054396,
              33.514907
            ],
            [
              -84.044493,
              33.525776
            ],
            [
              -84.04972,
              33.541081
            ],
            [
              -84.080583,
              33.554535
            ],
            [
              -84.097471,
              33.560009
            ],
            [
              -84.125073,
              33.561248
            ],
            [
              -84.171495,
              33.619954
            ],
            [
              -84.181584,
              33.629174
            ],
            [
              -84.184143,
              33.646157
            ],
            [
              -84.187442,
              33.646173
            ],
            [
              -84.223952,
              33.646572
            ],
            [
              -84.224235,
              33.630657
            ],
            [
              -84.245453,
              33.63073
            ],
            [
              -84.254149,
              33.647045
            ],
            [
              -84.266882,
              33.64717
            ],
            [
              -84.281273,
              33.647411
            ],
            [
              -84.262566,
              33.631004
            ],
            [
              -84.26297,
              33.606953
            ],
            [
              -84.243897,
              33.590294
            ],
            [
              -84.263292,
              33.574885
            ],
            [
              -84.263393,
              33.569333
            ],
            [
              -84.263702,
              33.548923
            ],
            [
              -84.283879,
              33.549078
            ],
            [
              -84.292557,
              33.532981
            ],
            [
              -84.282524,
              33.532879
            ],
            [
              -84.283173,
              33.511444
            ],
            [
              -84.283913,
              33.484341
            ],
            [
              -84.294151,
              33.484325
            ],
            [
              -84.294383,
              33.447367
            ],
            [
              -84.29468,
              33.435715
            ],
            [
              -84.353584,
              33.436165
            ],
            [
              -84.353749,
              33.420537
            ],
            [
              -84.353824,
              33.397291
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "269",
      "COUNTYNS": "00344156",
      "AFFGEOID": "0500000US13269",
      "GEOID": "13269",
      "NAME": "Taylor",
      "LSAD": "06",
      "ALAND": 975612265,
      "AWATER": 7802363,
      "County_state": "Taylor,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.05331,
              32.522021
            ],
            [
              -84.034326,
              32.513478
            ],
            [
              -84.034109,
              32.497449
            ],
            [
              -84.017809,
              32.496949
            ],
            [
              -84.018147,
              32.506406
            ],
            [
              -84.016609,
              32.511741
            ],
            [
              -84.00849,
              32.521769
            ],
            [
              -84.05331,
              32.522021
            ]
          ]
        ],
        [
          [
            [
              -84.444353,
              32.562083
            ],
            [
              -84.453154,
              32.534336
            ],
            [
              -84.433542,
              32.518694
            ],
            [
              -84.433619,
              32.493243
            ],
            [
              -84.407808,
              32.485267
            ],
            [
              -84.408578,
              32.443949
            ],
            [
              -84.392117,
              32.435347
            ],
            [
              -84.392316,
              32.414046
            ],
            [
              -84.381817,
              32.428047
            ],
            [
              -84.362416,
              32.428047
            ],
            [
              -84.363216,
              32.397649
            ],
            [
              -84.323315,
              32.39785
            ],
            [
              -84.323815,
              32.37245
            ],
            [
              -84.254613,
              32.372053
            ],
            [
              -84.254111,
              32.395764
            ],
            [
              -84.242845,
              32.401034
            ],
            [
              -84.197912,
              32.400852
            ],
            [
              -84.122347,
              32.505921
            ],
            [
              -84.111226,
              32.514124
            ],
            [
              -84.07216,
              32.521904
            ],
            [
              -84.05331,
              32.522021
            ],
            [
              -84.052952,
              32.530602
            ],
            [
              -84.003363,
              32.529927
            ],
            [
              -84.035379,
              32.55849
            ],
            [
              -84.052549,
              32.566837
            ],
            [
              -84.071252,
              32.598264
            ],
            [
              -84.072171,
              32.618603
            ],
            [
              -84.056765,
              32.636518
            ],
            [
              -84.073236,
              32.635581
            ],
            [
              -84.078156,
              32.647239
            ],
            [
              -84.104966,
              32.673385
            ],
            [
              -84.105924,
              32.685049
            ],
            [
              -84.127591,
              32.674534
            ],
            [
              -84.145738,
              32.68525
            ],
            [
              -84.174217,
              32.677658
            ],
            [
              -84.202628,
              32.690018
            ],
            [
              -84.220333,
              32.720869
            ],
            [
              -84.236469,
              32.721785
            ],
            [
              -84.235803,
              32.738284
            ],
            [
              -84.25644,
              32.722717
            ],
            [
              -84.262706,
              32.739306
            ],
            [
              -84.286246,
              32.747626
            ],
            [
              -84.288743,
              32.736151
            ],
            [
              -84.304132,
              32.733708
            ],
            [
              -84.322266,
              32.721159
            ],
            [
              -84.33794,
              32.720647
            ],
            [
              -84.338884,
              32.679043
            ],
            [
              -84.364243,
              32.679364
            ],
            [
              -84.378432,
              32.631805
            ],
            [
              -84.408378,
              32.610503
            ],
            [
              -84.408866,
              32.561923
            ],
            [
              -84.444353,
              32.562083
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "033",
      "COUNTYNS": "00347944",
      "AFFGEOID": "0500000US13033",
      "GEOID": "13033",
      "NAME": "Burke",
      "LSAD": "06",
      "ALAND": 2141993539,
      "AWATER": 20601185,
      "County_state": "Burke,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.316512,
              32.835772
            ],
            [
              -82.30083,
              32.826763
            ],
            [
              -82.256299,
              32.823988
            ],
            [
              -82.213745,
              32.803863
            ],
            [
              -82.157638,
              32.816023
            ],
            [
              -82.144977,
              32.812741
            ],
            [
              -82.147942,
              32.819758
            ],
            [
              -82.123742,
              32.883656
            ],
            [
              -82.081349,
              32.916762
            ],
            [
              -81.956448,
              32.923724
            ],
            [
              -81.857345,
              32.953684
            ],
            [
              -81.783842,
              32.928345
            ],
            [
              -81.767553,
              32.909411
            ],
            [
              -81.542509768548,
              33.04525362347609
            ],
            [
              -81.544258,
              33.046905
            ],
            [
              -81.546785,
              33.047145
            ],
            [
              -81.551838,
              33.044739
            ],
            [
              -81.553643,
              33.044137
            ],
            [
              -81.557013,
              33.0451
            ],
            [
              -81.558336,
              33.046183
            ],
            [
              -81.559179,
              33.047386
            ],
            [
              -81.55966,
              33.04907
            ],
            [
              -81.559173,
              33.051765
            ],
            [
              -81.558938,
              33.052921
            ],
            [
              -81.559179,
              33.054124
            ],
            [
              -81.560502,
              33.055207
            ],
            [
              -81.561344,
              33.055568
            ],
            [
              -81.562066,
              33.055568
            ],
            [
              -81.562548,
              33.055568
            ],
            [
              -81.56327,
              33.055568
            ],
            [
              -81.564714,
              33.054726
            ],
            [
              -81.566759,
              33.053763
            ],
            [
              -81.568925,
              33.053523
            ],
            [
              -81.57288,
              33.05418
            ],
            [
              -81.578332,
              33.058936
            ],
            [
              -81.580994,
              33.062697
            ],
            [
              -81.583804,
              33.067021
            ],
            [
              -81.588539,
              33.07085
            ],
            [
              -81.590705,
              33.071211
            ],
            [
              -81.592645,
              33.06991
            ],
            [
              -81.594555,
              33.069887
            ],
            [
              -81.599248,
              33.071813
            ],
            [
              -81.600091,
              33.073497
            ],
            [
              -81.600211,
              33.075182
            ],
            [
              -81.599369,
              33.076867
            ],
            [
              -81.598286,
              33.079153
            ],
            [
              -81.598165,
              33.081078
            ],
            [
              -81.600211,
              33.083966
            ],
            [
              -81.601655,
              33.084688
            ],
            [
              -81.603587,
              33.084578
            ],
            [
              -81.606836,
              33.081717
            ],
            [
              -81.608995,
              33.0818
            ],
            [
              -81.609837,
              33.082161
            ],
            [
              -81.610078,
              33.082883
            ],
            [
              -81.609837,
              33.084929
            ],
            [
              -81.609533,
              33.086877
            ],
            [
              -81.609476,
              33.089862
            ],
            [
              -81.6108,
              33.09263
            ],
            [
              -81.612725,
              33.093953
            ],
            [
              -81.61429847805029,
              33.0946609670663
            ],
            [
              -81.615132,
              33.095036
            ],
            [
              -81.617779,
              33.095277
            ],
            [
              -81.632232,
              33.093952
            ],
            [
              -81.637232,
              33.092952
            ],
            [
              -81.642333,
              33.093152
            ],
            [
              -81.646433,
              33.094552
            ],
            [
              -81.658433,
              33.103152
            ],
            [
              -81.683533,
              33.112651
            ],
            [
              -81.696934,
              33.116551
            ],
            [
              -81.699834,
              33.116751
            ],
            [
              -81.703134,
              33.116151
            ],
            [
              -81.704634,
              33.116451
            ],
            [
              -81.717134,
              33.124051
            ],
            [
              -81.724334,
              33.129451
            ],
            [
              -81.743835,
              33.14145
            ],
            [
              -81.755135,
              33.15155
            ],
            [
              -81.763135,
              33.159449
            ],
            [
              -81.764435,
              33.165549
            ],
            [
              -81.766735,
              33.170749
            ],
            [
              -81.772435,
              33.180449
            ],
            [
              -81.772435,
              33.181249
            ],
            [
              -81.765735,
              33.187948
            ],
            [
              -81.762835,
              33.188248
            ],
            [
              -81.760635,
              33.189248
            ],
            [
              -81.756935,
              33.197848
            ],
            [
              -81.7570833221477,
              33.1981218255034
            ],
            [
              -81.758235,
              33.200248
            ],
            [
              -81.761635,
              33.201748
            ],
            [
              -81.763535,
              33.203648
            ],
            [
              -81.767635,
              33.215747
            ],
            [
              -81.768935,
              33.217447
            ],
            [
              -81.774035,
              33.221147
            ],
            [
              -81.778435,
              33.221847
            ],
            [
              -81.780135,
              33.221147
            ],
            [
              -81.781035,
              33.219847
            ],
            [
              -81.777335,
              33.214947
            ],
            [
              -81.777535,
              33.211347
            ],
            [
              -81.778935,
              33.209847
            ],
            [
              -81.784535,
              33.208147
            ],
            [
              -81.790236,
              33.208447
            ],
            [
              -81.805236,
              33.211447
            ],
            [
              -81.807336,
              33.212647
            ],
            [
              -81.807936,
              33.213747
            ],
            [
              -81.808136,
              33.219447
            ],
            [
              -81.809636,
              33.222647
            ],
            [
              -81.811736,
              33.223847
            ],
            [
              -81.819636,
              33.226646
            ],
            [
              -81.827936,
              33.228746
            ],
            [
              -81.8315651013874,
              33.233330128068296
            ],
            [
              -81.831736,
              33.233546
            ],
            [
              -81.837016,
              33.237652
            ],
            [
              -81.846536,
              33.241746
            ],
            [
              -81.851979119203,
              33.2473815723462
            ],
            [
              -81.886035,
              33.26185
            ],
            [
              -81.934559,
              33.252963
            ],
            [
              -81.979195,
              33.227165
            ],
            [
              -82.045078,
              33.236386
            ],
            [
              -82.060031,
              33.242566
            ],
            [
              -82.100764,
              33.231629
            ],
            [
              -82.125329,
              33.249665
            ],
            [
              -82.145035,
              33.282061
            ],
            [
              -82.160344,
              33.288949
            ],
            [
              -82.165077,
              33.295998
            ],
            [
              -82.17424,
              33.296771
            ],
            [
              -82.26758,
              33.267397
            ],
            [
              -82.249573,
              33.253254
            ],
            [
              -82.238735,
              33.231896
            ],
            [
              -82.232554,
              33.231211
            ],
            [
              -82.266724,
              33.153402
            ],
            [
              -82.281054,
              33.110354
            ],
            [
              -82.29118,
              33.062827
            ],
            [
              -82.272353,
              32.937796
            ],
            [
              -82.291467,
              32.888454
            ],
            [
              -82.316512,
              32.835772
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "291",
      "COUNTYNS": "00353193",
      "AFFGEOID": "0500000US13291",
      "GEOID": "13291",
      "NAME": "Union",
      "LSAD": "06",
      "ALAND": 834083256,
      "AWATER": 18082753,
      "County_state": "Union,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.178834,
              34.952086
            ],
            [
              -84.171684,
              34.937904
            ],
            [
              -84.149123,
              34.919606
            ],
            [
              -84.133545,
              34.916473
            ],
            [
              -84.107422,
              34.886896
            ],
            [
              -84.11374,
              34.870369
            ],
            [
              -84.142467,
              34.854044
            ],
            [
              -84.147507,
              34.844643
            ],
            [
              -84.140063,
              34.806885
            ],
            [
              -84.123987,
              34.79604
            ],
            [
              -84.093193,
              34.801411
            ],
            [
              -84.09317,
              34.727779
            ],
            [
              -84.10363,
              34.727837
            ],
            [
              -84.158035,
              34.648243
            ],
            [
              -84.148226,
              34.645833
            ],
            [
              -84.124914,
              34.664419
            ],
            [
              -84.098701,
              34.653225
            ],
            [
              -84.07077,
              34.656361
            ],
            [
              -84.054958,
              34.642886
            ],
            [
              -84.03651,
              34.641934
            ],
            [
              -84.024109,
              34.670753
            ],
            [
              -83.994698,
              34.678211
            ],
            [
              -83.982612,
              34.71589
            ],
            [
              -83.93941,
              34.74079
            ],
            [
              -83.900108,
              34.732191
            ],
            [
              -83.877427,
              34.723304
            ],
            [
              -83.856506,
              34.722191
            ],
            [
              -83.830401,
              34.730097
            ],
            [
              -83.825465,
              34.778972
            ],
            [
              -83.780401,
              34.792893
            ],
            [
              -83.7806,
              34.818392
            ],
            [
              -83.7963,
              34.826092
            ],
            [
              -83.7917,
              34.843292
            ],
            [
              -83.8092,
              34.859791
            ],
            [
              -83.8121,
              34.872991
            ],
            [
              -83.804899,
              34.897191
            ],
            [
              -83.810399,
              34.906491
            ],
            [
              -83.819099,
              34.91369
            ],
            [
              -83.8366,
              34.90899
            ],
            [
              -83.832256,
              34.899599
            ],
            [
              -83.852864,
              34.899452
            ],
            [
              -83.865733,
              34.914686
            ],
            [
              -83.915104,
              34.926887
            ],
            [
              -83.927017,
              34.956089
            ],
            [
              -83.945854,
              34.970661
            ],
            [
              -83.936427258766,
              34.9874845938068
            ],
            [
              -83.936646,
              34.987485
            ],
            [
              -84.0052593484451,
              34.9874404516631
            ],
            [
              -84.021357,
              34.98743
            ],
            [
              -84.029954,
              34.987321
            ],
            [
              -84.1294520756424,
              34.9875038108939
            ],
            [
              -84.178834,
              34.952086
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "293",
      "COUNTYNS": "00346759",
      "AFFGEOID": "0500000US13293",
      "GEOID": "13293",
      "NAME": "Upson",
      "LSAD": "06",
      "ALAND": 837697011,
      "AWATER": 10663282,
      "County_state": "Upson,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.52702,
              32.970548
            ],
            [
              -84.53257,
              32.968975
            ],
            [
              -84.518751,
              32.948301
            ],
            [
              -84.526614,
              32.913477
            ],
            [
              -84.508171,
              32.903366
            ],
            [
              -84.506888,
              32.881788
            ],
            [
              -84.475494,
              32.876326
            ],
            [
              -84.489152,
              32.862302
            ],
            [
              -84.480868,
              32.852086
            ],
            [
              -84.46731,
              32.853006
            ],
            [
              -84.457287,
              32.829881
            ],
            [
              -84.442729,
              32.825208
            ],
            [
              -84.434179,
              32.838063
            ],
            [
              -84.420547,
              32.838417
            ],
            [
              -84.401758,
              32.816046
            ],
            [
              -84.391017,
              32.786956
            ],
            [
              -84.380398,
              32.779176
            ],
            [
              -84.357282,
              32.773682
            ],
            [
              -84.349454,
              32.781791
            ],
            [
              -84.339101,
              32.761758
            ],
            [
              -84.316289,
              32.755591
            ],
            [
              -84.30281,
              32.761445
            ],
            [
              -84.286246,
              32.747626
            ],
            [
              -84.262706,
              32.739306
            ],
            [
              -84.25644,
              32.722717
            ],
            [
              -84.235803,
              32.738284
            ],
            [
              -84.236469,
              32.721785
            ],
            [
              -84.220333,
              32.720869
            ],
            [
              -84.202628,
              32.690018
            ],
            [
              -84.149328,
              32.801206
            ],
            [
              -84.124332,
              32.800935
            ],
            [
              -84.124275,
              32.849562
            ],
            [
              -84.12334,
              32.932184
            ],
            [
              -84.122361,
              32.989576
            ],
            [
              -84.27014,
              32.991011
            ],
            [
              -84.490015,
              32.993487
            ],
            [
              -84.505721,
              32.972551
            ],
            [
              -84.52702,
              32.970548
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "297",
      "COUNTYNS": "00351264",
      "AFFGEOID": "0500000US13297",
      "GEOID": "13297",
      "NAME": "Walton",
      "LSAD": "06",
      "ALAND": 845768028,
      "AWATER": 8927725,
      "County_state": "Walton,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.982033,
              33.786054
            ],
            [
              -83.915623,
              33.744702
            ],
            [
              -83.914823,
              33.744203
            ],
            [
              -83.888133,
              33.727607
            ],
            [
              -83.790705,
              33.666656
            ],
            [
              -83.758286,
              33.646351
            ],
            [
              -83.734353,
              33.645639
            ],
            [
              -83.691551,
              33.616989
            ],
            [
              -83.699417,
              33.609447
            ],
            [
              -83.680896,
              33.596919
            ],
            [
              -83.618844,
              33.674944
            ],
            [
              -83.534669,
              33.781544
            ],
            [
              -83.526386,
              33.796119
            ],
            [
              -83.505928,
              33.81776
            ],
            [
              -83.532753,
              33.825584
            ],
            [
              -83.574646,
              33.848999
            ],
            [
              -83.587334,
              33.867638
            ],
            [
              -83.583763,
              33.88385
            ],
            [
              -83.630608,
              33.886882
            ],
            [
              -83.638855,
              33.908306
            ],
            [
              -83.647031,
              33.906198
            ],
            [
              -83.675975,
              33.917542
            ],
            [
              -83.723193,
              33.900162
            ],
            [
              -83.744161,
              33.90568
            ],
            [
              -83.757545,
              33.897167
            ],
            [
              -83.761441,
              33.895137
            ],
            [
              -83.799104,
              33.929844
            ],
            [
              -83.855066,
              33.88531
            ],
            [
              -83.869007,
              33.874159
            ],
            [
              -83.89261,
              33.855276
            ],
            [
              -83.908465,
              33.842845
            ],
            [
              -83.982033,
              33.786054
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "303",
      "COUNTYNS": "00348428",
      "AFFGEOID": "0500000US13303",
      "GEOID": "13303",
      "NAME": "Washington",
      "LSAD": "06",
      "ALAND": 1757156969,
      "AWATER": 15316016,
      "County_state": "Washington,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.068232,
              32.974366
            ],
            [
              -83.073648,
              32.946562
            ],
            [
              -83.061571,
              32.93479
            ],
            [
              -83.048316,
              32.910083
            ],
            [
              -83.050715,
              32.893903
            ],
            [
              -83.037384,
              32.879762
            ],
            [
              -83.027036,
              32.856133
            ],
            [
              -82.998367,
              32.855455
            ],
            [
              -82.969964,
              32.834156
            ],
            [
              -82.954358,
              32.80176
            ],
            [
              -82.960759,
              32.774658
            ],
            [
              -82.955364,
              32.756461
            ],
            [
              -82.946966,
              32.759358
            ],
            [
              -82.802456,
              32.809756
            ],
            [
              -82.798261,
              32.80277
            ],
            [
              -82.773709,
              32.792808
            ],
            [
              -82.768365,
              32.769108
            ],
            [
              -82.732659,
              32.781259
            ],
            [
              -82.711358,
              32.783959
            ],
            [
              -82.680757,
              32.79676
            ],
            [
              -82.667053,
              32.782955
            ],
            [
              -82.609774,
              32.797815
            ],
            [
              -82.560555,
              32.820056
            ],
            [
              -82.521052,
              32.822356
            ],
            [
              -82.525952,
              32.833557
            ],
            [
              -82.510851,
              32.917754
            ],
            [
              -82.539352,
              32.947752
            ],
            [
              -82.543069,
              32.976252
            ],
            [
              -82.550225,
              33.019351
            ],
            [
              -82.555962,
              33.014128
            ],
            [
              -82.598018,
              33.032013
            ],
            [
              -82.604348,
              33.045538
            ],
            [
              -82.617899,
              33.051144
            ],
            [
              -82.612873,
              33.057764
            ],
            [
              -82.641569,
              33.084392
            ],
            [
              -82.638105,
              33.100701
            ],
            [
              -82.645838,
              33.101143
            ],
            [
              -82.661917,
              33.126331
            ],
            [
              -82.708713,
              33.15204
            ],
            [
              -82.719041,
              33.17005
            ],
            [
              -82.739612,
              33.174709
            ],
            [
              -82.733438,
              33.208123
            ],
            [
              -82.748311,
              33.238348
            ],
            [
              -82.777995,
              33.238428
            ],
            [
              -82.798866,
              33.221707
            ],
            [
              -82.825928,
              33.210501
            ],
            [
              -82.832047,
              33.201994
            ],
            [
              -82.855046,
              33.196427
            ],
            [
              -82.867717,
              33.178403
            ],
            [
              -82.888866,
              33.138603
            ],
            [
              -82.900998,
              33.128537
            ],
            [
              -82.914519,
              33.126792
            ],
            [
              -82.926674,
              33.11627
            ],
            [
              -82.950596,
              33.107583
            ],
            [
              -82.970138,
              33.10928
            ],
            [
              -82.990936,
              33.090688
            ],
            [
              -83.00874,
              33.080849
            ],
            [
              -83.052197,
              33.080682
            ],
            [
              -83.044844,
              33.060654
            ],
            [
              -83.067713,
              33.027371
            ],
            [
              -83.051328,
              33.003085
            ],
            [
              -83.049081,
              32.985605
            ],
            [
              -83.068232,
              32.974366
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "305",
      "COUNTYNS": "00350608",
      "AFFGEOID": "0500000US13305",
      "GEOID": "13305",
      "NAME": "Wayne",
      "LSAD": "06",
      "ALAND": 1662305031,
      "AWATER": 18071707,
      "County_state": "Wayne,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.147948,
              31.569123
            ],
            [
              -82.147926,
              31.557308
            ],
            [
              -82.132867,
              31.557514
            ],
            [
              -82.132794,
              31.471262
            ],
            [
              -82.09051,
              31.480009
            ],
            [
              -82.063387,
              31.466362
            ],
            [
              -82.052754,
              31.454569
            ],
            [
              -82.057524,
              31.409117
            ],
            [
              -82.041129,
              31.373721
            ],
            [
              -82.0311,
              31.366239
            ],
            [
              -81.923238,
              31.345875
            ],
            [
              -81.845192,
              31.354745
            ],
            [
              -81.801052,
              31.363737
            ],
            [
              -81.764434,
              31.348085
            ],
            [
              -81.731694,
              31.330048
            ],
            [
              -81.725013,
              31.327101
            ],
            [
              -81.704778,
              31.374917
            ],
            [
              -81.692011,
              31.400491
            ],
            [
              -81.66484,
              31.399719
            ],
            [
              -81.628092,
              31.449865
            ],
            [
              -81.62429,
              31.452982
            ],
            [
              -81.612054,
              31.469405
            ],
            [
              -81.65818,
              31.510147
            ],
            [
              -81.652252,
              31.528551
            ],
            [
              -81.663206,
              31.538667
            ],
            [
              -81.6695,
              31.546955
            ],
            [
              -81.696784,
              31.553977
            ],
            [
              -81.696989,
              31.577864
            ],
            [
              -81.717742,
              31.589056
            ],
            [
              -81.724784,
              31.599431
            ],
            [
              -81.755859,
              31.615449
            ],
            [
              -81.768041,
              31.614106
            ],
            [
              -81.771476,
              31.62803
            ],
            [
              -81.79058,
              31.633882
            ],
            [
              -81.787487,
              31.643151
            ],
            [
              -81.813637,
              31.664458
            ],
            [
              -81.814117,
              31.652303
            ],
            [
              -81.824401,
              31.652066
            ],
            [
              -81.828918,
              31.663958
            ],
            [
              -81.850624,
              31.667671
            ],
            [
              -81.8488,
              31.678495
            ],
            [
              -81.865303,
              31.689285
            ],
            [
              -81.876464,
              31.71315
            ],
            [
              -81.904964,
              31.724303
            ],
            [
              -81.913242,
              31.739734
            ],
            [
              -81.909438,
              31.75031
            ],
            [
              -81.920822,
              31.759916
            ],
            [
              -81.925757,
              31.751812
            ],
            [
              -81.933876,
              31.762392
            ],
            [
              -81.944534,
              31.761127
            ],
            [
              -81.951492,
              31.785852
            ],
            [
              -81.969052,
              31.789324
            ],
            [
              -81.97654,
              31.782607
            ],
            [
              -82.006777,
              31.800047
            ],
            [
              -82.014639,
              31.8218
            ],
            [
              -82.028346,
              31.815513
            ],
            [
              -82.048582,
              31.827075
            ],
            [
              -82.074253,
              31.829296
            ],
            [
              -82.087242,
              31.821003
            ],
            [
              -82.087247,
              31.799509
            ],
            [
              -82.10654,
              31.799798
            ],
            [
              -82.107028,
              31.786101
            ],
            [
              -82.089338,
              31.785903
            ],
            [
              -82.089437,
              31.773434
            ],
            [
              -82.133013,
              31.773404
            ],
            [
              -82.132957,
              31.758148
            ],
            [
              -82.132942,
              31.56931
            ],
            [
              -82.147948,
              31.569123
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "019",
      "COUNTYNS": "00326638",
      "AFFGEOID": "0500000US13019",
      "GEOID": "13019",
      "NAME": "Berrien",
      "LSAD": "06",
      "ALAND": 1170501546,
      "AWATER": 15409110,
      "County_state": "Berrien,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.432505,
              31.332802
            ],
            [
              -83.438823,
              31.316391
            ],
            [
              -83.428794,
              31.293282
            ],
            [
              -83.409405,
              31.286799
            ],
            [
              -83.385684,
              31.258461
            ],
            [
              -83.366156,
              31.249056
            ],
            [
              -83.347036,
              31.25212
            ],
            [
              -83.335949,
              31.244579
            ],
            [
              -83.332181,
              31.215926
            ],
            [
              -83.334279,
              31.191578
            ],
            [
              -83.305611,
              31.167233
            ],
            [
              -83.304813,
              31.147946
            ],
            [
              -83.322471,
              31.118256
            ],
            [
              -83.321403,
              31.097
            ],
            [
              -83.306417,
              31.092672
            ],
            [
              -83.296624,
              31.07192
            ],
            [
              -83.279781,
              31.063351
            ],
            [
              -83.295052,
              31.027278
            ],
            [
              -83.197971,
              31.025405
            ],
            [
              -83.197876,
              31.06221
            ],
            [
              -83.167269,
              31.062473
            ],
            [
              -83.165072,
              31.147198
            ],
            [
              -83.038246,
              31.146705
            ],
            [
              -83.040016,
              31.16789
            ],
            [
              -83.050093,
              31.183571
            ],
            [
              -83.046885,
              31.18368
            ],
            [
              -83.050719,
              31.232088
            ],
            [
              -83.033468,
              31.275041
            ],
            [
              -83.058707,
              31.303925
            ],
            [
              -83.059567,
              31.329857
            ],
            [
              -83.081618,
              31.349814
            ],
            [
              -83.110916,
              31.367971
            ],
            [
              -83.115242,
              31.386076
            ],
            [
              -83.141518,
              31.403894
            ],
            [
              -83.140483,
              31.420395
            ],
            [
              -83.151087,
              31.461552
            ],
            [
              -83.145587,
              31.472276
            ],
            [
              -83.338728,
              31.475991
            ],
            [
              -83.339187,
              31.465061
            ],
            [
              -83.369514,
              31.465055
            ],
            [
              -83.370888,
              31.401142
            ],
            [
              -83.399925,
              31.376423
            ],
            [
              -83.42592,
              31.36372
            ],
            [
              -83.430229,
              31.350664
            ],
            [
              -83.434492,
              31.350361
            ],
            [
              -83.432505,
              31.332802
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "309",
      "COUNTYNS": "00351278",
      "AFFGEOID": "0500000US13309",
      "GEOID": "13309",
      "NAME": "Wheeler",
      "LSAD": "06",
      "ALAND": 765314385,
      "AWATER": 12377029,
      "County_state": "Wheeler,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.927865,
              32.135274
            ],
            [
              -82.939966,
              32.125175
            ],
            [
              -82.915293,
              32.12362
            ],
            [
              -82.901958,
              32.110304
            ],
            [
              -82.901564,
              32.097592
            ],
            [
              -82.887657,
              32.079891
            ],
            [
              -82.86982,
              32.074295
            ],
            [
              -82.852332,
              32.060634
            ],
            [
              -82.83749,
              32.060747
            ],
            [
              -82.801692,
              32.044932
            ],
            [
              -82.786919,
              32.022545
            ],
            [
              -82.748728,
              32.007401
            ],
            [
              -82.728924,
              32.006794
            ],
            [
              -82.695178,
              31.956883
            ],
            [
              -82.669981,
              31.934365
            ],
            [
              -82.652774,
              31.928927
            ],
            [
              -82.645659,
              31.918883
            ],
            [
              -82.596457,
              31.931985
            ],
            [
              -82.564956,
              31.957086
            ],
            [
              -82.544455,
              31.955887
            ],
            [
              -82.543655,
              31.958914
            ],
            [
              -82.539184,
              31.966587
            ],
            [
              -82.547561,
              31.981588
            ],
            [
              -82.597671,
              32.013979
            ],
            [
              -82.598038,
              32.030386
            ],
            [
              -82.607505,
              32.032016
            ],
            [
              -82.602096,
              32.046512
            ],
            [
              -82.613815,
              32.060619
            ],
            [
              -82.608657,
              32.073261
            ],
            [
              -82.622314,
              32.081417
            ],
            [
              -82.611411,
              32.089111
            ],
            [
              -82.623634,
              32.113731
            ],
            [
              -82.62619,
              32.132736
            ],
            [
              -82.613593,
              32.149951
            ],
            [
              -82.612876,
              32.161549
            ],
            [
              -82.626563,
              32.169963
            ],
            [
              -82.632324,
              32.195663
            ],
            [
              -82.625327,
              32.206016
            ],
            [
              -82.635726,
              32.220516
            ],
            [
              -82.63459,
              32.233764
            ],
            [
              -82.648567,
              32.244476
            ],
            [
              -82.636634,
              32.269142
            ],
            [
              -82.651534,
              32.286704
            ],
            [
              -82.645232,
              32.295799
            ],
            [
              -82.655486,
              32.297561
            ],
            [
              -82.67377,
              32.293595
            ],
            [
              -82.694063,
              32.294646
            ],
            [
              -82.721964,
              32.309283
            ],
            [
              -82.761959,
              32.275574
            ],
            [
              -82.755158,
              32.26969
            ],
            [
              -82.768559,
              32.269774
            ],
            [
              -82.815955,
              32.230092
            ],
            [
              -82.858308,
              32.194386
            ],
            [
              -82.884803,
              32.196072
            ],
            [
              -82.87179,
              32.18273
            ],
            [
              -82.927865,
              32.135274
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "073",
      "COUNTYNS": "00348865",
      "AFFGEOID": "0500000US13073",
      "GEOID": "13073",
      "NAME": "Columbia",
      "LSAD": "06",
      "ALAND": 751323672,
      "AWATER": 45373097,
      "County_state": "Columbia,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.441664,
              33.638344
            ],
            [
              -82.430108,
              33.614466
            ],
            [
              -82.432988,
              33.608147
            ],
            [
              -82.412701,
              33.597596
            ],
            [
              -82.413517,
              33.587802
            ],
            [
              -82.432666,
              33.568838
            ],
            [
              -82.436814,
              33.549658
            ],
            [
              -82.384924,
              33.52995
            ],
            [
              -82.402385,
              33.516481
            ],
            [
              -82.398703,
              33.491907
            ],
            [
              -82.383204,
              33.477651
            ],
            [
              -82.384762,
              33.467281
            ],
            [
              -82.364184,
              33.45068
            ],
            [
              -82.33611,
              33.419732
            ],
            [
              -82.340433,
              33.405434
            ],
            [
              -82.294179,
              33.354635
            ],
            [
              -82.28283,
              33.362695
            ],
            [
              -82.128799,
              33.474373
            ],
            [
              -82.124841,
              33.476811
            ],
            [
              -82.108451,
              33.488497
            ],
            [
              -82.070688,
              33.5161
            ],
            [
              -82.053705,
              33.527702
            ],
            [
              -82.028238,
              33.544934
            ],
            [
              -82.033023,
              33.546454
            ],
            [
              -82.034895,
              33.549158
            ],
            [
              -82.037375,
              33.554662
            ],
            [
              -82.046335,
              33.56383
            ],
            [
              -82.048959,
              33.56487
            ],
            [
              -82.054943,
              33.565382
            ],
            [
              -82.05557829987289,
              33.5656996499365
            ],
            [
              -82.057727,
              33.566774
            ],
            [
              -82.069039,
              33.575382
            ],
            [
              -82.073104,
              33.57751
            ],
            [
              -82.087488,
              33.580614
            ],
            [
              -82.094128,
              33.582742
            ],
            [
              -82.096352,
              33.58407
            ],
            [
              -82.098816,
              33.586358
            ],
            [
              -82.10624,
              33.595637
            ],
            [
              -82.109376,
              33.596581
            ],
            [
              -82.11532750427301,
              33.5965013034719
            ],
            [
              -82.116545,
              33.596485
            ],
            [
              -82.120385,
              33.594885
            ],
            [
              -82.125864,
              33.590741
            ],
            [
              -82.12908,
              33.589925
            ],
            [
              -82.133523,
              33.590535
            ],
            [
              -82.142872,
              33.594278
            ],
            [
              -82.1450507828929,
              33.595676027919495
            ],
            [
              -82.148816,
              33.598092
            ],
            [
              -82.15106,
              33.600956
            ],
            [
              -82.153357,
              33.606319
            ],
            [
              -82.156288,
              33.60863
            ],
            [
              -82.158331,
              33.60971
            ],
            [
              -82.161908,
              33.610643
            ],
            [
              -82.1641411027551,
              33.6110870003388
            ],
            [
              -82.174351,
              33.613117
            ],
            [
              -82.179854,
              33.615945
            ],
            [
              -82.186154,
              33.62088
            ],
            [
              -82.196583,
              33.630582
            ],
            [
              -82.201186,
              33.646898
            ],
            [
              -82.199747,
              33.657611
            ],
            [
              -82.19983644896229,
              33.661320448468096
            ],
            [
              -82.199847,
              33.661758
            ],
            [
              -82.200718,
              33.66464
            ],
            [
              -82.208411,
              33.669872
            ],
            [
              -82.209677,
              33.67176
            ],
            [
              -82.212047,
              33.677317
            ],
            [
              -82.216868,
              33.6844
            ],
            [
              -82.218649,
              33.686299
            ],
            [
              -82.240657,
              33.693302
            ],
            [
              -82.276092,
              33.684532
            ],
            [
              -82.306414,
              33.700644
            ],
            [
              -82.338613,
              33.693047
            ],
            [
              -82.351492,
              33.682854
            ],
            [
              -82.412514,
              33.666351
            ],
            [
              -82.425328,
              33.650629
            ],
            [
              -82.441664,
              33.638344
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "091",
      "COUNTYNS": "00348116",
      "AFFGEOID": "0500000US13091",
      "GEOID": "13091",
      "NAME": "Dodge",
      "LSAD": "06",
      "ALAND": 1284379383,
      "AWATER": 18687765,
      "County_state": "Dodge,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.346528,
              32.272489
            ],
            [
              -83.373931,
              32.249741
            ],
            [
              -83.291106,
              32.178825
            ],
            [
              -83.324805,
              32.150367
            ],
            [
              -83.304685,
              32.1332
            ],
            [
              -83.308926,
              32.125709
            ],
            [
              -83.337313,
              32.105885
            ],
            [
              -83.317994,
              32.085903
            ],
            [
              -83.312988,
              32.092481
            ],
            [
              -83.295206,
              32.07918
            ],
            [
              -83.300626,
              32.055052
            ],
            [
              -83.307722,
              32.050919
            ],
            [
              -83.290102,
              32.027444
            ],
            [
              -83.296923,
              32.011245
            ],
            [
              -83.279208,
              31.996728
            ],
            [
              -83.292061,
              31.984506
            ],
            [
              -83.261534,
              31.952076
            ],
            [
              -83.226437,
              31.935931
            ],
            [
              -83.205739,
              31.900325
            ],
            [
              -82.942066,
              32.124074
            ],
            [
              -82.955466,
              32.135274
            ],
            [
              -82.934566,
              32.153274
            ],
            [
              -82.927865,
              32.135274
            ],
            [
              -82.87179,
              32.18273
            ],
            [
              -82.884803,
              32.196072
            ],
            [
              -82.990967,
              32.147274
            ],
            [
              -83.111429,
              32.372051
            ],
            [
              -83.138991,
              32.423069
            ],
            [
              -83.173252,
              32.452533
            ],
            [
              -83.30475,
              32.341958
            ],
            [
              -83.283958,
              32.324311
            ],
            [
              -83.332224,
              32.284033
            ],
            [
              -83.346103,
              32.283818
            ],
            [
              -83.346528,
              32.272489
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "135",
      "COUNTYNS": "01688166",
      "AFFGEOID": "0500000US13135",
      "GEOID": "13135",
      "NAME": "Gwinnett",
      "LSAD": "06",
      "ALAND": 1114851817,
      "AWATER": 16349375,
      "County_state": "Gwinnett,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.277093,
              33.957614
            ],
            [
              -84.27147,
              33.955892
            ],
            [
              -84.259013,
              33.918824
            ],
            [
              -84.258285,
              33.91789
            ],
            [
              -84.252421,
              33.911601
            ],
            [
              -84.237756,
              33.904859
            ],
            [
              -84.237113,
              33.904447
            ],
            [
              -84.23222,
              33.902002
            ],
            [
              -84.213351,
              33.882851
            ],
            [
              -84.207699,
              33.877931
            ],
            [
              -84.203519,
              33.873003
            ],
            [
              -84.17313,
              33.858115
            ],
            [
              -84.157817,
              33.853204
            ],
            [
              -84.148337,
              33.842564
            ],
            [
              -84.134257,
              33.834467
            ],
            [
              -84.123025,
              33.818534
            ],
            [
              -84.113415,
              33.807505
            ],
            [
              -84.097217,
              33.798371
            ],
            [
              -84.093315,
              33.795106
            ],
            [
              -84.075914,
              33.777507
            ],
            [
              -84.046474,
              33.764664
            ],
            [
              -84.040313,
              33.757708
            ],
            [
              -84.023713,
              33.752808
            ],
            [
              -84.01879,
              33.756625
            ],
            [
              -83.982033,
              33.786054
            ],
            [
              -83.908465,
              33.842845
            ],
            [
              -83.89261,
              33.855276
            ],
            [
              -83.869007,
              33.874159
            ],
            [
              -83.855066,
              33.88531
            ],
            [
              -83.799104,
              33.929844
            ],
            [
              -83.813319,
              33.949055
            ],
            [
              -83.812991,
              33.953139
            ],
            [
              -83.824235,
              33.964167
            ],
            [
              -83.83889,
              33.984249
            ],
            [
              -83.869115,
              34.004316
            ],
            [
              -83.864874,
              34.013482
            ],
            [
              -83.833011,
              34.090426
            ],
            [
              -83.817682,
              34.127493
            ],
            [
              -83.86803,
              34.098281
            ],
            [
              -83.890097,
              34.106179
            ],
            [
              -83.914539,
              34.115074
            ],
            [
              -83.973754,
              34.136218
            ],
            [
              -84.062841,
              34.167873
            ],
            [
              -84.074624,
              34.163687
            ],
            [
              -84.0975,
              34.122931
            ],
            [
              -84.110316,
              34.097536
            ],
            [
              -84.113324,
              34.078285
            ],
            [
              -84.117994,
              34.065945
            ],
            [
              -84.097693,
              34.050708
            ],
            [
              -84.123465,
              34.033489
            ],
            [
              -84.169166,
              34.028344
            ],
            [
              -84.18102,
              33.998922
            ],
            [
              -84.203348,
              33.989701
            ],
            [
              -84.212489,
              34.003785
            ],
            [
              -84.233324,
              34.001666
            ],
            [
              -84.233349,
              34.001658
            ],
            [
              -84.254669,
              33.9861
            ],
            [
              -84.26308,
              33.986588
            ],
            [
              -84.262849,
              33.973676
            ],
            [
              -84.25847,
              33.968429
            ],
            [
              -84.277093,
              33.957614
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "085",
      "COUNTYNS": "00351312",
      "AFFGEOID": "0500000US13085",
      "GEOID": "13085",
      "NAME": "Dawson",
      "LSAD": "06",
      "ALAND": 546042216,
      "AWATER": 9272655,
      "County_state": "Dawson,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.345553,
              34.562735
            ],
            [
              -84.345659,
              34.54841
            ],
            [
              -84.319679,
              34.467857
            ],
            [
              -84.256876,
              34.46564
            ],
            [
              -84.257586,
              34.380992
            ],
            [
              -84.258075,
              34.335156
            ],
            [
              -84.148473,
              34.334756
            ],
            [
              -84.018542,
              34.334313
            ],
            [
              -83.957077,
              34.334011
            ],
            [
              -83.981433,
              34.357829
            ],
            [
              -83.984332,
              34.382959
            ],
            [
              -83.971836,
              34.405108
            ],
            [
              -83.980649,
              34.418389
            ],
            [
              -84.106675,
              34.465852
            ],
            [
              -84.115358,
              34.473277
            ],
            [
              -84.167736,
              34.52084
            ],
            [
              -84.19022,
              34.538543
            ],
            [
              -84.19092,
              34.542516
            ],
            [
              -84.188557,
              34.602692
            ],
            [
              -84.196754,
              34.617924
            ],
            [
              -84.223907,
              34.596992
            ],
            [
              -84.254677,
              34.597496
            ],
            [
              -84.255112,
              34.568333
            ],
            [
              -84.295059,
              34.570488
            ],
            [
              -84.307068,
              34.581398
            ],
            [
              -84.334938,
              34.580162
            ],
            [
              -84.345553,
              34.562735
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "167",
      "COUNTYNS": "00346664",
      "AFFGEOID": "0500000US13167",
      "GEOID": "13167",
      "NAME": "Johnson",
      "LSAD": "06",
      "ALAND": 784793323,
      "AWATER": 9278537,
      "County_state": "Johnson,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.94512,
              32.751434
            ],
            [
              -82.957066,
              32.708318
            ],
            [
              -82.862763,
              32.71576
            ],
            [
              -82.785782,
              32.6867
            ],
            [
              -82.794538,
              32.66023
            ],
            [
              -82.668557,
              32.612164
            ],
            [
              -82.674457,
              32.523866
            ],
            [
              -82.647733,
              32.512507
            ],
            [
              -82.623056,
              32.521566
            ],
            [
              -82.602955,
              32.521467
            ],
            [
              -82.60281,
              32.509523
            ],
            [
              -82.597855,
              32.532866
            ],
            [
              -82.585322,
              32.52523
            ],
            [
              -82.564358,
              32.532449
            ],
            [
              -82.589764,
              32.556133
            ],
            [
              -82.594329,
              32.578912
            ],
            [
              -82.533897,
              32.612887
            ],
            [
              -82.512002,
              32.625163
            ],
            [
              -82.517717,
              32.633677
            ],
            [
              -82.498921,
              32.642733
            ],
            [
              -82.492924,
              32.634046
            ],
            [
              -82.463854,
              32.647236
            ],
            [
              -82.490315,
              32.662035
            ],
            [
              -82.49856,
              32.684593
            ],
            [
              -82.470763,
              32.710608
            ],
            [
              -82.452751,
              32.71926
            ],
            [
              -82.452751,
              32.728659
            ],
            [
              -82.434149,
              32.762258
            ],
            [
              -82.458249,
              32.768223
            ],
            [
              -82.502216,
              32.800917
            ],
            [
              -82.521052,
              32.822356
            ],
            [
              -82.560555,
              32.820056
            ],
            [
              -82.609774,
              32.797815
            ],
            [
              -82.667053,
              32.782955
            ],
            [
              -82.680757,
              32.79676
            ],
            [
              -82.711358,
              32.783959
            ],
            [
              -82.732659,
              32.781259
            ],
            [
              -82.768365,
              32.769108
            ],
            [
              -82.773709,
              32.792808
            ],
            [
              -82.798261,
              32.80277
            ],
            [
              -82.802456,
              32.809756
            ],
            [
              -82.946966,
              32.759358
            ],
            [
              -82.94512,
              32.751434
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "163",
      "COUNTYNS": "00345714",
      "AFFGEOID": "0500000US13163",
      "GEOID": "13163",
      "NAME": "Jefferson",
      "LSAD": "06",
      "ALAND": 1363593760,
      "AWATER": 8237192,
      "County_state": "Jefferson,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.661917,
              33.126331
            ],
            [
              -82.645838,
              33.101143
            ],
            [
              -82.638105,
              33.100701
            ],
            [
              -82.641569,
              33.084392
            ],
            [
              -82.612873,
              33.057764
            ],
            [
              -82.617899,
              33.051144
            ],
            [
              -82.604348,
              33.045538
            ],
            [
              -82.598018,
              33.032013
            ],
            [
              -82.555962,
              33.014128
            ],
            [
              -82.550225,
              33.019351
            ],
            [
              -82.543069,
              32.976252
            ],
            [
              -82.539352,
              32.947752
            ],
            [
              -82.510851,
              32.917754
            ],
            [
              -82.525952,
              32.833557
            ],
            [
              -82.521052,
              32.822356
            ],
            [
              -82.502216,
              32.800917
            ],
            [
              -82.458249,
              32.768223
            ],
            [
              -82.434149,
              32.762258
            ],
            [
              -82.398331,
              32.777445
            ],
            [
              -82.419129,
              32.810927
            ],
            [
              -82.361637,
              32.816394
            ],
            [
              -82.35482,
              32.827281
            ],
            [
              -82.320435,
              32.840033
            ],
            [
              -82.316512,
              32.835772
            ],
            [
              -82.291467,
              32.888454
            ],
            [
              -82.272353,
              32.937796
            ],
            [
              -82.29118,
              33.062827
            ],
            [
              -82.281054,
              33.110354
            ],
            [
              -82.266724,
              33.153402
            ],
            [
              -82.232554,
              33.231211
            ],
            [
              -82.238735,
              33.231896
            ],
            [
              -82.249573,
              33.253254
            ],
            [
              -82.26758,
              33.267397
            ],
            [
              -82.295952,
              33.276573
            ],
            [
              -82.30129,
              33.288304
            ],
            [
              -82.341456,
              33.31174
            ],
            [
              -82.353498,
              33.312318
            ],
            [
              -82.371972,
              33.310879
            ],
            [
              -82.383829,
              33.312106
            ],
            [
              -82.431957,
              33.27483
            ],
            [
              -82.470899,
              33.250124
            ],
            [
              -82.585114,
              33.171231
            ],
            [
              -82.57441,
              33.156357
            ],
            [
              -82.578816,
              33.11984
            ],
            [
              -82.589096,
              33.123555
            ],
            [
              -82.661917,
              33.126331
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "103",
      "COUNTYNS": "00350219",
      "AFFGEOID": "0500000US13103",
      "GEOID": "13103",
      "NAME": "Effingham",
      "LSAD": "06",
      "ALAND": 1237281310,
      "AWATER": 13464072,
      "County_state": "Effingham,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.538558,
              32.509059
            ],
            [
              -81.548006,
              32.489286
            ],
            [
              -81.53588,
              32.480801
            ],
            [
              -81.542388,
              32.466087
            ],
            [
              -81.531341,
              32.465942
            ],
            [
              -81.525726,
              32.449818
            ],
            [
              -81.539047,
              32.435077
            ],
            [
              -81.535217,
              32.422443
            ],
            [
              -81.542716,
              32.417446
            ],
            [
              -81.533515,
              32.40704
            ],
            [
              -81.534904,
              32.39358
            ],
            [
              -81.523322,
              32.392944
            ],
            [
              -81.520515,
              32.376812
            ],
            [
              -81.509429,
              32.37146
            ],
            [
              -81.507575,
              32.357792
            ],
            [
              -81.49089,
              32.351695
            ],
            [
              -81.487831,
              32.333886
            ],
            [
              -81.470756,
              32.329552
            ],
            [
              -81.449477,
              32.296719
            ],
            [
              -81.446334,
              32.284717
            ],
            [
              -81.449722,
              32.283535
            ],
            [
              -81.439811,
              32.274914
            ],
            [
              -81.43583,
              32.241289
            ],
            [
              -81.42842,
              32.215899
            ],
            [
              -81.415771,
              32.21181
            ],
            [
              -81.418601,
              32.196582
            ],
            [
              -81.408004,
              32.151871
            ],
            [
              -81.401931,
              32.150184
            ],
            [
              -81.406776,
              32.101931
            ],
            [
              -81.391698,
              32.095886
            ],
            [
              -81.295504,
              32.165211
            ],
            [
              -81.195449,
              32.237591
            ],
            [
              -81.146530388013,
              32.226938177361
            ],
            [
              -81.153531,
              32.237687
            ],
            [
              -81.155995,
              32.241478
            ],
            [
              -81.156587,
              32.24391
            ],
            [
              -81.155595,
              32.246022
            ],
            [
              -81.148334,
              32.255098
            ],
            [
              -81.145834,
              32.263397
            ],
            [
              -81.136534,
              32.272697
            ],
            [
              -81.130834,
              32.274597
            ],
            [
              -81.128034,
              32.276297
            ],
            [
              -81.121433,
              32.284496
            ],
            [
              -81.119633,
              32.287596
            ],
            [
              -81.1196397722772,
              32.2876637227723
            ],
            [
              -81.119833,
              32.289596
            ],
            [
              -81.122333,
              32.305395
            ],
            [
              -81.122933,
              32.307295
            ],
            [
              -81.123933,
              32.308695
            ],
            [
              -81.125433,
              32.309295
            ],
            [
              -81.135733,
              32.324594
            ],
            [
              -81.137633,
              32.328194
            ],
            [
              -81.137032,
              32.329994
            ],
            [
              -81.133032,
              32.334794
            ],
            [
              -81.133632,
              32.341293
            ],
            [
              -81.140932,
              32.349393
            ],
            [
              -81.142532,
              32.350893
            ],
            [
              -81.144032,
              32.351093
            ],
            [
              -81.147632,
              32.349393
            ],
            [
              -81.148477,
              32.347549
            ],
            [
              -81.149073,
              32.346682
            ],
            [
              -81.150589,
              32.34587
            ],
            [
              -81.152105,
              32.345816
            ],
            [
              -81.153296,
              32.345816
            ],
            [
              -81.154,
              32.345924
            ],
            [
              -81.154433,
              32.346087
            ],
            [
              -81.154812,
              32.346412
            ],
            [
              -81.155136,
              32.34717
            ],
            [
              -81.154974,
              32.348794
            ],
            [
              -81.155032,
              32.350093
            ],
            [
              -81.161732,
              32.356092
            ],
            [
              -81.170126,
              32.361318
            ],
            [
              -81.170858,
              32.362722
            ],
            [
              -81.170553,
              32.363821
            ],
            [
              -81.169332,
              32.365591
            ],
            [
              -81.168722,
              32.367544
            ],
            [
              -81.169332,
              32.369436
            ],
            [
              -81.173432,
              32.372591
            ],
            [
              -81.18025865806439,
              32.379566879516794
            ],
            [
              -81.181072,
              32.380398
            ],
            [
              -81.178131,
              32.38459
            ],
            [
              -81.177231,
              32.39169
            ],
            [
              -81.180931,
              32.39649
            ],
            [
              -81.189731,
              32.407289
            ],
            [
              -81.194931,
              32.411489
            ],
            [
              -81.20513,
              32.423788
            ],
            [
              -81.20843,
              32.435987
            ],
            [
              -81.207246,
              32.437542
            ],
            [
              -81.20453,
              32.438687
            ],
            [
              -81.202588,
              32.439833
            ],
            [
              -81.201595,
              32.44136
            ],
            [
              -81.201137,
              32.442964
            ],
            [
              -81.201137,
              32.444033
            ],
            [
              -81.202206,
              32.445484
            ],
            [
              -81.203046,
              32.447164
            ],
            [
              -81.203046,
              32.448844
            ],
            [
              -81.202359,
              32.450448
            ],
            [
              -81.200908,
              32.451593
            ],
            [
              -81.197529,
              32.452086
            ],
            [
              -81.192629,
              32.456286
            ],
            [
              -81.187329,
              32.462886
            ],
            [
              -81.186829,
              32.464086
            ],
            [
              -81.188129,
              32.465386
            ],
            [
              -81.189229,
              32.465586
            ],
            [
              -81.192429,
              32.464786
            ],
            [
              -81.194829,
              32.465086
            ],
            [
              -81.200029,
              32.467985
            ],
            [
              -81.212428,
              32.478685
            ],
            [
              -81.227528,
              32.493884
            ],
            [
              -81.233585,
              32.498488
            ],
            [
              -81.238281,
              32.505988
            ],
            [
              -81.238728,
              32.508896
            ],
            [
              -81.238411,
              32.510192
            ],
            [
              -81.236707,
              32.511402
            ],
            [
              -81.234834,
              32.512271
            ],
            [
              -81.234023,
              32.513778
            ],
            [
              -81.23466,
              32.51627
            ],
            [
              -81.237095,
              32.517314
            ],
            [
              -81.24501,
              32.518317
            ],
            [
              -81.247874,
              32.518231
            ],
            [
              -81.249827,
              32.517541
            ],
            [
              -81.252882,
              32.51833
            ],
            [
              -81.277131,
              32.535417
            ],
            [
              -81.276242,
              32.538558
            ],
            [
              -81.274802,
              32.541143
            ],
            [
              -81.274927,
              32.544158
            ],
            [
              -81.275213,
              32.545202
            ],
            [
              -81.279238,
              32.55459
            ],
            [
              -81.2812975973478,
              32.556440280647
            ],
            [
              -81.281324,
              32.556464
            ],
            [
              -81.29676,
              32.562648
            ],
            [
              -81.297955,
              32.563026
            ],
            [
              -81.300593,
              32.562843
            ],
            [
              -81.309009,
              32.56097
            ],
            [
              -81.320588,
              32.559534
            ],
            [
              -81.328753,
              32.561228
            ],
            [
              -81.366964,
              32.577059
            ],
            [
              -81.368386,
              32.584221
            ],
            [
              -81.368982,
              32.590025
            ],
            [
              -81.369757,
              32.591231
            ],
            [
              -81.37157,
              32.592018
            ],
            [
              -81.373178,
              32.592115
            ],
            [
              -81.376237,
              32.589217
            ],
            [
              -81.379216,
              32.589022
            ],
            [
              -81.380999,
              32.589652
            ],
            [
              -81.3892612409816,
              32.5953827593042
            ],
            [
              -81.538558,
              32.509059
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "173",
      "COUNTYNS": "00348102",
      "AFFGEOID": "0500000US13173",
      "GEOID": "13173",
      "NAME": "Lanier",
      "LSAD": "06",
      "ALAND": 479903057,
      "AWATER": 37546688,
      "County_state": "Lanier,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.197876,
              31.06221
            ],
            [
              -83.197971,
              31.025405
            ],
            [
              -83.180622,
              31.025488
            ],
            [
              -83.180487,
              31.016726
            ],
            [
              -83.179465,
              30.950128
            ],
            [
              -83.042924,
              30.947296
            ],
            [
              -83.036369,
              30.916011
            ],
            [
              -83.022896,
              30.902783
            ],
            [
              -83.021059,
              30.881771
            ],
            [
              -83.025993,
              30.864669
            ],
            [
              -83.019419,
              30.849453
            ],
            [
              -83.013962,
              30.844709
            ],
            [
              -83.006577,
              30.85947
            ],
            [
              -82.971336,
              30.869392
            ],
            [
              -82.971247,
              31.183988
            ],
            [
              -83.046885,
              31.18368
            ],
            [
              -83.050093,
              31.183571
            ],
            [
              -83.040016,
              31.16789
            ],
            [
              -83.038246,
              31.146705
            ],
            [
              -83.165072,
              31.147198
            ],
            [
              -83.167269,
              31.062473
            ],
            [
              -83.197876,
              31.06221
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "119",
      "COUNTYNS": "00349324",
      "AFFGEOID": "0500000US13119",
      "GEOID": "13119",
      "NAME": "Franklin",
      "LSAD": "06",
      "ALAND": 677294070,
      "AWATER": 12811302,
      "County_state": "Franklin,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.398396,
              34.460883
            ],
            [
              -83.382565,
              34.428928
            ],
            [
              -83.382795,
              34.392728
            ],
            [
              -83.393915,
              34.324801
            ],
            [
              -83.337849,
              34.261033
            ],
            [
              -83.355269,
              34.22373
            ],
            [
              -83.313591,
              34.259144
            ],
            [
              -83.297317,
              34.264587
            ],
            [
              -83.273073,
              34.248901
            ],
            [
              -83.239021,
              34.246227
            ],
            [
              -83.202827,
              34.248337
            ],
            [
              -83.172745,
              34.238522
            ],
            [
              -83.11336,
              34.27353
            ],
            [
              -83.105934,
              34.278912
            ],
            [
              -83.094711,
              34.347023
            ],
            [
              -83.107063,
              34.344669
            ],
            [
              -83.094093,
              34.416073
            ],
            [
              -83.0494672080646,
              34.495092874783005
            ],
            [
              -83.054463,
              34.50289
            ],
            [
              -83.057843,
              34.503711
            ],
            [
              -83.065515,
              34.501126
            ],
            [
              -83.069451,
              34.502131
            ],
            [
              -83.087189,
              34.515939
            ],
            [
              -83.086861,
              34.517798
            ],
            [
              -83.077995,
              34.523746
            ],
            [
              -83.078113,
              34.524837
            ],
            [
              -83.084855,
              34.530967
            ],
            [
              -83.087789,
              34.532078
            ],
            [
              -83.092564,
              34.532944
            ],
            [
              -83.09285833837379,
              34.5328466640683
            ],
            [
              -83.096858,
              34.531524
            ],
            [
              -83.102179,
              34.532179
            ],
            [
              -83.103176,
              34.533406
            ],
            [
              -83.10356281094009,
              34.536630219427096
            ],
            [
              -83.177838,
              34.477017
            ],
            [
              -83.373054,
              34.470216
            ],
            [
              -83.398396,
              34.460883
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "271",
      "COUNTYNS": "00356958",
      "AFFGEOID": "0500000US13271",
      "GEOID": "13271",
      "NAME": "Telfair",
      "LSAD": "06",
      "ALAND": 1132612023,
      "AWATER": 17365488,
      "County_state": "Telfair,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.205739,
              31.900325
            ],
            [
              -83.204236,
              31.884682
            ],
            [
              -83.181646,
              31.879715
            ],
            [
              -83.177469,
              31.847857
            ],
            [
              -83.152311,
              31.83166
            ],
            [
              -83.151966,
              31.82488
            ],
            [
              -83.134605,
              31.824115
            ],
            [
              -83.138659,
              31.814446
            ],
            [
              -83.099269,
              31.808295
            ],
            [
              -83.085009,
              31.811451
            ],
            [
              -83.052816,
              31.789224
            ],
            [
              -83.039045,
              31.790566
            ],
            [
              -83.027966,
              31.778291
            ],
            [
              -83.005787,
              31.774629
            ],
            [
              -82.995698,
              31.780983
            ],
            [
              -82.97831,
              31.793201
            ],
            [
              -82.955261,
              31.782999
            ],
            [
              -82.94651,
              31.787752
            ],
            [
              -82.907577,
              31.784847
            ],
            [
              -82.886986,
              31.779581
            ],
            [
              -82.868118,
              31.782347
            ],
            [
              -82.836366,
              31.816154
            ],
            [
              -82.815291,
              31.818436
            ],
            [
              -82.79581,
              31.835096
            ],
            [
              -82.791937,
              31.846732
            ],
            [
              -82.772774,
              31.861677
            ],
            [
              -82.757192,
              31.857031
            ],
            [
              -82.751076,
              31.869214
            ],
            [
              -82.717562,
              31.885782
            ],
            [
              -82.699861,
              31.903582
            ],
            [
              -82.693961,
              31.898282
            ],
            [
              -82.67626,
              31.913182
            ],
            [
              -82.681763,
              31.920741
            ],
            [
              -82.65826,
              31.923583
            ],
            [
              -82.645659,
              31.918883
            ],
            [
              -82.652774,
              31.928927
            ],
            [
              -82.669981,
              31.934365
            ],
            [
              -82.695178,
              31.956883
            ],
            [
              -82.728924,
              32.006794
            ],
            [
              -82.748728,
              32.007401
            ],
            [
              -82.786919,
              32.022545
            ],
            [
              -82.801692,
              32.044932
            ],
            [
              -82.83749,
              32.060747
            ],
            [
              -82.852332,
              32.060634
            ],
            [
              -82.86982,
              32.074295
            ],
            [
              -82.887657,
              32.079891
            ],
            [
              -82.901564,
              32.097592
            ],
            [
              -82.901958,
              32.110304
            ],
            [
              -82.915293,
              32.12362
            ],
            [
              -82.939966,
              32.125175
            ],
            [
              -82.927865,
              32.135274
            ],
            [
              -82.934566,
              32.153274
            ],
            [
              -82.955466,
              32.135274
            ],
            [
              -82.942066,
              32.124074
            ],
            [
              -83.205739,
              31.900325
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "105",
      "COUNTYNS": "00347828",
      "AFFGEOID": "0500000US13105",
      "GEOID": "13105",
      "NAME": "Elbert",
      "LSAD": "06",
      "ALAND": 909303700,
      "AWATER": 59911773,
      "County_state": "Elbert,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.095491,
              34.202488
            ],
            [
              -83.102487,
              34.17305
            ],
            [
              -83.094536,
              34.161659
            ],
            [
              -83.067528,
              34.154804
            ],
            [
              -83.04792,
              34.12109
            ],
            [
              -83.031021,
              34.1133
            ],
            [
              -83.036102,
              34.099148
            ],
            [
              -82.991458,
              34.065054
            ],
            [
              -82.976294,
              34.043219
            ],
            [
              -82.943497,
              34.027843
            ],
            [
              -82.942268,
              34.014957
            ],
            [
              -82.881103,
              34.000141
            ],
            [
              -82.864334,
              33.983658
            ],
            [
              -82.842224,
              33.984058
            ],
            [
              -82.837067,
              33.992604
            ],
            [
              -82.824594,
              33.984381
            ],
            [
              -82.779506,
              33.971124
            ],
            [
              -82.753566,
              33.972253
            ],
            [
              -82.718719,
              33.984776
            ],
            [
              -82.701658,
              33.981346
            ],
            [
              -82.654876,
              33.991265
            ],
            [
              -82.645451,
              33.984195
            ],
            [
              -82.639742,
              33.973018
            ],
            [
              -82.624992,
              33.982319
            ],
            [
              -82.602844,
              33.979782
            ],
            [
              -82.56453073781259,
              33.955740805641796
            ],
            [
              -82.564582,
              33.95581
            ],
            [
              -82.5657,
              33.958682
            ],
            [
              -82.566145,
              33.9639
            ],
            [
              -82.568288,
              33.968772
            ],
            [
              -82.569864,
              33.970684
            ],
            [
              -82.574724,
              33.974113
            ],
            [
              -82.57754,
              33.977034
            ],
            [
              -82.579576,
              33.979761
            ],
            [
              -82.580551,
              33.982463
            ],
            [
              -82.580571,
              33.98514
            ],
            [
              -82.579996,
              33.987011
            ],
            [
              -82.578244,
              33.988671
            ],
            [
              -82.57633,
              33.989694
            ],
            [
              -82.575351,
              33.990904
            ],
            [
              -82.57554,
              33.992049
            ],
            [
              -82.576222,
              33.993106
            ],
            [
              -82.577735,
              33.993743
            ],
            [
              -82.583394,
              33.995286
            ],
            [
              -82.586234,
              33.997151
            ],
            [
              -82.589245,
              34.000118
            ],
            [
              -82.591855,
              34.009018
            ],
            [
              -82.5944878368938,
              34.0139372478806
            ],
            [
              -82.595655,
              34.016118
            ],
            [
              -82.595855,
              34.018518
            ],
            [
              -82.594055,
              34.025917
            ],
            [
              -82.594555,
              34.028717
            ],
            [
              -82.596155,
              34.030517
            ],
            [
              -82.603055,
              34.034817
            ],
            [
              -82.609655,
              34.039917
            ],
            [
              -82.613355,
              34.046816
            ],
            [
              -82.619155,
              34.051316
            ],
            [
              -82.620955,
              34.054416
            ],
            [
              -82.621255,
              34.056916
            ],
            [
              -82.622155,
              34.058516
            ],
            [
              -82.626963,
              34.063457
            ],
            [
              -82.630972,
              34.065528
            ],
            [
              -82.633565,
              34.064822
            ],
            [
              -82.635991,
              34.064941
            ],
            [
              -82.640543,
              34.067595
            ],
            [
              -82.64398,
              34.072237
            ],
            [
              -82.645661,
              34.076046
            ],
            [
              -82.64522,
              34.079046
            ],
            [
              -82.642797,
              34.081312
            ],
            [
              -82.640345,
              34.086304
            ],
            [
              -82.640151,
              34.087609
            ],
            [
              -82.640701,
              34.088341
            ],
            [
              -82.641252,
              34.088914
            ],
            [
              -82.64103,
              34.090861
            ],
            [
              -82.641553,
              34.092212
            ],
            [
              -82.647028,
              34.097825
            ],
            [
              -82.648184,
              34.098649
            ],
            [
              -82.652175,
              34.099704
            ],
            [
              -82.654019,
              34.100346
            ],
            [
              -82.658561,
              34.103118
            ],
            [
              -82.659077,
              34.103544
            ],
            [
              -82.660322,
              34.106897
            ],
            [
              -82.661851,
              34.107754
            ],
            [
              -82.666879,
              34.113591
            ],
            [
              -82.668113,
              34.12016
            ],
            [
              -82.67522,
              34.129779
            ],
            [
              -82.67732,
              34.131657
            ],
            [
              -82.68629,
              34.134454
            ],
            [
              -82.690386,
              34.138293
            ],
            [
              -82.692152,
              34.138986
            ],
            [
              -82.69553,
              34.138815
            ],
            [
              -82.699758,
              34.139318
            ],
            [
              -82.70414,
              34.141007
            ],
            [
              -82.715373,
              34.148165
            ],
            [
              -82.717507,
              34.150504
            ],
            [
              -82.723312,
              34.165895
            ],
            [
              -82.725409,
              34.169774
            ],
            [
              -82.730824,
              34.175906
            ],
            [
              -82.731881,
              34.178363
            ],
            [
              -82.732359,
              34.180564
            ],
            [
              -82.731975,
              34.193154
            ],
            [
              -82.732761,
              34.195338
            ],
            [
              -82.741126673611,
              34.208787562607604
            ],
            [
              -82.74192,
              34.210063
            ],
            [
              -82.74238,
              34.213766
            ],
            [
              -82.740544,
              34.218387
            ],
            [
              -82.740447,
              34.219679
            ],
            [
              -82.744415,
              34.224913
            ],
            [
              -82.743461,
              34.227343
            ],
            [
              -82.74198,
              34.230196
            ],
            [
              -82.744834,
              34.242957
            ],
            [
              -82.744982,
              34.244861
            ],
            [
              -82.744056,
              34.252407
            ],
            [
              -82.748756,
              34.263407
            ],
            [
              -82.748656,
              34.264107
            ],
            [
              -82.746656,
              34.266407
            ],
            [
              -82.749856,
              34.27087
            ],
            [
              -82.755028,
              34.276067
            ],
            [
              -82.761995,
              34.281492
            ],
            [
              -82.762945,
              34.28199
            ],
            [
              -82.765266,
              34.281539
            ],
            [
              -82.770928,
              34.285402
            ],
            [
              -82.7737024263561,
              34.288744030212904
            ],
            [
              -82.82401,
              34.27054
            ],
            [
              -82.915535,
              34.247684
            ],
            [
              -82.958799,
              34.218539
            ],
            [
              -82.980018,
              34.210066
            ],
            [
              -82.99638,
              34.21911
            ],
            [
              -82.988367,
              34.224436
            ],
            [
              -83.018785,
              34.234085
            ],
            [
              -83.078004,
              34.223606
            ],
            [
              -83.095491,
              34.202488
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "185",
      "COUNTYNS": "00326720",
      "AFFGEOID": "0500000US13185",
      "GEOID": "13185",
      "NAME": "Lowndes",
      "LSAD": "06",
      "ALAND": 1285022595,
      "AWATER": 37361466,
      "County_state": "Lowndes,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.484766,
              30.752372
            ],
            [
              -83.467764,
              30.731437
            ],
            [
              -83.468872,
              30.718403
            ],
            [
              -83.43968,
              30.688565
            ],
            [
              -83.417789,
              30.685815
            ],
            [
              -83.412491,
              30.673905
            ],
            [
              -83.394365,
              30.674955
            ],
            [
              -83.362738,
              30.658595
            ],
            [
              -83.3577037830355,
              30.6373471003798
            ],
            [
              -83.34108,
              30.636338
            ],
            [
              -83.311715,
              30.634569
            ],
            [
              -83.309523,
              30.634409
            ],
            [
              -83.309256456485,
              30.634393291556
            ],
            [
              -83.2723449732595,
              30.632217954571797
            ],
            [
              -83.187454,
              30.627215
            ],
            [
              -83.174474,
              30.626436
            ],
            [
              -83.163371,
              30.625887
            ],
            [
              -83.156232,
              30.625496
            ],
            [
              -83.1366140447318,
              30.623976460785002
            ],
            [
              -83.133009,
              30.789649
            ],
            [
              -83.125797,
              30.803306
            ],
            [
              -83.104365,
              30.803703
            ],
            [
              -83.103223,
              30.829582
            ],
            [
              -83.088099,
              30.831193
            ],
            [
              -83.088082,
              30.848658
            ],
            [
              -83.019419,
              30.849453
            ],
            [
              -83.025993,
              30.864669
            ],
            [
              -83.021059,
              30.881771
            ],
            [
              -83.022896,
              30.902783
            ],
            [
              -83.036369,
              30.916011
            ],
            [
              -83.042924,
              30.947296
            ],
            [
              -83.179465,
              30.950128
            ],
            [
              -83.180487,
              31.016726
            ],
            [
              -83.180622,
              31.025488
            ],
            [
              -83.197971,
              31.025405
            ],
            [
              -83.295052,
              31.027278
            ],
            [
              -83.385648,
              31.029408
            ],
            [
              -83.475617,
              31.031964
            ],
            [
              -83.468295,
              31.028481
            ],
            [
              -83.463689,
              31.005935
            ],
            [
              -83.443077,
              30.984995
            ],
            [
              -83.429265,
              30.948733
            ],
            [
              -83.437455,
              30.93865
            ],
            [
              -83.43998,
              30.904513
            ],
            [
              -83.417612,
              30.889391
            ],
            [
              -83.399454,
              30.883904
            ],
            [
              -83.369182,
              30.883956
            ],
            [
              -83.355857,
              30.872178
            ],
            [
              -83.346685,
              30.85285
            ],
            [
              -83.350948,
              30.841798
            ],
            [
              -83.364637,
              30.837407
            ],
            [
              -83.395736,
              30.825445
            ],
            [
              -83.413598,
              30.828882
            ],
            [
              -83.425489,
              30.81553
            ],
            [
              -83.449293,
              30.817957
            ],
            [
              -83.446399,
              30.804856
            ],
            [
              -83.459261,
              30.778605
            ],
            [
              -83.456764,
              30.76578
            ],
            [
              -83.484766,
              30.752372
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "263",
      "COUNTYNS": "00326757",
      "AFFGEOID": "0500000US13263",
      "GEOID": "13263",
      "NAME": "Talbot",
      "LSAD": "06",
      "ALAND": 1013662791,
      "AWATER": 8766849,
      "County_state": "Talbot,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.703196,
              32.700718
            ],
            [
              -84.693899,
              32.686045
            ],
            [
              -84.694603,
              32.583945
            ],
            [
              -84.694663,
              32.563698
            ],
            [
              -84.667463,
              32.563948
            ],
            [
              -84.679453,
              32.535775
            ],
            [
              -84.679967,
              32.531533
            ],
            [
              -84.69451,
              32.518732
            ],
            [
              -84.678562,
              32.518869
            ],
            [
              -84.637323,
              32.534855
            ],
            [
              -84.621486,
              32.529781
            ],
            [
              -84.604919,
              32.53501
            ],
            [
              -84.584858,
              32.522947
            ],
            [
              -84.561404,
              32.528056
            ],
            [
              -84.566024,
              32.540059
            ],
            [
              -84.553826,
              32.548732
            ],
            [
              -84.550957,
              32.542719
            ],
            [
              -84.504816,
              32.541822
            ],
            [
              -84.491784,
              32.548319
            ],
            [
              -84.492065,
              32.561956
            ],
            [
              -84.444353,
              32.562083
            ],
            [
              -84.408866,
              32.561923
            ],
            [
              -84.408378,
              32.610503
            ],
            [
              -84.378432,
              32.631805
            ],
            [
              -84.364243,
              32.679364
            ],
            [
              -84.338884,
              32.679043
            ],
            [
              -84.33794,
              32.720647
            ],
            [
              -84.322266,
              32.721159
            ],
            [
              -84.304132,
              32.733708
            ],
            [
              -84.288743,
              32.736151
            ],
            [
              -84.286246,
              32.747626
            ],
            [
              -84.30281,
              32.761445
            ],
            [
              -84.316289,
              32.755591
            ],
            [
              -84.339101,
              32.761758
            ],
            [
              -84.349454,
              32.781791
            ],
            [
              -84.357282,
              32.773682
            ],
            [
              -84.380398,
              32.779176
            ],
            [
              -84.391017,
              32.786956
            ],
            [
              -84.401758,
              32.816046
            ],
            [
              -84.420547,
              32.838417
            ],
            [
              -84.434179,
              32.838063
            ],
            [
              -84.442729,
              32.825208
            ],
            [
              -84.457287,
              32.829881
            ],
            [
              -84.46731,
              32.853006
            ],
            [
              -84.480868,
              32.852086
            ],
            [
              -84.489152,
              32.862302
            ],
            [
              -84.475494,
              32.876326
            ],
            [
              -84.506888,
              32.881788
            ],
            [
              -84.570669,
              32.845179
            ],
            [
              -84.700538,
              32.84464
            ],
            [
              -84.692428,
              32.829393
            ],
            [
              -84.693057,
              32.738812
            ],
            [
              -84.688048,
              32.730365
            ],
            [
              -84.682876,
              32.73035
            ],
            [
              -84.693248,
              32.722637
            ],
            [
              -84.693421,
              32.708105
            ],
            [
              -84.703196,
              32.700718
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "139",
      "COUNTYNS": "01686953",
      "AFFGEOID": "0500000US13139",
      "GEOID": "13139",
      "NAME": "Hall",
      "LSAD": "06",
      "ALAND": 1017805267,
      "AWATER": 94061469,
      "County_state": "Hall,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.057632,
              34.183277
            ],
            [
              -84.062841,
              34.167873
            ],
            [
              -83.973754,
              34.136218
            ],
            [
              -83.914539,
              34.115074
            ],
            [
              -83.890097,
              34.106179
            ],
            [
              -83.86803,
              34.098281
            ],
            [
              -83.817682,
              34.127493
            ],
            [
              -83.773452,
              34.165076
            ],
            [
              -83.755644,
              34.180189
            ],
            [
              -83.696335,
              34.230622
            ],
            [
              -83.659479,
              34.261873
            ],
            [
              -83.620115,
              34.295276
            ],
            [
              -83.649025,
              34.320984
            ],
            [
              -83.651441,
              34.343715
            ],
            [
              -83.66967,
              34.3661
            ],
            [
              -83.655563,
              34.399746
            ],
            [
              -83.615251,
              34.431748
            ],
            [
              -83.666415,
              34.503602
            ],
            [
              -83.756767,
              34.504457
            ],
            [
              -83.777605,
              34.513696
            ],
            [
              -83.788479,
              34.504754
            ],
            [
              -83.843405,
              34.505494
            ],
            [
              -83.843315,
              34.497022
            ],
            [
              -83.90995,
              34.459805
            ],
            [
              -83.931886,
              34.469345
            ],
            [
              -83.920662,
              34.460114
            ],
            [
              -83.980649,
              34.418389
            ],
            [
              -83.971836,
              34.405108
            ],
            [
              -83.984332,
              34.382959
            ],
            [
              -83.981433,
              34.357829
            ],
            [
              -83.957077,
              34.334011
            ],
            [
              -83.945472,
              34.329242
            ],
            [
              -83.949836,
              34.312305
            ],
            [
              -83.932442,
              34.284916
            ],
            [
              -83.92559,
              34.286418
            ],
            [
              -83.954116,
              34.267509
            ],
            [
              -83.950522,
              34.261587
            ],
            [
              -83.950141,
              34.239528
            ],
            [
              -83.971992,
              34.227905
            ],
            [
              -83.989372,
              34.195487
            ],
            [
              -84.030708,
              34.195312
            ],
            [
              -84.057632,
              34.183277
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "153",
      "COUNTYNS": "01671693",
      "AFFGEOID": "0500000US13153",
      "GEOID": "13153",
      "NAME": "Houston",
      "LSAD": "06",
      "ALAND": 972671487,
      "AWATER": 11319233,
      "County_state": "Houston,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.85654,
              32.382399
            ],
            [
              -83.856587,
              32.357774
            ],
            [
              -83.846831,
              32.357738
            ],
            [
              -83.848379,
              32.29097
            ],
            [
              -83.718434,
              32.290162
            ],
            [
              -83.700403,
              32.28287
            ],
            [
              -83.700362,
              32.289991
            ],
            [
              -83.615579,
              32.288556
            ],
            [
              -83.579403,
              32.336296
            ],
            [
              -83.523458,
              32.402288
            ],
            [
              -83.503081,
              32.389164
            ],
            [
              -83.498039,
              32.401715
            ],
            [
              -83.484136,
              32.405767
            ],
            [
              -83.482648,
              32.424536
            ],
            [
              -83.497923,
              32.452198
            ],
            [
              -83.488421,
              32.485138
            ],
            [
              -83.517935,
              32.498908
            ],
            [
              -83.539907,
              32.52451
            ],
            [
              -83.535696,
              32.529058
            ],
            [
              -83.548147,
              32.571922
            ],
            [
              -83.535053,
              32.586568
            ],
            [
              -83.542949,
              32.625791
            ],
            [
              -83.555777,
              32.652516
            ],
            [
              -83.597656,
              32.664338
            ],
            [
              -83.604728,
              32.660643
            ],
            [
              -83.619315,
              32.664046
            ],
            [
              -83.626522,
              32.669562
            ],
            [
              -83.642972,
              32.671509
            ],
            [
              -83.666134,
              32.674518
            ],
            [
              -83.69018,
              32.686991
            ],
            [
              -83.701029,
              32.692159
            ],
            [
              -83.701152,
              32.692169
            ],
            [
              -83.70109,
              32.691598
            ],
            [
              -83.708914,
              32.674959
            ],
            [
              -83.709152,
              32.651304
            ],
            [
              -83.727768,
              32.651428
            ],
            [
              -83.718631,
              32.634983
            ],
            [
              -83.71918,
              32.595823
            ],
            [
              -83.719859,
              32.53176
            ],
            [
              -83.723139,
              32.521122
            ],
            [
              -83.748697,
              32.534157
            ],
            [
              -83.749007,
              32.505248
            ],
            [
              -83.778007,
              32.505547
            ],
            [
              -83.787186,
              32.512849
            ],
            [
              -83.797694,
              32.496568
            ],
            [
              -83.797712,
              32.44699
            ],
            [
              -83.817118,
              32.439876
            ],
            [
              -83.846676,
              32.468514
            ],
            [
              -83.846203,
              32.439385
            ],
            [
              -83.836673,
              32.439308
            ],
            [
              -83.83696,
              32.393934
            ],
            [
              -83.837041,
              32.382195
            ],
            [
              -83.85654,
              32.382399
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "229",
      "COUNTYNS": "00349086",
      "AFFGEOID": "0500000US13229",
      "GEOID": "13229",
      "NAME": "Pierce",
      "LSAD": "06",
      "ALAND": 881044511,
      "AWATER": 7916649,
      "County_state": "Pierce,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.417246,
              31.417119
            ],
            [
              -82.417412,
              31.404988
            ],
            [
              -82.402561,
              31.404835
            ],
            [
              -82.402554,
              31.390866
            ],
            [
              -82.416985,
              31.390862
            ],
            [
              -82.416987,
              31.378856
            ],
            [
              -82.402631,
              31.378775
            ],
            [
              -82.402633,
              31.364986
            ],
            [
              -82.389451,
              31.364905
            ],
            [
              -82.390217,
              31.310733
            ],
            [
              -82.383705,
              31.291143
            ],
            [
              -82.374603,
              31.290165
            ],
            [
              -82.357979,
              31.263348
            ],
            [
              -82.32247,
              31.238242
            ],
            [
              -82.31504,
              31.225958
            ],
            [
              -82.284561,
              31.224449
            ],
            [
              -82.268616,
              31.216951
            ],
            [
              -82.226288,
              31.22048
            ],
            [
              -82.194824,
              31.20759
            ],
            [
              -82.193474,
              31.202356
            ],
            [
              -82.170128,
              31.217705
            ],
            [
              -82.1464,
              31.226875
            ],
            [
              -82.145515,
              31.252375
            ],
            [
              -82.125854,
              31.258265
            ],
            [
              -82.114822,
              31.270355
            ],
            [
              -82.096695,
              31.27495
            ],
            [
              -82.082451,
              31.271429
            ],
            [
              -82.070099,
              31.276306
            ],
            [
              -82.051933,
              31.271158
            ],
            [
              -82.022178,
              31.278845
            ],
            [
              -82.006775,
              31.277063
            ],
            [
              -81.997421,
              31.288534
            ],
            [
              -81.992114,
              31.307996
            ],
            [
              -82.004875,
              31.310993
            ],
            [
              -82.020202,
              31.327444
            ],
            [
              -82.04084,
              31.362846
            ],
            [
              -82.041129,
              31.373721
            ],
            [
              -82.057524,
              31.409117
            ],
            [
              -82.052754,
              31.454569
            ],
            [
              -82.063387,
              31.466362
            ],
            [
              -82.09051,
              31.480009
            ],
            [
              -82.132794,
              31.471262
            ],
            [
              -82.155075,
              31.471116
            ],
            [
              -82.219044,
              31.518709
            ],
            [
              -82.226585,
              31.530699
            ],
            [
              -82.239254,
              31.530569
            ],
            [
              -82.23883,
              31.517639
            ],
            [
              -82.255946,
              31.517409
            ],
            [
              -82.255829,
              31.505424
            ],
            [
              -82.269584,
              31.505699
            ],
            [
              -82.269661,
              31.492643
            ],
            [
              -82.300422,
              31.493175
            ],
            [
              -82.300621,
              31.467812
            ],
            [
              -82.315025,
              31.467936
            ],
            [
              -82.315111,
              31.457138
            ],
            [
              -82.330021,
              31.457095
            ],
            [
              -82.330493,
              31.442058
            ],
            [
              -82.344989,
              31.441755
            ],
            [
              -82.344843,
              31.43013
            ],
            [
              -82.401512,
              31.430292
            ],
            [
              -82.401794,
              31.417278
            ],
            [
              -82.417246,
              31.417119
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "155",
      "COUNTYNS": "00352302",
      "AFFGEOID": "0500000US13155",
      "GEOID": "13155",
      "NAME": "Irwin",
      "LSAD": "06",
      "ALAND": 917793238,
      "AWATER": 21758147,
      "County_state": "Irwin,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.500702,
              31.59399
            ],
            [
              -83.500951,
              31.570117
            ],
            [
              -83.470825,
              31.569391
            ],
            [
              -83.47093,
              31.555957
            ],
            [
              -83.420699,
              31.553567
            ],
            [
              -83.39726,
              31.531624
            ],
            [
              -83.376785,
              31.482248
            ],
            [
              -83.34527,
              31.476314
            ],
            [
              -83.338728,
              31.475991
            ],
            [
              -83.145587,
              31.472276
            ],
            [
              -83.119817,
              31.473677
            ],
            [
              -83.11166,
              31.478229
            ],
            [
              -83.096731,
              31.506193
            ],
            [
              -83.083514,
              31.516107
            ],
            [
              -83.060119,
              31.56521
            ],
            [
              -83.058807,
              31.611212
            ],
            [
              -83.044848,
              31.610805
            ],
            [
              -83.044424,
              31.62416
            ],
            [
              -83.030189,
              31.623688
            ],
            [
              -83.029361,
              31.636185
            ],
            [
              -83.014174,
              31.635715
            ],
            [
              -83.01342,
              31.660801
            ],
            [
              -82.998794,
              31.660467
            ],
            [
              -82.99836,
              31.673164
            ],
            [
              -83.176376,
              31.678382
            ],
            [
              -83.177218,
              31.651691
            ],
            [
              -83.266363,
              31.654203
            ],
            [
              -83.265636,
              31.679859
            ],
            [
              -83.325617,
              31.680603
            ],
            [
              -83.325296,
              31.756584
            ],
            [
              -83.339868,
              31.756599
            ],
            [
              -83.339664,
              31.769552
            ],
            [
              -83.369642,
              31.769651
            ],
            [
              -83.370272,
              31.757056
            ],
            [
              -83.453634,
              31.757861
            ],
            [
              -83.466637,
              31.741806
            ],
            [
              -83.452919,
              31.708597
            ],
            [
              -83.460637,
              31.69539
            ],
            [
              -83.490672,
              31.696093
            ],
            [
              -83.491647,
              31.61941
            ],
            [
              -83.500702,
              31.59399
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "181",
      "COUNTYNS": "01688000",
      "AFFGEOID": "0500000US13181",
      "GEOID": "13181",
      "NAME": "Lincoln",
      "LSAD": "06",
      "ALAND": 544880787,
      "AWATER": 121516946,
      "County_state": "Lincoln,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.645451,
              33.984195
            ],
            [
              -82.645043,
              33.981048
            ],
            [
              -82.617125,
              33.892979
            ],
            [
              -82.605687,
              33.859942
            ],
            [
              -82.576909,
              33.873977
            ],
            [
              -82.573282,
              33.8557
            ],
            [
              -82.595596,
              33.828898
            ],
            [
              -82.573652,
              33.786116
            ],
            [
              -82.479518,
              33.639535
            ],
            [
              -82.458237,
              33.637471
            ],
            [
              -82.430915,
              33.655076
            ],
            [
              -82.425328,
              33.650629
            ],
            [
              -82.412514,
              33.666351
            ],
            [
              -82.351492,
              33.682854
            ],
            [
              -82.338613,
              33.693047
            ],
            [
              -82.306414,
              33.700644
            ],
            [
              -82.276092,
              33.684532
            ],
            [
              -82.240657,
              33.693302
            ],
            [
              -82.218649,
              33.686299
            ],
            [
              -82.222709,
              33.689124
            ],
            [
              -82.234576,
              33.700216
            ],
            [
              -82.237192,
              33.70788
            ],
            [
              -82.235753,
              33.71439
            ],
            [
              -82.239098,
              33.730872
            ],
            [
              -82.240405,
              33.734901
            ],
            [
              -82.246161,
              33.746347
            ],
            [
              -82.247472,
              33.752591
            ],
            [
              -82.255267,
              33.75969
            ],
            [
              -82.258049,
              33.760429
            ],
            [
              -82.259471,
              33.760245
            ],
            [
              -82.263206,
              33.761962
            ],
            [
              -82.26438,
              33.763481
            ],
            [
              -82.265019,
              33.765742
            ],
            [
              -82.266127,
              33.766745
            ],
            [
              -82.267719,
              33.767651
            ],
            [
              -82.270445,
              33.767913
            ],
            [
              -82.277681,
              33.772032
            ],
            [
              -82.28106,
              33.776056
            ],
            [
              -82.285804,
              33.780058
            ],
            [
              -82.289762,
              33.782032
            ],
            [
              -82.292468,
              33.782406
            ],
            [
              -82.294984,
              33.781868
            ],
            [
              -82.298286,
              33.783518
            ],
            [
              -82.299393,
              33.785037
            ],
            [
              -82.299601,
              33.786483
            ],
            [
              -82.298923,
              33.795839
            ],
            [
              -82.29928,
              33.798939
            ],
            [
              -82.300213,
              33.800627
            ],
            [
              -82.302885,
              33.802907
            ],
            [
              -82.308997,
              33.805892
            ],
            [
              -82.313339,
              33.809205
            ],
            [
              -82.314746,
              33.811499
            ],
            [
              -82.32448,
              33.820033
            ],
            [
              -82.337829,
              33.827156
            ],
            [
              -82.346933,
              33.834298
            ],
            [
              -82.351881,
              33.836432
            ],
            [
              -82.369107,
              33.842375
            ],
            [
              -82.371775,
              33.843813
            ],
            [
              -82.374286,
              33.84559
            ],
            [
              -82.37975,
              33.851086
            ],
            [
              -82.384973,
              33.854428
            ],
            [
              -82.390527,
              33.857162
            ],
            [
              -82.395736,
              33.859089
            ],
            [
              -82.400517,
              33.863343
            ],
            [
              -82.403881,
              33.865477
            ],
            [
              -82.408354,
              33.86632
            ],
            [
              -82.414259,
              33.865348
            ],
            [
              -82.417871,
              33.864233
            ],
            [
              -82.422803,
              33.863754
            ],
            [
              -82.429164,
              33.865844
            ],
            [
              -82.43115,
              33.867051
            ],
            [
              -82.438644,
              33.873919
            ],
            [
              -82.440503,
              33.875123
            ],
            [
              -82.448109,
              33.877543
            ],
            [
              -82.455105,
              33.88165
            ],
            [
              -82.459391,
              33.886386
            ],
            [
              -82.469913,
              33.892838
            ],
            [
              -82.480111,
              33.901897
            ],
            [
              -82.492929,
              33.909754
            ],
            [
              -82.496109,
              33.913459
            ],
            [
              -82.503584,
              33.926048
            ],
            [
              -82.50764,
              33.931456
            ],
            [
              -82.51295,
              33.936969
            ],
            [
              -82.524515,
              33.94336
            ],
            [
              -82.526741,
              33.943765
            ],
            [
              -82.534111,
              33.943651
            ],
            [
              -82.53977,
              33.941551
            ],
            [
              -82.543128,
              33.940949
            ],
            [
              -82.554497,
              33.943819
            ],
            [
              -82.556835,
              33.945353
            ],
            [
              -82.56453073781259,
              33.955740805641796
            ],
            [
              -82.602844,
              33.979782
            ],
            [
              -82.624992,
              33.982319
            ],
            [
              -82.639742,
              33.973018
            ],
            [
              -82.645451,
              33.984195
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "183",
      "COUNTYNS": "00326719",
      "AFFGEOID": "0500000US13183",
      "GEOID": "13183",
      "NAME": "Long",
      "LSAD": "06",
      "ALAND": 1037005778,
      "AWATER": 8862741,
      "County_state": "Long,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.980042,
              31.804163
            ],
            [
              -81.981033,
              31.791367
            ],
            [
              -81.969052,
              31.789324
            ],
            [
              -81.951492,
              31.785852
            ],
            [
              -81.944534,
              31.761127
            ],
            [
              -81.933876,
              31.762392
            ],
            [
              -81.925757,
              31.751812
            ],
            [
              -81.920822,
              31.759916
            ],
            [
              -81.909438,
              31.75031
            ],
            [
              -81.913242,
              31.739734
            ],
            [
              -81.904964,
              31.724303
            ],
            [
              -81.876464,
              31.71315
            ],
            [
              -81.865303,
              31.689285
            ],
            [
              -81.8488,
              31.678495
            ],
            [
              -81.850624,
              31.667671
            ],
            [
              -81.828918,
              31.663958
            ],
            [
              -81.824401,
              31.652066
            ],
            [
              -81.814117,
              31.652303
            ],
            [
              -81.813637,
              31.664458
            ],
            [
              -81.787487,
              31.643151
            ],
            [
              -81.79058,
              31.633882
            ],
            [
              -81.771476,
              31.62803
            ],
            [
              -81.768041,
              31.614106
            ],
            [
              -81.755859,
              31.615449
            ],
            [
              -81.724784,
              31.599431
            ],
            [
              -81.717742,
              31.589056
            ],
            [
              -81.696989,
              31.577864
            ],
            [
              -81.696784,
              31.553977
            ],
            [
              -81.6695,
              31.546955
            ],
            [
              -81.663206,
              31.538667
            ],
            [
              -81.622216,
              31.551295
            ],
            [
              -81.566009,
              31.576467
            ],
            [
              -81.491798,
              31.699575
            ],
            [
              -81.499603,
              31.699968
            ],
            [
              -81.627151,
              31.759462
            ],
            [
              -81.641761,
              31.765451
            ],
            [
              -81.64331,
              31.7859
            ],
            [
              -81.660331,
              31.800806
            ],
            [
              -81.68811,
              31.8094
            ],
            [
              -81.73561,
              31.853708
            ],
            [
              -81.765357,
              31.87454
            ],
            [
              -81.765777,
              31.887847
            ],
            [
              -81.745414,
              31.936275
            ],
            [
              -81.74694,
              31.969326
            ],
            [
              -81.779273,
              31.981004
            ],
            [
              -81.793922,
              31.977796
            ],
            [
              -81.824405,
              32.014882
            ],
            [
              -81.854576,
              31.988328
            ],
            [
              -81.863113,
              31.972961
            ],
            [
              -81.876236,
              31.968056
            ],
            [
              -81.889168,
              31.947071
            ],
            [
              -81.881996,
              31.918341
            ],
            [
              -81.892471,
              31.895264
            ],
            [
              -81.912529,
              31.886833
            ],
            [
              -81.921921,
              31.852289
            ],
            [
              -81.940414,
              31.839872
            ],
            [
              -81.955132,
              31.809851
            ],
            [
              -81.980042,
              31.804163
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "207",
      "COUNTYNS": "00343073",
      "AFFGEOID": "0500000US13207",
      "GEOID": "13207",
      "NAME": "Monroe",
      "LSAD": "06",
      "ALAND": 1025872423,
      "AWATER": 5643844,
      "County_state": "Monroe,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.12334,
              32.932184
            ],
            [
              -84.124275,
              32.849562
            ],
            [
              -83.978409,
              32.848936
            ],
            [
              -83.968309,
              32.857136
            ],
            [
              -83.968421,
              32.84886
            ],
            [
              -83.934953,
              32.848582
            ],
            [
              -83.891922,
              32.848351
            ],
            [
              -83.872752,
              32.859489
            ],
            [
              -83.863831,
              32.864683
            ],
            [
              -83.725855,
              32.944905
            ],
            [
              -83.734112,
              32.952393
            ],
            [
              -83.710685,
              32.952792
            ],
            [
              -83.708045,
              32.966615
            ],
            [
              -83.722429,
              32.975687
            ],
            [
              -83.729647,
              33.013565
            ],
            [
              -83.721212,
              33.023364
            ],
            [
              -83.72537,
              33.032666
            ],
            [
              -83.737309,
              33.043866
            ],
            [
              -83.747004,
              33.063373
            ],
            [
              -83.773917,
              33.07936
            ],
            [
              -83.794708,
              33.104086
            ],
            [
              -83.799014,
              33.116578
            ],
            [
              -83.816845,
              33.126463
            ],
            [
              -83.816048,
              33.131816
            ],
            [
              -83.813919,
              33.147633
            ],
            [
              -83.822929,
              33.157856
            ],
            [
              -83.822261,
              33.180238
            ],
            [
              -83.842615,
              33.196773
            ],
            [
              -83.859238,
              33.200495
            ],
            [
              -84.041498,
              33.202629
            ],
            [
              -84.041355,
              33.129603
            ],
            [
              -84.04288,
              33.028716
            ],
            [
              -84.044113,
              32.947874
            ],
            [
              -84.054175,
              32.931497
            ],
            [
              -84.12334,
              32.932184
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "219",
      "COUNTYNS": "00356774",
      "AFFGEOID": "0500000US13219",
      "GEOID": "13219",
      "NAME": "Oconee",
      "LSAD": "06",
      "ALAND": 477396249,
      "AWATER": 5305211,
      "County_state": "Oconee,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.647031,
              33.906198
            ],
            [
              -83.638855,
              33.908306
            ],
            [
              -83.630608,
              33.886882
            ],
            [
              -83.583763,
              33.88385
            ],
            [
              -83.587334,
              33.867638
            ],
            [
              -83.574646,
              33.848999
            ],
            [
              -83.532753,
              33.825584
            ],
            [
              -83.505928,
              33.81776
            ],
            [
              -83.479523,
              33.802265
            ],
            [
              -83.461861,
              33.774128
            ],
            [
              -83.44532,
              33.722599
            ],
            [
              -83.425308,
              33.716015
            ],
            [
              -83.406189,
              33.698307
            ],
            [
              -83.29145,
              33.734315
            ],
            [
              -83.297394,
              33.739059
            ],
            [
              -83.28034,
              33.761774
            ],
            [
              -83.280914,
              33.783424
            ],
            [
              -83.306619,
              33.811444
            ],
            [
              -83.275933,
              33.847977
            ],
            [
              -83.325197,
              33.854971
            ],
            [
              -83.32753,
              33.8689
            ],
            [
              -83.360973,
              33.87653
            ],
            [
              -83.376712,
              33.890433
            ],
            [
              -83.377696,
              33.890764
            ],
            [
              -83.382472,
              33.898387
            ],
            [
              -83.40514,
              33.913413
            ],
            [
              -83.421933,
              33.927055
            ],
            [
              -83.435415,
              33.922732
            ],
            [
              -83.491684,
              33.932453
            ],
            [
              -83.508452,
              33.931233
            ],
            [
              -83.537385,
              33.965912
            ],
            [
              -83.56669,
              33.950138
            ],
            [
              -83.570786,
              33.947936
            ],
            [
              -83.647031,
              33.906198
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "225",
      "COUNTYNS": "00343153",
      "AFFGEOID": "0500000US13225",
      "GEOID": "13225",
      "NAME": "Peach",
      "LSAD": "06",
      "ALAND": 389188297,
      "AWATER": 2678495,
      "County_state": "Peach,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.016609,
              32.511741
            ],
            [
              -84.018147,
              32.506406
            ],
            [
              -83.932472,
              32.50569
            ],
            [
              -83.923363,
              32.511355
            ],
            [
              -83.931122,
              32.483745
            ],
            [
              -83.922832,
              32.497428
            ],
            [
              -83.900503,
              32.496982
            ],
            [
              -83.877066,
              32.489015
            ],
            [
              -83.872089,
              32.479014
            ],
            [
              -83.846676,
              32.468514
            ],
            [
              -83.817118,
              32.439876
            ],
            [
              -83.797712,
              32.44699
            ],
            [
              -83.797694,
              32.496568
            ],
            [
              -83.787186,
              32.512849
            ],
            [
              -83.778007,
              32.505547
            ],
            [
              -83.749007,
              32.505248
            ],
            [
              -83.748697,
              32.534157
            ],
            [
              -83.723139,
              32.521122
            ],
            [
              -83.719859,
              32.53176
            ],
            [
              -83.71918,
              32.595823
            ],
            [
              -83.718631,
              32.634983
            ],
            [
              -83.727768,
              32.651428
            ],
            [
              -83.709152,
              32.651304
            ],
            [
              -83.708914,
              32.674959
            ],
            [
              -83.70109,
              32.691598
            ],
            [
              -83.766787,
              32.692622
            ],
            [
              -83.882642,
              32.613103
            ],
            [
              -83.945364,
              32.569617
            ],
            [
              -83.945237,
              32.552667
            ],
            [
              -83.954708,
              32.563048
            ],
            [
              -84.003363,
              32.529927
            ],
            [
              -84.00849,
              32.521769
            ],
            [
              -84.016609,
              32.511741
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "239",
      "COUNTYNS": "00350547",
      "AFFGEOID": "0500000US13239",
      "GEOID": "13239",
      "NAME": "Quitman",
      "LSAD": "06",
      "ALAND": 391703077,
      "AWATER": 24158294,
      "County_state": "Quitman,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.141831,
              31.839261
            ],
            [
              -85.131331,
              31.817562
            ],
            [
              -85.1274996270141,
              31.7802526552609
            ],
            [
              -85.073829,
              31.779063
            ],
            [
              -85.073941,
              31.771441
            ],
            [
              -85.026627,
              31.770963
            ],
            [
              -85.026527,
              31.778862
            ],
            [
              -84.95864,
              31.777854
            ],
            [
              -84.910338,
              31.776841
            ],
            [
              -84.907006,
              31.924465
            ],
            [
              -84.917204,
              31.933087
            ],
            [
              -84.936137,
              31.933755
            ],
            [
              -84.935759,
              31.941837
            ],
            [
              -84.955249,
              31.942261
            ],
            [
              -84.954904,
              31.950285
            ],
            [
              -84.974067,
              31.950209
            ],
            [
              -84.973701,
              31.959299
            ],
            [
              -84.954034,
              31.966769
            ],
            [
              -84.9537,
              31.974781
            ],
            [
              -85.042029,
              31.975658
            ],
            [
              -85.041929,
              31.984757
            ],
            [
              -85.060929,
              31.9900776803197
            ],
            [
              -85.067829,
              31.967358
            ],
            [
              -85.07893,
              31.940159
            ],
            [
              -85.114031,
              31.89336
            ],
            [
              -85.128431,
              31.87756
            ],
            [
              -85.141831,
              31.839261
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "243",
      "COUNTYNS": "00352242",
      "AFFGEOID": "0500000US13243",
      "GEOID": "13243",
      "NAME": "Randolph",
      "LSAD": "06",
      "ALAND": 1109126226,
      "AWATER": 6830041,
      "County_state": "Randolph,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.95864,
              31.777854
            ],
            [
              -84.959324,
              31.713378
            ],
            [
              -84.949381,
              31.713401
            ],
            [
              -84.949784,
              31.679914
            ],
            [
              -84.930998,
              31.672309
            ],
            [
              -84.931349,
              31.655425
            ],
            [
              -84.942511,
              31.655552
            ],
            [
              -84.942353,
              31.618669
            ],
            [
              -84.817843,
              31.619771
            ],
            [
              -84.656517,
              31.619981
            ],
            [
              -84.649297,
              31.64367
            ],
            [
              -84.622229,
              31.637824
            ],
            [
              -84.622521,
              31.628465
            ],
            [
              -84.606931,
              31.628255
            ],
            [
              -84.59715,
              31.637613
            ],
            [
              -84.59741,
              31.621358
            ],
            [
              -84.586501,
              31.621159
            ],
            [
              -84.577972,
              31.637252
            ],
            [
              -84.578024,
              31.621098
            ],
            [
              -84.568823,
              31.628627
            ],
            [
              -84.546854,
              31.621199
            ],
            [
              -84.536152,
              31.646923
            ],
            [
              -84.535434,
              31.676494
            ],
            [
              -84.546877,
              31.712421
            ],
            [
              -84.560918,
              31.732755
            ],
            [
              -84.570329,
              31.760662
            ],
            [
              -84.563453,
              31.770412
            ],
            [
              -84.603086,
              31.772001
            ],
            [
              -84.599776,
              31.920171
            ],
            [
              -84.65582,
              31.920308
            ],
            [
              -84.67583,
              31.920531
            ],
            [
              -84.675788,
              31.92868
            ],
            [
              -84.685518,
              31.920314
            ],
            [
              -84.907006,
              31.924465
            ],
            [
              -84.910338,
              31.776841
            ],
            [
              -84.95864,
              31.777854
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "259",
      "COUNTYNS": "00347360",
      "AFFGEOID": "0500000US13259",
      "GEOID": "13259",
      "NAME": "Stewart",
      "LSAD": "06",
      "ALAND": 1188153052,
      "AWATER": 12598484,
      "County_state": "Stewart,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.053815,
              32.013502
            ],
            [
              -85.060929,
              31.9900776803197
            ],
            [
              -85.041929,
              31.984757
            ],
            [
              -85.042029,
              31.975658
            ],
            [
              -84.9537,
              31.974781
            ],
            [
              -84.954034,
              31.966769
            ],
            [
              -84.973701,
              31.959299
            ],
            [
              -84.974067,
              31.950209
            ],
            [
              -84.954904,
              31.950285
            ],
            [
              -84.955249,
              31.942261
            ],
            [
              -84.935759,
              31.941837
            ],
            [
              -84.936137,
              31.933755
            ],
            [
              -84.917204,
              31.933087
            ],
            [
              -84.907006,
              31.924465
            ],
            [
              -84.685518,
              31.920314
            ],
            [
              -84.675788,
              31.92868
            ],
            [
              -84.67583,
              31.920531
            ],
            [
              -84.65582,
              31.920308
            ],
            [
              -84.655013,
              31.961534
            ],
            [
              -84.645357,
              31.961495
            ],
            [
              -84.644805,
              31.984316
            ],
            [
              -84.655173,
              31.984355
            ],
            [
              -84.654119,
              32.028458
            ],
            [
              -84.644419,
              32.028858
            ],
            [
              -84.64242,
              32.160154
            ],
            [
              -84.63112,
              32.184054
            ],
            [
              -84.64032,
              32.225253
            ],
            [
              -84.64932,
              32.225153
            ],
            [
              -84.649319,
              32.232953
            ],
            [
              -84.65892,
              32.232852
            ],
            [
              -84.802524,
              32.230851
            ],
            [
              -84.802525,
              32.220951
            ],
            [
              -84.814024,
              32.221051
            ],
            [
              -84.813924,
              32.230851
            ],
            [
              -84.9199421479259,
              32.2308482009333
            ],
            [
              -84.930127,
              32.219051
            ],
            [
              -84.967428,
              32.219351
            ],
            [
              -84.961728,
              32.19865
            ],
            [
              -85.011267,
              32.180493
            ],
            [
              -85.058749,
              32.136018
            ],
            [
              -85.047063,
              32.087389
            ],
            [
              -85.04934254607609,
              32.0624439732037
            ],
            [
              -85.053815,
              32.013502
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "159",
      "COUNTYNS": "00346144",
      "AFFGEOID": "0500000US13159",
      "GEOID": "13159",
      "NAME": "Jasper",
      "LSAD": "06",
      "ALAND": 953536358,
      "AWATER": 13732175,
      "County_state": "Jasper,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.863058,
              33.368278
            ],
            [
              -83.863362,
              33.342317
            ],
            [
              -83.840409,
              33.328902
            ],
            [
              -83.835901,
              33.306325
            ],
            [
              -83.827408,
              33.291987
            ],
            [
              -83.832459,
              33.272781
            ],
            [
              -83.814103,
              33.242134
            ],
            [
              -83.82575,
              33.210275
            ],
            [
              -83.80729,
              33.192423
            ],
            [
              -83.822261,
              33.180238
            ],
            [
              -83.822929,
              33.157856
            ],
            [
              -83.813919,
              33.147633
            ],
            [
              -83.816048,
              33.131816
            ],
            [
              -83.545876,
              33.171944
            ],
            [
              -83.537811,
              33.393803
            ],
            [
              -83.533736,
              33.434472
            ],
            [
              -83.586817,
              33.465577
            ],
            [
              -83.682196,
              33.52622
            ],
            [
              -83.688143,
              33.510763
            ],
            [
              -83.674644,
              33.487225
            ],
            [
              -83.687601,
              33.483117
            ],
            [
              -83.705197,
              33.500812
            ],
            [
              -83.704743,
              33.488518
            ],
            [
              -83.736687,
              33.480862
            ],
            [
              -83.771278,
              33.452495
            ],
            [
              -83.824922,
              33.437263
            ],
            [
              -83.830792,
              33.419186
            ],
            [
              -83.845207,
              33.414721
            ],
            [
              -83.840191,
              33.399748
            ],
            [
              -83.831024,
              33.393462
            ],
            [
              -83.863058,
              33.368278
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "117",
      "COUNTYNS": "01673358",
      "AFFGEOID": "0500000US13117",
      "GEOID": "13117",
      "NAME": "Forsyth",
      "LSAD": "06",
      "ALAND": 580598432,
      "AWATER": 59439960,
      "County_state": "Forsyth,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.258743,
              34.185909
            ],
            [
              -84.258934,
              34.109539
            ],
            [
              -84.227465,
              34.097143
            ],
            [
              -84.216177,
              34.099819
            ],
            [
              -84.205969,
              34.090679
            ],
            [
              -84.205837,
              34.090603
            ],
            [
              -84.19043,
              34.086317
            ],
            [
              -84.178344,
              34.070402
            ],
            [
              -84.143442,
              34.055315
            ],
            [
              -84.097693,
              34.050708
            ],
            [
              -84.117994,
              34.065945
            ],
            [
              -84.113324,
              34.078285
            ],
            [
              -84.110316,
              34.097536
            ],
            [
              -84.0975,
              34.122931
            ],
            [
              -84.074624,
              34.163687
            ],
            [
              -84.062841,
              34.167873
            ],
            [
              -84.057632,
              34.183277
            ],
            [
              -84.030708,
              34.195312
            ],
            [
              -83.989372,
              34.195487
            ],
            [
              -83.971992,
              34.227905
            ],
            [
              -83.950141,
              34.239528
            ],
            [
              -83.950522,
              34.261587
            ],
            [
              -83.954116,
              34.267509
            ],
            [
              -83.92559,
              34.286418
            ],
            [
              -83.932442,
              34.284916
            ],
            [
              -83.949836,
              34.312305
            ],
            [
              -83.945472,
              34.329242
            ],
            [
              -83.957077,
              34.334011
            ],
            [
              -84.018542,
              34.334313
            ],
            [
              -84.148473,
              34.334756
            ],
            [
              -84.258075,
              34.335156
            ],
            [
              -84.258576,
              34.288206
            ],
            [
              -84.258689,
              34.237094
            ],
            [
              -84.258919,
              34.207317
            ],
            [
              -84.258743,
              34.185909
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "265",
      "COUNTYNS": "00347654",
      "AFFGEOID": "0500000US13265",
      "GEOID": "13265",
      "NAME": "Taliaferro",
      "LSAD": "06",
      "ALAND": 504031039,
      "AWATER": 1915743,
      "County_state": "Taliaferro,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.989899,
              33.603279
            ],
            [
              -83.014038,
              33.580383
            ],
            [
              -82.986035,
              33.520356
            ],
            [
              -82.985588,
              33.489841
            ],
            [
              -83.012853,
              33.469178
            ],
            [
              -82.983829,
              33.447635
            ],
            [
              -82.951222,
              33.451787
            ],
            [
              -82.93966,
              33.442694
            ],
            [
              -82.901795,
              33.438788
            ],
            [
              -82.872597,
              33.446416
            ],
            [
              -82.851954,
              33.443543
            ],
            [
              -82.866605,
              33.466599
            ],
            [
              -82.823141,
              33.504075
            ],
            [
              -82.815315,
              33.521137
            ],
            [
              -82.787605,
              33.5107
            ],
            [
              -82.767286,
              33.51457
            ],
            [
              -82.749072,
              33.510685
            ],
            [
              -82.740128,
              33.528618
            ],
            [
              -82.722011,
              33.540581
            ],
            [
              -82.719238,
              33.563962
            ],
            [
              -82.704968,
              33.587513
            ],
            [
              -82.679975,
              33.599792
            ],
            [
              -82.692039,
              33.619745
            ],
            [
              -82.74383,
              33.611442
            ],
            [
              -82.779913,
              33.632577
            ],
            [
              -82.812958,
              33.655836
            ],
            [
              -82.832603,
              33.650963
            ],
            [
              -82.850325,
              33.639552
            ],
            [
              -82.869336,
              33.642404
            ],
            [
              -82.879716,
              33.620748
            ],
            [
              -82.890599,
              33.632877
            ],
            [
              -82.949046,
              33.733333
            ],
            [
              -82.993133,
              33.701523
            ],
            [
              -82.995602,
              33.693583
            ],
            [
              -82.978241,
              33.679888
            ],
            [
              -82.963678,
              33.676761
            ],
            [
              -82.950752,
              33.661071
            ],
            [
              -82.970512,
              33.641727
            ],
            [
              -82.980102,
              33.614716
            ],
            [
              -82.987724,
              33.628941
            ],
            [
              -83.00161,
              33.620243
            ],
            [
              -82.989899,
              33.603279
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "279",
      "COUNTYNS": "00345220",
      "AFFGEOID": "0500000US13279",
      "GEOID": "13279",
      "NAME": "Toombs",
      "LSAD": "06",
      "ALAND": 942770579,
      "AWATER": 18094405,
      "County_state": "Toombs,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.483131,
              31.968961
            ],
            [
              -82.479607,
              31.971653
            ],
            [
              -82.458152,
              31.96389
            ],
            [
              -82.431531,
              31.966182
            ],
            [
              -82.408046,
              31.955689
            ],
            [
              -82.407245,
              31.949488
            ],
            [
              -82.402242,
              31.960286
            ],
            [
              -82.372096,
              31.953976
            ],
            [
              -82.37242,
              31.941456
            ],
            [
              -82.323277,
              31.934495
            ],
            [
              -82.314482,
              31.930783
            ],
            [
              -82.291583,
              31.942398
            ],
            [
              -82.259705,
              31.932406
            ],
            [
              -82.24681,
              31.9151
            ],
            [
              -82.233129,
              31.919199
            ],
            [
              -82.225042,
              31.913074
            ],
            [
              -82.208574,
              32.040604
            ],
            [
              -82.204417,
              32.076774
            ],
            [
              -82.194643,
              32.133715
            ],
            [
              -82.181912,
              32.166125
            ],
            [
              -82.18382,
              32.169873
            ],
            [
              -82.194759,
              32.201469
            ],
            [
              -82.204984,
              32.209772
            ],
            [
              -82.213683,
              32.229224
            ],
            [
              -82.209825,
              32.239254
            ],
            [
              -82.227401,
              32.277794
            ],
            [
              -82.232788,
              32.318838
            ],
            [
              -82.279845,
              32.294182
            ],
            [
              -82.354546,
              32.292194
            ],
            [
              -82.356378,
              32.313494
            ],
            [
              -82.378936,
              32.336902
            ],
            [
              -82.391679,
              32.335276
            ],
            [
              -82.409118,
              32.35383
            ],
            [
              -82.40913,
              32.353738
            ],
            [
              -82.438987,
              32.29318
            ],
            [
              -82.441124,
              32.217998
            ],
            [
              -82.483131,
              31.968961
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "285",
      "COUNTYNS": "01673298",
      "AFFGEOID": "0500000US13285",
      "GEOID": "13285",
      "NAME": "Troup",
      "LSAD": "06",
      "ALAND": 1072272397,
      "AWATER": 82763611,
      "County_state": "Troup,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.23656892180279,
              33.129549211458
            ],
            [
              -85.23244140250969,
              33.1080770939296
            ],
            [
              -85.232441,
              33.108075
            ],
            [
              -85.223325,
              33.062578
            ],
            [
              -85.221932,
              33.055536
            ],
            [
              -85.20735641663379,
              32.98256742859439
            ],
            [
              -85.2026164555261,
              32.9588381423037
            ],
            [
              -85.188811,
              32.889725
            ],
            [
              -85.1857897629128,
              32.870267438636695
            ],
            [
              -85.109493,
              32.870243
            ],
            [
              -85.108675,
              32.866656
            ],
            [
              -85.099469,
              32.870154
            ],
            [
              -84.861855,
              32.868617
            ],
            [
              -84.861768,
              32.872495
            ],
            [
              -84.861531,
              32.96081
            ],
            [
              -84.864465,
              32.970143
            ],
            [
              -84.864107,
              32.995612
            ],
            [
              -84.862359,
              33.191173
            ],
            [
              -84.871954,
              33.207433
            ],
            [
              -84.862135,
              33.223858
            ],
            [
              -84.93226,
              33.224623
            ],
            [
              -84.939015,
              33.224693
            ],
            [
              -85.04083,
              33.188281
            ],
            [
              -85.045259,
              33.181618
            ],
            [
              -85.088036,
              33.171701
            ],
            [
              -85.087712,
              33.186588
            ],
            [
              -85.10798,
              33.195098
            ],
            [
              -85.107851,
              33.178458
            ],
            [
              -85.11754,
              33.178496
            ],
            [
              -85.117402,
              33.163654
            ],
            [
              -85.23656892180279,
              33.129549211458
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "289",
      "COUNTYNS": "00345456",
      "AFFGEOID": "0500000US13289",
      "GEOID": "13289",
      "NAME": "Twiggs",
      "LSAD": "06",
      "ALAND": 928304208,
      "AWATER": 10914123,
      "County_state": "Twiggs,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.611063,
              32.688543
            ],
            [
              -83.597656,
              32.664338
            ],
            [
              -83.555777,
              32.652516
            ],
            [
              -83.542949,
              32.625791
            ],
            [
              -83.535053,
              32.586568
            ],
            [
              -83.548147,
              32.571922
            ],
            [
              -83.535696,
              32.529058
            ],
            [
              -83.539907,
              32.52451
            ],
            [
              -83.517935,
              32.498908
            ],
            [
              -83.488421,
              32.485138
            ],
            [
              -83.497923,
              32.452198
            ],
            [
              -83.408844,
              32.495713
            ],
            [
              -83.412055,
              32.502091
            ],
            [
              -83.400041,
              32.500157
            ],
            [
              -83.226536,
              32.584201
            ],
            [
              -83.242851,
              32.60876
            ],
            [
              -83.40699,
              32.89822
            ],
            [
              -83.513511,
              32.844868
            ],
            [
              -83.48943,
              32.82406
            ],
            [
              -83.492938,
              32.805149
            ],
            [
              -83.502603,
              32.801119
            ],
            [
              -83.578715,
              32.738118
            ],
            [
              -83.60236,
              32.741267
            ],
            [
              -83.597164,
              32.712035
            ],
            [
              -83.611063,
              32.688543
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "223",
      "COUNTYNS": "00349912",
      "AFFGEOID": "0500000US13223",
      "GEOID": "13223",
      "NAME": "Paulding",
      "LSAD": "06",
      "ALAND": 808831878,
      "AWATER": 5309332,
      "County_state": "Paulding,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.049833,
              33.952635
            ],
            [
              -85.05031,
              33.904488
            ],
            [
              -85.036684,
              33.904327
            ],
            [
              -85.037674,
              33.829027
            ],
            [
              -85.037926,
              33.811942
            ],
            [
              -84.935428,
              33.790565
            ],
            [
              -84.901688,
              33.780703
            ],
            [
              -84.879151,
              33.774758
            ],
            [
              -84.832708,
              33.776019
            ],
            [
              -84.825604,
              33.778822
            ],
            [
              -84.799853,
              33.779909
            ],
            [
              -84.793599,
              33.783241
            ],
            [
              -84.767494,
              33.786895
            ],
            [
              -84.741444,
              33.787719
            ],
            [
              -84.730974,
              33.788568
            ],
            [
              -84.724119,
              33.788604
            ],
            [
              -84.724139,
              33.80617
            ],
            [
              -84.725033,
              33.820065
            ],
            [
              -84.724935,
              33.827559
            ],
            [
              -84.72423,
              33.903596
            ],
            [
              -84.723236,
              33.947353
            ],
            [
              -84.739636,
              33.947602
            ],
            [
              -84.738933,
              34.029962
            ],
            [
              -84.738438,
              34.057396
            ],
            [
              -84.737836,
              34.079399
            ],
            [
              -84.740727,
              34.08236
            ],
            [
              -84.859065,
              34.082572
            ],
            [
              -84.896841,
              34.082498
            ],
            [
              -84.922742,
              34.082497
            ],
            [
              -84.928743,
              34.071698
            ],
            [
              -84.978683,
              33.951393
            ],
            [
              -85.049833,
              33.952635
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "113",
      "COUNTYNS": "01687740",
      "AFFGEOID": "0500000US13113",
      "GEOID": "13113",
      "NAME": "Fayette",
      "LSAD": "06",
      "ALAND": 503420105,
      "AWATER": 12825183,
      "County_state": "Fayette,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.625609,
              33.476795
            ],
            [
              -84.62614,
              33.439037
            ],
            [
              -84.613944,
              33.402954
            ],
            [
              -84.609683,
              33.3971
            ],
            [
              -84.592758,
              33.379505
            ],
            [
              -84.586047,
              33.357885
            ],
            [
              -84.564903,
              33.344651
            ],
            [
              -84.534371,
              33.335424
            ],
            [
              -84.518243,
              33.318331
            ],
            [
              -84.517416,
              33.293036
            ],
            [
              -84.497527,
              33.257422
            ],
            [
              -84.432907,
              33.2565
            ],
            [
              -84.418785,
              33.293667
            ],
            [
              -84.392962,
              33.308887
            ],
            [
              -84.383041,
              33.340214
            ],
            [
              -84.388118,
              33.352465
            ],
            [
              -84.396336,
              33.37326
            ],
            [
              -84.394809,
              33.394368
            ],
            [
              -84.384087,
              33.40247
            ],
            [
              -84.402138,
              33.425278
            ],
            [
              -84.402196,
              33.436368
            ],
            [
              -84.383744,
              33.444407
            ],
            [
              -84.388737,
              33.447937
            ],
            [
              -84.384772,
              33.458713
            ],
            [
              -84.381639,
              33.463043
            ],
            [
              -84.397479,
              33.485738
            ],
            [
              -84.420428,
              33.493992
            ],
            [
              -84.427384,
              33.516941
            ],
            [
              -84.433599,
              33.524445
            ],
            [
              -84.439448,
              33.55072
            ],
            [
              -84.458665,
              33.550933
            ],
            [
              -84.474343,
              33.547776
            ],
            [
              -84.483656,
              33.545732
            ],
            [
              -84.556857,
              33.528405
            ],
            [
              -84.570289,
              33.524918
            ],
            [
              -84.60567,
              33.511071
            ],
            [
              -84.60954,
              33.502511
            ],
            [
              -84.613508,
              33.492461
            ],
            [
              -84.625609,
              33.476795
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "157",
      "COUNTYNS": "00349570",
      "AFFGEOID": "0500000US13157",
      "GEOID": "13157",
      "NAME": "Jackson",
      "LSAD": "06",
      "ALAND": 879710263,
      "AWATER": 8907131,
      "County_state": "Jackson,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.817682,
              34.127493
            ],
            [
              -83.811757,
              34.112851
            ],
            [
              -83.792221,
              34.100304
            ],
            [
              -83.791222,
              34.087513
            ],
            [
              -83.764523,
              34.064933
            ],
            [
              -83.733726,
              34.067345
            ],
            [
              -83.72377,
              34.063423
            ],
            [
              -83.711379,
              34.045984
            ],
            [
              -83.700874,
              34.054316
            ],
            [
              -83.679458,
              34.047412
            ],
            [
              -83.66324,
              34.052273
            ],
            [
              -83.614647,
              34.047161
            ],
            [
              -83.600538,
              34.0353
            ],
            [
              -83.577393,
              34.039563
            ],
            [
              -83.563215,
              34.031757
            ],
            [
              -83.537385,
              33.965912
            ],
            [
              -83.498243,
              33.987529
            ],
            [
              -83.503054,
              33.999577
            ],
            [
              -83.484201,
              33.992762
            ],
            [
              -83.454025,
              34.004809
            ],
            [
              -83.384847,
              34.029734
            ],
            [
              -83.384863,
              34.037805
            ],
            [
              -83.360028,
              34.040572
            ],
            [
              -83.386763,
              34.050241
            ],
            [
              -83.379744,
              34.079524
            ],
            [
              -83.357051,
              34.116199
            ],
            [
              -83.361011,
              34.148355
            ],
            [
              -83.363835,
              34.164059
            ],
            [
              -83.379441,
              34.171304
            ],
            [
              -83.402428,
              34.197499
            ],
            [
              -83.480361,
              34.262038
            ],
            [
              -83.489696,
              34.257008
            ],
            [
              -83.561521,
              34.252712
            ],
            [
              -83.620115,
              34.295276
            ],
            [
              -83.659479,
              34.261873
            ],
            [
              -83.696335,
              34.230622
            ],
            [
              -83.755644,
              34.180189
            ],
            [
              -83.773452,
              34.165076
            ],
            [
              -83.817682,
              34.127493
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "287",
      "COUNTYNS": "00343238",
      "AFFGEOID": "0500000US13287",
      "GEOID": "13287",
      "NAME": "Turner",
      "LSAD": "06",
      "ALAND": 739164624,
      "AWATER": 11881239,
      "County_state": "Turner,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.802723,
              31.803579
            ],
            [
              -83.798679,
              31.801009
            ],
            [
              -83.796846,
              31.622929
            ],
            [
              -83.718774,
              31.622028
            ],
            [
              -83.706677,
              31.619203
            ],
            [
              -83.701505,
              31.606152
            ],
            [
              -83.684373,
              31.596842
            ],
            [
              -83.677667,
              31.600874
            ],
            [
              -83.66769,
              31.585871
            ],
            [
              -83.671407,
              31.571834
            ],
            [
              -83.649384,
              31.567977
            ],
            [
              -83.648972,
              31.596466
            ],
            [
              -83.605142,
              31.597921
            ],
            [
              -83.500702,
              31.59399
            ],
            [
              -83.491647,
              31.61941
            ],
            [
              -83.490672,
              31.696093
            ],
            [
              -83.460637,
              31.69539
            ],
            [
              -83.452919,
              31.708597
            ],
            [
              -83.466637,
              31.741806
            ],
            [
              -83.453634,
              31.757861
            ],
            [
              -83.45919,
              31.794298
            ],
            [
              -83.484529,
              31.835521
            ],
            [
              -83.480176,
              31.847312
            ],
            [
              -83.547524,
              31.847961
            ],
            [
              -83.54164,
              31.853102
            ],
            [
              -83.612256,
              31.854089
            ],
            [
              -83.612642,
              31.804128
            ],
            [
              -83.802723,
              31.803579
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "031",
      "COUNTYNS": "00350302",
      "AFFGEOID": "0500000US13031",
      "GEOID": "13031",
      "NAME": "Bulloch",
      "LSAD": "06",
      "ALAND": 1743101866,
      "AWATER": 40429683,
      "County_state": "Bulloch,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.026842,
              32.555163
            ],
            [
              -82.030233,
              32.53877
            ],
            [
              -81.971003,
              32.51324
            ],
            [
              -81.976947,
              32.497808
            ],
            [
              -81.976299,
              32.477276
            ],
            [
              -81.962123,
              32.452508
            ],
            [
              -81.939763,
              32.434918
            ],
            [
              -81.939609,
              32.425829
            ],
            [
              -81.918671,
              32.41541
            ],
            [
              -81.931981,
              32.373914
            ],
            [
              -81.956924,
              32.286228
            ],
            [
              -81.969069,
              32.268783
            ],
            [
              -81.953213,
              32.266832
            ],
            [
              -81.918592,
              32.274513
            ],
            [
              -81.892725,
              32.274783
            ],
            [
              -81.857389,
              32.257391
            ],
            [
              -81.841651,
              32.240921
            ],
            [
              -81.81628,
              32.23747
            ],
            [
              -81.805582,
              32.220609
            ],
            [
              -81.80194,
              32.196865
            ],
            [
              -81.792594,
              32.193351
            ],
            [
              -81.778471,
              32.162295
            ],
            [
              -81.780858,
              32.15289
            ],
            [
              -81.624809,
              32.189681
            ],
            [
              -81.43583,
              32.241289
            ],
            [
              -81.439811,
              32.274914
            ],
            [
              -81.449722,
              32.283535
            ],
            [
              -81.446334,
              32.284717
            ],
            [
              -81.449477,
              32.296719
            ],
            [
              -81.470756,
              32.329552
            ],
            [
              -81.487831,
              32.333886
            ],
            [
              -81.49089,
              32.351695
            ],
            [
              -81.507575,
              32.357792
            ],
            [
              -81.509429,
              32.37146
            ],
            [
              -81.520515,
              32.376812
            ],
            [
              -81.523322,
              32.392944
            ],
            [
              -81.534904,
              32.39358
            ],
            [
              -81.533515,
              32.40704
            ],
            [
              -81.542716,
              32.417446
            ],
            [
              -81.535217,
              32.422443
            ],
            [
              -81.539047,
              32.435077
            ],
            [
              -81.525726,
              32.449818
            ],
            [
              -81.531341,
              32.465942
            ],
            [
              -81.542388,
              32.466087
            ],
            [
              -81.53588,
              32.480801
            ],
            [
              -81.548006,
              32.489286
            ],
            [
              -81.578689,
              32.512538
            ],
            [
              -81.586353,
              32.512068
            ],
            [
              -81.588066,
              32.512264
            ],
            [
              -81.595481,
              32.508274
            ],
            [
              -81.593375,
              32.506576
            ],
            [
              -81.629057,
              32.524189
            ],
            [
              -81.63932,
              32.532513
            ],
            [
              -81.648538,
              32.527684
            ],
            [
              -81.656799,
              32.539977
            ],
            [
              -81.683204,
              32.549225
            ],
            [
              -81.689643,
              32.54629
            ],
            [
              -81.708977,
              32.566132
            ],
            [
              -81.732643,
              32.576496
            ],
            [
              -81.754226,
              32.601988
            ],
            [
              -81.773113,
              32.606715
            ],
            [
              -81.793819,
              32.628521
            ],
            [
              -81.800506,
              32.641525
            ],
            [
              -81.833839,
              32.653385
            ],
            [
              -81.841005,
              32.649093
            ],
            [
              -81.943693,
              32.621968
            ],
            [
              -82.001236,
              32.60691
            ],
            [
              -82.026842,
              32.555163
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "187",
      "COUNTYNS": "00357196",
      "AFFGEOID": "0500000US13187",
      "GEOID": "13187",
      "NAME": "Lumpkin",
      "LSAD": "06",
      "ALAND": 732786481,
      "AWATER": 3280165,
      "County_state": "Lumpkin,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.19092,
              34.542516
            ],
            [
              -84.19022,
              34.538543
            ],
            [
              -84.167736,
              34.52084
            ],
            [
              -84.115358,
              34.473277
            ],
            [
              -84.106675,
              34.465852
            ],
            [
              -83.980649,
              34.418389
            ],
            [
              -83.920662,
              34.460114
            ],
            [
              -83.931886,
              34.469345
            ],
            [
              -83.90995,
              34.459805
            ],
            [
              -83.843315,
              34.497022
            ],
            [
              -83.843405,
              34.505494
            ],
            [
              -83.832831,
              34.532307
            ],
            [
              -83.843788,
              34.532397
            ],
            [
              -83.843753,
              34.541432
            ],
            [
              -83.85325,
              34.541455
            ],
            [
              -83.853713,
              34.541996
            ],
            [
              -83.864449,
              34.550593
            ],
            [
              -83.864361,
              34.560668
            ],
            [
              -83.864456,
              34.615581
            ],
            [
              -83.877276,
              34.629597
            ],
            [
              -83.874407,
              34.674893
            ],
            [
              -83.863606,
              34.688593
            ],
            [
              -83.856506,
              34.722191
            ],
            [
              -83.877427,
              34.723304
            ],
            [
              -83.900108,
              34.732191
            ],
            [
              -83.93941,
              34.74079
            ],
            [
              -83.982612,
              34.71589
            ],
            [
              -83.994698,
              34.678211
            ],
            [
              -84.024109,
              34.670753
            ],
            [
              -84.03651,
              34.641934
            ],
            [
              -84.054958,
              34.642886
            ],
            [
              -84.07077,
              34.656361
            ],
            [
              -84.098701,
              34.653225
            ],
            [
              -84.124914,
              34.664419
            ],
            [
              -84.148226,
              34.645833
            ],
            [
              -84.158035,
              34.648243
            ],
            [
              -84.188557,
              34.602692
            ],
            [
              -84.19092,
              34.542516
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "255",
      "COUNTYNS": "00353055",
      "AFFGEOID": "0500000US13255",
      "GEOID": "13255",
      "NAME": "Spalding",
      "LSAD": "06",
      "ALAND": 507343333,
      "AWATER": 9593612,
      "County_state": "Spalding,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.507951,
              33.248617
            ],
            [
              -84.502352,
              33.221055
            ],
            [
              -84.490539,
              33.197586
            ],
            [
              -84.496783,
              33.183866
            ],
            [
              -84.481302,
              33.18846
            ],
            [
              -84.475458,
              33.20064
            ],
            [
              -84.455285,
              33.202636
            ],
            [
              -84.451336,
              33.208889
            ],
            [
              -84.393467,
              33.208311
            ],
            [
              -84.39363,
              33.200271
            ],
            [
              -84.373141,
              33.20036
            ],
            [
              -84.373685,
              33.189625
            ],
            [
              -84.26834,
              33.189234
            ],
            [
              -84.248185,
              33.189019
            ],
            [
              -84.224277,
              33.179388
            ],
            [
              -84.123981,
              33.17862
            ],
            [
              -84.123767,
              33.202824
            ],
            [
              -84.122623,
              33.252668
            ],
            [
              -84.102585,
              33.272301
            ],
            [
              -84.088991,
              33.285646
            ],
            [
              -84.102582,
              33.298191
            ],
            [
              -84.150909,
              33.312982
            ],
            [
              -84.150581,
              33.335639
            ],
            [
              -84.216329,
              33.335821
            ],
            [
              -84.247304,
              33.33597
            ],
            [
              -84.249164,
              33.352539
            ],
            [
              -84.3544,
              33.352514
            ],
            [
              -84.388118,
              33.352465
            ],
            [
              -84.383041,
              33.340214
            ],
            [
              -84.392962,
              33.308887
            ],
            [
              -84.418785,
              33.293667
            ],
            [
              -84.432907,
              33.2565
            ],
            [
              -84.497527,
              33.257422
            ],
            [
              -84.507951,
              33.248617
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "281",
      "COUNTYNS": "00351252",
      "AFFGEOID": "0500000US13281",
      "GEOID": "13281",
      "NAME": "Towns",
      "LSAD": "06",
      "ALAND": 431313630,
      "AWATER": 13975835,
      "County_state": "Towns,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.936427258766,
              34.9874845938068
            ],
            [
              -83.945854,
              34.970661
            ],
            [
              -83.927017,
              34.956089
            ],
            [
              -83.915104,
              34.926887
            ],
            [
              -83.865733,
              34.914686
            ],
            [
              -83.852864,
              34.899452
            ],
            [
              -83.832256,
              34.899599
            ],
            [
              -83.8366,
              34.90899
            ],
            [
              -83.819099,
              34.91369
            ],
            [
              -83.810399,
              34.906491
            ],
            [
              -83.804899,
              34.897191
            ],
            [
              -83.8121,
              34.872991
            ],
            [
              -83.8092,
              34.859791
            ],
            [
              -83.7917,
              34.843292
            ],
            [
              -83.7963,
              34.826092
            ],
            [
              -83.7806,
              34.818392
            ],
            [
              -83.780401,
              34.792893
            ],
            [
              -83.743107,
              34.801191
            ],
            [
              -83.704146,
              34.790982
            ],
            [
              -83.690397,
              34.798991
            ],
            [
              -83.681596,
              34.800593
            ],
            [
              -83.666855,
              34.806528
            ],
            [
              -83.652194,
              34.822993
            ],
            [
              -83.661672,
              34.848157
            ],
            [
              -83.656692,
              34.878592
            ],
            [
              -83.645091,
              34.891392
            ],
            [
              -83.63059,
              34.894492
            ],
            [
              -83.626889,
              34.909892
            ],
            [
              -83.59901,
              34.912774
            ],
            [
              -83.60159,
              34.938342
            ],
            [
              -83.546883,
              34.946492
            ],
            [
              -83.54937747827239,
              34.992492052558696
            ],
            [
              -83.549381,
              34.992492
            ],
            [
              -83.620185,
              34.992091
            ],
            [
              -83.619985,
              34.986592
            ],
            [
              -83.749893,
              34.987691
            ],
            [
              -83.831097,
              34.987289
            ],
            [
              -83.936427258766,
              34.9874845938068
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "319",
      "COUNTYNS": "00346824",
      "AFFGEOID": "0500000US13319",
      "GEOID": "13319",
      "NAME": "Wilkinson",
      "LSAD": "06",
      "ALAND": 1158541310,
      "AWATER": 12003359,
      "County_state": "Wilkinson,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.357685,
              32.926141
            ],
            [
              -83.40699,
              32.89822
            ],
            [
              -83.242851,
              32.60876
            ],
            [
              -83.226536,
              32.584201
            ],
            [
              -83.040792,
              32.673339
            ],
            [
              -82.957066,
              32.708318
            ],
            [
              -82.94512,
              32.751434
            ],
            [
              -82.946966,
              32.759358
            ],
            [
              -82.955364,
              32.756461
            ],
            [
              -82.960759,
              32.774658
            ],
            [
              -82.954358,
              32.80176
            ],
            [
              -82.969964,
              32.834156
            ],
            [
              -82.998367,
              32.855455
            ],
            [
              -83.027036,
              32.856133
            ],
            [
              -83.037384,
              32.879762
            ],
            [
              -83.050715,
              32.893903
            ],
            [
              -83.048316,
              32.910083
            ],
            [
              -83.061571,
              32.93479
            ],
            [
              -83.073648,
              32.946562
            ],
            [
              -83.114347,
              32.972991
            ],
            [
              -83.123973,
              33.001747
            ],
            [
              -83.133847,
              33.007248
            ],
            [
              -83.147416,
              33.008666
            ],
            [
              -83.179469,
              32.987401
            ],
            [
              -83.16227,
              32.969602
            ],
            [
              -83.20616,
              32.983597
            ],
            [
              -83.268672,
              32.958699
            ],
            [
              -83.276067,
              32.942067
            ],
            [
              -83.287737,
              32.951884
            ],
            [
              -83.357685,
              32.926141
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "177",
      "COUNTYNS": "00343390",
      "AFFGEOID": "0500000US13177",
      "GEOID": "13177",
      "NAME": "Lee",
      "LSAD": "06",
      "ALAND": 921722888,
      "AWATER": 15318763,
      "County_state": "Lee,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.338143,
              31.91619
            ],
            [
              -84.338245,
              31.873591
            ],
            [
              -84.293133,
              31.864881
            ],
            [
              -84.276689,
              31.850406
            ],
            [
              -84.277522,
              31.841744
            ],
            [
              -84.259611,
              31.827865
            ],
            [
              -84.26449,
              31.812401
            ],
            [
              -84.260489,
              31.790898
            ],
            [
              -84.286456,
              31.791182
            ],
            [
              -84.293668,
              31.749794
            ],
            [
              -84.295416,
              31.698098
            ],
            [
              -84.304927,
              31.698231
            ],
            [
              -84.295641,
              31.690986
            ],
            [
              -84.297801,
              31.621951
            ],
            [
              -84.261066,
              31.622416
            ],
            [
              -84.043213,
              31.623573
            ],
            [
              -84.02891,
              31.64836
            ],
            [
              -84.018404,
              31.650274
            ],
            [
              -84.012401,
              31.663039
            ],
            [
              -84.02206,
              31.685636
            ],
            [
              -84.019608,
              31.692482
            ],
            [
              -84.032344,
              31.716772
            ],
            [
              -84.00762,
              31.735516
            ],
            [
              -84.003496,
              31.754596
            ],
            [
              -83.98639,
              31.765426
            ],
            [
              -83.991554,
              31.783756
            ],
            [
              -84.008037,
              31.792313
            ],
            [
              -84.008445,
              31.801645
            ],
            [
              -83.990498,
              31.806369
            ],
            [
              -83.976884,
              31.803329
            ],
            [
              -83.977008,
              31.819065
            ],
            [
              -83.967344,
              31.834477
            ],
            [
              -83.939437,
              31.847929
            ],
            [
              -83.926524,
              31.863292
            ],
            [
              -83.934061,
              31.88139
            ],
            [
              -83.922494,
              31.909653
            ],
            [
              -83.935679,
              31.91184
            ],
            [
              -84.191135,
              31.914909
            ],
            [
              -84.338143,
              31.91619
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "267",
      "COUNTYNS": "00347445",
      "AFFGEOID": "0500000US13267",
      "GEOID": "13267",
      "NAME": "Tattnall",
      "LSAD": "06",
      "ALAND": 1241979177,
      "AWATER": 22837730,
      "County_state": "Tattnall,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.232788,
              32.318838
            ],
            [
              -82.227401,
              32.277794
            ],
            [
              -82.209825,
              32.239254
            ],
            [
              -82.213683,
              32.229224
            ],
            [
              -82.204984,
              32.209772
            ],
            [
              -82.194759,
              32.201469
            ],
            [
              -82.18382,
              32.169873
            ],
            [
              -82.181912,
              32.166125
            ],
            [
              -82.194643,
              32.133715
            ],
            [
              -82.204417,
              32.076774
            ],
            [
              -82.208574,
              32.040604
            ],
            [
              -82.225042,
              31.913074
            ],
            [
              -82.192044,
              31.906499
            ],
            [
              -82.190244,
              31.900499
            ],
            [
              -82.165843,
              31.903599
            ],
            [
              -82.142843,
              31.8993
            ],
            [
              -82.132943,
              31.9074
            ],
            [
              -82.134843,
              31.891
            ],
            [
              -82.116542,
              31.887201
            ],
            [
              -82.111818,
              31.900487
            ],
            [
              -82.107235,
              31.863797
            ],
            [
              -82.048582,
              31.827075
            ],
            [
              -82.028346,
              31.815513
            ],
            [
              -82.014639,
              31.8218
            ],
            [
              -82.006777,
              31.800047
            ],
            [
              -81.97654,
              31.782607
            ],
            [
              -81.969052,
              31.789324
            ],
            [
              -81.981033,
              31.791367
            ],
            [
              -81.980042,
              31.804163
            ],
            [
              -81.955132,
              31.809851
            ],
            [
              -81.940414,
              31.839872
            ],
            [
              -81.921921,
              31.852289
            ],
            [
              -81.912529,
              31.886833
            ],
            [
              -81.892471,
              31.895264
            ],
            [
              -81.881996,
              31.918341
            ],
            [
              -81.889168,
              31.947071
            ],
            [
              -81.876236,
              31.968056
            ],
            [
              -81.863113,
              31.972961
            ],
            [
              -81.854576,
              31.988328
            ],
            [
              -81.824405,
              32.014882
            ],
            [
              -81.813382,
              32.01623
            ],
            [
              -81.761735,
              32.0479
            ],
            [
              -81.889118,
              32.050438
            ],
            [
              -81.97979,
              32.081412
            ],
            [
              -82.025339,
              32.278883
            ],
            [
              -82.232788,
              32.318838
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "197",
      "COUNTYNS": "00344107",
      "AFFGEOID": "0500000US13197",
      "GEOID": "13197",
      "NAME": "Marion",
      "LSAD": "06",
      "ALAND": 947978209,
      "AWATER": 3791374,
      "County_state": "Marion,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.657325,
              32.49494
            ],
            [
              -84.65892,
              32.232852
            ],
            [
              -84.649319,
              32.232953
            ],
            [
              -84.620621,
              32.233055
            ],
            [
              -84.600692,
              32.210247
            ],
            [
              -84.600523,
              32.190736
            ],
            [
              -84.564316,
              32.189847
            ],
            [
              -84.56469,
              32.175136
            ],
            [
              -84.545692,
              32.175232
            ],
            [
              -84.546117,
              32.159056
            ],
            [
              -84.527417,
              32.151156
            ],
            [
              -84.527117,
              32.134556
            ],
            [
              -84.431214,
              32.134058
            ],
            [
              -84.430218,
              32.166257
            ],
            [
              -84.420515,
              32.174357
            ],
            [
              -84.42008,
              32.191677
            ],
            [
              -84.429832,
              32.194153
            ],
            [
              -84.429451,
              32.232151
            ],
            [
              -84.410415,
              32.231856
            ],
            [
              -84.410101,
              32.289454
            ],
            [
              -84.413304,
              32.297531
            ],
            [
              -84.389927,
              32.297237
            ],
            [
              -84.392316,
              32.414046
            ],
            [
              -84.392117,
              32.435347
            ],
            [
              -84.408578,
              32.443949
            ],
            [
              -84.407808,
              32.485267
            ],
            [
              -84.433619,
              32.493243
            ],
            [
              -84.433542,
              32.518694
            ],
            [
              -84.453154,
              32.534336
            ],
            [
              -84.444353,
              32.562083
            ],
            [
              -84.492065,
              32.561956
            ],
            [
              -84.491784,
              32.548319
            ],
            [
              -84.504816,
              32.541822
            ],
            [
              -84.550957,
              32.542719
            ],
            [
              -84.553826,
              32.548732
            ],
            [
              -84.566024,
              32.540059
            ],
            [
              -84.561404,
              32.528056
            ],
            [
              -84.584858,
              32.522947
            ],
            [
              -84.604919,
              32.53501
            ],
            [
              -84.621486,
              32.529781
            ],
            [
              -84.637323,
              32.534855
            ],
            [
              -84.638225,
              32.494841
            ],
            [
              -84.657325,
              32.49494
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "039",
      "COUNTYNS": "01671316",
      "AFFGEOID": "0500000US13039",
      "GEOID": "13039",
      "NAME": "Camden",
      "LSAD": "06",
      "ALAND": 1587815888,
      "AWATER": 437170621,
      "County_state": "Camden,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.936749,
              31.060787
            ],
            [
              -81.936006,
              31.047993
            ],
            [
              -81.906036,
              31.039715
            ],
            [
              -81.907116,
              30.999059
            ],
            [
              -81.91458,
              30.975981
            ],
            [
              -81.892028,
              30.960474
            ],
            [
              -81.899167,
              30.9456
            ],
            [
              -81.915128,
              30.912187
            ],
            [
              -81.892139,
              30.90988
            ],
            [
              -81.904855,
              30.82834
            ],
            [
              -81.902354,
              30.82082
            ],
            [
              -81.899397,
              30.821665
            ],
            [
              -81.895737,
              30.821101
            ],
            [
              -81.892921,
              30.819271
            ],
            [
              -81.891298,
              30.815948
            ],
            [
              -81.882741,
              30.805126
            ],
            [
              -81.876898,
              30.799518
            ],
            [
              -81.868624,
              30.792756
            ],
            [
              -81.852641,
              30.794441
            ],
            [
              -81.846301,
              30.79055
            ],
            [
              -81.842073,
              30.787122
            ],
            [
              -81.84039,
              30.786386
            ],
            [
              -81.827028,
              30.788935
            ],
            [
              -81.808543,
              30.790016
            ],
            [
              -81.806666,
              30.789685
            ],
            [
              -81.792782,
              30.784434
            ],
            [
              -81.784363,
              30.773591
            ],
            [
              -81.782666,
              30.769938
            ],
            [
              -81.779183,
              30.768063
            ],
            [
              -81.775033,
              30.768331
            ],
            [
              -81.772623,
              30.769536
            ],
            [
              -81.77048,
              30.772482
            ],
            [
              -81.768204,
              30.773955
            ],
            [
              -81.763384,
              30.773821
            ],
            [
              -81.75935,
              30.771378
            ],
            [
              -81.755086,
              30.76832
            ],
            [
              -81.751294,
              30.767083
            ],
            [
              -81.747583,
              30.766456
            ],
            [
              -81.746323,
              30.765892
            ],
            [
              -81.745046,
              30.76504
            ],
            [
              -81.744194,
              30.763869
            ],
            [
              -81.743449,
              30.762272
            ],
            [
              -81.743105,
              30.759913
            ],
            [
              -81.742747,
              30.759202
            ],
            [
              -81.732238,
              30.749635
            ],
            [
              -81.727137,
              30.746935
            ],
            [
              -81.719937,
              30.744634
            ],
            [
              -81.694787,
              30.748415
            ],
            [
              -81.692824,
              30.747101
            ],
            [
              -81.691827,
              30.743991
            ],
            [
              -81.690999,
              30.742841
            ],
            [
              -81.688934,
              30.741434
            ],
            [
              -81.672832,
              30.738935
            ],
            [
              -81.670132,
              30.740235
            ],
            [
              -81.669332,
              30.741335
            ],
            [
              -81.668283,
              30.744644
            ],
            [
              -81.667344,
              30.745661
            ],
            [
              -81.664606,
              30.7466
            ],
            [
              -81.662181,
              30.746522
            ],
            [
              -81.656549,
              30.745114
            ],
            [
              -81.654542677328,
              30.743897854206498
            ],
            [
              -81.652131,
              30.742436
            ],
            [
              -81.651731,
              30.740235
            ],
            [
              -81.652169,
              30.735648
            ],
            [
              -81.651778,
              30.732284
            ],
            [
              -81.650448,
              30.729703
            ],
            [
              -81.649196,
              30.728686
            ],
            [
              -81.646144,
              30.727591
            ],
            [
              -81.633273,
              30.729603
            ],
            [
              -81.629616,
              30.732407
            ],
            [
              -81.625105,
              30.733017
            ],
            [
              -81.621936,
              30.731188
            ],
            [
              -81.620829,
              30.729535
            ],
            [
              -81.619619,
              30.724849
            ],
            [
              -81.617669,
              30.722046
            ],
            [
              -81.609501,
              30.720705
            ],
            [
              -81.607673,
              30.721924
            ],
            [
              -81.605722,
              30.725337
            ],
            [
              -81.604016,
              30.727287
            ],
            [
              -81.601212,
              30.728141
            ],
            [
              -81.593654,
              30.725459
            ],
            [
              -81.586825,
              30.723735
            ],
            [
              -81.573724,
              30.722336
            ],
            [
              -81.571424,
              30.721636
            ],
            [
              -81.566223,
              30.717836
            ],
            [
              -81.56171,
              30.715597
            ],
            [
              -81.55257,
              30.716974
            ],
            [
              -81.54919,
              30.715972
            ],
            [
              -81.546936,
              30.714345
            ],
            [
              -81.544683,
              30.713969
            ],
            [
              -81.542679,
              30.713593
            ],
            [
              -81.540927,
              30.713343
            ],
            [
              -81.539298,
              30.713468
            ],
            [
              -81.537671,
              30.714345
            ],
            [
              -81.535542,
              30.716348
            ],
            [
              -81.53452,
              30.717936
            ],
            [
              -81.53404,
              30.719853
            ],
            [
              -81.532788,
              30.721606
            ],
            [
              -81.530534,
              30.722858
            ],
            [
              -81.528281,
              30.723359
            ],
            [
              -81.52142,
              30.722536
            ],
            [
              -81.516119,
              30.722236
            ],
            [
              -81.507218,
              30.722936
            ],
            [
              -81.489539,
              30.7261
            ],
            [
              -81.487334,
              30.726081
            ],
            [
              -81.483788,
              30.723891
            ],
            [
              -81.475755,
              30.714754
            ],
            [
              -81.472598,
              30.713312
            ],
            [
              -81.464466,
              30.711045
            ],
            [
              -81.459979,
              30.710434
            ],
            [
              -81.448718,
              30.709353
            ],
            [
              -81.444124,
              30.709714
            ],
            [
              -81.449375,
              30.715601
            ],
            [
              -81.45947,
              30.741979
            ],
            [
              -81.461065,
              30.753684
            ],
            [
              -81.460061,
              30.769912
            ],
            [
              -81.455287,
              30.79093
            ],
            [
              -81.446927,
              30.81039
            ],
            [
              -81.44013,
              30.821369
            ],
            [
              -81.430835,
              30.831156
            ],
            [
              -81.428577,
              30.836336
            ],
            [
              -81.405153,
              30.908203
            ],
            [
              -81.403409,
              30.957914
            ],
            [
              -81.408484,
              30.977718
            ],
            [
              -81.415825,
              30.977192
            ],
            [
              -81.420108,
              30.974076
            ],
            [
              -81.426929,
              30.956615
            ],
            [
              -81.447388,
              30.956732
            ],
            [
              -81.453568,
              30.965573
            ],
            [
              -81.466814,
              30.97091
            ],
            [
              -81.472321,
              30.969899
            ],
            [
              -81.475789,
              30.965976
            ],
            [
              -81.486966,
              30.969602
            ],
            [
              -81.493651,
              30.977528
            ],
            [
              -81.490586,
              30.984952
            ],
            [
              -81.469298,
              30.996028
            ],
            [
              -81.45924,
              31.005692
            ],
            [
              -81.457795,
              31.010259
            ],
            [
              -81.451444,
              31.015515
            ],
            [
              -81.443170434232,
              31.0166613102744
            ],
            [
              -81.44493,
              31.028994
            ],
            [
              -81.456893,
              31.037197
            ],
            [
              -81.486297,
              31.034595
            ],
            [
              -81.492454,
              31.054476
            ],
            [
              -81.526565,
              31.063194
            ],
            [
              -81.539764,
              31.083776
            ],
            [
              -81.556716,
              31.080807
            ],
            [
              -81.572692,
              31.097269
            ],
            [
              -81.558108,
              31.120681
            ],
            [
              -81.562606,
              31.130285
            ],
            [
              -81.577316,
              31.117235
            ],
            [
              -81.611701,
              31.113192
            ],
            [
              -81.622983,
              31.116317
            ],
            [
              -81.631624,
              31.108117
            ],
            [
              -81.662078,
              31.12554
            ],
            [
              -81.686225,
              31.122419
            ],
            [
              -81.713226,
              31.138674
            ],
            [
              -81.72229,
              31.148718
            ],
            [
              -81.763435,
              31.154087
            ],
            [
              -81.766322,
              31.169595
            ],
            [
              -81.788218,
              31.152166
            ],
            [
              -81.801463,
              31.151188
            ],
            [
              -81.819066,
              31.136504
            ],
            [
              -81.840671,
              31.097665
            ],
            [
              -81.889574,
              31.100809
            ],
            [
              -81.915883,
              31.095281
            ],
            [
              -81.927353,
              31.078867
            ],
            [
              -81.925979,
              31.061596
            ],
            [
              -81.936749,
              31.060787
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "045",
      "COUNTYNS": "00326650",
      "AFFGEOID": "0500000US13045",
      "GEOID": "13045",
      "NAME": "Carroll",
      "LSAD": "06",
      "ALAND": 1292475855,
      "AWATER": 12301426,
      "County_state": "Carroll,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.33824506153,
              33.65311401873009
            ],
            [
              -85.3238402036472,
              33.58033992928419
            ],
            [
              -85.3226150430291,
              33.5741503542721
            ],
            [
              -85.315043,
              33.535896
            ],
            [
              -85.31414,
              33.530216
            ],
            [
              -85.314048,
              33.529805
            ],
            [
              -85.304489,
              33.482882
            ],
            [
              -85.3044873227631,
              33.4828732747038
            ],
            [
              -85.2939525862265,
              33.4280696299523
            ],
            [
              -85.099121,
              33.426631
            ],
            [
              -85.015358,
              33.425506
            ],
            [
              -84.986542,
              33.441287
            ],
            [
              -84.984767,
              33.457177
            ],
            [
              -84.973902,
              33.452679
            ],
            [
              -84.937426,
              33.436753
            ],
            [
              -84.921762,
              33.445011
            ],
            [
              -84.908788,
              33.469191
            ],
            [
              -84.878874,
              33.490586
            ],
            [
              -84.858345,
              33.493886
            ],
            [
              -84.850713,
              33.511457
            ],
            [
              -84.815869,
              33.520249
            ],
            [
              -84.832336,
              33.545267
            ],
            [
              -84.808934,
              33.574085
            ],
            [
              -84.821429,
              33.573608
            ],
            [
              -84.830042,
              33.573774
            ],
            [
              -84.886858,
              33.573423
            ],
            [
              -84.905788,
              33.573378
            ],
            [
              -84.903607,
              33.638831
            ],
            [
              -84.902741,
              33.649999
            ],
            [
              -84.901563,
              33.721991
            ],
            [
              -84.910852,
              33.730976
            ],
            [
              -84.902331,
              33.730599
            ],
            [
              -84.901688,
              33.780703
            ],
            [
              -84.935428,
              33.790565
            ],
            [
              -85.037926,
              33.811942
            ],
            [
              -85.049031,
              33.812322
            ],
            [
              -85.049591,
              33.77996
            ],
            [
              -85.050889,
              33.714632
            ],
            [
              -85.106786,
              33.694611
            ],
            [
              -85.129688,
              33.691423
            ],
            [
              -85.139027,
              33.700017
            ],
            [
              -85.289742,
              33.652087
            ],
            [
              -85.33824506153,
              33.65311401873009
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "129",
      "COUNTYNS": "00356672",
      "AFFGEOID": "0500000US13129",
      "GEOID": "13129",
      "NAME": "Gordon",
      "LSAD": "06",
      "ALAND": 921597971,
      "AWATER": 5729801,
      "County_state": "Gordon,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.090067,
              34.463527
            ],
            [
              -85.112324,
              34.461696
            ],
            [
              -85.088712,
              34.42502
            ],
            [
              -85.107403,
              34.415106
            ],
            [
              -85.104444,
              34.404278
            ],
            [
              -85.086761,
              34.401264
            ],
            [
              -85.031709,
              34.401353
            ],
            [
              -85.027031,
              34.393993
            ],
            [
              -85.005775,
              34.392446
            ],
            [
              -84.992507,
              34.392661
            ],
            [
              -84.980054,
              34.384772
            ],
            [
              -84.979632,
              34.397631
            ],
            [
              -84.953743,
              34.38419
            ],
            [
              -84.952546,
              34.398955
            ],
            [
              -84.862863,
              34.396601
            ],
            [
              -84.837061,
              34.397721
            ],
            [
              -84.798977,
              34.397345
            ],
            [
              -84.734118,
              34.396495
            ],
            [
              -84.733878,
              34.412968
            ],
            [
              -84.653232,
              34.41259
            ],
            [
              -84.653733,
              34.455789
            ],
            [
              -84.644432,
              34.455789
            ],
            [
              -84.653291,
              34.463197
            ],
            [
              -84.654366,
              34.548946
            ],
            [
              -84.654523,
              34.583187
            ],
            [
              -84.681962,
              34.583335
            ],
            [
              -84.70554,
              34.598456
            ],
            [
              -84.715692,
              34.622876
            ],
            [
              -84.746134,
              34.610865
            ],
            [
              -84.779654,
              34.608322
            ],
            [
              -84.802198,
              34.60839
            ],
            [
              -84.863908,
              34.615759
            ],
            [
              -84.90825,
              34.615789
            ],
            [
              -84.913456,
              34.634128
            ],
            [
              -84.926362,
              34.615719
            ],
            [
              -85.050446,
              34.622482
            ],
            [
              -85.055206,
              34.622383
            ],
            [
              -85.060499,
              34.587184
            ],
            [
              -85.069159,
              34.587184
            ],
            [
              -85.064169,
              34.574885
            ],
            [
              -85.063507,
              34.542548
            ],
            [
              -85.089997,
              34.541915
            ],
            [
              -85.090067,
              34.463527
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "241",
      "COUNTYNS": "00351489",
      "AFFGEOID": "0500000US13241",
      "GEOID": "13241",
      "NAME": "Rabun",
      "LSAD": "06",
      "ALAND": 958635596,
      "AWATER": 17839179,
      "County_state": "Rabun,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.656692,
              34.878592
            ],
            [
              -83.661672,
              34.848157
            ],
            [
              -83.652194,
              34.822993
            ],
            [
              -83.613084,
              34.822015
            ],
            [
              -83.601566,
              34.826615
            ],
            [
              -83.586897,
              34.819991
            ],
            [
              -83.576248,
              34.795494
            ],
            [
              -83.556641,
              34.785057
            ],
            [
              -83.56221,
              34.781101
            ],
            [
              -83.548988,
              34.749451
            ],
            [
              -83.512788,
              34.731683
            ],
            [
              -83.503487,
              34.734291
            ],
            [
              -83.478622,
              34.723949
            ],
            [
              -83.45529,
              34.732628
            ],
            [
              -83.431221,
              34.747345
            ],
            [
              -83.403662,
              34.728921
            ],
            [
              -83.387382,
              34.737392
            ],
            [
              -83.3524926405687,
              34.7161214082301
            ],
            [
              -83.353238,
              34.728648
            ],
            [
              -83.348829,
              34.737194
            ],
            [
              -83.338666,
              34.742295
            ],
            [
              -83.320062,
              34.759616
            ],
            [
              -83.321008,
              34.765371
            ],
            [
              -83.319945,
              34.773725
            ],
            [
              -83.323866,
              34.789712
            ],
            [
              -83.313782,
              34.799911
            ],
            [
              -83.303643,
              34.802403
            ],
            [
              -83.301182,
              34.804008
            ],
            [
              -83.301482,
              34.808677
            ],
            [
              -83.302965,
              34.811073
            ],
            [
              -83.302965,
              34.812214
            ],
            [
              -83.302395,
              34.813241
            ],
            [
              -83.301368,
              34.814154
            ],
            [
              -83.299428,
              34.814268
            ],
            [
              -83.297259,
              34.814268
            ],
            [
              -83.294292,
              34.814725
            ],
            [
              -83.291325,
              34.818833
            ],
            [
              -83.29112,
              34.822508
            ],
            [
              -83.289914,
              34.824477
            ],
            [
              -83.284812,
              34.823043
            ],
            [
              -83.283151,
              34.821328
            ],
            [
              -83.275656,
              34.816862
            ],
            [
              -83.271214,
              34.81844
            ],
            [
              -83.268159,
              34.821393
            ],
            [
              -83.267293,
              34.832748
            ],
            [
              -83.269982,
              34.837196
            ],
            [
              -83.267656,
              34.845289
            ],
            [
              -83.26452,
              34.846402
            ],
            [
              -83.262193,
              34.846402
            ],
            [
              -83.25986,
              34.845629
            ],
            [
              -83.258146,
              34.844985
            ],
            [
              -83.255718,
              34.845592
            ],
            [
              -83.254605,
              34.846402
            ],
            [
              -83.253762,
              34.848057
            ],
            [
              -83.252582,
              34.853483
            ],
            [
              -83.250053,
              34.856012
            ],
            [
              -83.24722,
              34.85844
            ],
            [
              -83.247018,
              34.863094
            ],
            [
              -83.245602,
              34.865522
            ],
            [
              -83.240847,
              34.866736
            ],
            [
              -83.238419,
              34.869771
            ],
            [
              -83.238557,
              34.872868
            ],
            [
              -83.239081,
              34.875661
            ],
            [
              -83.23751,
              34.877057
            ],
            [
              -83.232379,
              34.878051
            ],
            [
              -83.22924,
              34.879907
            ],
            [
              -83.220099,
              34.878124
            ],
            [
              -83.213323,
              34.882796
            ],
            [
              -83.209683,
              34.880279
            ],
            [
              -83.205627,
              34.880142
            ],
            [
              -83.201183,
              34.884653
            ],
            [
              -83.204572,
              34.890284
            ],
            [
              -83.203351,
              34.893717
            ],
            [
              -83.197627,
              34.895046
            ],
            [
              -83.194786,
              34.897843
            ],
            [
              -83.190409,
              34.89794
            ],
            [
              -83.186541,
              34.899534
            ],
            [
              -83.180871,
              34.904708
            ],
            [
              -83.178932,
              34.90825
            ],
            [
              -83.174034,
              34.910911
            ],
            [
              -83.170754,
              34.914231
            ],
            [
              -83.168524,
              34.91788
            ],
            [
              -83.165022,
              34.918853
            ],
            [
              -83.160937,
              34.918269
            ],
            [
              -83.158019,
              34.920117
            ],
            [
              -83.155879,
              34.9243
            ],
            [
              -83.153253,
              34.926342
            ],
            [
              -83.149946,
              34.927218
            ],
            [
              -83.143261,
              34.924756
            ],
            [
              -83.140621,
              34.924915
            ],
            [
              -83.130554,
              34.930932
            ],
            [
              -83.129885,
              34.932351
            ],
            [
              -83.130356,
              34.935167
            ],
            [
              -83.129493,
              34.937402
            ],
            [
              -83.12807,
              34.938113
            ],
            [
              -83.125175,
              34.937047
            ],
            [
              -83.122585,
              34.938062
            ],
            [
              -83.121112,
              34.939129
            ],
            [
              -83.120502,
              34.941262
            ],
            [
              -83.121214,
              34.942684
            ],
            [
              -83.12294,
              34.944513
            ],
            [
              -83.126761,
              34.948742
            ],
            [
              -83.127035,
              34.953778
            ],
            [
              -83.124378,
              34.95524
            ],
            [
              -83.12114,
              34.958966
            ],
            [
              -83.121803,
              34.96362
            ],
            [
              -83.120387,
              34.968406
            ],
            [
              -83.112021,
              34.975896
            ],
            [
              -83.110025,
              34.980635
            ],
            [
              -83.106991,
              34.98272
            ],
            [
              -83.10449,
              34.989332
            ],
            [
              -83.1046,
              34.992783
            ],
            [
              -83.105531,
              34.996344
            ],
            [
              -83.108606,
              35.000659
            ],
            [
              -83.10861299848649,
              35.0006588970811
            ],
            [
              -83.19041,
              34.999456
            ],
            [
              -83.322768,
              34.995874
            ],
            [
              -83.4829524283476,
              34.9934833880904
            ],
            [
              -83.54937747827239,
              34.992492052558696
            ],
            [
              -83.546883,
              34.946492
            ],
            [
              -83.60159,
              34.938342
            ],
            [
              -83.59901,
              34.912774
            ],
            [
              -83.626889,
              34.909892
            ],
            [
              -83.63059,
              34.894492
            ],
            [
              -83.645091,
              34.891392
            ],
            [
              -83.656692,
              34.878592
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "175",
      "COUNTYNS": "00346568",
      "AFFGEOID": "0500000US13175",
      "GEOID": "13175",
      "NAME": "Laurens",
      "LSAD": "06",
      "ALAND": 2090842776,
      "AWATER": 28985734,
      "County_state": "Laurens,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.226536,
              32.584201
            ],
            [
              -83.179528,
              32.497878
            ],
            [
              -83.138991,
              32.423069
            ],
            [
              -83.111429,
              32.372051
            ],
            [
              -82.990967,
              32.147274
            ],
            [
              -82.884803,
              32.196072
            ],
            [
              -82.858308,
              32.194386
            ],
            [
              -82.815955,
              32.230092
            ],
            [
              -82.768559,
              32.269774
            ],
            [
              -82.755158,
              32.26969
            ],
            [
              -82.761959,
              32.275574
            ],
            [
              -82.721964,
              32.309283
            ],
            [
              -82.748732,
              32.32915
            ],
            [
              -82.730582,
              32.361755
            ],
            [
              -82.712419,
              32.391342
            ],
            [
              -82.702888,
              32.397872
            ],
            [
              -82.697166,
              32.417484
            ],
            [
              -82.702163,
              32.431617
            ],
            [
              -82.690805,
              32.443163
            ],
            [
              -82.685625,
              32.460501
            ],
            [
              -82.663181,
              32.460061
            ],
            [
              -82.646923,
              32.468915
            ],
            [
              -82.635546,
              32.496059
            ],
            [
              -82.657057,
              32.507167
            ],
            [
              -82.647733,
              32.512507
            ],
            [
              -82.674457,
              32.523866
            ],
            [
              -82.668557,
              32.612164
            ],
            [
              -82.794538,
              32.66023
            ],
            [
              -82.785782,
              32.6867
            ],
            [
              -82.862763,
              32.71576
            ],
            [
              -82.957066,
              32.708318
            ],
            [
              -83.040792,
              32.673339
            ],
            [
              -83.226536,
              32.584201
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "251",
      "COUNTYNS": "00350204",
      "AFFGEOID": "0500000US13251",
      "GEOID": "13251",
      "NAME": "Screven",
      "LSAD": "06",
      "ALAND": 1670807253,
      "AWATER": 27885165,
      "County_state": "Screven,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.867938,
              32.68115
            ],
            [
              -81.868663,
              32.673127
            ],
            [
              -81.865987,
              32.66299
            ],
            [
              -81.841005,
              32.649093
            ],
            [
              -81.833839,
              32.653385
            ],
            [
              -81.800506,
              32.641525
            ],
            [
              -81.793819,
              32.628521
            ],
            [
              -81.773113,
              32.606715
            ],
            [
              -81.754226,
              32.601988
            ],
            [
              -81.732643,
              32.576496
            ],
            [
              -81.708977,
              32.566132
            ],
            [
              -81.689643,
              32.54629
            ],
            [
              -81.683204,
              32.549225
            ],
            [
              -81.656799,
              32.539977
            ],
            [
              -81.648538,
              32.527684
            ],
            [
              -81.63932,
              32.532513
            ],
            [
              -81.629057,
              32.524189
            ],
            [
              -81.593375,
              32.506576
            ],
            [
              -81.595481,
              32.508274
            ],
            [
              -81.588066,
              32.512264
            ],
            [
              -81.586353,
              32.512068
            ],
            [
              -81.578689,
              32.512538
            ],
            [
              -81.548006,
              32.489286
            ],
            [
              -81.538558,
              32.509059
            ],
            [
              -81.3892612409816,
              32.5953827593042
            ],
            [
              -81.389338,
              32.595436
            ],
            [
              -81.393865,
              32.60234
            ],
            [
              -81.397106,
              32.605587
            ],
            [
              -81.404714,
              32.611207
            ],
            [
              -81.411906,
              32.61841
            ],
            [
              -81.41866,
              32.629392
            ],
            [
              -81.418431,
              32.634704
            ],
            [
              -81.417014,
              32.636147
            ],
            [
              -81.414761,
              32.63744
            ],
            [
              -81.413411,
              32.637368
            ],
            [
              -81.411523,
              32.632907
            ],
            [
              -81.41026,
              32.631392
            ],
            [
              -81.40933,
              32.631096
            ],
            [
              -81.407271,
              32.631737
            ],
            [
              -81.402846,
              32.63621
            ],
            [
              -81.402735,
              32.637032
            ],
            [
              -81.404238,
              32.638258
            ],
            [
              -81.405109,
              32.64269
            ],
            [
              -81.403582,
              32.643398
            ],
            [
              -81.394589,
              32.64957
            ],
            [
              -81.393033,
              32.651543
            ],
            [
              -81.393818,
              32.653491
            ],
            [
              -81.398314,
              32.656307
            ],
            [
              -81.405273,
              32.656517
            ],
            [
              -81.407193,
              32.660519
            ],
            [
              -81.4073,
              32.66156
            ],
            [
              -81.406646,
              32.662515
            ],
            [
              -81.404287,
              32.667798
            ],
            [
              -81.401029,
              32.677494
            ],
            [
              -81.401256,
              32.680156
            ],
            [
              -81.40831,
              32.694908
            ],
            [
              -81.409349,
              32.695269
            ],
            [
              -81.409982,
              32.695179
            ],
            [
              -81.41075,
              32.694772
            ],
            [
              -81.411157,
              32.693959
            ],
            [
              -81.411609,
              32.693145
            ],
            [
              -81.4131,
              32.692648
            ],
            [
              -81.426735,
              32.700867
            ],
            [
              -81.427517,
              32.701896
            ],
            [
              -81.421194,
              32.711978
            ],
            [
              -81.420516,
              32.720238
            ],
            [
              -81.418542,
              32.732586
            ],
            [
              -81.41267,
              32.739083
            ],
            [
              -81.411549,
              32.740145
            ],
            [
              -81.410845,
              32.741694
            ],
            [
              -81.410563,
              32.743244
            ],
            [
              -81.410281,
              32.744653
            ],
            [
              -81.410563,
              32.74592
            ],
            [
              -81.411408,
              32.747329
            ],
            [
              -81.412817,
              32.748174
            ],
            [
              -81.415353,
              32.748879
            ],
            [
              -81.416198,
              32.750428
            ],
            [
              -81.416761,
              32.752259
            ],
            [
              -81.416479,
              32.754654
            ],
            [
              -81.415212,
              32.757753
            ],
            [
              -81.416479,
              32.760289
            ],
            [
              -81.417606,
              32.762684
            ],
            [
              -81.420142,
              32.765501
            ],
            [
              -81.422396,
              32.767051
            ],
            [
              -81.425017,
              32.768058
            ],
            [
              -81.426199,
              32.768319
            ],
            [
              -81.426481,
              32.769023
            ],
            [
              -81.426481,
              32.770291
            ],
            [
              -81.426059,
              32.771136
            ],
            [
              -81.425636,
              32.77184
            ],
            [
              -81.424714,
              32.772648
            ],
            [
              -81.422678,
              32.773249
            ],
            [
              -81.421269,
              32.774658
            ],
            [
              -81.421128,
              32.775926
            ],
            [
              -81.420987,
              32.776912
            ],
            [
              -81.421128,
              32.778039
            ],
            [
              -81.422114,
              32.779306
            ],
            [
              -81.424227,
              32.780152
            ],
            [
              -81.426481,
              32.78142
            ],
            [
              -81.428313,
              32.78311
            ],
            [
              -81.429017,
              32.785505
            ],
            [
              -81.428031,
              32.787618
            ],
            [
              -81.424999,
              32.790334
            ],
            [
              -81.425234,
              32.79419
            ],
            [
              -81.424874,
              32.801882
            ],
            [
              -81.423772,
              32.810514
            ],
            [
              -81.419752,
              32.813731
            ],
            [
              -81.418497,
              32.815664
            ],
            [
              -81.417984,
              32.818196
            ],
            [
              -81.42062,
              32.831223
            ],
            [
              -81.421614,
              32.835178
            ],
            [
              -81.426475,
              32.840773
            ],
            [
              -81.442671,
              32.850107
            ],
            [
              -81.443595,
              32.850628
            ],
            [
              -81.444866,
              32.850967
            ],
            [
              -81.449396,
              32.849126
            ],
            [
              -81.451199,
              32.847925
            ],
            [
              -81.452573,
              32.84795
            ],
            [
              -81.453949,
              32.849761
            ],
            [
              -81.455978,
              32.854107
            ],
            [
              -81.453017,
              32.859636
            ],
            [
              -81.451351,
              32.868583
            ],
            [
              -81.452883,
              32.872964
            ],
            [
              -81.45392,
              32.874074
            ],
            [
              -81.468042,
              32.876675
            ],
            [
              -81.470376,
              32.876267
            ],
            [
              -81.475918,
              32.877641
            ],
            [
              -81.479445,
              32.881082
            ],
            [
              -81.4771,
              32.887469
            ],
            [
              -81.471703,
              32.890439
            ],
            [
              -81.470836,
              32.890521
            ],
            [
              -81.464655,
              32.895999
            ],
            [
              -81.464069,
              32.897814
            ],
            [
              -81.465924,
              32.899889
            ],
            [
              -81.468978,
              32.901083
            ],
            [
              -81.479184,
              32.905638
            ],
            [
              -81.480008,
              32.913444
            ],
            [
              -81.483198,
              32.921802
            ],
            [
              -81.495092,
              32.931596
            ],
            [
              -81.499829,
              32.932614
            ],
            [
              -81.502427,
              32.935353
            ],
            [
              -81.502716,
              32.938688
            ],
            [
              -81.499566,
              32.943722
            ],
            [
              -81.499446,
              32.944988
            ],
            [
              -81.504016,
              32.948091
            ],
            [
              -81.507045,
              32.951194
            ],
            [
              -81.508138,
              32.953976
            ],
            [
              -81.508436,
              32.955765
            ],
            [
              -81.508536,
              32.957156
            ],
            [
              -81.508436,
              32.958349
            ],
            [
              -81.507741,
              32.959243
            ],
            [
              -81.507442,
              32.960237
            ],
            [
              -81.507144,
              32.96133
            ],
            [
              -81.506449,
              32.962423
            ],
            [
              -81.505256,
              32.963019
            ],
            [
              -81.503346,
              32.96295
            ],
            [
              -81.501369,
              32.962914
            ],
            [
              -81.49983,
              32.963816
            ],
            [
              -81.499471,
              32.96478
            ],
            [
              -81.494736,
              32.978998
            ],
            [
              -81.491457,
              32.995437
            ],
            [
              -81.491197,
              32.997824
            ],
            [
              -81.491419,
              33.008078
            ],
            [
              -81.492253,
              33.009342
            ],
            [
              -81.50203,
              33.015113
            ],
            [
              -81.511245,
              33.027786
            ],
            [
              -81.513231,
              33.028546
            ],
            [
              -81.519632,
              33.029181
            ],
            [
              -81.538789,
              33.039185
            ],
            [
              -81.540081,
              33.040613
            ],
            [
              -81.542092,
              33.044859
            ],
            [
              -81.542509768548,
              33.04525362347609
            ],
            [
              -81.767553,
              32.909411
            ],
            [
              -81.867938,
              32.68115
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "301",
      "COUNTYNS": "00347777",
      "AFFGEOID": "0500000US13301",
      "GEOID": "13301",
      "NAME": "Warren",
      "LSAD": "06",
      "ALAND": 736316130,
      "AWATER": 6201954,
      "County_state": "Warren,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.866605,
              33.466599
            ],
            [
              -82.851954,
              33.443543
            ],
            [
              -82.839663,
              33.43094
            ],
            [
              -82.824105,
              33.427627
            ],
            [
              -82.825807,
              33.39043
            ],
            [
              -82.821706,
              33.374268
            ],
            [
              -82.802916,
              33.365035
            ],
            [
              -82.79245,
              33.309413
            ],
            [
              -82.781574,
              33.304467
            ],
            [
              -82.785591,
              33.268726
            ],
            [
              -82.755846,
              33.25344
            ],
            [
              -82.732751,
              33.256489
            ],
            [
              -82.702577,
              33.272883
            ],
            [
              -82.679159,
              33.273926
            ],
            [
              -82.645593,
              33.301448
            ],
            [
              -82.615612,
              33.306809
            ],
            [
              -82.591698,
              33.320985
            ],
            [
              -82.559753,
              33.32727
            ],
            [
              -82.496847,
              33.302991
            ],
            [
              -82.431957,
              33.27483
            ],
            [
              -82.383829,
              33.312106
            ],
            [
              -82.411074,
              33.319922
            ],
            [
              -82.449977,
              33.340505
            ],
            [
              -82.51072,
              33.342045
            ],
            [
              -82.535779,
              33.35746
            ],
            [
              -82.548068,
              33.352783
            ],
            [
              -82.544313,
              33.374721
            ],
            [
              -82.551901,
              33.408425
            ],
            [
              -82.553616,
              33.415802
            ],
            [
              -82.584563,
              33.449654
            ],
            [
              -82.607437,
              33.505922
            ],
            [
              -82.649705,
              33.608768
            ],
            [
              -82.652301,
              33.596239
            ],
            [
              -82.679975,
              33.599792
            ],
            [
              -82.704968,
              33.587513
            ],
            [
              -82.719238,
              33.563962
            ],
            [
              -82.722011,
              33.540581
            ],
            [
              -82.740128,
              33.528618
            ],
            [
              -82.749072,
              33.510685
            ],
            [
              -82.767286,
              33.51457
            ],
            [
              -82.787605,
              33.5107
            ],
            [
              -82.815315,
              33.521137
            ],
            [
              -82.823141,
              33.504075
            ],
            [
              -82.866605,
              33.466599
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "043",
      "COUNTYNS": "00342852",
      "AFFGEOID": "0500000US13043",
      "GEOID": "13043",
      "NAME": "Candler",
      "LSAD": "06",
      "ALAND": 629508737,
      "AWATER": 15030369,
      "County_state": "Candler,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.254526,
              32.351498
            ],
            [
              -82.241993,
              32.347202
            ],
            [
              -82.232788,
              32.318838
            ],
            [
              -82.025339,
              32.278883
            ],
            [
              -81.999843,
              32.276189
            ],
            [
              -81.969069,
              32.268783
            ],
            [
              -81.956924,
              32.286228
            ],
            [
              -81.931981,
              32.373914
            ],
            [
              -81.918671,
              32.41541
            ],
            [
              -81.939609,
              32.425829
            ],
            [
              -81.939763,
              32.434918
            ],
            [
              -81.962123,
              32.452508
            ],
            [
              -81.976299,
              32.477276
            ],
            [
              -81.976947,
              32.497808
            ],
            [
              -81.971003,
              32.51324
            ],
            [
              -82.030233,
              32.53877
            ],
            [
              -82.079842,
              32.560063
            ],
            [
              -82.148338,
              32.520429
            ],
            [
              -82.181145,
              32.474066
            ],
            [
              -82.183495,
              32.460141
            ],
            [
              -82.187944,
              32.433571
            ],
            [
              -82.254526,
              32.351498
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "071",
      "COUNTYNS": "00343936",
      "AFFGEOID": "0500000US13071",
      "GEOID": "13071",
      "NAME": "Colquitt",
      "LSAD": "06",
      "ALAND": 1416682032,
      "AWATER": 25006488,
      "County_state": "Colquitt,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.013851,
              31.102436
            ],
            [
              -84.003201,
              31.102349
            ],
            [
              -84.003627,
              31.07729
            ],
            [
              -84.003876,
              31.041582
            ],
            [
              -83.918397,
              31.040508
            ],
            [
              -83.795731,
              31.038733
            ],
            [
              -83.795235,
              31.025988
            ],
            [
              -83.78083,
              31.038502
            ],
            [
              -83.736158,
              31.037679
            ],
            [
              -83.573959,
              31.033875
            ],
            [
              -83.574857,
              31.078135
            ],
            [
              -83.567499,
              31.100747
            ],
            [
              -83.557331,
              31.110501
            ],
            [
              -83.555463,
              31.144779
            ],
            [
              -83.543492,
              31.147986
            ],
            [
              -83.540698,
              31.17898
            ],
            [
              -83.509961,
              31.205972
            ],
            [
              -83.513519,
              31.228701
            ],
            [
              -83.506371,
              31.27977
            ],
            [
              -83.515297,
              31.302349
            ],
            [
              -83.512607,
              31.327405
            ],
            [
              -83.65409,
              31.330661
            ],
            [
              -83.713867,
              31.331652
            ],
            [
              -83.714134,
              31.318365
            ],
            [
              -83.758934,
              31.318954
            ],
            [
              -83.759086,
              31.332225
            ],
            [
              -83.775765,
              31.332319
            ],
            [
              -83.99943,
              31.334966
            ],
            [
              -84.002977,
              31.11189
            ],
            [
              -84.013851,
              31.102436
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "137",
      "COUNTYNS": "00351383",
      "AFFGEOID": "0500000US13137",
      "GEOID": "13137",
      "NAME": "Habersham",
      "LSAD": "06",
      "ALAND": 716858330,
      "AWATER": 5814561,
      "County_state": "Habersham,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.666855,
              34.806528
            ],
            [
              -83.681596,
              34.800593
            ],
            [
              -83.646095,
              34.771794
            ],
            [
              -83.628295,
              34.763394
            ],
            [
              -83.632696,
              34.753994
            ],
            [
              -83.623296,
              34.732194
            ],
            [
              -83.639497,
              34.710494
            ],
            [
              -83.636502,
              34.699599
            ],
            [
              -83.645254,
              34.669623
            ],
            [
              -83.662819,
              34.659801
            ],
            [
              -83.638243,
              34.62238
            ],
            [
              -83.646522,
              34.612209
            ],
            [
              -83.63884,
              34.580029
            ],
            [
              -83.61554,
              34.552864
            ],
            [
              -83.63311,
              34.52689
            ],
            [
              -83.662781,
              34.513538
            ],
            [
              -83.666415,
              34.503602
            ],
            [
              -83.615251,
              34.431748
            ],
            [
              -83.607475,
              34.444279
            ],
            [
              -83.585787,
              34.452545
            ],
            [
              -83.55238,
              34.485841
            ],
            [
              -83.537436,
              34.491734
            ],
            [
              -83.518385,
              34.489406
            ],
            [
              -83.469925,
              34.484055
            ],
            [
              -83.45977,
              34.481142
            ],
            [
              -83.365044,
              34.675308
            ],
            [
              -83.33933481518201,
              34.686666100517996
            ],
            [
              -83.339367,
              34.686967
            ],
            [
              -83.340383,
              34.688998
            ],
            [
              -83.342414,
              34.691255
            ],
            [
              -83.344671,
              34.693512
            ],
            [
              -83.347831,
              34.696108
            ],
            [
              -83.349411,
              34.697575
            ],
            [
              -83.349975,
              34.699155
            ],
            [
              -83.349636,
              34.70096
            ],
            [
              -83.347831,
              34.703669
            ],
            [
              -83.347718,
              34.705474
            ],
            [
              -83.349788,
              34.708274
            ],
            [
              -83.350976,
              34.713243
            ],
            [
              -83.351392,
              34.714456
            ],
            [
              -83.352485,
              34.715993
            ],
            [
              -83.3524926405687,
              34.7161214082301
            ],
            [
              -83.387382,
              34.737392
            ],
            [
              -83.403662,
              34.728921
            ],
            [
              -83.431221,
              34.747345
            ],
            [
              -83.45529,
              34.732628
            ],
            [
              -83.478622,
              34.723949
            ],
            [
              -83.503487,
              34.734291
            ],
            [
              -83.512788,
              34.731683
            ],
            [
              -83.548988,
              34.749451
            ],
            [
              -83.56221,
              34.781101
            ],
            [
              -83.556641,
              34.785057
            ],
            [
              -83.576248,
              34.795494
            ],
            [
              -83.586897,
              34.819991
            ],
            [
              -83.601566,
              34.826615
            ],
            [
              -83.613084,
              34.822015
            ],
            [
              -83.652194,
              34.822993
            ],
            [
              -83.666855,
              34.806528
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "165",
      "COUNTYNS": "00342985",
      "AFFGEOID": "0500000US13165",
      "GEOID": "13165",
      "NAME": "Jenkins",
      "LSAD": "06",
      "ALAND": 899563041,
      "AWATER": 13338830,
      "County_state": "Jenkins,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.147942,
              32.819758
            ],
            [
              -82.144977,
              32.812741
            ],
            [
              -82.082871,
              32.663324
            ],
            [
              -82.001236,
              32.60691
            ],
            [
              -81.943693,
              32.621968
            ],
            [
              -81.841005,
              32.649093
            ],
            [
              -81.865987,
              32.66299
            ],
            [
              -81.868663,
              32.673127
            ],
            [
              -81.867938,
              32.68115
            ],
            [
              -81.767553,
              32.909411
            ],
            [
              -81.783842,
              32.928345
            ],
            [
              -81.857345,
              32.953684
            ],
            [
              -81.956448,
              32.923724
            ],
            [
              -82.081349,
              32.916762
            ],
            [
              -82.123742,
              32.883656
            ],
            [
              -82.147942,
              32.819758
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "005",
      "COUNTYNS": "00344784",
      "AFFGEOID": "0500000US13005",
      "GEOID": "13005",
      "NAME": "Bacon",
      "LSAD": "06",
      "ALAND": 735650672,
      "AWATER": 4625861,
      "County_state": "Bacon,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.627342,
              31.672672
            ],
            [
              -82.628273,
              31.558329
            ],
            [
              -82.596283,
              31.557899
            ],
            [
              -82.597509,
              31.469293
            ],
            [
              -82.494718,
              31.46869
            ],
            [
              -82.49365,
              31.442892
            ],
            [
              -82.481438,
              31.443075
            ],
            [
              -82.48146,
              31.417534
            ],
            [
              -82.417246,
              31.417119
            ],
            [
              -82.401794,
              31.417278
            ],
            [
              -82.401512,
              31.430292
            ],
            [
              -82.344843,
              31.43013
            ],
            [
              -82.344989,
              31.441755
            ],
            [
              -82.330493,
              31.442058
            ],
            [
              -82.330021,
              31.457095
            ],
            [
              -82.315111,
              31.457138
            ],
            [
              -82.315025,
              31.467936
            ],
            [
              -82.300621,
              31.467812
            ],
            [
              -82.300422,
              31.493175
            ],
            [
              -82.269661,
              31.492643
            ],
            [
              -82.269584,
              31.505699
            ],
            [
              -82.255829,
              31.505424
            ],
            [
              -82.255946,
              31.517409
            ],
            [
              -82.23883,
              31.517639
            ],
            [
              -82.239254,
              31.530569
            ],
            [
              -82.226585,
              31.530699
            ],
            [
              -82.239639,
              31.554724
            ],
            [
              -82.269732,
              31.571882
            ],
            [
              -82.318077,
              31.594286
            ],
            [
              -82.348526,
              31.620661
            ],
            [
              -82.389152,
              31.637814
            ],
            [
              -82.407509,
              31.6392
            ],
            [
              -82.431855,
              31.658139
            ],
            [
              -82.45089,
              31.661381
            ],
            [
              -82.495834,
              31.710194
            ],
            [
              -82.52142,
              31.710796
            ],
            [
              -82.521393,
              31.672512
            ],
            [
              -82.627342,
              31.672672
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "191",
      "COUNTYNS": "00356886",
      "AFFGEOID": "0500000US13191",
      "GEOID": "13191",
      "NAME": "McIntosh",
      "LSAD": "06",
      "ALAND": 1099290158,
      "AWATER": 387088216,
      "County_state": "McIntosh,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.663206,
              31.538667
            ],
            [
              -81.652252,
              31.528551
            ],
            [
              -81.65818,
              31.510147
            ],
            [
              -81.612054,
              31.469405
            ],
            [
              -81.62429,
              31.452982
            ],
            [
              -81.607192,
              31.440111
            ],
            [
              -81.610549,
              31.434326
            ],
            [
              -81.577575,
              31.399429
            ],
            [
              -81.545669,
              31.384834
            ],
            [
              -81.550521,
              31.375572
            ],
            [
              -81.535903,
              31.3764
            ],
            [
              -81.534255,
              31.367805
            ],
            [
              -81.505546,
              31.35561
            ],
            [
              -81.493072,
              31.343452
            ],
            [
              -81.470687,
              31.339674
            ],
            [
              -81.454094,
              31.326415
            ],
            [
              -81.438888,
              31.332148
            ],
            [
              -81.435874,
              31.313263
            ],
            [
              -81.403465,
              31.307669
            ],
            [
              -81.412307,
              31.294733
            ],
            [
              -81.401534,
              31.295182
            ],
            [
              -81.382995,
              31.317108
            ],
            [
              -81.368804,
              31.318703
            ],
            [
              -81.319122,
              31.309172
            ],
            [
              -81.298232,
              31.292456
            ],
            [
              -81.2699059255329,
              31.294488935797197
            ],
            [
              -81.260958,
              31.30391
            ],
            [
              -81.25482,
              31.315452
            ],
            [
              -81.268027,
              31.324218
            ],
            [
              -81.274513,
              31.326237
            ],
            [
              -81.282923,
              31.326491
            ],
            [
              -81.279338,
              31.351127
            ],
            [
              -81.278798,
              31.367214
            ],
            [
              -81.258616,
              31.404425
            ],
            [
              -81.246911,
              31.422784
            ],
            [
              -81.204883,
              31.473124
            ],
            [
              -81.189643,
              31.503588
            ],
            [
              -81.177254,
              31.517074
            ],
            [
              -81.17831,
              31.52241
            ],
            [
              -81.181592,
              31.527697
            ],
            [
              -81.193016,
              31.535833
            ],
            [
              -81.199518,
              31.537596
            ],
            [
              -81.213519,
              31.528152
            ],
            [
              -81.217948,
              31.527284
            ],
            [
              -81.258809,
              31.52906
            ],
            [
              -81.263437,
              31.530932
            ],
            [
              -81.263905,
              31.532579
            ],
            [
              -81.260076,
              31.54828
            ],
            [
              -81.254218,
              31.55594
            ],
            [
              -81.240699,
              31.552313
            ],
            [
              -81.214536,
              31.557601
            ],
            [
              -81.204315,
              31.568183
            ],
            [
              -81.1943802746182,
              31.5681005790598
            ],
            [
              -81.190124,
              31.598101
            ],
            [
              -81.197234,
              31.608314
            ],
            [
              -81.21659,
              31.619326
            ],
            [
              -81.22222,
              31.63042
            ],
            [
              -81.261868,
              31.650142
            ],
            [
              -81.302416,
              31.650716
            ],
            [
              -81.314548,
              31.659382
            ],
            [
              -81.340167,
              31.647348
            ],
            [
              -81.362942,
              31.654538
            ],
            [
              -81.391576,
              31.643561
            ],
            [
              -81.417587,
              31.652094
            ],
            [
              -81.434188,
              31.64109
            ],
            [
              -81.455673,
              31.659813
            ],
            [
              -81.467964,
              31.679056
            ],
            [
              -81.465684,
              31.694162
            ],
            [
              -81.491798,
              31.699575
            ],
            [
              -81.566009,
              31.576467
            ],
            [
              -81.622216,
              31.551295
            ],
            [
              -81.663206,
              31.538667
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "245",
      "COUNTYNS": "00358024",
      "AFFGEOID": "0500000US13245",
      "GEOID": "13245",
      "NAME": "Richmond",
      "LSAD": "06",
      "ALAND": 840026450,
      "AWATER": 11024164,
      "County_state": "Richmond,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.353498,
              33.312318
            ],
            [
              -82.341456,
              33.31174
            ],
            [
              -82.30129,
              33.288304
            ],
            [
              -82.295952,
              33.276573
            ],
            [
              -82.26758,
              33.267397
            ],
            [
              -82.17424,
              33.296771
            ],
            [
              -82.165077,
              33.295998
            ],
            [
              -82.160344,
              33.288949
            ],
            [
              -82.145035,
              33.282061
            ],
            [
              -82.125329,
              33.249665
            ],
            [
              -82.100764,
              33.231629
            ],
            [
              -82.060031,
              33.242566
            ],
            [
              -82.045078,
              33.236386
            ],
            [
              -81.979195,
              33.227165
            ],
            [
              -81.934559,
              33.252963
            ],
            [
              -81.886035,
              33.26185
            ],
            [
              -81.851979119203,
              33.2473815723462
            ],
            [
              -81.852136,
              33.247544
            ],
            [
              -81.853137,
              33.250745
            ],
            [
              -81.847336,
              33.266345
            ],
            [
              -81.842522,
              33.266584
            ],
            [
              -81.840078,
              33.26704
            ],
            [
              -81.838337,
              33.269098
            ],
            [
              -81.838257,
              33.272975
            ],
            [
              -81.844036,
              33.278644
            ],
            [
              -81.851836,
              33.283544
            ],
            [
              -81.861336,
              33.286244
            ],
            [
              -81.863236,
              33.288844
            ],
            [
              -81.861536,
              33.297944
            ],
            [
              -81.857336,
              33.299544
            ],
            [
              -81.852936,
              33.299644
            ],
            [
              -81.851636,
              33.298544
            ],
            [
              -81.849636,
              33.299544
            ],
            [
              -81.846136,
              33.303843
            ],
            [
              -81.847296,
              33.306783
            ],
            [
              -81.853652,
              33.310326
            ],
            [
              -81.867936,
              33.314043
            ],
            [
              -81.870436,
              33.312943
            ],
            [
              -81.875836,
              33.307443
            ],
            [
              -81.884137,
              33.310443
            ],
            [
              -81.886637,
              33.316943
            ],
            [
              -81.897329,
              33.322331
            ],
            [
              -81.897064,
              33.324445
            ],
            [
              -81.896937,
              33.327642
            ],
            [
              -81.898187,
              33.329664
            ],
            [
              -81.900301,
              33.331117
            ],
            [
              -81.902613,
              33.330258
            ],
            [
              -81.904132,
              33.327286
            ],
            [
              -81.906444,
              33.324181
            ],
            [
              -81.909285,
              33.324181
            ],
            [
              -81.910342,
              33.32537
            ],
            [
              -81.911266,
              33.327616
            ],
            [
              -81.913314,
              33.329532
            ],
            [
              -81.918337,
              33.332842
            ],
            [
              -81.919137,
              33.334442
            ],
            [
              -81.917973,
              33.34159
            ],
            [
              -81.924737,
              33.345341
            ],
            [
              -81.932737,
              33.343541
            ],
            [
              -81.937237,
              33.343641
            ],
            [
              -81.939737,
              33.344941
            ],
            [
              -81.939837,
              33.347741
            ],
            [
              -81.9379453725436,
              33.3496776662054
            ],
            [
              -81.935637,
              33.352041
            ],
            [
              -81.934837,
              33.356041
            ],
            [
              -81.944737,
              33.364041
            ],
            [
              -81.946737,
              33.36734
            ],
            [
              -81.946337,
              33.37064
            ],
            [
              -81.943737,
              33.37234
            ],
            [
              -81.939637,
              33.37254
            ],
            [
              -81.934637,
              33.36894
            ],
            [
              -81.930634,
              33.368165
            ],
            [
              -81.925737,
              33.37114
            ],
            [
              -81.924837,
              33.37414
            ],
            [
              -81.930861,
              33.380076
            ],
            [
              -81.935453,
              33.397851
            ],
            [
              -81.9373,
              33.401259
            ],
            [
              -81.936961,
              33.404197
            ],
            [
              -81.934927,
              33.406006
            ],
            [
              -81.930519,
              33.406797
            ],
            [
              -81.92306,
              33.408266
            ],
            [
              -81.920121,
              33.410753
            ],
            [
              -81.919217,
              33.413126
            ],
            [
              -81.91933,
              33.415613
            ],
            [
              -81.921068,
              33.417419
            ],
            [
              -81.924893,
              33.419307
            ],
            [
              -81.927241,
              33.422846
            ],
            [
              -81.926789,
              33.426576
            ],
            [
              -81.924981,
              33.429288
            ],
            [
              -81.920716,
              33.430986
            ],
            [
              -81.916236,
              33.433114
            ],
            [
              -81.913356,
              33.437418
            ],
            [
              -81.913457,
              33.439641
            ],
            [
              -81.913532,
              33.441274
            ],
            [
              -81.920836,
              33.452038
            ],
            [
              -81.9259452812842,
              33.4621627375848
            ],
            [
              -81.926336,
              33.462937
            ],
            [
              -81.929436,
              33.465837
            ],
            [
              -81.934136,
              33.468337
            ],
            [
              -81.941737,
              33.470037
            ],
            [
              -81.946437,
              33.471737
            ],
            [
              -81.95534093127469,
              33.4755834118647
            ],
            [
              -81.967037,
              33.480636
            ],
            [
              -81.973537,
              33.482636
            ],
            [
              -81.980637,
              33.484036
            ],
            [
              -81.985938,
              33.486536
            ],
            [
              -81.989338,
              33.490036
            ],
            [
              -81.990938,
              33.494235
            ],
            [
              -81.990382,
              33.500759
            ],
            [
              -81.991938,
              33.504435
            ],
            [
              -82.001338,
              33.520135
            ],
            [
              -82.004338,
              33.521935
            ],
            [
              -82.007138,
              33.522835
            ],
            [
              -82.007638,
              33.523335
            ],
            [
              -82.010038,
              33.530435
            ],
            [
              -82.01153651351349,
              33.531733711711695
            ],
            [
              -82.011538,
              33.531735
            ],
            [
              -82.0124261353723,
              33.532088114063704
            ],
            [
              -82.019838,
              33.535035
            ],
            [
              -82.023438,
              33.537935
            ],
            [
              -82.023438,
              33.540734
            ],
            [
              -82.028238,
              33.544934
            ],
            [
              -82.053705,
              33.527702
            ],
            [
              -82.070688,
              33.5161
            ],
            [
              -82.108451,
              33.488497
            ],
            [
              -82.124841,
              33.476811
            ],
            [
              -82.128799,
              33.474373
            ],
            [
              -82.28283,
              33.362695
            ],
            [
              -82.294179,
              33.354635
            ],
            [
              -82.353498,
              33.312318
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "317",
      "COUNTYNS": "00351265",
      "AFFGEOID": "0500000US13317",
      "GEOID": "13317",
      "NAME": "Wilkes",
      "LSAD": "06",
      "ALAND": 1215973894,
      "AWATER": 11924769,
      "County_state": "Wilkes,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.986325,
              33.785405
            ],
            [
              -82.987286,
              33.78071
            ],
            [
              -82.959871,
              33.751621
            ],
            [
              -82.949046,
              33.733333
            ],
            [
              -82.890599,
              33.632877
            ],
            [
              -82.879716,
              33.620748
            ],
            [
              -82.869336,
              33.642404
            ],
            [
              -82.850325,
              33.639552
            ],
            [
              -82.832603,
              33.650963
            ],
            [
              -82.812958,
              33.655836
            ],
            [
              -82.779913,
              33.632577
            ],
            [
              -82.74383,
              33.611442
            ],
            [
              -82.692039,
              33.619745
            ],
            [
              -82.679975,
              33.599792
            ],
            [
              -82.652301,
              33.596239
            ],
            [
              -82.649705,
              33.608768
            ],
            [
              -82.643525,
              33.618034
            ],
            [
              -82.633502,
              33.611132
            ],
            [
              -82.617049,
              33.612171
            ],
            [
              -82.623749,
              33.619394
            ],
            [
              -82.617847,
              33.632203
            ],
            [
              -82.586683,
              33.640492
            ],
            [
              -82.583603,
              33.650477
            ],
            [
              -82.573511,
              33.639505
            ],
            [
              -82.565583,
              33.645918
            ],
            [
              -82.544281,
              33.638573
            ],
            [
              -82.537228,
              33.64916
            ],
            [
              -82.525209,
              33.644698
            ],
            [
              -82.507223,
              33.659994
            ],
            [
              -82.479518,
              33.639535
            ],
            [
              -82.573652,
              33.786116
            ],
            [
              -82.595596,
              33.828898
            ],
            [
              -82.573282,
              33.8557
            ],
            [
              -82.576909,
              33.873977
            ],
            [
              -82.605687,
              33.859942
            ],
            [
              -82.617125,
              33.892979
            ],
            [
              -82.645043,
              33.981048
            ],
            [
              -82.645451,
              33.984195
            ],
            [
              -82.654876,
              33.991265
            ],
            [
              -82.701658,
              33.981346
            ],
            [
              -82.718719,
              33.984776
            ],
            [
              -82.753566,
              33.972253
            ],
            [
              -82.779506,
              33.971124
            ],
            [
              -82.79345,
              33.94984
            ],
            [
              -82.825307,
              33.94092
            ],
            [
              -82.846686,
              33.940639
            ],
            [
              -82.855752,
              33.927443
            ],
            [
              -82.874587,
              33.921767
            ],
            [
              -82.89008,
              33.896489
            ],
            [
              -82.912276,
              33.877246
            ],
            [
              -82.922082,
              33.85676
            ],
            [
              -82.942554,
              33.848781
            ],
            [
              -82.9616,
              33.811007
            ],
            [
              -82.9763,
              33.80235
            ],
            [
              -82.986325,
              33.785405
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "079",
      "COUNTYNS": "00342918",
      "AFFGEOID": "0500000US13079",
      "GEOID": "13079",
      "NAME": "Crawford",
      "LSAD": "06",
      "ALAND": 841521994,
      "AWATER": 3988980,
      "County_state": "Crawford,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.202628,
              32.690018
            ],
            [
              -84.174217,
              32.677658
            ],
            [
              -84.145738,
              32.68525
            ],
            [
              -84.127591,
              32.674534
            ],
            [
              -84.105924,
              32.685049
            ],
            [
              -84.104966,
              32.673385
            ],
            [
              -84.078156,
              32.647239
            ],
            [
              -84.073236,
              32.635581
            ],
            [
              -84.056765,
              32.636518
            ],
            [
              -84.072171,
              32.618603
            ],
            [
              -84.071252,
              32.598264
            ],
            [
              -84.052549,
              32.566837
            ],
            [
              -84.035379,
              32.55849
            ],
            [
              -84.003363,
              32.529927
            ],
            [
              -83.954708,
              32.563048
            ],
            [
              -83.945237,
              32.552667
            ],
            [
              -83.945364,
              32.569617
            ],
            [
              -83.882642,
              32.613103
            ],
            [
              -83.766787,
              32.692622
            ],
            [
              -83.70109,
              32.691598
            ],
            [
              -83.701152,
              32.692169
            ],
            [
              -83.736292,
              32.693633
            ],
            [
              -83.776874,
              32.718809
            ],
            [
              -83.810141,
              32.729942
            ],
            [
              -83.853432,
              32.782016
            ],
            [
              -83.856924,
              32.793743
            ],
            [
              -83.876942,
              32.806944
            ],
            [
              -83.882471,
              32.818392
            ],
            [
              -83.89185,
              32.81908
            ],
            [
              -83.891922,
              32.848351
            ],
            [
              -83.934953,
              32.848582
            ],
            [
              -83.968421,
              32.84886
            ],
            [
              -83.968309,
              32.857136
            ],
            [
              -83.978409,
              32.848936
            ],
            [
              -84.124275,
              32.849562
            ],
            [
              -84.124332,
              32.800935
            ],
            [
              -84.149328,
              32.801206
            ],
            [
              -84.202628,
              32.690018
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "047",
      "COUNTYNS": "00353230",
      "AFFGEOID": "0500000US13047",
      "GEOID": "13047",
      "NAME": "Catoosa",
      "LSAD": "06",
      "ALAND": 419999268,
      "AWATER": 771158,
      "County_state": "Catoosa,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.265055,
              34.985075
            ],
            [
              -85.264621,
              34.927422
            ],
            [
              -85.264479,
              34.901006
            ],
            [
              -85.264762,
              34.854277
            ],
            [
              -85.20218,
              34.854451
            ],
            [
              -85.201856,
              34.77508
            ],
            [
              -85.159819,
              34.775197
            ],
            [
              -85.144789,
              34.767639
            ],
            [
              -85.13175,
              34.819678
            ],
            [
              -85.12275,
              34.812078
            ],
            [
              -85.105349,
              34.819178
            ],
            [
              -85.061648,
              34.819478
            ],
            [
              -85.061647,
              34.863576
            ],
            [
              -85.032727,
              34.863731
            ],
            [
              -85.023299,
              34.871131
            ],
            [
              -85.019864,
              34.900787
            ],
            [
              -85.013868,
              34.901502
            ],
            [
              -84.997874,
              34.940207
            ],
            [
              -84.997432,
              34.972435
            ],
            [
              -84.979661,
              34.972755
            ],
            [
              -84.97986,
              34.987647
            ],
            [
              -85.045052,
              34.986859
            ],
            [
              -85.045183,
              34.986883
            ],
            [
              -85.180553,
              34.986075
            ],
            [
              -85.185905,
              34.985995
            ],
            [
              -85.216554,
              34.985675
            ],
            [
              -85.217854,
              34.985675
            ],
            [
              -85.220554,
              34.985575
            ],
            [
              -85.221854,
              34.985475
            ],
            [
              -85.230354,
              34.985475
            ],
            [
              -85.235555,
              34.985475
            ],
            [
              -85.254955,
              34.985175
            ],
            [
              -85.265055,
              34.985075
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "067",
      "COUNTYNS": "01686112",
      "AFFGEOID": "0500000US13067",
      "GEOID": "13067",
      "NAME": "Cobb",
      "LSAD": "06",
      "ALAND": 879926969,
      "AWATER": 12497709,
      "County_state": "Cobb,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.738933,
              34.029962
            ],
            [
              -84.739636,
              33.947602
            ],
            [
              -84.723236,
              33.947353
            ],
            [
              -84.72423,
              33.903596
            ],
            [
              -84.724935,
              33.827559
            ],
            [
              -84.725033,
              33.820065
            ],
            [
              -84.724139,
              33.80617
            ],
            [
              -84.71493,
              33.806264
            ],
            [
              -84.693982,
              33.805706
            ],
            [
              -84.659771,
              33.805406
            ],
            [
              -84.623087,
              33.804786
            ],
            [
              -84.619988,
              33.801547
            ],
            [
              -84.581135,
              33.749765
            ],
            [
              -84.578132,
              33.743507
            ],
            [
              -84.538033,
              33.771104
            ],
            [
              -84.529131,
              33.783306
            ],
            [
              -84.515411,
              33.790334
            ],
            [
              -84.495332,
              33.808404
            ],
            [
              -84.480134,
              33.817319
            ],
            [
              -84.46843,
              33.827404
            ],
            [
              -84.457881,
              33.824578
            ],
            [
              -84.455876,
              33.82577
            ],
            [
              -84.455493,
              33.826808
            ],
            [
              -84.459829,
              33.853204
            ],
            [
              -84.454367,
              33.858764
            ],
            [
              -84.455929,
              33.864703
            ],
            [
              -84.447173,
              33.874757
            ],
            [
              -84.443087,
              33.879216
            ],
            [
              -84.441416,
              33.890151
            ],
            [
              -84.442708,
              33.901543
            ],
            [
              -84.447394,
              33.909935
            ],
            [
              -84.405177,
              33.943734
            ],
            [
              -84.398027,
              33.95121
            ],
            [
              -84.388655,
              33.958041
            ],
            [
              -84.383058,
              33.966921
            ],
            [
              -84.374772,
              33.986626
            ],
            [
              -84.383329,
              33.992804
            ],
            [
              -84.401235,
              34.014396
            ],
            [
              -84.403598,
              34.036472
            ],
            [
              -84.408386,
              34.03798
            ],
            [
              -84.411007,
              34.049445
            ],
            [
              -84.419028,
              34.061374
            ],
            [
              -84.418927,
              34.073298
            ],
            [
              -84.484781,
              34.07471
            ],
            [
              -84.515431,
              34.078698
            ],
            [
              -84.521992,
              34.075399
            ],
            [
              -84.538597,
              34.075692
            ],
            [
              -84.571683,
              34.076299
            ],
            [
              -84.571859,
              34.076299
            ],
            [
              -84.588524,
              34.076601
            ],
            [
              -84.618238,
              34.081655
            ],
            [
              -84.624235,
              34.078799
            ],
            [
              -84.626842,
              34.07749
            ],
            [
              -84.659241,
              34.07824
            ],
            [
              -84.702313,
              34.078794
            ],
            [
              -84.737836,
              34.079399
            ],
            [
              -84.738438,
              34.057396
            ],
            [
              -84.738933,
              34.029962
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "055",
      "COUNTYNS": "00352213",
      "AFFGEOID": "0500000US13055",
      "GEOID": "13055",
      "NAME": "Chattooga",
      "LSAD": "06",
      "ALAND": 811512406,
      "AWATER": 570806,
      "County_state": "Chattooga,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.5272733946093,
              34.5886802607335
            ],
            [
              -85.517089,
              34.542598
            ],
            [
              -85.513945,
              34.525192
            ],
            [
              -85.5137295115038,
              34.524190957986
            ],
            [
              -85.513725,
              34.52417
            ],
            [
              -85.512124,
              34.518252
            ],
            [
              -85.5084,
              34.501212
            ],
            [
              -85.502471,
              34.474526
            ],
            [
              -85.502333,
              34.473953
            ],
            [
              -85.48460743979379,
              34.3928858644596
            ],
            [
              -85.474036379434,
              34.3445395457524
            ],
            [
              -85.470472,
              34.328238
            ],
            [
              -85.46210536142519,
              34.28638558983619
            ],
            [
              -85.387351,
              34.286097
            ],
            [
              -85.382818,
              34.303283
            ],
            [
              -85.363197,
              34.33902
            ],
            [
              -85.334881,
              34.369748
            ],
            [
              -85.280215,
              34.389541
            ],
            [
              -85.241444,
              34.408422
            ],
            [
              -85.241341,
              34.415518
            ],
            [
              -85.222437,
              34.415533
            ],
            [
              -85.215078,
              34.429586
            ],
            [
              -85.180321,
              34.436316
            ],
            [
              -85.162853,
              34.472321
            ],
            [
              -85.14765,
              34.514985
            ],
            [
              -85.123549,
              34.553184
            ],
            [
              -85.107748,
              34.587483
            ],
            [
              -85.124949,
              34.588135
            ],
            [
              -85.322766,
              34.589145
            ],
            [
              -85.353477,
              34.589256
            ],
            [
              -85.352597,
              34.582316
            ],
            [
              -85.371725,
              34.589584
            ],
            [
              -85.5272733946093,
              34.5886802607335
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "075",
      "COUNTYNS": "00343878",
      "AFFGEOID": "0500000US13075",
      "GEOID": "13075",
      "NAME": "Cook",
      "LSAD": "06",
      "ALAND": 588450813,
      "AWATER": 15542061,
      "County_state": "Cook,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.567499,
              31.100747
            ],
            [
              -83.574857,
              31.078135
            ],
            [
              -83.551628,
              31.054329
            ],
            [
              -83.508196,
              31.04829
            ],
            [
              -83.475617,
              31.031964
            ],
            [
              -83.385648,
              31.029408
            ],
            [
              -83.295052,
              31.027278
            ],
            [
              -83.279781,
              31.063351
            ],
            [
              -83.296624,
              31.07192
            ],
            [
              -83.306417,
              31.092672
            ],
            [
              -83.321403,
              31.097
            ],
            [
              -83.322471,
              31.118256
            ],
            [
              -83.304813,
              31.147946
            ],
            [
              -83.305611,
              31.167233
            ],
            [
              -83.334279,
              31.191578
            ],
            [
              -83.332181,
              31.215926
            ],
            [
              -83.335949,
              31.244579
            ],
            [
              -83.347036,
              31.25212
            ],
            [
              -83.366156,
              31.249056
            ],
            [
              -83.385684,
              31.258461
            ],
            [
              -83.409405,
              31.286799
            ],
            [
              -83.428794,
              31.293282
            ],
            [
              -83.438823,
              31.316391
            ],
            [
              -83.432505,
              31.332802
            ],
            [
              -83.434492,
              31.350361
            ],
            [
              -83.460644,
              31.326263
            ],
            [
              -83.512607,
              31.327405
            ],
            [
              -83.515297,
              31.302349
            ],
            [
              -83.506371,
              31.27977
            ],
            [
              -83.513519,
              31.228701
            ],
            [
              -83.509961,
              31.205972
            ],
            [
              -83.540698,
              31.17898
            ],
            [
              -83.543492,
              31.147986
            ],
            [
              -83.555463,
              31.144779
            ],
            [
              -83.557331,
              31.110501
            ],
            [
              -83.567499,
              31.100747
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "209",
      "COUNTYNS": "00352276",
      "AFFGEOID": "0500000US13209",
      "GEOID": "13209",
      "NAME": "Montgomery",
      "LSAD": "06",
      "ALAND": 620371304,
      "AWATER": 13383238,
      "County_state": "Montgomery,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.649298,
              32.311132
            ],
            [
              -82.655486,
              32.297561
            ],
            [
              -82.645232,
              32.295799
            ],
            [
              -82.651534,
              32.286704
            ],
            [
              -82.636634,
              32.269142
            ],
            [
              -82.648567,
              32.244476
            ],
            [
              -82.63459,
              32.233764
            ],
            [
              -82.635726,
              32.220516
            ],
            [
              -82.625327,
              32.206016
            ],
            [
              -82.632324,
              32.195663
            ],
            [
              -82.626563,
              32.169963
            ],
            [
              -82.612876,
              32.161549
            ],
            [
              -82.613593,
              32.149951
            ],
            [
              -82.62619,
              32.132736
            ],
            [
              -82.623634,
              32.113731
            ],
            [
              -82.611411,
              32.089111
            ],
            [
              -82.622314,
              32.081417
            ],
            [
              -82.608657,
              32.073261
            ],
            [
              -82.613815,
              32.060619
            ],
            [
              -82.602096,
              32.046512
            ],
            [
              -82.607505,
              32.032016
            ],
            [
              -82.598038,
              32.030386
            ],
            [
              -82.597671,
              32.013979
            ],
            [
              -82.547561,
              31.981588
            ],
            [
              -82.539184,
              31.966587
            ],
            [
              -82.543655,
              31.958914
            ],
            [
              -82.533097,
              31.961351
            ],
            [
              -82.517077,
              31.957914
            ],
            [
              -82.491485,
              31.948678
            ],
            [
              -82.480224,
              31.954961
            ],
            [
              -82.499496,
              31.960567
            ],
            [
              -82.483131,
              31.968961
            ],
            [
              -82.441124,
              32.217998
            ],
            [
              -82.438987,
              32.29318
            ],
            [
              -82.40913,
              32.353738
            ],
            [
              -82.649298,
              32.311132
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "027",
      "COUNTYNS": "00345518",
      "AFFGEOID": "0500000US13027",
      "GEOID": "13027",
      "NAME": "Brooks",
      "LSAD": "06",
      "ALAND": 1277105513,
      "AWATER": 12191505,
      "County_state": "Brooks,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.739021,
              30.742003
            ],
            [
              -83.7437296744174,
              30.658383065883196
            ],
            [
              -83.676854,
              30.654896
            ],
            [
              -83.674139,
              30.654738
            ],
            [
              -83.611746,
              30.651246
            ],
            [
              -83.6117204626719,
              30.651244724675497
            ],
            [
              -83.499951,
              30.645663
            ],
            [
              -83.448968,
              30.642402
            ],
            [
              -83.440094,
              30.642015
            ],
            [
              -83.429656,
              30.641488
            ],
            [
              -83.429549,
              30.641511
            ],
            [
              -83.390133,
              30.639325
            ],
            [
              -83.37953,
              30.638672
            ],
            [
              -83.3577037830355,
              30.6373471003798
            ],
            [
              -83.362738,
              30.658595
            ],
            [
              -83.394365,
              30.674955
            ],
            [
              -83.412491,
              30.673905
            ],
            [
              -83.417789,
              30.685815
            ],
            [
              -83.43968,
              30.688565
            ],
            [
              -83.468872,
              30.718403
            ],
            [
              -83.467764,
              30.731437
            ],
            [
              -83.484766,
              30.752372
            ],
            [
              -83.456764,
              30.76578
            ],
            [
              -83.459261,
              30.778605
            ],
            [
              -83.446399,
              30.804856
            ],
            [
              -83.449293,
              30.817957
            ],
            [
              -83.425489,
              30.81553
            ],
            [
              -83.413598,
              30.828882
            ],
            [
              -83.395736,
              30.825445
            ],
            [
              -83.364637,
              30.837407
            ],
            [
              -83.350948,
              30.841798
            ],
            [
              -83.346685,
              30.85285
            ],
            [
              -83.355857,
              30.872178
            ],
            [
              -83.369182,
              30.883956
            ],
            [
              -83.399454,
              30.883904
            ],
            [
              -83.417612,
              30.889391
            ],
            [
              -83.43998,
              30.904513
            ],
            [
              -83.437455,
              30.93865
            ],
            [
              -83.429265,
              30.948733
            ],
            [
              -83.443077,
              30.984995
            ],
            [
              -83.463689,
              31.005935
            ],
            [
              -83.468295,
              31.028481
            ],
            [
              -83.475617,
              31.031964
            ],
            [
              -83.508196,
              31.04829
            ],
            [
              -83.551628,
              31.054329
            ],
            [
              -83.574857,
              31.078135
            ],
            [
              -83.573959,
              31.033875
            ],
            [
              -83.736158,
              31.037679
            ],
            [
              -83.739021,
              30.742003
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "051",
      "COUNTYNS": "01694477",
      "AFFGEOID": "0500000US13051",
      "GEOID": "13051",
      "NAME": "Chatham",
      "LSAD": "06",
      "ALAND": 1112416679,
      "AWATER": 525134462,
      "County_state": "Chatham,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.391698,
              32.095886
            ],
            [
              -81.387143,
              32.090229
            ],
            [
              -81.384727,
              32.079517
            ],
            [
              -81.367004,
              32.047877
            ],
            [
              -81.334635,
              32.027704
            ],
            [
              -81.304269,
              31.999174
            ],
            [
              -81.309399,
              31.984721
            ],
            [
              -81.290323,
              31.978821
            ],
            [
              -81.285467,
              31.942994
            ],
            [
              -81.272349,
              31.93876
            ],
            [
              -81.273034,
              31.925005
            ],
            [
              -81.263132,
              31.927109
            ],
            [
              -81.26568,
              31.910586
            ],
            [
              -81.256391,
              31.905369
            ],
            [
              -81.240961,
              31.911976
            ],
            [
              -81.233014,
              31.906656
            ],
            [
              -81.242853,
              31.891757
            ],
            [
              -81.238735,
              31.887088
            ],
            [
              -81.203586,
              31.922256
            ],
            [
              -81.191302,
              31.919433
            ],
            [
              -81.193681,
              31.908913
            ],
            [
              -81.217201,
              31.897736
            ],
            [
              -81.208655,
              31.888512
            ],
            [
              -81.180429,
              31.90071
            ],
            [
              -81.161187,
              31.887571
            ],
            [
              -81.15263,
              31.862215
            ],
            [
              -81.138533,
              31.855921
            ],
            [
              -81.157827,
              31.834517
            ],
            [
              -81.159726,
              31.823615
            ],
            [
              -81.177027,
              31.816113
            ],
            [
              -81.17973,
              31.791317
            ],
            [
              -81.163326,
              31.787722
            ],
            [
              -81.161917,
              31.779217
            ],
            [
              -81.173124,
              31.763929
            ],
            [
              -81.159528,
              31.746031
            ],
            [
              -81.154686330339,
              31.726202578964298
            ],
            [
              -81.138448,
              31.720934
            ],
            [
              -81.130634,
              31.722692
            ],
            [
              -81.097402,
              31.753126
            ],
            [
              -81.077057,
              31.761256
            ],
            [
              -81.068116,
              31.768735
            ],
            [
              -81.047345,
              31.802865
            ],
            [
              -81.036873,
              31.812721
            ],
            [
              -81.036958,
              31.819558
            ],
            [
              -81.039808,
              31.823
            ],
            [
              -81.04794,
              31.824881
            ],
            [
              -81.050946,
              31.822383
            ],
            [
              -81.057181,
              31.822687
            ],
            [
              -81.075812,
              31.829031
            ],
            [
              -81.076178,
              31.836132
            ],
            [
              -81.06279,
              31.84474
            ],
            [
              -81.05907,
              31.850106
            ],
            [
              -81.058596,
              31.857811
            ],
            [
              -81.065255,
              31.877095
            ],
            [
              -81.041548,
              31.876198
            ],
            [
              -81.014478,
              31.867474
            ],
            [
              -81.000317,
              31.856744
            ],
            [
              -80.99269,
              31.857641
            ],
            [
              -80.971434,
              31.877941
            ],
            [
              -80.947294,
              31.89621
            ],
            [
              -80.934508,
              31.90918
            ],
            [
              -80.941359,
              31.912984
            ],
            [
              -80.954469,
              31.911768
            ],
            [
              -80.968494,
              31.915822
            ],
            [
              -80.975714,
              31.923602
            ],
            [
              -80.972392,
              31.94127
            ],
            [
              -80.948491,
              31.95723
            ],
            [
              -80.930279,
              31.956705
            ],
            [
              -80.929101,
              31.944964
            ],
            [
              -80.911207,
              31.943769
            ],
            [
              -80.897687,
              31.949065
            ],
            [
              -80.882814,
              31.959075
            ],
            [
              -80.862814,
              31.969346
            ],
            [
              -80.848441,
              31.988279
            ],
            [
              -80.841913,
              32.002643
            ],
            [
              -80.840549,
              32.011306
            ],
            [
              -80.84313,
              32.024226
            ],
            [
              -80.852276,
              32.026676
            ],
            [
              -80.859111,
              32.023693
            ],
            [
              -80.885517,
              32.0346
            ],
            [
              -80.892977,
              32.034949
            ],
            [
              -80.910669,
              32.036735
            ],
            [
              -80.917845,
              32.037575
            ],
            [
              -80.922794,
              32.039151
            ],
            [
              -80.926753,
              32.041672
            ],
            [
              -80.933557,
              32.047554
            ],
            [
              -80.943226,
              32.057824
            ],
            [
              -80.954482,
              32.068622
            ],
            [
              -80.959402,
              32.071259
            ],
            [
              -80.978833,
              32.077309
            ],
            [
              -80.983133,
              32.079609
            ],
            [
              -80.987733,
              32.084209
            ],
            [
              -80.991733,
              32.091208
            ],
            [
              -80.994333,
              32.094608
            ],
            [
              -80.999833,
              32.099014
            ],
            [
              -81.002297,
              32.100048
            ],
            [
              -81.006745,
              32.101152
            ],
            [
              -81.011961,
              32.100176
            ],
            [
              -81.016009,
              32.097424
            ],
            [
              -81.02010401268758,
              32.0926621707087
            ],
            [
              -81.021622,
              32.090897
            ],
            [
              -81.032674,
              32.08545
            ],
            [
              -81.038265,
              32.084469
            ],
            [
              -81.050234,
              32.085308
            ],
            [
              -81.060442,
              32.087503
            ],
            [
              -81.066906,
              32.090351
            ],
            [
              -81.083546,
              32.100782
            ],
            [
              -81.0873316730266,
              32.10334023637979
            ],
            [
              -81.088234,
              32.10395
            ],
            [
              -81.090874,
              32.10699
            ],
            [
              -81.091498,
              32.110782
            ],
            [
              -81.093386,
              32.11123
            ],
            [
              -81.100458,
              32.111181
            ],
            [
              -81.111134,
              32.112005
            ],
            [
              -81.113334,
              32.113205
            ],
            [
              -81.117234,
              32.117605
            ],
            [
              -81.119994,
              32.134268
            ],
            [
              -81.119134,
              32.142104
            ],
            [
              -81.118334,
              32.144403
            ],
            [
              -81.120034,
              32.153303
            ],
            [
              -81.1203585245902,
              32.154682229508204
            ],
            [
              -81.122034,
              32.161803
            ],
            [
              -81.123134,
              32.162902
            ],
            [
              -81.128434,
              32.164402
            ],
            [
              -81.129634,
              32.165602
            ],
            [
              -81.129402,
              32.166922
            ],
            [
              -81.128134,
              32.169102
            ],
            [
              -81.124492,
              32.17212
            ],
            [
              -81.121134,
              32.174902
            ],
            [
              -81.119434,
              32.175402
            ],
            [
              -81.119361,
              32.177142
            ],
            [
              -81.120434,
              32.178702
            ],
            [
              -81.119834,
              32.181202
            ],
            [
              -81.117934,
              32.185301
            ],
            [
              -81.118234,
              32.189201
            ],
            [
              -81.12315,
              32.201329
            ],
            [
              -81.128283,
              32.208634
            ],
            [
              -81.136012,
              32.212858
            ],
            [
              -81.136727,
              32.213669
            ],
            [
              -81.143139,
              32.221731
            ],
            [
              -81.146530388013,
              32.226938177361
            ],
            [
              -81.195449,
              32.237591
            ],
            [
              -81.295504,
              32.165211
            ],
            [
              -81.391698,
              32.095886
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "061",
      "COUNTYNS": "00344805",
      "AFFGEOID": "0500000US13061",
      "GEOID": "13061",
      "NAME": "Clay",
      "LSAD": "06",
      "ALAND": 506035793,
      "AWATER": 56605066,
      "County_state": "Clay,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.1274996270141,
              31.7802526552609
            ],
            [
              -85.12633,
              31.768863
            ],
            [
              -85.12513385383839,
              31.763011743932
            ],
            [
              -85.11893,
              31.732664
            ],
            [
              -85.12553,
              31.694965
            ],
            [
              -85.08096,
              31.653102
            ],
            [
              -85.058169,
              31.620227
            ],
            [
              -85.05796,
              31.57084
            ],
            [
              -85.041881,
              31.544684
            ],
            [
              -85.044986,
              31.51823
            ],
            [
              -85.04555669828159,
              31.517161968216698
            ],
            [
              -85.029468,
              31.518562
            ],
            [
              -85.029227,
              31.486071
            ],
            [
              -84.98634,
              31.488856
            ],
            [
              -84.971053,
              31.497312
            ],
            [
              -84.959735,
              31.492663
            ],
            [
              -84.937801,
              31.503266
            ],
            [
              -84.819807,
              31.501192
            ],
            [
              -84.80796,
              31.514383
            ],
            [
              -84.818935,
              31.5479
            ],
            [
              -84.799134,
              31.565253
            ],
            [
              -84.818403,
              31.56573
            ],
            [
              -84.817843,
              31.619771
            ],
            [
              -84.942353,
              31.618669
            ],
            [
              -84.942511,
              31.655552
            ],
            [
              -84.931349,
              31.655425
            ],
            [
              -84.930998,
              31.672309
            ],
            [
              -84.949784,
              31.679914
            ],
            [
              -84.949381,
              31.713401
            ],
            [
              -84.959324,
              31.713378
            ],
            [
              -84.95864,
              31.777854
            ],
            [
              -85.026527,
              31.778862
            ],
            [
              -85.026627,
              31.770963
            ],
            [
              -85.073941,
              31.771441
            ],
            [
              -85.073829,
              31.779063
            ],
            [
              -85.1274996270141,
              31.7802526552609
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "215",
      "COUNTYNS": "00357428",
      "AFFGEOID": "0500000US13215",
      "GEOID": "13215",
      "NAME": "Muscogee",
      "LSAD": "06",
      "ALAND": 560544127,
      "AWATER": 11859741,
      "County_state": "Muscogee,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.076655,
              32.608072
            ],
            [
              -85.08128389549479,
              32.607840500985695
            ],
            [
              -85.069848,
              32.583146
            ],
            [
              -85.03429201871789,
              32.5529347949659
            ],
            [
              -85.022509,
              32.542923
            ],
            [
              -85.0071,
              32.523868
            ],
            [
              -85.00374006827789,
              32.510102725763296
            ],
            [
              -85.00283808653009,
              32.5064074048035
            ],
            [
              -85.0026861200596,
              32.505784814729004
            ],
            [
              -84.9957506231826,
              32.4773708395717
            ],
            [
              -84.995231,
              32.475242
            ],
            [
              -84.995331,
              32.453243
            ],
            [
              -84.971831,
              32.442843
            ],
            [
              -84.96343,
              32.422544
            ],
            [
              -84.983386,
              32.391761
            ],
            [
              -84.9834252816289,
              32.3777300931769
            ],
            [
              -84.963194,
              32.374066
            ],
            [
              -84.94513,
              32.376018
            ],
            [
              -84.893009,
              32.400813
            ],
            [
              -84.882201,
              32.410463
            ],
            [
              -84.848664,
              32.41464
            ],
            [
              -84.844664,
              32.41328
            ],
            [
              -84.837784,
              32.41328
            ],
            [
              -84.835176,
              32.411631
            ],
            [
              -84.834312,
              32.413744
            ],
            [
              -84.826328,
              32.412784
            ],
            [
              -84.822744,
              32.414032
            ],
            [
              -84.818952,
              32.412816
            ],
            [
              -84.806023,
              32.4196
            ],
            [
              -84.792951,
              32.41384
            ],
            [
              -84.778647,
              32.424975
            ],
            [
              -84.768407,
              32.420591
            ],
            [
              -84.724265,
              32.507756
            ],
            [
              -84.69451,
              32.518732
            ],
            [
              -84.679967,
              32.531533
            ],
            [
              -84.679453,
              32.535775
            ],
            [
              -84.667463,
              32.563948
            ],
            [
              -84.694663,
              32.563698
            ],
            [
              -84.694603,
              32.583945
            ],
            [
              -84.740436,
              32.583363
            ],
            [
              -84.868042,
              32.58328
            ],
            [
              -84.900715,
              32.583402
            ],
            [
              -84.907883,
              32.583433
            ],
            [
              -84.907693,
              32.607608
            ],
            [
              -84.912792,
              32.607629
            ],
            [
              -84.936517,
              32.607691
            ],
            [
              -84.98179,
              32.607671
            ],
            [
              -85.076655,
              32.608072
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "321",
      "COUNTYNS": "00343365",
      "AFFGEOID": "0500000US13321",
      "GEOID": "13321",
      "NAME": "Worth",
      "LSAD": "06",
      "ALAND": 1478123917,
      "AWATER": 10485470,
      "County_state": "Worth,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.032344,
              31.716772
            ],
            [
              -84.019608,
              31.692482
            ],
            [
              -84.02206,
              31.685636
            ],
            [
              -84.012401,
              31.663039
            ],
            [
              -84.018404,
              31.650274
            ],
            [
              -83.993374,
              31.650034
            ],
            [
              -84.012509,
              31.633619
            ],
            [
              -83.996246,
              31.625169
            ],
            [
              -83.997087,
              31.507574
            ],
            [
              -83.997468,
              31.47601
            ],
            [
              -83.982582,
              31.462678
            ],
            [
              -83.997679,
              31.462773
            ],
            [
              -83.997796,
              31.443753
            ],
            [
              -83.99943,
              31.334966
            ],
            [
              -83.775765,
              31.332319
            ],
            [
              -83.759086,
              31.332225
            ],
            [
              -83.758934,
              31.318954
            ],
            [
              -83.714134,
              31.318365
            ],
            [
              -83.713867,
              31.331652
            ],
            [
              -83.65409,
              31.330661
            ],
            [
              -83.651785,
              31.433069
            ],
            [
              -83.66666,
              31.43417
            ],
            [
              -83.665777,
              31.485135
            ],
            [
              -83.650627,
              31.484737
            ],
            [
              -83.649384,
              31.567977
            ],
            [
              -83.671407,
              31.571834
            ],
            [
              -83.66769,
              31.585871
            ],
            [
              -83.677667,
              31.600874
            ],
            [
              -83.684373,
              31.596842
            ],
            [
              -83.701505,
              31.606152
            ],
            [
              -83.706677,
              31.619203
            ],
            [
              -83.718774,
              31.622028
            ],
            [
              -83.796846,
              31.622929
            ],
            [
              -83.798679,
              31.801009
            ],
            [
              -83.802723,
              31.803579
            ],
            [
              -83.8262,
              31.809773
            ],
            [
              -83.845481,
              31.831555
            ],
            [
              -83.924263,
              31.848393
            ],
            [
              -83.939437,
              31.847929
            ],
            [
              -83.967344,
              31.834477
            ],
            [
              -83.977008,
              31.819065
            ],
            [
              -83.976884,
              31.803329
            ],
            [
              -83.990498,
              31.806369
            ],
            [
              -84.008445,
              31.801645
            ],
            [
              -84.008037,
              31.792313
            ],
            [
              -83.991554,
              31.783756
            ],
            [
              -83.98639,
              31.765426
            ],
            [
              -84.003496,
              31.754596
            ],
            [
              -84.00762,
              31.735516
            ],
            [
              -84.032344,
              31.716772
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "025",
      "COUNTYNS": "00351605",
      "AFFGEOID": "0500000US13025",
      "GEOID": "13025",
      "NAME": "Brantley",
      "LSAD": "06",
      "ALAND": 1145708458,
      "AWATER": 12540408,
      "County_state": "Brantley,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.284561,
              31.224449
            ],
            [
              -82.284256,
              31.192196
            ],
            [
              -82.251762,
              31.18273
            ],
            [
              -82.2341,
              31.170383
            ],
            [
              -82.208732,
              31.170938
            ],
            [
              -82.208262,
              31.084768
            ],
            [
              -82.163566,
              31.048559
            ],
            [
              -82.131698,
              31.010714
            ],
            [
              -82.087319,
              31.010443
            ],
            [
              -82.081055,
              31.011071
            ],
            [
              -82.06087,
              31.075809
            ],
            [
              -81.936006,
              31.047993
            ],
            [
              -81.936749,
              31.060787
            ],
            [
              -81.925979,
              31.061596
            ],
            [
              -81.927353,
              31.078867
            ],
            [
              -81.915883,
              31.095281
            ],
            [
              -81.889574,
              31.100809
            ],
            [
              -81.840671,
              31.097665
            ],
            [
              -81.819066,
              31.136504
            ],
            [
              -81.801463,
              31.151188
            ],
            [
              -81.788218,
              31.152166
            ],
            [
              -81.766322,
              31.169595
            ],
            [
              -81.780967,
              31.165272
            ],
            [
              -81.766872,
              31.201481
            ],
            [
              -81.757915,
              31.241925
            ],
            [
              -81.747198,
              31.281461
            ],
            [
              -81.732667,
              31.301345
            ],
            [
              -81.731694,
              31.330048
            ],
            [
              -81.764434,
              31.348085
            ],
            [
              -81.801052,
              31.363737
            ],
            [
              -81.845192,
              31.354745
            ],
            [
              -81.923238,
              31.345875
            ],
            [
              -82.0311,
              31.366239
            ],
            [
              -82.041129,
              31.373721
            ],
            [
              -82.04084,
              31.362846
            ],
            [
              -82.020202,
              31.327444
            ],
            [
              -82.004875,
              31.310993
            ],
            [
              -81.992114,
              31.307996
            ],
            [
              -81.997421,
              31.288534
            ],
            [
              -82.006775,
              31.277063
            ],
            [
              -82.022178,
              31.278845
            ],
            [
              -82.051933,
              31.271158
            ],
            [
              -82.070099,
              31.276306
            ],
            [
              -82.082451,
              31.271429
            ],
            [
              -82.096695,
              31.27495
            ],
            [
              -82.114822,
              31.270355
            ],
            [
              -82.125854,
              31.258265
            ],
            [
              -82.145515,
              31.252375
            ],
            [
              -82.1464,
              31.226875
            ],
            [
              -82.170128,
              31.217705
            ],
            [
              -82.193474,
              31.202356
            ],
            [
              -82.194824,
              31.20759
            ],
            [
              -82.226288,
              31.22048
            ],
            [
              -82.268616,
              31.216951
            ],
            [
              -82.284561,
              31.224449
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "237",
      "COUNTYNS": "00347246",
      "AFFGEOID": "0500000US13237",
      "GEOID": "13237",
      "NAME": "Putnam",
      "LSAD": "06",
      "ALAND": 892652833,
      "AWATER": 41520809,
      "County_state": "Putnam,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.537811,
              33.393803
            ],
            [
              -83.545876,
              33.171944
            ],
            [
              -83.437578,
              33.185764
            ],
            [
              -83.42909,
              33.185352
            ],
            [
              -83.418549,
              33.177464
            ],
            [
              -83.391468,
              33.183514
            ],
            [
              -83.363543,
              33.168998
            ],
            [
              -83.36309,
              33.180171
            ],
            [
              -83.331232,
              33.167562
            ],
            [
              -83.318852,
              33.183116
            ],
            [
              -83.290576,
              33.190377
            ],
            [
              -83.274108,
              33.187238
            ],
            [
              -83.252396,
              33.238054
            ],
            [
              -83.254359,
              33.243538
            ],
            [
              -83.253461,
              33.25929
            ],
            [
              -83.228674,
              33.25679
            ],
            [
              -83.230031,
              33.276054
            ],
            [
              -83.178444,
              33.292046
            ],
            [
              -83.145856,
              33.310045
            ],
            [
              -83.144121,
              33.339866
            ],
            [
              -83.164207,
              33.35503
            ],
            [
              -83.169968,
              33.370674
            ],
            [
              -83.197899,
              33.395321
            ],
            [
              -83.224564,
              33.380423
            ],
            [
              -83.212662,
              33.400394
            ],
            [
              -83.227035,
              33.404488
            ],
            [
              -83.244186,
              33.399234
            ],
            [
              -83.239708,
              33.418373
            ],
            [
              -83.262894,
              33.443687
            ],
            [
              -83.257645,
              33.456668
            ],
            [
              -83.274864,
              33.464115
            ],
            [
              -83.279931,
              33.483438
            ],
            [
              -83.533736,
              33.434472
            ],
            [
              -83.537811,
              33.393803
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "257",
      "COUNTYNS": "00350028",
      "AFFGEOID": "0500000US13257",
      "GEOID": "13257",
      "NAME": "Stephens",
      "LSAD": "06",
      "ALAND": 463974775,
      "AWATER": 13095267,
      "County_state": "Stephens,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.45977,
              34.481142
            ],
            [
              -83.413586,
              34.468014
            ],
            [
              -83.398396,
              34.460883
            ],
            [
              -83.373054,
              34.470216
            ],
            [
              -83.177838,
              34.477017
            ],
            [
              -83.10356281094009,
              34.536630219427096
            ],
            [
              -83.103987,
              34.540166
            ],
            [
              -83.122901,
              34.560129
            ],
            [
              -83.127176,
              34.561999
            ],
            [
              -83.129676,
              34.561699
            ],
            [
              -83.139876,
              34.567999
            ],
            [
              -83.152577,
              34.578299
            ],
            [
              -83.154577,
              34.588198
            ],
            [
              -83.168278,
              34.590998
            ],
            [
              -83.170278,
              34.592398
            ],
            [
              -83.170978,
              34.598798
            ],
            [
              -83.169994,
              34.60201
            ],
            [
              -83.169572,
              34.603866
            ],
            [
              -83.169994,
              34.605444
            ],
            [
              -83.173428,
              34.607162
            ],
            [
              -83.179439,
              34.60802
            ],
            [
              -83.196979,
              34.605998
            ],
            [
              -83.199779,
              34.608398
            ],
            [
              -83.211598,
              34.610905
            ],
            [
              -83.221402,
              34.609947
            ],
            [
              -83.23178,
              34.611297
            ],
            [
              -83.243381,
              34.617997
            ],
            [
              -83.240676,
              34.624307
            ],
            [
              -83.240669,
              34.624507
            ],
            [
              -83.244581,
              34.626297
            ],
            [
              -83.248281,
              34.631696
            ],
            [
              -83.255281,
              34.637696
            ],
            [
              -83.262282,
              34.640296
            ],
            [
              -83.271982,
              34.641896
            ],
            [
              -83.27796,
              34.644853
            ],
            [
              -83.286583,
              34.650896
            ],
            [
              -83.292883,
              34.654196
            ],
            [
              -83.300848,
              34.66247
            ],
            [
              -83.301477,
              34.666582
            ],
            [
              -83.304641,
              34.669561
            ],
            [
              -83.308917,
              34.670273
            ],
            [
              -83.314394,
              34.668944
            ],
            [
              -83.316401,
              34.669316
            ],
            [
              -83.318524,
              34.674773
            ],
            [
              -83.31944,
              34.675974
            ],
            [
              -83.321463,
              34.677543
            ],
            [
              -83.325336,
              34.679517
            ],
            [
              -83.330284,
              34.681342
            ],
            [
              -83.336207,
              34.680534
            ],
            [
              -83.33869,
              34.682002
            ],
            [
              -83.339029,
              34.683807
            ],
            [
              -83.33933481518201,
              34.686666100517996
            ],
            [
              -83.365044,
              34.675308
            ],
            [
              -83.45977,
              34.481142
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "011",
      "COUNTYNS": "00349230",
      "AFFGEOID": "0500000US13011",
      "GEOID": "13011",
      "NAME": "Banks",
      "LSAD": "06",
      "ALAND": 601117836,
      "AWATER": 4651151,
      "County_state": "Banks,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.655563,
              34.399746
            ],
            [
              -83.66967,
              34.3661
            ],
            [
              -83.651441,
              34.343715
            ],
            [
              -83.649025,
              34.320984
            ],
            [
              -83.620115,
              34.295276
            ],
            [
              -83.561521,
              34.252712
            ],
            [
              -83.489696,
              34.257008
            ],
            [
              -83.480361,
              34.262038
            ],
            [
              -83.402428,
              34.197499
            ],
            [
              -83.372429,
              34.205933
            ],
            [
              -83.355269,
              34.22373
            ],
            [
              -83.337849,
              34.261033
            ],
            [
              -83.393915,
              34.324801
            ],
            [
              -83.382795,
              34.392728
            ],
            [
              -83.382565,
              34.428928
            ],
            [
              -83.398396,
              34.460883
            ],
            [
              -83.413586,
              34.468014
            ],
            [
              -83.45977,
              34.481142
            ],
            [
              -83.469925,
              34.484055
            ],
            [
              -83.518385,
              34.489406
            ],
            [
              -83.537436,
              34.491734
            ],
            [
              -83.55238,
              34.485841
            ],
            [
              -83.585787,
              34.452545
            ],
            [
              -83.607475,
              34.444279
            ],
            [
              -83.615251,
              34.431748
            ],
            [
              -83.655563,
              34.399746
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "145",
      "COUNTYNS": "00326700",
      "AFFGEOID": "0500000US13145",
      "GEOID": "13145",
      "NAME": "Harris",
      "LSAD": "06",
      "ALAND": 1201447999,
      "AWATER": 23502960,
      "County_state": "Harris,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.1857897629128,
              32.870267438636695
            ],
            [
              -85.1844151797869,
              32.861414761819795
            ],
            [
              -85.1844,
              32.861317
            ],
            [
              -85.153497,
              32.842781
            ],
            [
              -85.167939,
              32.811612
            ],
            [
              -85.133322,
              32.782078
            ],
            [
              -85.122189,
              32.773353
            ],
            [
              -85.138879,
              32.760062
            ],
            [
              -85.1300975128125,
              32.7517487666425
            ],
            [
              -85.11855,
              32.740817
            ],
            [
              -85.11425,
              32.730447
            ],
            [
              -85.122738,
              32.715727
            ],
            [
              -85.114737,
              32.685634
            ],
            [
              -85.093536,
              32.669734
            ],
            [
              -85.088533,
              32.657958
            ],
            [
              -85.105337,
              32.644835
            ],
            [
              -85.088319,
              32.623032
            ],
            [
              -85.08128389549479,
              32.607840500985695
            ],
            [
              -85.076655,
              32.608072
            ],
            [
              -84.98179,
              32.607671
            ],
            [
              -84.936517,
              32.607691
            ],
            [
              -84.912792,
              32.607629
            ],
            [
              -84.907693,
              32.607608
            ],
            [
              -84.907883,
              32.583433
            ],
            [
              -84.900715,
              32.583402
            ],
            [
              -84.868042,
              32.58328
            ],
            [
              -84.740436,
              32.583363
            ],
            [
              -84.694603,
              32.583945
            ],
            [
              -84.693899,
              32.686045
            ],
            [
              -84.703196,
              32.700718
            ],
            [
              -84.693421,
              32.708105
            ],
            [
              -84.693248,
              32.722637
            ],
            [
              -84.682876,
              32.73035
            ],
            [
              -84.688048,
              32.730365
            ],
            [
              -84.693057,
              32.738812
            ],
            [
              -84.692428,
              32.829393
            ],
            [
              -84.700538,
              32.84464
            ],
            [
              -84.736906,
              32.857872
            ],
            [
              -84.751338,
              32.8692
            ],
            [
              -84.84759,
              32.86837
            ],
            [
              -84.861768,
              32.872495
            ],
            [
              -84.861855,
              32.868617
            ],
            [
              -85.099469,
              32.870154
            ],
            [
              -85.108675,
              32.866656
            ],
            [
              -85.109493,
              32.870243
            ],
            [
              -85.1857897629128,
              32.870267438636695
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "273",
      "COUNTYNS": "00352238",
      "AFFGEOID": "0500000US13273",
      "GEOID": "13273",
      "NAME": "Terrell",
      "LSAD": "06",
      "ALAND": 868785319,
      "AWATER": 5861795,
      "County_state": "Terrell,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.599776,
              31.920171
            ],
            [
              -84.603086,
              31.772001
            ],
            [
              -84.563453,
              31.770412
            ],
            [
              -84.570329,
              31.760662
            ],
            [
              -84.560918,
              31.732755
            ],
            [
              -84.546877,
              31.712421
            ],
            [
              -84.535434,
              31.676494
            ],
            [
              -84.536152,
              31.646923
            ],
            [
              -84.546854,
              31.621199
            ],
            [
              -84.455223,
              31.621238
            ],
            [
              -84.450398,
              31.621868
            ],
            [
              -84.297801,
              31.621951
            ],
            [
              -84.295641,
              31.690986
            ],
            [
              -84.304927,
              31.698231
            ],
            [
              -84.295416,
              31.698098
            ],
            [
              -84.293668,
              31.749794
            ],
            [
              -84.286456,
              31.791182
            ],
            [
              -84.260489,
              31.790898
            ],
            [
              -84.26449,
              31.812401
            ],
            [
              -84.259611,
              31.827865
            ],
            [
              -84.277522,
              31.841744
            ],
            [
              -84.276689,
              31.850406
            ],
            [
              -84.293133,
              31.864881
            ],
            [
              -84.338245,
              31.873591
            ],
            [
              -84.357615,
              31.885156
            ],
            [
              -84.383367,
              31.916938
            ],
            [
              -84.440358,
              31.959617
            ],
            [
              -84.443807,
              31.967456
            ],
            [
              -84.445248,
              31.926133
            ],
            [
              -84.45331,
              31.919224
            ],
            [
              -84.599776,
              31.920171
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "221",
      "COUNTYNS": "00351262",
      "AFFGEOID": "0500000US13221",
      "GEOID": "13221",
      "NAME": "Oglethorpe",
      "LSAD": "06",
      "ALAND": 1137034765,
      "AWATER": 7838679,
      "County_state": "Oglethorpe,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.306619,
              33.811444
            ],
            [
              -83.280914,
              33.783424
            ],
            [
              -83.28034,
              33.761774
            ],
            [
              -83.118729,
              33.698818
            ],
            [
              -83.049141,
              33.715561
            ],
            [
              -83.015274,
              33.69112
            ],
            [
              -82.995602,
              33.693583
            ],
            [
              -82.993133,
              33.701523
            ],
            [
              -82.949046,
              33.733333
            ],
            [
              -82.959871,
              33.751621
            ],
            [
              -82.987286,
              33.78071
            ],
            [
              -82.986325,
              33.785405
            ],
            [
              -82.9763,
              33.80235
            ],
            [
              -82.9616,
              33.811007
            ],
            [
              -82.942554,
              33.848781
            ],
            [
              -82.922082,
              33.85676
            ],
            [
              -82.912276,
              33.877246
            ],
            [
              -82.89008,
              33.896489
            ],
            [
              -82.874587,
              33.921767
            ],
            [
              -82.855752,
              33.927443
            ],
            [
              -82.846686,
              33.940639
            ],
            [
              -82.825307,
              33.94092
            ],
            [
              -82.79345,
              33.94984
            ],
            [
              -82.779506,
              33.971124
            ],
            [
              -82.824594,
              33.984381
            ],
            [
              -82.837067,
              33.992604
            ],
            [
              -82.842224,
              33.984058
            ],
            [
              -82.864334,
              33.983658
            ],
            [
              -82.881103,
              34.000141
            ],
            [
              -82.942268,
              34.014957
            ],
            [
              -82.943497,
              34.027843
            ],
            [
              -82.976294,
              34.043219
            ],
            [
              -83.001602,
              34.030899
            ],
            [
              -83.0289,
              34.030975
            ],
            [
              -83.048889,
              34.015804
            ],
            [
              -83.090584,
              34.032616
            ],
            [
              -83.100967,
              34.024792
            ],
            [
              -83.123878,
              34.047558
            ],
            [
              -83.164299,
              34.034519
            ],
            [
              -83.193153,
              34.010468
            ],
            [
              -83.220718,
              34.000501
            ],
            [
              -83.237006,
              34.013557
            ],
            [
              -83.258413,
              33.999098
            ],
            [
              -83.268992,
              34.000604
            ],
            [
              -83.266225,
              33.970321
            ],
            [
              -83.278645,
              33.951745
            ],
            [
              -83.275415,
              33.942205
            ],
            [
              -83.24086,
              33.90443
            ],
            [
              -83.243254,
              33.900532
            ],
            [
              -83.275933,
              33.847977
            ],
            [
              -83.306619,
              33.811444
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "315",
      "COUNTYNS": "00345499",
      "AFFGEOID": "0500000US13315",
      "GEOID": "13315",
      "NAME": "Wilcox",
      "LSAD": "06",
      "ALAND": 978282707,
      "AWATER": 11390302,
      "County_state": "Wilcox,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.609663,
              32.027938
            ],
            [
              -83.612256,
              31.854089
            ],
            [
              -83.54164,
              31.853102
            ],
            [
              -83.547524,
              31.847961
            ],
            [
              -83.480176,
              31.847312
            ],
            [
              -83.443957,
              31.847008
            ],
            [
              -83.209638,
              31.844646
            ],
            [
              -83.183027,
              31.851974
            ],
            [
              -83.177469,
              31.847857
            ],
            [
              -83.181646,
              31.879715
            ],
            [
              -83.204236,
              31.884682
            ],
            [
              -83.205739,
              31.900325
            ],
            [
              -83.226437,
              31.935931
            ],
            [
              -83.261534,
              31.952076
            ],
            [
              -83.292061,
              31.984506
            ],
            [
              -83.279208,
              31.996728
            ],
            [
              -83.296923,
              32.011245
            ],
            [
              -83.290102,
              32.027444
            ],
            [
              -83.307722,
              32.050919
            ],
            [
              -83.300626,
              32.055052
            ],
            [
              -83.295206,
              32.07918
            ],
            [
              -83.312988,
              32.092481
            ],
            [
              -83.317994,
              32.085903
            ],
            [
              -83.337313,
              32.105885
            ],
            [
              -83.3589,
              32.105765
            ],
            [
              -83.350525,
              32.112205
            ],
            [
              -83.361051,
              32.124009
            ],
            [
              -83.397148,
              32.124099
            ],
            [
              -83.396981,
              32.132578
            ],
            [
              -83.41644,
              32.133076
            ],
            [
              -83.416543,
              32.12517
            ],
            [
              -83.435593,
              32.125419
            ],
            [
              -83.435657,
              32.117244
            ],
            [
              -83.608547,
              32.118405
            ],
            [
              -83.609663,
              32.027938
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "13",
      "COUNTYFP": "283",
      "COUNTYNS": "00347505",
      "AFFGEOID": "0500000US13283",
      "GEOID": "13283",
      "NAME": "Treutlen",
      "LSAD": "06",
      "ALAND": 516529395,
      "AWATER": 7805621,
      "County_state": "Treutlen,13"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.730582,
              32.361755
            ],
            [
              -82.748732,
              32.32915
            ],
            [
              -82.721964,
              32.309283
            ],
            [
              -82.694063,
              32.294646
            ],
            [
              -82.67377,
              32.293595
            ],
            [
              -82.655486,
              32.297561
            ],
            [
              -82.649298,
              32.311132
            ],
            [
              -82.40913,
              32.353738
            ],
            [
              -82.409118,
              32.35383
            ],
            [
              -82.411201,
              32.365251
            ],
            [
              -82.396564,
              32.386961
            ],
            [
              -82.394316,
              32.411516
            ],
            [
              -82.38194,
              32.440596
            ],
            [
              -82.398031,
              32.451878
            ],
            [
              -82.42515,
              32.462283
            ],
            [
              -82.48929,
              32.492061
            ],
            [
              -82.490524,
              32.496273
            ],
            [
              -82.517486,
              32.499592
            ],
            [
              -82.534524,
              32.497158
            ],
            [
              -82.563046,
              32.505708
            ],
            [
              -82.618649,
              32.504966
            ],
            [
              -82.647733,
              32.512507
            ],
            [
              -82.657057,
              32.507167
            ],
            [
              -82.635546,
              32.496059
            ],
            [
              -82.646923,
              32.468915
            ],
            [
              -82.663181,
              32.460061
            ],
            [
              -82.685625,
              32.460501
            ],
            [
              -82.690805,
              32.443163
            ],
            [
              -82.702163,
              32.431617
            ],
            [
              -82.697166,
              32.417484
            ],
            [
              -82.702888,
              32.397872
            ],
            [
              -82.712419,
              32.391342
            ],
            [
              -82.730582,
              32.361755
            ]
          ]
        ]
      ]
    }
  }
]
};