export const counties_nj = {
  "type":"FeatureCollection","name":"nj_counties","features":[
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "34",
      "COUNTYFP": "011",
      "COUNTYNS": "00882275",
      "AFFGEOID": "0500000US34011",
      "GEOID": "34011",
      "NAME": "Cumberland",
      "LSAD": "06",
      "ALAND": 1251898435,
      "AWATER": 503712955,
      "County_state": "Cumberland,34"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -75.408753,
              39.423785
            ],
            [
              -75.418911,
              39.410417
            ],
            [
              -75.40729390306001,
              39.3819544443261
            ],
            [
              -75.399304,
              39.37949
            ],
            [
              -75.395181,
              39.371398
            ],
            [
              -75.394331,
              39.363753
            ],
            [
              -75.39003,
              39.358259
            ],
            [
              -75.365016,
              39.341388
            ],
            [
              -75.355558,
              39.347823
            ],
            [
              -75.341969,
              39.348697
            ],
            [
              -75.333743,
              39.345335
            ],
            [
              -75.327463,
              39.33927
            ],
            [
              -75.326754,
              39.332473
            ],
            [
              -75.315201,
              39.310593
            ],
            [
              -75.30601,
              39.301712
            ],
            [
              -75.288898,
              39.289557
            ],
            [
              -75.285333,
              39.292212
            ],
            [
              -75.28262,
              39.299055
            ],
            [
              -75.271629,
              39.304041
            ],
            [
              -75.251806,
              39.299913
            ],
            [
              -75.244357,
              39.2857
            ],
            [
              -75.242881,
              39.280574
            ],
            [
              -75.244056,
              39.27769
            ],
            [
              -75.241639,
              39.274097
            ],
            [
              -75.21251,
              39.262755
            ],
            [
              -75.205857,
              39.262619
            ],
            [
              -75.191863,
              39.2457
            ],
            [
              -75.177506,
              39.242746
            ],
            [
              -75.166667,
              39.222577
            ],
            [
              -75.163974,
              39.21132
            ],
            [
              -75.168351,
              39.202555
            ],
            [
              -75.151405,
              39.189996
            ],
            [
              -75.136667,
              39.181883
            ],
            [
              -75.12707,
              39.189766
            ],
            [
              -75.12407,
              39.199567
            ],
            [
              -75.110997,
              39.212459
            ],
            [
              -75.090794,
              39.210802
            ],
            [
              -75.048487,
              39.215222
            ],
            [
              -75.037554,
              39.221667
            ],
            [
              -75.026383,
              39.213196
            ],
            [
              -75.023437,
              39.204791
            ],
            [
              -75.026179,
              39.193621
            ],
            [
              -74.998002,
              39.191253
            ],
            [
              -74.976266,
              39.192271
            ],
            [
              -74.962382,
              39.190238
            ],
            [
              -74.9149362674663,
              39.1775531240776
            ],
            [
              -74.926347,
              39.209012
            ],
            [
              -74.921518,
              39.224999
            ],
            [
              -74.896384,
              39.235054
            ],
            [
              -74.882146,
              39.30839
            ],
            [
              -74.861294,
              39.323815
            ],
            [
              -74.864911,
              39.338879
            ],
            [
              -74.851989,
              39.383745
            ],
            [
              -74.853114,
              39.415455
            ],
            [
              -74.856747,
              39.420757
            ],
            [
              -74.855979,
              39.424041
            ],
            [
              -74.985271,
              39.514967
            ],
            [
              -75.061854,
              39.568746
            ],
            [
              -75.074457,
              39.544564
            ],
            [
              -75.079699,
              39.501434
            ],
            [
              -75.073183,
              39.485375
            ],
            [
              -75.080309,
              39.460648
            ],
            [
              -75.083823,
              39.457354
            ],
            [
              -75.177916,
              39.521061
            ],
            [
              -75.238434,
              39.559039
            ],
            [
              -75.270059,
              39.531236
            ],
            [
              -75.31802,
              39.49204
            ],
            [
              -75.342376,
              39.490622
            ],
            [
              -75.368213,
              39.456479
            ],
            [
              -75.39983,
              39.462271
            ],
            [
              -75.414305,
              39.453054
            ],
            [
              -75.408753,
              39.423785
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "34",
      "COUNTYFP": "019",
      "COUNTYNS": "00882228",
      "AFFGEOID": "0500000US34019",
      "GEOID": "34019",
      "NAME": "Hunterdon",
      "LSAD": "06",
      "ALAND": 1108047582,
      "AWATER": 24800279,
      "County_state": "Hunterdon,34"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -75.195114,
              40.579689
            ],
            [
              -75.19487,
              40.578591
            ],
            [
              -75.194046,
              40.576256
            ],
            [
              -75.192352,
              40.574257
            ],
            [
              -75.186737,
              40.569406
            ],
            [
              -75.183151,
              40.567354
            ],
            [
              -75.175307,
              40.564996
            ],
            [
              -75.168609,
              40.564111
            ],
            [
              -75.162871,
              40.564096
            ],
            [
              -75.158446,
              40.565286
            ],
            [
              -75.147368,
              40.573152
            ],
            [
              -75.141906,
              40.575273
            ],
            [
              -75.136748,
              40.575731
            ],
            [
              -75.117292,
              40.573211
            ],
            [
              -75.110903,
              40.570671
            ],
            [
              -75.100325,
              40.567811
            ],
            [
              -75.0957,
              40.564401
            ],
            [
              -75.078503,
              40.548296
            ],
            [
              -75.068615,
              40.542223
            ],
            [
              -75.067257,
              40.539584
            ],
            [
              -75.066402,
              40.536532
            ],
            [
              -75.06509,
              40.526148
            ],
            [
              -75.065853,
              40.519495
            ],
            [
              -75.066001,
              40.510716
            ],
            [
              -75.065275,
              40.504682
            ],
            [
              -75.062373,
              40.491689
            ],
            [
              -75.061937,
              40.486362
            ],
            [
              -75.062227,
              40.481391
            ],
            [
              -75.064327,
              40.476795
            ],
            [
              -75.067776,
              40.472827
            ],
            [
              -75.06805,
              40.468578
            ],
            [
              -75.067302,
              40.464954
            ],
            [
              -75.070568,
              40.456348
            ],
            [
              -75.070568,
              40.455165
            ],
            [
              -75.067425,
              40.448323
            ],
            [
              -75.062923,
              40.433407
            ],
            [
              -75.061489,
              40.422848
            ],
            [
              -75.06066217472039,
              40.4213505727709
            ],
            [
              -75.058848,
              40.418065
            ],
            [
              -75.056102,
              40.416066
            ],
            [
              -75.046473,
              40.413792
            ],
            [
              -75.0444082558835,
              40.412463450655196
            ],
            [
              -75.043071,
              40.411603
            ],
            [
              -75.0417605522261,
              40.4100258484186
            ],
            [
              -75.041651,
              40.409894
            ],
            [
              -75.036616,
              40.406796
            ],
            [
              -75.028315,
              40.403883
            ],
            [
              -75.024775,
              40.403455
            ],
            [
              -75.017221,
              40.404638
            ],
            [
              -75.003351,
              40.40785
            ],
            [
              -74.998651,
              40.410093
            ],
            [
              -74.996378,
              40.410528
            ],
            [
              -74.988901,
              40.408773
            ],
            [
              -74.985467,
              40.405935
            ],
            [
              -74.982735,
              40.404432
            ],
            [
              -74.969597,
              40.39977
            ],
            [
              -74.965508,
              40.397337
            ],
            [
              -74.963997,
              40.395246
            ],
            [
              -74.9540018446549,
              40.376648218234095
            ],
            [
              -74.953697,
              40.376081
            ],
            [
              -74.948722,
              40.364768
            ],
            [
              -74.946006,
              40.357306
            ],
            [
              -74.945088,
              40.347332
            ],
            [
              -74.943776,
              40.342564
            ],
            [
              -74.9429542775691,
              40.3416426197195
            ],
            [
              -74.932277,
              40.339427
            ],
            [
              -74.856262,
              40.346696
            ],
            [
              -74.869678,
              40.377774
            ],
            [
              -74.802016,
              40.384972
            ],
            [
              -74.80847,
              40.416664
            ],
            [
              -74.748147,
              40.424156
            ],
            [
              -74.78249,
              40.48779
            ],
            [
              -74.797841,
              40.515557
            ],
            [
              -74.775979,
              40.503606
            ],
            [
              -74.742659,
              40.553898
            ],
            [
              -74.71315,
              40.583903
            ],
            [
              -74.721752,
              40.600702
            ],
            [
              -74.702703,
              40.621117
            ],
            [
              -74.726544,
              40.63241
            ],
            [
              -74.72395,
              40.646858
            ],
            [
              -74.730594,
              40.665737
            ],
            [
              -74.715966,
              40.700881
            ],
            [
              -74.724415,
              40.719456
            ],
            [
              -74.797973,
              40.742186
            ],
            [
              -74.82388,
              40.742358
            ],
            [
              -74.889819,
              40.787729
            ],
            [
              -74.927262,
              40.754573
            ],
            [
              -74.968927,
              40.710392
            ],
            [
              -74.983388,
              40.705372
            ],
            [
              -75.016943,
              40.694612
            ],
            [
              -75.077206,
              40.663325
            ],
            [
              -75.108359,
              40.639754
            ],
            [
              -75.145908,
              40.62866
            ],
            [
              -75.180989,
              40.594204
            ],
            [
              -75.190369434453,
              40.5916420033367
            ],
            [
              -75.190146,
              40.590359
            ],
            [
              -75.190796,
              40.586838
            ],
            [
              -75.194656,
              40.58194
            ],
            [
              -75.195114,
              40.579689
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "34",
      "COUNTYFP": "041",
      "COUNTYNS": "00882237",
      "AFFGEOID": "0500000US34041",
      "GEOID": "34041",
      "NAME": "Warren",
      "LSAD": "06",
      "ALAND": 923327586,
      "AWATER": 15931292,
      "County_state": "Warren,34"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -75.20392,
              40.691498
            ],
            [
              -75.20092,
              40.685498
            ],
            [
              -75.19692,
              40.681299
            ],
            [
              -75.19058,
              40.679379
            ],
            [
              -75.1901585993587,
              40.6794201393766
            ],
            [
              -75.184516,
              40.679971
            ],
            [
              -75.180564,
              40.679363
            ],
            [
              -75.177587,
              40.677731
            ],
            [
              -75.176803,
              40.675715
            ],
            [
              -75.177491,
              40.672595
            ],
            [
              -75.182756,
              40.665971
            ],
            [
              -75.18794,
              40.663811
            ],
            [
              -75.190852,
              40.661939
            ],
            [
              -75.196676,
              40.655123
            ],
            [
              -75.200452,
              40.649219
            ],
            [
              -75.200468,
              40.646899
            ],
            [
              -75.193492,
              40.642275
            ],
            [
              -75.192276,
              40.640803
            ],
            [
              -75.191059,
              40.637971
            ],
            [
              -75.188579,
              40.624628
            ],
            [
              -75.189283,
              40.621492
            ],
            [
              -75.190691,
              40.619956
            ],
            [
              -75.197891,
              40.619332
            ],
            [
              -75.200708,
              40.618356
            ],
            [
              -75.201812,
              40.617188
            ],
            [
              -75.201348,
              40.614628
            ],
            [
              -75.198499,
              40.611492
            ],
            [
              -75.196803,
              40.60858
            ],
            [
              -75.195923,
              40.606788
            ],
            [
              -75.192291,
              40.602676
            ],
            [
              -75.190369434453,
              40.5916420033367
            ],
            [
              -75.180989,
              40.594204
            ],
            [
              -75.145908,
              40.62866
            ],
            [
              -75.108359,
              40.639754
            ],
            [
              -75.077206,
              40.663325
            ],
            [
              -75.016943,
              40.694612
            ],
            [
              -74.983388,
              40.705372
            ],
            [
              -74.968927,
              40.710392
            ],
            [
              -74.927262,
              40.754573
            ],
            [
              -74.889819,
              40.787729
            ],
            [
              -74.850263,
              40.804173
            ],
            [
              -74.818434,
              40.836946
            ],
            [
              -74.821655,
              40.848498
            ],
            [
              -74.802003,
              40.863732
            ],
            [
              -74.806776,
              40.882082
            ],
            [
              -74.779094,
              40.906348
            ],
            [
              -74.769425,
              40.910934
            ],
            [
              -74.790408,
              40.930435
            ],
            [
              -74.9670977569147,
              41.094338922910204
            ],
            [
              -74.966759,
              41.093425
            ],
            [
              -74.968389,
              41.087797
            ],
            [
              -74.970987,
              41.085293
            ],
            [
              -74.98259,
              41.079172
            ],
            [
              -74.989332,
              41.078319
            ],
            [
              -74.994847,
              41.076556
            ],
            [
              -74.999617,
              41.073943
            ],
            [
              -75.006376,
              41.067546
            ],
            [
              -75.011133,
              41.067521
            ],
            [
              -75.01257,
              41.066281
            ],
            [
              -75.015271,
              41.061215
            ],
            [
              -75.015867,
              41.05821
            ],
            [
              -75.017239,
              41.055491
            ],
            [
              -75.019186,
              41.052968
            ],
            [
              -75.025702,
              41.046482
            ],
            [
              -75.026376,
              41.04444
            ],
            [
              -75.02543,
              41.04071
            ],
            [
              -75.025777,
              41.039806
            ],
            [
              -75.030701,
              41.038416
            ],
            [
              -75.034496,
              41.036755
            ],
            [
              -75.040668,
              41.031755
            ],
            [
              -75.070532,
              41.01862
            ],
            [
              -75.074999,
              41.01713
            ],
            [
              -75.081101,
              41.016838
            ],
            [
              -75.089787,
              41.014549
            ],
            [
              -75.090312,
              41.013302
            ],
            [
              -75.095556,
              41.008874
            ],
            [
              -75.100682,
              41.006716
            ],
            [
              -75.109114,
              41.004102
            ],
            [
              -75.110595,
              41.002174
            ],
            [
              -75.123423,
              40.996129
            ],
            [
              -75.127196,
              40.993954
            ],
            [
              -75.130575,
              40.991093
            ],
            [
              -75.131619,
              40.9889
            ],
            [
              -75.13153,
              40.984914
            ],
            [
              -75.132106,
              40.982566
            ],
            [
              -75.133086,
              40.980179
            ],
            [
              -75.135521,
              40.976865
            ],
            [
              -75.135526,
              40.973807
            ],
            [
              -75.13378,
              40.970973
            ],
            [
              -75.131364,
              40.969277
            ],
            [
              -75.129074,
              40.968976
            ],
            [
              -75.122603,
              40.970152
            ],
            [
              -75.12051811257778,
              40.968372921710795
            ],
            [
              -75.120435,
              40.968302
            ],
            [
              -75.11977,
              40.96651
            ],
            [
              -75.12065,
              40.964028
            ],
            [
              -75.120316,
              40.96263
            ],
            [
              -75.119893,
              40.961646
            ],
            [
              -75.118904,
              40.956361
            ],
            [
              -75.117764,
              40.953023
            ],
            [
              -75.111683,
              40.948111
            ],
            [
              -75.106153,
              40.939671
            ],
            [
              -75.105524,
              40.936294
            ],
            [
              -75.09772,
              40.926679
            ],
            [
              -75.095526,
              40.924152
            ],
            [
              -75.079279,
              40.91389
            ],
            [
              -75.076956,
              40.90988
            ],
            [
              -75.076092,
              40.907042
            ],
            [
              -75.075188,
              40.900154
            ],
            [
              -75.075957,
              40.895694
            ],
            [
              -75.07534,
              40.894162
            ],
            [
              -75.07392,
              40.892176
            ],
            [
              -75.065438,
              40.885682
            ],
            [
              -75.062149,
              40.882289
            ],
            [
              -75.058655,
              40.877654
            ],
            [
              -75.053664,
              40.87366
            ],
            [
              -75.051508,
              40.870224
            ],
            [
              -75.050839,
              40.868067
            ],
            [
              -75.051029,
              40.865662
            ],
            [
              -75.053294,
              40.8599
            ],
            [
              -75.060491,
              40.85302
            ],
            [
              -75.064328,
              40.848338
            ],
            [
              -75.066014,
              40.847591
            ],
            [
              -75.07083,
              40.847392
            ],
            [
              -75.073544,
              40.84894
            ],
            [
              -75.076684,
              40.849875
            ],
            [
              -75.090962,
              40.849187
            ],
            [
              -75.095784,
              40.847082
            ],
            [
              -75.097221,
              40.844672
            ],
            [
              -75.097586,
              40.843042
            ],
            [
              -75.097572,
              40.840967
            ],
            [
              -75.097006,
              40.839336
            ],
            [
              -75.09494,
              40.837103
            ],
            [
              -75.085517,
              40.830085
            ],
            [
              -75.083822,
              40.827805
            ],
            [
              -75.083929,
              40.824471
            ],
            [
              -75.085387,
              40.821972
            ],
            [
              -75.090518,
              40.815913
            ],
            [
              -75.096147,
              40.812211
            ],
            [
              -75.098279,
              40.810286
            ],
            [
              -75.100277,
              40.807578
            ],
            [
              -75.100739,
              40.805488
            ],
            [
              -75.100165,
              40.803
            ],
            [
              -75.100277,
              40.801176
            ],
            [
              -75.1008,
              40.799797
            ],
            [
              -75.108505,
              40.791094
            ],
            [
              -75.1113280423616,
              40.789902314041896
            ],
            [
              -75.111343,
              40.789896
            ],
            [
              -75.116842,
              40.78935
            ],
            [
              -75.123088,
              40.786746
            ],
            [
              -75.125867,
              40.784026
            ],
            [
              -75.131465,
              40.77595
            ],
            [
              -75.133303,
              40.774124
            ],
            [
              -75.1344,
              40.773765
            ],
            [
              -75.139106,
              40.773606
            ],
            [
              -75.149378,
              40.774786
            ],
            [
              -75.16365,
              40.778386
            ],
            [
              -75.169523,
              40.778473
            ],
            [
              -75.171587,
              40.777745
            ],
            [
              -75.173349,
              40.776129
            ],
            [
              -75.17562,
              40.772923
            ],
            [
              -75.176855,
              40.768721
            ],
            [
              -75.177477,
              40.764225
            ],
            [
              -75.17904,
              40.761897
            ],
            [
              -75.183037,
              40.759344
            ],
            [
              -75.191796,
              40.75583
            ],
            [
              -75.196533,
              40.751631
            ],
            [
              -75.196861,
              40.750097
            ],
            [
              -75.196325,
              40.747137
            ],
            [
              -75.195349,
              40.745473
            ],
            [
              -75.18578,
              40.737266
            ],
            [
              -75.182804,
              40.73365
            ],
            [
              -75.182084,
              40.731522
            ],
            [
              -75.1825,
              40.729922
            ],
            [
              -75.186372,
              40.72397
            ],
            [
              -75.189412,
              40.71797
            ],
            [
              -75.192612,
              40.715874
            ],
            [
              -75.192856911611,
              40.71562258631079
            ],
            [
              -75.19442,
              40.714018
            ],
            [
              -75.19872,
              40.705298
            ],
            [
              -75.20392,
              40.691498
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "34",
      "COUNTYFP": "035",
      "COUNTYNS": "00882234",
      "AFFGEOID": "0500000US34035",
      "GEOID": "34035",
      "NAME": "Somerset",
      "LSAD": "06",
      "ALAND": 781816196,
      "AWATER": 8009303,
      "County_state": "Somerset,34"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -74.797841,
              40.515557
            ],
            [
              -74.78249,
              40.48779
            ],
            [
              -74.748147,
              40.424156
            ],
            [
              -74.722061,
              40.375205
            ],
            [
              -74.653928,
              40.391048
            ],
            [
              -74.622682,
              40.384336
            ],
            [
              -74.62024,
              40.373749
            ],
            [
              -74.587709,
              40.394418
            ],
            [
              -74.584987,
              40.418176
            ],
            [
              -74.53152,
              40.441355
            ],
            [
              -74.495317,
              40.468941
            ],
            [
              -74.489491,
              40.474452
            ],
            [
              -74.458956,
              40.49598
            ],
            [
              -74.515872,
              40.544984
            ],
            [
              -74.519843,
              40.555584
            ],
            [
              -74.526637,
              40.559469
            ],
            [
              -74.523917,
              40.56967
            ],
            [
              -74.512154,
              40.582075
            ],
            [
              -74.486054,
              40.588334
            ],
            [
              -74.463291,
              40.599187
            ],
            [
              -74.424401,
              40.619545
            ],
            [
              -74.413802,
              40.64101
            ],
            [
              -74.40352,
              40.654656
            ],
            [
              -74.403486,
              40.666657
            ],
            [
              -74.429866,
              40.664993
            ],
            [
              -74.450846,
              40.654148
            ],
            [
              -74.461035,
              40.673504
            ],
            [
              -74.523078,
              40.649125
            ],
            [
              -74.529567,
              40.664673
            ],
            [
              -74.513717,
              40.69775
            ],
            [
              -74.530893,
              40.729854
            ],
            [
              -74.549005,
              40.740935
            ],
            [
              -74.550095,
              40.747327
            ],
            [
              -74.556018,
              40.758288
            ],
            [
              -74.631262,
              40.741142
            ],
            [
              -74.657258,
              40.735215
            ],
            [
              -74.724415,
              40.719456
            ],
            [
              -74.715966,
              40.700881
            ],
            [
              -74.730594,
              40.665737
            ],
            [
              -74.72395,
              40.646858
            ],
            [
              -74.726544,
              40.63241
            ],
            [
              -74.702703,
              40.621117
            ],
            [
              -74.721752,
              40.600702
            ],
            [
              -74.71315,
              40.583903
            ],
            [
              -74.742659,
              40.553898
            ],
            [
              -74.775979,
              40.503606
            ],
            [
              -74.797841,
              40.515557
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "34",
      "COUNTYFP": "029",
      "COUNTYNS": "00882279",
      "AFFGEOID": "0500000US34029",
      "GEOID": "34029",
      "NAME": "Ocean",
      "LSAD": "06",
      "ALAND": 1628663096,
      "AWATER": 740751569,
      "County_state": "Ocean,34"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -74.528374,
              40.089613
            ],
            [
              -74.553105,
              40.07913
            ],
            [
              -74.50641,
              39.991301
            ],
            [
              -74.496851,
              39.973137
            ],
            [
              -74.434223,
              39.855157
            ],
            [
              -74.389708,
              39.773283
            ],
            [
              -74.390399,
              39.596037
            ],
            [
              -74.39812,
              39.576575
            ],
            [
              -74.418833,
              39.568583
            ],
            [
              -74.417393,
              39.557255
            ],
            [
              -74.380796,
              39.498537
            ],
            [
              -74.326382,
              39.506332
            ],
            [
              -74.3124508024098,
              39.499869360202
            ],
            [
              -74.311037,
              39.506715
            ],
            [
              -74.291585,
              39.507705
            ],
            [
              -74.27737,
              39.514064
            ],
            [
              -74.249043,
              39.547994
            ],
            [
              -74.240506,
              39.554911
            ],
            [
              -74.190974,
              39.625118
            ],
            [
              -74.141733,
              39.689435
            ],
            [
              -74.113655,
              39.740719
            ],
            [
              -74.101443,
              39.756173
            ],
            [
              -74.09892,
              39.759538
            ],
            [
              -74.0973588108717,
              39.762244888001895
            ],
            [
              -74.096906,
              39.76303
            ],
            [
              -74.097071,
              39.767847
            ],
            [
              -74.090945,
              39.799978
            ],
            [
              -74.077247,
              39.910991
            ],
            [
              -74.07225874273269,
              39.9369236986638
            ],
            [
              -74.064135,
              39.979157
            ],
            [
              -74.0451593832544,
              40.0576884429368
            ],
            [
              -74.039421,
              40.081437
            ],
            [
              -74.033546,
              40.099518
            ],
            [
              -74.031318,
              40.100541
            ],
            [
              -74.0318605705666,
              40.101046639623
            ],
            [
              -74.045719,
              40.105295
            ],
            [
              -74.064348,
              40.093473
            ],
            [
              -74.070984,
              40.089194
            ],
            [
              -74.085554,
              40.097573
            ],
            [
              -74.107794,
              40.136926
            ],
            [
              -74.154304,
              40.099618
            ],
            [
              -74.160123,
              40.106946
            ],
            [
              -74.242899,
              40.115712
            ],
            [
              -74.241882,
              40.131001
            ],
            [
              -74.259841,
              40.149166
            ],
            [
              -74.261455,
              40.167033
            ],
            [
              -74.40671,
              40.172402
            ],
            [
              -74.528374,
              40.089613
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "34",
      "COUNTYFP": "003",
      "COUNTYNS": "00882271",
      "AFFGEOID": "0500000US34003",
      "GEOID": "34003",
      "NAME": "Bergen",
      "LSAD": "06",
      "ALAND": 602859293,
      "AWATER": 35406803,
      "County_state": "Bergen,34"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -74.270658,
              41.021033
            ],
            [
              -74.269934,
              41.016206
            ],
            [
              -74.248532,
              40.992094
            ],
            [
              -74.224533,
              40.981303
            ],
            [
              -74.211933,
              40.980167
            ],
            [
              -74.171141,
              40.977066
            ],
            [
              -74.170275,
              40.983904
            ],
            [
              -74.141048,
              40.966348
            ],
            [
              -74.146514,
              40.948183
            ],
            [
              -74.148071,
              40.941643
            ],
            [
              -74.139749,
              40.92365
            ],
            [
              -74.132337,
              40.92243
            ],
            [
              -74.132823,
              40.897074
            ],
            [
              -74.128481,
              40.894133
            ],
            [
              -74.130347,
              40.885641
            ],
            [
              -74.118078,
              40.876768
            ],
            [
              -74.107481,
              40.863219
            ],
            [
              -74.120821,
              40.858255
            ],
            [
              -74.121683,
              40.834056
            ],
            [
              -74.123516,
              40.823049
            ],
            [
              -74.130016,
              40.819938
            ],
            [
              -74.139575,
              40.805409
            ],
            [
              -74.138085,
              40.798846
            ],
            [
              -74.147529,
              40.786491
            ],
            [
              -74.130359,
              40.777564
            ],
            [
              -74.101003,
              40.776039
            ],
            [
              -74.090787,
              40.761984
            ],
            [
              -74.076753,
              40.795621
            ],
            [
              -74.044923,
              40.807555
            ],
            [
              -74.017819,
              40.823341
            ],
            [
              -74.01377,
              40.823059
            ],
            [
              -74.011376,
              40.820838
            ],
            [
              -74.011788,
              40.819842
            ],
            [
              -74.01141,
              40.817691
            ],
            [
              -74.012339,
              40.81602
            ],
            [
              -73.998303,
              40.808477
            ],
            [
              -73.98382706989679,
              40.7988269099791
            ],
            [
              -73.97122753768102,
              40.81633021127801
            ],
            [
              -73.97004794249119,
              40.8179689078315
            ],
            [
              -73.968082,
              40.8207
            ],
            [
              -73.967982575439,
              40.8208258025057
            ],
            [
              -73.9679734153723,
              40.820837392794196
            ],
            [
              -73.963182,
              40.8269
            ],
            [
              -73.953982,
              40.848
            ],
            [
              -73.948281,
              40.858399
            ],
            [
              -73.9381535187682,
              40.8745831121645
            ],
            [
              -73.938081,
              40.874699
            ],
            [
              -73.9334079229381,
              40.882074964842694
            ],
            [
              -73.933406,
              40.882078
            ],
            [
              -73.929006,
              40.889578
            ],
            [
              -73.919705,
              40.913478
            ],
            [
              -73.9196864010756,
              40.9135352131528
            ],
            [
              -73.918405,
              40.917477
            ],
            [
              -73.917905,
              40.917577
            ],
            [
              -73.91768,
              40.919498
            ],
            [
              -73.91558,
              40.924898
            ],
            [
              -73.90728,
              40.951498
            ],
            [
              -73.9049158705604,
              40.9581079754602
            ],
            [
              -73.8989276186366,
              40.974850797407
            ],
            [
              -73.896479,
              40.981697
            ],
            [
              -73.893979,
              40.997197
            ],
            [
              -73.90268,
              40.997297
            ],
            [
              -73.90501,
              40.997591
            ],
            [
              -73.907054,
              40.998476
            ],
            [
              -73.91188,
              41.001297
            ],
            [
              -73.9589142691188,
              41.02233969118829
            ],
            [
              -74.041049,
              41.059086
            ],
            [
              -74.041054,
              41.059088
            ],
            [
              -74.092486,
              41.081896
            ],
            [
              -74.096786,
              41.083796
            ],
            [
              -74.18239,
              41.121595
            ],
            [
              -74.1953371933152,
              41.1268869350132
            ],
            [
              -74.2113709734917,
              41.1334404575138
            ],
            [
              -74.250458,
              41.060816
            ],
            [
              -74.270658,
              41.021033
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "34",
      "COUNTYFP": "023",
      "COUNTYNS": "00882230",
      "AFFGEOID": "0500000US34023",
      "GEOID": "34023",
      "NAME": "Middlesex",
      "LSAD": "06",
      "ALAND": 800857610,
      "AWATER": 35472648,
      "County_state": "Middlesex,34"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -74.628282,
              40.345995
            ],
            [
              -74.630228,
              40.343127
            ],
            [
              -74.622462,
              40.3248
            ],
            [
              -74.603322,
              40.321402
            ],
            [
              -74.573397,
              40.3009
            ],
            [
              -74.539918,
              40.28833
            ],
            [
              -74.48203,
              40.273915
            ],
            [
              -74.484962,
              40.253325
            ],
            [
              -74.460776,
              40.251358
            ],
            [
              -74.393384,
              40.279637
            ],
            [
              -74.394231,
              40.28839
            ],
            [
              -74.352522,
              40.324977
            ],
            [
              -74.327377,
              40.344599
            ],
            [
              -74.290883,
              40.372978
            ],
            [
              -74.251057,
              40.404085
            ],
            [
              -74.247901,
              40.407234
            ],
            [
              -74.249485,
              40.410228
            ],
            [
              -74.247963,
              40.412192
            ],
            [
              -74.247818,
              40.42974
            ],
            [
              -74.222774,
              40.451492
            ],
            [
              -74.22494582655939,
              40.4532664936393
            ],
            [
              -74.236689,
              40.457806
            ],
            [
              -74.2579619285372,
              40.4636307304328
            ],
            [
              -74.261889,
              40.464706
            ],
            [
              -74.26648,
              40.476924
            ],
            [
              -74.270825,
              40.489216
            ],
            [
              -74.260611,
              40.502436
            ],
            [
              -74.258556,
              40.506232
            ],
            [
              -74.25166,
              40.517448
            ],
            [
              -74.246408,
              40.524357
            ],
            [
              -74.248787,
              40.533033
            ],
            [
              -74.249211,
              40.545064
            ],
            [
              -74.239211,
              40.553764
            ],
            [
              -74.2274,
              40.558034
            ],
            [
              -74.216839,
              40.558618
            ],
            [
              -74.2168256483963,
              40.558646517311
            ],
            [
              -74.211481,
              40.570062
            ],
            [
              -74.207519,
              40.582741
            ],
            [
              -74.203688,
              40.592691
            ],
            [
              -74.214457,
              40.604141
            ],
            [
              -74.29099,
              40.591925
            ],
            [
              -74.305326,
              40.600586
            ],
            [
              -74.302921,
              40.608683
            ],
            [
              -74.338472,
              40.605735
            ],
            [
              -74.358717,
              40.604061
            ],
            [
              -74.437067,
              40.597468
            ],
            [
              -74.455132,
              40.595954
            ],
            [
              -74.463291,
              40.599187
            ],
            [
              -74.486054,
              40.588334
            ],
            [
              -74.512154,
              40.582075
            ],
            [
              -74.523917,
              40.56967
            ],
            [
              -74.526637,
              40.559469
            ],
            [
              -74.519843,
              40.555584
            ],
            [
              -74.515872,
              40.544984
            ],
            [
              -74.458956,
              40.49598
            ],
            [
              -74.489491,
              40.474452
            ],
            [
              -74.495317,
              40.468941
            ],
            [
              -74.53152,
              40.441355
            ],
            [
              -74.584987,
              40.418176
            ],
            [
              -74.587709,
              40.394418
            ],
            [
              -74.62024,
              40.373749
            ],
            [
              -74.624409,
              40.365229
            ],
            [
              -74.628282,
              40.345995
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "34",
      "COUNTYFP": "001",
      "COUNTYNS": "00882270",
      "AFFGEOID": "0500000US34001",
      "GEOID": "34001",
      "NAME": "Atlantic",
      "LSAD": "06",
      "ALAND": 1438982592,
      "AWATER": 301062779,
      "County_state": "Atlantic,34"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -74.911882,
              39.578462
            ],
            [
              -74.985271,
              39.514967
            ],
            [
              -74.855979,
              39.424041
            ],
            [
              -74.856747,
              39.420757
            ],
            [
              -74.853114,
              39.415455
            ],
            [
              -74.851989,
              39.383745
            ],
            [
              -74.864911,
              39.338879
            ],
            [
              -74.861294,
              39.323815
            ],
            [
              -74.824883,
              39.305276
            ],
            [
              -74.784374,
              39.307318
            ],
            [
              -74.752165,
              39.295005
            ],
            [
              -74.719595,
              39.298644
            ],
            [
              -74.699571,
              39.28935
            ],
            [
              -74.659302,
              39.297518
            ],
            [
              -74.651528,
              39.288688
            ],
            [
              -74.640088,
              39.29599
            ],
            [
              -74.618004,
              39.293522
            ],
            [
              -74.588701,
              39.310074
            ],
            [
              -74.568762,
              39.299268
            ],
            [
              -74.54118058483179,
              39.300426310362006
            ],
            [
              -74.521797,
              39.313816
            ],
            [
              -74.459894,
              39.345016
            ],
            [
              -74.412692,
              39.360816
            ],
            [
              -74.408237,
              39.365071
            ],
            [
              -74.406792,
              39.373916
            ],
            [
              -74.406692,
              39.377516
            ],
            [
              -74.36699,
              39.402017
            ],
            [
              -74.334804,
              39.432001
            ],
            [
              -74.3283000805524,
              39.4404229362033
            ],
            [
              -74.304343,
              39.471445
            ],
            [
              -74.302184,
              39.478935
            ],
            [
              -74.304778,
              39.482945
            ],
            [
              -74.308344,
              39.483945
            ],
            [
              -74.313689,
              39.493874
            ],
            [
              -74.3124508024098,
              39.499869360202
            ],
            [
              -74.326382,
              39.506332
            ],
            [
              -74.380796,
              39.498537
            ],
            [
              -74.417393,
              39.557255
            ],
            [
              -74.43835,
              39.551313
            ],
            [
              -74.436119,
              39.541265
            ],
            [
              -74.446722,
              39.559061
            ],
            [
              -74.453446,
              39.548573
            ],
            [
              -74.475664,
              39.55146
            ],
            [
              -74.517023,
              39.574151
            ],
            [
              -74.528631,
              39.56999
            ],
            [
              -74.542641,
              39.57912
            ],
            [
              -74.55356,
              39.598162
            ],
            [
              -74.575749,
              39.598395
            ],
            [
              -74.588718,
              39.611802
            ],
            [
              -74.621028,
              39.626691
            ],
            [
              -74.652998,
              39.626587
            ],
            [
              -74.658728,
              39.630981
            ],
            [
              -74.660479,
              39.668799
            ],
            [
              -74.666211,
              39.681291
            ],
            [
              -74.675895,
              39.691159
            ],
            [
              -74.723856,
              39.722063
            ],
            [
              -74.736216,
              39.729775
            ],
            [
              -74.800087,
              39.674864
            ],
            [
              -74.859173,
              39.623958
            ],
            [
              -74.87726,
              39.608352
            ],
            [
              -74.911882,
              39.578462
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "34",
      "COUNTYFP": "005",
      "COUNTYNS": "00882272",
      "AFFGEOID": "0500000US34005",
      "GEOID": "34005",
      "NAME": "Burlington",
      "LSAD": "06",
      "ALAND": 2069239325,
      "AWATER": 55032009,
      "County_state": "Burlington,34"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -75.059017,
              39.992512
            ],
            [
              -75.0599940905637,
              39.9916175863301
            ],
            [
              -75.036354,
              39.996081
            ],
            [
              -75.030782,
              39.993234
            ],
            [
              -75.010354,
              39.979996
            ],
            [
              -75.019253,
              39.967434
            ],
            [
              -75.0176,
              39.959375
            ],
            [
              -75.013092,
              39.956628
            ],
            [
              -75.015081,
              39.946813
            ],
            [
              -75.004149,
              39.941465
            ],
            [
              -74.975051,
              39.938511
            ],
            [
              -74.98558,
              39.93043
            ],
            [
              -74.984045,
              39.927354
            ],
            [
              -74.962971,
              39.92028
            ],
            [
              -74.957313,
              39.914345
            ],
            [
              -74.931071,
              39.885975
            ],
            [
              -74.902368,
              39.791041
            ],
            [
              -74.876185,
              39.781459
            ],
            [
              -74.843208,
              39.789919
            ],
            [
              -74.819419,
              39.788001
            ],
            [
              -74.790724,
              39.771303
            ],
            [
              -74.77209,
              39.750436
            ],
            [
              -74.736216,
              39.729775
            ],
            [
              -74.723856,
              39.722063
            ],
            [
              -74.675895,
              39.691159
            ],
            [
              -74.666211,
              39.681291
            ],
            [
              -74.660479,
              39.668799
            ],
            [
              -74.658728,
              39.630981
            ],
            [
              -74.652998,
              39.626587
            ],
            [
              -74.621028,
              39.626691
            ],
            [
              -74.588718,
              39.611802
            ],
            [
              -74.575749,
              39.598395
            ],
            [
              -74.55356,
              39.598162
            ],
            [
              -74.542641,
              39.57912
            ],
            [
              -74.528631,
              39.56999
            ],
            [
              -74.517023,
              39.574151
            ],
            [
              -74.475664,
              39.55146
            ],
            [
              -74.453446,
              39.548573
            ],
            [
              -74.446722,
              39.559061
            ],
            [
              -74.436119,
              39.541265
            ],
            [
              -74.43835,
              39.551313
            ],
            [
              -74.417393,
              39.557255
            ],
            [
              -74.418833,
              39.568583
            ],
            [
              -74.39812,
              39.576575
            ],
            [
              -74.390399,
              39.596037
            ],
            [
              -74.389708,
              39.773283
            ],
            [
              -74.434223,
              39.855157
            ],
            [
              -74.496851,
              39.973137
            ],
            [
              -74.50641,
              39.991301
            ],
            [
              -74.553105,
              40.07913
            ],
            [
              -74.587835,
              40.138254
            ],
            [
              -74.602419,
              40.136976
            ],
            [
              -74.623382,
              40.142711
            ],
            [
              -74.662023,
              40.16493
            ],
            [
              -74.677311,
              40.167279
            ],
            [
              -74.674668,
              40.173691
            ],
            [
              -74.710099,
              40.181283
            ],
            [
              -74.706269,
              40.161793
            ],
            [
              -74.7224939093264,
              40.1504283445596
            ],
            [
              -74.722604,
              40.15001
            ],
            [
              -74.724304,
              40.14701
            ],
            [
              -74.725663,
              40.145495
            ],
            [
              -74.740605,
              40.13521
            ],
            [
              -74.742905,
              40.13441
            ],
            [
              -74.745905,
              40.13421
            ],
            [
              -74.755305,
              40.13471
            ],
            [
              -74.758882,
              40.134036
            ],
            [
              -74.762864,
              40.132541
            ],
            [
              -74.769488,
              40.129145
            ],
            [
              -74.782106,
              40.12081
            ],
            [
              -74.785106,
              40.12031
            ],
            [
              -74.788706,
              40.12041
            ],
            [
              -74.800607,
              40.12281
            ],
            [
              -74.812807,
              40.12691
            ],
            [
              -74.8156695,
              40.1274825
            ],
            [
              -74.816307,
              40.12761
            ],
            [
              -74.819007,
              40.12751
            ],
            [
              -74.822307,
              40.12671
            ],
            [
              -74.825907,
              40.12391
            ],
            [
              -74.828408,
              40.12031
            ],
            [
              -74.832808,
              40.11171
            ],
            [
              -74.835108,
              40.10391
            ],
            [
              -74.838008,
              40.10091
            ],
            [
              -74.843408,
              40.09771
            ],
            [
              -74.851108,
              40.09491
            ],
            [
              -74.854409,
              40.09311
            ],
            [
              -74.856509,
              40.09131
            ],
            [
              -74.85739757549229,
              40.090003271334794
            ],
            [
              -74.858209,
              40.08881
            ],
            [
              -74.859809,
              40.08491
            ],
            [
              -74.860909,
              40.08371
            ],
            [
              -74.863809,
              40.08221
            ],
            [
              -74.880209,
              40.07881
            ],
            [
              -74.88781,
              40.07581
            ],
            [
              -74.909011,
              40.07021
            ],
            [
              -74.90907183176469,
              40.0702037070588
            ],
            [
              -74.911911,
              40.06991
            ],
            [
              -74.920811,
              40.07111
            ],
            [
              -74.925311,
              40.07071
            ],
            [
              -74.932211,
              40.068411
            ],
            [
              -74.944412,
              40.063211
            ],
            [
              -74.97431985326,
              40.04889913332
            ],
            [
              -74.974713,
              40.048711
            ],
            [
              -74.983913,
              40.042711
            ],
            [
              -74.989914,
              40.037311
            ],
            [
              -75.007914,
              40.023111
            ],
            [
              -75.011115,
              40.021311
            ],
            [
              -75.015515,
              40.019511
            ],
            [
              -75.0299549242127,
              40.015568095607
            ],
            [
              -75.039316,
              40.013012
            ],
            [
              -75.0466806199737,
              40.009090578975
            ],
            [
              -75.047016,
              40.008912
            ],
            [
              -75.051217,
              40.004512
            ],
            [
              -75.059017,
              39.992512
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "34",
      "COUNTYFP": "009",
      "COUNTYNS": "00882274",
      "AFFGEOID": "0500000US34009",
      "GEOID": "34009",
      "NAME": "Cape May",
      "LSAD": "06",
      "ALAND": 652640195,
      "AWATER": 953946819,
      "County_state": "Cape May,34"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -74.971995,
              38.94037
            ],
            [
              -74.967274,
              38.933413
            ],
            [
              -74.963463,
              38.931194
            ],
            [
              -74.933571,
              38.928519
            ],
            [
              -74.920414,
              38.929136
            ],
            [
              -74.90705,
              38.931994
            ],
            [
              -74.882309,
              38.941759
            ],
            [
              -74.870497,
              38.943543
            ],
            [
              -74.865198,
              38.941439
            ],
            [
              -74.864458,
              38.94041
            ],
            [
              -74.850748,
              38.954538
            ],
            [
              -74.819354,
              38.979402
            ],
            [
              -74.807917,
              38.985948
            ],
            [
              -74.792723,
              38.991991
            ],
            [
              -74.786356,
              39.000113
            ],
            [
              -74.778777,
              39.023073
            ],
            [
              -74.738316,
              39.074727
            ],
            [
              -74.705876,
              39.102937
            ],
            [
              -74.704409,
              39.107858
            ],
            [
              -74.714135,
              39.114631
            ],
            [
              -74.71532,
              39.116893
            ],
            [
              -74.714341,
              39.119804
            ],
            [
              -74.67143,
              39.179802
            ],
            [
              -74.651443,
              39.198578
            ],
            [
              -74.646595,
              39.212002
            ],
            [
              -74.636306,
              39.220834
            ],
            [
              -74.614481,
              39.244659
            ],
            [
              -74.597921,
              39.258851
            ],
            [
              -74.581008,
              39.270819
            ],
            [
              -74.560957,
              39.278677
            ],
            [
              -74.553439,
              39.286915
            ],
            [
              -74.551151,
              39.293539
            ],
            [
              -74.54118058483179,
              39.300426310362006
            ],
            [
              -74.568762,
              39.299268
            ],
            [
              -74.588701,
              39.310074
            ],
            [
              -74.618004,
              39.293522
            ],
            [
              -74.640088,
              39.29599
            ],
            [
              -74.651528,
              39.288688
            ],
            [
              -74.659302,
              39.297518
            ],
            [
              -74.699571,
              39.28935
            ],
            [
              -74.719595,
              39.298644
            ],
            [
              -74.752165,
              39.295005
            ],
            [
              -74.784374,
              39.307318
            ],
            [
              -74.824883,
              39.305276
            ],
            [
              -74.861294,
              39.323815
            ],
            [
              -74.882146,
              39.30839
            ],
            [
              -74.896384,
              39.235054
            ],
            [
              -74.921518,
              39.224999
            ],
            [
              -74.926347,
              39.209012
            ],
            [
              -74.9149362674663,
              39.1775531240776
            ],
            [
              -74.905181,
              39.174945
            ],
            [
              -74.887167,
              39.158825
            ],
            [
              -74.885914,
              39.143627
            ],
            [
              -74.892547,
              39.113183
            ],
            [
              -74.897784,
              39.098811
            ],
            [
              -74.903664,
              39.087437
            ],
            [
              -74.93832,
              39.035185
            ],
            [
              -74.94947,
              39.015637
            ],
            [
              -74.955363,
              39.001262
            ],
            [
              -74.971995,
              38.94037
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "34",
      "COUNTYFP": "013",
      "COUNTYNS": "00882276",
      "AFFGEOID": "0500000US34013",
      "GEOID": "34013",
      "NAME": "Essex",
      "LSAD": "06",
      "ALAND": 326559360,
      "AWATER": 8636456,
      "County_state": "Essex,34"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -74.37556,
              40.758404
            ],
            [
              -74.367145,
              40.761112
            ],
            [
              -74.357547,
              40.754788
            ],
            [
              -74.364876,
              40.749805
            ],
            [
              -74.371738,
              40.73964
            ],
            [
              -74.325596,
              40.717075
            ],
            [
              -74.303523,
              40.713188
            ],
            [
              -74.297212,
              40.715189
            ],
            [
              -74.285187,
              40.722152
            ],
            [
              -74.256022,
              40.713418
            ],
            [
              -74.221949,
              40.707341
            ],
            [
              -74.212972,
              40.70081
            ],
            [
              -74.209248,
              40.690709
            ],
            [
              -74.162389,
              40.689613
            ],
            [
              -74.136703,
              40.674444
            ],
            [
              -74.112787,
              40.703102
            ],
            [
              -74.117057,
              40.710381
            ],
            [
              -74.120987,
              40.722901
            ],
            [
              -74.116993,
              40.737125
            ],
            [
              -74.117605,
              40.739638
            ],
            [
              -74.122786,
              40.742101
            ],
            [
              -74.137492,
              40.741398
            ],
            [
              -74.142688,
              40.739301
            ],
            [
              -74.147188,
              40.734401
            ],
            [
              -74.155687,
              40.733501
            ],
            [
              -74.161958,
              40.736007
            ],
            [
              -74.16598,
              40.748068
            ],
            [
              -74.160325,
              40.763293
            ],
            [
              -74.148326,
              40.779606
            ],
            [
              -74.147529,
              40.786491
            ],
            [
              -74.138085,
              40.798846
            ],
            [
              -74.139575,
              40.805409
            ],
            [
              -74.130016,
              40.819938
            ],
            [
              -74.182662,
              40.848784
            ],
            [
              -74.197872,
              40.857053
            ],
            [
              -74.205196,
              40.861035
            ],
            [
              -74.24029,
              40.880436
            ],
            [
              -74.246778,
              40.880988
            ],
            [
              -74.259635,
              40.883768
            ],
            [
              -74.270702,
              40.89692
            ],
            [
              -74.272063,
              40.897427
            ],
            [
              -74.326114,
              40.908605
            ],
            [
              -74.336357,
              40.866613
            ],
            [
              -74.329854,
              40.849301
            ],
            [
              -74.329161,
              40.838557
            ],
            [
              -74.337921,
              40.813538
            ],
            [
              -74.367062,
              40.793286
            ],
            [
              -74.37556,
              40.758404
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "34",
      "COUNTYFP": "021",
      "COUNTYNS": "00882229",
      "AFFGEOID": "0500000US34021",
      "GEOID": "34021",
      "NAME": "Mercer",
      "LSAD": "06",
      "ALAND": 581086519,
      "AWATER": 11647674,
      "County_state": "Mercer,34"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -74.9429542775691,
              40.3416426197195
            ],
            [
              -74.939711,
              40.338006
            ],
            [
              -74.933111,
              40.333106
            ],
            [
              -74.92681,
              40.329406
            ],
            [
              -74.91741,
              40.322406
            ],
            [
              -74.90831,
              40.316907
            ],
            [
              -74.90331,
              40.315607
            ],
            [
              -74.896409,
              40.315107
            ],
            [
              -74.891609,
              40.313007
            ],
            [
              -74.887109,
              40.310307
            ],
            [
              -74.880609,
              40.305607
            ],
            [
              -74.868209,
              40.295207
            ],
            [
              -74.864692,
              40.290684
            ],
            [
              -74.860492,
              40.284584
            ],
            [
              -74.856508,
              40.277407
            ],
            [
              -74.853118148928,
              40.269729984337
            ],
            [
              -74.853108,
              40.269707
            ],
            [
              -74.846608,
              40.258808
            ],
            [
              -74.842308,
              40.250508
            ],
            [
              -74.836307,
              40.246208
            ],
            [
              -74.823907,
              40.241508
            ],
            [
              -74.819507,
              40.238508
            ],
            [
              -74.795306,
              40.229408
            ],
            [
              -74.781206,
              40.221508
            ],
            [
              -74.77136,
              40.215399
            ],
            [
              -74.770406,
              40.214508
            ],
            [
              -74.766905,
              40.207709
            ],
            [
              -74.7608412405874,
              40.1992389868522
            ],
            [
              -74.760605,
              40.198909
            ],
            [
              -74.756905,
              40.189409
            ],
            [
              -74.755605,
              40.186709
            ],
            [
              -74.754305,
              40.185209
            ],
            [
              -74.7519933317261,
              40.1835197039537
            ],
            [
              -74.751705,
              40.183309
            ],
            [
              -74.744105,
              40.181009
            ],
            [
              -74.737205,
              40.177609
            ],
            [
              -74.733804,
              40.174509
            ],
            [
              -74.722304,
              40.160609
            ],
            [
              -74.721504,
              40.158409
            ],
            [
              -74.721604,
              40.15381
            ],
            [
              -74.7224939093264,
              40.1504283445596
            ],
            [
              -74.706269,
              40.161793
            ],
            [
              -74.710099,
              40.181283
            ],
            [
              -74.674668,
              40.173691
            ],
            [
              -74.677311,
              40.167279
            ],
            [
              -74.662023,
              40.16493
            ],
            [
              -74.623382,
              40.142711
            ],
            [
              -74.602419,
              40.136976
            ],
            [
              -74.587835,
              40.138254
            ],
            [
              -74.614576,
              40.182383
            ],
            [
              -74.593675,
              40.179421
            ],
            [
              -74.562591,
              40.189943
            ],
            [
              -74.543268,
              40.216692
            ],
            [
              -74.481332,
              40.243199
            ],
            [
              -74.484962,
              40.253325
            ],
            [
              -74.48203,
              40.273915
            ],
            [
              -74.539918,
              40.28833
            ],
            [
              -74.573397,
              40.3009
            ],
            [
              -74.603322,
              40.321402
            ],
            [
              -74.622462,
              40.3248
            ],
            [
              -74.630228,
              40.343127
            ],
            [
              -74.628282,
              40.345995
            ],
            [
              -74.624409,
              40.365229
            ],
            [
              -74.62024,
              40.373749
            ],
            [
              -74.622682,
              40.384336
            ],
            [
              -74.653928,
              40.391048
            ],
            [
              -74.722061,
              40.375205
            ],
            [
              -74.748147,
              40.424156
            ],
            [
              -74.80847,
              40.416664
            ],
            [
              -74.802016,
              40.384972
            ],
            [
              -74.869678,
              40.377774
            ],
            [
              -74.856262,
              40.346696
            ],
            [
              -74.932277,
              40.339427
            ],
            [
              -74.9429542775691,
              40.3416426197195
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "34",
      "COUNTYFP": "017",
      "COUNTYNS": "00882278",
      "AFFGEOID": "0500000US34017",
      "GEOID": "34017",
      "NAME": "Hudson",
      "LSAD": "06",
      "ALAND": 119764738,
      "AWATER": 41834804,
      "County_state": "Hudson,34"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -74.16598,
              40.748068
            ],
            [
              -74.161958,
              40.736007
            ],
            [
              -74.155687,
              40.733501
            ],
            [
              -74.147188,
              40.734401
            ],
            [
              -74.142688,
              40.739301
            ],
            [
              -74.137492,
              40.741398
            ],
            [
              -74.122786,
              40.742101
            ],
            [
              -74.117605,
              40.739638
            ],
            [
              -74.116993,
              40.737125
            ],
            [
              -74.120987,
              40.722901
            ],
            [
              -74.117057,
              40.710381
            ],
            [
              -74.112787,
              40.703102
            ],
            [
              -74.136703,
              40.674444
            ],
            [
              -74.142516,
              40.667515
            ],
            [
              -74.1614075238905,
              40.6442325986627
            ],
            [
              -74.143255,
              40.642149
            ],
            [
              -74.128151,
              40.643902
            ],
            [
              -74.112343,
              40.647446
            ],
            [
              -74.100247,
              40.648148
            ],
            [
              -74.086806,
              40.651596
            ],
            [
              -74.081331,
              40.65929
            ],
            [
              -74.070198,
              40.660827
            ],
            [
              -74.059499,
              40.678628
            ],
            [
              -74.047313,
              40.690466
            ],
            [
              -74.04086,
              40.700117
            ],
            [
              -74.024543,
              40.709436
            ],
            [
              -74.0214927100048,
              40.725445125628504
            ],
            [
              -74.021117,
              40.727417
            ],
            [
              -74.0210755680795,
              40.7275818914723
            ],
            [
              -74.01977669977019,
              40.732751149994
            ],
            [
              -74.01692332756079,
              40.7441070499749
            ],
            [
              -74.013784,
              40.756601
            ],
            [
              -74.0098517287783,
              40.762584890989594
            ],
            [
              -74.009184,
              40.763601
            ],
            [
              -74.00828125206719,
              40.7648550996597
            ],
            [
              -74.0022809449912,
              40.7731907410818
            ],
            [
              -74.00139463374009,
              40.7744220068627
            ],
            [
              -73.99382297961459,
              40.784940567490196
            ],
            [
              -73.9880545412037,
              40.79295409638969
            ],
            [
              -73.984697580911,
              40.7976175939264
            ],
            [
              -73.98382706989679,
              40.7988269099791
            ],
            [
              -73.998303,
              40.808477
            ],
            [
              -74.012339,
              40.81602
            ],
            [
              -74.01141,
              40.817691
            ],
            [
              -74.011788,
              40.819842
            ],
            [
              -74.011376,
              40.820838
            ],
            [
              -74.01377,
              40.823059
            ],
            [
              -74.017819,
              40.823341
            ],
            [
              -74.044923,
              40.807555
            ],
            [
              -74.076753,
              40.795621
            ],
            [
              -74.090787,
              40.761984
            ],
            [
              -74.101003,
              40.776039
            ],
            [
              -74.130359,
              40.777564
            ],
            [
              -74.147529,
              40.786491
            ],
            [
              -74.148326,
              40.779606
            ],
            [
              -74.160325,
              40.763293
            ],
            [
              -74.16598,
              40.748068
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "34",
      "COUNTYFP": "025",
      "COUNTYNS": "00882910",
      "AFFGEOID": "0500000US34025",
      "GEOID": "34025",
      "NAME": "Monmouth",
      "LSAD": "06",
      "ALAND": 1213128209,
      "AWATER": 510444544,
      "County_state": "Monmouth,34"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -74.593675,
              40.179421
            ],
            [
              -74.614576,
              40.182383
            ],
            [
              -74.587835,
              40.138254
            ],
            [
              -74.553105,
              40.07913
            ],
            [
              -74.528374,
              40.089613
            ],
            [
              -74.40671,
              40.172402
            ],
            [
              -74.261455,
              40.167033
            ],
            [
              -74.259841,
              40.149166
            ],
            [
              -74.241882,
              40.131001
            ],
            [
              -74.242899,
              40.115712
            ],
            [
              -74.160123,
              40.106946
            ],
            [
              -74.154304,
              40.099618
            ],
            [
              -74.107794,
              40.136926
            ],
            [
              -74.085554,
              40.097573
            ],
            [
              -74.070984,
              40.089194
            ],
            [
              -74.064348,
              40.093473
            ],
            [
              -74.045719,
              40.105295
            ],
            [
              -74.0318605705666,
              40.101046639623
            ],
            [
              -74.03408,
              40.103115
            ],
            [
              -74.030181,
              40.122814
            ],
            [
              -74.00263434458721,
              40.208581331926595
            ],
            [
              -73.9999657551151,
              40.2168900647146
            ],
            [
              -73.993292,
              40.237669
            ],
            [
              -73.981681,
              40.279411
            ],
            [
              -73.977442,
              40.299373
            ],
            [
              -73.9746011832669,
              40.322169370804396
            ],
            [
              -73.971381,
              40.34801
            ],
            [
              -73.971381,
              40.371709
            ],
            [
              -73.976982,
              40.408508
            ],
            [
              -73.982256,
              40.420779
            ],
            [
              -73.982855,
              40.448569
            ],
            [
              -73.997943,
              40.476668
            ],
            [
              -74.018383,
              40.477934
            ],
            [
              -74.019334,
              40.471244
            ],
            [
              -74.005363,
              40.459622
            ],
            [
              -74.003674,
              40.448489
            ],
            [
              -73.991682,
              40.442908
            ],
            [
              -73.995486,
              40.419472
            ],
            [
              -73.998505,
              40.410911
            ],
            [
              -74.006383,
              40.411108
            ],
            [
              -74.047884,
              40.418908
            ],
            [
              -74.058984,
              40.422708
            ],
            [
              -74.076185,
              40.433707
            ],
            [
              -74.088085,
              40.438407
            ],
            [
              -74.108295,
              40.443788
            ],
            [
              -74.122012,
              40.449724
            ],
            [
              -74.133658,
              40.456778
            ],
            [
              -74.137936,
              40.456959
            ],
            [
              -74.140313,
              40.455873
            ],
            [
              -74.144354,
              40.452256
            ],
            [
              -74.151952,
              40.448062
            ],
            [
              -74.157094,
              40.447571
            ],
            [
              -74.163314,
              40.448424
            ],
            [
              -74.169222,
              40.451225
            ],
            [
              -74.174245,
              40.455109
            ],
            [
              -74.17549,
              40.458044
            ],
            [
              -74.180673,
              40.457977
            ],
            [
              -74.185558,
              40.452917
            ],
            [
              -74.188876,
              40.446041
            ],
            [
              -74.193908,
              40.440995
            ],
            [
              -74.202128,
              40.43894
            ],
            [
              -74.206188,
              40.440707
            ],
            [
              -74.209788,
              40.447407
            ],
            [
              -74.22494582655939,
              40.4532664936393
            ],
            [
              -74.222774,
              40.451492
            ],
            [
              -74.247818,
              40.42974
            ],
            [
              -74.247963,
              40.412192
            ],
            [
              -74.249485,
              40.410228
            ],
            [
              -74.247901,
              40.407234
            ],
            [
              -74.251057,
              40.404085
            ],
            [
              -74.290883,
              40.372978
            ],
            [
              -74.327377,
              40.344599
            ],
            [
              -74.352522,
              40.324977
            ],
            [
              -74.394231,
              40.28839
            ],
            [
              -74.393384,
              40.279637
            ],
            [
              -74.460776,
              40.251358
            ],
            [
              -74.484962,
              40.253325
            ],
            [
              -74.481332,
              40.243199
            ],
            [
              -74.543268,
              40.216692
            ],
            [
              -74.562591,
              40.189943
            ],
            [
              -74.593675,
              40.179421
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "34",
      "COUNTYFP": "037",
      "COUNTYNS": "00882236",
      "AFFGEOID": "0500000US34037",
      "GEOID": "34037",
      "NAME": "Sussex",
      "LSAD": "06",
      "ALAND": 1343552956,
      "AWATER": 43234734,
      "County_state": "Sussex,34"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -74.991718,
              41.092284
            ],
            [
              -74.991815,
              41.089132
            ],
            [
              -74.991013,
              41.088578
            ],
            [
              -74.988263,
              41.088222
            ],
            [
              -74.984782,
              41.088545
            ],
            [
              -74.981314,
              41.08986
            ],
            [
              -74.975298,
              41.094073
            ],
            [
              -74.972036,
              41.095562
            ],
            [
              -74.969434,
              41.096074
            ],
            [
              -74.967464,
              41.095327
            ],
            [
              -74.9670977569147,
              41.094338922910204
            ],
            [
              -74.790408,
              40.930435
            ],
            [
              -74.769425,
              40.910934
            ],
            [
              -74.731994,
              40.920817
            ],
            [
              -74.713482,
              40.902306
            ],
            [
              -74.71134,
              40.89918
            ],
            [
              -74.65303,
              40.921623
            ],
            [
              -74.636163,
              40.942639
            ],
            [
              -74.641044,
              40.964426
            ],
            [
              -74.614605,
              40.990676
            ],
            [
              -74.535808,
              41.058486
            ],
            [
              -74.503212,
              41.085873
            ],
            [
              -74.422315,
              41.160383
            ],
            [
              -74.367312185113,
              41.2036716043384
            ],
            [
              -74.378898,
              41.208994
            ],
            [
              -74.457584,
              41.248225
            ],
            [
              -74.499603,
              41.267344
            ],
            [
              -74.607348,
              41.317774
            ],
            [
              -74.641544,
              41.332879
            ],
            [
              -74.694914,
              41.357423
            ],
            [
              -74.700595,
              41.354553
            ],
            [
              -74.704429,
              41.354043
            ],
            [
              -74.708514,
              41.352734
            ],
            [
              -74.720923,
              41.347384
            ],
            [
              -74.730373,
              41.345983
            ],
            [
              -74.735622,
              41.346518
            ],
            [
              -74.753239,
              41.346122
            ],
            [
              -74.755971,
              41.344953
            ],
            [
              -74.760325,
              41.340325
            ],
            [
              -74.763499,
              41.331568
            ],
            [
              -74.766714,
              41.328558
            ],
            [
              -74.771588,
              41.325079
            ],
            [
              -74.774887,
              41.324326
            ],
            [
              -74.781584,
              41.324229
            ],
            [
              -74.789095,
              41.323281
            ],
            [
              -74.792116,
              41.322465
            ],
            [
              -74.79504,
              41.320407
            ],
            [
              -74.795822,
              41.318516
            ],
            [
              -74.792377,
              41.314088
            ],
            [
              -74.791991,
              41.311639
            ],
            [
              -74.792558,
              41.310628
            ],
            [
              -74.806858,
              41.303155
            ],
            [
              -74.812033,
              41.298157
            ],
            [
              -74.815703,
              41.296151
            ],
            [
              -74.821884,
              41.293838
            ],
            [
              -74.830057,
              41.2872
            ],
            [
              -74.834067,
              41.281111
            ],
            [
              -74.838366,
              41.277286
            ],
            [
              -74.8387298902787,
              41.2764578902572
            ],
            [
              -74.841137,
              41.27098
            ],
            [
              -74.846319,
              41.263077
            ],
            [
              -74.846506,
              41.261576
            ],
            [
              -74.845031,
              41.258055
            ],
            [
              -74.845883,
              41.254945
            ],
            [
              -74.846932,
              41.253318
            ],
            [
              -74.848987,
              41.251192
            ],
            [
              -74.854669,
              41.25051
            ],
            [
              -74.856003,
              41.250094
            ],
            [
              -74.857151,
              41.248975
            ],
            [
              -74.861678,
              41.241575
            ],
            [
              -74.862049,
              41.237609
            ],
            [
              -74.866182,
              41.232132
            ],
            [
              -74.867405,
              41.22777
            ],
            [
              -74.866839,
              41.226865
            ],
            [
              -74.860837,
              41.222317
            ],
            [
              -74.859323,
              41.220507
            ],
            [
              -74.859632,
              41.219077
            ],
            [
              -74.860398,
              41.217454
            ],
            [
              -74.867287,
              41.208754
            ],
            [
              -74.874034,
              41.198543
            ],
            [
              -74.878275,
              41.190489
            ],
            [
              -74.878492,
              41.187504
            ],
            [
              -74.882139,
              41.180836
            ],
            [
              -74.889424,
              41.1736
            ],
            [
              -74.899701,
              41.166181
            ],
            [
              -74.901172,
              41.16387
            ],
            [
              -74.90178,
              41.161394
            ],
            [
              -74.905256,
              41.155668
            ],
            [
              -74.923169,
              41.138146
            ],
            [
              -74.931141,
              41.133387
            ],
            [
              -74.945067,
              41.129052
            ],
            [
              -74.947714,
              41.126292
            ],
            [
              -74.947334,
              41.124439
            ],
            [
              -74.947912,
              41.12356
            ],
            [
              -74.964294,
              41.114237
            ],
            [
              -74.966298,
              41.113669
            ],
            [
              -74.969312,
              41.113869
            ],
            [
              -74.972917,
              41.113327
            ],
            [
              -74.979873,
              41.110423
            ],
            [
              -74.982212,
              41.108245
            ],
            [
              -74.991718,
              41.092284
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "34",
      "COUNTYFP": "039",
      "COUNTYNS": "00882235",
      "AFFGEOID": "0500000US34039",
      "GEOID": "34039",
      "NAME": "Union",
      "LSAD": "06",
      "ALAND": 266170662,
      "AWATER": 7046286,
      "County_state": "Union,34"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -74.463291,
              40.599187
            ],
            [
              -74.455132,
              40.595954
            ],
            [
              -74.437067,
              40.597468
            ],
            [
              -74.358717,
              40.604061
            ],
            [
              -74.338472,
              40.605735
            ],
            [
              -74.302921,
              40.608683
            ],
            [
              -74.305326,
              40.600586
            ],
            [
              -74.29099,
              40.591925
            ],
            [
              -74.214457,
              40.604141
            ],
            [
              -74.203688,
              40.592691
            ],
            [
              -74.199421,
              40.59988
            ],
            [
              -74.203759,
              40.606604
            ],
            [
              -74.202434,
              40.61655
            ],
            [
              -74.203485,
              40.625058
            ],
            [
              -74.202247,
              40.630903
            ],
            [
              -74.2004476063965,
              40.6326074142268
            ],
            [
              -74.1971585770214,
              40.6357228351377
            ],
            [
              -74.187754,
              40.644631
            ],
            [
              -74.18139,
              40.646475
            ],
            [
              -74.170611,
              40.645289
            ],
            [
              -74.1615257647334,
              40.64424617068519
            ],
            [
              -74.1614075238905,
              40.6442325986627
            ],
            [
              -74.142516,
              40.667515
            ],
            [
              -74.136703,
              40.674444
            ],
            [
              -74.162389,
              40.689613
            ],
            [
              -74.209248,
              40.690709
            ],
            [
              -74.212972,
              40.70081
            ],
            [
              -74.221949,
              40.707341
            ],
            [
              -74.256022,
              40.713418
            ],
            [
              -74.285187,
              40.722152
            ],
            [
              -74.297212,
              40.715189
            ],
            [
              -74.303523,
              40.713188
            ],
            [
              -74.325596,
              40.717075
            ],
            [
              -74.371738,
              40.73964
            ],
            [
              -74.384266,
              40.724492
            ],
            [
              -74.390776,
              40.725388
            ],
            [
              -74.390747,
              40.718446
            ],
            [
              -74.443762,
              40.688038
            ],
            [
              -74.453949,
              40.674274
            ],
            [
              -74.461035,
              40.673504
            ],
            [
              -74.450846,
              40.654148
            ],
            [
              -74.429866,
              40.664993
            ],
            [
              -74.403486,
              40.666657
            ],
            [
              -74.40352,
              40.654656
            ],
            [
              -74.413802,
              40.64101
            ],
            [
              -74.424401,
              40.619545
            ],
            [
              -74.463291,
              40.599187
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "34",
      "COUNTYFP": "027",
      "COUNTYNS": "00882231",
      "AFFGEOID": "0500000US34027",
      "GEOID": "34027",
      "NAME": "Morris",
      "LSAD": "06",
      "ALAND": 1192765238,
      "AWATER": 54376835,
      "County_state": "Morris,34"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -74.850263,
              40.804173
            ],
            [
              -74.889819,
              40.787729
            ],
            [
              -74.82388,
              40.742358
            ],
            [
              -74.797973,
              40.742186
            ],
            [
              -74.724415,
              40.719456
            ],
            [
              -74.657258,
              40.735215
            ],
            [
              -74.631262,
              40.741142
            ],
            [
              -74.556018,
              40.758288
            ],
            [
              -74.550095,
              40.747327
            ],
            [
              -74.549005,
              40.740935
            ],
            [
              -74.530893,
              40.729854
            ],
            [
              -74.513717,
              40.69775
            ],
            [
              -74.529567,
              40.664673
            ],
            [
              -74.523078,
              40.649125
            ],
            [
              -74.461035,
              40.673504
            ],
            [
              -74.453949,
              40.674274
            ],
            [
              -74.443762,
              40.688038
            ],
            [
              -74.390747,
              40.718446
            ],
            [
              -74.390776,
              40.725388
            ],
            [
              -74.384266,
              40.724492
            ],
            [
              -74.371738,
              40.73964
            ],
            [
              -74.364876,
              40.749805
            ],
            [
              -74.357547,
              40.754788
            ],
            [
              -74.367145,
              40.761112
            ],
            [
              -74.37556,
              40.758404
            ],
            [
              -74.367062,
              40.793286
            ],
            [
              -74.337921,
              40.813538
            ],
            [
              -74.329161,
              40.838557
            ],
            [
              -74.329854,
              40.849301
            ],
            [
              -74.336357,
              40.866613
            ],
            [
              -74.326114,
              40.908605
            ],
            [
              -74.272063,
              40.897427
            ],
            [
              -74.272407,
              40.897958
            ],
            [
              -74.266597,
              40.908609
            ],
            [
              -74.282637,
              40.91688
            ],
            [
              -74.282158,
              40.936625
            ],
            [
              -74.27754,
              40.973612
            ],
            [
              -74.297175,
              40.979443
            ],
            [
              -74.300029,
              41.003115
            ],
            [
              -74.301434,
              40.999245
            ],
            [
              -74.328456,
              41.001264
            ],
            [
              -74.350189,
              41.003975
            ],
            [
              -74.362826,
              41.013127
            ],
            [
              -74.38938,
              41.012306
            ],
            [
              -74.406204,
              41.024176
            ],
            [
              -74.42355,
              41.027697
            ],
            [
              -74.43504,
              41.020021
            ],
            [
              -74.427768,
              41.037104
            ],
            [
              -74.447069,
              41.054423
            ],
            [
              -74.481311,
              41.045509
            ],
            [
              -74.497777,
              41.034261
            ],
            [
              -74.501692,
              41.042098
            ],
            [
              -74.486925,
              41.076736
            ],
            [
              -74.503212,
              41.085873
            ],
            [
              -74.535808,
              41.058486
            ],
            [
              -74.614605,
              40.990676
            ],
            [
              -74.641044,
              40.964426
            ],
            [
              -74.636163,
              40.942639
            ],
            [
              -74.65303,
              40.921623
            ],
            [
              -74.71134,
              40.89918
            ],
            [
              -74.713482,
              40.902306
            ],
            [
              -74.731994,
              40.920817
            ],
            [
              -74.769425,
              40.910934
            ],
            [
              -74.779094,
              40.906348
            ],
            [
              -74.806776,
              40.882082
            ],
            [
              -74.802003,
              40.863732
            ],
            [
              -74.821655,
              40.848498
            ],
            [
              -74.818434,
              40.836946
            ],
            [
              -74.850263,
              40.804173
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "34",
      "COUNTYFP": "007",
      "COUNTYNS": "00882273",
      "AFFGEOID": "0500000US34007",
      "GEOID": "34007",
      "NAME": "Camden",
      "LSAD": "06",
      "ALAND": 573281541,
      "AWATER": 15731653,
      "County_state": "Camden,34"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -75.13342,
              39.896213
            ],
            [
              -75.1400064847137,
              39.888465333817194
            ],
            [
              -75.118844,
              39.862761
            ],
            [
              -75.083855,
              39.840502
            ],
            [
              -75.088753,
              39.814417
            ],
            [
              -75.075769,
              39.804961
            ],
            [
              -75.075362,
              39.804919
            ],
            [
              -75.07523,
              39.804632
            ],
            [
              -75.074328,
              39.803927
            ],
            [
              -75.073884,
              39.803098
            ],
            [
              -75.073683,
              39.803041
            ],
            [
              -75.073995,
              39.802343
            ],
            [
              -75.073792,
              39.802187
            ],
            [
              -75.074333,
              39.802056
            ],
            [
              -75.075048,
              39.801395
            ],
            [
              -75.075342,
              39.800621
            ],
            [
              -75.075923,
              39.799437
            ],
            [
              -75.076425,
              39.79894
            ],
            [
              -75.076491,
              39.798422
            ],
            [
              -75.074979,
              39.797374
            ],
            [
              -75.074891,
              39.797523
            ],
            [
              -75.074668,
              39.797067
            ],
            [
              -75.074809,
              39.796772
            ],
            [
              -75.074534,
              39.795585
            ],
            [
              -75.0745,
              39.795211
            ],
            [
              -75.074317,
              39.794902
            ],
            [
              -75.073225,
              39.794683
            ],
            [
              -75.072916,
              39.794788
            ],
            [
              -75.072422,
              39.794448
            ],
            [
              -75.07162,
              39.79422
            ],
            [
              -75.07082,
              39.793888
            ],
            [
              -75.070491,
              39.793597
            ],
            [
              -75.08707,
              39.779576
            ],
            [
              -75.072486,
              39.774315
            ],
            [
              -75.063547,
              39.785927
            ],
            [
              -75.049325,
              39.778478
            ],
            [
              -75.044397,
              39.762147
            ],
            [
              -75.022159,
              39.746397
            ],
            [
              -75.024252,
              39.730582
            ],
            [
              -74.99848,
              39.719205
            ],
            [
              -74.987551,
              39.706701
            ],
            [
              -74.943677,
              39.698361
            ],
            [
              -74.927134,
              39.687461
            ],
            [
              -74.90886,
              39.665302
            ],
            [
              -74.902725,
              39.631879
            ],
            [
              -74.87726,
              39.608352
            ],
            [
              -74.859173,
              39.623958
            ],
            [
              -74.800087,
              39.674864
            ],
            [
              -74.736216,
              39.729775
            ],
            [
              -74.77209,
              39.750436
            ],
            [
              -74.790724,
              39.771303
            ],
            [
              -74.819419,
              39.788001
            ],
            [
              -74.843208,
              39.789919
            ],
            [
              -74.876185,
              39.781459
            ],
            [
              -74.902368,
              39.791041
            ],
            [
              -74.931071,
              39.885975
            ],
            [
              -74.957313,
              39.914345
            ],
            [
              -74.962971,
              39.92028
            ],
            [
              -74.984045,
              39.927354
            ],
            [
              -74.98558,
              39.93043
            ],
            [
              -74.975051,
              39.938511
            ],
            [
              -75.004149,
              39.941465
            ],
            [
              -75.015081,
              39.946813
            ],
            [
              -75.013092,
              39.956628
            ],
            [
              -75.0176,
              39.959375
            ],
            [
              -75.019253,
              39.967434
            ],
            [
              -75.010354,
              39.979996
            ],
            [
              -75.030782,
              39.993234
            ],
            [
              -75.036354,
              39.996081
            ],
            [
              -75.0599940905637,
              39.9916175863301
            ],
            [
              -75.072017,
              39.980612
            ],
            [
              -75.088618,
              39.975212
            ],
            [
              -75.0890107861163,
              39.9751503864916
            ],
            [
              -75.093718,
              39.974412
            ],
            [
              -75.09371907941859,
              39.9744116926869
            ],
            [
              -75.1075409360153,
              39.970476576233395
            ],
            [
              -75.108119,
              39.970312
            ],
            [
              -75.11922,
              39.965412
            ],
            [
              -75.1238629772435,
              39.96281916855229
            ],
            [
              -75.12692,
              39.961112
            ],
            [
              -75.13012,
              39.958712
            ],
            [
              -75.13352,
              39.954412
            ],
            [
              -75.13572,
              39.947112
            ],
            [
              -75.13612,
              39.933912
            ],
            [
              -75.13588759459459,
              39.932517567567594
            ],
            [
              -75.13502,
              39.927312
            ],
            [
              -75.13282,
              39.921612
            ],
            [
              -75.13012,
              39.917013
            ],
            [
              -75.12792,
              39.911813
            ],
            [
              -75.13082,
              39.900213
            ],
            [
              -75.13342,
              39.896213
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "34",
      "COUNTYFP": "033",
      "COUNTYNS": "00882233",
      "AFFGEOID": "0500000US34033",
      "GEOID": "34033",
      "NAME": "Salem",
      "LSAD": "06",
      "ALAND": 859474410,
      "AWATER": 105430413,
      "County_state": "Salem,34"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -75.559102,
              39.629056
            ],
            [
              -75.559614,
              39.624208
            ],
            [
              -75.558446,
              39.617296
            ],
            [
              -75.556878,
              39.612144
            ],
            [
              -75.557502,
              39.609184
            ],
            [
              -75.556734,
              39.606688
            ],
            [
              -75.55587,
              39.605824
            ],
            [
              -75.553502,
              39.602
            ],
            [
              -75.545405,
              39.596784
            ],
            [
              -75.543965,
              39.596
            ],
            [
              -75.539949,
              39.594384
            ],
            [
              -75.53954,
              39.594251
            ],
            [
              -75.537213,
              39.592944
            ],
            [
              -75.534477,
              39.590384
            ],
            [
              -75.531133,
              39.587984
            ],
            [
              -75.525677,
              39.584048
            ],
            [
              -75.521596,
              39.583088
            ],
            [
              -75.519628,
              39.583248
            ],
            [
              -75.515228,
              39.580752
            ],
            [
              -75.512732,
              39.578
            ],
            [
              -75.511932,
              39.567616
            ],
            [
              -75.514756,
              39.562612
            ],
            [
              -75.519026,
              39.555401
            ],
            [
              -75.526003,
              39.548488
            ],
            [
              -75.532342,
              39.54328
            ],
            [
              -75.534014,
              39.540702
            ],
            [
              -75.531575,
              39.536825
            ],
            [
              -75.527676,
              39.535278
            ],
            [
              -75.526787,
              39.53144
            ],
            [
              -75.526654,
              39.526638
            ],
            [
              -75.529978,
              39.510817
            ],
            [
              -75.53014,
              39.505373
            ],
            [
              -75.529368,
              39.501229
            ],
            [
              -75.527141,
              39.500112
            ],
            [
              -75.528088,
              39.498114
            ],
            [
              -75.542693,
              39.496568
            ],
            [
              -75.544368,
              39.479602
            ],
            [
              -75.542894,
              39.470447
            ],
            [
              -75.536431,
              39.460559
            ],
            [
              -75.508383,
              39.459131
            ],
            [
              -75.505672,
              39.452927
            ],
            [
              -75.483572,
              39.440824
            ],
            [
              -75.476279,
              39.438126
            ],
            [
              -75.465212,
              39.43893
            ],
            [
              -75.442393,
              39.402291
            ],
            [
              -75.431803,
              39.391625
            ],
            [
              -75.422099,
              39.386521
            ],
            [
              -75.40729390306001,
              39.3819544443261
            ],
            [
              -75.418911,
              39.410417
            ],
            [
              -75.408753,
              39.423785
            ],
            [
              -75.414305,
              39.453054
            ],
            [
              -75.39983,
              39.462271
            ],
            [
              -75.368213,
              39.456479
            ],
            [
              -75.342376,
              39.490622
            ],
            [
              -75.31802,
              39.49204
            ],
            [
              -75.270059,
              39.531236
            ],
            [
              -75.238434,
              39.559039
            ],
            [
              -75.177916,
              39.521061
            ],
            [
              -75.083823,
              39.457354
            ],
            [
              -75.080309,
              39.460648
            ],
            [
              -75.073183,
              39.485375
            ],
            [
              -75.079699,
              39.501434
            ],
            [
              -75.074457,
              39.544564
            ],
            [
              -75.061854,
              39.568746
            ],
            [
              -75.199976,
              39.667033
            ],
            [
              -75.234857,
              39.661619
            ],
            [
              -75.247474,
              39.664486
            ],
            [
              -75.253278,
              39.679026
            ],
            [
              -75.286783,
              39.679807
            ],
            [
              -75.295145,
              39.686154
            ],
            [
              -75.32672,
              39.690372
            ],
            [
              -75.336507,
              39.704175
            ],
            [
              -75.376589,
              39.726421
            ],
            [
              -75.378228,
              39.74587
            ],
            [
              -75.4115142510838,
              39.7941711501703
            ],
            [
              -75.43628802100329,
              39.7851357978497
            ],
            [
              -75.4383,
              39.784402
            ],
            [
              -75.4503558506191,
              39.7753143722085
            ],
            [
              -75.450688,
              39.775064
            ],
            [
              -75.450212,
              39.774761
            ],
            [
              -75.449295,
              39.774436
            ],
            [
              -75.452339,
              39.769013
            ],
            [
              -75.459439,
              39.765813
            ],
            [
              -75.463339,
              39.761213
            ],
            [
              -75.463039,
              39.758313
            ],
            [
              -75.46923823133349,
              39.7436148224833
            ],
            [
              -75.469239,
              39.743613
            ],
            [
              -75.4703433594153,
              39.741789204982595
            ],
            [
              -75.474168,
              39.735473
            ],
            [
              -75.475384,
              39.731057
            ],
            [
              -75.47544,
              39.728713
            ],
            [
              -75.47724,
              39.724713
            ],
            [
              -75.477432,
              39.720561
            ],
            [
              -75.476888,
              39.718337
            ],
            [
              -75.47764,
              39.715013
            ],
            [
              -75.47894,
              39.713813
            ],
            [
              -75.481741,
              39.714546
            ],
            [
              -75.483141,
              39.715513
            ],
            [
              -75.485241,
              39.715813
            ],
            [
              -75.488553,
              39.714833
            ],
            [
              -75.491341,
              39.711113
            ],
            [
              -75.4924016254181,
              39.7090133945805
            ],
            [
              -75.496241,
              39.701413
            ],
            [
              -75.504042,
              39.698313
            ],
            [
              -75.507162,
              39.696961
            ],
            [
              -75.509042,
              39.694513
            ],
            [
              -75.509742,
              39.686113
            ],
            [
              -75.509342,
              39.685313
            ],
            [
              -75.511743,
              39.674313
            ],
            [
              -75.514643,
              39.668613
            ],
            [
              -75.518343,
              39.663913
            ],
            [
              -75.522343,
              39.660813
            ],
            [
              -75.526344,
              39.656413
            ],
            [
              -75.526844,
              39.655713
            ],
            [
              -75.526744,
              39.655113
            ],
            [
              -75.535144,
              39.647212
            ],
            [
              -75.539245,
              39.646112
            ],
            [
              -75.542045,
              39.646012
            ],
            [
              -75.547197,
              39.640528
            ],
            [
              -75.550645,
              39.637912
            ],
            [
              -75.556246,
              39.634912
            ],
            [
              -75.559446,
              39.629812
            ],
            [
              -75.559102,
              39.629056
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "34",
      "COUNTYFP": "015",
      "COUNTYNS": "00882277",
      "AFFGEOID": "0500000US34015",
      "GEOID": "34015",
      "NAME": "Gloucester",
      "LSAD": "06",
      "ALAND": 833862843,
      "AWATER": 38373885,
      "County_state": "Gloucester,34"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -75.415062,
              39.801919
            ],
            [
              -75.405414,
              39.796396
            ],
            [
              -75.4054892080789,
              39.7963685705255
            ],
            [
              -75.4115142510838,
              39.7941711501703
            ],
            [
              -75.378228,
              39.74587
            ],
            [
              -75.376589,
              39.726421
            ],
            [
              -75.336507,
              39.704175
            ],
            [
              -75.32672,
              39.690372
            ],
            [
              -75.295145,
              39.686154
            ],
            [
              -75.286783,
              39.679807
            ],
            [
              -75.253278,
              39.679026
            ],
            [
              -75.247474,
              39.664486
            ],
            [
              -75.234857,
              39.661619
            ],
            [
              -75.199976,
              39.667033
            ],
            [
              -75.061854,
              39.568746
            ],
            [
              -74.985271,
              39.514967
            ],
            [
              -74.911882,
              39.578462
            ],
            [
              -74.87726,
              39.608352
            ],
            [
              -74.902725,
              39.631879
            ],
            [
              -74.90886,
              39.665302
            ],
            [
              -74.927134,
              39.687461
            ],
            [
              -74.943677,
              39.698361
            ],
            [
              -74.987551,
              39.706701
            ],
            [
              -74.99848,
              39.719205
            ],
            [
              -75.024252,
              39.730582
            ],
            [
              -75.022159,
              39.746397
            ],
            [
              -75.044397,
              39.762147
            ],
            [
              -75.049325,
              39.778478
            ],
            [
              -75.063547,
              39.785927
            ],
            [
              -75.072486,
              39.774315
            ],
            [
              -75.08707,
              39.779576
            ],
            [
              -75.070491,
              39.793597
            ],
            [
              -75.07082,
              39.793888
            ],
            [
              -75.07162,
              39.79422
            ],
            [
              -75.072422,
              39.794448
            ],
            [
              -75.072916,
              39.794788
            ],
            [
              -75.073225,
              39.794683
            ],
            [
              -75.074317,
              39.794902
            ],
            [
              -75.0745,
              39.795211
            ],
            [
              -75.074534,
              39.795585
            ],
            [
              -75.074809,
              39.796772
            ],
            [
              -75.074668,
              39.797067
            ],
            [
              -75.074891,
              39.797523
            ],
            [
              -75.074979,
              39.797374
            ],
            [
              -75.076491,
              39.798422
            ],
            [
              -75.076425,
              39.79894
            ],
            [
              -75.075923,
              39.799437
            ],
            [
              -75.075342,
              39.800621
            ],
            [
              -75.075048,
              39.801395
            ],
            [
              -75.074333,
              39.802056
            ],
            [
              -75.073792,
              39.802187
            ],
            [
              -75.073995,
              39.802343
            ],
            [
              -75.073683,
              39.803041
            ],
            [
              -75.073884,
              39.803098
            ],
            [
              -75.074328,
              39.803927
            ],
            [
              -75.07523,
              39.804632
            ],
            [
              -75.075362,
              39.804919
            ],
            [
              -75.075769,
              39.804961
            ],
            [
              -75.088753,
              39.814417
            ],
            [
              -75.083855,
              39.840502
            ],
            [
              -75.118844,
              39.862761
            ],
            [
              -75.1400064847137,
              39.888465333817194
            ],
            [
              -75.140221,
              39.888213
            ],
            [
              -75.142421,
              39.886413
            ],
            [
              -75.14532381843439,
              39.8842842664814
            ],
            [
              -75.145421,
              39.884213
            ],
            [
              -75.150721,
              39.882713
            ],
            [
              -75.17453546617179,
              39.8821969289728
            ],
            [
              -75.183023,
              39.882013
            ],
            [
              -75.189323,
              39.880713
            ],
            [
              -75.195324,
              39.877013
            ],
            [
              -75.210425,
              39.865913
            ],
            [
              -75.2108764002954,
              39.865708592319095
            ],
            [
              -75.221025,
              39.861113
            ],
            [
              -75.235026,
              39.856613
            ],
            [
              -75.243431,
              39.854597
            ],
            [
              -75.271159,
              39.84944
            ],
            [
              -75.293376,
              39.848782
            ],
            [
              -75.309674,
              39.850179
            ],
            [
              -75.3185417987518,
              39.849938958538004
            ],
            [
              -75.323232,
              39.849812
            ],
            [
              -75.330433,
              39.849012
            ],
            [
              -75.341765,
              39.846082
            ],
            [
              -75.3544,
              39.839917
            ],
            [
              -75.371835,
              39.827612
            ],
            [
              -75.3897644561244,
              39.8158194589417
            ],
            [
              -75.390536,
              39.815312
            ],
            [
              -75.403737,
              39.807512
            ],
            [
              -75.415062,
              39.801919
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "34",
      "COUNTYFP": "031",
      "COUNTYNS": "00882232",
      "AFFGEOID": "0500000US34031",
      "GEOID": "34031",
      "NAME": "Passaic",
      "LSAD": "06",
      "ALAND": 481744927,
      "AWATER": 32079811,
      "County_state": "Passaic,34"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -74.503212,
              41.085873
            ],
            [
              -74.486925,
              41.076736
            ],
            [
              -74.501692,
              41.042098
            ],
            [
              -74.497777,
              41.034261
            ],
            [
              -74.481311,
              41.045509
            ],
            [
              -74.447069,
              41.054423
            ],
            [
              -74.427768,
              41.037104
            ],
            [
              -74.43504,
              41.020021
            ],
            [
              -74.42355,
              41.027697
            ],
            [
              -74.406204,
              41.024176
            ],
            [
              -74.38938,
              41.012306
            ],
            [
              -74.362826,
              41.013127
            ],
            [
              -74.350189,
              41.003975
            ],
            [
              -74.328456,
              41.001264
            ],
            [
              -74.301434,
              40.999245
            ],
            [
              -74.300029,
              41.003115
            ],
            [
              -74.297175,
              40.979443
            ],
            [
              -74.27754,
              40.973612
            ],
            [
              -74.282158,
              40.936625
            ],
            [
              -74.282637,
              40.91688
            ],
            [
              -74.266597,
              40.908609
            ],
            [
              -74.272407,
              40.897958
            ],
            [
              -74.272063,
              40.897427
            ],
            [
              -74.270702,
              40.89692
            ],
            [
              -74.259635,
              40.883768
            ],
            [
              -74.246778,
              40.880988
            ],
            [
              -74.24029,
              40.880436
            ],
            [
              -74.205196,
              40.861035
            ],
            [
              -74.197872,
              40.857053
            ],
            [
              -74.182662,
              40.848784
            ],
            [
              -74.130016,
              40.819938
            ],
            [
              -74.123516,
              40.823049
            ],
            [
              -74.121683,
              40.834056
            ],
            [
              -74.120821,
              40.858255
            ],
            [
              -74.107481,
              40.863219
            ],
            [
              -74.118078,
              40.876768
            ],
            [
              -74.130347,
              40.885641
            ],
            [
              -74.128481,
              40.894133
            ],
            [
              -74.132823,
              40.897074
            ],
            [
              -74.132337,
              40.92243
            ],
            [
              -74.139749,
              40.92365
            ],
            [
              -74.148071,
              40.941643
            ],
            [
              -74.146514,
              40.948183
            ],
            [
              -74.141048,
              40.966348
            ],
            [
              -74.170275,
              40.983904
            ],
            [
              -74.171141,
              40.977066
            ],
            [
              -74.211933,
              40.980167
            ],
            [
              -74.224533,
              40.981303
            ],
            [
              -74.248532,
              40.992094
            ],
            [
              -74.269934,
              41.016206
            ],
            [
              -74.270658,
              41.021033
            ],
            [
              -74.250458,
              41.060816
            ],
            [
              -74.2113709734917,
              41.1334404575138
            ],
            [
              -74.2343582601916,
              41.142836102143896
            ],
            [
              -74.234473,
              41.142883
            ],
            [
              -74.28008919002339,
              41.1629553126403
            ],
            [
              -74.301994,
              41.172594
            ],
            [
              -74.320995,
              41.182394
            ],
            [
              -74.367312185113,
              41.2036716043384
            ],
            [
              -74.422315,
              41.160383
            ],
            [
              -74.503212,
              41.085873
            ]
          ]
        ]
      ]
    }
  }
]
};