export const counties_nh = {
  "type":"FeatureCollection","name":"nh_counties","features":[
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "33",
      "COUNTYFP": "007",
      "COUNTYNS": "00873177",
      "AFFGEOID": "0500000US33007",
      "GEOID": "33007",
      "NAME": "Coos",
      "LSAD": "06",
      "ALAND": 4647904964,
      "AWATER": 92563568,
      "County_state": "Coos,33"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -71.764977,
              44.406587
            ],
            [
              -71.76568897743229,
              44.406307687658
            ],
            [
              -71.696885,
              44.332118
            ],
            [
              -71.686921,
              44.336581
            ],
            [
              -71.587004,
              44.304759
            ],
            [
              -71.575819,
              44.254596
            ],
            [
              -71.507412,
              44.262897
            ],
            [
              -71.50584,
              44.255557
            ],
            [
              -71.469706,
              44.259936
            ],
            [
              -71.443338,
              44.249172
            ],
            [
              -71.415898,
              44.212169
            ],
            [
              -71.394874,
              44.214679
            ],
            [
              -71.384239,
              44.178844
            ],
            [
              -71.356482,
              44.165836
            ],
            [
              -71.343238,
              44.123387
            ],
            [
              -71.343588,
              44.092054
            ],
            [
              -71.335882,
              44.085752
            ],
            [
              -71.311758,
              44.091756
            ],
            [
              -71.30245,
              44.137402
            ],
            [
              -71.251957,
              44.244266
            ],
            [
              -71.098434,
              44.235454
            ],
            [
              -71.04228,
              44.237514
            ],
            [
              -71.044385,
              44.283778
            ],
            [
              -71.0102711678683,
              44.284888321572005
            ],
            [
              -71.01127,
              44.301846
            ],
            [
              -71.012749,
              44.340784
            ],
            [
              -71.015363,
              44.378113
            ],
            [
              -71.020773,
              44.473737
            ],
            [
              -71.022992,
              44.500058
            ],
            [
              -71.026655,
              44.558149
            ],
            [
              -71.0290473414933,
              44.6112487220218
            ],
            [
              -71.031039,
              44.655455
            ],
            [
              -71.036705,
              44.736498
            ],
            [
              -71.037518,
              44.755607
            ],
            [
              -71.044646,
              44.845039
            ],
            [
              -71.052707,
              44.93084
            ],
            [
              -71.057861,
              45.000049
            ],
            [
              -71.059004,
              45.004918
            ],
            [
              -71.069387,
              45.144037
            ],
            [
              -71.0726,
              45.180935
            ],
            [
              -71.076914,
              45.246912
            ],
            [
              -71.083924,
              45.305451
            ],
            [
              -71.085564,
              45.305476
            ],
            [
              -71.089483,
              45.304584
            ],
            [
              -71.097772,
              45.301906
            ],
            [
              -71.105151,
              45.294635
            ],
            [
              -71.108675,
              45.288965
            ],
            [
              -71.110743,
              45.284576
            ],
            [
              -71.110159,
              45.282999
            ],
            [
              -71.109349,
              45.282222
            ],
            [
              -71.105691,
              45.282498
            ],
            [
              -71.107339,
              45.278612
            ],
            [
              -71.110735,
              45.275456
            ],
            [
              -71.113162,
              45.274861
            ],
            [
              -71.116332,
              45.272322
            ],
            [
              -71.120112,
              45.265738
            ],
            [
              -71.119914,
              45.262287
            ],
            [
              -71.124517,
              45.25527
            ],
            [
              -71.127962,
              45.253672
            ],
            [
              -71.131953,
              45.245423
            ],
            [
              -71.133994,
              45.244167
            ],
            [
              -71.13943,
              45.242958
            ],
            [
              -71.144801,
              45.242251
            ],
            [
              -71.148165,
              45.242412
            ],
            [
              -71.155734,
              45.24646
            ],
            [
              -71.158192,
              45.248746
            ],
            [
              -71.162845,
              45.250332
            ],
            [
              -71.167446,
              45.247491
            ],
            [
              -71.171264,
              45.246143
            ],
            [
              -71.173367,
              45.246348
            ],
            [
              -71.177508,
              45.243629
            ],
            [
              -71.178576,
              45.241389
            ],
            [
              -71.179838,
              45.240178
            ],
            [
              -71.180905,
              45.239858
            ],
            [
              -71.182587,
              45.241069
            ],
            [
              -71.182782,
              45.242715
            ],
            [
              -71.183785,
              45.244932
            ],
            [
              -71.187441,
              45.247697
            ],
            [
              -71.191744,
              45.249694
            ],
            [
              -71.194878,
              45.250515
            ],
            [
              -71.196658,
              45.253594
            ],
            [
              -71.198276,
              45.254257
            ],
            [
              -71.203033,
              45.254302
            ],
            [
              -71.2118,
              45.250457
            ],
            [
              -71.220634,
              45.251121
            ],
            [
              -71.221994,
              45.253543
            ],
            [
              -71.225811,
              45.251691
            ],
            [
              -71.228048,
              45.24969
            ],
            [
              -71.231122,
              45.249712
            ],
            [
              -71.233027,
              45.251551
            ],
            [
              -71.231572,
              45.253472
            ],
            [
              -71.235364,
              45.260349
            ],
            [
              -71.236271,
              45.261126
            ],
            [
              -71.239346,
              45.261925
            ],
            [
              -71.244499,
              45.268139
            ],
            [
              -71.245503,
              45.26887
            ],
            [
              -71.250393,
              45.269191
            ],
            [
              -71.256441,
              45.27333
            ],
            [
              -71.259614,
              45.27324
            ],
            [
              -71.262136,
              45.276098
            ],
            [
              -71.263042,
              45.277401
            ],
            [
              -71.263815,
              45.282726
            ],
            [
              -71.266754,
              45.29123
            ],
            [
              -71.264939,
              45.293446
            ],
            [
              -71.266557,
              45.294589
            ],
            [
              -71.27232,
              45.296694
            ],
            [
              -71.279219,
              45.294982
            ],
            [
              -71.28074,
              45.295188
            ],
            [
              -71.282747,
              45.297497
            ],
            [
              -71.282552,
              45.299279
            ],
            [
              -71.283684,
              45.301977
            ],
            [
              -71.284396,
              45.302434
            ],
            [
              -71.296509,
              45.29919
            ],
            [
              -71.301107,
              45.296563
            ],
            [
              -71.304896,
              45.292449
            ],
            [
              -71.306872,
              45.288861
            ],
            [
              -71.309008,
              45.287238
            ],
            [
              -71.314318,
              45.287033
            ],
            [
              -71.318397,
              45.284655
            ],
            [
              -71.320922,
              45.282324
            ],
            [
              -71.328464,
              45.281135
            ],
            [
              -71.331733,
              45.279969
            ],
            [
              -71.333674,
              45.276769
            ],
            [
              -71.333965,
              45.275306
            ],
            [
              -71.336392,
              45.273066
            ],
            [
              -71.344029,
              45.271167
            ],
            [
              -71.347622,
              45.272125
            ],
            [
              -71.351666,
              45.269199
            ],
            [
              -71.353446,
              45.268695
            ],
            [
              -71.354999,
              45.268626
            ],
            [
              -71.360664,
              45.269835
            ],
            [
              -71.362831,
              45.267617
            ],
            [
              -71.363218,
              45.266429
            ],
            [
              -71.362245,
              45.264738
            ],
            [
              -71.359558,
              45.262682
            ],
            [
              -71.356835,
              45.257175
            ],
            [
              -71.356736,
              45.254776
            ],
            [
              -71.357253,
              45.253336
            ],
            [
              -71.359224,
              45.250912
            ],
            [
              -71.363013,
              45.248205
            ],
            [
              -71.368381,
              45.246579
            ],
            [
              -71.37194,
              45.246349
            ],
            [
              -71.376691,
              45.244911
            ],
            [
              -71.37763,
              45.244203
            ],
            [
              -71.379833,
              45.240616
            ],
            [
              -71.380158,
              45.238857
            ],
            [
              -71.38317,
              45.234904
            ],
            [
              -71.384917,
              45.233351
            ],
            [
              -71.385629,
              45.233214
            ],
            [
              -71.389412,
              45.235021
            ],
            [
              -71.391901,
              45.237216
            ],
            [
              -71.394422,
              45.241216
            ],
            [
              -71.402638,
              45.242589
            ],
            [
              -71.406973,
              45.241516
            ],
            [
              -71.411924,
              45.23882
            ],
            [
              -71.417132,
              45.235575
            ],
            [
              -71.433014,
              45.237656
            ],
            [
              -71.438546,
              45.239004
            ],
            [
              -71.442298,
              45.238547
            ],
            [
              -71.443365,
              45.237724
            ],
            [
              -71.443883,
              45.237061
            ],
            [
              -71.443882,
              45.235462
            ],
            [
              -71.44288,
              45.234799
            ],
            [
              -71.440583,
              45.234982
            ],
            [
              -71.438545,
              45.232765
            ],
            [
              -71.431753,
              45.228743
            ],
            [
              -71.421372,
              45.224036
            ],
            [
              -71.417233,
              45.221293
            ],
            [
              -71.416167,
              45.219533
            ],
            [
              -71.41607,
              45.218322
            ],
            [
              -71.415553,
              45.218001
            ],
            [
              -71.409119,
              45.216606
            ],
            [
              -71.407405,
              45.216812
            ],
            [
              -71.403267,
              45.215348
            ],
            [
              -71.3991,
              45.208628
            ],
            [
              -71.398002,
              45.205953
            ],
            [
              -71.39781,
              45.203553
            ],
            [
              -71.405636,
              45.198139
            ],
            [
              -71.405346,
              45.196037
            ],
            [
              -71.408777,
              45.18797
            ],
            [
              -71.412946,
              45.186279
            ],
            [
              -71.414853,
              45.184908
            ],
            [
              -71.415468,
              45.183309
            ],
            [
              -71.416181,
              45.177344
            ],
            [
              -71.419058,
              45.170488
            ],
            [
              -71.422031,
              45.167495
            ],
            [
              -71.424616,
              45.165872
            ],
            [
              -71.423616,
              45.161096
            ],
            [
              -71.424004,
              45.15929
            ],
            [
              -71.42675,
              45.153257
            ],
            [
              -71.427688,
              45.152251
            ],
            [
              -71.429981,
              45.151474
            ],
            [
              -71.433179,
              45.149166
            ],
            [
              -71.437216,
              45.142333
            ],
            [
              -71.435795,
              45.139659
            ],
            [
              -71.433083,
              45.137899
            ],
            [
              -71.426755,
              45.129672
            ],
            [
              -71.427208,
              45.127364
            ],
            [
              -71.428828,
              45.123881
            ],
            [
              -71.432444,
              45.120453
            ],
            [
              -71.440577,
              45.114464
            ],
            [
              -71.445613,
              45.113367
            ],
            [
              -71.448678,
              45.109001
            ],
            [
              -71.448355,
              45.107882
            ],
            [
              -71.449257,
              45.104522
            ],
            [
              -71.452451,
              45.102282
            ],
            [
              -71.455452,
              45.101458
            ],
            [
              -71.458323,
              45.098807
            ],
            [
              -71.459999,
              45.095058
            ],
            [
              -71.462063,
              45.093526
            ],
            [
              -71.464837,
              45.093023
            ],
            [
              -71.467447,
              45.086851
            ],
            [
              -71.471382,
              45.084199
            ],
            [
              -71.475286,
              45.084426
            ],
            [
              -71.480769,
              45.083556
            ],
            [
              -71.480897,
              45.08303
            ],
            [
              -71.479929,
              45.082139
            ],
            [
              -71.480219,
              45.081316
            ],
            [
              -71.482862,
              45.079144
            ],
            [
              -71.486345,
              45.078503
            ],
            [
              -71.489146,
              45.073565
            ],
            [
              -71.489145,
              45.072308
            ],
            [
              -71.497917,
              45.070589
            ],
            [
              -71.498399,
              45.069629
            ],
            [
              -71.496105,
              45.065082
            ],
            [
              -71.497738,
              45.054751
            ],
            [
              -71.500545,
              45.051943
            ],
            [
              -71.502768,
              45.05263
            ],
            [
              -71.504542,
              45.052379
            ],
            [
              -71.505091,
              45.051465
            ],
            [
              -71.505222,
              45.048791
            ],
            [
              -71.500874,
              45.04511
            ],
            [
              -71.499527,
              45.044672
            ],
            [
              -71.497179,
              45.044582
            ],
            [
              -71.49315,
              45.045772
            ],
            [
              -71.491085,
              45.043671
            ],
            [
              -71.491148,
              45.041774
            ],
            [
              -71.494009,
              45.034345
            ],
            [
              -71.499945,
              45.026323
            ],
            [
              -71.500969,
              45.01604
            ],
            [
              -71.500164,
              45.015377
            ],
            [
              -71.500069,
              45.014212
            ],
            [
              -71.501088,
              45.013377
            ],
            [
              -71.465039,
              45.013638
            ],
            [
              -71.466247,
              45.011959
            ],
            [
              -71.479611,
              45.002905
            ],
            [
              -71.487565,
              45.000936
            ],
            [
              -71.497412,
              45.003878
            ],
            [
              -71.514609,
              45.003957
            ],
            [
              -71.520022,
              45.002291
            ],
            [
              -71.525016,
              45.001881
            ],
            [
              -71.530091,
              44.999656
            ],
            [
              -71.53698,
              44.994177
            ],
            [
              -71.538592,
              44.988182
            ],
            [
              -71.537784,
              44.984298
            ],
            [
              -71.531605,
              44.976023
            ],
            [
              -71.527163,
              44.973668
            ],
            [
              -71.52237,
              44.966308
            ],
            [
              -71.516223,
              44.964569
            ],
            [
              -71.514843,
              44.958741
            ],
            [
              -71.516814,
              44.947588
            ],
            [
              -71.515498,
              44.94352
            ],
            [
              -71.516144,
              44.940846
            ],
            [
              -71.516949,
              44.939704
            ],
            [
              -71.515189,
              44.927317
            ],
            [
              -71.509207,
              44.923429
            ],
            [
              -71.504483,
              44.919062
            ],
            [
              -71.500788,
              44.914535
            ],
            [
              -71.494403,
              44.911837
            ],
            [
              -71.49392,
              44.910923
            ],
            [
              -71.495844,
              44.90498
            ],
            [
              -71.496968,
              44.904225
            ],
            [
              -71.499528,
              44.904774
            ],
            [
              -71.501088,
              44.904433
            ],
            [
              -71.502473,
              44.90272
            ],
            [
              -71.508642,
              44.897703
            ],
            [
              -71.51387,
              44.894648
            ],
            [
              -71.51435,
              44.893964
            ],
            [
              -71.51409,
              44.893149
            ],
            [
              -71.511712,
              44.891571
            ],
            [
              -71.512292,
              44.890246
            ],
            [
              -71.522393,
              44.880811
            ],
            [
              -71.526638,
              44.879098
            ],
            [
              -71.528342,
              44.877819
            ],
            [
              -71.528889,
              44.876928
            ],
            [
              -71.529154,
              44.873559
            ],
            [
              -71.534588,
              44.869698
            ],
            [
              -71.540116,
              44.868625
            ],
            [
              -71.545901,
              44.866134
            ],
            [
              -71.549533,
              44.862592
            ],
            [
              -71.550176,
              44.861609
            ],
            [
              -71.550304,
              44.859552
            ],
            [
              -71.548377,
              44.857016
            ],
            [
              -71.548345,
              44.85553
            ],
            [
              -71.553656,
              44.852123
            ],
            [
              -71.5556,
              44.850547
            ],
            [
              -71.556805,
              44.848808
            ],
            [
              -71.55675,
              44.846862
            ],
            [
              -71.555036,
              44.845733
            ],
            [
              -71.552654,
              44.842049
            ],
            [
              -71.552005,
              44.839208
            ],
            [
              -71.552218,
              44.837775
            ],
            [
              -71.553712,
              44.836065
            ],
            [
              -71.557672,
              44.834421
            ],
            [
              -71.562256,
              44.824632
            ],
            [
              -71.563701,
              44.823901
            ],
            [
              -71.56476,
              44.823901
            ],
            [
              -71.565146,
              44.824678
            ],
            [
              -71.567907,
              44.823832
            ],
            [
              -71.574314,
              44.818079
            ],
            [
              -71.5755,
              44.816058
            ],
            [
              -71.575139,
              44.813565
            ],
            [
              -71.572864,
              44.810383
            ],
            [
              -71.569216,
              44.808813
            ],
            [
              -71.569098,
              44.807044
            ],
            [
              -71.570402,
              44.805276
            ],
            [
              -71.573129,
              44.797947
            ],
            [
              -71.571706,
              44.79483
            ],
            [
              -71.573247,
              44.791882
            ],
            [
              -71.578938,
              44.78607
            ],
            [
              -71.580005,
              44.78548
            ],
            [
              -71.584392,
              44.785733
            ],
            [
              -71.592966,
              44.782776
            ],
            [
              -71.596949,
              44.778987
            ],
            [
              -71.59668,
              44.777416
            ],
            [
              -71.595913,
              44.776272
            ],
            [
              -71.596035,
              44.775422
            ],
            [
              -71.601471,
              44.772067
            ],
            [
              -71.604615,
              44.767738
            ],
            [
              -71.608234,
              44.765658
            ],
            [
              -71.611767,
              44.764345
            ],
            [
              -71.614267,
              44.760622
            ],
            [
              -71.614238,
              44.758664
            ],
            [
              -71.617941,
              44.755883
            ],
            [
              -71.623924,
              44.755135
            ],
            [
              -71.631255,
              44.753253
            ],
            [
              -71.631883,
              44.752463
            ],
            [
              -71.631967,
              44.750333
            ],
            [
              -71.631840844135,
              44.750091274776196
            ],
            [
              -71.631109,
              44.748689
            ],
            [
              -71.626909,
              44.747224
            ],
            [
              -71.62518,
              44.743978
            ],
            [
              -71.625059,
              44.737099
            ],
            [
              -71.625638,
              44.735065
            ],
            [
              -71.625611,
              44.730312
            ],
            [
              -71.624922,
              44.729032
            ],
            [
              -71.623266,
              44.727795
            ],
            [
              -71.622593,
              44.727773
            ],
            [
              -71.619067,
              44.729283
            ],
            [
              -71.617656,
              44.728918
            ],
            [
              -71.617431,
              44.72805
            ],
            [
              -71.618516,
              44.723913
            ],
            [
              -71.618355,
              44.72261
            ],
            [
              -71.613094,
              44.718933
            ],
            [
              -71.604912,
              44.70815
            ],
            [
              -71.59975,
              44.705318
            ],
            [
              -71.599205,
              44.703878
            ],
            [
              -71.600772,
              44.700815
            ],
            [
              -71.600772,
              44.699901
            ],
            [
              -71.600162,
              44.698919
            ],
            [
              -71.598656,
              44.698005
            ],
            [
              -71.596858,
              44.694921
            ],
            [
              -71.598042,
              44.692818
            ],
            [
              -71.596437,
              44.687059
            ],
            [
              -71.594224,
              44.683815
            ],
            [
              -71.594671,
              44.681643
            ],
            [
              -71.5964,
              44.679677
            ],
            [
              -71.596304,
              44.679083
            ],
            [
              -71.590024,
              44.675543
            ],
            [
              -71.587365,
              44.674926
            ],
            [
              -71.583009,
              44.674836
            ],
            [
              -71.581983,
              44.673533
            ],
            [
              -71.582527,
              44.672253
            ],
            [
              -71.584478,
              44.670211
            ],
            [
              -71.585645,
              44.669277
            ],
            [
              -71.585645,
              44.667644
            ],
            [
              -71.584574,
              44.665351
            ],
            [
              -71.585246,
              44.663523
            ],
            [
              -71.586578,
              44.661111
            ],
            [
              -71.586578,
              44.659478
            ],
            [
              -71.584848,
              44.657816
            ],
            [
              -71.582965,
              44.656621
            ],
            [
              -71.576013,
              44.655691
            ],
            [
              -71.57571,
              44.654574
            ],
            [
              -71.576312,
              44.653179
            ],
            [
              -71.576079,
              44.652012
            ],
            [
              -71.575145,
              44.650612
            ],
            [
              -71.572163,
              44.650373
            ],
            [
              -71.570235,
              44.650483
            ],
            [
              -71.568677,
              44.651537
            ],
            [
              -71.567645,
              44.65356
            ],
            [
              -71.566144,
              44.653863
            ],
            [
              -71.564411,
              44.652827
            ],
            [
              -71.561772,
              44.650224
            ],
            [
              -71.558571,
              44.644373
            ],
            [
              -71.55805008432729,
              44.6419051022624
            ],
            [
              -71.558026,
              44.641791
            ],
            [
              -71.558859,
              44.640122
            ],
            [
              -71.562636,
              44.639505
            ],
            [
              -71.562636,
              44.637266
            ],
            [
              -71.562124,
              44.63658
            ],
            [
              -71.554634,
              44.632197
            ],
            [
              -71.551722,
              44.627598
            ],
            [
              -71.553156,
              44.626645
            ],
            [
              -71.553898,
              44.62541
            ],
            [
              -71.554666,
              44.625387
            ],
            [
              -71.55576,
              44.624119
            ],
            [
              -71.55656,
              44.616988
            ],
            [
              -71.554097,
              44.609583
            ],
            [
              -71.553873,
              44.607069
            ],
            [
              -71.554833,
              44.605172
            ],
            [
              -71.555781,
              44.603483
            ],
            [
              -71.556014,
              44.601383
            ],
            [
              -71.554449,
              44.598408
            ],
            [
              -71.554614,
              44.595784
            ],
            [
              -71.553447,
              44.593451
            ],
            [
              -71.549268,
              44.593174
            ],
            [
              -71.540601,
              44.590453
            ],
            [
              -71.536251,
              44.588441
            ],
            [
              -71.537724,
              44.584785
            ],
            [
              -71.540123,
              44.582522
            ],
            [
              -71.544922,
              44.579278
            ],
            [
              -71.547448,
              44.578547
            ],
            [
              -71.54927,
              44.579164
            ],
            [
              -71.551145,
              44.580405
            ],
            [
              -71.5532,
              44.580683
            ],
            [
              -71.555483,
              44.578171
            ],
            [
              -71.558635,
              44.570738
            ],
            [
              -71.558985,
              44.568779
            ],
            [
              -71.558565,
              44.565572
            ],
            [
              -71.559846,
              44.564119
            ],
            [
              -71.563399,
              44.563218
            ],
            [
              -71.569599,
              44.562777
            ],
            [
              -71.575519,
              44.564775
            ],
            [
              -71.59017,
              44.565694
            ],
            [
              -71.592091,
              44.565118
            ],
            [
              -71.593923,
              44.563813
            ],
            [
              -71.596137,
              44.560898
            ],
            [
              -71.597797,
              44.557172
            ],
            [
              -71.598116,
              44.555412
            ],
            [
              -71.596804,
              44.553424
            ],
            [
              -71.588076,
              44.54785
            ],
            [
              -71.575193,
              44.540859
            ],
            [
              -71.573083,
              44.53798
            ],
            [
              -71.573019,
              44.536312
            ],
            [
              -71.574456,
              44.53366
            ],
            [
              -71.576884,
              44.530323
            ],
            [
              -71.582505,
              44.524403
            ],
            [
              -71.585731,
              44.522665
            ],
            [
              -71.587104,
              44.522436
            ],
            [
              -71.592855,
              44.523006
            ],
            [
              -71.594259,
              44.52168
            ],
            [
              -71.593971,
              44.519738
            ],
            [
              -71.592117,
              44.517773
            ],
            [
              -71.586909,
              44.514666
            ],
            [
              -71.58595,
              44.513432
            ],
            [
              -71.584959,
              44.510141
            ],
            [
              -71.583233,
              44.508268
            ],
            [
              -71.577771,
              44.504886
            ],
            [
              -71.577068,
              44.504041
            ],
            [
              -71.577643,
              44.502692
            ],
            [
              -71.57876,
              44.501915
            ],
            [
              -71.579974,
              44.501778
            ],
            [
              -71.585241,
              44.501309
            ],
            [
              -71.58841,
              44.500482
            ],
            [
              -71.591502,
              44.499655
            ],
            [
              -71.595236,
              44.49673
            ],
            [
              -71.595484,
              44.494424
            ],
            [
              -71.597917,
              44.488375
            ],
            [
              -71.59948,
              44.486455
            ],
            [
              -71.609568,
              44.484348
            ],
            [
              -71.615923,
              44.485944
            ],
            [
              -71.617614,
              44.485715
            ],
            [
              -71.631007,
              44.484323
            ],
            [
              -71.632795,
              44.48389
            ],
            [
              -71.639312,
              44.477836
            ],
            [
              -71.643111,
              44.476649
            ],
            [
              -71.64589,
              44.475141
            ],
            [
              -71.647693,
              44.473125
            ],
            [
              -71.648178,
              44.472023
            ],
            [
              -71.647864,
              44.469976
            ],
            [
              -71.646551,
              44.468869
            ],
            [
              -71.640847,
              44.465935
            ],
            [
              -71.640404,
              44.464186
            ],
            [
              -71.642851,
              44.461734
            ],
            [
              -71.645068,
              44.460545
            ],
            [
              -71.65232,
              44.461117
            ],
            [
              -71.653348,
              44.460499
            ],
            [
              -71.657313,
              44.454003
            ],
            [
              -71.659021,
              44.444932
            ],
            [
              -71.66183,
              44.440293
            ],
            [
              -71.664191,
              44.438351
            ],
            [
              -71.668944,
              44.436523
            ],
            [
              -71.677384,
              44.435702
            ],
            [
              -71.679263,
              44.435018
            ],
            [
              -71.679933,
              44.434062
            ],
            [
              -71.679158,
              44.432174
            ],
            [
              -71.67995,
              44.427908
            ],
            [
              -71.68585,
              44.423405
            ],
            [
              -71.69092,
              44.421234
            ],
            [
              -71.699434,
              44.416069
            ],
            [
              -71.708041,
              44.411977
            ],
            [
              -71.715087,
              44.41049
            ],
            [
              -71.726199,
              44.411385
            ],
            [
              -71.73152,
              44.411015
            ],
            [
              -71.735923,
              44.410062
            ],
            [
              -71.737836,
              44.408921
            ],
            [
              -71.739921,
              44.406778
            ],
            [
              -71.742308,
              44.402366
            ],
            [
              -71.743104,
              44.401657
            ],
            [
              -71.745011,
              44.401359
            ],
            [
              -71.749533,
              44.401955
            ],
            [
              -71.75434,
              44.405577
            ],
            [
              -71.756091,
              44.406401
            ],
            [
              -71.761966,
              44.407027
            ],
            [
              -71.764977,
              44.406587
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "33",
      "COUNTYFP": "001",
      "COUNTYNS": "00873174",
      "AFFGEOID": "0500000US33001",
      "GEOID": "33001",
      "NAME": "Belknap",
      "LSAD": "06",
      "ALAND": 1040130767,
      "AWATER": 177190123,
      "County_state": "Belknap,33"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -71.731707,
              43.59006
            ],
            [
              -71.716066,
              43.578768
            ],
            [
              -71.727573,
              43.563411
            ],
            [
              -71.728715,
              43.560282
            ],
            [
              -71.706346,
              43.537623
            ],
            [
              -71.687623,
              43.52814
            ],
            [
              -71.691026,
              43.517932
            ],
            [
              -71.677293,
              43.500491
            ],
            [
              -71.654007,
              43.491153
            ],
            [
              -71.653473,
              43.487621
            ],
            [
              -71.638046,
              43.491756
            ],
            [
              -71.619979,
              43.443867
            ],
            [
              -71.600539,
              43.434887
            ],
            [
              -71.549423,
              43.452389
            ],
            [
              -71.471454,
              43.411298
            ],
            [
              -71.454982,
              43.40265
            ],
            [
              -71.349051,
              43.346578
            ],
            [
              -71.236011,
              43.284994
            ],
            [
              -71.207568,
              43.307996
            ],
            [
              -71.159364,
              43.355123
            ],
            [
              -71.190799,
              43.515033
            ],
            [
              -71.162675,
              43.538959
            ],
            [
              -71.199465,
              43.55913
            ],
            [
              -71.208941,
              43.550517
            ],
            [
              -71.227909,
              43.564715
            ],
            [
              -71.258259,
              43.571345
            ],
            [
              -71.283681,
              43.588557
            ],
            [
              -71.335639,
              43.621431
            ],
            [
              -71.34362,
              43.628794
            ],
            [
              -71.344245,
              43.62922
            ],
            [
              -71.43989,
              43.695558
            ],
            [
              -71.531658,
              43.760979
            ],
            [
              -71.521466,
              43.697992
            ],
            [
              -71.581403,
              43.691949
            ],
            [
              -71.648346,
              43.684511
            ],
            [
              -71.654999,
              43.661752
            ],
            [
              -71.650741,
              43.645052
            ],
            [
              -71.656936,
              43.631593
            ],
            [
              -71.651153,
              43.621119
            ],
            [
              -71.662689,
              43.606478
            ],
            [
              -71.688096,
              43.59495
            ],
            [
              -71.707704,
              43.601023
            ],
            [
              -71.731707,
              43.59006
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "33",
      "COUNTYFP": "003",
      "COUNTYNS": "00873175",
      "AFFGEOID": "0500000US33003",
      "GEOID": "33003",
      "NAME": "Carroll",
      "LSAD": "06",
      "ALAND": 2413888299,
      "AWATER": 159247403,
      "County_state": "Carroll,33"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -71.564603,
              43.893172
            ],
            [
              -71.560621,
              43.875045
            ],
            [
              -71.543805,
              43.796043
            ],
            [
              -71.537754,
              43.79508
            ],
            [
              -71.531658,
              43.760979
            ],
            [
              -71.43989,
              43.695558
            ],
            [
              -71.344245,
              43.62922
            ],
            [
              -71.34362,
              43.628794
            ],
            [
              -71.335639,
              43.621431
            ],
            [
              -71.283681,
              43.588557
            ],
            [
              -71.258259,
              43.571345
            ],
            [
              -71.227909,
              43.564715
            ],
            [
              -71.208941,
              43.550517
            ],
            [
              -71.199465,
              43.55913
            ],
            [
              -71.162675,
              43.538959
            ],
            [
              -71.127808,
              43.572787
            ],
            [
              -71.111496,
              43.506709
            ],
            [
              -71.084838,
              43.506686
            ],
            [
              -71.067565,
              43.52724
            ],
            [
              -71.037931,
              43.529826
            ],
            [
              -71.024244,
              43.482913
            ],
            [
              -70.962536,
              43.541301
            ],
            [
              -70.96214686301958,
              43.5410370171608
            ],
            [
              -70.955928,
              43.541925
            ],
            [
              -70.955337,
              43.54098
            ],
            [
              -70.955252,
              43.540887
            ],
            [
              -70.950838,
              43.551026
            ],
            [
              -70.951876,
              43.552238
            ],
            [
              -70.953322,
              43.552718
            ],
            [
              -70.955017,
              43.554239
            ],
            [
              -70.95586,
              43.559787
            ],
            [
              -70.957234,
              43.561358
            ],
            [
              -70.961848,
              43.562964
            ],
            [
              -70.968546,
              43.568856
            ],
            [
              -70.970124,
              43.568544
            ],
            [
              -70.972716,
              43.570255
            ],
            [
              -70.975123330997,
              43.614409268065195
            ],
            [
              -70.975705,
              43.625078
            ],
            [
              -70.981946,
              43.70096
            ],
            [
              -70.981978,
              43.701965
            ],
            [
              -70.982238,
              43.711865
            ],
            [
              -70.982083,
              43.715043
            ],
            [
              -70.984901,
              43.752983
            ],
            [
              -70.9890301198621,
              43.792090701007496
            ],
            [
              -70.9890367618669,
              43.7921536087332
            ],
            [
              -70.989067,
              43.79244
            ],
            [
              -70.989929,
              43.839239
            ],
            [
              -70.99174897262161,
              43.877322795670096
            ],
            [
              -70.992653,
              43.89624
            ],
            [
              -70.992842,
              43.916269
            ],
            [
              -70.998444,
              44.041099
            ],
            [
              -71.001367,
              44.092931
            ],
            [
              -71.001335,
              44.093205
            ],
            [
              -71.0021619977753,
              44.1106192952439
            ],
            [
              -71.008567,
              44.245491
            ],
            [
              -71.008764,
              44.258443
            ],
            [
              -71.008736,
              44.258825
            ],
            [
              -71.0102711678683,
              44.284888321572005
            ],
            [
              -71.044385,
              44.283778
            ],
            [
              -71.04228,
              44.237514
            ],
            [
              -71.098434,
              44.235454
            ],
            [
              -71.251957,
              44.244266
            ],
            [
              -71.30245,
              44.137402
            ],
            [
              -71.311758,
              44.091756
            ],
            [
              -71.335882,
              44.085752
            ],
            [
              -71.343588,
              44.092054
            ],
            [
              -71.343238,
              44.123387
            ],
            [
              -71.356482,
              44.165836
            ],
            [
              -71.384239,
              44.178844
            ],
            [
              -71.394874,
              44.214679
            ],
            [
              -71.415898,
              44.212169
            ],
            [
              -71.426507,
              44.210903
            ],
            [
              -71.413171,
              44.167462
            ],
            [
              -71.387138,
              44.155201
            ],
            [
              -71.376518,
              44.11887
            ],
            [
              -71.38163,
              44.097874
            ],
            [
              -71.366355,
              44.068524
            ],
            [
              -71.346656,
              44.068822
            ],
            [
              -71.383613,
              44.022611
            ],
            [
              -71.379546,
              44.001544
            ],
            [
              -71.361965,
              43.915938
            ],
            [
              -71.564603,
              43.893172
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "33",
      "COUNTYFP": "017",
      "COUNTYNS": "00873182",
      "AFFGEOID": "0500000US33017",
      "GEOID": "33017",
      "NAME": "Strafford",
      "LSAD": "06",
      "ALAND": 951728786,
      "AWATER": 39171752,
      "County_state": "Strafford,33"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -71.236011,
              43.284994
            ],
            [
              -71.247336,
              43.275232
            ],
            [
              -71.133614,
              43.203598
            ],
            [
              -71.127948,
              43.200072
            ],
            [
              -71.026686,
              43.137947
            ],
            [
              -71.072936,
              43.081681
            ],
            [
              -71.01257,
              43.081993
            ],
            [
              -70.980801,
              43.082367
            ],
            [
              -70.933941,
              43.090133
            ],
            [
              -70.875436,
              43.080422
            ],
            [
              -70.86094,
              43.09462
            ],
            [
              -70.86245,
              43.124359
            ],
            [
              -70.846899,
              43.126086
            ],
            [
              -70.833916,
              43.118011
            ],
            [
              -70.825575,
              43.118206
            ],
            [
              -70.8199991556947,
              43.1225855871936
            ],
            [
              -70.82,
              43.122586
            ],
            [
              -70.8268,
              43.127086
            ],
            [
              -70.8281,
              43.129086
            ],
            [
              -70.8293,
              43.132486
            ],
            [
              -70.83,
              43.138386
            ],
            [
              -70.8329,
              43.143286
            ],
            [
              -70.8338,
              43.146886
            ],
            [
              -70.829101,
              43.157886
            ],
            [
              -70.827801,
              43.166385
            ],
            [
              -70.828301,
              43.168985
            ],
            [
              -70.826201,
              43.172085
            ],
            [
              -70.8250151719745,
              43.172731815286596
            ],
            [
              -70.824001,
              43.173285
            ],
            [
              -70.823501,
              43.174585
            ],
            [
              -70.824801,
              43.179685
            ],
            [
              -70.828301,
              43.186685
            ],
            [
              -70.827901,
              43.188685
            ],
            [
              -70.827201,
              43.189485
            ],
            [
              -70.823754,
              43.191075
            ],
            [
              -70.820702,
              43.191663
            ],
            [
              -70.819344,
              43.193036
            ],
            [
              -70.819146,
              43.195157
            ],
            [
              -70.8199772172799,
              43.1968412369802
            ],
            [
              -70.820366,
              43.197629
            ],
            [
              -70.820763,
              43.19978
            ],
            [
              -70.816903,
              43.214604
            ],
            [
              -70.816033,
              43.21568
            ],
            [
              -70.813119,
              43.217252
            ],
            [
              -70.80967,
              43.224561
            ],
            [
              -70.80964,
              43.225407
            ],
            [
              -70.8111675533474,
              43.2274089788219
            ],
            [
              -70.811852,
              43.228306
            ],
            [
              -70.814019,
              43.229053
            ],
            [
              -70.815453,
              43.229023
            ],
            [
              -70.816232,
              43.234997
            ],
            [
              -70.817773,
              43.237408
            ],
            [
              -70.8178247635243,
              43.2376910114428
            ],
            [
              -70.817865,
              43.237911
            ],
            [
              -70.822959,
              43.240187
            ],
            [
              -70.823309,
              43.240343
            ],
            [
              -70.825071,
              43.24093
            ],
            [
              -70.826711,
              43.241301
            ],
            [
              -70.828022,
              43.241597
            ],
            [
              -70.83365,
              43.242868
            ],
            [
              -70.837274,
              43.242321
            ],
            [
              -70.838678,
              43.242931
            ],
            [
              -70.841273,
              43.248653
            ],
            [
              -70.841059,
              43.249699
            ],
            [
              -70.839717,
              43.250393
            ],
            [
              -70.839213,
              43.251224
            ],
            [
              -70.843302,
              43.254321
            ],
            [
              -70.852015,
              43.256808
            ],
            [
              -70.855082,
              43.255191
            ],
            [
              -70.858207,
              43.256286
            ],
            [
              -70.859607,
              43.257342
            ],
            [
              -70.859853,
              43.259763
            ],
            [
              -70.861384,
              43.263143
            ],
            [
              -70.863231,
              43.265098
            ],
            [
              -70.86323,
              43.265109
            ],
            [
              -70.8637496134913,
              43.2653891080531
            ],
            [
              -70.872585,
              43.270152
            ],
            [
              -70.881704,
              43.272483
            ],
            [
              -70.882804,
              43.273183
            ],
            [
              -70.883704,
              43.277483
            ],
            [
              -70.886504,
              43.282783
            ],
            [
              -70.890204,
              43.284182
            ],
            [
              -70.896304,
              43.285282
            ],
            [
              -70.89882057483361,
              43.286942249349
            ],
            [
              -70.906005,
              43.291682
            ],
            [
              -70.907405,
              43.293582
            ],
            [
              -70.903905,
              43.296882
            ],
            [
              -70.90139,
              43.298764
            ],
            [
              -70.900386,
              43.301358
            ],
            [
              -70.90231,
              43.304872
            ],
            [
              -70.904485,
              43.30529
            ],
            [
              -70.907405,
              43.304782
            ],
            [
              -70.909805,
              43.306682
            ],
            [
              -70.91246,
              43.308289
            ],
            [
              -70.911625,
              43.309952
            ],
            [
              -70.912004,
              43.319821
            ],
            [
              -70.915406,
              43.319858
            ],
            [
              -70.916421,
              43.320279
            ],
            [
              -70.92359415228529,
              43.3245564017812
            ],
            [
              -70.923949,
              43.324768
            ],
            [
              -70.930783,
              43.329569
            ],
            [
              -70.931641,
              43.331019
            ],
            [
              -70.932198,
              43.33456
            ],
            [
              -70.932735,
              43.33676
            ],
            [
              -70.93711,
              43.337367
            ],
            [
              -70.951525,
              43.334672
            ],
            [
              -70.953034,
              43.333257
            ],
            [
              -70.956528,
              43.334691
            ],
            [
              -70.95786,
              43.337776
            ],
            [
              -70.960439,
              43.341048
            ],
            [
              -70.964028,
              43.341888
            ],
            [
              -70.967229,
              43.343777
            ],
            [
              -70.97329039172,
              43.3550454400433
            ],
            [
              -70.974863,
              43.357969
            ],
            [
              -70.974156,
              43.362925
            ],
            [
              -70.976408,
              43.367272
            ],
            [
              -70.981859,
              43.373862
            ],
            [
              -70.984335,
              43.376128
            ],
            [
              -70.984305,
              43.376814
            ],
            [
              -70.9855356237213,
              43.3791929587481
            ],
            [
              -70.985965,
              43.380023
            ],
            [
              -70.985958,
              43.38424
            ],
            [
              -70.985205,
              43.386745
            ],
            [
              -70.987649,
              43.389521
            ],
            [
              -70.987733,
              43.391513
            ],
            [
              -70.98739,
              43.393457
            ],
            [
              -70.982876,
              43.394808
            ],
            [
              -70.982565,
              43.39778
            ],
            [
              -70.982817,
              43.399312
            ],
            [
              -70.985767,
              43.40162
            ],
            [
              -70.986677,
              43.403541
            ],
            [
              -70.987249,
              43.411863
            ],
            [
              -70.986812,
              43.414264
            ],
            [
              -70.982898,
              43.419332
            ],
            [
              -70.978052,
              43.421954
            ],
            [
              -70.97462,
              43.423022
            ],
            [
              -70.971039,
              43.425606
            ],
            [
              -70.968359,
              43.429283
            ],
            [
              -70.969362,
              43.432731
            ],
            [
              -70.968782,
              43.434891
            ],
            [
              -70.96115,
              43.438321
            ],
            [
              -70.961046,
              43.440475
            ],
            [
              -70.96164,
              43.443039
            ],
            [
              -70.96581,
              43.447557
            ],
            [
              -70.9669,
              43.450458
            ],
            [
              -70.966266,
              43.453627
            ],
            [
              -70.96399,
              43.456752
            ],
            [
              -70.96045,
              43.466592
            ],
            [
              -70.961428,
              43.469696
            ],
            [
              -70.964433,
              43.473276
            ],
            [
              -70.964542,
              43.473262
            ],
            [
              -70.966017,
              43.472918
            ],
            [
              -70.970946,
              43.4739
            ],
            [
              -70.972956,
              43.47502
            ],
            [
              -70.974245,
              43.47742
            ],
            [
              -70.967968,
              43.480783
            ],
            [
              -70.967404,
              43.482635
            ],
            [
              -70.968975,
              43.483961
            ],
            [
              -70.969572,
              43.486201
            ],
            [
              -70.966718,
              43.491278
            ],
            [
              -70.961948,
              43.49775
            ],
            [
              -70.959185,
              43.499351
            ],
            [
              -70.957958,
              43.508041
            ],
            [
              -70.954755,
              43.509802
            ],
            [
              -70.955386,
              43.511357
            ],
            [
              -70.956856,
              43.512719
            ],
            [
              -70.954045,
              43.518797
            ],
            [
              -70.954066,
              43.52261
            ],
            [
              -70.956787,
              43.524216
            ],
            [
              -70.957214,
              43.524994
            ],
            [
              -70.957046,
              43.528743
            ],
            [
              -70.95822,
              43.531586
            ],
            [
              -70.962556,
              43.53431
            ],
            [
              -70.963342,
              43.535247
            ],
            [
              -70.963531,
              43.536756
            ],
            [
              -70.963281,
              43.538929
            ],
            [
              -70.962182,
              43.541032
            ],
            [
              -70.96214686301958,
              43.5410370171608
            ],
            [
              -70.962536,
              43.541301
            ],
            [
              -71.024244,
              43.482913
            ],
            [
              -71.037931,
              43.529826
            ],
            [
              -71.067565,
              43.52724
            ],
            [
              -71.084838,
              43.506686
            ],
            [
              -71.111496,
              43.506709
            ],
            [
              -71.127808,
              43.572787
            ],
            [
              -71.162675,
              43.538959
            ],
            [
              -71.190799,
              43.515033
            ],
            [
              -71.159364,
              43.355123
            ],
            [
              -71.207568,
              43.307996
            ],
            [
              -71.236011,
              43.284994
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "33",
      "COUNTYFP": "019",
      "COUNTYNS": "00873183",
      "AFFGEOID": "0500000US33019",
      "GEOID": "33019",
      "NAME": "Sullivan",
      "LSAD": "06",
      "ALAND": 1392157812,
      "AWATER": 37865613,
      "County_state": "Sullivan,33"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -72.452556,
              43.172117
            ],
            [
              -72.451868,
              43.170174
            ],
            [
              -72.4521,
              43.161414
            ],
            [
              -72.45209374428919,
              43.1613424195726
            ],
            [
              -72.43209,
              43.15982
            ],
            [
              -72.431632,
              43.135635
            ],
            [
              -72.372045,
              43.132118
            ],
            [
              -72.376439,
              43.157684
            ],
            [
              -72.330326,
              43.155166
            ],
            [
              -72.332139,
              43.162842
            ],
            [
              -72.262483,
              43.169952
            ],
            [
              -72.156661,
              43.181968
            ],
            [
              -72.178497,
              43.131866
            ],
            [
              -72.038149,
              43.128807
            ],
            [
              -72.023478,
              43.160125
            ],
            [
              -72.036845,
              43.182471
            ],
            [
              -72.009074,
              43.187882
            ],
            [
              -72.041545,
              43.222724
            ],
            [
              -72.045284,
              43.254767
            ],
            [
              -72.065442,
              43.25508
            ],
            [
              -72.090146,
              43.322553
            ],
            [
              -72.078625,
              43.33859
            ],
            [
              -72.050304,
              43.378278
            ],
            [
              -72.04681,
              43.410862
            ],
            [
              -72.057766,
              43.422428
            ],
            [
              -72.053752,
              43.441097
            ],
            [
              -72.011363,
              43.436871
            ],
            [
              -71.990305,
              43.464923
            ],
            [
              -71.93531,
              43.52888
            ],
            [
              -72.067165,
              43.543971
            ],
            [
              -72.069516,
              43.553172
            ],
            [
              -72.090893,
              43.528857
            ],
            [
              -72.120176,
              43.538302
            ],
            [
              -72.106412,
              43.553485
            ],
            [
              -72.171188,
              43.573985
            ],
            [
              -72.208313,
              43.585207
            ],
            [
              -72.3296772840816,
              43.6001836405589
            ],
            [
              -72.332382,
              43.599364
            ],
            [
              -72.349926,
              43.587726
            ],
            [
              -72.363916,
              43.583652
            ],
            [
              -72.373126,
              43.579419
            ],
            [
              -72.37944,
              43.574069
            ],
            [
              -72.382625,
              43.564127
            ],
            [
              -72.382783,
              43.562459
            ],
            [
              -72.381187,
              43.554915
            ],
            [
              -72.380383,
              43.54088
            ],
            [
              -72.38331,
              43.53519
            ],
            [
              -72.389097,
              43.528266
            ],
            [
              -72.3907,
              43.527261
            ],
            [
              -72.394218,
              43.5274
            ],
            [
              -72.395949,
              43.52388
            ],
            [
              -72.395825,
              43.52056
            ],
            [
              -72.398563,
              43.513435
            ],
            [
              -72.398376,
              43.510829
            ],
            [
              -72.396305,
              43.508062
            ],
            [
              -72.389556,
              43.503899
            ],
            [
              -72.384773,
              43.500259
            ],
            [
              -72.380894,
              43.493394
            ],
            [
              -72.380362,
              43.491634
            ],
            [
              -72.380428,
              43.488525
            ],
            [
              -72.381723,
              43.480091
            ],
            [
              -72.382951,
              43.476
            ],
            [
              -72.384491,
              43.474195
            ],
            [
              -72.391526,
              43.46878
            ],
            [
              -72.3925,
              43.467364
            ],
            [
              -72.392628,
              43.465078
            ],
            [
              -72.390567,
              43.451225
            ],
            [
              -72.391196,
              43.449305
            ],
            [
              -72.393992,
              43.444666
            ],
            [
              -72.395659,
              43.438541
            ],
            [
              -72.395916,
              43.430974
            ],
            [
              -72.3964397283084,
              43.428943519810396
            ],
            [
              -72.3964568073635,
              43.4288773047854
            ],
            [
              -72.399972,
              43.415249
            ],
            [
              -72.400131,
              43.410997
            ],
            [
              -72.401624713554,
              43.403259726150395
            ],
            [
              -72.403811,
              43.391935
            ],
            [
              -72.405253,
              43.389992
            ],
            [
              -72.413154,
              43.384302
            ],
            [
              -72.415381,
              43.380211
            ],
            [
              -72.415978,
              43.376531
            ],
            [
              -72.415099,
              43.365896
            ],
            [
              -72.414692,
              43.364273
            ],
            [
              -72.413377,
              43.362741
            ],
            [
              -72.403949,
              43.358098
            ],
            [
              -72.400441,
              43.357685
            ],
            [
              -72.39217,
              43.357865
            ],
            [
              -72.390103,
              43.356926
            ],
            [
              -72.39092,
              43.354984
            ],
            [
              -72.395403,
              43.350414
            ],
            [
              -72.399289,
              43.347581
            ],
            [
              -72.400981,
              43.345775
            ],
            [
              -72.4066245346759,
              43.3378028767861
            ],
            [
              -72.4066991286665,
              43.3376975044409
            ],
            [
              -72.409037,
              43.334395
            ],
            [
              -72.410353,
              43.331675
            ],
            [
              -72.410197,
              43.330395
            ],
            [
              -72.408696,
              43.327674
            ],
            [
              -72.402532,
              43.32038
            ],
            [
              -72.397619,
              43.317064
            ],
            [
              -72.395805,
              43.314617
            ],
            [
              -72.395462,
              43.312994
            ],
            [
              -72.401666,
              43.303395
            ],
            [
              -72.407842,
              43.282892
            ],
            [
              -72.41545,
              43.271374
            ],
            [
              -72.421583,
              43.263442
            ],
            [
              -72.435221,
              43.258483
            ],
            [
              -72.436378,
              43.257454
            ],
            [
              -72.438693,
              43.252905
            ],
            [
              -72.438937,
              43.24424
            ],
            [
              -72.436654,
              43.238319
            ],
            [
              -72.434216,
              43.234958
            ],
            [
              -72.433684,
              43.233427
            ],
            [
              -72.4337736887345,
              43.2330834990285
            ],
            [
              -72.434466,
              43.230432
            ],
            [
              -72.437656,
              43.225266
            ],
            [
              -72.4405,
              43.219049
            ],
            [
              -72.440563,
              43.215254
            ],
            [
              -72.438594,
              43.209013
            ],
            [
              -72.437719,
              43.20275
            ],
            [
              -72.438969,
              43.201035
            ],
            [
              -72.45028,
              43.192485
            ],
            [
              -72.449435,
              43.18917
            ],
            [
              -72.443749,
              43.182221
            ],
            [
              -72.443405,
              43.179729
            ],
            [
              -72.444904,
              43.177969
            ],
            [
              -72.452556,
              43.172117
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "33",
      "COUNTYFP": "015",
      "COUNTYNS": "00873181",
      "AFFGEOID": "0500000US33015",
      "GEOID": "33015",
      "NAME": "Rockingham",
      "LSAD": "06",
      "ALAND": 1800358797,
      "AWATER": 259322531,
      "County_state": "Rockingham,33"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -71.451154,
              42.9309
            ],
            [
              -71.442301,
              42.890399
            ],
            [
              -71.427067,
              42.82484
            ],
            [
              -71.3872,
              42.823559
            ],
            [
              -71.360679,
              42.798962
            ],
            [
              -71.366203,
              42.791691
            ],
            [
              -71.36154,
              42.783715
            ],
            [
              -71.358055,
              42.785137
            ],
            [
              -71.351035,
              42.772183
            ],
            [
              -71.284261,
              42.760113
            ],
            [
              -71.26009,
              42.755734
            ],
            [
              -71.24553925939401,
              42.742567357762304
            ],
            [
              -71.245504,
              42.742589
            ],
            [
              -71.233404,
              42.745489
            ],
            [
              -71.223904,
              42.746689
            ],
            [
              -71.208302,
              42.743314
            ],
            [
              -71.208227,
              42.743294
            ],
            [
              -71.208137,
              42.743273
            ],
            [
              -71.181803,
              42.73759
            ],
            [
              -71.186104,
              42.790689
            ],
            [
              -71.18227871033069,
              42.7942524268349
            ],
            [
              -71.174403,
              42.801589
            ],
            [
              -71.167703,
              42.807389
            ],
            [
              -71.16627183278689,
              42.808274960655694
            ],
            [
              -71.165603,
              42.808689
            ],
            [
              -71.149703,
              42.815489
            ],
            [
              -71.132503,
              42.821389
            ],
            [
              -71.1157535303384,
              42.817686077803096
            ],
            [
              -71.064201,
              42.806289
            ],
            [
              -71.053601,
              42.833089
            ],
            [
              -71.0535652698312,
              42.8331534314519
            ],
            [
              -71.047501,
              42.844089
            ],
            [
              -71.044401,
              42.848789
            ],
            [
              -71.031201,
              42.859089
            ],
            [
              -71.0046387450972,
              42.8631533316724
            ],
            [
              -70.99947469686201,
              42.8639434906735
            ],
            [
              -70.9665,
              42.868989
            ],
            [
              -70.949199,
              42.876089
            ],
            [
              -70.931699,
              42.884189
            ],
            [
              -70.930799,
              42.884589
            ],
            [
              -70.927629,
              42.885326
            ],
            [
              -70.914899,
              42.886589
            ],
            [
              -70.914886,
              42.886564
            ],
            [
              -70.9145252357167,
              42.8865629877879
            ],
            [
              -70.902768,
              42.88653
            ],
            [
              -70.886136,
              42.88261
            ],
            [
              -70.848625,
              42.860939
            ],
            [
              -70.837376,
              42.864996
            ],
            [
              -70.830795,
              42.868918
            ],
            [
              -70.821769,
              42.87188
            ],
            [
              -70.817296,
              42.87229
            ],
            [
              -70.816989,
              42.879864
            ],
            [
              -70.81586,
              42.88625
            ],
            [
              -70.813975,
              42.889766
            ],
            [
              -70.8135053708169,
              42.890177714562704
            ],
            [
              -70.810999,
              42.892375
            ],
            [
              -70.810681,
              42.906887
            ],
            [
              -70.810069,
              42.909549
            ],
            [
              -70.808712,
              42.912787
            ],
            [
              -70.805971,
              42.916549
            ],
            [
              -70.798153,
              42.920926
            ],
            [
              -70.798636,
              42.924288
            ],
            [
              -70.797806,
              42.930037
            ],
            [
              -70.793996,
              42.93989
            ],
            [
              -70.790682,
              42.942772
            ],
            [
              -70.786564,
              42.947378
            ],
            [
              -70.78513,
              42.950792
            ],
            [
              -70.78389592861811,
              42.952093403273096
            ],
            [
              -70.780383,
              42.955798
            ],
            [
              -70.779068,
              42.956802
            ],
            [
              -70.775597,
              42.957213
            ],
            [
              -70.771729,
              42.961321
            ],
            [
              -70.769673,
              42.964419
            ],
            [
              -70.771913,
              42.966457
            ],
            [
              -70.7718,
              42.968064
            ],
            [
              -70.7704770619983,
              42.969529126686304
            ],
            [
              -70.765222,
              42.975349
            ],
            [
              -70.764567,
              42.980201
            ],
            [
              -70.761474,
              42.986681
            ],
            [
              -70.759175,
              42.989475
            ],
            [
              -70.756701,
              42.991337
            ],
            [
              -70.755138,
              42.991789
            ],
            [
              -70.752804,
              42.991255
            ],
            [
              -70.749969,
              42.991689
            ],
            [
              -70.749194,
              42.992677
            ],
            [
              -70.749024,
              42.995467
            ],
            [
              -70.743793,
              43.008027
            ],
            [
              -70.74036,
              43.011171
            ],
            [
              -70.735477,
              43.012201
            ],
            [
              -70.734363,
              43.013307
            ],
            [
              -70.732684,
              43.016916
            ],
            [
              -70.732135,
              43.022416
            ],
            [
              -70.730426,
              43.025392
            ],
            [
              -70.728443,
              43.027597
            ],
            [
              -70.723178,
              43.029298
            ],
            [
              -70.718936,
              43.03235
            ],
            [
              -70.71355,
              43.042077
            ],
            [
              -70.714176,
              43.046266
            ],
            [
              -70.71363,
              43.056006
            ],
            [
              -70.703799,
              43.059574
            ],
            [
              -70.703818,
              43.059825
            ],
            [
              -70.704696,
              43.070989
            ],
            [
              -70.705996,
              43.073089
            ],
            [
              -70.708896,
              43.074989
            ],
            [
              -70.720296,
              43.074688
            ],
            [
              -70.733497,
              43.073288
            ],
            [
              -70.73779383920369,
              43.073483310872895
            ],
            [
              -70.737897,
              43.073488
            ],
            [
              -70.741897,
              43.077388
            ],
            [
              -70.7526445792199,
              43.079315152136004
            ],
            [
              -70.756397,
              43.079988
            ],
            [
              -70.757597,
              43.080888
            ],
            [
              -70.762997,
              43.089488
            ],
            [
              -70.766398,
              43.092688
            ],
            [
              -70.76784146587539,
              43.093499949554904
            ],
            [
              -70.767998,
              43.093588
            ],
            [
              -70.779098,
              43.095887
            ],
            [
              -70.7826969713611,
              43.0996349877412
            ],
            [
              -70.78388,
              43.100867
            ],
            [
              -70.793906,
              43.106712
            ],
            [
              -70.808708,
              43.117065
            ],
            [
              -70.8199991556947,
              43.1225855871936
            ],
            [
              -70.825575,
              43.118206
            ],
            [
              -70.833916,
              43.118011
            ],
            [
              -70.846899,
              43.126086
            ],
            [
              -70.86245,
              43.124359
            ],
            [
              -70.86094,
              43.09462
            ],
            [
              -70.875436,
              43.080422
            ],
            [
              -70.933941,
              43.090133
            ],
            [
              -70.980801,
              43.082367
            ],
            [
              -71.01257,
              43.081993
            ],
            [
              -71.072936,
              43.081681
            ],
            [
              -71.026686,
              43.137947
            ],
            [
              -71.127948,
              43.200072
            ],
            [
              -71.133614,
              43.203598
            ],
            [
              -71.247336,
              43.275232
            ],
            [
              -71.250279,
              43.267439
            ],
            [
              -71.280796,
              43.218603
            ],
            [
              -71.31394,
              43.164626
            ],
            [
              -71.317709,
              43.162399
            ],
            [
              -71.329443,
              43.14064
            ],
            [
              -71.356117,
              43.102043
            ],
            [
              -71.391228,
              43.040963
            ],
            [
              -71.402962,
              43.041054
            ],
            [
              -71.392388,
              43.009309
            ],
            [
              -71.392277,
              43.009011
            ],
            [
              -71.377186,
              42.947145
            ],
            [
              -71.38406,
              42.938349
            ],
            [
              -71.451154,
              42.9309
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "33",
      "COUNTYFP": "011",
      "COUNTYNS": "00873179",
      "AFFGEOID": "0500000US33011",
      "GEOID": "33011",
      "NAME": "Hillsborough",
      "LSAD": "06",
      "ALAND": 2269883907,
      "AWATER": 41627731,
      "County_state": "Hillsborough,33"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -72.061246,
              42.965806
            ],
            [
              -72.062222,
              42.944796
            ],
            [
              -72.00619,
              42.943789
            ],
            [
              -72.003473,
              42.930224
            ],
            [
              -72.001215,
              42.919443
            ],
            [
              -71.984216,
              42.833805
            ],
            [
              -71.97471,
              42.789402
            ],
            [
              -71.944283,
              42.78841
            ],
            [
              -71.9290194043648,
              42.7122382005025
            ],
            [
              -71.928811,
              42.712234
            ],
            [
              -71.89877002948,
              42.7114218822193
            ],
            [
              -71.80539006840151,
              42.7088974788648
            ],
            [
              -71.7725568791756,
              42.70800987715601
            ],
            [
              -71.745817,
              42.707287
            ],
            [
              -71.65187391472301,
              42.7052307655759
            ],
            [
              -71.636214,
              42.704888
            ],
            [
              -71.631814,
              42.704788
            ],
            [
              -71.6306223191203,
              42.7047597596236
            ],
            [
              -71.5426896188347,
              42.702675936184
            ],
            [
              -71.49568066781009,
              42.7015619211054
            ],
            [
              -71.467553246573,
              42.7008953592976
            ],
            [
              -71.45768776362699,
              42.7006615677213
            ],
            [
              -71.4491742102848,
              42.70045981408529
            ],
            [
              -71.4323540138531,
              42.700061210159
            ],
            [
              -71.3869430269232,
              42.6989850635301
            ],
            [
              -71.3697250416668,
              42.69857703283
            ],
            [
              -71.351874,
              42.698154
            ],
            [
              -71.330206,
              42.69719
            ],
            [
              -71.294205,
              42.69699
            ],
            [
              -71.278929,
              42.711258
            ],
            [
              -71.267905,
              42.72589
            ],
            [
              -71.255605,
              42.736389
            ],
            [
              -71.2551987703894,
              42.736638343984396
            ],
            [
              -71.24553925939401,
              42.742567357762304
            ],
            [
              -71.26009,
              42.755734
            ],
            [
              -71.284261,
              42.760113
            ],
            [
              -71.351035,
              42.772183
            ],
            [
              -71.358055,
              42.785137
            ],
            [
              -71.36154,
              42.783715
            ],
            [
              -71.366203,
              42.791691
            ],
            [
              -71.360679,
              42.798962
            ],
            [
              -71.3872,
              42.823559
            ],
            [
              -71.427067,
              42.82484
            ],
            [
              -71.442301,
              42.890399
            ],
            [
              -71.451154,
              42.9309
            ],
            [
              -71.38406,
              42.938349
            ],
            [
              -71.377186,
              42.947145
            ],
            [
              -71.392277,
              43.009011
            ],
            [
              -71.392388,
              43.009309
            ],
            [
              -71.440622,
              43.018387
            ],
            [
              -71.454783,
              43.044
            ],
            [
              -71.473981,
              43.035274
            ],
            [
              -71.489109,
              43.051779
            ],
            [
              -71.512767,
              43.049615
            ],
            [
              -71.517467,
              43.068909
            ],
            [
              -71.641291,
              43.054168
            ],
            [
              -71.667771,
              43.152534
            ],
            [
              -71.73256,
              43.139786
            ],
            [
              -71.8561,
              43.115135
            ],
            [
              -71.913215,
              43.207099
            ],
            [
              -72.009074,
              43.187882
            ],
            [
              -72.036845,
              43.182471
            ],
            [
              -72.023478,
              43.160125
            ],
            [
              -72.038149,
              43.128807
            ],
            [
              -72.034862,
              43.125061
            ],
            [
              -72.041972,
              43.082238
            ],
            [
              -72.057949,
              43.018922
            ],
            [
              -72.058801,
              43.005106
            ],
            [
              -72.061246,
              42.965806
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "33",
      "COUNTYFP": "005",
      "COUNTYNS": "00873176",
      "AFFGEOID": "0500000US33005",
      "GEOID": "33005",
      "NAME": "Cheshire",
      "LSAD": "06",
      "ALAND": 1830338973,
      "AWATER": 57617298,
      "County_state": "Cheshire,33"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -72.557247,
              42.853019
            ],
            [
              -72.5565766339102,
              42.851911957334
            ],
            [
              -72.553426,
              42.846709
            ],
            [
              -72.54855,
              42.842021
            ],
            [
              -72.547402,
              42.837587
            ],
            [
              -72.547434,
              42.832603
            ],
            [
              -72.546133,
              42.823938
            ],
            [
              -72.54611935078601,
              42.8238750073899
            ],
            [
              -72.542784,
              42.808482
            ],
            [
              -72.5396,
              42.804832
            ],
            [
              -72.518354,
              42.790651
            ],
            [
              -72.515838,
              42.78856
            ],
            [
              -72.511746,
              42.784114
            ],
            [
              -72.508858,
              42.779919
            ],
            [
              -72.508048,
              42.776885
            ],
            [
              -72.508372,
              42.77461
            ],
            [
              -72.510154,
              42.773221
            ],
            [
              -72.514836,
              42.771436
            ],
            [
              -72.516731,
              42.76867
            ],
            [
              -72.516082,
              42.765949
            ],
            [
              -72.513105,
              42.763822
            ],
            [
              -72.510871,
              42.763752
            ],
            [
              -72.507985,
              42.764414
            ],
            [
              -72.50069,
              42.767657
            ],
            [
              -72.499249,
              42.769054
            ],
            [
              -72.498786,
              42.771981
            ],
            [
              -72.497949,
              42.772918
            ],
            [
              -72.495343,
              42.773286
            ],
            [
              -72.491122,
              42.772465
            ],
            [
              -72.487767,
              42.76938
            ],
            [
              -72.4864,
              42.76698
            ],
            [
              -72.484878,
              42.76554
            ],
            [
              -72.479354,
              42.763119
            ],
            [
              -72.477615,
              42.761245
            ],
            [
              -72.475938,
              42.757702
            ],
            [
              -72.474723,
              42.750729
            ],
            [
              -72.473071,
              42.745916
            ],
            [
              -72.467827,
              42.741209
            ],
            [
              -72.461001,
              42.733209
            ],
            [
              -72.458488,
              42.729094
            ],
            [
              -72.458519,
              42.726853
            ],
            [
              -72.4511957433251,
              42.726631390482694
            ],
            [
              -72.41203323742229,
              42.725446291500695
            ],
            [
              -72.326282472606,
              42.7228513823716
            ],
            [
              -72.285954,
              42.721631
            ],
            [
              -72.28303287565089,
              42.72155870837909
            ],
            [
              -72.2035695536582,
              42.7195921600024
            ],
            [
              -72.124526,
              42.717636
            ],
            [
              -72.1110583499481,
              42.7172274274578
            ],
            [
              -72.0813685534858,
              42.71632671830879
            ],
            [
              -72.0786269963685,
              42.716243546786195
            ],
            [
              -71.981402,
              42.713294
            ],
            [
              -71.9290194043648,
              42.7122382005025
            ],
            [
              -71.944283,
              42.78841
            ],
            [
              -71.97471,
              42.789402
            ],
            [
              -71.984216,
              42.833805
            ],
            [
              -72.001215,
              42.919443
            ],
            [
              -72.003473,
              42.930224
            ],
            [
              -72.00619,
              42.943789
            ],
            [
              -72.062222,
              42.944796
            ],
            [
              -72.061246,
              42.965806
            ],
            [
              -72.058801,
              43.005106
            ],
            [
              -72.057949,
              43.018922
            ],
            [
              -72.041972,
              43.082238
            ],
            [
              -72.034862,
              43.125061
            ],
            [
              -72.038149,
              43.128807
            ],
            [
              -72.178497,
              43.131866
            ],
            [
              -72.156661,
              43.181968
            ],
            [
              -72.262483,
              43.169952
            ],
            [
              -72.332139,
              43.162842
            ],
            [
              -72.330326,
              43.155166
            ],
            [
              -72.376439,
              43.157684
            ],
            [
              -72.372045,
              43.132118
            ],
            [
              -72.431632,
              43.135635
            ],
            [
              -72.43209,
              43.15982
            ],
            [
              -72.45209374428919,
              43.1613424195726
            ],
            [
              -72.451553,
              43.155155
            ],
            [
              -72.451802,
              43.153486
            ],
            [
              -72.452801,
              43.151977
            ],
            [
              -72.456537,
              43.149528
            ],
            [
              -72.45714,
              43.148493
            ],
            [
              -72.45689,
              43.146558
            ],
            [
              -72.451986,
              43.138924
            ],
            [
              -72.448303,
              43.137187
            ],
            [
              -72.444214,
              43.13737
            ],
            [
              -72.441904,
              43.136615
            ],
            [
              -72.440905,
              43.135793
            ],
            [
              -72.440624,
              43.132203
            ],
            [
              -72.44078,
              43.131472
            ],
            [
              -72.442746,
              43.131152
            ],
            [
              -72.442933,
              43.130192
            ],
            [
              -72.440967,
              43.127608
            ],
            [
              -72.4379502256947,
              43.1250733339319
            ],
            [
              -72.435936,
              43.123381
            ],
            [
              -72.432972,
              43.119655
            ],
            [
              -72.432661,
              43.114077
            ],
            [
              -72.433129,
              43.112637
            ],
            [
              -72.434845,
              43.109917
            ],
            [
              -72.440587,
              43.106145
            ],
            [
              -72.442427,
              43.10363
            ],
            [
              -72.443051,
              43.100841
            ],
            [
              -72.439214,
              43.094852
            ],
            [
              -72.435191,
              43.086622
            ],
            [
              -72.435316,
              43.083536
            ],
            [
              -72.43619,
              43.08173
            ],
            [
              -72.43987,
              43.077043
            ],
            [
              -72.445202,
              43.071352
            ],
            [
              -72.45471,
              43.063487
            ],
            [
              -72.463812,
              43.057404
            ],
            [
              -72.466491,
              43.054729
            ],
            [
              -72.467363,
              43.052648
            ],
            [
              -72.466832,
              43.049197
            ],
            [
              -72.465896,
              43.047505
            ],
            [
              -72.462248,
              43.044214
            ],
            [
              -72.460252,
              43.040671
            ],
            [
              -72.460905,
              43.035961
            ],
            [
              -72.46299,
              43.028531
            ],
            [
              -72.462397,
              43.02556
            ],
            [
              -72.458998,
              43.019388
            ],
            [
              -72.457035,
              43.017285
            ],
            [
              -72.452984,
              43.015731
            ],
            [
              -72.444635,
              43.010566
            ],
            [
              -72.443825,
              43.008965
            ],
            [
              -72.443762,
              43.006245
            ],
            [
              -72.444977,
              43.004416
            ],
            [
              -72.448714,
              43.001169
            ],
            [
              -72.451797,
              43.000577
            ],
            [
              -72.456936,
              43.001306
            ],
            [
              -72.459951,
              43.00008
            ],
            [
              -72.46294,
              42.996943
            ],
            [
              -72.464714,
              42.993582
            ],
            [
              -72.465335,
              42.989558
            ],
            [
              -72.464026,
              42.986107
            ],
            [
              -72.461597,
              42.984049
            ],
            [
              -72.461627,
              42.982906
            ],
            [
              -72.465985,
              42.97847
            ],
            [
              -72.473827,
              42.972045
            ],
            [
              -72.476722,
              42.971746
            ],
            [
              -72.479245,
              42.973597
            ],
            [
              -72.481706,
              42.973985
            ],
            [
              -72.486872,
              42.971789
            ],
            [
              -72.492597,
              42.967648
            ],
            [
              -72.504226,
              42.965815
            ],
            [
              -72.507901,
              42.964171
            ],
            [
              -72.518422,
              42.96317
            ],
            [
              -72.532186,
              42.954945
            ],
            [
              -72.534117,
              42.952133
            ],
            [
              -72.534554,
              42.949894
            ],
            [
              -72.533901,
              42.948591
            ],
            [
              -72.531693,
              42.94651
            ],
            [
              -72.529763,
              42.94612
            ],
            [
              -72.52855,
              42.94532
            ],
            [
              -72.527431,
              42.943148
            ],
            [
              -72.526624,
              42.939901
            ],
            [
              -72.526346,
              42.935717
            ],
            [
              -72.527097,
              42.928584
            ],
            [
              -72.524242,
              42.918501
            ],
            [
              -72.52443,
              42.915575
            ],
            [
              -72.525271,
              42.914363
            ],
            [
              -72.529191,
              42.912719
            ],
            [
              -72.530218,
              42.911576
            ],
            [
              -72.531588,
              42.907164
            ],
            [
              -72.531469,
              42.89795
            ],
            [
              -72.5327503134955,
              42.8961142343344
            ],
            [
              -72.532777,
              42.896076
            ],
            [
              -72.537287,
              42.89187
            ],
            [
              -72.540708,
              42.889379
            ],
            [
              -72.546491,
              42.88714
            ],
            [
              -72.552025,
              42.885631
            ],
            [
              -72.552834,
              42.884968
            ],
            [
              -72.555415,
              42.875428
            ],
            [
              -72.555131,
              42.871058
            ],
            [
              -72.556214,
              42.86695
            ],
            [
              -72.556112,
              42.866252
            ],
            [
              -72.555132,
              42.865731
            ],
            [
              -72.554232,
              42.860038
            ],
            [
              -72.557247,
              42.853019
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "33",
      "COUNTYFP": "013",
      "COUNTYNS": "00873180",
      "AFFGEOID": "0500000US33013",
      "GEOID": "33013",
      "NAME": "Merrimack",
      "LSAD": "06",
      "ALAND": 2415829018,
      "AWATER": 57547411,
      "County_state": "Merrimack,33"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -72.078625,
              43.33859
            ],
            [
              -72.090146,
              43.322553
            ],
            [
              -72.065442,
              43.25508
            ],
            [
              -72.045284,
              43.254767
            ],
            [
              -72.041545,
              43.222724
            ],
            [
              -72.009074,
              43.187882
            ],
            [
              -71.913215,
              43.207099
            ],
            [
              -71.8561,
              43.115135
            ],
            [
              -71.73256,
              43.139786
            ],
            [
              -71.667771,
              43.152534
            ],
            [
              -71.641291,
              43.054168
            ],
            [
              -71.517467,
              43.068909
            ],
            [
              -71.512767,
              43.049615
            ],
            [
              -71.489109,
              43.051779
            ],
            [
              -71.473981,
              43.035274
            ],
            [
              -71.454783,
              43.044
            ],
            [
              -71.440622,
              43.018387
            ],
            [
              -71.392388,
              43.009309
            ],
            [
              -71.402962,
              43.041054
            ],
            [
              -71.391228,
              43.040963
            ],
            [
              -71.356117,
              43.102043
            ],
            [
              -71.329443,
              43.14064
            ],
            [
              -71.317709,
              43.162399
            ],
            [
              -71.31394,
              43.164626
            ],
            [
              -71.280796,
              43.218603
            ],
            [
              -71.250279,
              43.267439
            ],
            [
              -71.247336,
              43.275232
            ],
            [
              -71.236011,
              43.284994
            ],
            [
              -71.349051,
              43.346578
            ],
            [
              -71.454982,
              43.40265
            ],
            [
              -71.471454,
              43.411298
            ],
            [
              -71.549423,
              43.452389
            ],
            [
              -71.600539,
              43.434887
            ],
            [
              -71.619979,
              43.443867
            ],
            [
              -71.638046,
              43.491756
            ],
            [
              -71.653473,
              43.487621
            ],
            [
              -71.654007,
              43.491153
            ],
            [
              -71.677293,
              43.500491
            ],
            [
              -71.691026,
              43.517932
            ],
            [
              -71.687623,
              43.52814
            ],
            [
              -71.706346,
              43.537623
            ],
            [
              -71.728715,
              43.560282
            ],
            [
              -71.727573,
              43.563411
            ],
            [
              -71.735583,
              43.56927
            ],
            [
              -71.76102,
              43.567729
            ],
            [
              -71.7775,
              43.552501
            ],
            [
              -71.797489,
              43.559413
            ],
            [
              -71.817921,
              43.544208
            ],
            [
              -71.849203,
              43.6112
            ],
            [
              -71.892112,
              43.574618
            ],
            [
              -71.930992,
              43.533855
            ],
            [
              -71.93531,
              43.52888
            ],
            [
              -71.990305,
              43.464923
            ],
            [
              -72.011363,
              43.436871
            ],
            [
              -72.053752,
              43.441097
            ],
            [
              -72.057766,
              43.422428
            ],
            [
              -72.04681,
              43.410862
            ],
            [
              -72.050304,
              43.378278
            ],
            [
              -72.078625,
              43.33859
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "33",
      "COUNTYFP": "009",
      "COUNTYNS": "00873178",
      "AFFGEOID": "0500000US33009",
      "GEOID": "33009",
      "NAME": "Grafton",
      "LSAD": "06",
      "ALAND": 4425224129,
      "AWATER": 106489725,
      "County_state": "Grafton,33"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -72.334401,
              43.61925
            ],
            [
              -72.334745,
              43.614519
            ],
            [
              -72.3327,
              43.610313
            ],
            [
              -72.329522,
              43.608393
            ],
            [
              -72.328232,
              43.606839
            ],
            [
              -72.3281646788123,
              43.606345073825395
            ],
            [
              -72.327665,
              43.602679
            ],
            [
              -72.328514,
              43.600805
            ],
            [
              -72.32962,
              43.600201
            ],
            [
              -72.3296772840816,
              43.6001836405589
            ],
            [
              -72.208313,
              43.585207
            ],
            [
              -72.171188,
              43.573985
            ],
            [
              -72.106412,
              43.553485
            ],
            [
              -72.120176,
              43.538302
            ],
            [
              -72.090893,
              43.528857
            ],
            [
              -72.069516,
              43.553172
            ],
            [
              -72.067165,
              43.543971
            ],
            [
              -71.93531,
              43.52888
            ],
            [
              -71.930992,
              43.533855
            ],
            [
              -71.892112,
              43.574618
            ],
            [
              -71.849203,
              43.6112
            ],
            [
              -71.817921,
              43.544208
            ],
            [
              -71.797489,
              43.559413
            ],
            [
              -71.7775,
              43.552501
            ],
            [
              -71.76102,
              43.567729
            ],
            [
              -71.735583,
              43.56927
            ],
            [
              -71.727573,
              43.563411
            ],
            [
              -71.716066,
              43.578768
            ],
            [
              -71.731707,
              43.59006
            ],
            [
              -71.707704,
              43.601023
            ],
            [
              -71.688096,
              43.59495
            ],
            [
              -71.662689,
              43.606478
            ],
            [
              -71.651153,
              43.621119
            ],
            [
              -71.656936,
              43.631593
            ],
            [
              -71.650741,
              43.645052
            ],
            [
              -71.654999,
              43.661752
            ],
            [
              -71.648346,
              43.684511
            ],
            [
              -71.581403,
              43.691949
            ],
            [
              -71.521466,
              43.697992
            ],
            [
              -71.531658,
              43.760979
            ],
            [
              -71.537754,
              43.79508
            ],
            [
              -71.543805,
              43.796043
            ],
            [
              -71.560621,
              43.875045
            ],
            [
              -71.564603,
              43.893172
            ],
            [
              -71.361965,
              43.915938
            ],
            [
              -71.379546,
              44.001544
            ],
            [
              -71.383613,
              44.022611
            ],
            [
              -71.346656,
              44.068822
            ],
            [
              -71.366355,
              44.068524
            ],
            [
              -71.38163,
              44.097874
            ],
            [
              -71.376518,
              44.11887
            ],
            [
              -71.387138,
              44.155201
            ],
            [
              -71.413171,
              44.167462
            ],
            [
              -71.426507,
              44.210903
            ],
            [
              -71.415898,
              44.212169
            ],
            [
              -71.443338,
              44.249172
            ],
            [
              -71.469706,
              44.259936
            ],
            [
              -71.50584,
              44.255557
            ],
            [
              -71.507412,
              44.262897
            ],
            [
              -71.575819,
              44.254596
            ],
            [
              -71.587004,
              44.304759
            ],
            [
              -71.686921,
              44.336581
            ],
            [
              -71.696885,
              44.332118
            ],
            [
              -71.76568897743229,
              44.406307687658
            ],
            [
              -71.767888,
              44.405445
            ],
            [
              -71.772801,
              44.403097
            ],
            [
              -71.775399,
              44.401126
            ],
            [
              -71.778613,
              44.399799
            ],
            [
              -71.790688,
              44.40026
            ],
            [
              -71.793924,
              44.399271
            ],
            [
              -71.802353,
              44.39338
            ],
            [
              -71.803488,
              44.39189
            ],
            [
              -71.803489,
              44.390384
            ],
            [
              -71.799899,
              44.385951
            ],
            [
              -71.800316,
              44.384276
            ],
            [
              -71.803461,
              44.383335
            ],
            [
              -71.808828,
              44.383862
            ],
            [
              -71.81313,
              44.382801
            ],
            [
              -71.814388,
              44.381932
            ],
            [
              -71.815773,
              44.375464
            ],
            [
              -71.815251,
              44.374594
            ],
            [
              -71.812424,
              44.372532
            ],
            [
              -71.812235,
              44.371492
            ],
            [
              -71.812832,
              44.370448
            ],
            [
              -71.81549,
              44.368836
            ],
            [
              -71.816157,
              44.367559
            ],
            [
              -71.814991,
              44.363686
            ],
            [
              -71.812473,
              44.358477
            ],
            [
              -71.812206,
              44.357356
            ],
            [
              -71.812902,
              44.355547
            ],
            [
              -71.814351,
              44.354541
            ],
            [
              -71.818838,
              44.352939
            ],
            [
              -71.826246,
              44.352006
            ],
            [
              -71.833261,
              44.350136
            ],
            [
              -71.83771955183909,
              44.3477442357108
            ],
            [
              -71.844319,
              44.344204
            ],
            [
              -71.852628,
              44.340873
            ],
            [
              -71.861941,
              44.340109
            ],
            [
              -71.86991,
              44.336962
            ],
            [
              -71.872472,
              44.336628
            ],
            [
              -71.875863,
              44.33737
            ],
            [
              -71.881895,
              44.340209
            ],
            [
              -71.902332,
              44.347499
            ],
            [
              -71.906909,
              44.348284
            ],
            [
              -71.917434,
              44.346535
            ],
            [
              -71.925088,
              44.342024
            ],
            [
              -71.92911,
              44.337577
            ],
            [
              -71.935395,
              44.33577
            ],
            [
              -71.939049,
              44.335844
            ],
            [
              -71.945163,
              44.337744
            ],
            [
              -71.958119,
              44.337544
            ],
            [
              -71.963133,
              44.336556
            ],
            [
              -71.98112,
              44.3375
            ],
            [
              -71.984617,
              44.336243
            ],
            [
              -71.9846456807074,
              44.336165806344404
            ],
            [
              -71.986484,
              44.331218
            ],
            [
              -71.988306,
              44.329768
            ],
            [
              -72.002314,
              44.324871
            ],
            [
              -72.009977,
              44.321951
            ],
            [
              -72.014543,
              44.321032
            ],
            [
              -72.01913,
              44.320383
            ],
            [
              -72.025783,
              44.322054
            ],
            [
              -72.029061,
              44.322398
            ],
            [
              -72.033136,
              44.320365
            ],
            [
              -72.033806,
              44.317349
            ],
            [
              -72.032341,
              44.315752
            ],
            [
              -72.032317,
              44.306677
            ],
            [
              -72.032541,
              44.303752
            ],
            [
              -72.033465,
              44.301878
            ],
            [
              -72.03703,
              44.297834
            ],
            [
              -72.039004,
              44.296463
            ],
            [
              -72.046302,
              44.291983
            ],
            [
              -72.053355,
              44.290501
            ],
            [
              -72.05888,
              44.28624
            ],
            [
              -72.065434,
              44.277235
            ],
            [
              -72.067774,
              44.270976
            ],
            [
              -72.066464,
              44.268331
            ],
            [
              -72.064544,
              44.267997
            ],
            [
              -72.061174,
              44.263377
            ],
            [
              -72.059782,
              44.256018
            ],
            [
              -72.05399,
              44.246926
            ],
            [
              -72.050112,
              44.244046
            ],
            [
              -72.04846,
              44.241212
            ],
            [
              -72.047889,
              44.238493
            ],
            [
              -72.050656,
              44.233581
            ],
            [
              -72.053582,
              44.22604
            ],
            [
              -72.0539,
              44.222703
            ],
            [
              -72.052662,
              44.218841
            ],
            [
              -72.052942903517,
              44.2178743180194
            ],
            [
              -72.053233,
              44.216876
            ],
            [
              -72.058605,
              44.208215
            ],
            [
              -72.058066,
              44.206067
            ],
            [
              -72.058987,
              44.202114
            ],
            [
              -72.060067,
              44.200446
            ],
            [
              -72.063561,
              44.198457
            ],
            [
              -72.064577,
              44.196949
            ],
            [
              -72.066166,
              44.189773
            ],
            [
              -72.061338,
              44.184951
            ],
            [
              -72.057496,
              44.179444
            ],
            [
              -72.053021,
              44.167903
            ],
            [
              -72.047593,
              44.161801
            ],
            [
              -72.042387,
              44.160817
            ],
            [
              -72.0406490709507,
              44.1578468635977
            ],
            [
              -72.040167,
              44.157023
            ],
            [
              -72.040082,
              44.155749
            ],
            [
              -72.042708,
              44.15227
            ],
            [
              -72.042867,
              44.151288
            ],
            [
              -72.041983,
              44.137165
            ],
            [
              -72.037859,
              44.133782
            ],
            [
              -72.034242,
              44.132524
            ],
            [
              -72.033703,
              44.131541
            ],
            [
              -72.037506,
              44.124708
            ],
            [
              -72.038839,
              44.124628
            ],
            [
              -72.040728,
              44.125668
            ],
            [
              -72.041948,
              44.125653
            ],
            [
              -72.04643,
              44.123911
            ],
            [
              -72.052342,
              44.119891
            ],
            [
              -72.054675,
              44.112147
            ],
            [
              -72.054831,
              44.110137
            ],
            [
              -72.039674,
              44.103371
            ],
            [
              -72.036883,
              44.103119
            ],
            [
              -72.032983,
              44.101655
            ],
            [
              -72.03124,
              44.100101
            ],
            [
              -72.031019,
              44.097975
            ],
            [
              -72.031878,
              44.093359
            ],
            [
              -72.032894,
              44.09144
            ],
            [
              -72.03429,
              44.090138
            ],
            [
              -72.036291,
              44.089236
            ],
            [
              -72.040012,
              44.088762
            ],
            [
              -72.046235,
              44.089538
            ],
            [
              -72.047684,
              44.088873
            ],
            [
              -72.048781,
              44.087141
            ],
            [
              -72.047305,
              44.085382
            ],
            [
              -72.039783,
              44.081271
            ],
            [
              -72.042504,
              44.074639
            ],
            [
              -72.04857,
              44.071359
            ],
            [
              -72.048289,
              44.069136
            ],
            [
              -72.053482,
              44.06473
            ],
            [
              -72.056341,
              44.059582
            ],
            [
              -72.057173,
              44.058646
            ],
            [
              -72.058863,
              44.057921
            ],
            [
              -72.062069,
              44.055547
            ],
            [
              -72.062713,
              44.051618
            ],
            [
              -72.066422,
              44.049299
            ],
            [
              -72.074881,
              44.045892
            ],
            [
              -72.077372,
              44.044591
            ],
            [
              -72.078989,
              44.042886
            ],
            [
              -72.079595,
              44.041429
            ],
            [
              -72.079397,
              44.039531
            ],
            [
              -72.075486,
              44.034614
            ],
            [
              -72.075004,
              44.032789
            ],
            [
              -72.075648,
              44.031654
            ],
            [
              -72.0793090203006,
              44.0300626181306
            ],
            [
              -72.07964236374369,
              44.029917719531795
            ],
            [
              -72.079996,
              44.029764
            ],
            [
              -72.081357,
              44.028529
            ],
            [
              -72.081864,
              44.026952
            ],
            [
              -72.085257,
              44.024467
            ],
            [
              -72.090478,
              44.024299
            ],
            [
              -72.09203,
              44.024459
            ],
            [
              -72.094056,
              44.023179
            ],
            [
              -72.0951,
              44.021831
            ],
            [
              -72.095669,
              44.019683
            ],
            [
              -72.098897,
              44.015477
            ],
            [
              -72.102475,
              44.014882
            ],
            [
              -72.105292,
              44.012663
            ],
            [
              -72.104941,
              44.009395
            ],
            [
              -72.103576,
              44.004231
            ],
            [
              -72.103765,
              44.002837
            ],
            [
              -72.109019,
              44.000535
            ],
            [
              -72.116985,
              43.99448
            ],
            [
              -72.116706,
              43.991954
            ],
            [
              -72.112813,
              43.98802
            ],
            [
              -72.111756,
              43.984943
            ],
            [
              -72.11249,
              43.975654
            ],
            [
              -72.113078,
              43.97279
            ],
            [
              -72.114702,
              43.969478
            ],
            [
              -72.114726,
              43.968332
            ],
            [
              -72.114273,
              43.967513
            ],
            [
              -72.110945,
              43.966959
            ],
            [
              -72.100894,
              43.960851
            ],
            [
              -72.098955,
              43.958879
            ],
            [
              -72.098689,
              43.95766
            ],
            [
              -72.104421,
              43.950536
            ],
            [
              -72.105875,
              43.94937
            ],
            [
              -72.110872,
              43.947654
            ],
            [
              -72.115268,
              43.947629
            ],
            [
              -72.117839,
              43.946828
            ],
            [
              -72.118698,
              43.94536
            ],
            [
              -72.118985,
              43.943225
            ],
            [
              -72.116766,
              43.935278
            ],
            [
              -72.116767,
              43.933923
            ],
            [
              -72.118013,
              43.923292
            ],
            [
              -72.11919,
              43.920952
            ],
            [
              -72.121002,
              43.918956
            ],
            [
              -72.135117,
              43.910024
            ],
            [
              -72.145041,
              43.905288
            ],
            [
              -72.151324,
              43.901704
            ],
            [
              -72.155724,
              43.89712
            ],
            [
              -72.158585,
              43.892762
            ],
            [
              -72.159216,
              43.888313
            ],
            [
              -72.160819,
              43.887223
            ],
            [
              -72.167224,
              43.886113
            ],
            [
              -72.170604,
              43.886388
            ],
            [
              -72.171648,
              43.885361
            ],
            [
              -72.172785,
              43.883716
            ],
            [
              -72.173576,
              43.87967
            ],
            [
              -72.171904,
              43.876149
            ],
            [
              -72.16978,
              43.873425
            ],
            [
              -72.1693273369057,
              43.8725850977742
            ],
            [
              -72.1686464134551,
              43.87132166559049
            ],
            [
              -72.167476,
              43.86915
            ],
            [
              -72.174774,
              43.866386
            ],
            [
              -72.179386,
              43.866181
            ],
            [
              -72.182956,
              43.865335
            ],
            [
              -72.184788,
              43.863393
            ],
            [
              -72.187916,
              43.856126
            ],
            [
              -72.187379,
              43.853612
            ],
            [
              -72.182864,
              43.845109
            ],
            [
              -72.182203,
              43.834032
            ],
            [
              -72.183337,
              43.830699
            ],
            [
              -72.186238,
              43.826931
            ],
            [
              -72.188255,
              43.822888
            ],
            [
              -72.18857,
              43.821153
            ],
            [
              -72.186424,
              43.815857
            ],
            [
              -72.184184,
              43.812524
            ],
            [
              -72.183333,
              43.808177
            ],
            [
              -72.184847,
              43.804698
            ],
            [
              -72.190754,
              43.800807
            ],
            [
              -72.193184,
              43.794697
            ],
            [
              -72.195552,
              43.791492
            ],
            [
              -72.197036,
              43.790006
            ],
            [
              -72.20407,
              43.786097
            ],
            [
              -72.2053,
              43.784474
            ],
            [
              -72.205521,
              43.782279
            ],
            [
              -72.20476,
              43.771263
            ],
            [
              -72.20520479908029,
              43.7709441872538
            ],
            [
              -72.207535,
              43.769274
            ],
            [
              -72.210815,
              43.767696
            ],
            [
              -72.216491,
              43.766507
            ],
            [
              -72.218099,
              43.765729
            ],
            [
              -72.220116,
              43.763626
            ],
            [
              -72.222069,
              43.759831
            ],
            [
              -72.223645,
              43.757842
            ],
            [
              -72.232713,
              43.748286
            ],
            [
              -72.245068,
              43.743093
            ],
            [
              -72.264245,
              43.734158
            ],
            [
              -72.27118,
              43.734138
            ],
            [
              -72.276072,
              43.727054
            ],
            [
              -72.279855,
              43.724633
            ],
            [
              -72.284805,
              43.72036
            ],
            [
              -72.28695,
              43.717252
            ],
            [
              -72.292215,
              43.711333
            ],
            [
              -72.294894,
              43.709003
            ],
            [
              -72.299715,
              43.706558
            ],
            [
              -72.302867,
              43.702718
            ],
            [
              -72.305326,
              43.69577
            ],
            [
              -72.3055042006241,
              43.6925066689756
            ],
            [
              -72.3055044139206,
              43.6925027629444
            ],
            [
              -72.30602,
              43.683061
            ],
            [
              -72.304351,
              43.681141
            ],
            [
              -72.303092,
              43.678078
            ],
            [
              -72.303408,
              43.674055
            ],
            [
              -72.304322,
              43.669507
            ],
            [
              -72.305771,
              43.666535
            ],
            [
              -72.310841,
              43.659724
            ],
            [
              -72.312887,
              43.658444
            ],
            [
              -72.31402,
              43.656158
            ],
            [
              -72.315059,
              43.649415
            ],
            [
              -72.313863,
              43.646558
            ],
            [
              -72.314083,
              43.64281
            ],
            [
              -72.315247,
              43.641164
            ],
            [
              -72.322517,
              43.638901
            ],
            [
              -72.327395,
              43.636774
            ],
            [
              -72.329126,
              43.635563
            ],
            [
              -72.32966,
              43.634648
            ],
            [
              -72.329471,
              43.632843
            ],
            [
              -72.327362,
              43.631174
            ],
            [
              -72.327236,
              43.630534
            ],
            [
              -72.328966,
              43.626991
            ],
            [
              -72.33236,
              43.62507
            ],
            [
              -72.334401,
              43.61925
            ]
          ]
        ]
      ]
    }
  }
]
};