export const counties_co = {
  "type":"FeatureCollection","name":"co_counties","features":[
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "021",
      "COUNTYNS": "00198126",
      "AFFGEOID": "0500000US08021",
      "GEOID": "08021",
      "NAME": "Conejos",
      "LSAD": "06",
      "ALAND": 3334423639,
      "AWATER": 9235291,
      "County_state": "Conejos,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -106.678373,
              37.403596
            ],
            [
              -106.678354,
              37.228566
            ],
            [
              -106.597384,
              37.194974
            ],
            [
              -106.589178,
              37.140188
            ],
            [
              -106.489062,
              37.01508
            ],
            [
              -106.47627502252101,
              36.9938393436494
            ],
            [
              -106.343139,
              36.99423
            ],
            [
              -106.293279,
              36.99389
            ],
            [
              -106.248675,
              36.994288
            ],
            [
              -106.247705,
              36.994266
            ],
            [
              -106.201469,
              36.994122
            ],
            [
              -106.006634,
              36.995343
            ],
            [
              -106.00631663454301,
              36.9953455633097
            ],
            [
              -105.997472,
              36.995417
            ],
            [
              -105.996159,
              36.995418
            ],
            [
              -105.718469674138,
              36.995845920037496
            ],
            [
              -105.728471,
              37.014982
            ],
            [
              -105.746517,
              37.014768
            ],
            [
              -105.75195,
              37.027943
            ],
            [
              -105.770673,
              37.041777
            ],
            [
              -105.76412,
              37.056199
            ],
            [
              -105.770171,
              37.061166
            ],
            [
              -105.740837,
              37.103297
            ],
            [
              -105.749162,
              37.112172
            ],
            [
              -105.748834,
              37.135001
            ],
            [
              -105.731404,
              37.156249
            ],
            [
              -105.738035,
              37.165484
            ],
            [
              -105.729755,
              37.180506
            ],
            [
              -105.745765,
              37.218131
            ],
            [
              -105.740878,
              37.240925
            ],
            [
              -105.746099,
              37.247774
            ],
            [
              -105.738779,
              37.263563
            ],
            [
              -105.723914,
              37.274611
            ],
            [
              -105.720084,
              37.290195
            ],
            [
              -105.753191,
              37.343657
            ],
            [
              -105.743339,
              37.356798
            ],
            [
              -106.038912,
              37.356953
            ],
            [
              -106.039331,
              37.400852
            ],
            [
              -106.487968,
              37.39935
            ],
            [
              -106.496094,
              37.401712
            ],
            [
              -106.678373,
              37.403596
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "057",
      "COUNTYNS": "00198144",
      "AFFGEOID": "0500000US08057",
      "GEOID": "08057",
      "NAME": "Jackson",
      "LSAD": "06",
      "ALAND": 4179530271,
      "AWATER": 18750017,
      "County_state": "Jackson,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -106.87109,
              40.979385
            ],
            [
              -106.872652,
              40.970082
            ],
            [
              -106.869526,
              40.959823
            ],
            [
              -106.865212,
              40.954361
            ],
            [
              -106.862241,
              40.946649
            ],
            [
              -106.861042,
              40.940025
            ],
            [
              -106.856235,
              40.936143
            ],
            [
              -106.852126,
              40.931334
            ],
            [
              -106.849362,
              40.925918
            ],
            [
              -106.848459,
              40.924291
            ],
            [
              -106.84383,
              40.92489
            ],
            [
              -106.837778,
              40.924102
            ],
            [
              -106.828021,
              40.925162
            ],
            [
              -106.825884,
              40.928186
            ],
            [
              -106.823332,
              40.929766
            ],
            [
              -106.822726,
              40.928098
            ],
            [
              -106.818597,
              40.921554
            ],
            [
              -106.815298,
              40.917339
            ],
            [
              -106.809039,
              40.90999
            ],
            [
              -106.805621,
              40.902728
            ],
            [
              -106.807526,
              40.900551
            ],
            [
              -106.806009,
              40.897996
            ],
            [
              -106.802068,
              40.896909
            ],
            [
              -106.794857,
              40.899021
            ],
            [
              -106.78881,
              40.900714
            ],
            [
              -106.786979,
              40.902907
            ],
            [
              -106.779862,
              40.906727
            ],
            [
              -106.772044,
              40.904727
            ],
            [
              -106.76771,
              40.89939
            ],
            [
              -106.765559,
              40.896343
            ],
            [
              -106.758367,
              40.894108
            ],
            [
              -106.750597,
              40.8938
            ],
            [
              -106.743264,
              40.891744
            ],
            [
              -106.743572,
              40.886159
            ],
            [
              -106.745448,
              40.879501
            ],
            [
              -106.74191,
              40.874119
            ],
            [
              -106.732285,
              40.872086
            ],
            [
              -106.706783,
              40.875675
            ],
            [
              -106.700189,
              40.882648
            ],
            [
              -106.695675,
              40.88727
            ],
            [
              -106.688792,
              40.886866
            ],
            [
              -106.686007,
              40.882912
            ],
            [
              -106.681392,
              40.874971
            ],
            [
              -106.678299,
              40.868279
            ],
            [
              -106.668769,
              40.865487
            ],
            [
              -106.665147,
              40.858812
            ],
            [
              -106.663095,
              40.856274
            ],
            [
              -106.656431,
              40.851487
            ],
            [
              -106.657917,
              40.844547
            ],
            [
              -106.661676,
              40.839966
            ],
            [
              -106.663656,
              40.833596
            ],
            [
              -106.664014,
              40.829285
            ],
            [
              -106.661096,
              40.826921
            ],
            [
              -106.659012,
              40.824109
            ],
            [
              -106.653577,
              40.823261
            ],
            [
              -106.644372,
              40.821489
            ],
            [
              -106.637593,
              40.813306
            ],
            [
              -106.636545,
              40.803717
            ],
            [
              -106.63661,
              40.795421
            ],
            [
              -106.641549,
              40.786163
            ],
            [
              -106.649087,
              40.780577
            ],
            [
              -106.650403,
              40.775655
            ],
            [
              -106.661016,
              40.771218
            ],
            [
              -106.664794,
              40.765534
            ],
            [
              -106.667328,
              40.758766
            ],
            [
              -106.66241,
              40.754049
            ],
            [
              -106.663805,
              40.747233
            ],
            [
              -106.673473,
              40.737167
            ],
            [
              -106.670377,
              40.731313
            ],
            [
              -106.669784,
              40.719788
            ],
            [
              -106.672551,
              40.708002
            ],
            [
              -106.668241,
              40.696837
            ],
            [
              -106.6739,
              40.687857
            ],
            [
              -106.679683,
              40.684675
            ],
            [
              -106.684676,
              40.679616
            ],
            [
              -106.681783,
              40.676755
            ],
            [
              -106.688505,
              40.661187
            ],
            [
              -106.684224,
              40.649449
            ],
            [
              -106.686016,
              40.641441
            ],
            [
              -106.696445,
              40.630628
            ],
            [
              -106.701893,
              40.626849
            ],
            [
              -106.706149,
              40.616597
            ],
            [
              -106.702214,
              40.605158
            ],
            [
              -106.695059,
              40.597961
            ],
            [
              -106.698003,
              40.588764
            ],
            [
              -106.69997,
              40.581789
            ],
            [
              -106.699975,
              40.57575
            ],
            [
              -106.692188,
              40.563537
            ],
            [
              -106.684964,
              40.54946
            ],
            [
              -106.682471,
              40.544558
            ],
            [
              -106.677915,
              40.543132
            ],
            [
              -106.669845,
              40.534446
            ],
            [
              -106.672612,
              40.52802
            ],
            [
              -106.667101,
              40.520143
            ],
            [
              -106.65907,
              40.514582
            ],
            [
              -106.656116,
              40.509454
            ],
            [
              -106.654919,
              40.497789
            ],
            [
              -106.659018,
              40.489779
            ],
            [
              -106.660631,
              40.481448
            ],
            [
              -106.664772,
              40.477118
            ],
            [
              -106.663489,
              40.470583
            ],
            [
              -106.658202,
              40.464986
            ],
            [
              -106.65634,
              40.451339
            ],
            [
              -106.652112,
              40.445231
            ],
            [
              -106.650957,
              40.440927
            ],
            [
              -106.632023,
              40.432471
            ],
            [
              -106.624896,
              40.428483
            ],
            [
              -106.626357,
              40.416476
            ],
            [
              -106.622443,
              40.40959
            ],
            [
              -106.618281,
              40.400027
            ],
            [
              -106.614076,
              40.389882
            ],
            [
              -106.611966,
              40.381635
            ],
            [
              -106.61004,
              40.378493
            ],
            [
              -106.602808,
              40.376917
            ],
            [
              -106.597837,
              40.3779
            ],
            [
              -106.596703,
              40.380354
            ],
            [
              -106.592545,
              40.38112
            ],
            [
              -106.586587,
              40.381886
            ],
            [
              -106.574152,
              40.378515
            ],
            [
              -106.564075,
              40.37785
            ],
            [
              -106.556344,
              40.376412
            ],
            [
              -106.55587,
              40.374981
            ],
            [
              -106.554667,
              40.371528
            ],
            [
              -106.548648,
              40.370331
            ],
            [
              -106.545357,
              40.371151
            ],
            [
              -106.540833,
              40.371454
            ],
            [
              -106.537527,
              40.367652
            ],
            [
              -106.532921,
              40.366191
            ],
            [
              -106.529539,
              40.371784
            ],
            [
              -106.527434,
              40.377458
            ],
            [
              -106.526734,
              40.382266
            ],
            [
              -106.521839,
              40.387469
            ],
            [
              -106.514688,
              40.38833
            ],
            [
              -106.507124,
              40.396194
            ],
            [
              -106.498827,
              40.403594
            ],
            [
              -106.492747,
              40.407976
            ],
            [
              -106.477897,
              40.401041
            ],
            [
              -106.477817,
              40.389251
            ],
            [
              -106.470586,
              40.379034
            ],
            [
              -106.463211,
              40.367824
            ],
            [
              -106.455595,
              40.358825
            ],
            [
              -106.450924,
              40.351545
            ],
            [
              -106.444334,
              40.347682
            ],
            [
              -106.439525,
              40.348792
            ],
            [
              -106.434475,
              40.349828
            ],
            [
              -106.429228,
              40.351382
            ],
            [
              -106.42403,
              40.35231
            ],
            [
              -106.415659,
              40.350427
            ],
            [
              -106.408943,
              40.348388
            ],
            [
              -106.404727,
              40.345479
            ],
            [
              -106.396503,
              40.346991
            ],
            [
              -106.391911,
              40.345825
            ],
            [
              -106.387031,
              40.349168
            ],
            [
              -106.383821,
              40.352782
            ],
            [
              -106.376667,
              40.35522
            ],
            [
              -106.367589,
              40.35608
            ],
            [
              -106.357989,
              40.358073
            ],
            [
              -106.350764,
              40.354214
            ],
            [
              -106.344856,
              40.349634
            ],
            [
              -106.341465,
              40.345499
            ],
            [
              -106.337344,
              40.341625
            ],
            [
              -106.333357,
              40.339092
            ],
            [
              -106.32346,
              40.33787
            ],
            [
              -106.317578,
              40.333999
            ],
            [
              -106.312241,
              40.330184
            ],
            [
              -106.305208,
              40.330121
            ],
            [
              -106.300764,
              40.330473
            ],
            [
              -106.296106,
              40.334601
            ],
            [
              -106.293906,
              40.337828
            ],
            [
              -106.298557,
              40.344178
            ],
            [
              -106.29763,
              40.350361
            ],
            [
              -106.293661,
              40.355742
            ],
            [
              -106.289553,
              40.35821
            ],
            [
              -106.284617,
              40.356594
            ],
            [
              -106.279972,
              40.354733
            ],
            [
              -106.274669,
              40.355601
            ],
            [
              -106.270822,
              40.357176
            ],
            [
              -106.267459,
              40.360022
            ],
            [
              -106.259478,
              40.357296
            ],
            [
              -106.257168,
              40.353986
            ],
            [
              -106.253645,
              40.347199
            ],
            [
              -106.251151,
              40.341038
            ],
            [
              -106.244064,
              40.336564
            ],
            [
              -106.239281,
              40.328439
            ],
            [
              -106.231523,
              40.324795
            ],
            [
              -106.22252,
              40.325746
            ],
            [
              -106.213783,
              40.327976
            ],
            [
              -106.204625,
              40.327311
            ],
            [
              -106.19806,
              40.32649
            ],
            [
              -106.190902,
              40.326308
            ],
            [
              -106.180338,
              40.333808
            ],
            [
              -106.176051,
              40.341339
            ],
            [
              -106.166306,
              40.343355
            ],
            [
              -106.157612,
              40.338981
            ],
            [
              -106.147527,
              40.33209
            ],
            [
              -106.142627,
              40.33018
            ],
            [
              -106.136033,
              40.331378
            ],
            [
              -106.135214,
              40.333646
            ],
            [
              -106.127243,
              40.338896
            ],
            [
              -106.115649,
              40.346099
            ],
            [
              -106.092598,
              40.350417
            ],
            [
              -106.074166,
              40.351118
            ],
            [
              -106.048509,
              40.360685
            ],
            [
              -106.024561,
              40.371777
            ],
            [
              -106.013326,
              40.370508
            ],
            [
              -106.01113,
              40.363956
            ],
            [
              -105.999837,
              40.362725
            ],
            [
              -105.991215,
              40.364203
            ],
            [
              -105.978905,
              40.358358
            ],
            [
              -105.971642,
              40.350905
            ],
            [
              -105.96615,
              40.349523
            ],
            [
              -105.959723,
              40.349381
            ],
            [
              -105.957473,
              40.351956
            ],
            [
              -105.954684,
              40.358538
            ],
            [
              -105.944637,
              40.364053
            ],
            [
              -105.938892,
              40.36741
            ],
            [
              -105.938918,
              40.372704
            ],
            [
              -105.943073,
              40.374983
            ],
            [
              -105.94215,
              40.384848
            ],
            [
              -105.925616,
              40.392342
            ],
            [
              -105.912543,
              40.401128
            ],
            [
              -105.907797,
              40.400468
            ],
            [
              -105.902313,
              40.410258
            ],
            [
              -105.898002,
              40.422271
            ],
            [
              -105.902176,
              40.435656
            ],
            [
              -105.899824,
              40.4545
            ],
            [
              -105.896762,
              40.469295
            ],
            [
              -105.888693,
              40.476413
            ],
            [
              -105.877466,
              40.477665
            ],
            [
              -105.86336,
              40.480501
            ],
            [
              -105.854926,
              40.486252
            ],
            [
              -105.852303,
              40.506553
            ],
            [
              -105.869091,
              40.520738
            ],
            [
              -105.907857,
              40.515493
            ],
            [
              -105.90509,
              40.535285
            ],
            [
              -105.910892,
              40.56642
            ],
            [
              -105.921248,
              40.572559
            ],
            [
              -105.930207,
              40.60619
            ],
            [
              -105.942064,
              40.609077
            ],
            [
              -105.948325,
              40.641745
            ],
            [
              -105.971873,
              40.681856
            ],
            [
              -105.972196,
              40.694811
            ],
            [
              -105.988739,
              40.709605
            ],
            [
              -105.99627,
              40.733126
            ],
            [
              -106.0107,
              40.741041
            ],
            [
              -106.029247,
              40.773436
            ],
            [
              -106.045371,
              40.791857
            ],
            [
              -106.047971,
              40.804643
            ],
            [
              -106.064831,
              40.821567
            ],
            [
              -106.087914,
              40.833403
            ],
            [
              -106.108132,
              40.852232
            ],
            [
              -106.108404,
              40.863258
            ],
            [
              -106.126893,
              40.869556
            ],
            [
              -106.136622,
              40.901859
            ],
            [
              -106.16817,
              40.918398
            ],
            [
              -106.187599,
              40.937745
            ],
            [
              -106.184226,
              40.955207
            ],
            [
              -106.195372,
              40.989938
            ],
            [
              -106.190553863626,
              40.9976070173843
            ],
            [
              -106.217573,
              40.997734
            ],
            [
              -106.321165,
              40.999123
            ],
            [
              -106.386356,
              41.001144
            ],
            [
              -106.391852,
              41.001176
            ],
            [
              -106.43095,
              41.001752
            ],
            [
              -106.437419,
              41.001795
            ],
            [
              -106.439563,
              41.001978
            ],
            [
              -106.453859,
              41.002057
            ],
            [
              -106.857773,
              41.002663
            ],
            [
              -106.85993,
              41.001033
            ],
            [
              -106.858369,
              40.994724
            ],
            [
              -106.853497,
              40.992535
            ],
            [
              -106.854614,
              40.987892
            ],
            [
              -106.855638,
              40.985247
            ],
            [
              -106.858657,
              40.982811
            ],
            [
              -106.860747,
              40.983471
            ],
            [
              -106.87109,
              40.979385
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "007",
      "COUNTYNS": "00198119",
      "AFFGEOID": "0500000US08007",
      "GEOID": "08007",
      "NAME": "Archuleta",
      "LSAD": "06",
      "ALAND": 3496731609,
      "AWATER": 13720776,
      "County_state": "Archuleta,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -107.482131,
              37.422673
            ],
            [
              -107.481736999155,
              37.0000045803143
            ],
            [
              -107.420921905745,
              37.0000049999386
            ],
            [
              -107.420913,
              37.000005
            ],
            [
              -106.985514007228,
              37.0001123237881
            ],
            [
              -106.877292,
              37.000139
            ],
            [
              -106.869796,
              36.992426
            ],
            [
              -106.750591,
              36.992461
            ],
            [
              -106.675626,
              36.993123
            ],
            [
              -106.661344,
              36.993243
            ],
            [
              -106.628733,
              36.993161
            ],
            [
              -106.628652,
              36.993175
            ],
            [
              -106.617125,
              36.993004
            ],
            [
              -106.617159,
              36.992967
            ],
            [
              -106.500589,
              36.993768
            ],
            [
              -106.47627502252101,
              36.9938393436494
            ],
            [
              -106.489062,
              37.01508
            ],
            [
              -106.589178,
              37.140188
            ],
            [
              -106.597384,
              37.194974
            ],
            [
              -106.678354,
              37.228566
            ],
            [
              -106.678373,
              37.403596
            ],
            [
              -106.710775,
              37.404228
            ],
            [
              -106.710767,
              37.396238
            ],
            [
              -107.128737,
              37.392409
            ],
            [
              -107.12868,
              37.422942
            ],
            [
              -107.482131,
              37.422673
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "121",
      "COUNTYNS": "00198176",
      "AFFGEOID": "0500000US08121",
      "GEOID": "08121",
      "NAME": "Washington",
      "LSAD": "06",
      "ALAND": 6521806786,
      "AWATER": 15207400,
      "County_state": "Washington,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -103.706547,
              39.739894
            ],
            [
              -103.707072,
              39.566295
            ],
            [
              -103.161515,
              39.565996
            ],
            [
              -103.154376,
              39.565654
            ],
            [
              -102.802932,
              39.567841
            ],
            [
              -102.803767,
              40.002554
            ],
            [
              -102.793305,
              40.002493
            ],
            [
              -102.793582,
              40.351281
            ],
            [
              -102.78018,
              40.351144
            ],
            [
              -102.77996,
              40.438446
            ],
            [
              -103.387065,
              40.435438
            ],
            [
              -103.40705,
              40.43636
            ],
            [
              -103.465377,
              40.436346
            ],
            [
              -103.470982,
              40.349759
            ],
            [
              -103.47199,
              40.001502
            ],
            [
              -103.705701,
              40.001366
            ],
            [
              -103.706547,
              39.739894
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "045",
      "COUNTYNS": "00198138",
      "AFFGEOID": "0500000US08045",
      "GEOID": "08045",
      "NAME": "Garfield",
      "LSAD": "06",
      "ALAND": 7634095739,
      "AWATER": 21408198,
      "County_state": "Garfield,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -109.05136294195901,
              39.497703276010604
            ],
            [
              -109.051363,
              39.497674
            ],
            [
              -109.050765,
              39.366677
            ],
            [
              -108.409607,
              39.365983
            ],
            [
              -107.430949,
              39.366178
            ],
            [
              -107.113446,
              39.366066
            ],
            [
              -107.113672,
              39.919112
            ],
            [
              -107.033968,
              39.918913
            ],
            [
              -107.03205,
              40.002939
            ],
            [
              -107.037363,
              40.091538
            ],
            [
              -107.316993,
              40.090765
            ],
            [
              -107.319247,
              40.003765
            ],
            [
              -107.318751,
              39.914968
            ],
            [
              -107.431665,
              39.917725
            ],
            [
              -107.43177,
              39.828377
            ],
            [
              -107.694229,
              39.830107
            ],
            [
              -107.787206,
              39.825047
            ],
            [
              -107.938502,
              39.824996
            ],
            [
              -107.938546,
              39.694464
            ],
            [
              -108.554722,
              39.69364
            ],
            [
              -108.554627,
              39.650164
            ],
            [
              -108.895728,
              39.650337
            ],
            [
              -108.895704,
              39.660487
            ],
            [
              -109.051040251034,
              39.660470649351794
            ],
            [
              -109.05136294195901,
              39.497703276010604
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "105",
      "COUNTYNS": "00198168",
      "AFFGEOID": "0500000US08105",
      "GEOID": "08105",
      "NAME": "Rio Grande",
      "LSAD": "06",
      "ALAND": 2361956102,
      "AWATER": 984295,
      "County_state": "Rio Grande,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -106.711624,
              37.664273
            ],
            [
              -106.710775,
              37.404228
            ],
            [
              -106.678373,
              37.403596
            ],
            [
              -106.496094,
              37.401712
            ],
            [
              -106.487968,
              37.39935
            ],
            [
              -106.039331,
              37.400852
            ],
            [
              -106.038413,
              37.748353
            ],
            [
              -106.586374,
              37.747809
            ],
            [
              -106.587139,
              37.834426
            ],
            [
              -106.693845,
              37.835244
            ],
            [
              -106.695638,
              37.664271
            ],
            [
              -106.711624,
              37.664273
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "087",
      "COUNTYNS": "00198159",
      "AFFGEOID": "0500000US08087",
      "GEOID": "08087",
      "NAME": "Morgan",
      "LSAD": "06",
      "ALAND": 3316441507,
      "AWATER": 34663099,
      "County_state": "Morgan,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -104.150494,
              40.350245
            ],
            [
              -104.150332,
              40.000856
            ],
            [
              -103.705701,
              40.001366
            ],
            [
              -103.47199,
              40.001502
            ],
            [
              -103.470982,
              40.349759
            ],
            [
              -103.465377,
              40.436346
            ],
            [
              -103.4672,
              40.523253
            ],
            [
              -103.581844,
              40.523383
            ],
            [
              -104.147714,
              40.524389
            ],
            [
              -104.150494,
              40.350245
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "039",
      "COUNTYNS": "00198136",
      "AFFGEOID": "0500000US08039",
      "GEOID": "08039",
      "NAME": "Elbert",
      "LSAD": "06",
      "ALAND": 4793658887,
      "AWATER": 442148,
      "County_state": "Elbert,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -104.660626,
              39.565909
            ],
            [
              -104.662896,
              39.129527
            ],
            [
              -104.551765,
              39.129427
            ],
            [
              -104.051568,
              39.128138
            ],
            [
              -104.055528,
              38.868868
            ],
            [
              -103.719664,
              38.866827
            ],
            [
              -103.721152,
              39.128307
            ],
            [
              -103.714355,
              39.566331
            ],
            [
              -104.642125,
              39.56589
            ],
            [
              -104.660626,
              39.565909
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "085",
      "COUNTYNS": "00198158",
      "AFFGEOID": "0500000US08085",
      "GEOID": "08085",
      "NAME": "Montrose",
      "LSAD": "06",
      "ALAND": 5804023642,
      "AWATER": 4923261,
      "County_state": "Montrose,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -109.059962,
              38.499987
            ],
            [
              -109.060062,
              38.275489
            ],
            [
              -109.054648,
              38.244921
            ],
            [
              -109.041762,
              38.16469
            ],
            [
              -109.04183657611101,
              38.153032090353896
            ],
            [
              -108.910366,
              38.15335
            ],
            [
              -108.906749,
              38.154959
            ],
            [
              -107.965789,
              38.152328
            ],
            [
              -107.946963,
              38.152491
            ],
            [
              -107.947744,
              38.173767
            ],
            [
              -107.938145,
              38.173936
            ],
            [
              -107.937588,
              38.218992
            ],
            [
              -107.947142,
              38.226477
            ],
            [
              -107.965408,
              38.226509
            ],
            [
              -107.965419,
              38.234015
            ],
            [
              -107.984184,
              38.233936
            ],
            [
              -107.984084,
              38.248386
            ],
            [
              -108.011531,
              38.248203
            ],
            [
              -108.011531,
              38.255261
            ],
            [
              -108.086604,
              38.255202
            ],
            [
              -108.08635,
              38.284334
            ],
            [
              -108.104161,
              38.284179
            ],
            [
              -108.104819,
              38.313718
            ],
            [
              -108.123269,
              38.313635
            ],
            [
              -108.13238,
              38.331573
            ],
            [
              -107.866597,
              38.332205
            ],
            [
              -107.635697,
              38.332148
            ],
            [
              -107.63504,
              38.301896
            ],
            [
              -107.50002,
              38.301926
            ],
            [
              -107.500655,
              38.668553
            ],
            [
              -107.998889,
              38.668476
            ],
            [
              -108.378698,
              38.668097
            ],
            [
              -108.378665,
              38.508223
            ],
            [
              -108.379136,
              38.499987
            ],
            [
              -109.059962005949,
              38.4999890310142
            ],
            [
              -109.059962,
              38.499987
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "089",
      "COUNTYNS": "00198160",
      "AFFGEOID": "0500000US08089",
      "GEOID": "08089",
      "NAME": "Otero",
      "LSAD": "06",
      "ALAND": 3268891492,
      "AWATER": 19625371,
      "County_state": "Otero,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -104.060069,
              37.82303
            ],
            [
              -104.061132,
              37.734704
            ],
            [
              -104.059188,
              37.726203
            ],
            [
              -104.058196,
              37.644049
            ],
            [
              -103.404339,
              37.643576
            ],
            [
              -103.403669,
              37.818431
            ],
            [
              -103.399854,
              37.822812
            ],
            [
              -103.399938,
              38.265435
            ],
            [
              -103.501764,
              38.265016
            ],
            [
              -103.509879,
              38.264091
            ],
            [
              -103.509364,
              38.172514
            ],
            [
              -103.619283,
              38.171455
            ],
            [
              -103.619172,
              38.113355
            ],
            [
              -103.803033,
              38.112533
            ],
            [
              -103.81191,
              38.117845
            ],
            [
              -103.835616,
              38.11334
            ],
            [
              -103.846211,
              38.123405
            ],
            [
              -103.868672,
              38.128809
            ],
            [
              -103.878001,
              38.124649
            ],
            [
              -103.923399,
              38.130424
            ],
            [
              -103.933989,
              38.124417
            ],
            [
              -103.974378,
              38.132119
            ],
            [
              -103.974128,
              38.138627
            ],
            [
              -103.994696,
              38.146654
            ],
            [
              -104.022159,
              38.140951
            ],
            [
              -104.047045,
              38.141389
            ],
            [
              -104.058242,
              38.146492
            ],
            [
              -104.060069,
              37.82303
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "119",
      "COUNTYNS": "00198175",
      "AFFGEOID": "0500000US08119",
      "GEOID": "08119",
      "NAME": "Teller",
      "LSAD": "06",
      "ALAND": 1442734233,
      "AWATER": 4944578,
      "County_state": "Teller,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -105.32922,
              39.129689
            ],
            [
              -105.330444,
              39.129685
            ],
            [
              -105.329134,
              38.697205
            ],
            [
              -105.237792,
              38.696901
            ],
            [
              -105.240362,
              38.647595
            ],
            [
              -105.220036,
              38.647652
            ],
            [
              -105.071529,
              38.64855
            ],
            [
              -105.053926,
              38.653096
            ],
            [
              -104.942426,
              38.649882
            ],
            [
              -104.939286,
              38.73698
            ],
            [
              -104.938994,
              38.796884
            ],
            [
              -105.030619,
              38.797133
            ],
            [
              -105.072184,
              38.799382
            ],
            [
              -105.0667,
              38.869508
            ],
            [
              -105.028903,
              38.868907
            ],
            [
              -105.027655,
              39.019542
            ],
            [
              -105.026945,
              39.029831
            ],
            [
              -105.033544,
              39.129819
            ],
            [
              -105.32922,
              39.129689
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "095",
      "COUNTYNS": "00198163",
      "AFFGEOID": "0500000US08095",
      "GEOID": "08095",
      "NAME": "Phillips",
      "LSAD": "06",
      "ALAND": 1781724973,
      "AWATER": 301808,
      "County_state": "Phillips,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -102.664689,
              40.705189
            ],
            [
              -102.664804,
              40.438473
            ],
            [
              -102.051465,
              40.440008
            ],
            [
              -102.051519,
              40.520094
            ],
            [
              -102.051725,
              40.537839
            ],
            [
              -102.05139843250801,
              40.697541816873404
            ],
            [
              -102.05129201074301,
              40.74958574646119
            ],
            [
              -102.65131,
              40.749402
            ],
            [
              -102.65088,
              40.70534
            ],
            [
              -102.664689,
              40.705189
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "125",
      "COUNTYNS": "00198178",
      "AFFGEOID": "0500000US08125",
      "GEOID": "08125",
      "NAME": "Yuma",
      "LSAD": "06",
      "ALAND": 6123763472,
      "AWATER": 11134666,
      "County_state": "Yuma,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -102.803767,
              40.002554
            ],
            [
              -102.802932,
              39.567841
            ],
            [
              -102.570862,
              39.569294
            ],
            [
              -102.552028,
              39.570639
            ],
            [
              -102.049806,
              39.574058
            ],
            [
              -102.049954,
              39.592331
            ],
            [
              -102.050422,
              39.646048
            ],
            [
              -102.050099,
              39.653812
            ],
            [
              -102.050594,
              39.675594
            ],
            [
              -102.051254,
              39.818992
            ],
            [
              -102.051318,
              39.833311
            ],
            [
              -102.051363,
              39.843471
            ],
            [
              -102.051569,
              39.849805
            ],
            [
              -102.051744,
              40.003078
            ],
            [
              -102.052001,
              40.148359
            ],
            [
              -102.051909,
              40.162674
            ],
            [
              -102.051894,
              40.229193
            ],
            [
              -102.051922,
              40.235344
            ],
            [
              -102.051309,
              40.338381
            ],
            [
              -102.051553251676,
              40.3492139863953
            ],
            [
              -102.051798,
              40.360069
            ],
            [
              -102.051572,
              40.39308
            ],
            [
              -102.05184,
              40.396396
            ],
            [
              -102.051465,
              40.440008
            ],
            [
              -102.664804,
              40.438473
            ],
            [
              -102.77996,
              40.438446
            ],
            [
              -102.78018,
              40.351144
            ],
            [
              -102.793582,
              40.351281
            ],
            [
              -102.793305,
              40.002493
            ],
            [
              -102.803767,
              40.002554
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "101",
      "COUNTYNS": "00198166",
      "AFFGEOID": "0500000US08101",
      "GEOID": "08101",
      "NAME": "Pueblo",
      "LSAD": "06",
      "ALAND": 6180089507,
      "AWATER": 30094812,
      "County_state": "Pueblo,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -105.050678,
              38.113824
            ],
            [
              -105.049917,
              37.915479
            ],
            [
              -105.034803,
              37.894583
            ],
            [
              -105.013729,
              37.881271
            ],
            [
              -104.828794,
              37.891127
            ],
            [
              -104.666059,
              37.899521
            ],
            [
              -104.646383,
              37.900527
            ],
            [
              -104.351109,
              37.817488
            ],
            [
              -104.061132,
              37.734704
            ],
            [
              -104.060069,
              37.82303
            ],
            [
              -104.058242,
              38.146492
            ],
            [
              -104.054725,
              38.261889
            ],
            [
              -104.053921,
              38.522393
            ],
            [
              -104.567087,
              38.520001
            ],
            [
              -104.630682,
              38.519917
            ],
            [
              -104.932949,
              38.520286
            ],
            [
              -104.941533,
              38.519569
            ],
            [
              -104.940889,
              38.372026
            ],
            [
              -104.940366,
              38.258275
            ],
            [
              -105.049215,
              38.257973
            ],
            [
              -105.048287,
              38.127773
            ],
            [
              -105.050678,
              38.113824
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "003",
      "COUNTYNS": "00198117",
      "AFFGEOID": "0500000US08003",
      "GEOID": "08003",
      "NAME": "Alamosa",
      "LSAD": "06",
      "ALAND": 1871465873,
      "AWATER": 1847609,
      "County_state": "Alamosa,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -106.039331,
              37.400852
            ],
            [
              -106.038912,
              37.356953
            ],
            [
              -105.743339,
              37.356798
            ],
            [
              -105.49631,
              37.563852
            ],
            [
              -105.485516,
              37.577899
            ],
            [
              -105.501755,
              37.597571
            ],
            [
              -105.486976,
              37.653483
            ],
            [
              -105.477351,
              37.665747
            ],
            [
              -105.477302,
              37.707686
            ],
            [
              -105.454245,
              37.723951
            ],
            [
              -105.457255,
              37.751464
            ],
            [
              -106.038413,
              37.748353
            ],
            [
              -106.039331,
              37.400852
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "009",
      "COUNTYNS": "00198120",
      "AFFGEOID": "0500000US08009",
      "GEOID": "08009",
      "NAME": "Baca",
      "LSAD": "06",
      "ALAND": 6617400584,
      "AWATER": 6142193,
      "County_state": "Baca,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -103.082537,
              37.381613
            ],
            [
              -103.086102011771,
              37.000173863231296
            ],
            [
              -103.002199,
              37.000104
            ],
            [
              -102.986976,
              36.998524
            ],
            [
              -102.985807,
              36.998571
            ],
            [
              -102.979613,
              36.998549
            ],
            [
              -102.841989,
              36.999598
            ],
            [
              -102.817142,
              36.999768
            ],
            [
              -102.799802,
              36.999489
            ],
            [
              -102.778569,
              36.999242
            ],
            [
              -102.759882,
              36.998529
            ],
            [
              -102.74206,
              36.997689
            ],
            [
              -102.698142,
              36.995149
            ],
            [
              -102.355367,
              36.994575
            ],
            [
              -102.355288,
              36.994506
            ],
            [
              -102.260789,
              36.994388
            ],
            [
              -102.208316,
              36.99373
            ],
            [
              -102.184271,
              36.993593
            ],
            [
              -102.054503,
              36.993109
            ],
            [
              -102.04224,
              36.993083
            ],
            [
              -102.041952,
              37.024742
            ],
            [
              -102.04195,
              37.030805
            ],
            [
              -102.041921,
              37.032178
            ],
            [
              -102.041749,
              37.034397
            ],
            [
              -102.04192,
              37.035083
            ],
            [
              -102.041983,
              37.106551
            ],
            [
              -102.041809,
              37.111973
            ],
            [
              -102.042092,
              37.125021
            ],
            [
              -102.042135,
              37.125021
            ],
            [
              -102.042002,
              37.141744
            ],
            [
              -102.041963,
              37.258164
            ],
            [
              -102.041664,
              37.29765
            ],
            [
              -102.041817,
              37.30949
            ],
            [
              -102.041974,
              37.352613
            ],
            [
              -102.042089,
              37.352819
            ],
            [
              -102.041524,
              37.375018
            ],
            [
              -102.041585760593,
              37.3891904308032
            ],
            [
              -102.041676,
              37.409898
            ],
            [
              -102.041669,
              37.43474
            ],
            [
              -102.041755,
              37.434855
            ],
            [
              -102.041801,
              37.469488
            ],
            [
              -102.041786,
              37.506066
            ],
            [
              -102.042016,
              37.535261
            ],
            [
              -102.041899,
              37.541186
            ],
            [
              -102.041894,
              37.557977
            ],
            [
              -102.041618,
              37.607868
            ],
            [
              -102.041585,
              37.644282
            ],
            [
              -102.747615,
              37.643642
            ],
            [
              -103.024042,
              37.644363
            ],
            [
              -103.075938,
              37.64342
            ],
            [
              -103.074663,
              37.381754
            ],
            [
              -103.082537,
              37.381613
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "083",
      "COUNTYNS": "00198157",
      "AFFGEOID": "0500000US08083",
      "GEOID": "08083",
      "NAME": "Montezuma",
      "LSAD": "06",
      "ALAND": 5255937110,
      "AWATER": 27261088,
      "County_state": "Montezuma,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -109.04581,
              37.374993
            ],
            [
              -109.046039,
              37.249993
            ],
            [
              -109.045584,
              37.249351
            ],
            [
              -109.045487,
              37.210844
            ],
            [
              -109.045978,
              37.201831
            ],
            [
              -109.045995,
              37.177279
            ],
            [
              -109.045156,
              37.112064
            ],
            [
              -109.045203,
              37.111958
            ],
            [
              -109.045173,
              37.109464
            ],
            [
              -109.045189,
              37.096271
            ],
            [
              -109.044995,
              37.086429
            ],
            [
              -109.045058,
              37.074661
            ],
            [
              -109.045166,
              37.072742
            ],
            [
              -109.045223,
              36.999084
            ],
            [
              -108.958868,
              36.998913
            ],
            [
              -108.954404,
              36.998906
            ],
            [
              -108.620309,
              36.999287
            ],
            [
              -108.619689,
              36.999249
            ],
            [
              -108.536412308881,
              36.9993217008121
            ],
            [
              -108.379302869798,
              36.999458857844296
            ],
            [
              -108.380293,
              37.000513
            ],
            [
              -108.379612,
              37.004177
            ],
            [
              -108.377179,
              37.007972
            ],
            [
              -108.375064,
              37.015032
            ],
            [
              -108.372597,
              37.022766
            ],
            [
              -108.367739,
              37.028467
            ],
            [
              -108.36288,
              37.027182
            ],
            [
              -108.359023,
              37.025938
            ],
            [
              -108.35644,
              37.028431
            ],
            [
              -108.353927,
              37.034065
            ],
            [
              -108.352459,
              37.039433
            ],
            [
              -108.344882,
              37.048601
            ],
            [
              -108.334356,
              37.054871
            ],
            [
              -108.333452,
              37.061446
            ],
            [
              -108.328266,
              37.069121
            ],
            [
              -108.328056,
              37.075645
            ],
            [
              -108.324535,
              37.080478
            ],
            [
              -108.323263,
              37.086501
            ],
            [
              -108.322683,
              37.091541
            ],
            [
              -108.318759,
              37.097174
            ],
            [
              -108.315502,
              37.10324
            ],
            [
              -108.310591,
              37.107984
            ],
            [
              -108.311526,
              37.114858
            ],
            [
              -108.31056,
              37.12217
            ],
            [
              -108.309042,
              37.130476
            ],
            [
              -108.292519,
              37.145336
            ],
            [
              -108.29441,
              37.157172
            ],
            [
              -108.296419,
              37.168136
            ],
            [
              -108.295869,
              37.187698
            ],
            [
              -108.29263,
              37.198319
            ],
            [
              -108.295752,
              37.206372
            ],
            [
              -108.293796,
              37.21737
            ],
            [
              -108.285279,
              37.225646
            ],
            [
              -108.276913,
              37.228704
            ],
            [
              -108.264738,
              37.233876
            ],
            [
              -108.257671,
              37.240352
            ],
            [
              -108.250451,
              37.247659
            ],
            [
              -108.247149,
              37.250509
            ],
            [
              -108.242946,
              37.260191
            ],
            [
              -108.237714,
              37.266016
            ],
            [
              -108.234838,
              37.274768
            ],
            [
              -108.226876,
              37.280502
            ],
            [
              -108.22187,
              37.287365
            ],
            [
              -108.219095,
              37.292796
            ],
            [
              -108.213521,
              37.298386
            ],
            [
              -108.216489,
              37.308118
            ],
            [
              -108.212674,
              37.316445
            ],
            [
              -108.205215,
              37.324457
            ],
            [
              -108.204811,
              37.329546
            ],
            [
              -108.207836,
              37.333451
            ],
            [
              -108.204831,
              37.343018
            ],
            [
              -108.197568,
              37.355157
            ],
            [
              -108.187868,
              37.36054
            ],
            [
              -108.174057,
              37.366051
            ],
            [
              -108.160541,
              37.362694
            ],
            [
              -108.149287,
              37.364029
            ],
            [
              -108.139821,
              37.373038
            ],
            [
              -108.120481,
              37.374715
            ],
            [
              -108.112938,
              37.380551
            ],
            [
              -108.106062,
              37.385177
            ],
            [
              -108.107379,
              37.392675
            ],
            [
              -108.105692,
              37.401863
            ],
            [
              -108.103418,
              37.408861
            ],
            [
              -108.098572,
              37.41651
            ],
            [
              -108.092884,
              37.41994
            ],
            [
              -108.087297,
              37.42668
            ],
            [
              -108.081206,
              37.428808
            ],
            [
              -108.07955,
              37.435309
            ],
            [
              -108.07302,
              37.438869
            ],
            [
              -108.065033,
              37.438279
            ],
            [
              -108.056177,
              37.437819
            ],
            [
              -108.049885,
              37.440759
            ],
            [
              -108.043756,
              37.44372
            ],
            [
              -108.039521,
              37.450801
            ],
            [
              -108.038227,
              37.457613
            ],
            [
              -108.03355,
              37.463999
            ],
            [
              -108.036106,
              37.47229
            ],
            [
              -108.033277,
              37.4782
            ],
            [
              -108.033705,
              37.484022
            ],
            [
              -108.035543,
              37.493355
            ],
            [
              -108.03668,
              37.499996
            ],
            [
              -108.034837,
              37.507851
            ],
            [
              -108.03447,
              37.513917
            ],
            [
              -108.034638,
              37.523226
            ],
            [
              -108.032853,
              37.533632
            ],
            [
              -108.024413,
              37.542574
            ],
            [
              -108.021767,
              37.550649
            ],
            [
              -108.02022,
              37.558807
            ],
            [
              -108.019149,
              37.566177
            ],
            [
              -108.020679,
              37.571899
            ],
            [
              -108.020098,
              37.58184
            ],
            [
              -108.020721,
              37.587161
            ],
            [
              -108.020334,
              37.59153
            ],
            [
              -108.01675,
              37.593391
            ],
            [
              -108.014167,
              37.596107
            ],
            [
              -108.015398,
              37.601231
            ],
            [
              -108.011171,
              37.605228
            ],
            [
              -108.004442,
              37.607667
            ],
            [
              -108.000517,
              37.613556
            ],
            [
              -107.989219,
              37.622241
            ],
            [
              -107.973079,
              37.629754
            ],
            [
              -107.970086,
              37.639587
            ],
            [
              -108.107996,
              37.638753
            ],
            [
              -108.161142,
              37.635265
            ],
            [
              -108.646498,
              37.632693
            ],
            [
              -108.693203,
              37.631511
            ],
            [
              -108.917081,
              37.631902
            ],
            [
              -109.043461439769,
              37.484820040900296
            ],
            [
              -109.04581,
              37.374993
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "005",
      "COUNTYNS": "00198118",
      "AFFGEOID": "0500000US08005",
      "GEOID": "08005",
      "NAME": "Arapahoe",
      "LSAD": "06",
      "ALAND": 2066634438,
      "AWATER": 19499590,
      "County_state": "Arapahoe,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -104.940687,
              39.711221
            ],
            [
              -104.940633,
              39.694755
            ],
            [
              -104.928029,
              39.696635
            ],
            [
              -104.931423,
              39.703789
            ],
            [
              -104.926981,
              39.708004
            ],
            [
              -104.940687,
              39.711221
            ]
          ]
        ],
        [
          [
            [
              -105.053653,
              39.6159
            ],
            [
              -105.05414,
              39.566088
            ],
            [
              -105.04874,
              39.566088
            ],
            [
              -104.973255,
              39.566069
            ],
            [
              -104.913831,
              39.566029
            ],
            [
              -104.904415,
              39.565982
            ],
            [
              -104.815686,
              39.565988
            ],
            [
              -104.784965,
              39.566088
            ],
            [
              -104.746043,
              39.566025
            ],
            [
              -104.679306,
              39.565991
            ],
            [
              -104.660626,
              39.565909
            ],
            [
              -104.642125,
              39.56589
            ],
            [
              -103.714355,
              39.566331
            ],
            [
              -103.707072,
              39.566295
            ],
            [
              -103.706547,
              39.739894
            ],
            [
              -104.470927,
              39.738198
            ],
            [
              -104.526958,
              39.738669
            ],
            [
              -104.602222,
              39.739878
            ],
            [
              -104.613603,
              39.739982
            ],
            [
              -104.616197,
              39.740004
            ],
            [
              -104.634448,
              39.739594
            ],
            [
              -104.659304,
              39.74046
            ],
            [
              -104.810022,
              39.740141
            ],
            [
              -104.884646,
              39.740156
            ],
            [
              -104.884708,
              39.732846
            ],
            [
              -104.886613,
              39.729219
            ],
            [
              -104.87533,
              39.725576
            ],
            [
              -104.865962,
              39.696559
            ],
            [
              -104.891715,
              39.69659
            ],
            [
              -104.90351,
              39.692581
            ],
            [
              -104.889308,
              39.682056
            ],
            [
              -104.903509,
              39.667624
            ],
            [
              -104.866051,
              39.670847
            ],
            [
              -104.848332,
              39.659161
            ],
            [
              -104.847639,
              39.656712
            ],
            [
              -104.876224,
              39.644301
            ],
            [
              -104.880313,
              39.645902
            ],
            [
              -104.885112,
              39.639262
            ],
            [
              -104.885265,
              39.624083
            ],
            [
              -104.913407,
              39.624083
            ],
            [
              -104.913257,
              39.638691
            ],
            [
              -104.931703,
              39.653044
            ],
            [
              -104.940683,
              39.653013
            ],
            [
              -104.973486,
              39.667684
            ],
            [
              -105.010665,
              39.660507
            ],
            [
              -105.008664,
              39.678627
            ],
            [
              -105.0158,
              39.660508
            ],
            [
              -105.034171,
              39.65329
            ],
            [
              -105.02555,
              39.628539
            ],
            [
              -105.034822,
              39.627809
            ],
            [
              -105.044048,
              39.628016
            ],
            [
              -105.053411,
              39.631586
            ],
            [
              -105.053457,
              39.629526
            ],
            [
              -105.053439,
              39.621423
            ],
            [
              -105.053653,
              39.6159
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "079",
      "COUNTYNS": "00198155",
      "AFFGEOID": "0500000US08079",
      "GEOID": "08079",
      "NAME": "Mineral",
      "LSAD": "06",
      "ALAND": 2268209080,
      "AWATER": 5222161,
      "County_state": "Mineral,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -107.137356,
              37.828788
            ],
            [
              -107.145138,
              37.675497
            ],
            [
              -107.123868,
              37.675029
            ],
            [
              -107.12868,
              37.422942
            ],
            [
              -107.128737,
              37.392409
            ],
            [
              -106.710767,
              37.396238
            ],
            [
              -106.710775,
              37.404228
            ],
            [
              -106.711624,
              37.664273
            ],
            [
              -106.695638,
              37.664271
            ],
            [
              -106.693845,
              37.835244
            ],
            [
              -106.693523,
              37.873519
            ],
            [
              -106.714678,
              37.871901
            ],
            [
              -106.736666,
              37.887881
            ],
            [
              -106.755823,
              37.880624
            ],
            [
              -106.773066,
              37.885575
            ],
            [
              -106.801026,
              37.913699
            ],
            [
              -106.84727,
              37.91917
            ],
            [
              -106.87988,
              37.949861
            ],
            [
              -106.879167,
              37.96111
            ],
            [
              -106.89456,
              37.967104
            ],
            [
              -106.920312,
              37.967431
            ],
            [
              -106.946317,
              37.954005
            ],
            [
              -106.968289,
              37.974777
            ],
            [
              -106.987368,
              37.969179
            ],
            [
              -107.000602,
              37.956041
            ],
            [
              -107.008973,
              37.946522
            ],
            [
              -107.022918,
              37.953792
            ],
            [
              -107.041363,
              37.948681
            ],
            [
              -107.06778,
              37.961299
            ],
            [
              -107.094606,
              37.949307
            ],
            [
              -107.103212,
              37.955053
            ],
            [
              -107.110669,
              37.943344
            ],
            [
              -107.138191,
              37.938848
            ],
            [
              -107.137356,
              37.828788
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "033",
      "COUNTYNS": "00198132",
      "AFFGEOID": "0500000US08033",
      "GEOID": "08033",
      "NAME": "Dolores",
      "LSAD": "06",
      "ALAND": 2763958520,
      "AWATER": 2716141,
      "County_state": "Dolores,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -109.043137,
              37.499992
            ],
            [
              -109.043461439769,
              37.484820040900296
            ],
            [
              -108.917081,
              37.631902
            ],
            [
              -108.693203,
              37.631511
            ],
            [
              -108.646498,
              37.632693
            ],
            [
              -108.161142,
              37.635265
            ],
            [
              -108.107996,
              37.638753
            ],
            [
              -107.970086,
              37.639587
            ],
            [
              -107.957078,
              37.648894
            ],
            [
              -107.96942,
              37.679232
            ],
            [
              -107.968875,
              37.696485
            ],
            [
              -107.952628,
              37.706788
            ],
            [
              -107.937206,
              37.702562
            ],
            [
              -107.901349,
              37.719002
            ],
            [
              -107.872966,
              37.722416
            ],
            [
              -107.875622,
              37.738851
            ],
            [
              -107.861912,
              37.756024
            ],
            [
              -107.870481,
              37.762762
            ],
            [
              -107.860845,
              37.775517
            ],
            [
              -107.873187,
              37.786239
            ],
            [
              -107.886257,
              37.785454
            ],
            [
              -107.892508,
              37.802657
            ],
            [
              -107.904847,
              37.807459
            ],
            [
              -107.921347,
              37.823714
            ],
            [
              -107.937646,
              37.822668
            ],
            [
              -107.950763,
              37.835759
            ],
            [
              -107.971393,
              37.832428
            ],
            [
              -107.989208,
              37.85664
            ],
            [
              -108.030865,
              37.855495
            ],
            [
              -108.058476,
              37.841
            ],
            [
              -108.066479,
              37.851048
            ],
            [
              -108.090526,
              37.839994
            ],
            [
              -108.112523,
              37.855235
            ],
            [
              -108.141527,
              37.832454
            ],
            [
              -108.174906,
              37.832592
            ],
            [
              -108.209093,
              37.820936
            ],
            [
              -108.212354,
              37.832038
            ],
            [
              -108.227005,
              37.843475
            ],
            [
              -108.237837,
              37.876392
            ],
            [
              -108.247934,
              37.87938
            ],
            [
              -108.256427,
              37.894446
            ],
            [
              -108.81671,
              37.895538
            ],
            [
              -108.820522,
              37.880851
            ],
            [
              -109.041654683556,
              37.8810851664933
            ],
            [
              -109.041844,
              37.872788
            ],
            [
              -109.041723,
              37.842051
            ],
            [
              -109.041754,
              37.835826
            ],
            [
              -109.041461,
              37.800105
            ],
            [
              -109.042098,
              37.74999
            ],
            [
              -109.041636,
              37.74021
            ],
            [
              -109.04176,
              37.713182
            ],
            [
              -109.041732,
              37.711214
            ],
            [
              -109.042269,
              37.666067
            ],
            [
              -109.042089,
              37.623795
            ],
            [
              -109.042131,
              37.617662
            ],
            [
              -109.041806,
              37.604171
            ],
            [
              -109.041865,
              37.530726
            ],
            [
              -109.041915,
              37.530653
            ],
            [
              -109.043137,
              37.499992
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "059",
      "COUNTYNS": "00198145",
      "AFFGEOID": "0500000US08059",
      "GEOID": "08059",
      "NAME": "Jefferson",
      "LSAD": "06",
      "ALAND": 1979380402,
      "AWATER": 25428019,
      "County_state": "Jefferson,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -105.058996,
              39.638177
            ],
            [
              -105.059457,
              39.634662
            ],
            [
              -105.057009,
              39.631679
            ],
            [
              -105.053457,
              39.629526
            ],
            [
              -105.053411,
              39.631586
            ],
            [
              -105.053333,
              39.638893
            ],
            [
              -105.059431,
              39.638812
            ],
            [
              -105.058996,
              39.638177
            ]
          ]
        ],
        [
          [
            [
              -105.398949,
              39.566056
            ],
            [
              -105.398154,
              39.40127
            ],
            [
              -105.397875,
              39.129564
            ],
            [
              -105.330444,
              39.129685
            ],
            [
              -105.32922,
              39.129689
            ],
            [
              -105.313557,
              39.159121
            ],
            [
              -105.287377,
              39.179442
            ],
            [
              -105.282929,
              39.199953
            ],
            [
              -105.260054,
              39.210445
            ],
            [
              -105.263511,
              39.229261
            ],
            [
              -105.252428,
              39.243695
            ],
            [
              -105.233938,
              39.246868
            ],
            [
              -105.234393,
              39.255307
            ],
            [
              -105.217419,
              39.260395
            ],
            [
              -105.220776,
              39.268063
            ],
            [
              -105.198156,
              39.310432
            ],
            [
              -105.182305,
              39.329797
            ],
            [
              -105.166574,
              39.361754
            ],
            [
              -105.172997,
              39.377722
            ],
            [
              -105.164337,
              39.391136
            ],
            [
              -105.170736,
              39.407528
            ],
            [
              -105.138206,
              39.42307
            ],
            [
              -105.123522,
              39.434561
            ],
            [
              -105.134993,
              39.470785
            ],
            [
              -105.086109,
              39.494923
            ],
            [
              -105.073045,
              39.545265
            ],
            [
              -105.060714,
              39.559941
            ],
            [
              -105.05306,
              39.563109
            ],
            [
              -105.04874,
              39.566088
            ],
            [
              -105.05414,
              39.566088
            ],
            [
              -105.053653,
              39.6159
            ],
            [
              -105.053439,
              39.621423
            ],
            [
              -105.058125,
              39.61443
            ],
            [
              -105.091405,
              39.614455
            ],
            [
              -105.096955,
              39.617969
            ],
            [
              -105.093074,
              39.618325
            ],
            [
              -105.065024,
              39.619923
            ],
            [
              -105.08173,
              39.627313
            ],
            [
              -105.109927,
              39.626988
            ],
            [
              -105.109874,
              39.635186
            ],
            [
              -105.076803,
              39.638735
            ],
            [
              -105.053727,
              39.651159
            ],
            [
              -105.08135,
              39.670099
            ],
            [
              -105.06011,
              39.66778
            ],
            [
              -105.053256,
              39.667785
            ],
            [
              -105.06262,
              39.67928
            ],
            [
              -105.053225,
              39.696769
            ],
            [
              -105.053247,
              39.703985
            ],
            [
              -105.053253,
              39.725828
            ],
            [
              -105.055539,
              39.733098
            ],
            [
              -105.05321,
              39.743273
            ],
            [
              -105.053237,
              39.755694
            ],
            [
              -105.053183,
              39.783797
            ],
            [
              -105.065083,
              39.788086
            ],
            [
              -105.053246,
              39.791063
            ],
            [
              -105.05327,
              39.820028
            ],
            [
              -105.052847,
              39.853229
            ],
            [
              -105.053249,
              39.854194
            ],
            [
              -105.053263,
              39.859948
            ],
            [
              -105.052892,
              39.914224
            ],
            [
              -105.071688,
              39.899832
            ],
            [
              -105.096692,
              39.899788
            ],
            [
              -105.108609,
              39.914137
            ],
            [
              -105.147117,
              39.914002
            ],
            [
              -105.128895,
              39.890353
            ],
            [
              -105.165571,
              39.892495
            ],
            [
              -105.165689,
              39.910503
            ],
            [
              -105.147143,
              39.913889
            ],
            [
              -105.16615,
              39.914047
            ],
            [
              -105.282977,
              39.913689
            ],
            [
              -105.339547,
              39.913786
            ],
            [
              -105.397849,
              39.912886
            ],
            [
              -105.397949,
              39.747087
            ],
            [
              -105.398949,
              39.566056
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "029",
      "COUNTYNS": "00198130",
      "AFFGEOID": "0500000US08029",
      "GEOID": "08029",
      "NAME": "Delta",
      "LSAD": "06",
      "ALAND": 2958099789,
      "AWATER": 16777978,
      "County_state": "Delta,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -108.378953,
              38.829108
            ],
            [
              -108.378698,
              38.668097
            ],
            [
              -107.998889,
              38.668476
            ],
            [
              -107.500655,
              38.668553
            ],
            [
              -107.500624,
              38.688404
            ],
            [
              -107.500606,
              39.217916
            ],
            [
              -107.509835,
              39.216555
            ],
            [
              -107.543455,
              39.194401
            ],
            [
              -107.543364,
              39.181925
            ],
            [
              -107.567423,
              39.181397
            ],
            [
              -107.637893,
              39.14202
            ],
            [
              -107.680815,
              39.100256
            ],
            [
              -107.689556,
              39.087171
            ],
            [
              -107.712581,
              39.080781
            ],
            [
              -107.721624,
              39.065255
            ],
            [
              -107.737988,
              39.06616
            ],
            [
              -107.76549,
              39.043294
            ],
            [
              -107.80213,
              39.064788
            ],
            [
              -107.85824,
              39.079804
            ],
            [
              -107.906811,
              39.060729
            ],
            [
              -107.916692,
              39.063833
            ],
            [
              -107.982173,
              39.059878
            ],
            [
              -107.987204,
              39.052876
            ],
            [
              -108.032022,
              39.027322
            ],
            [
              -108.036119,
              39.01718
            ],
            [
              -108.053779,
              39.014052
            ],
            [
              -108.069963,
              38.996592
            ],
            [
              -108.094786,
              38.979684
            ],
            [
              -108.08686,
              38.967804
            ],
            [
              -108.120793,
              38.927209
            ],
            [
              -108.137741,
              38.926052
            ],
            [
              -108.141122,
              38.906484
            ],
            [
              -108.16631,
              38.902245
            ],
            [
              -108.176988,
              38.895315
            ],
            [
              -108.378953,
              38.829108
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "049",
      "COUNTYNS": "00198140",
      "AFFGEOID": "0500000US08049",
      "GEOID": "08049",
      "NAME": "Grand",
      "LSAD": "06",
      "ALAND": 4782260517,
      "AWATER": 59902382,
      "County_state": "Grand,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -106.652112,
              40.445231
            ],
            [
              -106.652832,
              40.341562
            ],
            [
              -106.632257,
              40.341559
            ],
            [
              -106.636995,
              40.178985
            ],
            [
              -106.637031,
              40.002106
            ],
            [
              -106.626112,
              39.999986
            ],
            [
              -106.626569,
              39.924785
            ],
            [
              -106.6185,
              39.925231
            ],
            [
              -106.434508,
              39.924914
            ],
            [
              -106.395257,
              39.924589
            ],
            [
              -106.395433,
              39.914016
            ],
            [
              -106.294777,
              39.91892
            ],
            [
              -106.252027,
              39.914712
            ],
            [
              -106.231688,
              39.900806
            ],
            [
              -106.200566,
              39.864042
            ],
            [
              -106.171143,
              39.845293
            ],
            [
              -106.149507,
              39.839561
            ],
            [
              -106.131483,
              39.8442
            ],
            [
              -106.083711,
              39.805964
            ],
            [
              -106.081207,
              39.787399
            ],
            [
              -106.064827,
              39.760733
            ],
            [
              -106.058548,
              39.724701
            ],
            [
              -106.038106,
              39.712058
            ],
            [
              -106.031889,
              39.694175
            ],
            [
              -106.012987,
              39.686341
            ],
            [
              -105.996339,
              39.684122
            ],
            [
              -105.951036,
              39.695149
            ],
            [
              -105.945601,
              39.688866
            ],
            [
              -105.924618,
              39.698972
            ],
            [
              -105.90828,
              39.726246
            ],
            [
              -105.883714,
              39.737012
            ],
            [
              -105.880481,
              39.759303
            ],
            [
              -105.892346,
              39.784108
            ],
            [
              -105.887395,
              39.796996
            ],
            [
              -105.854942,
              39.796154
            ],
            [
              -105.8511,
              39.78776
            ],
            [
              -105.822954,
              39.785605
            ],
            [
              -105.799013,
              39.80343
            ],
            [
              -105.765958,
              39.794855
            ],
            [
              -105.736426,
              39.804801
            ],
            [
              -105.713369,
              39.838212
            ],
            [
              -105.696345,
              39.838919
            ],
            [
              -105.690348,
              39.851996
            ],
            [
              -105.699719,
              39.891499
            ],
            [
              -105.675798,
              39.932445
            ],
            [
              -105.6932,
              39.941239
            ],
            [
              -105.683164,
              39.992881
            ],
            [
              -105.689818,
              40.015108
            ],
            [
              -105.652793,
              40.023772
            ],
            [
              -105.637486,
              40.045845
            ],
            [
              -105.651061,
              40.058445
            ],
            [
              -105.635429,
              40.074947
            ],
            [
              -105.634652,
              40.113232
            ],
            [
              -105.62609,
              40.127346
            ],
            [
              -105.636551,
              40.146375
            ],
            [
              -105.667729,
              40.158276
            ],
            [
              -105.677688,
              40.201673
            ],
            [
              -105.670559,
              40.207802
            ],
            [
              -105.658932,
              40.249413
            ],
            [
              -105.64402,
              40.249939
            ],
            [
              -105.653321,
              40.260457
            ],
            [
              -105.682428,
              40.2728
            ],
            [
              -105.685772,
              40.302554
            ],
            [
              -105.702235,
              40.31261
            ],
            [
              -105.70168,
              40.324309
            ],
            [
              -105.729088,
              40.335278
            ],
            [
              -105.736345,
              40.346615
            ],
            [
              -105.767699,
              40.359764
            ],
            [
              -105.790729,
              40.386263
            ],
            [
              -105.790951,
              40.39864
            ],
            [
              -105.81534,
              40.421486
            ],
            [
              -105.816366,
              40.435519
            ],
            [
              -105.80569,
              40.447882
            ],
            [
              -105.807341,
              40.474527
            ],
            [
              -105.854926,
              40.486252
            ],
            [
              -105.86336,
              40.480501
            ],
            [
              -105.877466,
              40.477665
            ],
            [
              -105.888693,
              40.476413
            ],
            [
              -105.896762,
              40.469295
            ],
            [
              -105.899824,
              40.4545
            ],
            [
              -105.902176,
              40.435656
            ],
            [
              -105.898002,
              40.422271
            ],
            [
              -105.902313,
              40.410258
            ],
            [
              -105.907797,
              40.400468
            ],
            [
              -105.912543,
              40.401128
            ],
            [
              -105.925616,
              40.392342
            ],
            [
              -105.94215,
              40.384848
            ],
            [
              -105.943073,
              40.374983
            ],
            [
              -105.938918,
              40.372704
            ],
            [
              -105.938892,
              40.36741
            ],
            [
              -105.944637,
              40.364053
            ],
            [
              -105.954684,
              40.358538
            ],
            [
              -105.957473,
              40.351956
            ],
            [
              -105.959723,
              40.349381
            ],
            [
              -105.96615,
              40.349523
            ],
            [
              -105.971642,
              40.350905
            ],
            [
              -105.978905,
              40.358358
            ],
            [
              -105.991215,
              40.364203
            ],
            [
              -105.999837,
              40.362725
            ],
            [
              -106.01113,
              40.363956
            ],
            [
              -106.013326,
              40.370508
            ],
            [
              -106.024561,
              40.371777
            ],
            [
              -106.048509,
              40.360685
            ],
            [
              -106.074166,
              40.351118
            ],
            [
              -106.092598,
              40.350417
            ],
            [
              -106.115649,
              40.346099
            ],
            [
              -106.127243,
              40.338896
            ],
            [
              -106.135214,
              40.333646
            ],
            [
              -106.136033,
              40.331378
            ],
            [
              -106.142627,
              40.33018
            ],
            [
              -106.147527,
              40.33209
            ],
            [
              -106.157612,
              40.338981
            ],
            [
              -106.166306,
              40.343355
            ],
            [
              -106.176051,
              40.341339
            ],
            [
              -106.180338,
              40.333808
            ],
            [
              -106.190902,
              40.326308
            ],
            [
              -106.19806,
              40.32649
            ],
            [
              -106.204625,
              40.327311
            ],
            [
              -106.213783,
              40.327976
            ],
            [
              -106.22252,
              40.325746
            ],
            [
              -106.231523,
              40.324795
            ],
            [
              -106.239281,
              40.328439
            ],
            [
              -106.244064,
              40.336564
            ],
            [
              -106.251151,
              40.341038
            ],
            [
              -106.253645,
              40.347199
            ],
            [
              -106.257168,
              40.353986
            ],
            [
              -106.259478,
              40.357296
            ],
            [
              -106.267459,
              40.360022
            ],
            [
              -106.270822,
              40.357176
            ],
            [
              -106.274669,
              40.355601
            ],
            [
              -106.279972,
              40.354733
            ],
            [
              -106.284617,
              40.356594
            ],
            [
              -106.289553,
              40.35821
            ],
            [
              -106.293661,
              40.355742
            ],
            [
              -106.29763,
              40.350361
            ],
            [
              -106.298557,
              40.344178
            ],
            [
              -106.293906,
              40.337828
            ],
            [
              -106.296106,
              40.334601
            ],
            [
              -106.300764,
              40.330473
            ],
            [
              -106.305208,
              40.330121
            ],
            [
              -106.312241,
              40.330184
            ],
            [
              -106.317578,
              40.333999
            ],
            [
              -106.32346,
              40.33787
            ],
            [
              -106.333357,
              40.339092
            ],
            [
              -106.337344,
              40.341625
            ],
            [
              -106.341465,
              40.345499
            ],
            [
              -106.344856,
              40.349634
            ],
            [
              -106.350764,
              40.354214
            ],
            [
              -106.357989,
              40.358073
            ],
            [
              -106.367589,
              40.35608
            ],
            [
              -106.376667,
              40.35522
            ],
            [
              -106.383821,
              40.352782
            ],
            [
              -106.387031,
              40.349168
            ],
            [
              -106.391911,
              40.345825
            ],
            [
              -106.396503,
              40.346991
            ],
            [
              -106.404727,
              40.345479
            ],
            [
              -106.408943,
              40.348388
            ],
            [
              -106.415659,
              40.350427
            ],
            [
              -106.42403,
              40.35231
            ],
            [
              -106.429228,
              40.351382
            ],
            [
              -106.434475,
              40.349828
            ],
            [
              -106.439525,
              40.348792
            ],
            [
              -106.444334,
              40.347682
            ],
            [
              -106.450924,
              40.351545
            ],
            [
              -106.455595,
              40.358825
            ],
            [
              -106.463211,
              40.367824
            ],
            [
              -106.470586,
              40.379034
            ],
            [
              -106.477817,
              40.389251
            ],
            [
              -106.477897,
              40.401041
            ],
            [
              -106.492747,
              40.407976
            ],
            [
              -106.498827,
              40.403594
            ],
            [
              -106.507124,
              40.396194
            ],
            [
              -106.514688,
              40.38833
            ],
            [
              -106.521839,
              40.387469
            ],
            [
              -106.526734,
              40.382266
            ],
            [
              -106.527434,
              40.377458
            ],
            [
              -106.529539,
              40.371784
            ],
            [
              -106.532921,
              40.366191
            ],
            [
              -106.537527,
              40.367652
            ],
            [
              -106.540833,
              40.371454
            ],
            [
              -106.545357,
              40.371151
            ],
            [
              -106.548648,
              40.370331
            ],
            [
              -106.554667,
              40.371528
            ],
            [
              -106.55587,
              40.374981
            ],
            [
              -106.556344,
              40.376412
            ],
            [
              -106.564075,
              40.37785
            ],
            [
              -106.574152,
              40.378515
            ],
            [
              -106.586587,
              40.381886
            ],
            [
              -106.592545,
              40.38112
            ],
            [
              -106.596703,
              40.380354
            ],
            [
              -106.597837,
              40.3779
            ],
            [
              -106.602808,
              40.376917
            ],
            [
              -106.61004,
              40.378493
            ],
            [
              -106.611966,
              40.381635
            ],
            [
              -106.614076,
              40.389882
            ],
            [
              -106.618281,
              40.400027
            ],
            [
              -106.622443,
              40.40959
            ],
            [
              -106.626357,
              40.416476
            ],
            [
              -106.624896,
              40.428483
            ],
            [
              -106.632023,
              40.432471
            ],
            [
              -106.650957,
              40.440927
            ],
            [
              -106.652112,
              40.445231
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "109",
      "COUNTYNS": "00198170",
      "AFFGEOID": "0500000US08109",
      "GEOID": "08109",
      "NAME": "Saguache",
      "LSAD": "06",
      "ALAND": 8206547707,
      "AWATER": 4454510,
      "County_state": "Saguache,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -107.001119,
              38.147072
            ],
            [
              -107.000602,
              37.956041
            ],
            [
              -106.987368,
              37.969179
            ],
            [
              -106.968289,
              37.974777
            ],
            [
              -106.946317,
              37.954005
            ],
            [
              -106.920312,
              37.967431
            ],
            [
              -106.89456,
              37.967104
            ],
            [
              -106.879167,
              37.96111
            ],
            [
              -106.87988,
              37.949861
            ],
            [
              -106.84727,
              37.91917
            ],
            [
              -106.801026,
              37.913699
            ],
            [
              -106.773066,
              37.885575
            ],
            [
              -106.755823,
              37.880624
            ],
            [
              -106.736666,
              37.887881
            ],
            [
              -106.714678,
              37.871901
            ],
            [
              -106.693523,
              37.873519
            ],
            [
              -106.693845,
              37.835244
            ],
            [
              -106.587139,
              37.834426
            ],
            [
              -106.586374,
              37.747809
            ],
            [
              -106.038413,
              37.748353
            ],
            [
              -105.457255,
              37.751464
            ],
            [
              -105.449454,
              37.778127
            ],
            [
              -105.454912,
              37.795696
            ],
            [
              -105.435179,
              37.804767
            ],
            [
              -105.428893,
              37.851733
            ],
            [
              -105.450537,
              37.878112
            ],
            [
              -105.478495,
              37.87985
            ],
            [
              -105.473204,
              37.89597
            ],
            [
              -105.502273,
              37.92295
            ],
            [
              -105.511086,
              37.939131
            ],
            [
              -105.533315,
              37.955182
            ],
            [
              -105.560311,
              37.951886
            ],
            [
              -105.583922,
              37.967196
            ],
            [
              -105.581522,
              37.975225
            ],
            [
              -105.604355,
              38.008745
            ],
            [
              -105.597112,
              38.013638
            ],
            [
              -105.627206,
              38.06731
            ],
            [
              -105.655856,
              38.092291
            ],
            [
              -105.662654,
              38.119158
            ],
            [
              -105.67627,
              38.146173
            ],
            [
              -105.673042,
              38.159126
            ],
            [
              -105.700414,
              38.16918
            ],
            [
              -105.736738,
              38.209561
            ],
            [
              -105.789761,
              38.24436
            ],
            [
              -105.796897,
              38.265047
            ],
            [
              -105.818973,
              38.273597
            ],
            [
              -105.84278,
              38.293226
            ],
            [
              -105.858315,
              38.298359
            ],
            [
              -105.876376,
              38.315951
            ],
            [
              -105.888766,
              38.340811
            ],
            [
              -105.919851,
              38.367156
            ],
            [
              -105.944637,
              38.378638
            ],
            [
              -105.947606,
              38.390526
            ],
            [
              -105.98395,
              38.41999
            ],
            [
              -105.996768,
              38.420303
            ],
            [
              -106.012751,
              38.440123
            ],
            [
              -106.010751,
              38.446566
            ],
            [
              -106.019043,
              38.454423
            ],
            [
              -106.042771,
              38.458267
            ],
            [
              -106.058571,
              38.456945
            ],
            [
              -106.057982,
              38.444691
            ],
            [
              -106.076535,
              38.423652
            ],
            [
              -106.125571,
              38.422092
            ],
            [
              -106.24694,
              38.422768
            ],
            [
              -106.760633,
              38.425062
            ],
            [
              -107.000601,
              38.425619
            ],
            [
              -107.001119,
              38.147072
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "117",
      "COUNTYNS": "00198174",
      "AFFGEOID": "0500000US08117",
      "GEOID": "08117",
      "NAME": "Summit",
      "LSAD": "06",
      "ALAND": 1575590697,
      "AWATER": 28283469,
      "County_state": "Summit,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -106.434508,
              39.924914
            ],
            [
              -106.439202,
              39.919945
            ],
            [
              -106.428683,
              39.895107
            ],
            [
              -106.433745,
              39.876043
            ],
            [
              -106.43252,
              39.864332
            ],
            [
              -106.423019,
              39.841106
            ],
            [
              -106.41223,
              39.826786
            ],
            [
              -106.400904,
              39.802815
            ],
            [
              -106.390316,
              39.788725
            ],
            [
              -106.383487,
              39.766092
            ],
            [
              -106.37212,
              39.758442
            ],
            [
              -106.363652,
              39.752076
            ],
            [
              -106.355534,
              39.75262
            ],
            [
              -106.350744,
              39.756879
            ],
            [
              -106.343428,
              39.75734
            ],
            [
              -106.343338,
              39.755292
            ],
            [
              -106.334806,
              39.747777
            ],
            [
              -106.328472,
              39.737818
            ],
            [
              -106.31967,
              39.730014
            ],
            [
              -106.309002,
              39.724945
            ],
            [
              -106.299377,
              39.720489
            ],
            [
              -106.294182,
              39.713017
            ],
            [
              -106.290916,
              39.704066
            ],
            [
              -106.279565,
              39.700105
            ],
            [
              -106.273626,
              39.702997
            ],
            [
              -106.269026,
              39.702563
            ],
            [
              -106.263628,
              39.699785
            ],
            [
              -106.264055,
              39.694575
            ],
            [
              -106.26462,
              39.687907
            ],
            [
              -106.260688,
              39.68199
            ],
            [
              -106.252649,
              39.679272
            ],
            [
              -106.238827,
              39.675992
            ],
            [
              -106.232485,
              39.662229
            ],
            [
              -106.218957,
              39.657999
            ],
            [
              -106.189205,
              39.653341
            ],
            [
              -106.178208,
              39.63714
            ],
            [
              -106.176443,
              39.621988
            ],
            [
              -106.179698,
              39.607281
            ],
            [
              -106.190323,
              39.603979
            ],
            [
              -106.202105,
              39.605836
            ],
            [
              -106.20977,
              39.59737
            ],
            [
              -106.210777,
              39.587683
            ],
            [
              -106.214017,
              39.577908
            ],
            [
              -106.210084,
              39.568731
            ],
            [
              -106.203766,
              39.557797
            ],
            [
              -106.197648,
              39.555066
            ],
            [
              -106.202014,
              39.549983
            ],
            [
              -106.209926,
              39.546943
            ],
            [
              -106.210742,
              39.536341
            ],
            [
              -106.211122,
              39.532828
            ],
            [
              -106.214355,
              39.531057
            ],
            [
              -106.216111,
              39.531706
            ],
            [
              -106.220795,
              39.531695
            ],
            [
              -106.226909,
              39.534272
            ],
            [
              -106.231096,
              39.540469
            ],
            [
              -106.235619,
              39.546194
            ],
            [
              -106.240106,
              39.54682
            ],
            [
              -106.244324,
              39.543836
            ],
            [
              -106.250328,
              39.539117
            ],
            [
              -106.255065,
              39.525328
            ],
            [
              -106.246435,
              39.512577
            ],
            [
              -106.25108,
              39.502098
            ],
            [
              -106.255642,
              39.499986
            ],
            [
              -106.256579,
              39.493259
            ],
            [
              -106.251784,
              39.492647
            ],
            [
              -106.24732,
              39.486933
            ],
            [
              -106.250294,
              39.477989
            ],
            [
              -106.251528,
              39.467521
            ],
            [
              -106.242401,
              39.458303
            ],
            [
              -106.2387,
              39.450908
            ],
            [
              -106.232798,
              39.442679
            ],
            [
              -106.226828,
              39.438317
            ],
            [
              -106.224897,
              39.432201
            ],
            [
              -106.227599,
              39.428099
            ],
            [
              -106.229986,
              39.420859
            ],
            [
              -106.228664,
              39.414965
            ],
            [
              -106.218508,
              39.411486
            ],
            [
              -106.215548,
              39.406835
            ],
            [
              -106.212753,
              39.405118
            ],
            [
              -106.204737,
              39.398805
            ],
            [
              -106.203041,
              39.389739
            ],
            [
              -106.206782,
              39.387026
            ],
            [
              -106.208386,
              39.381367
            ],
            [
              -106.206732,
              39.379636
            ],
            [
              -106.135529,
              39.379546
            ],
            [
              -106.096791,
              39.377896
            ],
            [
              -106.056096,
              39.358431
            ],
            [
              -106.021966,
              39.361713
            ],
            [
              -106.018383,
              39.381403
            ],
            [
              -105.992856,
              39.401859
            ],
            [
              -105.951698,
              39.414619
            ],
            [
              -105.966786,
              39.438035
            ],
            [
              -105.944155,
              39.44091
            ],
            [
              -105.938663,
              39.458498
            ],
            [
              -105.911637,
              39.456736
            ],
            [
              -105.891974,
              39.484173
            ],
            [
              -105.879087,
              39.483767
            ],
            [
              -105.863584,
              39.502305
            ],
            [
              -105.854117,
              39.50452
            ],
            [
              -105.866889,
              39.516702
            ],
            [
              -105.860506,
              39.530487
            ],
            [
              -105.845377,
              39.52375
            ],
            [
              -105.817631,
              39.53831
            ],
            [
              -105.815807,
              39.551976
            ],
            [
              -105.829662,
              39.564865
            ],
            [
              -105.838369,
              39.572952
            ],
            [
              -105.808083,
              39.583395
            ],
            [
              -105.782745,
              39.600145
            ],
            [
              -105.776143,
              39.61108
            ],
            [
              -105.793058,
              39.636328
            ],
            [
              -105.816646,
              39.633546
            ],
            [
              -105.821182,
              39.642692
            ],
            [
              -105.848665,
              39.644156
            ],
            [
              -105.864767,
              39.667218
            ],
            [
              -105.884861,
              39.657084
            ],
            [
              -105.910753,
              39.662294
            ],
            [
              -105.92629,
              39.672985
            ],
            [
              -105.924618,
              39.698972
            ],
            [
              -105.945601,
              39.688866
            ],
            [
              -105.951036,
              39.695149
            ],
            [
              -105.996339,
              39.684122
            ],
            [
              -106.012987,
              39.686341
            ],
            [
              -106.031889,
              39.694175
            ],
            [
              -106.038106,
              39.712058
            ],
            [
              -106.058548,
              39.724701
            ],
            [
              -106.064827,
              39.760733
            ],
            [
              -106.081207,
              39.787399
            ],
            [
              -106.083711,
              39.805964
            ],
            [
              -106.131483,
              39.8442
            ],
            [
              -106.149507,
              39.839561
            ],
            [
              -106.171143,
              39.845293
            ],
            [
              -106.200566,
              39.864042
            ],
            [
              -106.231688,
              39.900806
            ],
            [
              -106.252027,
              39.914712
            ],
            [
              -106.294777,
              39.91892
            ],
            [
              -106.395433,
              39.914016
            ],
            [
              -106.395257,
              39.924589
            ],
            [
              -106.434508,
              39.924914
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "051",
      "COUNTYNS": "00198141",
      "AFFGEOID": "0500000US08051",
      "GEOID": "08051",
      "NAME": "Gunnison",
      "LSAD": "06",
      "ALAND": 8389338065,
      "AWATER": 53067068,
      "County_state": "Gunnison,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -107.63504,
              38.301896
            ],
            [
              -107.61157,
              38.260827
            ],
            [
              -107.588821,
              38.249588
            ],
            [
              -107.580978,
              38.212685
            ],
            [
              -107.56219,
              38.158204
            ],
            [
              -107.56862,
              38.147267
            ],
            [
              -107.001119,
              38.147072
            ],
            [
              -107.000601,
              38.425619
            ],
            [
              -106.760633,
              38.425062
            ],
            [
              -106.24694,
              38.422768
            ],
            [
              -106.24855,
              38.429053
            ],
            [
              -106.248879,
              38.434661
            ],
            [
              -106.253087,
              38.441003
            ],
            [
              -106.263467,
              38.442046
            ],
            [
              -106.275748,
              38.454747
            ],
            [
              -106.284006,
              38.45719
            ],
            [
              -106.289138,
              38.464328
            ],
            [
              -106.290646,
              38.472715
            ],
            [
              -106.301052,
              38.476089
            ],
            [
              -106.307251,
              38.484813
            ],
            [
              -106.310973,
              38.49294
            ],
            [
              -106.317291,
              38.494794
            ],
            [
              -106.321474,
              38.497369
            ],
            [
              -106.328484,
              38.49642
            ],
            [
              -106.332465,
              38.49453
            ],
            [
              -106.335543,
              38.496383
            ],
            [
              -106.338658,
              38.498904
            ],
            [
              -106.347269,
              38.511484
            ],
            [
              -106.354344,
              38.537584
            ],
            [
              -106.347451,
              38.561041
            ],
            [
              -106.353139,
              38.58518
            ],
            [
              -106.359822,
              38.600605
            ],
            [
              -106.371084,
              38.619519
            ],
            [
              -106.384602,
              38.624164
            ],
            [
              -106.387975,
              38.640016
            ],
            [
              -106.407944,
              38.647679
            ],
            [
              -106.429356,
              38.658608
            ],
            [
              -106.44122,
              38.687137
            ],
            [
              -106.451076,
              38.695922
            ],
            [
              -106.444553,
              38.706926
            ],
            [
              -106.434372,
              38.709185
            ],
            [
              -106.419185,
              38.723989
            ],
            [
              -106.418201,
              38.745129
            ],
            [
              -106.417983,
              38.754189
            ],
            [
              -106.415348,
              38.757554
            ],
            [
              -106.417117,
              38.763572
            ],
            [
              -106.418058,
              38.76827
            ],
            [
              -106.415947,
              38.771746
            ],
            [
              -106.414829,
              38.776282
            ],
            [
              -106.414925,
              38.780702
            ],
            [
              -106.41588,
              38.788328
            ],
            [
              -106.413226,
              38.792152
            ],
            [
              -106.414787,
              38.797468
            ],
            [
              -106.417148,
              38.800468
            ],
            [
              -106.418962,
              38.803098
            ],
            [
              -106.422336,
              38.806731
            ],
            [
              -106.420233,
              38.811739
            ],
            [
              -106.415424,
              38.814869
            ],
            [
              -106.41565,
              38.820892
            ],
            [
              -106.413048,
              38.825127
            ],
            [
              -106.410095,
              38.827243
            ],
            [
              -106.406017,
              38.828766
            ],
            [
              -106.404373,
              38.827361
            ],
            [
              -106.397198,
              38.822885
            ],
            [
              -106.390273,
              38.823118
            ],
            [
              -106.386388,
              38.82414
            ],
            [
              -106.383604,
              38.827343
            ],
            [
              -106.383136,
              38.830562
            ],
            [
              -106.379543,
              38.834606
            ],
            [
              -106.375723,
              38.836651
            ],
            [
              -106.370793,
              38.838324
            ],
            [
              -106.367849,
              38.843142
            ],
            [
              -106.365299,
              38.847364
            ],
            [
              -106.36531,
              38.85055
            ],
            [
              -106.362372,
              38.853376
            ],
            [
              -106.358667,
              38.854515
            ],
            [
              -106.351126,
              38.855503
            ],
            [
              -106.349106,
              38.858209
            ],
            [
              -106.349139,
              38.863765
            ],
            [
              -106.345577,
              38.867969
            ],
            [
              -106.338117,
              38.871754
            ],
            [
              -106.336589,
              38.875737
            ],
            [
              -106.326039,
              38.888927
            ],
            [
              -106.32472,
              38.902929
            ],
            [
              -106.344049,
              38.907606
            ],
            [
              -106.361151,
              38.912285
            ],
            [
              -106.376495,
              38.916772
            ],
            [
              -106.392991,
              38.917718
            ],
            [
              -106.407602,
              38.914762
            ],
            [
              -106.41598,
              38.919417
            ],
            [
              -106.417868,
              38.923429
            ],
            [
              -106.433715,
              38.920492
            ],
            [
              -106.444757,
              38.914578
            ],
            [
              -106.470044,
              38.913053
            ],
            [
              -106.489938,
              38.926316
            ],
            [
              -106.505893,
              38.940929
            ],
            [
              -106.521327,
              38.953688
            ],
            [
              -106.522233,
              38.96797
            ],
            [
              -106.54215,
              38.988277
            ],
            [
              -106.560427,
              38.998232
            ],
            [
              -106.580289,
              38.991912
            ],
            [
              -106.589276,
              38.991879
            ],
            [
              -106.597336,
              38.99466
            ],
            [
              -106.599214,
              38.997994
            ],
            [
              -106.6116,
              38.995434
            ],
            [
              -106.638334,
              39.019223
            ],
            [
              -106.655492,
              39.04442
            ],
            [
              -106.685314,
              39.045383
            ],
            [
              -106.694969,
              39.055667
            ],
            [
              -106.710315,
              39.047573
            ],
            [
              -106.749357,
              39.042528
            ],
            [
              -106.751343,
              39.025794
            ],
            [
              -106.801874,
              38.978303
            ],
            [
              -106.827248,
              38.97772
            ],
            [
              -106.846898,
              38.987007
            ],
            [
              -106.861687,
              39.009439
            ],
            [
              -106.87649,
              39.00694
            ],
            [
              -106.878041,
              38.994275
            ],
            [
              -106.906422,
              38.992091
            ],
            [
              -106.944638,
              39.014427
            ],
            [
              -106.964695,
              39.017545
            ],
            [
              -106.963943,
              39.028513
            ],
            [
              -106.98801,
              39.030797
            ],
            [
              -107.001622,
              39.042273
            ],
            [
              -106.989756,
              39.05198
            ],
            [
              -106.996958,
              39.069348
            ],
            [
              -107.019565,
              39.08937
            ],
            [
              -107.03633,
              39.094928
            ],
            [
              -107.06632,
              39.118805
            ],
            [
              -107.28249,
              39.118718
            ],
            [
              -107.295687,
              39.15365
            ],
            [
              -107.318741,
              39.165313
            ],
            [
              -107.353427,
              39.173042
            ],
            [
              -107.375608,
              39.197931
            ],
            [
              -107.379315,
              39.231091
            ],
            [
              -107.371385,
              39.238924
            ],
            [
              -107.394486,
              39.256299
            ],
            [
              -107.428073,
              39.257596
            ],
            [
              -107.430261,
              39.240541
            ],
            [
              -107.451075,
              39.230673
            ],
            [
              -107.484393,
              39.235217
            ],
            [
              -107.500606,
              39.217916
            ],
            [
              -107.500624,
              38.688404
            ],
            [
              -107.500655,
              38.668553
            ],
            [
              -107.50002,
              38.301926
            ],
            [
              -107.63504,
              38.301896
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "023",
      "COUNTYNS": "00198127",
      "AFFGEOID": "0500000US08023",
      "GEOID": "08023",
      "NAME": "Costilla",
      "LSAD": "06",
      "ALAND": 3179452304,
      "AWATER": 8828906,
      "County_state": "Costilla,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -105.76412,
              37.056199
            ],
            [
              -105.770673,
              37.041777
            ],
            [
              -105.75195,
              37.027943
            ],
            [
              -105.746517,
              37.014768
            ],
            [
              -105.728471,
              37.014982
            ],
            [
              -105.718469674138,
              36.995845920037496
            ],
            [
              -105.718463007598,
              36.99584593031069
            ],
            [
              -105.716471,
              36.995849
            ],
            [
              -105.66472,
              36.995874
            ],
            [
              -105.62747,
              36.995679
            ],
            [
              -105.533922,
              36.995875
            ],
            [
              -105.512485,
              36.995777
            ],
            [
              -105.508836,
              36.995895
            ],
            [
              -105.465182,
              36.995991
            ],
            [
              -105.447255,
              36.996017
            ],
            [
              -105.442459,
              36.995994
            ],
            [
              -105.419665893396,
              36.995858121616
            ],
            [
              -105.41931,
              36.995856
            ],
            [
              -105.251296,
              36.995605
            ],
            [
              -105.220613,
              36.995169
            ],
            [
              -105.22050650671301,
              36.995169276334394
            ],
            [
              -105.155042271642,
              36.9953391463601
            ],
            [
              -105.154176,
              37.293129
            ],
            [
              -105.164559,
              37.289117
            ],
            [
              -105.163096,
              37.328909
            ],
            [
              -105.168906,
              37.361514
            ],
            [
              -105.166418,
              37.38877
            ],
            [
              -105.155169,
              37.399636
            ],
            [
              -105.138567,
              37.400691
            ],
            [
              -105.130585,
              37.411655
            ],
            [
              -105.140526,
              37.418129
            ],
            [
              -105.156185,
              37.45709
            ],
            [
              -105.17537,
              37.456288
            ],
            [
              -105.176683,
              37.475594
            ],
            [
              -105.17132,
              37.513246
            ],
            [
              -105.178352,
              37.546319
            ],
            [
              -105.19351,
              37.566921
            ],
            [
              -105.218333,
              37.582439
            ],
            [
              -105.20377,
              37.594449
            ],
            [
              -105.187549,
              37.619669
            ],
            [
              -105.214232,
              37.626324
            ],
            [
              -105.235978,
              37.620773
            ],
            [
              -105.297623,
              37.658143
            ],
            [
              -105.34856,
              37.637758
            ],
            [
              -105.370305,
              37.639396
            ],
            [
              -105.400312,
              37.613415
            ],
            [
              -105.4084,
              37.618
            ],
            [
              -105.442279,
              37.611851
            ],
            [
              -105.454492,
              37.590483
            ],
            [
              -105.485516,
              37.577899
            ],
            [
              -105.49631,
              37.563852
            ],
            [
              -105.743339,
              37.356798
            ],
            [
              -105.753191,
              37.343657
            ],
            [
              -105.720084,
              37.290195
            ],
            [
              -105.723914,
              37.274611
            ],
            [
              -105.738779,
              37.263563
            ],
            [
              -105.746099,
              37.247774
            ],
            [
              -105.740878,
              37.240925
            ],
            [
              -105.745765,
              37.218131
            ],
            [
              -105.729755,
              37.180506
            ],
            [
              -105.738035,
              37.165484
            ],
            [
              -105.731404,
              37.156249
            ],
            [
              -105.748834,
              37.135001
            ],
            [
              -105.749162,
              37.112172
            ],
            [
              -105.740837,
              37.103297
            ],
            [
              -105.770171,
              37.061166
            ],
            [
              -105.76412,
              37.056199
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "123",
      "COUNTYNS": "00198177",
      "AFFGEOID": "0500000US08123",
      "GEOID": "08123",
      "NAME": "Weld",
      "LSAD": "06",
      "ALAND": 10323705895,
      "AWATER": 79558079,
      "County_state": "Weld,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -104.979886,
              40.033051
            ],
            [
              -104.979917,
              40.029486
            ],
            [
              -104.973074,
              40.029512
            ],
            [
              -104.97305,
              40.033037
            ],
            [
              -104.977635,
              40.033057
            ],
            [
              -104.979886,
              40.033051
            ]
          ]
        ],
        [
          [
            [
              -105.05672,
              40.349281
            ],
            [
              -105.055135,
              40.335183
            ],
            [
              -105.055089,
              40.261793
            ],
            [
              -105.0551,
              40.25612
            ],
            [
              -105.055184,
              40.20368
            ],
            [
              -105.055393,
              40.138263
            ],
            [
              -105.05543,
              40.087512
            ],
            [
              -105.055628,
              40.001551
            ],
            [
              -105.052823,
              40.00026
            ],
            [
              -105.007906,
              40.000483
            ],
            [
              -104.989647,
              40.007679
            ],
            [
              -104.999007,
              40.025776
            ],
            [
              -104.961071,
              40.044144
            ],
            [
              -104.961405,
              40.000337
            ],
            [
              -104.922153,
              40.00033
            ],
            [
              -104.885967,
              40.000356
            ],
            [
              -104.753728,
              40.000471
            ],
            [
              -104.489757,
              40.000613
            ],
            [
              -104.150332,
              40.000856
            ],
            [
              -104.150494,
              40.350245
            ],
            [
              -104.147714,
              40.524389
            ],
            [
              -103.581844,
              40.523383
            ],
            [
              -103.580402,
              40.696992
            ],
            [
              -103.574301,
              40.702018
            ],
            [
              -103.573773995352,
              41.0017201653792
            ],
            [
              -103.574522,
              41.001721
            ],
            [
              -103.750498,
              41.002054
            ],
            [
              -103.858449,
              41.001681
            ],
            [
              -103.877967,
              41.001673
            ],
            [
              -103.896207,
              41.00175
            ],
            [
              -103.906324,
              41.001387
            ],
            [
              -103.953525,
              41.001596
            ],
            [
              -103.971373,
              41.001524
            ],
            [
              -103.972642,
              41.001615
            ],
            [
              -104.018223,
              41.001617
            ],
            [
              -104.023383,
              41.001887
            ],
            [
              -104.039238,
              41.001502
            ],
            [
              -104.053249,
              41.001406
            ],
            [
              -104.066961,
              41.001504
            ],
            [
              -104.086068,
              41.001563
            ],
            [
              -104.10459,
              41.001543
            ],
            [
              -104.123586,
              41.001626
            ],
            [
              -104.211473,
              41.001591
            ],
            [
              -104.214191,
              41.001568
            ],
            [
              -104.214692,
              41.001657
            ],
            [
              -104.467672,
              41.001473
            ],
            [
              -104.497058,
              41.001805
            ],
            [
              -104.497149,
              41.001828
            ],
            [
              -104.599525227751,
              41.0013294274846
            ],
            [
              -104.675999,
              41.000957
            ],
            [
              -104.77302720016701,
              40.9998906726903
            ],
            [
              -104.829504,
              40.99927
            ],
            [
              -104.855273,
              40.998048
            ],
            [
              -104.943371,
              40.998083723808904
            ],
            [
              -104.943244,
              40.682254
            ],
            [
              -104.944091,
              40.595356
            ],
            [
              -104.945551,
              40.349099
            ],
            [
              -104.981291,
              40.349164
            ],
            [
              -104.98346,
              40.349184
            ],
            [
              -105.002429,
              40.349217
            ],
            [
              -105.05672,
              40.349281
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "071",
      "COUNTYNS": "00198151",
      "AFFGEOID": "0500000US08071",
      "GEOID": "08071",
      "NAME": "Las Animas",
      "LSAD": "06",
      "ALAND": 12361852569,
      "AWATER": 6929970,
      "County_state": "Las Animas,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -105.154176,
              37.293129
            ],
            [
              -105.155042271642,
              36.9953391463601
            ],
            [
              -105.13644756670801,
              36.9953873968744
            ],
            [
              -105.1208,
              36.995428
            ],
            [
              -105.029228,
              36.992729
            ],
            [
              -105.000554,
              36.993264
            ],
            [
              -104.73212,
              36.993484
            ],
            [
              -104.732031,
              36.993447
            ],
            [
              -104.645029,
              36.993378
            ],
            [
              -104.625545,
              36.993599
            ],
            [
              -104.624556,
              36.994377
            ],
            [
              -104.519257,
              36.993766
            ],
            [
              -104.338833,
              36.993535
            ],
            [
              -104.250536,
              36.994644
            ],
            [
              -104.007855,
              36.996239
            ],
            [
              -104.007851691976,
              36.99623902179619
            ],
            [
              -103.734364,
              36.998041
            ],
            [
              -103.733247,
              36.998016
            ],
            [
              -103.155922,
              37.000232
            ],
            [
              -103.086102011771,
              37.000173863231296
            ],
            [
              -103.082537,
              37.381613
            ],
            [
              -103.074663,
              37.381754
            ],
            [
              -103.075938,
              37.64342
            ],
            [
              -103.404339,
              37.643576
            ],
            [
              -104.058196,
              37.644049
            ],
            [
              -104.059188,
              37.726203
            ],
            [
              -104.061132,
              37.734704
            ],
            [
              -104.351109,
              37.817488
            ],
            [
              -104.359188,
              37.811142
            ],
            [
              -104.415876,
              37.773325
            ],
            [
              -104.456887,
              37.738088
            ],
            [
              -104.487198,
              37.695485
            ],
            [
              -104.513466,
              37.658957
            ],
            [
              -104.543242,
              37.61672
            ],
            [
              -104.556928,
              37.569536
            ],
            [
              -104.620338,
              37.530053
            ],
            [
              -104.661846,
              37.494933
            ],
            [
              -104.680737,
              37.496
            ],
            [
              -104.686229,
              37.495797
            ],
            [
              -104.691229,
              37.491828
            ],
            [
              -104.692477,
              37.486475
            ],
            [
              -104.695599,
              37.483821
            ],
            [
              -104.694004,
              37.481978
            ],
            [
              -104.692998,
              37.480929
            ],
            [
              -104.692836,
              37.477428
            ],
            [
              -104.695863,
              37.47511
            ],
            [
              -104.699279,
              37.46872
            ],
            [
              -104.699483,
              37.457259
            ],
            [
              -104.700495,
              37.452154
            ],
            [
              -104.698557,
              37.447377
            ],
            [
              -104.696272,
              37.443423
            ],
            [
              -104.699109,
              37.440109
            ],
            [
              -104.704625,
              37.437645
            ],
            [
              -104.711463,
              37.437026
            ],
            [
              -104.71448,
              37.435333
            ],
            [
              -104.718099,
              37.431826
            ],
            [
              -104.725532,
              37.428317
            ],
            [
              -104.730997,
              37.427095
            ],
            [
              -104.735715,
              37.425123
            ],
            [
              -104.738345,
              37.422423
            ],
            [
              -104.742426,
              37.41979
            ],
            [
              -104.750541,
              37.406869
            ],
            [
              -104.760756,
              37.405451
            ],
            [
              -104.773535,
              37.405636
            ],
            [
              -104.782008,
              37.401292
            ],
            [
              -104.793559,
              37.401197
            ],
            [
              -104.804973,
              37.40248
            ],
            [
              -104.811821,
              37.399359
            ],
            [
              -104.821107,
              37.397847
            ],
            [
              -104.83509,
              37.397109
            ],
            [
              -104.847202,
              37.39569
            ],
            [
              -104.8628,
              37.391698
            ],
            [
              -104.873948,
              37.388678
            ],
            [
              -104.881378,
              37.386831
            ],
            [
              -104.890166,
              37.388589
            ],
            [
              -104.902014,
              37.389733
            ],
            [
              -104.912573,
              37.393648
            ],
            [
              -104.923252,
              37.395176
            ],
            [
              -104.931473,
              37.395898
            ],
            [
              -104.942534,
              37.38976
            ],
            [
              -104.954715,
              37.388778
            ],
            [
              -104.969285,
              37.377783
            ],
            [
              -104.983527,
              37.373388
            ],
            [
              -105.000554,
              37.369391
            ],
            [
              -105.005539,
              37.362931
            ],
            [
              -105.016674,
              37.357831
            ],
            [
              -105.024069,
              37.352959
            ],
            [
              -105.024117,
              37.345832
            ],
            [
              -105.025355,
              37.34007
            ],
            [
              -105.032002,
              37.339765
            ],
            [
              -105.037336,
              37.340843
            ],
            [
              -105.045961,
              37.335119
            ],
            [
              -105.054451,
              37.330288
            ],
            [
              -105.060755,
              37.32834
            ],
            [
              -105.066718,
              37.326485
            ],
            [
              -105.07377,
              37.318338
            ],
            [
              -105.082378,
              37.315565
            ],
            [
              -105.089568,
              37.315198
            ],
            [
              -105.095248,
              37.310452
            ],
            [
              -105.098554,
              37.303608
            ],
            [
              -105.111868,
              37.298859
            ],
            [
              -105.124966,
              37.296495
            ],
            [
              -105.134407,
              37.299191
            ],
            [
              -105.148895,
              37.29562
            ],
            [
              -105.154176,
              37.293129
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "107",
      "COUNTYNS": "00198169",
      "AFFGEOID": "0500000US08107",
      "GEOID": "08107",
      "NAME": "Routt",
      "LSAD": "06",
      "ALAND": 6117602774,
      "AWATER": 15831744,
      "County_state": "Routt,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -107.442594,
              40.334755
            ],
            [
              -107.439386,
              40.223379
            ],
            [
              -107.038097,
              40.225357
            ],
            [
              -107.037363,
              40.091538
            ],
            [
              -107.03205,
              40.002939
            ],
            [
              -107.033968,
              39.918913
            ],
            [
              -106.653132,
              39.919964
            ],
            [
              -106.626555,
              39.918671
            ],
            [
              -106.626569,
              39.924785
            ],
            [
              -106.626112,
              39.999986
            ],
            [
              -106.637031,
              40.002106
            ],
            [
              -106.636995,
              40.178985
            ],
            [
              -106.632257,
              40.341559
            ],
            [
              -106.652832,
              40.341562
            ],
            [
              -106.652112,
              40.445231
            ],
            [
              -106.65634,
              40.451339
            ],
            [
              -106.658202,
              40.464986
            ],
            [
              -106.663489,
              40.470583
            ],
            [
              -106.664772,
              40.477118
            ],
            [
              -106.660631,
              40.481448
            ],
            [
              -106.659018,
              40.489779
            ],
            [
              -106.654919,
              40.497789
            ],
            [
              -106.656116,
              40.509454
            ],
            [
              -106.65907,
              40.514582
            ],
            [
              -106.667101,
              40.520143
            ],
            [
              -106.672612,
              40.52802
            ],
            [
              -106.669845,
              40.534446
            ],
            [
              -106.677915,
              40.543132
            ],
            [
              -106.682471,
              40.544558
            ],
            [
              -106.684964,
              40.54946
            ],
            [
              -106.692188,
              40.563537
            ],
            [
              -106.699975,
              40.57575
            ],
            [
              -106.69997,
              40.581789
            ],
            [
              -106.698003,
              40.588764
            ],
            [
              -106.695059,
              40.597961
            ],
            [
              -106.702214,
              40.605158
            ],
            [
              -106.706149,
              40.616597
            ],
            [
              -106.701893,
              40.626849
            ],
            [
              -106.696445,
              40.630628
            ],
            [
              -106.686016,
              40.641441
            ],
            [
              -106.684224,
              40.649449
            ],
            [
              -106.688505,
              40.661187
            ],
            [
              -106.681783,
              40.676755
            ],
            [
              -106.684676,
              40.679616
            ],
            [
              -106.679683,
              40.684675
            ],
            [
              -106.6739,
              40.687857
            ],
            [
              -106.668241,
              40.696837
            ],
            [
              -106.672551,
              40.708002
            ],
            [
              -106.669784,
              40.719788
            ],
            [
              -106.670377,
              40.731313
            ],
            [
              -106.673473,
              40.737167
            ],
            [
              -106.663805,
              40.747233
            ],
            [
              -106.66241,
              40.754049
            ],
            [
              -106.667328,
              40.758766
            ],
            [
              -106.664794,
              40.765534
            ],
            [
              -106.661016,
              40.771218
            ],
            [
              -106.650403,
              40.775655
            ],
            [
              -106.649087,
              40.780577
            ],
            [
              -106.641549,
              40.786163
            ],
            [
              -106.63661,
              40.795421
            ],
            [
              -106.636545,
              40.803717
            ],
            [
              -106.637593,
              40.813306
            ],
            [
              -106.644372,
              40.821489
            ],
            [
              -106.653577,
              40.823261
            ],
            [
              -106.659012,
              40.824109
            ],
            [
              -106.661096,
              40.826921
            ],
            [
              -106.664014,
              40.829285
            ],
            [
              -106.663656,
              40.833596
            ],
            [
              -106.661676,
              40.839966
            ],
            [
              -106.657917,
              40.844547
            ],
            [
              -106.656431,
              40.851487
            ],
            [
              -106.663095,
              40.856274
            ],
            [
              -106.665147,
              40.858812
            ],
            [
              -106.668769,
              40.865487
            ],
            [
              -106.678299,
              40.868279
            ],
            [
              -106.681392,
              40.874971
            ],
            [
              -106.686007,
              40.882912
            ],
            [
              -106.688792,
              40.886866
            ],
            [
              -106.695675,
              40.88727
            ],
            [
              -106.700189,
              40.882648
            ],
            [
              -106.706783,
              40.875675
            ],
            [
              -106.732285,
              40.872086
            ],
            [
              -106.74191,
              40.874119
            ],
            [
              -106.745448,
              40.879501
            ],
            [
              -106.743572,
              40.886159
            ],
            [
              -106.743264,
              40.891744
            ],
            [
              -106.750597,
              40.8938
            ],
            [
              -106.758367,
              40.894108
            ],
            [
              -106.765559,
              40.896343
            ],
            [
              -106.76771,
              40.89939
            ],
            [
              -106.772044,
              40.904727
            ],
            [
              -106.779862,
              40.906727
            ],
            [
              -106.786979,
              40.902907
            ],
            [
              -106.78881,
              40.900714
            ],
            [
              -106.794857,
              40.899021
            ],
            [
              -106.802068,
              40.896909
            ],
            [
              -106.806009,
              40.897996
            ],
            [
              -106.807526,
              40.900551
            ],
            [
              -106.805621,
              40.902728
            ],
            [
              -106.809039,
              40.90999
            ],
            [
              -106.815298,
              40.917339
            ],
            [
              -106.818597,
              40.921554
            ],
            [
              -106.822726,
              40.928098
            ],
            [
              -106.823332,
              40.929766
            ],
            [
              -106.825884,
              40.928186
            ],
            [
              -106.828021,
              40.925162
            ],
            [
              -106.837778,
              40.924102
            ],
            [
              -106.84383,
              40.92489
            ],
            [
              -106.848459,
              40.924291
            ],
            [
              -106.849362,
              40.925918
            ],
            [
              -106.852126,
              40.931334
            ],
            [
              -106.856235,
              40.936143
            ],
            [
              -106.861042,
              40.940025
            ],
            [
              -106.862241,
              40.946649
            ],
            [
              -106.865212,
              40.954361
            ],
            [
              -106.869526,
              40.959823
            ],
            [
              -106.872652,
              40.970082
            ],
            [
              -106.87109,
              40.979385
            ],
            [
              -106.860747,
              40.983471
            ],
            [
              -106.858657,
              40.982811
            ],
            [
              -106.855638,
              40.985247
            ],
            [
              -106.854614,
              40.987892
            ],
            [
              -106.853497,
              40.992535
            ],
            [
              -106.858369,
              40.994724
            ],
            [
              -106.85993,
              41.001033
            ],
            [
              -106.857773,
              41.002663
            ],
            [
              -107.000606,
              41.003444
            ],
            [
              -107.241194,
              41.002804
            ],
            [
              -107.317793899405,
              41.0029672121675
            ],
            [
              -107.314153,
              40.600281
            ],
            [
              -107.371487,
              40.600302
            ],
            [
              -107.371328,
              40.542399
            ],
            [
              -107.428813,
              40.542207
            ],
            [
              -107.432068,
              40.334879
            ],
            [
              -107.442594,
              40.334755
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "035",
      "COUNTYNS": "00198133",
      "AFFGEOID": "0500000US08035",
      "GEOID": "08035",
      "NAME": "Douglas",
      "LSAD": "06",
      "ALAND": 2176069189,
      "AWATER": 6950848,
      "County_state": "Douglas,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -105.313557,
              39.159121
            ],
            [
              -105.32922,
              39.129689
            ],
            [
              -105.033544,
              39.129819
            ],
            [
              -104.89503,
              39.129857
            ],
            [
              -104.662896,
              39.129527
            ],
            [
              -104.660626,
              39.565909
            ],
            [
              -104.679306,
              39.565991
            ],
            [
              -104.746043,
              39.566025
            ],
            [
              -104.784965,
              39.566088
            ],
            [
              -104.815686,
              39.565988
            ],
            [
              -104.904415,
              39.565982
            ],
            [
              -104.913831,
              39.566029
            ],
            [
              -104.973255,
              39.566069
            ],
            [
              -105.04874,
              39.566088
            ],
            [
              -105.05306,
              39.563109
            ],
            [
              -105.060714,
              39.559941
            ],
            [
              -105.073045,
              39.545265
            ],
            [
              -105.086109,
              39.494923
            ],
            [
              -105.134993,
              39.470785
            ],
            [
              -105.123522,
              39.434561
            ],
            [
              -105.138206,
              39.42307
            ],
            [
              -105.170736,
              39.407528
            ],
            [
              -105.164337,
              39.391136
            ],
            [
              -105.172997,
              39.377722
            ],
            [
              -105.166574,
              39.361754
            ],
            [
              -105.182305,
              39.329797
            ],
            [
              -105.198156,
              39.310432
            ],
            [
              -105.220776,
              39.268063
            ],
            [
              -105.217419,
              39.260395
            ],
            [
              -105.234393,
              39.255307
            ],
            [
              -105.233938,
              39.246868
            ],
            [
              -105.252428,
              39.243695
            ],
            [
              -105.263511,
              39.229261
            ],
            [
              -105.260054,
              39.210445
            ],
            [
              -105.282929,
              39.199953
            ],
            [
              -105.287377,
              39.179442
            ],
            [
              -105.313557,
              39.159121
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "103",
      "COUNTYNS": "00198167",
      "AFFGEOID": "0500000US08103",
      "GEOID": "08103",
      "NAME": "Rio Blanco",
      "LSAD": "06",
      "ALAND": 8342275028,
      "AWATER": 4868111,
      "County_state": "Rio Blanco,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -109.050615,
              39.87497
            ],
            [
              -109.051040251034,
              39.660470649351794
            ],
            [
              -108.895704,
              39.660487
            ],
            [
              -108.895728,
              39.650337
            ],
            [
              -108.554627,
              39.650164
            ],
            [
              -108.554722,
              39.69364
            ],
            [
              -107.938546,
              39.694464
            ],
            [
              -107.938502,
              39.824996
            ],
            [
              -107.787206,
              39.825047
            ],
            [
              -107.694229,
              39.830107
            ],
            [
              -107.43177,
              39.828377
            ],
            [
              -107.431665,
              39.917725
            ],
            [
              -107.318751,
              39.914968
            ],
            [
              -107.319247,
              40.003765
            ],
            [
              -107.316993,
              40.090765
            ],
            [
              -107.037363,
              40.091538
            ],
            [
              -107.038097,
              40.225357
            ],
            [
              -107.439386,
              40.223379
            ],
            [
              -107.44108,
              40.220438
            ],
            [
              -107.495618,
              40.219991
            ],
            [
              -107.644175,
              40.224238
            ],
            [
              -107.653987,
              40.223452
            ],
            [
              -108.625652,
              40.222361
            ],
            [
              -109.050968717402,
              40.222647002020196
            ],
            [
              -109.050973,
              40.180849
            ],
            [
              -109.050944,
              40.180712
            ],
            [
              -109.050813,
              40.059579
            ],
            [
              -109.050873,
              40.058915
            ],
            [
              -109.050615,
              39.87497
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "014",
      "COUNTYNS": "01945881",
      "AFFGEOID": "0500000US08014",
      "GEOID": "08014",
      "NAME": "Broomfield",
      "LSAD": "06",
      "ALAND": 85478932,
      "AWATER": 1411395,
      "County_state": "Broomfield,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -105.107374,
              39.957915
            ],
            [
              -105.109179,
              39.956214
            ],
            [
              -105.103757,
              39.957538
            ],
            [
              -105.091149,
              39.95784
            ],
            [
              -105.099961,
              39.958133
            ],
            [
              -105.09996,
              39.958979
            ],
            [
              -105.103852,
              39.958651
            ],
            [
              -105.106758,
              39.958159
            ],
            [
              -105.107374,
              39.957915
            ]
          ]
        ],
        [
          [
            [
              -105.165689,
              39.910503
            ],
            [
              -105.165571,
              39.892495
            ],
            [
              -105.128895,
              39.890353
            ],
            [
              -105.147117,
              39.914002
            ],
            [
              -105.108609,
              39.914137
            ],
            [
              -105.096692,
              39.899788
            ],
            [
              -105.071688,
              39.899832
            ],
            [
              -105.052892,
              39.914224
            ],
            [
              -105.015469,
              39.921402
            ],
            [
              -105.015845,
              39.981193
            ],
            [
              -104.988159,
              39.968466
            ],
            [
              -104.980959,
              40.000239
            ],
            [
              -104.961405,
              40.000337
            ],
            [
              -104.961071,
              40.044144
            ],
            [
              -104.999007,
              40.025776
            ],
            [
              -104.989647,
              40.007679
            ],
            [
              -105.007906,
              40.000483
            ],
            [
              -105.052823,
              40.00026
            ],
            [
              -105.052774,
              39.986398
            ],
            [
              -105.052797,
              39.977674
            ],
            [
              -105.068065,
              39.970932
            ],
            [
              -105.088613,
              39.95803
            ],
            [
              -105.072002,
              39.95767
            ],
            [
              -105.076637,
              39.943329
            ],
            [
              -105.109236,
              39.936135
            ],
            [
              -105.109414,
              39.925475
            ],
            [
              -105.118657,
              39.932965
            ],
            [
              -105.109528,
              39.956457
            ],
            [
              -105.128002,
              39.954173
            ],
            [
              -105.137579,
              39.939688
            ],
            [
              -105.147041,
              39.928938
            ],
            [
              -105.147143,
              39.913889
            ],
            [
              -105.165689,
              39.910503
            ]
          ],
          [
            [
              -105.082566,
              39.959277
            ],
            [
              -105.057709,
              39.974669
            ],
            [
              -105.057747,
              39.968636
            ],
            [
              -105.052833,
              39.964319
            ],
            [
              -105.052889,
              39.957826
            ],
            [
              -105.082566,
              39.959277
            ]
          ],
          [
            [
              -104.979917,
              40.029486
            ],
            [
              -104.979886,
              40.033051
            ],
            [
              -104.977635,
              40.033057
            ],
            [
              -104.97305,
              40.033037
            ],
            [
              -104.973074,
              40.029512
            ],
            [
              -104.979917,
              40.029486
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "031",
      "COUNTYNS": "00198131",
      "AFFGEOID": "0500000US08031",
      "GEOID": "08031",
      "NAME": "Denver",
      "LSAD": "06",
      "ALAND": 397057845,
      "AWATER": 4236701,
      "County_state": "Denver,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -105.109874,
              39.635186
            ],
            [
              -105.109927,
              39.626988
            ],
            [
              -105.08173,
              39.627313
            ],
            [
              -105.065024,
              39.619923
            ],
            [
              -105.093074,
              39.618325
            ],
            [
              -105.096955,
              39.617969
            ],
            [
              -105.091405,
              39.614455
            ],
            [
              -105.058125,
              39.61443
            ],
            [
              -105.053439,
              39.621423
            ],
            [
              -105.053457,
              39.629526
            ],
            [
              -105.057009,
              39.631679
            ],
            [
              -105.059457,
              39.634662
            ],
            [
              -105.058996,
              39.638177
            ],
            [
              -105.059431,
              39.638812
            ],
            [
              -105.053333,
              39.638893
            ],
            [
              -105.053411,
              39.631586
            ],
            [
              -105.044048,
              39.628016
            ],
            [
              -105.034822,
              39.627809
            ],
            [
              -105.02555,
              39.628539
            ],
            [
              -105.034171,
              39.65329
            ],
            [
              -105.0158,
              39.660508
            ],
            [
              -105.008664,
              39.678627
            ],
            [
              -105.010665,
              39.660507
            ],
            [
              -104.973486,
              39.667684
            ],
            [
              -104.940683,
              39.653013
            ],
            [
              -104.931703,
              39.653044
            ],
            [
              -104.913257,
              39.638691
            ],
            [
              -104.913407,
              39.624083
            ],
            [
              -104.885265,
              39.624083
            ],
            [
              -104.885112,
              39.639262
            ],
            [
              -104.880313,
              39.645902
            ],
            [
              -104.876224,
              39.644301
            ],
            [
              -104.847639,
              39.656712
            ],
            [
              -104.848332,
              39.659161
            ],
            [
              -104.866051,
              39.670847
            ],
            [
              -104.903509,
              39.667624
            ],
            [
              -104.889308,
              39.682056
            ],
            [
              -104.90351,
              39.692581
            ],
            [
              -104.891715,
              39.69659
            ],
            [
              -104.865962,
              39.696559
            ],
            [
              -104.87533,
              39.725576
            ],
            [
              -104.886613,
              39.729219
            ],
            [
              -104.884708,
              39.732846
            ],
            [
              -104.884646,
              39.740156
            ],
            [
              -104.879901,
              39.754607
            ],
            [
              -104.84694,
              39.754687
            ],
            [
              -104.856523,
              39.768391
            ],
            [
              -104.832961,
              39.766195
            ],
            [
              -104.828229,
              39.773174
            ],
            [
              -104.781727,
              39.773387
            ],
            [
              -104.734601,
              39.769181
            ],
            [
              -104.734399,
              39.798442
            ],
            [
              -104.762606,
              39.798433
            ],
            [
              -104.76247,
              39.82298
            ],
            [
              -104.619938,
              39.822421
            ],
            [
              -104.619841,
              39.873767
            ],
            [
              -104.605791,
              39.873827
            ],
            [
              -104.600435,
              39.899459
            ],
            [
              -104.633969,
              39.899289
            ],
            [
              -104.64037,
              39.906241
            ],
            [
              -104.680213,
              39.906599
            ],
            [
              -104.692934,
              39.914183
            ],
            [
              -104.705568,
              39.89849
            ],
            [
              -104.724103,
              39.898577
            ],
            [
              -104.73155,
              39.898611
            ],
            [
              -104.732138,
              39.845086
            ],
            [
              -104.790859,
              39.841802
            ],
            [
              -104.79091,
              39.79825
            ],
            [
              -104.866041,
              39.798386
            ],
            [
              -104.866075,
              39.812986
            ],
            [
              -104.884585,
              39.812975
            ],
            [
              -104.884627,
              39.798321
            ],
            [
              -104.903561,
              39.798391
            ],
            [
              -104.912887,
              39.783818
            ],
            [
              -104.927002,
              39.783778
            ],
            [
              -104.939882,
              39.790994
            ],
            [
              -104.968716,
              39.798396
            ],
            [
              -104.97595,
              39.791038
            ],
            [
              -105.015832,
              39.791072
            ],
            [
              -105.021339,
              39.793984
            ],
            [
              -105.053246,
              39.791063
            ],
            [
              -105.065083,
              39.788086
            ],
            [
              -105.053183,
              39.783797
            ],
            [
              -105.053237,
              39.755694
            ],
            [
              -105.05321,
              39.743273
            ],
            [
              -105.055539,
              39.733098
            ],
            [
              -105.053253,
              39.725828
            ],
            [
              -105.053247,
              39.703985
            ],
            [
              -105.053225,
              39.696769
            ],
            [
              -105.06262,
              39.67928
            ],
            [
              -105.053256,
              39.667785
            ],
            [
              -105.06011,
              39.66778
            ],
            [
              -105.08135,
              39.670099
            ],
            [
              -105.053727,
              39.651159
            ],
            [
              -105.076803,
              39.638735
            ],
            [
              -105.109874,
              39.635186
            ]
          ],
          [
            [
              -104.940633,
              39.694755
            ],
            [
              -104.940687,
              39.711221
            ],
            [
              -104.926981,
              39.708004
            ],
            [
              -104.931423,
              39.703789
            ],
            [
              -104.928029,
              39.696635
            ],
            [
              -104.940633,
              39.694755
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "013",
      "COUNTYNS": "00198122",
      "AFFGEOID": "0500000US08013",
      "GEOID": "08013",
      "NAME": "Boulder",
      "LSAD": "06",
      "ALAND": 1881281634,
      "AWATER": 36521204,
      "County_state": "Boulder,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -105.057709,
              39.974669
            ],
            [
              -105.082566,
              39.959277
            ],
            [
              -105.052889,
              39.957826
            ],
            [
              -105.052833,
              39.964319
            ],
            [
              -105.057747,
              39.968636
            ],
            [
              -105.057709,
              39.974669
            ]
          ]
        ],
        [
          [
            [
              -105.683164,
              39.992881
            ],
            [
              -105.6932,
              39.941239
            ],
            [
              -105.675798,
              39.932445
            ],
            [
              -105.457484,
              39.932187
            ],
            [
              -105.438404,
              39.937332
            ],
            [
              -105.429325,
              39.931209
            ],
            [
              -105.422063,
              39.931626
            ],
            [
              -105.41911,
              39.928819
            ],
            [
              -105.413971,
              39.934409
            ],
            [
              -105.406488,
              39.9317
            ],
            [
              -105.400687,
              39.935072
            ],
            [
              -105.397955,
              39.932985
            ],
            [
              -105.397849,
              39.912886
            ],
            [
              -105.339547,
              39.913786
            ],
            [
              -105.282977,
              39.913689
            ],
            [
              -105.16615,
              39.914047
            ],
            [
              -105.147143,
              39.913889
            ],
            [
              -105.147041,
              39.928938
            ],
            [
              -105.137579,
              39.939688
            ],
            [
              -105.128002,
              39.954173
            ],
            [
              -105.109528,
              39.956457
            ],
            [
              -105.118657,
              39.932965
            ],
            [
              -105.109414,
              39.925475
            ],
            [
              -105.109236,
              39.936135
            ],
            [
              -105.076637,
              39.943329
            ],
            [
              -105.072002,
              39.95767
            ],
            [
              -105.088613,
              39.95803
            ],
            [
              -105.068065,
              39.970932
            ],
            [
              -105.052797,
              39.977674
            ],
            [
              -105.052774,
              39.986398
            ],
            [
              -105.052823,
              40.00026
            ],
            [
              -105.055628,
              40.001551
            ],
            [
              -105.05543,
              40.087512
            ],
            [
              -105.055393,
              40.138263
            ],
            [
              -105.055184,
              40.20368
            ],
            [
              -105.0551,
              40.25612
            ],
            [
              -105.055089,
              40.261793
            ],
            [
              -105.15906,
              40.261219
            ],
            [
              -105.289445,
              40.262785
            ],
            [
              -105.375226,
              40.261291
            ],
            [
              -105.4041,
              40.257784
            ],
            [
              -105.542801,
              40.26134
            ],
            [
              -105.653321,
              40.260457
            ],
            [
              -105.64402,
              40.249939
            ],
            [
              -105.658932,
              40.249413
            ],
            [
              -105.670559,
              40.207802
            ],
            [
              -105.677688,
              40.201673
            ],
            [
              -105.667729,
              40.158276
            ],
            [
              -105.636551,
              40.146375
            ],
            [
              -105.62609,
              40.127346
            ],
            [
              -105.634652,
              40.113232
            ],
            [
              -105.635429,
              40.074947
            ],
            [
              -105.651061,
              40.058445
            ],
            [
              -105.637486,
              40.045845
            ],
            [
              -105.652793,
              40.023772
            ],
            [
              -105.689818,
              40.015108
            ],
            [
              -105.683164,
              39.992881
            ]
          ],
          [
            [
              -105.109179,
              39.956214
            ],
            [
              -105.107374,
              39.957915
            ],
            [
              -105.106758,
              39.958159
            ],
            [
              -105.103852,
              39.958651
            ],
            [
              -105.09996,
              39.958979
            ],
            [
              -105.099961,
              39.958133
            ],
            [
              -105.091149,
              39.95784
            ],
            [
              -105.103757,
              39.957538
            ],
            [
              -105.109179,
              39.956214
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "069",
      "COUNTYNS": "00198150",
      "AFFGEOID": "0500000US08069",
      "GEOID": "08069",
      "NAME": "Larimer",
      "LSAD": "06",
      "ALAND": 6723022936,
      "AWATER": 99012625,
      "County_state": "Larimer,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -106.190553863626,
              40.9976070173843
            ],
            [
              -106.195372,
              40.989938
            ],
            [
              -106.184226,
              40.955207
            ],
            [
              -106.187599,
              40.937745
            ],
            [
              -106.16817,
              40.918398
            ],
            [
              -106.136622,
              40.901859
            ],
            [
              -106.126893,
              40.869556
            ],
            [
              -106.108404,
              40.863258
            ],
            [
              -106.108132,
              40.852232
            ],
            [
              -106.087914,
              40.833403
            ],
            [
              -106.064831,
              40.821567
            ],
            [
              -106.047971,
              40.804643
            ],
            [
              -106.045371,
              40.791857
            ],
            [
              -106.029247,
              40.773436
            ],
            [
              -106.0107,
              40.741041
            ],
            [
              -105.99627,
              40.733126
            ],
            [
              -105.988739,
              40.709605
            ],
            [
              -105.972196,
              40.694811
            ],
            [
              -105.971873,
              40.681856
            ],
            [
              -105.948325,
              40.641745
            ],
            [
              -105.942064,
              40.609077
            ],
            [
              -105.930207,
              40.60619
            ],
            [
              -105.921248,
              40.572559
            ],
            [
              -105.910892,
              40.56642
            ],
            [
              -105.90509,
              40.535285
            ],
            [
              -105.907857,
              40.515493
            ],
            [
              -105.869091,
              40.520738
            ],
            [
              -105.852303,
              40.506553
            ],
            [
              -105.854926,
              40.486252
            ],
            [
              -105.807341,
              40.474527
            ],
            [
              -105.80569,
              40.447882
            ],
            [
              -105.816366,
              40.435519
            ],
            [
              -105.81534,
              40.421486
            ],
            [
              -105.790951,
              40.39864
            ],
            [
              -105.790729,
              40.386263
            ],
            [
              -105.767699,
              40.359764
            ],
            [
              -105.736345,
              40.346615
            ],
            [
              -105.729088,
              40.335278
            ],
            [
              -105.70168,
              40.324309
            ],
            [
              -105.702235,
              40.31261
            ],
            [
              -105.685772,
              40.302554
            ],
            [
              -105.682428,
              40.2728
            ],
            [
              -105.653321,
              40.260457
            ],
            [
              -105.542801,
              40.26134
            ],
            [
              -105.4041,
              40.257784
            ],
            [
              -105.375226,
              40.261291
            ],
            [
              -105.289445,
              40.262785
            ],
            [
              -105.15906,
              40.261219
            ],
            [
              -105.055089,
              40.261793
            ],
            [
              -105.055135,
              40.335183
            ],
            [
              -105.05672,
              40.349281
            ],
            [
              -105.002429,
              40.349217
            ],
            [
              -104.98346,
              40.349184
            ],
            [
              -104.981291,
              40.349164
            ],
            [
              -104.945551,
              40.349099
            ],
            [
              -104.944091,
              40.595356
            ],
            [
              -104.943244,
              40.682254
            ],
            [
              -104.943371,
              40.998083723808904
            ],
            [
              -105.254779,
              40.99821
            ],
            [
              -105.256527,
              40.998191
            ],
            [
              -105.27685999192101,
              40.99817324279
            ],
            [
              -105.277138,
              40.998173
            ],
            [
              -105.724804,
              40.99691
            ],
            [
              -105.730421,
              40.996886
            ],
            [
              -106.061181,
              40.996999
            ],
            [
              -106.190553863626,
              40.9976070173843
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "025",
      "COUNTYNS": "00198128",
      "AFFGEOID": "0500000US08025",
      "GEOID": "08025",
      "NAME": "Crowley",
      "LSAD": "06",
      "ALAND": 2039427875,
      "AWATER": 33422982,
      "County_state": "Crowley,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -104.054725,
              38.261889
            ],
            [
              -104.058242,
              38.146492
            ],
            [
              -104.047045,
              38.141389
            ],
            [
              -104.022159,
              38.140951
            ],
            [
              -103.994696,
              38.146654
            ],
            [
              -103.974128,
              38.138627
            ],
            [
              -103.974378,
              38.132119
            ],
            [
              -103.933989,
              38.124417
            ],
            [
              -103.923399,
              38.130424
            ],
            [
              -103.878001,
              38.124649
            ],
            [
              -103.868672,
              38.128809
            ],
            [
              -103.846211,
              38.123405
            ],
            [
              -103.835616,
              38.11334
            ],
            [
              -103.81191,
              38.117845
            ],
            [
              -103.803033,
              38.112533
            ],
            [
              -103.619172,
              38.113355
            ],
            [
              -103.619283,
              38.171455
            ],
            [
              -103.509364,
              38.172514
            ],
            [
              -103.509879,
              38.264091
            ],
            [
              -103.501764,
              38.265016
            ],
            [
              -103.506985,
              38.342256
            ],
            [
              -103.504666,
              38.516409
            ],
            [
              -103.61299,
              38.522548
            ],
            [
              -104.053921,
              38.522393
            ],
            [
              -104.054725,
              38.261889
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "027",
      "COUNTYNS": "00198129",
      "AFFGEOID": "0500000US08027",
      "GEOID": "08027",
      "NAME": "Custer",
      "LSAD": "06",
      "ALAND": 1913031958,
      "AWATER": 3364151,
      "County_state": "Custer,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -105.796897,
              38.265047
            ],
            [
              -105.789761,
              38.24436
            ],
            [
              -105.736738,
              38.209561
            ],
            [
              -105.700414,
              38.16918
            ],
            [
              -105.673042,
              38.159126
            ],
            [
              -105.67627,
              38.146173
            ],
            [
              -105.662654,
              38.119158
            ],
            [
              -105.655856,
              38.092291
            ],
            [
              -105.627206,
              38.06731
            ],
            [
              -105.597112,
              38.013638
            ],
            [
              -105.604355,
              38.008745
            ],
            [
              -105.581522,
              37.975225
            ],
            [
              -105.583922,
              37.967196
            ],
            [
              -105.560311,
              37.951886
            ],
            [
              -105.533315,
              37.955182
            ],
            [
              -105.511086,
              37.939131
            ],
            [
              -105.502273,
              37.92295
            ],
            [
              -105.473204,
              37.89597
            ],
            [
              -105.440272,
              37.897461
            ],
            [
              -105.413635,
              37.890527
            ],
            [
              -105.377088,
              37.916102
            ],
            [
              -105.347209,
              37.924759
            ],
            [
              -105.317081,
              37.938949
            ],
            [
              -105.306574,
              37.932292
            ],
            [
              -105.297431,
              37.909349
            ],
            [
              -105.284465,
              37.899512
            ],
            [
              -105.273818,
              37.907677
            ],
            [
              -105.248617,
              37.952109
            ],
            [
              -105.23856,
              37.96284
            ],
            [
              -105.215366,
              37.97052
            ],
            [
              -105.213986,
              37.980809
            ],
            [
              -105.197073,
              37.992416
            ],
            [
              -105.194586,
              38.00756
            ],
            [
              -105.168652,
              38.018946
            ],
            [
              -105.147948,
              38.005392
            ],
            [
              -105.152491,
              37.992113
            ],
            [
              -105.139501,
              37.984607
            ],
            [
              -105.125975,
              37.95117
            ],
            [
              -105.108507,
              37.943399
            ],
            [
              -105.084383,
              37.955384
            ],
            [
              -105.066056,
              37.944209
            ],
            [
              -105.0632,
              37.916976
            ],
            [
              -105.049917,
              37.915479
            ],
            [
              -105.050678,
              38.113824
            ],
            [
              -105.048287,
              38.127773
            ],
            [
              -105.049215,
              38.257973
            ],
            [
              -105.07446,
              38.25806
            ],
            [
              -105.796897,
              38.265047
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "041",
      "COUNTYNS": "00198135",
      "AFFGEOID": "0500000US08041",
      "GEOID": "08041",
      "NAME": "El Paso",
      "LSAD": "06",
      "ALAND": 5508502469,
      "AWATER": 7099036,
      "County_state": "El Paso,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -105.0667,
              38.869508
            ],
            [
              -105.072184,
              38.799382
            ],
            [
              -105.030619,
              38.797133
            ],
            [
              -104.938994,
              38.796884
            ],
            [
              -104.939286,
              38.73698
            ],
            [
              -104.942426,
              38.649882
            ],
            [
              -104.941533,
              38.519569
            ],
            [
              -104.932949,
              38.520286
            ],
            [
              -104.630682,
              38.519917
            ],
            [
              -104.567087,
              38.520001
            ],
            [
              -104.053921,
              38.522393
            ],
            [
              -104.055528,
              38.868868
            ],
            [
              -104.051568,
              39.128138
            ],
            [
              -104.551765,
              39.129427
            ],
            [
              -104.662896,
              39.129527
            ],
            [
              -104.89503,
              39.129857
            ],
            [
              -105.033544,
              39.129819
            ],
            [
              -105.026945,
              39.029831
            ],
            [
              -105.027655,
              39.019542
            ],
            [
              -105.028903,
              38.868907
            ],
            [
              -105.0667,
              38.869508
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "047",
      "COUNTYNS": "00198139",
      "AFFGEOID": "0500000US08047",
      "GEOID": "08047",
      "NAME": "Gilpin",
      "LSAD": "06",
      "ALAND": 388228996,
      "AWATER": 942056,
      "County_state": "Gilpin,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -105.699719,
              39.891499
            ],
            [
              -105.690348,
              39.851996
            ],
            [
              -105.660471,
              39.850568
            ],
            [
              -105.633065,
              39.843343
            ],
            [
              -105.627569,
              39.832087
            ],
            [
              -105.601972,
              39.817919
            ],
            [
              -105.578037,
              39.822268
            ],
            [
              -105.57805,
              39.805562
            ],
            [
              -105.54598,
              39.786983
            ],
            [
              -105.545076,
              39.773635
            ],
            [
              -105.496939,
              39.75787
            ],
            [
              -105.421994,
              39.755018
            ],
            [
              -105.421669,
              39.745891
            ],
            [
              -105.397949,
              39.747087
            ],
            [
              -105.397849,
              39.912886
            ],
            [
              -105.397955,
              39.932985
            ],
            [
              -105.400687,
              39.935072
            ],
            [
              -105.406488,
              39.9317
            ],
            [
              -105.413971,
              39.934409
            ],
            [
              -105.41911,
              39.928819
            ],
            [
              -105.422063,
              39.931626
            ],
            [
              -105.429325,
              39.931209
            ],
            [
              -105.438404,
              39.937332
            ],
            [
              -105.457484,
              39.932187
            ],
            [
              -105.675798,
              39.932445
            ],
            [
              -105.699719,
              39.891499
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "055",
      "COUNTYNS": "00198143",
      "AFFGEOID": "0500000US08055",
      "GEOID": "08055",
      "NAME": "Huerfano",
      "LSAD": "06",
      "ALAND": 4120756337,
      "AWATER": 5792101,
      "County_state": "Huerfano,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -105.486976,
              37.653483
            ],
            [
              -105.501755,
              37.597571
            ],
            [
              -105.485516,
              37.577899
            ],
            [
              -105.454492,
              37.590483
            ],
            [
              -105.442279,
              37.611851
            ],
            [
              -105.4084,
              37.618
            ],
            [
              -105.400312,
              37.613415
            ],
            [
              -105.370305,
              37.639396
            ],
            [
              -105.34856,
              37.637758
            ],
            [
              -105.297623,
              37.658143
            ],
            [
              -105.235978,
              37.620773
            ],
            [
              -105.214232,
              37.626324
            ],
            [
              -105.187549,
              37.619669
            ],
            [
              -105.20377,
              37.594449
            ],
            [
              -105.218333,
              37.582439
            ],
            [
              -105.19351,
              37.566921
            ],
            [
              -105.178352,
              37.546319
            ],
            [
              -105.17132,
              37.513246
            ],
            [
              -105.176683,
              37.475594
            ],
            [
              -105.17537,
              37.456288
            ],
            [
              -105.156185,
              37.45709
            ],
            [
              -105.140526,
              37.418129
            ],
            [
              -105.130585,
              37.411655
            ],
            [
              -105.138567,
              37.400691
            ],
            [
              -105.155169,
              37.399636
            ],
            [
              -105.166418,
              37.38877
            ],
            [
              -105.168906,
              37.361514
            ],
            [
              -105.163096,
              37.328909
            ],
            [
              -105.164559,
              37.289117
            ],
            [
              -105.154176,
              37.293129
            ],
            [
              -105.148895,
              37.29562
            ],
            [
              -105.134407,
              37.299191
            ],
            [
              -105.124966,
              37.296495
            ],
            [
              -105.111868,
              37.298859
            ],
            [
              -105.098554,
              37.303608
            ],
            [
              -105.095248,
              37.310452
            ],
            [
              -105.089568,
              37.315198
            ],
            [
              -105.082378,
              37.315565
            ],
            [
              -105.07377,
              37.318338
            ],
            [
              -105.066718,
              37.326485
            ],
            [
              -105.060755,
              37.32834
            ],
            [
              -105.054451,
              37.330288
            ],
            [
              -105.045961,
              37.335119
            ],
            [
              -105.037336,
              37.340843
            ],
            [
              -105.032002,
              37.339765
            ],
            [
              -105.025355,
              37.34007
            ],
            [
              -105.024117,
              37.345832
            ],
            [
              -105.024069,
              37.352959
            ],
            [
              -105.016674,
              37.357831
            ],
            [
              -105.005539,
              37.362931
            ],
            [
              -105.000554,
              37.369391
            ],
            [
              -104.983527,
              37.373388
            ],
            [
              -104.969285,
              37.377783
            ],
            [
              -104.954715,
              37.388778
            ],
            [
              -104.942534,
              37.38976
            ],
            [
              -104.931473,
              37.395898
            ],
            [
              -104.923252,
              37.395176
            ],
            [
              -104.912573,
              37.393648
            ],
            [
              -104.902014,
              37.389733
            ],
            [
              -104.890166,
              37.388589
            ],
            [
              -104.881378,
              37.386831
            ],
            [
              -104.873948,
              37.388678
            ],
            [
              -104.8628,
              37.391698
            ],
            [
              -104.847202,
              37.39569
            ],
            [
              -104.83509,
              37.397109
            ],
            [
              -104.821107,
              37.397847
            ],
            [
              -104.811821,
              37.399359
            ],
            [
              -104.804973,
              37.40248
            ],
            [
              -104.793559,
              37.401197
            ],
            [
              -104.782008,
              37.401292
            ],
            [
              -104.773535,
              37.405636
            ],
            [
              -104.760756,
              37.405451
            ],
            [
              -104.750541,
              37.406869
            ],
            [
              -104.742426,
              37.41979
            ],
            [
              -104.738345,
              37.422423
            ],
            [
              -104.735715,
              37.425123
            ],
            [
              -104.730997,
              37.427095
            ],
            [
              -104.725532,
              37.428317
            ],
            [
              -104.718099,
              37.431826
            ],
            [
              -104.71448,
              37.435333
            ],
            [
              -104.711463,
              37.437026
            ],
            [
              -104.704625,
              37.437645
            ],
            [
              -104.699109,
              37.440109
            ],
            [
              -104.696272,
              37.443423
            ],
            [
              -104.698557,
              37.447377
            ],
            [
              -104.700495,
              37.452154
            ],
            [
              -104.699483,
              37.457259
            ],
            [
              -104.699279,
              37.46872
            ],
            [
              -104.695863,
              37.47511
            ],
            [
              -104.692836,
              37.477428
            ],
            [
              -104.692998,
              37.480929
            ],
            [
              -104.694004,
              37.481978
            ],
            [
              -104.695599,
              37.483821
            ],
            [
              -104.692477,
              37.486475
            ],
            [
              -104.691229,
              37.491828
            ],
            [
              -104.686229,
              37.495797
            ],
            [
              -104.680737,
              37.496
            ],
            [
              -104.661846,
              37.494933
            ],
            [
              -104.620338,
              37.530053
            ],
            [
              -104.556928,
              37.569536
            ],
            [
              -104.543242,
              37.61672
            ],
            [
              -104.513466,
              37.658957
            ],
            [
              -104.487198,
              37.695485
            ],
            [
              -104.456887,
              37.738088
            ],
            [
              -104.415876,
              37.773325
            ],
            [
              -104.359188,
              37.811142
            ],
            [
              -104.351109,
              37.817488
            ],
            [
              -104.646383,
              37.900527
            ],
            [
              -104.666059,
              37.899521
            ],
            [
              -104.828794,
              37.891127
            ],
            [
              -105.013729,
              37.881271
            ],
            [
              -105.034803,
              37.894583
            ],
            [
              -105.049917,
              37.915479
            ],
            [
              -105.0632,
              37.916976
            ],
            [
              -105.066056,
              37.944209
            ],
            [
              -105.084383,
              37.955384
            ],
            [
              -105.108507,
              37.943399
            ],
            [
              -105.125975,
              37.95117
            ],
            [
              -105.139501,
              37.984607
            ],
            [
              -105.152491,
              37.992113
            ],
            [
              -105.147948,
              38.005392
            ],
            [
              -105.168652,
              38.018946
            ],
            [
              -105.194586,
              38.00756
            ],
            [
              -105.197073,
              37.992416
            ],
            [
              -105.213986,
              37.980809
            ],
            [
              -105.215366,
              37.97052
            ],
            [
              -105.23856,
              37.96284
            ],
            [
              -105.248617,
              37.952109
            ],
            [
              -105.273818,
              37.907677
            ],
            [
              -105.284465,
              37.899512
            ],
            [
              -105.297431,
              37.909349
            ],
            [
              -105.306574,
              37.932292
            ],
            [
              -105.317081,
              37.938949
            ],
            [
              -105.347209,
              37.924759
            ],
            [
              -105.377088,
              37.916102
            ],
            [
              -105.413635,
              37.890527
            ],
            [
              -105.440272,
              37.897461
            ],
            [
              -105.473204,
              37.89597
            ],
            [
              -105.478495,
              37.87985
            ],
            [
              -105.450537,
              37.878112
            ],
            [
              -105.428893,
              37.851733
            ],
            [
              -105.435179,
              37.804767
            ],
            [
              -105.454912,
              37.795696
            ],
            [
              -105.449454,
              37.778127
            ],
            [
              -105.457255,
              37.751464
            ],
            [
              -105.454245,
              37.723951
            ],
            [
              -105.477302,
              37.707686
            ],
            [
              -105.477351,
              37.665747
            ],
            [
              -105.486976,
              37.653483
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "091",
      "COUNTYNS": "00198161",
      "AFFGEOID": "0500000US08091",
      "GEOID": "08091",
      "NAME": "Ouray",
      "LSAD": "06",
      "ALAND": 1402719352,
      "AWATER": 1599543,
      "County_state": "Ouray,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -108.13238,
              38.331573
            ],
            [
              -108.123269,
              38.313635
            ],
            [
              -108.104819,
              38.313718
            ],
            [
              -108.104161,
              38.284179
            ],
            [
              -108.08635,
              38.284334
            ],
            [
              -108.086604,
              38.255202
            ],
            [
              -108.011531,
              38.255261
            ],
            [
              -108.011531,
              38.248203
            ],
            [
              -107.984084,
              38.248386
            ],
            [
              -107.984184,
              38.233936
            ],
            [
              -107.965419,
              38.234015
            ],
            [
              -107.965408,
              38.226509
            ],
            [
              -107.947142,
              38.226477
            ],
            [
              -107.937588,
              38.218992
            ],
            [
              -107.938145,
              38.173936
            ],
            [
              -107.947744,
              38.173767
            ],
            [
              -107.946963,
              38.152491
            ],
            [
              -107.965789,
              38.152328
            ],
            [
              -107.951717,
              38.144982
            ],
            [
              -107.951574,
              38.116125
            ],
            [
              -107.891469,
              38.115957
            ],
            [
              -107.893774,
              38.057742
            ],
            [
              -107.888208,
              38.045812
            ],
            [
              -107.900193,
              38.026249
            ],
            [
              -107.889716,
              38.011615
            ],
            [
              -107.8833,
              38.007371
            ],
            [
              -107.849131,
              38.008599
            ],
            [
              -107.84908,
              37.992608
            ],
            [
              -107.795469,
              37.98852
            ],
            [
              -107.778675,
              37.960948
            ],
            [
              -107.748897,
              37.953116
            ],
            [
              -107.732788,
              37.929975
            ],
            [
              -107.738283,
              37.905432
            ],
            [
              -107.695665,
              37.89241
            ],
            [
              -107.663756,
              37.903517
            ],
            [
              -107.637926,
              37.920859
            ],
            [
              -107.641588,
              37.965009
            ],
            [
              -107.568875,
              37.965015
            ],
            [
              -107.588221,
              37.970312
            ],
            [
              -107.591367,
              37.992252
            ],
            [
              -107.573888,
              38.006379
            ],
            [
              -107.549254,
              37.99848
            ],
            [
              -107.537975,
              38.008919
            ],
            [
              -107.539856,
              38.027109
            ],
            [
              -107.51088,
              38.060877
            ],
            [
              -107.533262,
              38.080059
            ],
            [
              -107.553585,
              38.0792
            ],
            [
              -107.565394,
              38.098868
            ],
            [
              -107.564733,
              38.127906
            ],
            [
              -107.574245,
              38.136009
            ],
            [
              -107.56862,
              38.147267
            ],
            [
              -107.56219,
              38.158204
            ],
            [
              -107.580978,
              38.212685
            ],
            [
              -107.588821,
              38.249588
            ],
            [
              -107.61157,
              38.260827
            ],
            [
              -107.63504,
              38.301896
            ],
            [
              -107.635697,
              38.332148
            ],
            [
              -107.866597,
              38.332205
            ],
            [
              -108.13238,
              38.331573
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "017",
      "COUNTYNS": "00198124",
      "AFFGEOID": "0500000US08017",
      "GEOID": "08017",
      "NAME": "Cheyenne",
      "LSAD": "06",
      "ALAND": 4605714032,
      "AWATER": 8166134,
      "County_state": "Cheyenne,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -103.172474,
              38.690057
            ],
            [
              -103.172943,
              38.61245
            ],
            [
              -103.062228,
              38.61502
            ],
            [
              -102.068834,
              38.615176
            ],
            [
              -102.058016,
              38.615124
            ],
            [
              -102.045287815606,
              38.6151684415595
            ],
            [
              -102.045288,
              38.615249
            ],
            [
              -102.045074,
              38.669617
            ],
            [
              -102.045102,
              38.674946
            ],
            [
              -102.04516,
              38.675221
            ],
            [
              -102.045127,
              38.686725
            ],
            [
              -102.045156,
              38.688555
            ],
            [
              -102.045212,
              38.697567
            ],
            [
              -102.045375,
              38.754339
            ],
            [
              -102.045287,
              38.755528
            ],
            [
              -102.045371,
              38.770064
            ],
            [
              -102.045448,
              38.783453
            ],
            [
              -102.045334,
              38.799463
            ],
            [
              -102.045388,
              38.813392
            ],
            [
              -102.046571,
              39.047038
            ],
            [
              -102.715766,
              39.038675
            ],
            [
              -102.72411,
              39.040199
            ],
            [
              -103.163025,
              39.03761
            ],
            [
              -103.164159,
              38.690133
            ],
            [
              -103.172474,
              38.690057
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "081",
      "COUNTYNS": "00198156",
      "AFFGEOID": "0500000US08081",
      "GEOID": "08081",
      "NAME": "Moffat",
      "LSAD": "06",
      "ALAND": 12284943876,
      "AWATER": 19803412,
      "County_state": "Moffat,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -109.050946,
              40.444368
            ],
            [
              -109.050968717402,
              40.222647002020196
            ],
            [
              -108.625652,
              40.222361
            ],
            [
              -107.653987,
              40.223452
            ],
            [
              -107.644175,
              40.224238
            ],
            [
              -107.495618,
              40.219991
            ],
            [
              -107.44108,
              40.220438
            ],
            [
              -107.439386,
              40.223379
            ],
            [
              -107.442594,
              40.334755
            ],
            [
              -107.432068,
              40.334879
            ],
            [
              -107.428813,
              40.542207
            ],
            [
              -107.371328,
              40.542399
            ],
            [
              -107.371487,
              40.600302
            ],
            [
              -107.314153,
              40.600281
            ],
            [
              -107.317793899405,
              41.0029672121675
            ],
            [
              -107.367443,
              41.003073
            ],
            [
              -107.625624,
              41.002124
            ],
            [
              -107.918421,
              41.002036
            ],
            [
              -108.046539,
              41.002064
            ],
            [
              -108.181227,
              41.000455
            ],
            [
              -108.250649,
              41.000114
            ],
            [
              -108.500659,
              41.000112
            ],
            [
              -108.526667,
              40.999608
            ],
            [
              -108.631108,
              41.000156
            ],
            [
              -108.884138,
              41.000094
            ],
            [
              -109.050076,
              41.000659
            ],
            [
              -109.048455,
              40.826081
            ],
            [
              -109.049088,
              40.714562
            ],
            [
              -109.048372974241,
              40.662608858969
            ],
            [
              -109.048249,
              40.653601
            ],
            [
              -109.048044,
              40.619231
            ],
            [
              -109.050074,
              40.540358
            ],
            [
              -109.049955,
              40.539901
            ],
            [
              -109.050698,
              40.499963
            ],
            [
              -109.050314,
              40.495092
            ],
            [
              -109.050946,
              40.444368
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "097",
      "COUNTYNS": "00198164",
      "AFFGEOID": "0500000US08097",
      "GEOID": "08097",
      "NAME": "Pitkin",
      "LSAD": "06",
      "ALAND": 2514104933,
      "AWATER": 6472574,
      "County_state": "Pitkin,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -107.430514,
              39.348222
            ],
            [
              -107.465187,
              39.314515
            ],
            [
              -107.412606,
              39.291877
            ],
            [
              -107.398513,
              39.29511
            ],
            [
              -107.379175,
              39.269101
            ],
            [
              -107.394486,
              39.256299
            ],
            [
              -107.371385,
              39.238924
            ],
            [
              -107.379315,
              39.231091
            ],
            [
              -107.375608,
              39.197931
            ],
            [
              -107.353427,
              39.173042
            ],
            [
              -107.318741,
              39.165313
            ],
            [
              -107.295687,
              39.15365
            ],
            [
              -107.28249,
              39.118718
            ],
            [
              -107.06632,
              39.118805
            ],
            [
              -107.03633,
              39.094928
            ],
            [
              -107.019565,
              39.08937
            ],
            [
              -106.996958,
              39.069348
            ],
            [
              -106.989756,
              39.05198
            ],
            [
              -107.001622,
              39.042273
            ],
            [
              -106.98801,
              39.030797
            ],
            [
              -106.963943,
              39.028513
            ],
            [
              -106.964695,
              39.017545
            ],
            [
              -106.944638,
              39.014427
            ],
            [
              -106.906422,
              38.992091
            ],
            [
              -106.878041,
              38.994275
            ],
            [
              -106.87649,
              39.00694
            ],
            [
              -106.861687,
              39.009439
            ],
            [
              -106.846898,
              38.987007
            ],
            [
              -106.827248,
              38.97772
            ],
            [
              -106.801874,
              38.978303
            ],
            [
              -106.751343,
              39.025794
            ],
            [
              -106.749357,
              39.042528
            ],
            [
              -106.710315,
              39.047573
            ],
            [
              -106.694969,
              39.055667
            ],
            [
              -106.685314,
              39.045383
            ],
            [
              -106.655492,
              39.04442
            ],
            [
              -106.638334,
              39.019223
            ],
            [
              -106.6116,
              38.995434
            ],
            [
              -106.599214,
              38.997994
            ],
            [
              -106.591692,
              39.024923
            ],
            [
              -106.597233,
              39.044821
            ],
            [
              -106.577966,
              39.057908
            ],
            [
              -106.586338,
              39.087743
            ],
            [
              -106.580311,
              39.102164
            ],
            [
              -106.563989,
              39.106041
            ],
            [
              -106.558171,
              39.120223
            ],
            [
              -106.564574,
              39.132422
            ],
            [
              -106.561265,
              39.155677
            ],
            [
              -106.535138,
              39.163146
            ],
            [
              -106.520942,
              39.157353
            ],
            [
              -106.508614,
              39.166681
            ],
            [
              -106.493649,
              39.198326
            ],
            [
              -106.489749,
              39.227718
            ],
            [
              -106.497649,
              39.242022
            ],
            [
              -106.483127,
              39.255103
            ],
            [
              -106.483292,
              39.272669
            ],
            [
              -106.504031,
              39.284761
            ],
            [
              -106.50238,
              39.298567
            ],
            [
              -106.485121,
              39.312566
            ],
            [
              -106.472878,
              39.308423
            ],
            [
              -106.432085,
              39.316698
            ],
            [
              -106.434931,
              39.341099
            ],
            [
              -106.426487,
              39.361871
            ],
            [
              -106.489362,
              39.361815
            ],
            [
              -106.509214,
              39.362298
            ],
            [
              -107.113446,
              39.366066
            ],
            [
              -107.430949,
              39.366178
            ],
            [
              -107.430514,
              39.348222
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "099",
      "COUNTYNS": "00198165",
      "AFFGEOID": "0500000US08099",
      "GEOID": "08099",
      "NAME": "Prowers",
      "LSAD": "06",
      "ALAND": 4243428647,
      "AWATER": 15345957,
      "County_state": "Prowers,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -102.747708,
              37.823451
            ],
            [
              -102.747615,
              37.643642
            ],
            [
              -102.041585,
              37.644282
            ],
            [
              -102.041582,
              37.654495
            ],
            [
              -102.041694,
              37.665681
            ],
            [
              -102.041574,
              37.680436
            ],
            [
              -102.041876,
              37.723875
            ],
            [
              -102.041989965863,
              37.7385406283736
            ],
            [
              -102.042158,
              37.760164
            ],
            [
              -102.042668,
              37.788758
            ],
            [
              -102.042953,
              37.803535
            ],
            [
              -102.043033,
              37.824146
            ],
            [
              -102.043219,
              37.867929
            ],
            [
              -102.043845,
              37.926135
            ],
            [
              -102.043844,
              37.928102
            ],
            [
              -102.044644,
              38.045532
            ],
            [
              -102.044255,
              38.113011
            ],
            [
              -102.044589,
              38.125013
            ],
            [
              -102.044251,
              38.141778
            ],
            [
              -102.044398,
              38.250015
            ],
            [
              -102.04451007292,
              38.2624115834718
            ],
            [
              -102.044567368215,
              38.2687491172007
            ],
            [
              -102.722139,
              38.267101
            ],
            [
              -102.723824,
              38.26725
            ],
            [
              -102.74233,
              38.266971
            ],
            [
              -102.740464,
              37.824064
            ],
            [
              -102.747708,
              37.823451
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "111",
      "COUNTYNS": "00198171",
      "AFFGEOID": "0500000US08111",
      "GEOID": "08111",
      "NAME": "San Juan",
      "LSAD": "06",
      "ALAND": 1003660601,
      "AWATER": 2035929,
      "County_state": "San Juan,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -107.957078,
              37.648894
            ],
            [
              -107.970086,
              37.639587
            ],
            [
              -107.758904,
              37.64033
            ],
            [
              -107.743187,
              37.639547
            ],
            [
              -107.482179,
              37.639501
            ],
            [
              -107.482245,
              37.768353
            ],
            [
              -107.46908,
              37.765482
            ],
            [
              -107.458114,
              37.782096
            ],
            [
              -107.468843,
              37.809726
            ],
            [
              -107.49031,
              37.824275
            ],
            [
              -107.516547,
              37.826451
            ],
            [
              -107.517859,
              37.856978
            ],
            [
              -107.536021,
              37.873914
            ],
            [
              -107.533444,
              37.888077
            ],
            [
              -107.520167,
              37.901312
            ],
            [
              -107.535902,
              37.920123
            ],
            [
              -107.532593,
              37.943905
            ],
            [
              -107.563369,
              37.955937
            ],
            [
              -107.568875,
              37.965015
            ],
            [
              -107.641588,
              37.965009
            ],
            [
              -107.637926,
              37.920859
            ],
            [
              -107.663756,
              37.903517
            ],
            [
              -107.695665,
              37.89241
            ],
            [
              -107.738283,
              37.905432
            ],
            [
              -107.756047,
              37.88322
            ],
            [
              -107.782459,
              37.867772
            ],
            [
              -107.779099,
              37.849775
            ],
            [
              -107.796447,
              37.822725
            ],
            [
              -107.828521,
              37.811858
            ],
            [
              -107.822201,
              37.795546
            ],
            [
              -107.840852,
              37.774139
            ],
            [
              -107.860845,
              37.775517
            ],
            [
              -107.870481,
              37.762762
            ],
            [
              -107.861912,
              37.756024
            ],
            [
              -107.875622,
              37.738851
            ],
            [
              -107.872966,
              37.722416
            ],
            [
              -107.901349,
              37.719002
            ],
            [
              -107.937206,
              37.702562
            ],
            [
              -107.952628,
              37.706788
            ],
            [
              -107.968875,
              37.696485
            ],
            [
              -107.96942,
              37.679232
            ],
            [
              -107.957078,
              37.648894
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "067",
      "COUNTYNS": "00198148",
      "AFFGEOID": "0500000US08067",
      "GEOID": "08067",
      "NAME": "La Plata",
      "LSAD": "06",
      "ALAND": 4376522002,
      "AWATER": 25375721,
      "County_state": "La Plata,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -108.379612,
              37.004177
            ],
            [
              -108.380293,
              37.000513
            ],
            [
              -108.379302869798,
              36.999458857844296
            ],
            [
              -108.320721,
              36.99951
            ],
            [
              -108.320464,
              36.999499
            ],
            [
              -108.2884,
              36.99952
            ],
            [
              -108.288086,
              36.999555
            ],
            [
              -108.250635,
              36.999561
            ],
            [
              -108.249358,
              36.999015
            ],
            [
              -108.033460486906,
              36.9998708302929
            ],
            [
              -108.000623,
              37.000001
            ],
            [
              -107.481736999155,
              37.0000045803143
            ],
            [
              -107.482131,
              37.422673
            ],
            [
              -107.482179,
              37.639501
            ],
            [
              -107.743187,
              37.639547
            ],
            [
              -107.758904,
              37.64033
            ],
            [
              -107.970086,
              37.639587
            ],
            [
              -107.973079,
              37.629754
            ],
            [
              -107.989219,
              37.622241
            ],
            [
              -108.000517,
              37.613556
            ],
            [
              -108.004442,
              37.607667
            ],
            [
              -108.011171,
              37.605228
            ],
            [
              -108.015398,
              37.601231
            ],
            [
              -108.014167,
              37.596107
            ],
            [
              -108.01675,
              37.593391
            ],
            [
              -108.020334,
              37.59153
            ],
            [
              -108.020721,
              37.587161
            ],
            [
              -108.020098,
              37.58184
            ],
            [
              -108.020679,
              37.571899
            ],
            [
              -108.019149,
              37.566177
            ],
            [
              -108.02022,
              37.558807
            ],
            [
              -108.021767,
              37.550649
            ],
            [
              -108.024413,
              37.542574
            ],
            [
              -108.032853,
              37.533632
            ],
            [
              -108.034638,
              37.523226
            ],
            [
              -108.03447,
              37.513917
            ],
            [
              -108.034837,
              37.507851
            ],
            [
              -108.03668,
              37.499996
            ],
            [
              -108.035543,
              37.493355
            ],
            [
              -108.033705,
              37.484022
            ],
            [
              -108.033277,
              37.4782
            ],
            [
              -108.036106,
              37.47229
            ],
            [
              -108.03355,
              37.463999
            ],
            [
              -108.038227,
              37.457613
            ],
            [
              -108.039521,
              37.450801
            ],
            [
              -108.043756,
              37.44372
            ],
            [
              -108.049885,
              37.440759
            ],
            [
              -108.056177,
              37.437819
            ],
            [
              -108.065033,
              37.438279
            ],
            [
              -108.07302,
              37.438869
            ],
            [
              -108.07955,
              37.435309
            ],
            [
              -108.081206,
              37.428808
            ],
            [
              -108.087297,
              37.42668
            ],
            [
              -108.092884,
              37.41994
            ],
            [
              -108.098572,
              37.41651
            ],
            [
              -108.103418,
              37.408861
            ],
            [
              -108.105692,
              37.401863
            ],
            [
              -108.107379,
              37.392675
            ],
            [
              -108.106062,
              37.385177
            ],
            [
              -108.112938,
              37.380551
            ],
            [
              -108.120481,
              37.374715
            ],
            [
              -108.139821,
              37.373038
            ],
            [
              -108.149287,
              37.364029
            ],
            [
              -108.160541,
              37.362694
            ],
            [
              -108.174057,
              37.366051
            ],
            [
              -108.187868,
              37.36054
            ],
            [
              -108.197568,
              37.355157
            ],
            [
              -108.204831,
              37.343018
            ],
            [
              -108.207836,
              37.333451
            ],
            [
              -108.204811,
              37.329546
            ],
            [
              -108.205215,
              37.324457
            ],
            [
              -108.212674,
              37.316445
            ],
            [
              -108.216489,
              37.308118
            ],
            [
              -108.213521,
              37.298386
            ],
            [
              -108.219095,
              37.292796
            ],
            [
              -108.22187,
              37.287365
            ],
            [
              -108.226876,
              37.280502
            ],
            [
              -108.234838,
              37.274768
            ],
            [
              -108.237714,
              37.266016
            ],
            [
              -108.242946,
              37.260191
            ],
            [
              -108.247149,
              37.250509
            ],
            [
              -108.250451,
              37.247659
            ],
            [
              -108.257671,
              37.240352
            ],
            [
              -108.264738,
              37.233876
            ],
            [
              -108.276913,
              37.228704
            ],
            [
              -108.285279,
              37.225646
            ],
            [
              -108.293796,
              37.21737
            ],
            [
              -108.295752,
              37.206372
            ],
            [
              -108.29263,
              37.198319
            ],
            [
              -108.295869,
              37.187698
            ],
            [
              -108.296419,
              37.168136
            ],
            [
              -108.29441,
              37.157172
            ],
            [
              -108.292519,
              37.145336
            ],
            [
              -108.309042,
              37.130476
            ],
            [
              -108.31056,
              37.12217
            ],
            [
              -108.311526,
              37.114858
            ],
            [
              -108.310591,
              37.107984
            ],
            [
              -108.315502,
              37.10324
            ],
            [
              -108.318759,
              37.097174
            ],
            [
              -108.322683,
              37.091541
            ],
            [
              -108.323263,
              37.086501
            ],
            [
              -108.324535,
              37.080478
            ],
            [
              -108.328056,
              37.075645
            ],
            [
              -108.328266,
              37.069121
            ],
            [
              -108.333452,
              37.061446
            ],
            [
              -108.334356,
              37.054871
            ],
            [
              -108.344882,
              37.048601
            ],
            [
              -108.352459,
              37.039433
            ],
            [
              -108.353927,
              37.034065
            ],
            [
              -108.35644,
              37.028431
            ],
            [
              -108.359023,
              37.025938
            ],
            [
              -108.36288,
              37.027182
            ],
            [
              -108.367739,
              37.028467
            ],
            [
              -108.372597,
              37.022766
            ],
            [
              -108.375064,
              37.015032
            ],
            [
              -108.377179,
              37.007972
            ],
            [
              -108.379612,
              37.004177
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "053",
      "COUNTYNS": "00198142",
      "AFFGEOID": "0500000US08053",
      "GEOID": "08053",
      "NAME": "Hinsdale",
      "LSAD": "06",
      "ALAND": 2893614470,
      "AWATER": 15324686,
      "County_state": "Hinsdale,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -107.591367,
              37.992252
            ],
            [
              -107.588221,
              37.970312
            ],
            [
              -107.568875,
              37.965015
            ],
            [
              -107.563369,
              37.955937
            ],
            [
              -107.532593,
              37.943905
            ],
            [
              -107.535902,
              37.920123
            ],
            [
              -107.520167,
              37.901312
            ],
            [
              -107.533444,
              37.888077
            ],
            [
              -107.536021,
              37.873914
            ],
            [
              -107.517859,
              37.856978
            ],
            [
              -107.516547,
              37.826451
            ],
            [
              -107.49031,
              37.824275
            ],
            [
              -107.468843,
              37.809726
            ],
            [
              -107.458114,
              37.782096
            ],
            [
              -107.46908,
              37.765482
            ],
            [
              -107.482245,
              37.768353
            ],
            [
              -107.482179,
              37.639501
            ],
            [
              -107.482131,
              37.422673
            ],
            [
              -107.12868,
              37.422942
            ],
            [
              -107.123868,
              37.675029
            ],
            [
              -107.145138,
              37.675497
            ],
            [
              -107.137356,
              37.828788
            ],
            [
              -107.138191,
              37.938848
            ],
            [
              -107.110669,
              37.943344
            ],
            [
              -107.103212,
              37.955053
            ],
            [
              -107.094606,
              37.949307
            ],
            [
              -107.06778,
              37.961299
            ],
            [
              -107.041363,
              37.948681
            ],
            [
              -107.022918,
              37.953792
            ],
            [
              -107.008973,
              37.946522
            ],
            [
              -107.000602,
              37.956041
            ],
            [
              -107.001119,
              38.147072
            ],
            [
              -107.56862,
              38.147267
            ],
            [
              -107.574245,
              38.136009
            ],
            [
              -107.564733,
              38.127906
            ],
            [
              -107.565394,
              38.098868
            ],
            [
              -107.553585,
              38.0792
            ],
            [
              -107.533262,
              38.080059
            ],
            [
              -107.51088,
              38.060877
            ],
            [
              -107.539856,
              38.027109
            ],
            [
              -107.537975,
              38.008919
            ],
            [
              -107.549254,
              37.99848
            ],
            [
              -107.573888,
              38.006379
            ],
            [
              -107.591367,
              37.992252
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "063",
      "COUNTYNS": "00198147",
      "AFFGEOID": "0500000US08063",
      "GEOID": "08063",
      "NAME": "Kit Carson",
      "LSAD": "06",
      "ALAND": 5596501950,
      "AWATER": 2251930,
      "County_state": "Kit Carson,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -103.163072,
              39.127991
            ],
            [
              -103.163025,
              39.03761
            ],
            [
              -102.72411,
              39.040199
            ],
            [
              -102.715766,
              39.038675
            ],
            [
              -102.046571,
              39.047038
            ],
            [
              -102.047134,
              39.129701
            ],
            [
              -102.047188609269,
              39.13314656241629
            ],
            [
              -102.04725,
              39.13702
            ],
            [
              -102.048449,
              39.303138
            ],
            [
              -102.04896,
              39.373712
            ],
            [
              -102.049167,
              39.403597
            ],
            [
              -102.04937,
              39.41821
            ],
            [
              -102.049369,
              39.423333
            ],
            [
              -102.049679,
              39.506183
            ],
            [
              -102.049673,
              39.536691
            ],
            [
              -102.049554,
              39.538932
            ],
            [
              -102.049763830217,
              39.568180000858
            ],
            [
              -102.049806,
              39.574058
            ],
            [
              -102.552028,
              39.570639
            ],
            [
              -102.570862,
              39.569294
            ],
            [
              -102.802932,
              39.567841
            ],
            [
              -103.154376,
              39.565654
            ],
            [
              -103.155253,
              39.127984
            ],
            [
              -103.163072,
              39.127991
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "113",
      "COUNTYNS": "00198172",
      "AFFGEOID": "0500000US08113",
      "GEOID": "08113",
      "NAME": "San Miguel",
      "LSAD": "06",
      "ALAND": 3332606125,
      "AWATER": 5204095,
      "County_state": "San Miguel,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -109.042819,
              37.997068
            ],
            [
              -109.043121,
              37.97426
            ],
            [
              -109.041058,
              37.907236
            ],
            [
              -109.041654683556,
              37.8810851664933
            ],
            [
              -108.820522,
              37.880851
            ],
            [
              -108.81671,
              37.895538
            ],
            [
              -108.256427,
              37.894446
            ],
            [
              -108.247934,
              37.87938
            ],
            [
              -108.237837,
              37.876392
            ],
            [
              -108.227005,
              37.843475
            ],
            [
              -108.212354,
              37.832038
            ],
            [
              -108.209093,
              37.820936
            ],
            [
              -108.174906,
              37.832592
            ],
            [
              -108.141527,
              37.832454
            ],
            [
              -108.112523,
              37.855235
            ],
            [
              -108.090526,
              37.839994
            ],
            [
              -108.066479,
              37.851048
            ],
            [
              -108.058476,
              37.841
            ],
            [
              -108.030865,
              37.855495
            ],
            [
              -107.989208,
              37.85664
            ],
            [
              -107.971393,
              37.832428
            ],
            [
              -107.950763,
              37.835759
            ],
            [
              -107.937646,
              37.822668
            ],
            [
              -107.921347,
              37.823714
            ],
            [
              -107.904847,
              37.807459
            ],
            [
              -107.892508,
              37.802657
            ],
            [
              -107.886257,
              37.785454
            ],
            [
              -107.873187,
              37.786239
            ],
            [
              -107.860845,
              37.775517
            ],
            [
              -107.840852,
              37.774139
            ],
            [
              -107.822201,
              37.795546
            ],
            [
              -107.828521,
              37.811858
            ],
            [
              -107.796447,
              37.822725
            ],
            [
              -107.779099,
              37.849775
            ],
            [
              -107.782459,
              37.867772
            ],
            [
              -107.756047,
              37.88322
            ],
            [
              -107.738283,
              37.905432
            ],
            [
              -107.732788,
              37.929975
            ],
            [
              -107.748897,
              37.953116
            ],
            [
              -107.778675,
              37.960948
            ],
            [
              -107.795469,
              37.98852
            ],
            [
              -107.84908,
              37.992608
            ],
            [
              -107.849131,
              38.008599
            ],
            [
              -107.8833,
              38.007371
            ],
            [
              -107.889716,
              38.011615
            ],
            [
              -107.900193,
              38.026249
            ],
            [
              -107.888208,
              38.045812
            ],
            [
              -107.893774,
              38.057742
            ],
            [
              -107.891469,
              38.115957
            ],
            [
              -107.951574,
              38.116125
            ],
            [
              -107.951717,
              38.144982
            ],
            [
              -107.965789,
              38.152328
            ],
            [
              -108.906749,
              38.154959
            ],
            [
              -108.910366,
              38.15335
            ],
            [
              -109.04183657611101,
              38.153032090353896
            ],
            [
              -109.04282,
              37.999301
            ],
            [
              -109.042819,
              37.997068
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "019",
      "COUNTYNS": "00198125",
      "AFFGEOID": "0500000US08019",
      "GEOID": "08019",
      "NAME": "Clear Creek",
      "LSAD": "06",
      "ALAND": 1023560263,
      "AWATER": 3274894,
      "County_state": "Clear Creek,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -105.924618,
              39.698972
            ],
            [
              -105.92629,
              39.672985
            ],
            [
              -105.910753,
              39.662294
            ],
            [
              -105.884861,
              39.657084
            ],
            [
              -105.864767,
              39.667218
            ],
            [
              -105.848665,
              39.644156
            ],
            [
              -105.821182,
              39.642692
            ],
            [
              -105.816646,
              39.633546
            ],
            [
              -105.793058,
              39.636328
            ],
            [
              -105.776143,
              39.61108
            ],
            [
              -105.782745,
              39.600145
            ],
            [
              -105.808083,
              39.583395
            ],
            [
              -105.838369,
              39.572952
            ],
            [
              -105.829662,
              39.564865
            ],
            [
              -105.776642,
              39.56492
            ],
            [
              -105.587986,
              39.568197
            ],
            [
              -105.398949,
              39.566056
            ],
            [
              -105.397949,
              39.747087
            ],
            [
              -105.421669,
              39.745891
            ],
            [
              -105.421994,
              39.755018
            ],
            [
              -105.496939,
              39.75787
            ],
            [
              -105.545076,
              39.773635
            ],
            [
              -105.54598,
              39.786983
            ],
            [
              -105.57805,
              39.805562
            ],
            [
              -105.578037,
              39.822268
            ],
            [
              -105.601972,
              39.817919
            ],
            [
              -105.627569,
              39.832087
            ],
            [
              -105.633065,
              39.843343
            ],
            [
              -105.660471,
              39.850568
            ],
            [
              -105.690348,
              39.851996
            ],
            [
              -105.696345,
              39.838919
            ],
            [
              -105.713369,
              39.838212
            ],
            [
              -105.736426,
              39.804801
            ],
            [
              -105.765958,
              39.794855
            ],
            [
              -105.799013,
              39.80343
            ],
            [
              -105.822954,
              39.785605
            ],
            [
              -105.8511,
              39.78776
            ],
            [
              -105.854942,
              39.796154
            ],
            [
              -105.887395,
              39.796996
            ],
            [
              -105.892346,
              39.784108
            ],
            [
              -105.880481,
              39.759303
            ],
            [
              -105.883714,
              39.737012
            ],
            [
              -105.90828,
              39.726246
            ],
            [
              -105.924618,
              39.698972
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "061",
      "COUNTYNS": "00198146",
      "AFFGEOID": "0500000US08061",
      "GEOID": "08061",
      "NAME": "Kiowa",
      "LSAD": "06",
      "ALAND": 4578603605,
      "AWATER": 47032919,
      "County_state": "Kiowa,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -103.504666,
              38.516409
            ],
            [
              -103.506985,
              38.342256
            ],
            [
              -103.501764,
              38.265016
            ],
            [
              -103.399938,
              38.265435
            ],
            [
              -102.822906,
              38.266488
            ],
            [
              -102.821677,
              38.2669
            ],
            [
              -102.74233,
              38.266971
            ],
            [
              -102.723824,
              38.26725
            ],
            [
              -102.722139,
              38.267101
            ],
            [
              -102.044567368215,
              38.2687491172007
            ],
            [
              -102.044568,
              38.268819
            ],
            [
              -102.044613,
              38.312324
            ],
            [
              -102.044944,
              38.384419
            ],
            [
              -102.044442,
              38.415802
            ],
            [
              -102.044936,
              38.41968
            ],
            [
              -102.045324,
              38.453647
            ],
            [
              -102.045263,
              38.505395
            ],
            [
              -102.045262,
              38.505532
            ],
            [
              -102.045112,
              38.523784
            ],
            [
              -102.045223,
              38.543797
            ],
            [
              -102.045189,
              38.558732
            ],
            [
              -102.045211,
              38.581609
            ],
            [
              -102.045287815606,
              38.6151684415595
            ],
            [
              -102.058016,
              38.615124
            ],
            [
              -102.068834,
              38.615176
            ],
            [
              -103.062228,
              38.61502
            ],
            [
              -103.172943,
              38.61245
            ],
            [
              -103.172878,
              38.525315
            ],
            [
              -103.504674,
              38.523664
            ],
            [
              -103.504666,
              38.516409
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "093",
      "COUNTYNS": "00198162",
      "AFFGEOID": "0500000US08093",
      "GEOID": "08093",
      "NAME": "Park",
      "LSAD": "06",
      "ALAND": 5682181421,
      "AWATER": 43520913,
      "County_state": "Park,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -106.210173,
              39.103918
            ],
            [
              -106.179218,
              39.103463
            ],
            [
              -106.169663,
              39.086417
            ],
            [
              -106.191246,
              39.068448
            ],
            [
              -106.191006,
              39.056073
            ],
            [
              -106.182501,
              39.038551
            ],
            [
              -106.193083,
              39.01811
            ],
            [
              -106.17947,
              39.011249
            ],
            [
              -106.168534,
              38.991249
            ],
            [
              -106.145414,
              38.997899
            ],
            [
              -106.108953,
              38.987377
            ],
            [
              -106.104379,
              38.974141
            ],
            [
              -106.113757,
              38.955327
            ],
            [
              -106.110995,
              38.940099
            ],
            [
              -106.08532,
              38.93946
            ],
            [
              -106.06591,
              38.931896
            ],
            [
              -106.05825,
              38.941805
            ],
            [
              -106.028138,
              38.942849
            ],
            [
              -105.994449,
              38.914858
            ],
            [
              -105.9861,
              38.918106
            ],
            [
              -105.96577,
              38.905876
            ],
            [
              -105.979418,
              38.894533
            ],
            [
              -105.974297,
              38.882458
            ],
            [
              -105.941238,
              38.885592
            ],
            [
              -105.925123,
              38.872178
            ],
            [
              -105.943174,
              38.852307
            ],
            [
              -105.941625,
              38.840174
            ],
            [
              -105.906587,
              38.806962
            ],
            [
              -105.938758,
              38.763022
            ],
            [
              -105.946185,
              38.716724
            ],
            [
              -105.96975,
              38.693551
            ],
            [
              -105.89839,
              38.693769
            ],
            [
              -105.888912,
              38.695153
            ],
            [
              -105.881574,
              38.694118
            ],
            [
              -105.329134,
              38.697205
            ],
            [
              -105.330444,
              39.129685
            ],
            [
              -105.397875,
              39.129564
            ],
            [
              -105.398154,
              39.40127
            ],
            [
              -105.398949,
              39.566056
            ],
            [
              -105.587986,
              39.568197
            ],
            [
              -105.776642,
              39.56492
            ],
            [
              -105.829662,
              39.564865
            ],
            [
              -105.815807,
              39.551976
            ],
            [
              -105.817631,
              39.53831
            ],
            [
              -105.845377,
              39.52375
            ],
            [
              -105.860506,
              39.530487
            ],
            [
              -105.866889,
              39.516702
            ],
            [
              -105.854117,
              39.50452
            ],
            [
              -105.863584,
              39.502305
            ],
            [
              -105.879087,
              39.483767
            ],
            [
              -105.891974,
              39.484173
            ],
            [
              -105.911637,
              39.456736
            ],
            [
              -105.938663,
              39.458498
            ],
            [
              -105.944155,
              39.44091
            ],
            [
              -105.966786,
              39.438035
            ],
            [
              -105.951698,
              39.414619
            ],
            [
              -105.992856,
              39.401859
            ],
            [
              -106.018383,
              39.381403
            ],
            [
              -106.021966,
              39.361713
            ],
            [
              -106.056096,
              39.358431
            ],
            [
              -106.096791,
              39.377896
            ],
            [
              -106.135529,
              39.379546
            ],
            [
              -106.152943,
              39.362761
            ],
            [
              -106.139926,
              39.339553
            ],
            [
              -106.153573,
              39.320418
            ],
            [
              -106.169474,
              39.322232
            ],
            [
              -106.183972,
              39.309354
            ],
            [
              -106.186759,
              39.279851
            ],
            [
              -106.180482,
              39.261097
            ],
            [
              -106.18512,
              39.242694
            ],
            [
              -106.168304,
              39.23072
            ],
            [
              -106.184744,
              39.209308
            ],
            [
              -106.173847,
              39.137076
            ],
            [
              -106.210173,
              39.103918
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "065",
      "COUNTYNS": "00198149",
      "AFFGEOID": "0500000US08065",
      "GEOID": "08065",
      "NAME": "Lake",
      "LSAD": "06",
      "ALAND": 976215870,
      "AWATER": 18111565,
      "County_state": "Lake,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -106.580311,
              39.102164
            ],
            [
              -106.586338,
              39.087743
            ],
            [
              -106.577966,
              39.057908
            ],
            [
              -106.191006,
              39.056073
            ],
            [
              -106.191246,
              39.068448
            ],
            [
              -106.169663,
              39.086417
            ],
            [
              -106.179218,
              39.103463
            ],
            [
              -106.210173,
              39.103918
            ],
            [
              -106.173847,
              39.137076
            ],
            [
              -106.184744,
              39.209308
            ],
            [
              -106.168304,
              39.23072
            ],
            [
              -106.18512,
              39.242694
            ],
            [
              -106.180482,
              39.261097
            ],
            [
              -106.186759,
              39.279851
            ],
            [
              -106.183972,
              39.309354
            ],
            [
              -106.169474,
              39.322232
            ],
            [
              -106.153573,
              39.320418
            ],
            [
              -106.139926,
              39.339553
            ],
            [
              -106.152943,
              39.362761
            ],
            [
              -106.135529,
              39.379546
            ],
            [
              -106.206732,
              39.379636
            ],
            [
              -106.225627,
              39.37963
            ],
            [
              -106.239517,
              39.365667
            ],
            [
              -106.259456,
              39.361861
            ],
            [
              -106.283812,
              39.349469
            ],
            [
              -106.302557,
              39.361119
            ],
            [
              -106.339252,
              39.359064
            ],
            [
              -106.351742,
              39.353683
            ],
            [
              -106.37082,
              39.360637
            ],
            [
              -106.380633,
              39.37615
            ],
            [
              -106.400634,
              39.380962
            ],
            [
              -106.426487,
              39.361871
            ],
            [
              -106.434931,
              39.341099
            ],
            [
              -106.432085,
              39.316698
            ],
            [
              -106.472878,
              39.308423
            ],
            [
              -106.485121,
              39.312566
            ],
            [
              -106.50238,
              39.298567
            ],
            [
              -106.504031,
              39.284761
            ],
            [
              -106.483292,
              39.272669
            ],
            [
              -106.483127,
              39.255103
            ],
            [
              -106.497649,
              39.242022
            ],
            [
              -106.489749,
              39.227718
            ],
            [
              -106.493649,
              39.198326
            ],
            [
              -106.508614,
              39.166681
            ],
            [
              -106.520942,
              39.157353
            ],
            [
              -106.535138,
              39.163146
            ],
            [
              -106.561265,
              39.155677
            ],
            [
              -106.564574,
              39.132422
            ],
            [
              -106.558171,
              39.120223
            ],
            [
              -106.563989,
              39.106041
            ],
            [
              -106.580311,
              39.102164
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "073",
      "COUNTYNS": "00198152",
      "AFFGEOID": "0500000US08073",
      "GEOID": "08073",
      "NAME": "Lincoln",
      "LSAD": "06",
      "ALAND": 6676158689,
      "AWATER": 22780366,
      "County_state": "Lincoln,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -104.055528,
              38.868868
            ],
            [
              -104.053921,
              38.522393
            ],
            [
              -103.61299,
              38.522548
            ],
            [
              -103.504666,
              38.516409
            ],
            [
              -103.504674,
              38.523664
            ],
            [
              -103.172878,
              38.525315
            ],
            [
              -103.172943,
              38.61245
            ],
            [
              -103.172474,
              38.690057
            ],
            [
              -103.164159,
              38.690133
            ],
            [
              -103.163025,
              39.03761
            ],
            [
              -103.163072,
              39.127991
            ],
            [
              -103.155253,
              39.127984
            ],
            [
              -103.154376,
              39.565654
            ],
            [
              -103.161515,
              39.565996
            ],
            [
              -103.707072,
              39.566295
            ],
            [
              -103.714355,
              39.566331
            ],
            [
              -103.721152,
              39.128307
            ],
            [
              -103.719664,
              38.866827
            ],
            [
              -104.055528,
              38.868868
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "001",
      "COUNTYNS": "00198116",
      "AFFGEOID": "0500000US08001",
      "GEOID": "08001",
      "NAME": "Adams",
      "LSAD": "06",
      "ALAND": 3023220812,
      "AWATER": 41815517,
      "County_state": "Adams,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -105.05327,
              39.820028
            ],
            [
              -105.053246,
              39.791063
            ],
            [
              -105.021339,
              39.793984
            ],
            [
              -105.015832,
              39.791072
            ],
            [
              -104.97595,
              39.791038
            ],
            [
              -104.968716,
              39.798396
            ],
            [
              -104.939882,
              39.790994
            ],
            [
              -104.927002,
              39.783778
            ],
            [
              -104.912887,
              39.783818
            ],
            [
              -104.903561,
              39.798391
            ],
            [
              -104.884627,
              39.798321
            ],
            [
              -104.884585,
              39.812975
            ],
            [
              -104.866075,
              39.812986
            ],
            [
              -104.866041,
              39.798386
            ],
            [
              -104.79091,
              39.79825
            ],
            [
              -104.790859,
              39.841802
            ],
            [
              -104.732138,
              39.845086
            ],
            [
              -104.73155,
              39.898611
            ],
            [
              -104.724103,
              39.898577
            ],
            [
              -104.705568,
              39.89849
            ],
            [
              -104.692934,
              39.914183
            ],
            [
              -104.680213,
              39.906599
            ],
            [
              -104.64037,
              39.906241
            ],
            [
              -104.633969,
              39.899289
            ],
            [
              -104.600435,
              39.899459
            ],
            [
              -104.605791,
              39.873827
            ],
            [
              -104.619841,
              39.873767
            ],
            [
              -104.619938,
              39.822421
            ],
            [
              -104.76247,
              39.82298
            ],
            [
              -104.762606,
              39.798433
            ],
            [
              -104.734399,
              39.798442
            ],
            [
              -104.734601,
              39.769181
            ],
            [
              -104.781727,
              39.773387
            ],
            [
              -104.828229,
              39.773174
            ],
            [
              -104.832961,
              39.766195
            ],
            [
              -104.856523,
              39.768391
            ],
            [
              -104.84694,
              39.754687
            ],
            [
              -104.879901,
              39.754607
            ],
            [
              -104.884646,
              39.740156
            ],
            [
              -104.810022,
              39.740141
            ],
            [
              -104.659304,
              39.74046
            ],
            [
              -104.634448,
              39.739594
            ],
            [
              -104.616197,
              39.740004
            ],
            [
              -104.613603,
              39.739982
            ],
            [
              -104.602222,
              39.739878
            ],
            [
              -104.526958,
              39.738669
            ],
            [
              -104.470927,
              39.738198
            ],
            [
              -103.706547,
              39.739894
            ],
            [
              -103.705701,
              40.001366
            ],
            [
              -104.150332,
              40.000856
            ],
            [
              -104.489757,
              40.000613
            ],
            [
              -104.753728,
              40.000471
            ],
            [
              -104.885967,
              40.000356
            ],
            [
              -104.922153,
              40.00033
            ],
            [
              -104.961405,
              40.000337
            ],
            [
              -104.980959,
              40.000239
            ],
            [
              -104.988159,
              39.968466
            ],
            [
              -105.015845,
              39.981193
            ],
            [
              -105.015469,
              39.921402
            ],
            [
              -105.052892,
              39.914224
            ],
            [
              -105.053263,
              39.859948
            ],
            [
              -105.053249,
              39.854194
            ],
            [
              -105.052847,
              39.853229
            ],
            [
              -105.05327,
              39.820028
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "037",
      "COUNTYNS": "00198134",
      "AFFGEOID": "0500000US08037",
      "GEOID": "08037",
      "NAME": "Eagle",
      "LSAD": "06",
      "ALAND": 4362804330,
      "AWATER": 18920534,
      "County_state": "Eagle,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -107.113672,
              39.919112
            ],
            [
              -107.113446,
              39.366066
            ],
            [
              -106.509214,
              39.362298
            ],
            [
              -106.489362,
              39.361815
            ],
            [
              -106.426487,
              39.361871
            ],
            [
              -106.400634,
              39.380962
            ],
            [
              -106.380633,
              39.37615
            ],
            [
              -106.37082,
              39.360637
            ],
            [
              -106.351742,
              39.353683
            ],
            [
              -106.339252,
              39.359064
            ],
            [
              -106.302557,
              39.361119
            ],
            [
              -106.283812,
              39.349469
            ],
            [
              -106.259456,
              39.361861
            ],
            [
              -106.239517,
              39.365667
            ],
            [
              -106.225627,
              39.37963
            ],
            [
              -106.206732,
              39.379636
            ],
            [
              -106.208386,
              39.381367
            ],
            [
              -106.206782,
              39.387026
            ],
            [
              -106.203041,
              39.389739
            ],
            [
              -106.204737,
              39.398805
            ],
            [
              -106.212753,
              39.405118
            ],
            [
              -106.215548,
              39.406835
            ],
            [
              -106.218508,
              39.411486
            ],
            [
              -106.228664,
              39.414965
            ],
            [
              -106.229986,
              39.420859
            ],
            [
              -106.227599,
              39.428099
            ],
            [
              -106.224897,
              39.432201
            ],
            [
              -106.226828,
              39.438317
            ],
            [
              -106.232798,
              39.442679
            ],
            [
              -106.2387,
              39.450908
            ],
            [
              -106.242401,
              39.458303
            ],
            [
              -106.251528,
              39.467521
            ],
            [
              -106.250294,
              39.477989
            ],
            [
              -106.24732,
              39.486933
            ],
            [
              -106.251784,
              39.492647
            ],
            [
              -106.256579,
              39.493259
            ],
            [
              -106.255642,
              39.499986
            ],
            [
              -106.25108,
              39.502098
            ],
            [
              -106.246435,
              39.512577
            ],
            [
              -106.255065,
              39.525328
            ],
            [
              -106.250328,
              39.539117
            ],
            [
              -106.244324,
              39.543836
            ],
            [
              -106.240106,
              39.54682
            ],
            [
              -106.235619,
              39.546194
            ],
            [
              -106.231096,
              39.540469
            ],
            [
              -106.226909,
              39.534272
            ],
            [
              -106.220795,
              39.531695
            ],
            [
              -106.216111,
              39.531706
            ],
            [
              -106.214355,
              39.531057
            ],
            [
              -106.211122,
              39.532828
            ],
            [
              -106.210742,
              39.536341
            ],
            [
              -106.209926,
              39.546943
            ],
            [
              -106.202014,
              39.549983
            ],
            [
              -106.197648,
              39.555066
            ],
            [
              -106.203766,
              39.557797
            ],
            [
              -106.210084,
              39.568731
            ],
            [
              -106.214017,
              39.577908
            ],
            [
              -106.210777,
              39.587683
            ],
            [
              -106.20977,
              39.59737
            ],
            [
              -106.202105,
              39.605836
            ],
            [
              -106.190323,
              39.603979
            ],
            [
              -106.179698,
              39.607281
            ],
            [
              -106.176443,
              39.621988
            ],
            [
              -106.178208,
              39.63714
            ],
            [
              -106.189205,
              39.653341
            ],
            [
              -106.218957,
              39.657999
            ],
            [
              -106.232485,
              39.662229
            ],
            [
              -106.238827,
              39.675992
            ],
            [
              -106.252649,
              39.679272
            ],
            [
              -106.260688,
              39.68199
            ],
            [
              -106.26462,
              39.687907
            ],
            [
              -106.264055,
              39.694575
            ],
            [
              -106.263628,
              39.699785
            ],
            [
              -106.269026,
              39.702563
            ],
            [
              -106.273626,
              39.702997
            ],
            [
              -106.279565,
              39.700105
            ],
            [
              -106.290916,
              39.704066
            ],
            [
              -106.294182,
              39.713017
            ],
            [
              -106.299377,
              39.720489
            ],
            [
              -106.309002,
              39.724945
            ],
            [
              -106.31967,
              39.730014
            ],
            [
              -106.328472,
              39.737818
            ],
            [
              -106.334806,
              39.747777
            ],
            [
              -106.343338,
              39.755292
            ],
            [
              -106.343428,
              39.75734
            ],
            [
              -106.350744,
              39.756879
            ],
            [
              -106.355534,
              39.75262
            ],
            [
              -106.363652,
              39.752076
            ],
            [
              -106.37212,
              39.758442
            ],
            [
              -106.383487,
              39.766092
            ],
            [
              -106.390316,
              39.788725
            ],
            [
              -106.400904,
              39.802815
            ],
            [
              -106.41223,
              39.826786
            ],
            [
              -106.423019,
              39.841106
            ],
            [
              -106.43252,
              39.864332
            ],
            [
              -106.433745,
              39.876043
            ],
            [
              -106.428683,
              39.895107
            ],
            [
              -106.439202,
              39.919945
            ],
            [
              -106.434508,
              39.924914
            ],
            [
              -106.6185,
              39.925231
            ],
            [
              -106.626569,
              39.924785
            ],
            [
              -106.626555,
              39.918671
            ],
            [
              -106.653132,
              39.919964
            ],
            [
              -107.033968,
              39.918913
            ],
            [
              -107.113672,
              39.919112
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "075",
      "COUNTYNS": "00198153",
      "AFFGEOID": "0500000US08075",
      "GEOID": "08075",
      "NAME": "Logan",
      "LSAD": "06",
      "ALAND": 4761948002,
      "AWATER": 16363553,
      "County_state": "Logan,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -103.580402,
              40.696992
            ],
            [
              -103.581844,
              40.523383
            ],
            [
              -103.4672,
              40.523253
            ],
            [
              -103.465377,
              40.436346
            ],
            [
              -103.40705,
              40.43636
            ],
            [
              -103.387065,
              40.435438
            ],
            [
              -102.77996,
              40.438446
            ],
            [
              -102.664804,
              40.438473
            ],
            [
              -102.664689,
              40.705189
            ],
            [
              -102.65088,
              40.70534
            ],
            [
              -102.65131,
              40.749402
            ],
            [
              -102.653463,
              41.002332
            ],
            [
              -102.739624,
              41.00223
            ],
            [
              -102.754617,
              41.002361
            ],
            [
              -102.766723,
              41.002275
            ],
            [
              -102.773546,
              41.002414
            ],
            [
              -102.82728,
              41.002143
            ],
            [
              -102.830303,
              41.002351
            ],
            [
              -102.846455,
              41.002256
            ],
            [
              -102.849263,
              41.002301
            ],
            [
              -102.865784,
              41.001988
            ],
            [
              -102.867822,
              41.002183
            ],
            [
              -102.885746,
              41.002131
            ],
            [
              -102.887407,
              41.002178
            ],
            [
              -102.904796,
              41.002207
            ],
            [
              -102.906547,
              41.002276
            ],
            [
              -102.924029,
              41.002142
            ],
            [
              -102.925568,
              41.00228
            ],
            [
              -102.943109,
              41.002051
            ],
            [
              -102.94483,
              41.002303
            ],
            [
              -102.959624,
              41.002095
            ],
            [
              -102.960706,
              41.002059
            ],
            [
              -102.962522,
              41.002072
            ],
            [
              -102.963669,
              41.002186
            ],
            [
              -102.981483,
              41.002112
            ],
            [
              -102.98269,
              41.002157
            ],
            [
              -103.000102,
              41.0024
            ],
            [
              -103.002026,
              41.002486
            ],
            [
              -103.038704,
              41.002251
            ],
            [
              -103.043444,
              41.002344
            ],
            [
              -103.057998,
              41.002368
            ],
            [
              -103.059538,
              41.002368
            ],
            [
              -103.076536,
              41.002253
            ],
            [
              -103.077804,
              41.002298
            ],
            [
              -103.362979,
              41.001844
            ],
            [
              -103.365314,
              41.001846
            ],
            [
              -103.382492446889,
              41.00223211782
            ],
            [
              -103.396991,
              41.002558
            ],
            [
              -103.421925,
              41.001969
            ],
            [
              -103.421975,
              41.002007
            ],
            [
              -103.486697,
              41.001914
            ],
            [
              -103.497447,
              41.001635
            ],
            [
              -103.573773995352,
              41.0017201653792
            ],
            [
              -103.574301,
              40.702018
            ],
            [
              -103.580402,
              40.696992
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "115",
      "COUNTYNS": "00198173",
      "AFFGEOID": "0500000US08115",
      "GEOID": "08115",
      "NAME": "Sedgwick",
      "LSAD": "06",
      "ALAND": 1419419128,
      "AWATER": 3530746,
      "County_state": "Sedgwick,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -102.653463,
              41.002332
            ],
            [
              -102.65131,
              40.749402
            ],
            [
              -102.05129201074301,
              40.74958574646119
            ],
            [
              -102.051292,
              40.749591
            ],
            [
              -102.051614,
              41.002377
            ],
            [
              -102.05171752451801,
              41.0023772508495
            ],
            [
              -102.070598,
              41.002423
            ],
            [
              -102.124972,
              41.002338
            ],
            [
              -102.19121,
              41.002326
            ],
            [
              -102.209361,
              41.002442
            ],
            [
              -102.2122,
              41.002462
            ],
            [
              -102.231931,
              41.002327
            ],
            [
              -102.267812,
              41.002383
            ],
            [
              -102.2721,
              41.002245
            ],
            [
              -102.291354,
              41.002207
            ],
            [
              -102.292553,
              41.002207
            ],
            [
              -102.292622,
              41.00223
            ],
            [
              -102.292833,
              41.002207
            ],
            [
              -102.364066,
              41.002174
            ],
            [
              -102.379593,
              41.002301
            ],
            [
              -102.469223,
              41.002424
            ],
            [
              -102.470537,
              41.002382
            ],
            [
              -102.487955,
              41.002445
            ],
            [
              -102.556789,
              41.002219
            ],
            [
              -102.566048,
              41.0022
            ],
            [
              -102.575496,
              41.0022
            ],
            [
              -102.575738,
              41.002268
            ],
            [
              -102.578696,
              41.002291
            ],
            [
              -102.621033,
              41.002597
            ],
            [
              -102.653463,
              41.002332
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "015",
      "COUNTYNS": "00198123",
      "AFFGEOID": "0500000US08015",
      "GEOID": "08015",
      "NAME": "Chaffee",
      "LSAD": "06",
      "ALAND": 2624715691,
      "AWATER": 3999975,
      "County_state": "Chaffee,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -106.599214,
              38.997994
            ],
            [
              -106.597336,
              38.99466
            ],
            [
              -106.589276,
              38.991879
            ],
            [
              -106.580289,
              38.991912
            ],
            [
              -106.560427,
              38.998232
            ],
            [
              -106.54215,
              38.988277
            ],
            [
              -106.522233,
              38.96797
            ],
            [
              -106.521327,
              38.953688
            ],
            [
              -106.505893,
              38.940929
            ],
            [
              -106.489938,
              38.926316
            ],
            [
              -106.470044,
              38.913053
            ],
            [
              -106.444757,
              38.914578
            ],
            [
              -106.433715,
              38.920492
            ],
            [
              -106.417868,
              38.923429
            ],
            [
              -106.41598,
              38.919417
            ],
            [
              -106.407602,
              38.914762
            ],
            [
              -106.392991,
              38.917718
            ],
            [
              -106.376495,
              38.916772
            ],
            [
              -106.361151,
              38.912285
            ],
            [
              -106.344049,
              38.907606
            ],
            [
              -106.32472,
              38.902929
            ],
            [
              -106.326039,
              38.888927
            ],
            [
              -106.336589,
              38.875737
            ],
            [
              -106.338117,
              38.871754
            ],
            [
              -106.345577,
              38.867969
            ],
            [
              -106.349139,
              38.863765
            ],
            [
              -106.349106,
              38.858209
            ],
            [
              -106.351126,
              38.855503
            ],
            [
              -106.358667,
              38.854515
            ],
            [
              -106.362372,
              38.853376
            ],
            [
              -106.36531,
              38.85055
            ],
            [
              -106.365299,
              38.847364
            ],
            [
              -106.367849,
              38.843142
            ],
            [
              -106.370793,
              38.838324
            ],
            [
              -106.375723,
              38.836651
            ],
            [
              -106.379543,
              38.834606
            ],
            [
              -106.383136,
              38.830562
            ],
            [
              -106.383604,
              38.827343
            ],
            [
              -106.386388,
              38.82414
            ],
            [
              -106.390273,
              38.823118
            ],
            [
              -106.397198,
              38.822885
            ],
            [
              -106.404373,
              38.827361
            ],
            [
              -106.406017,
              38.828766
            ],
            [
              -106.410095,
              38.827243
            ],
            [
              -106.413048,
              38.825127
            ],
            [
              -106.41565,
              38.820892
            ],
            [
              -106.415424,
              38.814869
            ],
            [
              -106.420233,
              38.811739
            ],
            [
              -106.422336,
              38.806731
            ],
            [
              -106.418962,
              38.803098
            ],
            [
              -106.417148,
              38.800468
            ],
            [
              -106.414787,
              38.797468
            ],
            [
              -106.413226,
              38.792152
            ],
            [
              -106.41588,
              38.788328
            ],
            [
              -106.414925,
              38.780702
            ],
            [
              -106.414829,
              38.776282
            ],
            [
              -106.415947,
              38.771746
            ],
            [
              -106.418058,
              38.76827
            ],
            [
              -106.417117,
              38.763572
            ],
            [
              -106.415348,
              38.757554
            ],
            [
              -106.417983,
              38.754189
            ],
            [
              -106.418201,
              38.745129
            ],
            [
              -106.419185,
              38.723989
            ],
            [
              -106.434372,
              38.709185
            ],
            [
              -106.444553,
              38.706926
            ],
            [
              -106.451076,
              38.695922
            ],
            [
              -106.44122,
              38.687137
            ],
            [
              -106.429356,
              38.658608
            ],
            [
              -106.407944,
              38.647679
            ],
            [
              -106.387975,
              38.640016
            ],
            [
              -106.384602,
              38.624164
            ],
            [
              -106.371084,
              38.619519
            ],
            [
              -106.359822,
              38.600605
            ],
            [
              -106.353139,
              38.58518
            ],
            [
              -106.347451,
              38.561041
            ],
            [
              -106.354344,
              38.537584
            ],
            [
              -106.347269,
              38.511484
            ],
            [
              -106.338658,
              38.498904
            ],
            [
              -106.335543,
              38.496383
            ],
            [
              -106.332465,
              38.49453
            ],
            [
              -106.328484,
              38.49642
            ],
            [
              -106.321474,
              38.497369
            ],
            [
              -106.317291,
              38.494794
            ],
            [
              -106.310973,
              38.49294
            ],
            [
              -106.307251,
              38.484813
            ],
            [
              -106.301052,
              38.476089
            ],
            [
              -106.290646,
              38.472715
            ],
            [
              -106.289138,
              38.464328
            ],
            [
              -106.284006,
              38.45719
            ],
            [
              -106.275748,
              38.454747
            ],
            [
              -106.263467,
              38.442046
            ],
            [
              -106.253087,
              38.441003
            ],
            [
              -106.248879,
              38.434661
            ],
            [
              -106.24855,
              38.429053
            ],
            [
              -106.24694,
              38.422768
            ],
            [
              -106.125571,
              38.422092
            ],
            [
              -106.076535,
              38.423652
            ],
            [
              -106.057982,
              38.444691
            ],
            [
              -106.058571,
              38.456945
            ],
            [
              -106.042771,
              38.458267
            ],
            [
              -106.019043,
              38.454423
            ],
            [
              -106.010751,
              38.446566
            ],
            [
              -105.93804,
              38.50347
            ],
            [
              -105.908717,
              38.505631
            ],
            [
              -105.90376,
              38.547982
            ],
            [
              -105.885429,
              38.561312
            ],
            [
              -105.89249,
              38.573289
            ],
            [
              -105.878122,
              38.60212
            ],
            [
              -105.887149,
              38.621329
            ],
            [
              -105.915665,
              38.630048
            ],
            [
              -105.907912,
              38.650939
            ],
            [
              -105.925484,
              38.666365
            ],
            [
              -105.926213,
              38.679907
            ],
            [
              -105.945309,
              38.690228
            ],
            [
              -105.96975,
              38.693551
            ],
            [
              -105.946185,
              38.716724
            ],
            [
              -105.938758,
              38.763022
            ],
            [
              -105.906587,
              38.806962
            ],
            [
              -105.941625,
              38.840174
            ],
            [
              -105.943174,
              38.852307
            ],
            [
              -105.925123,
              38.872178
            ],
            [
              -105.941238,
              38.885592
            ],
            [
              -105.974297,
              38.882458
            ],
            [
              -105.979418,
              38.894533
            ],
            [
              -105.96577,
              38.905876
            ],
            [
              -105.9861,
              38.918106
            ],
            [
              -105.994449,
              38.914858
            ],
            [
              -106.028138,
              38.942849
            ],
            [
              -106.05825,
              38.941805
            ],
            [
              -106.06591,
              38.931896
            ],
            [
              -106.08532,
              38.93946
            ],
            [
              -106.110995,
              38.940099
            ],
            [
              -106.113757,
              38.955327
            ],
            [
              -106.104379,
              38.974141
            ],
            [
              -106.108953,
              38.987377
            ],
            [
              -106.145414,
              38.997899
            ],
            [
              -106.168534,
              38.991249
            ],
            [
              -106.17947,
              39.011249
            ],
            [
              -106.193083,
              39.01811
            ],
            [
              -106.182501,
              39.038551
            ],
            [
              -106.191006,
              39.056073
            ],
            [
              -106.577966,
              39.057908
            ],
            [
              -106.597233,
              39.044821
            ],
            [
              -106.591692,
              39.024923
            ],
            [
              -106.599214,
              38.997994
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "043",
      "COUNTYNS": "00198137",
      "AFFGEOID": "0500000US08043",
      "GEOID": "08043",
      "NAME": "Fremont",
      "LSAD": "06",
      "ALAND": 3970664546,
      "AWATER": 2235374,
      "County_state": "Fremont,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -106.010751,
              38.446566
            ],
            [
              -106.012751,
              38.440123
            ],
            [
              -105.996768,
              38.420303
            ],
            [
              -105.98395,
              38.41999
            ],
            [
              -105.947606,
              38.390526
            ],
            [
              -105.944637,
              38.378638
            ],
            [
              -105.919851,
              38.367156
            ],
            [
              -105.888766,
              38.340811
            ],
            [
              -105.876376,
              38.315951
            ],
            [
              -105.858315,
              38.298359
            ],
            [
              -105.84278,
              38.293226
            ],
            [
              -105.818973,
              38.273597
            ],
            [
              -105.796897,
              38.265047
            ],
            [
              -105.07446,
              38.25806
            ],
            [
              -105.049215,
              38.257973
            ],
            [
              -104.940366,
              38.258275
            ],
            [
              -104.940889,
              38.372026
            ],
            [
              -104.941533,
              38.519569
            ],
            [
              -104.942426,
              38.649882
            ],
            [
              -105.053926,
              38.653096
            ],
            [
              -105.071529,
              38.64855
            ],
            [
              -105.220036,
              38.647652
            ],
            [
              -105.240362,
              38.647595
            ],
            [
              -105.237792,
              38.696901
            ],
            [
              -105.329134,
              38.697205
            ],
            [
              -105.881574,
              38.694118
            ],
            [
              -105.888912,
              38.695153
            ],
            [
              -105.89839,
              38.693769
            ],
            [
              -105.96975,
              38.693551
            ],
            [
              -105.945309,
              38.690228
            ],
            [
              -105.926213,
              38.679907
            ],
            [
              -105.925484,
              38.666365
            ],
            [
              -105.907912,
              38.650939
            ],
            [
              -105.915665,
              38.630048
            ],
            [
              -105.887149,
              38.621329
            ],
            [
              -105.878122,
              38.60212
            ],
            [
              -105.89249,
              38.573289
            ],
            [
              -105.885429,
              38.561312
            ],
            [
              -105.90376,
              38.547982
            ],
            [
              -105.908717,
              38.505631
            ],
            [
              -105.93804,
              38.50347
            ],
            [
              -106.010751,
              38.446566
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "077",
      "COUNTYNS": "00198154",
      "AFFGEOID": "0500000US08077",
      "GEOID": "08077",
      "NAME": "Mesa",
      "LSAD": "06",
      "ALAND": 8621911419,
      "AWATER": 31428360,
      "County_state": "Mesa,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -109.060253,
              38.599328
            ],
            [
              -109.059962005949,
              38.4999890310142
            ],
            [
              -108.379136,
              38.499987
            ],
            [
              -108.378665,
              38.508223
            ],
            [
              -108.378698,
              38.668097
            ],
            [
              -108.378953,
              38.829108
            ],
            [
              -108.176988,
              38.895315
            ],
            [
              -108.16631,
              38.902245
            ],
            [
              -108.141122,
              38.906484
            ],
            [
              -108.137741,
              38.926052
            ],
            [
              -108.120793,
              38.927209
            ],
            [
              -108.08686,
              38.967804
            ],
            [
              -108.094786,
              38.979684
            ],
            [
              -108.069963,
              38.996592
            ],
            [
              -108.053779,
              39.014052
            ],
            [
              -108.036119,
              39.01718
            ],
            [
              -108.032022,
              39.027322
            ],
            [
              -107.987204,
              39.052876
            ],
            [
              -107.982173,
              39.059878
            ],
            [
              -107.916692,
              39.063833
            ],
            [
              -107.906811,
              39.060729
            ],
            [
              -107.85824,
              39.079804
            ],
            [
              -107.80213,
              39.064788
            ],
            [
              -107.76549,
              39.043294
            ],
            [
              -107.737988,
              39.06616
            ],
            [
              -107.721624,
              39.065255
            ],
            [
              -107.712581,
              39.080781
            ],
            [
              -107.689556,
              39.087171
            ],
            [
              -107.680815,
              39.100256
            ],
            [
              -107.637893,
              39.14202
            ],
            [
              -107.567423,
              39.181397
            ],
            [
              -107.543364,
              39.181925
            ],
            [
              -107.543455,
              39.194401
            ],
            [
              -107.509835,
              39.216555
            ],
            [
              -107.500606,
              39.217916
            ],
            [
              -107.484393,
              39.235217
            ],
            [
              -107.451075,
              39.230673
            ],
            [
              -107.430261,
              39.240541
            ],
            [
              -107.428073,
              39.257596
            ],
            [
              -107.394486,
              39.256299
            ],
            [
              -107.379175,
              39.269101
            ],
            [
              -107.398513,
              39.29511
            ],
            [
              -107.412606,
              39.291877
            ],
            [
              -107.465187,
              39.314515
            ],
            [
              -107.430514,
              39.348222
            ],
            [
              -107.430949,
              39.366178
            ],
            [
              -108.409607,
              39.365983
            ],
            [
              -109.050765,
              39.366677
            ],
            [
              -109.051512,
              39.126095
            ],
            [
              -109.052436,
              38.999985
            ],
            [
              -109.053292,
              38.942878
            ],
            [
              -109.053233,
              38.942467
            ],
            [
              -109.053797,
              38.905284
            ],
            [
              -109.053943,
              38.904414
            ],
            [
              -109.054189,
              38.874984
            ],
            [
              -109.057388,
              38.795456
            ],
            [
              -109.059541,
              38.719888
            ],
            [
              -109.060253,
              38.599328
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "08",
      "COUNTYFP": "011",
      "COUNTYNS": "00198121",
      "AFFGEOID": "0500000US08011",
      "GEOID": "08011",
      "NAME": "Bent",
      "LSAD": "06",
      "ALAND": 3918303427,
      "AWATER": 73093229,
      "County_state": "Bent,08"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -103.403669,
              37.818431
            ],
            [
              -103.404339,
              37.643576
            ],
            [
              -103.075938,
              37.64342
            ],
            [
              -103.024042,
              37.644363
            ],
            [
              -102.747615,
              37.643642
            ],
            [
              -102.747708,
              37.823451
            ],
            [
              -102.740464,
              37.824064
            ],
            [
              -102.74233,
              38.266971
            ],
            [
              -102.821677,
              38.2669
            ],
            [
              -102.822906,
              38.266488
            ],
            [
              -103.399938,
              38.265435
            ],
            [
              -103.399854,
              37.822812
            ],
            [
              -103.403669,
              37.818431
            ]
          ]
        ]
      ]
    }
  }
]
};