export const counties_ms = {
  "type":"FeatureCollection","name":"ms_counties","features":[
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "017",
      "COUNTYNS": "00695733",
      "AFFGEOID": "0500000US28017",
      "GEOID": "28017",
      "NAME": "Chickasaw",
      "LSAD": "06",
      "ALAND": 1299609263,
      "AWATER": 6380767,
      "County_state": "Chickasaw,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.19004,
              33.81171
            ],
            [
              -89.191781,
              33.738602
            ],
            [
              -89.034498,
              33.73943
            ],
            [
              -89.034775,
              33.754156
            ],
            [
              -88.931604,
              33.754312
            ],
            [
              -88.931176,
              33.812374
            ],
            [
              -88.719979,
              33.81133
            ],
            [
              -88.719529,
              34.053302
            ],
            [
              -88.718964,
              34.074521
            ],
            [
              -88.824523,
              34.074948
            ],
            [
              -89.074738,
              34.074348
            ],
            [
              -89.139158,
              34.074116
            ],
            [
              -89.137876,
              33.812144
            ],
            [
              -89.19004,
              33.81171
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "075",
      "COUNTYNS": "00695761",
      "AFFGEOID": "0500000US28075",
      "GEOID": "28075",
      "NAME": "Lauderdale",
      "LSAD": "06",
      "ALAND": 1822451131,
      "AWATER": 29787695,
      "County_state": "Lauderdale,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.914516,
              32.576955
            ],
            [
              -88.915416,
              32.486401
            ],
            [
              -88.913847,
              32.224186
            ],
            [
              -88.623369,
              32.226373
            ],
            [
              -88.43129401638389,
              32.2276546024792
            ],
            [
              -88.428278,
              32.250143
            ],
            [
              -88.421453,
              32.30868
            ],
            [
              -88.413819,
              32.373922
            ],
            [
              -88.4125,
              32.380025
            ],
            [
              -88.4100961455102,
              32.3992715654218
            ],
            [
              -88.403789,
              32.44977
            ],
            [
              -88.403789,
              32.449885
            ],
            [
              -88.3999703119346,
              32.4853749259647
            ],
            [
              -88.399966,
              32.485415
            ],
            [
              -88.3888572465182,
              32.5781229194103
            ],
            [
              -88.708362,
              32.577555
            ],
            [
              -88.914516,
              32.576955
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "131",
      "COUNTYNS": "00695786",
      "AFFGEOID": "0500000US28131",
      "GEOID": "28131",
      "NAME": "Stone",
      "LSAD": "06",
      "ALAND": 1153800122,
      "AWATER": 6712353,
      "County_state": "Stone,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.341393,
              30.695093
            ],
            [
              -89.341446,
              30.64772
            ],
            [
              -89.273563,
              30.647393
            ],
            [
              -89.2519,
              30.647297
            ],
            [
              -89.246985,
              30.647424
            ],
            [
              -89.240073,
              30.67716
            ],
            [
              -89.134378,
              30.676956
            ],
            [
              -88.985928,
              30.677157
            ],
            [
              -88.884533,
              30.677292
            ],
            [
              -88.884534,
              30.735591
            ],
            [
              -88.885038,
              30.910788
            ],
            [
              -89.137967,
              30.909877
            ],
            [
              -89.171076,
              30.909872
            ],
            [
              -89.198684,
              30.909925
            ],
            [
              -89.340806,
              30.909912
            ],
            [
              -89.340896,
              30.857628
            ],
            [
              -89.341393,
              30.695093
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "013",
      "COUNTYNS": "00695731",
      "AFFGEOID": "0500000US28013",
      "GEOID": "28013",
      "NAME": "Calhoun",
      "LSAD": "06",
      "ALAND": 1519218202,
      "AWATER": 3590395,
      "County_state": "Calhoun,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.509748,
              34.162082
            ],
            [
              -89.50738,
              34.020541
            ],
            [
              -89.507353,
              33.867469
            ],
            [
              -89.507135,
              33.721819
            ],
            [
              -89.39365,
              33.721721
            ],
            [
              -89.191924,
              33.722158
            ],
            [
              -89.191781,
              33.738602
            ],
            [
              -89.19004,
              33.81171
            ],
            [
              -89.137876,
              33.812144
            ],
            [
              -89.139158,
              34.074116
            ],
            [
              -89.244436,
              34.073942
            ],
            [
              -89.245485,
              34.161186
            ],
            [
              -89.509748,
              34.162082
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "099",
      "COUNTYNS": "00695772",
      "AFFGEOID": "0500000US28099",
      "GEOID": "28099",
      "NAME": "Neshoba",
      "LSAD": "06",
      "ALAND": 1476641123,
      "AWATER": 3830971,
      "County_state": "Neshoba,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.319163,
              32.931654
            ],
            [
              -89.317938,
              32.649192
            ],
            [
              -89.317588,
              32.576458
            ],
            [
              -88.976833,
              32.577337
            ],
            [
              -88.966047,
              32.576677
            ],
            [
              -88.914516,
              32.576955
            ],
            [
              -88.914821,
              32.805874
            ],
            [
              -88.914315,
              32.860198
            ],
            [
              -88.9144,
              32.868017
            ],
            [
              -88.914413,
              32.9266
            ],
            [
              -88.920689,
              32.926603
            ],
            [
              -88.930549,
              32.926675
            ],
            [
              -88.930505,
              32.91924
            ],
            [
              -88.96531,
              32.926574
            ],
            [
              -89.017723,
              32.931326
            ],
            [
              -89.168475,
              32.931987
            ],
            [
              -89.319163,
              32.931654
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "113",
      "COUNTYNS": "00711758",
      "AFFGEOID": "0500000US28113",
      "GEOID": "28113",
      "NAME": "Pike",
      "LSAD": "06",
      "ALAND": 1059350502,
      "AWATER": 4341105,
      "County_state": "Pike,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.548199,
              31.349574
            ],
            [
              -90.548387,
              31.223382
            ],
            [
              -90.548108,
              31.203508
            ],
            [
              -90.548063,
              31.159709
            ],
            [
              -90.547806,
              31.058506
            ],
            [
              -90.54761548218009,
              30.9997232645164
            ],
            [
              -90.486749,
              30.999693
            ],
            [
              -90.485876,
              30.99974
            ],
            [
              -90.477284,
              30.999717
            ],
            [
              -90.475928,
              30.99974
            ],
            [
              -90.474094,
              30.999798
            ],
            [
              -90.4566267908343,
              30.9997560101883
            ],
            [
              -90.442479,
              30.999722
            ],
            [
              -90.441725,
              30.999729
            ],
            [
              -90.437351,
              30.99973
            ],
            [
              -90.426849,
              30.999776
            ],
            [
              -90.422117,
              30.99981
            ],
            [
              -90.380536,
              30.999872
            ],
            [
              -90.369371,
              31.000335
            ],
            [
              -90.3472409612406,
              31.0003589881184
            ],
            [
              -90.34723,
              31.000359
            ],
            [
              -90.346007,
              31.000363
            ],
            [
              -90.2595549256981,
              31.0006571633248
            ],
            [
              -90.260234,
              31.147169
            ],
            [
              -90.260557,
              31.175754
            ],
            [
              -90.260692,
              31.262772
            ],
            [
              -90.260692,
              31.271327
            ],
            [
              -90.260391,
              31.350274
            ],
            [
              -90.46966,
              31.34952
            ],
            [
              -90.548199,
              31.349574
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "043",
      "COUNTYNS": "00695746",
      "AFFGEOID": "0500000US28043",
      "GEOID": "28043",
      "NAME": "Grenada",
      "LSAD": "06",
      "ALAND": 1093252402,
      "AWATER": 70698246,
      "County_state": "Grenada,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.136787,
              33.721648
            ],
            [
              -90.137011,
              33.676944
            ],
            [
              -90.127775,
              33.676956
            ],
            [
              -89.878344,
              33.676532
            ],
            [
              -89.78689,
              33.676727
            ],
            [
              -89.734395,
              33.676892
            ],
            [
              -89.506872,
              33.677605
            ],
            [
              -89.507135,
              33.721819
            ],
            [
              -89.507353,
              33.867469
            ],
            [
              -89.717329,
              33.867142
            ],
            [
              -89.76905,
              33.867334
            ],
            [
              -89.769296,
              33.897137
            ],
            [
              -89.926099,
              33.897957
            ],
            [
              -89.926294,
              33.823854
            ],
            [
              -89.978268,
              33.82396
            ],
            [
              -89.978367,
              33.809719
            ],
            [
              -89.995939,
              33.809711
            ],
            [
              -90.136491,
              33.809319
            ],
            [
              -90.136787,
              33.721648
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "005",
      "COUNTYNS": "00695727",
      "AFFGEOID": "0500000US28005",
      "GEOID": "28005",
      "NAME": "Amite",
      "LSAD": "06",
      "ALAND": 1890950867,
      "AWATER": 3877823,
      "County_state": "Amite,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.095398,
              31.320975
            ],
            [
              -91.079847,
              31.288774
            ],
            [
              -91.059469,
              31.281921
            ],
            [
              -91.059355,
              31.245723
            ],
            [
              -91.0602129522528,
              30.9989529636044
            ],
            [
              -91.0421723672845,
              30.999026772612797
            ],
            [
              -90.89473,
              30.99963
            ],
            [
              -90.826027,
              30.99936
            ],
            [
              -90.8258293654941,
              30.9993604066554
            ],
            [
              -90.783745,
              30.999447
            ],
            [
              -90.779858,
              30.999457
            ],
            [
              -90.775981,
              30.999491
            ],
            [
              -90.769333,
              30.999374
            ],
            [
              -90.758775,
              30.999583
            ],
            [
              -90.734552,
              30.999222
            ],
            [
              -90.734473,
              30.999214
            ],
            [
              -90.651193,
              30.99951
            ],
            [
              -90.648721,
              30.999486
            ],
            [
              -90.588676,
              30.99965
            ],
            [
              -90.587373,
              30.999604
            ],
            [
              -90.584448,
              30.999698
            ],
            [
              -90.583518,
              30.999698
            ],
            [
              -90.567195,
              30.999733
            ],
            [
              -90.54761548218009,
              30.9997232645164
            ],
            [
              -90.547806,
              31.058506
            ],
            [
              -90.548063,
              31.159709
            ],
            [
              -90.548108,
              31.203508
            ],
            [
              -90.548387,
              31.223382
            ],
            [
              -90.548199,
              31.349574
            ],
            [
              -90.633302,
              31.349306
            ],
            [
              -90.770824,
              31.349583
            ],
            [
              -90.983002,
              31.348671
            ],
            [
              -90.988365,
              31.336363
            ],
            [
              -91.020871,
              31.345309
            ],
            [
              -91.025427,
              31.337373
            ],
            [
              -91.095398,
              31.320975
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "105",
      "COUNTYNS": "00695775",
      "AFFGEOID": "0500000US28105",
      "GEOID": "28105",
      "NAME": "Oktibbeha",
      "LSAD": "06",
      "ALAND": 1186750731,
      "AWATER": 9649656,
      "County_state": "Oktibbeha,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.088438,
              33.532518
            ],
            [
              -89.088652,
              33.445821
            ],
            [
              -89.088255,
              33.286003
            ],
            [
              -89.04366,
              33.286153
            ],
            [
              -88.965963,
              33.286108
            ],
            [
              -88.901438,
              33.286323
            ],
            [
              -88.900283,
              33.286356
            ],
            [
              -88.813053,
              33.286424
            ],
            [
              -88.669076,
              33.286922
            ],
            [
              -88.670378,
              33.404071
            ],
            [
              -88.671051,
              33.462739
            ],
            [
              -88.67125,
              33.506168
            ],
            [
              -88.671526,
              33.565135
            ],
            [
              -88.691996,
              33.565229
            ],
            [
              -88.70712,
              33.559177
            ],
            [
              -88.735481,
              33.565219
            ],
            [
              -88.770596,
              33.565212
            ],
            [
              -88.949824,
              33.564343
            ],
            [
              -89.018613,
              33.562114
            ],
            [
              -89.035677,
              33.562154
            ],
            [
              -89.088263,
              33.562039
            ],
            [
              -89.088446,
              33.533202
            ],
            [
              -89.088438,
              33.532518
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "065",
      "COUNTYNS": "00695756",
      "AFFGEOID": "0500000US28065",
      "GEOID": "28065",
      "NAME": "Jefferson Davis",
      "LSAD": "06",
      "ALAND": 1057871313,
      "AWATER": 1791496,
      "County_state": "Jefferson Davis,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.974586,
              31.761655
            ],
            [
              -89.976052,
              31.522477
            ],
            [
              -89.959097,
              31.522421
            ],
            [
              -89.959,
              31.482246
            ],
            [
              -89.958951,
              31.440788
            ],
            [
              -89.95894,
              31.390489
            ],
            [
              -89.823332,
              31.390021
            ],
            [
              -89.824236,
              31.434093
            ],
            [
              -89.670969,
              31.433838
            ],
            [
              -89.654212,
              31.433805
            ],
            [
              -89.586819,
              31.433688
            ],
            [
              -89.586919,
              31.513444
            ],
            [
              -89.597372,
              31.515136
            ],
            [
              -89.599331,
              31.534647
            ],
            [
              -89.639461,
              31.559492
            ],
            [
              -89.672328,
              31.564492
            ],
            [
              -89.717027,
              31.58892
            ],
            [
              -89.730805,
              31.607081
            ],
            [
              -89.755614,
              31.609751
            ],
            [
              -89.75557,
              31.641995
            ],
            [
              -89.755217,
              31.728324
            ],
            [
              -89.755103,
              31.774654
            ],
            [
              -89.852401,
              31.76861
            ],
            [
              -89.974586,
              31.761655
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "079",
      "COUNTYNS": "00695763",
      "AFFGEOID": "0500000US28079",
      "GEOID": "28079",
      "NAME": "Leake",
      "LSAD": "06",
      "ALAND": 1509662463,
      "AWATER": 6411187,
      "County_state": "Leake,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.730304,
              32.634228
            ],
            [
              -89.730152,
              32.577248
            ],
            [
              -89.472093,
              32.576405
            ],
            [
              -89.368438,
              32.576322
            ],
            [
              -89.322598,
              32.576433
            ],
            [
              -89.317588,
              32.576458
            ],
            [
              -89.317938,
              32.649192
            ],
            [
              -89.319163,
              32.931654
            ],
            [
              -89.559562,
              32.930749
            ],
            [
              -89.600814,
              32.930518
            ],
            [
              -89.62289,
              32.930146
            ],
            [
              -89.730154,
              32.929924
            ],
            [
              -89.730016,
              32.885072
            ],
            [
              -89.730304,
              32.634228
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "123",
      "COUNTYNS": "00695782",
      "AFFGEOID": "0500000US28123",
      "GEOID": "28123",
      "NAME": "Scott",
      "LSAD": "06",
      "ALAND": 1577902077,
      "AWATER": 3001522,
      "County_state": "Scott,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.770913,
              32.60615
            ],
            [
              -89.784123,
              32.586891
            ],
            [
              -89.764404,
              32.503613
            ],
            [
              -89.730063,
              32.352733
            ],
            [
              -89.730038,
              32.282215
            ],
            [
              -89.730424,
              32.222039
            ],
            [
              -89.701621,
              32.223757
            ],
            [
              -89.322692,
              32.223472
            ],
            [
              -89.32265,
              32.282101
            ],
            [
              -89.322598,
              32.576433
            ],
            [
              -89.368438,
              32.576322
            ],
            [
              -89.472093,
              32.576405
            ],
            [
              -89.730152,
              32.577248
            ],
            [
              -89.730304,
              32.634228
            ],
            [
              -89.770913,
              32.60615
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "097",
      "COUNTYNS": "00711757",
      "AFFGEOID": "0500000US28097",
      "GEOID": "28097",
      "NAME": "Montgomery",
      "LSAD": "06",
      "ALAND": 1054074717,
      "AWATER": 2352280,
      "County_state": "Montgomery,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.78689,
              33.676727
            ],
            [
              -89.785227,
              33.416275
            ],
            [
              -89.715847,
              33.416766
            ],
            [
              -89.715631,
              33.402066
            ],
            [
              -89.69918,
              33.402179
            ],
            [
              -89.646314,
              33.402544
            ],
            [
              -89.646085,
              33.366391
            ],
            [
              -89.64528,
              33.285515
            ],
            [
              -89.453808,
              33.285942
            ],
            [
              -89.45405,
              33.4332
            ],
            [
              -89.446326,
              33.439837
            ],
            [
              -89.422202,
              33.429501
            ],
            [
              -89.402272,
              33.452769
            ],
            [
              -89.381092,
              33.460307
            ],
            [
              -89.417705,
              33.460107
            ],
            [
              -89.506496,
              33.459806
            ],
            [
              -89.506616,
              33.576289
            ],
            [
              -89.506872,
              33.677605
            ],
            [
              -89.734395,
              33.676892
            ],
            [
              -89.78689,
              33.676727
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "015",
      "COUNTYNS": "00695732",
      "AFFGEOID": "0500000US28015",
      "GEOID": "28015",
      "NAME": "Carroll",
      "LSAD": "06",
      "ALAND": 1627140833,
      "AWATER": 16426880,
      "County_state": "Carroll,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.174756,
              33.417003
            ],
            [
              -90.174864,
              33.374187
            ],
            [
              -90.174614,
              33.333378
            ],
            [
              -89.92704,
              33.263535
            ],
            [
              -89.914941,
              33.260125
            ],
            [
              -89.889573,
              33.252977
            ],
            [
              -89.749441,
              33.214478
            ],
            [
              -89.721182,
              33.260137
            ],
            [
              -89.701514,
              33.285359
            ],
            [
              -89.64528,
              33.285515
            ],
            [
              -89.646085,
              33.366391
            ],
            [
              -89.646314,
              33.402544
            ],
            [
              -89.69918,
              33.402179
            ],
            [
              -89.715631,
              33.402066
            ],
            [
              -89.715847,
              33.416766
            ],
            [
              -89.785227,
              33.416275
            ],
            [
              -89.78689,
              33.676727
            ],
            [
              -89.878344,
              33.676532
            ],
            [
              -90.127775,
              33.676956
            ],
            [
              -90.102971,
              33.66073
            ],
            [
              -90.107927,
              33.606311
            ],
            [
              -90.130254,
              33.603875
            ],
            [
              -90.101987,
              33.603792
            ],
            [
              -90.101401,
              33.45894
            ],
            [
              -90.13761,
              33.459114
            ],
            [
              -90.137817,
              33.416455
            ],
            [
              -90.174756,
              33.417003
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "159",
      "COUNTYNS": "00695800",
      "AFFGEOID": "0500000US28159",
      "GEOID": "28159",
      "NAME": "Winston",
      "LSAD": "06",
      "ALAND": 1572709946,
      "AWATER": 7405132,
      "County_state": "Winston,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.318223,
              33.065452
            ],
            [
              -89.319163,
              32.931654
            ],
            [
              -89.168475,
              32.931987
            ],
            [
              -89.017723,
              32.931326
            ],
            [
              -88.96531,
              32.926574
            ],
            [
              -88.930505,
              32.91924
            ],
            [
              -88.930549,
              32.926675
            ],
            [
              -88.920689,
              32.926603
            ],
            [
              -88.914413,
              32.9266
            ],
            [
              -88.812782,
              32.925973
            ],
            [
              -88.812796,
              32.927384
            ],
            [
              -88.813555,
              33.034873
            ],
            [
              -88.813722,
              33.089209
            ],
            [
              -88.813172,
              33.173511
            ],
            [
              -88.813053,
              33.286424
            ],
            [
              -88.900283,
              33.286356
            ],
            [
              -88.901438,
              33.286323
            ],
            [
              -88.965963,
              33.286108
            ],
            [
              -89.04366,
              33.286153
            ],
            [
              -89.088255,
              33.286003
            ],
            [
              -89.108886,
              33.285937
            ],
            [
              -89.1089,
              33.198059
            ],
            [
              -89.128902,
              33.197715
            ],
            [
              -89.213422,
              33.197543
            ],
            [
              -89.213675,
              33.153303
            ],
            [
              -89.213921,
              33.109402
            ],
            [
              -89.317855,
              33.108871
            ],
            [
              -89.318223,
              33.065452
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "139",
      "COUNTYNS": "00695790",
      "AFFGEOID": "0500000US28139",
      "GEOID": "28139",
      "NAME": "Tippah",
      "LSAD": "06",
      "ALAND": 1185725576,
      "AWATER": 5551259,
      "County_state": "Tippah,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.088065,
              34.815307
            ],
            [
              -89.088488,
              34.598247
            ],
            [
              -88.758182,
              34.596536
            ],
            [
              -88.73544,
              34.596483
            ],
            [
              -88.717794,
              34.596472
            ],
            [
              -88.718508,
              34.756781
            ],
            [
              -88.753065,
              34.75696
            ],
            [
              -88.752956,
              34.858775
            ],
            [
              -88.823473,
              34.858629
            ],
            [
              -88.823305,
              34.950334
            ],
            [
              -88.8230487339323,
              34.9951575501037
            ],
            [
              -88.851037,
              34.995085
            ],
            [
              -88.886979,
              34.995868
            ],
            [
              -88.925241,
              34.994842
            ],
            [
              -89.0171270872889,
              34.9949454068841
            ],
            [
              -89.017551,
              34.858723
            ],
            [
              -89.035009,
              34.858762
            ],
            [
              -89.03534,
              34.81526
            ],
            [
              -89.088065,
              34.815307
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "119",
      "COUNTYNS": "00695780",
      "AFFGEOID": "0500000US28119",
      "GEOID": "28119",
      "NAME": "Quitman",
      "LSAD": "06",
      "ALAND": 1048975544,
      "AWATER": 3676001,
      "County_state": "Quitman,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.450703,
              34.153676
            ],
            [
              -90.450981,
              34.072932
            ],
            [
              -90.34218,
              34.072711
            ],
            [
              -90.289471,
              34.072834
            ],
            [
              -90.135695,
              34.0729
            ],
            [
              -90.135456,
              34.160074
            ],
            [
              -90.134762,
              34.226029
            ],
            [
              -90.134974,
              34.266212
            ],
            [
              -90.194086,
              34.301407
            ],
            [
              -90.193414,
              34.340274
            ],
            [
              -90.198536,
              34.511088
            ],
            [
              -90.304317,
              34.509621
            ],
            [
              -90.301851,
              34.424546
            ],
            [
              -90.399498,
              34.423807
            ],
            [
              -90.397526,
              34.422609
            ],
            [
              -90.399822,
              34.334878
            ],
            [
              -90.399014,
              34.261524
            ],
            [
              -90.450572,
              34.262402
            ],
            [
              -90.450792,
              34.223317
            ],
            [
              -90.450703,
              34.153676
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "011",
      "COUNTYNS": "00695730",
      "AFFGEOID": "0500000US28011",
      "GEOID": "28011",
      "NAME": "Bolivar",
      "LSAD": "06",
      "ALAND": 2270541554,
      "AWATER": 75515374,
      "County_state": "Bolivar,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.232537,
              33.557454
            ],
            [
              -91.232295,
              33.552788
            ],
            [
              -91.229834,
              33.547047
            ],
            [
              -91.226325,
              33.5412
            ],
            [
              -91.219297,
              33.532364
            ],
            [
              -91.215671,
              33.529423
            ],
            [
              -90.971457,
              33.530276
            ],
            [
              -90.865885,
              33.529387
            ],
            [
              -90.822955,
              33.52988
            ],
            [
              -90.763509,
              33.529389
            ],
            [
              -90.763514,
              33.55775
            ],
            [
              -90.763403,
              33.599758
            ],
            [
              -90.763469,
              33.616164
            ],
            [
              -90.658719,
              33.616264
            ],
            [
              -90.658579,
              33.689118
            ],
            [
              -90.658791,
              33.70405
            ],
            [
              -90.658627,
              33.784193
            ],
            [
              -90.658224,
              33.852949
            ],
            [
              -90.657002,
              33.987586
            ],
            [
              -90.660357,
              33.987585
            ],
            [
              -90.659457,
              34.118867
            ],
            [
              -90.799346,
              34.120614
            ],
            [
              -90.955974,
              34.120125
            ],
            [
              -90.95500367987549,
              34.1189731025439
            ],
            [
              -90.948514,
              34.111269
            ],
            [
              -90.946323,
              34.109374
            ],
            [
              -90.921273,
              34.093958
            ],
            [
              -90.918395,
              34.093054
            ],
            [
              -90.914066,
              34.092756
            ],
            [
              -90.90113,
              34.094667
            ],
            [
              -90.893526,
              34.097795
            ],
            [
              -90.888085,
              34.09781
            ],
            [
              -90.882628,
              34.096615
            ],
            [
              -90.878912,
              34.094573
            ],
            [
              -90.876606,
              34.092911
            ],
            [
              -90.871923,
              34.086652
            ],
            [
              -90.870461,
              34.082739
            ],
            [
              -90.870528,
              34.080516
            ],
            [
              -90.87194,
              34.076362
            ],
            [
              -90.874541,
              34.072041
            ],
            [
              -90.882115,
              34.063903
            ],
            [
              -90.886351,
              34.058564
            ],
            [
              -90.887837,
              34.055403
            ],
            [
              -90.888627,
              34.052419
            ],
            [
              -90.889058,
              34.046362
            ],
            [
              -90.887394,
              34.039845
            ],
            [
              -90.886991,
              34.035094
            ],
            [
              -90.887413,
              34.032505
            ],
            [
              -90.888956,
              34.029788
            ],
            [
              -90.89242,
              34.02686
            ],
            [
              -90.899467,
              34.023796
            ],
            [
              -90.914642,
              34.021885
            ],
            [
              -90.922017,
              34.023216
            ],
            [
              -90.923745,
              34.023143
            ],
            [
              -90.934896,
              34.019262
            ],
            [
              -90.942662,
              34.01805
            ],
            [
              -90.950311,
              34.017822
            ],
            [
              -90.958456,
              34.020254
            ],
            [
              -90.970726,
              34.02162
            ],
            [
              -90.976918,
              34.021335
            ],
            [
              -90.982742,
              34.020469
            ],
            [
              -90.987948,
              34.019038
            ],
            [
              -90.979945,
              34.000106
            ],
            [
              -90.977489,
              33.996554
            ],
            [
              -90.970947,
              33.991885
            ],
            [
              -90.961548,
              33.979945
            ],
            [
              -90.961222,
              33.976151
            ],
            [
              -90.96372,
              33.967688
            ],
            [
              -90.965187,
              33.965461
            ],
            [
              -90.967632,
              33.963324
            ],
            [
              -90.970856,
              33.961868
            ],
            [
              -90.976864,
              33.960503
            ],
            [
              -90.983359,
              33.960186
            ],
            [
              -90.987653,
              33.960793
            ],
            [
              -90.994856,
              33.963118
            ],
            [
              -91.000108,
              33.966428
            ],
            [
              -91.002986,
              33.970902
            ],
            [
              -91.004981,
              33.977011
            ],
            [
              -91.01361,
              33.994495
            ],
            [
              -91.01889,
              34.003151
            ],
            [
              -91.033765,
              33.995323
            ],
            [
              -91.039589,
              33.989297
            ],
            [
              -91.042751,
              33.986811
            ],
            [
              -91.048367,
              33.985078
            ],
            [
              -91.062264,
              33.985083
            ],
            [
              -91.071203,
              33.984473
            ],
            [
              -91.075378,
              33.983586
            ],
            [
              -91.079254,
              33.982101
            ],
            [
              -91.083187,
              33.979865
            ],
            [
              -91.087921,
              33.975335
            ],
            [
              -91.089119,
              33.972653
            ],
            [
              -91.089756,
              33.969721
            ],
            [
              -91.089787,
              33.966004
            ],
            [
              -91.088696,
              33.961334
            ],
            [
              -91.086758,
              33.95827
            ],
            [
              -91.084095,
              33.956179
            ],
            [
              -91.0790048351099,
              33.954252573959295
            ],
            [
              -91.078496,
              33.95406
            ],
            [
              -91.046725,
              33.947406
            ],
            [
              -91.035961,
              33.943758
            ],
            [
              -91.020097,
              33.937127
            ],
            [
              -91.012994,
              33.932276
            ],
            [
              -91.010318,
              33.929352
            ],
            [
              -91.01004,
              33.927003
            ],
            [
              -91.010831,
              33.925552
            ],
            [
              -91.017481,
              33.919083
            ],
            [
              -91.026382,
              33.90798
            ],
            [
              -91.036674,
              33.898531
            ],
            [
              -91.055309,
              33.883035
            ],
            [
              -91.061247,
              33.877505
            ],
            [
              -91.070883,
              33.866714
            ],
            [
              -91.072798,
              33.862396
            ],
            [
              -91.073011,
              33.857449
            ],
            [
              -91.071195,
              33.849539
            ],
            [
              -91.067511,
              33.840443
            ],
            [
              -91.064977,
              33.837126
            ],
            [
              -91.056692,
              33.828935
            ],
            [
              -91.052819,
              33.824181
            ],
            [
              -91.049679,
              33.818729
            ],
            [
              -91.046849,
              33.815365
            ],
            [
              -91.042448,
              33.812855
            ],
            [
              -91.025173,
              33.805953
            ],
            [
              -91.020098,
              33.804447
            ],
            [
              -91.007767,
              33.802591
            ],
            [
              -91.000107,
              33.799549
            ],
            [
              -90.991747,
              33.792597
            ],
            [
              -90.989299,
              33.788016
            ],
            [
              -90.988466,
              33.78453
            ],
            [
              -90.989444,
              33.780576
            ],
            [
              -90.99122,
              33.776791
            ],
            [
              -90.993842,
              33.773601
            ],
            [
              -91.000106,
              33.769165
            ],
            [
              -91.01277,
              33.764675
            ],
            [
              -91.023285,
              33.762991
            ],
            [
              -91.026782,
              33.763642
            ],
            [
              -91.053886,
              33.778701
            ],
            [
              -91.060524,
              33.77764
            ],
            [
              -91.08551,
              33.77641
            ],
            [
              -91.088996,
              33.775801
            ],
            [
              -91.107318,
              33.770619
            ],
            [
              -91.111494,
              33.774568
            ],
            [
              -91.117836,
              33.779026
            ],
            [
              -91.123466,
              33.782106
            ],
            [
              -91.128222,
              33.783447
            ],
            [
              -91.132185,
              33.78342
            ],
            [
              -91.133854,
              33.782814
            ],
            [
              -91.137351,
              33.779923
            ],
            [
              -91.139869,
              33.777117
            ],
            [
              -91.14201,
              33.77382
            ],
            [
              -91.145112,
              33.76734
            ],
            [
              -91.144812,
              33.763996
            ],
            [
              -91.143634,
              33.762095
            ],
            [
              -91.141304,
              33.760835
            ],
            [
              -91.140756,
              33.759735
            ],
            [
              -91.141553,
              33.755957
            ],
            [
              -91.144571,
              33.751607
            ],
            [
              -91.144539,
              33.749338
            ],
            [
              -91.143287,
              33.747141
            ],
            [
              -91.146523,
              33.736503
            ],
            [
              -91.146618,
              33.732456
            ],
            [
              -91.145792,
              33.728924
            ],
            [
              -91.144732,
              33.726803
            ],
            [
              -91.132338,
              33.714246
            ],
            [
              -91.125527,
              33.70878
            ],
            [
              -91.117733,
              33.705342
            ],
            [
              -91.107646,
              33.704679
            ],
            [
              -91.101101,
              33.705007
            ],
            [
              -91.089873,
              33.707364
            ],
            [
              -91.075389,
              33.714403
            ],
            [
              -91.06829,
              33.716477
            ],
            [
              -91.063752,
              33.715892
            ],
            [
              -91.059891,
              33.714816
            ],
            [
              -91.055562,
              33.712486
            ],
            [
              -91.046778,
              33.706313
            ],
            [
              -91.041261,
              33.699933
            ],
            [
              -91.039025,
              33.696595
            ],
            [
              -91.03715,
              33.692907
            ],
            [
              -91.03612,
              33.689113
            ],
            [
              -91.033366,
              33.688773
            ],
            [
              -91.030402,
              33.687766
            ],
            [
              -91.030332,
              33.681622
            ],
            [
              -91.03146,
              33.678142
            ],
            [
              -91.034565,
              33.673018
            ],
            [
              -91.03684,
              33.671316
            ],
            [
              -91.046412,
              33.668272
            ],
            [
              -91.050523,
              33.668064
            ],
            [
              -91.059182,
              33.6664
            ],
            [
              -91.06711,
              33.662689
            ],
            [
              -91.078507,
              33.658283
            ],
            [
              -91.084126,
              33.657322
            ],
            [
              -91.088202,
              33.657387
            ],
            [
              -91.09404,
              33.658351
            ],
            [
              -91.10098,
              33.660551
            ],
            [
              -91.13045,
              33.674522
            ],
            [
              -91.133416,
              33.67679
            ],
            [
              -91.144188,
              33.689596
            ],
            [
              -91.160866,
              33.707096
            ],
            [
              -91.162464,
              33.70684
            ],
            [
              -91.165846,
              33.705133
            ],
            [
              -91.17573,
              33.703116
            ],
            [
              -91.190113,
              33.70186
            ],
            [
              -91.200712,
              33.701535
            ],
            [
              -91.205377,
              33.700819
            ],
            [
              -91.212077,
              33.698249
            ],
            [
              -91.22057,
              33.692923
            ],
            [
              -91.225279,
              33.687749
            ],
            [
              -91.227857,
              33.683073
            ],
            [
              -91.229015,
              33.677543
            ],
            [
              -91.228228,
              33.671326
            ],
            [
              -91.226537,
              33.668665
            ],
            [
              -91.223001,
              33.664794
            ],
            [
              -91.219048,
              33.661503
            ],
            [
              -91.211284,
              33.658526
            ],
            [
              -91.185455,
              33.653604
            ],
            [
              -91.178311,
              33.651109
            ],
            [
              -91.171168,
              33.647766
            ],
            [
              -91.164212,
              33.643278
            ],
            [
              -91.150499,
              33.633013
            ],
            [
              -91.139209,
              33.625658
            ],
            [
              -91.134389,
              33.619512
            ],
            [
              -91.1329605860014,
              33.6159919651592
            ],
            [
              -91.130902,
              33.610919
            ],
            [
              -91.130445,
              33.606034
            ],
            [
              -91.131588,
              33.59945
            ],
            [
              -91.13245,
              33.596989
            ],
            [
              -91.134043,
              33.594489
            ],
            [
              -91.138418,
              33.590896
            ],
            [
              -91.152148,
              33.582721
            ],
            [
              -91.157429,
              33.581372
            ],
            [
              -91.160755,
              33.581352
            ],
            [
              -91.175979,
              33.582968
            ],
            [
              -91.17822,
              33.582607
            ],
            [
              -91.181068,
              33.58152
            ],
            [
              -91.188942,
              33.576225
            ],
            [
              -91.198285,
              33.572061
            ],
            [
              -91.203151,
              33.570758
            ],
            [
              -91.221466,
              33.568166
            ],
            [
              -91.224121,
              33.567369
            ],
            [
              -91.228489,
              33.564667
            ],
            [
              -91.2308579778816,
              33.56137194507
            ],
            [
              -91.231418,
              33.560593
            ],
            [
              -91.232537,
              33.557454
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "149",
      "COUNTYNS": "00695795",
      "AFFGEOID": "0500000US28149",
      "GEOID": "28149",
      "NAME": "Warren",
      "LSAD": "06",
      "ALAND": 1524245417,
      "AWATER": 77800995,
      "County_state": "Warren,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.173664,
              32.164231
            ],
            [
              -91.174552,
              32.154978
            ],
            [
              -91.173495,
              32.149009
            ],
            [
              -91.171702,
              32.14425
            ],
            [
              -91.165452,
              32.13429
            ],
            [
              -91.162822,
              32.132694
            ],
            [
              -91.155043,
              32.132243
            ],
            [
              -91.1444,
              32.13039
            ],
            [
              -91.136566,
              32.127371
            ],
            [
              -91.131403,
              32.126213
            ],
            [
              -91.113866,
              32.125731
            ],
            [
              -91.111294,
              32.125036
            ],
            [
              -91.103696,
              32.130007
            ],
            [
              -91.101181,
              32.131136
            ],
            [
              -91.091656,
              32.133604
            ],
            [
              -91.08163,
              32.133992
            ],
            [
              -91.077043,
              32.133493
            ],
            [
              -91.069081,
              32.131478
            ],
            [
              -91.061555,
              32.126907
            ],
            [
              -91.053175,
              32.124237
            ],
            [
              -91.051207,
              32.144152
            ],
            [
              -91.050207,
              32.145252
            ],
            [
              -91.048507,
              32.150152
            ],
            [
              -91.049707,
              32.157352
            ],
            [
              -91.055707,
              32.163752
            ],
            [
              -91.058907,
              32.171251
            ],
            [
              -91.057647,
              32.177354
            ],
            [
              -91.050207,
              32.178451
            ],
            [
              -91.041807,
              32.174551
            ],
            [
              -91.031907,
              32.167851
            ],
            [
              -91.025007,
              32.162552
            ],
            [
              -91.016606,
              32.160852
            ],
            [
              -91.00619,
              32.156957
            ],
            [
              -91.004106,
              32.146152
            ],
            [
              -91.005006,
              32.142852
            ],
            [
              -91.006406,
              32.139952
            ],
            [
              -91.011806,
              32.131153
            ],
            [
              -91.017606,
              32.125153
            ],
            [
              -91.020006,
              32.123553
            ],
            [
              -91.027007,
              32.121053
            ],
            [
              -91.0307991898912,
              32.1205658494524
            ],
            [
              -91.030907,
              32.120552
            ],
            [
              -91.0307064962793,
              32.114336885917304
            ],
            [
              -90.990299,
              32.096986
            ],
            [
              -90.97034,
              32.094612
            ],
            [
              -90.96301,
              32.105321
            ],
            [
              -90.957699,
              32.090339
            ],
            [
              -90.942925,
              32.078337
            ],
            [
              -90.917952,
              32.080684
            ],
            [
              -90.913834,
              32.096061
            ],
            [
              -90.92432,
              32.097477
            ],
            [
              -90.921733,
              32.117844
            ],
            [
              -90.90774,
              32.131888
            ],
            [
              -90.882558,
              32.114534
            ],
            [
              -90.875104,
              32.127612
            ],
            [
              -90.870458,
              32.119977
            ],
            [
              -90.846115,
              32.128174
            ],
            [
              -90.843787,
              32.138616
            ],
            [
              -90.858011,
              32.143574
            ],
            [
              -90.843843,
              32.159039
            ],
            [
              -90.826108,
              32.156273
            ],
            [
              -90.829654,
              32.144857
            ],
            [
              -90.819237,
              32.137276
            ],
            [
              -90.803323,
              32.152577
            ],
            [
              -90.792392,
              32.144223
            ],
            [
              -90.792869,
              32.151566
            ],
            [
              -90.77482,
              32.153993
            ],
            [
              -90.780695,
              32.171882
            ],
            [
              -90.79005,
              32.174716
            ],
            [
              -90.777187,
              32.193587
            ],
            [
              -90.750398,
              32.19697
            ],
            [
              -90.75435,
              32.206197
            ],
            [
              -90.73849,
              32.205577
            ],
            [
              -90.739199,
              32.216047
            ],
            [
              -90.728622,
              32.225119
            ],
            [
              -90.727881,
              32.226019
            ],
            [
              -90.726616,
              32.236632
            ],
            [
              -90.715417,
              32.256043
            ],
            [
              -90.71461,
              32.275974
            ],
            [
              -90.695917,
              32.305426
            ],
            [
              -90.705843,
              32.315637
            ],
            [
              -90.699319,
              32.331059
            ],
            [
              -90.707605,
              32.340004
            ],
            [
              -90.694517,
              32.352996
            ],
            [
              -90.683571,
              32.352784
            ],
            [
              -90.627978,
              32.339483
            ],
            [
              -90.636162,
              32.374498
            ],
            [
              -90.617742,
              32.393012
            ],
            [
              -90.61549,
              32.409438
            ],
            [
              -90.599985,
              32.424627
            ],
            [
              -90.59991,
              32.435209
            ],
            [
              -90.585846,
              32.445348
            ],
            [
              -90.597829,
              32.450281
            ],
            [
              -90.604067,
              32.479064
            ],
            [
              -90.587528,
              32.496664
            ],
            [
              -90.553821,
              32.507574
            ],
            [
              -90.553904,
              32.531907
            ],
            [
              -90.587925,
              32.531756
            ],
            [
              -90.588019,
              32.546067
            ],
            [
              -90.654563,
              32.546042
            ],
            [
              -90.655356,
              32.580003
            ],
            [
              -90.655527,
              32.589771
            ],
            [
              -90.690249,
              32.589388
            ],
            [
              -90.689946,
              32.618658
            ],
            [
              -90.720102,
              32.618858
            ],
            [
              -90.716399,
              32.611578
            ],
            [
              -90.722726,
              32.592118
            ],
            [
              -90.731699,
              32.587276
            ],
            [
              -90.728344,
              32.566265
            ],
            [
              -90.73952,
              32.571773
            ],
            [
              -90.768081,
              32.574751
            ],
            [
              -90.860566,
              32.575173
            ],
            [
              -90.861379,
              32.442768
            ],
            [
              -90.869784,
              32.451315
            ],
            [
              -90.881466,
              32.440768
            ],
            [
              -90.894745,
              32.448602
            ],
            [
              -90.915712,
              32.444141
            ],
            [
              -90.926476,
              32.450579
            ],
            [
              -90.95124,
              32.444389
            ],
            [
              -90.963381,
              32.411185
            ],
            [
              -90.964043,
              32.575605
            ],
            [
              -91.0434509306581,
              32.576269553442096
            ],
            [
              -91.049312,
              32.573624
            ],
            [
              -91.069354,
              32.563052
            ],
            [
              -91.0697862054881,
              32.562793318519
            ],
            [
              -91.080398,
              32.556442
            ],
            [
              -91.075373,
              32.546718
            ],
            [
              -91.061685,
              32.536448
            ],
            [
              -91.051168,
              32.53089
            ],
            [
              -91.011275,
              32.516596
            ],
            [
              -91.005468,
              32.513842
            ],
            [
              -90.994481,
              32.506331
            ],
            [
              -90.989826,
              32.500139
            ],
            [
              -90.988174,
              32.496796
            ],
            [
              -90.987831,
              32.49419
            ],
            [
              -90.988278,
              32.49119
            ],
            [
              -90.990703,
              32.487749
            ],
            [
              -90.996388,
              32.483925
            ],
            [
              -90.999223,
              32.482615
            ],
            [
              -91.004206,
              32.48214
            ],
            [
              -91.017106,
              32.48374
            ],
            [
              -91.024106,
              32.48524
            ],
            [
              -91.033906,
              32.48854
            ],
            [
              -91.038106,
              32.49044
            ],
            [
              -91.045807,
              32.495539
            ],
            [
              -91.050907,
              32.500139
            ],
            [
              -91.060516,
              32.512361
            ],
            [
              -91.083808,
              32.526873
            ],
            [
              -91.101304,
              32.525599
            ],
            [
              -91.106985,
              32.514934
            ],
            [
              -91.116708,
              32.500139
            ],
            [
              -91.117308,
              32.495039
            ],
            [
              -91.116008,
              32.48314
            ],
            [
              -91.112108,
              32.47614
            ],
            [
              -91.108808,
              32.47204
            ],
            [
              -91.095308,
              32.458741
            ],
            [
              -91.081807,
              32.450441
            ],
            [
              -91.070207,
              32.445141
            ],
            [
              -91.052907,
              32.438442
            ],
            [
              -91.029606,
              32.433542
            ],
            [
              -91.026306,
              32.434442
            ],
            [
              -91.016506,
              32.440342
            ],
            [
              -91.004806,
              32.445741
            ],
            [
              -90.998974,
              32.450165
            ],
            [
              -90.993863,
              32.45085
            ],
            [
              -90.983423,
              32.449077
            ],
            [
              -90.978547,
              32.447032
            ],
            [
              -90.96959,
              32.43949
            ],
            [
              -90.96856,
              32.438084
            ],
            [
              -90.966869,
              32.435499
            ],
            [
              -90.966457,
              32.433868
            ],
            [
              -90.965986,
              32.424806
            ],
            [
              -90.966255,
              32.421027
            ],
            [
              -90.967767,
              32.418279
            ],
            [
              -90.971141,
              32.414636
            ],
            [
              -90.974461,
              32.412001
            ],
            [
              -90.980723,
              32.408243
            ],
            [
              -90.99408,
              32.403862
            ],
            [
              -90.99378,
              32.396778
            ],
            [
              -90.994686,
              32.392277
            ],
            [
              -90.996237,
              32.388061
            ],
            [
              -91.000606,
              32.381644
            ],
            [
              -91.004506,
              32.368144
            ],
            [
              -91.004506,
              32.364744
            ],
            [
              -91.003506,
              32.362145
            ],
            [
              -91.000106,
              32.357695
            ],
            [
              -90.993625,
              32.354047
            ],
            [
              -90.986672,
              32.35176
            ],
            [
              -90.954583,
              32.345859
            ],
            [
              -90.942981,
              32.345956
            ],
            [
              -90.934897,
              32.344967
            ],
            [
              -90.92117,
              32.342073
            ],
            [
              -90.912363,
              32.339454
            ],
            [
              -90.907756,
              32.343611
            ],
            [
              -90.897762,
              32.35436
            ],
            [
              -90.89206,
              32.370579
            ],
            [
              -90.888947,
              32.373246
            ],
            [
              -90.878289,
              32.374548
            ],
            [
              -90.875631,
              32.372434
            ],
            [
              -90.8761,
              32.357754
            ],
            [
              -90.893653,
              32.328095
            ],
            [
              -90.902558,
              32.319587
            ],
            [
              -90.905173,
              32.315497
            ],
            [
              -90.916157,
              32.303582
            ],
            [
              -90.922231,
              32.298639
            ],
            [
              -90.933991,
              32.290343
            ],
            [
              -90.947834,
              32.283486
            ],
            [
              -90.951351,
              32.283199
            ],
            [
              -90.953008,
              32.284043
            ],
            [
              -90.955405,
              32.286241
            ],
            [
              -90.961171,
              32.293288
            ],
            [
              -90.963079,
              32.296285
            ],
            [
              -90.964149,
              32.296872
            ],
            [
              -90.971643,
              32.297497
            ],
            [
              -90.974602,
              32.297122
            ],
            [
              -90.976199,
              32.29645
            ],
            [
              -90.979475,
              32.293702
            ],
            [
              -90.980747,
              32.29141
            ],
            [
              -90.984077,
              32.279954
            ],
            [
              -90.982985,
              32.270294
            ],
            [
              -90.981028,
              32.266733
            ],
            [
              -90.979663,
              32.265252
            ],
            [
              -90.971344,
              32.257742
            ],
            [
              -90.969403,
              32.25252
            ],
            [
              -90.970016,
              32.25168
            ],
            [
              -90.976139,
              32.248092
            ],
            [
              -90.98029,
              32.243601
            ],
            [
              -90.983135,
              32.231371
            ],
            [
              -90.983263,
              32.226201
            ],
            [
              -90.983434,
              32.221305
            ],
            [
              -90.985989,
              32.217856
            ],
            [
              -90.988672,
              32.215812
            ],
            [
              -90.991227,
              32.214662
            ],
            [
              -90.994293,
              32.213768
            ],
            [
              -90.997359,
              32.213896
            ],
            [
              -90.999531,
              32.214662
            ],
            [
              -91.001192,
              32.215173
            ],
            [
              -91.002469,
              32.215812
            ],
            [
              -91.004769,
              32.22105
            ],
            [
              -91.006106,
              32.22405
            ],
            [
              -91.009606,
              32.22615
            ],
            [
              -91.021507,
              32.236149
            ],
            [
              -91.026607,
              32.238749
            ],
            [
              -91.034307,
              32.241549
            ],
            [
              -91.039007,
              32.242349
            ],
            [
              -91.046507,
              32.241149
            ],
            [
              -91.050307,
              32.237949
            ],
            [
              -91.051807,
              32.234449
            ],
            [
              -91.053107,
              32.22795
            ],
            [
              -91.055107,
              32.22475
            ],
            [
              -91.061408,
              32.21865
            ],
            [
              -91.071108,
              32.22605
            ],
            [
              -91.075108,
              32.22705
            ],
            [
              -91.083708,
              32.22645
            ],
            [
              -91.092108,
              32.22385
            ],
            [
              -91.100409,
              32.21785
            ],
            [
              -91.108509,
              32.20815
            ],
            [
              -91.113009,
              32.20655
            ],
            [
              -91.114084,
              32.206697
            ],
            [
              -91.11727,
              32.206668
            ],
            [
              -91.124043,
              32.211104
            ],
            [
              -91.128495,
              32.213169
            ],
            [
              -91.130197,
              32.213666
            ],
            [
              -91.133587,
              32.213432
            ],
            [
              -91.158026,
              32.201956
            ],
            [
              -91.162062,
              32.199035
            ],
            [
              -91.164171,
              32.196888
            ],
            [
              -91.168073,
              32.186635
            ],
            [
              -91.171046,
              32.176526
            ],
            [
              -91.173664,
              32.164231
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "085",
      "COUNTYNS": "00695766",
      "AFFGEOID": "0500000US28085",
      "GEOID": "28085",
      "NAME": "Lincoln",
      "LSAD": "06",
      "ALAND": 1518055421,
      "AWATER": 5309826,
      "County_state": "Lincoln,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.736824,
              31.698491
            ],
            [
              -90.73733,
              31.611124
            ],
            [
              -90.633231,
              31.611409
            ],
            [
              -90.63313,
              31.44466
            ],
            [
              -90.633302,
              31.349306
            ],
            [
              -90.548199,
              31.349574
            ],
            [
              -90.46966,
              31.34952
            ],
            [
              -90.260391,
              31.350274
            ],
            [
              -90.24389,
              31.350274
            ],
            [
              -90.244523,
              31.487929
            ],
            [
              -90.244614,
              31.548781
            ],
            [
              -90.244608,
              31.569762
            ],
            [
              -90.245186,
              31.716187
            ],
            [
              -90.245191,
              31.717524
            ],
            [
              -90.259003,
              31.703916
            ],
            [
              -90.312064,
              31.700013
            ],
            [
              -90.380079,
              31.700205
            ],
            [
              -90.382913,
              31.685148
            ],
            [
              -90.409961,
              31.685317
            ],
            [
              -90.41379,
              31.689402
            ],
            [
              -90.414198,
              31.700456
            ],
            [
              -90.594025,
              31.698373
            ],
            [
              -90.736824,
              31.698491
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "063",
      "COUNTYNS": "00695755",
      "AFFGEOID": "0500000US28063",
      "GEOID": "28063",
      "NAME": "Jefferson",
      "LSAD": "06",
      "ALAND": 1346620561,
      "AWATER": 18988092,
      "County_state": "Jefferson,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.363714,
              31.780363
            ],
            [
              -91.364685,
              31.770345
            ],
            [
              -91.348562,
              31.765837
            ],
            [
              -91.322232,
              31.767702
            ],
            [
              -91.31597187202169,
              31.767416482856497
            ],
            [
              -91.301315,
              31.766748
            ],
            [
              -91.286045,
              31.772062
            ],
            [
              -91.273874,
              31.771178
            ],
            [
              -91.263043,
              31.766995
            ],
            [
              -91.259611,
              31.76129
            ],
            [
              -91.263406,
              31.754468
            ],
            [
              -91.275545,
              31.745515
            ],
            [
              -91.291723,
              31.74526
            ],
            [
              -91.3165801943028,
              31.7453109122142
            ],
            [
              -91.306939,
              31.711026
            ],
            [
              -91.291833,
              31.691609
            ],
            [
              -91.300115,
              31.672235
            ],
            [
              -91.267023,
              31.663897
            ],
            [
              -91.269313,
              31.647254
            ],
            [
              -91.241123,
              31.636949
            ],
            [
              -91.233022,
              31.629158
            ],
            [
              -91.153841,
              31.630488
            ],
            [
              -91.153864,
              31.610068
            ],
            [
              -91.127398,
              31.610211
            ],
            [
              -91.048993,
              31.61059
            ],
            [
              -90.80337,
              31.61079
            ],
            [
              -90.7501,
              31.611588
            ],
            [
              -90.73733,
              31.611124
            ],
            [
              -90.736824,
              31.698491
            ],
            [
              -90.736282,
              31.786134
            ],
            [
              -90.852657,
              31.785422
            ],
            [
              -90.840394,
              31.804097
            ],
            [
              -90.842,
              31.826498
            ],
            [
              -90.921189,
              31.842688
            ],
            [
              -91.013676,
              31.861852
            ],
            [
              -91.248144,
              31.869848
            ],
            [
              -91.264412,
              31.86546
            ],
            [
              -91.266512,
              31.86426
            ],
            [
              -91.267712,
              31.86266
            ],
            [
              -91.269012,
              31.858661
            ],
            [
              -91.268812,
              31.855161
            ],
            [
              -91.268112,
              31.853061
            ],
            [
              -91.266612,
              31.851161
            ],
            [
              -91.261144,
              31.846119
            ],
            [
              -91.245624,
              31.833165
            ],
            [
              -91.245047,
              31.831447
            ],
            [
              -91.247367,
              31.822323
            ],
            [
              -91.250111,
              31.817762
            ],
            [
              -91.255611,
              31.812662
            ],
            [
              -91.262011,
              31.809362
            ],
            [
              -91.269212,
              31.809162
            ],
            [
              -91.276712,
              31.811362
            ],
            [
              -91.280212,
              31.813162
            ],
            [
              -91.282212,
              31.814762
            ],
            [
              -91.284912,
              31.818362
            ],
            [
              -91.287812,
              31.824161
            ],
            [
              -91.289412,
              31.828661
            ],
            [
              -91.290135,
              31.833658
            ],
            [
              -91.289312,
              31.846861
            ],
            [
              -91.293413,
              31.86016
            ],
            [
              -91.294713,
              31.86046
            ],
            [
              -91.326914,
              31.854961
            ],
            [
              -91.333814,
              31.853261
            ],
            [
              -91.338414,
              31.851261
            ],
            [
              -91.343014,
              31.846861
            ],
            [
              -91.345714,
              31.842861
            ],
            [
              -91.359514,
              31.799362
            ],
            [
              -91.363714,
              31.780363
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "145",
      "COUNTYNS": "00695793",
      "AFFGEOID": "0500000US28145",
      "GEOID": "28145",
      "NAME": "Union",
      "LSAD": "06",
      "ALAND": 1076399403,
      "AWATER": 3387488,
      "County_state": "Union,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.246133,
              34.495633
            ],
            [
              -89.246339,
              34.379344
            ],
            [
              -89.139869,
              34.379874
            ],
            [
              -88.928901,
              34.379361
            ],
            [
              -88.928546,
              34.365001
            ],
            [
              -88.824966,
              34.364759
            ],
            [
              -88.825126,
              34.393893
            ],
            [
              -88.789774,
              34.393692
            ],
            [
              -88.789442,
              34.49493
            ],
            [
              -88.736671,
              34.494656
            ],
            [
              -88.736465,
              34.50891
            ],
            [
              -88.735982,
              34.55652
            ],
            [
              -88.73544,
              34.596483
            ],
            [
              -88.758182,
              34.596536
            ],
            [
              -89.088488,
              34.598247
            ],
            [
              -89.14105,
              34.59789
            ],
            [
              -89.245611,
              34.597601
            ],
            [
              -89.245645,
              34.583234
            ],
            [
              -89.246133,
              34.495633
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "141",
      "COUNTYNS": "00695791",
      "AFFGEOID": "0500000US28141",
      "GEOID": "28141",
      "NAME": "Tishomingo",
      "LSAD": "06",
      "ALAND": 1098939231,
      "AWATER": 52359814,
      "County_state": "Tishomingo,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.36567,
              34.799421
            ],
            [
              -88.365472,
              34.755604
            ],
            [
              -88.348366,
              34.755355
            ],
            [
              -88.348311,
              34.740873
            ],
            [
              -88.330699,
              34.740721
            ],
            [
              -88.330813,
              34.580412
            ],
            [
              -88.329193,
              34.463571
            ],
            [
              -88.156292,
              34.463214
            ],
            [
              -88.139988,
              34.581703
            ],
            [
              -88.139246,
              34.587795
            ],
            [
              -88.138719,
              34.589215
            ],
            [
              -88.134263,
              34.62266
            ],
            [
              -88.1229228444128,
              34.6953468499391
            ],
            [
              -88.118407,
              34.724292
            ],
            [
              -88.116418,
              34.746303
            ],
            [
              -88.10756,
              34.811628
            ],
            [
              -88.097888,
              34.892202
            ],
            [
              -88.099999,
              34.894095
            ],
            [
              -88.125038,
              34.902227
            ],
            [
              -88.136692,
              34.907592
            ],
            [
              -88.139721,
              34.909631
            ],
            [
              -88.146335,
              34.914374
            ],
            [
              -88.154617,
              34.922392
            ],
            [
              -88.161594,
              34.933456
            ],
            [
              -88.172102,
              34.955213
            ],
            [
              -88.176106,
              34.962519
            ],
            [
              -88.179973,
              34.967466
            ],
            [
              -88.187429,
              34.974909
            ],
            [
              -88.198811,
              34.991192
            ],
            [
              -88.200064,
              34.995634
            ],
            [
              -88.253825,
              34.995553
            ],
            [
              -88.258111,
              34.995463
            ],
            [
              -88.3635308623519,
              34.99558961029079
            ],
            [
              -88.36567,
              34.799421
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "081",
      "COUNTYNS": "00695764",
      "AFFGEOID": "0500000US28081",
      "GEOID": "28081",
      "NAME": "Lee",
      "LSAD": "06",
      "ALAND": 1165367719,
      "AWATER": 8349354,
      "County_state": "Lee,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.825534,
              34.308244
            ],
            [
              -88.825424,
              34.293597
            ],
            [
              -88.824849,
              34.206032
            ],
            [
              -88.824416,
              34.147871
            ],
            [
              -88.82444,
              34.118169
            ],
            [
              -88.824523,
              34.074948
            ],
            [
              -88.718964,
              34.074521
            ],
            [
              -88.718693,
              34.08934
            ],
            [
              -88.67312,
              34.089145
            ],
            [
              -88.653185,
              34.08923
            ],
            [
              -88.543352,
              34.089044
            ],
            [
              -88.543692,
              34.11841
            ],
            [
              -88.543812,
              34.162498
            ],
            [
              -88.544575,
              34.233932
            ],
            [
              -88.544659,
              34.27654
            ],
            [
              -88.542177,
              34.337988
            ],
            [
              -88.542106,
              34.432502
            ],
            [
              -88.542079,
              34.465276
            ],
            [
              -88.541749,
              34.509192
            ],
            [
              -88.736465,
              34.50891
            ],
            [
              -88.736671,
              34.494656
            ],
            [
              -88.789442,
              34.49493
            ],
            [
              -88.789774,
              34.393692
            ],
            [
              -88.825126,
              34.393893
            ],
            [
              -88.824966,
              34.364759
            ],
            [
              -88.825534,
              34.308244
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "071",
      "COUNTYNS": "00695759",
      "AFFGEOID": "0500000US28071",
      "GEOID": "28071",
      "NAME": "Lafayette",
      "LSAD": "06",
      "ALAND": 1636137315,
      "AWATER": 123056595,
      "County_state": "Lafayette,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.721341,
              34.554274
            ],
            [
              -89.720941,
              34.450933
            ],
            [
              -89.721013,
              34.448581
            ],
            [
              -89.721008,
              34.349182
            ],
            [
              -89.719382,
              34.338039
            ],
            [
              -89.72105,
              34.192545
            ],
            [
              -89.526874,
              34.191003
            ],
            [
              -89.50944,
              34.19096
            ],
            [
              -89.509748,
              34.162082
            ],
            [
              -89.245485,
              34.161186
            ],
            [
              -89.245976,
              34.248649
            ],
            [
              -89.246409,
              34.333833
            ],
            [
              -89.246339,
              34.379344
            ],
            [
              -89.246133,
              34.495633
            ],
            [
              -89.264308,
              34.505426
            ],
            [
              -89.284509,
              34.506887
            ],
            [
              -89.292897,
              34.51715
            ],
            [
              -89.326401,
              34.523111
            ],
            [
              -89.325433,
              34.525623
            ],
            [
              -89.353678,
              34.522252
            ],
            [
              -89.379192,
              34.516884
            ],
            [
              -89.40435,
              34.527108
            ],
            [
              -89.434287,
              34.520062
            ],
            [
              -89.485527,
              34.554204
            ],
            [
              -89.511012,
              34.554496
            ],
            [
              -89.668448,
              34.554367
            ],
            [
              -89.721341,
              34.554274
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "029",
      "COUNTYNS": "00695739",
      "AFFGEOID": "0500000US28029",
      "GEOID": "28029",
      "NAME": "Copiah",
      "LSAD": "06",
      "ALAND": 2013048568,
      "AWATER": 5754986,
      "County_state": "Copiah,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.736282,
              31.786134
            ],
            [
              -90.736824,
              31.698491
            ],
            [
              -90.594025,
              31.698373
            ],
            [
              -90.414198,
              31.700456
            ],
            [
              -90.41379,
              31.689402
            ],
            [
              -90.409961,
              31.685317
            ],
            [
              -90.382913,
              31.685148
            ],
            [
              -90.380079,
              31.700205
            ],
            [
              -90.312064,
              31.700013
            ],
            [
              -90.259003,
              31.703916
            ],
            [
              -90.245191,
              31.717524
            ],
            [
              -90.184139,
              31.731462
            ],
            [
              -90.154472,
              31.747144
            ],
            [
              -90.147898,
              31.746349
            ],
            [
              -90.131274,
              31.756048
            ],
            [
              -90.122565,
              31.75268
            ],
            [
              -90.128799,
              31.765916
            ],
            [
              -90.107491,
              31.777685
            ],
            [
              -90.110981,
              31.789266
            ],
            [
              -90.140757,
              31.789353
            ],
            [
              -90.150702,
              31.799872
            ],
            [
              -90.136024,
              31.818968
            ],
            [
              -90.158918,
              31.843179
            ],
            [
              -90.13891,
              31.848104
            ],
            [
              -90.132552,
              31.856923
            ],
            [
              -90.138341,
              31.875319
            ],
            [
              -90.145716,
              31.892867
            ],
            [
              -90.170276,
              31.904472
            ],
            [
              -90.169786,
              31.91764
            ],
            [
              -90.178698,
              31.942663
            ],
            [
              -90.198297,
              31.941904
            ],
            [
              -90.191867,
              31.944108
            ],
            [
              -90.193043,
              31.953088
            ],
            [
              -90.188081,
              31.949493
            ],
            [
              -90.224273,
              31.996597
            ],
            [
              -90.232667,
              32.008298
            ],
            [
              -90.220928,
              32.018905
            ],
            [
              -90.239174,
              32.019515
            ],
            [
              -90.247919,
              32.031521
            ],
            [
              -90.230331,
              32.049728
            ],
            [
              -90.300953,
              32.049647
            ],
            [
              -90.33407,
              32.049665
            ],
            [
              -90.338667,
              32.049669
            ],
            [
              -90.38239,
              32.049826
            ],
            [
              -90.655148,
              32.049958
            ],
            [
              -90.66139,
              32.048462
            ],
            [
              -90.718297,
              32.048352
            ],
            [
              -90.714405,
              31.977629
            ],
            [
              -90.716406,
              31.904197
            ],
            [
              -90.716785,
              31.844583
            ],
            [
              -90.733,
              31.829976
            ],
            [
              -90.736282,
              31.786134
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "111",
      "COUNTYNS": "00695778",
      "AFFGEOID": "0500000US28111",
      "GEOID": "28111",
      "NAME": "Perry",
      "LSAD": "06",
      "ALAND": 1676259638,
      "AWATER": 7754345,
      "County_state": "Perry,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.146104,
              31.344974
            ],
            [
              -89.146176,
              31.000447
            ],
            [
              -89.138154,
              31.000185
            ],
            [
              -89.137967,
              30.909877
            ],
            [
              -88.885038,
              30.910788
            ],
            [
              -88.834475,
              30.910323
            ],
            [
              -88.834339,
              30.997983
            ],
            [
              -88.840929,
              30.99841
            ],
            [
              -88.841232,
              31.26779
            ],
            [
              -88.841707,
              31.433703
            ],
            [
              -88.943468,
              31.433458
            ],
            [
              -89.013412,
              31.433599
            ],
            [
              -89.146092,
              31.434052
            ],
            [
              -89.146104,
              31.344974
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "073",
      "COUNTYNS": "00695760",
      "AFFGEOID": "0500000US28073",
      "GEOID": "28073",
      "NAME": "Lamar",
      "LSAD": "06",
      "ALAND": 1287499611,
      "AWATER": 8599890,
      "County_state": "Lamar,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.654212,
              31.433805
            ],
            [
              -89.6538,
              31.283134
            ],
            [
              -89.653998,
              31.229582
            ],
            [
              -89.653803,
              31.212195
            ],
            [
              -89.653754,
              31.127737
            ],
            [
              -89.654038,
              31.002502
            ],
            [
              -89.61003,
              31.002345
            ],
            [
              -89.551851,
              31.001917
            ],
            [
              -89.551966,
              31.014007
            ],
            [
              -89.533141,
              31.013946
            ],
            [
              -89.501411,
              31.013907
            ],
            [
              -89.501396,
              31.001461
            ],
            [
              -89.47594,
              31.001363
            ],
            [
              -89.476022,
              30.982901
            ],
            [
              -89.425337,
              30.982452
            ],
            [
              -89.424808,
              31.010114
            ],
            [
              -89.34895,
              31.01037
            ],
            [
              -89.349038,
              31.082508
            ],
            [
              -89.348868,
              31.166306
            ],
            [
              -89.347884,
              31.259176
            ],
            [
              -89.347891,
              31.32498
            ],
            [
              -89.347863,
              31.3467
            ],
            [
              -89.352644,
              31.346566
            ],
            [
              -89.398983,
              31.346539
            ],
            [
              -89.451073,
              31.346383
            ],
            [
              -89.451008,
              31.361141
            ],
            [
              -89.451616,
              31.434029
            ],
            [
              -89.586819,
              31.433688
            ],
            [
              -89.654212,
              31.433805
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "053",
      "COUNTYNS": "00695750",
      "AFFGEOID": "0500000US28053",
      "GEOID": "28053",
      "NAME": "Humphreys",
      "LSAD": "06",
      "ALAND": 1083947281,
      "AWATER": 32942668,
      "County_state": "Humphreys,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.727711,
              33.267507
            ],
            [
              -90.733216,
              33.232975
            ],
            [
              -90.704174,
              33.2126
            ],
            [
              -90.680319,
              33.201971
            ],
            [
              -90.685693,
              33.189611
            ],
            [
              -90.688136,
              33.148468
            ],
            [
              -90.698502,
              33.138033
            ],
            [
              -90.699732,
              33.121997
            ],
            [
              -90.679551,
              33.114586
            ],
            [
              -90.679607,
              33.102483
            ],
            [
              -90.701845,
              33.096294
            ],
            [
              -90.658049,
              33.096514
            ],
            [
              -90.656632,
              32.92251
            ],
            [
              -90.449893,
              32.92329
            ],
            [
              -90.449323,
              33.00901
            ],
            [
              -90.371242,
              33.009766
            ],
            [
              -90.364031,
              33.010596
            ],
            [
              -90.382837,
              33.025903
            ],
            [
              -90.379756,
              33.042074
            ],
            [
              -90.391456,
              33.04999
            ],
            [
              -90.415436,
              33.048978
            ],
            [
              -90.429783,
              33.060991
            ],
            [
              -90.43152,
              33.095709
            ],
            [
              -90.41846,
              33.114682
            ],
            [
              -90.416287,
              33.104058
            ],
            [
              -90.393411,
              33.101737
            ],
            [
              -90.384934,
              33.087334
            ],
            [
              -90.37296,
              33.084073
            ],
            [
              -90.36546,
              33.095533
            ],
            [
              -90.38629,
              33.112805
            ],
            [
              -90.368855,
              33.125467
            ],
            [
              -90.35928,
              33.094079
            ],
            [
              -90.347004,
              33.101191
            ],
            [
              -90.355459,
              33.113145
            ],
            [
              -90.346924,
              33.132024
            ],
            [
              -90.330567,
              33.135352
            ],
            [
              -90.330491,
              33.186684
            ],
            [
              -90.331589,
              33.302825
            ],
            [
              -90.343788,
              33.309061
            ],
            [
              -90.364823,
              33.275765
            ],
            [
              -90.376725,
              33.280299
            ],
            [
              -90.366107,
              33.239747
            ],
            [
              -90.410827,
              33.245111
            ],
            [
              -90.413236,
              33.23532
            ],
            [
              -90.453801,
              33.267302
            ],
            [
              -90.453755,
              33.276255
            ],
            [
              -90.453233,
              33.329484
            ],
            [
              -90.555549,
              33.329181
            ],
            [
              -90.555211,
              33.270533
            ],
            [
              -90.710217,
              33.270466
            ],
            [
              -90.716277,
              33.270032
            ],
            [
              -90.727711,
              33.267507
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "121",
      "COUNTYNS": "00695781",
      "AFFGEOID": "0500000US28121",
      "GEOID": "28121",
      "NAME": "Rankin",
      "LSAD": "06",
      "ALAND": 2008322209,
      "AWATER": 79429951,
      "County_state": "Rankin,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.242691,
              32.177689
            ],
            [
              -90.248421,
              32.153664
            ],
            [
              -90.240997,
              32.121712
            ],
            [
              -90.24752,
              32.09906
            ],
            [
              -90.226723,
              32.079758
            ],
            [
              -90.236259,
              32.064686
            ],
            [
              -90.230331,
              32.049728
            ],
            [
              -90.192932,
              32.049613
            ],
            [
              -89.985009,
              32.049361
            ],
            [
              -89.730066,
              32.047072
            ],
            [
              -89.72987,
              32.090707
            ],
            [
              -89.731059,
              32.221946
            ],
            [
              -89.730424,
              32.222039
            ],
            [
              -89.730038,
              32.282215
            ],
            [
              -89.730063,
              32.352733
            ],
            [
              -89.764404,
              32.503613
            ],
            [
              -89.784123,
              32.586891
            ],
            [
              -89.826885,
              32.584593
            ],
            [
              -89.839985,
              32.593295
            ],
            [
              -89.863104,
              32.575772
            ],
            [
              -89.87508,
              32.559244
            ],
            [
              -89.886312,
              32.56323
            ],
            [
              -89.896112,
              32.541131
            ],
            [
              -89.923261,
              32.52892
            ],
            [
              -89.931403,
              32.512336
            ],
            [
              -89.939758,
              32.513744
            ],
            [
              -89.960643,
              32.491383
            ],
            [
              -90.013819,
              32.480117
            ],
            [
              -90.018595,
              32.473709
            ],
            [
              -90.04066,
              32.441678
            ],
            [
              -90.068068,
              32.438606
            ],
            [
              -90.0629,
              32.419982
            ],
            [
              -90.075445,
              32.418574
            ],
            [
              -90.065697,
              32.399529
            ],
            [
              -90.081482,
              32.395344
            ],
            [
              -90.093681,
              32.390244
            ],
            [
              -90.109685,
              32.34865
            ],
            [
              -90.142265,
              32.321135
            ],
            [
              -90.152957,
              32.320775
            ],
            [
              -90.162677,
              32.30624
            ],
            [
              -90.156187,
              32.297346
            ],
            [
              -90.151387,
              32.291046
            ],
            [
              -90.167893,
              32.287385
            ],
            [
              -90.177692,
              32.290275
            ],
            [
              -90.179701,
              32.276162
            ],
            [
              -90.184925,
              32.274245
            ],
            [
              -90.186089,
              32.260647
            ],
            [
              -90.205292,
              32.251945
            ],
            [
              -90.209023,
              32.235802
            ],
            [
              -90.185799,
              32.219563
            ],
            [
              -90.190544,
              32.203613
            ],
            [
              -90.181908,
              32.201912
            ],
            [
              -90.194855,
              32.187855
            ],
            [
              -90.232643,
              32.173595
            ],
            [
              -90.242691,
              32.177689
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "049",
      "COUNTYNS": "00695748",
      "AFFGEOID": "0500000US28049",
      "GEOID": "28049",
      "NAME": "Hinds",
      "LSAD": "06",
      "ALAND": 2252651371,
      "AWATER": 19495790,
      "County_state": "Hinds,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.727881,
              32.226019
            ],
            [
              -90.728622,
              32.225119
            ],
            [
              -90.718297,
              32.048352
            ],
            [
              -90.66139,
              32.048462
            ],
            [
              -90.655148,
              32.049958
            ],
            [
              -90.38239,
              32.049826
            ],
            [
              -90.338667,
              32.049669
            ],
            [
              -90.33407,
              32.049665
            ],
            [
              -90.300953,
              32.049647
            ],
            [
              -90.230331,
              32.049728
            ],
            [
              -90.236259,
              32.064686
            ],
            [
              -90.226723,
              32.079758
            ],
            [
              -90.24752,
              32.09906
            ],
            [
              -90.240997,
              32.121712
            ],
            [
              -90.248421,
              32.153664
            ],
            [
              -90.242691,
              32.177689
            ],
            [
              -90.232643,
              32.173595
            ],
            [
              -90.194855,
              32.187855
            ],
            [
              -90.181908,
              32.201912
            ],
            [
              -90.190544,
              32.203613
            ],
            [
              -90.185799,
              32.219563
            ],
            [
              -90.209023,
              32.235802
            ],
            [
              -90.205292,
              32.251945
            ],
            [
              -90.186089,
              32.260647
            ],
            [
              -90.184925,
              32.274245
            ],
            [
              -90.179701,
              32.276162
            ],
            [
              -90.177692,
              32.290275
            ],
            [
              -90.167893,
              32.287385
            ],
            [
              -90.151387,
              32.291046
            ],
            [
              -90.156187,
              32.297346
            ],
            [
              -90.162677,
              32.30624
            ],
            [
              -90.152957,
              32.320775
            ],
            [
              -90.142265,
              32.321135
            ],
            [
              -90.109685,
              32.34865
            ],
            [
              -90.093681,
              32.390244
            ],
            [
              -90.081482,
              32.395344
            ],
            [
              -90.065697,
              32.399529
            ],
            [
              -90.08589,
              32.399473
            ],
            [
              -90.123488,
              32.399451
            ],
            [
              -90.134441,
              32.399492
            ],
            [
              -90.140723,
              32.399479
            ],
            [
              -90.160183,
              32.400275
            ],
            [
              -90.176566,
              32.400235
            ],
            [
              -90.243764,
              32.400098
            ],
            [
              -90.248092,
              32.487577
            ],
            [
              -90.279875,
              32.487635
            ],
            [
              -90.354129,
              32.487744
            ],
            [
              -90.394259,
              32.487714
            ],
            [
              -90.449864,
              32.488394
            ],
            [
              -90.450037,
              32.573783
            ],
            [
              -90.465137,
              32.547656
            ],
            [
              -90.523735,
              32.518831
            ],
            [
              -90.522217,
              32.50311
            ],
            [
              -90.553821,
              32.507574
            ],
            [
              -90.587528,
              32.496664
            ],
            [
              -90.604067,
              32.479064
            ],
            [
              -90.597829,
              32.450281
            ],
            [
              -90.585846,
              32.445348
            ],
            [
              -90.59991,
              32.435209
            ],
            [
              -90.599985,
              32.424627
            ],
            [
              -90.61549,
              32.409438
            ],
            [
              -90.617742,
              32.393012
            ],
            [
              -90.636162,
              32.374498
            ],
            [
              -90.627978,
              32.339483
            ],
            [
              -90.683571,
              32.352784
            ],
            [
              -90.694517,
              32.352996
            ],
            [
              -90.707605,
              32.340004
            ],
            [
              -90.699319,
              32.331059
            ],
            [
              -90.705843,
              32.315637
            ],
            [
              -90.695917,
              32.305426
            ],
            [
              -90.71461,
              32.275974
            ],
            [
              -90.715417,
              32.256043
            ],
            [
              -90.726616,
              32.236632
            ],
            [
              -90.727881,
              32.226019
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "127",
      "COUNTYNS": "00695784",
      "AFFGEOID": "0500000US28127",
      "GEOID": "28127",
      "NAME": "Simpson",
      "LSAD": "06",
      "ALAND": 1525930832,
      "AWATER": 3378529,
      "County_state": "Simpson,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.247919,
              32.031521
            ],
            [
              -90.239174,
              32.019515
            ],
            [
              -90.220928,
              32.018905
            ],
            [
              -90.232667,
              32.008298
            ],
            [
              -90.224273,
              31.996597
            ],
            [
              -90.188081,
              31.949493
            ],
            [
              -90.193043,
              31.953088
            ],
            [
              -90.191867,
              31.944108
            ],
            [
              -90.198297,
              31.941904
            ],
            [
              -90.178698,
              31.942663
            ],
            [
              -90.169786,
              31.91764
            ],
            [
              -90.170276,
              31.904472
            ],
            [
              -90.145716,
              31.892867
            ],
            [
              -90.138341,
              31.875319
            ],
            [
              -90.132552,
              31.856923
            ],
            [
              -90.13891,
              31.848104
            ],
            [
              -90.158918,
              31.843179
            ],
            [
              -90.136024,
              31.818968
            ],
            [
              -90.150702,
              31.799872
            ],
            [
              -90.140757,
              31.789353
            ],
            [
              -90.110981,
              31.789266
            ],
            [
              -90.107491,
              31.777685
            ],
            [
              -90.128799,
              31.765916
            ],
            [
              -90.122565,
              31.75268
            ],
            [
              -89.974586,
              31.761655
            ],
            [
              -89.852401,
              31.76861
            ],
            [
              -89.755103,
              31.774654
            ],
            [
              -89.653025,
              31.780653
            ],
            [
              -89.654501,
              31.783755
            ],
            [
              -89.654637,
              31.974676
            ],
            [
              -89.654426,
              32.013678
            ],
            [
              -89.662086,
              32.047239
            ],
            [
              -89.730066,
              32.047072
            ],
            [
              -89.985009,
              32.049361
            ],
            [
              -90.192932,
              32.049613
            ],
            [
              -90.230331,
              32.049728
            ],
            [
              -90.247919,
              32.031521
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "125",
      "COUNTYNS": "00695783",
      "AFFGEOID": "0500000US28125",
      "GEOID": "28125",
      "NAME": "Sharkey",
      "LSAD": "06",
      "ALAND": 1118149660,
      "AWATER": 8806465,
      "County_state": "Sharkey,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.963347,
              32.838248
            ],
            [
              -90.964864,
              32.662798
            ],
            [
              -90.723159,
              32.662128
            ],
            [
              -90.708028,
              32.690319
            ],
            [
              -90.695601,
              32.685119
            ],
            [
              -90.684171,
              32.663747
            ],
            [
              -90.670064,
              32.669581
            ],
            [
              -90.664408,
              32.689129
            ],
            [
              -90.693271,
              32.706523
            ],
            [
              -90.713735,
              32.707313
            ],
            [
              -90.742634,
              32.729225
            ],
            [
              -90.736123,
              32.755996
            ],
            [
              -90.717628,
              32.747993
            ],
            [
              -90.693061,
              32.754297
            ],
            [
              -90.689758,
              32.765881
            ],
            [
              -90.70767,
              32.777785
            ],
            [
              -90.715415,
              32.789861
            ],
            [
              -90.709499,
              32.806884
            ],
            [
              -90.694289,
              32.814926
            ],
            [
              -90.723389,
              32.818882
            ],
            [
              -90.741016,
              32.809132
            ],
            [
              -90.759635,
              32.814006
            ],
            [
              -90.759753,
              32.835763
            ],
            [
              -90.656632,
              32.92251
            ],
            [
              -90.658049,
              33.096514
            ],
            [
              -90.701845,
              33.096294
            ],
            [
              -90.862866,
              33.095106
            ],
            [
              -90.914465,
              33.097417
            ],
            [
              -90.914337,
              33.007697
            ],
            [
              -90.91404,
              32.944198
            ],
            [
              -90.928652,
              32.907857
            ],
            [
              -90.940155,
              32.885838
            ],
            [
              -90.940177,
              32.862068
            ],
            [
              -90.956024,
              32.852905
            ],
            [
              -90.963347,
              32.838248
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "115",
      "COUNTYNS": "00695803",
      "AFFGEOID": "0500000US28115",
      "GEOID": "28115",
      "NAME": "Pontotoc",
      "LSAD": "06",
      "ALAND": 1289142874,
      "AWATER": 8496511,
      "County_state": "Pontotoc,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.246339,
              34.379344
            ],
            [
              -89.246409,
              34.333833
            ],
            [
              -89.245976,
              34.248649
            ],
            [
              -89.245485,
              34.161186
            ],
            [
              -89.244436,
              34.073942
            ],
            [
              -89.139158,
              34.074116
            ],
            [
              -89.074738,
              34.074348
            ],
            [
              -88.824523,
              34.074948
            ],
            [
              -88.82444,
              34.118169
            ],
            [
              -88.824416,
              34.147871
            ],
            [
              -88.824849,
              34.206032
            ],
            [
              -88.825424,
              34.293597
            ],
            [
              -88.825534,
              34.308244
            ],
            [
              -88.824966,
              34.364759
            ],
            [
              -88.928546,
              34.365001
            ],
            [
              -88.928901,
              34.379361
            ],
            [
              -89.139869,
              34.379874
            ],
            [
              -89.246339,
              34.379344
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "033",
      "COUNTYNS": "00695741",
      "AFFGEOID": "0500000US28033",
      "GEOID": "28033",
      "NAME": "DeSoto",
      "LSAD": "06",
      "ALAND": 1233556699,
      "AWATER": 53624068,
      "County_state": "DeSoto,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.310005,
              34.875097
            ],
            [
              -90.313476,
              34.871698
            ],
            [
              -90.3044193945795,
              34.8605888851081
            ],
            [
              -90.202126,
              34.860374
            ],
            [
              -90.202407,
              34.772448
            ],
            [
              -90.200199,
              34.724418
            ],
            [
              -90.19246,
              34.724892
            ],
            [
              -90.170946,
              34.759159
            ],
            [
              -90.128906,
              34.760537
            ],
            [
              -90.11694,
              34.752395
            ],
            [
              -90.106985,
              34.733274
            ],
            [
              -90.060568,
              34.728177
            ],
            [
              -90.053487,
              34.712353
            ],
            [
              -90.02872,
              34.708542
            ],
            [
              -89.982282,
              34.725501
            ],
            [
              -89.954005,
              34.727109
            ],
            [
              -89.955046,
              34.7382
            ],
            [
              -89.915271,
              34.75835
            ],
            [
              -89.912202,
              34.772506
            ],
            [
              -89.753114,
              34.772374
            ],
            [
              -89.730913,
              34.772155
            ],
            [
              -89.723442,
              34.772311
            ],
            [
              -89.72387,
              34.819322
            ],
            [
              -89.724018,
              34.927631
            ],
            [
              -89.7243244282036,
              34.995211728650496
            ],
            [
              -89.741785,
              34.995194
            ],
            [
              -89.75961233055601,
              34.994893216792796
            ],
            [
              -89.795187,
              34.994293
            ],
            [
              -89.8131287302322,
              34.9942593388487
            ],
            [
              -89.848488,
              34.994193
            ],
            [
              -89.883365,
              34.994261
            ],
            [
              -89.8837478802462,
              34.994264623953704
            ],
            [
              -89.893402,
              34.994356
            ],
            [
              -89.9014606033329,
              34.9943819258016
            ],
            [
              -89.9899073593362,
              34.99466647300589
            ],
            [
              -90.0250168365057,
              34.9947794257475
            ],
            [
              -90.0607842992266,
              34.994894495332595
            ],
            [
              -90.0963372057652,
              34.9950088746566
            ],
            [
              -90.309297,
              34.995694
            ],
            [
              -90.304425,
              34.984939
            ],
            [
              -90.302471,
              34.982077
            ],
            [
              -90.296422,
              34.976346
            ],
            [
              -90.293083,
              34.974574
            ],
            [
              -90.287239,
              34.972531
            ],
            [
              -90.282234,
              34.967661
            ],
            [
              -90.27824,
              34.965077
            ],
            [
              -90.259663,
              34.957793
            ],
            [
              -90.253969,
              34.954988
            ],
            [
              -90.250056,
              34.951196
            ],
            [
              -90.247832,
              34.947916
            ],
            [
              -90.246116,
              34.944316
            ],
            [
              -90.244476,
              34.937596
            ],
            [
              -90.244725,
              34.921031
            ],
            [
              -90.246546,
              34.914168
            ],
            [
              -90.248308,
              34.909739
            ],
            [
              -90.250095,
              34.90732
            ],
            [
              -90.279364,
              34.890077
            ],
            [
              -90.301957,
              34.880053
            ],
            [
              -90.310005,
              34.875097
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "093",
      "COUNTYNS": "00695770",
      "AFFGEOID": "0500000US28093",
      "GEOID": "28093",
      "NAME": "Marshall",
      "LSAD": "06",
      "ALAND": 1828989824,
      "AWATER": 9195190,
      "County_state": "Marshall,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.7243244282036,
              34.995211728650496
            ],
            [
              -89.724018,
              34.927631
            ],
            [
              -89.72387,
              34.819322
            ],
            [
              -89.723442,
              34.772311
            ],
            [
              -89.723386,
              34.743008
            ],
            [
              -89.722989,
              34.713169
            ],
            [
              -89.722333,
              34.685504
            ],
            [
              -89.669183,
              34.685597
            ],
            [
              -89.668448,
              34.554367
            ],
            [
              -89.511012,
              34.554496
            ],
            [
              -89.485527,
              34.554204
            ],
            [
              -89.434287,
              34.520062
            ],
            [
              -89.40435,
              34.527108
            ],
            [
              -89.379192,
              34.516884
            ],
            [
              -89.353678,
              34.522252
            ],
            [
              -89.325433,
              34.525623
            ],
            [
              -89.326401,
              34.523111
            ],
            [
              -89.292897,
              34.51715
            ],
            [
              -89.284509,
              34.506887
            ],
            [
              -89.264308,
              34.505426
            ],
            [
              -89.246133,
              34.495633
            ],
            [
              -89.245645,
              34.583234
            ],
            [
              -89.298334,
              34.582926
            ],
            [
              -89.298794,
              34.721352
            ],
            [
              -89.299183,
              34.820655
            ],
            [
              -89.299227,
              34.844415
            ],
            [
              -89.352705,
              34.844432
            ],
            [
              -89.352858,
              34.917253
            ],
            [
              -89.352796,
              34.946419
            ],
            [
              -89.35268016441019,
              34.9941227781396
            ],
            [
              -89.40572016978702,
              34.9938850355527
            ],
            [
              -89.434954,
              34.993754
            ],
            [
              -89.486897,
              34.993975
            ],
            [
              -89.493739,
              34.994361
            ],
            [
              -89.511153,
              34.994755
            ],
            [
              -89.56505951050359,
              34.994972846619795
            ],
            [
              -89.644282,
              34.995293
            ],
            [
              -89.7243244282036,
              34.995211728650496
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "027",
      "COUNTYNS": "00695738",
      "AFFGEOID": "0500000US28027",
      "GEOID": "28027",
      "NAME": "Coahoma",
      "LSAD": "06",
      "ALAND": 1431035290,
      "AWATER": 79322563,
      "County_state": "Coahoma,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.959317,
              34.13035
            ],
            [
              -90.958467,
              34.125105
            ],
            [
              -90.955974,
              34.120125
            ],
            [
              -90.799346,
              34.120614
            ],
            [
              -90.659457,
              34.118867
            ],
            [
              -90.660357,
              33.987585
            ],
            [
              -90.657002,
              33.987586
            ],
            [
              -90.451012,
              33.985689
            ],
            [
              -90.450981,
              34.072932
            ],
            [
              -90.450703,
              34.153676
            ],
            [
              -90.450792,
              34.223317
            ],
            [
              -90.450572,
              34.262402
            ],
            [
              -90.399014,
              34.261524
            ],
            [
              -90.399822,
              34.334878
            ],
            [
              -90.397526,
              34.422609
            ],
            [
              -90.399498,
              34.423807
            ],
            [
              -90.569347,
              34.524867
            ],
            [
              -90.578493,
              34.516296
            ],
            [
              -90.581062,
              34.512818
            ],
            [
              -90.58353,
              34.504085
            ],
            [
              -90.586525,
              34.500103
            ],
            [
              -90.587490456876,
              34.4965056046236
            ],
            [
              -90.588942,
              34.491097
            ],
            [
              -90.589921,
              34.484793
            ],
            [
              -90.588346,
              34.470562
            ],
            [
              -90.585477,
              34.461247
            ],
            [
              -90.583717,
              34.458829
            ],
            [
              -90.576893,
              34.454351
            ],
            [
              -90.573959,
              34.451875
            ],
            [
              -90.56733,
              34.440383
            ],
            [
              -90.565826,
              34.434379
            ],
            [
              -90.566505,
              34.429528
            ],
            [
              -90.568397,
              34.424805
            ],
            [
              -90.571145,
              34.420319
            ],
            [
              -90.575336,
              34.415152
            ],
            [
              -90.580677,
              34.410554
            ],
            [
              -90.613944,
              34.390723
            ],
            [
              -90.61848,
              34.388767
            ],
            [
              -90.631586,
              34.387193
            ],
            [
              -90.63494,
              34.386363
            ],
            [
              -90.641398,
              34.383869
            ],
            [
              -90.658542,
              34.375705
            ],
            [
              -90.655346,
              34.371846
            ],
            [
              -90.666788,
              34.35582
            ],
            [
              -90.666862,
              34.348569
            ],
            [
              -90.66087440465779,
              34.336693015370294
            ],
            [
              -90.660404,
              34.33576
            ],
            [
              -90.657371,
              34.327287
            ],
            [
              -90.657488,
              34.322231
            ],
            [
              -90.661395,
              34.315398
            ],
            [
              -90.669343,
              34.31302
            ],
            [
              -90.678097,
              34.313031
            ],
            [
              -90.686003,
              34.315771
            ],
            [
              -90.690005,
              34.318584
            ],
            [
              -90.693129,
              34.32257
            ],
            [
              -90.693686,
              34.32968
            ],
            [
              -90.691551,
              34.338618
            ],
            [
              -90.68162,
              34.35291
            ],
            [
              -90.680512,
              34.362529
            ],
            [
              -90.681921,
              34.365998
            ],
            [
              -90.683222,
              34.368817
            ],
            [
              -90.690497,
              34.368606
            ],
            [
              -90.700147,
              34.365855
            ],
            [
              -90.712088,
              34.363805
            ],
            [
              -90.724909,
              34.363659
            ],
            [
              -90.729131,
              34.364206
            ],
            [
              -90.741616,
              34.367225
            ],
            [
              -90.750107,
              34.367919
            ],
            [
              -90.756197,
              34.367256
            ],
            [
              -90.762085,
              34.364754
            ],
            [
              -90.765764,
              34.362109
            ],
            [
              -90.767061,
              34.360271
            ],
            [
              -90.768445,
              34.353469
            ],
            [
              -90.767732,
              34.346872
            ],
            [
              -90.767108,
              34.345264
            ],
            [
              -90.765174,
              34.342818
            ],
            [
              -90.748942,
              34.331045
            ],
            [
              -90.744713,
              34.324872
            ],
            [
              -90.742694,
              34.320263
            ],
            [
              -90.74061,
              34.313469
            ],
            [
              -90.740889,
              34.306538
            ],
            [
              -90.743082,
              34.302257
            ],
            [
              -90.752681,
              34.289266
            ],
            [
              -90.755271,
              34.286848
            ],
            [
              -90.765165,
              34.280524
            ],
            [
              -90.772266,
              34.279943
            ],
            [
              -90.797569,
              34.282402
            ],
            [
              -90.802928,
              34.282465
            ],
            [
              -90.812829,
              34.279438
            ],
            [
              -90.820919,
              34.27784
            ],
            [
              -90.824478,
              34.27624
            ],
            [
              -90.828267,
              34.27365
            ],
            [
              -90.830612,
              34.271245
            ],
            [
              -90.832407,
              34.267491
            ],
            [
              -90.836972,
              34.250104
            ],
            [
              -90.839981,
              34.236114
            ],
            [
              -90.840128,
              34.230104
            ],
            [
              -90.839509,
              34.226201
            ],
            [
              -90.840009,
              34.223077
            ],
            [
              -90.842151,
              34.21688
            ],
            [
              -90.844824,
              34.210999
            ],
            [
              -90.847808,
              34.20653
            ],
            [
              -90.852764,
              34.209403
            ],
            [
              -90.856708,
              34.211598
            ],
            [
              -90.867064,
              34.212141
            ],
            [
              -90.87912,
              34.21545
            ],
            [
              -90.89456,
              34.22438
            ],
            [
              -90.898286,
              34.227253
            ],
            [
              -90.900078,
              34.229621
            ],
            [
              -90.904279,
              34.24096
            ],
            [
              -90.905934,
              34.243529
            ],
            [
              -90.907082,
              34.244492
            ],
            [
              -90.912396,
              34.245932
            ],
            [
              -90.923152,
              34.24653
            ],
            [
              -90.929015,
              34.244541
            ],
            [
              -90.933511,
              34.240218
            ],
            [
              -90.936404,
              34.236698
            ],
            [
              -90.937152,
              34.23411
            ],
            [
              -90.936988,
              34.227041
            ],
            [
              -90.93522,
              34.21905
            ],
            [
              -90.93268,
              34.214824
            ],
            [
              -90.916048,
              34.196916
            ],
            [
              -90.9118,
              34.193897
            ],
            [
              -90.891871,
              34.184766
            ],
            [
              -90.887884,
              34.18198
            ],
            [
              -90.882701,
              34.184364
            ],
            [
              -90.877475,
              34.185633
            ],
            [
              -90.87383,
              34.18322
            ],
            [
              -90.869651,
              34.182965
            ],
            [
              -90.864566,
              34.183882
            ],
            [
              -90.859087,
              34.186288
            ],
            [
              -90.8556,
              34.18688
            ],
            [
              -90.847108,
              34.186053
            ],
            [
              -90.838205,
              34.183804
            ],
            [
              -90.828388,
              34.184784
            ],
            [
              -90.816572,
              34.183023
            ],
            [
              -90.812374,
              34.180767
            ],
            [
              -90.810016,
              34.178437
            ],
            [
              -90.808685,
              34.175878
            ],
            [
              -90.807164,
              34.16746
            ],
            [
              -90.807813,
              34.161474
            ],
            [
              -90.810884,
              34.155903
            ],
            [
              -90.815878,
              34.149879
            ],
            [
              -90.822593,
              34.144054
            ],
            [
              -90.825708,
              34.142011
            ],
            [
              -90.830285,
              34.139813
            ],
            [
              -90.836099,
              34.137876
            ],
            [
              -90.847168,
              34.136884
            ],
            [
              -90.853471,
              34.137511
            ],
            [
              -90.86458,
              34.140555
            ],
            [
              -90.86788,
              34.142146
            ],
            [
              -90.876836,
              34.14813
            ],
            [
              -90.883073,
              34.151502
            ],
            [
              -90.894385,
              34.160953
            ],
            [
              -90.903577,
              34.164332
            ],
            [
              -90.91001,
              34.165508
            ],
            [
              -90.938064,
              34.148754
            ],
            [
              -90.9543,
              34.138498
            ],
            [
              -90.959317,
              34.13035
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "133",
      "COUNTYNS": "00695787",
      "AFFGEOID": "0500000US28133",
      "GEOID": "28133",
      "NAME": "Sunflower",
      "LSAD": "06",
      "ALAND": 1807187009,
      "AWATER": 23747315,
      "County_state": "Sunflower,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.764484,
              33.445692
            ],
            [
              -90.76412,
              33.372756
            ],
            [
              -90.763773,
              33.270359
            ],
            [
              -90.716277,
              33.270032
            ],
            [
              -90.710217,
              33.270466
            ],
            [
              -90.555211,
              33.270533
            ],
            [
              -90.555549,
              33.329181
            ],
            [
              -90.453233,
              33.329484
            ],
            [
              -90.451389,
              33.708628
            ],
            [
              -90.451643,
              33.81139
            ],
            [
              -90.450529,
              33.908609
            ],
            [
              -90.451012,
              33.985689
            ],
            [
              -90.657002,
              33.987586
            ],
            [
              -90.658224,
              33.852949
            ],
            [
              -90.658627,
              33.784193
            ],
            [
              -90.658791,
              33.70405
            ],
            [
              -90.658579,
              33.689118
            ],
            [
              -90.658719,
              33.616264
            ],
            [
              -90.763469,
              33.616164
            ],
            [
              -90.763403,
              33.599758
            ],
            [
              -90.763514,
              33.55775
            ],
            [
              -90.763509,
              33.529389
            ],
            [
              -90.76095,
              33.526442
            ],
            [
              -90.764484,
              33.445692
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "143",
      "COUNTYNS": "00695792",
      "AFFGEOID": "0500000US28143",
      "GEOID": "28143",
      "NAME": "Tunica",
      "LSAD": "06",
      "ALAND": 1177602383,
      "AWATER": 67538891,
      "County_state": "Tunica,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.588419,
              34.670963
            ],
            [
              -90.588536,
              34.668646
            ],
            [
              -90.587323,
              34.665407
            ],
            [
              -90.586336,
              34.651689
            ],
            [
              -90.585031,
              34.647072
            ],
            [
              -90.583088,
              34.64361
            ],
            [
              -90.58302,
              34.642679
            ],
            [
              -90.5834403324521,
              34.641389257845596
            ],
            [
              -90.588255,
              34.626616
            ],
            [
              -90.587224,
              34.615732
            ],
            [
              -90.581693,
              34.604227
            ],
            [
              -90.574787,
              34.595243
            ],
            [
              -90.570133,
              34.587457
            ],
            [
              -90.564866,
              34.582602
            ],
            [
              -90.557666,
              34.576929
            ],
            [
              -90.549244,
              34.568101
            ],
            [
              -90.545891,
              34.563257
            ],
            [
              -90.540951,
              34.550853
            ],
            [
              -90.540736,
              34.548085
            ],
            [
              -90.541282,
              34.545331
            ],
            [
              -90.543633,
              34.540378
            ],
            [
              -90.545728,
              34.53775
            ],
            [
              -90.555276,
              34.531012
            ],
            [
              -90.569347,
              34.524867
            ],
            [
              -90.399498,
              34.423807
            ],
            [
              -90.301851,
              34.424546
            ],
            [
              -90.304317,
              34.509621
            ],
            [
              -90.198536,
              34.511088
            ],
            [
              -90.198631,
              34.554425
            ],
            [
              -90.218341,
              34.559604
            ],
            [
              -90.234035,
              34.601008
            ],
            [
              -90.243853,
              34.599597
            ],
            [
              -90.239971,
              34.626237
            ],
            [
              -90.216325,
              34.646874
            ],
            [
              -90.224934,
              34.672952
            ],
            [
              -90.220446,
              34.689931
            ],
            [
              -90.231358,
              34.698503
            ],
            [
              -90.222302,
              34.715764
            ],
            [
              -90.200199,
              34.724418
            ],
            [
              -90.202407,
              34.772448
            ],
            [
              -90.202126,
              34.860374
            ],
            [
              -90.3044193945795,
              34.8605888851081
            ],
            [
              -90.303698,
              34.859704
            ],
            [
              -90.302523,
              34.856471
            ],
            [
              -90.302669,
              34.854366
            ],
            [
              -90.303944,
              34.850517
            ],
            [
              -90.307384,
              34.846195
            ],
            [
              -90.311312,
              34.844223
            ],
            [
              -90.319198,
              34.844854
            ],
            [
              -90.323067,
              34.846391
            ],
            [
              -90.332298,
              34.85253
            ],
            [
              -90.34038,
              34.860357
            ],
            [
              -90.348218,
              34.855113
            ],
            [
              -90.352174,
              34.853196
            ],
            [
              -90.379837,
              34.845931
            ],
            [
              -90.401633,
              34.835305
            ],
            [
              -90.4079908833418,
              34.832998481267495
            ],
            [
              -90.410666,
              34.832028
            ],
            [
              -90.414864,
              34.831846
            ],
            [
              -90.422355,
              34.833675
            ],
            [
              -90.423879,
              34.834606
            ],
            [
              -90.428754,
              34.8414
            ],
            [
              -90.430828,
              34.848982
            ],
            [
              -90.431741,
              34.855051
            ],
            [
              -90.431722,
              34.858913
            ],
            [
              -90.429115,
              34.865087
            ],
            [
              -90.42898,
              34.867168
            ],
            [
              -90.430096,
              34.871212
            ],
            [
              -90.436561,
              34.882731
            ],
            [
              -90.438313,
              34.884581
            ],
            [
              -90.441254,
              34.886313
            ],
            [
              -90.444806,
              34.887994
            ],
            [
              -90.453916,
              34.891122
            ],
            [
              -90.459819,
              34.891946
            ],
            [
              -90.466154,
              34.890989
            ],
            [
              -90.475686,
              34.88631
            ],
            [
              -90.479872,
              34.883264
            ],
            [
              -90.483969,
              34.877176
            ],
            [
              -90.484558,
              34.875096
            ],
            [
              -90.485038,
              34.869252
            ],
            [
              -90.483876,
              34.861333
            ],
            [
              -90.481955,
              34.857805
            ],
            [
              -90.474403,
              34.849495
            ],
            [
              -90.463795,
              34.834923
            ],
            [
              -90.456935,
              34.823383
            ],
            [
              -90.456761,
              34.820395
            ],
            [
              -90.459024,
              34.81444
            ],
            [
              -90.465367,
              34.810592
            ],
            [
              -90.470544,
              34.805036
            ],
            [
              -90.47228,
              34.802465
            ],
            [
              -90.473877,
              34.798399
            ],
            [
              -90.47459,
              34.7932
            ],
            [
              -90.473527,
              34.788835
            ],
            [
              -90.470411,
              34.780877
            ],
            [
              -90.458883,
              34.764267
            ],
            [
              -90.453038,
              34.753352
            ],
            [
              -90.45117,
              34.747787
            ],
            [
              -90.451257,
              34.744485
            ],
            [
              -90.452479,
              34.739898
            ],
            [
              -90.454968,
              34.735557
            ],
            [
              -90.45795,
              34.732946
            ],
            [
              -90.469897,
              34.72703
            ],
            [
              -90.479704,
              34.724793
            ],
            [
              -90.488865,
              34.723731
            ],
            [
              -90.501667,
              34.724236
            ],
            [
              -90.514735,
              34.729656
            ],
            [
              -90.518317,
              34.73279
            ],
            [
              -90.521004,
              34.738612
            ],
            [
              -90.5219,
              34.743537
            ],
            [
              -90.5219,
              34.748463
            ],
            [
              -90.520556,
              34.753388
            ],
            [
              -90.516522,
              34.758333
            ],
            [
              -90.505494,
              34.764568
            ],
            [
              -90.501325,
              34.769931
            ],
            [
              -90.500994,
              34.771187
            ],
            [
              -90.501523,
              34.774795
            ],
            [
              -90.503679,
              34.780136
            ],
            [
              -90.512761,
              34.796488
            ],
            [
              -90.514706,
              34.801768
            ],
            [
              -90.522892,
              34.802265
            ],
            [
              -90.53133,
              34.800584
            ],
            [
              -90.53651,
              34.798572
            ],
            [
              -90.540222,
              34.795768
            ],
            [
              -90.544067,
              34.791159
            ],
            [
              -90.547612,
              34.784589
            ],
            [
              -90.54817,
              34.78189
            ],
            [
              -90.547859,
              34.779194
            ],
            [
              -90.546225,
              34.773443
            ],
            [
              -90.542631,
              34.764396
            ],
            [
              -90.542695,
              34.752626
            ],
            [
              -90.543811,
              34.749277
            ],
            [
              -90.54582,
              34.745929
            ],
            [
              -90.547606,
              34.744367
            ],
            [
              -90.550284,
              34.742804
            ],
            [
              -90.553186,
              34.741912
            ],
            [
              -90.556308,
              34.741541
            ],
            [
              -90.559895,
              34.740788
            ],
            [
              -90.563544,
              34.738671
            ],
            [
              -90.565437,
              34.736536
            ],
            [
              -90.56724,
              34.733463
            ],
            [
              -90.568172,
              34.727384
            ],
            [
              -90.568081,
              34.724802
            ],
            [
              -90.567482,
              34.723292
            ],
            [
              -90.565646,
              34.721053
            ],
            [
              -90.546053,
              34.702076
            ],
            [
              -90.538974,
              34.698783
            ],
            [
              -90.529211,
              34.696819
            ],
            [
              -90.522084,
              34.696605
            ],
            [
              -90.509847,
              34.698003
            ],
            [
              -90.496552,
              34.700578
            ],
            [
              -90.486966,
              34.701477
            ],
            [
              -90.480041,
              34.701515
            ],
            [
              -90.475194,
              34.700826
            ],
            [
              -90.471185,
              34.699066
            ],
            [
              -90.467064,
              34.695643
            ],
            [
              -90.463734,
              34.691093
            ],
            [
              -90.462552,
              34.687576
            ],
            [
              -90.462816,
              34.684074
            ],
            [
              -90.466041,
              34.674312
            ],
            [
              -90.469821,
              34.669436
            ],
            [
              -90.479718,
              34.659934
            ],
            [
              -90.48789,
              34.654881
            ],
            [
              -90.496639,
              34.648117
            ],
            [
              -90.503061,
              34.64079
            ],
            [
              -90.5081,
              34.636682
            ],
            [
              -90.517168,
              34.630928
            ],
            [
              -90.524481,
              34.628504
            ],
            [
              -90.532188,
              34.627487
            ],
            [
              -90.537165,
              34.627767
            ],
            [
              -90.543696,
              34.629559
            ],
            [
              -90.547614,
              34.631656
            ],
            [
              -90.551761,
              34.636484
            ],
            [
              -90.554129,
              34.640871
            ],
            [
              -90.555104,
              34.646236
            ],
            [
              -90.553962,
              34.655018
            ],
            [
              -90.552642,
              34.659707
            ],
            [
              -90.550158,
              34.663445
            ],
            [
              -90.539409,
              34.670902
            ],
            [
              -90.552717,
              34.688371
            ],
            [
              -90.573106,
              34.686425
            ],
            [
              -90.578745,
              34.683844
            ],
            [
              -90.582006,
              34.680235
            ],
            [
              -90.588419,
              34.670963
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "089",
      "COUNTYNS": "00695768",
      "AFFGEOID": "0500000US28089",
      "GEOID": "28089",
      "NAME": "Madison",
      "LSAD": "06",
      "ALAND": 1850209209,
      "AWATER": 71667634,
      "County_state": "Madison,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.450037,
              32.573783
            ],
            [
              -90.449864,
              32.488394
            ],
            [
              -90.394259,
              32.487714
            ],
            [
              -90.354129,
              32.487744
            ],
            [
              -90.279875,
              32.487635
            ],
            [
              -90.248092,
              32.487577
            ],
            [
              -90.243764,
              32.400098
            ],
            [
              -90.176566,
              32.400235
            ],
            [
              -90.160183,
              32.400275
            ],
            [
              -90.140723,
              32.399479
            ],
            [
              -90.134441,
              32.399492
            ],
            [
              -90.123488,
              32.399451
            ],
            [
              -90.08589,
              32.399473
            ],
            [
              -90.065697,
              32.399529
            ],
            [
              -90.075445,
              32.418574
            ],
            [
              -90.0629,
              32.419982
            ],
            [
              -90.068068,
              32.438606
            ],
            [
              -90.04066,
              32.441678
            ],
            [
              -90.018595,
              32.473709
            ],
            [
              -90.013819,
              32.480117
            ],
            [
              -89.960643,
              32.491383
            ],
            [
              -89.939758,
              32.513744
            ],
            [
              -89.931403,
              32.512336
            ],
            [
              -89.923261,
              32.52892
            ],
            [
              -89.896112,
              32.541131
            ],
            [
              -89.886312,
              32.56323
            ],
            [
              -89.87508,
              32.559244
            ],
            [
              -89.863104,
              32.575772
            ],
            [
              -89.839985,
              32.593295
            ],
            [
              -89.826885,
              32.584593
            ],
            [
              -89.784123,
              32.586891
            ],
            [
              -89.770913,
              32.60615
            ],
            [
              -89.730304,
              32.634228
            ],
            [
              -89.730016,
              32.885072
            ],
            [
              -89.840423,
              32.885408
            ],
            [
              -89.965882,
              32.879573
            ],
            [
              -89.998123,
              32.831861
            ],
            [
              -90.01743,
              32.827143
            ],
            [
              -90.0173,
              32.803703
            ],
            [
              -90.032469,
              32.759933
            ],
            [
              -90.042441,
              32.744143
            ],
            [
              -90.076054,
              32.729529
            ],
            [
              -90.093625,
              32.707628
            ],
            [
              -90.099623,
              32.700274
            ],
            [
              -90.115597,
              32.704571
            ],
            [
              -90.173776,
              32.679949
            ],
            [
              -90.197729,
              32.66385
            ],
            [
              -90.224016,
              32.660999
            ],
            [
              -90.22631,
              32.668155
            ],
            [
              -90.244689,
              32.658658
            ],
            [
              -90.277813,
              32.660428
            ],
            [
              -90.288887,
              32.641852
            ],
            [
              -90.304069,
              32.645102
            ],
            [
              -90.322146,
              32.616183
            ],
            [
              -90.337048,
              32.605877
            ],
            [
              -90.346203,
              32.613546
            ],
            [
              -90.363788,
              32.603331
            ],
            [
              -90.404875,
              32.574716
            ],
            [
              -90.427777,
              32.585707
            ],
            [
              -90.450037,
              32.573783
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "051",
      "COUNTYNS": "00695749",
      "AFFGEOID": "0500000US28051",
      "GEOID": "28051",
      "NAME": "Holmes",
      "LSAD": "06",
      "ALAND": 1959776825,
      "AWATER": 20568825,
      "County_state": "Holmes,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.43152,
              33.095709
            ],
            [
              -90.429783,
              33.060991
            ],
            [
              -90.415436,
              33.048978
            ],
            [
              -90.391456,
              33.04999
            ],
            [
              -90.379756,
              33.042074
            ],
            [
              -90.382837,
              33.025903
            ],
            [
              -90.364031,
              33.010596
            ],
            [
              -90.354911,
              33.01198
            ],
            [
              -90.346221,
              33.022385
            ],
            [
              -90.338339,
              33.010131
            ],
            [
              -90.173932,
              32.952697
            ],
            [
              -90.170202,
              32.951375
            ],
            [
              -89.965882,
              32.879573
            ],
            [
              -89.896363,
              32.932602
            ],
            [
              -89.884414,
              32.973132
            ],
            [
              -89.873556,
              33.001009
            ],
            [
              -89.836363,
              33.039984
            ],
            [
              -89.833411,
              33.06237
            ],
            [
              -89.824182,
              33.064142
            ],
            [
              -89.827068,
              33.089005
            ],
            [
              -89.819328,
              33.100114
            ],
            [
              -89.82487,
              33.110215
            ],
            [
              -89.806257,
              33.112882
            ],
            [
              -89.793834,
              33.130716
            ],
            [
              -89.793929,
              33.159474
            ],
            [
              -89.783323,
              33.162368
            ],
            [
              -89.774276,
              33.176869
            ],
            [
              -89.776694,
              33.193801
            ],
            [
              -89.759426,
              33.195106
            ],
            [
              -89.748572,
              33.211553
            ],
            [
              -89.749441,
              33.214478
            ],
            [
              -89.889573,
              33.252977
            ],
            [
              -89.914941,
              33.260125
            ],
            [
              -89.92704,
              33.263535
            ],
            [
              -90.174614,
              33.333378
            ],
            [
              -90.321308,
              33.37505
            ],
            [
              -90.321321,
              33.36567
            ],
            [
              -90.295029,
              33.361784
            ],
            [
              -90.279627,
              33.339509
            ],
            [
              -90.286439,
              33.312677
            ],
            [
              -90.305702,
              33.310252
            ],
            [
              -90.309497,
              33.32041
            ],
            [
              -90.331589,
              33.302825
            ],
            [
              -90.330491,
              33.186684
            ],
            [
              -90.330567,
              33.135352
            ],
            [
              -90.346924,
              33.132024
            ],
            [
              -90.355459,
              33.113145
            ],
            [
              -90.347004,
              33.101191
            ],
            [
              -90.35928,
              33.094079
            ],
            [
              -90.368855,
              33.125467
            ],
            [
              -90.38629,
              33.112805
            ],
            [
              -90.36546,
              33.095533
            ],
            [
              -90.37296,
              33.084073
            ],
            [
              -90.384934,
              33.087334
            ],
            [
              -90.393411,
              33.101737
            ],
            [
              -90.416287,
              33.104058
            ],
            [
              -90.41846,
              33.114682
            ],
            [
              -90.43152,
              33.095709
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "091",
      "COUNTYNS": "00695769",
      "AFFGEOID": "0500000US28091",
      "GEOID": "28091",
      "NAME": "Marion",
      "LSAD": "06",
      "ALAND": 1404769593,
      "AWATER": 16149942,
      "County_state": "Marion,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.040485,
              31.394673
            ],
            [
              -90.040466,
              31.336014
            ],
            [
              -90.040386,
              31.271847
            ],
            [
              -90.040375,
              31.23391
            ],
            [
              -90.031857,
              31.226844
            ],
            [
              -90.031896,
              31.175727
            ],
            [
              -90.006138,
              31.175605
            ],
            [
              -90.005936,
              31.1323
            ],
            [
              -89.938178,
              31.132949
            ],
            [
              -89.938242,
              31.111158
            ],
            [
              -89.92952,
              31.111167
            ],
            [
              -89.929501,
              31.089229
            ],
            [
              -89.920944,
              31.074677
            ],
            [
              -89.835873,
              31.074926
            ],
            [
              -89.83590796037879,
              31.002059274641898
            ],
            [
              -89.835542,
              31.002059
            ],
            [
              -89.824617,
              31.00206
            ],
            [
              -89.816429,
              31.002084
            ],
            [
              -89.752642,
              31.001853
            ],
            [
              -89.752133,
              31.000183
            ],
            [
              -89.751481,
              30.99969
            ],
            [
              -89.749189,
              30.999555
            ],
            [
              -89.747229,
              31.000184
            ],
            [
              -89.745215,
              31.002252
            ],
            [
              -89.741821,
              31.003441
            ],
            [
              -89.732504,
              31.004831
            ],
            [
              -89.729616,
              31.003927
            ],
            [
              -89.728147,
              31.002431
            ],
            [
              -89.7281451296188,
              31.002299627985096
            ],
            [
              -89.654038,
              31.002502
            ],
            [
              -89.653754,
              31.127737
            ],
            [
              -89.653803,
              31.212195
            ],
            [
              -89.653998,
              31.229582
            ],
            [
              -89.6538,
              31.283134
            ],
            [
              -89.654212,
              31.433805
            ],
            [
              -89.670969,
              31.433838
            ],
            [
              -89.824236,
              31.434093
            ],
            [
              -89.823332,
              31.390021
            ],
            [
              -89.95894,
              31.390489
            ],
            [
              -89.975904,
              31.394587
            ],
            [
              -90.040485,
              31.394673
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "161",
      "COUNTYNS": "00695801",
      "AFFGEOID": "0500000US28161",
      "GEOID": "28161",
      "NAME": "Yalobusha",
      "LSAD": "06",
      "ALAND": 1209858146,
      "AWATER": 72197127,
      "County_state": "Yalobusha,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.931485,
              34.164303
            ],
            [
              -89.925497,
              34.140553
            ],
            [
              -89.926172,
              34.057624
            ],
            [
              -89.926099,
              33.897957
            ],
            [
              -89.769296,
              33.897137
            ],
            [
              -89.76905,
              33.867334
            ],
            [
              -89.717329,
              33.867142
            ],
            [
              -89.507353,
              33.867469
            ],
            [
              -89.50738,
              34.020541
            ],
            [
              -89.509748,
              34.162082
            ],
            [
              -89.50944,
              34.19096
            ],
            [
              -89.526874,
              34.191003
            ],
            [
              -89.72105,
              34.192545
            ],
            [
              -89.721431,
              34.163513
            ],
            [
              -89.931485,
              34.164303
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "055",
      "COUNTYNS": "00695751",
      "AFFGEOID": "0500000US28055",
      "GEOID": "28055",
      "NAME": "Issaquena",
      "LSAD": "06",
      "ALAND": 1069782900,
      "AWATER": 73507441,
      "County_state": "Issaquena,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.214027,
              32.93032
            ],
            [
              -91.213972,
              32.927198
            ],
            [
              -91.212837,
              32.922104
            ],
            [
              -91.211597,
              32.919624
            ],
            [
              -91.208263,
              32.915354
            ],
            [
              -91.199775,
              32.908512
            ],
            [
              -91.196785,
              32.906784
            ],
            [
              -91.181631,
              32.901446
            ],
            [
              -91.175405,
              32.899998
            ],
            [
              -91.170235,
              32.899391
            ],
            [
              -91.159975,
              32.899879
            ],
            [
              -91.15169,
              32.901935
            ],
            [
              -91.145076,
              32.905494
            ],
            [
              -91.134041,
              32.917676
            ],
            [
              -91.132115,
              32.923122
            ],
            [
              -91.131304,
              32.926919
            ],
            [
              -91.131289,
              32.930049
            ],
            [
              -91.135507,
              32.946164
            ],
            [
              -91.137863,
              32.952756
            ],
            [
              -91.137167,
              32.95652
            ],
            [
              -91.136628,
              32.957349
            ],
            [
              -91.133335,
              32.959056
            ],
            [
              -91.131243,
              32.960928
            ],
            [
              -91.130721,
              32.962257
            ],
            [
              -91.130947,
              32.963815
            ],
            [
              -91.13305,
              32.966541
            ],
            [
              -91.137524,
              32.96955
            ],
            [
              -91.138585,
              32.971352
            ],
            [
              -91.135517,
              32.979657
            ],
            [
              -91.134414,
              32.980533
            ],
            [
              -91.125107,
              32.984669
            ],
            [
              -91.111757,
              32.988361
            ],
            [
              -91.106581,
              32.988938
            ],
            [
              -91.09693,
              32.986412
            ],
            [
              -91.094265,
              32.984371
            ],
            [
              -91.090887,
              32.981174
            ],
            [
              -91.086802,
              32.976266
            ],
            [
              -91.080355,
              32.962794
            ],
            [
              -91.078904,
              32.951818
            ],
            [
              -91.080507,
              32.950123
            ],
            [
              -91.081892,
              32.949435
            ],
            [
              -91.083084,
              32.947909
            ],
            [
              -91.081913,
              32.944768
            ],
            [
              -91.080431,
              32.943206
            ],
            [
              -91.072075,
              32.937832
            ],
            [
              -91.070547,
              32.936036
            ],
            [
              -91.064804,
              32.926464
            ],
            [
              -91.063875,
              32.922692
            ],
            [
              -91.064854,
              32.91652
            ],
            [
              -91.063684,
              32.906364
            ],
            [
              -91.0637985738819,
              32.903930450747694
            ],
            [
              -91.063809,
              32.903709
            ],
            [
              -91.064449,
              32.901064
            ],
            [
              -91.068186,
              32.891929
            ],
            [
              -91.070602,
              32.888659
            ],
            [
              -91.086683,
              32.873392
            ],
            [
              -91.105631,
              32.858396
            ],
            [
              -91.116091,
              32.855641
            ],
            [
              -91.124725,
              32.854861
            ],
            [
              -91.127886,
              32.855059
            ],
            [
              -91.13789,
              32.848975
            ],
            [
              -91.143559,
              32.844739
            ],
            [
              -91.145002,
              32.84287
            ],
            [
              -91.149704,
              32.83122
            ],
            [
              -91.152174,
              32.826673
            ],
            [
              -91.157155,
              32.823823
            ],
            [
              -91.158336,
              32.822304
            ],
            [
              -91.161669,
              32.812465
            ],
            [
              -91.161412,
              32.800004
            ],
            [
              -91.164397,
              32.785821
            ],
            [
              -91.158583,
              32.781096
            ],
            [
              -91.156918,
              32.780343
            ],
            [
              -91.157614,
              32.776033
            ],
            [
              -91.164968,
              32.761984
            ],
            [
              -91.165814,
              32.757842
            ],
            [
              -91.165328,
              32.751301
            ],
            [
              -91.163389,
              32.747009
            ],
            [
              -91.15861,
              32.743449
            ],
            [
              -91.154461,
              32.742339
            ],
            [
              -91.150244,
              32.741786
            ],
            [
              -91.131691,
              32.743107
            ],
            [
              -91.123152,
              32.742798
            ],
            [
              -91.113652,
              32.73997
            ],
            [
              -91.090573,
              32.7361
            ],
            [
              -91.077176,
              32.732534
            ],
            [
              -91.060766,
              32.727494
            ],
            [
              -91.056999,
              32.72558
            ],
            [
              -91.054481,
              32.722259
            ],
            [
              -91.054749,
              32.719229
            ],
            [
              -91.057043,
              32.712576
            ],
            [
              -91.063946,
              32.702926
            ],
            [
              -91.076061,
              32.693751
            ],
            [
              -91.081145,
              32.691127
            ],
            [
              -91.098762,
              32.685291
            ],
            [
              -91.104443,
              32.682434
            ],
            [
              -91.118258,
              32.674075
            ],
            [
              -91.127723,
              32.665343
            ],
            [
              -91.130928,
              32.65887
            ],
            [
              -91.137638,
              32.650621
            ],
            [
              -91.138712,
              32.649774
            ],
            [
              -91.142038,
              32.649265
            ],
            [
              -91.144347,
              32.648029
            ],
            [
              -91.149753,
              32.644041
            ],
            [
              -91.152081,
              32.641508
            ],
            [
              -91.152699,
              32.640757
            ],
            [
              -91.153744,
              32.635228
            ],
            [
              -91.153821,
              32.631282
            ],
            [
              -91.153556,
              32.626181
            ],
            [
              -91.151318,
              32.615919
            ],
            [
              -91.146204,
              32.604144
            ],
            [
              -91.144074,
              32.600613
            ],
            [
              -91.141148,
              32.597209
            ],
            [
              -91.13528,
              32.591651
            ],
            [
              -91.127912,
              32.586493
            ],
            [
              -91.125108,
              32.585187
            ],
            [
              -91.119854,
              32.584795
            ],
            [
              -91.118641,
              32.585139
            ],
            [
              -91.112764,
              32.590186
            ],
            [
              -91.105704,
              32.590879
            ],
            [
              -91.087784,
              32.59707
            ],
            [
              -91.079506,
              32.60068
            ],
            [
              -91.061354,
              32.605372
            ],
            [
              -91.049796,
              32.607188
            ],
            [
              -91.0489,
              32.613556
            ],
            [
              -91.043263,
              32.620779
            ],
            [
              -91.041448,
              32.625135
            ],
            [
              -91.040401,
              32.632521
            ],
            [
              -91.038415,
              32.636443
            ],
            [
              -91.030181,
              32.644052
            ],
            [
              -91.025769,
              32.646573
            ],
            [
              -91.019115,
              32.643845
            ],
            [
              -91.014286,
              32.640482
            ],
            [
              -91.00682,
              32.631261
            ],
            [
              -91.0035,
              32.624845
            ],
            [
              -91.002962,
              32.622555
            ],
            [
              -91.002997,
              32.614678
            ],
            [
              -91.004599,
              32.60978
            ],
            [
              -91.010228,
              32.601927
            ],
            [
              -91.013723,
              32.598419
            ],
            [
              -91.016231,
              32.596953
            ],
            [
              -91.022943,
              32.591848
            ],
            [
              -91.030991,
              32.583347
            ],
            [
              -91.03617,
              32.579556
            ],
            [
              -91.0434509306581,
              32.576269553442096
            ],
            [
              -90.964043,
              32.575605
            ],
            [
              -90.963381,
              32.411185
            ],
            [
              -90.95124,
              32.444389
            ],
            [
              -90.926476,
              32.450579
            ],
            [
              -90.915712,
              32.444141
            ],
            [
              -90.894745,
              32.448602
            ],
            [
              -90.881466,
              32.440768
            ],
            [
              -90.869784,
              32.451315
            ],
            [
              -90.861379,
              32.442768
            ],
            [
              -90.860566,
              32.575173
            ],
            [
              -90.768081,
              32.574751
            ],
            [
              -90.73952,
              32.571773
            ],
            [
              -90.728344,
              32.566265
            ],
            [
              -90.731699,
              32.587276
            ],
            [
              -90.722726,
              32.592118
            ],
            [
              -90.716399,
              32.611578
            ],
            [
              -90.720102,
              32.618858
            ],
            [
              -90.697563,
              32.625135
            ],
            [
              -90.691405,
              32.636284
            ],
            [
              -90.712819,
              32.642346
            ],
            [
              -90.723159,
              32.662128
            ],
            [
              -90.964864,
              32.662798
            ],
            [
              -90.963347,
              32.838248
            ],
            [
              -90.956024,
              32.852905
            ],
            [
              -90.940177,
              32.862068
            ],
            [
              -90.940155,
              32.885838
            ],
            [
              -90.928652,
              32.907857
            ],
            [
              -90.91404,
              32.944198
            ],
            [
              -90.914337,
              33.007697
            ],
            [
              -90.965433,
              33.010545
            ],
            [
              -91.166282,
              33.011331
            ],
            [
              -91.166073,
              33.004106
            ],
            [
              -91.166195,
              33.002238
            ],
            [
              -91.168973,
              32.992132
            ],
            [
              -91.173308,
              32.986088
            ],
            [
              -91.182158,
              32.978639
            ],
            [
              -91.186983,
              32.976194
            ],
            [
              -91.197433,
              32.96482
            ],
            [
              -91.199646,
              32.963561
            ],
            [
              -91.201842,
              32.961212
            ],
            [
              -91.20203,
              32.957332
            ],
            [
              -91.20119,
              32.954982
            ],
            [
              -91.199415,
              32.952314
            ],
            [
              -91.20744,
              32.944393
            ],
            [
              -91.210705,
              32.939845
            ],
            [
              -91.21282,
              32.936076
            ],
            [
              -91.214027,
              32.93032
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "031",
      "COUNTYNS": "00695740",
      "AFFGEOID": "0500000US28031",
      "GEOID": "28031",
      "NAME": "Covington",
      "LSAD": "06",
      "ALAND": 1071727825,
      "AWATER": 2937796,
      "County_state": "Covington,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.75557,
              31.641995
            ],
            [
              -89.755614,
              31.609751
            ],
            [
              -89.730805,
              31.607081
            ],
            [
              -89.717027,
              31.58892
            ],
            [
              -89.672328,
              31.564492
            ],
            [
              -89.639461,
              31.559492
            ],
            [
              -89.599331,
              31.534647
            ],
            [
              -89.597372,
              31.515136
            ],
            [
              -89.586919,
              31.513444
            ],
            [
              -89.586819,
              31.433688
            ],
            [
              -89.451616,
              31.434029
            ],
            [
              -89.403872,
              31.434023
            ],
            [
              -89.399178,
              31.434029
            ],
            [
              -89.401227,
              31.796863
            ],
            [
              -89.653025,
              31.780653
            ],
            [
              -89.755103,
              31.774654
            ],
            [
              -89.755217,
              31.728324
            ],
            [
              -89.75557,
              31.641995
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "153",
      "COUNTYNS": "00695797",
      "AFFGEOID": "0500000US28153",
      "GEOID": "28153",
      "NAME": "Wayne",
      "LSAD": "06",
      "ALAND": 2099745578,
      "AWATER": 7255476,
      "County_state": "Wayne,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.943169,
              31.784214
            ],
            [
              -88.943468,
              31.433458
            ],
            [
              -88.841707,
              31.433703
            ],
            [
              -88.63578,
              31.434181
            ],
            [
              -88.4495695963996,
              31.4358347375977
            ],
            [
              -88.451045,
              31.459448
            ],
            [
              -88.451575,
              31.481533
            ],
            [
              -88.453013,
              31.500164
            ],
            [
              -88.459478,
              31.621652
            ],
            [
              -88.459722,
              31.624002
            ],
            [
              -88.464425,
              31.697881
            ],
            [
              -88.46442697928089,
              31.697951902948798
            ],
            [
              -88.465107,
              31.722312
            ],
            [
              -88.468669,
              31.790722
            ],
            [
              -88.471106,
              31.850949
            ],
            [
              -88.471214,
              31.851385
            ],
            [
              -88.472642,
              31.875153
            ],
            [
              -88.473227,
              31.893856
            ],
            [
              -88.561731,
              31.872051
            ],
            [
              -88.625036,
              31.856689
            ],
            [
              -88.763411,
              31.835934
            ],
            [
              -88.809174,
              31.832897
            ],
            [
              -88.910459,
              31.826649
            ],
            [
              -88.943355,
              31.824565
            ],
            [
              -88.943169,
              31.784214
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "157",
      "COUNTYNS": "00695799",
      "AFFGEOID": "0500000US28157",
      "GEOID": "28157",
      "NAME": "Wilkinson",
      "LSAD": "06",
      "ALAND": 1756287150,
      "AWATER": 25019144,
      "County_state": "Wilkinson,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.625118,
              31.005374
            ],
            [
              -91.636942,
              30.999416
            ],
            [
              -91.625118,
              30.999167
            ],
            [
              -91.538727,
              30.999388
            ],
            [
              -91.500116,
              30.998691
            ],
            [
              -91.49739,
              30.999006
            ],
            [
              -91.425749,
              30.999007
            ],
            [
              -91.423621,
              30.998984
            ],
            [
              -91.39651504144929,
              30.999058983598
            ],
            [
              -91.24649,
              30.999474
            ],
            [
              -91.224839,
              30.999183
            ],
            [
              -91.224068,
              30.999183
            ],
            [
              -91.176209,
              30.999144
            ],
            [
              -91.17613975227569,
              30.9991437308313
            ],
            [
              -91.108291,
              30.99888
            ],
            [
              -91.108114,
              30.998857
            ],
            [
              -91.080814,
              30.998909
            ],
            [
              -91.06827,
              30.99892
            ],
            [
              -91.0602129522528,
              30.9989529636044
            ],
            [
              -91.059355,
              31.245723
            ],
            [
              -91.059469,
              31.281921
            ],
            [
              -91.079847,
              31.288774
            ],
            [
              -91.095398,
              31.320975
            ],
            [
              -91.117967,
              31.320314
            ],
            [
              -91.15815,
              31.346695
            ],
            [
              -91.186857,
              31.349281
            ],
            [
              -91.213055,
              31.3571
            ],
            [
              -91.231134,
              31.37317
            ],
            [
              -91.257134,
              31.360127
            ],
            [
              -91.272869,
              31.368243
            ],
            [
              -91.283005,
              31.357778
            ],
            [
              -91.306294,
              31.360862
            ],
            [
              -91.330855,
              31.351776
            ],
            [
              -91.341691,
              31.340034
            ],
            [
              -91.355341,
              31.343587
            ],
            [
              -91.36665,
              31.335922
            ],
            [
              -91.384256,
              31.33542
            ],
            [
              -91.391738,
              31.324801
            ],
            [
              -91.413048,
              31.323868
            ],
            [
              -91.424012,
              31.31504
            ],
            [
              -91.468435,
              31.31957
            ],
            [
              -91.47106,
              31.285607
            ],
            [
              -91.49487,
              31.284334
            ],
            [
              -91.497369,
              31.276412
            ],
            [
              -91.477301,
              31.259876
            ],
            [
              -91.478799,
              31.238999
            ],
            [
              -91.487057,
              31.227471
            ],
            [
              -91.50729,
              31.225977
            ],
            [
              -91.530126,
              31.214842
            ],
            [
              -91.53037,
              31.230353
            ],
            [
              -91.540907,
              31.238346
            ],
            [
              -91.560538,
              31.235604
            ],
            [
              -91.57567,
              31.224475
            ],
            [
              -91.5894512930811,
              31.1911399311563
            ],
            [
              -91.588939,
              31.188959
            ],
            [
              -91.588695,
              31.181025
            ],
            [
              -91.589046,
              31.178586
            ],
            [
              -91.591502,
              31.173118
            ],
            [
              -91.597062,
              31.163492
            ],
            [
              -91.599732,
              31.159592
            ],
            [
              -91.604197,
              31.154545
            ],
            [
              -91.621671,
              31.13687
            ],
            [
              -91.624217,
              31.133729
            ],
            [
              -91.625118,
              31.131879
            ],
            [
              -91.625707,
              31.128763
            ],
            [
              -91.626476,
              31.119125
            ],
            [
              -91.625994,
              31.116896
            ],
            [
              -91.621535,
              31.110257
            ],
            [
              -91.61857,
              31.107328
            ],
            [
              -91.614763,
              31.104357
            ],
            [
              -91.609523,
              31.101557
            ],
            [
              -91.594693,
              31.091444
            ],
            [
              -91.567648,
              31.070186
            ],
            [
              -91.56415,
              31.06683
            ],
            [
              -91.561283,
              31.060906
            ],
            [
              -91.559907,
              31.054119
            ],
            [
              -91.560365,
              31.049508
            ],
            [
              -91.561232,
              31.046225
            ],
            [
              -91.564397,
              31.038965
            ],
            [
              -91.571695,
              31.029782
            ],
            [
              -91.578413,
              31.02403
            ],
            [
              -91.590463,
              31.01727
            ],
            [
              -91.60649,
              31.011216
            ],
            [
              -91.625118,
              31.005374
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "021",
      "COUNTYNS": "00695735",
      "AFFGEOID": "0500000US28021",
      "GEOID": "28021",
      "NAME": "Claiborne",
      "LSAD": "06",
      "ALAND": 1262400024,
      "AWATER": 35711133,
      "County_state": "Claiborne,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.234899,
              31.876863
            ],
            [
              -91.248144,
              31.869848
            ],
            [
              -91.013676,
              31.861852
            ],
            [
              -90.921189,
              31.842688
            ],
            [
              -90.842,
              31.826498
            ],
            [
              -90.840394,
              31.804097
            ],
            [
              -90.852657,
              31.785422
            ],
            [
              -90.736282,
              31.786134
            ],
            [
              -90.733,
              31.829976
            ],
            [
              -90.716785,
              31.844583
            ],
            [
              -90.716406,
              31.904197
            ],
            [
              -90.714405,
              31.977629
            ],
            [
              -90.718297,
              32.048352
            ],
            [
              -90.728622,
              32.225119
            ],
            [
              -90.739199,
              32.216047
            ],
            [
              -90.73849,
              32.205577
            ],
            [
              -90.75435,
              32.206197
            ],
            [
              -90.750398,
              32.19697
            ],
            [
              -90.777187,
              32.193587
            ],
            [
              -90.79005,
              32.174716
            ],
            [
              -90.780695,
              32.171882
            ],
            [
              -90.77482,
              32.153993
            ],
            [
              -90.792869,
              32.151566
            ],
            [
              -90.792392,
              32.144223
            ],
            [
              -90.803323,
              32.152577
            ],
            [
              -90.819237,
              32.137276
            ],
            [
              -90.829654,
              32.144857
            ],
            [
              -90.826108,
              32.156273
            ],
            [
              -90.843843,
              32.159039
            ],
            [
              -90.858011,
              32.143574
            ],
            [
              -90.843787,
              32.138616
            ],
            [
              -90.846115,
              32.128174
            ],
            [
              -90.870458,
              32.119977
            ],
            [
              -90.875104,
              32.127612
            ],
            [
              -90.882558,
              32.114534
            ],
            [
              -90.90774,
              32.131888
            ],
            [
              -90.921733,
              32.117844
            ],
            [
              -90.92432,
              32.097477
            ],
            [
              -90.913834,
              32.096061
            ],
            [
              -90.917952,
              32.080684
            ],
            [
              -90.942925,
              32.078337
            ],
            [
              -90.957699,
              32.090339
            ],
            [
              -90.96301,
              32.105321
            ],
            [
              -90.97034,
              32.094612
            ],
            [
              -90.990299,
              32.096986
            ],
            [
              -91.0307064962793,
              32.114336885917304
            ],
            [
              -91.030507,
              32.108153
            ],
            [
              -91.034707,
              32.101053
            ],
            [
              -91.038607,
              32.098254
            ],
            [
              -91.066679,
              32.08533
            ],
            [
              -91.080008,
              32.079154
            ],
            [
              -91.081508,
              32.077754
            ],
            [
              -91.081708,
              32.075754
            ],
            [
              -91.079108,
              32.050255
            ],
            [
              -91.080208,
              32.048355
            ],
            [
              -91.082308,
              32.047555
            ],
            [
              -91.098708,
              32.048355
            ],
            [
              -91.103708,
              32.050255
            ],
            [
              -91.114309,
              32.058255
            ],
            [
              -91.124109,
              32.071854
            ],
            [
              -91.128609,
              32.076554
            ],
            [
              -91.134909,
              32.080354
            ],
            [
              -91.139309,
              32.081754
            ],
            [
              -91.14511,
              32.081354
            ],
            [
              -91.14881,
              32.080154
            ],
            [
              -91.15581,
              32.075554
            ],
            [
              -91.16031,
              32.070354
            ],
            [
              -91.16161,
              32.067754
            ],
            [
              -91.16201,
              32.065354
            ],
            [
              -91.16201,
              32.062355
            ],
            [
              -91.16131,
              32.059755
            ],
            [
              -91.15901,
              32.055955
            ],
            [
              -91.15631,
              32.052655
            ],
            [
              -91.15141,
              32.049255
            ],
            [
              -91.14511,
              32.046555
            ],
            [
              -91.125109,
              32.042855
            ],
            [
              -91.095508,
              32.037455
            ],
            [
              -91.088108,
              32.034455
            ],
            [
              -91.084408,
              32.031456
            ],
            [
              -91.082608,
              32.028656
            ],
            [
              -91.080808,
              32.023456
            ],
            [
              -91.079928,
              32.018316
            ],
            [
              -91.075908,
              32.016828
            ],
            [
              -91.090105,
              32.000157
            ],
            [
              -91.096108,
              31.994857
            ],
            [
              -91.104108,
              31.990357
            ],
            [
              -91.117409,
              31.987057
            ],
            [
              -91.16441,
              31.982557
            ],
            [
              -91.17021,
              31.979057
            ],
            [
              -91.17741,
              31.973257
            ],
            [
              -91.18481,
              31.965557
            ],
            [
              -91.18831,
              31.960958
            ],
            [
              -91.18911,
              31.957458
            ],
            [
              -91.18881,
              31.953158
            ],
            [
              -91.18951,
              31.946358
            ],
            [
              -91.19321,
              31.935658
            ],
            [
              -91.19291,
              31.934958
            ],
            [
              -91.19111,
              31.934158
            ],
            [
              -91.18471,
              31.935058
            ],
            [
              -91.18371,
              31.933158
            ],
            [
              -91.18491,
              31.923759
            ],
            [
              -91.18111,
              31.920059
            ],
            [
              -91.18061,
              31.917959
            ],
            [
              -91.18321,
              31.916159
            ],
            [
              -91.18921,
              31.914259
            ],
            [
              -91.19081,
              31.912959
            ],
            [
              -91.19261,
              31.910159
            ],
            [
              -91.19381,
              31.909559
            ],
            [
              -91.19751,
              31.908659
            ],
            [
              -91.20101,
              31.909159
            ],
            [
              -91.20281,
              31.907959
            ],
            [
              -91.20511,
              31.904159
            ],
            [
              -91.20881,
              31.900459
            ],
            [
              -91.226951,
              31.885105
            ],
            [
              -91.234899,
              31.876863
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "023",
      "COUNTYNS": "00695736",
      "AFFGEOID": "0500000US28023",
      "GEOID": "28023",
      "NAME": "Clarke",
      "LSAD": "06",
      "ALAND": 1791177222,
      "AWATER": 5069761,
      "County_state": "Clarke,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.913847,
              32.224186
            ],
            [
              -88.91356,
              32.108454
            ],
            [
              -88.91294,
              32.049361
            ],
            [
              -88.911735,
              31.962774
            ],
            [
              -88.911583,
              31.934402
            ],
            [
              -88.910459,
              31.826649
            ],
            [
              -88.809174,
              31.832897
            ],
            [
              -88.763411,
              31.835934
            ],
            [
              -88.625036,
              31.856689
            ],
            [
              -88.561731,
              31.872051
            ],
            [
              -88.473227,
              31.893856
            ],
            [
              -88.468879,
              31.930262
            ],
            [
              -88.46866,
              31.933173
            ],
            [
              -88.455039,
              32.039719
            ],
            [
              -88.454959,
              32.040576
            ],
            [
              -88.4534176891327,
              32.0530497191012
            ],
            [
              -88.443049750597,
              32.1369567074276
            ],
            [
              -88.43871,
              32.172078
            ],
            [
              -88.43865,
              32.172806
            ],
            [
              -88.4338373824385,
              32.2086904392936
            ],
            [
              -88.43129401638389,
              32.2276546024792
            ],
            [
              -88.623369,
              32.226373
            ],
            [
              -88.913847,
              32.224186
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "035",
      "COUNTYNS": "00695742",
      "AFFGEOID": "0500000US28035",
      "GEOID": "28035",
      "NAME": "Forrest",
      "LSAD": "06",
      "ALAND": 1207042799,
      "AWATER": 10872920,
      "County_state": "Forrest,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.451616,
              31.434029
            ],
            [
              -89.451008,
              31.361141
            ],
            [
              -89.451073,
              31.346383
            ],
            [
              -89.398983,
              31.346539
            ],
            [
              -89.352644,
              31.346566
            ],
            [
              -89.347863,
              31.3467
            ],
            [
              -89.347891,
              31.32498
            ],
            [
              -89.347884,
              31.259176
            ],
            [
              -89.348868,
              31.166306
            ],
            [
              -89.349038,
              31.082508
            ],
            [
              -89.34895,
              31.01037
            ],
            [
              -89.340701,
              31.001033
            ],
            [
              -89.340806,
              30.909912
            ],
            [
              -89.198684,
              30.909925
            ],
            [
              -89.171076,
              30.909872
            ],
            [
              -89.137967,
              30.909877
            ],
            [
              -89.138154,
              31.000185
            ],
            [
              -89.146176,
              31.000447
            ],
            [
              -89.146104,
              31.344974
            ],
            [
              -89.146092,
              31.434052
            ],
            [
              -89.299027,
              31.434149
            ],
            [
              -89.319762,
              31.434045
            ],
            [
              -89.399178,
              31.434029
            ],
            [
              -89.403872,
              31.434023
            ],
            [
              -89.451616,
              31.434029
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "163",
      "COUNTYNS": "00695802",
      "AFFGEOID": "0500000US28163",
      "GEOID": "28163",
      "NAME": "Yazoo",
      "LSAD": "06",
      "ALAND": 2387955940,
      "AWATER": 31503956,
      "County_state": "Yazoo,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.759753,
              32.835763
            ],
            [
              -90.759635,
              32.814006
            ],
            [
              -90.741016,
              32.809132
            ],
            [
              -90.723389,
              32.818882
            ],
            [
              -90.694289,
              32.814926
            ],
            [
              -90.709499,
              32.806884
            ],
            [
              -90.715415,
              32.789861
            ],
            [
              -90.70767,
              32.777785
            ],
            [
              -90.689758,
              32.765881
            ],
            [
              -90.693061,
              32.754297
            ],
            [
              -90.717628,
              32.747993
            ],
            [
              -90.736123,
              32.755996
            ],
            [
              -90.742634,
              32.729225
            ],
            [
              -90.713735,
              32.707313
            ],
            [
              -90.693271,
              32.706523
            ],
            [
              -90.664408,
              32.689129
            ],
            [
              -90.670064,
              32.669581
            ],
            [
              -90.684171,
              32.663747
            ],
            [
              -90.695601,
              32.685119
            ],
            [
              -90.708028,
              32.690319
            ],
            [
              -90.723159,
              32.662128
            ],
            [
              -90.712819,
              32.642346
            ],
            [
              -90.691405,
              32.636284
            ],
            [
              -90.697563,
              32.625135
            ],
            [
              -90.720102,
              32.618858
            ],
            [
              -90.689946,
              32.618658
            ],
            [
              -90.690249,
              32.589388
            ],
            [
              -90.655527,
              32.589771
            ],
            [
              -90.655356,
              32.580003
            ],
            [
              -90.654563,
              32.546042
            ],
            [
              -90.588019,
              32.546067
            ],
            [
              -90.587925,
              32.531756
            ],
            [
              -90.553904,
              32.531907
            ],
            [
              -90.553821,
              32.507574
            ],
            [
              -90.522217,
              32.50311
            ],
            [
              -90.523735,
              32.518831
            ],
            [
              -90.465137,
              32.547656
            ],
            [
              -90.450037,
              32.573783
            ],
            [
              -90.427777,
              32.585707
            ],
            [
              -90.404875,
              32.574716
            ],
            [
              -90.363788,
              32.603331
            ],
            [
              -90.346203,
              32.613546
            ],
            [
              -90.337048,
              32.605877
            ],
            [
              -90.322146,
              32.616183
            ],
            [
              -90.304069,
              32.645102
            ],
            [
              -90.288887,
              32.641852
            ],
            [
              -90.277813,
              32.660428
            ],
            [
              -90.244689,
              32.658658
            ],
            [
              -90.22631,
              32.668155
            ],
            [
              -90.224016,
              32.660999
            ],
            [
              -90.197729,
              32.66385
            ],
            [
              -90.173776,
              32.679949
            ],
            [
              -90.115597,
              32.704571
            ],
            [
              -90.099623,
              32.700274
            ],
            [
              -90.093625,
              32.707628
            ],
            [
              -90.076054,
              32.729529
            ],
            [
              -90.042441,
              32.744143
            ],
            [
              -90.032469,
              32.759933
            ],
            [
              -90.0173,
              32.803703
            ],
            [
              -90.01743,
              32.827143
            ],
            [
              -89.998123,
              32.831861
            ],
            [
              -89.965882,
              32.879573
            ],
            [
              -90.170202,
              32.951375
            ],
            [
              -90.173932,
              32.952697
            ],
            [
              -90.338339,
              33.010131
            ],
            [
              -90.346221,
              33.022385
            ],
            [
              -90.354911,
              33.01198
            ],
            [
              -90.364031,
              33.010596
            ],
            [
              -90.371242,
              33.009766
            ],
            [
              -90.449323,
              33.00901
            ],
            [
              -90.449893,
              32.92329
            ],
            [
              -90.656632,
              32.92251
            ],
            [
              -90.759753,
              32.835763
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "047",
      "COUNTYNS": "00695747",
      "AFFGEOID": "0500000US28047",
      "GEOID": "28047",
      "NAME": "Harrison",
      "LSAD": "06",
      "ALAND": 1486713863,
      "AWATER": 1063618187,
      "County_state": "Harrison,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.988629,
              30.216293
            ],
            [
              -88.993382,
              30.208077
            ],
            [
              -88.970803,
              30.200888
            ],
            [
              -88.947035,
              30.206023
            ],
            [
              -88.9334246115684,
              30.210727514569697
            ],
            [
              -88.893557,
              30.224508
            ],
            [
              -88.876919,
              30.235803
            ],
            [
              -88.872166,
              30.245043
            ],
            [
              -88.881673,
              30.24915
            ],
            [
              -88.897122,
              30.240937
            ],
            [
              -88.9334803804366,
              30.222850056992197
            ],
            [
              -88.936339,
              30.221428
            ],
            [
              -88.95773,
              30.220401
            ],
            [
              -88.988629,
              30.216293
            ]
          ]
        ],
        [
          [
            [
              -89.159758,
              30.238883
            ],
            [
              -89.170454,
              30.228616
            ],
            [
              -89.127671,
              30.219374
            ],
            [
              -89.108657,
              30.206023
            ],
            [
              -89.098586804167,
              30.208043695341
            ],
            [
              -89.088384,
              30.210091
            ],
            [
              -89.074194,
              30.212185
            ],
            [
              -89.06231,
              30.229642
            ],
            [
              -89.056368,
              30.24915
            ],
            [
              -89.070628,
              30.258388
            ],
            [
              -89.084889,
              30.237856
            ],
            [
              -89.09821653898139,
              30.2380388177555
            ],
            [
              -89.159758,
              30.238883
            ]
          ]
        ],
        [
          [
            [
              -89.341534,
              30.550867
            ],
            [
              -89.341564,
              30.459783
            ],
            [
              -89.340947,
              30.372896406886298
            ],
            [
              -89.335942,
              30.374016
            ],
            [
              -89.315067,
              30.375408
            ],
            [
              -89.292499,
              30.365635
            ],
            [
              -89.279818,
              30.34979
            ],
            [
              -89.281564,
              30.33198
            ],
            [
              -89.287844,
              30.333196
            ],
            [
              -89.291844,
              30.328096
            ],
            [
              -89.294444,
              30.307596
            ],
            [
              -89.291444,
              30.303296
            ],
            [
              -89.285744,
              30.303097
            ],
            [
              -89.271833,
              30.305491
            ],
            [
              -89.2464882653851,
              30.3138779268327
            ],
            [
              -89.220442,
              30.322497
            ],
            [
              -89.18684,
              30.331197
            ],
            [
              -89.1255341679545,
              30.353032132211197
            ],
            [
              -89.09850064702741,
              30.362660588165298
            ],
            [
              -89.09748950594901,
              30.3630207235455
            ],
            [
              -89.083237,
              30.368097
            ],
            [
              -89.05730259200769,
              30.374222130124004
            ],
            [
              -89.0531857391684,
              30.375194439164197
            ],
            [
              -89.016334,
              30.383898
            ],
            [
              -88.9728126051128,
              30.3905563362835
            ],
            [
              -88.971233,
              30.390798
            ],
            [
              -88.9607314975501,
              30.391438309486396
            ],
            [
              -88.93430293656681,
              30.393049741602002
            ],
            [
              -88.922031,
              30.393798
            ],
            [
              -88.89393,
              30.393398
            ],
            [
              -88.884829,
              30.391998
            ],
            [
              -88.883789,
              30.389172
            ],
            [
              -88.880526,
              30.3866
            ],
            [
              -88.818762,
              30.36059
            ],
            [
              -88.81431023615959,
              30.3757367106908
            ],
            [
              -88.825287,
              30.383506
            ],
            [
              -88.833793,
              30.394147
            ],
            [
              -88.846042,
              30.405654
            ],
            [
              -88.861843,
              30.415621
            ],
            [
              -88.88313,
              30.418798
            ],
            [
              -88.883436,
              30.454103
            ],
            [
              -88.883944,
              30.560978
            ],
            [
              -88.884533,
              30.677292
            ],
            [
              -88.985928,
              30.677157
            ],
            [
              -89.134378,
              30.676956
            ],
            [
              -89.240073,
              30.67716
            ],
            [
              -89.246985,
              30.647424
            ],
            [
              -89.2519,
              30.647297
            ],
            [
              -89.273563,
              30.647393
            ],
            [
              -89.341446,
              30.64772
            ],
            [
              -89.341534,
              30.550867
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "109",
      "COUNTYNS": "00695777",
      "AFFGEOID": "0500000US28109",
      "GEOID": "28109",
      "NAME": "Pearl River",
      "LSAD": "06",
      "ALAND": 2100183723,
      "AWATER": 20778648,
      "County_state": "Pearl River,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.852263,
              30.662934
            ],
            [
              -89.851889,
              30.661199
            ],
            [
              -89.840988,
              30.658515
            ],
            [
              -89.836047,
              30.657298
            ],
            [
              -89.833261,
              30.657516
            ],
            [
              -89.824986,
              30.649423
            ],
            [
              -89.821868,
              30.644024
            ],
            [
              -89.818081,
              30.634019
            ],
            [
              -89.823261,
              30.622803
            ],
            [
              -89.821424,
              30.619815
            ],
            [
              -89.820868,
              30.618254
            ],
            [
              -89.822389,
              30.614462
            ],
            [
              -89.823278,
              30.60823
            ],
            [
              -89.821286,
              30.60713
            ],
            [
              -89.816905,
              30.60862
            ],
            [
              -89.81538,
              30.608566
            ],
            [
              -89.81392,
              30.607721
            ],
            [
              -89.814563,
              30.606152
            ],
            [
              -89.817202,
              30.600891
            ],
            [
              -89.819696,
              30.596785
            ],
            [
              -89.819838,
              30.59534
            ],
            [
              -89.818527,
              30.592688
            ],
            [
              -89.816396,
              30.591646
            ],
            [
              -89.812109,
              30.591473
            ],
            [
              -89.807118,
              30.587337
            ],
            [
              -89.807762,
              30.585825
            ],
            [
              -89.80866317829809,
              30.585318571527896
            ],
            [
              -89.809739,
              30.584714
            ],
            [
              -89.806843,
              30.572039
            ],
            [
              -89.808184,
              30.568795
            ],
            [
              -89.808027,
              30.567998
            ],
            [
              -89.806182,
              30.567543
            ],
            [
              -89.803753,
              30.568148
            ],
            [
              -89.799947,
              30.569351
            ],
            [
              -89.794495,
              30.569653
            ],
            [
              -89.790318,
              30.567524
            ],
            [
              -89.789695,
              30.56658
            ],
            [
              -89.790078,
              30.565333
            ],
            [
              -89.79243,
              30.563087
            ],
            [
              -89.796697,
              30.561718
            ],
            [
              -89.800277,
              30.563695
            ],
            [
              -89.801494,
              30.563703
            ],
            [
              -89.802833,
              30.562879
            ],
            [
              -89.803887,
              30.560581
            ],
            [
              -89.803831,
              30.558888
            ],
            [
              -89.802789,
              30.557903
            ],
            [
              -89.794532,
              30.556554
            ],
            [
              -89.791664,
              30.551524
            ],
            [
              -89.79196,
              30.548788
            ],
            [
              -89.793989,
              30.548283
            ],
            [
              -89.795231,
              30.548132
            ],
            [
              -89.795388,
              30.547452
            ],
            [
              -89.795335,
              30.546563
            ],
            [
              -89.793818,
              30.545935
            ],
            [
              -89.791046,
              30.545046
            ],
            [
              -89.788542,
              30.544464
            ],
            [
              -89.783994,
              30.544075
            ],
            [
              -89.780246,
              30.544607
            ],
            [
              -89.779565,
              30.544345
            ],
            [
              -89.775355,
              30.538848
            ],
            [
              -89.771643,
              30.530249
            ],
            [
              -89.770744,
              30.527819
            ],
            [
              -89.769996,
              30.521896
            ],
            [
              -89.768133,
              30.51502
            ],
            [
              -89.76057,
              30.515761
            ],
            [
              -89.758862,
              30.513062
            ],
            [
              -89.758575,
              30.505942
            ],
            [
              -89.758133,
              30.505404
            ],
            [
              -89.752931,
              30.502493
            ],
            [
              -89.746435,
              30.502619
            ],
            [
              -89.742816,
              30.498704
            ],
            [
              -89.742396,
              30.497316
            ],
            [
              -89.734615,
              30.494723
            ],
            [
              -89.726154,
              30.49256
            ],
            [
              -89.724614,
              30.491902
            ],
            [
              -89.721181,
              30.488608
            ],
            [
              -89.719652,
              30.483166
            ],
            [
              -89.715886,
              30.477797
            ],
            [
              -89.712493,
              30.47751
            ],
            [
              -89.710164,
              30.478308
            ],
            [
              -89.709551,
              30.477853
            ],
            [
              -89.705538,
              30.47235
            ],
            [
              -89.701799,
              30.465115
            ],
            [
              -89.695864,
              30.463269
            ],
            [
              -89.690102,
              30.459657
            ],
            [
              -89.6897794685397,
              30.4592779821573
            ],
            [
              -89.646961,
              30.458892
            ],
            [
              -89.647072,
              30.502738
            ],
            [
              -89.599154,
              30.502474
            ],
            [
              -89.595873,
              30.517069
            ],
            [
              -89.54451,
              30.516862
            ],
            [
              -89.543762,
              30.585704
            ],
            [
              -89.544232,
              30.648368
            ],
            [
              -89.341446,
              30.64772
            ],
            [
              -89.341393,
              30.695093
            ],
            [
              -89.340896,
              30.857628
            ],
            [
              -89.340806,
              30.909912
            ],
            [
              -89.340701,
              31.001033
            ],
            [
              -89.34895,
              31.01037
            ],
            [
              -89.424808,
              31.010114
            ],
            [
              -89.425337,
              30.982452
            ],
            [
              -89.476022,
              30.982901
            ],
            [
              -89.47594,
              31.001363
            ],
            [
              -89.501396,
              31.001461
            ],
            [
              -89.501411,
              31.013907
            ],
            [
              -89.533141,
              31.013946
            ],
            [
              -89.551966,
              31.014007
            ],
            [
              -89.551851,
              31.001917
            ],
            [
              -89.61003,
              31.002345
            ],
            [
              -89.654038,
              31.002502
            ],
            [
              -89.7281451296188,
              31.002299627985096
            ],
            [
              -89.728126,
              31.000956
            ],
            [
              -89.73,
              30.999749
            ],
            [
              -89.73554,
              30.999715
            ],
            [
              -89.734227,
              30.995602
            ],
            [
              -89.732035,
              30.994409
            ],
            [
              -89.728563,
              30.994396
            ],
            [
              -89.727698,
              30.993329
            ],
            [
              -89.72993,
              30.98209
            ],
            [
              -89.730501,
              30.979707
            ],
            [
              -89.732168,
              30.978088
            ],
            [
              -89.735912,
              30.977865
            ],
            [
              -89.736883,
              30.977122
            ],
            [
              -89.737149,
              30.976081
            ],
            [
              -89.736086,
              30.974446
            ],
            [
              -89.728382,
              30.971141
            ],
            [
              -89.727086,
              30.969707
            ],
            [
              -89.727072,
              30.967395
            ],
            [
              -89.728041,
              30.966518
            ],
            [
              -89.729327,
              30.966242
            ],
            [
              -89.731393,
              30.96613
            ],
            [
              -89.733933,
              30.966919
            ],
            [
              -89.735686,
              30.966573
            ],
            [
              -89.743134,
              30.959993
            ],
            [
              -89.743592,
              30.958482
            ],
            [
              -89.751196,
              30.951439
            ],
            [
              -89.756333,
              30.943498
            ],
            [
              -89.756554,
              30.941949
            ],
            [
              -89.755835,
              30.939543
            ],
            [
              -89.750073,
              30.935763
            ],
            [
              -89.748897,
              30.933888
            ],
            [
              -89.748851,
              30.932816
            ],
            [
              -89.750073,
              30.929815
            ],
            [
              -89.748208,
              30.923369
            ],
            [
              -89.745161,
              30.922416
            ],
            [
              -89.744448,
              30.920577
            ],
            [
              -89.744789,
              30.918933
            ],
            [
              -89.746718,
              30.915805
            ],
            [
              -89.750073,
              30.91293
            ],
            [
              -89.754086,
              30.9128
            ],
            [
              -89.757417,
              30.914993
            ],
            [
              -89.759403,
              30.915134
            ],
            [
              -89.7626,
              30.913736
            ],
            [
              -89.764202,
              30.911906
            ],
            [
              -89.764451,
              30.910276
            ],
            [
              -89.763622,
              30.907732
            ],
            [
              -89.761593,
              30.906591
            ],
            [
              -89.759803,
              30.906216
            ],
            [
              -89.756671,
              30.901069
            ],
            [
              -89.757024,
              30.898947
            ],
            [
              -89.758719,
              30.897319
            ],
            [
              -89.760701,
              30.896306
            ],
            [
              -89.765101,
              30.896919
            ],
            [
              -89.770269,
              30.89939
            ],
            [
              -89.771986,
              30.899127
            ],
            [
              -89.773099,
              30.898338
            ],
            [
              -89.773553,
              30.896862
            ],
            [
              -89.772011,
              30.89024
            ],
            [
              -89.770027,
              30.882254
            ],
            [
              -89.775458,
              30.881497
            ],
            [
              -89.77711,
              30.881088
            ],
            [
              -89.778583,
              30.878903
            ],
            [
              -89.779194,
              30.875185
            ],
            [
              -89.778005,
              30.873411
            ],
            [
              -89.768237,
              30.866392
            ],
            [
              -89.767789,
              30.865577
            ],
            [
              -89.767955,
              30.863858
            ],
            [
              -89.771722,
              30.854677
            ],
            [
              -89.772587,
              30.85366
            ],
            [
              -89.774739,
              30.853254
            ],
            [
              -89.778755,
              30.8558
            ],
            [
              -89.781643,
              30.856613
            ],
            [
              -89.783384,
              30.856022
            ],
            [
              -89.784073,
              30.85527
            ],
            [
              -89.784416,
              30.853744
            ],
            [
              -89.783791,
              30.852131
            ],
            [
              -89.780947,
              30.848542
            ],
            [
              -89.780228,
              30.846235
            ],
            [
              -89.7806,
              30.845508
            ],
            [
              -89.782649,
              30.845264
            ],
            [
              -89.7875,
              30.844112
            ],
            [
              -89.790121,
              30.837983
            ],
            [
              -89.790805,
              30.832131
            ],
            [
              -89.790432,
              30.830985
            ],
            [
              -89.789426,
              30.83047
            ],
            [
              -89.786837,
              30.830642
            ],
            [
              -89.783985,
              30.827385
            ],
            [
              -89.781168,
              30.820123
            ],
            [
              -89.782404,
              30.817975
            ],
            [
              -89.785894,
              30.815962
            ],
            [
              -89.791745,
              30.820387
            ],
            [
              -89.796634,
              30.821648
            ],
            [
              -89.797491,
              30.821478
            ],
            [
              -89.798654,
              30.820855
            ],
            [
              -89.800049,
              30.819078
            ],
            [
              -89.799673,
              30.815172
            ],
            [
              -89.800422,
              30.810425
            ],
            [
              -89.804065,
              30.803247
            ],
            [
              -89.804632,
              30.802511
            ],
            [
              -89.808176,
              30.800562
            ],
            [
              -89.810143,
              30.799846
            ],
            [
              -89.811171,
              30.798921
            ],
            [
              -89.811479,
              30.797996
            ],
            [
              -89.810863,
              30.797379
            ],
            [
              -89.808601,
              30.794913
            ],
            [
              -89.807071,
              30.793908
            ],
            [
              -89.806237,
              30.793371
            ],
            [
              -89.804901,
              30.792549
            ],
            [
              -89.804696,
              30.791624
            ],
            [
              -89.805107,
              30.790596
            ],
            [
              -89.806763,
              30.789069
            ],
            [
              -89.810657,
              30.788026
            ],
            [
              -89.812096,
              30.788437
            ],
            [
              -89.81261,
              30.789876
            ],
            [
              -89.813535,
              30.792035
            ],
            [
              -89.813946,
              30.793782
            ],
            [
              -89.816418,
              30.796054
            ],
            [
              -89.817559,
              30.796054
            ],
            [
              -89.819164,
              30.795229
            ],
            [
              -89.821078,
              30.792523
            ],
            [
              -89.821486,
              30.791183
            ],
            [
              -89.82113,
              30.788609
            ],
            [
              -89.824395,
              30.779629
            ],
            [
              -89.831537,
              30.76761
            ],
            [
              -89.827886,
              30.758419
            ],
            [
              -89.825774,
              30.747305
            ],
            [
              -89.826053,
              30.742322
            ],
            [
              -89.823492,
              30.740988
            ],
            [
              -89.819548,
              30.740671
            ],
            [
              -89.816764,
              30.740076
            ],
            [
              -89.816075,
              30.739366
            ],
            [
              -89.816499,
              30.737946
            ],
            [
              -89.81748,
              30.737305
            ],
            [
              -89.821535,
              30.736618
            ],
            [
              -89.826175,
              30.736594
            ],
            [
              -89.833818,
              30.736972
            ],
            [
              -89.835437,
              30.73626
            ],
            [
              -89.83580066478609,
              30.7358542079603
            ],
            [
              -89.83687,
              30.734661
            ],
            [
              -89.836945,
              30.728201
            ],
            [
              -89.836331,
              30.727197
            ],
            [
              -89.833065,
              30.726759
            ],
            [
              -89.828061,
              30.725018
            ],
            [
              -89.83006,
              30.71631
            ],
            [
              -89.831961,
              30.715384
            ],
            [
              -89.836257,
              30.716185
            ],
            [
              -89.845801,
              30.707314
            ],
            [
              -89.846576,
              30.706209
            ],
            [
              -89.845926,
              30.704157
            ],
            [
              -89.843605,
              30.702511
            ],
            [
              -89.84173,
              30.702713
            ],
            [
              -89.839312,
              30.704143
            ],
            [
              -89.838065,
              30.704036
            ],
            [
              -89.835848,
              30.699555
            ],
            [
              -89.835478,
              30.691166
            ],
            [
              -89.836797,
              30.690573
            ],
            [
              -89.844965,
              30.674691
            ],
            [
              -89.847201,
              30.670038
            ],
            [
              -89.84861106109449,
              30.6659742005644
            ],
            [
              -89.848879,
              30.665202
            ],
            [
              -89.85055,
              30.664781
            ],
            [
              -89.852263,
              30.662934
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "083",
      "COUNTYNS": "00695765",
      "AFFGEOID": "0500000US28083",
      "GEOID": "28083",
      "NAME": "Leflore",
      "LSAD": "06",
      "ALAND": 1536793536,
      "AWATER": 33720363,
      "County_state": "Leflore,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.453755,
              33.276255
            ],
            [
              -90.453801,
              33.267302
            ],
            [
              -90.413236,
              33.23532
            ],
            [
              -90.410827,
              33.245111
            ],
            [
              -90.366107,
              33.239747
            ],
            [
              -90.376725,
              33.280299
            ],
            [
              -90.364823,
              33.275765
            ],
            [
              -90.343788,
              33.309061
            ],
            [
              -90.331589,
              33.302825
            ],
            [
              -90.309497,
              33.32041
            ],
            [
              -90.305702,
              33.310252
            ],
            [
              -90.286439,
              33.312677
            ],
            [
              -90.279627,
              33.339509
            ],
            [
              -90.295029,
              33.361784
            ],
            [
              -90.321321,
              33.36567
            ],
            [
              -90.321308,
              33.37505
            ],
            [
              -90.174614,
              33.333378
            ],
            [
              -90.174864,
              33.374187
            ],
            [
              -90.174756,
              33.417003
            ],
            [
              -90.137817,
              33.416455
            ],
            [
              -90.13761,
              33.459114
            ],
            [
              -90.101401,
              33.45894
            ],
            [
              -90.101987,
              33.603792
            ],
            [
              -90.130254,
              33.603875
            ],
            [
              -90.107927,
              33.606311
            ],
            [
              -90.102971,
              33.66073
            ],
            [
              -90.127775,
              33.676956
            ],
            [
              -90.137011,
              33.676944
            ],
            [
              -90.136787,
              33.721648
            ],
            [
              -90.220439,
              33.722122
            ],
            [
              -90.239603,
              33.737246
            ],
            [
              -90.25234,
              33.730316
            ],
            [
              -90.256237,
              33.714414
            ],
            [
              -90.269105,
              33.697208
            ],
            [
              -90.300043,
              33.698047
            ],
            [
              -90.313859,
              33.725746
            ],
            [
              -90.294502,
              33.750981
            ],
            [
              -90.233791,
              33.765492
            ],
            [
              -90.227715,
              33.75928
            ],
            [
              -90.204205,
              33.759446
            ],
            [
              -90.185599,
              33.765514
            ],
            [
              -90.17144,
              33.789671
            ],
            [
              -90.182584,
              33.809322
            ],
            [
              -90.223868,
              33.809859
            ],
            [
              -90.451643,
              33.81139
            ],
            [
              -90.451389,
              33.708628
            ],
            [
              -90.453233,
              33.329484
            ],
            [
              -90.453755,
              33.276255
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "007",
      "COUNTYNS": "00695728",
      "AFFGEOID": "0500000US28007",
      "GEOID": "28007",
      "NAME": "Attala",
      "LSAD": "06",
      "ALAND": 1903529288,
      "AWATER": 4425860,
      "County_state": "Attala,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.896363,
              32.932602
            ],
            [
              -89.965882,
              32.879573
            ],
            [
              -89.840423,
              32.885408
            ],
            [
              -89.730016,
              32.885072
            ],
            [
              -89.730154,
              32.929924
            ],
            [
              -89.62289,
              32.930146
            ],
            [
              -89.600814,
              32.930518
            ],
            [
              -89.559562,
              32.930749
            ],
            [
              -89.319163,
              32.931654
            ],
            [
              -89.318223,
              33.065452
            ],
            [
              -89.317855,
              33.108871
            ],
            [
              -89.316408,
              33.285556
            ],
            [
              -89.401269,
              33.285915
            ],
            [
              -89.453808,
              33.285942
            ],
            [
              -89.64528,
              33.285515
            ],
            [
              -89.701514,
              33.285359
            ],
            [
              -89.721182,
              33.260137
            ],
            [
              -89.749441,
              33.214478
            ],
            [
              -89.748572,
              33.211553
            ],
            [
              -89.759426,
              33.195106
            ],
            [
              -89.776694,
              33.193801
            ],
            [
              -89.774276,
              33.176869
            ],
            [
              -89.783323,
              33.162368
            ],
            [
              -89.793929,
              33.159474
            ],
            [
              -89.793834,
              33.130716
            ],
            [
              -89.806257,
              33.112882
            ],
            [
              -89.82487,
              33.110215
            ],
            [
              -89.819328,
              33.100114
            ],
            [
              -89.827068,
              33.089005
            ],
            [
              -89.824182,
              33.064142
            ],
            [
              -89.833411,
              33.06237
            ],
            [
              -89.836363,
              33.039984
            ],
            [
              -89.873556,
              33.001009
            ],
            [
              -89.884414,
              32.973132
            ],
            [
              -89.896363,
              32.932602
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "057",
      "COUNTYNS": "00695752",
      "AFFGEOID": "0500000US28057",
      "GEOID": "28057",
      "NAME": "Itawamba",
      "LSAD": "06",
      "ALAND": 1379925849,
      "AWATER": 19832711,
      "County_state": "Itawamba,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.544659,
              34.27654
            ],
            [
              -88.544575,
              34.233932
            ],
            [
              -88.543812,
              34.162498
            ],
            [
              -88.543692,
              34.11841
            ],
            [
              -88.543352,
              34.089044
            ],
            [
              -88.510828,
              34.089042
            ],
            [
              -88.2037840638383,
              34.0865542422802
            ],
            [
              -88.200196,
              34.115948
            ],
            [
              -88.192128,
              34.175351
            ],
            [
              -88.190678,
              34.190123
            ],
            [
              -88.18762,
              34.204778
            ],
            [
              -88.186667,
              34.220952
            ],
            [
              -88.17710617074539,
              34.292238747559196
            ],
            [
              -88.176889,
              34.293858
            ],
            [
              -88.175867,
              34.302171
            ],
            [
              -88.173632,
              34.321054
            ],
            [
              -88.16591,
              34.380926
            ],
            [
              -88.165634,
              34.383102
            ],
            [
              -88.156292,
              34.463214
            ],
            [
              -88.329193,
              34.463571
            ],
            [
              -88.356438,
              34.464086
            ],
            [
              -88.424085,
              34.465076
            ],
            [
              -88.506908,
              34.465452
            ],
            [
              -88.542079,
              34.465276
            ],
            [
              -88.542106,
              34.432502
            ],
            [
              -88.542177,
              34.337988
            ],
            [
              -88.544659,
              34.27654
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "095",
      "COUNTYNS": "00695771",
      "AFFGEOID": "0500000US28095",
      "GEOID": "28095",
      "NAME": "Monroe",
      "LSAD": "06",
      "ALAND": 1981580823,
      "AWATER": 18138022,
      "County_state": "Monroe,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.719979,
              33.81133
            ],
            [
              -88.719626,
              33.680253
            ],
            [
              -88.497147,
              33.680181
            ],
            [
              -88.490978,
              33.673726
            ],
            [
              -88.513104,
              33.660697
            ],
            [
              -88.513917,
              33.650209
            ],
            [
              -88.483152,
              33.665743
            ],
            [
              -88.456018,
              33.663699
            ],
            [
              -88.445067,
              33.671576
            ],
            [
              -88.433504,
              33.667907
            ],
            [
              -88.425494,
              33.67542
            ],
            [
              -88.40636,
              33.673474
            ],
            [
              -88.394159,
              33.69559
            ],
            [
              -88.37033,
              33.685918
            ],
            [
              -88.330205,
              33.718864
            ],
            [
              -88.324962,
              33.745651
            ],
            [
              -88.2489373465951,
              33.744973677721305
            ],
            [
              -88.244142,
              33.781673
            ],
            [
              -88.243025,
              33.79568
            ],
            [
              -88.240054,
              33.810879
            ],
            [
              -88.235492,
              33.847203
            ],
            [
              -88.22924906832169,
              33.8919629072572
            ],
            [
              -88.226517,
              33.911551
            ],
            [
              -88.226517,
              33.911665
            ],
            [
              -88.226428,
              33.912875
            ],
            [
              -88.2132700197265,
              34.0104455295678
            ],
            [
              -88.210741,
              34.029199
            ],
            [
              -88.207229,
              34.058333
            ],
            [
              -88.2037840638383,
              34.0865542422802
            ],
            [
              -88.510828,
              34.089042
            ],
            [
              -88.543352,
              34.089044
            ],
            [
              -88.653185,
              34.08923
            ],
            [
              -88.67312,
              34.089145
            ],
            [
              -88.718693,
              34.08934
            ],
            [
              -88.718964,
              34.074521
            ],
            [
              -88.719529,
              34.053302
            ],
            [
              -88.719979,
              33.81133
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "137",
      "COUNTYNS": "00695789",
      "AFFGEOID": "0500000US28137",
      "GEOID": "28137",
      "NAME": "Tate",
      "LSAD": "06",
      "ALAND": 1048332509,
      "AWATER": 16090912,
      "County_state": "Tate,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.239971,
              34.626237
            ],
            [
              -90.243853,
              34.599597
            ],
            [
              -90.234035,
              34.601008
            ],
            [
              -90.218341,
              34.559604
            ],
            [
              -90.198631,
              34.554425
            ],
            [
              -89.915636,
              34.553692
            ],
            [
              -89.862712,
              34.553807
            ],
            [
              -89.792165,
              34.55385
            ],
            [
              -89.721341,
              34.554274
            ],
            [
              -89.668448,
              34.554367
            ],
            [
              -89.669183,
              34.685597
            ],
            [
              -89.722333,
              34.685504
            ],
            [
              -89.722989,
              34.713169
            ],
            [
              -89.723386,
              34.743008
            ],
            [
              -89.723442,
              34.772311
            ],
            [
              -89.730913,
              34.772155
            ],
            [
              -89.753114,
              34.772374
            ],
            [
              -89.912202,
              34.772506
            ],
            [
              -89.915271,
              34.75835
            ],
            [
              -89.955046,
              34.7382
            ],
            [
              -89.954005,
              34.727109
            ],
            [
              -89.982282,
              34.725501
            ],
            [
              -90.02872,
              34.708542
            ],
            [
              -90.053487,
              34.712353
            ],
            [
              -90.060568,
              34.728177
            ],
            [
              -90.106985,
              34.733274
            ],
            [
              -90.11694,
              34.752395
            ],
            [
              -90.128906,
              34.760537
            ],
            [
              -90.170946,
              34.759159
            ],
            [
              -90.19246,
              34.724892
            ],
            [
              -90.200199,
              34.724418
            ],
            [
              -90.222302,
              34.715764
            ],
            [
              -90.231358,
              34.698503
            ],
            [
              -90.220446,
              34.689931
            ],
            [
              -90.224934,
              34.672952
            ],
            [
              -90.216325,
              34.646874
            ],
            [
              -90.239971,
              34.626237
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "019",
      "COUNTYNS": "00695734",
      "AFFGEOID": "0500000US28019",
      "GEOID": "28019",
      "NAME": "Choctaw",
      "LSAD": "06",
      "ALAND": 1083088569,
      "AWATER": 4331852,
      "County_state": "Choctaw,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.45405,
              33.4332
            ],
            [
              -89.453808,
              33.285942
            ],
            [
              -89.401269,
              33.285915
            ],
            [
              -89.316408,
              33.285556
            ],
            [
              -89.317855,
              33.108871
            ],
            [
              -89.213921,
              33.109402
            ],
            [
              -89.213675,
              33.153303
            ],
            [
              -89.213422,
              33.197543
            ],
            [
              -89.128902,
              33.197715
            ],
            [
              -89.1089,
              33.198059
            ],
            [
              -89.108886,
              33.285937
            ],
            [
              -89.088255,
              33.286003
            ],
            [
              -89.088652,
              33.445821
            ],
            [
              -89.088438,
              33.532518
            ],
            [
              -89.088446,
              33.533202
            ],
            [
              -89.180963,
              33.532845
            ],
            [
              -89.228853,
              33.519747
            ],
            [
              -89.261102,
              33.518594
            ],
            [
              -89.271715,
              33.511165
            ],
            [
              -89.319794,
              33.496263
            ],
            [
              -89.34028,
              33.469496
            ],
            [
              -89.362339,
              33.473408
            ],
            [
              -89.381092,
              33.460307
            ],
            [
              -89.402272,
              33.452769
            ],
            [
              -89.422202,
              33.429501
            ],
            [
              -89.446326,
              33.439837
            ],
            [
              -89.45405,
              33.4332
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "039",
      "COUNTYNS": "00695744",
      "AFFGEOID": "0500000US28039",
      "GEOID": "28039",
      "NAME": "George",
      "LSAD": "06",
      "ALAND": 1239817244,
      "AWATER": 12838126,
      "County_state": "George,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.885038,
              30.910788
            ],
            [
              -88.884534,
              30.735591
            ],
            [
              -88.839116,
              30.735991
            ],
            [
              -88.833815,
              30.734237
            ],
            [
              -88.661054,
              30.734722
            ],
            [
              -88.54775,
              30.734997
            ],
            [
              -88.41245060127909,
              30.7355976173844
            ],
            [
              -88.41863,
              30.866528
            ],
            [
              -88.419562,
              30.875186
            ],
            [
              -88.4220230007208,
              30.925067256218597
            ],
            [
              -88.4256361537673,
              30.9983011286609
            ],
            [
              -88.563964,
              30.998331
            ],
            [
              -88.834339,
              30.997983
            ],
            [
              -88.834475,
              30.910323
            ],
            [
              -88.885038,
              30.910788
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "101",
      "COUNTYNS": "00695773",
      "AFFGEOID": "0500000US28101",
      "GEOID": "28101",
      "NAME": "Newton",
      "LSAD": "06",
      "ALAND": 1496636739,
      "AWATER": 4525334,
      "County_state": "Newton,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.32265,
              32.282101
            ],
            [
              -89.322692,
              32.223472
            ],
            [
              -89.185831,
              32.223793
            ],
            [
              -89.117675,
              32.223972
            ],
            [
              -88.913847,
              32.224186
            ],
            [
              -88.915416,
              32.486401
            ],
            [
              -88.914516,
              32.576955
            ],
            [
              -88.966047,
              32.576677
            ],
            [
              -88.976833,
              32.577337
            ],
            [
              -89.317588,
              32.576458
            ],
            [
              -89.322598,
              32.576433
            ],
            [
              -89.32265,
              32.282101
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "025",
      "COUNTYNS": "00695737",
      "AFFGEOID": "0500000US28025",
      "GEOID": "28025",
      "NAME": "Clay",
      "LSAD": "06",
      "ALAND": 1062066012,
      "AWATER": 15393112,
      "County_state": "Clay,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.034622,
              33.683418
            ],
            [
              -89.036021,
              33.633569
            ],
            [
              -89.01854,
              33.635373
            ],
            [
              -89.018625,
              33.593037
            ],
            [
              -89.018613,
              33.562114
            ],
            [
              -88.949824,
              33.564343
            ],
            [
              -88.770596,
              33.565212
            ],
            [
              -88.735481,
              33.565219
            ],
            [
              -88.70712,
              33.559177
            ],
            [
              -88.691996,
              33.565229
            ],
            [
              -88.671526,
              33.565135
            ],
            [
              -88.67125,
              33.506168
            ],
            [
              -88.632219,
              33.506521
            ],
            [
              -88.601208,
              33.507853
            ],
            [
              -88.578581,
              33.522037
            ],
            [
              -88.573835,
              33.519871
            ],
            [
              -88.54386,
              33.526806
            ],
            [
              -88.52728,
              33.546579
            ],
            [
              -88.524183,
              33.550949
            ],
            [
              -88.480299,
              33.522741
            ],
            [
              -88.471886,
              33.527114
            ],
            [
              -88.492504,
              33.543154
            ],
            [
              -88.494864,
              33.579522
            ],
            [
              -88.475174,
              33.604151
            ],
            [
              -88.504543,
              33.613353
            ],
            [
              -88.499641,
              33.643782
            ],
            [
              -88.513917,
              33.650209
            ],
            [
              -88.513104,
              33.660697
            ],
            [
              -88.490978,
              33.673726
            ],
            [
              -88.497147,
              33.680181
            ],
            [
              -88.719626,
              33.680253
            ],
            [
              -88.719979,
              33.81133
            ],
            [
              -88.931176,
              33.812374
            ],
            [
              -88.931604,
              33.754312
            ],
            [
              -89.034775,
              33.754156
            ],
            [
              -89.034498,
              33.73943
            ],
            [
              -89.034622,
              33.683418
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "041",
      "COUNTYNS": "00695745",
      "AFFGEOID": "0500000US28041",
      "GEOID": "28041",
      "NAME": "Greene",
      "LSAD": "06",
      "ALAND": 1846034017,
      "AWATER": 15380474,
      "County_state": "Greene,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.841707,
              31.433703
            ],
            [
              -88.841232,
              31.26779
            ],
            [
              -88.840929,
              30.99841
            ],
            [
              -88.834339,
              30.997983
            ],
            [
              -88.563964,
              30.998331
            ],
            [
              -88.4256361537673,
              30.9983011286609
            ],
            [
              -88.425729,
              31.000183
            ],
            [
              -88.425807,
              31.001123
            ],
            [
              -88.432007,
              31.114298
            ],
            [
              -88.438104,
              31.23006
            ],
            [
              -88.438211,
              31.231252
            ],
            [
              -88.43898,
              31.246896
            ],
            [
              -88.43878,
              31.252654
            ],
            [
              -88.43970863275949,
              31.267623670323697
            ],
            [
              -88.445182,
              31.355855
            ],
            [
              -88.445209,
              31.355969
            ],
            [
              -88.448686,
              31.420888
            ],
            [
              -88.44866,
              31.421277
            ],
            [
              -88.4495695963996,
              31.4358347375977
            ],
            [
              -88.63578,
              31.434181
            ],
            [
              -88.841707,
              31.433703
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "059",
      "COUNTYNS": "00695753",
      "AFFGEOID": "0500000US28059",
      "GEOID": "28059",
      "NAME": "Jackson",
      "LSAD": "06",
      "ALAND": 1872033050,
      "AWATER": 830368430,
      "County_state": "Jackson,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.510674,
              30.217021
            ],
            [
              -88.508642,
              30.205602
            ],
            [
              -88.494411,
              30.196817
            ],
            [
              -88.453752,
              30.189349
            ],
            [
              -88.44838931350489,
              30.190121661216196
            ],
            [
              -88.429356,
              30.192864
            ],
            [
              -88.404581,
              30.206162
            ],
            [
              -88.408519,
              30.218778
            ],
            [
              -88.42834,
              30.214826
            ],
            [
              -88.443079,
              30.208237
            ],
            [
              -88.44905,
              30.207205511952797
            ],
            [
              -88.453244,
              30.206481
            ],
            [
              -88.465441,
              30.208237
            ],
            [
              -88.492378,
              30.219217
            ],
            [
              -88.510674,
              30.217021
            ]
          ]
        ],
        [
          [
            [
              -88.771991,
              30.245523
            ],
            [
              -88.768351,
              30.236783
            ],
            [
              -88.75717,
              30.23327
            ],
            [
              -88.741415,
              30.23327
            ],
            [
              -88.714986,
              30.237661
            ],
            [
              -88.671786,
              30.22317
            ],
            [
              -88.6682729566225,
              30.2228327119949
            ],
            [
              -88.644341,
              30.220535
            ],
            [
              -88.611306,
              30.217021
            ],
            [
              -88.587424,
              30.219154
            ],
            [
              -88.608764,
              30.231952
            ],
            [
              -88.656031,
              30.239417
            ],
            [
              -88.6677097774897,
              30.2432006553382
            ],
            [
              -88.688558,
              30.249955
            ],
            [
              -88.714986,
              30.256979
            ],
            [
              -88.73684,
              30.253028
            ],
            [
              -88.771991,
              30.245523
            ]
          ]
        ],
        [
          [
            [
              -88.884534,
              30.735591
            ],
            [
              -88.884533,
              30.677292
            ],
            [
              -88.883944,
              30.560978
            ],
            [
              -88.883436,
              30.454103
            ],
            [
              -88.88313,
              30.418798
            ],
            [
              -88.861843,
              30.415621
            ],
            [
              -88.846042,
              30.405654
            ],
            [
              -88.833793,
              30.394147
            ],
            [
              -88.825287,
              30.383506
            ],
            [
              -88.81431023615959,
              30.3757367106908
            ],
            [
              -88.811615,
              30.384907
            ],
            [
              -88.771742,
              30.365403
            ],
            [
              -88.7490527901102,
              30.3491334173469
            ],
            [
              -88.746945,
              30.347622
            ],
            [
              -88.728893,
              30.342671
            ],
            [
              -88.700587,
              30.343689
            ],
            [
              -88.66382,
              30.362099
            ],
            [
              -88.637697,
              30.359848
            ],
            [
              -88.637479684005,
              30.3597962124128
            ],
            [
              -88.613006,
              30.353964
            ],
            [
              -88.60001,
              30.356575
            ],
            [
              -88.581929,
              30.331063
            ],
            [
              -88.567766,
              30.329556
            ],
            [
              -88.536214,
              30.343299
            ],
            [
              -88.522494,
              30.340092
            ],
            [
              -88.506334,
              30.327398
            ],
            [
              -88.506226,
              30.322393
            ],
            [
              -88.504802,
              30.321472
            ],
            [
              -88.480117,
              30.318345
            ],
            [
              -88.471875,
              30.32002
            ],
            [
              -88.45381,
              30.329626
            ],
            [
              -88.446625,
              30.337671
            ],
            [
              -88.446495,
              30.347753
            ],
            [
              -88.433891,
              30.354652
            ],
            [
              -88.418811,
              30.353911
            ],
            [
              -88.409927,
              30.342115
            ],
            [
              -88.401181,
              30.344382
            ],
            [
              -88.39398,
              30.349307
            ],
            [
              -88.39415,
              30.352493
            ],
            [
              -88.397082,
              30.354785
            ],
            [
              -88.399062,
              30.360744
            ],
            [
              -88.397236,
              30.367689
            ],
            [
              -88.395023,
              30.369425
            ],
            [
              -88.402283,
              30.510852
            ],
            [
              -88.403547,
              30.5331
            ],
            [
              -88.403931,
              30.543359
            ],
            [
              -88.404013,
              30.54506
            ],
            [
              -88.404676680269,
              30.559912212208598
            ],
            [
              -88.4060104917735,
              30.589761
            ],
            [
              -88.407484,
              30.622736
            ],
            [
              -88.407462,
              30.631653
            ],
            [
              -88.40807,
              30.63697
            ],
            [
              -88.409571,
              30.668731
            ],
            [
              -88.411339,
              30.706334
            ],
            [
              -88.41155,
              30.712956
            ],
            [
              -88.412209,
              30.730395
            ],
            [
              -88.41227,
              30.731771
            ],
            [
              -88.41245060127909,
              30.7355976173844
            ],
            [
              -88.54775,
              30.734997
            ],
            [
              -88.661054,
              30.734722
            ],
            [
              -88.833815,
              30.734237
            ],
            [
              -88.839116,
              30.735991
            ],
            [
              -88.884534,
              30.735591
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "061",
      "COUNTYNS": "00695754",
      "AFFGEOID": "0500000US28061",
      "GEOID": "28061",
      "NAME": "Jasper",
      "LSAD": "06",
      "ALAND": 1751451429,
      "AWATER": 3181592,
      "County_state": "Jasper,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.322692,
              32.223472
            ],
            [
              -89.321158,
              31.888745
            ],
            [
              -89.317086,
              31.874305
            ],
            [
              -89.31656,
              31.80209
            ],
            [
              -89.264668,
              31.80523
            ],
            [
              -89.198211,
              31.809257
            ],
            [
              -89.082826,
              31.816071
            ],
            [
              -89.079491,
              31.816268
            ],
            [
              -88.943355,
              31.824565
            ],
            [
              -88.910459,
              31.826649
            ],
            [
              -88.911583,
              31.934402
            ],
            [
              -88.911735,
              31.962774
            ],
            [
              -88.91294,
              32.049361
            ],
            [
              -88.91356,
              32.108454
            ],
            [
              -88.913847,
              32.224186
            ],
            [
              -89.117675,
              32.223972
            ],
            [
              -89.185831,
              32.223793
            ],
            [
              -89.322692,
              32.223472
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "037",
      "COUNTYNS": "00695743",
      "AFFGEOID": "0500000US28037",
      "GEOID": "28037",
      "NAME": "Franklin",
      "LSAD": "06",
      "ALAND": 1460658781,
      "AWATER": 7250805,
      "County_state": "Franklin,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.156314,
              31.425119
            ],
            [
              -91.15815,
              31.346695
            ],
            [
              -91.117967,
              31.320314
            ],
            [
              -91.095398,
              31.320975
            ],
            [
              -91.025427,
              31.337373
            ],
            [
              -91.020871,
              31.345309
            ],
            [
              -90.988365,
              31.336363
            ],
            [
              -90.983002,
              31.348671
            ],
            [
              -90.770824,
              31.349583
            ],
            [
              -90.633302,
              31.349306
            ],
            [
              -90.63313,
              31.44466
            ],
            [
              -90.633231,
              31.611409
            ],
            [
              -90.73733,
              31.611124
            ],
            [
              -90.7501,
              31.611588
            ],
            [
              -90.80337,
              31.61079
            ],
            [
              -91.048993,
              31.61059
            ],
            [
              -91.127398,
              31.610211
            ],
            [
              -91.153864,
              31.610068
            ],
            [
              -91.156141,
              31.436188
            ],
            [
              -91.156314,
              31.425119
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "117",
      "COUNTYNS": "00695779",
      "AFFGEOID": "0500000US28117",
      "GEOID": "28117",
      "NAME": "Prentiss",
      "LSAD": "06",
      "ALAND": 1074778137,
      "AWATER": 8353594,
      "County_state": "Prentiss,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.735982,
              34.55652
            ],
            [
              -88.736465,
              34.50891
            ],
            [
              -88.541749,
              34.509192
            ],
            [
              -88.542079,
              34.465276
            ],
            [
              -88.506908,
              34.465452
            ],
            [
              -88.424085,
              34.465076
            ],
            [
              -88.356438,
              34.464086
            ],
            [
              -88.329193,
              34.463571
            ],
            [
              -88.330813,
              34.580412
            ],
            [
              -88.330699,
              34.740721
            ],
            [
              -88.348311,
              34.740873
            ],
            [
              -88.348366,
              34.755355
            ],
            [
              -88.365472,
              34.755604
            ],
            [
              -88.700983,
              34.756744
            ],
            [
              -88.718508,
              34.756781
            ],
            [
              -88.717794,
              34.596472
            ],
            [
              -88.73544,
              34.596483
            ],
            [
              -88.735982,
              34.55652
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "103",
      "COUNTYNS": "00695774",
      "AFFGEOID": "0500000US28103",
      "GEOID": "28103",
      "NAME": "Noxubee",
      "LSAD": "06",
      "ALAND": 1800451886,
      "AWATER": 12487951,
      "County_state": "Noxubee,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.813722,
              33.089209
            ],
            [
              -88.813555,
              33.034873
            ],
            [
              -88.812796,
              32.927384
            ],
            [
              -88.812782,
              32.925973
            ],
            [
              -88.3478500256084,
              32.929077584824
            ],
            [
              -88.340432,
              32.991199
            ],
            [
              -88.3310076496119,
              33.072489727510394
            ],
            [
              -88.330934,
              33.073125
            ],
            [
              -88.317135,
              33.184123
            ],
            [
              -88.315235,
              33.203323
            ],
            [
              -88.312535,
              33.220923
            ],
            [
              -88.30792779585659,
              33.259171559021006
            ],
            [
              -88.30517442861469,
              33.2820297450658
            ],
            [
              -88.30441698708249,
              33.2883179507206
            ],
            [
              -88.669076,
              33.286922
            ],
            [
              -88.813053,
              33.286424
            ],
            [
              -88.813172,
              33.173511
            ],
            [
              -88.813722,
              33.089209
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "077",
      "COUNTYNS": "00695762",
      "AFFGEOID": "0500000US28077",
      "GEOID": "28077",
      "NAME": "Lawrence",
      "LSAD": "06",
      "ALAND": 1115424613,
      "AWATER": 13213419,
      "County_state": "Lawrence,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.245191,
              31.717524
            ],
            [
              -90.245186,
              31.716187
            ],
            [
              -90.244608,
              31.569762
            ],
            [
              -90.244614,
              31.548781
            ],
            [
              -90.244523,
              31.487929
            ],
            [
              -90.24389,
              31.350274
            ],
            [
              -90.108651,
              31.350869
            ],
            [
              -90.08243,
              31.328778
            ],
            [
              -90.052985,
              31.328874
            ],
            [
              -90.040466,
              31.336014
            ],
            [
              -90.040485,
              31.394673
            ],
            [
              -89.975904,
              31.394587
            ],
            [
              -89.95894,
              31.390489
            ],
            [
              -89.958951,
              31.440788
            ],
            [
              -89.959,
              31.482246
            ],
            [
              -89.959097,
              31.522421
            ],
            [
              -89.976052,
              31.522477
            ],
            [
              -89.974586,
              31.761655
            ],
            [
              -90.122565,
              31.75268
            ],
            [
              -90.131274,
              31.756048
            ],
            [
              -90.147898,
              31.746349
            ],
            [
              -90.154472,
              31.747144
            ],
            [
              -90.184139,
              31.731462
            ],
            [
              -90.245191,
              31.717524
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "087",
      "COUNTYNS": "00695767",
      "AFFGEOID": "0500000US28087",
      "GEOID": "28087",
      "NAME": "Lowndes",
      "LSAD": "06",
      "ALAND": 1309077220,
      "AWATER": 28560499,
      "County_state": "Lowndes,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.67125,
              33.506168
            ],
            [
              -88.671051,
              33.462739
            ],
            [
              -88.670378,
              33.404071
            ],
            [
              -88.669076,
              33.286922
            ],
            [
              -88.30441698708249,
              33.2883179507206
            ],
            [
              -88.30274373083179,
              33.3022091607627
            ],
            [
              -88.2910782508144,
              33.3990548370265
            ],
            [
              -88.277421,
              33.512436
            ],
            [
              -88.276805,
              33.516463
            ],
            [
              -88.274619,
              33.534008
            ],
            [
              -88.27005,
              33.570819
            ],
            [
              -88.269532,
              33.572894
            ],
            [
              -88.269076,
              33.576929
            ],
            [
              -88.26816,
              33.58504
            ],
            [
              -88.267148,
              33.591989
            ],
            [
              -88.267005,
              33.594229
            ],
            [
              -88.262002140265,
              33.6354380745981
            ],
            [
              -88.256343,
              33.682053
            ],
            [
              -88.256131,
              33.68286
            ],
            [
              -88.254622,
              33.69578
            ],
            [
              -88.254445,
              33.698779
            ],
            [
              -88.252257,
              33.719568
            ],
            [
              -88.2489373465951,
              33.744973677721305
            ],
            [
              -88.324962,
              33.745651
            ],
            [
              -88.330205,
              33.718864
            ],
            [
              -88.37033,
              33.685918
            ],
            [
              -88.394159,
              33.69559
            ],
            [
              -88.40636,
              33.673474
            ],
            [
              -88.425494,
              33.67542
            ],
            [
              -88.433504,
              33.667907
            ],
            [
              -88.445067,
              33.671576
            ],
            [
              -88.456018,
              33.663699
            ],
            [
              -88.483152,
              33.665743
            ],
            [
              -88.513917,
              33.650209
            ],
            [
              -88.499641,
              33.643782
            ],
            [
              -88.504543,
              33.613353
            ],
            [
              -88.475174,
              33.604151
            ],
            [
              -88.494864,
              33.579522
            ],
            [
              -88.492504,
              33.543154
            ],
            [
              -88.471886,
              33.527114
            ],
            [
              -88.480299,
              33.522741
            ],
            [
              -88.524183,
              33.550949
            ],
            [
              -88.52728,
              33.546579
            ],
            [
              -88.54386,
              33.526806
            ],
            [
              -88.573835,
              33.519871
            ],
            [
              -88.578581,
              33.522037
            ],
            [
              -88.601208,
              33.507853
            ],
            [
              -88.632219,
              33.506521
            ],
            [
              -88.67125,
              33.506168
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "147",
      "COUNTYNS": "00695794",
      "AFFGEOID": "0500000US28147",
      "GEOID": "28147",
      "NAME": "Walthall",
      "LSAD": "06",
      "ALAND": 1046169273,
      "AWATER": 1236552,
      "County_state": "Walthall,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.260692,
              31.271327
            ],
            [
              -90.260692,
              31.262772
            ],
            [
              -90.260557,
              31.175754
            ],
            [
              -90.260234,
              31.147169
            ],
            [
              -90.2595549256981,
              31.0006571633248
            ],
            [
              -90.1943924283431,
              31.0008788863664
            ],
            [
              -90.164676,
              31.00098
            ],
            [
              -90.164278,
              31.001025
            ],
            [
              -90.131395,
              31.000924
            ],
            [
              -90.128406,
              31.001047
            ],
            [
              -90.050706,
              31.001215
            ],
            [
              -90.029574,
              31.00119
            ],
            [
              -90.022185,
              31.001302
            ],
            [
              -90.005332,
              31.001364
            ],
            [
              -89.97543,
              31.001692
            ],
            [
              -89.972802,
              31.001392
            ],
            [
              -89.927161,
              31.001437
            ],
            [
              -89.923119,
              31.001446
            ],
            [
              -89.897516,
              31.001913
            ],
            [
              -89.892708,
              31.001759
            ],
            [
              -89.856862,
              31.002075
            ],
            [
              -89.83590796037879,
              31.002059274641898
            ],
            [
              -89.835873,
              31.074926
            ],
            [
              -89.920944,
              31.074677
            ],
            [
              -89.929501,
              31.089229
            ],
            [
              -89.92952,
              31.111167
            ],
            [
              -89.938242,
              31.111158
            ],
            [
              -89.938178,
              31.132949
            ],
            [
              -90.005936,
              31.1323
            ],
            [
              -90.006138,
              31.175605
            ],
            [
              -90.031896,
              31.175727
            ],
            [
              -90.031857,
              31.226844
            ],
            [
              -90.040375,
              31.23391
            ],
            [
              -90.040386,
              31.271847
            ],
            [
              -90.040466,
              31.336014
            ],
            [
              -90.052985,
              31.328874
            ],
            [
              -90.08243,
              31.328778
            ],
            [
              -90.108651,
              31.350869
            ],
            [
              -90.24389,
              31.350274
            ],
            [
              -90.260391,
              31.350274
            ],
            [
              -90.260692,
              31.271327
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "009",
      "COUNTYNS": "00695729",
      "AFFGEOID": "0500000US28009",
      "GEOID": "28009",
      "NAME": "Benton",
      "LSAD": "06",
      "ALAND": 1053131400,
      "AWATER": 5279183,
      "County_state": "Benton,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.352796,
              34.946419
            ],
            [
              -89.352858,
              34.917253
            ],
            [
              -89.352705,
              34.844432
            ],
            [
              -89.299227,
              34.844415
            ],
            [
              -89.299183,
              34.820655
            ],
            [
              -89.298794,
              34.721352
            ],
            [
              -89.298334,
              34.582926
            ],
            [
              -89.245645,
              34.583234
            ],
            [
              -89.245611,
              34.597601
            ],
            [
              -89.14105,
              34.59789
            ],
            [
              -89.088488,
              34.598247
            ],
            [
              -89.088065,
              34.815307
            ],
            [
              -89.03534,
              34.81526
            ],
            [
              -89.035009,
              34.858762
            ],
            [
              -89.017551,
              34.858723
            ],
            [
              -89.0171270872889,
              34.9949454068841
            ],
            [
              -89.02654,
              34.994956
            ],
            [
              -89.138997,
              34.99433
            ],
            [
              -89.139136,
              34.994307
            ],
            [
              -89.156827,
              34.993926
            ],
            [
              -89.198288,
              34.994484
            ],
            [
              -89.217433,
              34.994729
            ],
            [
              -89.35268016441019,
              34.9941227781396
            ],
            [
              -89.352796,
              34.946419
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "135",
      "COUNTYNS": "00695788",
      "AFFGEOID": "0500000US28135",
      "GEOID": "28135",
      "NAME": "Tallahatchie",
      "LSAD": "06",
      "ALAND": 1671109461,
      "AWATER": 17929624,
      "County_state": "Tallahatchie,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.450529,
              33.908609
            ],
            [
              -90.451643,
              33.81139
            ],
            [
              -90.223868,
              33.809859
            ],
            [
              -90.182584,
              33.809322
            ],
            [
              -90.17144,
              33.789671
            ],
            [
              -90.185599,
              33.765514
            ],
            [
              -90.204205,
              33.759446
            ],
            [
              -90.227715,
              33.75928
            ],
            [
              -90.233791,
              33.765492
            ],
            [
              -90.294502,
              33.750981
            ],
            [
              -90.313859,
              33.725746
            ],
            [
              -90.300043,
              33.698047
            ],
            [
              -90.269105,
              33.697208
            ],
            [
              -90.256237,
              33.714414
            ],
            [
              -90.25234,
              33.730316
            ],
            [
              -90.239603,
              33.737246
            ],
            [
              -90.220439,
              33.722122
            ],
            [
              -90.136787,
              33.721648
            ],
            [
              -90.136491,
              33.809319
            ],
            [
              -89.995939,
              33.809711
            ],
            [
              -89.978367,
              33.809719
            ],
            [
              -89.978268,
              33.82396
            ],
            [
              -89.926294,
              33.823854
            ],
            [
              -89.926099,
              33.897957
            ],
            [
              -89.926172,
              34.057624
            ],
            [
              -89.925497,
              34.140553
            ],
            [
              -89.931485,
              34.164303
            ],
            [
              -89.958579,
              34.159554
            ],
            [
              -90.119166,
              34.160055
            ],
            [
              -90.135456,
              34.160074
            ],
            [
              -90.135695,
              34.0729
            ],
            [
              -90.289471,
              34.072834
            ],
            [
              -90.34218,
              34.072711
            ],
            [
              -90.450981,
              34.072932
            ],
            [
              -90.451012,
              33.985689
            ],
            [
              -90.450529,
              33.908609
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "069",
      "COUNTYNS": "00695758",
      "AFFGEOID": "0500000US28069",
      "GEOID": "28069",
      "NAME": "Kemper",
      "LSAD": "06",
      "ALAND": 1984403113,
      "AWATER": 2182350,
      "County_state": "Kemper,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.914821,
              32.805874
            ],
            [
              -88.914516,
              32.576955
            ],
            [
              -88.708362,
              32.577555
            ],
            [
              -88.3888572465182,
              32.5781229194103
            ],
            [
              -88.383039,
              32.626679
            ],
            [
              -88.382985,
              32.626954
            ],
            [
              -88.373338,
              32.711825
            ],
            [
              -88.368349,
              32.747656
            ],
            [
              -88.354292,
              32.87513
            ],
            [
              -88.3478500256084,
              32.929077584824
            ],
            [
              -88.812782,
              32.925973
            ],
            [
              -88.914413,
              32.9266
            ],
            [
              -88.9144,
              32.868017
            ],
            [
              -88.914315,
              32.860198
            ],
            [
              -88.914821,
              32.805874
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "151",
      "COUNTYNS": "00695796",
      "AFFGEOID": "0500000US28151",
      "GEOID": "28151",
      "NAME": "Washington",
      "LSAD": "06",
      "ALAND": 1877069905,
      "AWATER": 94495553,
      "County_state": "Washington,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.235928,
              33.440611
            ],
            [
              -91.235181,
              33.438972
            ],
            [
              -91.217374,
              33.434699
            ],
            [
              -91.210275,
              33.433796
            ],
            [
              -91.206807,
              33.433846
            ],
            [
              -91.194658,
              33.436358
            ],
            [
              -91.186979,
              33.438592
            ],
            [
              -91.181787,
              33.44078
            ],
            [
              -91.177293,
              33.443638
            ],
            [
              -91.16936,
              33.452629
            ],
            [
              -91.171799,
              33.462342
            ],
            [
              -91.175635,
              33.471761
            ],
            [
              -91.176984,
              33.478899
            ],
            [
              -91.177148,
              33.48617
            ],
            [
              -91.175488,
              33.49077
            ],
            [
              -91.172213,
              33.496691
            ],
            [
              -91.167403,
              33.498304
            ],
            [
              -91.164019,
              33.497448
            ],
            [
              -91.160142,
              33.494829
            ],
            [
              -91.157319,
              33.492923
            ],
            [
              -91.136656,
              33.481323
            ],
            [
              -91.125109,
              33.472842
            ],
            [
              -91.119667,
              33.46023
            ],
            [
              -91.117975,
              33.453807
            ],
            [
              -91.118495,
              33.449116
            ],
            [
              -91.1211,
              33.443563
            ],
            [
              -91.128589,
              33.436284
            ],
            [
              -91.130552,
              33.433263
            ],
            [
              -91.130561,
              33.4319
            ],
            [
              -91.131885,
              33.430063
            ],
            [
              -91.13915,
              33.426955
            ],
            [
              -91.144877,
              33.427706
            ],
            [
              -91.147663,
              33.427172
            ],
            [
              -91.163387,
              33.422157
            ],
            [
              -91.17628,
              33.416979
            ],
            [
              -91.179368,
              33.417151
            ],
            [
              -91.184427,
              33.419576
            ],
            [
              -91.191973,
              33.417728
            ],
            [
              -91.199354,
              33.418321
            ],
            [
              -91.20258,
              33.416832
            ],
            [
              -91.205272,
              33.414231
            ],
            [
              -91.208702,
              33.408719
            ],
            [
              -91.20922,
              33.40629
            ],
            [
              -91.209032,
              33.403633
            ],
            [
              -91.208113,
              33.402007
            ],
            [
              -91.204758,
              33.398823
            ],
            [
              -91.191127,
              33.389634
            ],
            [
              -91.176942,
              33.382841
            ],
            [
              -91.171968,
              33.38103
            ],
            [
              -91.166304,
              33.379709
            ],
            [
              -91.154017,
              33.378914
            ],
            [
              -91.140938,
              33.380477
            ],
            [
              -91.123623,
              33.387526
            ],
            [
              -91.113764,
              33.393124
            ],
            [
              -91.10717,
              33.399078
            ],
            [
              -91.099277,
              33.408244
            ],
            [
              -91.095211,
              33.417488
            ],
            [
              -91.095335,
              33.425684
            ],
            [
              -91.097474,
              33.431733
            ],
            [
              -91.097531,
              33.433725
            ],
            [
              -91.096723,
              33.437603
            ],
            [
              -91.094279,
              33.442671
            ],
            [
              -91.091566,
              33.446319
            ],
            [
              -91.086498,
              33.451576
            ],
            [
              -91.081834,
              33.454188
            ],
            [
              -91.077814,
              33.455648
            ],
            [
              -91.074555,
              33.455811
            ],
            [
              -91.067623,
              33.455104
            ],
            [
              -91.064701,
              33.453775
            ],
            [
              -91.059828,
              33.450257
            ],
            [
              -91.0580133125203,
              33.4462148596964
            ],
            [
              -91.057621,
              33.445341
            ],
            [
              -91.058152,
              33.428705
            ],
            [
              -91.062281,
              33.421238
            ],
            [
              -91.075293,
              33.405966
            ],
            [
              -91.090852,
              33.395781
            ],
            [
              -91.101456,
              33.38719
            ],
            [
              -91.106758,
              33.381141
            ],
            [
              -91.120409,
              33.363809
            ],
            [
              -91.125108,
              33.360099
            ],
            [
              -91.140192,
              33.351452
            ],
            [
              -91.141793,
              33.350076
            ],
            [
              -91.142219,
              33.348989
            ],
            [
              -91.140968,
              33.336493
            ],
            [
              -91.141893,
              33.332963
            ],
            [
              -91.143353,
              33.33052
            ],
            [
              -91.143667,
              33.328398
            ],
            [
              -91.141475,
              33.314318
            ],
            [
              -91.141615,
              33.299539
            ],
            [
              -91.140057,
              33.296618
            ],
            [
              -91.125539,
              33.280255
            ],
            [
              -91.125528,
              33.274732
            ],
            [
              -91.128078,
              33.268502
            ],
            [
              -91.118208,
              33.262071
            ],
            [
              -91.117223,
              33.260685
            ],
            [
              -91.114325,
              33.252953
            ],
            [
              -91.110561,
              33.24593
            ],
            [
              -91.106142,
              33.241799
            ],
            [
              -91.1001,
              33.238125
            ],
            [
              -91.099093,
              33.238173
            ],
            [
              -91.096931,
              33.241628
            ],
            [
              -91.094748,
              33.250499
            ],
            [
              -91.091489,
              33.254838
            ],
            [
              -91.090342,
              33.257325
            ],
            [
              -91.086137,
              33.273652
            ],
            [
              -91.083694,
              33.278557
            ],
            [
              -91.081244,
              33.28125
            ],
            [
              -91.07853,
              33.283306
            ],
            [
              -91.072567,
              33.285885
            ],
            [
              -91.067035,
              33.28718
            ],
            [
              -91.05873,
              33.286901
            ],
            [
              -91.052369,
              33.285415
            ],
            [
              -91.04815,
              33.282796
            ],
            [
              -91.045141,
              33.279015
            ],
            [
              -91.043624,
              33.274636
            ],
            [
              -91.043985,
              33.269835
            ],
            [
              -91.045191,
              33.265404
            ],
            [
              -91.047648,
              33.259989
            ],
            [
              -91.050407,
              33.251202
            ],
            [
              -91.054126,
              33.246105
            ],
            [
              -91.063912,
              33.237356
            ],
            [
              -91.065629,
              33.232982
            ],
            [
              -91.068708,
              33.232936
            ],
            [
              -91.071079,
              33.230096
            ],
            [
              -91.070697,
              33.227302
            ],
            [
              -91.074103,
              33.226821
            ],
            [
              -91.077673,
              33.227485
            ],
            [
              -91.079227,
              33.2265
            ],
            [
              -91.079227,
              33.223889
            ],
            [
              -91.079635,
              33.223225
            ],
            [
              -91.082878,
              33.221621
            ],
            [
              -91.085984,
              33.221644
            ],
            [
              -91.091711,
              33.220813
            ],
            [
              -91.089909,
              33.210194
            ],
            [
              -91.086963,
              33.198509
            ],
            [
              -91.084366,
              33.180856
            ],
            [
              -91.084889,
              33.1618
            ],
            [
              -91.085562,
              33.155822
            ],
            [
              -91.0869117647879,
              33.1487335707116
            ],
            [
              -91.087589,
              33.145177
            ],
            [
              -91.089862,
              33.139655
            ],
            [
              -91.093201,
              33.136726
            ],
            [
              -91.104317,
              33.131598
            ],
            [
              -91.114087,
              33.129834
            ],
            [
              -91.131659,
              33.129101
            ],
            [
              -91.143334,
              33.129785
            ],
            [
              -91.150362,
              33.130695
            ],
            [
              -91.151853,
              33.131802
            ],
            [
              -91.153015,
              33.135093
            ],
            [
              -91.160298,
              33.141216
            ],
            [
              -91.161651,
              33.141781
            ],
            [
              -91.169406,
              33.142639
            ],
            [
              -91.183662,
              33.141691
            ],
            [
              -91.188718,
              33.139811
            ],
            [
              -91.193174,
              33.136734
            ],
            [
              -91.195296,
              33.134731
            ],
            [
              -91.20178,
              33.125121
            ],
            [
              -91.202089,
              33.121249
            ],
            [
              -91.201462,
              33.109638
            ],
            [
              -91.201125,
              33.108185
            ],
            [
              -91.200167,
              33.10693
            ],
            [
              -91.195953,
              33.104561
            ],
            [
              -91.180836,
              33.098364
            ],
            [
              -91.174723,
              33.09164
            ],
            [
              -91.173546,
              33.089318
            ],
            [
              -91.171514,
              33.087818
            ],
            [
              -91.165918,
              33.086174
            ],
            [
              -91.160656,
              33.085596
            ],
            [
              -91.149823,
              33.081603
            ],
            [
              -91.124639,
              33.064127
            ],
            [
              -91.121195,
              33.059166
            ],
            [
              -91.120293,
              33.055921
            ],
            [
              -91.120379,
              33.05453
            ],
            [
              -91.123441,
              33.046577
            ],
            [
              -91.125656,
              33.038276
            ],
            [
              -91.129088,
              33.033554
            ],
            [
              -91.137439,
              33.028736
            ],
            [
              -91.142424,
              33.027231
            ],
            [
              -91.149758,
              33.026312
            ],
            [
              -91.15616,
              33.02358
            ],
            [
              -91.162363,
              33.019684
            ],
            [
              -91.166282,
              33.011331
            ],
            [
              -90.965433,
              33.010545
            ],
            [
              -90.914337,
              33.007697
            ],
            [
              -90.914465,
              33.097417
            ],
            [
              -90.862866,
              33.095106
            ],
            [
              -90.701845,
              33.096294
            ],
            [
              -90.679607,
              33.102483
            ],
            [
              -90.679551,
              33.114586
            ],
            [
              -90.699732,
              33.121997
            ],
            [
              -90.698502,
              33.138033
            ],
            [
              -90.688136,
              33.148468
            ],
            [
              -90.685693,
              33.189611
            ],
            [
              -90.680319,
              33.201971
            ],
            [
              -90.704174,
              33.2126
            ],
            [
              -90.733216,
              33.232975
            ],
            [
              -90.727711,
              33.267507
            ],
            [
              -90.716277,
              33.270032
            ],
            [
              -90.763773,
              33.270359
            ],
            [
              -90.76412,
              33.372756
            ],
            [
              -90.764484,
              33.445692
            ],
            [
              -90.76095,
              33.526442
            ],
            [
              -90.763509,
              33.529389
            ],
            [
              -90.822955,
              33.52988
            ],
            [
              -90.865885,
              33.529387
            ],
            [
              -90.971457,
              33.530276
            ],
            [
              -91.215671,
              33.529423
            ],
            [
              -91.187367,
              33.510552
            ],
            [
              -91.184612,
              33.507364
            ],
            [
              -91.182901,
              33.502379
            ],
            [
              -91.18307,
              33.498613
            ],
            [
              -91.185637,
              33.496399
            ],
            [
              -91.189375,
              33.493005
            ],
            [
              -91.195634,
              33.488468
            ],
            [
              -91.199593,
              33.483125
            ],
            [
              -91.205661,
              33.473553
            ],
            [
              -91.206753,
              33.470308
            ],
            [
              -91.208535,
              33.468606
            ],
            [
              -91.215508,
              33.46478
            ],
            [
              -91.220192,
              33.463045
            ],
            [
              -91.223338,
              33.462764
            ],
            [
              -91.231661,
              33.4571
            ],
            [
              -91.232587,
              33.453958
            ],
            [
              -91.233422,
              33.444038
            ],
            [
              -91.23431,
              33.4423
            ],
            [
              -91.235928,
              33.440611
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "107",
      "COUNTYNS": "00695776",
      "AFFGEOID": "0500000US28107",
      "GEOID": "28107",
      "NAME": "Panola",
      "LSAD": "06",
      "ALAND": 1774512450,
      "AWATER": 51769784,
      "County_state": "Panola,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.198631,
              34.554425
            ],
            [
              -90.198536,
              34.511088
            ],
            [
              -90.193414,
              34.340274
            ],
            [
              -90.194086,
              34.301407
            ],
            [
              -90.134974,
              34.266212
            ],
            [
              -90.134762,
              34.226029
            ],
            [
              -90.135456,
              34.160074
            ],
            [
              -90.119166,
              34.160055
            ],
            [
              -89.958579,
              34.159554
            ],
            [
              -89.931485,
              34.164303
            ],
            [
              -89.721431,
              34.163513
            ],
            [
              -89.72105,
              34.192545
            ],
            [
              -89.719382,
              34.338039
            ],
            [
              -89.721008,
              34.349182
            ],
            [
              -89.721013,
              34.448581
            ],
            [
              -89.720941,
              34.450933
            ],
            [
              -89.721341,
              34.554274
            ],
            [
              -89.792165,
              34.55385
            ],
            [
              -89.862712,
              34.553807
            ],
            [
              -89.915636,
              34.553692
            ],
            [
              -90.198631,
              34.554425
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "045",
      "COUNTYNS": "00711756",
      "AFFGEOID": "0500000US28045",
      "GEOID": "28045",
      "NAME": "Hancock",
      "LSAD": "06",
      "ALAND": 1227560683,
      "AWATER": 204096133,
      "County_state": "Hancock,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.6897794685397,
              30.4592779821573
            ],
            [
              -89.68341,
              30.451793
            ],
            [
              -89.682829,
              30.44581
            ],
            [
              -89.684816,
              30.439511
            ],
            [
              -89.683521,
              30.434959
            ],
            [
              -89.681946,
              30.434073
            ],
            [
              -89.680515,
              30.428924
            ],
            [
              -89.678514,
              30.414012
            ],
            [
              -89.680134,
              30.4114
            ],
            [
              -89.681165,
              30.411492
            ],
            [
              -89.68232,
              30.412991
            ],
            [
              -89.684118,
              30.412646
            ],
            [
              -89.683686,
              30.405873
            ],
            [
              -89.6815402880308,
              30.4030887339946
            ],
            [
              -89.679153,
              30.399991
            ],
            [
              -89.672762,
              30.389276
            ],
            [
              -89.670134,
              30.382429
            ],
            [
              -89.662204,
              30.371267
            ],
            [
              -89.660274,
              30.363487
            ],
            [
              -89.657191,
              30.356515
            ],
            [
              -89.652693,
              30.355536
            ],
            [
              -89.6467,
              30.3525
            ],
            [
              -89.645617,
              30.351314
            ],
            [
              -89.645199,
              30.348126
            ],
            [
              -89.636299,
              30.34397
            ],
            [
              -89.629727,
              30.339287
            ],
            [
              -89.630399,
              30.332933
            ],
            [
              -89.629877,
              30.321017
            ],
            [
              -89.626606,
              30.315457
            ],
            [
              -89.626221,
              30.314255
            ],
            [
              -89.631643,
              30.309332
            ],
            [
              -89.634208,
              30.308256
            ],
            [
              -89.639872,
              30.307281
            ],
            [
              -89.640401,
              30.306755
            ],
            [
              -89.641705,
              30.303799
            ],
            [
              -89.64344,
              30.287682
            ],
            [
              -89.637647,
              30.285032
            ],
            [
              -89.631411,
              30.279662
            ],
            [
              -89.63052,
              30.276562
            ],
            [
              -89.629757,
              30.267195
            ],
            [
              -89.630649,
              30.262084
            ],
            [
              -89.631215,
              30.261704
            ],
            [
              -89.632225,
              30.260137
            ],
            [
              -89.631789,
              30.256924
            ],
            [
              -89.626922,
              30.251745
            ],
            [
              -89.623856,
              30.249895
            ],
            [
              -89.616156,
              30.247395
            ],
            [
              -89.614156,
              30.244595
            ],
            [
              -89.614056,
              30.241495
            ],
            [
              -89.615856,
              30.235295
            ],
            [
              -89.617056,
              30.227495
            ],
            [
              -89.616956,
              30.225595
            ],
            [
              -89.615856,
              30.223195
            ],
            [
              -89.612556,
              30.219496
            ],
            [
              -89.610655,
              30.218096
            ],
            [
              -89.607655,
              30.217096
            ],
            [
              -89.601255,
              30.216096
            ],
            [
              -89.596655,
              30.211796
            ],
            [
              -89.588854,
              30.200296
            ],
            [
              -89.587354,
              30.195196
            ],
            [
              -89.585754,
              30.192096
            ],
            [
              -89.580754,
              30.186197
            ],
            [
              -89.574454,
              30.181697
            ],
            [
              -89.570154,
              30.180297
            ],
            [
              -89.562253,
              30.182397
            ],
            [
              -89.554653,
              30.185797
            ],
            [
              -89.550853,
              30.189197
            ],
            [
              -89.549053,
              30.191597
            ],
            [
              -89.546953,
              30.193097
            ],
            [
              -89.541453,
              30.195397
            ],
            [
              -89.538652,
              30.195797
            ],
            [
              -89.533352,
              30.194797
            ],
            [
              -89.530452,
              30.192197
            ],
            [
              -89.527952,
              30.188697
            ],
            [
              -89.524504,
              30.180753
            ],
            [
              -89.522814,
              30.183076
            ],
            [
              -89.503231,
              30.183051
            ],
            [
              -89.490099,
              30.187677
            ],
            [
              -89.480214,
              30.193751
            ],
            [
              -89.475824,
              30.191561
            ],
            [
              -89.469792,
              30.176278
            ],
            [
              -89.463595,
              30.173943
            ],
            [
              -89.461275,
              30.174745
            ],
            [
              -89.44791,
              30.185352
            ],
            [
              -89.447465,
              30.205098
            ],
            [
              -89.430428,
              30.223218
            ],
            [
              -89.424493,
              30.239092
            ],
            [
              -89.424624,
              30.245391
            ],
            [
              -89.419348,
              30.25432
            ],
            [
              -89.383747,
              30.267796
            ],
            [
              -89.379547,
              30.270396
            ],
            [
              -89.365747,
              30.284896
            ],
            [
              -89.358546,
              30.288896
            ],
            [
              -89.344746,
              30.293196
            ],
            [
              -89.329946,
              30.302896
            ],
            [
              -89.322545,
              30.314896
            ],
            [
              -89.322345,
              30.319296
            ],
            [
              -89.332546,
              30.337895
            ],
            [
              -89.338847,
              30.342995
            ],
            [
              -89.345934,
              30.343573
            ],
            [
              -89.3540950268691,
              30.347048977949
            ],
            [
              -89.366116,
              30.352169
            ],
            [
              -89.363848,
              30.357395
            ],
            [
              -89.353248,
              30.368795
            ],
            [
              -89.349447,
              30.370995
            ],
            [
              -89.340947,
              30.372896406886298
            ],
            [
              -89.341564,
              30.459783
            ],
            [
              -89.341534,
              30.550867
            ],
            [
              -89.341446,
              30.64772
            ],
            [
              -89.544232,
              30.648368
            ],
            [
              -89.543762,
              30.585704
            ],
            [
              -89.54451,
              30.516862
            ],
            [
              -89.595873,
              30.517069
            ],
            [
              -89.599154,
              30.502474
            ],
            [
              -89.647072,
              30.502738
            ],
            [
              -89.646961,
              30.458892
            ],
            [
              -89.6897794685397,
              30.4592779821573
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "067",
      "COUNTYNS": "00695757",
      "AFFGEOID": "0500000US28067",
      "GEOID": "28067",
      "NAME": "Jones",
      "LSAD": "06",
      "ALAND": 1799558905,
      "AWATER": 12676802,
      "County_state": "Jones,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.401227,
              31.796863
            ],
            [
              -89.399178,
              31.434029
            ],
            [
              -89.319762,
              31.434045
            ],
            [
              -89.299027,
              31.434149
            ],
            [
              -89.146092,
              31.434052
            ],
            [
              -89.013412,
              31.433599
            ],
            [
              -88.943468,
              31.433458
            ],
            [
              -88.943169,
              31.784214
            ],
            [
              -88.943355,
              31.824565
            ],
            [
              -89.079491,
              31.816268
            ],
            [
              -89.082826,
              31.816071
            ],
            [
              -89.198211,
              31.809257
            ],
            [
              -89.264668,
              31.80523
            ],
            [
              -89.31656,
              31.80209
            ],
            [
              -89.401227,
              31.796863
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "003",
      "COUNTYNS": "00711755",
      "AFFGEOID": "0500000US28003",
      "GEOID": "28003",
      "NAME": "Alcorn",
      "LSAD": "06",
      "ALAND": 1036100568,
      "AWATER": 3417891,
      "County_state": "Alcorn,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.823305,
              34.950334
            ],
            [
              -88.823473,
              34.858629
            ],
            [
              -88.752956,
              34.858775
            ],
            [
              -88.753065,
              34.75696
            ],
            [
              -88.718508,
              34.756781
            ],
            [
              -88.700983,
              34.756744
            ],
            [
              -88.365472,
              34.755604
            ],
            [
              -88.36567,
              34.799421
            ],
            [
              -88.3635308623519,
              34.99558961029079
            ],
            [
              -88.380508,
              34.99561
            ],
            [
              -88.469801,
              34.996052
            ],
            [
              -88.469877,
              34.996033
            ],
            [
              -88.51421417673279,
              34.9959236741123
            ],
            [
              -88.786612,
              34.995252
            ],
            [
              -88.8230487339323,
              34.9951575501037
            ],
            [
              -88.823305,
              34.950334
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "129",
      "COUNTYNS": "00695785",
      "AFFGEOID": "0500000US28129",
      "GEOID": "28129",
      "NAME": "Smith",
      "LSAD": "06",
      "ALAND": 1647926085,
      "AWATER": 2599432,
      "County_state": "Smith,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.730424,
              32.222039
            ],
            [
              -89.731059,
              32.221946
            ],
            [
              -89.72987,
              32.090707
            ],
            [
              -89.730066,
              32.047072
            ],
            [
              -89.662086,
              32.047239
            ],
            [
              -89.654426,
              32.013678
            ],
            [
              -89.654637,
              31.974676
            ],
            [
              -89.654501,
              31.783755
            ],
            [
              -89.653025,
              31.780653
            ],
            [
              -89.401227,
              31.796863
            ],
            [
              -89.31656,
              31.80209
            ],
            [
              -89.317086,
              31.874305
            ],
            [
              -89.321158,
              31.888745
            ],
            [
              -89.322692,
              32.223472
            ],
            [
              -89.701621,
              32.223757
            ],
            [
              -89.730424,
              32.222039
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "155",
      "COUNTYNS": "00695798",
      "AFFGEOID": "0500000US28155",
      "GEOID": "28155",
      "NAME": "Webster",
      "LSAD": "06",
      "ALAND": 1090223315,
      "AWATER": 5935288,
      "County_state": "Webster,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.507135,
              33.721819
            ],
            [
              -89.506872,
              33.677605
            ],
            [
              -89.506616,
              33.576289
            ],
            [
              -89.506496,
              33.459806
            ],
            [
              -89.417705,
              33.460107
            ],
            [
              -89.381092,
              33.460307
            ],
            [
              -89.362339,
              33.473408
            ],
            [
              -89.34028,
              33.469496
            ],
            [
              -89.319794,
              33.496263
            ],
            [
              -89.271715,
              33.511165
            ],
            [
              -89.261102,
              33.518594
            ],
            [
              -89.228853,
              33.519747
            ],
            [
              -89.180963,
              33.532845
            ],
            [
              -89.088446,
              33.533202
            ],
            [
              -89.088263,
              33.562039
            ],
            [
              -89.035677,
              33.562154
            ],
            [
              -89.018613,
              33.562114
            ],
            [
              -89.018625,
              33.593037
            ],
            [
              -89.01854,
              33.635373
            ],
            [
              -89.036021,
              33.633569
            ],
            [
              -89.034622,
              33.683418
            ],
            [
              -89.034498,
              33.73943
            ],
            [
              -89.191781,
              33.738602
            ],
            [
              -89.191924,
              33.722158
            ],
            [
              -89.39365,
              33.721721
            ],
            [
              -89.507135,
              33.721819
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "28",
      "COUNTYFP": "001",
      "COUNTYNS": "00695726",
      "AFFGEOID": "0500000US28001",
      "GEOID": "28001",
      "NAME": "Adams",
      "LSAD": "06",
      "ALAND": 1197464269,
      "AWATER": 65273640,
      "County_state": "Adams,28"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.655009,
              31.251815
            ],
            [
              -91.654907,
              31.250175
            ],
            [
              -91.652019,
              31.242691
            ],
            [
              -91.644356,
              31.234414
            ],
            [
              -91.625119,
              31.226071
            ],
            [
              -91.601616,
              31.208573
            ],
            [
              -91.595029,
              31.201969
            ],
            [
              -91.590051,
              31.193693
            ],
            [
              -91.5894512930811,
              31.1911399311563
            ],
            [
              -91.57567,
              31.224475
            ],
            [
              -91.560538,
              31.235604
            ],
            [
              -91.540907,
              31.238346
            ],
            [
              -91.53037,
              31.230353
            ],
            [
              -91.530126,
              31.214842
            ],
            [
              -91.50729,
              31.225977
            ],
            [
              -91.487057,
              31.227471
            ],
            [
              -91.478799,
              31.238999
            ],
            [
              -91.477301,
              31.259876
            ],
            [
              -91.497369,
              31.276412
            ],
            [
              -91.49487,
              31.284334
            ],
            [
              -91.47106,
              31.285607
            ],
            [
              -91.468435,
              31.31957
            ],
            [
              -91.424012,
              31.31504
            ],
            [
              -91.413048,
              31.323868
            ],
            [
              -91.391738,
              31.324801
            ],
            [
              -91.384256,
              31.33542
            ],
            [
              -91.36665,
              31.335922
            ],
            [
              -91.355341,
              31.343587
            ],
            [
              -91.341691,
              31.340034
            ],
            [
              -91.330855,
              31.351776
            ],
            [
              -91.306294,
              31.360862
            ],
            [
              -91.283005,
              31.357778
            ],
            [
              -91.272869,
              31.368243
            ],
            [
              -91.257134,
              31.360127
            ],
            [
              -91.231134,
              31.37317
            ],
            [
              -91.213055,
              31.3571
            ],
            [
              -91.186857,
              31.349281
            ],
            [
              -91.15815,
              31.346695
            ],
            [
              -91.156314,
              31.425119
            ],
            [
              -91.156141,
              31.436188
            ],
            [
              -91.153864,
              31.610068
            ],
            [
              -91.153841,
              31.630488
            ],
            [
              -91.233022,
              31.629158
            ],
            [
              -91.241123,
              31.636949
            ],
            [
              -91.269313,
              31.647254
            ],
            [
              -91.267023,
              31.663897
            ],
            [
              -91.300115,
              31.672235
            ],
            [
              -91.291833,
              31.691609
            ],
            [
              -91.306939,
              31.711026
            ],
            [
              -91.3165801943028,
              31.7453109122142
            ],
            [
              -91.318576,
              31.745315
            ],
            [
              -91.334666,
              31.744071
            ],
            [
              -91.355144,
              31.741583
            ],
            [
              -91.3800788050052,
              31.7327598853811
            ],
            [
              -91.380915,
              31.732464
            ],
            [
              -91.397115,
              31.711364
            ],
            [
              -91.397915,
              31.709364
            ],
            [
              -91.400015,
              31.697864
            ],
            [
              -91.400115,
              31.688164
            ],
            [
              -91.398015,
              31.662665
            ],
            [
              -91.395715,
              31.644165
            ],
            [
              -91.396115,
              31.639265
            ],
            [
              -91.398315,
              31.626265
            ],
            [
              -91.401015,
              31.620365
            ],
            [
              -91.416498,
              31.613133
            ],
            [
              -91.421116,
              31.611565
            ],
            [
              -91.429616,
              31.611365
            ],
            [
              -91.436716,
              31.612665
            ],
            [
              -91.463817,
              31.620365
            ],
            [
              -91.474318,
              31.625365
            ],
            [
              -91.479818,
              31.628965
            ],
            [
              -91.482618,
              31.631565
            ],
            [
              -91.487518,
              31.637065
            ],
            [
              -91.490027,
              31.641
            ],
            [
              -91.492748,
              31.644279
            ],
            [
              -91.494478,
              31.645013
            ],
            [
              -91.497665,
              31.645371
            ],
            [
              -91.499278,
              31.644658
            ],
            [
              -91.50731,
              31.639068
            ],
            [
              -91.512336,
              31.634722
            ],
            [
              -91.515462,
              31.630372
            ],
            [
              -91.516659,
              31.627332
            ],
            [
              -91.517921,
              31.618642
            ],
            [
              -91.517233,
              31.61346
            ],
            [
              -91.516567,
              31.611818
            ],
            [
              -91.5148155315998,
              31.6093889885807
            ],
            [
              -91.51301,
              31.606885
            ],
            [
              -91.502783,
              31.595727
            ],
            [
              -91.494118,
              31.590165
            ],
            [
              -91.488618,
              31.587466
            ],
            [
              -91.485218,
              31.586166
            ],
            [
              -91.479418,
              31.585466
            ],
            [
              -91.466317,
              31.586066
            ],
            [
              -91.457517,
              31.587566
            ],
            [
              -91.436316,
              31.594965
            ],
            [
              -91.430716,
              31.596465
            ],
            [
              -91.422716,
              31.597065
            ],
            [
              -91.417115,
              31.596265
            ],
            [
              -91.413015,
              31.595365
            ],
            [
              -91.403915,
              31.589766
            ],
            [
              -91.405415,
              31.576466
            ],
            [
              -91.406615,
              31.571966
            ],
            [
              -91.4077734,
              31.5696492
            ],
            [
              -91.407915,
              31.569366
            ],
            [
              -91.414915,
              31.562166
            ],
            [
              -91.426416,
              31.554566
            ],
            [
              -91.437616,
              31.546166
            ],
            [
              -91.443916,
              31.542466
            ],
            [
              -91.450017,
              31.539666
            ],
            [
              -91.479718,
              31.530366
            ],
            [
              -91.481318,
              31.530666
            ],
            [
              -91.483918,
              31.532566
            ],
            [
              -91.489618,
              31.534266
            ],
            [
              -91.500118,
              31.532616
            ],
            [
              -91.506719,
              31.532966
            ],
            [
              -91.511217,
              31.532612
            ],
            [
              -91.513963,
              31.532084
            ],
            [
              -91.51581,
              31.530894
            ],
            [
              -91.521445,
              31.523912
            ],
            [
              -91.522536,
              31.522078
            ],
            [
              -91.52292,
              31.519841
            ],
            [
              -91.522862,
              31.517493
            ],
            [
              -91.520579,
              31.513207
            ],
            [
              -91.516759,
              31.511772
            ],
            [
              -91.514917,
              31.510113
            ],
            [
              -91.515157,
              31.50338
            ],
            [
              -91.51714,
              31.498394
            ],
            [
              -91.518148,
              31.483483
            ],
            [
              -91.516999,
              31.460574
            ],
            [
              -91.51513,
              31.449206
            ],
            [
              -91.513366,
              31.444396
            ],
            [
              -91.510356,
              31.438928
            ],
            [
              -91.506423,
              31.43146
            ],
            [
              -91.500046,
              31.42052
            ],
            [
              -91.49004,
              31.412716
            ],
            [
              -91.48023,
              31.404391
            ],
            [
              -91.476926,
              31.397796
            ],
            [
              -91.472065,
              31.395925
            ],
            [
              -91.472149,
              31.391434
            ],
            [
              -91.471992,
              31.382143
            ],
            [
              -91.471098,
              31.376917
            ],
            [
              -91.472465,
              31.371326
            ],
            [
              -91.47887,
              31.364955
            ],
            [
              -91.504163,
              31.36495
            ],
            [
              -91.508075,
              31.366276
            ],
            [
              -91.515978,
              31.370286
            ],
            [
              -91.521836,
              31.37517
            ],
            [
              -91.52695,
              31.380855
            ],
            [
              -91.532336,
              31.390275
            ],
            [
              -91.539458,
              31.414021
            ],
            [
              -91.539504,
              31.41791
            ],
            [
              -91.537002,
              31.423184
            ],
            [
              -91.537137,
              31.424161
            ],
            [
              -91.540647,
              31.430758
            ],
            [
              -91.541626,
              31.431706
            ],
            [
              -91.545013,
              31.433026
            ],
            [
              -91.548465,
              31.432668
            ],
            [
              -91.55275,
              31.430692
            ],
            [
              -91.554805,
              31.42857
            ],
            [
              -91.565179,
              31.423447
            ],
            [
              -91.570092,
              31.419487
            ],
            [
              -91.576265,
              31.410498
            ],
            [
              -91.578246,
              31.403859
            ],
            [
              -91.578334,
              31.399067
            ],
            [
              -91.573931,
              31.390886
            ],
            [
              -91.571234,
              31.384664
            ],
            [
              -91.568953,
              31.377629
            ],
            [
              -91.562555,
              31.383219
            ],
            [
              -91.560524,
              31.384559
            ],
            [
              -91.55568,
              31.386413
            ],
            [
              -91.552432,
              31.385658
            ],
            [
              -91.546207,
              31.38248
            ],
            [
              -91.546607,
              31.381198
            ],
            [
              -91.549915,
              31.376315
            ],
            [
              -91.551002,
              31.363645
            ],
            [
              -91.550869,
              31.360574
            ],
            [
              -91.548894,
              31.353998
            ],
            [
              -91.548967,
              31.347255
            ],
            [
              -91.545617,
              31.343762
            ],
            [
              -91.536061,
              31.338355
            ],
            [
              -91.533206,
              31.333225
            ],
            [
              -91.531657,
              31.331801
            ],
            [
              -91.518509,
              31.323332
            ],
            [
              -91.510049,
              31.316822
            ],
            [
              -91.50866,
              31.315131
            ],
            [
              -91.507977,
              31.312943
            ],
            [
              -91.508296,
              31.295829
            ],
            [
              -91.508858,
              31.291644
            ],
            [
              -91.512085,
              31.284177
            ],
            [
              -91.515614,
              31.27821
            ],
            [
              -91.518578,
              31.275283
            ],
            [
              -91.537734,
              31.267369
            ],
            [
              -91.553905,
              31.26305
            ],
            [
              -91.564192,
              31.261633
            ],
            [
              -91.574493,
              31.261289
            ],
            [
              -91.587749,
              31.262563
            ],
            [
              -91.606672,
              31.2659
            ],
            [
              -91.621358,
              31.267811
            ],
            [
              -91.637672,
              31.26768
            ],
            [
              -91.641253,
              31.266917
            ],
            [
              -91.648669,
              31.262238
            ],
            [
              -91.651369,
              31.259528
            ],
            [
              -91.654027,
              31.255753
            ],
            [
              -91.655009,
              31.251815
            ]
          ]
        ]
      ]
    }
  }
]
};