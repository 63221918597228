export const counties_ny = {
  "type":"FeatureCollection","name":"ny_counties","features":[
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "095",
      "COUNTYNS": "00974145",
      "AFFGEOID": "0500000US36095",
      "GEOID": "36095",
      "NAME": "Schoharie",
      "LSAD": "06",
      "ALAND": 1610503572,
      "AWATER": 11676949,
      "County_state": "Schoharie,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -74.672428,
              42.564455
            ],
            [
              -74.71158,
              42.517799
            ],
            [
              -74.618895,
              42.424389
            ],
            [
              -74.443506,
              42.355017
            ],
            [
              -74.379051,
              42.373873
            ],
            [
              -74.346087,
              42.355691
            ],
            [
              -74.276645,
              42.363349
            ],
            [
              -74.259186,
              42.379117
            ],
            [
              -74.244692,
              42.377159
            ],
            [
              -74.254303,
              42.408207
            ],
            [
              -74.263469,
              42.407127
            ],
            [
              -74.264863,
              42.419863
            ],
            [
              -74.244457,
              42.470265
            ],
            [
              -74.228501,
              42.494381
            ],
            [
              -74.225639,
              42.526403
            ],
            [
              -74.234419,
              42.53784
            ],
            [
              -74.241572,
              42.550802
            ],
            [
              -74.233995,
              42.575688
            ],
            [
              -74.193495,
              42.618999
            ],
            [
              -74.169725,
              42.667426
            ],
            [
              -74.164678,
              42.717207
            ],
            [
              -74.180274,
              42.729979
            ],
            [
              -74.184235,
              42.7125
            ],
            [
              -74.239846,
              42.718924
            ],
            [
              -74.272295,
              42.71427
            ],
            [
              -74.30533,
              42.747396
            ],
            [
              -74.286988,
              42.756685
            ],
            [
              -74.258797,
              42.757135
            ],
            [
              -74.248062,
              42.78457
            ],
            [
              -74.263314,
              42.796534
            ],
            [
              -74.289446,
              42.783805
            ],
            [
              -74.411384,
              42.77649
            ],
            [
              -74.454911,
              42.772979
            ],
            [
              -74.562477,
              42.812916
            ],
            [
              -74.648298,
              42.829558
            ],
            [
              -74.665383,
              42.791873
            ],
            [
              -74.667512,
              42.75071
            ],
            [
              -74.634637,
              42.647809
            ],
            [
              -74.630631,
              42.626674
            ],
            [
              -74.672428,
              42.564455
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "099",
      "COUNTYNS": "00974147",
      "AFFGEOID": "0500000US36099",
      "GEOID": "36099",
      "NAME": "Seneca",
      "LSAD": "06",
      "ALAND": 838403626,
      "AWATER": 172828921,
      "County_state": "Seneca,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.978526,
              42.869072
            ],
            [
              -76.980647,
              42.857064
            ],
            [
              -76.973453,
              42.833747
            ],
            [
              -76.977888,
              42.77324
            ],
            [
              -76.971392,
              42.764223
            ],
            [
              -76.947429,
              42.76443
            ],
            [
              -76.946737,
              42.749743
            ],
            [
              -76.935365,
              42.715629
            ],
            [
              -76.897117,
              42.66365
            ],
            [
              -76.894061,
              42.578795
            ],
            [
              -76.895596,
              42.541537
            ],
            [
              -76.696655,
              42.54679
            ],
            [
              -76.585989,
              42.54991
            ],
            [
              -76.618239,
              42.566652
            ],
            [
              -76.645317,
              42.591708
            ],
            [
              -76.666543,
              42.623457
            ],
            [
              -76.685311,
              42.670281
            ],
            [
              -76.728951,
              42.712898
            ],
            [
              -76.737519,
              42.753911
            ],
            [
              -76.74006,
              42.789419
            ],
            [
              -76.717185,
              42.83883
            ],
            [
              -76.721246,
              42.869579
            ],
            [
              -76.733468,
              42.894246
            ],
            [
              -76.739361,
              42.954473
            ],
            [
              -76.713806,
              43.024035
            ],
            [
              -76.963926,
              43.013157
            ],
            [
              -76.963365,
              42.872945
            ],
            [
              -76.978526,
              42.869072
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "073",
      "COUNTYNS": "00974135",
      "AFFGEOID": "0500000US36073",
      "GEOID": "36073",
      "NAME": "Orleans",
      "LSAD": "06",
      "ALAND": 1013366849,
      "AWATER": 1103741896,
      "County_state": "Orleans,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.4655021200225,
              43.3712323915095
            ],
            [
              -78.466429,
              43.302775
            ],
            [
              -78.460416,
              43.216222
            ],
            [
              -78.465505,
              43.128619
            ],
            [
              -78.310512,
              43.131295
            ],
            [
              -77.99729,
              43.132981
            ],
            [
              -77.996899,
              43.219521
            ],
            [
              -77.996543,
              43.254769
            ],
            [
              -77.995698,
              43.284963
            ],
            [
              -77.99559132206089,
              43.3652934573297
            ],
            [
              -78.023609,
              43.366575
            ],
            [
              -78.104509,
              43.375628
            ],
            [
              -78.145195,
              43.37551
            ],
            [
              -78.233609,
              43.36907
            ],
            [
              -78.250641,
              43.370866
            ],
            [
              -78.358711,
              43.373988
            ],
            [
              -78.370221,
              43.376505
            ],
            [
              -78.4655021200225,
              43.3712323915095
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "117",
      "COUNTYNS": "00974156",
      "AFFGEOID": "0500000US36117",
      "GEOID": "36117",
      "NAME": "Wayne",
      "LSAD": "06",
      "ALAND": 1563903159,
      "AWATER": 2018194942,
      "County_state": "Wayne,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.3760378804266,
              43.2776524189036
            ],
            [
              -77.375048,
              43.196387
            ],
            [
              -77.371478,
              43.034696
            ],
            [
              -77.246804,
              43.034663
            ],
            [
              -77.245674,
              43.039763
            ],
            [
              -77.134307,
              43.039887
            ],
            [
              -77.133397,
              43.012463
            ],
            [
              -76.963926,
              43.013157
            ],
            [
              -76.713806,
              43.024035
            ],
            [
              -76.718614,
              43.033116
            ],
            [
              -76.717789,
              43.062634
            ],
            [
              -76.702324,
              43.080159
            ],
            [
              -76.7225013043099,
              43.3436861782835
            ],
            [
              -76.731039,
              43.343421
            ],
            [
              -76.747067,
              43.331477
            ],
            [
              -76.769025,
              43.318452
            ],
            [
              -76.794708,
              43.309632
            ],
            [
              -76.841675,
              43.305399
            ],
            [
              -76.854976,
              43.298443
            ],
            [
              -76.877397,
              43.292926
            ],
            [
              -76.886913,
              43.293891
            ],
            [
              -76.904288,
              43.291816
            ],
            [
              -76.922351,
              43.285006
            ],
            [
              -76.952174,
              43.270692
            ],
            [
              -76.958402,
              43.270005
            ],
            [
              -76.988445,
              43.2745
            ],
            [
              -76.999691,
              43.271456
            ],
            [
              -77.033875,
              43.271218
            ],
            [
              -77.067295,
              43.280937
            ],
            [
              -77.111866,
              43.287945
            ],
            [
              -77.130429,
              43.285635
            ],
            [
              -77.143416,
              43.287561
            ],
            [
              -77.173088,
              43.281509
            ],
            [
              -77.214058,
              43.284114
            ],
            [
              -77.264177,
              43.277363
            ],
            [
              -77.303979,
              43.27815
            ],
            [
              -77.314619,
              43.28103
            ],
            [
              -77.341092,
              43.280661
            ],
            [
              -77.3760378804266,
              43.2776524189036
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "039",
      "COUNTYNS": "00974118",
      "AFFGEOID": "0500000US36039",
      "GEOID": "36039",
      "NAME": "Greene",
      "LSAD": "06",
      "ALAND": 1676140490,
      "AWATER": 28201061,
      "County_state": "Greene,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -74.53731,
              42.201424
            ],
            [
              -74.451713,
              42.169225
            ],
            [
              -74.307571,
              42.114346
            ],
            [
              -74.271606,
              42.111625
            ],
            [
              -74.074797,
              42.096589
            ],
            [
              -74.042393,
              42.170386
            ],
            [
              -74.003589,
              42.163365
            ],
            [
              -74.00245,
              42.176992
            ],
            [
              -73.918356,
              42.128916
            ],
            [
              -73.910675,
              42.127293
            ],
            [
              -73.892319,
              42.167534
            ],
            [
              -73.855255,
              42.191415
            ],
            [
              -73.847813,
              42.230347
            ],
            [
              -73.835206,
              42.24208
            ],
            [
              -73.802996,
              42.253655
            ],
            [
              -73.783326,
              42.288356
            ],
            [
              -73.782406,
              42.32497
            ],
            [
              -73.794327,
              42.375084
            ],
            [
              -73.775817,
              42.429938
            ],
            [
              -73.776261,
              42.461824
            ],
            [
              -73.783721,
              42.464231
            ],
            [
              -73.93326,
              42.444814
            ],
            [
              -74.254303,
              42.408207
            ],
            [
              -74.244692,
              42.377159
            ],
            [
              -74.259186,
              42.379117
            ],
            [
              -74.276645,
              42.363349
            ],
            [
              -74.346087,
              42.355691
            ],
            [
              -74.379051,
              42.373873
            ],
            [
              -74.443506,
              42.355017
            ],
            [
              -74.428196,
              42.349075
            ],
            [
              -74.53731,
              42.201424
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "003",
      "COUNTYNS": "00974100",
      "AFFGEOID": "0500000US36003",
      "GEOID": "36003",
      "NAME": "Allegany",
      "LSAD": "06",
      "ALAND": 2665875177,
      "AWATER": 13153776,
      "County_state": "Allegany,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.308839,
              42.521217
            ],
            [
              -78.308128,
              41.999415
            ],
            [
              -78.271204,
              41.998968
            ],
            [
              -78.2066042785298,
              41.999622491491095
            ],
            [
              -78.12473,
              42.000452
            ],
            [
              -78.031177,
              41.999415
            ],
            [
              -78.030963,
              41.999392
            ],
            [
              -77.997508,
              41.998758
            ],
            [
              -77.83203,
              41.998524
            ],
            [
              -77.822799,
              41.998547
            ],
            [
              -77.749931,
              41.998782
            ],
            [
              -77.741531,
              42.19221
            ],
            [
              -77.736813,
              42.28153
            ],
            [
              -77.726111,
              42.404043
            ],
            [
              -77.722964,
              42.471216
            ],
            [
              -77.840231,
              42.474576
            ],
            [
              -77.840694,
              42.517771
            ],
            [
              -77.954956,
              42.518369
            ],
            [
              -78.043418,
              42.518464
            ],
            [
              -78.038261,
              42.521522
            ],
            [
              -78.0483,
              42.520989
            ],
            [
              -78.308839,
              42.521217
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "075",
      "COUNTYNS": "00974136",
      "AFFGEOID": "0500000US36075",
      "GEOID": "36075",
      "NAME": "Oswego",
      "LSAD": "06",
      "ALAND": 2464746080,
      "AWATER": 933480216,
      "County_state": "Oswego,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.607093,
              43.423374
            ],
            [
              -76.6171093082771,
              43.419136705277595
            ],
            [
              -76.605279,
              43.257718
            ],
            [
              -76.600761,
              43.253742
            ],
            [
              -76.481148,
              43.256641
            ],
            [
              -76.479224,
              43.227519
            ],
            [
              -76.344632,
              43.23267
            ],
            [
              -76.306437,
              43.230451
            ],
            [
              -76.281027,
              43.201091
            ],
            [
              -76.262051,
              43.218468
            ],
            [
              -76.275551,
              43.238768
            ],
            [
              -76.258951,
              43.237468
            ],
            [
              -76.24465,
              43.214568
            ],
            [
              -76.224449,
              43.204968
            ],
            [
              -76.194248,
              43.217068
            ],
            [
              -76.210609,
              43.244004
            ],
            [
              -76.21083,
              43.268473
            ],
            [
              -76.202573,
              43.271539
            ],
            [
              -76.157647,
              43.243268
            ],
            [
              -76.114311,
              43.232536
            ],
            [
              -76.068035,
              43.212688
            ],
            [
              -76.084448,
              43.213842
            ],
            [
              -76.07839,
              43.199416
            ],
            [
              -76.046131,
              43.202161
            ],
            [
              -76.005215,
              43.179352
            ],
            [
              -75.993394,
              43.183376
            ],
            [
              -75.989569,
              43.184866
            ],
            [
              -75.933138,
              43.176688
            ],
            [
              -75.925365,
              43.161708
            ],
            [
              -75.884275,
              43.155562
            ],
            [
              -75.862927,
              43.319274
            ],
            [
              -75.886756,
              43.325021
            ],
            [
              -75.814627,
              43.483578
            ],
            [
              -75.756213,
              43.470388
            ],
            [
              -75.766776,
              43.625069
            ],
            [
              -75.774553,
              43.688884
            ],
            [
              -76.025087,
              43.707018
            ],
            [
              -76.022003,
              43.668143
            ],
            [
              -76.2005002287568,
              43.68023076718659
            ],
            [
              -76.196596,
              43.649761
            ],
            [
              -76.199138,
              43.600454
            ],
            [
              -76.203473,
              43.574978
            ],
            [
              -76.209853,
              43.560136
            ],
            [
              -76.217958,
              43.545156
            ],
            [
              -76.228701,
              43.532987
            ],
            [
              -76.235834,
              43.529256
            ],
            [
              -76.259858,
              43.524728
            ],
            [
              -76.297103,
              43.51287
            ],
            [
              -76.330911,
              43.511978
            ],
            [
              -76.345492,
              43.513437
            ],
            [
              -76.368849,
              43.525822
            ],
            [
              -76.3709178164322,
              43.5256901585622
            ],
            [
              -76.410636,
              43.523159
            ],
            [
              -76.417581,
              43.521285
            ],
            [
              -76.437473,
              43.509213
            ],
            [
              -76.472498,
              43.492781
            ],
            [
              -76.486962,
              43.47535
            ],
            [
              -76.506858,
              43.469127
            ],
            [
              -76.515882,
              43.471136
            ],
            [
              -76.521999,
              43.468617
            ],
            [
              -76.53181,
              43.460299
            ],
            [
              -76.53734343497149,
              43.458200590723
            ],
            [
              -76.562826,
              43.448537
            ],
            [
              -76.607093,
              43.423374
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "059",
      "COUNTYNS": "00974128",
      "AFFGEOID": "0500000US36059",
      "GEOID": "36059",
      "NAME": "Nassau",
      "LSAD": "06",
      "ALAND": 737191047,
      "AWATER": 437282733,
      "County_state": "Nassau,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -73.768301,
              40.800797
            ],
            [
              -73.7691495733027,
              40.8001908918927
            ],
            [
              -73.750295,
              40.782478
            ],
            [
              -73.701633,
              40.752493
            ],
            [
              -73.700582,
              40.743184
            ],
            [
              -73.70002,
              40.73939
            ],
            [
              -73.707647,
              40.727796
            ],
            [
              -73.724722,
              40.724314
            ],
            [
              -73.730326,
              40.722157
            ],
            [
              -73.729176,
              40.719167
            ],
            [
              -73.726971,
              40.710715
            ],
            [
              -73.725861,
              40.683223
            ],
            [
              -73.728107,
              40.665345
            ],
            [
              -73.724874,
              40.653447
            ],
            [
              -73.732208,
              40.650091
            ],
            [
              -73.736735,
              40.6491
            ],
            [
              -73.73996,
              40.635144
            ],
            [
              -73.743295,
              40.638048
            ],
            [
              -73.748001,
              40.634631
            ],
            [
              -73.767425,
              40.626606
            ],
            [
              -73.768761,
              40.624352
            ],
            [
              -73.766719,
              40.615004
            ],
            [
              -73.754732,
              40.610405
            ],
            [
              -73.745633,
              40.611756
            ],
            [
              -73.737185,
              40.592965
            ],
            [
              -73.7503555869235,
              40.5895220262279
            ],
            [
              -73.742686,
              40.586791
            ],
            [
              -73.640902,
              40.582823
            ],
            [
              -73.610873,
              40.587703
            ],
            [
              -73.57441375392109,
              40.58440388067469
            ],
            [
              -73.570083,
              40.584012
            ],
            [
              -73.559093,
              40.582027
            ],
            [
              -73.507325,
              40.593411
            ],
            [
              -73.484901465472,
              40.5978245108009
            ],
            [
              -73.4238050058968,
              40.6098498176898
            ],
            [
              -73.425346,
              40.651818
            ],
            [
              -73.42388,
              40.655973
            ],
            [
              -73.427871,
              40.696283
            ],
            [
              -73.428103,
              40.697513
            ],
            [
              -73.431139,
              40.712731
            ],
            [
              -73.431821,
              40.717969
            ],
            [
              -73.432175,
              40.71974
            ],
            [
              -73.432934,
              40.723539
            ],
            [
              -73.436006,
              40.739458
            ],
            [
              -73.4385,
              40.751311
            ],
            [
              -73.444096,
              40.781606
            ],
            [
              -73.445113,
              40.785557
            ],
            [
              -73.462259,
              40.86671
            ],
            [
              -73.476079,
              40.877111
            ],
            [
              -73.477307,
              40.89925
            ],
            [
              -73.4970614703723,
              40.92280082944009
            ],
            [
              -73.499941,
              40.918166
            ],
            [
              -73.514999,
              40.912821
            ],
            [
              -73.519267,
              40.914298
            ],
            [
              -73.548068,
              40.908698
            ],
            [
              -73.566169,
              40.915798
            ],
            [
              -73.569969,
              40.915398
            ],
            [
              -73.59517,
              40.907298
            ],
            [
              -73.60187,
              40.902798
            ],
            [
              -73.617571,
              40.897898
            ],
            [
              -73.626972,
              40.899397
            ],
            [
              -73.633771,
              40.898198
            ],
            [
              -73.641072,
              40.892599
            ],
            [
              -73.654372,
              40.878199
            ],
            [
              -73.655872,
              40.863899
            ],
            [
              -73.6706921399942,
              40.8587084530247
            ],
            [
              -73.675573,
              40.856999
            ],
            [
              -73.713674,
              40.870099
            ],
            [
              -73.729575,
              40.8665
            ],
            [
              -73.730675,
              40.8654
            ],
            [
              -73.726675,
              40.8568
            ],
            [
              -73.728275,
              40.8529
            ],
            [
              -73.7544,
              40.826837
            ],
            [
              -73.754032,
              40.820941
            ],
            [
              -73.768301,
              40.800797
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "055",
      "COUNTYNS": "00974126",
      "AFFGEOID": "0500000US36055",
      "GEOID": "36055",
      "NAME": "Monroe",
      "LSAD": "06",
      "ALAND": 1702151431,
      "AWATER": 1837595092,
      "County_state": "Monroe,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.996899,
              43.219521
            ],
            [
              -77.99729,
              43.132981
            ],
            [
              -77.905934,
              43.133561
            ],
            [
              -77.908122,
              43.129335
            ],
            [
              -77.951044,
              43.039544
            ],
            [
              -77.904121,
              43.037056
            ],
            [
              -77.90754,
              43.002859
            ],
            [
              -77.909832,
              42.987762
            ],
            [
              -77.730957,
              42.988372
            ],
            [
              -77.720748,
              42.979193
            ],
            [
              -77.732378,
              42.945285
            ],
            [
              -77.759052,
              42.940462
            ],
            [
              -77.635022,
              42.944464
            ],
            [
              -77.580377,
              42.943963
            ],
            [
              -77.482517,
              42.943164
            ],
            [
              -77.485418,
              43.034564
            ],
            [
              -77.371478,
              43.034696
            ],
            [
              -77.375048,
              43.196387
            ],
            [
              -77.3760378804266,
              43.2776524189036
            ],
            [
              -77.391015,
              43.276363
            ],
            [
              -77.414516,
              43.269263
            ],
            [
              -77.436831,
              43.265701
            ],
            [
              -77.476642,
              43.254522
            ],
            [
              -77.50092,
              43.250363
            ],
            [
              -77.534184,
              43.234569
            ],
            [
              -77.5342514410039,
              43.234573782311294
            ],
            [
              -77.551022,
              43.235763
            ],
            [
              -77.577223,
              43.243263
            ],
            [
              -77.6021611595269,
              43.2569494086968
            ],
            [
              -77.62216716906501,
              43.267928984901395
            ],
            [
              -77.628315,
              43.271303
            ],
            [
              -77.653759,
              43.279484
            ],
            [
              -77.660359,
              43.282998
            ],
            [
              -77.701429,
              43.308261
            ],
            [
              -77.714129,
              43.323561
            ],
            [
              -77.73063,
              43.330161
            ],
            [
              -77.756931,
              43.337361
            ],
            [
              -77.760231,
              43.341161
            ],
            [
              -77.785132,
              43.339261
            ],
            [
              -77.797381,
              43.339857
            ],
            [
              -77.8088692218046,
              43.342078224172
            ],
            [
              -77.816533,
              43.34356
            ],
            [
              -77.875335,
              43.34966
            ],
            [
              -77.904836,
              43.35696
            ],
            [
              -77.922736,
              43.35696
            ],
            [
              -77.952937,
              43.36346
            ],
            [
              -77.965238,
              43.368059
            ],
            [
              -77.976438,
              43.369159
            ],
            [
              -77.994838,
              43.365259
            ],
            [
              -77.99559132206089,
              43.3652934573297
            ],
            [
              -77.995698,
              43.284963
            ],
            [
              -77.996543,
              43.254769
            ],
            [
              -77.996899,
              43.219521
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "043",
      "COUNTYNS": "00974120",
      "AFFGEOID": "0500000US36043",
      "GEOID": "36043",
      "NAME": "Herkimer",
      "LSAD": "06",
      "ALAND": 3655805548,
      "AWATER": 120422420,
      "County_state": "Herkimer,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -75.219106,
              43.052469
            ],
            [
              -75.216006,
              43.022769
            ],
            [
              -75.207362,
              42.940574
            ],
            [
              -75.201444,
              42.880664
            ],
            [
              -75.212158,
              42.879973
            ],
            [
              -75.210642,
              42.856549
            ],
            [
              -75.13987,
              42.85976
            ],
            [
              -75.100999,
              42.908363
            ],
            [
              -74.954822,
              42.846422
            ],
            [
              -74.906738,
              42.824943
            ],
            [
              -74.894671,
              42.832051
            ],
            [
              -74.878822,
              42.898274
            ],
            [
              -74.763303,
              42.863237
            ],
            [
              -74.740356,
              42.996588
            ],
            [
              -74.743715,
              42.99941
            ],
            [
              -74.743805,
              43.030741
            ],
            [
              -74.759895,
              43.047423
            ],
            [
              -74.763614,
              43.057962
            ],
            [
              -74.773734,
              43.083375
            ],
            [
              -74.758948,
              43.119562
            ],
            [
              -74.72825,
              43.125307
            ],
            [
              -74.733984,
              43.144229
            ],
            [
              -74.708975,
              43.149302
            ],
            [
              -74.718012,
              43.164735
            ],
            [
              -74.695985,
              43.174177
            ],
            [
              -74.712615,
              43.286143
            ],
            [
              -74.867712,
              43.339826
            ],
            [
              -74.775617,
              43.486677
            ],
            [
              -74.854171,
              44.070089
            ],
            [
              -75.062779,
              44.0504
            ],
            [
              -75.170159,
              44.096959
            ],
            [
              -75.11016,
              43.615229
            ],
            [
              -75.076581,
              43.330705
            ],
            [
              -75.099839,
              43.325175
            ],
            [
              -75.106495,
              43.313539
            ],
            [
              -75.11984,
              43.304637
            ],
            [
              -75.147995,
              43.305159
            ],
            [
              -75.162371,
              43.258463
            ],
            [
              -75.122684,
              43.241048
            ],
            [
              -75.069165,
              43.227333
            ],
            [
              -75.160906,
              43.114769
            ],
            [
              -75.179646,
              43.094736
            ],
            [
              -75.182334,
              43.075303
            ],
            [
              -75.207807,
              43.064469
            ],
            [
              -75.219106,
              43.052469
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "061",
      "COUNTYNS": "00974129",
      "AFFGEOID": "0500000US36061",
      "GEOID": "36061",
      "NAME": "New York",
      "LSAD": "06",
      "ALAND": 58681329,
      "AWATER": 28550878,
      "County_state": "New York,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -74.047313,
              40.690466
            ],
            [
              -74.046359,
              40.689175
            ],
            [
              -74.044451,
              40.688445
            ],
            [
              -74.025442,
              40.684191
            ],
            [
              -74.0220537924917,
              40.6804990456477
            ],
            [
              -74.015275,
              40.682142
            ],
            [
              -74.008425,
              40.686016
            ],
            [
              -74.00096,
              40.690132
            ],
            [
              -73.994498,
              40.704318
            ],
            [
              -73.979233,
              40.705769
            ],
            [
              -73.972789,
              40.709378
            ],
            [
              -73.966511,
              40.717917
            ],
            [
              -73.965572,
              40.71895
            ],
            [
              -73.962645,
              40.722747
            ],
            [
              -73.962148,
              40.732452
            ],
            [
              -73.962478,
              40.736802
            ],
            [
              -73.961381,
              40.742802
            ],
            [
              -73.960211,
              40.744511
            ],
            [
              -73.935323,
              40.770485
            ],
            [
              -73.934412,
              40.778093
            ],
            [
              -73.928277,
              40.776899
            ],
            [
              -73.920999,
              40.781749
            ],
            [
              -73.917171,
              40.784193
            ],
            [
              -73.912456,
              40.796096
            ],
            [
              -73.913624,
              40.796786
            ],
            [
              -73.928225,
              40.80385
            ],
            [
              -73.931801,
              40.807876
            ],
            [
              -73.932605,
              40.811517
            ],
            [
              -73.932327,
              40.819503
            ],
            [
              -73.933059,
              40.828156
            ],
            [
              -73.933006,
              40.835679
            ],
            [
              -73.927296,
              40.846557
            ],
            [
              -73.921215,
              40.854693
            ],
            [
              -73.91929,
              40.857475
            ],
            [
              -73.910851,
              40.866528
            ],
            [
              -73.907,
              40.873455
            ],
            [
              -73.908554,
              40.877712
            ],
            [
              -73.91031,
              40.879047
            ],
            [
              -73.9152,
              40.875581
            ],
            [
              -73.9334079229381,
              40.882074964842694
            ],
            [
              -73.938081,
              40.874699
            ],
            [
              -73.9381535187682,
              40.8745831121645
            ],
            [
              -73.948281,
              40.858399
            ],
            [
              -73.953982,
              40.848
            ],
            [
              -73.963182,
              40.8269
            ],
            [
              -73.9679734153723,
              40.820837392794196
            ],
            [
              -73.967982575439,
              40.8208258025057
            ],
            [
              -73.968082,
              40.8207
            ],
            [
              -73.97004794249119,
              40.8179689078315
            ],
            [
              -73.97122753768102,
              40.81633021127801
            ],
            [
              -73.98382706989679,
              40.7988269099791
            ],
            [
              -73.984697580911,
              40.7976175939264
            ],
            [
              -73.9880545412037,
              40.79295409638969
            ],
            [
              -73.99382297961459,
              40.784940567490196
            ],
            [
              -74.00139463374009,
              40.7744220068627
            ],
            [
              -74.0022809449912,
              40.7731907410818
            ],
            [
              -74.00828125206719,
              40.7648550996597
            ],
            [
              -74.009184,
              40.763601
            ],
            [
              -74.0098517287783,
              40.762584890989594
            ],
            [
              -74.013784,
              40.756601
            ],
            [
              -74.01692332756079,
              40.7441070499749
            ],
            [
              -74.01977669977019,
              40.732751149994
            ],
            [
              -74.0210755680795,
              40.7275818914723
            ],
            [
              -74.021117,
              40.727417
            ],
            [
              -74.0214927100048,
              40.725445125628504
            ],
            [
              -74.024543,
              40.709436
            ],
            [
              -74.04086,
              40.700117
            ],
            [
              -74.047313,
              40.690466
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "089",
      "COUNTYNS": "00977309",
      "AFFGEOID": "0500000US36089",
      "GEOID": "36089",
      "NAME": "St. Lawrence",
      "LSAD": "06",
      "ALAND": 6939259769,
      "AWATER": 367915773,
      "County_state": "St. Lawrence,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -75.82083,
              44.432244
            ],
            [
              -75.8600599283155,
              44.4032818662487
            ],
            [
              -75.509752,
              44.246897
            ],
            [
              -75.446124,
              44.217655
            ],
            [
              -75.413334,
              44.203399
            ],
            [
              -75.261169,
              44.136123
            ],
            [
              -75.170159,
              44.096959
            ],
            [
              -75.062779,
              44.0504
            ],
            [
              -74.854171,
              44.070089
            ],
            [
              -74.768108,
              44.077604
            ],
            [
              -74.649076,
              44.088343
            ],
            [
              -74.535156,
              44.09925
            ],
            [
              -74.544487,
              44.169414
            ],
            [
              -74.525683,
              44.170636
            ],
            [
              -74.62294,
              44.815154
            ],
            [
              -74.641872,
              44.952621
            ],
            [
              -74.720307,
              44.953011
            ],
            [
              -74.72622806320749,
              44.994863072200594
            ],
            [
              -74.731301,
              44.990422
            ],
            [
              -74.74464,
              44.990577
            ],
            [
              -74.760215,
              44.994946
            ],
            [
              -74.768749,
              45.003893
            ],
            [
              -74.793148,
              45.004647
            ],
            [
              -74.799434,
              45.009132
            ],
            [
              -74.805421,
              45.011003
            ],
            [
              -74.813263,
              45.013543
            ],
            [
              -74.819641,
              45.012874
            ],
            [
              -74.826578,
              45.01585
            ],
            [
              -74.834669,
              45.014683
            ],
            [
              -74.846175,
              45.01029
            ],
            [
              -74.854443,
              45.00539
            ],
            [
              -74.861927,
              45.002771
            ],
            [
              -74.868663,
              45.001274
            ],
            [
              -74.877232,
              45.001362
            ],
            [
              -74.887837,
              45.000046
            ],
            [
              -74.900733,
              44.992754
            ],
            [
              -74.907956,
              44.983359
            ],
            [
              -74.9394468687615,
              44.984420891934
            ],
            [
              -74.946686,
              44.984665
            ],
            [
              -74.972463,
              44.983402
            ],
            [
              -74.992756,
              44.977449
            ],
            [
              -74.99927,
              44.971638
            ],
            [
              -74.999655,
              44.965921
            ],
            [
              -75.005155,
              44.958402
            ],
            [
              -75.027125,
              44.946568
            ],
            [
              -75.059966,
              44.93457
            ],
            [
              -75.066245,
              44.930174
            ],
            [
              -75.096659,
              44.927067
            ],
            [
              -75.105162,
              44.921193
            ],
            [
              -75.119757,
              44.917825
            ],
            [
              -75.133977,
              44.911838
            ],
            [
              -75.142958,
              44.900237
            ],
            [
              -75.165123,
              44.893324
            ],
            [
              -75.188283,
              44.88322
            ],
            [
              -75.196227,
              44.881368
            ],
            [
              -75.203012,
              44.877548
            ],
            [
              -75.218548,
              44.87554
            ],
            [
              -75.228635,
              44.8679
            ],
            [
              -75.241303,
              44.866958
            ],
            [
              -75.255517,
              44.857651
            ],
            [
              -75.26825,
              44.855119
            ],
            [
              -75.283136,
              44.849156
            ],
            [
              -75.30763,
              44.836813
            ],
            [
              -75.306487,
              44.826144
            ],
            [
              -75.333744,
              44.806378
            ],
            [
              -75.346527,
              44.805563
            ],
            [
              -75.36636,
              44.789472
            ],
            [
              -75.372347,
              44.78311
            ],
            [
              -75.387371,
              44.78003
            ],
            [
              -75.397007,
              44.773471
            ],
            [
              -75.413885,
              44.76889
            ],
            [
              -75.423943,
              44.756329
            ],
            [
              -75.477642,
              44.720224
            ],
            [
              -75.505903,
              44.705081
            ],
            [
              -75.580912,
              44.648521
            ],
            [
              -75.618364,
              44.619637
            ],
            [
              -75.662381,
              44.591934
            ],
            [
              -75.696586,
              44.567583
            ],
            [
              -75.727052,
              44.542812
            ],
            [
              -75.76623,
              44.515851
            ],
            [
              -75.807778,
              44.471644
            ],
            [
              -75.82083,
              44.432244
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "071",
      "COUNTYNS": "00974134",
      "AFFGEOID": "0500000US36071",
      "GEOID": "36071",
      "NAME": "Orange",
      "LSAD": "06",
      "ALAND": 2103900470,
      "AWATER": 68092052,
      "County_state": "Orange,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -74.762466,
              41.449525
            ],
            [
              -74.7547086478625,
              41.4249931814039
            ],
            [
              -74.754359,
              41.425147
            ],
            [
              -74.75068,
              41.427984
            ],
            [
              -74.743821,
              41.430635
            ],
            [
              -74.740932,
              41.43116
            ],
            [
              -74.738455,
              41.430641
            ],
            [
              -74.736688,
              41.429228
            ],
            [
              -74.735519,
              41.427465
            ],
            [
              -74.734893,
              41.425818
            ],
            [
              -74.734731,
              41.422699
            ],
            [
              -74.738684,
              41.413463
            ],
            [
              -74.741086,
              41.411413
            ],
            [
              -74.741717,
              41.40788
            ],
            [
              -74.740963,
              41.40512
            ],
            [
              -74.738554,
              41.401191
            ],
            [
              -74.736103,
              41.398398
            ],
            [
              -74.73364,
              41.396975
            ],
            [
              -74.730384,
              41.39566
            ],
            [
              -74.720891,
              41.39469
            ],
            [
              -74.715979,
              41.392584
            ],
            [
              -74.713411,
              41.389814
            ],
            [
              -74.710391,
              41.382102
            ],
            [
              -74.708458,
              41.378901
            ],
            [
              -74.703282,
              41.375093
            ],
            [
              -74.694968,
              41.370431
            ],
            [
              -74.691129,
              41.367324
            ],
            [
              -74.689516,
              41.363843
            ],
            [
              -74.689767,
              41.361558
            ],
            [
              -74.691076,
              41.36034
            ],
            [
              -74.696398,
              41.357339
            ],
            [
              -74.694914,
              41.357423
            ],
            [
              -74.641544,
              41.332879
            ],
            [
              -74.607348,
              41.317774
            ],
            [
              -74.499603,
              41.267344
            ],
            [
              -74.457584,
              41.248225
            ],
            [
              -74.378898,
              41.208994
            ],
            [
              -74.367312185113,
              41.2036716043384
            ],
            [
              -74.320995,
              41.182394
            ],
            [
              -74.301994,
              41.172594
            ],
            [
              -74.28008919002339,
              41.1629553126403
            ],
            [
              -74.234473,
              41.142883
            ],
            [
              -74.2343582601916,
              41.142836102143896
            ],
            [
              -74.161789,
              41.195794
            ],
            [
              -74.09884,
              41.2408
            ],
            [
              -74.069505,
              41.261794
            ],
            [
              -73.981384,
              41.324693
            ],
            [
              -73.961257,
              41.348539
            ],
            [
              -73.947294,
              41.394765
            ],
            [
              -73.959136,
              41.400442
            ],
            [
              -73.981486,
              41.438905
            ],
            [
              -73.999584,
              41.455024
            ],
            [
              -73.99825,
              41.480625
            ],
            [
              -73.997609,
              41.487212
            ],
            [
              -73.997232,
              41.519149
            ],
            [
              -73.994547,
              41.527138
            ],
            [
              -73.988036,
              41.542562
            ],
            [
              -73.954725,
              41.58179
            ],
            [
              -73.953307,
              41.589977
            ],
            [
              -74.037125,
              41.582044
            ],
            [
              -74.053685,
              41.58061
            ],
            [
              -74.068083,
              41.606001
            ],
            [
              -74.089896,
              41.598602
            ],
            [
              -74.098653,
              41.607259
            ],
            [
              -74.134511,
              41.615695
            ],
            [
              -74.126393,
              41.582544
            ],
            [
              -74.187505,
              41.590793
            ],
            [
              -74.251601,
              41.605374
            ],
            [
              -74.250186,
              41.629324
            ],
            [
              -74.264292,
              41.632629
            ],
            [
              -74.304711,
              41.615251
            ],
            [
              -74.34068,
              41.594376
            ],
            [
              -74.367055,
              41.590977
            ],
            [
              -74.408278,
              41.575175
            ],
            [
              -74.419321,
              41.565626
            ],
            [
              -74.45454,
              41.533369
            ],
            [
              -74.475623,
              41.503987
            ],
            [
              -74.529751,
              41.502394
            ],
            [
              -74.752399,
              41.493743
            ],
            [
              -74.761435,
              41.490245
            ],
            [
              -74.750832,
              41.463713
            ],
            [
              -74.762466,
              41.449525
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "109",
      "COUNTYNS": "00974152",
      "AFFGEOID": "0500000US36109",
      "GEOID": "36109",
      "NAME": "Tompkins",
      "LSAD": "06",
      "ALAND": 1229335996,
      "AWATER": 43792937,
      "County_state": "Tompkins,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.696655,
              42.54679
            ],
            [
              -76.685726,
              42.375108
            ],
            [
              -76.691406,
              42.284307
            ],
            [
              -76.619303,
              42.282853
            ],
            [
              -76.538349,
              42.281755
            ],
            [
              -76.473962,
              42.281132
            ],
            [
              -76.474494,
              42.263761
            ],
            [
              -76.416199,
              42.262976
            ],
            [
              -76.415305,
              42.318368
            ],
            [
              -76.350871,
              42.318288
            ],
            [
              -76.350619,
              42.308437
            ],
            [
              -76.28822,
              42.308227
            ],
            [
              -76.288174,
              42.296764
            ],
            [
              -76.250149,
              42.296676
            ],
            [
              -76.251363,
              42.324894
            ],
            [
              -76.237695,
              42.351358
            ],
            [
              -76.242129,
              42.363678
            ],
            [
              -76.299493,
              42.385012
            ],
            [
              -76.294802,
              42.404846
            ],
            [
              -76.253359,
              42.407568
            ],
            [
              -76.265584,
              42.623588
            ],
            [
              -76.4584,
              42.618615
            ],
            [
              -76.458858,
              42.627416
            ],
            [
              -76.614291,
              42.622103
            ],
            [
              -76.666543,
              42.623457
            ],
            [
              -76.645317,
              42.591708
            ],
            [
              -76.618239,
              42.566652
            ],
            [
              -76.585989,
              42.54991
            ],
            [
              -76.696655,
              42.54679
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "067",
      "COUNTYNS": "00974132",
      "AFFGEOID": "0500000US36067",
      "GEOID": "36067",
      "NAME": "Onondaga",
      "LSAD": "06",
      "ALAND": 2016012328,
      "AWATER": 70528316,
      "County_state": "Onondaga,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.497829,
              43.102657
            ],
            [
              -76.491941,
              43.004964
            ],
            [
              -76.462999,
              43.006316
            ],
            [
              -76.457428,
              42.929524
            ],
            [
              -76.450398,
              42.845727
            ],
            [
              -76.356974,
              42.84945
            ],
            [
              -76.305762,
              42.813338
            ],
            [
              -76.275348,
              42.78047
            ],
            [
              -76.274673,
              42.771257
            ],
            [
              -76.195944,
              42.77473
            ],
            [
              -75.896079,
              42.790964
            ],
            [
              -75.910613,
              42.98941
            ],
            [
              -75.917559,
              43.086174
            ],
            [
              -75.919772,
              43.089765
            ],
            [
              -75.951361,
              43.087295
            ],
            [
              -75.975404,
              43.090921
            ],
            [
              -75.976396,
              43.103038
            ],
            [
              -75.965594,
              43.119552
            ],
            [
              -75.956023,
              43.115148
            ],
            [
              -75.950287,
              43.132551
            ],
            [
              -75.967318,
              43.134819
            ],
            [
              -75.971235,
              43.154639
            ],
            [
              -75.977068,
              43.157646
            ],
            [
              -75.97438,
              43.163175
            ],
            [
              -75.97788,
              43.164591
            ],
            [
              -75.982668,
              43.173631
            ],
            [
              -75.990028,
              43.171527
            ],
            [
              -75.992635,
              43.173849
            ],
            [
              -75.989672,
              43.179591
            ],
            [
              -75.993394,
              43.183376
            ],
            [
              -76.005215,
              43.179352
            ],
            [
              -76.046131,
              43.202161
            ],
            [
              -76.07839,
              43.199416
            ],
            [
              -76.084448,
              43.213842
            ],
            [
              -76.068035,
              43.212688
            ],
            [
              -76.114311,
              43.232536
            ],
            [
              -76.157647,
              43.243268
            ],
            [
              -76.202573,
              43.271539
            ],
            [
              -76.21083,
              43.268473
            ],
            [
              -76.210609,
              43.244004
            ],
            [
              -76.194248,
              43.217068
            ],
            [
              -76.224449,
              43.204968
            ],
            [
              -76.24465,
              43.214568
            ],
            [
              -76.258951,
              43.237468
            ],
            [
              -76.275551,
              43.238768
            ],
            [
              -76.262051,
              43.218468
            ],
            [
              -76.281027,
              43.201091
            ],
            [
              -76.306437,
              43.230451
            ],
            [
              -76.344632,
              43.23267
            ],
            [
              -76.479224,
              43.227519
            ],
            [
              -76.473066,
              43.146017
            ],
            [
              -76.486171,
              43.142208
            ],
            [
              -76.471873,
              43.107059
            ],
            [
              -76.497829,
              43.102657
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "001",
      "COUNTYNS": "00974099",
      "AFFGEOID": "0500000US36001",
      "GEOID": "36001",
      "NAME": "Albany",
      "LSAD": "06",
      "ALAND": 1354083642,
      "AWATER": 27161700,
      "County_state": "Albany,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -74.264863,
              42.419863
            ],
            [
              -74.263469,
              42.407127
            ],
            [
              -74.254303,
              42.408207
            ],
            [
              -73.93326,
              42.444814
            ],
            [
              -73.783721,
              42.464231
            ],
            [
              -73.785548,
              42.487209
            ],
            [
              -73.773161,
              42.509377
            ],
            [
              -73.76377,
              42.521135
            ],
            [
              -73.752165,
              42.56658
            ],
            [
              -73.759739,
              42.61379
            ],
            [
              -73.759565,
              42.614179
            ],
            [
              -73.741764,
              42.654379
            ],
            [
              -73.724964,
              42.670179
            ],
            [
              -73.723263,
              42.672879
            ],
            [
              -73.706463,
              42.694078
            ],
            [
              -73.703463,
              42.705578
            ],
            [
              -73.703295,
              42.710273
            ],
            [
              -73.68483,
              42.75701
            ],
            [
              -73.683762,
              42.766677
            ],
            [
              -73.676762,
              42.783277
            ],
            [
              -73.690262,
              42.775477
            ],
            [
              -73.710663,
              42.794077
            ],
            [
              -73.719863,
              42.801277
            ],
            [
              -73.722663,
              42.820677
            ],
            [
              -73.736963,
              42.819477
            ],
            [
              -73.768764,
              42.785877
            ],
            [
              -73.779964,
              42.792177
            ],
            [
              -73.809369,
              42.778869
            ],
            [
              -73.811626,
              42.774774
            ],
            [
              -73.906785,
              42.763432
            ],
            [
              -73.933755,
              42.7607
            ],
            [
              -74.106499,
              42.739235
            ],
            [
              -74.180274,
              42.729979
            ],
            [
              -74.164678,
              42.717207
            ],
            [
              -74.169725,
              42.667426
            ],
            [
              -74.193495,
              42.618999
            ],
            [
              -74.233995,
              42.575688
            ],
            [
              -74.241572,
              42.550802
            ],
            [
              -74.234419,
              42.53784
            ],
            [
              -74.225639,
              42.526403
            ],
            [
              -74.228501,
              42.494381
            ],
            [
              -74.244457,
              42.470265
            ],
            [
              -74.264863,
              42.419863
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "103",
      "COUNTYNS": "00974149",
      "AFFGEOID": "0500000US36103",
      "GEOID": "36103",
      "NAME": "Suffolk",
      "LSAD": "06",
      "ALAND": 2360846288,
      "AWATER": 3785546967,
      "County_state": "Suffolk,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -72.034958,
              41.255458
            ],
            [
              -72.036846,
              41.249794
            ],
            [
              -72.002461,
              41.252867
            ],
            [
              -71.994717,
              41.256451
            ],
            [
              -71.978926,
              41.265002
            ],
            [
              -71.962598,
              41.270968
            ],
            [
              -71.94627,
              41.276306
            ],
            [
              -71.935259,
              41.280579
            ],
            [
              -71.926802,
              41.290122
            ],
            [
              -71.943563,
              41.286675
            ],
            [
              -71.952864,
              41.285098
            ],
            [
              -71.980061,
              41.280291
            ],
            [
              -71.991117,
              41.281331
            ],
            [
              -72.006872,
              41.27348
            ],
            [
              -72.018926,
              41.274114
            ],
            [
              -72.023422,
              41.270994
            ],
            [
              -72.029438,
              41.26309
            ],
            [
              -72.034958,
              41.255458
            ]
          ]
        ],
        [
          [
            [
              -72.142929,
              41.097811
            ],
            [
              -72.141921,
              41.094371
            ],
            [
              -72.139233,
              41.092451
            ],
            [
              -72.12056,
              41.093171
            ],
            [
              -72.1064,
              41.088883
            ],
            [
              -72.103152,
              41.086484
            ],
            [
              -72.097136,
              41.075844
            ],
            [
              -72.0972,
              41.054884
            ],
            [
              -72.095711,
              41.05402
            ],
            [
              -72.086975,
              41.058292
            ],
            [
              -72.081167,
              41.09394
            ],
            [
              -72.084207,
              41.101524
            ],
            [
              -72.126704,
              41.115139
            ],
            [
              -72.128352,
              41.108131
            ],
            [
              -72.132225,
              41.104387
            ],
            [
              -72.140737,
              41.100835
            ],
            [
              -72.142929,
              41.097811
            ]
          ]
        ],
        [
          [
            [
              -72.21412,
              41.182817
            ],
            [
              -72.216497,
              41.170294
            ],
            [
              -72.199859,
              41.160453
            ],
            [
              -72.187975,
              41.1694
            ],
            [
              -72.182033,
              41.178345
            ],
            [
              -72.161034,
              41.188671
            ],
            [
              -72.158265,
              41.197126
            ],
            [
              -72.189163,
              41.193549
            ],
            [
              -72.21412,
              41.182817
            ]
          ]
        ],
        [
          [
            [
              -73.496642,
              40.923476
            ],
            [
              -73.4970614703723,
              40.92280082944009
            ],
            [
              -73.477307,
              40.89925
            ],
            [
              -73.476079,
              40.877111
            ],
            [
              -73.462259,
              40.86671
            ],
            [
              -73.445113,
              40.785557
            ],
            [
              -73.444096,
              40.781606
            ],
            [
              -73.4385,
              40.751311
            ],
            [
              -73.436006,
              40.739458
            ],
            [
              -73.432934,
              40.723539
            ],
            [
              -73.432175,
              40.71974
            ],
            [
              -73.431821,
              40.717969
            ],
            [
              -73.431139,
              40.712731
            ],
            [
              -73.428103,
              40.697513
            ],
            [
              -73.427871,
              40.696283
            ],
            [
              -73.42388,
              40.655973
            ],
            [
              -73.425346,
              40.651818
            ],
            [
              -73.4238050058968,
              40.6098498176898
            ],
            [
              -73.417311,
              40.611128
            ],
            [
              -73.351465,
              40.6305
            ],
            [
              -73.326262,
              40.6331
            ],
            [
              -73.306396,
              40.620756
            ],
            [
              -73.2644932067679,
              40.6214371923939
            ],
            [
              -73.262106,
              40.621476
            ],
            [
              -73.23914,
              40.6251
            ],
            [
              -73.20844,
              40.630884
            ],
            [
              -73.14526632896501,
              40.645491035999
            ],
            [
              -73.054963,
              40.666371
            ],
            [
              -73.012545,
              40.679651
            ],
            [
              -72.923214,
              40.713282
            ],
            [
              -72.87286853017079,
              40.7297815644669
            ],
            [
              -72.863164,
              40.732962
            ],
            [
              -72.768152,
              40.761587
            ],
            [
              -72.757176,
              40.764371
            ],
            [
              -72.753112,
              40.763571
            ],
            [
              -72.745208,
              40.767091
            ],
            [
              -72.7280770672225,
              40.771694700693395
            ],
            [
              -72.573441,
              40.813251
            ],
            [
              -72.469996,
              40.84274
            ],
            [
              -72.39585,
              40.86666
            ],
            [
              -72.114448,
              40.972085
            ],
            [
              -72.029357,
              40.999909
            ],
            [
              -71.935689,
              41.034182
            ],
            [
              -71.903736,
              41.040166
            ],
            [
              -71.87391,
              41.052278
            ],
            [
              -71.856214,
              41.070598
            ],
            [
              -71.857494,
              41.073558
            ],
            [
              -71.86447,
              41.076918
            ],
            [
              -71.869558,
              41.075046
            ],
            [
              -71.889543,
              41.075701
            ],
            [
              -71.895496,
              41.077381
            ],
            [
              -71.899256,
              41.080837
            ],
            [
              -71.919385,
              41.080517
            ],
            [
              -71.93825,
              41.077413
            ],
            [
              -71.959595,
              41.071237
            ],
            [
              -71.961563,
              41.064021
            ],
            [
              -71.960355,
              41.059878
            ],
            [
              -71.961078,
              41.054277
            ],
            [
              -71.96704,
              41.047772
            ],
            [
              -71.99926,
              41.039669
            ],
            [
              -72.015013,
              41.028348
            ],
            [
              -72.035792,
              41.020759
            ],
            [
              -72.047468,
              41.022565
            ],
            [
              -72.051928,
              41.020506
            ],
            [
              -72.051549,
              41.015741
            ],
            [
              -72.049526,
              41.009697
            ],
            [
              -72.051585,
              41.006437
            ],
            [
              -72.055188,
              41.005236
            ],
            [
              -72.057075,
              41.004893
            ],
            [
              -72.057934,
              41.004789
            ],
            [
              -72.061448,
              41.009442
            ],
            [
              -72.076175,
              41.009093
            ],
            [
              -72.079208,
              41.006437
            ],
            [
              -72.079951,
              41.003429
            ],
            [
              -72.083039,
              40.996453
            ],
            [
              -72.095456,
              40.991349
            ],
            [
              -72.10216,
              40.991509
            ],
            [
              -72.109008,
              40.994084
            ],
            [
              -72.116368,
              40.999796
            ],
            [
              -72.137409,
              41.023908
            ],
            [
              -72.135137,
              41.031284
            ],
            [
              -72.137297,
              41.039684
            ],
            [
              -72.153857,
              41.051859
            ],
            [
              -72.16037,
              41.053827
            ],
            [
              -72.162898,
              41.053187
            ],
            [
              -72.174882,
              41.046147
            ],
            [
              -72.17949,
              41.038435
            ],
            [
              -72.183266,
              41.035619
            ],
            [
              -72.190563,
              41.032579
            ],
            [
              -72.201859,
              41.032275
            ],
            [
              -72.217476,
              41.040611
            ],
            [
              -72.229364,
              41.044355
            ],
            [
              -72.241252,
              41.04477
            ],
            [
              -72.260515,
              41.042065
            ],
            [
              -72.273657,
              41.051533
            ],
            [
              -72.283093,
              41.067874
            ],
            [
              -72.276709,
              41.076722
            ],
            [
              -72.280373,
              41.080402
            ],
            [
              -72.297718,
              41.081042
            ],
            [
              -72.317238,
              41.088659
            ],
            [
              -72.335177,
              41.106917
            ],
            [
              -72.3352138798347,
              41.1121574675718
            ],
            [
              -72.335271,
              41.120274
            ],
            [
              -72.32663,
              41.132162
            ],
            [
              -72.318146,
              41.137134
            ],
            [
              -72.312734,
              41.138546
            ],
            [
              -72.306381,
              41.13784
            ],
            [
              -72.300044,
              41.132059
            ],
            [
              -72.300374,
              41.112274
            ],
            [
              -72.265124,
              41.128482
            ],
            [
              -72.253572,
              41.137138
            ],
            [
              -72.237731,
              41.156434
            ],
            [
              -72.238211,
              41.15949
            ],
            [
              -72.245348,
              41.161217
            ],
            [
              -72.2681,
              41.154146
            ],
            [
              -72.272997,
              41.15501
            ],
            [
              -72.278789,
              41.158722
            ],
            [
              -72.291109,
              41.155874
            ],
            [
              -72.312775,
              41.148002
            ],
            [
              -72.330583,
              41.147031
            ],
            [
              -72.354123,
              41.139952
            ],
            [
              -72.402625,
              41.096929
            ],
            [
              -72.417945,
              41.087955
            ],
            [
              -72.445242,
              41.086116
            ],
            [
              -72.460778,
              41.067012
            ],
            [
              -72.477306,
              41.052212
            ],
            [
              -72.521548,
              41.037652
            ],
            [
              -72.549853,
              41.019844
            ],
            [
              -72.560974,
              41.015444
            ],
            [
              -72.565406,
              41.009508
            ],
            [
              -72.585327,
              40.997587
            ],
            [
              -72.635562,
              40.981957
            ],
            [
              -72.663707,
              40.979677
            ],
            [
              -72.685761,
              40.980288
            ],
            [
              -72.708069,
              40.977851
            ],
            [
              -72.745656,
              40.968445
            ],
            [
              -72.774104,
              40.965314
            ],
            [
              -72.826057,
              40.969794
            ],
            [
              -72.88825,
              40.962962
            ],
            [
              -72.913834,
              40.962466
            ],
            [
              -72.955163,
              40.966146
            ],
            [
              -72.995931,
              40.966498
            ],
            [
              -73.040445,
              40.964498
            ],
            [
              -73.043701,
              40.962185
            ],
            [
              -73.0496600156786,
              40.9638954188768
            ],
            [
              -73.081582,
              40.973058
            ],
            [
              -73.09514812823929,
              40.9725301717631
            ],
            [
              -73.110368,
              40.971938
            ],
            [
              -73.140785,
              40.966178
            ],
            [
              -73.144673,
              40.955842
            ],
            [
              -73.146242,
              40.935074
            ],
            [
              -73.14848903048039,
              40.9300312455499
            ],
            [
              -73.148994,
              40.928898
            ],
            [
              -73.229285,
              40.905121
            ],
            [
              -73.2369377995271,
              40.907375396017194
            ],
            [
              -73.295059,
              40.924497
            ],
            [
              -73.2950614460617,
              40.92449734365209
            ],
            [
              -73.33136,
              40.929597
            ],
            [
              -73.344161,
              40.927297
            ],
            [
              -73.345561,
              40.925297
            ],
            [
              -73.352761,
              40.926697
            ],
            [
              -73.365961,
              40.931697
            ],
            [
              -73.374462,
              40.937597
            ],
            [
              -73.392862,
              40.955297
            ],
            [
              -73.399762,
              40.955197
            ],
            [
              -73.400862,
              40.953997
            ],
            [
              -73.403462,
              40.942197
            ],
            [
              -73.402963,
              40.925097
            ],
            [
              -73.406074,
              40.920235
            ],
            [
              -73.428836,
              40.921506
            ],
            [
              -73.4293168342383,
              40.9253877883836
            ],
            [
              -73.429863,
              40.929797
            ],
            [
              -73.436664,
              40.934897
            ],
            [
              -73.463708,
              40.937697
            ],
            [
              -73.478365,
              40.942297
            ],
            [
              -73.485365,
              40.946397
            ],
            [
              -73.491765,
              40.942097
            ],
            [
              -73.496642,
              40.923476
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "045",
      "COUNTYNS": "00974121",
      "AFFGEOID": "0500000US36045",
      "GEOID": "36045",
      "NAME": "Jefferson",
      "LSAD": "06",
      "ALAND": 3285895343,
      "AWATER": 1524639909,
      "County_state": "Jefferson,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.373772,
              43.874784
            ],
            [
              -76.361037,
              43.872585
            ],
            [
              -76.334297,
              43.878567
            ],
            [
              -76.307487,
              43.905376
            ],
            [
              -76.3013,
              43.917751
            ],
            [
              -76.319443,
              43.917972
            ],
            [
              -76.346776,
              43.900849
            ],
            [
              -76.373772,
              43.874784
            ]
          ]
        ],
        [
          [
            [
              -76.381388,
              44.04711
            ],
            [
              -76.383338,
              44.037361
            ],
            [
              -76.37556,
              44.031536
            ],
            [
              -76.361748,
              44.032993
            ],
            [
              -76.321297,
              44.031208
            ],
            [
              -76.307674,
              44.025277
            ],
            [
              -76.301888,
              44.013435
            ],
            [
              -76.296487,
              44.008489
            ],
            [
              -76.281928,
              44.009177
            ],
            [
              -76.269672,
              44.001148
            ],
            [
              -76.268702,
              43.987278
            ],
            [
              -76.279314,
              43.972462
            ],
            [
              -76.283639,
              43.962648
            ],
            [
              -76.271993,
              43.960766
            ],
            [
              -76.254555,
              43.966213
            ],
            [
              -76.207819,
              43.975307
            ],
            [
              -76.200249,
              43.967931
            ],
            [
              -76.169553,
              43.958713
            ],
            [
              -76.147292,
              43.941416
            ],
            [
              -76.136663,
              43.934592
            ],
            [
              -76.128196,
              43.916781
            ],
            [
              -76.12756929214629,
              43.90378455129339
            ],
            [
              -76.127285,
              43.897889
            ],
            [
              -76.145506,
              43.888681
            ],
            [
              -76.153155,
              43.890511
            ],
            [
              -76.202662,
              43.86291
            ],
            [
              -76.220488,
              43.864453
            ],
            [
              -76.208922,
              43.890572
            ],
            [
              -76.213761,
              43.900234
            ],
            [
              -76.228261,
              43.886645
            ],
            [
              -76.260289,
              43.881883
            ],
            [
              -76.286004,
              43.868084
            ],
            [
              -76.296758,
              43.857079
            ],
            [
              -76.299064,
              43.838503
            ],
            [
              -76.23503133456339,
              43.80697290306429
            ],
            [
              -76.229268,
              43.804135
            ],
            [
              -76.213205,
              43.753513
            ],
            [
              -76.205436,
              43.718751
            ],
            [
              -76.2005002287568,
              43.68023076718659
            ],
            [
              -76.022003,
              43.668143
            ],
            [
              -76.025087,
              43.707018
            ],
            [
              -75.774553,
              43.688884
            ],
            [
              -75.786759,
              43.78832
            ],
            [
              -75.850534,
              43.791886
            ],
            [
              -75.84056,
              43.883976
            ],
            [
              -75.758157,
              43.878785
            ],
            [
              -75.60367,
              43.971363
            ],
            [
              -75.577181,
              43.965276
            ],
            [
              -75.542898,
              43.967795
            ],
            [
              -75.549993,
              44.008163
            ],
            [
              -75.50183,
              44.009556
            ],
            [
              -75.502014,
              44.036274
            ],
            [
              -75.493342,
              44.046835
            ],
            [
              -75.502741,
              44.051253
            ],
            [
              -75.484528,
              44.074172
            ],
            [
              -75.545886,
              44.102978
            ],
            [
              -75.533512,
              44.117506
            ],
            [
              -75.446124,
              44.217655
            ],
            [
              -75.509752,
              44.246897
            ],
            [
              -75.8600599283155,
              44.4032818662487
            ],
            [
              -75.871496,
              44.394839
            ],
            [
              -75.912985,
              44.368084
            ],
            [
              -75.922247,
              44.36568
            ],
            [
              -75.929465,
              44.359603
            ],
            [
              -75.939664,
              44.355395
            ],
            [
              -75.94954,
              44.349129
            ],
            [
              -75.970185,
              44.342835
            ],
            [
              -75.973053,
              44.343634
            ],
            [
              -75.974839,
              44.346172
            ],
            [
              -75.978281,
              44.34688
            ],
            [
              -75.982392,
              44.347404
            ],
            [
              -76.000998,
              44.347534
            ],
            [
              -76.008361,
              44.343856
            ],
            [
              -76.045228,
              44.331724
            ],
            [
              -76.097351,
              44.299547
            ],
            [
              -76.111931,
              44.298031
            ],
            [
              -76.118136,
              44.29485
            ],
            [
              -76.126565,
              44.294581
            ],
            [
              -76.130884,
              44.296635
            ],
            [
              -76.161833,
              44.280777
            ],
            [
              -76.164265,
              44.239603
            ],
            [
              -76.191328,
              44.221244
            ],
            [
              -76.206777,
              44.214543
            ],
            [
              -76.245487,
              44.203669
            ],
            [
              -76.249661,
              44.204171
            ],
            [
              -76.286547,
              44.203773
            ],
            [
              -76.312647,
              44.199044
            ],
            [
              -76.3524,
              44.137226
            ],
            [
              -76.355679,
              44.133258
            ],
            [
              -76.370706,
              44.100499
            ],
            [
              -76.360798,
              44.087644
            ],
            [
              -76.361836,
              44.072721
            ],
            [
              -76.370079,
              44.052029
            ],
            [
              -76.381388,
              44.04711
            ]
          ]
        ],
        [
          [
            [
              -76.453732,
              43.890572
            ],
            [
              -76.441848,
              43.882864
            ],
            [
              -76.420455,
              43.894094
            ],
            [
              -76.388603,
              43.908127
            ],
            [
              -76.377945,
              43.921188
            ],
            [
              -76.412138,
              43.925676
            ],
            [
              -76.453732,
              43.890572
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "051",
      "COUNTYNS": "00974124",
      "AFFGEOID": "0500000US36051",
      "GEOID": "36051",
      "NAME": "Livingston",
      "LSAD": "06",
      "ALAND": 1636256623,
      "AWATER": 22006106,
      "County_state": "Livingston,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.047965,
              42.557929
            ],
            [
              -78.060617,
              42.532814
            ],
            [
              -78.042561,
              42.53515
            ],
            [
              -78.038261,
              42.521522
            ],
            [
              -78.043418,
              42.518464
            ],
            [
              -77.954956,
              42.518369
            ],
            [
              -77.840694,
              42.517771
            ],
            [
              -77.840231,
              42.474576
            ],
            [
              -77.722964,
              42.471216
            ],
            [
              -77.720617,
              42.535066
            ],
            [
              -77.661315,
              42.535067
            ],
            [
              -77.659917,
              42.580409
            ],
            [
              -77.490889,
              42.577288
            ],
            [
              -77.489908,
              42.646326
            ],
            [
              -77.486875,
              42.670279
            ],
            [
              -77.598815,
              42.671965
            ],
            [
              -77.61167,
              42.763169
            ],
            [
              -77.588217,
              42.762964
            ],
            [
              -77.587997,
              42.854011
            ],
            [
              -77.551926,
              42.854064
            ],
            [
              -77.546309,
              42.86569
            ],
            [
              -77.560566,
              42.921432
            ],
            [
              -77.580377,
              42.943963
            ],
            [
              -77.635022,
              42.944464
            ],
            [
              -77.759052,
              42.940462
            ],
            [
              -77.732378,
              42.945285
            ],
            [
              -77.720748,
              42.979193
            ],
            [
              -77.730957,
              42.988372
            ],
            [
              -77.909832,
              42.987762
            ],
            [
              -77.911413,
              42.945064
            ],
            [
              -77.934234,
              42.94558
            ],
            [
              -77.93523,
              42.905261
            ],
            [
              -77.95553,
              42.905861
            ],
            [
              -77.954964,
              42.862754
            ],
            [
              -77.956334,
              42.667322
            ],
            [
              -77.968757,
              42.662803
            ],
            [
              -77.9904,
              42.61486
            ],
            [
              -78.015381,
              42.609449
            ],
            [
              -78.006162,
              42.598801
            ],
            [
              -78.022851,
              42.584894
            ],
            [
              -78.048247,
              42.579306
            ],
            [
              -78.033665,
              42.567991
            ],
            [
              -78.047965,
              42.557929
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "121",
      "COUNTYNS": "00974158",
      "AFFGEOID": "0500000US36121",
      "GEOID": "36121",
      "NAME": "Wyoming",
      "LSAD": "06",
      "ALAND": 1535218841,
      "AWATER": 9158424,
      "County_state": "Wyoming,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.486256,
              42.867731
            ],
            [
              -78.488143,
              42.78066
            ],
            [
              -78.463233,
              42.780461
            ],
            [
              -78.46394,
              42.536332
            ],
            [
              -78.464556,
              42.519166
            ],
            [
              -78.308839,
              42.521217
            ],
            [
              -78.0483,
              42.520989
            ],
            [
              -78.038261,
              42.521522
            ],
            [
              -78.042561,
              42.53515
            ],
            [
              -78.060617,
              42.532814
            ],
            [
              -78.047965,
              42.557929
            ],
            [
              -78.033665,
              42.567991
            ],
            [
              -78.048247,
              42.579306
            ],
            [
              -78.022851,
              42.584894
            ],
            [
              -78.006162,
              42.598801
            ],
            [
              -78.015381,
              42.609449
            ],
            [
              -77.9904,
              42.61486
            ],
            [
              -77.968757,
              42.662803
            ],
            [
              -77.956334,
              42.667322
            ],
            [
              -77.954964,
              42.862754
            ],
            [
              -77.995362,
              42.864993
            ],
            [
              -78.074171,
              42.870188
            ],
            [
              -78.464381,
              42.867461
            ],
            [
              -78.486256,
              42.867731
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "097",
      "COUNTYNS": "00974146",
      "AFFGEOID": "0500000US36097",
      "GEOID": "36097",
      "NAME": "Schuyler",
      "LSAD": "06",
      "ALAND": 850379112,
      "AWATER": 36246374,
      "County_state": "Schuyler,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.107203,
              42.483771
            ],
            [
              -77.099657,
              42.272356
            ],
            [
              -77.074784,
              42.275476
            ],
            [
              -76.965028,
              42.278495
            ],
            [
              -76.964761,
              42.28744
            ],
            [
              -76.842907,
              42.293756
            ],
            [
              -76.733912,
              42.29372
            ],
            [
              -76.732677,
              42.248052
            ],
            [
              -76.685779,
              42.248333
            ],
            [
              -76.685656,
              42.237604
            ],
            [
              -76.648114,
              42.238094
            ],
            [
              -76.642256,
              42.233721
            ],
            [
              -76.619533,
              42.248664
            ],
            [
              -76.619303,
              42.282853
            ],
            [
              -76.691406,
              42.284307
            ],
            [
              -76.685726,
              42.375108
            ],
            [
              -76.696655,
              42.54679
            ],
            [
              -76.895596,
              42.541537
            ],
            [
              -76.900841,
              42.493812
            ],
            [
              -76.889805,
              42.463054
            ],
            [
              -76.963977,
              42.459186
            ],
            [
              -76.987913,
              42.463239
            ],
            [
              -76.989329,
              42.484821
            ],
            [
              -77.107203,
              42.483771
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "107",
      "COUNTYNS": "00974151",
      "AFFGEOID": "0500000US36107",
      "GEOID": "36107",
      "NAME": "Tioga",
      "LSAD": "06",
      "ALAND": 1343192581,
      "AWATER": 11059913,
      "County_state": "Tioga,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.562732,
              42.155912
            ],
            [
              -76.559409,
              42.131251
            ],
            [
              -76.541489,
              42.051728
            ],
            [
              -76.55762413688969,
              42.000148906120295
            ],
            [
              -76.47303045547619,
              41.9991050870808
            ],
            [
              -76.46654,
              41.999025
            ],
            [
              -76.462155,
              41.998934
            ],
            [
              -76.349898,
              41.99841
            ],
            [
              -76.343722,
              41.998346
            ],
            [
              -76.1455192032297,
              41.9989130371419
            ],
            [
              -76.131201,
              41.998954
            ],
            [
              -76.123696,
              41.998954
            ],
            [
              -76.10584,
              41.998858
            ],
            [
              -76.104829,
              42.031568
            ],
            [
              -76.108948,
              42.074751
            ],
            [
              -76.108562,
              42.101256
            ],
            [
              -76.114033,
              42.153418
            ],
            [
              -76.116225,
              42.18561
            ],
            [
              -76.086683,
              42.186858
            ],
            [
              -76.081134,
              42.230495
            ],
            [
              -76.096153,
              42.257509
            ],
            [
              -76.080681,
              42.258084
            ],
            [
              -76.087728,
              42.278752
            ],
            [
              -76.106582,
              42.287356
            ],
            [
              -76.101622,
              42.310395
            ],
            [
              -76.130181,
              42.410337
            ],
            [
              -76.253359,
              42.407568
            ],
            [
              -76.294802,
              42.404846
            ],
            [
              -76.299493,
              42.385012
            ],
            [
              -76.242129,
              42.363678
            ],
            [
              -76.237695,
              42.351358
            ],
            [
              -76.251363,
              42.324894
            ],
            [
              -76.250149,
              42.296676
            ],
            [
              -76.288174,
              42.296764
            ],
            [
              -76.28822,
              42.308227
            ],
            [
              -76.350619,
              42.308437
            ],
            [
              -76.350871,
              42.318288
            ],
            [
              -76.415305,
              42.318368
            ],
            [
              -76.416199,
              42.262976
            ],
            [
              -76.474494,
              42.263761
            ],
            [
              -76.473962,
              42.281132
            ],
            [
              -76.538349,
              42.281755
            ],
            [
              -76.535782,
              42.250074
            ],
            [
              -76.536507,
              42.155403
            ],
            [
              -76.562732,
              42.155912
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "015",
      "COUNTYNS": "00974106",
      "AFFGEOID": "0500000US36015",
      "GEOID": "36015",
      "NAME": "Chemung",
      "LSAD": "06",
      "ALAND": 1055038643,
      "AWATER": 8874334,
      "County_state": "Chemung,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.96572797329421,
              42.0012735436595
            ],
            [
              -76.965686,
              42.001274
            ],
            [
              -76.942585,
              42.001574
            ],
            [
              -76.937084,
              42.001674
            ],
            [
              -76.927084,
              42.001674
            ],
            [
              -76.921884,
              42.001674
            ],
            [
              -76.920784,
              42.001774
            ],
            [
              -76.835079,
              42.001773
            ],
            [
              -76.815878,
              42.001673
            ],
            [
              -76.749675,
              42.001689
            ],
            [
              -76.6381035393041,
              42.000795529019
            ],
            [
              -76.558118,
              42.000155
            ],
            [
              -76.55762413688969,
              42.000148906120295
            ],
            [
              -76.541489,
              42.051728
            ],
            [
              -76.559409,
              42.131251
            ],
            [
              -76.562732,
              42.155912
            ],
            [
              -76.536507,
              42.155403
            ],
            [
              -76.535782,
              42.250074
            ],
            [
              -76.538349,
              42.281755
            ],
            [
              -76.619303,
              42.282853
            ],
            [
              -76.619533,
              42.248664
            ],
            [
              -76.642256,
              42.233721
            ],
            [
              -76.648114,
              42.238094
            ],
            [
              -76.685656,
              42.237604
            ],
            [
              -76.685779,
              42.248333
            ],
            [
              -76.732677,
              42.248052
            ],
            [
              -76.733912,
              42.29372
            ],
            [
              -76.842907,
              42.293756
            ],
            [
              -76.964761,
              42.28744
            ],
            [
              -76.965028,
              42.278495
            ],
            [
              -76.965376,
              42.199156
            ],
            [
              -76.96572797329421,
              42.0012735436595
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "119",
      "COUNTYNS": "00974157",
      "AFFGEOID": "0500000US36119",
      "GEOID": "36119",
      "NAME": "Westchester",
      "LSAD": "06",
      "ALAND": 1115031328,
      "AWATER": 179949077,
      "County_state": "Westchester,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -73.982584,
              41.321693
            ],
            [
              -73.964483,
              41.299693
            ],
            [
              -73.946583,
              41.290993
            ],
            [
              -73.966884,
              41.267193
            ],
            [
              -73.970784,
              41.247893
            ],
            [
              -73.947965,
              41.223101
            ],
            [
              -73.931482,
              41.204994
            ],
            [
              -73.921697,
              41.186288
            ],
            [
              -73.909281,
              41.164395
            ],
            [
              -73.892481,
              41.150595
            ],
            [
              -73.88758,
              41.128795
            ],
            [
              -73.89378,
              41.093296
            ],
            [
              -73.894777,
              41.070791
            ],
            [
              -73.88938,
              41.037597
            ],
            [
              -73.893579,
              41.005297
            ],
            [
              -73.893979,
              40.997197
            ],
            [
              -73.896479,
              40.981697
            ],
            [
              -73.8989276186366,
              40.974850797407
            ],
            [
              -73.9049158705604,
              40.9581079754602
            ],
            [
              -73.90728,
              40.951498
            ],
            [
              -73.91558,
              40.924898
            ],
            [
              -73.91768,
              40.919498
            ],
            [
              -73.917905,
              40.917577
            ],
            [
              -73.878189,
              40.905983
            ],
            [
              -73.860006,
              40.900565
            ],
            [
              -73.852678,
              40.909899
            ],
            [
              -73.847071,
              40.906173
            ],
            [
              -73.838407,
              40.894061
            ],
            [
              -73.827224,
              40.890785
            ],
            [
              -73.824047,
              40.889866
            ],
            [
              -73.823244,
              40.891199
            ],
            [
              -73.783702,
              40.881078
            ],
            [
              -73.767161767951,
              40.8767967281162
            ],
            [
              -73.766276,
              40.881099
            ],
            [
              -73.767176,
              40.886299
            ],
            [
              -73.770576,
              40.888399
            ],
            [
              -73.775383,
              40.892031
            ],
            [
              -73.756776,
              40.912599
            ],
            [
              -73.731775,
              40.924999
            ],
            [
              -73.721739,
              40.932037
            ],
            [
              -73.697974,
              40.939598
            ],
            [
              -73.686473,
              40.945198
            ],
            [
              -73.683273,
              40.948998
            ],
            [
              -73.678073,
              40.962798
            ],
            [
              -73.664472,
              40.967198
            ],
            [
              -73.662072,
              40.966198
            ],
            [
              -73.659972,
              40.968398
            ],
            [
              -73.655972,
              40.979597
            ],
            [
              -73.657336,
              40.985171
            ],
            [
              -73.6576158881351,
              40.98549919431
            ],
            [
              -73.659671,
              40.987909
            ],
            [
              -73.658772,
              40.993497
            ],
            [
              -73.659372,
              40.999497
            ],
            [
              -73.655571,
              41.007697
            ],
            [
              -73.654671,
              41.011697
            ],
            [
              -73.65535923695079,
              41.012778515208396
            ],
            [
              -73.655371,
              41.012797
            ],
            [
              -73.662672,
              41.020497
            ],
            [
              -73.670472,
              41.030097
            ],
            [
              -73.679973,
              41.041797
            ],
            [
              -73.687173,
              41.050697
            ],
            [
              -73.694273,
              41.059296
            ],
            [
              -73.70616305989901,
              41.074183562832594
            ],
            [
              -73.716875,
              41.087596
            ],
            [
              -73.722575,
              41.093596
            ],
            [
              -73.727775,
              41.100696
            ],
            [
              -73.6959488909347,
              41.1154341295047
            ],
            [
              -73.639672,
              41.141495
            ],
            [
              -73.632153,
              41.144921
            ],
            [
              -73.61439109108869,
              41.152914810371
            ],
            [
              -73.564941,
              41.17517
            ],
            [
              -73.5647586721642,
              41.17525428731359
            ],
            [
              -73.55465007369439,
              41.1799273346628
            ],
            [
              -73.514617,
              41.198434
            ],
            [
              -73.509487,
              41.200814
            ],
            [
              -73.4959359310458,
              41.206859300983105
            ],
            [
              -73.4849111933569,
              41.2117775740592
            ],
            [
              -73.482709,
              41.21276
            ],
            [
              -73.5025525269551,
              41.237211341315195
            ],
            [
              -73.518384,
              41.256719
            ],
            [
              -73.550961,
              41.295422
            ],
            [
              -73.548929,
              41.307598
            ],
            [
              -73.549574,
              41.315931
            ],
            [
              -73.548973,
              41.326297
            ],
            [
              -73.5452425041353,
              41.3615174507099
            ],
            [
              -73.544728,
              41.366375
            ],
            [
              -73.659871,
              41.355099
            ],
            [
              -73.780007,
              41.342492
            ],
            [
              -73.797456,
              41.340647
            ],
            [
              -73.86201,
              41.334264
            ],
            [
              -73.89346,
              41.330892
            ],
            [
              -73.982584,
              41.321693
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "063",
      "COUNTYNS": "00974130",
      "AFFGEOID": "0500000US36063",
      "GEOID": "36063",
      "NAME": "Niagara",
      "LSAD": "06",
      "ALAND": 1352887116,
      "AWATER": 1598842827,
      "County_state": "Niagara,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.074678,
              43.083141
            ],
            [
              -79.074467,
              43.077855
            ],
            [
              -79.0195783140304,
              43.0662946977452
            ],
            [
              -79.009664,
              43.069558
            ],
            [
              -78.945262,
              43.066956
            ],
            [
              -78.91152,
              43.05474
            ],
            [
              -78.90586,
              43.051556
            ],
            [
              -78.89626,
              43.044156
            ],
            [
              -78.890159,
              43.021956
            ],
            [
              -78.886467,
              43.022311
            ],
            [
              -78.83935,
              43.019453
            ],
            [
              -78.828805,
              43.030139
            ],
            [
              -78.825953,
              43.035955
            ],
            [
              -78.83126,
              43.050246
            ],
            [
              -78.822357,
              43.051557
            ],
            [
              -78.802051,
              43.065781
            ],
            [
              -78.744015,
              43.070376
            ],
            [
              -78.727647,
              43.08622
            ],
            [
              -78.719048,
              43.081055
            ],
            [
              -78.696906,
              43.086337
            ],
            [
              -78.682793,
              43.080269
            ],
            [
              -78.664711,
              43.086399
            ],
            [
              -78.648392,
              43.080589
            ],
            [
              -78.64212,
              43.096878
            ],
            [
              -78.618881,
              43.087444
            ],
            [
              -78.603989,
              43.072025
            ],
            [
              -78.586136,
              43.073109
            ],
            [
              -78.576248,
              43.088791
            ],
            [
              -78.550721,
              43.092907
            ],
            [
              -78.54,
              43.08567
            ],
            [
              -78.52079,
              43.088799
            ],
            [
              -78.509804,
              43.098034
            ],
            [
              -78.497932,
              43.082409
            ],
            [
              -78.472537,
              43.08165
            ],
            [
              -78.464449,
              43.088703
            ],
            [
              -78.465505,
              43.128619
            ],
            [
              -78.460416,
              43.216222
            ],
            [
              -78.466429,
              43.302775
            ],
            [
              -78.4655021200225,
              43.3712323915095
            ],
            [
              -78.473099,
              43.370812
            ],
            [
              -78.482526,
              43.374425
            ],
            [
              -78.488857,
              43.374763
            ],
            [
              -78.547395,
              43.369541
            ],
            [
              -78.634346,
              43.357624
            ],
            [
              -78.696856,
              43.341255
            ],
            [
              -78.747158,
              43.334555
            ],
            [
              -78.777759,
              43.327055
            ],
            [
              -78.834061,
              43.317555
            ],
            [
              -78.836261,
              43.318455
            ],
            [
              -78.859362,
              43.310955
            ],
            [
              -78.930764,
              43.293254
            ],
            [
              -78.971866,
              43.281254
            ],
            [
              -79.019848,
              43.273686
            ],
            [
              -79.070469,
              43.262454
            ],
            [
              -79.061388,
              43.251349
            ],
            [
              -79.055868,
              43.238554
            ],
            [
              -79.052868,
              43.222054
            ],
            [
              -79.0529386031844,
              43.2184397614707
            ],
            [
              -79.053109,
              43.209717
            ],
            [
              -79.050744,
              43.197417
            ],
            [
              -79.052567,
              43.183655
            ],
            [
              -79.053067,
              43.173655
            ],
            [
              -79.048467,
              43.164655
            ],
            [
              -79.046567,
              43.162355
            ],
            [
              -79.044567,
              43.153255
            ],
            [
              -79.042867,
              43.149155
            ],
            [
              -79.042366,
              43.143655
            ],
            [
              -79.044066,
              43.138055
            ],
            [
              -79.049467,
              43.135055
            ],
            [
              -79.05210115348869,
              43.132096087862
            ],
            [
              -79.056767,
              43.126855
            ],
            [
              -79.060206,
              43.124799
            ],
            [
              -79.062518,
              43.120182
            ],
            [
              -79.061967,
              43.115355
            ],
            [
              -79.0614,
              43.111096
            ],
            [
              -79.060281,
              43.105086
            ],
            [
              -79.064754,
              43.093205
            ],
            [
              -79.069667,
              43.088355
            ],
            [
              -79.074678,
              43.083141
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "065",
      "COUNTYNS": "00974131",
      "AFFGEOID": "0500000US36065",
      "GEOID": "36065",
      "NAME": "Oneida",
      "LSAD": "06",
      "ALAND": 3139921985,
      "AWATER": 117340432,
      "County_state": "Oneida,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -75.886756,
              43.325021
            ],
            [
              -75.862927,
              43.319274
            ],
            [
              -75.884275,
              43.155562
            ],
            [
              -75.868099,
              43.146699
            ],
            [
              -75.839993,
              43.160513
            ],
            [
              -75.797959,
              43.158985
            ],
            [
              -75.768787,
              43.169895
            ],
            [
              -75.752527,
              43.158722
            ],
            [
              -75.723929,
              43.162596
            ],
            [
              -75.707166,
              43.151445
            ],
            [
              -75.707357,
              43.137962
            ],
            [
              -75.678197,
              43.126897
            ],
            [
              -75.669701,
              43.115183
            ],
            [
              -75.653103,
              43.114111
            ],
            [
              -75.639392,
              43.095622
            ],
            [
              -75.640971,
              43.07992
            ],
            [
              -75.599496,
              43.0614
            ],
            [
              -75.596879,
              43.035399
            ],
            [
              -75.552774,
              43.037554
            ],
            [
              -75.550924,
              43.020411
            ],
            [
              -75.542803,
              42.931774
            ],
            [
              -75.456499,
              42.932882
            ],
            [
              -75.437167,
              42.863319
            ],
            [
              -75.247963,
              42.871604
            ],
            [
              -75.242413,
              42.877535
            ],
            [
              -75.212158,
              42.879973
            ],
            [
              -75.201444,
              42.880664
            ],
            [
              -75.207362,
              42.940574
            ],
            [
              -75.216006,
              43.022769
            ],
            [
              -75.219106,
              43.052469
            ],
            [
              -75.207807,
              43.064469
            ],
            [
              -75.182334,
              43.075303
            ],
            [
              -75.179646,
              43.094736
            ],
            [
              -75.160906,
              43.114769
            ],
            [
              -75.069165,
              43.227333
            ],
            [
              -75.122684,
              43.241048
            ],
            [
              -75.162371,
              43.258463
            ],
            [
              -75.147995,
              43.305159
            ],
            [
              -75.11984,
              43.304637
            ],
            [
              -75.106495,
              43.313539
            ],
            [
              -75.099839,
              43.325175
            ],
            [
              -75.076581,
              43.330705
            ],
            [
              -75.11016,
              43.615229
            ],
            [
              -75.225253,
              43.562204
            ],
            [
              -75.392396,
              43.485727
            ],
            [
              -75.5335,
              43.419756
            ],
            [
              -75.756213,
              43.470388
            ],
            [
              -75.814627,
              43.483578
            ],
            [
              -75.886756,
              43.325021
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "031",
      "COUNTYNS": "00974114",
      "AFFGEOID": "0500000US36031",
      "GEOID": "36031",
      "NAME": "Essex",
      "LSAD": "06",
      "ALAND": 4646734755,
      "AWATER": 316368965,
      "County_state": "Essex,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -74.255998,
              43.969797
            ],
            [
              -74.336826,
              43.925223
            ],
            [
              -74.213734,
              43.810875
            ],
            [
              -74.202829,
              43.820315
            ],
            [
              -74.149076,
              43.829613
            ],
            [
              -74.138677,
              43.817719
            ],
            [
              -74.101858,
              43.805003
            ],
            [
              -74.085652,
              43.808863
            ],
            [
              -74.047062,
              43.796343
            ],
            [
              -74.057005,
              43.744513
            ],
            [
              -73.43812,
              43.803687
            ],
            [
              -73.3793298267232,
              43.808476322237
            ],
            [
              -73.380804,
              43.810951
            ],
            [
              -73.383259,
              43.81331
            ],
            [
              -73.390302,
              43.817371
            ],
            [
              -73.392492,
              43.820779
            ],
            [
              -73.392751,
              43.822196
            ],
            [
              -73.390194,
              43.829364
            ],
            [
              -73.388389,
              43.832404
            ],
            [
              -73.381865,
              43.837315
            ],
            [
              -73.376598,
              43.839357
            ],
            [
              -73.373688,
              43.84261
            ],
            [
              -73.372247,
              43.845337
            ],
            [
              -73.372462,
              43.846266
            ],
            [
              -73.373742,
              43.847693
            ],
            [
              -73.380987,
              43.852633
            ],
            [
              -73.382046,
              43.855008
            ],
            [
              -73.381501,
              43.859235
            ],
            [
              -73.379334,
              43.864648
            ],
            [
              -73.37415,
              43.874163
            ],
            [
              -73.374051,
              43.875563
            ],
            [
              -73.376312,
              43.880292
            ],
            [
              -73.383491,
              43.890951
            ],
            [
              -73.395878,
              43.903044
            ],
            [
              -73.397256,
              43.905668
            ],
            [
              -73.400926,
              43.917048
            ],
            [
              -73.407742,
              43.929887
            ],
            [
              -73.4077427838004,
              43.9298898187203
            ],
            [
              -73.408589,
              43.932933
            ],
            [
              -73.405525,
              43.948813
            ],
            [
              -73.406823,
              43.967317
            ],
            [
              -73.411248,
              43.975596
            ],
            [
              -73.412613,
              43.97998
            ],
            [
              -73.412581,
              43.98272
            ],
            [
              -73.411224,
              43.986202
            ],
            [
              -73.405977,
              44.011485
            ],
            [
              -73.405999,
              44.016229
            ],
            [
              -73.40738403594659,
              44.0202750561591
            ],
            [
              -73.407739,
              44.021312
            ],
            [
              -73.410776,
              44.026944
            ],
            [
              -73.414364,
              44.029526
            ],
            [
              -73.42016,
              44.032004
            ],
            [
              -73.42312,
              44.032759
            ],
            [
              -73.427987,
              44.037708
            ],
            [
              -73.430772,
              44.038746
            ],
            [
              -73.43688,
              44.042578
            ],
            [
              -73.43774,
              44.045006
            ],
            [
              -73.431991,
              44.06345
            ],
            [
              -73.430207,
              44.071716
            ],
            [
              -73.429239,
              44.079414
            ],
            [
              -73.416319,
              44.099422
            ],
            [
              -73.411316,
              44.112686
            ],
            [
              -73.411722,
              44.11754
            ],
            [
              -73.413751,
              44.126068
            ],
            [
              -73.41578,
              44.131523
            ],
            [
              -73.415761,
              44.132826
            ],
            [
              -73.41172,
              44.137825
            ],
            [
              -73.408118,
              44.139373
            ],
            [
              -73.403268,
              44.144295
            ],
            [
              -73.402381,
              44.145856
            ],
            [
              -73.399634,
              44.155326
            ],
            [
              -73.398728,
              44.162248
            ],
            [
              -73.395532,
              44.166122
            ],
            [
              -73.395399,
              44.166903
            ],
            [
              -73.396664,
              44.168831
            ],
            [
              -73.397385,
              44.171596
            ],
            [
              -73.396892,
              44.173846
            ],
            [
              -73.395862,
              44.175785
            ],
            [
              -73.390383,
              44.179486
            ],
            [
              -73.389658,
              44.181249
            ],
            [
              -73.390805,
              44.189072
            ],
            [
              -73.390583,
              44.190886
            ],
            [
              -73.388502,
              44.192318
            ],
            [
              -73.385326,
              44.192597
            ],
            [
              -73.383987,
              44.193158
            ],
            [
              -73.382252,
              44.197178
            ],
            [
              -73.377693,
              44.199453
            ],
            [
              -73.375289,
              44.199868
            ],
            [
              -73.372405,
              44.202165
            ],
            [
              -73.370678,
              44.204546
            ],
            [
              -73.362013,
              44.208545
            ],
            [
              -73.361476,
              44.210374
            ],
            [
              -73.357908,
              44.216193
            ],
            [
              -73.355276,
              44.219554
            ],
            [
              -73.355252,
              44.22287
            ],
            [
              -73.354747,
              44.223599
            ],
            [
              -73.350806,
              44.225943
            ],
            [
              -73.349889,
              44.230356
            ],
            [
              -73.342312,
              44.234531
            ],
            [
              -73.34323,
              44.238049
            ],
            [
              -73.336778,
              44.239557
            ],
            [
              -73.334042,
              44.240971
            ],
            [
              -73.3305,
              44.244254
            ],
            [
              -73.329322,
              44.244504
            ],
            [
              -73.324681,
              44.243614
            ],
            [
              -73.323596,
              44.243897
            ],
            [
              -73.319802,
              44.249547
            ],
            [
              -73.316618,
              44.257769
            ],
            [
              -73.3134220405831,
              44.2641991074088
            ],
            [
              -73.312852,
              44.265346
            ],
            [
              -73.311025,
              44.27424
            ],
            [
              -73.312299,
              44.280025
            ],
            [
              -73.316838,
              44.287683
            ],
            [
              -73.322267,
              44.301523
            ],
            [
              -73.324229,
              44.310023
            ],
            [
              -73.324545,
              44.319247
            ],
            [
              -73.323835,
              44.325418
            ],
            [
              -73.323997,
              44.333842
            ],
            [
              -73.325127,
              44.338534
            ],
            [
              -73.32695510159479,
              44.3433650565242
            ],
            [
              -73.327335,
              44.344369
            ],
            [
              -73.334637,
              44.356877
            ],
            [
              -73.334939,
              44.364441
            ],
            [
              -73.333575,
              44.372288
            ],
            [
              -73.330369,
              44.375987
            ],
            [
              -73.320954,
              44.382669
            ],
            [
              -73.317029,
              44.385978
            ],
            [
              -73.315016,
              44.388513
            ],
            [
              -73.312418,
              44.39471
            ],
            [
              -73.310491,
              44.402601
            ],
            [
              -73.296031,
              44.428339
            ],
            [
              -73.293855,
              44.437556
            ],
            [
              -73.29380572883369,
              44.4381674103822
            ],
            [
              -73.293613,
              44.440559
            ],
            [
              -73.295216,
              44.445884
            ],
            [
              -73.300114,
              44.454711
            ],
            [
              -73.298725,
              44.463957
            ],
            [
              -73.298939,
              44.471304
            ],
            [
              -73.299885,
              44.476652
            ],
            [
              -73.304418,
              44.485739
            ],
            [
              -73.304921,
              44.492209
            ],
            [
              -73.306707,
              44.500334
            ],
            [
              -73.312871,
              44.507246
            ],
            [
              -73.319265,
              44.51196
            ],
            [
              -73.320836,
              44.513631
            ],
            [
              -73.321416,
              44.516454
            ],
            [
              -73.321111,
              44.519857
            ],
            [
              -73.3211898546784,
              44.5203251296318
            ],
            [
              -73.322026,
              44.525289
            ],
            [
              -73.323935,
              44.52712
            ],
            [
              -73.328512,
              44.528478
            ],
            [
              -73.329458,
              44.529203
            ],
            [
              -73.330588,
              44.531034
            ],
            [
              -73.330893,
              44.534269
            ],
            [
              -73.331595,
              44.535924
            ],
            [
              -73.338995,
              44.543302
            ],
            [
              -73.3393,
              44.544477
            ],
            [
              -73.33863044507319,
              44.5468424276295
            ],
            [
              -73.463838,
              44.537681
            ],
            [
              -73.46019,
              44.519793
            ],
            [
              -73.48832,
              44.49961
            ],
            [
              -73.496604,
              44.486081
            ],
            [
              -73.526907,
              44.488028
            ],
            [
              -73.55497,
              44.482549
            ],
            [
              -73.571307,
              44.465332
            ],
            [
              -73.615593,
              44.454346
            ],
            [
              -73.629049,
              44.458391
            ],
            [
              -73.669281,
              44.441355
            ],
            [
              -73.700717,
              44.445571
            ],
            [
              -73.909687,
              44.429699
            ],
            [
              -74.141424,
              44.407268
            ],
            [
              -74.122439,
              44.307876
            ],
            [
              -74.09349,
              44.137615
            ],
            [
              -74.28187,
              44.120552
            ],
            [
              -74.255998,
              43.969797
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "077",
      "COUNTYNS": "00974137",
      "AFFGEOID": "0500000US36077",
      "GEOID": "36077",
      "NAME": "Otsego",
      "LSAD": "06",
      "ALAND": 2594462131,
      "AWATER": 36339122,
      "County_state": "Otsego,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -75.398331,
              42.336065
            ],
            [
              -75.415319,
              42.314151
            ],
            [
              -75.377546,
              42.322149
            ],
            [
              -75.341368,
              42.314797
            ],
            [
              -75.307321,
              42.321291
            ],
            [
              -75.292726,
              42.339488
            ],
            [
              -75.269101,
              42.351312
            ],
            [
              -75.261569,
              42.366673
            ],
            [
              -75.241317,
              42.365073
            ],
            [
              -75.217635,
              42.367398
            ],
            [
              -75.19096,
              42.379852
            ],
            [
              -75.197237,
              42.358329
            ],
            [
              -75.093408,
              42.420634
            ],
            [
              -75.059068,
              42.43064
            ],
            [
              -75.019818,
              42.429045
            ],
            [
              -75.017958,
              42.454243
            ],
            [
              -74.992406,
              42.440483
            ],
            [
              -74.968706,
              42.468435
            ],
            [
              -74.844121,
              42.512591
            ],
            [
              -74.777776,
              42.506327
            ],
            [
              -74.772888,
              42.505169
            ],
            [
              -74.751865,
              42.514392
            ],
            [
              -74.71158,
              42.517799
            ],
            [
              -74.672428,
              42.564455
            ],
            [
              -74.630631,
              42.626674
            ],
            [
              -74.634637,
              42.647809
            ],
            [
              -74.667512,
              42.75071
            ],
            [
              -74.665383,
              42.791873
            ],
            [
              -74.648298,
              42.829558
            ],
            [
              -74.751246,
              42.859658
            ],
            [
              -74.763303,
              42.863237
            ],
            [
              -74.878822,
              42.898274
            ],
            [
              -74.894671,
              42.832051
            ],
            [
              -74.906738,
              42.824943
            ],
            [
              -74.954822,
              42.846422
            ],
            [
              -75.100999,
              42.908363
            ],
            [
              -75.13987,
              42.85976
            ],
            [
              -75.210642,
              42.856549
            ],
            [
              -75.212158,
              42.879973
            ],
            [
              -75.242413,
              42.877535
            ],
            [
              -75.247963,
              42.871604
            ],
            [
              -75.2448,
              42.868633
            ],
            [
              -75.247524,
              42.864713
            ],
            [
              -75.246614,
              42.844521
            ],
            [
              -75.239547,
              42.834233
            ],
            [
              -75.253242,
              42.795703
            ],
            [
              -75.251416,
              42.784565
            ],
            [
              -75.273156,
              42.772487
            ],
            [
              -75.280061,
              42.754546
            ],
            [
              -75.295877,
              42.744106
            ],
            [
              -75.296513,
              42.718653
            ],
            [
              -75.313283,
              42.689745
            ],
            [
              -75.330394,
              42.674195
            ],
            [
              -75.325379,
              42.646453
            ],
            [
              -75.322983,
              42.623184
            ],
            [
              -75.337168,
              42.613367
            ],
            [
              -75.326639,
              42.577851
            ],
            [
              -75.33719,
              42.562338
            ],
            [
              -75.364728,
              42.554611
            ],
            [
              -75.389161,
              42.511231
            ],
            [
              -75.403459,
              42.508699
            ],
            [
              -75.395112,
              42.487017
            ],
            [
              -75.404464,
              42.479117
            ],
            [
              -75.39913,
              42.461067
            ],
            [
              -75.386205,
              42.44499
            ],
            [
              -75.374617,
              42.415998
            ],
            [
              -75.392582,
              42.400913
            ],
            [
              -75.40669,
              42.380781
            ],
            [
              -75.394267,
              42.353354
            ],
            [
              -75.398331,
              42.336065
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "041",
      "COUNTYNS": "00974119",
      "AFFGEOID": "0500000US36041",
      "GEOID": "36041",
      "NAME": "Hamilton",
      "LSAD": "06",
      "ALAND": 4447987884,
      "AWATER": 234205889,
      "County_state": "Hamilton,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -74.775617,
              43.486677
            ],
            [
              -74.867712,
              43.339826
            ],
            [
              -74.712615,
              43.286143
            ],
            [
              -74.713005,
              43.289003
            ],
            [
              -74.534657,
              43.228115
            ],
            [
              -74.326378,
              43.241635
            ],
            [
              -74.322375,
              43.214684
            ],
            [
              -74.220902,
              43.221403
            ],
            [
              -74.224215,
              43.248165
            ],
            [
              -74.140147,
              43.253979
            ],
            [
              -74.1601,
              43.371532
            ],
            [
              -74.214625,
              43.728703
            ],
            [
              -74.057005,
              43.744513
            ],
            [
              -74.047062,
              43.796343
            ],
            [
              -74.085652,
              43.808863
            ],
            [
              -74.101858,
              43.805003
            ],
            [
              -74.138677,
              43.817719
            ],
            [
              -74.149076,
              43.829613
            ],
            [
              -74.202829,
              43.820315
            ],
            [
              -74.213734,
              43.810875
            ],
            [
              -74.336826,
              43.925223
            ],
            [
              -74.255998,
              43.969797
            ],
            [
              -74.28187,
              44.120552
            ],
            [
              -74.535156,
              44.09925
            ],
            [
              -74.649076,
              44.088343
            ],
            [
              -74.768108,
              44.077604
            ],
            [
              -74.854171,
              44.070089
            ],
            [
              -74.775617,
              43.486677
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "057",
      "COUNTYNS": "00974127",
      "AFFGEOID": "0500000US36057",
      "GEOID": "36057",
      "NAME": "Montgomery",
      "LSAD": "06",
      "ALAND": 1044067732,
      "AWATER": 18598434,
      "County_state": "Montgomery,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -74.763303,
              42.863237
            ],
            [
              -74.751246,
              42.859658
            ],
            [
              -74.648298,
              42.829558
            ],
            [
              -74.562477,
              42.812916
            ],
            [
              -74.454911,
              42.772979
            ],
            [
              -74.411384,
              42.77649
            ],
            [
              -74.289446,
              42.783805
            ],
            [
              -74.263314,
              42.796534
            ],
            [
              -74.256442,
              42.8119
            ],
            [
              -74.084844,
              42.896869
            ],
            [
              -74.084297,
              42.900434
            ],
            [
              -74.09298,
              42.955868
            ],
            [
              -74.096897,
              42.979378
            ],
            [
              -74.097467,
              42.982934
            ],
            [
              -74.488844,
              42.985118
            ],
            [
              -74.542367,
              42.98553
            ],
            [
              -74.759895,
              43.047423
            ],
            [
              -74.743805,
              43.030741
            ],
            [
              -74.743715,
              42.99941
            ],
            [
              -74.740356,
              42.996588
            ],
            [
              -74.763303,
              42.863237
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "033",
      "COUNTYNS": "00974115",
      "AFFGEOID": "0500000US36033",
      "GEOID": "36033",
      "NAME": "Franklin",
      "LSAD": "06",
      "ALAND": 4219632482,
      "AWATER": 176707575,
      "County_state": "Franklin,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -74.722574,
              44.998062
            ],
            [
              -74.72622806320749,
              44.994863072200594
            ],
            [
              -74.720307,
              44.953011
            ],
            [
              -74.641872,
              44.952621
            ],
            [
              -74.62294,
              44.815154
            ],
            [
              -74.525683,
              44.170636
            ],
            [
              -74.544487,
              44.169414
            ],
            [
              -74.535156,
              44.09925
            ],
            [
              -74.28187,
              44.120552
            ],
            [
              -74.09349,
              44.137615
            ],
            [
              -74.122439,
              44.307876
            ],
            [
              -74.141424,
              44.407268
            ],
            [
              -73.909687,
              44.429699
            ],
            [
              -73.932927,
              44.576197
            ],
            [
              -73.9459,
              44.574869
            ],
            [
              -73.966148,
              44.709118
            ],
            [
              -73.986382,
              44.707773
            ],
            [
              -74.006692,
              44.836938
            ],
            [
              -74.005957,
              44.872184
            ],
            [
              -74.009446,
              44.903518
            ],
            [
              -74.0273918752465,
              44.9957654989181
            ],
            [
              -74.146814,
              44.9915
            ],
            [
              -74.234136,
              44.992148
            ],
            [
              -74.335184,
              44.991905
            ],
            [
              -74.45753,
              44.997032
            ],
            [
              -74.54902,
              44.998699
            ],
            [
              -74.661478,
              44.999592
            ],
            [
              -74.667338,
              45.001648
            ],
            [
              -74.673047,
              45.000942
            ],
            [
              -74.678428,
              45.000047
            ],
            [
              -74.683973,
              44.99969
            ],
            [
              -74.702018,
              45.003322
            ],
            [
              -74.722574,
              44.998062
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "049",
      "COUNTYNS": "00974123",
      "AFFGEOID": "0500000US36049",
      "GEOID": "36049",
      "NAME": "Lewis",
      "LSAD": "06",
      "ALAND": 3301307137,
      "AWATER": 39612943,
      "County_state": "Lewis,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -75.84056,
              43.883976
            ],
            [
              -75.850534,
              43.791886
            ],
            [
              -75.786759,
              43.78832
            ],
            [
              -75.774553,
              43.688884
            ],
            [
              -75.766776,
              43.625069
            ],
            [
              -75.756213,
              43.470388
            ],
            [
              -75.5335,
              43.419756
            ],
            [
              -75.392396,
              43.485727
            ],
            [
              -75.225253,
              43.562204
            ],
            [
              -75.11016,
              43.615229
            ],
            [
              -75.170159,
              44.096959
            ],
            [
              -75.261169,
              44.136123
            ],
            [
              -75.413334,
              44.203399
            ],
            [
              -75.446124,
              44.217655
            ],
            [
              -75.533512,
              44.117506
            ],
            [
              -75.545886,
              44.102978
            ],
            [
              -75.484528,
              44.074172
            ],
            [
              -75.502741,
              44.051253
            ],
            [
              -75.493342,
              44.046835
            ],
            [
              -75.502014,
              44.036274
            ],
            [
              -75.50183,
              44.009556
            ],
            [
              -75.549993,
              44.008163
            ],
            [
              -75.542898,
              43.967795
            ],
            [
              -75.577181,
              43.965276
            ],
            [
              -75.60367,
              43.971363
            ],
            [
              -75.758157,
              43.878785
            ],
            [
              -75.84056,
              43.883976
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "007",
      "COUNTYNS": "00974102",
      "AFFGEOID": "0500000US36007",
      "GEOID": "36007",
      "NAME": "Broome",
      "LSAD": "06",
      "ALAND": 1827987181,
      "AWATER": 25185424,
      "County_state": "Broome,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.130181,
              42.410337
            ],
            [
              -76.101622,
              42.310395
            ],
            [
              -76.106582,
              42.287356
            ],
            [
              -76.087728,
              42.278752
            ],
            [
              -76.080681,
              42.258084
            ],
            [
              -76.096153,
              42.257509
            ],
            [
              -76.081134,
              42.230495
            ],
            [
              -76.086683,
              42.186858
            ],
            [
              -76.116225,
              42.18561
            ],
            [
              -76.114033,
              42.153418
            ],
            [
              -76.108562,
              42.101256
            ],
            [
              -76.108948,
              42.074751
            ],
            [
              -76.104829,
              42.031568
            ],
            [
              -76.10584,
              41.998858
            ],
            [
              -76.0600871153046,
              41.9989239693103
            ],
            [
              -75.983082,
              41.999035
            ],
            [
              -75.98025,
              41.999035
            ],
            [
              -75.9584074252567,
              41.9989937360666
            ],
            [
              -75.870677,
              41.998828
            ],
            [
              -75.7733433652803,
              41.9980975810068
            ],
            [
              -75.742217,
              41.997864
            ],
            [
              -75.610316,
              41.99896
            ],
            [
              -75.55307597628,
              41.9990884280581
            ],
            [
              -75.483738,
              41.999244
            ],
            [
              -75.4831501008195,
              41.9992585325396
            ],
            [
              -75.477144,
              41.999407
            ],
            [
              -75.436216,
              41.999353
            ],
            [
              -75.431961,
              41.999363
            ],
            [
              -75.4022261958532,
              41.9993966859156
            ],
            [
              -75.421776,
              42.04203
            ],
            [
              -75.419664,
              42.150436
            ],
            [
              -75.418421,
              42.195032
            ],
            [
              -75.483638,
              42.195183
            ],
            [
              -75.63711,
              42.195628
            ],
            [
              -75.638299,
              42.248686
            ],
            [
              -75.722286,
              42.249341
            ],
            [
              -75.819382,
              42.249003
            ],
            [
              -75.836502,
              42.244283
            ],
            [
              -75.843792,
              42.259707
            ],
            [
              -75.852065,
              42.345344
            ],
            [
              -75.86402,
              42.415702
            ],
            [
              -76.021136,
              42.409333
            ],
            [
              -76.057633,
              42.411941
            ],
            [
              -76.130181,
              42.410337
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "093",
      "COUNTYNS": "00974144",
      "AFFGEOID": "0500000US36093",
      "GEOID": "36093",
      "NAME": "Schenectady",
      "LSAD": "06",
      "ALAND": 529856261,
      "AWATER": 12600384,
      "County_state": "Schenectady,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -74.286988,
              42.756685
            ],
            [
              -74.30533,
              42.747396
            ],
            [
              -74.272295,
              42.71427
            ],
            [
              -74.239846,
              42.718924
            ],
            [
              -74.184235,
              42.7125
            ],
            [
              -74.180274,
              42.729979
            ],
            [
              -74.106499,
              42.739235
            ],
            [
              -73.933755,
              42.7607
            ],
            [
              -73.906785,
              42.763432
            ],
            [
              -73.811626,
              42.774774
            ],
            [
              -73.809369,
              42.778869
            ],
            [
              -73.822166,
              42.780278
            ],
            [
              -73.875864,
              42.834274
            ],
            [
              -73.881769,
              42.850876
            ],
            [
              -73.895938,
              42.851076
            ],
            [
              -73.904386,
              42.911163
            ],
            [
              -73.955458,
              42.897306
            ],
            [
              -74.005656,
              42.933484
            ],
            [
              -74.055458,
              42.953276
            ],
            [
              -74.09298,
              42.955868
            ],
            [
              -74.084297,
              42.900434
            ],
            [
              -74.084844,
              42.896869
            ],
            [
              -74.256442,
              42.8119
            ],
            [
              -74.263314,
              42.796534
            ],
            [
              -74.248062,
              42.78457
            ],
            [
              -74.258797,
              42.757135
            ],
            [
              -74.286988,
              42.756685
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "101",
      "COUNTYNS": "00974148",
      "AFFGEOID": "0500000US36101",
      "GEOID": "36101",
      "NAME": "Steuben",
      "LSAD": "06",
      "ALAND": 3601504767,
      "AWATER": 35055663,
      "County_state": "Steuben,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.741531,
              42.19221
            ],
            [
              -77.749931,
              41.998782
            ],
            [
              -77.6100277930756,
              41.99951862464489
            ],
            [
              -77.505308,
              42.00007
            ],
            [
              -77.4565781532607,
              41.9999835802673
            ],
            [
              -77.124693,
              41.999395
            ],
            [
              -77.063676,
              42.000461
            ],
            [
              -77.007635,
              42.000848
            ],
            [
              -77.007536,
              42.000819
            ],
            [
              -76.96572797329421,
              42.0012735436595
            ],
            [
              -76.965376,
              42.199156
            ],
            [
              -76.965028,
              42.278495
            ],
            [
              -77.074784,
              42.275476
            ],
            [
              -77.099657,
              42.272356
            ],
            [
              -77.107203,
              42.483771
            ],
            [
              -77.107593,
              42.50577
            ],
            [
              -77.127279,
              42.497259
            ],
            [
              -77.149502,
              42.50007
            ],
            [
              -77.149595,
              42.55387
            ],
            [
              -77.143795,
              42.576869
            ],
            [
              -77.242763,
              42.576374
            ],
            [
              -77.366505,
              42.576368
            ],
            [
              -77.455632,
              42.576895
            ],
            [
              -77.490889,
              42.577288
            ],
            [
              -77.659917,
              42.580409
            ],
            [
              -77.661315,
              42.535067
            ],
            [
              -77.720617,
              42.535066
            ],
            [
              -77.722964,
              42.471216
            ],
            [
              -77.726111,
              42.404043
            ],
            [
              -77.736813,
              42.28153
            ],
            [
              -77.741531,
              42.19221
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "017",
      "COUNTYNS": "00974107",
      "AFFGEOID": "0500000US36017",
      "GEOID": "36017",
      "NAME": "Chenango",
      "LSAD": "06",
      "ALAND": 2314378336,
      "AWATER": 13062477,
      "County_state": "Chenango,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -75.889832,
              42.723844
            ],
            [
              -75.874224,
              42.542716
            ],
            [
              -75.86402,
              42.415702
            ],
            [
              -75.852065,
              42.345344
            ],
            [
              -75.843792,
              42.259707
            ],
            [
              -75.836502,
              42.244283
            ],
            [
              -75.819382,
              42.249003
            ],
            [
              -75.722286,
              42.249341
            ],
            [
              -75.638299,
              42.248686
            ],
            [
              -75.63711,
              42.195628
            ],
            [
              -75.483638,
              42.195183
            ],
            [
              -75.418421,
              42.195032
            ],
            [
              -75.416109,
              42.281661
            ],
            [
              -75.415319,
              42.314151
            ],
            [
              -75.398331,
              42.336065
            ],
            [
              -75.394267,
              42.353354
            ],
            [
              -75.40669,
              42.380781
            ],
            [
              -75.392582,
              42.400913
            ],
            [
              -75.374617,
              42.415998
            ],
            [
              -75.386205,
              42.44499
            ],
            [
              -75.39913,
              42.461067
            ],
            [
              -75.404464,
              42.479117
            ],
            [
              -75.395112,
              42.487017
            ],
            [
              -75.403459,
              42.508699
            ],
            [
              -75.389161,
              42.511231
            ],
            [
              -75.364728,
              42.554611
            ],
            [
              -75.33719,
              42.562338
            ],
            [
              -75.326639,
              42.577851
            ],
            [
              -75.337168,
              42.613367
            ],
            [
              -75.322983,
              42.623184
            ],
            [
              -75.325379,
              42.646453
            ],
            [
              -75.330394,
              42.674195
            ],
            [
              -75.313283,
              42.689745
            ],
            [
              -75.296513,
              42.718653
            ],
            [
              -75.295877,
              42.744106
            ],
            [
              -75.427988,
              42.738111
            ],
            [
              -75.428668,
              42.745421
            ],
            [
              -75.889832,
              42.723844
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "105",
      "COUNTYNS": "00974150",
      "AFFGEOID": "0500000US36105",
      "GEOID": "36105",
      "NAME": "Sullivan",
      "LSAD": "06",
      "ALAND": 2507463254,
      "AWATER": 74035009,
      "County_state": "Sullivan,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -75.146446,
              41.850899
            ],
            [
              -75.143824,
              41.851737
            ],
            [
              -75.140241,
              41.852078
            ],
            [
              -75.130983,
              41.845145
            ],
            [
              -75.127913,
              41.844903
            ],
            [
              -75.118789,
              41.845819
            ],
            [
              -75.115598,
              41.844638
            ],
            [
              -75.114399,
              41.843583
            ],
            [
              -75.113369,
              41.840698
            ],
            [
              -75.113441,
              41.836298
            ],
            [
              -75.114998,
              41.8303
            ],
            [
              -75.115147,
              41.827285
            ],
            [
              -75.114837,
              41.82567
            ],
            [
              -75.113334,
              41.822782
            ],
            [
              -75.100024,
              41.818347
            ],
            [
              -75.093537,
              41.813375
            ],
            [
              -75.089484,
              41.811576
            ],
            [
              -75.085789,
              41.811626
            ],
            [
              -75.079818,
              41.814815
            ],
            [
              -75.078063,
              41.815112
            ],
            [
              -75.074409,
              41.815088
            ],
            [
              -75.072172,
              41.813732
            ],
            [
              -75.071751,
              41.811901
            ],
            [
              -75.072168,
              41.808327
            ],
            [
              -75.074412,
              41.802191
            ],
            [
              -75.076889,
              41.798509
            ],
            [
              -75.07827,
              41.797467
            ],
            [
              -75.081415,
              41.796483
            ],
            [
              -75.088328,
              41.797534
            ],
            [
              -75.092876,
              41.796386
            ],
            [
              -75.101463,
              41.787941
            ],
            [
              -75.102329,
              41.786503
            ],
            [
              -75.103548,
              41.782008
            ],
            [
              -75.1039936438857,
              41.7788227888939
            ],
            [
              -75.10464,
              41.774203
            ],
            [
              -75.104334,
              41.772693
            ],
            [
              -75.103492,
              41.771238
            ],
            [
              -75.10099,
              41.769121
            ],
            [
              -75.095451,
              41.768366
            ],
            [
              -75.09281,
              41.768361
            ],
            [
              -75.079478,
              41.771205
            ],
            [
              -75.075942,
              41.771518
            ],
            [
              -75.074231,
              41.770518
            ],
            [
              -75.072664,
              41.768807
            ],
            [
              -75.068567,
              41.767298
            ],
            [
              -75.064901,
              41.766686
            ],
            [
              -75.060759,
              41.764638
            ],
            [
              -75.053431,
              41.752538
            ],
            [
              -75.052808,
              41.744725
            ],
            [
              -75.054818,
              41.735168
            ],
            [
              -75.053527,
              41.72715
            ],
            [
              -75.049699,
              41.715093
            ],
            [
              -75.049862,
              41.713309
            ],
            [
              -75.050689,
              41.711969
            ],
            [
              -75.052226,
              41.711396
            ],
            [
              -75.061174,
              41.712935
            ],
            [
              -75.06663,
              41.712588
            ],
            [
              -75.068642,
              41.710146
            ],
            [
              -75.06883,
              41.708161
            ],
            [
              -75.067278,
              41.705434
            ],
            [
              -75.059829,
              41.699716
            ],
            [
              -75.056745,
              41.695703
            ],
            [
              -75.052736,
              41.688393
            ],
            [
              -75.051234,
              41.682439
            ],
            [
              -75.051285,
              41.679961
            ],
            [
              -75.052653,
              41.678436
            ],
            [
              -75.058765,
              41.674412
            ],
            [
              -75.059332,
              41.67232
            ],
            [
              -75.05843,
              41.669653
            ],
            [
              -75.057251,
              41.668933
            ],
            [
              -75.053991,
              41.668194
            ],
            [
              -75.04992,
              41.662556
            ],
            [
              -75.048683,
              41.656317
            ],
            [
              -75.049281,
              41.641862
            ],
            [
              -75.048658,
              41.633781
            ],
            [
              -75.048199,
              41.632011
            ],
            [
              -75.043562,
              41.62364
            ],
            [
              -75.044224,
              41.617978
            ],
            [
              -75.045508,
              41.616203
            ],
            [
              -75.047298,
              41.615791
            ],
            [
              -75.048385,
              41.615986
            ],
            [
              -75.051856,
              41.618157
            ],
            [
              -75.05385,
              41.618655
            ],
            [
              -75.060098,
              41.617482
            ],
            [
              -75.06156,
              41.616429
            ],
            [
              -75.061675,
              41.615468
            ],
            [
              -75.059956,
              41.612306
            ],
            [
              -75.059725,
              41.610801
            ],
            [
              -75.062716,
              41.609639
            ],
            [
              -75.067795,
              41.610143
            ],
            [
              -75.071667,
              41.609501
            ],
            [
              -75.074626,
              41.607905
            ],
            [
              -75.074613,
              41.605711
            ],
            [
              -75.0697121394208,
              41.6016900928416
            ],
            [
              -75.066955,
              41.599428
            ],
            [
              -75.063677,
              41.594739
            ],
            [
              -75.060012,
              41.590813
            ],
            [
              -75.052858,
              41.587772
            ],
            [
              -75.04676,
              41.583258
            ],
            [
              -75.043879,
              41.575094
            ],
            [
              -75.04049,
              41.569688
            ],
            [
              -75.036989,
              41.567049
            ],
            [
              -75.033162,
              41.565092
            ],
            [
              -75.029211,
              41.564637
            ],
            [
              -75.027343,
              41.563541
            ],
            [
              -75.018524,
              41.551802
            ],
            [
              -75.016328,
              41.546501
            ],
            [
              -75.016144,
              41.544246
            ],
            [
              -75.017626,
              41.542734
            ],
            [
              -75.022828,
              41.541456
            ],
            [
              -75.024798,
              41.539801
            ],
            [
              -75.024757,
              41.535099
            ],
            [
              -75.024206,
              41.534018
            ],
            [
              -75.023018,
              41.533147
            ],
            [
              -75.016616,
              41.53211
            ],
            [
              -75.014919,
              41.531399
            ],
            [
              -75.009552,
              41.528461
            ],
            [
              -75.00385,
              41.524052
            ],
            [
              -75.001297,
              41.52065
            ],
            [
              -75.000911,
              41.519292
            ],
            [
              -75.000935,
              41.517638
            ],
            [
              -75.002592,
              41.51456
            ],
            [
              -75.003706,
              41.511118
            ],
            [
              -75.003694,
              41.509295
            ],
            [
              -75.003151,
              41.508101
            ],
            [
              -74.999612,
              41.5074
            ],
            [
              -74.993893,
              41.508754
            ],
            [
              -74.987645,
              41.508738
            ],
            [
              -74.985653,
              41.507926
            ],
            [
              -74.984372,
              41.506611
            ],
            [
              -74.982385,
              41.500981
            ],
            [
              -74.982168,
              41.498486
            ],
            [
              -74.982463,
              41.496467
            ],
            [
              -74.985247,
              41.489113
            ],
            [
              -74.985595,
              41.485863
            ],
            [
              -74.985004,
              41.483703
            ],
            [
              -74.983341,
              41.480894
            ],
            [
              -74.981652,
              41.479945
            ],
            [
              -74.969887,
              41.477438
            ],
            [
              -74.95826,
              41.476396
            ],
            [
              -74.956411,
              41.476735
            ],
            [
              -74.94808,
              41.480625
            ],
            [
              -74.945634,
              41.483213
            ],
            [
              -74.941798,
              41.483542
            ],
            [
              -74.932585,
              41.482323
            ],
            [
              -74.926835,
              41.478327
            ],
            [
              -74.924092,
              41.477138
            ],
            [
              -74.917282,
              41.477041
            ],
            [
              -74.912517,
              41.475605
            ],
            [
              -74.909181,
              41.472436
            ],
            [
              -74.908133,
              41.468117
            ],
            [
              -74.908103,
              41.464639
            ],
            [
              -74.906887,
              41.461131
            ],
            [
              -74.9042,
              41.459806
            ],
            [
              -74.895069,
              41.45819
            ],
            [
              -74.892114,
              41.456959
            ],
            [
              -74.890358,
              41.455324
            ],
            [
              -74.889116,
              41.452534
            ],
            [
              -74.889075,
              41.451245
            ],
            [
              -74.894931,
              41.446099
            ],
            [
              -74.896399,
              41.442179
            ],
            [
              -74.896025,
              41.439987
            ],
            [
              -74.893913,
              41.43893
            ],
            [
              -74.888691,
              41.438259
            ],
            [
              -74.876721,
              41.440338
            ],
            [
              -74.864688,
              41.443993
            ],
            [
              -74.858578,
              41.444427
            ],
            [
              -74.8542,
              41.443166
            ],
            [
              -74.848602,
              41.440179
            ],
            [
              -74.845572,
              41.437577
            ],
            [
              -74.836915,
              41.431625
            ],
            [
              -74.834635,
              41.430796
            ],
            [
              -74.830671,
              41.430503
            ],
            [
              -74.828592,
              41.430698
            ],
            [
              -74.826031,
              41.431736
            ],
            [
              -74.82288,
              41.436792
            ],
            [
              -74.817995,
              41.440505
            ],
            [
              -74.812123,
              41.442982
            ],
            [
              -74.807582,
              41.442847
            ],
            [
              -74.805655,
              41.442101
            ],
            [
              -74.801225,
              41.4381
            ],
            [
              -74.80037,
              41.43606
            ],
            [
              -74.800095,
              41.432661
            ],
            [
              -74.799546,
              41.43129
            ],
            [
              -74.795396,
              41.42398
            ],
            [
              -74.793856,
              41.422671
            ],
            [
              -74.790417,
              41.42166
            ],
            [
              -74.784339,
              41.422397
            ],
            [
              -74.778029,
              41.425104
            ],
            [
              -74.773239,
              41.426352
            ],
            [
              -74.77065,
              41.42623
            ],
            [
              -74.763701,
              41.423612
            ],
            [
              -74.758587,
              41.423287
            ],
            [
              -74.7547086478625,
              41.4249931814039
            ],
            [
              -74.762466,
              41.449525
            ],
            [
              -74.750832,
              41.463713
            ],
            [
              -74.761435,
              41.490245
            ],
            [
              -74.752399,
              41.493743
            ],
            [
              -74.529751,
              41.502394
            ],
            [
              -74.475623,
              41.503987
            ],
            [
              -74.45454,
              41.533369
            ],
            [
              -74.419321,
              41.565626
            ],
            [
              -74.408278,
              41.575175
            ],
            [
              -74.367055,
              41.590977
            ],
            [
              -74.364486,
              41.593783
            ],
            [
              -74.376138,
              41.598189
            ],
            [
              -74.394771,
              41.644123
            ],
            [
              -74.575086,
              41.745258
            ],
            [
              -74.525506,
              41.796817
            ],
            [
              -74.486924,
              41.835264
            ],
            [
              -74.453685,
              41.875595
            ],
            [
              -74.655032,
              41.963676
            ],
            [
              -74.67402,
              41.971675
            ],
            [
              -74.780693,
              42.016375
            ],
            [
              -74.997252,
              41.918485
            ],
            [
              -75.146446,
              41.850899
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "079",
      "COUNTYNS": "00974138",
      "AFFGEOID": "0500000US36079",
      "GEOID": "36079",
      "NAME": "Putnam",
      "LSAD": "06",
      "ALAND": 596504204,
      "AWATER": 41282800,
      "County_state": "Putnam,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -73.981384,
              41.324693
            ],
            [
              -73.982584,
              41.321693
            ],
            [
              -73.89346,
              41.330892
            ],
            [
              -73.86201,
              41.334264
            ],
            [
              -73.797456,
              41.340647
            ],
            [
              -73.780007,
              41.342492
            ],
            [
              -73.659871,
              41.355099
            ],
            [
              -73.544728,
              41.366375
            ],
            [
              -73.543425,
              41.376622
            ],
            [
              -73.5434148559124,
              41.3767540709835
            ],
            [
              -73.541169,
              41.405994
            ],
            [
              -73.537673,
              41.433905
            ],
            [
              -73.537469,
              41.43589
            ],
            [
              -73.536969,
              41.441094
            ],
            [
              -73.536067,
              41.451331
            ],
            [
              -73.535986,
              41.45306
            ],
            [
              -73.535885,
              41.455236
            ],
            [
              -73.535857,
              41.455709
            ],
            [
              -73.535769,
              41.457159
            ],
            [
              -73.53529546019818,
              41.463495977276594
            ],
            [
              -73.534369,
              41.475894
            ],
            [
              -73.534269,
              41.476394
            ],
            [
              -73.534269,
              41.476911
            ],
            [
              -73.53415,
              41.47806
            ],
            [
              -73.534055,
              41.478968
            ],
            [
              -73.533969,
              41.479693
            ],
            [
              -73.530067,
              41.527194
            ],
            [
              -73.579783,
              41.526461
            ],
            [
              -73.647635,
              41.518293
            ],
            [
              -73.673285,
              41.514701
            ],
            [
              -73.692664,
              41.512246
            ],
            [
              -73.933775,
              41.488279
            ],
            [
              -73.981486,
              41.438905
            ],
            [
              -73.959136,
              41.400442
            ],
            [
              -73.947294,
              41.394765
            ],
            [
              -73.961257,
              41.348539
            ],
            [
              -73.981384,
              41.324693
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "087",
      "COUNTYNS": "00974142",
      "AFFGEOID": "0500000US36087",
      "GEOID": "36087",
      "NAME": "Rockland",
      "LSAD": "06",
      "ALAND": 449199631,
      "AWATER": 66861670,
      "County_state": "Rockland,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -74.2343582601916,
              41.142836102143896
            ],
            [
              -74.2113709734917,
              41.1334404575138
            ],
            [
              -74.1953371933152,
              41.1268869350132
            ],
            [
              -74.18239,
              41.121595
            ],
            [
              -74.096786,
              41.083796
            ],
            [
              -74.092486,
              41.081896
            ],
            [
              -74.041054,
              41.059088
            ],
            [
              -74.041049,
              41.059086
            ],
            [
              -73.9589142691188,
              41.02233969118829
            ],
            [
              -73.91188,
              41.001297
            ],
            [
              -73.907054,
              40.998476
            ],
            [
              -73.90501,
              40.997591
            ],
            [
              -73.90268,
              40.997297
            ],
            [
              -73.893979,
              40.997197
            ],
            [
              -73.893579,
              41.005297
            ],
            [
              -73.88938,
              41.037597
            ],
            [
              -73.894777,
              41.070791
            ],
            [
              -73.89378,
              41.093296
            ],
            [
              -73.88758,
              41.128795
            ],
            [
              -73.892481,
              41.150595
            ],
            [
              -73.909281,
              41.164395
            ],
            [
              -73.921697,
              41.186288
            ],
            [
              -73.931482,
              41.204994
            ],
            [
              -73.947965,
              41.223101
            ],
            [
              -73.970784,
              41.247893
            ],
            [
              -73.966884,
              41.267193
            ],
            [
              -73.946583,
              41.290993
            ],
            [
              -73.964483,
              41.299693
            ],
            [
              -73.982584,
              41.321693
            ],
            [
              -73.981384,
              41.324693
            ],
            [
              -74.069505,
              41.261794
            ],
            [
              -74.09884,
              41.2408
            ],
            [
              -74.161789,
              41.195794
            ],
            [
              -74.2343582601916,
              41.142836102143896
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "021",
      "COUNTYNS": "00974109",
      "AFFGEOID": "0500000US36021",
      "GEOID": "36021",
      "NAME": "Columbia",
      "LSAD": "06",
      "ALAND": 1643778340,
      "AWATER": 35063763,
      "County_state": "Columbia,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -73.921465,
              42.110025
            ],
            [
              -73.929626,
              42.078778
            ],
            [
              -73.907785,
              42.07961
            ],
            [
              -73.818533,
              42.046739
            ],
            [
              -73.71093,
              42.005488
            ],
            [
              -73.527072,
              41.97798
            ],
            [
              -73.521416,
              42.049966
            ],
            [
              -73.496879,
              42.049675
            ],
            [
              -73.508142,
              42.086257
            ],
            [
              -73.47591804393001,
              42.1740039412646
            ],
            [
              -73.3835056572836,
              42.4256460501838
            ],
            [
              -73.352527,
              42.510002
            ],
            [
              -73.3525249549442,
              42.5100075099506
            ],
            [
              -73.71799,
              42.470751
            ],
            [
              -73.783721,
              42.464231
            ],
            [
              -73.776261,
              42.461824
            ],
            [
              -73.775817,
              42.429938
            ],
            [
              -73.794327,
              42.375084
            ],
            [
              -73.782406,
              42.32497
            ],
            [
              -73.783326,
              42.288356
            ],
            [
              -73.802996,
              42.253655
            ],
            [
              -73.835206,
              42.24208
            ],
            [
              -73.847813,
              42.230347
            ],
            [
              -73.855255,
              42.191415
            ],
            [
              -73.892319,
              42.167534
            ],
            [
              -73.910675,
              42.127293
            ],
            [
              -73.921465,
              42.110025
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "019",
      "COUNTYNS": "00974108",
      "AFFGEOID": "0500000US36019",
      "GEOID": "36019",
      "NAME": "Clinton",
      "LSAD": "06",
      "ALAND": 2687771451,
      "AWATER": 206611024,
      "County_state": "Clinton,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -74.0273918752465,
              44.9957654989181
            ],
            [
              -74.009446,
              44.903518
            ],
            [
              -74.005957,
              44.872184
            ],
            [
              -74.006692,
              44.836938
            ],
            [
              -73.986382,
              44.707773
            ],
            [
              -73.966148,
              44.709118
            ],
            [
              -73.9459,
              44.574869
            ],
            [
              -73.932927,
              44.576197
            ],
            [
              -73.909687,
              44.429699
            ],
            [
              -73.700717,
              44.445571
            ],
            [
              -73.669281,
              44.441355
            ],
            [
              -73.629049,
              44.458391
            ],
            [
              -73.615593,
              44.454346
            ],
            [
              -73.571307,
              44.465332
            ],
            [
              -73.55497,
              44.482549
            ],
            [
              -73.526907,
              44.488028
            ],
            [
              -73.496604,
              44.486081
            ],
            [
              -73.48832,
              44.49961
            ],
            [
              -73.46019,
              44.519793
            ],
            [
              -73.463838,
              44.537681
            ],
            [
              -73.33863044507319,
              44.5468424276295
            ],
            [
              -73.33863,
              44.546844
            ],
            [
              -73.338751,
              44.548046
            ],
            [
              -73.342932,
              44.551907
            ],
            [
              -73.350027,
              44.555392
            ],
            [
              -73.355186,
              44.556918
            ],
            [
              -73.356788,
              44.557918
            ],
            [
              -73.360088,
              44.562546
            ],
            [
              -73.3614860172538,
              44.563518406880696
            ],
            [
              -73.367275,
              44.567545
            ],
            [
              -73.374389,
              44.575455
            ],
            [
              -73.375666,
              44.582038
            ],
            [
              -73.377794,
              44.585128
            ],
            [
              -73.381848,
              44.589316
            ],
            [
              -73.38164,
              44.590583
            ],
            [
              -73.377897,
              44.593848
            ],
            [
              -73.376806,
              44.595455
            ],
            [
              -73.376332,
              44.597218
            ],
            [
              -73.376849,
              44.599598
            ],
            [
              -73.380726,
              44.605239
            ],
            [
              -73.382932,
              44.612184
            ],
            [
              -73.38982,
              44.61721
            ],
            [
              -73.390231,
              44.618353
            ],
            [
              -73.389966,
              44.61962
            ],
            [
              -73.387346,
              44.623672
            ],
            [
              -73.386497,
              44.626924
            ],
            [
              -73.385899,
              44.631044
            ],
            [
              -73.387169,
              44.635542
            ],
            [
              -73.386783,
              44.636369
            ],
            [
              -73.379748,
              44.64036
            ],
            [
              -73.378561,
              44.641475
            ],
            [
              -73.383157,
              44.645764
            ],
            [
              -73.377973,
              44.652918
            ],
            [
              -73.378014,
              44.653846
            ],
            [
              -73.378968,
              44.65518
            ],
            [
              -73.379074,
              44.656772
            ],
            [
              -73.374134,
              44.66234
            ],
            [
              -73.373063,
              44.662713
            ],
            [
              -73.37059,
              44.662518
            ],
            [
              -73.369669,
              44.663478
            ],
            [
              -73.370065,
              44.666071
            ],
            [
              -73.37272,
              44.668739
            ],
            [
              -73.371843,
              44.676956
            ],
            [
              -73.371089,
              44.67753
            ],
            [
              -73.367209,
              44.678513
            ],
            [
              -73.367414,
              44.681292
            ],
            [
              -73.369685,
              44.683758
            ],
            [
              -73.370142,
              44.684853
            ],
            [
              -73.365297,
              44.687546
            ],
            [
              -73.361308,
              44.694523
            ],
            [
              -73.361323,
              44.695369
            ],
            [
              -73.36556,
              44.700297
            ],
            [
              -73.365068,
              44.725646
            ],
            [
              -73.365561,
              44.741786
            ],
            [
              -73.363791,
              44.745254
            ],
            [
              -73.357671,
              44.751018
            ],
            [
              -73.354361,
              44.755296
            ],
            [
              -73.348694,
              44.768246
            ],
            [
              -73.347072,
              44.772988
            ],
            [
              -73.344254,
              44.776282
            ],
            [
              -73.335713,
              44.782086
            ],
            [
              -73.333771,
              44.785192
            ],
            [
              -73.333154,
              44.788759
            ],
            [
              -73.333933,
              44.7992
            ],
            [
              -73.33443,
              44.802188
            ],
            [
              -73.335443,
              44.804602
            ],
            [
              -73.3502,
              44.816394
            ],
            [
              -73.353472,
              44.820386
            ],
            [
              -73.354945,
              44.8215
            ],
            [
              -73.35808,
              44.82331
            ],
            [
              -73.365678,
              44.826451
            ],
            [
              -73.369647,
              44.829136
            ],
            [
              -73.371329,
              44.830742
            ],
            [
              -73.375345,
              44.836307
            ],
            [
              -73.378717,
              44.837358
            ],
            [
              -73.379452,
              44.83801
            ],
            [
              -73.381359,
              44.845021
            ],
            [
              -73.381397,
              44.848805
            ],
            [
              -73.379822,
              44.857037
            ],
            [
              -73.375709,
              44.860745
            ],
            [
              -73.371967,
              44.862414
            ],
            [
              -73.369103,
              44.86668
            ],
            [
              -73.366459,
              44.87504
            ],
            [
              -73.362229,
              44.891463
            ],
            [
              -73.360327,
              44.897236
            ],
            [
              -73.35808,
              44.901325
            ],
            [
              -73.356218,
              44.904492
            ],
            [
              -73.353657,
              44.907346
            ],
            [
              -73.347837,
              44.911309
            ],
            [
              -73.3427866086322,
              44.913802307163195
            ],
            [
              -73.341106,
              44.914632
            ],
            [
              -73.338979,
              44.917681
            ],
            [
              -73.338482,
              44.924112
            ],
            [
              -73.339603,
              44.94337
            ],
            [
              -73.337906,
              44.960541
            ],
            [
              -73.338243,
              44.96475
            ],
            [
              -73.338734,
              44.965886
            ],
            [
              -73.34474,
              44.970468
            ],
            [
              -73.350218,
              44.976222
            ],
            [
              -73.352886,
              44.980644
            ],
            [
              -73.354112,
              44.984062
            ],
            [
              -73.354633,
              44.987352
            ],
            [
              -73.353429,
              44.990165
            ],
            [
              -73.350188,
              44.994304
            ],
            [
              -73.343124,
              45.01084
            ],
            [
              -73.437371,
              45.009198
            ],
            [
              -73.639718,
              45.003464
            ],
            [
              -73.675458,
              45.002907
            ],
            [
              -73.762985,
              45.003238
            ],
            [
              -73.874597,
              45.001223
            ],
            [
              -74.0273918752465,
              44.9957654989181
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "047",
      "COUNTYNS": "00974122",
      "AFFGEOID": "0500000US36047",
      "GEOID": "36047",
      "NAME": "Kings",
      "LSAD": "06",
      "ALAND": 179672197,
      "AWATER": 71097153,
      "County_state": "Kings,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -74.0410381415638,
              40.6289682382479
            ],
            [
              -74.042412,
              40.624847
            ],
            [
              -74.03959,
              40.612934
            ],
            [
              -74.0365707614339,
              40.609117133663005
            ],
            [
              -74.0361942925187,
              40.6086412085256
            ],
            [
              -74.032856,
              40.604421
            ],
            [
              -74.0095265531234,
              40.597584269109696
            ],
            [
              -74.00869607059511,
              40.5973408949061
            ],
            [
              -74.003281,
              40.595754
            ],
            [
              -74.000486,
              40.584085
            ],
            [
              -74.0021335535718,
              40.58272008412919
            ],
            [
              -74.012022,
              40.574528
            ],
            [
              -73.991346,
              40.57035
            ],
            [
              -73.9594132697819,
              40.5733474155248
            ],
            [
              -73.9523548431828,
              40.57400996574601
            ],
            [
              -73.9417677123018,
              40.5750037432981
            ],
            [
              -73.93814037516348,
              40.5753442289558
            ],
            [
              -73.931559,
              40.575962
            ],
            [
              -73.9333949765105,
              40.5692405208928
            ],
            [
              -73.885069,
              40.573749
            ],
            [
              -73.849852,
              40.588669
            ],
            [
              -73.834463,
              40.607193
            ],
            [
              -73.833041,
              40.628261
            ],
            [
              -73.848338,
              40.643521
            ],
            [
              -73.855021,
              40.643098
            ],
            [
              -73.857633,
              40.671656
            ],
            [
              -73.861318,
              40.675021
            ],
            [
              -73.861712,
              40.676611
            ],
            [
              -73.863349,
              40.679352
            ],
            [
              -73.863787,
              40.681114
            ],
            [
              -73.866027,
              40.681918
            ],
            [
              -73.868717,
              40.694622
            ],
            [
              -73.879616,
              40.691106
            ],
            [
              -73.896466,
              40.682336
            ],
            [
              -73.908774,
              40.698255
            ],
            [
              -73.914031,
              40.704749
            ],
            [
              -73.922711,
              40.716478
            ],
            [
              -73.923127,
              40.717024
            ],
            [
              -73.927801,
              40.726494
            ],
            [
              -73.931825,
              40.728482
            ],
            [
              -73.953907,
              40.739421
            ],
            [
              -73.962478,
              40.736802
            ],
            [
              -73.962148,
              40.732452
            ],
            [
              -73.962645,
              40.722747
            ],
            [
              -73.965572,
              40.71895
            ],
            [
              -73.966511,
              40.717917
            ],
            [
              -73.972789,
              40.709378
            ],
            [
              -73.979233,
              40.705769
            ],
            [
              -73.994498,
              40.704318
            ],
            [
              -74.00096,
              40.690132
            ],
            [
              -74.008425,
              40.686016
            ],
            [
              -74.015275,
              40.682142
            ],
            [
              -74.0220537924917,
              40.6804990456477
            ],
            [
              -74.020467,
              40.67877
            ],
            [
              -74.018272,
              40.659019
            ],
            [
              -74.02620732724439,
              40.6518050661414
            ],
            [
              -74.0272708959275,
              40.6508381855204
            ],
            [
              -74.02938578280529,
              40.64891556108609
            ],
            [
              -74.032066,
              40.646479
            ],
            [
              -74.033516831553,
              40.6443027526706
            ],
            [
              -74.03376784615389,
              40.6439262307692
            ],
            [
              -74.034266307692,
              40.6431785384622
            ],
            [
              -74.03442953846161,
              40.6429336923077
            ],
            [
              -74.0349110769231,
              40.64221138461539
            ],
            [
              -74.0354890769231,
              40.641344384615394
            ],
            [
              -74.03585910791371,
              40.6407893381295
            ],
            [
              -74.038336,
              40.637074
            ],
            [
              -74.0410381415638,
              40.6289682382479
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "009",
      "COUNTYNS": "00974103",
      "AFFGEOID": "0500000US36009",
      "GEOID": "36009",
      "NAME": "Cattaraugus",
      "LSAD": "06",
      "ALAND": 3388633499,
      "AWATER": 36608250,
      "County_state": "Cattaraugus,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.060777,
              42.537853
            ],
            [
              -79.061265,
              41.999259
            ],
            [
              -78.983065,
              41.998949
            ],
            [
              -78.9188549239275,
              41.998124927411794
            ],
            [
              -78.874759,
              41.997559
            ],
            [
              -78.749754,
              41.998109
            ],
            [
              -78.59665,
              41.999877
            ],
            [
              -78.308128,
              41.999415
            ],
            [
              -78.308839,
              42.521217
            ],
            [
              -78.464556,
              42.519166
            ],
            [
              -78.46394,
              42.536332
            ],
            [
              -78.527623,
              42.521985
            ],
            [
              -78.56315,
              42.509046
            ],
            [
              -78.597718,
              42.512484
            ],
            [
              -78.612708,
              42.502797
            ],
            [
              -78.638678,
              42.498058
            ],
            [
              -78.645652,
              42.490853
            ],
            [
              -78.6624,
              42.492437
            ],
            [
              -78.695937,
              42.47194
            ],
            [
              -78.700702,
              42.48535
            ],
            [
              -78.737837,
              42.480564
            ],
            [
              -78.739443,
              42.472798
            ],
            [
              -78.763624,
              42.470547
            ],
            [
              -78.780825,
              42.455155
            ],
            [
              -78.794634,
              42.462655
            ],
            [
              -78.836455,
              42.443696
            ],
            [
              -78.877711,
              42.441729
            ],
            [
              -78.884208,
              42.438046
            ],
            [
              -78.900455,
              42.449115
            ],
            [
              -78.919766,
              42.442386
            ],
            [
              -78.919255,
              42.454048
            ],
            [
              -78.940837,
              42.468366
            ],
            [
              -78.95703,
              42.472362
            ],
            [
              -78.966565,
              42.493699
            ],
            [
              -78.986235,
              42.505284
            ],
            [
              -78.988305,
              42.521684
            ],
            [
              -79.033098,
              42.54328
            ],
            [
              -79.060777,
              42.537853
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "013",
      "COUNTYNS": "00974105",
      "AFFGEOID": "0500000US36013",
      "GEOID": "36013",
      "NAME": "Chautauqua",
      "LSAD": "06",
      "ALAND": 2745967850,
      "AWATER": 1139487496,
      "County_state": "Chautauqua,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.761964,
              42.251354
            ],
            [
              -79.762152,
              42.243054
            ],
            [
              -79.761833,
              42.183627
            ],
            [
              -79.761929,
              42.179693
            ],
            [
              -79.761921,
              42.173319
            ],
            [
              -79.761759,
              42.162675
            ],
            [
              -79.761861,
              42.150712
            ],
            [
              -79.762122,
              42.131246
            ],
            [
              -79.761709,
              42.11899
            ],
            [
              -79.761798,
              42.019042
            ],
            [
              -79.76162,
              42.00006
            ],
            [
              -79.761313,
              41.998808
            ],
            [
              -79.670128,
              41.999335
            ],
            [
              -79.625287,
              41.999003
            ],
            [
              -79.625301,
              41.999068
            ],
            [
              -79.6108388547624,
              41.9989893460904
            ],
            [
              -79.551385,
              41.998666
            ],
            [
              -79.538445,
              41.998527
            ],
            [
              -79.5109917186785,
              41.9984138129004
            ],
            [
              -79.472472,
              41.998255
            ],
            [
              -79.249772,
              41.998807
            ],
            [
              -79.17857,
              41.999458
            ],
            [
              -79.061265,
              41.999259
            ],
            [
              -79.060777,
              42.537853
            ],
            [
              -79.091042,
              42.55159
            ],
            [
              -79.107778,
              42.569965
            ],
            [
              -79.1367251589718,
              42.5696934012929
            ],
            [
              -79.138569,
              42.564462
            ],
            [
              -79.148723,
              42.553672
            ],
            [
              -79.193232,
              42.545881
            ],
            [
              -79.223195,
              42.536087
            ],
            [
              -79.242889,
              42.531757
            ],
            [
              -79.264624,
              42.523159
            ],
            [
              -79.283364,
              42.511228
            ],
            [
              -79.31774,
              42.499884
            ],
            [
              -79.323079,
              42.494795
            ],
            [
              -79.331483,
              42.489076
            ],
            [
              -79.335129,
              42.488321
            ],
            [
              -79.342316,
              42.489664
            ],
            [
              -79.351989,
              42.48892
            ],
            [
              -79.36213,
              42.480195
            ],
            [
              -79.381943,
              42.466491
            ],
            [
              -79.405458,
              42.453281
            ],
            [
              -79.429119,
              42.42838
            ],
            [
              -79.453533,
              42.411157
            ],
            [
              -79.474794,
              42.404291
            ],
            [
              -79.510999,
              42.382373
            ],
            [
              -79.546262,
              42.363417
            ],
            [
              -79.593992,
              42.341641
            ],
            [
              -79.645358,
              42.315631
            ],
            [
              -79.717825,
              42.284711
            ],
            [
              -79.761951,
              42.26986
            ],
            [
              -79.761964,
              42.251354
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "081",
      "COUNTYNS": "00974139",
      "AFFGEOID": "0500000US36081",
      "GEOID": "36081",
      "NAME": "Queens",
      "LSAD": "06",
      "ALAND": 281697156,
      "AWATER": 179401845,
      "County_state": "Queens,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -73.961381,
              40.742802
            ],
            [
              -73.962478,
              40.736802
            ],
            [
              -73.953907,
              40.739421
            ],
            [
              -73.931825,
              40.728482
            ],
            [
              -73.927801,
              40.726494
            ],
            [
              -73.923127,
              40.717024
            ],
            [
              -73.922711,
              40.716478
            ],
            [
              -73.914031,
              40.704749
            ],
            [
              -73.908774,
              40.698255
            ],
            [
              -73.896466,
              40.682336
            ],
            [
              -73.879616,
              40.691106
            ],
            [
              -73.868717,
              40.694622
            ],
            [
              -73.866027,
              40.681918
            ],
            [
              -73.863787,
              40.681114
            ],
            [
              -73.863349,
              40.679352
            ],
            [
              -73.861712,
              40.676611
            ],
            [
              -73.861318,
              40.675021
            ],
            [
              -73.857633,
              40.671656
            ],
            [
              -73.855021,
              40.643098
            ],
            [
              -73.848338,
              40.643521
            ],
            [
              -73.833041,
              40.628261
            ],
            [
              -73.834463,
              40.607193
            ],
            [
              -73.849852,
              40.588669
            ],
            [
              -73.885069,
              40.573749
            ],
            [
              -73.9333949765105,
              40.5692405208928
            ],
            [
              -73.934060795753,
              40.566802967851096
            ],
            [
              -73.940591,
              40.542896
            ],
            [
              -73.905141,
              40.556401
            ],
            [
              -73.834408,
              40.577201
            ],
            [
              -73.774928,
              40.590759
            ],
            [
              -73.756868,
              40.591841
            ],
            [
              -73.7503555869235,
              40.5895220262279
            ],
            [
              -73.737185,
              40.592965
            ],
            [
              -73.745633,
              40.611756
            ],
            [
              -73.754732,
              40.610405
            ],
            [
              -73.766719,
              40.615004
            ],
            [
              -73.768761,
              40.624352
            ],
            [
              -73.767425,
              40.626606
            ],
            [
              -73.748001,
              40.634631
            ],
            [
              -73.743295,
              40.638048
            ],
            [
              -73.73996,
              40.635144
            ],
            [
              -73.736735,
              40.6491
            ],
            [
              -73.732208,
              40.650091
            ],
            [
              -73.724874,
              40.653447
            ],
            [
              -73.728107,
              40.665345
            ],
            [
              -73.725861,
              40.683223
            ],
            [
              -73.726971,
              40.710715
            ],
            [
              -73.729176,
              40.719167
            ],
            [
              -73.730326,
              40.722157
            ],
            [
              -73.724722,
              40.724314
            ],
            [
              -73.707647,
              40.727796
            ],
            [
              -73.70002,
              40.73939
            ],
            [
              -73.700582,
              40.743184
            ],
            [
              -73.701633,
              40.752493
            ],
            [
              -73.750295,
              40.782478
            ],
            [
              -73.7691495733027,
              40.8001908918927
            ],
            [
              -73.776032,
              40.795275
            ],
            [
              -73.781369,
              40.794907
            ],
            [
              -73.7864227032759,
              40.801457217426396
            ],
            [
              -73.793559,
              40.80038
            ],
            [
              -73.811299,
              40.805397
            ],
            [
              -73.827583,
              40.803936
            ],
            [
              -73.846654,
              40.801024
            ],
            [
              -73.862965,
              40.79767
            ],
            [
              -73.870973,
              40.789057
            ],
            [
              -73.871631,
              40.786798
            ],
            [
              -73.885303,
              40.786685
            ],
            [
              -73.892182,
              40.790394
            ],
            [
              -73.912456,
              40.796096
            ],
            [
              -73.917171,
              40.784193
            ],
            [
              -73.920999,
              40.781749
            ],
            [
              -73.928277,
              40.776899
            ],
            [
              -73.934412,
              40.778093
            ],
            [
              -73.935323,
              40.770485
            ],
            [
              -73.960211,
              40.744511
            ],
            [
              -73.961381,
              40.742802
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "083",
      "COUNTYNS": "00974140",
      "AFFGEOID": "0500000US36083",
      "GEOID": "36083",
      "NAME": "Rensselaer",
      "LSAD": "06",
      "ALAND": 1689815931,
      "AWATER": 33569457,
      "County_state": "Rensselaer,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -73.785548,
              42.487209
            ],
            [
              -73.783721,
              42.464231
            ],
            [
              -73.71799,
              42.470751
            ],
            [
              -73.3525249549442,
              42.5100075099506
            ],
            [
              -73.264957,
              42.74594
            ],
            [
              -73.276421,
              42.746019
            ],
            [
              -73.290944,
              42.80192
            ],
            [
              -73.286337,
              42.808038
            ],
            [
              -73.28375,
              42.813864
            ],
            [
              -73.287063,
              42.82014
            ],
            [
              -73.285388,
              42.834093
            ],
            [
              -73.284311,
              42.834954
            ],
            [
              -73.278673,
              42.83341
            ],
            [
              -73.2784862199926,
              42.837566099299195
            ],
            [
              -73.275804,
              42.897249
            ],
            [
              -73.27464979158489,
              42.934439009859396
            ],
            [
              -73.274466,
              42.940361
            ],
            [
              -73.274393,
              42.942482
            ],
            [
              -73.274294,
              42.943652
            ],
            [
              -73.380235,
              42.943739
            ],
            [
              -73.392102,
              42.952586
            ],
            [
              -73.43316,
              42.958241
            ],
            [
              -73.450593,
              42.944581
            ],
            [
              -73.517323,
              42.943356
            ],
            [
              -73.624561,
              42.941222
            ],
            [
              -73.635463,
              42.94129
            ],
            [
              -73.650654,
              42.938278
            ],
            [
              -73.660433,
              42.932534
            ],
            [
              -73.678874,
              42.912293
            ],
            [
              -73.683654,
              42.901314
            ],
            [
              -73.684535,
              42.892837
            ],
            [
              -73.673202,
              42.875077
            ],
            [
              -73.675904,
              42.858575
            ],
            [
              -73.659663,
              42.818978
            ],
            [
              -73.661362,
              42.802977
            ],
            [
              -73.672355,
              42.795791
            ],
            [
              -73.676762,
              42.783277
            ],
            [
              -73.683762,
              42.766677
            ],
            [
              -73.68483,
              42.75701
            ],
            [
              -73.703295,
              42.710273
            ],
            [
              -73.703463,
              42.705578
            ],
            [
              -73.706463,
              42.694078
            ],
            [
              -73.723263,
              42.672879
            ],
            [
              -73.724964,
              42.670179
            ],
            [
              -73.741764,
              42.654379
            ],
            [
              -73.759565,
              42.614179
            ],
            [
              -73.759739,
              42.61379
            ],
            [
              -73.752165,
              42.56658
            ],
            [
              -73.76377,
              42.521135
            ],
            [
              -73.773161,
              42.509377
            ],
            [
              -73.785548,
              42.487209
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "085",
      "COUNTYNS": "00974141",
      "AFFGEOID": "0500000US36085",
      "GEOID": "36085",
      "NAME": "Richmond",
      "LSAD": "06",
      "ALAND": 148972687,
      "AWATER": 115952653,
      "County_state": "Richmond,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -74.258556,
              40.506232
            ],
            [
              -74.260611,
              40.502436
            ],
            [
              -74.252838,
              40.498088
            ],
            [
              -74.246688,
              40.496103
            ],
            [
              -74.23324,
              40.501299
            ],
            [
              -74.219787,
              40.502603
            ],
            [
              -74.210474,
              40.509448
            ],
            [
              -74.199923,
              40.511729
            ],
            [
              -74.182157,
              40.520634
            ],
            [
              -74.170381,
              40.522795
            ],
            [
              -74.155333,
              40.530289
            ],
            [
              -74.14469174808059,
              40.5350421901054
            ],
            [
              -74.144428,
              40.53516
            ],
            [
              -74.137241,
              40.530076
            ],
            [
              -74.127289,
              40.53814
            ],
            [
              -74.112585,
              40.547603
            ],
            [
              -74.085912,
              40.570274
            ],
            [
              -74.062312,
              40.590235
            ],
            [
              -74.0596877161933,
              40.594075018201096
            ],
            [
              -74.053125,
              40.603678
            ],
            [
              -74.062742,
              40.615921
            ],
            [
              -74.063144532299,
              40.616579508357304
            ],
            [
              -74.067598,
              40.623865
            ],
            [
              -74.0697,
              40.641216
            ],
            [
              -74.075884,
              40.648101
            ],
            [
              -74.070198,
              40.660827
            ],
            [
              -74.081331,
              40.65929
            ],
            [
              -74.086806,
              40.651596
            ],
            [
              -74.100247,
              40.648148
            ],
            [
              -74.112343,
              40.647446
            ],
            [
              -74.128151,
              40.643902
            ],
            [
              -74.143255,
              40.642149
            ],
            [
              -74.1614075238905,
              40.6442325986627
            ],
            [
              -74.1615257647334,
              40.64424617068519
            ],
            [
              -74.170611,
              40.645289
            ],
            [
              -74.18139,
              40.646475
            ],
            [
              -74.187754,
              40.644631
            ],
            [
              -74.1971585770214,
              40.6357228351377
            ],
            [
              -74.2004476063965,
              40.6326074142268
            ],
            [
              -74.202247,
              40.630903
            ],
            [
              -74.203485,
              40.625058
            ],
            [
              -74.202434,
              40.61655
            ],
            [
              -74.203759,
              40.606604
            ],
            [
              -74.199421,
              40.59988
            ],
            [
              -74.203688,
              40.592691
            ],
            [
              -74.207519,
              40.582741
            ],
            [
              -74.211481,
              40.570062
            ],
            [
              -74.2168256483963,
              40.558646517311
            ],
            [
              -74.216839,
              40.558618
            ],
            [
              -74.2274,
              40.558034
            ],
            [
              -74.239211,
              40.553764
            ],
            [
              -74.249211,
              40.545064
            ],
            [
              -74.248787,
              40.533033
            ],
            [
              -74.246408,
              40.524357
            ],
            [
              -74.25166,
              40.517448
            ],
            [
              -74.258556,
              40.506232
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "111",
      "COUNTYNS": "00974153",
      "AFFGEOID": "0500000US36111",
      "GEOID": "36111",
      "NAME": "Ulster",
      "LSAD": "06",
      "ALAND": 2911757797,
      "AWATER": 94596954,
      "County_state": "Ulster,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -74.780693,
              42.016375
            ],
            [
              -74.67402,
              41.971675
            ],
            [
              -74.655032,
              41.963676
            ],
            [
              -74.453685,
              41.875595
            ],
            [
              -74.486924,
              41.835264
            ],
            [
              -74.525506,
              41.796817
            ],
            [
              -74.575086,
              41.745258
            ],
            [
              -74.394771,
              41.644123
            ],
            [
              -74.376138,
              41.598189
            ],
            [
              -74.364486,
              41.593783
            ],
            [
              -74.367055,
              41.590977
            ],
            [
              -74.34068,
              41.594376
            ],
            [
              -74.304711,
              41.615251
            ],
            [
              -74.264292,
              41.632629
            ],
            [
              -74.250186,
              41.629324
            ],
            [
              -74.251601,
              41.605374
            ],
            [
              -74.187505,
              41.590793
            ],
            [
              -74.126393,
              41.582544
            ],
            [
              -74.134511,
              41.615695
            ],
            [
              -74.098653,
              41.607259
            ],
            [
              -74.089896,
              41.598602
            ],
            [
              -74.068083,
              41.606001
            ],
            [
              -74.053685,
              41.58061
            ],
            [
              -74.037125,
              41.582044
            ],
            [
              -73.953307,
              41.589977
            ],
            [
              -73.947033,
              41.668826
            ],
            [
              -73.942482,
              41.684093
            ],
            [
              -73.943482,
              41.690293
            ],
            [
              -73.945782,
              41.695593
            ],
            [
              -73.946682,
              41.699396
            ],
            [
              -73.944435,
              41.714634
            ],
            [
              -73.941081,
              41.732693
            ],
            [
              -73.941081,
              41.735993
            ],
            [
              -73.940989,
              41.755741
            ],
            [
              -73.946589,
              41.763677
            ],
            [
              -73.951614,
              41.770621
            ],
            [
              -73.948477,
              41.8507
            ],
            [
              -73.940317,
              41.872748
            ],
            [
              -73.964189,
              41.910156
            ],
            [
              -73.936796,
              42.006651
            ],
            [
              -73.932282,
              42.061578
            ],
            [
              -73.926186,
              42.07193
            ],
            [
              -73.929626,
              42.078778
            ],
            [
              -73.921465,
              42.110025
            ],
            [
              -73.910675,
              42.127293
            ],
            [
              -73.918356,
              42.128916
            ],
            [
              -74.00245,
              42.176992
            ],
            [
              -74.003589,
              42.163365
            ],
            [
              -74.042393,
              42.170386
            ],
            [
              -74.074797,
              42.096589
            ],
            [
              -74.271606,
              42.111625
            ],
            [
              -74.307571,
              42.114346
            ],
            [
              -74.451713,
              42.169225
            ],
            [
              -74.503984,
              42.14492
            ],
            [
              -74.530985,
              42.146475
            ],
            [
              -74.548265,
              42.127406
            ],
            [
              -74.553598,
              42.121484
            ],
            [
              -74.669689,
              42.067819
            ],
            [
              -74.780693,
              42.016375
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "005",
      "COUNTYNS": "00974101",
      "AFFGEOID": "0500000US36005",
      "GEOID": "36005",
      "NAME": "Bronx",
      "LSAD": "06",
      "ALAND": 108910661,
      "AWATER": 39682657,
      "County_state": "Bronx,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -73.933406,
              40.882078
            ],
            [
              -73.9334079229381,
              40.882074964842694
            ],
            [
              -73.9152,
              40.875581
            ],
            [
              -73.91031,
              40.879047
            ],
            [
              -73.908554,
              40.877712
            ],
            [
              -73.907,
              40.873455
            ],
            [
              -73.910851,
              40.866528
            ],
            [
              -73.91929,
              40.857475
            ],
            [
              -73.921215,
              40.854693
            ],
            [
              -73.927296,
              40.846557
            ],
            [
              -73.933006,
              40.835679
            ],
            [
              -73.933059,
              40.828156
            ],
            [
              -73.932327,
              40.819503
            ],
            [
              -73.932605,
              40.811517
            ],
            [
              -73.931801,
              40.807876
            ],
            [
              -73.928225,
              40.80385
            ],
            [
              -73.913624,
              40.796786
            ],
            [
              -73.912456,
              40.796096
            ],
            [
              -73.892182,
              40.790394
            ],
            [
              -73.885303,
              40.786685
            ],
            [
              -73.871631,
              40.786798
            ],
            [
              -73.870973,
              40.789057
            ],
            [
              -73.862965,
              40.79767
            ],
            [
              -73.846654,
              40.801024
            ],
            [
              -73.827583,
              40.803936
            ],
            [
              -73.811299,
              40.805397
            ],
            [
              -73.793559,
              40.80038
            ],
            [
              -73.7864227032759,
              40.801457217426396
            ],
            [
              -73.797332,
              40.815597
            ],
            [
              -73.804518,
              40.818546
            ],
            [
              -73.811889,
              40.825363
            ],
            [
              -73.815205,
              40.831075
            ],
            [
              -73.815574,
              40.835129
            ],
            [
              -73.81281,
              40.846737
            ],
            [
              -73.806914,
              40.849501
            ],
            [
              -73.799543,
              40.848027
            ],
            [
              -73.797252,
              40.852196
            ],
            [
              -73.793785,
              40.855583
            ],
            [
              -73.792253,
              40.855825
            ],
            [
              -73.789512,
              40.85139
            ],
            [
              -73.791044,
              40.846552
            ],
            [
              -73.788221,
              40.842036
            ],
            [
              -73.785399,
              40.838004
            ],
            [
              -73.783867,
              40.836795
            ],
            [
              -73.782577,
              40.837601
            ],
            [
              -73.781206,
              40.838891
            ],
            [
              -73.78031,
              40.84456
            ],
            [
              -73.775485,
              40.846453
            ],
            [
              -73.769648,
              40.84466
            ],
            [
              -73.766032,
              40.844961
            ],
            [
              -73.765128,
              40.854228
            ],
            [
              -73.766333,
              40.857317
            ],
            [
              -73.770552,
              40.86033
            ],
            [
              -73.767161767951,
              40.8767967281162
            ],
            [
              -73.783702,
              40.881078
            ],
            [
              -73.823244,
              40.891199
            ],
            [
              -73.824047,
              40.889866
            ],
            [
              -73.827224,
              40.890785
            ],
            [
              -73.838407,
              40.894061
            ],
            [
              -73.847071,
              40.906173
            ],
            [
              -73.852678,
              40.909899
            ],
            [
              -73.860006,
              40.900565
            ],
            [
              -73.878189,
              40.905983
            ],
            [
              -73.917905,
              40.917577
            ],
            [
              -73.918405,
              40.917477
            ],
            [
              -73.9196864010756,
              40.9135352131528
            ],
            [
              -73.919705,
              40.913478
            ],
            [
              -73.929006,
              40.889578
            ],
            [
              -73.933406,
              40.882078
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "091",
      "COUNTYNS": "00974143",
      "AFFGEOID": "0500000US36091",
      "GEOID": "36091",
      "NAME": "Saratoga",
      "LSAD": "06",
      "ALAND": 2097880370,
      "AWATER": 87571838,
      "County_state": "Saratoga,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -74.1601,
              43.371532
            ],
            [
              -74.140147,
              43.253979
            ],
            [
              -74.124335,
              43.1539
            ],
            [
              -74.097467,
              42.982934
            ],
            [
              -74.096897,
              42.979378
            ],
            [
              -74.09298,
              42.955868
            ],
            [
              -74.055458,
              42.953276
            ],
            [
              -74.005656,
              42.933484
            ],
            [
              -73.955458,
              42.897306
            ],
            [
              -73.904386,
              42.911163
            ],
            [
              -73.895938,
              42.851076
            ],
            [
              -73.881769,
              42.850876
            ],
            [
              -73.875864,
              42.834274
            ],
            [
              -73.822166,
              42.780278
            ],
            [
              -73.809369,
              42.778869
            ],
            [
              -73.779964,
              42.792177
            ],
            [
              -73.768764,
              42.785877
            ],
            [
              -73.736963,
              42.819477
            ],
            [
              -73.722663,
              42.820677
            ],
            [
              -73.719863,
              42.801277
            ],
            [
              -73.710663,
              42.794077
            ],
            [
              -73.690262,
              42.775477
            ],
            [
              -73.676762,
              42.783277
            ],
            [
              -73.672355,
              42.795791
            ],
            [
              -73.661362,
              42.802977
            ],
            [
              -73.659663,
              42.818978
            ],
            [
              -73.675904,
              42.858575
            ],
            [
              -73.673202,
              42.875077
            ],
            [
              -73.684535,
              42.892837
            ],
            [
              -73.683654,
              42.901314
            ],
            [
              -73.678874,
              42.912293
            ],
            [
              -73.660433,
              42.932534
            ],
            [
              -73.650654,
              42.938278
            ],
            [
              -73.635463,
              42.94129
            ],
            [
              -73.631467,
              42.969739
            ],
            [
              -73.610334,
              42.981343
            ],
            [
              -73.597113,
              43.007623
            ],
            [
              -73.585833,
              43.049728
            ],
            [
              -73.578155,
              43.055787
            ],
            [
              -73.575647,
              43.107558
            ],
            [
              -73.577229,
              43.118976
            ],
            [
              -73.590779,
              43.13424
            ],
            [
              -73.580214,
              43.152882
            ],
            [
              -73.589836,
              43.170472
            ],
            [
              -73.580906,
              43.183737
            ],
            [
              -73.585705,
              43.19312
            ],
            [
              -73.579279,
              43.208906
            ],
            [
              -73.597082,
              43.244441
            ],
            [
              -73.589054,
              43.263683
            ],
            [
              -73.600656,
              43.268501
            ],
            [
              -73.588822,
              43.300543
            ],
            [
              -73.59496,
              43.306118
            ],
            [
              -73.642761,
              43.304172
            ],
            [
              -73.650661,
              43.289572
            ],
            [
              -73.671273,
              43.293154
            ],
            [
              -73.675162,
              43.285072
            ],
            [
              -73.661061,
              43.272872
            ],
            [
              -73.68076,
              43.267371
            ],
            [
              -73.684962,
              43.280872
            ],
            [
              -73.705576,
              43.278938
            ],
            [
              -73.737483,
              43.267364
            ],
            [
              -73.743957,
              43.242717
            ],
            [
              -73.763515,
              43.22539
            ],
            [
              -73.769071,
              43.222338
            ],
            [
              -73.789731,
              43.244421
            ],
            [
              -73.803298,
              43.25032
            ],
            [
              -73.833459,
              43.249303
            ],
            [
              -73.835567,
              43.265835
            ],
            [
              -73.826077,
              43.276088
            ],
            [
              -73.825828,
              43.305348
            ],
            [
              -73.836918,
              43.306611
            ],
            [
              -73.858991,
              43.33032
            ],
            [
              -73.853286,
              43.33775
            ],
            [
              -73.875372,
              43.365122
            ],
            [
              -73.870977,
              43.372523
            ],
            [
              -73.883498,
              43.398045
            ],
            [
              -74.1601,
              43.371532
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "035",
      "COUNTYNS": "00974116",
      "AFFGEOID": "0500000US36035",
      "GEOID": "36035",
      "NAME": "Fulton",
      "LSAD": "06",
      "ALAND": 1283234678,
      "AWATER": 96916405,
      "County_state": "Fulton,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -74.773734,
              43.083375
            ],
            [
              -74.763614,
              43.057962
            ],
            [
              -74.759895,
              43.047423
            ],
            [
              -74.542367,
              42.98553
            ],
            [
              -74.488844,
              42.985118
            ],
            [
              -74.097467,
              42.982934
            ],
            [
              -74.124335,
              43.1539
            ],
            [
              -74.140147,
              43.253979
            ],
            [
              -74.224215,
              43.248165
            ],
            [
              -74.220902,
              43.221403
            ],
            [
              -74.322375,
              43.214684
            ],
            [
              -74.326378,
              43.241635
            ],
            [
              -74.534657,
              43.228115
            ],
            [
              -74.713005,
              43.289003
            ],
            [
              -74.712615,
              43.286143
            ],
            [
              -74.695985,
              43.174177
            ],
            [
              -74.718012,
              43.164735
            ],
            [
              -74.708975,
              43.149302
            ],
            [
              -74.733984,
              43.144229
            ],
            [
              -74.72825,
              43.125307
            ],
            [
              -74.758948,
              43.119562
            ],
            [
              -74.773734,
              43.083375
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "027",
      "COUNTYNS": "00974112",
      "AFFGEOID": "0500000US36027",
      "GEOID": "36027",
      "NAME": "Dutchess",
      "LSAD": "06",
      "ALAND": 2060678182,
      "AWATER": 76956282,
      "County_state": "Dutchess,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -73.99825,
              41.480625
            ],
            [
              -73.999584,
              41.455024
            ],
            [
              -73.981486,
              41.438905
            ],
            [
              -73.933775,
              41.488279
            ],
            [
              -73.692664,
              41.512246
            ],
            [
              -73.673285,
              41.514701
            ],
            [
              -73.647635,
              41.518293
            ],
            [
              -73.579783,
              41.526461
            ],
            [
              -73.530067,
              41.527194
            ],
            [
              -73.521041,
              41.619773
            ],
            [
              -73.520017,
              41.641197
            ],
            [
              -73.51823760355249,
              41.666733981689404
            ],
            [
              -73.516785,
              41.687581
            ],
            [
              -73.511921,
              41.740941
            ],
            [
              -73.510961,
              41.758749
            ],
            [
              -73.505008,
              41.823773
            ],
            [
              -73.504944,
              41.824285
            ],
            [
              -73.501984,
              41.858717
            ],
            [
              -73.498304,
              41.892508
            ],
            [
              -73.496527,
              41.92238
            ],
            [
              -73.492975,
              41.958524
            ],
            [
              -73.489615,
              42.000092
            ],
            [
              -73.487314,
              42.049638
            ],
            [
              -73.496879,
              42.049675
            ],
            [
              -73.521416,
              42.049966
            ],
            [
              -73.527072,
              41.97798
            ],
            [
              -73.71093,
              42.005488
            ],
            [
              -73.818533,
              42.046739
            ],
            [
              -73.907785,
              42.07961
            ],
            [
              -73.929626,
              42.078778
            ],
            [
              -73.926186,
              42.07193
            ],
            [
              -73.932282,
              42.061578
            ],
            [
              -73.936796,
              42.006651
            ],
            [
              -73.964189,
              41.910156
            ],
            [
              -73.940317,
              41.872748
            ],
            [
              -73.948477,
              41.8507
            ],
            [
              -73.951614,
              41.770621
            ],
            [
              -73.946589,
              41.763677
            ],
            [
              -73.940989,
              41.755741
            ],
            [
              -73.941081,
              41.735993
            ],
            [
              -73.941081,
              41.732693
            ],
            [
              -73.944435,
              41.714634
            ],
            [
              -73.946682,
              41.699396
            ],
            [
              -73.945782,
              41.695593
            ],
            [
              -73.943482,
              41.690293
            ],
            [
              -73.942482,
              41.684093
            ],
            [
              -73.947033,
              41.668826
            ],
            [
              -73.953307,
              41.589977
            ],
            [
              -73.954725,
              41.58179
            ],
            [
              -73.988036,
              41.542562
            ],
            [
              -73.994547,
              41.527138
            ],
            [
              -73.997232,
              41.519149
            ],
            [
              -73.997609,
              41.487212
            ],
            [
              -73.99825,
              41.480625
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "011",
      "COUNTYNS": "00974104",
      "AFFGEOID": "0500000US36011",
      "GEOID": "36011",
      "NAME": "Cayuga",
      "LSAD": "06",
      "ALAND": 1791190504,
      "AWATER": 445708175,
      "County_state": "Cayuga,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.74006,
              42.789419
            ],
            [
              -76.737519,
              42.753911
            ],
            [
              -76.728951,
              42.712898
            ],
            [
              -76.685311,
              42.670281
            ],
            [
              -76.666543,
              42.623457
            ],
            [
              -76.614291,
              42.622103
            ],
            [
              -76.458858,
              42.627416
            ],
            [
              -76.4584,
              42.618615
            ],
            [
              -76.265584,
              42.623588
            ],
            [
              -76.265611,
              42.625447
            ],
            [
              -76.274673,
              42.771257
            ],
            [
              -76.275348,
              42.78047
            ],
            [
              -76.305762,
              42.813338
            ],
            [
              -76.356974,
              42.84945
            ],
            [
              -76.450398,
              42.845727
            ],
            [
              -76.457428,
              42.929524
            ],
            [
              -76.462999,
              43.006316
            ],
            [
              -76.491941,
              43.004964
            ],
            [
              -76.497829,
              43.102657
            ],
            [
              -76.471873,
              43.107059
            ],
            [
              -76.486171,
              43.142208
            ],
            [
              -76.473066,
              43.146017
            ],
            [
              -76.479224,
              43.227519
            ],
            [
              -76.481148,
              43.256641
            ],
            [
              -76.600761,
              43.253742
            ],
            [
              -76.605279,
              43.257718
            ],
            [
              -76.6171093082771,
              43.419136705277595
            ],
            [
              -76.630774,
              43.413356
            ],
            [
              -76.642672,
              43.401241
            ],
            [
              -76.669624,
              43.366526
            ],
            [
              -76.684856,
              43.352691
            ],
            [
              -76.69836,
              43.344436
            ],
            [
              -76.7225013043099,
              43.3436861782835
            ],
            [
              -76.702324,
              43.080159
            ],
            [
              -76.717789,
              43.062634
            ],
            [
              -76.718614,
              43.033116
            ],
            [
              -76.713806,
              43.024035
            ],
            [
              -76.739361,
              42.954473
            ],
            [
              -76.733468,
              42.894246
            ],
            [
              -76.721246,
              42.869579
            ],
            [
              -76.717185,
              42.83883
            ],
            [
              -76.74006,
              42.789419
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "029",
      "COUNTYNS": "00974113",
      "AFFGEOID": "0500000US36029",
      "GEOID": "36029",
      "NAME": "Erie",
      "LSAD": "06",
      "ALAND": 2700590735,
      "AWATER": 476948860,
      "County_state": "Erie,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.12963,
              42.589824
            ],
            [
              -79.1367251589718,
              42.5696934012929
            ],
            [
              -79.107778,
              42.569965
            ],
            [
              -79.091042,
              42.55159
            ],
            [
              -79.060777,
              42.537853
            ],
            [
              -79.033098,
              42.54328
            ],
            [
              -78.988305,
              42.521684
            ],
            [
              -78.986235,
              42.505284
            ],
            [
              -78.966565,
              42.493699
            ],
            [
              -78.95703,
              42.472362
            ],
            [
              -78.940837,
              42.468366
            ],
            [
              -78.919255,
              42.454048
            ],
            [
              -78.919766,
              42.442386
            ],
            [
              -78.900455,
              42.449115
            ],
            [
              -78.884208,
              42.438046
            ],
            [
              -78.877711,
              42.441729
            ],
            [
              -78.836455,
              42.443696
            ],
            [
              -78.794634,
              42.462655
            ],
            [
              -78.780825,
              42.455155
            ],
            [
              -78.763624,
              42.470547
            ],
            [
              -78.739443,
              42.472798
            ],
            [
              -78.737837,
              42.480564
            ],
            [
              -78.700702,
              42.48535
            ],
            [
              -78.695937,
              42.47194
            ],
            [
              -78.6624,
              42.492437
            ],
            [
              -78.645652,
              42.490853
            ],
            [
              -78.638678,
              42.498058
            ],
            [
              -78.612708,
              42.502797
            ],
            [
              -78.597718,
              42.512484
            ],
            [
              -78.56315,
              42.509046
            ],
            [
              -78.527623,
              42.521985
            ],
            [
              -78.46394,
              42.536332
            ],
            [
              -78.463233,
              42.780461
            ],
            [
              -78.488143,
              42.78066
            ],
            [
              -78.486256,
              42.867731
            ],
            [
              -78.464381,
              42.867461
            ],
            [
              -78.464231,
              42.954555
            ],
            [
              -78.464449,
              43.088703
            ],
            [
              -78.472537,
              43.08165
            ],
            [
              -78.497932,
              43.082409
            ],
            [
              -78.509804,
              43.098034
            ],
            [
              -78.52079,
              43.088799
            ],
            [
              -78.54,
              43.08567
            ],
            [
              -78.550721,
              43.092907
            ],
            [
              -78.576248,
              43.088791
            ],
            [
              -78.586136,
              43.073109
            ],
            [
              -78.603989,
              43.072025
            ],
            [
              -78.618881,
              43.087444
            ],
            [
              -78.64212,
              43.096878
            ],
            [
              -78.648392,
              43.080589
            ],
            [
              -78.664711,
              43.086399
            ],
            [
              -78.682793,
              43.080269
            ],
            [
              -78.696906,
              43.086337
            ],
            [
              -78.719048,
              43.081055
            ],
            [
              -78.727647,
              43.08622
            ],
            [
              -78.744015,
              43.070376
            ],
            [
              -78.802051,
              43.065781
            ],
            [
              -78.822357,
              43.051557
            ],
            [
              -78.83126,
              43.050246
            ],
            [
              -78.825953,
              43.035955
            ],
            [
              -78.828805,
              43.030139
            ],
            [
              -78.83935,
              43.019453
            ],
            [
              -78.886467,
              43.022311
            ],
            [
              -78.890159,
              43.021956
            ],
            [
              -78.89626,
              43.044156
            ],
            [
              -78.90586,
              43.051556
            ],
            [
              -78.91152,
              43.05474
            ],
            [
              -78.945262,
              43.066956
            ],
            [
              -79.009664,
              43.069558
            ],
            [
              -79.0195783140304,
              43.0662946977452
            ],
            [
              -79.01053,
              43.064389
            ],
            [
              -79.00545,
              43.057231
            ],
            [
              -79.005164,
              43.047056
            ],
            [
              -79.011764,
              43.028956
            ],
            [
              -79.02092,
              43.014287
            ],
            [
              -79.019964,
              42.994756
            ],
            [
              -79.011563,
              42.985256
            ],
            [
              -78.975062,
              42.968756
            ],
            [
              -78.961761,
              42.957756
            ],
            [
              -78.93236,
              42.955857
            ],
            [
              -78.918859,
              42.946857
            ],
            [
              -78.9123653357821,
              42.937752481096595
            ],
            [
              -78.909159,
              42.933257
            ],
            [
              -78.905659,
              42.923357
            ],
            [
              -78.905758,
              42.899957
            ],
            [
              -78.912458,
              42.886557
            ],
            [
              -78.891655,
              42.884845
            ],
            [
              -78.882557,
              42.867258
            ],
            [
              -78.872227,
              42.853306
            ],
            [
              -78.865592,
              42.852358
            ],
            [
              -78.859456,
              42.841358
            ],
            [
              -78.860445,
              42.83511
            ],
            [
              -78.865656,
              42.826758
            ],
            [
              -78.863656,
              42.813058
            ],
            [
              -78.8609855598212,
              42.8053571957635
            ],
            [
              -78.859356,
              42.800658
            ],
            [
              -78.856456,
              42.800258
            ],
            [
              -78.851355,
              42.791758
            ],
            [
              -78.853455,
              42.783958
            ],
            [
              -78.868556,
              42.770258
            ],
            [
              -78.918157,
              42.737258
            ],
            [
              -78.944158,
              42.731958
            ],
            [
              -78.9697820767554,
              42.7174561714922
            ],
            [
              -78.991159,
              42.705358
            ],
            [
              -79.00616,
              42.704558
            ],
            [
              -79.01886,
              42.701558
            ],
            [
              -79.04886,
              42.689158
            ],
            [
              -79.062261,
              42.668358
            ],
            [
              -79.06376,
              42.644758
            ],
            [
              -79.073261,
              42.639958
            ],
            [
              -79.078761,
              42.640058
            ],
            [
              -79.111361,
              42.613358
            ],
            [
              -79.113713,
              42.605994
            ],
            [
              -79.121921,
              42.594234
            ],
            [
              -79.126261,
              42.590937
            ],
            [
              -79.12963,
              42.589824
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "023",
      "COUNTYNS": "00974110",
      "AFFGEOID": "0500000US36023",
      "GEOID": "36023",
      "NAME": "Cortland",
      "LSAD": "06",
      "ALAND": 1291815779,
      "AWATER": 7112822,
      "County_state": "Cortland,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.274673,
              42.771257
            ],
            [
              -76.265611,
              42.625447
            ],
            [
              -76.265584,
              42.623588
            ],
            [
              -76.253359,
              42.407568
            ],
            [
              -76.130181,
              42.410337
            ],
            [
              -76.057633,
              42.411941
            ],
            [
              -76.021136,
              42.409333
            ],
            [
              -75.86402,
              42.415702
            ],
            [
              -75.874224,
              42.542716
            ],
            [
              -75.889832,
              42.723844
            ],
            [
              -75.896079,
              42.790964
            ],
            [
              -76.195944,
              42.77473
            ],
            [
              -76.274673,
              42.771257
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "053",
      "COUNTYNS": "00974125",
      "AFFGEOID": "0500000US36053",
      "GEOID": "36053",
      "NAME": "Madison",
      "LSAD": "06",
      "ALAND": 1696091558,
      "AWATER": 16670528,
      "County_state": "Madison,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -75.993394,
              43.183376
            ],
            [
              -75.989672,
              43.179591
            ],
            [
              -75.992635,
              43.173849
            ],
            [
              -75.990028,
              43.171527
            ],
            [
              -75.982668,
              43.173631
            ],
            [
              -75.97788,
              43.164591
            ],
            [
              -75.97438,
              43.163175
            ],
            [
              -75.977068,
              43.157646
            ],
            [
              -75.971235,
              43.154639
            ],
            [
              -75.967318,
              43.134819
            ],
            [
              -75.950287,
              43.132551
            ],
            [
              -75.956023,
              43.115148
            ],
            [
              -75.965594,
              43.119552
            ],
            [
              -75.976396,
              43.103038
            ],
            [
              -75.975404,
              43.090921
            ],
            [
              -75.951361,
              43.087295
            ],
            [
              -75.919772,
              43.089765
            ],
            [
              -75.917559,
              43.086174
            ],
            [
              -75.910613,
              42.98941
            ],
            [
              -75.896079,
              42.790964
            ],
            [
              -75.889832,
              42.723844
            ],
            [
              -75.428668,
              42.745421
            ],
            [
              -75.427988,
              42.738111
            ],
            [
              -75.295877,
              42.744106
            ],
            [
              -75.280061,
              42.754546
            ],
            [
              -75.273156,
              42.772487
            ],
            [
              -75.251416,
              42.784565
            ],
            [
              -75.253242,
              42.795703
            ],
            [
              -75.239547,
              42.834233
            ],
            [
              -75.246614,
              42.844521
            ],
            [
              -75.247524,
              42.864713
            ],
            [
              -75.2448,
              42.868633
            ],
            [
              -75.247963,
              42.871604
            ],
            [
              -75.437167,
              42.863319
            ],
            [
              -75.456499,
              42.932882
            ],
            [
              -75.542803,
              42.931774
            ],
            [
              -75.550924,
              43.020411
            ],
            [
              -75.552774,
              43.037554
            ],
            [
              -75.596879,
              43.035399
            ],
            [
              -75.599496,
              43.0614
            ],
            [
              -75.640971,
              43.07992
            ],
            [
              -75.639392,
              43.095622
            ],
            [
              -75.653103,
              43.114111
            ],
            [
              -75.669701,
              43.115183
            ],
            [
              -75.678197,
              43.126897
            ],
            [
              -75.707357,
              43.137962
            ],
            [
              -75.707166,
              43.151445
            ],
            [
              -75.723929,
              43.162596
            ],
            [
              -75.752527,
              43.158722
            ],
            [
              -75.768787,
              43.169895
            ],
            [
              -75.797959,
              43.158985
            ],
            [
              -75.839993,
              43.160513
            ],
            [
              -75.868099,
              43.146699
            ],
            [
              -75.884275,
              43.155562
            ],
            [
              -75.925365,
              43.161708
            ],
            [
              -75.933138,
              43.176688
            ],
            [
              -75.989569,
              43.184866
            ],
            [
              -75.993394,
              43.183376
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "115",
      "COUNTYNS": "00974155",
      "AFFGEOID": "0500000US36115",
      "GEOID": "36115",
      "NAME": "Washington",
      "LSAD": "06",
      "ALAND": 2152713677,
      "AWATER": 38126891,
      "County_state": "Washington,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -73.636651,
              43.497242
            ],
            [
              -73.62894,
              43.486391
            ],
            [
              -73.6097,
              43.383692
            ],
            [
              -73.59496,
              43.306118
            ],
            [
              -73.588822,
              43.300543
            ],
            [
              -73.600656,
              43.268501
            ],
            [
              -73.589054,
              43.263683
            ],
            [
              -73.597082,
              43.244441
            ],
            [
              -73.579279,
              43.208906
            ],
            [
              -73.585705,
              43.19312
            ],
            [
              -73.580906,
              43.183737
            ],
            [
              -73.589836,
              43.170472
            ],
            [
              -73.580214,
              43.152882
            ],
            [
              -73.590779,
              43.13424
            ],
            [
              -73.577229,
              43.118976
            ],
            [
              -73.575647,
              43.107558
            ],
            [
              -73.578155,
              43.055787
            ],
            [
              -73.585833,
              43.049728
            ],
            [
              -73.597113,
              43.007623
            ],
            [
              -73.610334,
              42.981343
            ],
            [
              -73.631467,
              42.969739
            ],
            [
              -73.635463,
              42.94129
            ],
            [
              -73.624561,
              42.941222
            ],
            [
              -73.517323,
              42.943356
            ],
            [
              -73.450593,
              42.944581
            ],
            [
              -73.43316,
              42.958241
            ],
            [
              -73.392102,
              42.952586
            ],
            [
              -73.380235,
              42.943739
            ],
            [
              -73.274294,
              42.943652
            ],
            [
              -73.2700347484158,
              43.0307156692348
            ],
            [
              -73.26978,
              43.035923
            ],
            [
              -73.266206375905,
              43.0871568404487
            ],
            [
              -73.265574,
              43.096223
            ],
            [
              -73.25938653218309,
              43.2168596701502
            ],
            [
              -73.258718,
              43.229894
            ],
            [
              -73.2586680648889,
              43.230552806826
            ],
            [
              -73.256493,
              43.259249
            ],
            [
              -73.2545139435998,
              43.3146701262092
            ],
            [
              -73.253084,
              43.354714
            ],
            [
              -73.252832,
              43.363493
            ],
            [
              -73.252674,
              43.370285
            ],
            [
              -73.252582,
              43.370997
            ],
            [
              -73.2487638638259,
              43.4618122044894
            ],
            [
              -73.248401,
              43.470443
            ],
            [
              -73.247061,
              43.514919
            ],
            [
              -73.24672,
              43.518875
            ],
            [
              -73.247631,
              43.51924
            ],
            [
              -73.247698,
              43.523173
            ],
            [
              -73.246821,
              43.52578
            ],
            [
              -73.243366,
              43.527726
            ],
            [
              -73.241891,
              43.529418
            ],
            [
              -73.24139,
              43.532345
            ],
            [
              -73.241589,
              43.534973
            ],
            [
              -73.242042,
              43.534925
            ],
            [
              -73.2455893651413,
              43.540336234961295
            ],
            [
              -73.246585,
              43.541855
            ],
            [
              -73.247812,
              43.542814
            ],
            [
              -73.250132,
              43.543429
            ],
            [
              -73.250408,
              43.550425
            ],
            [
              -73.24842,
              43.552577
            ],
            [
              -73.248641,
              43.553857
            ],
            [
              -73.252602,
              43.556851
            ],
            [
              -73.258631,
              43.564949
            ],
            [
              -73.264099,
              43.568884
            ],
            [
              -73.26938,
              43.571973
            ],
            [
              -73.279726,
              43.574241
            ],
            [
              -73.280952,
              43.575407
            ],
            [
              -73.281296,
              43.577579
            ],
            [
              -73.284912,
              43.579272
            ],
            [
              -73.293536,
              43.578518
            ],
            [
              -73.294621,
              43.57897
            ],
            [
              -73.295344,
              43.580235
            ],
            [
              -73.29444,
              43.582494
            ],
            [
              -73.292113,
              43.584509
            ],
            [
              -73.292364,
              43.585104
            ],
            [
              -73.296924,
              43.587323
            ],
            [
              -73.293242,
              43.592558
            ],
            [
              -73.292801,
              43.593861
            ],
            [
              -73.292202,
              43.59816
            ],
            [
              -73.292232,
              43.60255
            ],
            [
              -73.293741,
              43.605203
            ],
            [
              -73.29802,
              43.610028
            ],
            [
              -73.300285,
              43.610806
            ],
            [
              -73.302076,
              43.624364
            ],
            [
              -73.302552,
              43.625708
            ],
            [
              -73.304125,
              43.627057
            ],
            [
              -73.306234,
              43.628018
            ],
            [
              -73.307682,
              43.627492
            ],
            [
              -73.310606,
              43.624114
            ],
            [
              -73.312809,
              43.624602
            ],
            [
              -73.317566,
              43.627355
            ],
            [
              -73.323893,
              43.627629
            ],
            [
              -73.327702,
              43.625913
            ],
            [
              -73.342181,
              43.62607
            ],
            [
              -73.347621,
              43.622509
            ],
            [
              -73.358593,
              43.625065
            ],
            [
              -73.35911,
              43.624598
            ],
            [
              -73.365562,
              43.62344
            ],
            [
              -73.367167,
              43.623622
            ],
            [
              -73.368899,
              43.62471
            ],
            [
              -73.371889,
              43.624489
            ],
            [
              -73.372486,
              43.622751
            ],
            [
              -73.36987,
              43.619711
            ],
            [
              -73.369933,
              43.619093
            ],
            [
              -73.374557,
              43.614677
            ],
            [
              -73.376036,
              43.612596
            ],
            [
              -73.372375,
              43.606014
            ],
            [
              -73.372469,
              43.604848
            ],
            [
              -73.373443,
              43.603292
            ],
            [
              -73.377748,
              43.599656
            ],
            [
              -73.383446,
              43.596778
            ],
            [
              -73.383426,
              43.584727
            ],
            [
              -73.382549,
              43.579193
            ],
            [
              -73.383369,
              43.57677
            ],
            [
              -73.384188,
              43.575512
            ],
            [
              -73.39196,
              43.569915
            ],
            [
              -73.395767,
              43.568087
            ],
            [
              -73.398125,
              43.568065
            ],
            [
              -73.400295,
              43.568889
            ],
            [
              -73.405629,
              43.571179
            ],
            [
              -73.416964,
              43.57773
            ],
            [
              -73.420378,
              43.581489
            ],
            [
              -73.426663,
              43.582974
            ],
            [
              -73.428636,
              43.583994
            ],
            [
              -73.430947,
              43.587036
            ],
            [
              -73.431229,
              43.588285
            ],
            [
              -73.430325,
              43.590532
            ],
            [
              -73.424977,
              43.598775
            ],
            [
              -73.421616,
              43.603023
            ],
            [
              -73.422154,
              43.606511
            ],
            [
              -73.423815,
              43.610989
            ],
            [
              -73.423708,
              43.612356
            ],
            [
              -73.417827,
              43.620586
            ],
            [
              -73.417668,
              43.621687
            ],
            [
              -73.418319,
              43.623325
            ],
            [
              -73.42791,
              43.634428
            ],
            [
              -73.428583,
              43.636543
            ],
            [
              -73.426463,
              43.642598
            ],
            [
              -73.425217,
              43.64429
            ],
            [
              -73.423539,
              43.645676
            ],
            [
              -73.418763,
              43.64788
            ],
            [
              -73.415513,
              43.65245
            ],
            [
              -73.414546,
              43.658209
            ],
            [
              -73.408061,
              43.669438
            ],
            [
              -73.407776,
              43.672519
            ],
            [
              -73.40739952321769,
              43.6734287329369
            ],
            [
              -73.404126,
              43.681339
            ],
            [
              -73.403474,
              43.684694
            ],
            [
              -73.405243,
              43.688367
            ],
            [
              -73.404739,
              43.690213
            ],
            [
              -73.402078,
              43.693106
            ],
            [
              -73.398332,
              43.694625
            ],
            [
              -73.395517,
              43.696831
            ],
            [
              -73.393723,
              43.6992
            ],
            [
              -73.39179,
              43.703481
            ],
            [
              -73.385883,
              43.711336
            ],
            [
              -73.382965,
              43.714058
            ],
            [
              -73.377756,
              43.717712
            ],
            [
              -73.370612,
              43.725329
            ],
            [
              -73.369916,
              43.728789
            ],
            [
              -73.370724,
              43.735571
            ],
            [
              -73.370287,
              43.742269
            ],
            [
              -73.369725,
              43.744274
            ],
            [
              -73.3629511574321,
              43.7531814596909
            ],
            [
              -73.354597,
              43.764167
            ],
            [
              -73.350707,
              43.770463
            ],
            [
              -73.350593,
              43.771939
            ],
            [
              -73.354758,
              43.776721
            ],
            [
              -73.355545,
              43.778468
            ],
            [
              -73.357547,
              43.785933
            ],
            [
              -73.362498,
              43.790211
            ],
            [
              -73.368184,
              43.793346
            ],
            [
              -73.376361,
              43.798766
            ],
            [
              -73.377232,
              43.800565
            ],
            [
              -73.37827,
              43.805995
            ],
            [
              -73.379279,
              43.808391
            ],
            [
              -73.3793298267232,
              43.808476322237
            ],
            [
              -73.43812,
              43.803687
            ],
            [
              -73.471307,
              43.771681
            ],
            [
              -73.460289,
              43.768853
            ],
            [
              -73.459984,
              43.740808
            ],
            [
              -73.471941,
              43.706927
            ],
            [
              -73.484185,
              43.692151
            ],
            [
              -73.49352,
              43.655165
            ],
            [
              -73.51427,
              43.637409
            ],
            [
              -73.526789,
              43.636014
            ],
            [
              -73.589004,
              43.569311
            ],
            [
              -73.61323,
              43.562932
            ],
            [
              -73.607065,
              43.552757
            ],
            [
              -73.636651,
              43.497242
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "037",
      "COUNTYNS": "00974117",
      "AFFGEOID": "0500000US36037",
      "GEOID": "36037",
      "NAME": "Genesee",
      "LSAD": "06",
      "ALAND": 1276698028,
      "AWATER": 6142605,
      "County_state": "Genesee,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.465505,
              43.128619
            ],
            [
              -78.464449,
              43.088703
            ],
            [
              -78.464231,
              42.954555
            ],
            [
              -78.464381,
              42.867461
            ],
            [
              -78.074171,
              42.870188
            ],
            [
              -77.995362,
              42.864993
            ],
            [
              -77.954964,
              42.862754
            ],
            [
              -77.95553,
              42.905861
            ],
            [
              -77.93523,
              42.905261
            ],
            [
              -77.934234,
              42.94558
            ],
            [
              -77.911413,
              42.945064
            ],
            [
              -77.909832,
              42.987762
            ],
            [
              -77.90754,
              43.002859
            ],
            [
              -77.904121,
              43.037056
            ],
            [
              -77.951044,
              43.039544
            ],
            [
              -77.908122,
              43.129335
            ],
            [
              -77.905934,
              43.133561
            ],
            [
              -77.99729,
              43.132981
            ],
            [
              -78.310512,
              43.131295
            ],
            [
              -78.465505,
              43.128619
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "113",
      "COUNTYNS": "00974154",
      "AFFGEOID": "0500000US36113",
      "GEOID": "36113",
      "NAME": "Warren",
      "LSAD": "06",
      "ALAND": 2246154434,
      "AWATER": 166682934,
      "County_state": "Warren,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -74.214625,
              43.728703
            ],
            [
              -74.1601,
              43.371532
            ],
            [
              -73.883498,
              43.398045
            ],
            [
              -73.870977,
              43.372523
            ],
            [
              -73.875372,
              43.365122
            ],
            [
              -73.853286,
              43.33775
            ],
            [
              -73.858991,
              43.33032
            ],
            [
              -73.836918,
              43.306611
            ],
            [
              -73.825828,
              43.305348
            ],
            [
              -73.826077,
              43.276088
            ],
            [
              -73.835567,
              43.265835
            ],
            [
              -73.833459,
              43.249303
            ],
            [
              -73.803298,
              43.25032
            ],
            [
              -73.789731,
              43.244421
            ],
            [
              -73.769071,
              43.222338
            ],
            [
              -73.763515,
              43.22539
            ],
            [
              -73.743957,
              43.242717
            ],
            [
              -73.737483,
              43.267364
            ],
            [
              -73.705576,
              43.278938
            ],
            [
              -73.684962,
              43.280872
            ],
            [
              -73.68076,
              43.267371
            ],
            [
              -73.661061,
              43.272872
            ],
            [
              -73.675162,
              43.285072
            ],
            [
              -73.671273,
              43.293154
            ],
            [
              -73.650661,
              43.289572
            ],
            [
              -73.642761,
              43.304172
            ],
            [
              -73.59496,
              43.306118
            ],
            [
              -73.6097,
              43.383692
            ],
            [
              -73.62894,
              43.486391
            ],
            [
              -73.636651,
              43.497242
            ],
            [
              -73.607065,
              43.552757
            ],
            [
              -73.61323,
              43.562932
            ],
            [
              -73.589004,
              43.569311
            ],
            [
              -73.526789,
              43.636014
            ],
            [
              -73.51427,
              43.637409
            ],
            [
              -73.49352,
              43.655165
            ],
            [
              -73.484185,
              43.692151
            ],
            [
              -73.471941,
              43.706927
            ],
            [
              -73.459984,
              43.740808
            ],
            [
              -73.460289,
              43.768853
            ],
            [
              -73.471307,
              43.771681
            ],
            [
              -73.43812,
              43.803687
            ],
            [
              -74.057005,
              43.744513
            ],
            [
              -74.214625,
              43.728703
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "025",
      "COUNTYNS": "00974111",
      "AFFGEOID": "0500000US36025",
      "GEOID": "36025",
      "NAME": "Delaware",
      "LSAD": "06",
      "ALAND": 3735952964,
      "AWATER": 64717114,
      "County_state": "Delaware,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -75.419664,
              42.150436
            ],
            [
              -75.421776,
              42.04203
            ],
            [
              -75.4022261958532,
              41.9993966859156
            ],
            [
              -75.359579,
              41.999445
            ],
            [
              -75.353504,
              41.99711
            ],
            [
              -75.346568,
              41.995324
            ],
            [
              -75.341125,
              41.992772
            ],
            [
              -75.337602,
              41.9867
            ],
            [
              -75.337791,
              41.984386
            ],
            [
              -75.34246,
              41.974303
            ],
            [
              -75.342204,
              41.972872
            ],
            [
              -75.339488,
              41.970786
            ],
            [
              -75.335771,
              41.970315
            ],
            [
              -75.329318,
              41.968232
            ],
            [
              -75.322384,
              41.961693
            ],
            [
              -75.32004,
              41.960867
            ],
            [
              -75.318168,
              41.954236
            ],
            [
              -75.312817,
              41.950182
            ],
            [
              -75.310358,
              41.949012
            ],
            [
              -75.303966,
              41.948216
            ],
            [
              -75.301664,
              41.94838
            ],
            [
              -75.301233,
              41.9489
            ],
            [
              -75.301593,
              41.952811
            ],
            [
              -75.300409,
              41.953871
            ],
            [
              -75.29858,
              41.954521
            ],
            [
              -75.293713,
              41.954593
            ],
            [
              -75.29143,
              41.952477
            ],
            [
              -75.291762,
              41.947092
            ],
            [
              -75.290966,
              41.945039
            ],
            [
              -75.289383,
              41.942891
            ],
            [
              -75.279094,
              41.938917
            ],
            [
              -75.277243,
              41.933598
            ],
            [
              -75.276501,
              41.926679
            ],
            [
              -75.276552,
              41.922208
            ],
            [
              -75.275368,
              41.919564
            ],
            [
              -75.269736,
              41.911363
            ],
            [
              -75.267562,
              41.907054
            ],
            [
              -75.267773,
              41.901971
            ],
            [
              -75.272778,
              41.897112
            ],
            [
              -75.272581,
              41.893168
            ],
            [
              -75.271292,
              41.88736
            ],
            [
              -75.267789,
              41.885982
            ],
            [
              -75.263005,
              41.885109
            ],
            [
              -75.260623,
              41.883783
            ],
            [
              -75.257564,
              41.877108
            ],
            [
              -75.258439,
              41.875087
            ],
            [
              -75.261488,
              41.873277
            ],
            [
              -75.263815,
              41.870757
            ],
            [
              -75.263673,
              41.868105
            ],
            [
              -75.262802,
              41.866213
            ],
            [
              -75.260527,
              41.8638
            ],
            [
              -75.257825,
              41.862154
            ],
            [
              -75.251197,
              41.86204
            ],
            [
              -75.248045,
              41.8633
            ],
            [
              -75.243345,
              41.866875
            ],
            [
              -75.241134,
              41.867118
            ],
            [
              -75.238743,
              41.865699
            ],
            [
              -75.234565,
              41.861569
            ],
            [
              -75.231612,
              41.859459
            ],
            [
              -75.22572,
              41.857481
            ],
            [
              -75.223734,
              41.857456
            ],
            [
              -75.220125,
              41.860534
            ],
            [
              -75.21497,
              41.867449
            ],
            [
              -75.209741,
              41.86925
            ],
            [
              -75.204002,
              41.869867
            ],
            [
              -75.197836,
              41.868807
            ],
            [
              -75.194382,
              41.867287
            ],
            [
              -75.191441,
              41.865063
            ],
            [
              -75.190203,
              41.862454
            ],
            [
              -75.188888,
              41.861264
            ],
            [
              -75.186993,
              41.860109
            ],
            [
              -75.185254,
              41.85993
            ],
            [
              -75.183937,
              41.860515
            ],
            [
              -75.182271,
              41.862198
            ],
            [
              -75.180497,
              41.86568
            ],
            [
              -75.179134,
              41.869935
            ],
            [
              -75.176633,
              41.872371
            ],
            [
              -75.174574,
              41.87266
            ],
            [
              -75.170565,
              41.871608
            ],
            [
              -75.169142,
              41.87029
            ],
            [
              -75.168053,
              41.867043
            ],
            [
              -75.168733,
              41.859258
            ],
            [
              -75.166217,
              41.853862
            ],
            [
              -75.164168,
              41.851586
            ],
            [
              -75.161541,
              41.849836
            ],
            [
              -75.156512,
              41.848327
            ],
            [
              -75.152898,
              41.848564
            ],
            [
              -75.146446,
              41.850899
            ],
            [
              -74.997252,
              41.918485
            ],
            [
              -74.780693,
              42.016375
            ],
            [
              -74.669689,
              42.067819
            ],
            [
              -74.553598,
              42.121484
            ],
            [
              -74.548265,
              42.127406
            ],
            [
              -74.530985,
              42.146475
            ],
            [
              -74.503984,
              42.14492
            ],
            [
              -74.451713,
              42.169225
            ],
            [
              -74.53731,
              42.201424
            ],
            [
              -74.428196,
              42.349075
            ],
            [
              -74.443506,
              42.355017
            ],
            [
              -74.618895,
              42.424389
            ],
            [
              -74.71158,
              42.517799
            ],
            [
              -74.751865,
              42.514392
            ],
            [
              -74.772888,
              42.505169
            ],
            [
              -74.777776,
              42.506327
            ],
            [
              -74.844121,
              42.512591
            ],
            [
              -74.968706,
              42.468435
            ],
            [
              -74.992406,
              42.440483
            ],
            [
              -75.017958,
              42.454243
            ],
            [
              -75.019818,
              42.429045
            ],
            [
              -75.059068,
              42.43064
            ],
            [
              -75.093408,
              42.420634
            ],
            [
              -75.197237,
              42.358329
            ],
            [
              -75.19096,
              42.379852
            ],
            [
              -75.217635,
              42.367398
            ],
            [
              -75.241317,
              42.365073
            ],
            [
              -75.261569,
              42.366673
            ],
            [
              -75.269101,
              42.351312
            ],
            [
              -75.292726,
              42.339488
            ],
            [
              -75.307321,
              42.321291
            ],
            [
              -75.341368,
              42.314797
            ],
            [
              -75.377546,
              42.322149
            ],
            [
              -75.415319,
              42.314151
            ],
            [
              -75.416109,
              42.281661
            ],
            [
              -75.418421,
              42.195032
            ],
            [
              -75.419664,
              42.150436
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "123",
      "COUNTYNS": "00974159",
      "AFFGEOID": "0500000US36123",
      "GEOID": "36123",
      "NAME": "Yates",
      "LSAD": "06",
      "ALAND": 875785691,
      "AWATER": 97504774,
      "County_state": "Yates,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.367106,
              42.667866
            ],
            [
              -77.366996,
              42.65507
            ],
            [
              -77.366505,
              42.576368
            ],
            [
              -77.242763,
              42.576374
            ],
            [
              -77.143795,
              42.576869
            ],
            [
              -77.149595,
              42.55387
            ],
            [
              -77.149502,
              42.50007
            ],
            [
              -77.127279,
              42.497259
            ],
            [
              -77.107593,
              42.50577
            ],
            [
              -77.107203,
              42.483771
            ],
            [
              -76.989329,
              42.484821
            ],
            [
              -76.987913,
              42.463239
            ],
            [
              -76.963977,
              42.459186
            ],
            [
              -76.889805,
              42.463054
            ],
            [
              -76.900841,
              42.493812
            ],
            [
              -76.895596,
              42.541537
            ],
            [
              -76.894061,
              42.578795
            ],
            [
              -76.897117,
              42.66365
            ],
            [
              -76.935365,
              42.715629
            ],
            [
              -76.946737,
              42.749743
            ],
            [
              -76.947429,
              42.76443
            ],
            [
              -76.971392,
              42.764223
            ],
            [
              -77.12315,
              42.76334
            ],
            [
              -77.313004,
              42.761265
            ],
            [
              -77.314461,
              42.759132
            ],
            [
              -77.337605,
              42.732666
            ],
            [
              -77.353606,
              42.699966
            ],
            [
              -77.367106,
              42.667866
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "36",
      "COUNTYFP": "069",
      "COUNTYNS": "00974133",
      "AFFGEOID": "0500000US36069",
      "GEOID": "36069",
      "NAME": "Ontario",
      "LSAD": "06",
      "ALAND": 1668114449,
      "AWATER": 47820988,
      "County_state": "Ontario,36"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.61167,
              42.763169
            ],
            [
              -77.598815,
              42.671965
            ],
            [
              -77.486875,
              42.670279
            ],
            [
              -77.489908,
              42.646326
            ],
            [
              -77.490889,
              42.577288
            ],
            [
              -77.455632,
              42.576895
            ],
            [
              -77.366505,
              42.576368
            ],
            [
              -77.366996,
              42.65507
            ],
            [
              -77.367106,
              42.667866
            ],
            [
              -77.353606,
              42.699966
            ],
            [
              -77.337605,
              42.732666
            ],
            [
              -77.314461,
              42.759132
            ],
            [
              -77.313004,
              42.761265
            ],
            [
              -77.12315,
              42.76334
            ],
            [
              -76.971392,
              42.764223
            ],
            [
              -76.977888,
              42.77324
            ],
            [
              -76.973453,
              42.833747
            ],
            [
              -76.980647,
              42.857064
            ],
            [
              -76.978526,
              42.869072
            ],
            [
              -76.963365,
              42.872945
            ],
            [
              -76.963926,
              43.013157
            ],
            [
              -77.133397,
              43.012463
            ],
            [
              -77.134307,
              43.039887
            ],
            [
              -77.245674,
              43.039763
            ],
            [
              -77.246804,
              43.034663
            ],
            [
              -77.371478,
              43.034696
            ],
            [
              -77.485418,
              43.034564
            ],
            [
              -77.482517,
              42.943164
            ],
            [
              -77.580377,
              42.943963
            ],
            [
              -77.560566,
              42.921432
            ],
            [
              -77.546309,
              42.86569
            ],
            [
              -77.551926,
              42.854064
            ],
            [
              -77.587997,
              42.854011
            ],
            [
              -77.588217,
              42.762964
            ],
            [
              -77.61167,
              42.763169
            ]
          ]
        ]
      ]
    }
  }
]
};