export const counties_ky = {
  "type":"FeatureCollection","name":"ky_counties","features":[
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "219",
      "COUNTYNS": "00516956",
      "AFFGEOID": "0500000US21219",
      "GEOID": "21219",
      "NAME": "Todd",
      "LSAD": "06",
      "ALAND": 969941707,
      "AWATER": 6629026,
      "County_state": "Todd,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.33597953375501,
              36.6415426709465
            ],
            [
              -87.3149492444387,
              36.64162696001029
            ],
            [
              -87.281506,
              36.641761
            ],
            [
              -87.278398,
              36.641718
            ],
            [
              -87.247655,
              36.641841
            ],
            [
              -87.231037,
              36.641888
            ],
            [
              -87.23053,
              36.641895
            ],
            [
              -87.11498319298539,
              36.6424139676934
            ],
            [
              -87.114976,
              36.642414
            ],
            [
              -87.0608458150142,
              36.6432171572868
            ],
            [
              -87.059535,
              36.747951
            ],
            [
              -87.05016,
              36.767516
            ],
            [
              -87.058188,
              36.804718
            ],
            [
              -87.053164,
              37.061019
            ],
            [
              -87.118069,
              37.044732
            ],
            [
              -87.250256,
              37.040557
            ],
            [
              -87.245248,
              37.064116
            ],
            [
              -87.259371,
              37.072401
            ],
            [
              -87.33597953375501,
              36.6415426709465
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "209",
      "COUNTYNS": "00516951",
      "AFFGEOID": "0500000US21209",
      "GEOID": "21209",
      "NAME": "Scott",
      "LSAD": "06",
      "ALAND": 729781719,
      "AWATER": 9487693,
      "County_state": "Scott,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.740594,
              38.352415
            ],
            [
              -84.732241,
              38.231192
            ],
            [
              -84.730312,
              38.208362
            ],
            [
              -84.725168,
              38.195426
            ],
            [
              -84.707541,
              38.173389
            ],
            [
              -84.689717,
              38.171179
            ],
            [
              -84.682641,
              38.181267
            ],
            [
              -84.647821,
              38.176606
            ],
            [
              -84.655275,
              38.171015
            ],
            [
              -84.650277,
              38.144824
            ],
            [
              -84.639277,
              38.128023
            ],
            [
              -84.645012,
              38.116631
            ],
            [
              -84.625079,
              38.116397
            ],
            [
              -84.620601,
              38.129545
            ],
            [
              -84.538989,
              38.159066
            ],
            [
              -84.401847,
              38.207889
            ],
            [
              -84.419918,
              38.214245
            ],
            [
              -84.423351,
              38.257158
            ],
            [
              -84.441635,
              38.277428
            ],
            [
              -84.442661,
              38.283236
            ],
            [
              -84.432841,
              38.299569
            ],
            [
              -84.443756,
              38.32431
            ],
            [
              -84.456298,
              38.336761
            ],
            [
              -84.462832,
              38.363961
            ],
            [
              -84.458108,
              38.373545
            ],
            [
              -84.467952,
              38.393184
            ],
            [
              -84.495513,
              38.417264
            ],
            [
              -84.527948,
              38.43325
            ],
            [
              -84.526601,
              38.450648
            ],
            [
              -84.543918,
              38.464735
            ],
            [
              -84.557375,
              38.492917
            ],
            [
              -84.562692,
              38.490436
            ],
            [
              -84.580539,
              38.473039
            ],
            [
              -84.623583,
              38.431191
            ],
            [
              -84.681555,
              38.390157
            ],
            [
              -84.705988,
              38.370102
            ],
            [
              -84.740594,
              38.352415
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "129",
      "COUNTYNS": "00516911",
      "AFFGEOID": "0500000US21129",
      "GEOID": "21129",
      "NAME": "Lee",
      "LSAD": "06",
      "ALAND": 540937712,
      "AWATER": 6215038,
      "County_state": "Lee,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.90557,
              37.542856
            ],
            [
              -83.893514,
              37.531119
            ],
            [
              -83.885262,
              37.517688
            ],
            [
              -83.866305,
              37.51365
            ],
            [
              -83.866808,
              37.505083
            ],
            [
              -83.82934,
              37.486821
            ],
            [
              -83.742984,
              37.494253
            ],
            [
              -83.745032,
              37.50791
            ],
            [
              -83.731864,
              37.521158
            ],
            [
              -83.701721,
              37.507705
            ],
            [
              -83.680439,
              37.511984
            ],
            [
              -83.657152,
              37.536781
            ],
            [
              -83.635706,
              37.540011
            ],
            [
              -83.622381,
              37.510775
            ],
            [
              -83.607624,
              37.514836
            ],
            [
              -83.579186,
              37.506249
            ],
            [
              -83.522308,
              37.638516
            ],
            [
              -83.616649,
              37.666925
            ],
            [
              -83.628307,
              37.67889
            ],
            [
              -83.707974,
              37.716463
            ],
            [
              -83.7221,
              37.71642
            ],
            [
              -83.749284,
              37.715162
            ],
            [
              -83.780783,
              37.687807
            ],
            [
              -83.822121,
              37.665578
            ],
            [
              -83.808123,
              37.636035
            ],
            [
              -83.83246,
              37.636259
            ],
            [
              -83.865969,
              37.615877
            ],
            [
              -83.858098,
              37.609205
            ],
            [
              -83.90557,
              37.542856
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "149",
      "COUNTYNS": "00516927",
      "AFFGEOID": "0500000US21149",
      "GEOID": "21149",
      "NAME": "McLean",
      "LSAD": "06",
      "ALAND": 653906323,
      "AWATER": 9734358,
      "County_state": "McLean,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.495331,
              37.647547
            ],
            [
              -87.499647,
              37.627445
            ],
            [
              -87.482702,
              37.600914
            ],
            [
              -87.466031,
              37.594201
            ],
            [
              -87.39697,
              37.589253
            ],
            [
              -87.375145,
              37.569988
            ],
            [
              -87.359639,
              37.544823
            ],
            [
              -87.366627,
              37.534845
            ],
            [
              -87.349404,
              37.518671
            ],
            [
              -87.361657,
              37.488052
            ],
            [
              -87.351089,
              37.424929
            ],
            [
              -87.32502,
              37.406242
            ],
            [
              -87.328543,
              37.398462
            ],
            [
              -87.311479,
              37.401873
            ],
            [
              -87.295965,
              37.391819
            ],
            [
              -87.210958,
              37.38093
            ],
            [
              -87.171068,
              37.413589
            ],
            [
              -87.112297,
              37.411537
            ],
            [
              -87.099803,
              37.416042
            ],
            [
              -87.104603,
              37.460728
            ],
            [
              -87.135629,
              37.485153
            ],
            [
              -87.113344,
              37.471507
            ],
            [
              -87.103111,
              37.4809
            ],
            [
              -87.049475,
              37.551738
            ],
            [
              -87.03826,
              37.560803
            ],
            [
              -87.057272,
              37.570641
            ],
            [
              -87.095524,
              37.566954
            ],
            [
              -87.138821,
              37.575628
            ],
            [
              -87.15982,
              37.586999
            ],
            [
              -87.26161,
              37.622009
            ],
            [
              -87.408547,
              37.683563
            ],
            [
              -87.414344,
              37.673601
            ],
            [
              -87.472811,
              37.664869
            ],
            [
              -87.495331,
              37.647547
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "073",
      "COUNTYNS": "00516883",
      "AFFGEOID": "0500000US21073",
      "GEOID": "21073",
      "NAME": "Franklin",
      "LSAD": "06",
      "ALAND": 538073033,
      "AWATER": 11025563,
      "County_state": "Franklin,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.019997,
              38.141523
            ],
            [
              -85.023711,
              38.129052
            ],
            [
              -84.967393,
              38.119268
            ],
            [
              -84.958135,
              38.109825
            ],
            [
              -84.902266,
              38.093437
            ],
            [
              -84.887438,
              38.097005
            ],
            [
              -84.864908,
              38.116926
            ],
            [
              -84.866003,
              38.134627
            ],
            [
              -84.864236,
              38.14159
            ],
            [
              -84.725168,
              38.195426
            ],
            [
              -84.730312,
              38.208362
            ],
            [
              -84.732241,
              38.231192
            ],
            [
              -84.740594,
              38.352415
            ],
            [
              -84.793412,
              38.338669
            ],
            [
              -84.838419,
              38.356926
            ],
            [
              -84.870482,
              38.356751
            ],
            [
              -84.892357,
              38.3552
            ],
            [
              -84.906256,
              38.374836
            ],
            [
              -84.997667,
              38.335586
            ],
            [
              -84.956959,
              38.332271
            ],
            [
              -84.998898,
              38.274979
            ],
            [
              -85.001032,
              38.226152
            ],
            [
              -85.00748,
              38.17578
            ],
            [
              -85.003837,
              38.16066
            ],
            [
              -85.019997,
              38.141523
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "061",
      "COUNTYNS": "00516877",
      "AFFGEOID": "0500000US21061",
      "GEOID": "21061",
      "NAME": "Edmonson",
      "LSAD": "06",
      "ALAND": 784468319,
      "AWATER": 13258106,
      "County_state": "Edmonson,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.469126,
              37.321099
            ],
            [
              -86.399165,
              37.169896
            ],
            [
              -86.28175,
              37.080597
            ],
            [
              -86.256493,
              37.085593
            ],
            [
              -86.241809,
              37.097005
            ],
            [
              -86.218607,
              37.104029
            ],
            [
              -86.192246,
              37.076696
            ],
            [
              -86.113512,
              37.061072
            ],
            [
              -86.074267,
              37.053285
            ],
            [
              -86.056443,
              37.167208
            ],
            [
              -86.049133,
              37.215285
            ],
            [
              -86.157589,
              37.335649
            ],
            [
              -86.176963,
              37.33277
            ],
            [
              -86.187534,
              37.340796
            ],
            [
              -86.19305,
              37.327905
            ],
            [
              -86.209193,
              37.33293
            ],
            [
              -86.469126,
              37.321099
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "041",
      "COUNTYNS": "00516867",
      "AFFGEOID": "0500000US21041",
      "GEOID": "21041",
      "NAME": "Carroll",
      "LSAD": "06",
      "ALAND": 332993661,
      "AWATER": 22650593,
      "County_state": "Carroll,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.330807,
              38.736705
            ],
            [
              -85.33305185946669,
              38.7360828292115
            ],
            [
              -85.329683,
              38.725817
            ],
            [
              -85.228976,
              38.627035
            ],
            [
              -85.230257,
              38.617818
            ],
            [
              -85.213982,
              38.601346
            ],
            [
              -85.211375,
              38.580216
            ],
            [
              -85.16827,
              38.585448
            ],
            [
              -85.074583,
              38.596838
            ],
            [
              -85.059846,
              38.611022
            ],
            [
              -85.0243,
              38.614202
            ],
            [
              -85.015006,
              38.632386
            ],
            [
              -84.974623,
              38.636619
            ],
            [
              -84.969888,
              38.648732
            ],
            [
              -84.936982,
              38.650827
            ],
            [
              -84.934536,
              38.66213
            ],
            [
              -84.962671,
              38.679586
            ],
            [
              -85.0235842033963,
              38.7620346655344
            ],
            [
              -85.040938,
              38.755163
            ],
            [
              -85.047967,
              38.750849
            ],
            [
              -85.060264,
              38.744948
            ],
            [
              -85.071928,
              38.741567
            ],
            [
              -85.076369,
              38.739496
            ],
            [
              -85.08218,
              38.735439
            ],
            [
              -85.100963,
              38.7268
            ],
            [
              -85.103313,
              38.725323
            ],
            [
              -85.106979,
              38.72163
            ],
            [
              -85.106902,
              38.720789
            ],
            [
              -85.121357,
              38.711232
            ],
            [
              -85.133049,
              38.702375
            ],
            [
              -85.13868,
              38.699168
            ],
            [
              -85.146861,
              38.695427
            ],
            [
              -85.156158,
              38.692251
            ],
            [
              -85.172528,
              38.688082
            ],
            [
              -85.177112,
              38.688405
            ],
            [
              -85.187278,
              38.687609
            ],
            [
              -85.190507,
              38.68795
            ],
            [
              -85.2015826164201,
              38.6909118349635
            ],
            [
              -85.2045,
              38.691692
            ],
            [
              -85.213257,
              38.695446
            ],
            [
              -85.221124,
              38.700957
            ],
            [
              -85.226062,
              38.705456
            ],
            [
              -85.238665,
              38.722494
            ],
            [
              -85.242434,
              38.726235
            ],
            [
              -85.246505,
              38.731821
            ],
            [
              -85.258846,
              38.737754
            ],
            [
              -85.267639,
              38.739899
            ],
            [
              -85.275454,
              38.741172
            ],
            [
              -85.289226,
              38.74241
            ],
            [
              -85.306049,
              38.741649
            ],
            [
              -85.330807,
              38.736705
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "083",
      "COUNTYNS": "00516888",
      "AFFGEOID": "0500000US21083",
      "GEOID": "21083",
      "NAME": "Graves",
      "LSAD": "06",
      "ALAND": 1429025286,
      "AWATER": 13047565,
      "County_state": "Graves,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.817236,
              36.767754
            ],
            [
              -88.819595,
              36.755906
            ],
            [
              -88.816287,
              36.682894
            ],
            [
              -88.81676518603649,
              36.5028152435007
            ],
            [
              -88.799594,
              36.502757
            ],
            [
              -88.747523,
              36.502834
            ],
            [
              -88.715255,
              36.502662
            ],
            [
              -88.661133,
              36.502243
            ],
            [
              -88.577283,
              36.50194
            ],
            [
              -88.545192,
              36.501814
            ],
            [
              -88.516427,
              36.50143
            ],
            [
              -88.5163456859488,
              36.5014314866172
            ],
            [
              -88.51227,
              36.501506
            ],
            [
              -88.51192,
              36.501457
            ],
            [
              -88.48921,
              36.501068
            ],
            [
              -88.4890752146435,
              36.5010676761135
            ],
            [
              -88.485697,
              36.750328
            ],
            [
              -88.484103,
              36.942096
            ],
            [
              -88.573401,
              36.943293
            ],
            [
              -88.699804,
              36.942774
            ],
            [
              -88.709378,
              36.946357
            ],
            [
              -88.812897,
              36.946864
            ],
            [
              -88.813229,
              36.773115
            ],
            [
              -88.817236,
              36.767754
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "171",
      "COUNTYNS": "00516932",
      "AFFGEOID": "0500000US21171",
      "GEOID": "21171",
      "NAME": "Monroe",
      "LSAD": "06",
      "ALAND": 853073337,
      "AWATER": 6949352,
      "County_state": "Monroe,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.97691,
              36.722722
            ],
            [
              -85.977442,
              36.715877
            ],
            [
              -85.949403,
              36.661645
            ],
            [
              -85.950661,
              36.651023
            ],
            [
              -85.97584562225909,
              36.628610523713704
            ],
            [
              -85.959981,
              36.628121
            ],
            [
              -85.873857,
              36.623642
            ],
            [
              -85.832172,
              36.622046
            ],
            [
              -85.80149,
              36.622418
            ],
            [
              -85.788645,
              36.621846
            ],
            [
              -85.7886129951215,
              36.6218452013294
            ],
            [
              -85.731862,
              36.620429
            ],
            [
              -85.677789,
              36.618157
            ],
            [
              -85.552017,
              36.615782
            ],
            [
              -85.551483,
              36.615727
            ],
            [
              -85.488353,
              36.614994
            ],
            [
              -85.4364330342016,
              36.6185230953912
            ],
            [
              -85.496932,
              36.690718
            ],
            [
              -85.471021,
              36.733216
            ],
            [
              -85.517429,
              36.731326
            ],
            [
              -85.542513,
              36.774814
            ],
            [
              -85.57115,
              36.770883
            ],
            [
              -85.588751,
              36.777349
            ],
            [
              -85.596214,
              36.818039
            ],
            [
              -85.602421,
              36.829574
            ],
            [
              -85.613014,
              36.832717
            ],
            [
              -85.739255,
              36.841476
            ],
            [
              -85.772286,
              36.83634
            ],
            [
              -85.807011,
              36.821638
            ],
            [
              -85.97691,
              36.722722
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "189",
      "COUNTYNS": "00516941",
      "AFFGEOID": "0500000US21189",
      "GEOID": "21189",
      "NAME": "Owsley",
      "LSAD": "06",
      "ALAND": 511282856,
      "AWATER": 2285944,
      "County_state": "Owsley,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.885262,
              37.517688
            ],
            [
              -83.883953,
              37.477335
            ],
            [
              -83.865122,
              37.451378
            ],
            [
              -83.849961,
              37.447848
            ],
            [
              -83.81792,
              37.382216
            ],
            [
              -83.784975,
              37.348717
            ],
            [
              -83.718172,
              37.33883
            ],
            [
              -83.678553,
              37.337055
            ],
            [
              -83.668138,
              37.355232
            ],
            [
              -83.652996,
              37.357911
            ],
            [
              -83.638572,
              37.344166
            ],
            [
              -83.622383,
              37.314781
            ],
            [
              -83.608829,
              37.30853
            ],
            [
              -83.597279,
              37.292255
            ],
            [
              -83.583132,
              37.294827
            ],
            [
              -83.565566,
              37.275716
            ],
            [
              -83.567509,
              37.267539
            ],
            [
              -83.541436,
              37.267791
            ],
            [
              -83.526907,
              37.258529
            ],
            [
              -83.521254,
              37.272913
            ],
            [
              -83.551777,
              37.287218
            ],
            [
              -83.547628,
              37.334418
            ],
            [
              -83.534059,
              37.352795
            ],
            [
              -83.545212,
              37.362053
            ],
            [
              -83.549201,
              37.394142
            ],
            [
              -83.534281,
              37.417409
            ],
            [
              -83.549734,
              37.445623
            ],
            [
              -83.579186,
              37.506249
            ],
            [
              -83.607624,
              37.514836
            ],
            [
              -83.622381,
              37.510775
            ],
            [
              -83.635706,
              37.540011
            ],
            [
              -83.657152,
              37.536781
            ],
            [
              -83.680439,
              37.511984
            ],
            [
              -83.701721,
              37.507705
            ],
            [
              -83.731864,
              37.521158
            ],
            [
              -83.745032,
              37.50791
            ],
            [
              -83.742984,
              37.494253
            ],
            [
              -83.82934,
              37.486821
            ],
            [
              -83.866808,
              37.505083
            ],
            [
              -83.866305,
              37.51365
            ],
            [
              -83.885262,
              37.517688
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "109",
      "COUNTYNS": "00516901",
      "AFFGEOID": "0500000US21109",
      "GEOID": "21109",
      "NAME": "Jackson",
      "LSAD": "06",
      "ALAND": 894055741,
      "AWATER": 3407438,
      "County_state": "Jackson,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.205306,
              37.503062
            ],
            [
              -84.203552,
              37.486177
            ],
            [
              -84.189256,
              37.456842
            ],
            [
              -84.169547,
              37.426225
            ],
            [
              -84.159608,
              37.417791
            ],
            [
              -84.169481,
              37.385406
            ],
            [
              -84.12913,
              37.357835
            ],
            [
              -84.142928,
              37.35262
            ],
            [
              -84.137898,
              37.319759
            ],
            [
              -84.120756,
              37.335892
            ],
            [
              -84.108069,
              37.326554
            ],
            [
              -84.107254,
              37.308072
            ],
            [
              -84.076297,
              37.287769
            ],
            [
              -84.048486,
              37.281492
            ],
            [
              -84.050439,
              37.268075
            ],
            [
              -84.005606,
              37.253343
            ],
            [
              -83.943718,
              37.250551
            ],
            [
              -83.921797,
              37.261803
            ],
            [
              -83.901476,
              37.25382
            ],
            [
              -83.899891,
              37.266795
            ],
            [
              -83.876838,
              37.294652
            ],
            [
              -83.88198,
              37.306932
            ],
            [
              -83.863192,
              37.306571
            ],
            [
              -83.821693,
              37.331255
            ],
            [
              -83.783761,
              37.334733
            ],
            [
              -83.784975,
              37.348717
            ],
            [
              -83.81792,
              37.382216
            ],
            [
              -83.849961,
              37.447848
            ],
            [
              -83.865122,
              37.451378
            ],
            [
              -83.883953,
              37.477335
            ],
            [
              -83.885262,
              37.517688
            ],
            [
              -83.893514,
              37.531119
            ],
            [
              -83.90557,
              37.542856
            ],
            [
              -83.939846,
              37.573173
            ],
            [
              -83.965244,
              37.581421
            ],
            [
              -84.045023,
              37.563573
            ],
            [
              -84.090364,
              37.566227
            ],
            [
              -84.142063,
              37.564839
            ],
            [
              -84.171836,
              37.557549
            ],
            [
              -84.199489,
              37.522858
            ],
            [
              -84.205306,
              37.503062
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "235",
      "COUNTYNS": "00516964",
      "AFFGEOID": "0500000US21235",
      "GEOID": "21235",
      "NAME": "Whitley",
      "LSAD": "06",
      "ALAND": 1133976430,
      "AWATER": 18875138,
      "County_state": "Whitley,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.345228,
              36.821558
            ],
            [
              -84.355967,
              36.811019
            ],
            [
              -84.345141,
              36.804705
            ],
            [
              -84.350006,
              36.780681
            ],
            [
              -84.33758,
              36.774401
            ],
            [
              -84.332261,
              36.760304
            ],
            [
              -84.322652,
              36.758669
            ],
            [
              -84.319769,
              36.709756
            ],
            [
              -84.307598,
              36.676809
            ],
            [
              -84.271529,
              36.63395
            ],
            [
              -84.239598,
              36.613354
            ],
            [
              -84.2271936534631,
              36.5921799356786
            ],
            [
              -84.127503,
              36.591413
            ],
            [
              -83.987842,
              36.5896
            ],
            [
              -83.9876109506828,
              36.589592286072495
            ],
            [
              -83.9307607839439,
              36.5876942581124
            ],
            [
              -83.924712,
              36.602673
            ],
            [
              -83.956319,
              36.645433
            ],
            [
              -83.878615,
              36.680335
            ],
            [
              -83.877503,
              36.687259
            ],
            [
              -83.958534,
              36.727245
            ],
            [
              -83.95967,
              36.749377
            ],
            [
              -83.968546,
              36.760923
            ],
            [
              -83.986771,
              36.759032
            ],
            [
              -83.976791,
              36.778962
            ],
            [
              -83.983674,
              36.800839
            ],
            [
              -83.99964,
              36.800858
            ],
            [
              -84.000494,
              36.811286
            ],
            [
              -84.027514,
              36.818644
            ],
            [
              -84.043728,
              36.839726
            ],
            [
              -84.039508,
              36.848694
            ],
            [
              -84.05261,
              36.855665
            ],
            [
              -84.065833,
              36.854147
            ],
            [
              -84.070446,
              36.890592
            ],
            [
              -84.085362,
              36.904821
            ],
            [
              -84.08042,
              36.926967
            ],
            [
              -84.091853,
              36.955992
            ],
            [
              -84.101851,
              36.954313
            ],
            [
              -84.123994,
              36.970187
            ],
            [
              -84.133853,
              36.968931
            ],
            [
              -84.160847,
              36.947737
            ],
            [
              -84.183131,
              36.939369
            ],
            [
              -84.190204,
              36.947713
            ],
            [
              -84.209091,
              36.947178
            ],
            [
              -84.222806,
              36.960089
            ],
            [
              -84.234196,
              36.941132
            ],
            [
              -84.246431,
              36.947283
            ],
            [
              -84.257947,
              36.953942
            ],
            [
              -84.256476,
              36.972864
            ],
            [
              -84.263562,
              36.962326
            ],
            [
              -84.297416,
              36.945931
            ],
            [
              -84.310575,
              36.932218
            ],
            [
              -84.304105,
              36.912459
            ],
            [
              -84.315851,
              36.905425
            ],
            [
              -84.322151,
              36.890819
            ],
            [
              -84.315798,
              36.878897
            ],
            [
              -84.324957,
              36.871184
            ],
            [
              -84.314872,
              36.859556
            ],
            [
              -84.344752,
              36.837492
            ],
            [
              -84.318676,
              36.832628
            ],
            [
              -84.345228,
              36.821558
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "093",
      "COUNTYNS": "00516893",
      "AFFGEOID": "0500000US21093",
      "GEOID": "21093",
      "NAME": "Hardin",
      "LSAD": "06",
      "ALAND": 1614331601,
      "AWATER": 17701432,
      "County_state": "Hardin,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.27527,
              37.593519
            ],
            [
              -86.268132,
              37.584631
            ],
            [
              -86.247962,
              37.594187
            ],
            [
              -86.246169,
              37.601937
            ],
            [
              -86.232505,
              37.574988
            ],
            [
              -86.2014,
              37.5745
            ],
            [
              -86.17619,
              37.554808
            ],
            [
              -86.166033,
              37.571496
            ],
            [
              -86.15926,
              37.56334
            ],
            [
              -86.114261,
              37.567182
            ],
            [
              -86.05427,
              37.496002
            ],
            [
              -86.061552,
              37.489895
            ],
            [
              -86.04932,
              37.47688
            ],
            [
              -86.060297,
              37.472536
            ],
            [
              -86.06744,
              37.457928
            ],
            [
              -86.047598,
              37.449933
            ],
            [
              -86.002156,
              37.447141
            ],
            [
              -85.892804,
              37.440308
            ],
            [
              -85.894419,
              37.472374
            ],
            [
              -85.875807,
              37.543997
            ],
            [
              -85.838629,
              37.568731
            ],
            [
              -85.820885,
              37.574071
            ],
            [
              -85.819258,
              37.584407
            ],
            [
              -85.797304,
              37.608075
            ],
            [
              -85.801934,
              37.623303
            ],
            [
              -85.775059,
              37.630855
            ],
            [
              -85.776346,
              37.638647
            ],
            [
              -85.735949,
              37.654598
            ],
            [
              -85.689768,
              37.719288
            ],
            [
              -85.680488,
              37.732284
            ],
            [
              -85.676171,
              37.733657
            ],
            [
              -85.687539,
              37.754961
            ],
            [
              -85.728133,
              37.774178
            ],
            [
              -85.740523,
              37.789243
            ],
            [
              -85.737777,
              37.81179
            ],
            [
              -85.742184,
              37.821871
            ],
            [
              -85.760574,
              37.83343
            ],
            [
              -85.785555,
              37.842896
            ],
            [
              -85.803147,
              37.860016
            ],
            [
              -85.817337,
              37.858261
            ],
            [
              -85.838198,
              37.879284
            ],
            [
              -85.833944,
              37.892696
            ],
            [
              -85.813353,
              37.889622
            ],
            [
              -85.82746,
              37.900631
            ],
            [
              -85.830169,
              37.918752
            ],
            [
              -85.844886,
              37.921718
            ],
            [
              -85.854871,
              37.945385
            ],
            [
              -85.879827,
              37.94851
            ],
            [
              -85.894233,
              37.940084
            ],
            [
              -85.905177,
              37.942579
            ],
            [
              -85.900765,
              37.969537
            ],
            [
              -85.907437,
              37.991586
            ],
            [
              -85.935378,
              37.996431
            ],
            [
              -85.938321,
              37.998756
            ],
            [
              -85.9456286311378,
              38.008211004408395
            ],
            [
              -85.9480303121263,
              38.0071402282467
            ],
            [
              -85.951467,
              38.005608
            ],
            [
              -85.958299,
              38.004616
            ],
            [
              -85.976028,
              38.00356
            ],
            [
              -85.996582,
              38.000073
            ],
            [
              -85.99896743483329,
              37.999779408020494
            ],
            [
              -85.996629,
              37.987442
            ],
            [
              -85.982331,
              37.91025
            ],
            [
              -85.975948,
              37.875793
            ],
            [
              -85.985496,
              37.858401
            ],
            [
              -86.007432,
              37.818439
            ],
            [
              -86.151285,
              37.798878
            ],
            [
              -86.182808,
              37.746386
            ],
            [
              -86.27527,
              37.593519
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "027",
      "COUNTYNS": "00516860",
      "AFFGEOID": "0500000US21027",
      "GEOID": "21027",
      "NAME": "Breckinridge",
      "LSAD": "06",
      "ALAND": 1469049378,
      "AWATER": 47701854,
      "County_state": "Breckinridge,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.666088,
              37.835803
            ],
            [
              -86.677873,
              37.792848
            ],
            [
              -86.622316,
              37.655008
            ],
            [
              -86.638935,
              37.661877
            ],
            [
              -86.64064,
              37.619069
            ],
            [
              -86.620226,
              37.587105
            ],
            [
              -86.59281,
              37.56436
            ],
            [
              -86.569039,
              37.58145
            ],
            [
              -86.575022,
              37.599645
            ],
            [
              -86.552372,
              37.595848
            ],
            [
              -86.551459,
              37.588426
            ],
            [
              -86.534827,
              37.58987
            ],
            [
              -86.523412,
              37.60151
            ],
            [
              -86.541495,
              37.60019
            ],
            [
              -86.536391,
              37.613487
            ],
            [
              -86.511631,
              37.622924
            ],
            [
              -86.470263,
              37.599996
            ],
            [
              -86.47504,
              37.590372
            ],
            [
              -86.454987,
              37.591679
            ],
            [
              -86.447179,
              37.576566
            ],
            [
              -86.425135,
              37.580513
            ],
            [
              -86.409744,
              37.566514
            ],
            [
              -86.387911,
              37.567016
            ],
            [
              -86.374581,
              37.558906
            ],
            [
              -86.34623,
              37.562718
            ],
            [
              -86.352694,
              37.586974
            ],
            [
              -86.343081,
              37.594624
            ],
            [
              -86.316675,
              37.586641
            ],
            [
              -86.27527,
              37.593519
            ],
            [
              -86.182808,
              37.746386
            ],
            [
              -86.151285,
              37.798878
            ],
            [
              -86.209141,
              37.845874
            ],
            [
              -86.242777,
              37.877002
            ],
            [
              -86.429137,
              38.000123
            ],
            [
              -86.4907693787391,
              38.045672484270696
            ],
            [
              -86.500051,
              38.045757
            ],
            [
              -86.51176,
              38.044448
            ],
            [
              -86.517289,
              38.042634
            ],
            [
              -86.519404,
              38.041241
            ],
            [
              -86.521825,
              38.038327
            ],
            [
              -86.524969,
              38.027879
            ],
            [
              -86.524385,
              38.018609
            ],
            [
              -86.524656,
              38.012894
            ],
            [
              -86.525671,
              38.007145
            ],
            [
              -86.525844,
              37.998385
            ],
            [
              -86.524888,
              37.981834
            ],
            [
              -86.525174,
              37.968228
            ],
            [
              -86.523831,
              37.962169
            ],
            [
              -86.520503,
              37.954438
            ],
            [
              -86.518575,
              37.951798
            ],
            [
              -86.512588,
              37.94695
            ],
            [
              -86.50939,
              37.942492
            ],
            [
              -86.507043,
              37.936439
            ],
            [
              -86.50662,
              37.930719
            ],
            [
              -86.507831,
              37.928829
            ],
            [
              -86.511005,
              37.92612
            ],
            [
              -86.51924,
              37.922163
            ],
            [
              -86.528279,
              37.918618
            ],
            [
              -86.534156,
              37.917007
            ],
            [
              -86.540722,
              37.916871
            ],
            [
              -86.548507,
              37.917842
            ],
            [
              -86.566256,
              37.922164
            ],
            [
              -86.580322,
              37.923145
            ],
            [
              -86.586542,
              37.922285
            ],
            [
              -86.588581,
              37.921159
            ],
            [
              -86.596125,
              37.914289
            ],
            [
              -86.598452,
              37.910965
            ],
            [
              -86.599848,
              37.906754
            ],
            [
              -86.600096,
              37.901218
            ],
            [
              -86.598151,
              37.884553
            ],
            [
              -86.598317,
              37.88042
            ],
            [
              -86.59939,
              37.874753
            ],
            [
              -86.597476,
              37.871478
            ],
            [
              -86.59732,
              37.870162
            ],
            [
              -86.598108,
              37.867382
            ],
            [
              -86.604624,
              37.858272
            ],
            [
              -86.609163,
              37.855408
            ],
            [
              -86.615215,
              37.852857
            ],
            [
              -86.625763,
              37.847266
            ],
            [
              -86.634271,
              37.843845
            ],
            [
              -86.638265,
              37.842718
            ],
            [
              -86.648028,
              37.841425
            ],
            [
              -86.652516,
              37.841636
            ],
            [
              -86.65528645526759,
              37.842505006112695
            ],
            [
              -86.666088,
              37.835803
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "225",
      "COUNTYNS": "00516959",
      "AFFGEOID": "0500000US21225",
      "GEOID": "21225",
      "NAME": "Union",
      "LSAD": "06",
      "ALAND": 887974689,
      "AWATER": 53167480,
      "County_state": "Union,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.160187,
              37.657592
            ],
            [
              -88.160062,
              37.654332
            ],
            [
              -88.15864,
              37.649097
            ],
            [
              -88.158374,
              37.639948
            ],
            [
              -88.156827,
              37.632801
            ],
            [
              -88.152691,
              37.622827
            ],
            [
              -88.142225,
              37.603737
            ],
            [
              -88.140752,
              37.599158
            ],
            [
              -88.140226,
              37.595263
            ],
            [
              -88.14094,
              37.590865
            ],
            [
              -88.139973,
              37.586451
            ],
            [
              -88.136164,
              37.580285
            ],
            [
              -88.133410402099,
              37.5742729944782
            ],
            [
              -88.133393,
              37.574235
            ],
            [
              -88.131622,
              37.572968
            ],
            [
              -88.121517,
              37.568166
            ],
            [
              -88.11433,
              37.562189
            ],
            [
              -88.105585,
              37.55618
            ],
            [
              -88.101174,
              37.55133
            ],
            [
              -88.092814,
              37.539637
            ],
            [
              -88.088049,
              37.535124
            ],
            [
              -88.086194,
              37.534186
            ],
            [
              -88.078046,
              37.532029
            ],
            [
              -88.072242,
              37.528826
            ],
            [
              -88.069018,
              37.525297
            ],
            [
              -88.063311,
              37.515755
            ],
            [
              -88.062568,
              37.513563
            ],
            [
              -88.062828,
              37.508123
            ],
            [
              -88.061342239857,
              37.505326559522494
            ],
            [
              -88.047489,
              37.518272
            ],
            [
              -88.034092,
              37.513602
            ],
            [
              -88.02106,
              37.52853
            ],
            [
              -88.015572,
              37.54721
            ],
            [
              -87.998811,
              37.538583
            ],
            [
              -87.996918,
              37.506268
            ],
            [
              -87.980556,
              37.49396
            ],
            [
              -87.971977,
              37.499299
            ],
            [
              -87.966496,
              37.484621
            ],
            [
              -87.982807,
              37.474208
            ],
            [
              -87.961488,
              37.472031
            ],
            [
              -87.955182,
              37.47893
            ],
            [
              -87.932952,
              37.480052
            ],
            [
              -87.803653,
              37.579461
            ],
            [
              -87.734545,
              37.638376
            ],
            [
              -87.754488,
              37.66939
            ],
            [
              -87.750952,
              37.678162
            ],
            [
              -87.755293,
              37.728555
            ],
            [
              -87.766038,
              37.749092
            ],
            [
              -87.76386,
              37.756557
            ],
            [
              -87.773438,
              37.76666
            ],
            [
              -87.778968,
              37.754255
            ],
            [
              -87.799262,
              37.760487
            ],
            [
              -87.79203,
              37.770295
            ],
            [
              -87.811087,
              37.771188
            ],
            [
              -87.815093,
              37.762423
            ],
            [
              -87.9270921983773,
              37.9017059445802
            ],
            [
              -87.927769,
              37.900924
            ],
            [
              -87.932129,
              37.89732
            ],
            [
              -87.936784,
              37.892587
            ],
            [
              -87.938365,
              37.890802
            ],
            [
              -87.940069,
              37.88767
            ],
            [
              -87.940839,
              37.883338
            ],
            [
              -87.941021,
              37.879168
            ],
            [
              -87.940005,
              37.875044
            ],
            [
              -87.938128,
              37.870651
            ],
            [
              -87.936228,
              37.867937
            ],
            [
              -87.927303,
              37.858709
            ],
            [
              -87.914892,
              37.849618
            ],
            [
              -87.910276,
              37.843416
            ],
            [
              -87.907773,
              37.837611
            ],
            [
              -87.903804,
              37.817762
            ],
            [
              -87.904595,
              37.812526
            ],
            [
              -87.90681,
              37.807624
            ],
            [
              -87.911087,
              37.805158
            ],
            [
              -87.919138,
              37.802128
            ],
            [
              -87.927543,
              37.799851
            ],
            [
              -87.932554,
              37.797672
            ],
            [
              -87.934936,
              37.79522
            ],
            [
              -87.934698,
              37.791827
            ],
            [
              -87.935861,
              37.789703
            ],
            [
              -87.938598,
              37.787914
            ],
            [
              -87.944506,
              37.775256
            ],
            [
              -87.946463,
              37.773477
            ],
            [
              -87.948594,
              37.772344
            ],
            [
              -87.95259,
              37.771742
            ],
            [
              -87.96003,
              37.773223
            ],
            [
              -87.970262,
              37.781856
            ],
            [
              -87.971805,
              37.784648
            ],
            [
              -87.976389,
              37.788004
            ],
            [
              -87.984358,
              37.7918
            ],
            [
              -87.987157,
              37.792202
            ],
            [
              -87.991168,
              37.794049
            ],
            [
              -87.993099,
              37.795756
            ],
            [
              -87.997102,
              37.797672
            ],
            [
              -88.004706,
              37.800145
            ],
            [
              -88.015144,
              37.80193
            ],
            [
              -88.021021,
              37.801409
            ],
            [
              -88.02803,
              37.799224
            ],
            [
              -88.032438,
              37.796361
            ],
            [
              -88.035827,
              37.791917
            ],
            [
              -88.038769,
              37.784307
            ],
            [
              -88.040873,
              37.772082
            ],
            [
              -88.042602,
              37.76712
            ],
            [
              -88.049942,
              37.754078
            ],
            [
              -88.059588,
              37.742608
            ],
            [
              -88.063802,
              37.738645
            ],
            [
              -88.072538,
              37.733286
            ],
            [
              -88.081925,
              37.730389
            ],
            [
              -88.085901,
              37.728587
            ],
            [
              -88.095759,
              37.723205
            ],
            [
              -88.101844,
              37.718036
            ],
            [
              -88.107088,
              37.715915
            ],
            [
              -88.117803,
              37.712583
            ],
            [
              -88.122412,
              37.709685
            ],
            [
              -88.125033,
              37.707094
            ],
            [
              -88.132341,
              37.697142
            ],
            [
              -88.134282,
              37.691498
            ],
            [
              -88.145434,
              37.68259
            ],
            [
              -88.151646,
              37.675098
            ],
            [
              -88.158207,
              37.664542
            ],
            [
              -88.159372,
              37.661847
            ],
            [
              -88.160187,
              37.657592
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "231",
      "COUNTYNS": "00516962",
      "AFFGEOID": "0500000US21231",
      "GEOID": "21231",
      "NAME": "Wayne",
      "LSAD": "06",
      "ALAND": 1186687833,
      "AWATER": 67286710,
      "County_state": "Wayne,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.064307,
              36.858743
            ],
            [
              -85.059167,
              36.833097
            ],
            [
              -85.026377,
              36.806912
            ],
            [
              -85.004099,
              36.756237
            ],
            [
              -85.019998,
              36.717945
            ],
            [
              -85.018502,
              36.689872
            ],
            [
              -85.001267,
              36.67674
            ],
            [
              -85.009305,
              36.668287
            ],
            [
              -84.998144,
              36.663097
            ],
            [
              -85.004388,
              36.642899
            ],
            [
              -84.999106,
              36.624161
            ],
            [
              -84.9748750576222,
              36.6155518237457
            ],
            [
              -84.943948,
              36.612569
            ],
            [
              -84.859738,
              36.606495
            ],
            [
              -84.859759,
              36.606428
            ],
            [
              -84.843091,
              36.605127
            ],
            [
              -84.803744,
              36.604265
            ],
            [
              -84.78539955660389,
              36.60337484144149
            ],
            [
              -84.785341,
              36.603372
            ],
            [
              -84.7784559169908,
              36.6032090178664
            ],
            [
              -84.673964,
              36.739542
            ],
            [
              -84.674035,
              36.761265
            ],
            [
              -84.634178,
              36.769233
            ],
            [
              -84.62256,
              36.793933
            ],
            [
              -84.5962,
              36.799852
            ],
            [
              -84.599206,
              36.808628
            ],
            [
              -84.581678,
              36.804039
            ],
            [
              -84.577734,
              36.824952
            ],
            [
              -84.595423,
              36.837267
            ],
            [
              -84.573833,
              36.844288
            ],
            [
              -84.591673,
              36.862507
            ],
            [
              -84.578158,
              36.867133
            ],
            [
              -84.589926,
              36.88259
            ],
            [
              -84.582434,
              36.892521
            ],
            [
              -84.600842,
              36.879659
            ],
            [
              -84.620241,
              36.896366
            ],
            [
              -84.67462,
              36.974372
            ],
            [
              -84.707138,
              36.980967
            ],
            [
              -84.730741,
              36.966542
            ],
            [
              -84.770172,
              36.957735
            ],
            [
              -84.783794,
              36.978483
            ],
            [
              -84.808563,
              36.987609
            ],
            [
              -84.835712,
              36.997612
            ],
            [
              -84.907859,
              36.945447
            ],
            [
              -84.921475,
              36.956844
            ],
            [
              -84.945527,
              36.959747
            ],
            [
              -84.950428,
              36.955467
            ],
            [
              -84.960878,
              36.916338
            ],
            [
              -85.018063,
              36.887309
            ],
            [
              -85.064307,
              36.858743
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "211",
      "COUNTYNS": "00516952",
      "AFFGEOID": "0500000US21211",
      "GEOID": "21211",
      "NAME": "Shelby",
      "LSAD": "06",
      "ALAND": 983235165,
      "AWATER": 15627712,
      "County_state": "Shelby,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.448123,
              38.304042
            ],
            [
              -85.468206,
              38.285309
            ],
            [
              -85.411364,
              38.26592
            ],
            [
              -85.404899,
              38.26373
            ],
            [
              -85.424054,
              38.147438
            ],
            [
              -85.232406,
              38.089431
            ],
            [
              -85.196117,
              38.074822
            ],
            [
              -85.101646,
              38.03727
            ],
            [
              -85.083616,
              38.040876
            ],
            [
              -85.071682,
              38.057753
            ],
            [
              -85.047783,
              38.072341
            ],
            [
              -85.054538,
              38.084223
            ],
            [
              -85.039653,
              38.119313
            ],
            [
              -85.023711,
              38.129052
            ],
            [
              -85.019997,
              38.141523
            ],
            [
              -85.003837,
              38.16066
            ],
            [
              -85.00748,
              38.17578
            ],
            [
              -85.001032,
              38.226152
            ],
            [
              -84.998898,
              38.274979
            ],
            [
              -84.956959,
              38.332271
            ],
            [
              -84.997667,
              38.335586
            ],
            [
              -85.283082,
              38.358054
            ],
            [
              -85.324453,
              38.310112
            ],
            [
              -85.351805,
              38.303061
            ],
            [
              -85.386096,
              38.308039
            ],
            [
              -85.411536,
              38.294004
            ],
            [
              -85.419126,
              38.303134
            ],
            [
              -85.426909,
              38.292823
            ],
            [
              -85.448123,
              38.304042
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "227",
      "COUNTYNS": "00516960",
      "AFFGEOID": "0500000US21227",
      "GEOID": "21227",
      "NAME": "Warren",
      "LSAD": "06",
      "ALAND": 1402737947,
      "AWATER": 15447169,
      "County_state": "Warren,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.666739,
              37.019863
            ],
            [
              -86.674462,
              36.999766
            ],
            [
              -86.616457,
              36.892156
            ],
            [
              -86.611586,
              36.883057
            ],
            [
              -86.603976,
              36.868922
            ],
            [
              -86.583289,
              36.830282
            ],
            [
              -86.400518,
              36.807226
            ],
            [
              -86.405769,
              36.776187
            ],
            [
              -86.330947,
              36.82714
            ],
            [
              -86.275191,
              36.865109
            ],
            [
              -86.263405,
              36.873134
            ],
            [
              -86.264111,
              36.876301
            ],
            [
              -86.262657,
              36.884425
            ],
            [
              -86.268776,
              36.887279
            ],
            [
              -86.268273,
              36.892071
            ],
            [
              -86.235608,
              36.897693
            ],
            [
              -86.20703,
              36.907946
            ],
            [
              -86.204176,
              36.933784
            ],
            [
              -86.189027,
              36.921677
            ],
            [
              -86.16674,
              36.934015
            ],
            [
              -86.141655,
              36.993894
            ],
            [
              -86.113512,
              37.061072
            ],
            [
              -86.192246,
              37.076696
            ],
            [
              -86.218607,
              37.104029
            ],
            [
              -86.241809,
              37.097005
            ],
            [
              -86.256493,
              37.085593
            ],
            [
              -86.28175,
              37.080597
            ],
            [
              -86.399165,
              37.169896
            ],
            [
              -86.408789,
              37.167607
            ],
            [
              -86.410456,
              37.152111
            ],
            [
              -86.442817,
              37.165555
            ],
            [
              -86.44925,
              37.151065
            ],
            [
              -86.466544,
              37.152681
            ],
            [
              -86.484593,
              37.18188
            ],
            [
              -86.497187,
              37.190387
            ],
            [
              -86.541738,
              37.175919
            ],
            [
              -86.563301,
              37.180208
            ],
            [
              -86.562539,
              37.187139
            ],
            [
              -86.623216,
              37.180614
            ],
            [
              -86.613223,
              37.164599
            ],
            [
              -86.614147,
              37.162155
            ],
            [
              -86.614334,
              37.16063
            ],
            [
              -86.614327,
              37.159057
            ],
            [
              -86.614073,
              37.157839
            ],
            [
              -86.613196,
              37.155744
            ],
            [
              -86.613016,
              37.154112
            ],
            [
              -86.613395,
              37.152915
            ],
            [
              -86.614612,
              37.15103
            ],
            [
              -86.61608,
              37.149924
            ],
            [
              -86.617188,
              37.149492
            ],
            [
              -86.618743,
              37.148616
            ],
            [
              -86.619007,
              37.147567
            ],
            [
              -86.618102,
              37.146766
            ],
            [
              -86.616823,
              37.146724
            ],
            [
              -86.666739,
              37.019863
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "203",
      "COUNTYNS": "00516948",
      "AFFGEOID": "0500000US21203",
      "GEOID": "21203",
      "NAME": "Rockcastle",
      "LSAD": "06",
      "ALAND": 819848737,
      "AWATER": 4016362,
      "County_state": "Rockcastle,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.446719,
              37.485893
            ],
            [
              -84.502932,
              37.328801
            ],
            [
              -84.446286,
              37.27905
            ],
            [
              -84.39688,
              37.241711
            ],
            [
              -84.300475,
              37.16394
            ],
            [
              -84.291795,
              37.160554
            ],
            [
              -84.289076,
              37.15174
            ],
            [
              -84.2888,
              37.162061
            ],
            [
              -84.300166,
              37.169642
            ],
            [
              -84.296793,
              37.171337
            ],
            [
              -84.265629,
              37.181708
            ],
            [
              -84.280989,
              37.197471
            ],
            [
              -84.258941,
              37.205821
            ],
            [
              -84.271264,
              37.211562
            ],
            [
              -84.262828,
              37.217912
            ],
            [
              -84.254315,
              37.239082
            ],
            [
              -84.24103,
              37.228586
            ],
            [
              -84.229731,
              37.247926
            ],
            [
              -84.217827,
              37.242432
            ],
            [
              -84.216241,
              37.280256
            ],
            [
              -84.200824,
              37.280196
            ],
            [
              -84.178782,
              37.299279
            ],
            [
              -84.14855,
              37.302452
            ],
            [
              -84.137898,
              37.319759
            ],
            [
              -84.142928,
              37.35262
            ],
            [
              -84.12913,
              37.357835
            ],
            [
              -84.169481,
              37.385406
            ],
            [
              -84.159608,
              37.417791
            ],
            [
              -84.169547,
              37.426225
            ],
            [
              -84.189256,
              37.456842
            ],
            [
              -84.203552,
              37.486177
            ],
            [
              -84.205306,
              37.503062
            ],
            [
              -84.199489,
              37.522858
            ],
            [
              -84.22422,
              37.527189
            ],
            [
              -84.268112,
              37.51531
            ],
            [
              -84.301932,
              37.523431
            ],
            [
              -84.303929,
              37.537424
            ],
            [
              -84.347805,
              37.539064
            ],
            [
              -84.360894,
              37.515732
            ],
            [
              -84.355538,
              37.49794
            ],
            [
              -84.37444,
              37.472663
            ],
            [
              -84.446719,
              37.485893
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "091",
      "COUNTYNS": "00516892",
      "AFFGEOID": "0500000US21091",
      "GEOID": "21091",
      "NAME": "Hancock",
      "LSAD": "06",
      "ALAND": 486020462,
      "AWATER": 29093100,
      "County_state": "Hancock,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.973912,
              37.918355
            ],
            [
              -86.987296,
              37.897213
            ],
            [
              -86.958521,
              37.897976
            ],
            [
              -86.931951,
              37.909727
            ],
            [
              -86.91916,
              37.906032
            ],
            [
              -86.909708,
              37.886003
            ],
            [
              -86.893859,
              37.882113
            ],
            [
              -86.908544,
              37.869282
            ],
            [
              -86.912531,
              37.837481
            ],
            [
              -86.893873,
              37.828673
            ],
            [
              -86.886527,
              37.83626
            ],
            [
              -86.854035,
              37.840811
            ],
            [
              -86.842495,
              37.819921
            ],
            [
              -86.8171,
              37.791589
            ],
            [
              -86.823429,
              37.737754
            ],
            [
              -86.638935,
              37.661877
            ],
            [
              -86.622316,
              37.655008
            ],
            [
              -86.677873,
              37.792848
            ],
            [
              -86.666088,
              37.835803
            ],
            [
              -86.65528645526759,
              37.842505006112695
            ],
            [
              -86.655296,
              37.842508
            ],
            [
              -86.658268,
              37.844144
            ],
            [
              -86.661637,
              37.849714
            ],
            [
              -86.662495,
              37.856951
            ],
            [
              -86.661233,
              37.862761
            ],
            [
              -86.658374,
              37.869376
            ],
            [
              -86.648727,
              37.886036
            ],
            [
              -86.644754,
              37.894806
            ],
            [
              -86.644039,
              37.898202
            ],
            [
              -86.644143,
              37.902366
            ],
            [
              -86.645513,
              37.906529
            ],
            [
              -86.647081,
              37.908621
            ],
            [
              -86.650087,
              37.910616
            ],
            [
              -86.660888,
              37.913059
            ],
            [
              -86.673038,
              37.914903
            ],
            [
              -86.680929,
              37.91501
            ],
            [
              -86.686015,
              37.913084
            ],
            [
              -86.691994,
              37.908529
            ],
            [
              -86.707816,
              37.898367
            ],
            [
              -86.716138,
              37.894073
            ],
            [
              -86.718462,
              37.893123
            ],
            [
              -86.722247,
              37.892648
            ],
            [
              -86.73146,
              37.89434
            ],
            [
              -86.734718,
              37.896587
            ],
            [
              -86.75099,
              37.912893
            ],
            [
              -86.765054,
              37.93251
            ],
            [
              -86.779993,
              37.956522
            ],
            [
              -86.788044,
              37.97284
            ],
            [
              -86.790597,
              37.980062
            ],
            [
              -86.794985,
              37.988982
            ],
            [
              -86.810913,
              37.99715
            ],
            [
              -86.81475580563179,
              37.9986742364093
            ],
            [
              -86.815267,
              37.998877
            ],
            [
              -86.820071,
              37.999392
            ],
            [
              -86.823491,
              37.998939
            ],
            [
              -86.835161,
              37.99375
            ],
            [
              -86.849027,
              37.99002
            ],
            [
              -86.85595,
              37.987292
            ],
            [
              -86.863224,
              37.982495
            ],
            [
              -86.866936,
              37.979294
            ],
            [
              -86.870388,
              37.975276
            ],
            [
              -86.875874,
              37.97077
            ],
            [
              -86.881338,
              37.967523
            ],
            [
              -86.884961,
              37.964373
            ],
            [
              -86.892084,
              37.955929
            ],
            [
              -86.902413,
              37.946161
            ],
            [
              -86.907131,
              37.943023
            ],
            [
              -86.90726731730089,
              37.9429519357504
            ],
            [
              -86.919329,
              37.936664
            ],
            [
              -86.927747,
              37.934956
            ],
            [
              -86.933357,
              37.934939
            ],
            [
              -86.944633,
              37.933534
            ],
            [
              -86.964785,
              37.932384
            ],
            [
              -86.969044,
              37.932858
            ],
            [
              -86.9788342958521,
              37.9302329010012
            ],
            [
              -86.973912,
              37.918355
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "045",
      "COUNTYNS": "00516869",
      "AFFGEOID": "0500000US21045",
      "GEOID": "21045",
      "NAME": "Casey",
      "LSAD": "06",
      "ALAND": 1150571810,
      "AWATER": 3892620,
      "County_state": "Casey,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.131241,
              37.365062
            ],
            [
              -85.165174,
              37.310396
            ],
            [
              -85.112093,
              37.28209
            ],
            [
              -85.056898,
              37.257041
            ],
            [
              -85.053565,
              37.196746
            ],
            [
              -85.043316,
              37.186028
            ],
            [
              -84.970624,
              37.113045
            ],
            [
              -84.954406,
              37.102271
            ],
            [
              -84.916557,
              37.107281
            ],
            [
              -84.901112,
              37.116297
            ],
            [
              -84.811396,
              37.17371
            ],
            [
              -84.720712,
              37.238328
            ],
            [
              -84.716689,
              37.251782
            ],
            [
              -84.724165,
              37.286675
            ],
            [
              -84.706122,
              37.299835
            ],
            [
              -84.722826,
              37.360043
            ],
            [
              -84.796104,
              37.390556
            ],
            [
              -84.850816,
              37.427143
            ],
            [
              -84.86013,
              37.46735
            ],
            [
              -84.857086,
              37.528519
            ],
            [
              -84.847183,
              37.548455
            ],
            [
              -84.8981,
              37.532113
            ],
            [
              -85.002183,
              37.533628
            ],
            [
              -85.002408,
              37.548525
            ],
            [
              -85.039674,
              37.545232
            ],
            [
              -85.043508,
              37.500094
            ],
            [
              -85.044807,
              37.412038
            ],
            [
              -85.073772,
              37.413669
            ],
            [
              -85.087325,
              37.397667
            ],
            [
              -85.105924,
              37.397612
            ],
            [
              -85.131162,
              37.384265
            ],
            [
              -85.131241,
              37.365062
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "113",
      "COUNTYNS": "00516903",
      "AFFGEOID": "0500000US21113",
      "GEOID": "21113",
      "NAME": "Jessamine",
      "LSAD": "06",
      "ALAND": 445871152,
      "AWATER": 6166287,
      "County_state": "Jessamine,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.723423,
              37.832947
            ],
            [
              -84.716925,
              37.815389
            ],
            [
              -84.695715,
              37.827869
            ],
            [
              -84.675889,
              37.826242
            ],
            [
              -84.677209,
              37.790707
            ],
            [
              -84.656903,
              37.783921
            ],
            [
              -84.656608,
              37.809908
            ],
            [
              -84.616571,
              37.803971
            ],
            [
              -84.608486,
              37.792799
            ],
            [
              -84.626077,
              37.79703
            ],
            [
              -84.644749,
              37.788337
            ],
            [
              -84.638084,
              37.773544
            ],
            [
              -84.611903,
              37.766713
            ],
            [
              -84.604322,
              37.756673
            ],
            [
              -84.61621,
              37.74832
            ],
            [
              -84.606722,
              37.743078
            ],
            [
              -84.596423,
              37.75339
            ],
            [
              -84.585209,
              37.750613
            ],
            [
              -84.587227,
              37.73533
            ],
            [
              -84.571158,
              37.72855
            ],
            [
              -84.555375,
              37.749225
            ],
            [
              -84.538895,
              37.755518
            ],
            [
              -84.538199,
              37.767857
            ],
            [
              -84.525643,
              37.769497
            ],
            [
              -84.495962,
              37.785248
            ],
            [
              -84.482441,
              37.802141
            ],
            [
              -84.462031,
              37.803431
            ],
            [
              -84.485128,
              37.840287
            ],
            [
              -84.476283,
              37.853131
            ],
            [
              -84.446832,
              37.840397
            ],
            [
              -84.43572,
              37.847202
            ],
            [
              -84.434338,
              37.868119
            ],
            [
              -84.452614,
              37.90899
            ],
            [
              -84.471965,
              37.922538
            ],
            [
              -84.479629,
              37.940219
            ],
            [
              -84.522228,
              37.957715
            ],
            [
              -84.535625,
              37.96323
            ],
            [
              -84.570703,
              37.977587
            ],
            [
              -84.592312,
              37.986432
            ],
            [
              -84.604659,
              37.98958
            ],
            [
              -84.66034,
              38.003836
            ],
            [
              -84.709918,
              37.861952
            ],
            [
              -84.701888,
              37.860761
            ],
            [
              -84.689095,
              37.854796
            ],
            [
              -84.723423,
              37.832947
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "103",
      "COUNTYNS": "00516898",
      "AFFGEOID": "0500000US21103",
      "GEOID": "21103",
      "NAME": "Henry",
      "LSAD": "06",
      "ALAND": 741450757,
      "AWATER": 12552369,
      "County_state": "Henry,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.34608,
              38.45954
            ],
            [
              -85.331721,
              38.443049
            ],
            [
              -85.325072,
              38.403872
            ],
            [
              -85.283082,
              38.358054
            ],
            [
              -84.997667,
              38.335586
            ],
            [
              -84.906256,
              38.374836
            ],
            [
              -84.892357,
              38.3552
            ],
            [
              -84.870482,
              38.356751
            ],
            [
              -84.86835,
              38.368643
            ],
            [
              -84.894369,
              38.375308
            ],
            [
              -84.897591,
              38.389558
            ],
            [
              -84.880248,
              38.41618
            ],
            [
              -84.895832,
              38.424854
            ],
            [
              -84.924715,
              38.431176
            ],
            [
              -84.925023,
              38.441824
            ],
            [
              -84.909846,
              38.449384
            ],
            [
              -84.91223,
              38.459844
            ],
            [
              -84.922642,
              38.464538
            ],
            [
              -84.938189,
              38.450414
            ],
            [
              -84.939253,
              38.427797
            ],
            [
              -84.949835,
              38.42618
            ],
            [
              -84.963265,
              38.437358
            ],
            [
              -84.95181,
              38.456992
            ],
            [
              -84.956842,
              38.473646
            ],
            [
              -84.988781,
              38.487085
            ],
            [
              -84.992159,
              38.495293
            ],
            [
              -84.977166,
              38.512989
            ],
            [
              -84.985026,
              38.518405
            ],
            [
              -85.007173,
              38.513923
            ],
            [
              -85.017363,
              38.504751
            ],
            [
              -85.029888,
              38.510765
            ],
            [
              -85.002707,
              38.55212
            ],
            [
              -85.009185,
              38.561124
            ],
            [
              -85.04264,
              38.573862
            ],
            [
              -85.062141,
              38.564329
            ],
            [
              -85.076118,
              38.578895
            ],
            [
              -85.074583,
              38.596838
            ],
            [
              -85.16827,
              38.585448
            ],
            [
              -85.24994,
              38.52407
            ],
            [
              -85.279627,
              38.496268
            ],
            [
              -85.314006,
              38.492592
            ],
            [
              -85.34608,
              38.45954
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "057",
      "COUNTYNS": "00516875",
      "AFFGEOID": "0500000US21057",
      "GEOID": "21057",
      "NAME": "Cumberland",
      "LSAD": "06",
      "ALAND": 790416910,
      "AWATER": 14830126,
      "County_state": "Cumberland,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.601624,
              36.872196
            ],
            [
              -85.594208,
              36.857465
            ],
            [
              -85.574686,
              36.838241
            ],
            [
              -85.581216,
              36.822889
            ],
            [
              -85.596214,
              36.818039
            ],
            [
              -85.588751,
              36.777349
            ],
            [
              -85.57115,
              36.770883
            ],
            [
              -85.542513,
              36.774814
            ],
            [
              -85.517429,
              36.731326
            ],
            [
              -85.471021,
              36.733216
            ],
            [
              -85.496932,
              36.690718
            ],
            [
              -85.4364330342016,
              36.6185230953912
            ],
            [
              -85.430123,
              36.618952
            ],
            [
              -85.334124,
              36.622951
            ],
            [
              -85.324654,
              36.62455
            ],
            [
              -85.300402,
              36.624437
            ],
            [
              -85.296073,
              36.625824
            ],
            [
              -85.2959488756198,
              36.6258382676941
            ],
            [
              -85.246357,
              36.744294
            ],
            [
              -85.215405,
              36.854467
            ],
            [
              -85.236706,
              36.853334
            ],
            [
              -85.247832,
              36.877623
            ],
            [
              -85.27495,
              36.897901
            ],
            [
              -85.232026,
              36.925069
            ],
            [
              -85.45183,
              36.938138
            ],
            [
              -85.47422,
              36.893823
            ],
            [
              -85.507377,
              36.867509
            ],
            [
              -85.536982,
              36.862189
            ],
            [
              -85.560719,
              36.850857
            ],
            [
              -85.580531,
              36.868958
            ],
            [
              -85.601624,
              36.872196
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "107",
      "COUNTYNS": "00516900",
      "AFFGEOID": "0500000US21107",
      "GEOID": "21107",
      "NAME": "Hopkins",
      "LSAD": "06",
      "ALAND": 1403746106,
      "AWATER": 32182700,
      "County_state": "Hopkins,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.83202,
              37.339269
            ],
            [
              -87.850706,
              37.321643
            ],
            [
              -87.820689,
              37.300444
            ],
            [
              -87.820217,
              37.317932
            ],
            [
              -87.795808,
              37.319119
            ],
            [
              -87.807078,
              37.293649
            ],
            [
              -87.793339,
              37.291053
            ],
            [
              -87.799608,
              37.27982
            ],
            [
              -87.794352,
              37.255002
            ],
            [
              -87.805029,
              37.241083
            ],
            [
              -87.788947,
              37.232474
            ],
            [
              -87.771544,
              37.214127
            ],
            [
              -87.774694,
              37.198127
            ],
            [
              -87.763961,
              37.194889
            ],
            [
              -87.74797,
              37.209199
            ],
            [
              -87.746908,
              37.188599
            ],
            [
              -87.733335,
              37.193099
            ],
            [
              -87.729802,
              37.178319
            ],
            [
              -87.713683,
              37.17869
            ],
            [
              -87.705227,
              37.16105
            ],
            [
              -87.697442,
              37.161746
            ],
            [
              -87.680617,
              37.149239
            ],
            [
              -87.650087,
              37.147098
            ],
            [
              -87.62232,
              37.135242
            ],
            [
              -87.520116,
              37.105452
            ],
            [
              -87.487151,
              37.125901
            ],
            [
              -87.372285,
              37.1323
            ],
            [
              -87.356142,
              37.143588
            ],
            [
              -87.360305,
              37.15666
            ],
            [
              -87.333741,
              37.157186
            ],
            [
              -87.327041,
              37.17106
            ],
            [
              -87.311904,
              37.176482
            ],
            [
              -87.317096,
              37.186235
            ],
            [
              -87.306706,
              37.191836
            ],
            [
              -87.32451,
              37.196566
            ],
            [
              -87.334676,
              37.210988
            ],
            [
              -87.349044,
              37.214505
            ],
            [
              -87.34683,
              37.223474
            ],
            [
              -87.369146,
              37.238607
            ],
            [
              -87.364081,
              37.249
            ],
            [
              -87.388826,
              37.261395
            ],
            [
              -87.38703,
              37.273606
            ],
            [
              -87.378817,
              37.311997
            ],
            [
              -87.366757,
              37.318543
            ],
            [
              -87.346414,
              37.309536
            ],
            [
              -87.338221,
              37.335992
            ],
            [
              -87.322558,
              37.343876
            ],
            [
              -87.300984,
              37.374704
            ],
            [
              -87.309926,
              37.376523
            ],
            [
              -87.295965,
              37.391819
            ],
            [
              -87.311479,
              37.401873
            ],
            [
              -87.328543,
              37.398462
            ],
            [
              -87.32502,
              37.406242
            ],
            [
              -87.351089,
              37.424929
            ],
            [
              -87.361657,
              37.488052
            ],
            [
              -87.349404,
              37.518671
            ],
            [
              -87.366627,
              37.534845
            ],
            [
              -87.359639,
              37.544823
            ],
            [
              -87.375145,
              37.569988
            ],
            [
              -87.497112,
              37.487802
            ],
            [
              -87.593575,
              37.461963
            ],
            [
              -87.813413,
              37.350645
            ],
            [
              -87.814823,
              37.342608
            ],
            [
              -87.83202,
              37.339269
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "115",
      "COUNTYNS": "00516904",
      "AFFGEOID": "0500000US21115",
      "GEOID": "21115",
      "NAME": "Johnson",
      "LSAD": "06",
      "ALAND": 678468756,
      "AWATER": 5639297,
      "County_state": "Johnson,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.989217,
              37.869589
            ],
            [
              -83.004649,
              37.859247
            ],
            [
              -82.98152,
              37.835412
            ],
            [
              -82.989762,
              37.818181
            ],
            [
              -82.950582,
              37.794734
            ],
            [
              -82.940763,
              37.716338
            ],
            [
              -82.927258,
              37.719369
            ],
            [
              -82.900703,
              37.703312
            ],
            [
              -82.876262,
              37.719312
            ],
            [
              -82.870833,
              37.729141
            ],
            [
              -82.845244,
              37.729557
            ],
            [
              -82.785675,
              37.746058
            ],
            [
              -82.767106,
              37.741807
            ],
            [
              -82.763313,
              37.75612
            ],
            [
              -82.75223,
              37.756227
            ],
            [
              -82.739382,
              37.743954
            ],
            [
              -82.723691,
              37.749244
            ],
            [
              -82.708284,
              37.739517
            ],
            [
              -82.684956,
              37.735344
            ],
            [
              -82.687246,
              37.729582
            ],
            [
              -82.644577,
              37.7243
            ],
            [
              -82.64075,
              37.720182
            ],
            [
              -82.655719,
              37.752854
            ],
            [
              -82.653581,
              37.778005
            ],
            [
              -82.674419,
              37.78748
            ],
            [
              -82.664322,
              37.806806
            ],
            [
              -82.644807,
              37.822939
            ],
            [
              -82.670887,
              37.851823
            ],
            [
              -82.612393,
              37.879272
            ],
            [
              -82.676933,
              37.892328
            ],
            [
              -82.736583,
              37.916327
            ],
            [
              -82.831615,
              37.967884
            ],
            [
              -82.875218,
              37.977619
            ],
            [
              -82.931671,
              37.996558
            ],
            [
              -82.952043,
              38.000316
            ],
            [
              -82.970724,
              37.990978
            ],
            [
              -82.989014,
              37.963968
            ],
            [
              -82.96166,
              37.912882
            ],
            [
              -82.961423,
              37.893827
            ],
            [
              -82.981858,
              37.898878
            ],
            [
              -82.989217,
              37.869589
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "119",
      "COUNTYNS": "00516906",
      "AFFGEOID": "0500000US21119",
      "GEOID": "21119",
      "NAME": "Knott",
      "LSAD": "06",
      "ALAND": 910427757,
      "AWATER": 3453309,
      "County_state": "Knott,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.134711,
              37.457181
            ],
            [
              -83.124348,
              37.404973
            ],
            [
              -83.110702,
              37.331891
            ],
            [
              -83.116229,
              37.310207
            ],
            [
              -83.110426,
              37.298793
            ],
            [
              -83.119303,
              37.295432
            ],
            [
              -83.127249,
              37.268401
            ],
            [
              -83.120402,
              37.246927
            ],
            [
              -83.126805,
              37.235973
            ],
            [
              -83.115956,
              37.240689
            ],
            [
              -83.101563,
              37.235437
            ],
            [
              -83.066377,
              37.244395
            ],
            [
              -83.067404,
              37.231258
            ],
            [
              -83.054308,
              37.215818
            ],
            [
              -83.032983,
              37.216831
            ],
            [
              -83.029024,
              37.20829
            ],
            [
              -83.005811,
              37.205225
            ],
            [
              -83.000142,
              37.19571
            ],
            [
              -82.984493,
              37.187718
            ],
            [
              -82.979183,
              37.17242
            ],
            [
              -82.95831,
              37.190034
            ],
            [
              -82.944096,
              37.190413
            ],
            [
              -82.931835,
              37.202743
            ],
            [
              -82.920769,
              37.200027
            ],
            [
              -82.893379,
              37.216972
            ],
            [
              -82.874726,
              37.242143
            ],
            [
              -82.852325,
              37.255795
            ],
            [
              -82.832537,
              37.258777
            ],
            [
              -82.819373,
              37.268671
            ],
            [
              -82.801672,
              37.261232
            ],
            [
              -82.75741,
              37.26969
            ],
            [
              -82.732693,
              37.271502
            ],
            [
              -82.724557,
              37.281035
            ],
            [
              -82.710805,
              37.285323
            ],
            [
              -82.725709,
              37.288786
            ],
            [
              -82.724957,
              37.301313
            ],
            [
              -82.752346,
              37.308039
            ],
            [
              -82.748166,
              37.31679
            ],
            [
              -82.752222,
              37.337022
            ],
            [
              -82.764316,
              37.352379
            ],
            [
              -82.752746,
              37.370544
            ],
            [
              -82.756568,
              37.380252
            ],
            [
              -82.755848,
              37.4136
            ],
            [
              -82.762749,
              37.425133
            ],
            [
              -82.781926,
              37.429463
            ],
            [
              -82.810522,
              37.411531
            ],
            [
              -82.835476,
              37.414445
            ],
            [
              -82.831499,
              37.436412
            ],
            [
              -82.831473,
              37.46699
            ],
            [
              -82.927846,
              37.480934
            ],
            [
              -82.92546,
              37.486525
            ],
            [
              -82.941066,
              37.48963
            ],
            [
              -82.948544,
              37.503167
            ],
            [
              -82.974293,
              37.497959
            ],
            [
              -82.985361,
              37.506522
            ],
            [
              -83.002665,
              37.5012
            ],
            [
              -83.013903,
              37.508544
            ],
            [
              -83.031262,
              37.506341
            ],
            [
              -83.046963,
              37.521995
            ],
            [
              -83.062266,
              37.526231
            ],
            [
              -83.07096,
              37.516846
            ],
            [
              -83.088514,
              37.522666
            ],
            [
              -83.094663,
              37.518286
            ],
            [
              -83.093144,
              37.498102
            ],
            [
              -83.134711,
              37.457181
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "121",
      "COUNTYNS": "00516907",
      "AFFGEOID": "0500000US21121",
      "GEOID": "21121",
      "NAME": "Knox",
      "LSAD": "06",
      "ALAND": 1000508765,
      "AWATER": 3753811,
      "County_state": "Knox,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.091853,
              36.955992
            ],
            [
              -84.08042,
              36.926967
            ],
            [
              -84.085362,
              36.904821
            ],
            [
              -84.070446,
              36.890592
            ],
            [
              -84.065833,
              36.854147
            ],
            [
              -84.05261,
              36.855665
            ],
            [
              -84.039508,
              36.848694
            ],
            [
              -84.043728,
              36.839726
            ],
            [
              -84.027514,
              36.818644
            ],
            [
              -84.000494,
              36.811286
            ],
            [
              -83.99964,
              36.800858
            ],
            [
              -83.983674,
              36.800839
            ],
            [
              -83.976791,
              36.778962
            ],
            [
              -83.986771,
              36.759032
            ],
            [
              -83.968546,
              36.760923
            ],
            [
              -83.95967,
              36.749377
            ],
            [
              -83.958534,
              36.727245
            ],
            [
              -83.877503,
              36.687259
            ],
            [
              -83.86552,
              36.714717
            ],
            [
              -83.854824,
              36.705366
            ],
            [
              -83.841314,
              36.711756
            ],
            [
              -83.828235,
              36.742749
            ],
            [
              -83.809166,
              36.748103
            ],
            [
              -83.808606,
              36.761708
            ],
            [
              -83.796303,
              36.77259
            ],
            [
              -83.798248,
              36.785197
            ],
            [
              -83.786165,
              36.800678
            ],
            [
              -83.775776,
              36.798121
            ],
            [
              -83.754042,
              36.80535
            ],
            [
              -83.73873,
              36.801439
            ],
            [
              -83.737731,
              36.809144
            ],
            [
              -83.714737,
              36.820553
            ],
            [
              -83.705846,
              36.836134
            ],
            [
              -83.691787,
              36.839212
            ],
            [
              -83.682879,
              36.854582
            ],
            [
              -83.66327,
              36.864248
            ],
            [
              -83.642407,
              36.866246
            ],
            [
              -83.64307,
              36.879377
            ],
            [
              -83.629044,
              36.888182
            ],
            [
              -83.620573,
              36.884087
            ],
            [
              -83.612394,
              36.897593
            ],
            [
              -83.581094,
              36.904667
            ],
            [
              -83.575778,
              36.922451
            ],
            [
              -83.566142,
              36.931525
            ],
            [
              -83.583819,
              36.941249
            ],
            [
              -83.589521,
              36.955786
            ],
            [
              -83.607899,
              36.952718
            ],
            [
              -83.612327,
              36.959711
            ],
            [
              -83.641079,
              36.959517
            ],
            [
              -83.66045,
              36.976225
            ],
            [
              -83.700092,
              36.986582
            ],
            [
              -83.736163,
              37.006489
            ],
            [
              -83.732623,
              37.01658
            ],
            [
              -83.743926,
              37.026771
            ],
            [
              -83.774896,
              37.015237
            ],
            [
              -83.780327,
              37.022999
            ],
            [
              -83.774677,
              37.037366
            ],
            [
              -83.79723,
              37.036813
            ],
            [
              -83.815307,
              37.026889
            ],
            [
              -83.831618,
              37.031077
            ],
            [
              -83.833982,
              37.043338
            ],
            [
              -83.856181,
              37.042458
            ],
            [
              -83.871315,
              37.054919
            ],
            [
              -83.905545,
              37.032441
            ],
            [
              -83.922336,
              37.036413
            ],
            [
              -83.931678,
              36.99641
            ],
            [
              -83.959138,
              36.977273
            ],
            [
              -84.000192,
              36.980598
            ],
            [
              -84.032312,
              36.988628
            ],
            [
              -84.045593,
              36.97646
            ],
            [
              -84.067665,
              36.974883
            ],
            [
              -84.091853,
              36.955992
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "127",
      "COUNTYNS": "00516910",
      "AFFGEOID": "0500000US21127",
      "GEOID": "21127",
      "NAME": "Lawrence",
      "LSAD": "06",
      "ALAND": 1076385464,
      "AWATER": 11584860,
      "County_state": "Lawrence,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.022115,
              38.007047
            ],
            [
              -83.015694,
              37.973348
            ],
            [
              -82.989014,
              37.963968
            ],
            [
              -82.970724,
              37.990978
            ],
            [
              -82.952043,
              38.000316
            ],
            [
              -82.931671,
              37.996558
            ],
            [
              -82.875218,
              37.977619
            ],
            [
              -82.831615,
              37.967884
            ],
            [
              -82.736583,
              37.916327
            ],
            [
              -82.676933,
              37.892328
            ],
            [
              -82.612393,
              37.879272
            ],
            [
              -82.596296,
              37.890009
            ],
            [
              -82.601414,
              37.921778
            ],
            [
              -82.580117,
              37.933948
            ],
            [
              -82.580864,
              37.954074
            ],
            [
              -82.534871,
              37.962786
            ],
            [
              -82.4972999070143,
              37.9454908576785
            ],
            [
              -82.4973,
              37.945507
            ],
            [
              -82.496681,
              37.946405
            ],
            [
              -82.495294,
              37.946612
            ],
            [
              -82.487836,
              37.945288
            ],
            [
              -82.48512,
              37.946044
            ],
            [
              -82.48096,
              37.949136
            ],
            [
              -82.475096,
              37.954906
            ],
            [
              -82.471801,
              37.959119
            ],
            [
              -82.471657,
              37.959988
            ],
            [
              -82.472669,
              37.960721
            ],
            [
              -82.46938,
              37.973059
            ],
            [
              -82.464987,
              37.976859
            ],
            [
              -82.464067,
              37.980295
            ],
            [
              -82.464257,
              37.983412
            ],
            [
              -82.465473,
              37.98478
            ],
            [
              -82.467015,
              37.985578
            ],
            [
              -82.471629,
              37.986826
            ],
            [
              -82.485967,
              37.995705
            ],
            [
              -82.487732,
              37.99833
            ],
            [
              -82.48978,
              37.998869
            ],
            [
              -82.499874,
              37.999157
            ],
            [
              -82.507197,
              38.001512
            ],
            [
              -82.509812,
              38.001249
            ],
            [
              -82.513271,
              37.999674
            ],
            [
              -82.515974,
              37.999929
            ],
            [
              -82.517351,
              38.001204
            ],
            [
              -82.51781,
              38.002479
            ],
            [
              -82.517351,
              38.005131
            ],
            [
              -82.517606,
              38.007222
            ],
            [
              -82.519665,
              38.008538
            ],
            [
              -82.522591,
              38.012968
            ],
            [
              -82.525831,
              38.019564
            ],
            [
              -82.525817,
              38.026406
            ],
            [
              -82.527068,
              38.027586
            ],
            [
              -82.530371,
              38.028792
            ],
            [
              -82.534976,
              38.03225
            ],
            [
              -82.538639,
              38.037381
            ],
            [
              -82.539071,
              38.039788
            ],
            [
              -82.53747,
              38.042701
            ],
            [
              -82.537293,
              38.045204
            ],
            [
              -82.541245,
              38.048444
            ],
            [
              -82.543916,
              38.052133
            ],
            [
              -82.544779,
              38.054262
            ],
            [
              -82.54485,
              38.058521
            ],
            [
              -82.547284,
              38.061094
            ],
            [
              -82.549407,
              38.063063
            ],
            [
              -82.54958,
              38.068579
            ],
            [
              -82.551259,
              38.070799
            ],
            [
              -82.559598,
              38.072837
            ],
            [
              -82.565736,
              38.08064
            ],
            [
              -82.574075,
              38.082104
            ],
            [
              -82.584039,
              38.090663
            ],
            [
              -82.585488,
              38.094256
            ],
            [
              -82.585142,
              38.098055
            ],
            [
              -82.585696,
              38.107003
            ],
            [
              -82.587782,
              38.108879
            ],
            [
              -82.59178,
              38.109908
            ],
            [
              -82.593605,
              38.110869
            ],
            [
              -82.598011,
              38.115925
            ],
            [
              -82.600127,
              38.117389
            ],
            [
              -82.602618,
              38.11835
            ],
            [
              -82.606589,
              38.120843
            ],
            [
              -82.616149,
              38.120221
            ],
            [
              -82.619452,
              38.120745
            ],
            [
              -82.620351,
              38.121477
            ],
            [
              -82.621164,
              38.123239
            ],
            [
              -82.621167,
              38.131996
            ],
            [
              -82.622125,
              38.133414
            ],
            [
              -82.626182,
              38.134835
            ],
            [
              -82.634265,
              38.136669
            ],
            [
              -82.636466,
              38.13786
            ],
            [
              -82.637306,
              38.13905
            ],
            [
              -82.638288,
              38.143491
            ],
            [
              -82.638398,
              38.152157
            ],
            [
              -82.638947,
              38.156742
            ],
            [
              -82.644739,
              38.165487
            ],
            [
              -82.642997,
              38.16956
            ],
            [
              -82.639054,
              38.171114
            ],
            [
              -82.625457,
              38.170491
            ],
            [
              -82.619429,
              38.169027
            ],
            [
              -82.613487,
              38.170242
            ],
            [
              -82.611343,
              38.171548
            ],
            [
              -82.60425,
              38.187639
            ],
            [
              -82.599326,
              38.197231
            ],
            [
              -82.598864,
              38.201007
            ],
            [
              -82.598437,
              38.217393
            ],
            [
              -82.600353,
              38.218949
            ],
            [
              -82.60575,
              38.221144
            ],
            [
              -82.608944,
              38.22366
            ],
            [
              -82.610367,
              38.226772
            ],
            [
              -82.61252,
              38.234553
            ],
            [
              -82.61226,
              38.236087
            ],
            [
              -82.607131,
              38.245975
            ],
            [
              -82.605333,
              38.247303
            ],
            [
              -82.604625,
              38.247303
            ],
            [
              -82.614304,
              38.266078
            ],
            [
              -82.699471,
              38.261913
            ],
            [
              -82.7368,
              38.237487
            ],
            [
              -82.766528,
              38.235161
            ],
            [
              -82.794206,
              38.243772
            ],
            [
              -82.82111,
              38.232733
            ],
            [
              -82.8232,
              38.208264
            ],
            [
              -82.853983,
              38.190156
            ],
            [
              -82.883913,
              38.195978
            ],
            [
              -82.924805,
              38.175114
            ],
            [
              -82.917786,
              38.174834
            ],
            [
              -82.907833,
              38.156716
            ],
            [
              -82.90675,
              38.133025
            ],
            [
              -82.888644,
              38.112806
            ],
            [
              -82.907528,
              38.095731
            ],
            [
              -82.917064,
              38.099094
            ],
            [
              -82.942005,
              38.077585
            ],
            [
              -82.941991,
              38.064164
            ],
            [
              -82.95632,
              38.049521
            ],
            [
              -82.981228,
              38.049999
            ],
            [
              -83.001995,
              38.034737
            ],
            [
              -83.022115,
              38.007047
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "151",
      "COUNTYNS": "00516919",
      "AFFGEOID": "0500000US21151",
      "GEOID": "21151",
      "NAME": "Madison",
      "LSAD": "06",
      "ALAND": 1132603918,
      "AWATER": 15425953,
      "County_state": "Madison,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.525643,
              37.769497
            ],
            [
              -84.511458,
              37.765068
            ],
            [
              -84.514885,
              37.757373
            ],
            [
              -84.494485,
              37.747461
            ],
            [
              -84.503228,
              37.734184
            ],
            [
              -84.487976,
              37.728014
            ],
            [
              -84.485404,
              37.711126
            ],
            [
              -84.4745,
              37.708072
            ],
            [
              -84.453702,
              37.691206
            ],
            [
              -84.464183,
              37.687107
            ],
            [
              -84.446286,
              37.671293
            ],
            [
              -84.429772,
              37.643009
            ],
            [
              -84.412419,
              37.630439
            ],
            [
              -84.421758,
              37.618152
            ],
            [
              -84.401642,
              37.617785
            ],
            [
              -84.396651,
              37.599572
            ],
            [
              -84.372342,
              37.592412
            ],
            [
              -84.370718,
              37.57204
            ],
            [
              -84.347805,
              37.539064
            ],
            [
              -84.303929,
              37.537424
            ],
            [
              -84.301932,
              37.523431
            ],
            [
              -84.268112,
              37.51531
            ],
            [
              -84.22422,
              37.527189
            ],
            [
              -84.199489,
              37.522858
            ],
            [
              -84.171836,
              37.557549
            ],
            [
              -84.142063,
              37.564839
            ],
            [
              -84.090364,
              37.566227
            ],
            [
              -84.112521,
              37.605648
            ],
            [
              -84.126948,
              37.642421
            ],
            [
              -84.089212,
              37.668149
            ],
            [
              -84.092786,
              37.709949
            ],
            [
              -84.099206,
              37.718976
            ],
            [
              -84.096689,
              37.735087
            ],
            [
              -84.081076,
              37.749075
            ],
            [
              -84.081913,
              37.767258
            ],
            [
              -84.103108,
              37.773758
            ],
            [
              -84.073908,
              37.801117
            ],
            [
              -84.099159,
              37.815605
            ],
            [
              -84.121716,
              37.81309
            ],
            [
              -84.092676,
              37.835783
            ],
            [
              -84.080337,
              37.853066
            ],
            [
              -84.092714,
              37.861548
            ],
            [
              -84.107378,
              37.850558
            ],
            [
              -84.13113,
              37.867935
            ],
            [
              -84.166694,
              37.861822
            ],
            [
              -84.186846,
              37.879232
            ],
            [
              -84.221911,
              37.871991
            ],
            [
              -84.237685,
              37.887329
            ],
            [
              -84.263154,
              37.876873
            ],
            [
              -84.265348,
              37.884011
            ],
            [
              -84.270331,
              37.917281
            ],
            [
              -84.337393,
              37.892007
            ],
            [
              -84.341142,
              37.881973
            ],
            [
              -84.378609,
              37.901543
            ],
            [
              -84.383587,
              37.89381
            ],
            [
              -84.365694,
              37.858755
            ],
            [
              -84.386165,
              37.84549
            ],
            [
              -84.410672,
              37.87126
            ],
            [
              -84.43572,
              37.847202
            ],
            [
              -84.446832,
              37.840397
            ],
            [
              -84.476283,
              37.853131
            ],
            [
              -84.485128,
              37.840287
            ],
            [
              -84.462031,
              37.803431
            ],
            [
              -84.482441,
              37.802141
            ],
            [
              -84.495962,
              37.785248
            ],
            [
              -84.525643,
              37.769497
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "135",
      "COUNTYNS": "00516914",
      "AFFGEOID": "0500000US21135",
      "GEOID": "21135",
      "NAME": "Lewis",
      "LSAD": "06",
      "ALAND": 1250540181,
      "AWATER": 32492720,
      "County_state": "Lewis,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.642994,
              38.643273
            ],
            [
              -83.645914,
              38.637007
            ],
            [
              -83.624596,
              38.611425
            ],
            [
              -83.641852,
              38.525381
            ],
            [
              -83.628552,
              38.521994
            ],
            [
              -83.624755,
              38.503638
            ],
            [
              -83.599189,
              38.501992
            ],
            [
              -83.59027,
              38.489576
            ],
            [
              -83.574865,
              38.444344
            ],
            [
              -83.581613,
              38.431258
            ],
            [
              -83.552275,
              38.419926
            ],
            [
              -83.51489,
              38.414678
            ],
            [
              -83.497434,
              38.401587
            ],
            [
              -83.466592,
              38.398908
            ],
            [
              -83.452848,
              38.381799
            ],
            [
              -83.412003,
              38.396446
            ],
            [
              -83.400368,
              38.375694
            ],
            [
              -83.378434,
              38.362722
            ],
            [
              -83.356336,
              38.321869
            ],
            [
              -83.341347,
              38.319521
            ],
            [
              -83.312589,
              38.33366
            ],
            [
              -83.284788,
              38.323742
            ],
            [
              -83.247344,
              38.330769
            ],
            [
              -83.23068,
              38.339286
            ],
            [
              -83.239615,
              38.355851
            ],
            [
              -83.232433,
              38.37418
            ],
            [
              -83.238649,
              38.397472
            ],
            [
              -83.23721,
              38.426472
            ],
            [
              -83.207514,
              38.441177
            ],
            [
              -83.213097,
              38.454794
            ],
            [
              -83.200485,
              38.453818
            ],
            [
              -83.186411,
              38.469428
            ],
            [
              -83.188505,
              38.496071
            ],
            [
              -83.166419,
              38.503965
            ],
            [
              -83.147875,
              38.522006
            ],
            [
              -83.146652,
              38.530239
            ],
            [
              -83.122045,
              38.535532
            ],
            [
              -83.099754,
              38.558714
            ],
            [
              -83.090328,
              38.579365
            ],
            [
              -83.071005,
              38.5952
            ],
            [
              -83.08079,
              38.610465
            ],
            [
              -83.069573,
              38.621845
            ],
            [
              -83.073774,
              38.632412
            ],
            [
              -83.054558,
              38.639588
            ],
            [
              -83.050259,
              38.663758
            ],
            [
              -83.038327,
              38.665294
            ],
            [
              -83.024075,
              38.683306
            ],
            [
              -83.0447314019964,
              38.705542778178504
            ],
            [
              -83.053104,
              38.695831
            ],
            [
              -83.064319,
              38.688976
            ],
            [
              -83.084226,
              38.68109
            ],
            [
              -83.102746,
              38.677316
            ],
            [
              -83.107436,
              38.67522
            ],
            [
              -83.112372,
              38.671685
            ],
            [
              -83.11786,
              38.666073
            ],
            [
              -83.122547,
              38.6592
            ],
            [
              -83.126311,
              38.645244
            ],
            [
              -83.128973,
              38.640231
            ],
            [
              -83.135046,
              38.631719
            ],
            [
              -83.138572,
              38.628286
            ],
            [
              -83.142836,
              38.625076
            ],
            [
              -83.156926,
              38.620547
            ],
            [
              -83.164744,
              38.620068
            ],
            [
              -83.172647,
              38.620252
            ],
            [
              -83.1914,
              38.617598
            ],
            [
              -83.202453,
              38.616956
            ],
            [
              -83.211027,
              38.618578
            ],
            [
              -83.223076,
              38.624158
            ],
            [
              -83.232404,
              38.627569
            ],
            [
              -83.239515,
              38.628588
            ],
            [
              -83.245572,
              38.627936
            ],
            [
              -83.251103,
              38.626224
            ],
            [
              -83.254558,
              38.623403
            ],
            [
              -83.261126,
              38.622723
            ],
            [
              -83.264011,
              38.621535
            ],
            [
              -83.2675502920792,
              38.6183503098152
            ],
            [
              -83.267694,
              38.618221
            ],
            [
              -83.26851,
              38.615104
            ],
            [
              -83.286514,
              38.599241
            ],
            [
              -83.288885,
              38.597977
            ],
            [
              -83.294193,
              38.596588
            ],
            [
              -83.301951,
              38.598178
            ],
            [
              -83.307832,
              38.600824
            ],
            [
              -83.311448,
              38.603314
            ],
            [
              -83.317542,
              38.609242
            ],
            [
              -83.319101,
              38.612233
            ],
            [
              -83.320099,
              38.616043
            ],
            [
              -83.320531,
              38.622713
            ],
            [
              -83.322383,
              38.630615
            ],
            [
              -83.327636,
              38.637489
            ],
            [
              -83.340004,
              38.645674
            ],
            [
              -83.356445,
              38.654009
            ],
            [
              -83.366661,
              38.658537
            ],
            [
              -83.376302,
              38.661473
            ],
            [
              -83.384755,
              38.663171
            ],
            [
              -83.412039,
              38.666499
            ],
            [
              -83.420194,
              38.668428
            ],
            [
              -83.440404,
              38.669361
            ],
            [
              -83.446989,
              38.670143
            ],
            [
              -83.457055,
              38.673965
            ],
            [
              -83.459809,
              38.673617
            ],
            [
              -83.468059,
              38.67547
            ],
            [
              -83.472157,
              38.678123
            ],
            [
              -83.47642,
              38.682261
            ],
            [
              -83.486477,
              38.690099
            ],
            [
              -83.493342,
              38.694187
            ],
            [
              -83.504365,
              38.699256
            ],
            [
              -83.512571,
              38.701716
            ],
            [
              -83.520953,
              38.703045
            ],
            [
              -83.533339,
              38.702105
            ],
            [
              -83.549298,
              38.698787
            ],
            [
              -83.56187,
              38.695371
            ],
            [
              -83.569098,
              38.692842
            ],
            [
              -83.575121,
              38.691746
            ],
            [
              -83.574754,
              38.692671
            ],
            [
              -83.615736,
              38.684145
            ],
            [
              -83.626922,
              38.679387
            ],
            [
              -83.634018,
              38.673351
            ],
            [
              -83.636208,
              38.670584
            ],
            [
              -83.637377,
              38.66793
            ],
            [
              -83.639396,
              38.659171
            ],
            [
              -83.639954,
              38.652468
            ],
            [
              -83.642994,
              38.643273
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "139",
      "COUNTYNS": "00516916",
      "AFFGEOID": "0500000US21139",
      "GEOID": "21139",
      "NAME": "Livingston",
      "LSAD": "06",
      "ALAND": 810999158,
      "AWATER": 75642166,
      "County_state": "Livingston,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.5638451054736,
              37.078541595124996
            ],
            [
              -88.565945,
              37.067785
            ],
            [
              -88.526674,
              37.035247
            ],
            [
              -88.496298,
              37.022317
            ],
            [
              -88.482957,
              37.022566
            ],
            [
              -88.448901,
              37.034929
            ],
            [
              -88.426519,
              37.050079
            ],
            [
              -88.393756,
              37.05569
            ],
            [
              -88.365595,
              37.064183
            ],
            [
              -88.339292,
              37.061216
            ],
            [
              -88.307753,
              37.048531
            ],
            [
              -88.240371,
              36.981911
            ],
            [
              -88.209221,
              36.999976
            ],
            [
              -88.226774,
              37.027602
            ],
            [
              -88.216295,
              37.056225
            ],
            [
              -88.231145,
              37.081575
            ],
            [
              -88.216889,
              37.095732
            ],
            [
              -88.210909,
              37.130426
            ],
            [
              -88.193519,
              37.147256
            ],
            [
              -88.187289,
              37.15454
            ],
            [
              -88.198089,
              37.174915
            ],
            [
              -88.220338,
              37.183013
            ],
            [
              -88.19647,
              37.219388
            ],
            [
              -88.21898,
              37.273145
            ],
            [
              -88.267358,
              37.31732
            ],
            [
              -88.293628,
              37.335376
            ],
            [
              -88.306191,
              37.368875
            ],
            [
              -88.299114,
              37.380058
            ],
            [
              -88.31785,
              37.397927
            ],
            [
              -88.35447,
              37.397026
            ],
            [
              -88.35850600784909,
              37.4048167301695
            ],
            [
              -88.361557,
              37.402931
            ],
            [
              -88.365471,
              37.401663
            ],
            [
              -88.371214,
              37.40273
            ],
            [
              -88.373445,
              37.404342
            ],
            [
              -88.377507,
              37.409825
            ],
            [
              -88.387669,
              37.416482
            ],
            [
              -88.39734,
              37.421644
            ],
            [
              -88.404127,
              37.424146
            ],
            [
              -88.408808,
              37.425216
            ],
            [
              -88.413108,
              37.424468
            ],
            [
              -88.41488228248849,
              37.4236655948133
            ],
            [
              -88.418594,
              37.421987
            ],
            [
              -88.425981,
              37.419441
            ],
            [
              -88.433182,
              37.418169
            ],
            [
              -88.439333,
              37.416416
            ],
            [
              -88.450127,
              37.411717
            ],
            [
              -88.456,
              37.408482
            ],
            [
              -88.465861,
              37.400547
            ],
            [
              -88.470224,
              37.396255
            ],
            [
              -88.476592,
              37.386875
            ],
            [
              -88.478523,
              37.375052
            ],
            [
              -88.482113,
              37.364615
            ],
            [
              -88.482612,
              37.354915
            ],
            [
              -88.484462,
              37.345609
            ],
            [
              -88.486947,
              37.339596
            ],
            [
              -88.49031,
              37.335042
            ],
            [
              -88.494137,
              37.327689
            ],
            [
              -88.500566,
              37.317822
            ],
            [
              -88.505087,
              37.307858
            ],
            [
              -88.508657,
              37.303353
            ],
            [
              -88.511497,
              37.298527
            ],
            [
              -88.514661,
              37.290948
            ],
            [
              -88.515939,
              37.284043
            ],
            [
              -88.509587,
              37.273398
            ],
            [
              -88.506942,
              37.266656
            ],
            [
              -88.509328,
              37.26213
            ],
            [
              -88.508031,
              37.260261
            ],
            [
              -88.500777,
              37.253579
            ],
            [
              -88.492383,
              37.248445
            ],
            [
              -88.487277,
              37.244077
            ],
            [
              -88.484982,
              37.240774
            ],
            [
              -88.47973,
              37.229606
            ],
            [
              -88.478179,
              37.227251
            ],
            [
              -88.471753,
              37.220155
            ],
            [
              -88.466981,
              37.217026
            ],
            [
              -88.458763,
              37.213536
            ],
            [
              -88.450653,
              37.207046
            ],
            [
              -88.447764,
              37.203527
            ],
            [
              -88.441956,
              37.189036
            ],
            [
              -88.439527,
              37.18174
            ],
            [
              -88.437781,
              37.180007
            ],
            [
              -88.433454,
              37.165871
            ],
            [
              -88.433782,
              37.16407
            ],
            [
              -88.431488,
              37.160298
            ],
            [
              -88.429906,
              37.158668
            ],
            [
              -88.428097,
              37.157758
            ],
            [
              -88.424403,
              37.152428
            ],
            [
              -88.424776,
              37.149901
            ],
            [
              -88.434701,
              37.126424
            ],
            [
              -88.435829,
              37.125055
            ],
            [
              -88.443538,
              37.109192
            ],
            [
              -88.443538,
              37.108517
            ],
            [
              -88.442743,
              37.107842
            ],
            [
              -88.444605,
              37.098601
            ],
            [
              -88.458948,
              37.073796
            ],
            [
              -88.476127,
              37.068223
            ],
            [
              -88.482856,
              37.067114
            ],
            [
              -88.490068,
              37.067874
            ],
            [
              -88.49027613045209,
              37.0678362094544
            ],
            [
              -88.504437,
              37.065265
            ],
            [
              -88.514356,
              37.065231
            ],
            [
              -88.521436,
              37.065584
            ],
            [
              -88.531576,
              37.067192
            ],
            [
              -88.545403,
              37.070003
            ],
            [
              -88.560032,
              37.07601
            ],
            [
              -88.5638451054736,
              37.078541595124996
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "145",
      "COUNTYNS": "00516925",
      "AFFGEOID": "0500000US21145",
      "GEOID": "21145",
      "NAME": "McCracken",
      "LSAD": "06",
      "ALAND": 644243924,
      "AWATER": 50305452,
      "County_state": "McCracken,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.9330768781631,
              37.22774949504169
            ],
            [
              -88.932225,
              37.222288
            ],
            [
              -88.869151,
              37.076829
            ],
            [
              -88.821705,
              36.967611
            ],
            [
              -88.815952,
              36.9541
            ],
            [
              -88.812897,
              36.946864
            ],
            [
              -88.709378,
              36.946357
            ],
            [
              -88.699804,
              36.942774
            ],
            [
              -88.573401,
              36.943293
            ],
            [
              -88.484103,
              36.942096
            ],
            [
              -88.485061,
              36.981083
            ],
            [
              -88.482957,
              37.022566
            ],
            [
              -88.496298,
              37.022317
            ],
            [
              -88.526674,
              37.035247
            ],
            [
              -88.565945,
              37.067785
            ],
            [
              -88.5638451054736,
              37.078541595124996
            ],
            [
              -88.569375,
              37.082213
            ],
            [
              -88.576718,
              37.085852
            ],
            [
              -88.581635,
              37.090567
            ],
            [
              -88.589207,
              37.099655
            ],
            [
              -88.593922,
              37.101761
            ],
            [
              -88.601144,
              37.107081
            ],
            [
              -88.61144,
              37.112745
            ],
            [
              -88.625889,
              37.119458
            ],
            [
              -88.630605,
              37.121003
            ],
            [
              -88.637977,
              37.121309
            ],
            [
              -88.644872,
              37.122844
            ],
            [
              -88.687767,
              37.139378
            ],
            [
              -88.693983,
              37.141155
            ],
            [
              -88.702553,
              37.142646
            ],
            [
              -88.720224,
              37.140641
            ],
            [
              -88.72344,
              37.141194
            ],
            [
              -88.732105,
              37.143956
            ],
            [
              -88.737937,
              37.146513
            ],
            [
              -88.746065,
              37.151564
            ],
            [
              -88.753068,
              37.154701
            ],
            [
              -88.765357,
              37.162662
            ],
            [
              -88.77595,
              37.16878
            ],
            [
              -88.779466,
              37.172495
            ],
            [
              -88.786947,
              37.178584
            ],
            [
              -88.797373,
              37.184854
            ],
            [
              -88.80572,
              37.188595
            ],
            [
              -88.820935,
              37.192203
            ],
            [
              -88.835051,
              37.196486
            ],
            [
              -88.86953,
              37.209711
            ],
            [
              -88.902841,
              37.219299
            ],
            [
              -88.916934,
              37.224291
            ],
            [
              -88.920878,
              37.224769
            ],
            [
              -88.92801898699109,
              37.2266247234989
            ],
            [
              -88.931745,
              37.227593
            ],
            [
              -88.9330768781631,
              37.22774949504169
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "095",
      "COUNTYNS": "00516894",
      "AFFGEOID": "0500000US21095",
      "GEOID": "21095",
      "NAME": "Harlan",
      "LSAD": "06",
      "ALAND": 1206490776,
      "AWATER": 6014027,
      "County_state": "Harlan,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.511729,
              36.807362
            ],
            [
              -83.485814,
              36.783293
            ],
            [
              -83.491054,
              36.756988
            ],
            [
              -83.500891,
              36.733254
            ],
            [
              -83.480179,
              36.709879
            ],
            [
              -83.459459,
              36.697387
            ],
            [
              -83.4610151191699,
              36.6648782480975
            ],
            [
              -83.460808,
              36.664885
            ],
            [
              -83.458009,
              36.665785
            ],
            [
              -83.454709,
              36.664785
            ],
            [
              -83.448108,
              36.665285
            ],
            [
              -83.440808,
              36.666885
            ],
            [
              -83.436508,
              36.666185
            ],
            [
              -83.431708,
              36.666485
            ],
            [
              -83.423707,
              36.667385
            ],
            [
              -83.419507,
              36.668486
            ],
            [
              -83.411807,
              36.670786
            ],
            [
              -83.395806,
              36.676786
            ],
            [
              -83.394906,
              36.677586
            ],
            [
              -83.395607,
              36.678987
            ],
            [
              -83.395306,
              36.679486
            ],
            [
              -83.389306,
              36.684986
            ],
            [
              -83.386099,
              36.686589
            ],
            [
              -83.367505,
              36.692686
            ],
            [
              -83.364005,
              36.694586
            ],
            [
              -83.362105,
              36.694786
            ],
            [
              -83.360205,
              36.693586
            ],
            [
              -83.359205,
              36.693586
            ],
            [
              -83.356405,
              36.694686
            ],
            [
              -83.354606,
              36.696153
            ],
            [
              -83.353613,
              36.696699
            ],
            [
              -83.350805,
              36.697386
            ],
            [
              -83.349705,
              36.698386
            ],
            [
              -83.342804,
              36.701286
            ],
            [
              -83.315703,
              36.708187
            ],
            [
              -83.313003,
              36.709087
            ],
            [
              -83.311403,
              36.710287
            ],
            [
              -83.307103,
              36.711387
            ],
            [
              -83.287802,
              36.713787
            ],
            [
              -83.286902,
              36.713987
            ],
            [
              -83.285702,
              36.715187
            ],
            [
              -83.275501,
              36.717987
            ],
            [
              -83.272501,
              36.717787
            ],
            [
              -83.269301,
              36.718487
            ],
            [
              -83.263601,
              36.720987
            ],
            [
              -83.2555,
              36.721787
            ],
            [
              -83.2463,
              36.725287
            ],
            [
              -83.238499,
              36.727087
            ],
            [
              -83.236399,
              36.726887
            ],
            [
              -83.219999,
              36.731287
            ],
            [
              -83.199698,
              36.737487
            ],
            [
              -83.194597,
              36.739487
            ],
            [
              -83.175696,
              36.739587
            ],
            [
              -83.167396,
              36.739187
            ],
            [
              -83.161496,
              36.739887
            ],
            [
              -83.156696,
              36.742187
            ],
            [
              -83.146095,
              36.741788
            ],
            [
              -83.136395,
              36.743088
            ],
            [
              -83.134294,
              36.746588
            ],
            [
              -83.130994,
              36.749788
            ],
            [
              -83.127833,
              36.750828
            ],
            [
              -83.128272,
              36.75637
            ],
            [
              -83.128813,
              36.757864
            ],
            [
              -83.126719,
              36.761
            ],
            [
              -83.125728,
              36.761276
            ],
            [
              -83.125655,
              36.761407
            ],
            [
              -83.132477,
              36.764398
            ],
            [
              -83.132286,
              36.76561
            ],
            [
              -83.131245,
              36.767105
            ],
            [
              -83.128894,
              36.769888
            ],
            [
              -83.128494,
              36.775588
            ],
            [
              -83.131694,
              36.781488
            ],
            [
              -83.128794,
              36.785388
            ],
            [
              -83.123341,
              36.786654
            ],
            [
              -83.119144,
              36.789531
            ],
            [
              -83.114693,
              36.796088
            ],
            [
              -83.110793,
              36.800388
            ],
            [
              -83.108029,
              36.802181
            ],
            [
              -83.103092,
              36.806689
            ],
            [
              -83.098492,
              36.814289
            ],
            [
              -83.098892,
              36.822989
            ],
            [
              -83.099792,
              36.824889
            ],
            [
              -83.101692,
              36.826689
            ],
            [
              -83.102092,
              36.828189
            ],
            [
              -83.101792,
              36.829089
            ],
            [
              -83.098892,
              36.831789
            ],
            [
              -83.091291,
              36.834389
            ],
            [
              -83.088991,
              36.835989
            ],
            [
              -83.086991,
              36.838189
            ],
            [
              -83.07969,
              36.840589
            ],
            [
              -83.07519,
              36.840889
            ],
            [
              -83.07379,
              36.844889
            ],
            [
              -83.07559,
              36.850589
            ],
            [
              -83.07259,
              36.854589
            ],
            [
              -83.06929,
              36.853589
            ],
            [
              -83.066189,
              36.851889
            ],
            [
              -83.063989,
              36.851689
            ],
            [
              -83.060489,
              36.853789
            ],
            [
              -83.052489,
              36.851989
            ],
            [
              -83.047589,
              36.851789
            ],
            [
              -83.044288,
              36.852989
            ],
            [
              -83.042188,
              36.854389
            ],
            [
              -83.026887,
              36.855489
            ],
            [
              -83.025887,
              36.855289
            ],
            [
              -83.024387,
              36.851689
            ],
            [
              -83.021887,
              36.849989
            ],
            [
              -83.012587,
              36.847289
            ],
            [
              -83.009222,
              36.847295
            ],
            [
              -83.006086,
              36.847889
            ],
            [
              -83.003786,
              36.851789
            ],
            [
              -82.998376,
              36.85663
            ],
            [
              -82.988707,
              36.859137
            ],
            [
              -82.979227,
              36.859693
            ],
            [
              -82.973395,
              36.859097
            ],
            [
              -82.971934,
              36.857767
            ],
            [
              -82.970253,
              36.857686
            ],
            [
              -82.960955,
              36.862536
            ],
            [
              -82.951685,
              36.866152
            ],
            [
              -82.937573,
              36.867275
            ],
            [
              -82.92241,
              36.873925
            ],
            [
              -82.911824,
              36.874243
            ],
            [
              -82.91169,
              36.874248
            ],
            [
              -82.910315,
              36.874055
            ],
            [
              -82.907774,
              36.874706
            ],
            [
              -82.907276,
              36.875643
            ],
            [
              -82.908004,
              36.877233
            ],
            [
              -82.907981,
              36.878749
            ],
            [
              -82.906325,
              36.87974
            ],
            [
              -82.895445,
              36.882145
            ],
            [
              -82.890028,
              36.884287
            ],
            [
              -82.887627,
              36.88689
            ],
            [
              -82.884626,
              36.888477
            ],
            [
              -82.879492,
              36.889085
            ],
            [
              -82.878569,
              36.889585
            ],
            [
              -82.877862,
              36.891843
            ],
            [
              -82.8782081655467,
              36.8927955121479
            ],
            [
              -82.878639,
              36.893981
            ],
            [
              -82.876438,
              36.896238
            ],
            [
              -82.873213,
              36.897263
            ],
            [
              -82.870068,
              36.901735
            ],
            [
              -82.872561,
              36.903376
            ],
            [
              -82.877473,
              36.90796
            ],
            [
              -82.876215,
              36.910218
            ],
            [
              -82.873777,
              36.912299
            ],
            [
              -82.872136,
              36.913456
            ],
            [
              -82.867116,
              36.917965
            ],
            [
              -82.865192,
              36.920923
            ],
            [
              -82.863468,
              36.922308
            ],
            [
              -82.861943,
              36.924236
            ],
            [
              -82.860032,
              36.925241
            ],
            [
              -82.858635,
              36.927785
            ],
            [
              -82.857965,
              36.929529
            ],
            [
              -82.858461,
              36.932717
            ],
            [
              -82.858784,
              36.933065
            ],
            [
              -82.860537,
              36.937439
            ],
            [
              -82.861684,
              36.939316
            ],
            [
              -82.861282,
              36.944848
            ],
            [
              -82.860633,
              36.94584
            ],
            [
              -82.859969,
              36.949074
            ],
            [
              -82.856099,
              36.952471
            ],
            [
              -82.855705,
              36.953808
            ],
            [
              -82.858443,
              36.954036
            ],
            [
              -82.860534,
              36.956015
            ],
            [
              -82.862866,
              36.957765
            ],
            [
              -82.864211,
              36.957983
            ],
            [
              -82.865404,
              36.958084
            ],
            [
              -82.867358,
              36.963182
            ],
            [
              -82.87023,
              36.965498
            ],
            [
              -82.870274,
              36.965993
            ],
            [
              -82.8694630847178,
              36.9720796959173
            ],
            [
              -82.869183,
              36.974182
            ],
            [
              -82.8691826690104,
              36.9741830452543
            ],
            [
              -82.939466,
              37.01552
            ],
            [
              -82.965561,
              37.001894
            ],
            [
              -83.004528,
              36.993374
            ],
            [
              -83.022587,
              36.977403
            ],
            [
              -83.038161,
              36.984271
            ],
            [
              -83.153401,
              36.956591
            ],
            [
              -83.149172,
              36.981659
            ],
            [
              -83.126428,
              36.991441
            ],
            [
              -83.12209,
              37.000997
            ],
            [
              -83.162296,
              37.021013
            ],
            [
              -83.182104,
              37.021115
            ],
            [
              -83.185782,
              37.00608
            ],
            [
              -83.230339,
              37.002739
            ],
            [
              -83.26002,
              36.968951
            ],
            [
              -83.32428,
              36.940622
            ],
            [
              -83.399656,
              36.920955
            ],
            [
              -83.395527,
              36.903604
            ],
            [
              -83.408614,
              36.890218
            ],
            [
              -83.455777,
              36.883605
            ],
            [
              -83.489813,
              36.895413
            ],
            [
              -83.496728,
              36.884362
            ],
            [
              -83.493867,
              36.863508
            ],
            [
              -83.48171,
              36.849134
            ],
            [
              -83.511729,
              36.807362
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "153",
      "COUNTYNS": "00516920",
      "AFFGEOID": "0500000US21153",
      "GEOID": "21153",
      "NAME": "Magoffin",
      "LSAD": "06",
      "ALAND": 798866697,
      "AWATER": 1847051,
      "County_state": "Magoffin,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.24734,
              37.785427
            ],
            [
              -83.27064,
              37.769169
            ],
            [
              -83.243175,
              37.744004
            ],
            [
              -83.262172,
              37.712657
            ],
            [
              -83.242808,
              37.698119
            ],
            [
              -83.257445,
              37.685463
            ],
            [
              -83.248692,
              37.669616
            ],
            [
              -83.217563,
              37.64884
            ],
            [
              -83.171606,
              37.641915
            ],
            [
              -83.168275,
              37.635119
            ],
            [
              -83.141984,
              37.619097
            ],
            [
              -83.134949,
              37.606812
            ],
            [
              -83.114667,
              37.611401
            ],
            [
              -83.089796,
              37.632167
            ],
            [
              -83.071977,
              37.609511
            ],
            [
              -83.063122,
              37.611464
            ],
            [
              -83.056893,
              37.598513
            ],
            [
              -83.038749,
              37.584303
            ],
            [
              -83.027515,
              37.589649
            ],
            [
              -83.01217,
              37.583474
            ],
            [
              -83.017953,
              37.549181
            ],
            [
              -83.007838,
              37.550215
            ],
            [
              -82.996952,
              37.528332
            ],
            [
              -82.959935,
              37.518249
            ],
            [
              -82.948544,
              37.503167
            ],
            [
              -82.941066,
              37.48963
            ],
            [
              -82.92546,
              37.486525
            ],
            [
              -82.909546,
              37.491498
            ],
            [
              -82.9106,
              37.499523
            ],
            [
              -82.896178,
              37.508926
            ],
            [
              -82.892422,
              37.532426
            ],
            [
              -82.879082,
              37.535395
            ],
            [
              -82.889865,
              37.543815
            ],
            [
              -82.888851,
              37.564619
            ],
            [
              -82.902147,
              37.576932
            ],
            [
              -82.89654,
              37.588485
            ],
            [
              -82.90625,
              37.606593
            ],
            [
              -82.903122,
              37.64069
            ],
            [
              -82.912425,
              37.653598
            ],
            [
              -82.927762,
              37.656547
            ],
            [
              -82.945873,
              37.677938
            ],
            [
              -82.940763,
              37.716338
            ],
            [
              -82.950582,
              37.794734
            ],
            [
              -82.989762,
              37.818181
            ],
            [
              -82.98152,
              37.835412
            ],
            [
              -83.004649,
              37.859247
            ],
            [
              -83.021502,
              37.867916
            ],
            [
              -83.034646,
              37.886384
            ],
            [
              -83.042535,
              37.87226
            ],
            [
              -83.056795,
              37.87015
            ],
            [
              -83.089409,
              37.89034
            ],
            [
              -83.129258,
              37.894438
            ],
            [
              -83.113277,
              37.864121
            ],
            [
              -83.173889,
              37.831929
            ],
            [
              -83.178454,
              37.821484
            ],
            [
              -83.207849,
              37.819104
            ],
            [
              -83.225684,
              37.790104
            ],
            [
              -83.24734,
              37.785427
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "159",
      "COUNTYNS": "00516923",
      "AFFGEOID": "0500000US21159",
      "GEOID": "21159",
      "NAME": "Martin",
      "LSAD": "06",
      "ALAND": 594701951,
      "AWATER": 2564962,
      "County_state": "Martin,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.664322,
              37.806806
            ],
            [
              -82.674419,
              37.78748
            ],
            [
              -82.653581,
              37.778005
            ],
            [
              -82.655719,
              37.752854
            ],
            [
              -82.64075,
              37.720182
            ],
            [
              -82.621511,
              37.704692
            ],
            [
              -82.565003,
              37.689132
            ],
            [
              -82.563561,
              37.684844
            ],
            [
              -82.556433,
              37.691132
            ],
            [
              -82.520561,
              37.694305
            ],
            [
              -82.503249,
              37.688646
            ],
            [
              -82.490263,
              37.678411
            ],
            [
              -82.465872,
              37.683279
            ],
            [
              -82.456134,
              37.668795
            ],
            [
              -82.418143,
              37.680835
            ],
            [
              -82.404105,
              37.671302
            ],
            [
              -82.386286,
              37.697159
            ],
            [
              -82.413011,
              37.712465
            ],
            [
              -82.375963,
              37.739665
            ],
            [
              -82.33304432026151,
              37.7409688362322
            ],
            [
              -82.333349,
              37.7412
            ],
            [
              -82.333581,
              37.743283
            ],
            [
              -82.328221,
              37.74848
            ],
            [
              -82.321415,
              37.751269
            ],
            [
              -82.321012,
              37.755435
            ],
            [
              -82.327356,
              37.762233
            ],
            [
              -82.32946,
              37.762393
            ],
            [
              -82.331162,
              37.763125
            ],
            [
              -82.333816,
              37.765391
            ],
            [
              -82.333903,
              37.766306
            ],
            [
              -82.335981,
              37.7745
            ],
            [
              -82.337596,
              37.775369
            ],
            [
              -82.338895,
              37.776857
            ],
            [
              -82.339097,
              37.778276
            ],
            [
              -82.338377,
              37.780428
            ],
            [
              -82.339705,
              37.785509
            ],
            [
              -82.340455,
              37.786058
            ],
            [
              -82.348849,
              37.787178
            ],
            [
              -82.354275,
              37.793104
            ],
            [
              -82.356122,
              37.793859
            ],
            [
              -82.361199,
              37.794429
            ],
            [
              -82.363795,
              37.795435
            ],
            [
              -82.364979,
              37.796784
            ],
            [
              -82.365557,
              37.798318
            ],
            [
              -82.36778,
              37.800628
            ],
            [
              -82.369973,
              37.801749
            ],
            [
              -82.374867,
              37.80216
            ],
            [
              -82.377393,
              37.803009
            ],
            [
              -82.378514,
              37.80832
            ],
            [
              -82.37958,
              37.810907
            ],
            [
              -82.385259,
              37.81741
            ],
            [
              -82.386586,
              37.818212
            ],
            [
              -82.387769,
              37.818212
            ],
            [
              -82.39968,
              37.829935
            ],
            [
              -82.407874,
              37.835499
            ],
            [
              -82.408941,
              37.836644
            ],
            [
              -82.412172,
              37.844793
            ],
            [
              -82.413153,
              37.845343
            ],
            [
              -82.417367,
              37.845664
            ],
            [
              -82.420484,
              37.846809
            ],
            [
              -82.420484,
              37.847496
            ],
            [
              -82.4213880826174,
              37.8546737766706
            ],
            [
              -82.421983,
              37.859397
            ],
            [
              -82.423513,
              37.860313
            ],
            [
              -82.424264,
              37.861709
            ],
            [
              -82.42409,
              37.863037
            ],
            [
              -82.422127,
              37.863952
            ],
            [
              -82.417679,
              37.870658
            ],
            [
              -82.41869,
              37.872375
            ],
            [
              -82.419337,
              37.875095
            ],
            [
              -82.419204,
              37.882081
            ],
            [
              -82.419781,
              37.883821
            ],
            [
              -82.421484,
              37.885652
            ],
            [
              -82.429023,
              37.888285
            ],
            [
              -82.432113,
              37.88991
            ],
            [
              -82.435751,
              37.897028
            ],
            [
              -82.438582,
              37.900256
            ],
            [
              -82.447596,
              37.904352
            ],
            [
              -82.471223,
              37.899358
            ],
            [
              -82.472523,
              37.899243
            ],
            [
              -82.474574,
              37.900295
            ],
            [
              -82.487556,
              37.916975
            ],
            [
              -82.488279,
              37.91812
            ],
            [
              -82.49574,
              37.927043
            ],
            [
              -82.49814,
              37.9283
            ],
            [
              -82.501862,
              37.9332
            ],
            [
              -82.501948,
              37.934756
            ],
            [
              -82.500386,
              37.936518
            ],
            [
              -82.497285,
              37.942903
            ],
            [
              -82.4972999070143,
              37.9454908576785
            ],
            [
              -82.534871,
              37.962786
            ],
            [
              -82.580864,
              37.954074
            ],
            [
              -82.580117,
              37.933948
            ],
            [
              -82.601414,
              37.921778
            ],
            [
              -82.596296,
              37.890009
            ],
            [
              -82.612393,
              37.879272
            ],
            [
              -82.670887,
              37.851823
            ],
            [
              -82.644807,
              37.822939
            ],
            [
              -82.664322,
              37.806806
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "169",
      "COUNTYNS": "00516931",
      "AFFGEOID": "0500000US21169",
      "GEOID": "21169",
      "NAME": "Metcalfe",
      "LSAD": "06",
      "ALAND": 750178423,
      "AWATER": 3999881,
      "County_state": "Metcalfe,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.776962,
              37.138328
            ],
            [
              -85.771771,
              37.109733
            ],
            [
              -85.75609,
              37.109835
            ],
            [
              -85.759278,
              37.068573
            ],
            [
              -85.755426,
              37.026786
            ],
            [
              -85.750396,
              37.005902
            ],
            [
              -85.739255,
              36.841476
            ],
            [
              -85.613014,
              36.832717
            ],
            [
              -85.602421,
              36.829574
            ],
            [
              -85.596214,
              36.818039
            ],
            [
              -85.581216,
              36.822889
            ],
            [
              -85.574686,
              36.838241
            ],
            [
              -85.594208,
              36.857465
            ],
            [
              -85.601624,
              36.872196
            ],
            [
              -85.580531,
              36.868958
            ],
            [
              -85.560719,
              36.850857
            ],
            [
              -85.536982,
              36.862189
            ],
            [
              -85.507377,
              36.867509
            ],
            [
              -85.47422,
              36.893823
            ],
            [
              -85.45183,
              36.938138
            ],
            [
              -85.441755,
              36.954076
            ],
            [
              -85.503629,
              37.00768
            ],
            [
              -85.522736,
              37.067339
            ],
            [
              -85.526881,
              37.10945
            ],
            [
              -85.590897,
              37.116285
            ],
            [
              -85.632313,
              37.125056
            ],
            [
              -85.660898,
              37.143039
            ],
            [
              -85.686482,
              37.182427
            ],
            [
              -85.744221,
              37.169813
            ],
            [
              -85.759315,
              37.150242
            ],
            [
              -85.776962,
              37.138328
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "181",
      "COUNTYNS": "00516937",
      "AFFGEOID": "0500000US21181",
      "GEOID": "21181",
      "NAME": "Nicholas",
      "LSAD": "06",
      "ALAND": 505465376,
      "AWATER": 4302413,
      "County_state": "Nicholas,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.19405,
              38.37175
            ],
            [
              -84.166253,
              38.354913
            ],
            [
              -84.132621,
              38.304549
            ],
            [
              -84.130164,
              38.2952
            ],
            [
              -84.104135,
              38.285744
            ],
            [
              -84.120185,
              38.275516
            ],
            [
              -84.103647,
              38.269874
            ],
            [
              -84.095324,
              38.259014
            ],
            [
              -84.088983,
              38.273024
            ],
            [
              -84.076309,
              38.268895
            ],
            [
              -84.077741,
              38.256157
            ],
            [
              -84.057118,
              38.256187
            ],
            [
              -84.053141,
              38.239455
            ],
            [
              -84.062691,
              38.235203
            ],
            [
              -84.033999,
              38.223655
            ],
            [
              -84.027045,
              38.214105
            ],
            [
              -84.00814,
              38.219841
            ],
            [
              -83.994218,
              38.203903
            ],
            [
              -83.989145,
              38.218796
            ],
            [
              -83.977367,
              38.220048
            ],
            [
              -83.969281,
              38.20933
            ],
            [
              -83.977924,
              38.192118
            ],
            [
              -83.860897,
              38.287751
            ],
            [
              -83.848355,
              38.296949
            ],
            [
              -83.833728,
              38.315173
            ],
            [
              -83.848383,
              38.325325
            ],
            [
              -83.839597,
              38.338368
            ],
            [
              -83.857802,
              38.333853
            ],
            [
              -83.857428,
              38.351434
            ],
            [
              -83.866702,
              38.349336
            ],
            [
              -83.873023,
              38.362126
            ],
            [
              -83.899228,
              38.34834
            ],
            [
              -83.890839,
              38.363533
            ],
            [
              -83.916131,
              38.360152
            ],
            [
              -83.921376,
              38.383309
            ],
            [
              -83.9491,
              38.422824
            ],
            [
              -83.980068,
              38.439448
            ],
            [
              -83.999227,
              38.422286
            ],
            [
              -84.010146,
              38.432872
            ],
            [
              -84.073362,
              38.457407
            ],
            [
              -84.1021,
              38.459379
            ],
            [
              -84.19405,
              38.37175
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "193",
      "COUNTYNS": "00516943",
      "AFFGEOID": "0500000US21193",
      "GEOID": "21193",
      "NAME": "Perry",
      "LSAD": "06",
      "ALAND": 879750403,
      "AWATER": 7603771,
      "County_state": "Perry,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.547628,
              37.334418
            ],
            [
              -83.551777,
              37.287218
            ],
            [
              -83.521254,
              37.272913
            ],
            [
              -83.526907,
              37.258529
            ],
            [
              -83.511263,
              37.255073
            ],
            [
              -83.507886,
              37.235877
            ],
            [
              -83.483323,
              37.251788
            ],
            [
              -83.486828,
              37.271977
            ],
            [
              -83.478971,
              37.286035
            ],
            [
              -83.460992,
              37.293848
            ],
            [
              -83.454254,
              37.288168
            ],
            [
              -83.431933,
              37.290265
            ],
            [
              -83.431741,
              37.300359
            ],
            [
              -83.39873,
              37.320564
            ],
            [
              -83.395782,
              37.30967
            ],
            [
              -83.351774,
              37.295682
            ],
            [
              -83.353494,
              37.284175
            ],
            [
              -83.335985,
              37.272514
            ],
            [
              -83.344161,
              37.260624
            ],
            [
              -83.32462,
              37.225561
            ],
            [
              -83.311091,
              37.229843
            ],
            [
              -83.298641,
              37.214494
            ],
            [
              -83.287001,
              37.217273
            ],
            [
              -83.281887,
              37.207628
            ],
            [
              -83.263181,
              37.200234
            ],
            [
              -83.255031,
              37.20423
            ],
            [
              -83.232648,
              37.184548
            ],
            [
              -83.202231,
              37.174803
            ],
            [
              -83.201921,
              37.16673
            ],
            [
              -83.22689,
              37.154549
            ],
            [
              -83.218196,
              37.144109
            ],
            [
              -83.22734,
              37.136848
            ],
            [
              -83.20557,
              37.128927
            ],
            [
              -83.194034,
              37.109099
            ],
            [
              -83.167233,
              37.102372
            ],
            [
              -83.176669,
              37.081017
            ],
            [
              -83.164412,
              37.07356
            ],
            [
              -83.178075,
              37.062527
            ],
            [
              -83.178303,
              37.038446
            ],
            [
              -83.186192,
              37.035265
            ],
            [
              -83.182104,
              37.021115
            ],
            [
              -83.162296,
              37.021013
            ],
            [
              -83.12209,
              37.000997
            ],
            [
              -83.110859,
              36.992264
            ],
            [
              -83.05534,
              37.017391
            ],
            [
              -83.052243,
              37.035114
            ],
            [
              -83.071912,
              37.042272
            ],
            [
              -83.057875,
              37.0615
            ],
            [
              -83.04279,
              37.062006
            ],
            [
              -83.040771,
              37.080059
            ],
            [
              -83.051146,
              37.092297
            ],
            [
              -83.058818,
              37.124079
            ],
            [
              -83.035611,
              37.15724
            ],
            [
              -83.038261,
              37.165468
            ],
            [
              -83.016741,
              37.177487
            ],
            [
              -83.000142,
              37.19571
            ],
            [
              -83.005811,
              37.205225
            ],
            [
              -83.029024,
              37.20829
            ],
            [
              -83.032983,
              37.216831
            ],
            [
              -83.054308,
              37.215818
            ],
            [
              -83.067404,
              37.231258
            ],
            [
              -83.066377,
              37.244395
            ],
            [
              -83.101563,
              37.235437
            ],
            [
              -83.115956,
              37.240689
            ],
            [
              -83.126805,
              37.235973
            ],
            [
              -83.120402,
              37.246927
            ],
            [
              -83.127249,
              37.268401
            ],
            [
              -83.119303,
              37.295432
            ],
            [
              -83.110426,
              37.298793
            ],
            [
              -83.116229,
              37.310207
            ],
            [
              -83.110702,
              37.331891
            ],
            [
              -83.124348,
              37.404973
            ],
            [
              -83.199734,
              37.41466
            ],
            [
              -83.202294,
              37.430429
            ],
            [
              -83.214735,
              37.443538
            ],
            [
              -83.269933,
              37.398657
            ],
            [
              -83.302525,
              37.386061
            ],
            [
              -83.310514,
              37.377684
            ],
            [
              -83.332641,
              37.379675
            ],
            [
              -83.343122,
              37.364891
            ],
            [
              -83.357802,
              37.357896
            ],
            [
              -83.368244,
              37.358071
            ],
            [
              -83.36971,
              37.34647
            ],
            [
              -83.38165,
              37.338266
            ],
            [
              -83.403988,
              37.338332
            ],
            [
              -83.422235,
              37.350821
            ],
            [
              -83.449454,
              37.378853
            ],
            [
              -83.46297,
              37.373006
            ],
            [
              -83.476721,
              37.377534
            ],
            [
              -83.486696,
              37.358947
            ],
            [
              -83.536265,
              37.330291
            ],
            [
              -83.547628,
              37.334418
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "123",
      "COUNTYNS": "00516908",
      "AFFGEOID": "0500000US21123",
      "GEOID": "21123",
      "NAME": "Larue",
      "LSAD": "06",
      "ALAND": 677386387,
      "AWATER": 5418290,
      "County_state": "Larue,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.894419,
              37.472374
            ],
            [
              -85.892804,
              37.440308
            ],
            [
              -85.750375,
              37.431179
            ],
            [
              -85.733317,
              37.425328
            ],
            [
              -85.657332,
              37.421996
            ],
            [
              -85.665391,
              37.436756
            ],
            [
              -85.638227,
              37.454075
            ],
            [
              -85.634193,
              37.471785
            ],
            [
              -85.606891,
              37.461022
            ],
            [
              -85.583497,
              37.469917
            ],
            [
              -85.528855,
              37.454067
            ],
            [
              -85.498842,
              37.482951
            ],
            [
              -85.488837,
              37.467285
            ],
            [
              -85.46625,
              37.465595
            ],
            [
              -85.473727,
              37.491839
            ],
            [
              -85.491748,
              37.515673
            ],
            [
              -85.503001,
              37.54909
            ],
            [
              -85.52129,
              37.554343
            ],
            [
              -85.542921,
              37.562622
            ],
            [
              -85.551131,
              37.554024
            ],
            [
              -85.547123,
              37.53031
            ],
            [
              -85.564792,
              37.522955
            ],
            [
              -85.592397,
              37.534319
            ],
            [
              -85.606164,
              37.54825
            ],
            [
              -85.62394,
              37.540489
            ],
            [
              -85.623323,
              37.551604
            ],
            [
              -85.604162,
              37.569121
            ],
            [
              -85.606677,
              37.590796
            ],
            [
              -85.590438,
              37.596291
            ],
            [
              -85.611426,
              37.607346
            ],
            [
              -85.613991,
              37.615873
            ],
            [
              -85.592543,
              37.61379
            ],
            [
              -85.57983,
              37.626684
            ],
            [
              -85.568125,
              37.622946
            ],
            [
              -85.573343,
              37.638601
            ],
            [
              -85.583736,
              37.644319
            ],
            [
              -85.601108,
              37.639894
            ],
            [
              -85.595259,
              37.653152
            ],
            [
              -85.592088,
              37.693479
            ],
            [
              -85.606042,
              37.700837
            ],
            [
              -85.625818,
              37.694179
            ],
            [
              -85.637889,
              37.696683
            ],
            [
              -85.645286,
              37.716101
            ],
            [
              -85.671795,
              37.732283
            ],
            [
              -85.680488,
              37.732284
            ],
            [
              -85.689768,
              37.719288
            ],
            [
              -85.735949,
              37.654598
            ],
            [
              -85.776346,
              37.638647
            ],
            [
              -85.775059,
              37.630855
            ],
            [
              -85.801934,
              37.623303
            ],
            [
              -85.797304,
              37.608075
            ],
            [
              -85.819258,
              37.584407
            ],
            [
              -85.820885,
              37.574071
            ],
            [
              -85.838629,
              37.568731
            ],
            [
              -85.875807,
              37.543997
            ],
            [
              -85.894419,
              37.472374
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "195",
      "COUNTYNS": "00516944",
      "AFFGEOID": "0500000US21195",
      "GEOID": "21195",
      "NAME": "Pike",
      "LSAD": "06",
      "ALAND": 2037621821,
      "AWATER": 4756763,
      "County_state": "Pike,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.724557,
              37.281035
            ],
            [
              -82.732693,
              37.271502
            ],
            [
              -82.72407,
              37.256694
            ],
            [
              -82.699886,
              37.255427
            ],
            [
              -82.705125,
              37.24267
            ],
            [
              -82.684264,
              37.228796
            ],
            [
              -82.671515,
              37.227359
            ],
            [
              -82.628921,
              37.247804
            ],
            [
              -82.602065,
              37.229737
            ],
            [
              -82.589803,
              37.208977
            ],
            [
              -82.5653752160821,
              37.1960922172527
            ],
            [
              -82.565329,
              37.196118
            ],
            [
              -82.558178,
              37.199606
            ],
            [
              -82.55386432928549,
              37.202819153295295
            ],
            [
              -82.553835,
              37.202841
            ],
            [
              -82.550372,
              37.204458
            ],
            [
              -82.531787,
              37.212097
            ],
            [
              -82.528746,
              37.213742
            ],
            [
              -82.524464,
              37.214957
            ],
            [
              -82.5165008662769,
              37.2186259093968
            ],
            [
              -82.508342,
              37.222385
            ],
            [
              -82.507895,
              37.222727
            ],
            [
              -82.498858,
              37.227044
            ],
            [
              -82.496308,
              37.227562
            ],
            [
              -82.487317,
              37.230578
            ],
            [
              -82.486439,
              37.231204
            ],
            [
              -82.473275,
              37.235569
            ],
            [
              -82.463073,
              37.238292
            ],
            [
              -82.457016,
              37.238288
            ],
            [
              -82.451998,
              37.242559
            ],
            [
              -82.449164,
              37.243908
            ],
            [
              -82.435728,
              37.246491
            ],
            [
              -82.431022,
              37.246773
            ],
            [
              -82.418085,
              37.251331
            ],
            [
              -82.385663,
              37.259631
            ],
            [
              -82.383285,
              37.260154
            ],
            [
              -82.376595,
              37.2599
            ],
            [
              -82.364535,
              37.264415
            ],
            [
              -82.355343,
              37.26522
            ],
            [
              -82.350948,
              37.267077
            ],
            [
              -82.342068,
              37.274109
            ],
            [
              -82.341849,
              37.280886
            ],
            [
              -82.336942,
              37.279737
            ],
            [
              -82.33157,
              37.282211
            ],
            [
              -82.324619,
              37.28318
            ],
            [
              -82.318957,
              37.287524
            ],
            [
              -82.317395,
              37.290975
            ],
            [
              -82.317083,
              37.293635
            ],
            [
              -82.316028,
              37.294879
            ],
            [
              -82.3143123901533,
              37.296224662827
            ],
            [
              -82.309415,
              37.300066
            ],
            [
              -82.291908,
              37.311642
            ],
            [
              -82.254352,
              37.337745
            ],
            [
              -82.202688,
              37.374041
            ],
            [
              -82.201745,
              37.375108
            ],
            [
              -82.124854,
              37.427272
            ],
            [
              -82.062809,
              37.470911
            ],
            [
              -82.050888,
              37.480527
            ],
            [
              -81.968297,
              37.537798
            ],
            [
              -81.965401,
              37.541171
            ],
            [
              -81.964971,
              37.543026
            ],
            [
              -81.970147,
              37.546504
            ],
            [
              -81.976386,
              37.545426
            ],
            [
              -81.980841,
              37.542357
            ],
            [
              -81.982479,
              37.541807
            ],
            [
              -81.987511,
              37.542835
            ],
            [
              -81.989092,
              37.542514
            ],
            [
              -81.992597,
              37.538323
            ],
            [
              -81.994033,
              37.537612
            ],
            [
              -82.007412,
              37.533677
            ],
            [
              -82.009194,
              37.533243
            ],
            [
              -82.013966,
              37.533564
            ],
            [
              -82.01592,
              37.534321
            ],
            [
              -82.016925,
              37.538556
            ],
            [
              -82.018878,
              37.540572
            ],
            [
              -82.021006,
              37.540526
            ],
            [
              -82.025261,
              37.538261
            ],
            [
              -82.028826,
              37.537667
            ],
            [
              -82.036932,
              37.542729
            ],
            [
              -82.038024,
              37.546918
            ],
            [
              -82.038972,
              37.547926
            ],
            [
              -82.042825,
              37.548361
            ],
            [
              -82.04478,
              37.546713
            ],
            [
              -82.045155,
              37.544516
            ],
            [
              -82.04481,
              37.541814
            ],
            [
              -82.054787,
              37.536756
            ],
            [
              -82.05746,
              37.536893
            ],
            [
              -82.061256,
              37.536001
            ],
            [
              -82.063326,
              37.536206
            ],
            [
              -82.064418,
              37.53687
            ],
            [
              -82.064792,
              37.539021
            ],
            [
              -82.063671,
              37.541929
            ],
            [
              -82.064418,
              37.544516
            ],
            [
              -82.073246,
              37.555023
            ],
            [
              -82.07503,
              37.555824
            ],
            [
              -82.084605,
              37.55541
            ],
            [
              -82.089178,
              37.556004
            ],
            [
              -82.101946,
              37.558106
            ],
            [
              -82.102263,
              37.559456
            ],
            [
              -82.103127,
              37.560097
            ],
            [
              -82.116584,
              37.559588
            ],
            [
              -82.124372,
              37.57641
            ],
            [
              -82.124307,
              37.577806
            ],
            [
              -82.125601,
              37.579021
            ],
            [
              -82.127321,
              37.586667
            ],
            [
              -82.130165,
              37.591544
            ],
            [
              -82.131977,
              37.593537
            ],
            [
              -82.141555,
              37.595166
            ],
            [
              -82.146419,
              37.59265
            ],
            [
              -82.148434,
              37.59091
            ],
            [
              -82.156718,
              37.59279
            ],
            [
              -82.157609,
              37.596773
            ],
            [
              -82.164454,
              37.608014
            ],
            [
              -82.168137,
              37.608495
            ],
            [
              -82.169057,
              37.609869
            ],
            [
              -82.1692,
              37.613028
            ],
            [
              -82.176682,
              37.618202
            ],
            [
              -82.18143,
              37.621842
            ],
            [
              -82.182438,
              37.623971
            ],
            [
              -82.181398,
              37.626798
            ],
            [
              -82.1858895205048,
              37.6274578192887
            ],
            [
              -82.186694,
              37.627576
            ],
            [
              -82.187298,
              37.626935
            ],
            [
              -82.192394,
              37.625606
            ],
            [
              -82.201201,
              37.627895
            ],
            [
              -82.202467,
              37.627483
            ],
            [
              -82.203388,
              37.626132
            ],
            [
              -82.204337,
              37.625811
            ],
            [
              -82.209691,
              37.625103
            ],
            [
              -82.21349,
              37.625408
            ],
            [
              -82.215649,
              37.626244
            ],
            [
              -82.214815,
              37.627572
            ],
            [
              -82.2202,
              37.633912
            ],
            [
              -82.220257,
              37.634781
            ],
            [
              -82.219078,
              37.635903
            ],
            [
              -82.21669,
              37.639956
            ],
            [
              -82.216691,
              37.641329
            ],
            [
              -82.21934,
              37.642931
            ],
            [
              -82.223602,
              37.644554
            ],
            [
              -82.224956,
              37.647003
            ],
            [
              -82.225535,
              37.651947
            ],
            [
              -82.226111,
              37.653092
            ],
            [
              -82.23939,
              37.661465
            ],
            [
              -82.240773,
              37.661785
            ],
            [
              -82.243911,
              37.660959
            ],
            [
              -82.252273,
              37.656907
            ],
            [
              -82.257111,
              37.656749
            ],
            [
              -82.267962,
              37.662407
            ],
            [
              -82.272021,
              37.663782
            ],
            [
              -82.277876,
              37.669998
            ],
            [
              -82.286446,
              37.670127
            ],
            [
              -82.288174,
              37.668227
            ],
            [
              -82.291773,
              37.669143
            ],
            [
              -82.294393,
              37.670448
            ],
            [
              -82.29471,
              37.672257
            ],
            [
              -82.294134,
              37.673378
            ],
            [
              -82.294392,
              37.677957
            ],
            [
              -82.294737,
              37.678277
            ],
            [
              -82.296118,
              37.686174
            ],
            [
              -82.296262,
              37.687067
            ],
            [
              -82.297788,
              37.687753
            ],
            [
              -82.301504,
              37.690775
            ],
            [
              -82.302886,
              37.693683
            ],
            [
              -82.301964,
              37.696223
            ],
            [
              -82.297325,
              37.699817
            ],
            [
              -82.296634,
              37.702403
            ],
            [
              -82.298074,
              37.704143
            ],
            [
              -82.300954,
              37.706135
            ],
            [
              -82.305679,
              37.706708
            ],
            [
              -82.307235,
              37.707669
            ],
            [
              -82.310665,
              37.7133
            ],
            [
              -82.311097,
              37.717329
            ],
            [
              -82.311702,
              37.718771
            ],
            [
              -82.316197,
              37.721541
            ],
            [
              -82.317869,
              37.72772
            ],
            [
              -82.318302,
              37.733053
            ],
            [
              -82.318879,
              37.733763
            ],
            [
              -82.319686,
              37.734404
            ],
            [
              -82.326834,
              37.736257
            ],
            [
              -82.33304432026151,
              37.7409688362322
            ],
            [
              -82.375963,
              37.739665
            ],
            [
              -82.413011,
              37.712465
            ],
            [
              -82.386286,
              37.697159
            ],
            [
              -82.404105,
              37.671302
            ],
            [
              -82.418143,
              37.680835
            ],
            [
              -82.456134,
              37.668795
            ],
            [
              -82.465872,
              37.683279
            ],
            [
              -82.490263,
              37.678411
            ],
            [
              -82.503249,
              37.688646
            ],
            [
              -82.520561,
              37.694305
            ],
            [
              -82.556433,
              37.691132
            ],
            [
              -82.563561,
              37.684844
            ],
            [
              -82.614538,
              37.670322
            ],
            [
              -82.598278,
              37.642226
            ],
            [
              -82.623576,
              37.504051
            ],
            [
              -82.597786,
              37.502749
            ],
            [
              -82.587124,
              37.473051
            ],
            [
              -82.588627,
              37.446259
            ],
            [
              -82.601937,
              37.436071
            ],
            [
              -82.604638,
              37.410217
            ],
            [
              -82.621927,
              37.393584
            ],
            [
              -82.618803,
              37.386661
            ],
            [
              -82.633029,
              37.379312
            ],
            [
              -82.653625,
              37.380304
            ],
            [
              -82.666169,
              37.370026
            ],
            [
              -82.651981,
              37.351461
            ],
            [
              -82.669844,
              37.3213
            ],
            [
              -82.672375,
              37.306841
            ],
            [
              -82.693716,
              37.306103
            ],
            [
              -82.700241,
              37.290513
            ],
            [
              -82.710805,
              37.285323
            ],
            [
              -82.724557,
              37.281035
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "197",
      "COUNTYNS": "00516945",
      "AFFGEOID": "0500000US21197",
      "GEOID": "21197",
      "NAME": "Powell",
      "LSAD": "06",
      "ALAND": 463556264,
      "AWATER": 2956914,
      "County_state": "Powell,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.00645,
              37.860051
            ],
            [
              -84.02737,
              37.869454
            ],
            [
              -84.001967,
              37.837592
            ],
            [
              -83.991595,
              37.850158
            ],
            [
              -83.962554,
              37.844644
            ],
            [
              -83.963262,
              37.800136
            ],
            [
              -83.91179,
              37.766732
            ],
            [
              -83.906251,
              37.759363
            ],
            [
              -83.865441,
              37.752299
            ],
            [
              -83.830347,
              37.765247
            ],
            [
              -83.821069,
              37.745573
            ],
            [
              -83.803931,
              37.756748
            ],
            [
              -83.777592,
              37.756776
            ],
            [
              -83.768963,
              37.741097
            ],
            [
              -83.74845,
              37.731836
            ],
            [
              -83.722651,
              37.728947
            ],
            [
              -83.7221,
              37.71642
            ],
            [
              -83.707974,
              37.716463
            ],
            [
              -83.63106,
              37.825372
            ],
            [
              -83.66296,
              37.834735
            ],
            [
              -83.667352,
              37.852447
            ],
            [
              -83.75035,
              37.896024
            ],
            [
              -83.768783,
              37.918371
            ],
            [
              -83.781787,
              37.908312
            ],
            [
              -83.814919,
              37.900227
            ],
            [
              -83.828286,
              37.906381
            ],
            [
              -83.878662,
              37.905062
            ],
            [
              -83.886242,
              37.916507
            ],
            [
              -83.908162,
              37.916717
            ],
            [
              -83.96631,
              37.930768
            ],
            [
              -83.991769,
              37.917776
            ],
            [
              -83.990118,
              37.899804
            ],
            [
              -84.00645,
              37.860051
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "221",
      "COUNTYNS": "00516957",
      "AFFGEOID": "0500000US21221",
      "GEOID": "21221",
      "NAME": "Trigg",
      "LSAD": "06",
      "ALAND": 1143307587,
      "AWATER": 103330127,
      "County_state": "Trigg,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.157347,
              36.867183
            ],
            [
              -88.129944,
              36.807546
            ],
            [
              -88.110831,
              36.74715
            ],
            [
              -88.080126,
              36.706739
            ],
            [
              -88.070532,
              36.678118
            ],
            [
              -88.044772,
              36.677983
            ],
            [
              -88.011792,
              36.677025
            ],
            [
              -87.872062,
              36.665089
            ],
            [
              -87.849567,
              36.663701
            ],
            [
              -87.853204,
              36.633247
            ],
            [
              -87.744768,
              36.636151
            ],
            [
              -87.69419132838901,
              36.6370710817038
            ],
            [
              -87.682749,
              36.703366
            ],
            [
              -87.673774,
              36.755557
            ],
            [
              -87.673816,
              36.815471
            ],
            [
              -87.671871,
              36.878892
            ],
            [
              -87.659724,
              36.966397
            ],
            [
              -87.733368,
              37.002103
            ],
            [
              -87.764868,
              36.974737
            ],
            [
              -87.858088,
              36.950714
            ],
            [
              -87.876996,
              36.960116
            ],
            [
              -87.890669,
              36.960144
            ],
            [
              -88.002697,
              36.910168
            ],
            [
              -88.049765,
              36.898036
            ],
            [
              -88.157347,
              36.867183
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "069",
      "COUNTYNS": "00516881",
      "AFFGEOID": "0500000US21069",
      "GEOID": "21069",
      "NAME": "Fleming",
      "LSAD": "06",
      "ALAND": 902719721,
      "AWATER": 7185948,
      "County_state": "Fleming,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.982823,
              38.451825
            ],
            [
              -83.980068,
              38.439448
            ],
            [
              -83.9491,
              38.422824
            ],
            [
              -83.921376,
              38.383309
            ],
            [
              -83.916131,
              38.360152
            ],
            [
              -83.890839,
              38.363533
            ],
            [
              -83.899228,
              38.34834
            ],
            [
              -83.873023,
              38.362126
            ],
            [
              -83.866702,
              38.349336
            ],
            [
              -83.857428,
              38.351434
            ],
            [
              -83.857802,
              38.333853
            ],
            [
              -83.839597,
              38.338368
            ],
            [
              -83.848383,
              38.325325
            ],
            [
              -83.833728,
              38.315173
            ],
            [
              -83.848355,
              38.296949
            ],
            [
              -83.822957,
              38.300809
            ],
            [
              -83.812719,
              38.282192
            ],
            [
              -83.801869,
              38.279276
            ],
            [
              -83.795422,
              38.292154
            ],
            [
              -83.784572,
              38.294785
            ],
            [
              -83.77488,
              38.285753
            ],
            [
              -83.768357,
              38.293533
            ],
            [
              -83.74408,
              38.284713
            ],
            [
              -83.726984,
              38.293353
            ],
            [
              -83.73823,
              38.268149
            ],
            [
              -83.710803,
              38.26817
            ],
            [
              -83.717272,
              38.251942
            ],
            [
              -83.695607,
              38.243617
            ],
            [
              -83.691245,
              38.234395
            ],
            [
              -83.694425,
              38.208937
            ],
            [
              -83.683079,
              38.214377
            ],
            [
              -83.671375,
              38.196855
            ],
            [
              -83.659177,
              38.201521
            ],
            [
              -83.646546,
              38.170963
            ],
            [
              -83.635195,
              38.187525
            ],
            [
              -83.614985,
              38.193341
            ],
            [
              -83.586792,
              38.21828
            ],
            [
              -83.563762,
              38.216918
            ],
            [
              -83.556308,
              38.237285
            ],
            [
              -83.524999,
              38.254617
            ],
            [
              -83.52039,
              38.267719
            ],
            [
              -83.50716,
              38.269447
            ],
            [
              -83.514281,
              38.292054
            ],
            [
              -83.505396,
              38.294132
            ],
            [
              -83.501543,
              38.315804
            ],
            [
              -83.488086,
              38.324496
            ],
            [
              -83.49799,
              38.333886
            ],
            [
              -83.498562,
              38.349822
            ],
            [
              -83.481983,
              38.359716
            ],
            [
              -83.470207,
              38.355217
            ],
            [
              -83.452848,
              38.381799
            ],
            [
              -83.466592,
              38.398908
            ],
            [
              -83.497434,
              38.401587
            ],
            [
              -83.51489,
              38.414678
            ],
            [
              -83.552275,
              38.419926
            ],
            [
              -83.581613,
              38.431258
            ],
            [
              -83.574865,
              38.444344
            ],
            [
              -83.59027,
              38.489576
            ],
            [
              -83.599189,
              38.501992
            ],
            [
              -83.624755,
              38.503638
            ],
            [
              -83.628552,
              38.521994
            ],
            [
              -83.641852,
              38.525381
            ],
            [
              -83.721744,
              38.503982
            ],
            [
              -83.85928,
              38.456348
            ],
            [
              -83.930348,
              38.492278
            ],
            [
              -83.982823,
              38.451825
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "011",
      "COUNTYNS": "00516852",
      "AFFGEOID": "0500000US21011",
      "GEOID": "21011",
      "NAME": "Bath",
      "LSAD": "06",
      "ALAND": 722081570,
      "AWATER": 13486212,
      "County_state": "Bath,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.977924,
              38.192118
            ],
            [
              -83.979324,
              38.176613
            ],
            [
              -83.965253,
              38.170542
            ],
            [
              -83.974602,
              38.15876
            ],
            [
              -83.881353,
              38.136517
            ],
            [
              -83.820799,
              38.08111
            ],
            [
              -83.801686,
              38.041702
            ],
            [
              -83.756914,
              38.006315
            ],
            [
              -83.75943,
              37.998731
            ],
            [
              -83.730982,
              38.010524
            ],
            [
              -83.718497,
              38.003397
            ],
            [
              -83.705902,
              38.012729
            ],
            [
              -83.689207,
              38.012152
            ],
            [
              -83.659293,
              38.028261
            ],
            [
              -83.634565,
              38.026875
            ],
            [
              -83.60982,
              38.007159
            ],
            [
              -83.604662,
              38.014425
            ],
            [
              -83.580859,
              38.011568
            ],
            [
              -83.572955,
              38.023212
            ],
            [
              -83.574989,
              38.058883
            ],
            [
              -83.501094,
              38.049025
            ],
            [
              -83.498846,
              38.05062
            ],
            [
              -83.480476,
              38.069143
            ],
            [
              -83.468096,
              38.065289
            ],
            [
              -83.463285,
              38.079711
            ],
            [
              -83.506823,
              38.087343
            ],
            [
              -83.513122,
              38.069387
            ],
            [
              -83.53442,
              38.094693
            ],
            [
              -83.515259,
              38.108089
            ],
            [
              -83.525436,
              38.11935
            ],
            [
              -83.554723,
              38.112439
            ],
            [
              -83.549329,
              38.131816
            ],
            [
              -83.572287,
              38.147376
            ],
            [
              -83.585216,
              38.145478
            ],
            [
              -83.603278,
              38.124047
            ],
            [
              -83.621575,
              38.14912
            ],
            [
              -83.614237,
              38.160824
            ],
            [
              -83.591639,
              38.16916
            ],
            [
              -83.614045,
              38.186633
            ],
            [
              -83.619805,
              38.175569
            ],
            [
              -83.635195,
              38.187525
            ],
            [
              -83.646546,
              38.170963
            ],
            [
              -83.659177,
              38.201521
            ],
            [
              -83.671375,
              38.196855
            ],
            [
              -83.683079,
              38.214377
            ],
            [
              -83.694425,
              38.208937
            ],
            [
              -83.691245,
              38.234395
            ],
            [
              -83.695607,
              38.243617
            ],
            [
              -83.717272,
              38.251942
            ],
            [
              -83.710803,
              38.26817
            ],
            [
              -83.73823,
              38.268149
            ],
            [
              -83.726984,
              38.293353
            ],
            [
              -83.74408,
              38.284713
            ],
            [
              -83.768357,
              38.293533
            ],
            [
              -83.77488,
              38.285753
            ],
            [
              -83.784572,
              38.294785
            ],
            [
              -83.795422,
              38.292154
            ],
            [
              -83.801869,
              38.279276
            ],
            [
              -83.812719,
              38.282192
            ],
            [
              -83.822957,
              38.300809
            ],
            [
              -83.848355,
              38.296949
            ],
            [
              -83.860897,
              38.287751
            ],
            [
              -83.977924,
              38.192118
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "051",
      "COUNTYNS": "00516872",
      "AFFGEOID": "0500000US21051",
      "GEOID": "21051",
      "NAME": "Clay",
      "LSAD": "06",
      "ALAND": 1215461477,
      "AWATER": 4763611,
      "County_state": "Clay,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.947545,
              37.191071
            ],
            [
              -83.969571,
              37.168876
            ],
            [
              -83.945488,
              37.14871
            ],
            [
              -83.926632,
              37.112856
            ],
            [
              -83.903664,
              37.110524
            ],
            [
              -83.885673,
              37.118767
            ],
            [
              -83.882899,
              37.109017
            ],
            [
              -83.865329,
              37.103654
            ],
            [
              -83.862526,
              37.091361
            ],
            [
              -83.887856,
              37.074636
            ],
            [
              -83.871315,
              37.054919
            ],
            [
              -83.856181,
              37.042458
            ],
            [
              -83.833982,
              37.043338
            ],
            [
              -83.831618,
              37.031077
            ],
            [
              -83.815307,
              37.026889
            ],
            [
              -83.79723,
              37.036813
            ],
            [
              -83.774677,
              37.037366
            ],
            [
              -83.780327,
              37.022999
            ],
            [
              -83.774896,
              37.015237
            ],
            [
              -83.743926,
              37.026771
            ],
            [
              -83.732623,
              37.01658
            ],
            [
              -83.736163,
              37.006489
            ],
            [
              -83.700092,
              36.986582
            ],
            [
              -83.66045,
              36.976225
            ],
            [
              -83.641079,
              36.959517
            ],
            [
              -83.612327,
              36.959711
            ],
            [
              -83.607899,
              36.952718
            ],
            [
              -83.589521,
              36.955786
            ],
            [
              -83.520687,
              36.93755
            ],
            [
              -83.509083,
              36.938509
            ],
            [
              -83.523037,
              36.998487
            ],
            [
              -83.510863,
              37.00357
            ],
            [
              -83.526481,
              37.021472
            ],
            [
              -83.539049,
              37.050575
            ],
            [
              -83.539184,
              37.070107
            ],
            [
              -83.553101,
              37.079281
            ],
            [
              -83.535284,
              37.103096
            ],
            [
              -83.551237,
              37.119056
            ],
            [
              -83.551303,
              37.16751
            ],
            [
              -83.505113,
              37.204213
            ],
            [
              -83.528922,
              37.231259
            ],
            [
              -83.507886,
              37.235877
            ],
            [
              -83.511263,
              37.255073
            ],
            [
              -83.526907,
              37.258529
            ],
            [
              -83.541436,
              37.267791
            ],
            [
              -83.567509,
              37.267539
            ],
            [
              -83.565566,
              37.275716
            ],
            [
              -83.583132,
              37.294827
            ],
            [
              -83.597279,
              37.292255
            ],
            [
              -83.608829,
              37.30853
            ],
            [
              -83.622383,
              37.314781
            ],
            [
              -83.638572,
              37.344166
            ],
            [
              -83.652996,
              37.357911
            ],
            [
              -83.668138,
              37.355232
            ],
            [
              -83.678553,
              37.337055
            ],
            [
              -83.718172,
              37.33883
            ],
            [
              -83.784975,
              37.348717
            ],
            [
              -83.783761,
              37.334733
            ],
            [
              -83.821693,
              37.331255
            ],
            [
              -83.863192,
              37.306571
            ],
            [
              -83.88198,
              37.306932
            ],
            [
              -83.876838,
              37.294652
            ],
            [
              -83.899891,
              37.266795
            ],
            [
              -83.901476,
              37.25382
            ],
            [
              -83.921797,
              37.261803
            ],
            [
              -83.943718,
              37.250551
            ],
            [
              -83.940715,
              37.206972
            ],
            [
              -83.947545,
              37.191071
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "055",
      "COUNTYNS": "00516874",
      "AFFGEOID": "0500000US21055",
      "GEOID": "21055",
      "NAME": "Crittenden",
      "LSAD": "06",
      "ALAND": 932275951,
      "AWATER": 28974004,
      "County_state": "Crittenden,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.358436,
              37.40486
            ],
            [
              -88.35850600784909,
              37.4048167301695
            ],
            [
              -88.35447,
              37.397026
            ],
            [
              -88.31785,
              37.397927
            ],
            [
              -88.299114,
              37.380058
            ],
            [
              -88.306191,
              37.368875
            ],
            [
              -88.293628,
              37.335376
            ],
            [
              -88.267358,
              37.31732
            ],
            [
              -88.21898,
              37.273145
            ],
            [
              -88.19647,
              37.219388
            ],
            [
              -88.220338,
              37.183013
            ],
            [
              -88.198089,
              37.174915
            ],
            [
              -88.187289,
              37.15454
            ],
            [
              -88.193519,
              37.147256
            ],
            [
              -88.197339,
              37.13161
            ],
            [
              -88.182447,
              37.133553
            ],
            [
              -88.184188,
              37.126189
            ],
            [
              -88.160162,
              37.133067
            ],
            [
              -88.163384,
              37.143154
            ],
            [
              -88.153199,
              37.149531
            ],
            [
              -88.136327,
              37.147704
            ],
            [
              -88.131299,
              37.158485
            ],
            [
              -88.118213,
              37.155035
            ],
            [
              -88.095781,
              37.179684
            ],
            [
              -88.083583,
              37.18412
            ],
            [
              -88.089566,
              37.2068
            ],
            [
              -88.080515,
              37.21189
            ],
            [
              -88.089493,
              37.222066
            ],
            [
              -88.052483,
              37.237663
            ],
            [
              -87.801046,
              37.379445
            ],
            [
              -87.818952,
              37.388185
            ],
            [
              -87.844075,
              37.374867
            ],
            [
              -87.859907,
              37.380332
            ],
            [
              -87.860173,
              37.391573
            ],
            [
              -87.84636,
              37.394341
            ],
            [
              -87.847694,
              37.420701
            ],
            [
              -87.868638,
              37.405345
            ],
            [
              -87.897996,
              37.403206
            ],
            [
              -87.903034,
              37.398197
            ],
            [
              -87.922849,
              37.411159
            ],
            [
              -87.929999,
              37.409167
            ],
            [
              -87.938325,
              37.425635
            ],
            [
              -87.936385,
              37.43865
            ],
            [
              -87.943955,
              37.465225
            ],
            [
              -87.932952,
              37.480052
            ],
            [
              -87.955182,
              37.47893
            ],
            [
              -87.961488,
              37.472031
            ],
            [
              -87.982807,
              37.474208
            ],
            [
              -87.966496,
              37.484621
            ],
            [
              -87.971977,
              37.499299
            ],
            [
              -87.980556,
              37.49396
            ],
            [
              -87.996918,
              37.506268
            ],
            [
              -87.998811,
              37.538583
            ],
            [
              -88.015572,
              37.54721
            ],
            [
              -88.02106,
              37.52853
            ],
            [
              -88.034092,
              37.513602
            ],
            [
              -88.047489,
              37.518272
            ],
            [
              -88.061342239857,
              37.505326559522494
            ],
            [
              -88.061292,
              37.505232
            ],
            [
              -88.062563,
              37.495951
            ],
            [
              -88.064115,
              37.492013
            ],
            [
              -88.06295,
              37.489385
            ],
            [
              -88.062174,
              37.489057
            ],
            [
              -88.062294,
              37.487837
            ],
            [
              -88.064234,
              37.484548
            ],
            [
              -88.067728,
              37.481593
            ],
            [
              -88.068504,
              37.481921
            ],
            [
              -88.072386,
              37.483563
            ],
            [
              -88.077987,
              37.480146
            ],
            [
              -88.084171,
              37.472699
            ],
            [
              -88.087664,
              37.471059
            ],
            [
              -88.09038,
              37.471059
            ],
            [
              -88.091156,
              37.471715
            ],
            [
              -88.091156,
              37.472699
            ],
            [
              -88.095818,
              37.473025
            ],
            [
              -88.109417,
              37.472369
            ],
            [
              -88.12801,
              37.470507
            ],
            [
              -88.132628,
              37.471555
            ],
            [
              -88.135142,
              37.471626
            ],
            [
              -88.157061,
              37.466937
            ],
            [
              -88.171764,
              37.465612
            ],
            [
              -88.175283,
              37.46379
            ],
            [
              -88.188615,
              37.461896
            ],
            [
              -88.206923,
              37.460188
            ],
            [
              -88.225012,
              37.45739
            ],
            [
              -88.237784,
              37.456811
            ],
            [
              -88.255193,
              37.456748
            ],
            [
              -88.281667,
              37.452596
            ],
            [
              -88.297821,
              37.446816
            ],
            [
              -88.312585,
              37.440591
            ],
            [
              -88.317525,
              37.436178
            ],
            [
              -88.321199,
              37.434705
            ],
            [
              -88.330622,
              37.429316
            ],
            [
              -88.333183,
              37.42721
            ],
            [
              -88.348405,
              37.410726
            ],
            [
              -88.358436,
              37.40486
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "075",
      "COUNTYNS": "00516884",
      "AFFGEOID": "0500000US21075",
      "GEOID": "21075",
      "NAME": "Fulton",
      "LSAD": "06",
      "ALAND": 533358346,
      "AWATER": 64084949,
      "County_state": "Fulton,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.405654,
              36.528165
            ],
            [
              -89.417293,
              36.499033
            ],
            [
              -89.403913,
              36.499141
            ],
            [
              -89.391044,
              36.499079
            ],
            [
              -89.381792,
              36.500062
            ],
            [
              -89.380085,
              36.500416
            ],
            [
              -89.356593,
              36.502195
            ],
            [
              -89.34605560857419,
              36.503209748794795
            ],
            [
              -89.346053,
              36.50321
            ],
            [
              -89.300284,
              36.507147
            ],
            [
              -89.282298,
              36.506782
            ],
            [
              -89.279091,
              36.506511
            ],
            [
              -89.211409,
              36.50563
            ],
            [
              -89.163429,
              36.504526
            ],
            [
              -89.163224,
              36.504522
            ],
            [
              -89.119805,
              36.503647
            ],
            [
              -89.117537,
              36.503603
            ],
            [
              -89.090146,
              36.503392
            ],
            [
              -89.072118,
              36.503249
            ],
            [
              -89.058871,
              36.503157
            ],
            [
              -89.034649,
              36.502964
            ],
            [
              -89.0105563150147,
              36.5027112308143
            ],
            [
              -89.010439,
              36.50271
            ],
            [
              -89.006825,
              36.502684
            ],
            [
              -89.000063,
              36.502633
            ],
            [
              -88.964471,
              36.502191
            ],
            [
              -88.89951,
              36.502218
            ],
            [
              -88.884557,
              36.501704
            ],
            [
              -88.874725,
              36.502446
            ],
            [
              -88.834865800811,
              36.5029112012574
            ],
            [
              -88.903877,
              36.576975
            ],
            [
              -88.928042,
              36.578769
            ],
            [
              -88.940873,
              36.591514
            ],
            [
              -89.026629,
              36.592124
            ],
            [
              -89.147098,
              36.649117
            ],
            [
              -89.17468187525279,
              36.6504726524698
            ],
            [
              -89.174741,
              36.650416
            ],
            [
              -89.183321,
              36.644694
            ],
            [
              -89.187749,
              36.641115
            ],
            [
              -89.192542,
              36.635997
            ],
            [
              -89.197654,
              36.628936
            ],
            [
              -89.199136,
              36.625649
            ],
            [
              -89.200902,
              36.618177
            ],
            [
              -89.202607,
              36.601576
            ],
            [
              -89.213563,
              36.580119
            ],
            [
              -89.217447,
              36.576159
            ],
            [
              -89.227319,
              36.569375
            ],
            [
              -89.236542,
              36.566824
            ],
            [
              -89.258318,
              36.564948
            ],
            [
              -89.259994,
              36.565149
            ],
            [
              -89.268057,
              36.568908
            ],
            [
              -89.27171,
              36.571387
            ],
            [
              -89.278935,
              36.577699
            ],
            [
              -89.294637,
              36.593729
            ],
            [
              -89.301502,
              36.604138
            ],
            [
              -89.313405,
              36.62012
            ],
            [
              -89.318154,
              36.625059
            ],
            [
              -89.326731,
              36.632186
            ],
            [
              -89.32758872176659,
              36.6321935043326
            ],
            [
              -89.334046,
              36.63225
            ],
            [
              -89.343753,
              36.630991
            ],
            [
              -89.35651,
              36.628439
            ],
            [
              -89.365548,
              36.625059
            ],
            [
              -89.371002,
              36.62084
            ],
            [
              -89.375453,
              36.615719
            ],
            [
              -89.376367,
              36.613868
            ],
            [
              -89.378012,
              36.608096
            ],
            [
              -89.380643,
              36.59113
            ],
            [
              -89.382762,
              36.583603
            ],
            [
              -89.395909,
              36.559649
            ],
            [
              -89.396627,
              36.556429
            ],
            [
              -89.396811,
              36.551975
            ],
            [
              -89.398685,
              36.542329
            ],
            [
              -89.405654,
              36.528165
            ]
          ]
        ],
        [
          [
            [
              -89.571509,
              36.552569
            ],
            [
              -89.571241,
              36.547343
            ],
            [
              -89.570071,
              36.544387
            ],
            [
              -89.565804,
              36.536988
            ],
            [
              -89.560344,
              36.525436
            ],
            [
              -89.558349,
              36.522099
            ],
            [
              -89.554321,
              36.517022
            ],
            [
              -89.542955,
              36.504957
            ],
            [
              -89.539232,
              36.497934
            ],
            [
              -89.498036,
              36.497887
            ],
            [
              -89.492537,
              36.497775
            ],
            [
              -89.485427,
              36.497491
            ],
            [
              -89.482474,
              36.502131
            ],
            [
              -89.477186,
              36.50707
            ],
            [
              -89.47246,
              36.513741
            ],
            [
              -89.468668,
              36.521291
            ],
            [
              -89.465888,
              36.529946
            ],
            [
              -89.465445,
              36.536163
            ],
            [
              -89.467761,
              36.546847
            ],
            [
              -89.473341,
              36.559918
            ],
            [
              -89.479093,
              36.568206
            ],
            [
              -89.480893,
              36.569771
            ],
            [
              -89.484836,
              36.571821
            ],
            [
              -89.500076,
              36.576305
            ],
            [
              -89.514468,
              36.577803
            ],
            [
              -89.518702,
              36.578698
            ],
            [
              -89.522338,
              36.58018
            ],
            [
              -89.527583,
              36.581147
            ],
            [
              -89.542459,
              36.580566
            ],
            [
              -89.546113,
              36.579989
            ],
            [
              -89.55264,
              36.577178
            ],
            [
              -89.558089,
              36.573514
            ],
            [
              -89.563185,
              36.568749
            ],
            [
              -89.566817,
              36.564216
            ],
            [
              -89.569807,
              36.558119
            ],
            [
              -89.571509,
              36.552569
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "019",
      "COUNTYNS": "00516856",
      "AFFGEOID": "0500000US21019",
      "GEOID": "21019",
      "NAME": "Boyd",
      "LSAD": "06",
      "ALAND": 414050310,
      "AWATER": 5584870,
      "County_state": "Boyd,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.810637,
              38.393024
            ],
            [
              -82.817454,
              38.373935
            ],
            [
              -82.771737,
              38.362588
            ],
            [
              -82.781462,
              38.345438
            ],
            [
              -82.794206,
              38.243772
            ],
            [
              -82.766528,
              38.235161
            ],
            [
              -82.7368,
              38.237487
            ],
            [
              -82.699471,
              38.261913
            ],
            [
              -82.614304,
              38.266078
            ],
            [
              -82.604625,
              38.247303
            ],
            [
              -82.60423,
              38.247303
            ],
            [
              -82.59497,
              38.245453
            ],
            [
              -82.586061,
              38.245616
            ],
            [
              -82.584001,
              38.246371
            ],
            [
              -82.581796,
              38.248592
            ],
            [
              -82.578254,
              38.254809
            ],
            [
              -82.574656,
              38.263873
            ],
            [
              -82.5746,
              38.274721
            ],
            [
              -82.57672,
              38.277513
            ],
            [
              -82.578782,
              38.281747
            ],
            [
              -82.57948,
              38.284928
            ],
            [
              -82.579743,
              38.291726
            ],
            [
              -82.582823,
              38.295478
            ],
            [
              -82.583056,
              38.296829
            ],
            [
              -82.58146,
              38.300445
            ],
            [
              -82.578352,
              38.305458
            ],
            [
              -82.572893,
              38.311981
            ],
            [
              -82.571964,
              38.313606
            ],
            [
              -82.571877,
              38.315781
            ],
            [
              -82.572691,
              38.318801
            ],
            [
              -82.5759667693653,
              38.326111869014596
            ],
            [
              -82.576936,
              38.328275
            ],
            [
              -82.585363,
              38.334064
            ],
            [
              -82.587951,
              38.338595
            ],
            [
              -82.589724,
              38.340265
            ],
            [
              -82.592543,
              38.34166
            ],
            [
              -82.596525,
              38.342849
            ],
            [
              -82.597979,
              38.344909
            ],
            [
              -82.598189,
              38.357885
            ],
            [
              -82.597524,
              38.364843
            ],
            [
              -82.596654,
              38.367338
            ],
            [
              -82.593952,
              38.371847
            ],
            [
              -82.593008,
              38.375082
            ],
            [
              -82.5949049758321,
              38.3811788515581
            ],
            [
              -82.595382,
              38.382712
            ],
            [
              -82.595369,
              38.382722
            ],
            [
              -82.599273,
              38.388738
            ],
            [
              -82.599737,
              38.39037
            ],
            [
              -82.599241,
              38.39317
            ],
            [
              -82.597801,
              38.395154
            ],
            [
              -82.595001,
              38.40133
            ],
            [
              -82.597033,
              38.409937
            ],
            [
              -82.597113,
              38.412881
            ],
            [
              -82.596281,
              38.417681
            ],
            [
              -82.593673,
              38.421809
            ],
            [
              -82.596921,
              38.426705
            ],
            [
              -82.600761,
              38.437425
            ],
            [
              -82.604089,
              38.459841
            ],
            [
              -82.608202,
              38.468049
            ],
            [
              -82.610458,
              38.471457
            ],
            [
              -82.613802,
              38.474529
            ],
            [
              -82.618474,
              38.477089
            ],
            [
              -82.624106,
              38.479697
            ],
            [
              -82.6297414981797,
              38.4816659645872
            ],
            [
              -82.637707,
              38.484449
            ],
            [
              -82.648395,
              38.490368
            ],
            [
              -82.657051,
              38.496816
            ],
            [
              -82.6654809868313,
              38.5057370829218
            ],
            [
              -82.689433,
              38.489028
            ],
            [
              -82.769047,
              38.433715
            ],
            [
              -82.777227,
              38.414939
            ],
            [
              -82.810637,
              38.393024
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "039",
      "COUNTYNS": "00516866",
      "AFFGEOID": "0500000US21039",
      "GEOID": "21039",
      "NAME": "Carlisle",
      "LSAD": "06",
      "ALAND": 490632724,
      "AWATER": 24722530,
      "County_state": "Carlisle,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.179229,
              36.812915
            ],
            [
              -89.178749,
              36.809928
            ],
            [
              -89.177417,
              36.807269
            ],
            [
              -89.17505946393709,
              36.803870850192894
            ],
            [
              -89.171069,
              36.798119
            ],
            [
              -89.168458,
              36.795571
            ],
            [
              -89.160877,
              36.790914
            ],
            [
              -89.155891,
              36.789126
            ],
            [
              -89.144208,
              36.788353
            ],
            [
              -89.13321,
              36.788228
            ],
            [
              -89.128923,
              36.787677
            ],
            [
              -89.12353,
              36.785309
            ],
            [
              -89.1234809771075,
              36.7852576789117
            ],
            [
              -89.104158,
              36.797285
            ],
            [
              -89.061815,
              36.787018
            ],
            [
              -89.067265,
              36.772763
            ],
            [
              -89.002277,
              36.771562
            ],
            [
              -88.813229,
              36.773115
            ],
            [
              -88.812897,
              36.946864
            ],
            [
              -88.815952,
              36.9541
            ],
            [
              -88.845695,
              36.947415
            ],
            [
              -88.852221,
              36.952224
            ],
            [
              -88.888211,
              36.945253
            ],
            [
              -88.905058,
              36.937442
            ],
            [
              -88.915802,
              36.942461
            ],
            [
              -88.952552,
              36.92845
            ],
            [
              -88.962716,
              36.930885
            ],
            [
              -88.988811,
              36.919491
            ],
            [
              -89.013869,
              36.930413
            ],
            [
              -89.02645,
              36.923771
            ],
            [
              -89.049763,
              36.926289
            ],
            [
              -89.048567,
              36.935912
            ],
            [
              -89.071206,
              36.93182
            ],
            [
              -89.063836,
              36.923224
            ],
            [
              -89.080505,
              36.915544
            ],
            [
              -89.10076200668469,
              36.9440018170751
            ],
            [
              -89.100766,
              36.943973
            ],
            [
              -89.102137,
              36.939154
            ],
            [
              -89.109377,
              36.920066
            ],
            [
              -89.117567,
              36.887356
            ],
            [
              -89.119813,
              36.882792
            ],
            [
              -89.126702,
              36.872073
            ],
            [
              -89.131944,
              36.857437
            ],
            [
              -89.137969,
              36.847349
            ],
            [
              -89.147674,
              36.847148
            ],
            [
              -89.153513,
              36.8466
            ],
            [
              -89.15733,
              36.843305
            ],
            [
              -89.159466,
              36.842505
            ],
            [
              -89.166841,
              36.842529
            ],
            [
              -89.1704,
              36.841522
            ],
            [
              -89.173419,
              36.839806
            ],
            [
              -89.177177,
              36.835779
            ],
            [
              -89.178153,
              36.834586
            ],
            [
              -89.178888,
              36.831368
            ],
            [
              -89.178574,
              36.816554
            ],
            [
              -89.179229,
              36.812915
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "101",
      "COUNTYNS": "00516897",
      "AFFGEOID": "0500000US21101",
      "GEOID": "21101",
      "NAME": "Henderson",
      "LSAD": "06",
      "ALAND": 1130483404,
      "AWATER": 76387046,
      "County_state": "Henderson,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.921744,
              37.907885
            ],
            [
              -87.9270921983773,
              37.9017059445802
            ],
            [
              -87.815093,
              37.762423
            ],
            [
              -87.811087,
              37.771188
            ],
            [
              -87.79203,
              37.770295
            ],
            [
              -87.799262,
              37.760487
            ],
            [
              -87.778968,
              37.754255
            ],
            [
              -87.773438,
              37.76666
            ],
            [
              -87.76386,
              37.756557
            ],
            [
              -87.766038,
              37.749092
            ],
            [
              -87.755293,
              37.728555
            ],
            [
              -87.750952,
              37.678162
            ],
            [
              -87.754488,
              37.66939
            ],
            [
              -87.734545,
              37.638376
            ],
            [
              -87.495331,
              37.647547
            ],
            [
              -87.472811,
              37.664869
            ],
            [
              -87.414344,
              37.673601
            ],
            [
              -87.408547,
              37.683563
            ],
            [
              -87.393511,
              37.724569
            ],
            [
              -87.370759,
              37.737814
            ],
            [
              -87.322738,
              37.741875
            ],
            [
              -87.289784,
              37.761078
            ],
            [
              -87.279756,
              37.773425
            ],
            [
              -87.271162,
              37.781914
            ],
            [
              -87.297317,
              37.785206
            ],
            [
              -87.310481,
              37.799007
            ],
            [
              -87.302875,
              37.808944
            ],
            [
              -87.323402,
              37.819239
            ],
            [
              -87.3025990430289,
              37.8985582968574
            ],
            [
              -87.320036,
              37.905741
            ],
            [
              -87.331765,
              37.908253
            ],
            [
              -87.334165,
              37.908205
            ],
            [
              -87.335397,
              37.907565
            ],
            [
              -87.344933,
              37.911164
            ],
            [
              -87.352614,
              37.916124
            ],
            [
              -87.35471,
              37.918252
            ],
            [
              -87.358294,
              37.92054
            ],
            [
              -87.361638,
              37.921004
            ],
            [
              -87.363622,
              37.922348
            ],
            [
              -87.372327,
              37.930028
            ],
            [
              -87.372711,
              37.930556
            ],
            [
              -87.372039,
              37.931708
            ],
            [
              -87.372439,
              37.932044
            ],
            [
              -87.37282473753419,
              37.9322194789603
            ],
            [
              -87.380247,
              37.935596
            ],
            [
              -87.3912341733316,
              37.938554388228894
            ],
            [
              -87.40116,
              37.941227
            ],
            [
              -87.402632,
              37.942267
            ],
            [
              -87.418585,
              37.944763
            ],
            [
              -87.428521,
              37.944811
            ],
            [
              -87.436859,
              37.944192
            ],
            [
              -87.447786,
              37.942427
            ],
            [
              -87.450458,
              37.941451
            ],
            [
              -87.4511762695734,
              37.9410807495909
            ],
            [
              -87.465514,
              37.93369
            ],
            [
              -87.486347,
              37.920218
            ],
            [
              -87.490411,
              37.916682
            ],
            [
              -87.501131,
              37.909162
            ],
            [
              -87.507483,
              37.90673
            ],
            [
              -87.511499,
              37.906426
            ],
            [
              -87.520284,
              37.912618
            ],
            [
              -87.531532,
              37.916298
            ],
            [
              -87.545901,
              37.922666
            ],
            [
              -87.5485030002246,
              37.9239979763054
            ],
            [
              -87.551277,
              37.925418
            ],
            [
              -87.559342,
              37.931146
            ],
            [
              -87.56587,
              37.93793
            ],
            [
              -87.568398,
              37.941226
            ],
            [
              -87.57203,
              37.947466
            ],
            [
              -87.574287,
              37.954842
            ],
            [
              -87.573415,
              37.962642
            ],
            [
              -87.574715,
              37.967742
            ],
            [
              -87.577915,
              37.971542
            ],
            [
              -87.581115,
              37.973442
            ],
            [
              -87.585916,
              37.975442
            ],
            [
              -87.5878975398239,
              37.9757468522806
            ],
            [
              -87.589816,
              37.976042
            ],
            [
              -87.592916,
              37.975842
            ],
            [
              -87.596716,
              37.974842
            ],
            [
              -87.601416,
              37.972542
            ],
            [
              -87.603816,
              37.968942
            ],
            [
              -87.605216,
              37.965142
            ],
            [
              -87.605216,
              37.961442
            ],
            [
              -87.603516,
              37.958942
            ],
            [
              -87.606216,
              37.949642
            ],
            [
              -87.610816,
              37.944602
            ],
            [
              -87.619488,
              37.938538
            ],
            [
              -87.625616,
              37.933442
            ],
            [
              -87.62896,
              37.926714
            ],
            [
              -87.628416,
              37.92145
            ],
            [
              -87.626256,
              37.916138
            ],
            [
              -87.623296,
              37.910746
            ],
            [
              -87.620272,
              37.906922
            ],
            [
              -87.608479,
              37.898794
            ],
            [
              -87.601967,
              37.895722
            ],
            [
              -87.597118,
              37.892394
            ],
            [
              -87.591582,
              37.887194
            ],
            [
              -87.588426,
              37.868791
            ],
            [
              -87.588729,
              37.860984
            ],
            [
              -87.591504,
              37.856642
            ],
            [
              -87.606599,
              37.838669
            ],
            [
              -87.612426,
              37.83384
            ],
            [
              -87.615399,
              37.831974
            ],
            [
              -87.625014,
              37.829077
            ],
            [
              -87.635806,
              37.827015
            ],
            [
              -87.645858,
              37.825899
            ],
            [
              -87.655171,
              37.826037
            ],
            [
              -87.666522,
              37.827455
            ],
            [
              -87.672397,
              37.829127
            ],
            [
              -87.675538,
              37.831732
            ],
            [
              -87.679188,
              37.836321
            ],
            [
              -87.680689,
              37.84062
            ],
            [
              -87.6819,
              37.84641
            ],
            [
              -87.681633,
              37.855917
            ],
            [
              -87.6754,
              37.865946
            ],
            [
              -87.673186,
              37.868412
            ],
            [
              -87.668879,
              37.871497
            ],
            [
              -87.666175,
              37.874146
            ],
            [
              -87.664101,
              37.877176
            ],
            [
              -87.66282,
              37.881449
            ],
            [
              -87.662865,
              37.885578
            ],
            [
              -87.665025,
              37.893514
            ],
            [
              -87.666481,
              37.895786
            ],
            [
              -87.671457,
              37.899498
            ],
            [
              -87.67573,
              37.90193
            ],
            [
              -87.680338,
              37.903274
            ],
            [
              -87.684018,
              37.903498
            ],
            [
              -87.688338,
              37.902474
            ],
            [
              -87.7006847636268,
              37.8973691919489
            ],
            [
              -87.700915,
              37.897274
            ],
            [
              -87.710675,
              37.893898
            ],
            [
              -87.717971,
              37.89257
            ],
            [
              -87.723635,
              37.892058
            ],
            [
              -87.7333,
              37.894346
            ],
            [
              -87.740148,
              37.89465
            ],
            [
              -87.76226,
              37.890906
            ],
            [
              -87.771004,
              37.886261
            ],
            [
              -87.773015,
              37.884544
            ],
            [
              -87.783643,
              37.877759
            ],
            [
              -87.786407,
              37.876556
            ],
            [
              -87.790273896178,
              37.8758313335006
            ],
            [
              -87.7909,
              37.875714
            ],
            [
              -87.795185,
              37.875273
            ],
            [
              -87.808013,
              37.875191
            ],
            [
              -87.830578,
              37.876516
            ],
            [
              -87.833883,
              37.877324
            ],
            [
              -87.838102,
              37.879769
            ],
            [
              -87.841193,
              37.882325
            ],
            [
              -87.841615,
              37.883393
            ],
            [
              -87.841693,
              37.887685
            ],
            [
              -87.844691,
              37.892048
            ],
            [
              -87.84559,
              37.893151
            ],
            [
              -87.857243,
              37.900649
            ],
            [
              -87.858738,
              37.902779
            ],
            [
              -87.863097,
              37.911858
            ],
            [
              -87.865558,
              37.915056
            ],
            [
              -87.87254,
              37.920999
            ],
            [
              -87.877325,
              37.924034
            ],
            [
              -87.883321,
              37.926238
            ],
            [
              -87.892471,
              37.92793
            ],
            [
              -87.898062,
              37.927514
            ],
            [
              -87.904789,
              37.924892
            ],
            [
              -87.921744,
              37.907885
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "125",
      "COUNTYNS": "00516909",
      "AFFGEOID": "0500000US21125",
      "GEOID": "21125",
      "NAME": "Laurel",
      "LSAD": "06",
      "ALAND": 1123945963,
      "AWATER": 25218355,
      "County_state": "Laurel,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.358024,
              36.9594
            ],
            [
              -84.368212,
              36.945588
            ],
            [
              -84.345872,
              36.939496
            ],
            [
              -84.297416,
              36.945931
            ],
            [
              -84.263562,
              36.962326
            ],
            [
              -84.256476,
              36.972864
            ],
            [
              -84.257947,
              36.953942
            ],
            [
              -84.246431,
              36.947283
            ],
            [
              -84.234196,
              36.941132
            ],
            [
              -84.222806,
              36.960089
            ],
            [
              -84.209091,
              36.947178
            ],
            [
              -84.190204,
              36.947713
            ],
            [
              -84.183131,
              36.939369
            ],
            [
              -84.160847,
              36.947737
            ],
            [
              -84.133853,
              36.968931
            ],
            [
              -84.123994,
              36.970187
            ],
            [
              -84.101851,
              36.954313
            ],
            [
              -84.091853,
              36.955992
            ],
            [
              -84.067665,
              36.974883
            ],
            [
              -84.045593,
              36.97646
            ],
            [
              -84.032312,
              36.988628
            ],
            [
              -84.000192,
              36.980598
            ],
            [
              -83.959138,
              36.977273
            ],
            [
              -83.931678,
              36.99641
            ],
            [
              -83.922336,
              37.036413
            ],
            [
              -83.905545,
              37.032441
            ],
            [
              -83.871315,
              37.054919
            ],
            [
              -83.887856,
              37.074636
            ],
            [
              -83.862526,
              37.091361
            ],
            [
              -83.865329,
              37.103654
            ],
            [
              -83.882899,
              37.109017
            ],
            [
              -83.885673,
              37.118767
            ],
            [
              -83.903664,
              37.110524
            ],
            [
              -83.926632,
              37.112856
            ],
            [
              -83.945488,
              37.14871
            ],
            [
              -83.969571,
              37.168876
            ],
            [
              -83.947545,
              37.191071
            ],
            [
              -83.940715,
              37.206972
            ],
            [
              -83.943718,
              37.250551
            ],
            [
              -84.005606,
              37.253343
            ],
            [
              -84.050439,
              37.268075
            ],
            [
              -84.048486,
              37.281492
            ],
            [
              -84.076297,
              37.287769
            ],
            [
              -84.107254,
              37.308072
            ],
            [
              -84.108069,
              37.326554
            ],
            [
              -84.120756,
              37.335892
            ],
            [
              -84.137898,
              37.319759
            ],
            [
              -84.14855,
              37.302452
            ],
            [
              -84.178782,
              37.299279
            ],
            [
              -84.200824,
              37.280196
            ],
            [
              -84.216241,
              37.280256
            ],
            [
              -84.217827,
              37.242432
            ],
            [
              -84.229731,
              37.247926
            ],
            [
              -84.24103,
              37.228586
            ],
            [
              -84.254315,
              37.239082
            ],
            [
              -84.262828,
              37.217912
            ],
            [
              -84.271264,
              37.211562
            ],
            [
              -84.258941,
              37.205821
            ],
            [
              -84.280989,
              37.197471
            ],
            [
              -84.265629,
              37.181708
            ],
            [
              -84.296793,
              37.171337
            ],
            [
              -84.300166,
              37.169642
            ],
            [
              -84.2888,
              37.162061
            ],
            [
              -84.289076,
              37.15174
            ],
            [
              -84.28941,
              37.12956
            ],
            [
              -84.296573,
              37.12128
            ],
            [
              -84.278585,
              37.118064
            ],
            [
              -84.292959,
              37.11356
            ],
            [
              -84.280174,
              37.101893
            ],
            [
              -84.285209,
              37.088898
            ],
            [
              -84.301489,
              37.087544
            ],
            [
              -84.325223,
              37.062179
            ],
            [
              -84.317583,
              37.057884
            ],
            [
              -84.317027,
              37.040492
            ],
            [
              -84.303016,
              37.038396
            ],
            [
              -84.309575,
              37.025609
            ],
            [
              -84.331786,
              37.02438
            ],
            [
              -84.313422,
              37.007077
            ],
            [
              -84.31706,
              36.999333
            ],
            [
              -84.349247,
              36.989593
            ],
            [
              -84.344732,
              36.971702
            ],
            [
              -84.358024,
              36.9594
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "237",
      "COUNTYNS": "00516965",
      "AFFGEOID": "0500000US21237",
      "GEOID": "21237",
      "NAME": "Wolfe",
      "LSAD": "06",
      "ALAND": 575419099,
      "AWATER": 1505157,
      "County_state": "Wolfe,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.63106,
              37.825372
            ],
            [
              -83.707974,
              37.716463
            ],
            [
              -83.628307,
              37.67889
            ],
            [
              -83.616649,
              37.666925
            ],
            [
              -83.522308,
              37.638516
            ],
            [
              -83.495922,
              37.645617
            ],
            [
              -83.495488,
              37.619595
            ],
            [
              -83.458716,
              37.630476
            ],
            [
              -83.433623,
              37.65654
            ],
            [
              -83.431626,
              37.673727
            ],
            [
              -83.418474,
              37.690729
            ],
            [
              -83.390714,
              37.699776
            ],
            [
              -83.364582,
              37.684223
            ],
            [
              -83.33067,
              37.674569
            ],
            [
              -83.317939,
              37.682894
            ],
            [
              -83.294862,
              37.682389
            ],
            [
              -83.280125,
              37.674466
            ],
            [
              -83.268313,
              37.67995
            ],
            [
              -83.248692,
              37.669616
            ],
            [
              -83.257445,
              37.685463
            ],
            [
              -83.242808,
              37.698119
            ],
            [
              -83.262172,
              37.712657
            ],
            [
              -83.281249,
              37.718921
            ],
            [
              -83.279881,
              37.731544
            ],
            [
              -83.296932,
              37.744688
            ],
            [
              -83.326426,
              37.753115
            ],
            [
              -83.334089,
              37.763677
            ],
            [
              -83.329526,
              37.783269
            ],
            [
              -83.335866,
              37.778836
            ],
            [
              -83.367364,
              37.799825
            ],
            [
              -83.387593,
              37.800357
            ],
            [
              -83.386437,
              37.813675
            ],
            [
              -83.433048,
              37.819112
            ],
            [
              -83.45532,
              37.842679
            ],
            [
              -83.484258,
              37.84391
            ],
            [
              -83.478128,
              37.851814
            ],
            [
              -83.49508,
              37.861906
            ],
            [
              -83.517002,
              37.851733
            ],
            [
              -83.523783,
              37.831496
            ],
            [
              -83.55141,
              37.821615
            ],
            [
              -83.590333,
              37.818343
            ],
            [
              -83.620646,
              37.834844
            ],
            [
              -83.63106,
              37.825372
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "033",
      "COUNTYNS": "00516863",
      "AFFGEOID": "0500000US21033",
      "GEOID": "21033",
      "NAME": "Caldwell",
      "LSAD": "06",
      "ALAND": 893006262,
      "AWATER": 8700648,
      "County_state": "Caldwell,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.083583,
              37.18412
            ],
            [
              -88.095781,
              37.179684
            ],
            [
              -88.042142,
              37.174756
            ],
            [
              -87.975186,
              37.067509
            ],
            [
              -87.876996,
              36.960116
            ],
            [
              -87.858088,
              36.950714
            ],
            [
              -87.764868,
              36.974737
            ],
            [
              -87.733368,
              37.002103
            ],
            [
              -87.710912,
              37.021607
            ],
            [
              -87.69254,
              37.100022
            ],
            [
              -87.680617,
              37.149239
            ],
            [
              -87.697442,
              37.161746
            ],
            [
              -87.705227,
              37.16105
            ],
            [
              -87.713683,
              37.17869
            ],
            [
              -87.729802,
              37.178319
            ],
            [
              -87.733335,
              37.193099
            ],
            [
              -87.746908,
              37.188599
            ],
            [
              -87.74797,
              37.209199
            ],
            [
              -87.763961,
              37.194889
            ],
            [
              -87.774694,
              37.198127
            ],
            [
              -87.771544,
              37.214127
            ],
            [
              -87.788947,
              37.232474
            ],
            [
              -87.805029,
              37.241083
            ],
            [
              -87.794352,
              37.255002
            ],
            [
              -87.799608,
              37.27982
            ],
            [
              -87.793339,
              37.291053
            ],
            [
              -87.807078,
              37.293649
            ],
            [
              -87.795808,
              37.319119
            ],
            [
              -87.820217,
              37.317932
            ],
            [
              -87.820689,
              37.300444
            ],
            [
              -87.850706,
              37.321643
            ],
            [
              -87.83202,
              37.339269
            ],
            [
              -87.814823,
              37.342608
            ],
            [
              -87.813413,
              37.350645
            ],
            [
              -87.827887,
              37.352986
            ],
            [
              -87.801046,
              37.379445
            ],
            [
              -88.052483,
              37.237663
            ],
            [
              -88.089493,
              37.222066
            ],
            [
              -88.080515,
              37.21189
            ],
            [
              -88.089566,
              37.2068
            ],
            [
              -88.083583,
              37.18412
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "021",
      "COUNTYNS": "00516857",
      "AFFGEOID": "0500000US21021",
      "GEOID": "21021",
      "NAME": "Boyle",
      "LSAD": "06",
      "ALAND": 467093981,
      "AWATER": 6460546,
      "County_state": "Boyle,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.030172,
              37.63127
            ],
            [
              -85.039674,
              37.545232
            ],
            [
              -85.002408,
              37.548525
            ],
            [
              -85.002183,
              37.533628
            ],
            [
              -84.8981,
              37.532113
            ],
            [
              -84.847183,
              37.548455
            ],
            [
              -84.783689,
              37.576624
            ],
            [
              -84.74838,
              37.586405
            ],
            [
              -84.752858,
              37.588541
            ],
            [
              -84.756826,
              37.588362
            ],
            [
              -84.756242,
              37.592089
            ],
            [
              -84.753965,
              37.59355
            ],
            [
              -84.755371,
              37.596096
            ],
            [
              -84.746126,
              37.597576
            ],
            [
              -84.744171,
              37.597354
            ],
            [
              -84.745057,
              37.594186
            ],
            [
              -84.747778,
              37.586472
            ],
            [
              -84.746986,
              37.585612
            ],
            [
              -84.731734,
              37.589344
            ],
            [
              -84.688516,
              37.612363
            ],
            [
              -84.683807,
              37.62099
            ],
            [
              -84.658381,
              37.617752
            ],
            [
              -84.652122,
              37.625856
            ],
            [
              -84.658296,
              37.634501
            ],
            [
              -84.652294,
              37.650892
            ],
            [
              -84.659285,
              37.654726
            ],
            [
              -84.679414,
              37.645836
            ],
            [
              -84.679045,
              37.636811
            ],
            [
              -84.693741,
              37.640927
            ],
            [
              -84.678867,
              37.652846
            ],
            [
              -84.698217,
              37.668506
            ],
            [
              -84.681664,
              37.67358
            ],
            [
              -84.685794,
              37.687605
            ],
            [
              -84.697056,
              37.68163
            ],
            [
              -84.704273,
              37.697633
            ],
            [
              -84.722387,
              37.692803
            ],
            [
              -84.743698,
              37.69839
            ],
            [
              -84.733815,
              37.710752
            ],
            [
              -84.744888,
              37.713075
            ],
            [
              -84.793574,
              37.717249
            ],
            [
              -84.807867,
              37.704614
            ],
            [
              -84.999833,
              37.680177
            ],
            [
              -85.025021,
              37.678854
            ],
            [
              -85.026526,
              37.664427
            ],
            [
              -85.030172,
              37.63127
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "199",
      "COUNTYNS": "00516946",
      "AFFGEOID": "0500000US21199",
      "GEOID": "21199",
      "NAME": "Pulaski",
      "LSAD": "06",
      "ALAND": 1705274742,
      "AWATER": 48546459,
      "County_state": "Pulaski,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.907062,
              37.087166
            ],
            [
              -84.905778,
              37.047187
            ],
            [
              -84.863737,
              37.00802
            ],
            [
              -84.835712,
              36.997612
            ],
            [
              -84.808563,
              36.987609
            ],
            [
              -84.783794,
              36.978483
            ],
            [
              -84.770172,
              36.957735
            ],
            [
              -84.730741,
              36.966542
            ],
            [
              -84.707138,
              36.980967
            ],
            [
              -84.67462,
              36.974372
            ],
            [
              -84.620241,
              36.896366
            ],
            [
              -84.600842,
              36.879659
            ],
            [
              -84.582434,
              36.892521
            ],
            [
              -84.589926,
              36.88259
            ],
            [
              -84.578158,
              36.867133
            ],
            [
              -84.416816,
              36.94692
            ],
            [
              -84.382425,
              36.953195
            ],
            [
              -84.366104,
              36.962885
            ],
            [
              -84.358024,
              36.9594
            ],
            [
              -84.344732,
              36.971702
            ],
            [
              -84.349247,
              36.989593
            ],
            [
              -84.31706,
              36.999333
            ],
            [
              -84.313422,
              37.007077
            ],
            [
              -84.331786,
              37.02438
            ],
            [
              -84.309575,
              37.025609
            ],
            [
              -84.303016,
              37.038396
            ],
            [
              -84.317027,
              37.040492
            ],
            [
              -84.317583,
              37.057884
            ],
            [
              -84.325223,
              37.062179
            ],
            [
              -84.301489,
              37.087544
            ],
            [
              -84.285209,
              37.088898
            ],
            [
              -84.280174,
              37.101893
            ],
            [
              -84.292959,
              37.11356
            ],
            [
              -84.278585,
              37.118064
            ],
            [
              -84.296573,
              37.12128
            ],
            [
              -84.28941,
              37.12956
            ],
            [
              -84.289076,
              37.15174
            ],
            [
              -84.291795,
              37.160554
            ],
            [
              -84.300475,
              37.16394
            ],
            [
              -84.39688,
              37.241711
            ],
            [
              -84.446286,
              37.27905
            ],
            [
              -84.502932,
              37.328801
            ],
            [
              -84.556461,
              37.355729
            ],
            [
              -84.574221,
              37.343176
            ],
            [
              -84.720712,
              37.238328
            ],
            [
              -84.811396,
              37.17371
            ],
            [
              -84.901112,
              37.116297
            ],
            [
              -84.907062,
              37.087166
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "099",
      "COUNTYNS": "00516896",
      "AFFGEOID": "0500000US21099",
      "GEOID": "21099",
      "NAME": "Hart",
      "LSAD": "06",
      "ALAND": 1068313096,
      "AWATER": 13912256,
      "County_state": "Hart,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.15616,
              37.344863
            ],
            [
              -86.157589,
              37.335649
            ],
            [
              -86.049133,
              37.215285
            ],
            [
              -86.056443,
              37.167208
            ],
            [
              -85.992192,
              37.157084
            ],
            [
              -85.906336,
              37.148377
            ],
            [
              -85.857058,
              37.151137
            ],
            [
              -85.802215,
              37.163092
            ],
            [
              -85.744221,
              37.169813
            ],
            [
              -85.686482,
              37.182427
            ],
            [
              -85.67899,
              37.249996
            ],
            [
              -85.66966,
              37.267985
            ],
            [
              -85.680083,
              37.271166
            ],
            [
              -85.682301,
              37.288443
            ],
            [
              -85.697325,
              37.301838
            ],
            [
              -85.685485,
              37.361306
            ],
            [
              -85.651381,
              37.396666
            ],
            [
              -85.657332,
              37.421996
            ],
            [
              -85.733317,
              37.425328
            ],
            [
              -85.750375,
              37.431179
            ],
            [
              -85.892804,
              37.440308
            ],
            [
              -86.002156,
              37.447141
            ],
            [
              -86.047598,
              37.449933
            ],
            [
              -86.032748,
              37.418151
            ],
            [
              -86.043646,
              37.408827
            ],
            [
              -86.019089,
              37.394537
            ],
            [
              -86.051077,
              37.387233
            ],
            [
              -86.072351,
              37.381071
            ],
            [
              -86.064516,
              37.363507
            ],
            [
              -86.071281,
              37.340373
            ],
            [
              -86.080707,
              37.35143
            ],
            [
              -86.106219,
              37.338549
            ],
            [
              -86.120643,
              37.34633
            ],
            [
              -86.126798,
              37.338831
            ],
            [
              -86.15616,
              37.344863
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "183",
      "COUNTYNS": "00516938",
      "AFFGEOID": "0500000US21183",
      "GEOID": "21183",
      "NAME": "Ohio",
      "LSAD": "06",
      "ALAND": 1521032968,
      "AWATER": 23189052,
      "County_state": "Ohio,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.124382,
              37.360054
            ],
            [
              -87.136983,
              37.335524
            ],
            [
              -87.121105,
              37.32588
            ],
            [
              -87.103789,
              37.327893
            ],
            [
              -87.087373,
              37.359842
            ],
            [
              -87.07133,
              37.370298
            ],
            [
              -87.051895,
              37.369467
            ],
            [
              -87.030567,
              37.363307
            ],
            [
              -87.016147,
              37.353144
            ],
            [
              -86.985957,
              37.316566
            ],
            [
              -86.988177,
              37.302179
            ],
            [
              -86.980933,
              37.286336
            ],
            [
              -86.98267,
              37.269632
            ],
            [
              -86.971538,
              37.257963
            ],
            [
              -86.954838,
              37.257618
            ],
            [
              -86.947009,
              37.240586
            ],
            [
              -86.92322,
              37.241494
            ],
            [
              -86.909751,
              37.232772
            ],
            [
              -86.919271,
              37.225191
            ],
            [
              -86.899268,
              37.212313
            ],
            [
              -86.885457,
              37.222595
            ],
            [
              -86.883551,
              37.235127
            ],
            [
              -86.861844,
              37.251912
            ],
            [
              -86.853853,
              37.25044
            ],
            [
              -86.844558,
              37.268304
            ],
            [
              -86.828181,
              37.281843
            ],
            [
              -86.825632,
              37.294572
            ],
            [
              -86.80825,
              37.291386
            ],
            [
              -86.818836,
              37.305283
            ],
            [
              -86.815852,
              37.328859
            ],
            [
              -86.801173,
              37.334445
            ],
            [
              -86.775813,
              37.326285
            ],
            [
              -86.769731,
              37.309014
            ],
            [
              -86.759533,
              37.304099
            ],
            [
              -86.612095,
              37.395123
            ],
            [
              -86.664055,
              37.546105
            ],
            [
              -86.66489,
              37.556649
            ],
            [
              -86.646966,
              37.555567
            ],
            [
              -86.635599,
              37.567027
            ],
            [
              -86.625322,
              37.565887
            ],
            [
              -86.610691,
              37.544918
            ],
            [
              -86.579569,
              37.547181
            ],
            [
              -86.576947,
              37.553803
            ],
            [
              -86.59281,
              37.56436
            ],
            [
              -86.620226,
              37.587105
            ],
            [
              -86.64064,
              37.619069
            ],
            [
              -86.638935,
              37.661877
            ],
            [
              -86.823429,
              37.737754
            ],
            [
              -86.866552,
              37.70885
            ],
            [
              -86.817783,
              37.673679
            ],
            [
              -86.841155,
              37.665625
            ],
            [
              -86.854199,
              37.654962
            ],
            [
              -86.892668,
              37.64286
            ],
            [
              -86.90488,
              37.629818
            ],
            [
              -86.928965,
              37.623334
            ],
            [
              -86.943493,
              37.628276
            ],
            [
              -86.94876,
              37.630548
            ],
            [
              -87.006097,
              37.580466
            ],
            [
              -87.03826,
              37.560803
            ],
            [
              -87.049475,
              37.551738
            ],
            [
              -87.103111,
              37.4809
            ],
            [
              -87.113344,
              37.471507
            ],
            [
              -87.135629,
              37.485153
            ],
            [
              -87.104603,
              37.460728
            ],
            [
              -87.099803,
              37.416042
            ],
            [
              -87.095156,
              37.37593
            ],
            [
              -87.124382,
              37.360054
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "097",
      "COUNTYNS": "00516895",
      "AFFGEOID": "0500000US21097",
      "GEOID": "21097",
      "NAME": "Harrison",
      "LSAD": "06",
      "ALAND": 793480924,
      "AWATER": 9057368,
      "County_state": "Harrison,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.557375,
              38.492917
            ],
            [
              -84.543918,
              38.464735
            ],
            [
              -84.526601,
              38.450648
            ],
            [
              -84.527948,
              38.43325
            ],
            [
              -84.495513,
              38.417264
            ],
            [
              -84.467952,
              38.393184
            ],
            [
              -84.458108,
              38.373545
            ],
            [
              -84.462832,
              38.363961
            ],
            [
              -84.456298,
              38.336761
            ],
            [
              -84.443756,
              38.32431
            ],
            [
              -84.432841,
              38.299569
            ],
            [
              -84.442661,
              38.283236
            ],
            [
              -84.417001,
              38.287669
            ],
            [
              -84.378762,
              38.277852
            ],
            [
              -84.361984,
              38.295675
            ],
            [
              -84.333846,
              38.288751
            ],
            [
              -84.304995,
              38.304836
            ],
            [
              -84.306169,
              38.3131
            ],
            [
              -84.294808,
              38.308864
            ],
            [
              -84.27964,
              38.314797
            ],
            [
              -84.19405,
              38.37175
            ],
            [
              -84.1021,
              38.459379
            ],
            [
              -84.132184,
              38.483181
            ],
            [
              -84.149769,
              38.479092
            ],
            [
              -84.153211,
              38.490001
            ],
            [
              -84.183903,
              38.498019
            ],
            [
              -84.178484,
              38.532774
            ],
            [
              -84.162031,
              38.554125
            ],
            [
              -84.215625,
              38.554976
            ],
            [
              -84.20401,
              38.563961
            ],
            [
              -84.205346,
              38.582039
            ],
            [
              -84.400321,
              38.560603
            ],
            [
              -84.412442,
              38.564656
            ],
            [
              -84.427891,
              38.546463
            ],
            [
              -84.419285,
              38.543435
            ],
            [
              -84.448343,
              38.53658
            ],
            [
              -84.448556,
              38.529422
            ],
            [
              -84.476299,
              38.542723
            ],
            [
              -84.485295,
              38.508552
            ],
            [
              -84.521393,
              38.508299
            ],
            [
              -84.557375,
              38.492917
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "217",
      "COUNTYNS": "00516955",
      "AFFGEOID": "0500000US21217",
      "GEOID": "21217",
      "NAME": "Taylor",
      "LSAD": "06",
      "ALAND": 689791858,
      "AWATER": 27162461,
      "County_state": "Taylor,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.583497,
              37.469917
            ],
            [
              -85.584477,
              37.432091
            ],
            [
              -85.478081,
              37.363673
            ],
            [
              -85.431232,
              37.305114
            ],
            [
              -85.393579,
              37.250514
            ],
            [
              -85.391564,
              37.237693
            ],
            [
              -85.403798,
              37.236554
            ],
            [
              -85.410756,
              37.22418
            ],
            [
              -85.371895,
              37.221312
            ],
            [
              -85.352771,
              37.192432
            ],
            [
              -85.317235,
              37.223913
            ],
            [
              -85.307827,
              37.227337
            ],
            [
              -85.312291,
              37.246405
            ],
            [
              -85.294005,
              37.245749
            ],
            [
              -85.272446,
              37.260032
            ],
            [
              -85.248527,
              37.264183
            ],
            [
              -85.223594,
              37.262494
            ],
            [
              -85.192542,
              37.272139
            ],
            [
              -85.184026,
              37.295864
            ],
            [
              -85.188375,
              37.306955
            ],
            [
              -85.165174,
              37.310396
            ],
            [
              -85.131241,
              37.365062
            ],
            [
              -85.131162,
              37.384265
            ],
            [
              -85.105924,
              37.397612
            ],
            [
              -85.087325,
              37.397667
            ],
            [
              -85.073772,
              37.413669
            ],
            [
              -85.088127,
              37.418216
            ],
            [
              -85.098059,
              37.431974
            ],
            [
              -85.112183,
              37.436344
            ],
            [
              -85.1288,
              37.453261
            ],
            [
              -85.143087,
              37.44733
            ],
            [
              -85.17144,
              37.465081
            ],
            [
              -85.193488,
              37.454627
            ],
            [
              -85.205954,
              37.443131
            ],
            [
              -85.228147,
              37.44098
            ],
            [
              -85.246759,
              37.420205
            ],
            [
              -85.264819,
              37.416456
            ],
            [
              -85.272278,
              37.417282
            ],
            [
              -85.278408,
              37.434838
            ],
            [
              -85.311657,
              37.450817
            ],
            [
              -85.319789,
              37.450199
            ],
            [
              -85.340446,
              37.469512
            ],
            [
              -85.369437,
              37.466188
            ],
            [
              -85.39771,
              37.468535
            ],
            [
              -85.403337,
              37.475157
            ],
            [
              -85.442733,
              37.471791
            ],
            [
              -85.46625,
              37.465595
            ],
            [
              -85.488837,
              37.467285
            ],
            [
              -85.498842,
              37.482951
            ],
            [
              -85.528855,
              37.454067
            ],
            [
              -85.583497,
              37.469917
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "009",
      "COUNTYNS": "00516851",
      "AFFGEOID": "0500000US21009",
      "GEOID": "21009",
      "NAME": "Barren",
      "LSAD": "06",
      "ALAND": 1262733192,
      "AWATER": 32260040,
      "County_state": "Barren,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.160555,
              36.92423
            ],
            [
              -86.17641,
              36.914409
            ],
            [
              -86.157767,
              36.906485
            ],
            [
              -86.14897,
              36.911562
            ],
            [
              -86.150469,
              36.926811
            ],
            [
              -86.136291,
              36.932116
            ],
            [
              -86.141926,
              36.91353
            ],
            [
              -86.134673,
              36.902719
            ],
            [
              -86.141296,
              36.892292
            ],
            [
              -86.124777,
              36.895444
            ],
            [
              -86.109879,
              36.868293
            ],
            [
              -86.101443,
              36.864058
            ],
            [
              -86.108695,
              36.882892
            ],
            [
              -86.097399,
              36.900813
            ],
            [
              -86.06531,
              36.899128
            ],
            [
              -86.077346,
              36.892722
            ],
            [
              -86.078016,
              36.8547
            ],
            [
              -86.056835,
              36.828465
            ],
            [
              -86.066045,
              36.822847
            ],
            [
              -86.069957,
              36.805783
            ],
            [
              -86.046984,
              36.807647
            ],
            [
              -86.05229,
              36.795386
            ],
            [
              -86.039224,
              36.790835
            ],
            [
              -86.035311,
              36.779731
            ],
            [
              -86.052797,
              36.775378
            ],
            [
              -86.03759,
              36.760112
            ],
            [
              -86.030735,
              36.764383
            ],
            [
              -86.002309,
              36.758711
            ],
            [
              -85.982876,
              36.741563
            ],
            [
              -85.97691,
              36.722722
            ],
            [
              -85.807011,
              36.821638
            ],
            [
              -85.772286,
              36.83634
            ],
            [
              -85.739255,
              36.841476
            ],
            [
              -85.750396,
              37.005902
            ],
            [
              -85.755426,
              37.026786
            ],
            [
              -85.759278,
              37.068573
            ],
            [
              -85.75609,
              37.109835
            ],
            [
              -85.771771,
              37.109733
            ],
            [
              -85.776962,
              37.138328
            ],
            [
              -85.759315,
              37.150242
            ],
            [
              -85.744221,
              37.169813
            ],
            [
              -85.802215,
              37.163092
            ],
            [
              -85.857058,
              37.151137
            ],
            [
              -85.906336,
              37.148377
            ],
            [
              -85.992192,
              37.157084
            ],
            [
              -86.056443,
              37.167208
            ],
            [
              -86.074267,
              37.053285
            ],
            [
              -86.113512,
              37.061072
            ],
            [
              -86.141655,
              36.993894
            ],
            [
              -86.16674,
              36.934015
            ],
            [
              -86.160555,
              36.92423
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "165",
      "COUNTYNS": "00516929",
      "AFFGEOID": "0500000US21165",
      "GEOID": "21165",
      "NAME": "Menifee",
      "LSAD": "06",
      "ALAND": 527283187,
      "AWATER": 6084693,
      "County_state": "Menifee,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.775168,
              37.988663
            ],
            [
              -83.768783,
              37.918371
            ],
            [
              -83.75035,
              37.896024
            ],
            [
              -83.667352,
              37.852447
            ],
            [
              -83.66296,
              37.834735
            ],
            [
              -83.63106,
              37.825372
            ],
            [
              -83.620646,
              37.834844
            ],
            [
              -83.590333,
              37.818343
            ],
            [
              -83.55141,
              37.821615
            ],
            [
              -83.523783,
              37.831496
            ],
            [
              -83.517002,
              37.851733
            ],
            [
              -83.49508,
              37.861906
            ],
            [
              -83.506513,
              37.869737
            ],
            [
              -83.496027,
              37.890628
            ],
            [
              -83.444343,
              37.942468
            ],
            [
              -83.433942,
              38.033974
            ],
            [
              -83.463408,
              38.016975
            ],
            [
              -83.456861,
              38.03288
            ],
            [
              -83.478746,
              38.032365
            ],
            [
              -83.464069,
              38.050214
            ],
            [
              -83.498846,
              38.05062
            ],
            [
              -83.501094,
              38.049025
            ],
            [
              -83.574989,
              38.058883
            ],
            [
              -83.572955,
              38.023212
            ],
            [
              -83.580859,
              38.011568
            ],
            [
              -83.604662,
              38.014425
            ],
            [
              -83.60982,
              38.007159
            ],
            [
              -83.634565,
              38.026875
            ],
            [
              -83.659293,
              38.028261
            ],
            [
              -83.689207,
              38.012152
            ],
            [
              -83.705902,
              38.012729
            ],
            [
              -83.718497,
              38.003397
            ],
            [
              -83.730982,
              38.010524
            ],
            [
              -83.75943,
              37.998731
            ],
            [
              -83.775168,
              37.988663
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "031",
      "COUNTYNS": "00516862",
      "AFFGEOID": "0500000US21031",
      "GEOID": "21031",
      "NAME": "Butler",
      "LSAD": "06",
      "ALAND": 1103567143,
      "AWATER": 13943044,
      "County_state": "Butler,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.940771,
              37.078511
            ],
            [
              -86.941391,
              37.068981
            ],
            [
              -86.84535,
              37.056513
            ],
            [
              -86.674462,
              36.999766
            ],
            [
              -86.666739,
              37.019863
            ],
            [
              -86.616823,
              37.146724
            ],
            [
              -86.618102,
              37.146766
            ],
            [
              -86.619007,
              37.147567
            ],
            [
              -86.618743,
              37.148616
            ],
            [
              -86.617188,
              37.149492
            ],
            [
              -86.61608,
              37.149924
            ],
            [
              -86.614612,
              37.15103
            ],
            [
              -86.613395,
              37.152915
            ],
            [
              -86.613016,
              37.154112
            ],
            [
              -86.613196,
              37.155744
            ],
            [
              -86.614073,
              37.157839
            ],
            [
              -86.614327,
              37.159057
            ],
            [
              -86.614334,
              37.16063
            ],
            [
              -86.614147,
              37.162155
            ],
            [
              -86.613223,
              37.164599
            ],
            [
              -86.623216,
              37.180614
            ],
            [
              -86.562539,
              37.187139
            ],
            [
              -86.563301,
              37.180208
            ],
            [
              -86.541738,
              37.175919
            ],
            [
              -86.497187,
              37.190387
            ],
            [
              -86.484593,
              37.18188
            ],
            [
              -86.466544,
              37.152681
            ],
            [
              -86.44925,
              37.151065
            ],
            [
              -86.442817,
              37.165555
            ],
            [
              -86.410456,
              37.152111
            ],
            [
              -86.408789,
              37.167607
            ],
            [
              -86.399165,
              37.169896
            ],
            [
              -86.469126,
              37.321099
            ],
            [
              -86.487152,
              37.362501
            ],
            [
              -86.612095,
              37.395123
            ],
            [
              -86.759533,
              37.304099
            ],
            [
              -86.769731,
              37.309014
            ],
            [
              -86.775813,
              37.326285
            ],
            [
              -86.801173,
              37.334445
            ],
            [
              -86.815852,
              37.328859
            ],
            [
              -86.818836,
              37.305283
            ],
            [
              -86.80825,
              37.291386
            ],
            [
              -86.825632,
              37.294572
            ],
            [
              -86.828181,
              37.281843
            ],
            [
              -86.844558,
              37.268304
            ],
            [
              -86.853853,
              37.25044
            ],
            [
              -86.861844,
              37.251912
            ],
            [
              -86.883551,
              37.235127
            ],
            [
              -86.885457,
              37.222595
            ],
            [
              -86.899268,
              37.212313
            ],
            [
              -86.905478,
              37.2024
            ],
            [
              -86.895336,
              37.177361
            ],
            [
              -86.922165,
              37.180151
            ],
            [
              -86.926882,
              37.16027
            ],
            [
              -86.938588,
              37.137077
            ],
            [
              -86.924326,
              37.142734
            ],
            [
              -86.912782,
              37.135896
            ],
            [
              -86.915982,
              37.125827
            ],
            [
              -86.900352,
              37.134065
            ],
            [
              -86.899759,
              37.122469
            ],
            [
              -86.881778,
              37.1116
            ],
            [
              -86.901639,
              37.112497
            ],
            [
              -86.903984,
              37.101542
            ],
            [
              -86.894067,
              37.088349
            ],
            [
              -86.921824,
              37.07816
            ],
            [
              -86.940771,
              37.078511
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "163",
      "COUNTYNS": "00516928",
      "AFFGEOID": "0500000US21163",
      "GEOID": "21163",
      "NAME": "Meade",
      "LSAD": "06",
      "ALAND": 791044349,
      "AWATER": 49413697,
      "County_state": "Meade,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.480393,
              38.045578
            ],
            [
              -86.4907693787391,
              38.045672484270696
            ],
            [
              -86.429137,
              38.000123
            ],
            [
              -86.242777,
              37.877002
            ],
            [
              -86.209141,
              37.845874
            ],
            [
              -86.151285,
              37.798878
            ],
            [
              -86.007432,
              37.818439
            ],
            [
              -85.985496,
              37.858401
            ],
            [
              -85.975948,
              37.875793
            ],
            [
              -85.982331,
              37.91025
            ],
            [
              -85.996629,
              37.987442
            ],
            [
              -85.99896743483329,
              37.999779408020494
            ],
            [
              -86.009127,
              37.998529
            ],
            [
              -86.020655,
              37.996116
            ],
            [
              -86.029509,
              37.99264
            ],
            [
              -86.032468,
              37.9901
            ],
            [
              -86.035012,
              37.984814
            ],
            [
              -86.035279,
              37.981228
            ],
            [
              -86.033386,
              37.970382
            ],
            [
              -86.034355,
              37.964621
            ],
            [
              -86.036013,
              37.961703
            ],
            [
              -86.038188,
              37.95935
            ],
            [
              -86.042354,
              37.958018
            ],
            [
              -86.045208,
              37.958258
            ],
            [
              -86.048458,
              37.959369
            ],
            [
              -86.053912,
              37.963571
            ],
            [
              -86.061731,
              37.971326
            ],
            [
              -86.064859,
              37.975618
            ],
            [
              -86.071644,
              37.9872
            ],
            [
              -86.074915,
              37.993345
            ],
            [
              -86.07398,
              37.995449
            ],
            [
              -86.075393,
              37.996948
            ],
            [
              -86.080034,
              38.000848
            ],
            [
              -86.087525,
              38.005127
            ],
            [
              -86.095766,
              38.00893
            ],
            [
              -86.108156,
              38.013416
            ],
            [
              -86.118208,
              38.015279
            ],
            [
              -86.12757,
              38.016011
            ],
            [
              -86.141063,
              38.01547
            ],
            [
              -86.16731,
              38.009879
            ],
            [
              -86.172186,
              38.00992
            ],
            [
              -86.178983,
              38.011308
            ],
            [
              -86.190927,
              38.016438
            ],
            [
              -86.206439,
              38.021876
            ],
            [
              -86.220371,
              38.027922
            ],
            [
              -86.225519,
              38.03328
            ],
            [
              -86.233057,
              38.039305
            ],
            [
              -86.249972,
              38.04583
            ],
            [
              -86.261273,
              38.052721
            ],
            [
              -86.266891,
              38.057125
            ],
            [
              -86.26731,
              38.057655
            ],
            [
              -86.273584,
              38.067443
            ],
            [
              -86.27872,
              38.089303
            ],
            [
              -86.278656,
              38.098509
            ],
            [
              -86.271223,
              38.130112
            ],
            [
              -86.271802,
              38.137874
            ],
            [
              -86.287773,
              38.15805
            ],
            [
              -86.304155,
              38.167872
            ],
            [
              -86.317139,
              38.172907
            ],
            [
              -86.33001851513349,
              38.1811511718017
            ],
            [
              -86.33281,
              38.182938
            ],
            [
              -86.347736,
              38.195363
            ],
            [
              -86.360377,
              38.198796
            ],
            [
              -86.373801,
              38.193352
            ],
            [
              -86.37524673212658,
              38.190857031937
            ],
            [
              -86.378151,
              38.185845
            ],
            [
              -86.377434,
              38.171379
            ],
            [
              -86.37174,
              38.164183
            ],
            [
              -86.353625,
              38.159579
            ],
            [
              -86.325941,
              38.154317
            ],
            [
              -86.321274,
              38.147418
            ],
            [
              -86.323453,
              38.139032
            ],
            [
              -86.328398,
              38.132877
            ],
            [
              -86.335145,
              38.129242
            ],
            [
              -86.352466,
              38.128459
            ],
            [
              -86.375324,
              38.130629
            ],
            [
              -86.379775,
              38.129274
            ],
            [
              -86.387216,
              38.124632
            ],
            [
              -86.396215,
              38.107789
            ],
            [
              -86.401653,
              38.105396
            ],
            [
              -86.405068,
              38.105801
            ],
            [
              -86.41876,
              38.117693
            ],
            [
              -86.431749,
              38.126121
            ],
            [
              -86.449793,
              38.127223
            ],
            [
              -86.457115,
              38.124531
            ],
            [
              -86.4610338985719,
              38.1211744087398
            ],
            [
              -86.463248,
              38.119278
            ],
            [
              -86.466081,
              38.114437
            ],
            [
              -86.466217,
              38.106781
            ],
            [
              -86.463858,
              38.101177
            ],
            [
              -86.458795,
              38.096404
            ],
            [
              -86.434046,
              38.086763
            ],
            [
              -86.430091,
              38.078638
            ],
            [
              -86.432789,
              38.067171
            ],
            [
              -86.438236,
              38.060426
            ],
            [
              -86.452192,
              38.05049
            ],
            [
              -86.471903,
              38.046218
            ],
            [
              -86.480393,
              38.045578
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "137",
      "COUNTYNS": "00516915",
      "AFFGEOID": "0500000US21137",
      "GEOID": "21137",
      "NAME": "Lincoln",
      "LSAD": "06",
      "ALAND": 861936841,
      "AWATER": 9315386,
      "County_state": "Lincoln,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.857086,
              37.528519
            ],
            [
              -84.86013,
              37.46735
            ],
            [
              -84.850816,
              37.427143
            ],
            [
              -84.796104,
              37.390556
            ],
            [
              -84.722826,
              37.360043
            ],
            [
              -84.706122,
              37.299835
            ],
            [
              -84.724165,
              37.286675
            ],
            [
              -84.716689,
              37.251782
            ],
            [
              -84.720712,
              37.238328
            ],
            [
              -84.574221,
              37.343176
            ],
            [
              -84.556461,
              37.355729
            ],
            [
              -84.502932,
              37.328801
            ],
            [
              -84.446719,
              37.485893
            ],
            [
              -84.452394,
              37.49277
            ],
            [
              -84.473203,
              37.49444
            ],
            [
              -84.618471,
              37.593051
            ],
            [
              -84.622306,
              37.603584
            ],
            [
              -84.634243,
              37.601713
            ],
            [
              -84.648559,
              37.609897
            ],
            [
              -84.652567,
              37.61821
            ],
            [
              -84.644212,
              37.631883
            ],
            [
              -84.658296,
              37.634501
            ],
            [
              -84.652122,
              37.625856
            ],
            [
              -84.658381,
              37.617752
            ],
            [
              -84.683807,
              37.62099
            ],
            [
              -84.688516,
              37.612363
            ],
            [
              -84.731734,
              37.589344
            ],
            [
              -84.746986,
              37.585612
            ],
            [
              -84.747778,
              37.586472
            ],
            [
              -84.745057,
              37.594186
            ],
            [
              -84.744171,
              37.597354
            ],
            [
              -84.746126,
              37.597576
            ],
            [
              -84.755371,
              37.596096
            ],
            [
              -84.753965,
              37.59355
            ],
            [
              -84.756242,
              37.592089
            ],
            [
              -84.756826,
              37.588362
            ],
            [
              -84.752858,
              37.588541
            ],
            [
              -84.74838,
              37.586405
            ],
            [
              -84.783689,
              37.576624
            ],
            [
              -84.847183,
              37.548455
            ],
            [
              -84.857086,
              37.528519
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "013",
      "COUNTYNS": "00516853",
      "AFFGEOID": "0500000US21013",
      "GEOID": "21013",
      "NAME": "Bell",
      "LSAD": "06",
      "ALAND": 929806778,
      "AWATER": 5480741,
      "County_state": "Bell,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.956319,
              36.645433
            ],
            [
              -83.924712,
              36.602673
            ],
            [
              -83.9307607839439,
              36.5876942581124
            ],
            [
              -83.894421,
              36.586481
            ],
            [
              -83.789017,
              36.583881
            ],
            [
              -83.690714,
              36.582581
            ],
            [
              -83.688814,
              36.584382
            ],
            [
              -83.687514,
              36.587182
            ],
            [
              -83.683014,
              36.592182
            ],
            [
              -83.679514,
              36.594082
            ],
            [
              -83.677114,
              36.596582
            ],
            [
              -83.675614,
              36.598582
            ],
            [
              -83.675413,
              36.600814
            ],
            [
              -83.674614,
              36.603082
            ],
            [
              -83.673114,
              36.604682
            ],
            [
              -83.665614,
              36.605782
            ],
            [
              -83.663614,
              36.606782
            ],
            [
              -83.657714,
              36.611782
            ],
            [
              -83.649513,
              36.616683
            ],
            [
              -83.648314,
              36.622683
            ],
            [
              -83.645213,
              36.624183
            ],
            [
              -83.639813,
              36.624783
            ],
            [
              -83.635013,
              36.622883
            ],
            [
              -83.628913,
              36.624083
            ],
            [
              -83.625013,
              36.625183
            ],
            [
              -83.619913,
              36.627983
            ],
            [
              -83.616413,
              36.631383
            ],
            [
              -83.614513,
              36.633983
            ],
            [
              -83.607913,
              36.637083
            ],
            [
              -83.605613,
              36.637783
            ],
            [
              -83.604313,
              36.637683
            ],
            [
              -83.603013,
              36.636883
            ],
            [
              -83.600713,
              36.637084
            ],
            [
              -83.584512,
              36.641384
            ],
            [
              -83.577312,
              36.641784
            ],
            [
              -83.569812,
              36.645684
            ],
            [
              -83.565712,
              36.648384
            ],
            [
              -83.562612,
              36.651284
            ],
            [
              -83.554112,
              36.653784
            ],
            [
              -83.547312,
              36.654484
            ],
            [
              -83.541812,
              36.656584
            ],
            [
              -83.533112,
              36.661884
            ],
            [
              -83.533012,
              36.663284
            ],
            [
              -83.531912,
              36.664984
            ],
            [
              -83.529612,
              36.666184
            ],
            [
              -83.527112,
              36.665985
            ],
            [
              -83.516011,
              36.667585
            ],
            [
              -83.511711,
              36.669085
            ],
            [
              -83.506911,
              36.668685
            ],
            [
              -83.501411,
              36.669085
            ],
            [
              -83.499911,
              36.670185
            ],
            [
              -83.498011,
              36.670485
            ],
            [
              -83.493911,
              36.670085
            ],
            [
              -83.48891,
              36.667685
            ],
            [
              -83.48261,
              36.666185
            ],
            [
              -83.466483,
              36.6647
            ],
            [
              -83.4610151191699,
              36.6648782480975
            ],
            [
              -83.459459,
              36.697387
            ],
            [
              -83.480179,
              36.709879
            ],
            [
              -83.500891,
              36.733254
            ],
            [
              -83.491054,
              36.756988
            ],
            [
              -83.485814,
              36.783293
            ],
            [
              -83.511729,
              36.807362
            ],
            [
              -83.48171,
              36.849134
            ],
            [
              -83.493867,
              36.863508
            ],
            [
              -83.496728,
              36.884362
            ],
            [
              -83.489813,
              36.895413
            ],
            [
              -83.499905,
              36.895407
            ],
            [
              -83.489423,
              36.911302
            ],
            [
              -83.509482,
              36.923375
            ],
            [
              -83.509083,
              36.938509
            ],
            [
              -83.520687,
              36.93755
            ],
            [
              -83.589521,
              36.955786
            ],
            [
              -83.583819,
              36.941249
            ],
            [
              -83.566142,
              36.931525
            ],
            [
              -83.575778,
              36.922451
            ],
            [
              -83.581094,
              36.904667
            ],
            [
              -83.612394,
              36.897593
            ],
            [
              -83.620573,
              36.884087
            ],
            [
              -83.629044,
              36.888182
            ],
            [
              -83.64307,
              36.879377
            ],
            [
              -83.642407,
              36.866246
            ],
            [
              -83.66327,
              36.864248
            ],
            [
              -83.682879,
              36.854582
            ],
            [
              -83.691787,
              36.839212
            ],
            [
              -83.705846,
              36.836134
            ],
            [
              -83.714737,
              36.820553
            ],
            [
              -83.737731,
              36.809144
            ],
            [
              -83.73873,
              36.801439
            ],
            [
              -83.754042,
              36.80535
            ],
            [
              -83.775776,
              36.798121
            ],
            [
              -83.786165,
              36.800678
            ],
            [
              -83.798248,
              36.785197
            ],
            [
              -83.796303,
              36.77259
            ],
            [
              -83.808606,
              36.761708
            ],
            [
              -83.809166,
              36.748103
            ],
            [
              -83.828235,
              36.742749
            ],
            [
              -83.841314,
              36.711756
            ],
            [
              -83.854824,
              36.705366
            ],
            [
              -83.86552,
              36.714717
            ],
            [
              -83.877503,
              36.687259
            ],
            [
              -83.878615,
              36.680335
            ],
            [
              -83.956319,
              36.645433
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "187",
      "COUNTYNS": "00516940",
      "AFFGEOID": "0500000US21187",
      "GEOID": "21187",
      "NAME": "Owen",
      "LSAD": "06",
      "ALAND": 909364763,
      "AWATER": 8129649,
      "County_state": "Owen,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.074583,
              38.596838
            ],
            [
              -85.076118,
              38.578895
            ],
            [
              -85.062141,
              38.564329
            ],
            [
              -85.04264,
              38.573862
            ],
            [
              -85.009185,
              38.561124
            ],
            [
              -85.002707,
              38.55212
            ],
            [
              -85.029888,
              38.510765
            ],
            [
              -85.017363,
              38.504751
            ],
            [
              -85.007173,
              38.513923
            ],
            [
              -84.985026,
              38.518405
            ],
            [
              -84.977166,
              38.512989
            ],
            [
              -84.992159,
              38.495293
            ],
            [
              -84.988781,
              38.487085
            ],
            [
              -84.956842,
              38.473646
            ],
            [
              -84.95181,
              38.456992
            ],
            [
              -84.963265,
              38.437358
            ],
            [
              -84.949835,
              38.42618
            ],
            [
              -84.939253,
              38.427797
            ],
            [
              -84.938189,
              38.450414
            ],
            [
              -84.922642,
              38.464538
            ],
            [
              -84.91223,
              38.459844
            ],
            [
              -84.909846,
              38.449384
            ],
            [
              -84.925023,
              38.441824
            ],
            [
              -84.924715,
              38.431176
            ],
            [
              -84.895832,
              38.424854
            ],
            [
              -84.880248,
              38.41618
            ],
            [
              -84.897591,
              38.389558
            ],
            [
              -84.894369,
              38.375308
            ],
            [
              -84.86835,
              38.368643
            ],
            [
              -84.870482,
              38.356751
            ],
            [
              -84.838419,
              38.356926
            ],
            [
              -84.793412,
              38.338669
            ],
            [
              -84.740594,
              38.352415
            ],
            [
              -84.705988,
              38.370102
            ],
            [
              -84.681555,
              38.390157
            ],
            [
              -84.623583,
              38.431191
            ],
            [
              -84.580539,
              38.473039
            ],
            [
              -84.600769,
              38.481028
            ],
            [
              -84.626651,
              38.485132
            ],
            [
              -84.642287,
              38.513854
            ],
            [
              -84.65697,
              38.532016
            ],
            [
              -84.674574,
              38.532033
            ],
            [
              -84.665457,
              38.543453
            ],
            [
              -84.680959,
              38.55975
            ],
            [
              -84.774322,
              38.618594
            ],
            [
              -84.785788,
              38.720459
            ],
            [
              -84.793642,
              38.724626
            ],
            [
              -84.830512,
              38.711481
            ],
            [
              -84.844734,
              38.696818
            ],
            [
              -84.870541,
              38.698394
            ],
            [
              -84.905579,
              38.669364
            ],
            [
              -84.925938,
              38.673137
            ],
            [
              -84.934536,
              38.66213
            ],
            [
              -84.936982,
              38.650827
            ],
            [
              -84.969888,
              38.648732
            ],
            [
              -84.974623,
              38.636619
            ],
            [
              -85.015006,
              38.632386
            ],
            [
              -85.0243,
              38.614202
            ],
            [
              -85.059846,
              38.611022
            ],
            [
              -85.074583,
              38.596838
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "077",
      "COUNTYNS": "00516885",
      "AFFGEOID": "0500000US21077",
      "GEOID": "21077",
      "NAME": "Gallatin",
      "LSAD": "06",
      "ALAND": 254699793,
      "AWATER": 16518583,
      "County_state": "Gallatin,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.011772,
              38.766712
            ],
            [
              -85.0235842033963,
              38.7620346655344
            ],
            [
              -84.962671,
              38.679586
            ],
            [
              -84.934536,
              38.66213
            ],
            [
              -84.925938,
              38.673137
            ],
            [
              -84.905579,
              38.669364
            ],
            [
              -84.870541,
              38.698394
            ],
            [
              -84.844734,
              38.696818
            ],
            [
              -84.830512,
              38.711481
            ],
            [
              -84.793642,
              38.724626
            ],
            [
              -84.785788,
              38.720459
            ],
            [
              -84.780766,
              38.765208
            ],
            [
              -84.660107,
              38.777293
            ],
            [
              -84.682678,
              38.790654
            ],
            [
              -84.750732,
              38.831008
            ],
            [
              -84.780075,
              38.859509
            ],
            [
              -84.794616596501,
              38.857119076861395
            ],
            [
              -84.803247,
              38.850723
            ],
            [
              -84.817169,
              38.84342
            ],
            [
              -84.823363,
              38.839196
            ],
            [
              -84.827488,
              38.834909
            ],
            [
              -84.829958,
              38.830632
            ],
            [
              -84.829886,
              38.825405
            ],
            [
              -84.827098,
              38.818634
            ],
            [
              -84.816506,
              38.80532
            ],
            [
              -84.813939,
              38.800209
            ],
            [
              -84.811645,
              38.792766
            ],
            [
              -84.811752,
              38.789169
            ],
            [
              -84.812877,
              38.786087
            ],
            [
              -84.814641,
              38.784488
            ],
            [
              -84.821378,
              38.783111
            ],
            [
              -84.828714,
              38.783208
            ],
            [
              -84.835672,
              38.784289
            ],
            [
              -84.847918,
              38.788106
            ],
            [
              -84.856904,
              38.790224
            ],
            [
              -84.887919,
              38.794652
            ],
            [
              -84.89393,
              38.793704
            ],
            [
              -84.901874,
              38.790604
            ],
            [
              -84.915234,
              38.784086
            ],
            [
              -84.932977,
              38.777519
            ],
            [
              -84.941071,
              38.775627
            ],
            [
              -84.947644,
              38.775273
            ],
            [
              -84.962535,
              38.778035
            ],
            [
              -84.978723,
              38.77928
            ],
            [
              -84.990006,
              38.778383
            ],
            [
              -84.995939,
              38.776756
            ],
            [
              -84.999949,
              38.774715
            ],
            [
              -85.011772,
              38.766712
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "017",
      "COUNTYNS": "00516855",
      "AFFGEOID": "0500000US21017",
      "GEOID": "21017",
      "NAME": "Bourbon",
      "LSAD": "06",
      "ALAND": 750370820,
      "AWATER": 4849018,
      "County_state": "Bourbon,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.442661,
              38.283236
            ],
            [
              -84.441635,
              38.277428
            ],
            [
              -84.423351,
              38.257158
            ],
            [
              -84.419918,
              38.214245
            ],
            [
              -84.401847,
              38.207889
            ],
            [
              -84.372983,
              38.208004
            ],
            [
              -84.35523,
              38.178474
            ],
            [
              -84.373755,
              38.157572
            ],
            [
              -84.368508,
              38.13365
            ],
            [
              -84.380935,
              38.113491
            ],
            [
              -84.36056,
              38.098708
            ],
            [
              -84.32667,
              38.084732
            ],
            [
              -84.318125,
              38.092885
            ],
            [
              -84.287259,
              38.075592
            ],
            [
              -84.286461,
              38.067028
            ],
            [
              -84.080527,
              38.115118
            ],
            [
              -83.990171,
              38.175067
            ],
            [
              -83.977924,
              38.192118
            ],
            [
              -83.969281,
              38.20933
            ],
            [
              -83.977367,
              38.220048
            ],
            [
              -83.989145,
              38.218796
            ],
            [
              -83.994218,
              38.203903
            ],
            [
              -84.00814,
              38.219841
            ],
            [
              -84.027045,
              38.214105
            ],
            [
              -84.033999,
              38.223655
            ],
            [
              -84.062691,
              38.235203
            ],
            [
              -84.053141,
              38.239455
            ],
            [
              -84.057118,
              38.256187
            ],
            [
              -84.077741,
              38.256157
            ],
            [
              -84.076309,
              38.268895
            ],
            [
              -84.088983,
              38.273024
            ],
            [
              -84.095324,
              38.259014
            ],
            [
              -84.103647,
              38.269874
            ],
            [
              -84.120185,
              38.275516
            ],
            [
              -84.104135,
              38.285744
            ],
            [
              -84.130164,
              38.2952
            ],
            [
              -84.132621,
              38.304549
            ],
            [
              -84.166253,
              38.354913
            ],
            [
              -84.19405,
              38.37175
            ],
            [
              -84.27964,
              38.314797
            ],
            [
              -84.294808,
              38.308864
            ],
            [
              -84.306169,
              38.3131
            ],
            [
              -84.304995,
              38.304836
            ],
            [
              -84.333846,
              38.288751
            ],
            [
              -84.361984,
              38.295675
            ],
            [
              -84.378762,
              38.277852
            ],
            [
              -84.417001,
              38.287669
            ],
            [
              -84.442661,
              38.283236
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "023",
      "COUNTYNS": "00516858",
      "AFFGEOID": "0500000US21023",
      "GEOID": "21023",
      "NAME": "Bracken",
      "LSAD": "06",
      "ALAND": 532533292,
      "AWATER": 8636823,
      "County_state": "Bracken,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.23042691143739,
              38.8274224447174
            ],
            [
              -84.235962,
              38.822361
            ],
            [
              -84.207373,
              38.597602
            ],
            [
              -84.201054,
              38.603857
            ],
            [
              -84.19537,
              38.589256
            ],
            [
              -84.205346,
              38.582039
            ],
            [
              -84.20401,
              38.563961
            ],
            [
              -84.215625,
              38.554976
            ],
            [
              -84.162031,
              38.554125
            ],
            [
              -84.146365,
              38.549802
            ],
            [
              -84.122315,
              38.564155
            ],
            [
              -84.104477,
              38.586288
            ],
            [
              -84.083512,
              38.597737
            ],
            [
              -84.083746,
              38.608594
            ],
            [
              -84.074408,
              38.598636
            ],
            [
              -84.045016,
              38.60005
            ],
            [
              -84.033695,
              38.582947
            ],
            [
              -83.997136,
              38.583307
            ],
            [
              -83.991605,
              38.593701
            ],
            [
              -83.90391694448489,
              38.7681517942292
            ],
            [
              -83.903971,
              38.76816
            ],
            [
              -83.912922,
              38.769763
            ],
            [
              -83.917217,
              38.769665
            ],
            [
              -83.926986,
              38.771562
            ],
            [
              -83.928591,
              38.772203
            ],
            [
              -83.928454,
              38.774583
            ],
            [
              -83.943978,
              38.783616
            ],
            [
              -83.953546,
              38.786172
            ],
            [
              -83.962123,
              38.787384
            ],
            [
              -83.978814,
              38.787104
            ],
            [
              -83.995447,
              38.781912
            ],
            [
              -84.006104,
              38.780156
            ],
            [
              -84.023113,
              38.775061
            ],
            [
              -84.044486,
              38.770572
            ],
            [
              -84.051642,
              38.771397
            ],
            [
              -84.052684882421,
              38.7713751994327
            ],
            [
              -84.06341,
              38.771151
            ],
            [
              -84.071491,
              38.770475
            ],
            [
              -84.094334,
              38.775246
            ],
            [
              -84.108836,
              38.779247
            ],
            [
              -84.115655,
              38.782721
            ],
            [
              -84.135088,
              38.789485
            ],
            [
              -84.155912,
              38.794909
            ],
            [
              -84.198358,
              38.80092
            ],
            [
              -84.205592,
              38.802588
            ],
            [
              -84.212904,
              38.805707
            ],
            [
              -84.222059,
              38.813753
            ],
            [
              -84.2253,
              38.817665
            ],
            [
              -84.230181,
              38.826547
            ],
            [
              -84.23042691143739,
              38.8274224447174
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "207",
      "COUNTYNS": "00516950",
      "AFFGEOID": "0500000US21207",
      "GEOID": "21207",
      "NAME": "Russell",
      "LSAD": "06",
      "ALAND": 656969509,
      "AWATER": 75674970,
      "County_state": "Russell,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.27495,
              36.897901
            ],
            [
              -85.247832,
              36.877623
            ],
            [
              -85.236706,
              36.853334
            ],
            [
              -85.215405,
              36.854467
            ],
            [
              -85.235046,
              36.885673
            ],
            [
              -85.196988,
              36.874053
            ],
            [
              -85.118411,
              36.827334
            ],
            [
              -85.064307,
              36.858743
            ],
            [
              -85.018063,
              36.887309
            ],
            [
              -84.960878,
              36.916338
            ],
            [
              -84.950428,
              36.955467
            ],
            [
              -84.945527,
              36.959747
            ],
            [
              -84.921475,
              36.956844
            ],
            [
              -84.907859,
              36.945447
            ],
            [
              -84.835712,
              36.997612
            ],
            [
              -84.863737,
              37.00802
            ],
            [
              -84.905778,
              37.047187
            ],
            [
              -84.907062,
              37.087166
            ],
            [
              -84.901112,
              37.116297
            ],
            [
              -84.916557,
              37.107281
            ],
            [
              -84.954406,
              37.102271
            ],
            [
              -84.970624,
              37.113045
            ],
            [
              -85.043316,
              37.186028
            ],
            [
              -85.089643,
              37.125545
            ],
            [
              -85.166186,
              37.033265
            ],
            [
              -85.205831,
              36.996862
            ],
            [
              -85.232026,
              36.925069
            ],
            [
              -85.27495,
              36.897901
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "143",
      "COUNTYNS": "00516918",
      "AFFGEOID": "0500000US21143",
      "GEOID": "21143",
      "NAME": "Lyon",
      "LSAD": "06",
      "ALAND": 553844796,
      "AWATER": 110561084,
      "County_state": "Lyon,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.209221,
              36.999976
            ],
            [
              -88.240371,
              36.981911
            ],
            [
              -88.189389,
              36.935549
            ],
            [
              -88.180283,
              36.900827
            ],
            [
              -88.157347,
              36.867183
            ],
            [
              -88.049765,
              36.898036
            ],
            [
              -88.002697,
              36.910168
            ],
            [
              -87.890669,
              36.960144
            ],
            [
              -87.876996,
              36.960116
            ],
            [
              -87.975186,
              37.067509
            ],
            [
              -88.042142,
              37.174756
            ],
            [
              -88.095781,
              37.179684
            ],
            [
              -88.118213,
              37.155035
            ],
            [
              -88.131299,
              37.158485
            ],
            [
              -88.136327,
              37.147704
            ],
            [
              -88.153199,
              37.149531
            ],
            [
              -88.163384,
              37.143154
            ],
            [
              -88.160162,
              37.133067
            ],
            [
              -88.184188,
              37.126189
            ],
            [
              -88.182447,
              37.133553
            ],
            [
              -88.197339,
              37.13161
            ],
            [
              -88.193519,
              37.147256
            ],
            [
              -88.210909,
              37.130426
            ],
            [
              -88.216889,
              37.095732
            ],
            [
              -88.231145,
              37.081575
            ],
            [
              -88.216295,
              37.056225
            ],
            [
              -88.226774,
              37.027602
            ],
            [
              -88.209221,
              36.999976
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "085",
      "COUNTYNS": "00516889",
      "AFFGEOID": "0500000US21085",
      "GEOID": "21085",
      "NAME": "Grayson",
      "LSAD": "06",
      "ALAND": 1291710298,
      "AWATER": 31853979,
      "County_state": "Grayson,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.66489,
              37.556649
            ],
            [
              -86.664055,
              37.546105
            ],
            [
              -86.612095,
              37.395123
            ],
            [
              -86.487152,
              37.362501
            ],
            [
              -86.469126,
              37.321099
            ],
            [
              -86.209193,
              37.33293
            ],
            [
              -86.19305,
              37.327905
            ],
            [
              -86.187534,
              37.340796
            ],
            [
              -86.176963,
              37.33277
            ],
            [
              -86.157589,
              37.335649
            ],
            [
              -86.15616,
              37.344863
            ],
            [
              -86.126798,
              37.338831
            ],
            [
              -86.120643,
              37.34633
            ],
            [
              -86.106219,
              37.338549
            ],
            [
              -86.080707,
              37.35143
            ],
            [
              -86.071281,
              37.340373
            ],
            [
              -86.064516,
              37.363507
            ],
            [
              -86.072351,
              37.381071
            ],
            [
              -86.051077,
              37.387233
            ],
            [
              -86.019089,
              37.394537
            ],
            [
              -86.043646,
              37.408827
            ],
            [
              -86.032748,
              37.418151
            ],
            [
              -86.047598,
              37.449933
            ],
            [
              -86.06744,
              37.457928
            ],
            [
              -86.060297,
              37.472536
            ],
            [
              -86.04932,
              37.47688
            ],
            [
              -86.061552,
              37.489895
            ],
            [
              -86.05427,
              37.496002
            ],
            [
              -86.114261,
              37.567182
            ],
            [
              -86.15926,
              37.56334
            ],
            [
              -86.166033,
              37.571496
            ],
            [
              -86.17619,
              37.554808
            ],
            [
              -86.2014,
              37.5745
            ],
            [
              -86.232505,
              37.574988
            ],
            [
              -86.246169,
              37.601937
            ],
            [
              -86.247962,
              37.594187
            ],
            [
              -86.268132,
              37.584631
            ],
            [
              -86.27527,
              37.593519
            ],
            [
              -86.316675,
              37.586641
            ],
            [
              -86.343081,
              37.594624
            ],
            [
              -86.352694,
              37.586974
            ],
            [
              -86.34623,
              37.562718
            ],
            [
              -86.374581,
              37.558906
            ],
            [
              -86.387911,
              37.567016
            ],
            [
              -86.409744,
              37.566514
            ],
            [
              -86.425135,
              37.580513
            ],
            [
              -86.447179,
              37.576566
            ],
            [
              -86.454987,
              37.591679
            ],
            [
              -86.47504,
              37.590372
            ],
            [
              -86.470263,
              37.599996
            ],
            [
              -86.511631,
              37.622924
            ],
            [
              -86.536391,
              37.613487
            ],
            [
              -86.541495,
              37.60019
            ],
            [
              -86.523412,
              37.60151
            ],
            [
              -86.534827,
              37.58987
            ],
            [
              -86.551459,
              37.588426
            ],
            [
              -86.552372,
              37.595848
            ],
            [
              -86.575022,
              37.599645
            ],
            [
              -86.569039,
              37.58145
            ],
            [
              -86.59281,
              37.56436
            ],
            [
              -86.576947,
              37.553803
            ],
            [
              -86.579569,
              37.547181
            ],
            [
              -86.610691,
              37.544918
            ],
            [
              -86.625322,
              37.565887
            ],
            [
              -86.635599,
              37.567027
            ],
            [
              -86.646966,
              37.555567
            ],
            [
              -86.66489,
              37.556649
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "059",
      "COUNTYNS": "00516876",
      "AFFGEOID": "0500000US21059",
      "GEOID": "21059",
      "NAME": "Daviess",
      "LSAD": "06",
      "ALAND": 1187163564,
      "AWATER": 47158145,
      "County_state": "Daviess,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.393511,
              37.724569
            ],
            [
              -87.408547,
              37.683563
            ],
            [
              -87.26161,
              37.622009
            ],
            [
              -87.15982,
              37.586999
            ],
            [
              -87.138821,
              37.575628
            ],
            [
              -87.095524,
              37.566954
            ],
            [
              -87.057272,
              37.570641
            ],
            [
              -87.03826,
              37.560803
            ],
            [
              -87.006097,
              37.580466
            ],
            [
              -86.94876,
              37.630548
            ],
            [
              -86.943493,
              37.628276
            ],
            [
              -86.928965,
              37.623334
            ],
            [
              -86.90488,
              37.629818
            ],
            [
              -86.892668,
              37.64286
            ],
            [
              -86.854199,
              37.654962
            ],
            [
              -86.841155,
              37.665625
            ],
            [
              -86.817783,
              37.673679
            ],
            [
              -86.866552,
              37.70885
            ],
            [
              -86.823429,
              37.737754
            ],
            [
              -86.8171,
              37.791589
            ],
            [
              -86.842495,
              37.819921
            ],
            [
              -86.854035,
              37.840811
            ],
            [
              -86.886527,
              37.83626
            ],
            [
              -86.893873,
              37.828673
            ],
            [
              -86.912531,
              37.837481
            ],
            [
              -86.908544,
              37.869282
            ],
            [
              -86.893859,
              37.882113
            ],
            [
              -86.909708,
              37.886003
            ],
            [
              -86.91916,
              37.906032
            ],
            [
              -86.931951,
              37.909727
            ],
            [
              -86.958521,
              37.897976
            ],
            [
              -86.987296,
              37.897213
            ],
            [
              -86.973912,
              37.918355
            ],
            [
              -86.9788342958521,
              37.9302329010012
            ],
            [
              -86.978957,
              37.9302
            ],
            [
              -87.003301,
              37.922395
            ],
            [
              -87.010315,
              37.919668
            ],
            [
              -87.033444,
              37.906593
            ],
            [
              -87.042249,
              37.898291
            ],
            [
              -87.045101,
              37.893775
            ],
            [
              -87.046237,
              37.889866
            ],
            [
              -87.045894,
              37.887574
            ],
            [
              -87.044144,
              37.884025
            ],
            [
              -87.043407,
              37.87994
            ],
            [
              -87.043049,
              37.875049
            ],
            [
              -87.043854,
              37.870796
            ],
            [
              -87.04926,
              37.859745
            ],
            [
              -87.051452,
              37.853681
            ],
            [
              -87.055404,
              37.835297
            ],
            [
              -87.057836,
              37.827457
            ],
            [
              -87.065388,
              37.810481
            ],
            [
              -87.067836,
              37.806065
            ],
            [
              -87.070732,
              37.801937
            ],
            [
              -87.0765780955594,
              37.7969180498555
            ],
            [
              -87.077404,
              37.796209
            ],
            [
              -87.090636,
              37.787808
            ],
            [
              -87.0999,
              37.78464
            ],
            [
              -87.111133,
              37.782512
            ],
            [
              -87.119229,
              37.782848
            ],
            [
              -87.127533,
              37.78504
            ],
            [
              -87.129629,
              37.786608
            ],
            [
              -87.133149,
              37.792208
            ],
            [
              -87.13749861515589,
              37.807252292623005
            ],
            [
              -87.137502,
              37.807264
            ],
            [
              -87.14195,
              37.816176
            ],
            [
              -87.153486,
              37.832384
            ],
            [
              -87.158878,
              37.837871
            ],
            [
              -87.162319,
              37.840159
            ],
            [
              -87.164863,
              37.841215
            ],
            [
              -87.170831,
              37.842319
            ],
            [
              -87.180063,
              37.841375
            ],
            [
              -87.20224,
              37.843791
            ],
            [
              -87.212416,
              37.846223
            ],
            [
              -87.220944,
              37.849134
            ],
            [
              -87.25525,
              37.867326
            ],
            [
              -87.26293,
              37.872846
            ],
            [
              -87.2686936894134,
              37.878649438995495
            ],
            [
              -87.26989,
              37.879854
            ],
            [
              -87.27437,
              37.882942
            ],
            [
              -87.302324,
              37.898445
            ],
            [
              -87.3025990430289,
              37.8985582968574
            ],
            [
              -87.323402,
              37.819239
            ],
            [
              -87.302875,
              37.808944
            ],
            [
              -87.310481,
              37.799007
            ],
            [
              -87.297317,
              37.785206
            ],
            [
              -87.271162,
              37.781914
            ],
            [
              -87.279756,
              37.773425
            ],
            [
              -87.289784,
              37.761078
            ],
            [
              -87.322738,
              37.741875
            ],
            [
              -87.370759,
              37.737814
            ],
            [
              -87.393511,
              37.724569
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "037",
      "COUNTYNS": "00516865",
      "AFFGEOID": "0500000US21037",
      "GEOID": "21037",
      "NAME": "Campbell",
      "LSAD": "06",
      "ALAND": 391966324,
      "AWATER": 20930398,
      "County_state": "Campbell,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.502062,
              39.096641
            ],
            [
              -84.50608223252529,
              39.0950807457156
            ],
            [
              -84.493817,
              39.072631
            ],
            [
              -84.497739,
              39.058525
            ],
            [
              -84.48522,
              39.041016
            ],
            [
              -84.490038,
              39.030087
            ],
            [
              -84.489205,
              39.021219
            ],
            [
              -84.46976,
              39.004266
            ],
            [
              -84.4508,
              38.993609
            ],
            [
              -84.465874,
              38.977716
            ],
            [
              -84.452837,
              38.967698
            ],
            [
              -84.440368,
              38.984421
            ],
            [
              -84.426791,
              38.979302
            ],
            [
              -84.449412,
              38.949869
            ],
            [
              -84.461755,
              38.949448
            ],
            [
              -84.463112,
              38.945226
            ],
            [
              -84.441619,
              38.907709
            ],
            [
              -84.438912,
              38.90488
            ],
            [
              -84.449258,
              38.862073
            ],
            [
              -84.463842,
              38.850952
            ],
            [
              -84.451507,
              38.820344
            ],
            [
              -84.430312,
              38.828551
            ],
            [
              -84.418107,
              38.806596
            ],
            [
              -84.4081,
              38.805861
            ],
            [
              -84.387389,
              38.812323
            ],
            [
              -84.23191434799891,
              38.8748661027637
            ],
            [
              -84.232132,
              38.880483
            ],
            [
              -84.232343,
              38.884325
            ],
            [
              -84.234453,
              38.893226
            ],
            [
              -84.240155,
              38.900912
            ],
            [
              -84.242689,
              38.903187
            ],
            [
              -84.245647,
              38.907035
            ],
            [
              -84.25701,
              38.923208
            ],
            [
              -84.260797,
              38.926593
            ],
            [
              -84.27491,
              38.941511
            ],
            [
              -84.279916,
              38.945168
            ],
            [
              -84.288164,
              38.955789
            ],
            [
              -84.295076,
              38.968295
            ],
            [
              -84.296466,
              38.985306
            ],
            [
              -84.297255,
              38.989694
            ],
            [
              -84.299362,
              38.995985
            ],
            [
              -84.304698,
              39.006455
            ],
            [
              -84.31368,
              39.016981
            ],
            [
              -84.3199364709232,
              39.02208095553439
            ],
            [
              -84.326539,
              39.027463
            ],
            [
              -84.3294235658147,
              39.0290524546326
            ],
            [
              -84.336339,
              39.032863
            ],
            [
              -84.346039,
              39.036963
            ],
            [
              -84.360439,
              39.041362
            ],
            [
              -84.38684,
              39.045162
            ],
            [
              -84.39414,
              39.045262
            ],
            [
              -84.40094,
              39.046362
            ],
            [
              -84.40254,
              39.045662
            ],
            [
              -84.406941,
              39.045662
            ],
            [
              -84.421467,
              39.050938
            ],
            [
              -84.42573,
              39.053059
            ],
            [
              -84.427913,
              39.054962
            ],
            [
              -84.429841,
              39.058262
            ],
            [
              -84.432341,
              39.067561
            ],
            [
              -84.433141,
              39.072961
            ],
            [
              -84.432641,
              39.078261
            ],
            [
              -84.432941,
              39.083961
            ],
            [
              -84.434641,
              39.086861
            ],
            [
              -84.433941,
              39.092461
            ],
            [
              -84.432841,
              39.094261
            ],
            [
              -84.435541,
              39.102261
            ],
            [
              -84.440642,
              39.109661
            ],
            [
              -84.445242,
              39.114461
            ],
            [
              -84.449793,
              39.117754
            ],
            [
              -84.455342,
              39.12036
            ],
            [
              -84.462042,
              39.12176
            ],
            [
              -84.470542,
              39.12146
            ],
            [
              -84.4712374796217,
              39.121179417097
            ],
            [
              -84.476243,
              39.11916
            ],
            [
              -84.480943,
              39.11676
            ],
            [
              -84.487743,
              39.11076
            ],
            [
              -84.493743,
              39.10246
            ],
            [
              -84.49583814195579,
              39.1008138170347
            ],
            [
              -84.496543,
              39.10026
            ],
            [
              -84.502062,
              39.096641
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "205",
      "COUNTYNS": "00516949",
      "AFFGEOID": "0500000US21205",
      "GEOID": "21205",
      "NAME": "Rowan",
      "LSAD": "06",
      "ALAND": 724723335,
      "AWATER": 16699596,
      "County_state": "Rowan,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.635195,
              38.187525
            ],
            [
              -83.619805,
              38.175569
            ],
            [
              -83.614045,
              38.186633
            ],
            [
              -83.591639,
              38.16916
            ],
            [
              -83.614237,
              38.160824
            ],
            [
              -83.621575,
              38.14912
            ],
            [
              -83.603278,
              38.124047
            ],
            [
              -83.585216,
              38.145478
            ],
            [
              -83.572287,
              38.147376
            ],
            [
              -83.549329,
              38.131816
            ],
            [
              -83.554723,
              38.112439
            ],
            [
              -83.525436,
              38.11935
            ],
            [
              -83.515259,
              38.108089
            ],
            [
              -83.53442,
              38.094693
            ],
            [
              -83.513122,
              38.069387
            ],
            [
              -83.506823,
              38.087343
            ],
            [
              -83.463285,
              38.079711
            ],
            [
              -83.468096,
              38.065289
            ],
            [
              -83.480476,
              38.069143
            ],
            [
              -83.498846,
              38.05062
            ],
            [
              -83.464069,
              38.050214
            ],
            [
              -83.478746,
              38.032365
            ],
            [
              -83.456861,
              38.03288
            ],
            [
              -83.463408,
              38.016975
            ],
            [
              -83.433942,
              38.033974
            ],
            [
              -83.439205,
              38.040192
            ],
            [
              -83.416356,
              38.047668
            ],
            [
              -83.40838,
              38.039019
            ],
            [
              -83.380423,
              38.051669
            ],
            [
              -83.371368,
              38.06956
            ],
            [
              -83.358181,
              38.065697
            ],
            [
              -83.334156,
              38.084245
            ],
            [
              -83.298813,
              38.092586
            ],
            [
              -83.263289,
              38.115386
            ],
            [
              -83.247264,
              38.1304
            ],
            [
              -83.222442,
              38.132072
            ],
            [
              -83.211806,
              38.164473
            ],
            [
              -83.199219,
              38.168998
            ],
            [
              -83.205257,
              38.175513
            ],
            [
              -83.243324,
              38.176098
            ],
            [
              -83.264229,
              38.188098
            ],
            [
              -83.244306,
              38.19238
            ],
            [
              -83.26621,
              38.191499
            ],
            [
              -83.286553,
              38.206247
            ],
            [
              -83.287741,
              38.215522
            ],
            [
              -83.30114,
              38.209703
            ],
            [
              -83.312722,
              38.24402
            ],
            [
              -83.307888,
              38.252254
            ],
            [
              -83.334839,
              38.287003
            ],
            [
              -83.341347,
              38.319521
            ],
            [
              -83.356336,
              38.321869
            ],
            [
              -83.378434,
              38.362722
            ],
            [
              -83.400368,
              38.375694
            ],
            [
              -83.412003,
              38.396446
            ],
            [
              -83.452848,
              38.381799
            ],
            [
              -83.470207,
              38.355217
            ],
            [
              -83.481983,
              38.359716
            ],
            [
              -83.498562,
              38.349822
            ],
            [
              -83.49799,
              38.333886
            ],
            [
              -83.488086,
              38.324496
            ],
            [
              -83.501543,
              38.315804
            ],
            [
              -83.505396,
              38.294132
            ],
            [
              -83.514281,
              38.292054
            ],
            [
              -83.50716,
              38.269447
            ],
            [
              -83.52039,
              38.267719
            ],
            [
              -83.524999,
              38.254617
            ],
            [
              -83.556308,
              38.237285
            ],
            [
              -83.563762,
              38.216918
            ],
            [
              -83.586792,
              38.21828
            ],
            [
              -83.614985,
              38.193341
            ],
            [
              -83.635195,
              38.187525
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "087",
      "COUNTYNS": "00516890",
      "AFFGEOID": "0500000US21087",
      "GEOID": "21087",
      "NAME": "Green",
      "LSAD": "06",
      "ALAND": 740839712,
      "AWATER": 7123457,
      "County_state": "Green,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.685485,
              37.361306
            ],
            [
              -85.697325,
              37.301838
            ],
            [
              -85.682301,
              37.288443
            ],
            [
              -85.680083,
              37.271166
            ],
            [
              -85.66966,
              37.267985
            ],
            [
              -85.67899,
              37.249996
            ],
            [
              -85.686482,
              37.182427
            ],
            [
              -85.660898,
              37.143039
            ],
            [
              -85.632313,
              37.125056
            ],
            [
              -85.590897,
              37.116285
            ],
            [
              -85.526881,
              37.10945
            ],
            [
              -85.43384,
              37.144807
            ],
            [
              -85.380258,
              37.168314
            ],
            [
              -85.352771,
              37.192432
            ],
            [
              -85.371895,
              37.221312
            ],
            [
              -85.410756,
              37.22418
            ],
            [
              -85.403798,
              37.236554
            ],
            [
              -85.391564,
              37.237693
            ],
            [
              -85.393579,
              37.250514
            ],
            [
              -85.431232,
              37.305114
            ],
            [
              -85.478081,
              37.363673
            ],
            [
              -85.584477,
              37.432091
            ],
            [
              -85.583497,
              37.469917
            ],
            [
              -85.606891,
              37.461022
            ],
            [
              -85.634193,
              37.471785
            ],
            [
              -85.638227,
              37.454075
            ],
            [
              -85.665391,
              37.436756
            ],
            [
              -85.657332,
              37.421996
            ],
            [
              -85.651381,
              37.396666
            ],
            [
              -85.685485,
              37.361306
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "147",
      "COUNTYNS": "00516926",
      "AFFGEOID": "0500000US21147",
      "GEOID": "21147",
      "NAME": "McCreary",
      "LSAD": "06",
      "ALAND": 1105416732,
      "AWATER": 10730756,
      "County_state": "McCreary,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.673964,
              36.739542
            ],
            [
              -84.7784559169908,
              36.6032090178664
            ],
            [
              -84.624939,
              36.599575
            ],
            [
              -84.543138,
              36.596277
            ],
            [
              -84.499938,
              36.596678
            ],
            [
              -84.442837,
              36.596079
            ],
            [
              -84.271176,
              36.591882
            ],
            [
              -84.26133898080309,
              36.5919809398456
            ],
            [
              -84.261333,
              36.591981
            ],
            [
              -84.227332,
              36.592181
            ],
            [
              -84.2271936534631,
              36.5921799356786
            ],
            [
              -84.239598,
              36.613354
            ],
            [
              -84.271529,
              36.63395
            ],
            [
              -84.307598,
              36.676809
            ],
            [
              -84.319769,
              36.709756
            ],
            [
              -84.322652,
              36.758669
            ],
            [
              -84.332261,
              36.760304
            ],
            [
              -84.33758,
              36.774401
            ],
            [
              -84.350006,
              36.780681
            ],
            [
              -84.345141,
              36.804705
            ],
            [
              -84.355967,
              36.811019
            ],
            [
              -84.345228,
              36.821558
            ],
            [
              -84.318676,
              36.832628
            ],
            [
              -84.344752,
              36.837492
            ],
            [
              -84.314872,
              36.859556
            ],
            [
              -84.324957,
              36.871184
            ],
            [
              -84.315798,
              36.878897
            ],
            [
              -84.322151,
              36.890819
            ],
            [
              -84.315851,
              36.905425
            ],
            [
              -84.304105,
              36.912459
            ],
            [
              -84.310575,
              36.932218
            ],
            [
              -84.297416,
              36.945931
            ],
            [
              -84.345872,
              36.939496
            ],
            [
              -84.368212,
              36.945588
            ],
            [
              -84.358024,
              36.9594
            ],
            [
              -84.366104,
              36.962885
            ],
            [
              -84.382425,
              36.953195
            ],
            [
              -84.416816,
              36.94692
            ],
            [
              -84.578158,
              36.867133
            ],
            [
              -84.591673,
              36.862507
            ],
            [
              -84.573833,
              36.844288
            ],
            [
              -84.595423,
              36.837267
            ],
            [
              -84.577734,
              36.824952
            ],
            [
              -84.581678,
              36.804039
            ],
            [
              -84.599206,
              36.808628
            ],
            [
              -84.5962,
              36.799852
            ],
            [
              -84.62256,
              36.793933
            ],
            [
              -84.634178,
              36.769233
            ],
            [
              -84.674035,
              36.761265
            ],
            [
              -84.673964,
              36.739542
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "003",
      "COUNTYNS": "00516848",
      "AFFGEOID": "0500000US21003",
      "GEOID": "21003",
      "NAME": "Allen",
      "LSAD": "06",
      "ALAND": 891830211,
      "AWATER": 19490893,
      "County_state": "Allen,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.418888,
              36.676031
            ],
            [
              -86.41138614351739,
              36.6505962464493
            ],
            [
              -86.374991,
              36.649803
            ],
            [
              -86.373784,
              36.650126
            ],
            [
              -86.359073,
              36.649845
            ],
            [
              -86.333051,
              36.648778
            ],
            [
              -86.293357,
              36.645356
            ],
            [
              -86.222151,
              36.640891
            ],
            [
              -86.219081,
              36.640824
            ],
            [
              -86.21641,
              36.640595
            ],
            [
              -86.216183,
              36.640527
            ],
            [
              -86.20546861000119,
              36.6397833130926
            ],
            [
              -86.204859,
              36.639741
            ],
            [
              -86.197573,
              36.639363
            ],
            [
              -86.081944,
              36.633848
            ],
            [
              -86.080666,
              36.63394
            ],
            [
              -86.038366,
              36.630804
            ],
            [
              -86.033139,
              36.630413
            ],
            [
              -86.03277,
              36.630367
            ],
            [
              -85.97584562225909,
              36.628610523713704
            ],
            [
              -85.950661,
              36.651023
            ],
            [
              -85.949403,
              36.661645
            ],
            [
              -85.977442,
              36.715877
            ],
            [
              -85.97691,
              36.722722
            ],
            [
              -85.982876,
              36.741563
            ],
            [
              -86.002309,
              36.758711
            ],
            [
              -86.030735,
              36.764383
            ],
            [
              -86.03759,
              36.760112
            ],
            [
              -86.052797,
              36.775378
            ],
            [
              -86.035311,
              36.779731
            ],
            [
              -86.039224,
              36.790835
            ],
            [
              -86.05229,
              36.795386
            ],
            [
              -86.046984,
              36.807647
            ],
            [
              -86.069957,
              36.805783
            ],
            [
              -86.066045,
              36.822847
            ],
            [
              -86.056835,
              36.828465
            ],
            [
              -86.078016,
              36.8547
            ],
            [
              -86.077346,
              36.892722
            ],
            [
              -86.06531,
              36.899128
            ],
            [
              -86.097399,
              36.900813
            ],
            [
              -86.108695,
              36.882892
            ],
            [
              -86.101443,
              36.864058
            ],
            [
              -86.109879,
              36.868293
            ],
            [
              -86.124777,
              36.895444
            ],
            [
              -86.141296,
              36.892292
            ],
            [
              -86.134673,
              36.902719
            ],
            [
              -86.141926,
              36.91353
            ],
            [
              -86.136291,
              36.932116
            ],
            [
              -86.150469,
              36.926811
            ],
            [
              -86.14897,
              36.911562
            ],
            [
              -86.157767,
              36.906485
            ],
            [
              -86.17641,
              36.914409
            ],
            [
              -86.160555,
              36.92423
            ],
            [
              -86.16674,
              36.934015
            ],
            [
              -86.189027,
              36.921677
            ],
            [
              -86.204176,
              36.933784
            ],
            [
              -86.20703,
              36.907946
            ],
            [
              -86.235608,
              36.897693
            ],
            [
              -86.268273,
              36.892071
            ],
            [
              -86.268776,
              36.887279
            ],
            [
              -86.262657,
              36.884425
            ],
            [
              -86.264111,
              36.876301
            ],
            [
              -86.263405,
              36.873134
            ],
            [
              -86.275191,
              36.865109
            ],
            [
              -86.330947,
              36.82714
            ],
            [
              -86.405769,
              36.776187
            ],
            [
              -86.413629,
              36.738555
            ],
            [
              -86.408595,
              36.720806
            ],
            [
              -86.418888,
              36.676031
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "053",
      "COUNTYNS": "00516873",
      "AFFGEOID": "0500000US21053",
      "GEOID": "21053",
      "NAME": "Clinton",
      "LSAD": "06",
      "ALAND": 510864294,
      "AWATER": 21164151,
      "County_state": "Clinton,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.2959488756198,
              36.6258382676941
            ],
            [
              -85.290627,
              36.62645
            ],
            [
              -85.276289,
              36.626511
            ],
            [
              -85.2762840339111,
              36.626510937829494
            ],
            [
              -85.195372,
              36.625498
            ],
            [
              -85.096128,
              36.622483
            ],
            [
              -85.086415,
              36.621913
            ],
            [
              -85.024627,
              36.619354
            ],
            [
              -84.986448,
              36.616668
            ],
            [
              -84.9748750576222,
              36.6155518237457
            ],
            [
              -84.999106,
              36.624161
            ],
            [
              -85.004388,
              36.642899
            ],
            [
              -84.998144,
              36.663097
            ],
            [
              -85.009305,
              36.668287
            ],
            [
              -85.001267,
              36.67674
            ],
            [
              -85.018502,
              36.689872
            ],
            [
              -85.019998,
              36.717945
            ],
            [
              -85.004099,
              36.756237
            ],
            [
              -85.026377,
              36.806912
            ],
            [
              -85.059167,
              36.833097
            ],
            [
              -85.064307,
              36.858743
            ],
            [
              -85.118411,
              36.827334
            ],
            [
              -85.196988,
              36.874053
            ],
            [
              -85.235046,
              36.885673
            ],
            [
              -85.215405,
              36.854467
            ],
            [
              -85.246357,
              36.744294
            ],
            [
              -85.2959488756198,
              36.6258382676941
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "111",
      "COUNTYNS": "00516902",
      "AFFGEOID": "0500000US21111",
      "GEOID": "21111",
      "NAME": "Jefferson",
      "LSAD": "06",
      "ALAND": 985870988,
      "AWATER": 44122247,
      "County_state": "Jefferson,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.939483,
              38.010951
            ],
            [
              -85.9456286311378,
              38.008211004408395
            ],
            [
              -85.938321,
              37.998756
            ],
            [
              -85.934643,
              37.997217
            ],
            [
              -85.912789,
              38.020652
            ],
            [
              -85.882805,
              38.040545
            ],
            [
              -85.842259,
              38.051485
            ],
            [
              -85.816945,
              38.058446
            ],
            [
              -85.790154,
              38.065842
            ],
            [
              -85.712578,
              38.087311
            ],
            [
              -85.69931,
              38.086173
            ],
            [
              -85.616942,
              38.079061
            ],
            [
              -85.567145,
              38.074743
            ],
            [
              -85.55091,
              38.076135
            ],
            [
              -85.529477,
              38.083864
            ],
            [
              -85.515895,
              38.088766
            ],
            [
              -85.42883,
              38.118383
            ],
            [
              -85.427678,
              38.125821
            ],
            [
              -85.424054,
              38.147438
            ],
            [
              -85.404899,
              38.26373
            ],
            [
              -85.411364,
              38.26592
            ],
            [
              -85.468206,
              38.285309
            ],
            [
              -85.476253,
              38.289695
            ],
            [
              -85.492437,
              38.29854
            ],
            [
              -85.498273,
              38.301741
            ],
            [
              -85.526966,
              38.31795
            ],
            [
              -85.545363,
              38.328248
            ],
            [
              -85.585272,
              38.339431
            ],
            [
              -85.569413,
              38.341752
            ],
            [
              -85.63804773991271,
              38.3802883493094
            ],
            [
              -85.638521,
              38.376802
            ],
            [
              -85.638009,
              38.366115
            ],
            [
              -85.638777,
              38.361443
            ],
            [
              -85.646201,
              38.342916
            ],
            [
              -85.653641,
              38.327108
            ],
            [
              -85.659897,
              38.319396
            ],
            [
              -85.668698,
              38.310517
            ],
            [
              -85.675017,
              38.301317
            ],
            [
              -85.6757904672099,
              38.3007875950089
            ],
            [
              -85.683561,
              38.295469
            ],
            [
              -85.68603448270309,
              38.2942990204947
            ],
            [
              -85.7130753274456,
              38.2815084583798
            ],
            [
              -85.73345550237049,
              38.2718684528336
            ],
            [
              -85.738746,
              38.269366
            ],
            [
              -85.744862,
              38.26717
            ],
            [
              -85.750962,
              38.26787
            ],
            [
              -85.761062,
              38.27257
            ],
            [
              -85.766563,
              38.27767
            ],
            [
              -85.765763,
              38.279669
            ],
            [
              -85.765963,
              38.280469
            ],
            [
              -85.773363,
              38.286169
            ],
            [
              -85.780963,
              38.288469
            ],
            [
              -85.791563,
              38.288569
            ],
            [
              -85.7915755577848,
              38.2885654838202
            ],
            [
              -85.794063,
              38.287869
            ],
            [
              -85.796063,
              38.286669
            ],
            [
              -85.802563,
              38.284969
            ],
            [
              -85.816164,
              38.282969
            ],
            [
              -85.823764,
              38.280569
            ],
            [
              -85.829364,
              38.276769
            ],
            [
              -85.834864,
              38.268069
            ],
            [
              -85.838064,
              38.257369
            ],
            [
              -85.837964,
              38.25117
            ],
            [
              -85.8396575160708,
              38.2398134804667
            ],
            [
              -85.839664,
              38.23977
            ],
            [
              -85.845464,
              38.23027
            ],
            [
              -85.8457451799961,
              38.2299366048974
            ],
            [
              -85.851436,
              38.223189
            ],
            [
              -85.868564,
              38.211969
            ],
            [
              -85.87186928365789,
              38.20953947526
            ],
            [
              -85.8732389448897,
              38.2085327157221
            ],
            [
              -85.8745408219975,
              38.2075757804121
            ],
            [
              -85.880264,
              38.203369
            ],
            [
              -85.894764,
              38.188469
            ],
            [
              -85.897664,
              38.184269
            ],
            [
              -85.89955038654419,
              38.1803432766511
            ],
            [
              -85.908764,
              38.161169
            ],
            [
              -85.909464,
              38.14007
            ],
            [
              -85.9051687084466,
              38.1111017546398
            ],
            [
              -85.905164,
              38.11107
            ],
            [
              -85.904564,
              38.10027
            ],
            [
              -85.906163,
              38.08617
            ],
            [
              -85.913163,
              38.07337
            ],
            [
              -85.915643,
              38.06647
            ],
            [
              -85.916987,
              38.061846
            ],
            [
              -85.918379,
              38.054214
            ],
            [
              -85.919563,
              38.041079
            ],
            [
              -85.921371,
              38.032135
            ],
            [
              -85.922395,
              38.028679
            ],
            [
              -85.92390443119619,
              38.0260710743837
            ],
            [
              -85.925418,
              38.023456
            ],
            [
              -85.930235,
              38.018311
            ],
            [
              -85.934635,
              38.014423
            ],
            [
              -85.939483,
              38.010951
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "071",
      "COUNTYNS": "00516882",
      "AFFGEOID": "0500000US21071",
      "GEOID": "21071",
      "NAME": "Floyd",
      "LSAD": "06",
      "ALAND": 1018773537,
      "AWATER": 6098292,
      "County_state": "Floyd,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.940763,
              37.716338
            ],
            [
              -82.945873,
              37.677938
            ],
            [
              -82.927762,
              37.656547
            ],
            [
              -82.912425,
              37.653598
            ],
            [
              -82.903122,
              37.64069
            ],
            [
              -82.90625,
              37.606593
            ],
            [
              -82.89654,
              37.588485
            ],
            [
              -82.902147,
              37.576932
            ],
            [
              -82.888851,
              37.564619
            ],
            [
              -82.889865,
              37.543815
            ],
            [
              -82.879082,
              37.535395
            ],
            [
              -82.892422,
              37.532426
            ],
            [
              -82.896178,
              37.508926
            ],
            [
              -82.9106,
              37.499523
            ],
            [
              -82.909546,
              37.491498
            ],
            [
              -82.92546,
              37.486525
            ],
            [
              -82.927846,
              37.480934
            ],
            [
              -82.831473,
              37.46699
            ],
            [
              -82.831499,
              37.436412
            ],
            [
              -82.835476,
              37.414445
            ],
            [
              -82.810522,
              37.411531
            ],
            [
              -82.781926,
              37.429463
            ],
            [
              -82.762749,
              37.425133
            ],
            [
              -82.755848,
              37.4136
            ],
            [
              -82.756568,
              37.380252
            ],
            [
              -82.752746,
              37.370544
            ],
            [
              -82.764316,
              37.352379
            ],
            [
              -82.752222,
              37.337022
            ],
            [
              -82.748166,
              37.31679
            ],
            [
              -82.752346,
              37.308039
            ],
            [
              -82.724957,
              37.301313
            ],
            [
              -82.725709,
              37.288786
            ],
            [
              -82.710805,
              37.285323
            ],
            [
              -82.700241,
              37.290513
            ],
            [
              -82.693716,
              37.306103
            ],
            [
              -82.672375,
              37.306841
            ],
            [
              -82.669844,
              37.3213
            ],
            [
              -82.651981,
              37.351461
            ],
            [
              -82.666169,
              37.370026
            ],
            [
              -82.653625,
              37.380304
            ],
            [
              -82.633029,
              37.379312
            ],
            [
              -82.618803,
              37.386661
            ],
            [
              -82.621927,
              37.393584
            ],
            [
              -82.604638,
              37.410217
            ],
            [
              -82.601937,
              37.436071
            ],
            [
              -82.588627,
              37.446259
            ],
            [
              -82.587124,
              37.473051
            ],
            [
              -82.597786,
              37.502749
            ],
            [
              -82.623576,
              37.504051
            ],
            [
              -82.598278,
              37.642226
            ],
            [
              -82.614538,
              37.670322
            ],
            [
              -82.563561,
              37.684844
            ],
            [
              -82.565003,
              37.689132
            ],
            [
              -82.621511,
              37.704692
            ],
            [
              -82.64075,
              37.720182
            ],
            [
              -82.644577,
              37.7243
            ],
            [
              -82.687246,
              37.729582
            ],
            [
              -82.684956,
              37.735344
            ],
            [
              -82.708284,
              37.739517
            ],
            [
              -82.723691,
              37.749244
            ],
            [
              -82.739382,
              37.743954
            ],
            [
              -82.75223,
              37.756227
            ],
            [
              -82.763313,
              37.75612
            ],
            [
              -82.767106,
              37.741807
            ],
            [
              -82.785675,
              37.746058
            ],
            [
              -82.845244,
              37.729557
            ],
            [
              -82.870833,
              37.729141
            ],
            [
              -82.876262,
              37.719312
            ],
            [
              -82.900703,
              37.703312
            ],
            [
              -82.927258,
              37.719369
            ],
            [
              -82.940763,
              37.716338
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "155",
      "COUNTYNS": "00516921",
      "AFFGEOID": "0500000US21155",
      "GEOID": "21155",
      "NAME": "Marion",
      "LSAD": "06",
      "ALAND": 888390643,
      "AWATER": 9967834,
      "County_state": "Marion,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.52129,
              37.554343
            ],
            [
              -85.503001,
              37.54909
            ],
            [
              -85.491748,
              37.515673
            ],
            [
              -85.473727,
              37.491839
            ],
            [
              -85.46625,
              37.465595
            ],
            [
              -85.442733,
              37.471791
            ],
            [
              -85.403337,
              37.475157
            ],
            [
              -85.39771,
              37.468535
            ],
            [
              -85.369437,
              37.466188
            ],
            [
              -85.340446,
              37.469512
            ],
            [
              -85.319789,
              37.450199
            ],
            [
              -85.311657,
              37.450817
            ],
            [
              -85.278408,
              37.434838
            ],
            [
              -85.272278,
              37.417282
            ],
            [
              -85.264819,
              37.416456
            ],
            [
              -85.246759,
              37.420205
            ],
            [
              -85.228147,
              37.44098
            ],
            [
              -85.205954,
              37.443131
            ],
            [
              -85.193488,
              37.454627
            ],
            [
              -85.17144,
              37.465081
            ],
            [
              -85.143087,
              37.44733
            ],
            [
              -85.1288,
              37.453261
            ],
            [
              -85.112183,
              37.436344
            ],
            [
              -85.098059,
              37.431974
            ],
            [
              -85.088127,
              37.418216
            ],
            [
              -85.073772,
              37.413669
            ],
            [
              -85.044807,
              37.412038
            ],
            [
              -85.043508,
              37.500094
            ],
            [
              -85.039674,
              37.545232
            ],
            [
              -85.030172,
              37.63127
            ],
            [
              -85.166084,
              37.63111
            ],
            [
              -85.257298,
              37.630903
            ],
            [
              -85.311239,
              37.651776
            ],
            [
              -85.340697,
              37.651423
            ],
            [
              -85.349334,
              37.655663
            ],
            [
              -85.373639,
              37.673343
            ],
            [
              -85.369414,
              37.688201
            ],
            [
              -85.37473,
              37.702373
            ],
            [
              -85.390911,
              37.713432
            ],
            [
              -85.401784,
              37.730658
            ],
            [
              -85.450493,
              37.685948
            ],
            [
              -85.52129,
              37.554343
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "191",
      "COUNTYNS": "00516942",
      "AFFGEOID": "0500000US21191",
      "GEOID": "21191",
      "NAME": "Pendleton",
      "LSAD": "06",
      "ALAND": 717836419,
      "AWATER": 12328246,
      "County_state": "Pendleton,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.532288,
              38.791937
            ],
            [
              -84.476299,
              38.542723
            ],
            [
              -84.448556,
              38.529422
            ],
            [
              -84.448343,
              38.53658
            ],
            [
              -84.419285,
              38.543435
            ],
            [
              -84.427891,
              38.546463
            ],
            [
              -84.412442,
              38.564656
            ],
            [
              -84.400321,
              38.560603
            ],
            [
              -84.205346,
              38.582039
            ],
            [
              -84.19537,
              38.589256
            ],
            [
              -84.201054,
              38.603857
            ],
            [
              -84.207373,
              38.597602
            ],
            [
              -84.235962,
              38.822361
            ],
            [
              -84.23042691143739,
              38.8274224447174
            ],
            [
              -84.231306,
              38.830552
            ],
            [
              -84.233265,
              38.842671
            ],
            [
              -84.233727,
              38.853576
            ],
            [
              -84.231837,
              38.87287
            ],
            [
              -84.23191434799891,
              38.8748661027637
            ],
            [
              -84.387389,
              38.812323
            ],
            [
              -84.4081,
              38.805861
            ],
            [
              -84.418107,
              38.806596
            ],
            [
              -84.423905,
              38.805227
            ],
            [
              -84.532288,
              38.791937
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "015",
      "COUNTYNS": "00516854",
      "AFFGEOID": "0500000US21015",
      "GEOID": "21015",
      "NAME": "Boone",
      "LSAD": "06",
      "ALAND": 637796403,
      "AWATER": 26558591,
      "County_state": "Boone,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.897364,
              39.057378
            ],
            [
              -84.897171,
              39.052407
            ],
            [
              -84.894281,
              39.049572
            ],
            [
              -84.889065,
              39.04082
            ],
            [
              -84.882856,
              39.034031
            ],
            [
              -84.87805047759561,
              39.0308192384939
            ],
            [
              -84.870168,
              39.025551
            ],
            [
              -84.8646449061898,
              39.0196875474676
            ],
            [
              -84.856959,
              39.011528
            ],
            [
              -84.850354,
              39.00325
            ],
            [
              -84.849445,
              39.000923
            ],
            [
              -84.847094,
              38.997309
            ],
            [
              -84.83983,
              38.99129
            ],
            [
              -84.83712,
              38.988059
            ],
            [
              -84.833473,
              38.981522
            ],
            [
              -84.830619,
              38.974898
            ],
            [
              -84.829857,
              38.969385
            ],
            [
              -84.832617,
              38.96146
            ],
            [
              -84.83516,
              38.957961
            ],
            [
              -84.864731,
              38.934893
            ],
            [
              -84.870759,
              38.929231
            ],
            [
              -84.877762,
              38.920357
            ],
            [
              -84.879268,
              38.916116
            ],
            [
              -84.878817,
              38.913405
            ],
            [
              -84.877029,
              38.909016
            ],
            [
              -84.8706197834529,
              38.9010084241634
            ],
            [
              -84.870124,
              38.900389
            ],
            [
              -84.867778,
              38.899133
            ],
            [
              -84.860759,
              38.897654
            ],
            [
              -84.830472,
              38.897256
            ],
            [
              -84.819073,
              38.895469
            ],
            [
              -84.812746,
              38.895132
            ],
            [
              -84.800247,
              38.89107
            ],
            [
              -84.788143,
              38.883728
            ],
            [
              -84.786406,
              38.88222
            ],
            [
              -84.785234,
              38.880439
            ],
            [
              -84.784579,
              38.87532
            ],
            [
              -84.785799,
              38.869496
            ],
            [
              -84.788302,
              38.864325
            ],
            [
              -84.791002,
              38.860572
            ],
            [
              -84.793714,
              38.857788
            ],
            [
              -84.794616596501,
              38.857119076861395
            ],
            [
              -84.780075,
              38.859509
            ],
            [
              -84.750732,
              38.831008
            ],
            [
              -84.682678,
              38.790654
            ],
            [
              -84.660107,
              38.777293
            ],
            [
              -84.643581,
              38.797995
            ],
            [
              -84.615664,
              38.802277
            ],
            [
              -84.60854,
              38.833319
            ],
            [
              -84.597932,
              38.879431
            ],
            [
              -84.609169,
              38.957167
            ],
            [
              -84.61098,
              38.971893
            ],
            [
              -84.611775,
              38.978301
            ],
            [
              -84.61667,
              39.018495
            ],
            [
              -84.622019,
              39.062277
            ],
            [
              -84.6234682396614,
              39.0743557886818
            ],
            [
              -84.632446,
              39.07676
            ],
            [
              -84.650346,
              39.09166
            ],
            [
              -84.657246,
              39.09546
            ],
            [
              -84.666147,
              39.09796
            ],
            [
              -84.677247,
              39.09826
            ],
            [
              -84.684847,
              39.100459
            ],
            [
              -84.689747,
              39.104159
            ],
            [
              -84.69248451179729,
              39.1074799159508
            ],
            [
              -84.701947,
              39.118959
            ],
            [
              -84.714048,
              39.132659
            ],
            [
              -84.718548,
              39.137059
            ],
            [
              -84.726148,
              39.141958
            ],
            [
              -84.732048,
              39.144458
            ],
            [
              -84.744149,
              39.147458
            ],
            [
              -84.750749,
              39.147358
            ],
            [
              -84.754449,
              39.146658
            ],
            [
              -84.766749,
              39.138558
            ],
            [
              -84.783991,
              39.11806
            ],
            [
              -84.78768,
              39.115297
            ],
            [
              -84.79382,
              39.112939
            ],
            [
              -84.810753,
              39.109142
            ],
            [
              -84.812241,
              39.107102
            ],
            [
              -84.813767,
              39.106492
            ],
            [
              -84.820157,
              39.10548
            ],
            [
              -84.826246,
              39.10417
            ],
            [
              -84.831197,
              39.10192
            ],
            [
              -84.839515,
              39.095292
            ],
            [
              -84.849574,
              39.088264
            ],
            [
              -84.860689,
              39.07814
            ],
            [
              -84.87095572870109,
              39.073854668022996
            ],
            [
              -84.888873,
              39.066376
            ],
            [
              -84.893873,
              39.062466
            ],
            [
              -84.897364,
              39.057378
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "141",
      "COUNTYNS": "00516917",
      "AFFGEOID": "0500000US21141",
      "GEOID": "21141",
      "NAME": "Logan",
      "LSAD": "06",
      "ALAND": 1430014646,
      "AWATER": 12688607,
      "County_state": "Logan,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.059535,
              36.747951
            ],
            [
              -87.0608458150142,
              36.6432171572868
            ],
            [
              -87.011522,
              36.643949
            ],
            [
              -86.906583,
              36.646255
            ],
            [
              -86.906023,
              36.646302
            ],
            [
              -86.854268,
              36.646884
            ],
            [
              -86.833155,
              36.64721
            ],
            [
              -86.818405,
              36.647639
            ],
            [
              -86.816186,
              36.647722
            ],
            [
              -86.813037,
              36.647647
            ],
            [
              -86.7632948135703,
              36.648907168479305
            ],
            [
              -86.75134,
              36.72712
            ],
            [
              -86.740301,
              36.755737
            ],
            [
              -86.716418,
              36.795337
            ],
            [
              -86.704274,
              36.809729
            ],
            [
              -86.690719,
              36.8443
            ],
            [
              -86.655888,
              36.876256
            ],
            [
              -86.632405,
              36.882929
            ],
            [
              -86.611586,
              36.883057
            ],
            [
              -86.616457,
              36.892156
            ],
            [
              -86.674462,
              36.999766
            ],
            [
              -86.84535,
              37.056513
            ],
            [
              -86.941391,
              37.068981
            ],
            [
              -86.976399,
              37.073588
            ],
            [
              -87.053164,
              37.061019
            ],
            [
              -87.058188,
              36.804718
            ],
            [
              -87.05016,
              36.767516
            ],
            [
              -87.059535,
              36.747951
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "215",
      "COUNTYNS": "00516954",
      "AFFGEOID": "0500000US21215",
      "GEOID": "21215",
      "NAME": "Spencer",
      "LSAD": "06",
      "ALAND": 483503272,
      "AWATER": 12896776,
      "County_state": "Spencer,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.516476,
              38.011257
            ],
            [
              -85.489202,
              37.990654
            ],
            [
              -85.463438,
              37.971377
            ],
            [
              -85.425822,
              37.959632
            ],
            [
              -85.399559,
              37.939929
            ],
            [
              -85.379145,
              37.940353
            ],
            [
              -85.305058,
              37.953189
            ],
            [
              -85.167834,
              37.971809
            ],
            [
              -85.169359,
              37.997576
            ],
            [
              -85.131052,
              37.99588
            ],
            [
              -85.126452,
              38.020292
            ],
            [
              -85.101646,
              38.03727
            ],
            [
              -85.196117,
              38.074822
            ],
            [
              -85.232406,
              38.089431
            ],
            [
              -85.424054,
              38.147438
            ],
            [
              -85.427678,
              38.125821
            ],
            [
              -85.42883,
              38.118383
            ],
            [
              -85.436129,
              38.104761
            ],
            [
              -85.469231,
              38.096222
            ],
            [
              -85.486643,
              38.062424
            ],
            [
              -85.516476,
              38.011257
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "201",
      "COUNTYNS": "00516947",
      "AFFGEOID": "0500000US21201",
      "GEOID": "21201",
      "NAME": "Robertson",
      "LSAD": "06",
      "ALAND": 258799092,
      "AWATER": 484952,
      "County_state": "Robertson,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.178484,
              38.532774
            ],
            [
              -84.183903,
              38.498019
            ],
            [
              -84.153211,
              38.490001
            ],
            [
              -84.149769,
              38.479092
            ],
            [
              -84.132184,
              38.483181
            ],
            [
              -84.1021,
              38.459379
            ],
            [
              -84.073362,
              38.457407
            ],
            [
              -84.010146,
              38.432872
            ],
            [
              -83.999227,
              38.422286
            ],
            [
              -83.980068,
              38.439448
            ],
            [
              -83.982823,
              38.451825
            ],
            [
              -83.930348,
              38.492278
            ],
            [
              -83.960936,
              38.532713
            ],
            [
              -83.970778,
              38.587208
            ],
            [
              -83.991605,
              38.593701
            ],
            [
              -83.997136,
              38.583307
            ],
            [
              -84.033695,
              38.582947
            ],
            [
              -84.045016,
              38.60005
            ],
            [
              -84.074408,
              38.598636
            ],
            [
              -84.083746,
              38.608594
            ],
            [
              -84.083512,
              38.597737
            ],
            [
              -84.104477,
              38.586288
            ],
            [
              -84.122315,
              38.564155
            ],
            [
              -84.146365,
              38.549802
            ],
            [
              -84.162031,
              38.554125
            ],
            [
              -84.178484,
              38.532774
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "157",
      "COUNTYNS": "00516922",
      "AFFGEOID": "0500000US21157",
      "GEOID": "21157",
      "NAME": "Marshall",
      "LSAD": "06",
      "ALAND": 780276604,
      "AWATER": 100761088,
      "County_state": "Marshall,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.484103,
              36.942096
            ],
            [
              -88.485697,
              36.750328
            ],
            [
              -88.136108,
              36.74866
            ],
            [
              -88.110831,
              36.74715
            ],
            [
              -88.129944,
              36.807546
            ],
            [
              -88.157347,
              36.867183
            ],
            [
              -88.180283,
              36.900827
            ],
            [
              -88.189389,
              36.935549
            ],
            [
              -88.240371,
              36.981911
            ],
            [
              -88.307753,
              37.048531
            ],
            [
              -88.339292,
              37.061216
            ],
            [
              -88.365595,
              37.064183
            ],
            [
              -88.393756,
              37.05569
            ],
            [
              -88.426519,
              37.050079
            ],
            [
              -88.448901,
              37.034929
            ],
            [
              -88.482957,
              37.022566
            ],
            [
              -88.485061,
              36.981083
            ],
            [
              -88.484103,
              36.942096
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "175",
      "COUNTYNS": "00516934",
      "AFFGEOID": "0500000US21175",
      "GEOID": "21175",
      "NAME": "Morgan",
      "LSAD": "06",
      "ALAND": 987114420,
      "AWATER": 6889722,
      "County_state": "Morgan,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.496027,
              37.890628
            ],
            [
              -83.506513,
              37.869737
            ],
            [
              -83.49508,
              37.861906
            ],
            [
              -83.478128,
              37.851814
            ],
            [
              -83.484258,
              37.84391
            ],
            [
              -83.45532,
              37.842679
            ],
            [
              -83.433048,
              37.819112
            ],
            [
              -83.386437,
              37.813675
            ],
            [
              -83.387593,
              37.800357
            ],
            [
              -83.367364,
              37.799825
            ],
            [
              -83.335866,
              37.778836
            ],
            [
              -83.329526,
              37.783269
            ],
            [
              -83.334089,
              37.763677
            ],
            [
              -83.326426,
              37.753115
            ],
            [
              -83.296932,
              37.744688
            ],
            [
              -83.279881,
              37.731544
            ],
            [
              -83.281249,
              37.718921
            ],
            [
              -83.262172,
              37.712657
            ],
            [
              -83.243175,
              37.744004
            ],
            [
              -83.27064,
              37.769169
            ],
            [
              -83.24734,
              37.785427
            ],
            [
              -83.225684,
              37.790104
            ],
            [
              -83.207849,
              37.819104
            ],
            [
              -83.178454,
              37.821484
            ],
            [
              -83.173889,
              37.831929
            ],
            [
              -83.113277,
              37.864121
            ],
            [
              -83.129258,
              37.894438
            ],
            [
              -83.089409,
              37.89034
            ],
            [
              -83.056795,
              37.87015
            ],
            [
              -83.042535,
              37.87226
            ],
            [
              -83.034646,
              37.886384
            ],
            [
              -83.021502,
              37.867916
            ],
            [
              -83.004649,
              37.859247
            ],
            [
              -82.989217,
              37.869589
            ],
            [
              -82.981858,
              37.898878
            ],
            [
              -82.961423,
              37.893827
            ],
            [
              -82.96166,
              37.912882
            ],
            [
              -82.989014,
              37.963968
            ],
            [
              -83.015694,
              37.973348
            ],
            [
              -83.022115,
              38.007047
            ],
            [
              -83.029133,
              38.009491
            ],
            [
              -83.047871,
              37.996728
            ],
            [
              -83.065341,
              38.007949
            ],
            [
              -83.101689,
              38.009751
            ],
            [
              -83.109823,
              38.0039
            ],
            [
              -83.136357,
              38.004285
            ],
            [
              -83.144613,
              37.996593
            ],
            [
              -83.151976,
              38.010445
            ],
            [
              -83.174215,
              38.017036
            ],
            [
              -83.194445,
              38.010649
            ],
            [
              -83.22155,
              38.027392
            ],
            [
              -83.222891,
              38.043956
            ],
            [
              -83.232842,
              38.051856
            ],
            [
              -83.250391,
              38.050318
            ],
            [
              -83.267544,
              38.05895
            ],
            [
              -83.270846,
              38.069202
            ],
            [
              -83.261269,
              38.102292
            ],
            [
              -83.263289,
              38.115386
            ],
            [
              -83.298813,
              38.092586
            ],
            [
              -83.334156,
              38.084245
            ],
            [
              -83.358181,
              38.065697
            ],
            [
              -83.371368,
              38.06956
            ],
            [
              -83.380423,
              38.051669
            ],
            [
              -83.40838,
              38.039019
            ],
            [
              -83.416356,
              38.047668
            ],
            [
              -83.439205,
              38.040192
            ],
            [
              -83.433942,
              38.033974
            ],
            [
              -83.444343,
              37.942468
            ],
            [
              -83.496027,
              37.890628
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "177",
      "COUNTYNS": "00516935",
      "AFFGEOID": "0500000US21177",
      "GEOID": "21177",
      "NAME": "Muhlenberg",
      "LSAD": "06",
      "ALAND": 1209734539,
      "AWATER": 31905594,
      "County_state": "Muhlenberg,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.38703,
              37.273606
            ],
            [
              -87.388826,
              37.261395
            ],
            [
              -87.364081,
              37.249
            ],
            [
              -87.369146,
              37.238607
            ],
            [
              -87.34683,
              37.223474
            ],
            [
              -87.349044,
              37.214505
            ],
            [
              -87.334676,
              37.210988
            ],
            [
              -87.32451,
              37.196566
            ],
            [
              -87.306706,
              37.191836
            ],
            [
              -87.317096,
              37.186235
            ],
            [
              -87.311904,
              37.176482
            ],
            [
              -87.327041,
              37.17106
            ],
            [
              -87.333741,
              37.157186
            ],
            [
              -87.334277,
              37.131818
            ],
            [
              -87.3059,
              37.107389
            ],
            [
              -87.284329,
              37.108024
            ],
            [
              -87.279584,
              37.089008
            ],
            [
              -87.285534,
              37.08498
            ],
            [
              -87.274102,
              37.070514
            ],
            [
              -87.259371,
              37.072401
            ],
            [
              -87.245248,
              37.064116
            ],
            [
              -87.250256,
              37.040557
            ],
            [
              -87.118069,
              37.044732
            ],
            [
              -87.053164,
              37.061019
            ],
            [
              -86.976399,
              37.073588
            ],
            [
              -86.941391,
              37.068981
            ],
            [
              -86.940771,
              37.078511
            ],
            [
              -86.921824,
              37.07816
            ],
            [
              -86.894067,
              37.088349
            ],
            [
              -86.903984,
              37.101542
            ],
            [
              -86.901639,
              37.112497
            ],
            [
              -86.881778,
              37.1116
            ],
            [
              -86.899759,
              37.122469
            ],
            [
              -86.900352,
              37.134065
            ],
            [
              -86.915982,
              37.125827
            ],
            [
              -86.912782,
              37.135896
            ],
            [
              -86.924326,
              37.142734
            ],
            [
              -86.938588,
              37.137077
            ],
            [
              -86.926882,
              37.16027
            ],
            [
              -86.922165,
              37.180151
            ],
            [
              -86.895336,
              37.177361
            ],
            [
              -86.905478,
              37.2024
            ],
            [
              -86.899268,
              37.212313
            ],
            [
              -86.919271,
              37.225191
            ],
            [
              -86.909751,
              37.232772
            ],
            [
              -86.92322,
              37.241494
            ],
            [
              -86.947009,
              37.240586
            ],
            [
              -86.954838,
              37.257618
            ],
            [
              -86.971538,
              37.257963
            ],
            [
              -86.98267,
              37.269632
            ],
            [
              -86.980933,
              37.286336
            ],
            [
              -86.988177,
              37.302179
            ],
            [
              -86.985957,
              37.316566
            ],
            [
              -87.016147,
              37.353144
            ],
            [
              -87.030567,
              37.363307
            ],
            [
              -87.051895,
              37.369467
            ],
            [
              -87.07133,
              37.370298
            ],
            [
              -87.087373,
              37.359842
            ],
            [
              -87.103789,
              37.327893
            ],
            [
              -87.121105,
              37.32588
            ],
            [
              -87.136983,
              37.335524
            ],
            [
              -87.124382,
              37.360054
            ],
            [
              -87.095156,
              37.37593
            ],
            [
              -87.099803,
              37.416042
            ],
            [
              -87.112297,
              37.411537
            ],
            [
              -87.171068,
              37.413589
            ],
            [
              -87.210958,
              37.38093
            ],
            [
              -87.295965,
              37.391819
            ],
            [
              -87.309926,
              37.376523
            ],
            [
              -87.300984,
              37.374704
            ],
            [
              -87.322558,
              37.343876
            ],
            [
              -87.338221,
              37.335992
            ],
            [
              -87.346414,
              37.309536
            ],
            [
              -87.366757,
              37.318543
            ],
            [
              -87.378817,
              37.311997
            ],
            [
              -87.38703,
              37.273606
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "213",
      "COUNTYNS": "00516953",
      "AFFGEOID": "0500000US21213",
      "GEOID": "21213",
      "NAME": "Simpson",
      "LSAD": "06",
      "ALAND": 606584079,
      "AWATER": 5857862,
      "County_state": "Simpson,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.7632948135703,
              36.648907168479305
            ],
            [
              -86.75892,
              36.649018
            ],
            [
              -86.713786,
              36.649341
            ],
            [
              -86.606394,
              36.652107
            ],
            [
              -86.605042,
              36.652125
            ],
            [
              -86.589906,
              36.652486
            ],
            [
              -86.564143,
              36.633472
            ],
            [
              -86.56391574841909,
              36.6335518059594
            ],
            [
              -86.551292,
              36.637985
            ],
            [
              -86.550054,
              36.644817
            ],
            [
              -86.543388,
              36.64389
            ],
            [
              -86.543777,
              36.640536
            ],
            [
              -86.507771,
              36.652445
            ],
            [
              -86.473497,
              36.651671
            ],
            [
              -86.473413,
              36.651676
            ],
            [
              -86.47219,
              36.651763
            ],
            [
              -86.468497,
              36.651841
            ],
            [
              -86.41138614351739,
              36.6505962464493
            ],
            [
              -86.418888,
              36.676031
            ],
            [
              -86.408595,
              36.720806
            ],
            [
              -86.413629,
              36.738555
            ],
            [
              -86.405769,
              36.776187
            ],
            [
              -86.400518,
              36.807226
            ],
            [
              -86.583289,
              36.830282
            ],
            [
              -86.603976,
              36.868922
            ],
            [
              -86.611586,
              36.883057
            ],
            [
              -86.632405,
              36.882929
            ],
            [
              -86.655888,
              36.876256
            ],
            [
              -86.690719,
              36.8443
            ],
            [
              -86.704274,
              36.809729
            ],
            [
              -86.716418,
              36.795337
            ],
            [
              -86.740301,
              36.755737
            ],
            [
              -86.75134,
              36.72712
            ],
            [
              -86.7632948135703,
              36.648907168479305
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "007",
      "COUNTYNS": "00516850",
      "AFFGEOID": "0500000US21007",
      "GEOID": "21007",
      "NAME": "Ballard",
      "LSAD": "06",
      "ALAND": 638850964,
      "AWATER": 70009832,
      "County_state": "Ballard,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.181369,
              37.046305
            ],
            [
              -89.182509,
              37.037275
            ],
            [
              -89.180849,
              37.026843
            ],
            [
              -89.178975,
              37.020928
            ],
            [
              -89.173595,
              37.011409
            ],
            [
              -89.17112,
              37.008072
            ],
            [
              -89.166447,
              37.003337
            ],
            [
              -89.160667,
              37.000051
            ],
            [
              -89.138437,
              36.985089
            ],
            [
              -89.132915,
              36.982057
            ],
            [
              -89.128868,
              36.983265
            ],
            [
              -89.125069,
              36.983499
            ],
            [
              -89.1183,
              36.981879
            ],
            [
              -89.11503,
              36.980335
            ],
            [
              -89.109498,
              36.976563
            ],
            [
              -89.102879,
              36.9697
            ],
            [
              -89.099594,
              36.964543
            ],
            [
              -89.099007,
              36.961389
            ],
            [
              -89.098843,
              36.95785
            ],
            [
              -89.10076200668469,
              36.9440018170751
            ],
            [
              -89.080505,
              36.915544
            ],
            [
              -89.063836,
              36.923224
            ],
            [
              -89.071206,
              36.93182
            ],
            [
              -89.048567,
              36.935912
            ],
            [
              -89.049763,
              36.926289
            ],
            [
              -89.02645,
              36.923771
            ],
            [
              -89.013869,
              36.930413
            ],
            [
              -88.988811,
              36.919491
            ],
            [
              -88.962716,
              36.930885
            ],
            [
              -88.952552,
              36.92845
            ],
            [
              -88.915802,
              36.942461
            ],
            [
              -88.905058,
              36.937442
            ],
            [
              -88.888211,
              36.945253
            ],
            [
              -88.852221,
              36.952224
            ],
            [
              -88.845695,
              36.947415
            ],
            [
              -88.815952,
              36.9541
            ],
            [
              -88.821705,
              36.967611
            ],
            [
              -88.869151,
              37.076829
            ],
            [
              -88.932225,
              37.222288
            ],
            [
              -88.9330768781631,
              37.22774949504169
            ],
            [
              -88.942111,
              37.228811
            ],
            [
              -88.966831,
              37.229891
            ],
            [
              -88.98326,
              37.228685
            ],
            [
              -89.000968,
              37.224401
            ],
            [
              -89.008532,
              37.220583
            ],
            [
              -89.00592,
              37.221198
            ],
            [
              -89.014003,
              37.21609
            ],
            [
              -89.029981,
              37.211144
            ],
            [
              -89.037568,
              37.203932
            ],
            [
              -89.041263,
              37.202881
            ],
            [
              -89.058036,
              37.188767
            ],
            [
              -89.076221,
              37.175125
            ],
            [
              -89.086526,
              37.165602
            ],
            [
              -89.092934,
              37.156439
            ],
            [
              -89.095753,
              37.150391
            ],
            [
              -89.096669,
              37.1462
            ],
            [
              -89.099047,
              37.140967
            ],
            [
              -89.111189,
              37.119052
            ],
            [
              -89.115579,
              37.115781
            ],
            [
              -89.120465,
              37.113487
            ],
            [
              -89.12202,
              37.111342
            ],
            [
              -89.125072,
              37.108813
            ],
            [
              -89.134931,
              37.103278
            ],
            [
              -89.135847,
              37.102197
            ],
            [
              -89.138231,
              37.096906
            ],
            [
              -89.14132,
              37.093865
            ],
            [
              -89.146596,
              37.090714
            ],
            [
              -89.149797,
              37.089828
            ],
            [
              -89.151294,
              37.090487
            ],
            [
              -89.154504,
              37.088907
            ],
            [
              -89.168087,
              37.074218
            ],
            [
              -89.171880551089,
              37.0681839790285
            ],
            [
              -89.175725,
              37.062069
            ],
            [
              -89.179384,
              37.053012
            ],
            [
              -89.181369,
              37.046305
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "001",
      "COUNTYNS": "00516847",
      "AFFGEOID": "0500000US21001",
      "GEOID": "21001",
      "NAME": "Adair",
      "LSAD": "06",
      "ALAND": 1049678094,
      "AWATER": 18430783,
      "County_state": "Adair,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.526881,
              37.10945
            ],
            [
              -85.522736,
              37.067339
            ],
            [
              -85.503629,
              37.00768
            ],
            [
              -85.441755,
              36.954076
            ],
            [
              -85.45183,
              36.938138
            ],
            [
              -85.232026,
              36.925069
            ],
            [
              -85.205831,
              36.996862
            ],
            [
              -85.166186,
              37.033265
            ],
            [
              -85.089643,
              37.125545
            ],
            [
              -85.043316,
              37.186028
            ],
            [
              -85.053565,
              37.196746
            ],
            [
              -85.056898,
              37.257041
            ],
            [
              -85.112093,
              37.28209
            ],
            [
              -85.165174,
              37.310396
            ],
            [
              -85.188375,
              37.306955
            ],
            [
              -85.184026,
              37.295864
            ],
            [
              -85.192542,
              37.272139
            ],
            [
              -85.223594,
              37.262494
            ],
            [
              -85.248527,
              37.264183
            ],
            [
              -85.272446,
              37.260032
            ],
            [
              -85.294005,
              37.245749
            ],
            [
              -85.312291,
              37.246405
            ],
            [
              -85.307827,
              37.227337
            ],
            [
              -85.317235,
              37.223913
            ],
            [
              -85.352771,
              37.192432
            ],
            [
              -85.380258,
              37.168314
            ],
            [
              -85.43384,
              37.144807
            ],
            [
              -85.526881,
              37.10945
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "089",
      "COUNTYNS": "00516891",
      "AFFGEOID": "0500000US21089",
      "GEOID": "21089",
      "NAME": "Greenup",
      "LSAD": "06",
      "ALAND": 892135587,
      "AWATER": 25884322,
      "County_state": "Greenup,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.147875,
              38.522006
            ],
            [
              -83.166419,
              38.503965
            ],
            [
              -83.138267,
              38.497364
            ],
            [
              -83.12123,
              38.485662
            ],
            [
              -83.096944,
              38.483193
            ],
            [
              -83.066617,
              38.466134
            ],
            [
              -83.051751,
              38.465404
            ],
            [
              -82.970648,
              38.393666
            ],
            [
              -82.93862,
              38.401652
            ],
            [
              -82.912712,
              38.398949
            ],
            [
              -82.817454,
              38.373935
            ],
            [
              -82.810637,
              38.393024
            ],
            [
              -82.777227,
              38.414939
            ],
            [
              -82.769047,
              38.433715
            ],
            [
              -82.689433,
              38.489028
            ],
            [
              -82.6654809868313,
              38.5057370829218
            ],
            [
              -82.665548,
              38.505808
            ],
            [
              -82.675724,
              38.515504
            ],
            [
              -82.689965,
              38.53592
            ],
            [
              -82.696621,
              38.542112
            ],
            [
              -82.700045,
              38.544336
            ],
            [
              -82.714142,
              38.550544
            ],
            [
              -82.719662,
              38.553664
            ],
            [
              -82.724846,
              38.5576
            ],
            [
              -82.730958,
              38.559264
            ],
            [
              -82.739582,
              38.558991
            ],
            [
              -82.763695,
              38.560399
            ],
            [
              -82.779472,
              38.559023
            ],
            [
              -82.789776,
              38.559951
            ],
            [
              -82.800112,
              38.563183
            ],
            [
              -82.81601184488581,
              38.5707328290844
            ],
            [
              -82.820161,
              38.572703
            ],
            [
              -82.839538,
              38.586159
            ],
            [
              -82.844306,
              38.590862
            ],
            [
              -82.847186,
              38.595166
            ],
            [
              -82.851314,
              38.604334
            ],
            [
              -82.854291,
              38.613454
            ],
            [
              -82.855795,
              38.620814
            ],
            [
              -82.856791,
              38.632878
            ],
            [
              -82.856291,
              38.646078
            ],
            [
              -82.859391,
              38.660378
            ],
            [
              -82.863291,
              38.669277
            ],
            [
              -82.869592,
              38.678177
            ],
            [
              -82.874892,
              38.682827
            ],
            [
              -82.877592,
              38.690177
            ],
            [
              -82.876892,
              38.697377
            ],
            [
              -82.875292,
              38.704977
            ],
            [
              -82.871192,
              38.718377
            ],
            [
              -82.870392,
              38.722077
            ],
            [
              -82.869892,
              38.728177
            ],
            [
              -82.871292,
              38.739376
            ],
            [
              -82.872592,
              38.742576
            ],
            [
              -82.87434064295078,
              38.745410007541004
            ],
            [
              -82.875492,
              38.747276
            ],
            [
              -82.879492,
              38.751476
            ],
            [
              -82.889193,
              38.756076
            ],
            [
              -82.894193,
              38.756576
            ],
            [
              -82.923694,
              38.750076
            ],
            [
              -82.943147,
              38.74328
            ],
            [
              -82.958895,
              38.734976
            ],
            [
              -82.968695,
              38.728776
            ],
            [
              -82.979395,
              38.725976
            ],
            [
              -82.986095,
              38.726276
            ],
            [
              -82.993996,
              38.728576
            ],
            [
              -82.999296,
              38.729376
            ],
            [
              -83.011816,
              38.730057
            ],
            [
              -83.021752,
              38.72879
            ],
            [
              -83.027917,
              38.727143
            ],
            [
              -83.030702,
              38.72572
            ],
            [
              -83.0308908096218,
              38.7255636114076
            ],
            [
              -83.033014,
              38.723805
            ],
            [
              -83.035964,
              38.720152
            ],
            [
              -83.038442,
              38.716377
            ],
            [
              -83.042338,
              38.708319
            ],
            [
              -83.0447314019964,
              38.705542778178504
            ],
            [
              -83.024075,
              38.683306
            ],
            [
              -83.038327,
              38.665294
            ],
            [
              -83.050259,
              38.663758
            ],
            [
              -83.054558,
              38.639588
            ],
            [
              -83.073774,
              38.632412
            ],
            [
              -83.069573,
              38.621845
            ],
            [
              -83.08079,
              38.610465
            ],
            [
              -83.071005,
              38.5952
            ],
            [
              -83.090328,
              38.579365
            ],
            [
              -83.099754,
              38.558714
            ],
            [
              -83.122045,
              38.535532
            ],
            [
              -83.146652,
              38.530239
            ],
            [
              -83.147875,
              38.522006
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "131",
      "COUNTYNS": "00516912",
      "AFFGEOID": "0500000US21131",
      "GEOID": "21131",
      "NAME": "Leslie",
      "LSAD": "06",
      "ALAND": 1038165209,
      "AWATER": 9230600,
      "County_state": "Leslie,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.553101,
              37.079281
            ],
            [
              -83.539184,
              37.070107
            ],
            [
              -83.539049,
              37.050575
            ],
            [
              -83.526481,
              37.021472
            ],
            [
              -83.510863,
              37.00357
            ],
            [
              -83.523037,
              36.998487
            ],
            [
              -83.509083,
              36.938509
            ],
            [
              -83.509482,
              36.923375
            ],
            [
              -83.489423,
              36.911302
            ],
            [
              -83.499905,
              36.895407
            ],
            [
              -83.489813,
              36.895413
            ],
            [
              -83.455777,
              36.883605
            ],
            [
              -83.408614,
              36.890218
            ],
            [
              -83.395527,
              36.903604
            ],
            [
              -83.399656,
              36.920955
            ],
            [
              -83.32428,
              36.940622
            ],
            [
              -83.26002,
              36.968951
            ],
            [
              -83.230339,
              37.002739
            ],
            [
              -83.185782,
              37.00608
            ],
            [
              -83.182104,
              37.021115
            ],
            [
              -83.186192,
              37.035265
            ],
            [
              -83.178303,
              37.038446
            ],
            [
              -83.178075,
              37.062527
            ],
            [
              -83.164412,
              37.07356
            ],
            [
              -83.176669,
              37.081017
            ],
            [
              -83.167233,
              37.102372
            ],
            [
              -83.194034,
              37.109099
            ],
            [
              -83.20557,
              37.128927
            ],
            [
              -83.22734,
              37.136848
            ],
            [
              -83.218196,
              37.144109
            ],
            [
              -83.22689,
              37.154549
            ],
            [
              -83.201921,
              37.16673
            ],
            [
              -83.202231,
              37.174803
            ],
            [
              -83.232648,
              37.184548
            ],
            [
              -83.255031,
              37.20423
            ],
            [
              -83.263181,
              37.200234
            ],
            [
              -83.281887,
              37.207628
            ],
            [
              -83.287001,
              37.217273
            ],
            [
              -83.298641,
              37.214494
            ],
            [
              -83.311091,
              37.229843
            ],
            [
              -83.32462,
              37.225561
            ],
            [
              -83.344161,
              37.260624
            ],
            [
              -83.335985,
              37.272514
            ],
            [
              -83.353494,
              37.284175
            ],
            [
              -83.351774,
              37.295682
            ],
            [
              -83.395782,
              37.30967
            ],
            [
              -83.39873,
              37.320564
            ],
            [
              -83.431741,
              37.300359
            ],
            [
              -83.431933,
              37.290265
            ],
            [
              -83.454254,
              37.288168
            ],
            [
              -83.460992,
              37.293848
            ],
            [
              -83.478971,
              37.286035
            ],
            [
              -83.486828,
              37.271977
            ],
            [
              -83.483323,
              37.251788
            ],
            [
              -83.507886,
              37.235877
            ],
            [
              -83.528922,
              37.231259
            ],
            [
              -83.505113,
              37.204213
            ],
            [
              -83.551303,
              37.16751
            ],
            [
              -83.551237,
              37.119056
            ],
            [
              -83.535284,
              37.103096
            ],
            [
              -83.553101,
              37.079281
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "043",
      "COUNTYNS": "00516868",
      "AFFGEOID": "0500000US21043",
      "GEOID": "21043",
      "NAME": "Carter",
      "LSAD": "06",
      "ALAND": 1060602204,
      "AWATER": 6788531,
      "County_state": "Carter,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.341347,
              38.319521
            ],
            [
              -83.334839,
              38.287003
            ],
            [
              -83.307888,
              38.252254
            ],
            [
              -83.312722,
              38.24402
            ],
            [
              -83.30114,
              38.209703
            ],
            [
              -83.287741,
              38.215522
            ],
            [
              -83.286553,
              38.206247
            ],
            [
              -83.26621,
              38.191499
            ],
            [
              -83.244306,
              38.19238
            ],
            [
              -83.232866,
              38.19341
            ],
            [
              -83.220913,
              38.209848
            ],
            [
              -83.21815,
              38.237053
            ],
            [
              -83.185851,
              38.252558
            ],
            [
              -83.177781,
              38.266963
            ],
            [
              -83.159191,
              38.264537
            ],
            [
              -83.135008,
              38.242037
            ],
            [
              -83.101441,
              38.226036
            ],
            [
              -83.083424,
              38.221954
            ],
            [
              -83.075843,
              38.206559
            ],
            [
              -83.053081,
              38.189545
            ],
            [
              -83.051072,
              38.179065
            ],
            [
              -83.040272,
              38.199445
            ],
            [
              -83.027058,
              38.195503
            ],
            [
              -83.011467,
              38.205751
            ],
            [
              -83.007089,
              38.192253
            ],
            [
              -82.988955,
              38.188959
            ],
            [
              -82.98943,
              38.179346
            ],
            [
              -82.975129,
              38.17206
            ],
            [
              -82.956984,
              38.17456
            ],
            [
              -82.945015,
              38.168334
            ],
            [
              -82.924805,
              38.175114
            ],
            [
              -82.883913,
              38.195978
            ],
            [
              -82.853983,
              38.190156
            ],
            [
              -82.8232,
              38.208264
            ],
            [
              -82.82111,
              38.232733
            ],
            [
              -82.794206,
              38.243772
            ],
            [
              -82.781462,
              38.345438
            ],
            [
              -82.771737,
              38.362588
            ],
            [
              -82.817454,
              38.373935
            ],
            [
              -82.912712,
              38.398949
            ],
            [
              -82.93862,
              38.401652
            ],
            [
              -82.970648,
              38.393666
            ],
            [
              -83.051751,
              38.465404
            ],
            [
              -83.066617,
              38.466134
            ],
            [
              -83.096944,
              38.483193
            ],
            [
              -83.12123,
              38.485662
            ],
            [
              -83.138267,
              38.497364
            ],
            [
              -83.166419,
              38.503965
            ],
            [
              -83.188505,
              38.496071
            ],
            [
              -83.186411,
              38.469428
            ],
            [
              -83.200485,
              38.453818
            ],
            [
              -83.213097,
              38.454794
            ],
            [
              -83.207514,
              38.441177
            ],
            [
              -83.23721,
              38.426472
            ],
            [
              -83.238649,
              38.397472
            ],
            [
              -83.232433,
              38.37418
            ],
            [
              -83.239615,
              38.355851
            ],
            [
              -83.23068,
              38.339286
            ],
            [
              -83.247344,
              38.330769
            ],
            [
              -83.284788,
              38.323742
            ],
            [
              -83.312589,
              38.33366
            ],
            [
              -83.341347,
              38.319521
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "117",
      "COUNTYNS": "00516905",
      "AFFGEOID": "0500000US21117",
      "GEOID": "21117",
      "NAME": "Kenton",
      "LSAD": "06",
      "ALAND": 414950497,
      "AWATER": 10577248,
      "County_state": "Kenton,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.6234682396614,
              39.0743557886818
            ],
            [
              -84.622019,
              39.062277
            ],
            [
              -84.61667,
              39.018495
            ],
            [
              -84.611775,
              38.978301
            ],
            [
              -84.61098,
              38.971893
            ],
            [
              -84.609169,
              38.957167
            ],
            [
              -84.597932,
              38.879431
            ],
            [
              -84.60854,
              38.833319
            ],
            [
              -84.615664,
              38.802277
            ],
            [
              -84.605009,
              38.792833
            ],
            [
              -84.532288,
              38.791937
            ],
            [
              -84.423905,
              38.805227
            ],
            [
              -84.418107,
              38.806596
            ],
            [
              -84.430312,
              38.828551
            ],
            [
              -84.451507,
              38.820344
            ],
            [
              -84.463842,
              38.850952
            ],
            [
              -84.449258,
              38.862073
            ],
            [
              -84.438912,
              38.90488
            ],
            [
              -84.441619,
              38.907709
            ],
            [
              -84.463112,
              38.945226
            ],
            [
              -84.461755,
              38.949448
            ],
            [
              -84.449412,
              38.949869
            ],
            [
              -84.426791,
              38.979302
            ],
            [
              -84.440368,
              38.984421
            ],
            [
              -84.452837,
              38.967698
            ],
            [
              -84.465874,
              38.977716
            ],
            [
              -84.4508,
              38.993609
            ],
            [
              -84.46976,
              39.004266
            ],
            [
              -84.489205,
              39.021219
            ],
            [
              -84.490038,
              39.030087
            ],
            [
              -84.48522,
              39.041016
            ],
            [
              -84.497739,
              39.058525
            ],
            [
              -84.493817,
              39.072631
            ],
            [
              -84.50608223252529,
              39.0950807457156
            ],
            [
              -84.509743,
              39.09366
            ],
            [
              -84.519543,
              39.09206
            ],
            [
              -84.524644,
              39.09216
            ],
            [
              -84.541344,
              39.09916
            ],
            [
              -84.543544,
              39.09966
            ],
            [
              -84.54355120700639,
              39.09965970382169
            ],
            [
              -84.550844,
              39.09936
            ],
            [
              -84.554444,
              39.09766
            ],
            [
              -84.557544,
              39.09486
            ],
            [
              -84.563944,
              39.08736
            ],
            [
              -84.5718392746594,
              39.0822569566226
            ],
            [
              -84.572144,
              39.08206
            ],
            [
              -84.592408,
              39.078088
            ],
            [
              -84.601682,
              39.074115
            ],
            [
              -84.607928,
              39.073238
            ],
            [
              -84.620112,
              39.073457
            ],
            [
              -84.6234682396614,
              39.0743557886818
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "049",
      "COUNTYNS": "00516871",
      "AFFGEOID": "0500000US21049",
      "GEOID": "21049",
      "NAME": "Clark",
      "LSAD": "06",
      "ALAND": 653878979,
      "AWATER": 6972749,
      "County_state": "Clark,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.338975,
              37.907717
            ],
            [
              -84.348874,
              37.898543
            ],
            [
              -84.337393,
              37.892007
            ],
            [
              -84.270331,
              37.917281
            ],
            [
              -84.265348,
              37.884011
            ],
            [
              -84.263154,
              37.876873
            ],
            [
              -84.237685,
              37.887329
            ],
            [
              -84.221911,
              37.871991
            ],
            [
              -84.186846,
              37.879232
            ],
            [
              -84.166694,
              37.861822
            ],
            [
              -84.13113,
              37.867935
            ],
            [
              -84.107378,
              37.850558
            ],
            [
              -84.092714,
              37.861548
            ],
            [
              -84.080337,
              37.853066
            ],
            [
              -84.075107,
              37.823272
            ],
            [
              -84.05744,
              37.835428
            ],
            [
              -84.059258,
              37.847748
            ],
            [
              -84.039557,
              37.833721
            ],
            [
              -84.001967,
              37.837592
            ],
            [
              -84.02737,
              37.869454
            ],
            [
              -84.00645,
              37.860051
            ],
            [
              -83.990118,
              37.899804
            ],
            [
              -83.991769,
              37.917776
            ],
            [
              -83.96631,
              37.930768
            ],
            [
              -83.981513,
              37.95845
            ],
            [
              -84.080527,
              38.115118
            ],
            [
              -84.286461,
              38.067028
            ],
            [
              -84.284686,
              38.040095
            ],
            [
              -84.292677,
              38.033346
            ],
            [
              -84.290492,
              38.030384
            ],
            [
              -84.282629,
              38.019103
            ],
            [
              -84.322502,
              38.00097
            ],
            [
              -84.323043,
              37.964966
            ],
            [
              -84.312807,
              37.967274
            ],
            [
              -84.339631,
              37.934485
            ],
            [
              -84.331,
              37.920913
            ],
            [
              -84.347373,
              37.915562
            ],
            [
              -84.338975,
              37.907717
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "035",
      "COUNTYNS": "00516864",
      "AFFGEOID": "0500000US21035",
      "GEOID": "21035",
      "NAME": "Calloway",
      "LSAD": "06",
      "ALAND": 997206000,
      "AWATER": 66492945,
      "County_state": "Calloway,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.485697,
              36.750328
            ],
            [
              -88.4890752146435,
              36.5010676761135
            ],
            [
              -88.472564,
              36.501028
            ],
            [
              -88.452543,
              36.500872
            ],
            [
              -88.450161,
              36.501101
            ],
            [
              -88.41636,
              36.500756
            ],
            [
              -88.330799,
              36.500531
            ],
            [
              -88.325895,
              36.500483
            ],
            [
              -88.320794,
              36.500432
            ],
            [
              -88.127378,
              36.49854
            ],
            [
              -88.0532916180151,
              36.4971306477427
            ],
            [
              -88.053205,
              36.497129
            ],
            [
              -88.050466,
              36.500053
            ],
            [
              -88.045304,
              36.504081
            ],
            [
              -88.039481,
              36.510408
            ],
            [
              -88.037822,
              36.51385
            ],
            [
              -88.037329,
              36.51783
            ],
            [
              -88.03783,
              36.521015
            ],
            [
              -88.03727,
              36.523783
            ],
            [
              -88.035854,
              36.525912
            ],
            [
              -88.034132,
              36.53112
            ],
            [
              -88.032489,
              36.540662
            ],
            [
              -88.033802,
              36.551733
            ],
            [
              -88.035625,
              36.561736
            ],
            [
              -88.039625,
              36.572783
            ],
            [
              -88.041196,
              36.584123
            ],
            [
              -88.045127,
              36.602939
            ],
            [
              -88.055738,
              36.630475
            ],
            [
              -88.055604,
              36.63571
            ],
            [
              -88.057042,
              36.64054
            ],
            [
              -88.05858,
              36.643315
            ],
            [
              -88.064401,
              36.650854
            ],
            [
              -88.068208,
              36.659747
            ],
            [
              -88.070532,
              36.678118
            ],
            [
              -88.080126,
              36.706739
            ],
            [
              -88.110831,
              36.74715
            ],
            [
              -88.136108,
              36.74866
            ],
            [
              -88.485697,
              36.750328
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "161",
      "COUNTYNS": "00516924",
      "AFFGEOID": "0500000US21161",
      "GEOID": "21161",
      "NAME": "Mason",
      "LSAD": "06",
      "ALAND": 621927922,
      "AWATER": 16277777,
      "County_state": "Mason,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.991605,
              38.593701
            ],
            [
              -83.970778,
              38.587208
            ],
            [
              -83.960936,
              38.532713
            ],
            [
              -83.930348,
              38.492278
            ],
            [
              -83.85928,
              38.456348
            ],
            [
              -83.721744,
              38.503982
            ],
            [
              -83.641852,
              38.525381
            ],
            [
              -83.624596,
              38.611425
            ],
            [
              -83.645914,
              38.637007
            ],
            [
              -83.649737,
              38.632753
            ],
            [
              -83.652916,
              38.630604
            ],
            [
              -83.655425,
              38.629735
            ],
            [
              -83.659304,
              38.628592
            ],
            [
              -83.663911,
              38.62793
            ],
            [
              -83.668111,
              38.628068
            ],
            [
              -83.679484,
              38.630036
            ],
            [
              -83.68552,
              38.63189
            ],
            [
              -83.704006,
              38.639724
            ],
            [
              -83.7053136208764,
              38.6397614234931
            ],
            [
              -83.708164,
              38.639843
            ],
            [
              -83.713405,
              38.641591
            ],
            [
              -83.716933,
              38.643657
            ],
            [
              -83.717915,
              38.645247
            ],
            [
              -83.720779,
              38.646704
            ],
            [
              -83.738207,
              38.647932
            ],
            [
              -83.74992,
              38.649613
            ],
            [
              -83.762445,
              38.652103
            ],
            [
              -83.76509,
              38.652881
            ],
            [
              -83.769347,
              38.65522
            ],
            [
              -83.77216,
              38.65815
            ],
            [
              -83.775761,
              38.666748
            ],
            [
              -83.777141,
              38.671205
            ],
            [
              -83.779961,
              38.684907
            ],
            [
              -83.78362,
              38.695641
            ],
            [
              -83.787113,
              38.699489
            ],
            [
              -83.790676,
              38.701676
            ],
            [
              -83.798549,
              38.704668
            ],
            [
              -83.806317,
              38.706613
            ],
            [
              -83.81388,
              38.707446
            ],
            [
              -83.821854,
              38.709575
            ],
            [
              -83.834015,
              38.716008
            ],
            [
              -83.836696,
              38.717857
            ],
            [
              -83.840595,
              38.721912
            ],
            [
              -83.841689,
              38.724264
            ],
            [
              -83.842506,
              38.727019
            ],
            [
              -83.84274,
              38.730365
            ],
            [
              -83.844676,
              38.737439
            ],
            [
              -83.846207,
              38.74229
            ],
            [
              -83.848734,
              38.747178
            ],
            [
              -83.852085,
              38.751433
            ],
            [
              -83.859028,
              38.756793
            ],
            [
              -83.86653,
              38.7602
            ],
            [
              -83.873168,
              38.762418
            ],
            [
              -83.887107,
              38.7656
            ],
            [
              -83.90391694448489,
              38.7681517942292
            ],
            [
              -83.991605,
              38.593701
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "063",
      "COUNTYNS": "00516878",
      "AFFGEOID": "0500000US21063",
      "GEOID": "21063",
      "NAME": "Elliott",
      "LSAD": "06",
      "ALAND": 606875936,
      "AWATER": 2608208,
      "County_state": "Elliott,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.270846,
              38.069202
            ],
            [
              -83.267544,
              38.05895
            ],
            [
              -83.250391,
              38.050318
            ],
            [
              -83.232842,
              38.051856
            ],
            [
              -83.222891,
              38.043956
            ],
            [
              -83.22155,
              38.027392
            ],
            [
              -83.194445,
              38.010649
            ],
            [
              -83.174215,
              38.017036
            ],
            [
              -83.151976,
              38.010445
            ],
            [
              -83.144613,
              37.996593
            ],
            [
              -83.136357,
              38.004285
            ],
            [
              -83.109823,
              38.0039
            ],
            [
              -83.101689,
              38.009751
            ],
            [
              -83.065341,
              38.007949
            ],
            [
              -83.047871,
              37.996728
            ],
            [
              -83.029133,
              38.009491
            ],
            [
              -83.022115,
              38.007047
            ],
            [
              -83.001995,
              38.034737
            ],
            [
              -82.981228,
              38.049999
            ],
            [
              -82.95632,
              38.049521
            ],
            [
              -82.941991,
              38.064164
            ],
            [
              -82.942005,
              38.077585
            ],
            [
              -82.917064,
              38.099094
            ],
            [
              -82.907528,
              38.095731
            ],
            [
              -82.888644,
              38.112806
            ],
            [
              -82.90675,
              38.133025
            ],
            [
              -82.907833,
              38.156716
            ],
            [
              -82.917786,
              38.174834
            ],
            [
              -82.924805,
              38.175114
            ],
            [
              -82.945015,
              38.168334
            ],
            [
              -82.956984,
              38.17456
            ],
            [
              -82.975129,
              38.17206
            ],
            [
              -82.98943,
              38.179346
            ],
            [
              -82.988955,
              38.188959
            ],
            [
              -83.007089,
              38.192253
            ],
            [
              -83.011467,
              38.205751
            ],
            [
              -83.027058,
              38.195503
            ],
            [
              -83.040272,
              38.199445
            ],
            [
              -83.051072,
              38.179065
            ],
            [
              -83.053081,
              38.189545
            ],
            [
              -83.075843,
              38.206559
            ],
            [
              -83.083424,
              38.221954
            ],
            [
              -83.101441,
              38.226036
            ],
            [
              -83.135008,
              38.242037
            ],
            [
              -83.159191,
              38.264537
            ],
            [
              -83.177781,
              38.266963
            ],
            [
              -83.185851,
              38.252558
            ],
            [
              -83.21815,
              38.237053
            ],
            [
              -83.220913,
              38.209848
            ],
            [
              -83.232866,
              38.19341
            ],
            [
              -83.244306,
              38.19238
            ],
            [
              -83.264229,
              38.188098
            ],
            [
              -83.243324,
              38.176098
            ],
            [
              -83.205257,
              38.175513
            ],
            [
              -83.199219,
              38.168998
            ],
            [
              -83.211806,
              38.164473
            ],
            [
              -83.222442,
              38.132072
            ],
            [
              -83.247264,
              38.1304
            ],
            [
              -83.263289,
              38.115386
            ],
            [
              -83.261269,
              38.102292
            ],
            [
              -83.270846,
              38.069202
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "079",
      "COUNTYNS": "00516886",
      "AFFGEOID": "0500000US21079",
      "GEOID": "21079",
      "NAME": "Garrard",
      "LSAD": "06",
      "ALAND": 595899383,
      "AWATER": 10017670,
      "County_state": "Garrard,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.737384,
              37.718917
            ],
            [
              -84.744888,
              37.713075
            ],
            [
              -84.733815,
              37.710752
            ],
            [
              -84.743698,
              37.69839
            ],
            [
              -84.722387,
              37.692803
            ],
            [
              -84.704273,
              37.697633
            ],
            [
              -84.697056,
              37.68163
            ],
            [
              -84.685794,
              37.687605
            ],
            [
              -84.681664,
              37.67358
            ],
            [
              -84.698217,
              37.668506
            ],
            [
              -84.678867,
              37.652846
            ],
            [
              -84.693741,
              37.640927
            ],
            [
              -84.679045,
              37.636811
            ],
            [
              -84.679414,
              37.645836
            ],
            [
              -84.659285,
              37.654726
            ],
            [
              -84.652294,
              37.650892
            ],
            [
              -84.658296,
              37.634501
            ],
            [
              -84.644212,
              37.631883
            ],
            [
              -84.652567,
              37.61821
            ],
            [
              -84.648559,
              37.609897
            ],
            [
              -84.634243,
              37.601713
            ],
            [
              -84.622306,
              37.603584
            ],
            [
              -84.618471,
              37.593051
            ],
            [
              -84.473203,
              37.49444
            ],
            [
              -84.452394,
              37.49277
            ],
            [
              -84.446719,
              37.485893
            ],
            [
              -84.37444,
              37.472663
            ],
            [
              -84.355538,
              37.49794
            ],
            [
              -84.360894,
              37.515732
            ],
            [
              -84.347805,
              37.539064
            ],
            [
              -84.370718,
              37.57204
            ],
            [
              -84.372342,
              37.592412
            ],
            [
              -84.396651,
              37.599572
            ],
            [
              -84.401642,
              37.617785
            ],
            [
              -84.421758,
              37.618152
            ],
            [
              -84.412419,
              37.630439
            ],
            [
              -84.429772,
              37.643009
            ],
            [
              -84.446286,
              37.671293
            ],
            [
              -84.464183,
              37.687107
            ],
            [
              -84.453702,
              37.691206
            ],
            [
              -84.4745,
              37.708072
            ],
            [
              -84.485404,
              37.711126
            ],
            [
              -84.487976,
              37.728014
            ],
            [
              -84.503228,
              37.734184
            ],
            [
              -84.494485,
              37.747461
            ],
            [
              -84.514885,
              37.757373
            ],
            [
              -84.511458,
              37.765068
            ],
            [
              -84.525643,
              37.769497
            ],
            [
              -84.538199,
              37.767857
            ],
            [
              -84.538895,
              37.755518
            ],
            [
              -84.555375,
              37.749225
            ],
            [
              -84.571158,
              37.72855
            ],
            [
              -84.587227,
              37.73533
            ],
            [
              -84.585209,
              37.750613
            ],
            [
              -84.596423,
              37.75339
            ],
            [
              -84.606722,
              37.743078
            ],
            [
              -84.61621,
              37.74832
            ],
            [
              -84.604322,
              37.756673
            ],
            [
              -84.611903,
              37.766713
            ],
            [
              -84.638084,
              37.773544
            ],
            [
              -84.644749,
              37.788337
            ],
            [
              -84.626077,
              37.79703
            ],
            [
              -84.608486,
              37.792799
            ],
            [
              -84.616571,
              37.803971
            ],
            [
              -84.656608,
              37.809908
            ],
            [
              -84.656903,
              37.783921
            ],
            [
              -84.677209,
              37.790707
            ],
            [
              -84.675889,
              37.826242
            ],
            [
              -84.695715,
              37.827869
            ],
            [
              -84.716925,
              37.815389
            ],
            [
              -84.716433,
              37.799564
            ],
            [
              -84.702923,
              37.786498
            ],
            [
              -84.71577,
              37.769989
            ],
            [
              -84.694249,
              37.763291
            ],
            [
              -84.703553,
              37.746195
            ],
            [
              -84.691482,
              37.744848
            ],
            [
              -84.706581,
              37.731828
            ],
            [
              -84.689343,
              37.727274
            ],
            [
              -84.717374,
              37.724419
            ],
            [
              -84.727898,
              37.728808
            ],
            [
              -84.726739,
              37.713803
            ],
            [
              -84.737384,
              37.718917
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "133",
      "COUNTYNS": "00516913",
      "AFFGEOID": "0500000US21133",
      "GEOID": "21133",
      "NAME": "Letcher",
      "LSAD": "06",
      "ALAND": 875192042,
      "AWATER": 2898572,
      "County_state": "Letcher,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.149172,
              36.981659
            ],
            [
              -83.153401,
              36.956591
            ],
            [
              -83.038161,
              36.984271
            ],
            [
              -83.022587,
              36.977403
            ],
            [
              -83.004528,
              36.993374
            ],
            [
              -82.965561,
              37.001894
            ],
            [
              -82.939466,
              37.01552
            ],
            [
              -82.8691826690104,
              36.9741830452543
            ],
            [
              -82.868455,
              36.976481
            ],
            [
              -82.867535,
              36.977518
            ],
            [
              -82.866689,
              36.978052
            ],
            [
              -82.866019,
              36.978272
            ],
            [
              -82.864909,
              36.97901
            ],
            [
              -82.862926,
              36.979975
            ],
            [
              -82.860561,
              36.978265
            ],
            [
              -82.857936,
              36.978276
            ],
            [
              -82.856768,
              36.979095
            ],
            [
              -82.856417,
              36.982216
            ],
            [
              -82.853729,
              36.985178
            ],
            [
              -82.852614,
              36.984963
            ],
            [
              -82.851397,
              36.984497
            ],
            [
              -82.849429,
              36.983479
            ],
            [
              -82.845002,
              36.983812
            ],
            [
              -82.841252,
              36.986858
            ],
            [
              -82.840051,
              36.987113
            ],
            [
              -82.838549,
              36.987027
            ],
            [
              -82.836008,
              36.988837
            ],
            [
              -82.833843,
              36.991973
            ],
            [
              -82.830802,
              36.993445
            ],
            [
              -82.829125,
              36.997541
            ],
            [
              -82.830588,
              37.000945
            ],
            [
              -82.829961,
              37.003555
            ],
            [
              -82.828592,
              37.005707
            ],
            [
              -82.825224,
              37.006279
            ],
            [
              -82.822684,
              37.004128
            ],
            [
              -82.821798,
              37.00438
            ],
            [
              -82.819715,
              37.006212
            ],
            [
              -82.818006,
              37.006161
            ],
            [
              -82.815748,
              37.007196
            ],
            [
              -82.813579,
              37.00532
            ],
            [
              -82.800531,
              37.007944
            ],
            [
              -82.797487,
              37.009654
            ],
            [
              -82.796187,
              37.008502
            ],
            [
              -82.795695,
              37.006702
            ],
            [
              -82.794824,
              37.005899
            ],
            [
              -82.793441,
              37.005491
            ],
            [
              -82.79089,
              37.00676
            ],
            [
              -82.790462,
              37.007263
            ],
            [
              -82.789092,
              37.007995
            ],
            [
              -82.788897,
              37.00816
            ],
            [
              -82.782144,
              37.008242
            ],
            [
              -82.778386,
              37.012521
            ],
            [
              -82.777368,
              37.015279
            ],
            [
              -82.771795,
              37.015716
            ],
            [
              -82.767083,
              37.020935
            ],
            [
              -82.766408,
              37.023106
            ],
            [
              -82.759175,
              37.027333
            ],
            [
              -82.754051,
              37.026587
            ],
            [
              -82.751852,
              37.025624
            ],
            [
              -82.750715,
              37.024107
            ],
            [
              -82.747981,
              37.025214
            ],
            [
              -82.745562,
              37.029839
            ],
            [
              -82.743684,
              37.041397
            ],
            [
              -82.742454,
              37.04298
            ],
            [
              -82.733603,
              37.044525
            ],
            [
              -82.731722,
              37.043447
            ],
            [
              -82.726279,
              37.042098
            ],
            [
              -82.72509517249749,
              37.0425972499372
            ],
            [
              -82.724714,
              37.042758
            ],
            [
              -82.722472,
              37.045101
            ],
            [
              -82.723279,
              37.047616
            ],
            [
              -82.723128,
              37.052436
            ],
            [
              -82.722254,
              37.057948
            ],
            [
              -82.723904,
              37.062542
            ],
            [
              -82.726312,
              37.06687
            ],
            [
              -82.727022,
              37.073019
            ],
            [
              -82.723747,
              37.075525
            ],
            [
              -82.721676,
              37.07519
            ],
            [
              -82.718353,
              37.075706
            ],
            [
              -82.71674,
              37.07722
            ],
            [
              -82.717204,
              37.079544
            ],
            [
              -82.718183,
              37.080679
            ],
            [
              -82.720597,
              37.081833
            ],
            [
              -82.72304,
              37.084992
            ],
            [
              -82.724954,
              37.091905
            ],
            [
              -82.724358,
              37.09299
            ],
            [
              -82.722179,
              37.094309
            ],
            [
              -82.721617,
              37.101276
            ],
            [
              -82.721941,
              37.105689
            ],
            [
              -82.726294,
              37.111852
            ],
            [
              -82.726449,
              37.114985
            ],
            [
              -82.726201,
              37.115882
            ],
            [
              -82.722097,
              37.120168
            ],
            [
              -82.718334,
              37.122267
            ],
            [
              -82.716334,
              37.122093
            ],
            [
              -82.70787,
              37.125488
            ],
            [
              -82.695667,
              37.131514
            ],
            [
              -82.684601,
              37.135835
            ],
            [
              -82.679428,
              37.135575
            ],
            [
              -82.676765,
              37.134965
            ],
            [
              -82.67153,
              37.138444
            ],
            [
              -82.667717,
              37.141949
            ],
            [
              -82.662449,
              37.143865
            ],
            [
              -82.657468,
              37.145024
            ],
            [
              -82.65476,
              37.150601
            ],
            [
              -82.653268,
              37.151314
            ],
            [
              -82.651646,
              37.151908
            ],
            [
              -82.633493,
              37.154264
            ],
            [
              -82.624878,
              37.162932
            ],
            [
              -82.617423,
              37.168129
            ],
            [
              -82.60829,
              37.173047
            ],
            [
              -82.597447,
              37.177088
            ],
            [
              -82.592766,
              37.180391
            ],
            [
              -82.593232,
              37.18206
            ],
            [
              -82.592451,
              37.182847
            ],
            [
              -82.586718,
              37.185623
            ],
            [
              -82.578988,
              37.188498
            ],
            [
              -82.5653752160821,
              37.1960922172527
            ],
            [
              -82.589803,
              37.208977
            ],
            [
              -82.602065,
              37.229737
            ],
            [
              -82.628921,
              37.247804
            ],
            [
              -82.671515,
              37.227359
            ],
            [
              -82.684264,
              37.228796
            ],
            [
              -82.705125,
              37.24267
            ],
            [
              -82.699886,
              37.255427
            ],
            [
              -82.72407,
              37.256694
            ],
            [
              -82.732693,
              37.271502
            ],
            [
              -82.75741,
              37.26969
            ],
            [
              -82.801672,
              37.261232
            ],
            [
              -82.819373,
              37.268671
            ],
            [
              -82.832537,
              37.258777
            ],
            [
              -82.852325,
              37.255795
            ],
            [
              -82.874726,
              37.242143
            ],
            [
              -82.893379,
              37.216972
            ],
            [
              -82.920769,
              37.200027
            ],
            [
              -82.931835,
              37.202743
            ],
            [
              -82.944096,
              37.190413
            ],
            [
              -82.95831,
              37.190034
            ],
            [
              -82.979183,
              37.17242
            ],
            [
              -82.984493,
              37.187718
            ],
            [
              -83.000142,
              37.19571
            ],
            [
              -83.016741,
              37.177487
            ],
            [
              -83.038261,
              37.165468
            ],
            [
              -83.035611,
              37.15724
            ],
            [
              -83.058818,
              37.124079
            ],
            [
              -83.051146,
              37.092297
            ],
            [
              -83.040771,
              37.080059
            ],
            [
              -83.04279,
              37.062006
            ],
            [
              -83.057875,
              37.0615
            ],
            [
              -83.071912,
              37.042272
            ],
            [
              -83.052243,
              37.035114
            ],
            [
              -83.05534,
              37.017391
            ],
            [
              -83.110859,
              36.992264
            ],
            [
              -83.12209,
              37.000997
            ],
            [
              -83.126428,
              36.991441
            ],
            [
              -83.149172,
              36.981659
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "223",
      "COUNTYNS": "00516958",
      "AFFGEOID": "0500000US21223",
      "GEOID": "21223",
      "NAME": "Trimble",
      "LSAD": "06",
      "ALAND": 392766460,
      "AWATER": 11833388,
      "County_state": "Trimble,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.456978,
              38.689135
            ],
            [
              -85.456481,
              38.685069
            ],
            [
              -85.455486,
              38.68209
            ],
            [
              -85.444815,
              38.670083
            ],
            [
              -85.438742,
              38.659319
            ],
            [
              -85.437738,
              38.648898
            ],
            [
              -85.439458,
              38.632366
            ],
            [
              -85.439351,
              38.610388
            ],
            [
              -85.438594,
              38.605405
            ],
            [
              -85.437446,
              38.601724
            ],
            [
              -85.43617,
              38.598292
            ],
            [
              -85.42829037139839,
              38.5863257242075
            ],
            [
              -85.419883,
              38.573558
            ],
            [
              -85.415821,
              38.563558
            ],
            [
              -85.415272,
              38.555416
            ],
            [
              -85.4156,
              38.546341
            ],
            [
              -85.417322,
              38.540763
            ],
            [
              -85.423077,
              38.531581
            ],
            [
              -85.425787,
              38.52873
            ],
            [
              -85.43291605412969,
              38.52405813652349
            ],
            [
              -85.427853,
              38.519199
            ],
            [
              -85.378743,
              38.518822
            ],
            [
              -85.364396,
              38.507634
            ],
            [
              -85.323767,
              38.500824
            ],
            [
              -85.314006,
              38.492592
            ],
            [
              -85.279627,
              38.496268
            ],
            [
              -85.24994,
              38.52407
            ],
            [
              -85.16827,
              38.585448
            ],
            [
              -85.211375,
              38.580216
            ],
            [
              -85.213982,
              38.601346
            ],
            [
              -85.230257,
              38.617818
            ],
            [
              -85.228976,
              38.627035
            ],
            [
              -85.329683,
              38.725817
            ],
            [
              -85.33305185946669,
              38.7360828292115
            ],
            [
              -85.340953,
              38.733893
            ],
            [
              -85.351776,
              38.731638
            ],
            [
              -85.3633411161261,
              38.730523810320896
            ],
            [
              -85.363827,
              38.730477
            ],
            [
              -85.372284,
              38.730576
            ],
            [
              -85.400481,
              38.73598
            ],
            [
              -85.410925,
              38.73708
            ],
            [
              -85.416631,
              38.736272
            ],
            [
              -85.422021,
              38.734834
            ],
            [
              -85.434065,
              38.729455
            ],
            [
              -85.437766,
              38.726405
            ],
            [
              -85.442271,
              38.71985
            ],
            [
              -85.448862,
              38.713368
            ],
            [
              -85.452114,
              38.709348
            ],
            [
              -85.455967,
              38.695655
            ],
            [
              -85.456978,
              38.689135
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "105",
      "COUNTYNS": "00516899",
      "AFFGEOID": "0500000US21105",
      "GEOID": "21105",
      "NAME": "Hickman",
      "LSAD": "06",
      "ALAND": 627487971,
      "AWATER": 27882017,
      "County_state": "Hickman,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.201047,
              36.725772
            ],
            [
              -89.200732,
              36.720141
            ],
            [
              -89.19948,
              36.716045
            ],
            [
              -89.19604,
              36.712017
            ],
            [
              -89.184356,
              36.701238
            ],
            [
              -89.174836,
              36.69396
            ],
            [
              -89.169522,
              36.688878
            ],
            [
              -89.168016,
              36.685514
            ],
            [
              -89.167961,
              36.678189
            ],
            [
              -89.169467,
              36.674596
            ],
            [
              -89.171882,
              36.672526
            ],
            [
              -89.168723,
              36.671892
            ],
            [
              -89.15908,
              36.666352
            ],
            [
              -89.164601,
              36.660132
            ],
            [
              -89.17468187525279,
              36.6504726524698
            ],
            [
              -89.147098,
              36.649117
            ],
            [
              -89.026629,
              36.592124
            ],
            [
              -88.940873,
              36.591514
            ],
            [
              -88.928042,
              36.578769
            ],
            [
              -88.903877,
              36.576975
            ],
            [
              -88.834865800811,
              36.5029112012574
            ],
            [
              -88.834626,
              36.502914
            ],
            [
              -88.82730115556168,
              36.502852430516896
            ],
            [
              -88.827012,
              36.50285
            ],
            [
              -88.81676518603649,
              36.5028152435007
            ],
            [
              -88.816287,
              36.682894
            ],
            [
              -88.819595,
              36.755906
            ],
            [
              -88.817236,
              36.767754
            ],
            [
              -88.813229,
              36.773115
            ],
            [
              -89.002277,
              36.771562
            ],
            [
              -89.067265,
              36.772763
            ],
            [
              -89.061815,
              36.787018
            ],
            [
              -89.104158,
              36.797285
            ],
            [
              -89.1234809771075,
              36.7852576789117
            ],
            [
              -89.120437,
              36.782071
            ],
            [
              -89.116847,
              36.775607
            ],
            [
              -89.116067,
              36.772423
            ],
            [
              -89.116563,
              36.767557
            ],
            [
              -89.119198,
              36.759802
            ],
            [
              -89.12243,
              36.754844
            ],
            [
              -89.126134,
              36.751735
            ],
            [
              -89.130399,
              36.751702
            ],
            [
              -89.135518,
              36.751956
            ],
            [
              -89.142313,
              36.755369
            ],
            [
              -89.151756,
              36.756264
            ],
            [
              -89.15699,
              36.755968
            ],
            [
              -89.166888,
              36.759633
            ],
            [
              -89.169106,
              36.759473
            ],
            [
              -89.179545,
              36.756132
            ],
            [
              -89.184523,
              36.753638
            ],
            [
              -89.191553,
              36.747217
            ],
            [
              -89.197808,
              36.739412
            ],
            [
              -89.199798,
              36.734217
            ],
            [
              -89.201047,
              36.725772
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "067",
      "COUNTYNS": "00516880",
      "AFFGEOID": "0500000US21067",
      "GEOID": "21067",
      "NAME": "Fayette",
      "LSAD": "06",
      "ALAND": 734615998,
      "AWATER": 4922111,
      "County_state": "Fayette,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.657704,
              38.017755
            ],
            [
              -84.66034,
              38.003836
            ],
            [
              -84.604659,
              37.98958
            ],
            [
              -84.592312,
              37.986432
            ],
            [
              -84.570703,
              37.977587
            ],
            [
              -84.535625,
              37.96323
            ],
            [
              -84.522228,
              37.957715
            ],
            [
              -84.479629,
              37.940219
            ],
            [
              -84.471965,
              37.922538
            ],
            [
              -84.452614,
              37.90899
            ],
            [
              -84.434338,
              37.868119
            ],
            [
              -84.43572,
              37.847202
            ],
            [
              -84.410672,
              37.87126
            ],
            [
              -84.386165,
              37.84549
            ],
            [
              -84.365694,
              37.858755
            ],
            [
              -84.383587,
              37.89381
            ],
            [
              -84.378609,
              37.901543
            ],
            [
              -84.341142,
              37.881973
            ],
            [
              -84.337393,
              37.892007
            ],
            [
              -84.348874,
              37.898543
            ],
            [
              -84.338975,
              37.907717
            ],
            [
              -84.347373,
              37.915562
            ],
            [
              -84.331,
              37.920913
            ],
            [
              -84.339631,
              37.934485
            ],
            [
              -84.312807,
              37.967274
            ],
            [
              -84.323043,
              37.964966
            ],
            [
              -84.322502,
              38.00097
            ],
            [
              -84.282629,
              38.019103
            ],
            [
              -84.290492,
              38.030384
            ],
            [
              -84.292677,
              38.033346
            ],
            [
              -84.284686,
              38.040095
            ],
            [
              -84.286461,
              38.067028
            ],
            [
              -84.287259,
              38.075592
            ],
            [
              -84.318125,
              38.092885
            ],
            [
              -84.32667,
              38.084732
            ],
            [
              -84.36056,
              38.098708
            ],
            [
              -84.380935,
              38.113491
            ],
            [
              -84.368508,
              38.13365
            ],
            [
              -84.373755,
              38.157572
            ],
            [
              -84.35523,
              38.178474
            ],
            [
              -84.372983,
              38.208004
            ],
            [
              -84.401847,
              38.207889
            ],
            [
              -84.538989,
              38.159066
            ],
            [
              -84.620601,
              38.129545
            ],
            [
              -84.625079,
              38.116397
            ],
            [
              -84.62909,
              38.104405
            ],
            [
              -84.649258,
              38.043207
            ],
            [
              -84.657704,
              38.017755
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "229",
      "COUNTYNS": "00516961",
      "AFFGEOID": "0500000US21229",
      "GEOID": "21229",
      "NAME": "Washington",
      "LSAD": "06",
      "ALAND": 769158640,
      "AWATER": 10943099,
      "County_state": "Washington,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.401784,
              37.730658
            ],
            [
              -85.390911,
              37.713432
            ],
            [
              -85.37473,
              37.702373
            ],
            [
              -85.369414,
              37.688201
            ],
            [
              -85.373639,
              37.673343
            ],
            [
              -85.349334,
              37.655663
            ],
            [
              -85.340697,
              37.651423
            ],
            [
              -85.311239,
              37.651776
            ],
            [
              -85.257298,
              37.630903
            ],
            [
              -85.166084,
              37.63111
            ],
            [
              -85.030172,
              37.63127
            ],
            [
              -85.026526,
              37.664427
            ],
            [
              -85.025021,
              37.678854
            ],
            [
              -85.005815,
              37.816613
            ],
            [
              -85.009528,
              37.835587
            ],
            [
              -85.00052,
              37.854723
            ],
            [
              -85.03098,
              37.881526
            ],
            [
              -85.030528,
              37.891538
            ],
            [
              -85.051431,
              37.897139
            ],
            [
              -85.068162,
              37.885897
            ],
            [
              -85.106913,
              37.893901
            ],
            [
              -85.106192,
              37.909247
            ],
            [
              -85.126705,
              37.917167
            ],
            [
              -85.138236,
              37.891741
            ],
            [
              -85.152797,
              37.897661
            ],
            [
              -85.153818,
              37.885578
            ],
            [
              -85.172182,
              37.887834
            ],
            [
              -85.199762,
              37.898253
            ],
            [
              -85.196219,
              37.880793
            ],
            [
              -85.205394,
              37.863179
            ],
            [
              -85.197101,
              37.858231
            ],
            [
              -85.205089,
              37.846218
            ],
            [
              -85.218117,
              37.855779
            ],
            [
              -85.216249,
              37.871326
            ],
            [
              -85.226875,
              37.855174
            ],
            [
              -85.232942,
              37.866013
            ],
            [
              -85.254333,
              37.866423
            ],
            [
              -85.243032,
              37.848307
            ],
            [
              -85.264269,
              37.844338
            ],
            [
              -85.280647,
              37.848745
            ],
            [
              -85.277092,
              37.838845
            ],
            [
              -85.299042,
              37.831214
            ],
            [
              -85.313748,
              37.804907
            ],
            [
              -85.308234,
              37.793765
            ],
            [
              -85.33632,
              37.786919
            ],
            [
              -85.337862,
              37.780343
            ],
            [
              -85.355671,
              37.784139
            ],
            [
              -85.352953,
              37.757362
            ],
            [
              -85.374954,
              37.764507
            ],
            [
              -85.373323,
              37.739581
            ],
            [
              -85.386311,
              37.740677
            ],
            [
              -85.401784,
              37.730658
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "239",
      "COUNTYNS": "00516966",
      "AFFGEOID": "0500000US21239",
      "GEOID": "21239",
      "NAME": "Woodford",
      "LSAD": "06",
      "ALAND": 489012357,
      "AWATER": 8307144,
      "County_state": "Woodford,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.866003,
              38.134627
            ],
            [
              -84.864908,
              38.116926
            ],
            [
              -84.865138,
              38.09644
            ],
            [
              -84.855669,
              38.090203
            ],
            [
              -84.842174,
              38.100003
            ],
            [
              -84.830915,
              38.093605
            ],
            [
              -84.82735,
              38.083839
            ],
            [
              -84.842487,
              38.068744
            ],
            [
              -84.82693,
              38.060456
            ],
            [
              -84.829482,
              38.052208
            ],
            [
              -84.849779,
              38.047985
            ],
            [
              -84.829753,
              38.02362
            ],
            [
              -84.836651,
              38.007396
            ],
            [
              -84.817142,
              37.999998
            ],
            [
              -84.823246,
              37.98121
            ],
            [
              -84.81806,
              37.973391
            ],
            [
              -84.796327,
              37.970088
            ],
            [
              -84.797253,
              37.963532
            ],
            [
              -84.818251,
              37.953055
            ],
            [
              -84.818833,
              37.945838
            ],
            [
              -84.796896,
              37.936747
            ],
            [
              -84.821025,
              37.926874
            ],
            [
              -84.826454,
              37.916458
            ],
            [
              -84.794991,
              37.914562
            ],
            [
              -84.800123,
              37.889245
            ],
            [
              -84.792461,
              37.888921
            ],
            [
              -84.782103,
              37.907687
            ],
            [
              -84.773931,
              37.903892
            ],
            [
              -84.77939,
              37.890536
            ],
            [
              -84.765922,
              37.889006
            ],
            [
              -84.759052,
              37.874273
            ],
            [
              -84.774048,
              37.857313
            ],
            [
              -84.765204,
              37.847835
            ],
            [
              -84.744632,
              37.850901
            ],
            [
              -84.720701,
              37.862193
            ],
            [
              -84.709918,
              37.861952
            ],
            [
              -84.66034,
              38.003836
            ],
            [
              -84.657704,
              38.017755
            ],
            [
              -84.649258,
              38.043207
            ],
            [
              -84.62909,
              38.104405
            ],
            [
              -84.625079,
              38.116397
            ],
            [
              -84.645012,
              38.116631
            ],
            [
              -84.639277,
              38.128023
            ],
            [
              -84.650277,
              38.144824
            ],
            [
              -84.655275,
              38.171015
            ],
            [
              -84.647821,
              38.176606
            ],
            [
              -84.682641,
              38.181267
            ],
            [
              -84.689717,
              38.171179
            ],
            [
              -84.707541,
              38.173389
            ],
            [
              -84.725168,
              38.195426
            ],
            [
              -84.864236,
              38.14159
            ],
            [
              -84.866003,
              38.134627
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "047",
      "COUNTYNS": "00516870",
      "AFFGEOID": "0500000US21047",
      "GEOID": "21047",
      "NAME": "Christian",
      "LSAD": "06",
      "ALAND": 1858332405,
      "AWATER": 16928991,
      "County_state": "Christian,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.710912,
              37.021607
            ],
            [
              -87.733368,
              37.002103
            ],
            [
              -87.659724,
              36.966397
            ],
            [
              -87.671871,
              36.878892
            ],
            [
              -87.673816,
              36.815471
            ],
            [
              -87.673774,
              36.755557
            ],
            [
              -87.682749,
              36.703366
            ],
            [
              -87.69419132838901,
              36.6370710817038
            ],
            [
              -87.64115,
              36.638036
            ],
            [
              -87.6411458985761,
              36.6380360579522
            ],
            [
              -87.564928,
              36.639113
            ],
            [
              -87.563052,
              36.639113
            ],
            [
              -87.4454075808545,
              36.6406320961245
            ],
            [
              -87.436509,
              36.640747
            ],
            [
              -87.425009,
              36.641047
            ],
            [
              -87.414309,
              36.641047
            ],
            [
              -87.4142954016638,
              36.6410470803474
            ],
            [
              -87.38569247363779,
              36.6412160841619
            ],
            [
              -87.347796,
              36.64144
            ],
            [
              -87.344131,
              36.64151
            ],
            [
              -87.33597953375501,
              36.6415426709465
            ],
            [
              -87.259371,
              37.072401
            ],
            [
              -87.274102,
              37.070514
            ],
            [
              -87.285534,
              37.08498
            ],
            [
              -87.279584,
              37.089008
            ],
            [
              -87.284329,
              37.108024
            ],
            [
              -87.3059,
              37.107389
            ],
            [
              -87.334277,
              37.131818
            ],
            [
              -87.333741,
              37.157186
            ],
            [
              -87.360305,
              37.15666
            ],
            [
              -87.356142,
              37.143588
            ],
            [
              -87.372285,
              37.1323
            ],
            [
              -87.487151,
              37.125901
            ],
            [
              -87.520116,
              37.105452
            ],
            [
              -87.62232,
              37.135242
            ],
            [
              -87.650087,
              37.147098
            ],
            [
              -87.680617,
              37.149239
            ],
            [
              -87.69254,
              37.100022
            ],
            [
              -87.710912,
              37.021607
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "065",
      "COUNTYNS": "00516879",
      "AFFGEOID": "0500000US21065",
      "GEOID": "21065",
      "NAME": "Estill",
      "LSAD": "06",
      "ALAND": 655468668,
      "AWATER": 6557602,
      "County_state": "Estill,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.126948,
              37.642421
            ],
            [
              -84.112521,
              37.605648
            ],
            [
              -84.090364,
              37.566227
            ],
            [
              -84.045023,
              37.563573
            ],
            [
              -83.965244,
              37.581421
            ],
            [
              -83.939846,
              37.573173
            ],
            [
              -83.90557,
              37.542856
            ],
            [
              -83.858098,
              37.609205
            ],
            [
              -83.865969,
              37.615877
            ],
            [
              -83.83246,
              37.636259
            ],
            [
              -83.808123,
              37.636035
            ],
            [
              -83.822121,
              37.665578
            ],
            [
              -83.780783,
              37.687807
            ],
            [
              -83.749284,
              37.715162
            ],
            [
              -83.7221,
              37.71642
            ],
            [
              -83.722651,
              37.728947
            ],
            [
              -83.74845,
              37.731836
            ],
            [
              -83.768963,
              37.741097
            ],
            [
              -83.777592,
              37.756776
            ],
            [
              -83.803931,
              37.756748
            ],
            [
              -83.821069,
              37.745573
            ],
            [
              -83.830347,
              37.765247
            ],
            [
              -83.865441,
              37.752299
            ],
            [
              -83.906251,
              37.759363
            ],
            [
              -83.91179,
              37.766732
            ],
            [
              -83.963262,
              37.800136
            ],
            [
              -83.962554,
              37.844644
            ],
            [
              -83.991595,
              37.850158
            ],
            [
              -84.001967,
              37.837592
            ],
            [
              -84.039557,
              37.833721
            ],
            [
              -84.059258,
              37.847748
            ],
            [
              -84.05744,
              37.835428
            ],
            [
              -84.075107,
              37.823272
            ],
            [
              -84.080337,
              37.853066
            ],
            [
              -84.092676,
              37.835783
            ],
            [
              -84.121716,
              37.81309
            ],
            [
              -84.099159,
              37.815605
            ],
            [
              -84.073908,
              37.801117
            ],
            [
              -84.103108,
              37.773758
            ],
            [
              -84.081913,
              37.767258
            ],
            [
              -84.081076,
              37.749075
            ],
            [
              -84.096689,
              37.735087
            ],
            [
              -84.099206,
              37.718976
            ],
            [
              -84.092786,
              37.709949
            ],
            [
              -84.089212,
              37.668149
            ],
            [
              -84.126948,
              37.642421
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "081",
      "COUNTYNS": "00516887",
      "AFFGEOID": "0500000US21081",
      "GEOID": "21081",
      "NAME": "Grant",
      "LSAD": "06",
      "ALAND": 668161261,
      "AWATER": 7236760,
      "County_state": "Grant,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.780766,
              38.765208
            ],
            [
              -84.785788,
              38.720459
            ],
            [
              -84.774322,
              38.618594
            ],
            [
              -84.680959,
              38.55975
            ],
            [
              -84.665457,
              38.543453
            ],
            [
              -84.674574,
              38.532033
            ],
            [
              -84.65697,
              38.532016
            ],
            [
              -84.642287,
              38.513854
            ],
            [
              -84.626651,
              38.485132
            ],
            [
              -84.600769,
              38.481028
            ],
            [
              -84.580539,
              38.473039
            ],
            [
              -84.562692,
              38.490436
            ],
            [
              -84.557375,
              38.492917
            ],
            [
              -84.521393,
              38.508299
            ],
            [
              -84.485295,
              38.508552
            ],
            [
              -84.476299,
              38.542723
            ],
            [
              -84.532288,
              38.791937
            ],
            [
              -84.605009,
              38.792833
            ],
            [
              -84.615664,
              38.802277
            ],
            [
              -84.643581,
              38.797995
            ],
            [
              -84.660107,
              38.777293
            ],
            [
              -84.780766,
              38.765208
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "167",
      "COUNTYNS": "00516930",
      "AFFGEOID": "0500000US21167",
      "GEOID": "21167",
      "NAME": "Mercer",
      "LSAD": "06",
      "ALAND": 644463522,
      "AWATER": 11600781,
      "County_state": "Mercer,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.030528,
              37.891538
            ],
            [
              -85.03098,
              37.881526
            ],
            [
              -85.00052,
              37.854723
            ],
            [
              -85.009528,
              37.835587
            ],
            [
              -85.005815,
              37.816613
            ],
            [
              -85.025021,
              37.678854
            ],
            [
              -84.999833,
              37.680177
            ],
            [
              -84.807867,
              37.704614
            ],
            [
              -84.793574,
              37.717249
            ],
            [
              -84.744888,
              37.713075
            ],
            [
              -84.737384,
              37.718917
            ],
            [
              -84.726739,
              37.713803
            ],
            [
              -84.727898,
              37.728808
            ],
            [
              -84.717374,
              37.724419
            ],
            [
              -84.689343,
              37.727274
            ],
            [
              -84.706581,
              37.731828
            ],
            [
              -84.691482,
              37.744848
            ],
            [
              -84.703553,
              37.746195
            ],
            [
              -84.694249,
              37.763291
            ],
            [
              -84.71577,
              37.769989
            ],
            [
              -84.702923,
              37.786498
            ],
            [
              -84.716433,
              37.799564
            ],
            [
              -84.716925,
              37.815389
            ],
            [
              -84.723423,
              37.832947
            ],
            [
              -84.689095,
              37.854796
            ],
            [
              -84.701888,
              37.860761
            ],
            [
              -84.709918,
              37.861952
            ],
            [
              -84.720701,
              37.862193
            ],
            [
              -84.744632,
              37.850901
            ],
            [
              -84.765204,
              37.847835
            ],
            [
              -84.774048,
              37.857313
            ],
            [
              -84.759052,
              37.874273
            ],
            [
              -84.765922,
              37.889006
            ],
            [
              -84.77939,
              37.890536
            ],
            [
              -84.773931,
              37.903892
            ],
            [
              -84.782103,
              37.907687
            ],
            [
              -84.792461,
              37.888921
            ],
            [
              -84.800123,
              37.889245
            ],
            [
              -84.794991,
              37.914562
            ],
            [
              -84.826454,
              37.916458
            ],
            [
              -84.821025,
              37.926874
            ],
            [
              -84.796896,
              37.936747
            ],
            [
              -84.818833,
              37.945838
            ],
            [
              -84.818251,
              37.953055
            ],
            [
              -84.797253,
              37.963532
            ],
            [
              -84.796327,
              37.970088
            ],
            [
              -84.883748,
              37.939893
            ],
            [
              -84.910587,
              37.950999
            ],
            [
              -84.93778,
              37.952826
            ],
            [
              -84.973351,
              37.935511
            ],
            [
              -84.987192,
              37.905657
            ],
            [
              -85.000115,
              37.90713
            ],
            [
              -85.007983,
              37.890969
            ],
            [
              -85.027462,
              37.88447
            ],
            [
              -85.030528,
              37.891538
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "173",
      "COUNTYNS": "00516933",
      "AFFGEOID": "0500000US21173",
      "GEOID": "21173",
      "NAME": "Montgomery",
      "LSAD": "06",
      "ALAND": 511177578,
      "AWATER": 3859635,
      "County_state": "Montgomery,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.990171,
              38.175067
            ],
            [
              -84.080527,
              38.115118
            ],
            [
              -83.981513,
              37.95845
            ],
            [
              -83.96631,
              37.930768
            ],
            [
              -83.908162,
              37.916717
            ],
            [
              -83.886242,
              37.916507
            ],
            [
              -83.878662,
              37.905062
            ],
            [
              -83.828286,
              37.906381
            ],
            [
              -83.814919,
              37.900227
            ],
            [
              -83.781787,
              37.908312
            ],
            [
              -83.768783,
              37.918371
            ],
            [
              -83.775168,
              37.988663
            ],
            [
              -83.75943,
              37.998731
            ],
            [
              -83.756914,
              38.006315
            ],
            [
              -83.801686,
              38.041702
            ],
            [
              -83.820799,
              38.08111
            ],
            [
              -83.881353,
              38.136517
            ],
            [
              -83.974602,
              38.15876
            ],
            [
              -83.965253,
              38.170542
            ],
            [
              -83.979324,
              38.176613
            ],
            [
              -83.977924,
              38.192118
            ],
            [
              -83.990171,
              38.175067
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "233",
      "COUNTYNS": "00516963",
      "AFFGEOID": "0500000US21233",
      "GEOID": "21233",
      "NAME": "Webster",
      "LSAD": "06",
      "ALAND": 859730022,
      "AWATER": 9613810,
      "County_state": "Webster,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.943955,
              37.465225
            ],
            [
              -87.936385,
              37.43865
            ],
            [
              -87.938325,
              37.425635
            ],
            [
              -87.929999,
              37.409167
            ],
            [
              -87.922849,
              37.411159
            ],
            [
              -87.903034,
              37.398197
            ],
            [
              -87.897996,
              37.403206
            ],
            [
              -87.868638,
              37.405345
            ],
            [
              -87.847694,
              37.420701
            ],
            [
              -87.84636,
              37.394341
            ],
            [
              -87.860173,
              37.391573
            ],
            [
              -87.859907,
              37.380332
            ],
            [
              -87.844075,
              37.374867
            ],
            [
              -87.818952,
              37.388185
            ],
            [
              -87.801046,
              37.379445
            ],
            [
              -87.827887,
              37.352986
            ],
            [
              -87.813413,
              37.350645
            ],
            [
              -87.593575,
              37.461963
            ],
            [
              -87.497112,
              37.487802
            ],
            [
              -87.375145,
              37.569988
            ],
            [
              -87.39697,
              37.589253
            ],
            [
              -87.466031,
              37.594201
            ],
            [
              -87.482702,
              37.600914
            ],
            [
              -87.499647,
              37.627445
            ],
            [
              -87.495331,
              37.647547
            ],
            [
              -87.734545,
              37.638376
            ],
            [
              -87.803653,
              37.579461
            ],
            [
              -87.932952,
              37.480052
            ],
            [
              -87.943955,
              37.465225
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "029",
      "COUNTYNS": "00516861",
      "AFFGEOID": "0500000US21029",
      "GEOID": "21029",
      "NAME": "Bullitt",
      "LSAD": "06",
      "ALAND": 769200000,
      "AWATER": 8481647,
      "County_state": "Bullitt,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.938321,
              37.998756
            ],
            [
              -85.935378,
              37.996431
            ],
            [
              -85.907437,
              37.991586
            ],
            [
              -85.900765,
              37.969537
            ],
            [
              -85.905177,
              37.942579
            ],
            [
              -85.894233,
              37.940084
            ],
            [
              -85.879827,
              37.94851
            ],
            [
              -85.854871,
              37.945385
            ],
            [
              -85.844886,
              37.921718
            ],
            [
              -85.830169,
              37.918752
            ],
            [
              -85.82746,
              37.900631
            ],
            [
              -85.813353,
              37.889622
            ],
            [
              -85.833944,
              37.892696
            ],
            [
              -85.838198,
              37.879284
            ],
            [
              -85.817337,
              37.858261
            ],
            [
              -85.803147,
              37.860016
            ],
            [
              -85.785555,
              37.842896
            ],
            [
              -85.760574,
              37.83343
            ],
            [
              -85.742184,
              37.821871
            ],
            [
              -85.737777,
              37.81179
            ],
            [
              -85.720388,
              37.809229
            ],
            [
              -85.724233,
              37.805879
            ],
            [
              -85.691231,
              37.818509
            ],
            [
              -85.680471,
              37.81512
            ],
            [
              -85.643254,
              37.834033
            ],
            [
              -85.610269,
              37.858904
            ],
            [
              -85.612898,
              37.870205
            ],
            [
              -85.599523,
              37.869528
            ],
            [
              -85.576259,
              37.885129
            ],
            [
              -85.584803,
              37.893551
            ],
            [
              -85.583946,
              37.911048
            ],
            [
              -85.549074,
              37.928243
            ],
            [
              -85.524026,
              37.957832
            ],
            [
              -85.534301,
              37.965356
            ],
            [
              -85.489202,
              37.990654
            ],
            [
              -85.516476,
              38.011257
            ],
            [
              -85.486643,
              38.062424
            ],
            [
              -85.469231,
              38.096222
            ],
            [
              -85.436129,
              38.104761
            ],
            [
              -85.42883,
              38.118383
            ],
            [
              -85.515895,
              38.088766
            ],
            [
              -85.529477,
              38.083864
            ],
            [
              -85.55091,
              38.076135
            ],
            [
              -85.567145,
              38.074743
            ],
            [
              -85.616942,
              38.079061
            ],
            [
              -85.69931,
              38.086173
            ],
            [
              -85.712578,
              38.087311
            ],
            [
              -85.790154,
              38.065842
            ],
            [
              -85.816945,
              38.058446
            ],
            [
              -85.842259,
              38.051485
            ],
            [
              -85.882805,
              38.040545
            ],
            [
              -85.912789,
              38.020652
            ],
            [
              -85.934643,
              37.997217
            ],
            [
              -85.938321,
              37.998756
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "005",
      "COUNTYNS": "00516849",
      "AFFGEOID": "0500000US21005",
      "GEOID": "21005",
      "NAME": "Anderson",
      "LSAD": "06",
      "ALAND": 522773659,
      "AWATER": 6311541,
      "County_state": "Anderson,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.169359,
              37.997576
            ],
            [
              -85.167834,
              37.971809
            ],
            [
              -85.152797,
              37.897661
            ],
            [
              -85.138236,
              37.891741
            ],
            [
              -85.126705,
              37.917167
            ],
            [
              -85.106192,
              37.909247
            ],
            [
              -85.106913,
              37.893901
            ],
            [
              -85.068162,
              37.885897
            ],
            [
              -85.051431,
              37.897139
            ],
            [
              -85.030528,
              37.891538
            ],
            [
              -85.027462,
              37.88447
            ],
            [
              -85.007983,
              37.890969
            ],
            [
              -85.000115,
              37.90713
            ],
            [
              -84.987192,
              37.905657
            ],
            [
              -84.973351,
              37.935511
            ],
            [
              -84.93778,
              37.952826
            ],
            [
              -84.910587,
              37.950999
            ],
            [
              -84.883748,
              37.939893
            ],
            [
              -84.796327,
              37.970088
            ],
            [
              -84.81806,
              37.973391
            ],
            [
              -84.823246,
              37.98121
            ],
            [
              -84.817142,
              37.999998
            ],
            [
              -84.836651,
              38.007396
            ],
            [
              -84.829753,
              38.02362
            ],
            [
              -84.849779,
              38.047985
            ],
            [
              -84.829482,
              38.052208
            ],
            [
              -84.82693,
              38.060456
            ],
            [
              -84.842487,
              38.068744
            ],
            [
              -84.82735,
              38.083839
            ],
            [
              -84.830915,
              38.093605
            ],
            [
              -84.842174,
              38.100003
            ],
            [
              -84.855669,
              38.090203
            ],
            [
              -84.865138,
              38.09644
            ],
            [
              -84.864908,
              38.116926
            ],
            [
              -84.887438,
              38.097005
            ],
            [
              -84.902266,
              38.093437
            ],
            [
              -84.958135,
              38.109825
            ],
            [
              -84.967393,
              38.119268
            ],
            [
              -85.023711,
              38.129052
            ],
            [
              -85.039653,
              38.119313
            ],
            [
              -85.054538,
              38.084223
            ],
            [
              -85.047783,
              38.072341
            ],
            [
              -85.071682,
              38.057753
            ],
            [
              -85.083616,
              38.040876
            ],
            [
              -85.101646,
              38.03727
            ],
            [
              -85.126452,
              38.020292
            ],
            [
              -85.131052,
              37.99588
            ],
            [
              -85.169359,
              37.997576
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "179",
      "COUNTYNS": "00516936",
      "AFFGEOID": "0500000US21179",
      "GEOID": "21179",
      "NAME": "Nelson",
      "LSAD": "06",
      "ALAND": 1081354955,
      "AWATER": 16997387,
      "County_state": "Nelson,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.737777,
              37.81179
            ],
            [
              -85.740523,
              37.789243
            ],
            [
              -85.728133,
              37.774178
            ],
            [
              -85.687539,
              37.754961
            ],
            [
              -85.676171,
              37.733657
            ],
            [
              -85.680488,
              37.732284
            ],
            [
              -85.671795,
              37.732283
            ],
            [
              -85.645286,
              37.716101
            ],
            [
              -85.637889,
              37.696683
            ],
            [
              -85.625818,
              37.694179
            ],
            [
              -85.606042,
              37.700837
            ],
            [
              -85.592088,
              37.693479
            ],
            [
              -85.595259,
              37.653152
            ],
            [
              -85.601108,
              37.639894
            ],
            [
              -85.583736,
              37.644319
            ],
            [
              -85.573343,
              37.638601
            ],
            [
              -85.568125,
              37.622946
            ],
            [
              -85.57983,
              37.626684
            ],
            [
              -85.592543,
              37.61379
            ],
            [
              -85.613991,
              37.615873
            ],
            [
              -85.611426,
              37.607346
            ],
            [
              -85.590438,
              37.596291
            ],
            [
              -85.606677,
              37.590796
            ],
            [
              -85.604162,
              37.569121
            ],
            [
              -85.623323,
              37.551604
            ],
            [
              -85.62394,
              37.540489
            ],
            [
              -85.606164,
              37.54825
            ],
            [
              -85.592397,
              37.534319
            ],
            [
              -85.564792,
              37.522955
            ],
            [
              -85.547123,
              37.53031
            ],
            [
              -85.551131,
              37.554024
            ],
            [
              -85.542921,
              37.562622
            ],
            [
              -85.52129,
              37.554343
            ],
            [
              -85.450493,
              37.685948
            ],
            [
              -85.401784,
              37.730658
            ],
            [
              -85.386311,
              37.740677
            ],
            [
              -85.373323,
              37.739581
            ],
            [
              -85.374954,
              37.764507
            ],
            [
              -85.352953,
              37.757362
            ],
            [
              -85.355671,
              37.784139
            ],
            [
              -85.337862,
              37.780343
            ],
            [
              -85.33632,
              37.786919
            ],
            [
              -85.308234,
              37.793765
            ],
            [
              -85.313748,
              37.804907
            ],
            [
              -85.299042,
              37.831214
            ],
            [
              -85.277092,
              37.838845
            ],
            [
              -85.280647,
              37.848745
            ],
            [
              -85.264269,
              37.844338
            ],
            [
              -85.243032,
              37.848307
            ],
            [
              -85.254333,
              37.866423
            ],
            [
              -85.232942,
              37.866013
            ],
            [
              -85.226875,
              37.855174
            ],
            [
              -85.216249,
              37.871326
            ],
            [
              -85.218117,
              37.855779
            ],
            [
              -85.205089,
              37.846218
            ],
            [
              -85.197101,
              37.858231
            ],
            [
              -85.205394,
              37.863179
            ],
            [
              -85.196219,
              37.880793
            ],
            [
              -85.199762,
              37.898253
            ],
            [
              -85.172182,
              37.887834
            ],
            [
              -85.153818,
              37.885578
            ],
            [
              -85.152797,
              37.897661
            ],
            [
              -85.167834,
              37.971809
            ],
            [
              -85.305058,
              37.953189
            ],
            [
              -85.379145,
              37.940353
            ],
            [
              -85.399559,
              37.939929
            ],
            [
              -85.425822,
              37.959632
            ],
            [
              -85.463438,
              37.971377
            ],
            [
              -85.489202,
              37.990654
            ],
            [
              -85.534301,
              37.965356
            ],
            [
              -85.524026,
              37.957832
            ],
            [
              -85.549074,
              37.928243
            ],
            [
              -85.583946,
              37.911048
            ],
            [
              -85.584803,
              37.893551
            ],
            [
              -85.576259,
              37.885129
            ],
            [
              -85.599523,
              37.869528
            ],
            [
              -85.612898,
              37.870205
            ],
            [
              -85.610269,
              37.858904
            ],
            [
              -85.643254,
              37.834033
            ],
            [
              -85.680471,
              37.81512
            ],
            [
              -85.691231,
              37.818509
            ],
            [
              -85.724233,
              37.805879
            ],
            [
              -85.720388,
              37.809229
            ],
            [
              -85.737777,
              37.81179
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "025",
      "COUNTYNS": "00516859",
      "AFFGEOID": "0500000US21025",
      "GEOID": "21025",
      "NAME": "Breathitt",
      "LSAD": "06",
      "ALAND": 1275346433,
      "AWATER": 7390878,
      "County_state": "Breathitt,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.579186,
              37.506249
            ],
            [
              -83.549734,
              37.445623
            ],
            [
              -83.534281,
              37.417409
            ],
            [
              -83.549201,
              37.394142
            ],
            [
              -83.545212,
              37.362053
            ],
            [
              -83.534059,
              37.352795
            ],
            [
              -83.547628,
              37.334418
            ],
            [
              -83.536265,
              37.330291
            ],
            [
              -83.486696,
              37.358947
            ],
            [
              -83.476721,
              37.377534
            ],
            [
              -83.46297,
              37.373006
            ],
            [
              -83.449454,
              37.378853
            ],
            [
              -83.422235,
              37.350821
            ],
            [
              -83.403988,
              37.338332
            ],
            [
              -83.38165,
              37.338266
            ],
            [
              -83.36971,
              37.34647
            ],
            [
              -83.368244,
              37.358071
            ],
            [
              -83.357802,
              37.357896
            ],
            [
              -83.343122,
              37.364891
            ],
            [
              -83.332641,
              37.379675
            ],
            [
              -83.310514,
              37.377684
            ],
            [
              -83.302525,
              37.386061
            ],
            [
              -83.269933,
              37.398657
            ],
            [
              -83.214735,
              37.443538
            ],
            [
              -83.202294,
              37.430429
            ],
            [
              -83.199734,
              37.41466
            ],
            [
              -83.124348,
              37.404973
            ],
            [
              -83.134711,
              37.457181
            ],
            [
              -83.093144,
              37.498102
            ],
            [
              -83.094663,
              37.518286
            ],
            [
              -83.088514,
              37.522666
            ],
            [
              -83.07096,
              37.516846
            ],
            [
              -83.062266,
              37.526231
            ],
            [
              -83.046963,
              37.521995
            ],
            [
              -83.031262,
              37.506341
            ],
            [
              -83.013903,
              37.508544
            ],
            [
              -83.002665,
              37.5012
            ],
            [
              -82.985361,
              37.506522
            ],
            [
              -82.974293,
              37.497959
            ],
            [
              -82.948544,
              37.503167
            ],
            [
              -82.959935,
              37.518249
            ],
            [
              -82.996952,
              37.528332
            ],
            [
              -83.007838,
              37.550215
            ],
            [
              -83.017953,
              37.549181
            ],
            [
              -83.01217,
              37.583474
            ],
            [
              -83.027515,
              37.589649
            ],
            [
              -83.038749,
              37.584303
            ],
            [
              -83.056893,
              37.598513
            ],
            [
              -83.063122,
              37.611464
            ],
            [
              -83.071977,
              37.609511
            ],
            [
              -83.089796,
              37.632167
            ],
            [
              -83.114667,
              37.611401
            ],
            [
              -83.134949,
              37.606812
            ],
            [
              -83.141984,
              37.619097
            ],
            [
              -83.168275,
              37.635119
            ],
            [
              -83.171606,
              37.641915
            ],
            [
              -83.217563,
              37.64884
            ],
            [
              -83.248692,
              37.669616
            ],
            [
              -83.268313,
              37.67995
            ],
            [
              -83.280125,
              37.674466
            ],
            [
              -83.294862,
              37.682389
            ],
            [
              -83.317939,
              37.682894
            ],
            [
              -83.33067,
              37.674569
            ],
            [
              -83.364582,
              37.684223
            ],
            [
              -83.390714,
              37.699776
            ],
            [
              -83.418474,
              37.690729
            ],
            [
              -83.431626,
              37.673727
            ],
            [
              -83.433623,
              37.65654
            ],
            [
              -83.458716,
              37.630476
            ],
            [
              -83.495488,
              37.619595
            ],
            [
              -83.495922,
              37.645617
            ],
            [
              -83.522308,
              37.638516
            ],
            [
              -83.579186,
              37.506249
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "21",
      "COUNTYFP": "185",
      "COUNTYNS": "00516939",
      "AFFGEOID": "0500000US21185",
      "GEOID": "21185",
      "NAME": "Oldham",
      "LSAD": "06",
      "ALAND": 484931825,
      "AWATER": 23831645,
      "County_state": "Oldham,21"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.638041,
              38.380338
            ],
            [
              -85.63804773991271,
              38.3802883493094
            ],
            [
              -85.569413,
              38.341752
            ],
            [
              -85.585272,
              38.339431
            ],
            [
              -85.545363,
              38.328248
            ],
            [
              -85.526966,
              38.31795
            ],
            [
              -85.498273,
              38.301741
            ],
            [
              -85.492437,
              38.29854
            ],
            [
              -85.476253,
              38.289695
            ],
            [
              -85.468206,
              38.285309
            ],
            [
              -85.448123,
              38.304042
            ],
            [
              -85.426909,
              38.292823
            ],
            [
              -85.419126,
              38.303134
            ],
            [
              -85.411536,
              38.294004
            ],
            [
              -85.386096,
              38.308039
            ],
            [
              -85.351805,
              38.303061
            ],
            [
              -85.324453,
              38.310112
            ],
            [
              -85.283082,
              38.358054
            ],
            [
              -85.325072,
              38.403872
            ],
            [
              -85.331721,
              38.443049
            ],
            [
              -85.34608,
              38.45954
            ],
            [
              -85.314006,
              38.492592
            ],
            [
              -85.323767,
              38.500824
            ],
            [
              -85.364396,
              38.507634
            ],
            [
              -85.378743,
              38.518822
            ],
            [
              -85.427853,
              38.519199
            ],
            [
              -85.43291605412969,
              38.52405813652349
            ],
            [
              -85.433136,
              38.523914
            ],
            [
              -85.441725,
              38.520191
            ],
            [
              -85.458496,
              38.5144
            ],
            [
              -85.462518,
              38.512602
            ],
            [
              -85.466691,
              38.51028
            ],
            [
              -85.472221,
              38.506279
            ],
            [
              -85.474354,
              38.504074
            ],
            [
              -85.47767,
              38.49832
            ],
            [
              -85.479472,
              38.494533
            ],
            [
              -85.481246,
              38.488374
            ],
            [
              -85.482897,
              38.485701
            ],
            [
              -85.491422,
              38.474702
            ],
            [
              -85.498866,
              38.468242
            ],
            [
              -85.516939,
              38.461357
            ],
            [
              -85.527164,
              38.45829
            ],
            [
              -85.536542,
              38.456083
            ],
            [
              -85.553304,
              38.45388
            ],
            [
              -85.575254,
              38.453292
            ],
            [
              -85.587758,
              38.450495
            ],
            [
              -85.603833,
              38.442094
            ],
            [
              -85.607629,
              38.439295
            ],
            [
              -85.620521,
              38.423105
            ],
            [
              -85.620329,
              38.421697
            ],
            [
              -85.621625,
              38.417089
            ],
            [
              -85.629961,
              38.402306
            ],
            [
              -85.632937,
              38.395666
            ],
            [
              -85.6336949144431,
              38.3933898807632
            ],
            [
              -85.638041,
              38.380338
            ]
          ]
        ]
      ]
    }
  }
]
};