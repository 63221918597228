export const counties_ca = {
  "type":"FeatureCollection","name":"ca_counties","features":[
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "06",
      "COUNTYFP": "069",
      "COUNTYNS": "00277299",
      "AFFGEOID": "0500000US06069",
      "GEOID": "06069",
      "NAME": "San Benito",
      "LSAD": "06",
      "ALAND": 3596588882,
      "AWATER": 4721397,
      "County_state": "San Benito,06"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -121.644001,
              36.893996
            ],
            [
              -121.642565,
              36.8737
            ],
            [
              -121.622089,
              36.846203
            ],
            [
              -121.597698,
              36.83735
            ],
            [
              -121.560563,
              36.805503
            ],
            [
              -121.520469,
              36.771537
            ],
            [
              -121.495738,
              36.761966
            ],
            [
              -121.487533,
              36.763949
            ],
            [
              -121.483917,
              36.764478
            ],
            [
              -121.480774,
              36.764023
            ],
            [
              -121.47972,
              36.762287
            ],
            [
              -121.476571,
              36.752342
            ],
            [
              -121.473021,
              36.746889
            ],
            [
              -121.468166,
              36.745069
            ],
            [
              -121.464111,
              36.744191
            ],
            [
              -121.460386,
              36.740523
            ],
            [
              -121.45803,
              36.736768
            ],
            [
              -121.456269,
              36.731927
            ],
            [
              -121.452484,
              36.727333
            ],
            [
              -121.453084,
              36.723045
            ],
            [
              -121.456339,
              36.720981
            ],
            [
              -121.461109,
              36.717859
            ],
            [
              -121.464959,
              36.717781
            ],
            [
              -121.467822,
              36.717177
            ],
            [
              -121.47184,
              36.716724
            ],
            [
              -121.478764,
              36.715357
            ],
            [
              -121.48141,
              36.715293
            ],
            [
              -121.484215,
              36.712399
            ],
            [
              -121.483318,
              36.710104
            ],
            [
              -121.48114,
              36.707112
            ],
            [
              -121.479464,
              36.703436
            ],
            [
              -121.476158,
              36.699754
            ],
            [
              -121.4738,
              36.696881
            ],
            [
              -121.471917,
              36.694411
            ],
            [
              -121.469192,
              36.690472
            ],
            [
              -121.468756,
              36.687005
            ],
            [
              -121.465884,
              36.684863
            ],
            [
              -121.462975,
              36.685183
            ],
            [
              -121.458659,
              36.684419
            ],
            [
              -121.457033,
              36.68246
            ],
            [
              -121.452008,
              36.679911
            ],
            [
              -121.44797,
              36.67638
            ],
            [
              -121.442818,
              36.673349
            ],
            [
              -121.439781,
              36.675554
            ],
            [
              -121.433999,
              36.677821
            ],
            [
              -121.425391,
              36.675159
            ],
            [
              -121.420359,
              36.673443
            ],
            [
              -121.413769,
              36.670141
            ],
            [
              -121.411424,
              36.663612
            ],
            [
              -121.408533,
              36.657796
            ],
            [
              -121.401946,
              36.655424
            ],
            [
              -121.395587,
              36.655338
            ],
            [
              -121.390574,
              36.655175
            ],
            [
              -121.384231,
              36.653781
            ],
            [
              -121.379158,
              36.653296
            ],
            [
              -121.374825,
              36.654831
            ],
            [
              -121.369581,
              36.65601
            ],
            [
              -121.365048,
              36.655813
            ],
            [
              -121.361444,
              36.653771
            ],
            [
              -121.35301,
              36.648356
            ],
            [
              -121.350184,
              36.645872
            ],
            [
              -121.346191,
              36.641996
            ],
            [
              -121.342239,
              36.637726
            ],
            [
              -121.341779,
              36.632506
            ],
            [
              -121.337154,
              36.626419
            ],
            [
              -121.333284,
              36.621458
            ],
            [
              -121.327864,
              36.617444
            ],
            [
              -121.319068,
              36.610552
            ],
            [
              -121.319091,
              36.60584
            ],
            [
              -121.323355,
              36.60276
            ],
            [
              -121.325069,
              36.593369
            ],
            [
              -121.324288,
              36.589078
            ],
            [
              -121.327259,
              36.585283
            ],
            [
              -121.325227,
              36.581929
            ],
            [
              -121.324635,
              36.579691
            ],
            [
              -121.325082,
              36.577065
            ],
            [
              -121.328632,
              36.576637
            ],
            [
              -121.332317,
              36.575353
            ],
            [
              -121.332924,
              36.571305
            ],
            [
              -121.331419,
              36.56936
            ],
            [
              -121.331396,
              36.565435
            ],
            [
              -121.331469,
              36.562901
            ],
            [
              -121.329499,
              36.559571
            ],
            [
              -121.327698,
              36.554488
            ],
            [
              -121.32065,
              36.552553
            ],
            [
              -121.313033,
              36.549488
            ],
            [
              -121.307744,
              36.544965
            ],
            [
              -121.303282,
              36.539599
            ],
            [
              -121.300474,
              36.537069
            ],
            [
              -121.298013,
              36.532829
            ],
            [
              -121.296011,
              36.528936
            ],
            [
              -121.298732,
              36.523402
            ],
            [
              -121.303155,
              36.519818
            ],
            [
              -121.307415,
              36.514704
            ],
            [
              -121.310726,
              36.508749
            ],
            [
              -121.311562,
              36.502417
            ],
            [
              -121.309506,
              36.500671
            ],
            [
              -121.306404,
              36.501818
            ],
            [
              -121.30269,
              36.503844
            ],
            [
              -121.296629,
              36.504499
            ],
            [
              -121.290973,
              36.505478
            ],
            [
              -121.284087,
              36.503928
            ],
            [
              -121.275121,
              36.50333
            ],
            [
              -121.267297,
              36.502282
            ],
            [
              -121.259599,
              36.504464
            ],
            [
              -121.24984,
              36.504731
            ],
            [
              -121.247258,
              36.506784
            ],
            [
              -121.240843,
              36.505437
            ],
            [
              -121.233468,
              36.494354
            ],
            [
              -121.232318,
              36.488788
            ],
            [
              -121.228583,
              36.481337
            ],
            [
              -121.23004,
              36.477744
            ],
            [
              -121.22781,
              36.474122
            ],
            [
              -121.223277,
              36.47247
            ],
            [
              -121.218507,
              36.472538
            ],
            [
              -121.21443,
              36.471679
            ],
            [
              -121.207174,
              36.463245
            ],
            [
              -121.202042,
              36.45794
            ],
            [
              -121.196753,
              36.449542
            ],
            [
              -121.155838,
              36.416008
            ],
            [
              -121.11944,
              36.387164
            ],
            [
              -121.077359,
              36.353463
            ],
            [
              -121.040664,
              36.309781
            ],
            [
              -121.040451,
              36.287104
            ],
            [
              -121.037473,
              36.272246
            ],
            [
              -121.035837,
              36.27346
            ],
            [
              -121.03585,
              36.274498
            ],
            [
              -121.033326,
              36.275196
            ],
            [
              -121.030069,
              36.275887
            ],
            [
              -121.02642,
              36.273045
            ],
            [
              -121.025532,
              36.271347
            ],
            [
              -121.027249,
              36.270409
            ],
            [
              -121.028019,
              36.270206
            ],
            [
              -121.028657,
              36.267618
            ],
            [
              -121.027998,
              36.264931
            ],
            [
              -121.027022,
              36.26247
            ],
            [
              -121.027463,
              36.260951
            ],
            [
              -121.025293,
              36.259882
            ],
            [
              -121.023456,
              36.260844
            ],
            [
              -121.020212,
              36.259973
            ],
            [
              -121.018462,
              36.260555
            ],
            [
              -121.016031,
              36.264751
            ],
            [
              -121.015866,
              36.266855
            ],
            [
              -121.013742,
              36.268393
            ],
            [
              -121.013187,
              36.267134
            ],
            [
              -121.012814,
              36.26559
            ],
            [
              -121.010805,
              36.265656
            ],
            [
              -121.010819,
              36.266716
            ],
            [
              -121.010886,
              36.267644
            ],
            [
              -121.008562,
              36.268387
            ],
            [
              -121.005161,
              36.267114
            ],
            [
              -121.002091,
              36.268354
            ],
            [
              -121.001346,
              36.269749
            ],
            [
              -120.998057,
              36.270508
            ],
            [
              -120.994979,
              36.26992
            ],
            [
              -120.992272,
              36.270234
            ],
            [
              -120.989595,
              36.274004
            ],
            [
              -120.987338,
              36.277691
            ],
            [
              -120.987981,
              36.284125
            ],
            [
              -120.985377,
              36.289745
            ],
            [
              -120.985731,
              36.291426
            ],
            [
              -120.98546,
              36.292951
            ],
            [
              -120.983973,
              36.291729
            ],
            [
              -120.979996,
              36.290758
            ],
            [
              -120.977363,
              36.290473
            ],
            [
              -120.973787,
              36.29032
            ],
            [
              -120.971567,
              36.287651
            ],
            [
              -120.966171,
              36.284136
            ],
            [
              -120.964521,
              36.280868
            ],
            [
              -120.958726,
              36.279836
            ],
            [
              -120.957083,
              36.276557
            ],
            [
              -120.952673,
              36.275074
            ],
            [
              -120.95004,
              36.276893
            ],
            [
              -120.944735,
              36.279453
            ],
            [
              -120.941125,
              36.281607
            ],
            [
              -120.939117,
              36.28421
            ],
            [
              -120.94046,
              36.285952
            ],
            [
              -120.938536,
              36.288186
            ],
            [
              -120.935452,
              36.28955
            ],
            [
              -120.935255,
              36.29155
            ],
            [
              -120.934918,
              36.295425
            ],
            [
              -120.9322,
              36.300305
            ],
            [
              -120.929078,
              36.300976
            ],
            [
              -120.925786,
              36.302782
            ],
            [
              -120.926129,
              36.305374
            ],
            [
              -120.924679,
              36.308646
            ],
            [
              -120.92083,
              36.31094
            ],
            [
              -120.917314,
              36.310105
            ],
            [
              -120.913764,
              36.308807
            ],
            [
              -120.910236,
              36.305307
            ],
            [
              -120.906674,
              36.303828
            ],
            [
              -120.904747,
              36.30268
            ],
            [
              -120.898905,
              36.300635
            ],
            [
              -120.895628,
              36.296572
            ],
            [
              -120.891587,
              36.291908
            ],
            [
              -120.890508,
              36.291072
            ],
            [
              -120.887771,
              36.291571
            ],
            [
              -120.886239,
              36.293257
            ],
            [
              -120.882717,
              36.293738
            ],
            [
              -120.880267,
              36.294916
            ],
            [
              -120.876017,
              36.295058
            ],
            [
              -120.872959,
              36.294256
            ],
            [
              -120.869077,
              36.293643
            ],
            [
              -120.865412,
              36.292534
            ],
            [
              -120.863389,
              36.291068
            ],
            [
              -120.861681,
              36.290178
            ],
            [
              -120.860776,
              36.29057
            ],
            [
              -120.85992,
              36.288526
            ],
            [
              -120.857062,
              36.286462
            ],
            [
              -120.854748,
              36.285232
            ],
            [
              -120.852794,
              36.285733
            ],
            [
              -120.851361,
              36.283854
            ],
            [
              -120.849399,
              36.281614
            ],
            [
              -120.844466,
              36.279896
            ],
            [
              -120.841018,
              36.277183
            ],
            [
              -120.839512,
              36.274157
            ],
            [
              -120.837645,
              36.272201
            ],
            [
              -120.837706,
              36.270822
            ],
            [
              -120.838094,
              36.271045
            ],
            [
              -120.839354,
              36.270574
            ],
            [
              -120.838465,
              36.268822
            ],
            [
              -120.837033,
              36.266235
            ],
            [
              -120.837032,
              36.264142
            ],
            [
              -120.83619,
              36.262258
            ],
            [
              -120.835058,
              36.262456
            ],
            [
              -120.833002,
              36.261644
            ],
            [
              -120.833354,
              36.260451
            ],
            [
              -120.831719,
              36.259216
            ],
            [
              -120.830254,
              36.25936
            ],
            [
              -120.828687,
              36.258359
            ],
            [
              -120.829054,
              36.258315
            ],
            [
              -120.829434,
              36.258112
            ],
            [
              -120.82824,
              36.257046
            ],
            [
              -120.826932,
              36.257093
            ],
            [
              -120.826789,
              36.257493
            ],
            [
              -120.825746,
              36.25685
            ],
            [
              -120.826075,
              36.255904
            ],
            [
              -120.824729,
              36.255623
            ],
            [
              -120.824729,
              36.256046
            ],
            [
              -120.82263,
              36.256701
            ],
            [
              -120.821585,
              36.255992
            ],
            [
              -120.817413,
              36.253141
            ],
            [
              -120.815813,
              36.253306
            ],
            [
              -120.813948,
              36.252219
            ],
            [
              -120.813609,
              36.251323
            ],
            [
              -120.812693,
              36.250681
            ],
            [
              -120.81051,
              36.249966
            ],
            [
              -120.810667,
              36.249156
            ],
            [
              -120.8118,
              36.247354
            ],
            [
              -120.809121,
              36.244349
            ],
            [
              -120.806701,
              36.241553
            ],
            [
              -120.802212,
              36.240486
            ],
            [
              -120.801423,
              36.238177
            ],
            [
              -120.797563,
              36.235719
            ],
            [
              -120.79371,
              36.234305
            ],
            [
              -120.791777,
              36.234117
            ],
            [
              -120.788747,
              36.233062
            ],
            [
              -120.787907,
              36.230452
            ],
            [
              -120.782091,
              36.227248
            ],
            [
              -120.779751,
              36.222707
            ],
            [
              -120.776392,
              36.220704
            ],
            [
              -120.769263,
              36.216647
            ],
            [
              -120.765632,
              36.212327
            ],
            [
              -120.76379,
              36.209085
            ],
            [
              -120.760378,
              36.203085
            ],
            [
              -120.757153,
              36.202817
            ],
            [
              -120.754903,
              36.201198
            ],
            [
              -120.749123,
              36.198827
            ],
            [
              -120.744389,
              36.198777
            ],
            [
              -120.743793,
              36.199121
            ],
            [
              -120.732046,
              36.203981
            ],
            [
              -120.718169,
              36.196848
            ],
            [
              -120.706121,
              36.20373
            ],
            [
              -120.706411,
              36.227692
            ],
            [
              -120.717696,
              36.260012
            ],
            [
              -120.732108,
              36.275465
            ],
            [
              -120.743114,
              36.298059
            ],
            [
              -120.75834,
              36.308604
            ],
            [
              -120.746654,
              36.310234
            ],
            [
              -120.722584,
              36.292656
            ],
            [
              -120.69773,
              36.282884
            ],
            [
              -120.678582,
              36.267319
            ],
            [
              -120.672622,
              36.280489
            ],
            [
              -120.682723,
              36.294441
            ],
            [
              -120.658806,
              36.316235
            ],
            [
              -120.630009,
              36.316908
            ],
            [
              -120.596562,
              36.328488
            ],
            [
              -120.603706,
              36.488242
            ],
            [
              -120.918731,
              36.740381
            ],
            [
              -120.933682,
              36.751895
            ],
            [
              -121.141523,
              36.836656
            ],
            [
              -121.152808,
              36.865601
            ],
            [
              -121.163588,
              36.869245
            ],
            [
              -121.169495,
              36.885322
            ],
            [
              -121.184297,
              36.896385
            ],
            [
              -121.183885,
              36.908096
            ],
            [
              -121.198274,
              36.914338
            ],
            [
              -121.221713,
              36.910362
            ],
            [
              -121.234683,
              36.926894
            ],
            [
              -121.215406,
              36.961248
            ],
            [
              -121.418246,
              36.960549
            ],
            [
              -121.451972,
              36.98884
            ],
            [
              -121.467939,
              36.976772
            ],
            [
              -121.488949,
              36.983148
            ],
            [
              -121.501488,
              36.971895
            ],
            [
              -121.50528,
              36.964216
            ],
            [
              -121.512263,
              36.957997
            ],
            [
              -121.513813,
              36.945155
            ],
            [
              -121.523591,
              36.93709
            ],
            [
              -121.534463,
              36.930933
            ],
            [
              -121.540754,
              36.924628
            ],
            [
              -121.540016,
              36.920765
            ],
            [
              -121.548009,
              36.917391
            ],
            [
              -121.560272,
              36.897111
            ],
            [
              -121.575402,
              36.893033
            ],
            [
              -121.581354,
              36.899152
            ],
            [
              -121.607354,
              36.899452
            ],
            [
              -121.629457,
              36.911688
            ],
            [
              -121.644001,
              36.893996
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "06",
      "COUNTYFP": "089",
      "COUNTYNS": "01682610",
      "AFFGEOID": "0500000US06089",
      "GEOID": "06089",
      "NAME": "Shasta",
      "LSAD": "06",
      "ALAND": 9778230094,
      "AWATER": 186479466,
      "County_state": "Shasta,06"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -123.068141,
              40.306282
            ],
            [
              -123.067393,
              40.302321
            ],
            [
              -123.065897,
              40.300798
            ],
            [
              -123.065687,
              40.299635
            ],
            [
              -123.065034,
              40.298461
            ],
            [
              -123.065818,
              40.296231
            ],
            [
              -123.065269,
              40.291765
            ],
            [
              -123.064707,
              40.288315
            ],
            [
              -123.065426,
              40.286971
            ],
            [
              -123.059268,
              40.285484
            ],
            [
              -123.049697,
              40.28926
            ],
            [
              -123.046648,
              40.291836
            ],
            [
              -123.040839,
              40.291296
            ],
            [
              -123.037216,
              40.294662
            ],
            [
              -123.033915,
              40.298649
            ],
            [
              -123.029488,
              40.300158
            ],
            [
              -123.027328,
              40.30231
            ],
            [
              -123.023382,
              40.303852
            ],
            [
              -123.020678,
              40.302594
            ],
            [
              -123.014394,
              40.303108
            ],
            [
              -123.012111,
              40.306292
            ],
            [
              -123.007942,
              40.306911
            ],
            [
              -123.001779,
              40.307748
            ],
            [
              -122.999134,
              40.308188
            ],
            [
              -122.998854,
              40.307859
            ],
            [
              -122.997879,
              40.308062
            ],
            [
              -122.996796,
              40.308376
            ],
            [
              -122.995692,
              40.309447
            ],
            [
              -122.993016,
              40.309756
            ],
            [
              -122.99136,
              40.31035
            ],
            [
              -122.988999,
              40.310631
            ],
            [
              -122.988347,
              40.311302
            ],
            [
              -122.987221,
              40.311796
            ],
            [
              -122.98674,
              40.311522
            ],
            [
              -122.985707,
              40.311715
            ],
            [
              -122.985198,
              40.312149
            ],
            [
              -122.9839,
              40.313001
            ],
            [
              -122.982724,
              40.313545
            ],
            [
              -122.980923,
              40.313282
            ],
            [
              -122.978606,
              40.313574
            ],
            [
              -122.977602,
              40.314365
            ],
            [
              -122.976196,
              40.315035
            ],
            [
              -122.975356,
              40.314487
            ],
            [
              -122.973369,
              40.313532
            ],
            [
              -122.972134,
              40.312263
            ],
            [
              -122.970727,
              40.310967
            ],
            [
              -122.969306,
              40.310589
            ],
            [
              -122.967154,
              40.31037
            ],
            [
              -122.965475,
              40.310623
            ],
            [
              -122.963273,
              40.310607
            ],
            [
              -122.95937,
              40.310685
            ],
            [
              -122.956336,
              40.310537
            ],
            [
              -122.954233,
              40.309686
            ],
            [
              -122.951974,
              40.310169
            ],
            [
              -122.950625,
              40.309955
            ],
            [
              -122.950151,
              40.308923
            ],
            [
              -122.950187,
              40.30828
            ],
            [
              -122.949082,
              40.308066
            ],
            [
              -122.947741,
              40.308088
            ],
            [
              -122.946363,
              40.309319
            ],
            [
              -122.946414,
              40.310165
            ],
            [
              -122.945223,
              40.310593
            ],
            [
              -122.944627,
              40.310302
            ],
            [
              -122.943437,
              40.310549
            ],
            [
              -122.942848,
              40.311801
            ],
            [
              -122.94033,
              40.312307
            ],
            [
              -122.938336,
              40.312076
            ],
            [
              -122.936341,
              40.312411
            ],
            [
              -122.935488,
              40.313032
            ],
            [
              -122.93411,
              40.313097
            ],
            [
              -122.934081,
              40.312246
            ],
            [
              -122.933213,
              40.310615
            ],
            [
              -122.931628,
              40.310187
            ],
            [
              -122.929756,
              40.309341
            ],
            [
              -122.929992,
              40.308841
            ],
            [
              -122.930157,
              40.307869
            ],
            [
              -122.928988,
              40.30755
            ],
            [
              -122.926973,
              40.307034
            ],
            [
              -122.925983,
              40.306682
            ],
            [
              -122.924526,
              40.306627
            ],
            [
              -122.924318,
              40.307143
            ],
            [
              -122.923866,
              40.307605
            ],
            [
              -122.922754,
              40.307099
            ],
            [
              -122.92142,
              40.307236
            ],
            [
              -122.920193,
              40.306681
            ],
            [
              -122.918795,
              40.306692
            ],
            [
              -122.91789,
              40.307642
            ],
            [
              -122.918156,
              40.308219
            ],
            [
              -122.917323,
              40.30896
            ],
            [
              -122.916512,
              40.309361
            ],
            [
              -122.916448,
              40.31052
            ],
            [
              -122.914647,
              40.310811
            ],
            [
              -122.914274,
              40.310464
            ],
            [
              -122.913119,
              40.310382
            ],
            [
              -122.912631,
              40.311024
            ],
            [
              -122.91154,
              40.31159
            ],
            [
              -122.91131,
              40.312221
            ],
            [
              -122.911439,
              40.31265
            ],
            [
              -122.910843,
              40.312996
            ],
            [
              -122.910521,
              40.312748
            ],
            [
              -122.909351,
              40.312935
            ],
            [
              -122.908849,
              40.313687
            ],
            [
              -122.908805,
              40.315703
            ],
            [
              -122.907111,
              40.316598
            ],
            [
              -122.907068,
              40.3173
            ],
            [
              -122.90564,
              40.317646
            ],
            [
              -122.904765,
              40.316372
            ],
            [
              -122.903811,
              40.31602
            ],
            [
              -122.903129,
              40.317025
            ],
            [
              -122.903035,
              40.318282
            ],
            [
              -122.902202,
              40.319013
            ],
            [
              -122.902001,
              40.320259
            ],
            [
              -122.90241,
              40.3211
            ],
            [
              -122.901885,
              40.321879
            ],
            [
              -122.901254,
              40.32172
            ],
            [
              -122.900981,
              40.322555
            ],
            [
              -122.901225,
              40.322725
            ],
            [
              -122.900851,
              40.323477
            ],
            [
              -122.899911,
              40.324257
            ],
            [
              -122.899021,
              40.324256
            ],
            [
              -122.898059,
              40.324696
            ],
            [
              -122.897894,
              40.325442
            ],
            [
              -122.896472,
              40.327177
            ],
            [
              -122.895186,
              40.328731
            ],
            [
              -122.89398,
              40.33028
            ],
            [
              -122.892034,
              40.332119
            ],
            [
              -122.890224,
              40.333947
            ],
            [
              -122.8882,
              40.334731
            ],
            [
              -122.887064,
              40.336906
            ],
            [
              -122.884946,
              40.338366
            ],
            [
              -122.883402,
              40.339364
            ],
            [
              -122.883027,
              40.341006
            ],
            [
              -122.883551,
              40.341583
            ],
            [
              -122.883723,
              40.342396
            ],
            [
              -122.882459,
              40.342566
            ],
            [
              -122.88124,
              40.341972
            ],
            [
              -122.880241,
              40.343196
            ],
            [
              -122.880448,
              40.344267
            ],
            [
              -122.879069,
              40.344887
            ],
            [
              -122.8781,
              40.345052
            ],
            [
              -122.878329,
              40.34565
            ],
            [
              -122.877661,
              40.346732
            ],
            [
              -122.876383,
              40.346847
            ],
            [
              -122.874283,
              40.348028
            ],
            [
              -122.87279,
              40.34854
            ],
            [
              -122.871247,
              40.34836
            ],
            [
              -122.869947,
              40.34719
            ],
            [
              -122.867083,
              40.347302
            ],
            [
              -122.86559,
              40.347912
            ],
            [
              -122.863845,
              40.347039
            ],
            [
              -122.863198,
              40.345672
            ],
            [
              -122.860807,
              40.344932
            ],
            [
              -122.857139,
              40.344597
            ],
            [
              -122.854813,
              40.343424
            ],
            [
              -122.852617,
              40.343551
            ],
            [
              -122.849179,
              40.343799
            ],
            [
              -122.845404,
              40.344899
            ],
            [
              -122.842138,
              40.345207
            ],
            [
              -122.839109,
              40.345592
            ],
            [
              -122.837272,
              40.347751
            ],
            [
              -122.834861,
              40.348614
            ],
            [
              -122.833619,
              40.348032
            ],
            [
              -122.831803,
              40.348565
            ],
            [
              -122.830396,
              40.349449
            ],
            [
              -122.826563,
              40.349801
            ],
            [
              -122.823455,
              40.349659
            ],
            [
              -122.820074,
              40.350466
            ],
            [
              -122.816212,
              40.350994
            ],
            [
              -122.812831,
              40.351268
            ],
            [
              -122.810017,
              40.35374
            ],
            [
              -122.807189,
              40.355393
            ],
            [
              -122.803427,
              40.355618
            ],
            [
              -122.800218,
              40.355497
            ],
            [
              -122.79627,
              40.355359
            ],
            [
              -122.792522,
              40.356151
            ],
            [
              -122.789981,
              40.355627
            ],
            [
              -122.787217,
              40.355621
            ],
            [
              -122.784726,
              40.356449
            ],
            [
              -122.782571,
              40.358108
            ],
            [
              -122.778486,
              40.35854
            ],
            [
              -122.775148,
              40.358698
            ],
            [
              -122.773,
              40.359708
            ],
            [
              -122.770458,
              40.360822
            ],
            [
              -122.76638,
              40.361683
            ],
            [
              -122.762883,
              40.362417
            ],
            [
              -122.760319,
              40.3629
            ],
            [
              -122.75884,
              40.363289
            ],
            [
              -122.757081,
              40.362755
            ],
            [
              -122.756472,
              40.361794
            ],
            [
              -122.755123,
              40.360909
            ],
            [
              -122.753242,
              40.36043
            ],
            [
              -122.751927,
              40.36161
            ],
            [
              -122.752436,
              40.362835
            ],
            [
              -122.750963,
              40.364554
            ],
            [
              -122.747516,
              40.365449
            ],
            [
              -122.744479,
              40.365341
            ],
            [
              -122.738794,
              40.364297
            ],
            [
              -122.735988,
              40.361741
            ],
            [
              -122.732583,
              40.35946
            ],
            [
              -122.731177,
              40.358728
            ],
            [
              -122.731288,
              40.35696
            ],
            [
              -122.731968,
              40.355261
            ],
            [
              -122.731845,
              40.35109
            ],
            [
              -122.732435,
              40.349219
            ],
            [
              -122.731132,
              40.346914
            ],
            [
              -122.72859,
              40.346835
            ],
            [
              -122.724597,
              40.347323
            ],
            [
              -122.720889,
              40.348142
            ],
            [
              -122.716418,
              40.347811
            ],
            [
              -122.713741,
              40.348344
            ],
            [
              -122.709225,
              40.347967
            ],
            [
              -122.705675,
              40.345091
            ],
            [
              -122.700461,
              40.343271
            ],
            [
              -122.697395,
              40.34258
            ],
            [
              -122.692775,
              40.340801
            ],
            [
              -122.689934,
              40.338896
            ],
            [
              -122.687608,
              40.337162
            ],
            [
              -122.678799,
              40.336565
            ],
            [
              -122.669458,
              40.330032
            ],
            [
              -122.654153,
              40.328824
            ],
            [
              -122.643024,
              40.333205
            ],
            [
              -122.632703,
              40.338121
            ],
            [
              -122.623883,
              40.344046
            ],
            [
              -122.613897,
              40.346613
            ],
            [
              -122.599785,
              40.354697
            ],
            [
              -122.583216,
              40.361382
            ],
            [
              -122.58226,
              40.365021
            ],
            [
              -122.572208,
              40.367127
            ],
            [
              -122.563262,
              40.365892
            ],
            [
              -122.55327,
              40.372962
            ],
            [
              -122.546149,
              40.377526
            ],
            [
              -122.542168,
              40.381347
            ],
            [
              -122.531366,
              40.382214
            ],
            [
              -122.527354,
              40.386149
            ],
            [
              -122.522893,
              40.390266
            ],
            [
              -122.524747,
              40.392395
            ],
            [
              -122.516279,
              40.39079
            ],
            [
              -122.506403,
              40.389458
            ],
            [
              -122.496043,
              40.382914
            ],
            [
              -122.482217,
              40.380769
            ],
            [
              -122.475396,
              40.384181
            ],
            [
              -122.466179,
              40.380958
            ],
            [
              -122.461449,
              40.375444
            ],
            [
              -122.44951,
              40.375927
            ],
            [
              -122.428865,
              40.375515
            ],
            [
              -122.415281,
              40.377664
            ],
            [
              -122.402803,
              40.374284
            ],
            [
              -122.389789,
              40.374051
            ],
            [
              -122.375396,
              40.372718
            ],
            [
              -122.360137,
              40.372908
            ],
            [
              -122.345625,
              40.371106
            ],
            [
              -122.33339,
              40.373877
            ],
            [
              -122.321033,
              40.374875
            ],
            [
              -122.302274,
              40.374359
            ],
            [
              -122.291472,
              40.379222
            ],
            [
              -122.280852,
              40.375787
            ],
            [
              -122.264393,
              40.379786
            ],
            [
              -122.250647,
              40.387127
            ],
            [
              -122.230297,
              40.388805
            ],
            [
              -122.217008,
              40.384736
            ],
            [
              -122.205213,
              40.379868
            ],
            [
              -122.197919,
              40.383149
            ],
            [
              -122.195634,
              40.388138
            ],
            [
              -122.192075,
              40.387928
            ],
            [
              -122.190197,
              40.384092
            ],
            [
              -122.177865,
              40.379744
            ],
            [
              -122.174789,
              40.379615
            ],
            [
              -122.175636,
              40.381496
            ],
            [
              -122.179398,
              40.384151
            ],
            [
              -122.184608,
              40.384505
            ],
            [
              -122.186416,
              40.388255
            ],
            [
              -122.18388,
              40.390556
            ],
            [
              -122.177283,
              40.392127
            ],
            [
              -122.171279,
              40.393679
            ],
            [
              -122.16409,
              40.393234
            ],
            [
              -122.160464,
              40.394969
            ],
            [
              -122.153381,
              40.39731
            ],
            [
              -122.145281,
              40.398066
            ],
            [
              -122.13588,
              40.397911
            ],
            [
              -122.129444,
              40.400653
            ],
            [
              -122.129786,
              40.402585
            ],
            [
              -122.126781,
              40.403903
            ],
            [
              -122.124501,
              40.402978
            ],
            [
              -122.120269,
              40.404266
            ],
            [
              -122.115706,
              40.405659
            ],
            [
              -122.109551,
              40.408539
            ],
            [
              -122.101726,
              40.412671
            ],
            [
              -122.100201,
              40.413773
            ],
            [
              -122.098393,
              40.413304
            ],
            [
              -122.094925,
              40.410505
            ],
            [
              -122.093496,
              40.412292
            ],
            [
              -122.092434,
              40.414066
            ],
            [
              -122.090874,
              40.41277
            ],
            [
              -122.090396,
              40.41147
            ],
            [
              -122.085022,
              40.412714
            ],
            [
              -122.078656,
              40.412676
            ],
            [
              -122.067045,
              40.415675
            ],
            [
              -122.060449,
              40.416618
            ],
            [
              -122.055476,
              40.418428
            ],
            [
              -122.049424,
              40.419886
            ],
            [
              -122.039784,
              40.41843
            ],
            [
              -122.033302,
              40.418124
            ],
            [
              -122.030861,
              40.419161
            ],
            [
              -122.030086,
              40.420987
            ],
            [
              -122.020305,
              40.424153
            ],
            [
              -122.018142,
              40.422217
            ],
            [
              -122.013886,
              40.423923
            ],
            [
              -122.010316,
              40.426558
            ],
            [
              -122.006396,
              40.426186
            ],
            [
              -122.001416,
              40.424666
            ],
            [
              -121.999774,
              40.423145
            ],
            [
              -121.996727,
              40.423411
            ],
            [
              -121.995528,
              40.424504
            ],
            [
              -121.993783,
              40.425708
            ],
            [
              -121.992201,
              40.424731
            ],
            [
              -121.992474,
              40.424033
            ],
            [
              -121.990879,
              40.423886
            ],
            [
              -121.989204,
              40.423392
            ],
            [
              -121.985489,
              40.423992
            ],
            [
              -121.983592,
              40.422983
            ],
            [
              -121.979065,
              40.422358
            ],
            [
              -121.975586,
              40.421596
            ],
            [
              -121.972382,
              40.421982
            ],
            [
              -121.967338,
              40.422131
            ],
            [
              -121.962782,
              40.420919
            ],
            [
              -121.95985,
              40.41914
            ],
            [
              -121.955654,
              40.419042
            ],
            [
              -121.951616,
              40.417867
            ],
            [
              -121.950437,
              40.41885
            ],
            [
              -121.948634,
              40.418125
            ],
            [
              -121.946751,
              40.416972
            ],
            [
              -121.942153,
              40.415616
            ],
            [
              -121.93961,
              40.416198
            ],
            [
              -121.936161,
              40.417412
            ],
            [
              -121.93264,
              40.418065
            ],
            [
              -121.928739,
              40.418301
            ],
            [
              -121.924542,
              40.42041
            ],
            [
              -121.921351,
              40.423309
            ],
            [
              -121.918455,
              40.42433
            ],
            [
              -121.915904,
              40.425274
            ],
            [
              -121.914797,
              40.425993
            ],
            [
              -121.913742,
              40.42462
            ],
            [
              -121.911335,
              40.423153
            ],
            [
              -121.906644,
              40.421669
            ],
            [
              -121.903381,
              40.422036
            ],
            [
              -121.901902,
              40.421031
            ],
            [
              -121.899738,
              40.422408
            ],
            [
              -121.898502,
              40.423402
            ],
            [
              -121.896446,
              40.425411
            ],
            [
              -121.895777,
              40.426377
            ],
            [
              -121.893577,
              40.427145
            ],
            [
              -121.891378,
              40.427243
            ],
            [
              -121.888632,
              40.428664
            ],
            [
              -121.886281,
              40.430646
            ],
            [
              -121.883239,
              40.433269
            ],
            [
              -121.880754,
              40.435206
            ],
            [
              -121.877034,
              40.436912
            ],
            [
              -121.873204,
              40.436231
            ],
            [
              -121.86986,
              40.437407
            ],
            [
              -121.866912,
              40.438701
            ],
            [
              -121.863592,
              40.440596
            ],
            [
              -121.859786,
              40.441014
            ],
            [
              -121.857162,
              40.441837
            ],
            [
              -121.854648,
              40.441445
            ],
            [
              -121.85135,
              40.442596
            ],
            [
              -121.847333,
              40.44107
            ],
            [
              -121.843861,
              40.440247
            ],
            [
              -121.840959,
              40.439894
            ],
            [
              -121.838442,
              40.437536
            ],
            [
              -121.834734,
              40.437703
            ],
            [
              -121.830127,
              40.438007
            ],
            [
              -121.825939,
              40.439145
            ],
            [
              -121.821614,
              40.440349
            ],
            [
              -121.817689,
              40.440875
            ],
            [
              -121.814566,
              40.441203
            ],
            [
              -121.810051,
              40.443024
            ],
            [
              -121.807932,
              40.44425
            ],
            [
              -121.805983,
              40.444505
            ],
            [
              -121.804075,
              40.443596
            ],
            [
              -121.801808,
              40.443375
            ],
            [
              -121.798511,
              40.443122
            ],
            [
              -121.794624,
              40.442879
            ],
            [
              -121.791858,
              40.442651
            ],
            [
              -121.789846,
              40.442475
            ],
            [
              -121.788905,
              40.443489
            ],
            [
              -121.786682,
              40.443186
            ],
            [
              -121.784809,
              40.44249
            ],
            [
              -121.782568,
              40.440957
            ],
            [
              -121.780298,
              40.439695
            ],
            [
              -121.778442,
              40.438212
            ],
            [
              -121.776476,
              40.438291
            ],
            [
              -121.775414,
              40.437819
            ],
            [
              -121.772219,
              40.438302
            ],
            [
              -121.767433,
              40.437364
            ],
            [
              -121.762546,
              40.436679
            ],
            [
              -121.756224,
              40.436391
            ],
            [
              -121.751099,
              40.436399
            ],
            [
              -121.747937,
              40.436804
            ],
            [
              -121.745667,
              40.43675
            ],
            [
              -121.743577,
              40.436501
            ],
            [
              -121.741182,
              40.436185
            ],
            [
              -121.738101,
              40.435846
            ],
            [
              -121.734973,
              40.435709
            ],
            [
              -121.73167,
              40.436913
            ],
            [
              -121.729686,
              40.438912
            ],
            [
              -121.726421,
              40.441114
            ],
            [
              -121.724684,
              40.443235
            ],
            [
              -121.72245,
              40.444876
            ],
            [
              -121.720964,
              40.447217
            ],
            [
              -121.718744,
              40.448757
            ],
            [
              -121.716165,
              40.448432
            ],
            [
              -121.714344,
              40.449021
            ],
            [
              -121.711614,
              40.449708
            ],
            [
              -121.708088,
              40.45068
            ],
            [
              -121.70449,
              40.450171
            ],
            [
              -121.702684,
              40.449101
            ],
            [
              -121.699851,
              40.44852
            ],
            [
              -121.695448,
              40.44965
            ],
            [
              -121.691918,
              40.451406
            ],
            [
              -121.687629,
              40.452632
            ],
            [
              -121.685277,
              40.452999
            ],
            [
              -121.682906,
              40.452164
            ],
            [
              -121.681074,
              40.451296
            ],
            [
              -121.678339,
              40.450512
            ],
            [
              -121.675275,
              40.450303
            ],
            [
              -121.673771,
              40.450669
            ],
            [
              -121.671666,
              40.449821
            ],
            [
              -121.671615,
              40.44847
            ],
            [
              -121.669656,
              40.447155
            ],
            [
              -121.667198,
              40.44642
            ],
            [
              -121.665448,
              40.445762
            ],
            [
              -121.662915,
              40.445117
            ],
            [
              -121.660446,
              40.443795
            ],
            [
              -121.659353,
              40.442417
            ],
            [
              -121.657493,
              40.441659
            ],
            [
              -121.656137,
              40.441877
            ],
            [
              -121.654103,
              40.440521
            ],
            [
              -121.651958,
              40.438384
            ],
            [
              -121.648798,
              40.436148
            ],
            [
              -121.646584,
              40.434872
            ],
            [
              -121.643354,
              40.43556
            ],
            [
              -121.639713,
              40.436534
            ],
            [
              -121.63749,
              40.437043
            ],
            [
              -121.633431,
              40.437546
            ],
            [
              -121.62996,
              40.437733
            ],
            [
              -121.626275,
              40.438108
            ],
            [
              -121.621373,
              40.437167
            ],
            [
              -121.61816,
              40.437889
            ],
            [
              -121.612899,
              40.43878
            ],
            [
              -121.603345,
              40.433718
            ],
            [
              -121.587914,
              40.432293
            ],
            [
              -121.581893,
              40.435813
            ],
            [
              -121.569044,
              40.444211
            ],
            [
              -121.522988,
              40.445378
            ],
            [
              -121.497635,
              40.445591
            ],
            [
              -121.366111,
              40.445927
            ],
            [
              -121.346531,
              40.449715
            ],
            [
              -121.327826,
              40.445367
            ],
            [
              -121.32668,
              40.497574
            ],
            [
              -121.319976,
              40.905886
            ],
            [
              -121.332338,
              40.905394
            ],
            [
              -121.330973,
              40.934572
            ],
            [
              -121.331786,
              41.183886
            ],
            [
              -121.446495,
              41.183484
            ],
            [
              -122.126114,
              41.182969
            ],
            [
              -122.147626,
              41.184554
            ],
            [
              -122.294573,
              41.184978
            ],
            [
              -122.498376,
              41.182675
            ],
            [
              -122.492554,
              41.175972
            ],
            [
              -122.469313,
              41.170165
            ],
            [
              -122.447632,
              41.159012
            ],
            [
              -122.448185,
              41.137574
            ],
            [
              -122.451888,
              41.114698
            ],
            [
              -122.461728,
              41.09738
            ],
            [
              -122.484206,
              41.090028
            ],
            [
              -122.510818,
              41.087456
            ],
            [
              -122.526035,
              41.078829
            ],
            [
              -122.529488,
              41.064582
            ],
            [
              -122.52746,
              41.043481
            ],
            [
              -122.527092,
              41.021085
            ],
            [
              -122.532105,
              41.009853
            ],
            [
              -122.544056,
              41.004255
            ],
            [
              -122.555243,
              41.002745
            ],
            [
              -122.563026,
              40.99368
            ],
            [
              -122.569181,
              40.984988
            ],
            [
              -122.584517,
              40.983966
            ],
            [
              -122.587301,
              40.985183
            ],
            [
              -122.589699,
              40.982801
            ],
            [
              -122.594883,
              40.977903
            ],
            [
              -122.59943,
              40.973286
            ],
            [
              -122.60125,
              40.971888
            ],
            [
              -122.601148,
              40.967351
            ],
            [
              -122.603204,
              40.960606
            ],
            [
              -122.606578,
              40.952709
            ],
            [
              -122.605131,
              40.949038
            ],
            [
              -122.603509,
              40.942237
            ],
            [
              -122.606768,
              40.938618
            ],
            [
              -122.607694,
              40.93048
            ],
            [
              -122.612448,
              40.921762
            ],
            [
              -122.604797,
              40.909465
            ],
            [
              -122.601485,
              40.9034
            ],
            [
              -122.604523,
              40.895299
            ],
            [
              -122.605909,
              40.890104
            ],
            [
              -122.607763,
              40.887085
            ],
            [
              -122.611453,
              40.883857
            ],
            [
              -122.619622,
              40.879676
            ],
            [
              -122.62627,
              40.874857
            ],
            [
              -122.626062,
              40.871336
            ],
            [
              -122.629137,
              40.865178
            ],
            [
              -122.63438,
              40.861289
            ],
            [
              -122.638636,
              40.853962
            ],
            [
              -122.645419,
              40.843781
            ],
            [
              -122.656279,
              40.838526
            ],
            [
              -122.661561,
              40.831136
            ],
            [
              -122.665167,
              40.826882
            ],
            [
              -122.661152,
              40.818469
            ],
            [
              -122.656186,
              40.810461
            ],
            [
              -122.656758,
              40.804784
            ],
            [
              -122.660824,
              40.798386
            ],
            [
              -122.660338,
              40.791256
            ],
            [
              -122.660369,
              40.785595
            ],
            [
              -122.665572,
              40.782076
            ],
            [
              -122.667707,
              40.779142
            ],
            [
              -122.667372,
              40.777126
            ],
            [
              -122.677124,
              40.769359
            ],
            [
              -122.683427,
              40.76594
            ],
            [
              -122.687781,
              40.76448
            ],
            [
              -122.691709,
              40.761381
            ],
            [
              -122.697645,
              40.759917
            ],
            [
              -122.700929,
              40.756685
            ],
            [
              -122.701028,
              40.75463
            ],
            [
              -122.707158,
              40.749382
            ],
            [
              -122.713215,
              40.747711
            ],
            [
              -122.718916,
              40.741582
            ],
            [
              -122.714974,
              40.735265
            ],
            [
              -122.709436,
              40.729123
            ],
            [
              -122.706741,
              40.724656
            ],
            [
              -122.709469,
              40.719976
            ],
            [
              -122.711616,
              40.710537
            ],
            [
              -122.719242,
              40.705894
            ],
            [
              -122.722088,
              40.699169
            ],
            [
              -122.723739,
              40.694742
            ],
            [
              -122.728937,
              40.692609
            ],
            [
              -122.736512,
              40.69262
            ],
            [
              -122.740629,
              40.694495
            ],
            [
              -122.745579,
              40.693716
            ],
            [
              -122.748695,
              40.692063
            ],
            [
              -122.751634,
              40.686402
            ],
            [
              -122.749491,
              40.683414
            ],
            [
              -122.74373,
              40.679365
            ],
            [
              -122.743006,
              40.674921
            ],
            [
              -122.742952,
              40.673002
            ],
            [
              -122.742528,
              40.671263
            ],
            [
              -122.741801,
              40.668905
            ],
            [
              -122.741336,
              40.665747
            ],
            [
              -122.736894,
              40.663805
            ],
            [
              -122.734941,
              40.662958
            ],
            [
              -122.733482,
              40.659933
            ],
            [
              -122.734091,
              40.658274
            ],
            [
              -122.730445,
              40.654019
            ],
            [
              -122.728038,
              40.651701
            ],
            [
              -122.729594,
              40.646209
            ],
            [
              -122.733696,
              40.641623
            ],
            [
              -122.73576,
              40.63845
            ],
            [
              -122.729873,
              40.634924
            ],
            [
              -122.724617,
              40.634705
            ],
            [
              -122.715622,
              40.634045
            ],
            [
              -122.709562,
              40.627409
            ],
            [
              -122.71075,
              40.621644
            ],
            [
              -122.710854,
              40.613215
            ],
            [
              -122.70617,
              40.609371
            ],
            [
              -122.704987,
              40.6032
            ],
            [
              -122.70282,
              40.597204
            ],
            [
              -122.701982,
              40.593034
            ],
            [
              -122.69989,
              40.590324
            ],
            [
              -122.696243,
              40.5826
            ],
            [
              -122.693346,
              40.577717
            ],
            [
              -122.696781,
              40.57157
            ],
            [
              -122.704313,
              40.569527
            ],
            [
              -122.717614,
              40.572158
            ],
            [
              -122.728087,
              40.572785
            ],
            [
              -122.740263,
              40.565071
            ],
            [
              -122.742499,
              40.558064
            ],
            [
              -122.752995,
              40.55538
            ],
            [
              -122.76088,
              40.556068
            ],
            [
              -122.767634,
              40.554981
            ],
            [
              -122.774132,
              40.549523
            ],
            [
              -122.782806,
              40.543354
            ],
            [
              -122.782443,
              40.533137
            ],
            [
              -122.793276,
              40.520121
            ],
            [
              -122.797988,
              40.512508
            ],
            [
              -122.8131,
              40.510665
            ],
            [
              -122.827145,
              40.508002
            ],
            [
              -122.833641,
              40.506824
            ],
            [
              -122.840686,
              40.506498
            ],
            [
              -122.846366,
              40.50477
            ],
            [
              -122.848718,
              40.497999
            ],
            [
              -122.846708,
              40.492144
            ],
            [
              -122.851731,
              40.485669
            ],
            [
              -122.862288,
              40.482156
            ],
            [
              -122.874805,
              40.480623
            ],
            [
              -122.883879,
              40.474036
            ],
            [
              -122.886242,
              40.470107
            ],
            [
              -122.888224,
              40.469204
            ],
            [
              -122.889468,
              40.467407
            ],
            [
              -122.889606,
              40.465311
            ],
            [
              -122.894348,
              40.460299
            ],
            [
              -122.898278,
              40.44925
            ],
            [
              -122.900445,
              40.447354
            ],
            [
              -122.904286,
              40.445175
            ],
            [
              -122.908211,
              40.446488
            ],
            [
              -122.919165,
              40.448592
            ],
            [
              -122.93111,
              40.444879
            ],
            [
              -122.935484,
              40.438226
            ],
            [
              -122.937654,
              40.429981
            ],
            [
              -122.951095,
              40.423961
            ],
            [
              -122.96338,
              40.424877
            ],
            [
              -122.977658,
              40.419072
            ],
            [
              -122.985941,
              40.419073
            ],
            [
              -122.994353,
              40.4184
            ],
            [
              -122.997804,
              40.409845
            ],
            [
              -122.997018,
              40.397422
            ],
            [
              -123.005478,
              40.390316
            ],
            [
              -123.01739,
              40.384488
            ],
            [
              -123.018407,
              40.377441
            ],
            [
              -123.022274,
              40.368171
            ],
            [
              -123.032578,
              40.363932
            ],
            [
              -123.040724,
              40.359566
            ],
            [
              -123.044463,
              40.351591
            ],
            [
              -123.044146,
              40.348749
            ],
            [
              -123.049456,
              40.346872
            ],
            [
              -123.053308,
              40.347357
            ],
            [
              -123.061431,
              40.347218
            ],
            [
              -123.063574,
              40.340973
            ],
            [
              -123.066807,
              40.335411
            ],
            [
              -123.067056,
              40.328985
            ],
            [
              -123.063824,
              40.318285
            ],
            [
              -123.068141,
              40.306282
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "06",
      "COUNTYFP": "091",
      "COUNTYNS": "00277310",
      "AFFGEOID": "0500000US06091",
      "GEOID": "06091",
      "NAME": "Sierra",
      "LSAD": "06",
      "ALAND": 2468694586,
      "AWATER": 23299110,
      "County_state": "Sierra,06"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -121.058203,
              39.537043
            ],
            [
              -121.048743,
              39.51702
            ],
            [
              -121.034406,
              39.514509
            ],
            [
              -121.022085,
              39.391558
            ],
            [
              -121.018038,
              39.395469
            ],
            [
              -121.012027,
              39.397156
            ],
            [
              -121.009943,
              39.400264
            ],
            [
              -121.012509,
              39.401273
            ],
            [
              -121.012142,
              39.4054
            ],
            [
              -121.009194,
              39.404749
            ],
            [
              -121.003379,
              39.407492
            ],
            [
              -120.995736,
              39.412827
            ],
            [
              -120.991419,
              39.413985
            ],
            [
              -120.988321,
              39.412215
            ],
            [
              -120.984461,
              39.411833
            ],
            [
              -120.983263,
              39.412934
            ],
            [
              -120.981203,
              39.413579
            ],
            [
              -120.980935,
              39.412524
            ],
            [
              -120.978898,
              39.41266
            ],
            [
              -120.979532,
              39.414586
            ],
            [
              -120.98002,
              39.416529
            ],
            [
              -120.97722,
              39.417753
            ],
            [
              -120.974313,
              39.417383
            ],
            [
              -120.971238,
              39.418811
            ],
            [
              -120.966057,
              39.417563
            ],
            [
              -120.959633,
              39.415968
            ],
            [
              -120.956604,
              39.416497
            ],
            [
              -120.952536,
              39.416705
            ],
            [
              -120.950123,
              39.418909
            ],
            [
              -120.945491,
              39.41861
            ],
            [
              -120.942528,
              39.419303
            ],
            [
              -120.937537,
              39.417426
            ],
            [
              -120.934333,
              39.417815
            ],
            [
              -120.931983,
              39.417125
            ],
            [
              -120.92971,
              39.418192
            ],
            [
              -120.929923,
              39.419458
            ],
            [
              -120.929107,
              39.42097
            ],
            [
              -120.927222,
              39.420234
            ],
            [
              -120.922713,
              39.420942
            ],
            [
              -120.921371,
              39.421625
            ],
            [
              -120.919173,
              39.421212
            ],
            [
              -120.917113,
              39.420382
            ],
            [
              -120.913618,
              39.420856
            ],
            [
              -120.91384,
              39.422346
            ],
            [
              -120.912696,
              39.424939
            ],
            [
              -120.908522,
              39.425873
            ],
            [
              -120.903502,
              39.424206
            ],
            [
              -120.899046,
              39.424283
            ],
            [
              -120.89355,
              39.424213
            ],
            [
              -120.88976,
              39.426043
            ],
            [
              -120.886968,
              39.427957
            ],
            [
              -120.884511,
              39.429218
            ],
            [
              -120.880696,
              39.428586
            ],
            [
              -120.879376,
              39.428841
            ],
            [
              -120.87859,
              39.429728
            ],
            [
              -120.878766,
              39.430213
            ],
            [
              -120.877347,
              39.430782
            ],
            [
              -120.877187,
              39.430112
            ],
            [
              -120.875692,
              39.429787
            ],
            [
              -120.875531,
              39.430429
            ],
            [
              -120.872639,
              39.430191
            ],
            [
              -120.869793,
              39.430076
            ],
            [
              -120.867794,
              39.430909
            ],
            [
              -120.865468,
              39.43015
            ],
            [
              -120.862919,
              39.431878
            ],
            [
              -120.861614,
              39.433498
            ],
            [
              -120.858814,
              39.434303
            ],
            [
              -120.856846,
              39.435136
            ],
            [
              -120.856144,
              39.434353
            ],
            [
              -120.854694,
              39.434335
            ],
            [
              -120.854679,
              39.435222
            ],
            [
              -120.851619,
              39.435751
            ],
            [
              -120.849514,
              39.435811
            ],
            [
              -120.84605,
              39.436067
            ],
            [
              -120.844318,
              39.435049
            ],
            [
              -120.842723,
              39.436388
            ],
            [
              -120.84016,
              39.438427
            ],
            [
              -120.835864,
              39.440324
            ],
            [
              -120.833659,
              39.439761
            ],
            [
              -120.831912,
              39.438715
            ],
            [
              -120.832759,
              39.438271
            ],
            [
              -120.830676,
              39.436948
            ],
            [
              -120.827288,
              39.435939
            ],
            [
              -120.824388,
              39.435853
            ],
            [
              -120.821986,
              39.43702
            ],
            [
              -120.819971,
              39.436663
            ],
            [
              -120.817759,
              39.437216
            ],
            [
              -120.817813,
              39.437962
            ],
            [
              -120.81755,
              39.439392
            ],
            [
              -120.815483,
              39.440754
            ],
            [
              -120.813455,
              39.439886
            ],
            [
              -120.812172,
              39.437779
            ],
            [
              -120.809171,
              39.436761
            ],
            [
              -120.807321,
              39.437772
            ],
            [
              -120.803537,
              39.438101
            ],
            [
              -120.801431,
              39.437548
            ],
            [
              -120.798943,
              39.438812
            ],
            [
              -120.798005,
              39.440975
            ],
            [
              -120.795014,
              39.441801
            ],
            [
              -120.793481,
              39.44156
            ],
            [
              -120.791497,
              39.44173
            ],
            [
              -120.789987,
              39.443381
            ],
            [
              -120.787362,
              39.443912
            ],
            [
              -120.78637,
              39.44565
            ],
            [
              -120.785981,
              39.447012
            ],
            [
              -120.783494,
              39.4476
            ],
            [
              -120.78241,
              39.446782
            ],
            [
              -120.778923,
              39.448328
            ],
            [
              -120.777207,
              39.450183
            ],
            [
              -120.774918,
              39.449911
            ],
            [
              -120.774735,
              39.449289
            ],
            [
              -120.771193,
              39.449907
            ],
            [
              -120.768516,
              39.450557
            ],
            [
              -120.765083,
              39.451221
            ],
            [
              -120.763679,
              39.453352
            ],
            [
              -120.76033,
              39.453612
            ],
            [
              -120.75785,
              39.452388
            ],
            [
              -120.755516,
              39.452938
            ],
            [
              -120.755546,
              39.454439
            ],
            [
              -120.753669,
              39.45638
            ],
            [
              -120.753463,
              39.457538
            ],
            [
              -120.752159,
              39.45813
            ],
            [
              -120.751159,
              39.457588
            ],
            [
              -120.749595,
              39.4579
            ],
            [
              -120.749678,
              39.458636
            ],
            [
              -120.749244,
              39.459551
            ],
            [
              -120.748115,
              39.459223
            ],
            [
              -120.74523,
              39.459681
            ],
            [
              -120.742171,
              39.461698
            ],
            [
              -120.742163,
              39.463797
            ],
            [
              -120.743255,
              39.465796
            ],
            [
              -120.74282,
              39.467283
            ],
            [
              -120.739799,
              39.468341
            ],
            [
              -120.737792,
              39.47001
            ],
            [
              -120.734923,
              39.469864
            ],
            [
              -120.732192,
              39.469842
            ],
            [
              -120.73014,
              39.471185
            ],
            [
              -120.72814,
              39.471497
            ],
            [
              -120.724799,
              39.473855
            ],
            [
              -120.722113,
              39.47638
            ],
            [
              -120.719245,
              39.478864
            ],
            [
              -120.716353,
              39.480424
            ],
            [
              -120.714743,
              39.481469
            ],
            [
              -120.714338,
              39.483624
            ],
            [
              -120.715002,
              39.48443
            ],
            [
              -120.715483,
              39.486232
            ],
            [
              -120.716773,
              39.487946
            ],
            [
              -120.717009,
              39.488609
            ],
            [
              -120.715399,
              39.489329
            ],
            [
              -120.713858,
              39.489331
            ],
            [
              -120.712271,
              39.490173
            ],
            [
              -120.709418,
              39.490761
            ],
            [
              -120.707678,
              39.492808
            ],
            [
              -120.706343,
              39.49484
            ],
            [
              -120.704985,
              39.496971
            ],
            [
              -120.70294,
              39.498931
            ],
            [
              -120.701384,
              39.502135
            ],
            [
              -120.69808,
              39.502628
            ],
            [
              -120.695784,
              39.503659
            ],
            [
              -120.693922,
              39.50487
            ],
            [
              -120.691534,
              39.505399
            ],
            [
              -120.689031,
              39.505903
            ],
            [
              -120.686743,
              39.506696
            ],
            [
              -120.685953,
              39.509375
            ],
            [
              -120.683851,
              39.510868
            ],
            [
              -120.683072,
              39.51187
            ],
            [
              -120.680387,
              39.51279
            ],
            [
              -120.678228,
              39.515603
            ],
            [
              -120.677503,
              39.518057
            ],
            [
              -120.674626,
              39.519139
            ],
            [
              -120.672529,
              39.520673
            ],
            [
              -120.670407,
              39.520906
            ],
            [
              -120.667203,
              39.521954
            ],
            [
              -120.664037,
              39.522782
            ],
            [
              -120.66071,
              39.523522
            ],
            [
              -120.658452,
              39.524859
            ],
            [
              -120.6573,
              39.526084
            ],
            [
              -120.655217,
              39.526699
            ],
            [
              -120.653661,
              39.526073
            ],
            [
              -120.652516,
              39.524406
            ],
            [
              -120.650433,
              39.523418
            ],
            [
              -120.646847,
              39.52376
            ],
            [
              -120.643658,
              39.523867
            ],
            [
              -120.641606,
              39.523127
            ],
            [
              -120.638635,
              39.524478
            ],
            [
              -120.635128,
              39.523765
            ],
            [
              -120.63171,
              39.524308
            ],
            [
              -120.630726,
              39.524721
            ],
            [
              -120.628925,
              39.523734
            ],
            [
              -120.627498,
              39.522565
            ],
            [
              -120.627239,
              39.521253
            ],
            [
              -120.626049,
              39.519932
            ],
            [
              -120.623881,
              39.520167
            ],
            [
              -120.62115,
              39.521417
            ],
            [
              -120.615786,
              39.521307
            ],
            [
              -120.611445,
              39.521565
            ],
            [
              -120.606021,
              39.521133
            ],
            [
              -120.601214,
              39.521552
            ],
            [
              -120.598416,
              39.521841
            ],
            [
              -120.596026,
              39.521154
            ],
            [
              -120.593772,
              39.522025
            ],
            [
              -120.591813,
              39.522506
            ],
            [
              -120.589899,
              39.5234
            ],
            [
              -120.58732,
              39.523233
            ],
            [
              -120.584826,
              39.522195
            ],
            [
              -120.582781,
              39.522463
            ],
            [
              -120.580667,
              39.523104
            ],
            [
              -120.577859,
              39.523271
            ],
            [
              -120.576525,
              39.522599
            ],
            [
              -120.575159,
              39.522304
            ],
            [
              -120.575304,
              39.521961
            ],
            [
              -120.575777,
              39.521574
            ],
            [
              -120.575349,
              39.521326
            ],
            [
              -120.574724,
              39.520975
            ],
            [
              -120.571733,
              39.519769
            ],
            [
              -120.569208,
              39.517103
            ],
            [
              -120.565103,
              39.515563
            ],
            [
              -120.560998,
              39.51617
            ],
            [
              -120.558677,
              39.514417
            ],
            [
              -120.557066,
              39.514151
            ],
            [
              -120.55602,
              39.511926
            ],
            [
              -120.556138,
              39.509744
            ],
            [
              -120.554724,
              39.505419
            ],
            [
              -120.550323,
              39.502653
            ],
            [
              -120.550245,
              39.49812
            ],
            [
              -120.547825,
              39.49371
            ],
            [
              -120.541828,
              39.491019
            ],
            [
              -120.538398,
              39.486029
            ],
            [
              -120.534721,
              39.48371
            ],
            [
              -120.534442,
              39.479677
            ],
            [
              -120.534386,
              39.476934
            ],
            [
              -120.533731,
              39.473351
            ],
            [
              -120.535112,
              39.469251
            ],
            [
              -120.534705,
              39.466556
            ],
            [
              -120.534507,
              39.46453
            ],
            [
              -120.532542,
              39.463114
            ],
            [
              -120.530997,
              39.461937
            ],
            [
              -120.529295,
              39.461387
            ],
            [
              -120.527235,
              39.460429
            ],
            [
              -120.525158,
              39.459155
            ],
            [
              -120.52355,
              39.459049
            ],
            [
              -120.521796,
              39.458349
            ],
            [
              -120.520773,
              39.457008
            ],
            [
              -120.520056,
              39.457191
            ],
            [
              -120.519285,
              39.45721
            ],
            [
              -120.518042,
              39.456733
            ],
            [
              -120.518141,
              39.455722
            ],
            [
              -120.516577,
              39.454918
            ],
            [
              -120.514585,
              39.455276
            ],
            [
              -120.5119,
              39.454944
            ],
            [
              -120.509382,
              39.454643
            ],
            [
              -120.507604,
              39.453213
            ],
            [
              -120.507619,
              39.45074
            ],
            [
              -120.505933,
              39.447542
            ],
            [
              -120.50107,
              39.446056
            ],
            [
              -120.457544,
              39.446125
            ],
            [
              -120.435943,
              39.44611
            ],
            [
              -120.400407,
              39.446149
            ],
            [
              -120.355649,
              39.446159
            ],
            [
              -120.292756,
              39.446156
            ],
            [
              -120.218601,
              39.445651
            ],
            [
              -120.18609,
              39.445511
            ],
            [
              -120.137518,
              39.445395
            ],
            [
              -120.104315,
              39.445108
            ],
            [
              -120.078185,
              39.445107
            ],
            [
              -120.051767,
              39.44512
            ],
            [
              -120.020267,
              39.445116
            ],
            [
              -120.007952,
              39.4451
            ],
            [
              -120.003117296548,
              39.445043727450496
            ],
            [
              -120.003116,
              39.445113
            ],
            [
              -120.00174,
              39.538852
            ],
            [
              -120.001319008439,
              39.72241632020629
            ],
            [
              -120.015734,
              39.708721
            ],
            [
              -120.147149,
              39.707658
            ],
            [
              -120.201171,
              39.707901
            ],
            [
              -120.251052,
              39.708461
            ],
            [
              -120.314475,
              39.708117
            ],
            [
              -120.350176,
              39.708408
            ],
            [
              -120.385487,
              39.708631
            ],
            [
              -120.471836,
              39.706496
            ],
            [
              -120.519008,
              39.70647
            ],
            [
              -120.546569,
              39.706463
            ],
            [
              -120.581049,
              39.706542
            ],
            [
              -120.612726,
              39.706563
            ],
            [
              -120.636785,
              39.706565
            ],
            [
              -120.654542,
              39.70228
            ],
            [
              -120.654794,
              39.697851
            ],
            [
              -120.656152,
              39.693205
            ],
            [
              -120.663837,
              39.684232
            ],
            [
              -120.672879,
              39.681304
            ],
            [
              -120.675759,
              39.679631
            ],
            [
              -120.68142,
              39.678061
            ],
            [
              -120.683488,
              39.680963
            ],
            [
              -120.690145,
              39.686771
            ],
            [
              -120.697919,
              39.692443
            ],
            [
              -120.705819,
              39.695518
            ],
            [
              -120.712207,
              39.701301
            ],
            [
              -120.718395,
              39.706674
            ],
            [
              -120.728317,
              39.707574
            ],
            [
              -120.738481,
              39.710812
            ],
            [
              -120.745994,
              39.715949
            ],
            [
              -120.752933,
              39.719356
            ],
            [
              -120.770862,
              39.716921
            ],
            [
              -120.775031,
              39.712492
            ],
            [
              -120.782236,
              39.710557
            ],
            [
              -120.787669,
              39.709992
            ],
            [
              -120.791673,
              39.713908
            ],
            [
              -120.796613,
              39.718424
            ],
            [
              -120.804046,
              39.720944
            ],
            [
              -120.811716,
              39.725396
            ],
            [
              -120.812971,
              39.732508
            ],
            [
              -120.813005,
              39.740043
            ],
            [
              -120.813554,
              39.74364
            ],
            [
              -120.819217,
              39.744578
            ],
            [
              -120.822343,
              39.743333
            ],
            [
              -120.82764,
              39.744675
            ],
            [
              -120.829649,
              39.746681
            ],
            [
              -120.837986,
              39.745859
            ],
            [
              -120.843153,
              39.750884
            ],
            [
              -120.845767,
              39.756906
            ],
            [
              -120.859293,
              39.770115
            ],
            [
              -120.865174,
              39.773076
            ],
            [
              -120.870474,
              39.775941
            ],
            [
              -120.875682,
              39.772213
            ],
            [
              -120.887408,
              39.767289
            ],
            [
              -120.894634,
              39.76029
            ],
            [
              -120.905997,
              39.757348
            ],
            [
              -120.916803,
              39.75323
            ],
            [
              -120.931083,
              39.740839
            ],
            [
              -120.934158,
              39.704314
            ],
            [
              -120.936435,
              39.701039
            ],
            [
              -120.937029,
              39.698324
            ],
            [
              -120.937052,
              39.695753
            ],
            [
              -120.936892,
              39.694128
            ],
            [
              -120.938105,
              39.692758
            ],
            [
              -120.93909,
              39.693077
            ],
            [
              -120.939311,
              39.691892
            ],
            [
              -120.93838,
              39.691105
            ],
            [
              -120.939281,
              39.688742
            ],
            [
              -120.940653,
              39.686451
            ],
            [
              -120.94269,
              39.683546
            ],
            [
              -120.947154,
              39.679575
            ],
            [
              -120.95032,
              39.677726
            ],
            [
              -120.951297,
              39.675008
            ],
            [
              -120.950015,
              39.673837
            ],
            [
              -120.95122,
              39.671165
            ],
            [
              -120.954547,
              39.668845
            ],
            [
              -120.957607,
              39.667723
            ],
            [
              -120.963573,
              39.665395
            ],
            [
              -120.96561,
              39.66039
            ],
            [
              -120.969387,
              39.656307
            ],
            [
              -120.974148,
              39.653863
            ],
            [
              -120.976017,
              39.650702
            ],
            [
              -120.979718,
              39.64878
            ],
            [
              -120.982655,
              39.649277
            ],
            [
              -120.984212,
              39.646488
            ],
            [
              -120.986394,
              39.644684
            ],
            [
              -120.991208,
              39.643218
            ],
            [
              -120.994962,
              39.640598
            ],
            [
              -120.998906,
              39.639837
            ],
            [
              -121.00305,
              39.638394
            ],
            [
              -121.006727,
              39.639543
            ],
            [
              -121.009477,
              39.639459
            ],
            [
              -121.024383,
              39.559049
            ],
            [
              -121.047454,
              39.548657
            ],
            [
              -121.058203,
              39.537043
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "06",
      "COUNTYFP": "019",
      "COUNTYNS": "00277274",
      "AFFGEOID": "0500000US06019",
      "GEOID": "06019",
      "NAME": "Fresno",
      "LSAD": "06",
      "ALAND": 15431410219,
      "AWATER": 137339029,
      "County_state": "Fresno,06"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -120.65595,
              36.952832
            ],
            [
              -120.918731,
              36.740381
            ],
            [
              -120.603706,
              36.488242
            ],
            [
              -120.596562,
              36.328488
            ],
            [
              -120.630009,
              36.316908
            ],
            [
              -120.658806,
              36.316235
            ],
            [
              -120.682723,
              36.294441
            ],
            [
              -120.672622,
              36.280489
            ],
            [
              -120.678582,
              36.267319
            ],
            [
              -120.681837,
              36.251046
            ],
            [
              -120.62691,
              36.203227
            ],
            [
              -120.638147,
              36.197539
            ],
            [
              -120.654621,
              36.167721
            ],
            [
              -120.671945,
              36.164287
            ],
            [
              -120.667551,
              36.138057
            ],
            [
              -120.655726,
              36.131781
            ],
            [
              -120.648784,
              36.10799
            ],
            [
              -120.61516,
              36.095161
            ],
            [
              -120.600607,
              36.101587
            ],
            [
              -120.59013,
              36.089803
            ],
            [
              -120.592308,
              36.071881
            ],
            [
              -120.566649,
              36.052932
            ],
            [
              -120.545408,
              36.046123
            ],
            [
              -120.540409,
              36.036664
            ],
            [
              -120.478698,
              36.010183
            ],
            [
              -120.477391,
              36.000824
            ],
            [
              -120.4497,
              35.988432
            ],
            [
              -120.433053,
              35.968933
            ],
            [
              -120.407822,
              35.975737
            ],
            [
              -120.359821,
              35.963978
            ],
            [
              -120.356513,
              35.948057
            ],
            [
              -120.335883,
              35.93709
            ],
            [
              -120.336369,
              35.923108
            ],
            [
              -120.315068,
              35.907186
            ],
            [
              -119.95921,
              36.181403
            ],
            [
              -119.959247,
              36.400871
            ],
            [
              -119.754213,
              36.402023
            ],
            [
              -119.747117,
              36.416387
            ],
            [
              -119.712072,
              36.419437
            ],
            [
              -119.671493,
              36.431074
            ],
            [
              -119.666422,
              36.418866
            ],
            [
              -119.573194,
              36.488835
            ],
            [
              -119.546131,
              36.508963
            ],
            [
              -119.489911,
              36.556724
            ],
            [
              -119.466322,
              36.575238
            ],
            [
              -119.305102,
              36.573725
            ],
            [
              -119.304625,
              36.660606
            ],
            [
              -119.25095,
              36.661214
            ],
            [
              -119.174791,
              36.658878
            ],
            [
              -119.088295,
              36.657514
            ],
            [
              -118.984779,
              36.657147
            ],
            [
              -118.982441,
              36.741646
            ],
            [
              -118.555487,
              36.744746
            ],
            [
              -118.360586,
              36.744773
            ],
            [
              -118.361829,
              36.747134
            ],
            [
              -118.36653,
              36.747834
            ],
            [
              -118.36973,
              36.753834
            ],
            [
              -118.37363,
              36.765234
            ],
            [
              -118.37603,
              36.778534
            ],
            [
              -118.37593,
              36.788284
            ],
            [
              -118.373808,
              36.797456
            ],
            [
              -118.377255,
              36.803314
            ],
            [
              -118.384466,
              36.812708
            ],
            [
              -118.391128,
              36.820968
            ],
            [
              -118.391321,
              36.833207
            ],
            [
              -118.382271,
              36.834527
            ],
            [
              -118.366484,
              36.839991
            ],
            [
              -118.362901,
              36.855419
            ],
            [
              -118.366514,
              36.866128
            ],
            [
              -118.368131,
              36.876834
            ],
            [
              -118.366631,
              36.895034
            ],
            [
              -118.375431,
              36.916733
            ],
            [
              -118.386132,
              36.941134
            ],
            [
              -118.403433,
              36.965535
            ],
            [
              -118.419733,
              36.991735
            ],
            [
              -118.421111,
              37.007563
            ],
            [
              -118.423435,
              37.02248
            ],
            [
              -118.437378,
              37.037483
            ],
            [
              -118.437774,
              37.054512
            ],
            [
              -118.455082,
              37.067468
            ],
            [
              -118.485364,
              37.076842
            ],
            [
              -118.502186,
              37.093477
            ],
            [
              -118.522127,
              37.098354
            ],
            [
              -118.531771,
              37.110075
            ],
            [
              -118.544648,
              37.114733
            ],
            [
              -118.566445,
              37.114453
            ],
            [
              -118.57999,
              37.122592
            ],
            [
              -118.588167,
              37.12988
            ],
            [
              -118.589865,
              37.133282
            ],
            [
              -118.594594,
              37.137539
            ],
            [
              -118.598823,
              37.136622
            ],
            [
              -118.608175,
              37.140259
            ],
            [
              -118.617698,
              37.141625
            ],
            [
              -118.628539,
              37.137766
            ],
            [
              -118.645508,
              37.139516
            ],
            [
              -118.661818,
              37.150859
            ],
            [
              -118.669082,
              37.16338
            ],
            [
              -118.664529,
              37.17774
            ],
            [
              -118.671277,
              37.192981
            ],
            [
              -118.680121,
              37.203442
            ],
            [
              -118.678776,
              37.215327
            ],
            [
              -118.685102,
              37.2298
            ],
            [
              -118.680322,
              37.246409
            ],
            [
              -118.668999,
              37.257109
            ],
            [
              -118.674577,
              37.270708
            ],
            [
              -118.684267,
              37.28569
            ],
            [
              -118.693543,
              37.300367
            ],
            [
              -118.706227,
              37.314071
            ],
            [
              -118.728754,
              37.323002
            ],
            [
              -118.745348,
              37.319781
            ],
            [
              -118.760668,
              37.329347
            ],
            [
              -118.780401,
              37.335814
            ],
            [
              -118.784501,
              37.35168
            ],
            [
              -118.769866,
              37.364136
            ],
            [
              -118.773049,
              37.373178
            ],
            [
              -118.784563,
              37.386101
            ],
            [
              -118.781811,
              37.408513
            ],
            [
              -118.772371,
              37.423559
            ],
            [
              -118.759581,
              37.435573
            ],
            [
              -118.762383,
              37.450869
            ],
            [
              -118.770876,
              37.458176
            ],
            [
              -118.775014,
              37.463052
            ],
            [
              -118.797097,
              37.488712
            ],
            [
              -118.815526,
              37.487807
            ],
            [
              -118.850474,
              37.475796
            ],
            [
              -118.860758,
              37.501535
            ],
            [
              -118.881658,
              37.510336
            ],
            [
              -118.883658,
              37.520136
            ],
            [
              -118.901159,
              37.531236
            ],
            [
              -118.91706,
              37.550336
            ],
            [
              -118.92966,
              37.548936
            ],
            [
              -118.952661,
              37.565836
            ],
            [
              -118.97696,
              37.556837
            ],
            [
              -118.984861,
              37.567337
            ],
            [
              -119.002262,
              37.571137
            ],
            [
              -119.022363,
              37.585737
            ],
            [
              -119.049018,
              37.564531
            ],
            [
              -119.114424,
              37.512497
            ],
            [
              -119.146409,
              37.486822
            ],
            [
              -119.207068,
              37.438081
            ],
            [
              -119.250957,
              37.403173
            ],
            [
              -119.287222,
              37.374458
            ],
            [
              -119.299239,
              37.36415
            ],
            [
              -119.312792,
              37.351932
            ],
            [
              -119.31242,
              37.343803
            ],
            [
              -119.316839,
              37.337784
            ],
            [
              -119.323035,
              37.331649
            ],
            [
              -119.317911,
              37.329117
            ],
            [
              -119.31626,
              37.323982
            ],
            [
              -119.31903,
              37.320963
            ],
            [
              -119.32221,
              37.320858
            ],
            [
              -119.325297,
              37.319877
            ],
            [
              -119.328238,
              37.317274
            ],
            [
              -119.33466,
              37.312904
            ],
            [
              -119.334042,
              37.30393
            ],
            [
              -119.331523,
              37.302501
            ],
            [
              -119.329473,
              37.29834
            ],
            [
              -119.327375,
              37.293323
            ],
            [
              -119.327162,
              37.290113
            ],
            [
              -119.328303,
              37.286935
            ],
            [
              -119.33041,
              37.283617
            ],
            [
              -119.331153,
              37.279856
            ],
            [
              -119.332198,
              37.275005
            ],
            [
              -119.330559,
              37.272769
            ],
            [
              -119.326228,
              37.269413
            ],
            [
              -119.324697,
              37.265757
            ],
            [
              -119.324436,
              37.262434
            ],
            [
              -119.324338,
              37.258117
            ],
            [
              -119.322491,
              37.254189
            ],
            [
              -119.322179,
              37.248807
            ],
            [
              -119.324349,
              37.244258
            ],
            [
              -119.330059,
              37.241271
            ],
            [
              -119.331864,
              37.236051
            ],
            [
              -119.332625,
              37.233162
            ],
            [
              -119.333045,
              37.228698
            ],
            [
              -119.333862,
              37.223736
            ],
            [
              -119.336895,
              37.220648
            ],
            [
              -119.337673,
              37.219622
            ],
            [
              -119.336012,
              37.216897
            ],
            [
              -119.331781,
              37.215733
            ],
            [
              -119.329937,
              37.209256
            ],
            [
              -119.33084,
              37.20548
            ],
            [
              -119.33432,
              37.202782
            ],
            [
              -119.337357,
              37.200095
            ],
            [
              -119.338645,
              37.19703
            ],
            [
              -119.339345,
              37.194484
            ],
            [
              -119.340806,
              37.191587
            ],
            [
              -119.343408,
              37.188842
            ],
            [
              -119.347279,
              37.187055
            ],
            [
              -119.351793,
              37.186198
            ],
            [
              -119.35492,
              37.184684
            ],
            [
              -119.357877,
              37.182632
            ],
            [
              -119.360684,
              37.179462
            ],
            [
              -119.360755,
              37.174244
            ],
            [
              -119.360787,
              37.169382
            ],
            [
              -119.364174,
              37.166359
            ],
            [
              -119.368612,
              37.165104
            ],
            [
              -119.373511,
              37.164631
            ],
            [
              -119.375792,
              37.162316
            ],
            [
              -119.37492,
              37.158663
            ],
            [
              -119.378572,
              37.15548
            ],
            [
              -119.383116,
              37.154282
            ],
            [
              -119.385956,
              37.150052
            ],
            [
              -119.391101,
              37.149299
            ],
            [
              -119.402324,
              37.153142
            ],
            [
              -119.406812,
              37.160031
            ],
            [
              -119.415793,
              37.163446
            ],
            [
              -119.427604,
              37.163955
            ],
            [
              -119.433101,
              37.152016
            ],
            [
              -119.447105,
              37.144414
            ],
            [
              -119.454295,
              37.144029
            ],
            [
              -119.45665,
              37.14474
            ],
            [
              -119.459977,
              37.145221
            ],
            [
              -119.463121,
              37.141377
            ],
            [
              -119.466526,
              37.132639
            ],
            [
              -119.468946,
              37.125182
            ],
            [
              -119.466807,
              37.121759
            ],
            [
              -119.466404,
              37.119332
            ],
            [
              -119.468911,
              37.115043
            ],
            [
              -119.47386,
              37.110191
            ],
            [
              -119.483281,
              37.110306
            ],
            [
              -119.490258,
              37.119051
            ],
            [
              -119.493028,
              37.125068
            ],
            [
              -119.491718,
              37.130319
            ],
            [
              -119.488909,
              37.135703
            ],
            [
              -119.490621,
              37.138909
            ],
            [
              -119.496262,
              37.143972
            ],
            [
              -119.50397,
              37.148335
            ],
            [
              -119.511127,
              37.149279
            ],
            [
              -119.517709,
              37.145508
            ],
            [
              -119.520285,
              37.137957
            ],
            [
              -119.523122,
              37.130725
            ],
            [
              -119.523961,
              37.128656
            ],
            [
              -119.528084,
              37.129269
            ],
            [
              -119.530771,
              37.131282
            ],
            [
              -119.537292,
              37.134102
            ],
            [
              -119.543237,
              37.139516
            ],
            [
              -119.550891,
              37.14484
            ],
            [
              -119.558302,
              37.143672
            ],
            [
              -119.562852,
              37.140456
            ],
            [
              -119.563301,
              37.134391
            ],
            [
              -119.563691,
              37.127767
            ],
            [
              -119.566718,
              37.12117
            ],
            [
              -119.568973,
              37.118342
            ],
            [
              -119.564097,
              37.11584
            ],
            [
              -119.557845,
              37.115611
            ],
            [
              -119.551707,
              37.116934
            ],
            [
              -119.544749,
              37.113133
            ],
            [
              -119.540865,
              37.109929
            ],
            [
              -119.537768,
              37.10558
            ],
            [
              -119.538461,
              37.101873
            ],
            [
              -119.540811,
              37.09683
            ],
            [
              -119.545541,
              37.09517
            ],
            [
              -119.549413,
              37.09402
            ],
            [
              -119.553529,
              37.09236
            ],
            [
              -119.558074,
              37.089002
            ],
            [
              -119.559617,
              37.085139
            ],
            [
              -119.56081,
              37.080573
            ],
            [
              -119.563199,
              37.074755
            ],
            [
              -119.560563,
              37.070349
            ],
            [
              -119.561814,
              37.066968
            ],
            [
              -119.565536,
              37.064023
            ],
            [
              -119.569898,
              37.063883
            ],
            [
              -119.583632,
              37.065682
            ],
            [
              -119.593206,
              37.06907
            ],
            [
              -119.602871,
              37.071289
            ],
            [
              -119.609899,
              37.06413
            ],
            [
              -119.611514,
              37.055968
            ],
            [
              -119.614146,
              37.039842
            ],
            [
              -119.620657,
              37.027058
            ],
            [
              -119.627885,
              37.023887
            ],
            [
              -119.632057,
              37.029586
            ],
            [
              -119.633635,
              37.038486
            ],
            [
              -119.652736,
              37.042118
            ],
            [
              -119.658835,
              37.032905
            ],
            [
              -119.670918,
              37.00926
            ],
            [
              -119.69971,
              37.004904
            ],
            [
              -119.705356,
              36.999794
            ],
            [
              -119.713182,
              36.991638
            ],
            [
              -119.7405,
              36.970212
            ],
            [
              -119.742361,
              36.953698
            ],
            [
              -119.733767,
              36.946441
            ],
            [
              -119.752532,
              36.935223
            ],
            [
              -119.758728,
              36.920238
            ],
            [
              -119.772737,
              36.918599
            ],
            [
              -119.789655,
              36.895349
            ],
            [
              -119.78819,
              36.876607
            ],
            [
              -119.809639,
              36.862569
            ],
            [
              -119.814421,
              36.850138
            ],
            [
              -119.837294,
              36.854114
            ],
            [
              -119.870619,
              36.852227
            ],
            [
              -119.884889,
              36.858549
            ],
            [
              -119.909098,
              36.846199
            ],
            [
              -119.932338,
              36.843323
            ],
            [
              -119.943507,
              36.83403
            ],
            [
              -119.966639,
              36.832482
            ],
            [
              -119.984035,
              36.840892
            ],
            [
              -119.99075,
              36.830565
            ],
            [
              -120.013334,
              36.828228
            ],
            [
              -120.027775,
              36.814505
            ],
            [
              -120.057776,
              36.82286
            ],
            [
              -120.070733,
              36.818621
            ],
            [
              -120.079458,
              36.825345
            ],
            [
              -120.104262,
              36.813351
            ],
            [
              -120.136572,
              36.809296
            ],
            [
              -120.155336,
              36.798816
            ],
            [
              -120.172198,
              36.803096
            ],
            [
              -120.18883,
              36.776408
            ],
            [
              -120.202218,
              36.790005
            ],
            [
              -120.21749,
              36.788666
            ],
            [
              -120.233502,
              36.778668
            ],
            [
              -120.229179,
              36.769687
            ],
            [
              -120.285504,
              36.773504
            ],
            [
              -120.290594,
              36.763047
            ],
            [
              -120.300357,
              36.777633
            ],
            [
              -120.335563,
              36.78417
            ],
            [
              -120.34885,
              36.798255
            ],
            [
              -120.344335,
              36.786352
            ],
            [
              -120.370256,
              36.78494
            ],
            [
              -120.369612,
              36.810355
            ],
            [
              -120.38903,
              36.824441
            ],
            [
              -120.40071,
              36.82241
            ],
            [
              -120.410444,
              36.840005
            ],
            [
              -120.417095,
              36.835626
            ],
            [
              -120.433886,
              36.850582
            ],
            [
              -120.456246,
              36.862863
            ],
            [
              -120.453666,
              36.88079
            ],
            [
              -120.466695,
              36.889744
            ],
            [
              -120.450341,
              36.911172
            ],
            [
              -120.468011,
              36.937472
            ],
            [
              -120.475166,
              36.939252
            ],
            [
              -120.483795,
              36.970985
            ],
            [
              -120.499519,
              36.981966
            ],
            [
              -120.508965,
              37.007623
            ],
            [
              -120.545536,
              37.027582
            ],
            [
              -120.541696,
              37.044505
            ],
            [
              -120.598564,
              36.99863
            ],
            [
              -120.585359,
              36.991176
            ],
            [
              -120.590483,
              36.981642
            ],
            [
              -120.590566,
              36.952641
            ],
            [
              -120.65595,
              36.952832
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "06",
      "COUNTYFP": "005",
      "COUNTYNS": "01675841",
      "AFFGEOID": "0500000US06005",
      "GEOID": "06005",
      "NAME": "Amador",
      "LSAD": "06",
      "ALAND": 1539933576,
      "AWATER": 29470568,
      "County_state": "Amador,06"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -121.027507,
              38.508292
            ],
            [
              -121.027084,
              38.300252
            ],
            [
              -120.995497,
              38.225402
            ],
            [
              -120.992341,
              38.225254
            ],
            [
              -120.978663,
              38.220989
            ],
            [
              -120.963347,
              38.219902
            ],
            [
              -120.950329,
              38.225973
            ],
            [
              -120.932081,
              38.223053
            ],
            [
              -120.909312,
              38.227476
            ],
            [
              -120.903741,
              38.223564
            ],
            [
              -120.895518,
              38.221494
            ],
            [
              -120.889097,
              38.227134
            ],
            [
              -120.880884,
              38.234387
            ],
            [
              -120.873976,
              38.242234
            ],
            [
              -120.856479,
              38.24777
            ],
            [
              -120.848794,
              38.26042
            ],
            [
              -120.841612,
              38.265102
            ],
            [
              -120.829839,
              38.270785
            ],
            [
              -120.8212,
              38.27742
            ],
            [
              -120.814313,
              38.283508
            ],
            [
              -120.808321,
              38.287694
            ],
            [
              -120.801521,
              38.287725
            ],
            [
              -120.785314,
              38.288925
            ],
            [
              -120.767291,
              38.290316
            ],
            [
              -120.759678,
              38.290031
            ],
            [
              -120.75272,
              38.292655
            ],
            [
              -120.750386,
              38.297506
            ],
            [
              -120.739327,
              38.306826
            ],
            [
              -120.72035,
              38.312677
            ],
            [
              -120.70302,
              38.316314
            ],
            [
              -120.683569,
              38.324667
            ],
            [
              -120.673172,
              38.329646
            ],
            [
              -120.658983,
              38.331559
            ],
            [
              -120.640245,
              38.339385
            ],
            [
              -120.631801,
              38.345566
            ],
            [
              -120.623545,
              38.357523
            ],
            [
              -120.616163,
              38.367978
            ],
            [
              -120.616726,
              38.377112
            ],
            [
              -120.613725,
              38.390073
            ],
            [
              -120.593915,
              38.399494
            ],
            [
              -120.572769,
              38.404217
            ],
            [
              -120.561785,
              38.415089
            ],
            [
              -120.551415,
              38.419699
            ],
            [
              -120.53891,
              38.419898
            ],
            [
              -120.525789,
              38.422975
            ],
            [
              -120.508354,
              38.437147
            ],
            [
              -120.503115,
              38.444677
            ],
            [
              -120.500205,
              38.444426
            ],
            [
              -120.498097,
              38.445462
            ],
            [
              -120.498674,
              38.448121
            ],
            [
              -120.494008,
              38.448412
            ],
            [
              -120.492255,
              38.446585
            ],
            [
              -120.475718,
              38.448106
            ],
            [
              -120.459223,
              38.447408
            ],
            [
              -120.447493,
              38.456722
            ],
            [
              -120.429235,
              38.468683
            ],
            [
              -120.415564,
              38.473419
            ],
            [
              -120.400261,
              38.466783
            ],
            [
              -120.384198,
              38.461865
            ],
            [
              -120.366718,
              38.466086
            ],
            [
              -120.342646,
              38.467149
            ],
            [
              -120.320338,
              38.470491
            ],
            [
              -120.297986,
              38.475449
            ],
            [
              -120.277647,
              38.475612
            ],
            [
              -120.26519,
              38.482278
            ],
            [
              -120.2571,
              38.486929
            ],
            [
              -120.244628,
              38.485737
            ],
            [
              -120.235781,
              38.489656
            ],
            [
              -120.219225,
              38.495995
            ],
            [
              -120.20205,
              38.500021
            ],
            [
              -120.182033,
              38.496408
            ],
            [
              -120.166316,
              38.495079
            ],
            [
              -120.153523,
              38.494431
            ],
            [
              -120.143762,
              38.496795
            ],
            [
              -120.13018,
              38.497138
            ],
            [
              -120.117156,
              38.497468
            ],
            [
              -120.106045,
              38.503923
            ],
            [
              -120.096571,
              38.505963
            ],
            [
              -120.085055,
              38.507223
            ],
            [
              -120.072484,
              38.509869
            ],
            [
              -120.072392,
              38.702767
            ],
            [
              -120.07323,
              38.702673
            ],
            [
              -120.078558,
              38.700628
            ],
            [
              -120.094452,
              38.708991
            ],
            [
              -120.112405,
              38.704249
            ],
            [
              -120.12021,
              38.697033
            ],
            [
              -120.122901,
              38.692652
            ],
            [
              -120.120617,
              38.683705
            ],
            [
              -120.117744,
              38.677331
            ],
            [
              -120.121707,
              38.669251
            ],
            [
              -120.123094,
              38.666704
            ],
            [
              -120.131557,
              38.661041
            ],
            [
              -120.131918,
              38.651484
            ],
            [
              -120.135898,
              38.64348
            ],
            [
              -120.143707,
              38.638916
            ],
            [
              -120.146591,
              38.639402
            ],
            [
              -120.149902,
              38.637495
            ],
            [
              -120.154338,
              38.635173
            ],
            [
              -120.165276,
              38.633092
            ],
            [
              -120.183189,
              38.632355
            ],
            [
              -120.197843,
              38.627579
            ],
            [
              -120.206242,
              38.628105
            ],
            [
              -120.211912,
              38.629234
            ],
            [
              -120.215552,
              38.626149
            ],
            [
              -120.217777,
              38.62185
            ],
            [
              -120.223827,
              38.616727
            ],
            [
              -120.227144,
              38.609182
            ],
            [
              -120.232871,
              38.59553
            ],
            [
              -120.243185,
              38.58642
            ],
            [
              -120.255644,
              38.581339
            ],
            [
              -120.266181,
              38.568592
            ],
            [
              -120.2783,
              38.559732
            ],
            [
              -120.289558,
              38.556196
            ],
            [
              -120.296384,
              38.556342
            ],
            [
              -120.313093,
              38.546277
            ],
            [
              -120.330221,
              38.547214
            ],
            [
              -120.343945,
              38.547101
            ],
            [
              -120.357141,
              38.547097
            ],
            [
              -120.362486,
              38.5448
            ],
            [
              -120.367399,
              38.544166
            ],
            [
              -120.372916,
              38.545
            ],
            [
              -120.377102,
              38.54467
            ],
            [
              -120.380105,
              38.543424
            ],
            [
              -120.382672,
              38.53954
            ],
            [
              -120.385436,
              38.538722
            ],
            [
              -120.389208,
              38.540543
            ],
            [
              -120.393477,
              38.539312
            ],
            [
              -120.398193,
              38.537596
            ],
            [
              -120.402655,
              38.537215
            ],
            [
              -120.407654,
              38.534216
            ],
            [
              -120.4119,
              38.531836
            ],
            [
              -120.416297,
              38.531053
            ],
            [
              -120.419707,
              38.530588
            ],
            [
              -120.424249,
              38.530388
            ],
            [
              -120.427084,
              38.528794
            ],
            [
              -120.429945,
              38.528846
            ],
            [
              -120.432661,
              38.526982
            ],
            [
              -120.437893,
              38.528062
            ],
            [
              -120.451733,
              38.527306
            ],
            [
              -120.456456,
              38.5247
            ],
            [
              -120.462842,
              38.523338
            ],
            [
              -120.469625,
              38.522457
            ],
            [
              -120.475286,
              38.521946
            ],
            [
              -120.481028,
              38.521421
            ],
            [
              -120.487402,
              38.52021
            ],
            [
              -120.491758,
              38.517277
            ],
            [
              -120.498869,
              38.515682
            ],
            [
              -120.502577,
              38.513561
            ],
            [
              -120.508696,
              38.512123
            ],
            [
              -120.513899,
              38.511165
            ],
            [
              -120.518408,
              38.511127
            ],
            [
              -120.523467,
              38.51102
            ],
            [
              -120.52748,
              38.510273
            ],
            [
              -120.53173,
              38.510048
            ],
            [
              -120.537086,
              38.509582
            ],
            [
              -120.544235,
              38.509994
            ],
            [
              -120.549225,
              38.510654
            ],
            [
              -120.554977,
              38.511417
            ],
            [
              -120.559815,
              38.509731
            ],
            [
              -120.564827,
              38.508319
            ],
            [
              -120.570259,
              38.506114
            ],
            [
              -120.573876,
              38.505321
            ],
            [
              -120.577371,
              38.504825
            ],
            [
              -120.581361,
              38.503696
            ],
            [
              -120.586831,
              38.503753
            ],
            [
              -120.590028,
              38.503723
            ],
            [
              -120.594011,
              38.503799
            ],
            [
              -120.599123,
              38.503437
            ],
            [
              -120.604898,
              38.503173
            ],
            [
              -120.60708,
              38.502574
            ],
            [
              -120.610399,
              38.503418
            ],
            [
              -120.613711,
              38.503498
            ],
            [
              -120.618357,
              38.503349
            ],
            [
              -120.622393,
              38.504283
            ],
            [
              -120.626702,
              38.503505
            ],
            [
              -120.634422,
              38.505996
            ],
            [
              -120.646467,
              38.50973
            ],
            [
              -120.656731,
              38.512846
            ],
            [
              -120.664641,
              38.516741
            ],
            [
              -120.672161,
              38.517275
            ],
            [
              -120.678018,
              38.520818
            ],
            [
              -120.684919,
              38.527359
            ],
            [
              -120.690494,
              38.528582
            ],
            [
              -120.695031,
              38.530219
            ],
            [
              -120.700799,
              38.533151
            ],
            [
              -120.704015,
              38.535963
            ],
            [
              -120.707811,
              38.535879
            ],
            [
              -120.713104,
              38.539013
            ],
            [
              -120.719559,
              38.543174
            ],
            [
              -120.725939,
              38.547186
            ],
            [
              -120.73221,
              38.547507
            ],
            [
              -120.734526,
              38.54624
            ],
            [
              -120.738254,
              38.546978
            ],
            [
              -120.741582,
              38.548281
            ],
            [
              -120.746531,
              38.548178
            ],
            [
              -120.748466,
              38.551186
            ],
            [
              -120.747798,
              38.553135
            ],
            [
              -120.750961,
              38.552562
            ],
            [
              -120.754374,
              38.551161
            ],
            [
              -120.762792,
              38.553916
            ],
            [
              -120.768373,
              38.552749
            ],
            [
              -120.775428,
              38.55379
            ],
            [
              -120.781443,
              38.552721
            ],
            [
              -120.787365,
              38.553359
            ],
            [
              -120.793217,
              38.557007
            ],
            [
              -120.79754,
              38.556404
            ],
            [
              -120.800826,
              38.557277
            ],
            [
              -120.805281,
              38.55814
            ],
            [
              -120.810974,
              38.55825
            ],
            [
              -120.811785,
              38.560595
            ],
            [
              -120.812058,
              38.562012
            ],
            [
              -120.815659,
              38.560782
            ],
            [
              -120.815953,
              38.558838
            ],
            [
              -120.819289,
              38.55836
            ],
            [
              -120.820891,
              38.55931
            ],
            [
              -120.823146,
              38.559695
            ],
            [
              -120.824126,
              38.558823
            ],
            [
              -120.824221,
              38.556852
            ],
            [
              -120.826025,
              38.555795
            ],
            [
              -120.831518,
              38.556351
            ],
            [
              -120.833649,
              38.555228
            ],
            [
              -120.834257,
              38.552871
            ],
            [
              -120.835325,
              38.551728
            ],
            [
              -120.837527,
              38.552344
            ],
            [
              -120.837852,
              38.553882
            ],
            [
              -120.840041,
              38.555574
            ],
            [
              -120.842074,
              38.555945
            ],
            [
              -120.844042,
              38.555109
            ],
            [
              -120.846277,
              38.554007
            ],
            [
              -120.847878,
              38.553104
            ],
            [
              -120.849605,
              38.550627
            ],
            [
              -120.852407,
              38.550498
            ],
            [
              -120.854444,
              38.549713
            ],
            [
              -120.856367,
              38.548149
            ],
            [
              -120.856481,
              38.546307
            ],
            [
              -120.855586,
              38.544838
            ],
            [
              -120.85656,
              38.542713
            ],
            [
              -120.859123,
              38.542954
            ],
            [
              -120.861108,
              38.543749
            ],
            [
              -120.862723,
              38.542285
            ],
            [
              -120.862998,
              38.540573
            ],
            [
              -120.862898,
              38.538232
            ],
            [
              -120.862926,
              38.536719
            ],
            [
              -120.866468,
              38.535485
            ],
            [
              -120.86975,
              38.535859
            ],
            [
              -120.873716,
              38.53839
            ],
            [
              -120.873324,
              38.540327
            ],
            [
              -120.873367,
              38.541936
            ],
            [
              -120.874771,
              38.543242
            ],
            [
              -120.875612,
              38.543395
            ],
            [
              -120.877373,
              38.541726
            ],
            [
              -120.877793,
              38.539984
            ],
            [
              -120.881965,
              38.537806
            ],
            [
              -120.883507,
              38.536799
            ],
            [
              -120.883029,
              38.534288
            ],
            [
              -120.881477,
              38.532537
            ],
            [
              -120.880718,
              38.52981
            ],
            [
              -120.882083,
              38.52769
            ],
            [
              -120.883796,
              38.525856
            ],
            [
              -120.887175,
              38.524483
            ],
            [
              -120.888541,
              38.524533
            ],
            [
              -120.889856,
              38.526035
            ],
            [
              -120.890238,
              38.527407
            ],
            [
              -120.891238,
              38.528289
            ],
            [
              -120.892531,
              38.528928
            ],
            [
              -120.893588,
              38.528363
            ],
            [
              -120.895687,
              38.528535
            ],
            [
              -120.899697,
              38.530607
            ],
            [
              -120.901418,
              38.531177
            ],
            [
              -120.903368,
              38.530003
            ],
            [
              -120.903798,
              38.528313
            ],
            [
              -120.904743,
              38.526066
            ],
            [
              -120.906533,
              38.523989
            ],
            [
              -120.907683,
              38.524063
            ],
            [
              -120.909569,
              38.522306
            ],
            [
              -120.910555,
              38.520832
            ],
            [
              -120.912519,
              38.520401
            ],
            [
              -120.915463,
              38.520621
            ],
            [
              -120.917539,
              38.520295
            ],
            [
              -120.920673,
              38.51951
            ],
            [
              -120.923536,
              38.518292
            ],
            [
              -120.924351,
              38.516093
            ],
            [
              -120.925394,
              38.515237
            ],
            [
              -120.927906,
              38.515565
            ],
            [
              -120.9289,
              38.516837
            ],
            [
              -120.933336,
              38.51848
            ],
            [
              -120.936631,
              38.519873
            ],
            [
              -120.937024,
              38.522622
            ],
            [
              -120.937466,
              38.524776
            ],
            [
              -120.937995,
              38.526599
            ],
            [
              -120.940382,
              38.528518
            ],
            [
              -120.94305,
              38.527876
            ],
            [
              -120.943563,
              38.527524
            ],
            [
              -120.994785,
              38.513646
            ],
            [
              -121.007326,
              38.519206
            ],
            [
              -121.027507,
              38.508292
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "06",
      "COUNTYFP": "017",
      "COUNTYNS": "00277273",
      "AFFGEOID": "0500000US06017",
      "GEOID": "06017",
      "NAME": "El Dorado",
      "LSAD": "06",
      "ALAND": 4423290535,
      "AWATER": 203328338,
      "County_state": "El Dorado,06"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -121.134507,
              38.712032
            ],
            [
              -121.141009,
              38.71198
            ],
            [
              -121.133264,
              38.705427
            ],
            [
              -121.118764,
              38.716952
            ],
            [
              -121.0793,
              38.632857
            ],
            [
              -121.027507,
              38.508292
            ],
            [
              -121.007326,
              38.519206
            ],
            [
              -120.994785,
              38.513646
            ],
            [
              -120.943563,
              38.527524
            ],
            [
              -120.94305,
              38.527876
            ],
            [
              -120.940382,
              38.528518
            ],
            [
              -120.937995,
              38.526599
            ],
            [
              -120.937466,
              38.524776
            ],
            [
              -120.937024,
              38.522622
            ],
            [
              -120.936631,
              38.519873
            ],
            [
              -120.933336,
              38.51848
            ],
            [
              -120.9289,
              38.516837
            ],
            [
              -120.927906,
              38.515565
            ],
            [
              -120.925394,
              38.515237
            ],
            [
              -120.924351,
              38.516093
            ],
            [
              -120.923536,
              38.518292
            ],
            [
              -120.920673,
              38.51951
            ],
            [
              -120.917539,
              38.520295
            ],
            [
              -120.915463,
              38.520621
            ],
            [
              -120.912519,
              38.520401
            ],
            [
              -120.910555,
              38.520832
            ],
            [
              -120.909569,
              38.522306
            ],
            [
              -120.907683,
              38.524063
            ],
            [
              -120.906533,
              38.523989
            ],
            [
              -120.904743,
              38.526066
            ],
            [
              -120.903798,
              38.528313
            ],
            [
              -120.903368,
              38.530003
            ],
            [
              -120.901418,
              38.531177
            ],
            [
              -120.899697,
              38.530607
            ],
            [
              -120.895687,
              38.528535
            ],
            [
              -120.893588,
              38.528363
            ],
            [
              -120.892531,
              38.528928
            ],
            [
              -120.891238,
              38.528289
            ],
            [
              -120.890238,
              38.527407
            ],
            [
              -120.889856,
              38.526035
            ],
            [
              -120.888541,
              38.524533
            ],
            [
              -120.887175,
              38.524483
            ],
            [
              -120.883796,
              38.525856
            ],
            [
              -120.882083,
              38.52769
            ],
            [
              -120.880718,
              38.52981
            ],
            [
              -120.881477,
              38.532537
            ],
            [
              -120.883029,
              38.534288
            ],
            [
              -120.883507,
              38.536799
            ],
            [
              -120.881965,
              38.537806
            ],
            [
              -120.877793,
              38.539984
            ],
            [
              -120.877373,
              38.541726
            ],
            [
              -120.875612,
              38.543395
            ],
            [
              -120.874771,
              38.543242
            ],
            [
              -120.873367,
              38.541936
            ],
            [
              -120.873324,
              38.540327
            ],
            [
              -120.873716,
              38.53839
            ],
            [
              -120.86975,
              38.535859
            ],
            [
              -120.866468,
              38.535485
            ],
            [
              -120.862926,
              38.536719
            ],
            [
              -120.862898,
              38.538232
            ],
            [
              -120.862998,
              38.540573
            ],
            [
              -120.862723,
              38.542285
            ],
            [
              -120.861108,
              38.543749
            ],
            [
              -120.859123,
              38.542954
            ],
            [
              -120.85656,
              38.542713
            ],
            [
              -120.855586,
              38.544838
            ],
            [
              -120.856481,
              38.546307
            ],
            [
              -120.856367,
              38.548149
            ],
            [
              -120.854444,
              38.549713
            ],
            [
              -120.852407,
              38.550498
            ],
            [
              -120.849605,
              38.550627
            ],
            [
              -120.847878,
              38.553104
            ],
            [
              -120.846277,
              38.554007
            ],
            [
              -120.844042,
              38.555109
            ],
            [
              -120.842074,
              38.555945
            ],
            [
              -120.840041,
              38.555574
            ],
            [
              -120.837852,
              38.553882
            ],
            [
              -120.837527,
              38.552344
            ],
            [
              -120.835325,
              38.551728
            ],
            [
              -120.834257,
              38.552871
            ],
            [
              -120.833649,
              38.555228
            ],
            [
              -120.831518,
              38.556351
            ],
            [
              -120.826025,
              38.555795
            ],
            [
              -120.824221,
              38.556852
            ],
            [
              -120.824126,
              38.558823
            ],
            [
              -120.823146,
              38.559695
            ],
            [
              -120.820891,
              38.55931
            ],
            [
              -120.819289,
              38.55836
            ],
            [
              -120.815953,
              38.558838
            ],
            [
              -120.815659,
              38.560782
            ],
            [
              -120.812058,
              38.562012
            ],
            [
              -120.811785,
              38.560595
            ],
            [
              -120.810974,
              38.55825
            ],
            [
              -120.805281,
              38.55814
            ],
            [
              -120.800826,
              38.557277
            ],
            [
              -120.79754,
              38.556404
            ],
            [
              -120.793217,
              38.557007
            ],
            [
              -120.787365,
              38.553359
            ],
            [
              -120.781443,
              38.552721
            ],
            [
              -120.775428,
              38.55379
            ],
            [
              -120.768373,
              38.552749
            ],
            [
              -120.762792,
              38.553916
            ],
            [
              -120.754374,
              38.551161
            ],
            [
              -120.750961,
              38.552562
            ],
            [
              -120.747798,
              38.553135
            ],
            [
              -120.748466,
              38.551186
            ],
            [
              -120.746531,
              38.548178
            ],
            [
              -120.741582,
              38.548281
            ],
            [
              -120.738254,
              38.546978
            ],
            [
              -120.734526,
              38.54624
            ],
            [
              -120.73221,
              38.547507
            ],
            [
              -120.725939,
              38.547186
            ],
            [
              -120.719559,
              38.543174
            ],
            [
              -120.713104,
              38.539013
            ],
            [
              -120.707811,
              38.535879
            ],
            [
              -120.704015,
              38.535963
            ],
            [
              -120.700799,
              38.533151
            ],
            [
              -120.695031,
              38.530219
            ],
            [
              -120.690494,
              38.528582
            ],
            [
              -120.684919,
              38.527359
            ],
            [
              -120.678018,
              38.520818
            ],
            [
              -120.672161,
              38.517275
            ],
            [
              -120.664641,
              38.516741
            ],
            [
              -120.656731,
              38.512846
            ],
            [
              -120.646467,
              38.50973
            ],
            [
              -120.634422,
              38.505996
            ],
            [
              -120.626702,
              38.503505
            ],
            [
              -120.622393,
              38.504283
            ],
            [
              -120.618357,
              38.503349
            ],
            [
              -120.613711,
              38.503498
            ],
            [
              -120.610399,
              38.503418
            ],
            [
              -120.60708,
              38.502574
            ],
            [
              -120.604898,
              38.503173
            ],
            [
              -120.599123,
              38.503437
            ],
            [
              -120.594011,
              38.503799
            ],
            [
              -120.590028,
              38.503723
            ],
            [
              -120.586831,
              38.503753
            ],
            [
              -120.581361,
              38.503696
            ],
            [
              -120.577371,
              38.504825
            ],
            [
              -120.573876,
              38.505321
            ],
            [
              -120.570259,
              38.506114
            ],
            [
              -120.564827,
              38.508319
            ],
            [
              -120.559815,
              38.509731
            ],
            [
              -120.554977,
              38.511417
            ],
            [
              -120.549225,
              38.510654
            ],
            [
              -120.544235,
              38.509994
            ],
            [
              -120.537086,
              38.509582
            ],
            [
              -120.53173,
              38.510048
            ],
            [
              -120.52748,
              38.510273
            ],
            [
              -120.523467,
              38.51102
            ],
            [
              -120.518408,
              38.511127
            ],
            [
              -120.513899,
              38.511165
            ],
            [
              -120.508696,
              38.512123
            ],
            [
              -120.502577,
              38.513561
            ],
            [
              -120.498869,
              38.515682
            ],
            [
              -120.491758,
              38.517277
            ],
            [
              -120.487402,
              38.52021
            ],
            [
              -120.481028,
              38.521421
            ],
            [
              -120.475286,
              38.521946
            ],
            [
              -120.469625,
              38.522457
            ],
            [
              -120.462842,
              38.523338
            ],
            [
              -120.456456,
              38.5247
            ],
            [
              -120.451733,
              38.527306
            ],
            [
              -120.437893,
              38.528062
            ],
            [
              -120.432661,
              38.526982
            ],
            [
              -120.429945,
              38.528846
            ],
            [
              -120.427084,
              38.528794
            ],
            [
              -120.424249,
              38.530388
            ],
            [
              -120.419707,
              38.530588
            ],
            [
              -120.416297,
              38.531053
            ],
            [
              -120.4119,
              38.531836
            ],
            [
              -120.407654,
              38.534216
            ],
            [
              -120.402655,
              38.537215
            ],
            [
              -120.398193,
              38.537596
            ],
            [
              -120.393477,
              38.539312
            ],
            [
              -120.389208,
              38.540543
            ],
            [
              -120.385436,
              38.538722
            ],
            [
              -120.382672,
              38.53954
            ],
            [
              -120.380105,
              38.543424
            ],
            [
              -120.377102,
              38.54467
            ],
            [
              -120.372916,
              38.545
            ],
            [
              -120.367399,
              38.544166
            ],
            [
              -120.362486,
              38.5448
            ],
            [
              -120.357141,
              38.547097
            ],
            [
              -120.343945,
              38.547101
            ],
            [
              -120.330221,
              38.547214
            ],
            [
              -120.313093,
              38.546277
            ],
            [
              -120.296384,
              38.556342
            ],
            [
              -120.289558,
              38.556196
            ],
            [
              -120.2783,
              38.559732
            ],
            [
              -120.266181,
              38.568592
            ],
            [
              -120.255644,
              38.581339
            ],
            [
              -120.243185,
              38.58642
            ],
            [
              -120.232871,
              38.59553
            ],
            [
              -120.227144,
              38.609182
            ],
            [
              -120.223827,
              38.616727
            ],
            [
              -120.217777,
              38.62185
            ],
            [
              -120.215552,
              38.626149
            ],
            [
              -120.211912,
              38.629234
            ],
            [
              -120.206242,
              38.628105
            ],
            [
              -120.197843,
              38.627579
            ],
            [
              -120.183189,
              38.632355
            ],
            [
              -120.165276,
              38.633092
            ],
            [
              -120.154338,
              38.635173
            ],
            [
              -120.149902,
              38.637495
            ],
            [
              -120.146591,
              38.639402
            ],
            [
              -120.143707,
              38.638916
            ],
            [
              -120.135898,
              38.64348
            ],
            [
              -120.131918,
              38.651484
            ],
            [
              -120.131557,
              38.661041
            ],
            [
              -120.123094,
              38.666704
            ],
            [
              -120.121707,
              38.669251
            ],
            [
              -120.117744,
              38.677331
            ],
            [
              -120.120617,
              38.683705
            ],
            [
              -120.122901,
              38.692652
            ],
            [
              -120.12021,
              38.697033
            ],
            [
              -120.112405,
              38.704249
            ],
            [
              -120.094452,
              38.708991
            ],
            [
              -120.078558,
              38.700628
            ],
            [
              -120.07323,
              38.702673
            ],
            [
              -120.072392,
              38.702767
            ],
            [
              -119.964948,
              38.775986
            ],
            [
              -119.947927,
              38.781642
            ],
            [
              -119.942108,
              38.80311
            ],
            [
              -119.92186,
              38.820962
            ],
            [
              -119.92271,
              38.829955
            ],
            [
              -119.908493,
              38.834346
            ],
            [
              -119.906779,
              38.854664
            ],
            [
              -119.891909,
              38.857344
            ],
            [
              -119.877287,
              38.870193
            ],
            [
              -119.888444,
              38.879301
            ],
            [
              -119.879516,
              38.887021
            ],
            [
              -119.887643,
              38.918295
            ],
            [
              -119.904315,
              38.933324
            ],
            [
              -120.001014,
              38.999574
            ],
            [
              -120.002461,
              39.067489
            ],
            [
              -120.143663,
              39.067285
            ],
            [
              -120.153021,
              39.060329
            ],
            [
              -120.152976,
              39.045807
            ],
            [
              -120.165028,
              39.038485
            ],
            [
              -120.18356,
              39.038372
            ],
            [
              -120.184098,
              39.031014
            ],
            [
              -120.240157,
              39.030889
            ],
            [
              -120.24041,
              39.023668
            ],
            [
              -120.328122,
              39.022433
            ],
            [
              -120.43524,
              39.028397
            ],
            [
              -120.445213,
              39.017595
            ],
            [
              -120.470985,
              38.965507
            ],
            [
              -120.486122,
              38.958837
            ],
            [
              -120.492735,
              38.943351
            ],
            [
              -120.512346,
              38.931191
            ],
            [
              -120.54537,
              38.926668
            ],
            [
              -120.556321,
              38.915164
            ],
            [
              -120.572352,
              38.9144
            ],
            [
              -120.594381,
              38.936104
            ],
            [
              -120.612809,
              38.943091
            ],
            [
              -120.642458,
              38.944404
            ],
            [
              -120.683668,
              38.967531
            ],
            [
              -120.687925,
              38.989949
            ],
            [
              -120.703904,
              38.981182
            ],
            [
              -120.716286,
              38.986671
            ],
            [
              -120.729575,
              39.004025
            ],
            [
              -120.746395,
              39.010352
            ],
            [
              -120.764448,
              39.00975
            ],
            [
              -120.798103,
              38.996051
            ],
            [
              -120.812295,
              39.000133
            ],
            [
              -120.829711,
              38.987343
            ],
            [
              -120.834163,
              38.971866
            ],
            [
              -120.850514,
              38.975899
            ],
            [
              -120.858903,
              38.952256
            ],
            [
              -120.890502,
              38.959562
            ],
            [
              -120.899989,
              38.953348
            ],
            [
              -120.940281,
              38.962339
            ],
            [
              -120.93857,
              38.935565
            ],
            [
              -120.958052,
              38.939005
            ],
            [
              -120.965994,
              38.932581
            ],
            [
              -120.991968,
              38.927225
            ],
            [
              -121.001052,
              38.917835
            ],
            [
              -121.037502,
              38.915689
            ],
            [
              -121.040511,
              38.915538
            ],
            [
              -121.053407,
              38.899502
            ],
            [
              -121.044175,
              38.890826
            ],
            [
              -121.061984,
              38.881499
            ],
            [
              -121.053263,
              38.868347
            ],
            [
              -121.060916,
              38.845613
            ],
            [
              -121.073575,
              38.842604
            ],
            [
              -121.085791,
              38.83445
            ],
            [
              -121.088959,
              38.829233
            ],
            [
              -121.083055,
              38.823054
            ],
            [
              -121.084957,
              38.81601
            ],
            [
              -121.100844,
              38.815392
            ],
            [
              -121.102999,
              38.785548
            ],
            [
              -121.134507,
              38.712032
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "06",
      "COUNTYFP": "053",
      "COUNTYNS": "00277291",
      "AFFGEOID": "0500000US06053",
      "GEOID": "06053",
      "NAME": "Monterey",
      "LSAD": "06",
      "ALAND": 8496680819,
      "AWATER": 1270736099,
      "County_state": "Monterey,06"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -121.978592,
              36.580488
            ],
            [
              -121.972594,
              36.57337
            ],
            [
              -121.957335,
              36.564482
            ],
            [
              -121.95146,
              36.564009
            ],
            [
              -121.949659,
              36.567602
            ],
            [
              -121.942533,
              36.566435
            ],
            [
              -121.932508,
              36.559935
            ],
            [
              -121.925937,
              36.525173
            ],
            [
              -121.928769,
              36.523147
            ],
            [
              -121.944666,
              36.521861
            ],
            [
              -121.943678,
              36.511802
            ],
            [
              -121.938763,
              36.506423
            ],
            [
              -121.939216,
              36.496896
            ],
            [
              -121.9416,
              36.485602
            ],
            [
              -121.94003766700901,
              36.4809402400819
            ],
            [
              -121.937205,
              36.472488
            ],
            [
              -121.9255,
              36.453918
            ],
            [
              -121.91474,
              36.42589
            ],
            [
              -121.917463,
              36.414809
            ],
            [
              -121.914378,
              36.404344
            ],
            [
              -121.905657,
              36.398206
            ],
            [
              -121.903195,
              36.393603
            ],
            [
              -121.901813,
              36.381879
            ],
            [
              -121.902669,
              36.363901
            ],
            [
              -121.905446,
              36.358269
            ],
            [
              -121.892917,
              36.340428
            ],
            [
              -121.894714,
              36.317806
            ],
            [
              -121.888491,
              36.30281
            ],
            [
              -121.874797,
              36.289064
            ],
            [
              -121.851967,
              36.277831
            ],
            [
              -121.83935,
              36.260478
            ],
            [
              -121.835785,
              36.250748
            ],
            [
              -121.826425,
              36.24186
            ],
            [
              -121.813734,
              36.234235
            ],
            [
              -121.806979,
              36.232907
            ],
            [
              -121.797059,
              36.234211
            ],
            [
              -121.779851,
              36.227407
            ],
            [
              -121.717176,
              36.195146
            ],
            [
              -121.680145,
              36.165818
            ],
            [
              -121.629634,
              36.114452
            ],
            [
              -121.622009,
              36.099695
            ],
            [
              -121.618672,
              36.087767
            ],
            [
              -121.606845,
              36.072065
            ],
            [
              -121.592853,
              36.065062
            ],
            [
              -121.589183,
              36.053775
            ],
            [
              -121.590395,
              36.050363
            ],
            [
              -121.574602,
              36.025156
            ],
            [
              -121.569612,
              36.021539
            ],
            [
              -121.553716,
              36.019798
            ],
            [
              -121.531876,
              36.014368
            ],
            [
              -121.51159,
              36.006598
            ],
            [
              -121.503112,
              36.000299
            ],
            [
              -121.4862,
              35.970348
            ],
            [
              -121.472435,
              35.91989
            ],
            [
              -121.463452,
              35.904416
            ],
            [
              -121.461227,
              35.896906
            ],
            [
              -121.462264,
              35.885618
            ],
            [
              -121.439584,
              35.86695
            ],
            [
              -121.426955,
              35.860103
            ],
            [
              -121.413146,
              35.855316
            ],
            [
              -121.406823,
              35.844623
            ],
            [
              -121.388053,
              35.823483
            ],
            [
              -121.356737,
              35.804187
            ],
            [
              -121.346362833935,
              35.7951826441526
            ],
            [
              -121.125937,
              35.793941
            ],
            [
              -121.114454,
              35.79331
            ],
            [
              -120.250979,
              35.788977
            ],
            [
              -120.244551,
              35.789458
            ],
            [
              -120.213979,
              35.789276
            ],
            [
              -120.220667,
              35.819542
            ],
            [
              -120.244534,
              35.823988
            ],
            [
              -120.259302,
              35.845053
            ],
            [
              -120.240246,
              35.865928
            ],
            [
              -120.243275,
              35.877669
            ],
            [
              -120.27576,
              35.905881
            ],
            [
              -120.315068,
              35.907186
            ],
            [
              -120.336369,
              35.923108
            ],
            [
              -120.335883,
              35.93709
            ],
            [
              -120.356513,
              35.948057
            ],
            [
              -120.359821,
              35.963978
            ],
            [
              -120.407822,
              35.975737
            ],
            [
              -120.433053,
              35.968933
            ],
            [
              -120.4497,
              35.988432
            ],
            [
              -120.477391,
              36.000824
            ],
            [
              -120.478698,
              36.010183
            ],
            [
              -120.540409,
              36.036664
            ],
            [
              -120.545408,
              36.046123
            ],
            [
              -120.566649,
              36.052932
            ],
            [
              -120.592308,
              36.071881
            ],
            [
              -120.59013,
              36.089803
            ],
            [
              -120.600607,
              36.101587
            ],
            [
              -120.61516,
              36.095161
            ],
            [
              -120.648784,
              36.10799
            ],
            [
              -120.655726,
              36.131781
            ],
            [
              -120.667551,
              36.138057
            ],
            [
              -120.671945,
              36.164287
            ],
            [
              -120.654621,
              36.167721
            ],
            [
              -120.638147,
              36.197539
            ],
            [
              -120.62691,
              36.203227
            ],
            [
              -120.681837,
              36.251046
            ],
            [
              -120.678582,
              36.267319
            ],
            [
              -120.69773,
              36.282884
            ],
            [
              -120.722584,
              36.292656
            ],
            [
              -120.746654,
              36.310234
            ],
            [
              -120.75834,
              36.308604
            ],
            [
              -120.743114,
              36.298059
            ],
            [
              -120.732108,
              36.275465
            ],
            [
              -120.717696,
              36.260012
            ],
            [
              -120.706411,
              36.227692
            ],
            [
              -120.706121,
              36.20373
            ],
            [
              -120.718169,
              36.196848
            ],
            [
              -120.732046,
              36.203981
            ],
            [
              -120.743793,
              36.199121
            ],
            [
              -120.744389,
              36.198777
            ],
            [
              -120.749123,
              36.198827
            ],
            [
              -120.754903,
              36.201198
            ],
            [
              -120.757153,
              36.202817
            ],
            [
              -120.760378,
              36.203085
            ],
            [
              -120.76379,
              36.209085
            ],
            [
              -120.765632,
              36.212327
            ],
            [
              -120.769263,
              36.216647
            ],
            [
              -120.776392,
              36.220704
            ],
            [
              -120.779751,
              36.222707
            ],
            [
              -120.782091,
              36.227248
            ],
            [
              -120.787907,
              36.230452
            ],
            [
              -120.788747,
              36.233062
            ],
            [
              -120.791777,
              36.234117
            ],
            [
              -120.79371,
              36.234305
            ],
            [
              -120.797563,
              36.235719
            ],
            [
              -120.801423,
              36.238177
            ],
            [
              -120.802212,
              36.240486
            ],
            [
              -120.806701,
              36.241553
            ],
            [
              -120.809121,
              36.244349
            ],
            [
              -120.8118,
              36.247354
            ],
            [
              -120.810667,
              36.249156
            ],
            [
              -120.81051,
              36.249966
            ],
            [
              -120.812693,
              36.250681
            ],
            [
              -120.813609,
              36.251323
            ],
            [
              -120.813948,
              36.252219
            ],
            [
              -120.815813,
              36.253306
            ],
            [
              -120.817413,
              36.253141
            ],
            [
              -120.821585,
              36.255992
            ],
            [
              -120.82263,
              36.256701
            ],
            [
              -120.824729,
              36.256046
            ],
            [
              -120.824729,
              36.255623
            ],
            [
              -120.826075,
              36.255904
            ],
            [
              -120.825746,
              36.25685
            ],
            [
              -120.826789,
              36.257493
            ],
            [
              -120.826932,
              36.257093
            ],
            [
              -120.82824,
              36.257046
            ],
            [
              -120.829434,
              36.258112
            ],
            [
              -120.829054,
              36.258315
            ],
            [
              -120.828687,
              36.258359
            ],
            [
              -120.830254,
              36.25936
            ],
            [
              -120.831719,
              36.259216
            ],
            [
              -120.833354,
              36.260451
            ],
            [
              -120.833002,
              36.261644
            ],
            [
              -120.835058,
              36.262456
            ],
            [
              -120.83619,
              36.262258
            ],
            [
              -120.837032,
              36.264142
            ],
            [
              -120.837033,
              36.266235
            ],
            [
              -120.838465,
              36.268822
            ],
            [
              -120.839354,
              36.270574
            ],
            [
              -120.838094,
              36.271045
            ],
            [
              -120.837706,
              36.270822
            ],
            [
              -120.837645,
              36.272201
            ],
            [
              -120.839512,
              36.274157
            ],
            [
              -120.841018,
              36.277183
            ],
            [
              -120.844466,
              36.279896
            ],
            [
              -120.849399,
              36.281614
            ],
            [
              -120.851361,
              36.283854
            ],
            [
              -120.852794,
              36.285733
            ],
            [
              -120.854748,
              36.285232
            ],
            [
              -120.857062,
              36.286462
            ],
            [
              -120.85992,
              36.288526
            ],
            [
              -120.860776,
              36.29057
            ],
            [
              -120.861681,
              36.290178
            ],
            [
              -120.863389,
              36.291068
            ],
            [
              -120.865412,
              36.292534
            ],
            [
              -120.869077,
              36.293643
            ],
            [
              -120.872959,
              36.294256
            ],
            [
              -120.876017,
              36.295058
            ],
            [
              -120.880267,
              36.294916
            ],
            [
              -120.882717,
              36.293738
            ],
            [
              -120.886239,
              36.293257
            ],
            [
              -120.887771,
              36.291571
            ],
            [
              -120.890508,
              36.291072
            ],
            [
              -120.891587,
              36.291908
            ],
            [
              -120.895628,
              36.296572
            ],
            [
              -120.898905,
              36.300635
            ],
            [
              -120.904747,
              36.30268
            ],
            [
              -120.906674,
              36.303828
            ],
            [
              -120.910236,
              36.305307
            ],
            [
              -120.913764,
              36.308807
            ],
            [
              -120.917314,
              36.310105
            ],
            [
              -120.92083,
              36.31094
            ],
            [
              -120.924679,
              36.308646
            ],
            [
              -120.926129,
              36.305374
            ],
            [
              -120.925786,
              36.302782
            ],
            [
              -120.929078,
              36.300976
            ],
            [
              -120.9322,
              36.300305
            ],
            [
              -120.934918,
              36.295425
            ],
            [
              -120.935255,
              36.29155
            ],
            [
              -120.935452,
              36.28955
            ],
            [
              -120.938536,
              36.288186
            ],
            [
              -120.94046,
              36.285952
            ],
            [
              -120.939117,
              36.28421
            ],
            [
              -120.941125,
              36.281607
            ],
            [
              -120.944735,
              36.279453
            ],
            [
              -120.95004,
              36.276893
            ],
            [
              -120.952673,
              36.275074
            ],
            [
              -120.957083,
              36.276557
            ],
            [
              -120.958726,
              36.279836
            ],
            [
              -120.964521,
              36.280868
            ],
            [
              -120.966171,
              36.284136
            ],
            [
              -120.971567,
              36.287651
            ],
            [
              -120.973787,
              36.29032
            ],
            [
              -120.977363,
              36.290473
            ],
            [
              -120.979996,
              36.290758
            ],
            [
              -120.983973,
              36.291729
            ],
            [
              -120.98546,
              36.292951
            ],
            [
              -120.985731,
              36.291426
            ],
            [
              -120.985377,
              36.289745
            ],
            [
              -120.987981,
              36.284125
            ],
            [
              -120.987338,
              36.277691
            ],
            [
              -120.989595,
              36.274004
            ],
            [
              -120.992272,
              36.270234
            ],
            [
              -120.994979,
              36.26992
            ],
            [
              -120.998057,
              36.270508
            ],
            [
              -121.001346,
              36.269749
            ],
            [
              -121.002091,
              36.268354
            ],
            [
              -121.005161,
              36.267114
            ],
            [
              -121.008562,
              36.268387
            ],
            [
              -121.010886,
              36.267644
            ],
            [
              -121.010819,
              36.266716
            ],
            [
              -121.010805,
              36.265656
            ],
            [
              -121.012814,
              36.26559
            ],
            [
              -121.013187,
              36.267134
            ],
            [
              -121.013742,
              36.268393
            ],
            [
              -121.015866,
              36.266855
            ],
            [
              -121.016031,
              36.264751
            ],
            [
              -121.018462,
              36.260555
            ],
            [
              -121.020212,
              36.259973
            ],
            [
              -121.023456,
              36.260844
            ],
            [
              -121.025293,
              36.259882
            ],
            [
              -121.027463,
              36.260951
            ],
            [
              -121.027022,
              36.26247
            ],
            [
              -121.027998,
              36.264931
            ],
            [
              -121.028657,
              36.267618
            ],
            [
              -121.028019,
              36.270206
            ],
            [
              -121.027249,
              36.270409
            ],
            [
              -121.025532,
              36.271347
            ],
            [
              -121.02642,
              36.273045
            ],
            [
              -121.030069,
              36.275887
            ],
            [
              -121.033326,
              36.275196
            ],
            [
              -121.03585,
              36.274498
            ],
            [
              -121.035837,
              36.27346
            ],
            [
              -121.037473,
              36.272246
            ],
            [
              -121.040451,
              36.287104
            ],
            [
              -121.040664,
              36.309781
            ],
            [
              -121.077359,
              36.353463
            ],
            [
              -121.11944,
              36.387164
            ],
            [
              -121.155838,
              36.416008
            ],
            [
              -121.196753,
              36.449542
            ],
            [
              -121.202042,
              36.45794
            ],
            [
              -121.207174,
              36.463245
            ],
            [
              -121.21443,
              36.471679
            ],
            [
              -121.218507,
              36.472538
            ],
            [
              -121.223277,
              36.47247
            ],
            [
              -121.22781,
              36.474122
            ],
            [
              -121.23004,
              36.477744
            ],
            [
              -121.228583,
              36.481337
            ],
            [
              -121.232318,
              36.488788
            ],
            [
              -121.233468,
              36.494354
            ],
            [
              -121.240843,
              36.505437
            ],
            [
              -121.247258,
              36.506784
            ],
            [
              -121.24984,
              36.504731
            ],
            [
              -121.259599,
              36.504464
            ],
            [
              -121.267297,
              36.502282
            ],
            [
              -121.275121,
              36.50333
            ],
            [
              -121.284087,
              36.503928
            ],
            [
              -121.290973,
              36.505478
            ],
            [
              -121.296629,
              36.504499
            ],
            [
              -121.30269,
              36.503844
            ],
            [
              -121.306404,
              36.501818
            ],
            [
              -121.309506,
              36.500671
            ],
            [
              -121.311562,
              36.502417
            ],
            [
              -121.310726,
              36.508749
            ],
            [
              -121.307415,
              36.514704
            ],
            [
              -121.303155,
              36.519818
            ],
            [
              -121.298732,
              36.523402
            ],
            [
              -121.296011,
              36.528936
            ],
            [
              -121.298013,
              36.532829
            ],
            [
              -121.300474,
              36.537069
            ],
            [
              -121.303282,
              36.539599
            ],
            [
              -121.307744,
              36.544965
            ],
            [
              -121.313033,
              36.549488
            ],
            [
              -121.32065,
              36.552553
            ],
            [
              -121.327698,
              36.554488
            ],
            [
              -121.329499,
              36.559571
            ],
            [
              -121.331469,
              36.562901
            ],
            [
              -121.331396,
              36.565435
            ],
            [
              -121.331419,
              36.56936
            ],
            [
              -121.332924,
              36.571305
            ],
            [
              -121.332317,
              36.575353
            ],
            [
              -121.328632,
              36.576637
            ],
            [
              -121.325082,
              36.577065
            ],
            [
              -121.324635,
              36.579691
            ],
            [
              -121.325227,
              36.581929
            ],
            [
              -121.327259,
              36.585283
            ],
            [
              -121.324288,
              36.589078
            ],
            [
              -121.325069,
              36.593369
            ],
            [
              -121.323355,
              36.60276
            ],
            [
              -121.319091,
              36.60584
            ],
            [
              -121.319068,
              36.610552
            ],
            [
              -121.327864,
              36.617444
            ],
            [
              -121.333284,
              36.621458
            ],
            [
              -121.337154,
              36.626419
            ],
            [
              -121.341779,
              36.632506
            ],
            [
              -121.342239,
              36.637726
            ],
            [
              -121.346191,
              36.641996
            ],
            [
              -121.350184,
              36.645872
            ],
            [
              -121.35301,
              36.648356
            ],
            [
              -121.361444,
              36.653771
            ],
            [
              -121.365048,
              36.655813
            ],
            [
              -121.369581,
              36.65601
            ],
            [
              -121.374825,
              36.654831
            ],
            [
              -121.379158,
              36.653296
            ],
            [
              -121.384231,
              36.653781
            ],
            [
              -121.390574,
              36.655175
            ],
            [
              -121.395587,
              36.655338
            ],
            [
              -121.401946,
              36.655424
            ],
            [
              -121.408533,
              36.657796
            ],
            [
              -121.411424,
              36.663612
            ],
            [
              -121.413769,
              36.670141
            ],
            [
              -121.420359,
              36.673443
            ],
            [
              -121.425391,
              36.675159
            ],
            [
              -121.433999,
              36.677821
            ],
            [
              -121.439781,
              36.675554
            ],
            [
              -121.442818,
              36.673349
            ],
            [
              -121.44797,
              36.67638
            ],
            [
              -121.452008,
              36.679911
            ],
            [
              -121.457033,
              36.68246
            ],
            [
              -121.458659,
              36.684419
            ],
            [
              -121.462975,
              36.685183
            ],
            [
              -121.465884,
              36.684863
            ],
            [
              -121.468756,
              36.687005
            ],
            [
              -121.469192,
              36.690472
            ],
            [
              -121.471917,
              36.694411
            ],
            [
              -121.4738,
              36.696881
            ],
            [
              -121.476158,
              36.699754
            ],
            [
              -121.479464,
              36.703436
            ],
            [
              -121.48114,
              36.707112
            ],
            [
              -121.483318,
              36.710104
            ],
            [
              -121.484215,
              36.712399
            ],
            [
              -121.48141,
              36.715293
            ],
            [
              -121.478764,
              36.715357
            ],
            [
              -121.47184,
              36.716724
            ],
            [
              -121.467822,
              36.717177
            ],
            [
              -121.464959,
              36.717781
            ],
            [
              -121.461109,
              36.717859
            ],
            [
              -121.456339,
              36.720981
            ],
            [
              -121.453084,
              36.723045
            ],
            [
              -121.452484,
              36.727333
            ],
            [
              -121.456269,
              36.731927
            ],
            [
              -121.45803,
              36.736768
            ],
            [
              -121.460386,
              36.740523
            ],
            [
              -121.464111,
              36.744191
            ],
            [
              -121.468166,
              36.745069
            ],
            [
              -121.473021,
              36.746889
            ],
            [
              -121.476571,
              36.752342
            ],
            [
              -121.47972,
              36.762287
            ],
            [
              -121.480774,
              36.764023
            ],
            [
              -121.483917,
              36.764478
            ],
            [
              -121.487533,
              36.763949
            ],
            [
              -121.495738,
              36.761966
            ],
            [
              -121.520469,
              36.771537
            ],
            [
              -121.560563,
              36.805503
            ],
            [
              -121.597698,
              36.83735
            ],
            [
              -121.622089,
              36.846203
            ],
            [
              -121.642565,
              36.8737
            ],
            [
              -121.644001,
              36.893996
            ],
            [
              -121.661241,
              36.914177
            ],
            [
              -121.680621,
              36.903285
            ],
            [
              -121.699956,
              36.919683
            ],
            [
              -121.715417,
              36.908871
            ],
            [
              -121.745288,
              36.909341
            ],
            [
              -121.7725,
              36.883767
            ],
            [
              -121.786856,
              36.885038
            ],
            [
              -121.810551858284,
              36.8506482495196
            ],
            [
              -121.809363,
              36.848654
            ],
            [
              -121.791544,
              36.815186
            ],
            [
              -121.788278,
              36.803994
            ],
            [
              -121.805643,
              36.750239
            ],
            [
              -121.807062,
              36.714157
            ],
            [
              -121.814462,
              36.682858
            ],
            [
              -121.825052,
              36.657207
            ],
            [
              -121.831995,
              36.644856
            ],
            [
              -121.842263,
              36.630059
            ],
            [
              -121.860604,
              36.611136
            ],
            [
              -121.871364,
              36.604559
            ],
            [
              -121.886764,
              36.601459
            ],
            [
              -121.889064,
              36.601759
            ],
            [
              -121.890164,
              36.609259
            ],
            [
              -121.923866,
              36.634559
            ],
            [
              -121.929666,
              36.636959
            ],
            [
              -121.93643,
              36.636746
            ],
            [
              -121.938551,
              36.633908
            ],
            [
              -121.941666,
              36.618059
            ],
            [
              -121.970427,
              36.582754
            ],
            [
              -121.978592,
              36.580488
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "06",
      "COUNTYFP": "071",
      "COUNTYNS": "00277300",
      "AFFGEOID": "0500000US06071",
      "GEOID": "06071",
      "NAME": "San Bernardino",
      "LSAD": "06",
      "ALAND": 51948123813,
      "AWATER": 123845007,
      "County_state": "San Bernardino,06"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -117.785062,
              34.004809
            ],
            [
              -117.802539,
              33.975551
            ],
            [
              -117.783287,
              33.946411
            ],
            [
              -117.680289,
              33.877412
            ],
            [
              -117.673749,
              33.870831
            ],
            [
              -117.676286,
              33.888815
            ],
            [
              -117.654885,
              33.888815
            ],
            [
              -117.655375,
              33.924343
            ],
            [
              -117.610947,
              33.925114
            ],
            [
              -117.610386,
              33.971614
            ],
            [
              -117.558377,
              33.98842
            ],
            [
              -117.558269,
              34.018906
            ],
            [
              -117.558312,
              34.033451
            ],
            [
              -117.524313,
              34.033469
            ],
            [
              -117.375278,
              34.033875
            ],
            [
              -117.371588,
              34.019399
            ],
            [
              -117.370274,
              34.019444
            ],
            [
              -117.270602,
              34.018715
            ],
            [
              -117.225373,
              34.018715
            ],
            [
              -117.212372,
              34.004215
            ],
            [
              -117.121293,
              34.003916
            ],
            [
              -117.104737,
              34.004015
            ],
            [
              -116.929357,
              34.004914
            ],
            [
              -116.929558,
              34.034113
            ],
            [
              -116.719663,
              34.033824
            ],
            [
              -116.512252,
              34.032878
            ],
            [
              -116.300598,
              34.034401
            ],
            [
              -115.98389,
              34.035131
            ],
            [
              -115.692829,
              34.035313
            ],
            [
              -115.316212,
              34.03411
            ],
            [
              -115.316065,
              34.077843
            ],
            [
              -115.227265,
              34.077984
            ],
            [
              -114.435429,
              34.079727
            ],
            [
              -114.434181,
              34.087379
            ],
            [
              -114.43338,
              34.088413
            ],
            [
              -114.428026,
              34.092787
            ],
            [
              -114.426168,
              34.097042
            ],
            [
              -114.420499,
              34.103466
            ],
            [
              -114.415908,
              34.107636
            ],
            [
              -114.411681,
              34.110031
            ],
            [
              -114.405941,
              34.11154
            ],
            [
              -114.401352,
              34.111652
            ],
            [
              -114.390565,
              34.110084
            ],
            [
              -114.379234,
              34.115988
            ],
            [
              -114.369297,
              34.117517
            ],
            [
              -114.366521,
              34.118575
            ],
            [
              -114.360402,
              34.123577
            ],
            [
              -114.356373,
              34.130429
            ],
            [
              -114.353031,
              34.133121
            ],
            [
              -114.348052,
              34.134458
            ],
            [
              -114.336112,
              34.134035
            ],
            [
              -114.324576,
              34.136759
            ],
            [
              -114.320777,
              34.138635
            ],
            [
              -114.312206,
              34.144776
            ],
            [
              -114.292806,
              34.166725
            ],
            [
              -114.287294,
              34.170529
            ],
            [
              -114.275267,
              34.17215
            ],
            [
              -114.268267,
              34.17021
            ],
            [
              -114.254141,
              34.173831
            ],
            [
              -114.244191,
              34.179625
            ],
            [
              -114.240712,
              34.183232
            ],
            [
              -114.229715,
              34.186928
            ],
            [
              -114.227034,
              34.188866
            ],
            [
              -114.224941,
              34.193896
            ],
            [
              -114.225861,
              34.201774
            ],
            [
              -114.225194,
              34.203642
            ],
            [
              -114.223384,
              34.205136
            ],
            [
              -114.215454,
              34.208956
            ],
            [
              -114.211761,
              34.211539
            ],
            [
              -114.208253,
              34.215505
            ],
            [
              -114.190876,
              34.230858
            ],
            [
              -114.17805,
              34.239969
            ],
            [
              -114.176403,
              34.241512
            ],
            [
              -114.175948,
              34.242695
            ],
            [
              -114.174322,
              34.245468
            ],
            [
              -114.173119,
              34.247226
            ],
            [
              -114.166536,
              34.249647
            ],
            [
              -114.164476,
              34.251667
            ],
            [
              -114.163867,
              34.253349
            ],
            [
              -114.163122,
              34.255187
            ],
            [
              -114.161826,
              34.257038
            ],
            [
              -114.159697,
              34.258242
            ],
            [
              -114.156853,
              34.258415
            ],
            [
              -114.153346,
              34.258289
            ],
            [
              -114.147159,
              34.259564
            ],
            [
              -114.144779,
              34.259623
            ],
            [
              -114.139055,
              34.259538
            ],
            [
              -114.136185,
              34.261296
            ],
            [
              -114.134612,
              34.263518
            ],
            [
              -114.134427,
              34.266387
            ],
            [
              -114.134768,
              34.268965
            ],
            [
              -114.136671,
              34.274377
            ],
            [
              -114.137045,
              34.277018
            ],
            [
              -114.13605,
              34.280833
            ],
            [
              -114.138365,
              34.288564
            ],
            [
              -114.139534,
              34.295844
            ],
            [
              -114.138167,
              34.300936
            ],
            [
              -114.138282,
              34.30323
            ],
            [
              -114.14093,
              34.305919
            ],
            [
              -114.157206,
              34.317862
            ],
            [
              -114.168807,
              34.339513
            ],
            [
              -114.172845,
              34.344979
            ],
            [
              -114.176909,
              34.349306
            ],
            [
              -114.181145,
              34.352186
            ],
            [
              -114.185556,
              34.354386
            ],
            [
              -114.191094,
              34.356125
            ],
            [
              -114.199482,
              34.361373
            ],
            [
              -114.213774,
              34.36246
            ],
            [
              -114.226107,
              34.365916
            ],
            [
              -114.229686,
              34.368908
            ],
            [
              -114.234275,
              34.376662
            ],
            [
              -114.245261,
              34.385659
            ],
            [
              -114.248649,
              34.388113
            ],
            [
              -114.252739,
              34.3901
            ],
            [
              -114.264317,
              34.401329
            ],
            [
              -114.267521,
              34.402486
            ],
            [
              -114.280108,
              34.403147
            ],
            [
              -114.286802,
              34.40534
            ],
            [
              -114.288663,
              34.406623
            ],
            [
              -114.290219,
              34.408291
            ],
            [
              -114.291751,
              34.411104
            ],
            [
              -114.292226,
              34.417606
            ],
            [
              -114.294836,
              34.421389
            ],
            [
              -114.301016,
              34.426807
            ],
            [
              -114.312251,
              34.432726
            ],
            [
              -114.319054,
              34.435831
            ],
            [
              -114.32613,
              34.437251
            ],
            [
              -114.330669,
              34.445295
            ],
            [
              -114.332991,
              34.448082
            ],
            [
              -114.335372,
              34.450038
            ],
            [
              -114.339627,
              34.451435
            ],
            [
              -114.342615,
              34.451442
            ],
            [
              -114.356025,
              34.449744
            ],
            [
              -114.363404,
              34.447773
            ],
            [
              -114.373719,
              34.446938
            ],
            [
              -114.375789,
              34.447798
            ],
            [
              -114.378852,
              34.450376
            ],
            [
              -114.386699,
              34.457911
            ],
            [
              -114.387407,
              34.460492
            ],
            [
              -114.387187,
              34.462021
            ],
            [
              -114.383525,
              34.470405
            ],
            [
              -114.381701,
              34.47604
            ],
            [
              -114.381555,
              34.477883
            ],
            [
              -114.383038,
              34.488903
            ],
            [
              -114.382358,
              34.495757
            ],
            [
              -114.381402,
              34.499242
            ],
            [
              -114.378124,
              34.507288
            ],
            [
              -114.378223,
              34.516521
            ],
            [
              -114.380838,
              34.529724
            ],
            [
              -114.389603,
              34.542982
            ],
            [
              -114.405228,
              34.569637
            ],
            [
              -114.422382,
              34.580711
            ],
            [
              -114.429747,
              34.591734
            ],
            [
              -114.429747,
              34.595846
            ],
            [
              -114.43009,
              34.596874
            ],
            [
              -114.427502,
              34.599227
            ],
            [
              -114.425338,
              34.600842
            ],
            [
              -114.424326,
              34.602338
            ],
            [
              -114.424202,
              34.610453
            ],
            [
              -114.428648,
              34.614641
            ],
            [
              -114.438739,
              34.621455
            ],
            [
              -114.441398,
              34.630171
            ],
            [
              -114.441525,
              34.631529
            ],
            [
              -114.440294,
              34.63824
            ],
            [
              -114.441465,
              34.64253
            ],
            [
              -114.444276,
              34.646542
            ],
            [
              -114.449549,
              34.651423
            ],
            [
              -114.451753,
              34.654321
            ],
            [
              -114.451971,
              34.657166
            ],
            [
              -114.452628,
              34.659573
            ],
            [
              -114.451785,
              34.663891
            ],
            [
              -114.451753,
              34.665044
            ],
            [
              -114.451971,
              34.666795
            ],
            [
              -114.452628,
              34.668546
            ],
            [
              -114.454305,
              34.671234
            ],
            [
              -114.45491,
              34.673092
            ],
            [
              -114.455473,
              34.675768
            ],
            [
              -114.456567,
              34.677956
            ],
            [
              -114.462178,
              34.6858
            ],
            [
              -114.465246,
              34.691202
            ],
            [
              -114.46809,
              34.701786
            ],
            [
              -114.46862,
              34.707573
            ],
            [
              -114.470477,
              34.711368
            ],
            [
              -114.47162,
              34.712966
            ],
            [
              -114.473682,
              34.713964
            ],
            [
              -114.477297,
              34.714514
            ],
            [
              -114.481954,
              34.716036
            ],
            [
              -114.486768,
              34.7191
            ],
            [
              -114.490971,
              34.724848
            ],
            [
              -114.492017,
              34.725702
            ],
            [
              -114.495858,
              34.727956
            ],
            [
              -114.503361,
              34.731247
            ],
            [
              -114.510292,
              34.733582
            ],
            [
              -114.516619,
              34.736745
            ],
            [
              -114.521048,
              34.741173
            ],
            [
              -114.522619,
              34.74373
            ],
            [
              -114.525611,
              34.747005
            ],
            [
              -114.529615,
              34.750822
            ],
            [
              -114.540306,
              34.757109
            ],
            [
              -114.546884,
              34.761802
            ],
            [
              -114.552682,
              34.766871
            ],
            [
              -114.558653,
              34.773852
            ],
            [
              -114.57101,
              34.794294
            ],
            [
              -114.574569,
              34.805746
            ],
            [
              -114.576452,
              34.8153
            ],
            [
              -114.581126,
              34.826115
            ],
            [
              -114.586842,
              34.835672
            ],
            [
              -114.592339,
              34.841153
            ],
            [
              -114.600653,
              34.847361
            ],
            [
              -114.604255,
              34.849573
            ],
            [
              -114.619878,
              34.856873
            ],
            [
              -114.623939,
              34.859738
            ],
            [
              -114.630682,
              34.866352
            ],
            [
              -114.634382,
              34.87289
            ],
            [
              -114.635176,
              34.875003
            ],
            [
              -114.636768,
              34.885705
            ],
            [
              -114.636725,
              34.889107
            ],
            [
              -114.635425,
              34.895192
            ],
            [
              -114.630877,
              34.907263
            ],
            [
              -114.630552,
              34.911852
            ],
            [
              -114.633237,
              34.92123
            ],
            [
              -114.633253,
              34.924608
            ],
            [
              -114.632196,
              34.930628
            ],
            [
              -114.629753,
              34.938684
            ],
            [
              -114.629769,
              34.94304
            ],
            [
              -114.629811,
              34.94481
            ],
            [
              -114.631681,
              34.95131
            ],
            [
              -114.634953,
              34.958918
            ],
            [
              -114.635237,
              34.965149
            ],
            [
              -114.634607,
              34.96906
            ],
            [
              -114.629907,
              34.980791
            ],
            [
              -114.629015,
              34.986148
            ],
            [
              -114.62919,
              34.991887
            ],
            [
              -114.629928,
              34.99474
            ],
            [
              -114.633487,
              35.001857
            ],
            [
              -114.804249,
              35.139689
            ],
            [
              -114.80503,
              35.140284
            ],
            [
              -114.925381,
              35.237039
            ],
            [
              -114.92548,
              35.237054
            ],
            [
              -114.942216,
              35.249994
            ],
            [
              -115.043812,
              35.332012
            ],
            [
              -115.098018,
              35.37499
            ],
            [
              -115.102881,
              35.379371
            ],
            [
              -115.125816,
              35.39694
            ],
            [
              -115.145813,
              35.413182
            ],
            [
              -115.146788,
              35.413662
            ],
            [
              -115.160068,
              35.424129
            ],
            [
              -115.160599,
              35.424313
            ],
            [
              -115.271342,
              35.51266
            ],
            [
              -115.303743,
              35.538207
            ],
            [
              -115.366825365988,
              35.587832484379
            ],
            [
              -115.391535,
              35.607271
            ],
            [
              -115.393996,
              35.609344
            ],
            [
              -115.404537,
              35.617605
            ],
            [
              -115.406079,
              35.618613
            ],
            [
              -115.412908,
              35.624981
            ],
            [
              -115.500832,
              35.693382
            ],
            [
              -115.625838,
              35.792013
            ],
            [
              -115.627386,
              35.793846
            ],
            [
              -115.647202,
              35.808995
            ],
            [
              -115.647683,
              35.809358
            ],
            [
              -115.64802031181802,
              35.8096294219517
            ],
            [
              -115.653794,
              35.809214
            ],
            [
              -115.735745,
              35.809097
            ],
            [
              -115.735891,
              35.79362
            ],
            [
              -116.651095,
              35.795433
            ],
            [
              -117.632996,
              35.797251
            ],
            [
              -117.634251,
              35.709927
            ],
            [
              -117.651986,
              35.709934
            ],
            [
              -117.652319,
              35.680782
            ],
            [
              -117.616195,
              35.680856
            ],
            [
              -117.616395,
              35.651755
            ],
            [
              -117.63383,
              35.651569
            ],
            [
              -117.634771,
              35.564109
            ],
            [
              -117.630216,
              35.451041
            ],
            [
              -117.631299,
              35.274179
            ],
            [
              -117.63088,
              35.097604
            ],
            [
              -117.632011,
              34.82227
            ],
            [
              -117.667292,
              34.822526
            ],
            [
              -117.667087,
              34.67552
            ],
            [
              -117.667034,
              34.558008
            ],
            [
              -117.660052,
              34.498629
            ],
            [
              -117.655235,
              34.397222
            ],
            [
              -117.652685,
              34.366165
            ],
            [
              -117.652246,
              34.360809
            ],
            [
              -117.650459,
              34.339025
            ],
            [
              -117.646374,
              34.28917
            ],
            [
              -117.656255,
              34.249765
            ],
            [
              -117.663777,
              34.220334
            ],
            [
              -117.677405,
              34.166103
            ],
            [
              -117.678501,
              34.161993
            ],
            [
              -117.693545,
              34.121627
            ],
            [
              -117.70429,
              34.095055
            ],
            [
              -117.711067,
              34.079536
            ],
            [
              -117.730125,
              34.021371
            ],
            [
              -117.744066,
              34.019808
            ],
            [
              -117.766233,
              34.023289
            ],
            [
              -117.767752,
              34.019429
            ],
            [
              -117.767483,
              34.004611
            ],
            [
              -117.785062,
              34.004809
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "06",
      "COUNTYFP": "103",
      "COUNTYNS": "01692767",
      "AFFGEOID": "0500000US06103",
      "GEOID": "06103",
      "NAME": "Tehama",
      "LSAD": "06",
      "ALAND": 7638228772,
      "AWATER": 33767784,
      "County_state": "Tehama,06"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -123.059268,
              40.285484
            ],
            [
              -123.065426,
              40.286971
            ],
            [
              -123.051427,
              40.268143
            ],
            [
              -123.01858,
              40.262783
            ],
            [
              -122.97755,
              40.241285
            ],
            [
              -122.980027,
              40.214708
            ],
            [
              -122.970064,
              40.186306
            ],
            [
              -122.983937,
              40.171984
            ],
            [
              -122.989623,
              40.145496
            ],
            [
              -122.968568,
              40.139033
            ],
            [
              -122.973657,
              40.130025
            ],
            [
              -122.961088,
              40.099996
            ],
            [
              -122.973222,
              40.077553
            ],
            [
              -122.952831,
              40.059723
            ],
            [
              -122.94976,
              40.032101
            ],
            [
              -122.956378,
              40.01994
            ],
            [
              -122.951671,
              39.998271
            ],
            [
              -122.934013,
              39.978131
            ],
            [
              -122.926897,
              39.971275
            ],
            [
              -122.926821,
              39.945071
            ],
            [
              -122.909787,
              39.936385
            ],
            [
              -122.920203,
              39.917209
            ],
            [
              -122.950077,
              39.907718
            ],
            [
              -122.947732,
              39.894192
            ],
            [
              -122.95399,
              39.854685
            ],
            [
              -122.960147,
              39.845163
            ],
            [
              -122.946419,
              39.832851
            ],
            [
              -122.943602,
              39.801652
            ],
            [
              -122.937654,
              39.798156
            ],
            [
              -122.917545,
              39.79837
            ],
            [
              -122.911794,
              39.799485
            ],
            [
              -122.046471,
              39.797648
            ],
            [
              -122.06393,
              39.810634
            ],
            [
              -122.057308,
              39.828709
            ],
            [
              -122.069431,
              39.84053
            ],
            [
              -122.040789,
              39.879899
            ],
            [
              -122.044865,
              39.883771
            ],
            [
              -121.884518,
              39.883208
            ],
            [
              -121.873517,
              39.883995
            ],
            [
              -121.804041,
              39.884344
            ],
            [
              -121.777103,
              39.888519
            ],
            [
              -121.750634,
              39.906805
            ],
            [
              -121.746251,
              39.938532
            ],
            [
              -121.725321,
              39.966966
            ],
            [
              -121.703241,
              39.98423
            ],
            [
              -121.646032,
              39.982527
            ],
            [
              -121.63544,
              39.996822
            ],
            [
              -121.636174,
              40.02581
            ],
            [
              -121.626086,
              40.039496
            ],
            [
              -121.595647,
              40.057714
            ],
            [
              -121.595802,
              40.093458
            ],
            [
              -121.586407,
              40.100522
            ],
            [
              -121.510559,
              40.100515
            ],
            [
              -121.490873,
              40.108044
            ],
            [
              -121.480954,
              40.122366
            ],
            [
              -121.452898,
              40.122125
            ],
            [
              -121.442869,
              40.129647
            ],
            [
              -121.436886,
              40.151905
            ],
            [
              -121.445123,
              40.177692
            ],
            [
              -121.439187,
              40.195085
            ],
            [
              -121.407356,
              40.191404
            ],
            [
              -121.385028,
              40.210297
            ],
            [
              -121.368395,
              40.21223
            ],
            [
              -121.361713,
              40.225581
            ],
            [
              -121.367098,
              40.245572
            ],
            [
              -121.349,
              40.264454
            ],
            [
              -121.350322,
              40.275883
            ],
            [
              -121.3768,
              40.297989
            ],
            [
              -121.356626,
              40.29747
            ],
            [
              -121.342264,
              40.309751
            ],
            [
              -121.368909,
              40.32564
            ],
            [
              -121.381826,
              40.340513
            ],
            [
              -121.412066,
              40.342719
            ],
            [
              -121.429353,
              40.350349
            ],
            [
              -121.47242,
              40.35162
            ],
            [
              -121.480992,
              40.366614
            ],
            [
              -121.47354,
              40.374556
            ],
            [
              -121.484278,
              40.396559
            ],
            [
              -121.474029,
              40.423732
            ],
            [
              -121.49788,
              40.432003
            ],
            [
              -121.497635,
              40.445591
            ],
            [
              -121.522988,
              40.445378
            ],
            [
              -121.569044,
              40.444211
            ],
            [
              -121.581893,
              40.435813
            ],
            [
              -121.587914,
              40.432293
            ],
            [
              -121.603345,
              40.433718
            ],
            [
              -121.612899,
              40.43878
            ],
            [
              -121.61816,
              40.437889
            ],
            [
              -121.621373,
              40.437167
            ],
            [
              -121.626275,
              40.438108
            ],
            [
              -121.62996,
              40.437733
            ],
            [
              -121.633431,
              40.437546
            ],
            [
              -121.63749,
              40.437043
            ],
            [
              -121.639713,
              40.436534
            ],
            [
              -121.643354,
              40.43556
            ],
            [
              -121.646584,
              40.434872
            ],
            [
              -121.648798,
              40.436148
            ],
            [
              -121.651958,
              40.438384
            ],
            [
              -121.654103,
              40.440521
            ],
            [
              -121.656137,
              40.441877
            ],
            [
              -121.657493,
              40.441659
            ],
            [
              -121.659353,
              40.442417
            ],
            [
              -121.660446,
              40.443795
            ],
            [
              -121.662915,
              40.445117
            ],
            [
              -121.665448,
              40.445762
            ],
            [
              -121.667198,
              40.44642
            ],
            [
              -121.669656,
              40.447155
            ],
            [
              -121.671615,
              40.44847
            ],
            [
              -121.671666,
              40.449821
            ],
            [
              -121.673771,
              40.450669
            ],
            [
              -121.675275,
              40.450303
            ],
            [
              -121.678339,
              40.450512
            ],
            [
              -121.681074,
              40.451296
            ],
            [
              -121.682906,
              40.452164
            ],
            [
              -121.685277,
              40.452999
            ],
            [
              -121.687629,
              40.452632
            ],
            [
              -121.691918,
              40.451406
            ],
            [
              -121.695448,
              40.44965
            ],
            [
              -121.699851,
              40.44852
            ],
            [
              -121.702684,
              40.449101
            ],
            [
              -121.70449,
              40.450171
            ],
            [
              -121.708088,
              40.45068
            ],
            [
              -121.711614,
              40.449708
            ],
            [
              -121.714344,
              40.449021
            ],
            [
              -121.716165,
              40.448432
            ],
            [
              -121.718744,
              40.448757
            ],
            [
              -121.720964,
              40.447217
            ],
            [
              -121.72245,
              40.444876
            ],
            [
              -121.724684,
              40.443235
            ],
            [
              -121.726421,
              40.441114
            ],
            [
              -121.729686,
              40.438912
            ],
            [
              -121.73167,
              40.436913
            ],
            [
              -121.734973,
              40.435709
            ],
            [
              -121.738101,
              40.435846
            ],
            [
              -121.741182,
              40.436185
            ],
            [
              -121.743577,
              40.436501
            ],
            [
              -121.745667,
              40.43675
            ],
            [
              -121.747937,
              40.436804
            ],
            [
              -121.751099,
              40.436399
            ],
            [
              -121.756224,
              40.436391
            ],
            [
              -121.762546,
              40.436679
            ],
            [
              -121.767433,
              40.437364
            ],
            [
              -121.772219,
              40.438302
            ],
            [
              -121.775414,
              40.437819
            ],
            [
              -121.776476,
              40.438291
            ],
            [
              -121.778442,
              40.438212
            ],
            [
              -121.780298,
              40.439695
            ],
            [
              -121.782568,
              40.440957
            ],
            [
              -121.784809,
              40.44249
            ],
            [
              -121.786682,
              40.443186
            ],
            [
              -121.788905,
              40.443489
            ],
            [
              -121.789846,
              40.442475
            ],
            [
              -121.791858,
              40.442651
            ],
            [
              -121.794624,
              40.442879
            ],
            [
              -121.798511,
              40.443122
            ],
            [
              -121.801808,
              40.443375
            ],
            [
              -121.804075,
              40.443596
            ],
            [
              -121.805983,
              40.444505
            ],
            [
              -121.807932,
              40.44425
            ],
            [
              -121.810051,
              40.443024
            ],
            [
              -121.814566,
              40.441203
            ],
            [
              -121.817689,
              40.440875
            ],
            [
              -121.821614,
              40.440349
            ],
            [
              -121.825939,
              40.439145
            ],
            [
              -121.830127,
              40.438007
            ],
            [
              -121.834734,
              40.437703
            ],
            [
              -121.838442,
              40.437536
            ],
            [
              -121.840959,
              40.439894
            ],
            [
              -121.843861,
              40.440247
            ],
            [
              -121.847333,
              40.44107
            ],
            [
              -121.85135,
              40.442596
            ],
            [
              -121.854648,
              40.441445
            ],
            [
              -121.857162,
              40.441837
            ],
            [
              -121.859786,
              40.441014
            ],
            [
              -121.863592,
              40.440596
            ],
            [
              -121.866912,
              40.438701
            ],
            [
              -121.86986,
              40.437407
            ],
            [
              -121.873204,
              40.436231
            ],
            [
              -121.877034,
              40.436912
            ],
            [
              -121.880754,
              40.435206
            ],
            [
              -121.883239,
              40.433269
            ],
            [
              -121.886281,
              40.430646
            ],
            [
              -121.888632,
              40.428664
            ],
            [
              -121.891378,
              40.427243
            ],
            [
              -121.893577,
              40.427145
            ],
            [
              -121.895777,
              40.426377
            ],
            [
              -121.896446,
              40.425411
            ],
            [
              -121.898502,
              40.423402
            ],
            [
              -121.899738,
              40.422408
            ],
            [
              -121.901902,
              40.421031
            ],
            [
              -121.903381,
              40.422036
            ],
            [
              -121.906644,
              40.421669
            ],
            [
              -121.911335,
              40.423153
            ],
            [
              -121.913742,
              40.42462
            ],
            [
              -121.914797,
              40.425993
            ],
            [
              -121.915904,
              40.425274
            ],
            [
              -121.918455,
              40.42433
            ],
            [
              -121.921351,
              40.423309
            ],
            [
              -121.924542,
              40.42041
            ],
            [
              -121.928739,
              40.418301
            ],
            [
              -121.93264,
              40.418065
            ],
            [
              -121.936161,
              40.417412
            ],
            [
              -121.93961,
              40.416198
            ],
            [
              -121.942153,
              40.415616
            ],
            [
              -121.946751,
              40.416972
            ],
            [
              -121.948634,
              40.418125
            ],
            [
              -121.950437,
              40.41885
            ],
            [
              -121.951616,
              40.417867
            ],
            [
              -121.955654,
              40.419042
            ],
            [
              -121.95985,
              40.41914
            ],
            [
              -121.962782,
              40.420919
            ],
            [
              -121.967338,
              40.422131
            ],
            [
              -121.972382,
              40.421982
            ],
            [
              -121.975586,
              40.421596
            ],
            [
              -121.979065,
              40.422358
            ],
            [
              -121.983592,
              40.422983
            ],
            [
              -121.985489,
              40.423992
            ],
            [
              -121.989204,
              40.423392
            ],
            [
              -121.990879,
              40.423886
            ],
            [
              -121.992474,
              40.424033
            ],
            [
              -121.992201,
              40.424731
            ],
            [
              -121.993783,
              40.425708
            ],
            [
              -121.995528,
              40.424504
            ],
            [
              -121.996727,
              40.423411
            ],
            [
              -121.999774,
              40.423145
            ],
            [
              -122.001416,
              40.424666
            ],
            [
              -122.006396,
              40.426186
            ],
            [
              -122.010316,
              40.426558
            ],
            [
              -122.013886,
              40.423923
            ],
            [
              -122.018142,
              40.422217
            ],
            [
              -122.020305,
              40.424153
            ],
            [
              -122.030086,
              40.420987
            ],
            [
              -122.030861,
              40.419161
            ],
            [
              -122.033302,
              40.418124
            ],
            [
              -122.039784,
              40.41843
            ],
            [
              -122.049424,
              40.419886
            ],
            [
              -122.055476,
              40.418428
            ],
            [
              -122.060449,
              40.416618
            ],
            [
              -122.067045,
              40.415675
            ],
            [
              -122.078656,
              40.412676
            ],
            [
              -122.085022,
              40.412714
            ],
            [
              -122.090396,
              40.41147
            ],
            [
              -122.090874,
              40.41277
            ],
            [
              -122.092434,
              40.414066
            ],
            [
              -122.093496,
              40.412292
            ],
            [
              -122.094925,
              40.410505
            ],
            [
              -122.098393,
              40.413304
            ],
            [
              -122.100201,
              40.413773
            ],
            [
              -122.101726,
              40.412671
            ],
            [
              -122.109551,
              40.408539
            ],
            [
              -122.115706,
              40.405659
            ],
            [
              -122.120269,
              40.404266
            ],
            [
              -122.124501,
              40.402978
            ],
            [
              -122.126781,
              40.403903
            ],
            [
              -122.129786,
              40.402585
            ],
            [
              -122.129444,
              40.400653
            ],
            [
              -122.13588,
              40.397911
            ],
            [
              -122.145281,
              40.398066
            ],
            [
              -122.153381,
              40.39731
            ],
            [
              -122.160464,
              40.394969
            ],
            [
              -122.16409,
              40.393234
            ],
            [
              -122.171279,
              40.393679
            ],
            [
              -122.177283,
              40.392127
            ],
            [
              -122.18388,
              40.390556
            ],
            [
              -122.186416,
              40.388255
            ],
            [
              -122.184608,
              40.384505
            ],
            [
              -122.179398,
              40.384151
            ],
            [
              -122.175636,
              40.381496
            ],
            [
              -122.174789,
              40.379615
            ],
            [
              -122.177865,
              40.379744
            ],
            [
              -122.190197,
              40.384092
            ],
            [
              -122.192075,
              40.387928
            ],
            [
              -122.195634,
              40.388138
            ],
            [
              -122.197919,
              40.383149
            ],
            [
              -122.205213,
              40.379868
            ],
            [
              -122.217008,
              40.384736
            ],
            [
              -122.230297,
              40.388805
            ],
            [
              -122.250647,
              40.387127
            ],
            [
              -122.264393,
              40.379786
            ],
            [
              -122.280852,
              40.375787
            ],
            [
              -122.291472,
              40.379222
            ],
            [
              -122.302274,
              40.374359
            ],
            [
              -122.321033,
              40.374875
            ],
            [
              -122.33339,
              40.373877
            ],
            [
              -122.345625,
              40.371106
            ],
            [
              -122.360137,
              40.372908
            ],
            [
              -122.375396,
              40.372718
            ],
            [
              -122.389789,
              40.374051
            ],
            [
              -122.402803,
              40.374284
            ],
            [
              -122.415281,
              40.377664
            ],
            [
              -122.428865,
              40.375515
            ],
            [
              -122.44951,
              40.375927
            ],
            [
              -122.461449,
              40.375444
            ],
            [
              -122.466179,
              40.380958
            ],
            [
              -122.475396,
              40.384181
            ],
            [
              -122.482217,
              40.380769
            ],
            [
              -122.496043,
              40.382914
            ],
            [
              -122.506403,
              40.389458
            ],
            [
              -122.516279,
              40.39079
            ],
            [
              -122.524747,
              40.392395
            ],
            [
              -122.522893,
              40.390266
            ],
            [
              -122.527354,
              40.386149
            ],
            [
              -122.531366,
              40.382214
            ],
            [
              -122.542168,
              40.381347
            ],
            [
              -122.546149,
              40.377526
            ],
            [
              -122.55327,
              40.372962
            ],
            [
              -122.563262,
              40.365892
            ],
            [
              -122.572208,
              40.367127
            ],
            [
              -122.58226,
              40.365021
            ],
            [
              -122.583216,
              40.361382
            ],
            [
              -122.599785,
              40.354697
            ],
            [
              -122.613897,
              40.346613
            ],
            [
              -122.623883,
              40.344046
            ],
            [
              -122.632703,
              40.338121
            ],
            [
              -122.643024,
              40.333205
            ],
            [
              -122.654153,
              40.328824
            ],
            [
              -122.669458,
              40.330032
            ],
            [
              -122.678799,
              40.336565
            ],
            [
              -122.687608,
              40.337162
            ],
            [
              -122.689934,
              40.338896
            ],
            [
              -122.692775,
              40.340801
            ],
            [
              -122.697395,
              40.34258
            ],
            [
              -122.700461,
              40.343271
            ],
            [
              -122.705675,
              40.345091
            ],
            [
              -122.709225,
              40.347967
            ],
            [
              -122.713741,
              40.348344
            ],
            [
              -122.716418,
              40.347811
            ],
            [
              -122.720889,
              40.348142
            ],
            [
              -122.724597,
              40.347323
            ],
            [
              -122.72859,
              40.346835
            ],
            [
              -122.731132,
              40.346914
            ],
            [
              -122.732435,
              40.349219
            ],
            [
              -122.731845,
              40.35109
            ],
            [
              -122.731968,
              40.355261
            ],
            [
              -122.731288,
              40.35696
            ],
            [
              -122.731177,
              40.358728
            ],
            [
              -122.732583,
              40.35946
            ],
            [
              -122.735988,
              40.361741
            ],
            [
              -122.738794,
              40.364297
            ],
            [
              -122.744479,
              40.365341
            ],
            [
              -122.747516,
              40.365449
            ],
            [
              -122.750963,
              40.364554
            ],
            [
              -122.752436,
              40.362835
            ],
            [
              -122.751927,
              40.36161
            ],
            [
              -122.753242,
              40.36043
            ],
            [
              -122.755123,
              40.360909
            ],
            [
              -122.756472,
              40.361794
            ],
            [
              -122.757081,
              40.362755
            ],
            [
              -122.75884,
              40.363289
            ],
            [
              -122.760319,
              40.3629
            ],
            [
              -122.762883,
              40.362417
            ],
            [
              -122.76638,
              40.361683
            ],
            [
              -122.770458,
              40.360822
            ],
            [
              -122.773,
              40.359708
            ],
            [
              -122.775148,
              40.358698
            ],
            [
              -122.778486,
              40.35854
            ],
            [
              -122.782571,
              40.358108
            ],
            [
              -122.784726,
              40.356449
            ],
            [
              -122.787217,
              40.355621
            ],
            [
              -122.789981,
              40.355627
            ],
            [
              -122.792522,
              40.356151
            ],
            [
              -122.79627,
              40.355359
            ],
            [
              -122.800218,
              40.355497
            ],
            [
              -122.803427,
              40.355618
            ],
            [
              -122.807189,
              40.355393
            ],
            [
              -122.810017,
              40.35374
            ],
            [
              -122.812831,
              40.351268
            ],
            [
              -122.816212,
              40.350994
            ],
            [
              -122.820074,
              40.350466
            ],
            [
              -122.823455,
              40.349659
            ],
            [
              -122.826563,
              40.349801
            ],
            [
              -122.830396,
              40.349449
            ],
            [
              -122.831803,
              40.348565
            ],
            [
              -122.833619,
              40.348032
            ],
            [
              -122.834861,
              40.348614
            ],
            [
              -122.837272,
              40.347751
            ],
            [
              -122.839109,
              40.345592
            ],
            [
              -122.842138,
              40.345207
            ],
            [
              -122.845404,
              40.344899
            ],
            [
              -122.849179,
              40.343799
            ],
            [
              -122.852617,
              40.343551
            ],
            [
              -122.854813,
              40.343424
            ],
            [
              -122.857139,
              40.344597
            ],
            [
              -122.860807,
              40.344932
            ],
            [
              -122.863198,
              40.345672
            ],
            [
              -122.863845,
              40.347039
            ],
            [
              -122.86559,
              40.347912
            ],
            [
              -122.867083,
              40.347302
            ],
            [
              -122.869947,
              40.34719
            ],
            [
              -122.871247,
              40.34836
            ],
            [
              -122.87279,
              40.34854
            ],
            [
              -122.874283,
              40.348028
            ],
            [
              -122.876383,
              40.346847
            ],
            [
              -122.877661,
              40.346732
            ],
            [
              -122.878329,
              40.34565
            ],
            [
              -122.8781,
              40.345052
            ],
            [
              -122.879069,
              40.344887
            ],
            [
              -122.880448,
              40.344267
            ],
            [
              -122.880241,
              40.343196
            ],
            [
              -122.88124,
              40.341972
            ],
            [
              -122.882459,
              40.342566
            ],
            [
              -122.883723,
              40.342396
            ],
            [
              -122.883551,
              40.341583
            ],
            [
              -122.883027,
              40.341006
            ],
            [
              -122.883402,
              40.339364
            ],
            [
              -122.884946,
              40.338366
            ],
            [
              -122.887064,
              40.336906
            ],
            [
              -122.8882,
              40.334731
            ],
            [
              -122.890224,
              40.333947
            ],
            [
              -122.892034,
              40.332119
            ],
            [
              -122.89398,
              40.33028
            ],
            [
              -122.895186,
              40.328731
            ],
            [
              -122.896472,
              40.327177
            ],
            [
              -122.897894,
              40.325442
            ],
            [
              -122.898059,
              40.324696
            ],
            [
              -122.899021,
              40.324256
            ],
            [
              -122.899911,
              40.324257
            ],
            [
              -122.900851,
              40.323477
            ],
            [
              -122.901225,
              40.322725
            ],
            [
              -122.900981,
              40.322555
            ],
            [
              -122.901254,
              40.32172
            ],
            [
              -122.901885,
              40.321879
            ],
            [
              -122.90241,
              40.3211
            ],
            [
              -122.902001,
              40.320259
            ],
            [
              -122.902202,
              40.319013
            ],
            [
              -122.903035,
              40.318282
            ],
            [
              -122.903129,
              40.317025
            ],
            [
              -122.903811,
              40.31602
            ],
            [
              -122.904765,
              40.316372
            ],
            [
              -122.90564,
              40.317646
            ],
            [
              -122.907068,
              40.3173
            ],
            [
              -122.907111,
              40.316598
            ],
            [
              -122.908805,
              40.315703
            ],
            [
              -122.908849,
              40.313687
            ],
            [
              -122.909351,
              40.312935
            ],
            [
              -122.910521,
              40.312748
            ],
            [
              -122.910843,
              40.312996
            ],
            [
              -122.911439,
              40.31265
            ],
            [
              -122.91131,
              40.312221
            ],
            [
              -122.91154,
              40.31159
            ],
            [
              -122.912631,
              40.311024
            ],
            [
              -122.913119,
              40.310382
            ],
            [
              -122.914274,
              40.310464
            ],
            [
              -122.914647,
              40.310811
            ],
            [
              -122.916448,
              40.31052
            ],
            [
              -122.916512,
              40.309361
            ],
            [
              -122.917323,
              40.30896
            ],
            [
              -122.918156,
              40.308219
            ],
            [
              -122.91789,
              40.307642
            ],
            [
              -122.918795,
              40.306692
            ],
            [
              -122.920193,
              40.306681
            ],
            [
              -122.92142,
              40.307236
            ],
            [
              -122.922754,
              40.307099
            ],
            [
              -122.923866,
              40.307605
            ],
            [
              -122.924318,
              40.307143
            ],
            [
              -122.924526,
              40.306627
            ],
            [
              -122.925983,
              40.306682
            ],
            [
              -122.926973,
              40.307034
            ],
            [
              -122.928988,
              40.30755
            ],
            [
              -122.930157,
              40.307869
            ],
            [
              -122.929992,
              40.308841
            ],
            [
              -122.929756,
              40.309341
            ],
            [
              -122.931628,
              40.310187
            ],
            [
              -122.933213,
              40.310615
            ],
            [
              -122.934081,
              40.312246
            ],
            [
              -122.93411,
              40.313097
            ],
            [
              -122.935488,
              40.313032
            ],
            [
              -122.936341,
              40.312411
            ],
            [
              -122.938336,
              40.312076
            ],
            [
              -122.94033,
              40.312307
            ],
            [
              -122.942848,
              40.311801
            ],
            [
              -122.943437,
              40.310549
            ],
            [
              -122.944627,
              40.310302
            ],
            [
              -122.945223,
              40.310593
            ],
            [
              -122.946414,
              40.310165
            ],
            [
              -122.946363,
              40.309319
            ],
            [
              -122.947741,
              40.308088
            ],
            [
              -122.949082,
              40.308066
            ],
            [
              -122.950187,
              40.30828
            ],
            [
              -122.950151,
              40.308923
            ],
            [
              -122.950625,
              40.309955
            ],
            [
              -122.951974,
              40.310169
            ],
            [
              -122.954233,
              40.309686
            ],
            [
              -122.956336,
              40.310537
            ],
            [
              -122.95937,
              40.310685
            ],
            [
              -122.963273,
              40.310607
            ],
            [
              -122.965475,
              40.310623
            ],
            [
              -122.967154,
              40.31037
            ],
            [
              -122.969306,
              40.310589
            ],
            [
              -122.970727,
              40.310967
            ],
            [
              -122.972134,
              40.312263
            ],
            [
              -122.973369,
              40.313532
            ],
            [
              -122.975356,
              40.314487
            ],
            [
              -122.976196,
              40.315035
            ],
            [
              -122.977602,
              40.314365
            ],
            [
              -122.978606,
              40.313574
            ],
            [
              -122.980923,
              40.313282
            ],
            [
              -122.982724,
              40.313545
            ],
            [
              -122.9839,
              40.313001
            ],
            [
              -122.985198,
              40.312149
            ],
            [
              -122.985707,
              40.311715
            ],
            [
              -122.98674,
              40.311522
            ],
            [
              -122.987221,
              40.311796
            ],
            [
              -122.988347,
              40.311302
            ],
            [
              -122.988999,
              40.310631
            ],
            [
              -122.99136,
              40.31035
            ],
            [
              -122.993016,
              40.309756
            ],
            [
              -122.995692,
              40.309447
            ],
            [
              -122.996796,
              40.308376
            ],
            [
              -122.997879,
              40.308062
            ],
            [
              -122.998854,
              40.307859
            ],
            [
              -122.999134,
              40.308188
            ],
            [
              -123.001779,
              40.307748
            ],
            [
              -123.007942,
              40.306911
            ],
            [
              -123.012111,
              40.306292
            ],
            [
              -123.014394,
              40.303108
            ],
            [
              -123.020678,
              40.302594
            ],
            [
              -123.023382,
              40.303852
            ],
            [
              -123.027328,
              40.30231
            ],
            [
              -123.029488,
              40.300158
            ],
            [
              -123.033915,
              40.298649
            ],
            [
              -123.037216,
              40.294662
            ],
            [
              -123.040839,
              40.291296
            ],
            [
              -123.046648,
              40.291836
            ],
            [
              -123.049697,
              40.28926
            ],
            [
              -123.059268,
              40.285484
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "06",
      "COUNTYFP": "057",
      "COUNTYNS": "01682927",
      "AFFGEOID": "0500000US06057",
      "GEOID": "06057",
      "NAME": "Nevada",
      "LSAD": "06",
      "ALAND": 2480587331,
      "AWATER": 41531993,
      "County_state": "Nevada,06"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -121.27953,
              39.230537
            ],
            [
              -121.279533,
              39.034618
            ],
            [
              -121.232735,
              39.024556
            ],
            [
              -121.217431,
              39.012475
            ],
            [
              -121.18637,
              39.012867
            ],
            [
              -121.137397,
              39.037858
            ],
            [
              -121.104247,
              39.01185
            ],
            [
              -121.090272,
              39.014267
            ],
            [
              -121.067546,
              39.005371
            ],
            [
              -121.041608,
              39.013791
            ],
            [
              -121.02901,
              39.038427
            ],
            [
              -121.008303,
              39.052055
            ],
            [
              -121.007072,
              39.061218
            ],
            [
              -120.991588,
              39.076878
            ],
            [
              -120.988717,
              39.111777
            ],
            [
              -120.979315,
              39.110402
            ],
            [
              -120.94749,
              39.143395
            ],
            [
              -120.911243,
              39.164035
            ],
            [
              -120.908996,
              39.170173
            ],
            [
              -120.868849,
              39.180737
            ],
            [
              -120.857909,
              39.206235
            ],
            [
              -120.816647,
              39.229928
            ],
            [
              -120.804249,
              39.233266
            ],
            [
              -120.756595,
              39.264235
            ],
            [
              -120.727893,
              39.288081
            ],
            [
              -120.69111,
              39.304134
            ],
            [
              -120.645795,
              39.315155
            ],
            [
              -120.5546,
              39.315596
            ],
            [
              -120.322378,
              39.316427
            ],
            [
              -120.036657,
              39.316334
            ],
            [
              -120.010253,
              39.316328
            ],
            [
              -120.00531557607401,
              39.3164525335553
            ],
            [
              -120.00471,
              39.330488
            ],
            [
              -120.00443,
              39.374908
            ],
            [
              -120.003117296548,
              39.445043727450496
            ],
            [
              -120.007952,
              39.4451
            ],
            [
              -120.020267,
              39.445116
            ],
            [
              -120.051767,
              39.44512
            ],
            [
              -120.078185,
              39.445107
            ],
            [
              -120.104315,
              39.445108
            ],
            [
              -120.137518,
              39.445395
            ],
            [
              -120.18609,
              39.445511
            ],
            [
              -120.218601,
              39.445651
            ],
            [
              -120.292756,
              39.446156
            ],
            [
              -120.355649,
              39.446159
            ],
            [
              -120.400407,
              39.446149
            ],
            [
              -120.435943,
              39.44611
            ],
            [
              -120.457544,
              39.446125
            ],
            [
              -120.50107,
              39.446056
            ],
            [
              -120.505933,
              39.447542
            ],
            [
              -120.507619,
              39.45074
            ],
            [
              -120.507604,
              39.453213
            ],
            [
              -120.509382,
              39.454643
            ],
            [
              -120.5119,
              39.454944
            ],
            [
              -120.514585,
              39.455276
            ],
            [
              -120.516577,
              39.454918
            ],
            [
              -120.518141,
              39.455722
            ],
            [
              -120.518042,
              39.456733
            ],
            [
              -120.519285,
              39.45721
            ],
            [
              -120.520056,
              39.457191
            ],
            [
              -120.520773,
              39.457008
            ],
            [
              -120.521796,
              39.458349
            ],
            [
              -120.52355,
              39.459049
            ],
            [
              -120.525158,
              39.459155
            ],
            [
              -120.527235,
              39.460429
            ],
            [
              -120.529295,
              39.461387
            ],
            [
              -120.530997,
              39.461937
            ],
            [
              -120.532542,
              39.463114
            ],
            [
              -120.534507,
              39.46453
            ],
            [
              -120.534705,
              39.466556
            ],
            [
              -120.535112,
              39.469251
            ],
            [
              -120.533731,
              39.473351
            ],
            [
              -120.534386,
              39.476934
            ],
            [
              -120.534442,
              39.479677
            ],
            [
              -120.534721,
              39.48371
            ],
            [
              -120.538398,
              39.486029
            ],
            [
              -120.541828,
              39.491019
            ],
            [
              -120.547825,
              39.49371
            ],
            [
              -120.550245,
              39.49812
            ],
            [
              -120.550323,
              39.502653
            ],
            [
              -120.554724,
              39.505419
            ],
            [
              -120.556138,
              39.509744
            ],
            [
              -120.55602,
              39.511926
            ],
            [
              -120.557066,
              39.514151
            ],
            [
              -120.558677,
              39.514417
            ],
            [
              -120.560998,
              39.51617
            ],
            [
              -120.565103,
              39.515563
            ],
            [
              -120.569208,
              39.517103
            ],
            [
              -120.571733,
              39.519769
            ],
            [
              -120.574724,
              39.520975
            ],
            [
              -120.575349,
              39.521326
            ],
            [
              -120.575777,
              39.521574
            ],
            [
              -120.575304,
              39.521961
            ],
            [
              -120.575159,
              39.522304
            ],
            [
              -120.576525,
              39.522599
            ],
            [
              -120.577859,
              39.523271
            ],
            [
              -120.580667,
              39.523104
            ],
            [
              -120.582781,
              39.522463
            ],
            [
              -120.584826,
              39.522195
            ],
            [
              -120.58732,
              39.523233
            ],
            [
              -120.589899,
              39.5234
            ],
            [
              -120.591813,
              39.522506
            ],
            [
              -120.593772,
              39.522025
            ],
            [
              -120.596026,
              39.521154
            ],
            [
              -120.598416,
              39.521841
            ],
            [
              -120.601214,
              39.521552
            ],
            [
              -120.606021,
              39.521133
            ],
            [
              -120.611445,
              39.521565
            ],
            [
              -120.615786,
              39.521307
            ],
            [
              -120.62115,
              39.521417
            ],
            [
              -120.623881,
              39.520167
            ],
            [
              -120.626049,
              39.519932
            ],
            [
              -120.627239,
              39.521253
            ],
            [
              -120.627498,
              39.522565
            ],
            [
              -120.628925,
              39.523734
            ],
            [
              -120.630726,
              39.524721
            ],
            [
              -120.63171,
              39.524308
            ],
            [
              -120.635128,
              39.523765
            ],
            [
              -120.638635,
              39.524478
            ],
            [
              -120.641606,
              39.523127
            ],
            [
              -120.643658,
              39.523867
            ],
            [
              -120.646847,
              39.52376
            ],
            [
              -120.650433,
              39.523418
            ],
            [
              -120.652516,
              39.524406
            ],
            [
              -120.653661,
              39.526073
            ],
            [
              -120.655217,
              39.526699
            ],
            [
              -120.6573,
              39.526084
            ],
            [
              -120.658452,
              39.524859
            ],
            [
              -120.66071,
              39.523522
            ],
            [
              -120.664037,
              39.522782
            ],
            [
              -120.667203,
              39.521954
            ],
            [
              -120.670407,
              39.520906
            ],
            [
              -120.672529,
              39.520673
            ],
            [
              -120.674626,
              39.519139
            ],
            [
              -120.677503,
              39.518057
            ],
            [
              -120.678228,
              39.515603
            ],
            [
              -120.680387,
              39.51279
            ],
            [
              -120.683072,
              39.51187
            ],
            [
              -120.683851,
              39.510868
            ],
            [
              -120.685953,
              39.509375
            ],
            [
              -120.686743,
              39.506696
            ],
            [
              -120.689031,
              39.505903
            ],
            [
              -120.691534,
              39.505399
            ],
            [
              -120.693922,
              39.50487
            ],
            [
              -120.695784,
              39.503659
            ],
            [
              -120.69808,
              39.502628
            ],
            [
              -120.701384,
              39.502135
            ],
            [
              -120.70294,
              39.498931
            ],
            [
              -120.704985,
              39.496971
            ],
            [
              -120.706343,
              39.49484
            ],
            [
              -120.707678,
              39.492808
            ],
            [
              -120.709418,
              39.490761
            ],
            [
              -120.712271,
              39.490173
            ],
            [
              -120.713858,
              39.489331
            ],
            [
              -120.715399,
              39.489329
            ],
            [
              -120.717009,
              39.488609
            ],
            [
              -120.716773,
              39.487946
            ],
            [
              -120.715483,
              39.486232
            ],
            [
              -120.715002,
              39.48443
            ],
            [
              -120.714338,
              39.483624
            ],
            [
              -120.714743,
              39.481469
            ],
            [
              -120.716353,
              39.480424
            ],
            [
              -120.719245,
              39.478864
            ],
            [
              -120.722113,
              39.47638
            ],
            [
              -120.724799,
              39.473855
            ],
            [
              -120.72814,
              39.471497
            ],
            [
              -120.73014,
              39.471185
            ],
            [
              -120.732192,
              39.469842
            ],
            [
              -120.734923,
              39.469864
            ],
            [
              -120.737792,
              39.47001
            ],
            [
              -120.739799,
              39.468341
            ],
            [
              -120.74282,
              39.467283
            ],
            [
              -120.743255,
              39.465796
            ],
            [
              -120.742163,
              39.463797
            ],
            [
              -120.742171,
              39.461698
            ],
            [
              -120.74523,
              39.459681
            ],
            [
              -120.748115,
              39.459223
            ],
            [
              -120.749244,
              39.459551
            ],
            [
              -120.749678,
              39.458636
            ],
            [
              -120.749595,
              39.4579
            ],
            [
              -120.751159,
              39.457588
            ],
            [
              -120.752159,
              39.45813
            ],
            [
              -120.753463,
              39.457538
            ],
            [
              -120.753669,
              39.45638
            ],
            [
              -120.755546,
              39.454439
            ],
            [
              -120.755516,
              39.452938
            ],
            [
              -120.75785,
              39.452388
            ],
            [
              -120.76033,
              39.453612
            ],
            [
              -120.763679,
              39.453352
            ],
            [
              -120.765083,
              39.451221
            ],
            [
              -120.768516,
              39.450557
            ],
            [
              -120.771193,
              39.449907
            ],
            [
              -120.774735,
              39.449289
            ],
            [
              -120.774918,
              39.449911
            ],
            [
              -120.777207,
              39.450183
            ],
            [
              -120.778923,
              39.448328
            ],
            [
              -120.78241,
              39.446782
            ],
            [
              -120.783494,
              39.4476
            ],
            [
              -120.785981,
              39.447012
            ],
            [
              -120.78637,
              39.44565
            ],
            [
              -120.787362,
              39.443912
            ],
            [
              -120.789987,
              39.443381
            ],
            [
              -120.791497,
              39.44173
            ],
            [
              -120.793481,
              39.44156
            ],
            [
              -120.795014,
              39.441801
            ],
            [
              -120.798005,
              39.440975
            ],
            [
              -120.798943,
              39.438812
            ],
            [
              -120.801431,
              39.437548
            ],
            [
              -120.803537,
              39.438101
            ],
            [
              -120.807321,
              39.437772
            ],
            [
              -120.809171,
              39.436761
            ],
            [
              -120.812172,
              39.437779
            ],
            [
              -120.813455,
              39.439886
            ],
            [
              -120.815483,
              39.440754
            ],
            [
              -120.81755,
              39.439392
            ],
            [
              -120.817813,
              39.437962
            ],
            [
              -120.817759,
              39.437216
            ],
            [
              -120.819971,
              39.436663
            ],
            [
              -120.821986,
              39.43702
            ],
            [
              -120.824388,
              39.435853
            ],
            [
              -120.827288,
              39.435939
            ],
            [
              -120.830676,
              39.436948
            ],
            [
              -120.832759,
              39.438271
            ],
            [
              -120.831912,
              39.438715
            ],
            [
              -120.833659,
              39.439761
            ],
            [
              -120.835864,
              39.440324
            ],
            [
              -120.84016,
              39.438427
            ],
            [
              -120.842723,
              39.436388
            ],
            [
              -120.844318,
              39.435049
            ],
            [
              -120.84605,
              39.436067
            ],
            [
              -120.849514,
              39.435811
            ],
            [
              -120.851619,
              39.435751
            ],
            [
              -120.854679,
              39.435222
            ],
            [
              -120.854694,
              39.434335
            ],
            [
              -120.856144,
              39.434353
            ],
            [
              -120.856846,
              39.435136
            ],
            [
              -120.858814,
              39.434303
            ],
            [
              -120.861614,
              39.433498
            ],
            [
              -120.862919,
              39.431878
            ],
            [
              -120.865468,
              39.43015
            ],
            [
              -120.867794,
              39.430909
            ],
            [
              -120.869793,
              39.430076
            ],
            [
              -120.872639,
              39.430191
            ],
            [
              -120.875531,
              39.430429
            ],
            [
              -120.875692,
              39.429787
            ],
            [
              -120.877187,
              39.430112
            ],
            [
              -120.877347,
              39.430782
            ],
            [
              -120.878766,
              39.430213
            ],
            [
              -120.87859,
              39.429728
            ],
            [
              -120.879376,
              39.428841
            ],
            [
              -120.880696,
              39.428586
            ],
            [
              -120.884511,
              39.429218
            ],
            [
              -120.886968,
              39.427957
            ],
            [
              -120.88976,
              39.426043
            ],
            [
              -120.89355,
              39.424213
            ],
            [
              -120.899046,
              39.424283
            ],
            [
              -120.903502,
              39.424206
            ],
            [
              -120.908522,
              39.425873
            ],
            [
              -120.912696,
              39.424939
            ],
            [
              -120.91384,
              39.422346
            ],
            [
              -120.913618,
              39.420856
            ],
            [
              -120.917113,
              39.420382
            ],
            [
              -120.919173,
              39.421212
            ],
            [
              -120.921371,
              39.421625
            ],
            [
              -120.922713,
              39.420942
            ],
            [
              -120.927222,
              39.420234
            ],
            [
              -120.929107,
              39.42097
            ],
            [
              -120.929923,
              39.419458
            ],
            [
              -120.92971,
              39.418192
            ],
            [
              -120.931983,
              39.417125
            ],
            [
              -120.934333,
              39.417815
            ],
            [
              -120.937537,
              39.417426
            ],
            [
              -120.942528,
              39.419303
            ],
            [
              -120.945491,
              39.41861
            ],
            [
              -120.950123,
              39.418909
            ],
            [
              -120.952536,
              39.416705
            ],
            [
              -120.956604,
              39.416497
            ],
            [
              -120.959633,
              39.415968
            ],
            [
              -120.966057,
              39.417563
            ],
            [
              -120.971238,
              39.418811
            ],
            [
              -120.974313,
              39.417383
            ],
            [
              -120.97722,
              39.417753
            ],
            [
              -120.98002,
              39.416529
            ],
            [
              -120.979532,
              39.414586
            ],
            [
              -120.978898,
              39.41266
            ],
            [
              -120.980935,
              39.412524
            ],
            [
              -120.981203,
              39.413579
            ],
            [
              -120.983263,
              39.412934
            ],
            [
              -120.984461,
              39.411833
            ],
            [
              -120.988321,
              39.412215
            ],
            [
              -120.991419,
              39.413985
            ],
            [
              -120.995736,
              39.412827
            ],
            [
              -121.003379,
              39.407492
            ],
            [
              -121.009194,
              39.404749
            ],
            [
              -121.012142,
              39.4054
            ],
            [
              -121.012509,
              39.401273
            ],
            [
              -121.009943,
              39.400264
            ],
            [
              -121.012027,
              39.397156
            ],
            [
              -121.018038,
              39.395469
            ],
            [
              -121.022085,
              39.391558
            ],
            [
              -121.041087,
              39.388244
            ],
            [
              -121.048146,
              39.394934
            ],
            [
              -121.084002,
              39.394624
            ],
            [
              -121.100942,
              39.381246
            ],
            [
              -121.127481,
              39.380237
            ],
            [
              -121.154766,
              39.348605
            ],
            [
              -121.156588,
              39.334149
            ],
            [
              -121.178977,
              39.33856
            ],
            [
              -121.206352,
              39.316469
            ],
            [
              -121.200146,
              39.302375
            ],
            [
              -121.220979,
              39.282573
            ],
            [
              -121.240146,
              39.283997
            ],
            [
              -121.266132,
              39.272717
            ],
            [
              -121.259182,
              39.256421
            ],
            [
              -121.27953,
              39.230537
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "06",
      "COUNTYFP": "109",
      "COUNTYNS": "00277319",
      "AFFGEOID": "0500000US06109",
      "GEOID": "06109",
      "NAME": "Tuolumne",
      "LSAD": "06",
      "ALAND": 5752079368,
      "AWATER": 138722711,
      "County_state": "Tuolumne,06"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -120.652551,
              37.833463
            ],
            [
              -120.653274,
              37.831858
            ],
            [
              -120.387613,
              37.633704
            ],
            [
              -120.3862,
              37.634572
            ],
            [
              -120.383482,
              37.638721
            ],
            [
              -120.381709,
              37.641801
            ],
            [
              -120.37777,
              37.646851
            ],
            [
              -120.376194,
              37.650563
            ],
            [
              -120.37939,
              37.656795
            ],
            [
              -120.384934,
              37.658941
            ],
            [
              -120.389444,
              37.664349
            ],
            [
              -120.39397,
              37.668878
            ],
            [
              -120.39398,
              37.675366
            ],
            [
              -120.392314,
              37.679928
            ],
            [
              -120.391924,
              37.681921
            ],
            [
              -120.388825,
              37.683738
            ],
            [
              -120.383237,
              37.682437
            ],
            [
              -120.378599,
              37.681298
            ],
            [
              -120.368178,
              37.680264
            ],
            [
              -120.3623,
              37.674778
            ],
            [
              -120.355417,
              37.6755
            ],
            [
              -120.349469,
              37.672403
            ],
            [
              -120.345127,
              37.66782
            ],
            [
              -120.340349,
              37.661293
            ],
            [
              -120.334092,
              37.656759
            ],
            [
              -120.331799,
              37.652567
            ],
            [
              -120.328376,
              37.650045
            ],
            [
              -120.321018,
              37.650385
            ],
            [
              -120.314395,
              37.653197
            ],
            [
              -120.311744,
              37.66014
            ],
            [
              -120.308277,
              37.664372
            ],
            [
              -120.310233,
              37.672614
            ],
            [
              -120.317575,
              37.68136
            ],
            [
              -120.323067,
              37.688623
            ],
            [
              -120.330182,
              37.697977
            ],
            [
              -120.332013,
              37.70344
            ],
            [
              -120.335013,
              37.707549
            ],
            [
              -120.338213,
              37.710522
            ],
            [
              -120.33955,
              37.713774
            ],
            [
              -120.340084,
              37.717348
            ],
            [
              -120.341264,
              37.718942
            ],
            [
              -120.34233,
              37.721951
            ],
            [
              -120.34409,
              37.723481
            ],
            [
              -120.34483,
              37.725966
            ],
            [
              -120.342099,
              37.726734
            ],
            [
              -120.339277,
              37.726744
            ],
            [
              -120.336424,
              37.726402
            ],
            [
              -120.333347,
              37.728457
            ],
            [
              -120.328915,
              37.72839
            ],
            [
              -120.324291,
              37.726634
            ],
            [
              -120.319135,
              37.729323
            ],
            [
              -120.315276,
              37.733869
            ],
            [
              -120.30646,
              37.734988
            ],
            [
              -120.296921,
              37.733487
            ],
            [
              -120.290437,
              37.731304
            ],
            [
              -120.288548,
              37.730297
            ],
            [
              -120.284832,
              37.732867
            ],
            [
              -120.285226,
              37.73643
            ],
            [
              -120.285214,
              37.741733
            ],
            [
              -120.283013,
              37.743466
            ],
            [
              -120.277793,
              37.740925
            ],
            [
              -120.272775,
              37.737903
            ],
            [
              -120.263871,
              37.735487
            ],
            [
              -120.259926,
              37.733827
            ],
            [
              -120.258814,
              37.735837
            ],
            [
              -120.260607,
              37.737164
            ],
            [
              -120.261336,
              37.741122
            ],
            [
              -120.255604,
              37.748537
            ],
            [
              -120.247174,
              37.74993
            ],
            [
              -120.243929,
              37.751013
            ],
            [
              -120.241325,
              37.752666
            ],
            [
              -120.239304,
              37.754968
            ],
            [
              -120.240836,
              37.758643
            ],
            [
              -120.239205,
              37.7619
            ],
            [
              -120.23944,
              37.76391
            ],
            [
              -120.23709,
              37.764975
            ],
            [
              -120.235712,
              37.763848
            ],
            [
              -120.229687,
              37.762713
            ],
            [
              -120.227958,
              37.760988
            ],
            [
              -120.226,
              37.760981
            ],
            [
              -120.22517,
              37.761959
            ],
            [
              -120.223363,
              37.763001
            ],
            [
              -120.221156,
              37.762787
            ],
            [
              -120.216579,
              37.763955
            ],
            [
              -120.212971,
              37.765112
            ],
            [
              -120.210699,
              37.764371
            ],
            [
              -120.209373,
              37.763155
            ],
            [
              -120.205222,
              37.76309
            ],
            [
              -120.200068,
              37.763716
            ],
            [
              -120.198178,
              37.766622
            ],
            [
              -120.198416,
              37.768461
            ],
            [
              -120.19565,
              37.769503
            ],
            [
              -120.193069,
              37.7693
            ],
            [
              -120.191936,
              37.768075
            ],
            [
              -120.18875,
              37.768486
            ],
            [
              -120.187329,
              37.77025
            ],
            [
              -120.185266,
              37.773281
            ],
            [
              -120.184566,
              37.775988
            ],
            [
              -120.186192,
              37.77781
            ],
            [
              -120.187155,
              37.780889
            ],
            [
              -120.18565,
              37.781499
            ],
            [
              -120.184231,
              37.782873
            ],
            [
              -120.182352,
              37.784155
            ],
            [
              -120.181345,
              37.78785
            ],
            [
              -120.180596,
              37.789328
            ],
            [
              -120.182036,
              37.79097
            ],
            [
              -120.182946,
              37.793092
            ],
            [
              -120.182686,
              37.794758
            ],
            [
              -120.18113,
              37.795355
            ],
            [
              -120.17994,
              37.795203
            ],
            [
              -120.177332,
              37.795586
            ],
            [
              -120.176755,
              37.796995
            ],
            [
              -120.175645,
              37.798384
            ],
            [
              -120.173351,
              37.798647
            ],
            [
              -120.172967,
              37.797604
            ],
            [
              -120.173441,
              37.79652
            ],
            [
              -120.172633,
              37.794947
            ],
            [
              -120.171233,
              37.794631
            ],
            [
              -120.169324,
              37.79276
            ],
            [
              -120.167366,
              37.790534
            ],
            [
              -120.163961,
              37.788896
            ],
            [
              -120.161587,
              37.787332
            ],
            [
              -120.15891,
              37.787859
            ],
            [
              -120.156332,
              37.787832
            ],
            [
              -120.153489,
              37.788148
            ],
            [
              -120.150713,
              37.787338
            ],
            [
              -120.147997,
              37.786695
            ],
            [
              -120.146408,
              37.785621
            ],
            [
              -120.143885,
              37.784498
            ],
            [
              -120.142198,
              37.783106
            ],
            [
              -120.137564,
              37.782583
            ],
            [
              -120.134709,
              37.782177
            ],
            [
              -120.1311,
              37.781818
            ],
            [
              -120.126828,
              37.781781
            ],
            [
              -120.125557,
              37.783484
            ],
            [
              -120.120347,
              37.785792
            ],
            [
              -120.120127,
              37.786986
            ],
            [
              -120.118545,
              37.789828
            ],
            [
              -120.113941,
              37.793839
            ],
            [
              -120.110564,
              37.795145
            ],
            [
              -120.106357,
              37.798577
            ],
            [
              -120.101344,
              37.801929
            ],
            [
              -120.099774,
              37.803798
            ],
            [
              -120.096651,
              37.807941
            ],
            [
              -120.092645,
              37.810159
            ],
            [
              -120.089328,
              37.8129
            ],
            [
              -120.090625,
              37.817264
            ],
            [
              -120.090107,
              37.820294
            ],
            [
              -120.08924,
              37.822859
            ],
            [
              -120.086748,
              37.823099
            ],
            [
              -120.084895,
              37.826987
            ],
            [
              -120.081737,
              37.828114
            ],
            [
              -120.073361,
              37.827031
            ],
            [
              -120.06842,
              37.824915
            ],
            [
              -120.064302,
              37.823315
            ],
            [
              -120.063102,
              37.824416
            ],
            [
              -120.060778,
              37.825552
            ],
            [
              -120.05911,
              37.824865
            ],
            [
              -120.057004,
              37.820842
            ],
            [
              -120.055099,
              37.816264
            ],
            [
              -120.052792,
              37.812872
            ],
            [
              -120.048174,
              37.812038
            ],
            [
              -120.039977,
              37.810984
            ],
            [
              -120.034101,
              37.809427
            ],
            [
              -120.025008,
              37.811318
            ],
            [
              -120.015724,
              37.805668
            ],
            [
              -120.007726,
              37.802001
            ],
            [
              -120.000016,
              37.797626
            ],
            [
              -119.975616,
              37.786826
            ],
            [
              -119.956915,
              37.774826
            ],
            [
              -119.938414,
              37.763026
            ],
            [
              -119.930414,
              37.763726
            ],
            [
              -119.92594,
              37.764299
            ],
            [
              -119.918013,
              37.762226
            ],
            [
              -119.912513,
              37.761026
            ],
            [
              -119.902413,
              37.757926
            ],
            [
              -119.895736,
              37.761367
            ],
            [
              -119.889971,
              37.761255
            ],
            [
              -119.875968,
              37.764038
            ],
            [
              -119.873113,
              37.769426
            ],
            [
              -119.867912,
              37.772425
            ],
            [
              -119.863111,
              37.769525
            ],
            [
              -119.860011,
              37.764126
            ],
            [
              -119.853911,
              37.761226
            ],
            [
              -119.845811,
              37.762625
            ],
            [
              -119.841648,
              37.766481
            ],
            [
              -119.835609,
              37.769626
            ],
            [
              -119.827514,
              37.765787
            ],
            [
              -119.82141,
              37.760225
            ],
            [
              -119.811409,
              37.757025
            ],
            [
              -119.805408,
              37.757427
            ],
            [
              -119.803975,
              37.759684
            ],
            [
              -119.794922,
              37.762619
            ],
            [
              -119.780108,
              37.763925
            ],
            [
              -119.772407,
              37.766727
            ],
            [
              -119.766706,
              37.770727
            ],
            [
              -119.759795,
              37.772474
            ],
            [
              -119.749407,
              37.775724
            ],
            [
              -119.731906,
              37.787424
            ],
            [
              -119.693204,
              37.796924
            ],
            [
              -119.651103,
              37.819823
            ],
            [
              -119.652403,
              37.838923
            ],
            [
              -119.637661,
              37.849035
            ],
            [
              -119.626002,
              37.848152
            ],
            [
              -119.617922,
              37.853553
            ],
            [
              -119.605255,
              37.857656
            ],
            [
              -119.593223,
              37.865625
            ],
            [
              -119.584864,
              37.874922
            ],
            [
              -119.5741,
              37.895622
            ],
            [
              -119.5567,
              37.900922
            ],
            [
              -119.530298,
              37.892922
            ],
            [
              -119.506597,
              37.874522
            ],
            [
              -119.491691,
              37.863318
            ],
            [
              -119.471038,
              37.861909
            ],
            [
              -119.445793,
              37.869423
            ],
            [
              -119.429307,
              37.866238
            ],
            [
              -119.423892,
              37.862624
            ],
            [
              -119.406091,
              37.850324
            ],
            [
              -119.389227,
              37.837858
            ],
            [
              -119.380902,
              37.839442
            ],
            [
              -119.377071,
              37.839684
            ],
            [
              -119.37391,
              37.837378
            ],
            [
              -119.366697,
              37.830977
            ],
            [
              -119.347787,
              37.809027
            ],
            [
              -119.325645,
              37.798436
            ],
            [
              -119.310384,
              37.790528
            ],
            [
              -119.309984,
              37.778428
            ],
            [
              -119.308995,
              37.777986
            ],
            [
              -119.293382,
              37.767929
            ],
            [
              -119.288381,
              37.74503
            ],
            [
              -119.268979,
              37.73923
            ],
            [
              -119.263479,
              37.74073
            ],
            [
              -119.256279,
              37.74473
            ],
            [
              -119.254613,
              37.749005
            ],
            [
              -119.255183,
              37.75293
            ],
            [
              -119.253481,
              37.75713
            ],
            [
              -119.25008,
              37.75803
            ],
            [
              -119.24408,
              37.76803
            ],
            [
              -119.23008,
              37.77603
            ],
            [
              -119.21988,
              37.781329
            ],
            [
              -119.20388,
              37.793929
            ],
            [
              -119.204481,
              37.808929
            ],
            [
              -119.211581,
              37.815129
            ],
            [
              -119.212281,
              37.825628
            ],
            [
              -119.205881,
              37.835828
            ],
            [
              -119.20398,
              37.841928
            ],
            [
              -119.20036,
              37.84313
            ],
            [
              -119.202582,
              37.847528
            ],
            [
              -119.208482,
              37.846428
            ],
            [
              -119.215182,
              37.854027
            ],
            [
              -119.213782,
              37.863227
            ],
            [
              -119.206383,
              37.883327
            ],
            [
              -119.204383,
              37.888826
            ],
            [
              -119.207783,
              37.890026
            ],
            [
              -119.210683,
              37.893926
            ],
            [
              -119.213783,
              37.899126
            ],
            [
              -119.224084,
              37.903526
            ],
            [
              -119.238984,
              37.911626
            ],
            [
              -119.250985,
              37.9106
            ],
            [
              -119.265086,
              37.919625
            ],
            [
              -119.283187,
              37.933724
            ],
            [
              -119.302788,
              37.944924
            ],
            [
              -119.311789,
              37.951924
            ],
            [
              -119.32199,
              37.969023
            ],
            [
              -119.31819,
              37.985323
            ],
            [
              -119.309879,
              38.002271
            ],
            [
              -119.30886,
              38.007421
            ],
            [
              -119.307546,
              38.030525
            ],
            [
              -119.316717,
              38.047845
            ],
            [
              -119.334109,
              38.065244
            ],
            [
              -119.354078,
              38.084567
            ],
            [
              -119.375994,
              38.093043
            ],
            [
              -119.389879,
              38.09913
            ],
            [
              -119.404239,
              38.106396
            ],
            [
              -119.422601,
              38.107301
            ],
            [
              -119.426928,
              38.112493
            ],
            [
              -119.436951,
              38.107671
            ],
            [
              -119.440873,
              38.103557
            ],
            [
              -119.447529,
              38.096991
            ],
            [
              -119.450747,
              38.099228
            ],
            [
              -119.462277,
              38.105876
            ],
            [
              -119.471872,
              38.116089
            ],
            [
              -119.470736,
              38.122886
            ],
            [
              -119.477671,
              38.130205
            ],
            [
              -119.488929,
              38.137757
            ],
            [
              -119.496967,
              38.149297
            ],
            [
              -119.496264,
              38.153913
            ],
            [
              -119.505017,
              38.155749
            ],
            [
              -119.507525,
              38.152036
            ],
            [
              -119.505834,
              38.145797
            ],
            [
              -119.50604,
              38.139251
            ],
            [
              -119.513458,
              38.13726
            ],
            [
              -119.52972,
              38.143622
            ],
            [
              -119.540917,
              38.144455
            ],
            [
              -119.547952,
              38.148115
            ],
            [
              -119.553958,
              38.154264
            ],
            [
              -119.564818,
              38.155063
            ],
            [
              -119.577503,
              38.162032
            ],
            [
              -119.579035,
              38.173846
            ],
            [
              -119.582473,
              38.180947
            ],
            [
              -119.592266,
              38.185052
            ],
            [
              -119.598421,
              38.181697
            ],
            [
              -119.621948,
              38.192355
            ],
            [
              -119.629837,
              38.197111
            ],
            [
              -119.629233,
              38.204405
            ],
            [
              -119.624938,
              38.207722
            ],
            [
              -119.626507,
              38.214235
            ],
            [
              -119.625483,
              38.217256
            ],
            [
              -119.626286,
              38.219824
            ],
            [
              -119.623719,
              38.225671
            ],
            [
              -119.615991,
              38.231577
            ],
            [
              -119.605215,
              38.237094
            ],
            [
              -119.612361,
              38.241942
            ],
            [
              -119.617786,
              38.248399
            ],
            [
              -119.61904,
              38.255884
            ],
            [
              -119.61646,
              38.257844
            ],
            [
              -119.614853,
              38.262493
            ],
            [
              -119.620426,
              38.264698
            ],
            [
              -119.631415,
              38.271183
            ],
            [
              -119.640967,
              38.278073
            ],
            [
              -119.647834,
              38.282929
            ],
            [
              -119.649187,
              38.288832
            ],
            [
              -119.643532,
              38.291911
            ],
            [
              -119.64371,
              38.303592
            ],
            [
              -119.642576,
              38.315819
            ],
            [
              -119.642245,
              38.324195
            ],
            [
              -119.639205,
              38.32688
            ],
            [
              -119.669524,
              38.348288
            ],
            [
              -119.700029,
              38.365215
            ],
            [
              -119.693622,
              38.378899
            ],
            [
              -119.698671,
              38.409838
            ],
            [
              -119.704637,
              38.415736
            ],
            [
              -119.712893,
              38.418452
            ],
            [
              -119.753481,
              38.416759
            ],
            [
              -119.770553,
              38.406663
            ],
            [
              -119.801737,
              38.401321
            ],
            [
              -119.814691,
              38.387516
            ],
            [
              -119.837551,
              38.382411
            ],
            [
              -119.869667,
              38.367597
            ],
            [
              -119.884749,
              38.356185
            ],
            [
              -120.019951,
              38.433521
            ],
            [
              -120.023372,
              38.4306
            ],
            [
              -120.033993,
              38.42464
            ],
            [
              -120.042572,
              38.423161
            ],
            [
              -120.05159,
              38.419348
            ],
            [
              -120.062759,
              38.416177
            ],
            [
              -120.069737,
              38.413481
            ],
            [
              -120.075146,
              38.4117
            ],
            [
              -120.080594,
              38.410184
            ],
            [
              -120.086808,
              38.404501
            ],
            [
              -120.095907,
              38.402927
            ],
            [
              -120.107448,
              38.401352
            ],
            [
              -120.114744,
              38.400758
            ],
            [
              -120.125209,
              38.397535
            ],
            [
              -120.136661,
              38.393967
            ],
            [
              -120.14384,
              38.392503
            ],
            [
              -120.152238,
              38.387698
            ],
            [
              -120.161143,
              38.38097
            ],
            [
              -120.17089,
              38.3758
            ],
            [
              -120.176642,
              38.372619
            ],
            [
              -120.183202,
              38.362345
            ],
            [
              -120.184575,
              38.359331
            ],
            [
              -120.189005,
              38.357146
            ],
            [
              -120.190137,
              38.352758
            ],
            [
              -120.196085,
              38.34333
            ],
            [
              -120.201077,
              38.336046
            ],
            [
              -120.208789,
              38.326808
            ],
            [
              -120.217689,
              38.321133
            ],
            [
              -120.224864,
              38.31744
            ],
            [
              -120.22962,
              38.312769
            ],
            [
              -120.234341,
              38.304965
            ],
            [
              -120.237089,
              38.298466
            ],
            [
              -120.24538,
              38.291573
            ],
            [
              -120.256374,
              38.285747
            ],
            [
              -120.262215,
              38.27628
            ],
            [
              -120.271899,
              38.267643
            ],
            [
              -120.273564,
              38.259021
            ],
            [
              -120.284222,
              38.251595
            ],
            [
              -120.290388,
              38.242832
            ],
            [
              -120.292184,
              38.23338
            ],
            [
              -120.308191,
              38.222074
            ],
            [
              -120.317222,
              38.208812
            ],
            [
              -120.325871,
              38.198065
            ],
            [
              -120.331339,
              38.192164
            ],
            [
              -120.335707,
              38.186815
            ],
            [
              -120.33745,
              38.180501
            ],
            [
              -120.340816,
              38.174349
            ],
            [
              -120.351656,
              38.16765
            ],
            [
              -120.358123,
              38.158386
            ],
            [
              -120.367569,
              38.152806
            ],
            [
              -120.372641,
              38.14663
            ],
            [
              -120.375509,
              38.141712
            ],
            [
              -120.372351,
              38.141139
            ],
            [
              -120.373568,
              38.136312
            ],
            [
              -120.378666,
              38.134826
            ],
            [
              -120.382519,
              38.127574
            ],
            [
              -120.390253,
              38.113546
            ],
            [
              -120.405758,
              38.105627
            ],
            [
              -120.410367,
              38.093094
            ],
            [
              -120.41463,
              38.081911
            ],
            [
              -120.423818,
              38.07632
            ],
            [
              -120.429258,
              38.073481
            ],
            [
              -120.431103,
              38.065084
            ],
            [
              -120.435788,
              38.060026
            ],
            [
              -120.441156,
              38.059922
            ],
            [
              -120.443394,
              38.054668
            ],
            [
              -120.44424,
              38.05077
            ],
            [
              -120.453623,
              38.041804
            ],
            [
              -120.456623,
              38.033864
            ],
            [
              -120.464533,
              38.021092
            ],
            [
              -120.464729,
              38.013554
            ],
            [
              -120.47195,
              38.011296
            ],
            [
              -120.483679,
              38.012106
            ],
            [
              -120.49225,
              38.011183
            ],
            [
              -120.500137,
              38.007492
            ],
            [
              -120.500575,
              37.99466
            ],
            [
              -120.503767,
              37.991681
            ],
            [
              -120.509963,
              37.991955
            ],
            [
              -120.511257,
              37.993875
            ],
            [
              -120.512839,
              37.997067
            ],
            [
              -120.511315,
              37.99744
            ],
            [
              -120.509285,
              37.999928
            ],
            [
              -120.511091,
              38.002585
            ],
            [
              -120.516681,
              38.003886
            ],
            [
              -120.519447,
              38.001036
            ],
            [
              -120.521653,
              37.99516
            ],
            [
              -120.527828,
              37.993023
            ],
            [
              -120.532959,
              37.992118
            ],
            [
              -120.532386,
              37.988092
            ],
            [
              -120.528511,
              37.984899
            ],
            [
              -120.531817,
              37.980672
            ],
            [
              -120.536355,
              37.980173
            ],
            [
              -120.542076,
              37.977883
            ],
            [
              -120.539867,
              37.974348
            ],
            [
              -120.537589,
              37.974646
            ],
            [
              -120.533971,
              37.975477
            ],
            [
              -120.528619,
              37.97599
            ],
            [
              -120.527925,
              37.972105
            ],
            [
              -120.519787,
              37.966294
            ],
            [
              -120.514585,
              37.96081
            ],
            [
              -120.518278,
              37.951265
            ],
            [
              -120.528248,
              37.946822
            ],
            [
              -120.534261,
              37.944939
            ],
            [
              -120.536604,
              37.941385
            ],
            [
              -120.538646,
              37.938181
            ],
            [
              -120.539448,
              37.933256
            ],
            [
              -120.53712,
              37.929422
            ],
            [
              -120.539016,
              37.925383
            ],
            [
              -120.541081,
              37.922505
            ],
            [
              -120.544474,
              37.920101
            ],
            [
              -120.54642,
              37.921136
            ],
            [
              -120.551897,
              37.921131
            ],
            [
              -120.556073,
              37.916157
            ],
            [
              -120.562877,
              37.912855
            ],
            [
              -120.563302,
              37.90634
            ],
            [
              -120.566028,
              37.901762
            ],
            [
              -120.568158,
              37.897559
            ],
            [
              -120.574532,
              37.894374
            ],
            [
              -120.581286,
              37.896019
            ],
            [
              -120.588564,
              37.893537
            ],
            [
              -120.596681,
              37.888144
            ],
            [
              -120.59656,
              37.883851
            ],
            [
              -120.600983,
              37.879007
            ],
            [
              -120.604102,
              37.8767
            ],
            [
              -120.605154,
              37.874981
            ],
            [
              -120.604649,
              37.874058
            ],
            [
              -120.607672,
              37.872897
            ],
            [
              -120.612415,
              37.871627
            ],
            [
              -120.623508,
              37.866601
            ],
            [
              -120.631885,
              37.86128
            ],
            [
              -120.635686,
              37.856043
            ],
            [
              -120.637936,
              37.850559
            ],
            [
              -120.642039,
              37.845693
            ],
            [
              -120.644072,
              37.840052
            ],
            [
              -120.652551,
              37.833463
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "06",
      "COUNTYFP": "083",
      "COUNTYNS": "00277306",
      "AFFGEOID": "0500000US06083",
      "GEOID": "06083",
      "NAME": "Santa Barbara",
      "LSAD": "06",
      "ALAND": 7084000622,
      "AWATER": 2729814515,
      "County_state": "Santa Barbara,06"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -119.060024,
              33.491647
            ],
            [
              -119.065966,
              33.467858
            ],
            [
              -119.052893,
              33.455961
            ],
            [
              -119.02556,
              33.461909
            ],
            [
              -119.020807,
              33.481736
            ],
            [
              -119.030314,
              33.497593
            ],
            [
              -119.060024,
              33.491647
            ]
          ]
        ],
        [
          [
            [
              -119.919155,
              34.07728
            ],
            [
              -119.923337,
              34.069361
            ],
            [
              -119.916216,
              34.058351
            ],
            [
              -119.892821,
              34.045529
            ],
            [
              -119.876329,
              34.032087
            ],
            [
              -119.876916,
              34.023527
            ],
            [
              -119.882531,
              34.011674
            ],
            [
              -119.884896,
              34.008814
            ],
            [
              -119.883033,
              34.000802
            ],
            [
              -119.877057,
              33.985757
            ],
            [
              -119.873358,
              33.980375
            ],
            [
              -119.842748,
              33.97034
            ],
            [
              -119.795938,
              33.962929
            ],
            [
              -119.758141,
              33.959212
            ],
            [
              -119.750438,
              33.963759
            ],
            [
              -119.742966,
              33.963877
            ],
            [
              -119.721206,
              33.959583
            ],
            [
              -119.714696,
              33.961439
            ],
            [
              -119.712363,
              33.965422
            ],
            [
              -119.706952,
              33.969178
            ],
            [
              -119.69011,
              33.972225
            ],
            [
              -119.662825,
              33.985889
            ],
            [
              -119.64771,
              33.987786
            ],
            [
              -119.621117,
              33.98899
            ],
            [
              -119.619082,
              33.987228
            ],
            [
              -119.596877,
              33.988611
            ],
            [
              -119.5902,
              33.989712
            ],
            [
              -119.575636,
              33.996009
            ],
            [
              -119.560464,
              33.99553
            ],
            [
              -119.554472,
              33.99782
            ],
            [
              -119.547072,
              34.005469
            ],
            [
              -119.54828,
              34.009819
            ],
            [
              -119.542449,
              34.021082
            ],
            [
              -119.538847,
              34.023988
            ],
            [
              -119.532413,
              34.024949
            ],
            [
              -119.52177,
              34.032247
            ],
            [
              -119.52064,
              34.034262
            ],
            [
              -119.529603,
              34.041155
            ],
            [
              -119.5667,
              34.053452
            ],
            [
              -119.57341,
              34.05011
            ],
            [
              -119.59324,
              34.049625
            ],
            [
              -119.609239,
              34.03735
            ],
            [
              -119.608798,
              34.035245
            ],
            [
              -119.604287,
              34.031561
            ],
            [
              -119.612226,
              34.021256
            ],
            [
              -119.619343,
              34.016468
            ],
            [
              -119.637742,
              34.013178
            ],
            [
              -119.686507,
              34.019805
            ],
            [
              -119.704628,
              34.037681
            ],
            [
              -119.712576,
              34.043265
            ],
            [
              -119.726437,
              34.047908
            ],
            [
              -119.739472,
              34.049299
            ],
            [
              -119.755521,
              34.056716
            ],
            [
              -119.763688,
              34.057155
            ],
            [
              -119.766081,
              34.05537
            ],
            [
              -119.770729,
              34.055051
            ],
            [
              -119.789798,
              34.05726
            ],
            [
              -119.807825,
              34.052127
            ],
            [
              -119.818742,
              34.052997
            ],
            [
              -119.825865,
              34.059794
            ],
            [
              -119.857304,
              34.071298
            ],
            [
              -119.89113,
              34.072856
            ],
            [
              -119.912857,
              34.077508
            ],
            [
              -119.919155,
              34.07728
            ]
          ]
        ],
        [
          [
            [
              -120.247393,
              34.001911
            ],
            [
              -120.248484,
              33.999329
            ],
            [
              -120.224461,
              33.989059
            ],
            [
              -120.209372,
              33.972376
            ],
            [
              -120.200085,
              33.956904
            ],
            [
              -120.198602,
              33.952211
            ],
            [
              -120.192339,
              33.950266
            ],
            [
              -120.18984,
              33.947703
            ],
            [
              -120.179049,
              33.927994
            ],
            [
              -120.168974,
              33.91909
            ],
            [
              -120.121817,
              33.895712
            ],
            [
              -120.109137,
              33.899129
            ],
            [
              -120.105489,
              33.90428
            ],
            [
              -120.098601,
              33.907853
            ],
            [
              -120.077793,
              33.908886
            ],
            [
              -120.049682,
              33.914563
            ],
            [
              -120.048611,
              33.915775
            ],
            [
              -120.048315,
              33.917625
            ],
            [
              -120.046881,
              33.919597
            ],
            [
              -120.017715,
              33.936366
            ],
            [
              -120.00096,
              33.941554
            ],
            [
              -119.973691,
              33.942481
            ],
            [
              -119.97026,
              33.944359
            ],
            [
              -119.971141,
              33.950401
            ],
            [
              -119.976857,
              33.956693
            ],
            [
              -119.979913,
              33.969623
            ],
            [
              -119.978876,
              33.983081
            ],
            [
              -119.984316,
              33.983948
            ],
            [
              -120.003815,
              33.979547
            ],
            [
              -120.011123,
              33.979894
            ],
            [
              -120.025653,
              33.985553
            ],
            [
              -120.041311,
              33.994507
            ],
            [
              -120.046575,
              34.000002
            ],
            [
              -120.048926,
              34.009898
            ],
            [
              -120.050382,
              34.013331
            ],
            [
              -120.047798,
              34.021227
            ],
            [
              -120.044004,
              34.02482
            ],
            [
              -120.043259,
              34.035806
            ],
            [
              -120.055107,
              34.037729
            ],
            [
              -120.057637,
              34.03734
            ],
            [
              -120.061953,
              34.033976
            ],
            [
              -120.062778,
              34.031161
            ],
            [
              -120.073609,
              34.024477
            ],
            [
              -120.090182,
              34.019806
            ],
            [
              -120.115058,
              34.019866
            ],
            [
              -120.135853,
              34.026087
            ],
            [
              -120.140362,
              34.025974
            ],
            [
              -120.147647,
              34.024831
            ],
            [
              -120.151663,
              34.018126
            ],
            [
              -120.167306,
              34.008219
            ],
            [
              -120.19578,
              34.004284
            ],
            [
              -120.208478,
              34.005655
            ],
            [
              -120.221287,
              34.010367
            ],
            [
              -120.230001,
              34.010136
            ],
            [
              -120.238657,
              34.007592
            ],
            [
              -120.247393,
              34.001911
            ]
          ]
        ],
        [
          [
            [
              -120.449114,
              34.037345
            ],
            [
              -120.454134,
              34.028081
            ],
            [
              -120.427408,
              34.025425
            ],
            [
              -120.419021,
              34.028949
            ],
            [
              -120.415225,
              34.032245
            ],
            [
              -120.409368,
              34.032198
            ],
            [
              -120.375143,
              34.018775
            ],
            [
              -120.35793,
              34.015029
            ],
            [
              -120.35532,
              34.017914
            ],
            [
              -120.347706,
              34.020114
            ],
            [
              -120.317052,
              34.018837
            ],
            [
              -120.304543,
              34.021171
            ],
            [
              -120.302122,
              34.023574
            ],
            [
              -120.313175,
              34.036576
            ],
            [
              -120.319032,
              34.041979
            ],
            [
              -120.331161,
              34.049097
            ],
            [
              -120.346946,
              34.046576
            ],
            [
              -120.358608,
              34.050235
            ],
            [
              -120.36029,
              34.05582
            ],
            [
              -120.354982,
              34.059256
            ],
            [
              -120.362251,
              34.073056
            ],
            [
              -120.368278,
              34.076465
            ],
            [
              -120.370176,
              34.074907
            ],
            [
              -120.368584,
              34.071214
            ],
            [
              -120.368813,
              34.06778
            ],
            [
              -120.374211,
              34.062658
            ],
            [
              -120.390906,
              34.051994
            ],
            [
              -120.396188,
              34.050187
            ],
            [
              -120.403613,
              34.050442
            ],
            [
              -120.411314,
              34.052869
            ],
            [
              -120.415287,
              34.05496
            ],
            [
              -120.418768,
              34.052093
            ],
            [
              -120.435008,
              34.041872
            ],
            [
              -120.449114,
              34.037345
            ]
          ]
        ],
        [
          [
            [
              -120.670835,
              34.904115
            ],
            [
              -120.662889,
              34.901183
            ],
            [
              -120.647328,
              34.901133
            ],
            [
              -120.642212,
              34.894145
            ],
            [
              -120.639283,
              34.880413
            ],
            [
              -120.616325,
              34.866739
            ],
            [
              -120.610266,
              34.85818
            ],
            [
              -120.609898,
              34.842751
            ],
            [
              -120.616296,
              34.816308
            ],
            [
              -120.62297,
              34.7933
            ],
            [
              -120.637415,
              34.755895
            ],
            [
              -120.62632,
              34.738072
            ],
            [
              -120.614852,
              34.730709
            ],
            [
              -120.601672,
              34.709721
            ],
            [
              -120.60045,
              34.70464
            ],
            [
              -120.60197,
              34.692095
            ],
            [
              -120.625127,
              34.634489
            ],
            [
              -120.640244,
              34.604406
            ],
            [
              -120.645739,
              34.581035
            ],
            [
              -120.637805,
              34.56622
            ],
            [
              -120.622575,
              34.554017
            ],
            [
              -120.612005,
              34.553564
            ],
            [
              -120.608355,
              34.556656
            ],
            [
              -120.581293,
              34.556959
            ],
            [
              -120.524776,
              34.531291
            ],
            [
              -120.511421,
              34.522953
            ],
            [
              -120.50429222290201,
              34.511737575871294
            ],
            [
              -120.490523,
              34.490075
            ],
            [
              -120.480372,
              34.481059
            ],
            [
              -120.47661,
              34.475131
            ],
            [
              -120.471376,
              34.447846
            ],
            [
              -120.451425,
              34.447094
            ],
            [
              -120.441975,
              34.451512
            ],
            [
              -120.341369,
              34.458789
            ],
            [
              -120.301822,
              34.467077
            ],
            [
              -120.299169,
              34.469731
            ],
            [
              -120.295051,
              34.470623
            ],
            [
              -120.283001,
              34.468354
            ],
            [
              -120.25777,
              34.467451
            ],
            [
              -120.238002,
              34.468098
            ],
            [
              -120.225498,
              34.470587
            ],
            [
              -120.183505,
              34.470372
            ],
            [
              -120.141165,
              34.473405
            ],
            [
              -120.118411,
              34.469927
            ],
            [
              -120.097212,
              34.461809
            ],
            [
              -120.088591,
              34.460208
            ],
            [
              -120.050682,
              34.461651
            ],
            [
              -120.038828,
              34.463434
            ],
            [
              -120.008077,
              34.460447
            ],
            [
              -119.971951,
              34.444641
            ],
            [
              -119.956433,
              34.435288
            ],
            [
              -119.925227,
              34.433931
            ],
            [
              -119.873971,
              34.408795
            ],
            [
              -119.853771,
              34.407996
            ],
            [
              -119.835771,
              34.415796
            ],
            [
              -119.794771,
              34.417597
            ],
            [
              -119.785871,
              34.415997
            ],
            [
              -119.74547,
              34.402898
            ],
            [
              -119.729369,
              34.395897
            ],
            [
              -119.709067,
              34.395397
            ],
            [
              -119.691749,
              34.403154
            ],
            [
              -119.684666,
              34.408297
            ],
            [
              -119.688167,
              34.412497
            ],
            [
              -119.671866,
              34.416096
            ],
            [
              -119.648664,
              34.417396
            ],
            [
              -119.638864,
              34.415696
            ],
            [
              -119.616862,
              34.420995
            ],
            [
              -119.559459,
              34.413395
            ],
            [
              -119.536957,
              34.395495
            ],
            [
              -119.510655,
              34.386295
            ],
            [
              -119.478265424859,
              34.3771973988722
            ],
            [
              -119.445991,
              34.404067
            ],
            [
              -119.454277,
              34.418166
            ],
            [
              -119.440414,
              34.441842
            ],
            [
              -119.442269,
              34.463949
            ],
            [
              -119.442353,
              34.561101
            ],
            [
              -119.442352,
              34.901274
            ],
            [
              -119.472754,
              34.901174
            ],
            [
              -119.490455,
              34.897475
            ],
            [
              -119.535972,
              34.89772
            ],
            [
              -119.536557,
              34.898076
            ],
            [
              -119.542992,
              34.902553
            ],
            [
              -119.548515,
              34.906236
            ],
            [
              -119.561758,
              34.914075
            ],
            [
              -119.571459,
              34.918775
            ],
            [
              -119.582157,
              34.924499
            ],
            [
              -119.588159,
              34.929675
            ],
            [
              -119.591274,
              34.933592
            ],
            [
              -119.597141,
              34.938513
            ],
            [
              -119.607251,
              34.945568
            ],
            [
              -119.616061,
              34.950575
            ],
            [
              -119.622861,
              34.949475
            ],
            [
              -119.628632,
              34.950861
            ],
            [
              -119.634513,
              34.953703
            ],
            [
              -119.638571,
              34.953757
            ],
            [
              -119.645298,
              34.955117
            ],
            [
              -119.653573,
              34.961465
            ],
            [
              -119.668083,
              34.971401
            ],
            [
              -119.673994,
              34.973513
            ],
            [
              -119.681366,
              34.971778
            ],
            [
              -119.698917,
              34.975965
            ],
            [
              -119.707459,
              34.975629
            ],
            [
              -119.711207,
              34.974328
            ],
            [
              -119.716402,
              34.972192
            ],
            [
              -119.724948,
              34.973857
            ],
            [
              -119.728605,
              34.97511
            ],
            [
              -119.748062,
              34.974868
            ],
            [
              -119.755499,
              34.980132
            ],
            [
              -119.765697,
              34.98303
            ],
            [
              -119.782381,
              34.990264
            ],
            [
              -119.791539,
              34.989765
            ],
            [
              -119.794847,
              34.991317
            ],
            [
              -119.800812,
              34.997155
            ],
            [
              -119.806399,
              34.999105
            ],
            [
              -119.80973,
              35.001881
            ],
            [
              -119.815362,
              35.00107
            ],
            [
              -119.823406,
              35.004642
            ],
            [
              -119.832239,
              35.007041
            ],
            [
              -119.84118,
              35.015015
            ],
            [
              -119.844921,
              35.017451
            ],
            [
              -119.846465,
              35.021506
            ],
            [
              -119.849991,
              35.026667
            ],
            [
              -119.857499,
              35.031877
            ],
            [
              -119.86446,
              35.032975
            ],
            [
              -119.870416,
              35.035378
            ],
            [
              -119.873604,
              35.03552
            ],
            [
              -119.876936,
              35.038036
            ],
            [
              -119.884738,
              35.040059
            ],
            [
              -119.887669,
              35.040472
            ],
            [
              -119.891089,
              35.039543
            ],
            [
              -119.894105,
              35.038703
            ],
            [
              -119.898783,
              35.037119
            ],
            [
              -119.901924,
              35.038555
            ],
            [
              -119.905506,
              35.038011
            ],
            [
              -119.906986,
              35.038731
            ],
            [
              -119.909921,
              35.04021
            ],
            [
              -119.911097,
              35.044027
            ],
            [
              -119.910296,
              35.045542
            ],
            [
              -119.911897,
              35.047199
            ],
            [
              -119.914939,
              35.048319
            ],
            [
              -119.921791,
              35.052543
            ],
            [
              -119.92494,
              35.055709
            ],
            [
              -119.92729,
              35.059098
            ],
            [
              -119.929507,
              35.058227
            ],
            [
              -119.934005,
              35.055913
            ],
            [
              -119.937079,
              35.055418
            ],
            [
              -119.942008,
              35.055354
            ],
            [
              -119.945838,
              35.056045
            ],
            [
              -119.949004,
              35.056524
            ],
            [
              -119.955817,
              35.056549
            ],
            [
              -119.957476,
              35.057249
            ],
            [
              -119.960689,
              35.055712
            ],
            [
              -119.968858,
              35.056709
            ],
            [
              -119.978357,
              35.059064
            ],
            [
              -119.983327,
              35.061665
            ],
            [
              -119.984284,
              35.062223
            ],
            [
              -119.996816,
              35.067624
            ],
            [
              -120.000661,
              35.071806
            ],
            [
              -120.012415,
              35.075485
            ],
            [
              -120.013833,
              35.078303
            ],
            [
              -120.015863,
              35.081839
            ],
            [
              -120.02245,
              35.080538
            ],
            [
              -120.02463,
              35.081938
            ],
            [
              -120.037609,
              35.088753
            ],
            [
              -120.044172,
              35.08611
            ],
            [
              -120.059989,
              35.093076
            ],
            [
              -120.060853,
              35.09457
            ],
            [
              -120.069108,
              35.098619
            ],
            [
              -120.072037,
              35.102001
            ],
            [
              -120.072344,
              35.103952
            ],
            [
              -120.076299,
              35.10475
            ],
            [
              -120.08231,
              35.105897
            ],
            [
              -120.084434,
              35.107083
            ],
            [
              -120.085785,
              35.108995
            ],
            [
              -120.084373,
              35.109506
            ],
            [
              -120.083131,
              35.109938
            ],
            [
              -120.080513,
              35.112061
            ],
            [
              -120.080169,
              35.113835
            ],
            [
              -120.083297,
              35.114556
            ],
            [
              -120.086515,
              35.113522
            ],
            [
              -120.092156,
              35.113851
            ],
            [
              -120.094987,
              35.112992
            ],
            [
              -120.096268,
              35.111544
            ],
            [
              -120.096504,
              35.109992
            ],
            [
              -120.097913,
              35.109502
            ],
            [
              -120.099263,
              35.110637
            ],
            [
              -120.099168,
              35.112134
            ],
            [
              -120.100627,
              35.113236
            ],
            [
              -120.102655,
              35.111653
            ],
            [
              -120.10209,
              35.110291
            ],
            [
              -120.102621,
              35.108344
            ],
            [
              -120.103253,
              35.108156
            ],
            [
              -120.104273,
              35.108081
            ],
            [
              -120.106195,
              35.108514
            ],
            [
              -120.10782,
              35.10698
            ],
            [
              -120.1075,
              35.105817
            ],
            [
              -120.109239,
              35.103616
            ],
            [
              -120.112215,
              35.103452
            ],
            [
              -120.114496,
              35.105176
            ],
            [
              -120.115755,
              35.106675
            ],
            [
              -120.116874,
              35.104915
            ],
            [
              -120.118632,
              35.103704
            ],
            [
              -120.119255,
              35.10175
            ],
            [
              -120.118441,
              35.100679
            ],
            [
              -120.119287,
              35.098553
            ],
            [
              -120.121325,
              35.097786
            ],
            [
              -120.122828,
              35.098535
            ],
            [
              -120.123851,
              35.099721
            ],
            [
              -120.124553,
              35.098696
            ],
            [
              -120.125377,
              35.097379
            ],
            [
              -120.126826,
              35.094907
            ],
            [
              -120.126651,
              35.092626
            ],
            [
              -120.127223,
              35.091268
            ],
            [
              -120.129207,
              35.090791
            ],
            [
              -120.130557,
              35.091871
            ],
            [
              -120.130183,
              35.093099
            ],
            [
              -120.129673,
              35.094063
            ],
            [
              -120.129573,
              35.095091
            ],
            [
              -120.131168,
              35.095693
            ],
            [
              -120.132213,
              35.095403
            ],
            [
              -120.1347,
              35.09723
            ],
            [
              -120.136219,
              35.097177
            ],
            [
              -120.137043,
              35.095976
            ],
            [
              -120.137462,
              35.095052
            ],
            [
              -120.139118,
              35.094369
            ],
            [
              -120.140137,
              35.094762
            ],
            [
              -120.141712,
              35.09588
            ],
            [
              -120.143597,
              35.094644
            ],
            [
              -120.142801,
              35.092651
            ],
            [
              -120.141524,
              35.091266
            ],
            [
              -120.14175,
              35.090704
            ],
            [
              -120.143209,
              35.090343
            ],
            [
              -120.144833,
              35.090021
            ],
            [
              -120.145329,
              35.088731
            ],
            [
              -120.145176,
              35.087555
            ],
            [
              -120.143444,
              35.086775
            ],
            [
              -120.142452,
              35.087194
            ],
            [
              -120.140763,
              35.086065
            ],
            [
              -120.141033,
              35.084619
            ],
            [
              -120.142369,
              35.083082
            ],
            [
              -120.145055,
              35.083402
            ],
            [
              -120.146851,
              35.083995
            ],
            [
              -120.148541,
              35.084452
            ],
            [
              -120.149678,
              35.08301
            ],
            [
              -120.149458,
              35.082196
            ],
            [
              -120.149762,
              35.081377
            ],
            [
              -120.150486,
              35.081351
            ],
            [
              -120.151114,
              35.081733
            ],
            [
              -120.152982,
              35.08201
            ],
            [
              -120.155408,
              35.080557
            ],
            [
              -120.155309,
              35.079012
            ],
            [
              -120.155873,
              35.078077
            ],
            [
              -120.158819,
              35.077189
            ],
            [
              -120.16197,
              35.075953
            ],
            [
              -120.165602,
              35.075522
            ],
            [
              -120.168074,
              35.075049
            ],
            [
              -120.16944,
              35.072955
            ],
            [
              -120.171011,
              35.070864
            ],
            [
              -120.17301,
              35.070155
            ],
            [
              -120.173476,
              35.070464
            ],
            [
              -120.174697,
              35.069579
            ],
            [
              -120.173476,
              35.068858
            ],
            [
              -120.173224,
              35.066336
            ],
            [
              -120.17298,
              35.064604
            ],
            [
              -120.17433,
              35.063128
            ],
            [
              -120.179037,
              35.061904
            ],
            [
              -120.179527,
              35.060351
            ],
            [
              -120.180314,
              35.055795
            ],
            [
              -120.182914,
              35.052798
            ],
            [
              -120.184913,
              35.049094
            ],
            [
              -120.183447,
              35.04566
            ],
            [
              -120.182264,
              35.044536
            ],
            [
              -120.182064,
              35.043356
            ],
            [
              -120.182725,
              35.043061
            ],
            [
              -120.183151,
              35.041991
            ],
            [
              -120.182641,
              35.041023
            ],
            [
              -120.181591,
              35.040286
            ],
            [
              -120.180978,
              35.03765
            ],
            [
              -120.183906,
              35.037132
            ],
            [
              -120.186913,
              35.035522
            ],
            [
              -120.188235,
              35.03244
            ],
            [
              -120.18893,
              35.030532
            ],
            [
              -120.191025,
              35.030009
            ],
            [
              -120.195061,
              35.029689
            ],
            [
              -120.200128,
              35.029537
            ],
            [
              -120.203729,
              35.027812
            ],
            [
              -120.205758,
              35.025001
            ],
            [
              -120.209199,
              35.024102
            ],
            [
              -120.210135,
              35.023821
            ],
            [
              -120.210256,
              35.02279
            ],
            [
              -120.209816,
              35.021959
            ],
            [
              -120.212554,
              35.021116
            ],
            [
              -120.215555,
              35.022575
            ],
            [
              -120.219683,
              35.021629
            ],
            [
              -120.222628,
              35.022056
            ],
            [
              -120.22639,
              35.022411
            ],
            [
              -120.228213,
              35.021568
            ],
            [
              -120.23054,
              35.020881
            ],
            [
              -120.232356,
              35.021358
            ],
            [
              -120.23228,
              35.022213
            ],
            [
              -120.234164,
              35.023655
            ],
            [
              -120.236667,
              35.023132
            ],
            [
              -120.239788,
              35.02243
            ],
            [
              -120.242321,
              35.023037
            ],
            [
              -120.245334,
              35.024807
            ],
            [
              -120.248356,
              35.028572
            ],
            [
              -120.249805,
              35.030064
            ],
            [
              -120.252362,
              35.02872
            ],
            [
              -120.253468,
              35.026691
            ],
            [
              -120.254261,
              35.022945
            ],
            [
              -120.25623,
              35.019573
            ],
            [
              -120.257115,
              35.018215
            ],
            [
              -120.259396,
              35.018825
            ],
            [
              -120.260342,
              35.020893
            ],
            [
              -120.260918,
              35.024415
            ],
            [
              -120.260102,
              35.025618
            ],
            [
              -120.260437,
              35.02657
            ],
            [
              -120.261396,
              35.026606
            ],
            [
              -120.262873,
              35.025428
            ],
            [
              -120.264064,
              35.023771
            ],
            [
              -120.269515,
              35.020768
            ],
            [
              -120.280344,
              35.012136
            ],
            [
              -120.292459,
              35.014712
            ],
            [
              -120.311943,
              35.010585
            ],
            [
              -120.322143,
              35.01643
            ],
            [
              -120.323011,
              35.016887
            ],
            [
              -120.324479,
              35.016618
            ],
            [
              -120.325391,
              35.015651
            ],
            [
              -120.330927,
              35.009974
            ],
            [
              -120.335355,
              35.003512
            ],
            [
              -120.330465,
              34.991931
            ],
            [
              -120.327639,
              34.987343
            ],
            [
              -120.323686,
              34.98557
            ],
            [
              -120.321662,
              34.985859
            ],
            [
              -120.315998,
              34.984005
            ],
            [
              -120.315642,
              34.977554
            ],
            [
              -120.305348,
              34.967802
            ],
            [
              -120.302207,
              34.961568
            ],
            [
              -120.304139,
              34.958003
            ],
            [
              -120.302752,
              34.954277
            ],
            [
              -120.296887,
              34.950641
            ],
            [
              -120.294548,
              34.946594
            ],
            [
              -120.292741,
              34.934867
            ],
            [
              -120.296237,
              34.921435
            ],
            [
              -120.29774,
              34.91987
            ],
            [
              -120.298988,
              34.910223
            ],
            [
              -120.299194,
              34.907862
            ],
            [
              -120.301108,
              34.905935
            ],
            [
              -120.303517,
              34.905856
            ],
            [
              -120.309576,
              34.904032
            ],
            [
              -120.314034,
              34.905209
            ],
            [
              -120.317651,
              34.90655
            ],
            [
              -120.320452,
              34.911789
            ],
            [
              -120.324459,
              34.919332
            ],
            [
              -120.32463,
              34.921863
            ],
            [
              -120.327557,
              34.925006
            ],
            [
              -120.33471,
              34.930244
            ],
            [
              -120.340667,
              34.933958
            ],
            [
              -120.347795,
              34.936303
            ],
            [
              -120.351959,
              34.938955
            ],
            [
              -120.35488,
              34.940657
            ],
            [
              -120.376081,
              34.954665
            ],
            [
              -120.402815,
              34.969382
            ],
            [
              -120.404555,
              34.970333
            ],
            [
              -120.413013,
              34.974596
            ],
            [
              -120.42163,
              34.978934
            ],
            [
              -120.428034,
              34.982293
            ],
            [
              -120.435165,
              34.986572
            ],
            [
              -120.43827,
              34.987819
            ],
            [
              -120.443525,
              34.988626
            ],
            [
              -120.447953,
              34.988976
            ],
            [
              -120.453819,
              34.989241
            ],
            [
              -120.480698,
              34.99158
            ],
            [
              -120.501,
              34.992109
            ],
            [
              -120.535243,
              34.984499
            ],
            [
              -120.543783,
              34.979525
            ],
            [
              -120.548589,
              34.977869
            ],
            [
              -120.552962,
              34.975115
            ],
            [
              -120.55455,
              34.974114
            ],
            [
              -120.559194,
              34.975267
            ],
            [
              -120.565581,
              34.977332
            ],
            [
              -120.567455,
              34.977435
            ],
            [
              -120.577937,
              34.975967
            ],
            [
              -120.584226,
              34.96924
            ],
            [
              -120.590774,
              34.966833
            ],
            [
              -120.594817,
              34.966538
            ],
            [
              -120.600839,
              34.971646
            ],
            [
              -120.603816,
              34.972536
            ],
            [
              -120.624632,
              34.963963
            ],
            [
              -120.627624,
              34.963142
            ],
            [
              -120.629676,
              34.962373
            ],
            [
              -120.640459,
              34.965909
            ],
            [
              -120.647096,
              34.972576
            ],
            [
              -120.64890505130701,
              34.9743932158666
            ],
            [
              -120.670835,
              34.904115
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "06",
      "COUNTYFP": "105",
      "COUNTYNS": "00277317",
      "AFFGEOID": "0500000US06105",
      "GEOID": "06105",
      "NAME": "Trinity",
      "LSAD": "06",
      "ALAND": 8234265086,
      "AWATER": 73407949,
      "County_state": "Trinity,06"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -123.617494,
              40.931144
            ],
            [
              -123.622276,
              40.926845
            ],
            [
              -123.615869,
              40.923742
            ],
            [
              -123.614664,
              40.912926
            ],
            [
              -123.606772,
              40.905376
            ],
            [
              -123.607154,
              40.894149
            ],
            [
              -123.6022,
              40.889359
            ],
            [
              -123.600911,
              40.880374
            ],
            [
              -123.597997,
              40.877615
            ],
            [
              -123.587228,
              40.869042
            ],
            [
              -123.584401,
              40.861085
            ],
            [
              -123.574827,
              40.856755
            ],
            [
              -123.574176,
              40.845677
            ],
            [
              -123.566281,
              40.836579
            ],
            [
              -123.566004,
              40.82269
            ],
            [
              -123.559525,
              40.810093
            ],
            [
              -123.556225,
              40.802764
            ],
            [
              -123.555308,
              40.794673
            ],
            [
              -123.560159,
              40.794983
            ],
            [
              -123.561047,
              40.790505
            ],
            [
              -123.557845,
              40.787961
            ],
            [
              -123.549502,
              40.774429
            ],
            [
              -123.550541,
              40.765813
            ],
            [
              -123.55113,
              40.756912
            ],
            [
              -123.550742,
              40.749858
            ],
            [
              -123.546219,
              40.740397
            ],
            [
              -123.543102,
              40.734014
            ],
            [
              -123.544451,
              40.693805
            ],
            [
              -123.544243,
              40.636004
            ],
            [
              -123.544507,
              40.591174
            ],
            [
              -123.544291,
              40.53566
            ],
            [
              -123.544347,
              40.456924
            ],
            [
              -123.544508,
              40.374726
            ],
            [
              -123.5444,
              40.269889
            ],
            [
              -123.54403,
              40.19701
            ],
            [
              -123.544129,
              40.133666
            ],
            [
              -123.544273,
              40.07627
            ],
            [
              -123.544385,
              40.034099
            ],
            [
              -123.544458,
              40.001923
            ],
            [
              -123.544563,
              39.977015
            ],
            [
              -122.934013,
              39.978131
            ],
            [
              -122.951671,
              39.998271
            ],
            [
              -122.956378,
              40.01994
            ],
            [
              -122.94976,
              40.032101
            ],
            [
              -122.952831,
              40.059723
            ],
            [
              -122.973222,
              40.077553
            ],
            [
              -122.961088,
              40.099996
            ],
            [
              -122.973657,
              40.130025
            ],
            [
              -122.968568,
              40.139033
            ],
            [
              -122.989623,
              40.145496
            ],
            [
              -122.983937,
              40.171984
            ],
            [
              -122.970064,
              40.186306
            ],
            [
              -122.980027,
              40.214708
            ],
            [
              -122.97755,
              40.241285
            ],
            [
              -123.01858,
              40.262783
            ],
            [
              -123.051427,
              40.268143
            ],
            [
              -123.065426,
              40.286971
            ],
            [
              -123.064707,
              40.288315
            ],
            [
              -123.065269,
              40.291765
            ],
            [
              -123.065818,
              40.296231
            ],
            [
              -123.065034,
              40.298461
            ],
            [
              -123.065687,
              40.299635
            ],
            [
              -123.065897,
              40.300798
            ],
            [
              -123.067393,
              40.302321
            ],
            [
              -123.068141,
              40.306282
            ],
            [
              -123.063824,
              40.318285
            ],
            [
              -123.067056,
              40.328985
            ],
            [
              -123.066807,
              40.335411
            ],
            [
              -123.063574,
              40.340973
            ],
            [
              -123.061431,
              40.347218
            ],
            [
              -123.053308,
              40.347357
            ],
            [
              -123.049456,
              40.346872
            ],
            [
              -123.044146,
              40.348749
            ],
            [
              -123.044463,
              40.351591
            ],
            [
              -123.040724,
              40.359566
            ],
            [
              -123.032578,
              40.363932
            ],
            [
              -123.022274,
              40.368171
            ],
            [
              -123.018407,
              40.377441
            ],
            [
              -123.01739,
              40.384488
            ],
            [
              -123.005478,
              40.390316
            ],
            [
              -122.997018,
              40.397422
            ],
            [
              -122.997804,
              40.409845
            ],
            [
              -122.994353,
              40.4184
            ],
            [
              -122.985941,
              40.419073
            ],
            [
              -122.977658,
              40.419072
            ],
            [
              -122.96338,
              40.424877
            ],
            [
              -122.951095,
              40.423961
            ],
            [
              -122.937654,
              40.429981
            ],
            [
              -122.935484,
              40.438226
            ],
            [
              -122.93111,
              40.444879
            ],
            [
              -122.919165,
              40.448592
            ],
            [
              -122.908211,
              40.446488
            ],
            [
              -122.904286,
              40.445175
            ],
            [
              -122.900445,
              40.447354
            ],
            [
              -122.898278,
              40.44925
            ],
            [
              -122.894348,
              40.460299
            ],
            [
              -122.889606,
              40.465311
            ],
            [
              -122.889468,
              40.467407
            ],
            [
              -122.888224,
              40.469204
            ],
            [
              -122.886242,
              40.470107
            ],
            [
              -122.883879,
              40.474036
            ],
            [
              -122.874805,
              40.480623
            ],
            [
              -122.862288,
              40.482156
            ],
            [
              -122.851731,
              40.485669
            ],
            [
              -122.846708,
              40.492144
            ],
            [
              -122.848718,
              40.497999
            ],
            [
              -122.846366,
              40.50477
            ],
            [
              -122.840686,
              40.506498
            ],
            [
              -122.833641,
              40.506824
            ],
            [
              -122.827145,
              40.508002
            ],
            [
              -122.8131,
              40.510665
            ],
            [
              -122.797988,
              40.512508
            ],
            [
              -122.793276,
              40.520121
            ],
            [
              -122.782443,
              40.533137
            ],
            [
              -122.782806,
              40.543354
            ],
            [
              -122.774132,
              40.549523
            ],
            [
              -122.767634,
              40.554981
            ],
            [
              -122.76088,
              40.556068
            ],
            [
              -122.752995,
              40.55538
            ],
            [
              -122.742499,
              40.558064
            ],
            [
              -122.740263,
              40.565071
            ],
            [
              -122.728087,
              40.572785
            ],
            [
              -122.717614,
              40.572158
            ],
            [
              -122.704313,
              40.569527
            ],
            [
              -122.696781,
              40.57157
            ],
            [
              -122.693346,
              40.577717
            ],
            [
              -122.696243,
              40.5826
            ],
            [
              -122.69989,
              40.590324
            ],
            [
              -122.701982,
              40.593034
            ],
            [
              -122.70282,
              40.597204
            ],
            [
              -122.704987,
              40.6032
            ],
            [
              -122.70617,
              40.609371
            ],
            [
              -122.710854,
              40.613215
            ],
            [
              -122.71075,
              40.621644
            ],
            [
              -122.709562,
              40.627409
            ],
            [
              -122.715622,
              40.634045
            ],
            [
              -122.724617,
              40.634705
            ],
            [
              -122.729873,
              40.634924
            ],
            [
              -122.73576,
              40.63845
            ],
            [
              -122.733696,
              40.641623
            ],
            [
              -122.729594,
              40.646209
            ],
            [
              -122.728038,
              40.651701
            ],
            [
              -122.730445,
              40.654019
            ],
            [
              -122.734091,
              40.658274
            ],
            [
              -122.733482,
              40.659933
            ],
            [
              -122.734941,
              40.662958
            ],
            [
              -122.736894,
              40.663805
            ],
            [
              -122.741336,
              40.665747
            ],
            [
              -122.741801,
              40.668905
            ],
            [
              -122.742528,
              40.671263
            ],
            [
              -122.742952,
              40.673002
            ],
            [
              -122.743006,
              40.674921
            ],
            [
              -122.74373,
              40.679365
            ],
            [
              -122.749491,
              40.683414
            ],
            [
              -122.751634,
              40.686402
            ],
            [
              -122.748695,
              40.692063
            ],
            [
              -122.745579,
              40.693716
            ],
            [
              -122.740629,
              40.694495
            ],
            [
              -122.736512,
              40.69262
            ],
            [
              -122.728937,
              40.692609
            ],
            [
              -122.723739,
              40.694742
            ],
            [
              -122.722088,
              40.699169
            ],
            [
              -122.719242,
              40.705894
            ],
            [
              -122.711616,
              40.710537
            ],
            [
              -122.709469,
              40.719976
            ],
            [
              -122.706741,
              40.724656
            ],
            [
              -122.709436,
              40.729123
            ],
            [
              -122.714974,
              40.735265
            ],
            [
              -122.718916,
              40.741582
            ],
            [
              -122.713215,
              40.747711
            ],
            [
              -122.707158,
              40.749382
            ],
            [
              -122.701028,
              40.75463
            ],
            [
              -122.700929,
              40.756685
            ],
            [
              -122.697645,
              40.759917
            ],
            [
              -122.691709,
              40.761381
            ],
            [
              -122.687781,
              40.76448
            ],
            [
              -122.683427,
              40.76594
            ],
            [
              -122.677124,
              40.769359
            ],
            [
              -122.667372,
              40.777126
            ],
            [
              -122.667707,
              40.779142
            ],
            [
              -122.665572,
              40.782076
            ],
            [
              -122.660369,
              40.785595
            ],
            [
              -122.660338,
              40.791256
            ],
            [
              -122.660824,
              40.798386
            ],
            [
              -122.656758,
              40.804784
            ],
            [
              -122.656186,
              40.810461
            ],
            [
              -122.661152,
              40.818469
            ],
            [
              -122.665167,
              40.826882
            ],
            [
              -122.661561,
              40.831136
            ],
            [
              -122.656279,
              40.838526
            ],
            [
              -122.645419,
              40.843781
            ],
            [
              -122.638636,
              40.853962
            ],
            [
              -122.63438,
              40.861289
            ],
            [
              -122.629137,
              40.865178
            ],
            [
              -122.626062,
              40.871336
            ],
            [
              -122.62627,
              40.874857
            ],
            [
              -122.619622,
              40.879676
            ],
            [
              -122.611453,
              40.883857
            ],
            [
              -122.607763,
              40.887085
            ],
            [
              -122.605909,
              40.890104
            ],
            [
              -122.604523,
              40.895299
            ],
            [
              -122.601485,
              40.9034
            ],
            [
              -122.604797,
              40.909465
            ],
            [
              -122.612448,
              40.921762
            ],
            [
              -122.607694,
              40.93048
            ],
            [
              -122.606768,
              40.938618
            ],
            [
              -122.603509,
              40.942237
            ],
            [
              -122.605131,
              40.949038
            ],
            [
              -122.606578,
              40.952709
            ],
            [
              -122.603204,
              40.960606
            ],
            [
              -122.601148,
              40.967351
            ],
            [
              -122.60125,
              40.971888
            ],
            [
              -122.59943,
              40.973286
            ],
            [
              -122.594883,
              40.977903
            ],
            [
              -122.589699,
              40.982801
            ],
            [
              -122.587301,
              40.985183
            ],
            [
              -122.584517,
              40.983966
            ],
            [
              -122.569181,
              40.984988
            ],
            [
              -122.563026,
              40.99368
            ],
            [
              -122.555243,
              41.002745
            ],
            [
              -122.544056,
              41.004255
            ],
            [
              -122.532105,
              41.009853
            ],
            [
              -122.527092,
              41.021085
            ],
            [
              -122.52746,
              41.043481
            ],
            [
              -122.529488,
              41.064582
            ],
            [
              -122.526035,
              41.078829
            ],
            [
              -122.510818,
              41.087456
            ],
            [
              -122.484206,
              41.090028
            ],
            [
              -122.461728,
              41.09738
            ],
            [
              -122.451888,
              41.114698
            ],
            [
              -122.448185,
              41.137574
            ],
            [
              -122.447632,
              41.159012
            ],
            [
              -122.469313,
              41.170165
            ],
            [
              -122.492554,
              41.175972
            ],
            [
              -122.498376,
              41.182675
            ],
            [
              -122.497504,
              41.186803
            ],
            [
              -122.515492,
              41.204992
            ],
            [
              -122.515063,
              41.232615
            ],
            [
              -122.509884,
              41.249871
            ],
            [
              -122.512499,
              41.277379
            ],
            [
              -122.498367,
              41.294571
            ],
            [
              -122.499355,
              41.302476
            ],
            [
              -122.494797,
              41.311951
            ],
            [
              -122.488281,
              41.31621
            ],
            [
              -122.487014,
              41.32447
            ],
            [
              -122.494636,
              41.328852
            ],
            [
              -122.511292,
              41.343728
            ],
            [
              -122.541004,
              41.345889
            ],
            [
              -122.55169,
              41.361702
            ],
            [
              -122.565749,
              41.365464
            ],
            [
              -122.582678,
              41.360096
            ],
            [
              -122.586055,
              41.347036
            ],
            [
              -122.58712,
              41.333281
            ],
            [
              -122.598758,
              41.325778
            ],
            [
              -122.610233,
              41.328839
            ],
            [
              -122.623932,
              41.32946
            ],
            [
              -122.647578,
              41.318062
            ],
            [
              -122.647978,
              41.302598
            ],
            [
              -122.663488,
              41.291644
            ],
            [
              -122.686119,
              41.281092
            ],
            [
              -122.700268,
              41.276077
            ],
            [
              -122.723383,
              41.262076
            ],
            [
              -122.726111,
              41.252932
            ],
            [
              -122.737593,
              41.244001
            ],
            [
              -122.751137,
              41.241518
            ],
            [
              -122.777882,
              41.22865
            ],
            [
              -122.797704,
              41.20822
            ],
            [
              -122.830025,
              41.20862
            ],
            [
              -122.866345,
              41.203907
            ],
            [
              -122.882059,
              41.203949
            ],
            [
              -122.891957,
              41.20282
            ],
            [
              -122.903367,
              41.19715
            ],
            [
              -122.911384,
              41.190522
            ],
            [
              -122.92276,
              41.187222
            ],
            [
              -122.927426,
              41.182029
            ],
            [
              -122.934331,
              41.177665
            ],
            [
              -122.94134,
              41.179604
            ],
            [
              -122.947603,
              41.182961
            ],
            [
              -122.955515,
              41.182795
            ],
            [
              -122.959897,
              41.181566
            ],
            [
              -122.961488,
              41.175616
            ],
            [
              -122.957501,
              41.169783
            ],
            [
              -122.956421,
              41.159804
            ],
            [
              -122.95174,
              41.151045
            ],
            [
              -122.959647,
              41.142785
            ],
            [
              -122.964572,
              41.136873
            ],
            [
              -122.960516,
              41.125482
            ],
            [
              -122.970739,
              41.104406
            ],
            [
              -122.96889,
              41.086761
            ],
            [
              -122.94314,
              41.07075
            ],
            [
              -122.921975,
              41.065865
            ],
            [
              -122.90943,
              41.045523
            ],
            [
              -122.900223,
              41.026167
            ],
            [
              -122.908618,
              41.008658
            ],
            [
              -122.916495,
              40.995706
            ],
            [
              -122.934339,
              40.992586
            ],
            [
              -122.94888,
              40.999863
            ],
            [
              -122.971646,
              41.012304
            ],
            [
              -122.998097,
              41.013092
            ],
            [
              -123.010398,
              41.007693
            ],
            [
              -123.027711,
              41.00495
            ],
            [
              -123.032004,
              41.004478
            ],
            [
              -123.040689,
              41.008652
            ],
            [
              -123.043623,
              41.016047
            ],
            [
              -123.04727,
              41.024459
            ],
            [
              -123.04637,
              41.030585
            ],
            [
              -123.050657,
              41.039951
            ],
            [
              -123.057601,
              41.046842
            ],
            [
              -123.069904,
              41.053097
            ],
            [
              -123.08654,
              41.05795
            ],
            [
              -123.099058,
              41.063171
            ],
            [
              -123.107951,
              41.070317
            ],
            [
              -123.124611,
              41.076727
            ],
            [
              -123.15691,
              41.075837
            ],
            [
              -123.187248,
              41.07745
            ],
            [
              -123.213179,
              41.073348
            ],
            [
              -123.24277,
              41.077946
            ],
            [
              -123.257174,
              41.091947
            ],
            [
              -123.270315,
              41.113915
            ],
            [
              -123.284272,
              41.127458
            ],
            [
              -123.297921,
              41.141444
            ],
            [
              -123.326152,
              41.145679
            ],
            [
              -123.33888,
              41.155497
            ],
            [
              -123.360056,
              41.161503
            ],
            [
              -123.370528,
              41.155486
            ],
            [
              -123.389231,
              41.159613
            ],
            [
              -123.402459,
              41.173877
            ],
            [
              -123.408291,
              41.179944
            ],
            [
              -123.409598,
              41.17664
            ],
            [
              -123.425225,
              41.164903
            ],
            [
              -123.429964,
              41.149979
            ],
            [
              -123.429566,
              41.131306
            ],
            [
              -123.435827,
              41.10655
            ],
            [
              -123.441224,
              41.0948
            ],
            [
              -123.452258,
              41.08944
            ],
            [
              -123.456451,
              41.090493
            ],
            [
              -123.460694,
              41.080846
            ],
            [
              -123.454774,
              41.068652
            ],
            [
              -123.428326,
              41.061047
            ],
            [
              -123.423534,
              41.051495
            ],
            [
              -123.423842,
              41.044585
            ],
            [
              -123.422454,
              41.041197
            ],
            [
              -123.420542,
              41.03781
            ],
            [
              -123.409406,
              41.030482
            ],
            [
              -123.409048,
              41.019166
            ],
            [
              -123.41613,
              41.010023
            ],
            [
              -123.425755,
              41.003511
            ],
            [
              -123.427758,
              40.992656
            ],
            [
              -123.434955,
              40.97974
            ],
            [
              -123.441593,
              40.970603
            ],
            [
              -123.447416,
              40.966621
            ],
            [
              -123.450357,
              40.958957
            ],
            [
              -123.444761,
              40.951853
            ],
            [
              -123.449488,
              40.943133
            ],
            [
              -123.460245,
              40.94073
            ],
            [
              -123.466003,
              40.937902
            ],
            [
              -123.475658,
              40.930656
            ],
            [
              -123.479957,
              40.918457
            ],
            [
              -123.485457,
              40.915357
            ],
            [
              -123.496657,
              40.916157
            ],
            [
              -123.519358,
              40.926856
            ],
            [
              -123.531959,
              40.933356
            ],
            [
              -123.541659,
              40.934656
            ],
            [
              -123.55516,
              40.947455
            ],
            [
              -123.564361,
              40.949055
            ],
            [
              -123.568761,
              40.942655
            ],
            [
              -123.572405,
              40.936858
            ],
            [
              -123.584261,
              40.931262
            ],
            [
              -123.587861,
              40.927755
            ],
            [
              -123.609562,
              40.929355
            ],
            [
              -123.617494,
              40.931144
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "06",
      "COUNTYFP": "099",
      "COUNTYNS": "00277314",
      "AFFGEOID": "0500000US06099",
      "GEOID": "06099",
      "NAME": "Stanislaus",
      "LSAD": "06",
      "ALAND": 3874634688,
      "AWATER": 46384540,
      "County_state": "Stanislaus,06"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -121.486775,
              37.475652
            ],
            [
              -121.484252,
              37.466032
            ],
            [
              -121.462917,
              37.451489
            ],
            [
              -121.46186,
              37.438799
            ],
            [
              -121.472606,
              37.423345
            ],
            [
              -121.456351,
              37.406735
            ],
            [
              -121.456651,
              37.395535
            ],
            [
              -121.436085,
              37.39613
            ],
            [
              -121.412549,
              37.389435
            ],
            [
              -121.409693,
              37.382013
            ],
            [
              -121.42365,
              37.358837
            ],
            [
              -121.40915,
              37.330637
            ],
            [
              -121.405753,
              37.31099
            ],
            [
              -121.436816,
              37.291971
            ],
            [
              -121.443551,
              37.296638
            ],
            [
              -121.459051,
              37.282739
            ],
            [
              -121.453977,
              37.276427
            ],
            [
              -121.45575,
              37.24944
            ],
            [
              -121.416869,
              37.211262
            ],
            [
              -121.404636,
              37.155989
            ],
            [
              -121.393424,
              37.148131
            ],
            [
              -121.374053,
              37.17304
            ],
            [
              -121.354561,
              37.183893
            ],
            [
              -121.328409,
              37.16595
            ],
            [
              -121.29773,
              37.166429
            ],
            [
              -121.281107,
              37.183603
            ],
            [
              -121.262293,
              37.159473
            ],
            [
              -121.237712,
              37.15758
            ],
            [
              -121.226804,
              37.134774
            ],
            [
              -121.06634,
              37.262939
            ],
            [
              -120.963812,
              37.346144
            ],
            [
              -120.976465,
              37.349616
            ],
            [
              -120.976121,
              37.377552
            ],
            [
              -120.99296,
              37.385324
            ],
            [
              -120.983756,
              37.39964
            ],
            [
              -120.886429,
              37.44018
            ],
            [
              -120.81557,
              37.466963
            ],
            [
              -120.38767,
              37.633364
            ],
            [
              -120.387613,
              37.633704
            ],
            [
              -120.653274,
              37.831858
            ],
            [
              -120.74453,
              37.912419
            ],
            [
              -120.752695,
              37.923334
            ],
            [
              -120.832781,
              37.99384
            ],
            [
              -120.876047,
              38.028894
            ],
            [
              -120.926449,
              38.077421
            ],
            [
              -120.922643,
              37.759568
            ],
            [
              -120.920993,
              37.737947
            ],
            [
              -120.954032,
              37.738352
            ],
            [
              -120.995696,
              37.760178
            ],
            [
              -121.028816,
              37.740704
            ],
            [
              -121.05674,
              37.750514
            ],
            [
              -121.092734,
              37.73475
            ],
            [
              -121.110692,
              37.741776
            ],
            [
              -121.110248,
              37.730128
            ],
            [
              -121.146106,
              37.717499
            ],
            [
              -121.154562,
              37.7202
            ],
            [
              -121.15922,
              37.704439
            ],
            [
              -121.176299,
              37.704047
            ],
            [
              -121.178951,
              37.691083
            ],
            [
              -121.194228,
              37.694953
            ],
            [
              -121.216391,
              37.68787
            ],
            [
              -121.21847,
              37.674032
            ],
            [
              -121.241219,
              37.664009
            ],
            [
              -121.269447,
              37.641628
            ],
            [
              -121.471925,
              37.481783
            ],
            [
              -121.472648,
              37.48217
            ],
            [
              -121.486775,
              37.475652
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "06",
      "COUNTYFP": "011",
      "COUNTYNS": "01675902",
      "AFFGEOID": "0500000US06011",
      "GEOID": "06011",
      "NAME": "Colusa",
      "LSAD": "06",
      "ALAND": 2980332860,
      "AWATER": 14608869,
      "County_state": "Colusa,06"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -122.78509,
              39.382975
            ],
            [
              -122.743482,
              39.36813
            ],
            [
              -122.752355,
              39.346047
            ],
            [
              -122.76531,
              39.342743
            ],
            [
              -122.775043,
              39.311778
            ],
            [
              -122.757095,
              39.284307
            ],
            [
              -122.7297,
              39.273486
            ],
            [
              -122.712454,
              39.25701
            ],
            [
              -122.680927,
              39.238726
            ],
            [
              -122.665362,
              39.217548
            ],
            [
              -122.650138,
              39.213922
            ],
            [
              -122.63516,
              39.223052
            ],
            [
              -122.578573,
              39.210291
            ],
            [
              -122.573442,
              39.204288
            ],
            [
              -122.515331,
              39.209215
            ],
            [
              -122.513788,
              39.196007
            ],
            [
              -122.498538,
              39.191091
            ],
            [
              -122.494012,
              39.178098
            ],
            [
              -122.47729,
              39.17395
            ],
            [
              -122.479527,
              39.158038
            ],
            [
              -122.478227,
              39.139782
            ],
            [
              -122.496835,
              39.140501
            ],
            [
              -122.500909,
              39.121075
            ],
            [
              -122.486249,
              39.08251
            ],
            [
              -122.492215,
              39.054092
            ],
            [
              -122.468178,
              39.044761
            ],
            [
              -122.43729,
              39.021357
            ],
            [
              -122.413416,
              39.020218
            ],
            [
              -122.408617,
              39.006985
            ],
            [
              -122.41931,
              38.989177
            ],
            [
              -122.409118,
              38.962793
            ],
            [
              -122.373023,
              38.939817
            ],
            [
              -122.340172,
              38.924246
            ],
            [
              -121.835488,
              38.924481
            ],
            [
              -121.830134,
              38.938425
            ],
            [
              -121.840374,
              38.95452
            ],
            [
              -121.832086,
              38.97116
            ],
            [
              -121.821398,
              38.974743
            ],
            [
              -121.819142,
              38.988551
            ],
            [
              -121.795366,
              38.995686
            ],
            [
              -121.825954,
              39.01065
            ],
            [
              -121.824375,
              39.031781
            ],
            [
              -121.838744,
              39.045091
            ],
            [
              -121.838213,
              39.062953
            ],
            [
              -121.847964,
              39.070817
            ],
            [
              -121.869817,
              39.06686
            ],
            [
              -121.889101,
              39.072373
            ],
            [
              -121.894369,
              39.096126
            ],
            [
              -121.905924,
              39.104195
            ],
            [
              -121.912302,
              39.123876
            ],
            [
              -121.922086,
              39.122632
            ],
            [
              -121.939172,
              39.136145
            ],
            [
              -121.937254,
              39.144227
            ],
            [
              -121.918983,
              39.142253
            ],
            [
              -121.945514,
              39.179843
            ],
            [
              -121.928332,
              39.198729
            ],
            [
              -121.948177,
              39.240387
            ],
            [
              -121.936916,
              39.26795
            ],
            [
              -121.920796,
              39.281631
            ],
            [
              -121.908269,
              39.303878
            ],
            [
              -121.894389,
              39.341085
            ],
            [
              -121.899139,
              39.35692
            ],
            [
              -121.884047,
              39.373857
            ],
            [
              -121.890013,
              39.383864
            ],
            [
              -122.009735,
              39.383864
            ],
            [
              -122.003828,
              39.396994
            ],
            [
              -122.008857,
              39.413567
            ],
            [
              -122.136161,
              39.414499
            ],
            [
              -122.136006,
              39.385494
            ],
            [
              -122.192334,
              39.385257
            ],
            [
              -122.594548,
              39.385542
            ],
            [
              -122.739062,
              39.383266
            ],
            [
              -122.78509,
              39.382975
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "06",
      "COUNTYFP": "003",
      "COUNTYNS": "01675840",
      "AFFGEOID": "0500000US06003",
      "GEOID": "06003",
      "NAME": "Alpine",
      "LSAD": "06",
      "ALAND": 1912292631,
      "AWATER": 12557304,
      "County_state": "Alpine,06"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -120.072484,
              38.509869
            ],
            [
              -120.072566,
              38.447081
            ],
            [
              -120.05365,
              38.455607
            ],
            [
              -120.019951,
              38.433521
            ],
            [
              -119.884749,
              38.356185
            ],
            [
              -119.869667,
              38.367597
            ],
            [
              -119.837551,
              38.382411
            ],
            [
              -119.814691,
              38.387516
            ],
            [
              -119.801737,
              38.401321
            ],
            [
              -119.770553,
              38.406663
            ],
            [
              -119.753481,
              38.416759
            ],
            [
              -119.712893,
              38.418452
            ],
            [
              -119.704637,
              38.415736
            ],
            [
              -119.698671,
              38.409838
            ],
            [
              -119.693622,
              38.378899
            ],
            [
              -119.700029,
              38.365215
            ],
            [
              -119.669524,
              38.348288
            ],
            [
              -119.639205,
              38.32688
            ],
            [
              -119.628295,
              38.349733
            ],
            [
              -119.635575,
              38.353908
            ],
            [
              -119.607395,
              38.366458
            ],
            [
              -119.622093,
              38.393875
            ],
            [
              -119.601212,
              38.405354
            ],
            [
              -119.592409,
              38.398877
            ],
            [
              -119.561995,
              38.410734
            ],
            [
              -119.570009,
              38.43486
            ],
            [
              -119.556426,
              38.447465
            ],
            [
              -119.555863,
              38.470242
            ],
            [
              -119.542367,
              38.481657
            ],
            [
              -119.542862,
              38.499694
            ],
            [
              -119.556616,
              38.501702
            ],
            [
              -119.556217,
              38.516621
            ],
            [
              -119.568055,
              38.537707
            ],
            [
              -119.587367,
              38.558354
            ],
            [
              -119.599815,
              38.593348
            ],
            [
              -119.619237,
              38.604501
            ],
            [
              -119.614658,
              38.665879
            ],
            [
              -119.598647,
              38.670942
            ],
            [
              -119.579518,
              38.705609
            ],
            [
              -119.585437,
              38.713212
            ],
            [
              -119.587066,
              38.714345
            ],
            [
              -119.587679,
              38.714734
            ],
            [
              -119.904315,
              38.933324
            ],
            [
              -119.887643,
              38.918295
            ],
            [
              -119.879516,
              38.887021
            ],
            [
              -119.888444,
              38.879301
            ],
            [
              -119.877287,
              38.870193
            ],
            [
              -119.891909,
              38.857344
            ],
            [
              -119.906779,
              38.854664
            ],
            [
              -119.908493,
              38.834346
            ],
            [
              -119.92271,
              38.829955
            ],
            [
              -119.92186,
              38.820962
            ],
            [
              -119.942108,
              38.80311
            ],
            [
              -119.947927,
              38.781642
            ],
            [
              -119.964948,
              38.775986
            ],
            [
              -120.072392,
              38.702767
            ],
            [
              -120.072484,
              38.509869
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "06",
      "COUNTYFP": "093",
      "COUNTYNS": "00277311",
      "AFFGEOID": "0500000US06093",
      "GEOID": "06093",
      "NAME": "Siskiyou",
      "LSAD": "06",
      "ALAND": 16261933292,
      "AWATER": 179149824,
      "County_state": "Siskiyou,06"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -123.718797,
              41.599264
            ],
            [
              -123.718331,
              41.594652
            ],
            [
              -123.712382,
              41.592774
            ],
            [
              -123.709132,
              41.594292
            ],
            [
              -123.702327,
              41.595232
            ],
            [
              -123.698408,
              41.592147
            ],
            [
              -123.690037,
              41.593382
            ],
            [
              -123.683476,
              41.592217
            ],
            [
              -123.683044,
              41.590491
            ],
            [
              -123.683187,
              41.586075
            ],
            [
              -123.685707,
              41.585538
            ],
            [
              -123.687957,
              41.579841
            ],
            [
              -123.684988,
              41.576021
            ],
            [
              -123.683841,
              41.569632
            ],
            [
              -123.688524,
              41.563732
            ],
            [
              -123.693183,
              41.557515
            ],
            [
              -123.689813,
              41.550787
            ],
            [
              -123.682949,
              41.543205
            ],
            [
              -123.673293,
              41.543624
            ],
            [
              -123.669685,
              41.54368
            ],
            [
              -123.668227,
              41.54217
            ],
            [
              -123.66415,
              41.541162
            ],
            [
              -123.656417,
              41.538616
            ],
            [
              -123.653814,
              41.539249
            ],
            [
              -123.649176,
              41.536023
            ],
            [
              -123.646838,
              41.531422
            ],
            [
              -123.646826,
              41.528986
            ],
            [
              -123.645402,
              41.526283
            ],
            [
              -123.644365,
              41.524162
            ],
            [
              -123.644218,
              41.521723
            ],
            [
              -123.64523,
              41.519881
            ],
            [
              -123.646038,
              41.515789
            ],
            [
              -123.644114,
              41.509264
            ],
            [
              -123.639875,
              41.500912
            ],
            [
              -123.638204,
              41.495506
            ],
            [
              -123.635403,
              41.483696
            ],
            [
              -123.628495,
              41.470012
            ],
            [
              -123.617944,
              41.463748
            ],
            [
              -123.613757,
              41.455889
            ],
            [
              -123.6153,
              41.446447
            ],
            [
              -123.62107,
              41.435768
            ],
            [
              -123.639221,
              41.427041
            ],
            [
              -123.643016,
              41.41646
            ],
            [
              -123.646256,
              41.398154
            ],
            [
              -123.655409,
              41.388819
            ],
            [
              -123.658428,
              41.38624
            ],
            [
              -123.661363,
              41.38209
            ],
            [
              -123.50117,
              41.382567
            ],
            [
              -123.487831,
              41.376934
            ],
            [
              -123.474085,
              41.366193
            ],
            [
              -123.48251,
              41.35365
            ],
            [
              -123.47748,
              41.327751
            ],
            [
              -123.46344,
              41.31773
            ],
            [
              -123.455475,
              41.292819
            ],
            [
              -123.461649,
              41.282281
            ],
            [
              -123.446812,
              41.276041
            ],
            [
              -123.442776,
              41.249475
            ],
            [
              -123.455356,
              41.236873
            ],
            [
              -123.437897,
              41.226897
            ],
            [
              -123.435348,
              41.213361
            ],
            [
              -123.417095,
              41.197002
            ],
            [
              -123.408291,
              41.179944
            ],
            [
              -123.402459,
              41.173877
            ],
            [
              -123.389231,
              41.159613
            ],
            [
              -123.370528,
              41.155486
            ],
            [
              -123.360056,
              41.161503
            ],
            [
              -123.33888,
              41.155497
            ],
            [
              -123.326152,
              41.145679
            ],
            [
              -123.297921,
              41.141444
            ],
            [
              -123.284272,
              41.127458
            ],
            [
              -123.270315,
              41.113915
            ],
            [
              -123.257174,
              41.091947
            ],
            [
              -123.24277,
              41.077946
            ],
            [
              -123.213179,
              41.073348
            ],
            [
              -123.187248,
              41.07745
            ],
            [
              -123.15691,
              41.075837
            ],
            [
              -123.124611,
              41.076727
            ],
            [
              -123.107951,
              41.070317
            ],
            [
              -123.099058,
              41.063171
            ],
            [
              -123.08654,
              41.05795
            ],
            [
              -123.069904,
              41.053097
            ],
            [
              -123.057601,
              41.046842
            ],
            [
              -123.050657,
              41.039951
            ],
            [
              -123.04637,
              41.030585
            ],
            [
              -123.04727,
              41.024459
            ],
            [
              -123.043623,
              41.016047
            ],
            [
              -123.040689,
              41.008652
            ],
            [
              -123.032004,
              41.004478
            ],
            [
              -123.027711,
              41.00495
            ],
            [
              -123.010398,
              41.007693
            ],
            [
              -122.998097,
              41.013092
            ],
            [
              -122.971646,
              41.012304
            ],
            [
              -122.94888,
              40.999863
            ],
            [
              -122.934339,
              40.992586
            ],
            [
              -122.916495,
              40.995706
            ],
            [
              -122.908618,
              41.008658
            ],
            [
              -122.900223,
              41.026167
            ],
            [
              -122.90943,
              41.045523
            ],
            [
              -122.921975,
              41.065865
            ],
            [
              -122.94314,
              41.07075
            ],
            [
              -122.96889,
              41.086761
            ],
            [
              -122.970739,
              41.104406
            ],
            [
              -122.960516,
              41.125482
            ],
            [
              -122.964572,
              41.136873
            ],
            [
              -122.959647,
              41.142785
            ],
            [
              -122.95174,
              41.151045
            ],
            [
              -122.956421,
              41.159804
            ],
            [
              -122.957501,
              41.169783
            ],
            [
              -122.961488,
              41.175616
            ],
            [
              -122.959897,
              41.181566
            ],
            [
              -122.955515,
              41.182795
            ],
            [
              -122.947603,
              41.182961
            ],
            [
              -122.94134,
              41.179604
            ],
            [
              -122.934331,
              41.177665
            ],
            [
              -122.927426,
              41.182029
            ],
            [
              -122.92276,
              41.187222
            ],
            [
              -122.911384,
              41.190522
            ],
            [
              -122.903367,
              41.19715
            ],
            [
              -122.891957,
              41.20282
            ],
            [
              -122.882059,
              41.203949
            ],
            [
              -122.866345,
              41.203907
            ],
            [
              -122.830025,
              41.20862
            ],
            [
              -122.797704,
              41.20822
            ],
            [
              -122.777882,
              41.22865
            ],
            [
              -122.751137,
              41.241518
            ],
            [
              -122.737593,
              41.244001
            ],
            [
              -122.726111,
              41.252932
            ],
            [
              -122.723383,
              41.262076
            ],
            [
              -122.700268,
              41.276077
            ],
            [
              -122.686119,
              41.281092
            ],
            [
              -122.663488,
              41.291644
            ],
            [
              -122.647978,
              41.302598
            ],
            [
              -122.647578,
              41.318062
            ],
            [
              -122.623932,
              41.32946
            ],
            [
              -122.610233,
              41.328839
            ],
            [
              -122.598758,
              41.325778
            ],
            [
              -122.58712,
              41.333281
            ],
            [
              -122.586055,
              41.347036
            ],
            [
              -122.582678,
              41.360096
            ],
            [
              -122.565749,
              41.365464
            ],
            [
              -122.55169,
              41.361702
            ],
            [
              -122.541004,
              41.345889
            ],
            [
              -122.511292,
              41.343728
            ],
            [
              -122.494636,
              41.328852
            ],
            [
              -122.487014,
              41.32447
            ],
            [
              -122.488281,
              41.31621
            ],
            [
              -122.494797,
              41.311951
            ],
            [
              -122.499355,
              41.302476
            ],
            [
              -122.498367,
              41.294571
            ],
            [
              -122.512499,
              41.277379
            ],
            [
              -122.509884,
              41.249871
            ],
            [
              -122.515063,
              41.232615
            ],
            [
              -122.515492,
              41.204992
            ],
            [
              -122.497504,
              41.186803
            ],
            [
              -122.498376,
              41.182675
            ],
            [
              -122.294573,
              41.184978
            ],
            [
              -122.147626,
              41.184554
            ],
            [
              -122.126114,
              41.182969
            ],
            [
              -121.446495,
              41.183484
            ],
            [
              -121.448699,
              41.429383
            ],
            [
              -121.448981,
              41.776392
            ],
            [
              -121.456922,
              41.776302
            ],
            [
              -121.457213,
              41.949945
            ],
            [
              -121.447563,
              41.950642
            ],
            [
              -121.44754015233201,
              41.9971688011849
            ],
            [
              -121.52025,
              41.997983
            ],
            [
              -121.580865,
              41.998668
            ],
            [
              -121.675348,
              42.000351
            ],
            [
              -121.689159,
              42.000584
            ],
            [
              -121.705045,
              42.000766
            ],
            [
              -121.708199,
              42.000815
            ],
            [
              -121.846712,
              42.00307
            ],
            [
              -122.000319,
              42.003967
            ],
            [
              -122.101922,
              42.005766
            ],
            [
              -122.155408,
              42.007429
            ],
            [
              -122.156666,
              42.007384
            ],
            [
              -122.160438,
              42.007637
            ],
            [
              -122.161328,
              42.007637
            ],
            [
              -122.261127,
              42.007364
            ],
            [
              -122.289527,
              42.007764
            ],
            [
              -122.289754518188,
              42.0077685007884
            ],
            [
              -122.378193,
              42.009518
            ],
            [
              -122.397984,
              42.008758
            ],
            [
              -122.501135,
              42.00846
            ],
            [
              -122.634739,
              42.004858
            ],
            [
              -122.712942,
              42.004157
            ],
            [
              -122.80008,
              42.004071
            ],
            [
              -122.876148,
              42.003247
            ],
            [
              -122.893961,
              42.002605
            ],
            [
              -122.941597,
              42.003085
            ],
            [
              -123.045254,
              42.003049
            ],
            [
              -123.065655,
              42.004948
            ],
            [
              -123.083956,
              42.005448
            ],
            [
              -123.145959,
              42.009247
            ],
            [
              -123.154908,
              42.008036
            ],
            [
              -123.192361,
              42.005446
            ],
            [
              -123.230762,
              42.003845
            ],
            [
              -123.23110845560201,
              42.00383094897101
            ],
            [
              -123.347562,
              41.999108
            ],
            [
              -123.381776,
              41.999268
            ],
            [
              -123.43477,
              42.001641
            ],
            [
              -123.49883,
              42.000525
            ],
            [
              -123.498896,
              42.000474
            ],
            [
              -123.501997,
              42.000527
            ],
            [
              -123.51790816171501,
              42.000882893156096
            ],
            [
              -123.519551,
              41.998819
            ],
            [
              -123.525279,
              41.992984
            ],
            [
              -123.534887,
              41.990495
            ],
            [
              -123.538066,
              41.982645
            ],
            [
              -123.53583,
              41.974643
            ],
            [
              -123.535101,
              41.967215
            ],
            [
              -123.533537,
              41.957245
            ],
            [
              -123.539444,
              41.948415
            ],
            [
              -123.545799,
              41.938963
            ],
            [
              -123.551964,
              41.931202
            ],
            [
              -123.554801,
              41.920753
            ],
            [
              -123.555839,
              41.912451
            ],
            [
              -123.562409,
              41.904921
            ],
            [
              -123.575174,
              41.903805
            ],
            [
              -123.589545,
              41.904904
            ],
            [
              -123.595795,
              41.901785
            ],
            [
              -123.597476,
              41.894656
            ],
            [
              -123.603723,
              41.888823
            ],
            [
              -123.604615,
              41.883226
            ],
            [
              -123.616136,
              41.881504
            ],
            [
              -123.622683,
              41.886135
            ],
            [
              -123.628173,
              41.888112
            ],
            [
              -123.637618,
              41.888292
            ],
            [
              -123.642908,
              41.887904
            ],
            [
              -123.647776,
              41.882363
            ],
            [
              -123.64991,
              41.878032
            ],
            [
              -123.650546,
              41.871685
            ],
            [
              -123.65099,
              41.861174
            ],
            [
              -123.662731,
              41.853142
            ],
            [
              -123.67102,
              41.84171
            ],
            [
              -123.678409,
              41.837053
            ],
            [
              -123.695995,
              41.833227
            ],
            [
              -123.702115,
              41.825044
            ],
            [
              -123.699165,
              41.816997
            ],
            [
              -123.693409,
              41.812615
            ],
            [
              -123.689399,
              41.802669
            ],
            [
              -123.679304,
              41.798882
            ],
            [
              -123.673581,
              41.796361
            ],
            [
              -123.676262,
              41.789489
            ],
            [
              -123.687244,
              41.782097
            ],
            [
              -123.682577,
              41.764761
            ],
            [
              -123.678655,
              41.74627
            ],
            [
              -123.661891,
              41.725988
            ],
            [
              -123.669408,
              41.703113
            ],
            [
              -123.674073,
              41.68448
            ],
            [
              -123.68971,
              41.667332
            ],
            [
              -123.685175,
              41.644811
            ],
            [
              -123.697461,
              41.627612
            ],
            [
              -123.704867,
              41.62191
            ],
            [
              -123.711313,
              41.613891
            ],
            [
              -123.714883,
              41.606924
            ],
            [
              -123.718797,
              41.599264
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "06",
      "COUNTYFP": "015",
      "COUNTYNS": "01682074",
      "AFFGEOID": "0500000US06015",
      "GEOID": "06015",
      "NAME": "Del Norte",
      "LSAD": "06",
      "ALAND": 2606117992,
      "AWATER": 578742645,
      "County_state": "Del Norte,06"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -124.255994,
              41.783014
            ],
            [
              -124.248704,
              41.771459
            ],
            [
              -124.242288,
              41.772034
            ],
            [
              -124.23972,
              41.7708
            ],
            [
              -124.203843,
              41.747035
            ],
            [
              -124.194953,
              41.736778
            ],
            [
              -124.19104,
              41.736079
            ],
            [
              -124.185363,
              41.739351
            ],
            [
              -124.17739,
              41.745756
            ],
            [
              -124.164716,
              41.740126
            ],
            [
              -124.154246,
              41.728801
            ],
            [
              -124.147412,
              41.717955
            ],
            [
              -124.143479,
              41.709284
            ],
            [
              -124.138373,
              41.678881
            ],
            [
              -124.139354,
              41.671652
            ],
            [
              -124.135552,
              41.657307
            ],
            [
              -124.120225,
              41.640354
            ],
            [
              -124.116037,
              41.628849
            ],
            [
              -124.114413,
              41.616768
            ],
            [
              -124.100961,
              41.602499
            ],
            [
              -124.097385,
              41.585251
            ],
            [
              -124.101403,
              41.578524
            ],
            [
              -124.101123,
              41.569192
            ],
            [
              -124.092404,
              41.553615
            ],
            [
              -124.081987,
              41.547761
            ],
            [
              -124.081427,
              41.511228
            ],
            [
              -124.075917,
              41.501757
            ],
            [
              -124.066057,
              41.470258
            ],
            [
              -124.065520778075,
              41.4647394651375
            ],
            [
              -123.885695,
              41.465844
            ],
            [
              -123.770551,
              41.464193
            ],
            [
              -123.770239,
              41.380776
            ],
            [
              -123.661363,
              41.38209
            ],
            [
              -123.658428,
              41.38624
            ],
            [
              -123.655409,
              41.388819
            ],
            [
              -123.646256,
              41.398154
            ],
            [
              -123.643016,
              41.41646
            ],
            [
              -123.639221,
              41.427041
            ],
            [
              -123.62107,
              41.435768
            ],
            [
              -123.6153,
              41.446447
            ],
            [
              -123.613757,
              41.455889
            ],
            [
              -123.617944,
              41.463748
            ],
            [
              -123.628495,
              41.470012
            ],
            [
              -123.635403,
              41.483696
            ],
            [
              -123.638204,
              41.495506
            ],
            [
              -123.639875,
              41.500912
            ],
            [
              -123.644114,
              41.509264
            ],
            [
              -123.646038,
              41.515789
            ],
            [
              -123.64523,
              41.519881
            ],
            [
              -123.644218,
              41.521723
            ],
            [
              -123.644365,
              41.524162
            ],
            [
              -123.645402,
              41.526283
            ],
            [
              -123.646826,
              41.528986
            ],
            [
              -123.646838,
              41.531422
            ],
            [
              -123.649176,
              41.536023
            ],
            [
              -123.653814,
              41.539249
            ],
            [
              -123.656417,
              41.538616
            ],
            [
              -123.66415,
              41.541162
            ],
            [
              -123.668227,
              41.54217
            ],
            [
              -123.669685,
              41.54368
            ],
            [
              -123.673293,
              41.543624
            ],
            [
              -123.682949,
              41.543205
            ],
            [
              -123.689813,
              41.550787
            ],
            [
              -123.693183,
              41.557515
            ],
            [
              -123.688524,
              41.563732
            ],
            [
              -123.683841,
              41.569632
            ],
            [
              -123.684988,
              41.576021
            ],
            [
              -123.687957,
              41.579841
            ],
            [
              -123.685707,
              41.585538
            ],
            [
              -123.683187,
              41.586075
            ],
            [
              -123.683044,
              41.590491
            ],
            [
              -123.683476,
              41.592217
            ],
            [
              -123.690037,
              41.593382
            ],
            [
              -123.698408,
              41.592147
            ],
            [
              -123.702327,
              41.595232
            ],
            [
              -123.709132,
              41.594292
            ],
            [
              -123.712382,
              41.592774
            ],
            [
              -123.718331,
              41.594652
            ],
            [
              -123.718797,
              41.599264
            ],
            [
              -123.714883,
              41.606924
            ],
            [
              -123.711313,
              41.613891
            ],
            [
              -123.704867,
              41.62191
            ],
            [
              -123.697461,
              41.627612
            ],
            [
              -123.685175,
              41.644811
            ],
            [
              -123.68971,
              41.667332
            ],
            [
              -123.674073,
              41.68448
            ],
            [
              -123.669408,
              41.703113
            ],
            [
              -123.661891,
              41.725988
            ],
            [
              -123.678655,
              41.74627
            ],
            [
              -123.682577,
              41.764761
            ],
            [
              -123.687244,
              41.782097
            ],
            [
              -123.676262,
              41.789489
            ],
            [
              -123.673581,
              41.796361
            ],
            [
              -123.679304,
              41.798882
            ],
            [
              -123.689399,
              41.802669
            ],
            [
              -123.693409,
              41.812615
            ],
            [
              -123.699165,
              41.816997
            ],
            [
              -123.702115,
              41.825044
            ],
            [
              -123.695995,
              41.833227
            ],
            [
              -123.678409,
              41.837053
            ],
            [
              -123.67102,
              41.84171
            ],
            [
              -123.662731,
              41.853142
            ],
            [
              -123.65099,
              41.861174
            ],
            [
              -123.650546,
              41.871685
            ],
            [
              -123.64991,
              41.878032
            ],
            [
              -123.647776,
              41.882363
            ],
            [
              -123.642908,
              41.887904
            ],
            [
              -123.637618,
              41.888292
            ],
            [
              -123.628173,
              41.888112
            ],
            [
              -123.622683,
              41.886135
            ],
            [
              -123.616136,
              41.881504
            ],
            [
              -123.604615,
              41.883226
            ],
            [
              -123.603723,
              41.888823
            ],
            [
              -123.597476,
              41.894656
            ],
            [
              -123.595795,
              41.901785
            ],
            [
              -123.589545,
              41.904904
            ],
            [
              -123.575174,
              41.903805
            ],
            [
              -123.562409,
              41.904921
            ],
            [
              -123.555839,
              41.912451
            ],
            [
              -123.554801,
              41.920753
            ],
            [
              -123.551964,
              41.931202
            ],
            [
              -123.545799,
              41.938963
            ],
            [
              -123.539444,
              41.948415
            ],
            [
              -123.533537,
              41.957245
            ],
            [
              -123.535101,
              41.967215
            ],
            [
              -123.53583,
              41.974643
            ],
            [
              -123.538066,
              41.982645
            ],
            [
              -123.534887,
              41.990495
            ],
            [
              -123.525279,
              41.992984
            ],
            [
              -123.519551,
              41.998819
            ],
            [
              -123.51790816171501,
              42.000882893156096
            ],
            [
              -123.525245,
              42.001047
            ],
            [
              -123.55256,
              42.000246
            ],
            [
              -123.624554,
              41.999837
            ],
            [
              -123.656998,
              41.995137
            ],
            [
              -123.721429255526,
              41.996830224202895
            ],
            [
              -123.728156,
              41.997007
            ],
            [
              -123.789295,
              41.996111
            ],
            [
              -123.813992,
              41.995096
            ],
            [
              -123.821472,
              41.995473
            ],
            [
              -123.822044941707,
              41.995501925998596
            ],
            [
              -123.834208,
              41.996116
            ],
            [
              -124.001188,
              41.996146
            ],
            [
              -124.086661,
              41.996869
            ],
            [
              -124.087827,
              41.996891
            ],
            [
              -124.100216,
              41.996842
            ],
            [
              -124.100921,
              41.996956
            ],
            [
              -124.126194,
              41.996992
            ],
            [
              -124.211605,
              41.99846
            ],
            [
              -124.204948,
              41.983441
            ],
            [
              -124.203402,
              41.940964
            ],
            [
              -124.208439,
              41.888192
            ],
            [
              -124.219592,
              41.846432
            ],
            [
              -124.230678,
              41.818681
            ],
            [
              -124.245027,
              41.7923
            ],
            [
              -124.255994,
              41.783014
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "06",
      "COUNTYFP": "027",
      "COUNTYNS": "01804637",
      "AFFGEOID": "0500000US06027",
      "GEOID": "06027",
      "NAME": "Inyo",
      "LSAD": "06",
      "ALAND": 26368508430,
      "AWATER": 119088140,
      "County_state": "Inyo,06"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -118.781811,
              37.408513
            ],
            [
              -118.784563,
              37.386101
            ],
            [
              -118.773049,
              37.373178
            ],
            [
              -118.769866,
              37.364136
            ],
            [
              -118.784501,
              37.35168
            ],
            [
              -118.780401,
              37.335814
            ],
            [
              -118.760668,
              37.329347
            ],
            [
              -118.745348,
              37.319781
            ],
            [
              -118.728754,
              37.323002
            ],
            [
              -118.706227,
              37.314071
            ],
            [
              -118.693543,
              37.300367
            ],
            [
              -118.684267,
              37.28569
            ],
            [
              -118.674577,
              37.270708
            ],
            [
              -118.668999,
              37.257109
            ],
            [
              -118.680322,
              37.246409
            ],
            [
              -118.685102,
              37.2298
            ],
            [
              -118.678776,
              37.215327
            ],
            [
              -118.680121,
              37.203442
            ],
            [
              -118.671277,
              37.192981
            ],
            [
              -118.664529,
              37.17774
            ],
            [
              -118.669082,
              37.16338
            ],
            [
              -118.661818,
              37.150859
            ],
            [
              -118.645508,
              37.139516
            ],
            [
              -118.628539,
              37.137766
            ],
            [
              -118.617698,
              37.141625
            ],
            [
              -118.608175,
              37.140259
            ],
            [
              -118.598823,
              37.136622
            ],
            [
              -118.594594,
              37.137539
            ],
            [
              -118.589865,
              37.133282
            ],
            [
              -118.588167,
              37.12988
            ],
            [
              -118.57999,
              37.122592
            ],
            [
              -118.566445,
              37.114453
            ],
            [
              -118.544648,
              37.114733
            ],
            [
              -118.531771,
              37.110075
            ],
            [
              -118.522127,
              37.098354
            ],
            [
              -118.502186,
              37.093477
            ],
            [
              -118.485364,
              37.076842
            ],
            [
              -118.455082,
              37.067468
            ],
            [
              -118.437774,
              37.054512
            ],
            [
              -118.437378,
              37.037483
            ],
            [
              -118.423435,
              37.02248
            ],
            [
              -118.421111,
              37.007563
            ],
            [
              -118.419733,
              36.991735
            ],
            [
              -118.403433,
              36.965535
            ],
            [
              -118.386132,
              36.941134
            ],
            [
              -118.375431,
              36.916733
            ],
            [
              -118.366631,
              36.895034
            ],
            [
              -118.368131,
              36.876834
            ],
            [
              -118.366514,
              36.866128
            ],
            [
              -118.362901,
              36.855419
            ],
            [
              -118.366484,
              36.839991
            ],
            [
              -118.382271,
              36.834527
            ],
            [
              -118.391321,
              36.833207
            ],
            [
              -118.391128,
              36.820968
            ],
            [
              -118.384466,
              36.812708
            ],
            [
              -118.377255,
              36.803314
            ],
            [
              -118.373808,
              36.797456
            ],
            [
              -118.37593,
              36.788284
            ],
            [
              -118.37603,
              36.778534
            ],
            [
              -118.37363,
              36.765234
            ],
            [
              -118.36973,
              36.753834
            ],
            [
              -118.36653,
              36.747834
            ],
            [
              -118.361829,
              36.747134
            ],
            [
              -118.360586,
              36.744773
            ],
            [
              -118.339629,
              36.729034
            ],
            [
              -118.334728,
              36.705534
            ],
            [
              -118.366253,
              36.69171
            ],
            [
              -118.365628,
              36.689835
            ],
            [
              -118.352028,
              36.682934
            ],
            [
              -118.341922,
              36.672605
            ],
            [
              -118.333727,
              36.662134
            ],
            [
              -118.337627,
              36.657834
            ],
            [
              -118.333527,
              36.651834
            ],
            [
              -118.328126,
              36.648034
            ],
            [
              -118.320226,
              36.639734
            ],
            [
              -118.321026,
              36.628034
            ],
            [
              -118.308625,
              36.620934
            ],
            [
              -118.298825,
              36.611634
            ],
            [
              -118.287525,
              36.603334
            ],
            [
              -118.279224,
              36.601534
            ],
            [
              -118.281724,
              36.591334
            ],
            [
              -118.289224,
              36.588034
            ],
            [
              -118.292524,
              36.579035
            ],
            [
              -118.290224,
              36.572435
            ],
            [
              -118.292624,
              36.566835
            ],
            [
              -118.291223,
              36.559335
            ],
            [
              -118.276123,
              36.551835
            ],
            [
              -118.262822,
              36.548935
            ],
            [
              -118.251922,
              36.540135
            ],
            [
              -118.249022,
              36.533735
            ],
            [
              -118.250922,
              36.528335
            ],
            [
              -118.247721,
              36.523135
            ],
            [
              -118.244021,
              36.522035
            ],
            [
              -118.239021,
              36.518335
            ],
            [
              -118.239021,
              36.513035
            ],
            [
              -118.239121,
              36.504035
            ],
            [
              -118.23672,
              36.495635
            ],
            [
              -118.24482,
              36.489035
            ],
            [
              -118.24512,
              36.479835
            ],
            [
              -118.23592,
              36.472835
            ],
            [
              -118.22502,
              36.463935
            ],
            [
              -118.215162,
              36.453851
            ],
            [
              -118.20962,
              36.442136
            ],
            [
              -118.21212,
              36.437036
            ],
            [
              -118.21182,
              36.432236
            ],
            [
              -118.203719,
              36.431236
            ],
            [
              -118.188019,
              36.427436
            ],
            [
              -118.174819,
              36.426736
            ],
            [
              -118.166719,
              36.429636
            ],
            [
              -118.155619,
              36.429436
            ],
            [
              -118.151919,
              36.425536
            ],
            [
              -118.143319,
              36.421236
            ],
            [
              -118.139019,
              36.410836
            ],
            [
              -118.142619,
              36.401636
            ],
            [
              -118.151419,
              36.397336
            ],
            [
              -118.157119,
              36.388336
            ],
            [
              -118.154118,
              36.382536
            ],
            [
              -118.142503,
              36.374936
            ],
            [
              -118.129918,
              36.365837
            ],
            [
              -118.123218,
              36.353337
            ],
            [
              -118.110718,
              36.348937
            ],
            [
              -118.101817,
              36.339337
            ],
            [
              -118.099817,
              36.333437
            ],
            [
              -118.104455,
              36.325669
            ],
            [
              -118.112517,
              36.322037
            ],
            [
              -118.114577,
              36.306984
            ],
            [
              -118.125917,
              36.301063
            ],
            [
              -118.125361,
              36.290503
            ],
            [
              -118.127485,
              36.282329
            ],
            [
              -118.119739,
              36.273087
            ],
            [
              -118.118004,
              36.2528
            ],
            [
              -118.112916,
              36.241539
            ],
            [
              -118.105516,
              36.231239
            ],
            [
              -118.107416,
              36.222139
            ],
            [
              -118.101916,
              36.208839
            ],
            [
              -118.092615,
              36.20184
            ],
            [
              -118.083115,
              36.19554
            ],
            [
              -118.073715,
              36.18944
            ],
            [
              -118.067115,
              36.17714
            ],
            [
              -118.060515,
              36.16844
            ],
            [
              -118.059314,
              36.158741
            ],
            [
              -118.065214,
              36.147741
            ],
            [
              -118.073514,
              36.138441
            ],
            [
              -118.070914,
              36.128641
            ],
            [
              -118.065614,
              36.112842
            ],
            [
              -118.066913,
              36.097742
            ],
            [
              -118.057413,
              36.090942
            ],
            [
              -118.050613,
              36.079542
            ],
            [
              -118.051113,
              36.068543
            ],
            [
              -118.048012,
              36.056143
            ],
            [
              -118.043681,
              36.049612
            ],
            [
              -118.045912,
              36.043643
            ],
            [
              -118.040012,
              36.034643
            ],
            [
              -118.039012,
              36.029844
            ],
            [
              -118.036012,
              36.019544
            ],
            [
              -118.033611,
              36.008944
            ],
            [
              -118.020611,
              36.004044
            ],
            [
              -118.006854,
              35.989901
            ],
            [
              -118.005298,
              35.980508
            ],
            [
              -118.008004,
              35.977851
            ],
            [
              -118.010095,
              35.977617
            ],
            [
              -118.01824,
              35.960977
            ],
            [
              -118.006882,
              35.946483
            ],
            [
              -117.991944,
              35.943771
            ],
            [
              -117.983287,
              35.926563
            ],
            [
              -117.990046,
              35.915919
            ],
            [
              -117.982473,
              35.895796
            ],
            [
              -117.988802,
              35.879769
            ],
            [
              -117.980761,
              35.867515
            ],
            [
              -118.005489,
              35.862699
            ],
            [
              -118.008043,
              35.789161
            ],
            [
              -117.92312,
              35.786812
            ],
            [
              -117.924459,
              35.798149
            ],
            [
              -117.632996,
              35.797251
            ],
            [
              -116.651095,
              35.795433
            ],
            [
              -115.735891,
              35.79362
            ],
            [
              -115.735745,
              35.809097
            ],
            [
              -115.653794,
              35.809214
            ],
            [
              -115.64802031181802,
              35.8096294219517
            ],
            [
              -115.669005,
              35.826515
            ],
            [
              -115.689302,
              35.842003
            ],
            [
              -115.750844,
              35.889287
            ],
            [
              -115.845983929593,
              35.9642074573715
            ],
            [
              -115.852908,
              35.96966
            ],
            [
              -115.892975,
              35.999967
            ],
            [
              -115.912858,
              36.015359
            ],
            [
              -116.093601,
              36.155805
            ],
            [
              -116.097216,
              36.158346
            ],
            [
              -116.250869,
              36.276979
            ],
            [
              -116.375875,
              36.372562
            ],
            [
              -116.38034,
              36.374955
            ],
            [
              -116.488233,
              36.459097
            ],
            [
              -116.500882,
              36.468223
            ],
            [
              -116.541983,
              36.499952
            ],
            [
              -117.000895,
              36.847694
            ],
            [
              -117.066728,
              36.896354
            ],
            [
              -117.131975,
              36.945777
            ],
            [
              -117.166000489853,
              36.9712239643835
            ],
            [
              -117.244917,
              37.030244
            ],
            [
              -117.266046,
              37.04491
            ],
            [
              -117.375905,
              37.126843
            ],
            [
              -117.500117,
              37.22038
            ],
            [
              -117.500909,
              37.220282
            ],
            [
              -117.540885,
              37.249931
            ],
            [
              -117.581418,
              37.278936
            ],
            [
              -117.68061,
              37.353399
            ],
            [
              -117.712358,
              37.374931
            ],
            [
              -117.832726,
              37.464929
            ],
            [
              -118.775014,
              37.463052
            ],
            [
              -118.770876,
              37.458176
            ],
            [
              -118.762383,
              37.450869
            ],
            [
              -118.759581,
              37.435573
            ],
            [
              -118.772371,
              37.423559
            ],
            [
              -118.781811,
              37.408513
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "06",
      "COUNTYFP": "077",
      "COUNTYNS": "00277303",
      "AFFGEOID": "0500000US06077",
      "GEOID": "06077",
      "NAME": "San Joaquin",
      "LSAD": "06",
      "ALAND": 3606190671,
      "AWATER": 89046837,
      "County_state": "San Joaquin,06"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -121.582203,
              38.068423
            ],
            [
              -121.584388,
              38.036025
            ],
            [
              -121.572169,
              38.041412
            ],
            [
              -121.557554,
              38.017037
            ],
            [
              -121.572789,
              38.01691
            ],
            [
              -121.578711,
              38.004255
            ],
            [
              -121.569612,
              38.00316
            ],
            [
              -121.582463,
              37.983922
            ],
            [
              -121.565138,
              37.959849
            ],
            [
              -121.568515,
              37.949171
            ],
            [
              -121.557274,
              37.932247
            ],
            [
              -121.572824,
              37.904301
            ],
            [
              -121.579503,
              37.861072
            ],
            [
              -121.553123,
              37.860963
            ],
            [
              -121.534247,
              37.849891
            ],
            [
              -121.556997,
              37.816488
            ],
            [
              -121.556959,
              37.743051
            ],
            [
              -121.556655,
              37.542732
            ],
            [
              -121.541833,
              37.530215
            ],
            [
              -121.50442,
              37.525863
            ],
            [
              -121.493183,
              37.502421
            ],
            [
              -121.469275,
              37.489688
            ],
            [
              -121.471925,
              37.481783
            ],
            [
              -121.269447,
              37.641628
            ],
            [
              -121.241219,
              37.664009
            ],
            [
              -121.21847,
              37.674032
            ],
            [
              -121.216391,
              37.68787
            ],
            [
              -121.194228,
              37.694953
            ],
            [
              -121.178951,
              37.691083
            ],
            [
              -121.176299,
              37.704047
            ],
            [
              -121.15922,
              37.704439
            ],
            [
              -121.154562,
              37.7202
            ],
            [
              -121.146106,
              37.717499
            ],
            [
              -121.110248,
              37.730128
            ],
            [
              -121.110692,
              37.741776
            ],
            [
              -121.092734,
              37.73475
            ],
            [
              -121.05674,
              37.750514
            ],
            [
              -121.028816,
              37.740704
            ],
            [
              -120.995696,
              37.760178
            ],
            [
              -120.954032,
              37.738352
            ],
            [
              -120.920993,
              37.737947
            ],
            [
              -120.922643,
              37.759568
            ],
            [
              -120.926449,
              38.077421
            ],
            [
              -120.93885,
              38.088321
            ],
            [
              -120.995497,
              38.225402
            ],
            [
              -121.027084,
              38.300252
            ],
            [
              -121.046751,
              38.291426
            ],
            [
              -121.064783,
              38.29947
            ],
            [
              -121.09544,
              38.281633
            ],
            [
              -121.127782,
              38.277447
            ],
            [
              -121.147014,
              38.263905
            ],
            [
              -121.173079,
              38.255208
            ],
            [
              -121.194137,
              38.255749
            ],
            [
              -121.223537,
              38.244463
            ],
            [
              -121.247584,
              38.245426
            ],
            [
              -121.269228,
              38.252089
            ],
            [
              -121.272822,
              38.250782
            ],
            [
              -121.282267,
              38.249836
            ],
            [
              -121.293719,
              38.238387
            ],
            [
              -121.344024,
              38.22826
            ],
            [
              -121.411005,
              38.231934
            ],
            [
              -121.429097,
              38.254673
            ],
            [
              -121.439911,
              38.255531
            ],
            [
              -121.448142,
              38.255008
            ],
            [
              -121.4614,
              38.259157
            ],
            [
              -121.48007,
              38.253576
            ],
            [
              -121.491669,
              38.22769
            ],
            [
              -121.507487,
              38.222563
            ],
            [
              -121.504303,
              38.212579
            ],
            [
              -121.529152,
              38.193828
            ],
            [
              -121.524614,
              38.174844
            ],
            [
              -121.536023,
              38.152014
            ],
            [
              -121.558216,
              38.144302
            ],
            [
              -121.558088,
              38.136495
            ],
            [
              -121.584074,
              38.120228
            ],
            [
              -121.565751,
              38.105279
            ],
            [
              -121.580022,
              38.094414
            ],
            [
              -121.572923,
              38.074239
            ],
            [
              -121.582203,
              38.068423
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "06",
      "COUNTYFP": "025",
      "COUNTYNS": "00277277",
      "AFFGEOID": "0500000US06025",
      "GEOID": "06025",
      "NAME": "Imperial",
      "LSAD": "06",
      "ALAND": 10817291631,
      "AWATER": 790230304,
      "County_state": "Imperial,06"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -116.105634,
              32.72157
            ],
            [
              -116.10618141881301,
              32.6186413746915
            ],
            [
              -116.04662,
              32.623353
            ],
            [
              -115.465164,
              32.6671
            ],
            [
              -115.000802,
              32.699676
            ],
            [
              -114.719633,
              32.718763
            ],
            [
              -114.717665,
              32.721654
            ],
            [
              -114.715788,
              32.727758
            ],
            [
              -114.714522,
              32.73039
            ],
            [
              -114.705717,
              32.741581
            ],
            [
              -114.701918,
              32.745548
            ],
            [
              -114.700743,
              32.745617
            ],
            [
              -114.69879,
              32.744846
            ],
            [
              -114.688779,
              32.737675
            ],
            [
              -114.684278,
              32.737537
            ],
            [
              -114.678632,
              32.736614
            ],
            [
              -114.677091,
              32.736218
            ],
            [
              -114.667493,
              32.734226
            ],
            [
              -114.65884,
              32.73383
            ],
            [
              -114.65826,
              32.733799
            ],
            [
              -114.632686,
              32.730846
            ],
            [
              -114.618373,
              32.728245
            ],
            [
              -114.615669227736,
              32.7284399276274
            ],
            [
              -114.615585,
              32.728446
            ],
            [
              -114.615733,
              32.729427
            ],
            [
              -114.614772,
              32.734089
            ],
            [
              -114.539093,
              32.756949
            ],
            [
              -114.526856,
              32.757094
            ],
            [
              -114.528443,
              32.767276
            ],
            [
              -114.531831,
              32.774264
            ],
            [
              -114.532432,
              32.776923
            ],
            [
              -114.531746,
              32.782503
            ],
            [
              -114.531669,
              32.791185
            ],
            [
              -114.530755,
              32.793485
            ],
            [
              -114.528849,
              32.796307
            ],
            [
              -114.522031,
              32.801675
            ],
            [
              -114.520385,
              32.803577
            ],
            [
              -114.519758,
              32.805676
            ],
            [
              -114.515389,
              32.811439
            ],
            [
              -114.510217,
              32.816417
            ],
            [
              -114.496827,
              32.822119
            ],
            [
              -114.496284,
              32.822326
            ],
            [
              -114.494116,
              32.823288
            ],
            [
              -114.475892,
              32.838694
            ],
            [
              -114.468971,
              32.845155
            ],
            [
              -114.465546,
              32.874809
            ],
            [
              -114.465715,
              32.879191
            ],
            [
              -114.465715,
              32.87942
            ],
            [
              -114.463127,
              32.901884
            ],
            [
              -114.462929,
              32.907944
            ],
            [
              -114.46365,
              32.911682
            ],
            [
              -114.464448,
              32.913129
            ],
            [
              -114.473713,
              32.920594
            ],
            [
              -114.47664,
              32.923628
            ],
            [
              -114.479005,
              32.928291
            ],
            [
              -114.48092,
              32.935252
            ],
            [
              -114.48074,
              32.937027
            ],
            [
              -114.478456,
              32.940555
            ],
            [
              -114.474042,
              32.94515
            ],
            [
              -114.470833,
              32.949333
            ],
            [
              -114.469113,
              32.952673
            ],
            [
              -114.46773,
              32.956323
            ],
            [
              -114.467272,
              32.960675
            ],
            [
              -114.467664,
              32.966861
            ],
            [
              -114.469039,
              32.972295
            ],
            [
              -114.470988,
              32.97406
            ],
            [
              -114.476156,
              32.975168
            ],
            [
              -114.480417,
              32.973665
            ],
            [
              -114.481315,
              32.972064
            ],
            [
              -114.488625,
              32.969946
            ],
            [
              -114.490129,
              32.969885
            ],
            [
              -114.492938,
              32.971781
            ],
            [
              -114.494212,
              32.974262
            ],
            [
              -114.495712,
              32.980076
            ],
            [
              -114.497315,
              32.991474
            ],
            [
              -114.499797,
              33.003905
            ],
            [
              -114.502871,
              33.011153
            ],
            [
              -114.50613,
              33.01701
            ],
            [
              -114.511343,
              33.023455
            ],
            [
              -114.5149,
              33.026524
            ],
            [
              -114.51648387559901,
              33.0275718412186
            ],
            [
              -114.52013,
              33.029984
            ],
            [
              -114.523578,
              33.030961
            ],
            [
              -114.538459,
              33.033422
            ],
            [
              -114.553189,
              33.033974
            ],
            [
              -114.571653,
              33.036624
            ],
            [
              -114.575161,
              33.036542
            ],
            [
              -114.578287,
              33.035375
            ],
            [
              -114.581404,
              33.032545
            ],
            [
              -114.584765,
              33.028231
            ],
            [
              -114.586982,
              33.026945
            ],
            [
              -114.589778,
              33.026228
            ],
            [
              -114.601014,
              33.02541
            ],
            [
              -114.618788,
              33.027202
            ],
            [
              -114.625787,
              33.029436
            ],
            [
              -114.628293,
              33.031052
            ],
            [
              -114.63419,
              33.039025
            ],
            [
              -114.639553,
              33.045291
            ],
            [
              -114.641622,
              33.046896
            ],
            [
              -114.64598,
              33.048903
            ],
            [
              -114.649001,
              33.046763
            ],
            [
              -114.655038,
              33.037107
            ],
            [
              -114.657827,
              33.033825
            ],
            [
              -114.659832,
              33.032665
            ],
            [
              -114.662317,
              33.032671
            ],
            [
              -114.66506,
              33.033908
            ],
            [
              -114.670803,
              33.037984
            ],
            [
              -114.673659,
              33.041897
            ],
            [
              -114.675104,
              33.047532
            ],
            [
              -114.674296,
              33.057171
            ],
            [
              -114.686991,
              33.070969
            ],
            [
              -114.68912,
              33.076122
            ],
            [
              -114.689307,
              33.079179
            ],
            [
              -114.688597,
              33.082869
            ],
            [
              -114.68902,
              33.084036
            ],
            [
              -114.692548,
              33.085786
            ],
            [
              -114.694628,
              33.086226
            ],
            [
              -114.701165,
              33.086368
            ],
            [
              -114.70473,
              33.087051
            ],
            [
              -114.706488,
              33.08816
            ],
            [
              -114.707819,
              33.091102
            ],
            [
              -114.707896,
              33.097432
            ],
            [
              -114.706175,
              33.105335
            ],
            [
              -114.703682,
              33.113769
            ],
            [
              -114.696829,
              33.131209
            ],
            [
              -114.689995,
              33.137883
            ],
            [
              -114.687074,
              33.142196
            ],
            [
              -114.684489,
              33.148121
            ],
            [
              -114.682253,
              33.155214
            ],
            [
              -114.679359,
              33.159519
            ],
            [
              -114.678729,
              33.162948
            ],
            [
              -114.680248,
              33.169717
            ],
            [
              -114.679034,
              33.174738
            ],
            [
              -114.675831,
              33.18152
            ],
            [
              -114.67536,
              33.185489
            ],
            [
              -114.67519,
              33.188179
            ],
            [
              -114.678178,
              33.199584
            ],
            [
              -114.678749,
              33.203448
            ],
            [
              -114.676072,
              33.210835
            ],
            [
              -114.673715,
              33.219245
            ],
            [
              -114.673626,
              33.223121
            ],
            [
              -114.674479,
              33.225504
            ],
            [
              -114.678097,
              33.2303
            ],
            [
              -114.682731,
              33.234918
            ],
            [
              -114.689421,
              33.24525
            ],
            [
              -114.689541,
              33.246428
            ],
            [
              -114.688205,
              33.247966
            ],
            [
              -114.674491,
              33.255597
            ],
            [
              -114.672088,
              33.258499
            ],
            [
              -114.672401,
              33.26047
            ],
            [
              -114.677032,
              33.27017
            ],
            [
              -114.680507,
              33.273577
            ],
            [
              -114.684363,
              33.276025
            ],
            [
              -114.694449,
              33.279786
            ],
            [
              -114.702873,
              33.281916
            ],
            [
              -114.711197,
              33.283342
            ],
            [
              -114.717875,
              33.285157
            ],
            [
              -114.72167,
              33.286982
            ],
            [
              -114.723259,
              33.288079
            ],
            [
              -114.731223,
              33.302434
            ],
            [
              -114.731222,
              33.304039
            ],
            [
              -114.729904,
              33.305745
            ],
            [
              -114.723623,
              33.31211
            ],
            [
              -114.710792,
              33.320607
            ],
            [
              -114.707962,
              33.323421
            ],
            [
              -114.705241,
              33.327767
            ],
            [
              -114.700938,
              33.337014
            ],
            [
              -114.698035,
              33.352442
            ],
            [
              -114.69817,
              33.356575
            ],
            [
              -114.699053,
              33.361148
            ],
            [
              -114.707348,
              33.376628
            ],
            [
              -114.707009,
              33.380634
            ],
            [
              -114.70731,
              33.382542
            ],
            [
              -114.708408,
              33.384147
            ],
            [
              -114.713602,
              33.388257
            ],
            [
              -114.722872,
              33.398779
            ],
            [
              -114.725292,
              33.402342
            ],
            [
              -114.725535,
              33.404056
            ],
            [
              -114.725282,
              33.405048
            ],
            [
              -114.723829,
              33.406531
            ],
            [
              -114.720065,
              33.407891
            ],
            [
              -114.710878,
              33.407254
            ],
            [
              -114.701732,
              33.408388
            ],
            [
              -114.697707,
              33.410942
            ],
            [
              -114.696805,
              33.412087
            ],
            [
              -114.696504,
              33.414059
            ],
            [
              -114.695655,
              33.415127
            ],
            [
              -114.687953,
              33.417944
            ],
            [
              -114.673901,
              33.418299
            ],
            [
              -114.658382,
              33.413036
            ],
            [
              -114.652828,
              33.412922
            ],
            [
              -114.64954,
              33.413633
            ],
            [
              -114.643302,
              33.416745
            ],
            [
              -114.635183,
              33.422726
            ],
            [
              -114.62964,
              33.428138
            ],
            [
              -114.627125,
              33.433554
            ],
            [
              -114.828335,
              33.433709
            ],
            [
              -115.442045,
              33.428322
            ],
            [
              -115.468368,
              33.426986
            ],
            [
              -116.085165,
              33.425932
            ],
            [
              -116.08109,
              33.074833
            ],
            [
              -116.103252,
              33.07467
            ],
            [
              -116.103714,
              32.803871
            ],
            [
              -116.103149,
              32.7265
            ],
            [
              -116.105634,
              32.72157
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "06",
      "COUNTYFP": "009",
      "COUNTYNS": "01675885",
      "AFFGEOID": "0500000US06009",
      "GEOID": "06009",
      "NAME": "Calaveras",
      "LSAD": "06",
      "ALAND": 2641829199,
      "AWATER": 43797662,
      "County_state": "Calaveras,06"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -120.992341,
              38.225254
            ],
            [
              -120.995497,
              38.225402
            ],
            [
              -120.93885,
              38.088321
            ],
            [
              -120.926449,
              38.077421
            ],
            [
              -120.876047,
              38.028894
            ],
            [
              -120.832781,
              37.99384
            ],
            [
              -120.752695,
              37.923334
            ],
            [
              -120.74453,
              37.912419
            ],
            [
              -120.653274,
              37.831858
            ],
            [
              -120.652551,
              37.833463
            ],
            [
              -120.644072,
              37.840052
            ],
            [
              -120.642039,
              37.845693
            ],
            [
              -120.637936,
              37.850559
            ],
            [
              -120.635686,
              37.856043
            ],
            [
              -120.631885,
              37.86128
            ],
            [
              -120.623508,
              37.866601
            ],
            [
              -120.612415,
              37.871627
            ],
            [
              -120.607672,
              37.872897
            ],
            [
              -120.604649,
              37.874058
            ],
            [
              -120.605154,
              37.874981
            ],
            [
              -120.604102,
              37.8767
            ],
            [
              -120.600983,
              37.879007
            ],
            [
              -120.59656,
              37.883851
            ],
            [
              -120.596681,
              37.888144
            ],
            [
              -120.588564,
              37.893537
            ],
            [
              -120.581286,
              37.896019
            ],
            [
              -120.574532,
              37.894374
            ],
            [
              -120.568158,
              37.897559
            ],
            [
              -120.566028,
              37.901762
            ],
            [
              -120.563302,
              37.90634
            ],
            [
              -120.562877,
              37.912855
            ],
            [
              -120.556073,
              37.916157
            ],
            [
              -120.551897,
              37.921131
            ],
            [
              -120.54642,
              37.921136
            ],
            [
              -120.544474,
              37.920101
            ],
            [
              -120.541081,
              37.922505
            ],
            [
              -120.539016,
              37.925383
            ],
            [
              -120.53712,
              37.929422
            ],
            [
              -120.539448,
              37.933256
            ],
            [
              -120.538646,
              37.938181
            ],
            [
              -120.536604,
              37.941385
            ],
            [
              -120.534261,
              37.944939
            ],
            [
              -120.528248,
              37.946822
            ],
            [
              -120.518278,
              37.951265
            ],
            [
              -120.514585,
              37.96081
            ],
            [
              -120.519787,
              37.966294
            ],
            [
              -120.527925,
              37.972105
            ],
            [
              -120.528619,
              37.97599
            ],
            [
              -120.533971,
              37.975477
            ],
            [
              -120.537589,
              37.974646
            ],
            [
              -120.539867,
              37.974348
            ],
            [
              -120.542076,
              37.977883
            ],
            [
              -120.536355,
              37.980173
            ],
            [
              -120.531817,
              37.980672
            ],
            [
              -120.528511,
              37.984899
            ],
            [
              -120.532386,
              37.988092
            ],
            [
              -120.532959,
              37.992118
            ],
            [
              -120.527828,
              37.993023
            ],
            [
              -120.521653,
              37.99516
            ],
            [
              -120.519447,
              38.001036
            ],
            [
              -120.516681,
              38.003886
            ],
            [
              -120.511091,
              38.002585
            ],
            [
              -120.509285,
              37.999928
            ],
            [
              -120.511315,
              37.99744
            ],
            [
              -120.512839,
              37.997067
            ],
            [
              -120.511257,
              37.993875
            ],
            [
              -120.509963,
              37.991955
            ],
            [
              -120.503767,
              37.991681
            ],
            [
              -120.500575,
              37.99466
            ],
            [
              -120.500137,
              38.007492
            ],
            [
              -120.49225,
              38.011183
            ],
            [
              -120.483679,
              38.012106
            ],
            [
              -120.47195,
              38.011296
            ],
            [
              -120.464729,
              38.013554
            ],
            [
              -120.464533,
              38.021092
            ],
            [
              -120.456623,
              38.033864
            ],
            [
              -120.453623,
              38.041804
            ],
            [
              -120.44424,
              38.05077
            ],
            [
              -120.443394,
              38.054668
            ],
            [
              -120.441156,
              38.059922
            ],
            [
              -120.435788,
              38.060026
            ],
            [
              -120.431103,
              38.065084
            ],
            [
              -120.429258,
              38.073481
            ],
            [
              -120.423818,
              38.07632
            ],
            [
              -120.41463,
              38.081911
            ],
            [
              -120.410367,
              38.093094
            ],
            [
              -120.405758,
              38.105627
            ],
            [
              -120.390253,
              38.113546
            ],
            [
              -120.382519,
              38.127574
            ],
            [
              -120.378666,
              38.134826
            ],
            [
              -120.373568,
              38.136312
            ],
            [
              -120.372351,
              38.141139
            ],
            [
              -120.375509,
              38.141712
            ],
            [
              -120.372641,
              38.14663
            ],
            [
              -120.367569,
              38.152806
            ],
            [
              -120.358123,
              38.158386
            ],
            [
              -120.351656,
              38.16765
            ],
            [
              -120.340816,
              38.174349
            ],
            [
              -120.33745,
              38.180501
            ],
            [
              -120.335707,
              38.186815
            ],
            [
              -120.331339,
              38.192164
            ],
            [
              -120.325871,
              38.198065
            ],
            [
              -120.317222,
              38.208812
            ],
            [
              -120.308191,
              38.222074
            ],
            [
              -120.292184,
              38.23338
            ],
            [
              -120.290388,
              38.242832
            ],
            [
              -120.284222,
              38.251595
            ],
            [
              -120.273564,
              38.259021
            ],
            [
              -120.271899,
              38.267643
            ],
            [
              -120.262215,
              38.27628
            ],
            [
              -120.256374,
              38.285747
            ],
            [
              -120.24538,
              38.291573
            ],
            [
              -120.237089,
              38.298466
            ],
            [
              -120.234341,
              38.304965
            ],
            [
              -120.22962,
              38.312769
            ],
            [
              -120.224864,
              38.31744
            ],
            [
              -120.217689,
              38.321133
            ],
            [
              -120.208789,
              38.326808
            ],
            [
              -120.201077,
              38.336046
            ],
            [
              -120.196085,
              38.34333
            ],
            [
              -120.190137,
              38.352758
            ],
            [
              -120.189005,
              38.357146
            ],
            [
              -120.184575,
              38.359331
            ],
            [
              -120.183202,
              38.362345
            ],
            [
              -120.176642,
              38.372619
            ],
            [
              -120.17089,
              38.3758
            ],
            [
              -120.161143,
              38.38097
            ],
            [
              -120.152238,
              38.387698
            ],
            [
              -120.14384,
              38.392503
            ],
            [
              -120.136661,
              38.393967
            ],
            [
              -120.125209,
              38.397535
            ],
            [
              -120.114744,
              38.400758
            ],
            [
              -120.107448,
              38.401352
            ],
            [
              -120.095907,
              38.402927
            ],
            [
              -120.086808,
              38.404501
            ],
            [
              -120.080594,
              38.410184
            ],
            [
              -120.075146,
              38.4117
            ],
            [
              -120.069737,
              38.413481
            ],
            [
              -120.062759,
              38.416177
            ],
            [
              -120.05159,
              38.419348
            ],
            [
              -120.042572,
              38.423161
            ],
            [
              -120.033993,
              38.42464
            ],
            [
              -120.023372,
              38.4306
            ],
            [
              -120.019951,
              38.433521
            ],
            [
              -120.05365,
              38.455607
            ],
            [
              -120.072566,
              38.447081
            ],
            [
              -120.072484,
              38.509869
            ],
            [
              -120.085055,
              38.507223
            ],
            [
              -120.096571,
              38.505963
            ],
            [
              -120.106045,
              38.503923
            ],
            [
              -120.117156,
              38.497468
            ],
            [
              -120.13018,
              38.497138
            ],
            [
              -120.143762,
              38.496795
            ],
            [
              -120.153523,
              38.494431
            ],
            [
              -120.166316,
              38.495079
            ],
            [
              -120.182033,
              38.496408
            ],
            [
              -120.20205,
              38.500021
            ],
            [
              -120.219225,
              38.495995
            ],
            [
              -120.235781,
              38.489656
            ],
            [
              -120.244628,
              38.485737
            ],
            [
              -120.2571,
              38.486929
            ],
            [
              -120.26519,
              38.482278
            ],
            [
              -120.277647,
              38.475612
            ],
            [
              -120.297986,
              38.475449
            ],
            [
              -120.320338,
              38.470491
            ],
            [
              -120.342646,
              38.467149
            ],
            [
              -120.366718,
              38.466086
            ],
            [
              -120.384198,
              38.461865
            ],
            [
              -120.400261,
              38.466783
            ],
            [
              -120.415564,
              38.473419
            ],
            [
              -120.429235,
              38.468683
            ],
            [
              -120.447493,
              38.456722
            ],
            [
              -120.459223,
              38.447408
            ],
            [
              -120.475718,
              38.448106
            ],
            [
              -120.492255,
              38.446585
            ],
            [
              -120.494008,
              38.448412
            ],
            [
              -120.498674,
              38.448121
            ],
            [
              -120.498097,
              38.445462
            ],
            [
              -120.500205,
              38.444426
            ],
            [
              -120.503115,
              38.444677
            ],
            [
              -120.508354,
              38.437147
            ],
            [
              -120.525789,
              38.422975
            ],
            [
              -120.53891,
              38.419898
            ],
            [
              -120.551415,
              38.419699
            ],
            [
              -120.561785,
              38.415089
            ],
            [
              -120.572769,
              38.404217
            ],
            [
              -120.593915,
              38.399494
            ],
            [
              -120.613725,
              38.390073
            ],
            [
              -120.616726,
              38.377112
            ],
            [
              -120.616163,
              38.367978
            ],
            [
              -120.623545,
              38.357523
            ],
            [
              -120.631801,
              38.345566
            ],
            [
              -120.640245,
              38.339385
            ],
            [
              -120.658983,
              38.331559
            ],
            [
              -120.673172,
              38.329646
            ],
            [
              -120.683569,
              38.324667
            ],
            [
              -120.70302,
              38.316314
            ],
            [
              -120.72035,
              38.312677
            ],
            [
              -120.739327,
              38.306826
            ],
            [
              -120.750386,
              38.297506
            ],
            [
              -120.75272,
              38.292655
            ],
            [
              -120.759678,
              38.290031
            ],
            [
              -120.767291,
              38.290316
            ],
            [
              -120.785314,
              38.288925
            ],
            [
              -120.801521,
              38.287725
            ],
            [
              -120.808321,
              38.287694
            ],
            [
              -120.814313,
              38.283508
            ],
            [
              -120.8212,
              38.27742
            ],
            [
              -120.829839,
              38.270785
            ],
            [
              -120.841612,
              38.265102
            ],
            [
              -120.848794,
              38.26042
            ],
            [
              -120.856479,
              38.24777
            ],
            [
              -120.873976,
              38.242234
            ],
            [
              -120.880884,
              38.234387
            ],
            [
              -120.889097,
              38.227134
            ],
            [
              -120.895518,
              38.221494
            ],
            [
              -120.903741,
              38.223564
            ],
            [
              -120.909312,
              38.227476
            ],
            [
              -120.932081,
              38.223053
            ],
            [
              -120.950329,
              38.225973
            ],
            [
              -120.963347,
              38.219902
            ],
            [
              -120.978663,
              38.220989
            ],
            [
              -120.992341,
              38.225254
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "06",
      "COUNTYFP": "007",
      "COUNTYNS": "01675842",
      "AFFGEOID": "0500000US06007",
      "GEOID": "06007",
      "NAME": "Butte",
      "LSAD": "06",
      "ALAND": 4238438196,
      "AWATER": 105310997,
      "County_state": "Butte,06"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -122.069431,
              39.84053
            ],
            [
              -122.057308,
              39.828709
            ],
            [
              -122.06393,
              39.810634
            ],
            [
              -122.046471,
              39.797648
            ],
            [
              -122.023828,
              39.797674
            ],
            [
              -122.032375,
              39.780171
            ],
            [
              -121.983846,
              39.735413
            ],
            [
              -121.960472,
              39.736396
            ],
            [
              -121.967868,
              39.722397
            ],
            [
              -121.957239,
              39.719088
            ],
            [
              -121.949868,
              39.733001
            ],
            [
              -121.950951,
              39.715004
            ],
            [
              -121.941583,
              39.698113
            ],
            [
              -121.961435,
              39.677289
            ],
            [
              -121.994343,
              39.671458
            ],
            [
              -121.97765,
              39.66424
            ],
            [
              -121.968188,
              39.648348
            ],
            [
              -121.987075,
              39.643951
            ],
            [
              -121.992364,
              39.6296
            ],
            [
              -121.979555,
              39.614791
            ],
            [
              -121.995767,
              39.603164
            ],
            [
              -121.995062,
              39.582295
            ],
            [
              -122.00334,
              39.57326
            ],
            [
              -121.992273,
              39.562259
            ],
            [
              -122.003246,
              39.558228
            ],
            [
              -121.994021,
              39.533926
            ],
            [
              -121.974031,
              39.52885
            ],
            [
              -121.945194,
              39.536057
            ],
            [
              -121.856532,
              39.536904
            ],
            [
              -121.877341,
              39.509549
            ],
            [
              -121.87171,
              39.490771
            ],
            [
              -121.8708,
              39.449559
            ],
            [
              -121.890013,
              39.383864
            ],
            [
              -121.884047,
              39.373857
            ],
            [
              -121.899139,
              39.35692
            ],
            [
              -121.894389,
              39.341085
            ],
            [
              -121.908269,
              39.303878
            ],
            [
              -121.63015,
              39.305668
            ],
            [
              -121.62376,
              39.295621
            ],
            [
              -121.611855,
              39.318982
            ],
            [
              -121.594183,
              39.307835
            ],
            [
              -121.56314,
              39.305006
            ],
            [
              -121.531636,
              39.317891
            ],
            [
              -121.486673,
              39.317419
            ],
            [
              -121.474964,
              39.333341
            ],
            [
              -121.447014,
              39.330568
            ],
            [
              -121.434813,
              39.337482
            ],
            [
              -121.407527,
              39.339754
            ],
            [
              -121.382747,
              39.36276
            ],
            [
              -121.372901,
              39.36459
            ],
            [
              -121.36008,
              39.398719
            ],
            [
              -121.33442,
              39.425212
            ],
            [
              -121.345338,
              39.438923
            ],
            [
              -121.338326,
              39.461178
            ],
            [
              -121.314787,
              39.475899
            ],
            [
              -121.305425,
              39.497816
            ],
            [
              -121.305122,
              39.519585
            ],
            [
              -121.276789,
              39.520271
            ],
            [
              -121.277077,
              39.512812
            ],
            [
              -121.240242,
              39.513091
            ],
            [
              -121.240483,
              39.526613
            ],
            [
              -121.185173,
              39.526695
            ],
            [
              -121.159296,
              39.519595
            ],
            [
              -121.149907,
              39.526446
            ],
            [
              -121.149898,
              39.555289
            ],
            [
              -121.141213,
              39.562278
            ],
            [
              -121.10697,
              39.567191
            ],
            [
              -121.107465,
              39.585275
            ],
            [
              -121.079543,
              39.585263
            ],
            [
              -121.076695,
              39.597264
            ],
            [
              -121.088921,
              39.603306
            ],
            [
              -121.097538,
              39.620903
            ],
            [
              -121.136715,
              39.628169
            ],
            [
              -121.136412,
              39.65681
            ],
            [
              -121.155077,
              39.656785
            ],
            [
              -121.171143,
              39.671088
            ],
            [
              -121.171188,
              39.685564
            ],
            [
              -121.190284,
              39.685353
            ],
            [
              -121.190532,
              39.699891
            ],
            [
              -121.208687,
              39.700358
            ],
            [
              -121.210878,
              39.725651
            ],
            [
              -121.229446,
              39.725665
            ],
            [
              -121.229706,
              39.740213
            ],
            [
              -121.248256,
              39.740041
            ],
            [
              -121.250641,
              39.753965
            ],
            [
              -121.268025,
              39.754244
            ],
            [
              -121.268272,
              39.768799
            ],
            [
              -121.285852,
              39.767624
            ],
            [
              -121.284946,
              39.781576
            ],
            [
              -121.316938,
              39.796702
            ],
            [
              -121.318471,
              39.810906
            ],
            [
              -121.332333,
              39.811053
            ],
            [
              -121.332293,
              39.825696
            ],
            [
              -121.350821,
              39.825704
            ],
            [
              -121.361206,
              39.855473
            ],
            [
              -121.380183,
              39.855421
            ],
            [
              -121.411936,
              39.871421
            ],
            [
              -121.411,
              39.900425
            ],
            [
              -121.429957,
              39.900313
            ],
            [
              -121.430012,
              39.914833
            ],
            [
              -121.411001,
              39.915091
            ],
            [
              -121.420577,
              39.92945
            ],
            [
              -121.419844,
              39.958625
            ],
            [
              -121.410327,
              39.958478
            ],
            [
              -121.410263,
              39.987303
            ],
            [
              -121.419817,
              39.987549
            ],
            [
              -121.41948,
              40.015766
            ],
            [
              -121.400338,
              40.030523
            ],
            [
              -121.400353,
              40.052095
            ],
            [
              -121.382266,
              40.051827
            ],
            [
              -121.366818,
              40.086054
            ],
            [
              -121.376792,
              40.086034
            ],
            [
              -121.376489,
              40.108626
            ],
            [
              -121.404855,
              40.115545
            ],
            [
              -121.404509,
              40.151648
            ],
            [
              -121.436886,
              40.151905
            ],
            [
              -121.442869,
              40.129647
            ],
            [
              -121.452898,
              40.122125
            ],
            [
              -121.480954,
              40.122366
            ],
            [
              -121.490873,
              40.108044
            ],
            [
              -121.510559,
              40.100515
            ],
            [
              -121.586407,
              40.100522
            ],
            [
              -121.595802,
              40.093458
            ],
            [
              -121.595647,
              40.057714
            ],
            [
              -121.626086,
              40.039496
            ],
            [
              -121.636174,
              40.02581
            ],
            [
              -121.63544,
              39.996822
            ],
            [
              -121.646032,
              39.982527
            ],
            [
              -121.703241,
              39.98423
            ],
            [
              -121.725321,
              39.966966
            ],
            [
              -121.746251,
              39.938532
            ],
            [
              -121.750634,
              39.906805
            ],
            [
              -121.777103,
              39.888519
            ],
            [
              -121.804041,
              39.884344
            ],
            [
              -121.873517,
              39.883995
            ],
            [
              -121.884518,
              39.883208
            ],
            [
              -122.044865,
              39.883771
            ],
            [
              -122.040789,
              39.879899
            ],
            [
              -122.069431,
              39.84053
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "06",
      "COUNTYFP": "039",
      "COUNTYNS": "00277284",
      "AFFGEOID": "0500000US06039",
      "GEOID": "06039",
      "NAME": "Madera",
      "LSAD": "06",
      "ALAND": 5534850492,
      "AWATER": 42012923,
      "County_state": "Madera,06"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -120.541696,
              37.044505
            ],
            [
              -120.545536,
              37.027582
            ],
            [
              -120.508965,
              37.007623
            ],
            [
              -120.499519,
              36.981966
            ],
            [
              -120.483795,
              36.970985
            ],
            [
              -120.475166,
              36.939252
            ],
            [
              -120.468011,
              36.937472
            ],
            [
              -120.450341,
              36.911172
            ],
            [
              -120.466695,
              36.889744
            ],
            [
              -120.453666,
              36.88079
            ],
            [
              -120.456246,
              36.862863
            ],
            [
              -120.433886,
              36.850582
            ],
            [
              -120.417095,
              36.835626
            ],
            [
              -120.410444,
              36.840005
            ],
            [
              -120.40071,
              36.82241
            ],
            [
              -120.38903,
              36.824441
            ],
            [
              -120.369612,
              36.810355
            ],
            [
              -120.370256,
              36.78494
            ],
            [
              -120.344335,
              36.786352
            ],
            [
              -120.34885,
              36.798255
            ],
            [
              -120.335563,
              36.78417
            ],
            [
              -120.300357,
              36.777633
            ],
            [
              -120.290594,
              36.763047
            ],
            [
              -120.285504,
              36.773504
            ],
            [
              -120.229179,
              36.769687
            ],
            [
              -120.233502,
              36.778668
            ],
            [
              -120.21749,
              36.788666
            ],
            [
              -120.202218,
              36.790005
            ],
            [
              -120.18883,
              36.776408
            ],
            [
              -120.172198,
              36.803096
            ],
            [
              -120.155336,
              36.798816
            ],
            [
              -120.136572,
              36.809296
            ],
            [
              -120.104262,
              36.813351
            ],
            [
              -120.079458,
              36.825345
            ],
            [
              -120.070733,
              36.818621
            ],
            [
              -120.057776,
              36.82286
            ],
            [
              -120.027775,
              36.814505
            ],
            [
              -120.013334,
              36.828228
            ],
            [
              -119.99075,
              36.830565
            ],
            [
              -119.984035,
              36.840892
            ],
            [
              -119.966639,
              36.832482
            ],
            [
              -119.943507,
              36.83403
            ],
            [
              -119.932338,
              36.843323
            ],
            [
              -119.909098,
              36.846199
            ],
            [
              -119.884889,
              36.858549
            ],
            [
              -119.870619,
              36.852227
            ],
            [
              -119.837294,
              36.854114
            ],
            [
              -119.814421,
              36.850138
            ],
            [
              -119.809639,
              36.862569
            ],
            [
              -119.78819,
              36.876607
            ],
            [
              -119.789655,
              36.895349
            ],
            [
              -119.772737,
              36.918599
            ],
            [
              -119.758728,
              36.920238
            ],
            [
              -119.752532,
              36.935223
            ],
            [
              -119.733767,
              36.946441
            ],
            [
              -119.742361,
              36.953698
            ],
            [
              -119.7405,
              36.970212
            ],
            [
              -119.713182,
              36.991638
            ],
            [
              -119.705356,
              36.999794
            ],
            [
              -119.69971,
              37.004904
            ],
            [
              -119.670918,
              37.00926
            ],
            [
              -119.658835,
              37.032905
            ],
            [
              -119.652736,
              37.042118
            ],
            [
              -119.633635,
              37.038486
            ],
            [
              -119.632057,
              37.029586
            ],
            [
              -119.627885,
              37.023887
            ],
            [
              -119.620657,
              37.027058
            ],
            [
              -119.614146,
              37.039842
            ],
            [
              -119.611514,
              37.055968
            ],
            [
              -119.609899,
              37.06413
            ],
            [
              -119.602871,
              37.071289
            ],
            [
              -119.593206,
              37.06907
            ],
            [
              -119.583632,
              37.065682
            ],
            [
              -119.569898,
              37.063883
            ],
            [
              -119.565536,
              37.064023
            ],
            [
              -119.561814,
              37.066968
            ],
            [
              -119.560563,
              37.070349
            ],
            [
              -119.563199,
              37.074755
            ],
            [
              -119.56081,
              37.080573
            ],
            [
              -119.559617,
              37.085139
            ],
            [
              -119.558074,
              37.089002
            ],
            [
              -119.553529,
              37.09236
            ],
            [
              -119.549413,
              37.09402
            ],
            [
              -119.545541,
              37.09517
            ],
            [
              -119.540811,
              37.09683
            ],
            [
              -119.538461,
              37.101873
            ],
            [
              -119.537768,
              37.10558
            ],
            [
              -119.540865,
              37.109929
            ],
            [
              -119.544749,
              37.113133
            ],
            [
              -119.551707,
              37.116934
            ],
            [
              -119.557845,
              37.115611
            ],
            [
              -119.564097,
              37.11584
            ],
            [
              -119.568973,
              37.118342
            ],
            [
              -119.566718,
              37.12117
            ],
            [
              -119.563691,
              37.127767
            ],
            [
              -119.563301,
              37.134391
            ],
            [
              -119.562852,
              37.140456
            ],
            [
              -119.558302,
              37.143672
            ],
            [
              -119.550891,
              37.14484
            ],
            [
              -119.543237,
              37.139516
            ],
            [
              -119.537292,
              37.134102
            ],
            [
              -119.530771,
              37.131282
            ],
            [
              -119.528084,
              37.129269
            ],
            [
              -119.523961,
              37.128656
            ],
            [
              -119.523122,
              37.130725
            ],
            [
              -119.520285,
              37.137957
            ],
            [
              -119.517709,
              37.145508
            ],
            [
              -119.511127,
              37.149279
            ],
            [
              -119.50397,
              37.148335
            ],
            [
              -119.496262,
              37.143972
            ],
            [
              -119.490621,
              37.138909
            ],
            [
              -119.488909,
              37.135703
            ],
            [
              -119.491718,
              37.130319
            ],
            [
              -119.493028,
              37.125068
            ],
            [
              -119.490258,
              37.119051
            ],
            [
              -119.483281,
              37.110306
            ],
            [
              -119.47386,
              37.110191
            ],
            [
              -119.468911,
              37.115043
            ],
            [
              -119.466404,
              37.119332
            ],
            [
              -119.466807,
              37.121759
            ],
            [
              -119.468946,
              37.125182
            ],
            [
              -119.466526,
              37.132639
            ],
            [
              -119.463121,
              37.141377
            ],
            [
              -119.459977,
              37.145221
            ],
            [
              -119.45665,
              37.14474
            ],
            [
              -119.454295,
              37.144029
            ],
            [
              -119.447105,
              37.144414
            ],
            [
              -119.433101,
              37.152016
            ],
            [
              -119.427604,
              37.163955
            ],
            [
              -119.415793,
              37.163446
            ],
            [
              -119.406812,
              37.160031
            ],
            [
              -119.402324,
              37.153142
            ],
            [
              -119.391101,
              37.149299
            ],
            [
              -119.385956,
              37.150052
            ],
            [
              -119.383116,
              37.154282
            ],
            [
              -119.378572,
              37.15548
            ],
            [
              -119.37492,
              37.158663
            ],
            [
              -119.375792,
              37.162316
            ],
            [
              -119.373511,
              37.164631
            ],
            [
              -119.368612,
              37.165104
            ],
            [
              -119.364174,
              37.166359
            ],
            [
              -119.360787,
              37.169382
            ],
            [
              -119.360755,
              37.174244
            ],
            [
              -119.360684,
              37.179462
            ],
            [
              -119.357877,
              37.182632
            ],
            [
              -119.35492,
              37.184684
            ],
            [
              -119.351793,
              37.186198
            ],
            [
              -119.347279,
              37.187055
            ],
            [
              -119.343408,
              37.188842
            ],
            [
              -119.340806,
              37.191587
            ],
            [
              -119.339345,
              37.194484
            ],
            [
              -119.338645,
              37.19703
            ],
            [
              -119.337357,
              37.200095
            ],
            [
              -119.33432,
              37.202782
            ],
            [
              -119.33084,
              37.20548
            ],
            [
              -119.329937,
              37.209256
            ],
            [
              -119.331781,
              37.215733
            ],
            [
              -119.336012,
              37.216897
            ],
            [
              -119.337673,
              37.219622
            ],
            [
              -119.336895,
              37.220648
            ],
            [
              -119.333862,
              37.223736
            ],
            [
              -119.333045,
              37.228698
            ],
            [
              -119.332625,
              37.233162
            ],
            [
              -119.331864,
              37.236051
            ],
            [
              -119.330059,
              37.241271
            ],
            [
              -119.324349,
              37.244258
            ],
            [
              -119.322179,
              37.248807
            ],
            [
              -119.322491,
              37.254189
            ],
            [
              -119.324338,
              37.258117
            ],
            [
              -119.324436,
              37.262434
            ],
            [
              -119.324697,
              37.265757
            ],
            [
              -119.326228,
              37.269413
            ],
            [
              -119.330559,
              37.272769
            ],
            [
              -119.332198,
              37.275005
            ],
            [
              -119.331153,
              37.279856
            ],
            [
              -119.33041,
              37.283617
            ],
            [
              -119.328303,
              37.286935
            ],
            [
              -119.327162,
              37.290113
            ],
            [
              -119.327375,
              37.293323
            ],
            [
              -119.329473,
              37.29834
            ],
            [
              -119.331523,
              37.302501
            ],
            [
              -119.334042,
              37.30393
            ],
            [
              -119.33466,
              37.312904
            ],
            [
              -119.328238,
              37.317274
            ],
            [
              -119.325297,
              37.319877
            ],
            [
              -119.32221,
              37.320858
            ],
            [
              -119.31903,
              37.320963
            ],
            [
              -119.31626,
              37.323982
            ],
            [
              -119.317911,
              37.329117
            ],
            [
              -119.323035,
              37.331649
            ],
            [
              -119.316839,
              37.337784
            ],
            [
              -119.31242,
              37.343803
            ],
            [
              -119.312792,
              37.351932
            ],
            [
              -119.299239,
              37.36415
            ],
            [
              -119.287222,
              37.374458
            ],
            [
              -119.250957,
              37.403173
            ],
            [
              -119.207068,
              37.438081
            ],
            [
              -119.146409,
              37.486822
            ],
            [
              -119.114424,
              37.512497
            ],
            [
              -119.049018,
              37.564531
            ],
            [
              -119.022363,
              37.585737
            ],
            [
              -119.034365,
              37.603536
            ],
            [
              -119.032565,
              37.630535
            ],
            [
              -119.054618,
              37.645135
            ],
            [
              -119.065166,
              37.684556
            ],
            [
              -119.093126,
              37.703627
            ],
            [
              -119.109694,
              37.724735
            ],
            [
              -119.124312,
              37.733944
            ],
            [
              -119.147891,
              37.732757
            ],
            [
              -119.170957,
              37.738094
            ],
            [
              -119.198491,
              37.736657
            ],
            [
              -119.216333,
              37.714123
            ],
            [
              -119.239264,
              37.727467
            ],
            [
              -119.255178,
              37.725331
            ],
            [
              -119.268979,
              37.73923
            ],
            [
              -119.288381,
              37.74503
            ],
            [
              -119.293382,
              37.767929
            ],
            [
              -119.308995,
              37.777986
            ],
            [
              -119.583585,
              37.560335
            ],
            [
              -119.58422,
              37.494696
            ],
            [
              -119.615148,
              37.494681
            ],
            [
              -119.615495,
              37.461522
            ],
            [
              -119.651172,
              37.461351
            ],
            [
              -119.651191,
              37.417832
            ],
            [
              -119.761809,
              37.417114
            ],
            [
              -120.052055,
              37.183108
            ],
            [
              -120.096649,
              37.171959
            ],
            [
              -120.106385,
              37.167153
            ],
            [
              -120.164576,
              37.162698
            ],
            [
              -120.227286,
              37.1634
            ],
            [
              -120.276096,
              37.152211
            ],
            [
              -120.289451,
              37.152685
            ],
            [
              -120.303757,
              37.141093
            ],
            [
              -120.324544,
              37.136382
            ],
            [
              -120.341204,
              37.124596
            ],
            [
              -120.363315,
              37.119712
            ],
            [
              -120.384886,
              37.124589
            ],
            [
              -120.406777,
              37.110854
            ],
            [
              -120.476692,
              37.09639
            ],
            [
              -120.541696,
              37.044505
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "06",
      "COUNTYFP": "111",
      "COUNTYNS": "00277320",
      "AFFGEOID": "0500000US06111",
      "GEOID": "06111",
      "NAME": "Ventura",
      "LSAD": "06",
      "ALAND": 4773302741,
      "AWATER": 946030569,
      "County_state": "Ventura,06"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -119.430804,
              34.029114
            ],
            [
              -119.455761,
              34.015325
            ],
            [
              -119.423674,
              33.997592
            ],
            [
              -119.391587,
              33.994636
            ],
            [
              -119.363065,
              34.000548
            ],
            [
              -119.347616,
              34.019265
            ],
            [
              -119.364254,
              34.026159
            ],
            [
              -119.403471,
              34.014339
            ],
            [
              -119.430804,
              34.029114
            ]
          ]
        ],
        [
          [
            [
              -119.478265424859,
              34.3771973988722
            ],
            [
              -119.472678,
              34.375628
            ],
            [
              -119.461036,
              34.374064
            ],
            [
              -119.435888,
              34.355839
            ],
            [
              -119.431066,
              34.355297
            ],
            [
              -119.42777,
              34.353016
            ],
            [
              -119.390449,
              34.318198
            ],
            [
              -119.388249,
              34.317398
            ],
            [
              -119.37578,
              34.321118
            ],
            [
              -119.370356,
              34.319486
            ],
            [
              -119.349187,
              34.304383
            ],
            [
              -119.337475,
              34.290576
            ],
            [
              -119.313034,
              34.275689
            ],
            [
              -119.302131,
              34.272761
            ],
            [
              -119.290945,
              34.274902
            ],
            [
              -119.278644,
              34.266902
            ],
            [
              -119.273694327306,
              34.2587501802301
            ],
            [
              -119.270144,
              34.252903
            ],
            [
              -119.265927,
              34.234609
            ],
            [
              -119.257043,
              34.213304
            ],
            [
              -119.237142,
              34.175804
            ],
            [
              -119.227743,
              34.161728
            ],
            [
              -119.225267883658,
              34.158306592938594
            ],
            [
              -119.216441,
              34.146105
            ],
            [
              -119.211241,
              34.144905
            ],
            [
              -119.20314,
              34.144505
            ],
            [
              -119.18864,
              34.139005
            ],
            [
              -119.130169,
              34.100102
            ],
            [
              -119.109784,
              34.094566
            ],
            [
              -119.098216,
              34.099334
            ],
            [
              -119.088536,
              34.09831
            ],
            [
              -119.069959,
              34.09047
            ],
            [
              -119.063806806195,
              34.0890754522035
            ],
            [
              -119.037494,
              34.083111
            ],
            [
              -119.004644,
              34.066231
            ],
            [
              -118.99698,
              34.065943
            ],
            [
              -118.977751,
              34.059822
            ],
            [
              -118.954722,
              34.048167
            ],
            [
              -118.944887329171,
              34.0453397020464
            ],
            [
              -118.940801,
              34.074967
            ],
            [
              -118.856473,
              34.126765
            ],
            [
              -118.788889,
              34.168214
            ],
            [
              -118.723374,
              34.167861
            ],
            [
              -118.703392,
              34.168591
            ],
            [
              -118.693834,
              34.168557
            ],
            [
              -118.668152,
              34.168195
            ],
            [
              -118.667944,
              34.199166
            ],
            [
              -118.66778,
              34.216517
            ],
            [
              -118.667708,
              34.236692
            ],
            [
              -118.650138,
              34.240403
            ],
            [
              -118.648113,
              34.240435
            ],
            [
              -118.632537,
              34.241948
            ],
            [
              -118.632548,
              34.263429
            ],
            [
              -118.633461,
              34.269522
            ],
            [
              -118.636789,
              34.291804
            ],
            [
              -118.652285,
              34.323392
            ],
            [
              -118.670593,
              34.360692
            ],
            [
              -118.6929,
              34.406088
            ],
            [
              -118.722616,
              34.466483
            ],
            [
              -118.722668,
              34.466587
            ],
            [
              -118.722716,
              34.466686
            ],
            [
              -118.738618,
              34.498969
            ],
            [
              -118.796842,
              34.616567
            ],
            [
              -118.881364,
              34.790629
            ],
            [
              -118.976723,
              34.79066
            ],
            [
              -118.976721,
              34.812199
            ],
            [
              -119.243645,
              34.814178
            ],
            [
              -119.243645,
              34.857576
            ],
            [
              -119.278346,
              34.857276
            ],
            [
              -119.276946,
              34.879675
            ],
            [
              -119.382451,
              34.879675
            ],
            [
              -119.382154,
              34.900936
            ],
            [
              -119.442352,
              34.901274
            ],
            [
              -119.442353,
              34.561101
            ],
            [
              -119.442269,
              34.463949
            ],
            [
              -119.440414,
              34.441842
            ],
            [
              -119.454277,
              34.418166
            ],
            [
              -119.445991,
              34.404067
            ],
            [
              -119.478265424859,
              34.3771973988722
            ]
          ]
        ],
        [
          [
            [
              -119.578942,
              33.278628
            ],
            [
              -119.570642,
              33.257729
            ],
            [
              -119.566014,
              33.252639
            ],
            [
              -119.565641,
              33.250029
            ],
            [
              -119.564971,
              33.24744
            ],
            [
              -119.545872,
              33.233406
            ],
            [
              -119.517514,
              33.226737
            ],
            [
              -119.511659,
              33.223027
            ],
            [
              -119.500684,
              33.220569
            ],
            [
              -119.476029,
              33.21552
            ],
            [
              -119.464725,
              33.215432
            ],
            [
              -119.444269,
              33.21919
            ],
            [
              -119.429559,
              33.228167
            ],
            [
              -119.458466,
              33.254661
            ],
            [
              -119.465717,
              33.259239
            ],
            [
              -119.48278,
              33.263973
            ],
            [
              -119.50504,
              33.272829
            ],
            [
              -119.528141,
              33.284929
            ],
            [
              -119.532941,
              33.284728
            ],
            [
              -119.543842,
              33.280329
            ],
            [
              -119.547642,
              33.280328
            ],
            [
              -119.555242,
              33.273429
            ],
            [
              -119.562042,
              33.271129
            ],
            [
              -119.578942,
              33.278628
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "06",
      "COUNTYFP": "059",
      "COUNTYNS": "00277294",
      "AFFGEOID": "0500000US06059",
      "GEOID": "06059",
      "NAME": "Orange",
      "LSAD": "06",
      "ALAND": 2048392529,
      "AWATER": 406915886,
      "County_state": "Orange,06"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -118.115658466483,
              33.7429181141995
            ],
            [
              -118.101097,
              33.734117
            ],
            [
              -118.088896,
              33.729817
            ],
            [
              -118.064895,
              33.711018
            ],
            [
              -118.03222547678901,
              33.678906255927295
            ],
            [
              -118.029694,
              33.676418
            ],
            [
              -118.000593,
              33.654319
            ],
            [
              -117.940591,
              33.620021
            ],
            [
              -117.927091,
              33.605521
            ],
            [
              -117.89979,
              33.599622
            ],
            [
              -117.87679,
              33.592322
            ],
            [
              -117.840289,
              33.573523
            ],
            [
              -117.814188,
              33.552224
            ],
            [
              -117.801288,
              33.546324
            ],
            [
              -117.784888,
              33.541525
            ],
            [
              -117.761387,
              33.516326
            ],
            [
              -117.732259633371,
              33.4888694447514
            ],
            [
              -117.726486,
              33.483427
            ],
            [
              -117.715349,
              33.460556
            ],
            [
              -117.691384,
              33.454028
            ],
            [
              -117.691984,
              33.456627
            ],
            [
              -117.689284,
              33.460155
            ],
            [
              -117.684584,
              33.461927
            ],
            [
              -117.645582,
              33.440728
            ],
            [
              -117.631682,
              33.430528
            ],
            [
              -117.607905,
              33.406317
            ],
            [
              -117.59616273186401,
              33.387091904360595
            ],
            [
              -117.57848,
              33.453927
            ],
            [
              -117.538429,
              33.455643
            ],
            [
              -117.508614,
              33.469614
            ],
            [
              -117.509722,
              33.505019
            ],
            [
              -117.51021,
              33.533999
            ],
            [
              -117.457937,
              33.602189
            ],
            [
              -117.412987,
              33.659045
            ],
            [
              -117.474573,
              33.703811
            ],
            [
              -117.533999,
              33.710355
            ],
            [
              -117.536448,
              33.757665
            ],
            [
              -117.542265,
              33.758801
            ],
            [
              -117.569175,
              33.76461
            ],
            [
              -117.580789,
              33.769477
            ],
            [
              -117.662117,
              33.857499
            ],
            [
              -117.674282,
              33.857956
            ],
            [
              -117.675092,
              33.869172
            ],
            [
              -117.673749,
              33.870831
            ],
            [
              -117.680289,
              33.877412
            ],
            [
              -117.783287,
              33.946411
            ],
            [
              -117.919725,
              33.947668
            ],
            [
              -117.925836,
              33.946028
            ],
            [
              -117.966691,
              33.946058
            ],
            [
              -117.976498,
              33.94605
            ],
            [
              -117.976586,
              33.906407
            ],
            [
              -117.994057,
              33.888218
            ],
            [
              -117.999393,
              33.888175
            ],
            [
              -118.011383,
              33.873431
            ],
            [
              -118.028782,
              33.873322
            ],
            [
              -118.028714,
              33.866242
            ],
            [
              -118.037598,
              33.866013
            ],
            [
              -118.058655,
              33.846127
            ],
            [
              -118.058918,
              33.846121
            ],
            [
              -118.058988,
              33.835207
            ],
            [
              -118.063268,
              33.82422
            ],
            [
              -118.063162,
              33.81961
            ],
            [
              -118.071827,
              33.815446
            ],
            [
              -118.084597,
              33.803216
            ],
            [
              -118.093099,
              33.786152
            ],
            [
              -118.09197,
              33.758472
            ],
            [
              -118.115658466483,
              33.7429181141995
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "06",
      "COUNTYFP": "029",
      "COUNTYNS": "02054176",
      "AFFGEOID": "0500000US06029",
      "GEOID": "06029",
      "NAME": "Kern",
      "LSAD": "06",
      "ALAND": 21062393794,
      "AWATER": 78770534,
      "County_state": "Kern,06"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -120.194146,
              35.789204
            ],
            [
              -120.193918,
              35.614359
            ],
            [
              -120.085922,
              35.614524
            ],
            [
              -120.086674,
              35.526554
            ],
            [
              -120.068657,
              35.52632
            ],
            [
              -120.068905,
              35.512779
            ],
            [
              -120.051237,
              35.512695
            ],
            [
              -120.05105,
              35.498627
            ],
            [
              -120.033241,
              35.498642
            ],
            [
              -120.033314,
              35.483648
            ],
            [
              -120.014602,
              35.483652
            ],
            [
              -120.015659,
              35.469039
            ],
            [
              -119.997382,
              35.468702
            ],
            [
              -119.997392,
              35.439495
            ],
            [
              -119.913659,
              35.439262
            ],
            [
              -119.880045,
              35.439133
            ],
            [
              -119.880172,
              35.351211
            ],
            [
              -119.809346,
              35.350865
            ],
            [
              -119.809449,
              35.263584
            ],
            [
              -119.666663,
              35.262527
            ],
            [
              -119.667056,
              35.174809
            ],
            [
              -119.571259,
              35.180292
            ],
            [
              -119.553641,
              35.179975
            ],
            [
              -119.560975,
              35.087673
            ],
            [
              -119.490632,
              35.091805
            ],
            [
              -119.490709,
              35.077208
            ],
            [
              -119.472719,
              35.076885
            ],
            [
              -119.472754,
              34.901174
            ],
            [
              -119.442352,
              34.901274
            ],
            [
              -119.382154,
              34.900936
            ],
            [
              -119.382451,
              34.879675
            ],
            [
              -119.276946,
              34.879675
            ],
            [
              -119.278346,
              34.857276
            ],
            [
              -119.243645,
              34.857576
            ],
            [
              -119.243645,
              34.814178
            ],
            [
              -118.976721,
              34.812199
            ],
            [
              -118.976723,
              34.79066
            ],
            [
              -118.881364,
              34.790629
            ],
            [
              -118.894474,
              34.817972
            ],
            [
              -118.881729,
              34.817802
            ],
            [
              -118.877289,
              34.803212
            ],
            [
              -118.854576,
              34.803027
            ],
            [
              -118.854253,
              34.817772
            ],
            [
              -118.587591,
              34.818022
            ],
            [
              -118.326281,
              34.819726
            ],
            [
              -117.774368,
              34.823301
            ],
            [
              -117.667292,
              34.822526
            ],
            [
              -117.632011,
              34.82227
            ],
            [
              -117.63088,
              35.097604
            ],
            [
              -117.631299,
              35.274179
            ],
            [
              -117.630216,
              35.451041
            ],
            [
              -117.634771,
              35.564109
            ],
            [
              -117.63383,
              35.651569
            ],
            [
              -117.616395,
              35.651755
            ],
            [
              -117.616195,
              35.680856
            ],
            [
              -117.652319,
              35.680782
            ],
            [
              -117.651986,
              35.709934
            ],
            [
              -117.634251,
              35.709927
            ],
            [
              -117.632996,
              35.797251
            ],
            [
              -117.924459,
              35.798149
            ],
            [
              -117.92312,
              35.786812
            ],
            [
              -118.008043,
              35.789161
            ],
            [
              -118.067719,
              35.791537
            ],
            [
              -118.195464,
              35.790689
            ],
            [
              -118.375916,
              35.790224
            ],
            [
              -118.464791,
              35.792637
            ],
            [
              -118.507224,
              35.789711
            ],
            [
              -118.803729,
              35.790347
            ],
            [
              -119.053437,
              35.790748
            ],
            [
              -119.538116,
              35.789567
            ],
            [
              -120.194146,
              35.789204
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "06",
      "COUNTYFP": "013",
      "COUNTYNS": "01675903",
      "AFFGEOID": "0500000US06013",
      "GEOID": "06013",
      "NAME": "Contra Costa",
      "LSAD": "06",
      "ALAND": 1857310903,
      "AWATER": 225193562,
      "County_state": "Contra Costa,06"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -122.42976,
              37.965405
            ],
            [
              -122.430087,
              37.963115
            ],
            [
              -122.417371,
              37.943513
            ],
            [
              -122.413725,
              37.937262
            ],
            [
              -122.401289,
              37.928426
            ],
            [
              -122.395071,
              37.927117
            ],
            [
              -122.39049,
              37.922535
            ],
            [
              -122.389181,
              37.9101
            ],
            [
              -122.385908,
              37.908136
            ],
            [
              -122.378709,
              37.905191
            ],
            [
              -122.367582,
              37.903882
            ],
            [
              -122.362346,
              37.904209
            ],
            [
              -122.35711,
              37.908791
            ],
            [
              -122.33453,
              37.908791
            ],
            [
              -122.32373,
              37.905845
            ],
            [
              -122.313495917826,
              37.897210726126204
            ],
            [
              -122.27108,
              37.905824
            ],
            [
              -122.248977,
              37.891222
            ],
            [
              -122.223878,
              37.878326
            ],
            [
              -122.221488,
              37.865026
            ],
            [
              -122.184179,
              37.833922
            ],
            [
              -122.176977,
              37.816227
            ],
            [
              -122.157392,
              37.817952
            ],
            [
              -122.14026,
              37.804562
            ],
            [
              -122.113812,
              37.802729
            ],
            [
              -122.106302,
              37.802194
            ],
            [
              -122.045473,
              37.798126
            ],
            [
              -122.004809,
              37.770571
            ],
            [
              -121.997771,
              37.763227
            ],
            [
              -122.011771,
              37.747428
            ],
            [
              -121.955261,
              37.719857
            ],
            [
              -121.870733,
              37.740086
            ],
            [
              -121.577005,
              37.811527
            ],
            [
              -121.556997,
              37.816488
            ],
            [
              -121.534247,
              37.849891
            ],
            [
              -121.553123,
              37.860963
            ],
            [
              -121.579503,
              37.861072
            ],
            [
              -121.572824,
              37.904301
            ],
            [
              -121.557274,
              37.932247
            ],
            [
              -121.568515,
              37.949171
            ],
            [
              -121.565138,
              37.959849
            ],
            [
              -121.582463,
              37.983922
            ],
            [
              -121.569612,
              38.00316
            ],
            [
              -121.578711,
              38.004255
            ],
            [
              -121.572789,
              38.01691
            ],
            [
              -121.557554,
              38.017037
            ],
            [
              -121.572169,
              38.041412
            ],
            [
              -121.584388,
              38.036025
            ],
            [
              -121.582203,
              38.068423
            ],
            [
              -121.572923,
              38.074239
            ],
            [
              -121.580022,
              38.094414
            ],
            [
              -121.604923,
              38.099878
            ],
            [
              -121.627376,
              38.09836
            ],
            [
              -121.638088,
              38.086131
            ],
            [
              -121.661865,
              38.095618
            ],
            [
              -121.673065,
              38.093518
            ],
            [
              -121.681865,
              38.082019
            ],
            [
              -121.68147,
              38.061244
            ],
            [
              -121.699765,
              38.04522
            ],
            [
              -121.737824,
              38.02663
            ],
            [
              -121.788773,
              38.018421
            ],
            [
              -121.80127,
              38.021622
            ],
            [
              -121.815673,
              38.021038
            ],
            [
              -121.832122,
              38.031101
            ],
            [
              -121.841659,
              38.051974
            ],
            [
              -121.862462,
              38.06603
            ],
            [
              -121.869582,
              38.054414
            ],
            [
              -121.889573,
              38.046657
            ],
            [
              -121.925814,
              38.046474
            ],
            [
              -121.983158,
              38.067297
            ],
            [
              -122.028411,
              38.05919
            ],
            [
              -122.050386,
              38.060198
            ],
            [
              -122.061103,
              38.062137
            ],
            [
              -122.095733,
              38.048355
            ],
            [
              -122.099776,
              38.047008
            ],
            [
              -122.14312,
              38.031761
            ],
            [
              -122.167577,
              38.036721
            ],
            [
              -122.183863,
              38.053997
            ],
            [
              -122.245251,
              38.063969
            ],
            [
              -122.26666850460501,
              38.0600701915224
            ],
            [
              -122.26587212142701,
              38.058271990490695
            ],
            [
              -122.262861,
              38.051473
            ],
            [
              -122.262861,
              38.0446
            ],
            [
              -122.27332423000101,
              38.033472445990796
            ],
            [
              -122.283478,
              38.022674
            ],
            [
              -122.300823,
              38.010893
            ],
            [
              -122.315549,
              38.013511
            ],
            [
              -122.321112,
              38.012857
            ],
            [
              -122.331100444013,
              38.005895609436294
            ],
            [
              -122.331912,
              38.00533
            ],
            [
              -122.340093,
              38.003694
            ],
            [
              -122.359493,
              38.009941
            ],
            [
              -122.363655,
              38.014166
            ],
            [
              -122.367909,
              38.01253
            ],
            [
              -122.368891,
              38.007948
            ],
            [
              -122.366928,
              37.998458
            ],
            [
              -122.363001,
              37.994375
            ],
            [
              -122.361905,
              37.989991
            ],
            [
              -122.367582,
              37.978168
            ],
            [
              -122.392171,
              37.969993
            ],
            [
              -122.399832,
              37.956009
            ],
            [
              -122.408383,
              37.957544
            ],
            [
              -122.411761,
              37.960497
            ],
            [
              -122.415361,
              37.963115
            ],
            [
              -122.42976,
              37.965405
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "06",
      "COUNTYFP": "031",
      "COUNTYNS": "00277280",
      "AFFGEOID": "0500000US06031",
      "GEOID": "06031",
      "NAME": "Kings",
      "LSAD": "06",
      "ALAND": 3598593366,
      "AWATER": 5453080,
      "County_state": "Kings,06"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -120.315068,
              35.907186
            ],
            [
              -120.27576,
              35.905881
            ],
            [
              -120.243275,
              35.877669
            ],
            [
              -120.240246,
              35.865928
            ],
            [
              -120.259302,
              35.845053
            ],
            [
              -120.244534,
              35.823988
            ],
            [
              -120.220667,
              35.819542
            ],
            [
              -120.213979,
              35.789276
            ],
            [
              -120.194146,
              35.789204
            ],
            [
              -119.538116,
              35.789567
            ],
            [
              -119.536175,
              35.963415
            ],
            [
              -119.536161,
              36.050782
            ],
            [
              -119.53638,
              36.137893
            ],
            [
              -119.529358,
              36.26985
            ],
            [
              -119.474607,
              36.269025
            ],
            [
              -119.474367,
              36.297889
            ],
            [
              -119.474892,
              36.400953
            ],
            [
              -119.528792,
              36.401465
            ],
            [
              -119.527194,
              36.488886
            ],
            [
              -119.573194,
              36.488835
            ],
            [
              -119.666422,
              36.418866
            ],
            [
              -119.671493,
              36.431074
            ],
            [
              -119.712072,
              36.419437
            ],
            [
              -119.747117,
              36.416387
            ],
            [
              -119.754213,
              36.402023
            ],
            [
              -119.959247,
              36.400871
            ],
            [
              -119.95921,
              36.181403
            ],
            [
              -120.315068,
              35.907186
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "06",
      "COUNTYFP": "045",
      "COUNTYNS": "00277287",
      "AFFGEOID": "0500000US06045",
      "GEOID": "06045",
      "NAME": "Mendocino",
      "LSAD": "06",
      "ALAND": 9081426673,
      "AWATER": 962946204,
      "County_state": "Mendocino,06"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -124.02393814948901,
              40.0012844214495
            ],
            [
              -123.99586,
              39.973045
            ],
            [
              -123.980031,
              39.962458
            ],
            [
              -123.962655,
              39.937635
            ],
            [
              -123.954952,
              39.922373
            ],
            [
              -123.930047,
              39.909697
            ],
            [
              -123.915853,
              39.881114
            ],
            [
              -123.915142,
              39.875313
            ],
            [
              -123.907664,
              39.863028
            ],
            [
              -123.881458,
              39.845422
            ],
            [
              -123.853764,
              39.8341
            ],
            [
              -123.851714,
              39.832041
            ],
            [
              -123.839797,
              39.795637
            ],
            [
              -123.83715,
              39.776232
            ],
            [
              -123.838089,
              39.752409
            ],
            [
              -123.835092,
              39.738768
            ],
            [
              -123.831599,
              39.730629
            ],
            [
              -123.829545,
              39.723071
            ],
            [
              -123.824744,
              39.718128
            ],
            [
              -123.808208,
              39.710715
            ],
            [
              -123.792659,
              39.684122
            ],
            [
              -123.78636,
              39.659932
            ],
            [
              -123.782322,
              39.621486
            ],
            [
              -123.78354,
              39.609517
            ],
            [
              -123.787417,
              39.604552
            ],
            [
              -123.76721,
              39.559852
            ],
            [
              -123.766475,
              39.552803
            ],
            [
              -123.778521,
              39.521478
            ],
            [
              -123.78417,
              39.509419
            ],
            [
              -123.795639,
              39.492215
            ],
            [
              -123.81469,
              39.446538
            ],
            [
              -123.821887,
              39.406809
            ],
            [
              -123.822325,
              39.379987
            ],
            [
              -123.826306,
              39.36871
            ],
            [
              -123.825331,
              39.360814
            ],
            [
              -123.822085,
              39.343857
            ],
            [
              -123.817369,
              39.3388
            ],
            [
              -123.808772,
              39.324368
            ],
            [
              -123.811387,
              39.312825
            ],
            [
              -123.803081,
              39.291747
            ],
            [
              -123.801757,
              39.28353
            ],
            [
              -123.803848,
              39.278771
            ],
            [
              -123.798991,
              39.271355
            ],
            [
              -123.787893,
              39.264327
            ],
            [
              -123.777368,
              39.237214
            ],
            [
              -123.774998,
              39.212083
            ],
            [
              -123.765891,
              39.193657
            ],
            [
              -123.76101,
              39.191595
            ],
            [
              -123.742221,
              39.164885
            ],
            [
              -123.737913,
              39.143442
            ],
            [
              -123.735936,
              39.139644
            ],
            [
              -123.721505,
              39.125327
            ],
            [
              -123.713392,
              39.108422
            ],
            [
              -123.693969,
              39.057363
            ],
            [
              -123.690095,
              39.031157
            ],
            [
              -123.69074,
              39.021293
            ],
            [
              -123.6969,
              39.004401
            ],
            [
              -123.711149,
              38.977316
            ],
            [
              -123.721347,
              38.963879
            ],
            [
              -123.729053,
              38.956667
            ],
            [
              -123.732892,
              38.954994
            ],
            [
              -123.738886,
              38.95412
            ],
            [
              -123.726315,
              38.936367
            ],
            [
              -123.72763,
              38.9295
            ],
            [
              -123.725367,
              38.917438
            ],
            [
              -123.71054,
              38.91323
            ],
            [
              -123.688099,
              38.893594
            ],
            [
              -123.659846,
              38.872529
            ],
            [
              -123.654696,
              38.865638
            ],
            [
              -123.652212,
              38.854582
            ],
            [
              -123.647387,
              38.845472
            ],
            [
              -123.642676,
              38.844005
            ],
            [
              -123.638637,
              38.843865
            ],
            [
              -123.605317,
              38.822765
            ],
            [
              -123.601569,
              38.81899
            ],
            [
              -123.600221,
              38.814115
            ],
            [
              -123.58638,
              38.802857
            ],
            [
              -123.579856,
              38.802835
            ],
            [
              -123.571987,
              38.798189
            ],
            [
              -123.541837,
              38.776764
            ],
            [
              -123.533535,
              38.768408
            ],
            [
              -123.520961,
              38.758575
            ],
            [
              -123.499155,
              38.778327
            ],
            [
              -123.444084,
              38.776835
            ],
            [
              -123.368236,
              38.777066
            ],
            [
              -123.368394,
              38.80664
            ],
            [
              -123.136253,
              38.809136
            ],
            [
              -123.136636,
              38.839448
            ],
            [
              -123.08123,
              38.838406
            ],
            [
              -123.080997,
              38.852438
            ],
            [
              -122.821592,
              38.85014
            ],
            [
              -122.839994,
              38.866024
            ],
            [
              -122.876495,
              38.87313
            ],
            [
              -122.894633,
              38.880832
            ],
            [
              -122.89389,
              38.895524
            ],
            [
              -122.911386,
              38.901529
            ],
            [
              -122.948865,
              38.90022
            ],
            [
              -122.949067,
              38.925442
            ],
            [
              -122.969919,
              38.932663
            ],
            [
              -122.977712,
              38.946693
            ],
            [
              -122.977429,
              38.9829
            ],
            [
              -122.98664,
              38.997283
            ],
            [
              -123.028035,
              38.996593
            ],
            [
              -123.056212,
              39.021096
            ],
            [
              -123.056115,
              39.048815
            ],
            [
              -123.065114,
              39.064393
            ],
            [
              -123.093013,
              39.071994
            ],
            [
              -123.085012,
              39.095294
            ],
            [
              -123.087512,
              39.138392
            ],
            [
              -123.078112,
              39.13874
            ],
            [
              -123.077812,
              39.173791
            ],
            [
              -123.045811,
              39.173492
            ],
            [
              -123.04011,
              39.19249
            ],
            [
              -123.01251,
              39.220989
            ],
            [
              -123.01271,
              39.236188
            ],
            [
              -122.994839,
              39.235924
            ],
            [
              -122.994484,
              39.256757
            ],
            [
              -123.00361,
              39.257387
            ],
            [
              -123.004514,
              39.278187
            ],
            [
              -123.019612,
              39.277986
            ],
            [
              -123.019711,
              39.321785
            ],
            [
              -123.037712,
              39.336185
            ],
            [
              -123.037914,
              39.363885
            ],
            [
              -123.048613,
              39.364084
            ],
            [
              -123.057113,
              39.378684
            ],
            [
              -123.056314,
              39.407583
            ],
            [
              -123.075114,
              39.407683
            ],
            [
              -123.073115,
              39.452682
            ],
            [
              -123.063015,
              39.452482
            ],
            [
              -123.063181,
              39.503539
            ],
            [
              -123.025236,
              39.518709
            ],
            [
              -122.936697,
              39.516396
            ],
            [
              -122.936696,
              39.530713
            ],
            [
              -122.89031,
              39.529014
            ],
            [
              -122.885352,
              39.580108
            ],
            [
              -122.884497,
              39.610761
            ],
            [
              -122.890413,
              39.622916
            ],
            [
              -122.892676,
              39.708898
            ],
            [
              -122.912876,
              39.708723
            ],
            [
              -122.912516,
              39.748165
            ],
            [
              -122.938413,
              39.748112
            ],
            [
              -122.937654,
              39.798156
            ],
            [
              -122.943602,
              39.801652
            ],
            [
              -122.946419,
              39.832851
            ],
            [
              -122.960147,
              39.845163
            ],
            [
              -122.95399,
              39.854685
            ],
            [
              -122.947732,
              39.894192
            ],
            [
              -122.950077,
              39.907718
            ],
            [
              -122.920203,
              39.917209
            ],
            [
              -122.909787,
              39.936385
            ],
            [
              -122.926821,
              39.945071
            ],
            [
              -122.926897,
              39.971275
            ],
            [
              -122.934013,
              39.978131
            ],
            [
              -123.544563,
              39.977015
            ],
            [
              -123.544458,
              40.001923
            ],
            [
              -124.02393814948901,
              40.0012844214495
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "06",
      "COUNTYFP": "097",
      "COUNTYNS": "01657246",
      "AFFGEOID": "0500000US06097",
      "GEOID": "06097",
      "NAME": "Sonoma",
      "LSAD": "06",
      "ALAND": 4081450561,
      "AWATER": 497495823,
      "County_state": "Sonoma,06"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -123.533535,
              38.768408
            ],
            [
              -123.525152,
              38.753801
            ],
            [
              -123.514784,
              38.741966
            ],
            [
              -123.490021,
              38.732213
            ],
            [
              -123.461291,
              38.717001
            ],
            [
              -123.441774,
              38.699744
            ],
            [
              -123.43272,
              38.687131
            ],
            [
              -123.405663,
              38.656729
            ],
            [
              -123.40301,
              38.649449
            ],
            [
              -123.398166,
              38.647044
            ],
            [
              -123.379303,
              38.621953
            ],
            [
              -123.371876,
              38.607235
            ],
            [
              -123.349612,
              38.596805
            ],
            [
              -123.343338,
              38.590008
            ],
            [
              -123.331899,
              38.565542
            ],
            [
              -123.297151,
              38.543452
            ],
            [
              -123.287156,
              38.540223
            ],
            [
              -123.249797,
              38.511045
            ],
            [
              -123.202277,
              38.494314
            ],
            [
              -123.166428,
              38.474947
            ],
            [
              -123.145325,
              38.459422
            ],
            [
              -123.128825,
              38.450418
            ],
            [
              -123.122379,
              38.437314
            ],
            [
              -123.103706,
              38.415541
            ],
            [
              -123.085572,
              38.390525
            ],
            [
              -123.068265,
              38.359865
            ],
            [
              -123.068437,
              38.33521
            ],
            [
              -123.074684,
              38.322574
            ],
            [
              -123.063671,
              38.302178
            ],
            [
              -123.058239,
              38.298355
            ],
            [
              -123.053504,
              38.299385
            ],
            [
              -123.052021,
              38.302246
            ],
            [
              -123.053476,
              38.305722
            ],
            [
              -123.051061,
              38.310693
            ],
            [
              -123.038742,
              38.313576
            ],
            [
              -123.024333,
              38.310573
            ],
            [
              -123.004122,
              38.297012
            ],
            [
              -123.00291120126201,
              38.2957083549502
            ],
            [
              -122.966124,
              38.31697
            ],
            [
              -122.93506,
              38.313952
            ],
            [
              -122.911319,
              38.321209
            ],
            [
              -122.901726,
              38.316943
            ],
            [
              -122.7399,
              38.207018
            ],
            [
              -122.722995,
              38.207132
            ],
            [
              -122.701393,
              38.196099
            ],
            [
              -122.667158,
              38.189697
            ],
            [
              -122.648986,
              38.181077
            ],
            [
              -122.625408,
              38.182394
            ],
            [
              -122.570732,
              38.187142
            ],
            [
              -122.557916,
              38.160158
            ],
            [
              -122.544992,
              38.158818
            ],
            [
              -122.513144,
              38.132235
            ],
            [
              -122.505707,
              38.115526
            ],
            [
              -122.490726960382,
              38.1097551188534
            ],
            [
              -122.489974,
              38.112014
            ],
            [
              -122.484411,
              38.11496
            ],
            [
              -122.454958,
              38.118887
            ],
            [
              -122.450377,
              38.116269
            ],
            [
              -122.439577,
              38.116923
            ],
            [
              -122.409798,
              38.136231
            ],
            [
              -122.39740598374101,
              38.1417489255618
            ],
            [
              -122.406786,
              38.155632
            ],
            [
              -122.371116,
              38.15772
            ],
            [
              -122.360257,
              38.197115
            ],
            [
              -122.349564,
              38.193972
            ],
            [
              -122.359241,
              38.209989
            ],
            [
              -122.366787,
              38.247015
            ],
            [
              -122.374587,
              38.244515
            ],
            [
              -122.389896,
              38.260695
            ],
            [
              -122.387242,
              38.273253
            ],
            [
              -122.404592,
              38.281423
            ],
            [
              -122.40277,
              38.300549
            ],
            [
              -122.394917,
              38.304479
            ],
            [
              -122.42439,
              38.344413
            ],
            [
              -122.457207,
              38.366993
            ],
            [
              -122.44789,
              38.379312
            ],
            [
              -122.497475,
              38.424306
            ],
            [
              -122.480003,
              38.44303
            ],
            [
              -122.482991,
              38.45271
            ],
            [
              -122.509728,
              38.458254
            ],
            [
              -122.516244,
              38.470872
            ],
            [
              -122.529592,
              38.469609
            ],
            [
              -122.544901,
              38.496285
            ],
            [
              -122.535534,
              38.501391
            ],
            [
              -122.548807,
              38.511627
            ],
            [
              -122.543893,
              38.519966
            ],
            [
              -122.566644,
              38.525543
            ],
            [
              -122.582148,
              38.549399
            ],
            [
              -122.602659,
              38.557496
            ],
            [
              -122.620846,
              38.560317
            ],
            [
              -122.646421,
              38.598593
            ],
            [
              -122.628293,
              38.623038
            ],
            [
              -122.627396,
              38.667506
            ],
            [
              -122.646569,
              38.706177
            ],
            [
              -122.691835,
              38.710946
            ],
            [
              -122.71038,
              38.732082
            ],
            [
              -122.7117,
              38.750763
            ],
            [
              -122.720085,
              38.753545
            ],
            [
              -122.735338,
              38.777888
            ],
            [
              -122.74822,
              38.787795
            ],
            [
              -122.748742,
              38.80419
            ],
            [
              -122.77248,
              38.82153
            ],
            [
              -122.795842,
              38.836838
            ],
            [
              -122.808674,
              38.836643
            ],
            [
              -122.821592,
              38.85014
            ],
            [
              -123.080997,
              38.852438
            ],
            [
              -123.08123,
              38.838406
            ],
            [
              -123.136636,
              38.839448
            ],
            [
              -123.136253,
              38.809136
            ],
            [
              -123.368394,
              38.80664
            ],
            [
              -123.368236,
              38.777066
            ],
            [
              -123.444084,
              38.776835
            ],
            [
              -123.499155,
              38.778327
            ],
            [
              -123.520961,
              38.758575
            ],
            [
              -123.533535,
              38.768408
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "06",
      "COUNTYFP": "115",
      "COUNTYNS": "00277322",
      "AFFGEOID": "0500000US06115",
      "GEOID": "06115",
      "NAME": "Yuba",
      "LSAD": "06",
      "ALAND": 1636913850,
      "AWATER": 31057914,
      "County_state": "Yuba,06"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -121.635779,
              39.261718
            ],
            [
              -121.636368,
              39.246076
            ],
            [
              -121.628176,
              39.228986
            ],
            [
              -121.614307,
              39.228745
            ],
            [
              -121.621221,
              39.206066
            ],
            [
              -121.629089,
              39.204678
            ],
            [
              -121.613,
              39.190304
            ],
            [
              -121.618452,
              39.182748
            ],
            [
              -121.597676,
              39.127792
            ],
            [
              -121.602678,
              39.100158
            ],
            [
              -121.587292,
              39.101718
            ],
            [
              -121.58591,
              39.089691
            ],
            [
              -121.610138,
              39.057847
            ],
            [
              -121.605634,
              39.032628
            ],
            [
              -121.594618,
              39.023708
            ],
            [
              -121.59927,
              39.011701
            ],
            [
              -121.579095,
              39.006692
            ],
            [
              -121.589399,
              38.990344
            ],
            [
              -121.577961,
              38.946771
            ],
            [
              -121.582183,
              38.93565
            ],
            [
              -121.575415,
              38.918348
            ],
            [
              -121.571297,
              38.938918
            ],
            [
              -121.553518,
              38.953755
            ],
            [
              -121.543015,
              38.972409
            ],
            [
              -121.524653,
              38.971473
            ],
            [
              -121.489646,
              38.99205
            ],
            [
              -121.456315,
              38.99819
            ],
            [
              -121.443826,
              38.993325
            ],
            [
              -121.414779,
              38.996452
            ],
            [
              -121.365643,
              39.031743
            ],
            [
              -121.332135,
              39.042021
            ],
            [
              -121.305992,
              39.052943
            ],
            [
              -121.279533,
              39.034618
            ],
            [
              -121.27953,
              39.230537
            ],
            [
              -121.259182,
              39.256421
            ],
            [
              -121.266132,
              39.272717
            ],
            [
              -121.240146,
              39.283997
            ],
            [
              -121.220979,
              39.282573
            ],
            [
              -121.200146,
              39.302375
            ],
            [
              -121.206352,
              39.316469
            ],
            [
              -121.178977,
              39.33856
            ],
            [
              -121.156588,
              39.334149
            ],
            [
              -121.154766,
              39.348605
            ],
            [
              -121.127481,
              39.380237
            ],
            [
              -121.100942,
              39.381246
            ],
            [
              -121.084002,
              39.394624
            ],
            [
              -121.048146,
              39.394934
            ],
            [
              -121.041087,
              39.388244
            ],
            [
              -121.022085,
              39.391558
            ],
            [
              -121.034406,
              39.514509
            ],
            [
              -121.048743,
              39.51702
            ],
            [
              -121.058203,
              39.537043
            ],
            [
              -121.047454,
              39.548657
            ],
            [
              -121.024383,
              39.559049
            ],
            [
              -121.009477,
              39.639459
            ],
            [
              -121.076695,
              39.597264
            ],
            [
              -121.079543,
              39.585263
            ],
            [
              -121.107465,
              39.585275
            ],
            [
              -121.10697,
              39.567191
            ],
            [
              -121.141213,
              39.562278
            ],
            [
              -121.149898,
              39.555289
            ],
            [
              -121.149907,
              39.526446
            ],
            [
              -121.159296,
              39.519595
            ],
            [
              -121.185173,
              39.526695
            ],
            [
              -121.240483,
              39.526613
            ],
            [
              -121.240242,
              39.513091
            ],
            [
              -121.277077,
              39.512812
            ],
            [
              -121.276789,
              39.520271
            ],
            [
              -121.305122,
              39.519585
            ],
            [
              -121.305425,
              39.497816
            ],
            [
              -121.314787,
              39.475899
            ],
            [
              -121.338326,
              39.461178
            ],
            [
              -121.345338,
              39.438923
            ],
            [
              -121.33442,
              39.425212
            ],
            [
              -121.36008,
              39.398719
            ],
            [
              -121.372901,
              39.36459
            ],
            [
              -121.382747,
              39.36276
            ],
            [
              -121.407527,
              39.339754
            ],
            [
              -121.434813,
              39.337482
            ],
            [
              -121.447014,
              39.330568
            ],
            [
              -121.474964,
              39.333341
            ],
            [
              -121.486673,
              39.317419
            ],
            [
              -121.531636,
              39.317891
            ],
            [
              -121.56314,
              39.305006
            ],
            [
              -121.594183,
              39.307835
            ],
            [
              -121.611855,
              39.318982
            ],
            [
              -121.62376,
              39.295621
            ],
            [
              -121.63156,
              39.291758
            ],
            [
              -121.621755,
              39.262825
            ],
            [
              -121.635779,
              39.261718
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "06",
      "COUNTYFP": "047",
      "COUNTYNS": "00277288",
      "AFFGEOID": "0500000US06047",
      "GEOID": "06047",
      "NAME": "Merced",
      "LSAD": "06",
      "ALAND": 5012175320,
      "AWATER": 112509475,
      "County_state": "Merced,06"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -121.245989,
              37.025575
            ],
            [
              -121.233337,
              37.011746
            ],
            [
              -121.233137,
              36.999346
            ],
            [
              -121.245887,
              36.983036
            ],
            [
              -121.215406,
              36.961248
            ],
            [
              -121.234683,
              36.926894
            ],
            [
              -121.221713,
              36.910362
            ],
            [
              -121.198274,
              36.914338
            ],
            [
              -121.183885,
              36.908096
            ],
            [
              -121.184297,
              36.896385
            ],
            [
              -121.169495,
              36.885322
            ],
            [
              -121.163588,
              36.869245
            ],
            [
              -121.152808,
              36.865601
            ],
            [
              -121.141523,
              36.836656
            ],
            [
              -120.933682,
              36.751895
            ],
            [
              -120.918731,
              36.740381
            ],
            [
              -120.65595,
              36.952832
            ],
            [
              -120.590566,
              36.952641
            ],
            [
              -120.590483,
              36.981642
            ],
            [
              -120.585359,
              36.991176
            ],
            [
              -120.598564,
              36.99863
            ],
            [
              -120.541696,
              37.044505
            ],
            [
              -120.476692,
              37.09639
            ],
            [
              -120.406777,
              37.110854
            ],
            [
              -120.384886,
              37.124589
            ],
            [
              -120.363315,
              37.119712
            ],
            [
              -120.341204,
              37.124596
            ],
            [
              -120.324544,
              37.136382
            ],
            [
              -120.303757,
              37.141093
            ],
            [
              -120.289451,
              37.152685
            ],
            [
              -120.276096,
              37.152211
            ],
            [
              -120.227286,
              37.1634
            ],
            [
              -120.164576,
              37.162698
            ],
            [
              -120.106385,
              37.167153
            ],
            [
              -120.096649,
              37.171959
            ],
            [
              -120.052055,
              37.183108
            ],
            [
              -120.062214,
              37.197984
            ],
            [
              -120.085878,
              37.218743
            ],
            [
              -120.143826,
              37.239192
            ],
            [
              -120.177636,
              37.261526
            ],
            [
              -120.186637,
              37.28028
            ],
            [
              -120.182724,
              37.291405
            ],
            [
              -120.203531,
              37.313321
            ],
            [
              -120.216505,
              37.337336
            ],
            [
              -120.26601,
              37.400607
            ],
            [
              -120.284428,
              37.431685
            ],
            [
              -120.274965,
              37.443634
            ],
            [
              -120.279579,
              37.457947
            ],
            [
              -120.299092,
              37.488837
            ],
            [
              -120.311493,
              37.497228
            ],
            [
              -120.318623,
              37.520434
            ],
            [
              -120.38767,
              37.633364
            ],
            [
              -120.81557,
              37.466963
            ],
            [
              -120.886429,
              37.44018
            ],
            [
              -120.983756,
              37.39964
            ],
            [
              -120.99296,
              37.385324
            ],
            [
              -120.976121,
              37.377552
            ],
            [
              -120.976465,
              37.349616
            ],
            [
              -120.963812,
              37.346144
            ],
            [
              -121.06634,
              37.262939
            ],
            [
              -121.226804,
              37.134774
            ],
            [
              -121.217339,
              37.123042
            ],
            [
              -121.230439,
              37.096942
            ],
            [
              -121.245384,
              37.089501
            ],
            [
              -121.209637,
              37.068243
            ],
            [
              -121.208198,
              37.061289
            ],
            [
              -121.223387,
              37.057507
            ],
            [
              -121.224507,
              37.039743
            ],
            [
              -121.245989,
              37.025575
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "06",
      "COUNTYFP": "107",
      "COUNTYNS": "00277318",
      "AFFGEOID": "0500000US06107",
      "GEOID": "06107",
      "NAME": "Tulare",
      "LSAD": "06",
      "ALAND": 12494838453,
      "AWATER": 37259234,
      "County_state": "Tulare,06"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -119.546131,
              36.508963
            ],
            [
              -119.573194,
              36.488835
            ],
            [
              -119.527194,
              36.488886
            ],
            [
              -119.528792,
              36.401465
            ],
            [
              -119.474892,
              36.400953
            ],
            [
              -119.474367,
              36.297889
            ],
            [
              -119.474607,
              36.269025
            ],
            [
              -119.529358,
              36.26985
            ],
            [
              -119.53638,
              36.137893
            ],
            [
              -119.536161,
              36.050782
            ],
            [
              -119.536175,
              35.963415
            ],
            [
              -119.538116,
              35.789567
            ],
            [
              -119.053437,
              35.790748
            ],
            [
              -118.803729,
              35.790347
            ],
            [
              -118.507224,
              35.789711
            ],
            [
              -118.464791,
              35.792637
            ],
            [
              -118.375916,
              35.790224
            ],
            [
              -118.195464,
              35.790689
            ],
            [
              -118.067719,
              35.791537
            ],
            [
              -118.008043,
              35.789161
            ],
            [
              -118.005489,
              35.862699
            ],
            [
              -117.980761,
              35.867515
            ],
            [
              -117.988802,
              35.879769
            ],
            [
              -117.982473,
              35.895796
            ],
            [
              -117.990046,
              35.915919
            ],
            [
              -117.983287,
              35.926563
            ],
            [
              -117.991944,
              35.943771
            ],
            [
              -118.006882,
              35.946483
            ],
            [
              -118.01824,
              35.960977
            ],
            [
              -118.010095,
              35.977617
            ],
            [
              -118.008004,
              35.977851
            ],
            [
              -118.005298,
              35.980508
            ],
            [
              -118.006854,
              35.989901
            ],
            [
              -118.020611,
              36.004044
            ],
            [
              -118.033611,
              36.008944
            ],
            [
              -118.036012,
              36.019544
            ],
            [
              -118.039012,
              36.029844
            ],
            [
              -118.040012,
              36.034643
            ],
            [
              -118.045912,
              36.043643
            ],
            [
              -118.043681,
              36.049612
            ],
            [
              -118.048012,
              36.056143
            ],
            [
              -118.051113,
              36.068543
            ],
            [
              -118.050613,
              36.079542
            ],
            [
              -118.057413,
              36.090942
            ],
            [
              -118.066913,
              36.097742
            ],
            [
              -118.065614,
              36.112842
            ],
            [
              -118.070914,
              36.128641
            ],
            [
              -118.073514,
              36.138441
            ],
            [
              -118.065214,
              36.147741
            ],
            [
              -118.059314,
              36.158741
            ],
            [
              -118.060515,
              36.16844
            ],
            [
              -118.067115,
              36.17714
            ],
            [
              -118.073715,
              36.18944
            ],
            [
              -118.083115,
              36.19554
            ],
            [
              -118.092615,
              36.20184
            ],
            [
              -118.101916,
              36.208839
            ],
            [
              -118.107416,
              36.222139
            ],
            [
              -118.105516,
              36.231239
            ],
            [
              -118.112916,
              36.241539
            ],
            [
              -118.118004,
              36.2528
            ],
            [
              -118.119739,
              36.273087
            ],
            [
              -118.127485,
              36.282329
            ],
            [
              -118.125361,
              36.290503
            ],
            [
              -118.125917,
              36.301063
            ],
            [
              -118.114577,
              36.306984
            ],
            [
              -118.112517,
              36.322037
            ],
            [
              -118.104455,
              36.325669
            ],
            [
              -118.099817,
              36.333437
            ],
            [
              -118.101817,
              36.339337
            ],
            [
              -118.110718,
              36.348937
            ],
            [
              -118.123218,
              36.353337
            ],
            [
              -118.129918,
              36.365837
            ],
            [
              -118.142503,
              36.374936
            ],
            [
              -118.154118,
              36.382536
            ],
            [
              -118.157119,
              36.388336
            ],
            [
              -118.151419,
              36.397336
            ],
            [
              -118.142619,
              36.401636
            ],
            [
              -118.139019,
              36.410836
            ],
            [
              -118.143319,
              36.421236
            ],
            [
              -118.151919,
              36.425536
            ],
            [
              -118.155619,
              36.429436
            ],
            [
              -118.166719,
              36.429636
            ],
            [
              -118.174819,
              36.426736
            ],
            [
              -118.188019,
              36.427436
            ],
            [
              -118.203719,
              36.431236
            ],
            [
              -118.21182,
              36.432236
            ],
            [
              -118.21212,
              36.437036
            ],
            [
              -118.20962,
              36.442136
            ],
            [
              -118.215162,
              36.453851
            ],
            [
              -118.22502,
              36.463935
            ],
            [
              -118.23592,
              36.472835
            ],
            [
              -118.24512,
              36.479835
            ],
            [
              -118.24482,
              36.489035
            ],
            [
              -118.23672,
              36.495635
            ],
            [
              -118.239121,
              36.504035
            ],
            [
              -118.239021,
              36.513035
            ],
            [
              -118.239021,
              36.518335
            ],
            [
              -118.244021,
              36.522035
            ],
            [
              -118.247721,
              36.523135
            ],
            [
              -118.250922,
              36.528335
            ],
            [
              -118.249022,
              36.533735
            ],
            [
              -118.251922,
              36.540135
            ],
            [
              -118.262822,
              36.548935
            ],
            [
              -118.276123,
              36.551835
            ],
            [
              -118.291223,
              36.559335
            ],
            [
              -118.292624,
              36.566835
            ],
            [
              -118.290224,
              36.572435
            ],
            [
              -118.292524,
              36.579035
            ],
            [
              -118.289224,
              36.588034
            ],
            [
              -118.281724,
              36.591334
            ],
            [
              -118.279224,
              36.601534
            ],
            [
              -118.287525,
              36.603334
            ],
            [
              -118.298825,
              36.611634
            ],
            [
              -118.308625,
              36.620934
            ],
            [
              -118.321026,
              36.628034
            ],
            [
              -118.320226,
              36.639734
            ],
            [
              -118.328126,
              36.648034
            ],
            [
              -118.333527,
              36.651834
            ],
            [
              -118.337627,
              36.657834
            ],
            [
              -118.333727,
              36.662134
            ],
            [
              -118.341922,
              36.672605
            ],
            [
              -118.352028,
              36.682934
            ],
            [
              -118.365628,
              36.689835
            ],
            [
              -118.366253,
              36.69171
            ],
            [
              -118.334728,
              36.705534
            ],
            [
              -118.339629,
              36.729034
            ],
            [
              -118.360586,
              36.744773
            ],
            [
              -118.555487,
              36.744746
            ],
            [
              -118.982441,
              36.741646
            ],
            [
              -118.984779,
              36.657147
            ],
            [
              -119.088295,
              36.657514
            ],
            [
              -119.174791,
              36.658878
            ],
            [
              -119.25095,
              36.661214
            ],
            [
              -119.304625,
              36.660606
            ],
            [
              -119.305102,
              36.573725
            ],
            [
              -119.466322,
              36.575238
            ],
            [
              -119.489911,
              36.556724
            ],
            [
              -119.546131,
              36.508963
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "06",
      "COUNTYFP": "067",
      "COUNTYNS": "00277298",
      "AFFGEOID": "0500000US06067",
      "GEOID": "06067",
      "NAME": "Sacramento",
      "LSAD": "06",
      "ALAND": 2499039655,
      "AWATER": 76217822,
      "County_state": "Sacramento,06"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -121.843228,
              38.076655
            ],
            [
              -121.862462,
              38.06603
            ],
            [
              -121.841659,
              38.051974
            ],
            [
              -121.832122,
              38.031101
            ],
            [
              -121.815673,
              38.021038
            ],
            [
              -121.80127,
              38.021622
            ],
            [
              -121.788773,
              38.018421
            ],
            [
              -121.737824,
              38.02663
            ],
            [
              -121.699765,
              38.04522
            ],
            [
              -121.68147,
              38.061244
            ],
            [
              -121.681865,
              38.082019
            ],
            [
              -121.673065,
              38.093518
            ],
            [
              -121.661865,
              38.095618
            ],
            [
              -121.638088,
              38.086131
            ],
            [
              -121.627376,
              38.09836
            ],
            [
              -121.604923,
              38.099878
            ],
            [
              -121.580022,
              38.094414
            ],
            [
              -121.565751,
              38.105279
            ],
            [
              -121.584074,
              38.120228
            ],
            [
              -121.558088,
              38.136495
            ],
            [
              -121.558216,
              38.144302
            ],
            [
              -121.536023,
              38.152014
            ],
            [
              -121.524614,
              38.174844
            ],
            [
              -121.529152,
              38.193828
            ],
            [
              -121.504303,
              38.212579
            ],
            [
              -121.507487,
              38.222563
            ],
            [
              -121.491669,
              38.22769
            ],
            [
              -121.48007,
              38.253576
            ],
            [
              -121.4614,
              38.259157
            ],
            [
              -121.448142,
              38.255008
            ],
            [
              -121.439911,
              38.255531
            ],
            [
              -121.429097,
              38.254673
            ],
            [
              -121.411005,
              38.231934
            ],
            [
              -121.344024,
              38.22826
            ],
            [
              -121.293719,
              38.238387
            ],
            [
              -121.282267,
              38.249836
            ],
            [
              -121.272822,
              38.250782
            ],
            [
              -121.269228,
              38.252089
            ],
            [
              -121.247584,
              38.245426
            ],
            [
              -121.223537,
              38.244463
            ],
            [
              -121.194137,
              38.255749
            ],
            [
              -121.173079,
              38.255208
            ],
            [
              -121.147014,
              38.263905
            ],
            [
              -121.127782,
              38.277447
            ],
            [
              -121.09544,
              38.281633
            ],
            [
              -121.064783,
              38.29947
            ],
            [
              -121.046751,
              38.291426
            ],
            [
              -121.027084,
              38.300252
            ],
            [
              -121.027507,
              38.508292
            ],
            [
              -121.0793,
              38.632857
            ],
            [
              -121.118764,
              38.716952
            ],
            [
              -121.133264,
              38.705427
            ],
            [
              -121.141009,
              38.71198
            ],
            [
              -121.151857,
              38.711502
            ],
            [
              -121.216864,
              38.71621
            ],
            [
              -121.242795,
              38.718241
            ],
            [
              -121.30883,
              38.72279
            ],
            [
              -121.381658,
              38.727796
            ],
            [
              -121.484396,
              38.734598
            ],
            [
              -121.489459,
              38.734985
            ],
            [
              -121.559868,
              38.736302
            ],
            [
              -121.567515,
              38.735789
            ],
            [
              -121.602894,
              38.735838
            ],
            [
              -121.605774,
              38.718319
            ],
            [
              -121.633774,
              38.686164
            ],
            [
              -121.594069,
              38.644106
            ],
            [
              -121.5651,
              38.642304
            ],
            [
              -121.553609,
              38.603326
            ],
            [
              -121.511566,
              38.600904
            ],
            [
              -121.506269,
              38.586305
            ],
            [
              -121.520965,
              38.566205
            ],
            [
              -121.512618,
              38.541666
            ],
            [
              -121.528467,
              38.532106
            ],
            [
              -121.525178,
              38.518962
            ],
            [
              -121.551866,
              38.513806
            ],
            [
              -121.558069,
              38.501876
            ],
            [
              -121.543083,
              38.477356
            ],
            [
              -121.50509,
              38.469563
            ],
            [
              -121.502982,
              38.440718
            ],
            [
              -121.532425,
              38.429316
            ],
            [
              -121.516506,
              38.407239
            ],
            [
              -121.521641,
              38.360104
            ],
            [
              -121.560025,
              38.342071
            ],
            [
              -121.575216,
              38.327161
            ],
            [
              -121.583954,
              38.331652
            ],
            [
              -121.593273,
              38.313089
            ],
            [
              -121.605258,
              38.295352
            ],
            [
              -121.602443,
              38.22041
            ],
            [
              -121.615413,
              38.195696
            ],
            [
              -121.631876,
              38.194369
            ],
            [
              -121.662907,
              38.181869
            ],
            [
              -121.685645,
              38.159644
            ],
            [
              -121.692095,
              38.133126
            ],
            [
              -121.706488,
              38.111858
            ],
            [
              -121.71213,
              38.08552
            ],
            [
              -121.73182,
              38.082634
            ],
            [
              -121.743164,
              38.087229
            ],
            [
              -121.796395,
              38.060847
            ],
            [
              -121.827916,
              38.066223
            ],
            [
              -121.843228,
              38.076655
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "06",
      "COUNTYFP": "095",
      "COUNTYNS": "00277312",
      "AFFGEOID": "0500000US06095",
      "GEOID": "06095",
      "NAME": "Solano",
      "LSAD": "06",
      "ALAND": 2128347201,
      "AWATER": 218679972,
      "County_state": "Solano,06"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -122.406786,
              38.155632
            ],
            [
              -122.39740598374101,
              38.1417489255618
            ],
            [
              -122.393588,
              38.143449
            ],
            [
              -122.36876,
              38.135646
            ],
            [
              -122.317293,
              38.112038
            ],
            [
              -122.301804,
              38.105142
            ],
            [
              -122.282824,
              38.082889
            ],
            [
              -122.273006,
              38.07438
            ],
            [
              -122.26666850460501,
              38.0600701915224
            ],
            [
              -122.245251,
              38.063969
            ],
            [
              -122.183863,
              38.053997
            ],
            [
              -122.167577,
              38.036721
            ],
            [
              -122.14312,
              38.031761
            ],
            [
              -122.099776,
              38.047008
            ],
            [
              -122.095733,
              38.048355
            ],
            [
              -122.061103,
              38.062137
            ],
            [
              -122.050386,
              38.060198
            ],
            [
              -122.028411,
              38.05919
            ],
            [
              -121.983158,
              38.067297
            ],
            [
              -121.925814,
              38.046474
            ],
            [
              -121.889573,
              38.046657
            ],
            [
              -121.869582,
              38.054414
            ],
            [
              -121.862462,
              38.06603
            ],
            [
              -121.843228,
              38.076655
            ],
            [
              -121.827916,
              38.066223
            ],
            [
              -121.796395,
              38.060847
            ],
            [
              -121.743164,
              38.087229
            ],
            [
              -121.73182,
              38.082634
            ],
            [
              -121.71213,
              38.08552
            ],
            [
              -121.706488,
              38.111858
            ],
            [
              -121.692095,
              38.133126
            ],
            [
              -121.685645,
              38.159644
            ],
            [
              -121.662907,
              38.181869
            ],
            [
              -121.631876,
              38.194369
            ],
            [
              -121.615413,
              38.195696
            ],
            [
              -121.602443,
              38.22041
            ],
            [
              -121.605258,
              38.295352
            ],
            [
              -121.593273,
              38.313089
            ],
            [
              -121.690544,
              38.313731
            ],
            [
              -121.693969,
              38.314454
            ],
            [
              -121.694971,
              38.527012
            ],
            [
              -121.712034,
              38.537962
            ],
            [
              -121.738594,
              38.537704
            ],
            [
              -121.764717,
              38.525917
            ],
            [
              -121.770676,
              38.532229
            ],
            [
              -121.785811,
              38.523074
            ],
            [
              -121.81333,
              38.526023
            ],
            [
              -121.86012,
              38.538512
            ],
            [
              -121.888421,
              38.537819
            ],
            [
              -121.908969,
              38.529576
            ],
            [
              -121.940285,
              38.533384
            ],
            [
              -121.968048,
              38.519989
            ],
            [
              -121.978068,
              38.508277
            ],
            [
              -122.013901,
              38.488728
            ],
            [
              -122.03247,
              38.494304
            ],
            [
              -122.05694,
              38.517269
            ],
            [
              -122.103281,
              38.513348
            ],
            [
              -122.104882,
              38.490807
            ],
            [
              -122.123582,
              38.447608
            ],
            [
              -122.126389,
              38.428918
            ],
            [
              -122.117983,
              38.414109
            ],
            [
              -122.089164,
              38.384662
            ],
            [
              -122.07338,
              38.360911
            ],
            [
              -122.061379,
              38.329611
            ],
            [
              -122.064779,
              38.315912
            ],
            [
              -122.133843,
              38.315612
            ],
            [
              -122.205982,
              38.315713
            ],
            [
              -122.203482,
              38.292914
            ],
            [
              -122.188381,
              38.271614
            ],
            [
              -122.212282,
              38.272814
            ],
            [
              -122.216882,
              38.262315
            ],
            [
              -122.196781,
              38.258415
            ],
            [
              -122.211081,
              38.252215
            ],
            [
              -122.19598,
              38.23041
            ],
            [
              -122.19398,
              38.213614
            ],
            [
              -122.205681,
              38.208216
            ],
            [
              -122.21508,
              38.179717
            ],
            [
              -122.199064,
              38.168727
            ],
            [
              -122.195971,
              38.162991
            ],
            [
              -122.19538,
              38.155017
            ],
            [
              -122.406786,
              38.155632
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "06",
      "COUNTYFP": "075",
      "COUNTYNS": "00277302",
      "AFFGEOID": "0500000US06075",
      "GEOID": "06075",
      "NAME": "San Francisco",
      "LSAD": "06",
      "ALAND": 121485107,
      "AWATER": 479107241,
      "County_state": "San Francisco,06"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -122.330962783411,
              37.7860343411807
            ],
            [
              -122.33079,
              37.78383
            ],
            [
              -122.32915929691902,
              37.783172558054304
            ],
            [
              -122.330962783411,
              37.7860343411807
            ]
          ]
        ],
        [
          [
            [
              -122.377879,
              37.830648
            ],
            [
              -122.3785,
              37.826505
            ],
            [
              -122.37267,
              37.81651
            ],
            [
              -122.372422,
              37.811301
            ],
            [
              -122.362661,
              37.807577
            ],
            [
              -122.358779,
              37.814278
            ],
            [
              -122.363244,
              37.823951
            ],
            [
              -122.369941,
              37.832137
            ],
            [
              -122.377879,
              37.830648
            ]
          ]
        ],
        [
          [
            [
              -122.514483,
              37.780829
            ],
            [
              -122.511983,
              37.77113
            ],
            [
              -122.507844510976,
              37.735464501593995
            ],
            [
              -122.506483,
              37.723731
            ],
            [
              -122.502426880724,
              37.7081329861058
            ],
            [
              -122.420082,
              37.708231
            ],
            [
              -122.405292,
              37.708262
            ],
            [
              -122.391375121004,
              37.708331
            ],
            [
              -122.370411,
              37.717572
            ],
            [
              -122.361749,
              37.71501
            ],
            [
              -122.356784,
              37.729505
            ],
            [
              -122.365478,
              37.734621
            ],
            [
              -122.367697,
              37.734943
            ],
            [
              -122.370094,
              37.732331
            ],
            [
              -122.375854,
              37.734979
            ],
            [
              -122.385323,
              37.790724
            ],
            [
              -122.398139,
              37.80563
            ],
            [
              -122.407452,
              37.811441
            ],
            [
              -122.42504116215702,
              37.811001508766
            ],
            [
              -122.425942,
              37.810979
            ],
            [
              -122.463793,
              37.804653
            ],
            [
              -122.470336,
              37.808671
            ],
            [
              -122.478083,
              37.810828
            ],
            [
              -122.485783,
              37.790629
            ],
            [
              -122.492883,
              37.787929
            ],
            [
              -122.50531,
              37.788312
            ],
            [
              -122.514483,
              37.780829
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "06",
      "COUNTYFP": "043",
      "COUNTYNS": "00277286",
      "AFFGEOID": "0500000US06043",
      "GEOID": "06043",
      "NAME": "Mariposa",
      "LSAD": "06",
      "ALAND": 3752472156,
      "AWATER": 36225644,
      "County_state": "Mariposa,06"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -120.39398,
              37.675366
            ],
            [
              -120.39397,
              37.668878
            ],
            [
              -120.389444,
              37.664349
            ],
            [
              -120.384934,
              37.658941
            ],
            [
              -120.37939,
              37.656795
            ],
            [
              -120.376194,
              37.650563
            ],
            [
              -120.37777,
              37.646851
            ],
            [
              -120.381709,
              37.641801
            ],
            [
              -120.383482,
              37.638721
            ],
            [
              -120.3862,
              37.634572
            ],
            [
              -120.387613,
              37.633704
            ],
            [
              -120.38767,
              37.633364
            ],
            [
              -120.318623,
              37.520434
            ],
            [
              -120.311493,
              37.497228
            ],
            [
              -120.299092,
              37.488837
            ],
            [
              -120.279579,
              37.457947
            ],
            [
              -120.274965,
              37.443634
            ],
            [
              -120.284428,
              37.431685
            ],
            [
              -120.26601,
              37.400607
            ],
            [
              -120.216505,
              37.337336
            ],
            [
              -120.203531,
              37.313321
            ],
            [
              -120.182724,
              37.291405
            ],
            [
              -120.186637,
              37.28028
            ],
            [
              -120.177636,
              37.261526
            ],
            [
              -120.143826,
              37.239192
            ],
            [
              -120.085878,
              37.218743
            ],
            [
              -120.062214,
              37.197984
            ],
            [
              -120.052055,
              37.183108
            ],
            [
              -119.761809,
              37.417114
            ],
            [
              -119.651191,
              37.417832
            ],
            [
              -119.651172,
              37.461351
            ],
            [
              -119.615495,
              37.461522
            ],
            [
              -119.615148,
              37.494681
            ],
            [
              -119.58422,
              37.494696
            ],
            [
              -119.583585,
              37.560335
            ],
            [
              -119.308995,
              37.777986
            ],
            [
              -119.309984,
              37.778428
            ],
            [
              -119.310384,
              37.790528
            ],
            [
              -119.325645,
              37.798436
            ],
            [
              -119.347787,
              37.809027
            ],
            [
              -119.366697,
              37.830977
            ],
            [
              -119.37391,
              37.837378
            ],
            [
              -119.377071,
              37.839684
            ],
            [
              -119.380902,
              37.839442
            ],
            [
              -119.389227,
              37.837858
            ],
            [
              -119.406091,
              37.850324
            ],
            [
              -119.423892,
              37.862624
            ],
            [
              -119.429307,
              37.866238
            ],
            [
              -119.445793,
              37.869423
            ],
            [
              -119.471038,
              37.861909
            ],
            [
              -119.491691,
              37.863318
            ],
            [
              -119.506597,
              37.874522
            ],
            [
              -119.530298,
              37.892922
            ],
            [
              -119.5567,
              37.900922
            ],
            [
              -119.5741,
              37.895622
            ],
            [
              -119.584864,
              37.874922
            ],
            [
              -119.593223,
              37.865625
            ],
            [
              -119.605255,
              37.857656
            ],
            [
              -119.617922,
              37.853553
            ],
            [
              -119.626002,
              37.848152
            ],
            [
              -119.637661,
              37.849035
            ],
            [
              -119.652403,
              37.838923
            ],
            [
              -119.651103,
              37.819823
            ],
            [
              -119.693204,
              37.796924
            ],
            [
              -119.731906,
              37.787424
            ],
            [
              -119.749407,
              37.775724
            ],
            [
              -119.759795,
              37.772474
            ],
            [
              -119.766706,
              37.770727
            ],
            [
              -119.772407,
              37.766727
            ],
            [
              -119.780108,
              37.763925
            ],
            [
              -119.794922,
              37.762619
            ],
            [
              -119.803975,
              37.759684
            ],
            [
              -119.805408,
              37.757427
            ],
            [
              -119.811409,
              37.757025
            ],
            [
              -119.82141,
              37.760225
            ],
            [
              -119.827514,
              37.765787
            ],
            [
              -119.835609,
              37.769626
            ],
            [
              -119.841648,
              37.766481
            ],
            [
              -119.845811,
              37.762625
            ],
            [
              -119.853911,
              37.761226
            ],
            [
              -119.860011,
              37.764126
            ],
            [
              -119.863111,
              37.769525
            ],
            [
              -119.867912,
              37.772425
            ],
            [
              -119.873113,
              37.769426
            ],
            [
              -119.875968,
              37.764038
            ],
            [
              -119.889971,
              37.761255
            ],
            [
              -119.895736,
              37.761367
            ],
            [
              -119.902413,
              37.757926
            ],
            [
              -119.912513,
              37.761026
            ],
            [
              -119.918013,
              37.762226
            ],
            [
              -119.92594,
              37.764299
            ],
            [
              -119.930414,
              37.763726
            ],
            [
              -119.938414,
              37.763026
            ],
            [
              -119.956915,
              37.774826
            ],
            [
              -119.975616,
              37.786826
            ],
            [
              -120.000016,
              37.797626
            ],
            [
              -120.007726,
              37.802001
            ],
            [
              -120.015724,
              37.805668
            ],
            [
              -120.025008,
              37.811318
            ],
            [
              -120.034101,
              37.809427
            ],
            [
              -120.039977,
              37.810984
            ],
            [
              -120.048174,
              37.812038
            ],
            [
              -120.052792,
              37.812872
            ],
            [
              -120.055099,
              37.816264
            ],
            [
              -120.057004,
              37.820842
            ],
            [
              -120.05911,
              37.824865
            ],
            [
              -120.060778,
              37.825552
            ],
            [
              -120.063102,
              37.824416
            ],
            [
              -120.064302,
              37.823315
            ],
            [
              -120.06842,
              37.824915
            ],
            [
              -120.073361,
              37.827031
            ],
            [
              -120.081737,
              37.828114
            ],
            [
              -120.084895,
              37.826987
            ],
            [
              -120.086748,
              37.823099
            ],
            [
              -120.08924,
              37.822859
            ],
            [
              -120.090107,
              37.820294
            ],
            [
              -120.090625,
              37.817264
            ],
            [
              -120.089328,
              37.8129
            ],
            [
              -120.092645,
              37.810159
            ],
            [
              -120.096651,
              37.807941
            ],
            [
              -120.099774,
              37.803798
            ],
            [
              -120.101344,
              37.801929
            ],
            [
              -120.106357,
              37.798577
            ],
            [
              -120.110564,
              37.795145
            ],
            [
              -120.113941,
              37.793839
            ],
            [
              -120.118545,
              37.789828
            ],
            [
              -120.120127,
              37.786986
            ],
            [
              -120.120347,
              37.785792
            ],
            [
              -120.125557,
              37.783484
            ],
            [
              -120.126828,
              37.781781
            ],
            [
              -120.1311,
              37.781818
            ],
            [
              -120.134709,
              37.782177
            ],
            [
              -120.137564,
              37.782583
            ],
            [
              -120.142198,
              37.783106
            ],
            [
              -120.143885,
              37.784498
            ],
            [
              -120.146408,
              37.785621
            ],
            [
              -120.147997,
              37.786695
            ],
            [
              -120.150713,
              37.787338
            ],
            [
              -120.153489,
              37.788148
            ],
            [
              -120.156332,
              37.787832
            ],
            [
              -120.15891,
              37.787859
            ],
            [
              -120.161587,
              37.787332
            ],
            [
              -120.163961,
              37.788896
            ],
            [
              -120.167366,
              37.790534
            ],
            [
              -120.169324,
              37.79276
            ],
            [
              -120.171233,
              37.794631
            ],
            [
              -120.172633,
              37.794947
            ],
            [
              -120.173441,
              37.79652
            ],
            [
              -120.172967,
              37.797604
            ],
            [
              -120.173351,
              37.798647
            ],
            [
              -120.175645,
              37.798384
            ],
            [
              -120.176755,
              37.796995
            ],
            [
              -120.177332,
              37.795586
            ],
            [
              -120.17994,
              37.795203
            ],
            [
              -120.18113,
              37.795355
            ],
            [
              -120.182686,
              37.794758
            ],
            [
              -120.182946,
              37.793092
            ],
            [
              -120.182036,
              37.79097
            ],
            [
              -120.180596,
              37.789328
            ],
            [
              -120.181345,
              37.78785
            ],
            [
              -120.182352,
              37.784155
            ],
            [
              -120.184231,
              37.782873
            ],
            [
              -120.18565,
              37.781499
            ],
            [
              -120.187155,
              37.780889
            ],
            [
              -120.186192,
              37.77781
            ],
            [
              -120.184566,
              37.775988
            ],
            [
              -120.185266,
              37.773281
            ],
            [
              -120.187329,
              37.77025
            ],
            [
              -120.18875,
              37.768486
            ],
            [
              -120.191936,
              37.768075
            ],
            [
              -120.193069,
              37.7693
            ],
            [
              -120.19565,
              37.769503
            ],
            [
              -120.198416,
              37.768461
            ],
            [
              -120.198178,
              37.766622
            ],
            [
              -120.200068,
              37.763716
            ],
            [
              -120.205222,
              37.76309
            ],
            [
              -120.209373,
              37.763155
            ],
            [
              -120.210699,
              37.764371
            ],
            [
              -120.212971,
              37.765112
            ],
            [
              -120.216579,
              37.763955
            ],
            [
              -120.221156,
              37.762787
            ],
            [
              -120.223363,
              37.763001
            ],
            [
              -120.22517,
              37.761959
            ],
            [
              -120.226,
              37.760981
            ],
            [
              -120.227958,
              37.760988
            ],
            [
              -120.229687,
              37.762713
            ],
            [
              -120.235712,
              37.763848
            ],
            [
              -120.23709,
              37.764975
            ],
            [
              -120.23944,
              37.76391
            ],
            [
              -120.239205,
              37.7619
            ],
            [
              -120.240836,
              37.758643
            ],
            [
              -120.239304,
              37.754968
            ],
            [
              -120.241325,
              37.752666
            ],
            [
              -120.243929,
              37.751013
            ],
            [
              -120.247174,
              37.74993
            ],
            [
              -120.255604,
              37.748537
            ],
            [
              -120.261336,
              37.741122
            ],
            [
              -120.260607,
              37.737164
            ],
            [
              -120.258814,
              37.735837
            ],
            [
              -120.259926,
              37.733827
            ],
            [
              -120.263871,
              37.735487
            ],
            [
              -120.272775,
              37.737903
            ],
            [
              -120.277793,
              37.740925
            ],
            [
              -120.283013,
              37.743466
            ],
            [
              -120.285214,
              37.741733
            ],
            [
              -120.285226,
              37.73643
            ],
            [
              -120.284832,
              37.732867
            ],
            [
              -120.288548,
              37.730297
            ],
            [
              -120.290437,
              37.731304
            ],
            [
              -120.296921,
              37.733487
            ],
            [
              -120.30646,
              37.734988
            ],
            [
              -120.315276,
              37.733869
            ],
            [
              -120.319135,
              37.729323
            ],
            [
              -120.324291,
              37.726634
            ],
            [
              -120.328915,
              37.72839
            ],
            [
              -120.333347,
              37.728457
            ],
            [
              -120.336424,
              37.726402
            ],
            [
              -120.339277,
              37.726744
            ],
            [
              -120.342099,
              37.726734
            ],
            [
              -120.34483,
              37.725966
            ],
            [
              -120.34409,
              37.723481
            ],
            [
              -120.34233,
              37.721951
            ],
            [
              -120.341264,
              37.718942
            ],
            [
              -120.340084,
              37.717348
            ],
            [
              -120.33955,
              37.713774
            ],
            [
              -120.338213,
              37.710522
            ],
            [
              -120.335013,
              37.707549
            ],
            [
              -120.332013,
              37.70344
            ],
            [
              -120.330182,
              37.697977
            ],
            [
              -120.323067,
              37.688623
            ],
            [
              -120.317575,
              37.68136
            ],
            [
              -120.310233,
              37.672614
            ],
            [
              -120.308277,
              37.664372
            ],
            [
              -120.311744,
              37.66014
            ],
            [
              -120.314395,
              37.653197
            ],
            [
              -120.321018,
              37.650385
            ],
            [
              -120.328376,
              37.650045
            ],
            [
              -120.331799,
              37.652567
            ],
            [
              -120.334092,
              37.656759
            ],
            [
              -120.340349,
              37.661293
            ],
            [
              -120.345127,
              37.66782
            ],
            [
              -120.349469,
              37.672403
            ],
            [
              -120.355417,
              37.6755
            ],
            [
              -120.3623,
              37.674778
            ],
            [
              -120.368178,
              37.680264
            ],
            [
              -120.378599,
              37.681298
            ],
            [
              -120.383237,
              37.682437
            ],
            [
              -120.388825,
              37.683738
            ],
            [
              -120.391924,
              37.681921
            ],
            [
              -120.392314,
              37.679928
            ],
            [
              -120.39398,
              37.675366
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "06",
      "COUNTYFP": "041",
      "COUNTYNS": "00277285",
      "AFFGEOID": "0500000US06041",
      "GEOID": "06041",
      "NAME": "Marin",
      "LSAD": "06",
      "ALAND": 1347976832,
      "AWATER": 797029137,
      "County_state": "Marin,06"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -122.446316,
              37.861046
            ],
            [
              -122.443302,
              37.855448
            ],
            [
              -122.434403,
              37.852434
            ],
            [
              -122.41869810449701,
              37.8527168911698
            ],
            [
              -122.41847,
              37.852721
            ],
            [
              -122.41847,
              37.861764
            ],
            [
              -122.421341,
              37.869946
            ],
            [
              -122.430958,
              37.872242
            ],
            [
              -122.438565,
              37.868367
            ],
            [
              -122.446316,
              37.861046
            ]
          ]
        ],
        [
          [
            [
              -123.020562,
              37.999544
            ],
            [
              -123.024066,
              37.994878
            ],
            [
              -122.97439,
              37.992429
            ],
            [
              -122.976764,
              37.99568
            ],
            [
              -122.980147,
              38.000831
            ],
            [
              -122.982386,
              38.004274
            ],
            [
              -122.981776,
              38.009119
            ],
            [
              -122.972378,
              38.020247
            ],
            [
              -122.956811,
              38.02872
            ],
            [
              -122.939711,
              38.031908
            ],
            [
              -122.882114,
              38.025273
            ],
            [
              -122.856573,
              38.016717
            ],
            [
              -122.821383,
              37.996735
            ],
            [
              -122.797405,
              37.976657
            ],
            [
              -122.791739,
              37.969422
            ],
            [
              -122.783244,
              37.951334
            ],
            [
              -122.766138,
              37.938004
            ],
            [
              -122.754606,
              37.935527
            ],
            [
              -122.736898,
              37.925825
            ],
            [
              -122.732898,
              37.920225
            ],
            [
              -122.727297,
              37.904626
            ],
            [
              -122.70264,
              37.89382
            ],
            [
              -122.693569,
              37.901171
            ],
            [
              -122.682171,
              37.90645
            ],
            [
              -122.678474,
              37.906604
            ],
            [
              -122.656519,
              37.904519
            ],
            [
              -122.639977,
              37.897349
            ],
            [
              -122.627113,
              37.88608
            ],
            [
              -122.60129,
              37.875126
            ],
            [
              -122.584289,
              37.859227
            ],
            [
              -122.561487,
              37.851827
            ],
            [
              -122.548986,
              37.836227
            ],
            [
              -122.537285,
              37.830328
            ],
            [
              -122.529185,
              37.819028
            ],
            [
              -122.505383,
              37.822128
            ],
            [
              -122.483483,
              37.826728
            ],
            [
              -122.479151,
              37.825428
            ],
            [
              -122.476473,
              37.832513
            ],
            [
              -122.483102,
              37.863957
            ],
            [
              -122.496266,
              37.870775
            ],
            [
              -122.501019,
              37.893662
            ],
            [
              -122.483431,
              37.890098
            ],
            [
              -122.462158,
              37.868866
            ],
            [
              -122.45005,
              37.871157
            ],
            [
              -122.438268,
              37.880974
            ],
            [
              -122.43925,
              37.88392
            ],
            [
              -122.448413,
              37.89341
            ],
            [
              -122.458558,
              37.894064
            ],
            [
              -122.472303,
              37.902573
            ],
            [
              -122.471975,
              37.910427
            ],
            [
              -122.478193,
              37.918608
            ],
            [
              -122.486375,
              37.921881
            ],
            [
              -122.493574,
              37.921881
            ],
            [
              -122.503064,
              37.928753
            ],
            [
              -122.503064,
              37.936607
            ],
            [
              -122.499465,
              37.939225
            ],
            [
              -122.48572,
              37.937589
            ],
            [
              -122.479175,
              37.941516
            ],
            [
              -122.480484,
              37.945443
            ],
            [
              -122.487684,
              37.948716
            ],
            [
              -122.490302,
              37.959188
            ],
            [
              -122.490302,
              37.964751
            ],
            [
              -122.488665,
              37.966714
            ],
            [
              -122.471975,
              37.981768
            ],
            [
              -122.462485,
              37.981441
            ],
            [
              -122.456595,
              37.978823
            ],
            [
              -122.448413,
              37.984713
            ],
            [
              -122.448413,
              37.988313
            ],
            [
              -122.452995,
              37.996167
            ],
            [
              -122.462812,
              38.003367
            ],
            [
              -122.481466,
              38.007621
            ],
            [
              -122.494556,
              38.015148
            ],
            [
              -122.497828,
              38.019402
            ],
            [
              -122.499465,
              38.032165
            ],
            [
              -122.492265,
              38.056381
            ],
            [
              -122.483757,
              38.071762
            ],
            [
              -122.486702,
              38.090088
            ],
            [
              -122.489974,
              38.096961
            ],
            [
              -122.491283,
              38.108087
            ],
            [
              -122.490726960382,
              38.1097551188534
            ],
            [
              -122.505707,
              38.115526
            ],
            [
              -122.513144,
              38.132235
            ],
            [
              -122.544992,
              38.158818
            ],
            [
              -122.557916,
              38.160158
            ],
            [
              -122.570732,
              38.187142
            ],
            [
              -122.625408,
              38.182394
            ],
            [
              -122.648986,
              38.181077
            ],
            [
              -122.667158,
              38.189697
            ],
            [
              -122.701393,
              38.196099
            ],
            [
              -122.722995,
              38.207132
            ],
            [
              -122.7399,
              38.207018
            ],
            [
              -122.901726,
              38.316943
            ],
            [
              -122.911319,
              38.321209
            ],
            [
              -122.93506,
              38.313952
            ],
            [
              -122.966124,
              38.31697
            ],
            [
              -123.00291120126201,
              38.2957083549502
            ],
            [
              -122.997106,
              38.289458
            ],
            [
              -122.994603,
              38.283096
            ],
            [
              -122.986319,
              38.273164
            ],
            [
              -122.977082,
              38.267902
            ],
            [
              -122.967203,
              38.250691
            ],
            [
              -122.968569,
              38.242879
            ],
            [
              -122.987149,
              38.237538
            ],
            [
              -122.993235,
              38.239686
            ],
            [
              -122.993959,
              38.237602
            ],
            [
              -122.991953,
              38.233185
            ],
            [
              -122.968112,
              38.202428
            ],
            [
              -122.96637,
              38.198514
            ],
            [
              -122.965408,
              38.187113
            ],
            [
              -122.953629,
              38.17567
            ],
            [
              -122.949626,
              38.164041
            ],
            [
              -122.949074,
              38.15406
            ],
            [
              -122.952086,
              38.138562
            ],
            [
              -122.960889,
              38.112962
            ],
            [
              -122.99242,
              38.041758
            ],
            [
              -123.011533,
              38.003438
            ],
            [
              -123.016303,
              38.001691
            ],
            [
              -123.020562,
              37.999544
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "06",
      "COUNTYFP": "101",
      "COUNTYNS": "00277315",
      "AFFGEOID": "0500000US06101",
      "GEOID": "06101",
      "NAME": "Sutter",
      "LSAD": "06",
      "ALAND": 1560275634,
      "AWATER": 15717389,
      "County_state": "Sutter,06"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -121.936916,
              39.26795
            ],
            [
              -121.948177,
              39.240387
            ],
            [
              -121.928332,
              39.198729
            ],
            [
              -121.945514,
              39.179843
            ],
            [
              -121.918983,
              39.142253
            ],
            [
              -121.937254,
              39.144227
            ],
            [
              -121.939172,
              39.136145
            ],
            [
              -121.922086,
              39.122632
            ],
            [
              -121.912302,
              39.123876
            ],
            [
              -121.905924,
              39.104195
            ],
            [
              -121.894369,
              39.096126
            ],
            [
              -121.889101,
              39.072373
            ],
            [
              -121.869817,
              39.06686
            ],
            [
              -121.847964,
              39.070817
            ],
            [
              -121.838213,
              39.062953
            ],
            [
              -121.838744,
              39.045091
            ],
            [
              -121.824375,
              39.031781
            ],
            [
              -121.825954,
              39.01065
            ],
            [
              -121.795366,
              38.995686
            ],
            [
              -121.819142,
              38.988551
            ],
            [
              -121.821398,
              38.974743
            ],
            [
              -121.832086,
              38.97116
            ],
            [
              -121.840374,
              38.95452
            ],
            [
              -121.830134,
              38.938425
            ],
            [
              -121.835488,
              38.924481
            ],
            [
              -121.805525,
              38.915065
            ],
            [
              -121.803989,
              38.90172
            ],
            [
              -121.814814,
              38.876582
            ],
            [
              -121.784265,
              38.856685
            ],
            [
              -121.749747,
              38.871243
            ],
            [
              -121.723149,
              38.851306
            ],
            [
              -121.730631,
              38.834654
            ],
            [
              -121.723414,
              38.825617
            ],
            [
              -121.723346,
              38.803759
            ],
            [
              -121.701251,
              38.803028
            ],
            [
              -121.685998,
              38.763371
            ],
            [
              -121.670018,
              38.75931
            ],
            [
              -121.673033,
              38.742847
            ],
            [
              -121.659035,
              38.75433
            ],
            [
              -121.664214,
              38.767802
            ],
            [
              -121.636175,
              38.767085
            ],
            [
              -121.624113,
              38.785174
            ],
            [
              -121.60811,
              38.78086
            ],
            [
              -121.592622,
              38.76286
            ],
            [
              -121.602894,
              38.735838
            ],
            [
              -121.567515,
              38.735789
            ],
            [
              -121.559868,
              38.736302
            ],
            [
              -121.489459,
              38.734985
            ],
            [
              -121.484396,
              38.734598
            ],
            [
              -121.48444,
              38.751354
            ],
            [
              -121.469803,
              38.751939
            ],
            [
              -121.469356,
              38.925992
            ],
            [
              -121.414399,
              38.926214
            ],
            [
              -121.414779,
              38.996452
            ],
            [
              -121.443826,
              38.993325
            ],
            [
              -121.456315,
              38.99819
            ],
            [
              -121.489646,
              38.99205
            ],
            [
              -121.524653,
              38.971473
            ],
            [
              -121.543015,
              38.972409
            ],
            [
              -121.553518,
              38.953755
            ],
            [
              -121.571297,
              38.938918
            ],
            [
              -121.575415,
              38.918348
            ],
            [
              -121.582183,
              38.93565
            ],
            [
              -121.577961,
              38.946771
            ],
            [
              -121.589399,
              38.990344
            ],
            [
              -121.579095,
              39.006692
            ],
            [
              -121.59927,
              39.011701
            ],
            [
              -121.594618,
              39.023708
            ],
            [
              -121.605634,
              39.032628
            ],
            [
              -121.610138,
              39.057847
            ],
            [
              -121.58591,
              39.089691
            ],
            [
              -121.587292,
              39.101718
            ],
            [
              -121.602678,
              39.100158
            ],
            [
              -121.597676,
              39.127792
            ],
            [
              -121.618452,
              39.182748
            ],
            [
              -121.613,
              39.190304
            ],
            [
              -121.629089,
              39.204678
            ],
            [
              -121.621221,
              39.206066
            ],
            [
              -121.614307,
              39.228745
            ],
            [
              -121.628176,
              39.228986
            ],
            [
              -121.636368,
              39.246076
            ],
            [
              -121.635779,
              39.261718
            ],
            [
              -121.621755,
              39.262825
            ],
            [
              -121.63156,
              39.291758
            ],
            [
              -121.62376,
              39.295621
            ],
            [
              -121.63015,
              39.305668
            ],
            [
              -121.908269,
              39.303878
            ],
            [
              -121.920796,
              39.281631
            ],
            [
              -121.936916,
              39.26795
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "06",
      "COUNTYFP": "035",
      "COUNTYNS": "01693324",
      "AFFGEOID": "0500000US06035",
      "GEOID": "06035",
      "NAME": "Lassen",
      "LSAD": "06",
      "ALAND": 11761615611,
      "AWATER": 463426050,
      "County_state": "Lassen,06"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -121.330973,
              40.934572
            ],
            [
              -121.332338,
              40.905394
            ],
            [
              -121.319976,
              40.905886
            ],
            [
              -121.32668,
              40.497574
            ],
            [
              -121.327826,
              40.445367
            ],
            [
              -121.061417,
              40.446536
            ],
            [
              -121.061493,
              40.256417
            ],
            [
              -121.041517,
              40.256474
            ],
            [
              -121.041521,
              40.234795
            ],
            [
              -121.003918,
              40.234941
            ],
            [
              -121.003732,
              40.220975
            ],
            [
              -120.946188,
              40.220808
            ],
            [
              -120.947152,
              40.206499
            ],
            [
              -120.928066,
              40.206451
            ],
            [
              -120.928582,
              40.191931
            ],
            [
              -120.890646,
              40.192116
            ],
            [
              -120.87183,
              40.199397
            ],
            [
              -120.871782,
              40.24164
            ],
            [
              -120.853118,
              40.241803
            ],
            [
              -120.834058,
              40.256161
            ],
            [
              -120.83382,
              40.270728
            ],
            [
              -120.796,
              40.277726
            ],
            [
              -120.796757,
              40.300937
            ],
            [
              -120.773805,
              40.301434
            ],
            [
              -120.764403,
              40.31601
            ],
            [
              -120.726574,
              40.315929
            ],
            [
              -120.726417,
              40.308433
            ],
            [
              -120.652158,
              40.307656
            ],
            [
              -120.642505,
              40.293184
            ],
            [
              -120.622796,
              40.28571
            ],
            [
              -120.576825,
              40.28543
            ],
            [
              -120.576729,
              40.263796
            ],
            [
              -120.548724,
              40.263596
            ],
            [
              -120.548663,
              40.256275
            ],
            [
              -120.510817,
              40.248945
            ],
            [
              -120.510665,
              40.234698
            ],
            [
              -120.49172,
              40.234791
            ],
            [
              -120.482848,
              40.205798
            ],
            [
              -120.454331,
              40.205751
            ],
            [
              -120.445892,
              40.176854
            ],
            [
              -120.416918,
              40.169441
            ],
            [
              -120.407954,
              40.147354
            ],
            [
              -120.379117,
              40.140556
            ],
            [
              -120.379399,
              40.12951
            ],
            [
              -120.341463,
              40.122556
            ],
            [
              -120.341385,
              40.115243
            ],
            [
              -120.275153,
              40.114809
            ],
            [
              -120.246788,
              40.107885
            ],
            [
              -120.24689,
              40.085805
            ],
            [
              -120.209641,
              40.086008
            ],
            [
              -120.21089,
              40.042757
            ],
            [
              -120.201383,
              40.042737
            ],
            [
              -120.201264,
              40.013474
            ],
            [
              -120.182484,
              40.01335
            ],
            [
              -120.163654,
              39.968659
            ],
            [
              -120.145015,
              39.962292
            ],
            [
              -120.108761,
              39.93951
            ],
            [
              -120.108883,
              39.910374
            ],
            [
              -120.099556,
              39.910339
            ],
            [
              -120.099339,
              39.881219
            ],
            [
              -120.108099,
              39.881266
            ],
            [
              -120.10781,
              39.794214
            ],
            [
              -120.110627,
              39.765779
            ],
            [
              -120.119584,
              39.765627
            ],
            [
              -120.127763,
              39.736159
            ],
            [
              -120.14658,
              39.736406
            ],
            [
              -120.147149,
              39.707658
            ],
            [
              -120.015734,
              39.708721
            ],
            [
              -120.001319008439,
              39.72241632020629
            ],
            [
              -120.001319,
              39.72242
            ],
            [
              -120.001151854493,
              39.734190971706795
            ],
            [
              -120.000502,
              39.779956
            ],
            [
              -120.000607,
              39.780779
            ],
            [
              -119.999733,
              39.851406
            ],
            [
              -119.997634,
              39.956505
            ],
            [
              -119.997291,
              40.071803
            ],
            [
              -119.997175,
              40.077245
            ],
            [
              -119.997234,
              40.091591
            ],
            [
              -119.997124,
              40.126363
            ],
            [
              -119.996183,
              40.262461
            ],
            [
              -119.996182,
              40.263532
            ],
            [
              -119.996155,
              40.32125
            ],
            [
              -119.996155,
              40.321838
            ],
            [
              -119.995926,
              40.499901
            ],
            [
              -119.997533,
              40.720992
            ],
            [
              -119.998479,
              40.749899
            ],
            [
              -119.999231,
              40.865899
            ],
            [
              -119.999232,
              40.867454
            ],
            [
              -119.999358,
              40.873101
            ],
            [
              -119.999866,
              41.183974
            ],
            [
              -120.876669,
              41.183977
            ],
            [
              -121.331786,
              41.183886
            ],
            [
              -121.330973,
              40.934572
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "06",
      "COUNTYFP": "065",
      "COUNTYNS": "00277297",
      "AFFGEOID": "0500000US06065",
      "GEOID": "06065",
      "NAME": "Riverside",
      "LSAD": "06",
      "ALAND": 18664542308,
      "AWATER": 250588224,
      "County_state": "Riverside,06"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -117.676286,
              33.888815
            ],
            [
              -117.673749,
              33.870831
            ],
            [
              -117.675092,
              33.869172
            ],
            [
              -117.674282,
              33.857956
            ],
            [
              -117.662117,
              33.857499
            ],
            [
              -117.580789,
              33.769477
            ],
            [
              -117.569175,
              33.76461
            ],
            [
              -117.542265,
              33.758801
            ],
            [
              -117.536448,
              33.757665
            ],
            [
              -117.533999,
              33.710355
            ],
            [
              -117.474573,
              33.703811
            ],
            [
              -117.412987,
              33.659045
            ],
            [
              -117.457937,
              33.602189
            ],
            [
              -117.51021,
              33.533999
            ],
            [
              -117.509722,
              33.505019
            ],
            [
              -117.364272,
              33.505025
            ],
            [
              -117.370925,
              33.490549
            ],
            [
              -117.322403,
              33.475031
            ],
            [
              -117.259308,
              33.454513
            ],
            [
              -117.250495,
              33.451629
            ],
            [
              -117.242167,
              33.449015
            ],
            [
              -117.238239,
              33.43196
            ],
            [
              -117.141574,
              33.43261
            ],
            [
              -117.089024,
              33.430286
            ],
            [
              -117.031381,
              33.430134
            ],
            [
              -117.030861,
              33.426931
            ],
            [
              -116.859783,
              33.426838
            ],
            [
              -116.85473,
              33.426619
            ],
            [
              -116.821826,
              33.426873
            ],
            [
              -116.112623,
              33.425932
            ],
            [
              -116.085165,
              33.425932
            ],
            [
              -115.468368,
              33.426986
            ],
            [
              -115.442045,
              33.428322
            ],
            [
              -114.828335,
              33.433709
            ],
            [
              -114.627125,
              33.433554
            ],
            [
              -114.622283,
              33.447558
            ],
            [
              -114.623395,
              33.45449
            ],
            [
              -114.622918,
              33.456561
            ],
            [
              -114.612472,
              33.470768
            ],
            [
              -114.607843,
              33.474834
            ],
            [
              -114.601696,
              33.481394
            ],
            [
              -114.599713,
              33.484315
            ],
            [
              -114.597283,
              33.490653
            ],
            [
              -114.591554,
              33.499443
            ],
            [
              -114.588239,
              33.502453
            ],
            [
              -114.580468,
              33.506465
            ],
            [
              -114.573757,
              33.507543
            ],
            [
              -114.569533,
              33.509219
            ],
            [
              -114.560963,
              33.516739
            ],
            [
              -114.560552,
              33.518272
            ],
            [
              -114.560835,
              33.524334
            ],
            [
              -114.559507,
              33.530724
            ],
            [
              -114.558898,
              33.531819
            ],
            [
              -114.542011,
              33.542481
            ],
            [
              -114.524599,
              33.552231
            ],
            [
              -114.524391,
              33.553683
            ],
            [
              -114.526834,
              33.557466
            ],
            [
              -114.535664,
              33.568788
            ],
            [
              -114.535965,
              33.569154
            ],
            [
              -114.5403,
              33.580615
            ],
            [
              -114.540617,
              33.591412
            ],
            [
              -114.529186,
              33.60665
            ],
            [
              -114.526782,
              33.608831
            ],
            [
              -114.524813,
              33.611351
            ],
            [
              -114.524619,
              33.61426
            ],
            [
              -114.525783,
              33.616588
            ],
            [
              -114.527938,
              33.618839
            ],
            [
              -114.529662,
              33.622794
            ],
            [
              -114.529856,
              33.627448
            ],
            [
              -114.528498,
              33.630164
            ],
            [
              -114.526947,
              33.633073
            ],
            [
              -114.526947,
              33.637534
            ],
            [
              -114.527917,
              33.641413
            ],
            [
              -114.52908,
              33.644128
            ],
            [
              -114.53005,
              33.647619
            ],
            [
              -114.530244,
              33.65014
            ],
            [
              -114.528304,
              33.653049
            ],
            [
              -114.526947,
              33.655571
            ],
            [
              -114.525783,
              33.657122
            ],
            [
              -114.525201,
              33.658092
            ],
            [
              -114.525007,
              33.659643
            ],
            [
              -114.525201,
              33.661583
            ],
            [
              -114.525977,
              33.662941
            ],
            [
              -114.526947,
              33.664298
            ],
            [
              -114.528304,
              33.666044
            ],
            [
              -114.529706,
              33.668031
            ],
            [
              -114.530999,
              33.671102
            ],
            [
              -114.531523,
              33.675108
            ],
            [
              -114.530348,
              33.679245
            ],
            [
              -114.527782,
              33.682684
            ],
            [
              -114.523959,
              33.685879
            ],
            [
              -114.519113,
              33.688473
            ],
            [
              -114.512409,
              33.691282
            ],
            [
              -114.504993,
              33.693022
            ],
            [
              -114.496489,
              33.696901
            ],
            [
              -114.495719,
              33.698454
            ],
            [
              -114.494197,
              33.707922
            ],
            [
              -114.494901,
              33.71443
            ],
            [
              -114.496565,
              33.719155
            ],
            [
              -114.500788,
              33.722204
            ],
            [
              -114.502661,
              33.724584
            ],
            [
              -114.504176,
              33.728055
            ],
            [
              -114.506799,
              33.730518
            ],
            [
              -114.510265,
              33.732146
            ],
            [
              -114.512348,
              33.734214
            ],
            [
              -114.508206,
              33.741587
            ],
            [
              -114.506,
              33.746344
            ],
            [
              -114.504483,
              33.750998
            ],
            [
              -114.50434,
              33.756381
            ],
            [
              -114.504863,
              33.760465
            ],
            [
              -114.507089,
              33.76793
            ],
            [
              -114.516734,
              33.788345
            ],
            [
              -114.520094,
              33.799473
            ],
            [
              -114.52805,
              33.814963
            ],
            [
              -114.527161,
              33.816191
            ],
            [
              -114.522714,
              33.818979
            ],
            [
              -114.520733,
              33.822031
            ],
            [
              -114.51997,
              33.825381
            ],
            [
              -114.520465,
              33.827778
            ],
            [
              -114.523409,
              33.835323
            ],
            [
              -114.525539,
              33.838614
            ],
            [
              -114.529597,
              33.848063
            ],
            [
              -114.529385,
              33.851755
            ],
            [
              -114.528451,
              33.854929
            ],
            [
              -114.526771,
              33.857357
            ],
            [
              -114.52453,
              33.858477
            ],
            [
              -114.516811,
              33.85812
            ],
            [
              -114.514673,
              33.858638
            ],
            [
              -114.505638,
              33.864276
            ],
            [
              -114.503887,
              33.865754
            ],
            [
              -114.503017,
              33.867998
            ],
            [
              -114.503395,
              33.875018
            ],
            [
              -114.50434,
              33.876882
            ],
            [
              -114.512467,
              33.882884
            ],
            [
              -114.516501,
              33.885926
            ],
            [
              -114.517808,
              33.888167
            ],
            [
              -114.518555,
              33.889847
            ],
            [
              -114.518928,
              33.891714
            ],
            [
              -114.518741,
              33.893208
            ],
            [
              -114.517808,
              33.894889
            ],
            [
              -114.516314,
              33.896196
            ],
            [
              -114.513715,
              33.897959
            ],
            [
              -114.510944,
              33.899099
            ],
            [
              -114.508708,
              33.90064
            ],
            [
              -114.507988,
              33.901813
            ],
            [
              -114.50792,
              33.903807
            ],
            [
              -114.508558,
              33.906098
            ],
            [
              -114.511511,
              33.911092
            ],
            [
              -114.518434,
              33.917518
            ],
            [
              -114.525361,
              33.922272
            ],
            [
              -114.528385,
              33.923674
            ],
            [
              -114.533679,
              33.926072
            ],
            [
              -114.534987,
              33.928499
            ],
            [
              -114.535478,
              33.934651
            ],
            [
              -114.52868,
              33.947817
            ],
            [
              -114.522002,
              33.955623
            ],
            [
              -114.51586,
              33.958106
            ],
            [
              -114.511231,
              33.95704
            ],
            [
              -114.509568,
              33.957264
            ],
            [
              -114.499883,
              33.961789
            ],
            [
              -114.495047,
              33.966835
            ],
            [
              -114.484784,
              33.975519
            ],
            [
              -114.483097,
              33.977745
            ],
            [
              -114.482333,
              33.980181
            ],
            [
              -114.481455,
              33.981261
            ],
            [
              -114.475907,
              33.984424
            ],
            [
              -114.471138,
              33.98804
            ],
            [
              -114.467932,
              33.992877
            ],
            [
              -114.466187,
              33.993465
            ],
            [
              -114.462377,
              33.993781
            ],
            [
              -114.46117,
              33.994687
            ],
            [
              -114.460264,
              33.996649
            ],
            [
              -114.460415,
              33.999215
            ],
            [
              -114.46283,
              34.004497
            ],
            [
              -114.463132,
              34.00661
            ],
            [
              -114.46283,
              34.008421
            ],
            [
              -114.46117,
              34.010081
            ],
            [
              -114.458906,
              34.010835
            ],
            [
              -114.454807,
              34.010968
            ],
            [
              -114.450206,
              34.012574
            ],
            [
              -114.443821,
              34.016176
            ],
            [
              -114.44054,
              34.019329
            ],
            [
              -114.438266,
              34.022609
            ],
            [
              -114.436171,
              34.028083
            ],
            [
              -114.434949,
              34.037784
            ],
            [
              -114.435504,
              34.042615
            ],
            [
              -114.438602,
              34.050205
            ],
            [
              -114.439406,
              34.05381
            ],
            [
              -114.43934,
              34.057893
            ],
            [
              -114.437683,
              34.071937
            ],
            [
              -114.435429,
              34.079727
            ],
            [
              -115.227265,
              34.077984
            ],
            [
              -115.316065,
              34.077843
            ],
            [
              -115.316212,
              34.03411
            ],
            [
              -115.692829,
              34.035313
            ],
            [
              -115.98389,
              34.035131
            ],
            [
              -116.300598,
              34.034401
            ],
            [
              -116.512252,
              34.032878
            ],
            [
              -116.719663,
              34.033824
            ],
            [
              -116.929558,
              34.034113
            ],
            [
              -116.929357,
              34.004914
            ],
            [
              -117.104737,
              34.004015
            ],
            [
              -117.121293,
              34.003916
            ],
            [
              -117.212372,
              34.004215
            ],
            [
              -117.225373,
              34.018715
            ],
            [
              -117.270602,
              34.018715
            ],
            [
              -117.370274,
              34.019444
            ],
            [
              -117.371588,
              34.019399
            ],
            [
              -117.375278,
              34.033875
            ],
            [
              -117.524313,
              34.033469
            ],
            [
              -117.558312,
              34.033451
            ],
            [
              -117.558269,
              34.018906
            ],
            [
              -117.558377,
              33.98842
            ],
            [
              -117.610386,
              33.971614
            ],
            [
              -117.610947,
              33.925114
            ],
            [
              -117.655375,
              33.924343
            ],
            [
              -117.654885,
              33.888815
            ],
            [
              -117.676286,
              33.888815
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "06",
      "COUNTYFP": "063",
      "COUNTYNS": "00277296",
      "AFFGEOID": "0500000US06063",
      "GEOID": "06063",
      "NAME": "Plumas",
      "LSAD": "06",
      "ALAND": 6612400919,
      "AWATER": 156387635,
      "County_state": "Plumas,06"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -121.497635,
              40.445591
            ],
            [
              -121.49788,
              40.432003
            ],
            [
              -121.474029,
              40.423732
            ],
            [
              -121.484278,
              40.396559
            ],
            [
              -121.47354,
              40.374556
            ],
            [
              -121.480992,
              40.366614
            ],
            [
              -121.47242,
              40.35162
            ],
            [
              -121.429353,
              40.350349
            ],
            [
              -121.412066,
              40.342719
            ],
            [
              -121.381826,
              40.340513
            ],
            [
              -121.368909,
              40.32564
            ],
            [
              -121.342264,
              40.309751
            ],
            [
              -121.356626,
              40.29747
            ],
            [
              -121.3768,
              40.297989
            ],
            [
              -121.350322,
              40.275883
            ],
            [
              -121.349,
              40.264454
            ],
            [
              -121.367098,
              40.245572
            ],
            [
              -121.361713,
              40.225581
            ],
            [
              -121.368395,
              40.21223
            ],
            [
              -121.385028,
              40.210297
            ],
            [
              -121.407356,
              40.191404
            ],
            [
              -121.439187,
              40.195085
            ],
            [
              -121.445123,
              40.177692
            ],
            [
              -121.436886,
              40.151905
            ],
            [
              -121.404509,
              40.151648
            ],
            [
              -121.404855,
              40.115545
            ],
            [
              -121.376489,
              40.108626
            ],
            [
              -121.376792,
              40.086034
            ],
            [
              -121.366818,
              40.086054
            ],
            [
              -121.382266,
              40.051827
            ],
            [
              -121.400353,
              40.052095
            ],
            [
              -121.400338,
              40.030523
            ],
            [
              -121.41948,
              40.015766
            ],
            [
              -121.419817,
              39.987549
            ],
            [
              -121.410263,
              39.987303
            ],
            [
              -121.410327,
              39.958478
            ],
            [
              -121.419844,
              39.958625
            ],
            [
              -121.420577,
              39.92945
            ],
            [
              -121.411001,
              39.915091
            ],
            [
              -121.430012,
              39.914833
            ],
            [
              -121.429957,
              39.900313
            ],
            [
              -121.411,
              39.900425
            ],
            [
              -121.411936,
              39.871421
            ],
            [
              -121.380183,
              39.855421
            ],
            [
              -121.361206,
              39.855473
            ],
            [
              -121.350821,
              39.825704
            ],
            [
              -121.332293,
              39.825696
            ],
            [
              -121.332333,
              39.811053
            ],
            [
              -121.318471,
              39.810906
            ],
            [
              -121.316938,
              39.796702
            ],
            [
              -121.284946,
              39.781576
            ],
            [
              -121.285852,
              39.767624
            ],
            [
              -121.268272,
              39.768799
            ],
            [
              -121.268025,
              39.754244
            ],
            [
              -121.250641,
              39.753965
            ],
            [
              -121.248256,
              39.740041
            ],
            [
              -121.229706,
              39.740213
            ],
            [
              -121.229446,
              39.725665
            ],
            [
              -121.210878,
              39.725651
            ],
            [
              -121.208687,
              39.700358
            ],
            [
              -121.190532,
              39.699891
            ],
            [
              -121.190284,
              39.685353
            ],
            [
              -121.171188,
              39.685564
            ],
            [
              -121.171143,
              39.671088
            ],
            [
              -121.155077,
              39.656785
            ],
            [
              -121.136412,
              39.65681
            ],
            [
              -121.136715,
              39.628169
            ],
            [
              -121.097538,
              39.620903
            ],
            [
              -121.088921,
              39.603306
            ],
            [
              -121.076695,
              39.597264
            ],
            [
              -121.009477,
              39.639459
            ],
            [
              -121.006727,
              39.639543
            ],
            [
              -121.00305,
              39.638394
            ],
            [
              -120.998906,
              39.639837
            ],
            [
              -120.994962,
              39.640598
            ],
            [
              -120.991208,
              39.643218
            ],
            [
              -120.986394,
              39.644684
            ],
            [
              -120.984212,
              39.646488
            ],
            [
              -120.982655,
              39.649277
            ],
            [
              -120.979718,
              39.64878
            ],
            [
              -120.976017,
              39.650702
            ],
            [
              -120.974148,
              39.653863
            ],
            [
              -120.969387,
              39.656307
            ],
            [
              -120.96561,
              39.66039
            ],
            [
              -120.963573,
              39.665395
            ],
            [
              -120.957607,
              39.667723
            ],
            [
              -120.954547,
              39.668845
            ],
            [
              -120.95122,
              39.671165
            ],
            [
              -120.950015,
              39.673837
            ],
            [
              -120.951297,
              39.675008
            ],
            [
              -120.95032,
              39.677726
            ],
            [
              -120.947154,
              39.679575
            ],
            [
              -120.94269,
              39.683546
            ],
            [
              -120.940653,
              39.686451
            ],
            [
              -120.939281,
              39.688742
            ],
            [
              -120.93838,
              39.691105
            ],
            [
              -120.939311,
              39.691892
            ],
            [
              -120.93909,
              39.693077
            ],
            [
              -120.938105,
              39.692758
            ],
            [
              -120.936892,
              39.694128
            ],
            [
              -120.937052,
              39.695753
            ],
            [
              -120.937029,
              39.698324
            ],
            [
              -120.936435,
              39.701039
            ],
            [
              -120.934158,
              39.704314
            ],
            [
              -120.931083,
              39.740839
            ],
            [
              -120.916803,
              39.75323
            ],
            [
              -120.905997,
              39.757348
            ],
            [
              -120.894634,
              39.76029
            ],
            [
              -120.887408,
              39.767289
            ],
            [
              -120.875682,
              39.772213
            ],
            [
              -120.870474,
              39.775941
            ],
            [
              -120.865174,
              39.773076
            ],
            [
              -120.859293,
              39.770115
            ],
            [
              -120.845767,
              39.756906
            ],
            [
              -120.843153,
              39.750884
            ],
            [
              -120.837986,
              39.745859
            ],
            [
              -120.829649,
              39.746681
            ],
            [
              -120.82764,
              39.744675
            ],
            [
              -120.822343,
              39.743333
            ],
            [
              -120.819217,
              39.744578
            ],
            [
              -120.813554,
              39.74364
            ],
            [
              -120.813005,
              39.740043
            ],
            [
              -120.812971,
              39.732508
            ],
            [
              -120.811716,
              39.725396
            ],
            [
              -120.804046,
              39.720944
            ],
            [
              -120.796613,
              39.718424
            ],
            [
              -120.791673,
              39.713908
            ],
            [
              -120.787669,
              39.709992
            ],
            [
              -120.782236,
              39.710557
            ],
            [
              -120.775031,
              39.712492
            ],
            [
              -120.770862,
              39.716921
            ],
            [
              -120.752933,
              39.719356
            ],
            [
              -120.745994,
              39.715949
            ],
            [
              -120.738481,
              39.710812
            ],
            [
              -120.728317,
              39.707574
            ],
            [
              -120.718395,
              39.706674
            ],
            [
              -120.712207,
              39.701301
            ],
            [
              -120.705819,
              39.695518
            ],
            [
              -120.697919,
              39.692443
            ],
            [
              -120.690145,
              39.686771
            ],
            [
              -120.683488,
              39.680963
            ],
            [
              -120.68142,
              39.678061
            ],
            [
              -120.675759,
              39.679631
            ],
            [
              -120.672879,
              39.681304
            ],
            [
              -120.663837,
              39.684232
            ],
            [
              -120.656152,
              39.693205
            ],
            [
              -120.654794,
              39.697851
            ],
            [
              -120.654542,
              39.70228
            ],
            [
              -120.636785,
              39.706565
            ],
            [
              -120.612726,
              39.706563
            ],
            [
              -120.581049,
              39.706542
            ],
            [
              -120.546569,
              39.706463
            ],
            [
              -120.519008,
              39.70647
            ],
            [
              -120.471836,
              39.706496
            ],
            [
              -120.385487,
              39.708631
            ],
            [
              -120.350176,
              39.708408
            ],
            [
              -120.314475,
              39.708117
            ],
            [
              -120.251052,
              39.708461
            ],
            [
              -120.201171,
              39.707901
            ],
            [
              -120.147149,
              39.707658
            ],
            [
              -120.14658,
              39.736406
            ],
            [
              -120.127763,
              39.736159
            ],
            [
              -120.119584,
              39.765627
            ],
            [
              -120.110627,
              39.765779
            ],
            [
              -120.10781,
              39.794214
            ],
            [
              -120.108099,
              39.881266
            ],
            [
              -120.099339,
              39.881219
            ],
            [
              -120.099556,
              39.910339
            ],
            [
              -120.108883,
              39.910374
            ],
            [
              -120.108761,
              39.93951
            ],
            [
              -120.145015,
              39.962292
            ],
            [
              -120.163654,
              39.968659
            ],
            [
              -120.182484,
              40.01335
            ],
            [
              -120.201264,
              40.013474
            ],
            [
              -120.201383,
              40.042737
            ],
            [
              -120.21089,
              40.042757
            ],
            [
              -120.209641,
              40.086008
            ],
            [
              -120.24689,
              40.085805
            ],
            [
              -120.246788,
              40.107885
            ],
            [
              -120.275153,
              40.114809
            ],
            [
              -120.341385,
              40.115243
            ],
            [
              -120.341463,
              40.122556
            ],
            [
              -120.379399,
              40.12951
            ],
            [
              -120.379117,
              40.140556
            ],
            [
              -120.407954,
              40.147354
            ],
            [
              -120.416918,
              40.169441
            ],
            [
              -120.445892,
              40.176854
            ],
            [
              -120.454331,
              40.205751
            ],
            [
              -120.482848,
              40.205798
            ],
            [
              -120.49172,
              40.234791
            ],
            [
              -120.510665,
              40.234698
            ],
            [
              -120.510817,
              40.248945
            ],
            [
              -120.548663,
              40.256275
            ],
            [
              -120.548724,
              40.263596
            ],
            [
              -120.576729,
              40.263796
            ],
            [
              -120.576825,
              40.28543
            ],
            [
              -120.622796,
              40.28571
            ],
            [
              -120.642505,
              40.293184
            ],
            [
              -120.652158,
              40.307656
            ],
            [
              -120.726417,
              40.308433
            ],
            [
              -120.726574,
              40.315929
            ],
            [
              -120.764403,
              40.31601
            ],
            [
              -120.773805,
              40.301434
            ],
            [
              -120.796757,
              40.300937
            ],
            [
              -120.796,
              40.277726
            ],
            [
              -120.83382,
              40.270728
            ],
            [
              -120.834058,
              40.256161
            ],
            [
              -120.853118,
              40.241803
            ],
            [
              -120.871782,
              40.24164
            ],
            [
              -120.87183,
              40.199397
            ],
            [
              -120.890646,
              40.192116
            ],
            [
              -120.928582,
              40.191931
            ],
            [
              -120.928066,
              40.206451
            ],
            [
              -120.947152,
              40.206499
            ],
            [
              -120.946188,
              40.220808
            ],
            [
              -121.003732,
              40.220975
            ],
            [
              -121.003918,
              40.234941
            ],
            [
              -121.041521,
              40.234795
            ],
            [
              -121.041517,
              40.256474
            ],
            [
              -121.061493,
              40.256417
            ],
            [
              -121.061417,
              40.446536
            ],
            [
              -121.327826,
              40.445367
            ],
            [
              -121.346531,
              40.449715
            ],
            [
              -121.366111,
              40.445927
            ],
            [
              -121.497635,
              40.445591
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "06",
      "COUNTYFP": "085",
      "COUNTYNS": "00277307",
      "AFFGEOID": "0500000US06085",
      "GEOID": "06085",
      "NAME": "Santa Clara",
      "LSAD": "06",
      "ALAND": 3344209919,
      "AWATER": 33276429,
      "County_state": "Santa Clara,06"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -122.190505,
              37.385253
            ],
            [
              -122.202467,
              37.360272
            ],
            [
              -122.175075,
              37.325741
            ],
            [
              -122.192665,
              37.31801
            ],
            [
              -122.16207,
              37.304233
            ],
            [
              -122.166838,
              37.295237
            ],
            [
              -122.152278,
              37.286055
            ],
            [
              -122.151571,
              37.285346
            ],
            [
              -122.15057,
              37.2762
            ],
            [
              -122.121638,
              37.25856
            ],
            [
              -122.08981,
              37.22327
            ],
            [
              -122.065933,
              37.210413
            ],
            [
              -122.055087,
              37.212497
            ],
            [
              -122.03075,
              37.18999
            ],
            [
              -122.026174,
              37.166854
            ],
            [
              -122.002934,
              37.158458
            ],
            [
              -121.99109,
              37.14427
            ],
            [
              -121.93415,
              37.12331
            ],
            [
              -121.890867,
              37.105256
            ],
            [
              -121.860421,
              37.104037
            ],
            [
              -121.84629,
              37.09702
            ],
            [
              -121.8184,
              37.08382
            ],
            [
              -121.809076,
              37.069301
            ],
            [
              -121.78594,
              37.06193
            ],
            [
              -121.778257,
              37.056065
            ],
            [
              -121.7576,
              37.04963
            ],
            [
              -121.725826,
              37.020793
            ],
            [
              -121.718762,
              37.007557
            ],
            [
              -121.738697,
              36.989991
            ],
            [
              -121.732312,
              36.989664
            ],
            [
              -121.717878,
              36.995561
            ],
            [
              -121.695358,
              36.98515
            ],
            [
              -121.693303,
              36.96823
            ],
            [
              -121.66613,
              36.96434
            ],
            [
              -121.645791,
              36.93233
            ],
            [
              -121.624755,
              36.940451
            ],
            [
              -121.607155,
              36.926051
            ],
            [
              -121.581154,
              36.919252
            ],
            [
              -121.581354,
              36.899152
            ],
            [
              -121.575402,
              36.893033
            ],
            [
              -121.560272,
              36.897111
            ],
            [
              -121.548009,
              36.917391
            ],
            [
              -121.540016,
              36.920765
            ],
            [
              -121.540754,
              36.924628
            ],
            [
              -121.534463,
              36.930933
            ],
            [
              -121.523591,
              36.93709
            ],
            [
              -121.513813,
              36.945155
            ],
            [
              -121.512263,
              36.957997
            ],
            [
              -121.50528,
              36.964216
            ],
            [
              -121.501488,
              36.971895
            ],
            [
              -121.488949,
              36.983148
            ],
            [
              -121.467939,
              36.976772
            ],
            [
              -121.451972,
              36.98884
            ],
            [
              -121.418246,
              36.960549
            ],
            [
              -121.215406,
              36.961248
            ],
            [
              -121.245887,
              36.983036
            ],
            [
              -121.233137,
              36.999346
            ],
            [
              -121.233337,
              37.011746
            ],
            [
              -121.245989,
              37.025575
            ],
            [
              -121.224507,
              37.039743
            ],
            [
              -121.223387,
              37.057507
            ],
            [
              -121.208198,
              37.061289
            ],
            [
              -121.209637,
              37.068243
            ],
            [
              -121.245384,
              37.089501
            ],
            [
              -121.230439,
              37.096942
            ],
            [
              -121.217339,
              37.123042
            ],
            [
              -121.226804,
              37.134774
            ],
            [
              -121.237712,
              37.15758
            ],
            [
              -121.262293,
              37.159473
            ],
            [
              -121.281107,
              37.183603
            ],
            [
              -121.29773,
              37.166429
            ],
            [
              -121.328409,
              37.16595
            ],
            [
              -121.354561,
              37.183893
            ],
            [
              -121.374053,
              37.17304
            ],
            [
              -121.393424,
              37.148131
            ],
            [
              -121.404636,
              37.155989
            ],
            [
              -121.416869,
              37.211262
            ],
            [
              -121.45575,
              37.24944
            ],
            [
              -121.453977,
              37.276427
            ],
            [
              -121.459051,
              37.282739
            ],
            [
              -121.443551,
              37.296638
            ],
            [
              -121.436816,
              37.291971
            ],
            [
              -121.405753,
              37.31099
            ],
            [
              -121.40915,
              37.330637
            ],
            [
              -121.42365,
              37.358837
            ],
            [
              -121.409693,
              37.382013
            ],
            [
              -121.412549,
              37.389435
            ],
            [
              -121.436085,
              37.39613
            ],
            [
              -121.456651,
              37.395535
            ],
            [
              -121.456351,
              37.406735
            ],
            [
              -121.472606,
              37.423345
            ],
            [
              -121.46186,
              37.438799
            ],
            [
              -121.462917,
              37.451489
            ],
            [
              -121.484252,
              37.466032
            ],
            [
              -121.486775,
              37.475652
            ],
            [
              -121.472648,
              37.48217
            ],
            [
              -121.670399,
              37.48274
            ],
            [
              -121.855762,
              37.484537
            ],
            [
              -121.867784,
              37.483033
            ],
            [
              -121.925041,
              37.454186
            ],
            [
              -121.947087,
              37.467424
            ],
            [
              -121.975071,
              37.460639
            ],
            [
              -121.996671,
              37.467239
            ],
            [
              -122.046554266444,
              37.4595373585317
            ],
            [
              -122.044771,
              37.457249
            ],
            [
              -122.04535096161901,
              37.4570188023894
            ],
            [
              -122.05985524831101,
              37.45126177979729
            ],
            [
              -122.060933,
              37.450834
            ],
            [
              -122.071108,
              37.452092
            ],
            [
              -122.08993,
              37.454419
            ],
            [
              -122.108706487739,
              37.466261871117
            ],
            [
              -122.128008,
              37.452085
            ],
            [
              -122.144933,
              37.4582
            ],
            [
              -122.165302,
              37.452843
            ],
            [
              -122.190402,
              37.431472
            ],
            [
              -122.19454,
              37.407934
            ],
            [
              -122.190505,
              37.385253
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "06",
      "COUNTYFP": "061",
      "COUNTYNS": "00277295",
      "AFFGEOID": "0500000US06061",
      "GEOID": "06061",
      "NAME": "Placer",
      "LSAD": "06",
      "ALAND": 3644244131,
      "AWATER": 246575767,
      "County_state": "Placer,06"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -121.48444,
              38.751354
            ],
            [
              -121.484396,
              38.734598
            ],
            [
              -121.381658,
              38.727796
            ],
            [
              -121.30883,
              38.72279
            ],
            [
              -121.242795,
              38.718241
            ],
            [
              -121.216864,
              38.71621
            ],
            [
              -121.151857,
              38.711502
            ],
            [
              -121.141009,
              38.71198
            ],
            [
              -121.134507,
              38.712032
            ],
            [
              -121.102999,
              38.785548
            ],
            [
              -121.100844,
              38.815392
            ],
            [
              -121.084957,
              38.81601
            ],
            [
              -121.083055,
              38.823054
            ],
            [
              -121.088959,
              38.829233
            ],
            [
              -121.085791,
              38.83445
            ],
            [
              -121.073575,
              38.842604
            ],
            [
              -121.060916,
              38.845613
            ],
            [
              -121.053263,
              38.868347
            ],
            [
              -121.061984,
              38.881499
            ],
            [
              -121.044175,
              38.890826
            ],
            [
              -121.053407,
              38.899502
            ],
            [
              -121.040511,
              38.915538
            ],
            [
              -121.037502,
              38.915689
            ],
            [
              -121.001052,
              38.917835
            ],
            [
              -120.991968,
              38.927225
            ],
            [
              -120.965994,
              38.932581
            ],
            [
              -120.958052,
              38.939005
            ],
            [
              -120.93857,
              38.935565
            ],
            [
              -120.940281,
              38.962339
            ],
            [
              -120.899989,
              38.953348
            ],
            [
              -120.890502,
              38.959562
            ],
            [
              -120.858903,
              38.952256
            ],
            [
              -120.850514,
              38.975899
            ],
            [
              -120.834163,
              38.971866
            ],
            [
              -120.829711,
              38.987343
            ],
            [
              -120.812295,
              39.000133
            ],
            [
              -120.798103,
              38.996051
            ],
            [
              -120.764448,
              39.00975
            ],
            [
              -120.746395,
              39.010352
            ],
            [
              -120.729575,
              39.004025
            ],
            [
              -120.716286,
              38.986671
            ],
            [
              -120.703904,
              38.981182
            ],
            [
              -120.687925,
              38.989949
            ],
            [
              -120.683668,
              38.967531
            ],
            [
              -120.642458,
              38.944404
            ],
            [
              -120.612809,
              38.943091
            ],
            [
              -120.594381,
              38.936104
            ],
            [
              -120.572352,
              38.9144
            ],
            [
              -120.556321,
              38.915164
            ],
            [
              -120.54537,
              38.926668
            ],
            [
              -120.512346,
              38.931191
            ],
            [
              -120.492735,
              38.943351
            ],
            [
              -120.486122,
              38.958837
            ],
            [
              -120.470985,
              38.965507
            ],
            [
              -120.445213,
              39.017595
            ],
            [
              -120.43524,
              39.028397
            ],
            [
              -120.328122,
              39.022433
            ],
            [
              -120.24041,
              39.023668
            ],
            [
              -120.240157,
              39.030889
            ],
            [
              -120.184098,
              39.031014
            ],
            [
              -120.18356,
              39.038372
            ],
            [
              -120.165028,
              39.038485
            ],
            [
              -120.152976,
              39.045807
            ],
            [
              -120.153021,
              39.060329
            ],
            [
              -120.143663,
              39.067285
            ],
            [
              -120.002461,
              39.067489
            ],
            [
              -120.00340238137,
              39.112687055111
            ],
            [
              -120.004504420333,
              39.1655986798664
            ],
            [
              -120.005746,
              39.22521
            ],
            [
              -120.005743,
              39.228664
            ],
            [
              -120.00526394636,
              39.278557317057796
            ],
            [
              -120.005142,
              39.291258
            ],
            [
              -120.005414,
              39.313345
            ],
            [
              -120.005413,
              39.313848
            ],
            [
              -120.00532,
              39.31635
            ],
            [
              -120.00531557607401,
              39.3164525335553
            ],
            [
              -120.010253,
              39.316328
            ],
            [
              -120.036657,
              39.316334
            ],
            [
              -120.322378,
              39.316427
            ],
            [
              -120.5546,
              39.315596
            ],
            [
              -120.645795,
              39.315155
            ],
            [
              -120.69111,
              39.304134
            ],
            [
              -120.727893,
              39.288081
            ],
            [
              -120.756595,
              39.264235
            ],
            [
              -120.804249,
              39.233266
            ],
            [
              -120.816647,
              39.229928
            ],
            [
              -120.857909,
              39.206235
            ],
            [
              -120.868849,
              39.180737
            ],
            [
              -120.908996,
              39.170173
            ],
            [
              -120.911243,
              39.164035
            ],
            [
              -120.94749,
              39.143395
            ],
            [
              -120.979315,
              39.110402
            ],
            [
              -120.988717,
              39.111777
            ],
            [
              -120.991588,
              39.076878
            ],
            [
              -121.007072,
              39.061218
            ],
            [
              -121.008303,
              39.052055
            ],
            [
              -121.02901,
              39.038427
            ],
            [
              -121.041608,
              39.013791
            ],
            [
              -121.067546,
              39.005371
            ],
            [
              -121.090272,
              39.014267
            ],
            [
              -121.104247,
              39.01185
            ],
            [
              -121.137397,
              39.037858
            ],
            [
              -121.18637,
              39.012867
            ],
            [
              -121.217431,
              39.012475
            ],
            [
              -121.232735,
              39.024556
            ],
            [
              -121.279533,
              39.034618
            ],
            [
              -121.305992,
              39.052943
            ],
            [
              -121.332135,
              39.042021
            ],
            [
              -121.365643,
              39.031743
            ],
            [
              -121.414779,
              38.996452
            ],
            [
              -121.414399,
              38.926214
            ],
            [
              -121.469356,
              38.925992
            ],
            [
              -121.469803,
              38.751939
            ],
            [
              -121.48444,
              38.751354
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "06",
      "COUNTYFP": "079",
      "COUNTYNS": "00277304",
      "AFFGEOID": "0500000US06079",
      "GEOID": "06079",
      "NAME": "San Luis Obispo",
      "LSAD": "06",
      "ALAND": 8548552397,
      "AWATER": 815578711,
      "County_state": "San Luis Obispo,06"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -121.346362833935,
              35.7951826441526
            ],
            [
              -121.332449,
              35.783106
            ],
            [
              -121.324918,
              35.769347
            ],
            [
              -121.315786,
              35.75252
            ],
            [
              -121.314632,
              35.71331
            ],
            [
              -121.304583,
              35.701794
            ],
            [
              -121.296473,
              35.696824
            ],
            [
              -121.289794,
              35.689428
            ],
            [
              -121.284973,
              35.674109
            ],
            [
              -121.272322,
              35.666711
            ],
            [
              -121.251034,
              35.656641
            ],
            [
              -121.195291,
              35.640734
            ],
            [
              -121.188897,
              35.643138
            ],
            [
              -121.166712,
              35.635399
            ],
            [
              -121.143561,
              35.606046
            ],
            [
              -121.133556,
              35.600455
            ],
            [
              -121.126027,
              35.593058
            ],
            [
              -121.101595,
              35.548814
            ],
            [
              -121.059913,
              35.509671
            ],
            [
              -121.05308,
              35.50753
            ],
            [
              -121.025621,
              35.484598
            ],
            [
              -121.003359,
              35.46071
            ],
            [
              -120.976122,
              35.459028
            ],
            [
              -120.969436,
              35.460197
            ],
            [
              -120.955863,
              35.453743
            ],
            [
              -120.950742,
              35.44802
            ],
            [
              -120.946546,
              35.446715
            ],
            [
              -120.907937,
              35.449069
            ],
            [
              -120.896862,
              35.442243
            ],
            [
              -120.884757,
              35.430196
            ],
            [
              -120.869209,
              35.403276
            ],
            [
              -120.866099,
              35.393045
            ],
            [
              -120.862133,
              35.360763
            ],
            [
              -120.862684,
              35.346776
            ],
            [
              -120.8672,
              35.327154
            ],
            [
              -120.87957,
              35.294184
            ],
            [
              -120.889354,
              35.277819
            ],
            [
              -120.896876,
              35.25399
            ],
            [
              -120.89679,
              35.247877
            ],
            [
              -120.873046,
              35.225688
            ],
            [
              -120.856047,
              35.206487
            ],
            [
              -120.846674,
              35.204429
            ],
            [
              -120.805258,
              35.184973
            ],
            [
              -120.786076,
              35.177666
            ],
            [
              -120.778998,
              35.168897
            ],
            [
              -120.760492,
              35.15971
            ],
            [
              -120.756086,
              35.160459
            ],
            [
              -120.756862,
              35.169208
            ],
            [
              -120.754823,
              35.174701
            ],
            [
              -120.74887,
              35.177795
            ],
            [
              -120.734231,
              35.178472
            ],
            [
              -120.714185,
              35.175998
            ],
            [
              -120.704203,
              35.173206
            ],
            [
              -120.698906,
              35.171192
            ],
            [
              -120.686974,
              35.160708
            ],
            [
              -120.675074,
              35.153061
            ],
            [
              -120.667994,
              35.15203
            ],
            [
              -120.662475,
              35.153357
            ],
            [
              -120.651134,
              35.147768
            ],
            [
              -120.644311,
              35.139616
            ],
            [
              -120.635787,
              35.123805
            ],
            [
              -120.630957,
              35.101941
            ],
            [
              -120.629583,
              35.078362
            ],
            [
              -120.629931,
              35.061515
            ],
            [
              -120.63357,
              35.033085
            ],
            [
              -120.63999,
              35.002963
            ],
            [
              -120.64890505130701,
              34.9743932158666
            ],
            [
              -120.647096,
              34.972576
            ],
            [
              -120.640459,
              34.965909
            ],
            [
              -120.629676,
              34.962373
            ],
            [
              -120.627624,
              34.963142
            ],
            [
              -120.624632,
              34.963963
            ],
            [
              -120.603816,
              34.972536
            ],
            [
              -120.600839,
              34.971646
            ],
            [
              -120.594817,
              34.966538
            ],
            [
              -120.590774,
              34.966833
            ],
            [
              -120.584226,
              34.96924
            ],
            [
              -120.577937,
              34.975967
            ],
            [
              -120.567455,
              34.977435
            ],
            [
              -120.565581,
              34.977332
            ],
            [
              -120.559194,
              34.975267
            ],
            [
              -120.55455,
              34.974114
            ],
            [
              -120.552962,
              34.975115
            ],
            [
              -120.548589,
              34.977869
            ],
            [
              -120.543783,
              34.979525
            ],
            [
              -120.535243,
              34.984499
            ],
            [
              -120.501,
              34.992109
            ],
            [
              -120.480698,
              34.99158
            ],
            [
              -120.453819,
              34.989241
            ],
            [
              -120.447953,
              34.988976
            ],
            [
              -120.443525,
              34.988626
            ],
            [
              -120.43827,
              34.987819
            ],
            [
              -120.435165,
              34.986572
            ],
            [
              -120.428034,
              34.982293
            ],
            [
              -120.42163,
              34.978934
            ],
            [
              -120.413013,
              34.974596
            ],
            [
              -120.404555,
              34.970333
            ],
            [
              -120.402815,
              34.969382
            ],
            [
              -120.376081,
              34.954665
            ],
            [
              -120.35488,
              34.940657
            ],
            [
              -120.351959,
              34.938955
            ],
            [
              -120.347795,
              34.936303
            ],
            [
              -120.340667,
              34.933958
            ],
            [
              -120.33471,
              34.930244
            ],
            [
              -120.327557,
              34.925006
            ],
            [
              -120.32463,
              34.921863
            ],
            [
              -120.324459,
              34.919332
            ],
            [
              -120.320452,
              34.911789
            ],
            [
              -120.317651,
              34.90655
            ],
            [
              -120.314034,
              34.905209
            ],
            [
              -120.309576,
              34.904032
            ],
            [
              -120.303517,
              34.905856
            ],
            [
              -120.301108,
              34.905935
            ],
            [
              -120.299194,
              34.907862
            ],
            [
              -120.298988,
              34.910223
            ],
            [
              -120.29774,
              34.91987
            ],
            [
              -120.296237,
              34.921435
            ],
            [
              -120.292741,
              34.934867
            ],
            [
              -120.294548,
              34.946594
            ],
            [
              -120.296887,
              34.950641
            ],
            [
              -120.302752,
              34.954277
            ],
            [
              -120.304139,
              34.958003
            ],
            [
              -120.302207,
              34.961568
            ],
            [
              -120.305348,
              34.967802
            ],
            [
              -120.315642,
              34.977554
            ],
            [
              -120.315998,
              34.984005
            ],
            [
              -120.321662,
              34.985859
            ],
            [
              -120.323686,
              34.98557
            ],
            [
              -120.327639,
              34.987343
            ],
            [
              -120.330465,
              34.991931
            ],
            [
              -120.335355,
              35.003512
            ],
            [
              -120.330927,
              35.009974
            ],
            [
              -120.325391,
              35.015651
            ],
            [
              -120.324479,
              35.016618
            ],
            [
              -120.323011,
              35.016887
            ],
            [
              -120.322143,
              35.01643
            ],
            [
              -120.311943,
              35.010585
            ],
            [
              -120.292459,
              35.014712
            ],
            [
              -120.280344,
              35.012136
            ],
            [
              -120.269515,
              35.020768
            ],
            [
              -120.264064,
              35.023771
            ],
            [
              -120.262873,
              35.025428
            ],
            [
              -120.261396,
              35.026606
            ],
            [
              -120.260437,
              35.02657
            ],
            [
              -120.260102,
              35.025618
            ],
            [
              -120.260918,
              35.024415
            ],
            [
              -120.260342,
              35.020893
            ],
            [
              -120.259396,
              35.018825
            ],
            [
              -120.257115,
              35.018215
            ],
            [
              -120.25623,
              35.019573
            ],
            [
              -120.254261,
              35.022945
            ],
            [
              -120.253468,
              35.026691
            ],
            [
              -120.252362,
              35.02872
            ],
            [
              -120.249805,
              35.030064
            ],
            [
              -120.248356,
              35.028572
            ],
            [
              -120.245334,
              35.024807
            ],
            [
              -120.242321,
              35.023037
            ],
            [
              -120.239788,
              35.02243
            ],
            [
              -120.236667,
              35.023132
            ],
            [
              -120.234164,
              35.023655
            ],
            [
              -120.23228,
              35.022213
            ],
            [
              -120.232356,
              35.021358
            ],
            [
              -120.23054,
              35.020881
            ],
            [
              -120.228213,
              35.021568
            ],
            [
              -120.22639,
              35.022411
            ],
            [
              -120.222628,
              35.022056
            ],
            [
              -120.219683,
              35.021629
            ],
            [
              -120.215555,
              35.022575
            ],
            [
              -120.212554,
              35.021116
            ],
            [
              -120.209816,
              35.021959
            ],
            [
              -120.210256,
              35.02279
            ],
            [
              -120.210135,
              35.023821
            ],
            [
              -120.209199,
              35.024102
            ],
            [
              -120.205758,
              35.025001
            ],
            [
              -120.203729,
              35.027812
            ],
            [
              -120.200128,
              35.029537
            ],
            [
              -120.195061,
              35.029689
            ],
            [
              -120.191025,
              35.030009
            ],
            [
              -120.18893,
              35.030532
            ],
            [
              -120.188235,
              35.03244
            ],
            [
              -120.186913,
              35.035522
            ],
            [
              -120.183906,
              35.037132
            ],
            [
              -120.180978,
              35.03765
            ],
            [
              -120.181591,
              35.040286
            ],
            [
              -120.182641,
              35.041023
            ],
            [
              -120.183151,
              35.041991
            ],
            [
              -120.182725,
              35.043061
            ],
            [
              -120.182064,
              35.043356
            ],
            [
              -120.182264,
              35.044536
            ],
            [
              -120.183447,
              35.04566
            ],
            [
              -120.184913,
              35.049094
            ],
            [
              -120.182914,
              35.052798
            ],
            [
              -120.180314,
              35.055795
            ],
            [
              -120.179527,
              35.060351
            ],
            [
              -120.179037,
              35.061904
            ],
            [
              -120.17433,
              35.063128
            ],
            [
              -120.17298,
              35.064604
            ],
            [
              -120.173224,
              35.066336
            ],
            [
              -120.173476,
              35.068858
            ],
            [
              -120.174697,
              35.069579
            ],
            [
              -120.173476,
              35.070464
            ],
            [
              -120.17301,
              35.070155
            ],
            [
              -120.171011,
              35.070864
            ],
            [
              -120.16944,
              35.072955
            ],
            [
              -120.168074,
              35.075049
            ],
            [
              -120.165602,
              35.075522
            ],
            [
              -120.16197,
              35.075953
            ],
            [
              -120.158819,
              35.077189
            ],
            [
              -120.155873,
              35.078077
            ],
            [
              -120.155309,
              35.079012
            ],
            [
              -120.155408,
              35.080557
            ],
            [
              -120.152982,
              35.08201
            ],
            [
              -120.151114,
              35.081733
            ],
            [
              -120.150486,
              35.081351
            ],
            [
              -120.149762,
              35.081377
            ],
            [
              -120.149458,
              35.082196
            ],
            [
              -120.149678,
              35.08301
            ],
            [
              -120.148541,
              35.084452
            ],
            [
              -120.146851,
              35.083995
            ],
            [
              -120.145055,
              35.083402
            ],
            [
              -120.142369,
              35.083082
            ],
            [
              -120.141033,
              35.084619
            ],
            [
              -120.140763,
              35.086065
            ],
            [
              -120.142452,
              35.087194
            ],
            [
              -120.143444,
              35.086775
            ],
            [
              -120.145176,
              35.087555
            ],
            [
              -120.145329,
              35.088731
            ],
            [
              -120.144833,
              35.090021
            ],
            [
              -120.143209,
              35.090343
            ],
            [
              -120.14175,
              35.090704
            ],
            [
              -120.141524,
              35.091266
            ],
            [
              -120.142801,
              35.092651
            ],
            [
              -120.143597,
              35.094644
            ],
            [
              -120.141712,
              35.09588
            ],
            [
              -120.140137,
              35.094762
            ],
            [
              -120.139118,
              35.094369
            ],
            [
              -120.137462,
              35.095052
            ],
            [
              -120.137043,
              35.095976
            ],
            [
              -120.136219,
              35.097177
            ],
            [
              -120.1347,
              35.09723
            ],
            [
              -120.132213,
              35.095403
            ],
            [
              -120.131168,
              35.095693
            ],
            [
              -120.129573,
              35.095091
            ],
            [
              -120.129673,
              35.094063
            ],
            [
              -120.130183,
              35.093099
            ],
            [
              -120.130557,
              35.091871
            ],
            [
              -120.129207,
              35.090791
            ],
            [
              -120.127223,
              35.091268
            ],
            [
              -120.126651,
              35.092626
            ],
            [
              -120.126826,
              35.094907
            ],
            [
              -120.125377,
              35.097379
            ],
            [
              -120.124553,
              35.098696
            ],
            [
              -120.123851,
              35.099721
            ],
            [
              -120.122828,
              35.098535
            ],
            [
              -120.121325,
              35.097786
            ],
            [
              -120.119287,
              35.098553
            ],
            [
              -120.118441,
              35.100679
            ],
            [
              -120.119255,
              35.10175
            ],
            [
              -120.118632,
              35.103704
            ],
            [
              -120.116874,
              35.104915
            ],
            [
              -120.115755,
              35.106675
            ],
            [
              -120.114496,
              35.105176
            ],
            [
              -120.112215,
              35.103452
            ],
            [
              -120.109239,
              35.103616
            ],
            [
              -120.1075,
              35.105817
            ],
            [
              -120.10782,
              35.10698
            ],
            [
              -120.106195,
              35.108514
            ],
            [
              -120.104273,
              35.108081
            ],
            [
              -120.103253,
              35.108156
            ],
            [
              -120.102621,
              35.108344
            ],
            [
              -120.10209,
              35.110291
            ],
            [
              -120.102655,
              35.111653
            ],
            [
              -120.100627,
              35.113236
            ],
            [
              -120.099168,
              35.112134
            ],
            [
              -120.099263,
              35.110637
            ],
            [
              -120.097913,
              35.109502
            ],
            [
              -120.096504,
              35.109992
            ],
            [
              -120.096268,
              35.111544
            ],
            [
              -120.094987,
              35.112992
            ],
            [
              -120.092156,
              35.113851
            ],
            [
              -120.086515,
              35.113522
            ],
            [
              -120.083297,
              35.114556
            ],
            [
              -120.080169,
              35.113835
            ],
            [
              -120.080513,
              35.112061
            ],
            [
              -120.083131,
              35.109938
            ],
            [
              -120.084373,
              35.109506
            ],
            [
              -120.085785,
              35.108995
            ],
            [
              -120.084434,
              35.107083
            ],
            [
              -120.08231,
              35.105897
            ],
            [
              -120.076299,
              35.10475
            ],
            [
              -120.072344,
              35.103952
            ],
            [
              -120.072037,
              35.102001
            ],
            [
              -120.069108,
              35.098619
            ],
            [
              -120.060853,
              35.09457
            ],
            [
              -120.059989,
              35.093076
            ],
            [
              -120.044172,
              35.08611
            ],
            [
              -120.037609,
              35.088753
            ],
            [
              -120.02463,
              35.081938
            ],
            [
              -120.02245,
              35.080538
            ],
            [
              -120.015863,
              35.081839
            ],
            [
              -120.013833,
              35.078303
            ],
            [
              -120.012415,
              35.075485
            ],
            [
              -120.000661,
              35.071806
            ],
            [
              -119.996816,
              35.067624
            ],
            [
              -119.984284,
              35.062223
            ],
            [
              -119.983327,
              35.061665
            ],
            [
              -119.978357,
              35.059064
            ],
            [
              -119.968858,
              35.056709
            ],
            [
              -119.960689,
              35.055712
            ],
            [
              -119.957476,
              35.057249
            ],
            [
              -119.955817,
              35.056549
            ],
            [
              -119.949004,
              35.056524
            ],
            [
              -119.945838,
              35.056045
            ],
            [
              -119.942008,
              35.055354
            ],
            [
              -119.937079,
              35.055418
            ],
            [
              -119.934005,
              35.055913
            ],
            [
              -119.929507,
              35.058227
            ],
            [
              -119.92729,
              35.059098
            ],
            [
              -119.92494,
              35.055709
            ],
            [
              -119.921791,
              35.052543
            ],
            [
              -119.914939,
              35.048319
            ],
            [
              -119.911897,
              35.047199
            ],
            [
              -119.910296,
              35.045542
            ],
            [
              -119.911097,
              35.044027
            ],
            [
              -119.909921,
              35.04021
            ],
            [
              -119.906986,
              35.038731
            ],
            [
              -119.905506,
              35.038011
            ],
            [
              -119.901924,
              35.038555
            ],
            [
              -119.898783,
              35.037119
            ],
            [
              -119.894105,
              35.038703
            ],
            [
              -119.891089,
              35.039543
            ],
            [
              -119.887669,
              35.040472
            ],
            [
              -119.884738,
              35.040059
            ],
            [
              -119.876936,
              35.038036
            ],
            [
              -119.873604,
              35.03552
            ],
            [
              -119.870416,
              35.035378
            ],
            [
              -119.86446,
              35.032975
            ],
            [
              -119.857499,
              35.031877
            ],
            [
              -119.849991,
              35.026667
            ],
            [
              -119.846465,
              35.021506
            ],
            [
              -119.844921,
              35.017451
            ],
            [
              -119.84118,
              35.015015
            ],
            [
              -119.832239,
              35.007041
            ],
            [
              -119.823406,
              35.004642
            ],
            [
              -119.815362,
              35.00107
            ],
            [
              -119.80973,
              35.001881
            ],
            [
              -119.806399,
              34.999105
            ],
            [
              -119.800812,
              34.997155
            ],
            [
              -119.794847,
              34.991317
            ],
            [
              -119.791539,
              34.989765
            ],
            [
              -119.782381,
              34.990264
            ],
            [
              -119.765697,
              34.98303
            ],
            [
              -119.755499,
              34.980132
            ],
            [
              -119.748062,
              34.974868
            ],
            [
              -119.728605,
              34.97511
            ],
            [
              -119.724948,
              34.973857
            ],
            [
              -119.716402,
              34.972192
            ],
            [
              -119.711207,
              34.974328
            ],
            [
              -119.707459,
              34.975629
            ],
            [
              -119.698917,
              34.975965
            ],
            [
              -119.681366,
              34.971778
            ],
            [
              -119.673994,
              34.973513
            ],
            [
              -119.668083,
              34.971401
            ],
            [
              -119.653573,
              34.961465
            ],
            [
              -119.645298,
              34.955117
            ],
            [
              -119.638571,
              34.953757
            ],
            [
              -119.634513,
              34.953703
            ],
            [
              -119.628632,
              34.950861
            ],
            [
              -119.622861,
              34.949475
            ],
            [
              -119.616061,
              34.950575
            ],
            [
              -119.607251,
              34.945568
            ],
            [
              -119.597141,
              34.938513
            ],
            [
              -119.591274,
              34.933592
            ],
            [
              -119.588159,
              34.929675
            ],
            [
              -119.582157,
              34.924499
            ],
            [
              -119.571459,
              34.918775
            ],
            [
              -119.561758,
              34.914075
            ],
            [
              -119.548515,
              34.906236
            ],
            [
              -119.542992,
              34.902553
            ],
            [
              -119.536557,
              34.898076
            ],
            [
              -119.535972,
              34.89772
            ],
            [
              -119.490455,
              34.897475
            ],
            [
              -119.472754,
              34.901174
            ],
            [
              -119.472719,
              35.076885
            ],
            [
              -119.490709,
              35.077208
            ],
            [
              -119.490632,
              35.091805
            ],
            [
              -119.560975,
              35.087673
            ],
            [
              -119.553641,
              35.179975
            ],
            [
              -119.571259,
              35.180292
            ],
            [
              -119.667056,
              35.174809
            ],
            [
              -119.666663,
              35.262527
            ],
            [
              -119.809449,
              35.263584
            ],
            [
              -119.809346,
              35.350865
            ],
            [
              -119.880172,
              35.351211
            ],
            [
              -119.880045,
              35.439133
            ],
            [
              -119.913659,
              35.439262
            ],
            [
              -119.997392,
              35.439495
            ],
            [
              -119.997382,
              35.468702
            ],
            [
              -120.015659,
              35.469039
            ],
            [
              -120.014602,
              35.483652
            ],
            [
              -120.033314,
              35.483648
            ],
            [
              -120.033241,
              35.498642
            ],
            [
              -120.05105,
              35.498627
            ],
            [
              -120.051237,
              35.512695
            ],
            [
              -120.068905,
              35.512779
            ],
            [
              -120.068657,
              35.52632
            ],
            [
              -120.086674,
              35.526554
            ],
            [
              -120.085922,
              35.614524
            ],
            [
              -120.193918,
              35.614359
            ],
            [
              -120.194146,
              35.789204
            ],
            [
              -120.213979,
              35.789276
            ],
            [
              -120.244551,
              35.789458
            ],
            [
              -120.250979,
              35.788977
            ],
            [
              -121.114454,
              35.79331
            ],
            [
              -121.125937,
              35.793941
            ],
            [
              -121.346362833935,
              35.7951826441526
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "06",
      "COUNTYFP": "081",
      "COUNTYNS": "00277305",
      "AFFGEOID": "0500000US06081",
      "GEOID": "06081",
      "NAME": "San Mateo",
      "LSAD": "06",
      "ALAND": 1161757245,
      "AWATER": 757313941,
      "County_state": "San Mateo,06"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -122.519533,
              37.537302
            ],
            [
              -122.516689,
              37.52134
            ],
            [
              -122.499289,
              37.495341
            ],
            [
              -122.493789,
              37.492341
            ],
            [
              -122.486749307094,
              37.494390271444594
            ],
            [
              -122.485888,
              37.494641
            ],
            [
              -122.472388,
              37.50054
            ],
            [
              -122.467888,
              37.49814
            ],
            [
              -122.452087,
              37.48054
            ],
            [
              -122.445987,
              37.461541
            ],
            [
              -122.443687,
              37.435941
            ],
            [
              -122.423286,
              37.392542
            ],
            [
              -122.409258,
              37.374805
            ],
            [
              -122.40085,
              37.359225
            ],
            [
              -122.401323,
              37.337009
            ],
            [
              -122.40476469464801,
              37.313374125966895
            ],
            [
              -122.411686,
              37.265844
            ],
            [
              -122.418452,
              37.248521
            ],
            [
              -122.419113,
              37.24147
            ],
            [
              -122.415822,
              37.232839
            ],
            [
              -122.408982,
              37.225258
            ],
            [
              -122.407181,
              37.219465
            ],
            [
              -122.405073,
              37.195791
            ],
            [
              -122.397065,
              37.187249
            ],
            [
              -122.390599,
              37.182988
            ],
            [
              -122.37927,
              37.181128
            ],
            [
              -122.367085,
              37.172817
            ],
            [
              -122.36179,
              37.163593
            ],
            [
              -122.359791,
              37.155574
            ],
            [
              -122.344029,
              37.144099
            ],
            [
              -122.337833,
              37.135936
            ],
            [
              -122.337085,
              37.130795
            ],
            [
              -122.338856,
              37.120854
            ],
            [
              -122.337071,
              37.117382
            ],
            [
              -122.330463,
              37.115338
            ],
            [
              -122.322971,
              37.11546
            ],
            [
              -122.313907,
              37.118161
            ],
            [
              -122.306139,
              37.116383
            ],
            [
              -122.292973615833,
              37.1073182889556
            ],
            [
              -122.289382,
              37.113472
            ],
            [
              -122.311782,
              37.147545
            ],
            [
              -122.317682,
              37.186945
            ],
            [
              -122.24266,
              37.190035
            ],
            [
              -122.242573,
              37.215134
            ],
            [
              -122.152774,
              37.215444
            ],
            [
              -122.152278,
              37.286055
            ],
            [
              -122.166838,
              37.295237
            ],
            [
              -122.16207,
              37.304233
            ],
            [
              -122.192665,
              37.31801
            ],
            [
              -122.175075,
              37.325741
            ],
            [
              -122.202467,
              37.360272
            ],
            [
              -122.190505,
              37.385253
            ],
            [
              -122.19454,
              37.407934
            ],
            [
              -122.190402,
              37.431472
            ],
            [
              -122.165302,
              37.452843
            ],
            [
              -122.144933,
              37.4582
            ],
            [
              -122.128008,
              37.452085
            ],
            [
              -122.108706487739,
              37.466261871117
            ],
            [
              -122.11655,
              37.471209
            ],
            [
              -122.125541,
              37.494265
            ],
            [
              -122.140142,
              37.507907
            ],
            [
              -122.149632,
              37.502671
            ],
            [
              -122.155686,
              37.501198
            ],
            [
              -122.163693387749,
              37.503045665668
            ],
            [
              -122.168449,
              37.504143
            ],
            [
              -122.194957,
              37.522469
            ],
            [
              -122.196593,
              37.537196
            ],
            [
              -122.214264,
              37.538505
            ],
            [
              -122.244372,
              37.55814
            ],
            [
              -122.251898,
              37.566321
            ],
            [
              -122.262698,
              37.572866
            ],
            [
              -122.305895,
              37.575484
            ],
            [
              -122.315713,
              37.583666
            ],
            [
              -122.315385,
              37.587265
            ],
            [
              -122.317676,
              37.590865
            ],
            [
              -122.360219,
              37.592501
            ],
            [
              -122.378545,
              37.605592
            ],
            [
              -122.373309,
              37.613773
            ],
            [
              -122.370364,
              37.614427
            ],
            [
              -122.358583,
              37.611155
            ],
            [
              -122.35531,
              37.615736
            ],
            [
              -122.365455,
              37.626208
            ],
            [
              -122.386072,
              37.637662
            ],
            [
              -122.387381,
              37.648462
            ],
            [
              -122.37789,
              37.650425
            ],
            [
              -122.3756,
              37.652389
            ],
            [
              -122.374291,
              37.662206
            ],
            [
              -122.387626,
              37.67906
            ],
            [
              -122.39319,
              37.707531
            ],
            [
              -122.391375121004,
              37.708331
            ],
            [
              -122.405292,
              37.708262
            ],
            [
              -122.420082,
              37.708231
            ],
            [
              -122.502426880724,
              37.7081329861058
            ],
            [
              -122.496784,
              37.686433
            ],
            [
              -122.49543075507701,
              37.665175171821396
            ],
            [
              -122.494085,
              37.644035
            ],
            [
              -122.496786,
              37.612136
            ],
            [
              -122.501386,
              37.599637
            ],
            [
              -122.517187,
              37.590637
            ],
            [
              -122.518088,
              37.576138
            ],
            [
              -122.513688,
              37.552239
            ],
            [
              -122.514789,
              37.546139
            ],
            [
              -122.516589,
              37.544939
            ],
            [
              -122.519533,
              37.537302
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "06",
      "COUNTYFP": "051",
      "COUNTYNS": "00277290",
      "AFFGEOID": "0500000US06051",
      "GEOID": "06051",
      "NAME": "Mono",
      "LSAD": "06",
      "ALAND": 7896838493,
      "AWATER": 214695673,
      "County_state": "Mono,06"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -119.649187,
              38.288832
            ],
            [
              -119.647834,
              38.282929
            ],
            [
              -119.640967,
              38.278073
            ],
            [
              -119.631415,
              38.271183
            ],
            [
              -119.620426,
              38.264698
            ],
            [
              -119.614853,
              38.262493
            ],
            [
              -119.61646,
              38.257844
            ],
            [
              -119.61904,
              38.255884
            ],
            [
              -119.617786,
              38.248399
            ],
            [
              -119.612361,
              38.241942
            ],
            [
              -119.605215,
              38.237094
            ],
            [
              -119.615991,
              38.231577
            ],
            [
              -119.623719,
              38.225671
            ],
            [
              -119.626286,
              38.219824
            ],
            [
              -119.625483,
              38.217256
            ],
            [
              -119.626507,
              38.214235
            ],
            [
              -119.624938,
              38.207722
            ],
            [
              -119.629233,
              38.204405
            ],
            [
              -119.629837,
              38.197111
            ],
            [
              -119.621948,
              38.192355
            ],
            [
              -119.598421,
              38.181697
            ],
            [
              -119.592266,
              38.185052
            ],
            [
              -119.582473,
              38.180947
            ],
            [
              -119.579035,
              38.173846
            ],
            [
              -119.577503,
              38.162032
            ],
            [
              -119.564818,
              38.155063
            ],
            [
              -119.553958,
              38.154264
            ],
            [
              -119.547952,
              38.148115
            ],
            [
              -119.540917,
              38.144455
            ],
            [
              -119.52972,
              38.143622
            ],
            [
              -119.513458,
              38.13726
            ],
            [
              -119.50604,
              38.139251
            ],
            [
              -119.505834,
              38.145797
            ],
            [
              -119.507525,
              38.152036
            ],
            [
              -119.505017,
              38.155749
            ],
            [
              -119.496264,
              38.153913
            ],
            [
              -119.496967,
              38.149297
            ],
            [
              -119.488929,
              38.137757
            ],
            [
              -119.477671,
              38.130205
            ],
            [
              -119.470736,
              38.122886
            ],
            [
              -119.471872,
              38.116089
            ],
            [
              -119.462277,
              38.105876
            ],
            [
              -119.450747,
              38.099228
            ],
            [
              -119.447529,
              38.096991
            ],
            [
              -119.440873,
              38.103557
            ],
            [
              -119.436951,
              38.107671
            ],
            [
              -119.426928,
              38.112493
            ],
            [
              -119.422601,
              38.107301
            ],
            [
              -119.404239,
              38.106396
            ],
            [
              -119.389879,
              38.09913
            ],
            [
              -119.375994,
              38.093043
            ],
            [
              -119.354078,
              38.084567
            ],
            [
              -119.334109,
              38.065244
            ],
            [
              -119.316717,
              38.047845
            ],
            [
              -119.307546,
              38.030525
            ],
            [
              -119.30886,
              38.007421
            ],
            [
              -119.309879,
              38.002271
            ],
            [
              -119.31819,
              37.985323
            ],
            [
              -119.32199,
              37.969023
            ],
            [
              -119.311789,
              37.951924
            ],
            [
              -119.302788,
              37.944924
            ],
            [
              -119.283187,
              37.933724
            ],
            [
              -119.265086,
              37.919625
            ],
            [
              -119.250985,
              37.9106
            ],
            [
              -119.238984,
              37.911626
            ],
            [
              -119.224084,
              37.903526
            ],
            [
              -119.213783,
              37.899126
            ],
            [
              -119.210683,
              37.893926
            ],
            [
              -119.207783,
              37.890026
            ],
            [
              -119.204383,
              37.888826
            ],
            [
              -119.206383,
              37.883327
            ],
            [
              -119.213782,
              37.863227
            ],
            [
              -119.215182,
              37.854027
            ],
            [
              -119.208482,
              37.846428
            ],
            [
              -119.202582,
              37.847528
            ],
            [
              -119.20036,
              37.84313
            ],
            [
              -119.20398,
              37.841928
            ],
            [
              -119.205881,
              37.835828
            ],
            [
              -119.212281,
              37.825628
            ],
            [
              -119.211581,
              37.815129
            ],
            [
              -119.204481,
              37.808929
            ],
            [
              -119.20388,
              37.793929
            ],
            [
              -119.21988,
              37.781329
            ],
            [
              -119.23008,
              37.77603
            ],
            [
              -119.24408,
              37.76803
            ],
            [
              -119.25008,
              37.75803
            ],
            [
              -119.253481,
              37.75713
            ],
            [
              -119.255183,
              37.75293
            ],
            [
              -119.254613,
              37.749005
            ],
            [
              -119.256279,
              37.74473
            ],
            [
              -119.263479,
              37.74073
            ],
            [
              -119.268979,
              37.73923
            ],
            [
              -119.255178,
              37.725331
            ],
            [
              -119.239264,
              37.727467
            ],
            [
              -119.216333,
              37.714123
            ],
            [
              -119.198491,
              37.736657
            ],
            [
              -119.170957,
              37.738094
            ],
            [
              -119.147891,
              37.732757
            ],
            [
              -119.124312,
              37.733944
            ],
            [
              -119.109694,
              37.724735
            ],
            [
              -119.093126,
              37.703627
            ],
            [
              -119.065166,
              37.684556
            ],
            [
              -119.054618,
              37.645135
            ],
            [
              -119.032565,
              37.630535
            ],
            [
              -119.034365,
              37.603536
            ],
            [
              -119.022363,
              37.585737
            ],
            [
              -119.002262,
              37.571137
            ],
            [
              -118.984861,
              37.567337
            ],
            [
              -118.97696,
              37.556837
            ],
            [
              -118.952661,
              37.565836
            ],
            [
              -118.92966,
              37.548936
            ],
            [
              -118.91706,
              37.550336
            ],
            [
              -118.901159,
              37.531236
            ],
            [
              -118.883658,
              37.520136
            ],
            [
              -118.881658,
              37.510336
            ],
            [
              -118.860758,
              37.501535
            ],
            [
              -118.850474,
              37.475796
            ],
            [
              -118.815526,
              37.487807
            ],
            [
              -118.797097,
              37.488712
            ],
            [
              -118.775014,
              37.463052
            ],
            [
              -117.832726,
              37.464929
            ],
            [
              -117.875927,
              37.497267
            ],
            [
              -117.904625,
              37.515836
            ],
            [
              -117.975776,
              37.569293
            ],
            [
              -118.039849,
              37.615245
            ],
            [
              -118.039798,
              37.615273
            ],
            [
              -118.052189,
              37.62493
            ],
            [
              -118.250947,
              37.768616
            ],
            [
              -118.42780036384801,
              37.8962298945818
            ],
            [
              -118.500958,
              37.949019
            ],
            [
              -118.571958,
              37.99993
            ],
            [
              -118.62159,
              38.034389
            ],
            [
              -118.714312,
              38.102185
            ],
            [
              -118.746598,
              38.124926
            ],
            [
              -118.771867,
              38.141871
            ],
            [
              -118.859087,
              38.204808
            ],
            [
              -118.922518,
              38.249919
            ],
            [
              -118.949673,
              38.26894
            ],
            [
              -119.000975,
              38.303675
            ],
            [
              -119.030078,
              38.325181
            ],
            [
              -119.082358,
              38.361267
            ],
            [
              -119.097161,
              38.372853
            ],
            [
              -119.125982,
              38.39317
            ],
            [
              -119.156982753766,
              38.414739167545896
            ],
            [
              -119.234966,
              38.468997
            ],
            [
              -119.250988,
              38.48078
            ],
            [
              -119.279262,
              38.499914
            ],
            [
              -119.328411217387,
              38.5347733644266
            ],
            [
              -119.333423,
              38.538328
            ],
            [
              -119.370117,
              38.563281
            ],
            [
              -119.375994,
              38.566793
            ],
            [
              -119.450623,
              38.619965
            ],
            [
              -119.450612,
              38.619964
            ],
            [
              -119.494022,
              38.649734
            ],
            [
              -119.494183,
              38.649852
            ],
            [
              -119.585437,
              38.713212
            ],
            [
              -119.579518,
              38.705609
            ],
            [
              -119.598647,
              38.670942
            ],
            [
              -119.614658,
              38.665879
            ],
            [
              -119.619237,
              38.604501
            ],
            [
              -119.599815,
              38.593348
            ],
            [
              -119.587367,
              38.558354
            ],
            [
              -119.568055,
              38.537707
            ],
            [
              -119.556217,
              38.516621
            ],
            [
              -119.556616,
              38.501702
            ],
            [
              -119.542862,
              38.499694
            ],
            [
              -119.542367,
              38.481657
            ],
            [
              -119.555863,
              38.470242
            ],
            [
              -119.556426,
              38.447465
            ],
            [
              -119.570009,
              38.43486
            ],
            [
              -119.561995,
              38.410734
            ],
            [
              -119.592409,
              38.398877
            ],
            [
              -119.601212,
              38.405354
            ],
            [
              -119.622093,
              38.393875
            ],
            [
              -119.607395,
              38.366458
            ],
            [
              -119.635575,
              38.353908
            ],
            [
              -119.628295,
              38.349733
            ],
            [
              -119.639205,
              38.32688
            ],
            [
              -119.642245,
              38.324195
            ],
            [
              -119.642576,
              38.315819
            ],
            [
              -119.64371,
              38.303592
            ],
            [
              -119.643532,
              38.291911
            ],
            [
              -119.649187,
              38.288832
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "06",
      "COUNTYFP": "073",
      "COUNTYNS": "00277301",
      "AFFGEOID": "0500000US06073",
      "GEOID": "06073",
      "NAME": "San Diego",
      "LSAD": "06",
      "ALAND": 10898787530,
      "AWATER": 822557348,
      "County_state": "San Diego,06"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -117.59616273186401,
              33.387091904360595
            ],
            [
              -117.59588,
              33.386629
            ],
            [
              -117.571722,
              33.378988
            ],
            [
              -117.547693,
              33.365491
            ],
            [
              -117.50565,
              33.334063
            ],
            [
              -117.469794,
              33.296417
            ],
            [
              -117.445583,
              33.268517
            ],
            [
              -117.401926,
              33.213598
            ],
            [
              -117.39148,
              33.202762
            ],
            [
              -117.362572,
              33.168437
            ],
            [
              -117.328359,
              33.121842
            ],
            [
              -117.315278,
              33.093504
            ],
            [
              -117.309771,
              33.07454
            ],
            [
              -117.29337,
              33.034642
            ],
            [
              -117.28077,
              33.012343
            ],
            [
              -117.277965080627,
              33.001137416190296
            ],
            [
              -117.26047,
              32.931245
            ],
            [
              -117.25447,
              32.900146
            ],
            [
              -117.25167,
              32.874346
            ],
            [
              -117.25250738527001,
              32.871573510415196
            ],
            [
              -117.25617,
              32.859447
            ],
            [
              -117.26067,
              32.852647
            ],
            [
              -117.26497,
              32.848947
            ],
            [
              -117.27387,
              32.851447
            ],
            [
              -117.28117,
              32.843047
            ],
            [
              -117.28217,
              32.839547
            ],
            [
              -117.280971,
              32.822247
            ],
            [
              -117.26107,
              32.803148
            ],
            [
              -117.25497,
              32.786948
            ],
            [
              -117.25257,
              32.752949
            ],
            [
              -117.25537,
              32.745449
            ],
            [
              -117.25757,
              32.72605
            ],
            [
              -117.255169,
              32.700051
            ],
            [
              -117.246069,
              32.669352
            ],
            [
              -117.236239,
              32.671353
            ],
            [
              -117.223868,
              32.683051
            ],
            [
              -117.213068,
              32.687751
            ],
            [
              -117.196767,
              32.688851
            ],
            [
              -117.192967,
              32.687751
            ],
            [
              -117.180366,
              32.681652
            ],
            [
              -117.168866,
              32.671952
            ],
            [
              -117.159865,
              32.660652
            ],
            [
              -117.139464,
              32.627054
            ],
            [
              -117.136664,
              32.618754
            ],
            [
              -117.132963,
              32.597054
            ],
            [
              -117.133176216707,
              32.5856314479488
            ],
            [
              -117.133363,
              32.575625
            ],
            [
              -117.129306589873,
              32.555837296016094
            ],
            [
              -117.124862,
              32.534156
            ],
            [
              -117.118867876573,
              32.5347062551835
            ],
            [
              -117.062759,
              32.539857
            ],
            [
              -117.026358,
              32.542457
            ],
            [
              -116.945956,
              32.550058
            ],
            [
              -116.928055,
              32.550758
            ],
            [
              -116.857154,
              32.557459
            ],
            [
              -116.85312903173102,
              32.5577878836934
            ],
            [
              -116.62705,
              32.576261
            ],
            [
              -116.540643,
              32.583747
            ],
            [
              -116.211033,
              32.610326
            ],
            [
              -116.125848,
              32.616673
            ],
            [
              -116.115427,
              32.61791
            ],
            [
              -116.10618141881301,
              32.6186413746915
            ],
            [
              -116.105634,
              32.72157
            ],
            [
              -116.103149,
              32.7265
            ],
            [
              -116.103714,
              32.803871
            ],
            [
              -116.103252,
              33.07467
            ],
            [
              -116.08109,
              33.074833
            ],
            [
              -116.085165,
              33.425932
            ],
            [
              -116.112623,
              33.425932
            ],
            [
              -116.821826,
              33.426873
            ],
            [
              -116.85473,
              33.426619
            ],
            [
              -116.859783,
              33.426838
            ],
            [
              -117.030861,
              33.426931
            ],
            [
              -117.031381,
              33.430134
            ],
            [
              -117.089024,
              33.430286
            ],
            [
              -117.141574,
              33.43261
            ],
            [
              -117.238239,
              33.43196
            ],
            [
              -117.242167,
              33.449015
            ],
            [
              -117.250495,
              33.451629
            ],
            [
              -117.259308,
              33.454513
            ],
            [
              -117.322403,
              33.475031
            ],
            [
              -117.370925,
              33.490549
            ],
            [
              -117.364272,
              33.505025
            ],
            [
              -117.509722,
              33.505019
            ],
            [
              -117.508614,
              33.469614
            ],
            [
              -117.538429,
              33.455643
            ],
            [
              -117.57848,
              33.453927
            ],
            [
              -117.59616273186401,
              33.387091904360595
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "06",
      "COUNTYFP": "055",
      "COUNTYNS": "00277292",
      "AFFGEOID": "0500000US06055",
      "GEOID": "06055",
      "NAME": "Napa",
      "LSAD": "06",
      "ALAND": 1938165958,
      "AWATER": 104249021,
      "County_state": "Napa,06"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -122.628293,
              38.623038
            ],
            [
              -122.646421,
              38.598593
            ],
            [
              -122.620846,
              38.560317
            ],
            [
              -122.602659,
              38.557496
            ],
            [
              -122.582148,
              38.549399
            ],
            [
              -122.566644,
              38.525543
            ],
            [
              -122.543893,
              38.519966
            ],
            [
              -122.548807,
              38.511627
            ],
            [
              -122.535534,
              38.501391
            ],
            [
              -122.544901,
              38.496285
            ],
            [
              -122.529592,
              38.469609
            ],
            [
              -122.516244,
              38.470872
            ],
            [
              -122.509728,
              38.458254
            ],
            [
              -122.482991,
              38.45271
            ],
            [
              -122.480003,
              38.44303
            ],
            [
              -122.497475,
              38.424306
            ],
            [
              -122.44789,
              38.379312
            ],
            [
              -122.457207,
              38.366993
            ],
            [
              -122.42439,
              38.344413
            ],
            [
              -122.394917,
              38.304479
            ],
            [
              -122.40277,
              38.300549
            ],
            [
              -122.404592,
              38.281423
            ],
            [
              -122.387242,
              38.273253
            ],
            [
              -122.389896,
              38.260695
            ],
            [
              -122.374587,
              38.244515
            ],
            [
              -122.366787,
              38.247015
            ],
            [
              -122.359241,
              38.209989
            ],
            [
              -122.349564,
              38.193972
            ],
            [
              -122.360257,
              38.197115
            ],
            [
              -122.371116,
              38.15772
            ],
            [
              -122.406786,
              38.155632
            ],
            [
              -122.19538,
              38.155017
            ],
            [
              -122.195971,
              38.162991
            ],
            [
              -122.199064,
              38.168727
            ],
            [
              -122.21508,
              38.179717
            ],
            [
              -122.205681,
              38.208216
            ],
            [
              -122.19398,
              38.213614
            ],
            [
              -122.19598,
              38.23041
            ],
            [
              -122.211081,
              38.252215
            ],
            [
              -122.196781,
              38.258415
            ],
            [
              -122.216882,
              38.262315
            ],
            [
              -122.212282,
              38.272814
            ],
            [
              -122.188381,
              38.271614
            ],
            [
              -122.203482,
              38.292914
            ],
            [
              -122.205982,
              38.315713
            ],
            [
              -122.133843,
              38.315612
            ],
            [
              -122.064779,
              38.315912
            ],
            [
              -122.061379,
              38.329611
            ],
            [
              -122.07338,
              38.360911
            ],
            [
              -122.089164,
              38.384662
            ],
            [
              -122.117983,
              38.414109
            ],
            [
              -122.126389,
              38.428918
            ],
            [
              -122.123582,
              38.447608
            ],
            [
              -122.104882,
              38.490807
            ],
            [
              -122.103281,
              38.513348
            ],
            [
              -122.111563,
              38.524051
            ],
            [
              -122.128123,
              38.583697
            ],
            [
              -122.151772,
              38.625297
            ],
            [
              -122.168349,
              38.619297
            ],
            [
              -122.161212,
              38.635761
            ],
            [
              -122.168301,
              38.655296
            ],
            [
              -122.183245,
              38.657136
            ],
            [
              -122.203422,
              38.677184
            ],
            [
              -122.208206,
              38.695536
            ],
            [
              -122.224206,
              38.699984
            ],
            [
              -122.225038,
              38.722335
            ],
            [
              -122.250111,
              38.755262
            ],
            [
              -122.259647,
              38.789917
            ],
            [
              -122.273983,
              38.809437
            ],
            [
              -122.287998,
              38.839931
            ],
            [
              -122.315359,
              38.839468
            ],
            [
              -122.326207,
              38.845932
            ],
            [
              -122.349968,
              38.835756
            ],
            [
              -122.385184,
              38.852475
            ],
            [
              -122.395056,
              38.864245
            ],
            [
              -122.403886,
              38.8556
            ],
            [
              -122.381988,
              38.8338
            ],
            [
              -122.373488,
              38.817
            ],
            [
              -122.379388,
              38.802101
            ],
            [
              -122.397989,
              38.804001
            ],
            [
              -122.412289,
              38.785801
            ],
            [
              -122.402889,
              38.772902
            ],
            [
              -122.415789,
              38.768002
            ],
            [
              -122.46389,
              38.705203
            ],
            [
              -122.627396,
              38.667506
            ],
            [
              -122.628293,
              38.623038
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "06",
      "COUNTYFP": "001",
      "COUNTYNS": "01675839",
      "AFFGEOID": "0500000US06001",
      "GEOID": "06001",
      "NAME": "Alameda",
      "LSAD": "06",
      "ALAND": 1909616630,
      "AWATER": 216916717,
      "County_state": "Alameda,06"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -122.333711,
              37.809797
            ],
            [
              -122.335675,
              37.799652
            ],
            [
              -122.331748,
              37.796052
            ],
            [
              -122.330962783411,
              37.7860343411807
            ],
            [
              -122.32915929691902,
              37.783172558054304
            ],
            [
              -122.318909,
              37.77904
            ],
            [
              -122.304345,
              37.774632
            ],
            [
              -122.293996,
              37.770416
            ],
            [
              -122.286139,
              37.769458
            ],
            [
              -122.275408,
              37.76735
            ],
            [
              -122.264101,
              37.764667
            ],
            [
              -122.253753,
              37.761218
            ],
            [
              -122.242638,
              37.753744
            ],
            [
              -122.244938,
              37.750294
            ],
            [
              -122.252226,
              37.747619
            ],
            [
              -122.257134,
              37.745001
            ],
            [
              -122.257953,
              37.739601
            ],
            [
              -122.255989,
              37.735674
            ],
            [
              -122.246826,
              37.72193
            ],
            [
              -122.221628,
              37.705567
            ],
            [
              -122.213774,
              37.698695
            ],
            [
              -122.203971,
              37.697769
            ],
            [
              -122.197411,
              37.692804
            ],
            [
              -122.179085,
              37.680041
            ],
            [
              -122.170904,
              37.676114
            ],
            [
              -122.163049,
              37.667933
            ],
            [
              -122.162802519462,
              37.6672730133697
            ],
            [
              -122.152905,
              37.640771
            ],
            [
              -122.14636,
              37.607391
            ],
            [
              -122.145378,
              37.600846
            ],
            [
              -122.147014,
              37.588411
            ],
            [
              -122.144396,
              37.581866
            ],
            [
              -122.137524,
              37.567467
            ],
            [
              -122.133924,
              37.562885
            ],
            [
              -122.128688,
              37.560594
            ],
            [
              -122.111998,
              37.528851
            ],
            [
              -122.11144457163401,
              37.5108510385602
            ],
            [
              -122.111344,
              37.50758
            ],
            [
              -122.092442,
              37.499489
            ],
            [
              -122.068539,
              37.491202
            ],
            [
              -122.059031,
              37.475548
            ],
            [
              -122.046554266444,
              37.4595373585317
            ],
            [
              -121.996671,
              37.467239
            ],
            [
              -121.975071,
              37.460639
            ],
            [
              -121.947087,
              37.467424
            ],
            [
              -121.925041,
              37.454186
            ],
            [
              -121.867784,
              37.483033
            ],
            [
              -121.855762,
              37.484537
            ],
            [
              -121.670399,
              37.48274
            ],
            [
              -121.472648,
              37.48217
            ],
            [
              -121.471925,
              37.481783
            ],
            [
              -121.469275,
              37.489688
            ],
            [
              -121.493183,
              37.502421
            ],
            [
              -121.50442,
              37.525863
            ],
            [
              -121.541833,
              37.530215
            ],
            [
              -121.556655,
              37.542732
            ],
            [
              -121.556959,
              37.743051
            ],
            [
              -121.556997,
              37.816488
            ],
            [
              -121.577005,
              37.811527
            ],
            [
              -121.870733,
              37.740086
            ],
            [
              -121.955261,
              37.719857
            ],
            [
              -122.011771,
              37.747428
            ],
            [
              -121.997771,
              37.763227
            ],
            [
              -122.004809,
              37.770571
            ],
            [
              -122.045473,
              37.798126
            ],
            [
              -122.106302,
              37.802194
            ],
            [
              -122.113812,
              37.802729
            ],
            [
              -122.14026,
              37.804562
            ],
            [
              -122.157392,
              37.817952
            ],
            [
              -122.176977,
              37.816227
            ],
            [
              -122.184179,
              37.833922
            ],
            [
              -122.221488,
              37.865026
            ],
            [
              -122.223878,
              37.878326
            ],
            [
              -122.248977,
              37.891222
            ],
            [
              -122.27108,
              37.905824
            ],
            [
              -122.313495917826,
              37.897210726126204
            ],
            [
              -122.313258,
              37.89701
            ],
            [
              -122.309986,
              37.892755
            ],
            [
              -122.310477,
              37.873938
            ],
            [
              -122.301313,
              37.847758
            ],
            [
              -122.303485201935,
              37.8330960517989
            ],
            [
              -122.303931,
              37.830087
            ],
            [
              -122.306222,
              37.827469
            ],
            [
              -122.317676,
              37.826814
            ],
            [
              -122.323567,
              37.823214
            ],
            [
              -122.333711,
              37.809797
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "06",
      "COUNTYFP": "113",
      "COUNTYNS": "00277321",
      "AFFGEOID": "0500000US06113",
      "GEOID": "06113",
      "NAME": "Yolo",
      "LSAD": "06",
      "ALAND": 2628256658,
      "AWATER": 22688556,
      "County_state": "Yolo,06"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -122.403941,
              38.925289
            ],
            [
              -122.421904,
              38.90377
            ],
            [
              -122.390816,
              38.867963
            ],
            [
              -122.395056,
              38.864245
            ],
            [
              -122.385184,
              38.852475
            ],
            [
              -122.349968,
              38.835756
            ],
            [
              -122.326207,
              38.845932
            ],
            [
              -122.315359,
              38.839468
            ],
            [
              -122.287998,
              38.839931
            ],
            [
              -122.273983,
              38.809437
            ],
            [
              -122.259647,
              38.789917
            ],
            [
              -122.250111,
              38.755262
            ],
            [
              -122.225038,
              38.722335
            ],
            [
              -122.224206,
              38.699984
            ],
            [
              -122.208206,
              38.695536
            ],
            [
              -122.203422,
              38.677184
            ],
            [
              -122.183245,
              38.657136
            ],
            [
              -122.168301,
              38.655296
            ],
            [
              -122.161212,
              38.635761
            ],
            [
              -122.168349,
              38.619297
            ],
            [
              -122.151772,
              38.625297
            ],
            [
              -122.128123,
              38.583697
            ],
            [
              -122.111563,
              38.524051
            ],
            [
              -122.103281,
              38.513348
            ],
            [
              -122.05694,
              38.517269
            ],
            [
              -122.03247,
              38.494304
            ],
            [
              -122.013901,
              38.488728
            ],
            [
              -121.978068,
              38.508277
            ],
            [
              -121.968048,
              38.519989
            ],
            [
              -121.940285,
              38.533384
            ],
            [
              -121.908969,
              38.529576
            ],
            [
              -121.888421,
              38.537819
            ],
            [
              -121.86012,
              38.538512
            ],
            [
              -121.81333,
              38.526023
            ],
            [
              -121.785811,
              38.523074
            ],
            [
              -121.770676,
              38.532229
            ],
            [
              -121.764717,
              38.525917
            ],
            [
              -121.738594,
              38.537704
            ],
            [
              -121.712034,
              38.537962
            ],
            [
              -121.694971,
              38.527012
            ],
            [
              -121.693969,
              38.314454
            ],
            [
              -121.690544,
              38.313731
            ],
            [
              -121.593273,
              38.313089
            ],
            [
              -121.583954,
              38.331652
            ],
            [
              -121.575216,
              38.327161
            ],
            [
              -121.560025,
              38.342071
            ],
            [
              -121.521641,
              38.360104
            ],
            [
              -121.516506,
              38.407239
            ],
            [
              -121.532425,
              38.429316
            ],
            [
              -121.502982,
              38.440718
            ],
            [
              -121.50509,
              38.469563
            ],
            [
              -121.543083,
              38.477356
            ],
            [
              -121.558069,
              38.501876
            ],
            [
              -121.551866,
              38.513806
            ],
            [
              -121.525178,
              38.518962
            ],
            [
              -121.528467,
              38.532106
            ],
            [
              -121.512618,
              38.541666
            ],
            [
              -121.520965,
              38.566205
            ],
            [
              -121.506269,
              38.586305
            ],
            [
              -121.511566,
              38.600904
            ],
            [
              -121.553609,
              38.603326
            ],
            [
              -121.5651,
              38.642304
            ],
            [
              -121.594069,
              38.644106
            ],
            [
              -121.633774,
              38.686164
            ],
            [
              -121.605774,
              38.718319
            ],
            [
              -121.602894,
              38.735838
            ],
            [
              -121.592622,
              38.76286
            ],
            [
              -121.60811,
              38.78086
            ],
            [
              -121.624113,
              38.785174
            ],
            [
              -121.636175,
              38.767085
            ],
            [
              -121.664214,
              38.767802
            ],
            [
              -121.659035,
              38.75433
            ],
            [
              -121.673033,
              38.742847
            ],
            [
              -121.670018,
              38.75931
            ],
            [
              -121.685998,
              38.763371
            ],
            [
              -121.701251,
              38.803028
            ],
            [
              -121.723346,
              38.803759
            ],
            [
              -121.723414,
              38.825617
            ],
            [
              -121.730631,
              38.834654
            ],
            [
              -121.723149,
              38.851306
            ],
            [
              -121.749747,
              38.871243
            ],
            [
              -121.784265,
              38.856685
            ],
            [
              -121.814814,
              38.876582
            ],
            [
              -121.803989,
              38.90172
            ],
            [
              -121.805525,
              38.915065
            ],
            [
              -121.835488,
              38.924481
            ],
            [
              -122.340172,
              38.924246
            ],
            [
              -122.354729,
              38.925225
            ],
            [
              -122.403941,
              38.925289
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "06",
      "COUNTYFP": "033",
      "COUNTYNS": "00277281",
      "AFFGEOID": "0500000US06033",
      "GEOID": "06033",
      "NAME": "Lake",
      "LSAD": "06",
      "ALAND": 3254348631,
      "AWATER": 188852161,
      "County_state": "Lake,06"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -123.085012,
              39.095294
            ],
            [
              -123.093013,
              39.071994
            ],
            [
              -123.065114,
              39.064393
            ],
            [
              -123.056115,
              39.048815
            ],
            [
              -123.056212,
              39.021096
            ],
            [
              -123.028035,
              38.996593
            ],
            [
              -122.98664,
              38.997283
            ],
            [
              -122.977429,
              38.9829
            ],
            [
              -122.977712,
              38.946693
            ],
            [
              -122.969919,
              38.932663
            ],
            [
              -122.949067,
              38.925442
            ],
            [
              -122.948865,
              38.90022
            ],
            [
              -122.911386,
              38.901529
            ],
            [
              -122.89389,
              38.895524
            ],
            [
              -122.894633,
              38.880832
            ],
            [
              -122.876495,
              38.87313
            ],
            [
              -122.839994,
              38.866024
            ],
            [
              -122.821592,
              38.85014
            ],
            [
              -122.808674,
              38.836643
            ],
            [
              -122.795842,
              38.836838
            ],
            [
              -122.77248,
              38.82153
            ],
            [
              -122.748742,
              38.80419
            ],
            [
              -122.74822,
              38.787795
            ],
            [
              -122.735338,
              38.777888
            ],
            [
              -122.720085,
              38.753545
            ],
            [
              -122.7117,
              38.750763
            ],
            [
              -122.71038,
              38.732082
            ],
            [
              -122.691835,
              38.710946
            ],
            [
              -122.646569,
              38.706177
            ],
            [
              -122.627396,
              38.667506
            ],
            [
              -122.46389,
              38.705203
            ],
            [
              -122.415789,
              38.768002
            ],
            [
              -122.402889,
              38.772902
            ],
            [
              -122.412289,
              38.785801
            ],
            [
              -122.397989,
              38.804001
            ],
            [
              -122.379388,
              38.802101
            ],
            [
              -122.373488,
              38.817
            ],
            [
              -122.381988,
              38.8338
            ],
            [
              -122.403886,
              38.8556
            ],
            [
              -122.395056,
              38.864245
            ],
            [
              -122.390816,
              38.867963
            ],
            [
              -122.421904,
              38.90377
            ],
            [
              -122.403941,
              38.925289
            ],
            [
              -122.354729,
              38.925225
            ],
            [
              -122.340172,
              38.924246
            ],
            [
              -122.373023,
              38.939817
            ],
            [
              -122.409118,
              38.962793
            ],
            [
              -122.41931,
              38.989177
            ],
            [
              -122.408617,
              39.006985
            ],
            [
              -122.413416,
              39.020218
            ],
            [
              -122.43729,
              39.021357
            ],
            [
              -122.468178,
              39.044761
            ],
            [
              -122.492215,
              39.054092
            ],
            [
              -122.486249,
              39.08251
            ],
            [
              -122.500909,
              39.121075
            ],
            [
              -122.496835,
              39.140501
            ],
            [
              -122.478227,
              39.139782
            ],
            [
              -122.479527,
              39.158038
            ],
            [
              -122.47729,
              39.17395
            ],
            [
              -122.494012,
              39.178098
            ],
            [
              -122.498538,
              39.191091
            ],
            [
              -122.513788,
              39.196007
            ],
            [
              -122.515331,
              39.209215
            ],
            [
              -122.573442,
              39.204288
            ],
            [
              -122.578573,
              39.210291
            ],
            [
              -122.63516,
              39.223052
            ],
            [
              -122.650138,
              39.213922
            ],
            [
              -122.665362,
              39.217548
            ],
            [
              -122.680927,
              39.238726
            ],
            [
              -122.712454,
              39.25701
            ],
            [
              -122.7297,
              39.273486
            ],
            [
              -122.757095,
              39.284307
            ],
            [
              -122.775043,
              39.311778
            ],
            [
              -122.76531,
              39.342743
            ],
            [
              -122.752355,
              39.346047
            ],
            [
              -122.743482,
              39.36813
            ],
            [
              -122.78509,
              39.382975
            ],
            [
              -122.739062,
              39.383266
            ],
            [
              -122.741748,
              39.43523
            ],
            [
              -122.732601,
              39.450754
            ],
            [
              -122.732276,
              39.493345
            ],
            [
              -122.736548,
              39.50851
            ],
            [
              -122.735639,
              39.580668
            ],
            [
              -122.885352,
              39.580108
            ],
            [
              -122.89031,
              39.529014
            ],
            [
              -122.936696,
              39.530713
            ],
            [
              -122.936697,
              39.516396
            ],
            [
              -123.025236,
              39.518709
            ],
            [
              -123.063181,
              39.503539
            ],
            [
              -123.063015,
              39.452482
            ],
            [
              -123.073115,
              39.452682
            ],
            [
              -123.075114,
              39.407683
            ],
            [
              -123.056314,
              39.407583
            ],
            [
              -123.057113,
              39.378684
            ],
            [
              -123.048613,
              39.364084
            ],
            [
              -123.037914,
              39.363885
            ],
            [
              -123.037712,
              39.336185
            ],
            [
              -123.019711,
              39.321785
            ],
            [
              -123.019612,
              39.277986
            ],
            [
              -123.004514,
              39.278187
            ],
            [
              -123.00361,
              39.257387
            ],
            [
              -122.994484,
              39.256757
            ],
            [
              -122.994839,
              39.235924
            ],
            [
              -123.01271,
              39.236188
            ],
            [
              -123.01251,
              39.220989
            ],
            [
              -123.04011,
              39.19249
            ],
            [
              -123.045811,
              39.173492
            ],
            [
              -123.077812,
              39.173791
            ],
            [
              -123.078112,
              39.13874
            ],
            [
              -123.087512,
              39.138392
            ],
            [
              -123.085012,
              39.095294
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "06",
      "COUNTYFP": "037",
      "COUNTYNS": "00277283",
      "AFFGEOID": "0500000US06037",
      "GEOID": "06037",
      "NAME": "Los Angeles",
      "LSAD": "06",
      "ALAND": 10510588451,
      "AWATER": 1794793532,
      "County_state": "Los Angeles,06"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -118.603375,
              33.478098
            ],
            [
              -118.60403,
              33.47654
            ],
            [
              -118.601185,
              33.469853
            ],
            [
              -118.593969,
              33.467198
            ],
            [
              -118.575901,
              33.448261
            ],
            [
              -118.570927,
              33.439351
            ],
            [
              -118.563442,
              33.434381
            ],
            [
              -118.558715,
              33.433419
            ],
            [
              -118.53738,
              33.434608
            ],
            [
              -118.52323,
              33.430733
            ],
            [
              -118.516267,
              33.425075
            ],
            [
              -118.515914,
              33.422417
            ],
            [
              -118.503952,
              33.424234
            ],
            [
              -118.48875,
              33.419826
            ],
            [
              -118.484949,
              33.412131
            ],
            [
              -118.478465,
              33.38632
            ],
            [
              -118.482609,
              33.369914
            ],
            [
              -118.48877,
              33.356649
            ],
            [
              -118.481886,
              33.344123
            ],
            [
              -118.465368,
              33.326056
            ],
            [
              -118.456309,
              33.32182
            ],
            [
              -118.440047,
              33.318638
            ],
            [
              -118.402941,
              33.320901
            ],
            [
              -118.374768,
              33.320065
            ],
            [
              -118.360332,
              33.31533
            ],
            [
              -118.343249,
              33.305234
            ],
            [
              -118.325244,
              33.299075
            ],
            [
              -118.316787,
              33.301137
            ],
            [
              -118.305084,
              33.310323
            ],
            [
              -118.303174,
              33.320264
            ],
            [
              -118.310213,
              33.335795
            ],
            [
              -118.316083,
              33.342928
            ],
            [
              -118.32446,
              33.348782
            ],
            [
              -118.365094,
              33.388374
            ],
            [
              -118.368301,
              33.40711
            ],
            [
              -118.370323,
              33.409285
            ],
            [
              -118.382037,
              33.409883
            ],
            [
              -118.423576,
              33.427258
            ],
            [
              -118.445812,
              33.428907
            ],
            [
              -118.477646,
              33.448392
            ],
            [
              -118.48557,
              33.446213
            ],
            [
              -118.499669,
              33.447879
            ],
            [
              -118.500212,
              33.449592
            ],
            [
              -118.530702,
              33.468071
            ],
            [
              -118.54453,
              33.474119
            ],
            [
              -118.585936,
              33.473819
            ],
            [
              -118.598783,
              33.477939
            ],
            [
              -118.603375,
              33.478098
            ]
          ]
        ],
        [
          [
            [
              -118.605534,
              33.030999
            ],
            [
              -118.606559,
              33.01469
            ],
            [
              -118.596037,
              33.015357
            ],
            [
              -118.586928,
              33.008281
            ],
            [
              -118.573522,
              32.969183
            ],
            [
              -118.560887,
              32.957891
            ],
            [
              -118.551134,
              32.945155
            ],
            [
              -118.535823,
              32.90628
            ],
            [
              -118.524531,
              32.895488
            ],
            [
              -118.524641,
              32.893175
            ],
            [
              -118.507193,
              32.876264
            ],
            [
              -118.508095,
              32.871321
            ],
            [
              -118.506902,
              32.868503
            ],
            [
              -118.496298,
              32.851572
            ],
            [
              -118.487908,
              32.84459
            ],
            [
              -118.476074,
              32.841754
            ],
            [
              -118.44492,
              32.820593
            ],
            [
              -118.428372,
              32.806872
            ],
            [
              -118.42943,
              32.805429
            ],
            [
              -118.425634,
              32.800595
            ],
            [
              -118.401268,
              32.820338
            ],
            [
              -118.394565,
              32.823978
            ],
            [
              -118.387375,
              32.825327
            ],
            [
              -118.379968,
              32.824545
            ],
            [
              -118.36053,
              32.819921
            ],
            [
              -118.356541,
              32.817311
            ],
            [
              -118.353504,
              32.821962
            ],
            [
              -118.369984,
              32.839273
            ],
            [
              -118.446771,
              32.895424
            ],
            [
              -118.460623,
              32.90951
            ],
            [
              -118.479039,
              32.920363
            ],
            [
              -118.485288,
              32.923545
            ],
            [
              -118.496811,
              32.933847
            ],
            [
              -118.529228,
              32.970921
            ],
            [
              -118.540069,
              32.980933
            ],
            [
              -118.559171,
              33.006291
            ],
            [
              -118.564527,
              33.018637
            ],
            [
              -118.564445,
              33.024914
            ],
            [
              -118.569013,
              33.029151
            ],
            [
              -118.57516,
              33.033961
            ],
            [
              -118.594033,
              33.035951
            ],
            [
              -118.605534,
              33.030999
            ]
          ]
        ],
        [
          [
            [
              -118.940801,
              34.074967
            ],
            [
              -118.944887329171,
              34.0453397020464
            ],
            [
              -118.938081,
              34.043383
            ],
            [
              -118.928048,
              34.045847
            ],
            [
              -118.896159,
              34.039207
            ],
            [
              -118.854653,
              34.034215
            ],
            [
              -118.84038,
              34.027527
            ],
            [
              -118.821579,
              34.013959
            ],
            [
              -118.805114,
              34.001239
            ],
            [
              -118.787094,
              34.019545
            ],
            [
              -118.783433,
              34.021543
            ],
            [
              -118.744952,
              34.032103
            ],
            [
              -118.732391,
              34.032743
            ],
            [
              -118.706215,
              34.029383
            ],
            [
              -118.679366,
              34.033255
            ],
            [
              -118.67543,
              34.037479
            ],
            [
              -118.668358,
              34.038887
            ],
            [
              -118.609652,
              34.036424
            ],
            [
              -118.603572,
              34.039048
            ],
            [
              -118.569235,
              34.04164
            ],
            [
              -118.56714103361601,
              34.041388916435096
            ],
            [
              -118.543115,
              34.038508
            ],
            [
              -118.519514,
              34.027509
            ],
            [
              -118.502813,
              34.015509
            ],
            [
              -118.484212,
              33.99771
            ],
            [
              -118.48272858809501,
              33.9959124449355
            ],
            [
              -118.460611,
              33.969111
            ],
            [
              -118.44241,
              33.940312
            ],
            [
              -118.42201784513202,
              33.9015907979799
            ],
            [
              -118.412708,
              33.883913
            ],
            [
              -118.392107,
              33.840915
            ],
            [
              -118.391507,
              33.815415
            ],
            [
              -118.394307,
              33.804315
            ],
            [
              -118.405007,
              33.800215
            ],
            [
              -118.423407,
              33.782015
            ],
            [
              -118.428407,
              33.774715
            ],
            [
              -118.411211,
              33.741985
            ],
            [
              -118.396606,
              33.735917
            ],
            [
              -118.385006,
              33.741417
            ],
            [
              -118.360505,
              33.736817
            ],
            [
              -118.354705,
              33.732317
            ],
            [
              -118.333295892977,
              33.7211848352577
            ],
            [
              -118.32280902508,
              33.7157319436011
            ],
            [
              -118.317205,
              33.712818
            ],
            [
              -118.297104,
              33.708319
            ],
            [
              -118.277208,
              33.707091
            ],
            [
              -118.258687,
              33.703741
            ],
            [
              -118.232646410548,
              33.7135504234136
            ],
            [
              -118.21222,
              33.721245
            ],
            [
              -118.183342,
              33.723186
            ],
            [
              -118.1837,
              33.736118
            ],
            [
              -118.187701,
              33.749218
            ],
            [
              -118.180831,
              33.763072
            ],
            [
              -118.1755,
              33.763617
            ],
            [
              -118.1569,
              33.760317
            ],
            [
              -118.14660647971701,
              33.7572972498137
            ],
            [
              -118.132698,
              33.753217
            ],
            [
              -118.115658466483,
              33.7429181141995
            ],
            [
              -118.09197,
              33.758472
            ],
            [
              -118.093099,
              33.786152
            ],
            [
              -118.084597,
              33.803216
            ],
            [
              -118.071827,
              33.815446
            ],
            [
              -118.063162,
              33.81961
            ],
            [
              -118.063268,
              33.82422
            ],
            [
              -118.058988,
              33.835207
            ],
            [
              -118.058918,
              33.846121
            ],
            [
              -118.058655,
              33.846127
            ],
            [
              -118.037598,
              33.866013
            ],
            [
              -118.028714,
              33.866242
            ],
            [
              -118.028782,
              33.873322
            ],
            [
              -118.011383,
              33.873431
            ],
            [
              -117.999393,
              33.888175
            ],
            [
              -117.994057,
              33.888218
            ],
            [
              -117.976586,
              33.906407
            ],
            [
              -117.976498,
              33.94605
            ],
            [
              -117.966691,
              33.946058
            ],
            [
              -117.925836,
              33.946028
            ],
            [
              -117.919725,
              33.947668
            ],
            [
              -117.783287,
              33.946411
            ],
            [
              -117.802539,
              33.975551
            ],
            [
              -117.785062,
              34.004809
            ],
            [
              -117.767483,
              34.004611
            ],
            [
              -117.767752,
              34.019429
            ],
            [
              -117.766233,
              34.023289
            ],
            [
              -117.744066,
              34.019808
            ],
            [
              -117.730125,
              34.021371
            ],
            [
              -117.711067,
              34.079536
            ],
            [
              -117.70429,
              34.095055
            ],
            [
              -117.693545,
              34.121627
            ],
            [
              -117.678501,
              34.161993
            ],
            [
              -117.677405,
              34.166103
            ],
            [
              -117.663777,
              34.220334
            ],
            [
              -117.656255,
              34.249765
            ],
            [
              -117.646374,
              34.28917
            ],
            [
              -117.650459,
              34.339025
            ],
            [
              -117.652246,
              34.360809
            ],
            [
              -117.652685,
              34.366165
            ],
            [
              -117.655235,
              34.397222
            ],
            [
              -117.660052,
              34.498629
            ],
            [
              -117.667034,
              34.558008
            ],
            [
              -117.667087,
              34.67552
            ],
            [
              -117.667292,
              34.822526
            ],
            [
              -117.774368,
              34.823301
            ],
            [
              -118.326281,
              34.819726
            ],
            [
              -118.587591,
              34.818022
            ],
            [
              -118.854253,
              34.817772
            ],
            [
              -118.854576,
              34.803027
            ],
            [
              -118.877289,
              34.803212
            ],
            [
              -118.881729,
              34.817802
            ],
            [
              -118.894474,
              34.817972
            ],
            [
              -118.881364,
              34.790629
            ],
            [
              -118.796842,
              34.616567
            ],
            [
              -118.738618,
              34.498969
            ],
            [
              -118.722716,
              34.466686
            ],
            [
              -118.722668,
              34.466587
            ],
            [
              -118.722616,
              34.466483
            ],
            [
              -118.6929,
              34.406088
            ],
            [
              -118.670593,
              34.360692
            ],
            [
              -118.652285,
              34.323392
            ],
            [
              -118.636789,
              34.291804
            ],
            [
              -118.633461,
              34.269522
            ],
            [
              -118.632548,
              34.263429
            ],
            [
              -118.632537,
              34.241948
            ],
            [
              -118.648113,
              34.240435
            ],
            [
              -118.650138,
              34.240403
            ],
            [
              -118.667708,
              34.236692
            ],
            [
              -118.66778,
              34.216517
            ],
            [
              -118.667944,
              34.199166
            ],
            [
              -118.668152,
              34.168195
            ],
            [
              -118.693834,
              34.168557
            ],
            [
              -118.703392,
              34.168591
            ],
            [
              -118.723374,
              34.167861
            ],
            [
              -118.788889,
              34.168214
            ],
            [
              -118.856473,
              34.126765
            ],
            [
              -118.940801,
              34.074967
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "06",
      "COUNTYFP": "021",
      "COUNTYNS": "00277275",
      "AFFGEOID": "0500000US06021",
      "GEOID": "06021",
      "NAME": "Glenn",
      "LSAD": "06",
      "ALAND": 3403149421,
      "AWATER": 33704225,
      "County_state": "Glenn,06"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -122.937654,
              39.798156
            ],
            [
              -122.938413,
              39.748112
            ],
            [
              -122.912516,
              39.748165
            ],
            [
              -122.912876,
              39.708723
            ],
            [
              -122.892676,
              39.708898
            ],
            [
              -122.890413,
              39.622916
            ],
            [
              -122.884497,
              39.610761
            ],
            [
              -122.885352,
              39.580108
            ],
            [
              -122.735639,
              39.580668
            ],
            [
              -122.736548,
              39.50851
            ],
            [
              -122.732276,
              39.493345
            ],
            [
              -122.732601,
              39.450754
            ],
            [
              -122.741748,
              39.43523
            ],
            [
              -122.739062,
              39.383266
            ],
            [
              -122.594548,
              39.385542
            ],
            [
              -122.192334,
              39.385257
            ],
            [
              -122.136006,
              39.385494
            ],
            [
              -122.136161,
              39.414499
            ],
            [
              -122.008857,
              39.413567
            ],
            [
              -122.003828,
              39.396994
            ],
            [
              -122.009735,
              39.383864
            ],
            [
              -121.890013,
              39.383864
            ],
            [
              -121.8708,
              39.449559
            ],
            [
              -121.87171,
              39.490771
            ],
            [
              -121.877341,
              39.509549
            ],
            [
              -121.856532,
              39.536904
            ],
            [
              -121.945194,
              39.536057
            ],
            [
              -121.974031,
              39.52885
            ],
            [
              -121.994021,
              39.533926
            ],
            [
              -122.003246,
              39.558228
            ],
            [
              -121.992273,
              39.562259
            ],
            [
              -122.00334,
              39.57326
            ],
            [
              -121.995062,
              39.582295
            ],
            [
              -121.995767,
              39.603164
            ],
            [
              -121.979555,
              39.614791
            ],
            [
              -121.992364,
              39.6296
            ],
            [
              -121.987075,
              39.643951
            ],
            [
              -121.968188,
              39.648348
            ],
            [
              -121.97765,
              39.66424
            ],
            [
              -121.994343,
              39.671458
            ],
            [
              -121.961435,
              39.677289
            ],
            [
              -121.941583,
              39.698113
            ],
            [
              -121.950951,
              39.715004
            ],
            [
              -121.949868,
              39.733001
            ],
            [
              -121.957239,
              39.719088
            ],
            [
              -121.967868,
              39.722397
            ],
            [
              -121.960472,
              39.736396
            ],
            [
              -121.983846,
              39.735413
            ],
            [
              -122.032375,
              39.780171
            ],
            [
              -122.023828,
              39.797674
            ],
            [
              -122.046471,
              39.797648
            ],
            [
              -122.911794,
              39.799485
            ],
            [
              -122.917545,
              39.79837
            ],
            [
              -122.937654,
              39.798156
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "06",
      "COUNTYFP": "087",
      "COUNTYNS": "00277308",
      "AFFGEOID": "0500000US06087",
      "GEOID": "06087",
      "NAME": "Santa Cruz",
      "LSAD": "06",
      "ALAND": 1152967426,
      "AWATER": 419570389,
      "County_state": "Santa Cruz,06"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -122.317682,
              37.186945
            ],
            [
              -122.311782,
              37.147545
            ],
            [
              -122.289382,
              37.113472
            ],
            [
              -122.292973615833,
              37.1073182889556
            ],
            [
              -122.284882,
              37.101747
            ],
            [
              -122.260481,
              37.072548
            ],
            [
              -122.252181,
              37.059448
            ],
            [
              -122.20618,
              37.013949
            ],
            [
              -122.186879,
              37.00345
            ],
            [
              -122.155078,
              36.98085
            ],
            [
              -122.140578,
              36.97495
            ],
            [
              -122.105976,
              36.955951
            ],
            [
              -122.06894563398,
              36.9487611830101
            ],
            [
              -122.066421,
              36.948271
            ],
            [
              -122.050122,
              36.948523
            ],
            [
              -122.027174,
              36.95115
            ],
            [
              -122.023373,
              36.96215
            ],
            [
              -122.012373,
              36.96455
            ],
            [
              -121.983896,
              36.958727
            ],
            [
              -121.975871,
              36.954051
            ],
            [
              -121.972771,
              36.954151
            ],
            [
              -121.95167,
              36.97145
            ],
            [
              -121.93947,
              36.97805
            ],
            [
              -121.930069,
              36.97815
            ],
            [
              -121.906468,
              36.96895
            ],
            [
              -121.894667,
              36.961851
            ],
            [
              -121.880167,
              36.950151
            ],
            [
              -121.862266,
              36.931552
            ],
            [
              -121.827664,
              36.879353
            ],
            [
              -121.810551858284,
              36.8506482495196
            ],
            [
              -121.786856,
              36.885038
            ],
            [
              -121.7725,
              36.883767
            ],
            [
              -121.745288,
              36.909341
            ],
            [
              -121.715417,
              36.908871
            ],
            [
              -121.699956,
              36.919683
            ],
            [
              -121.680621,
              36.903285
            ],
            [
              -121.661241,
              36.914177
            ],
            [
              -121.644001,
              36.893996
            ],
            [
              -121.629457,
              36.911688
            ],
            [
              -121.607354,
              36.899452
            ],
            [
              -121.581354,
              36.899152
            ],
            [
              -121.581154,
              36.919252
            ],
            [
              -121.607155,
              36.926051
            ],
            [
              -121.624755,
              36.940451
            ],
            [
              -121.645791,
              36.93233
            ],
            [
              -121.66613,
              36.96434
            ],
            [
              -121.693303,
              36.96823
            ],
            [
              -121.695358,
              36.98515
            ],
            [
              -121.717878,
              36.995561
            ],
            [
              -121.732312,
              36.989664
            ],
            [
              -121.738697,
              36.989991
            ],
            [
              -121.718762,
              37.007557
            ],
            [
              -121.725826,
              37.020793
            ],
            [
              -121.7576,
              37.04963
            ],
            [
              -121.778257,
              37.056065
            ],
            [
              -121.78594,
              37.06193
            ],
            [
              -121.809076,
              37.069301
            ],
            [
              -121.8184,
              37.08382
            ],
            [
              -121.84629,
              37.09702
            ],
            [
              -121.860421,
              37.104037
            ],
            [
              -121.890867,
              37.105256
            ],
            [
              -121.93415,
              37.12331
            ],
            [
              -121.99109,
              37.14427
            ],
            [
              -122.002934,
              37.158458
            ],
            [
              -122.026174,
              37.166854
            ],
            [
              -122.03075,
              37.18999
            ],
            [
              -122.055087,
              37.212497
            ],
            [
              -122.065933,
              37.210413
            ],
            [
              -122.08981,
              37.22327
            ],
            [
              -122.121638,
              37.25856
            ],
            [
              -122.15057,
              37.2762
            ],
            [
              -122.151571,
              37.285346
            ],
            [
              -122.152278,
              37.286055
            ],
            [
              -122.152774,
              37.215444
            ],
            [
              -122.242573,
              37.215134
            ],
            [
              -122.24266,
              37.190035
            ],
            [
              -122.317682,
              37.186945
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "06",
      "COUNTYFP": "023",
      "COUNTYNS": "01681908",
      "AFFGEOID": "0500000US06023",
      "GEOID": "06023",
      "NAME": "Humboldt",
      "LSAD": "06",
      "ALAND": 9241251740,
      "AWATER": 1254039383,
      "County_state": "Humboldt,06"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -124.408601,
              40.443201
            ],
            [
              -124.409591,
              40.438076
            ],
            [
              -124.402623,
              40.422105
            ],
            [
              -124.391496,
              40.407047
            ],
            [
              -124.379082,
              40.398828
            ],
            [
              -124.373599,
              40.392923
            ],
            [
              -124.365357,
              40.374855
            ],
            [
              -124.362796,
              40.350046
            ],
            [
              -124.356595,
              40.335016
            ],
            [
              -124.353124,
              40.331425
            ],
            [
              -124.347853,
              40.314634
            ],
            [
              -124.363634,
              40.276212
            ],
            [
              -124.363414,
              40.260974
            ],
            [
              -124.352715,
              40.250453
            ],
            [
              -124.34307,
              40.243979
            ],
            [
              -124.327691,
              40.23737
            ],
            [
              -124.320912,
              40.226617
            ],
            [
              -124.296497,
              40.208816
            ],
            [
              -124.258405,
              40.184277
            ],
            [
              -124.231095,
              40.171581
            ],
            [
              -124.214895,
              40.160902
            ],
            [
              -124.187874,
              40.130542
            ],
            [
              -124.170767,
              40.124207
            ],
            [
              -124.139952,
              40.11635
            ],
            [
              -124.110549,
              40.103765
            ],
            [
              -124.087086,
              40.078442
            ],
            [
              -124.080709,
              40.06611
            ],
            [
              -124.079983,
              40.029773
            ],
            [
              -124.072509,
              40.022657
            ],
            [
              -124.068908,
              40.021307
            ],
            [
              -124.065069,
              40.024785
            ],
            [
              -124.056408,
              40.024305
            ],
            [
              -124.035904,
              40.013319
            ],
            [
              -124.02393814948901,
              40.0012844214495
            ],
            [
              -123.544458,
              40.001923
            ],
            [
              -123.544385,
              40.034099
            ],
            [
              -123.544273,
              40.07627
            ],
            [
              -123.544129,
              40.133666
            ],
            [
              -123.54403,
              40.19701
            ],
            [
              -123.5444,
              40.269889
            ],
            [
              -123.544508,
              40.374726
            ],
            [
              -123.544347,
              40.456924
            ],
            [
              -123.544291,
              40.53566
            ],
            [
              -123.544507,
              40.591174
            ],
            [
              -123.544243,
              40.636004
            ],
            [
              -123.544451,
              40.693805
            ],
            [
              -123.543102,
              40.734014
            ],
            [
              -123.546219,
              40.740397
            ],
            [
              -123.550742,
              40.749858
            ],
            [
              -123.55113,
              40.756912
            ],
            [
              -123.550541,
              40.765813
            ],
            [
              -123.549502,
              40.774429
            ],
            [
              -123.557845,
              40.787961
            ],
            [
              -123.561047,
              40.790505
            ],
            [
              -123.560159,
              40.794983
            ],
            [
              -123.555308,
              40.794673
            ],
            [
              -123.556225,
              40.802764
            ],
            [
              -123.559525,
              40.810093
            ],
            [
              -123.566004,
              40.82269
            ],
            [
              -123.566281,
              40.836579
            ],
            [
              -123.574176,
              40.845677
            ],
            [
              -123.574827,
              40.856755
            ],
            [
              -123.584401,
              40.861085
            ],
            [
              -123.587228,
              40.869042
            ],
            [
              -123.597997,
              40.877615
            ],
            [
              -123.600911,
              40.880374
            ],
            [
              -123.6022,
              40.889359
            ],
            [
              -123.607154,
              40.894149
            ],
            [
              -123.606772,
              40.905376
            ],
            [
              -123.614664,
              40.912926
            ],
            [
              -123.615869,
              40.923742
            ],
            [
              -123.622276,
              40.926845
            ],
            [
              -123.617494,
              40.931144
            ],
            [
              -123.609562,
              40.929355
            ],
            [
              -123.587861,
              40.927755
            ],
            [
              -123.584261,
              40.931262
            ],
            [
              -123.572405,
              40.936858
            ],
            [
              -123.568761,
              40.942655
            ],
            [
              -123.564361,
              40.949055
            ],
            [
              -123.55516,
              40.947455
            ],
            [
              -123.541659,
              40.934656
            ],
            [
              -123.531959,
              40.933356
            ],
            [
              -123.519358,
              40.926856
            ],
            [
              -123.496657,
              40.916157
            ],
            [
              -123.485457,
              40.915357
            ],
            [
              -123.479957,
              40.918457
            ],
            [
              -123.475658,
              40.930656
            ],
            [
              -123.466003,
              40.937902
            ],
            [
              -123.460245,
              40.94073
            ],
            [
              -123.449488,
              40.943133
            ],
            [
              -123.444761,
              40.951853
            ],
            [
              -123.450357,
              40.958957
            ],
            [
              -123.447416,
              40.966621
            ],
            [
              -123.441593,
              40.970603
            ],
            [
              -123.434955,
              40.97974
            ],
            [
              -123.427758,
              40.992656
            ],
            [
              -123.425755,
              41.003511
            ],
            [
              -123.41613,
              41.010023
            ],
            [
              -123.409048,
              41.019166
            ],
            [
              -123.409406,
              41.030482
            ],
            [
              -123.420542,
              41.03781
            ],
            [
              -123.422454,
              41.041197
            ],
            [
              -123.423842,
              41.044585
            ],
            [
              -123.423534,
              41.051495
            ],
            [
              -123.428326,
              41.061047
            ],
            [
              -123.454774,
              41.068652
            ],
            [
              -123.460694,
              41.080846
            ],
            [
              -123.456451,
              41.090493
            ],
            [
              -123.452258,
              41.08944
            ],
            [
              -123.441224,
              41.0948
            ],
            [
              -123.435827,
              41.10655
            ],
            [
              -123.429566,
              41.131306
            ],
            [
              -123.429964,
              41.149979
            ],
            [
              -123.425225,
              41.164903
            ],
            [
              -123.409598,
              41.17664
            ],
            [
              -123.408291,
              41.179944
            ],
            [
              -123.417095,
              41.197002
            ],
            [
              -123.435348,
              41.213361
            ],
            [
              -123.437897,
              41.226897
            ],
            [
              -123.455356,
              41.236873
            ],
            [
              -123.442776,
              41.249475
            ],
            [
              -123.446812,
              41.276041
            ],
            [
              -123.461649,
              41.282281
            ],
            [
              -123.455475,
              41.292819
            ],
            [
              -123.46344,
              41.31773
            ],
            [
              -123.47748,
              41.327751
            ],
            [
              -123.48251,
              41.35365
            ],
            [
              -123.474085,
              41.366193
            ],
            [
              -123.487831,
              41.376934
            ],
            [
              -123.50117,
              41.382567
            ],
            [
              -123.661363,
              41.38209
            ],
            [
              -123.770239,
              41.380776
            ],
            [
              -123.770551,
              41.464193
            ],
            [
              -123.885695,
              41.465844
            ],
            [
              -124.065520778075,
              41.4647394651375
            ],
            [
              -124.063076,
              41.439579
            ],
            [
              -124.072294,
              41.374844
            ],
            [
              -124.079015,
              41.347135
            ],
            [
              -124.092284,
              41.287695
            ],
            [
              -124.106389,
              41.240682
            ],
            [
              -124.106986,
              41.229678
            ],
            [
              -124.122677,
              41.189726
            ],
            [
              -124.1438,
              41.144686
            ],
            [
              -124.149674,
              41.140845
            ],
            [
              -124.158539,
              41.143021
            ],
            [
              -124.163988,
              41.138675
            ],
            [
              -124.165414,
              41.129822
            ],
            [
              -124.159065,
              41.121957
            ],
            [
              -124.160556,
              41.099011
            ],
            [
              -124.154513,
              41.087159
            ],
            [
              -124.154028,
              41.059923
            ],
            [
              -124.153622,
              41.05355
            ],
            [
              -124.151266,
              41.051101
            ],
            [
              -124.148939,
              41.051467
            ],
            [
              -124.147216,
              41.052884
            ],
            [
              -124.142867,
              41.054032
            ],
            [
              -124.138217,
              41.054342
            ],
            [
              -124.132946,
              41.052482
            ],
            [
              -124.125448,
              41.048504
            ],
            [
              -124.112165,
              41.028173
            ],
            [
              -124.118147,
              40.989263
            ],
            [
              -124.137066,
              40.925732
            ],
            [
              -124.158322,
              40.876069
            ],
            [
              -124.176715,
              40.843618
            ],
            [
              -124.201921,
              40.805111
            ],
            [
              -124.228244,
              40.76939
            ],
            [
              -124.248406,
              40.735166
            ],
            [
              -124.289119,
              40.67963
            ],
            [
              -124.312558,
              40.641333
            ],
            [
              -124.315141,
              40.639526
            ],
            [
              -124.329404,
              40.61643
            ],
            [
              -124.363545,
              40.548698
            ],
            [
              -124.379096,
              40.522865
            ],
            [
              -124.382816,
              40.519
            ],
            [
              -124.387023,
              40.504954
            ],
            [
              -124.383224,
              40.499852
            ],
            [
              -124.38494,
              40.48982
            ],
            [
              -124.396642,
              40.462119
            ],
            [
              -124.408601,
              40.443201
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "06",
      "COUNTYFP": "049",
      "COUNTYNS": "00277289",
      "AFFGEOID": "0500000US06049",
      "GEOID": "06049",
      "NAME": "Modoc",
      "LSAD": "06",
      "ALAND": 10140960164,
      "AWATER": 745420669,
      "County_state": "Modoc,06"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -121.457213,
              41.949945
            ],
            [
              -121.456922,
              41.776302
            ],
            [
              -121.448981,
              41.776392
            ],
            [
              -121.448699,
              41.429383
            ],
            [
              -121.446495,
              41.183484
            ],
            [
              -121.331786,
              41.183886
            ],
            [
              -120.876669,
              41.183977
            ],
            [
              -119.999866,
              41.183974
            ],
            [
              -119.999471,
              41.499894
            ],
            [
              -119.99828,
              41.618765
            ],
            [
              -119.998855,
              41.624893
            ],
            [
              -119.998287,
              41.749892
            ],
            [
              -119.999276,
              41.874891
            ],
            [
              -119.999168,
              41.99454
            ],
            [
              -120.001058,
              41.995139
            ],
            [
              -120.181563,
              41.994588
            ],
            [
              -120.286424,
              41.993058
            ],
            [
              -120.326005,
              41.993122
            ],
            [
              -120.328540815297,
              41.9931316036052
            ],
            [
              -120.501069,
              41.993785
            ],
            [
              -120.647173,
              41.993084
            ],
            [
              -120.692219,
              41.993677
            ],
            [
              -120.693941,
              41.993676
            ],
            [
              -120.812279,
              41.994183
            ],
            [
              -120.879481,
              41.993781
            ],
            [
              -120.879924974291,
              41.993779694142894
            ],
            [
              -121.035195,
              41.993323
            ],
            [
              -121.094926,
              41.994658
            ],
            [
              -121.126093,
              41.99601
            ],
            [
              -121.247616,
              41.997054
            ],
            [
              -121.251099,
              41.99757
            ],
            [
              -121.309981,
              41.997612
            ],
            [
              -121.334385,
              41.996655
            ],
            [
              -121.335734,
              41.996518
            ],
            [
              -121.340517,
              41.99622
            ],
            [
              -121.360253,
              41.99668
            ],
            [
              -121.376101,
              41.997026
            ],
            [
              -121.434977,
              41.997022
            ],
            [
              -121.43961,
              41.99708
            ],
            [
              -121.44754015233201,
              41.9971688011849
            ],
            [
              -121.447563,
              41.950642
            ],
            [
              -121.457213,
              41.949945
            ]
          ]
        ]
      ]
    }
  }
]
};