export const counties_de = {
  "type":"FeatureCollection","name":"de_counties","features":[
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "10",
      "COUNTYFP": "003",
      "COUNTYNS": "00217270",
      "AFFGEOID": "0500000US10003",
      "GEOID": "10003",
      "NAME": "New Castle",
      "LSAD": "06",
      "ALAND": 1104150372,
      "AWATER": 175375756,
      "County_state": "New Castle,10"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -75.570798,
              39.626768
            ],
            [
              -75.571759,
              39.623584
            ],
            [
              -75.567694,
              39.613744
            ],
            [
              -75.561934,
              39.605216
            ],
            [
              -75.5583634034576,
              39.6055740017641
            ],
            [
              -75.55587,
              39.605824
            ],
            [
              -75.556734,
              39.606688
            ],
            [
              -75.557502,
              39.609184
            ],
            [
              -75.556878,
              39.612144
            ],
            [
              -75.558446,
              39.617296
            ],
            [
              -75.559614,
              39.624208
            ],
            [
              -75.559102,
              39.629056
            ],
            [
              -75.559446,
              39.629812
            ],
            [
              -75.570798,
              39.626768
            ]
          ]
        ],
        [
          [
            [
              -75.581696,
              39.603414
            ],
            [
              -75.58491,
              39.593318
            ],
            [
              -75.578977,
              39.585888
            ],
            [
              -75.564927,
              39.583248
            ],
            [
              -75.560188,
              39.592175
            ],
            [
              -75.581696,
              39.603414
            ]
          ]
        ],
        [
          [
            [
              -75.7888710853167,
              39.6714125051951
            ],
            [
              -75.788895,
              39.65858
            ],
            [
              -75.788299266797,
              39.64834905831869
            ],
            [
              -75.787686,
              39.637817
            ],
            [
              -75.787126,
              39.630935
            ],
            [
              -75.7864211340645,
              39.6216610375593
            ],
            [
              -75.78561658130309,
              39.6110754897058
            ],
            [
              -75.78102,
              39.550598
            ],
            [
              -75.7798688816967,
              39.5364912794919
            ],
            [
              -75.779789684394,
              39.535520732666996
            ],
            [
              -75.7797240184808,
              39.5347160102669
            ],
            [
              -75.7753341711683,
              39.480919324960006
            ],
            [
              -75.7700344831288,
              39.415972726733294
            ],
            [
              -75.7669076051649,
              39.3776534737305
            ],
            [
              -75.766895,
              39.377499
            ],
            [
              -75.7603074473469,
              39.2967999434366
            ],
            [
              -75.684863,
              39.296898
            ],
            [
              -75.665811,
              39.290567
            ],
            [
              -75.64857,
              39.295026
            ],
            [
              -75.619136,
              39.310079
            ],
            [
              -75.609385,
              39.308801
            ],
            [
              -75.584341,
              39.308718
            ],
            [
              -75.556205,
              39.335325
            ],
            [
              -75.551626,
              39.35394
            ],
            [
              -75.535849,
              39.350617
            ],
            [
              -75.5123720823948,
              39.3656561384634
            ],
            [
              -75.512996,
              39.366153
            ],
            [
              -75.521682,
              39.387871
            ],
            [
              -75.523583,
              39.391583
            ],
            [
              -75.535977,
              39.409384
            ],
            [
              -75.538512,
              39.416502
            ],
            [
              -75.55589,
              39.430351
            ],
            [
              -75.57183,
              39.438897
            ],
            [
              -75.570985,
              39.442486
            ],
            [
              -75.578914,
              39.44788
            ],
            [
              -75.580185,
              39.450786
            ],
            [
              -75.589439,
              39.460812
            ],
            [
              -75.589901,
              39.462022
            ],
            [
              -75.593068,
              39.477996
            ],
            [
              -75.593068,
              39.479186
            ],
            [
              -75.587729,
              39.495369
            ],
            [
              -75.587729,
              39.496353
            ],
            [
              -75.576436,
              39.509195
            ],
            [
              -75.566933,
              39.508273
            ],
            [
              -75.560728,
              39.520472
            ],
            [
              -75.570362,
              39.527223
            ],
            [
              -75.569418,
              39.539124
            ],
            [
              -75.569359,
              39.540589
            ],
            [
              -75.565636,
              39.558509
            ],
            [
              -75.564649,
              39.559922
            ],
            [
              -75.5641182880885,
              39.5606837276848
            ],
            [
              -75.563034,
              39.56224
            ],
            [
              -75.570783,
              39.56728
            ],
            [
              -75.571599,
              39.567728
            ],
            [
              -75.586016,
              39.578448
            ],
            [
              -75.586608,
              39.57888
            ],
            [
              -75.5872,
              39.580256
            ],
            [
              -75.587744,
              39.580672
            ],
            [
              -75.591984,
              39.583248
            ],
            [
              -75.592224,
              39.583568
            ],
            [
              -75.603584,
              39.58896
            ],
            [
              -75.60464,
              39.58992
            ],
            [
              -75.611873,
              39.597408
            ],
            [
              -75.611905,
              39.597568
            ],
            [
              -75.613793,
              39.606192
            ],
            [
              -75.613473,
              39.606832
            ],
            [
              -75.613477,
              39.606861
            ],
            [
              -75.6134736873228,
              39.606868426370596
            ],
            [
              -75.613233,
              39.607408
            ],
            [
              -75.613665,
              39.61256
            ],
            [
              -75.613345,
              39.613056
            ],
            [
              -75.614273,
              39.61464
            ],
            [
              -75.614929,
              39.615952
            ],
            [
              -75.614065,
              39.61832
            ],
            [
              -75.613377,
              39.620288
            ],
            [
              -75.613153,
              39.62096
            ],
            [
              -75.611969,
              39.621968
            ],
            [
              -75.587147,
              39.651012
            ],
            [
              -75.5846612667907,
              39.6515810004134
            ],
            [
              -75.562246,
              39.656712
            ],
            [
              -75.55659985812079,
              39.6629486051198
            ],
            [
              -75.5300418313125,
              39.692284022030904
            ],
            [
              -75.529744,
              39.692613
            ],
            [
              -75.509742,
              39.686113
            ],
            [
              -75.509042,
              39.694513
            ],
            [
              -75.507162,
              39.696961
            ],
            [
              -75.504042,
              39.698313
            ],
            [
              -75.496241,
              39.701413
            ],
            [
              -75.4924016254181,
              39.7090133945805
            ],
            [
              -75.491341,
              39.711113
            ],
            [
              -75.488553,
              39.714833
            ],
            [
              -75.485241,
              39.715813
            ],
            [
              -75.483141,
              39.715513
            ],
            [
              -75.481741,
              39.714546
            ],
            [
              -75.47894,
              39.713813
            ],
            [
              -75.47764,
              39.715013
            ],
            [
              -75.476888,
              39.718337
            ],
            [
              -75.477432,
              39.720561
            ],
            [
              -75.47724,
              39.724713
            ],
            [
              -75.47544,
              39.728713
            ],
            [
              -75.475384,
              39.731057
            ],
            [
              -75.474168,
              39.735473
            ],
            [
              -75.4703433594153,
              39.741789204982595
            ],
            [
              -75.469239,
              39.743613
            ],
            [
              -75.46923823133349,
              39.7436148224833
            ],
            [
              -75.463039,
              39.758313
            ],
            [
              -75.463339,
              39.761213
            ],
            [
              -75.459439,
              39.765813
            ],
            [
              -75.452339,
              39.769013
            ],
            [
              -75.449295,
              39.774436
            ],
            [
              -75.450212,
              39.774761
            ],
            [
              -75.450688,
              39.775064
            ],
            [
              -75.4503558506191,
              39.7753143722085
            ],
            [
              -75.4383,
              39.784402
            ],
            [
              -75.43628802100329,
              39.7851357978497
            ],
            [
              -75.4115142510838,
              39.7941711501703
            ],
            [
              -75.4054892080789,
              39.7963685705255
            ],
            [
              -75.405414,
              39.796396
            ],
            [
              -75.415062,
              39.801919
            ],
            [
              -75.428027,
              39.809228
            ],
            [
              -75.453715,
              39.820359
            ],
            [
              -75.45987721341919,
              39.8226134056804
            ],
            [
              -75.46243421691631,
              39.82354886877079
            ],
            [
              -75.463312,
              39.82387
            ],
            [
              -75.46343882090001,
              39.823907709640096
            ],
            [
              -75.481207,
              39.829191
            ],
            [
              -75.498805,
              39.833411
            ],
            [
              -75.49968327104519,
              39.833546370246296
            ],
            [
              -75.518405,
              39.836432
            ],
            [
              -75.539309,
              39.838355
            ],
            [
              -75.53994382136939,
              39.8383719291137
            ],
            [
              -75.570433,
              39.839185
            ],
            [
              -75.579821,
              39.838714
            ],
            [
              -75.593643,
              39.837658
            ],
            [
              -75.5948740549263,
              39.8374831442788
            ],
            [
              -75.595734,
              39.837361
            ],
            [
              -75.61724,
              39.834227
            ],
            [
              -75.634706,
              39.83041
            ],
            [
              -75.641523,
              39.828616
            ],
            [
              -75.662846,
              39.821425
            ],
            [
              -75.6671626247441,
              39.819550051624496
            ],
            [
              -75.686039,
              39.811351
            ],
            [
              -75.701275,
              39.802918
            ],
            [
              -75.71010888507239,
              39.796989379588695
            ],
            [
              -75.717059,
              39.792325
            ],
            [
              -75.7195088833789,
              39.7904171627208
            ],
            [
              -75.72390952185569,
              39.7869901823956
            ],
            [
              -75.727156,
              39.784462
            ],
            [
              -75.736614,
              39.776103
            ],
            [
              -75.744536,
              39.768206
            ],
            [
              -75.753228,
              39.757989
            ],
            [
              -75.7595457568876,
              39.748993919484
            ],
            [
              -75.760529,
              39.747594
            ],
            [
              -75.76626,
              39.738178
            ],
            [
              -75.773786,
              39.7222
            ],
            [
              -75.788596,
              39.722199
            ],
            [
              -75.788632,
              39.700668
            ],
            [
              -75.788632,
              39.700412
            ],
            [
              -75.788895,
              39.682287
            ],
            [
              -75.788853,
              39.681117
            ],
            [
              -75.7888710853167,
              39.6714125051951
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "10",
      "COUNTYFP": "001",
      "COUNTYNS": "00217271",
      "AFFGEOID": "0500000US10001",
      "GEOID": "10001",
      "NAME": "Kent",
      "LSAD": "06",
      "ALAND": 1518568163,
      "AWATER": 549099845,
      "County_state": "Kent,10"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -75.7603074473469,
              39.2967999434366
            ],
            [
              -75.75617871424829,
              39.246221999941795
            ],
            [
              -75.756177,
              39.246201
            ],
            [
              -75.75125,
              39.177984
            ],
            [
              -75.749578,
              39.165033
            ],
            [
              -75.747889,
              39.143517
            ],
            [
              -75.7478730007744,
              39.1432793370846
            ],
            [
              -75.746341,
              39.120522
            ],
            [
              -75.746013,
              39.115137
            ],
            [
              -75.74403,
              39.09487
            ],
            [
              -75.742408676419,
              39.074550775064196
            ],
            [
              -75.728119928006,
              38.8954771487671
            ],
            [
              -75.726041,
              38.869423
            ],
            [
              -75.725777,
              38.868279
            ],
            [
              -75.724273,
              38.847901
            ],
            [
              -75.724214,
              38.846802
            ],
            [
              -75.723093,
              38.833272
            ],
            [
              -75.722821,
              38.830123
            ],
            [
              -75.72280102322149,
              38.8298507048386
            ],
            [
              -75.555013,
              38.835649
            ],
            [
              -75.520439,
              38.855742
            ],
            [
              -75.508168,
              38.878951
            ],
            [
              -75.490453,
              38.889664
            ],
            [
              -75.484819,
              38.903753
            ],
            [
              -75.465153,
              38.91131
            ],
            [
              -75.401413,
              38.918355
            ],
            [
              -75.40104,
              38.947031
            ],
            [
              -75.393134,
              38.95414
            ],
            [
              -75.380649,
              38.961873
            ],
            [
              -75.350213,
              38.947438
            ],
            [
              -75.321266,
              38.953455
            ],
            [
              -75.31192256161259,
              38.945916812795794
            ],
            [
              -75.312546,
              38.94928
            ],
            [
              -75.312546,
              38.951065
            ],
            [
              -75.311607,
              38.967637
            ],
            [
              -75.314951,
              38.980775
            ],
            [
              -75.318354,
              38.988191
            ],
            [
              -75.34089,
              39.01996
            ],
            [
              -75.345763,
              39.024857
            ],
            [
              -75.379873,
              39.04879
            ],
            [
              -75.387914,
              39.051174
            ],
            [
              -75.396277,
              39.057884
            ],
            [
              -75.395806,
              39.059211
            ],
            [
              -75.400294,
              39.065645
            ],
            [
              -75.402035,
              39.066885
            ],
            [
              -75.4020280846737,
              39.06706467528859
            ],
            [
              -75.401193,
              39.088762
            ],
            [
              -75.410625,
              39.156246
            ],
            [
              -75.4102249917875,
              39.1593624692399
            ],
            [
              -75.408266,
              39.174625
            ],
            [
              -75.400144,
              39.186456
            ],
            [
              -75.398584,
              39.186616
            ],
            [
              -75.39479,
              39.188354
            ],
            [
              -75.393015,
              39.204512
            ],
            [
              -75.396892,
              39.216141
            ],
            [
              -75.404745,
              39.222666
            ],
            [
              -75.40550885923369,
              39.22358483376409
            ],
            [
              -75.405716,
              39.223834
            ],
            [
              -75.405927,
              39.243631
            ],
            [
              -75.404823,
              39.245898
            ],
            [
              -75.402964,
              39.254626
            ],
            [
              -75.408376,
              39.264698
            ],
            [
              -75.427953,
              39.285049
            ],
            [
              -75.435374,
              39.296676
            ],
            [
              -75.435551,
              39.297546
            ],
            [
              -75.436936,
              39.309379
            ],
            [
              -75.439027,
              39.313384
            ],
            [
              -75.460423,
              39.328236
            ],
            [
              -75.469324,
              39.33082
            ],
            [
              -75.479963,
              39.336577
            ],
            [
              -75.479845,
              39.337472
            ],
            [
              -75.490377,
              39.342818
            ],
            [
              -75.491688,
              39.343963
            ],
            [
              -75.493148,
              39.345527
            ],
            [
              -75.494122,
              39.34658
            ],
            [
              -75.491797,
              39.351845
            ],
            [
              -75.494158,
              39.354613
            ],
            [
              -75.505276,
              39.359169
            ],
            [
              -75.511788,
              39.365191
            ],
            [
              -75.5123720823948,
              39.3656561384634
            ],
            [
              -75.535849,
              39.350617
            ],
            [
              -75.551626,
              39.35394
            ],
            [
              -75.556205,
              39.335325
            ],
            [
              -75.584341,
              39.308718
            ],
            [
              -75.609385,
              39.308801
            ],
            [
              -75.619136,
              39.310079
            ],
            [
              -75.64857,
              39.295026
            ],
            [
              -75.665811,
              39.290567
            ],
            [
              -75.684863,
              39.296898
            ],
            [
              -75.7603074473469,
              39.2967999434366
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "10",
      "COUNTYFP": "005",
      "COUNTYNS": "00217269",
      "AFFGEOID": "0500000US10005",
      "GEOID": "10005",
      "NAME": "Sussex",
      "LSAD": "06",
      "ALAND": 2424522544,
      "AWATER": 674194633,
      "County_state": "Sussex,10"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -75.72280102322149,
              38.8298507048386
            ],
            [
              -75.722239,
              38.82219
            ],
            [
              -75.7206411412939,
              38.8018626655411
            ],
            [
              -75.7177845673772,
              38.7655224477304
            ],
            [
              -75.7130753987384,
              38.705614243345494
            ],
            [
              -75.7075551268783,
              38.635387500534705
            ],
            [
              -75.7075549587544,
              38.6353853617286
            ],
            [
              -75.707551,
              38.635335
            ],
            [
              -75.70679,
              38.626177
            ],
            [
              -75.70644,
              38.621346
            ],
            [
              -75.706065,
              38.616317
            ],
            [
              -75.705979,
              38.614788
            ],
            [
              -75.704185,
              38.592107
            ],
            [
              -75.703649,
              38.585159
            ],
            [
              -75.701768,
              38.560768
            ],
            [
              -75.7017678069245,
              38.560765489042794
            ],
            [
              -75.7017643841958,
              38.56072097628319
            ],
            [
              -75.700382,
              38.542743
            ],
            [
              -75.7003726990414,
              38.5426056268973
            ],
            [
              -75.698979,
              38.522021
            ],
            [
              -75.696889,
              38.496479
            ],
            [
              -75.69657,
              38.492383
            ],
            [
              -75.693721,
              38.460128
            ],
            [
              -75.665776,
              38.4589
            ],
            [
              -75.663034,
              38.458759
            ],
            [
              -75.64808157468919,
              38.45836438557149
            ],
            [
              -75.630637,
              38.457904
            ],
            [
              -75.598239,
              38.456855
            ],
            [
              -75.593251,
              38.456404
            ],
            [
              -75.589475,
              38.456286
            ],
            [
              -75.583767,
              38.456424
            ],
            [
              -75.574273,
              38.455991
            ],
            [
              -75.560093,
              38.455578
            ],
            [
              -75.55937,
              38.455562
            ],
            [
              -75.533913,
              38.454957
            ],
            [
              -75.522877,
              38.454656
            ],
            [
              -75.521451,
              38.454656
            ],
            [
              -75.503102,
              38.454219
            ],
            [
              -75.500282,
              38.454143
            ],
            [
              -75.479283,
              38.453698
            ],
            [
              -75.47753923468429,
              38.4536624588151
            ],
            [
              -75.428846,
              38.45267
            ],
            [
              -75.4286445672873,
              38.4526668477692
            ],
            [
              -75.424948,
              38.452609
            ],
            [
              -75.410996,
              38.452399
            ],
            [
              -75.394893,
              38.452159
            ],
            [
              -75.39367,
              38.452113
            ],
            [
              -75.371154,
              38.452106
            ],
            [
              -75.355892,
              38.452007
            ],
            [
              -75.341341,
              38.451969
            ],
            [
              -75.3412457132725,
              38.4519684371695
            ],
            [
              -75.27306316431009,
              38.451565703052694
            ],
            [
              -75.260416,
              38.451491
            ],
            [
              -75.252786,
              38.451396
            ],
            [
              -75.20513351197421,
              38.4511242298584
            ],
            [
              -75.185455,
              38.451012
            ],
            [
              -75.141923,
              38.451196
            ],
            [
              -75.0896490323041,
              38.45125407654
            ],
            [
              -75.069916,
              38.451276
            ],
            [
              -75.066332,
              38.451291
            ],
            [
              -75.0647189393122,
              38.451288865657595
            ],
            [
              -75.053484,
              38.451274
            ],
            [
              -75.052511,
              38.451273
            ],
            [
              -75.048939,
              38.451263
            ],
            [
              -75.049748,
              38.486387
            ],
            [
              -75.060032,
              38.607709
            ],
            [
              -75.060478,
              38.608012
            ],
            [
              -75.0610007060148,
              38.608406873942
            ],
            [
              -75.061259,
              38.608602
            ],
            [
              -75.06192,
              38.608869
            ],
            [
              -75.065217,
              38.632394
            ],
            [
              -75.06551,
              38.66103
            ],
            [
              -75.079221,
              38.738238
            ],
            [
              -75.080217,
              38.750112
            ],
            [
              -75.082153,
              38.772157
            ],
            [
              -75.089473,
              38.797198
            ],
            [
              -75.093805,
              38.803812
            ],
            [
              -75.098545,
              38.802926
            ],
            [
              -75.1040813498539,
              38.7863546802258
            ],
            [
              -75.104121,
              38.786236
            ],
            [
              -75.113331,
              38.782998
            ],
            [
              -75.134022,
              38.782242
            ],
            [
              -75.159022,
              38.790193
            ],
            [
              -75.160748,
              38.791224
            ],
            [
              -75.1902011884624,
              38.806676942826
            ],
            [
              -75.190552,
              38.806861
            ],
            [
              -75.205329,
              38.823386
            ],
            [
              -75.232029,
              38.844254
            ],
            [
              -75.263115,
              38.877351
            ],
            [
              -75.304078,
              38.91316
            ],
            [
              -75.312282,
              38.924594
            ],
            [
              -75.302552,
              38.939002
            ],
            [
              -75.311542,
              38.944633
            ],
            [
              -75.311882,
              38.945698
            ],
            [
              -75.31192256161259,
              38.945916812795794
            ],
            [
              -75.321266,
              38.953455
            ],
            [
              -75.350213,
              38.947438
            ],
            [
              -75.380649,
              38.961873
            ],
            [
              -75.393134,
              38.95414
            ],
            [
              -75.40104,
              38.947031
            ],
            [
              -75.401413,
              38.918355
            ],
            [
              -75.465153,
              38.91131
            ],
            [
              -75.484819,
              38.903753
            ],
            [
              -75.490453,
              38.889664
            ],
            [
              -75.508168,
              38.878951
            ],
            [
              -75.520439,
              38.855742
            ],
            [
              -75.555013,
              38.835649
            ],
            [
              -75.72280102322149,
              38.8298507048386
            ]
          ]
        ]
      ]
    }
  }
]
};