export const counties_in = {
  "type":"FeatureCollection","name":"in_counties","features":[
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "093",
      "COUNTYNS": "00451703",
      "AFFGEOID": "0500000US18093",
      "GEOID": "18093",
      "NAME": "Lawrence",
      "LSAD": "06",
      "ALAND": 1163367128,
      "AWATER": 7122898,
      "County_state": "Lawrence,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.683255,
              38.730049
            ],
            [
              -86.683102,
              38.686561
            ],
            [
              -86.553399,
              38.687441
            ],
            [
              -86.308701,
              38.688101
            ],
            [
              -86.310058,
              38.733131
            ],
            [
              -86.299722,
              38.729706
            ],
            [
              -86.275281,
              38.763795
            ],
            [
              -86.279437,
              38.961106
            ],
            [
              -86.280389,
              38.991028
            ],
            [
              -86.317474,
              38.99087
            ],
            [
              -86.573548,
              38.992147
            ],
            [
              -86.682277,
              38.992404
            ],
            [
              -86.682853,
              38.904697
            ],
            [
              -86.683255,
              38.730049
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "079",
      "COUNTYNS": "00450364",
      "AFFGEOID": "0500000US18079",
      "GEOID": "18079",
      "NAME": "Jennings",
      "LSAD": "06",
      "ALAND": 975364386,
      "AWATER": 4546211,
      "County_state": "Jennings,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.799834,
              39.128897
            ],
            [
              -85.798804,
              39.068536
            ],
            [
              -85.797259,
              38.969762
            ],
            [
              -85.796462,
              38.911024
            ],
            [
              -85.795357,
              38.807508
            ],
            [
              -85.77266,
              38.809613
            ],
            [
              -85.733191,
              38.831812
            ],
            [
              -85.683839,
              38.815406
            ],
            [
              -85.610595,
              38.824247
            ],
            [
              -85.610999,
              38.853769
            ],
            [
              -85.573776,
              38.853745
            ],
            [
              -85.573806,
              38.882658
            ],
            [
              -85.537431,
              38.88278
            ],
            [
              -85.5382,
              38.912217
            ],
            [
              -85.463477,
              38.912943
            ],
            [
              -85.444897,
              38.912998
            ],
            [
              -85.440054,
              39.195617
            ],
            [
              -85.56632,
              39.132761
            ],
            [
              -85.686783,
              39.130859
            ],
            [
              -85.774355,
              39.129146
            ],
            [
              -85.799834,
              39.128897
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "075",
      "COUNTYNS": "00450362",
      "AFFGEOID": "0500000US18075",
      "GEOID": "18075",
      "NAME": "Jay",
      "LSAD": "06",
      "ALAND": 994310106,
      "AWATER": 458556,
      "County_state": "Jay,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.219901,
              40.379034
            ],
            [
              -85.218758,
              40.306706
            ],
            [
              -84.8039175091217,
              40.310093574693596
            ],
            [
              -84.803917,
              40.310115
            ],
            [
              -84.8041185884184,
              40.3527569382653
            ],
            [
              -84.804119,
              40.352844
            ],
            [
              -84.804504,
              40.411555
            ],
            [
              -84.803928,
              40.462564
            ],
            [
              -84.8038280958923,
              40.46540312861
            ],
            [
              -84.802547,
              40.50181
            ],
            [
              -84.802483,
              40.528046
            ],
            [
              -84.80226501503509,
              40.57221195372949
            ],
            [
              -85.068479,
              40.56824
            ],
            [
              -85.201146,
              40.567242
            ],
            [
              -85.200809,
              40.480697
            ],
            [
              -85.206508,
              40.479373
            ],
            [
              -85.206864,
              40.382817
            ],
            [
              -85.206831,
              40.379182
            ],
            [
              -85.211516,
              40.379188
            ],
            [
              -85.219901,
              40.379034
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "081",
      "COUNTYNS": "00450365",
      "AFFGEOID": "0500000US18081",
      "GEOID": "18081",
      "NAME": "Johnson",
      "LSAD": "06",
      "ALAND": 829909465,
      "AWATER": 3525572,
      "County_state": "Johnson,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.25142,
              39.37478
            ],
            [
              -86.251684,
              39.341672
            ],
            [
              -86.14431,
              39.34315
            ],
            [
              -86.139569,
              39.343063
            ],
            [
              -86.085601,
              39.344189
            ],
            [
              -86.027719,
              39.345609
            ],
            [
              -85.952005,
              39.347373
            ],
            [
              -85.953164,
              39.383521
            ],
            [
              -85.951389,
              39.523125
            ],
            [
              -85.951922,
              39.537875
            ],
            [
              -85.95208,
              39.638345
            ],
            [
              -86.11123,
              39.63575
            ],
            [
              -86.139743,
              39.635355
            ],
            [
              -86.163749,
              39.634999
            ],
            [
              -86.205516,
              39.634246
            ],
            [
              -86.233906,
              39.633859
            ],
            [
              -86.249885,
              39.633645
            ],
            [
              -86.250626,
              39.567936
            ],
            [
              -86.250291,
              39.517494
            ],
            [
              -86.251621,
              39.516671
            ],
            [
              -86.25142,
              39.37478
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "113",
      "COUNTYNS": "00450377",
      "AFFGEOID": "0500000US18113",
      "GEOID": "18113",
      "NAME": "Noble",
      "LSAD": "06",
      "ALAND": 1064071452,
      "AWATER": 17074609,
      "County_state": "Noble,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.654747,
              41.523347
            ],
            [
              -85.653507,
              41.436856
            ],
            [
              -85.653227,
              41.351195
            ],
            [
              -85.651928,
              41.294776
            ],
            [
              -85.536288,
              41.295241
            ],
            [
              -85.53718,
              41.266157
            ],
            [
              -85.424053,
              41.265048
            ],
            [
              -85.307781,
              41.264158
            ],
            [
              -85.192094,
              41.264209
            ],
            [
              -85.194084,
              41.526437
            ],
            [
              -85.308461,
              41.526198
            ],
            [
              -85.423502,
              41.525596
            ],
            [
              -85.654747,
              41.523347
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "177",
      "COUNTYNS": "00450336",
      "AFFGEOID": "0500000US18177",
      "GEOID": "18177",
      "NAME": "Wayne",
      "LSAD": "06",
      "ALAND": 1040508996,
      "AWATER": 6732250,
      "County_state": "Wayne,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.220728,
              39.873576
            ],
            [
              -85.221118,
              39.788449
            ],
            [
              -85.183472,
              39.788397
            ],
            [
              -85.185089,
              39.715515
            ],
            [
              -85.120723,
              39.71503
            ],
            [
              -85.034575,
              39.714764
            ],
            [
              -85.034408,
              39.729263
            ],
            [
              -84.969515,
              39.726802
            ],
            [
              -84.81412906532259,
              39.7266202480145
            ],
            [
              -84.814189,
              39.785569
            ],
            [
              -84.814179,
              39.786853
            ],
            [
              -84.814209,
              39.799755
            ],
            [
              -84.81412,
              39.811398
            ],
            [
              -84.814179,
              39.814212
            ],
            [
              -84.813852,
              39.824621
            ],
            [
              -84.813793,
              39.826771
            ],
            [
              -84.813703,
              39.843059
            ],
            [
              -84.813674,
              39.843173
            ],
            [
              -84.813549,
              39.850773
            ],
            [
              -84.813464,
              39.853261
            ],
            [
              -84.81305,
              39.872958
            ],
            [
              -84.812787,
              39.89083
            ],
            [
              -84.812698,
              39.891585
            ],
            [
              -84.8124110112002,
              39.916915011456894
            ],
            [
              -84.812411,
              39.916916
            ],
            [
              -84.812357,
              39.921764
            ],
            [
              -84.812193,
              39.92734
            ],
            [
              -84.811212,
              39.995331
            ],
            [
              -84.8109329724325,
              40.005078914562695
            ],
            [
              -85.126777,
              40.004205
            ],
            [
              -85.145875,
              40.004694
            ],
            [
              -85.201473,
              40.004521
            ],
            [
              -85.200804,
              39.960705
            ],
            [
              -85.200945,
              39.873799
            ],
            [
              -85.220728,
              39.873576
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "049",
      "COUNTYNS": "00450353",
      "AFFGEOID": "0500000US18049",
      "GEOID": "18049",
      "NAME": "Fulton",
      "LSAD": "06",
      "ALAND": 954146361,
      "AWATER": 7426329,
      "County_state": "Fulton,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.467221,
              41.084228
            ],
            [
              -86.468509,
              40.909889
            ],
            [
              -86.355867,
              40.909453
            ],
            [
              -86.318987,
              40.909392
            ],
            [
              -86.169012,
              40.909836
            ],
            [
              -86.168459,
              40.996097
            ],
            [
              -85.946436,
              40.999195
            ],
            [
              -85.945426,
              41.00283
            ],
            [
              -85.945561,
              41.042876
            ],
            [
              -86.018664,
              41.041878
            ],
            [
              -86.018414,
              41.086118
            ],
            [
              -86.075939,
              41.084878
            ],
            [
              -86.077551,
              41.172868
            ],
            [
              -86.467046,
              41.171442
            ],
            [
              -86.467221,
              41.084228
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "063",
      "COUNTYNS": "00450358",
      "AFFGEOID": "0500000US18063",
      "GEOID": "18063",
      "NAME": "Hendricks",
      "LSAD": "06",
      "ALAND": 1053849206,
      "AWATER": 4880843,
      "County_state": "Hendricks,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.695071,
              39.92277
            ],
            [
              -86.695127,
              39.864845
            ],
            [
              -86.686843,
              39.864842
            ],
            [
              -86.687406,
              39.630437
            ],
            [
              -86.641745,
              39.63004
            ],
            [
              -86.65665,
              39.601395
            ],
            [
              -86.559847,
              39.600862
            ],
            [
              -86.465605,
              39.600769
            ],
            [
              -86.465931,
              39.630032
            ],
            [
              -86.437058,
              39.630294
            ],
            [
              -86.417894,
              39.630457
            ],
            [
              -86.335904,
              39.631985
            ],
            [
              -86.326341,
              39.632177
            ],
            [
              -86.326591,
              39.719486
            ],
            [
              -86.3265,
              39.729442
            ],
            [
              -86.326637,
              39.778645
            ],
            [
              -86.327291,
              39.822492
            ],
            [
              -86.328012,
              39.859269
            ],
            [
              -86.325373,
              39.866176
            ],
            [
              -86.326293,
              39.924029
            ],
            [
              -86.331407,
              39.924145
            ],
            [
              -86.355262,
              39.924041
            ],
            [
              -86.44985,
              39.923297
            ],
            [
              -86.695071,
              39.92277
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "149",
      "COUNTYNS": "00450389",
      "AFFGEOID": "0500000US18149",
      "GEOID": "18149",
      "NAME": "Starke",
      "LSAD": "06",
      "ALAND": 800678470,
      "AWATER": 7930487,
      "County_state": "Starke,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.930079,
              41.236798
            ],
            [
              -86.93017,
              41.172318
            ],
            [
              -86.814389,
              41.171993
            ],
            [
              -86.467046,
              41.171442
            ],
            [
              -86.46669,
              41.258617
            ],
            [
              -86.466515,
              41.302228
            ],
            [
              -86.46629,
              41.374817
            ],
            [
              -86.466603,
              41.432968
            ],
            [
              -86.524711,
              41.432886
            ],
            [
              -86.642542,
              41.432639
            ],
            [
              -86.670798,
              41.413873
            ],
            [
              -86.70063,
              41.401982
            ],
            [
              -86.707722,
              41.380987
            ],
            [
              -86.723778,
              41.360357
            ],
            [
              -86.739995,
              41.321708
            ],
            [
              -86.761495,
              41.307486
            ],
            [
              -86.783473,
              41.285465
            ],
            [
              -86.814652,
              41.273501
            ],
            [
              -86.870265,
              41.264553
            ],
            [
              -86.920543,
              41.245028
            ],
            [
              -86.930079,
              41.236798
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "145",
      "COUNTYNS": "00450387",
      "AFFGEOID": "0500000US18145",
      "GEOID": "18145",
      "NAME": "Shelby",
      "LSAD": "06",
      "ALAND": 1064884352,
      "AWATER": 4149819,
      "County_state": "Shelby,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.953164,
              39.383521
            ],
            [
              -85.952005,
              39.347373
            ],
            [
              -85.85746,
              39.349312
            ],
            [
              -85.798785,
              39.350188
            ],
            [
              -85.684515,
              39.350049
            ],
            [
              -85.63063,
              39.350205
            ],
            [
              -85.629327,
              39.45275
            ],
            [
              -85.632224,
              39.61116
            ],
            [
              -85.632771,
              39.625706
            ],
            [
              -85.632657,
              39.691236
            ],
            [
              -85.633228,
              39.698462
            ],
            [
              -85.894876,
              39.698442
            ],
            [
              -85.951721,
              39.697136
            ],
            [
              -85.95208,
              39.638345
            ],
            [
              -85.951922,
              39.537875
            ],
            [
              -85.951389,
              39.523125
            ],
            [
              -85.953164,
              39.383521
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "169",
      "COUNTYNS": "00450399",
      "AFFGEOID": "0500000US18169",
      "GEOID": "18169",
      "NAME": "Wabash",
      "LSAD": "06",
      "ALAND": 1068205084,
      "AWATER": 22117118,
      "County_state": "Wabash,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.945426,
              41.00283
            ],
            [
              -85.946436,
              40.999195
            ],
            [
              -85.939525,
              40.650991
            ],
            [
              -85.864791,
              40.651693
            ],
            [
              -85.675525,
              40.652817
            ],
            [
              -85.638587,
              40.653129
            ],
            [
              -85.641884,
              40.847903
            ],
            [
              -85.643841,
              41.002305
            ],
            [
              -85.683198,
              41.001909
            ],
            [
              -85.684181,
              41.046716
            ],
            [
              -85.795814,
              41.043588
            ],
            [
              -85.945561,
              41.042876
            ],
            [
              -85.945426,
              41.00283
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "003",
      "COUNTYNS": "00450402",
      "AFFGEOID": "0500000US18003",
      "GEOID": "18003",
      "NAME": "Allen",
      "LSAD": "06",
      "ALAND": 1702454342,
      "AWATER": 6999605,
      "County_state": "Allen,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.338552,
              41.17912
            ],
            [
              -85.337024,
              41.089723
            ],
            [
              -85.335643,
              41.00525
            ],
            [
              -85.335374,
              41.002047
            ],
            [
              -85.33603,
              40.917082
            ],
            [
              -85.167063,
              40.916585
            ],
            [
              -85.109723,
              40.917331
            ],
            [
              -85.073861,
              40.917823
            ],
            [
              -84.8029360779022,
              40.9225675776629
            ],
            [
              -84.803313,
              40.989209
            ],
            [
              -84.80331311272211,
              40.9893939621464
            ],
            [
              -84.8033254118667,
              41.009575245394196
            ],
            [
              -84.803374,
              41.089302
            ],
            [
              -84.803234,
              41.121414
            ],
            [
              -84.80378,
              41.14052
            ],
            [
              -84.803413,
              41.164649
            ],
            [
              -84.803594,
              41.173203
            ],
            [
              -84.803472,
              41.173889
            ],
            [
              -84.803492,
              41.252531
            ],
            [
              -84.80349214742239,
              41.2525618431087
            ],
            [
              -84.80358,
              41.270942
            ],
            [
              -84.803581,
              41.271079
            ],
            [
              -84.8035816909474,
              41.271273026028304
            ],
            [
              -85.077804,
              41.265601
            ],
            [
              -85.192094,
              41.264209
            ],
            [
              -85.307781,
              41.264158
            ],
            [
              -85.30979,
              41.179257
            ],
            [
              -85.338552,
              41.17912
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "011",
      "COUNTYNS": "00450405",
      "AFFGEOID": "0500000US18011",
      "GEOID": "18011",
      "NAME": "Boone",
      "LSAD": "06",
      "ALAND": 1095336143,
      "AWATER": 877936,
      "County_state": "Boone,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.695804,
              40.178657
            ],
            [
              -86.695781,
              40.010581
            ],
            [
              -86.695071,
              39.92277
            ],
            [
              -86.44985,
              39.923297
            ],
            [
              -86.355262,
              39.924041
            ],
            [
              -86.331407,
              39.924145
            ],
            [
              -86.326293,
              39.924029
            ],
            [
              -86.278471,
              39.924729
            ],
            [
              -86.239739,
              39.926055
            ],
            [
              -86.241065,
              39.998773
            ],
            [
              -86.241458,
              40.070819
            ],
            [
              -86.241722,
              40.09985
            ],
            [
              -86.242365,
              40.180778
            ],
            [
              -86.31855,
              40.179258
            ],
            [
              -86.351821,
              40.178553
            ],
            [
              -86.356454,
              40.178109
            ],
            [
              -86.695804,
              40.178657
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "103",
      "COUNTYNS": "00450373",
      "AFFGEOID": "0500000US18103",
      "GEOID": "18103",
      "NAME": "Miami",
      "LSAD": "06",
      "ALAND": 968246952,
      "AWATER": 9184624,
      "County_state": "Miami,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.169012,
              40.909836
            ],
            [
              -86.168858,
              40.743517
            ],
            [
              -86.165634,
              40.740792
            ],
            [
              -86.165866,
              40.649637
            ],
            [
              -86.165024,
              40.562649
            ],
            [
              -86.127424,
              40.563098
            ],
            [
              -86.013325,
              40.564128
            ],
            [
              -85.938785,
              40.564799
            ],
            [
              -85.863641,
              40.565525
            ],
            [
              -85.864791,
              40.651693
            ],
            [
              -85.939525,
              40.650991
            ],
            [
              -85.946436,
              40.999195
            ],
            [
              -86.168459,
              40.996097
            ],
            [
              -86.169012,
              40.909836
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "023",
      "COUNTYNS": "00450342",
      "AFFGEOID": "0500000US18023",
      "GEOID": "18023",
      "NAME": "Clinton",
      "LSAD": "06",
      "ALAND": 1049119206,
      "AWATER": 461117,
      "County_state": "Clinton,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.695637,
              40.214367
            ],
            [
              -86.695804,
              40.178657
            ],
            [
              -86.356454,
              40.178109
            ],
            [
              -86.351821,
              40.178553
            ],
            [
              -86.31855,
              40.179258
            ],
            [
              -86.242365,
              40.180778
            ],
            [
              -86.242743,
              40.215834
            ],
            [
              -86.242992,
              40.373661
            ],
            [
              -86.280755,
              40.373734
            ],
            [
              -86.280829,
              40.388339
            ],
            [
              -86.309325,
              40.388372
            ],
            [
              -86.309249,
              40.431712
            ],
            [
              -86.356799,
              40.431848
            ],
            [
              -86.375762,
              40.431851
            ],
            [
              -86.563464,
              40.431443
            ],
            [
              -86.580932,
              40.431494
            ],
            [
              -86.694665,
              40.432156
            ],
            [
              -86.69516,
              40.301198
            ],
            [
              -86.695253,
              40.258211
            ],
            [
              -86.695637,
              40.214367
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "087",
      "COUNTYNS": "00450368",
      "AFFGEOID": "0500000US18087",
      "GEOID": "18087",
      "NAME": "LaGrange",
      "LSAD": "06",
      "ALAND": 983237213,
      "AWATER": 18317909,
      "County_state": "LaGrange,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.6597500080009,
              41.759100801830094
            ],
            [
              -85.654747,
              41.523347
            ],
            [
              -85.423502,
              41.525596
            ],
            [
              -85.308461,
              41.526198
            ],
            [
              -85.194084,
              41.526437
            ],
            [
              -85.19677373742039,
              41.7597353928585
            ],
            [
              -85.232835,
              41.759839
            ],
            [
              -85.272216,
              41.759999
            ],
            [
              -85.272951,
              41.759911
            ],
            [
              -85.273713,
              41.75977
            ],
            [
              -85.292099,
              41.759962
            ],
            [
              -85.2921778813506,
              41.759962830860104
            ],
            [
              -85.298365,
              41.760028
            ],
            [
              -85.30814,
              41.760097
            ],
            [
              -85.318129,
              41.759983
            ],
            [
              -85.330623,
              41.759982
            ],
            [
              -85.350174,
              41.759908
            ],
            [
              -85.379133,
              41.759875
            ],
            [
              -85.427553,
              41.759706
            ],
            [
              -85.432471,
              41.759684
            ],
            [
              -85.515959,
              41.759352
            ],
            [
              -85.518251,
              41.759513
            ],
            [
              -85.607548,
              41.759079
            ],
            [
              -85.608312,
              41.759193
            ],
            [
              -85.622608,
              41.759049
            ],
            [
              -85.624987,
              41.759093
            ],
            [
              -85.632714,
              41.759164
            ],
            [
              -85.647683,
              41.759125
            ],
            [
              -85.650738,
              41.759103
            ],
            [
              -85.6597500080009,
              41.759100801830094
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "047",
      "COUNTYNS": "00450352",
      "AFFGEOID": "0500000US18047",
      "GEOID": "18047",
      "NAME": "Franklin",
      "LSAD": "06",
      "ALAND": 995670602,
      "AWATER": 17143494,
      "County_state": "Franklin,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.29811,
              39.525476
            ],
            [
              -85.297575,
              39.453275
            ],
            [
              -85.296126,
              39.356044
            ],
            [
              -85.296907,
              39.351844
            ],
            [
              -85.29654,
              39.268291
            ],
            [
              -85.217883,
              39.308474
            ],
            [
              -85.170534,
              39.309884
            ],
            [
              -85.076292,
              39.309993
            ],
            [
              -85.065574,
              39.307232
            ],
            [
              -84.81945096866738,
              39.305153361544896
            ],
            [
              -84.819352,
              39.309454
            ],
            [
              -84.817453,
              39.391753
            ],
            [
              -84.815754,
              39.477352
            ],
            [
              -84.815555,
              39.510952
            ],
            [
              -84.815555,
              39.511052
            ],
            [
              -84.81535501751969,
              39.521950045262
            ],
            [
              -85.022818,
              39.524382
            ],
            [
              -85.036087,
              39.526213
            ],
            [
              -85.129627,
              39.526655
            ],
            [
              -85.241769,
              39.525701
            ],
            [
              -85.29811,
              39.525476
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "007",
      "COUNTYNS": "00450403",
      "AFFGEOID": "0500000US18007",
      "GEOID": "18007",
      "NAME": "Benton",
      "LSAD": "06",
      "ALAND": 1052616452,
      "AWATER": 236033,
      "County_state": "Benton,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.526502,
              40.477158
            ],
            [
              -87.526510746795,
              40.4768790330699
            ],
            [
              -87.093676,
              40.475745
            ],
            [
              -87.095357,
              40.562895
            ],
            [
              -87.096899,
              40.732127
            ],
            [
              -87.09839,
              40.736541
            ],
            [
              -87.267298,
              40.736582
            ],
            [
              -87.5261290525058,
              40.7368850793018
            ],
            [
              -87.526292,
              40.535409
            ],
            [
              -87.526352,
              40.535111
            ],
            [
              -87.526376,
              40.491574
            ],
            [
              -87.5263789480033,
              40.4912367109821
            ],
            [
              -87.526502,
              40.477158
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "065",
      "COUNTYNS": "00450359",
      "AFFGEOID": "0500000US18065",
      "GEOID": "18065",
      "NAME": "Henry",
      "LSAD": "06",
      "ALAND": 1014954735,
      "AWATER": 7655289,
      "County_state": "Henry,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.596678,
              39.872926
            ],
            [
              -85.596916,
              39.786519
            ],
            [
              -85.409211,
              39.78731
            ],
            [
              -85.301584,
              39.787579
            ],
            [
              -85.221118,
              39.788449
            ],
            [
              -85.220728,
              39.873576
            ],
            [
              -85.200945,
              39.873799
            ],
            [
              -85.200804,
              39.960705
            ],
            [
              -85.201473,
              40.004521
            ],
            [
              -85.213496,
              40.00438
            ],
            [
              -85.214386,
              40.076889
            ],
            [
              -85.442547,
              40.076686
            ],
            [
              -85.576197,
              40.077143
            ],
            [
              -85.576408,
              40.047839
            ],
            [
              -85.576365,
              39.990103
            ],
            [
              -85.576192,
              39.945756
            ],
            [
              -85.576798,
              39.872808
            ],
            [
              -85.596678,
              39.872926
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "099",
      "COUNTYNS": "00450372",
      "AFFGEOID": "0500000US18099",
      "GEOID": "18099",
      "NAME": "Marshall",
      "LSAD": "06",
      "ALAND": 1148996723,
      "AWATER": 15831838,
      "County_state": "Marshall,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.467108,
              41.476459
            ],
            [
              -86.466603,
              41.432968
            ],
            [
              -86.46629,
              41.374817
            ],
            [
              -86.466515,
              41.302228
            ],
            [
              -86.46669,
              41.258617
            ],
            [
              -86.467046,
              41.171442
            ],
            [
              -86.077551,
              41.172868
            ],
            [
              -86.054047,
              41.173147
            ],
            [
              -86.055148,
              41.224891
            ],
            [
              -86.056553,
              41.274342
            ],
            [
              -86.05923,
              41.435812
            ],
            [
              -86.059451,
              41.479154
            ],
            [
              -86.286216,
              41.47872
            ],
            [
              -86.30649,
              41.477292
            ],
            [
              -86.329838,
              41.478099
            ],
            [
              -86.467108,
              41.476459
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "055",
      "COUNTYNS": "00451676",
      "AFFGEOID": "0500000US18055",
      "GEOID": "18055",
      "NAME": "Greene",
      "LSAD": "06",
      "ALAND": 1404983930,
      "AWATER": 8919620,
      "County_state": "Greene,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.240918,
              39.084397
            ],
            [
              -87.241027,
              38.907874
            ],
            [
              -87.129882,
              38.907315
            ],
            [
              -87.098771,
              38.903974
            ],
            [
              -87.03542,
              38.90246
            ],
            [
              -86.904252,
              38.904204
            ],
            [
              -86.682853,
              38.904697
            ],
            [
              -86.682277,
              38.992404
            ],
            [
              -86.682401,
              39.079846
            ],
            [
              -86.683002,
              39.165746
            ],
            [
              -87.054578,
              39.168086
            ],
            [
              -87.068341,
              39.167965
            ],
            [
              -87.128932,
              39.168989
            ],
            [
              -87.240737,
              39.171773
            ],
            [
              -87.240918,
              39.084397
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "131",
      "COUNTYNS": "00446852",
      "AFFGEOID": "0500000US18131",
      "GEOID": "18131",
      "NAME": "Pulaski",
      "LSAD": "06",
      "ALAND": 1123146087,
      "AWATER": 2291536,
      "County_state": "Pulaski,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.930935,
              40.977276
            ],
            [
              -86.930759,
              40.912418
            ],
            [
              -86.581532,
              40.910925
            ],
            [
              -86.468509,
              40.909889
            ],
            [
              -86.467221,
              41.084228
            ],
            [
              -86.467046,
              41.171442
            ],
            [
              -86.814389,
              41.171993
            ],
            [
              -86.93017,
              41.172318
            ],
            [
              -86.930935,
              40.977276
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "069",
      "COUNTYNS": "00450361",
      "AFFGEOID": "0500000US18069",
      "GEOID": "18069",
      "NAME": "Huntington",
      "LSAD": "06",
      "ALAND": 991057049,
      "AWATER": 13133342,
      "County_state": "Huntington,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.643841,
              41.002305
            ],
            [
              -85.641884,
              40.847903
            ],
            [
              -85.638587,
              40.653129
            ],
            [
              -85.448825,
              40.653607
            ],
            [
              -85.34396,
              40.654801
            ],
            [
              -85.334667,
              40.654413
            ],
            [
              -85.33603,
              40.917082
            ],
            [
              -85.335374,
              41.002047
            ],
            [
              -85.335643,
              41.00525
            ],
            [
              -85.393971,
              41.00519
            ],
            [
              -85.452146,
              41.005232
            ],
            [
              -85.643841,
              41.002305
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "031",
      "COUNTYNS": "00450345",
      "AFFGEOID": "0500000US18031",
      "GEOID": "18031",
      "NAME": "Decatur",
      "LSAD": "06",
      "ALAND": 964946087,
      "AWATER": 1955470,
      "County_state": "Decatur,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.686659,
              39.261416
            ],
            [
              -85.686783,
              39.130859
            ],
            [
              -85.56632,
              39.132761
            ],
            [
              -85.440054,
              39.195617
            ],
            [
              -85.435339,
              39.198394
            ],
            [
              -85.315162,
              39.258653
            ],
            [
              -85.29654,
              39.268291
            ],
            [
              -85.296907,
              39.351844
            ],
            [
              -85.296126,
              39.356044
            ],
            [
              -85.297575,
              39.453275
            ],
            [
              -85.41369,
              39.453124
            ],
            [
              -85.629327,
              39.45275
            ],
            [
              -85.63063,
              39.350205
            ],
            [
              -85.684515,
              39.350049
            ],
            [
              -85.686659,
              39.261416
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "129",
      "COUNTYNS": "00450383",
      "AFFGEOID": "0500000US18129",
      "GEOID": "18129",
      "NAME": "Posey",
      "LSAD": "06",
      "ALAND": 1060786892,
      "AWATER": 25260282,
      "County_state": "Posey,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.095662,
              37.905808
            ],
            [
              -88.097892,
              37.901517
            ],
            [
              -88.094956,
              37.893689
            ],
            [
              -88.09202942327529,
              37.891093081727895
            ],
            [
              -88.078529,
              37.879118
            ],
            [
              -88.067364,
              37.856051
            ],
            [
              -88.057576,
              37.84789
            ],
            [
              -88.033309,
              37.841917
            ],
            [
              -88.026382,
              37.831107
            ],
            [
              -88.040271,
              37.822341
            ],
            [
              -88.047947,
              37.821727
            ],
            [
              -88.060212,
              37.822849
            ],
            [
              -88.074042,
              37.82916
            ],
            [
              -88.083671,
              37.830175
            ],
            [
              -88.090884,
              37.824468
            ],
            [
              -88.083204,
              37.810897
            ],
            [
              -88.068922,
              37.800983
            ],
            [
              -88.057555,
              37.802031
            ],
            [
              -88.044249,
              37.808575
            ],
            [
              -88.035456,
              37.80952
            ],
            [
              -88.029382,
              37.803601
            ],
            [
              -88.02803,
              37.799224
            ],
            [
              -88.021021,
              37.801409
            ],
            [
              -88.015144,
              37.80193
            ],
            [
              -88.004706,
              37.800145
            ],
            [
              -87.997102,
              37.797672
            ],
            [
              -87.993099,
              37.795756
            ],
            [
              -87.991168,
              37.794049
            ],
            [
              -87.987157,
              37.792202
            ],
            [
              -87.984358,
              37.7918
            ],
            [
              -87.976389,
              37.788004
            ],
            [
              -87.971805,
              37.784648
            ],
            [
              -87.970262,
              37.781856
            ],
            [
              -87.96003,
              37.773223
            ],
            [
              -87.95259,
              37.771742
            ],
            [
              -87.948594,
              37.772344
            ],
            [
              -87.946463,
              37.773477
            ],
            [
              -87.944506,
              37.775256
            ],
            [
              -87.938598,
              37.787914
            ],
            [
              -87.935861,
              37.789703
            ],
            [
              -87.934698,
              37.791827
            ],
            [
              -87.934936,
              37.79522
            ],
            [
              -87.932554,
              37.797672
            ],
            [
              -87.927543,
              37.799851
            ],
            [
              -87.919138,
              37.802128
            ],
            [
              -87.911087,
              37.805158
            ],
            [
              -87.90681,
              37.807624
            ],
            [
              -87.904595,
              37.812526
            ],
            [
              -87.903804,
              37.817762
            ],
            [
              -87.907773,
              37.837611
            ],
            [
              -87.910276,
              37.843416
            ],
            [
              -87.914892,
              37.849618
            ],
            [
              -87.927303,
              37.858709
            ],
            [
              -87.936228,
              37.867937
            ],
            [
              -87.938128,
              37.870651
            ],
            [
              -87.940005,
              37.875044
            ],
            [
              -87.941021,
              37.879168
            ],
            [
              -87.940839,
              37.883338
            ],
            [
              -87.940069,
              37.88767
            ],
            [
              -87.938365,
              37.890802
            ],
            [
              -87.936784,
              37.892587
            ],
            [
              -87.932129,
              37.89732
            ],
            [
              -87.927769,
              37.900924
            ],
            [
              -87.9270921983773,
              37.9017059445802
            ],
            [
              -87.921744,
              37.907885
            ],
            [
              -87.904789,
              37.924892
            ],
            [
              -87.898062,
              37.927514
            ],
            [
              -87.892471,
              37.92793
            ],
            [
              -87.883321,
              37.926238
            ],
            [
              -87.877325,
              37.924034
            ],
            [
              -87.87254,
              37.920999
            ],
            [
              -87.865558,
              37.915056
            ],
            [
              -87.863097,
              37.911858
            ],
            [
              -87.858738,
              37.902779
            ],
            [
              -87.857243,
              37.900649
            ],
            [
              -87.84559,
              37.893151
            ],
            [
              -87.844691,
              37.892048
            ],
            [
              -87.841693,
              37.887685
            ],
            [
              -87.841615,
              37.883393
            ],
            [
              -87.841193,
              37.882325
            ],
            [
              -87.838102,
              37.879769
            ],
            [
              -87.833883,
              37.877324
            ],
            [
              -87.830578,
              37.876516
            ],
            [
              -87.808013,
              37.875191
            ],
            [
              -87.795185,
              37.875273
            ],
            [
              -87.7909,
              37.875714
            ],
            [
              -87.790273896178,
              37.8758313335006
            ],
            [
              -87.786407,
              37.876556
            ],
            [
              -87.783643,
              37.877759
            ],
            [
              -87.773015,
              37.884544
            ],
            [
              -87.771004,
              37.886261
            ],
            [
              -87.76226,
              37.890906
            ],
            [
              -87.740148,
              37.89465
            ],
            [
              -87.7333,
              37.894346
            ],
            [
              -87.723635,
              37.892058
            ],
            [
              -87.717971,
              37.89257
            ],
            [
              -87.710675,
              37.893898
            ],
            [
              -87.700915,
              37.897274
            ],
            [
              -87.7006847636268,
              37.8973691919489
            ],
            [
              -87.698329,
              38.00822
            ],
            [
              -87.696775,
              38.124843
            ],
            [
              -87.688918,
              38.12471
            ],
            [
              -87.688374,
              38.168419
            ],
            [
              -87.688208,
              38.201001
            ],
            [
              -87.799677,
              38.201944
            ],
            [
              -87.799169,
              38.22858
            ],
            [
              -87.98526853288558,
              38.2300649614472
            ],
            [
              -87.984929,
              38.22826
            ],
            [
              -87.984234,
              38.20996
            ],
            [
              -87.975819,
              38.197834
            ],
            [
              -87.9595,
              38.184376
            ],
            [
              -87.937162,
              38.172189
            ],
            [
              -87.933759,
              38.164351
            ],
            [
              -87.933768,
              38.156317
            ],
            [
              -87.927468,
              38.151946
            ],
            [
              -87.9255,
              38.146251
            ],
            [
              -87.92783,
              38.141545
            ],
            [
              -87.935135,
              38.139428
            ],
            [
              -87.945472,
              38.126616
            ],
            [
              -87.955872,
              38.132897
            ],
            [
              -87.960482,
              38.132095
            ],
            [
              -87.967349,
              38.132139
            ],
            [
              -87.970793,
              38.128919
            ],
            [
              -87.974445,
              38.120836
            ],
            [
              -87.974219,
              38.11138
            ],
            [
              -87.979926,
              38.10802
            ],
            [
              -87.986958,
              38.10715
            ],
            [
              -87.99831,
              38.108488
            ],
            [
              -88.013118,
              38.103527
            ],
            [
              -88.016729,
              38.097257
            ],
            [
              -88.012131,
              38.085275
            ],
            [
              -88.004697,
              38.083965
            ],
            [
              -87.96221,
              38.100054
            ],
            [
              -87.956878,
              38.088284
            ],
            [
              -87.96126,
              38.078295
            ],
            [
              -87.967226,
              38.067105
            ],
            [
              -87.98877,
              38.055591
            ],
            [
              -88.00133,
              38.051545
            ],
            [
              -88.035332,
              38.051145
            ],
            [
              -88.041845,
              38.038181
            ],
            [
              -88.030884,
              38.030713
            ],
            [
              -88.017631,
              38.034745
            ],
            [
              -88.007631,
              38.029145
            ],
            [
              -88.023331,
              38.015445
            ],
            [
              -88.021879,
              37.995959
            ],
            [
              -88.012574,
              37.977062
            ],
            [
              -88.012929,
              37.966544
            ],
            [
              -88.016311,
              37.961574
            ],
            [
              -88.020868,
              37.959351
            ],
            [
              -88.028218,
              37.958409
            ],
            [
              -88.031989,
              37.95859
            ],
            [
              -88.036688,
              37.956872
            ],
            [
              -88.036124,
              37.942746
            ],
            [
              -88.054122,
              37.933029
            ],
            [
              -88.059902,
              37.933831
            ],
            [
              -88.068948,
              37.928565
            ],
            [
              -88.06519,
              37.919684
            ],
            [
              -88.030199,
              37.914346
            ],
            [
              -88.02006,
              37.9129
            ],
            [
              -88.013355,
              37.894854
            ],
            [
              -88.018693,
              37.888403
            ],
            [
              -88.026569,
              37.891024
            ],
            [
              -88.040861,
              37.891767
            ],
            [
              -88.055373,
              37.891238
            ],
            [
              -88.071449,
              37.895813
            ],
            [
              -88.086237,
              37.905713
            ],
            [
              -88.095662,
              37.905808
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "053",
      "COUNTYNS": "00450355",
      "AFFGEOID": "0500000US18053",
      "GEOID": "18053",
      "NAME": "Grant",
      "LSAD": "06",
      "ALAND": 1072452772,
      "AWATER": 2133506,
      "County_state": "Grant,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.864791,
              40.651693
            ],
            [
              -85.863641,
              40.565525
            ],
            [
              -85.863047,
              40.478728
            ],
            [
              -85.862117,
              40.406889
            ],
            [
              -85.862296,
              40.378367
            ],
            [
              -85.786191,
              40.378923
            ],
            [
              -85.578589,
              40.379524
            ],
            [
              -85.44433,
              40.37914
            ],
            [
              -85.445829,
              40.479302
            ],
            [
              -85.447014,
              40.566929
            ],
            [
              -85.448825,
              40.653607
            ],
            [
              -85.638587,
              40.653129
            ],
            [
              -85.675525,
              40.652817
            ],
            [
              -85.864791,
              40.651693
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "121",
      "COUNTYNS": "00450380",
      "AFFGEOID": "0500000US18121",
      "GEOID": "18121",
      "NAME": "Parke",
      "LSAD": "06",
      "ALAND": 1151747431,
      "AWATER": 13683945,
      "County_state": "Parke,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.420221,
              39.952452
            ],
            [
              -87.43319,
              39.931384
            ],
            [
              -87.419069,
              39.925711
            ],
            [
              -87.404959,
              39.910857
            ],
            [
              -87.379939,
              39.903957
            ],
            [
              -87.368863,
              39.890471
            ],
            [
              -87.376891,
              39.873505
            ],
            [
              -87.353863,
              39.862077
            ],
            [
              -87.366394,
              39.842871
            ],
            [
              -87.385538,
              39.833365
            ],
            [
              -87.383561,
              39.804818
            ],
            [
              -87.374605,
              39.792498
            ],
            [
              -87.380567,
              39.766766
            ],
            [
              -87.377168,
              39.746105
            ],
            [
              -87.384418,
              39.718573
            ],
            [
              -87.381618,
              39.694266
            ],
            [
              -87.395625,
              39.65295
            ],
            [
              -87.382418,
              39.607937
            ],
            [
              -87.199012,
              39.607137
            ],
            [
              -87.124237,
              39.605762
            ],
            [
              -87.013062,
              39.604787
            ],
            [
              -87.009477,
              39.866705
            ],
            [
              -87.091542,
              39.867265
            ],
            [
              -87.091051,
              39.945655
            ],
            [
              -87.091006,
              39.953009
            ],
            [
              -87.318521,
              39.952753
            ],
            [
              -87.420221,
              39.952452
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "143",
      "COUNTYNS": "00450386",
      "AFFGEOID": "0500000US18143",
      "GEOID": "18143",
      "NAME": "Scott",
      "LSAD": "06",
      "ALAND": 493126036,
      "AWATER": 6088999,
      "County_state": "Scott,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.888334,
              38.734443
            ],
            [
              -85.885643,
              38.575412
            ],
            [
              -85.848575,
              38.576266
            ],
            [
              -85.847893,
              38.561269
            ],
            [
              -85.821162,
              38.561418
            ],
            [
              -85.821457,
              38.57539
            ],
            [
              -85.793584,
              38.604814
            ],
            [
              -85.765665,
              38.605469
            ],
            [
              -85.749963,
              38.605848
            ],
            [
              -85.719718,
              38.60554
            ],
            [
              -85.56998,
              38.606155
            ],
            [
              -85.570926,
              38.678455
            ],
            [
              -85.60819,
              38.67874
            ],
            [
              -85.608678,
              38.707858
            ],
            [
              -85.64576,
              38.708018
            ],
            [
              -85.646218,
              38.729859
            ],
            [
              -85.683338,
              38.736731
            ],
            [
              -85.683839,
              38.815406
            ],
            [
              -85.733191,
              38.831812
            ],
            [
              -85.77266,
              38.809613
            ],
            [
              -85.795357,
              38.807508
            ],
            [
              -85.804554,
              38.779906
            ],
            [
              -85.819429,
              38.776503
            ],
            [
              -85.841596,
              38.741139
            ],
            [
              -85.869559,
              38.726171
            ],
            [
              -85.881404,
              38.725026
            ],
            [
              -85.888334,
              38.734443
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "025",
      "COUNTYNS": "00451674",
      "AFFGEOID": "0500000US18025",
      "GEOID": "18025",
      "NAME": "Crawford",
      "LSAD": "06",
      "ALAND": 791534789,
      "AWATER": 8041751,
      "County_state": "Crawford,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.681424,
              38.394767
            ],
            [
              -86.680908,
              38.35096
            ],
            [
              -86.679511,
              38.263086
            ],
            [
              -86.570136,
              38.266328
            ],
            [
              -86.571685,
              38.207539
            ],
            [
              -86.460425,
              38.206825
            ],
            [
              -86.4606,
              38.201477
            ],
            [
              -86.4610338985719,
              38.1211744087398
            ],
            [
              -86.457115,
              38.124531
            ],
            [
              -86.449793,
              38.127223
            ],
            [
              -86.431749,
              38.126121
            ],
            [
              -86.41876,
              38.117693
            ],
            [
              -86.405068,
              38.105801
            ],
            [
              -86.401653,
              38.105396
            ],
            [
              -86.396215,
              38.107789
            ],
            [
              -86.387216,
              38.124632
            ],
            [
              -86.379775,
              38.129274
            ],
            [
              -86.375324,
              38.130629
            ],
            [
              -86.352466,
              38.128459
            ],
            [
              -86.335145,
              38.129242
            ],
            [
              -86.328398,
              38.132877
            ],
            [
              -86.323453,
              38.139032
            ],
            [
              -86.321274,
              38.147418
            ],
            [
              -86.325941,
              38.154317
            ],
            [
              -86.353625,
              38.159579
            ],
            [
              -86.37174,
              38.164183
            ],
            [
              -86.377434,
              38.171379
            ],
            [
              -86.378151,
              38.185845
            ],
            [
              -86.37524673212658,
              38.190857031937
            ],
            [
              -86.373801,
              38.193352
            ],
            [
              -86.360377,
              38.198796
            ],
            [
              -86.347736,
              38.195363
            ],
            [
              -86.33281,
              38.182938
            ],
            [
              -86.33001851513349,
              38.1811511718017
            ],
            [
              -86.317322,
              38.178143
            ],
            [
              -86.306511,
              38.202519
            ],
            [
              -86.289903,
              38.202692
            ],
            [
              -86.294927,
              38.223964
            ],
            [
              -86.276956,
              38.227126
            ],
            [
              -86.274762,
              38.279956
            ],
            [
              -86.253137,
              38.2919
            ],
            [
              -86.26439,
              38.294662
            ],
            [
              -86.259348,
              38.30614
            ],
            [
              -86.289669,
              38.316091
            ],
            [
              -86.273474,
              38.321105
            ],
            [
              -86.274908,
              38.335807
            ],
            [
              -86.281143,
              38.35063
            ],
            [
              -86.270757,
              38.361277
            ],
            [
              -86.247158,
              38.34946
            ],
            [
              -86.259489,
              38.388927
            ],
            [
              -86.255045,
              38.422685
            ],
            [
              -86.308674,
              38.422875
            ],
            [
              -86.308868,
              38.394069
            ],
            [
              -86.614784,
              38.395461
            ],
            [
              -86.681424,
              38.394767
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "043",
      "COUNTYNS": "00450350",
      "AFFGEOID": "0500000US18043",
      "GEOID": "18043",
      "NAME": "Floyd",
      "LSAD": "06",
      "ALAND": 383225658,
      "AWATER": 2708194,
      "County_state": "Floyd,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.032817,
              38.417898
            ],
            [
              -86.032559,
              38.33066
            ],
            [
              -86.014312,
              38.330718
            ],
            [
              -86.014115,
              38.301813
            ],
            [
              -85.99528,
              38.300893
            ],
            [
              -85.995297,
              38.293861
            ],
            [
              -85.992358,
              38.275379
            ],
            [
              -85.973689,
              38.275272
            ],
            [
              -85.973786,
              38.260668
            ],
            [
              -85.955224,
              38.260501
            ],
            [
              -85.957576,
              38.186072
            ],
            [
              -85.900604,
              38.185701
            ],
            [
              -85.89955038654419,
              38.1803432766511
            ],
            [
              -85.897664,
              38.184269
            ],
            [
              -85.894764,
              38.188469
            ],
            [
              -85.880264,
              38.203369
            ],
            [
              -85.8745408219975,
              38.2075757804121
            ],
            [
              -85.8732389448897,
              38.2085327157221
            ],
            [
              -85.87186928365789,
              38.20953947526
            ],
            [
              -85.868564,
              38.211969
            ],
            [
              -85.851436,
              38.223189
            ],
            [
              -85.8457451799961,
              38.2299366048974
            ],
            [
              -85.845464,
              38.23027
            ],
            [
              -85.839664,
              38.23977
            ],
            [
              -85.8396575160708,
              38.2398134804667
            ],
            [
              -85.837964,
              38.25117
            ],
            [
              -85.838064,
              38.257369
            ],
            [
              -85.834864,
              38.268069
            ],
            [
              -85.829364,
              38.276769
            ],
            [
              -85.823764,
              38.280569
            ],
            [
              -85.816164,
              38.282969
            ],
            [
              -85.802563,
              38.284969
            ],
            [
              -85.796063,
              38.286669
            ],
            [
              -85.794063,
              38.287869
            ],
            [
              -85.7915755577848,
              38.2885654838202
            ],
            [
              -85.793823,
              38.314151
            ],
            [
              -85.783185,
              38.320279
            ],
            [
              -85.79715,
              38.332478
            ],
            [
              -85.763913,
              38.348583
            ],
            [
              -85.760844,
              38.350218
            ],
            [
              -85.807704,
              38.404443
            ],
            [
              -85.920401,
              38.403808
            ],
            [
              -85.966882,
              38.403455
            ],
            [
              -85.99463,
              38.410949
            ],
            [
              -85.99462,
              38.418345
            ],
            [
              -86.014139,
              38.419079
            ],
            [
              -86.032817,
              38.417898
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "115",
      "COUNTYNS": "00450378",
      "AFFGEOID": "0500000US18115",
      "GEOID": "18115",
      "NAME": "Ohio",
      "LSAD": "06",
      "ALAND": 223126822,
      "AWATER": 3352208,
      "County_state": "Ohio,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.135832,
              38.929578
            ],
            [
              -85.140312,
              38.903194
            ],
            [
              -84.8706197834529,
              38.9010084241634
            ],
            [
              -84.877029,
              38.909016
            ],
            [
              -84.878817,
              38.913405
            ],
            [
              -84.879268,
              38.916116
            ],
            [
              -84.877762,
              38.920357
            ],
            [
              -84.870759,
              38.929231
            ],
            [
              -84.864731,
              38.934893
            ],
            [
              -84.83516,
              38.957961
            ],
            [
              -84.832617,
              38.96146
            ],
            [
              -84.829857,
              38.969385
            ],
            [
              -84.830619,
              38.974898
            ],
            [
              -84.833473,
              38.981522
            ],
            [
              -84.83712,
              38.988059
            ],
            [
              -84.83983,
              38.99129
            ],
            [
              -84.847094,
              38.997309
            ],
            [
              -84.849445,
              39.000923
            ],
            [
              -84.850354,
              39.00325
            ],
            [
              -84.856959,
              39.011528
            ],
            [
              -84.8646449061898,
              39.0196875474676
            ],
            [
              -84.870168,
              39.025551
            ],
            [
              -84.87805047759561,
              39.0308192384939
            ],
            [
              -84.898759,
              39.013241
            ],
            [
              -84.912109,
              39.01893
            ],
            [
              -84.933465,
              39.006226
            ],
            [
              -84.94606,
              39.00627
            ],
            [
              -84.953866,
              38.994892
            ],
            [
              -84.969707,
              38.992408
            ],
            [
              -84.998111,
              38.99654
            ],
            [
              -85.003564,
              38.975221
            ],
            [
              -85.016925,
              38.982343
            ],
            [
              -85.024668,
              38.974498
            ],
            [
              -85.040145,
              38.975921
            ],
            [
              -85.058203,
              38.958099
            ],
            [
              -85.058556,
              38.94897
            ],
            [
              -85.082642,
              38.952241
            ],
            [
              -85.097021,
              38.935351
            ],
            [
              -85.117561,
              38.936511
            ],
            [
              -85.115466,
              38.956555
            ],
            [
              -85.132508,
              38.948055
            ],
            [
              -85.135832,
              38.929578
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "123",
      "COUNTYNS": "00451682",
      "AFFGEOID": "0500000US18123",
      "GEOID": "18123",
      "NAME": "Perry",
      "LSAD": "06",
      "ALAND": 988669306,
      "AWATER": 11816977,
      "County_state": "Perry,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.81475580563179,
              37.9986742364093
            ],
            [
              -86.810913,
              37.99715
            ],
            [
              -86.794985,
              37.988982
            ],
            [
              -86.790597,
              37.980062
            ],
            [
              -86.788044,
              37.97284
            ],
            [
              -86.779993,
              37.956522
            ],
            [
              -86.765054,
              37.93251
            ],
            [
              -86.75099,
              37.912893
            ],
            [
              -86.734718,
              37.896587
            ],
            [
              -86.73146,
              37.89434
            ],
            [
              -86.722247,
              37.892648
            ],
            [
              -86.718462,
              37.893123
            ],
            [
              -86.716138,
              37.894073
            ],
            [
              -86.707816,
              37.898367
            ],
            [
              -86.691994,
              37.908529
            ],
            [
              -86.686015,
              37.913084
            ],
            [
              -86.680929,
              37.91501
            ],
            [
              -86.673038,
              37.914903
            ],
            [
              -86.660888,
              37.913059
            ],
            [
              -86.650087,
              37.910616
            ],
            [
              -86.647081,
              37.908621
            ],
            [
              -86.645513,
              37.906529
            ],
            [
              -86.644143,
              37.902366
            ],
            [
              -86.644039,
              37.898202
            ],
            [
              -86.644754,
              37.894806
            ],
            [
              -86.648727,
              37.886036
            ],
            [
              -86.658374,
              37.869376
            ],
            [
              -86.661233,
              37.862761
            ],
            [
              -86.662495,
              37.856951
            ],
            [
              -86.661637,
              37.849714
            ],
            [
              -86.658268,
              37.844144
            ],
            [
              -86.655296,
              37.842508
            ],
            [
              -86.65528645526759,
              37.842505006112695
            ],
            [
              -86.652516,
              37.841636
            ],
            [
              -86.648028,
              37.841425
            ],
            [
              -86.638265,
              37.842718
            ],
            [
              -86.634271,
              37.843845
            ],
            [
              -86.625763,
              37.847266
            ],
            [
              -86.615215,
              37.852857
            ],
            [
              -86.609163,
              37.855408
            ],
            [
              -86.604624,
              37.858272
            ],
            [
              -86.598108,
              37.867382
            ],
            [
              -86.59732,
              37.870162
            ],
            [
              -86.597476,
              37.871478
            ],
            [
              -86.59939,
              37.874753
            ],
            [
              -86.598317,
              37.88042
            ],
            [
              -86.598151,
              37.884553
            ],
            [
              -86.600096,
              37.901218
            ],
            [
              -86.599848,
              37.906754
            ],
            [
              -86.598452,
              37.910965
            ],
            [
              -86.596125,
              37.914289
            ],
            [
              -86.588581,
              37.921159
            ],
            [
              -86.586542,
              37.922285
            ],
            [
              -86.580322,
              37.923145
            ],
            [
              -86.566256,
              37.922164
            ],
            [
              -86.548507,
              37.917842
            ],
            [
              -86.540722,
              37.916871
            ],
            [
              -86.534156,
              37.917007
            ],
            [
              -86.528279,
              37.918618
            ],
            [
              -86.51924,
              37.922163
            ],
            [
              -86.511005,
              37.92612
            ],
            [
              -86.507831,
              37.928829
            ],
            [
              -86.50662,
              37.930719
            ],
            [
              -86.507043,
              37.936439
            ],
            [
              -86.50939,
              37.942492
            ],
            [
              -86.512588,
              37.94695
            ],
            [
              -86.518575,
              37.951798
            ],
            [
              -86.520503,
              37.954438
            ],
            [
              -86.523831,
              37.962169
            ],
            [
              -86.525174,
              37.968228
            ],
            [
              -86.524888,
              37.981834
            ],
            [
              -86.525844,
              37.998385
            ],
            [
              -86.525671,
              38.007145
            ],
            [
              -86.524656,
              38.012894
            ],
            [
              -86.524385,
              38.018609
            ],
            [
              -86.524969,
              38.027879
            ],
            [
              -86.521825,
              38.038327
            ],
            [
              -86.519404,
              38.041241
            ],
            [
              -86.517289,
              38.042634
            ],
            [
              -86.51176,
              38.044448
            ],
            [
              -86.500051,
              38.045757
            ],
            [
              -86.4907693787391,
              38.045672484270696
            ],
            [
              -86.480393,
              38.045578
            ],
            [
              -86.471903,
              38.046218
            ],
            [
              -86.452192,
              38.05049
            ],
            [
              -86.438236,
              38.060426
            ],
            [
              -86.432789,
              38.067171
            ],
            [
              -86.430091,
              38.078638
            ],
            [
              -86.434046,
              38.086763
            ],
            [
              -86.458795,
              38.096404
            ],
            [
              -86.463858,
              38.101177
            ],
            [
              -86.466217,
              38.106781
            ],
            [
              -86.466081,
              38.114437
            ],
            [
              -86.463248,
              38.119278
            ],
            [
              -86.4610338985719,
              38.1211744087398
            ],
            [
              -86.4606,
              38.201477
            ],
            [
              -86.460425,
              38.206825
            ],
            [
              -86.571685,
              38.207539
            ],
            [
              -86.570136,
              38.266328
            ],
            [
              -86.679511,
              38.263086
            ],
            [
              -86.678992,
              38.248062
            ],
            [
              -86.792152,
              38.2491
            ],
            [
              -86.791497,
              38.20513
            ],
            [
              -86.791316,
              38.180071
            ],
            [
              -86.803569,
              38.18143
            ],
            [
              -86.795555,
              38.156793
            ],
            [
              -86.806261,
              38.143119
            ],
            [
              -86.795016,
              38.123544
            ],
            [
              -86.807235,
              38.117953
            ],
            [
              -86.792448,
              38.116644
            ],
            [
              -86.767213,
              38.094347
            ],
            [
              -86.780316,
              38.078931
            ],
            [
              -86.767352,
              38.056924
            ],
            [
              -86.782628,
              38.048438
            ],
            [
              -86.772367,
              37.998165
            ],
            [
              -86.790518,
              38.003628
            ],
            [
              -86.798293,
              38.015528
            ],
            [
              -86.81475580563179,
              37.9986742364093
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "083",
      "COUNTYNS": "00450366",
      "AFFGEOID": "0500000US18083",
      "GEOID": "18083",
      "NAME": "Knox",
      "LSAD": "06",
      "ALAND": 1336511182,
      "AWATER": 20758112,
      "County_state": "Knox,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.74104,
              38.435576
            ],
            [
              -87.744381942629,
              38.4144965422212
            ],
            [
              -87.700122,
              38.421864
            ],
            [
              -87.661702,
              38.435677
            ],
            [
              -87.673481,
              38.446475
            ],
            [
              -87.655842,
              38.458052
            ],
            [
              -87.652071,
              38.449158
            ],
            [
              -87.639268,
              38.455523
            ],
            [
              -87.637928,
              38.446129
            ],
            [
              -87.599317,
              38.450721
            ],
            [
              -87.573558,
              38.491078
            ],
            [
              -87.54018,
              38.492824
            ],
            [
              -87.549462,
              38.511645
            ],
            [
              -87.536782,
              38.518453
            ],
            [
              -87.533273,
              38.500984
            ],
            [
              -87.52061,
              38.503582
            ],
            [
              -87.494671,
              38.495692
            ],
            [
              -87.490168,
              38.506333
            ],
            [
              -87.472404,
              38.513683
            ],
            [
              -87.476097,
              38.528013
            ],
            [
              -87.462892,
              38.533519
            ],
            [
              -87.432118,
              38.530515
            ],
            [
              -87.422865,
              38.539614
            ],
            [
              -87.411279,
              38.526545
            ],
            [
              -87.403249,
              38.541404
            ],
            [
              -87.38381,
              38.537772
            ],
            [
              -87.381952,
              38.520343
            ],
            [
              -87.367596,
              38.512944
            ],
            [
              -87.370095,
              38.538029
            ],
            [
              -87.355054,
              38.543528
            ],
            [
              -87.314637,
              38.521406
            ],
            [
              -87.301231,
              38.510263
            ],
            [
              -87.255884,
              38.526585
            ],
            [
              -87.242097,
              38.54493
            ],
            [
              -87.238074,
              38.560647
            ],
            [
              -87.247587,
              38.569466
            ],
            [
              -87.250498,
              38.615065
            ],
            [
              -87.238824,
              38.610548
            ],
            [
              -87.252958,
              38.631454
            ],
            [
              -87.240629,
              38.632128
            ],
            [
              -87.249725,
              38.652996
            ],
            [
              -87.238671,
              38.658879
            ],
            [
              -87.255698,
              38.655223
            ],
            [
              -87.272603,
              38.67982
            ],
            [
              -87.26289,
              38.688057
            ],
            [
              -87.278553,
              38.691343
            ],
            [
              -87.257043,
              38.718863
            ],
            [
              -87.262455,
              38.7289
            ],
            [
              -87.26051,
              38.734038
            ],
            [
              -87.247003,
              38.728892
            ],
            [
              -87.254616,
              38.732123
            ],
            [
              -87.243872,
              38.750047
            ],
            [
              -87.243153,
              38.768839
            ],
            [
              -87.257558,
              38.767746
            ],
            [
              -87.253185,
              38.779596
            ],
            [
              -87.239778,
              38.786318
            ],
            [
              -87.2466,
              38.807701
            ],
            [
              -87.239915,
              38.819264
            ],
            [
              -87.213267,
              38.807977
            ],
            [
              -87.186849,
              38.8338
            ],
            [
              -87.173359,
              38.829404
            ],
            [
              -87.170381,
              38.846936
            ],
            [
              -87.14932,
              38.83838
            ],
            [
              -87.141861,
              38.847958
            ],
            [
              -87.143709,
              38.867361
            ],
            [
              -87.119961,
              38.873276
            ],
            [
              -87.13058,
              38.885535
            ],
            [
              -87.11711,
              38.898983
            ],
            [
              -87.090694,
              38.900289
            ],
            [
              -87.098771,
              38.903974
            ],
            [
              -87.129882,
              38.907315
            ],
            [
              -87.241027,
              38.907874
            ],
            [
              -87.241067,
              38.901591
            ],
            [
              -87.522939,
              38.90239
            ],
            [
              -87.527645,
              38.907688
            ],
            [
              -87.544089,
              38.895093
            ],
            [
              -87.54737,
              38.875614
            ],
            [
              -87.553384,
              38.863344
            ],
            [
              -87.550515,
              38.85956
            ],
            [
              -87.5343549340073,
              38.852494647453
            ],
            [
              -87.525893,
              38.848795
            ],
            [
              -87.521681,
              38.826576
            ],
            [
              -87.527342,
              38.818121
            ],
            [
              -87.496537,
              38.778571
            ],
            [
              -87.49839413077359,
              38.7625516102452
            ],
            [
              -87.498948,
              38.757774
            ],
            [
              -87.496494,
              38.742728
            ],
            [
              -87.5035231219252,
              38.7335490719232
            ],
            [
              -87.516707,
              38.716333
            ],
            [
              -87.519609,
              38.697198
            ],
            [
              -87.531231,
              38.684036
            ],
            [
              -87.53547535600869,
              38.6821305342389
            ],
            [
              -87.545538,
              38.677613
            ],
            [
              -87.593678,
              38.667402
            ],
            [
              -87.62012,
              38.639489
            ],
            [
              -87.622375,
              38.618873
            ],
            [
              -87.627348,
              38.60544
            ],
            [
              -87.624143,
              38.596955
            ],
            [
              -87.62389,
              38.593984
            ],
            [
              -87.626444,
              38.591066
            ],
            [
              -87.629362,
              38.589971
            ],
            [
              -87.637752,
              38.588512
            ],
            [
              -87.651529,
              38.568166
            ],
            [
              -87.650704,
              38.55624
            ],
            [
              -87.660732,
              38.541092
            ],
            [
              -87.65578,
              38.521206
            ],
            [
              -87.653802,
              38.517382
            ],
            [
              -87.654166,
              38.511911
            ],
            [
              -87.657084,
              38.507169
            ],
            [
              -87.663701,
              38.502931
            ],
            [
              -87.678374,
              38.498438
            ],
            [
              -87.693188,
              38.488038
            ],
            [
              -87.714047,
              38.47988
            ],
            [
              -87.730768,
              38.478717
            ],
            [
              -87.739522,
              38.475069
            ],
            [
              -87.743535,
              38.467774
            ],
            [
              -87.74317,
              38.459019
            ],
            [
              -87.735729,
              38.452986
            ],
            [
              -87.730134,
              38.446518
            ],
            [
              -87.730699,
              38.442908
            ],
            [
              -87.74104,
              38.435576
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "039",
      "COUNTYNS": "00450348",
      "AFFGEOID": "0500000US18039",
      "GEOID": "18039",
      "NAME": "Elkhart",
      "LSAD": "06",
      "ALAND": 1199614707,
      "AWATER": 12423391,
      "County_state": "Elkhart,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.0625749882628,
              41.7605284110677
            ],
            [
              -86.061359,
              41.680497
            ],
            [
              -86.061329,
              41.678604
            ],
            [
              -86.060902,
              41.653448
            ],
            [
              -86.061084,
              41.65068
            ],
            [
              -86.060521,
              41.586531
            ],
            [
              -86.05944,
              41.521465
            ],
            [
              -86.059773,
              41.516453
            ],
            [
              -86.059451,
              41.479154
            ],
            [
              -86.05923,
              41.435812
            ],
            [
              -85.885824,
              41.435641
            ],
            [
              -85.851632,
              41.43494
            ],
            [
              -85.768708,
              41.435745
            ],
            [
              -85.653507,
              41.436856
            ],
            [
              -85.654747,
              41.523347
            ],
            [
              -85.6597500080009,
              41.759100801830094
            ],
            [
              -85.724534,
              41.759085
            ],
            [
              -85.749992,
              41.759091
            ],
            [
              -85.750469,
              41.75909
            ],
            [
              -85.77403287318049,
              41.7591446658841
            ],
            [
              -85.775039,
              41.759147
            ],
            [
              -85.79133484218919,
              41.7590511655936
            ],
            [
              -85.791363,
              41.759051
            ],
            [
              -85.872041,
              41.759365
            ],
            [
              -85.874997,
              41.759341
            ],
            [
              -85.8887690751255,
              41.75942167241
            ],
            [
              -85.888825,
              41.759422
            ],
            [
              -85.974901,
              41.759849
            ],
            [
              -85.97498,
              41.759849
            ],
            [
              -85.991302,
              41.759949
            ],
            [
              -85.9913794053612,
              41.7599498764046
            ],
            [
              -86.041027,
              41.760512
            ],
            [
              -86.0625749882628,
              41.7605284110677
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "153",
      "COUNTYNS": "00450391",
      "AFFGEOID": "0500000US18153",
      "GEOID": "18153",
      "NAME": "Sullivan",
      "LSAD": "06",
      "ALAND": 1158058307,
      "AWATER": 18096342,
      "County_state": "Sullivan,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.64599,
              39.1449
            ],
            [
              -87.643145,
              39.128562
            ],
            [
              -87.632245,
              39.118702
            ],
            [
              -87.632874,
              39.114297
            ],
            [
              -87.632874,
              39.11055
            ],
            [
              -87.632249,
              39.106803
            ],
            [
              -87.630376,
              39.104305
            ],
            [
              -87.625379,
              39.101806
            ],
            [
              -87.619134,
              39.100557
            ],
            [
              -87.61726,
              39.096186
            ],
            [
              -87.616636,
              39.08994
            ],
            [
              -87.613513,
              39.085568
            ],
            [
              -87.608517,
              39.082445
            ],
            [
              -87.596373,
              39.079639
            ],
            [
              -87.572588,
              39.057286
            ],
            [
              -87.575027,
              39.034062
            ],
            [
              -87.569696,
              39.019413
            ],
            [
              -87.579117,
              39.001607
            ],
            [
              -87.578319,
              38.988786
            ],
            [
              -87.529496,
              38.971925
            ],
            [
              -87.512187,
              38.954417
            ],
            [
              -87.518826,
              38.923205
            ],
            [
              -87.527645,
              38.907688
            ],
            [
              -87.522939,
              38.90239
            ],
            [
              -87.241067,
              38.901591
            ],
            [
              -87.241027,
              38.907874
            ],
            [
              -87.240918,
              39.084397
            ],
            [
              -87.240737,
              39.171773
            ],
            [
              -87.240379,
              39.259064
            ],
            [
              -87.351332,
              39.259195
            ],
            [
              -87.604075798943,
              39.25945851501619
            ],
            [
              -87.593486,
              39.247452
            ],
            [
              -87.583535,
              39.243579
            ],
            [
              -87.579163,
              39.232962
            ],
            [
              -87.574558,
              39.218404
            ],
            [
              -87.577029,
              39.211123
            ],
            [
              -87.588614,
              39.197824
            ],
            [
              -87.620796,
              39.17479
            ],
            [
              -87.640435,
              39.166727
            ],
            [
              -87.6427769752148,
              39.157524787036294
            ],
            [
              -87.64599,
              39.1449
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "045",
      "COUNTYNS": "00450351",
      "AFFGEOID": "0500000US18045",
      "GEOID": "18045",
      "NAME": "Fountain",
      "LSAD": "06",
      "ALAND": 1024769248,
      "AWATER": 5734092,
      "County_state": "Fountain,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.427385,
              39.982139
            ],
            [
              -87.43984,
              39.959486
            ],
            [
              -87.420221,
              39.952452
            ],
            [
              -87.318521,
              39.952753
            ],
            [
              -87.091006,
              39.953009
            ],
            [
              -87.091352,
              40.034621
            ],
            [
              -87.092142,
              40.039019
            ],
            [
              -87.092563,
              40.214806
            ],
            [
              -87.092148,
              40.366612
            ],
            [
              -87.131525,
              40.340592
            ],
            [
              -87.170128,
              40.334831
            ],
            [
              -87.245787,
              40.308846
            ],
            [
              -87.249683,
              40.299146
            ],
            [
              -87.28195,
              40.277604
            ],
            [
              -87.295296,
              40.256324
            ],
            [
              -87.316316,
              40.246684
            ],
            [
              -87.339273,
              40.223173
            ],
            [
              -87.357583,
              40.210877
            ],
            [
              -87.4199,
              40.192258
            ],
            [
              -87.427998,
              40.178287
            ],
            [
              -87.438774,
              40.164458
            ],
            [
              -87.408526,
              40.142956
            ],
            [
              -87.406667,
              40.127508
            ],
            [
              -87.417438,
              40.058684
            ],
            [
              -87.435013,
              40.026293
            ],
            [
              -87.427385,
              39.982139
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "089",
      "COUNTYNS": "00450495",
      "AFFGEOID": "0500000US18089",
      "GEOID": "18089",
      "NAME": "Lake",
      "LSAD": "06",
      "ALAND": 1292050352,
      "AWATER": 330971523,
      "County_state": "Lake,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.526768,
              41.298177
            ],
            [
              -87.52676781234179,
              41.2980516032755
            ],
            [
              -87.5265703397201,
              41.166096664112004
            ],
            [
              -87.442293,
              41.17341
            ],
            [
              -87.414421,
              41.162248
            ],
            [
              -87.393796,
              41.162806
            ],
            [
              -87.380709,
              41.172652
            ],
            [
              -87.324599,
              41.186203
            ],
            [
              -87.275882,
              41.218595
            ],
            [
              -87.226372,
              41.235507
            ],
            [
              -87.218862,
              41.242027
            ],
            [
              -87.220266,
              41.333327
            ],
            [
              -87.219652,
              41.38483
            ],
            [
              -87.219625,
              41.434595
            ],
            [
              -87.219953,
              41.470702
            ],
            [
              -87.220633,
              41.521614
            ],
            [
              -87.221355,
              41.54718
            ],
            [
              -87.2226440045199,
              41.624160880659296
            ],
            [
              -87.261536,
              41.620336
            ],
            [
              -87.278437,
              41.619736
            ],
            [
              -87.287637,
              41.622236
            ],
            [
              -87.324338,
              41.623036
            ],
            [
              -87.365439,
              41.629536
            ],
            [
              -87.39163170036589,
              41.636465814437
            ],
            [
              -87.394539,
              41.637235
            ],
            [
              -87.40820017504319,
              41.6398820565116
            ],
            [
              -87.42344,
              41.642835
            ],
            [
              -87.42984,
              41.646035
            ],
            [
              -87.438941,
              41.654335
            ],
            [
              -87.421152,
              41.659516
            ],
            [
              -87.400567,
              41.677604
            ],
            [
              -87.410873,
              41.683992
            ],
            [
              -87.415816,
              41.688183
            ],
            [
              -87.424877,
              41.690073
            ],
            [
              -87.449722,
              41.684214
            ],
            [
              -87.4623900596434,
              41.6777835751478
            ],
            [
              -87.464178,
              41.676876
            ],
            [
              -87.468013,
              41.672432
            ],
            [
              -87.470742,
              41.672835
            ],
            [
              -87.505343,
              41.691535
            ],
            [
              -87.511043,
              41.696535
            ],
            [
              -87.515243,
              41.704235
            ],
            [
              -87.520544,
              41.709935
            ],
            [
              -87.524044,
              41.708335
            ],
            [
              -87.524944,
              41.702635
            ],
            [
              -87.524844,
              41.691635
            ],
            [
              -87.5247862899967,
              41.6754362020356
            ],
            [
              -87.524642,
              41.634935
            ],
            [
              -87.524742,
              41.632435
            ],
            [
              -87.524642,
              41.622535
            ],
            [
              -87.5246415449503,
              41.5955960550854
            ],
            [
              -87.5246413332451,
              41.58306310896079
            ],
            [
              -87.524641,
              41.563335
            ],
            [
              -87.525041,
              41.559235
            ],
            [
              -87.52494,
              41.529735
            ],
            [
              -87.525025896482,
              41.5227293252257
            ],
            [
              -87.5254269452529,
              41.4900199849783
            ],
            [
              -87.5256689455744,
              41.470282557937196
            ],
            [
              -87.525671,
              41.470115
            ],
            [
              -87.525623,
              41.453619
            ],
            [
              -87.5255553386101,
              41.4355838937009
            ],
            [
              -87.52535,
              41.380851
            ],
            [
              -87.526404,
              41.355812
            ],
            [
              -87.526768,
              41.298177
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "157",
      "COUNTYNS": "00450393",
      "AFFGEOID": "0500000US18157",
      "GEOID": "18157",
      "NAME": "Tippecanoe",
      "LSAD": "06",
      "ALAND": 1292138000,
      "AWATER": 11256312,
      "County_state": "Tippecanoe,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.095357,
              40.562895
            ],
            [
              -87.093676,
              40.475745
            ],
            [
              -87.092812,
              40.471765
            ],
            [
              -87.092148,
              40.366612
            ],
            [
              -87.092563,
              40.214806
            ],
            [
              -86.923342,
              40.215047
            ],
            [
              -86.86696,
              40.214918
            ],
            [
              -86.695637,
              40.214367
            ],
            [
              -86.695253,
              40.258211
            ],
            [
              -86.69516,
              40.301198
            ],
            [
              -86.694665,
              40.432156
            ],
            [
              -86.695353,
              40.561874
            ],
            [
              -86.771293,
              40.562082
            ],
            [
              -86.868993,
              40.561666
            ],
            [
              -86.943797,
              40.56171
            ],
            [
              -86.981549,
              40.561989
            ],
            [
              -87.001196,
              40.562206
            ],
            [
              -87.095357,
              40.562895
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "085",
      "COUNTYNS": "00450367",
      "AFFGEOID": "0500000US18085",
      "GEOID": "18085",
      "NAME": "Kosciusko",
      "LSAD": "06",
      "ALAND": 1376361579,
      "AWATER": 59509695,
      "County_state": "Kosciusko,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.077551,
              41.172868
            ],
            [
              -86.075939,
              41.084878
            ],
            [
              -86.018414,
              41.086118
            ],
            [
              -86.018664,
              41.041878
            ],
            [
              -85.945561,
              41.042876
            ],
            [
              -85.795814,
              41.043588
            ],
            [
              -85.684181,
              41.046716
            ],
            [
              -85.686574,
              41.178376
            ],
            [
              -85.652715,
              41.178489
            ],
            [
              -85.651196,
              41.265773
            ],
            [
              -85.651928,
              41.294776
            ],
            [
              -85.653227,
              41.351195
            ],
            [
              -85.653507,
              41.436856
            ],
            [
              -85.768708,
              41.435745
            ],
            [
              -85.851632,
              41.43494
            ],
            [
              -85.885824,
              41.435641
            ],
            [
              -86.05923,
              41.435812
            ],
            [
              -86.056553,
              41.274342
            ],
            [
              -86.055148,
              41.224891
            ],
            [
              -86.054047,
              41.173147
            ],
            [
              -86.077551,
              41.172868
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "117",
      "COUNTYNS": "00451681",
      "AFFGEOID": "0500000US18117",
      "GEOID": "18117",
      "NAME": "Orange",
      "LSAD": "06",
      "ALAND": 1031786463,
      "AWATER": 25425748,
      "County_state": "Orange,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.683102,
              38.686561
            ],
            [
              -86.682539,
              38.598908
            ],
            [
              -86.682359,
              38.526378
            ],
            [
              -86.681424,
              38.394767
            ],
            [
              -86.614784,
              38.395461
            ],
            [
              -86.308868,
              38.394069
            ],
            [
              -86.308674,
              38.422875
            ],
            [
              -86.308614,
              38.484824
            ],
            [
              -86.308701,
              38.688101
            ],
            [
              -86.553399,
              38.687441
            ],
            [
              -86.683102,
              38.686561
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "125",
      "COUNTYNS": "00450381",
      "AFFGEOID": "0500000US18125",
      "GEOID": "18125",
      "NAME": "Pike",
      "LSAD": "06",
      "ALAND": 865676037,
      "AWATER": 17753971,
      "County_state": "Pike,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.462892,
              38.533519
            ],
            [
              -87.462486,
              38.46561
            ],
            [
              -87.444503,
              38.465489
            ],
            [
              -87.444549,
              38.436125
            ],
            [
              -87.407327,
              38.43632
            ],
            [
              -87.407583,
              38.375501
            ],
            [
              -87.37151,
              38.376814
            ],
            [
              -87.356864,
              38.387906
            ],
            [
              -87.339687,
              38.3788
            ],
            [
              -87.316243,
              38.381482
            ],
            [
              -87.31698,
              38.289775
            ],
            [
              -87.316893,
              38.246077
            ],
            [
              -87.298218,
              38.245886
            ],
            [
              -87.298388,
              38.231671
            ],
            [
              -87.073067,
              38.232596
            ],
            [
              -87.072602,
              38.334686
            ],
            [
              -87.073238,
              38.422354
            ],
            [
              -87.072369,
              38.5147
            ],
            [
              -87.086704,
              38.512846
            ],
            [
              -87.10636,
              38.537234
            ],
            [
              -87.149961,
              38.515615
            ],
            [
              -87.175497,
              38.543954
            ],
            [
              -87.20347,
              38.552238
            ],
            [
              -87.220945,
              38.538829
            ],
            [
              -87.242097,
              38.54493
            ],
            [
              -87.255884,
              38.526585
            ],
            [
              -87.301231,
              38.510263
            ],
            [
              -87.314637,
              38.521406
            ],
            [
              -87.355054,
              38.543528
            ],
            [
              -87.370095,
              38.538029
            ],
            [
              -87.367596,
              38.512944
            ],
            [
              -87.381952,
              38.520343
            ],
            [
              -87.38381,
              38.537772
            ],
            [
              -87.403249,
              38.541404
            ],
            [
              -87.411279,
              38.526545
            ],
            [
              -87.422865,
              38.539614
            ],
            [
              -87.432118,
              38.530515
            ],
            [
              -87.462892,
              38.533519
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "137",
      "COUNTYNS": "00450385",
      "AFFGEOID": "0500000US18137",
      "GEOID": "18137",
      "NAME": "Ripley",
      "LSAD": "06",
      "ALAND": 1156236802,
      "AWATER": 4237459,
      "County_state": "Ripley,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.440054,
              39.195617
            ],
            [
              -85.444897,
              38.912998
            ],
            [
              -85.203166,
              38.913803
            ],
            [
              -85.202849,
              38.928523
            ],
            [
              -85.135832,
              38.929578
            ],
            [
              -85.132508,
              38.948055
            ],
            [
              -85.113111,
              39.050589
            ],
            [
              -85.08486,
              39.206263
            ],
            [
              -85.081872,
              39.222266
            ],
            [
              -85.065574,
              39.307232
            ],
            [
              -85.076292,
              39.309993
            ],
            [
              -85.170534,
              39.309884
            ],
            [
              -85.217883,
              39.308474
            ],
            [
              -85.29654,
              39.268291
            ],
            [
              -85.315162,
              39.258653
            ],
            [
              -85.435339,
              39.198394
            ],
            [
              -85.440054,
              39.195617
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "119",
      "COUNTYNS": "00450379",
      "AFFGEOID": "0500000US18119",
      "GEOID": "18119",
      "NAME": "Owen",
      "LSAD": "06",
      "ALAND": 997910783,
      "AWATER": 6566402,
      "County_state": "Owen,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.053646,
              39.342621
            ],
            [
              -87.054578,
              39.168086
            ],
            [
              -86.683002,
              39.165746
            ],
            [
              -86.68561,
              39.336043
            ],
            [
              -86.657628,
              39.33011
            ],
            [
              -86.658911,
              39.347252
            ],
            [
              -86.651551,
              39.355593
            ],
            [
              -86.630818,
              39.346948
            ],
            [
              -86.630955,
              39.406256
            ],
            [
              -86.630779,
              39.426545
            ],
            [
              -86.63191,
              39.441086
            ],
            [
              -86.632206,
              39.470214
            ],
            [
              -86.68571,
              39.470063
            ],
            [
              -86.691656,
              39.470543
            ],
            [
              -86.939981,
              39.473345
            ],
            [
              -86.941666,
              39.432506
            ],
            [
              -86.942409,
              39.429356
            ],
            [
              -86.942293,
              39.399912
            ],
            [
              -86.942473,
              39.342043
            ],
            [
              -87.053646,
              39.342621
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "037",
      "COUNTYNS": "00451675",
      "AFFGEOID": "0500000US18037",
      "GEOID": "18037",
      "NAME": "Dubois",
      "LSAD": "06",
      "ALAND": 1106626119,
      "AWATER": 20883162,
      "County_state": "Dubois,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.073238,
              38.422354
            ],
            [
              -87.072602,
              38.334686
            ],
            [
              -87.073067,
              38.232596
            ],
            [
              -87.073141,
              38.203821
            ],
            [
              -87.01749,
              38.20358
            ],
            [
              -86.92368,
              38.203028
            ],
            [
              -86.791497,
              38.20513
            ],
            [
              -86.792152,
              38.2491
            ],
            [
              -86.678992,
              38.248062
            ],
            [
              -86.679511,
              38.263086
            ],
            [
              -86.680908,
              38.35096
            ],
            [
              -86.681424,
              38.394767
            ],
            [
              -86.682359,
              38.526378
            ],
            [
              -86.837462,
              38.526468
            ],
            [
              -86.84418,
              38.51517
            ],
            [
              -86.877623,
              38.495647
            ],
            [
              -86.918156,
              38.496986
            ],
            [
              -86.924186,
              38.505358
            ],
            [
              -86.936589,
              38.504262
            ],
            [
              -86.942513,
              38.492593
            ],
            [
              -86.956217,
              38.503439
            ],
            [
              -86.974763,
              38.501953
            ],
            [
              -86.993951,
              38.513037
            ],
            [
              -87.025385,
              38.507394
            ],
            [
              -87.045073,
              38.510865
            ],
            [
              -87.063815,
              38.501657
            ],
            [
              -87.072369,
              38.5147
            ],
            [
              -87.073238,
              38.422354
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "095",
      "COUNTYNS": "00450370",
      "AFFGEOID": "0500000US18095",
      "GEOID": "18095",
      "NAME": "Madison",
      "LSAD": "06",
      "ALAND": 1170457563,
      "AWATER": 2559342,
      "County_state": "Madison,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.863278,
              40.103861
            ],
            [
              -85.86224,
              40.002288
            ],
            [
              -85.862489,
              39.943618
            ],
            [
              -85.805711,
              39.944567
            ],
            [
              -85.576192,
              39.945756
            ],
            [
              -85.576365,
              39.990103
            ],
            [
              -85.576408,
              40.047839
            ],
            [
              -85.576197,
              40.077143
            ],
            [
              -85.576051,
              40.110032
            ],
            [
              -85.575903,
              40.142243
            ],
            [
              -85.575982,
              40.149601
            ],
            [
              -85.577018,
              40.306381
            ],
            [
              -85.578589,
              40.379524
            ],
            [
              -85.786191,
              40.378923
            ],
            [
              -85.862296,
              40.378367
            ],
            [
              -85.860391,
              40.291455
            ],
            [
              -85.861903,
              40.218936
            ],
            [
              -85.863278,
              40.103861
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "101",
      "COUNTYNS": "00451679",
      "AFFGEOID": "0500000US18101",
      "GEOID": "18101",
      "NAME": "Martin",
      "LSAD": "06",
      "ALAND": 869615710,
      "AWATER": 12033246,
      "County_state": "Martin,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.923689,
              38.686427
            ],
            [
              -86.924186,
              38.505358
            ],
            [
              -86.918156,
              38.496986
            ],
            [
              -86.877623,
              38.495647
            ],
            [
              -86.84418,
              38.51517
            ],
            [
              -86.837462,
              38.526468
            ],
            [
              -86.682359,
              38.526378
            ],
            [
              -86.682539,
              38.598908
            ],
            [
              -86.683102,
              38.686561
            ],
            [
              -86.683255,
              38.730049
            ],
            [
              -86.682853,
              38.904697
            ],
            [
              -86.904252,
              38.904204
            ],
            [
              -86.904193,
              38.849631
            ],
            [
              -86.904227,
              38.817511
            ],
            [
              -86.922943,
              38.817238
            ],
            [
              -86.923689,
              38.686427
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "181",
      "COUNTYNS": "00450338",
      "AFFGEOID": "0500000US18181",
      "GEOID": "18181",
      "NAME": "White",
      "LSAD": "06",
      "ALAND": 1308269840,
      "AWATER": 9203227,
      "County_state": "White,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.099793,
              40.837607
            ],
            [
              -87.09839,
              40.736541
            ],
            [
              -87.096899,
              40.732127
            ],
            [
              -87.095357,
              40.562895
            ],
            [
              -87.001196,
              40.562206
            ],
            [
              -86.981549,
              40.561989
            ],
            [
              -86.943797,
              40.56171
            ],
            [
              -86.868993,
              40.561666
            ],
            [
              -86.771293,
              40.562082
            ],
            [
              -86.77423,
              40.664026
            ],
            [
              -86.758301,
              40.664282
            ],
            [
              -86.742589,
              40.681236
            ],
            [
              -86.762228,
              40.67989
            ],
            [
              -86.748705,
              40.688404
            ],
            [
              -86.761373,
              40.697925
            ],
            [
              -86.750011,
              40.736027
            ],
            [
              -86.740484,
              40.737589
            ],
            [
              -86.65878,
              40.7366
            ],
            [
              -86.581739,
              40.735765
            ],
            [
              -86.581532,
              40.910925
            ],
            [
              -86.930759,
              40.912418
            ],
            [
              -86.987438,
              40.912177
            ],
            [
              -86.987348,
              40.896213
            ],
            [
              -86.986438,
              40.837921
            ],
            [
              -87.099793,
              40.837607
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "071",
      "COUNTYNS": "00451678",
      "AFFGEOID": "0500000US18071",
      "GEOID": "18071",
      "NAME": "Jackson",
      "LSAD": "06",
      "ALAND": 1319087093,
      "AWATER": 11923424,
      "County_state": "Jackson,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.318133,
              39.04881
            ],
            [
              -86.317474,
              38.99087
            ],
            [
              -86.280389,
              38.991028
            ],
            [
              -86.279437,
              38.961106
            ],
            [
              -86.275281,
              38.763795
            ],
            [
              -86.254103,
              38.770118
            ],
            [
              -86.237144,
              38.767797
            ],
            [
              -86.224068,
              38.779147
            ],
            [
              -86.197651,
              38.773769
            ],
            [
              -86.184102,
              38.783588
            ],
            [
              -86.134944,
              38.762425
            ],
            [
              -86.102356,
              38.765755
            ],
            [
              -86.092538,
              38.783462
            ],
            [
              -86.075066,
              38.774727
            ],
            [
              -86.05508,
              38.773133
            ],
            [
              -86.025736,
              38.756009
            ],
            [
              -85.984252,
              38.768115
            ],
            [
              -85.976641,
              38.752393
            ],
            [
              -85.960862,
              38.764185
            ],
            [
              -85.948377,
              38.753636
            ],
            [
              -85.93651,
              38.755834
            ],
            [
              -85.915611,
              38.745958
            ],
            [
              -85.908449,
              38.756151
            ],
            [
              -85.89078,
              38.729722
            ],
            [
              -85.888334,
              38.734443
            ],
            [
              -85.881404,
              38.725026
            ],
            [
              -85.869559,
              38.726171
            ],
            [
              -85.841596,
              38.741139
            ],
            [
              -85.819429,
              38.776503
            ],
            [
              -85.804554,
              38.779906
            ],
            [
              -85.795357,
              38.807508
            ],
            [
              -85.796462,
              38.911024
            ],
            [
              -85.797259,
              38.969762
            ],
            [
              -85.798804,
              39.068536
            ],
            [
              -85.822851,
              39.069711
            ],
            [
              -85.832961,
              39.063663
            ],
            [
              -85.847245,
              39.056512
            ],
            [
              -85.862447,
              39.048919
            ],
            [
              -85.860498,
              39.040921
            ],
            [
              -86.061733,
              39.036
            ],
            [
              -86.079844,
              39.035903
            ],
            [
              -86.080272,
              39.05027
            ],
            [
              -86.318133,
              39.04881
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "013",
      "COUNTYNS": "00451684",
      "AFFGEOID": "0500000US18013",
      "GEOID": "18013",
      "NAME": "Brown",
      "LSAD": "06",
      "ALAND": 808043862,
      "AWATER": 12025993,
      "County_state": "Brown,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.381395,
              39.339752
            ],
            [
              -86.379937,
              39.25108
            ],
            [
              -86.372408,
              39.250905
            ],
            [
              -86.369168,
              39.076826
            ],
            [
              -86.370066,
              39.048375
            ],
            [
              -86.318133,
              39.04881
            ],
            [
              -86.080272,
              39.05027
            ],
            [
              -86.07903,
              39.079641
            ],
            [
              -86.081824,
              39.226288
            ],
            [
              -86.085601,
              39.344189
            ],
            [
              -86.139569,
              39.343063
            ],
            [
              -86.14431,
              39.34315
            ],
            [
              -86.251684,
              39.341672
            ],
            [
              -86.381395,
              39.339752
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "159",
      "COUNTYNS": "00450394",
      "AFFGEOID": "0500000US18159",
      "GEOID": "18159",
      "NAME": "Tipton",
      "LSAD": "06",
      "ALAND": 674797600,
      "AWATER": 68028,
      "County_state": "Tipton,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.242812,
              40.402914
            ],
            [
              -86.242992,
              40.373661
            ],
            [
              -86.242743,
              40.215834
            ],
            [
              -85.935561,
              40.218437
            ],
            [
              -85.861903,
              40.218936
            ],
            [
              -85.860391,
              40.291455
            ],
            [
              -85.862296,
              40.378367
            ],
            [
              -85.862117,
              40.406889
            ],
            [
              -85.975506,
              40.406227
            ],
            [
              -86.060645,
              40.405198
            ],
            [
              -86.127113,
              40.404337
            ],
            [
              -86.242812,
              40.402914
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "051",
      "COUNTYNS": "00450354",
      "AFFGEOID": "0500000US18051",
      "GEOID": "18051",
      "NAME": "Gibson",
      "LSAD": "06",
      "ALAND": 1262606254,
      "AWATER": 30223915,
      "County_state": "Gibson,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.988037,
              38.255486
            ],
            [
              -87.988794,
              38.248739
            ],
            [
              -87.987277,
              38.240742
            ],
            [
              -87.98526853288558,
              38.2300649614472
            ],
            [
              -87.799169,
              38.22858
            ],
            [
              -87.799677,
              38.201944
            ],
            [
              -87.688208,
              38.201001
            ],
            [
              -87.688374,
              38.168419
            ],
            [
              -87.467284,
              38.165403
            ],
            [
              -87.466717,
              38.201234
            ],
            [
              -87.355139,
              38.201625
            ],
            [
              -87.31703,
              38.201753
            ],
            [
              -87.316893,
              38.246077
            ],
            [
              -87.31698,
              38.289775
            ],
            [
              -87.316243,
              38.381482
            ],
            [
              -87.339687,
              38.3788
            ],
            [
              -87.356864,
              38.387906
            ],
            [
              -87.37151,
              38.376814
            ],
            [
              -87.407583,
              38.375501
            ],
            [
              -87.407327,
              38.43632
            ],
            [
              -87.444549,
              38.436125
            ],
            [
              -87.444503,
              38.465489
            ],
            [
              -87.462486,
              38.46561
            ],
            [
              -87.462892,
              38.533519
            ],
            [
              -87.476097,
              38.528013
            ],
            [
              -87.472404,
              38.513683
            ],
            [
              -87.490168,
              38.506333
            ],
            [
              -87.494671,
              38.495692
            ],
            [
              -87.52061,
              38.503582
            ],
            [
              -87.533273,
              38.500984
            ],
            [
              -87.536782,
              38.518453
            ],
            [
              -87.549462,
              38.511645
            ],
            [
              -87.54018,
              38.492824
            ],
            [
              -87.573558,
              38.491078
            ],
            [
              -87.599317,
              38.450721
            ],
            [
              -87.637928,
              38.446129
            ],
            [
              -87.639268,
              38.455523
            ],
            [
              -87.652071,
              38.449158
            ],
            [
              -87.655842,
              38.458052
            ],
            [
              -87.673481,
              38.446475
            ],
            [
              -87.661702,
              38.435677
            ],
            [
              -87.700122,
              38.421864
            ],
            [
              -87.744381942629,
              38.4144965422212
            ],
            [
              -87.745254,
              38.408996
            ],
            [
              -87.75979852361239,
              38.393023063672004
            ],
            [
              -87.779996,
              38.370842
            ],
            [
              -87.806075,
              38.363143
            ],
            [
              -87.822721,
              38.346912
            ],
            [
              -87.832723,
              38.324853
            ],
            [
              -87.831972,
              38.307241
            ],
            [
              -87.830953,
              38.299365
            ],
            [
              -87.832802,
              38.29334
            ],
            [
              -87.840984,
              38.280105
            ],
            [
              -87.845937,
              38.276667
            ],
            [
              -87.854643,
              38.275376
            ],
            [
              -87.863068,
              38.280267
            ],
            [
              -87.866248,
              38.28998
            ],
            [
              -87.860453,
              38.305372
            ],
            [
              -87.86311,
              38.309823
            ],
            [
              -87.868886,
              38.312277
            ],
            [
              -87.87503,
              38.310221
            ],
            [
              -87.880848,
              38.303725
            ],
            [
              -87.884858,
              38.293063
            ],
            [
              -87.887849,
              38.285299
            ],
            [
              -87.897803,
              38.279482
            ],
            [
              -87.908542,
              38.268581
            ],
            [
              -87.91285,
              38.268133
            ],
            [
              -87.917615,
              38.270078
            ],
            [
              -87.919292,
              38.275908
            ],
            [
              -87.915444,
              38.28235
            ],
            [
              -87.909853,
              38.289335
            ],
            [
              -87.909338,
              38.29572
            ],
            [
              -87.916489,
              38.299372
            ],
            [
              -87.924973,
              38.298659
            ],
            [
              -87.937329,
              38.292431
            ],
            [
              -87.94227,
              38.278976
            ],
            [
              -87.951923,
              38.274253
            ],
            [
              -87.948903,
              38.264116
            ],
            [
              -87.942067,
              38.260301
            ],
            [
              -87.946065,
              38.255319
            ],
            [
              -87.956145,
              38.251652
            ],
            [
              -87.958839,
              38.244964
            ],
            [
              -87.957992,
              38.240096
            ],
            [
              -87.960225,
              38.237118
            ],
            [
              -87.965381,
              38.236411
            ],
            [
              -87.968968,
              38.237389
            ],
            [
              -87.970593,
              38.240861
            ],
            [
              -87.969947,
              38.243354
            ],
            [
              -87.981868,
              38.256444
            ],
            [
              -87.987103,
              38.257731
            ],
            [
              -87.98749671787239,
              38.256784643872095
            ],
            [
              -87.988037,
              38.255486
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "173",
      "COUNTYNS": "00450335",
      "AFFGEOID": "0500000US18173",
      "GEOID": "18173",
      "NAME": "Warrick",
      "LSAD": "06",
      "ALAND": 996664713,
      "AWATER": 16155577,
      "County_state": "Warrick,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.472499,
              38.078162
            ],
            [
              -87.472983,
              38.034944
            ],
            [
              -87.449867,
              38.03517
            ],
            [
              -87.449906,
              37.991204
            ],
            [
              -87.450707,
              37.947763
            ],
            [
              -87.4511762695734,
              37.9410807495909
            ],
            [
              -87.450458,
              37.941451
            ],
            [
              -87.447786,
              37.942427
            ],
            [
              -87.436859,
              37.944192
            ],
            [
              -87.428521,
              37.944811
            ],
            [
              -87.418585,
              37.944763
            ],
            [
              -87.402632,
              37.942267
            ],
            [
              -87.40116,
              37.941227
            ],
            [
              -87.3912341733316,
              37.938554388228894
            ],
            [
              -87.380247,
              37.935596
            ],
            [
              -87.37282473753419,
              37.9322194789603
            ],
            [
              -87.372439,
              37.932044
            ],
            [
              -87.372039,
              37.931708
            ],
            [
              -87.372711,
              37.930556
            ],
            [
              -87.372327,
              37.930028
            ],
            [
              -87.363622,
              37.922348
            ],
            [
              -87.361638,
              37.921004
            ],
            [
              -87.358294,
              37.92054
            ],
            [
              -87.35471,
              37.918252
            ],
            [
              -87.352614,
              37.916124
            ],
            [
              -87.344933,
              37.911164
            ],
            [
              -87.335397,
              37.907565
            ],
            [
              -87.334165,
              37.908205
            ],
            [
              -87.331765,
              37.908253
            ],
            [
              -87.320036,
              37.905741
            ],
            [
              -87.3025990430289,
              37.8985582968574
            ],
            [
              -87.302324,
              37.898445
            ],
            [
              -87.27437,
              37.882942
            ],
            [
              -87.26989,
              37.879854
            ],
            [
              -87.2686936894134,
              37.878649438995495
            ],
            [
              -87.268003,
              37.924766
            ],
            [
              -87.240918,
              37.952269
            ],
            [
              -87.232476,
              37.948181
            ],
            [
              -87.225701,
              37.971667
            ],
            [
              -87.196218,
              37.987454
            ],
            [
              -87.182282,
              38.010774
            ],
            [
              -87.152794,
              38.023577
            ],
            [
              -87.153495,
              38.053588
            ],
            [
              -87.132774,
              38.05454
            ],
            [
              -87.125021,
              38.043866
            ],
            [
              -87.088487,
              38.049682
            ],
            [
              -87.097718,
              38.056749
            ],
            [
              -87.089241,
              38.082755
            ],
            [
              -87.075161,
              38.096409
            ],
            [
              -87.072332,
              38.11828
            ],
            [
              -87.018026,
              38.118283
            ],
            [
              -87.017459,
              38.122001
            ],
            [
              -87.01749,
              38.20358
            ],
            [
              -87.073141,
              38.203821
            ],
            [
              -87.073067,
              38.232596
            ],
            [
              -87.298388,
              38.231671
            ],
            [
              -87.298218,
              38.245886
            ],
            [
              -87.316893,
              38.246077
            ],
            [
              -87.31703,
              38.201753
            ],
            [
              -87.355139,
              38.201625
            ],
            [
              -87.466717,
              38.201234
            ],
            [
              -87.467284,
              38.165403
            ],
            [
              -87.472093,
              38.121741
            ],
            [
              -87.472499,
              38.078162
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "019",
      "COUNTYNS": "00450340",
      "AFFGEOID": "0500000US18019",
      "GEOID": "18019",
      "NAME": "Clark",
      "LSAD": "06",
      "ALAND": 965646802,
      "AWATER": 9223876,
      "County_state": "Clark,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.99462,
              38.418345
            ],
            [
              -85.99463,
              38.410949
            ],
            [
              -85.966882,
              38.403455
            ],
            [
              -85.920401,
              38.403808
            ],
            [
              -85.807704,
              38.404443
            ],
            [
              -85.760844,
              38.350218
            ],
            [
              -85.763913,
              38.348583
            ],
            [
              -85.79715,
              38.332478
            ],
            [
              -85.783185,
              38.320279
            ],
            [
              -85.793823,
              38.314151
            ],
            [
              -85.7915755577848,
              38.2885654838202
            ],
            [
              -85.791563,
              38.288569
            ],
            [
              -85.780963,
              38.288469
            ],
            [
              -85.773363,
              38.286169
            ],
            [
              -85.765963,
              38.280469
            ],
            [
              -85.765763,
              38.279669
            ],
            [
              -85.766563,
              38.27767
            ],
            [
              -85.761062,
              38.27257
            ],
            [
              -85.750962,
              38.26787
            ],
            [
              -85.744862,
              38.26717
            ],
            [
              -85.738746,
              38.269366
            ],
            [
              -85.73345550237049,
              38.2718684528336
            ],
            [
              -85.7130753274456,
              38.2815084583798
            ],
            [
              -85.68603448270309,
              38.2942990204947
            ],
            [
              -85.683561,
              38.295469
            ],
            [
              -85.6757904672099,
              38.3007875950089
            ],
            [
              -85.675017,
              38.301317
            ],
            [
              -85.668698,
              38.310517
            ],
            [
              -85.659897,
              38.319396
            ],
            [
              -85.653641,
              38.327108
            ],
            [
              -85.646201,
              38.342916
            ],
            [
              -85.638777,
              38.361443
            ],
            [
              -85.638009,
              38.366115
            ],
            [
              -85.638521,
              38.376802
            ],
            [
              -85.63804773991271,
              38.3802883493094
            ],
            [
              -85.638041,
              38.380338
            ],
            [
              -85.6336949144431,
              38.3933898807632
            ],
            [
              -85.632937,
              38.395666
            ],
            [
              -85.629961,
              38.402306
            ],
            [
              -85.621625,
              38.417089
            ],
            [
              -85.620329,
              38.421697
            ],
            [
              -85.620521,
              38.423105
            ],
            [
              -85.607629,
              38.439295
            ],
            [
              -85.603833,
              38.442094
            ],
            [
              -85.587758,
              38.450495
            ],
            [
              -85.575254,
              38.453292
            ],
            [
              -85.553304,
              38.45388
            ],
            [
              -85.536542,
              38.456083
            ],
            [
              -85.527164,
              38.45829
            ],
            [
              -85.516939,
              38.461357
            ],
            [
              -85.498866,
              38.468242
            ],
            [
              -85.491422,
              38.474702
            ],
            [
              -85.482897,
              38.485701
            ],
            [
              -85.481246,
              38.488374
            ],
            [
              -85.479472,
              38.494533
            ],
            [
              -85.47767,
              38.49832
            ],
            [
              -85.474354,
              38.504074
            ],
            [
              -85.472221,
              38.506279
            ],
            [
              -85.466691,
              38.51028
            ],
            [
              -85.462518,
              38.512602
            ],
            [
              -85.458496,
              38.5144
            ],
            [
              -85.441725,
              38.520191
            ],
            [
              -85.433136,
              38.523914
            ],
            [
              -85.43291605412969,
              38.52405813652349
            ],
            [
              -85.425787,
              38.52873
            ],
            [
              -85.423077,
              38.531581
            ],
            [
              -85.417322,
              38.540763
            ],
            [
              -85.4156,
              38.546341
            ],
            [
              -85.415272,
              38.555416
            ],
            [
              -85.415821,
              38.563558
            ],
            [
              -85.419883,
              38.573558
            ],
            [
              -85.42829037139839,
              38.5863257242075
            ],
            [
              -85.478762,
              38.586178
            ],
            [
              -85.496452,
              38.59222
            ],
            [
              -85.496666,
              38.606736
            ],
            [
              -85.56998,
              38.606155
            ],
            [
              -85.719718,
              38.60554
            ],
            [
              -85.749963,
              38.605848
            ],
            [
              -85.765665,
              38.605469
            ],
            [
              -85.793584,
              38.604814
            ],
            [
              -85.821457,
              38.57539
            ],
            [
              -85.821162,
              38.561418
            ],
            [
              -85.847893,
              38.561269
            ],
            [
              -85.847588,
              38.54714
            ],
            [
              -85.866341,
              38.547095
            ],
            [
              -85.866268,
              38.518425
            ],
            [
              -85.884708,
              38.518152
            ],
            [
              -85.884776,
              38.503469
            ],
            [
              -85.957102,
              38.501949
            ],
            [
              -85.956195,
              38.488956
            ],
            [
              -85.993317,
              38.48854
            ],
            [
              -85.99462,
              38.418345
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "151",
      "COUNTYNS": "00450390",
      "AFFGEOID": "0500000US18151",
      "GEOID": "18151",
      "NAME": "Steuben",
      "LSAD": "06",
      "ALAND": 799725227,
      "AWATER": 35458838,
      "County_state": "Steuben,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.196637,
              41.759735
            ],
            [
              -85.19677373742039,
              41.7597353928585
            ],
            [
              -85.194084,
              41.526437
            ],
            [
              -85.080813,
              41.527008
            ],
            [
              -84.968505,
              41.528065
            ],
            [
              -84.804729,
              41.5301345906643
            ],
            [
              -84.804729,
              41.530231
            ],
            [
              -84.805812,
              41.61304
            ],
            [
              -84.8057905226358,
              41.6164389780273
            ],
            [
              -84.805696,
              41.631398
            ],
            [
              -84.805673,
              41.632342
            ],
            [
              -84.80621,
              41.67455
            ],
            [
              -84.806082,
              41.696089
            ],
            [
              -84.806018,
              41.707485
            ],
            [
              -84.806042,
              41.720544
            ],
            [
              -84.806065,
              41.732909
            ],
            [
              -84.806074,
              41.737603
            ],
            [
              -84.806134,
              41.743115
            ],
            [
              -84.805883,
              41.760216
            ],
            [
              -84.818873,
              41.760059
            ],
            [
              -84.82512990965739,
              41.7599907212136
            ],
            [
              -84.825196,
              41.75999
            ],
            [
              -84.932484,
              41.759691
            ],
            [
              -84.96086,
              41.759438
            ],
            [
              -84.961562,
              41.759552
            ],
            [
              -84.971551,
              41.759527
            ],
            [
              -84.972803,
              41.759366
            ],
            [
              -85.037817,
              41.759801
            ],
            [
              -85.039436,
              41.759985
            ],
            [
              -85.117267,
              41.7597
            ],
            [
              -85.123102,
              41.759743
            ],
            [
              -85.17223,
              41.759618
            ],
            [
              -85.196637,
              41.759735
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "167",
      "COUNTYNS": "00450398",
      "AFFGEOID": "0500000US18167",
      "GEOID": "18167",
      "NAME": "Vigo",
      "LSAD": "06",
      "ALAND": 1044993095,
      "AWATER": 18068475,
      "County_state": "Vigo,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.61005,
              39.282232
            ],
            [
              -87.605543,
              39.261122
            ],
            [
              -87.604075798943,
              39.25945851501619
            ],
            [
              -87.351332,
              39.259195
            ],
            [
              -87.240379,
              39.259064
            ],
            [
              -87.239556,
              39.433561
            ],
            [
              -87.23909,
              39.500281
            ],
            [
              -87.238964,
              39.52062
            ],
            [
              -87.200107,
              39.519858
            ],
            [
              -87.199012,
              39.607137
            ],
            [
              -87.382418,
              39.607937
            ],
            [
              -87.5321962235157,
              39.6073059903329
            ],
            [
              -87.532008,
              39.564013
            ],
            [
              -87.531939,
              39.545853
            ],
            [
              -87.531965,
              39.526937
            ],
            [
              -87.53190785893749,
              39.5203603358081
            ],
            [
              -87.531692,
              39.495516
            ],
            [
              -87.531627,
              39.491698
            ],
            [
              -87.531663,
              39.47712
            ],
            [
              -87.5316629504044,
              39.47711015463629
            ],
            [
              -87.531624,
              39.469378
            ],
            [
              -87.531608,
              39.466225
            ],
            [
              -87.531489,
              39.449474
            ],
            [
              -87.531355,
              39.437732
            ],
            [
              -87.531355,
              39.436656
            ],
            [
              -87.5315486565777,
              39.377582092477695
            ],
            [
              -87.531646,
              39.347888
            ],
            [
              -87.544013,
              39.352907
            ],
            [
              -87.5544,
              39.340488
            ],
            [
              -87.578331,
              39.340343
            ],
            [
              -87.589084,
              39.333831
            ],
            [
              -87.600397,
              39.312904
            ],
            [
              -87.597946,
              39.299479
            ],
            [
              -87.597545,
              39.296388
            ],
            [
              -87.61005,
              39.282232
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "155",
      "COUNTYNS": "00450392",
      "AFFGEOID": "0500000US18155",
      "GEOID": "18155",
      "NAME": "Switzerland",
      "LSAD": "06",
      "ALAND": 571509742,
      "AWATER": 7193732,
      "County_state": "Switzerland,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.202849,
              38.928523
            ],
            [
              -85.203166,
              38.913803
            ],
            [
              -85.2015826164201,
              38.6909118349635
            ],
            [
              -85.190507,
              38.68795
            ],
            [
              -85.187278,
              38.687609
            ],
            [
              -85.177112,
              38.688405
            ],
            [
              -85.172528,
              38.688082
            ],
            [
              -85.156158,
              38.692251
            ],
            [
              -85.146861,
              38.695427
            ],
            [
              -85.13868,
              38.699168
            ],
            [
              -85.133049,
              38.702375
            ],
            [
              -85.121357,
              38.711232
            ],
            [
              -85.106902,
              38.720789
            ],
            [
              -85.106979,
              38.72163
            ],
            [
              -85.103313,
              38.725323
            ],
            [
              -85.100963,
              38.7268
            ],
            [
              -85.08218,
              38.735439
            ],
            [
              -85.076369,
              38.739496
            ],
            [
              -85.071928,
              38.741567
            ],
            [
              -85.060264,
              38.744948
            ],
            [
              -85.047967,
              38.750849
            ],
            [
              -85.040938,
              38.755163
            ],
            [
              -85.0235842033963,
              38.7620346655344
            ],
            [
              -85.011772,
              38.766712
            ],
            [
              -84.999949,
              38.774715
            ],
            [
              -84.995939,
              38.776756
            ],
            [
              -84.990006,
              38.778383
            ],
            [
              -84.978723,
              38.77928
            ],
            [
              -84.962535,
              38.778035
            ],
            [
              -84.947644,
              38.775273
            ],
            [
              -84.941071,
              38.775627
            ],
            [
              -84.932977,
              38.777519
            ],
            [
              -84.915234,
              38.784086
            ],
            [
              -84.901874,
              38.790604
            ],
            [
              -84.89393,
              38.793704
            ],
            [
              -84.887919,
              38.794652
            ],
            [
              -84.856904,
              38.790224
            ],
            [
              -84.847918,
              38.788106
            ],
            [
              -84.835672,
              38.784289
            ],
            [
              -84.828714,
              38.783208
            ],
            [
              -84.821378,
              38.783111
            ],
            [
              -84.814641,
              38.784488
            ],
            [
              -84.812877,
              38.786087
            ],
            [
              -84.811752,
              38.789169
            ],
            [
              -84.811645,
              38.792766
            ],
            [
              -84.813939,
              38.800209
            ],
            [
              -84.816506,
              38.80532
            ],
            [
              -84.827098,
              38.818634
            ],
            [
              -84.829886,
              38.825405
            ],
            [
              -84.829958,
              38.830632
            ],
            [
              -84.827488,
              38.834909
            ],
            [
              -84.823363,
              38.839196
            ],
            [
              -84.817169,
              38.84342
            ],
            [
              -84.803247,
              38.850723
            ],
            [
              -84.794616596501,
              38.857119076861395
            ],
            [
              -84.793714,
              38.857788
            ],
            [
              -84.791002,
              38.860572
            ],
            [
              -84.788302,
              38.864325
            ],
            [
              -84.785799,
              38.869496
            ],
            [
              -84.784579,
              38.87532
            ],
            [
              -84.785234,
              38.880439
            ],
            [
              -84.786406,
              38.88222
            ],
            [
              -84.788143,
              38.883728
            ],
            [
              -84.800247,
              38.89107
            ],
            [
              -84.812746,
              38.895132
            ],
            [
              -84.819073,
              38.895469
            ],
            [
              -84.830472,
              38.897256
            ],
            [
              -84.860759,
              38.897654
            ],
            [
              -84.867778,
              38.899133
            ],
            [
              -84.870124,
              38.900389
            ],
            [
              -84.8706197834529,
              38.9010084241634
            ],
            [
              -85.140312,
              38.903194
            ],
            [
              -85.135832,
              38.929578
            ],
            [
              -85.202849,
              38.928523
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "077",
      "COUNTYNS": "00450363",
      "AFFGEOID": "0500000US18077",
      "GEOID": "18077",
      "NAME": "Jefferson",
      "LSAD": "06",
      "ALAND": 934033957,
      "AWATER": 5843999,
      "County_state": "Jefferson,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.683839,
              38.815406
            ],
            [
              -85.683338,
              38.736731
            ],
            [
              -85.646218,
              38.729859
            ],
            [
              -85.64576,
              38.708018
            ],
            [
              -85.608678,
              38.707858
            ],
            [
              -85.60819,
              38.67874
            ],
            [
              -85.570926,
              38.678455
            ],
            [
              -85.56998,
              38.606155
            ],
            [
              -85.496666,
              38.606736
            ],
            [
              -85.496452,
              38.59222
            ],
            [
              -85.478762,
              38.586178
            ],
            [
              -85.42829037139839,
              38.5863257242075
            ],
            [
              -85.43617,
              38.598292
            ],
            [
              -85.437446,
              38.601724
            ],
            [
              -85.438594,
              38.605405
            ],
            [
              -85.439351,
              38.610388
            ],
            [
              -85.439458,
              38.632366
            ],
            [
              -85.437738,
              38.648898
            ],
            [
              -85.438742,
              38.659319
            ],
            [
              -85.444815,
              38.670083
            ],
            [
              -85.455486,
              38.68209
            ],
            [
              -85.456481,
              38.685069
            ],
            [
              -85.456978,
              38.689135
            ],
            [
              -85.455967,
              38.695655
            ],
            [
              -85.452114,
              38.709348
            ],
            [
              -85.448862,
              38.713368
            ],
            [
              -85.442271,
              38.71985
            ],
            [
              -85.437766,
              38.726405
            ],
            [
              -85.434065,
              38.729455
            ],
            [
              -85.422021,
              38.734834
            ],
            [
              -85.416631,
              38.736272
            ],
            [
              -85.410925,
              38.73708
            ],
            [
              -85.400481,
              38.73598
            ],
            [
              -85.372284,
              38.730576
            ],
            [
              -85.363827,
              38.730477
            ],
            [
              -85.3633411161261,
              38.730523810320896
            ],
            [
              -85.351776,
              38.731638
            ],
            [
              -85.340953,
              38.733893
            ],
            [
              -85.33305185946669,
              38.7360828292115
            ],
            [
              -85.330807,
              38.736705
            ],
            [
              -85.306049,
              38.741649
            ],
            [
              -85.289226,
              38.74241
            ],
            [
              -85.275454,
              38.741172
            ],
            [
              -85.267639,
              38.739899
            ],
            [
              -85.258846,
              38.737754
            ],
            [
              -85.246505,
              38.731821
            ],
            [
              -85.242434,
              38.726235
            ],
            [
              -85.238665,
              38.722494
            ],
            [
              -85.226062,
              38.705456
            ],
            [
              -85.221124,
              38.700957
            ],
            [
              -85.213257,
              38.695446
            ],
            [
              -85.2045,
              38.691692
            ],
            [
              -85.2015826164201,
              38.6909118349635
            ],
            [
              -85.203166,
              38.913803
            ],
            [
              -85.444897,
              38.912998
            ],
            [
              -85.463477,
              38.912943
            ],
            [
              -85.5382,
              38.912217
            ],
            [
              -85.537431,
              38.88278
            ],
            [
              -85.573806,
              38.882658
            ],
            [
              -85.573776,
              38.853745
            ],
            [
              -85.610999,
              38.853769
            ],
            [
              -85.610595,
              38.824247
            ],
            [
              -85.683839,
              38.815406
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "033",
      "COUNTYNS": "00450346",
      "AFFGEOID": "0500000US18033",
      "GEOID": "18033",
      "NAME": "DeKalb",
      "LSAD": "06",
      "ALAND": 939731978,
      "AWATER": 2657419,
      "County_state": "DeKalb,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.194084,
              41.526437
            ],
            [
              -85.192094,
              41.264209
            ],
            [
              -85.077804,
              41.265601
            ],
            [
              -84.8035816909474,
              41.271273026028304
            ],
            [
              -84.803926,
              41.367959
            ],
            [
              -84.804133,
              41.408292
            ],
            [
              -84.804046,
              41.408361
            ],
            [
              -84.804015,
              41.411655
            ],
            [
              -84.8039563404477,
              41.4260444864396
            ],
            [
              -84.803956,
              41.426128
            ],
            [
              -84.803919,
              41.435531
            ],
            [
              -84.804457,
              41.488224
            ],
            [
              -84.804551,
              41.500364
            ],
            [
              -84.804729,
              41.530092
            ],
            [
              -84.804729,
              41.5301345906643
            ],
            [
              -84.968505,
              41.528065
            ],
            [
              -85.080813,
              41.527008
            ],
            [
              -85.194084,
              41.526437
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "057",
      "COUNTYNS": "00450356",
      "AFFGEOID": "0500000US18057",
      "GEOID": "18057",
      "NAME": "Hamilton",
      "LSAD": "06",
      "ALAND": 1021307261,
      "AWATER": 20999468,
      "County_state": "Hamilton,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.242743,
              40.215834
            ],
            [
              -86.242365,
              40.180778
            ],
            [
              -86.241722,
              40.09985
            ],
            [
              -86.241458,
              40.070819
            ],
            [
              -86.241065,
              39.998773
            ],
            [
              -86.239739,
              39.926055
            ],
            [
              -86.202864,
              39.926628
            ],
            [
              -86.184029,
              39.926809
            ],
            [
              -86.165066,
              39.926906
            ],
            [
              -86.076484,
              39.926948
            ],
            [
              -86.076183,
              39.92695
            ],
            [
              -86.012555,
              39.927318
            ],
            [
              -85.995037,
              39.927
            ],
            [
              -85.937587,
              39.927137
            ],
            [
              -85.862395,
              39.928943
            ],
            [
              -85.862489,
              39.943618
            ],
            [
              -85.86224,
              40.002288
            ],
            [
              -85.863278,
              40.103861
            ],
            [
              -85.861903,
              40.218936
            ],
            [
              -85.935561,
              40.218437
            ],
            [
              -86.242743,
              40.215834
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "067",
      "COUNTYNS": "00450360",
      "AFFGEOID": "0500000US18067",
      "GEOID": "18067",
      "NAME": "Howard",
      "LSAD": "06",
      "ALAND": 758866967,
      "AWATER": 2386193,
      "County_state": "Howard,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.375151,
              40.517839
            ],
            [
              -86.375762,
              40.431851
            ],
            [
              -86.356799,
              40.431848
            ],
            [
              -86.309249,
              40.431712
            ],
            [
              -86.309325,
              40.388372
            ],
            [
              -86.280829,
              40.388339
            ],
            [
              -86.280755,
              40.373734
            ],
            [
              -86.242992,
              40.373661
            ],
            [
              -86.242812,
              40.402914
            ],
            [
              -86.127113,
              40.404337
            ],
            [
              -86.060645,
              40.405198
            ],
            [
              -85.975506,
              40.406227
            ],
            [
              -85.862117,
              40.406889
            ],
            [
              -85.863047,
              40.478728
            ],
            [
              -85.863641,
              40.565525
            ],
            [
              -85.938785,
              40.564799
            ],
            [
              -86.013325,
              40.564128
            ],
            [
              -86.127424,
              40.563098
            ],
            [
              -86.165024,
              40.562649
            ],
            [
              -86.261248,
              40.561882
            ],
            [
              -86.374417,
              40.561361
            ],
            [
              -86.375151,
              40.517839
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "097",
      "COUNTYNS": "00450371",
      "AFFGEOID": "0500000US18097",
      "GEOID": "18097",
      "NAME": "Marion",
      "LSAD": "06",
      "ALAND": 1026465038,
      "AWATER": 17326366,
      "County_state": "Marion,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.328012,
              39.859269
            ],
            [
              -86.327291,
              39.822492
            ],
            [
              -86.326637,
              39.778645
            ],
            [
              -86.3265,
              39.729442
            ],
            [
              -86.326591,
              39.719486
            ],
            [
              -86.326341,
              39.632177
            ],
            [
              -86.249885,
              39.633645
            ],
            [
              -86.233906,
              39.633859
            ],
            [
              -86.205516,
              39.634246
            ],
            [
              -86.163749,
              39.634999
            ],
            [
              -86.139743,
              39.635355
            ],
            [
              -86.11123,
              39.63575
            ],
            [
              -85.95208,
              39.638345
            ],
            [
              -85.951721,
              39.697136
            ],
            [
              -85.951699,
              39.726419
            ],
            [
              -85.952314,
              39.772524
            ],
            [
              -85.952378,
              39.782674
            ],
            [
              -85.952582,
              39.79738
            ],
            [
              -85.952902,
              39.806749
            ],
            [
              -85.953123,
              39.826777
            ],
            [
              -85.953489,
              39.841465
            ],
            [
              -85.953683,
              39.856117
            ],
            [
              -85.938067,
              39.869812
            ],
            [
              -85.938077,
              39.887183
            ],
            [
              -85.938084,
              39.923196
            ],
            [
              -85.937587,
              39.927137
            ],
            [
              -85.995037,
              39.927
            ],
            [
              -86.012555,
              39.927318
            ],
            [
              -86.076183,
              39.92695
            ],
            [
              -86.076484,
              39.926948
            ],
            [
              -86.165066,
              39.926906
            ],
            [
              -86.184029,
              39.926809
            ],
            [
              -86.202864,
              39.926628
            ],
            [
              -86.239739,
              39.926055
            ],
            [
              -86.278471,
              39.924729
            ],
            [
              -86.326293,
              39.924029
            ],
            [
              -86.325373,
              39.866176
            ],
            [
              -86.328012,
              39.859269
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "107",
      "COUNTYNS": "00450374",
      "AFFGEOID": "0500000US18107",
      "GEOID": "18107",
      "NAME": "Montgomery",
      "LSAD": "06",
      "ALAND": 1306954200,
      "AWATER": 2144021,
      "County_state": "Montgomery,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.092563,
              40.214806
            ],
            [
              -87.092142,
              40.039019
            ],
            [
              -87.091352,
              40.034621
            ],
            [
              -87.091006,
              39.953009
            ],
            [
              -87.091051,
              39.945655
            ],
            [
              -87.091542,
              39.867265
            ],
            [
              -87.009477,
              39.866705
            ],
            [
              -86.695127,
              39.864845
            ],
            [
              -86.695071,
              39.92277
            ],
            [
              -86.695781,
              40.010581
            ],
            [
              -86.695804,
              40.178657
            ],
            [
              -86.695637,
              40.214367
            ],
            [
              -86.86696,
              40.214918
            ],
            [
              -86.923342,
              40.215047
            ],
            [
              -87.092563,
              40.214806
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "109",
      "COUNTYNS": "00450375",
      "AFFGEOID": "0500000US18109",
      "GEOID": "18109",
      "NAME": "Morgan",
      "LSAD": "06",
      "ALAND": 1046298638,
      "AWATER": 14115819,
      "County_state": "Morgan,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.687561,
              39.521902
            ],
            [
              -86.68571,
              39.470063
            ],
            [
              -86.632206,
              39.470214
            ],
            [
              -86.63191,
              39.441086
            ],
            [
              -86.630779,
              39.426545
            ],
            [
              -86.630955,
              39.406256
            ],
            [
              -86.630818,
              39.346948
            ],
            [
              -86.630844,
              39.340755
            ],
            [
              -86.57428,
              39.339681
            ],
            [
              -86.464609,
              39.338295
            ],
            [
              -86.381395,
              39.339752
            ],
            [
              -86.251684,
              39.341672
            ],
            [
              -86.25142,
              39.37478
            ],
            [
              -86.251621,
              39.516671
            ],
            [
              -86.250291,
              39.517494
            ],
            [
              -86.250626,
              39.567936
            ],
            [
              -86.249885,
              39.633645
            ],
            [
              -86.326341,
              39.632177
            ],
            [
              -86.335904,
              39.631985
            ],
            [
              -86.417894,
              39.630457
            ],
            [
              -86.437058,
              39.630294
            ],
            [
              -86.465931,
              39.630032
            ],
            [
              -86.465605,
              39.600769
            ],
            [
              -86.559847,
              39.600862
            ],
            [
              -86.65665,
              39.601395
            ],
            [
              -86.659594,
              39.579206
            ],
            [
              -86.64832,
              39.565441
            ],
            [
              -86.662283,
              39.540437
            ],
            [
              -86.687561,
              39.521902
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "061",
      "COUNTYNS": "00451677",
      "AFFGEOID": "0500000US18061",
      "GEOID": "18061",
      "NAME": "Harrison",
      "LSAD": "06",
      "ALAND": 1254889443,
      "AWATER": 5193189,
      "County_state": "Harrison,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.317322,
              38.178143
            ],
            [
              -86.33001851513349,
              38.1811511718017
            ],
            [
              -86.317139,
              38.172907
            ],
            [
              -86.304155,
              38.167872
            ],
            [
              -86.287773,
              38.15805
            ],
            [
              -86.271802,
              38.137874
            ],
            [
              -86.271223,
              38.130112
            ],
            [
              -86.278656,
              38.098509
            ],
            [
              -86.27872,
              38.089303
            ],
            [
              -86.273584,
              38.067443
            ],
            [
              -86.26731,
              38.057655
            ],
            [
              -86.266891,
              38.057125
            ],
            [
              -86.261273,
              38.052721
            ],
            [
              -86.249972,
              38.04583
            ],
            [
              -86.233057,
              38.039305
            ],
            [
              -86.225519,
              38.03328
            ],
            [
              -86.220371,
              38.027922
            ],
            [
              -86.206439,
              38.021876
            ],
            [
              -86.190927,
              38.016438
            ],
            [
              -86.178983,
              38.011308
            ],
            [
              -86.172186,
              38.00992
            ],
            [
              -86.16731,
              38.009879
            ],
            [
              -86.141063,
              38.01547
            ],
            [
              -86.12757,
              38.016011
            ],
            [
              -86.118208,
              38.015279
            ],
            [
              -86.108156,
              38.013416
            ],
            [
              -86.095766,
              38.00893
            ],
            [
              -86.087525,
              38.005127
            ],
            [
              -86.080034,
              38.000848
            ],
            [
              -86.075393,
              37.996948
            ],
            [
              -86.07398,
              37.995449
            ],
            [
              -86.074915,
              37.993345
            ],
            [
              -86.071644,
              37.9872
            ],
            [
              -86.064859,
              37.975618
            ],
            [
              -86.061731,
              37.971326
            ],
            [
              -86.053912,
              37.963571
            ],
            [
              -86.048458,
              37.959369
            ],
            [
              -86.045208,
              37.958258
            ],
            [
              -86.042354,
              37.958018
            ],
            [
              -86.038188,
              37.95935
            ],
            [
              -86.036013,
              37.961703
            ],
            [
              -86.034355,
              37.964621
            ],
            [
              -86.033386,
              37.970382
            ],
            [
              -86.035279,
              37.981228
            ],
            [
              -86.035012,
              37.984814
            ],
            [
              -86.032468,
              37.9901
            ],
            [
              -86.029509,
              37.99264
            ],
            [
              -86.020655,
              37.996116
            ],
            [
              -86.009127,
              37.998529
            ],
            [
              -85.99896743483329,
              37.999779408020494
            ],
            [
              -85.996582,
              38.000073
            ],
            [
              -85.976028,
              38.00356
            ],
            [
              -85.958299,
              38.004616
            ],
            [
              -85.951467,
              38.005608
            ],
            [
              -85.9480303121263,
              38.0071402282467
            ],
            [
              -85.9456286311378,
              38.008211004408395
            ],
            [
              -85.939483,
              38.010951
            ],
            [
              -85.934635,
              38.014423
            ],
            [
              -85.930235,
              38.018311
            ],
            [
              -85.925418,
              38.023456
            ],
            [
              -85.92390443119619,
              38.0260710743837
            ],
            [
              -85.922395,
              38.028679
            ],
            [
              -85.921371,
              38.032135
            ],
            [
              -85.919563,
              38.041079
            ],
            [
              -85.918379,
              38.054214
            ],
            [
              -85.916987,
              38.061846
            ],
            [
              -85.915643,
              38.06647
            ],
            [
              -85.913163,
              38.07337
            ],
            [
              -85.906163,
              38.08617
            ],
            [
              -85.904564,
              38.10027
            ],
            [
              -85.905164,
              38.11107
            ],
            [
              -85.9051687084466,
              38.1111017546398
            ],
            [
              -85.909464,
              38.14007
            ],
            [
              -85.908764,
              38.161169
            ],
            [
              -85.89955038654419,
              38.1803432766511
            ],
            [
              -85.900604,
              38.185701
            ],
            [
              -85.957576,
              38.186072
            ],
            [
              -85.955224,
              38.260501
            ],
            [
              -85.973786,
              38.260668
            ],
            [
              -85.973689,
              38.275272
            ],
            [
              -85.992358,
              38.275379
            ],
            [
              -85.995297,
              38.293861
            ],
            [
              -85.99528,
              38.300893
            ],
            [
              -86.014115,
              38.301813
            ],
            [
              -86.014312,
              38.330718
            ],
            [
              -86.032559,
              38.33066
            ],
            [
              -86.032817,
              38.417898
            ],
            [
              -86.141731,
              38.419917
            ],
            [
              -86.255045,
              38.422685
            ],
            [
              -86.259489,
              38.388927
            ],
            [
              -86.247158,
              38.34946
            ],
            [
              -86.270757,
              38.361277
            ],
            [
              -86.281143,
              38.35063
            ],
            [
              -86.274908,
              38.335807
            ],
            [
              -86.273474,
              38.321105
            ],
            [
              -86.289669,
              38.316091
            ],
            [
              -86.259348,
              38.30614
            ],
            [
              -86.26439,
              38.294662
            ],
            [
              -86.253137,
              38.2919
            ],
            [
              -86.274762,
              38.279956
            ],
            [
              -86.276956,
              38.227126
            ],
            [
              -86.294927,
              38.223964
            ],
            [
              -86.289903,
              38.202692
            ],
            [
              -86.306511,
              38.202519
            ],
            [
              -86.317322,
              38.178143
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "165",
      "COUNTYNS": "00450397",
      "AFFGEOID": "0500000US18165",
      "GEOID": "18165",
      "NAME": "Vermillion",
      "LSAD": "06",
      "ALAND": 665311748,
      "AWATER": 7904759,
      "County_state": "Vermillion,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.533227,
              39.883127
            ],
            [
              -87.5332268504449,
              39.88300000135369
            ],
            [
              -87.533142,
              39.810947
            ],
            [
              -87.533056,
              39.803922
            ],
            [
              -87.533058,
              39.796243
            ],
            [
              -87.533066,
              39.781743
            ],
            [
              -87.532703,
              39.664868
            ],
            [
              -87.532444,
              39.646102
            ],
            [
              -87.532365,
              39.646126
            ],
            [
              -87.5321962235157,
              39.6073059903329
            ],
            [
              -87.382418,
              39.607937
            ],
            [
              -87.395625,
              39.65295
            ],
            [
              -87.381618,
              39.694266
            ],
            [
              -87.384418,
              39.718573
            ],
            [
              -87.377168,
              39.746105
            ],
            [
              -87.380567,
              39.766766
            ],
            [
              -87.374605,
              39.792498
            ],
            [
              -87.383561,
              39.804818
            ],
            [
              -87.385538,
              39.833365
            ],
            [
              -87.366394,
              39.842871
            ],
            [
              -87.353863,
              39.862077
            ],
            [
              -87.376891,
              39.873505
            ],
            [
              -87.368863,
              39.890471
            ],
            [
              -87.379939,
              39.903957
            ],
            [
              -87.404959,
              39.910857
            ],
            [
              -87.419069,
              39.925711
            ],
            [
              -87.43319,
              39.931384
            ],
            [
              -87.420221,
              39.952452
            ],
            [
              -87.43984,
              39.959486
            ],
            [
              -87.427385,
              39.982139
            ],
            [
              -87.435013,
              40.026293
            ],
            [
              -87.417438,
              40.058684
            ],
            [
              -87.406667,
              40.127508
            ],
            [
              -87.49041,
              40.127406
            ],
            [
              -87.490455,
              40.148422
            ],
            [
              -87.5314376605081,
              40.1481233164708
            ],
            [
              -87.531439,
              40.148027
            ],
            [
              -87.531759,
              40.144273
            ],
            [
              -87.531561,
              40.133005
            ],
            [
              -87.532308,
              40.011587
            ],
            [
              -87.532308,
              40.011492
            ],
            [
              -87.532287,
              40.000037
            ],
            [
              -87.532331,
              39.997776
            ],
            [
              -87.532542,
              39.987462
            ],
            [
              -87.532683,
              39.977691
            ],
            [
              -87.53279,
              39.97501
            ],
            [
              -87.532776,
              39.971077
            ],
            [
              -87.533227,
              39.883127
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "009",
      "COUNTYNS": "00450404",
      "AFFGEOID": "0500000US18009",
      "GEOID": "18009",
      "NAME": "Blackford",
      "LSAD": "06",
      "ALAND": 427554638,
      "AWATER": 1303566,
      "County_state": "Blackford,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.447014,
              40.566929
            ],
            [
              -85.445829,
              40.479302
            ],
            [
              -85.44433,
              40.37914
            ],
            [
              -85.444323,
              40.378746
            ],
            [
              -85.336359,
              40.378709
            ],
            [
              -85.331737,
              40.378222
            ],
            [
              -85.219901,
              40.379034
            ],
            [
              -85.211516,
              40.379188
            ],
            [
              -85.206831,
              40.379182
            ],
            [
              -85.206864,
              40.382817
            ],
            [
              -85.206508,
              40.479373
            ],
            [
              -85.200809,
              40.480697
            ],
            [
              -85.201146,
              40.567242
            ],
            [
              -85.220999,
              40.567251
            ],
            [
              -85.390539,
              40.566898
            ],
            [
              -85.447014,
              40.566929
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "179",
      "COUNTYNS": "00450337",
      "AFFGEOID": "0500000US18179",
      "GEOID": "18179",
      "NAME": "Wells",
      "LSAD": "06",
      "ALAND": 953341274,
      "AWATER": 5596377,
      "County_state": "Wells,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.448825,
              40.653607
            ],
            [
              -85.447014,
              40.566929
            ],
            [
              -85.390539,
              40.566898
            ],
            [
              -85.220999,
              40.567251
            ],
            [
              -85.201146,
              40.567242
            ],
            [
              -85.068479,
              40.56824
            ],
            [
              -85.070245,
              40.740621
            ],
            [
              -85.071024,
              40.744886
            ],
            [
              -85.073861,
              40.917823
            ],
            [
              -85.109723,
              40.917331
            ],
            [
              -85.167063,
              40.916585
            ],
            [
              -85.33603,
              40.917082
            ],
            [
              -85.334667,
              40.654413
            ],
            [
              -85.34396,
              40.654801
            ],
            [
              -85.448825,
              40.653607
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "163",
      "COUNTYNS": "00450396",
      "AFFGEOID": "0500000US18163",
      "GEOID": "18163",
      "NAME": "Vanderburgh",
      "LSAD": "06",
      "ALAND": 605228197,
      "AWATER": 7654554,
      "County_state": "Vanderburgh,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.698329,
              38.00822
            ],
            [
              -87.7006847636268,
              37.8973691919489
            ],
            [
              -87.688338,
              37.902474
            ],
            [
              -87.684018,
              37.903498
            ],
            [
              -87.680338,
              37.903274
            ],
            [
              -87.67573,
              37.90193
            ],
            [
              -87.671457,
              37.899498
            ],
            [
              -87.666481,
              37.895786
            ],
            [
              -87.665025,
              37.893514
            ],
            [
              -87.662865,
              37.885578
            ],
            [
              -87.66282,
              37.881449
            ],
            [
              -87.664101,
              37.877176
            ],
            [
              -87.666175,
              37.874146
            ],
            [
              -87.668879,
              37.871497
            ],
            [
              -87.673186,
              37.868412
            ],
            [
              -87.6754,
              37.865946
            ],
            [
              -87.681633,
              37.855917
            ],
            [
              -87.6819,
              37.84641
            ],
            [
              -87.680689,
              37.84062
            ],
            [
              -87.679188,
              37.836321
            ],
            [
              -87.675538,
              37.831732
            ],
            [
              -87.672397,
              37.829127
            ],
            [
              -87.666522,
              37.827455
            ],
            [
              -87.655171,
              37.826037
            ],
            [
              -87.645858,
              37.825899
            ],
            [
              -87.635806,
              37.827015
            ],
            [
              -87.625014,
              37.829077
            ],
            [
              -87.615399,
              37.831974
            ],
            [
              -87.612426,
              37.83384
            ],
            [
              -87.606599,
              37.838669
            ],
            [
              -87.591504,
              37.856642
            ],
            [
              -87.588729,
              37.860984
            ],
            [
              -87.588426,
              37.868791
            ],
            [
              -87.591582,
              37.887194
            ],
            [
              -87.597118,
              37.892394
            ],
            [
              -87.601967,
              37.895722
            ],
            [
              -87.608479,
              37.898794
            ],
            [
              -87.620272,
              37.906922
            ],
            [
              -87.623296,
              37.910746
            ],
            [
              -87.626256,
              37.916138
            ],
            [
              -87.628416,
              37.92145
            ],
            [
              -87.62896,
              37.926714
            ],
            [
              -87.625616,
              37.933442
            ],
            [
              -87.619488,
              37.938538
            ],
            [
              -87.610816,
              37.944602
            ],
            [
              -87.606216,
              37.949642
            ],
            [
              -87.603516,
              37.958942
            ],
            [
              -87.605216,
              37.961442
            ],
            [
              -87.605216,
              37.965142
            ],
            [
              -87.603816,
              37.968942
            ],
            [
              -87.601416,
              37.972542
            ],
            [
              -87.596716,
              37.974842
            ],
            [
              -87.592916,
              37.975842
            ],
            [
              -87.589816,
              37.976042
            ],
            [
              -87.5878975398239,
              37.9757468522806
            ],
            [
              -87.585916,
              37.975442
            ],
            [
              -87.581115,
              37.973442
            ],
            [
              -87.577915,
              37.971542
            ],
            [
              -87.574715,
              37.967742
            ],
            [
              -87.573415,
              37.962642
            ],
            [
              -87.574287,
              37.954842
            ],
            [
              -87.57203,
              37.947466
            ],
            [
              -87.568398,
              37.941226
            ],
            [
              -87.56587,
              37.93793
            ],
            [
              -87.559342,
              37.931146
            ],
            [
              -87.551277,
              37.925418
            ],
            [
              -87.5485030002246,
              37.9239979763054
            ],
            [
              -87.545901,
              37.922666
            ],
            [
              -87.531532,
              37.916298
            ],
            [
              -87.520284,
              37.912618
            ],
            [
              -87.511499,
              37.906426
            ],
            [
              -87.507483,
              37.90673
            ],
            [
              -87.501131,
              37.909162
            ],
            [
              -87.490411,
              37.916682
            ],
            [
              -87.486347,
              37.920218
            ],
            [
              -87.465514,
              37.93369
            ],
            [
              -87.4511762695734,
              37.9410807495909
            ],
            [
              -87.450707,
              37.947763
            ],
            [
              -87.449906,
              37.991204
            ],
            [
              -87.449867,
              38.03517
            ],
            [
              -87.472983,
              38.034944
            ],
            [
              -87.472499,
              38.078162
            ],
            [
              -87.472093,
              38.121741
            ],
            [
              -87.467284,
              38.165403
            ],
            [
              -87.688374,
              38.168419
            ],
            [
              -87.688918,
              38.12471
            ],
            [
              -87.696775,
              38.124843
            ],
            [
              -87.698329,
              38.00822
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "001",
      "COUNTYNS": "00450401",
      "AFFGEOID": "0500000US18001",
      "GEOID": "18001",
      "NAME": "Adams",
      "LSAD": "06",
      "ALAND": 878079474,
      "AWATER": 2429955,
      "County_state": "Adams,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.073861,
              40.917823
            ],
            [
              -85.071024,
              40.744886
            ],
            [
              -85.070245,
              40.740621
            ],
            [
              -85.068479,
              40.56824
            ],
            [
              -84.80226501503509,
              40.57221195372949
            ],
            [
              -84.802265,
              40.572215
            ],
            [
              -84.802135,
              40.644859
            ],
            [
              -84.802193,
              40.660298
            ],
            [
              -84.80222,
              40.674776
            ],
            [
              -84.802157,
              40.689324
            ],
            [
              -84.802127,
              40.691405
            ],
            [
              -84.802094,
              40.702476
            ],
            [
              -84.802181,
              40.718602
            ],
            [
              -84.80211911064869,
              40.728145936904696
            ],
            [
              -84.802119,
              40.728163
            ],
            [
              -84.802266,
              40.742298
            ],
            [
              -84.802538,
              40.765515
            ],
            [
              -84.80217,
              40.800601
            ],
            [
              -84.802935,
              40.922377
            ],
            [
              -84.8029360779022,
              40.9225675776629
            ],
            [
              -85.073861,
              40.917823
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "183",
      "COUNTYNS": "00450369",
      "AFFGEOID": "0500000US18183",
      "GEOID": "18183",
      "NAME": "Whitley",
      "LSAD": "06",
      "ALAND": 869120483,
      "AWATER": 6073937,
      "County_state": "Whitley,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.686574,
              41.178376
            ],
            [
              -85.684181,
              41.046716
            ],
            [
              -85.683198,
              41.001909
            ],
            [
              -85.643841,
              41.002305
            ],
            [
              -85.452146,
              41.005232
            ],
            [
              -85.393971,
              41.00519
            ],
            [
              -85.335643,
              41.00525
            ],
            [
              -85.337024,
              41.089723
            ],
            [
              -85.338552,
              41.17912
            ],
            [
              -85.30979,
              41.179257
            ],
            [
              -85.307781,
              41.264158
            ],
            [
              -85.424053,
              41.265048
            ],
            [
              -85.53718,
              41.266157
            ],
            [
              -85.536288,
              41.295241
            ],
            [
              -85.651928,
              41.294776
            ],
            [
              -85.651196,
              41.265773
            ],
            [
              -85.652715,
              41.178489
            ],
            [
              -85.686574,
              41.178376
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "035",
      "COUNTYNS": "00450347",
      "AFFGEOID": "0500000US18035",
      "GEOID": "18035",
      "NAME": "Delaware",
      "LSAD": "06",
      "ALAND": 1015542111,
      "AWATER": 9857909,
      "County_state": "Delaware,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.578589,
              40.379524
            ],
            [
              -85.577018,
              40.306381
            ],
            [
              -85.575982,
              40.149601
            ],
            [
              -85.575903,
              40.142243
            ],
            [
              -85.576051,
              40.110032
            ],
            [
              -85.576197,
              40.077143
            ],
            [
              -85.442547,
              40.076686
            ],
            [
              -85.214386,
              40.076889
            ],
            [
              -85.217428,
              40.234931
            ],
            [
              -85.218758,
              40.306706
            ],
            [
              -85.219901,
              40.379034
            ],
            [
              -85.331737,
              40.378222
            ],
            [
              -85.336359,
              40.378709
            ],
            [
              -85.444323,
              40.378746
            ],
            [
              -85.44433,
              40.37914
            ],
            [
              -85.578589,
              40.379524
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "141",
      "COUNTYNS": "00452855",
      "AFFGEOID": "0500000US18141",
      "GEOID": "18141",
      "NAME": "St. Joseph",
      "LSAD": "06",
      "ALAND": 1185912330,
      "AWATER": 9070753,
      "County_state": "St. Joseph,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.526006,
              41.67245
            ],
            [
              -86.526682,
              41.65044
            ],
            [
              -86.487567,
              41.649325
            ],
            [
              -86.486433,
              41.570545
            ],
            [
              -86.498158,
              41.561083
            ],
            [
              -86.49814,
              41.531409
            ],
            [
              -86.503612,
              41.519534
            ],
            [
              -86.52494,
              41.519675
            ],
            [
              -86.524711,
              41.432886
            ],
            [
              -86.466603,
              41.432968
            ],
            [
              -86.467108,
              41.476459
            ],
            [
              -86.329838,
              41.478099
            ],
            [
              -86.30649,
              41.477292
            ],
            [
              -86.286216,
              41.47872
            ],
            [
              -86.059451,
              41.479154
            ],
            [
              -86.059773,
              41.516453
            ],
            [
              -86.05944,
              41.521465
            ],
            [
              -86.060521,
              41.586531
            ],
            [
              -86.061084,
              41.65068
            ],
            [
              -86.060902,
              41.653448
            ],
            [
              -86.061329,
              41.678604
            ],
            [
              -86.061359,
              41.680497
            ],
            [
              -86.0625749882628,
              41.7605284110677
            ],
            [
              -86.086463628697,
              41.7605466047891
            ],
            [
              -86.12506,
              41.760576
            ],
            [
              -86.12546,
              41.76056
            ],
            [
              -86.127844,
              41.760592
            ],
            [
              -86.178753691729,
              41.760265255828294
            ],
            [
              -86.21759,
              41.760016
            ],
            [
              -86.22607,
              41.760016
            ],
            [
              -86.22609688873129,
              41.760016130263004
            ],
            [
              -86.265496,
              41.760207
            ],
            [
              -86.2724268033895,
              41.76018781596849
            ],
            [
              -86.501773,
              41.759553
            ],
            [
              -86.519318,
              41.759447
            ],
            [
              -86.524222859982,
              41.7594561006795
            ],
            [
              -86.525408,
              41.694581
            ],
            [
              -86.526006,
              41.67245
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "059",
      "COUNTYNS": "00450357",
      "AFFGEOID": "0500000US18059",
      "GEOID": "18059",
      "NAME": "Hancock",
      "LSAD": "06",
      "ALAND": 792563315,
      "AWATER": 2628056,
      "County_state": "Hancock,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.953683,
              39.856117
            ],
            [
              -85.953489,
              39.841465
            ],
            [
              -85.953123,
              39.826777
            ],
            [
              -85.952902,
              39.806749
            ],
            [
              -85.952582,
              39.79738
            ],
            [
              -85.952378,
              39.782674
            ],
            [
              -85.952314,
              39.772524
            ],
            [
              -85.951699,
              39.726419
            ],
            [
              -85.951721,
              39.697136
            ],
            [
              -85.894876,
              39.698442
            ],
            [
              -85.633228,
              39.698462
            ],
            [
              -85.633491,
              39.786177
            ],
            [
              -85.596916,
              39.786519
            ],
            [
              -85.596678,
              39.872926
            ],
            [
              -85.576798,
              39.872808
            ],
            [
              -85.576192,
              39.945756
            ],
            [
              -85.805711,
              39.944567
            ],
            [
              -85.862489,
              39.943618
            ],
            [
              -85.862395,
              39.928943
            ],
            [
              -85.937587,
              39.927137
            ],
            [
              -85.938084,
              39.923196
            ],
            [
              -85.938077,
              39.887183
            ],
            [
              -85.938067,
              39.869812
            ],
            [
              -85.953683,
              39.856117
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "111",
      "COUNTYNS": "00450376",
      "AFFGEOID": "0500000US18111",
      "GEOID": "18111",
      "NAME": "Newton",
      "LSAD": "06",
      "ALAND": 1040539918,
      "AWATER": 4349506,
      "County_state": "Newton,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.526719,
              41.159448
            ],
            [
              -87.526693,
              41.153958
            ],
            [
              -87.526696,
              41.149222
            ],
            [
              -87.5267,
              41.139658
            ],
            [
              -87.526711,
              41.121485
            ],
            [
              -87.52652,
              41.024837
            ],
            [
              -87.526346,
              41.010583
            ],
            [
              -87.52630654473128,
              41.0103549293006
            ],
            [
              -87.526305,
              41.010346
            ],
            [
              -87.526084,
              40.911914
            ],
            [
              -87.5260836726485,
              40.9118376242294
            ],
            [
              -87.526014,
              40.895582
            ],
            [
              -87.526437,
              40.894209
            ],
            [
              -87.525962,
              40.880618
            ],
            [
              -87.526113,
              40.879703
            ],
            [
              -87.525783,
              40.854357
            ],
            [
              -87.526129,
              40.73695
            ],
            [
              -87.5261290525058,
              40.7368850793018
            ],
            [
              -87.267298,
              40.736582
            ],
            [
              -87.268443,
              40.89479
            ],
            [
              -87.26905,
              40.911517
            ],
            [
              -87.276449,
              40.911477
            ],
            [
              -87.275882,
              41.218595
            ],
            [
              -87.324599,
              41.186203
            ],
            [
              -87.380709,
              41.172652
            ],
            [
              -87.393796,
              41.162806
            ],
            [
              -87.414421,
              41.162248
            ],
            [
              -87.442293,
              41.17341
            ],
            [
              -87.5265703397201,
              41.166096664112004
            ],
            [
              -87.526567,
              41.163865
            ],
            [
              -87.5266425829641,
              41.1607969818351
            ],
            [
              -87.52666,
              41.16009
            ],
            [
              -87.526719,
              41.159448
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "139",
      "COUNTYNS": "00446854",
      "AFFGEOID": "0500000US18139",
      "GEOID": "18139",
      "NAME": "Rush",
      "LSAD": "06",
      "ALAND": 1057035404,
      "AWATER": 872270,
      "County_state": "Rush,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.633491,
              39.786177
            ],
            [
              -85.633228,
              39.698462
            ],
            [
              -85.632657,
              39.691236
            ],
            [
              -85.632771,
              39.625706
            ],
            [
              -85.632224,
              39.61116
            ],
            [
              -85.629327,
              39.45275
            ],
            [
              -85.41369,
              39.453124
            ],
            [
              -85.297575,
              39.453275
            ],
            [
              -85.29811,
              39.525476
            ],
            [
              -85.301584,
              39.787579
            ],
            [
              -85.409211,
              39.78731
            ],
            [
              -85.596916,
              39.786519
            ],
            [
              -85.633491,
              39.786177
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "133",
      "COUNTYNS": "00450384",
      "AFFGEOID": "0500000US18133",
      "GEOID": "18133",
      "NAME": "Putnam",
      "LSAD": "06",
      "ALAND": 1244585902,
      "AWATER": 5585892,
      "County_state": "Putnam,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.013062,
              39.604787
            ],
            [
              -87.014533,
              39.473572
            ],
            [
              -86.939981,
              39.473345
            ],
            [
              -86.691656,
              39.470543
            ],
            [
              -86.68571,
              39.470063
            ],
            [
              -86.687561,
              39.521902
            ],
            [
              -86.662283,
              39.540437
            ],
            [
              -86.64832,
              39.565441
            ],
            [
              -86.659594,
              39.579206
            ],
            [
              -86.65665,
              39.601395
            ],
            [
              -86.641745,
              39.63004
            ],
            [
              -86.687406,
              39.630437
            ],
            [
              -86.686843,
              39.864842
            ],
            [
              -86.695127,
              39.864845
            ],
            [
              -87.009477,
              39.866705
            ],
            [
              -87.013062,
              39.604787
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "147",
      "COUNTYNS": "00450388",
      "AFFGEOID": "0500000US18147",
      "GEOID": "18147",
      "NAME": "Spencer",
      "LSAD": "06",
      "ALAND": 1027620480,
      "AWATER": 12069099,
      "County_state": "Spencer,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.268003,
              37.924766
            ],
            [
              -87.2686936894134,
              37.878649438995495
            ],
            [
              -87.26293,
              37.872846
            ],
            [
              -87.25525,
              37.867326
            ],
            [
              -87.220944,
              37.849134
            ],
            [
              -87.212416,
              37.846223
            ],
            [
              -87.20224,
              37.843791
            ],
            [
              -87.180063,
              37.841375
            ],
            [
              -87.170831,
              37.842319
            ],
            [
              -87.164863,
              37.841215
            ],
            [
              -87.162319,
              37.840159
            ],
            [
              -87.158878,
              37.837871
            ],
            [
              -87.153486,
              37.832384
            ],
            [
              -87.14195,
              37.816176
            ],
            [
              -87.137502,
              37.807264
            ],
            [
              -87.13749861515589,
              37.807252292623005
            ],
            [
              -87.133149,
              37.792208
            ],
            [
              -87.129629,
              37.786608
            ],
            [
              -87.127533,
              37.78504
            ],
            [
              -87.119229,
              37.782848
            ],
            [
              -87.111133,
              37.782512
            ],
            [
              -87.0999,
              37.78464
            ],
            [
              -87.090636,
              37.787808
            ],
            [
              -87.077404,
              37.796209
            ],
            [
              -87.0765780955594,
              37.7969180498555
            ],
            [
              -87.070732,
              37.801937
            ],
            [
              -87.067836,
              37.806065
            ],
            [
              -87.065388,
              37.810481
            ],
            [
              -87.057836,
              37.827457
            ],
            [
              -87.055404,
              37.835297
            ],
            [
              -87.051452,
              37.853681
            ],
            [
              -87.04926,
              37.859745
            ],
            [
              -87.043854,
              37.870796
            ],
            [
              -87.043049,
              37.875049
            ],
            [
              -87.043407,
              37.87994
            ],
            [
              -87.044144,
              37.884025
            ],
            [
              -87.045894,
              37.887574
            ],
            [
              -87.046237,
              37.889866
            ],
            [
              -87.045101,
              37.893775
            ],
            [
              -87.042249,
              37.898291
            ],
            [
              -87.033444,
              37.906593
            ],
            [
              -87.010315,
              37.919668
            ],
            [
              -87.003301,
              37.922395
            ],
            [
              -86.978957,
              37.9302
            ],
            [
              -86.9788342958521,
              37.9302329010012
            ],
            [
              -86.969044,
              37.932858
            ],
            [
              -86.964785,
              37.932384
            ],
            [
              -86.944633,
              37.933534
            ],
            [
              -86.933357,
              37.934939
            ],
            [
              -86.927747,
              37.934956
            ],
            [
              -86.919329,
              37.936664
            ],
            [
              -86.90726731730089,
              37.9429519357504
            ],
            [
              -86.907131,
              37.943023
            ],
            [
              -86.902413,
              37.946161
            ],
            [
              -86.892084,
              37.955929
            ],
            [
              -86.884961,
              37.964373
            ],
            [
              -86.881338,
              37.967523
            ],
            [
              -86.875874,
              37.97077
            ],
            [
              -86.870388,
              37.975276
            ],
            [
              -86.866936,
              37.979294
            ],
            [
              -86.863224,
              37.982495
            ],
            [
              -86.85595,
              37.987292
            ],
            [
              -86.849027,
              37.99002
            ],
            [
              -86.835161,
              37.99375
            ],
            [
              -86.823491,
              37.998939
            ],
            [
              -86.820071,
              37.999392
            ],
            [
              -86.815267,
              37.998877
            ],
            [
              -86.81475580563179,
              37.9986742364093
            ],
            [
              -86.798293,
              38.015528
            ],
            [
              -86.790518,
              38.003628
            ],
            [
              -86.772367,
              37.998165
            ],
            [
              -86.782628,
              38.048438
            ],
            [
              -86.767352,
              38.056924
            ],
            [
              -86.780316,
              38.078931
            ],
            [
              -86.767213,
              38.094347
            ],
            [
              -86.792448,
              38.116644
            ],
            [
              -86.807235,
              38.117953
            ],
            [
              -86.795016,
              38.123544
            ],
            [
              -86.806261,
              38.143119
            ],
            [
              -86.795555,
              38.156793
            ],
            [
              -86.803569,
              38.18143
            ],
            [
              -86.791316,
              38.180071
            ],
            [
              -86.791497,
              38.20513
            ],
            [
              -86.92368,
              38.203028
            ],
            [
              -87.01749,
              38.20358
            ],
            [
              -87.017459,
              38.122001
            ],
            [
              -87.018026,
              38.118283
            ],
            [
              -87.072332,
              38.11828
            ],
            [
              -87.075161,
              38.096409
            ],
            [
              -87.089241,
              38.082755
            ],
            [
              -87.097718,
              38.056749
            ],
            [
              -87.088487,
              38.049682
            ],
            [
              -87.125021,
              38.043866
            ],
            [
              -87.132774,
              38.05454
            ],
            [
              -87.153495,
              38.053588
            ],
            [
              -87.152794,
              38.023577
            ],
            [
              -87.182282,
              38.010774
            ],
            [
              -87.196218,
              37.987454
            ],
            [
              -87.225701,
              37.971667
            ],
            [
              -87.232476,
              37.948181
            ],
            [
              -87.240918,
              37.952269
            ],
            [
              -87.268003,
              37.924766
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "127",
      "COUNTYNS": "00450382",
      "AFFGEOID": "0500000US18127",
      "GEOID": "18127",
      "NAME": "Porter",
      "LSAD": "06",
      "ALAND": 1082777926,
      "AWATER": 268688087,
      "County_state": "Porter,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.2226440045199,
              41.624160880659296
            ],
            [
              -87.221355,
              41.54718
            ],
            [
              -87.220633,
              41.521614
            ],
            [
              -87.219953,
              41.470702
            ],
            [
              -87.219625,
              41.434595
            ],
            [
              -87.219652,
              41.38483
            ],
            [
              -87.220266,
              41.333327
            ],
            [
              -87.218862,
              41.242027
            ],
            [
              -87.192361,
              41.265289
            ],
            [
              -87.131339,
              41.286245
            ],
            [
              -87.122124,
              41.286365
            ],
            [
              -87.059724,
              41.26952
            ],
            [
              -87.011028,
              41.240908
            ],
            [
              -86.968125,
              41.219658
            ],
            [
              -86.930079,
              41.236798
            ],
            [
              -86.930173,
              41.434275
            ],
            [
              -86.930169,
              41.436194
            ],
            [
              -86.929774,
              41.519019
            ],
            [
              -86.9327468138127,
              41.711040300429495
            ],
            [
              -86.93483,
              41.709638
            ],
            [
              -87.027888,
              41.674661
            ],
            [
              -87.066033,
              41.661845
            ],
            [
              -87.0674720713681,
              41.6614170612248
            ],
            [
              -87.120322,
              41.645701
            ],
            [
              -87.125835,
              41.650302
            ],
            [
              -87.160784,
              41.645385
            ],
            [
              -87.160625,
              41.637266
            ],
            [
              -87.187651,
              41.629653
            ],
            [
              -87.22066,
              41.624356
            ],
            [
              -87.2226440045199,
              41.624160880659296
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "017",
      "COUNTYNS": "00450339",
      "AFFGEOID": "0500000US18017",
      "GEOID": "18017",
      "NAME": "Cass",
      "LSAD": "06",
      "ALAND": 1067475985,
      "AWATER": 6974423,
      "County_state": "Cass,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.581532,
              40.910925
            ],
            [
              -86.581739,
              40.735765
            ],
            [
              -86.525143,
              40.735445
            ],
            [
              -86.525213,
              40.717196
            ],
            [
              -86.525164,
              40.692419
            ],
            [
              -86.467934,
              40.691955
            ],
            [
              -86.373521,
              40.69246
            ],
            [
              -86.374417,
              40.561361
            ],
            [
              -86.261248,
              40.561882
            ],
            [
              -86.165024,
              40.562649
            ],
            [
              -86.165866,
              40.649637
            ],
            [
              -86.165634,
              40.740792
            ],
            [
              -86.168858,
              40.743517
            ],
            [
              -86.169012,
              40.909836
            ],
            [
              -86.318987,
              40.909392
            ],
            [
              -86.355867,
              40.909453
            ],
            [
              -86.468509,
              40.909889
            ],
            [
              -86.581532,
              40.910925
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "041",
      "COUNTYNS": "00450349",
      "AFFGEOID": "0500000US18041",
      "GEOID": "18041",
      "NAME": "Fayette",
      "LSAD": "06",
      "ALAND": 556901556,
      "AWATER": 366408,
      "County_state": "Fayette,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.301584,
              39.787579
            ],
            [
              -85.29811,
              39.525476
            ],
            [
              -85.241769,
              39.525701
            ],
            [
              -85.129627,
              39.526655
            ],
            [
              -85.036087,
              39.526213
            ],
            [
              -85.034957,
              39.657069
            ],
            [
              -85.034575,
              39.714764
            ],
            [
              -85.120723,
              39.71503
            ],
            [
              -85.185089,
              39.715515
            ],
            [
              -85.183472,
              39.788397
            ],
            [
              -85.221118,
              39.788449
            ],
            [
              -85.301584,
              39.787579
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "171",
      "COUNTYNS": "00450400",
      "AFFGEOID": "0500000US18171",
      "GEOID": "18171",
      "NAME": "Warren",
      "LSAD": "06",
      "ALAND": 944520568,
      "AWATER": 4466121,
      "County_state": "Warren,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.531133,
              40.17003
            ],
            [
              -87.5314376605081,
              40.1481233164708
            ],
            [
              -87.490455,
              40.148422
            ],
            [
              -87.49041,
              40.127406
            ],
            [
              -87.406667,
              40.127508
            ],
            [
              -87.408526,
              40.142956
            ],
            [
              -87.438774,
              40.164458
            ],
            [
              -87.427998,
              40.178287
            ],
            [
              -87.4199,
              40.192258
            ],
            [
              -87.357583,
              40.210877
            ],
            [
              -87.339273,
              40.223173
            ],
            [
              -87.316316,
              40.246684
            ],
            [
              -87.295296,
              40.256324
            ],
            [
              -87.28195,
              40.277604
            ],
            [
              -87.249683,
              40.299146
            ],
            [
              -87.245787,
              40.308846
            ],
            [
              -87.170128,
              40.334831
            ],
            [
              -87.131525,
              40.340592
            ],
            [
              -87.092148,
              40.366612
            ],
            [
              -87.092812,
              40.471765
            ],
            [
              -87.093676,
              40.475745
            ],
            [
              -87.526510746795,
              40.4768790330699
            ],
            [
              -87.526549,
              40.475659
            ],
            [
              -87.526809,
              40.46217
            ],
            [
              -87.530054,
              40.250671
            ],
            [
              -87.529992,
              40.250036
            ],
            [
              -87.5308277456112,
              40.1918297171436
            ],
            [
              -87.530828,
              40.191812
            ],
            [
              -87.53103269801801,
              40.17719320580729
            ],
            [
              -87.531133,
              40.17003
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "021",
      "COUNTYNS": "00450341",
      "AFFGEOID": "0500000US18021",
      "GEOID": "18021",
      "NAME": "Clay",
      "LSAD": "06",
      "ALAND": 926189694,
      "AWATER": 7038300,
      "County_state": "Clay,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.240379,
              39.259064
            ],
            [
              -87.240737,
              39.171773
            ],
            [
              -87.128932,
              39.168989
            ],
            [
              -87.068341,
              39.167965
            ],
            [
              -87.054578,
              39.168086
            ],
            [
              -87.053646,
              39.342621
            ],
            [
              -86.942473,
              39.342043
            ],
            [
              -86.942293,
              39.399912
            ],
            [
              -86.942409,
              39.429356
            ],
            [
              -86.941666,
              39.432506
            ],
            [
              -86.939981,
              39.473345
            ],
            [
              -87.014533,
              39.473572
            ],
            [
              -87.013062,
              39.604787
            ],
            [
              -87.124237,
              39.605762
            ],
            [
              -87.199012,
              39.607137
            ],
            [
              -87.200107,
              39.519858
            ],
            [
              -87.238964,
              39.52062
            ],
            [
              -87.23909,
              39.500281
            ],
            [
              -87.239556,
              39.433561
            ],
            [
              -87.240379,
              39.259064
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "175",
      "COUNTYNS": "00451665",
      "AFFGEOID": "0500000US18175",
      "GEOID": "18175",
      "NAME": "Washington",
      "LSAD": "06",
      "ALAND": 1330545619,
      "AWATER": 7438838,
      "County_state": "Washington,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.310058,
              38.733131
            ],
            [
              -86.308701,
              38.688101
            ],
            [
              -86.308614,
              38.484824
            ],
            [
              -86.308674,
              38.422875
            ],
            [
              -86.255045,
              38.422685
            ],
            [
              -86.141731,
              38.419917
            ],
            [
              -86.032817,
              38.417898
            ],
            [
              -86.014139,
              38.419079
            ],
            [
              -85.99462,
              38.418345
            ],
            [
              -85.993317,
              38.48854
            ],
            [
              -85.956195,
              38.488956
            ],
            [
              -85.957102,
              38.501949
            ],
            [
              -85.884776,
              38.503469
            ],
            [
              -85.884708,
              38.518152
            ],
            [
              -85.866268,
              38.518425
            ],
            [
              -85.866341,
              38.547095
            ],
            [
              -85.847588,
              38.54714
            ],
            [
              -85.847893,
              38.561269
            ],
            [
              -85.848575,
              38.576266
            ],
            [
              -85.885643,
              38.575412
            ],
            [
              -85.888334,
              38.734443
            ],
            [
              -85.89078,
              38.729722
            ],
            [
              -85.908449,
              38.756151
            ],
            [
              -85.915611,
              38.745958
            ],
            [
              -85.93651,
              38.755834
            ],
            [
              -85.948377,
              38.753636
            ],
            [
              -85.960862,
              38.764185
            ],
            [
              -85.976641,
              38.752393
            ],
            [
              -85.984252,
              38.768115
            ],
            [
              -86.025736,
              38.756009
            ],
            [
              -86.05508,
              38.773133
            ],
            [
              -86.075066,
              38.774727
            ],
            [
              -86.092538,
              38.783462
            ],
            [
              -86.102356,
              38.765755
            ],
            [
              -86.134944,
              38.762425
            ],
            [
              -86.184102,
              38.783588
            ],
            [
              -86.197651,
              38.773769
            ],
            [
              -86.224068,
              38.779147
            ],
            [
              -86.237144,
              38.767797
            ],
            [
              -86.254103,
              38.770118
            ],
            [
              -86.275281,
              38.763795
            ],
            [
              -86.299722,
              38.729706
            ],
            [
              -86.310058,
              38.733131
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "029",
      "COUNTYNS": "00450344",
      "AFFGEOID": "0500000US18029",
      "GEOID": "18029",
      "NAME": "Dearborn",
      "LSAD": "06",
      "ALAND": 790138587,
      "AWATER": 6033369,
      "County_state": "Dearborn,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.132508,
              38.948055
            ],
            [
              -85.115466,
              38.956555
            ],
            [
              -85.117561,
              38.936511
            ],
            [
              -85.097021,
              38.935351
            ],
            [
              -85.082642,
              38.952241
            ],
            [
              -85.058556,
              38.94897
            ],
            [
              -85.058203,
              38.958099
            ],
            [
              -85.040145,
              38.975921
            ],
            [
              -85.024668,
              38.974498
            ],
            [
              -85.016925,
              38.982343
            ],
            [
              -85.003564,
              38.975221
            ],
            [
              -84.998111,
              38.99654
            ],
            [
              -84.969707,
              38.992408
            ],
            [
              -84.953866,
              38.994892
            ],
            [
              -84.94606,
              39.00627
            ],
            [
              -84.933465,
              39.006226
            ],
            [
              -84.912109,
              39.01893
            ],
            [
              -84.898759,
              39.013241
            ],
            [
              -84.87805047759561,
              39.0308192384939
            ],
            [
              -84.882856,
              39.034031
            ],
            [
              -84.889065,
              39.04082
            ],
            [
              -84.894281,
              39.049572
            ],
            [
              -84.897171,
              39.052407
            ],
            [
              -84.897364,
              39.057378
            ],
            [
              -84.893873,
              39.062466
            ],
            [
              -84.888873,
              39.066376
            ],
            [
              -84.87095572870109,
              39.073854668022996
            ],
            [
              -84.860689,
              39.07814
            ],
            [
              -84.849574,
              39.088264
            ],
            [
              -84.839515,
              39.095292
            ],
            [
              -84.831197,
              39.10192
            ],
            [
              -84.826246,
              39.10417
            ],
            [
              -84.820157,
              39.10548
            ],
            [
              -84.819985,
              39.149081
            ],
            [
              -84.819802,
              39.157613
            ],
            [
              -84.8201153322843,
              39.2187101624055
            ],
            [
              -84.820159,
              39.227225
            ],
            [
              -84.819813,
              39.244334
            ],
            [
              -84.819801,
              39.247806
            ],
            [
              -84.819859,
              39.251018
            ],
            [
              -84.819633,
              39.261855
            ],
            [
              -84.819622,
              39.27159
            ],
            [
              -84.819451,
              39.305152
            ],
            [
              -84.81945096866738,
              39.305153361544896
            ],
            [
              -85.065574,
              39.307232
            ],
            [
              -85.081872,
              39.222266
            ],
            [
              -85.08486,
              39.206263
            ],
            [
              -85.113111,
              39.050589
            ],
            [
              -85.132508,
              38.948055
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "027",
      "COUNTYNS": "00450343",
      "AFFGEOID": "0500000US18027",
      "GEOID": "18027",
      "NAME": "Daviess",
      "LSAD": "06",
      "ALAND": 1112375531,
      "AWATER": 19123262,
      "County_state": "Daviess,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.278553,
              38.691343
            ],
            [
              -87.26289,
              38.688057
            ],
            [
              -87.272603,
              38.67982
            ],
            [
              -87.255698,
              38.655223
            ],
            [
              -87.238671,
              38.658879
            ],
            [
              -87.249725,
              38.652996
            ],
            [
              -87.240629,
              38.632128
            ],
            [
              -87.252958,
              38.631454
            ],
            [
              -87.238824,
              38.610548
            ],
            [
              -87.250498,
              38.615065
            ],
            [
              -87.247587,
              38.569466
            ],
            [
              -87.238074,
              38.560647
            ],
            [
              -87.242097,
              38.54493
            ],
            [
              -87.220945,
              38.538829
            ],
            [
              -87.20347,
              38.552238
            ],
            [
              -87.175497,
              38.543954
            ],
            [
              -87.149961,
              38.515615
            ],
            [
              -87.10636,
              38.537234
            ],
            [
              -87.086704,
              38.512846
            ],
            [
              -87.072369,
              38.5147
            ],
            [
              -87.063815,
              38.501657
            ],
            [
              -87.045073,
              38.510865
            ],
            [
              -87.025385,
              38.507394
            ],
            [
              -86.993951,
              38.513037
            ],
            [
              -86.974763,
              38.501953
            ],
            [
              -86.956217,
              38.503439
            ],
            [
              -86.942513,
              38.492593
            ],
            [
              -86.936589,
              38.504262
            ],
            [
              -86.924186,
              38.505358
            ],
            [
              -86.923689,
              38.686427
            ],
            [
              -86.922943,
              38.817238
            ],
            [
              -86.904227,
              38.817511
            ],
            [
              -86.904193,
              38.849631
            ],
            [
              -86.904252,
              38.904204
            ],
            [
              -87.03542,
              38.90246
            ],
            [
              -87.098771,
              38.903974
            ],
            [
              -87.090694,
              38.900289
            ],
            [
              -87.11711,
              38.898983
            ],
            [
              -87.13058,
              38.885535
            ],
            [
              -87.119961,
              38.873276
            ],
            [
              -87.143709,
              38.867361
            ],
            [
              -87.141861,
              38.847958
            ],
            [
              -87.14932,
              38.83838
            ],
            [
              -87.170381,
              38.846936
            ],
            [
              -87.173359,
              38.829404
            ],
            [
              -87.186849,
              38.8338
            ],
            [
              -87.213267,
              38.807977
            ],
            [
              -87.239915,
              38.819264
            ],
            [
              -87.2466,
              38.807701
            ],
            [
              -87.239778,
              38.786318
            ],
            [
              -87.253185,
              38.779596
            ],
            [
              -87.257558,
              38.767746
            ],
            [
              -87.243153,
              38.768839
            ],
            [
              -87.243872,
              38.750047
            ],
            [
              -87.254616,
              38.732123
            ],
            [
              -87.247003,
              38.728892
            ],
            [
              -87.26051,
              38.734038
            ],
            [
              -87.262455,
              38.7289
            ],
            [
              -87.257043,
              38.718863
            ],
            [
              -87.278553,
              38.691343
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "135",
      "COUNTYNS": "00446853",
      "AFFGEOID": "0500000US18135",
      "GEOID": "18135",
      "NAME": "Randolph",
      "LSAD": "06",
      "ALAND": 1171656639,
      "AWATER": 2420286,
      "County_state": "Randolph,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.218758,
              40.306706
            ],
            [
              -85.217428,
              40.234931
            ],
            [
              -85.214386,
              40.076889
            ],
            [
              -85.213496,
              40.00438
            ],
            [
              -85.201473,
              40.004521
            ],
            [
              -85.145875,
              40.004694
            ],
            [
              -85.126777,
              40.004205
            ],
            [
              -84.8109329724325,
              40.005078914562695
            ],
            [
              -84.810099,
              40.034214
            ],
            [
              -84.809737,
              40.048929
            ],
            [
              -84.80896373948549,
              40.092644844432
            ],
            [
              -84.808706,
              40.107216
            ],
            [
              -84.808305,
              40.127018
            ],
            [
              -84.808291,
              40.129027
            ],
            [
              -84.806766,
              40.180128
            ],
            [
              -84.806347,
              40.192252
            ],
            [
              -84.80634,
              40.192327
            ],
            [
              -84.806175,
              40.197995
            ],
            [
              -84.805627,
              40.223659
            ],
            [
              -84.804098,
              40.302498
            ],
            [
              -84.8039175091217,
              40.310093574693596
            ],
            [
              -85.218758,
              40.306706
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "073",
      "COUNTYNS": "00450494",
      "AFFGEOID": "0500000US18073",
      "GEOID": "18073",
      "NAME": "Jasper",
      "LSAD": "06",
      "ALAND": 1449422928,
      "AWATER": 4569484,
      "County_state": "Jasper,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.275882,
              41.218595
            ],
            [
              -87.276449,
              40.911477
            ],
            [
              -87.26905,
              40.911517
            ],
            [
              -87.268443,
              40.89479
            ],
            [
              -87.267298,
              40.736582
            ],
            [
              -87.09839,
              40.736541
            ],
            [
              -87.099793,
              40.837607
            ],
            [
              -86.986438,
              40.837921
            ],
            [
              -86.987348,
              40.896213
            ],
            [
              -86.987438,
              40.912177
            ],
            [
              -86.930759,
              40.912418
            ],
            [
              -86.930935,
              40.977276
            ],
            [
              -86.93017,
              41.172318
            ],
            [
              -86.930079,
              41.236798
            ],
            [
              -86.968125,
              41.219658
            ],
            [
              -87.011028,
              41.240908
            ],
            [
              -87.059724,
              41.26952
            ],
            [
              -87.122124,
              41.286365
            ],
            [
              -87.131339,
              41.286245
            ],
            [
              -87.192361,
              41.265289
            ],
            [
              -87.218862,
              41.242027
            ],
            [
              -87.226372,
              41.235507
            ],
            [
              -87.275882,
              41.218595
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "005",
      "COUNTYNS": "00451683",
      "AFFGEOID": "0500000US18005",
      "GEOID": "18005",
      "NAME": "Bartholomew",
      "LSAD": "06",
      "ALAND": 1053869826,
      "AWATER": 6814131,
      "County_state": "Bartholomew,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.085601,
              39.344189
            ],
            [
              -86.081824,
              39.226288
            ],
            [
              -86.07903,
              39.079641
            ],
            [
              -86.080272,
              39.05027
            ],
            [
              -86.079844,
              39.035903
            ],
            [
              -86.061733,
              39.036
            ],
            [
              -85.860498,
              39.040921
            ],
            [
              -85.862447,
              39.048919
            ],
            [
              -85.847245,
              39.056512
            ],
            [
              -85.832961,
              39.063663
            ],
            [
              -85.822851,
              39.069711
            ],
            [
              -85.798804,
              39.068536
            ],
            [
              -85.799834,
              39.128897
            ],
            [
              -85.774355,
              39.129146
            ],
            [
              -85.686783,
              39.130859
            ],
            [
              -85.686659,
              39.261416
            ],
            [
              -85.684515,
              39.350049
            ],
            [
              -85.798785,
              39.350188
            ],
            [
              -85.85746,
              39.349312
            ],
            [
              -85.952005,
              39.347373
            ],
            [
              -86.027719,
              39.345609
            ],
            [
              -86.085601,
              39.344189
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "091",
      "COUNTYNS": "00450507",
      "AFFGEOID": "0500000US18091",
      "GEOID": "18091",
      "NAME": "LaPorte",
      "LSAD": "06",
      "ALAND": 1549582915,
      "AWATER": 38751745,
      "County_state": "LaPorte,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.9327468138127,
              41.711040300429495
            ],
            [
              -86.929774,
              41.519019
            ],
            [
              -86.930169,
              41.436194
            ],
            [
              -86.930173,
              41.434275
            ],
            [
              -86.930079,
              41.236798
            ],
            [
              -86.920543,
              41.245028
            ],
            [
              -86.870265,
              41.264553
            ],
            [
              -86.814652,
              41.273501
            ],
            [
              -86.783473,
              41.285465
            ],
            [
              -86.761495,
              41.307486
            ],
            [
              -86.739995,
              41.321708
            ],
            [
              -86.723778,
              41.360357
            ],
            [
              -86.707722,
              41.380987
            ],
            [
              -86.70063,
              41.401982
            ],
            [
              -86.670798,
              41.413873
            ],
            [
              -86.642542,
              41.432639
            ],
            [
              -86.524711,
              41.432886
            ],
            [
              -86.52494,
              41.519675
            ],
            [
              -86.503612,
              41.519534
            ],
            [
              -86.49814,
              41.531409
            ],
            [
              -86.498158,
              41.561083
            ],
            [
              -86.486433,
              41.570545
            ],
            [
              -86.487567,
              41.649325
            ],
            [
              -86.526682,
              41.65044
            ],
            [
              -86.526006,
              41.67245
            ],
            [
              -86.525408,
              41.694581
            ],
            [
              -86.524222859982,
              41.7594561006795
            ],
            [
              -86.640044,
              41.759671
            ],
            [
              -86.641186,
              41.759633
            ],
            [
              -86.6413638204539,
              41.7596335891616
            ],
            [
              -86.746521,
              41.759982
            ],
            [
              -86.748096,
              41.759967
            ],
            [
              -86.750999806915,
              41.7599827037259
            ],
            [
              -86.800611,
              41.760251
            ],
            [
              -86.800707,
              41.76024
            ],
            [
              -86.801578,
              41.76024
            ],
            [
              -86.804427,
              41.76024
            ],
            [
              -86.81607,
              41.76024
            ],
            [
              -86.823628,
              41.76024
            ],
            [
              -86.824828,
              41.76024
            ],
            [
              -86.875429,
              41.737939
            ],
            [
              -86.90913,
              41.726938
            ],
            [
              -86.9327468138127,
              41.711040300429495
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "105",
      "COUNTYNS": "00451680",
      "AFFGEOID": "0500000US18105",
      "GEOID": "18105",
      "NAME": "Monroe",
      "LSAD": "06",
      "ALAND": 1021814153,
      "AWATER": 43504254,
      "County_state": "Monroe,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.68561,
              39.336043
            ],
            [
              -86.683002,
              39.165746
            ],
            [
              -86.682401,
              39.079846
            ],
            [
              -86.682277,
              38.992404
            ],
            [
              -86.573548,
              38.992147
            ],
            [
              -86.317474,
              38.99087
            ],
            [
              -86.318133,
              39.04881
            ],
            [
              -86.370066,
              39.048375
            ],
            [
              -86.369168,
              39.076826
            ],
            [
              -86.372408,
              39.250905
            ],
            [
              -86.379937,
              39.25108
            ],
            [
              -86.381395,
              39.339752
            ],
            [
              -86.464609,
              39.338295
            ],
            [
              -86.57428,
              39.339681
            ],
            [
              -86.630844,
              39.340755
            ],
            [
              -86.630818,
              39.346948
            ],
            [
              -86.651551,
              39.355593
            ],
            [
              -86.658911,
              39.347252
            ],
            [
              -86.657628,
              39.33011
            ],
            [
              -86.68561,
              39.336043
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "015",
      "COUNTYNS": "00450406",
      "AFFGEOID": "0500000US18015",
      "GEOID": "18015",
      "NAME": "Carroll",
      "LSAD": "06",
      "ALAND": 964054634,
      "AWATER": 7239098,
      "County_state": "Carroll,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.77423,
              40.664026
            ],
            [
              -86.771293,
              40.562082
            ],
            [
              -86.695353,
              40.561874
            ],
            [
              -86.694665,
              40.432156
            ],
            [
              -86.580932,
              40.431494
            ],
            [
              -86.563464,
              40.431443
            ],
            [
              -86.375762,
              40.431851
            ],
            [
              -86.375151,
              40.517839
            ],
            [
              -86.374417,
              40.561361
            ],
            [
              -86.373521,
              40.69246
            ],
            [
              -86.467934,
              40.691955
            ],
            [
              -86.525164,
              40.692419
            ],
            [
              -86.525213,
              40.717196
            ],
            [
              -86.525143,
              40.735445
            ],
            [
              -86.581739,
              40.735765
            ],
            [
              -86.65878,
              40.7366
            ],
            [
              -86.740484,
              40.737589
            ],
            [
              -86.750011,
              40.736027
            ],
            [
              -86.761373,
              40.697925
            ],
            [
              -86.748705,
              40.688404
            ],
            [
              -86.762228,
              40.67989
            ],
            [
              -86.742589,
              40.681236
            ],
            [
              -86.758301,
              40.664282
            ],
            [
              -86.77423,
              40.664026
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "18",
      "COUNTYFP": "161",
      "COUNTYNS": "00450395",
      "AFFGEOID": "0500000US18161",
      "GEOID": "18161",
      "NAME": "Union",
      "LSAD": "06",
      "ALAND": 417568563,
      "AWATER": 10238515,
      "County_state": "Union,18"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.034957,
              39.657069
            ],
            [
              -85.036087,
              39.526213
            ],
            [
              -85.022818,
              39.524382
            ],
            [
              -84.81535501751969,
              39.521950045262
            ],
            [
              -84.815355,
              39.521951
            ],
            [
              -84.815155,
              39.548051
            ],
            [
              -84.814955,
              39.566251
            ],
            [
              -84.814955,
              39.567251
            ],
            [
              -84.8150361906572,
              39.5676953269799
            ],
            [
              -84.815156,
              39.568351
            ],
            [
              -84.814705,
              39.628854
            ],
            [
              -84.814323,
              39.655814
            ],
            [
              -84.814619,
              39.669174
            ],
            [
              -84.81453,
              39.680429
            ],
            [
              -84.814129,
              39.726556
            ],
            [
              -84.81412906532259,
              39.7266202480145
            ],
            [
              -84.969515,
              39.726802
            ],
            [
              -85.034408,
              39.729263
            ],
            [
              -85.034575,
              39.714764
            ],
            [
              -85.034957,
              39.657069
            ]
          ]
        ]
      ]
    }
  }
]
};