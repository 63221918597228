export const counties_mi = {
  "type":"FeatureCollection","name":"mi_counties","features":[
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "087",
      "COUNTYNS": "01622986",
      "AFFGEOID": "0500000US26087",
      "GEOID": "26087",
      "NAME": "Lapeer",
      "LSAD": "06",
      "ALAND": 1668448345,
      "AWATER": 48310232,
      "County_state": "Lapeer,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.460708,
              43.232791
            ],
            [
              -83.460733,
              43.223131
            ],
            [
              -83.459081,
              43.050014
            ],
            [
              -83.456781,
              42.963137
            ],
            [
              -83.453364,
              42.880432
            ],
            [
              -83.336678,
              42.882788
            ],
            [
              -83.102891,
              42.888647
            ],
            [
              -82.983647,
              42.893741
            ],
            [
              -82.996257,
              43.154099
            ],
            [
              -83.00043,
              43.284913
            ],
            [
              -83.119027,
              43.28368
            ],
            [
              -83.120396,
              43.327049
            ],
            [
              -83.354418,
              43.322257
            ],
            [
              -83.351709,
              43.235193
            ],
            [
              -83.460708,
              43.232791
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "123",
      "COUNTYNS": "01623004",
      "AFFGEOID": "0500000US26123",
      "GEOID": "26123",
      "NAME": "Newaygo",
      "LSAD": "06",
      "ALAND": 2112606166,
      "AWATER": 119183856,
      "County_state": "Newaygo,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.039491,
              43.467447
            ],
            [
              -86.038953,
              43.293519
            ],
            [
              -85.790448,
              43.293003
            ],
            [
              -85.562538,
              43.294271
            ],
            [
              -85.562348,
              43.46813
            ],
            [
              -85.562433,
              43.815483
            ],
            [
              -86.037884,
              43.815611
            ],
            [
              -86.039491,
              43.467447
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "073",
      "COUNTYNS": "01622979",
      "AFFGEOID": "0500000US26073",
      "GEOID": "26073",
      "NAME": "Isabella",
      "LSAD": "06",
      "ALAND": 1483229495,
      "AWATER": 12959299,
      "County_state": "Isabella,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.088811,
              43.813676
            ],
            [
              -85.084996,
              43.46619
            ],
            [
              -84.845962,
              43.466158
            ],
            [
              -84.60754,
              43.466006
            ],
            [
              -84.606035,
              43.815218
            ],
            [
              -85.088811,
              43.813676
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "151",
      "COUNTYNS": "01623016",
      "AFFGEOID": "0500000US26151",
      "GEOID": "26151",
      "NAME": "Sanilac",
      "LSAD": "06",
      "ALAND": 2493045473,
      "AWATER": 1624882510,
      "County_state": "Sanilac,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.120396,
              43.327049
            ],
            [
              -83.119027,
              43.28368
            ],
            [
              -83.00043,
              43.284913
            ],
            [
              -82.996257,
              43.154099
            ],
            [
              -82.544925,
              43.16751
            ],
            [
              -82.50315682967,
              43.1689455660594
            ],
            [
              -82.508881,
              43.196748
            ],
            [
              -82.519123,
              43.212737
            ],
            [
              -82.523086,
              43.225361
            ],
            [
              -82.532396,
              43.30577
            ],
            [
              -82.529416,
              43.316243
            ],
            [
              -82.530128,
              43.333805
            ],
            [
              -82.536794,
              43.34851
            ],
            [
              -82.535627,
              43.368062
            ],
            [
              -82.53993,
              43.422378
            ],
            [
              -82.538578,
              43.431594
            ],
            [
              -82.539517,
              43.437539
            ],
            [
              -82.55354,
              43.464111
            ],
            [
              -82.565505,
              43.497063
            ],
            [
              -82.565691,
              43.502904
            ],
            [
              -82.585654,
              43.543969
            ],
            [
              -82.593785,
              43.581467
            ],
            [
              -82.597911,
              43.590016
            ],
            [
              -82.6005,
              43.602935
            ],
            [
              -82.605783,
              43.669489
            ],
            [
              -82.60483,
              43.678884
            ],
            [
              -82.6062330164376,
              43.69043734890919
            ],
            [
              -83.117844,
              43.675493
            ],
            [
              -83.103826,
              43.327365
            ],
            [
              -83.120396,
              43.327049
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "101",
      "COUNTYNS": "01622993",
      "AFFGEOID": "0500000US26101",
      "GEOID": "26101",
      "NAME": "Manistee",
      "LSAD": "06",
      "ALAND": 1404616462,
      "AWATER": 1912440724,
      "County_state": "Manistee,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.380062,
              44.189472
            ],
            [
              -86.38686221380789,
              44.178693679285296
            ],
            [
              -86.043507,
              44.167088
            ],
            [
              -85.821186,
              44.164197
            ],
            [
              -85.817802,
              44.512969
            ],
            [
              -86.2335026627983,
              44.5182776275632
            ],
            [
              -86.238743,
              44.501682
            ],
            [
              -86.243745,
              44.488929
            ],
            [
              -86.248914,
              44.483004
            ],
            [
              -86.251605,
              44.465443
            ],
            [
              -86.251843,
              44.451632
            ],
            [
              -86.24832,
              44.434758
            ],
            [
              -86.248083,
              44.420946
            ],
            [
              -86.251926,
              44.400984
            ],
            [
              -86.26871,
              44.345324
            ],
            [
              -86.300264,
              44.308197
            ],
            [
              -86.316025,
              44.28421
            ],
            [
              -86.327287,
              44.263057
            ],
            [
              -86.343793,
              44.249608
            ],
            [
              -86.351638,
              44.229429
            ],
            [
              -86.362847,
              44.208113
            ],
            [
              -86.380062,
              44.189472
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "129",
      "COUNTYNS": "01623007",
      "AFFGEOID": "0500000US26129",
      "GEOID": "26129",
      "NAME": "Ogemaw",
      "LSAD": "06",
      "ALAND": 1459425379,
      "AWATER": 29682543,
      "County_state": "Ogemaw,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.370643,
              44.507223
            ],
            [
              -84.367995,
              44.20381
            ],
            [
              -84.366324,
              44.175508
            ],
            [
              -84.368039,
              44.160538
            ],
            [
              -84.166107,
              44.161788
            ],
            [
              -83.883977,
              44.161786
            ],
            [
              -83.886634,
              44.508976
            ],
            [
              -84.370643,
              44.507223
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "035",
      "COUNTYNS": "01622960",
      "AFFGEOID": "0500000US26035",
      "GEOID": "26035",
      "NAME": "Clare",
      "LSAD": "06",
      "ALAND": 1461741364,
      "AWATER": 28501274,
      "County_state": "Clare,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.087403,
              44.164242
            ],
            [
              -85.088811,
              43.813676
            ],
            [
              -84.606035,
              43.815218
            ],
            [
              -84.608104,
              44.160482
            ],
            [
              -84.851705,
              44.161375
            ],
            [
              -85.087403,
              44.164242
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "039",
      "COUNTYNS": "01622962",
      "AFFGEOID": "0500000US26039",
      "GEOID": "26039",
      "NAME": "Crawford",
      "LSAD": "06",
      "ALAND": 1440969294,
      "AWATER": 18026370,
      "County_state": "Crawford,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.847309,
              44.858037
            ],
            [
              -84.850929,
              44.511069
            ],
            [
              -84.471035,
              44.510733
            ],
            [
              -84.370643,
              44.507223
            ],
            [
              -84.371737,
              44.855039
            ],
            [
              -84.847309,
              44.858037
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "119",
      "COUNTYNS": "01623002",
      "AFFGEOID": "0500000US26119",
      "GEOID": "26119",
      "NAME": "Montmorency",
      "LSAD": "06",
      "ALAND": 1415822785,
      "AWATER": 41410946,
      "County_state": "Montmorency,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.366614,
              45.198737
            ],
            [
              -84.371737,
              44.855039
            ],
            [
              -84.213026,
              44.854797
            ],
            [
              -83.888482,
              44.856635
            ],
            [
              -83.890439,
              45.03047
            ],
            [
              -83.880234,
              45.030611
            ],
            [
              -83.882911,
              45.159505
            ],
            [
              -83.881223,
              45.202071
            ],
            [
              -84.022072,
              45.198424
            ],
            [
              -84.246634,
              45.198727
            ],
            [
              -84.366614,
              45.198737
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "051",
      "COUNTYNS": "01622968",
      "AFFGEOID": "0500000US26051",
      "GEOID": "26051",
      "NAME": "Gladwin",
      "LSAD": "06",
      "ALAND": 1299662664,
      "AWATER": 36635214,
      "County_state": "Gladwin,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.608104,
              44.160482
            ],
            [
              -84.606035,
              43.815218
            ],
            [
              -84.366676,
              43.81356
            ],
            [
              -84.366511,
              43.828434
            ],
            [
              -84.167318,
              43.825902
            ],
            [
              -84.166377,
              43.996901
            ],
            [
              -84.166107,
              44.161788
            ],
            [
              -84.368039,
              44.160538
            ],
            [
              -84.608104,
              44.160482
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "135",
      "COUNTYNS": "01623010",
      "AFFGEOID": "0500000US26135",
      "GEOID": "26135",
      "NAME": "Oscoda",
      "LSAD": "06",
      "ALAND": 1465088065,
      "AWATER": 15136485,
      "County_state": "Oscoda,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.371737,
              44.855039
            ],
            [
              -84.370643,
              44.507223
            ],
            [
              -83.886634,
              44.508976
            ],
            [
              -83.888484,
              44.856458
            ],
            [
              -83.888482,
              44.856635
            ],
            [
              -84.213026,
              44.854797
            ],
            [
              -84.371737,
              44.855039
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "037",
      "COUNTYNS": "01622961",
      "AFFGEOID": "0500000US26037",
      "GEOID": "26037",
      "NAME": "Clinton",
      "LSAD": "06",
      "ALAND": 1467009545,
      "AWATER": 21106020,
      "County_state": "Clinton,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.836889,
              43.118851
            ],
            [
              -84.837085,
              42.770479
            ],
            [
              -84.602761,
              42.76988
            ],
            [
              -84.512355,
              42.769663
            ],
            [
              -84.36366,
              42.769971
            ],
            [
              -84.363659,
              42.775778
            ],
            [
              -84.36776,
              43.117942
            ],
            [
              -84.836889,
              43.118851
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "023",
      "COUNTYNS": "01622954",
      "AFFGEOID": "0500000US26023",
      "GEOID": "26023",
      "NAME": "Branch",
      "LSAD": "06",
      "ALAND": 1311600891,
      "AWATER": 34424716,
      "County_state": "Branch,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.293626,
              42.071553
            ],
            [
              -85.2921778813506,
              41.759962830860104
            ],
            [
              -85.292099,
              41.759962
            ],
            [
              -85.273713,
              41.75977
            ],
            [
              -85.272951,
              41.759911
            ],
            [
              -85.272216,
              41.759999
            ],
            [
              -85.232835,
              41.759839
            ],
            [
              -85.19677373742039,
              41.7597353928585
            ],
            [
              -85.196637,
              41.759735
            ],
            [
              -85.17223,
              41.759618
            ],
            [
              -85.123102,
              41.759743
            ],
            [
              -85.117267,
              41.7597
            ],
            [
              -85.039436,
              41.759985
            ],
            [
              -85.037817,
              41.759801
            ],
            [
              -84.972803,
              41.759366
            ],
            [
              -84.971551,
              41.759527
            ],
            [
              -84.961562,
              41.759552
            ],
            [
              -84.96086,
              41.759438
            ],
            [
              -84.932484,
              41.759691
            ],
            [
              -84.825196,
              41.75999
            ],
            [
              -84.82512990965739,
              41.7599907212136
            ],
            [
              -84.826491,
              42.072468
            ],
            [
              -85.293626,
              42.071553
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "057",
      "COUNTYNS": "01622971",
      "AFFGEOID": "0500000US26057",
      "GEOID": "26057",
      "NAME": "Gratiot",
      "LSAD": "06",
      "ALAND": 1472084943,
      "AWATER": 8383160,
      "County_state": "Gratiot,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.845962,
              43.466158
            ],
            [
              -84.845005,
              43.379177
            ],
            [
              -84.845573,
              43.292231
            ],
            [
              -84.835305,
              43.2922
            ],
            [
              -84.836889,
              43.118851
            ],
            [
              -84.36776,
              43.117942
            ],
            [
              -84.367891,
              43.128452
            ],
            [
              -84.369876,
              43.466044
            ],
            [
              -84.488684,
              43.466168
            ],
            [
              -84.60754,
              43.466006
            ],
            [
              -84.845962,
              43.466158
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "067",
      "COUNTYNS": "01622976",
      "AFFGEOID": "0500000US26067",
      "GEOID": "26067",
      "NAME": "Ionia",
      "LSAD": "06",
      "ALAND": 1479659350,
      "AWATER": 22641874,
      "County_state": "Ionia,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.312513,
              43.118793
            ],
            [
              -85.312393,
              43.02973
            ],
            [
              -85.311933,
              42.943988
            ],
            [
              -85.309626,
              42.769878
            ],
            [
              -85.074245,
              42.770784
            ],
            [
              -84.837085,
              42.770479
            ],
            [
              -84.836889,
              43.118851
            ],
            [
              -85.075033,
              43.120213
            ],
            [
              -85.1936,
              43.119534
            ],
            [
              -85.312513,
              43.118793
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "077",
      "COUNTYNS": "01622981",
      "AFFGEOID": "0500000US26077",
      "GEOID": "26077",
      "NAME": "Kalamazoo",
      "LSAD": "06",
      "ALAND": 1456083085,
      "AWATER": 46882315,
      "County_state": "Kalamazoo,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.764697,
              42.420715
            ],
            [
              -85.765506,
              42.331714
            ],
            [
              -85.762943,
              42.069327
            ],
            [
              -85.529149,
              42.070703
            ],
            [
              -85.293626,
              42.071553
            ],
            [
              -85.296996,
              42.246071
            ],
            [
              -85.299357,
              42.412264
            ],
            [
              -85.298879,
              42.419849
            ],
            [
              -85.532498,
              42.421522
            ],
            [
              -85.543191,
              42.421433
            ],
            [
              -85.764697,
              42.420715
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "085",
      "COUNTYNS": "01622985",
      "AFFGEOID": "0500000US26085",
      "GEOID": "26085",
      "NAME": "Lake",
      "LSAD": "06",
      "ALAND": 1470022082,
      "AWATER": 17522231,
      "County_state": "Lake,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.043507,
              44.167088
            ],
            [
              -86.039284,
              44.047345
            ],
            [
              -86.040106,
              43.989671
            ],
            [
              -86.037884,
              43.815611
            ],
            [
              -85.562433,
              43.815483
            ],
            [
              -85.564554,
              44.164861
            ],
            [
              -85.821186,
              44.164197
            ],
            [
              -86.043507,
              44.167088
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "105",
      "COUNTYNS": "01622995",
      "AFFGEOID": "0500000US26105",
      "GEOID": "26105",
      "NAME": "Mason",
      "LSAD": "06",
      "ALAND": 1281901694,
      "AWATER": 1935678152,
      "County_state": "Mason,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.514702,
              44.058119
            ],
            [
              -86.514742,
              44.04792
            ],
            [
              -86.508827,
              44.032755
            ],
            [
              -86.501738,
              44.021912
            ],
            [
              -86.483331,
              44.001179
            ],
            [
              -86.4816058073594,
              43.9985988567307
            ],
            [
              -86.47736567399139,
              43.9922574480596
            ],
            [
              -86.4632197981358,
              43.971101325828
            ],
            [
              -86.463136,
              43.970976
            ],
            [
              -86.447915,
              43.918089
            ],
            [
              -86.445455,
              43.889726
            ],
            [
              -86.433915,
              43.855608
            ],
            [
              -86.431198,
              43.84072
            ],
            [
              -86.4310644927959,
              43.819406220869304
            ],
            [
              -86.037884,
              43.815611
            ],
            [
              -86.040106,
              43.989671
            ],
            [
              -86.039284,
              44.047345
            ],
            [
              -86.043507,
              44.167088
            ],
            [
              -86.38686221380789,
              44.178693679285296
            ],
            [
              -86.3978744854132,
              44.161239258217
            ],
            [
              -86.400645,
              44.156848
            ],
            [
              -86.421108,
              44.12948
            ],
            [
              -86.42157619282001,
              44.1289618516525
            ],
            [
              -86.429871,
              44.119782
            ],
            [
              -86.446883,
              44.10597
            ],
            [
              -86.500453,
              44.075607
            ],
            [
              -86.508764,
              44.067881
            ],
            [
              -86.514702,
              44.058119
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "107",
      "COUNTYNS": "01622996",
      "AFFGEOID": "0500000US26107",
      "GEOID": "26107",
      "NAME": "Mecosta",
      "LSAD": "06",
      "ALAND": 1437745751,
      "AWATER": 41762966,
      "County_state": "Mecosta,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.562433,
              43.815483
            ],
            [
              -85.562348,
              43.46813
            ],
            [
              -85.084996,
              43.46619
            ],
            [
              -85.088811,
              43.813676
            ],
            [
              -85.443132,
              43.81481
            ],
            [
              -85.562433,
              43.815483
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "121",
      "COUNTYNS": "01623003",
      "AFFGEOID": "0500000US26121",
      "GEOID": "26121",
      "NAME": "Muskegon",
      "LSAD": "06",
      "ALAND": 1298494539,
      "AWATER": 2482949902,
      "County_state": "Muskegon,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.46219506947669,
              43.4723275153535
            ],
            [
              -86.448743,
              43.432013
            ],
            [
              -86.435124,
              43.396702
            ],
            [
              -86.4252166145337,
              43.375550610523895
            ],
            [
              -86.407832,
              43.338436
            ],
            [
              -86.39575,
              43.316225
            ],
            [
              -86.3298364585293,
              43.215800361725805
            ],
            [
              -86.316259,
              43.195114
            ],
            [
              -86.3113363877276,
              43.186919721694395
            ],
            [
              -86.28724407096941,
              43.1468151719916
            ],
            [
              -86.280756,
              43.136015
            ],
            [
              -86.27199581316529,
              43.118365119164196
            ],
            [
              -86.027027,
              43.118272
            ],
            [
              -85.907435,
              43.118911
            ],
            [
              -85.908388,
              43.20592
            ],
            [
              -85.790662,
              43.205167
            ],
            [
              -85.790448,
              43.293003
            ],
            [
              -86.038953,
              43.293519
            ],
            [
              -86.039491,
              43.467447
            ],
            [
              -86.46219506947669,
              43.4723275153535
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "137",
      "COUNTYNS": "01623011",
      "AFFGEOID": "0500000US26137",
      "GEOID": "26137",
      "NAME": "Otsego",
      "LSAD": "06",
      "ALAND": 1333913235,
      "AWATER": 28997739,
      "County_state": "Otsego,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.857452,
              45.116707
            ],
            [
              -84.857319,
              45.029757
            ],
            [
              -84.847309,
              44.858037
            ],
            [
              -84.371737,
              44.855039
            ],
            [
              -84.366614,
              45.198737
            ],
            [
              -84.734303,
              45.201936
            ],
            [
              -84.734799,
              45.114903
            ],
            [
              -84.857452,
              45.116707
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "143",
      "COUNTYNS": "01623014",
      "AFFGEOID": "0500000US26143",
      "GEOID": "26143",
      "NAME": "Roscommon",
      "LSAD": "06",
      "ALAND": 1345827533,
      "AWATER": 156421850,
      "County_state": "Roscommon,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.855452,
              44.436632
            ],
            [
              -84.851705,
              44.161375
            ],
            [
              -84.608104,
              44.160482
            ],
            [
              -84.368039,
              44.160538
            ],
            [
              -84.366324,
              44.175508
            ],
            [
              -84.367995,
              44.20381
            ],
            [
              -84.370643,
              44.507223
            ],
            [
              -84.471035,
              44.510733
            ],
            [
              -84.850929,
              44.511069
            ],
            [
              -84.855452,
              44.436632
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "161",
      "COUNTYNS": "01623021",
      "AFFGEOID": "0500000US26161",
      "GEOID": "26161",
      "NAME": "Washtenaw",
      "LSAD": "06",
      "ALAND": 1828420670,
      "AWATER": 42642348,
      "County_state": "Washtenaw,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.130771,
              42.250035
            ],
            [
              -84.131963,
              42.071577
            ],
            [
              -84.008337,
              42.078005
            ],
            [
              -83.773922,
              42.08243
            ],
            [
              -83.656621,
              42.084581
            ],
            [
              -83.646553,
              42.08389
            ],
            [
              -83.539396,
              42.085598
            ],
            [
              -83.542102,
              42.174344
            ],
            [
              -83.543728,
              42.262462
            ],
            [
              -83.547548,
              42.349217
            ],
            [
              -83.551907,
              42.435166
            ],
            [
              -83.664808,
              42.431179
            ],
            [
              -83.94465,
              42.424149
            ],
            [
              -84.023402,
              42.42393
            ],
            [
              -84.131136,
              42.424567
            ],
            [
              -84.130771,
              42.250035
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "015",
      "COUNTYNS": "01622950",
      "AFFGEOID": "0500000US26015",
      "GEOID": "26015",
      "NAME": "Barry",
      "LSAD": "06",
      "ALAND": 1432511156,
      "AWATER": 62021519,
      "County_state": "Barry,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.545564,
              42.768139
            ],
            [
              -85.543513,
              42.594812
            ],
            [
              -85.543191,
              42.421433
            ],
            [
              -85.532498,
              42.421522
            ],
            [
              -85.298879,
              42.419849
            ],
            [
              -85.071609,
              42.421428
            ],
            [
              -85.074245,
              42.770784
            ],
            [
              -85.309626,
              42.769878
            ],
            [
              -85.545564,
              42.768139
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "109",
      "COUNTYNS": "01622997",
      "AFFGEOID": "0500000US26109",
      "GEOID": "26109",
      "NAME": "Menominee",
      "LSAD": "06",
      "ALAND": 2704072756,
      "AWATER": 761779246,
      "County_state": "Menominee,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.875424,
              45.379373
            ],
            [
              -87.875692,
              45.377052
            ],
            [
              -87.871789,
              45.373557
            ],
            [
              -87.871485,
              45.371546
            ],
            [
              -87.86856,
              45.360537
            ],
            [
              -87.867037,
              45.360137
            ],
            [
              -87.865675,
              45.358213
            ],
            [
              -87.865274,
              45.355969
            ],
            [
              -87.864873,
              45.354767
            ],
            [
              -87.863489,
              45.35302
            ],
            [
              -87.860871,
              45.351192
            ],
            [
              -87.858617,
              45.350378
            ],
            [
              -87.852784,
              45.349497
            ],
            [
              -87.850418,
              45.347492
            ],
            [
              -87.849899,
              45.344651
            ],
            [
              -87.851475,
              45.342335
            ],
            [
              -87.851318,
              45.341346
            ],
            [
              -87.850133,
              45.340435
            ],
            [
              -87.848368,
              45.340676
            ],
            [
              -87.838141,
              45.345101
            ],
            [
              -87.836782,
              45.346451
            ],
            [
              -87.835303,
              45.35098
            ],
            [
              -87.832612,
              45.352249
            ],
            [
              -87.829775,
              45.352005
            ],
            [
              -87.826918,
              45.350538
            ],
            [
              -87.824855,
              45.350713
            ],
            [
              -87.823554,
              45.351637
            ],
            [
              -87.823028,
              45.35265
            ],
            [
              -87.810076,
              45.351269
            ],
            [
              -87.800464,
              45.353608
            ],
            [
              -87.790324,
              45.353444
            ],
            [
              -87.787967,
              45.352612
            ],
            [
              -87.783076,
              45.349725
            ],
            [
              -87.773901,
              45.351226
            ],
            [
              -87.771384,
              45.35121
            ],
            [
              -87.769172,
              45.351195
            ],
            [
              -87.762128,
              45.348401
            ],
            [
              -87.754104,
              45.349442
            ],
            [
              -87.751452,
              45.351755
            ],
            [
              -87.751626,
              45.354169
            ],
            [
              -87.750928,
              45.355037
            ],
            [
              -87.738352,
              45.358243
            ],
            [
              -87.737801,
              45.359635
            ],
            [
              -87.733409,
              45.364432
            ],
            [
              -87.718891,
              45.377462
            ],
            [
              -87.708329,
              45.381218
            ],
            [
              -87.706767,
              45.383827
            ],
            [
              -87.704337,
              45.385462
            ],
            [
              -87.699797,
              45.387927
            ],
            [
              -87.693956,
              45.389893
            ],
            [
              -87.690281,
              45.389822
            ],
            [
              -87.685934,
              45.388711
            ],
            [
              -87.682866,
              45.38495
            ],
            [
              -87.675017,
              45.382454
            ],
            [
              -87.67455,
              45.381649
            ],
            [
              -87.674403,
              45.378065
            ],
            [
              -87.673513,
              45.376946
            ],
            [
              -87.657349,
              45.368752
            ],
            [
              -87.656624,
              45.367295
            ],
            [
              -87.655807,
              45.362706
            ],
            [
              -87.656632,
              45.358617
            ],
            [
              -87.653568,
              45.354204
            ],
            [
              -87.650661,
              45.353798
            ],
            [
              -87.648476,
              45.352243
            ],
            [
              -87.647729,
              45.350721
            ],
            [
              -87.647454,
              45.345232
            ],
            [
              -87.648126,
              45.339396
            ],
            [
              -87.655775,
              45.330847
            ],
            [
              -87.65983,
              45.329144
            ],
            [
              -87.661603,
              45.327608
            ],
            [
              -87.662029,
              45.326434
            ],
            [
              -87.6615,
              45.321386
            ],
            [
              -87.663666,
              45.318257
            ],
            [
              -87.665243,
              45.317115
            ],
            [
              -87.667423,
              45.31636
            ],
            [
              -87.675328,
              45.307907
            ],
            [
              -87.679085,
              45.305841
            ],
            [
              -87.687498,
              45.298055
            ],
            [
              -87.687578,
              45.296283
            ],
            [
              -87.690364,
              45.29027
            ],
            [
              -87.693468,
              45.287675
            ],
            [
              -87.698248,
              45.281512
            ],
            [
              -87.699492,
              45.276659
            ],
            [
              -87.698456,
              45.272072
            ],
            [
              -87.69878,
              45.26942
            ],
            [
              -87.703053,
              45.267041
            ],
            [
              -87.709137,
              45.260341
            ],
            [
              -87.707779,
              45.258343
            ],
            [
              -87.709145,
              45.254649
            ],
            [
              -87.71148,
              45.245224
            ],
            [
              -87.711339,
              45.239965
            ],
            [
              -87.712184,
              45.239014
            ],
            [
              -87.713398,
              45.238564
            ],
            [
              -87.717051,
              45.238743
            ],
            [
              -87.718264,
              45.238333
            ],
            [
              -87.724156,
              45.233236
            ],
            [
              -87.725205,
              45.231539
            ],
            [
              -87.72492,
              45.229977
            ],
            [
              -87.721935,
              45.228444
            ],
            [
              -87.721354,
              45.226847
            ],
            [
              -87.722473,
              45.223309
            ],
            [
              -87.726952,
              45.218949
            ],
            [
              -87.727276,
              45.216129
            ],
            [
              -87.726175,
              45.21264
            ],
            [
              -87.726198,
              45.209391
            ],
            [
              -87.72796,
              45.207956
            ],
            [
              -87.736339,
              45.204653
            ],
            [
              -87.739492,
              45.202126
            ],
            [
              -87.741732,
              45.198201
            ],
            [
              -87.741805,
              45.197051
            ],
            [
              -87.73521,
              45.177642
            ],
            [
              -87.736509,
              45.173389
            ],
            [
              -87.736104,
              45.172244
            ],
            [
              -87.735135,
              45.171538
            ],
            [
              -87.730866,
              45.170913
            ],
            [
              -87.727768,
              45.169596
            ],
            [
              -87.724601,
              45.167452
            ],
            [
              -87.723121,
              45.165141
            ],
            [
              -87.717945,
              45.161156
            ],
            [
              -87.711322,
              45.158946
            ],
            [
              -87.708134,
              45.156004
            ],
            [
              -87.707391,
              45.154679
            ],
            [
              -87.703492,
              45.152206
            ],
            [
              -87.700618,
              45.151188
            ],
            [
              -87.695055,
              45.150522
            ],
            [
              -87.692375,
              45.149505
            ],
            [
              -87.688425,
              45.147433
            ],
            [
              -87.683902,
              45.144135
            ],
            [
              -87.675816,
              45.135059
            ],
            [
              -87.676024,
              45.134089
            ],
            [
              -87.678511,
              45.131204
            ],
            [
              -87.678209,
              45.130084
            ],
            [
              -87.672447,
              45.121294
            ],
            [
              -87.671,
              45.120069
            ],
            [
              -87.667102,
              45.118109
            ],
            [
              -87.662087985458,
              45.1133221101264
            ],
            [
              -87.661296,
              45.112566
            ],
            [
              -87.661211,
              45.108279
            ],
            [
              -87.659952,
              45.107512
            ],
            [
              -87.657135,
              45.107568
            ],
            [
              -87.652512,
              45.108633
            ],
            [
              -87.648191,
              45.106368
            ],
            [
              -87.63611,
              45.105918
            ],
            [
              -87.631535,
              45.106224
            ],
            [
              -87.629571,
              45.105324
            ],
            [
              -87.628829,
              45.104039
            ],
            [
              -87.62764,
              45.103328
            ],
            [
              -87.621609,
              45.102399
            ],
            [
              -87.614897,
              45.100064
            ],
            [
              -87.59188,
              45.094689
            ],
            [
              -87.590208,
              45.095264
            ],
            [
              -87.59027,
              45.096406
            ],
            [
              -87.60012,
              45.103011
            ],
            [
              -87.610073,
              45.114141
            ],
            [
              -87.612019,
              45.123377
            ],
            [
              -87.60928,
              45.13232
            ],
            [
              -87.600796,
              45.146842
            ],
            [
              -87.585651,
              45.166394
            ],
            [
              -87.563417,
              45.18407
            ],
            [
              -87.548964,
              45.191591
            ],
            [
              -87.512336,
              45.224252
            ],
            [
              -87.465201,
              45.273351
            ],
            [
              -87.438908,
              45.293405
            ],
            [
              -87.437257,
              45.3055
            ],
            [
              -87.431684,
              45.316383
            ],
            [
              -87.399973,
              45.349322
            ],
            [
              -87.3925,
              45.369028
            ],
            [
              -87.364368,
              45.388532
            ],
            [
              -87.359512,
              45.399829
            ],
            [
              -87.350852,
              45.407743
            ],
            [
              -87.336152,
              45.41536
            ],
            [
              -87.327749,
              45.425307
            ],
            [
              -87.325834,
              45.43004
            ],
            [
              -87.329958,
              45.431937
            ],
            [
              -87.33324,
              45.436897
            ],
            [
              -87.334249,
              45.442315
            ],
            [
              -87.333147,
              45.447208
            ],
            [
              -87.319703,
              45.464929
            ],
            [
              -87.306122,
              45.475513
            ],
            [
              -87.288726,
              45.501606
            ],
            [
              -87.26423095029679,
              45.550547571294594
            ],
            [
              -87.327502,
              45.55125
            ],
            [
              -87.325391,
              45.898665
            ],
            [
              -87.367673,
              45.898969
            ],
            [
              -87.367849,
              45.985321
            ],
            [
              -87.617091,
              45.986014
            ],
            [
              -87.617396,
              45.898846
            ],
            [
              -87.696946,
              45.898842
            ],
            [
              -87.697159,
              45.72296
            ],
            [
              -87.846816947262,
              45.7221550835504
            ],
            [
              -87.837343,
              45.716919
            ],
            [
              -87.831442,
              45.714938
            ],
            [
              -87.812338,
              45.711303
            ],
            [
              -87.810144,
              45.71023
            ],
            [
              -87.805867,
              45.706841
            ],
            [
              -87.805081,
              45.704974
            ],
            [
              -87.805076,
              45.703556
            ],
            [
              -87.809181,
              45.700337
            ],
            [
              -87.809075,
              45.699717
            ],
            [
              -87.804993,
              45.695796
            ],
            [
              -87.80188,
              45.693862
            ],
            [
              -87.787727,
              45.68718
            ],
            [
              -87.782226,
              45.683053
            ],
            [
              -87.780808,
              45.680349
            ],
            [
              -87.780737,
              45.675458
            ],
            [
              -87.781007,
              45.673934
            ],
            [
              -87.781623,
              45.67328
            ],
            [
              -87.795355,
              45.671334
            ],
            [
              -87.798903,
              45.67014
            ],
            [
              -87.80329,
              45.666494
            ],
            [
              -87.823164,
              45.662732
            ],
            [
              -87.823868,
              45.66192
            ],
            [
              -87.823672,
              45.659817
            ],
            [
              -87.822425,
              45.658012
            ],
            [
              -87.822693,
              45.656077
            ],
            [
              -87.824676,
              45.653211
            ],
            [
              -87.824102,
              45.647138
            ],
            [
              -87.821818,
              45.645589
            ],
            [
              -87.817277,
              45.643926
            ],
            [
              -87.810194,
              45.638732
            ],
            [
              -87.804481,
              45.628933
            ],
            [
              -87.796983,
              45.623613
            ],
            [
              -87.796179,
              45.622074
            ],
            [
              -87.79588,
              45.618846
            ],
            [
              -87.792016,
              45.616756
            ],
            [
              -87.780845,
              45.614599
            ],
            [
              -87.777671,
              45.609204
            ],
            [
              -87.776238,
              45.597797
            ],
            [
              -87.777199,
              45.588499
            ],
            [
              -87.781255,
              45.585682
            ],
            [
              -87.785647,
              45.58396
            ],
            [
              -87.786767,
              45.58283
            ],
            [
              -87.787534,
              45.581376
            ],
            [
              -87.787292,
              45.574906
            ],
            [
              -87.788326,
              45.567941
            ],
            [
              -87.788798,
              45.565947
            ],
            [
              -87.790874,
              45.564096
            ],
            [
              -87.792372,
              45.563055
            ],
            [
              -87.797536,
              45.562124
            ],
            [
              -87.806104,
              45.562863
            ],
            [
              -87.813745,
              45.565175
            ],
            [
              -87.829346,
              45.568776
            ],
            [
              -87.831689,
              45.568035
            ],
            [
              -87.833591,
              45.562529
            ],
            [
              -87.832968,
              45.559461
            ],
            [
              -87.832296,
              45.558767
            ],
            [
              -87.827215,
              45.55562
            ],
            [
              -87.818791,
              45.5521
            ],
            [
              -87.813737,
              45.548616
            ],
            [
              -87.807159,
              45.543523
            ],
            [
              -87.80339,
              45.538272
            ],
            [
              -87.803364,
              45.537016
            ],
            [
              -87.804528,
              45.534373
            ],
            [
              -87.80472,
              45.531244
            ],
            [
              -87.804203,
              45.524676
            ],
            [
              -87.802267,
              45.514233
            ],
            [
              -87.798794,
              45.506287
            ],
            [
              -87.793215,
              45.505028
            ],
            [
              -87.792769,
              45.499967
            ],
            [
              -87.793447,
              45.498372
            ],
            [
              -87.796409,
              45.494679
            ],
            [
              -87.797824,
              45.491468
            ],
            [
              -87.798362,
              45.486564
            ],
            [
              -87.79896,
              45.485147
            ],
            [
              -87.806891,
              45.479092
            ],
            [
              -87.807388,
              45.477031
            ],
            [
              -87.805873,
              45.47438
            ],
            [
              -87.805773,
              45.473139
            ],
            [
              -87.811469,
              45.467991
            ],
            [
              -87.812971,
              45.4661
            ],
            [
              -87.812976,
              45.464159
            ],
            [
              -87.821057,
              45.459955
            ],
            [
              -87.82743,
              45.458076
            ],
            [
              -87.832456,
              45.45502
            ],
            [
              -87.833042,
              45.453596
            ],
            [
              -87.836008,
              45.450877
            ],
            [
              -87.844815,
              45.448411
            ],
            [
              -87.847429,
              45.444177
            ],
            [
              -87.855298,
              45.441379
            ],
            [
              -87.861697,
              45.434473
            ],
            [
              -87.86195,
              45.433072
            ],
            [
              -87.860127,
              45.429584
            ],
            [
              -87.860432,
              45.423504
            ],
            [
              -87.856216,
              45.416101
            ],
            [
              -87.85181,
              45.413103
            ],
            [
              -87.850533,
              45.411685
            ],
            [
              -87.849668,
              45.409518
            ],
            [
              -87.849322,
              45.403872
            ],
            [
              -87.850969,
              45.401925
            ],
            [
              -87.859131,
              45.398967
            ],
            [
              -87.859773,
              45.397278
            ],
            [
              -87.859603,
              45.396409
            ],
            [
              -87.85683,
              45.393106
            ],
            [
              -87.859418,
              45.388227
            ],
            [
              -87.864677,
              45.385232
            ],
            [
              -87.870905,
              45.383116
            ],
            [
              -87.873568,
              45.381357
            ],
            [
              -87.875424,
              45.379373
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "111",
      "COUNTYNS": "01622998",
      "AFFGEOID": "0500000US26111",
      "GEOID": "26111",
      "NAME": "Midland",
      "LSAD": "06",
      "ALAND": 1337100078,
      "AWATER": 30591264,
      "County_state": "Midland,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.606035,
              43.815218
            ],
            [
              -84.60754,
              43.466006
            ],
            [
              -84.488684,
              43.466168
            ],
            [
              -84.369876,
              43.466044
            ],
            [
              -84.369777,
              43.481573
            ],
            [
              -84.170576,
              43.481969
            ],
            [
              -84.168127,
              43.568899
            ],
            [
              -84.166975,
              43.740385
            ],
            [
              -84.167318,
              43.825902
            ],
            [
              -84.366511,
              43.828434
            ],
            [
              -84.366676,
              43.81356
            ],
            [
              -84.606035,
              43.815218
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "095",
      "COUNTYNS": "01622990",
      "AFFGEOID": "0500000US26095",
      "GEOID": "26095",
      "NAME": "Luce",
      "LSAD": "06",
      "ALAND": 2328596732,
      "AWATER": 2624713509,
      "County_state": "Luce,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.864932,
              46.504758
            ],
            [
              -85.865025,
              46.244631
            ],
            [
              -85.237839,
              46.24543
            ],
            [
              -85.23787317735959,
              46.7557027812565
            ],
            [
              -85.25686,
              46.75338
            ],
            [
              -85.289846,
              46.744644
            ],
            [
              -85.369805,
              46.713754
            ],
            [
              -85.482096,
              46.680432
            ],
            [
              -85.50951,
              46.675786
            ],
            [
              -85.542517,
              46.674263
            ],
            [
              -85.587345,
              46.674627
            ],
            [
              -85.624573,
              46.678862
            ],
            [
              -85.668753,
              46.680404
            ],
            [
              -85.714415,
              46.677156
            ],
            [
              -85.750606,
              46.677368
            ],
            [
              -85.794923,
              46.681083
            ],
            [
              -85.841057,
              46.688896
            ],
            [
              -85.864549264065,
              46.6901824613954
            ],
            [
              -85.864932,
              46.504758
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "009",
      "COUNTYNS": "01622947",
      "AFFGEOID": "0500000US26009",
      "GEOID": "26009",
      "NAME": "Antrim",
      "LSAD": "06",
      "ALAND": 1232000354,
      "AWATER": 326539096,
      "County_state": "Antrim,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.425804,
              44.881646
            ],
            [
              -85.4422502592113,
              44.85981687981659
            ],
            [
              -85.381983,
              44.859568
            ],
            [
              -85.37506,
              44.829325
            ],
            [
              -85.348542,
              44.827811
            ],
            [
              -85.332836,
              44.812372
            ],
            [
              -85.316548,
              44.823514
            ],
            [
              -85.327696,
              44.850707
            ],
            [
              -85.291716,
              44.859608
            ],
            [
              -84.847309,
              44.858037
            ],
            [
              -84.857319,
              45.029757
            ],
            [
              -84.857452,
              45.116707
            ],
            [
              -85.223681,
              45.117861
            ],
            [
              -85.222707,
              45.20556
            ],
            [
              -85.38747191734359,
              45.207772574210196
            ],
            [
              -85.386726,
              45.189497
            ],
            [
              -85.380464,
              45.180876
            ],
            [
              -85.376948,
              45.142881
            ],
            [
              -85.372571,
              45.126241
            ],
            [
              -85.366908,
              45.116938
            ],
            [
              -85.366412,
              45.069023
            ],
            [
              -85.377586,
              45.055713
            ],
            [
              -85.380659,
              45.046319
            ],
            [
              -85.381654,
              45.018407
            ],
            [
              -85.378286,
              44.998587
            ],
            [
              -85.3958,
              44.931018
            ],
            [
              -85.406173,
              44.911773
            ],
            [
              -85.423003,
              44.895019
            ],
            [
              -85.425804,
              44.881646
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "075",
      "COUNTYNS": "01622980",
      "AFFGEOID": "0500000US26075",
      "GEOID": "26075",
      "NAME": "Jackson",
      "LSAD": "06",
      "ALAND": 1817385089,
      "AWATER": 56453210,
      "County_state": "Jackson,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.718493,
              42.42152
            ],
            [
              -84.718602,
              42.334535
            ],
            [
              -84.716938,
              42.246696
            ],
            [
              -84.711481,
              42.159146
            ],
            [
              -84.709556,
              42.070366
            ],
            [
              -84.477935,
              42.072935
            ],
            [
              -84.363297,
              42.073456
            ],
            [
              -84.247627,
              42.073266
            ],
            [
              -84.179835,
              42.073791
            ],
            [
              -84.131963,
              42.071577
            ],
            [
              -84.130771,
              42.250035
            ],
            [
              -84.131136,
              42.424567
            ],
            [
              -84.140713,
              42.424611
            ],
            [
              -84.364805,
              42.424694
            ],
            [
              -84.600403,
              42.421984
            ],
            [
              -84.718493,
              42.42152
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "149",
      "COUNTYNS": "01625034",
      "AFFGEOID": "0500000US26149",
      "GEOID": "26149",
      "NAME": "St. Joseph",
      "LSAD": "06",
      "ALAND": 1296530601,
      "AWATER": 52921099,
      "County_state": "St. Joseph,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.784807,
              41.759528
            ],
            [
              -85.79133484218919,
              41.7590511655936
            ],
            [
              -85.775039,
              41.759147
            ],
            [
              -85.77403287318049,
              41.7591446658841
            ],
            [
              -85.750469,
              41.75909
            ],
            [
              -85.749992,
              41.759091
            ],
            [
              -85.724534,
              41.759085
            ],
            [
              -85.6597500080009,
              41.759100801830094
            ],
            [
              -85.650738,
              41.759103
            ],
            [
              -85.647683,
              41.759125
            ],
            [
              -85.632714,
              41.759164
            ],
            [
              -85.624987,
              41.759093
            ],
            [
              -85.622608,
              41.759049
            ],
            [
              -85.608312,
              41.759193
            ],
            [
              -85.607548,
              41.759079
            ],
            [
              -85.518251,
              41.759513
            ],
            [
              -85.515959,
              41.759352
            ],
            [
              -85.432471,
              41.759684
            ],
            [
              -85.427553,
              41.759706
            ],
            [
              -85.379133,
              41.759875
            ],
            [
              -85.350174,
              41.759908
            ],
            [
              -85.330623,
              41.759982
            ],
            [
              -85.318129,
              41.759983
            ],
            [
              -85.30814,
              41.760097
            ],
            [
              -85.298365,
              41.760028
            ],
            [
              -85.2921778813506,
              41.759962830860104
            ],
            [
              -85.293626,
              42.071553
            ],
            [
              -85.529149,
              42.070703
            ],
            [
              -85.762943,
              42.069327
            ],
            [
              -85.760186,
              41.798814
            ],
            [
              -85.768832,
              41.785386
            ],
            [
              -85.785875,
              41.77729
            ],
            [
              -85.784807,
              41.759528
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "133",
      "COUNTYNS": "01623009",
      "AFFGEOID": "0500000US26133",
      "GEOID": "26133",
      "NAME": "Osceola",
      "LSAD": "06",
      "ALAND": 1466836685,
      "AWATER": 17262767,
      "County_state": "Osceola,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.564554,
              44.164861
            ],
            [
              -85.562433,
              43.815483
            ],
            [
              -85.443132,
              43.81481
            ],
            [
              -85.088811,
              43.813676
            ],
            [
              -85.087403,
              44.164242
            ],
            [
              -85.334777,
              44.165118
            ],
            [
              -85.44442,
              44.165002
            ],
            [
              -85.564554,
              44.164861
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "145",
      "COUNTYNS": "01623015",
      "AFFGEOID": "0500000US26145",
      "GEOID": "26145",
      "NAME": "Saginaw",
      "LSAD": "06",
      "ALAND": 2073181841,
      "AWATER": 40159474,
      "County_state": "Saginaw,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.369777,
              43.481573
            ],
            [
              -84.369876,
              43.466044
            ],
            [
              -84.367891,
              43.128452
            ],
            [
              -83.929079,
              43.132782
            ],
            [
              -83.932071,
              43.220377
            ],
            [
              -83.814494,
              43.22121
            ],
            [
              -83.695621,
              43.221422
            ],
            [
              -83.695258,
              43.326048
            ],
            [
              -83.698509,
              43.392711
            ],
            [
              -83.698816,
              43.478957
            ],
            [
              -83.817678,
              43.479052
            ],
            [
              -83.817228,
              43.522345
            ],
            [
              -83.916091,
              43.522629
            ],
            [
              -84.050987,
              43.524064
            ],
            [
              -84.05,
              43.567324
            ],
            [
              -84.168127,
              43.568899
            ],
            [
              -84.170576,
              43.481969
            ],
            [
              -84.369777,
              43.481573
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "059",
      "COUNTYNS": "01622972",
      "AFFGEOID": "0500000US26059",
      "GEOID": "26059",
      "NAME": "Hillsdale",
      "LSAD": "06",
      "ALAND": 1549207599,
      "AWATER": 22938677,
      "County_state": "Hillsdale,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.826491,
              42.072468
            ],
            [
              -84.82512990965739,
              41.7599907212136
            ],
            [
              -84.818873,
              41.760059
            ],
            [
              -84.805883,
              41.760216
            ],
            [
              -84.806134,
              41.743115
            ],
            [
              -84.806074,
              41.737603
            ],
            [
              -84.806065,
              41.732909
            ],
            [
              -84.806042,
              41.720544
            ],
            [
              -84.806018,
              41.707485
            ],
            [
              -84.806082,
              41.696089
            ],
            [
              -84.749955,
              41.698245
            ],
            [
              -84.438067,
              41.704903
            ],
            [
              -84.39954710435259,
              41.705860430932304
            ],
            [
              -84.396547,
              41.705935
            ],
            [
              -84.360546,
              41.706621
            ],
            [
              -84.3604162952785,
              41.7066246193358
            ],
            [
              -84.363297,
              42.073456
            ],
            [
              -84.477935,
              42.072935
            ],
            [
              -84.709556,
              42.070366
            ],
            [
              -84.826491,
              42.072468
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "011",
      "COUNTYNS": "01622948",
      "AFFGEOID": "0500000US26011",
      "GEOID": "26011",
      "NAME": "Arenac",
      "LSAD": "06",
      "ALAND": 940623611,
      "AWATER": 822317353,
      "County_state": "Arenac,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.166107,
              44.161788
            ],
            [
              -84.166377,
              43.996901
            ],
            [
              -84.045409,
              43.996974
            ],
            [
              -84.044843,
              43.911146
            ],
            [
              -83.9083763975914,
              43.91044842698769
            ],
            [
              -83.907388,
              43.918062
            ],
            [
              -83.890912,
              43.923314
            ],
            [
              -83.890145,
              43.934672
            ],
            [
              -83.885328,
              43.946691
            ],
            [
              -83.877694,
              43.959235
            ],
            [
              -83.869406,
              43.960719
            ],
            [
              -83.856128,
              43.972632
            ],
            [
              -83.85493,
              43.977067
            ],
            [
              -83.848276,
              43.981594
            ],
            [
              -83.829077,
              43.989095
            ],
            [
              -83.787863,
              43.985279
            ],
            [
              -83.763774,
              43.985158
            ],
            [
              -83.746779,
              43.988807
            ],
            [
              -83.743806,
              43.991529
            ],
            [
              -83.727916,
              44.001508
            ],
            [
              -83.693214,
              43.98877
            ],
            [
              -83.680108,
              43.994196
            ],
            [
              -83.687892,
              44.020709
            ],
            [
              -83.679654,
              44.036365
            ],
            [
              -83.650116,
              44.052404
            ],
            [
              -83.621078,
              44.056186
            ],
            [
              -83.601173,
              44.054686
            ],
            [
              -83.58409,
              44.056748
            ],
            [
              -83.590437,
              44.069569
            ],
            [
              -83.591361,
              44.079237
            ],
            [
              -83.588004,
              44.086758
            ],
            [
              -83.573071,
              44.101298
            ],
            [
              -83.567714,
              44.119652
            ],
            [
              -83.568915,
              44.126734
            ],
            [
              -83.567744,
              44.155899
            ],
            [
              -83.5652245417928,
              44.163516602719
            ],
            [
              -83.883977,
              44.161786
            ],
            [
              -84.166107,
              44.161788
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "079",
      "COUNTYNS": "01622982",
      "AFFGEOID": "0500000US26079",
      "GEOID": "26079",
      "NAME": "Kalkaska",
      "LSAD": "06",
      "ALAND": 1449687582,
      "AWATER": 28061738,
      "County_state": "Kalkaska,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.332836,
              44.812372
            ],
            [
              -85.334,
              44.512303
            ],
            [
              -84.850929,
              44.511069
            ],
            [
              -84.847309,
              44.858037
            ],
            [
              -85.291716,
              44.859608
            ],
            [
              -85.327696,
              44.850707
            ],
            [
              -85.316548,
              44.823514
            ],
            [
              -85.332836,
              44.812372
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "017",
      "COUNTYNS": "01622951",
      "AFFGEOID": "0500000US26017",
      "GEOID": "26017",
      "NAME": "Bay",
      "LSAD": "06",
      "ALAND": 1145557694,
      "AWATER": 487990588,
      "County_state": "Bay,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.166975,
              43.740385
            ],
            [
              -84.168127,
              43.568899
            ],
            [
              -84.05,
              43.567324
            ],
            [
              -84.050987,
              43.524064
            ],
            [
              -83.916091,
              43.522629
            ],
            [
              -83.817228,
              43.522345
            ],
            [
              -83.817678,
              43.479052
            ],
            [
              -83.698816,
              43.478957
            ],
            [
              -83.699484,
              43.565823
            ],
            [
              -83.6994170918811,
              43.601637150256494
            ],
            [
              -83.731005,
              43.623369
            ],
            [
              -83.756318,
              43.631369
            ],
            [
              -83.800548,
              43.641386
            ],
            [
              -83.817894,
              43.673789
            ],
            [
              -83.830098,
              43.671839
            ],
            [
              -83.852076,
              43.644922
            ],
            [
              -83.897078,
              43.664022
            ],
            [
              -83.909479,
              43.672622
            ],
            [
              -83.939297,
              43.715369
            ],
            [
              -83.9492264180073,
              43.7386440836188
            ],
            [
              -83.954347,
              43.750647
            ],
            [
              -83.956021,
              43.759286
            ],
            [
              -83.954792,
              43.760932
            ],
            [
              -83.945426,
              43.759946
            ],
            [
              -83.929375,
              43.777091
            ],
            [
              -83.926345,
              43.787398
            ],
            [
              -83.917875,
              43.856509
            ],
            [
              -83.910613,
              43.89322
            ],
            [
              -83.9083763975914,
              43.91044842698769
            ],
            [
              -84.044843,
              43.911146
            ],
            [
              -84.045409,
              43.996974
            ],
            [
              -84.166377,
              43.996901
            ],
            [
              -84.167318,
              43.825902
            ],
            [
              -84.166975,
              43.740385
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "007",
      "COUNTYNS": "01622946",
      "AFFGEOID": "0500000US26007",
      "GEOID": "26007",
      "NAME": "Alpena",
      "LSAD": "06",
      "ALAND": 1481123848,
      "AWATER": 2908714548,
      "County_state": "Alpena,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.890439,
              45.03047
            ],
            [
              -83.888482,
              44.856635
            ],
            [
              -83.888484,
              44.856458
            ],
            [
              -83.32109311974439,
              44.8584942845224
            ],
            [
              -83.320503,
              44.880571
            ],
            [
              -83.352815,
              44.886164
            ],
            [
              -83.39396,
              44.903056
            ],
            [
              -83.398879,
              44.906417
            ],
            [
              -83.404596,
              44.918761
            ],
            [
              -83.425311,
              44.926741
            ],
            [
              -83.433032,
              44.93289
            ],
            [
              -83.438856,
              44.940843
            ],
            [
              -83.443718,
              44.952247
            ],
            [
              -83.450013,
              44.990219
            ],
            [
              -83.438948,
              45.000011
            ],
            [
              -83.435822,
              45.000012
            ],
            [
              -83.431254,
              45.007998
            ],
            [
              -83.435249,
              45.011883
            ],
            [
              -83.446342,
              45.016655
            ],
            [
              -83.454168,
              45.03188
            ],
            [
              -83.453363,
              45.035331
            ],
            [
              -83.442052,
              45.051056
            ],
            [
              -83.433798,
              45.057616
            ],
            [
              -83.399255,
              45.070364
            ],
            [
              -83.36747,
              45.062268
            ],
            [
              -83.357609,
              45.050613
            ],
            [
              -83.340257,
              45.041545
            ],
            [
              -83.302153,
              45.032315
            ],
            [
              -83.287974,
              45.026462
            ],
            [
              -83.271506,
              45.023417
            ],
            [
              -83.265896,
              45.026844
            ],
            [
              -83.271464,
              45.038114
            ],
            [
              -83.277037,
              45.044767
            ],
            [
              -83.280272,
              45.045962
            ],
            [
              -83.291346,
              45.062597
            ],
            [
              -83.290827,
              45.069157
            ],
            [
              -83.298275,
              45.090483
            ],
            [
              -83.30788,
              45.099093
            ],
            [
              -83.318442,
              45.12893
            ],
            [
              -83.319315,
              45.137684
            ],
            [
              -83.315924,
              45.139992
            ],
            [
              -83.316118,
              45.141958
            ],
            [
              -83.337822,
              45.14712
            ],
            [
              -83.348684,
              45.161516
            ],
            [
              -83.359895,
              45.16302
            ],
            [
              -83.363678,
              45.166469
            ],
            [
              -83.368046,
              45.172478
            ],
            [
              -83.368896,
              45.182168
            ],
            [
              -83.381647,
              45.203357
            ],
            [
              -83.384265,
              45.203472
            ],
            [
              -83.38758745434319,
              45.207106917599795
            ],
            [
              -83.513882,
              45.207123
            ],
            [
              -83.881223,
              45.202071
            ],
            [
              -83.882911,
              45.159505
            ],
            [
              -83.880234,
              45.030611
            ],
            [
              -83.890439,
              45.03047
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "053",
      "COUNTYNS": "01622969",
      "AFFGEOID": "0500000US26053",
      "GEOID": "26053",
      "NAME": "Gogebic",
      "LSAD": "06",
      "ALAND": 2854517985,
      "AWATER": 970153168,
      "County_state": "Gogebic,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.418136,
              46.566094
            ],
            [
              -90.41562,
              46.563169
            ],
            [
              -90.414596,
              46.55732
            ],
            [
              -90.414464,
              46.55732
            ],
            [
              -90.407775,
              46.552246
            ],
            [
              -90.405593,
              46.547584
            ],
            [
              -90.402019,
              46.544384
            ],
            [
              -90.400429,
              46.544384
            ],
            [
              -90.400041,
              46.544384
            ],
            [
              -90.398742,
              46.542738
            ],
            [
              -90.395568,
              46.536317
            ],
            [
              -90.395272,
              46.533941
            ],
            [
              -90.39332,
              46.532615
            ],
            [
              -90.387228,
              46.533663
            ],
            [
              -90.374461,
              46.539212
            ],
            [
              -90.369964,
              46.540549
            ],
            [
              -90.3616,
              46.541434
            ],
            [
              -90.357676,
              46.540271
            ],
            [
              -90.357014,
              46.540591
            ],
            [
              -90.355689,
              46.540317
            ],
            [
              -90.353534,
              46.537553
            ],
            [
              -90.35158,
              46.537074
            ],
            [
              -90.350121,
              46.537337
            ],
            [
              -90.349462,
              46.53808
            ],
            [
              -90.347514,
              46.547083
            ],
            [
              -90.344338,
              46.552087
            ],
            [
              -90.336921,
              46.554076
            ],
            [
              -90.331887,
              46.553278
            ],
            [
              -90.327548,
              46.550262
            ],
            [
              -90.328044,
              46.548046
            ],
            [
              -90.326686,
              46.54615
            ],
            [
              -90.324699,
              46.545602
            ],
            [
              -90.320428,
              46.546287
            ],
            [
              -90.310859,
              46.539365
            ],
            [
              -90.310329,
              46.536852
            ],
            [
              -90.311886,
              46.528695
            ],
            [
              -90.314434,
              46.523784
            ],
            [
              -90.317777,
              46.521637
            ],
            [
              -90.316983,
              46.517319
            ],
            [
              -90.313894,
              46.516199
            ],
            [
              -90.313839,
              46.516199
            ],
            [
              -90.312581,
              46.517113
            ],
            [
              -90.307716,
              46.518392
            ],
            [
              -90.306558,
              46.518484
            ],
            [
              -90.303546,
              46.517432
            ],
            [
              -90.298284,
              46.51782
            ],
            [
              -90.294411,
              46.518848
            ],
            [
              -90.294311,
              46.519876
            ],
            [
              -90.292854,
              46.520972
            ],
            [
              -90.285707,
              46.518846
            ],
            [
              -90.283423,
              46.518868
            ],
            [
              -90.27892,
              46.522271
            ],
            [
              -90.278356,
              46.523847
            ],
            [
              -90.277131,
              46.524487
            ],
            [
              -90.272599,
              46.521127
            ],
            [
              -90.271971,
              46.519756
            ],
            [
              -90.274721,
              46.515416
            ],
            [
              -90.270422,
              46.51169
            ],
            [
              -90.270432,
              46.510756
            ],
            [
              -90.270558,
              46.50956
            ],
            [
              -90.270684,
              46.508237
            ],
            [
              -90.27018,
              46.507356
            ],
            [
              -90.26848,
              46.507167
            ],
            [
              -90.266528,
              46.507356
            ],
            [
              -90.265143,
              46.506222
            ],
            [
              -90.265143,
              46.505089
            ],
            [
              -90.265269,
              46.503829
            ],
            [
              -90.263018,
              46.502777
            ],
            [
              -90.260504,
              46.502822
            ],
            [
              -90.25865,
              46.503483
            ],
            [
              -90.25716,
              46.504716
            ],
            [
              -90.248194,
              46.505357
            ],
            [
              -90.246043,
              46.504832
            ],
            [
              -90.243395,
              46.505245
            ],
            [
              -90.236283,
              46.507121
            ],
            [
              -90.231587,
              46.509842
            ],
            [
              -90.230363,
              46.509705
            ],
            [
              -90.229402,
              46.507992
            ],
            [
              -90.230921,
              46.504656
            ],
            [
              -90.23102,
              46.503354
            ],
            [
              -90.230324,
              46.501732
            ],
            [
              -90.228735,
              46.501573
            ],
            [
              -90.222351,
              46.50338
            ],
            [
              -90.220532,
              46.503403
            ],
            [
              -90.216594,
              46.501759
            ],
            [
              -90.214866,
              46.499947
            ],
            [
              -90.214843,
              46.498181
            ],
            [
              -90.211753,
              46.490351
            ],
            [
              -90.204009,
              46.478175
            ],
            [
              -90.201727,
              46.476074
            ],
            [
              -90.193394,
              46.472487
            ],
            [
              -90.188996,
              46.469015
            ],
            [
              -90.188633,
              46.468101
            ],
            [
              -90.189426,
              46.467004
            ],
            [
              -90.192005,
              46.465611
            ],
            [
              -90.193294,
              46.463143
            ],
            [
              -90.190749,
              46.460173
            ],
            [
              -90.189162,
              46.459054
            ],
            [
              -90.180336,
              46.456746
            ],
            [
              -90.179212,
              46.45309
            ],
            [
              -90.17786,
              46.440548
            ],
            [
              -90.174556,
              46.439656
            ],
            [
              -90.166919,
              46.439851
            ],
            [
              -90.166909,
              46.439311
            ],
            [
              -90.166526,
              46.437576
            ],
            [
              -90.163422,
              46.434605
            ],
            [
              -90.158603,
              46.422656
            ],
            [
              -90.158241,
              46.420485
            ],
            [
              -90.158972,
              46.413769
            ],
            [
              -90.157851,
              46.409291
            ],
            [
              -90.152936,
              46.401293
            ],
            [
              -90.148347,
              46.399258
            ],
            [
              -90.146816,
              46.397205
            ],
            [
              -90.144359,
              46.390255
            ],
            [
              -90.13941,
              46.384999
            ],
            [
              -90.135253,
              46.38221
            ],
            [
              -90.133966,
              46.382118
            ],
            [
              -90.13225,
              46.381249
            ],
            [
              -90.134656,
              46.374979
            ],
            [
              -90.134663,
              46.374947
            ],
            [
              -90.133871,
              46.371828
            ],
            [
              -90.131036,
              46.369199
            ],
            [
              -90.126517,
              46.366889
            ],
            [
              -90.122923,
              46.363603
            ],
            [
              -90.122757,
              46.362621
            ],
            [
              -90.122785,
              46.361259
            ],
            [
              -90.122287,
              46.360139
            ],
            [
              -90.120973,
              46.35972
            ],
            [
              -90.119757,
              46.359748
            ],
            [
              -90.119691,
              46.359755
            ],
            [
              -90.118827,
              46.359241
            ],
            [
              -90.116844,
              46.355153
            ],
            [
              -90.116741,
              46.350652
            ],
            [
              -90.117466,
              46.349487
            ],
            [
              -90.119729,
              46.348504
            ],
            [
              -90.120614,
              46.34642
            ],
            [
              -90.120198,
              46.345066
            ],
            [
              -90.119572,
              46.34418
            ],
            [
              -90.118791,
              46.342253
            ],
            [
              -90.119468,
              46.3397
            ],
            [
              -90.121084,
              46.338656
            ],
            [
              -90.12138,
              46.338131
            ],
            [
              -90.121248,
              46.337217
            ],
            [
              -90.120489,
              46.336852
            ],
            [
              -89.92915763295049,
              46.2997498928327
            ],
            [
              -89.918798,
              46.297741
            ],
            [
              -89.90991,
              46.296402
            ],
            [
              -89.908196,
              46.296037
            ],
            [
              -89.764506,
              46.268082
            ],
            [
              -89.667617,
              46.249797
            ],
            [
              -89.638416,
              46.243804
            ],
            [
              -89.533801,
              46.224119
            ],
            [
              -89.495723,
              46.216301
            ],
            [
              -89.276883,
              46.174116
            ],
            [
              -89.276489,
              46.174047
            ],
            [
              -89.219964,
              46.163319
            ],
            [
              -89.218156,
              46.162988
            ],
            [
              -89.205657,
              46.160408
            ],
            [
              -89.203289,
              46.16002
            ],
            [
              -89.201283,
              46.159426
            ],
            [
              -89.194508,
              46.157942
            ],
            [
              -89.166887,
              46.152868
            ],
            [
              -89.161757,
              46.151816
            ],
            [
              -89.125136,
              46.144531
            ],
            [
              -89.09163,
              46.138505
            ],
            [
              -88.99088490869069,
              46.09732984177639
            ],
            [
              -88.991012,
              46.332308
            ],
            [
              -89.365507,
              46.333078
            ],
            [
              -89.365094,
              46.505949
            ],
            [
              -89.740568,
              46.505642
            ],
            [
              -89.740001,
              46.592494
            ],
            [
              -89.86442,
              46.592643
            ],
            [
              -89.863793,
              46.765405
            ],
            [
              -89.88911913036699,
              46.765503481203496
            ],
            [
              -89.892355,
              46.763088
            ],
            [
              -89.918466,
              46.740324
            ],
            [
              -89.957101,
              46.716929
            ],
            [
              -89.973803,
              46.710322
            ],
            [
              -89.985817,
              46.70319
            ],
            [
              -89.996034,
              46.693225
            ],
            [
              -90.028392,
              46.67439
            ],
            [
              -90.04542,
              46.668272
            ],
            [
              -90.100695,
              46.655132
            ],
            [
              -90.164026,
              46.645515
            ],
            [
              -90.237609,
              46.624485
            ],
            [
              -90.265294,
              46.618516
            ],
            [
              -90.306609,
              46.602741
            ],
            [
              -90.327626,
              46.607744
            ],
            [
              -90.348407,
              46.600635
            ],
            [
              -90.418136,
              46.566094
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "043",
      "COUNTYNS": "01622964",
      "AFFGEOID": "0500000US26043",
      "GEOID": "26043",
      "NAME": "Dickinson",
      "LSAD": "06",
      "ALAND": 1970831145,
      "AWATER": 40519954,
      "County_state": "Dickinson,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.135067,
              45.821694
            ],
            [
              -88.13611,
              45.819029
            ],
            [
              -88.131834,
              45.811312
            ],
            [
              -88.129461,
              45.809288
            ],
            [
              -88.116024,
              45.804079
            ],
            [
              -88.109506,
              45.803584
            ],
            [
              -88.107506,
              45.802668
            ],
            [
              -88.105355,
              45.800104
            ],
            [
              -88.105518,
              45.798839
            ],
            [
              -88.106351,
              45.797573
            ],
            [
              -88.103247,
              45.791361
            ],
            [
              -88.099616,
              45.790186
            ],
            [
              -88.094047,
              45.785658
            ],
            [
              -88.08859,
              45.784697
            ],
            [
              -88.079764,
              45.78495
            ],
            [
              -88.078361,
              45.784249
            ],
            [
              -88.076375,
              45.781606
            ],
            [
              -88.072091,
              45.780261
            ],
            [
              -88.0582559967311,
              45.7807194372151
            ],
            [
              -88.050634,
              45.780972
            ],
            [
              -88.048514,
              45.782549
            ],
            [
              -88.044697,
              45.783718
            ],
            [
              -88.040892,
              45.786452
            ],
            [
              -88.040221,
              45.789236
            ],
            [
              -88.039729,
              45.789626
            ],
            [
              -88.033568,
              45.789816
            ],
            [
              -88.031124,
              45.789233
            ],
            [
              -88.027228,
              45.78919
            ],
            [
              -88.0236,
              45.790094
            ],
            [
              -88.017588,
              45.792455
            ],
            [
              -88.007043,
              45.792192
            ],
            [
              -88.001593,
              45.794091
            ],
            [
              -87.995876,
              45.795435
            ],
            [
              -87.991447,
              45.795393
            ],
            [
              -87.989831,
              45.794827
            ],
            [
              -87.987942,
              45.793075
            ],
            [
              -87.982617,
              45.782944
            ],
            [
              -87.98087,
              45.776977
            ],
            [
              -87.981789,
              45.775081
            ],
            [
              -87.983392,
              45.774696
            ],
            [
              -87.986429,
              45.769596
            ],
            [
              -87.976835,
              45.767015
            ],
            [
              -87.972451,
              45.766319
            ],
            [
              -87.96697,
              45.764021
            ],
            [
              -87.963996,
              45.760794
            ],
            [
              -87.964725,
              45.759461
            ],
            [
              -87.963452,
              45.75822
            ],
            [
              -87.959277,
              45.757367
            ],
            [
              -87.954459,
              45.758414
            ],
            [
              -87.944113,
              45.757422
            ],
            [
              -87.934585,
              45.758094
            ],
            [
              -87.92913,
              45.760364
            ],
            [
              -87.926611,
              45.75959
            ],
            [
              -87.921999,
              45.756989
            ],
            [
              -87.908933,
              45.758297
            ],
            [
              -87.907771,
              45.75928
            ],
            [
              -87.905873,
              45.759364
            ],
            [
              -87.904657,
              45.759163
            ],
            [
              -87.902707,
              45.757932
            ],
            [
              -87.901299,
              45.756553
            ],
            [
              -87.900005,
              45.753497
            ],
            [
              -87.898363,
              45.752503
            ],
            [
              -87.896032,
              45.752285
            ],
            [
              -87.891905,
              45.754055
            ],
            [
              -87.882261,
              45.754779
            ],
            [
              -87.879812,
              45.754843
            ],
            [
              -87.875813,
              45.753888
            ],
            [
              -87.873339,
              45.750439
            ],
            [
              -87.868111,
              45.749477
            ],
            [
              -87.864141,
              45.745697
            ],
            [
              -87.86305,
              45.74309
            ],
            [
              -87.863874,
              45.74266
            ],
            [
              -87.86432,
              45.737139
            ],
            [
              -87.85548,
              45.726943
            ],
            [
              -87.846816947262,
              45.7221550835504
            ],
            [
              -87.697159,
              45.72296
            ],
            [
              -87.696946,
              45.898842
            ],
            [
              -87.617396,
              45.898846
            ],
            [
              -87.617091,
              45.986014
            ],
            [
              -87.615597,
              46.246653
            ],
            [
              -88.117407,
              46.246618
            ],
            [
              -88.1168457401249,
              45.9217028628049
            ],
            [
              -88.115346,
              45.922211
            ],
            [
              -88.104686,
              45.922121
            ],
            [
              -88.102908,
              45.921869
            ],
            [
              -88.096496,
              45.917273
            ],
            [
              -88.095409,
              45.915175
            ],
            [
              -88.095354,
              45.913895
            ],
            [
              -88.099172,
              45.912362
            ],
            [
              -88.101973,
              45.91055
            ],
            [
              -88.104576,
              45.906847
            ],
            [
              -88.105677,
              45.904387
            ],
            [
              -88.106136,
              45.900811
            ],
            [
              -88.105981,
              45.897091
            ],
            [
              -88.105447,
              45.896593
            ],
            [
              -88.101814,
              45.883504
            ],
            [
              -88.100218,
              45.881205
            ],
            [
              -88.095841,
              45.880042
            ],
            [
              -88.083965,
              45.881186
            ],
            [
              -88.081781,
              45.880516
            ],
            [
              -88.073944,
              45.875593
            ],
            [
              -88.073134,
              45.871952
            ],
            [
              -88.075146,
              45.864832
            ],
            [
              -88.077534,
              45.863825
            ],
            [
              -88.081641,
              45.865087
            ],
            [
              -88.08259,
              45.864944
            ],
            [
              -88.084985,
              45.862443
            ],
            [
              -88.087419,
              45.857459
            ],
            [
              -88.088825,
              45.85586
            ],
            [
              -88.098326,
              45.850142
            ],
            [
              -88.106622,
              45.841072
            ],
            [
              -88.109089,
              45.839492
            ],
            [
              -88.111726,
              45.839196
            ],
            [
              -88.114267,
              45.837891
            ],
            [
              -88.120723,
              45.832995
            ],
            [
              -88.122947,
              45.829565
            ],
            [
              -88.127808,
              45.827173
            ],
            [
              -88.13364,
              45.823128
            ],
            [
              -88.135067,
              45.821694
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "113",
      "COUNTYNS": "01622999",
      "AFFGEOID": "0500000US26113",
      "GEOID": "26113",
      "NAME": "Missaukee",
      "LSAD": "06",
      "ALAND": 1462807689,
      "AWATER": 23574739,
      "County_state": "Missaukee,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.338404,
              44.425376
            ],
            [
              -85.334777,
              44.165118
            ],
            [
              -85.087403,
              44.164242
            ],
            [
              -84.851705,
              44.161375
            ],
            [
              -84.855452,
              44.436632
            ],
            [
              -84.850929,
              44.511069
            ],
            [
              -85.334,
              44.512303
            ],
            [
              -85.338404,
              44.425376
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "117",
      "COUNTYNS": "01623001",
      "AFFGEOID": "0500000US26117",
      "GEOID": "26117",
      "NAME": "Montcalm",
      "LSAD": "06",
      "ALAND": 1826655021,
      "AWATER": 40083131,
      "County_state": "Montcalm,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.562348,
              43.46813
            ],
            [
              -85.562538,
              43.294271
            ],
            [
              -85.551778,
              43.294288
            ],
            [
              -85.312376,
              43.294205
            ],
            [
              -85.31225,
              43.206438
            ],
            [
              -85.312513,
              43.118793
            ],
            [
              -85.1936,
              43.119534
            ],
            [
              -85.075033,
              43.120213
            ],
            [
              -84.836889,
              43.118851
            ],
            [
              -84.835305,
              43.2922
            ],
            [
              -84.845573,
              43.292231
            ],
            [
              -84.845005,
              43.379177
            ],
            [
              -84.845962,
              43.466158
            ],
            [
              -85.084996,
              43.46619
            ],
            [
              -85.562348,
              43.46813
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "013",
      "COUNTYNS": "01622949",
      "AFFGEOID": "0500000US26013",
      "GEOID": "26013",
      "NAME": "Baraga",
      "LSAD": "06",
      "ALAND": 2326929577,
      "AWATER": 441604239,
      "County_state": "Baraga,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.678879,
              46.851801
            ],
            [
              -88.679392,
              46.420139
            ],
            [
              -88.116571,
              46.419951
            ],
            [
              -88.115843,
              46.678381
            ],
            [
              -87.991546,
              46.679113
            ],
            [
              -87.991389,
              46.765159
            ],
            [
              -88.044709,
              46.765353
            ],
            [
              -88.044528,
              46.808798
            ],
            [
              -88.0445508065543,
              46.9124727650012
            ],
            [
              -88.065192,
              46.918563
            ],
            [
              -88.08187,
              46.920458
            ],
            [
              -88.101315,
              46.917207
            ],
            [
              -88.126927,
              46.90984
            ],
            [
              -88.161913,
              46.904941
            ],
            [
              -88.175197,
              46.90458
            ],
            [
              -88.211287,
              46.884102
            ],
            [
              -88.241948,
              46.860055
            ],
            [
              -88.251574,
              46.843556
            ],
            [
              -88.267697,
              46.821701
            ],
            [
              -88.283423,
              46.822987
            ],
            [
              -88.282829,
              46.832012
            ],
            [
              -88.251217,
              46.871186
            ],
            [
              -88.242661,
              46.885077
            ],
            [
              -88.228837,
              46.898314
            ],
            [
              -88.212,
              46.909032
            ],
            [
              -88.187522,
              46.918999
            ],
            [
              -88.150114,
              46.94363
            ],
            [
              -88.132876,
              46.962204
            ],
            [
              -88.143688,
              46.966665
            ],
            [
              -88.155374,
              46.965069
            ],
            [
              -88.167227,
              46.958855
            ],
            [
              -88.244437,
              46.929612
            ],
            [
              -88.261593,
              46.915516
            ],
            [
              -88.281244,
              46.906632
            ],
            [
              -88.31029,
              46.889748
            ],
            [
              -88.349505,
              46.8606
            ],
            [
              -88.368767,
              46.857313
            ],
            [
              -88.372681,
              46.872277
            ],
            [
              -88.389727,
              46.8671
            ],
            [
              -88.404044,
              46.847798
            ],
            [
              -88.382052,
              46.845437
            ],
            [
              -88.385462,
              46.834367
            ],
            [
              -88.415225,
              46.811715
            ],
            [
              -88.433835,
              46.793502
            ],
            [
              -88.438427,
              46.786714
            ],
            [
              -88.455004,
              46.759343
            ],
            [
              -88.476395,
              46.749491
            ],
            [
              -88.496479,
              46.756494
            ],
            [
              -88.497073,
              46.764716
            ],
            [
              -88.482099,
              46.782622
            ],
            [
              -88.462349,
              46.786711
            ],
            [
              -88.473342,
              46.806226
            ],
            [
              -88.482579,
              46.826197
            ],
            [
              -88.483748,
              46.831727
            ],
            [
              -88.477935,
              46.85056
            ],
            [
              -88.475859,
              46.886042
            ],
            [
              -88.455404,
              46.923321
            ],
            [
              -88.4508233189607,
              46.9390376826177
            ],
            [
              -88.552185,
              46.938533
            ],
            [
              -88.551943,
              46.851795
            ],
            [
              -88.678879,
              46.851801
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "127",
      "COUNTYNS": "01623006",
      "AFFGEOID": "0500000US26127",
      "GEOID": "26127",
      "NAME": "Oceana",
      "LSAD": "06",
      "ALAND": 1355525949,
      "AWATER": 2026870042,
      "County_state": "Oceana,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.540787,
              43.644593
            ],
            [
              -86.540916,
              43.633158
            ],
            [
              -86.538497,
              43.617501
            ],
            [
              -86.529507,
              43.593462
            ],
            [
              -86.520205,
              43.576718
            ],
            [
              -86.479276,
              43.515335
            ],
            [
              -86.468747,
              43.491963
            ],
            [
              -86.46219506947669,
              43.4723275153535
            ],
            [
              -86.039491,
              43.467447
            ],
            [
              -86.037884,
              43.815611
            ],
            [
              -86.4310644927959,
              43.819406220869304
            ],
            [
              -86.431043,
              43.815975
            ],
            [
              -86.437391,
              43.789334
            ],
            [
              -86.445123,
              43.771564
            ],
            [
              -86.461554,
              43.746685
            ],
            [
              -86.481854,
              43.725135
            ],
            [
              -86.510319,
              43.698625
            ],
            [
              -86.529179,
              43.677889
            ],
            [
              -86.538482,
              43.658795
            ],
            [
              -86.540787,
              43.644593
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "003",
      "COUNTYNS": "01622944",
      "AFFGEOID": "0500000US26003",
      "GEOID": "26003",
      "NAME": "Alger",
      "LSAD": "06",
      "ALAND": 2369911333,
      "AWATER": 10703533792,
      "County_state": "Alger,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.11661786793019,
              46.4951625689184
            ],
            [
              -87.116138,
              46.15905
            ],
            [
              -86.615601,
              46.158691
            ],
            [
              -86.615397,
              46.333263
            ],
            [
              -86.364989,
              46.331927
            ],
            [
              -86.364953,
              46.505852
            ],
            [
              -85.864932,
              46.504758
            ],
            [
              -85.864549264065,
              46.6901824613954
            ],
            [
              -85.877908,
              46.690914
            ],
            [
              -85.924047,
              46.684733
            ],
            [
              -85.95367,
              46.676869
            ],
            [
              -85.995044,
              46.673676
            ],
            [
              -86.036969,
              46.667627
            ],
            [
              -86.074219,
              46.657799
            ],
            [
              -86.099843,
              46.654615
            ],
            [
              -86.112126,
              46.655044
            ],
            [
              -86.119862,
              46.657256
            ],
            [
              -86.138295,
              46.672935
            ],
            [
              -86.161681,
              46.669475
            ],
            [
              -86.188024,
              46.654008
            ],
            [
              -86.34989,
              46.578035
            ],
            [
              -86.390409,
              46.563194
            ],
            [
              -86.437167,
              46.54896
            ],
            [
              -86.44439,
              46.548137
            ],
            [
              -86.45993,
              46.551928
            ],
            [
              -86.469306,
              46.551422
            ],
            [
              -86.481956,
              46.542709
            ],
            [
              -86.484003,
              46.535965
            ],
            [
              -86.495054,
              46.524874
            ],
            [
              -86.524959,
              46.505381
            ],
            [
              -86.557731,
              46.487434
            ],
            [
              -86.586168,
              46.463324
            ],
            [
              -86.609039,
              46.470239
            ],
            [
              -86.606932,
              46.478531
            ],
            [
              -86.609393,
              46.492976
            ],
            [
              -86.612173,
              46.493295
            ],
            [
              -86.618061,
              46.489452
            ],
            [
              -86.620603,
              46.483873
            ],
            [
              -86.627441,
              46.47754
            ],
            [
              -86.636671,
              46.478298
            ],
            [
              -86.646393,
              46.485776
            ],
            [
              -86.645528,
              46.492039
            ],
            [
              -86.641088,
              46.500438
            ],
            [
              -86.63453,
              46.504523
            ],
            [
              -86.632109,
              46.508865
            ],
            [
              -86.629086,
              46.518144
            ],
            [
              -86.62738,
              46.53371
            ],
            [
              -86.652865,
              46.560555
            ],
            [
              -86.656479,
              46.558453
            ],
            [
              -86.670927,
              46.556489
            ],
            [
              -86.675764,
              46.557061
            ],
            [
              -86.678182,
              46.561039
            ],
            [
              -86.695645,
              46.555026
            ],
            [
              -86.709325,
              46.543914
            ],
            [
              -86.701929,
              46.511571
            ],
            [
              -86.696001,
              46.50316
            ],
            [
              -86.683819,
              46.498079
            ],
            [
              -86.686468,
              46.471655
            ],
            [
              -86.688816,
              46.463152
            ],
            [
              -86.686412,
              46.454965
            ],
            [
              -86.698139,
              46.438624
            ],
            [
              -86.70323,
              46.439378
            ],
            [
              -86.710573,
              46.444908
            ],
            [
              -86.730829,
              46.468057
            ],
            [
              -86.731096,
              46.47176
            ],
            [
              -86.735929,
              46.475231
            ],
            [
              -86.750157,
              46.479109
            ],
            [
              -86.768516,
              46.479072
            ],
            [
              -86.787905,
              46.477729
            ],
            [
              -86.803557,
              46.466669
            ],
            [
              -86.808817,
              46.460611
            ],
            [
              -86.810967,
              46.449663
            ],
            [
              -86.816026,
              46.437892
            ],
            [
              -86.837448,
              46.434186
            ],
            [
              -86.850111,
              46.434114
            ],
            [
              -86.875151,
              46.43728
            ],
            [
              -86.883919,
              46.441514
            ],
            [
              -86.883976,
              46.450976
            ],
            [
              -86.889094,
              46.458499
            ],
            [
              -86.903742,
              46.466138
            ],
            [
              -86.927725,
              46.464566
            ],
            [
              -86.947077,
              46.472064
            ],
            [
              -86.949526,
              46.476315
            ],
            [
              -86.946218,
              46.479059
            ],
            [
              -86.94698,
              46.484567
            ],
            [
              -86.962842,
              46.509646
            ],
            [
              -86.964534,
              46.516549
            ],
            [
              -86.976958,
              46.526581
            ],
            [
              -87.008724,
              46.532723
            ],
            [
              -87.017136,
              46.53355
            ],
            [
              -87.029892,
              46.525599
            ],
            [
              -87.046022,
              46.519956
            ],
            [
              -87.077279,
              46.515339
            ],
            [
              -87.09876,
              46.503609
            ],
            [
              -87.107559,
              46.496124
            ],
            [
              -87.11661786793019,
              46.4951625689184
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "061",
      "COUNTYNS": "01622973",
      "AFFGEOID": "0500000US26061",
      "GEOID": "26061",
      "NAME": "Houghton",
      "LSAD": "06",
      "ALAND": 2613557367,
      "AWATER": 1275355802,
      "County_state": "Houghton,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.989529,
              46.680119
            ],
            [
              -88.991236,
              46.419704
            ],
            [
              -88.679392,
              46.420139
            ],
            [
              -88.678879,
              46.851801
            ],
            [
              -88.551943,
              46.851795
            ],
            [
              -88.552185,
              46.938533
            ],
            [
              -88.4508233189607,
              46.9390376826177
            ],
            [
              -88.44857,
              46.946769
            ],
            [
              -88.443901,
              46.972251
            ],
            [
              -88.411145,
              46.977984
            ],
            [
              -88.404498,
              46.983353
            ],
            [
              -88.385606,
              47.004522
            ],
            [
              -88.373966,
              47.012262
            ],
            [
              -88.367624,
              47.019213
            ],
            [
              -88.359054,
              47.039739
            ],
            [
              -88.353952,
              47.058047
            ],
            [
              -88.353191,
              47.069063
            ],
            [
              -88.349952,
              47.076377
            ],
            [
              -88.346709,
              47.079372
            ],
            [
              -88.340052,
              47.080494
            ],
            [
              -88.297625,
              47.098505
            ],
            [
              -88.288347,
              47.114547
            ],
            [
              -88.287173,
              47.12042
            ],
            [
              -88.28787,
              47.125374
            ],
            [
              -88.289543,
              47.126604
            ],
            [
              -88.28904,
              47.129689
            ],
            [
              -88.281701,
              47.138212
            ],
            [
              -88.272017,
              47.143511
            ],
            [
              -88.262972,
              47.145174
            ],
            [
              -88.255303,
              47.14364
            ],
            [
              -88.250785,
              47.140209
            ],
            [
              -88.249571,
              47.136231
            ],
            [
              -88.247628,
              47.135981
            ],
            [
              -88.239895,
              47.139436
            ],
            [
              -88.232164,
              47.145975
            ],
            [
              -88.231797,
              47.149609
            ],
            [
              -88.236721,
              47.149287
            ],
            [
              -88.23947,
              47.151137
            ],
            [
              -88.24266,
              47.158426
            ],
            [
              -88.242006,
              47.174767
            ],
            [
              -88.236892,
              47.189236
            ],
            [
              -88.228987,
              47.199042
            ],
            [
              -88.2275522695528,
              47.199937822012
            ],
            [
              -88.298436,
              47.199782
            ],
            [
              -88.298992,
              47.286097
            ],
            [
              -88.512421220678,
              47.2859484572122
            ],
            [
              -88.573997,
              47.245989
            ],
            [
              -88.584912,
              47.242361
            ],
            [
              -88.60983,
              47.238894
            ],
            [
              -88.623579,
              47.232352
            ],
            [
              -88.640323,
              47.226784
            ],
            [
              -88.656359,
              47.225624
            ],
            [
              -88.672395,
              47.219137
            ],
            [
              -88.69966,
              47.204831
            ],
            [
              -88.729688,
              47.185834
            ],
            [
              -88.764351,
              47.155762
            ],
            [
              -88.778022,
              47.150465
            ],
            [
              -88.789813,
              47.150925
            ],
            [
              -88.814834,
              47.141399
            ],
            [
              -88.848176,
              47.115065
            ],
            [
              -88.855372,
              47.114263
            ],
            [
              -88.88914,
              47.100575
            ],
            [
              -88.903706,
              47.086161
            ],
            [
              -88.914189,
              47.059246
            ],
            [
              -88.924492,
              47.042156
            ],
            [
              -88.9333293525611,
              47.032200475790695
            ],
            [
              -88.93248,
              46.764787
            ],
            [
              -88.863906,
              46.765013
            ],
            [
              -88.864473,
              46.679311
            ],
            [
              -88.989529,
              46.680119
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "065",
      "COUNTYNS": "01622975",
      "AFFGEOID": "0500000US26065",
      "GEOID": "26065",
      "NAME": "Ingham",
      "LSAD": "06",
      "ALAND": 1440322252,
      "AWATER": 11920176,
      "County_state": "Ingham,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.602761,
              42.76988
            ],
            [
              -84.603048,
              42.665584
            ],
            [
              -84.601272,
              42.509444
            ],
            [
              -84.600403,
              42.421984
            ],
            [
              -84.364805,
              42.424694
            ],
            [
              -84.140713,
              42.424611
            ],
            [
              -84.140601,
              42.508394
            ],
            [
              -84.144834,
              42.599563
            ],
            [
              -84.150279,
              42.6852
            ],
            [
              -84.158189,
              42.776639
            ],
            [
              -84.363659,
              42.775778
            ],
            [
              -84.36366,
              42.769971
            ],
            [
              -84.512355,
              42.769663
            ],
            [
              -84.602761,
              42.76988
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "071",
      "COUNTYNS": "01622978",
      "AFFGEOID": "0500000US26071",
      "GEOID": "26071",
      "NAME": "Iron",
      "LSAD": "06",
      "ALAND": 3019973510,
      "AWATER": 116746381,
      "County_state": "Iron,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.991236,
              46.419704
            ],
            [
              -88.991012,
              46.332308
            ],
            [
              -88.99088490869069,
              46.09732984177639
            ],
            [
              -88.990807,
              46.097298
            ],
            [
              -88.948698,
              46.080205
            ],
            [
              -88.943279,
              46.077943
            ],
            [
              -88.9327522855634,
              46.073679640474396
            ],
            [
              -88.85027,
              46.040274
            ],
            [
              -88.848464,
              46.038858
            ],
            [
              -88.847599,
              46.037161
            ],
            [
              -88.843903,
              46.03305
            ],
            [
              -88.840584,
              46.031112
            ],
            [
              -88.837991,
              46.030176
            ],
            [
              -88.835249,
              46.03033
            ],
            [
              -88.831544,
              46.02962
            ],
            [
              -88.820592,
              46.026261
            ],
            [
              -88.816489,
              46.023924
            ],
            [
              -88.815427,
              46.022954
            ],
            [
              -88.815629,
              46.02232
            ],
            [
              -88.811948,
              46.021609
            ],
            [
              -88.801761,
              46.023737
            ],
            [
              -88.79646,
              46.023605
            ],
            [
              -88.79579,
              46.024864
            ],
            [
              -88.796242,
              46.026853
            ],
            [
              -88.80067,
              46.030036
            ],
            [
              -88.796182,
              46.033712
            ],
            [
              -88.791796,
              46.032057
            ],
            [
              -88.784411,
              46.032709
            ],
            [
              -88.779221,
              46.031869
            ],
            [
              -88.778628,
              46.031271
            ],
            [
              -88.778734,
              46.028875
            ],
            [
              -88.783635,
              46.024357
            ],
            [
              -88.784007,
              46.022984
            ],
            [
              -88.783891,
              46.020934
            ],
            [
              -88.782104,
              46.016558
            ],
            [
              -88.779915,
              46.015436
            ],
            [
              -88.776187,
              46.015931
            ],
            [
              -88.769712,
              46.018968
            ],
            [
              -88.768692,
              46.020571
            ],
            [
              -88.768305,
              46.021201
            ],
            [
              -88.76761,
              46.021643
            ],
            [
              -88.767104,
              46.021896
            ],
            [
              -88.766156,
              46.022149
            ],
            [
              -88.765208,
              46.022086
            ],
            [
              -88.763767,
              46.021943
            ],
            [
              -88.760044,
              46.019815
            ],
            [
              -88.758618,
              46.019542
            ],
            [
              -88.756295,
              46.020173
            ],
            [
              -88.754033,
              46.02246
            ],
            [
              -88.752176,
              46.023584
            ],
            [
              -88.746422,
              46.025798
            ],
            [
              -88.739994,
              46.027308
            ],
            [
              -88.730675,
              46.026535
            ],
            [
              -88.724801,
              46.024503
            ],
            [
              -88.721125,
              46.022013
            ],
            [
              -88.721319,
              46.018608
            ],
            [
              -88.718397,
              46.013284
            ],
            [
              -88.713049,
              46.012668
            ],
            [
              -88.710328,
              46.016303
            ],
            [
              -88.704687,
              46.018154
            ],
            [
              -88.698716,
              46.017903
            ],
            [
              -88.691662,
              46.015435
            ],
            [
              -88.6833034397318,
              46.01416954199289
            ],
            [
              -88.679132,
              46.013538
            ],
            [
              -88.674606,
              46.010567
            ],
            [
              -88.671458,
              46.005104
            ],
            [
              -88.670115,
              45.999957
            ],
            [
              -88.670939,
              45.999957
            ],
            [
              -88.671267,
              45.999026
            ],
            [
              -88.667464,
              45.995048
            ],
            [
              -88.663923,
              45.993242
            ],
            [
              -88.663609,
              45.992397
            ],
            [
              -88.66436,
              45.991337
            ],
            [
              -88.664802,
              45.989835
            ],
            [
              -88.663697,
              45.989084
            ],
            [
              -88.662902,
              45.98873
            ],
            [
              -88.661312,
              45.988819
            ],
            [
              -88.65776,
              45.989287
            ],
            [
              -88.6375,
              45.98496
            ],
            [
              -88.635598,
              45.985119
            ],
            [
              -88.634842,
              45.987565
            ],
            [
              -88.634055,
              45.987999
            ],
            [
              -88.623947,
              45.988633
            ],
            [
              -88.616405,
              45.9877
            ],
            [
              -88.614176,
              45.988775
            ],
            [
              -88.613063,
              45.990627
            ],
            [
              -88.611563,
              45.99881
            ],
            [
              -88.611466,
              46.003332
            ],
            [
              -88.607438,
              46.010991
            ],
            [
              -88.603965,
              46.016181
            ],
            [
              -88.60144,
              46.017599
            ],
            [
              -88.598093,
              46.017623
            ],
            [
              -88.59386,
              46.015132
            ],
            [
              -88.593302,
              46.014447
            ],
            [
              -88.592874,
              46.01159
            ],
            [
              -88.589755,
              46.005602
            ],
            [
              -88.589,
              46.005077
            ],
            [
              -88.58067,
              46.006975
            ],
            [
              -88.572995,
              46.011799
            ],
            [
              -88.571553,
              46.013811
            ],
            [
              -88.565485,
              46.015708
            ],
            [
              -88.554987,
              46.014977
            ],
            [
              -88.550756,
              46.012896
            ],
            [
              -88.541078,
              46.013763
            ],
            [
              -88.539011,
              46.014791
            ],
            [
              -88.534876,
              46.018104
            ],
            [
              -88.53353,
              46.019932
            ],
            [
              -88.533825,
              46.020915
            ],
            [
              -88.532414,
              46.021212
            ],
            [
              -88.526673,
              46.020822
            ],
            [
              -88.523131,
              46.019518
            ],
            [
              -88.514601,
              46.019926
            ],
            [
              -88.509516,
              46.019169
            ],
            [
              -88.507188,
              46.0183
            ],
            [
              -88.506205,
              46.017134
            ],
            [
              -88.505946,
              46.013385
            ],
            [
              -88.500133,
              46.000457
            ],
            [
              -88.498765,
              46.000393
            ],
            [
              -88.496898,
              45.999012
            ],
            [
              -88.496897,
              45.998281
            ],
            [
              -88.498108,
              45.99636
            ],
            [
              -88.497417,
              45.995149
            ],
            [
              -88.492495,
              45.992157
            ],
            [
              -88.486755,
              45.990949
            ],
            [
              -88.478984,
              45.991797
            ],
            [
              -88.476002,
              45.992826
            ],
            [
              -88.474036,
              45.994655
            ],
            [
              -88.475152,
              45.996598
            ],
            [
              -88.474695,
              45.99877
            ],
            [
              -88.470855,
              46.001004
            ],
            [
              -88.465542,
              46.000685
            ],
            [
              -88.458658,
              45.999391
            ],
            [
              -88.454361,
              45.997518
            ],
            [
              -88.453868,
              45.996169
            ],
            [
              -88.454261,
              45.993426
            ],
            [
              -88.450325,
              45.990181
            ],
            [
              -88.448751,
              45.98977
            ],
            [
              -88.443078,
              45.990685
            ],
            [
              -88.439733,
              45.990456
            ],
            [
              -88.435798,
              45.988125
            ],
            [
              -88.43406,
              45.986205
            ],
            [
              -88.426125,
              45.984102
            ],
            [
              -88.423437,
              45.98193
            ],
            [
              -88.422322,
              45.98017
            ],
            [
              -88.423044,
              45.978547
            ],
            [
              -88.420356,
              45.976764
            ],
            [
              -88.416914,
              45.975323
            ],
            [
              -88.414849,
              45.975483
            ],
            [
              -88.411077,
              45.979139
            ],
            [
              -88.409864,
              45.979688
            ],
            [
              -88.402848,
              45.981194
            ],
            [
              -88.399046,
              45.980278
            ],
            [
              -88.395308,
              45.980391
            ],
            [
              -88.388847,
              45.982675
            ],
            [
              -88.384318,
              45.988113
            ],
            [
              -88.385234,
              45.990239
            ],
            [
              -88.380183,
              45.991654
            ],
            [
              -88.334628,
              45.968808
            ],
            [
              -88.330137,
              45.965951
            ],
            [
              -88.328333,
              45.964054
            ],
            [
              -88.320531,
              45.959963
            ],
            [
              -88.316894,
              45.960969
            ],
            [
              -88.30952,
              45.959369
            ],
            [
              -88.300965,
              45.956168
            ],
            [
              -88.296968,
              45.953767
            ],
            [
              -88.295264,
              45.951253
            ],
            [
              -88.292381,
              45.951115
            ],
            [
              -88.283335,
              45.955091
            ],
            [
              -88.26839,
              45.957486
            ],
            [
              -88.259343,
              45.959494
            ],
            [
              -88.256455,
              45.962739
            ],
            [
              -88.254816,
              45.963538
            ],
            [
              -88.250133,
              45.963147
            ],
            [
              -88.250133,
              45.963572
            ],
            [
              -88.249117,
              45.963663
            ],
            [
              -88.246307,
              45.962983
            ],
            [
              -88.245937,
              45.958726
            ],
            [
              -88.246579,
              45.956597
            ],
            [
              -88.245752,
              45.954147
            ],
            [
              -88.244452,
              45.952142
            ],
            [
              -88.242518,
              45.950363
            ],
            [
              -88.239672,
              45.948982
            ],
            [
              -88.23314,
              45.947405
            ],
            [
              -88.227988,
              45.947688
            ],
            [
              -88.223773,
              45.948712
            ],
            [
              -88.222167,
              45.948513
            ],
            [
              -88.215025,
              45.946976
            ],
            [
              -88.211158,
              45.944531
            ],
            [
              -88.209585,
              45.94428
            ],
            [
              -88.201852,
              45.945173
            ],
            [
              -88.202116,
              45.949836
            ],
            [
              -88.197627,
              45.953082
            ],
            [
              -88.196316,
              45.953311
            ],
            [
              -88.191991,
              45.95274
            ],
            [
              -88.189789,
              45.952208
            ],
            [
              -88.178008,
              45.947111
            ],
            [
              -88.175532,
              45.944897
            ],
            [
              -88.172628,
              45.941015
            ],
            [
              -88.170096,
              45.93947
            ],
            [
              -88.163959,
              45.93834
            ],
            [
              -88.163105,
              45.939043
            ],
            [
              -88.158704,
              45.939064
            ],
            [
              -88.146352,
              45.935314
            ],
            [
              -88.146419,
              45.934194
            ],
            [
              -88.145928,
              45.933646
            ],
            [
              -88.141001,
              45.930608
            ],
            [
              -88.127428,
              45.926153
            ],
            [
              -88.126122,
              45.924639
            ],
            [
              -88.12743,
              45.923214
            ],
            [
              -88.127594,
              45.922414
            ],
            [
              -88.126382,
              45.921499
            ],
            [
              -88.121864,
              45.92075
            ],
            [
              -88.118507,
              45.92114
            ],
            [
              -88.1168457401249,
              45.9217028628049
            ],
            [
              -88.117407,
              46.246618
            ],
            [
              -88.116571,
              46.419951
            ],
            [
              -88.679392,
              46.420139
            ],
            [
              -88.991236,
              46.419704
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "139",
      "COUNTYNS": "01623012",
      "AFFGEOID": "0500000US26139",
      "GEOID": "26139",
      "NAME": "Ottawa",
      "LSAD": "06",
      "ALAND": 1459627896,
      "AWATER": 2765705477,
      "County_state": "Ottawa,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.27199581316529,
              43.118365119164196
            ],
            [
              -86.254646,
              43.083409
            ],
            [
              -86.250517,
              43.066993
            ],
            [
              -86.250069,
              43.057489
            ],
            [
              -86.244277,
              43.049681
            ],
            [
              -86.232707,
              43.015762
            ],
            [
              -86.2282146874249,
              42.9964805621778
            ],
            [
              -86.226305,
              42.988284
            ],
            [
              -86.216209,
              42.919007
            ],
            [
              -86.21543332019809,
              42.905728680184296
            ],
            [
              -86.214138,
              42.883555
            ],
            [
              -86.210737,
              42.859128
            ],
            [
              -86.211815,
              42.833236
            ],
            [
              -86.210863,
              42.783832
            ],
            [
              -86.2088855875804,
              42.7675396391759
            ],
            [
              -86.166564,
              42.768143
            ],
            [
              -86.137046,
              42.768342
            ],
            [
              -86.126702,
              42.768402
            ],
            [
              -85.881053,
              42.767498
            ],
            [
              -85.782498,
              42.7682
            ],
            [
              -85.782106,
              42.856296
            ],
            [
              -85.782276,
              42.877418
            ],
            [
              -85.782227,
              42.914518
            ],
            [
              -85.788503,
              43.030877
            ],
            [
              -85.790662,
              43.205167
            ],
            [
              -85.908388,
              43.20592
            ],
            [
              -85.907435,
              43.118911
            ],
            [
              -86.027027,
              43.118272
            ],
            [
              -86.27199581316529,
              43.118365119164196
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "055",
      "COUNTYNS": "01622970",
      "AFFGEOID": "0500000US26055",
      "GEOID": "26055",
      "NAME": "Grand Traverse",
      "LSAD": "06",
      "ALAND": 1202639650,
      "AWATER": 354756663,
      "County_state": "Grand Traverse,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.815119,
              44.774534
            ],
            [
              -85.817802,
              44.512969
            ],
            [
              -85.334,
              44.512303
            ],
            [
              -85.332836,
              44.812372
            ],
            [
              -85.348542,
              44.827811
            ],
            [
              -85.37506,
              44.829325
            ],
            [
              -85.381983,
              44.859568
            ],
            [
              -85.4422502592113,
              44.85981687981659
            ],
            [
              -85.460445,
              44.835667
            ],
            [
              -85.462916,
              44.825067
            ],
            [
              -85.474796,
              44.814959
            ],
            [
              -85.499591,
              44.803838
            ],
            [
              -85.509251,
              44.787334
            ],
            [
              -85.505244,
              44.781594
            ],
            [
              -85.503935,
              44.772951
            ],
            [
              -85.504775,
              44.768082
            ],
            [
              -85.527216,
              44.748235
            ],
            [
              -85.538285,
              44.746821
            ],
            [
              -85.554326,
              44.748744
            ],
            [
              -85.571301,
              44.755293
            ],
            [
              -85.576566,
              44.760208
            ],
            [
              -85.560488,
              44.789679
            ],
            [
              -85.560231,
              44.810072
            ],
            [
              -85.555894,
              44.818256
            ],
            [
              -85.539924,
              44.834166
            ],
            [
              -85.519096,
              44.845339
            ],
            [
              -85.508617,
              44.847872
            ],
            [
              -85.502182,
              44.855802
            ],
            [
              -85.498007,
              44.865451
            ],
            [
              -85.488624,
              44.901707
            ],
            [
              -85.49249,
              44.90822
            ],
            [
              -85.491286,
              44.927585
            ],
            [
              -85.48574,
              44.953626
            ],
            [
              -85.472258,
              44.959391
            ],
            [
              -85.46665,
              44.958844
            ],
            [
              -85.464944,
              44.961062
            ],
            [
              -85.470462,
              44.980745
            ],
            [
              -85.475204,
              44.991053
            ],
            [
              -85.4926,
              44.989834
            ],
            [
              -85.520034,
              44.973996
            ],
            [
              -85.5221,
              44.966727
            ],
            [
              -85.520205,
              44.960347
            ],
            [
              -85.533553,
              44.925762
            ],
            [
              -85.539703,
              44.916779
            ],
            [
              -85.564509,
              44.895246
            ],
            [
              -85.559524,
              44.888113
            ],
            [
              -85.553509,
              44.890924
            ],
            [
              -85.530649,
              44.889763
            ],
            [
              -85.532931,
              44.87319
            ],
            [
              -85.545891,
              44.864024
            ],
            [
              -85.581717,
              44.807784
            ],
            [
              -85.590985,
              44.783914
            ],
            [
              -85.593571,
              44.768783
            ],
            [
              -85.599256,
              44.765919
            ],
            [
              -85.610776,
              44.76516
            ],
            [
              -85.627982,
              44.767508
            ],
            [
              -85.636097,
              44.771329
            ],
            [
              -85.6402156770347,
              44.7750502299767
            ],
            [
              -85.815119,
              44.774534
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "093",
      "COUNTYNS": "01622989",
      "AFFGEOID": "0500000US26093",
      "GEOID": "26093",
      "NAME": "Livingston",
      "LSAD": "06",
      "ALAND": 1463693558,
      "AWATER": 52500785,
      "County_state": "Livingston,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.158189,
              42.776639
            ],
            [
              -84.150279,
              42.6852
            ],
            [
              -84.144834,
              42.599563
            ],
            [
              -84.140601,
              42.508394
            ],
            [
              -84.140713,
              42.424611
            ],
            [
              -84.131136,
              42.424567
            ],
            [
              -84.023402,
              42.42393
            ],
            [
              -83.94465,
              42.424149
            ],
            [
              -83.664808,
              42.431179
            ],
            [
              -83.670904,
              42.519609
            ],
            [
              -83.677115,
              42.606255
            ],
            [
              -83.68278,
              42.69558
            ],
            [
              -83.686493,
              42.783263
            ],
            [
              -83.746057,
              42.78227
            ],
            [
              -83.922516,
              42.780821
            ],
            [
              -84.158189,
              42.776639
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "147",
      "COUNTYNS": "01625033",
      "AFFGEOID": "0500000US26147",
      "GEOID": "26147",
      "NAME": "St. Clair",
      "LSAD": "06",
      "ALAND": 1867864472,
      "AWATER": 298677842,
      "County_state": "St. Clair,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.996257,
              43.154099
            ],
            [
              -82.983647,
              42.893741
            ],
            [
              -82.865664,
              42.895246
            ],
            [
              -82.73901,
              42.897541
            ],
            [
              -82.733357,
              42.792729
            ],
            [
              -82.729964,
              42.720843
            ],
            [
              -82.729277,
              42.706059
            ],
            [
              -82.705966,
              42.68631
            ],
            [
              -82.7078409351836,
              42.6835096985073
            ],
            [
              -82.706135,
              42.683578
            ],
            [
              -82.700964,
              42.689548
            ],
            [
              -82.6855,
              42.690036
            ],
            [
              -82.674287,
              42.687049
            ],
            [
              -82.659781,
              42.678618
            ],
            [
              -82.653241,
              42.672232
            ],
            [
              -82.641479,
              42.675095
            ],
            [
              -82.625997,
              42.667873
            ],
            [
              -82.625529252058,
              42.664883153435
            ],
            [
              -82.623821,
              42.653964
            ],
            [
              -82.630922,
              42.64211
            ],
            [
              -82.640391,
              42.641808
            ],
            [
              -82.65289,
              42.650577
            ],
            [
              -82.668116,
              42.639192
            ],
            [
              -82.664628,
              42.627678
            ],
            [
              -82.675351,
              42.62671
            ],
            [
              -82.689836,
              42.627148
            ],
            [
              -82.690124,
              42.625033
            ],
            [
              -82.681593,
              42.618672
            ],
            [
              -82.679391,
              42.612281
            ],
            [
              -82.687116,
              42.60642
            ],
            [
              -82.700818,
              42.606687
            ],
            [
              -82.713042,
              42.597904
            ],
            [
              -82.711151,
              42.590884
            ],
            [
              -82.702518,
              42.586243
            ],
            [
              -82.689514,
              42.58876
            ],
            [
              -82.681036,
              42.574695
            ],
            [
              -82.692701,
              42.557944
            ],
            [
              -82.683313,
              42.555493
            ],
            [
              -82.686417,
              42.518597
            ],
            [
              -82.679059,
              42.52221
            ],
            [
              -82.666596,
              42.535084
            ],
            [
              -82.661677,
              42.541875
            ],
            [
              -82.648776,
              42.550401
            ],
            [
              -82.64268,
              42.554333
            ],
            [
              -82.640916,
              42.554973
            ],
            [
              -82.633491,
              42.557051
            ],
            [
              -82.624907,
              42.557229
            ],
            [
              -82.616848,
              42.554601
            ],
            [
              -82.611059,
              42.550419
            ],
            [
              -82.607068,
              42.548843
            ],
            [
              -82.604686,
              42.548592
            ],
            [
              -82.589779,
              42.550678
            ],
            [
              -82.583996,
              42.554041
            ],
            [
              -82.579205,
              42.56534
            ],
            [
              -82.57738,
              42.567078
            ],
            [
              -82.569801,
              42.573551
            ],
            [
              -82.555938,
              42.582425
            ],
            [
              -82.554236,
              42.583981
            ],
            [
              -82.549717,
              42.590338
            ],
            [
              -82.548169,
              42.591848
            ],
            [
              -82.523337,
              42.607486
            ],
            [
              -82.518782,
              42.613888
            ],
            [
              -82.509935,
              42.637294
            ],
            [
              -82.510533,
              42.665172
            ],
            [
              -82.494491,
              42.700823
            ],
            [
              -82.48387,
              42.71798
            ],
            [
              -82.483604,
              42.733624
            ],
            [
              -82.467483,
              42.76191
            ],
            [
              -82.467394,
              42.769298
            ],
            [
              -82.471159,
              42.784002
            ],
            [
              -82.480394,
              42.802272
            ],
            [
              -82.481576,
              42.805519
            ],
            [
              -82.482045,
              42.808629
            ],
            [
              -82.47864,
              42.825187
            ],
            [
              -82.472681,
              42.836784
            ],
            [
              -82.468961,
              42.852314
            ],
            [
              -82.46822,
              42.859107
            ],
            [
              -82.470032,
              42.881421
            ],
            [
              -82.469912,
              42.887459
            ],
            [
              -82.46404,
              42.901456
            ],
            [
              -82.455027,
              42.926866
            ],
            [
              -82.4476251228702,
              42.9370850024648
            ],
            [
              -82.447142,
              42.937752
            ],
            [
              -82.428603,
              42.952001
            ],
            [
              -82.416737,
              42.966613
            ],
            [
              -82.412965,
              42.977041
            ],
            [
              -82.420346,
              42.984451
            ],
            [
              -82.423086,
              42.988728
            ],
            [
              -82.42455,
              42.993393
            ],
            [
              -82.424206,
              42.996938
            ],
            [
              -82.422586,
              43.000029
            ],
            [
              -82.415937,
              43.005555
            ],
            [
              -82.422768,
              43.007956
            ],
            [
              -82.457221,
              43.061285
            ],
            [
              -82.4573185212759,
              43.0614703975904
            ],
            [
              -82.471053,
              43.087581
            ],
            [
              -82.486042,
              43.102486
            ],
            [
              -82.4866838647596,
              43.1046881633025
            ],
            [
              -82.490614,
              43.118172
            ],
            [
              -82.494194,
              43.143736
            ],
            [
              -82.501656,
              43.161656
            ],
            [
              -82.50315682967,
              43.1689455660594
            ],
            [
              -82.544925,
              43.16751
            ],
            [
              -82.996257,
              43.154099
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "163",
      "COUNTYNS": "01623022",
      "AFFGEOID": "0500000US26163",
      "GEOID": "26163",
      "NAME": "Wayne",
      "LSAD": "06",
      "ALAND": 1585060122,
      "AWATER": 157341390,
      "County_state": "Wayne,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.551907,
              42.435166
            ],
            [
              -83.547548,
              42.349217
            ],
            [
              -83.543728,
              42.262462
            ],
            [
              -83.542102,
              42.174344
            ],
            [
              -83.539396,
              42.085598
            ],
            [
              -83.420622,
              42.087777
            ],
            [
              -83.420761,
              42.093568
            ],
            [
              -83.303885,
              42.094241
            ],
            [
              -83.283512,
              42.089737
            ],
            [
              -83.264314,
              42.069283
            ],
            [
              -83.225464,
              42.049104
            ],
            [
              -83.219674,
              42.04912
            ],
            [
              -83.18582193245518,
              42.0293674760258
            ],
            [
              -83.185858,
              42.029451
            ],
            [
              -83.18824,
              42.031329
            ],
            [
              -83.185526,
              42.052243
            ],
            [
              -83.186877,
              42.061206
            ],
            [
              -83.189115,
              42.061853
            ],
            [
              -83.188598,
              42.066431
            ],
            [
              -83.170589110798,
              42.0729395808548
            ],
            [
              -83.168759,
              42.073601
            ],
            [
              -83.157624,
              42.085542
            ],
            [
              -83.133511,
              42.088143
            ],
            [
              -83.121323,
              42.125742
            ],
            [
              -83.133923,
              42.17474
            ],
            [
              -83.1313434350721,
              42.202760256281096
            ],
            [
              -83.12839649351059,
              42.2347711031117
            ],
            [
              -83.128022,
              42.238839
            ],
            [
              -83.110922,
              42.260638
            ],
            [
              -83.098542223785,
              42.28599758602469
            ],
            [
              -83.096521,
              42.290138
            ],
            [
              -83.079721,
              42.308638
            ],
            [
              -83.064121,
              42.317738
            ],
            [
              -83.01832,
              42.329739
            ],
            [
              -82.988619,
              42.332439
            ],
            [
              -82.959416,
              42.339638
            ],
            [
              -82.945415,
              42.347337
            ],
            [
              -82.92397,
              42.352068
            ],
            [
              -82.9240184872606,
              42.3521417466715
            ],
            [
              -82.928815,
              42.359437
            ],
            [
              -82.919114,
              42.374437
            ],
            [
              -82.915114,
              42.378137
            ],
            [
              -82.898413,
              42.385437
            ],
            [
              -82.894013,
              42.389437
            ],
            [
              -82.888413,
              42.398237
            ],
            [
              -82.886113,
              42.408137
            ],
            [
              -82.88337375602309,
              42.415564719095194
            ],
            [
              -82.870347,
              42.450888
            ],
            [
              -82.870426085074,
              42.451010101572095
            ],
            [
              -82.928579,
              42.45062
            ],
            [
              -82.935403,
              42.450159
            ],
            [
              -82.967938,
              42.449836
            ],
            [
              -82.971041,
              42.449665
            ],
            [
              -83.006073,
              42.448784
            ],
            [
              -83.024745,
              42.448159
            ],
            [
              -83.083393,
              42.447153
            ],
            [
              -83.14206,
              42.446229
            ],
            [
              -83.151986,
              42.446007
            ],
            [
              -83.16696,
              42.445683
            ],
            [
              -83.181005,
              42.445343
            ],
            [
              -83.200522,
              42.444839
            ],
            [
              -83.203558,
              42.444748
            ],
            [
              -83.316825,
              42.442033
            ],
            [
              -83.367896,
              42.440774
            ],
            [
              -83.375469,
              42.440557
            ],
            [
              -83.433672,
              42.438942
            ],
            [
              -83.433734,
              42.43894
            ],
            [
              -83.551907,
              42.435166
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "027",
      "COUNTYNS": "01622956",
      "AFFGEOID": "0500000US26027",
      "GEOID": "26027",
      "NAME": "Cass",
      "LSAD": "06",
      "ALAND": 1269334177,
      "AWATER": 47180840,
      "County_state": "Cass,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.22609688873129,
              41.760016130263004
            ],
            [
              -86.22607,
              41.760016
            ],
            [
              -86.21759,
              41.760016
            ],
            [
              -86.178753691729,
              41.760265255828294
            ],
            [
              -86.127844,
              41.760592
            ],
            [
              -86.12546,
              41.76056
            ],
            [
              -86.12506,
              41.760576
            ],
            [
              -86.086463628697,
              41.7605466047891
            ],
            [
              -86.0625749882628,
              41.7605284110677
            ],
            [
              -86.041027,
              41.760512
            ],
            [
              -85.9913794053612,
              41.7599498764046
            ],
            [
              -85.991302,
              41.759949
            ],
            [
              -85.97498,
              41.759849
            ],
            [
              -85.974901,
              41.759849
            ],
            [
              -85.888825,
              41.759422
            ],
            [
              -85.8887690751255,
              41.75942167241
            ],
            [
              -85.874997,
              41.759341
            ],
            [
              -85.872041,
              41.759365
            ],
            [
              -85.791363,
              41.759051
            ],
            [
              -85.79133484218919,
              41.7590511655936
            ],
            [
              -85.784807,
              41.759528
            ],
            [
              -85.785875,
              41.77729
            ],
            [
              -85.768832,
              41.785386
            ],
            [
              -85.760186,
              41.798814
            ],
            [
              -85.762943,
              42.069327
            ],
            [
              -86.107885,
              42.070802
            ],
            [
              -86.22294,
              42.071484
            ],
            [
              -86.222821,
              41.984593
            ],
            [
              -86.22345,
              41.898721
            ],
            [
              -86.22609688873129,
              41.760016130263004
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "081",
      "COUNTYNS": "01622983",
      "AFFGEOID": "0500000US26081",
      "GEOID": "26081",
      "NAME": "Kent",
      "LSAD": "06",
      "ALAND": 2195420014,
      "AWATER": 62899810,
      "County_state": "Kent,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.790448,
              43.293003
            ],
            [
              -85.790662,
              43.205167
            ],
            [
              -85.788503,
              43.030877
            ],
            [
              -85.782227,
              42.914518
            ],
            [
              -85.782276,
              42.877418
            ],
            [
              -85.782106,
              42.856296
            ],
            [
              -85.782498,
              42.7682
            ],
            [
              -85.663296,
              42.768099
            ],
            [
              -85.545564,
              42.768139
            ],
            [
              -85.309626,
              42.769878
            ],
            [
              -85.311933,
              42.943988
            ],
            [
              -85.312393,
              43.02973
            ],
            [
              -85.312513,
              43.118793
            ],
            [
              -85.31225,
              43.206438
            ],
            [
              -85.312376,
              43.294205
            ],
            [
              -85.551778,
              43.294288
            ],
            [
              -85.562538,
              43.294271
            ],
            [
              -85.790448,
              43.293003
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "029",
      "COUNTYNS": "01622957",
      "AFFGEOID": "0500000US26029",
      "GEOID": "26029",
      "NAME": "Charlevoix",
      "LSAD": "06",
      "ALAND": 1078286047,
      "AWATER": 2522933853,
      "County_state": "Charlevoix,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.388593,
              45.23524
            ],
            [
              -85.38747191734359,
              45.207772574210196
            ],
            [
              -85.222707,
              45.20556
            ],
            [
              -85.223681,
              45.117861
            ],
            [
              -84.857452,
              45.116707
            ],
            [
              -84.734799,
              45.114903
            ],
            [
              -84.734303,
              45.201936
            ],
            [
              -84.732687,
              45.289382
            ],
            [
              -84.964787,
              45.291601
            ],
            [
              -84.965066,
              45.271222
            ],
            [
              -85.001803,
              45.272328
            ],
            [
              -85.019077,
              45.290127
            ],
            [
              -85.022839,
              45.310089
            ],
            [
              -85.045967,
              45.334655
            ],
            [
              -85.096533,
              45.335743
            ],
            [
              -85.0959851261272,
              45.3670008533623
            ],
            [
              -85.143651,
              45.370369
            ],
            [
              -85.182471,
              45.360824
            ],
            [
              -85.196704,
              45.360641
            ],
            [
              -85.209673,
              45.356937
            ],
            [
              -85.235629,
              45.339374
            ],
            [
              -85.252193,
              45.330863
            ],
            [
              -85.25505,
              45.325675
            ],
            [
              -85.262996,
              45.319507
            ],
            [
              -85.273789,
              45.315443
            ],
            [
              -85.289568,
              45.314052
            ],
            [
              -85.294848,
              45.316408
            ],
            [
              -85.307646,
              45.31314
            ],
            [
              -85.323941,
              45.303355
            ],
            [
              -85.335016,
              45.294027
            ],
            [
              -85.355478,
              45.282774
            ],
            [
              -85.371593,
              45.270834
            ],
            [
              -85.388593,
              45.23524
            ]
          ]
        ],
        [
          [
            [
              -85.377132,
              45.812795
            ],
            [
              -85.394264,
              45.778531
            ],
            [
              -85.377132,
              45.769013
            ],
            [
              -85.359048,
              45.776627
            ],
            [
              -85.351434,
              45.795663
            ],
            [
              -85.360952,
              45.817554
            ],
            [
              -85.377132,
              45.812795
            ]
          ]
        ],
        [
          [
            [
              -85.524448,
              45.829794
            ],
            [
              -85.532009,
              45.798172
            ],
            [
              -85.507263,
              45.778237
            ],
            [
              -85.462581,
              45.765864
            ],
            [
              -85.450206,
              45.776452
            ],
            [
              -85.450206,
              45.796677
            ],
            [
              -85.496951,
              45.822232
            ],
            [
              -85.524448,
              45.829794
            ]
          ]
        ],
        [
          [
            [
              -85.630016,
              45.598166
            ],
            [
              -85.622741,
              45.586028
            ],
            [
              -85.618049,
              45.582647
            ],
            [
              -85.561634,
              45.572213
            ],
            [
              -85.541129,
              45.575045
            ],
            [
              -85.534064,
              45.578198
            ],
            [
              -85.530273,
              45.589253
            ],
            [
              -85.526895,
              45.59159
            ],
            [
              -85.518038,
              45.592912
            ],
            [
              -85.509276,
              45.596475
            ],
            [
              -85.491347,
              45.609665
            ],
            [
              -85.487026,
              45.621211
            ],
            [
              -85.490252,
              45.652122
            ],
            [
              -85.500451,
              45.664298
            ],
            [
              -85.503767,
              45.670472
            ],
            [
              -85.506104,
              45.681148
            ],
            [
              -85.5028,
              45.690998
            ],
            [
              -85.494016,
              45.698476
            ],
            [
              -85.494154,
              45.705378
            ],
            [
              -85.498777,
              45.726291
            ],
            [
              -85.510895,
              45.734414
            ],
            [
              -85.515678,
              45.735782
            ],
            [
              -85.508818,
              45.742358
            ],
            [
              -85.503758,
              45.742771
            ],
            [
              -85.497656,
              45.746246
            ],
            [
              -85.501267,
              45.754415
            ],
            [
              -85.506133,
              45.754715
            ],
            [
              -85.525237,
              45.750462
            ],
            [
              -85.53562,
              45.750394
            ],
            [
              -85.54375,
              45.751413
            ],
            [
              -85.54956,
              45.757266
            ],
            [
              -85.566441,
              45.760222
            ],
            [
              -85.567781,
              45.757655
            ],
            [
              -85.567128,
              45.750419
            ],
            [
              -85.564774,
              45.745462
            ],
            [
              -85.565132,
              45.730719
            ],
            [
              -85.572309,
              45.711449
            ],
            [
              -85.583724,
              45.700796
            ],
            [
              -85.590769,
              45.698051
            ],
            [
              -85.600842,
              45.68886
            ],
            [
              -85.604881,
              45.681932
            ],
            [
              -85.609295,
              45.658067
            ],
            [
              -85.604951,
              45.647599
            ],
            [
              -85.604521,
              45.639256
            ],
            [
              -85.608653,
              45.632008
            ],
            [
              -85.61985,
              45.624547
            ],
            [
              -85.630016,
              45.598166
            ]
          ]
        ],
        [
          [
            [
              -85.701809,
              45.736129
            ],
            [
              -85.696872,
              45.69725
            ],
            [
              -85.672187,
              45.696633
            ],
            [
              -85.649353,
              45.722552
            ],
            [
              -85.651866,
              45.743139
            ],
            [
              -85.688849,
              45.747238
            ],
            [
              -85.701809,
              45.736129
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "091",
      "COUNTYNS": "01622988",
      "AFFGEOID": "0500000US26091",
      "GEOID": "26091",
      "NAME": "Lenawee",
      "LSAD": "06",
      "ALAND": 1941336570,
      "AWATER": 30687108,
      "County_state": "Lenawee,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.363297,
              42.073456
            ],
            [
              -84.3604162952785,
              41.7066246193358
            ],
            [
              -84.134417,
              41.712931
            ],
            [
              -84.019373,
              41.716668
            ],
            [
              -83.998849,
              41.716822
            ],
            [
              -83.899764,
              41.719961
            ],
            [
              -83.880539,
              41.720081
            ],
            [
              -83.8803872579474,
              41.720089447778804
            ],
            [
              -83.859541,
              41.72125
            ],
            [
              -83.7631498887765,
              41.7235473333038
            ],
            [
              -83.773922,
              42.08243
            ],
            [
              -84.008337,
              42.078005
            ],
            [
              -84.131963,
              42.071577
            ],
            [
              -84.179835,
              42.073791
            ],
            [
              -84.247627,
              42.073266
            ],
            [
              -84.363297,
              42.073456
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "033",
      "COUNTYNS": "01622959",
      "AFFGEOID": "0500000US26033",
      "GEOID": "26033",
      "NAME": "Chippewa",
      "LSAD": "06",
      "ALAND": 4036522461,
      "AWATER": 2952482167,
      "County_state": "Chippewa,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.009664,
              46.101332
            ],
            [
              -84.012634,
              46.08728
            ],
            [
              -83.995794,
              46.076005
            ],
            [
              -83.97345,
              46.065285
            ],
            [
              -83.974012,
              46.081552
            ],
            [
              -83.983643,
              46.08865
            ],
            [
              -83.987684,
              46.098292
            ],
            [
              -84.009664,
              46.101332
            ]
          ]
        ],
        [
          [
            [
              -85.23787317735959,
              46.7557027812565
            ],
            [
              -85.237839,
              46.24543
            ],
            [
              -85.113329,
              46.245722
            ],
            [
              -85.112513,
              46.158263
            ],
            [
              -84.363503,
              46.157984
            ],
            [
              -84.36309,
              46.071574
            ],
            [
              -84.238884,
              46.071457
            ],
            [
              -84.239332,
              45.984914
            ],
            [
              -84.114186,
              45.985152
            ],
            [
              -84.1142838818021,
              45.978321752175006
            ],
            [
              -84.111174,
              45.978675
            ],
            [
              -84.107204,
              45.977161
            ],
            [
              -84.10537,
              45.972948
            ],
            [
              -84.090391,
              45.967256
            ],
            [
              -84.080071,
              45.970822
            ],
            [
              -84.017565,
              45.959046
            ],
            [
              -83.996471,
              45.961461
            ],
            [
              -83.985141,
              45.967133
            ],
            [
              -83.952183,
              45.965498
            ],
            [
              -83.921257,
              45.958075
            ],
            [
              -83.910838,
              45.965613
            ],
            [
              -83.881055,
              45.968185
            ],
            [
              -83.879616,
              45.966196
            ],
            [
              -83.864859,
              45.959465
            ],
            [
              -83.846437,
              45.953181
            ],
            [
              -83.840869,
              45.952726
            ],
            [
              -83.835505,
              45.941843
            ],
            [
              -83.827566,
              45.941236
            ],
            [
              -83.82281,
              45.943985
            ],
            [
              -83.808147,
              45.945693
            ],
            [
              -83.803332,
              45.943362
            ],
            [
              -83.80104,
              45.937582
            ],
            [
              -83.78611,
              45.933375
            ],
            [
              -83.768854,
              45.932069
            ],
            [
              -83.766233,
              45.935221
            ],
            [
              -83.742778,
              45.938002
            ],
            [
              -83.732986,
              45.937641
            ],
            [
              -83.719433,
              45.934078
            ],
            [
              -83.687695,
              45.935389
            ],
            [
              -83.65766,
              45.945463
            ],
            [
              -83.632214,
              45.932287
            ],
            [
              -83.583052,
              45.915919
            ],
            [
              -83.561838,
              45.912562
            ],
            [
              -83.526347,
              45.918636
            ],
            [
              -83.517242,
              45.923614
            ],
            [
              -83.510623,
              45.929324
            ],
            [
              -83.488809,
              45.96874
            ],
            [
              -83.481765,
              45.971873
            ],
            [
              -83.473221,
              45.984422
            ],
            [
              -83.473946,
              45.988558
            ],
            [
              -83.480639,
              45.996164
            ],
            [
              -83.488348,
              45.999543
            ],
            [
              -83.49484,
              45.999541
            ],
            [
              -83.532913,
              46.011328
            ],
            [
              -83.540845,
              46.021247
            ],
            [
              -83.543366,
              46.037196
            ],
            [
              -83.547202,
              46.047868
            ],
            [
              -83.554059,
              46.058882
            ],
            [
              -83.565351,
              46.061898
            ],
            [
              -83.572574,
              46.069895
            ],
            [
              -83.57264,
              46.074922
            ],
            [
              -83.57609,
              46.083513
            ],
            [
              -83.581315,
              46.089613
            ],
            [
              -83.598612,
              46.090085
            ],
            [
              -83.615343,
              46.095976
            ],
            [
              -83.625554,
              46.102212
            ],
            [
              -83.63498,
              46.103953
            ],
            [
              -83.661161,
              46.100258
            ],
            [
              -83.703861,
              46.103366
            ],
            [
              -83.719791,
              46.101031
            ],
            [
              -83.7233,
              46.09381
            ],
            [
              -83.728165,
              46.090957
            ],
            [
              -83.755991,
              46.092159
            ],
            [
              -83.771821,
              46.090999
            ],
            [
              -83.779996,
              46.093515
            ],
            [
              -83.792867,
              46.101971
            ],
            [
              -83.81241,
              46.108598
            ],
            [
              -83.815826,
              46.108529
            ],
            [
              -83.824036,
              46.103638
            ],
            [
              -83.81252,
              46.073469
            ],
            [
              -83.796555,
              46.056688
            ],
            [
              -83.773785,
              46.051471
            ],
            [
              -83.765233,
              46.031935
            ],
            [
              -83.765277,
              46.018363
            ],
            [
              -83.776436,
              46.004202
            ],
            [
              -83.794055,
              45.995801
            ],
            [
              -83.818202,
              46.002425
            ],
            [
              -83.830146,
              46.022324
            ],
            [
              -83.845399,
              46.025679
            ],
            [
              -83.868233,
              45.995075
            ],
            [
              -83.873147,
              45.993426
            ],
            [
              -83.900535,
              45.998918
            ],
            [
              -83.908583,
              46.011471
            ],
            [
              -83.931175,
              46.017871
            ],
            [
              -83.93547,
              46.020385
            ],
            [
              -83.939012,
              46.029226
            ],
            [
              -83.943933,
              46.031465
            ],
            [
              -83.95141,
              46.029042
            ],
            [
              -83.963808,
              46.027833
            ],
            [
              -83.989526,
              46.032823
            ],
            [
              -84.006082,
              46.044586
            ],
            [
              -84.027861,
              46.054784
            ],
            [
              -84.051712,
              46.079189
            ],
            [
              -84.066257,
              46.087438
            ],
            [
              -84.071741,
              46.092441
            ],
            [
              -84.072398,
              46.09669
            ],
            [
              -84.069147,
              46.103978
            ],
            [
              -84.061329,
              46.113482
            ],
            [
              -84.0519,
              46.11981
            ],
            [
              -84.038696,
              46.12562
            ],
            [
              -84.031036,
              46.123186
            ],
            [
              -84.026536,
              46.131648
            ],
            [
              -84.060383,
              46.146138
            ],
            [
              -84.089309,
              46.146432
            ],
            [
              -84.095818,
              46.147733
            ],
            [
              -84.100126,
              46.15077
            ],
            [
              -84.113259,
              46.16886
            ],
            [
              -84.114941,
              46.174114
            ],
            [
              -84.125022,
              46.180209
            ],
            [
              -84.17164,
              46.181731
            ],
            [
              -84.177298,
              46.183993
            ],
            [
              -84.196669,
              46.16615
            ],
            [
              -84.221001,
              46.163062
            ],
            [
              -84.251424,
              46.175888
            ],
            [
              -84.247687,
              46.17989
            ],
            [
              -84.245233,
              46.192571
            ],
            [
              -84.249164,
              46.206461
            ],
            [
              -84.233117,
              46.224037
            ],
            [
              -84.219494,
              46.231992
            ],
            [
              -84.182732,
              46.23545
            ],
            [
              -84.159485,
              46.233233
            ],
            [
              -84.152499,
              46.227875
            ],
            [
              -84.15223,
              46.226254
            ],
            [
              -84.152042,
              46.224937
            ],
            [
              -84.151666,
              46.224184
            ],
            [
              -84.150725,
              46.223808
            ],
            [
              -84.14922,
              46.223808
            ],
            [
              -84.14715,
              46.224184
            ],
            [
              -84.14595,
              46.224995
            ],
            [
              -84.134652,
              46.23214
            ],
            [
              -84.125024,
              46.232885
            ],
            [
              -84.118175,
              46.233968
            ],
            [
              -84.108089,
              46.241238
            ],
            [
              -84.097766,
              46.256512
            ],
            [
              -84.115563,
              46.268225
            ],
            [
              -84.119629,
              46.315013
            ],
            [
              -84.106247,
              46.321963
            ],
            [
              -84.119122,
              46.337014
            ],
            [
              -84.138906,
              46.372221
            ],
            [
              -84.146172,
              46.41852
            ],
            [
              -84.125026,
              46.470143
            ],
            [
              -84.111225,
              46.504119
            ],
            [
              -84.117925,
              46.517619
            ],
            [
              -84.123325,
              46.520919
            ],
            [
              -84.128925,
              46.530119
            ],
            [
              -84.139426,
              46.532219
            ],
            [
              -84.146526,
              46.531119
            ],
            [
              -84.153027,
              46.52832
            ],
            [
              -84.166028,
              46.52622
            ],
            [
              -84.177428,
              46.52692
            ],
            [
              -84.193729,
              46.53992
            ],
            [
              -84.226131,
              46.53392
            ],
            [
              -84.254434,
              46.500821
            ],
            [
              -84.264266,
              46.495055
            ],
            [
              -84.265391,
              46.494393
            ],
            [
              -84.275814,
              46.492821
            ],
            [
              -84.293016,
              46.492803
            ],
            [
              -84.325371,
              46.500021
            ],
            [
              -84.337732,
              46.505577
            ],
            [
              -84.343599,
              46.507713
            ],
            [
              -84.373968,
              46.509098
            ],
            [
              -84.37504,
              46.508669
            ],
            [
              -84.394725,
              46.499242
            ],
            [
              -84.420274,
              46.501077
            ],
            [
              -84.445149,
              46.489016
            ],
            [
              -84.463322,
              46.467435
            ],
            [
              -84.455256,
              46.462785
            ],
            [
              -84.455527,
              46.453897
            ],
            [
              -84.462597,
              46.44094
            ],
            [
              -84.471848,
              46.434289
            ],
            [
              -84.479513,
              46.432573
            ],
            [
              -84.493401,
              46.440313
            ],
            [
              -84.503719,
              46.43919
            ],
            [
              -84.551496,
              46.418522
            ],
            [
              -84.583309,
              46.413505
            ],
            [
              -84.596263,
              46.415144
            ],
            [
              -84.60108,
              46.424444
            ],
            [
              -84.604225,
              46.431774
            ],
            [
              -84.599132,
              46.440212
            ],
            [
              -84.591874,
              46.445324
            ],
            [
              -84.607945,
              46.456747
            ],
            [
              -84.616489,
              46.47187
            ],
            [
              -84.63102,
              46.484868
            ],
            [
              -84.65388,
              46.48225
            ],
            [
              -84.678423,
              46.487694
            ],
            [
              -84.689672,
              46.483923
            ],
            [
              -84.723338,
              46.468266
            ],
            [
              -84.769151,
              46.453523
            ],
            [
              -84.800101,
              46.446219
            ],
            [
              -84.829491,
              46.444071
            ],
            [
              -84.843907,
              46.448661
            ],
            [
              -84.849767,
              46.460245
            ],
            [
              -84.861448,
              46.46993
            ],
            [
              -84.87507,
              46.466781
            ],
            [
              -84.893423,
              46.465406
            ],
            [
              -84.915184,
              46.467515
            ],
            [
              -84.921931,
              46.469962
            ],
            [
              -84.934432,
              46.480315
            ],
            [
              -84.937145,
              46.489252
            ],
            [
              -84.947269,
              46.487399
            ],
            [
              -84.955307,
              46.480269
            ],
            [
              -84.969464,
              46.47629
            ],
            [
              -85.015211,
              46.479712
            ],
            [
              -85.025598,
              46.483028
            ],
            [
              -85.033766,
              46.48767
            ],
            [
              -85.049847,
              46.503963
            ],
            [
              -85.054943,
              46.51475
            ],
            [
              -85.056133,
              46.52652
            ],
            [
              -85.052954,
              46.532827
            ],
            [
              -85.045534,
              46.537694
            ],
            [
              -85.027083,
              46.543038
            ],
            [
              -85.025491,
              46.546397
            ],
            [
              -85.027374,
              46.553756
            ],
            [
              -85.029594,
              46.554419
            ],
            [
              -85.031507,
              46.568703
            ],
            [
              -85.035476,
              46.581547
            ],
            [
              -85.037056,
              46.600995
            ],
            [
              -85.035504,
              46.625021
            ],
            [
              -85.028291,
              46.675125
            ],
            [
              -85.030078,
              46.684769
            ],
            [
              -85.027513,
              46.697451
            ],
            [
              -85.020159,
              46.712463
            ],
            [
              -85.007616,
              46.728339
            ],
            [
              -84.987539,
              46.745483
            ],
            [
              -84.95158,
              46.769488
            ],
            [
              -84.954009,
              46.771362
            ],
            [
              -84.964652,
              46.772845
            ],
            [
              -84.989497,
              46.772403
            ],
            [
              -85.00924,
              46.769224
            ],
            [
              -85.036286,
              46.760435
            ],
            [
              -85.063556,
              46.757856
            ],
            [
              -85.173042,
              46.763634
            ],
            [
              -85.23787317735959,
              46.7557027812565
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "097",
      "COUNTYNS": "01622991",
      "AFFGEOID": "0500000US26097",
      "GEOID": "26097",
      "NAME": "Mackinac",
      "LSAD": "06",
      "ALAND": 2646602337,
      "AWATER": 2793796394,
      "County_state": "Mackinac,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.582692,
              45.96312
            ],
            [
              -84.575833,
              45.955294
            ],
            [
              -84.567493,
              45.947702
            ],
            [
              -84.561829,
              45.959077
            ],
            [
              -84.563933,
              45.966408
            ],
            [
              -84.574955,
              45.970654
            ],
            [
              -84.582692,
              45.96312
            ]
          ]
        ],
        [
          [
            [
              -84.639863,
              45.978345
            ],
            [
              -84.640179,
              45.958369
            ],
            [
              -84.632855,
              45.951007
            ],
            [
              -84.619417,
              45.958356
            ],
            [
              -84.61067,
              45.965543
            ],
            [
              -84.617414,
              45.97297
            ],
            [
              -84.632855,
              45.984873
            ],
            [
              -84.639863,
              45.978345
            ]
          ]
        ],
        [
          [
            [
              -84.651336,
              45.862844
            ],
            [
              -84.650783,
              45.85921
            ],
            [
              -84.6397,
              45.852624
            ],
            [
              -84.629239,
              45.850014
            ],
            [
              -84.623863,
              45.846579
            ],
            [
              -84.61184,
              45.833429
            ],
            [
              -84.597043,
              45.822629
            ],
            [
              -84.590198,
              45.813217
            ],
            [
              -84.587572,
              45.8067
            ],
            [
              -84.58195,
              45.802633
            ],
            [
              -84.561562,
              45.796213
            ],
            [
              -84.549902,
              45.789859
            ],
            [
              -84.52506,
              45.764168
            ],
            [
              -84.509301,
              45.754336
            ],
            [
              -84.507476,
              45.744644
            ],
            [
              -84.500892,
              45.737259
            ],
            [
              -84.484128,
              45.73071
            ],
            [
              -84.469183,
              45.732246
            ],
            [
              -84.45104,
              45.727952
            ],
            [
              -84.430026,
              45.726307
            ],
            [
              -84.418902,
              45.721712
            ],
            [
              -84.405852,
              45.722417
            ],
            [
              -84.394038,
              45.727623
            ],
            [
              -84.3956,
              45.732925
            ],
            [
              -84.394292,
              45.735301
            ],
            [
              -84.372248,
              45.745784
            ],
            [
              -84.370241,
              45.75619
            ],
            [
              -84.356312,
              45.769495
            ],
            [
              -84.35602,
              45.771895
            ],
            [
              -84.403208,
              45.784394
            ],
            [
              -84.41091,
              45.797217
            ],
            [
              -84.419335,
              45.806747
            ],
            [
              -84.42159,
              45.805651
            ],
            [
              -84.419696,
              45.799823
            ],
            [
              -84.421267,
              45.792694
            ],
            [
              -84.426724,
              45.788194
            ],
            [
              -84.432472,
              45.786732
            ],
            [
              -84.492951,
              45.805343
            ],
            [
              -84.514441,
              45.81012
            ],
            [
              -84.538395,
              45.807843
            ],
            [
              -84.578328,
              45.820092
            ],
            [
              -84.589272,
              45.825795
            ],
            [
              -84.602922,
              45.85164
            ],
            [
              -84.6082,
              45.861631
            ],
            [
              -84.622515,
              45.87753
            ],
            [
              -84.629437,
              45.882578
            ],
            [
              -84.641804,
              45.885486
            ],
            [
              -84.646876,
              45.884642
            ],
            [
              -84.651336,
              45.862844
            ]
          ]
        ],
        [
          [
            [
              -85.865025,
              46.244631
            ],
            [
              -85.8654019629437,
              45.9682573438202
            ],
            [
              -85.861157,
              45.968167
            ],
            [
              -85.842404,
              45.965247
            ],
            [
              -85.832603,
              45.967742
            ],
            [
              -85.825819,
              45.976292
            ],
            [
              -85.817558,
              45.979447
            ],
            [
              -85.810442,
              45.980087
            ],
            [
              -85.790639,
              45.977594
            ],
            [
              -85.770938,
              45.971349
            ],
            [
              -85.743618,
              45.965173
            ],
            [
              -85.724246,
              45.965409
            ],
            [
              -85.697203,
              45.960158
            ],
            [
              -85.663966,
              45.967013
            ],
            [
              -85.654686,
              45.973686
            ],
            [
              -85.648581,
              45.983695
            ],
            [
              -85.617709,
              46.008458
            ],
            [
              -85.603785,
              46.030363
            ],
            [
              -85.540858,
              46.079581
            ],
            [
              -85.52157,
              46.091257
            ],
            [
              -85.512696,
              46.094727
            ],
            [
              -85.5001,
              46.09694
            ],
            [
              -85.480603,
              46.096379
            ],
            [
              -85.44699,
              46.085164
            ],
            [
              -85.440191,
              46.092593
            ],
            [
              -85.442293,
              46.093941
            ],
            [
              -85.441932,
              46.095793
            ],
            [
              -85.426916,
              46.101964
            ],
            [
              -85.412064,
              46.101437
            ],
            [
              -85.393832,
              46.095465
            ],
            [
              -85.381394,
              46.082044
            ],
            [
              -85.366622,
              46.086778
            ],
            [
              -85.356214,
              46.092086
            ],
            [
              -85.335911,
              46.092595
            ],
            [
              -85.316264,
              46.086608
            ],
            [
              -85.287693,
              46.072276
            ],
            [
              -85.266385,
              46.065779
            ],
            [
              -85.222511,
              46.060689
            ],
            [
              -85.197523,
              46.044878
            ],
            [
              -85.19063,
              46.047622
            ],
            [
              -85.152027,
              46.050725
            ],
            [
              -85.140835,
              46.049601
            ],
            [
              -85.130433,
              46.046076
            ],
            [
              -85.102899,
              46.032488
            ],
            [
              -85.088818,
              46.028378
            ],
            [
              -85.055581,
              46.023148
            ],
            [
              -85.01399,
              46.010774
            ],
            [
              -85.003597,
              46.00613
            ],
            [
              -84.973556,
              45.986134
            ],
            [
              -84.937134,
              45.955949
            ],
            [
              -84.917484,
              45.93067
            ],
            [
              -84.902913,
              45.923673
            ],
            [
              -84.879835,
              45.915847
            ],
            [
              -84.873254,
              45.909815
            ],
            [
              -84.852916,
              45.900111
            ],
            [
              -84.842243,
              45.898194
            ],
            [
              -84.837624,
              45.889054
            ],
            [
              -84.838472,
              45.881512
            ],
            [
              -84.831396,
              45.872038
            ],
            [
              -84.792763,
              45.858691
            ],
            [
              -84.746985,
              45.835597
            ],
            [
              -84.725734,
              45.837045
            ],
            [
              -84.722764,
              45.846621
            ],
            [
              -84.720836,
              45.848107
            ],
            [
              -84.706383,
              45.848658
            ],
            [
              -84.702295,
              45.850464
            ],
            [
              -84.701183,
              45.853092
            ],
            [
              -84.715481,
              45.865934
            ],
            [
              -84.721276,
              45.873908
            ],
            [
              -84.734002,
              45.907026
            ],
            [
              -84.713251,
              45.916047
            ],
            [
              -84.713614,
              45.920366
            ],
            [
              -84.718955,
              45.927449
            ],
            [
              -84.733041,
              45.932837
            ],
            [
              -84.73937,
              45.941816
            ],
            [
              -84.738849,
              45.945792
            ],
            [
              -84.730179,
              45.961198
            ],
            [
              -84.723039,
              45.967279
            ],
            [
              -84.703948,
              45.970901
            ],
            [
              -84.687712,
              45.97126
            ],
            [
              -84.685254,
              45.973454
            ],
            [
              -84.684368,
              45.977499
            ],
            [
              -84.686269,
              45.979144
            ],
            [
              -84.6927,
              46.016963
            ],
            [
              -84.692735,
              46.027019
            ],
            [
              -84.687322,
              46.03488
            ],
            [
              -84.675835,
              46.046009
            ],
            [
              -84.66671,
              46.050486
            ],
            [
              -84.656567,
              46.052654
            ],
            [
              -84.647609,
              46.049704
            ],
            [
              -84.609063,
              46.026418
            ],
            [
              -84.586592,
              46.026584
            ],
            [
              -84.581081,
              46.031041
            ],
            [
              -84.563891,
              46.032459
            ],
            [
              -84.544405,
              46.02286
            ],
            [
              -84.540995,
              46.019501
            ],
            [
              -84.533426,
              46.00572
            ],
            [
              -84.530444,
              45.991385
            ],
            [
              -84.534648,
              45.978132
            ],
            [
              -84.534422,
              45.972762
            ],
            [
              -84.532392,
              45.969448
            ],
            [
              -84.525052,
              45.968578
            ],
            [
              -84.514071,
              45.971292
            ],
            [
              -84.514123,
              45.987242
            ],
            [
              -84.507201,
              45.991169
            ],
            [
              -84.484009,
              45.98825
            ],
            [
              -84.482442,
              45.985441
            ],
            [
              -84.483062,
              45.982242
            ],
            [
              -84.480436,
              45.977764
            ],
            [
              -84.463128,
              45.968925
            ],
            [
              -84.443138,
              45.977863
            ],
            [
              -84.437633,
              45.97375
            ],
            [
              -84.428689,
              45.958371
            ],
            [
              -84.376429,
              45.931962
            ],
            [
              -84.353272,
              45.941663
            ],
            [
              -84.330021,
              45.956247
            ],
            [
              -84.254952,
              45.956068
            ],
            [
              -84.238174,
              45.967595
            ],
            [
              -84.17806,
              45.969175
            ],
            [
              -84.17225,
              45.966072
            ],
            [
              -84.140816,
              45.975308
            ],
            [
              -84.1142838818021,
              45.978321752175006
            ],
            [
              -84.114186,
              45.985152
            ],
            [
              -84.239332,
              45.984914
            ],
            [
              -84.238884,
              46.071457
            ],
            [
              -84.36309,
              46.071574
            ],
            [
              -84.363503,
              46.157984
            ],
            [
              -85.112513,
              46.158263
            ],
            [
              -85.113329,
              46.245722
            ],
            [
              -85.237839,
              46.24543
            ],
            [
              -85.865025,
              46.244631
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "153",
      "COUNTYNS": "01623017",
      "AFFGEOID": "0500000US26153",
      "GEOID": "26153",
      "NAME": "Schoolcraft",
      "LSAD": "06",
      "ALAND": 3033697431,
      "AWATER": 1845664144,
      "County_state": "Schoolcraft,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.615397,
              46.333263
            ],
            [
              -86.615601,
              46.158691
            ],
            [
              -86.490142,
              46.158178
            ],
            [
              -86.49025,
              45.896337
            ],
            [
              -86.459114,
              45.896363
            ],
            [
              -86.4598661599231,
              45.7580422203082
            ],
            [
              -86.455534,
              45.75685
            ],
            [
              -86.439661,
              45.760669
            ],
            [
              -86.431921,
              45.767756
            ],
            [
              -86.428294,
              45.77562
            ],
            [
              -86.427183,
              45.77905
            ],
            [
              -86.428946,
              45.782524
            ],
            [
              -86.428423,
              45.785587
            ],
            [
              -86.424828,
              45.789747
            ],
            [
              -86.415971,
              45.793793
            ],
            [
              -86.401656,
              45.795412
            ],
            [
              -86.395809,
              45.78974
            ],
            [
              -86.369918,
              45.789254
            ],
            [
              -86.363808,
              45.790057
            ],
            [
              -86.351658,
              45.798132
            ],
            [
              -86.355062,
              45.805355
            ],
            [
              -86.349134,
              45.83416
            ],
            [
              -86.332625,
              45.851813
            ],
            [
              -86.324232,
              45.90608
            ],
            [
              -86.315981,
              45.915247
            ],
            [
              -86.278007,
              45.942057
            ],
            [
              -86.254768,
              45.94864
            ],
            [
              -86.248008,
              45.944849
            ],
            [
              -86.233613,
              45.945802
            ],
            [
              -86.22906,
              45.94857
            ],
            [
              -86.220546,
              45.958883
            ],
            [
              -86.208255,
              45.962978
            ],
            [
              -86.196618,
              45.963185
            ],
            [
              -86.159415,
              45.953765
            ],
            [
              -86.150173,
              45.954494
            ],
            [
              -86.145714,
              45.957372
            ],
            [
              -86.123567,
              45.964748
            ],
            [
              -86.094753,
              45.966704
            ],
            [
              -86.072067,
              45.965313
            ],
            [
              -86.050956,
              45.962205
            ],
            [
              -85.998868,
              45.950968
            ],
            [
              -85.954063,
              45.936629
            ],
            [
              -85.920581,
              45.920994
            ],
            [
              -85.913769,
              45.919439
            ],
            [
              -85.910264,
              45.922112
            ],
            [
              -85.917238,
              45.927782
            ],
            [
              -85.926017,
              45.932104
            ],
            [
              -85.926213,
              45.938093
            ],
            [
              -85.922737,
              45.948287
            ],
            [
              -85.9091,
              45.959074
            ],
            [
              -85.893196,
              45.967253
            ],
            [
              -85.882442,
              45.96862
            ],
            [
              -85.8654019629437,
              45.9682573438202
            ],
            [
              -85.865025,
              46.244631
            ],
            [
              -85.864932,
              46.504758
            ],
            [
              -86.364953,
              46.505852
            ],
            [
              -86.364989,
              46.331927
            ],
            [
              -86.615397,
              46.333263
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "019",
      "COUNTYNS": "01622952",
      "AFFGEOID": "0500000US26019",
      "GEOID": "26019",
      "NAME": "Benzie",
      "LSAD": "06",
      "ALAND": 828049220,
      "AWATER": 1398210900,
      "County_state": "Benzie,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.256796,
              44.686769
            ],
            [
              -86.259029,
              44.663654
            ],
            [
              -86.25395,
              44.64808
            ],
            [
              -86.231828,
              44.609107
            ],
            [
              -86.22545,
              44.59459
            ],
            [
              -86.220697,
              44.566742
            ],
            [
              -86.223788,
              44.549043
            ],
            [
              -86.2335026627983,
              44.5182776275632
            ],
            [
              -85.817802,
              44.512969
            ],
            [
              -85.815119,
              44.774534
            ],
            [
              -85.937084,
              44.778345
            ],
            [
              -86.07308648269859,
              44.7781251744663
            ],
            [
              -86.073506,
              44.769803
            ],
            [
              -86.077933,
              44.758234
            ],
            [
              -86.089186,
              44.741496
            ],
            [
              -86.106182,
              44.731088
            ],
            [
              -86.121125,
              44.727972
            ],
            [
              -86.160268,
              44.728189
            ],
            [
              -86.172201,
              44.720623
            ],
            [
              -86.232482,
              44.70605
            ],
            [
              -86.248474,
              44.699046
            ],
            [
              -86.254996,
              44.691935
            ],
            [
              -86.256796,
              44.686769
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "047",
      "COUNTYNS": "01622966",
      "AFFGEOID": "0500000US26047",
      "GEOID": "26047",
      "NAME": "Emmet",
      "LSAD": "06",
      "ALAND": 1210879338,
      "AWATER": 1073480654,
      "County_state": "Emmet,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.118637,
              45.575175
            ],
            [
              -85.119737,
              45.569026
            ],
            [
              -85.115479,
              45.539406
            ],
            [
              -85.109252,
              45.521626
            ],
            [
              -85.088386,
              45.476928
            ],
            [
              -85.069573,
              45.459239
            ],
            [
              -85.040936,
              45.436701
            ],
            [
              -84.994975,
              45.4234
            ],
            [
              -84.990785,
              45.425264
            ],
            [
              -84.990041,
              45.427618
            ],
            [
              -84.980953,
              45.429382
            ],
            [
              -84.922006,
              45.421914
            ],
            [
              -84.916165,
              45.417639
            ],
            [
              -84.912956,
              45.409776
            ],
            [
              -84.912537,
              45.402828
            ],
            [
              -84.91585,
              45.393115
            ],
            [
              -84.959119,
              45.375973
            ],
            [
              -85.022234,
              45.366701
            ],
            [
              -85.032813,
              45.361251
            ],
            [
              -85.043101,
              45.361506
            ],
            [
              -85.054805,
              45.364091
            ],
            [
              -85.0959851261272,
              45.3670008533623
            ],
            [
              -85.096533,
              45.335743
            ],
            [
              -85.045967,
              45.334655
            ],
            [
              -85.022839,
              45.310089
            ],
            [
              -85.019077,
              45.290127
            ],
            [
              -85.001803,
              45.272328
            ],
            [
              -84.965066,
              45.271222
            ],
            [
              -84.964787,
              45.291601
            ],
            [
              -84.732687,
              45.289382
            ],
            [
              -84.730354,
              45.376159
            ],
            [
              -84.730288,
              45.464356
            ],
            [
              -84.730677,
              45.550985
            ],
            [
              -84.73239272681569,
              45.7879288720768
            ],
            [
              -84.734065,
              45.788205
            ],
            [
              -84.742,
              45.784134
            ],
            [
              -84.751571,
              45.782733
            ],
            [
              -84.772765,
              45.789301
            ],
            [
              -84.780313,
              45.787224
            ],
            [
              -84.793153,
              45.780463
            ],
            [
              -84.792337,
              45.778497
            ],
            [
              -84.7798,
              45.76965
            ],
            [
              -84.781373,
              45.76108
            ],
            [
              -84.788821,
              45.752283
            ],
            [
              -84.799558,
              45.74713
            ],
            [
              -84.806642,
              45.746171
            ],
            [
              -84.840981,
              45.744751
            ],
            [
              -84.866976,
              45.752066
            ],
            [
              -84.910398,
              45.75001
            ],
            [
              -84.924664,
              45.756897
            ],
            [
              -84.938312,
              45.759892
            ],
            [
              -84.995105,
              45.759855
            ],
            [
              -85.00741,
              45.763168
            ],
            [
              -85.014509,
              45.760329
            ],
            [
              -85.011433,
              45.757962
            ],
            [
              -85.002914,
              45.75394
            ],
            [
              -84.982328,
              45.75196
            ],
            [
              -84.95084,
              45.736893
            ],
            [
              -84.942125,
              45.72846
            ],
            [
              -84.940526,
              45.721832
            ],
            [
              -84.943756,
              45.71029
            ],
            [
              -84.97095,
              45.686334
            ],
            [
              -84.996336,
              45.669685
            ],
            [
              -85.001154,
              45.661225
            ],
            [
              -85.007026,
              45.65636
            ],
            [
              -85.015341,
              45.651564
            ],
            [
              -85.061488,
              45.639505
            ],
            [
              -85.07491,
              45.629242
            ],
            [
              -85.075686,
              45.623688
            ],
            [
              -85.079528,
              45.617083
            ],
            [
              -85.093525,
              45.600121
            ],
            [
              -85.111909,
              45.585829
            ],
            [
              -85.118637,
              45.575175
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "115",
      "COUNTYNS": "01623000",
      "AFFGEOID": "0500000US26115",
      "GEOID": "26115",
      "NAME": "Monroe",
      "LSAD": "06",
      "ALAND": 1423275272,
      "AWATER": 337746237,
      "County_state": "Monroe,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.773922,
              42.08243
            ],
            [
              -83.7631498887765,
              41.7235473333038
            ],
            [
              -83.763038,
              41.72355
            ],
            [
              -83.708937,
              41.72515
            ],
            [
              -83.685337,
              41.726449
            ],
            [
              -83.665937,
              41.726949
            ],
            [
              -83.64955722729461,
              41.727447225092696
            ],
            [
              -83.639636,
              41.727749
            ],
            [
              -83.636636,
              41.727849
            ],
            [
              -83.595235,
              41.729148
            ],
            [
              -83.593835,
              41.729148
            ],
            [
              -83.58554441405401,
              41.7293408043243
            ],
            [
              -83.585235,
              41.729348
            ],
            [
              -83.504334,
              41.731547
            ],
            [
              -83.503433,
              41.731547
            ],
            [
              -83.499733,
              41.731647
            ],
            [
              -83.497733,
              41.731847
            ],
            [
              -83.453832,
              41.732647
            ],
            [
              -83.451897,
              41.734486
            ],
            [
              -83.43436,
              41.737058
            ],
            [
              -83.424076,
              41.740738
            ],
            [
              -83.427308,
              41.750214
            ],
            [
              -83.437516,
              41.769694
            ],
            [
              -83.443364,
              41.789118
            ],
            [
              -83.441668,
              41.808646
            ],
            [
              -83.439612,
              41.813162
            ],
            [
              -83.434204,
              41.818562
            ],
            [
              -83.422316,
              41.822278
            ],
            [
              -83.409596,
              41.830325
            ],
            [
              -83.39622,
              41.852965
            ],
            [
              -83.381955,
              41.870877
            ],
            [
              -83.372445,
              41.874477
            ],
            [
              -83.3721976899804,
              41.8741224353634
            ],
            [
              -83.366187,
              41.865505
            ],
            [
              -83.359467,
              41.867849
            ],
            [
              -83.34760399150889,
              41.875868287761094
            ],
            [
              -83.341557,
              41.879956
            ],
            [
              -83.335961,
              41.889721
            ],
            [
              -83.3356584729891,
              41.8920091948129
            ],
            [
              -83.333642,
              41.907261
            ],
            [
              -83.326024,
              41.924961
            ],
            [
              -83.315859,
              41.935893
            ],
            [
              -83.302904,
              41.943073
            ],
            [
              -83.295982,
              41.944742
            ],
            [
              -83.28713,
              41.944397
            ],
            [
              -83.269521,
              41.939042
            ],
            [
              -83.257292,
              41.950745
            ],
            [
              -83.257009,
              41.959686
            ],
            [
              -83.249204,
              41.972402
            ],
            [
              -83.228502,
              41.987291
            ],
            [
              -83.223354,
              41.989191
            ],
            [
              -83.216897,
              41.988561
            ],
            [
              -83.209379,
              41.995736
            ],
            [
              -83.208647,
              42.00504
            ],
            [
              -83.190535,
              42.006172
            ],
            [
              -83.187246,
              42.007573
            ],
            [
              -83.181475,
              42.019301
            ],
            [
              -83.18582193245518,
              42.0293674760258
            ],
            [
              -83.219674,
              42.04912
            ],
            [
              -83.225464,
              42.049104
            ],
            [
              -83.264314,
              42.069283
            ],
            [
              -83.283512,
              42.089737
            ],
            [
              -83.303885,
              42.094241
            ],
            [
              -83.420761,
              42.093568
            ],
            [
              -83.420622,
              42.087777
            ],
            [
              -83.539396,
              42.085598
            ],
            [
              -83.646553,
              42.08389
            ],
            [
              -83.656621,
              42.084581
            ],
            [
              -83.773922,
              42.08243
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "155",
      "COUNTYNS": "01623018",
      "AFFGEOID": "0500000US26155",
      "GEOID": "26155",
      "NAME": "Shiawassee",
      "LSAD": "06",
      "ALAND": 1374689328,
      "AWATER": 26342203,
      "County_state": "Shiawassee,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.367891,
              43.128452
            ],
            [
              -84.36776,
              43.117942
            ],
            [
              -84.363659,
              42.775778
            ],
            [
              -84.158189,
              42.776639
            ],
            [
              -83.922516,
              42.780821
            ],
            [
              -83.927164,
              42.868344
            ],
            [
              -83.929079,
              43.132782
            ],
            [
              -84.367891,
              43.128452
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "157",
      "COUNTYNS": "01623019",
      "AFFGEOID": "0500000US26157",
      "GEOID": "26157",
      "NAME": "Tuscola",
      "LSAD": "06",
      "ALAND": 2080718000,
      "AWATER": 286299930,
      "County_state": "Tuscola,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.6994170918811,
              43.601637150256494
            ],
            [
              -83.699484,
              43.565823
            ],
            [
              -83.698816,
              43.478957
            ],
            [
              -83.698509,
              43.392711
            ],
            [
              -83.695258,
              43.326048
            ],
            [
              -83.695621,
              43.221422
            ],
            [
              -83.460733,
              43.223131
            ],
            [
              -83.460708,
              43.232791
            ],
            [
              -83.351709,
              43.235193
            ],
            [
              -83.354418,
              43.322257
            ],
            [
              -83.120396,
              43.327049
            ],
            [
              -83.103826,
              43.327365
            ],
            [
              -83.117844,
              43.675493
            ],
            [
              -83.466592,
              43.667621
            ],
            [
              -83.467403,
              43.728909
            ],
            [
              -83.4732241293124,
              43.73135556188301
            ],
            [
              -83.472291,
              43.729003
            ],
            [
              -83.479996,
              43.716012
            ],
            [
              -83.49796,
              43.707445
            ],
            [
              -83.512751,
              43.713625
            ],
            [
              -83.515237,
              43.717781
            ],
            [
              -83.517867,
              43.718696
            ],
            [
              -83.522847,
              43.717504
            ],
            [
              -83.528433,
              43.714283
            ],
            [
              -83.544893,
              43.707028
            ],
            [
              -83.562736,
              43.687659
            ],
            [
              -83.5628477668749,
              43.6875569553122
            ],
            [
              -83.58201212498109,
              43.67005963383569
            ],
            [
              -83.583176,
              43.668997
            ],
            [
              -83.607416,
              43.64028
            ],
            [
              -83.647846,
              43.60426
            ],
            [
              -83.683351,
              43.590584
            ],
            [
              -83.6994170918811,
              43.601637150256494
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "063",
      "COUNTYNS": "01622974",
      "AFFGEOID": "0500000US26063",
      "GEOID": "26063",
      "NAME": "Huron",
      "LSAD": "06",
      "ALAND": 2165446362,
      "AWATER": 3368847813,
      "County_state": "Huron,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.495788,
              43.772188
            ],
            [
              -83.488334,
              43.743686
            ],
            [
              -83.474962,
              43.735737
            ],
            [
              -83.4732241293124,
              43.73135556188301
            ],
            [
              -83.467403,
              43.728909
            ],
            [
              -83.466592,
              43.667621
            ],
            [
              -83.117844,
              43.675493
            ],
            [
              -82.6062330164376,
              43.69043734890919
            ],
            [
              -82.612224,
              43.739771
            ],
            [
              -82.617213,
              43.746788
            ],
            [
              -82.619079,
              43.756088
            ],
            [
              -82.617955,
              43.768596
            ],
            [
              -82.633641,
              43.831224
            ],
            [
              -82.644345,
              43.837539
            ],
            [
              -82.647784,
              43.842684
            ],
            [
              -82.647467,
              43.84449
            ],
            [
              -82.642899,
              43.846419
            ],
            [
              -82.643166,
              43.852468
            ],
            [
              -82.65545,
              43.867883
            ],
            [
              -82.678642,
              43.88373
            ],
            [
              -82.693505,
              43.91798
            ],
            [
              -82.709839,
              43.948226
            ],
            [
              -82.712235,
              43.94961
            ],
            [
              -82.728528,
              43.972615
            ],
            [
              -82.738992,
              43.989506
            ],
            [
              -82.746255,
              43.996037
            ],
            [
              -82.765018,
              44.006845
            ],
            [
              -82.783198,
              44.009366
            ],
            [
              -82.788298,
              44.013712
            ],
            [
              -82.793205,
              44.023247
            ],
            [
              -82.833103,
              44.036851
            ],
            [
              -82.875889,
              44.045046
            ],
            [
              -82.889831,
              44.050952
            ],
            [
              -82.915976,
              44.070503
            ],
            [
              -82.928884,
              44.069389
            ],
            [
              -82.947368,
              44.062187
            ],
            [
              -82.956658,
              44.063306
            ],
            [
              -82.958688,
              44.065774
            ],
            [
              -82.967439,
              44.066138
            ],
            [
              -82.990728,
              44.048846
            ],
            [
              -82.999283,
              44.04651
            ],
            [
              -83.024604,
              44.045174
            ],
            [
              -83.029868,
              44.041175
            ],
            [
              -83.046577,
              44.01571
            ],
            [
              -83.058741,
              44.006224
            ],
            [
              -83.066026,
              44.003366
            ],
            [
              -83.079297,
              44.001079
            ],
            [
              -83.10782,
              44.003245
            ],
            [
              -83.120659,
              44.00095
            ],
            [
              -83.134881,
              43.993147
            ],
            [
              -83.145407,
              43.989441
            ],
            [
              -83.180618,
              43.982109
            ],
            [
              -83.195688,
              43.983137
            ],
            [
              -83.227093,
              43.981003
            ],
            [
              -83.26153,
              43.973525
            ],
            [
              -83.26185,
              43.969021
            ],
            [
              -83.26898,
              43.956132
            ],
            [
              -83.28231,
              43.938031
            ],
            [
              -83.30569,
              43.922489
            ],
            [
              -83.318656,
              43.91762
            ],
            [
              -83.375056,
              43.919014
            ],
            [
              -83.407146,
              43.919807
            ],
            [
              -83.401115,
              43.909151
            ],
            [
              -83.373871,
              43.904396
            ],
            [
              -83.348915,
              43.899259
            ],
            [
              -83.331788,
              43.893901
            ],
            [
              -83.347907,
              43.868595
            ],
            [
              -83.372466,
              43.858707
            ],
            [
              -83.392859,
              43.849096
            ],
            [
              -83.417483,
              43.841967
            ],
            [
              -83.423443,
              43.855527
            ],
            [
              -83.414047,
              43.877026
            ],
            [
              -83.43261,
              43.885273
            ],
            [
              -83.449288,
              43.859258
            ],
            [
              -83.448642,
              43.84448
            ],
            [
              -83.46314,
              43.82665
            ],
            [
              -83.464448,
              43.810615
            ],
            [
              -83.479567,
              43.793625
            ],
            [
              -83.495788,
              43.772188
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "001",
      "COUNTYNS": "01622943",
      "AFFGEOID": "0500000US26001",
      "GEOID": "26001",
      "NAME": "Alcona",
      "LSAD": "06",
      "ALAND": 1747349262,
      "AWATER": 2890404971,
      "County_state": "Alcona,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.888484,
              44.856458
            ],
            [
              -83.886634,
              44.508976
            ],
            [
              -83.3182138699829,
              44.5116552271657
            ],
            [
              -83.318279,
              44.514416
            ],
            [
              -83.308471,
              44.539902
            ],
            [
              -83.308918,
              44.54836
            ],
            [
              -83.313649,
              44.564588
            ],
            [
              -83.315603,
              44.595079
            ],
            [
              -83.314517,
              44.608725
            ],
            [
              -83.307504,
              44.629816
            ],
            [
              -83.289442,
              44.652968
            ],
            [
              -83.276836,
              44.689354
            ],
            [
              -83.273393,
              44.713901
            ],
            [
              -83.284128,
              44.721766
            ],
            [
              -83.290665,
              44.729265
            ],
            [
              -83.2973,
              44.746134
            ],
            [
              -83.298287,
              44.754907
            ],
            [
              -83.296971,
              44.758495
            ],
            [
              -83.295518,
              44.793397
            ],
            [
              -83.300648,
              44.829831
            ],
            [
              -83.314429,
              44.84222
            ],
            [
              -83.321241,
              44.852962
            ],
            [
              -83.32109311974439,
              44.8584942845224
            ],
            [
              -83.888484,
              44.856458
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "103",
      "COUNTYNS": "01622994",
      "AFFGEOID": "0500000US26103",
      "GEOID": "26103",
      "NAME": "Marquette",
      "LSAD": "06",
      "ALAND": 4685386476,
      "AWATER": 4185372277,
      "County_state": "Marquette,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.116571,
              46.419951
            ],
            [
              -88.117407,
              46.246618
            ],
            [
              -87.615597,
              46.246653
            ],
            [
              -87.617091,
              45.986014
            ],
            [
              -87.367849,
              45.985321
            ],
            [
              -87.242885,
              45.985117
            ],
            [
              -87.241506,
              46.159103
            ],
            [
              -87.116138,
              46.15905
            ],
            [
              -87.11661786793019,
              46.4951625689184
            ],
            [
              -87.12744,
              46.494014
            ],
            [
              -87.175065,
              46.497548
            ],
            [
              -87.202404,
              46.490827
            ],
            [
              -87.258732,
              46.488255
            ],
            [
              -87.310755,
              46.492017
            ],
            [
              -87.351071,
              46.500749
            ],
            [
              -87.366767,
              46.507303
            ],
            [
              -87.381349,
              46.517292
            ],
            [
              -87.38929,
              46.524472
            ],
            [
              -87.393985,
              46.533183
            ],
            [
              -87.3903,
              46.542577
            ],
            [
              -87.375613,
              46.54714
            ],
            [
              -87.382206,
              46.553681
            ],
            [
              -87.392828,
              46.570852
            ],
            [
              -87.392974,
              46.572523
            ],
            [
              -87.381649,
              46.580059
            ],
            [
              -87.383961,
              46.59307
            ],
            [
              -87.403275,
              46.595215
            ],
            [
              -87.411167,
              46.601669
            ],
            [
              -87.442612,
              46.602776
            ],
            [
              -87.451368,
              46.605923
            ],
            [
              -87.464108,
              46.614811
            ],
            [
              -87.467563,
              46.626228
            ],
            [
              -87.466537,
              46.631555
            ],
            [
              -87.467965,
              46.635623
            ],
            [
              -87.49286,
              46.642561
            ],
            [
              -87.503025,
              46.647497
            ],
            [
              -87.524444,
              46.677586
            ],
            [
              -87.52430545884201,
              46.678915556078394
            ],
            [
              -87.523308,
              46.688488
            ],
            [
              -87.573203,
              46.720471
            ],
            [
              -87.582745,
              46.730527
            ],
            [
              -87.590767,
              46.753009
            ],
            [
              -87.595307,
              46.78295
            ],
            [
              -87.607988,
              46.788408
            ],
            [
              -87.628081,
              46.805157
            ],
            [
              -87.6333,
              46.812107
            ],
            [
              -87.641887,
              46.813733
            ],
            [
              -87.65151,
              46.812411
            ],
            [
              -87.662261,
              46.815157
            ],
            [
              -87.672015,
              46.820415
            ],
            [
              -87.674345,
              46.82405
            ],
            [
              -87.673177,
              46.827593
            ],
            [
              -87.674541,
              46.836964
            ],
            [
              -87.680668,
              46.842496
            ],
            [
              -87.687164,
              46.841742
            ],
            [
              -87.68793,
              46.839159
            ],
            [
              -87.685698,
              46.83253
            ],
            [
              -87.69459,
              46.827182
            ],
            [
              -87.713737,
              46.825534
            ],
            [
              -87.727358,
              46.827656
            ],
            [
              -87.731522,
              46.831196
            ],
            [
              -87.734325,
              46.836955
            ],
            [
              -87.736732,
              46.847216
            ],
            [
              -87.73487,
              46.85012
            ],
            [
              -87.741014,
              46.865247
            ],
            [
              -87.746646,
              46.865427
            ],
            [
              -87.755868,
              46.860453
            ],
            [
              -87.765989,
              46.861316
            ],
            [
              -87.776804,
              46.866823
            ],
            [
              -87.778752,
              46.870422
            ],
            [
              -87.776313,
              46.872591
            ],
            [
              -87.77693,
              46.876726
            ],
            [
              -87.782461,
              46.879859
            ],
            [
              -87.7881856458594,
              46.8803726340224
            ],
            [
              -87.793194,
              46.880822
            ],
            [
              -87.813226,
              46.888023
            ],
            [
              -87.8132279520549,
              46.888024712972005
            ],
            [
              -87.816794,
              46.891154
            ],
            [
              -87.827162,
              46.889713
            ],
            [
              -87.841228,
              46.884363
            ],
            [
              -87.846195,
              46.883905
            ],
            [
              -87.874538,
              46.892578
            ],
            [
              -87.900339,
              46.909686
            ],
            [
              -87.956,
              46.909051
            ],
            [
              -87.986113,
              46.905957
            ],
            [
              -88.004298,
              46.906982
            ],
            [
              -88.032408,
              46.90889
            ],
            [
              -88.0445508065543,
              46.9124727650012
            ],
            [
              -88.044528,
              46.808798
            ],
            [
              -88.044709,
              46.765353
            ],
            [
              -87.991389,
              46.765159
            ],
            [
              -87.991546,
              46.679113
            ],
            [
              -88.115843,
              46.678381
            ],
            [
              -88.116571,
              46.419951
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "069",
      "COUNTYNS": "01622977",
      "AFFGEOID": "0500000US26069",
      "GEOID": "26069",
      "NAME": "Iosco",
      "LSAD": "06",
      "ALAND": 1422175726,
      "AWATER": 3473066854,
      "County_state": "Iosco,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.886634,
              44.508976
            ],
            [
              -83.883977,
              44.161786
            ],
            [
              -83.5652245417928,
              44.163516602719
            ],
            [
              -83.553834,
              44.197956
            ],
            [
              -83.552872,
              44.210718
            ],
            [
              -83.549096,
              44.227282
            ],
            [
              -83.53771,
              44.248171
            ],
            [
              -83.524817,
              44.261558
            ],
            [
              -83.508839,
              44.273711
            ],
            [
              -83.500392,
              44.27661
            ],
            [
              -83.479531,
              44.28009
            ],
            [
              -83.463049,
              44.278838
            ],
            [
              -83.445805,
              44.273378
            ],
            [
              -83.442731,
              44.265361
            ],
            [
              -83.429689,
              44.269708
            ],
            [
              -83.425762,
              44.272487
            ],
            [
              -83.419236,
              44.2878
            ],
            [
              -83.414301,
              44.294543
            ],
            [
              -83.401822,
              44.301831
            ],
            [
              -83.373607,
              44.327784
            ],
            [
              -83.364312,
              44.33259
            ],
            [
              -83.352115,
              44.332366
            ],
            [
              -83.343738,
              44.329763
            ],
            [
              -83.336988,
              44.332919
            ],
            [
              -83.332533,
              44.340464
            ],
            [
              -83.335248,
              44.357995
            ],
            [
              -83.333757,
              44.372486
            ],
            [
              -83.321648,
              44.404502
            ],
            [
              -83.321553,
              44.409119
            ],
            [
              -83.324616,
              44.415039
            ],
            [
              -83.327171,
              44.429234
            ],
            [
              -83.326824,
              44.444411
            ],
            [
              -83.31761,
              44.486058
            ],
            [
              -83.3182138699829,
              44.5116552271657
            ],
            [
              -83.886634,
              44.508976
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "131",
      "COUNTYNS": "01623008",
      "AFFGEOID": "0500000US26131",
      "GEOID": "26131",
      "NAME": "Ontonagon",
      "LSAD": "06",
      "ALAND": 3395416191,
      "AWATER": 6292279421,
      "County_state": "Ontonagon,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.88911913036699,
              46.765503481203496
            ],
            [
              -89.863793,
              46.765405
            ],
            [
              -89.86442,
              46.592643
            ],
            [
              -89.740001,
              46.592494
            ],
            [
              -89.740568,
              46.505642
            ],
            [
              -89.365094,
              46.505949
            ],
            [
              -89.365507,
              46.333078
            ],
            [
              -88.991012,
              46.332308
            ],
            [
              -88.991236,
              46.419704
            ],
            [
              -88.989529,
              46.680119
            ],
            [
              -88.864473,
              46.679311
            ],
            [
              -88.863906,
              46.765013
            ],
            [
              -88.93248,
              46.764787
            ],
            [
              -88.9333293525611,
              47.032200475790695
            ],
            [
              -88.944045,
              47.020129
            ],
            [
              -88.959409,
              47.008496
            ],
            [
              -88.972802,
              47.002096
            ],
            [
              -88.987197,
              46.997239
            ],
            [
              -88.998417,
              46.995314
            ],
            [
              -89.022994,
              46.99512
            ],
            [
              -89.02893,
              47.00114
            ],
            [
              -89.03949,
              46.999419
            ],
            [
              -89.063103,
              46.988522
            ],
            [
              -89.086742,
              46.985298
            ],
            [
              -89.106277,
              46.98648
            ],
            [
              -89.113158,
              46.989356
            ],
            [
              -89.118339,
              46.99422
            ],
            [
              -89.128698,
              46.992599
            ],
            [
              -89.142595,
              46.984859
            ],
            [
              -89.168244,
              46.965536
            ],
            [
              -89.201511,
              46.931149
            ],
            [
              -89.227914,
              46.912954
            ],
            [
              -89.249143,
              46.903326
            ],
            [
              -89.372032,
              46.857386
            ],
            [
              -89.415154,
              46.843983
            ],
            [
              -89.437047,
              46.839512
            ],
            [
              -89.47154,
              46.837359
            ],
            [
              -89.491252,
              46.838448
            ],
            [
              -89.49908,
              46.841621
            ],
            [
              -89.513938,
              46.841835
            ],
            [
              -89.535683,
              46.835878
            ],
            [
              -89.569808,
              46.831859
            ],
            [
              -89.619329,
              46.81889
            ],
            [
              -89.634938,
              46.819488
            ],
            [
              -89.642255,
              46.82534
            ],
            [
              -89.660625,
              46.831056
            ],
            [
              -89.673375,
              46.833229
            ],
            [
              -89.720277,
              46.830413
            ],
            [
              -89.790663,
              46.818469
            ],
            [
              -89.831956,
              46.804053
            ],
            [
              -89.848652,
              46.795711
            ],
            [
              -89.88911913036699,
              46.765503481203496
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "099",
      "COUNTYNS": "01622992",
      "AFFGEOID": "0500000US26099",
      "GEOID": "26099",
      "NAME": "Macomb",
      "LSAD": "06",
      "ALAND": 1241120517,
      "AWATER": 237557908,
      "County_state": "Macomb,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.102891,
              42.888647
            ],
            [
              -83.095441,
              42.712499
            ],
            [
              -83.091449,
              42.624208
            ],
            [
              -83.0899,
              42.593009
            ],
            [
              -83.086882,
              42.534792
            ],
            [
              -83.084523,
              42.476345
            ],
            [
              -83.083393,
              42.447153
            ],
            [
              -83.024745,
              42.448159
            ],
            [
              -83.006073,
              42.448784
            ],
            [
              -82.971041,
              42.449665
            ],
            [
              -82.967938,
              42.449836
            ],
            [
              -82.935403,
              42.450159
            ],
            [
              -82.928579,
              42.45062
            ],
            [
              -82.870426085074,
              42.451010101572095
            ],
            [
              -82.883915,
              42.471836
            ],
            [
              -82.882316,
              42.501035
            ],
            [
              -82.874416,
              42.523535
            ],
            [
              -82.8602132221114,
              42.5408416962351
            ],
            [
              -82.859316,
              42.541935
            ],
            [
              -82.851016,
              42.548935
            ],
            [
              -82.849316,
              42.555734
            ],
            [
              -82.845916,
              42.560634
            ],
            [
              -82.834216,
              42.567849
            ],
            [
              -82.821016,
              42.570734
            ],
            [
              -82.796715,
              42.571034
            ],
            [
              -82.789114,
              42.568434
            ],
            [
              -82.784514,
              42.563634
            ],
            [
              -82.782414,
              42.564834
            ],
            [
              -82.781514,
              42.571634
            ],
            [
              -82.788116,
              42.582835
            ],
            [
              -82.788977,
              42.592661
            ],
            [
              -82.787573,
              42.5983
            ],
            [
              -82.789017,
              42.603434
            ],
            [
              -82.811017,
              42.610933
            ],
            [
              -82.819017,
              42.616333
            ],
            [
              -82.820118,
              42.626333
            ],
            [
              -82.81797570320629,
              42.6310395611375
            ],
            [
              -82.813518,
              42.640833
            ],
            [
              -82.797318,
              42.654032
            ],
            [
              -82.792418,
              42.655132
            ],
            [
              -82.780817,
              42.652232
            ],
            [
              -82.765583,
              42.655725
            ],
            [
              -82.7566188060066,
              42.66596154535009
            ],
            [
              -82.753317,
              42.669732
            ],
            [
              -82.726366,
              42.682768
            ],
            [
              -82.7078409351836,
              42.6835096985073
            ],
            [
              -82.705966,
              42.68631
            ],
            [
              -82.729277,
              42.706059
            ],
            [
              -82.729964,
              42.720843
            ],
            [
              -82.733357,
              42.792729
            ],
            [
              -82.73901,
              42.897541
            ],
            [
              -82.865664,
              42.895246
            ],
            [
              -82.983647,
              42.893741
            ],
            [
              -83.102891,
              42.888647
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "049",
      "COUNTYNS": "01622967",
      "AFFGEOID": "0500000US26049",
      "GEOID": "26049",
      "NAME": "Genesee",
      "LSAD": "06",
      "ALAND": 1649726963,
      "AWATER": 32695696,
      "County_state": "Genesee,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.932071,
              43.220377
            ],
            [
              -83.929079,
              43.132782
            ],
            [
              -83.927164,
              42.868344
            ],
            [
              -83.922516,
              42.780821
            ],
            [
              -83.746057,
              42.78227
            ],
            [
              -83.686493,
              42.783263
            ],
            [
              -83.687476,
              42.819585
            ],
            [
              -83.689384,
              42.871263
            ],
            [
              -83.57105,
              42.873277
            ],
            [
              -83.453297,
              42.876307
            ],
            [
              -83.453364,
              42.880432
            ],
            [
              -83.456781,
              42.963137
            ],
            [
              -83.459081,
              43.050014
            ],
            [
              -83.460733,
              43.223131
            ],
            [
              -83.695621,
              43.221422
            ],
            [
              -83.814494,
              43.22121
            ],
            [
              -83.932071,
              43.220377
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "159",
      "COUNTYNS": "01623020",
      "AFFGEOID": "0500000US26159",
      "GEOID": "26159",
      "NAME": "Van Buren",
      "LSAD": "06",
      "ALAND": 1574166310,
      "AWATER": 1248671230,
      "County_state": "Van Buren,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.356218,
              42.254166
            ],
            [
              -86.3648801403901,
              42.2431332189299
            ],
            [
              -86.223563,
              42.243379
            ],
            [
              -86.22294,
              42.071484
            ],
            [
              -86.107885,
              42.070802
            ],
            [
              -85.762943,
              42.069327
            ],
            [
              -85.765506,
              42.331714
            ],
            [
              -85.764697,
              42.420715
            ],
            [
              -86.2738932808627,
              42.4192800027482
            ],
            [
              -86.276878,
              42.413317
            ],
            [
              -86.284969,
              42.401814
            ],
            [
              -86.284448,
              42.394563
            ],
            [
              -86.297168,
              42.358207
            ],
            [
              -86.321803,
              42.310743
            ],
            [
              -86.356218,
              42.254166
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "141",
      "COUNTYNS": "01623013",
      "AFFGEOID": "0500000US26141",
      "GEOID": "26141",
      "NAME": "Presque Isle",
      "LSAD": "06",
      "ALAND": 1706070003,
      "AWATER": 4957327100,
      "County_state": "Presque Isle,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.25202,
              45.625998
            ],
            [
              -84.24938,
              45.282073
            ],
            [
              -84.246634,
              45.198727
            ],
            [
              -84.022072,
              45.198424
            ],
            [
              -83.881223,
              45.202071
            ],
            [
              -83.513882,
              45.207123
            ],
            [
              -83.38758745434319,
              45.207106917599795
            ],
            [
              -83.405914,
              45.227157
            ],
            [
              -83.41241,
              45.238905
            ],
            [
              -83.412569,
              45.245807
            ],
            [
              -83.388034,
              45.254976
            ],
            [
              -83.381743,
              45.268983
            ],
            [
              -83.385104,
              45.274195
            ],
            [
              -83.388274,
              45.276916
            ],
            [
              -83.401091,
              45.279572
            ],
            [
              -83.422389,
              45.290775
            ],
            [
              -83.42514,
              45.296808
            ],
            [
              -83.43304,
              45.303688
            ],
            [
              -83.445672,
              45.310612
            ],
            [
              -83.477794,
              45.341891
            ],
            [
              -83.488826,
              45.355872
            ],
            [
              -83.496704,
              45.357536
            ],
            [
              -83.514717,
              45.34646
            ],
            [
              -83.520258,
              45.347239
            ],
            [
              -83.538306,
              45.358167
            ],
            [
              -83.545729,
              45.358397
            ],
            [
              -83.546799,
              45.352637
            ],
            [
              -83.550268,
              45.350832
            ],
            [
              -83.570361,
              45.347198
            ],
            [
              -83.599273,
              45.352561
            ],
            [
              -83.64379,
              45.37171
            ],
            [
              -83.667934,
              45.384675
            ],
            [
              -83.697316,
              45.396239
            ],
            [
              -83.721815,
              45.413304
            ],
            [
              -83.737321,
              45.410943
            ],
            [
              -83.755569,
              45.411034
            ],
            [
              -83.773171,
              45.417302
            ],
            [
              -83.788777,
              45.416415
            ],
            [
              -83.806622,
              45.419159
            ],
            [
              -83.841543,
              45.435287
            ],
            [
              -83.85856,
              45.446865
            ],
            [
              -83.881813,
              45.467907
            ],
            [
              -83.909472,
              45.485784
            ],
            [
              -83.939261,
              45.493189
            ],
            [
              -83.978017,
              45.494138
            ],
            [
              -83.99835,
              45.491158
            ],
            [
              -84.009582,
              45.495069
            ],
            [
              -84.028813,
              45.497225
            ],
            [
              -84.036286,
              45.496245
            ],
            [
              -84.039958,
              45.493733
            ],
            [
              -84.056138,
              45.489349
            ],
            [
              -84.075792,
              45.490537
            ],
            [
              -84.095905,
              45.497298
            ],
            [
              -84.109238,
              45.505171
            ],
            [
              -84.116687,
              45.51305
            ],
            [
              -84.122309,
              45.523788
            ],
            [
              -84.126971,
              45.542428
            ],
            [
              -84.126532,
              45.556616
            ],
            [
              -84.128867,
              45.562284
            ],
            [
              -84.139462,
              45.573714
            ],
            [
              -84.157121,
              45.585305
            ],
            [
              -84.180514,
              45.604639
            ],
            [
              -84.196043,
              45.621456
            ],
            [
              -84.2042177111417,
              45.6271160041616
            ],
            [
              -84.25202,
              45.625998
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "031",
      "COUNTYNS": "01622958",
      "AFFGEOID": "0500000US26031",
      "GEOID": "26031",
      "NAME": "Cheboygan",
      "LSAD": "06",
      "ALAND": 1852552031,
      "AWATER": 440619039,
      "County_state": "Cheboygan,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.732687,
              45.289382
            ],
            [
              -84.734303,
              45.201936
            ],
            [
              -84.366614,
              45.198737
            ],
            [
              -84.246634,
              45.198727
            ],
            [
              -84.24938,
              45.282073
            ],
            [
              -84.25202,
              45.625998
            ],
            [
              -84.2042177111417,
              45.6271160041616
            ],
            [
              -84.215268,
              45.634767
            ],
            [
              -84.270238,
              45.64479
            ],
            [
              -84.289685,
              45.653296
            ],
            [
              -84.329537,
              45.66438
            ],
            [
              -84.376403,
              45.655565
            ],
            [
              -84.400283,
              45.663345
            ],
            [
              -84.413642,
              45.669427
            ],
            [
              -84.427495,
              45.669201
            ],
            [
              -84.435415,
              45.664106
            ],
            [
              -84.4422300917349,
              45.6549297586405
            ],
            [
              -84.442348,
              45.654771
            ],
            [
              -84.46168,
              45.652404
            ],
            [
              -84.47545339827211,
              45.659169564202095
            ],
            [
              -84.4981499283146,
              45.670318216708395
            ],
            [
              -84.538998,
              45.690383
            ],
            [
              -84.553311,
              45.698566
            ],
            [
              -84.555496,
              45.702268
            ],
            [
              -84.573631,
              45.710381
            ],
            [
              -84.604712,
              45.721668
            ],
            [
              -84.644822,
              45.73999
            ],
            [
              -84.679546,
              45.749095
            ],
            [
              -84.681967,
              45.756197
            ],
            [
              -84.715996,
              45.766174
            ],
            [
              -84.718904,
              45.777599
            ],
            [
              -84.726192,
              45.786905
            ],
            [
              -84.73239272681569,
              45.7879288720768
            ],
            [
              -84.730677,
              45.550985
            ],
            [
              -84.730288,
              45.464356
            ],
            [
              -84.730354,
              45.376159
            ],
            [
              -84.732687,
              45.289382
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "041",
      "COUNTYNS": "01622963",
      "AFFGEOID": "0500000US26041",
      "GEOID": "26041",
      "NAME": "Delta",
      "LSAD": "06",
      "ALAND": 3033137837,
      "AWATER": 2124389418,
      "County_state": "Delta,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.692393,
              45.617126
            ],
            [
              -86.712328,
              45.610939
            ],
            [
              -86.691705,
              45.595816
            ],
            [
              -86.661284,
              45.574176
            ],
            [
              -86.648792,
              45.543243
            ],
            [
              -86.636895,
              45.542053
            ],
            [
              -86.622023,
              45.55633
            ],
            [
              -86.620239,
              45.562279
            ],
            [
              -86.626187,
              45.573581
            ],
            [
              -86.644033,
              45.585479
            ],
            [
              -86.665746,
              45.606239
            ],
            [
              -86.67727,
              45.613689
            ],
            [
              -86.692393,
              45.617126
            ]
          ]
        ],
        [
          [
            [
              -86.78665,
              45.514562
            ],
            [
              -86.790268,
              45.501886
            ],
            [
              -86.782806,
              45.486782
            ],
            [
              -86.763739,
              45.473989
            ],
            [
              -86.750475,
              45.484135
            ],
            [
              -86.757548,
              45.504123
            ],
            [
              -86.769768,
              45.516252
            ],
            [
              -86.78665,
              45.514562
            ]
          ]
        ],
        [
          [
            [
              -87.367849,
              45.985321
            ],
            [
              -87.367673,
              45.898969
            ],
            [
              -87.325391,
              45.898665
            ],
            [
              -87.327502,
              45.55125
            ],
            [
              -87.26423095029679,
              45.550547571294594
            ],
            [
              -87.263488,
              45.552032
            ],
            [
              -87.234612,
              45.588817
            ],
            [
              -87.223647,
              45.599338
            ],
            [
              -87.196852,
              45.636275
            ],
            [
              -87.172241,
              45.661788
            ],
            [
              -87.129412,
              45.68171
            ],
            [
              -87.111638,
              45.685905
            ],
            [
              -87.099725,
              45.695231
            ],
            [
              -87.099401,
              45.698614
            ],
            [
              -87.095455,
              45.701039
            ],
            [
              -87.059533,
              45.708497
            ],
            [
              -87.070442,
              45.718779
            ],
            [
              -87.061721,
              45.732821
            ],
            [
              -87.057444,
              45.736822
            ],
            [
              -87.052908,
              45.747983
            ],
            [
              -87.05555,
              45.751535
            ],
            [
              -87.062406,
              45.753296
            ],
            [
              -87.064302,
              45.758828
            ],
            [
              -87.063975,
              45.76651
            ],
            [
              -87.058127,
              45.779152
            ],
            [
              -87.058844,
              45.80151
            ],
            [
              -87.057439,
              45.812483
            ],
            [
              -87.052043,
              45.821879
            ],
            [
              -87.039842,
              45.834245
            ],
            [
              -87.031435,
              45.837238
            ],
            [
              -87.018902,
              45.838886
            ],
            [
              -87.00508,
              45.831718
            ],
            [
              -86.988438,
              45.810621
            ],
            [
              -86.981624,
              45.792221
            ],
            [
              -86.981341,
              45.76616
            ],
            [
              -86.975224,
              45.75313
            ],
            [
              -86.977655,
              45.728768
            ],
            [
              -86.982413,
              45.719873
            ],
            [
              -86.984588,
              45.705812
            ],
            [
              -86.981349,
              45.696463
            ],
            [
              -86.969765,
              45.691895
            ],
            [
              -86.967315,
              45.684923
            ],
            [
              -86.966885,
              45.675001
            ],
            [
              -86.964275,
              45.672761
            ],
            [
              -86.944158,
              45.695833
            ],
            [
              -86.92106,
              45.697868
            ],
            [
              -86.904089,
              45.709546
            ],
            [
              -86.895342,
              45.711464
            ],
            [
              -86.876904,
              45.711891
            ],
            [
              -86.870392,
              45.710087
            ],
            [
              -86.838746,
              45.722307
            ],
            [
              -86.841818,
              45.729051
            ],
            [
              -86.838658,
              45.741831
            ],
            [
              -86.821814,
              45.757164
            ],
            [
              -86.820868,
              45.760776
            ],
            [
              -86.823743,
              45.765486
            ],
            [
              -86.821523,
              45.770356
            ],
            [
              -86.801476,
              45.780027
            ],
            [
              -86.785722,
              45.794517
            ],
            [
              -86.773279,
              45.811385
            ],
            [
              -86.774612,
              45.821696
            ],
            [
              -86.777225,
              45.827183
            ],
            [
              -86.782259,
              45.82995
            ],
            [
              -86.784177,
              45.854641
            ],
            [
              -86.78208,
              45.860195
            ],
            [
              -86.758449,
              45.867274
            ],
            [
              -86.749638,
              45.867796
            ],
            [
              -86.742466,
              45.864719
            ],
            [
              -86.72852,
              45.848759
            ],
            [
              -86.721113,
              45.845431
            ],
            [
              -86.645998,
              45.833888
            ],
            [
              -86.632478,
              45.843309
            ],
            [
              -86.633168,
              45.860068
            ],
            [
              -86.625736,
              45.868295
            ],
            [
              -86.613536,
              45.875982
            ],
            [
              -86.603293,
              45.876626
            ],
            [
              -86.593184,
              45.88511
            ],
            [
              -86.583304,
              45.898784
            ],
            [
              -86.567719,
              45.9005
            ],
            [
              -86.553608,
              45.896476
            ],
            [
              -86.541464,
              45.890234
            ],
            [
              -86.532989,
              45.882665
            ],
            [
              -86.529573,
              45.874974
            ],
            [
              -86.528224,
              45.856974
            ],
            [
              -86.529208,
              45.853043
            ],
            [
              -86.538831,
              45.840083
            ],
            [
              -86.545602,
              45.836495
            ],
            [
              -86.549723,
              45.836039
            ],
            [
              -86.555186,
              45.831696
            ],
            [
              -86.559044,
              45.822323
            ],
            [
              -86.555547,
              45.813499
            ],
            [
              -86.557215,
              45.808172
            ],
            [
              -86.563392,
              45.804469
            ],
            [
              -86.571172,
              45.805452
            ],
            [
              -86.576858,
              45.801473
            ],
            [
              -86.581759,
              45.794797
            ],
            [
              -86.581071,
              45.791802
            ],
            [
              -86.576869,
              45.788502
            ],
            [
              -86.583391,
              45.778242
            ],
            [
              -86.597661,
              45.775385
            ],
            [
              -86.612137,
              45.779356
            ],
            [
              -86.617336,
              45.783538
            ],
            [
              -86.631018,
              45.782019
            ],
            [
              -86.634902,
              45.763536
            ],
            [
              -86.633138,
              45.747654
            ],
            [
              -86.647319,
              45.732618
            ],
            [
              -86.662762,
              45.728964
            ],
            [
              -86.67148,
              45.72053
            ],
            [
              -86.669263,
              45.71086
            ],
            [
              -86.665511,
              45.70903
            ],
            [
              -86.665677,
              45.702217
            ],
            [
              -86.676184,
              45.691862
            ],
            [
              -86.689102,
              45.687862
            ],
            [
              -86.705184,
              45.690901
            ],
            [
              -86.715781,
              45.683949
            ],
            [
              -86.718191,
              45.67732
            ],
            [
              -86.717828,
              45.668106
            ],
            [
              -86.708038,
              45.649202
            ],
            [
              -86.695275,
              45.648175
            ],
            [
              -86.688772,
              45.639969
            ],
            [
              -86.687208,
              45.634253
            ],
            [
              -86.666127,
              45.621689
            ],
            [
              -86.648439,
              45.615992
            ],
            [
              -86.633224,
              45.618249
            ],
            [
              -86.62387,
              45.613262
            ],
            [
              -86.616893,
              45.606796
            ],
            [
              -86.616972,
              45.620581
            ],
            [
              -86.627938,
              45.659293
            ],
            [
              -86.625132,
              45.663819
            ],
            [
              -86.62043,
              45.667098
            ],
            [
              -86.611306,
              45.669733
            ],
            [
              -86.593613,
              45.665625
            ],
            [
              -86.587528,
              45.666456
            ],
            [
              -86.584771,
              45.682007
            ],
            [
              -86.585847,
              45.704922
            ],
            [
              -86.580936,
              45.71192
            ],
            [
              -86.570627,
              45.716412
            ],
            [
              -86.54143,
              45.70811
            ],
            [
              -86.537258,
              45.708361
            ],
            [
              -86.53328,
              45.710849
            ],
            [
              -86.525166,
              45.720797
            ],
            [
              -86.523197,
              45.736498
            ],
            [
              -86.518281,
              45.747688
            ],
            [
              -86.51457,
              45.752337
            ],
            [
              -86.504216,
              45.75423
            ],
            [
              -86.496251,
              45.749255
            ],
            [
              -86.486028,
              45.746608
            ],
            [
              -86.47905,
              45.757416
            ],
            [
              -86.466039,
              45.759741
            ],
            [
              -86.4598661599231,
              45.7580422203082
            ],
            [
              -86.459114,
              45.896363
            ],
            [
              -86.49025,
              45.896337
            ],
            [
              -86.490142,
              46.158178
            ],
            [
              -86.615601,
              46.158691
            ],
            [
              -87.116138,
              46.15905
            ],
            [
              -87.241506,
              46.159103
            ],
            [
              -87.242885,
              45.985117
            ],
            [
              -87.367849,
              45.985321
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "021",
      "COUNTYNS": "01622953",
      "AFFGEOID": "0500000US26021",
      "GEOID": "26021",
      "NAME": "Berrien",
      "LSAD": "06",
      "ALAND": 1470473558,
      "AWATER": 2625519926,
      "County_state": "Berrien,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.824828,
              41.76024
            ],
            [
              -86.823628,
              41.76024
            ],
            [
              -86.81607,
              41.76024
            ],
            [
              -86.804427,
              41.76024
            ],
            [
              -86.801578,
              41.76024
            ],
            [
              -86.800707,
              41.76024
            ],
            [
              -86.800611,
              41.760251
            ],
            [
              -86.750999806915,
              41.7599827037259
            ],
            [
              -86.748096,
              41.759967
            ],
            [
              -86.746521,
              41.759982
            ],
            [
              -86.6413638204539,
              41.7596335891616
            ],
            [
              -86.641186,
              41.759633
            ],
            [
              -86.640044,
              41.759671
            ],
            [
              -86.524222859982,
              41.7594561006795
            ],
            [
              -86.519318,
              41.759447
            ],
            [
              -86.501773,
              41.759553
            ],
            [
              -86.2724268033895,
              41.76018781596849
            ],
            [
              -86.265496,
              41.760207
            ],
            [
              -86.22609688873129,
              41.760016130263004
            ],
            [
              -86.22345,
              41.898721
            ],
            [
              -86.222821,
              41.984593
            ],
            [
              -86.22294,
              42.071484
            ],
            [
              -86.223563,
              42.243379
            ],
            [
              -86.3648801403901,
              42.2431332189299
            ],
            [
              -86.385179,
              42.217279
            ],
            [
              -86.4021828119479,
              42.1985422641055
            ],
            [
              -86.404146,
              42.196379
            ],
            [
              -86.43714775545149,
              42.163453219354096
            ],
            [
              -86.466262,
              42.134406
            ],
            [
              -86.485223,
              42.118239
            ],
            [
              -86.490122,
              42.105139
            ],
            [
              -86.501322,
              42.08454
            ],
            [
              -86.5088562951768,
              42.0729856546606
            ],
            [
              -86.5321611984015,
              42.0372460275771
            ],
            [
              -86.556421,
              42.000042
            ],
            [
              -86.56472203524389,
              41.9814274695013
            ],
            [
              -86.5815175823245,
              41.943764549854905
            ],
            [
              -86.582197,
              41.942241
            ],
            [
              -86.597899,
              41.918291
            ],
            [
              -86.6160290199382,
              41.89770274359329
            ],
            [
              -86.619442,
              41.893827
            ],
            [
              -86.679355,
              41.844793
            ],
            [
              -86.717037,
              41.819349
            ],
            [
              -86.777227,
              41.78474
            ],
            [
              -86.824828,
              41.76024
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "005",
      "COUNTYNS": "01622945",
      "AFFGEOID": "0500000US26005",
      "GEOID": "26005",
      "NAME": "Allegan",
      "LSAD": "06",
      "ALAND": 2137372873,
      "AWATER": 2610825889,
      "County_state": "Allegan,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.261573,
              42.443894
            ],
            [
              -86.2738932808627,
              42.4192800027482
            ],
            [
              -85.764697,
              42.420715
            ],
            [
              -85.543191,
              42.421433
            ],
            [
              -85.543513,
              42.594812
            ],
            [
              -85.545564,
              42.768139
            ],
            [
              -85.663296,
              42.768099
            ],
            [
              -85.782498,
              42.7682
            ],
            [
              -85.881053,
              42.767498
            ],
            [
              -86.126702,
              42.768402
            ],
            [
              -86.137046,
              42.768342
            ],
            [
              -86.166564,
              42.768143
            ],
            [
              -86.2088855875804,
              42.7675396391759
            ],
            [
              -86.208309,
              42.762789
            ],
            [
              -86.20787162455001,
              42.7499301617704
            ],
            [
              -86.206834,
              42.719424
            ],
            [
              -86.208654,
              42.69209
            ],
            [
              -86.2093519645706,
              42.6894674687183
            ],
            [
              -86.21602,
              42.664413
            ],
            [
              -86.226638,
              42.644922
            ],
            [
              -86.22905,
              42.637693
            ],
            [
              -86.225613,
              42.594765
            ],
            [
              -86.228082,
              42.583397
            ],
            [
              -86.23528,
              42.564958
            ],
            [
              -86.240642,
              42.54
            ],
            [
              -86.24971,
              42.480212
            ],
            [
              -86.261573,
              42.443894
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "089",
      "COUNTYNS": "01622987",
      "AFFGEOID": "0500000US26089",
      "GEOID": "26089",
      "NAME": "Leelanau",
      "LSAD": "06",
      "ALAND": 899206779,
      "AWATER": 5659140425,
      "County_state": "Leelanau,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.792961,
              45.481976
            ],
            [
              -85.78679,
              45.472203
            ],
            [
              -85.771572,
              45.460571
            ],
            [
              -85.768735,
              45.467876
            ],
            [
              -85.769076,
              45.480322
            ],
            [
              -85.775375,
              45.490154
            ],
            [
              -85.782646,
              45.491596
            ],
            [
              -85.792961,
              45.481976
            ]
          ]
        ],
        [
          [
            [
              -85.88301,
              45.443479
            ],
            [
              -85.873386,
              45.421482
            ],
            [
              -85.833516,
              45.378175
            ],
            [
              -85.825955,
              45.404296
            ],
            [
              -85.834891,
              45.428356
            ],
            [
              -85.858263,
              45.440042
            ],
            [
              -85.88301,
              45.443479
            ]
          ]
        ],
        [
          [
            [
              -86.065016,
              45.140266
            ],
            [
              -86.060396,
              45.104617
            ],
            [
              -86.058653,
              45.100776
            ],
            [
              -86.052424,
              45.095311
            ],
            [
              -86.037129,
              45.086576
            ],
            [
              -86.019874,
              45.071665
            ],
            [
              -86.013073,
              45.063774
            ],
            [
              -85.99736,
              45.055929
            ],
            [
              -85.976883,
              45.06266
            ],
            [
              -85.977082,
              45.072993
            ],
            [
              -85.982799,
              45.080787
            ],
            [
              -85.984095,
              45.087073
            ],
            [
              -85.980433,
              45.113046
            ],
            [
              -85.976434,
              45.120706
            ],
            [
              -85.976803,
              45.138363
            ],
            [
              -85.989412,
              45.151069
            ],
            [
              -85.993194,
              45.152805
            ],
            [
              -86.005946,
              45.155751
            ],
            [
              -86.033174,
              45.15842
            ],
            [
              -86.04443,
              45.159582
            ],
            [
              -86.050473,
              45.158418
            ],
            [
              -86.059393,
              45.152291
            ],
            [
              -86.065016,
              45.140266
            ]
          ]
        ],
        [
          [
            [
              -86.071746,
              44.804717
            ],
            [
              -86.07308648269859,
              44.7781251744663
            ],
            [
              -85.937084,
              44.778345
            ],
            [
              -85.815119,
              44.774534
            ],
            [
              -85.6402156770347,
              44.7750502299767
            ],
            [
              -85.640781,
              44.775561
            ],
            [
              -85.637,
              44.790078
            ],
            [
              -85.641652,
              44.810816
            ],
            [
              -85.651435,
              44.831624
            ],
            [
              -85.652355,
              44.849092
            ],
            [
              -85.648932,
              44.87401
            ],
            [
              -85.645456,
              44.883645
            ],
            [
              -85.639842,
              44.890255
            ],
            [
              -85.625497,
              44.921107
            ],
            [
              -85.621403,
              44.923123
            ],
            [
              -85.602034,
              44.926743
            ],
            [
              -85.602356,
              44.974272
            ],
            [
              -85.604301,
              44.990983
            ],
            [
              -85.606588,
              44.990662
            ],
            [
              -85.620797,
              44.974242
            ],
            [
              -85.633513,
              44.966843
            ],
            [
              -85.641475,
              44.968189
            ],
            [
              -85.649318,
              44.974074
            ],
            [
              -85.635652,
              44.993239
            ],
            [
              -85.629829,
              45.002483
            ],
            [
              -85.621878,
              45.004529
            ],
            [
              -85.609123,
              45.013103
            ],
            [
              -85.599652,
              45.021749
            ],
            [
              -85.597181,
              45.040547
            ],
            [
              -85.590054,
              45.052144
            ],
            [
              -85.579797,
              45.055145
            ],
            [
              -85.573976,
              45.043361
            ],
            [
              -85.57016,
              45.041278
            ],
            [
              -85.56613,
              45.043633
            ],
            [
              -85.566066,
              45.059201
            ],
            [
              -85.573353,
              45.068382
            ],
            [
              -85.583198,
              45.071304
            ],
            [
              -85.609266,
              45.11351
            ],
            [
              -85.614319,
              45.127562
            ],
            [
              -85.599801,
              45.149286
            ],
            [
              -85.590434,
              45.153175
            ],
            [
              -85.573893,
              45.155488
            ],
            [
              -85.570178,
              45.155145
            ],
            [
              -85.568489,
              45.153762
            ],
            [
              -85.566493,
              45.153762
            ],
            [
              -85.564897,
              45.153962
            ],
            [
              -85.5639,
              45.154361
            ],
            [
              -85.563102,
              45.155358
            ],
            [
              -85.562104,
              45.156954
            ],
            [
              -85.56168,
              45.15894
            ],
            [
              -85.552179,
              45.167352
            ],
            [
              -85.536892,
              45.173385
            ],
            [
              -85.531461,
              45.177247
            ],
            [
              -85.526734,
              45.189316
            ],
            [
              -85.540497,
              45.210169
            ],
            [
              -85.551072,
              45.210742
            ],
            [
              -85.561809,
              45.200524
            ],
            [
              -85.564654,
              45.192546
            ],
            [
              -85.585986,
              45.180381
            ],
            [
              -85.593064,
              45.178527
            ],
            [
              -85.606963,
              45.178477
            ],
            [
              -85.611684,
              45.181104
            ],
            [
              -85.613174,
              45.184624
            ],
            [
              -85.618639,
              45.186771
            ],
            [
              -85.656024,
              45.145788
            ],
            [
              -85.674861,
              45.116216
            ],
            [
              -85.675671,
              45.10554
            ],
            [
              -85.681096,
              45.092693
            ],
            [
              -85.695715,
              45.076461
            ],
            [
              -85.712262,
              45.065622
            ],
            [
              -85.740836,
              45.055575
            ],
            [
              -85.746444,
              45.051229
            ],
            [
              -85.761943,
              45.023454
            ],
            [
              -85.771395,
              45.015181
            ],
            [
              -85.776207,
              45.000574
            ],
            [
              -85.778278,
              44.983075
            ],
            [
              -85.780439,
              44.977932
            ],
            [
              -85.807403,
              44.949814
            ],
            [
              -85.815451,
              44.945631
            ],
            [
              -85.83615,
              44.940256
            ],
            [
              -85.854304,
              44.938147
            ],
            [
              -85.869852,
              44.939031
            ],
            [
              -85.879934,
              44.943305
            ],
            [
              -85.891543,
              44.957783
            ],
            [
              -85.897626,
              44.962014
            ],
            [
              -85.915851,
              44.968307
            ],
            [
              -85.9316,
              44.968788
            ],
            [
              -85.938589,
              44.964559
            ],
            [
              -85.942099,
              44.954317
            ],
            [
              -85.952721,
              44.940758
            ],
            [
              -85.961603,
              44.935567
            ],
            [
              -85.967169,
              44.929484
            ],
            [
              -85.972824,
              44.914781
            ],
            [
              -85.980219,
              44.906136
            ],
            [
              -85.992535,
              44.900026
            ],
            [
              -86.009355,
              44.899454
            ],
            [
              -86.021513,
              44.902774
            ],
            [
              -86.031194,
              44.907349
            ],
            [
              -86.038332,
              44.915696
            ],
            [
              -86.058862,
              44.911012
            ],
            [
              -86.066745,
              44.905685
            ],
            [
              -86.07099,
              44.895876
            ],
            [
              -86.072468,
              44.884788
            ],
            [
              -86.071112,
              44.86542
            ],
            [
              -86.066031,
              44.834852
            ],
            [
              -86.065966,
              44.821522
            ],
            [
              -86.071746,
              44.804717
            ]
          ]
        ],
        [
          [
            [
              -86.156689,
              45.010535
            ],
            [
              -86.154824,
              45.002394
            ],
            [
              -86.133655,
              44.996874
            ],
            [
              -86.115699,
              44.999093
            ],
            [
              -86.093536,
              45.007838
            ],
            [
              -86.101214,
              45.018101
            ],
            [
              -86.101894,
              45.022811
            ],
            [
              -86.100315,
              45.02624
            ],
            [
              -86.097094,
              45.030128
            ],
            [
              -86.093451,
              45.03166
            ],
            [
              -86.079103,
              45.030795
            ],
            [
              -86.093166,
              45.041492
            ],
            [
              -86.117908,
              45.048478
            ],
            [
              -86.138095,
              45.043038
            ],
            [
              -86.141644,
              45.040251
            ],
            [
              -86.154557,
              45.018102
            ],
            [
              -86.156689,
              45.010535
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "083",
      "COUNTYNS": "01622984",
      "AFFGEOID": "0500000US26083",
      "GEOID": "26083",
      "NAME": "Keweenaw",
      "LSAD": "06",
      "ALAND": 1398883253,
      "AWATER": 14053673397,
      "County_state": "Keweenaw,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.652303,
              47.41621
            ],
            [
              -87.639034,
              47.410443
            ],
            [
              -87.617531,
              47.407711
            ],
            [
              -87.600342,
              47.407711
            ],
            [
              -87.586224,
              47.418336
            ],
            [
              -87.591498,
              47.424113
            ],
            [
              -87.623673,
              47.426283
            ],
            [
              -87.637884,
              47.420449
            ],
            [
              -87.652303,
              47.41621
            ]
          ]
        ],
        [
          [
            [
              -88.50078,
              47.293503
            ],
            [
              -88.512421220678,
              47.2859484572122
            ],
            [
              -88.298992,
              47.286097
            ],
            [
              -88.298436,
              47.199782
            ],
            [
              -88.2275522695528,
              47.199937822012
            ],
            [
              -88.212361,
              47.209423
            ],
            [
              -88.204849,
              47.210498
            ],
            [
              -88.194218,
              47.209242
            ],
            [
              -88.163059,
              47.216278
            ],
            [
              -88.131943,
              47.239554
            ],
            [
              -88.117456,
              47.255174
            ],
            [
              -88.108833,
              47.259131
            ],
            [
              -88.096851,
              47.261351
            ],
            [
              -88.071476,
              47.286768
            ],
            [
              -88.06009,
              47.295796
            ],
            [
              -88.054849,
              47.29824
            ],
            [
              -88.016478,
              47.306275
            ],
            [
              -87.989133,
              47.322633
            ],
            [
              -87.968604,
              47.332582
            ],
            [
              -87.958386,
              47.334435
            ],
            [
              -87.946352,
              47.334254
            ],
            [
              -87.94336,
              47.335899
            ],
            [
              -87.93825,
              47.342299
            ],
            [
              -87.938787,
              47.346777
            ],
            [
              -87.947397,
              47.355461
            ],
            [
              -87.954796,
              47.356809
            ],
            [
              -87.962567,
              47.362543
            ],
            [
              -87.965598,
              47.368645
            ],
            [
              -87.965063,
              47.37443
            ],
            [
              -87.957058,
              47.38726
            ],
            [
              -87.941613,
              47.390073
            ],
            [
              -87.882245,
              47.395588
            ],
            [
              -87.8567,
              47.395387
            ],
            [
              -87.848252,
              47.394864
            ],
            [
              -87.834822,
              47.390478
            ],
            [
              -87.827115,
              47.38616
            ],
            [
              -87.815371,
              47.38479
            ],
            [
              -87.800294,
              47.392148
            ],
            [
              -87.765019,
              47.398652
            ],
            [
              -87.759057,
              47.403013
            ],
            [
              -87.75138,
              47.405066
            ],
            [
              -87.742417,
              47.405823
            ],
            [
              -87.721274,
              47.401032
            ],
            [
              -87.712421,
              47.4014
            ],
            [
              -87.710471,
              47.4062
            ],
            [
              -87.715942,
              47.439816
            ],
            [
              -87.730804,
              47.449112
            ],
            [
              -87.756739,
              47.460717
            ],
            [
              -87.801184,
              47.473301
            ],
            [
              -87.816958,
              47.471998
            ],
            [
              -87.898036,
              47.474872
            ],
            [
              -87.902416,
              47.477045
            ],
            [
              -87.929269,
              47.478737
            ],
            [
              -87.978934,
              47.47942
            ],
            [
              -88.040291,
              47.475999
            ],
            [
              -88.048077,
              47.474973
            ],
            [
              -88.048226,
              47.470008
            ],
            [
              -88.049326,
              47.469785
            ],
            [
              -88.076388,
              47.467752
            ],
            [
              -88.085252,
              47.468961
            ],
            [
              -88.139651,
              47.462693
            ],
            [
              -88.150571,
              47.460093
            ],
            [
              -88.18182,
              47.457657
            ],
            [
              -88.217822,
              47.448738
            ],
            [
              -88.216977,
              47.445493
            ],
            [
              -88.218424,
              47.441585
            ],
            [
              -88.227446,
              47.435093
            ],
            [
              -88.239161,
              47.429969
            ],
            [
              -88.285195,
              47.422392
            ],
            [
              -88.303447,
              47.412204
            ],
            [
              -88.324083,
              47.403542
            ],
            [
              -88.389459,
              47.384431
            ],
            [
              -88.418673,
              47.371188
            ],
            [
              -88.459262,
              47.339903
            ],
            [
              -88.470484,
              47.327653
            ],
            [
              -88.477733,
              47.31346
            ],
            [
              -88.50078,
              47.293503
            ]
          ]
        ],
        [
          [
            [
              -89.255202,
              47.876102
            ],
            [
              -89.250936,
              47.870377
            ],
            [
              -89.246774,
              47.871016
            ],
            [
              -89.228507,
              47.858039
            ],
            [
              -89.234535,
              47.855373
            ],
            [
              -89.235552,
              47.853774
            ],
            [
              -89.234533,
              47.851718
            ],
            [
              -89.201812,
              47.850243
            ],
            [
              -89.192207,
              47.84106
            ],
            [
              -89.192681,
              47.83343
            ],
            [
              -89.19017,
              47.831603
            ],
            [
              -89.157738,
              47.824015
            ],
            [
              -89.124134,
              47.828616
            ],
            [
              -89.107991,
              47.835705
            ],
            [
              -89.056412,
              47.852598
            ],
            [
              -89.044463,
              47.85575
            ],
            [
              -89.022736,
              47.858532
            ],
            [
              -88.998939,
              47.86749
            ],
            [
              -88.911665,
              47.891344
            ],
            [
              -88.898986,
              47.900685
            ],
            [
              -88.899698,
              47.902445
            ],
            [
              -88.95347,
              47.890504
            ],
            [
              -88.971471,
              47.895068
            ],
            [
              -88.994637,
              47.891566
            ],
            [
              -89.004858,
              47.896427
            ],
            [
              -89.003075,
              47.908537
            ],
            [
              -88.962664,
              47.923512
            ],
            [
              -88.923573,
              47.937976
            ],
            [
              -88.918029,
              47.945605
            ],
            [
              -88.899184,
              47.9533
            ],
            [
              -88.852923,
              47.965322
            ],
            [
              -88.832063,
              47.965213
            ],
            [
              -88.791959,
              47.978938
            ],
            [
              -88.772357,
              47.981126
            ],
            [
              -88.718555,
              47.995134
            ],
            [
              -88.670073,
              48.011446
            ],
            [
              -88.579784,
              48.058669
            ],
            [
              -88.575048,
              48.064154
            ],
            [
              -88.573924,
              48.068861
            ],
            [
              -88.575869,
              48.075166
            ],
            [
              -88.578395,
              48.078003
            ],
            [
              -88.578053,
              48.084373
            ],
            [
              -88.566938,
              48.093719
            ],
            [
              -88.511902,
              48.121699
            ],
            [
              -88.4857,
              48.137683
            ],
            [
              -88.469573,
              48.152879
            ],
            [
              -88.459697,
              48.158551
            ],
            [
              -88.449502,
              48.163312
            ],
            [
              -88.427373,
              48.166764
            ],
            [
              -88.419875,
              48.170731
            ],
            [
              -88.418244,
              48.18037
            ],
            [
              -88.422601,
              48.190975
            ],
            [
              -88.447236,
              48.182916
            ],
            [
              -88.459735,
              48.183807
            ],
            [
              -88.482039,
              48.179915
            ],
            [
              -88.491961,
              48.175466
            ],
            [
              -88.501088,
              48.168181
            ],
            [
              -88.524753,
              48.165291
            ],
            [
              -88.547033,
              48.174891
            ],
            [
              -88.578413,
              48.16237
            ],
            [
              -88.614026,
              48.154797
            ],
            [
              -88.656915,
              48.139225
            ],
            [
              -88.674192,
              48.127165
            ],
            [
              -88.676395,
              48.124876
            ],
            [
              -88.675628,
              48.120444
            ],
            [
              -88.684434,
              48.115785
            ],
            [
              -88.695353,
              48.110549
            ],
            [
              -88.705586,
              48.111013
            ],
            [
              -88.728198,
              48.101914
            ],
            [
              -88.744458,
              48.092769
            ],
            [
              -88.764256,
              48.085189
            ],
            [
              -88.77183,
              48.079457
            ],
            [
              -88.772077,
              48.070502
            ],
            [
              -88.787556,
              48.063035
            ],
            [
              -88.810461,
              48.055247
            ],
            [
              -88.816084,
              48.057006
            ],
            [
              -88.835714,
              48.056752
            ],
            [
              -88.893701,
              48.03477
            ],
            [
              -88.896327,
              48.031801
            ],
            [
              -88.895069,
              48.029059
            ],
            [
              -88.915032,
              48.020681
            ],
            [
              -88.927529,
              48.019615
            ],
            [
              -88.931487,
              48.021637
            ],
            [
              -88.940886,
              48.01959
            ],
            [
              -88.994163,
              48.00229
            ],
            [
              -89.018303,
              47.992525
            ],
            [
              -89.095207,
              47.967922
            ],
            [
              -89.179154,
              47.93503
            ],
            [
              -89.214499,
              47.913895
            ],
            [
              -89.221332,
              47.908069
            ],
            [
              -89.22071,
              47.90085
            ],
            [
              -89.226327,
              47.895438
            ],
            [
              -89.247127,
              47.888503
            ],
            [
              -89.255202,
              47.876102
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "125",
      "COUNTYNS": "01623005",
      "AFFGEOID": "0500000US26125",
      "GEOID": "26125",
      "NAME": "Oakland",
      "LSAD": "06",
      "ALAND": 2246869568,
      "AWATER": 102863438,
      "County_state": "Oakland,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.689384,
              42.871263
            ],
            [
              -83.687476,
              42.819585
            ],
            [
              -83.686493,
              42.783263
            ],
            [
              -83.68278,
              42.69558
            ],
            [
              -83.677115,
              42.606255
            ],
            [
              -83.670904,
              42.519609
            ],
            [
              -83.664808,
              42.431179
            ],
            [
              -83.551907,
              42.435166
            ],
            [
              -83.433734,
              42.43894
            ],
            [
              -83.433672,
              42.438942
            ],
            [
              -83.375469,
              42.440557
            ],
            [
              -83.367896,
              42.440774
            ],
            [
              -83.316825,
              42.442033
            ],
            [
              -83.203558,
              42.444748
            ],
            [
              -83.200522,
              42.444839
            ],
            [
              -83.181005,
              42.445343
            ],
            [
              -83.16696,
              42.445683
            ],
            [
              -83.151986,
              42.446007
            ],
            [
              -83.14206,
              42.446229
            ],
            [
              -83.083393,
              42.447153
            ],
            [
              -83.084523,
              42.476345
            ],
            [
              -83.086882,
              42.534792
            ],
            [
              -83.0899,
              42.593009
            ],
            [
              -83.091449,
              42.624208
            ],
            [
              -83.095441,
              42.712499
            ],
            [
              -83.102891,
              42.888647
            ],
            [
              -83.336678,
              42.882788
            ],
            [
              -83.453364,
              42.880432
            ],
            [
              -83.453297,
              42.876307
            ],
            [
              -83.57105,
              42.873277
            ],
            [
              -83.689384,
              42.871263
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "045",
      "COUNTYNS": "01622965",
      "AFFGEOID": "0500000US26045",
      "GEOID": "26045",
      "NAME": "Eaton",
      "LSAD": "06",
      "ALAND": 1489714027,
      "AWATER": 11011332,
      "County_state": "Eaton,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.074245,
              42.770784
            ],
            [
              -85.071609,
              42.421428
            ],
            [
              -84.835922,
              42.421801
            ],
            [
              -84.718493,
              42.42152
            ],
            [
              -84.600403,
              42.421984
            ],
            [
              -84.601272,
              42.509444
            ],
            [
              -84.603048,
              42.665584
            ],
            [
              -84.602761,
              42.76988
            ],
            [
              -84.837085,
              42.770479
            ],
            [
              -85.074245,
              42.770784
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "025",
      "COUNTYNS": "01622955",
      "AFFGEOID": "0500000US26025",
      "GEOID": "26025",
      "NAME": "Calhoun",
      "LSAD": "06",
      "ALAND": 1829198151,
      "AWATER": 31268858,
      "County_state": "Calhoun,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.298879,
              42.419849
            ],
            [
              -85.299357,
              42.412264
            ],
            [
              -85.296996,
              42.246071
            ],
            [
              -85.293626,
              42.071553
            ],
            [
              -84.826491,
              42.072468
            ],
            [
              -84.709556,
              42.070366
            ],
            [
              -84.711481,
              42.159146
            ],
            [
              -84.716938,
              42.246696
            ],
            [
              -84.718602,
              42.334535
            ],
            [
              -84.718493,
              42.42152
            ],
            [
              -84.835922,
              42.421801
            ],
            [
              -85.071609,
              42.421428
            ],
            [
              -85.298879,
              42.419849
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "26",
      "COUNTYFP": "165",
      "COUNTYNS": "01623023",
      "AFFGEOID": "0500000US26165",
      "GEOID": "26165",
      "NAME": "Wexford",
      "LSAD": "06",
      "ALAND": 1463234116,
      "AWATER": 27096654,
      "County_state": "Wexford,26"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.817802,
              44.512969
            ],
            [
              -85.821186,
              44.164197
            ],
            [
              -85.564554,
              44.164861
            ],
            [
              -85.44442,
              44.165002
            ],
            [
              -85.334777,
              44.165118
            ],
            [
              -85.338404,
              44.425376
            ],
            [
              -85.334,
              44.512303
            ],
            [
              -85.817802,
              44.512969
            ]
          ]
        ]
      ]
    }
  }
]
};