export const counties_ut = {
  "type":"FeatureCollection","name":"ut_counties","features":[
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "49",
      "COUNTYFP": "043",
      "COUNTYNS": "01448035",
      "AFFGEOID": "0500000US49043",
      "GEOID": "49043",
      "NAME": "Summit",
      "LSAD": "06",
      "ALAND": 4844778499,
      "AWATER": 26615947,
      "County_state": "Summit,49"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -111.641052,
              40.798925
            ],
            [
              -111.649129,
              40.773443
            ],
            [
              -111.623483,
              40.75347
            ],
            [
              -111.623469,
              40.731739
            ],
            [
              -111.606221,
              40.712856
            ],
            [
              -111.60039,
              40.708475
            ],
            [
              -111.592726,
              40.69912
            ],
            [
              -111.604411,
              40.666523
            ],
            [
              -111.596736,
              40.659767
            ],
            [
              -111.578242,
              40.644086
            ],
            [
              -111.553266,
              40.609313
            ],
            [
              -111.527947,
              40.611962
            ],
            [
              -111.489265,
              40.599438
            ],
            [
              -111.486884,
              40.620844
            ],
            [
              -111.473319,
              40.64377
            ],
            [
              -111.430367,
              40.665324
            ],
            [
              -111.43687,
              40.680587
            ],
            [
              -111.412416,
              40.681101
            ],
            [
              -111.397494,
              40.690935
            ],
            [
              -111.389635,
              40.677838
            ],
            [
              -111.390198,
              40.660643
            ],
            [
              -111.368623,
              40.628944
            ],
            [
              -111.327178,
              40.623126
            ],
            [
              -111.30327,
              40.605904
            ],
            [
              -111.265338,
              40.587251
            ],
            [
              -111.238337,
              40.577557
            ],
            [
              -111.214414,
              40.574582
            ],
            [
              -111.181189,
              40.558537
            ],
            [
              -111.15136,
              40.548352
            ],
            [
              -111.137059,
              40.558394
            ],
            [
              -111.131228,
              40.57836
            ],
            [
              -111.12087,
              40.590457
            ],
            [
              -111.080411,
              40.594447
            ],
            [
              -111.055339,
              40.581702
            ],
            [
              -111.024901,
              40.577504
            ],
            [
              -111.00713,
              40.592776
            ],
            [
              -110.976086,
              40.592954
            ],
            [
              -110.961039,
              40.607626
            ],
            [
              -110.962932,
              40.615239
            ],
            [
              -110.944959,
              40.646362
            ],
            [
              -110.948823,
              40.661331
            ],
            [
              -110.933957,
              40.680354
            ],
            [
              -110.901974,
              40.678162
            ],
            [
              -110.898714,
              40.68885
            ],
            [
              -110.90228,
              40.707159
            ],
            [
              -110.89551,
              40.713597
            ],
            [
              -110.889916,
              40.714665
            ],
            [
              -110.885434,
              40.715636
            ],
            [
              -110.886589,
              40.717637
            ],
            [
              -110.88835,
              40.720305
            ],
            [
              -110.891617,
              40.72088
            ],
            [
              -110.893551,
              40.722494
            ],
            [
              -110.892941,
              40.725836
            ],
            [
              -110.89021,
              40.72714
            ],
            [
              -110.881191,
              40.727743
            ],
            [
              -110.874495,
              40.727839
            ],
            [
              -110.871045,
              40.726458
            ],
            [
              -110.867409,
              40.724197
            ],
            [
              -110.863648,
              40.721868
            ],
            [
              -110.857769,
              40.721911
            ],
            [
              -110.852769,
              40.723134
            ],
            [
              -110.849818,
              40.72454
            ],
            [
              -110.846248,
              40.724477
            ],
            [
              -110.843965,
              40.723361
            ],
            [
              -110.840282,
              40.721203
            ],
            [
              -110.836902,
              40.718838
            ],
            [
              -110.832427,
              40.718098
            ],
            [
              -110.827718,
              40.7146
            ],
            [
              -110.823957,
              40.710761
            ],
            [
              -110.818979,
              40.710753
            ],
            [
              -110.814075,
              40.7112
            ],
            [
              -110.809239,
              40.711593
            ],
            [
              -110.804917,
              40.712127
            ],
            [
              -110.800792,
              40.713782
            ],
            [
              -110.797687,
              40.716366
            ],
            [
              -110.79303,
              40.719867
            ],
            [
              -110.789709,
              40.723568
            ],
            [
              -110.786245,
              40.727309
            ],
            [
              -110.783536,
              40.730169
            ],
            [
              -110.780664,
              40.730951
            ],
            [
              -110.775325,
              40.731487
            ],
            [
              -110.771464,
              40.733425
            ],
            [
              -110.767902,
              40.735891
            ],
            [
              -110.765313,
              40.738075
            ],
            [
              -110.766052,
              40.740035
            ],
            [
              -110.764583,
              40.741816
            ],
            [
              -110.762065,
              40.741725
            ],
            [
              -110.757094,
              40.742733
            ],
            [
              -110.754348,
              40.745457
            ],
            [
              -110.75119,
              40.747337
            ],
            [
              -110.747753,
              40.746438
            ],
            [
              -110.743808,
              40.744898
            ],
            [
              -110.739431,
              40.744884
            ],
            [
              -110.73547,
              40.74614
            ],
            [
              -110.732828,
              40.746332
            ],
            [
              -110.728237,
              40.745142
            ],
            [
              -110.723794,
              40.745428
            ],
            [
              -110.721876,
              40.746707
            ],
            [
              -110.719386,
              40.748746
            ],
            [
              -110.716305,
              40.750257
            ],
            [
              -110.713927,
              40.751912
            ],
            [
              -110.711244,
              40.752326
            ],
            [
              -110.707623,
              40.750163
            ],
            [
              -110.703589,
              40.748856
            ],
            [
              -110.698954,
              40.747046
            ],
            [
              -110.69614,
              40.74589
            ],
            [
              -110.6929,
              40.74335
            ],
            [
              -110.690482,
              40.741494
            ],
            [
              -110.686484,
              40.741306
            ],
            [
              -110.68169,
              40.740825
            ],
            [
              -110.678847,
              40.739578
            ],
            [
              -110.675602,
              40.739027
            ],
            [
              -110.671161,
              40.740633
            ],
            [
              -110.667236,
              40.741649
            ],
            [
              -110.662884,
              40.741677
            ],
            [
              -110.659453,
              40.740608
            ],
            [
              -110.656482,
              40.740275
            ],
            [
              -110.654163,
              40.742079
            ],
            [
              -110.651369,
              40.744273
            ],
            [
              -110.648726,
              40.746702
            ],
            [
              -110.647049,
              40.749178
            ],
            [
              -110.64705,
              40.752318
            ],
            [
              -110.643333,
              40.754254
            ],
            [
              -110.639584,
              40.757191
            ],
            [
              -110.635788,
              40.761427
            ],
            [
              -110.633909,
              40.764826
            ],
            [
              -110.630887,
              40.767064
            ],
            [
              -110.627069,
              40.768741
            ],
            [
              -110.622812,
              40.769195
            ],
            [
              -110.619805,
              40.766623
            ],
            [
              -110.617798,
              40.764323
            ],
            [
              -110.614117,
              40.762717
            ],
            [
              -110.610788,
              40.760665
            ],
            [
              -110.607634,
              40.758731
            ],
            [
              -110.605334,
              40.757322
            ],
            [
              -110.601597,
              40.757156
            ],
            [
              -110.596276,
              40.757458
            ],
            [
              -110.592302,
              40.760061
            ],
            [
              -110.588833,
              40.761886
            ],
            [
              -110.585114,
              40.762424
            ],
            [
              -110.582591,
              40.761576
            ],
            [
              -110.559634,
              40.760737
            ],
            [
              -110.548666,
              40.773351
            ],
            [
              -110.541776,
              40.771807
            ],
            [
              -110.536468,
              40.771678
            ],
            [
              -110.533152,
              40.77057
            ],
            [
              -110.53073,
              40.767092
            ],
            [
              -110.527517,
              40.763272
            ],
            [
              -110.523512,
              40.761504
            ],
            [
              -110.51434,
              40.764277
            ],
            [
              -110.510073,
              40.765295
            ],
            [
              -110.50616,
              40.765678
            ],
            [
              -110.50229,
              40.766829
            ],
            [
              -110.498385,
              40.768415
            ],
            [
              -110.495083,
              40.771734
            ],
            [
              -110.490816,
              40.775016
            ],
            [
              -110.489232,
              40.777529
            ],
            [
              -110.485934,
              40.779653
            ],
            [
              -110.484379,
              40.778403
            ],
            [
              -110.478965,
              40.774352
            ],
            [
              -110.472953,
              40.771945
            ],
            [
              -110.467101,
              40.773149
            ],
            [
              -110.461168,
              40.777027
            ],
            [
              -110.460586,
              40.780204
            ],
            [
              -110.4585,
              40.781752
            ],
            [
              -110.455799,
              40.782348
            ],
            [
              -110.453457,
              40.781486
            ],
            [
              -110.449346,
              40.781461
            ],
            [
              -110.444863,
              40.781396
            ],
            [
              -110.440459,
              40.779302
            ],
            [
              -110.436471,
              40.777908
            ],
            [
              -110.431744,
              40.77916
            ],
            [
              -110.429562,
              40.784086
            ],
            [
              -110.427737,
              40.788266
            ],
            [
              -110.4257,
              40.790085
            ],
            [
              -110.422328,
              40.790757
            ],
            [
              -110.419016,
              40.789208
            ],
            [
              -110.414932,
              40.786702
            ],
            [
              -110.412076,
              40.784669
            ],
            [
              -110.408979,
              40.784374
            ],
            [
              -110.403591,
              40.784599
            ],
            [
              -110.399769,
              40.78589
            ],
            [
              -110.396327,
              40.787759
            ],
            [
              -110.393615,
              40.787497
            ],
            [
              -110.39032,
              40.787528
            ],
            [
              -110.386516,
              40.787428
            ],
            [
              -110.381822,
              40.787047
            ],
            [
              -110.378229,
              40.787703
            ],
            [
              -110.375482,
              40.788603
            ],
            [
              -110.373255,
              40.788537
            ],
            [
              -110.369703,
              40.791211
            ],
            [
              -110.365591,
              40.794823
            ],
            [
              -110.360366,
              40.79652
            ],
            [
              -110.356092,
              40.799572
            ],
            [
              -110.353555,
              40.80204
            ],
            [
              -110.35162,
              40.805971
            ],
            [
              -110.348975,
              40.809712
            ],
            [
              -110.350966,
              40.812286
            ],
            [
              -110.349069,
              40.817167
            ],
            [
              -110.343923,
              40.821912
            ],
            [
              -110.339483,
              40.823022
            ],
            [
              -110.333815,
              40.820737
            ],
            [
              -110.327002,
              40.819518
            ],
            [
              -110.318276,
              40.821056
            ],
            [
              -110.314167,
              40.823338
            ],
            [
              -110.307716,
              40.825061
            ],
            [
              -110.301034,
              40.826709
            ],
            [
              -110.297055,
              40.832347
            ],
            [
              -110.29411,
              40.833302
            ],
            [
              -110.291425,
              40.831456
            ],
            [
              -110.290337,
              40.829549
            ],
            [
              -110.286864,
              40.828222
            ],
            [
              -110.280914,
              40.828402
            ],
            [
              -110.275562,
              40.83047
            ],
            [
              -110.266556,
              40.83345
            ],
            [
              -110.257459,
              40.833477
            ],
            [
              -110.248441,
              40.832825
            ],
            [
              -110.243987,
              40.830246
            ],
            [
              -110.239745,
              40.82847
            ],
            [
              -110.232892,
              40.829213
            ],
            [
              -110.230887,
              40.830267
            ],
            [
              -110.227807,
              40.828436
            ],
            [
              -110.225465,
              40.82638
            ],
            [
              -110.218652,
              40.826118
            ],
            [
              -110.213837,
              40.827292
            ],
            [
              -110.210049,
              40.825289
            ],
            [
              -110.206331,
              40.822858
            ],
            [
              -110.200584,
              40.821981
            ],
            [
              -110.19527,
              40.82307
            ],
            [
              -110.191059,
              40.824514
            ],
            [
              -110.189524,
              40.825543
            ],
            [
              -110.187474,
              40.823979
            ],
            [
              -110.185704,
              40.822737
            ],
            [
              -110.182682,
              40.820229
            ],
            [
              -110.178879,
              40.819428
            ],
            [
              -110.17222,
              40.817137
            ],
            [
              -110.168096,
              40.819332
            ],
            [
              -110.162497,
              40.820431
            ],
            [
              -110.157166,
              40.820487
            ],
            [
              -110.150396,
              40.818114
            ],
            [
              -110.147295,
              40.814861
            ],
            [
              -110.144117,
              40.813571
            ],
            [
              -110.136402,
              40.811352
            ],
            [
              -110.1285,
              40.814054
            ],
            [
              -110.12501,
              40.813014
            ],
            [
              -110.121127,
              40.811228
            ],
            [
              -110.115011,
              40.81132
            ],
            [
              -110.108876,
              40.81013
            ],
            [
              -110.104336,
              40.808275
            ],
            [
              -110.099543,
              40.808245
            ],
            [
              -110.09491,
              40.809215
            ],
            [
              -110.090188,
              40.810745
            ],
            [
              -110.084966,
              40.810731
            ],
            [
              -110.079328,
              40.81028
            ],
            [
              -110.074016,
              40.810328
            ],
            [
              -110.068899,
              40.812503
            ],
            [
              -110.063962,
              40.814138
            ],
            [
              -110.057945,
              40.812851
            ],
            [
              -110.051774,
              40.812386
            ],
            [
              -110.046989,
              40.815725
            ],
            [
              -110.04515,
              40.818353
            ],
            [
              -110.041264,
              40.819744
            ],
            [
              -110.038709,
              40.818873
            ],
            [
              -110.032927,
              40.818549
            ],
            [
              -110.027204,
              40.820298
            ],
            [
              -110.022509,
              40.819668
            ],
            [
              -110.019702,
              40.817586
            ],
            [
              -110.016979,
              40.815338
            ],
            [
              -110.013344,
              40.813744
            ],
            [
              -110.008588,
              40.81387
            ],
            [
              -110.002085,
              40.813476
            ],
            [
              -110.000711,
              40.813678
            ],
            [
              -110.00071595432901,
              40.9973526364013
            ],
            [
              -110.006495,
              40.997815
            ],
            [
              -110.048482511407,
              40.9975546383386
            ],
            [
              -110.121639,
              40.997101
            ],
            [
              -110.125709,
              40.99655
            ],
            [
              -110.237848,
              40.995427
            ],
            [
              -110.250709,
              40.996089
            ],
            [
              -110.375714,
              40.994947
            ],
            [
              -110.500718,
              40.994746
            ],
            [
              -110.539819,
              40.996346
            ],
            [
              -110.715026,
              40.996347
            ],
            [
              -110.750727,
              40.996847
            ],
            [
              -111.046723,
              40.997959
            ],
            [
              -111.04655106042401,
              41.251626854311006
            ],
            [
              -111.063921,
              41.250436
            ],
            [
              -111.075611,
              41.243293
            ],
            [
              -111.101536,
              41.207712
            ],
            [
              -111.110263,
              41.214658
            ],
            [
              -111.133234,
              41.206822
            ],
            [
              -111.129451,
              41.197627
            ],
            [
              -111.148745,
              41.183182
            ],
            [
              -111.202955,
              41.163782
            ],
            [
              -111.232824,
              41.142881
            ],
            [
              -111.264974,
              41.144044
            ],
            [
              -111.289716,
              41.143257
            ],
            [
              -111.310199,
              41.130487
            ],
            [
              -111.332389,
              41.138101
            ],
            [
              -111.345793,
              41.128718
            ],
            [
              -111.366604,
              41.130018
            ],
            [
              -111.37769,
              41.107816
            ],
            [
              -111.380452,
              41.087248
            ],
            [
              -111.408441,
              41.074944
            ],
            [
              -111.446506,
              41.082133
            ],
            [
              -111.473965,
              41.064235
            ],
            [
              -111.50751,
              41.063234
            ],
            [
              -111.529911,
              41.052774
            ],
            [
              -111.555243,
              41.031937
            ],
            [
              -111.566632,
              41.000484
            ],
            [
              -111.585521,
              40.996375
            ],
            [
              -111.592981,
              40.993015
            ],
            [
              -111.588032,
              40.965863
            ],
            [
              -111.577602,
              40.958372
            ],
            [
              -111.524895,
              40.953842
            ],
            [
              -111.528796,
              40.919104
            ],
            [
              -111.514908,
              40.916888
            ],
            [
              -111.509768,
              40.897781
            ],
            [
              -111.486159,
              40.873124
            ],
            [
              -111.479555,
              40.858288
            ],
            [
              -111.488292,
              40.854251
            ],
            [
              -111.50006,
              40.832022
            ],
            [
              -111.516922,
              40.819386
            ],
            [
              -111.510838,
              40.810384
            ],
            [
              -111.519425,
              40.791689
            ],
            [
              -111.554472,
              40.779948
            ],
            [
              -111.587897,
              40.793687
            ],
            [
              -111.594217,
              40.805001
            ],
            [
              -111.611034,
              40.811057
            ],
            [
              -111.641052,
              40.798925
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "49",
      "COUNTYFP": "037",
      "COUNTYNS": "01448032",
      "AFFGEOID": "0500000US49037",
      "GEOID": "49037",
      "NAME": "San Juan",
      "LSAD": "06",
      "ALAND": 20252985182,
      "AWATER": 293755887,
      "County_state": "San Juan,49"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -111.33601,
              37.016929
            ],
            [
              -111.35019712738301,
              37.0010482877479
            ],
            [
              -111.278286,
              37.000465
            ],
            [
              -111.254853,
              37.001077
            ],
            [
              -111.133718,
              37.000779
            ],
            [
              -111.066496,
              37.002389
            ],
            [
              -110.75069,
              37.003197
            ],
            [
              -110.62569,
              37.003721
            ],
            [
              -110.625605,
              37.003416
            ],
            [
              -110.599512,
              37.003448
            ],
            [
              -110.50069,
              37.00426
            ],
            [
              -110.490908,
              37.003566
            ],
            [
              -110.47019,
              36.997997
            ],
            [
              -110.021778,
              36.998602
            ],
            [
              -110.000876,
              36.998502
            ],
            [
              -110.00068203791601,
              36.997981518830194
            ],
            [
              -110.000677,
              36.997968
            ],
            [
              -109.875673,
              36.998504
            ],
            [
              -109.625668,
              36.998308
            ],
            [
              -109.495338,
              36.999105
            ],
            [
              -109.381226,
              36.999148
            ],
            [
              -109.378039,
              36.999135
            ],
            [
              -109.270097,
              36.999266
            ],
            [
              -109.268213,
              36.999242
            ],
            [
              -109.26339,
              36.999263
            ],
            [
              -109.246917,
              36.999346
            ],
            [
              -109.233848,
              36.999266
            ],
            [
              -109.181196,
              36.999271
            ],
            [
              -109.045223,
              36.999084
            ],
            [
              -109.045166,
              37.072742
            ],
            [
              -109.045058,
              37.074661
            ],
            [
              -109.044995,
              37.086429
            ],
            [
              -109.045189,
              37.096271
            ],
            [
              -109.045173,
              37.109464
            ],
            [
              -109.045203,
              37.111958
            ],
            [
              -109.045156,
              37.112064
            ],
            [
              -109.045995,
              37.177279
            ],
            [
              -109.045978,
              37.201831
            ],
            [
              -109.045487,
              37.210844
            ],
            [
              -109.045584,
              37.249351
            ],
            [
              -109.046039,
              37.249993
            ],
            [
              -109.04581,
              37.374993
            ],
            [
              -109.043461439769,
              37.484820040900296
            ],
            [
              -109.043137,
              37.499992
            ],
            [
              -109.041915,
              37.530653
            ],
            [
              -109.041865,
              37.530726
            ],
            [
              -109.041806,
              37.604171
            ],
            [
              -109.042131,
              37.617662
            ],
            [
              -109.042089,
              37.623795
            ],
            [
              -109.042269,
              37.666067
            ],
            [
              -109.041732,
              37.711214
            ],
            [
              -109.04176,
              37.713182
            ],
            [
              -109.041636,
              37.74021
            ],
            [
              -109.042098,
              37.74999
            ],
            [
              -109.041461,
              37.800105
            ],
            [
              -109.041754,
              37.835826
            ],
            [
              -109.041723,
              37.842051
            ],
            [
              -109.041844,
              37.872788
            ],
            [
              -109.041654683556,
              37.8810851664933
            ],
            [
              -109.041058,
              37.907236
            ],
            [
              -109.043121,
              37.97426
            ],
            [
              -109.042819,
              37.997068
            ],
            [
              -109.04282,
              37.999301
            ],
            [
              -109.04183657611101,
              38.153032090353896
            ],
            [
              -109.041762,
              38.16469
            ],
            [
              -109.054648,
              38.244921
            ],
            [
              -109.060062,
              38.275489
            ],
            [
              -109.059962,
              38.499987
            ],
            [
              -109.059962005949,
              38.4999890310142
            ],
            [
              -109.475344,
              38.499957
            ],
            [
              -110.025402,
              38.499981
            ],
            [
              -110.001865,
              38.480231
            ],
            [
              -110.011974,
              38.447649
            ],
            [
              -110.034495,
              38.459913
            ],
            [
              -110.044544,
              38.452624
            ],
            [
              -110.026957,
              38.44737
            ],
            [
              -110.003381,
              38.416182
            ],
            [
              -110.031973,
              38.384957
            ],
            [
              -110.01326,
              38.380381
            ],
            [
              -110.015126,
              38.364604
            ],
            [
              -110.000681,
              38.370611
            ],
            [
              -109.978633,
              38.389018
            ],
            [
              -109.972991,
              38.375909
            ],
            [
              -109.986407,
              38.357396
            ],
            [
              -109.967662,
              38.341197
            ],
            [
              -109.952446,
              38.35434
            ],
            [
              -109.941236,
              38.350146
            ],
            [
              -109.950546,
              38.336835
            ],
            [
              -109.965284,
              38.329351
            ],
            [
              -109.960844,
              38.317259
            ],
            [
              -109.946186,
              38.306802
            ],
            [
              -109.947896,
              38.289066
            ],
            [
              -109.931973,
              38.284253
            ],
            [
              -109.934944,
              38.267481
            ],
            [
              -109.926399,
              38.260013
            ],
            [
              -109.90976,
              38.27123
            ],
            [
              -109.902206,
              38.255957
            ],
            [
              -109.917058,
              38.246717
            ],
            [
              -109.900795,
              38.242843
            ],
            [
              -109.889083,
              38.231476
            ],
            [
              -109.919239,
              38.224783
            ],
            [
              -109.917487,
              38.215333
            ],
            [
              -109.888012,
              38.207483
            ],
            [
              -109.90335,
              38.192932
            ],
            [
              -109.88515,
              38.187767
            ],
            [
              -109.931122,
              38.15805
            ],
            [
              -109.927995,
              38.151884
            ],
            [
              -109.927248,
              38.148931
            ],
            [
              -109.936936,
              38.13571
            ],
            [
              -109.95771,
              38.128306
            ],
            [
              -109.966691,
              38.112816
            ],
            [
              -109.983305,
              38.105293
            ],
            [
              -109.990174,
              38.100487
            ],
            [
              -109.995945,
              38.100522
            ],
            [
              -110.000075,
              38.104989
            ],
            [
              -110.003758,
              38.108767
            ],
            [
              -110.009494,
              38.109656
            ],
            [
              -110.016677,
              38.106127
            ],
            [
              -110.02119,
              38.101136
            ],
            [
              -110.02597,
              38.097432
            ],
            [
              -110.028651,
              38.092932
            ],
            [
              -110.032104,
              38.087862
            ],
            [
              -110.037715,
              38.08345
            ],
            [
              -110.042682,
              38.077403
            ],
            [
              -110.046064,
              38.072332
            ],
            [
              -110.044555,
              38.065797
            ],
            [
              -110.043214,
              38.060991
            ],
            [
              -110.044348,
              38.052815
            ],
            [
              -110.053357,
              38.048752
            ],
            [
              -110.064936,
              38.041777
            ],
            [
              -110.072003,
              38.029778
            ],
            [
              -110.075349,
              38.016249
            ],
            [
              -110.075695,
              38.001614
            ],
            [
              -110.088369,
              37.995113
            ],
            [
              -110.102443,
              38.000788
            ],
            [
              -110.111369,
              38.007617
            ],
            [
              -110.121781,
              38.008021
            ],
            [
              -110.133293,
              38.006304
            ],
            [
              -110.13939,
              38.001987
            ],
            [
              -110.142415,
              37.994647
            ],
            [
              -110.151783,
              37.987115
            ],
            [
              -110.156834,
              37.984884
            ],
            [
              -110.163337,
              37.976124
            ],
            [
              -110.166678,
              37.966027
            ],
            [
              -110.174878,
              37.960878
            ],
            [
              -110.18569,
              37.954285
            ],
            [
              -110.196535,
              37.949129
            ],
            [
              -110.205517,
              37.939207
            ],
            [
              -110.201342,
              37.925425
            ],
            [
              -110.20146,
              37.910398
            ],
            [
              -110.20456,
              37.902294
            ],
            [
              -110.217919,
              37.892838
            ],
            [
              -110.233454,
              37.895917
            ],
            [
              -110.255784,
              37.898374
            ],
            [
              -110.267443,
              37.898395
            ],
            [
              -110.27716,
              37.888823
            ],
            [
              -110.278971,
              37.878572
            ],
            [
              -110.277803,
              37.873146
            ],
            [
              -110.282255,
              37.866602
            ],
            [
              -110.287301,
              37.867079
            ],
            [
              -110.293507,
              37.874047
            ],
            [
              -110.29407,
              37.881156
            ],
            [
              -110.300126,
              37.889233
            ],
            [
              -110.326141,
              37.890053
            ],
            [
              -110.346585,
              37.891226
            ],
            [
              -110.368637,
              37.891166
            ],
            [
              -110.388086,
              37.887905
            ],
            [
              -110.402762,
              37.881119
            ],
            [
              -110.402867,
              37.864204
            ],
            [
              -110.40295,
              37.856568
            ],
            [
              -110.410032,
              37.847118
            ],
            [
              -110.418903,
              37.844834
            ],
            [
              -110.434331,
              37.829826
            ],
            [
              -110.435413,
              37.814358
            ],
            [
              -110.447088,
              37.808698
            ],
            [
              -110.461102,
              37.808833
            ],
            [
              -110.467852,
              37.80868
            ],
            [
              -110.476535,
              37.800843
            ],
            [
              -110.472493,
              37.792056
            ],
            [
              -110.47059,
              37.781252
            ],
            [
              -110.463084,
              37.771936
            ],
            [
              -110.447956,
              37.772355
            ],
            [
              -110.437607,
              37.775713
            ],
            [
              -110.42936,
              37.770861
            ],
            [
              -110.435022,
              37.767389
            ],
            [
              -110.447833,
              37.749989
            ],
            [
              -110.454002,
              37.731096
            ],
            [
              -110.469065,
              37.713992
            ],
            [
              -110.483887,
              37.70452
            ],
            [
              -110.484416,
              37.689064
            ],
            [
              -110.493126,
              37.66785
            ],
            [
              -110.497168,
              37.658387
            ],
            [
              -110.504031,
              37.651508
            ],
            [
              -110.507352,
              37.65343
            ],
            [
              -110.529856,
              37.639675
            ],
            [
              -110.549964,
              37.638883
            ],
            [
              -110.563892,
              37.63432
            ],
            [
              -110.566097,
              37.622322
            ],
            [
              -110.573194,
              37.611793
            ],
            [
              -110.601011,
              37.601602
            ],
            [
              -110.61286,
              37.564736
            ],
            [
              -110.628576,
              37.558802
            ],
            [
              -110.643934,
              37.544002
            ],
            [
              -110.646394,
              37.541063
            ],
            [
              -110.650551,
              37.535122
            ],
            [
              -110.662189,
              37.52233
            ],
            [
              -110.666244,
              37.503732
            ],
            [
              -110.667385,
              37.498674
            ],
            [
              -110.660397,
              37.493891
            ],
            [
              -110.64899,
              37.493861
            ],
            [
              -110.642135,
              37.491633
            ],
            [
              -110.640684,
              37.485416
            ],
            [
              -110.646817,
              37.483496
            ],
            [
              -110.662294,
              37.478186
            ],
            [
              -110.696498,
              37.481591
            ],
            [
              -110.718498,
              37.481391
            ],
            [
              -110.725113,
              37.472178
            ],
            [
              -110.725198,
              37.465591
            ],
            [
              -110.729275,
              37.458208
            ],
            [
              -110.736498,
              37.459091
            ],
            [
              -110.743818,
              37.46015
            ],
            [
              -110.747599,
              37.456843
            ],
            [
              -110.747299,
              37.454491
            ],
            [
              -110.737398,
              37.445591
            ],
            [
              -110.720098,
              37.434791
            ],
            [
              -110.706797,
              37.436891
            ],
            [
              -110.700195,
              37.428784
            ],
            [
              -110.703779,
              37.421843
            ],
            [
              -110.707245,
              37.411894
            ],
            [
              -110.710997,
              37.406191
            ],
            [
              -110.723898,
              37.399391
            ],
            [
              -110.736297,
              37.40517
            ],
            [
              -110.744998,
              37.407491
            ],
            [
              -110.753618,
              37.402384
            ],
            [
              -110.752198,
              37.397391
            ],
            [
              -110.742964,
              37.387425
            ],
            [
              -110.734117,
              37.380832
            ],
            [
              -110.726779,
              37.375911
            ],
            [
              -110.724887,
              37.36634
            ],
            [
              -110.73288,
              37.360234
            ],
            [
              -110.734598,
              37.351292
            ],
            [
              -110.742198,
              37.344392
            ],
            [
              -110.746798,
              37.349492
            ],
            [
              -110.755181,
              37.346557
            ],
            [
              -110.755704,
              37.339098
            ],
            [
              -110.761318,
              37.33272
            ],
            [
              -110.769836,
              37.330876
            ],
            [
              -110.784499,
              37.323792
            ],
            [
              -110.808701,
              37.322992
            ],
            [
              -110.823902,
              37.331392
            ],
            [
              -110.837902,
              37.337892
            ],
            [
              -110.856693,
              37.348405
            ],
            [
              -110.868614,
              37.349358
            ],
            [
              -110.872604,
              37.336491
            ],
            [
              -110.864903,
              37.326731
            ],
            [
              -110.850449,
              37.323983
            ],
            [
              -110.841101,
              37.319152
            ],
            [
              -110.836602,
              37.310392
            ],
            [
              -110.844029,
              37.294272
            ],
            [
              -110.865091,
              37.290487
            ],
            [
              -110.865303,
              37.268392
            ],
            [
              -110.8618,
              37.259203
            ],
            [
              -110.87093,
              37.255343
            ],
            [
              -110.879752,
              37.253601
            ],
            [
              -110.88485,
              37.251265
            ],
            [
              -110.881738,
              37.242518
            ],
            [
              -110.876931,
              37.236428
            ],
            [
              -110.875369,
              37.230148
            ],
            [
              -110.88138,
              37.223185
            ],
            [
              -110.890141,
              37.219427
            ],
            [
              -110.894824,
              37.211717
            ],
            [
              -110.890017,
              37.206687
            ],
            [
              -110.891782,
              37.195434
            ],
            [
              -110.89897,
              37.187047
            ],
            [
              -110.898604,
              37.181602
            ],
            [
              -110.902846,
              37.175025
            ],
            [
              -110.909129,
              37.176133
            ],
            [
              -110.917109,
              37.178436
            ],
            [
              -110.925801,
              37.172589
            ],
            [
              -110.927494,
              37.165613
            ],
            [
              -110.930843,
              37.15465
            ],
            [
              -110.939739,
              37.148258
            ],
            [
              -110.94449,
              37.149373
            ],
            [
              -110.955666,
              37.149238
            ],
            [
              -110.953165,
              37.140405
            ],
            [
              -110.953679,
              37.132095
            ],
            [
              -110.958166,
              37.124996
            ],
            [
              -110.968579,
              37.124993
            ],
            [
              -110.974612,
              37.126442
            ],
            [
              -110.980084,
              37.127827
            ],
            [
              -110.984205,
              37.124393
            ],
            [
              -110.982849,
              37.121289
            ],
            [
              -110.984004,
              37.113993
            ],
            [
              -110.991504,
              37.111093
            ],
            [
              -111.000705,
              37.111693
            ],
            [
              -111.017055,
              37.113159
            ],
            [
              -111.043289,
              37.106933
            ],
            [
              -111.052117,
              37.099072
            ],
            [
              -111.060062,
              37.102835
            ],
            [
              -111.063296,
              37.107516
            ],
            [
              -111.073194,
              37.104462
            ],
            [
              -111.081872,
              37.102993
            ],
            [
              -111.105448,
              37.103869
            ],
            [
              -111.116599,
              37.106488
            ],
            [
              -111.127913,
              37.103342
            ],
            [
              -111.131036,
              37.097172
            ],
            [
              -111.144348,
              37.088819
            ],
            [
              -111.155321,
              37.095861
            ],
            [
              -111.160688,
              37.102405
            ],
            [
              -111.172896,
              37.103889
            ],
            [
              -111.181352,
              37.099537
            ],
            [
              -111.198899,
              37.076922
            ],
            [
              -111.217104,
              37.057374
            ],
            [
              -111.221659,
              37.055983
            ],
            [
              -111.226766,
              37.05975
            ],
            [
              -111.229227,
              37.064746
            ],
            [
              -111.242365,
              37.064322
            ],
            [
              -111.245748,
              37.056098
            ],
            [
              -111.24778,
              37.048976
            ],
            [
              -111.239543,
              37.044484
            ],
            [
              -111.235516,
              37.043354
            ],
            [
              -111.232975,
              37.031588
            ],
            [
              -111.237474,
              37.023932
            ],
            [
              -111.251275,
              37.026039
            ],
            [
              -111.254505,
              37.031389
            ],
            [
              -111.263313,
              37.0489
            ],
            [
              -111.281496,
              37.058494
            ],
            [
              -111.290901,
              37.062866
            ],
            [
              -111.301697,
              37.066412
            ],
            [
              -111.305106,
              37.061962
            ],
            [
              -111.304969,
              37.052411
            ],
            [
              -111.294707,
              37.043501
            ],
            [
              -111.284448,
              37.037836
            ],
            [
              -111.290517,
              37.033973
            ],
            [
              -111.299598,
              37.030643
            ],
            [
              -111.306284,
              37.021683
            ],
            [
              -111.305269,
              37.013164
            ],
            [
              -111.316771,
              37.013588
            ],
            [
              -111.322734,
              37.019282
            ],
            [
              -111.33601,
              37.016929
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "49",
      "COUNTYFP": "009",
      "COUNTYNS": "01448019",
      "AFFGEOID": "0500000US49009",
      "GEOID": "49009",
      "NAME": "Daggett",
      "LSAD": "06",
      "ALAND": 1805185918,
      "AWATER": 61230035,
      "County_state": "Daggett,49"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -110.00071595432901,
              40.9973526364013
            ],
            [
              -110.000711,
              40.813678
            ],
            [
              -109.987862,
              40.807618
            ],
            [
              -109.976402,
              40.809686
            ],
            [
              -109.941422,
              40.795014
            ],
            [
              -109.91867,
              40.790885
            ],
            [
              -109.910576,
              40.781357
            ],
            [
              -109.896822,
              40.781984
            ],
            [
              -109.870207,
              40.763829
            ],
            [
              -109.834133,
              40.768242
            ],
            [
              -109.831879,
              40.775731
            ],
            [
              -109.789723,
              40.782254
            ],
            [
              -109.764678,
              40.782533
            ],
            [
              -109.752499,
              40.802851
            ],
            [
              -109.728164,
              40.795028
            ],
            [
              -109.719957,
              40.801851
            ],
            [
              -109.692981,
              40.801894
            ],
            [
              -109.676775,
              40.794865
            ],
            [
              -109.632124,
              40.81567
            ],
            [
              -109.602364,
              40.815075
            ],
            [
              -109.580603,
              40.825345
            ],
            [
              -109.571853,
              40.813415
            ],
            [
              -109.525495,
              40.82637
            ],
            [
              -109.511458,
              40.794528
            ],
            [
              -109.518983,
              40.782277
            ],
            [
              -109.513115,
              40.751454
            ],
            [
              -109.484332,
              40.740432
            ],
            [
              -109.469784,
              40.755153
            ],
            [
              -109.436321,
              40.758046
            ],
            [
              -109.4342,
              40.777258
            ],
            [
              -109.394465,
              40.791032
            ],
            [
              -109.394491,
              40.858166
            ],
            [
              -109.20325,
              40.858401
            ],
            [
              -109.202356,
              40.785814
            ],
            [
              -109.163425,
              40.785955
            ],
            [
              -109.16181,
              40.77155
            ],
            [
              -109.161063,
              40.683817
            ],
            [
              -109.123471,
              40.684127
            ],
            [
              -109.123479,
              40.662385
            ],
            [
              -109.048372974241,
              40.662608858969
            ],
            [
              -109.049088,
              40.714562
            ],
            [
              -109.048455,
              40.826081
            ],
            [
              -109.050076,
              41.000659
            ],
            [
              -109.173682,
              41.000859
            ],
            [
              -109.231985,
              41.002059
            ],
            [
              -109.250735,
              41.001009
            ],
            [
              -109.500694,
              40.999127
            ],
            [
              -109.534926,
              40.998143
            ],
            [
              -109.54369742276602,
              40.998158621743094
            ],
            [
              -109.676421,
              40.998395
            ],
            [
              -109.713877,
              40.998266
            ],
            [
              -109.715409,
              40.998191
            ],
            [
              -109.854302,
              40.997661
            ],
            [
              -109.855299,
              40.997614
            ],
            [
              -109.97553,
              40.997912
            ],
            [
              -109.999838,
              40.99733
            ],
            [
              -110.000708,
              40.997352
            ],
            [
              -110.00071595432901,
              40.9973526364013
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "49",
      "COUNTYFP": "029",
      "COUNTYNS": "01448028",
      "AFFGEOID": "0500000US49029",
      "GEOID": "49029",
      "NAME": "Morgan",
      "LSAD": "06",
      "ALAND": 1577739361,
      "AWATER": 4437369,
      "County_state": "Morgan,49"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -111.872926,
              41.18755
            ],
            [
              -111.876596,
              41.169744
            ],
            [
              -111.854558,
              41.154522
            ],
            [
              -111.856247,
              41.139083
            ],
            [
              -111.844551,
              41.121788
            ],
            [
              -111.855549,
              41.110692
            ],
            [
              -111.855857,
              41.105328
            ],
            [
              -111.851796,
              41.066651
            ],
            [
              -111.833606,
              41.037175
            ],
            [
              -111.838249,
              41.02298
            ],
            [
              -111.816293,
              41.005748
            ],
            [
              -111.810466,
              41.005086
            ],
            [
              -111.801627,
              40.992577
            ],
            [
              -111.775105,
              40.959203
            ],
            [
              -111.814647,
              40.959774
            ],
            [
              -111.814454,
              40.955667
            ],
            [
              -111.793199,
              40.930362
            ],
            [
              -111.768295,
              40.872533
            ],
            [
              -111.738744,
              40.860998
            ],
            [
              -111.706732,
              40.852773
            ],
            [
              -111.663996,
              40.850194
            ],
            [
              -111.666128,
              40.832108
            ],
            [
              -111.64565,
              40.824061
            ],
            [
              -111.641052,
              40.798925
            ],
            [
              -111.611034,
              40.811057
            ],
            [
              -111.594217,
              40.805001
            ],
            [
              -111.587897,
              40.793687
            ],
            [
              -111.554472,
              40.779948
            ],
            [
              -111.519425,
              40.791689
            ],
            [
              -111.510838,
              40.810384
            ],
            [
              -111.516922,
              40.819386
            ],
            [
              -111.50006,
              40.832022
            ],
            [
              -111.488292,
              40.854251
            ],
            [
              -111.479555,
              40.858288
            ],
            [
              -111.486159,
              40.873124
            ],
            [
              -111.509768,
              40.897781
            ],
            [
              -111.514908,
              40.916888
            ],
            [
              -111.528796,
              40.919104
            ],
            [
              -111.524895,
              40.953842
            ],
            [
              -111.577602,
              40.958372
            ],
            [
              -111.588032,
              40.965863
            ],
            [
              -111.592981,
              40.993015
            ],
            [
              -111.585521,
              40.996375
            ],
            [
              -111.566632,
              41.000484
            ],
            [
              -111.555243,
              41.031937
            ],
            [
              -111.529911,
              41.052774
            ],
            [
              -111.50751,
              41.063234
            ],
            [
              -111.473965,
              41.064235
            ],
            [
              -111.446506,
              41.082133
            ],
            [
              -111.408441,
              41.074944
            ],
            [
              -111.380452,
              41.087248
            ],
            [
              -111.37769,
              41.107816
            ],
            [
              -111.366604,
              41.130018
            ],
            [
              -111.345793,
              41.128718
            ],
            [
              -111.332389,
              41.138101
            ],
            [
              -111.310199,
              41.130487
            ],
            [
              -111.289716,
              41.143257
            ],
            [
              -111.264974,
              41.144044
            ],
            [
              -111.242607,
              41.164537
            ],
            [
              -111.225823,
              41.191017
            ],
            [
              -111.231676,
              41.202651
            ],
            [
              -111.221776,
              41.215119
            ],
            [
              -111.252021,
              41.214557
            ],
            [
              -111.253514,
              41.228743
            ],
            [
              -111.281298,
              41.22497
            ],
            [
              -111.269654,
              41.236762
            ],
            [
              -111.27741,
              41.245832
            ],
            [
              -111.277283,
              41.277368
            ],
            [
              -111.261434,
              41.286935
            ],
            [
              -111.261803,
              41.296681
            ],
            [
              -111.292371,
              41.309409
            ],
            [
              -111.294886,
              41.329969
            ],
            [
              -111.327203,
              41.345643
            ],
            [
              -111.331944,
              41.357472
            ],
            [
              -111.367028,
              41.361641
            ],
            [
              -111.384293,
              41.374001
            ],
            [
              -111.420728,
              41.361308
            ],
            [
              -111.426684,
              41.342775
            ],
            [
              -111.438152,
              41.338105
            ],
            [
              -111.437369,
              41.323785
            ],
            [
              -111.449353,
              41.315919
            ],
            [
              -111.440107,
              41.289585
            ],
            [
              -111.457943,
              41.273563
            ],
            [
              -111.477557,
              41.245204
            ],
            [
              -111.494458,
              41.241255
            ],
            [
              -111.497182,
              41.226905
            ],
            [
              -111.526123,
              41.228752
            ],
            [
              -111.535259,
              41.236181
            ],
            [
              -111.559731,
              41.24024
            ],
            [
              -111.596716,
              41.238553
            ],
            [
              -111.609251,
              41.229222
            ],
            [
              -111.611983,
              41.209559
            ],
            [
              -111.624291,
              41.200373
            ],
            [
              -111.640364,
              41.198947
            ],
            [
              -111.658636,
              41.185718
            ],
            [
              -111.678016,
              41.181208
            ],
            [
              -111.717681,
              41.187539
            ],
            [
              -111.745211,
              41.203468
            ],
            [
              -111.749082,
              41.214541
            ],
            [
              -111.799811,
              41.217178
            ],
            [
              -111.817568,
              41.204709
            ],
            [
              -111.858208,
              41.197596
            ],
            [
              -111.872926,
              41.18755
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "49",
      "COUNTYFP": "005",
      "COUNTYNS": "01448017",
      "AFFGEOID": "0500000US49005",
      "GEOID": "49005",
      "NAME": "Cache",
      "LSAD": "06",
      "ALAND": 3016670856,
      "AWATER": 21097698,
      "County_state": "Cache,49"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -112.163956,
              41.996708
            ],
            [
              -112.164723711068,
              41.9966965611378
            ],
            [
              -112.150877,
              41.970564
            ],
            [
              -112.138721,
              41.964304
            ],
            [
              -112.121924,
              41.928171
            ],
            [
              -112.085494,
              41.903311
            ],
            [
              -112.052031,
              41.865108
            ],
            [
              -112.043347,
              41.837568
            ],
            [
              -112.030483,
              41.810047
            ],
            [
              -112.008626,
              41.794637
            ],
            [
              -112.021913,
              41.778797
            ],
            [
              -112.042236,
              41.738485
            ],
            [
              -112.042792,
              41.710433
            ],
            [
              -112.051862,
              41.700163
            ],
            [
              -112.018181,
              41.662514
            ],
            [
              -112.013253,
              41.635785
            ],
            [
              -112.005988,
              41.631891
            ],
            [
              -112.006348,
              41.592504
            ],
            [
              -111.994107,
              41.566125
            ],
            [
              -111.996606,
              41.557572
            ],
            [
              -111.981042,
              41.534117
            ],
            [
              -111.946089,
              41.553029
            ],
            [
              -111.913583,
              41.531611
            ],
            [
              -111.904959,
              41.496771
            ],
            [
              -111.88459,
              41.496191
            ],
            [
              -111.873314,
              41.485743
            ],
            [
              -111.87821,
              41.474447
            ],
            [
              -111.913181,
              41.469729
            ],
            [
              -111.916588,
              41.460375
            ],
            [
              -111.881564,
              41.441188
            ],
            [
              -111.885443,
              41.426374
            ],
            [
              -111.868507,
              41.398497
            ],
            [
              -111.814108,
              41.387964
            ],
            [
              -111.797423,
              41.392316
            ],
            [
              -111.76059,
              41.368818
            ],
            [
              -111.72651,
              41.375814
            ],
            [
              -111.726887,
              41.385051
            ],
            [
              -111.706517,
              41.404844
            ],
            [
              -111.721301,
              41.42739
            ],
            [
              -111.696486,
              41.417074
            ],
            [
              -111.666576,
              41.42883
            ],
            [
              -111.652644,
              41.407793
            ],
            [
              -111.618536,
              41.422347
            ],
            [
              -111.583856,
              41.418416
            ],
            [
              -111.575476,
              41.428774
            ],
            [
              -111.554044,
              41.423425
            ],
            [
              -111.543399,
              41.405362
            ],
            [
              -111.510839,
              41.4231
            ],
            [
              -111.500757,
              41.464449
            ],
            [
              -111.489871,
              41.466383
            ],
            [
              -111.474957,
              41.494909
            ],
            [
              -111.478061,
              41.503931
            ],
            [
              -111.456359,
              41.52093
            ],
            [
              -111.456946,
              41.532214
            ],
            [
              -111.442949,
              41.531365
            ],
            [
              -111.445188,
              41.544336
            ],
            [
              -111.433099,
              41.553629
            ],
            [
              -111.427898,
              41.57843
            ],
            [
              -111.42984,
              41.618191
            ],
            [
              -111.410663,
              41.627435
            ],
            [
              -111.416618,
              41.639256
            ],
            [
              -111.402077,
              41.658881
            ],
            [
              -111.425898,
              41.676748
            ],
            [
              -111.417127,
              41.712624
            ],
            [
              -111.420272,
              41.724603
            ],
            [
              -111.442086,
              41.736067
            ],
            [
              -111.443512,
              41.771701
            ],
            [
              -111.454656,
              41.77957
            ],
            [
              -111.4509,
              41.79282
            ],
            [
              -111.460722,
              41.816985
            ],
            [
              -111.507864,
              41.852589
            ],
            [
              -111.494177,
              41.873626
            ],
            [
              -111.500396,
              41.898263
            ],
            [
              -111.511025,
              41.904555
            ],
            [
              -111.502594,
              41.913653
            ],
            [
              -111.478331,
              41.912687
            ],
            [
              -111.471321,
              41.928157
            ],
            [
              -111.473236,
              41.947496
            ],
            [
              -111.484347,
              41.975355
            ],
            [
              -111.507675,
              41.987367
            ],
            [
              -111.507789674782,
              41.9995175941635
            ],
            [
              -111.507805973428,
              41.9995175815805
            ],
            [
              -111.73970108689299,
              41.9993385517041
            ],
            [
              -111.750778,
              41.99933
            ],
            [
              -111.876491,
              41.998528
            ],
            [
              -111.915622,
              41.998496
            ],
            [
              -111.915837,
              41.998519
            ],
            [
              -111.94997920191899,
              41.998459109482496
            ],
            [
              -112.01218,
              41.99835
            ],
            [
              -112.10944249133001,
              41.9975986914138
            ],
            [
              -112.109532,
              41.997598
            ],
            [
              -112.163956,
              41.996708
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "49",
      "COUNTYFP": "011",
      "COUNTYNS": "01448020",
      "AFFGEOID": "0500000US49011",
      "GEOID": "49011",
      "NAME": "Davis",
      "LSAD": "06",
      "ALAND": 774500764,
      "AWATER": 870693260,
      "County_state": "Davis,49"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -112.451621,
              41.087334
            ],
            [
              -112.493515,
              41.076888
            ],
            [
              -112.260216,
              40.769093
            ],
            [
              -112.148271,
              40.846133
            ],
            [
              -112.008134,
              40.921016
            ],
            [
              -111.958672,
              40.921822
            ],
            [
              -111.958643,
              40.912697
            ],
            [
              -111.968734,
              40.886904
            ],
            [
              -111.964494,
              40.853445
            ],
            [
              -111.946408,
              40.822046
            ],
            [
              -111.937785,
              40.821801
            ],
            [
              -111.916184,
              40.821823
            ],
            [
              -111.870895,
              40.821702
            ],
            [
              -111.838417,
              40.830824
            ],
            [
              -111.812616,
              40.84009
            ],
            [
              -111.758726,
              40.852408
            ],
            [
              -111.745999,
              40.860371
            ],
            [
              -111.738744,
              40.860998
            ],
            [
              -111.768295,
              40.872533
            ],
            [
              -111.793199,
              40.930362
            ],
            [
              -111.814454,
              40.955667
            ],
            [
              -111.814647,
              40.959774
            ],
            [
              -111.775105,
              40.959203
            ],
            [
              -111.801627,
              40.992577
            ],
            [
              -111.810466,
              41.005086
            ],
            [
              -111.816293,
              41.005748
            ],
            [
              -111.838249,
              41.02298
            ],
            [
              -111.833606,
              41.037175
            ],
            [
              -111.851796,
              41.066651
            ],
            [
              -111.855857,
              41.105328
            ],
            [
              -111.855549,
              41.110692
            ],
            [
              -111.844551,
              41.121788
            ],
            [
              -111.856247,
              41.139083
            ],
            [
              -111.861255,
              41.137511
            ],
            [
              -111.865199,
              41.137616
            ],
            [
              -111.866099,
              41.137264
            ],
            [
              -111.86904,
              41.138059
            ],
            [
              -111.874166,
              41.138139
            ],
            [
              -111.876634,
              41.137832
            ],
            [
              -111.878362,
              41.137478
            ],
            [
              -111.88098,
              41.137786
            ],
            [
              -111.882806,
              41.137348
            ],
            [
              -111.885795,
              41.137352
            ],
            [
              -111.888476,
              41.137748
            ],
            [
              -111.889873,
              41.137618
            ],
            [
              -111.893895,
              41.136668
            ],
            [
              -111.895535,
              41.136097
            ],
            [
              -111.897598,
              41.135627
            ],
            [
              -111.899433,
              41.135536
            ],
            [
              -111.901222,
              41.136466
            ],
            [
              -111.903059,
              41.136533
            ],
            [
              -111.903396,
              41.136339
            ],
            [
              -111.912182,
              41.134831
            ],
            [
              -111.956191,
              41.140631
            ],
            [
              -111.971004,
              41.152647
            ],
            [
              -112.021127,
              41.152652
            ],
            [
              -112.025023,
              41.152647
            ],
            [
              -112.077871,
              41.152703
            ],
            [
              -112.18654,
              41.153027
            ],
            [
              -112.451621,
              41.087334
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "49",
      "COUNTYFP": "031",
      "COUNTYNS": "01448029",
      "AFFGEOID": "0500000US49031",
      "GEOID": "49031",
      "NAME": "Piute",
      "LSAD": "06",
      "ALAND": 1964345915,
      "AWATER": 20285697,
      "County_state": "Piute,49"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -112.510167,
              38.488377
            ],
            [
              -112.519522,
              38.479902
            ],
            [
              -112.501142,
              38.465361
            ],
            [
              -112.493381,
              38.451625
            ],
            [
              -112.479229,
              38.448049
            ],
            [
              -112.453011,
              38.429232
            ],
            [
              -112.444081,
              38.405646
            ],
            [
              -112.425534,
              38.406797
            ],
            [
              -112.417048,
              38.419507
            ],
            [
              -112.403034,
              38.415601
            ],
            [
              -112.403795,
              38.394757
            ],
            [
              -112.391589,
              38.380692
            ],
            [
              -112.380346,
              38.379889
            ],
            [
              -112.362637,
              38.356814
            ],
            [
              -112.343485,
              38.347267
            ],
            [
              -112.335381,
              38.332827
            ],
            [
              -112.338853,
              38.311851
            ],
            [
              -112.348604,
              38.304892
            ],
            [
              -112.350698,
              38.281798
            ],
            [
              -112.377145,
              38.254325
            ],
            [
              -112.379847,
              38.244366
            ],
            [
              -112.35716,
              38.224689
            ],
            [
              -112.36493,
              38.215121
            ],
            [
              -112.395054,
              38.205984
            ],
            [
              -112.424284,
              38.180945
            ],
            [
              -112.422454,
              38.170824
            ],
            [
              -112.444214,
              38.150001
            ],
            [
              -112.060924,
              38.145564
            ],
            [
              -111.951449,
              38.151083
            ],
            [
              -111.843215,
              38.15128
            ],
            [
              -111.848761,
              38.424933
            ],
            [
              -111.837231,
              38.42854
            ],
            [
              -111.823569,
              38.457218
            ],
            [
              -111.766393,
              38.502243
            ],
            [
              -112.061965,
              38.50209
            ],
            [
              -112.062132,
              38.51047
            ],
            [
              -112.125753,
              38.51214
            ],
            [
              -112.20526,
              38.512175
            ],
            [
              -112.518495,
              38.510413
            ],
            [
              -112.510167,
              38.488377
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "49",
      "COUNTYFP": "049",
      "COUNTYNS": "01448038",
      "AFFGEOID": "0500000US49049",
      "GEOID": "49049",
      "NAME": "Utah",
      "LSAD": "06",
      "ALAND": 5189271229,
      "AWATER": 364277381,
      "County_state": "Utah,49"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -112.213227,
              40.455108
            ],
            [
              -112.209562,
              40.432352
            ],
            [
              -112.194669,
              40.421176
            ],
            [
              -112.199169,
              40.382545
            ],
            [
              -112.188734,
              40.372487
            ],
            [
              -112.193987,
              40.359869
            ],
            [
              -112.175533,
              40.335837
            ],
            [
              -112.196233,
              40.325693
            ],
            [
              -112.198605,
              40.306915
            ],
            [
              -112.186495,
              40.286858
            ],
            [
              -112.191588,
              40.263628
            ],
            [
              -112.179499,
              40.249444
            ],
            [
              -112.179693,
              40.23224
            ],
            [
              -112.168992,
              40.219637
            ],
            [
              -112.1495,
              40.212004
            ],
            [
              -112.160499,
              40.199245
            ],
            [
              -112.14862,
              40.195678
            ],
            [
              -112.153373,
              40.182158
            ],
            [
              -112.144298,
              40.173183
            ],
            [
              -112.173993,
              40.154288
            ],
            [
              -112.16793,
              40.147137
            ],
            [
              -112.176175,
              40.131689
            ],
            [
              -112.150892,
              40.107298
            ],
            [
              -112.157179,
              40.09796
            ],
            [
              -112.183594,
              40.059655
            ],
            [
              -112.171981,
              40.039484
            ],
            [
              -112.1798,
              40.011656
            ],
            [
              -112.160026,
              40.006758
            ],
            [
              -112.13206,
              39.977128
            ],
            [
              -112.109867,
              39.973799
            ],
            [
              -112.102424,
              39.958888
            ],
            [
              -112.10359,
              39.928767
            ],
            [
              -112.085248,
              39.925494
            ],
            [
              -112.081256,
              39.913147
            ],
            [
              -112.053511,
              39.893397
            ],
            [
              -112.059147,
              39.88038
            ],
            [
              -112.08122,
              39.865081
            ],
            [
              -112.075215,
              39.839693
            ],
            [
              -112.065614,
              39.829722
            ],
            [
              -112.076677,
              39.800311
            ],
            [
              -112.089843,
              39.781928
            ],
            [
              -112.047184,
              39.776849
            ],
            [
              -112.0262,
              39.782686
            ],
            [
              -111.990854,
              39.804746
            ],
            [
              -111.989892,
              39.811295
            ],
            [
              -111.969391,
              39.807242
            ],
            [
              -111.957169,
              39.836835
            ],
            [
              -111.937091,
              39.855365
            ],
            [
              -111.909464,
              39.894571
            ],
            [
              -111.891132,
              39.898353
            ],
            [
              -111.881551,
              39.907404
            ],
            [
              -111.866198,
              39.90575
            ],
            [
              -111.858991,
              39.914741
            ],
            [
              -111.832954,
              39.924527
            ],
            [
              -111.831342,
              39.93849
            ],
            [
              -111.82529,
              39.947696
            ],
            [
              -111.811116,
              39.939738
            ],
            [
              -111.794837,
              39.931159
            ],
            [
              -111.780846,
              39.900824
            ],
            [
              -111.756925,
              39.888811
            ],
            [
              -111.757836,
              39.876596
            ],
            [
              -111.73793,
              39.8641
            ],
            [
              -111.748373,
              39.834135
            ],
            [
              -111.766325,
              39.810817
            ],
            [
              -111.641944,
              39.812869
            ],
            [
              -111.566127,
              39.812828
            ],
            [
              -111.247496,
              39.813028
            ],
            [
              -111.079052,
              39.812231
            ],
            [
              -111.079051,
              39.814128
            ],
            [
              -110.85778,
              39.813285
            ],
            [
              -110.857647,
              39.899707
            ],
            [
              -110.891655,
              39.899654
            ],
            [
              -111.082455,
              39.900155
            ],
            [
              -111.083061,
              39.943198
            ],
            [
              -111.118422,
              39.943484
            ],
            [
              -111.131296,
              39.947396
            ],
            [
              -111.119271,
              39.968156
            ],
            [
              -111.129663,
              39.97048
            ],
            [
              -111.143047,
              39.986988
            ],
            [
              -111.167664,
              39.99059
            ],
            [
              -111.167908,
              40.003385
            ],
            [
              -111.18608,
              40.019655
            ],
            [
              -111.205772,
              40.047368
            ],
            [
              -111.226705,
              40.035693
            ],
            [
              -111.246207,
              40.047466
            ],
            [
              -111.249339,
              40.055217
            ],
            [
              -111.236058,
              40.069113
            ],
            [
              -111.242361,
              40.085893
            ],
            [
              -111.221447,
              40.124543
            ],
            [
              -111.225821,
              40.144618
            ],
            [
              -111.231165,
              40.147481
            ],
            [
              -111.225507,
              40.167324
            ],
            [
              -111.258155,
              40.197849
            ],
            [
              -111.273372,
              40.221861
            ],
            [
              -111.264786,
              40.242328
            ],
            [
              -111.311834,
              40.286464
            ],
            [
              -111.360753,
              40.288727
            ],
            [
              -111.374331,
              40.297103
            ],
            [
              -111.443448,
              40.289882
            ],
            [
              -111.457193,
              40.300741
            ],
            [
              -111.43787,
              40.321678
            ],
            [
              -111.480337,
              40.362169
            ],
            [
              -111.555259,
              40.366329
            ],
            [
              -111.560097,
              40.38777
            ],
            [
              -111.574209,
              40.391373
            ],
            [
              -111.59998,
              40.434779
            ],
            [
              -111.610418,
              40.430944
            ],
            [
              -111.611734,
              40.431466
            ],
            [
              -111.620968,
              40.449653
            ],
            [
              -111.575028,
              40.486526
            ],
            [
              -111.579126,
              40.500356
            ],
            [
              -111.567725,
              40.547211
            ],
            [
              -111.593942,
              40.577066
            ],
            [
              -111.605812,
              40.564407
            ],
            [
              -111.637897,
              40.567979
            ],
            [
              -111.650251,
              40.551134
            ],
            [
              -111.684626,
              40.531309
            ],
            [
              -111.698113,
              40.535728
            ],
            [
              -111.720305,
              40.525181
            ],
            [
              -111.73752,
              40.528148
            ],
            [
              -111.762368,
              40.529238
            ],
            [
              -111.787838,
              40.513763
            ],
            [
              -111.80268,
              40.49364
            ],
            [
              -111.811925,
              40.488408
            ],
            [
              -111.836245,
              40.47505
            ],
            [
              -111.883148,
              40.471651
            ],
            [
              -111.90771,
              40.453407
            ],
            [
              -111.913313,
              40.452523
            ],
            [
              -111.913664,
              40.45244
            ],
            [
              -111.913931,
              40.452397
            ],
            [
              -111.91912,
              40.443191
            ],
            [
              -111.929657,
              40.434719
            ],
            [
              -111.946454,
              40.424594
            ],
            [
              -111.982136,
              40.414217
            ],
            [
              -112.006569,
              40.426705
            ],
            [
              -112.031475,
              40.450547
            ],
            [
              -112.03817,
              40.464623
            ],
            [
              -112.066561,
              40.446509
            ],
            [
              -112.093551,
              40.44783
            ],
            [
              -112.112093,
              40.438918
            ],
            [
              -112.116406,
              40.451313
            ],
            [
              -112.143678,
              40.470352
            ],
            [
              -112.172862,
              40.467065
            ],
            [
              -112.19027,
              40.468518
            ],
            [
              -112.213227,
              40.455108
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "49",
      "COUNTYFP": "039",
      "COUNTYNS": "01448033",
      "AFFGEOID": "0500000US49039",
      "GEOID": "49039",
      "NAME": "Sanpete",
      "LSAD": "06",
      "ALAND": 4117901449,
      "AWATER": 32049371,
      "County_state": "Sanpete,49"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -112.015812,
              39.336863
            ],
            [
              -112.016003,
              39.314561
            ],
            [
              -112.01452,
              39.132617
            ],
            [
              -112.014083,
              39.045518
            ],
            [
              -111.849886,
              39.045577
            ],
            [
              -111.853297,
              39.032984
            ],
            [
              -111.802387,
              39.033221
            ],
            [
              -111.29936,
              39.032264
            ],
            [
              -111.299723,
              39.294536
            ],
            [
              -111.301256,
              39.323439
            ],
            [
              -111.30092,
              39.4226
            ],
            [
              -111.300905,
              39.467237
            ],
            [
              -111.247748,
              39.467199
            ],
            [
              -111.247391,
              39.70435
            ],
            [
              -111.247496,
              39.813028
            ],
            [
              -111.566127,
              39.812828
            ],
            [
              -111.641944,
              39.812869
            ],
            [
              -111.642057,
              39.798314
            ],
            [
              -111.622123,
              39.798483
            ],
            [
              -111.622514,
              39.783816
            ],
            [
              -111.584858,
              39.784235
            ],
            [
              -111.584754,
              39.740141
            ],
            [
              -111.642519,
              39.739693
            ],
            [
              -111.642619,
              39.725162
            ],
            [
              -111.689596,
              39.71036
            ],
            [
              -111.689481,
              39.695231
            ],
            [
              -111.717482,
              39.69456
            ],
            [
              -111.715184,
              39.63698
            ],
            [
              -111.713282,
              39.548111
            ],
            [
              -111.713224,
              39.460294
            ],
            [
              -111.747736,
              39.460077
            ],
            [
              -111.747389,
              39.41696
            ],
            [
              -111.747044,
              39.38275
            ],
            [
              -111.922389,
              39.381591
            ],
            [
              -111.921698,
              39.366862
            ],
            [
              -111.958773,
              39.366133
            ],
            [
              -111.958106,
              39.336846
            ],
            [
              -112.015812,
              39.336863
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "49",
      "COUNTYFP": "055",
      "COUNTYNS": "01448041",
      "AFFGEOID": "0500000US49055",
      "GEOID": "49055",
      "NAME": "Wayne",
      "LSAD": "06",
      "ALAND": 6374158879,
      "AWATER": 15154124,
      "County_state": "Wayne,49"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -111.848761,
              38.424933
            ],
            [
              -111.843215,
              38.15128
            ],
            [
              -111.635217,
              38.151183
            ],
            [
              -111.635194,
              38.150087
            ],
            [
              -111.41403,
              38.153982
            ],
            [
              -110.687406,
              38.151385
            ],
            [
              -109.927995,
              38.151884
            ],
            [
              -109.931122,
              38.15805
            ],
            [
              -109.88515,
              38.187767
            ],
            [
              -109.90335,
              38.192932
            ],
            [
              -109.888012,
              38.207483
            ],
            [
              -109.917487,
              38.215333
            ],
            [
              -109.919239,
              38.224783
            ],
            [
              -109.889083,
              38.231476
            ],
            [
              -109.900795,
              38.242843
            ],
            [
              -109.917058,
              38.246717
            ],
            [
              -109.902206,
              38.255957
            ],
            [
              -109.90976,
              38.27123
            ],
            [
              -109.926399,
              38.260013
            ],
            [
              -109.934944,
              38.267481
            ],
            [
              -109.931973,
              38.284253
            ],
            [
              -109.947896,
              38.289066
            ],
            [
              -109.946186,
              38.306802
            ],
            [
              -109.960844,
              38.317259
            ],
            [
              -109.965284,
              38.329351
            ],
            [
              -109.950546,
              38.336835
            ],
            [
              -109.941236,
              38.350146
            ],
            [
              -109.952446,
              38.35434
            ],
            [
              -109.967662,
              38.341197
            ],
            [
              -109.986407,
              38.357396
            ],
            [
              -109.972991,
              38.375909
            ],
            [
              -109.978633,
              38.389018
            ],
            [
              -110.000681,
              38.370611
            ],
            [
              -110.015126,
              38.364604
            ],
            [
              -110.01326,
              38.380381
            ],
            [
              -110.031973,
              38.384957
            ],
            [
              -110.003381,
              38.416182
            ],
            [
              -110.026957,
              38.44737
            ],
            [
              -110.044544,
              38.452624
            ],
            [
              -110.034495,
              38.459913
            ],
            [
              -110.011974,
              38.447649
            ],
            [
              -110.001865,
              38.480231
            ],
            [
              -110.025402,
              38.499981
            ],
            [
              -110.546022,
              38.49998
            ],
            [
              -111.305701,
              38.499998
            ],
            [
              -111.305665,
              38.510169
            ],
            [
              -111.756302,
              38.510147
            ],
            [
              -111.766393,
              38.502243
            ],
            [
              -111.823569,
              38.457218
            ],
            [
              -111.837231,
              38.42854
            ],
            [
              -111.848761,
              38.424933
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "49",
      "COUNTYFP": "015",
      "COUNTYNS": "01448022",
      "AFFGEOID": "0500000US49015",
      "GEOID": "49015",
      "NAME": "Emery",
      "LSAD": "06",
      "ALAND": 11557242845,
      "AWATER": 24843848,
      "County_state": "Emery,49"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -111.307011,
              38.672331
            ],
            [
              -111.305665,
              38.510169
            ],
            [
              -111.305701,
              38.499998
            ],
            [
              -110.546022,
              38.49998
            ],
            [
              -110.025402,
              38.499981
            ],
            [
              -110.026079,
              38.500617
            ],
            [
              -110.037064,
              38.503886
            ],
            [
              -110.045985,
              38.510899
            ],
            [
              -110.044454,
              38.51565
            ],
            [
              -110.030939,
              38.516678
            ],
            [
              -110.013935,
              38.517169
            ],
            [
              -110.00068,
              38.521786
            ],
            [
              -109.997019,
              38.530145
            ],
            [
              -110.004388,
              38.537358
            ],
            [
              -109.997224,
              38.552256
            ],
            [
              -109.989839,
              38.558372
            ],
            [
              -109.989468,
              38.56583
            ],
            [
              -109.999099,
              38.567666
            ],
            [
              -110.003348,
              38.565735
            ],
            [
              -110.018568,
              38.56473
            ],
            [
              -110.029238,
              38.574902
            ],
            [
              -110.043956,
              38.577099
            ],
            [
              -110.052647,
              38.57791
            ],
            [
              -110.055895,
              38.586091
            ],
            [
              -110.045461,
              38.595941
            ],
            [
              -110.025577,
              38.599965
            ],
            [
              -110.018041,
              38.589919
            ],
            [
              -110.008784,
              38.587013
            ],
            [
              -109.99626,
              38.594943
            ],
            [
              -109.996574,
              38.605649
            ],
            [
              -109.999581,
              38.61689
            ],
            [
              -110.018468,
              38.616611
            ],
            [
              -110.038168,
              38.60901
            ],
            [
              -110.052268,
              38.60514
            ],
            [
              -110.065033,
              38.608416
            ],
            [
              -110.050914,
              38.61562
            ],
            [
              -110.048342,
              38.637768
            ],
            [
              -110.039829,
              38.646708
            ],
            [
              -110.036207,
              38.654597
            ],
            [
              -110.047677,
              38.651236
            ],
            [
              -110.058505,
              38.652522
            ],
            [
              -110.067619,
              38.656836
            ],
            [
              -110.069517,
              38.665115
            ],
            [
              -110.068594,
              38.667783
            ],
            [
              -110.082933,
              38.668463
            ],
            [
              -110.092999,
              38.670208
            ],
            [
              -110.088827,
              38.673949
            ],
            [
              -110.09419,
              38.685426
            ],
            [
              -110.093998,
              38.698929
            ],
            [
              -110.086703,
              38.705768
            ],
            [
              -110.108206,
              38.709608
            ],
            [
              -110.121599,
              38.711434
            ],
            [
              -110.113895,
              38.712189
            ],
            [
              -110.108805,
              38.720858
            ],
            [
              -110.10586,
              38.739864
            ],
            [
              -110.086968,
              38.751201
            ],
            [
              -110.08835,
              38.765184
            ],
            [
              -110.099337,
              38.774124
            ],
            [
              -110.114,
              38.782543
            ],
            [
              -110.119236,
              38.789186
            ],
            [
              -110.117312,
              38.796098
            ],
            [
              -110.112422,
              38.797145
            ],
            [
              -110.105455,
              38.807089
            ],
            [
              -110.10924,
              38.815361
            ],
            [
              -110.122244,
              38.825251
            ],
            [
              -110.121178,
              38.831674
            ],
            [
              -110.129561,
              38.834216
            ],
            [
              -110.136791,
              38.83473
            ],
            [
              -110.143255,
              38.848439
            ],
            [
              -110.135944,
              38.860744
            ],
            [
              -110.159824,
              38.887861
            ],
            [
              -110.155049,
              38.926104
            ],
            [
              -110.142767,
              38.956874
            ],
            [
              -110.141206,
              38.965669
            ],
            [
              -110.149292,
              38.971896
            ],
            [
              -110.150423,
              38.974264
            ],
            [
              -110.1057,
              38.983385
            ],
            [
              -110.069114,
              38.978983
            ],
            [
              -110.069134,
              38.964448
            ],
            [
              -110.050505,
              38.964452
            ],
            [
              -110.041198,
              38.949932
            ],
            [
              -110.003984,
              38.949911
            ],
            [
              -110.003977,
              38.964446
            ],
            [
              -110.022587,
              38.964451
            ],
            [
              -110.022559,
              38.978971
            ],
            [
              -110.041184,
              38.978975
            ],
            [
              -110.041176,
              38.993516
            ],
            [
              -110.059811,
              38.993544
            ],
            [
              -110.059807,
              38.986248
            ],
            [
              -110.096336,
              38.986257
            ],
            [
              -110.09632,
              39.001676
            ],
            [
              -110.133899,
              39.001736
            ],
            [
              -110.150178,
              38.997237
            ],
            [
              -110.137964,
              39.004123
            ],
            [
              -110.153728,
              39.014654
            ],
            [
              -110.140302,
              39.025388
            ],
            [
              -110.156222,
              39.032654
            ],
            [
              -110.139869,
              39.05765
            ],
            [
              -110.145349,
              39.077365
            ],
            [
              -110.138896,
              39.08887
            ],
            [
              -110.117571,
              39.096005
            ],
            [
              -110.109332,
              39.112977
            ],
            [
              -110.114796,
              39.120869
            ],
            [
              -110.099411,
              39.136276
            ],
            [
              -110.116148,
              39.152564
            ],
            [
              -110.098471,
              39.168791
            ],
            [
              -110.105297,
              39.178248
            ],
            [
              -110.098763,
              39.189509
            ],
            [
              -110.078835,
              39.193533
            ],
            [
              -110.073406,
              39.201038
            ],
            [
              -110.077834,
              39.22619
            ],
            [
              -110.05457,
              39.228283
            ],
            [
              -110.063572,
              39.249966
            ],
            [
              -110.060624,
              39.263299
            ],
            [
              -110.07727,
              39.275869
            ],
            [
              -110.067328,
              39.293831
            ],
            [
              -110.049667,
              39.300553
            ],
            [
              -110.059554,
              39.307459
            ],
            [
              -110.054584,
              39.330026
            ],
            [
              -110.048191,
              39.32555
            ],
            [
              -110.034074,
              39.348968
            ],
            [
              -110.022166,
              39.352018
            ],
            [
              -110.025118,
              39.377505
            ],
            [
              -110.010617,
              39.416322
            ],
            [
              -110.023434,
              39.423094
            ],
            [
              -110.022978,
              39.446297
            ],
            [
              -110.016576,
              39.456959
            ],
            [
              -110.024118,
              39.469269
            ],
            [
              -110.497362,
              39.469633
            ],
            [
              -111.077545,
              39.469743
            ],
            [
              -111.081333,
              39.467455
            ],
            [
              -111.074689,
              39.491141
            ],
            [
              -111.080585,
              39.495926
            ],
            [
              -111.085349,
              39.521522
            ],
            [
              -111.106492,
              39.546522
            ],
            [
              -111.116335,
              39.551882
            ],
            [
              -111.119209,
              39.571518
            ],
            [
              -111.132121,
              39.61379
            ],
            [
              -111.159113,
              39.595947
            ],
            [
              -111.186239,
              39.619495
            ],
            [
              -111.20103,
              39.656869
            ],
            [
              -111.22164,
              39.675329
            ],
            [
              -111.221717,
              39.691935
            ],
            [
              -111.247391,
              39.70435
            ],
            [
              -111.247748,
              39.467199
            ],
            [
              -111.300905,
              39.467237
            ],
            [
              -111.30092,
              39.4226
            ],
            [
              -111.301256,
              39.323439
            ],
            [
              -111.299723,
              39.294536
            ],
            [
              -111.29936,
              39.032264
            ],
            [
              -111.298195,
              39.017769
            ],
            [
              -111.299138,
              38.672329
            ],
            [
              -111.307011,
              38.672331
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "49",
      "COUNTYFP": "017",
      "COUNTYNS": "01448023",
      "AFFGEOID": "0500000US49017",
      "GEOID": "49017",
      "NAME": "Garfield",
      "LSAD": "06",
      "ALAND": 13403572555,
      "AWATER": 85610784,
      "County_state": "Garfield,49"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -112.689221,
              37.80559
            ],
            [
              -112.689128,
              37.728396
            ],
            [
              -112.685757,
              37.717338
            ],
            [
              -112.68375,
              37.543692
            ],
            [
              -112.361187,
              37.543118
            ],
            [
              -112.164918,
              37.536821
            ],
            [
              -111.84317,
              37.535744
            ],
            [
              -111.814464,
              37.539802
            ],
            [
              -111.012085,
              37.540204
            ],
            [
              -111.00071,
              37.540774
            ],
            [
              -110.646394,
              37.541063
            ],
            [
              -110.643934,
              37.544002
            ],
            [
              -110.628576,
              37.558802
            ],
            [
              -110.61286,
              37.564736
            ],
            [
              -110.601011,
              37.601602
            ],
            [
              -110.573194,
              37.611793
            ],
            [
              -110.566097,
              37.622322
            ],
            [
              -110.563892,
              37.63432
            ],
            [
              -110.549964,
              37.638883
            ],
            [
              -110.529856,
              37.639675
            ],
            [
              -110.507352,
              37.65343
            ],
            [
              -110.504031,
              37.651508
            ],
            [
              -110.497168,
              37.658387
            ],
            [
              -110.493126,
              37.66785
            ],
            [
              -110.484416,
              37.689064
            ],
            [
              -110.483887,
              37.70452
            ],
            [
              -110.469065,
              37.713992
            ],
            [
              -110.454002,
              37.731096
            ],
            [
              -110.447833,
              37.749989
            ],
            [
              -110.435022,
              37.767389
            ],
            [
              -110.42936,
              37.770861
            ],
            [
              -110.437607,
              37.775713
            ],
            [
              -110.447956,
              37.772355
            ],
            [
              -110.463084,
              37.771936
            ],
            [
              -110.47059,
              37.781252
            ],
            [
              -110.472493,
              37.792056
            ],
            [
              -110.476535,
              37.800843
            ],
            [
              -110.467852,
              37.80868
            ],
            [
              -110.461102,
              37.808833
            ],
            [
              -110.447088,
              37.808698
            ],
            [
              -110.435413,
              37.814358
            ],
            [
              -110.434331,
              37.829826
            ],
            [
              -110.418903,
              37.844834
            ],
            [
              -110.410032,
              37.847118
            ],
            [
              -110.40295,
              37.856568
            ],
            [
              -110.402867,
              37.864204
            ],
            [
              -110.402762,
              37.881119
            ],
            [
              -110.388086,
              37.887905
            ],
            [
              -110.368637,
              37.891166
            ],
            [
              -110.346585,
              37.891226
            ],
            [
              -110.326141,
              37.890053
            ],
            [
              -110.300126,
              37.889233
            ],
            [
              -110.29407,
              37.881156
            ],
            [
              -110.293507,
              37.874047
            ],
            [
              -110.287301,
              37.867079
            ],
            [
              -110.282255,
              37.866602
            ],
            [
              -110.277803,
              37.873146
            ],
            [
              -110.278971,
              37.878572
            ],
            [
              -110.27716,
              37.888823
            ],
            [
              -110.267443,
              37.898395
            ],
            [
              -110.255784,
              37.898374
            ],
            [
              -110.233454,
              37.895917
            ],
            [
              -110.217919,
              37.892838
            ],
            [
              -110.20456,
              37.902294
            ],
            [
              -110.20146,
              37.910398
            ],
            [
              -110.201342,
              37.925425
            ],
            [
              -110.205517,
              37.939207
            ],
            [
              -110.196535,
              37.949129
            ],
            [
              -110.18569,
              37.954285
            ],
            [
              -110.174878,
              37.960878
            ],
            [
              -110.166678,
              37.966027
            ],
            [
              -110.163337,
              37.976124
            ],
            [
              -110.156834,
              37.984884
            ],
            [
              -110.151783,
              37.987115
            ],
            [
              -110.142415,
              37.994647
            ],
            [
              -110.13939,
              38.001987
            ],
            [
              -110.133293,
              38.006304
            ],
            [
              -110.121781,
              38.008021
            ],
            [
              -110.111369,
              38.007617
            ],
            [
              -110.102443,
              38.000788
            ],
            [
              -110.088369,
              37.995113
            ],
            [
              -110.075695,
              38.001614
            ],
            [
              -110.075349,
              38.016249
            ],
            [
              -110.072003,
              38.029778
            ],
            [
              -110.064936,
              38.041777
            ],
            [
              -110.053357,
              38.048752
            ],
            [
              -110.044348,
              38.052815
            ],
            [
              -110.043214,
              38.060991
            ],
            [
              -110.044555,
              38.065797
            ],
            [
              -110.046064,
              38.072332
            ],
            [
              -110.042682,
              38.077403
            ],
            [
              -110.037715,
              38.08345
            ],
            [
              -110.032104,
              38.087862
            ],
            [
              -110.028651,
              38.092932
            ],
            [
              -110.02597,
              38.097432
            ],
            [
              -110.02119,
              38.101136
            ],
            [
              -110.016677,
              38.106127
            ],
            [
              -110.009494,
              38.109656
            ],
            [
              -110.003758,
              38.108767
            ],
            [
              -110.000075,
              38.104989
            ],
            [
              -109.995945,
              38.100522
            ],
            [
              -109.990174,
              38.100487
            ],
            [
              -109.983305,
              38.105293
            ],
            [
              -109.966691,
              38.112816
            ],
            [
              -109.95771,
              38.128306
            ],
            [
              -109.936936,
              38.13571
            ],
            [
              -109.927248,
              38.148931
            ],
            [
              -109.927995,
              38.151884
            ],
            [
              -110.687406,
              38.151385
            ],
            [
              -111.41403,
              38.153982
            ],
            [
              -111.635194,
              38.150087
            ],
            [
              -111.635217,
              38.151183
            ],
            [
              -111.843215,
              38.15128
            ],
            [
              -111.951449,
              38.151083
            ],
            [
              -112.060924,
              38.145564
            ],
            [
              -112.444214,
              38.150001
            ],
            [
              -112.473446,
              38.148858
            ],
            [
              -112.47868,
              38.147419
            ],
            [
              -112.478965,
              37.977677
            ],
            [
              -112.466966,
              37.977659
            ],
            [
              -112.468177,
              37.890464
            ],
            [
              -112.588402,
              37.890423
            ],
            [
              -112.578397,
              37.882147
            ],
            [
              -112.57855,
              37.80454
            ],
            [
              -112.643394,
              37.806701
            ],
            [
              -112.689221,
              37.80559
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "49",
      "COUNTYFP": "001",
      "COUNTYNS": "01448015",
      "AFFGEOID": "0500000US49001",
      "GEOID": "49001",
      "NAME": "Beaver",
      "LSAD": "06",
      "ALAND": 6689681822,
      "AWATER": 5298466,
      "County_state": "Beaver,49"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -114.050485,
              38.499955
            ],
            [
              -114.050091,
              38.404673
            ],
            [
              -114.05012,
              38.404536
            ],
            [
              -114.049417,
              38.2647
            ],
            [
              -114.050138,
              38.24996
            ],
            [
              -114.049903375658,
              38.14876302667059
            ],
            [
              -113.256103,
              38.14871
            ],
            [
              -112.47868,
              38.147419
            ],
            [
              -112.473446,
              38.148858
            ],
            [
              -112.444214,
              38.150001
            ],
            [
              -112.422454,
              38.170824
            ],
            [
              -112.424284,
              38.180945
            ],
            [
              -112.395054,
              38.205984
            ],
            [
              -112.36493,
              38.215121
            ],
            [
              -112.35716,
              38.224689
            ],
            [
              -112.379847,
              38.244366
            ],
            [
              -112.377145,
              38.254325
            ],
            [
              -112.350698,
              38.281798
            ],
            [
              -112.348604,
              38.304892
            ],
            [
              -112.338853,
              38.311851
            ],
            [
              -112.335381,
              38.332827
            ],
            [
              -112.343485,
              38.347267
            ],
            [
              -112.362637,
              38.356814
            ],
            [
              -112.380346,
              38.379889
            ],
            [
              -112.391589,
              38.380692
            ],
            [
              -112.403795,
              38.394757
            ],
            [
              -112.403034,
              38.415601
            ],
            [
              -112.417048,
              38.419507
            ],
            [
              -112.425534,
              38.406797
            ],
            [
              -112.444081,
              38.405646
            ],
            [
              -112.453011,
              38.429232
            ],
            [
              -112.479229,
              38.448049
            ],
            [
              -112.493381,
              38.451625
            ],
            [
              -112.501142,
              38.465361
            ],
            [
              -112.519522,
              38.479902
            ],
            [
              -112.510167,
              38.488377
            ],
            [
              -112.518495,
              38.510413
            ],
            [
              -112.522539,
              38.521344
            ],
            [
              -112.517351,
              38.525821
            ],
            [
              -112.515394,
              38.572845
            ],
            [
              -112.603479,
              38.572733
            ],
            [
              -113.047215,
              38.572603
            ],
            [
              -113.750835,
              38.574158
            ],
            [
              -114.05015385888,
              38.5729744583009
            ],
            [
              -114.050154,
              38.57292
            ],
            [
              -114.049862,
              38.547764
            ],
            [
              -114.049834,
              38.543784
            ],
            [
              -114.050485,
              38.499955
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "49",
      "COUNTYFP": "021",
      "COUNTYNS": "01448025",
      "AFFGEOID": "0500000US49021",
      "GEOID": "49021",
      "NAME": "Iron",
      "LSAD": "06",
      "ALAND": 8537474409,
      "AWATER": 11390956,
      "County_state": "Iron,49"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -114.052305834287,
              37.6363164410974
            ],
            [
              -114.052471996614,
              37.6047766427194
            ],
            [
              -113.587797,
              37.604611
            ],
            [
              -113.587773,
              37.618223
            ],
            [
              -113.474485,
              37.618289
            ],
            [
              -113.474029,
              37.529085
            ],
            [
              -113.45489,
              37.529141
            ],
            [
              -113.25334,
              37.528983
            ],
            [
              -113.25349,
              37.482167
            ],
            [
              -113.14611,
              37.481028
            ],
            [
              -113.141958,
              37.474308
            ],
            [
              -113.037048,
              37.474474
            ],
            [
              -113.037088,
              37.499748
            ],
            [
              -112.900993,
              37.500021
            ],
            [
              -112.901162,
              37.543486
            ],
            [
              -112.68375,
              37.543692
            ],
            [
              -112.685757,
              37.717338
            ],
            [
              -112.689128,
              37.728396
            ],
            [
              -112.689221,
              37.80559
            ],
            [
              -112.643394,
              37.806701
            ],
            [
              -112.57855,
              37.80454
            ],
            [
              -112.578397,
              37.882147
            ],
            [
              -112.588402,
              37.890423
            ],
            [
              -112.468177,
              37.890464
            ],
            [
              -112.466966,
              37.977659
            ],
            [
              -112.478965,
              37.977677
            ],
            [
              -112.47868,
              38.147419
            ],
            [
              -113.256103,
              38.14871
            ],
            [
              -114.049903375658,
              38.14876302667059
            ],
            [
              -114.049903,
              38.148601
            ],
            [
              -114.050423,
              37.999961
            ],
            [
              -114.049658,
              37.881368
            ],
            [
              -114.049928,
              37.852508
            ],
            [
              -114.049677,
              37.823645
            ],
            [
              -114.048473,
              37.809861
            ],
            [
              -114.049919,
              37.765586
            ],
            [
              -114.051109,
              37.756276
            ],
            [
              -114.05167,
              37.746958
            ],
            [
              -114.051785,
              37.746249
            ],
            [
              -114.051728,
              37.745997
            ],
            [
              -114.052305834287,
              37.6363164410974
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "49",
      "COUNTYFP": "003",
      "COUNTYNS": "01455966",
      "AFFGEOID": "0500000US49003",
      "GEOID": "49003",
      "NAME": "Box Elder",
      "LSAD": "06",
      "ALAND": 14880984762,
      "AWATER": 2547651528,
      "County_state": "Box Elder,49"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -114.042553,
              41.210923
            ],
            [
              -114.041447,
              41.207752
            ],
            [
              -114.042145,
              40.999926
            ],
            [
              -114.042145133139,
              40.9998965245684
            ],
            [
              -113.201698,
              40.99993
            ],
            [
              -112.799359,
              40.999939
            ],
            [
              -112.493515,
              41.076888
            ],
            [
              -112.339813,
              41.233606
            ],
            [
              -112.339677,
              41.233748
            ],
            [
              -112.238065,
              41.336552
            ],
            [
              -112.034441,
              41.33655
            ],
            [
              -112.028285,
              41.336636
            ],
            [
              -112.023266,
              41.343104
            ],
            [
              -111.963247,
              41.365489
            ],
            [
              -111.975308,
              41.378393
            ],
            [
              -111.967502,
              41.395136
            ],
            [
              -111.972365,
              41.42009
            ],
            [
              -111.962156,
              41.427608
            ],
            [
              -111.917852,
              41.412445
            ],
            [
              -111.885443,
              41.426374
            ],
            [
              -111.881564,
              41.441188
            ],
            [
              -111.916588,
              41.460375
            ],
            [
              -111.913181,
              41.469729
            ],
            [
              -111.87821,
              41.474447
            ],
            [
              -111.873314,
              41.485743
            ],
            [
              -111.88459,
              41.496191
            ],
            [
              -111.904959,
              41.496771
            ],
            [
              -111.913583,
              41.531611
            ],
            [
              -111.946089,
              41.553029
            ],
            [
              -111.981042,
              41.534117
            ],
            [
              -111.996606,
              41.557572
            ],
            [
              -111.994107,
              41.566125
            ],
            [
              -112.006348,
              41.592504
            ],
            [
              -112.005988,
              41.631891
            ],
            [
              -112.013253,
              41.635785
            ],
            [
              -112.018181,
              41.662514
            ],
            [
              -112.051862,
              41.700163
            ],
            [
              -112.042792,
              41.710433
            ],
            [
              -112.042236,
              41.738485
            ],
            [
              -112.021913,
              41.778797
            ],
            [
              -112.008626,
              41.794637
            ],
            [
              -112.030483,
              41.810047
            ],
            [
              -112.043347,
              41.837568
            ],
            [
              -112.052031,
              41.865108
            ],
            [
              -112.085494,
              41.903311
            ],
            [
              -112.121924,
              41.928171
            ],
            [
              -112.138721,
              41.964304
            ],
            [
              -112.150877,
              41.970564
            ],
            [
              -112.164723711068,
              41.9966965611378
            ],
            [
              -112.173352,
              41.996568
            ],
            [
              -112.192976,
              42.001167
            ],
            [
              -112.239107,
              42.001217
            ],
            [
              -112.264936,
              42.000991
            ],
            [
              -112.38617,
              42.001126
            ],
            [
              -112.450567,
              42.001092
            ],
            [
              -112.450814,
              42.000953
            ],
            [
              -112.648019,
              42.000307
            ],
            [
              -112.709375,
              42.000309
            ],
            [
              -112.788542,
              41.999681
            ],
            [
              -112.833084,
              41.999305
            ],
            [
              -112.833125,
              41.999345
            ],
            [
              -112.880619,
              41.998921
            ],
            [
              -112.882367,
              41.998922
            ],
            [
              -112.909587,
              41.998791
            ],
            [
              -112.979218,
              41.998263
            ],
            [
              -113.000040255018,
              41.9982244456233
            ],
            [
              -113.000821,
              41.998223
            ],
            [
              -113.249159,
              41.996203
            ],
            [
              -113.250829,
              41.99561
            ],
            [
              -113.340072,
              41.994747
            ],
            [
              -113.357611,
              41.993859
            ],
            [
              -113.396497,
              41.99425
            ],
            [
              -113.40223,
              41.994161
            ],
            [
              -113.431563,
              41.993799
            ],
            [
              -113.496548,
              41.993305
            ],
            [
              -113.500837,
              41.992799
            ],
            [
              -113.76453,
              41.989459
            ],
            [
              -113.796082,
              41.989104
            ],
            [
              -113.822163,
              41.988479
            ],
            [
              -113.893261,
              41.988057
            ],
            [
              -113.993903,
              41.992698
            ],
            [
              -114.041723,
              41.99372
            ],
            [
              -114.039648,
              41.884816
            ],
            [
              -114.041107,
              41.850573
            ],
            [
              -114.041152,
              41.850595
            ],
            [
              -114.039901,
              41.753781
            ],
            [
              -114.039968,
              41.62492
            ],
            [
              -114.040437,
              41.615377
            ],
            [
              -114.040942,
              41.499921
            ],
            [
              -114.040231,
              41.49169
            ],
            [
              -114.041396,
              41.219958
            ],
            [
              -114.042553,
              41.210923
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "49",
      "COUNTYFP": "047",
      "COUNTYNS": "01448037",
      "AFFGEOID": "0500000US49047",
      "GEOID": "49047",
      "NAME": "Uintah",
      "LSAD": "06",
      "ALAND": 11609319530,
      "AWATER": 56115772,
      "County_state": "Uintah,49"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -110.03284,
              39.536998
            ],
            [
              -110.04823,
              39.53315
            ],
            [
              -110.027083,
              39.523893
            ],
            [
              -110.022282,
              39.499965
            ],
            [
              -110.014593,
              39.491607
            ],
            [
              -110.023963,
              39.469268
            ],
            [
              -109.953349,
              39.46927
            ],
            [
              -109.106972,
              39.461979
            ],
            [
              -109.095826,
              39.471593
            ],
            [
              -109.087568,
              39.492333
            ],
            [
              -109.076753,
              39.489191
            ],
            [
              -109.072976,
              39.499836
            ],
            [
              -109.069465,
              39.495974
            ],
            [
              -109.05136294195901,
              39.497703276010604
            ],
            [
              -109.051040251034,
              39.660470649351794
            ],
            [
              -109.050615,
              39.87497
            ],
            [
              -109.050873,
              40.058915
            ],
            [
              -109.050813,
              40.059579
            ],
            [
              -109.050944,
              40.180712
            ],
            [
              -109.050973,
              40.180849
            ],
            [
              -109.050968717402,
              40.222647002020196
            ],
            [
              -109.050946,
              40.444368
            ],
            [
              -109.050314,
              40.495092
            ],
            [
              -109.050698,
              40.499963
            ],
            [
              -109.049955,
              40.539901
            ],
            [
              -109.050074,
              40.540358
            ],
            [
              -109.048044,
              40.619231
            ],
            [
              -109.048249,
              40.653601
            ],
            [
              -109.048372974241,
              40.662608858969
            ],
            [
              -109.123479,
              40.662385
            ],
            [
              -109.123471,
              40.684127
            ],
            [
              -109.161063,
              40.683817
            ],
            [
              -109.16181,
              40.77155
            ],
            [
              -109.163425,
              40.785955
            ],
            [
              -109.202356,
              40.785814
            ],
            [
              -109.20325,
              40.858401
            ],
            [
              -109.394491,
              40.858166
            ],
            [
              -109.394465,
              40.791032
            ],
            [
              -109.4342,
              40.777258
            ],
            [
              -109.436321,
              40.758046
            ],
            [
              -109.469784,
              40.755153
            ],
            [
              -109.484332,
              40.740432
            ],
            [
              -109.513115,
              40.751454
            ],
            [
              -109.518983,
              40.782277
            ],
            [
              -109.511458,
              40.794528
            ],
            [
              -109.525495,
              40.82637
            ],
            [
              -109.571853,
              40.813415
            ],
            [
              -109.580603,
              40.825345
            ],
            [
              -109.602364,
              40.815075
            ],
            [
              -109.632124,
              40.81567
            ],
            [
              -109.676775,
              40.794865
            ],
            [
              -109.692981,
              40.801894
            ],
            [
              -109.719957,
              40.801851
            ],
            [
              -109.728164,
              40.795028
            ],
            [
              -109.752499,
              40.802851
            ],
            [
              -109.764678,
              40.782533
            ],
            [
              -109.789723,
              40.782254
            ],
            [
              -109.831879,
              40.775731
            ],
            [
              -109.834133,
              40.768242
            ],
            [
              -109.870207,
              40.763829
            ],
            [
              -109.896822,
              40.781984
            ],
            [
              -109.910576,
              40.781357
            ],
            [
              -109.91867,
              40.790885
            ],
            [
              -109.941422,
              40.795014
            ],
            [
              -109.976402,
              40.809686
            ],
            [
              -109.976511,
              40.498936
            ],
            [
              -109.976581,
              40.309417
            ],
            [
              -109.976814,
              39.80623
            ],
            [
              -109.88308,
              39.806236
            ],
            [
              -109.890855,
              39.797754
            ],
            [
              -109.874621,
              39.789739
            ],
            [
              -109.877586,
              39.783174
            ],
            [
              -109.8972,
              39.788884
            ],
            [
              -109.91922,
              39.786194
            ],
            [
              -109.930202,
              39.796037
            ],
            [
              -109.920811,
              39.774141
            ],
            [
              -109.929301,
              39.749964
            ],
            [
              -109.942695,
              39.750664
            ],
            [
              -109.954114,
              39.733874
            ],
            [
              -109.949807,
              39.719684
            ],
            [
              -109.960631,
              39.723441
            ],
            [
              -109.966769,
              39.742737
            ],
            [
              -109.991588,
              39.739237
            ],
            [
              -109.972357,
              39.706102
            ],
            [
              -109.993741,
              39.70278
            ],
            [
              -110.003931,
              39.68269
            ],
            [
              -109.989412,
              39.684498
            ],
            [
              -109.984302,
              39.664128
            ],
            [
              -109.995988,
              39.646629
            ],
            [
              -110.010531,
              39.646471
            ],
            [
              -110.010914,
              39.636574
            ],
            [
              -109.997538,
              39.626037
            ],
            [
              -110.011303,
              39.624962
            ],
            [
              -110.028978,
              39.600651
            ],
            [
              -110.019019,
              39.584766
            ],
            [
              -110.037952,
              39.566667
            ],
            [
              -110.028437,
              39.553361
            ],
            [
              -110.03284,
              39.536998
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "49",
      "COUNTYFP": "053",
      "COUNTYNS": "01448040",
      "AFFGEOID": "0500000US49053",
      "GEOID": "49053",
      "NAME": "Washington",
      "LSAD": "06",
      "ALAND": 6285757433,
      "AWATER": 9061293,
      "County_state": "Washington,49"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -114.052962,
              37.592783
            ],
            [
              -114.052689,
              37.517859
            ],
            [
              -114.052718,
              37.517264
            ],
            [
              -114.052685,
              37.502513
            ],
            [
              -114.052701,
              37.492014
            ],
            [
              -114.05261850687002,
              37.4722632534348
            ],
            [
              -114.052448,
              37.43144
            ],
            [
              -114.051765,
              37.418083
            ],
            [
              -114.051927,
              37.370734
            ],
            [
              -114.051927,
              37.370459
            ],
            [
              -114.0518,
              37.293548
            ],
            [
              -114.0518,
              37.293044
            ],
            [
              -114.051974,
              37.284511
            ],
            [
              -114.051974,
              37.283848
            ],
            [
              -114.051405,
              37.233854
            ],
            [
              -114.051673,
              37.172368
            ],
            [
              -114.052179,
              37.14711
            ],
            [
              -114.051867,
              37.134292
            ],
            [
              -114.052827,
              37.103961
            ],
            [
              -114.051822,
              37.090976
            ],
            [
              -114.051749,
              37.088434
            ],
            [
              -114.0506,
              37.000396
            ],
            [
              -113.965907,
              37.000025
            ],
            [
              -113.965907,
              36.999976
            ],
            [
              -113.55806774779501,
              37.000075160865
            ],
            [
              -113.494884,
              37.0000905231801
            ],
            [
              -113.332654298143,
              37.0001299672441
            ],
            [
              -112.966471,
              37.000219
            ],
            [
              -112.89919090227801,
              37.0003197266157
            ],
            [
              -112.899136,
              37.281402
            ],
            [
              -112.901301,
              37.285129
            ],
            [
              -112.900993,
              37.500021
            ],
            [
              -113.037088,
              37.499748
            ],
            [
              -113.037048,
              37.474474
            ],
            [
              -113.141958,
              37.474308
            ],
            [
              -113.14611,
              37.481028
            ],
            [
              -113.25349,
              37.482167
            ],
            [
              -113.25334,
              37.528983
            ],
            [
              -113.45489,
              37.529141
            ],
            [
              -113.474029,
              37.529085
            ],
            [
              -113.474485,
              37.618289
            ],
            [
              -113.587773,
              37.618223
            ],
            [
              -113.587797,
              37.604611
            ],
            [
              -114.052471996614,
              37.6047766427194
            ],
            [
              -114.052472,
              37.604776
            ],
            [
              -114.052962,
              37.592783
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "49",
      "COUNTYFP": "013",
      "COUNTYNS": "01448021",
      "AFFGEOID": "0500000US49013",
      "GEOID": "49013",
      "NAME": "Duchesne",
      "LSAD": "06",
      "ALAND": 8379502703,
      "AWATER": 38797815,
      "County_state": "Duchesne,49"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -110.901974,
              40.678162
            ],
            [
              -110.902928,
              40.431211
            ],
            [
              -110.896592,
              40.431183
            ],
            [
              -110.896633,
              40.19861
            ],
            [
              -110.89627,
              40.082384
            ],
            [
              -110.891655,
              39.899654
            ],
            [
              -110.857647,
              39.899707
            ],
            [
              -110.85778,
              39.813285
            ],
            [
              -110.518195,
              39.811641
            ],
            [
              -110.518198,
              39.806719
            ],
            [
              -109.976814,
              39.80623
            ],
            [
              -109.976581,
              40.309417
            ],
            [
              -109.976511,
              40.498936
            ],
            [
              -109.976402,
              40.809686
            ],
            [
              -109.987862,
              40.807618
            ],
            [
              -110.000711,
              40.813678
            ],
            [
              -110.002085,
              40.813476
            ],
            [
              -110.008588,
              40.81387
            ],
            [
              -110.013344,
              40.813744
            ],
            [
              -110.016979,
              40.815338
            ],
            [
              -110.019702,
              40.817586
            ],
            [
              -110.022509,
              40.819668
            ],
            [
              -110.027204,
              40.820298
            ],
            [
              -110.032927,
              40.818549
            ],
            [
              -110.038709,
              40.818873
            ],
            [
              -110.041264,
              40.819744
            ],
            [
              -110.04515,
              40.818353
            ],
            [
              -110.046989,
              40.815725
            ],
            [
              -110.051774,
              40.812386
            ],
            [
              -110.057945,
              40.812851
            ],
            [
              -110.063962,
              40.814138
            ],
            [
              -110.068899,
              40.812503
            ],
            [
              -110.074016,
              40.810328
            ],
            [
              -110.079328,
              40.81028
            ],
            [
              -110.084966,
              40.810731
            ],
            [
              -110.090188,
              40.810745
            ],
            [
              -110.09491,
              40.809215
            ],
            [
              -110.099543,
              40.808245
            ],
            [
              -110.104336,
              40.808275
            ],
            [
              -110.108876,
              40.81013
            ],
            [
              -110.115011,
              40.81132
            ],
            [
              -110.121127,
              40.811228
            ],
            [
              -110.12501,
              40.813014
            ],
            [
              -110.1285,
              40.814054
            ],
            [
              -110.136402,
              40.811352
            ],
            [
              -110.144117,
              40.813571
            ],
            [
              -110.147295,
              40.814861
            ],
            [
              -110.150396,
              40.818114
            ],
            [
              -110.157166,
              40.820487
            ],
            [
              -110.162497,
              40.820431
            ],
            [
              -110.168096,
              40.819332
            ],
            [
              -110.17222,
              40.817137
            ],
            [
              -110.178879,
              40.819428
            ],
            [
              -110.182682,
              40.820229
            ],
            [
              -110.185704,
              40.822737
            ],
            [
              -110.187474,
              40.823979
            ],
            [
              -110.189524,
              40.825543
            ],
            [
              -110.191059,
              40.824514
            ],
            [
              -110.19527,
              40.82307
            ],
            [
              -110.200584,
              40.821981
            ],
            [
              -110.206331,
              40.822858
            ],
            [
              -110.210049,
              40.825289
            ],
            [
              -110.213837,
              40.827292
            ],
            [
              -110.218652,
              40.826118
            ],
            [
              -110.225465,
              40.82638
            ],
            [
              -110.227807,
              40.828436
            ],
            [
              -110.230887,
              40.830267
            ],
            [
              -110.232892,
              40.829213
            ],
            [
              -110.239745,
              40.82847
            ],
            [
              -110.243987,
              40.830246
            ],
            [
              -110.248441,
              40.832825
            ],
            [
              -110.257459,
              40.833477
            ],
            [
              -110.266556,
              40.83345
            ],
            [
              -110.275562,
              40.83047
            ],
            [
              -110.280914,
              40.828402
            ],
            [
              -110.286864,
              40.828222
            ],
            [
              -110.290337,
              40.829549
            ],
            [
              -110.291425,
              40.831456
            ],
            [
              -110.29411,
              40.833302
            ],
            [
              -110.297055,
              40.832347
            ],
            [
              -110.301034,
              40.826709
            ],
            [
              -110.307716,
              40.825061
            ],
            [
              -110.314167,
              40.823338
            ],
            [
              -110.318276,
              40.821056
            ],
            [
              -110.327002,
              40.819518
            ],
            [
              -110.333815,
              40.820737
            ],
            [
              -110.339483,
              40.823022
            ],
            [
              -110.343923,
              40.821912
            ],
            [
              -110.349069,
              40.817167
            ],
            [
              -110.350966,
              40.812286
            ],
            [
              -110.348975,
              40.809712
            ],
            [
              -110.35162,
              40.805971
            ],
            [
              -110.353555,
              40.80204
            ],
            [
              -110.356092,
              40.799572
            ],
            [
              -110.360366,
              40.79652
            ],
            [
              -110.365591,
              40.794823
            ],
            [
              -110.369703,
              40.791211
            ],
            [
              -110.373255,
              40.788537
            ],
            [
              -110.375482,
              40.788603
            ],
            [
              -110.378229,
              40.787703
            ],
            [
              -110.381822,
              40.787047
            ],
            [
              -110.386516,
              40.787428
            ],
            [
              -110.39032,
              40.787528
            ],
            [
              -110.393615,
              40.787497
            ],
            [
              -110.396327,
              40.787759
            ],
            [
              -110.399769,
              40.78589
            ],
            [
              -110.403591,
              40.784599
            ],
            [
              -110.408979,
              40.784374
            ],
            [
              -110.412076,
              40.784669
            ],
            [
              -110.414932,
              40.786702
            ],
            [
              -110.419016,
              40.789208
            ],
            [
              -110.422328,
              40.790757
            ],
            [
              -110.4257,
              40.790085
            ],
            [
              -110.427737,
              40.788266
            ],
            [
              -110.429562,
              40.784086
            ],
            [
              -110.431744,
              40.77916
            ],
            [
              -110.436471,
              40.777908
            ],
            [
              -110.440459,
              40.779302
            ],
            [
              -110.444863,
              40.781396
            ],
            [
              -110.449346,
              40.781461
            ],
            [
              -110.453457,
              40.781486
            ],
            [
              -110.455799,
              40.782348
            ],
            [
              -110.4585,
              40.781752
            ],
            [
              -110.460586,
              40.780204
            ],
            [
              -110.461168,
              40.777027
            ],
            [
              -110.467101,
              40.773149
            ],
            [
              -110.472953,
              40.771945
            ],
            [
              -110.478965,
              40.774352
            ],
            [
              -110.484379,
              40.778403
            ],
            [
              -110.485934,
              40.779653
            ],
            [
              -110.489232,
              40.777529
            ],
            [
              -110.490816,
              40.775016
            ],
            [
              -110.495083,
              40.771734
            ],
            [
              -110.498385,
              40.768415
            ],
            [
              -110.50229,
              40.766829
            ],
            [
              -110.50616,
              40.765678
            ],
            [
              -110.510073,
              40.765295
            ],
            [
              -110.51434,
              40.764277
            ],
            [
              -110.523512,
              40.761504
            ],
            [
              -110.527517,
              40.763272
            ],
            [
              -110.53073,
              40.767092
            ],
            [
              -110.533152,
              40.77057
            ],
            [
              -110.536468,
              40.771678
            ],
            [
              -110.541776,
              40.771807
            ],
            [
              -110.548666,
              40.773351
            ],
            [
              -110.559634,
              40.760737
            ],
            [
              -110.582591,
              40.761576
            ],
            [
              -110.585114,
              40.762424
            ],
            [
              -110.588833,
              40.761886
            ],
            [
              -110.592302,
              40.760061
            ],
            [
              -110.596276,
              40.757458
            ],
            [
              -110.601597,
              40.757156
            ],
            [
              -110.605334,
              40.757322
            ],
            [
              -110.607634,
              40.758731
            ],
            [
              -110.610788,
              40.760665
            ],
            [
              -110.614117,
              40.762717
            ],
            [
              -110.617798,
              40.764323
            ],
            [
              -110.619805,
              40.766623
            ],
            [
              -110.622812,
              40.769195
            ],
            [
              -110.627069,
              40.768741
            ],
            [
              -110.630887,
              40.767064
            ],
            [
              -110.633909,
              40.764826
            ],
            [
              -110.635788,
              40.761427
            ],
            [
              -110.639584,
              40.757191
            ],
            [
              -110.643333,
              40.754254
            ],
            [
              -110.64705,
              40.752318
            ],
            [
              -110.647049,
              40.749178
            ],
            [
              -110.648726,
              40.746702
            ],
            [
              -110.651369,
              40.744273
            ],
            [
              -110.654163,
              40.742079
            ],
            [
              -110.656482,
              40.740275
            ],
            [
              -110.659453,
              40.740608
            ],
            [
              -110.662884,
              40.741677
            ],
            [
              -110.667236,
              40.741649
            ],
            [
              -110.671161,
              40.740633
            ],
            [
              -110.675602,
              40.739027
            ],
            [
              -110.678847,
              40.739578
            ],
            [
              -110.68169,
              40.740825
            ],
            [
              -110.686484,
              40.741306
            ],
            [
              -110.690482,
              40.741494
            ],
            [
              -110.6929,
              40.74335
            ],
            [
              -110.69614,
              40.74589
            ],
            [
              -110.698954,
              40.747046
            ],
            [
              -110.703589,
              40.748856
            ],
            [
              -110.707623,
              40.750163
            ],
            [
              -110.711244,
              40.752326
            ],
            [
              -110.713927,
              40.751912
            ],
            [
              -110.716305,
              40.750257
            ],
            [
              -110.719386,
              40.748746
            ],
            [
              -110.721876,
              40.746707
            ],
            [
              -110.723794,
              40.745428
            ],
            [
              -110.728237,
              40.745142
            ],
            [
              -110.732828,
              40.746332
            ],
            [
              -110.73547,
              40.74614
            ],
            [
              -110.739431,
              40.744884
            ],
            [
              -110.743808,
              40.744898
            ],
            [
              -110.747753,
              40.746438
            ],
            [
              -110.75119,
              40.747337
            ],
            [
              -110.754348,
              40.745457
            ],
            [
              -110.757094,
              40.742733
            ],
            [
              -110.762065,
              40.741725
            ],
            [
              -110.764583,
              40.741816
            ],
            [
              -110.766052,
              40.740035
            ],
            [
              -110.765313,
              40.738075
            ],
            [
              -110.767902,
              40.735891
            ],
            [
              -110.771464,
              40.733425
            ],
            [
              -110.775325,
              40.731487
            ],
            [
              -110.780664,
              40.730951
            ],
            [
              -110.783536,
              40.730169
            ],
            [
              -110.786245,
              40.727309
            ],
            [
              -110.789709,
              40.723568
            ],
            [
              -110.79303,
              40.719867
            ],
            [
              -110.797687,
              40.716366
            ],
            [
              -110.800792,
              40.713782
            ],
            [
              -110.804917,
              40.712127
            ],
            [
              -110.809239,
              40.711593
            ],
            [
              -110.814075,
              40.7112
            ],
            [
              -110.818979,
              40.710753
            ],
            [
              -110.823957,
              40.710761
            ],
            [
              -110.827718,
              40.7146
            ],
            [
              -110.832427,
              40.718098
            ],
            [
              -110.836902,
              40.718838
            ],
            [
              -110.840282,
              40.721203
            ],
            [
              -110.843965,
              40.723361
            ],
            [
              -110.846248,
              40.724477
            ],
            [
              -110.849818,
              40.72454
            ],
            [
              -110.852769,
              40.723134
            ],
            [
              -110.857769,
              40.721911
            ],
            [
              -110.863648,
              40.721868
            ],
            [
              -110.867409,
              40.724197
            ],
            [
              -110.871045,
              40.726458
            ],
            [
              -110.874495,
              40.727839
            ],
            [
              -110.881191,
              40.727743
            ],
            [
              -110.89021,
              40.72714
            ],
            [
              -110.892941,
              40.725836
            ],
            [
              -110.893551,
              40.722494
            ],
            [
              -110.891617,
              40.72088
            ],
            [
              -110.88835,
              40.720305
            ],
            [
              -110.886589,
              40.717637
            ],
            [
              -110.885434,
              40.715636
            ],
            [
              -110.889916,
              40.714665
            ],
            [
              -110.89551,
              40.713597
            ],
            [
              -110.90228,
              40.707159
            ],
            [
              -110.898714,
              40.68885
            ],
            [
              -110.901974,
              40.678162
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "49",
      "COUNTYFP": "023",
      "COUNTYNS": "01455158",
      "AFFGEOID": "0500000US49023",
      "GEOID": "49023",
      "NAME": "Juab",
      "LSAD": "06",
      "ALAND": 8784407434,
      "AWATER": 36542088,
      "County_state": "Juab,49"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -114.047783,
              39.79416
            ],
            [
              -114.047273,
              39.759413
            ],
            [
              -114.047728,
              39.542742
            ],
            [
              -114.047727981839,
              39.5427408023268
            ],
            [
              -113.815766,
              39.54409
            ],
            [
              -113.815743,
              39.552644
            ],
            [
              -112.892865,
              39.552518
            ],
            [
              -112.354467,
              39.553684
            ],
            [
              -112.212045,
              39.553987
            ],
            [
              -112.193292,
              39.540451
            ],
            [
              -112.193242,
              39.526023
            ],
            [
              -112.211973,
              39.526042
            ],
            [
              -112.202626,
              39.489858
            ],
            [
              -112.193277,
              39.489834
            ],
            [
              -112.188907,
              39.373089
            ],
            [
              -112.188922,
              39.329392
            ],
            [
              -112.072108,
              39.329695
            ],
            [
              -112.072218,
              39.314923
            ],
            [
              -112.016003,
              39.314561
            ],
            [
              -112.015812,
              39.336863
            ],
            [
              -111.958106,
              39.336846
            ],
            [
              -111.958773,
              39.366133
            ],
            [
              -111.921698,
              39.366862
            ],
            [
              -111.922389,
              39.381591
            ],
            [
              -111.747044,
              39.38275
            ],
            [
              -111.747389,
              39.41696
            ],
            [
              -111.747736,
              39.460077
            ],
            [
              -111.713224,
              39.460294
            ],
            [
              -111.713282,
              39.548111
            ],
            [
              -111.715184,
              39.63698
            ],
            [
              -111.717482,
              39.69456
            ],
            [
              -111.689481,
              39.695231
            ],
            [
              -111.689596,
              39.71036
            ],
            [
              -111.642619,
              39.725162
            ],
            [
              -111.642519,
              39.739693
            ],
            [
              -111.584754,
              39.740141
            ],
            [
              -111.584858,
              39.784235
            ],
            [
              -111.622514,
              39.783816
            ],
            [
              -111.622123,
              39.798483
            ],
            [
              -111.642057,
              39.798314
            ],
            [
              -111.641944,
              39.812869
            ],
            [
              -111.766325,
              39.810817
            ],
            [
              -111.748373,
              39.834135
            ],
            [
              -111.73793,
              39.8641
            ],
            [
              -111.757836,
              39.876596
            ],
            [
              -111.756925,
              39.888811
            ],
            [
              -111.780846,
              39.900824
            ],
            [
              -111.794837,
              39.931159
            ],
            [
              -111.811116,
              39.939738
            ],
            [
              -111.82529,
              39.947696
            ],
            [
              -111.831342,
              39.93849
            ],
            [
              -111.832954,
              39.924527
            ],
            [
              -111.858991,
              39.914741
            ],
            [
              -111.866198,
              39.90575
            ],
            [
              -111.881551,
              39.907404
            ],
            [
              -111.891132,
              39.898353
            ],
            [
              -111.909464,
              39.894571
            ],
            [
              -111.937091,
              39.855365
            ],
            [
              -111.957169,
              39.836835
            ],
            [
              -111.969391,
              39.807242
            ],
            [
              -111.989892,
              39.811295
            ],
            [
              -111.990854,
              39.804746
            ],
            [
              -112.0262,
              39.782686
            ],
            [
              -112.047184,
              39.776849
            ],
            [
              -112.089843,
              39.781928
            ],
            [
              -112.076677,
              39.800311
            ],
            [
              -112.065614,
              39.829722
            ],
            [
              -112.075215,
              39.839693
            ],
            [
              -112.08122,
              39.865081
            ],
            [
              -112.059147,
              39.88038
            ],
            [
              -112.053511,
              39.893397
            ],
            [
              -112.081256,
              39.913147
            ],
            [
              -112.085248,
              39.925494
            ],
            [
              -112.10359,
              39.928767
            ],
            [
              -112.102424,
              39.958888
            ],
            [
              -112.109867,
              39.973799
            ],
            [
              -112.13206,
              39.977128
            ],
            [
              -112.160026,
              40.006758
            ],
            [
              -112.1798,
              40.011656
            ],
            [
              -112.223862,
              39.988989
            ],
            [
              -112.236313,
              39.960579
            ],
            [
              -112.269882,
              39.938947
            ],
            [
              -112.301783,
              39.936547
            ],
            [
              -112.309083,
              39.948347
            ],
            [
              -112.322883,
              39.944347
            ],
            [
              -112.332296,
              39.952789
            ],
            [
              -112.339783,
              39.944147
            ],
            [
              -112.335383,
              39.923247
            ],
            [
              -112.341988,
              39.904458
            ],
            [
              -113.248498,
              39.904714
            ],
            [
              -114.047133613292,
              39.90609768574429
            ],
            [
              -114.047134,
              39.906037
            ],
            [
              -114.047214,
              39.821024
            ],
            [
              -114.047783,
              39.79416
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "49",
      "COUNTYFP": "027",
      "COUNTYNS": "01448027",
      "AFFGEOID": "0500000US49027",
      "GEOID": "49027",
      "NAME": "Millard",
      "LSAD": "06",
      "ALAND": 17106839735,
      "AWATER": 600835682,
      "County_state": "Millard,49"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -114.049883347847,
              38.677365
            ],
            [
              -114.05015385888,
              38.5729744583009
            ],
            [
              -113.750835,
              38.574158
            ],
            [
              -113.047215,
              38.572603
            ],
            [
              -112.603479,
              38.572733
            ],
            [
              -112.515394,
              38.572845
            ],
            [
              -112.503148,
              38.571377
            ],
            [
              -112.503074,
              38.600568
            ],
            [
              -112.484788,
              38.600632
            ],
            [
              -112.484444,
              38.644647
            ],
            [
              -112.466001,
              38.673917
            ],
            [
              -112.447517,
              38.68127
            ],
            [
              -112.356539,
              38.683855
            ],
            [
              -112.309953,
              38.67629
            ],
            [
              -112.30994,
              38.68341
            ],
            [
              -112.28226,
              38.686874
            ],
            [
              -112.28235,
              38.701527
            ],
            [
              -112.255438,
              38.705047
            ],
            [
              -112.236685,
              38.723266
            ],
            [
              -112.218356,
              38.727361
            ],
            [
              -112.228381,
              38.763987
            ],
            [
              -112.228438,
              38.837508
            ],
            [
              -112.224461,
              38.864803
            ],
            [
              -112.188541,
              38.864787
            ],
            [
              -112.18847,
              38.879447
            ],
            [
              -112.171554,
              38.878616
            ],
            [
              -112.169351,
              38.906132
            ],
            [
              -112.15076,
              38.906509
            ],
            [
              -112.151928,
              38.935473
            ],
            [
              -112.134031,
              38.935453
            ],
            [
              -112.133862,
              38.959302
            ],
            [
              -112.065162,
              38.959302
            ],
            [
              -112.065661,
              38.98843
            ],
            [
              -112.056387,
              38.99568
            ],
            [
              -112.018906,
              38.995902
            ],
            [
              -112.014083,
              39.045518
            ],
            [
              -112.01452,
              39.132617
            ],
            [
              -112.016003,
              39.314561
            ],
            [
              -112.072218,
              39.314923
            ],
            [
              -112.072108,
              39.329695
            ],
            [
              -112.188922,
              39.329392
            ],
            [
              -112.188907,
              39.373089
            ],
            [
              -112.193277,
              39.489834
            ],
            [
              -112.202626,
              39.489858
            ],
            [
              -112.211973,
              39.526042
            ],
            [
              -112.193242,
              39.526023
            ],
            [
              -112.193292,
              39.540451
            ],
            [
              -112.212045,
              39.553987
            ],
            [
              -112.354467,
              39.553684
            ],
            [
              -112.892865,
              39.552518
            ],
            [
              -113.815743,
              39.552644
            ],
            [
              -113.815766,
              39.54409
            ],
            [
              -114.047727981839,
              39.5427408023268
            ],
            [
              -114.047079,
              39.499943
            ],
            [
              -114.049104,
              39.005509
            ],
            [
              -114.048054,
              38.878693
            ],
            [
              -114.048521,
              38.876197
            ],
            [
              -114.049465,
              38.874949
            ],
            [
              -114.049168,
              38.749951
            ],
            [
              -114.049749,
              38.72921
            ],
            [
              -114.049883347847,
              38.677365
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "49",
      "COUNTYFP": "041",
      "COUNTYNS": "01448034",
      "AFFGEOID": "0500000US49041",
      "GEOID": "49041",
      "NAME": "Sevier",
      "LSAD": "06",
      "ALAND": 4948000005,
      "AWATER": 20260738,
      "County_state": "Sevier,49"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -112.522539,
              38.521344
            ],
            [
              -112.518495,
              38.510413
            ],
            [
              -112.20526,
              38.512175
            ],
            [
              -112.125753,
              38.51214
            ],
            [
              -112.062132,
              38.51047
            ],
            [
              -112.061965,
              38.50209
            ],
            [
              -111.766393,
              38.502243
            ],
            [
              -111.756302,
              38.510147
            ],
            [
              -111.305665,
              38.510169
            ],
            [
              -111.307011,
              38.672331
            ],
            [
              -111.299138,
              38.672329
            ],
            [
              -111.298195,
              39.017769
            ],
            [
              -111.29936,
              39.032264
            ],
            [
              -111.802387,
              39.033221
            ],
            [
              -111.853297,
              39.032984
            ],
            [
              -111.849886,
              39.045577
            ],
            [
              -112.014083,
              39.045518
            ],
            [
              -112.018906,
              38.995902
            ],
            [
              -112.056387,
              38.99568
            ],
            [
              -112.065661,
              38.98843
            ],
            [
              -112.065162,
              38.959302
            ],
            [
              -112.133862,
              38.959302
            ],
            [
              -112.134031,
              38.935453
            ],
            [
              -112.151928,
              38.935473
            ],
            [
              -112.15076,
              38.906509
            ],
            [
              -112.169351,
              38.906132
            ],
            [
              -112.171554,
              38.878616
            ],
            [
              -112.18847,
              38.879447
            ],
            [
              -112.188541,
              38.864787
            ],
            [
              -112.224461,
              38.864803
            ],
            [
              -112.228438,
              38.837508
            ],
            [
              -112.228381,
              38.763987
            ],
            [
              -112.218356,
              38.727361
            ],
            [
              -112.236685,
              38.723266
            ],
            [
              -112.255438,
              38.705047
            ],
            [
              -112.28235,
              38.701527
            ],
            [
              -112.28226,
              38.686874
            ],
            [
              -112.30994,
              38.68341
            ],
            [
              -112.309953,
              38.67629
            ],
            [
              -112.356539,
              38.683855
            ],
            [
              -112.447517,
              38.68127
            ],
            [
              -112.466001,
              38.673917
            ],
            [
              -112.484444,
              38.644647
            ],
            [
              -112.484788,
              38.600632
            ],
            [
              -112.503074,
              38.600568
            ],
            [
              -112.503148,
              38.571377
            ],
            [
              -112.515394,
              38.572845
            ],
            [
              -112.517351,
              38.525821
            ],
            [
              -112.522539,
              38.521344
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "49",
      "COUNTYFP": "045",
      "COUNTYNS": "01448036",
      "AFFGEOID": "0500000US49045",
      "GEOID": "49045",
      "NAME": "Tooele",
      "LSAD": "06",
      "ALAND": 17979586953,
      "AWATER": 891043654,
      "County_state": "Tooele,49"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -114.046555,
              39.996899
            ],
            [
              -114.047133613292,
              39.90609768574429
            ],
            [
              -113.248498,
              39.904714
            ],
            [
              -112.341988,
              39.904458
            ],
            [
              -112.335383,
              39.923247
            ],
            [
              -112.339783,
              39.944147
            ],
            [
              -112.332296,
              39.952789
            ],
            [
              -112.322883,
              39.944347
            ],
            [
              -112.309083,
              39.948347
            ],
            [
              -112.301783,
              39.936547
            ],
            [
              -112.269882,
              39.938947
            ],
            [
              -112.236313,
              39.960579
            ],
            [
              -112.223862,
              39.988989
            ],
            [
              -112.1798,
              40.011656
            ],
            [
              -112.171981,
              40.039484
            ],
            [
              -112.183594,
              40.059655
            ],
            [
              -112.157179,
              40.09796
            ],
            [
              -112.150892,
              40.107298
            ],
            [
              -112.176175,
              40.131689
            ],
            [
              -112.16793,
              40.147137
            ],
            [
              -112.173993,
              40.154288
            ],
            [
              -112.144298,
              40.173183
            ],
            [
              -112.153373,
              40.182158
            ],
            [
              -112.14862,
              40.195678
            ],
            [
              -112.160499,
              40.199245
            ],
            [
              -112.1495,
              40.212004
            ],
            [
              -112.168992,
              40.219637
            ],
            [
              -112.179693,
              40.23224
            ],
            [
              -112.179499,
              40.249444
            ],
            [
              -112.191588,
              40.263628
            ],
            [
              -112.186495,
              40.286858
            ],
            [
              -112.198605,
              40.306915
            ],
            [
              -112.196233,
              40.325693
            ],
            [
              -112.175533,
              40.335837
            ],
            [
              -112.193987,
              40.359869
            ],
            [
              -112.188734,
              40.372487
            ],
            [
              -112.199169,
              40.382545
            ],
            [
              -112.194669,
              40.421176
            ],
            [
              -112.209562,
              40.432352
            ],
            [
              -112.213227,
              40.455108
            ],
            [
              -112.19027,
              40.468518
            ],
            [
              -112.172862,
              40.467065
            ],
            [
              -112.171567,
              40.471182
            ],
            [
              -112.177317,
              40.484595
            ],
            [
              -112.187647,
              40.513795
            ],
            [
              -112.178764,
              40.52177
            ],
            [
              -112.181469,
              40.580383
            ],
            [
              -112.175337,
              40.603981
            ],
            [
              -112.196393,
              40.62934
            ],
            [
              -112.207676,
              40.715721
            ],
            [
              -112.224077,
              40.722909
            ],
            [
              -112.224979,
              40.723541
            ],
            [
              -112.230192,
              40.728822
            ],
            [
              -112.260216,
              40.769093
            ],
            [
              -112.493515,
              41.076888
            ],
            [
              -112.799359,
              40.999939
            ],
            [
              -113.201698,
              40.99993
            ],
            [
              -114.042145133139,
              40.9998965245684
            ],
            [
              -114.043176,
              40.771675
            ],
            [
              -114.043803,
              40.759205
            ],
            [
              -114.043831,
              40.758666
            ],
            [
              -114.043505,
              40.726292
            ],
            [
              -114.045281,
              40.506586
            ],
            [
              -114.045577,
              40.495801
            ],
            [
              -114.045518,
              40.494474
            ],
            [
              -114.045218,
              40.430282
            ],
            [
              -114.045826,
              40.424823
            ],
            [
              -114.046178,
              40.398313
            ],
            [
              -114.046153,
              40.231971
            ],
            [
              -114.046682520363,
              40.1169354028727
            ],
            [
              -114.046741,
              40.104231
            ],
            [
              -114.046386,
              40.097896
            ],
            [
              -114.046835,
              40.030131
            ],
            [
              -114.046555,
              39.996899
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "49",
      "COUNTYFP": "007",
      "COUNTYNS": "01448018",
      "AFFGEOID": "0500000US49007",
      "GEOID": "49007",
      "NAME": "Carbon",
      "LSAD": "06",
      "ALAND": 3831067724,
      "AWATER": 15844443,
      "County_state": "Carbon,49"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -111.247496,
              39.813028
            ],
            [
              -111.247391,
              39.70435
            ],
            [
              -111.221717,
              39.691935
            ],
            [
              -111.22164,
              39.675329
            ],
            [
              -111.20103,
              39.656869
            ],
            [
              -111.186239,
              39.619495
            ],
            [
              -111.159113,
              39.595947
            ],
            [
              -111.132121,
              39.61379
            ],
            [
              -111.119209,
              39.571518
            ],
            [
              -111.116335,
              39.551882
            ],
            [
              -111.106492,
              39.546522
            ],
            [
              -111.085349,
              39.521522
            ],
            [
              -111.080585,
              39.495926
            ],
            [
              -111.074689,
              39.491141
            ],
            [
              -111.081333,
              39.467455
            ],
            [
              -111.077545,
              39.469743
            ],
            [
              -110.497362,
              39.469633
            ],
            [
              -110.024118,
              39.469269
            ],
            [
              -110.023963,
              39.469268
            ],
            [
              -110.014593,
              39.491607
            ],
            [
              -110.022282,
              39.499965
            ],
            [
              -110.027083,
              39.523893
            ],
            [
              -110.04823,
              39.53315
            ],
            [
              -110.03284,
              39.536998
            ],
            [
              -110.028437,
              39.553361
            ],
            [
              -110.037952,
              39.566667
            ],
            [
              -110.019019,
              39.584766
            ],
            [
              -110.028978,
              39.600651
            ],
            [
              -110.011303,
              39.624962
            ],
            [
              -109.997538,
              39.626037
            ],
            [
              -110.010914,
              39.636574
            ],
            [
              -110.010531,
              39.646471
            ],
            [
              -109.995988,
              39.646629
            ],
            [
              -109.984302,
              39.664128
            ],
            [
              -109.989412,
              39.684498
            ],
            [
              -110.003931,
              39.68269
            ],
            [
              -109.993741,
              39.70278
            ],
            [
              -109.972357,
              39.706102
            ],
            [
              -109.991588,
              39.739237
            ],
            [
              -109.966769,
              39.742737
            ],
            [
              -109.960631,
              39.723441
            ],
            [
              -109.949807,
              39.719684
            ],
            [
              -109.954114,
              39.733874
            ],
            [
              -109.942695,
              39.750664
            ],
            [
              -109.929301,
              39.749964
            ],
            [
              -109.920811,
              39.774141
            ],
            [
              -109.930202,
              39.796037
            ],
            [
              -109.91922,
              39.786194
            ],
            [
              -109.8972,
              39.788884
            ],
            [
              -109.877586,
              39.783174
            ],
            [
              -109.874621,
              39.789739
            ],
            [
              -109.890855,
              39.797754
            ],
            [
              -109.88308,
              39.806236
            ],
            [
              -109.976814,
              39.80623
            ],
            [
              -110.518198,
              39.806719
            ],
            [
              -110.518195,
              39.811641
            ],
            [
              -110.85778,
              39.813285
            ],
            [
              -111.079051,
              39.814128
            ],
            [
              -111.079052,
              39.812231
            ],
            [
              -111.247496,
              39.813028
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "49",
      "COUNTYFP": "019",
      "COUNTYNS": "01448024",
      "AFFGEOID": "0500000US49019",
      "GEOID": "49019",
      "NAME": "Grand",
      "LSAD": "06",
      "ALAND": 9512361722,
      "AWATER": 31552277,
      "County_state": "Grand,49"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -110.155049,
              38.926104
            ],
            [
              -110.159824,
              38.887861
            ],
            [
              -110.135944,
              38.860744
            ],
            [
              -110.143255,
              38.848439
            ],
            [
              -110.136791,
              38.83473
            ],
            [
              -110.129561,
              38.834216
            ],
            [
              -110.121178,
              38.831674
            ],
            [
              -110.122244,
              38.825251
            ],
            [
              -110.10924,
              38.815361
            ],
            [
              -110.105455,
              38.807089
            ],
            [
              -110.112422,
              38.797145
            ],
            [
              -110.117312,
              38.796098
            ],
            [
              -110.119236,
              38.789186
            ],
            [
              -110.114,
              38.782543
            ],
            [
              -110.099337,
              38.774124
            ],
            [
              -110.08835,
              38.765184
            ],
            [
              -110.086968,
              38.751201
            ],
            [
              -110.10586,
              38.739864
            ],
            [
              -110.108805,
              38.720858
            ],
            [
              -110.113895,
              38.712189
            ],
            [
              -110.121599,
              38.711434
            ],
            [
              -110.108206,
              38.709608
            ],
            [
              -110.086703,
              38.705768
            ],
            [
              -110.093998,
              38.698929
            ],
            [
              -110.09419,
              38.685426
            ],
            [
              -110.088827,
              38.673949
            ],
            [
              -110.092999,
              38.670208
            ],
            [
              -110.082933,
              38.668463
            ],
            [
              -110.068594,
              38.667783
            ],
            [
              -110.069517,
              38.665115
            ],
            [
              -110.067619,
              38.656836
            ],
            [
              -110.058505,
              38.652522
            ],
            [
              -110.047677,
              38.651236
            ],
            [
              -110.036207,
              38.654597
            ],
            [
              -110.039829,
              38.646708
            ],
            [
              -110.048342,
              38.637768
            ],
            [
              -110.050914,
              38.61562
            ],
            [
              -110.065033,
              38.608416
            ],
            [
              -110.052268,
              38.60514
            ],
            [
              -110.038168,
              38.60901
            ],
            [
              -110.018468,
              38.616611
            ],
            [
              -109.999581,
              38.61689
            ],
            [
              -109.996574,
              38.605649
            ],
            [
              -109.99626,
              38.594943
            ],
            [
              -110.008784,
              38.587013
            ],
            [
              -110.018041,
              38.589919
            ],
            [
              -110.025577,
              38.599965
            ],
            [
              -110.045461,
              38.595941
            ],
            [
              -110.055895,
              38.586091
            ],
            [
              -110.052647,
              38.57791
            ],
            [
              -110.043956,
              38.577099
            ],
            [
              -110.029238,
              38.574902
            ],
            [
              -110.018568,
              38.56473
            ],
            [
              -110.003348,
              38.565735
            ],
            [
              -109.999099,
              38.567666
            ],
            [
              -109.989468,
              38.56583
            ],
            [
              -109.989839,
              38.558372
            ],
            [
              -109.997224,
              38.552256
            ],
            [
              -110.004388,
              38.537358
            ],
            [
              -109.997019,
              38.530145
            ],
            [
              -110.00068,
              38.521786
            ],
            [
              -110.013935,
              38.517169
            ],
            [
              -110.030939,
              38.516678
            ],
            [
              -110.044454,
              38.51565
            ],
            [
              -110.045985,
              38.510899
            ],
            [
              -110.037064,
              38.503886
            ],
            [
              -110.026079,
              38.500617
            ],
            [
              -110.025402,
              38.499981
            ],
            [
              -109.475344,
              38.499957
            ],
            [
              -109.059962005949,
              38.4999890310142
            ],
            [
              -109.060253,
              38.599328
            ],
            [
              -109.059541,
              38.719888
            ],
            [
              -109.057388,
              38.795456
            ],
            [
              -109.054189,
              38.874984
            ],
            [
              -109.053943,
              38.904414
            ],
            [
              -109.053797,
              38.905284
            ],
            [
              -109.053233,
              38.942467
            ],
            [
              -109.053292,
              38.942878
            ],
            [
              -109.052436,
              38.999985
            ],
            [
              -109.051512,
              39.126095
            ],
            [
              -109.050765,
              39.366677
            ],
            [
              -109.051363,
              39.497674
            ],
            [
              -109.05136294195901,
              39.497703276010604
            ],
            [
              -109.069465,
              39.495974
            ],
            [
              -109.072976,
              39.499836
            ],
            [
              -109.076753,
              39.489191
            ],
            [
              -109.087568,
              39.492333
            ],
            [
              -109.095826,
              39.471593
            ],
            [
              -109.106972,
              39.461979
            ],
            [
              -109.953349,
              39.46927
            ],
            [
              -110.023963,
              39.469268
            ],
            [
              -110.024118,
              39.469269
            ],
            [
              -110.016576,
              39.456959
            ],
            [
              -110.022978,
              39.446297
            ],
            [
              -110.023434,
              39.423094
            ],
            [
              -110.010617,
              39.416322
            ],
            [
              -110.025118,
              39.377505
            ],
            [
              -110.022166,
              39.352018
            ],
            [
              -110.034074,
              39.348968
            ],
            [
              -110.048191,
              39.32555
            ],
            [
              -110.054584,
              39.330026
            ],
            [
              -110.059554,
              39.307459
            ],
            [
              -110.049667,
              39.300553
            ],
            [
              -110.067328,
              39.293831
            ],
            [
              -110.07727,
              39.275869
            ],
            [
              -110.060624,
              39.263299
            ],
            [
              -110.063572,
              39.249966
            ],
            [
              -110.05457,
              39.228283
            ],
            [
              -110.077834,
              39.22619
            ],
            [
              -110.073406,
              39.201038
            ],
            [
              -110.078835,
              39.193533
            ],
            [
              -110.098763,
              39.189509
            ],
            [
              -110.105297,
              39.178248
            ],
            [
              -110.098471,
              39.168791
            ],
            [
              -110.116148,
              39.152564
            ],
            [
              -110.099411,
              39.136276
            ],
            [
              -110.114796,
              39.120869
            ],
            [
              -110.109332,
              39.112977
            ],
            [
              -110.117571,
              39.096005
            ],
            [
              -110.138896,
              39.08887
            ],
            [
              -110.145349,
              39.077365
            ],
            [
              -110.139869,
              39.05765
            ],
            [
              -110.156222,
              39.032654
            ],
            [
              -110.140302,
              39.025388
            ],
            [
              -110.153728,
              39.014654
            ],
            [
              -110.137964,
              39.004123
            ],
            [
              -110.150178,
              38.997237
            ],
            [
              -110.133899,
              39.001736
            ],
            [
              -110.09632,
              39.001676
            ],
            [
              -110.096336,
              38.986257
            ],
            [
              -110.059807,
              38.986248
            ],
            [
              -110.059811,
              38.993544
            ],
            [
              -110.041176,
              38.993516
            ],
            [
              -110.041184,
              38.978975
            ],
            [
              -110.022559,
              38.978971
            ],
            [
              -110.022587,
              38.964451
            ],
            [
              -110.003977,
              38.964446
            ],
            [
              -110.003984,
              38.949911
            ],
            [
              -110.041198,
              38.949932
            ],
            [
              -110.050505,
              38.964452
            ],
            [
              -110.069134,
              38.964448
            ],
            [
              -110.069114,
              38.978983
            ],
            [
              -110.1057,
              38.983385
            ],
            [
              -110.150423,
              38.974264
            ],
            [
              -110.149292,
              38.971896
            ],
            [
              -110.141206,
              38.965669
            ],
            [
              -110.142767,
              38.956874
            ],
            [
              -110.155049,
              38.926104
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "49",
      "COUNTYFP": "033",
      "COUNTYNS": "01448030",
      "AFFGEOID": "0500000US49033",
      "GEOID": "49033",
      "NAME": "Rich",
      "LSAD": "06",
      "ALAND": 2664700942,
      "AWATER": 149106532,
      "County_state": "Rich,49"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -111.502594,
              41.913653
            ],
            [
              -111.511025,
              41.904555
            ],
            [
              -111.500396,
              41.898263
            ],
            [
              -111.494177,
              41.873626
            ],
            [
              -111.507864,
              41.852589
            ],
            [
              -111.460722,
              41.816985
            ],
            [
              -111.4509,
              41.79282
            ],
            [
              -111.454656,
              41.77957
            ],
            [
              -111.443512,
              41.771701
            ],
            [
              -111.442086,
              41.736067
            ],
            [
              -111.420272,
              41.724603
            ],
            [
              -111.417127,
              41.712624
            ],
            [
              -111.425898,
              41.676748
            ],
            [
              -111.402077,
              41.658881
            ],
            [
              -111.416618,
              41.639256
            ],
            [
              -111.410663,
              41.627435
            ],
            [
              -111.42984,
              41.618191
            ],
            [
              -111.427898,
              41.57843
            ],
            [
              -111.433099,
              41.553629
            ],
            [
              -111.445188,
              41.544336
            ],
            [
              -111.442949,
              41.531365
            ],
            [
              -111.456946,
              41.532214
            ],
            [
              -111.456359,
              41.52093
            ],
            [
              -111.478061,
              41.503931
            ],
            [
              -111.474957,
              41.494909
            ],
            [
              -111.489871,
              41.466383
            ],
            [
              -111.500757,
              41.464449
            ],
            [
              -111.510839,
              41.4231
            ],
            [
              -111.507862,
              41.413843
            ],
            [
              -111.475512,
              41.399867
            ],
            [
              -111.477618,
              41.384348
            ],
            [
              -111.445937,
              41.366672
            ],
            [
              -111.420728,
              41.361308
            ],
            [
              -111.384293,
              41.374001
            ],
            [
              -111.367028,
              41.361641
            ],
            [
              -111.331944,
              41.357472
            ],
            [
              -111.327203,
              41.345643
            ],
            [
              -111.294886,
              41.329969
            ],
            [
              -111.292371,
              41.309409
            ],
            [
              -111.261803,
              41.296681
            ],
            [
              -111.261434,
              41.286935
            ],
            [
              -111.277283,
              41.277368
            ],
            [
              -111.27741,
              41.245832
            ],
            [
              -111.269654,
              41.236762
            ],
            [
              -111.281298,
              41.22497
            ],
            [
              -111.253514,
              41.228743
            ],
            [
              -111.252021,
              41.214557
            ],
            [
              -111.221776,
              41.215119
            ],
            [
              -111.231676,
              41.202651
            ],
            [
              -111.225823,
              41.191017
            ],
            [
              -111.242607,
              41.164537
            ],
            [
              -111.264974,
              41.144044
            ],
            [
              -111.232824,
              41.142881
            ],
            [
              -111.202955,
              41.163782
            ],
            [
              -111.148745,
              41.183182
            ],
            [
              -111.129451,
              41.197627
            ],
            [
              -111.133234,
              41.206822
            ],
            [
              -111.110263,
              41.214658
            ],
            [
              -111.101536,
              41.207712
            ],
            [
              -111.075611,
              41.243293
            ],
            [
              -111.063921,
              41.250436
            ],
            [
              -111.04655106042401,
              41.251626854311006
            ],
            [
              -111.046551,
              41.251716
            ],
            [
              -111.04656630533401,
              41.285755063172594
            ],
            [
              -111.0466,
              41.360692
            ],
            [
              -111.046264,
              41.377731
            ],
            [
              -111.045789,
              41.565571
            ],
            [
              -111.045818464842,
              41.5798449482258
            ],
            [
              -111.046230993972,
              41.7796905597888
            ],
            [
              -111.046689,
              42.001567
            ],
            [
              -111.415873,
              42.000748
            ],
            [
              -111.420898,
              42.000793
            ],
            [
              -111.425535,
              42.00084
            ],
            [
              -111.471381,
              41.999739
            ],
            [
              -111.507264,
              41.999518
            ],
            [
              -111.507789674782,
              41.9995175941635
            ],
            [
              -111.507675,
              41.987367
            ],
            [
              -111.484347,
              41.975355
            ],
            [
              -111.473236,
              41.947496
            ],
            [
              -111.471321,
              41.928157
            ],
            [
              -111.478331,
              41.912687
            ],
            [
              -111.502594,
              41.913653
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "49",
      "COUNTYFP": "051",
      "COUNTYNS": "01448039",
      "AFFGEOID": "0500000US49051",
      "GEOID": "49051",
      "NAME": "Wasatch",
      "LSAD": "06",
      "ALAND": 3048467979,
      "AWATER": 79114523,
      "County_state": "Wasatch,49"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -111.620968,
              40.449653
            ],
            [
              -111.611734,
              40.431466
            ],
            [
              -111.610418,
              40.430944
            ],
            [
              -111.59998,
              40.434779
            ],
            [
              -111.574209,
              40.391373
            ],
            [
              -111.560097,
              40.38777
            ],
            [
              -111.555259,
              40.366329
            ],
            [
              -111.480337,
              40.362169
            ],
            [
              -111.43787,
              40.321678
            ],
            [
              -111.457193,
              40.300741
            ],
            [
              -111.443448,
              40.289882
            ],
            [
              -111.374331,
              40.297103
            ],
            [
              -111.360753,
              40.288727
            ],
            [
              -111.311834,
              40.286464
            ],
            [
              -111.264786,
              40.242328
            ],
            [
              -111.273372,
              40.221861
            ],
            [
              -111.258155,
              40.197849
            ],
            [
              -111.225507,
              40.167324
            ],
            [
              -111.231165,
              40.147481
            ],
            [
              -111.225821,
              40.144618
            ],
            [
              -111.221447,
              40.124543
            ],
            [
              -111.242361,
              40.085893
            ],
            [
              -111.236058,
              40.069113
            ],
            [
              -111.249339,
              40.055217
            ],
            [
              -111.246207,
              40.047466
            ],
            [
              -111.226705,
              40.035693
            ],
            [
              -111.205772,
              40.047368
            ],
            [
              -111.18608,
              40.019655
            ],
            [
              -111.167908,
              40.003385
            ],
            [
              -111.167664,
              39.99059
            ],
            [
              -111.143047,
              39.986988
            ],
            [
              -111.129663,
              39.97048
            ],
            [
              -111.119271,
              39.968156
            ],
            [
              -111.131296,
              39.947396
            ],
            [
              -111.118422,
              39.943484
            ],
            [
              -111.083061,
              39.943198
            ],
            [
              -111.082455,
              39.900155
            ],
            [
              -110.891655,
              39.899654
            ],
            [
              -110.89627,
              40.082384
            ],
            [
              -110.896633,
              40.19861
            ],
            [
              -110.896592,
              40.431183
            ],
            [
              -110.902928,
              40.431211
            ],
            [
              -110.901974,
              40.678162
            ],
            [
              -110.933957,
              40.680354
            ],
            [
              -110.948823,
              40.661331
            ],
            [
              -110.944959,
              40.646362
            ],
            [
              -110.962932,
              40.615239
            ],
            [
              -110.961039,
              40.607626
            ],
            [
              -110.976086,
              40.592954
            ],
            [
              -111.00713,
              40.592776
            ],
            [
              -111.024901,
              40.577504
            ],
            [
              -111.055339,
              40.581702
            ],
            [
              -111.080411,
              40.594447
            ],
            [
              -111.12087,
              40.590457
            ],
            [
              -111.131228,
              40.57836
            ],
            [
              -111.137059,
              40.558394
            ],
            [
              -111.15136,
              40.548352
            ],
            [
              -111.181189,
              40.558537
            ],
            [
              -111.214414,
              40.574582
            ],
            [
              -111.238337,
              40.577557
            ],
            [
              -111.265338,
              40.587251
            ],
            [
              -111.30327,
              40.605904
            ],
            [
              -111.327178,
              40.623126
            ],
            [
              -111.368623,
              40.628944
            ],
            [
              -111.390198,
              40.660643
            ],
            [
              -111.389635,
              40.677838
            ],
            [
              -111.397494,
              40.690935
            ],
            [
              -111.412416,
              40.681101
            ],
            [
              -111.43687,
              40.680587
            ],
            [
              -111.430367,
              40.665324
            ],
            [
              -111.473319,
              40.64377
            ],
            [
              -111.486884,
              40.620844
            ],
            [
              -111.489265,
              40.599438
            ],
            [
              -111.527947,
              40.611962
            ],
            [
              -111.553266,
              40.609313
            ],
            [
              -111.564014,
              40.584917
            ],
            [
              -111.593942,
              40.577066
            ],
            [
              -111.567725,
              40.547211
            ],
            [
              -111.579126,
              40.500356
            ],
            [
              -111.575028,
              40.486526
            ],
            [
              -111.620968,
              40.449653
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "49",
      "COUNTYFP": "035",
      "COUNTYNS": "01448031",
      "AFFGEOID": "0500000US49035",
      "GEOID": "49035",
      "NAME": "Salt Lake",
      "LSAD": "06",
      "ALAND": 1921906751,
      "AWATER": 164903351,
      "County_state": "Salt Lake,49"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -112.260216,
              40.769093
            ],
            [
              -112.230192,
              40.728822
            ],
            [
              -112.224979,
              40.723541
            ],
            [
              -112.224077,
              40.722909
            ],
            [
              -112.207676,
              40.715721
            ],
            [
              -112.196393,
              40.62934
            ],
            [
              -112.175337,
              40.603981
            ],
            [
              -112.181469,
              40.580383
            ],
            [
              -112.178764,
              40.52177
            ],
            [
              -112.187647,
              40.513795
            ],
            [
              -112.177317,
              40.484595
            ],
            [
              -112.171567,
              40.471182
            ],
            [
              -112.172862,
              40.467065
            ],
            [
              -112.143678,
              40.470352
            ],
            [
              -112.116406,
              40.451313
            ],
            [
              -112.112093,
              40.438918
            ],
            [
              -112.093551,
              40.44783
            ],
            [
              -112.066561,
              40.446509
            ],
            [
              -112.03817,
              40.464623
            ],
            [
              -112.031475,
              40.450547
            ],
            [
              -112.006569,
              40.426705
            ],
            [
              -111.982136,
              40.414217
            ],
            [
              -111.946454,
              40.424594
            ],
            [
              -111.929657,
              40.434719
            ],
            [
              -111.91912,
              40.443191
            ],
            [
              -111.913931,
              40.452397
            ],
            [
              -111.913664,
              40.45244
            ],
            [
              -111.913313,
              40.452523
            ],
            [
              -111.90771,
              40.453407
            ],
            [
              -111.883148,
              40.471651
            ],
            [
              -111.836245,
              40.47505
            ],
            [
              -111.811925,
              40.488408
            ],
            [
              -111.80268,
              40.49364
            ],
            [
              -111.787838,
              40.513763
            ],
            [
              -111.762368,
              40.529238
            ],
            [
              -111.73752,
              40.528148
            ],
            [
              -111.720305,
              40.525181
            ],
            [
              -111.698113,
              40.535728
            ],
            [
              -111.684626,
              40.531309
            ],
            [
              -111.650251,
              40.551134
            ],
            [
              -111.637897,
              40.567979
            ],
            [
              -111.605812,
              40.564407
            ],
            [
              -111.593942,
              40.577066
            ],
            [
              -111.564014,
              40.584917
            ],
            [
              -111.553266,
              40.609313
            ],
            [
              -111.578242,
              40.644086
            ],
            [
              -111.596736,
              40.659767
            ],
            [
              -111.604411,
              40.666523
            ],
            [
              -111.592726,
              40.69912
            ],
            [
              -111.60039,
              40.708475
            ],
            [
              -111.606221,
              40.712856
            ],
            [
              -111.623469,
              40.731739
            ],
            [
              -111.623483,
              40.75347
            ],
            [
              -111.649129,
              40.773443
            ],
            [
              -111.641052,
              40.798925
            ],
            [
              -111.64565,
              40.824061
            ],
            [
              -111.666128,
              40.832108
            ],
            [
              -111.663996,
              40.850194
            ],
            [
              -111.706732,
              40.852773
            ],
            [
              -111.738744,
              40.860998
            ],
            [
              -111.745999,
              40.860371
            ],
            [
              -111.758726,
              40.852408
            ],
            [
              -111.812616,
              40.84009
            ],
            [
              -111.838417,
              40.830824
            ],
            [
              -111.870895,
              40.821702
            ],
            [
              -111.916184,
              40.821823
            ],
            [
              -111.937785,
              40.821801
            ],
            [
              -111.946408,
              40.822046
            ],
            [
              -111.964494,
              40.853445
            ],
            [
              -111.968734,
              40.886904
            ],
            [
              -111.958643,
              40.912697
            ],
            [
              -111.958672,
              40.921822
            ],
            [
              -112.008134,
              40.921016
            ],
            [
              -112.148271,
              40.846133
            ],
            [
              -112.260216,
              40.769093
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "49",
      "COUNTYFP": "057",
      "COUNTYNS": "01448042",
      "AFFGEOID": "0500000US49057",
      "GEOID": "49057",
      "NAME": "Weber",
      "LSAD": "06",
      "ALAND": 1492520445,
      "AWATER": 216460044,
      "County_state": "Weber,49"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -112.493515,
              41.076888
            ],
            [
              -112.451621,
              41.087334
            ],
            [
              -112.18654,
              41.153027
            ],
            [
              -112.077871,
              41.152703
            ],
            [
              -112.025023,
              41.152647
            ],
            [
              -112.021127,
              41.152652
            ],
            [
              -111.971004,
              41.152647
            ],
            [
              -111.956191,
              41.140631
            ],
            [
              -111.912182,
              41.134831
            ],
            [
              -111.903396,
              41.136339
            ],
            [
              -111.903059,
              41.136533
            ],
            [
              -111.901222,
              41.136466
            ],
            [
              -111.899433,
              41.135536
            ],
            [
              -111.897598,
              41.135627
            ],
            [
              -111.895535,
              41.136097
            ],
            [
              -111.893895,
              41.136668
            ],
            [
              -111.889873,
              41.137618
            ],
            [
              -111.888476,
              41.137748
            ],
            [
              -111.885795,
              41.137352
            ],
            [
              -111.882806,
              41.137348
            ],
            [
              -111.88098,
              41.137786
            ],
            [
              -111.878362,
              41.137478
            ],
            [
              -111.876634,
              41.137832
            ],
            [
              -111.874166,
              41.138139
            ],
            [
              -111.86904,
              41.138059
            ],
            [
              -111.866099,
              41.137264
            ],
            [
              -111.865199,
              41.137616
            ],
            [
              -111.861255,
              41.137511
            ],
            [
              -111.856247,
              41.139083
            ],
            [
              -111.854558,
              41.154522
            ],
            [
              -111.876596,
              41.169744
            ],
            [
              -111.872926,
              41.18755
            ],
            [
              -111.858208,
              41.197596
            ],
            [
              -111.817568,
              41.204709
            ],
            [
              -111.799811,
              41.217178
            ],
            [
              -111.749082,
              41.214541
            ],
            [
              -111.745211,
              41.203468
            ],
            [
              -111.717681,
              41.187539
            ],
            [
              -111.678016,
              41.181208
            ],
            [
              -111.658636,
              41.185718
            ],
            [
              -111.640364,
              41.198947
            ],
            [
              -111.624291,
              41.200373
            ],
            [
              -111.611983,
              41.209559
            ],
            [
              -111.609251,
              41.229222
            ],
            [
              -111.596716,
              41.238553
            ],
            [
              -111.559731,
              41.24024
            ],
            [
              -111.535259,
              41.236181
            ],
            [
              -111.526123,
              41.228752
            ],
            [
              -111.497182,
              41.226905
            ],
            [
              -111.494458,
              41.241255
            ],
            [
              -111.477557,
              41.245204
            ],
            [
              -111.457943,
              41.273563
            ],
            [
              -111.440107,
              41.289585
            ],
            [
              -111.449353,
              41.315919
            ],
            [
              -111.437369,
              41.323785
            ],
            [
              -111.438152,
              41.338105
            ],
            [
              -111.426684,
              41.342775
            ],
            [
              -111.420728,
              41.361308
            ],
            [
              -111.445937,
              41.366672
            ],
            [
              -111.477618,
              41.384348
            ],
            [
              -111.475512,
              41.399867
            ],
            [
              -111.507862,
              41.413843
            ],
            [
              -111.510839,
              41.4231
            ],
            [
              -111.543399,
              41.405362
            ],
            [
              -111.554044,
              41.423425
            ],
            [
              -111.575476,
              41.428774
            ],
            [
              -111.583856,
              41.418416
            ],
            [
              -111.618536,
              41.422347
            ],
            [
              -111.652644,
              41.407793
            ],
            [
              -111.666576,
              41.42883
            ],
            [
              -111.696486,
              41.417074
            ],
            [
              -111.721301,
              41.42739
            ],
            [
              -111.706517,
              41.404844
            ],
            [
              -111.726887,
              41.385051
            ],
            [
              -111.72651,
              41.375814
            ],
            [
              -111.76059,
              41.368818
            ],
            [
              -111.797423,
              41.392316
            ],
            [
              -111.814108,
              41.387964
            ],
            [
              -111.868507,
              41.398497
            ],
            [
              -111.885443,
              41.426374
            ],
            [
              -111.917852,
              41.412445
            ],
            [
              -111.962156,
              41.427608
            ],
            [
              -111.972365,
              41.42009
            ],
            [
              -111.967502,
              41.395136
            ],
            [
              -111.975308,
              41.378393
            ],
            [
              -111.963247,
              41.365489
            ],
            [
              -112.023266,
              41.343104
            ],
            [
              -112.028285,
              41.336636
            ],
            [
              -112.034441,
              41.33655
            ],
            [
              -112.238065,
              41.336552
            ],
            [
              -112.339677,
              41.233748
            ],
            [
              -112.339813,
              41.233606
            ],
            [
              -112.493515,
              41.076888
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "49",
      "COUNTYFP": "025",
      "COUNTYNS": "01448026",
      "AFFGEOID": "0500000US49025",
      "GEOID": "49025",
      "NAME": "Kane",
      "LSAD": "06",
      "ALAND": 10333912540,
      "AWATER": 306169207,
      "County_state": "Kane,49"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -112.900993,
              37.500021
            ],
            [
              -112.901301,
              37.285129
            ],
            [
              -112.899136,
              37.281402
            ],
            [
              -112.89919090227801,
              37.0003197266157
            ],
            [
              -112.82950225110801,
              37.000424059133294
            ],
            [
              -112.609787,
              37.000753
            ],
            [
              -112.558974,
              37.000692
            ],
            [
              -112.545094,
              37.000734
            ],
            [
              -112.540368,
              37.000669
            ],
            [
              -112.538582856208,
              37.0006737486645
            ],
            [
              -112.368946,
              37.001125
            ],
            [
              -112.35769,
              37.001025
            ],
            [
              -111.412783987873,
              37.001477687156004
            ],
            [
              -111.405869,
              37.001481
            ],
            [
              -111.405517,
              37.001497
            ],
            [
              -111.35019712738301,
              37.0010482877479
            ],
            [
              -111.33601,
              37.016929
            ],
            [
              -111.322734,
              37.019282
            ],
            [
              -111.316771,
              37.013588
            ],
            [
              -111.305269,
              37.013164
            ],
            [
              -111.306284,
              37.021683
            ],
            [
              -111.299598,
              37.030643
            ],
            [
              -111.290517,
              37.033973
            ],
            [
              -111.284448,
              37.037836
            ],
            [
              -111.294707,
              37.043501
            ],
            [
              -111.304969,
              37.052411
            ],
            [
              -111.305106,
              37.061962
            ],
            [
              -111.301697,
              37.066412
            ],
            [
              -111.290901,
              37.062866
            ],
            [
              -111.281496,
              37.058494
            ],
            [
              -111.263313,
              37.0489
            ],
            [
              -111.254505,
              37.031389
            ],
            [
              -111.251275,
              37.026039
            ],
            [
              -111.237474,
              37.023932
            ],
            [
              -111.232975,
              37.031588
            ],
            [
              -111.235516,
              37.043354
            ],
            [
              -111.239543,
              37.044484
            ],
            [
              -111.24778,
              37.048976
            ],
            [
              -111.245748,
              37.056098
            ],
            [
              -111.242365,
              37.064322
            ],
            [
              -111.229227,
              37.064746
            ],
            [
              -111.226766,
              37.05975
            ],
            [
              -111.221659,
              37.055983
            ],
            [
              -111.217104,
              37.057374
            ],
            [
              -111.198899,
              37.076922
            ],
            [
              -111.181352,
              37.099537
            ],
            [
              -111.172896,
              37.103889
            ],
            [
              -111.160688,
              37.102405
            ],
            [
              -111.155321,
              37.095861
            ],
            [
              -111.144348,
              37.088819
            ],
            [
              -111.131036,
              37.097172
            ],
            [
              -111.127913,
              37.103342
            ],
            [
              -111.116599,
              37.106488
            ],
            [
              -111.105448,
              37.103869
            ],
            [
              -111.081872,
              37.102993
            ],
            [
              -111.073194,
              37.104462
            ],
            [
              -111.063296,
              37.107516
            ],
            [
              -111.060062,
              37.102835
            ],
            [
              -111.052117,
              37.099072
            ],
            [
              -111.043289,
              37.106933
            ],
            [
              -111.017055,
              37.113159
            ],
            [
              -111.000705,
              37.111693
            ],
            [
              -110.991504,
              37.111093
            ],
            [
              -110.984004,
              37.113993
            ],
            [
              -110.982849,
              37.121289
            ],
            [
              -110.984205,
              37.124393
            ],
            [
              -110.980084,
              37.127827
            ],
            [
              -110.974612,
              37.126442
            ],
            [
              -110.968579,
              37.124993
            ],
            [
              -110.958166,
              37.124996
            ],
            [
              -110.953679,
              37.132095
            ],
            [
              -110.953165,
              37.140405
            ],
            [
              -110.955666,
              37.149238
            ],
            [
              -110.94449,
              37.149373
            ],
            [
              -110.939739,
              37.148258
            ],
            [
              -110.930843,
              37.15465
            ],
            [
              -110.927494,
              37.165613
            ],
            [
              -110.925801,
              37.172589
            ],
            [
              -110.917109,
              37.178436
            ],
            [
              -110.909129,
              37.176133
            ],
            [
              -110.902846,
              37.175025
            ],
            [
              -110.898604,
              37.181602
            ],
            [
              -110.89897,
              37.187047
            ],
            [
              -110.891782,
              37.195434
            ],
            [
              -110.890017,
              37.206687
            ],
            [
              -110.894824,
              37.211717
            ],
            [
              -110.890141,
              37.219427
            ],
            [
              -110.88138,
              37.223185
            ],
            [
              -110.875369,
              37.230148
            ],
            [
              -110.876931,
              37.236428
            ],
            [
              -110.881738,
              37.242518
            ],
            [
              -110.88485,
              37.251265
            ],
            [
              -110.879752,
              37.253601
            ],
            [
              -110.87093,
              37.255343
            ],
            [
              -110.8618,
              37.259203
            ],
            [
              -110.865303,
              37.268392
            ],
            [
              -110.865091,
              37.290487
            ],
            [
              -110.844029,
              37.294272
            ],
            [
              -110.836602,
              37.310392
            ],
            [
              -110.841101,
              37.319152
            ],
            [
              -110.850449,
              37.323983
            ],
            [
              -110.864903,
              37.326731
            ],
            [
              -110.872604,
              37.336491
            ],
            [
              -110.868614,
              37.349358
            ],
            [
              -110.856693,
              37.348405
            ],
            [
              -110.837902,
              37.337892
            ],
            [
              -110.823902,
              37.331392
            ],
            [
              -110.808701,
              37.322992
            ],
            [
              -110.784499,
              37.323792
            ],
            [
              -110.769836,
              37.330876
            ],
            [
              -110.761318,
              37.33272
            ],
            [
              -110.755704,
              37.339098
            ],
            [
              -110.755181,
              37.346557
            ],
            [
              -110.746798,
              37.349492
            ],
            [
              -110.742198,
              37.344392
            ],
            [
              -110.734598,
              37.351292
            ],
            [
              -110.73288,
              37.360234
            ],
            [
              -110.724887,
              37.36634
            ],
            [
              -110.726779,
              37.375911
            ],
            [
              -110.734117,
              37.380832
            ],
            [
              -110.742964,
              37.387425
            ],
            [
              -110.752198,
              37.397391
            ],
            [
              -110.753618,
              37.402384
            ],
            [
              -110.744998,
              37.407491
            ],
            [
              -110.736297,
              37.40517
            ],
            [
              -110.723898,
              37.399391
            ],
            [
              -110.710997,
              37.406191
            ],
            [
              -110.707245,
              37.411894
            ],
            [
              -110.703779,
              37.421843
            ],
            [
              -110.700195,
              37.428784
            ],
            [
              -110.706797,
              37.436891
            ],
            [
              -110.720098,
              37.434791
            ],
            [
              -110.737398,
              37.445591
            ],
            [
              -110.747299,
              37.454491
            ],
            [
              -110.747599,
              37.456843
            ],
            [
              -110.743818,
              37.46015
            ],
            [
              -110.736498,
              37.459091
            ],
            [
              -110.729275,
              37.458208
            ],
            [
              -110.725198,
              37.465591
            ],
            [
              -110.725113,
              37.472178
            ],
            [
              -110.718498,
              37.481391
            ],
            [
              -110.696498,
              37.481591
            ],
            [
              -110.662294,
              37.478186
            ],
            [
              -110.646817,
              37.483496
            ],
            [
              -110.640684,
              37.485416
            ],
            [
              -110.642135,
              37.491633
            ],
            [
              -110.64899,
              37.493861
            ],
            [
              -110.660397,
              37.493891
            ],
            [
              -110.667385,
              37.498674
            ],
            [
              -110.666244,
              37.503732
            ],
            [
              -110.662189,
              37.52233
            ],
            [
              -110.650551,
              37.535122
            ],
            [
              -110.646394,
              37.541063
            ],
            [
              -111.00071,
              37.540774
            ],
            [
              -111.012085,
              37.540204
            ],
            [
              -111.814464,
              37.539802
            ],
            [
              -111.84317,
              37.535744
            ],
            [
              -112.164918,
              37.536821
            ],
            [
              -112.361187,
              37.543118
            ],
            [
              -112.68375,
              37.543692
            ],
            [
              -112.901162,
              37.543486
            ],
            [
              -112.900993,
              37.500021
            ]
          ]
        ]
      ]
    }
  }
]
};