export const counties_tn = {
  "type":"FeatureCollection","name":"tn_counties","features":[
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "151",
      "COUNTYNS": "01639788",
      "AFFGEOID": "0500000US47151",
      "GEOID": "47151",
      "NAME": "Scott",
      "LSAD": "06",
      "ALAND": 1378656638,
      "AWATER": 2414254,
      "County_state": "Scott,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.785341,
              36.603372
            ],
            [
              -84.78539955660389,
              36.60337484144149
            ],
            [
              -84.731839,
              36.524674
            ],
            [
              -84.715139,
              36.498874
            ],
            [
              -84.692534,
              36.435468
            ],
            [
              -84.659082,
              36.395227
            ],
            [
              -84.685913,
              36.388016
            ],
            [
              -84.700785,
              36.370824
            ],
            [
              -84.611042,
              36.270377
            ],
            [
              -84.591841,
              36.276277
            ],
            [
              -84.573041,
              36.266577
            ],
            [
              -84.568741,
              36.237077
            ],
            [
              -84.573741,
              36.229977
            ],
            [
              -84.55244,
              36.233777
            ],
            [
              -84.471037,
              36.203778
            ],
            [
              -84.468136,
              36.191678
            ],
            [
              -84.441034,
              36.163478
            ],
            [
              -84.403633,
              36.174778
            ],
            [
              -84.392433,
              36.201678
            ],
            [
              -84.372731,
              36.216778
            ],
            [
              -84.376133,
              36.260079
            ],
            [
              -84.362333,
              36.271579
            ],
            [
              -84.333921,
              36.277988
            ],
            [
              -84.329927,
              36.285086
            ],
            [
              -84.334433,
              36.388079
            ],
            [
              -84.312086,
              36.384573
            ],
            [
              -84.344701,
              36.415677
            ],
            [
              -84.349939,
              36.466292
            ],
            [
              -84.341329,
              36.465579
            ],
            [
              -84.330434,
              36.50948
            ],
            [
              -84.286533,
              36.50388
            ],
            [
              -84.266877,
              36.511698
            ],
            [
              -84.251132,
              36.53418
            ],
            [
              -84.249231,
              36.564684
            ],
            [
              -84.263733,
              36.56668
            ],
            [
              -84.26133898080309,
              36.5919809398456
            ],
            [
              -84.271176,
              36.591882
            ],
            [
              -84.442837,
              36.596079
            ],
            [
              -84.499938,
              36.596678
            ],
            [
              -84.543138,
              36.596277
            ],
            [
              -84.624939,
              36.599575
            ],
            [
              -84.7784559169908,
              36.6032090178664
            ],
            [
              -84.785341,
              36.603372
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "153",
      "COUNTYNS": "01652643",
      "AFFGEOID": "0500000US47153",
      "GEOID": "47153",
      "NAME": "Sequatchie",
      "LSAD": "06",
      "ALAND": 688550864,
      "AWATER": 498314,
      "County_state": "Sequatchie,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.607326,
              35.531651
            ],
            [
              -85.56957,
              35.505776
            ],
            [
              -85.536658,
              35.487095
            ],
            [
              -85.541332,
              35.461405
            ],
            [
              -85.535814,
              35.431494
            ],
            [
              -85.512284,
              35.422958
            ],
            [
              -85.512878,
              35.407612
            ],
            [
              -85.485635,
              35.406433
            ],
            [
              -85.489224,
              35.383494
            ],
            [
              -85.506166,
              35.357657
            ],
            [
              -85.542347,
              35.341453
            ],
            [
              -85.558551,
              35.319292
            ],
            [
              -85.556757,
              35.308294
            ],
            [
              -85.494538,
              35.279871
            ],
            [
              -85.492557,
              35.274206
            ],
            [
              -85.444144,
              35.262646
            ],
            [
              -85.402913,
              35.223106
            ],
            [
              -85.391427,
              35.201367
            ],
            [
              -85.388293,
              35.180428
            ],
            [
              -85.39486,
              35.167329
            ],
            [
              -85.387089,
              35.147169
            ],
            [
              -85.246005,
              35.319077
            ],
            [
              -85.225877,
              35.354276
            ],
            [
              -85.314337,
              35.417205
            ],
            [
              -85.403298,
              35.49228
            ],
            [
              -85.410869,
              35.51189
            ],
            [
              -85.436147,
              35.541365
            ],
            [
              -85.423804,
              35.567422
            ],
            [
              -85.45352,
              35.55813
            ],
            [
              -85.459856,
              35.54685
            ],
            [
              -85.495752,
              35.547618
            ],
            [
              -85.557535,
              35.532977
            ],
            [
              -85.607326,
              35.531651
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "007",
      "COUNTYNS": "01639725",
      "AFFGEOID": "0500000US47007",
      "GEOID": "47007",
      "NAME": "Bledsoe",
      "LSAD": "06",
      "ALAND": 1052635794,
      "AWATER": 838360,
      "County_state": "Bledsoe,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.423804,
              35.567422
            ],
            [
              -85.436147,
              35.541365
            ],
            [
              -85.410869,
              35.51189
            ],
            [
              -85.403298,
              35.49228
            ],
            [
              -85.314337,
              35.417205
            ],
            [
              -85.225877,
              35.354276
            ],
            [
              -85.18892,
              35.418001
            ],
            [
              -85.168302,
              35.455614
            ],
            [
              -85.148792,
              35.445594
            ],
            [
              -85.135191,
              35.458649
            ],
            [
              -85.115946,
              35.47567
            ],
            [
              -85.146393,
              35.491299
            ],
            [
              -85.119276,
              35.528984
            ],
            [
              -85.099069,
              35.533683
            ],
            [
              -85.107762,
              35.571359
            ],
            [
              -85.047641,
              35.618654
            ],
            [
              -84.916062,
              35.761939
            ],
            [
              -84.928536,
              35.768549
            ],
            [
              -85.028787,
              35.746285
            ],
            [
              -85.04114,
              35.77177
            ],
            [
              -85.084962,
              35.760546
            ],
            [
              -85.103406,
              35.769921
            ],
            [
              -85.254062,
              35.765611
            ],
            [
              -85.270427,
              35.747175
            ],
            [
              -85.265387,
              35.721237
            ],
            [
              -85.300336,
              35.683243
            ],
            [
              -85.423804,
              35.567422
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "055",
      "COUNTYNS": "01639746",
      "AFFGEOID": "0500000US47055",
      "GEOID": "47055",
      "NAME": "Giles",
      "LSAD": "06",
      "ALAND": 1582304826,
      "AWATER": 642572,
      "County_state": "Giles,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.232054,
              35.292704
            ],
            [
              -87.2153,
              35.286625
            ],
            [
              -87.21331,
              35.227563
            ],
            [
              -87.221659,
              35.173377
            ],
            [
              -87.198374,
              35.162614
            ],
            [
              -87.213984,
              35.12331
            ],
            [
              -87.224707,
              35.048883
            ],
            [
              -87.22405322029701,
              34.999326659116896
            ],
            [
              -87.216683,
              34.999148
            ],
            [
              -87.210759,
              34.999024
            ],
            [
              -87.1226456781005,
              34.9973189938664
            ],
            [
              -87.011174,
              34.995162
            ],
            [
              -87.000007,
              34.995121
            ],
            [
              -86.974412,
              34.994513
            ],
            [
              -86.972613,
              34.99461
            ],
            [
              -86.970236,
              34.994546
            ],
            [
              -86.96712,
              34.9944
            ],
            [
              -86.862147,
              34.991956
            ],
            [
              -86.85504577547928,
              34.9919376045345
            ],
            [
              -86.849794,
              34.991924
            ],
            [
              -86.846466,
              34.99186
            ],
            [
              -86.83637,
              34.991764
            ],
            [
              -86.836306,
              34.991764
            ],
            [
              -86.828301,
              35.263677
            ],
            [
              -86.8285,
              35.293376
            ],
            [
              -86.845385,
              35.320889
            ],
            [
              -86.859481,
              35.298966
            ],
            [
              -86.886437,
              35.306444
            ],
            [
              -86.888004,
              35.315176
            ],
            [
              -86.906451,
              35.314075
            ],
            [
              -86.902303,
              35.337476
            ],
            [
              -86.920107,
              35.35218
            ],
            [
              -86.927535,
              35.375142
            ],
            [
              -86.925703,
              35.402475
            ],
            [
              -86.960804,
              35.417021
            ],
            [
              -86.984905,
              35.419775
            ],
            [
              -86.990905,
              35.408175
            ],
            [
              -87.017499,
              35.415816
            ],
            [
              -87.032728,
              35.431709
            ],
            [
              -87.058964,
              35.431265
            ],
            [
              -87.071771,
              35.440383
            ],
            [
              -87.094465,
              35.441845
            ],
            [
              -87.101246,
              35.449614
            ],
            [
              -87.129112,
              35.456019
            ],
            [
              -87.149904,
              35.446918
            ],
            [
              -87.160192,
              35.435377
            ],
            [
              -87.206578,
              35.433207
            ],
            [
              -87.214994,
              35.381302
            ],
            [
              -87.215082,
              35.295769
            ],
            [
              -87.232054,
              35.292704
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "023",
      "COUNTYNS": "01639732",
      "AFFGEOID": "0500000US47023",
      "GEOID": "47023",
      "NAME": "Chester",
      "LSAD": "06",
      "ALAND": 740053456,
      "AWATER": 575590,
      "County_state": "Chester,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.841607,
              35.427826
            ],
            [
              -88.842036,
              35.384447
            ],
            [
              -88.792777,
              35.329188
            ],
            [
              -88.781768,
              35.247587
            ],
            [
              -88.719761,
              35.24755
            ],
            [
              -88.707137,
              35.257875
            ],
            [
              -88.689275,
              35.310222
            ],
            [
              -88.674885,
              35.323415
            ],
            [
              -88.672741,
              35.312454
            ],
            [
              -88.653924,
              35.317571
            ],
            [
              -88.665364,
              35.319193
            ],
            [
              -88.663119,
              35.340258
            ],
            [
              -88.640934,
              35.361017
            ],
            [
              -88.566735,
              35.382275
            ],
            [
              -88.487211,
              35.38523
            ],
            [
              -88.362572,
              35.380994
            ],
            [
              -88.351765,
              35.406735
            ],
            [
              -88.360829,
              35.418972
            ],
            [
              -88.364005,
              35.492801
            ],
            [
              -88.377898,
              35.492853
            ],
            [
              -88.414582,
              35.482339
            ],
            [
              -88.421288,
              35.489027
            ],
            [
              -88.469106,
              35.495882
            ],
            [
              -88.505354,
              35.51203
            ],
            [
              -88.529617,
              35.531835
            ],
            [
              -88.544759,
              35.549056
            ],
            [
              -88.56557,
              35.587088
            ],
            [
              -88.599581,
              35.591729
            ],
            [
              -88.613611,
              35.588089
            ],
            [
              -88.614204,
              35.569161
            ],
            [
              -88.634974,
              35.535333
            ],
            [
              -88.675867,
              35.489163
            ],
            [
              -88.815798,
              35.427451
            ],
            [
              -88.841607,
              35.427826
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "169",
      "COUNTYNS": "01648581",
      "AFFGEOID": "0500000US47169",
      "GEOID": "47169",
      "NAME": "Trousdale",
      "LSAD": "06",
      "ALAND": 296143587,
      "AWATER": 5960710,
      "County_state": "Trousdale,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.283256,
              36.3475
            ],
            [
              -86.272764,
              36.347579
            ],
            [
              -86.264643,
              36.328292
            ],
            [
              -86.27347,
              36.309364
            ],
            [
              -86.264436,
              36.299718
            ],
            [
              -86.249275,
              36.30582
            ],
            [
              -86.244456,
              36.336405
            ],
            [
              -86.229897,
              36.349349
            ],
            [
              -86.226996,
              36.342692
            ],
            [
              -86.20656,
              36.342659
            ],
            [
              -86.204069,
              36.332321
            ],
            [
              -86.184277,
              36.319287
            ],
            [
              -86.175916,
              36.328836
            ],
            [
              -86.136803,
              36.295859
            ],
            [
              -86.126152,
              36.294086
            ],
            [
              -86.110712,
              36.323482
            ],
            [
              -86.0809,
              36.355367
            ],
            [
              -86.036052,
              36.38198
            ],
            [
              -85.982708,
              36.395903
            ],
            [
              -85.971168,
              36.419477
            ],
            [
              -85.978893,
              36.426073
            ],
            [
              -86.168261,
              36.444293
            ],
            [
              -86.190066,
              36.482725
            ],
            [
              -86.228434,
              36.487472
            ],
            [
              -86.239992,
              36.486384
            ],
            [
              -86.23722,
              36.45192
            ],
            [
              -86.268788,
              36.452897
            ],
            [
              -86.272488,
              36.35874
            ],
            [
              -86.283256,
              36.3475
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "053",
      "COUNTYNS": "01639745",
      "AFFGEOID": "0500000US47053",
      "GEOID": "47053",
      "NAME": "Gibson",
      "LSAD": "06",
      "ALAND": 1561106434,
      "AWATER": 2341447,
      "County_state": "Gibson,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.206297,
              36.011814
            ],
            [
              -89.188844,
              35.99976
            ],
            [
              -89.184441,
              35.987712
            ],
            [
              -89.167149,
              35.976676
            ],
            [
              -89.135958,
              35.967841
            ],
            [
              -89.124693,
              35.920972
            ],
            [
              -89.130221,
              35.900339
            ],
            [
              -89.116172,
              35.88571
            ],
            [
              -89.109818,
              35.862353
            ],
            [
              -89.084783,
              35.860955
            ],
            [
              -89.056372,
              35.849804
            ],
            [
              -89.021914,
              35.854491
            ],
            [
              -89.019273,
              35.839751
            ],
            [
              -88.988158,
              35.827838
            ],
            [
              -88.966393,
              35.826897
            ],
            [
              -88.939135,
              35.814745
            ],
            [
              -88.916181,
              35.797307
            ],
            [
              -88.706811,
              35.791075
            ],
            [
              -88.706002,
              35.796042
            ],
            [
              -88.695417,
              36.00181
            ],
            [
              -88.692709,
              36.062746
            ],
            [
              -88.749826,
              36.092386
            ],
            [
              -88.751592,
              36.105418
            ],
            [
              -88.766886,
              36.112352
            ],
            [
              -88.799282,
              36.113849
            ],
            [
              -88.840884,
              36.123495
            ],
            [
              -88.847823,
              36.135246
            ],
            [
              -88.863298,
              36.137639
            ],
            [
              -88.894533,
              36.176835
            ],
            [
              -88.93308,
              36.194567
            ],
            [
              -88.959839,
              36.222834
            ],
            [
              -88.961232,
              36.201635
            ],
            [
              -89.154464,
              36.204678
            ],
            [
              -89.163862,
              36.02619
            ],
            [
              -89.206297,
              36.011814
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "005",
      "COUNTYNS": "01639724",
      "AFFGEOID": "0500000US47005",
      "GEOID": "47005",
      "NAME": "Benton",
      "LSAD": "06",
      "ALAND": 1021248371,
      "AWATER": 108583504,
      "County_state": "Benton,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.214442,
              35.982752
            ],
            [
              -88.217436,
              35.846582
            ],
            [
              -88.177859,
              35.845841
            ],
            [
              -88.084223,
              35.842688
            ],
            [
              -88.051772,
              35.849975
            ],
            [
              -88.029504,
              35.839803
            ],
            [
              -88.028195,
              35.811021
            ],
            [
              -88.003612,
              35.81638
            ],
            [
              -87.970738,
              35.815705
            ],
            [
              -87.966952,
              35.834755
            ],
            [
              -87.96274,
              35.840921
            ],
            [
              -87.940331,
              35.865986
            ],
            [
              -87.928847,
              35.905116
            ],
            [
              -87.926676,
              35.93557
            ],
            [
              -87.934623,
              35.940022
            ],
            [
              -87.974172,
              35.937213
            ],
            [
              -88.012932,
              35.946648
            ],
            [
              -88.02239,
              35.965818
            ],
            [
              -88.020424,
              35.979967
            ],
            [
              -87.99928,
              36.002666
            ],
            [
              -88.000809,
              36.028089
            ],
            [
              -87.989144,
              36.04669
            ],
            [
              -87.959738,
              36.075862
            ],
            [
              -87.937974,
              36.108856
            ],
            [
              -87.919043,
              36.130049
            ],
            [
              -87.915654,
              36.16075
            ],
            [
              -87.933436,
              36.18382
            ],
            [
              -87.940533,
              36.205379
            ],
            [
              -87.951645,
              36.222325
            ],
            [
              -87.949603,
              36.24316
            ],
            [
              -87.944295,
              36.264177
            ],
            [
              -87.955844,
              36.312223
            ],
            [
              -87.98074,
              36.353307
            ],
            [
              -87.99092,
              36.360133
            ],
            [
              -87.993717,
              36.359022
            ],
            [
              -88.031779,
              36.359809
            ],
            [
              -88.095868,
              36.359265
            ],
            [
              -88.092269,
              36.340315
            ],
            [
              -88.102109,
              36.326973
            ],
            [
              -88.10266,
              36.310933
            ],
            [
              -88.090782,
              36.29485
            ],
            [
              -88.087082,
              36.267898
            ],
            [
              -88.082929,
              36.260107
            ],
            [
              -88.100827,
              36.25415
            ],
            [
              -88.10068,
              36.233531
            ],
            [
              -88.113408,
              36.223251
            ],
            [
              -88.135824,
              36.218678
            ],
            [
              -88.139206,
              36.201723
            ],
            [
              -88.172691,
              36.16745
            ],
            [
              -88.176502,
              36.147673
            ],
            [
              -88.212559,
              36.12029
            ],
            [
              -88.214442,
              35.982752
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "101",
      "COUNTYNS": "01639765",
      "AFFGEOID": "0500000US47101",
      "GEOID": "47101",
      "NAME": "Lewis",
      "LSAD": "06",
      "ALAND": 730608078,
      "AWATER": 1031592,
      "County_state": "Lewis,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.711014,
              35.505289
            ],
            [
              -87.717559,
              35.483347
            ],
            [
              -87.685809,
              35.472811
            ],
            [
              -87.645896,
              35.472745
            ],
            [
              -87.646485,
              35.442071
            ],
            [
              -87.607276,
              35.436176
            ],
            [
              -87.597164,
              35.411723
            ],
            [
              -87.575352,
              35.398454
            ],
            [
              -87.516794,
              35.398188
            ],
            [
              -87.509612,
              35.414679
            ],
            [
              -87.478329,
              35.414719
            ],
            [
              -87.478439,
              35.422658
            ],
            [
              -87.44882,
              35.420622
            ],
            [
              -87.448485,
              35.457844
            ],
            [
              -87.325796,
              35.456415
            ],
            [
              -87.326948,
              35.447699
            ],
            [
              -87.294534,
              35.444676
            ],
            [
              -87.274764,
              35.483405
            ],
            [
              -87.283376,
              35.496856
            ],
            [
              -87.269432,
              35.505851
            ],
            [
              -87.282393,
              35.50486
            ],
            [
              -87.285953,
              35.542156
            ],
            [
              -87.29719,
              35.548422
            ],
            [
              -87.317721,
              35.549104
            ],
            [
              -87.322744,
              35.568925
            ],
            [
              -87.352305,
              35.584621
            ],
            [
              -87.352894,
              35.617898
            ],
            [
              -87.332768,
              35.634672
            ],
            [
              -87.339232,
              35.65911
            ],
            [
              -87.357379,
              35.659514
            ],
            [
              -87.418709,
              35.62487
            ],
            [
              -87.459587,
              35.615124
            ],
            [
              -87.50779,
              35.628383
            ],
            [
              -87.549164,
              35.630228
            ],
            [
              -87.568831,
              35.652296
            ],
            [
              -87.586676,
              35.653117
            ],
            [
              -87.60701,
              35.636233
            ],
            [
              -87.623391,
              35.632744
            ],
            [
              -87.627139,
              35.615779
            ],
            [
              -87.658189,
              35.609349
            ],
            [
              -87.64853,
              35.590012
            ],
            [
              -87.651007,
              35.568762
            ],
            [
              -87.685834,
              35.548065
            ],
            [
              -87.690651,
              35.505329
            ],
            [
              -87.711014,
              35.505289
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "113",
      "COUNTYNS": "01639769",
      "AFFGEOID": "0500000US47113",
      "GEOID": "47113",
      "NAME": "Madison",
      "LSAD": "06",
      "ALAND": 1442901129,
      "AWATER": 3930429,
      "County_state": "Madison,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.073463,
              35.572166
            ],
            [
              -89.078876,
              35.431428
            ],
            [
              -88.893132,
              35.428645
            ],
            [
              -88.841607,
              35.427826
            ],
            [
              -88.815798,
              35.427451
            ],
            [
              -88.675867,
              35.489163
            ],
            [
              -88.634974,
              35.535333
            ],
            [
              -88.614204,
              35.569161
            ],
            [
              -88.613611,
              35.588089
            ],
            [
              -88.603575,
              35.626084
            ],
            [
              -88.610736,
              35.646424
            ],
            [
              -88.606862,
              35.789354
            ],
            [
              -88.706811,
              35.791075
            ],
            [
              -88.916181,
              35.797307
            ],
            [
              -88.892938,
              35.783842
            ],
            [
              -89.021364,
              35.670953
            ],
            [
              -89.034964,
              35.67831
            ],
            [
              -89.059911,
              35.679393
            ],
            [
              -89.069544,
              35.692679
            ],
            [
              -89.073463,
              35.572166
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "179",
      "COUNTYNS": "01639797",
      "AFFGEOID": "0500000US47179",
      "GEOID": "47179",
      "NAME": "Washington",
      "LSAD": "06",
      "ALAND": 845605969,
      "AWATER": 8635314,
      "County_state": "Washington,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.676177,
              36.4234
            ],
            [
              -82.702566,
              36.410412
            ],
            [
              -82.631794,
              36.418769
            ],
            [
              -82.629641,
              36.41154
            ],
            [
              -82.647769,
              36.146404
            ],
            [
              -82.642889,
              36.142067
            ],
            [
              -82.617866,
              36.118847
            ],
            [
              -82.595065,
              36.096205
            ],
            [
              -82.527605,
              36.148951
            ],
            [
              -82.487564,
              36.155706
            ],
            [
              -82.437251,
              36.154709
            ],
            [
              -82.420661,
              36.163207
            ],
            [
              -82.416662,
              36.179207
            ],
            [
              -82.401362,
              36.194807
            ],
            [
              -82.368638,
              36.212125
            ],
            [
              -82.356297,
              36.230066
            ],
            [
              -82.358061,
              36.240907
            ],
            [
              -82.34046,
              36.252807
            ],
            [
              -82.342282,
              36.263977
            ],
            [
              -82.315109,
              36.326356
            ],
            [
              -82.304354,
              36.357701
            ],
            [
              -82.299968,
              36.37114
            ],
            [
              -82.299859,
              36.396505
            ],
            [
              -82.337507,
              36.401896
            ],
            [
              -82.369413,
              36.403057
            ],
            [
              -82.386163,
              36.392104
            ],
            [
              -82.374351,
              36.419627
            ],
            [
              -82.408571,
              36.426284
            ],
            [
              -82.405017,
              36.436535
            ],
            [
              -82.421908,
              36.438946
            ],
            [
              -82.421572,
              36.447254
            ],
            [
              -82.437266,
              36.437603
            ],
            [
              -82.450581,
              36.444101
            ],
            [
              -82.601474,
              36.4232
            ],
            [
              -82.662877,
              36.4425
            ],
            [
              -82.680677,
              36.4318
            ],
            [
              -82.676177,
              36.4234
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "033",
      "COUNTYNS": "01648574",
      "AFFGEOID": "0500000US47033",
      "GEOID": "47033",
      "NAME": "Crockett",
      "LSAD": "06",
      "ALAND": 687766537,
      "AWATER": 494100,
      "County_state": "Crockett,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.356792,
              35.837839
            ],
            [
              -89.356033,
              35.817635
            ],
            [
              -89.350504,
              35.812094
            ],
            [
              -89.337575,
              35.791665
            ],
            [
              -89.309502,
              35.772923
            ],
            [
              -89.2862,
              35.772012
            ],
            [
              -89.271224,
              35.754922
            ],
            [
              -89.204432,
              35.747163
            ],
            [
              -89.171701,
              35.735714
            ],
            [
              -89.142252,
              35.719818
            ],
            [
              -89.117056,
              35.699271
            ],
            [
              -89.079584,
              35.697319
            ],
            [
              -89.069544,
              35.692679
            ],
            [
              -89.059911,
              35.679393
            ],
            [
              -89.034964,
              35.67831
            ],
            [
              -89.021364,
              35.670953
            ],
            [
              -88.892938,
              35.783842
            ],
            [
              -88.916181,
              35.797307
            ],
            [
              -88.939135,
              35.814745
            ],
            [
              -88.966393,
              35.826897
            ],
            [
              -88.988158,
              35.827838
            ],
            [
              -89.019273,
              35.839751
            ],
            [
              -89.021914,
              35.854491
            ],
            [
              -89.056372,
              35.849804
            ],
            [
              -89.084783,
              35.860955
            ],
            [
              -89.109818,
              35.862353
            ],
            [
              -89.116172,
              35.88571
            ],
            [
              -89.130221,
              35.900339
            ],
            [
              -89.124693,
              35.920972
            ],
            [
              -89.135958,
              35.967841
            ],
            [
              -89.167149,
              35.976676
            ],
            [
              -89.184441,
              35.987712
            ],
            [
              -89.188844,
              35.99976
            ],
            [
              -89.205277,
              35.963117
            ],
            [
              -89.250069,
              35.917348
            ],
            [
              -89.267229,
              35.913542
            ],
            [
              -89.280188,
              35.886416
            ],
            [
              -89.342829,
              35.880934
            ],
            [
              -89.339567,
              35.862268
            ],
            [
              -89.356792,
              35.837839
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "067",
      "COUNTYNS": "01648577",
      "AFFGEOID": "0500000US47067",
      "GEOID": "47067",
      "NAME": "Hancock",
      "LSAD": "06",
      "ALAND": 575816105,
      "AWATER": 2988718,
      "County_state": "Hancock,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.472108,
              36.597284
            ],
            [
              -83.432626,
              36.573098
            ],
            [
              -83.398303,
              36.534885
            ],
            [
              -83.369401,
              36.459186
            ],
            [
              -83.371586,
              36.433905
            ],
            [
              -83.3868,
              36.413186
            ],
            [
              -83.389443,
              36.408162
            ],
            [
              -83.352099,
              36.419787
            ],
            [
              -83.340998,
              36.408388
            ],
            [
              -83.308597,
              36.399589
            ],
            [
              -83.304697,
              36.388289
            ],
            [
              -83.280595,
              36.394689
            ],
            [
              -83.222794,
              36.41019
            ],
            [
              -83.193894,
              36.42429
            ],
            [
              -83.206937,
              36.441559
            ],
            [
              -83.162093,
              36.46159
            ],
            [
              -83.167194,
              36.47129
            ],
            [
              -83.134432,
              36.497301
            ],
            [
              -83.120966,
              36.513883
            ],
            [
              -83.040489,
              36.551891
            ],
            [
              -83.010789,
              36.563691
            ],
            [
              -82.99016,
              36.561895
            ],
            [
              -82.98388,
              36.544489
            ],
            [
              -82.960772,
              36.555155
            ],
            [
              -82.950519,
              36.543005
            ],
            [
              -82.884029,
              36.573171
            ],
            [
              -82.830433,
              36.593761
            ],
            [
              -82.888013,
              36.593461
            ],
            [
              -82.9851235492625,
              36.5937302148783
            ],
            [
              -83.02725,
              36.593847
            ],
            [
              -83.028357,
              36.593893
            ],
            [
              -83.248933,
              36.593827
            ],
            [
              -83.249899,
              36.593898
            ],
            [
              -83.250304,
              36.593935
            ],
            [
              -83.261099,
              36.593887
            ],
            [
              -83.2763,
              36.598187
            ],
            [
              -83.374904,
              36.597386
            ],
            [
              -83.472108,
              36.597284
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "159",
      "COUNTYNS": "01639791",
      "AFFGEOID": "0500000US47159",
      "GEOID": "47159",
      "NAME": "Smith",
      "LSAD": "06",
      "ALAND": 814020549,
      "AWATER": 28690300,
      "County_state": "Smith,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.126152,
              36.294086
            ],
            [
              -86.136803,
              36.295859
            ],
            [
              -86.124885,
              36.265489
            ],
            [
              -86.115607,
              36.268886
            ],
            [
              -86.119341,
              36.251147
            ],
            [
              -86.121367,
              36.231389
            ],
            [
              -86.111275,
              36.228566
            ],
            [
              -86.077223,
              36.135139
            ],
            [
              -86.066132,
              36.124178
            ],
            [
              -86.059706,
              36.086024
            ],
            [
              -86.042684,
              36.089279
            ],
            [
              -86.013396,
              36.083446
            ],
            [
              -86.012283,
              36.09004
            ],
            [
              -85.986083,
              36.087095
            ],
            [
              -85.959279,
              36.110621
            ],
            [
              -85.948414,
              36.101991
            ],
            [
              -85.93789,
              36.109093
            ],
            [
              -85.911267,
              36.097528
            ],
            [
              -85.910118,
              36.116925
            ],
            [
              -85.896938,
              36.116275
            ],
            [
              -85.894357,
              36.104963
            ],
            [
              -85.864024,
              36.093809
            ],
            [
              -85.83827,
              36.128855
            ],
            [
              -85.828647,
              36.122639
            ],
            [
              -85.807415,
              36.131582
            ],
            [
              -85.795517,
              36.191738
            ],
            [
              -85.809168,
              36.206598
            ],
            [
              -85.786212,
              36.210696
            ],
            [
              -85.780148,
              36.238011
            ],
            [
              -85.804269,
              36.255822
            ],
            [
              -85.813443,
              36.248814
            ],
            [
              -85.82766,
              36.255272
            ],
            [
              -85.837995,
              36.281787
            ],
            [
              -85.846483,
              36.288167
            ],
            [
              -85.828026,
              36.411836
            ],
            [
              -85.895732,
              36.420446
            ],
            [
              -85.978893,
              36.426073
            ],
            [
              -85.971168,
              36.419477
            ],
            [
              -85.982708,
              36.395903
            ],
            [
              -86.036052,
              36.38198
            ],
            [
              -86.0809,
              36.355367
            ],
            [
              -86.110712,
              36.323482
            ],
            [
              -86.126152,
              36.294086
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "105",
      "COUNTYNS": "01639767",
      "AFFGEOID": "0500000US47105",
      "GEOID": "47105",
      "NAME": "Loudon",
      "LSAD": "06",
      "ALAND": 593760987,
      "AWATER": 46923925,
      "County_state": "Loudon,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.584472,
              35.644424
            ],
            [
              -84.584296,
              35.633086
            ],
            [
              -84.557872,
              35.629651
            ],
            [
              -84.547603,
              35.641327
            ],
            [
              -84.52585,
              35.624186
            ],
            [
              -84.504746,
              35.619542
            ],
            [
              -84.509201,
              35.639474
            ],
            [
              -84.522162,
              35.651394
            ],
            [
              -84.504595,
              35.651034
            ],
            [
              -84.504274,
              35.665866
            ],
            [
              -84.495592,
              35.655445
            ],
            [
              -84.45724,
              35.659081
            ],
            [
              -84.457249,
              35.665763
            ],
            [
              -84.423549,
              35.671735
            ],
            [
              -84.424785,
              35.661078
            ],
            [
              -84.406472,
              35.665622
            ],
            [
              -84.398074,
              35.640208
            ],
            [
              -84.374397,
              35.641706
            ],
            [
              -84.353275,
              35.654396
            ],
            [
              -84.357608,
              35.665162
            ],
            [
              -84.326477,
              35.665005
            ],
            [
              -84.290765,
              35.654925
            ],
            [
              -84.297522,
              35.643152
            ],
            [
              -84.281835,
              35.635572
            ],
            [
              -84.25079,
              35.636989
            ],
            [
              -84.254461,
              35.620585
            ],
            [
              -84.221451,
              35.615322
            ],
            [
              -84.19263,
              35.618241
            ],
            [
              -84.188319,
              35.610549
            ],
            [
              -84.151652,
              35.627498
            ],
            [
              -84.134787,
              35.649061
            ],
            [
              -84.133222,
              35.660392
            ],
            [
              -84.153818,
              35.673866
            ],
            [
              -84.162524,
              35.698224
            ],
            [
              -84.172812,
              35.712756
            ],
            [
              -84.167955,
              35.741589
            ],
            [
              -84.151955,
              35.757554
            ],
            [
              -84.179499,
              35.768449
            ],
            [
              -84.172745,
              35.804419
            ],
            [
              -84.166267,
              35.805106
            ],
            [
              -84.168202,
              35.809477
            ],
            [
              -84.195349,
              35.834689
            ],
            [
              -84.240753,
              35.876654
            ],
            [
              -84.25894,
              35.893796
            ],
            [
              -84.263383,
              35.89694
            ],
            [
              -84.282819,
              35.902319
            ],
            [
              -84.28169,
              35.875467
            ],
            [
              -84.32997,
              35.892904
            ],
            [
              -84.338871,
              35.850295
            ],
            [
              -84.347624,
              35.832367
            ],
            [
              -84.371537,
              35.825862
            ],
            [
              -84.379668,
              35.817439
            ],
            [
              -84.388607,
              35.768352
            ],
            [
              -84.416415,
              35.776451
            ],
            [
              -84.42601,
              35.753255
            ],
            [
              -84.43642,
              35.748367
            ],
            [
              -84.448558,
              35.759426
            ],
            [
              -84.494488,
              35.716362
            ],
            [
              -84.510472,
              35.716448
            ],
            [
              -84.531909,
              35.683997
            ],
            [
              -84.530976,
              35.670382
            ],
            [
              -84.553352,
              35.658825
            ],
            [
              -84.575602,
              35.658889
            ],
            [
              -84.584472,
              35.644424
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "157",
      "COUNTYNS": "01639790",
      "AFFGEOID": "0500000US47157",
      "GEOID": "47157",
      "NAME": "Shelby",
      "LSAD": "06",
      "ALAND": 1977720315,
      "AWATER": 55485268,
      "County_state": "Shelby,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.309877,
              35.00975
            ],
            [
              -90.310298,
              35.004295
            ],
            [
              -90.309297,
              34.995694
            ],
            [
              -90.0963372057652,
              34.9950088746566
            ],
            [
              -90.0607842992266,
              34.994894495332595
            ],
            [
              -90.0250168365057,
              34.9947794257475
            ],
            [
              -89.9899073593362,
              34.99466647300589
            ],
            [
              -89.9014606033329,
              34.9943819258016
            ],
            [
              -89.893402,
              34.994356
            ],
            [
              -89.8837478802462,
              34.994264623953704
            ],
            [
              -89.883365,
              34.994261
            ],
            [
              -89.848488,
              34.994193
            ],
            [
              -89.8131287302322,
              34.9942593388487
            ],
            [
              -89.795187,
              34.994293
            ],
            [
              -89.75961233055601,
              34.994893216792796
            ],
            [
              -89.741785,
              34.995194
            ],
            [
              -89.7243244282036,
              34.995211728650496
            ],
            [
              -89.644282,
              34.995293
            ],
            [
              -89.637081,
              35.197889
            ],
            [
              -89.637878,
              35.20984
            ],
            [
              -89.637778,
              35.277086
            ],
            [
              -89.632776,
              35.375824
            ],
            [
              -89.644985,
              35.371211
            ],
            [
              -89.64174,
              35.386339
            ],
            [
              -89.702893,
              35.389059
            ],
            [
              -89.70248,
              35.408584
            ],
            [
              -89.730814,
              35.401111
            ],
            [
              -89.776736,
              35.405173
            ],
            [
              -89.778755,
              35.38782
            ],
            [
              -89.800443,
              35.391313
            ],
            [
              -89.865515,
              35.39077
            ],
            [
              -89.883987,
              35.391185
            ],
            [
              -89.889317,
              35.390906
            ],
            [
              -90.041563,
              35.39662
            ],
            [
              -90.05199406979949,
              35.4080350901095
            ],
            [
              -90.056644,
              35.403786
            ],
            [
              -90.054322,
              35.389277
            ],
            [
              -90.061788,
              35.386184
            ],
            [
              -90.0667,
              35.385167
            ],
            [
              -90.074992,
              35.384152
            ],
            [
              -90.083824,
              35.368798
            ],
            [
              -90.087903,
              35.36327
            ],
            [
              -90.090492,
              35.360886
            ],
            [
              -90.093492,
              35.360486
            ],
            [
              -90.096825,
              35.357216
            ],
            [
              -90.100294,
              35.351619
            ],
            [
              -90.107312,
              35.343143
            ],
            [
              -90.108817,
              35.342587
            ],
            [
              -90.110293,
              35.342786
            ],
            [
              -90.103862,
              35.332405
            ],
            [
              -90.106824,
              35.324618
            ],
            [
              -90.109093,
              35.304987
            ],
            [
              -90.117219,
              35.303384
            ],
            [
              -90.121864,
              35.304535
            ],
            [
              -90.123707,
              35.30453
            ],
            [
              -90.132393,
              35.300488
            ],
            [
              -90.139504,
              35.298828
            ],
            [
              -90.149794,
              35.303288
            ],
            [
              -90.153394,
              35.302588
            ],
            [
              -90.158913,
              35.300637
            ],
            [
              -90.161225,
              35.298951
            ],
            [
              -90.163812,
              35.296115
            ],
            [
              -90.165194,
              35.293188
            ],
            [
              -90.168871,
              35.281997
            ],
            [
              -90.168794,
              35.279088
            ],
            [
              -90.166594,
              35.274588
            ],
            [
              -90.158865,
              35.262577
            ],
            [
              -90.152094,
              35.255989
            ],
            [
              -90.140394,
              35.252289
            ],
            [
              -90.132116,
              35.25318
            ],
            [
              -90.123593,
              35.254989
            ],
            [
              -90.116493,
              35.255788
            ],
            [
              -90.110106,
              35.255456
            ],
            [
              -90.105093,
              35.254288
            ],
            [
              -90.09949,
              35.251393
            ],
            [
              -90.097947,
              35.249983
            ],
            [
              -90.090892,
              35.242189
            ],
            [
              -90.086322,
              35.235719
            ],
            [
              -90.082939,
              35.231824
            ],
            [
              -90.07875,
              35.227806
            ],
            [
              -90.076879,
              35.224405
            ],
            [
              -90.07492,
              35.220452
            ],
            [
              -90.074155,
              35.21707
            ],
            [
              -90.074271,
              35.211504
            ],
            [
              -90.07682,
              35.208817
            ],
            [
              -90.081173,
              35.208153
            ],
            [
              -90.08412,
              35.210423
            ],
            [
              -90.088597,
              35.212376
            ],
            [
              -90.093285,
              35.203282
            ],
            [
              -90.096466,
              35.194848
            ],
            [
              -90.097408,
              35.194794
            ],
            [
              -90.109076,
              35.199105
            ],
            [
              -90.116182,
              35.198498
            ],
            [
              -90.117542,
              35.19057
            ],
            [
              -90.117393,
              35.18789
            ],
            [
              -90.114214,
              35.181691
            ],
            [
              -90.111091,
              35.178639
            ],
            [
              -90.109177,
              35.178451
            ],
            [
              -90.108075,
              35.174571
            ],
            [
              -90.105525,
              35.170695
            ],
            [
              -90.103216,
              35.16798
            ],
            [
              -90.099777,
              35.164474
            ],
            [
              -90.096549,
              35.160976
            ],
            [
              -90.092944,
              35.157228
            ],
            [
              -90.090371,
              35.15627
            ],
            [
              -90.069402,
              35.153646
            ],
            [
              -90.066958,
              35.151839
            ],
            [
              -90.066482,
              35.151074
            ],
            [
              -90.064612,
              35.140621
            ],
            [
              -90.065392,
              35.137691
            ],
            [
              -90.066591,
              35.13599
            ],
            [
              -90.071192,
              35.131591
            ],
            [
              -90.08342,
              35.12167
            ],
            [
              -90.08671,
              35.119779
            ],
            [
              -90.09061,
              35.118287
            ],
            [
              -90.100593,
              35.116691
            ],
            [
              -90.109393,
              35.118891
            ],
            [
              -90.139024,
              35.133995
            ],
            [
              -90.142794,
              35.135091
            ],
            [
              -90.144691,
              35.134984
            ],
            [
              -90.155994,
              35.130991
            ],
            [
              -90.160058,
              35.12883
            ],
            [
              -90.165328,
              35.125228
            ],
            [
              -90.17312153527709,
              35.118489299709005
            ],
            [
              -90.173603,
              35.118073
            ],
            [
              -90.176843,
              35.112088
            ],
            [
              -90.181387,
              35.091401
            ],
            [
              -90.195133,
              35.061793
            ],
            [
              -90.196583,
              35.056137
            ],
            [
              -90.197146,
              35.050731
            ],
            [
              -90.19686,
              35.043667
            ],
            [
              -90.196095,
              35.0374
            ],
            [
              -90.200124,
              35.032813
            ],
            [
              -90.209397,
              35.026546
            ],
            [
              -90.214382,
              35.025795
            ],
            [
              -90.216258,
              35.026049
            ],
            [
              -90.224791,
              35.029961
            ],
            [
              -90.230611,
              35.03132
            ],
            [
              -90.256495,
              35.034493
            ],
            [
              -90.262396,
              35.036393
            ],
            [
              -90.263396,
              35.037493
            ],
            [
              -90.263796,
              35.039593
            ],
            [
              -90.265296,
              35.040293
            ],
            [
              -90.291996,
              35.041793
            ],
            [
              -90.29446489589901,
              35.0406271324921
            ],
            [
              -90.295596,
              35.040093
            ],
            [
              -90.297296,
              35.037893
            ],
            [
              -90.300697,
              35.028793
            ],
            [
              -90.306897,
              35.018194
            ],
            [
              -90.309877,
              35.00975
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "083",
      "COUNTYNS": "01648578",
      "AFFGEOID": "0500000US47083",
      "GEOID": "47083",
      "NAME": "Houston",
      "LSAD": "06",
      "ALAND": 518749021,
      "AWATER": 17312576,
      "County_state": "Houston,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.98074,
              36.353307
            ],
            [
              -87.955844,
              36.312223
            ],
            [
              -87.944295,
              36.264177
            ],
            [
              -87.949603,
              36.24316
            ],
            [
              -87.834641,
              36.232368
            ],
            [
              -87.798508,
              36.244754
            ],
            [
              -87.758,
              36.241522
            ],
            [
              -87.730969,
              36.235437
            ],
            [
              -87.697583,
              36.222763
            ],
            [
              -87.662191,
              36.201227
            ],
            [
              -87.585973,
              36.197699
            ],
            [
              -87.578359,
              36.183077
            ],
            [
              -87.566798,
              36.177857
            ],
            [
              -87.523627,
              36.24889
            ],
            [
              -87.513533,
              36.334713
            ],
            [
              -87.530815,
              36.33636
            ],
            [
              -87.586957,
              36.34178
            ],
            [
              -87.592358,
              36.367663
            ],
            [
              -87.715494,
              36.36824
            ],
            [
              -87.722804,
              36.360361
            ],
            [
              -87.756217,
              36.342993
            ],
            [
              -87.784438,
              36.334124
            ],
            [
              -87.821816,
              36.328724
            ],
            [
              -87.861571,
              36.329875
            ],
            [
              -87.892839,
              36.334712
            ],
            [
              -87.944984,
              36.354128
            ],
            [
              -87.98074,
              36.353307
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "091",
      "COUNTYNS": "01639760",
      "AFFGEOID": "0500000US47091",
      "GEOID": "47091",
      "NAME": "Johnson",
      "LSAD": "06",
      "ALAND": 772960730,
      "AWATER": 11007740,
      "County_state": "Johnson,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.058946,
              36.367415
            ],
            [
              -82.042145,
              36.362816
            ],
            [
              -82.030543,
              36.333817
            ],
            [
              -82.002142,
              36.333419
            ],
            [
              -81.990228,
              36.315396
            ],
            [
              -81.982535,
              36.288073
            ],
            [
              -81.9515,
              36.261763
            ],
            [
              -81.933019683861,
              36.264842650423404
            ],
            [
              -81.932994,
              36.264881
            ],
            [
              -81.9181112586581,
              36.287113206280196
            ],
            [
              -81.908137,
              36.302013
            ],
            [
              -81.897701,
              36.307446
            ],
            [
              -81.894569,
              36.307183
            ],
            [
              -81.887243,
              36.309193
            ],
            [
              -81.879382,
              36.313767
            ],
            [
              -81.876182,
              36.316075
            ],
            [
              -81.874336,
              36.31919
            ],
            [
              -81.863148,
              36.330209
            ],
            [
              -81.857333,
              36.334787
            ],
            [
              -81.850889,
              36.3375
            ],
            [
              -81.845638,
              36.34036
            ],
            [
              -81.841268,
              36.343321
            ],
            [
              -81.833202,
              36.347339
            ],
            [
              -81.822493,
              36.348819
            ],
            [
              -81.812904,
              36.351066
            ],
            [
              -81.808255,
              36.354121
            ],
            [
              -81.800812,
              36.358073
            ],
            [
              -81.795269,
              36.357849
            ],
            [
              -81.791877,
              36.354797
            ],
            [
              -81.790181,
              36.351744
            ],
            [
              -81.787468,
              36.348692
            ],
            [
              -81.784077,
              36.347674
            ],
            [
              -81.781318,
              36.347656
            ],
            [
              -81.777972,
              36.346318
            ],
            [
              -81.768977,
              36.341042
            ],
            [
              -81.766102,
              36.338517
            ],
            [
              -81.762371,
              36.338856
            ],
            [
              -81.760675,
              36.338178
            ],
            [
              -81.757962,
              36.3375
            ],
            [
              -81.75442,
              36.337044
            ],
            [
              -81.747842,
              36.337356
            ],
            [
              -81.744461,
              36.337778
            ],
            [
              -81.739498,
              36.339757
            ],
            [
              -81.7349,
              36.340891
            ],
            [
              -81.730976,
              36.341187
            ],
            [
              -81.725938,
              36.340364
            ],
            [
              -81.721015,
              36.338645
            ],
            [
              -81.717186,
              36.336169
            ],
            [
              -81.713194,
              36.334108
            ],
            [
              -81.707438,
              36.335171
            ],
            [
              -81.705966,
              36.338496
            ],
            [
              -81.705299,
              36.341852
            ],
            [
              -81.707785,
              36.346007
            ],
            [
              -81.713873,
              36.34937
            ],
            [
              -81.718282,
              36.350388
            ],
            [
              -81.721334,
              36.353101
            ],
            [
              -81.722691,
              36.354797
            ],
            [
              -81.723708,
              36.358527
            ],
            [
              -81.724047,
              36.360901
            ],
            [
              -81.724047,
              36.364293
            ],
            [
              -81.726082,
              36.368893
            ],
            [
              -81.731178,
              36.374062
            ],
            [
              -81.732865,
              36.376502
            ],
            [
              -81.732187,
              36.379894
            ],
            [
              -81.730737,
              36.382943
            ],
            [
              -81.729813,
              36.388033
            ],
            [
              -81.730491,
              36.390407
            ],
            [
              -81.731509,
              36.392103
            ],
            [
              -81.733877,
              36.39457
            ],
            [
              -81.737952,
              36.39719
            ],
            [
              -81.739648,
              36.401599
            ],
            [
              -81.739648,
              36.406686
            ],
            [
              -81.738292,
              36.410756
            ],
            [
              -81.734312,
              36.413342
            ],
            [
              -81.729924,
              36.415422
            ],
            [
              -81.720734,
              36.422537
            ],
            [
              -81.717939,
              36.428762
            ],
            [
              -81.716925,
              36.43314
            ],
            [
              -81.715229,
              36.436532
            ],
            [
              -81.715229,
              36.438567
            ],
            [
              -81.715569,
              36.44128
            ],
            [
              -81.715229,
              36.444332
            ],
            [
              -81.714212,
              36.447045
            ],
            [
              -81.714277,
              36.450978
            ],
            [
              -81.715082,
              36.453365
            ],
            [
              -81.71489,
              36.45722
            ],
            [
              -81.708247,
              36.462217
            ],
            [
              -81.701076,
              36.464212
            ],
            [
              -81.699223,
              36.463959
            ],
            [
              -81.697975,
              36.464741
            ],
            [
              -81.695311,
              36.467912
            ],
            [
              -81.694533,
              36.473283
            ],
            [
              -81.694829,
              36.474463
            ],
            [
              -81.696281,
              36.475499
            ],
            [
              -81.697287,
              36.484738
            ],
            [
              -81.695907,
              36.49158
            ],
            [
              -81.696835,
              36.493393
            ],
            [
              -81.697261,
              36.496141
            ],
            [
              -81.698265,
              36.497221
            ],
            [
              -81.699928,
              36.498018
            ],
            [
              -81.700238,
              36.500475
            ],
            [
              -81.699923,
              36.500865
            ],
            [
              -81.69797,
              36.504063
            ],
            [
              -81.69729,
              36.504887
            ],
            [
              -81.697829,
              36.507544
            ],
            [
              -81.697744,
              36.508448
            ],
            [
              -81.699446,
              36.511504
            ],
            [
              -81.699601,
              36.512883
            ],
            [
              -81.700093,
              36.514158
            ],
            [
              -81.700553,
              36.51519
            ],
            [
              -81.702543,
              36.520317
            ],
            [
              -81.707573,
              36.526101
            ],
            [
              -81.708262,
              36.532113
            ],
            [
              -81.707963,
              36.536209
            ],
            [
              -81.699962,
              36.536829
            ],
            [
              -81.699962,
              36.539714
            ],
            [
              -81.697539,
              36.544359
            ],
            [
              -81.693844,
              36.549083
            ],
            [
              -81.69003,
              36.552154
            ],
            [
              -81.689115,
              36.555912
            ],
            [
              -81.690132,
              36.559643
            ],
            [
              -81.692167,
              36.562695
            ],
            [
              -81.692506,
              36.565748
            ],
            [
              -81.690236,
              36.568718
            ],
            [
              -81.686436,
              36.567918
            ],
            [
              -81.679936,
              36.568618
            ],
            [
              -81.677036,
              36.570718
            ],
            [
              -81.677236,
              36.574406
            ],
            [
              -81.679036,
              36.578918
            ],
            [
              -81.680137,
              36.585518
            ],
            [
              -81.677535,
              36.588117
            ],
            [
              -81.6469,
              36.611918
            ],
            [
              -81.73694,
              36.612379
            ],
            [
              -81.826742,
              36.614215
            ],
            [
              -81.871443,
              36.602015
            ],
            [
              -81.875067,
              36.588258
            ],
            [
              -81.928044,
              36.567914
            ],
            [
              -81.931743,
              36.552214
            ],
            [
              -81.964945,
              36.531517
            ],
            [
              -81.983843,
              36.508314
            ],
            [
              -81.94984,
              36.472385
            ],
            [
              -81.965978,
              36.462947
            ],
            [
              -82.032544,
              36.389416
            ],
            [
              -82.058946,
              36.367415
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "121",
      "COUNTYNS": "01639775",
      "AFFGEOID": "0500000US47121",
      "GEOID": "47121",
      "NAME": "Meigs",
      "LSAD": "06",
      "ALAND": 505397665,
      "AWATER": 55976974,
      "County_state": "Meigs,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.016343,
              35.409288
            ],
            [
              -85.031314,
              35.379477
            ],
            [
              -85.008366,
              35.383255
            ],
            [
              -84.97985,
              35.36277
            ],
            [
              -84.964078,
              35.310315
            ],
            [
              -84.946339,
              35.287717
            ],
            [
              -84.927446,
              35.288869
            ],
            [
              -84.884046,
              35.329172
            ],
            [
              -84.877546,
              35.357871
            ],
            [
              -84.860164,
              35.350074
            ],
            [
              -84.850116,
              35.373469
            ],
            [
              -84.814543,
              35.43697
            ],
            [
              -84.803243,
              35.43657
            ],
            [
              -84.801147,
              35.455181
            ],
            [
              -84.777543,
              35.465669
            ],
            [
              -84.747173,
              35.510783
            ],
            [
              -84.724634,
              35.521551
            ],
            [
              -84.692215,
              35.561832
            ],
            [
              -84.689814,
              35.577603
            ],
            [
              -84.666535,
              35.589289
            ],
            [
              -84.641858,
              35.626175
            ],
            [
              -84.619865,
              35.644651
            ],
            [
              -84.628038,
              35.662249
            ],
            [
              -84.628061,
              35.690117
            ],
            [
              -84.619031,
              35.70788
            ],
            [
              -84.636309,
              35.72429
            ],
            [
              -84.672223,
              35.702943
            ],
            [
              -84.67654,
              35.717501
            ],
            [
              -84.69873,
              35.717869
            ],
            [
              -84.698416,
              35.741814
            ],
            [
              -84.713986,
              35.741986
            ],
            [
              -84.721557,
              35.752235
            ],
            [
              -84.723853,
              35.752896
            ],
            [
              -84.711872,
              35.725029
            ],
            [
              -84.723122,
              35.706924
            ],
            [
              -84.72324,
              35.68967
            ],
            [
              -84.74056,
              35.679471
            ],
            [
              -84.75162,
              35.680516
            ],
            [
              -84.753598,
              35.698914
            ],
            [
              -84.766321,
              35.700056
            ],
            [
              -84.781804,
              35.673908
            ],
            [
              -84.778299,
              35.644602
            ],
            [
              -84.786305,
              35.632767
            ],
            [
              -84.777791,
              35.613241
            ],
            [
              -84.782366,
              35.594799
            ],
            [
              -84.80989,
              35.582465
            ],
            [
              -84.808085,
              35.56883
            ],
            [
              -84.794904,
              35.55255
            ],
            [
              -84.815513,
              35.54539
            ],
            [
              -84.855882,
              35.554109
            ],
            [
              -84.873969,
              35.550217
            ],
            [
              -84.880439,
              35.539201
            ],
            [
              -84.877373,
              35.525078
            ],
            [
              -84.862648,
              35.515272
            ],
            [
              -84.859949,
              35.497072
            ],
            [
              -84.874347,
              35.487971
            ],
            [
              -84.895348,
              35.48297
            ],
            [
              -84.904448,
              35.47207
            ],
            [
              -84.903648,
              35.455471
            ],
            [
              -84.912148,
              35.442671
            ],
            [
              -84.929549,
              35.43707
            ],
            [
              -84.94585,
              35.43967
            ],
            [
              -84.962651,
              35.45267
            ],
            [
              -84.968953,
              35.466674
            ],
            [
              -84.980253,
              35.469868
            ],
            [
              -84.984554,
              35.466073
            ],
            [
              -84.980051,
              35.43927
            ],
            [
              -85.016343,
              35.409288
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "029",
      "COUNTYNS": "01639734",
      "AFFGEOID": "0500000US47029",
      "GEOID": "47029",
      "NAME": "Cocke",
      "LSAD": "06",
      "ALAND": 1125399301,
      "AWATER": 22117950,
      "County_state": "Cocke,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.310782,
              35.895846
            ],
            [
              -83.308735,
              35.871692
            ],
            [
              -83.263003,
              35.820005
            ],
            [
              -83.287421,
              35.795423
            ],
            [
              -83.285769,
              35.780445
            ],
            [
              -83.275055,
              35.769644
            ],
            [
              -83.280778,
              35.750095
            ],
            [
              -83.276413,
              35.713591
            ],
            [
              -83.255476179897,
              35.7150906815168
            ],
            [
              -83.255351,
              35.71623
            ],
            [
              -83.251247,
              35.719916
            ],
            [
              -83.243501,
              35.722533
            ],
            [
              -83.242132,
              35.723638
            ],
            [
              -83.240669,
              35.72676
            ],
            [
              -83.232042,
              35.726098
            ],
            [
              -83.222627,
              35.726138
            ],
            [
              -83.219981,
              35.726601
            ],
            [
              -83.216972,
              35.725752
            ],
            [
              -83.214501,
              35.724434
            ],
            [
              -83.203752,
              35.726553
            ],
            [
              -83.200126,
              35.725331
            ],
            [
              -83.198267,
              35.725494
            ],
            [
              -83.18837,
              35.729798
            ],
            [
              -83.185685,
              35.72989
            ],
            [
              -83.182097,
              35.735492
            ],
            [
              -83.180836,
              35.738882
            ],
            [
              -83.177499,
              35.743913
            ],
            [
              -83.171867,
              35.745978
            ],
            [
              -83.170173,
              35.746107
            ],
            [
              -83.16477,
              35.754618
            ],
            [
              -83.165427,
              35.7587
            ],
            [
              -83.164909,
              35.759965
            ],
            [
              -83.161537,
              35.763363
            ],
            [
              -83.159208,
              35.764892
            ],
            [
              -83.15408,
              35.76428
            ],
            [
              -83.14808,
              35.764295
            ],
            [
              -83.14697,
              35.765124
            ],
            [
              -83.127707,
              35.768093
            ],
            [
              -83.120183,
              35.766234
            ],
            [
              -83.113662,
              35.770211
            ],
            [
              -83.110491,
              35.770913
            ],
            [
              -83.104805,
              35.77348
            ],
            [
              -83.104584,
              35.77423
            ],
            [
              -83.10232,
              35.775071
            ],
            [
              -83.100233,
              35.774745
            ],
            [
              -83.100329,
              35.774804
            ],
            [
              -83.100225,
              35.774765
            ],
            [
              -83.097193,
              35.776067
            ],
            [
              -83.086054,
              35.783627
            ],
            [
              -83.085205,
              35.785794
            ],
            [
              -83.078732,
              35.789472
            ],
            [
              -83.07403,
              35.790016
            ],
            [
              -83.072221,
              35.78831
            ],
            [
              -83.063975,
              35.786643
            ],
            [
              -83.061507,
              35.786774
            ],
            [
              -83.05834,
              35.788241
            ],
            [
              -83.052677,
              35.789548
            ],
            [
              -83.04853,
              35.787706
            ],
            [
              -83.046307,
              35.785853
            ],
            [
              -83.044108,
              35.785347
            ],
            [
              -83.042666,
              35.785407
            ],
            [
              -83.03951,
              35.786777
            ],
            [
              -83.036209,
              35.787405
            ],
            [
              -83.012377,
              35.779818
            ],
            [
              -83.006067,
              35.778404
            ],
            [
              -83.001473,
              35.773752
            ],
            [
              -82.995803,
              35.773128
            ],
            [
              -82.992053,
              35.773948
            ],
            [
              -82.98397,
              35.77801
            ],
            [
              -82.978414,
              35.78261
            ],
            [
              -82.974463,
              35.78679
            ],
            [
              -82.969648,
              35.789663
            ],
            [
              -82.964088,
              35.78998
            ],
            [
              -82.9628979027128,
              35.791734792758696
            ],
            [
              -82.962206,
              35.792755
            ],
            [
              -82.962842,
              35.795126
            ],
            [
              -82.961724,
              35.800491
            ],
            [
              -82.95895,
              35.803323
            ],
            [
              -82.956127,
              35.807874
            ],
            [
              -82.955751,
              35.809802
            ],
            [
              -82.952026,
              35.816183
            ],
            [
              -82.945515,
              35.824662
            ],
            [
              -82.94383,
              35.825638
            ],
            [
              -82.937437,
              35.82732
            ],
            [
              -82.933221,
              35.832915
            ],
            [
              -82.931859,
              35.836351
            ],
            [
              -82.927569,
              35.838586
            ],
            [
              -82.923358,
              35.839273
            ],
            [
              -82.920171,
              35.841664
            ],
            [
              -82.919108,
              35.844851
            ],
            [
              -82.920974,
              35.851073
            ],
            [
              -82.919374,
              35.860523
            ],
            [
              -82.918312,
              35.863977
            ],
            [
              -82.916452,
              35.866102
            ],
            [
              -82.901301,
              35.872593
            ],
            [
              -82.899718,
              35.874602
            ],
            [
              -82.899186,
              35.877524
            ],
            [
              -82.899718,
              35.879914
            ],
            [
              -82.901046,
              35.882305
            ],
            [
              -82.903968,
              35.885492
            ],
            [
              -82.903702,
              35.887617
            ],
            [
              -82.901843,
              35.890274
            ],
            [
              -82.901843,
              35.89293
            ],
            [
              -82.904543,
              35.897011
            ],
            [
              -82.906917,
              35.907397
            ],
            [
              -82.911671,
              35.914711
            ],
            [
              -82.911936,
              35.921618
            ],
            [
              -82.911405,
              35.925868
            ],
            [
              -82.910608,
              35.92693
            ],
            [
              -82.906358,
              35.927196
            ],
            [
              -82.903436,
              35.928524
            ],
            [
              -82.902374,
              35.929852
            ],
            [
              -82.901577,
              35.931446
            ],
            [
              -82.901713,
              35.937713
            ],
            [
              -82.898505,
              35.945101
            ],
            [
              -82.8984628586303,
              35.945088097924696
            ],
            [
              -82.957228,
              36.0001
            ],
            [
              -83.007275,
              36.0398
            ],
            [
              -83.052177,
              36.077899
            ],
            [
              -83.055678,
              36.090898
            ],
            [
              -83.073809,
              36.094416
            ],
            [
              -83.102761,
              36.120909
            ],
            [
              -83.118333,
              36.112022
            ],
            [
              -83.116046,
              36.121756
            ],
            [
              -83.139683,
              36.129196
            ],
            [
              -83.152484,
              36.144996
            ],
            [
              -83.172485,
              36.157095
            ],
            [
              -83.166085,
              36.180894
            ],
            [
              -83.185285,
              36.176795
            ],
            [
              -83.175098,
              36.127357
            ],
            [
              -83.195281,
              36.128892
            ],
            [
              -83.217194,
              36.150196
            ],
            [
              -83.224894,
              36.149093
            ],
            [
              -83.219686,
              36.127494
            ],
            [
              -83.245787,
              36.124194
            ],
            [
              -83.226887,
              36.092501
            ],
            [
              -83.234585,
              36.085094
            ],
            [
              -83.253886,
              36.082194
            ],
            [
              -83.240085,
              36.057295
            ],
            [
              -83.256686,
              36.044321
            ],
            [
              -83.262056,
              35.997425
            ],
            [
              -83.276677,
              36.007652
            ],
            [
              -83.297853,
              35.901516
            ],
            [
              -83.310782,
              35.895846
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "043",
      "COUNTYNS": "01639740",
      "AFFGEOID": "0500000US47043",
      "GEOID": "47043",
      "NAME": "Dickson",
      "LSAD": "06",
      "ALAND": 1268824251,
      "AWATER": 3689043,
      "County_state": "Dickson,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.566798,
              36.177857
            ],
            [
              -87.552182,
              36.137745
            ],
            [
              -87.544503,
              36.094277
            ],
            [
              -87.545298,
              36.046641
            ],
            [
              -87.552874,
              36.016237
            ],
            [
              -87.53453,
              35.993074
            ],
            [
              -87.48969,
              35.983865
            ],
            [
              -87.445527,
              35.983697
            ],
            [
              -87.301916,
              35.96837
            ],
            [
              -87.279901,
              35.961429
            ],
            [
              -87.274235,
              35.964595
            ],
            [
              -87.204242,
              35.959186
            ],
            [
              -87.21195,
              35.969939
            ],
            [
              -87.197406,
              35.979967
            ],
            [
              -87.182573,
              36.049726
            ],
            [
              -87.173127,
              36.070885
            ],
            [
              -87.172799,
              36.109533
            ],
            [
              -87.165776,
              36.154736
            ],
            [
              -87.170827,
              36.165953
            ],
            [
              -87.149876,
              36.176878
            ],
            [
              -87.170027,
              36.201164
            ],
            [
              -87.187968,
              36.211783
            ],
            [
              -87.178306,
              36.228262
            ],
            [
              -87.164932,
              36.235503
            ],
            [
              -87.174046,
              36.247679
            ],
            [
              -87.186142,
              36.242074
            ],
            [
              -87.193301,
              36.248969
            ],
            [
              -87.181806,
              36.259763
            ],
            [
              -87.156439,
              36.271577
            ],
            [
              -87.153043,
              36.30535
            ],
            [
              -87.177303,
              36.314145
            ],
            [
              -87.216732,
              36.315886
            ],
            [
              -87.23304,
              36.331335
            ],
            [
              -87.242495,
              36.32337
            ],
            [
              -87.266522,
              36.320765
            ],
            [
              -87.286501,
              36.321933
            ],
            [
              -87.303129,
              36.312442
            ],
            [
              -87.513533,
              36.334713
            ],
            [
              -87.523627,
              36.24889
            ],
            [
              -87.566798,
              36.177857
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "057",
      "COUNTYNS": "01648575",
      "AFFGEOID": "0500000US47057",
      "GEOID": "47057",
      "NAME": "Grainger",
      "LSAD": "06",
      "ALAND": 726715762,
      "AWATER": 56600343,
      "County_state": "Grainger,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.732704,
              36.164583
            ],
            [
              -83.725103,
              36.142383
            ],
            [
              -83.699405,
              36.109469
            ],
            [
              -83.687167,
              36.111815
            ],
            [
              -83.66746,
              36.080587
            ],
            [
              -83.659387,
              36.085533
            ],
            [
              -83.674454,
              36.116399
            ],
            [
              -83.674237,
              36.125008
            ],
            [
              -83.651522,
              36.125151
            ],
            [
              -83.654559,
              36.105672
            ],
            [
              -83.641323,
              36.09707
            ],
            [
              -83.62892,
              36.104532
            ],
            [
              -83.627722,
              36.122976
            ],
            [
              -83.638938,
              36.128592
            ],
            [
              -83.637201,
              36.144385
            ],
            [
              -83.604223,
              36.138299
            ],
            [
              -83.597766,
              36.164182
            ],
            [
              -83.588666,
              36.154942
            ],
            [
              -83.582978,
              36.16622
            ],
            [
              -83.564399,
              36.166486
            ],
            [
              -83.5673,
              36.190086
            ],
            [
              -83.5591,
              36.180486
            ],
            [
              -83.541099,
              36.181987
            ],
            [
              -83.528698,
              36.162487
            ],
            [
              -83.515524,
              36.178053
            ],
            [
              -83.490697,
              36.166588
            ],
            [
              -83.483497,
              36.167088
            ],
            [
              -83.467097,
              36.174688
            ],
            [
              -83.465897,
              36.188888
            ],
            [
              -83.449097,
              36.190289
            ],
            [
              -83.449498,
              36.217989
            ],
            [
              -83.426597,
              36.211489
            ],
            [
              -83.416096,
              36.219389
            ],
            [
              -83.419897,
              36.236289
            ],
            [
              -83.387697,
              36.264589
            ],
            [
              -83.362196,
              36.26769
            ],
            [
              -83.362996,
              36.27899
            ],
            [
              -83.333495,
              36.28409
            ],
            [
              -83.328295,
              36.29669
            ],
            [
              -83.30507,
              36.268186
            ],
            [
              -83.29447,
              36.278097
            ],
            [
              -83.290157,
              36.262692
            ],
            [
              -83.27826,
              36.264497
            ],
            [
              -83.278566,
              36.276088
            ],
            [
              -83.267073,
              36.274296
            ],
            [
              -83.267281,
              36.285087
            ],
            [
              -83.255292,
              36.289092
            ],
            [
              -83.27369,
              36.319086
            ],
            [
              -83.264499,
              36.345267
            ],
            [
              -83.273295,
              36.37019
            ],
            [
              -83.288896,
              36.378789
            ],
            [
              -83.280595,
              36.394689
            ],
            [
              -83.304697,
              36.388289
            ],
            [
              -83.308597,
              36.399589
            ],
            [
              -83.340998,
              36.408388
            ],
            [
              -83.352099,
              36.419787
            ],
            [
              -83.389443,
              36.408162
            ],
            [
              -83.3868,
              36.413186
            ],
            [
              -83.414101,
              36.420686
            ],
            [
              -83.417073,
              36.411362
            ],
            [
              -83.439113,
              36.412854
            ],
            [
              -83.449481,
              36.387719
            ],
            [
              -83.460002,
              36.389186
            ],
            [
              -83.449201,
              36.374286
            ],
            [
              -83.466002,
              36.369186
            ],
            [
              -83.482603,
              36.379586
            ],
            [
              -83.497003,
              36.378885
            ],
            [
              -83.496303,
              36.362186
            ],
            [
              -83.506503,
              36.372485
            ],
            [
              -83.517805,
              36.357381
            ],
            [
              -83.531004,
              36.366185
            ],
            [
              -83.532504,
              36.353385
            ],
            [
              -83.550905,
              36.368284
            ],
            [
              -83.558405,
              36.363484
            ],
            [
              -83.594206,
              36.367483
            ],
            [
              -83.597306,
              36.357183
            ],
            [
              -83.614806,
              36.345583
            ],
            [
              -83.661107,
              36.332482
            ],
            [
              -83.667408,
              36.344381
            ],
            [
              -83.689107,
              36.295981
            ],
            [
              -83.689507,
              36.279082
            ],
            [
              -83.702707,
              36.273182
            ],
            [
              -83.704507,
              36.250182
            ],
            [
              -83.695506,
              36.254382
            ],
            [
              -83.693506,
              36.237483
            ],
            [
              -83.700205,
              36.207583
            ],
            [
              -83.716905,
              36.199783
            ],
            [
              -83.728405,
              36.182083
            ],
            [
              -83.714704,
              36.173783
            ],
            [
              -83.732704,
              36.164583
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "073",
      "COUNTYNS": "01639752",
      "AFFGEOID": "0500000US47073",
      "GEOID": "47073",
      "NAME": "Hawkins",
      "LSAD": "06",
      "ALAND": 1261465560,
      "AWATER": 32543978,
      "County_state": "Hawkins,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.280595,
              36.394689
            ],
            [
              -83.288896,
              36.378789
            ],
            [
              -83.273295,
              36.37019
            ],
            [
              -83.264499,
              36.345267
            ],
            [
              -83.27369,
              36.319086
            ],
            [
              -83.255292,
              36.289092
            ],
            [
              -83.233291,
              36.281892
            ],
            [
              -83.239592,
              36.293492
            ],
            [
              -83.215591,
              36.293492
            ],
            [
              -83.200291,
              36.306993
            ],
            [
              -83.196691,
              36.322192
            ],
            [
              -83.18079,
              36.312093
            ],
            [
              -83.182391,
              36.332692
            ],
            [
              -83.16259,
              36.345292
            ],
            [
              -83.159889,
              36.332893
            ],
            [
              -83.13433,
              36.296134
            ],
            [
              -83.109468,
              36.282398
            ],
            [
              -83.102884,
              36.266021
            ],
            [
              -83.082312,
              36.242296
            ],
            [
              -83.073679,
              36.241932
            ],
            [
              -83.031705,
              36.276696
            ],
            [
              -83.021483,
              36.269697
            ],
            [
              -82.991883,
              36.288397
            ],
            [
              -82.967583,
              36.316397
            ],
            [
              -82.937183,
              36.334797
            ],
            [
              -82.915482,
              36.328698
            ],
            [
              -82.885082,
              36.353398
            ],
            [
              -82.839581,
              36.370398
            ],
            [
              -82.814981,
              36.363899
            ],
            [
              -82.779718,
              36.388243
            ],
            [
              -82.75148,
              36.3858
            ],
            [
              -82.726879,
              36.4045
            ],
            [
              -82.702566,
              36.410412
            ],
            [
              -82.676177,
              36.4234
            ],
            [
              -82.680677,
              36.4318
            ],
            [
              -82.702279,
              36.455499
            ],
            [
              -82.626776,
              36.4954
            ],
            [
              -82.627676,
              36.513599
            ],
            [
              -82.610175,
              36.5193
            ],
            [
              -82.611985,
              36.545532
            ],
            [
              -82.616377,
              36.579599
            ],
            [
              -82.599989,
              36.577293
            ],
            [
              -82.609176,
              36.594099
            ],
            [
              -82.679879,
              36.593698
            ],
            [
              -82.69578,
              36.593698
            ],
            [
              -82.830433,
              36.593761
            ],
            [
              -82.884029,
              36.573171
            ],
            [
              -82.950519,
              36.543005
            ],
            [
              -82.960772,
              36.555155
            ],
            [
              -82.98388,
              36.544489
            ],
            [
              -82.99016,
              36.561895
            ],
            [
              -83.010789,
              36.563691
            ],
            [
              -83.040489,
              36.551891
            ],
            [
              -83.120966,
              36.513883
            ],
            [
              -83.134432,
              36.497301
            ],
            [
              -83.167194,
              36.47129
            ],
            [
              -83.162093,
              36.46159
            ],
            [
              -83.206937,
              36.441559
            ],
            [
              -83.193894,
              36.42429
            ],
            [
              -83.222794,
              36.41019
            ],
            [
              -83.280595,
              36.394689
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "077",
      "COUNTYNS": "01639754",
      "AFFGEOID": "0500000US47077",
      "GEOID": "47077",
      "NAME": "Henderson",
      "LSAD": "06",
      "ALAND": 1346816726,
      "AWATER": 15219341,
      "County_state": "Henderson,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.603575,
              35.626084
            ],
            [
              -88.613611,
              35.588089
            ],
            [
              -88.599581,
              35.591729
            ],
            [
              -88.56557,
              35.587088
            ],
            [
              -88.544759,
              35.549056
            ],
            [
              -88.529617,
              35.531835
            ],
            [
              -88.505354,
              35.51203
            ],
            [
              -88.469106,
              35.495882
            ],
            [
              -88.421288,
              35.489027
            ],
            [
              -88.414582,
              35.482339
            ],
            [
              -88.377898,
              35.492853
            ],
            [
              -88.364005,
              35.492801
            ],
            [
              -88.360829,
              35.418972
            ],
            [
              -88.246192,
              35.416307
            ],
            [
              -88.241677,
              35.423263
            ],
            [
              -88.243057,
              35.528858
            ],
            [
              -88.190465,
              35.609088
            ],
            [
              -88.179177,
              35.816785
            ],
            [
              -88.56955,
              35.824451
            ],
            [
              -88.599627,
              35.801785
            ],
            [
              -88.606862,
              35.789354
            ],
            [
              -88.610736,
              35.646424
            ],
            [
              -88.603575,
              35.626084
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "075",
      "COUNTYNS": "01639753",
      "AFFGEOID": "0500000US47075",
      "GEOID": "47075",
      "NAME": "Haywood",
      "LSAD": "06",
      "ALAND": 1380741549,
      "AWATER": 2472509,
      "County_state": "Haywood,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.482701,
              35.593953
            ],
            [
              -89.502118,
              35.580621
            ],
            [
              -89.477397,
              35.560751
            ],
            [
              -89.48319,
              35.54694
            ],
            [
              -89.469862,
              35.546566
            ],
            [
              -89.474171,
              35.403039
            ],
            [
              -89.440031,
              35.402166
            ],
            [
              -89.438639,
              35.401048
            ],
            [
              -89.183944,
              35.397126
            ],
            [
              -89.182551,
              35.432816
            ],
            [
              -89.078876,
              35.431428
            ],
            [
              -89.073463,
              35.572166
            ],
            [
              -89.069544,
              35.692679
            ],
            [
              -89.079584,
              35.697319
            ],
            [
              -89.117056,
              35.699271
            ],
            [
              -89.142252,
              35.719818
            ],
            [
              -89.171701,
              35.735714
            ],
            [
              -89.204432,
              35.747163
            ],
            [
              -89.271224,
              35.754922
            ],
            [
              -89.2862,
              35.772012
            ],
            [
              -89.309502,
              35.772923
            ],
            [
              -89.337575,
              35.791665
            ],
            [
              -89.350504,
              35.812094
            ],
            [
              -89.356033,
              35.817635
            ],
            [
              -89.401805,
              35.822657
            ],
            [
              -89.405412,
              35.808849
            ],
            [
              -89.419134,
              35.798987
            ],
            [
              -89.439691,
              35.765915
            ],
            [
              -89.439942,
              35.747626
            ],
            [
              -89.427469,
              35.747378
            ],
            [
              -89.432422,
              35.729877
            ],
            [
              -89.452712,
              35.682545
            ],
            [
              -89.466354,
              35.682706
            ],
            [
              -89.469993,
              35.595226
            ],
            [
              -89.482701,
              35.593953
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "167",
      "COUNTYNS": "01639795",
      "AFFGEOID": "0500000US47167",
      "GEOID": "47167",
      "NAME": "Tipton",
      "LSAD": "06",
      "ALAND": 1187267191,
      "AWATER": 38609225,
      "County_state": "Tipton,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.046598,
              35.412966
            ],
            [
              -90.05199406979949,
              35.4080350901095
            ],
            [
              -90.041563,
              35.39662
            ],
            [
              -89.889317,
              35.390906
            ],
            [
              -89.883987,
              35.391185
            ],
            [
              -89.865515,
              35.39077
            ],
            [
              -89.800443,
              35.391313
            ],
            [
              -89.778755,
              35.38782
            ],
            [
              -89.776736,
              35.405173
            ],
            [
              -89.730814,
              35.401111
            ],
            [
              -89.70248,
              35.408584
            ],
            [
              -89.702893,
              35.389059
            ],
            [
              -89.64174,
              35.386339
            ],
            [
              -89.644985,
              35.371211
            ],
            [
              -89.632776,
              35.375824
            ],
            [
              -89.628966,
              35.382599
            ],
            [
              -89.598603,
              35.394564
            ],
            [
              -89.540562,
              35.404771
            ],
            [
              -89.474171,
              35.403039
            ],
            [
              -89.469862,
              35.546566
            ],
            [
              -89.48319,
              35.54694
            ],
            [
              -89.477397,
              35.560751
            ],
            [
              -89.502118,
              35.580621
            ],
            [
              -89.512094,
              35.582532
            ],
            [
              -89.532108,
              35.602595
            ],
            [
              -89.55173,
              35.605952
            ],
            [
              -89.560592,
              35.613446
            ],
            [
              -89.583101,
              35.617913
            ],
            [
              -89.58791,
              35.625379
            ],
            [
              -89.615693,
              35.636872
            ],
            [
              -89.623458,
              35.652316
            ],
            [
              -89.631124,
              35.645012
            ],
            [
              -89.651056,
              35.648009
            ],
            [
              -89.650707,
              35.639473
            ],
            [
              -89.665938,
              35.631392
            ],
            [
              -89.671629,
              35.638757
            ],
            [
              -89.688961,
              35.636328
            ],
            [
              -89.692785,
              35.644305
            ],
            [
              -89.727414,
              35.63655
            ],
            [
              -89.728793,
              35.647079
            ],
            [
              -89.758116,
              35.650361
            ],
            [
              -89.759108,
              35.64256
            ],
            [
              -89.782022,
              35.634743
            ],
            [
              -89.793219,
              35.640109
            ],
            [
              -89.804497,
              35.622518
            ],
            [
              -89.826486,
              35.614379
            ],
            [
              -89.823484,
              35.603764
            ],
            [
              -89.837763,
              35.601363
            ],
            [
              -89.87212,
              35.581518
            ],
            [
              -89.878891,
              35.586534
            ],
            [
              -89.894393,
              35.550157
            ],
            [
              -89.9092060309267,
              35.538164136790705
            ],
            [
              -89.908826,
              35.538031
            ],
            [
              -89.905582,
              35.536774
            ],
            [
              -89.904392,
              35.535701
            ],
            [
              -89.903882,
              35.534175
            ],
            [
              -89.90766,
              35.522944
            ],
            [
              -89.909022,
              35.520548
            ],
            [
              -89.911931,
              35.51741
            ],
            [
              -89.9154,
              35.515119
            ],
            [
              -89.919331,
              35.51387
            ],
            [
              -89.923161,
              35.514428
            ],
            [
              -89.933614,
              35.518538
            ],
            [
              -89.945026,
              35.519388
            ],
            [
              -89.94801,
              35.52009
            ],
            [
              -89.951248,
              35.521866
            ],
            [
              -89.956347,
              35.525594
            ],
            [
              -89.957715,
              35.527192
            ],
            [
              -89.957739,
              35.530125
            ],
            [
              -89.95578,
              35.533214
            ],
            [
              -89.955641,
              35.534518
            ],
            [
              -89.956706,
              35.539369
            ],
            [
              -89.958498,
              35.541703
            ],
            [
              -89.97631,
              35.553872
            ],
            [
              -89.989363,
              35.560043
            ],
            [
              -89.992975,
              35.560774
            ],
            [
              -89.998996,
              35.56116
            ],
            [
              -90.008293,
              35.560065
            ],
            [
              -90.011262,
              35.559105
            ],
            [
              -90.017312,
              35.555996
            ],
            [
              -90.023903,
              35.556336
            ],
            [
              -90.02862,
              35.555249
            ],
            [
              -90.032938,
              35.55344
            ],
            [
              -90.037615,
              35.550329
            ],
            [
              -90.039744,
              35.548041
            ],
            [
              -90.041962,
              35.537468
            ],
            [
              -90.044748,
              35.531657
            ],
            [
              -90.046227,
              35.529592
            ],
            [
              -90.04909,
              35.522257
            ],
            [
              -90.050277,
              35.515275
            ],
            [
              -90.048519,
              35.504357
            ],
            [
              -90.045805,
              35.496533
            ],
            [
              -90.043517,
              35.492298
            ],
            [
              -90.034976,
              35.480705
            ],
            [
              -90.020386,
              35.470257
            ],
            [
              -90.018998,
              35.467803
            ],
            [
              -90.018842,
              35.464816
            ],
            [
              -90.022064,
              35.457375
            ],
            [
              -90.024247,
              35.45426
            ],
            [
              -90.026604,
              35.447788
            ],
            [
              -90.026899,
              35.444869
            ],
            [
              -90.026584,
              35.440103
            ],
            [
              -90.02737,
              35.43789
            ],
            [
              -90.02931,
              35.435245
            ],
            [
              -90.031267,
              35.431576
            ],
            [
              -90.031584,
              35.427662
            ],
            [
              -90.04057,
              35.422925
            ],
            [
              -90.04264,
              35.421237
            ],
            [
              -90.044216,
              35.419231
            ],
            [
              -90.045306,
              35.415435
            ],
            [
              -90.046598,
              35.412966
            ]
          ]
        ],
        [
          [
            [
              -90.179265,
              35.385194
            ],
            [
              -90.178341,
              35.382092
            ],
            [
              -90.172388,
              35.377681
            ],
            [
              -90.166246,
              35.374745
            ],
            [
              -90.144924,
              35.374633
            ],
            [
              -90.13551,
              35.376668
            ],
            [
              -90.143475,
              35.387602
            ],
            [
              -90.14587,
              35.395079
            ],
            [
              -90.146191,
              35.399468
            ],
            [
              -90.145085,
              35.403757
            ],
            [
              -90.143448,
              35.406671
            ],
            [
              -90.14166,
              35.408563
            ],
            [
              -90.137881,
              35.41151
            ],
            [
              -90.135125,
              35.412977
            ],
            [
              -90.130475,
              35.413745
            ],
            [
              -90.116902,
              35.411692
            ],
            [
              -90.112504,
              35.410153
            ],
            [
              -90.110543,
              35.408595
            ],
            [
              -90.106775,
              35.403339
            ],
            [
              -90.104842,
              35.401487
            ],
            [
              -90.09665,
              35.395257
            ],
            [
              -90.093589,
              35.393333
            ],
            [
              -90.087743,
              35.390838
            ],
            [
              -90.07993,
              35.385272
            ],
            [
              -90.077971,
              35.384501
            ],
            [
              -90.074992,
              35.384152
            ],
            [
              -90.0667,
              35.385167
            ],
            [
              -90.061788,
              35.386184
            ],
            [
              -90.054322,
              35.389277
            ],
            [
              -90.056644,
              35.403786
            ],
            [
              -90.069283,
              35.408306
            ],
            [
              -90.062018,
              35.41518
            ],
            [
              -90.070549,
              35.423291
            ],
            [
              -90.074082,
              35.433983
            ],
            [
              -90.074063,
              35.439611
            ],
            [
              -90.071327,
              35.450338
            ],
            [
              -90.067206,
              35.460957
            ],
            [
              -90.067138,
              35.464833
            ],
            [
              -90.067798,
              35.466224
            ],
            [
              -90.072154,
              35.470752
            ],
            [
              -90.080128,
              35.476844
            ],
            [
              -90.085009,
              35.478835
            ],
            [
              -90.098719,
              35.478595
            ],
            [
              -90.101759,
              35.476889
            ],
            [
              -90.107723,
              35.476935
            ],
            [
              -90.114412,
              35.472467
            ],
            [
              -90.11839,
              35.468791
            ],
            [
              -90.120619,
              35.465741
            ],
            [
              -90.123142,
              35.459853
            ],
            [
              -90.129448,
              35.441931
            ],
            [
              -90.1401796054271,
              35.436483531380794
            ],
            [
              -90.169002,
              35.421853
            ],
            [
              -90.170599,
              35.418352
            ],
            [
              -90.1707,
              35.410065
            ],
            [
              -90.179265,
              35.385194
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "109",
      "COUNTYNS": "01639774",
      "AFFGEOID": "0500000US47109",
      "GEOID": "47109",
      "NAME": "McNairy",
      "LSAD": "06",
      "ALAND": 1457753417,
      "AWATER": 1994987,
      "County_state": "McNairy,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.786729,
              35.125085
            ],
            [
              -88.786799,
              35.062521
            ],
            [
              -88.786612,
              34.995252
            ],
            [
              -88.51421417673279,
              34.9959236741123
            ],
            [
              -88.469877,
              34.996033
            ],
            [
              -88.469801,
              34.996052
            ],
            [
              -88.380508,
              34.99561
            ],
            [
              -88.367243,
              35.290985
            ],
            [
              -88.374926,
              35.304031
            ],
            [
              -88.372576,
              35.315748
            ],
            [
              -88.365568,
              35.315715
            ],
            [
              -88.362572,
              35.380994
            ],
            [
              -88.487211,
              35.38523
            ],
            [
              -88.566735,
              35.382275
            ],
            [
              -88.640934,
              35.361017
            ],
            [
              -88.663119,
              35.340258
            ],
            [
              -88.665364,
              35.319193
            ],
            [
              -88.653924,
              35.317571
            ],
            [
              -88.672741,
              35.312454
            ],
            [
              -88.674885,
              35.323415
            ],
            [
              -88.689275,
              35.310222
            ],
            [
              -88.707137,
              35.257875
            ],
            [
              -88.719761,
              35.24755
            ],
            [
              -88.781768,
              35.247587
            ],
            [
              -88.786729,
              35.125085
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "135",
      "COUNTYNS": "01639781",
      "AFFGEOID": "0500000US47135",
      "GEOID": "47135",
      "NAME": "Perry",
      "LSAD": "06",
      "ALAND": 1074185963,
      "AWATER": 21034745,
      "County_state": "Perry,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.039932,
              35.459294
            ],
            [
              -88.042332,
              35.444617
            ],
            [
              -88.030842,
              35.427963
            ],
            [
              -88.007709,
              35.423381
            ],
            [
              -88.011815,
              35.432826
            ],
            [
              -87.988894,
              35.453666
            ],
            [
              -87.97318,
              35.459968
            ],
            [
              -87.952092,
              35.458121
            ],
            [
              -87.932058,
              35.465701
            ],
            [
              -87.90579,
              35.462456
            ],
            [
              -87.864865,
              35.475976
            ],
            [
              -87.858506,
              35.465413
            ],
            [
              -87.827406,
              35.46988
            ],
            [
              -87.803589,
              35.484954
            ],
            [
              -87.733811,
              35.491691
            ],
            [
              -87.717559,
              35.483347
            ],
            [
              -87.711014,
              35.505289
            ],
            [
              -87.690651,
              35.505329
            ],
            [
              -87.685834,
              35.548065
            ],
            [
              -87.651007,
              35.568762
            ],
            [
              -87.64853,
              35.590012
            ],
            [
              -87.658189,
              35.609349
            ],
            [
              -87.686891,
              35.633702
            ],
            [
              -87.724306,
              35.650347
            ],
            [
              -87.74375,
              35.663952
            ],
            [
              -87.737811,
              35.702342
            ],
            [
              -87.710669,
              35.703616
            ],
            [
              -87.732935,
              35.72626
            ],
            [
              -87.71676,
              35.83875
            ],
            [
              -87.818738,
              35.836769
            ],
            [
              -87.825957,
              35.823921
            ],
            [
              -87.844142,
              35.820724
            ],
            [
              -87.852682,
              35.803799
            ],
            [
              -87.893127,
              35.824858
            ],
            [
              -87.910297,
              35.838336
            ],
            [
              -87.96274,
              35.840921
            ],
            [
              -87.966952,
              35.834755
            ],
            [
              -87.970738,
              35.815705
            ],
            [
              -87.979846,
              35.778914
            ],
            [
              -87.989971,
              35.761732
            ],
            [
              -88.021579,
              35.737641
            ],
            [
              -88.034788,
              35.717261
            ],
            [
              -88.028409,
              35.693815
            ],
            [
              -88.017557,
              35.683078
            ],
            [
              -88.018932,
              35.660224
            ],
            [
              -88.035837,
              35.621839
            ],
            [
              -88.03417,
              35.613787
            ],
            [
              -87.998443,
              35.553089
            ],
            [
              -87.981321,
              35.535289
            ],
            [
              -87.974353,
              35.514049
            ],
            [
              -87.981334,
              35.49015
            ],
            [
              -87.9891,
              35.482679
            ],
            [
              -88.026787,
              35.472051
            ],
            [
              -88.039932,
              35.459294
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "119",
      "COUNTYNS": "01639772",
      "AFFGEOID": "0500000US47119",
      "GEOID": "47119",
      "NAME": "Maury",
      "LSAD": "06",
      "ALAND": 1588021148,
      "AWATER": 6319196,
      "County_state": "Maury,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.352894,
              35.617898
            ],
            [
              -87.352305,
              35.584621
            ],
            [
              -87.322744,
              35.568925
            ],
            [
              -87.317721,
              35.549104
            ],
            [
              -87.29719,
              35.548422
            ],
            [
              -87.285953,
              35.542156
            ],
            [
              -87.282393,
              35.50486
            ],
            [
              -87.269432,
              35.505851
            ],
            [
              -87.283376,
              35.496856
            ],
            [
              -87.274764,
              35.483405
            ],
            [
              -87.294534,
              35.444676
            ],
            [
              -87.295242,
              35.442128
            ],
            [
              -87.227932,
              35.429332
            ],
            [
              -87.226272,
              35.440408
            ],
            [
              -87.206578,
              35.433207
            ],
            [
              -87.160192,
              35.435377
            ],
            [
              -87.149904,
              35.446918
            ],
            [
              -87.129112,
              35.456019
            ],
            [
              -87.101246,
              35.449614
            ],
            [
              -87.094465,
              35.441845
            ],
            [
              -87.071771,
              35.440383
            ],
            [
              -87.058964,
              35.431265
            ],
            [
              -87.032728,
              35.431709
            ],
            [
              -87.017499,
              35.415816
            ],
            [
              -86.990905,
              35.408175
            ],
            [
              -86.984905,
              35.419775
            ],
            [
              -86.960804,
              35.417021
            ],
            [
              -86.955004,
              35.451474
            ],
            [
              -86.924503,
              35.463674
            ],
            [
              -86.8806,
              35.494003
            ],
            [
              -86.875589,
              35.521251
            ],
            [
              -86.87128,
              35.502486
            ],
            [
              -86.849573,
              35.525515
            ],
            [
              -86.8381,
              35.550776
            ],
            [
              -86.840489,
              35.567286
            ],
            [
              -86.827394,
              35.58211
            ],
            [
              -86.839846,
              35.591527
            ],
            [
              -86.824795,
              35.600934
            ],
            [
              -86.811864,
              35.696412
            ],
            [
              -86.796935,
              35.691479
            ],
            [
              -86.782016,
              35.706595
            ],
            [
              -86.811891,
              35.720455
            ],
            [
              -86.845491,
              35.725166
            ],
            [
              -86.888056,
              35.743823
            ],
            [
              -86.938278,
              35.761972
            ],
            [
              -86.92836,
              35.778057
            ],
            [
              -86.962471,
              35.782883
            ],
            [
              -86.96951,
              35.771676
            ],
            [
              -87.003959,
              35.779028
            ],
            [
              -87.035681,
              35.796213
            ],
            [
              -87.215099,
              35.850651
            ],
            [
              -87.234143,
              35.724691
            ],
            [
              -87.245757,
              35.720637
            ],
            [
              -87.25561,
              35.700516
            ],
            [
              -87.289409,
              35.696904
            ],
            [
              -87.339232,
              35.65911
            ],
            [
              -87.332768,
              35.634672
            ],
            [
              -87.352894,
              35.617898
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "155",
      "COUNTYNS": "01639789",
      "AFFGEOID": "0500000US47155",
      "GEOID": "47155",
      "NAME": "Sevier",
      "LSAD": "06",
      "ALAND": 1534562002,
      "AWATER": 13491054,
      "County_state": "Sevier,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.767594,
              35.911462
            ],
            [
              -83.794275,
              35.887125
            ],
            [
              -83.757287,
              35.817528
            ],
            [
              -83.739518,
              35.806815
            ],
            [
              -83.747317,
              35.798398
            ],
            [
              -83.718914,
              35.747726
            ],
            [
              -83.716462,
              35.729035
            ],
            [
              -83.701344,
              35.719276
            ],
            [
              -83.70525,
              35.71158
            ],
            [
              -83.682161,
              35.709772
            ],
            [
              -83.674272,
              35.685497
            ],
            [
              -83.662814,
              35.690618
            ],
            [
              -83.66302360458259,
              35.5691430430619
            ],
            [
              -83.662957,
              35.569138
            ],
            [
              -83.660925,
              35.568207
            ],
            [
              -83.657933,
              35.569211
            ],
            [
              -83.653159,
              35.568309
            ],
            [
              -83.645481,
              35.565825
            ],
            [
              -83.640498,
              35.566075
            ],
            [
              -83.637182,
              35.567096
            ],
            [
              -83.635832,
              35.568169
            ],
            [
              -83.632358,
              35.569093
            ],
            [
              -83.629734,
              35.567889
            ],
            [
              -83.615312,
              35.574026
            ],
            [
              -83.608889,
              35.579451
            ],
            [
              -83.604806,
              35.57934
            ],
            [
              -83.601854,
              35.578228
            ],
            [
              -83.59427,
              35.572912
            ],
            [
              -83.587827,
              35.566963
            ],
            [
              -83.58714,
              35.564017
            ],
            [
              -83.58559,
              35.562941
            ],
            [
              -83.582,
              35.562684
            ],
            [
              -83.576345,
              35.564019
            ],
            [
              -83.572424,
              35.565518
            ],
            [
              -83.56609,
              35.565993
            ],
            [
              -83.559264,
              35.564796
            ],
            [
              -83.552167,
              35.564346
            ],
            [
              -83.540826,
              35.565702
            ],
            [
              -83.534169,
              35.564668
            ],
            [
              -83.520469,
              35.565602
            ],
            [
              -83.517564,
              35.562871
            ],
            [
              -83.498335,
              35.562981
            ],
            [
              -83.491647,
              35.566867
            ],
            [
              -83.485527,
              35.568204
            ],
            [
              -83.480617,
              35.576633
            ],
            [
              -83.478523,
              35.579202
            ],
            [
              -83.479317,
              35.582764
            ],
            [
              -83.479082,
              35.583316
            ],
            [
              -83.475367,
              35.584775
            ],
            [
              -83.472684,
              35.586552
            ],
            [
              -83.472668,
              35.589125
            ],
            [
              -83.471362,
              35.590304
            ],
            [
              -83.462678,
              35.5926
            ],
            [
              -83.455722,
              35.598045
            ],
            [
              -83.452431,
              35.602918
            ],
            [
              -83.447137,
              35.608664
            ],
            [
              -83.445802,
              35.611803
            ],
            [
              -83.441197,
              35.611739
            ],
            [
              -83.432298,
              35.609941
            ],
            [
              -83.421576,
              35.611186
            ],
            [
              -83.420964,
              35.611596
            ],
            [
              -83.42037,
              35.613467
            ],
            [
              -83.411852,
              35.61692
            ],
            [
              -83.406061,
              35.620185
            ],
            [
              -83.403569,
              35.621313
            ],
            [
              -83.396626,
              35.62272
            ],
            [
              -83.392652,
              35.625095
            ],
            [
              -83.388602,
              35.632352
            ],
            [
              -83.388722,
              35.633584
            ],
            [
              -83.380251,
              35.634705
            ],
            [
              -83.377984,
              35.634496
            ],
            [
              -83.376785,
              35.636638
            ],
            [
              -83.373712,
              35.638935
            ],
            [
              -83.372174,
              35.63931
            ],
            [
              -83.370369,
              35.638204
            ],
            [
              -83.368162,
              35.638202
            ],
            [
              -83.366941,
              35.638728
            ],
            [
              -83.358209,
              35.647277
            ],
            [
              -83.356202,
              35.650019
            ],
            [
              -83.355367,
              35.652338
            ],
            [
              -83.355537,
              35.654632
            ],
            [
              -83.353776,
              35.657478
            ],
            [
              -83.35156,
              35.659858
            ],
            [
              -83.349255,
              35.660854
            ],
            [
              -83.347262,
              35.660474
            ],
            [
              -83.337683,
              35.663074
            ],
            [
              -83.334965,
              35.665471
            ],
            [
              -83.321101,
              35.662815
            ],
            [
              -83.317905,
              35.659015
            ],
            [
              -83.312757,
              35.654809
            ],
            [
              -83.31049,
              35.654452
            ],
            [
              -83.302279,
              35.656064
            ],
            [
              -83.297154,
              35.65775
            ],
            [
              -83.293676,
              35.661919
            ],
            [
              -83.291075,
              35.667131
            ],
            [
              -83.290682,
              35.672638
            ],
            [
              -83.289165,
              35.674509
            ],
            [
              -83.281178,
              35.677802
            ],
            [
              -83.27548,
              35.679463
            ],
            [
              -83.271378,
              35.681476
            ],
            [
              -83.269277,
              35.685403
            ],
            [
              -83.26539,
              35.687535
            ],
            [
              -83.261252,
              35.689165
            ],
            [
              -83.258117,
              35.691924
            ],
            [
              -83.25561,
              35.696061
            ],
            [
              -83.255126,
              35.701493
            ],
            [
              -83.256111,
              35.703961
            ],
            [
              -83.255108,
              35.707096
            ],
            [
              -83.25423,
              35.709478
            ],
            [
              -83.254481,
              35.712362
            ],
            [
              -83.255489,
              35.714974
            ],
            [
              -83.255476179897,
              35.7150906815168
            ],
            [
              -83.276413,
              35.713591
            ],
            [
              -83.280778,
              35.750095
            ],
            [
              -83.275055,
              35.769644
            ],
            [
              -83.285769,
              35.780445
            ],
            [
              -83.287421,
              35.795423
            ],
            [
              -83.263003,
              35.820005
            ],
            [
              -83.308735,
              35.871692
            ],
            [
              -83.310782,
              35.895846
            ],
            [
              -83.330258,
              35.911609
            ],
            [
              -83.359949,
              35.919011
            ],
            [
              -83.387457,
              35.913605
            ],
            [
              -83.389133,
              35.92822
            ],
            [
              -83.415994,
              35.933231
            ],
            [
              -83.435405,
              35.923727
            ],
            [
              -83.444352,
              35.928915
            ],
            [
              -83.474901,
              35.922893
            ],
            [
              -83.477139,
              35.930126
            ],
            [
              -83.508088,
              35.938832
            ],
            [
              -83.525477,
              35.964557
            ],
            [
              -83.607494,
              36.015685
            ],
            [
              -83.655748,
              36.042966
            ],
            [
              -83.65636,
              36.034589
            ],
            [
              -83.673297,
              36.038486
            ],
            [
              -83.661938,
              35.991507
            ],
            [
              -83.650957,
              35.986368
            ],
            [
              -83.667341,
              35.961643
            ],
            [
              -83.686586,
              35.949967
            ],
            [
              -83.683627,
              35.936846
            ],
            [
              -83.697792,
              35.939763
            ],
            [
              -83.738506,
              35.916966
            ],
            [
              -83.767594,
              35.911462
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "139",
      "COUNTYNS": "01639782",
      "AFFGEOID": "0500000US47139",
      "GEOID": "47139",
      "NAME": "Polk",
      "LSAD": "06",
      "ALAND": 1125617646,
      "AWATER": 19911983,
      "County_state": "Polk,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.759638,
              35.025389
            ],
            [
              -84.7758524571709,
              34.987799615242096
            ],
            [
              -84.731022,
              34.988088
            ],
            [
              -84.727434,
              34.98802
            ],
            [
              -84.624933,
              34.987978
            ],
            [
              -84.621483,
              34.988329
            ],
            [
              -84.509886,
              34.98801
            ],
            [
              -84.509052,
              34.988033
            ],
            [
              -84.394903,
              34.98803
            ],
            [
              -84.393935,
              34.988068
            ],
            [
              -84.321869,
              34.988408
            ],
            [
              -84.310022,
              35.078883
            ],
            [
              -84.308576,
              35.092761
            ],
            [
              -84.308437,
              35.093173
            ],
            [
              -84.304809,
              35.121702
            ],
            [
              -84.298006,
              35.167468
            ],
            [
              -84.295238,
              35.182442
            ],
            [
              -84.292321,
              35.206677
            ],
            [
              -84.292319677455,
              35.206689008403494
            ],
            [
              -84.325722,
              35.219377
            ],
            [
              -84.344323,
              35.204276
            ],
            [
              -84.352123,
              35.207176
            ],
            [
              -84.344822,
              35.220376
            ],
            [
              -84.360923,
              35.226676
            ],
            [
              -84.356923,
              35.233676
            ],
            [
              -84.495529,
              35.285472
            ],
            [
              -84.51613,
              35.266472
            ],
            [
              -84.667398,
              35.268041
            ],
            [
              -84.685443,
              35.246584
            ],
            [
              -84.703607,
              35.241043
            ],
            [
              -84.715818,
              35.233683
            ],
            [
              -84.697033,
              35.228397
            ],
            [
              -84.702138,
              35.219073
            ],
            [
              -84.697937,
              35.200473
            ],
            [
              -84.703337,
              35.184774
            ],
            [
              -84.699737,
              35.172274
            ],
            [
              -84.747421,
              35.086011
            ],
            [
              -84.735303,
              35.08135
            ],
            [
              -84.748599,
              35.058776
            ],
            [
              -84.759638,
              35.025389
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "045",
      "COUNTYNS": "01639741",
      "AFFGEOID": "0500000US47045",
      "GEOID": "47045",
      "NAME": "Dyer",
      "LSAD": "06",
      "ALAND": 1326943234,
      "AWATER": 36772010,
      "County_state": "Dyer,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.733095,
              36.000608
            ],
            [
              -89.731218,
              35.996879
            ],
            [
              -89.728442,
              35.993568
            ],
            [
              -89.719168,
              35.985976
            ],
            [
              -89.718801,
              35.985015
            ],
            [
              -89.71997,
              35.97462
            ],
            [
              -89.719679,
              35.970939
            ],
            [
              -89.718796,
              35.968283
            ],
            [
              -89.714565,
              35.963034
            ],
            [
              -89.710227,
              35.959826
            ],
            [
              -89.699871,
              35.954063
            ],
            [
              -89.686924,
              35.947716
            ],
            [
              -89.676621,
              35.940539
            ],
            [
              -89.671117,
              35.935795
            ],
            [
              -89.656147,
              35.92581
            ],
            [
              -89.652279,
              35.921462
            ],
            [
              -89.65034,
              35.917795
            ],
            [
              -89.646711,
              35.908008
            ],
            [
              -89.644838,
              35.904351
            ],
            [
              -89.631345,
              35.924133
            ],
            [
              -89.608251,
              35.919957
            ],
            [
              -89.586938,
              35.928547
            ],
            [
              -89.569164,
              35.92577
            ],
            [
              -89.558335,
              35.937137
            ],
            [
              -89.515183,
              35.928164
            ],
            [
              -89.490271,
              35.934202
            ],
            [
              -89.490925,
              35.947723
            ],
            [
              -89.406965,
              35.946599
            ],
            [
              -89.398186,
              35.941369
            ],
            [
              -89.384072,
              35.920672
            ],
            [
              -89.353849,
              35.902457
            ],
            [
              -89.342829,
              35.880934
            ],
            [
              -89.280188,
              35.886416
            ],
            [
              -89.267229,
              35.913542
            ],
            [
              -89.250069,
              35.917348
            ],
            [
              -89.205277,
              35.963117
            ],
            [
              -89.188844,
              35.99976
            ],
            [
              -89.206297,
              36.011814
            ],
            [
              -89.163862,
              36.02619
            ],
            [
              -89.154464,
              36.204678
            ],
            [
              -89.482971,
              36.212659
            ],
            [
              -89.488283,
              36.187544
            ],
            [
              -89.629452,
              36.185382
            ],
            [
              -89.623804,
              36.183128
            ],
            [
              -89.618228,
              36.179966
            ],
            [
              -89.607004,
              36.171179
            ],
            [
              -89.600871,
              36.164558
            ],
            [
              -89.594397,
              36.155457
            ],
            [
              -89.592206,
              36.15012
            ],
            [
              -89.591605,
              36.144096
            ],
            [
              -89.592102,
              36.135637
            ],
            [
              -89.59307,
              36.129699
            ],
            [
              -89.594,
              36.12719
            ],
            [
              -89.598946,
              36.121778
            ],
            [
              -89.601936,
              36.11947
            ],
            [
              -89.615128,
              36.113816
            ],
            [
              -89.625078,
              36.108131
            ],
            [
              -89.628305,
              36.106853
            ],
            [
              -89.64302,
              36.10362
            ],
            [
              -89.657709,
              36.099128
            ],
            [
              -89.666598,
              36.095802
            ],
            [
              -89.672463,
              36.091837
            ],
            [
              -89.678821,
              36.084636
            ],
            [
              -89.680029,
              36.082494
            ],
            [
              -89.681946,
              36.072336
            ],
            [
              -89.684439,
              36.051719
            ],
            [
              -89.687254,
              36.034048
            ],
            [
              -89.688577,
              36.029238
            ],
            [
              -89.692437,
              36.020507
            ],
            [
              -89.703571,
              36.00804
            ],
            [
              -89.705677,
              36.005018
            ],
            [
              -89.706932,
              36.000981
            ],
            [
              -89.733095,
              36.000608
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "031",
      "COUNTYNS": "01639735",
      "AFFGEOID": "0500000US47031",
      "GEOID": "47031",
      "NAME": "Coffee",
      "LSAD": "06",
      "ALAND": 1111017222,
      "AWATER": 14472637,
      "County_state": "Coffee,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.25759,
              35.412476
            ],
            [
              -86.279905,
              35.387452
            ],
            [
              -86.253335,
              35.385299
            ],
            [
              -86.274391,
              35.372278
            ],
            [
              -86.24539,
              35.347379
            ],
            [
              -86.266376,
              35.338564
            ],
            [
              -86.261489,
              35.333579
            ],
            [
              -86.250174,
              35.344644
            ],
            [
              -86.23269,
              35.341179
            ],
            [
              -86.212809,
              35.34692
            ],
            [
              -86.197589,
              35.333479
            ],
            [
              -86.194039,
              35.344509
            ],
            [
              -86.158018,
              35.362607
            ],
            [
              -86.036549,
              35.343738
            ],
            [
              -86.033482,
              35.335157
            ],
            [
              -86.02369,
              35.334348
            ],
            [
              -86.002382,
              35.31488
            ],
            [
              -85.976862,
              35.317179
            ],
            [
              -85.913593,
              35.289189
            ],
            [
              -85.906935,
              35.297295
            ],
            [
              -85.904877,
              35.330434
            ],
            [
              -85.881077,
              35.348804
            ],
            [
              -85.869478,
              35.380435
            ],
            [
              -85.886982,
              35.38154
            ],
            [
              -85.884482,
              35.392979
            ],
            [
              -85.889216,
              35.443954
            ],
            [
              -85.885585,
              35.489485
            ],
            [
              -85.876232,
              35.507339
            ],
            [
              -85.885485,
              35.517039
            ],
            [
              -85.876962,
              35.524095
            ],
            [
              -85.882493,
              35.53902
            ],
            [
              -85.90455,
              35.541299
            ],
            [
              -85.948192,
              35.573422
            ],
            [
              -85.954374,
              35.594207
            ],
            [
              -85.94693,
              35.596389
            ],
            [
              -85.956333,
              35.619561
            ],
            [
              -85.965197,
              35.62045
            ],
            [
              -85.985062,
              35.660108
            ],
            [
              -85.994192,
              35.66027
            ],
            [
              -85.994462,
              35.653725
            ],
            [
              -86.032238,
              35.65114
            ],
            [
              -86.037601,
              35.642954
            ],
            [
              -86.08889,
              35.646823
            ],
            [
              -86.120263,
              35.660929
            ],
            [
              -86.143652,
              35.661747
            ],
            [
              -86.165826,
              35.681545
            ],
            [
              -86.207147,
              35.70379
            ],
            [
              -86.221631,
              35.706429
            ],
            [
              -86.245406,
              35.688899
            ],
            [
              -86.245165,
              35.631913
            ],
            [
              -86.242149,
              35.570689
            ],
            [
              -86.241641,
              35.504176
            ],
            [
              -86.244695,
              35.459394
            ],
            [
              -86.234575,
              35.45276
            ],
            [
              -86.246613,
              35.449195
            ],
            [
              -86.25759,
              35.412476
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "177",
      "COUNTYNS": "01639796",
      "AFFGEOID": "0500000US47177",
      "GEOID": "47177",
      "NAME": "Warren",
      "LSAD": "06",
      "ALAND": 1120619142,
      "AWATER": 3524884,
      "County_state": "Warren,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.997426,
              35.711955
            ],
            [
              -85.985062,
              35.660108
            ],
            [
              -85.965197,
              35.62045
            ],
            [
              -85.956333,
              35.619561
            ],
            [
              -85.94693,
              35.596389
            ],
            [
              -85.954374,
              35.594207
            ],
            [
              -85.948192,
              35.573422
            ],
            [
              -85.90455,
              35.541299
            ],
            [
              -85.882493,
              35.53902
            ],
            [
              -85.876962,
              35.524095
            ],
            [
              -85.832925,
              35.515303
            ],
            [
              -85.820334,
              35.504573
            ],
            [
              -85.791307,
              35.517884
            ],
            [
              -85.7537,
              35.524154
            ],
            [
              -85.70326,
              35.528653
            ],
            [
              -85.607326,
              35.531651
            ],
            [
              -85.557535,
              35.532977
            ],
            [
              -85.594632,
              35.617898
            ],
            [
              -85.60514,
              35.708529
            ],
            [
              -85.592684,
              35.734683
            ],
            [
              -85.599651,
              35.741308
            ],
            [
              -85.591894,
              35.75436
            ],
            [
              -85.615146,
              35.760531
            ],
            [
              -85.602876,
              35.794996
            ],
            [
              -85.641655,
              35.816717
            ],
            [
              -85.682602,
              35.827246
            ],
            [
              -85.682095,
              35.831254
            ],
            [
              -85.751785,
              35.850027
            ],
            [
              -85.787534,
              35.843036
            ],
            [
              -85.875877,
              35.845776
            ],
            [
              -85.885156,
              35.839658
            ],
            [
              -85.913867,
              35.822506
            ],
            [
              -85.954798,
              35.767081
            ],
            [
              -85.969994,
              35.753541
            ],
            [
              -85.997426,
              35.711955
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "165",
      "COUNTYNS": "01639794",
      "AFFGEOID": "0500000US47165",
      "GEOID": "47165",
      "NAME": "Sumner",
      "LSAD": "06",
      "ALAND": 1371067660,
      "AWATER": 35857190,
      "County_state": "Sumner,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.748276,
              36.418627
            ],
            [
              -86.754795,
              36.405496
            ],
            [
              -86.745693,
              36.403097
            ],
            [
              -86.727529,
              36.372316
            ],
            [
              -86.721054,
              36.342076
            ],
            [
              -86.674807,
              36.31579
            ],
            [
              -86.668744,
              36.300577
            ],
            [
              -86.642,
              36.288251
            ],
            [
              -86.639095,
              36.255105
            ],
            [
              -86.622798,
              36.249308
            ],
            [
              -86.615092,
              36.245104
            ],
            [
              -86.594286,
              36.245105
            ],
            [
              -86.571095,
              36.262552
            ],
            [
              -86.559794,
              36.287451
            ],
            [
              -86.564433,
              36.30455
            ],
            [
              -86.552842,
              36.319066
            ],
            [
              -86.543434,
              36.320602
            ],
            [
              -86.537826,
              36.290727
            ],
            [
              -86.521642,
              36.278099
            ],
            [
              -86.513426,
              36.298303
            ],
            [
              -86.451721,
              36.335629
            ],
            [
              -86.433665,
              36.340017
            ],
            [
              -86.418392,
              36.332837
            ],
            [
              -86.41544,
              36.313698
            ],
            [
              -86.407812,
              36.307702
            ],
            [
              -86.390436,
              36.310196
            ],
            [
              -86.389728,
              36.327817
            ],
            [
              -86.376888,
              36.350921
            ],
            [
              -86.361323,
              36.357684
            ],
            [
              -86.346517,
              36.345112
            ],
            [
              -86.346094,
              36.329848
            ],
            [
              -86.362023,
              36.311505
            ],
            [
              -86.330452,
              36.318772
            ],
            [
              -86.300075,
              36.312374
            ],
            [
              -86.296429,
              36.334636
            ],
            [
              -86.283256,
              36.3475
            ],
            [
              -86.272488,
              36.35874
            ],
            [
              -86.268788,
              36.452897
            ],
            [
              -86.23722,
              36.45192
            ],
            [
              -86.239992,
              36.486384
            ],
            [
              -86.228434,
              36.487472
            ],
            [
              -86.222331,
              36.490982
            ],
            [
              -86.215709,
              36.515422
            ],
            [
              -86.225104,
              36.528776
            ],
            [
              -86.20546861000119,
              36.6397833130926
            ],
            [
              -86.216183,
              36.640527
            ],
            [
              -86.21641,
              36.640595
            ],
            [
              -86.219081,
              36.640824
            ],
            [
              -86.222151,
              36.640891
            ],
            [
              -86.293357,
              36.645356
            ],
            [
              -86.333051,
              36.648778
            ],
            [
              -86.359073,
              36.649845
            ],
            [
              -86.373784,
              36.650126
            ],
            [
              -86.374991,
              36.649803
            ],
            [
              -86.41138614351739,
              36.6505962464493
            ],
            [
              -86.468497,
              36.651841
            ],
            [
              -86.47219,
              36.651763
            ],
            [
              -86.473413,
              36.651676
            ],
            [
              -86.473497,
              36.651671
            ],
            [
              -86.507771,
              36.652445
            ],
            [
              -86.543777,
              36.640536
            ],
            [
              -86.543388,
              36.64389
            ],
            [
              -86.550054,
              36.644817
            ],
            [
              -86.551292,
              36.637985
            ],
            [
              -86.56391574841909,
              36.6335518059594
            ],
            [
              -86.602777,
              36.572757
            ],
            [
              -86.623389,
              36.549877
            ],
            [
              -86.635037,
              36.534041
            ],
            [
              -86.640766,
              36.497093
            ],
            [
              -86.651919,
              36.470051
            ],
            [
              -86.675239,
              36.450001
            ],
            [
              -86.695064,
              36.421319
            ],
            [
              -86.693292,
              36.407231
            ],
            [
              -86.735484,
              36.418279
            ],
            [
              -86.748276,
              36.418627
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "001",
      "COUNTYNS": "01639722",
      "AFFGEOID": "0500000US47001",
      "GEOID": "47001",
      "NAME": "Anderson",
      "LSAD": "06",
      "ALAND": 873292849,
      "AWATER": 19724754,
      "County_state": "Anderson,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.441034,
              36.163478
            ],
            [
              -84.449734,
              36.141677
            ],
            [
              -84.430033,
              36.131877
            ],
            [
              -84.420432,
              36.108377
            ],
            [
              -84.37903,
              36.095377
            ],
            [
              -84.341812,
              36.048977
            ],
            [
              -84.32006,
              35.991607
            ],
            [
              -84.271587,
              35.910165
            ],
            [
              -84.252359,
              35.918888
            ],
            [
              -84.272869,
              35.940506
            ],
            [
              -84.25434,
              35.939936
            ],
            [
              -84.235823,
              35.931678
            ],
            [
              -84.244016,
              35.95499
            ],
            [
              -84.221373,
              35.945752
            ],
            [
              -84.213407,
              35.975774
            ],
            [
              -84.198894,
              35.977198
            ],
            [
              -84.195151,
              35.993008
            ],
            [
              -84.1495,
              35.986927
            ],
            [
              -84.101465,
              36.011709
            ],
            [
              -84.085091,
              36.016709
            ],
            [
              -84.070116,
              36.028881
            ],
            [
              -84.058585,
              36.036304
            ],
            [
              -84.093004,
              36.073519
            ],
            [
              -84.072427,
              36.083263
            ],
            [
              -84.029717,
              36.116475
            ],
            [
              -84.015314,
              36.135378
            ],
            [
              -84.007283,
              36.135887
            ],
            [
              -83.976313,
              36.167879
            ],
            [
              -83.941711,
              36.186378
            ],
            [
              -83.96914,
              36.210105
            ],
            [
              -84.009753,
              36.27031
            ],
            [
              -84.004317,
              36.270378
            ],
            [
              -84.021518,
              36.272278
            ],
            [
              -84.026519,
              36.297079
            ],
            [
              -84.032519,
              36.301779
            ],
            [
              -84.041419,
              36.275278
            ],
            [
              -84.055419,
              36.263678
            ],
            [
              -84.057119,
              36.250578
            ],
            [
              -84.072135,
              36.252264
            ],
            [
              -84.069918,
              36.230378
            ],
            [
              -84.102691,
              36.224424
            ],
            [
              -84.110421,
              36.233878
            ],
            [
              -84.140125,
              36.233676
            ],
            [
              -84.17348,
              36.240068
            ],
            [
              -84.227527,
              36.244879
            ],
            [
              -84.226326,
              36.216479
            ],
            [
              -84.230325,
              36.182078
            ],
            [
              -84.237125,
              36.178678
            ],
            [
              -84.301429,
              36.220978
            ],
            [
              -84.33073,
              36.208078
            ],
            [
              -84.346731,
              36.215078
            ],
            [
              -84.372731,
              36.216778
            ],
            [
              -84.392433,
              36.201678
            ],
            [
              -84.403633,
              36.174778
            ],
            [
              -84.441034,
              36.163478
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "027",
      "COUNTYNS": "01648573",
      "AFFGEOID": "0500000US47027",
      "GEOID": "47027",
      "NAME": "Clay",
      "LSAD": "06",
      "ALAND": 612626310,
      "AWATER": 59071215,
      "County_state": "Clay,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.796462,
              36.613541
            ],
            [
              -85.813885,
              36.499473
            ],
            [
              -85.767125,
              36.517197
            ],
            [
              -85.75359,
              36.499051
            ],
            [
              -85.715799,
              36.497517
            ],
            [
              -85.73599,
              36.518798
            ],
            [
              -85.704075,
              36.522395
            ],
            [
              -85.696107,
              36.49597
            ],
            [
              -85.667376,
              36.496037
            ],
            [
              -85.57596,
              36.471617
            ],
            [
              -85.564358,
              36.475229
            ],
            [
              -85.560772,
              36.459928
            ],
            [
              -85.529958,
              36.437701
            ],
            [
              -85.504865,
              36.407793
            ],
            [
              -85.495422,
              36.403074
            ],
            [
              -85.481736,
              36.454309
            ],
            [
              -85.446372,
              36.49797
            ],
            [
              -85.392619,
              36.504484
            ],
            [
              -85.400769,
              36.491292
            ],
            [
              -85.383184,
              36.488784
            ],
            [
              -85.389937,
              36.505639
            ],
            [
              -85.337916,
              36.527845
            ],
            [
              -85.299,
              36.522286
            ],
            [
              -85.284878,
              36.531293
            ],
            [
              -85.267057,
              36.573238
            ],
            [
              -85.27749,
              36.586137
            ],
            [
              -85.2762840339111,
              36.626510937829494
            ],
            [
              -85.276289,
              36.626511
            ],
            [
              -85.290627,
              36.62645
            ],
            [
              -85.2959488756198,
              36.6258382676941
            ],
            [
              -85.296073,
              36.625824
            ],
            [
              -85.300402,
              36.624437
            ],
            [
              -85.324654,
              36.62455
            ],
            [
              -85.334124,
              36.622951
            ],
            [
              -85.430123,
              36.618952
            ],
            [
              -85.4364330342016,
              36.6185230953912
            ],
            [
              -85.488353,
              36.614994
            ],
            [
              -85.551483,
              36.615727
            ],
            [
              -85.552017,
              36.615782
            ],
            [
              -85.677789,
              36.618157
            ],
            [
              -85.731862,
              36.620429
            ],
            [
              -85.7886129951215,
              36.6218452013294
            ],
            [
              -85.796462,
              36.613541
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "059",
      "COUNTYNS": "01639747",
      "AFFGEOID": "0500000US47059",
      "GEOID": "47059",
      "NAME": "Greene",
      "LSAD": "06",
      "ALAND": 1611409749,
      "AWATER": 5099752,
      "County_state": "Greene,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.166085,
              36.180894
            ],
            [
              -83.172485,
              36.157095
            ],
            [
              -83.152484,
              36.144996
            ],
            [
              -83.139683,
              36.129196
            ],
            [
              -83.116046,
              36.121756
            ],
            [
              -83.118333,
              36.112022
            ],
            [
              -83.102761,
              36.120909
            ],
            [
              -83.073809,
              36.094416
            ],
            [
              -83.055678,
              36.090898
            ],
            [
              -83.052177,
              36.077899
            ],
            [
              -83.007275,
              36.0398
            ],
            [
              -82.957228,
              36.0001
            ],
            [
              -82.8984628586303,
              35.945088097924696
            ],
            [
              -82.896947,
              35.944624
            ],
            [
              -82.892659,
              35.945182
            ],
            [
              -82.883933,
              35.949192
            ],
            [
              -82.874159,
              35.952698
            ],
            [
              -82.870666,
              35.95199
            ],
            [
              -82.869315,
              35.950565
            ],
            [
              -82.860724,
              35.94743
            ],
            [
              -82.852554,
              35.949089
            ],
            [
              -82.849849,
              35.947772
            ],
            [
              -82.841259,
              35.941721
            ],
            [
              -82.839994,
              35.940166
            ],
            [
              -82.833268,
              35.934993
            ],
            [
              -82.830112,
              35.932972
            ],
            [
              -82.828933,
              35.932932
            ],
            [
              -82.826045,
              35.929721
            ],
            [
              -82.82257,
              35.922531
            ],
            [
              -82.821861,
              35.921839
            ],
            [
              -82.81613,
              35.923986
            ],
            [
              -82.811067,
              35.926801
            ],
            [
              -82.809038,
              35.927241
            ],
            [
              -82.804997,
              35.927168
            ],
            [
              -82.802892,
              35.929013
            ],
            [
              -82.802769,
              35.930129
            ],
            [
              -82.805771,
              35.935316
            ],
            [
              -82.806174,
              35.936908
            ],
            [
              -82.805851,
              35.937938
            ],
            [
              -82.800431,
              35.944155
            ],
            [
              -82.787465,
              35.952163
            ],
            [
              -82.785191,
              35.959231
            ],
            [
              -82.785356,
              35.96253
            ],
            [
              -82.784536,
              35.963905
            ],
            [
              -82.783085,
              35.964982
            ],
            [
              -82.777751,
              35.966912
            ],
            [
              -82.774905,
              35.971978
            ],
            [
              -82.776434,
              35.973886
            ],
            [
              -82.778625,
              35.974792
            ],
            [
              -82.780319,
              35.974365
            ],
            [
              -82.781809,
              35.974562
            ],
            [
              -82.785558,
              35.977795
            ],
            [
              -82.7852918582795,
              35.987061487670005
            ],
            [
              -82.785267,
              35.987927
            ],
            [
              -82.779397,
              35.992511
            ],
            [
              -82.778589,
              35.997001
            ],
            [
              -82.777283,
              35.998811
            ],
            [
              -82.776001,
              36.000103
            ],
            [
              -82.765365,
              36.003003
            ],
            [
              -82.759165,
              36.004203
            ],
            [
              -82.754465,
              36.004304
            ],
            [
              -82.750065,
              36.006004
            ],
            [
              -82.731865,
              36.017604
            ],
            [
              -82.727865,
              36.018504
            ],
            [
              -82.725065,
              36.018204
            ],
            [
              -82.715965,
              36.022804
            ],
            [
              -82.715365,
              36.024253
            ],
            [
              -82.715565,
              36.026904
            ],
            [
              -82.715165,
              36.028604
            ],
            [
              -82.707465,
              36.030104
            ],
            [
              -82.703165,
              36.032404
            ],
            [
              -82.701065,
              36.034404
            ],
            [
              -82.688865,
              36.038604
            ],
            [
              -82.685565,
              36.042004
            ],
            [
              -82.684765,
              36.045004
            ],
            [
              -82.683565,
              36.046104
            ],
            [
              -82.672965,
              36.050405
            ],
            [
              -82.668365,
              36.052905
            ],
            [
              -82.662665,
              36.055005
            ],
            [
              -82.657249,
              36.056636
            ],
            [
              -82.654815,
              36.056225
            ],
            [
              -82.650165,
              36.057805
            ],
            [
              -82.643565,
              36.062805
            ],
            [
              -82.637165,
              36.065805
            ],
            [
              -82.632265,
              36.065705
            ],
            [
              -82.628365,
              36.062105
            ],
            [
              -82.618664,
              36.056105
            ],
            [
              -82.617264,
              36.054205
            ],
            [
              -82.618064,
              36.051205
            ],
            [
              -82.618164,
              36.047005
            ],
            [
              -82.613563,
              36.046406
            ],
            [
              -82.609663,
              36.044906
            ],
            [
              -82.606163,
              36.041006
            ],
            [
              -82.602878533975,
              36.0398335475815
            ],
            [
              -82.5772,
              36.078387
            ],
            [
              -82.595065,
              36.096205
            ],
            [
              -82.617866,
              36.118847
            ],
            [
              -82.642889,
              36.142067
            ],
            [
              -82.647769,
              36.146404
            ],
            [
              -82.629641,
              36.41154
            ],
            [
              -82.631794,
              36.418769
            ],
            [
              -82.702566,
              36.410412
            ],
            [
              -82.726879,
              36.4045
            ],
            [
              -82.75148,
              36.3858
            ],
            [
              -82.779718,
              36.388243
            ],
            [
              -82.814981,
              36.363899
            ],
            [
              -82.839581,
              36.370398
            ],
            [
              -82.885082,
              36.353398
            ],
            [
              -82.915482,
              36.328698
            ],
            [
              -82.937183,
              36.334797
            ],
            [
              -82.967583,
              36.316397
            ],
            [
              -82.991883,
              36.288397
            ],
            [
              -83.021483,
              36.269697
            ],
            [
              -83.031705,
              36.276696
            ],
            [
              -83.073679,
              36.241932
            ],
            [
              -83.082312,
              36.242296
            ],
            [
              -83.111954,
              36.220759
            ],
            [
              -83.14219,
              36.18739
            ],
            [
              -83.166085,
              36.180894
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "049",
      "COUNTYNS": "01639743",
      "AFFGEOID": "0500000US47049",
      "GEOID": "47049",
      "NAME": "Fentress",
      "LSAD": "06",
      "ALAND": 1291397316,
      "AWATER": 829331,
      "County_state": "Fentress,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.121757,
              36.328401
            ],
            [
              -85.122245,
              36.304599
            ],
            [
              -85.082497,
              36.196171
            ],
            [
              -85.119583,
              36.144719
            ],
            [
              -85.100213,
              36.138561
            ],
            [
              -85.081654,
              36.132789
            ],
            [
              -85.083227,
              36.136548
            ],
            [
              -85.056614,
              36.153455
            ],
            [
              -85.029461,
              36.170433
            ],
            [
              -85.004809,
              36.153507
            ],
            [
              -84.995149,
              36.160493
            ],
            [
              -84.970144,
              36.157865
            ],
            [
              -84.958954,
              36.147869
            ],
            [
              -84.937109,
              36.154898
            ],
            [
              -84.933157,
              36.146259
            ],
            [
              -84.907753,
              36.156293
            ],
            [
              -84.899747,
              36.172665
            ],
            [
              -84.910459,
              36.186363
            ],
            [
              -84.914155,
              36.20829
            ],
            [
              -84.883816,
              36.239215
            ],
            [
              -84.875842,
              36.285092
            ],
            [
              -84.855634,
              36.293712
            ],
            [
              -84.84294,
              36.286997
            ],
            [
              -84.82224,
              36.299513
            ],
            [
              -84.815003,
              36.293392
            ],
            [
              -84.797861,
              36.296894
            ],
            [
              -84.785908,
              36.32579
            ],
            [
              -84.774787,
              36.33365
            ],
            [
              -84.762027,
              36.32848
            ],
            [
              -84.739671,
              36.337932
            ],
            [
              -84.745486,
              36.351083
            ],
            [
              -84.731598,
              36.350655
            ],
            [
              -84.72468,
              36.376295
            ],
            [
              -84.700785,
              36.370824
            ],
            [
              -84.685913,
              36.388016
            ],
            [
              -84.659082,
              36.395227
            ],
            [
              -84.692534,
              36.435468
            ],
            [
              -84.715139,
              36.498874
            ],
            [
              -84.731839,
              36.524674
            ],
            [
              -84.784041,
              36.524973
            ],
            [
              -84.81804,
              36.550073
            ],
            [
              -84.862343,
              36.569871
            ],
            [
              -84.899845,
              36.57827
            ],
            [
              -84.943144,
              36.58127
            ],
            [
              -84.976047,
              36.574368
            ],
            [
              -84.999151,
              36.565862
            ],
            [
              -85.010347,
              36.573957
            ],
            [
              -85.012828,
              36.565541
            ],
            [
              -85.038773,
              36.554137
            ],
            [
              -85.043924,
              36.558791
            ],
            [
              -85.053558,
              36.523289
            ],
            [
              -85.064608,
              36.52766
            ],
            [
              -85.088459,
              36.500065
            ],
            [
              -85.102839,
              36.409939
            ],
            [
              -85.120445,
              36.407164
            ],
            [
              -85.118899,
              36.399812
            ],
            [
              -85.121757,
              36.328401
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "133",
      "COUNTYNS": "01639780",
      "AFFGEOID": "0500000US47133",
      "GEOID": "47133",
      "NAME": "Overton",
      "LSAD": "06",
      "ALAND": 1122721529,
      "AWATER": 3492728,
      "County_state": "Overton,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.495422,
              36.403074
            ],
            [
              -85.497587,
              36.375051
            ],
            [
              -85.495124,
              36.302278
            ],
            [
              -85.458729,
              36.303986
            ],
            [
              -85.463475,
              36.293476
            ],
            [
              -85.453956,
              36.280943
            ],
            [
              -85.466011,
              36.279383
            ],
            [
              -85.45365,
              36.27684
            ],
            [
              -85.452888,
              36.26146
            ],
            [
              -85.435652,
              36.262233
            ],
            [
              -85.43585,
              36.247148
            ],
            [
              -85.419432,
              36.236145
            ],
            [
              -85.365625,
              36.220588
            ],
            [
              -85.326706,
              36.217867
            ],
            [
              -85.322888,
              36.19968
            ],
            [
              -85.204156,
              36.152489
            ],
            [
              -85.119583,
              36.144719
            ],
            [
              -85.082497,
              36.196171
            ],
            [
              -85.122245,
              36.304599
            ],
            [
              -85.121757,
              36.328401
            ],
            [
              -85.118899,
              36.399812
            ],
            [
              -85.120445,
              36.407164
            ],
            [
              -85.135101,
              36.406991
            ],
            [
              -85.146916,
              36.435771
            ],
            [
              -85.147937,
              36.456164
            ],
            [
              -85.185359,
              36.490359
            ],
            [
              -85.223273,
              36.515015
            ],
            [
              -85.249954,
              36.523114
            ],
            [
              -85.269219,
              36.540265
            ],
            [
              -85.284878,
              36.531293
            ],
            [
              -85.299,
              36.522286
            ],
            [
              -85.337916,
              36.527845
            ],
            [
              -85.389937,
              36.505639
            ],
            [
              -85.383184,
              36.488784
            ],
            [
              -85.400769,
              36.491292
            ],
            [
              -85.392619,
              36.504484
            ],
            [
              -85.446372,
              36.49797
            ],
            [
              -85.481736,
              36.454309
            ],
            [
              -85.495422,
              36.403074
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "131",
      "COUNTYNS": "01639779",
      "AFFGEOID": "0500000US47131",
      "GEOID": "47131",
      "NAME": "Obion",
      "LSAD": "06",
      "ALAND": 1411163261,
      "AWATER": 27434034,
      "County_state": "Obion,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.485818,
              36.220931
            ],
            [
              -89.482971,
              36.212659
            ],
            [
              -89.154464,
              36.204678
            ],
            [
              -88.961232,
              36.201635
            ],
            [
              -88.959839,
              36.222834
            ],
            [
              -88.951171,
              36.389024
            ],
            [
              -88.947172,
              36.411939
            ],
            [
              -88.91181,
              36.405383
            ],
            [
              -88.897938,
              36.393826
            ],
            [
              -88.886352,
              36.39286
            ],
            [
              -88.864789,
              36.406822
            ],
            [
              -88.846523,
              36.413163
            ],
            [
              -88.81642,
              36.410732
            ],
            [
              -88.814016,
              36.470802
            ],
            [
              -88.82730115556168,
              36.502852430516896
            ],
            [
              -88.834626,
              36.502914
            ],
            [
              -88.834865800811,
              36.5029112012574
            ],
            [
              -88.874725,
              36.502446
            ],
            [
              -88.884557,
              36.501704
            ],
            [
              -88.89951,
              36.502218
            ],
            [
              -88.964471,
              36.502191
            ],
            [
              -89.000063,
              36.502633
            ],
            [
              -89.006825,
              36.502684
            ],
            [
              -89.010439,
              36.50271
            ],
            [
              -89.0105563150147,
              36.5027112308143
            ],
            [
              -89.034649,
              36.502964
            ],
            [
              -89.058871,
              36.503157
            ],
            [
              -89.072118,
              36.503249
            ],
            [
              -89.090146,
              36.503392
            ],
            [
              -89.117537,
              36.503603
            ],
            [
              -89.119805,
              36.503647
            ],
            [
              -89.163224,
              36.504522
            ],
            [
              -89.163429,
              36.504526
            ],
            [
              -89.211409,
              36.50563
            ],
            [
              -89.279091,
              36.506511
            ],
            [
              -89.282298,
              36.506782
            ],
            [
              -89.300284,
              36.507147
            ],
            [
              -89.346053,
              36.50321
            ],
            [
              -89.34605560857419,
              36.503209748794795
            ],
            [
              -89.340395,
              36.475967
            ],
            [
              -89.36005,
              36.456487
            ],
            [
              -89.369237,
              36.434289
            ],
            [
              -89.36012,
              36.409277
            ],
            [
              -89.380643,
              36.377103
            ],
            [
              -89.403834,
              36.352001
            ],
            [
              -89.400576,
              36.331773
            ],
            [
              -89.404284,
              36.313674
            ],
            [
              -89.4162,
              36.292316
            ],
            [
              -89.428987,
              36.259334
            ],
            [
              -89.444587,
              36.235538
            ],
            [
              -89.453643,
              36.228088
            ],
            [
              -89.485818,
              36.220931
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "009",
      "COUNTYNS": "01639726",
      "AFFGEOID": "0500000US47009",
      "GEOID": "47009",
      "NAME": "Blount",
      "LSAD": "06",
      "ALAND": 1447309647,
      "AWATER": 20304906,
      "County_state": "Blount,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.188319,
              35.610549
            ],
            [
              -84.181868,
              35.598892
            ],
            [
              -84.1595,
              35.592312
            ],
            [
              -84.130953,
              35.576313
            ],
            [
              -84.112319,
              35.587456
            ],
            [
              -84.106966,
              35.573537
            ],
            [
              -84.096169,
              35.575487
            ],
            [
              -84.087768,
              35.559875
            ],
            [
              -84.094807,
              35.55332
            ],
            [
              -84.081561,
              35.541231
            ],
            [
              -84.06809,
              35.546393
            ],
            [
              -84.042972,
              35.542175
            ],
            [
              -83.995926,
              35.55294
            ],
            [
              -83.992453,
              35.530721
            ],
            [
              -84.01091,
              35.516114
            ],
            [
              -83.985531,
              35.486803
            ],
            [
              -83.975855,
              35.500685
            ],
            [
              -83.957674,
              35.489674
            ],
            [
              -83.93854,
              35.487773
            ],
            [
              -83.965151,
              35.471117
            ],
            [
              -83.9610558840119,
              35.463753658400094
            ],
            [
              -83.961053,
              35.464143
            ],
            [
              -83.957821,
              35.464211
            ],
            [
              -83.955416,
              35.463456
            ],
            [
              -83.952882,
              35.460635
            ],
            [
              -83.949389,
              35.461164
            ],
            [
              -83.942987,
              35.465084
            ],
            [
              -83.942172,
              35.467254
            ],
            [
              -83.937015,
              35.471511
            ],
            [
              -83.929743,
              35.47333
            ],
            [
              -83.924895,
              35.473884
            ],
            [
              -83.919564,
              35.473367
            ],
            [
              -83.916801,
              35.473612
            ],
            [
              -83.911773,
              35.476028
            ],
            [
              -83.909265,
              35.479714
            ],
            [
              -83.90804,
              35.484397
            ],
            [
              -83.905612,
              35.48906
            ],
            [
              -83.901527,
              35.491026
            ],
            [
              -83.900338,
              35.493095
            ],
            [
              -83.9002,
              35.494191
            ],
            [
              -83.901403,
              35.495278
            ],
            [
              -83.901381,
              35.496553
            ],
            [
              -83.895669,
              35.501868
            ],
            [
              -83.893031,
              35.502253
            ],
            [
              -83.892074,
              35.503089
            ],
            [
              -83.884262,
              35.512754
            ],
            [
              -83.882563,
              35.517182
            ],
            [
              -83.880074,
              35.518745
            ],
            [
              -83.87263,
              35.521145
            ],
            [
              -83.866413,
              35.52091
            ],
            [
              -83.859261,
              35.521851
            ],
            [
              -83.853898,
              35.521059
            ],
            [
              -83.848502,
              35.519259
            ],
            [
              -83.840203,
              35.52156
            ],
            [
              -83.831895,
              35.524766
            ],
            [
              -83.827428,
              35.524653
            ],
            [
              -83.82559,
              35.523829
            ],
            [
              -83.809798,
              35.53431
            ],
            [
              -83.808713,
              35.536415
            ],
            [
              -83.802434,
              35.541588
            ],
            [
              -83.786802,
              35.5472
            ],
            [
              -83.780129,
              35.550387
            ],
            [
              -83.773092,
              35.557465
            ],
            [
              -83.771736,
              35.562118
            ],
            [
              -83.764606,
              35.561538
            ],
            [
              -83.759675,
              35.562492
            ],
            [
              -83.756917,
              35.563604
            ],
            [
              -83.749894,
              35.561146
            ],
            [
              -83.735669,
              35.565455
            ],
            [
              -83.732947,
              35.563149
            ],
            [
              -83.723459,
              35.561874
            ],
            [
              -83.720787,
              35.563347
            ],
            [
              -83.707199,
              35.568533
            ],
            [
              -83.703846,
              35.568476
            ],
            [
              -83.702099,
              35.567634
            ],
            [
              -83.700663,
              35.567621
            ],
            [
              -83.697827,
              35.568352
            ],
            [
              -83.684154,
              35.568848
            ],
            [
              -83.676268,
              35.570289
            ],
            [
              -83.673093,
              35.568974
            ],
            [
              -83.666272,
              35.569389
            ],
            [
              -83.66302360458259,
              35.5691430430619
            ],
            [
              -83.662814,
              35.690618
            ],
            [
              -83.674272,
              35.685497
            ],
            [
              -83.682161,
              35.709772
            ],
            [
              -83.70525,
              35.71158
            ],
            [
              -83.701344,
              35.719276
            ],
            [
              -83.716462,
              35.729035
            ],
            [
              -83.718914,
              35.747726
            ],
            [
              -83.747317,
              35.798398
            ],
            [
              -83.739518,
              35.806815
            ],
            [
              -83.757287,
              35.817528
            ],
            [
              -83.794275,
              35.887125
            ],
            [
              -83.810331,
              35.879349
            ],
            [
              -83.812319,
              35.875587
            ],
            [
              -83.852047,
              35.841349
            ],
            [
              -83.907542,
              35.846626
            ],
            [
              -83.945692,
              35.876136
            ],
            [
              -83.975423,
              35.863416
            ],
            [
              -83.985899,
              35.876879
            ],
            [
              -84.028362,
              35.876027
            ],
            [
              -84.011382,
              35.865317
            ],
            [
              -84.009845,
              35.843706
            ],
            [
              -84.023374,
              35.840504
            ],
            [
              -84.033714,
              35.853732
            ],
            [
              -84.049927,
              35.851336
            ],
            [
              -84.050317,
              35.833051
            ],
            [
              -84.067868,
              35.831638
            ],
            [
              -84.079979,
              35.851252
            ],
            [
              -84.124611,
              35.83151
            ],
            [
              -84.122981,
              35.844395
            ],
            [
              -84.145659,
              35.849586
            ],
            [
              -84.134538,
              35.835132
            ],
            [
              -84.142596,
              35.818533
            ],
            [
              -84.144301,
              35.795763
            ],
            [
              -84.159661,
              35.795196
            ],
            [
              -84.166267,
              35.805106
            ],
            [
              -84.172745,
              35.804419
            ],
            [
              -84.179499,
              35.768449
            ],
            [
              -84.151955,
              35.757554
            ],
            [
              -84.167955,
              35.741589
            ],
            [
              -84.172812,
              35.712756
            ],
            [
              -84.162524,
              35.698224
            ],
            [
              -84.153818,
              35.673866
            ],
            [
              -84.133222,
              35.660392
            ],
            [
              -84.134787,
              35.649061
            ],
            [
              -84.151652,
              35.627498
            ],
            [
              -84.188319,
              35.610549
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "017",
      "COUNTYNS": "01639729",
      "AFFGEOID": "0500000US47017",
      "GEOID": "47017",
      "NAME": "Carroll",
      "LSAD": "06",
      "ALAND": 1547925975,
      "AWATER": 6308712,
      "County_state": "Carroll,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.706002,
              35.796042
            ],
            [
              -88.706811,
              35.791075
            ],
            [
              -88.606862,
              35.789354
            ],
            [
              -88.599627,
              35.801785
            ],
            [
              -88.56955,
              35.824451
            ],
            [
              -88.179177,
              35.816785
            ],
            [
              -88.177859,
              35.845841
            ],
            [
              -88.217436,
              35.846582
            ],
            [
              -88.214442,
              35.982752
            ],
            [
              -88.212559,
              36.12029
            ],
            [
              -88.211698,
              36.145974
            ],
            [
              -88.473272,
              36.149907
            ],
            [
              -88.488688,
              36.150977
            ],
            [
              -88.530322,
              36.152023
            ],
            [
              -88.532233,
              36.115416
            ],
            [
              -88.655488,
              36.117338
            ],
            [
              -88.653843,
              36.064294
            ],
            [
              -88.678381,
              36.066496
            ],
            [
              -88.692709,
              36.062746
            ],
            [
              -88.695417,
              36.00181
            ],
            [
              -88.706002,
              35.796042
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "079",
      "COUNTYNS": "01639755",
      "AFFGEOID": "0500000US47079",
      "GEOID": "47079",
      "NAME": "Henry",
      "LSAD": "06",
      "ALAND": 1455159718,
      "AWATER": 81742881,
      "County_state": "Henry,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.5163456859488,
              36.5014314866172
            ],
            [
              -88.530322,
              36.152023
            ],
            [
              -88.488688,
              36.150977
            ],
            [
              -88.473272,
              36.149907
            ],
            [
              -88.211698,
              36.145974
            ],
            [
              -88.212559,
              36.12029
            ],
            [
              -88.176502,
              36.147673
            ],
            [
              -88.172691,
              36.16745
            ],
            [
              -88.139206,
              36.201723
            ],
            [
              -88.135824,
              36.218678
            ],
            [
              -88.113408,
              36.223251
            ],
            [
              -88.10068,
              36.233531
            ],
            [
              -88.100827,
              36.25415
            ],
            [
              -88.082929,
              36.260107
            ],
            [
              -88.087082,
              36.267898
            ],
            [
              -88.090782,
              36.29485
            ],
            [
              -88.10266,
              36.310933
            ],
            [
              -88.102109,
              36.326973
            ],
            [
              -88.092269,
              36.340315
            ],
            [
              -88.095868,
              36.359265
            ],
            [
              -88.031779,
              36.359809
            ],
            [
              -87.993717,
              36.359022
            ],
            [
              -87.99092,
              36.360133
            ],
            [
              -88.035982,
              36.420317
            ],
            [
              -88.061428,
              36.443992
            ],
            [
              -88.052006,
              36.471265
            ],
            [
              -88.0532916180151,
              36.4971306477427
            ],
            [
              -88.127378,
              36.49854
            ],
            [
              -88.320794,
              36.500432
            ],
            [
              -88.325895,
              36.500483
            ],
            [
              -88.330799,
              36.500531
            ],
            [
              -88.41636,
              36.500756
            ],
            [
              -88.450161,
              36.501101
            ],
            [
              -88.452543,
              36.500872
            ],
            [
              -88.472564,
              36.501028
            ],
            [
              -88.4890752146435,
              36.5010676761135
            ],
            [
              -88.48921,
              36.501068
            ],
            [
              -88.51192,
              36.501457
            ],
            [
              -88.51227,
              36.501506
            ],
            [
              -88.5163456859488,
              36.5014314866172
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "013",
      "COUNTYNS": "01639728",
      "AFFGEOID": "0500000US47013",
      "GEOID": "47013",
      "NAME": "Campbell",
      "LSAD": "06",
      "ALAND": 1243615773,
      "AWATER": 46494677,
      "County_state": "Campbell,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.376133,
              36.260079
            ],
            [
              -84.372731,
              36.216778
            ],
            [
              -84.346731,
              36.215078
            ],
            [
              -84.33073,
              36.208078
            ],
            [
              -84.301429,
              36.220978
            ],
            [
              -84.237125,
              36.178678
            ],
            [
              -84.230325,
              36.182078
            ],
            [
              -84.226326,
              36.216479
            ],
            [
              -84.227527,
              36.244879
            ],
            [
              -84.17348,
              36.240068
            ],
            [
              -84.140125,
              36.233676
            ],
            [
              -84.110421,
              36.233878
            ],
            [
              -84.102691,
              36.224424
            ],
            [
              -84.069918,
              36.230378
            ],
            [
              -84.072135,
              36.252264
            ],
            [
              -84.057119,
              36.250578
            ],
            [
              -84.055419,
              36.263678
            ],
            [
              -84.041419,
              36.275278
            ],
            [
              -84.032519,
              36.301779
            ],
            [
              -84.026519,
              36.297079
            ],
            [
              -84.021518,
              36.272278
            ],
            [
              -84.004317,
              36.270378
            ],
            [
              -83.997617,
              36.282678
            ],
            [
              -83.985717,
              36.281478
            ],
            [
              -83.974017,
              36.298278
            ],
            [
              -83.968418,
              36.354578
            ],
            [
              -83.949918,
              36.368978
            ],
            [
              -83.954908,
              36.387623
            ],
            [
              -83.950919,
              36.401278
            ],
            [
              -83.921217,
              36.387879
            ],
            [
              -83.902717,
              36.405379
            ],
            [
              -83.905638,
              36.419979
            ],
            [
              -83.899796,
              36.42315
            ],
            [
              -83.922618,
              36.427779
            ],
            [
              -83.943139,
              36.473564
            ],
            [
              -83.95696,
              36.501555
            ],
            [
              -83.984634,
              36.560482
            ],
            [
              -83.982492,
              36.585315
            ],
            [
              -83.9876109506828,
              36.589592286072495
            ],
            [
              -83.987842,
              36.5896
            ],
            [
              -84.127503,
              36.591413
            ],
            [
              -84.2271936534631,
              36.5921799356786
            ],
            [
              -84.227332,
              36.592181
            ],
            [
              -84.261333,
              36.591981
            ],
            [
              -84.26133898080309,
              36.5919809398456
            ],
            [
              -84.263733,
              36.56668
            ],
            [
              -84.249231,
              36.564684
            ],
            [
              -84.251132,
              36.53418
            ],
            [
              -84.266877,
              36.511698
            ],
            [
              -84.286533,
              36.50388
            ],
            [
              -84.330434,
              36.50948
            ],
            [
              -84.341329,
              36.465579
            ],
            [
              -84.349939,
              36.466292
            ],
            [
              -84.344701,
              36.415677
            ],
            [
              -84.312086,
              36.384573
            ],
            [
              -84.334433,
              36.388079
            ],
            [
              -84.329927,
              36.285086
            ],
            [
              -84.333921,
              36.277988
            ],
            [
              -84.362333,
              36.271579
            ],
            [
              -84.376133,
              36.260079
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "089",
      "COUNTYNS": "01639759",
      "AFFGEOID": "0500000US47089",
      "GEOID": "47089",
      "NAME": "Jefferson",
      "LSAD": "06",
      "ALAND": 711873311,
      "AWATER": 102176879,
      "County_state": "Jefferson,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.70925,
              36.093234
            ],
            [
              -83.694599,
              36.058249
            ],
            [
              -83.671169,
              36.053105
            ],
            [
              -83.673297,
              36.038486
            ],
            [
              -83.65636,
              36.034589
            ],
            [
              -83.655748,
              36.042966
            ],
            [
              -83.607494,
              36.015685
            ],
            [
              -83.525477,
              35.964557
            ],
            [
              -83.508088,
              35.938832
            ],
            [
              -83.477139,
              35.930126
            ],
            [
              -83.474901,
              35.922893
            ],
            [
              -83.444352,
              35.928915
            ],
            [
              -83.435405,
              35.923727
            ],
            [
              -83.415994,
              35.933231
            ],
            [
              -83.389133,
              35.92822
            ],
            [
              -83.387457,
              35.913605
            ],
            [
              -83.359949,
              35.919011
            ],
            [
              -83.330258,
              35.911609
            ],
            [
              -83.310782,
              35.895846
            ],
            [
              -83.297853,
              35.901516
            ],
            [
              -83.276677,
              36.007652
            ],
            [
              -83.262056,
              35.997425
            ],
            [
              -83.256686,
              36.044321
            ],
            [
              -83.240085,
              36.057295
            ],
            [
              -83.253886,
              36.082194
            ],
            [
              -83.234585,
              36.085094
            ],
            [
              -83.241986,
              36.101294
            ],
            [
              -83.274501,
              36.130794
            ],
            [
              -83.293689,
              36.138193
            ],
            [
              -83.372994,
              36.153792
            ],
            [
              -83.426,
              36.15455
            ],
            [
              -83.458695,
              36.155289
            ],
            [
              -83.467097,
              36.174688
            ],
            [
              -83.483497,
              36.167088
            ],
            [
              -83.490697,
              36.166588
            ],
            [
              -83.515524,
              36.178053
            ],
            [
              -83.528698,
              36.162487
            ],
            [
              -83.541099,
              36.181987
            ],
            [
              -83.5591,
              36.180486
            ],
            [
              -83.5673,
              36.190086
            ],
            [
              -83.564399,
              36.166486
            ],
            [
              -83.582978,
              36.16622
            ],
            [
              -83.588666,
              36.154942
            ],
            [
              -83.597766,
              36.164182
            ],
            [
              -83.604223,
              36.138299
            ],
            [
              -83.637201,
              36.144385
            ],
            [
              -83.638938,
              36.128592
            ],
            [
              -83.627722,
              36.122976
            ],
            [
              -83.62892,
              36.104532
            ],
            [
              -83.641323,
              36.09707
            ],
            [
              -83.654559,
              36.105672
            ],
            [
              -83.651522,
              36.125151
            ],
            [
              -83.674237,
              36.125008
            ],
            [
              -83.674454,
              36.116399
            ],
            [
              -83.659387,
              36.085533
            ],
            [
              -83.66746,
              36.080587
            ],
            [
              -83.691292,
              36.09463
            ],
            [
              -83.70925,
              36.093234
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "041",
      "COUNTYNS": "01639738",
      "AFFGEOID": "0500000US47041",
      "GEOID": "47041",
      "NAME": "DeKalb",
      "LSAD": "06",
      "ALAND": 788354549,
      "AWATER": 63715775,
      "County_state": "DeKalb,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.059706,
              36.086024
            ],
            [
              -86.063413,
              36.070052
            ],
            [
              -86.047374,
              36.057493
            ],
            [
              -86.053437,
              36.043793
            ],
            [
              -86.035778,
              36.031399
            ],
            [
              -86.040746,
              36.008765
            ],
            [
              -86.026513,
              35.998561
            ],
            [
              -86.014849,
              35.96106
            ],
            [
              -85.997022,
              35.952866
            ],
            [
              -85.91612,
              35.879254
            ],
            [
              -85.88921,
              35.857543
            ],
            [
              -85.885156,
              35.839658
            ],
            [
              -85.875877,
              35.845776
            ],
            [
              -85.787534,
              35.843036
            ],
            [
              -85.751785,
              35.850027
            ],
            [
              -85.682095,
              35.831254
            ],
            [
              -85.653837,
              35.827905
            ],
            [
              -85.632449,
              35.847385
            ],
            [
              -85.634803,
              35.853897
            ],
            [
              -85.672806,
              35.856527
            ],
            [
              -85.637327,
              35.881824
            ],
            [
              -85.644926,
              35.889541
            ],
            [
              -85.66813,
              35.883659
            ],
            [
              -85.651662,
              35.904993
            ],
            [
              -85.662917,
              35.971501
            ],
            [
              -85.630384,
              36.006521
            ],
            [
              -85.644604,
              36.015053
            ],
            [
              -85.653444,
              36.012867
            ],
            [
              -85.668236,
              36.029841
            ],
            [
              -85.686743,
              36.064802
            ],
            [
              -85.722648,
              36.081986
            ],
            [
              -85.747144,
              36.07743
            ],
            [
              -85.753054,
              36.067985
            ],
            [
              -85.767136,
              36.070087
            ],
            [
              -85.775976,
              36.078328
            ],
            [
              -85.769835,
              36.094981
            ],
            [
              -85.787958,
              36.102961
            ],
            [
              -85.807415,
              36.131582
            ],
            [
              -85.828647,
              36.122639
            ],
            [
              -85.83827,
              36.128855
            ],
            [
              -85.864024,
              36.093809
            ],
            [
              -85.894357,
              36.104963
            ],
            [
              -85.896938,
              36.116275
            ],
            [
              -85.910118,
              36.116925
            ],
            [
              -85.911267,
              36.097528
            ],
            [
              -85.93789,
              36.109093
            ],
            [
              -85.948414,
              36.101991
            ],
            [
              -85.959279,
              36.110621
            ],
            [
              -85.986083,
              36.087095
            ],
            [
              -86.012283,
              36.09004
            ],
            [
              -86.013396,
              36.083446
            ],
            [
              -86.042684,
              36.089279
            ],
            [
              -86.059706,
              36.086024
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "161",
      "COUNTYNS": "01639792",
      "AFFGEOID": "0500000US47161",
      "GEOID": "47161",
      "NAME": "Stewart",
      "LSAD": "06",
      "ALAND": 1189687496,
      "AWATER": 87209797,
      "County_state": "Stewart,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.070532,
              36.678118
            ],
            [
              -88.068208,
              36.659747
            ],
            [
              -88.064401,
              36.650854
            ],
            [
              -88.05858,
              36.643315
            ],
            [
              -88.057042,
              36.64054
            ],
            [
              -88.055604,
              36.63571
            ],
            [
              -88.055738,
              36.630475
            ],
            [
              -88.045127,
              36.602939
            ],
            [
              -88.041196,
              36.584123
            ],
            [
              -88.039625,
              36.572783
            ],
            [
              -88.035625,
              36.561736
            ],
            [
              -88.033802,
              36.551733
            ],
            [
              -88.032489,
              36.540662
            ],
            [
              -88.034132,
              36.53112
            ],
            [
              -88.035854,
              36.525912
            ],
            [
              -88.03727,
              36.523783
            ],
            [
              -88.03783,
              36.521015
            ],
            [
              -88.037329,
              36.51783
            ],
            [
              -88.037822,
              36.51385
            ],
            [
              -88.039481,
              36.510408
            ],
            [
              -88.045304,
              36.504081
            ],
            [
              -88.050466,
              36.500053
            ],
            [
              -88.053205,
              36.497129
            ],
            [
              -88.0532916180151,
              36.4971306477427
            ],
            [
              -88.052006,
              36.471265
            ],
            [
              -88.061428,
              36.443992
            ],
            [
              -88.035982,
              36.420317
            ],
            [
              -87.99092,
              36.360133
            ],
            [
              -87.98074,
              36.353307
            ],
            [
              -87.944984,
              36.354128
            ],
            [
              -87.892839,
              36.334712
            ],
            [
              -87.861571,
              36.329875
            ],
            [
              -87.821816,
              36.328724
            ],
            [
              -87.784438,
              36.334124
            ],
            [
              -87.756217,
              36.342993
            ],
            [
              -87.722804,
              36.360361
            ],
            [
              -87.715494,
              36.36824
            ],
            [
              -87.592358,
              36.367663
            ],
            [
              -87.6411458985761,
              36.6380360579522
            ],
            [
              -87.64115,
              36.638036
            ],
            [
              -87.69419132838901,
              36.6370710817038
            ],
            [
              -87.744768,
              36.636151
            ],
            [
              -87.853204,
              36.633247
            ],
            [
              -87.849567,
              36.663701
            ],
            [
              -87.872062,
              36.665089
            ],
            [
              -88.011792,
              36.677025
            ],
            [
              -88.044772,
              36.677983
            ],
            [
              -88.070532,
              36.678118
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "185",
      "COUNTYNS": "01639800",
      "AFFGEOID": "0500000US47185",
      "GEOID": "47185",
      "NAME": "White",
      "LSAD": "06",
      "ALAND": 975592111,
      "AWATER": 7113368,
      "County_state": "White,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.272664,
              35.787718
            ],
            [
              -85.273945,
              35.78365
            ],
            [
              -85.268056,
              35.779556
            ],
            [
              -85.26294,
              35.778764
            ],
            [
              -85.256846,
              35.780818
            ],
            [
              -85.255304,
              35.777754
            ],
            [
              -85.258988,
              35.777206
            ],
            [
              -85.255871,
              35.774128
            ],
            [
              -85.257842,
              35.771483
            ],
            [
              -85.253518,
              35.766927
            ],
            [
              -85.249965,
              35.777464
            ],
            [
              -85.247784,
              35.78666
            ],
            [
              -85.257467,
              35.787169
            ],
            [
              -85.26038,
              35.78258
            ],
            [
              -85.272664,
              35.787718
            ]
          ]
        ],
        [
          [
            [
              -85.682095,
              35.831254
            ],
            [
              -85.682602,
              35.827246
            ],
            [
              -85.641655,
              35.816717
            ],
            [
              -85.602876,
              35.794996
            ],
            [
              -85.601085,
              35.806515
            ],
            [
              -85.571577,
              35.806576
            ],
            [
              -85.543209,
              35.797325
            ],
            [
              -85.535043,
              35.799352
            ],
            [
              -85.519775,
              35.792077
            ],
            [
              -85.526581,
              35.807283
            ],
            [
              -85.517326,
              35.815815
            ],
            [
              -85.499398,
              35.806515
            ],
            [
              -85.49499,
              35.813035
            ],
            [
              -85.474213,
              35.822656
            ],
            [
              -85.464154,
              35.815161
            ],
            [
              -85.462996,
              35.822209
            ],
            [
              -85.44936,
              35.821559
            ],
            [
              -85.439527,
              35.815866
            ],
            [
              -85.423179,
              35.814691
            ],
            [
              -85.362267,
              35.796774
            ],
            [
              -85.265064,
              35.821973
            ],
            [
              -85.27749,
              35.809077
            ],
            [
              -85.269359,
              35.793388
            ],
            [
              -85.248435,
              35.798748
            ],
            [
              -85.24138,
              35.809495
            ],
            [
              -85.222524,
              35.877364
            ],
            [
              -85.231587,
              35.893201
            ],
            [
              -85.214506,
              35.898971
            ],
            [
              -85.213115,
              35.910379
            ],
            [
              -85.242698,
              35.91304
            ],
            [
              -85.265528,
              35.965831
            ],
            [
              -85.264206,
              35.979154
            ],
            [
              -85.311302,
              35.992997
            ],
            [
              -85.309363,
              36.031592
            ],
            [
              -85.326278,
              36.030227
            ],
            [
              -85.372181,
              36.052388
            ],
            [
              -85.395356,
              36.055085
            ],
            [
              -85.507677,
              36.082012
            ],
            [
              -85.560459,
              36.073471
            ],
            [
              -85.570285,
              36.058826
            ],
            [
              -85.579087,
              36.063246
            ],
            [
              -85.588672,
              36.040494
            ],
            [
              -85.611138,
              36.04605
            ],
            [
              -85.62498,
              36.031513
            ],
            [
              -85.643274,
              36.024191
            ],
            [
              -85.644604,
              36.015053
            ],
            [
              -85.630384,
              36.006521
            ],
            [
              -85.662917,
              35.971501
            ],
            [
              -85.651662,
              35.904993
            ],
            [
              -85.66813,
              35.883659
            ],
            [
              -85.644926,
              35.889541
            ],
            [
              -85.637327,
              35.881824
            ],
            [
              -85.672806,
              35.856527
            ],
            [
              -85.634803,
              35.853897
            ],
            [
              -85.632449,
              35.847385
            ],
            [
              -85.653837,
              35.827905
            ],
            [
              -85.682095,
              35.831254
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "085",
      "COUNTYNS": "01639757",
      "AFFGEOID": "0500000US47085",
      "GEOID": "47085",
      "NAME": "Humphreys",
      "LSAD": "06",
      "ALAND": 1374668747,
      "AWATER": 67184107,
      "County_state": "Humphreys,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.020424,
              35.979967
            ],
            [
              -88.02239,
              35.965818
            ],
            [
              -88.012932,
              35.946648
            ],
            [
              -87.974172,
              35.937213
            ],
            [
              -87.934623,
              35.940022
            ],
            [
              -87.926676,
              35.93557
            ],
            [
              -87.928847,
              35.905116
            ],
            [
              -87.940331,
              35.865986
            ],
            [
              -87.96274,
              35.840921
            ],
            [
              -87.910297,
              35.838336
            ],
            [
              -87.893127,
              35.824858
            ],
            [
              -87.852682,
              35.803799
            ],
            [
              -87.844142,
              35.820724
            ],
            [
              -87.825957,
              35.823921
            ],
            [
              -87.818738,
              35.836769
            ],
            [
              -87.71676,
              35.83875
            ],
            [
              -87.70696,
              35.905884
            ],
            [
              -87.683608,
              35.916076
            ],
            [
              -87.658423,
              35.915511
            ],
            [
              -87.609189,
              35.946391
            ],
            [
              -87.61186,
              35.949981
            ],
            [
              -87.587584,
              35.953378
            ],
            [
              -87.569304,
              35.947747
            ],
            [
              -87.532493,
              35.96946
            ],
            [
              -87.53453,
              35.993074
            ],
            [
              -87.552874,
              36.016237
            ],
            [
              -87.545298,
              36.046641
            ],
            [
              -87.544503,
              36.094277
            ],
            [
              -87.552182,
              36.137745
            ],
            [
              -87.566798,
              36.177857
            ],
            [
              -87.578359,
              36.183077
            ],
            [
              -87.585973,
              36.197699
            ],
            [
              -87.662191,
              36.201227
            ],
            [
              -87.697583,
              36.222763
            ],
            [
              -87.730969,
              36.235437
            ],
            [
              -87.758,
              36.241522
            ],
            [
              -87.798508,
              36.244754
            ],
            [
              -87.834641,
              36.232368
            ],
            [
              -87.949603,
              36.24316
            ],
            [
              -87.951645,
              36.222325
            ],
            [
              -87.940533,
              36.205379
            ],
            [
              -87.933436,
              36.18382
            ],
            [
              -87.915654,
              36.16075
            ],
            [
              -87.919043,
              36.130049
            ],
            [
              -87.937974,
              36.108856
            ],
            [
              -87.959738,
              36.075862
            ],
            [
              -87.989144,
              36.04669
            ],
            [
              -88.000809,
              36.028089
            ],
            [
              -87.99928,
              36.002666
            ],
            [
              -88.020424,
              35.979967
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "069",
      "COUNTYNS": "01639750",
      "AFFGEOID": "0500000US47069",
      "GEOID": "47069",
      "NAME": "Hardeman",
      "LSAD": "06",
      "ALAND": 1729448612,
      "AWATER": 6829952,
      "County_state": "Hardeman,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.197672,
              35.011822
            ],
            [
              -89.198288,
              34.994484
            ],
            [
              -89.156827,
              34.993926
            ],
            [
              -89.139136,
              34.994307
            ],
            [
              -89.138997,
              34.99433
            ],
            [
              -89.02654,
              34.994956
            ],
            [
              -89.0171270872889,
              34.9949454068841
            ],
            [
              -88.925241,
              34.994842
            ],
            [
              -88.886979,
              34.995868
            ],
            [
              -88.851037,
              34.995085
            ],
            [
              -88.8230487339323,
              34.9951575501037
            ],
            [
              -88.786612,
              34.995252
            ],
            [
              -88.786799,
              35.062521
            ],
            [
              -88.786729,
              35.125085
            ],
            [
              -88.781768,
              35.247587
            ],
            [
              -88.792777,
              35.329188
            ],
            [
              -88.842036,
              35.384447
            ],
            [
              -88.841607,
              35.427826
            ],
            [
              -88.893132,
              35.428645
            ],
            [
              -89.078876,
              35.431428
            ],
            [
              -89.182551,
              35.432816
            ],
            [
              -89.183944,
              35.397126
            ],
            [
              -89.197672,
              35.011822
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "019",
      "COUNTYNS": "01639730",
      "AFFGEOID": "0500000US47019",
      "GEOID": "47019",
      "NAME": "Carter",
      "LSAD": "06",
      "ALAND": 883865752,
      "AWATER": 16654827,
      "County_state": "Carter,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.342282,
              36.263977
            ],
            [
              -82.34046,
              36.252807
            ],
            [
              -82.319532,
              36.260365
            ],
            [
              -82.28477,
              36.251708
            ],
            [
              -82.256356,
              36.233208
            ],
            [
              -82.267955,
              36.210109
            ],
            [
              -82.247455,
              36.206809
            ],
            [
              -82.228953,
              36.19051
            ],
            [
              -82.222052,
              36.156911
            ],
            [
              -82.218451,
              36.157832
            ],
            [
              -82.213852,
              36.159112
            ],
            [
              -82.211251,
              36.159012
            ],
            [
              -82.204872,
              36.157067
            ],
            [
              -82.201812,
              36.154963
            ],
            [
              -82.199251,
              36.152713
            ],
            [
              -82.19535,
              36.150013
            ],
            [
              -82.19195,
              36.148813
            ],
            [
              -82.18785,
              36.147886
            ],
            [
              -82.18475,
              36.145414
            ],
            [
              -82.18365,
              36.144414
            ],
            [
              -82.182549,
              36.143714
            ],
            [
              -82.178861,
              36.143296
            ],
            [
              -82.17561,
              36.14387
            ],
            [
              -82.173849,
              36.145314
            ],
            [
              -82.172149,
              36.146414
            ],
            [
              -82.169249,
              36.146614
            ],
            [
              -82.160883,
              36.146548
            ],
            [
              -82.155948,
              36.148115
            ],
            [
              -82.147948,
              36.149516
            ],
            [
              -82.144147,
              36.144216
            ],
            [
              -82.140847,
              36.136216
            ],
            [
              -82.136547,
              36.128817
            ],
            [
              -82.136546,
              36.123717
            ],
            [
              -82.137974,
              36.119576
            ],
            [
              -82.130646,
              36.106417
            ],
            [
              -82.127146,
              36.104417
            ],
            [
              -82.115245,
              36.104618
            ],
            [
              -82.109145,
              36.107218
            ],
            [
              -82.105444,
              36.108119
            ],
            [
              -82.101644,
              36.106219
            ],
            [
              -82.098544,
              36.105719
            ],
            [
              -82.085943,
              36.10602
            ],
            [
              -82.080944957901,
              36.1057614805811
            ],
            [
              -82.080143,
              36.10572
            ],
            [
              -82.079743,
              36.10652
            ],
            [
              -82.067142,
              36.11202
            ],
            [
              -82.061342,
              36.113121
            ],
            [
              -82.056042,
              36.120721
            ],
            [
              -82.056042,
              36.123921
            ],
            [
              -82.054142,
              36.126821
            ],
            [
              -82.043941,
              36.125421
            ],
            [
              -82.037941,
              36.121122
            ],
            [
              -82.033141,
              36.120422
            ],
            [
              -82.02874,
              36.124322
            ],
            [
              -82.02634,
              36.129222
            ],
            [
              -82.02664,
              36.130222
            ],
            [
              -81.960101,
              36.228131
            ],
            [
              -81.938897,
              36.256067
            ],
            [
              -81.933019683861,
              36.264842650423404
            ],
            [
              -81.9515,
              36.261763
            ],
            [
              -81.982535,
              36.288073
            ],
            [
              -81.990228,
              36.315396
            ],
            [
              -82.002142,
              36.333419
            ],
            [
              -82.030543,
              36.333817
            ],
            [
              -82.042145,
              36.362816
            ],
            [
              -82.058946,
              36.367415
            ],
            [
              -82.032544,
              36.389416
            ],
            [
              -81.965978,
              36.462947
            ],
            [
              -81.94984,
              36.472385
            ],
            [
              -81.983843,
              36.508314
            ],
            [
              -81.9979,
              36.49271
            ],
            [
              -82.038546,
              36.486112
            ],
            [
              -82.078148,
              36.457611
            ],
            [
              -82.099049,
              36.45901
            ],
            [
              -82.107349,
              36.44971
            ],
            [
              -82.188653,
              36.400408
            ],
            [
              -82.201354,
              36.401107
            ],
            [
              -82.251866,
              36.420134
            ],
            [
              -82.288112,
              36.395323
            ],
            [
              -82.299859,
              36.396505
            ],
            [
              -82.299968,
              36.37114
            ],
            [
              -82.304354,
              36.357701
            ],
            [
              -82.315109,
              36.326356
            ],
            [
              -82.342282,
              36.263977
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "147",
      "COUNTYNS": "01639786",
      "AFFGEOID": "0500000US47147",
      "GEOID": "47147",
      "NAME": "Robertson",
      "LSAD": "06",
      "ALAND": 1233605511,
      "AWATER": 451691,
      "County_state": "Robertson,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.133119,
              36.562878
            ],
            [
              -87.150371,
              36.566824
            ],
            [
              -87.129672,
              36.546523
            ],
            [
              -87.118066,
              36.550057
            ],
            [
              -87.120657,
              36.510943
            ],
            [
              -87.128677,
              36.46195
            ],
            [
              -87.120443,
              36.45546
            ],
            [
              -87.09455,
              36.445039
            ],
            [
              -87.063143,
              36.421415
            ],
            [
              -87.005364,
              36.392392
            ],
            [
              -86.98806,
              36.369154
            ],
            [
              -86.962311,
              36.377128
            ],
            [
              -86.913233,
              36.382621
            ],
            [
              -86.894298,
              36.389097
            ],
            [
              -86.877174,
              36.372204
            ],
            [
              -86.854299,
              36.369796
            ],
            [
              -86.825053,
              36.349817
            ],
            [
              -86.787357,
              36.35964
            ],
            [
              -86.791034,
              36.37676
            ],
            [
              -86.781893,
              36.389588
            ],
            [
              -86.76433,
              36.394289
            ],
            [
              -86.754795,
              36.405496
            ],
            [
              -86.748276,
              36.418627
            ],
            [
              -86.735484,
              36.418279
            ],
            [
              -86.693292,
              36.407231
            ],
            [
              -86.695064,
              36.421319
            ],
            [
              -86.675239,
              36.450001
            ],
            [
              -86.651919,
              36.470051
            ],
            [
              -86.640766,
              36.497093
            ],
            [
              -86.635037,
              36.534041
            ],
            [
              -86.623389,
              36.549877
            ],
            [
              -86.602777,
              36.572757
            ],
            [
              -86.56391574841909,
              36.6335518059594
            ],
            [
              -86.564143,
              36.633472
            ],
            [
              -86.589906,
              36.652486
            ],
            [
              -86.605042,
              36.652125
            ],
            [
              -86.606394,
              36.652107
            ],
            [
              -86.713786,
              36.649341
            ],
            [
              -86.75892,
              36.649018
            ],
            [
              -86.7632948135703,
              36.648907168479305
            ],
            [
              -86.813037,
              36.647647
            ],
            [
              -86.816186,
              36.647722
            ],
            [
              -86.818405,
              36.647639
            ],
            [
              -86.833155,
              36.64721
            ],
            [
              -86.854268,
              36.646884
            ],
            [
              -86.906023,
              36.646302
            ],
            [
              -86.906583,
              36.646255
            ],
            [
              -87.011522,
              36.643949
            ],
            [
              -87.0608458150142,
              36.6432171572868
            ],
            [
              -87.114976,
              36.642414
            ],
            [
              -87.11498319298539,
              36.6424139676934
            ],
            [
              -87.125932,
              36.628989
            ],
            [
              -87.12099,
              36.613544
            ],
            [
              -87.133119,
              36.562878
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "011",
      "COUNTYNS": "01639727",
      "AFFGEOID": "0500000US47011",
      "GEOID": "47011",
      "NAME": "Bradley",
      "LSAD": "06",
      "ALAND": 851489133,
      "AWATER": 6959140,
      "County_state": "Bradley,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.026643,
              35.136171
            ],
            [
              -85.008478,
              35.09269
            ],
            [
              -85.007859,
              35.080051
            ],
            [
              -84.998946,
              35.076508
            ],
            [
              -84.994448,
              35.058874
            ],
            [
              -84.998182,
              35.052053
            ],
            [
              -84.993893,
              35.034884
            ],
            [
              -84.961146,
              35.015175
            ],
            [
              -84.9769733744412,
              34.9876687952914
            ],
            [
              -84.955623,
              34.98783
            ],
            [
              -84.94442,
              34.987864
            ],
            [
              -84.939306,
              34.987916
            ],
            [
              -84.861314,
              34.987791
            ],
            [
              -84.858032,
              34.987746
            ],
            [
              -84.831799,
              34.988004
            ],
            [
              -84.82401,
              34.987707
            ],
            [
              -84.820478,
              34.987913
            ],
            [
              -84.817279,
              34.987753
            ],
            [
              -84.810742,
              34.987615
            ],
            [
              -84.8104769358134,
              34.9876071739714
            ],
            [
              -84.809184,
              34.987569
            ],
            [
              -84.808127,
              34.987592
            ],
            [
              -84.7758524571709,
              34.987799615242096
            ],
            [
              -84.759638,
              35.025389
            ],
            [
              -84.748599,
              35.058776
            ],
            [
              -84.735303,
              35.08135
            ],
            [
              -84.747421,
              35.086011
            ],
            [
              -84.699737,
              35.172274
            ],
            [
              -84.703337,
              35.184774
            ],
            [
              -84.697937,
              35.200473
            ],
            [
              -84.702138,
              35.219073
            ],
            [
              -84.697033,
              35.228397
            ],
            [
              -84.715818,
              35.233683
            ],
            [
              -84.703607,
              35.241043
            ],
            [
              -84.697838,
              35.253173
            ],
            [
              -84.716138,
              35.261773
            ],
            [
              -84.730394,
              35.254713
            ],
            [
              -84.744441,
              35.264223
            ],
            [
              -84.74994,
              35.275886
            ],
            [
              -84.72314,
              35.285471
            ],
            [
              -84.74994,
              35.287373
            ],
            [
              -84.782567,
              35.313536
            ],
            [
              -84.824855,
              35.325693
            ],
            [
              -84.851095,
              35.346489
            ],
            [
              -84.860164,
              35.350074
            ],
            [
              -84.877546,
              35.357871
            ],
            [
              -84.884046,
              35.329172
            ],
            [
              -84.927446,
              35.288869
            ],
            [
              -84.946339,
              35.287717
            ],
            [
              -84.94233,
              35.282242
            ],
            [
              -84.966349,
              35.259971
            ],
            [
              -84.978813,
              35.225826
            ],
            [
              -85.008702,
              35.183685
            ],
            [
              -85.007577,
              35.174248
            ],
            [
              -85.026643,
              35.136171
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "183",
      "COUNTYNS": "01639799",
      "AFFGEOID": "0500000US47183",
      "GEOID": "47183",
      "NAME": "Weakley",
      "LSAD": "06",
      "ALAND": 1503107889,
      "AWATER": 3707115,
      "County_state": "Weakley,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.951171,
              36.389024
            ],
            [
              -88.959839,
              36.222834
            ],
            [
              -88.93308,
              36.194567
            ],
            [
              -88.894533,
              36.176835
            ],
            [
              -88.863298,
              36.137639
            ],
            [
              -88.847823,
              36.135246
            ],
            [
              -88.840884,
              36.123495
            ],
            [
              -88.799282,
              36.113849
            ],
            [
              -88.766886,
              36.112352
            ],
            [
              -88.751592,
              36.105418
            ],
            [
              -88.749826,
              36.092386
            ],
            [
              -88.692709,
              36.062746
            ],
            [
              -88.678381,
              36.066496
            ],
            [
              -88.653843,
              36.064294
            ],
            [
              -88.655488,
              36.117338
            ],
            [
              -88.532233,
              36.115416
            ],
            [
              -88.530322,
              36.152023
            ],
            [
              -88.5163456859488,
              36.5014314866172
            ],
            [
              -88.516427,
              36.50143
            ],
            [
              -88.545192,
              36.501814
            ],
            [
              -88.577283,
              36.50194
            ],
            [
              -88.661133,
              36.502243
            ],
            [
              -88.715255,
              36.502662
            ],
            [
              -88.747523,
              36.502834
            ],
            [
              -88.799594,
              36.502757
            ],
            [
              -88.81676518603649,
              36.5028152435007
            ],
            [
              -88.827012,
              36.50285
            ],
            [
              -88.82730115556168,
              36.502852430516896
            ],
            [
              -88.814016,
              36.470802
            ],
            [
              -88.81642,
              36.410732
            ],
            [
              -88.846523,
              36.413163
            ],
            [
              -88.864789,
              36.406822
            ],
            [
              -88.886352,
              36.39286
            ],
            [
              -88.897938,
              36.393826
            ],
            [
              -88.91181,
              36.405383
            ],
            [
              -88.947172,
              36.411939
            ],
            [
              -88.951171,
              36.389024
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "187",
      "COUNTYNS": "01639801",
      "AFFGEOID": "0500000US47187",
      "GEOID": "47187",
      "NAME": "Williamson",
      "LSAD": "06",
      "ALAND": 1508923182,
      "AWATER": 3012752,
      "County_state": "Williamson,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.201426,
              35.940579
            ],
            [
              -87.215099,
              35.850651
            ],
            [
              -87.035681,
              35.796213
            ],
            [
              -87.003959,
              35.779028
            ],
            [
              -86.96951,
              35.771676
            ],
            [
              -86.962471,
              35.782883
            ],
            [
              -86.92836,
              35.778057
            ],
            [
              -86.938278,
              35.761972
            ],
            [
              -86.888056,
              35.743823
            ],
            [
              -86.845491,
              35.725166
            ],
            [
              -86.811891,
              35.720455
            ],
            [
              -86.782016,
              35.706595
            ],
            [
              -86.751651,
              35.696447
            ],
            [
              -86.746606,
              35.707889
            ],
            [
              -86.686193,
              35.710051
            ],
            [
              -86.700214,
              35.722143
            ],
            [
              -86.696904,
              35.752334
            ],
            [
              -86.681813,
              35.750066
            ],
            [
              -86.671288,
              35.771736
            ],
            [
              -86.661266,
              35.767431
            ],
            [
              -86.662855,
              35.790934
            ],
            [
              -86.647049,
              35.795558
            ],
            [
              -86.613328,
              35.790331
            ],
            [
              -86.611816,
              35.824558
            ],
            [
              -86.626027,
              35.826526
            ],
            [
              -86.616383,
              35.838795
            ],
            [
              -86.60343,
              35.838704
            ],
            [
              -86.605648,
              35.855156
            ],
            [
              -86.617427,
              35.864132
            ],
            [
              -86.613771,
              35.883756
            ],
            [
              -86.626978,
              35.915135
            ],
            [
              -86.614985,
              35.943708
            ],
            [
              -86.625036,
              35.966706
            ],
            [
              -86.618892,
              35.968224
            ],
            [
              -86.621951,
              35.971627
            ],
            [
              -86.648288,
              35.976422
            ],
            [
              -86.703215,
              36.00217
            ],
            [
              -86.712896,
              36.008122
            ],
            [
              -86.738652,
              36.013256
            ],
            [
              -86.777073,
              36.033218
            ],
            [
              -86.790875,
              36.036715
            ],
            [
              -86.928772,
              36.052646
            ],
            [
              -87.016082,
              35.997874
            ],
            [
              -87.040407,
              35.989226
            ],
            [
              -87.054114,
              36.045535
            ],
            [
              -87.085365,
              36.043805
            ],
            [
              -87.182573,
              36.049726
            ],
            [
              -87.197406,
              35.979967
            ],
            [
              -87.21195,
              35.969939
            ],
            [
              -87.204242,
              35.959186
            ],
            [
              -87.201426,
              35.940579
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "137",
      "COUNTYNS": "01648580",
      "AFFGEOID": "0500000US47137",
      "GEOID": "47137",
      "NAME": "Pickett",
      "LSAD": "06",
      "ALAND": 422113135,
      "AWATER": 29822859,
      "County_state": "Pickett,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.284878,
              36.531293
            ],
            [
              -85.269219,
              36.540265
            ],
            [
              -85.249954,
              36.523114
            ],
            [
              -85.223273,
              36.515015
            ],
            [
              -85.185359,
              36.490359
            ],
            [
              -85.147937,
              36.456164
            ],
            [
              -85.146916,
              36.435771
            ],
            [
              -85.135101,
              36.406991
            ],
            [
              -85.120445,
              36.407164
            ],
            [
              -85.102839,
              36.409939
            ],
            [
              -85.088459,
              36.500065
            ],
            [
              -85.064608,
              36.52766
            ],
            [
              -85.053558,
              36.523289
            ],
            [
              -85.043924,
              36.558791
            ],
            [
              -85.038773,
              36.554137
            ],
            [
              -85.012828,
              36.565541
            ],
            [
              -85.010347,
              36.573957
            ],
            [
              -84.999151,
              36.565862
            ],
            [
              -84.976047,
              36.574368
            ],
            [
              -84.943144,
              36.58127
            ],
            [
              -84.899845,
              36.57827
            ],
            [
              -84.862343,
              36.569871
            ],
            [
              -84.81804,
              36.550073
            ],
            [
              -84.784041,
              36.524973
            ],
            [
              -84.731839,
              36.524674
            ],
            [
              -84.78539955660389,
              36.60337484144149
            ],
            [
              -84.803744,
              36.604265
            ],
            [
              -84.843091,
              36.605127
            ],
            [
              -84.859759,
              36.606428
            ],
            [
              -84.859738,
              36.606495
            ],
            [
              -84.943948,
              36.612569
            ],
            [
              -84.9748750576222,
              36.6155518237457
            ],
            [
              -84.986448,
              36.616668
            ],
            [
              -85.024627,
              36.619354
            ],
            [
              -85.086415,
              36.621913
            ],
            [
              -85.096128,
              36.622483
            ],
            [
              -85.195372,
              36.625498
            ],
            [
              -85.2762840339111,
              36.626510937829494
            ],
            [
              -85.27749,
              36.586137
            ],
            [
              -85.267057,
              36.573238
            ],
            [
              -85.284878,
              36.531293
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "099",
      "COUNTYNS": "01639764",
      "AFFGEOID": "0500000US47099",
      "GEOID": "47099",
      "NAME": "Lawrence",
      "LSAD": "06",
      "ALAND": 1598356130,
      "AWATER": 2228678,
      "County_state": "Lawrence,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.611925,
              35.019006
            ],
            [
              -87.60617837916699,
              35.0036420906069
            ],
            [
              -87.4370737168059,
              35.0028353625626
            ],
            [
              -87.428613,
              35.002795
            ],
            [
              -87.421543,
              35.002679
            ],
            [
              -87.4174,
              35.002669
            ],
            [
              -87.391314,
              35.002374
            ],
            [
              -87.381071,
              35.002118
            ],
            [
              -87.359281,
              35.001823
            ],
            [
              -87.349251,
              35.001662
            ],
            [
              -87.299185,
              35.000915
            ],
            [
              -87.270014,
              35.00039
            ],
            [
              -87.230544,
              34.999484
            ],
            [
              -87.22405322029701,
              34.999326659116896
            ],
            [
              -87.224707,
              35.048883
            ],
            [
              -87.213984,
              35.12331
            ],
            [
              -87.198374,
              35.162614
            ],
            [
              -87.221659,
              35.173377
            ],
            [
              -87.21331,
              35.227563
            ],
            [
              -87.2153,
              35.286625
            ],
            [
              -87.232054,
              35.292704
            ],
            [
              -87.215082,
              35.295769
            ],
            [
              -87.214994,
              35.381302
            ],
            [
              -87.206578,
              35.433207
            ],
            [
              -87.226272,
              35.440408
            ],
            [
              -87.227932,
              35.429332
            ],
            [
              -87.295242,
              35.442128
            ],
            [
              -87.294534,
              35.444676
            ],
            [
              -87.326948,
              35.447699
            ],
            [
              -87.325796,
              35.456415
            ],
            [
              -87.448485,
              35.457844
            ],
            [
              -87.44882,
              35.420622
            ],
            [
              -87.478439,
              35.422658
            ],
            [
              -87.478329,
              35.414719
            ],
            [
              -87.509612,
              35.414679
            ],
            [
              -87.516794,
              35.398188
            ],
            [
              -87.575352,
              35.398454
            ],
            [
              -87.578732,
              35.225094
            ],
            [
              -87.572654,
              35.190451
            ],
            [
              -87.590376,
              35.142756
            ],
            [
              -87.588833,
              35.058431
            ],
            [
              -87.57275,
              35.049051
            ],
            [
              -87.578649,
              35.03487
            ],
            [
              -87.605733,
              35.035865
            ],
            [
              -87.611925,
              35.019006
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "141",
      "COUNTYNS": "01639783",
      "AFFGEOID": "0500000US47141",
      "GEOID": "47141",
      "NAME": "Putnam",
      "LSAD": "06",
      "ALAND": 1038860590,
      "AWATER": 3777709,
      "County_state": "Putnam,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.809168,
              36.206598
            ],
            [
              -85.795517,
              36.191738
            ],
            [
              -85.807415,
              36.131582
            ],
            [
              -85.787958,
              36.102961
            ],
            [
              -85.769835,
              36.094981
            ],
            [
              -85.775976,
              36.078328
            ],
            [
              -85.767136,
              36.070087
            ],
            [
              -85.753054,
              36.067985
            ],
            [
              -85.747144,
              36.07743
            ],
            [
              -85.722648,
              36.081986
            ],
            [
              -85.686743,
              36.064802
            ],
            [
              -85.668236,
              36.029841
            ],
            [
              -85.653444,
              36.012867
            ],
            [
              -85.644604,
              36.015053
            ],
            [
              -85.643274,
              36.024191
            ],
            [
              -85.62498,
              36.031513
            ],
            [
              -85.611138,
              36.04605
            ],
            [
              -85.588672,
              36.040494
            ],
            [
              -85.579087,
              36.063246
            ],
            [
              -85.570285,
              36.058826
            ],
            [
              -85.560459,
              36.073471
            ],
            [
              -85.507677,
              36.082012
            ],
            [
              -85.395356,
              36.055085
            ],
            [
              -85.372181,
              36.052388
            ],
            [
              -85.326278,
              36.030227
            ],
            [
              -85.309363,
              36.031592
            ],
            [
              -85.311302,
              35.992997
            ],
            [
              -85.264206,
              35.979154
            ],
            [
              -85.257012,
              35.978492
            ],
            [
              -85.260407,
              35.996645
            ],
            [
              -85.251364,
              36.040521
            ],
            [
              -85.243303,
              36.054916
            ],
            [
              -85.248219,
              36.084534
            ],
            [
              -85.246362,
              36.100698
            ],
            [
              -85.235757,
              36.105752
            ],
            [
              -85.15275,
              36.099544
            ],
            [
              -85.131748,
              36.128246
            ],
            [
              -85.100213,
              36.138561
            ],
            [
              -85.119583,
              36.144719
            ],
            [
              -85.204156,
              36.152489
            ],
            [
              -85.322888,
              36.19968
            ],
            [
              -85.326706,
              36.217867
            ],
            [
              -85.365625,
              36.220588
            ],
            [
              -85.419432,
              36.236145
            ],
            [
              -85.43585,
              36.247148
            ],
            [
              -85.435652,
              36.262233
            ],
            [
              -85.452888,
              36.26146
            ],
            [
              -85.45365,
              36.27684
            ],
            [
              -85.466011,
              36.279383
            ],
            [
              -85.453956,
              36.280943
            ],
            [
              -85.463475,
              36.293476
            ],
            [
              -85.458729,
              36.303986
            ],
            [
              -85.495124,
              36.302278
            ],
            [
              -85.494,
              36.279817
            ],
            [
              -85.508283,
              36.27772
            ],
            [
              -85.512571,
              36.256879
            ],
            [
              -85.533915,
              36.258743
            ],
            [
              -85.533697,
              36.242577
            ],
            [
              -85.555607,
              36.234015
            ],
            [
              -85.557527,
              36.223177
            ],
            [
              -85.575113,
              36.230471
            ],
            [
              -85.600212,
              36.226177
            ],
            [
              -85.634158,
              36.213346
            ],
            [
              -85.650211,
              36.220599
            ],
            [
              -85.724815,
              36.233048
            ],
            [
              -85.743009,
              36.22833
            ],
            [
              -85.779644,
              36.248854
            ],
            [
              -85.780148,
              36.238011
            ],
            [
              -85.786212,
              36.210696
            ],
            [
              -85.809168,
              36.206598
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "037",
      "COUNTYNS": "01639737",
      "AFFGEOID": "0500000US47037",
      "GEOID": "47037",
      "NAME": "Davidson",
      "LSAD": "06",
      "ALAND": 1304960561,
      "AWATER": 56649279,
      "County_state": "Davidson,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.041362,
              36.041312
            ],
            [
              -87.054114,
              36.045535
            ],
            [
              -87.040407,
              35.989226
            ],
            [
              -87.016082,
              35.997874
            ],
            [
              -86.928772,
              36.052646
            ],
            [
              -86.790875,
              36.036715
            ],
            [
              -86.777073,
              36.033218
            ],
            [
              -86.738652,
              36.013256
            ],
            [
              -86.712896,
              36.008122
            ],
            [
              -86.703215,
              36.00217
            ],
            [
              -86.648288,
              35.976422
            ],
            [
              -86.621951,
              35.971627
            ],
            [
              -86.618892,
              35.968224
            ],
            [
              -86.607995,
              35.979772
            ],
            [
              -86.612691,
              36.007523
            ],
            [
              -86.600742,
              36.011876
            ],
            [
              -86.567207,
              36.034496
            ],
            [
              -86.562427,
              36.035323
            ],
            [
              -86.566379,
              36.048762
            ],
            [
              -86.546487,
              36.06652
            ],
            [
              -86.545091,
              36.086618
            ],
            [
              -86.52749,
              36.07402
            ],
            [
              -86.525689,
              36.057318
            ],
            [
              -86.515589,
              36.10061
            ],
            [
              -86.53589,
              36.108414
            ],
            [
              -86.527841,
              36.140769
            ],
            [
              -86.556537,
              36.135267
            ],
            [
              -86.555759,
              36.145381
            ],
            [
              -86.57089,
              36.173679
            ],
            [
              -86.575587,
              36.194508
            ],
            [
              -86.594286,
              36.245105
            ],
            [
              -86.615092,
              36.245104
            ],
            [
              -86.622798,
              36.249308
            ],
            [
              -86.639095,
              36.255105
            ],
            [
              -86.642,
              36.288251
            ],
            [
              -86.668744,
              36.300577
            ],
            [
              -86.674807,
              36.31579
            ],
            [
              -86.721054,
              36.342076
            ],
            [
              -86.727529,
              36.372316
            ],
            [
              -86.745693,
              36.403097
            ],
            [
              -86.754795,
              36.405496
            ],
            [
              -86.76433,
              36.394289
            ],
            [
              -86.781893,
              36.389588
            ],
            [
              -86.791034,
              36.37676
            ],
            [
              -86.787357,
              36.35964
            ],
            [
              -86.825053,
              36.349817
            ],
            [
              -86.854299,
              36.369796
            ],
            [
              -86.877174,
              36.372204
            ],
            [
              -86.894298,
              36.389097
            ],
            [
              -86.913233,
              36.382621
            ],
            [
              -86.906254,
              36.366218
            ],
            [
              -86.947789,
              36.282426
            ],
            [
              -86.963358,
              36.267004
            ],
            [
              -86.984085,
              36.22522
            ],
            [
              -86.983613,
              36.209566
            ],
            [
              -86.997705,
              36.128512
            ],
            [
              -87.038168,
              36.094418
            ],
            [
              -87.041362,
              36.041312
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "189",
      "COUNTYNS": "01639802",
      "AFFGEOID": "0500000US47189",
      "GEOID": "47189",
      "NAME": "Wilson",
      "LSAD": "06",
      "ALAND": 1479053189,
      "AWATER": 32002365,
      "County_state": "Wilson,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.594286,
              36.245105
            ],
            [
              -86.575587,
              36.194508
            ],
            [
              -86.57089,
              36.173679
            ],
            [
              -86.555759,
              36.145381
            ],
            [
              -86.556537,
              36.135267
            ],
            [
              -86.527841,
              36.140769
            ],
            [
              -86.53589,
              36.108414
            ],
            [
              -86.515589,
              36.10061
            ],
            [
              -86.435008,
              36.049888
            ],
            [
              -86.43103,
              36.050066
            ],
            [
              -86.39512,
              36.03103
            ],
            [
              -86.382528,
              36.035251
            ],
            [
              -86.388359,
              36.027193
            ],
            [
              -86.364201,
              36.010172
            ],
            [
              -86.326252,
              35.985008
            ],
            [
              -86.309967,
              35.986146
            ],
            [
              -86.310954,
              35.980507
            ],
            [
              -86.251731,
              35.961141
            ],
            [
              -86.161094,
              35.963214
            ],
            [
              -86.153214,
              35.954399
            ],
            [
              -86.154505,
              35.963097
            ],
            [
              -86.119514,
              35.959163
            ],
            [
              -86.083421,
              35.964969
            ],
            [
              -86.014849,
              35.96106
            ],
            [
              -86.026513,
              35.998561
            ],
            [
              -86.040746,
              36.008765
            ],
            [
              -86.035778,
              36.031399
            ],
            [
              -86.053437,
              36.043793
            ],
            [
              -86.047374,
              36.057493
            ],
            [
              -86.063413,
              36.070052
            ],
            [
              -86.059706,
              36.086024
            ],
            [
              -86.066132,
              36.124178
            ],
            [
              -86.077223,
              36.135139
            ],
            [
              -86.111275,
              36.228566
            ],
            [
              -86.121367,
              36.231389
            ],
            [
              -86.119341,
              36.251147
            ],
            [
              -86.115607,
              36.268886
            ],
            [
              -86.124885,
              36.265489
            ],
            [
              -86.136803,
              36.295859
            ],
            [
              -86.175916,
              36.328836
            ],
            [
              -86.184277,
              36.319287
            ],
            [
              -86.204069,
              36.332321
            ],
            [
              -86.20656,
              36.342659
            ],
            [
              -86.226996,
              36.342692
            ],
            [
              -86.229897,
              36.349349
            ],
            [
              -86.244456,
              36.336405
            ],
            [
              -86.249275,
              36.30582
            ],
            [
              -86.264436,
              36.299718
            ],
            [
              -86.27347,
              36.309364
            ],
            [
              -86.264643,
              36.328292
            ],
            [
              -86.272764,
              36.347579
            ],
            [
              -86.283256,
              36.3475
            ],
            [
              -86.296429,
              36.334636
            ],
            [
              -86.300075,
              36.312374
            ],
            [
              -86.330452,
              36.318772
            ],
            [
              -86.362023,
              36.311505
            ],
            [
              -86.346094,
              36.329848
            ],
            [
              -86.346517,
              36.345112
            ],
            [
              -86.361323,
              36.357684
            ],
            [
              -86.376888,
              36.350921
            ],
            [
              -86.389728,
              36.327817
            ],
            [
              -86.390436,
              36.310196
            ],
            [
              -86.407812,
              36.307702
            ],
            [
              -86.41544,
              36.313698
            ],
            [
              -86.418392,
              36.332837
            ],
            [
              -86.433665,
              36.340017
            ],
            [
              -86.451721,
              36.335629
            ],
            [
              -86.513426,
              36.298303
            ],
            [
              -86.521642,
              36.278099
            ],
            [
              -86.537826,
              36.290727
            ],
            [
              -86.543434,
              36.320602
            ],
            [
              -86.552842,
              36.319066
            ],
            [
              -86.564433,
              36.30455
            ],
            [
              -86.559794,
              36.287451
            ],
            [
              -86.571095,
              36.262552
            ],
            [
              -86.594286,
              36.245105
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "173",
      "COUNTYNS": "01648583",
      "AFFGEOID": "0500000US47173",
      "GEOID": "47173",
      "NAME": "Union",
      "LSAD": "06",
      "ALAND": 579055053,
      "AWATER": 61028041,
      "County_state": "Union,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.004317,
              36.270378
            ],
            [
              -84.009753,
              36.27031
            ],
            [
              -83.96914,
              36.210105
            ],
            [
              -83.941711,
              36.186378
            ],
            [
              -83.922712,
              36.169381
            ],
            [
              -83.917522,
              36.169333
            ],
            [
              -83.905169,
              36.160906
            ],
            [
              -83.903972,
              36.16898
            ],
            [
              -83.787018,
              36.165944
            ],
            [
              -83.732704,
              36.164583
            ],
            [
              -83.714704,
              36.173783
            ],
            [
              -83.728405,
              36.182083
            ],
            [
              -83.716905,
              36.199783
            ],
            [
              -83.700205,
              36.207583
            ],
            [
              -83.693506,
              36.237483
            ],
            [
              -83.695506,
              36.254382
            ],
            [
              -83.704507,
              36.250182
            ],
            [
              -83.702707,
              36.273182
            ],
            [
              -83.689507,
              36.279082
            ],
            [
              -83.689107,
              36.295981
            ],
            [
              -83.667408,
              36.344381
            ],
            [
              -83.697309,
              36.365681
            ],
            [
              -83.711109,
              36.346181
            ],
            [
              -83.73301,
              36.34498
            ],
            [
              -83.74421,
              36.35468
            ],
            [
              -83.743432,
              36.369617
            ],
            [
              -83.764512,
              36.398779
            ],
            [
              -83.795133,
              36.413284
            ],
            [
              -83.79273,
              36.423099
            ],
            [
              -83.805515,
              36.435879
            ],
            [
              -83.833282,
              36.419654
            ],
            [
              -83.839341,
              36.429287
            ],
            [
              -83.861217,
              36.434679
            ],
            [
              -83.884737,
              36.421325
            ],
            [
              -83.905638,
              36.419979
            ],
            [
              -83.902717,
              36.405379
            ],
            [
              -83.921217,
              36.387879
            ],
            [
              -83.950919,
              36.401278
            ],
            [
              -83.954908,
              36.387623
            ],
            [
              -83.949918,
              36.368978
            ],
            [
              -83.968418,
              36.354578
            ],
            [
              -83.974017,
              36.298278
            ],
            [
              -83.985717,
              36.281478
            ],
            [
              -83.997617,
              36.282678
            ],
            [
              -84.004317,
              36.270378
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "047",
      "COUNTYNS": "01639742",
      "AFFGEOID": "0500000US47047",
      "GEOID": "47047",
      "NAME": "Fayette",
      "LSAD": "06",
      "ALAND": 1825375565,
      "AWATER": 3774866,
      "County_state": "Fayette,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.637081,
              35.197889
            ],
            [
              -89.644282,
              34.995293
            ],
            [
              -89.56505951050359,
              34.994972846619795
            ],
            [
              -89.511153,
              34.994755
            ],
            [
              -89.493739,
              34.994361
            ],
            [
              -89.486897,
              34.993975
            ],
            [
              -89.434954,
              34.993754
            ],
            [
              -89.40572016978702,
              34.9938850355527
            ],
            [
              -89.35268016441019,
              34.9941227781396
            ],
            [
              -89.217433,
              34.994729
            ],
            [
              -89.198288,
              34.994484
            ],
            [
              -89.197672,
              35.011822
            ],
            [
              -89.183944,
              35.397126
            ],
            [
              -89.438639,
              35.401048
            ],
            [
              -89.440031,
              35.402166
            ],
            [
              -89.474171,
              35.403039
            ],
            [
              -89.540562,
              35.404771
            ],
            [
              -89.598603,
              35.394564
            ],
            [
              -89.628966,
              35.382599
            ],
            [
              -89.632776,
              35.375824
            ],
            [
              -89.637778,
              35.277086
            ],
            [
              -89.637878,
              35.20984
            ],
            [
              -89.637081,
              35.197889
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "051",
      "COUNTYNS": "01639744",
      "AFFGEOID": "0500000US47051",
      "GEOID": "47051",
      "NAME": "Franklin",
      "LSAD": "06",
      "ALAND": 1436108593,
      "AWATER": 55079682,
      "County_state": "Franklin,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.316753,
              35.146636
            ],
            [
              -86.333353,
              35.146485
            ],
            [
              -86.3128,
              35.133886
            ],
            [
              -86.318168,
              35.126986
            ],
            [
              -86.318175,
              35.110975
            ],
            [
              -86.319741,
              35.019037
            ],
            [
              -86.31876114409519,
              34.9911469680432
            ],
            [
              -86.311274,
              34.991098
            ],
            [
              -86.06161930972429,
              34.9895805671815
            ],
            [
              -85.8639354755464,
              34.9883790198068
            ],
            [
              -85.863536,
              35.058594
            ],
            [
              -85.863782,
              35.120651
            ],
            [
              -85.863327,
              35.22028
            ],
            [
              -85.873025,
              35.223362
            ],
            [
              -85.907955,
              35.276199
            ],
            [
              -85.913593,
              35.289189
            ],
            [
              -85.976862,
              35.317179
            ],
            [
              -86.002382,
              35.31488
            ],
            [
              -86.02369,
              35.334348
            ],
            [
              -86.033482,
              35.335157
            ],
            [
              -86.036549,
              35.343738
            ],
            [
              -86.158018,
              35.362607
            ],
            [
              -86.194039,
              35.344509
            ],
            [
              -86.197589,
              35.333479
            ],
            [
              -86.212809,
              35.34692
            ],
            [
              -86.23269,
              35.341179
            ],
            [
              -86.250174,
              35.344644
            ],
            [
              -86.261489,
              35.333579
            ],
            [
              -86.245519,
              35.324139
            ],
            [
              -86.255762,
              35.295829
            ],
            [
              -86.28719,
              35.259681
            ],
            [
              -86.281191,
              35.241761
            ],
            [
              -86.301597,
              35.235241
            ],
            [
              -86.293717,
              35.207287
            ],
            [
              -86.311832,
              35.195513
            ],
            [
              -86.311925,
              35.172314
            ],
            [
              -86.319973,
              35.161316
            ],
            [
              -86.316753,
              35.146636
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "115",
      "COUNTYNS": "01639770",
      "AFFGEOID": "0500000US47115",
      "GEOID": "47115",
      "NAME": "Marion",
      "LSAD": "06",
      "ALAND": 1290244637,
      "AWATER": 36484742,
      "County_state": "Marion,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.873025,
              35.223362
            ],
            [
              -85.863327,
              35.22028
            ],
            [
              -85.863782,
              35.120651
            ],
            [
              -85.863536,
              35.058594
            ],
            [
              -85.8639354755464,
              34.9883790198068
            ],
            [
              -85.828724,
              34.988165
            ],
            [
              -85.824411,
              34.988142
            ],
            [
              -85.605165,
              34.984678
            ],
            [
              -85.55259257343901,
              34.984030204665096
            ],
            [
              -85.47410531586509,
              34.9830630878201
            ],
            [
              -85.427779,
              35.006673
            ],
            [
              -85.409592,
              35.040235
            ],
            [
              -85.388193,
              35.039864
            ],
            [
              -85.366359,
              35.069474
            ],
            [
              -85.361959,
              35.087773
            ],
            [
              -85.391819,
              35.120448
            ],
            [
              -85.385791,
              35.137095
            ],
            [
              -85.387089,
              35.147169
            ],
            [
              -85.39486,
              35.167329
            ],
            [
              -85.388293,
              35.180428
            ],
            [
              -85.391427,
              35.201367
            ],
            [
              -85.402913,
              35.223106
            ],
            [
              -85.444144,
              35.262646
            ],
            [
              -85.492557,
              35.274206
            ],
            [
              -85.494538,
              35.279871
            ],
            [
              -85.556757,
              35.308294
            ],
            [
              -85.558551,
              35.319292
            ],
            [
              -85.632951,
              35.303685
            ],
            [
              -85.65546,
              35.305569
            ],
            [
              -85.690415,
              35.286075
            ],
            [
              -85.709418,
              35.289251
            ],
            [
              -85.693581,
              35.239257
            ],
            [
              -85.740113,
              35.215295
            ],
            [
              -85.773489,
              35.218736
            ],
            [
              -85.822427,
              35.242226
            ],
            [
              -85.845123,
              35.24111
            ],
            [
              -85.873025,
              35.223362
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "149",
      "COUNTYNS": "01639787",
      "AFFGEOID": "0500000US47149",
      "GEOID": "47149",
      "NAME": "Rutherford",
      "LSAD": "06",
      "ALAND": 1604162229,
      "AWATER": 12132094,
      "County_state": "Rutherford,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.696904,
              35.752334
            ],
            [
              -86.700214,
              35.722143
            ],
            [
              -86.686193,
              35.710051
            ],
            [
              -86.682628,
              35.704097
            ],
            [
              -86.639436,
              35.6859
            ],
            [
              -86.636362,
              35.701982
            ],
            [
              -86.543116,
              35.697617
            ],
            [
              -86.515516,
              35.688396
            ],
            [
              -86.494006,
              35.658048
            ],
            [
              -86.472088,
              35.654732
            ],
            [
              -86.456052,
              35.637154
            ],
            [
              -86.455038,
              35.644548
            ],
            [
              -86.424145,
              35.652976
            ],
            [
              -86.413429,
              35.640023
            ],
            [
              -86.413678,
              35.623177
            ],
            [
              -86.401559,
              35.61894
            ],
            [
              -86.373011,
              35.6311
            ],
            [
              -86.378642,
              35.642823
            ],
            [
              -86.352169,
              35.650237
            ],
            [
              -86.34194,
              35.664259
            ],
            [
              -86.310374,
              35.661587
            ],
            [
              -86.305235,
              35.646375
            ],
            [
              -86.287584,
              35.648252
            ],
            [
              -86.245165,
              35.631913
            ],
            [
              -86.245406,
              35.688899
            ],
            [
              -86.221631,
              35.706429
            ],
            [
              -86.207147,
              35.70379
            ],
            [
              -86.20792,
              35.732286
            ],
            [
              -86.195462,
              35.741432
            ],
            [
              -86.18709,
              35.763725
            ],
            [
              -86.188591,
              35.795935
            ],
            [
              -86.179742,
              35.825315
            ],
            [
              -86.168634,
              35.884362
            ],
            [
              -86.158565,
              35.880778
            ],
            [
              -86.152904,
              35.909125
            ],
            [
              -86.141766,
              35.914374
            ],
            [
              -86.155319,
              35.932559
            ],
            [
              -86.153214,
              35.954399
            ],
            [
              -86.161094,
              35.963214
            ],
            [
              -86.251731,
              35.961141
            ],
            [
              -86.310954,
              35.980507
            ],
            [
              -86.309967,
              35.986146
            ],
            [
              -86.326252,
              35.985008
            ],
            [
              -86.364201,
              36.010172
            ],
            [
              -86.388359,
              36.027193
            ],
            [
              -86.382528,
              36.035251
            ],
            [
              -86.39512,
              36.03103
            ],
            [
              -86.43103,
              36.050066
            ],
            [
              -86.435008,
              36.049888
            ],
            [
              -86.515589,
              36.10061
            ],
            [
              -86.525689,
              36.057318
            ],
            [
              -86.52749,
              36.07402
            ],
            [
              -86.545091,
              36.086618
            ],
            [
              -86.546487,
              36.06652
            ],
            [
              -86.566379,
              36.048762
            ],
            [
              -86.562427,
              36.035323
            ],
            [
              -86.567207,
              36.034496
            ],
            [
              -86.600742,
              36.011876
            ],
            [
              -86.612691,
              36.007523
            ],
            [
              -86.607995,
              35.979772
            ],
            [
              -86.618892,
              35.968224
            ],
            [
              -86.625036,
              35.966706
            ],
            [
              -86.614985,
              35.943708
            ],
            [
              -86.626978,
              35.915135
            ],
            [
              -86.613771,
              35.883756
            ],
            [
              -86.617427,
              35.864132
            ],
            [
              -86.605648,
              35.855156
            ],
            [
              -86.60343,
              35.838704
            ],
            [
              -86.616383,
              35.838795
            ],
            [
              -86.626027,
              35.826526
            ],
            [
              -86.611816,
              35.824558
            ],
            [
              -86.613328,
              35.790331
            ],
            [
              -86.647049,
              35.795558
            ],
            [
              -86.662855,
              35.790934
            ],
            [
              -86.661266,
              35.767431
            ],
            [
              -86.671288,
              35.771736
            ],
            [
              -86.681813,
              35.750066
            ],
            [
              -86.696904,
              35.752334
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "081",
      "COUNTYNS": "01639756",
      "AFFGEOID": "0500000US47081",
      "GEOID": "47081",
      "NAME": "Hickman",
      "LSAD": "06",
      "ALAND": 1586324705,
      "AWATER": 373710,
      "County_state": "Hickman,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.737811,
              35.702342
            ],
            [
              -87.74375,
              35.663952
            ],
            [
              -87.724306,
              35.650347
            ],
            [
              -87.686891,
              35.633702
            ],
            [
              -87.658189,
              35.609349
            ],
            [
              -87.627139,
              35.615779
            ],
            [
              -87.623391,
              35.632744
            ],
            [
              -87.60701,
              35.636233
            ],
            [
              -87.586676,
              35.653117
            ],
            [
              -87.568831,
              35.652296
            ],
            [
              -87.549164,
              35.630228
            ],
            [
              -87.50779,
              35.628383
            ],
            [
              -87.459587,
              35.615124
            ],
            [
              -87.418709,
              35.62487
            ],
            [
              -87.357379,
              35.659514
            ],
            [
              -87.339232,
              35.65911
            ],
            [
              -87.289409,
              35.696904
            ],
            [
              -87.25561,
              35.700516
            ],
            [
              -87.245757,
              35.720637
            ],
            [
              -87.234143,
              35.724691
            ],
            [
              -87.215099,
              35.850651
            ],
            [
              -87.201426,
              35.940579
            ],
            [
              -87.204242,
              35.959186
            ],
            [
              -87.274235,
              35.964595
            ],
            [
              -87.279901,
              35.961429
            ],
            [
              -87.301916,
              35.96837
            ],
            [
              -87.445527,
              35.983697
            ],
            [
              -87.48969,
              35.983865
            ],
            [
              -87.53453,
              35.993074
            ],
            [
              -87.532493,
              35.96946
            ],
            [
              -87.569304,
              35.947747
            ],
            [
              -87.587584,
              35.953378
            ],
            [
              -87.61186,
              35.949981
            ],
            [
              -87.609189,
              35.946391
            ],
            [
              -87.658423,
              35.915511
            ],
            [
              -87.683608,
              35.916076
            ],
            [
              -87.70696,
              35.905884
            ],
            [
              -87.71676,
              35.83875
            ],
            [
              -87.732935,
              35.72626
            ],
            [
              -87.710669,
              35.703616
            ],
            [
              -87.737811,
              35.702342
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "035",
      "COUNTYNS": "01639736",
      "AFFGEOID": "0500000US47035",
      "GEOID": "47035",
      "NAME": "Cumberland",
      "LSAD": "06",
      "ALAND": 1763849850,
      "AWATER": 9823728,
      "County_state": "Cumberland,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.269359,
              35.793388
            ],
            [
              -85.272664,
              35.787718
            ],
            [
              -85.26038,
              35.78258
            ],
            [
              -85.257467,
              35.787169
            ],
            [
              -85.247784,
              35.78666
            ],
            [
              -85.249965,
              35.777464
            ],
            [
              -85.253518,
              35.766927
            ],
            [
              -85.254062,
              35.765611
            ],
            [
              -85.103406,
              35.769921
            ],
            [
              -85.084962,
              35.760546
            ],
            [
              -85.04114,
              35.77177
            ],
            [
              -85.028787,
              35.746285
            ],
            [
              -84.928536,
              35.768549
            ],
            [
              -84.916062,
              35.761939
            ],
            [
              -84.905133,
              35.773738
            ],
            [
              -84.882401,
              35.778785
            ],
            [
              -84.79783,
              35.825151
            ],
            [
              -84.781901,
              35.825
            ],
            [
              -84.728994,
              35.85885
            ],
            [
              -84.702019,
              35.895848
            ],
            [
              -84.68306,
              35.904113
            ],
            [
              -84.680633,
              35.908454
            ],
            [
              -84.720727,
              35.994914
            ],
            [
              -84.792046,
              36.059471
            ],
            [
              -84.80546,
              36.090501
            ],
            [
              -84.824312,
              36.094721
            ],
            [
              -84.832377,
              36.08401
            ],
            [
              -84.870888,
              36.136925
            ],
            [
              -84.8975,
              36.147177
            ],
            [
              -84.907753,
              36.156293
            ],
            [
              -84.933157,
              36.146259
            ],
            [
              -84.937109,
              36.154898
            ],
            [
              -84.958954,
              36.147869
            ],
            [
              -84.970144,
              36.157865
            ],
            [
              -84.995149,
              36.160493
            ],
            [
              -85.004809,
              36.153507
            ],
            [
              -85.029461,
              36.170433
            ],
            [
              -85.056614,
              36.153455
            ],
            [
              -85.083227,
              36.136548
            ],
            [
              -85.081654,
              36.132789
            ],
            [
              -85.100213,
              36.138561
            ],
            [
              -85.131748,
              36.128246
            ],
            [
              -85.15275,
              36.099544
            ],
            [
              -85.235757,
              36.105752
            ],
            [
              -85.246362,
              36.100698
            ],
            [
              -85.248219,
              36.084534
            ],
            [
              -85.243303,
              36.054916
            ],
            [
              -85.251364,
              36.040521
            ],
            [
              -85.260407,
              35.996645
            ],
            [
              -85.257012,
              35.978492
            ],
            [
              -85.264206,
              35.979154
            ],
            [
              -85.265528,
              35.965831
            ],
            [
              -85.242698,
              35.91304
            ],
            [
              -85.213115,
              35.910379
            ],
            [
              -85.214506,
              35.898971
            ],
            [
              -85.231587,
              35.893201
            ],
            [
              -85.222524,
              35.877364
            ],
            [
              -85.24138,
              35.809495
            ],
            [
              -85.248435,
              35.798748
            ],
            [
              -85.269359,
              35.793388
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "071",
      "COUNTYNS": "01639751",
      "AFFGEOID": "0500000US47071",
      "GEOID": "47071",
      "NAME": "Hardin",
      "LSAD": "06",
      "ALAND": 1495264133,
      "AWATER": 49126297,
      "County_state": "Hardin,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.367243,
              35.290985
            ],
            [
              -88.380508,
              34.99561
            ],
            [
              -88.3635308623519,
              34.99558961029079
            ],
            [
              -88.258111,
              34.995463
            ],
            [
              -88.253825,
              34.995553
            ],
            [
              -88.200064,
              34.995634
            ],
            [
              -88.20082,
              34.997774
            ],
            [
              -88.201987,
              35.005421
            ],
            [
              -88.202959,
              35.008028
            ],
            [
              -88.000032,
              35.005939
            ],
            [
              -87.9849162019114,
              35.005880673227
            ],
            [
              -87.981585,
              35.295678
            ],
            [
              -87.997385,
              35.30227
            ],
            [
              -88.017568,
              35.29957
            ],
            [
              -88.016473,
              35.33476
            ],
            [
              -88.004872,
              35.350912
            ],
            [
              -88.024654,
              35.369588
            ],
            [
              -88.024741,
              35.392307
            ],
            [
              -88.064509,
              35.417581
            ],
            [
              -88.106281,
              35.390557
            ],
            [
              -88.125033,
              35.383943
            ],
            [
              -88.166525,
              35.392059
            ],
            [
              -88.196462,
              35.379561
            ],
            [
              -88.201347,
              35.394929
            ],
            [
              -88.217864,
              35.411912
            ],
            [
              -88.241677,
              35.423263
            ],
            [
              -88.246192,
              35.416307
            ],
            [
              -88.360829,
              35.418972
            ],
            [
              -88.351765,
              35.406735
            ],
            [
              -88.362572,
              35.380994
            ],
            [
              -88.365568,
              35.315715
            ],
            [
              -88.372576,
              35.315748
            ],
            [
              -88.374926,
              35.304031
            ],
            [
              -88.367243,
              35.290985
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "095",
      "COUNTYNS": "01639762",
      "AFFGEOID": "0500000US47095",
      "GEOID": "47095",
      "NAME": "Lake",
      "LSAD": "06",
      "ALAND": 429381243,
      "AWATER": 72799687,
      "County_state": "Lake,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.705328,
              36.239898
            ],
            [
              -89.705545,
              36.238136
            ],
            [
              -89.70525,
              36.236568
            ],
            [
              -89.704459,
              36.235468
            ],
            [
              -89.699677,
              36.230821
            ],
            [
              -89.69263,
              36.224959
            ],
            [
              -89.68509280107669,
              36.219745991602295
            ],
            [
              -89.679548,
              36.215911
            ],
            [
              -89.664144,
              36.20652
            ],
            [
              -89.654876,
              36.20153
            ],
            [
              -89.64479,
              36.194101
            ],
            [
              -89.636893,
              36.188951
            ],
            [
              -89.629452,
              36.185382
            ],
            [
              -89.488283,
              36.187544
            ],
            [
              -89.482971,
              36.212659
            ],
            [
              -89.485818,
              36.220931
            ],
            [
              -89.453643,
              36.228088
            ],
            [
              -89.444587,
              36.235538
            ],
            [
              -89.428987,
              36.259334
            ],
            [
              -89.4162,
              36.292316
            ],
            [
              -89.404284,
              36.313674
            ],
            [
              -89.400576,
              36.331773
            ],
            [
              -89.403834,
              36.352001
            ],
            [
              -89.380643,
              36.377103
            ],
            [
              -89.36012,
              36.409277
            ],
            [
              -89.369237,
              36.434289
            ],
            [
              -89.36005,
              36.456487
            ],
            [
              -89.340395,
              36.475967
            ],
            [
              -89.34605560857419,
              36.503209748794795
            ],
            [
              -89.356593,
              36.502195
            ],
            [
              -89.380085,
              36.500416
            ],
            [
              -89.381792,
              36.500062
            ],
            [
              -89.391044,
              36.499079
            ],
            [
              -89.403913,
              36.499141
            ],
            [
              -89.417293,
              36.499033
            ],
            [
              -89.41977,
              36.493896
            ],
            [
              -89.422942,
              36.489381
            ],
            [
              -89.429311,
              36.481875
            ],
            [
              -89.436763,
              36.474432
            ],
            [
              -89.448468,
              36.46442
            ],
            [
              -89.453081,
              36.461285
            ],
            [
              -89.460436,
              36.45814
            ],
            [
              -89.464153,
              36.457189
            ],
            [
              -89.471718,
              36.457001
            ],
            [
              -89.476532,
              36.457846
            ],
            [
              -89.486215,
              36.46162
            ],
            [
              -89.49067,
              36.465528
            ],
            [
              -89.493198,
              36.470124
            ],
            [
              -89.494074,
              36.473225
            ],
            [
              -89.494248,
              36.475972
            ],
            [
              -89.493495,
              36.4787
            ],
            [
              -89.48671,
              36.494954
            ],
            [
              -89.485427,
              36.497491
            ],
            [
              -89.492537,
              36.497775
            ],
            [
              -89.498036,
              36.497887
            ],
            [
              -89.539232,
              36.497934
            ],
            [
              -89.534524,
              36.491432
            ],
            [
              -89.522674,
              36.481305
            ],
            [
              -89.520642,
              36.478668
            ],
            [
              -89.519501,
              36.475419
            ],
            [
              -89.51972,
              36.467002
            ],
            [
              -89.521021,
              36.461934
            ],
            [
              -89.523427,
              36.456572
            ],
            [
              -89.52519,
              36.453991
            ],
            [
              -89.527274,
              36.451545
            ],
            [
              -89.540868,
              36.441559
            ],
            [
              -89.543406,
              36.43877
            ],
            [
              -89.545061,
              36.434915
            ],
            [
              -89.545503,
              36.4307
            ],
            [
              -89.545255,
              36.427079
            ],
            [
              -89.544221,
              36.423684
            ],
            [
              -89.542337,
              36.420103
            ],
            [
              -89.525293,
              36.400446
            ],
            [
              -89.513956,
              36.384891
            ],
            [
              -89.51038,
              36.378356
            ],
            [
              -89.509558,
              36.375065
            ],
            [
              -89.509722,
              36.373626
            ],
            [
              -89.513178,
              36.359897
            ],
            [
              -89.519,
              36.3486
            ],
            [
              -89.522695,
              36.344789
            ],
            [
              -89.527029,
              36.341679
            ],
            [
              -89.531822,
              36.339246
            ],
            [
              -89.538079,
              36.337496
            ],
            [
              -89.545006,
              36.336809
            ],
            [
              -89.560439,
              36.337746
            ],
            [
              -89.581636,
              36.342357
            ],
            [
              -89.600544,
              36.342985
            ],
            [
              -89.605668,
              36.342234
            ],
            [
              -89.610689,
              36.340442
            ],
            [
              -89.615841,
              36.336085
            ],
            [
              -89.6198,
              36.329546
            ],
            [
              -89.620255,
              36.323006
            ],
            [
              -89.619242,
              36.320726
            ],
            [
              -89.611819,
              36.309088
            ],
            [
              -89.58982,
              36.296814
            ],
            [
              -89.584337,
              36.29334
            ],
            [
              -89.578492,
              36.288317
            ],
            [
              -89.554289,
              36.277751
            ],
            [
              -89.549219,
              36.27875
            ],
            [
              -89.546577,
              36.280439
            ],
            [
              -89.544797,
              36.280458
            ],
            [
              -89.539487,
              36.277368
            ],
            [
              -89.537675,
              36.275279
            ],
            [
              -89.535529,
              36.270541
            ],
            [
              -89.534507,
              36.261802
            ],
            [
              -89.534745,
              36.252576
            ],
            [
              -89.539229,
              36.248821
            ],
            [
              -89.541621,
              36.247891
            ],
            [
              -89.544743,
              36.247484
            ],
            [
              -89.548952,
              36.2482
            ],
            [
              -89.553563,
              36.250341
            ],
            [
              -89.557991,
              36.251037
            ],
            [
              -89.562206,
              36.250909
            ],
            [
              -89.564997,
              36.250067
            ],
            [
              -89.573928,
              36.243843
            ],
            [
              -89.577544,
              36.242262
            ],
            [
              -89.587326,
              36.239484
            ],
            [
              -89.602374,
              36.238106
            ],
            [
              -89.60651,
              36.238328
            ],
            [
              -89.611145,
              36.239271
            ],
            [
              -89.624416,
              36.243305
            ],
            [
              -89.63679,
              36.248079
            ],
            [
              -89.642182,
              36.249486
            ],
            [
              -89.652518,
              36.250692
            ],
            [
              -89.678046,
              36.248284
            ],
            [
              -89.686229,
              36.249507
            ],
            [
              -89.691308,
              36.252079
            ],
            [
              -89.695235,
              36.252766
            ],
            [
              -89.698568,
              36.250591
            ],
            [
              -89.699817,
              36.248384
            ],
            [
              -89.703511,
              36.243412
            ],
            [
              -89.705328,
              36.239898
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "145",
      "COUNTYNS": "01639785",
      "AFFGEOID": "0500000US47145",
      "GEOID": "47145",
      "NAME": "Roane",
      "LSAD": "06",
      "ALAND": 934284690,
      "AWATER": 88700776,
      "County_state": "Roane,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.781901,
              35.825
            ],
            [
              -84.788521,
              35.814078
            ],
            [
              -84.747167,
              35.794254
            ],
            [
              -84.738033,
              35.779291
            ],
            [
              -84.750638,
              35.774371
            ],
            [
              -84.754472,
              35.75439
            ],
            [
              -84.723853,
              35.752896
            ],
            [
              -84.721557,
              35.752235
            ],
            [
              -84.713986,
              35.741986
            ],
            [
              -84.698416,
              35.741814
            ],
            [
              -84.69873,
              35.717869
            ],
            [
              -84.67654,
              35.717501
            ],
            [
              -84.672223,
              35.702943
            ],
            [
              -84.636309,
              35.72429
            ],
            [
              -84.619031,
              35.70788
            ],
            [
              -84.628061,
              35.690117
            ],
            [
              -84.628038,
              35.662249
            ],
            [
              -84.619865,
              35.644651
            ],
            [
              -84.584472,
              35.644424
            ],
            [
              -84.575602,
              35.658889
            ],
            [
              -84.553352,
              35.658825
            ],
            [
              -84.530976,
              35.670382
            ],
            [
              -84.531909,
              35.683997
            ],
            [
              -84.510472,
              35.716448
            ],
            [
              -84.494488,
              35.716362
            ],
            [
              -84.448558,
              35.759426
            ],
            [
              -84.43642,
              35.748367
            ],
            [
              -84.42601,
              35.753255
            ],
            [
              -84.416415,
              35.776451
            ],
            [
              -84.388607,
              35.768352
            ],
            [
              -84.379668,
              35.817439
            ],
            [
              -84.371537,
              35.825862
            ],
            [
              -84.347624,
              35.832367
            ],
            [
              -84.338871,
              35.850295
            ],
            [
              -84.32997,
              35.892904
            ],
            [
              -84.28169,
              35.875467
            ],
            [
              -84.282819,
              35.902319
            ],
            [
              -84.263383,
              35.89694
            ],
            [
              -84.271587,
              35.910165
            ],
            [
              -84.32006,
              35.991607
            ],
            [
              -84.341812,
              36.048977
            ],
            [
              -84.364928,
              36.041077
            ],
            [
              -84.382628,
              36.024277
            ],
            [
              -84.446054,
              35.985984
            ],
            [
              -84.487147,
              35.985183
            ],
            [
              -84.545662,
              35.968883
            ],
            [
              -84.554969,
              35.950361
            ],
            [
              -84.596458,
              35.946712
            ],
            [
              -84.603967,
              35.953879
            ],
            [
              -84.606114,
              35.944414
            ],
            [
              -84.620086,
              35.932299
            ],
            [
              -84.651598,
              35.928652
            ],
            [
              -84.661781,
              35.915827
            ],
            [
              -84.680633,
              35.908454
            ],
            [
              -84.68306,
              35.904113
            ],
            [
              -84.702019,
              35.895848
            ],
            [
              -84.728994,
              35.85885
            ],
            [
              -84.781901,
              35.825
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "061",
      "COUNTYNS": "01639748",
      "AFFGEOID": "0500000US47061",
      "GEOID": "47061",
      "NAME": "Grundy",
      "LSAD": "06",
      "ALAND": 933548197,
      "AWATER": 1753031,
      "County_state": "Grundy,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.913593,
              35.289189
            ],
            [
              -85.907955,
              35.276199
            ],
            [
              -85.873025,
              35.223362
            ],
            [
              -85.845123,
              35.24111
            ],
            [
              -85.822427,
              35.242226
            ],
            [
              -85.773489,
              35.218736
            ],
            [
              -85.740113,
              35.215295
            ],
            [
              -85.693581,
              35.239257
            ],
            [
              -85.709418,
              35.289251
            ],
            [
              -85.690415,
              35.286075
            ],
            [
              -85.65546,
              35.305569
            ],
            [
              -85.632951,
              35.303685
            ],
            [
              -85.558551,
              35.319292
            ],
            [
              -85.542347,
              35.341453
            ],
            [
              -85.506166,
              35.357657
            ],
            [
              -85.489224,
              35.383494
            ],
            [
              -85.485635,
              35.406433
            ],
            [
              -85.512878,
              35.407612
            ],
            [
              -85.512284,
              35.422958
            ],
            [
              -85.535814,
              35.431494
            ],
            [
              -85.541332,
              35.461405
            ],
            [
              -85.536658,
              35.487095
            ],
            [
              -85.56957,
              35.505776
            ],
            [
              -85.607326,
              35.531651
            ],
            [
              -85.70326,
              35.528653
            ],
            [
              -85.7537,
              35.524154
            ],
            [
              -85.791307,
              35.517884
            ],
            [
              -85.820334,
              35.504573
            ],
            [
              -85.832925,
              35.515303
            ],
            [
              -85.876962,
              35.524095
            ],
            [
              -85.885485,
              35.517039
            ],
            [
              -85.876232,
              35.507339
            ],
            [
              -85.885585,
              35.489485
            ],
            [
              -85.889216,
              35.443954
            ],
            [
              -85.884482,
              35.392979
            ],
            [
              -85.886982,
              35.38154
            ],
            [
              -85.869478,
              35.380435
            ],
            [
              -85.881077,
              35.348804
            ],
            [
              -85.904877,
              35.330434
            ],
            [
              -85.906935,
              35.297295
            ],
            [
              -85.913593,
              35.289189
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "127",
      "COUNTYNS": "01648579",
      "AFFGEOID": "0500000US47127",
      "GEOID": "47127",
      "NAME": "Moore",
      "LSAD": "06",
      "ALAND": 334684865,
      "AWATER": 3093435,
      "County_state": "Moore,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.539485,
              35.335435
            ],
            [
              -86.52728,
              35.311536
            ],
            [
              -86.515294,
              35.310577
            ],
            [
              -86.46126,
              35.292767
            ],
            [
              -86.461095,
              35.284596
            ],
            [
              -86.437093,
              35.275179
            ],
            [
              -86.426101,
              35.259489
            ],
            [
              -86.410992,
              35.25948
            ],
            [
              -86.412696,
              35.245348
            ],
            [
              -86.423874,
              35.2411
            ],
            [
              -86.414163,
              35.228092
            ],
            [
              -86.408456,
              35.238015
            ],
            [
              -86.392192,
              35.235381
            ],
            [
              -86.368491,
              35.171582
            ],
            [
              -86.36906,
              35.128676
            ],
            [
              -86.351227,
              35.146539
            ],
            [
              -86.344768,
              35.129514
            ],
            [
              -86.318168,
              35.126986
            ],
            [
              -86.3128,
              35.133886
            ],
            [
              -86.333353,
              35.146485
            ],
            [
              -86.316753,
              35.146636
            ],
            [
              -86.319973,
              35.161316
            ],
            [
              -86.311925,
              35.172314
            ],
            [
              -86.311832,
              35.195513
            ],
            [
              -86.293717,
              35.207287
            ],
            [
              -86.301597,
              35.235241
            ],
            [
              -86.281191,
              35.241761
            ],
            [
              -86.28719,
              35.259681
            ],
            [
              -86.255762,
              35.295829
            ],
            [
              -86.245519,
              35.324139
            ],
            [
              -86.261489,
              35.333579
            ],
            [
              -86.266376,
              35.338564
            ],
            [
              -86.24539,
              35.347379
            ],
            [
              -86.274391,
              35.372278
            ],
            [
              -86.253335,
              35.385299
            ],
            [
              -86.279905,
              35.387452
            ],
            [
              -86.25759,
              35.412476
            ],
            [
              -86.288015,
              35.40726
            ],
            [
              -86.294328,
              35.392033
            ],
            [
              -86.329492,
              35.376977
            ],
            [
              -86.34728,
              35.361779
            ],
            [
              -86.360288,
              35.361989
            ],
            [
              -86.371198,
              35.347945
            ],
            [
              -86.409286,
              35.346591
            ],
            [
              -86.414249,
              35.324465
            ],
            [
              -86.422338,
              35.318846
            ],
            [
              -86.444169,
              35.3207
            ],
            [
              -86.475327,
              35.338121
            ],
            [
              -86.489348,
              35.361715
            ],
            [
              -86.525306,
              35.354718
            ],
            [
              -86.539485,
              35.335435
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "015",
      "COUNTYNS": "01648572",
      "AFFGEOID": "0500000US47015",
      "GEOID": "47015",
      "NAME": "Cannon",
      "LSAD": "06",
      "ALAND": 687992432,
      "AWATER": 156144,
      "County_state": "Cannon,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.20792,
              35.732286
            ],
            [
              -86.207147,
              35.70379
            ],
            [
              -86.165826,
              35.681545
            ],
            [
              -86.143652,
              35.661747
            ],
            [
              -86.120263,
              35.660929
            ],
            [
              -86.08889,
              35.646823
            ],
            [
              -86.037601,
              35.642954
            ],
            [
              -86.032238,
              35.65114
            ],
            [
              -85.994462,
              35.653725
            ],
            [
              -85.994192,
              35.66027
            ],
            [
              -85.985062,
              35.660108
            ],
            [
              -85.997426,
              35.711955
            ],
            [
              -85.969994,
              35.753541
            ],
            [
              -85.954798,
              35.767081
            ],
            [
              -85.913867,
              35.822506
            ],
            [
              -85.885156,
              35.839658
            ],
            [
              -85.88921,
              35.857543
            ],
            [
              -85.91612,
              35.879254
            ],
            [
              -85.997022,
              35.952866
            ],
            [
              -86.014849,
              35.96106
            ],
            [
              -86.083421,
              35.964969
            ],
            [
              -86.119514,
              35.959163
            ],
            [
              -86.154505,
              35.963097
            ],
            [
              -86.153214,
              35.954399
            ],
            [
              -86.155319,
              35.932559
            ],
            [
              -86.141766,
              35.914374
            ],
            [
              -86.152904,
              35.909125
            ],
            [
              -86.158565,
              35.880778
            ],
            [
              -86.168634,
              35.884362
            ],
            [
              -86.179742,
              35.825315
            ],
            [
              -86.188591,
              35.795935
            ],
            [
              -86.18709,
              35.763725
            ],
            [
              -86.195462,
              35.741432
            ],
            [
              -86.20792,
              35.732286
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "107",
      "COUNTYNS": "01639773",
      "AFFGEOID": "0500000US47107",
      "GEOID": "47107",
      "NAME": "McMinn",
      "LSAD": "06",
      "ALAND": 1114009037,
      "AWATER": 5340571,
      "County_state": "McMinn,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.860164,
              35.350074
            ],
            [
              -84.851095,
              35.346489
            ],
            [
              -84.824855,
              35.325693
            ],
            [
              -84.782567,
              35.313536
            ],
            [
              -84.74994,
              35.287373
            ],
            [
              -84.72314,
              35.285471
            ],
            [
              -84.74994,
              35.275886
            ],
            [
              -84.744441,
              35.264223
            ],
            [
              -84.730394,
              35.254713
            ],
            [
              -84.716138,
              35.261773
            ],
            [
              -84.697838,
              35.253173
            ],
            [
              -84.703607,
              35.241043
            ],
            [
              -84.685443,
              35.246584
            ],
            [
              -84.667398,
              35.268041
            ],
            [
              -84.51613,
              35.266472
            ],
            [
              -84.495529,
              35.285472
            ],
            [
              -84.45344,
              35.319027
            ],
            [
              -84.401025,
              35.330374
            ],
            [
              -84.409322,
              35.344276
            ],
            [
              -84.409226,
              35.398378
            ],
            [
              -84.426326,
              35.401874
            ],
            [
              -84.426651,
              35.418444
            ],
            [
              -84.436526,
              35.422774
            ],
            [
              -84.434027,
              35.448645
            ],
            [
              -84.452327,
              35.459876
            ],
            [
              -84.443282,
              35.462746
            ],
            [
              -84.504749,
              35.578735
            ],
            [
              -84.522628,
              35.586166
            ],
            [
              -84.52585,
              35.624186
            ],
            [
              -84.547603,
              35.641327
            ],
            [
              -84.557872,
              35.629651
            ],
            [
              -84.584296,
              35.633086
            ],
            [
              -84.584472,
              35.644424
            ],
            [
              -84.619865,
              35.644651
            ],
            [
              -84.641858,
              35.626175
            ],
            [
              -84.666535,
              35.589289
            ],
            [
              -84.689814,
              35.577603
            ],
            [
              -84.692215,
              35.561832
            ],
            [
              -84.724634,
              35.521551
            ],
            [
              -84.747173,
              35.510783
            ],
            [
              -84.777543,
              35.465669
            ],
            [
              -84.801147,
              35.455181
            ],
            [
              -84.803243,
              35.43657
            ],
            [
              -84.814543,
              35.43697
            ],
            [
              -84.850116,
              35.373469
            ],
            [
              -84.860164,
              35.350074
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "125",
      "COUNTYNS": "01639777",
      "AFFGEOID": "0500000US47125",
      "GEOID": "47125",
      "NAME": "Montgomery",
      "LSAD": "06",
      "ALAND": 1396430651,
      "AWATER": 12080001,
      "County_state": "Montgomery,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.6411458985761,
              36.6380360579522
            ],
            [
              -87.592358,
              36.367663
            ],
            [
              -87.586957,
              36.34178
            ],
            [
              -87.530815,
              36.33636
            ],
            [
              -87.513533,
              36.334713
            ],
            [
              -87.303129,
              36.312442
            ],
            [
              -87.286501,
              36.321933
            ],
            [
              -87.280597,
              36.354166
            ],
            [
              -87.263398,
              36.354314
            ],
            [
              -87.249988,
              36.37792
            ],
            [
              -87.215057,
              36.395949
            ],
            [
              -87.205617,
              36.409471
            ],
            [
              -87.1794,
              36.406611
            ],
            [
              -87.179549,
              36.424001
            ],
            [
              -87.16795,
              36.427742
            ],
            [
              -87.162729,
              36.413298
            ],
            [
              -87.149487,
              36.417481
            ],
            [
              -87.150276,
              36.445037
            ],
            [
              -87.120443,
              36.45546
            ],
            [
              -87.128677,
              36.46195
            ],
            [
              -87.120657,
              36.510943
            ],
            [
              -87.118066,
              36.550057
            ],
            [
              -87.129672,
              36.546523
            ],
            [
              -87.150371,
              36.566824
            ],
            [
              -87.133119,
              36.562878
            ],
            [
              -87.12099,
              36.613544
            ],
            [
              -87.125932,
              36.628989
            ],
            [
              -87.11498319298539,
              36.6424139676934
            ],
            [
              -87.23053,
              36.641895
            ],
            [
              -87.231037,
              36.641888
            ],
            [
              -87.247655,
              36.641841
            ],
            [
              -87.278398,
              36.641718
            ],
            [
              -87.281506,
              36.641761
            ],
            [
              -87.3149492444387,
              36.64162696001029
            ],
            [
              -87.33597953375501,
              36.6415426709465
            ],
            [
              -87.344131,
              36.64151
            ],
            [
              -87.347796,
              36.64144
            ],
            [
              -87.38569247363779,
              36.6412160841619
            ],
            [
              -87.4142954016638,
              36.6410470803474
            ],
            [
              -87.414309,
              36.641047
            ],
            [
              -87.425009,
              36.641047
            ],
            [
              -87.436509,
              36.640747
            ],
            [
              -87.4454075808545,
              36.6406320961245
            ],
            [
              -87.563052,
              36.639113
            ],
            [
              -87.564928,
              36.639113
            ],
            [
              -87.6411458985761,
              36.6380360579522
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "181",
      "COUNTYNS": "01639798",
      "AFFGEOID": "0500000US47181",
      "GEOID": "47181",
      "NAME": "Wayne",
      "LSAD": "06",
      "ALAND": 1901326637,
      "AWATER": 4023436,
      "County_state": "Wayne,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.024741,
              35.392307
            ],
            [
              -88.024654,
              35.369588
            ],
            [
              -88.004872,
              35.350912
            ],
            [
              -88.016473,
              35.33476
            ],
            [
              -88.017568,
              35.29957
            ],
            [
              -87.997385,
              35.30227
            ],
            [
              -87.981585,
              35.295678
            ],
            [
              -87.9849162019114,
              35.005880673227
            ],
            [
              -87.9734958113087,
              35.0058366057866
            ],
            [
              -87.9269766619543,
              35.005657104059196
            ],
            [
              -87.877969,
              35.005468
            ],
            [
              -87.877742,
              35.005512
            ],
            [
              -87.872626,
              35.005571
            ],
            [
              -87.853528,
              35.005541
            ],
            [
              -87.853411,
              35.005576
            ],
            [
              -87.851886,
              35.005656
            ],
            [
              -87.773586,
              35.004946
            ],
            [
              -87.767602,
              35.004783
            ],
            [
              -87.75889,
              35.004711
            ],
            [
              -87.709491,
              35.004089
            ],
            [
              -87.702321,
              35.003945
            ],
            [
              -87.700543,
              35.003988
            ],
            [
              -87.696834,
              35.003852
            ],
            [
              -87.671405,
              35.003537
            ],
            [
              -87.664123,
              35.003523
            ],
            [
              -87.625025,
              35.003732
            ],
            [
              -87.60617837916699,
              35.0036420906069
            ],
            [
              -87.611925,
              35.019006
            ],
            [
              -87.605733,
              35.035865
            ],
            [
              -87.578649,
              35.03487
            ],
            [
              -87.57275,
              35.049051
            ],
            [
              -87.588833,
              35.058431
            ],
            [
              -87.590376,
              35.142756
            ],
            [
              -87.572654,
              35.190451
            ],
            [
              -87.578732,
              35.225094
            ],
            [
              -87.575352,
              35.398454
            ],
            [
              -87.597164,
              35.411723
            ],
            [
              -87.607276,
              35.436176
            ],
            [
              -87.646485,
              35.442071
            ],
            [
              -87.645896,
              35.472745
            ],
            [
              -87.685809,
              35.472811
            ],
            [
              -87.717559,
              35.483347
            ],
            [
              -87.733811,
              35.491691
            ],
            [
              -87.803589,
              35.484954
            ],
            [
              -87.827406,
              35.46988
            ],
            [
              -87.858506,
              35.465413
            ],
            [
              -87.864865,
              35.475976
            ],
            [
              -87.90579,
              35.462456
            ],
            [
              -87.932058,
              35.465701
            ],
            [
              -87.952092,
              35.458121
            ],
            [
              -87.97318,
              35.459968
            ],
            [
              -87.988894,
              35.453666
            ],
            [
              -88.011815,
              35.432826
            ],
            [
              -88.007709,
              35.423381
            ],
            [
              -87.975184,
              35.414174
            ],
            [
              -87.97366,
              35.404958
            ],
            [
              -87.980472,
              35.394984
            ],
            [
              -88.012546,
              35.386944
            ],
            [
              -88.024741,
              35.392307
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "039",
      "COUNTYNS": "01639739",
      "AFFGEOID": "0500000US47039",
      "GEOID": "47039",
      "NAME": "Decatur",
      "LSAD": "06",
      "ALAND": 864738981,
      "AWATER": 28503793,
      "County_state": "Decatur,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.243057,
              35.528858
            ],
            [
              -88.241677,
              35.423263
            ],
            [
              -88.217864,
              35.411912
            ],
            [
              -88.201347,
              35.394929
            ],
            [
              -88.196462,
              35.379561
            ],
            [
              -88.166525,
              35.392059
            ],
            [
              -88.125033,
              35.383943
            ],
            [
              -88.106281,
              35.390557
            ],
            [
              -88.064509,
              35.417581
            ],
            [
              -88.024741,
              35.392307
            ],
            [
              -88.012546,
              35.386944
            ],
            [
              -87.980472,
              35.394984
            ],
            [
              -87.97366,
              35.404958
            ],
            [
              -87.975184,
              35.414174
            ],
            [
              -88.007709,
              35.423381
            ],
            [
              -88.030842,
              35.427963
            ],
            [
              -88.042332,
              35.444617
            ],
            [
              -88.039932,
              35.459294
            ],
            [
              -88.026787,
              35.472051
            ],
            [
              -87.9891,
              35.482679
            ],
            [
              -87.981334,
              35.49015
            ],
            [
              -87.974353,
              35.514049
            ],
            [
              -87.981321,
              35.535289
            ],
            [
              -87.998443,
              35.553089
            ],
            [
              -88.03417,
              35.613787
            ],
            [
              -88.035837,
              35.621839
            ],
            [
              -88.018932,
              35.660224
            ],
            [
              -88.017557,
              35.683078
            ],
            [
              -88.028409,
              35.693815
            ],
            [
              -88.034788,
              35.717261
            ],
            [
              -88.021579,
              35.737641
            ],
            [
              -87.989971,
              35.761732
            ],
            [
              -87.979846,
              35.778914
            ],
            [
              -87.970738,
              35.815705
            ],
            [
              -88.003612,
              35.81638
            ],
            [
              -88.028195,
              35.811021
            ],
            [
              -88.029504,
              35.839803
            ],
            [
              -88.051772,
              35.849975
            ],
            [
              -88.084223,
              35.842688
            ],
            [
              -88.177859,
              35.845841
            ],
            [
              -88.179177,
              35.816785
            ],
            [
              -88.190465,
              35.609088
            ],
            [
              -88.243057,
              35.528858
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "003",
      "COUNTYNS": "01639723",
      "AFFGEOID": "0500000US47003",
      "GEOID": "47003",
      "NAME": "Bedford",
      "LSAD": "06",
      "ALAND": 1226719784,
      "AWATER": 2980756,
      "County_state": "Bedford,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.664164,
              35.491771
            ],
            [
              -86.657891,
              35.45599
            ],
            [
              -86.658247,
              35.435281
            ],
            [
              -86.61822,
              35.366795
            ],
            [
              -86.599478,
              35.36495
            ],
            [
              -86.583603,
              35.370969
            ],
            [
              -86.525306,
              35.354718
            ],
            [
              -86.489348,
              35.361715
            ],
            [
              -86.475327,
              35.338121
            ],
            [
              -86.444169,
              35.3207
            ],
            [
              -86.422338,
              35.318846
            ],
            [
              -86.414249,
              35.324465
            ],
            [
              -86.409286,
              35.346591
            ],
            [
              -86.371198,
              35.347945
            ],
            [
              -86.360288,
              35.361989
            ],
            [
              -86.34728,
              35.361779
            ],
            [
              -86.329492,
              35.376977
            ],
            [
              -86.294328,
              35.392033
            ],
            [
              -86.288015,
              35.40726
            ],
            [
              -86.25759,
              35.412476
            ],
            [
              -86.246613,
              35.449195
            ],
            [
              -86.234575,
              35.45276
            ],
            [
              -86.244695,
              35.459394
            ],
            [
              -86.241641,
              35.504176
            ],
            [
              -86.242149,
              35.570689
            ],
            [
              -86.245165,
              35.631913
            ],
            [
              -86.287584,
              35.648252
            ],
            [
              -86.305235,
              35.646375
            ],
            [
              -86.310374,
              35.661587
            ],
            [
              -86.34194,
              35.664259
            ],
            [
              -86.352169,
              35.650237
            ],
            [
              -86.378642,
              35.642823
            ],
            [
              -86.373011,
              35.6311
            ],
            [
              -86.401559,
              35.61894
            ],
            [
              -86.413678,
              35.623177
            ],
            [
              -86.413429,
              35.640023
            ],
            [
              -86.424145,
              35.652976
            ],
            [
              -86.455038,
              35.644548
            ],
            [
              -86.456052,
              35.637154
            ],
            [
              -86.472088,
              35.654732
            ],
            [
              -86.494006,
              35.658048
            ],
            [
              -86.515516,
              35.688396
            ],
            [
              -86.543116,
              35.697617
            ],
            [
              -86.636362,
              35.701982
            ],
            [
              -86.639436,
              35.6859
            ],
            [
              -86.652207,
              35.587179
            ],
            [
              -86.66377,
              35.581893
            ],
            [
              -86.635207,
              35.577415
            ],
            [
              -86.653193,
              35.57412
            ],
            [
              -86.664164,
              35.491771
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "063",
      "COUNTYNS": "01648576",
      "AFFGEOID": "0500000US47063",
      "GEOID": "47063",
      "NAME": "Hamblen",
      "LSAD": "06",
      "ALAND": 417438540,
      "AWATER": 37890290,
      "County_state": "Hamblen,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.465897,
              36.188888
            ],
            [
              -83.467097,
              36.174688
            ],
            [
              -83.458695,
              36.155289
            ],
            [
              -83.426,
              36.15455
            ],
            [
              -83.372994,
              36.153792
            ],
            [
              -83.293689,
              36.138193
            ],
            [
              -83.274501,
              36.130794
            ],
            [
              -83.241986,
              36.101294
            ],
            [
              -83.234585,
              36.085094
            ],
            [
              -83.226887,
              36.092501
            ],
            [
              -83.245787,
              36.124194
            ],
            [
              -83.219686,
              36.127494
            ],
            [
              -83.224894,
              36.149093
            ],
            [
              -83.217194,
              36.150196
            ],
            [
              -83.195281,
              36.128892
            ],
            [
              -83.175098,
              36.127357
            ],
            [
              -83.185285,
              36.176795
            ],
            [
              -83.166085,
              36.180894
            ],
            [
              -83.14219,
              36.18739
            ],
            [
              -83.111954,
              36.220759
            ],
            [
              -83.082312,
              36.242296
            ],
            [
              -83.102884,
              36.266021
            ],
            [
              -83.109468,
              36.282398
            ],
            [
              -83.13433,
              36.296134
            ],
            [
              -83.159889,
              36.332893
            ],
            [
              -83.16259,
              36.345292
            ],
            [
              -83.182391,
              36.332692
            ],
            [
              -83.18079,
              36.312093
            ],
            [
              -83.196691,
              36.322192
            ],
            [
              -83.200291,
              36.306993
            ],
            [
              -83.215591,
              36.293492
            ],
            [
              -83.239592,
              36.293492
            ],
            [
              -83.233291,
              36.281892
            ],
            [
              -83.255292,
              36.289092
            ],
            [
              -83.267281,
              36.285087
            ],
            [
              -83.267073,
              36.274296
            ],
            [
              -83.278566,
              36.276088
            ],
            [
              -83.27826,
              36.264497
            ],
            [
              -83.290157,
              36.262692
            ],
            [
              -83.29447,
              36.278097
            ],
            [
              -83.30507,
              36.268186
            ],
            [
              -83.328295,
              36.29669
            ],
            [
              -83.333495,
              36.28409
            ],
            [
              -83.362996,
              36.27899
            ],
            [
              -83.362196,
              36.26769
            ],
            [
              -83.387697,
              36.264589
            ],
            [
              -83.419897,
              36.236289
            ],
            [
              -83.416096,
              36.219389
            ],
            [
              -83.426597,
              36.211489
            ],
            [
              -83.449498,
              36.217989
            ],
            [
              -83.449097,
              36.190289
            ],
            [
              -83.465897,
              36.188888
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "087",
      "COUNTYNS": "01639758",
      "AFFGEOID": "0500000US47087",
      "GEOID": "47087",
      "NAME": "Jackson",
      "LSAD": "06",
      "ALAND": 799368960,
      "AWATER": 28196490,
      "County_state": "Jackson,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.846483,
              36.288167
            ],
            [
              -85.837995,
              36.281787
            ],
            [
              -85.82766,
              36.255272
            ],
            [
              -85.813443,
              36.248814
            ],
            [
              -85.804269,
              36.255822
            ],
            [
              -85.780148,
              36.238011
            ],
            [
              -85.779644,
              36.248854
            ],
            [
              -85.743009,
              36.22833
            ],
            [
              -85.724815,
              36.233048
            ],
            [
              -85.650211,
              36.220599
            ],
            [
              -85.634158,
              36.213346
            ],
            [
              -85.600212,
              36.226177
            ],
            [
              -85.575113,
              36.230471
            ],
            [
              -85.557527,
              36.223177
            ],
            [
              -85.555607,
              36.234015
            ],
            [
              -85.533697,
              36.242577
            ],
            [
              -85.533915,
              36.258743
            ],
            [
              -85.512571,
              36.256879
            ],
            [
              -85.508283,
              36.27772
            ],
            [
              -85.494,
              36.279817
            ],
            [
              -85.495124,
              36.302278
            ],
            [
              -85.497587,
              36.375051
            ],
            [
              -85.495422,
              36.403074
            ],
            [
              -85.504865,
              36.407793
            ],
            [
              -85.529958,
              36.437701
            ],
            [
              -85.560772,
              36.459928
            ],
            [
              -85.564358,
              36.475229
            ],
            [
              -85.57596,
              36.471617
            ],
            [
              -85.667376,
              36.496037
            ],
            [
              -85.696107,
              36.49597
            ],
            [
              -85.704075,
              36.522395
            ],
            [
              -85.73599,
              36.518798
            ],
            [
              -85.715799,
              36.497517
            ],
            [
              -85.75359,
              36.499051
            ],
            [
              -85.767125,
              36.517197
            ],
            [
              -85.813885,
              36.499473
            ],
            [
              -85.828026,
              36.411836
            ],
            [
              -85.846483,
              36.288167
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "123",
      "COUNTYNS": "01639776",
      "AFFGEOID": "0500000US47123",
      "GEOID": "47123",
      "NAME": "Monroe",
      "LSAD": "06",
      "ALAND": 1646364006,
      "AWATER": 43812886,
      "County_state": "Monroe,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.52585,
              35.624186
            ],
            [
              -84.522628,
              35.586166
            ],
            [
              -84.504749,
              35.578735
            ],
            [
              -84.443282,
              35.462746
            ],
            [
              -84.452327,
              35.459876
            ],
            [
              -84.434027,
              35.448645
            ],
            [
              -84.436526,
              35.422774
            ],
            [
              -84.426651,
              35.418444
            ],
            [
              -84.426326,
              35.401874
            ],
            [
              -84.409226,
              35.398378
            ],
            [
              -84.409322,
              35.344276
            ],
            [
              -84.401025,
              35.330374
            ],
            [
              -84.45344,
              35.319027
            ],
            [
              -84.495529,
              35.285472
            ],
            [
              -84.356923,
              35.233676
            ],
            [
              -84.360923,
              35.226676
            ],
            [
              -84.344822,
              35.220376
            ],
            [
              -84.352123,
              35.207176
            ],
            [
              -84.344323,
              35.204276
            ],
            [
              -84.325722,
              35.219377
            ],
            [
              -84.292319677455,
              35.206689008403494
            ],
            [
              -84.29024,
              35.225572
            ],
            [
              -84.289621,
              35.224677
            ],
            [
              -84.287982,
              35.224468
            ],
            [
              -84.28322,
              35.226577
            ],
            [
              -84.28252,
              35.227877
            ],
            [
              -84.27542,
              35.234777
            ],
            [
              -84.260319,
              35.241877
            ],
            [
              -84.257719,
              35.246177
            ],
            [
              -84.252819,
              35.249477
            ],
            [
              -84.243019,
              35.253178
            ],
            [
              -84.240219,
              35.255178
            ],
            [
              -84.231818,
              35.264778
            ],
            [
              -84.227818,
              35.267878
            ],
            [
              -84.223718,
              35.269078
            ],
            [
              -84.216318,
              35.267978
            ],
            [
              -84.211818,
              35.266078
            ],
            [
              -84.205517,
              35.259679
            ],
            [
              -84.205317,
              35.258279
            ],
            [
              -84.202879,
              35.255772
            ],
            [
              -84.201717,
              35.247779
            ],
            [
              -84.200117,
              35.244679
            ],
            [
              -84.199117,
              35.243679
            ],
            [
              -84.192217,
              35.243079
            ],
            [
              -84.188417,
              35.239979
            ],
            [
              -84.178516,
              35.240679
            ],
            [
              -84.177016,
              35.242379
            ],
            [
              -84.170416,
              35.245779
            ],
            [
              -84.168616,
              35.24578
            ],
            [
              -84.161316,
              35.24348
            ],
            [
              -84.160416,
              35.24388
            ],
            [
              -84.158916,
              35.24588
            ],
            [
              -84.155316,
              35.24648
            ],
            [
              -84.143124,
              35.246879
            ],
            [
              -84.139715,
              35.24618
            ],
            [
              -84.136415,
              35.24478
            ],
            [
              -84.133299,
              35.243679
            ],
            [
              -84.13057,
              35.243364
            ],
            [
              -84.12889,
              35.243679
            ],
            [
              -84.127315,
              35.244414
            ],
            [
              -84.126815,
              35.246481
            ],
            [
              -84.125615,
              35.249381
            ],
            [
              -84.124915,
              35.24983
            ],
            [
              -84.12115,
              35.250644
            ],
            [
              -84.115279,
              35.250438
            ],
            [
              -84.115048,
              35.249765
            ],
            [
              -84.114414,
              35.249081
            ],
            [
              -84.113314,
              35.248981
            ],
            [
              -84.108449,
              35.25033
            ],
            [
              -84.103135,
              35.248781
            ],
            [
              -84.10227,
              35.248115
            ],
            [
              -84.097508,
              35.247382
            ],
            [
              -84.092213,
              35.249981
            ],
            [
              -84.082913,
              35.257082
            ],
            [
              -84.082813,
              35.258882
            ],
            [
              -84.081117,
              35.261146
            ],
            [
              -84.074713,
              35.263182
            ],
            [
              -84.070612,
              35.263782
            ],
            [
              -84.063712,
              35.266682
            ],
            [
              -84.055712,
              35.268182
            ],
            [
              -84.052612,
              35.269982
            ],
            [
              -84.042711,
              35.278383
            ],
            [
              -84.036011,
              35.288683
            ],
            [
              -84.0290849697824,
              35.292188335624196
            ],
            [
              -84.02791,
              35.292783
            ],
            [
              -84.02351,
              35.295783
            ],
            [
              -84.02141,
              35.301383
            ],
            [
              -84.02651,
              35.309283
            ],
            [
              -84.02871,
              35.310383
            ],
            [
              -84.03501,
              35.311983
            ],
            [
              -84.03571,
              35.312883
            ],
            [
              -84.03551,
              35.317783
            ],
            [
              -84.032479,
              35.325318
            ],
            [
              -84.03245,
              35.32653
            ],
            [
              -84.032209,
              35.328583
            ],
            [
              -84.030409,
              35.330483
            ],
            [
              -84.029377,
              35.333197
            ],
            [
              -84.031272,
              35.336438
            ],
            [
              -84.03243,
              35.336845
            ],
            [
              -84.038081,
              35.348363
            ],
            [
              -84.037494,
              35.34985
            ],
            [
              -84.035343,
              35.350833
            ],
            [
              -84.024756,
              35.353896
            ],
            [
              -84.023456,
              35.354217
            ],
            [
              -84.020188,
              35.357503
            ],
            [
              -84.019193,
              35.359569
            ],
            [
              -84.015121,
              35.364868
            ],
            [
              -84.007586,
              35.371661
            ],
            [
              -84.008307,
              35.378883
            ],
            [
              -84.009807,
              35.382683
            ],
            [
              -84.011207,
              35.384383
            ],
            [
              -84.010607,
              35.386183
            ],
            [
              -84.008207,
              35.389683
            ],
            [
              -84.008207,
              35.390383
            ],
            [
              -84.014107,
              35.397783
            ],
            [
              -84.015207,
              35.398483
            ],
            [
              -84.018107,
              35.399083
            ],
            [
              -84.018807,
              35.399783
            ],
            [
              -84.021507,
              35.404183
            ],
            [
              -84.021782,
              35.407418
            ],
            [
              -84.020633,
              35.409843
            ],
            [
              -84.017607,
              35.411183
            ],
            [
              -84.014707,
              35.411983
            ],
            [
              -84.011706,
              35.415383
            ],
            [
              -84.005306,
              35.420883
            ],
            [
              -84.00225,
              35.422548
            ],
            [
              -83.999906,
              35.425201
            ],
            [
              -83.999242,
              35.42614
            ],
            [
              -83.998154,
              35.430786
            ],
            [
              -83.996619,
              35.433761
            ],
            [
              -83.992568,
              35.438065
            ],
            [
              -83.983233,
              35.44235
            ],
            [
              -83.978286,
              35.44782
            ],
            [
              -83.973057,
              35.448921
            ],
            [
              -83.973171,
              35.452582
            ],
            [
              -83.971439,
              35.455145
            ],
            [
              -83.969845,
              35.455443
            ],
            [
              -83.966656,
              35.454941
            ],
            [
              -83.965229,
              35.455399
            ],
            [
              -83.9614,
              35.459496
            ],
            [
              -83.961054,
              35.462838
            ],
            [
              -83.961056,
              35.463738
            ],
            [
              -83.9610558840119,
              35.463753658400094
            ],
            [
              -83.965151,
              35.471117
            ],
            [
              -83.93854,
              35.487773
            ],
            [
              -83.957674,
              35.489674
            ],
            [
              -83.975855,
              35.500685
            ],
            [
              -83.985531,
              35.486803
            ],
            [
              -84.01091,
              35.516114
            ],
            [
              -83.992453,
              35.530721
            ],
            [
              -83.995926,
              35.55294
            ],
            [
              -84.042972,
              35.542175
            ],
            [
              -84.06809,
              35.546393
            ],
            [
              -84.081561,
              35.541231
            ],
            [
              -84.094807,
              35.55332
            ],
            [
              -84.087768,
              35.559875
            ],
            [
              -84.096169,
              35.575487
            ],
            [
              -84.106966,
              35.573537
            ],
            [
              -84.112319,
              35.587456
            ],
            [
              -84.130953,
              35.576313
            ],
            [
              -84.1595,
              35.592312
            ],
            [
              -84.181868,
              35.598892
            ],
            [
              -84.188319,
              35.610549
            ],
            [
              -84.19263,
              35.618241
            ],
            [
              -84.221451,
              35.615322
            ],
            [
              -84.254461,
              35.620585
            ],
            [
              -84.25079,
              35.636989
            ],
            [
              -84.281835,
              35.635572
            ],
            [
              -84.297522,
              35.643152
            ],
            [
              -84.290765,
              35.654925
            ],
            [
              -84.326477,
              35.665005
            ],
            [
              -84.357608,
              35.665162
            ],
            [
              -84.353275,
              35.654396
            ],
            [
              -84.374397,
              35.641706
            ],
            [
              -84.398074,
              35.640208
            ],
            [
              -84.406472,
              35.665622
            ],
            [
              -84.424785,
              35.661078
            ],
            [
              -84.423549,
              35.671735
            ],
            [
              -84.457249,
              35.665763
            ],
            [
              -84.45724,
              35.659081
            ],
            [
              -84.495592,
              35.655445
            ],
            [
              -84.504274,
              35.665866
            ],
            [
              -84.504595,
              35.651034
            ],
            [
              -84.522162,
              35.651394
            ],
            [
              -84.509201,
              35.639474
            ],
            [
              -84.504746,
              35.619542
            ],
            [
              -84.52585,
              35.624186
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "171",
      "COUNTYNS": "01648582",
      "AFFGEOID": "0500000US47171",
      "GEOID": "47171",
      "NAME": "Unicoi",
      "LSAD": "06",
      "ALAND": 481902939,
      "AWATER": 825725,
      "County_state": "Unicoi,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.614362,
              36.003506
            ],
            [
              -82.615062,
              36.000306
            ],
            [
              -82.613028,
              35.994
            ],
            [
              -82.612604,
              35.993488
            ],
            [
              -82.606944,
              35.99217
            ],
            [
              -82.604239,
              35.987319
            ],
            [
              -82.60674,
              35.984446
            ],
            [
              -82.610885,
              35.974442
            ],
            [
              -82.611602,
              35.971418
            ],
            [
              -82.610889,
              35.967409
            ],
            [
              -82.607761,
              35.966023
            ],
            [
              -82.60037,
              35.964626
            ],
            [
              -82.59486,
              35.965347
            ],
            [
              -82.591977,
              35.966385
            ],
            [
              -82.581003,
              35.965557
            ],
            [
              -82.577719,
              35.964196
            ],
            [
              -82.576678,
              35.959255
            ],
            [
              -82.57517,
              35.958384
            ],
            [
              -82.567503,
              35.955552
            ],
            [
              -82.557874,
              35.953901
            ],
            [
              -82.553192,
              35.960627
            ],
            [
              -82.549682,
              35.964275
            ],
            [
              -82.542463,
              35.967994
            ],
            [
              -82.539273,
              35.969115
            ],
            [
              -82.534763,
              35.969887
            ],
            [
              -82.531292,
              35.972188
            ],
            [
              -82.522702,
              35.973436
            ],
            [
              -82.52066,
              35.974633
            ],
            [
              -82.516444,
              35.975958
            ],
            [
              -82.512598,
              35.975664
            ],
            [
              -82.507068,
              35.977475
            ],
            [
              -82.5054541504531,
              35.9776714603071
            ],
            [
              -82.505384,
              35.97768
            ],
            [
              -82.500206,
              35.982561
            ],
            [
              -82.487451,
              35.991557
            ],
            [
              -82.487411,
              35.991634
            ],
            [
              -82.484678,
              35.992849
            ],
            [
              -82.483666,
              35.993866
            ],
            [
              -82.483498,
              35.996284
            ],
            [
              -82.482292,
              35.997823
            ],
            [
              -82.47419,
              36.000108
            ],
            [
              -82.464558,
              36.006508
            ],
            [
              -82.460658,
              36.007809
            ],
            [
              -82.41666077270189,
              36.072773655932195
            ],
            [
              -82.409458,
              36.083409
            ],
            [
              -82.404458,
              36.087609
            ],
            [
              -82.389958,
              36.096909
            ],
            [
              -82.380458,
              36.099309
            ],
            [
              -82.378758,
              36.102809
            ],
            [
              -82.375558,
              36.105609
            ],
            [
              -82.371383,
              36.106388
            ],
            [
              -82.366566,
              36.10765
            ],
            [
              -82.360919,
              36.110614
            ],
            [
              -82.360357,
              36.111609
            ],
            [
              -82.355157,
              36.115609
            ],
            [
              -82.349957,
              36.117109
            ],
            [
              -82.348422,
              36.115929
            ],
            [
              -82.346857,
              36.115209
            ],
            [
              -82.336756,
              36.114909
            ],
            [
              -82.332289,
              36.116935
            ],
            [
              -82.329177,
              36.117427
            ],
            [
              -82.325169,
              36.119363
            ],
            [
              -82.321448,
              36.119551
            ],
            [
              -82.318156,
              36.12091
            ],
            [
              -82.308655,
              36.12651
            ],
            [
              -82.307255,
              36.12851
            ],
            [
              -82.302855,
              36.13131
            ],
            [
              -82.297655,
              36.13351
            ],
            [
              -82.289455,
              36.13571
            ],
            [
              -82.288455,
              36.13541
            ],
            [
              -82.280354,
              36.12881
            ],
            [
              -82.278654,
              36.12851
            ],
            [
              -82.274054,
              36.12941
            ],
            [
              -82.270954,
              36.12761
            ],
            [
              -82.26875,
              36.12704
            ],
            [
              -82.26569,
              36.127614
            ],
            [
              -82.263354,
              36.13011
            ],
            [
              -82.260353,
              36.13371
            ],
            [
              -82.256319,
              36.133925
            ],
            [
              -82.253253,
              36.13371
            ],
            [
              -82.251853,
              36.13221
            ],
            [
              -82.247521,
              36.130865
            ],
            [
              -82.244461,
              36.132777
            ],
            [
              -82.243353,
              36.134311
            ],
            [
              -82.241553,
              36.137111
            ],
            [
              -82.237737,
              36.139189
            ],
            [
              -82.236415,
              36.139926
            ],
            [
              -82.235479,
              36.140748
            ],
            [
              -82.234807,
              36.14172
            ],
            [
              -82.228288,
              36.146622
            ],
            [
              -82.224852,
              36.150011
            ],
            [
              -82.223232,
              36.154772
            ],
            [
              -82.222052,
              36.156911
            ],
            [
              -82.228953,
              36.19051
            ],
            [
              -82.247455,
              36.206809
            ],
            [
              -82.267955,
              36.210109
            ],
            [
              -82.256356,
              36.233208
            ],
            [
              -82.28477,
              36.251708
            ],
            [
              -82.319532,
              36.260365
            ],
            [
              -82.34046,
              36.252807
            ],
            [
              -82.358061,
              36.240907
            ],
            [
              -82.356297,
              36.230066
            ],
            [
              -82.368638,
              36.212125
            ],
            [
              -82.401362,
              36.194807
            ],
            [
              -82.416662,
              36.179207
            ],
            [
              -82.420661,
              36.163207
            ],
            [
              -82.437251,
              36.154709
            ],
            [
              -82.487564,
              36.155706
            ],
            [
              -82.527605,
              36.148951
            ],
            [
              -82.595065,
              36.096205
            ],
            [
              -82.5772,
              36.078387
            ],
            [
              -82.602878533975,
              36.0398335475815
            ],
            [
              -82.602877,
              36.039833
            ],
            [
              -82.600741,
              36.037422
            ],
            [
              -82.598785,
              36.034162
            ],
            [
              -82.596177,
              36.03188
            ],
            [
              -82.594873,
              36.029598
            ],
            [
              -82.595525,
              36.026012
            ],
            [
              -82.600089,
              36.021774
            ],
            [
              -82.604327,
              36.018187
            ],
            [
              -82.611862,
              36.006206
            ],
            [
              -82.613862,
              36.004706
            ],
            [
              -82.614362,
              36.003506
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "103",
      "COUNTYNS": "01639766",
      "AFFGEOID": "0500000US47103",
      "GEOID": "47103",
      "NAME": "Lincoln",
      "LSAD": "06",
      "ALAND": 1477179215,
      "AWATER": 971039,
      "County_state": "Lincoln,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.828301,
              35.263677
            ],
            [
              -86.836306,
              34.991764
            ],
            [
              -86.820657,
              34.991764
            ],
            [
              -86.783648,
              34.991925
            ],
            [
              -86.78362801709059,
              34.9919250273269
            ],
            [
              -86.677616,
              34.99207
            ],
            [
              -86.676726,
              34.99207
            ],
            [
              -86.67436,
              34.992001
            ],
            [
              -86.670853,
              34.992
            ],
            [
              -86.65961,
              34.991792
            ],
            [
              -86.6413928487564,
              34.9917405111499
            ],
            [
              -86.641212,
              34.99174
            ],
            [
              -86.600039,
              34.99124
            ],
            [
              -86.588962,
              34.991197
            ],
            [
              -86.5713079656821,
              34.9910119534864
            ],
            [
              -86.571217,
              34.991011
            ],
            [
              -86.555864,
              34.990971
            ],
            [
              -86.528485,
              34.990677
            ],
            [
              -86.5179380043519,
              34.9906796068999
            ],
            [
              -86.467798,
              34.990692
            ],
            [
              -86.433927,
              34.991085
            ],
            [
              -86.397203,
              34.99166
            ],
            [
              -86.31876114409519,
              34.9911469680432
            ],
            [
              -86.319741,
              35.019037
            ],
            [
              -86.318175,
              35.110975
            ],
            [
              -86.318168,
              35.126986
            ],
            [
              -86.344768,
              35.129514
            ],
            [
              -86.351227,
              35.146539
            ],
            [
              -86.36906,
              35.128676
            ],
            [
              -86.368491,
              35.171582
            ],
            [
              -86.392192,
              35.235381
            ],
            [
              -86.408456,
              35.238015
            ],
            [
              -86.414163,
              35.228092
            ],
            [
              -86.423874,
              35.2411
            ],
            [
              -86.412696,
              35.245348
            ],
            [
              -86.410992,
              35.25948
            ],
            [
              -86.426101,
              35.259489
            ],
            [
              -86.437093,
              35.275179
            ],
            [
              -86.461095,
              35.284596
            ],
            [
              -86.46126,
              35.292767
            ],
            [
              -86.515294,
              35.310577
            ],
            [
              -86.52728,
              35.311536
            ],
            [
              -86.539485,
              35.335435
            ],
            [
              -86.525306,
              35.354718
            ],
            [
              -86.583603,
              35.370969
            ],
            [
              -86.599478,
              35.36495
            ],
            [
              -86.602613,
              35.327013
            ],
            [
              -86.637396,
              35.320976
            ],
            [
              -86.697998,
              35.295277
            ],
            [
              -86.73669,
              35.260776
            ],
            [
              -86.746899,
              35.252878
            ],
            [
              -86.828301,
              35.263677
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "117",
      "COUNTYNS": "01639771",
      "AFFGEOID": "0500000US47117",
      "GEOID": "47117",
      "NAME": "Marshall",
      "LSAD": "06",
      "ALAND": 972442316,
      "AWATER": 1801922,
      "County_state": "Marshall,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.955004,
              35.451474
            ],
            [
              -86.960804,
              35.417021
            ],
            [
              -86.925703,
              35.402475
            ],
            [
              -86.927535,
              35.375142
            ],
            [
              -86.920107,
              35.35218
            ],
            [
              -86.902303,
              35.337476
            ],
            [
              -86.906451,
              35.314075
            ],
            [
              -86.888004,
              35.315176
            ],
            [
              -86.886437,
              35.306444
            ],
            [
              -86.859481,
              35.298966
            ],
            [
              -86.845385,
              35.320889
            ],
            [
              -86.8285,
              35.293376
            ],
            [
              -86.828301,
              35.263677
            ],
            [
              -86.746899,
              35.252878
            ],
            [
              -86.73669,
              35.260776
            ],
            [
              -86.697998,
              35.295277
            ],
            [
              -86.637396,
              35.320976
            ],
            [
              -86.602613,
              35.327013
            ],
            [
              -86.599478,
              35.36495
            ],
            [
              -86.61822,
              35.366795
            ],
            [
              -86.658247,
              35.435281
            ],
            [
              -86.657891,
              35.45599
            ],
            [
              -86.664164,
              35.491771
            ],
            [
              -86.653193,
              35.57412
            ],
            [
              -86.635207,
              35.577415
            ],
            [
              -86.66377,
              35.581893
            ],
            [
              -86.652207,
              35.587179
            ],
            [
              -86.639436,
              35.6859
            ],
            [
              -86.682628,
              35.704097
            ],
            [
              -86.686193,
              35.710051
            ],
            [
              -86.746606,
              35.707889
            ],
            [
              -86.751651,
              35.696447
            ],
            [
              -86.782016,
              35.706595
            ],
            [
              -86.796935,
              35.691479
            ],
            [
              -86.811864,
              35.696412
            ],
            [
              -86.824795,
              35.600934
            ],
            [
              -86.839846,
              35.591527
            ],
            [
              -86.827394,
              35.58211
            ],
            [
              -86.840489,
              35.567286
            ],
            [
              -86.8381,
              35.550776
            ],
            [
              -86.849573,
              35.525515
            ],
            [
              -86.87128,
              35.502486
            ],
            [
              -86.875589,
              35.521251
            ],
            [
              -86.8806,
              35.494003
            ],
            [
              -86.924503,
              35.463674
            ],
            [
              -86.955004,
              35.451474
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "065",
      "COUNTYNS": "01639749",
      "AFFGEOID": "0500000US47065",
      "GEOID": "47065",
      "NAME": "Hamilton",
      "LSAD": "06",
      "ALAND": 1404756919,
      "AWATER": 86766886,
      "County_state": "Hamilton,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.47410531586509,
              34.9830630878201
            ],
            [
              -85.466713,
              34.982972
            ],
            [
              -85.384967,
              34.982987
            ],
            [
              -85.363919,
              34.983375
            ],
            [
              -85.32742496464239,
              34.984030636437005
            ],
            [
              -85.308257,
              34.984375
            ],
            [
              -85.30546199363059,
              34.984474821656
            ],
            [
              -85.305457,
              34.984475
            ],
            [
              -85.301488,
              34.984475
            ],
            [
              -85.2945,
              34.984651
            ],
            [
              -85.277556,
              34.984975
            ],
            [
              -85.275856,
              34.984975
            ],
            [
              -85.265055,
              34.985075
            ],
            [
              -85.254955,
              34.985175
            ],
            [
              -85.235555,
              34.985475
            ],
            [
              -85.230354,
              34.985475
            ],
            [
              -85.221854,
              34.985475
            ],
            [
              -85.220554,
              34.985575
            ],
            [
              -85.217854,
              34.985675
            ],
            [
              -85.216554,
              34.985675
            ],
            [
              -85.185905,
              34.985995
            ],
            [
              -85.180553,
              34.986075
            ],
            [
              -85.045183,
              34.986883
            ],
            [
              -85.045052,
              34.986859
            ],
            [
              -84.97986,
              34.987647
            ],
            [
              -84.9769733744412,
              34.9876687952914
            ],
            [
              -84.961146,
              35.015175
            ],
            [
              -84.993893,
              35.034884
            ],
            [
              -84.998182,
              35.052053
            ],
            [
              -84.994448,
              35.058874
            ],
            [
              -84.998946,
              35.076508
            ],
            [
              -85.007859,
              35.080051
            ],
            [
              -85.008478,
              35.09269
            ],
            [
              -85.026643,
              35.136171
            ],
            [
              -85.007577,
              35.174248
            ],
            [
              -85.008702,
              35.183685
            ],
            [
              -84.978813,
              35.225826
            ],
            [
              -84.966349,
              35.259971
            ],
            [
              -84.94233,
              35.282242
            ],
            [
              -84.946339,
              35.287717
            ],
            [
              -84.964078,
              35.310315
            ],
            [
              -84.97985,
              35.36277
            ],
            [
              -85.008366,
              35.383255
            ],
            [
              -85.031314,
              35.379477
            ],
            [
              -85.016343,
              35.409288
            ],
            [
              -85.067099,
              35.436809
            ],
            [
              -85.102158,
              35.428442
            ],
            [
              -85.111567,
              35.456972
            ],
            [
              -85.135191,
              35.458649
            ],
            [
              -85.148792,
              35.445594
            ],
            [
              -85.168302,
              35.455614
            ],
            [
              -85.18892,
              35.418001
            ],
            [
              -85.225877,
              35.354276
            ],
            [
              -85.246005,
              35.319077
            ],
            [
              -85.387089,
              35.147169
            ],
            [
              -85.385791,
              35.137095
            ],
            [
              -85.391819,
              35.120448
            ],
            [
              -85.361959,
              35.087773
            ],
            [
              -85.366359,
              35.069474
            ],
            [
              -85.388193,
              35.039864
            ],
            [
              -85.409592,
              35.040235
            ],
            [
              -85.427779,
              35.006673
            ],
            [
              -85.47410531586509,
              34.9830630878201
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "097",
      "COUNTYNS": "01639763",
      "AFFGEOID": "0500000US47097",
      "GEOID": "47097",
      "NAME": "Lauderdale",
      "LSAD": "06",
      "ALAND": 1222389178,
      "AWATER": 92231159,
      "County_state": "Lauderdale,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.958687,
              35.727706
            ],
            [
              -89.958882,
              35.723834
            ],
            [
              -89.956933,
              35.711677
            ],
            [
              -89.956589,
              35.695486
            ],
            [
              -89.955753,
              35.690621
            ],
            [
              -89.953303,
              35.686418
            ],
            [
              -89.950161,
              35.682433
            ],
            [
              -89.9427,
              35.675121
            ],
            [
              -89.937383,
              35.665711
            ],
            [
              -89.931036,
              35.660044
            ],
            [
              -89.922749,
              35.655293
            ],
            [
              -89.915427,
              35.652782
            ],
            [
              -89.906147,
              35.651145
            ],
            [
              -89.898916,
              35.650904
            ],
            [
              -89.89051,
              35.652408
            ],
            [
              -89.886979,
              35.653637
            ],
            [
              -89.884932,
              35.655107
            ],
            [
              -89.882893,
              35.657463
            ],
            [
              -89.878534,
              35.66482
            ],
            [
              -89.877158,
              35.666136
            ],
            [
              -89.872078,
              35.668487
            ],
            [
              -89.864782,
              35.670385
            ],
            [
              -89.861277,
              35.670064
            ],
            [
              -89.858935,
              35.66906
            ],
            [
              -89.85351,
              35.663034
            ],
            [
              -89.851176,
              35.657432
            ],
            [
              -89.850863,
              35.650208
            ],
            [
              -89.851825,
              35.644262
            ],
            [
              -89.85389,
              35.638261
            ],
            [
              -89.856619,
              35.634444
            ],
            [
              -89.863875,
              35.630788
            ],
            [
              -89.876548,
              35.626653
            ],
            [
              -89.894346,
              35.615535
            ],
            [
              -89.896999,
              35.614882
            ],
            [
              -89.899789,
              35.615061
            ],
            [
              -89.906029,
              35.616145
            ],
            [
              -89.910687,
              35.617536
            ],
            [
              -89.912172,
              35.617055
            ],
            [
              -89.919619,
              35.612236
            ],
            [
              -89.9325,
              35.607865
            ],
            [
              -89.945405,
              35.601611
            ],
            [
              -89.951147,
              35.597491
            ],
            [
              -89.954145,
              35.594264
            ],
            [
              -89.956749,
              35.590511
            ],
            [
              -89.957896,
              35.587261
            ],
            [
              -89.957924,
              35.585499
            ],
            [
              -89.95669,
              35.581426
            ],
            [
              -89.954196,
              35.57605
            ],
            [
              -89.946911,
              35.56358
            ],
            [
              -89.944754,
              35.560308
            ],
            [
              -89.941393,
              35.556555
            ],
            [
              -89.929101,
              35.551545
            ],
            [
              -89.924145,
              35.550561
            ],
            [
              -89.917424,
              35.550308
            ],
            [
              -89.914177,
              35.549713
            ],
            [
              -89.910789,
              35.547515
            ],
            [
              -89.909923,
              35.544037
            ],
            [
              -89.910885,
              35.541072
            ],
            [
              -89.910787,
              35.538718
            ],
            [
              -89.9092060309267,
              35.538164136790705
            ],
            [
              -89.894393,
              35.550157
            ],
            [
              -89.878891,
              35.586534
            ],
            [
              -89.87212,
              35.581518
            ],
            [
              -89.837763,
              35.601363
            ],
            [
              -89.823484,
              35.603764
            ],
            [
              -89.826486,
              35.614379
            ],
            [
              -89.804497,
              35.622518
            ],
            [
              -89.793219,
              35.640109
            ],
            [
              -89.782022,
              35.634743
            ],
            [
              -89.759108,
              35.64256
            ],
            [
              -89.758116,
              35.650361
            ],
            [
              -89.728793,
              35.647079
            ],
            [
              -89.727414,
              35.63655
            ],
            [
              -89.692785,
              35.644305
            ],
            [
              -89.688961,
              35.636328
            ],
            [
              -89.671629,
              35.638757
            ],
            [
              -89.665938,
              35.631392
            ],
            [
              -89.650707,
              35.639473
            ],
            [
              -89.651056,
              35.648009
            ],
            [
              -89.631124,
              35.645012
            ],
            [
              -89.623458,
              35.652316
            ],
            [
              -89.615693,
              35.636872
            ],
            [
              -89.58791,
              35.625379
            ],
            [
              -89.583101,
              35.617913
            ],
            [
              -89.560592,
              35.613446
            ],
            [
              -89.55173,
              35.605952
            ],
            [
              -89.532108,
              35.602595
            ],
            [
              -89.512094,
              35.582532
            ],
            [
              -89.502118,
              35.580621
            ],
            [
              -89.482701,
              35.593953
            ],
            [
              -89.469993,
              35.595226
            ],
            [
              -89.466354,
              35.682706
            ],
            [
              -89.452712,
              35.682545
            ],
            [
              -89.432422,
              35.729877
            ],
            [
              -89.427469,
              35.747378
            ],
            [
              -89.439942,
              35.747626
            ],
            [
              -89.439691,
              35.765915
            ],
            [
              -89.419134,
              35.798987
            ],
            [
              -89.405412,
              35.808849
            ],
            [
              -89.401805,
              35.822657
            ],
            [
              -89.356033,
              35.817635
            ],
            [
              -89.356792,
              35.837839
            ],
            [
              -89.339567,
              35.862268
            ],
            [
              -89.342829,
              35.880934
            ],
            [
              -89.353849,
              35.902457
            ],
            [
              -89.384072,
              35.920672
            ],
            [
              -89.398186,
              35.941369
            ],
            [
              -89.406965,
              35.946599
            ],
            [
              -89.490925,
              35.947723
            ],
            [
              -89.490271,
              35.934202
            ],
            [
              -89.515183,
              35.928164
            ],
            [
              -89.558335,
              35.937137
            ],
            [
              -89.569164,
              35.92577
            ],
            [
              -89.586938,
              35.928547
            ],
            [
              -89.608251,
              35.919957
            ],
            [
              -89.631345,
              35.924133
            ],
            [
              -89.644838,
              35.904351
            ],
            [
              -89.64727,
              35.89492
            ],
            [
              -89.65068,
              35.89088
            ],
            [
              -89.655452,
              35.886912
            ],
            [
              -89.657771,
              35.88575
            ],
            [
              -89.665672,
              35.883301
            ],
            [
              -89.669553,
              35.883281
            ],
            [
              -89.677012,
              35.88572
            ],
            [
              -89.68182,
              35.88999
            ],
            [
              -89.688141,
              35.896946
            ],
            [
              -89.714934,
              35.906247
            ],
            [
              -89.741241,
              35.906749
            ],
            [
              -89.756312,
              35.89806
            ],
            [
              -89.765689,
              35.891299
            ],
            [
              -89.768743,
              35.886663
            ],
            [
              -89.771726,
              35.879724
            ],
            [
              -89.772855,
              35.876244
            ],
            [
              -89.773564,
              35.871697
            ],
            [
              -89.773294,
              35.867426
            ],
            [
              -89.772467,
              35.865098
            ],
            [
              -89.769413,
              35.861558
            ],
            [
              -89.764343,
              35.858313
            ],
            [
              -89.749424,
              35.852955
            ],
            [
              -89.729517,
              35.847632
            ],
            [
              -89.709261,
              35.838911
            ],
            [
              -89.704351,
              35.835726
            ],
            [
              -89.702883,
              35.834153
            ],
            [
              -89.701407,
              35.830985
            ],
            [
              -89.701045,
              35.828227
            ],
            [
              -89.70175,
              35.824238
            ],
            [
              -89.703875,
              35.820281
            ],
            [
              -89.706085,
              35.81826
            ],
            [
              -89.719915,
              35.811557
            ],
            [
              -89.723426,
              35.809382
            ],
            [
              -89.734044,
              35.806174
            ],
            [
              -89.743025,
              35.805817
            ],
            [
              -89.757874,
              35.810415
            ],
            [
              -89.765442,
              35.811214
            ],
            [
              -89.765457,
              35.809513
            ],
            [
              -89.781793,
              35.805084
            ],
            [
              -89.796324,
              35.792807
            ],
            [
              -89.799331,
              35.788503
            ],
            [
              -89.797053,
              35.782648
            ],
            [
              -89.797231,
              35.780117
            ],
            [
              -89.799249,
              35.775439
            ],
            [
              -89.80928,
              35.764379
            ],
            [
              -89.812891,
              35.761154
            ],
            [
              -89.814456,
              35.759941
            ],
            [
              -89.821216,
              35.756716
            ],
            [
              -89.824923,
              35.755715
            ],
            [
              -89.832895,
              35.754905
            ],
            [
              -89.846343,
              35.755732
            ],
            [
              -89.857707,
              35.750077
            ],
            [
              -89.863874,
              35.747592
            ],
            [
              -89.872845,
              35.741299
            ],
            [
              -89.877256,
              35.741369
            ],
            [
              -89.883535,
              35.744984
            ],
            [
              -89.888163,
              35.750077
            ],
            [
              -89.905538,
              35.759063
            ],
            [
              -89.909996,
              35.759396
            ],
            [
              -89.913132,
              35.757302
            ],
            [
              -89.915491,
              35.754917
            ],
            [
              -89.950278,
              35.738493
            ],
            [
              -89.953983,
              35.73616
            ],
            [
              -89.956254,
              35.733386
            ],
            [
              -89.958687,
              35.727706
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "129",
      "COUNTYNS": "01639778",
      "AFFGEOID": "0500000US47129",
      "GEOID": "47129",
      "NAME": "Morgan",
      "LSAD": "06",
      "ALAND": 1352439646,
      "AWATER": 823018,
      "County_state": "Morgan,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.914155,
              36.20829
            ],
            [
              -84.910459,
              36.186363
            ],
            [
              -84.899747,
              36.172665
            ],
            [
              -84.907753,
              36.156293
            ],
            [
              -84.8975,
              36.147177
            ],
            [
              -84.870888,
              36.136925
            ],
            [
              -84.832377,
              36.08401
            ],
            [
              -84.824312,
              36.094721
            ],
            [
              -84.80546,
              36.090501
            ],
            [
              -84.792046,
              36.059471
            ],
            [
              -84.720727,
              35.994914
            ],
            [
              -84.680633,
              35.908454
            ],
            [
              -84.661781,
              35.915827
            ],
            [
              -84.651598,
              35.928652
            ],
            [
              -84.620086,
              35.932299
            ],
            [
              -84.606114,
              35.944414
            ],
            [
              -84.603967,
              35.953879
            ],
            [
              -84.596458,
              35.946712
            ],
            [
              -84.554969,
              35.950361
            ],
            [
              -84.545662,
              35.968883
            ],
            [
              -84.487147,
              35.985183
            ],
            [
              -84.446054,
              35.985984
            ],
            [
              -84.382628,
              36.024277
            ],
            [
              -84.364928,
              36.041077
            ],
            [
              -84.341812,
              36.048977
            ],
            [
              -84.37903,
              36.095377
            ],
            [
              -84.420432,
              36.108377
            ],
            [
              -84.430033,
              36.131877
            ],
            [
              -84.449734,
              36.141677
            ],
            [
              -84.441034,
              36.163478
            ],
            [
              -84.468136,
              36.191678
            ],
            [
              -84.471037,
              36.203778
            ],
            [
              -84.55244,
              36.233777
            ],
            [
              -84.573741,
              36.229977
            ],
            [
              -84.568741,
              36.237077
            ],
            [
              -84.573041,
              36.266577
            ],
            [
              -84.591841,
              36.276277
            ],
            [
              -84.611042,
              36.270377
            ],
            [
              -84.700785,
              36.370824
            ],
            [
              -84.72468,
              36.376295
            ],
            [
              -84.731598,
              36.350655
            ],
            [
              -84.745486,
              36.351083
            ],
            [
              -84.739671,
              36.337932
            ],
            [
              -84.762027,
              36.32848
            ],
            [
              -84.774787,
              36.33365
            ],
            [
              -84.785908,
              36.32579
            ],
            [
              -84.797861,
              36.296894
            ],
            [
              -84.815003,
              36.293392
            ],
            [
              -84.82224,
              36.299513
            ],
            [
              -84.84294,
              36.286997
            ],
            [
              -84.855634,
              36.293712
            ],
            [
              -84.875842,
              36.285092
            ],
            [
              -84.883816,
              36.239215
            ],
            [
              -84.914155,
              36.20829
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "025",
      "COUNTYNS": "01639733",
      "AFFGEOID": "0500000US47025",
      "GEOID": "47025",
      "NAME": "Claiborne",
      "LSAD": "06",
      "ALAND": 1125581216,
      "AWATER": 18090601,
      "County_state": "Claiborne,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.9876109506828,
              36.589592286072495
            ],
            [
              -83.982492,
              36.585315
            ],
            [
              -83.984634,
              36.560482
            ],
            [
              -83.95696,
              36.501555
            ],
            [
              -83.943139,
              36.473564
            ],
            [
              -83.922618,
              36.427779
            ],
            [
              -83.899796,
              36.42315
            ],
            [
              -83.905638,
              36.419979
            ],
            [
              -83.884737,
              36.421325
            ],
            [
              -83.861217,
              36.434679
            ],
            [
              -83.839341,
              36.429287
            ],
            [
              -83.833282,
              36.419654
            ],
            [
              -83.805515,
              36.435879
            ],
            [
              -83.79273,
              36.423099
            ],
            [
              -83.795133,
              36.413284
            ],
            [
              -83.764512,
              36.398779
            ],
            [
              -83.743432,
              36.369617
            ],
            [
              -83.74421,
              36.35468
            ],
            [
              -83.73301,
              36.34498
            ],
            [
              -83.711109,
              36.346181
            ],
            [
              -83.697309,
              36.365681
            ],
            [
              -83.667408,
              36.344381
            ],
            [
              -83.661107,
              36.332482
            ],
            [
              -83.614806,
              36.345583
            ],
            [
              -83.597306,
              36.357183
            ],
            [
              -83.594206,
              36.367483
            ],
            [
              -83.558405,
              36.363484
            ],
            [
              -83.550905,
              36.368284
            ],
            [
              -83.532504,
              36.353385
            ],
            [
              -83.531004,
              36.366185
            ],
            [
              -83.517805,
              36.357381
            ],
            [
              -83.506503,
              36.372485
            ],
            [
              -83.496303,
              36.362186
            ],
            [
              -83.497003,
              36.378885
            ],
            [
              -83.482603,
              36.379586
            ],
            [
              -83.466002,
              36.369186
            ],
            [
              -83.449201,
              36.374286
            ],
            [
              -83.460002,
              36.389186
            ],
            [
              -83.449481,
              36.387719
            ],
            [
              -83.439113,
              36.412854
            ],
            [
              -83.417073,
              36.411362
            ],
            [
              -83.414101,
              36.420686
            ],
            [
              -83.3868,
              36.413186
            ],
            [
              -83.371586,
              36.433905
            ],
            [
              -83.369401,
              36.459186
            ],
            [
              -83.398303,
              36.534885
            ],
            [
              -83.432626,
              36.573098
            ],
            [
              -83.472108,
              36.597284
            ],
            [
              -83.55681,
              36.597384
            ],
            [
              -83.622624,
              36.598061
            ],
            [
              -83.645586,
              36.600002
            ],
            [
              -83.670128,
              36.600764
            ],
            [
              -83.670141,
              36.600797
            ],
            [
              -83.675413,
              36.600814
            ],
            [
              -83.675614,
              36.598582
            ],
            [
              -83.677114,
              36.596582
            ],
            [
              -83.679514,
              36.594082
            ],
            [
              -83.683014,
              36.592182
            ],
            [
              -83.687514,
              36.587182
            ],
            [
              -83.688814,
              36.584382
            ],
            [
              -83.690714,
              36.582581
            ],
            [
              -83.789017,
              36.583881
            ],
            [
              -83.894421,
              36.586481
            ],
            [
              -83.9307607839439,
              36.5876942581124
            ],
            [
              -83.9876109506828,
              36.589592286072495
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "163",
      "COUNTYNS": "01639793",
      "AFFGEOID": "0500000US47163",
      "GEOID": "47163",
      "NAME": "Sullivan",
      "LSAD": "06",
      "ALAND": 1070732761,
      "AWATER": 42201523,
      "County_state": "Sullivan,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.702279,
              36.455499
            ],
            [
              -82.680677,
              36.4318
            ],
            [
              -82.662877,
              36.4425
            ],
            [
              -82.601474,
              36.4232
            ],
            [
              -82.450581,
              36.444101
            ],
            [
              -82.437266,
              36.437603
            ],
            [
              -82.421572,
              36.447254
            ],
            [
              -82.421908,
              36.438946
            ],
            [
              -82.405017,
              36.436535
            ],
            [
              -82.408571,
              36.426284
            ],
            [
              -82.374351,
              36.419627
            ],
            [
              -82.386163,
              36.392104
            ],
            [
              -82.369413,
              36.403057
            ],
            [
              -82.337507,
              36.401896
            ],
            [
              -82.299859,
              36.396505
            ],
            [
              -82.288112,
              36.395323
            ],
            [
              -82.251866,
              36.420134
            ],
            [
              -82.201354,
              36.401107
            ],
            [
              -82.188653,
              36.400408
            ],
            [
              -82.107349,
              36.44971
            ],
            [
              -82.099049,
              36.45901
            ],
            [
              -82.078148,
              36.457611
            ],
            [
              -82.038546,
              36.486112
            ],
            [
              -81.9979,
              36.49271
            ],
            [
              -81.983843,
              36.508314
            ],
            [
              -81.964945,
              36.531517
            ],
            [
              -81.931743,
              36.552214
            ],
            [
              -81.928044,
              36.567914
            ],
            [
              -81.875067,
              36.588258
            ],
            [
              -81.871443,
              36.602015
            ],
            [
              -81.826742,
              36.614215
            ],
            [
              -81.922644,
              36.616213
            ],
            [
              -81.934144,
              36.594213
            ],
            [
              -82.01946365130131,
              36.5944139393676
            ],
            [
              -82.1455730331036,
              36.594710944091005
            ],
            [
              -82.148569,
              36.594718
            ],
            [
              -82.150727,
              36.594673
            ],
            [
              -82.173982,
              36.594607
            ],
            [
              -82.177247,
              36.594768
            ],
            [
              -82.18074,
              36.594928
            ],
            [
              -82.188491,
              36.595179
            ],
            [
              -82.210497,
              36.595772
            ],
            [
              -82.211005,
              36.59586
            ],
            [
              -82.221713,
              36.595814
            ],
            [
              -82.223445,
              36.595721
            ],
            [
              -82.225716,
              36.595744
            ],
            [
              -82.226653,
              36.595743
            ],
            [
              -82.24319622554509,
              36.595733827872394
            ],
            [
              -82.2941391924011,
              36.595705583357
            ],
            [
              -82.296995,
              36.595704
            ],
            [
              -82.466613,
              36.594481
            ],
            [
              -82.478668,
              36.595588
            ],
            [
              -82.487238,
              36.595822
            ],
            [
              -82.554294,
              36.594876
            ],
            [
              -82.559774,
              36.5948
            ],
            [
              -82.561074,
              36.5948
            ],
            [
              -82.609176,
              36.594099
            ],
            [
              -82.599989,
              36.577293
            ],
            [
              -82.616377,
              36.579599
            ],
            [
              -82.611985,
              36.545532
            ],
            [
              -82.610175,
              36.5193
            ],
            [
              -82.627676,
              36.513599
            ],
            [
              -82.626776,
              36.4954
            ],
            [
              -82.702279,
              36.455499
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "093",
      "COUNTYNS": "01639761",
      "AFFGEOID": "0500000US47093",
      "GEOID": "47093",
      "NAME": "Knox",
      "LSAD": "06",
      "ALAND": 1316572195,
      "AWATER": 45439419,
      "County_state": "Knox,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.25434,
              35.939936
            ],
            [
              -84.272869,
              35.940506
            ],
            [
              -84.252359,
              35.918888
            ],
            [
              -84.271587,
              35.910165
            ],
            [
              -84.263383,
              35.89694
            ],
            [
              -84.25894,
              35.893796
            ],
            [
              -84.240753,
              35.876654
            ],
            [
              -84.195349,
              35.834689
            ],
            [
              -84.168202,
              35.809477
            ],
            [
              -84.166267,
              35.805106
            ],
            [
              -84.159661,
              35.795196
            ],
            [
              -84.144301,
              35.795763
            ],
            [
              -84.142596,
              35.818533
            ],
            [
              -84.134538,
              35.835132
            ],
            [
              -84.145659,
              35.849586
            ],
            [
              -84.122981,
              35.844395
            ],
            [
              -84.124611,
              35.83151
            ],
            [
              -84.079979,
              35.851252
            ],
            [
              -84.067868,
              35.831638
            ],
            [
              -84.050317,
              35.833051
            ],
            [
              -84.049927,
              35.851336
            ],
            [
              -84.033714,
              35.853732
            ],
            [
              -84.023374,
              35.840504
            ],
            [
              -84.009845,
              35.843706
            ],
            [
              -84.011382,
              35.865317
            ],
            [
              -84.028362,
              35.876027
            ],
            [
              -83.985899,
              35.876879
            ],
            [
              -83.975423,
              35.863416
            ],
            [
              -83.945692,
              35.876136
            ],
            [
              -83.907542,
              35.846626
            ],
            [
              -83.852047,
              35.841349
            ],
            [
              -83.812319,
              35.875587
            ],
            [
              -83.810331,
              35.879349
            ],
            [
              -83.794275,
              35.887125
            ],
            [
              -83.767594,
              35.911462
            ],
            [
              -83.738506,
              35.916966
            ],
            [
              -83.697792,
              35.939763
            ],
            [
              -83.683627,
              35.936846
            ],
            [
              -83.686586,
              35.949967
            ],
            [
              -83.667341,
              35.961643
            ],
            [
              -83.650957,
              35.986368
            ],
            [
              -83.661938,
              35.991507
            ],
            [
              -83.673297,
              36.038486
            ],
            [
              -83.671169,
              36.053105
            ],
            [
              -83.694599,
              36.058249
            ],
            [
              -83.70925,
              36.093234
            ],
            [
              -83.691292,
              36.09463
            ],
            [
              -83.66746,
              36.080587
            ],
            [
              -83.687167,
              36.111815
            ],
            [
              -83.699405,
              36.109469
            ],
            [
              -83.725103,
              36.142383
            ],
            [
              -83.732704,
              36.164583
            ],
            [
              -83.787018,
              36.165944
            ],
            [
              -83.903972,
              36.16898
            ],
            [
              -83.905169,
              36.160906
            ],
            [
              -83.917522,
              36.169333
            ],
            [
              -83.922712,
              36.169381
            ],
            [
              -83.941711,
              36.186378
            ],
            [
              -83.976313,
              36.167879
            ],
            [
              -84.007283,
              36.135887
            ],
            [
              -84.015314,
              36.135378
            ],
            [
              -84.029717,
              36.116475
            ],
            [
              -84.072427,
              36.083263
            ],
            [
              -84.093004,
              36.073519
            ],
            [
              -84.058585,
              36.036304
            ],
            [
              -84.070116,
              36.028881
            ],
            [
              -84.085091,
              36.016709
            ],
            [
              -84.101465,
              36.011709
            ],
            [
              -84.1495,
              35.986927
            ],
            [
              -84.195151,
              35.993008
            ],
            [
              -84.198894,
              35.977198
            ],
            [
              -84.213407,
              35.975774
            ],
            [
              -84.221373,
              35.945752
            ],
            [
              -84.244016,
              35.95499
            ],
            [
              -84.235823,
              35.931678
            ],
            [
              -84.25434,
              35.939936
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "175",
      "COUNTYNS": "01648584",
      "AFFGEOID": "0500000US47175",
      "GEOID": "47175",
      "NAME": "Van Buren",
      "LSAD": "06",
      "ALAND": 708142420,
      "AWATER": 2893455,
      "County_state": "Van Buren,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.602876,
              35.794996
            ],
            [
              -85.615146,
              35.760531
            ],
            [
              -85.591894,
              35.75436
            ],
            [
              -85.599651,
              35.741308
            ],
            [
              -85.592684,
              35.734683
            ],
            [
              -85.60514,
              35.708529
            ],
            [
              -85.594632,
              35.617898
            ],
            [
              -85.557535,
              35.532977
            ],
            [
              -85.495752,
              35.547618
            ],
            [
              -85.459856,
              35.54685
            ],
            [
              -85.45352,
              35.55813
            ],
            [
              -85.423804,
              35.567422
            ],
            [
              -85.300336,
              35.683243
            ],
            [
              -85.265387,
              35.721237
            ],
            [
              -85.270427,
              35.747175
            ],
            [
              -85.254062,
              35.765611
            ],
            [
              -85.253518,
              35.766927
            ],
            [
              -85.257842,
              35.771483
            ],
            [
              -85.255871,
              35.774128
            ],
            [
              -85.258988,
              35.777206
            ],
            [
              -85.255304,
              35.777754
            ],
            [
              -85.256846,
              35.780818
            ],
            [
              -85.26294,
              35.778764
            ],
            [
              -85.268056,
              35.779556
            ],
            [
              -85.273945,
              35.78365
            ],
            [
              -85.272664,
              35.787718
            ],
            [
              -85.269359,
              35.793388
            ],
            [
              -85.27749,
              35.809077
            ],
            [
              -85.265064,
              35.821973
            ],
            [
              -85.362267,
              35.796774
            ],
            [
              -85.423179,
              35.814691
            ],
            [
              -85.439527,
              35.815866
            ],
            [
              -85.44936,
              35.821559
            ],
            [
              -85.462996,
              35.822209
            ],
            [
              -85.464154,
              35.815161
            ],
            [
              -85.474213,
              35.822656
            ],
            [
              -85.49499,
              35.813035
            ],
            [
              -85.499398,
              35.806515
            ],
            [
              -85.517326,
              35.815815
            ],
            [
              -85.526581,
              35.807283
            ],
            [
              -85.519775,
              35.792077
            ],
            [
              -85.535043,
              35.799352
            ],
            [
              -85.543209,
              35.797325
            ],
            [
              -85.571577,
              35.806576
            ],
            [
              -85.601085,
              35.806515
            ],
            [
              -85.602876,
              35.794996
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "111",
      "COUNTYNS": "01639768",
      "AFFGEOID": "0500000US47111",
      "GEOID": "47111",
      "NAME": "Macon",
      "LSAD": "06",
      "ALAND": 795498349,
      "AWATER": 246690,
      "County_state": "Macon,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.222331,
              36.490982
            ],
            [
              -86.228434,
              36.487472
            ],
            [
              -86.190066,
              36.482725
            ],
            [
              -86.168261,
              36.444293
            ],
            [
              -85.978893,
              36.426073
            ],
            [
              -85.895732,
              36.420446
            ],
            [
              -85.828026,
              36.411836
            ],
            [
              -85.813885,
              36.499473
            ],
            [
              -85.796462,
              36.613541
            ],
            [
              -85.7886129951215,
              36.6218452013294
            ],
            [
              -85.788645,
              36.621846
            ],
            [
              -85.80149,
              36.622418
            ],
            [
              -85.832172,
              36.622046
            ],
            [
              -85.873857,
              36.623642
            ],
            [
              -85.959981,
              36.628121
            ],
            [
              -85.97584562225909,
              36.628610523713704
            ],
            [
              -86.03277,
              36.630367
            ],
            [
              -86.033139,
              36.630413
            ],
            [
              -86.038366,
              36.630804
            ],
            [
              -86.080666,
              36.63394
            ],
            [
              -86.081944,
              36.633848
            ],
            [
              -86.197573,
              36.639363
            ],
            [
              -86.204859,
              36.639741
            ],
            [
              -86.20546861000119,
              36.6397833130926
            ],
            [
              -86.225104,
              36.528776
            ],
            [
              -86.215709,
              36.515422
            ],
            [
              -86.222331,
              36.490982
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "143",
      "COUNTYNS": "01639784",
      "AFFGEOID": "0500000US47143",
      "GEOID": "47143",
      "NAME": "Rhea",
      "LSAD": "06",
      "ALAND": 816822292,
      "AWATER": 54464086,
      "County_state": "Rhea,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.119276,
              35.528984
            ],
            [
              -85.146393,
              35.491299
            ],
            [
              -85.115946,
              35.47567
            ],
            [
              -85.135191,
              35.458649
            ],
            [
              -85.111567,
              35.456972
            ],
            [
              -85.102158,
              35.428442
            ],
            [
              -85.067099,
              35.436809
            ],
            [
              -85.016343,
              35.409288
            ],
            [
              -84.980051,
              35.43927
            ],
            [
              -84.984554,
              35.466073
            ],
            [
              -84.980253,
              35.469868
            ],
            [
              -84.968953,
              35.466674
            ],
            [
              -84.962651,
              35.45267
            ],
            [
              -84.94585,
              35.43967
            ],
            [
              -84.929549,
              35.43707
            ],
            [
              -84.912148,
              35.442671
            ],
            [
              -84.903648,
              35.455471
            ],
            [
              -84.904448,
              35.47207
            ],
            [
              -84.895348,
              35.48297
            ],
            [
              -84.874347,
              35.487971
            ],
            [
              -84.859949,
              35.497072
            ],
            [
              -84.862648,
              35.515272
            ],
            [
              -84.877373,
              35.525078
            ],
            [
              -84.880439,
              35.539201
            ],
            [
              -84.873969,
              35.550217
            ],
            [
              -84.855882,
              35.554109
            ],
            [
              -84.815513,
              35.54539
            ],
            [
              -84.794904,
              35.55255
            ],
            [
              -84.808085,
              35.56883
            ],
            [
              -84.80989,
              35.582465
            ],
            [
              -84.782366,
              35.594799
            ],
            [
              -84.777791,
              35.613241
            ],
            [
              -84.786305,
              35.632767
            ],
            [
              -84.778299,
              35.644602
            ],
            [
              -84.781804,
              35.673908
            ],
            [
              -84.766321,
              35.700056
            ],
            [
              -84.753598,
              35.698914
            ],
            [
              -84.75162,
              35.680516
            ],
            [
              -84.74056,
              35.679471
            ],
            [
              -84.72324,
              35.68967
            ],
            [
              -84.723122,
              35.706924
            ],
            [
              -84.711872,
              35.725029
            ],
            [
              -84.723853,
              35.752896
            ],
            [
              -84.754472,
              35.75439
            ],
            [
              -84.750638,
              35.774371
            ],
            [
              -84.738033,
              35.779291
            ],
            [
              -84.747167,
              35.794254
            ],
            [
              -84.788521,
              35.814078
            ],
            [
              -84.781901,
              35.825
            ],
            [
              -84.79783,
              35.825151
            ],
            [
              -84.882401,
              35.778785
            ],
            [
              -84.905133,
              35.773738
            ],
            [
              -84.916062,
              35.761939
            ],
            [
              -85.047641,
              35.618654
            ],
            [
              -85.107762,
              35.571359
            ],
            [
              -85.099069,
              35.533683
            ],
            [
              -85.119276,
              35.528984
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "47",
      "COUNTYFP": "021",
      "COUNTYNS": "01639731",
      "AFFGEOID": "0500000US47021",
      "GEOID": "47021",
      "NAME": "Cheatham",
      "LSAD": "06",
      "ALAND": 783276686,
      "AWATER": 11964173,
      "County_state": "Cheatham,47"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.280597,
              36.354166
            ],
            [
              -87.286501,
              36.321933
            ],
            [
              -87.266522,
              36.320765
            ],
            [
              -87.242495,
              36.32337
            ],
            [
              -87.23304,
              36.331335
            ],
            [
              -87.216732,
              36.315886
            ],
            [
              -87.177303,
              36.314145
            ],
            [
              -87.153043,
              36.30535
            ],
            [
              -87.156439,
              36.271577
            ],
            [
              -87.181806,
              36.259763
            ],
            [
              -87.193301,
              36.248969
            ],
            [
              -87.186142,
              36.242074
            ],
            [
              -87.174046,
              36.247679
            ],
            [
              -87.164932,
              36.235503
            ],
            [
              -87.178306,
              36.228262
            ],
            [
              -87.187968,
              36.211783
            ],
            [
              -87.170027,
              36.201164
            ],
            [
              -87.149876,
              36.176878
            ],
            [
              -87.170827,
              36.165953
            ],
            [
              -87.165776,
              36.154736
            ],
            [
              -87.172799,
              36.109533
            ],
            [
              -87.173127,
              36.070885
            ],
            [
              -87.182573,
              36.049726
            ],
            [
              -87.085365,
              36.043805
            ],
            [
              -87.054114,
              36.045535
            ],
            [
              -87.041362,
              36.041312
            ],
            [
              -87.038168,
              36.094418
            ],
            [
              -86.997705,
              36.128512
            ],
            [
              -86.983613,
              36.209566
            ],
            [
              -86.984085,
              36.22522
            ],
            [
              -86.963358,
              36.267004
            ],
            [
              -86.947789,
              36.282426
            ],
            [
              -86.906254,
              36.366218
            ],
            [
              -86.913233,
              36.382621
            ],
            [
              -86.962311,
              36.377128
            ],
            [
              -86.98806,
              36.369154
            ],
            [
              -87.005364,
              36.392392
            ],
            [
              -87.063143,
              36.421415
            ],
            [
              -87.09455,
              36.445039
            ],
            [
              -87.120443,
              36.45546
            ],
            [
              -87.150276,
              36.445037
            ],
            [
              -87.149487,
              36.417481
            ],
            [
              -87.162729,
              36.413298
            ],
            [
              -87.16795,
              36.427742
            ],
            [
              -87.179549,
              36.424001
            ],
            [
              -87.1794,
              36.406611
            ],
            [
              -87.205617,
              36.409471
            ],
            [
              -87.215057,
              36.395949
            ],
            [
              -87.249988,
              36.37792
            ],
            [
              -87.263398,
              36.354314
            ],
            [
              -87.280597,
              36.354166
            ]
          ]
        ]
      ]
    }
  }
]
};