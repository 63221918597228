export const counties_il = {
  "type":"FeatureCollection","name":"il_counties","features":[
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "053",
      "COUNTYNS": "00424228",
      "AFFGEOID": "0500000US17053",
      "GEOID": "17053",
      "NAME": "Ford",
      "LSAD": "06",
      "ALAND": 1257719718,
      "AWATER": 1640286,
      "County_state": "Ford,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.459227,
              40.486243
            ],
            [
              -88.459957,
              40.39885
            ],
            [
              -88.346987,
              40.398651
            ],
            [
              -88.117411,
              40.40061
            ],
            [
              -87.932858,
              40.399401
            ],
            [
              -87.931875,
              40.404328
            ],
            [
              -87.935309,
              40.485923
            ],
            [
              -88.004076,
              40.488672
            ],
            [
              -88.117905,
              40.488086
            ],
            [
              -88.118191,
              40.516982
            ],
            [
              -88.131938,
              40.997839
            ],
            [
              -88.2473,
              40.99456
            ],
            [
              -88.234949,
              40.618166
            ],
            [
              -88.38723,
              40.61727
            ],
            [
              -88.459475,
              40.617345
            ],
            [
              -88.459227,
              40.486243
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "165",
      "COUNTYNS": "00424283",
      "AFFGEOID": "0500000US17165",
      "GEOID": "17165",
      "NAME": "Saline",
      "LSAD": "06",
      "ALAND": 983755534,
      "AWATER": 17999636,
      "County_state": "Saline,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.70676,
              37.863338
            ],
            [
              -88.708546,
              37.599277
            ],
            [
              -88.412112,
              37.599912
            ],
            [
              -88.375332,
              37.599563
            ],
            [
              -88.37453,
              37.907678
            ],
            [
              -88.706622,
              37.906797
            ],
            [
              -88.70676,
              37.863338
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "065",
      "COUNTYNS": "00424234",
      "AFFGEOID": "0500000US17065",
      "GEOID": "17065",
      "NAME": "Hamilton",
      "LSAD": "06",
      "ALAND": 1125780248,
      "AWATER": 3172680,
      "County_state": "Hamilton,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.706488,
              37.951049
            ],
            [
              -88.706622,
              37.906797
            ],
            [
              -88.37453,
              37.907678
            ],
            [
              -88.37452,
              37.908432
            ],
            [
              -88.370491,
              38.255413
            ],
            [
              -88.702391,
              38.256661
            ],
            [
              -88.704606,
              38.125195
            ],
            [
              -88.706488,
              37.951049
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "201",
      "COUNTYNS": "01785216",
      "AFFGEOID": "0500000US17201",
      "GEOID": "17201",
      "NAME": "Winnebago",
      "LSAD": "06",
      "ALAND": 1329696034,
      "AWATER": 15107670,
      "County_state": "Winnebago,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.40141639302949,
              42.5004329299029
            ],
            [
              -89.397809,
              42.307171
            ],
            [
              -89.396192,
              42.201916
            ],
            [
              -89.337195,
              42.20237
            ],
            [
              -89.173,
              42.204241
            ],
            [
              -89.172799,
              42.150297
            ],
            [
              -89.091831,
              42.150681
            ],
            [
              -88.939732,
              42.15232
            ],
            [
              -88.94028,
              42.34438
            ],
            [
              -88.940371,
              42.370234
            ],
            [
              -88.9403879812716,
              42.4950459398454
            ],
            [
              -88.940391,
              42.495046
            ],
            [
              -88.943264,
              42.495114
            ],
            [
              -88.9925591348397,
              42.4960231581706
            ],
            [
              -88.992659,
              42.496025
            ],
            [
              -89.01166603513579,
              42.496081094639095
            ],
            [
              -89.013667,
              42.496087
            ],
            [
              -89.013804,
              42.496097
            ],
            [
              -89.042898,
              42.496255
            ],
            [
              -89.071141,
              42.496208
            ],
            [
              -89.099012,
              42.496499
            ],
            [
              -89.116949,
              42.49691
            ],
            [
              -89.120365,
              42.496992
            ],
            [
              -89.125111,
              42.496957
            ],
            [
              -89.164905,
              42.497347
            ],
            [
              -89.166728,
              42.497256
            ],
            [
              -89.22627,
              42.497957
            ],
            [
              -89.228279,
              42.498047
            ],
            [
              -89.246972,
              42.49813
            ],
            [
              -89.250759,
              42.497994
            ],
            [
              -89.26266774014029,
              42.4982488672741
            ],
            [
              -89.290896,
              42.498853
            ],
            [
              -89.361561,
              42.500012
            ],
            [
              -89.3657987753767,
              42.5002603886239
            ],
            [
              -89.366031,
              42.500274
            ],
            [
              -89.40141639302949,
              42.5004329299029
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "037",
      "COUNTYNS": "00422190",
      "AFFGEOID": "0500000US17037",
      "GEOID": "17037",
      "NAME": "DeKalb",
      "LSAD": "06",
      "ALAND": 1635216352,
      "AWATER": 8545243,
      "County_state": "DeKalb,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.941625,
              41.935387
            ],
            [
              -88.941279,
              41.891752
            ],
            [
              -88.938618,
              41.628319
            ],
            [
              -88.818233,
              41.631351
            ],
            [
              -88.712444,
              41.630491
            ],
            [
              -88.706368,
              41.630375
            ],
            [
              -88.654211,
              41.631036
            ],
            [
              -88.60224,
              41.631389
            ],
            [
              -88.603488,
              41.713584
            ],
            [
              -88.601933,
              41.719563
            ],
            [
              -88.601992,
              41.734093
            ],
            [
              -88.60156,
              42.017925
            ],
            [
              -88.601958,
              42.066469
            ],
            [
              -88.58833,
              42.066462
            ],
            [
              -88.588344,
              42.110025
            ],
            [
              -88.588657,
              42.15359
            ],
            [
              -88.705633,
              42.153561
            ],
            [
              -88.822448,
              42.153336
            ],
            [
              -88.939732,
              42.15232
            ],
            [
              -88.938936,
              42.065068
            ],
            [
              -88.941625,
              41.935387
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "123",
      "COUNTYNS": "00424260",
      "AFFGEOID": "0500000US17123",
      "GEOID": "17123",
      "NAME": "Marshall",
      "LSAD": "06",
      "ALAND": 1001787870,
      "AWATER": 30368315,
      "County_state": "Marshall,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.638528,
              41.141321
            ],
            [
              -89.638727,
              40.973707
            ],
            [
              -89.447693,
              40.973246
            ],
            [
              -89.455306,
              40.935507
            ],
            [
              -89.47233,
              40.921206
            ],
            [
              -89.27582,
              40.92447
            ],
            [
              -89.143408,
              40.926848
            ],
            [
              -89.047718,
              40.925749
            ],
            [
              -89.047856,
              41.104781
            ],
            [
              -89.162238,
              41.10408
            ],
            [
              -89.358597,
              41.103665
            ],
            [
              -89.342248,
              41.11138
            ],
            [
              -89.333484,
              41.123673
            ],
            [
              -89.32927,
              41.147931
            ],
            [
              -89.466421,
              41.148558
            ],
            [
              -89.638429,
              41.148591
            ],
            [
              -89.638528,
              41.141321
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "147",
      "COUNTYNS": "00424275",
      "AFFGEOID": "0500000US17147",
      "GEOID": "17147",
      "NAME": "Piatt",
      "LSAD": "06",
      "ALAND": 1137445090,
      "AWATER": 756346,
      "County_state": "Piatt,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.745164,
              40.055191
            ],
            [
              -88.745671,
              39.792146
            ],
            [
              -88.473182,
              39.791837
            ],
            [
              -88.462328,
              39.79182
            ],
            [
              -88.462207,
              39.879092
            ],
            [
              -88.463174,
              40.142196
            ],
            [
              -88.46366,
              40.223352
            ],
            [
              -88.460418,
              40.281935
            ],
            [
              -88.574885,
              40.281501
            ],
            [
              -88.687993,
              40.142269
            ],
            [
              -88.688095,
              40.098695
            ],
            [
              -88.745344,
              40.098813
            ],
            [
              -88.745164,
              40.055191
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "159",
      "COUNTYNS": "00424281",
      "AFFGEOID": "0500000US17159",
      "GEOID": "17159",
      "NAME": "Richland",
      "LSAD": "06",
      "ALAND": 932444608,
      "AWATER": 4863725,
      "County_state": "Richland,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.296989,
              38.63419
            ],
            [
              -88.285969,
              38.619117
            ],
            [
              -88.253851,
              38.599502
            ],
            [
              -88.148044,
              38.597988
            ],
            [
              -88.147814,
              38.569086
            ],
            [
              -87.953898,
              38.570094
            ],
            [
              -87.912286,
              38.570104
            ],
            [
              -87.910282,
              38.574932
            ],
            [
              -87.908113,
              38.850107
            ],
            [
              -87.945923,
              38.850108
            ],
            [
              -88.258608,
              38.847521
            ],
            [
              -88.257783,
              38.731139
            ],
            [
              -88.283589,
              38.731801
            ],
            [
              -88.284338,
              38.681543
            ],
            [
              -88.272258,
              38.662546
            ],
            [
              -88.296989,
              38.63419
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "107",
      "COUNTYNS": "00424255",
      "AFFGEOID": "0500000US17107",
      "GEOID": "17107",
      "NAME": "Logan",
      "LSAD": "06",
      "ALAND": 1600758859,
      "AWATER": 2252736,
      "County_state": "Logan,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.602979,
              40.320129
            ],
            [
              -89.603855,
              40.218372
            ],
            [
              -89.601604,
              40.122432
            ],
            [
              -89.60112,
              40.092265
            ],
            [
              -89.579127,
              40.092324
            ],
            [
              -89.578289,
              39.976127
            ],
            [
              -89.483826,
              39.976733
            ],
            [
              -89.483419,
              39.93314
            ],
            [
              -89.404969,
              39.932733
            ],
            [
              -89.404984,
              39.918187
            ],
            [
              -89.217846,
              39.91699
            ],
            [
              -89.143457,
              39.91792
            ],
            [
              -89.144764,
              40.048853
            ],
            [
              -89.148764,
              40.282038
            ],
            [
              -89.148772,
              40.28252
            ],
            [
              -89.26265,
              40.280919
            ],
            [
              -89.26374,
              40.325344
            ],
            [
              -89.602979,
              40.320129
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "051",
      "COUNTYNS": "00424227",
      "AFFGEOID": "0500000US17051",
      "GEOID": "17051",
      "NAME": "Fayette",
      "LSAD": "06",
      "ALAND": 1855687602,
      "AWATER": 22942188,
      "County_state": "Fayette,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.2574,
              38.999151
            ],
            [
              -89.256708,
              38.917173
            ],
            [
              -89.254237,
              38.742019
            ],
            [
              -89.138393,
              38.736331
            ],
            [
              -89.138138,
              38.824244
            ],
            [
              -89.030633,
              38.822869
            ],
            [
              -88.695165,
              38.826299
            ],
            [
              -88.693531,
              38.914617
            ],
            [
              -88.806789,
              38.911655
            ],
            [
              -88.805325,
              39.216263
            ],
            [
              -89.027437,
              39.215454
            ],
            [
              -89.136594,
              39.217192
            ],
            [
              -89.140076,
              39.217907
            ],
            [
              -89.250513,
              39.217512
            ],
            [
              -89.250447,
              39.028145
            ],
            [
              -89.2574,
              38.999151
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "049",
      "COUNTYNS": "00424226",
      "AFFGEOID": "0500000US17049",
      "GEOID": "17049",
      "NAME": "Effingham",
      "LSAD": "06",
      "ALAND": 1240027659,
      "AWATER": 3183217,
      "County_state": "Effingham,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.805325,
              39.216263
            ],
            [
              -88.806789,
              38.911655
            ],
            [
              -88.693531,
              38.914617
            ],
            [
              -88.57991,
              38.915156
            ],
            [
              -88.361745,
              38.910847
            ],
            [
              -88.360654,
              39.171118
            ],
            [
              -88.467387,
              39.171481
            ],
            [
              -88.470865,
              39.171463
            ],
            [
              -88.470906,
              39.215029
            ],
            [
              -88.805325,
              39.216263
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "093",
      "COUNTYNS": "00424248",
      "AFFGEOID": "0500000US17093",
      "GEOID": "17093",
      "NAME": "Kendall",
      "LSAD": "06",
      "ALAND": 829446935,
      "AWATER": 5141326,
      "County_state": "Kendall,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.603488,
              41.713584
            ],
            [
              -88.60224,
              41.631389
            ],
            [
              -88.59596,
              41.457034
            ],
            [
              -88.252231,
              41.463066
            ],
            [
              -88.25429,
              41.520721
            ],
            [
              -88.255348,
              41.550089
            ],
            [
              -88.257274,
              41.593469
            ],
            [
              -88.25912,
              41.6362
            ],
            [
              -88.26081,
              41.695843
            ],
            [
              -88.261273,
              41.724534
            ],
            [
              -88.261952,
              41.724652
            ],
            [
              -88.31949,
              41.723426
            ],
            [
              -88.37131,
              41.7227
            ],
            [
              -88.37532,
              41.72261
            ],
            [
              -88.4045,
              41.72227
            ],
            [
              -88.489188,
              41.721128
            ],
            [
              -88.489852,
              41.721119
            ],
            [
              -88.601933,
              41.719563
            ],
            [
              -88.603488,
              41.713584
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "095",
      "COUNTYNS": "00424249",
      "AFFGEOID": "0500000US17095",
      "GEOID": "17095",
      "NAME": "Knox",
      "LSAD": "06",
      "ALAND": 1855506547,
      "AWATER": 8834348,
      "County_state": "Knox,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.440873,
              40.947669
            ],
            [
              -90.444343,
              40.714667
            ],
            [
              -90.329713,
              40.714108
            ],
            [
              -90.215267,
              40.713598
            ],
            [
              -89.986068,
              40.712257
            ],
            [
              -89.985479,
              40.974495
            ],
            [
              -89.984559,
              41.149366
            ],
            [
              -90.437657,
              41.151252
            ],
            [
              -90.439423,
              41.063995
            ],
            [
              -90.440873,
              40.947669
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "099",
      "COUNTYNS": "00422247",
      "AFFGEOID": "0500000US17099",
      "GEOID": "17099",
      "NAME": "LaSalle",
      "LSAD": "06",
      "ALAND": 2939990781,
      "AWATER": 33693285,
      "County_state": "LaSalle,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.1672,
              41.628707
            ],
            [
              -89.16703,
              41.617888
            ],
            [
              -89.166561,
              41.585289
            ],
            [
              -89.166508,
              41.548847
            ],
            [
              -89.166542,
              41.495832
            ],
            [
              -89.163705,
              41.310187
            ],
            [
              -89.16262,
              41.192312
            ],
            [
              -89.162238,
              41.10408
            ],
            [
              -89.047856,
              41.104781
            ],
            [
              -89.047718,
              40.925749
            ],
            [
              -88.93139,
              40.92774
            ],
            [
              -88.93073,
              41.072154
            ],
            [
              -88.930881,
              41.1059
            ],
            [
              -88.81221,
              41.10652
            ],
            [
              -88.777931,
              41.106657
            ],
            [
              -88.58624,
              41.108293
            ],
            [
              -88.59596,
              41.457034
            ],
            [
              -88.60224,
              41.631389
            ],
            [
              -88.654211,
              41.631036
            ],
            [
              -88.706368,
              41.630375
            ],
            [
              -88.712444,
              41.630491
            ],
            [
              -88.818233,
              41.631351
            ],
            [
              -88.938618,
              41.628319
            ],
            [
              -89.045109,
              41.62669
            ],
            [
              -89.1672,
              41.628707
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "179",
      "COUNTYNS": "01785094",
      "AFFGEOID": "0500000US17179",
      "GEOID": "17179",
      "NAME": "Tazewell",
      "LSAD": "06",
      "ALAND": 1674232803,
      "AWATER": 29822303,
      "County_state": "Tazewell,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.908543,
              40.445278
            ],
            [
              -89.92468,
              40.435921
            ],
            [
              -89.82819,
              40.434387
            ],
            [
              -89.717104,
              40.435655
            ],
            [
              -89.714927,
              40.319218
            ],
            [
              -89.602979,
              40.320129
            ],
            [
              -89.26374,
              40.325344
            ],
            [
              -89.266499,
              40.485552
            ],
            [
              -89.26939,
              40.594329
            ],
            [
              -89.269831,
              40.616097
            ],
            [
              -89.327343,
              40.615566
            ],
            [
              -89.330167,
              40.748257
            ],
            [
              -89.554994,
              40.747637
            ],
            [
              -89.546488,
              40.720205
            ],
            [
              -89.553374,
              40.706503
            ],
            [
              -89.554879,
              40.703553
            ],
            [
              -89.557996,
              40.700737
            ],
            [
              -89.592157,
              40.685015
            ],
            [
              -89.615399,
              40.664437
            ],
            [
              -89.610339,
              40.65199
            ],
            [
              -89.636401,
              40.623238
            ],
            [
              -89.658502,
              40.601558
            ],
            [
              -89.654294,
              40.579782
            ],
            [
              -89.658003,
              40.567438
            ],
            [
              -89.675404,
              40.553938
            ],
            [
              -89.691848,
              40.560518
            ],
            [
              -89.73905,
              40.551142
            ],
            [
              -89.770412,
              40.553149
            ],
            [
              -89.812459,
              40.541104
            ],
            [
              -89.872463,
              40.513127
            ],
            [
              -89.882194,
              40.505402
            ],
            [
              -89.885235,
              40.49248
            ],
            [
              -89.889067,
              40.473068
            ],
            [
              -89.908543,
              40.445278
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "021",
      "COUNTYNS": "00424212",
      "AFFGEOID": "0500000US17021",
      "GEOID": "17021",
      "NAME": "Christian",
      "LSAD": "06",
      "ALAND": 1837278858,
      "AWATER": 16221514,
      "County_state": "Christian,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.535028,
              39.641138
            ],
            [
              -89.534998,
              39.612226
            ],
            [
              -89.533811,
              39.539114
            ],
            [
              -89.533655,
              39.524592
            ],
            [
              -89.530836,
              39.348864
            ],
            [
              -89.139807,
              39.348888
            ],
            [
              -89.025453,
              39.346115
            ],
            [
              -89.02568,
              39.654183
            ],
            [
              -89.025691,
              39.654637
            ],
            [
              -89.138951,
              39.655136
            ],
            [
              -89.139673,
              39.687581
            ],
            [
              -89.141817,
              39.80091
            ],
            [
              -89.18797,
              39.817815
            ],
            [
              -89.217523,
              39.813224
            ],
            [
              -89.233523,
              39.813159
            ],
            [
              -89.246532,
              39.826085
            ],
            [
              -89.260966,
              39.805093
            ],
            [
              -89.296367,
              39.789879
            ],
            [
              -89.303122,
              39.775813
            ],
            [
              -89.329152,
              39.768804
            ],
            [
              -89.353958,
              39.772107
            ],
            [
              -89.362605,
              39.755799
            ],
            [
              -89.373718,
              39.758707
            ],
            [
              -89.397461,
              39.742095
            ],
            [
              -89.409475,
              39.74353
            ],
            [
              -89.425171,
              39.762022
            ],
            [
              -89.425535,
              39.747676
            ],
            [
              -89.425051,
              39.683696
            ],
            [
              -89.478923,
              39.683964
            ],
            [
              -89.478473,
              39.640842
            ],
            [
              -89.535028,
              39.641138
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "171",
      "COUNTYNS": "00424286",
      "AFFGEOID": "0500000US17171",
      "GEOID": "17171",
      "NAME": "Scott",
      "LSAD": "06",
      "ALAND": 649861236,
      "AWATER": 4812706,
      "County_state": "Scott,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.64599,
              39.703368
            ],
            [
              -90.633491,
              39.673008
            ],
            [
              -90.607668,
              39.637483
            ],
            [
              -90.601116,
              39.610474
            ],
            [
              -90.57662,
              39.559536
            ],
            [
              -90.572293,
              39.539166
            ],
            [
              -90.581055,
              39.521728
            ],
            [
              -90.301801,
              39.520192
            ],
            [
              -90.300666,
              39.636541
            ],
            [
              -90.338123,
              39.63672
            ],
            [
              -90.337812,
              39.665583
            ],
            [
              -90.372969,
              39.665781
            ],
            [
              -90.371253,
              39.753388
            ],
            [
              -90.483385,
              39.753434
            ],
            [
              -90.483063,
              39.78924
            ],
            [
              -90.599105,
              39.79006
            ],
            [
              -90.606247,
              39.768431
            ],
            [
              -90.64599,
              39.703368
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "063",
      "COUNTYNS": "00424233",
      "AFFGEOID": "0500000US17063",
      "GEOID": "17063",
      "NAME": "Grundy",
      "LSAD": "06",
      "ALAND": 1082840997,
      "AWATER": 32125709,
      "County_state": "Grundy,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.59596,
              41.457034
            ],
            [
              -88.58624,
              41.108293
            ],
            [
              -88.35599,
              41.11175
            ],
            [
              -88.251995,
              41.114229
            ],
            [
              -88.240889,
              41.114383
            ],
            [
              -88.244155,
              41.201546
            ],
            [
              -88.246993,
              41.288579
            ],
            [
              -88.249188,
              41.375691
            ],
            [
              -88.25071,
              41.4191
            ],
            [
              -88.252219,
              41.462753
            ],
            [
              -88.252231,
              41.463066
            ],
            [
              -88.59596,
              41.457034
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "081",
      "COUNTYNS": "00424242",
      "AFFGEOID": "0500000US17081",
      "GEOID": "17081",
      "NAME": "Jefferson",
      "LSAD": "06",
      "ALAND": 1479314410,
      "AWATER": 32679991,
      "County_state": "Jefferson,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.147416,
              38.212896
            ],
            [
              -89.149739,
              38.124945
            ],
            [
              -89.129637,
              38.124747
            ],
            [
              -88.704606,
              38.125195
            ],
            [
              -88.702391,
              38.256661
            ],
            [
              -88.698961,
              38.474914
            ],
            [
              -88.922428,
              38.476969
            ],
            [
              -89.033155,
              38.473963
            ],
            [
              -89.144388,
              38.473878
            ],
            [
              -89.145472,
              38.387436
            ],
            [
              -89.147416,
              38.212896
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "155",
      "COUNTYNS": "00424279",
      "AFFGEOID": "0500000US17155",
      "GEOID": "17155",
      "NAME": "Putnam",
      "LSAD": "06",
      "ALAND": 414813860,
      "AWATER": 31243032,
      "County_state": "Putnam,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.466534,
              41.233873
            ],
            [
              -89.466421,
              41.148558
            ],
            [
              -89.32927,
              41.147931
            ],
            [
              -89.333484,
              41.123673
            ],
            [
              -89.342248,
              41.11138
            ],
            [
              -89.358597,
              41.103665
            ],
            [
              -89.162238,
              41.10408
            ],
            [
              -89.16262,
              41.192312
            ],
            [
              -89.163705,
              41.310187
            ],
            [
              -89.172539,
              41.30846
            ],
            [
              -89.224854,
              41.3131
            ],
            [
              -89.267994,
              41.322034
            ],
            [
              -89.283466,
              41.312385
            ],
            [
              -89.334901,
              41.300877
            ],
            [
              -89.337083,
              41.281667
            ],
            [
              -89.356671,
              41.233235
            ],
            [
              -89.393947,
              41.2337
            ],
            [
              -89.466534,
              41.233873
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "121",
      "COUNTYNS": "00424259",
      "AFFGEOID": "0500000US17121",
      "GEOID": "17121",
      "NAME": "Marion",
      "LSAD": "06",
      "ALAND": 1482448747,
      "AWATER": 9481542,
      "County_state": "Marion,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.143898,
              38.503088
            ],
            [
              -89.144388,
              38.473878
            ],
            [
              -89.033155,
              38.473963
            ],
            [
              -88.922428,
              38.476969
            ],
            [
              -88.698961,
              38.474914
            ],
            [
              -88.698457,
              38.606304
            ],
            [
              -88.695165,
              38.826299
            ],
            [
              -89.030633,
              38.822869
            ],
            [
              -89.138138,
              38.824244
            ],
            [
              -89.138393,
              38.736331
            ],
            [
              -89.139589,
              38.648838
            ],
            [
              -89.143898,
              38.503088
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "073",
      "COUNTYNS": "00424238",
      "AFFGEOID": "0500000US17073",
      "GEOID": "17073",
      "NAME": "Henry",
      "LSAD": "06",
      "ALAND": 2131552073,
      "AWATER": 6862245,
      "County_state": "Henry,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.433771,
              41.326983
            ],
            [
              -90.437657,
              41.151252
            ],
            [
              -89.984559,
              41.149366
            ],
            [
              -89.875105,
              41.149507
            ],
            [
              -89.868384,
              41.148955
            ],
            [
              -89.867738,
              41.23443
            ],
            [
              -89.857798,
              41.234483
            ],
            [
              -89.856619,
              41.320956
            ],
            [
              -89.857406,
              41.518189
            ],
            [
              -89.862351,
              41.584005
            ],
            [
              -90.185609,
              41.584653
            ],
            [
              -90.179098,
              41.573487
            ],
            [
              -90.192498,
              41.543213
            ],
            [
              -90.241089,
              41.524995
            ],
            [
              -90.332657,
              41.514001
            ],
            [
              -90.369427,
              41.496734
            ],
            [
              -90.376528,
              41.478734
            ],
            [
              -90.43193,
              41.456834
            ],
            [
              -90.432273,
              41.414171
            ],
            [
              -90.433771,
              41.326983
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "117",
      "COUNTYNS": "00424257",
      "AFFGEOID": "0500000US17117",
      "GEOID": "17117",
      "NAME": "Macoupin",
      "LSAD": "06",
      "ALAND": 2234952037,
      "AWATER": 12253242,
      "County_state": "Macoupin,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.15375,
              39.520315
            ],
            [
              -90.148069,
              39.261947
            ],
            [
              -90.146299,
              39.174289
            ],
            [
              -90.145991,
              39.000046
            ],
            [
              -90.118012,
              38.998979
            ],
            [
              -90.041555,
              38.998642
            ],
            [
              -89.698555,
              38.998979
            ],
            [
              -89.703482,
              39.332938
            ],
            [
              -89.699094,
              39.348049
            ],
            [
              -89.701645,
              39.523369
            ],
            [
              -89.813147,
              39.522838
            ],
            [
              -89.926037,
              39.522104
            ],
            [
              -90.15375,
              39.520315
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "039",
      "COUNTYNS": "00426598",
      "AFFGEOID": "0500000US17039",
      "GEOID": "17039",
      "NAME": "De Witt",
      "LSAD": "06",
      "ALAND": 1029643576,
      "AWATER": 19519851,
      "County_state": "De Witt,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.148764,
              40.282038
            ],
            [
              -89.144764,
              40.048853
            ],
            [
              -88.745164,
              40.055191
            ],
            [
              -88.745344,
              40.098813
            ],
            [
              -88.688095,
              40.098695
            ],
            [
              -88.687993,
              40.142269
            ],
            [
              -88.574885,
              40.281501
            ],
            [
              -88.910853,
              40.282436
            ],
            [
              -88.919544,
              40.282865
            ],
            [
              -89.034711,
              40.282797
            ],
            [
              -89.052067,
              40.283159
            ],
            [
              -89.148764,
              40.282038
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "109",
      "COUNTYNS": "01784729",
      "AFFGEOID": "0500000US17109",
      "GEOID": "17109",
      "NAME": "McDonough",
      "LSAD": "06",
      "ALAND": 1526558090,
      "AWATER": 2012555,
      "County_state": "McDonough,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.904216,
              40.639201
            ],
            [
              -90.909756,
              40.284394
            ],
            [
              -90.450227,
              40.276335
            ],
            [
              -90.445506,
              40.627843
            ],
            [
              -90.789652,
              40.63575
            ],
            [
              -90.904216,
              40.639201
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "191",
      "COUNTYNS": "00424296",
      "AFFGEOID": "0500000US17191",
      "GEOID": "17191",
      "NAME": "Wayne",
      "LSAD": "06",
      "ALAND": 1848771790,
      "AWATER": 4328965,
      "County_state": "Wayne,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.698961,
              38.474914
            ],
            [
              -88.702391,
              38.256661
            ],
            [
              -88.370491,
              38.255413
            ],
            [
              -88.1509,
              38.256097
            ],
            [
              -88.147814,
              38.569086
            ],
            [
              -88.148044,
              38.597988
            ],
            [
              -88.253851,
              38.599502
            ],
            [
              -88.564109,
              38.607093
            ],
            [
              -88.698457,
              38.606304
            ],
            [
              -88.698961,
              38.474914
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "163",
      "COUNTYNS": "01784987",
      "AFFGEOID": "0500000US17163",
      "GEOID": "17163",
      "NAME": "St. Clair",
      "LSAD": "06",
      "ALAND": 1703659884,
      "AWATER": 42054455,
      "County_state": "St. Clair,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.260314,
              38.528352
            ],
            [
              -90.2637229314809,
              38.5207549526996
            ],
            [
              -90.145336,
              38.426266
            ],
            [
              -90.145608,
              38.412153
            ],
            [
              -90.036078,
              38.323347
            ],
            [
              -90.036018,
              38.30885
            ],
            [
              -89.913226,
              38.307829
            ],
            [
              -89.904082,
              38.29763
            ],
            [
              -89.908756,
              38.276372
            ],
            [
              -89.92,
              38.28569
            ],
            [
              -89.924976,
              38.273234
            ],
            [
              -89.913418,
              38.256294
            ],
            [
              -89.908938,
              38.238158
            ],
            [
              -89.896723,
              38.230221
            ],
            [
              -89.899061,
              38.220755
            ],
            [
              -89.703256,
              38.219404
            ],
            [
              -89.704417,
              38.415667
            ],
            [
              -89.707024,
              38.655152
            ],
            [
              -89.928401,
              38.657748
            ],
            [
              -90.037105,
              38.658449
            ],
            [
              -90.089736,
              38.659454
            ],
            [
              -90.094607,
              38.659749
            ],
            [
              -90.168909,
              38.66065
            ],
            [
              -90.1813250257207,
              38.6603814327868
            ],
            [
              -90.18111,
              38.65955
            ],
            [
              -90.1810494295439,
              38.6592507106875
            ],
            [
              -90.17771,
              38.64275
            ],
            [
              -90.1777132808621,
              38.6426515741363
            ],
            [
              -90.17801,
              38.63375
            ],
            [
              -90.17881,
              38.62915
            ],
            [
              -90.18451,
              38.611551
            ],
            [
              -90.18664790603499,
              38.6078614210325
            ],
            [
              -90.191811,
              38.598951
            ],
            [
              -90.196011,
              38.594451
            ],
            [
              -90.202511,
              38.588651
            ],
            [
              -90.2055396773137,
              38.58677800218759
            ],
            [
              -90.210111,
              38.583951
            ],
            [
              -90.216712,
              38.578751
            ],
            [
              -90.222112,
              38.576451
            ],
            [
              -90.224212,
              38.575051
            ],
            [
              -90.248913,
              38.544752
            ],
            [
              -90.2502824164961,
              38.542782135028794
            ],
            [
              -90.2577725855853,
              38.53200774918
            ],
            [
              -90.260314,
              38.528352
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "079",
      "COUNTYNS": "00424241",
      "AFFGEOID": "0500000US17079",
      "GEOID": "17079",
      "NAME": "Jasper",
      "LSAD": "06",
      "ALAND": 1280788076,
      "AWATER": 9334509,
      "County_state": "Jasper,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.361745,
              38.910847
            ],
            [
              -88.36176,
              38.851949
            ],
            [
              -88.258608,
              38.847521
            ],
            [
              -87.945923,
              38.850108
            ],
            [
              -87.948222,
              39.083828
            ],
            [
              -87.950385,
              39.174882
            ],
            [
              -88.007766,
              39.173925
            ],
            [
              -88.360654,
              39.171118
            ],
            [
              -88.361745,
              38.910847
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "035",
      "COUNTYNS": "00424219",
      "AFFGEOID": "0500000US17035",
      "GEOID": "17035",
      "NAME": "Cumberland",
      "LSAD": "06",
      "ALAND": 896203087,
      "AWATER": 2531272,
      "County_state": "Cumberland,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.470906,
              39.215029
            ],
            [
              -88.470865,
              39.171463
            ],
            [
              -88.467387,
              39.171481
            ],
            [
              -88.360654,
              39.171118
            ],
            [
              -88.007766,
              39.173925
            ],
            [
              -88.012121,
              39.378968
            ],
            [
              -88.021796,
              39.3787
            ],
            [
              -88.026521,
              39.377627
            ],
            [
              -88.47083,
              39.374515
            ],
            [
              -88.470906,
              39.215029
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "005",
      "COUNTYNS": "00424204",
      "AFFGEOID": "0500000US17005",
      "GEOID": "17005",
      "NAME": "Bond",
      "LSAD": "06",
      "ALAND": 984918649,
      "AWATER": 6426353,
      "County_state": "Bond,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.639265,
              38.999129
            ],
            [
              -89.637049,
              38.918364
            ],
            [
              -89.637831,
              38.918353
            ],
            [
              -89.636874,
              38.874278
            ],
            [
              -89.599593,
              38.87453
            ],
            [
              -89.597321,
              38.743236
            ],
            [
              -89.500076,
              38.740752
            ],
            [
              -89.254237,
              38.742019
            ],
            [
              -89.256708,
              38.917173
            ],
            [
              -89.2574,
              38.999151
            ],
            [
              -89.250447,
              39.028145
            ],
            [
              -89.586088,
              39.028246
            ],
            [
              -89.586148,
              38.999449
            ],
            [
              -89.639265,
              38.999129
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "045",
      "COUNTYNS": "00424224",
      "AFFGEOID": "0500000US17045",
      "GEOID": "17045",
      "NAME": "Edgar",
      "LSAD": "06",
      "ALAND": 1614542009,
      "AWATER": 1600421,
      "County_state": "Edgar,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.968777,
              39.79235
            ],
            [
              -87.9666,
              39.685928
            ],
            [
              -87.965698,
              39.642338
            ],
            [
              -87.960179,
              39.481309
            ],
            [
              -87.764705,
              39.486065
            ],
            [
              -87.687437,
              39.48734
            ],
            [
              -87.689166,
              39.476758
            ],
            [
              -87.5316629504044,
              39.47711015463629
            ],
            [
              -87.531663,
              39.47712
            ],
            [
              -87.531627,
              39.491698
            ],
            [
              -87.531692,
              39.495516
            ],
            [
              -87.53190785893749,
              39.5203603358081
            ],
            [
              -87.531965,
              39.526937
            ],
            [
              -87.531939,
              39.545853
            ],
            [
              -87.532008,
              39.564013
            ],
            [
              -87.5321962235157,
              39.6073059903329
            ],
            [
              -87.532365,
              39.646126
            ],
            [
              -87.532444,
              39.646102
            ],
            [
              -87.532703,
              39.664868
            ],
            [
              -87.533066,
              39.781743
            ],
            [
              -87.533058,
              39.796243
            ],
            [
              -87.533056,
              39.803922
            ],
            [
              -87.533142,
              39.810947
            ],
            [
              -87.5332268504449,
              39.88300000135369
            ],
            [
              -87.581553,
              39.88229
            ],
            [
              -87.557513,
              39.868719
            ],
            [
              -87.617424,
              39.868489
            ],
            [
              -87.615182,
              39.88163
            ],
            [
              -87.80371,
              39.88069
            ],
            [
              -87.937645,
              39.879803
            ],
            [
              -87.937029,
              39.792371
            ],
            [
              -87.968777,
              39.79235
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "141",
      "COUNTYNS": "01784894",
      "AFFGEOID": "0500000US17141",
      "GEOID": "17141",
      "NAME": "Ogle",
      "LSAD": "06",
      "ALAND": 1964706939,
      "AWATER": 11425029,
      "County_state": "Ogle,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.688486,
              42.199112
            ],
            [
              -89.684695,
              42.025788
            ],
            [
              -89.686549,
              41.937493
            ],
            [
              -89.685366,
              41.93034
            ],
            [
              -89.628907,
              41.930044
            ],
            [
              -89.62933,
              41.901617
            ],
            [
              -89.481335,
              41.902241
            ],
            [
              -89.472552,
              41.911939
            ],
            [
              -89.423379,
              41.907051
            ],
            [
              -89.428648,
              41.89404
            ],
            [
              -89.414627,
              41.886175
            ],
            [
              -89.403108,
              41.902273
            ],
            [
              -89.36052,
              41.902641
            ],
            [
              -89.360682,
              41.88807
            ],
            [
              -88.941279,
              41.891752
            ],
            [
              -88.941625,
              41.935387
            ],
            [
              -88.938936,
              42.065068
            ],
            [
              -88.939732,
              42.15232
            ],
            [
              -89.091831,
              42.150681
            ],
            [
              -89.172799,
              42.150297
            ],
            [
              -89.173,
              42.204241
            ],
            [
              -89.337195,
              42.20237
            ],
            [
              -89.396192,
              42.201916
            ],
            [
              -89.688486,
              42.199112
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "085",
      "COUNTYNS": "00424244",
      "AFFGEOID": "0500000US17085",
      "GEOID": "17085",
      "NAME": "Jo Daviess",
      "LSAD": "06",
      "ALAND": 1556339187,
      "AWATER": 45776828,
      "County_state": "Jo Daviess,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.656527,
              42.489203
            ],
            [
              -90.656327,
              42.483603
            ],
            [
              -90.654027,
              42.478503
            ],
            [
              -90.646727,
              42.471904
            ],
            [
              -90.64090030956581,
              42.468522287618
            ],
            [
              -90.624328,
              42.458904
            ],
            [
              -90.606328,
              42.451505
            ],
            [
              -90.590416,
              42.447493
            ],
            [
              -90.582128,
              42.444437
            ],
            [
              -90.570736,
              42.441701
            ],
            [
              -90.567968,
              42.440389
            ],
            [
              -90.565248,
              42.438742
            ],
            [
              -90.560439,
              42.432897
            ],
            [
              -90.558801,
              42.428517
            ],
            [
              -90.558168,
              42.420984
            ],
            [
              -90.55755,
              42.419258
            ],
            [
              -90.555018,
              42.416138
            ],
            [
              -90.548068,
              42.413115
            ],
            [
              -90.517516,
              42.403019
            ],
            [
              -90.506829,
              42.398792
            ],
            [
              -90.500128,
              42.395539
            ],
            [
              -90.495766,
              42.392406
            ],
            [
              -90.490334,
              42.387093
            ],
            [
              -90.487154,
              42.385141
            ],
            [
              -90.484621,
              42.38453
            ],
            [
              -90.480148,
              42.384616
            ],
            [
              -90.477279,
              42.383794
            ],
            [
              -90.474121,
              42.381729
            ],
            [
              -90.47381216765301,
              42.38145820989109
            ],
            [
              -90.470273,
              42.378355
            ],
            [
              -90.464788,
              42.369452
            ],
            [
              -90.462619,
              42.367253
            ],
            [
              -90.452724,
              42.359303
            ],
            [
              -90.44632,
              42.357041
            ],
            [
              -90.443874,
              42.355218
            ],
            [
              -90.430546,
              42.33686
            ],
            [
              -90.425363,
              42.332615
            ],
            [
              -90.42135,
              42.330472
            ],
            [
              -90.419027,
              42.328505
            ],
            [
              -90.416535,
              42.325109
            ],
            [
              -90.415937,
              42.322699
            ],
            [
              -90.4162,
              42.321314
            ],
            [
              -90.417125,
              42.319943
            ],
            [
              -90.420075,
              42.317681
            ],
            [
              -90.421047,
              42.316109
            ],
            [
              -90.4203,
              42.31169
            ],
            [
              -90.420454,
              42.305374
            ],
            [
              -90.424326,
              42.293326
            ],
            [
              -90.426909,
              42.290719
            ],
            [
              -90.430735,
              42.284211
            ],
            [
              -90.430884,
              42.27823
            ],
            [
              -90.424098,
              42.266364
            ],
            [
              -90.422181,
              42.259899
            ],
            [
              -90.419326,
              42.254467
            ],
            [
              -90.416315,
              42.251679
            ],
            [
              -90.410471,
              42.247749
            ],
            [
              -90.400653,
              42.239293
            ],
            [
              -90.395883,
              42.233133
            ],
            [
              -90.394749,
              42.229059
            ],
            [
              -90.391108,
              42.225473
            ],
            [
              -90.375129,
              42.214811
            ],
            [
              -90.365138,
              42.210526
            ],
            [
              -90.356964,
              42.205445
            ],
            [
              -90.338169,
              42.203321
            ],
            [
              -90.328273,
              42.201047
            ],
            [
              -90.31794761822601,
              42.1939090229626
            ],
            [
              -90.096038,
              42.197108
            ],
            [
              -89.91965,
              42.196824
            ],
            [
              -89.92700706749518,
              42.5057840766179
            ],
            [
              -89.955291,
              42.505626
            ],
            [
              -89.985072,
              42.506464
            ],
            [
              -89.985645,
              42.506431
            ],
            [
              -89.997213,
              42.506755
            ],
            [
              -89.999314,
              42.506914
            ],
            [
              -90.017028,
              42.507127
            ],
            [
              -90.018665,
              42.507288
            ],
            [
              -90.07367,
              42.508275
            ],
            [
              -90.093026,
              42.50816
            ],
            [
              -90.095004,
              42.507885
            ],
            [
              -90.142922,
              42.508151
            ],
            [
              -90.164363,
              42.508272
            ],
            [
              -90.181572,
              42.508068
            ],
            [
              -90.206073,
              42.507747
            ],
            [
              -90.22319,
              42.507765
            ],
            [
              -90.250622,
              42.507521
            ],
            [
              -90.253121,
              42.50734
            ],
            [
              -90.267143,
              42.507642
            ],
            [
              -90.269335,
              42.507726
            ],
            [
              -90.272864,
              42.507531
            ],
            [
              -90.303823,
              42.507469
            ],
            [
              -90.362652,
              42.507048
            ],
            [
              -90.367874,
              42.507114
            ],
            [
              -90.36796275766869,
              42.5071139048685
            ],
            [
              -90.370673,
              42.507111
            ],
            [
              -90.405927,
              42.506891
            ],
            [
              -90.4263779353442,
              42.507059428755895
            ],
            [
              -90.437011,
              42.507147
            ],
            [
              -90.474955,
              42.507484
            ],
            [
              -90.479446,
              42.507416
            ],
            [
              -90.491716,
              42.507624
            ],
            [
              -90.532254,
              42.507573
            ],
            [
              -90.544347,
              42.507707
            ],
            [
              -90.544799,
              42.507713
            ],
            [
              -90.551165,
              42.507691
            ],
            [
              -90.555862,
              42.507509
            ],
            [
              -90.565441,
              42.5076
            ],
            [
              -90.614589,
              42.508053
            ],
            [
              -90.617731,
              42.508077
            ],
            [
              -90.642843,
              42.508481
            ],
            [
              -90.648627,
              42.498102
            ],
            [
              -90.655927,
              42.491703
            ],
            [
              -90.6559328003026,
              42.4916788320726
            ],
            [
              -90.656527,
              42.489203
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "057",
      "COUNTYNS": "00424230",
      "AFFGEOID": "0500000US17057",
      "GEOID": "17057",
      "NAME": "Fulton",
      "LSAD": "06",
      "ALAND": 2241880793,
      "AWATER": 43973314,
      "County_state": "Fulton,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.450227,
              40.276335
            ],
            [
              -90.451502,
              40.188892
            ],
            [
              -90.199556,
              40.183945
            ],
            [
              -90.180809,
              40.196014
            ],
            [
              -90.157961,
              40.219655
            ],
            [
              -90.137942,
              40.222593
            ],
            [
              -90.118966,
              40.235263
            ],
            [
              -90.107293,
              40.2609
            ],
            [
              -90.09668,
              40.266685
            ],
            [
              -90.070622,
              40.293192
            ],
            [
              -90.06871,
              40.313888
            ],
            [
              -90.050548,
              40.334229
            ],
            [
              -90.052796,
              40.347476
            ],
            [
              -90.046044,
              40.364523
            ],
            [
              -90.03795,
              40.375041
            ],
            [
              -89.983758,
              40.392636
            ],
            [
              -89.948203,
              40.424918
            ],
            [
              -89.92468,
              40.435921
            ],
            [
              -89.908543,
              40.445278
            ],
            [
              -89.889067,
              40.473068
            ],
            [
              -89.885235,
              40.49248
            ],
            [
              -89.882194,
              40.505402
            ],
            [
              -89.872463,
              40.513127
            ],
            [
              -89.87351,
              40.624482
            ],
            [
              -89.989018,
              40.625835
            ],
            [
              -89.986068,
              40.712257
            ],
            [
              -90.215267,
              40.713598
            ],
            [
              -90.329713,
              40.714108
            ],
            [
              -90.444343,
              40.714667
            ],
            [
              -90.445506,
              40.627843
            ],
            [
              -90.450227,
              40.276335
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "011",
      "COUNTYNS": "00424207",
      "AFFGEOID": "0500000US17011",
      "GEOID": "17011",
      "NAME": "Bureau",
      "LSAD": "06",
      "ALAND": 2250785990,
      "AWATER": 11578416,
      "County_state": "Bureau,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.862351,
              41.584005
            ],
            [
              -89.857406,
              41.518189
            ],
            [
              -89.856619,
              41.320956
            ],
            [
              -89.857798,
              41.234483
            ],
            [
              -89.638864,
              41.233862
            ],
            [
              -89.638429,
              41.148591
            ],
            [
              -89.466421,
              41.148558
            ],
            [
              -89.466534,
              41.233873
            ],
            [
              -89.393947,
              41.2337
            ],
            [
              -89.356671,
              41.233235
            ],
            [
              -89.337083,
              41.281667
            ],
            [
              -89.334901,
              41.300877
            ],
            [
              -89.283466,
              41.312385
            ],
            [
              -89.267994,
              41.322034
            ],
            [
              -89.224854,
              41.3131
            ],
            [
              -89.172539,
              41.30846
            ],
            [
              -89.163705,
              41.310187
            ],
            [
              -89.166542,
              41.495832
            ],
            [
              -89.166508,
              41.548847
            ],
            [
              -89.166561,
              41.585289
            ],
            [
              -89.631494,
              41.584949
            ],
            [
              -89.862351,
              41.584005
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "177",
      "COUNTYNS": "01785076",
      "AFFGEOID": "0500000US17177",
      "GEOID": "17177",
      "NAME": "Stephenson",
      "LSAD": "06",
      "ALAND": 1462175613,
      "AWATER": 1385929,
      "County_state": "Stephenson,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.926484,
              42.505787
            ],
            [
              -89.92700706749518,
              42.5057840766179
            ],
            [
              -89.91965,
              42.196824
            ],
            [
              -89.913238,
              42.19628
            ],
            [
              -89.688486,
              42.199112
            ],
            [
              -89.396192,
              42.201916
            ],
            [
              -89.397809,
              42.307171
            ],
            [
              -89.40141639302949,
              42.5004329299029
            ],
            [
              -89.401432,
              42.500433
            ],
            [
              -89.420991,
              42.500589
            ],
            [
              -89.422567,
              42.50068
            ],
            [
              -89.423926,
              42.500818
            ],
            [
              -89.425162,
              42.500726
            ],
            [
              -89.4843,
              42.501426
            ],
            [
              -89.492612,
              42.501514
            ],
            [
              -89.493216,
              42.501514
            ],
            [
              -89.522542,
              42.501889
            ],
            [
              -89.564407,
              42.502628
            ],
            [
              -89.594779,
              42.503468
            ],
            [
              -89.600001,
              42.503672
            ],
            [
              -89.6014368365924,
              42.503625117203796
            ],
            [
              -89.603523,
              42.503557
            ],
            [
              -89.61341,
              42.503942
            ],
            [
              -89.644176,
              42.50452
            ],
            [
              -89.650324,
              42.504613
            ],
            [
              -89.667596,
              42.50496
            ],
            [
              -89.690088,
              42.505191
            ],
            [
              -89.693487,
              42.505099
            ],
            [
              -89.742395,
              42.505382
            ],
            [
              -89.769643,
              42.505322
            ],
            [
              -89.780302,
              42.505349
            ],
            [
              -89.793957,
              42.505466
            ],
            [
              -89.799704,
              42.505421
            ],
            [
              -89.801897,
              42.505444
            ],
            [
              -89.8375874625297,
              42.5055426328327
            ],
            [
              -89.926374,
              42.505788
            ],
            [
              -89.926484,
              42.505787
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "149",
      "COUNTYNS": "01784941",
      "AFFGEOID": "0500000US17149",
      "GEOID": "17149",
      "NAME": "Pike",
      "LSAD": "06",
      "ALAND": 2153317672,
      "AWATER": 45283711,
      "County_state": "Pike,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.369953,
              39.745042
            ],
            [
              -91.370009,
              39.732524
            ],
            [
              -91.367753,
              39.729029
            ],
            [
              -91.352749,
              39.715279
            ],
            [
              -91.3453,
              39.709402
            ],
            [
              -91.331603,
              39.700433
            ],
            [
              -91.317814,
              39.692591
            ],
            [
              -91.3066819759012,
              39.6848809168884
            ],
            [
              -91.305348,
              39.683957
            ],
            [
              -91.302485,
              39.679631
            ],
            [
              -91.293788,
              39.674766
            ],
            [
              -91.283329,
              39.670134
            ],
            [
              -91.27614,
              39.665759
            ],
            [
              -91.266765,
              39.656993
            ],
            [
              -91.260475,
              39.649024
            ],
            [
              -91.248779,
              39.64088
            ],
            [
              -91.245914,
              39.637311
            ],
            [
              -91.24356,
              39.633064
            ],
            [
              -91.241225,
              39.630067
            ],
            [
              -91.229317,
              39.620853
            ],
            [
              -91.223328,
              39.617603
            ],
            [
              -91.21664,
              39.615124
            ],
            [
              -91.185921,
              39.605119
            ],
            [
              -91.181936,
              39.602677
            ],
            [
              -91.178012,
              39.598196
            ],
            [
              -91.174651,
              39.593313
            ],
            [
              -91.174232,
              39.591975
            ],
            [
              -91.171641,
              39.581899
            ],
            [
              -91.16982,
              39.569555
            ],
            [
              -91.168419,
              39.564928
            ],
            [
              -91.163634,
              39.558566
            ],
            [
              -91.158606,
              39.553048
            ],
            [
              -91.153628,
              39.548248
            ],
            [
              -91.148275,
              39.545798
            ],
            [
              -91.126638,
              39.542227
            ],
            [
              -91.114305,
              39.541098
            ],
            [
              -91.100307,
              39.538695
            ],
            [
              -91.092869,
              39.529275
            ],
            [
              -91.086292,
              39.517141
            ],
            [
              -91.079769,
              39.507728
            ],
            [
              -91.075309,
              39.502845
            ],
            [
              -91.064305,
              39.494643
            ],
            [
              -91.062414,
              39.474122
            ],
            [
              -91.059439,
              39.46886
            ],
            [
              -91.053058,
              39.462122
            ],
            [
              -91.03827,
              39.448436
            ],
            [
              -91.02361,
              39.438694
            ],
            [
              -91.011954,
              39.432661
            ],
            [
              -91.003692,
              39.427603
            ],
            [
              -90.993789,
              39.422959
            ],
            [
              -90.98302,
              39.420462
            ],
            [
              -90.977618,
              39.41829
            ],
            [
              -90.972465,
              39.414144
            ],
            [
              -90.96748,
              39.411948
            ],
            [
              -90.957459,
              39.408996
            ],
            [
              -90.948299,
              39.407502
            ],
            [
              -90.940766,
              39.403984
            ],
            [
              -90.939025,
              39.402744
            ],
            [
              -90.937419,
              39.400803
            ],
            [
              -90.9367782192347,
              39.399486555729595
            ],
            [
              -90.908735,
              39.400208
            ],
            [
              -90.613694,
              39.395775
            ],
            [
              -90.616807,
              39.423878
            ],
            [
              -90.610502,
              39.457447
            ],
            [
              -90.592092,
              39.486979
            ],
            [
              -90.581055,
              39.521728
            ],
            [
              -90.572293,
              39.539166
            ],
            [
              -90.57662,
              39.559536
            ],
            [
              -90.601116,
              39.610474
            ],
            [
              -90.607668,
              39.637483
            ],
            [
              -90.633491,
              39.673008
            ],
            [
              -90.64599,
              39.703368
            ],
            [
              -90.606247,
              39.768431
            ],
            [
              -90.599105,
              39.79006
            ],
            [
              -90.565737,
              39.83007
            ],
            [
              -90.571754,
              39.839326
            ],
            [
              -90.893651,
              39.84092
            ],
            [
              -90.916609,
              39.845075
            ],
            [
              -90.916071,
              39.757168
            ],
            [
              -91.365125,
              39.758723
            ],
            [
              -91.366047,
              39.755955
            ],
            [
              -91.367406,
              39.75388
            ],
            [
              -91.369953,
              39.745042
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "125",
      "COUNTYNS": "00424261",
      "AFFGEOID": "0500000US17125",
      "GEOID": "17125",
      "NAME": "Mason",
      "LSAD": "06",
      "ALAND": 1396619099,
      "AWATER": 62745252,
      "County_state": "Mason,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.337548,
              40.136092
            ],
            [
              -90.355198,
              40.124245
            ],
            [
              -90.314714,
              40.115992
            ],
            [
              -90.303108,
              40.093224
            ],
            [
              -90.305069,
              40.077253
            ],
            [
              -90.289926,
              40.069597
            ],
            [
              -90.286724,
              40.055321
            ],
            [
              -90.266915,
              40.062288
            ],
            [
              -90.225759,
              40.055052
            ],
            [
              -90.211241,
              40.059707
            ],
            [
              -90.196609,
              40.052499
            ],
            [
              -90.185622,
              40.063403
            ],
            [
              -90.134517,
              40.062023
            ],
            [
              -90.127195,
              40.076706
            ],
            [
              -90.09385,
              40.077643
            ],
            [
              -90.063955,
              40.101088
            ],
            [
              -90.014165,
              40.100417
            ],
            [
              -89.994724,
              40.108373
            ],
            [
              -89.982562,
              40.114377
            ],
            [
              -89.970083,
              40.142264
            ],
            [
              -89.958872,
              40.134361
            ],
            [
              -89.93677,
              40.132445
            ],
            [
              -89.926058,
              40.13995
            ],
            [
              -89.898118,
              40.127795
            ],
            [
              -89.870559,
              40.136684
            ],
            [
              -89.868249,
              40.12972
            ],
            [
              -89.841505,
              40.1298
            ],
            [
              -89.831769,
              40.123534
            ],
            [
              -89.794367,
              40.123805
            ],
            [
              -89.772433,
              40.131774
            ],
            [
              -89.749196,
              40.126852
            ],
            [
              -89.708102,
              40.146603
            ],
            [
              -89.694344,
              40.14091
            ],
            [
              -89.683807,
              40.155006
            ],
            [
              -89.634524,
              40.153575
            ],
            [
              -89.62029,
              40.136224
            ],
            [
              -89.601604,
              40.122432
            ],
            [
              -89.603855,
              40.218372
            ],
            [
              -89.602979,
              40.320129
            ],
            [
              -89.714927,
              40.319218
            ],
            [
              -89.717104,
              40.435655
            ],
            [
              -89.82819,
              40.434387
            ],
            [
              -89.92468,
              40.435921
            ],
            [
              -89.948203,
              40.424918
            ],
            [
              -89.983758,
              40.392636
            ],
            [
              -90.03795,
              40.375041
            ],
            [
              -90.046044,
              40.364523
            ],
            [
              -90.052796,
              40.347476
            ],
            [
              -90.050548,
              40.334229
            ],
            [
              -90.06871,
              40.313888
            ],
            [
              -90.070622,
              40.293192
            ],
            [
              -90.09668,
              40.266685
            ],
            [
              -90.107293,
              40.2609
            ],
            [
              -90.118966,
              40.235263
            ],
            [
              -90.137942,
              40.222593
            ],
            [
              -90.157961,
              40.219655
            ],
            [
              -90.180809,
              40.196014
            ],
            [
              -90.199556,
              40.183945
            ],
            [
              -90.206144,
              40.155789
            ],
            [
              -90.216138,
              40.150459
            ],
            [
              -90.268144,
              40.154563
            ],
            [
              -90.310386,
              40.139296
            ],
            [
              -90.337548,
              40.136092
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "101",
      "COUNTYNS": "00424252",
      "AFFGEOID": "0500000US17101",
      "GEOID": "17101",
      "NAME": "Lawrence",
      "LSAD": "06",
      "ALAND": 963936864,
      "AWATER": 5077778,
      "County_state": "Lawrence,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.910282,
              38.574932
            ],
            [
              -87.912286,
              38.570104
            ],
            [
              -87.651529,
              38.568166
            ],
            [
              -87.637752,
              38.588512
            ],
            [
              -87.629362,
              38.589971
            ],
            [
              -87.626444,
              38.591066
            ],
            [
              -87.62389,
              38.593984
            ],
            [
              -87.624143,
              38.596955
            ],
            [
              -87.627348,
              38.60544
            ],
            [
              -87.622375,
              38.618873
            ],
            [
              -87.62012,
              38.639489
            ],
            [
              -87.593678,
              38.667402
            ],
            [
              -87.545538,
              38.677613
            ],
            [
              -87.53547535600869,
              38.6821305342389
            ],
            [
              -87.531231,
              38.684036
            ],
            [
              -87.519609,
              38.697198
            ],
            [
              -87.516707,
              38.716333
            ],
            [
              -87.5035231219252,
              38.7335490719232
            ],
            [
              -87.496494,
              38.742728
            ],
            [
              -87.498948,
              38.757774
            ],
            [
              -87.49839413077359,
              38.7625516102452
            ],
            [
              -87.496537,
              38.778571
            ],
            [
              -87.527342,
              38.818121
            ],
            [
              -87.521681,
              38.826576
            ],
            [
              -87.525893,
              38.848795
            ],
            [
              -87.5343549340073,
              38.852494647453
            ],
            [
              -87.684141,
              38.852179
            ],
            [
              -87.759592,
              38.849514
            ],
            [
              -87.908113,
              38.850107
            ],
            [
              -87.910282,
              38.574932
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "111",
      "COUNTYNS": "01784815",
      "AFFGEOID": "0500000US17111",
      "GEOID": "17111",
      "NAME": "McHenry",
      "LSAD": "06",
      "ALAND": 1562336883,
      "AWATER": 19832927,
      "County_state": "McHenry,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.7073777163598,
              42.493587048379496
            ],
            [
              -88.70561,
              42.327882
            ],
            [
              -88.705633,
              42.153561
            ],
            [
              -88.588657,
              42.15359
            ],
            [
              -88.472786,
              42.154124
            ],
            [
              -88.393762,
              42.1539
            ],
            [
              -88.3935,
              42.153902
            ],
            [
              -88.354183,
              42.154224
            ],
            [
              -88.32117,
              42.153865
            ],
            [
              -88.316684,
              42.153844
            ],
            [
              -88.257689,
              42.154073
            ],
            [
              -88.238369,
              42.154253
            ],
            [
              -88.199584,
              42.15426
            ],
            [
              -88.198683,
              42.241671
            ],
            [
              -88.198314,
              42.241612
            ],
            [
              -88.198302,
              42.258434
            ],
            [
              -88.198547,
              42.282687
            ],
            [
              -88.198924,
              42.295655
            ],
            [
              -88.198993,
              42.300977
            ],
            [
              -88.198647,
              42.328861
            ],
            [
              -88.198804,
              42.396642
            ],
            [
              -88.19882,
              42.41557
            ],
            [
              -88.198601,
              42.415567
            ],
            [
              -88.1995371176412,
              42.496017518266896
            ],
            [
              -88.200172,
              42.496016
            ],
            [
              -88.2169,
              42.495923
            ],
            [
              -88.25009,
              42.495823
            ],
            [
              -88.2716687936822,
              42.4948190331628
            ],
            [
              -88.271691,
              42.494818
            ],
            [
              -88.304692,
              42.4947727016231
            ],
            [
              -88.417396,
              42.494618
            ],
            [
              -88.461397,
              42.494618
            ],
            [
              -88.506912,
              42.494883
            ],
            [
              -88.5404800500758,
              42.4949237685384
            ],
            [
              -88.638653,
              42.495043
            ],
            [
              -88.7073777163598,
              42.493587048379496
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "089",
      "COUNTYNS": "00424246",
      "AFFGEOID": "0500000US17089",
      "GEOID": "17089",
      "NAME": "Kane",
      "LSAD": "06",
      "ALAND": 1347216944,
      "AWATER": 10775892,
      "County_state": "Kane,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.601992,
              41.734093
            ],
            [
              -88.601933,
              41.719563
            ],
            [
              -88.489852,
              41.721119
            ],
            [
              -88.489188,
              41.721128
            ],
            [
              -88.4045,
              41.72227
            ],
            [
              -88.37532,
              41.72261
            ],
            [
              -88.37131,
              41.7227
            ],
            [
              -88.31949,
              41.723426
            ],
            [
              -88.261952,
              41.724652
            ],
            [
              -88.26237,
              41.771272
            ],
            [
              -88.26237,
              41.77208
            ],
            [
              -88.26279,
              41.812132
            ],
            [
              -88.26279,
              41.812301
            ],
            [
              -88.262492,
              41.882886
            ],
            [
              -88.26241,
              41.899234
            ],
            [
              -88.262876,
              41.986227
            ],
            [
              -88.263371,
              42.011939
            ],
            [
              -88.263282,
              42.01395
            ],
            [
              -88.263255,
              42.015492
            ],
            [
              -88.263378,
              42.066626
            ],
            [
              -88.243326,
              42.067051
            ],
            [
              -88.237978,
              42.066926
            ],
            [
              -88.238291,
              42.092348
            ],
            [
              -88.238369,
              42.154253
            ],
            [
              -88.257689,
              42.154073
            ],
            [
              -88.316684,
              42.153844
            ],
            [
              -88.32117,
              42.153865
            ],
            [
              -88.354183,
              42.154224
            ],
            [
              -88.3935,
              42.153902
            ],
            [
              -88.393762,
              42.1539
            ],
            [
              -88.472786,
              42.154124
            ],
            [
              -88.588657,
              42.15359
            ],
            [
              -88.588344,
              42.110025
            ],
            [
              -88.58833,
              42.066462
            ],
            [
              -88.601958,
              42.066469
            ],
            [
              -88.60156,
              42.017925
            ],
            [
              -88.601992,
              41.734093
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "041",
      "COUNTYNS": "00424222",
      "AFFGEOID": "0500000US17041",
      "GEOID": "17041",
      "NAME": "Douglas",
      "LSAD": "06",
      "ALAND": 1079156592,
      "AWATER": 1448702,
      "County_state": "Douglas,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.473182,
              39.791837
            ],
            [
              -88.472073,
              39.651588
            ],
            [
              -88.063437,
              39.652555
            ],
            [
              -88.063467,
              39.681313
            ],
            [
              -88.026224,
              39.684867
            ],
            [
              -87.9666,
              39.685928
            ],
            [
              -87.968777,
              39.79235
            ],
            [
              -87.937029,
              39.792371
            ],
            [
              -87.937645,
              39.879803
            ],
            [
              -88.349187,
              39.879451
            ],
            [
              -88.462207,
              39.879092
            ],
            [
              -88.462328,
              39.79182
            ],
            [
              -88.473182,
              39.791837
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "083",
      "COUNTYNS": "00424243",
      "AFFGEOID": "0500000US17083",
      "GEOID": "17083",
      "NAME": "Jersey",
      "LSAD": "06",
      "ALAND": 956433111,
      "AWATER": 20324168,
      "County_state": "Jersey,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.599053,
              39.134475
            ],
            [
              -90.603569,
              39.117592
            ],
            [
              -90.58927,
              39.076036
            ],
            [
              -90.581406,
              39.06568
            ],
            [
              -90.588094,
              39.045747
            ],
            [
              -90.57681,
              39.035938
            ],
            [
              -90.576101,
              39.009383
            ],
            [
              -90.570169,
              38.99329
            ],
            [
              -90.534657,
              38.958499
            ],
            [
              -90.519644,
              38.953943
            ],
            [
              -90.491802,
              38.966614
            ],
            [
              -90.450809944078,
              38.967758684265696
            ],
            [
              -90.450792,
              38.967764
            ],
            [
              -90.440078,
              38.967364
            ],
            [
              -90.433745,
              38.965526
            ],
            [
              -90.424726,
              38.963785
            ],
            [
              -90.413108,
              38.96333
            ],
            [
              -90.406367,
              38.962554
            ],
            [
              -90.395816,
              38.960037
            ],
            [
              -90.385768377236,
              38.9564075041174
            ],
            [
              -90.383126,
              38.955453
            ],
            [
              -90.346442,
              38.94079
            ],
            [
              -90.333533,
              38.933489
            ],
            [
              -90.324179,
              38.929827
            ],
            [
              -90.317572,
              38.927912
            ],
            [
              -90.309454,
              38.92412
            ],
            [
              -90.306113,
              38.923525
            ],
            [
              -90.298711,
              38.923395
            ],
            [
              -90.283712,
              38.924048
            ],
            [
              -90.277471,
              38.923716
            ],
            [
              -90.2758247170787,
              38.92346469217149
            ],
            [
              -90.275115,
              38.966523
            ],
            [
              -90.273686,
              38.999347
            ],
            [
              -90.250111,
              39.000047
            ],
            [
              -90.145991,
              39.000046
            ],
            [
              -90.146299,
              39.174289
            ],
            [
              -90.148069,
              39.261947
            ],
            [
              -90.194701,
              39.261966
            ],
            [
              -90.203608,
              39.25842
            ],
            [
              -90.201769,
              39.225497
            ],
            [
              -90.314071,
              39.225053
            ],
            [
              -90.313544,
              39.174301
            ],
            [
              -90.48749,
              39.175133
            ],
            [
              -90.487879,
              39.164074
            ],
            [
              -90.502032,
              39.163234
            ],
            [
              -90.507508,
              39.185193
            ],
            [
              -90.536454,
              39.192857
            ],
            [
              -90.574615,
              39.178623
            ],
            [
              -90.580587,
              39.184895
            ],
            [
              -90.575995,
              39.165167
            ],
            [
              -90.587213,
              39.154873
            ],
            [
              -90.582803,
              39.144327
            ],
            [
              -90.599053,
              39.134475
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "017",
      "COUNTYNS": "00424210",
      "AFFGEOID": "0500000US17017",
      "GEOID": "17017",
      "NAME": "Cass",
      "LSAD": "06",
      "ALAND": 973345745,
      "AWATER": 20581547,
      "County_state": "Cass,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.57179,
              39.89427
            ],
            [
              -90.583534,
              39.87675
            ],
            [
              -89.994405,
              39.87286
            ],
            [
              -89.994506,
              39.901925
            ],
            [
              -89.994724,
              40.108373
            ],
            [
              -90.014165,
              40.100417
            ],
            [
              -90.063955,
              40.101088
            ],
            [
              -90.09385,
              40.077643
            ],
            [
              -90.127195,
              40.076706
            ],
            [
              -90.134517,
              40.062023
            ],
            [
              -90.185622,
              40.063403
            ],
            [
              -90.196609,
              40.052499
            ],
            [
              -90.211241,
              40.059707
            ],
            [
              -90.225759,
              40.055052
            ],
            [
              -90.266915,
              40.062288
            ],
            [
              -90.286724,
              40.055321
            ],
            [
              -90.289926,
              40.069597
            ],
            [
              -90.305069,
              40.077253
            ],
            [
              -90.303108,
              40.093224
            ],
            [
              -90.314714,
              40.115992
            ],
            [
              -90.355198,
              40.124245
            ],
            [
              -90.374327,
              40.121153
            ],
            [
              -90.393431,
              40.091866
            ],
            [
              -90.393432,
              40.078759
            ],
            [
              -90.428638,
              40.060345
            ],
            [
              -90.435852,
              40.020562
            ],
            [
              -90.453768,
              40.010843
            ],
            [
              -90.513747,
              39.987891
            ],
            [
              -90.511115,
              39.967605
            ],
            [
              -90.532113,
              39.946012
            ],
            [
              -90.536721,
              39.923027
            ],
            [
              -90.55428,
              39.901364
            ],
            [
              -90.57179,
              39.89427
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "127",
      "COUNTYNS": "01784730",
      "AFFGEOID": "0500000US17127",
      "GEOID": "17127",
      "NAME": "Massac",
      "LSAD": "06",
      "ALAND": 614391211,
      "AWATER": 11903724,
      "County_state": "Massac,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.929129,
              37.302647
            ],
            [
              -88.92801898699109,
              37.2266247234989
            ],
            [
              -88.920878,
              37.224769
            ],
            [
              -88.916934,
              37.224291
            ],
            [
              -88.902841,
              37.219299
            ],
            [
              -88.86953,
              37.209711
            ],
            [
              -88.835051,
              37.196486
            ],
            [
              -88.820935,
              37.192203
            ],
            [
              -88.80572,
              37.188595
            ],
            [
              -88.797373,
              37.184854
            ],
            [
              -88.786947,
              37.178584
            ],
            [
              -88.779466,
              37.172495
            ],
            [
              -88.77595,
              37.16878
            ],
            [
              -88.765357,
              37.162662
            ],
            [
              -88.753068,
              37.154701
            ],
            [
              -88.746065,
              37.151564
            ],
            [
              -88.737937,
              37.146513
            ],
            [
              -88.732105,
              37.143956
            ],
            [
              -88.72344,
              37.141194
            ],
            [
              -88.720224,
              37.140641
            ],
            [
              -88.702553,
              37.142646
            ],
            [
              -88.693983,
              37.141155
            ],
            [
              -88.687767,
              37.139378
            ],
            [
              -88.644872,
              37.122844
            ],
            [
              -88.637977,
              37.121309
            ],
            [
              -88.630605,
              37.121003
            ],
            [
              -88.625889,
              37.119458
            ],
            [
              -88.61144,
              37.112745
            ],
            [
              -88.601144,
              37.107081
            ],
            [
              -88.593922,
              37.101761
            ],
            [
              -88.589207,
              37.099655
            ],
            [
              -88.581635,
              37.090567
            ],
            [
              -88.576718,
              37.085852
            ],
            [
              -88.569375,
              37.082213
            ],
            [
              -88.5638451054736,
              37.078541595124996
            ],
            [
              -88.560032,
              37.07601
            ],
            [
              -88.545403,
              37.070003
            ],
            [
              -88.531576,
              37.067192
            ],
            [
              -88.521436,
              37.065584
            ],
            [
              -88.514356,
              37.065231
            ],
            [
              -88.504437,
              37.065265
            ],
            [
              -88.49027613045209,
              37.0678362094544
            ],
            [
              -88.490336,
              37.159358
            ],
            [
              -88.71065,
              37.33709
            ],
            [
              -88.728407,
              37.335192
            ],
            [
              -88.908291,
              37.335727
            ],
            [
              -88.902441,
              37.316503
            ],
            [
              -88.929129,
              37.302647
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "137",
      "COUNTYNS": "00424270",
      "AFFGEOID": "0500000US17137",
      "GEOID": "17137",
      "NAME": "Morgan",
      "LSAD": "06",
      "ALAND": 1473194924,
      "AWATER": 9064992,
      "County_state": "Morgan,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.565737,
              39.83007
            ],
            [
              -90.599105,
              39.79006
            ],
            [
              -90.483063,
              39.78924
            ],
            [
              -90.483385,
              39.753434
            ],
            [
              -90.371253,
              39.753388
            ],
            [
              -90.372969,
              39.665781
            ],
            [
              -90.337812,
              39.665583
            ],
            [
              -90.338123,
              39.63672
            ],
            [
              -90.300666,
              39.636541
            ],
            [
              -90.301801,
              39.520192
            ],
            [
              -90.15375,
              39.520315
            ],
            [
              -89.926037,
              39.522104
            ],
            [
              -89.923955,
              39.558678
            ],
            [
              -89.970848,
              39.681658
            ],
            [
              -89.984745,
              39.718079
            ],
            [
              -89.993755,
              39.785329
            ],
            [
              -89.994405,
              39.87286
            ],
            [
              -90.583534,
              39.87675
            ],
            [
              -90.586091,
              39.871413
            ],
            [
              -90.571754,
              39.839326
            ],
            [
              -90.565737,
              39.83007
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "131",
      "COUNTYNS": "01784750",
      "AFFGEOID": "0500000US17131",
      "GEOID": "17131",
      "NAME": "Mercer",
      "LSAD": "06",
      "ALAND": 1453500381,
      "AWATER": 19409929,
      "County_state": "Mercer,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.114186,
              41.250029
            ],
            [
              -91.113648,
              41.241401
            ],
            [
              -91.112333,
              41.239003
            ],
            [
              -91.109562,
              41.236567
            ],
            [
              -91.100829,
              41.230532
            ],
            [
              -91.093018,
              41.222635
            ],
            [
              -91.081445,
              41.214429
            ],
            [
              -91.07298,
              41.207151
            ],
            [
              -91.065899,
              41.199517
            ],
            [
              -91.055069,
              41.185766
            ],
            [
              -91.041536,
              41.166138
            ],
            [
              -91.030029,
              41.16354
            ],
            [
              -91.027214,
              41.163373
            ],
            [
              -91.012557,
              41.165922
            ],
            [
              -91.007586,
              41.166183
            ],
            [
              -90.997906,
              41.162564
            ],
            [
              -90.99496,
              41.160624
            ],
            [
              -90.989663,
              41.155716
            ],
            [
              -90.981311,
              41.142659
            ],
            [
              -90.970851,
              41.130107
            ],
            [
              -90.965905,
              41.119559
            ],
            [
              -90.957246,
              41.111085
            ],
            [
              -90.946627,
              41.096632
            ],
            [
              -90.946259,
              41.094734
            ],
            [
              -90.948207,
              41.084413
            ],
            [
              -90.949383,
              41.072711
            ],
            [
              -90.9493828838387,
              41.0727100123937
            ],
            [
              -90.949136,
              41.070611
            ],
            [
              -90.94899002615979,
              41.0702495855382
            ],
            [
              -90.785194,
              41.068749
            ],
            [
              -90.550511,
              41.064087
            ],
            [
              -90.439423,
              41.063995
            ],
            [
              -90.437657,
              41.151252
            ],
            [
              -90.433771,
              41.326983
            ],
            [
              -90.540597,
              41.326969
            ],
            [
              -90.549953,
              41.327274
            ],
            [
              -91.0719555691705,
              41.33335834590849
            ],
            [
              -91.073233,
              41.31344
            ],
            [
              -91.074841,
              41.305578
            ],
            [
              -91.077505,
              41.301828
            ],
            [
              -91.08688,
              41.294371
            ],
            [
              -91.092034,
              41.286911
            ],
            [
              -91.101142,
              41.267169
            ],
            [
              -91.104462,
              41.262104
            ],
            [
              -91.110304,
              41.256088
            ],
            [
              -91.114186,
              41.250029
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "169",
      "COUNTYNS": "01785037",
      "AFFGEOID": "0500000US17169",
      "GEOID": "17169",
      "NAME": "Schuyler",
      "LSAD": "06",
      "ALAND": 1132531697,
      "AWATER": 10511619,
      "County_state": "Schuyler,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.911969,
              40.193088
            ],
            [
              -90.913616,
              40.104452
            ],
            [
              -90.796246,
              40.105768
            ],
            [
              -90.695818,
              40.103736
            ],
            [
              -90.708058,
              40.09326
            ],
            [
              -90.694506,
              40.079378
            ],
            [
              -90.673275,
              40.080885
            ],
            [
              -90.678015,
              40.05275
            ],
            [
              -90.662004,
              40.046778
            ],
            [
              -90.653351,
              40.033143
            ],
            [
              -90.63009,
              40.023641
            ],
            [
              -90.611664,
              40.032912
            ],
            [
              -90.605679,
              40.025114
            ],
            [
              -90.619029,
              40.015159
            ],
            [
              -90.601947,
              40.003317
            ],
            [
              -90.607134,
              39.981658
            ],
            [
              -90.585534,
              39.97883
            ],
            [
              -90.568214,
              39.983815
            ],
            [
              -90.543596,
              39.980359
            ],
            [
              -90.513747,
              39.987891
            ],
            [
              -90.453768,
              40.010843
            ],
            [
              -90.435852,
              40.020562
            ],
            [
              -90.428638,
              40.060345
            ],
            [
              -90.393432,
              40.078759
            ],
            [
              -90.393431,
              40.091866
            ],
            [
              -90.374327,
              40.121153
            ],
            [
              -90.355198,
              40.124245
            ],
            [
              -90.337548,
              40.136092
            ],
            [
              -90.310386,
              40.139296
            ],
            [
              -90.268144,
              40.154563
            ],
            [
              -90.216138,
              40.150459
            ],
            [
              -90.206144,
              40.155789
            ],
            [
              -90.199556,
              40.183945
            ],
            [
              -90.451502,
              40.188892
            ],
            [
              -90.450227,
              40.276335
            ],
            [
              -90.909756,
              40.284394
            ],
            [
              -90.911969,
              40.193088
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "185",
      "COUNTYNS": "00424293",
      "AFFGEOID": "0500000US17185",
      "GEOID": "17185",
      "NAME": "Wabash",
      "LSAD": "06",
      "ALAND": 578224627,
      "AWATER": 11012015,
      "County_state": "Wabash,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.991679,
              38.269815
            ],
            [
              -87.9906,
              38.259715
            ],
            [
              -87.98749671787239,
              38.256784643872095
            ],
            [
              -87.987103,
              38.257731
            ],
            [
              -87.981868,
              38.256444
            ],
            [
              -87.969947,
              38.243354
            ],
            [
              -87.970593,
              38.240861
            ],
            [
              -87.968968,
              38.237389
            ],
            [
              -87.965381,
              38.236411
            ],
            [
              -87.960225,
              38.237118
            ],
            [
              -87.957992,
              38.240096
            ],
            [
              -87.958839,
              38.244964
            ],
            [
              -87.956145,
              38.251652
            ],
            [
              -87.946065,
              38.255319
            ],
            [
              -87.942067,
              38.260301
            ],
            [
              -87.948903,
              38.264116
            ],
            [
              -87.951923,
              38.274253
            ],
            [
              -87.94227,
              38.278976
            ],
            [
              -87.937329,
              38.292431
            ],
            [
              -87.924973,
              38.298659
            ],
            [
              -87.916489,
              38.299372
            ],
            [
              -87.909338,
              38.29572
            ],
            [
              -87.909853,
              38.289335
            ],
            [
              -87.915444,
              38.28235
            ],
            [
              -87.919292,
              38.275908
            ],
            [
              -87.917615,
              38.270078
            ],
            [
              -87.91285,
              38.268133
            ],
            [
              -87.908542,
              38.268581
            ],
            [
              -87.897803,
              38.279482
            ],
            [
              -87.887849,
              38.285299
            ],
            [
              -87.884858,
              38.293063
            ],
            [
              -87.880848,
              38.303725
            ],
            [
              -87.87503,
              38.310221
            ],
            [
              -87.868886,
              38.312277
            ],
            [
              -87.86311,
              38.309823
            ],
            [
              -87.860453,
              38.305372
            ],
            [
              -87.866248,
              38.28998
            ],
            [
              -87.863068,
              38.280267
            ],
            [
              -87.854643,
              38.275376
            ],
            [
              -87.845937,
              38.276667
            ],
            [
              -87.840984,
              38.280105
            ],
            [
              -87.832802,
              38.29334
            ],
            [
              -87.830953,
              38.299365
            ],
            [
              -87.831972,
              38.307241
            ],
            [
              -87.832723,
              38.324853
            ],
            [
              -87.822721,
              38.346912
            ],
            [
              -87.806075,
              38.363143
            ],
            [
              -87.779996,
              38.370842
            ],
            [
              -87.75979852361239,
              38.393023063672004
            ],
            [
              -87.745254,
              38.408996
            ],
            [
              -87.744381942629,
              38.4144965422212
            ],
            [
              -87.74104,
              38.435576
            ],
            [
              -87.730699,
              38.442908
            ],
            [
              -87.730134,
              38.446518
            ],
            [
              -87.735729,
              38.452986
            ],
            [
              -87.74317,
              38.459019
            ],
            [
              -87.743535,
              38.467774
            ],
            [
              -87.739522,
              38.475069
            ],
            [
              -87.730768,
              38.478717
            ],
            [
              -87.714047,
              38.47988
            ],
            [
              -87.693188,
              38.488038
            ],
            [
              -87.678374,
              38.498438
            ],
            [
              -87.663701,
              38.502931
            ],
            [
              -87.657084,
              38.507169
            ],
            [
              -87.654166,
              38.511911
            ],
            [
              -87.653802,
              38.517382
            ],
            [
              -87.65578,
              38.521206
            ],
            [
              -87.660732,
              38.541092
            ],
            [
              -87.650704,
              38.55624
            ],
            [
              -87.651529,
              38.568166
            ],
            [
              -87.912286,
              38.570104
            ],
            [
              -87.953898,
              38.570094
            ],
            [
              -87.952191,
              38.542926
            ],
            [
              -87.9387,
              38.533675
            ],
            [
              -87.95452,
              38.516256
            ],
            [
              -87.947581,
              38.505891
            ],
            [
              -87.951191,
              38.488815
            ],
            [
              -87.94379,
              38.455876
            ],
            [
              -87.95557,
              38.42479
            ],
            [
              -87.97607,
              38.399928
            ],
            [
              -87.977134,
              38.377266
            ],
            [
              -87.967298,
              38.353556
            ],
            [
              -87.953892,
              38.337009
            ],
            [
              -87.958305,
              38.319681
            ],
            [
              -87.946594,
              38.308672
            ],
            [
              -87.957131,
              38.299884
            ],
            [
              -87.951122,
              38.290611
            ],
            [
              -87.991679,
              38.269815
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "055",
      "COUNTYNS": "00424229",
      "AFFGEOID": "0500000US17055",
      "GEOID": "17055",
      "NAME": "Franklin",
      "LSAD": "06",
      "ALAND": 1059021102,
      "AWATER": 58413469,
      "County_state": "Franklin,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.164814,
              37.95506
            ],
            [
              -89.177597,
              37.950311
            ],
            [
              -89.150834,
              37.950196
            ],
            [
              -89.151176,
              37.861999
            ],
            [
              -88.70676,
              37.863338
            ],
            [
              -88.706622,
              37.906797
            ],
            [
              -88.706488,
              37.951049
            ],
            [
              -88.704606,
              38.125195
            ],
            [
              -89.129637,
              38.124747
            ],
            [
              -89.131662,
              38.103365
            ],
            [
              -89.13744,
              38.104332
            ],
            [
              -89.11793,
              38.09631
            ],
            [
              -89.115414,
              38.085954
            ],
            [
              -89.119654,
              38.0593
            ],
            [
              -89.132037,
              38.052492
            ],
            [
              -89.1403,
              38.033089
            ],
            [
              -89.136072,
              38.01803
            ],
            [
              -89.145253,
              38.008768
            ],
            [
              -89.145778,
              37.985725
            ],
            [
              -89.164814,
              37.95506
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "059",
      "COUNTYNS": "00424231",
      "AFFGEOID": "0500000US17059",
      "GEOID": "17059",
      "NAME": "Gallatin",
      "LSAD": "06",
      "ALAND": 836749960,
      "AWATER": 13238356,
      "County_state": "Gallatin,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.37453,
              37.907678
            ],
            [
              -88.375332,
              37.599563
            ],
            [
              -88.179647,
              37.599093
            ],
            [
              -88.15282,
              37.573799
            ],
            [
              -88.133410402099,
              37.5742729944782
            ],
            [
              -88.136164,
              37.580285
            ],
            [
              -88.139973,
              37.586451
            ],
            [
              -88.14094,
              37.590865
            ],
            [
              -88.140226,
              37.595263
            ],
            [
              -88.140752,
              37.599158
            ],
            [
              -88.142225,
              37.603737
            ],
            [
              -88.152691,
              37.622827
            ],
            [
              -88.156827,
              37.632801
            ],
            [
              -88.158374,
              37.639948
            ],
            [
              -88.15864,
              37.649097
            ],
            [
              -88.160062,
              37.654332
            ],
            [
              -88.160187,
              37.657592
            ],
            [
              -88.159372,
              37.661847
            ],
            [
              -88.158207,
              37.664542
            ],
            [
              -88.151646,
              37.675098
            ],
            [
              -88.145434,
              37.68259
            ],
            [
              -88.134282,
              37.691498
            ],
            [
              -88.132341,
              37.697142
            ],
            [
              -88.125033,
              37.707094
            ],
            [
              -88.122412,
              37.709685
            ],
            [
              -88.117803,
              37.712583
            ],
            [
              -88.107088,
              37.715915
            ],
            [
              -88.101844,
              37.718036
            ],
            [
              -88.095759,
              37.723205
            ],
            [
              -88.085901,
              37.728587
            ],
            [
              -88.081925,
              37.730389
            ],
            [
              -88.072538,
              37.733286
            ],
            [
              -88.063802,
              37.738645
            ],
            [
              -88.059588,
              37.742608
            ],
            [
              -88.049942,
              37.754078
            ],
            [
              -88.042602,
              37.76712
            ],
            [
              -88.040873,
              37.772082
            ],
            [
              -88.038769,
              37.784307
            ],
            [
              -88.035827,
              37.791917
            ],
            [
              -88.032438,
              37.796361
            ],
            [
              -88.02803,
              37.799224
            ],
            [
              -88.029382,
              37.803601
            ],
            [
              -88.035456,
              37.80952
            ],
            [
              -88.044249,
              37.808575
            ],
            [
              -88.057555,
              37.802031
            ],
            [
              -88.068922,
              37.800983
            ],
            [
              -88.083204,
              37.810897
            ],
            [
              -88.090884,
              37.824468
            ],
            [
              -88.083671,
              37.830175
            ],
            [
              -88.074042,
              37.82916
            ],
            [
              -88.060212,
              37.822849
            ],
            [
              -88.047947,
              37.821727
            ],
            [
              -88.040271,
              37.822341
            ],
            [
              -88.026382,
              37.831107
            ],
            [
              -88.033309,
              37.841917
            ],
            [
              -88.057576,
              37.84789
            ],
            [
              -88.067364,
              37.856051
            ],
            [
              -88.078529,
              37.879118
            ],
            [
              -88.09202942327529,
              37.891093081727895
            ],
            [
              -88.136053,
              37.916023
            ],
            [
              -88.135985,
              37.907794
            ],
            [
              -88.357462,
              37.909081
            ],
            [
              -88.37452,
              37.908432
            ],
            [
              -88.37453,
              37.907678
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "061",
      "COUNTYNS": "00424232",
      "AFFGEOID": "0500000US17061",
      "GEOID": "17061",
      "NAME": "Greene",
      "LSAD": "06",
      "ALAND": 1406413317,
      "AWATER": 8425393,
      "County_state": "Greene,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.613694,
              39.395775
            ],
            [
              -90.62229,
              39.365089
            ],
            [
              -90.611258,
              39.311213
            ],
            [
              -90.60243,
              39.292693
            ],
            [
              -90.609796,
              39.259812
            ],
            [
              -90.597989,
              39.24333
            ],
            [
              -90.59123,
              39.198624
            ],
            [
              -90.613326,
              39.162742
            ],
            [
              -90.61454,
              39.14229
            ],
            [
              -90.603569,
              39.117592
            ],
            [
              -90.599053,
              39.134475
            ],
            [
              -90.582803,
              39.144327
            ],
            [
              -90.587213,
              39.154873
            ],
            [
              -90.575995,
              39.165167
            ],
            [
              -90.580587,
              39.184895
            ],
            [
              -90.574615,
              39.178623
            ],
            [
              -90.536454,
              39.192857
            ],
            [
              -90.507508,
              39.185193
            ],
            [
              -90.502032,
              39.163234
            ],
            [
              -90.487879,
              39.164074
            ],
            [
              -90.48749,
              39.175133
            ],
            [
              -90.313544,
              39.174301
            ],
            [
              -90.314071,
              39.225053
            ],
            [
              -90.201769,
              39.225497
            ],
            [
              -90.203608,
              39.25842
            ],
            [
              -90.194701,
              39.261966
            ],
            [
              -90.148069,
              39.261947
            ],
            [
              -90.15375,
              39.520315
            ],
            [
              -90.301801,
              39.520192
            ],
            [
              -90.581055,
              39.521728
            ],
            [
              -90.592092,
              39.486979
            ],
            [
              -90.610502,
              39.457447
            ],
            [
              -90.616807,
              39.423878
            ],
            [
              -90.613694,
              39.395775
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "047",
      "COUNTYNS": "00424225",
      "AFFGEOID": "0500000US17047",
      "GEOID": "17047",
      "NAME": "Edwards",
      "LSAD": "06",
      "ALAND": 576062000,
      "AWATER": 781203,
      "County_state": "Edwards,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.147814,
              38.569086
            ],
            [
              -88.1509,
              38.256097
            ],
            [
              -88.027749,
              38.255661
            ],
            [
              -87.9906,
              38.259715
            ],
            [
              -87.991679,
              38.269815
            ],
            [
              -87.951122,
              38.290611
            ],
            [
              -87.957131,
              38.299884
            ],
            [
              -87.946594,
              38.308672
            ],
            [
              -87.958305,
              38.319681
            ],
            [
              -87.953892,
              38.337009
            ],
            [
              -87.967298,
              38.353556
            ],
            [
              -87.977134,
              38.377266
            ],
            [
              -87.97607,
              38.399928
            ],
            [
              -87.95557,
              38.42479
            ],
            [
              -87.94379,
              38.455876
            ],
            [
              -87.951191,
              38.488815
            ],
            [
              -87.947581,
              38.505891
            ],
            [
              -87.95452,
              38.516256
            ],
            [
              -87.9387,
              38.533675
            ],
            [
              -87.952191,
              38.542926
            ],
            [
              -87.953898,
              38.570094
            ],
            [
              -88.147814,
              38.569086
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "187",
      "COUNTYNS": "01785134",
      "AFFGEOID": "0500000US17187",
      "GEOID": "17187",
      "NAME": "Warren",
      "LSAD": "06",
      "ALAND": 1404823489,
      "AWATER": 1674135,
      "County_state": "Warren,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.78957,
              40.679038
            ],
            [
              -90.789652,
              40.63575
            ],
            [
              -90.445506,
              40.627843
            ],
            [
              -90.444343,
              40.714667
            ],
            [
              -90.440873,
              40.947669
            ],
            [
              -90.439423,
              41.063995
            ],
            [
              -90.550511,
              41.064087
            ],
            [
              -90.785194,
              41.068749
            ],
            [
              -90.78957,
              40.679038
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "009",
      "COUNTYNS": "00424206",
      "AFFGEOID": "0500000US17009",
      "GEOID": "17009",
      "NAME": "Brown",
      "LSAD": "06",
      "ALAND": 791518279,
      "AWATER": 4139308,
      "County_state": "Brown,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.916631,
              39.911459
            ],
            [
              -90.916609,
              39.845075
            ],
            [
              -90.893651,
              39.84092
            ],
            [
              -90.571754,
              39.839326
            ],
            [
              -90.586091,
              39.871413
            ],
            [
              -90.583534,
              39.87675
            ],
            [
              -90.57179,
              39.89427
            ],
            [
              -90.55428,
              39.901364
            ],
            [
              -90.536721,
              39.923027
            ],
            [
              -90.532113,
              39.946012
            ],
            [
              -90.511115,
              39.967605
            ],
            [
              -90.513747,
              39.987891
            ],
            [
              -90.543596,
              39.980359
            ],
            [
              -90.568214,
              39.983815
            ],
            [
              -90.585534,
              39.97883
            ],
            [
              -90.607134,
              39.981658
            ],
            [
              -90.601947,
              40.003317
            ],
            [
              -90.619029,
              40.015159
            ],
            [
              -90.605679,
              40.025114
            ],
            [
              -90.611664,
              40.032912
            ],
            [
              -90.63009,
              40.023641
            ],
            [
              -90.653351,
              40.033143
            ],
            [
              -90.662004,
              40.046778
            ],
            [
              -90.678015,
              40.05275
            ],
            [
              -90.673275,
              40.080885
            ],
            [
              -90.694506,
              40.079378
            ],
            [
              -90.708058,
              40.09326
            ],
            [
              -90.695818,
              40.103736
            ],
            [
              -90.796246,
              40.105768
            ],
            [
              -90.913616,
              40.104452
            ],
            [
              -90.914275,
              39.938302
            ],
            [
              -90.916631,
              39.911459
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "135",
      "COUNTYNS": "01784866",
      "AFFGEOID": "0500000US17135",
      "GEOID": "17135",
      "NAME": "Montgomery",
      "LSAD": "06",
      "ALAND": 1822539611,
      "AWATER": 15512963,
      "County_state": "Montgomery,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.699094,
              39.348049
            ],
            [
              -89.703482,
              39.332938
            ],
            [
              -89.698555,
              38.998979
            ],
            [
              -89.652929,
              38.999475
            ],
            [
              -89.639265,
              38.999129
            ],
            [
              -89.586148,
              38.999449
            ],
            [
              -89.586088,
              39.028246
            ],
            [
              -89.250447,
              39.028145
            ],
            [
              -89.250513,
              39.217512
            ],
            [
              -89.140076,
              39.217907
            ],
            [
              -89.139807,
              39.348888
            ],
            [
              -89.530836,
              39.348864
            ],
            [
              -89.533655,
              39.524592
            ],
            [
              -89.701645,
              39.523369
            ],
            [
              -89.699094,
              39.348049
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "119",
      "COUNTYNS": "00424258",
      "AFFGEOID": "0500000US17119",
      "GEOID": "17119",
      "NAME": "Madison",
      "LSAD": "06",
      "ALAND": 1853819910,
      "AWATER": 64225067,
      "County_state": "Madison,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.275115,
              38.966523
            ],
            [
              -90.2758247170787,
              38.92346469217149
            ],
            [
              -90.269872,
              38.922556
            ],
            [
              -90.262792,
              38.920344
            ],
            [
              -90.256993,
              38.920763
            ],
            [
              -90.254033,
              38.920489
            ],
            [
              -90.250248,
              38.919344
            ],
            [
              -90.241703,
              38.914828
            ],
            [
              -90.230336,
              38.91086
            ],
            [
              -90.223041,
              38.907389
            ],
            [
              -90.213519,
              38.900454
            ],
            [
              -90.2092211508743,
              38.8969944327171
            ],
            [
              -90.19761,
              38.887648
            ],
            [
              -90.19521,
              38.886748
            ],
            [
              -90.190309,
              38.886248
            ],
            [
              -90.186909,
              38.885048
            ],
            [
              -90.166409,
              38.876348
            ],
            [
              -90.151508,
              38.867148
            ],
            [
              -90.113327,
              38.849306
            ],
            [
              -90.109407,
              38.843548
            ],
            [
              -90.109107,
              38.837448
            ],
            [
              -90.114707,
              38.815048
            ],
            [
              -90.117707,
              38.805748
            ],
            [
              -90.123107,
              38.798048
            ],
            [
              -90.146708,
              38.783049
            ],
            [
              -90.166409,
              38.772649
            ],
            [
              -90.16657184776219,
              38.77244627115319
            ],
            [
              -90.171309,
              38.766549
            ],
            [
              -90.175109,
              38.760249
            ],
            [
              -90.176309,
              38.754449
            ],
            [
              -90.183409,
              38.746849
            ],
            [
              -90.191309,
              38.742949
            ],
            [
              -90.20521,
              38.73215
            ],
            [
              -90.20991,
              38.72605
            ],
            [
              -90.211178903355,
              38.7220741028209
            ],
            [
              -90.21141,
              38.72135
            ],
            [
              -90.21191,
              38.71795
            ],
            [
              -90.21201,
              38.71175
            ],
            [
              -90.20921,
              38.70275
            ],
            [
              -90.2022575105174,
              38.6935131211159
            ],
            [
              -90.20221,
              38.69345
            ],
            [
              -90.19770973268129,
              38.6896569175457
            ],
            [
              -90.19521,
              38.68755
            ],
            [
              -90.18641,
              38.67475
            ],
            [
              -90.18261,
              38.66535
            ],
            [
              -90.1813250257207,
              38.6603814327868
            ],
            [
              -90.168909,
              38.66065
            ],
            [
              -90.094607,
              38.659749
            ],
            [
              -90.089736,
              38.659454
            ],
            [
              -90.037105,
              38.658449
            ],
            [
              -89.928401,
              38.657748
            ],
            [
              -89.707024,
              38.655152
            ],
            [
              -89.595103,
              38.655948
            ],
            [
              -89.595388,
              38.666865
            ],
            [
              -89.597321,
              38.743236
            ],
            [
              -89.599593,
              38.87453
            ],
            [
              -89.636874,
              38.874278
            ],
            [
              -89.637831,
              38.918353
            ],
            [
              -89.637049,
              38.918364
            ],
            [
              -89.639265,
              38.999129
            ],
            [
              -89.652929,
              38.999475
            ],
            [
              -89.698555,
              38.998979
            ],
            [
              -90.041555,
              38.998642
            ],
            [
              -90.118012,
              38.998979
            ],
            [
              -90.145991,
              39.000046
            ],
            [
              -90.250111,
              39.000047
            ],
            [
              -90.273686,
              38.999347
            ],
            [
              -90.275115,
              38.966523
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "181",
      "COUNTYNS": "01785113",
      "AFFGEOID": "0500000US17181",
      "GEOID": "17181",
      "NAME": "Union",
      "LSAD": "06",
      "ALAND": 1070855939,
      "AWATER": 22502264,
      "County_state": "Union,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.5217295671219,
              37.5662095222196
            ],
            [
              -89.521925,
              37.560735
            ],
            [
              -89.521093,
              37.553805
            ],
            [
              -89.517051,
              37.537278
            ],
            [
              -89.516447,
              37.535558
            ],
            [
              -89.5124,
              37.52981
            ],
            [
              -89.507459,
              37.524322
            ],
            [
              -89.502917,
              37.51787
            ],
            [
              -89.500231,
              37.512954
            ],
            [
              -89.497689,
              37.504948
            ],
            [
              -89.492051,
              37.494008
            ],
            [
              -89.475525,
              37.471388
            ],
            [
              -89.471201,
              37.466473
            ],
            [
              -89.450969,
              37.450069
            ],
            [
              -89.443493,
              37.442129
            ],
            [
              -89.439769,
              37.4372
            ],
            [
              -89.43413,
              37.426847
            ],
            [
              -89.42594,
              37.407471
            ],
            [
              -89.422465,
              37.397132
            ],
            [
              -89.42132,
              37.392077
            ],
            [
              -89.421054,
              37.387668
            ],
            [
              -89.422037,
              37.38053
            ],
            [
              -89.428185,
              37.356158
            ],
            [
              -89.429738,
              37.351956
            ],
            [
              -89.432836,
              37.347056
            ],
            [
              -89.43604,
              37.344441
            ],
            [
              -89.447556,
              37.340475
            ],
            [
              -89.454723,
              37.339283
            ],
            [
              -89.474569,
              37.338165
            ],
            [
              -89.484211,
              37.335646
            ],
            [
              -89.4859297365844,
              37.3348292953819
            ],
            [
              -89.248439,
              37.335109
            ],
            [
              -89.169134,
              37.33419
            ],
            [
              -89.099277,
              37.333556
            ],
            [
              -89.044787,
              37.329846
            ],
            [
              -89.041401,
              37.596576
            ],
            [
              -89.153668,
              37.600469
            ],
            [
              -89.232598,
              37.5985
            ],
            [
              -89.450594,
              37.600022
            ],
            [
              -89.453087,
              37.580113
            ],
            [
              -89.472848,
              37.561925
            ],
            [
              -89.52156646342159,
              37.5707784301758
            ],
            [
              -89.5217295671219,
              37.5662095222196
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "151",
      "COUNTYNS": "00424277",
      "AFFGEOID": "0500000US17151",
      "GEOID": "17151",
      "NAME": "Pope",
      "LSAD": "06",
      "ALAND": 955109669,
      "AWATER": 14328523,
      "County_state": "Pope,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.708546,
              37.599277
            ],
            [
              -88.71065,
              37.33709
            ],
            [
              -88.490336,
              37.159358
            ],
            [
              -88.49027613045209,
              37.0678362094544
            ],
            [
              -88.490068,
              37.067874
            ],
            [
              -88.482856,
              37.067114
            ],
            [
              -88.476127,
              37.068223
            ],
            [
              -88.458948,
              37.073796
            ],
            [
              -88.444605,
              37.098601
            ],
            [
              -88.442743,
              37.107842
            ],
            [
              -88.443538,
              37.108517
            ],
            [
              -88.443538,
              37.109192
            ],
            [
              -88.435829,
              37.125055
            ],
            [
              -88.434701,
              37.126424
            ],
            [
              -88.424776,
              37.149901
            ],
            [
              -88.424403,
              37.152428
            ],
            [
              -88.428097,
              37.157758
            ],
            [
              -88.429906,
              37.158668
            ],
            [
              -88.431488,
              37.160298
            ],
            [
              -88.433782,
              37.16407
            ],
            [
              -88.433454,
              37.165871
            ],
            [
              -88.437781,
              37.180007
            ],
            [
              -88.439527,
              37.18174
            ],
            [
              -88.441956,
              37.189036
            ],
            [
              -88.447764,
              37.203527
            ],
            [
              -88.450653,
              37.207046
            ],
            [
              -88.458763,
              37.213536
            ],
            [
              -88.466981,
              37.217026
            ],
            [
              -88.471753,
              37.220155
            ],
            [
              -88.478179,
              37.227251
            ],
            [
              -88.47973,
              37.229606
            ],
            [
              -88.484982,
              37.240774
            ],
            [
              -88.487277,
              37.244077
            ],
            [
              -88.492383,
              37.248445
            ],
            [
              -88.500777,
              37.253579
            ],
            [
              -88.508031,
              37.260261
            ],
            [
              -88.509328,
              37.26213
            ],
            [
              -88.506942,
              37.266656
            ],
            [
              -88.509587,
              37.273398
            ],
            [
              -88.515939,
              37.284043
            ],
            [
              -88.514661,
              37.290948
            ],
            [
              -88.511497,
              37.298527
            ],
            [
              -88.508657,
              37.303353
            ],
            [
              -88.505087,
              37.307858
            ],
            [
              -88.500566,
              37.317822
            ],
            [
              -88.494137,
              37.327689
            ],
            [
              -88.49031,
              37.335042
            ],
            [
              -88.486947,
              37.339596
            ],
            [
              -88.484462,
              37.345609
            ],
            [
              -88.482612,
              37.354915
            ],
            [
              -88.482113,
              37.364615
            ],
            [
              -88.478523,
              37.375052
            ],
            [
              -88.476592,
              37.386875
            ],
            [
              -88.470224,
              37.396255
            ],
            [
              -88.465861,
              37.400547
            ],
            [
              -88.456,
              37.408482
            ],
            [
              -88.450127,
              37.411717
            ],
            [
              -88.439333,
              37.416416
            ],
            [
              -88.433182,
              37.418169
            ],
            [
              -88.425981,
              37.419441
            ],
            [
              -88.418594,
              37.421987
            ],
            [
              -88.41488228248849,
              37.4236655948133
            ],
            [
              -88.415149,
              37.424863
            ],
            [
              -88.412112,
              37.599912
            ],
            [
              -88.708546,
              37.599277
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "023",
      "COUNTYNS": "00424213",
      "AFFGEOID": "0500000US17023",
      "GEOID": "17023",
      "NAME": "Clark",
      "LSAD": "06",
      "ALAND": 1298676804,
      "AWATER": 8796711,
      "County_state": "Clark,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.014205,
              39.480762
            ],
            [
              -88.012121,
              39.378968
            ],
            [
              -88.007766,
              39.173925
            ],
            [
              -87.950385,
              39.174882
            ],
            [
              -87.746174,
              39.17922
            ],
            [
              -87.747113,
              39.172296
            ],
            [
              -87.656794,
              39.172177
            ],
            [
              -87.657032,
              39.157594
            ],
            [
              -87.6427769752148,
              39.157524787036294
            ],
            [
              -87.640435,
              39.166727
            ],
            [
              -87.620796,
              39.17479
            ],
            [
              -87.588614,
              39.197824
            ],
            [
              -87.577029,
              39.211123
            ],
            [
              -87.574558,
              39.218404
            ],
            [
              -87.579163,
              39.232962
            ],
            [
              -87.583535,
              39.243579
            ],
            [
              -87.593486,
              39.247452
            ],
            [
              -87.604075798943,
              39.25945851501619
            ],
            [
              -87.605543,
              39.261122
            ],
            [
              -87.61005,
              39.282232
            ],
            [
              -87.597545,
              39.296388
            ],
            [
              -87.597946,
              39.299479
            ],
            [
              -87.600397,
              39.312904
            ],
            [
              -87.589084,
              39.333831
            ],
            [
              -87.578331,
              39.340343
            ],
            [
              -87.5544,
              39.340488
            ],
            [
              -87.544013,
              39.352907
            ],
            [
              -87.531646,
              39.347888
            ],
            [
              -87.5315486565777,
              39.377582092477695
            ],
            [
              -87.531355,
              39.436656
            ],
            [
              -87.531355,
              39.437732
            ],
            [
              -87.531489,
              39.449474
            ],
            [
              -87.531608,
              39.466225
            ],
            [
              -87.531624,
              39.469378
            ],
            [
              -87.5316629504044,
              39.47711015463629
            ],
            [
              -87.689166,
              39.476758
            ],
            [
              -87.687437,
              39.48734
            ],
            [
              -87.764705,
              39.486065
            ],
            [
              -87.960179,
              39.481309
            ],
            [
              -88.014205,
              39.480762
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "103",
      "COUNTYNS": "00424253",
      "AFFGEOID": "0500000US17103",
      "GEOID": "17103",
      "NAME": "Lee",
      "LSAD": "06",
      "ALAND": 1877336099,
      "AWATER": 10634483,
      "County_state": "Lee,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.631467,
              41.758134
            ],
            [
              -89.631494,
              41.584949
            ],
            [
              -89.166561,
              41.585289
            ],
            [
              -89.16703,
              41.617888
            ],
            [
              -89.1672,
              41.628707
            ],
            [
              -89.045109,
              41.62669
            ],
            [
              -88.938618,
              41.628319
            ],
            [
              -88.941279,
              41.891752
            ],
            [
              -89.360682,
              41.88807
            ],
            [
              -89.36052,
              41.902641
            ],
            [
              -89.403108,
              41.902273
            ],
            [
              -89.414627,
              41.886175
            ],
            [
              -89.428648,
              41.89404
            ],
            [
              -89.423379,
              41.907051
            ],
            [
              -89.472552,
              41.911939
            ],
            [
              -89.481335,
              41.902241
            ],
            [
              -89.62933,
              41.901617
            ],
            [
              -89.630263,
              41.843623
            ],
            [
              -89.631467,
              41.758134
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "173",
      "COUNTYNS": "01785051",
      "AFFGEOID": "0500000US17173",
      "GEOID": "17173",
      "NAME": "Shelby",
      "LSAD": "06",
      "ALAND": 1964598160,
      "AWATER": 24693146,
      "County_state": "Shelby,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.139807,
              39.348888
            ],
            [
              -89.140076,
              39.217907
            ],
            [
              -89.136594,
              39.217192
            ],
            [
              -89.027437,
              39.215454
            ],
            [
              -88.805325,
              39.216263
            ],
            [
              -88.470906,
              39.215029
            ],
            [
              -88.47083,
              39.374515
            ],
            [
              -88.470505,
              39.447041
            ],
            [
              -88.584273,
              39.447582
            ],
            [
              -88.584518,
              39.476791
            ],
            [
              -88.603126,
              39.477014
            ],
            [
              -88.603409,
              39.491562
            ],
            [
              -88.622316,
              39.491786
            ],
            [
              -88.62239,
              39.506496
            ],
            [
              -88.641626,
              39.506689
            ],
            [
              -88.641468,
              39.520958
            ],
            [
              -88.716875,
              39.521209
            ],
            [
              -88.717539,
              39.579299
            ],
            [
              -88.809051,
              39.580241
            ],
            [
              -88.810575,
              39.653222
            ],
            [
              -89.02568,
              39.654183
            ],
            [
              -89.025453,
              39.346115
            ],
            [
              -89.139807,
              39.348888
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "031",
      "COUNTYNS": "01784766",
      "AFFGEOID": "0500000US17031",
      "GEOID": "17031",
      "NAME": "Cook",
      "LSAD": "06",
      "ALAND": 2447469694,
      "AWATER": 1786452809,
      "County_state": "Cook,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.263378,
              42.066626
            ],
            [
              -88.263255,
              42.015492
            ],
            [
              -88.263282,
              42.01395
            ],
            [
              -88.263371,
              42.011939
            ],
            [
              -88.262876,
              41.986227
            ],
            [
              -88.253302,
              41.986377
            ],
            [
              -88.216763,
              41.987112
            ],
            [
              -88.21078,
              41.987023
            ],
            [
              -88.207389,
              41.987127
            ],
            [
              -88.184215,
              41.987599
            ],
            [
              -88.174096,
              41.987699
            ],
            [
              -88.15997,
              41.987909
            ],
            [
              -88.159879,
              41.987912
            ],
            [
              -88.129192,
              41.988865
            ],
            [
              -88.069933,
              41.991045
            ],
            [
              -88.03131,
              41.992526
            ],
            [
              -88.023466,
              41.992488
            ],
            [
              -87.970636,
              41.992997
            ],
            [
              -87.939946,
              41.993481
            ],
            [
              -87.92067,
              41.994031
            ],
            [
              -87.920251,
              41.9416
            ],
            [
              -87.920288,
              41.9313
            ],
            [
              -87.920445,
              41.906841
            ],
            [
              -87.920082,
              41.862199
            ],
            [
              -87.918382,
              41.81873
            ],
            [
              -87.917304,
              41.796397
            ],
            [
              -87.917089,
              41.791233
            ],
            [
              -87.916716,
              41.781616
            ],
            [
              -87.916677,
              41.780625
            ],
            [
              -87.916322,
              41.771515
            ],
            [
              -87.914552,
              41.720169
            ],
            [
              -87.914265,
              41.716746
            ],
            [
              -87.921321,
              41.716293
            ],
            [
              -87.94138,
              41.703324
            ],
            [
              -87.942462,
              41.69918
            ],
            [
              -87.949758,
              41.695231
            ],
            [
              -87.955558,
              41.693631
            ],
            [
              -87.966758,
              41.68683
            ],
            [
              -87.978956,
              41.687432
            ],
            [
              -87.982659,
              41.686231
            ],
            [
              -88.004941,
              41.685652
            ],
            [
              -88.028985,
              41.685518
            ],
            [
              -88.028557,
              41.669915
            ],
            [
              -88.027688,
              41.641506
            ],
            [
              -87.91193,
              41.643933
            ],
            [
              -87.910461,
              41.600302
            ],
            [
              -87.909377,
              41.556819
            ],
            [
              -87.812262,
              41.558291
            ],
            [
              -87.792793,
              41.55854
            ],
            [
              -87.79229,
              41.543734
            ],
            [
              -87.790408,
              41.528041
            ],
            [
              -87.790317,
              41.499036
            ],
            [
              -87.790303,
              41.470113
            ],
            [
              -87.787302,
              41.469839
            ],
            [
              -87.698645,
              41.469767
            ],
            [
              -87.654065,
              41.469756
            ],
            [
              -87.653236,
              41.469754
            ],
            [
              -87.577339,
              41.469992
            ],
            [
              -87.577103,
              41.469993
            ],
            [
              -87.557849,
              41.470066
            ],
            [
              -87.5256689455744,
              41.470282557937196
            ],
            [
              -87.5254269452529,
              41.4900199849783
            ],
            [
              -87.525025896482,
              41.5227293252257
            ],
            [
              -87.52494,
              41.529735
            ],
            [
              -87.525041,
              41.559235
            ],
            [
              -87.524641,
              41.563335
            ],
            [
              -87.5246413332451,
              41.58306310896079
            ],
            [
              -87.5246415449503,
              41.5955960550854
            ],
            [
              -87.524642,
              41.622535
            ],
            [
              -87.524742,
              41.632435
            ],
            [
              -87.524642,
              41.634935
            ],
            [
              -87.5247862899967,
              41.6754362020356
            ],
            [
              -87.524844,
              41.691635
            ],
            [
              -87.524944,
              41.702635
            ],
            [
              -87.524044,
              41.708335
            ],
            [
              -87.524141,
              41.72399
            ],
            [
              -87.530745,
              41.748235
            ],
            [
              -87.542845,
              41.752135
            ],
            [
              -87.560646,
              41.766034
            ],
            [
              -87.576347,
              41.786034
            ],
            [
              -87.5805498004265,
              41.8027502025223
            ],
            [
              -87.5809204808497,
              41.8042245454359
            ],
            [
              -87.580948,
              41.804334
            ],
            [
              -87.5870542679338,
              41.8113430772023
            ],
            [
              -87.58712260843569,
              41.81142152182
            ],
            [
              -87.5904307309638,
              41.815218748939
            ],
            [
              -87.5919342175045,
              41.8169445253626
            ],
            [
              -87.600549,
              41.826833
            ],
            [
              -87.60945,
              41.845233
            ],
            [
              -87.616251,
              41.868933
            ],
            [
              -87.616537,
              41.882396
            ],
            [
              -87.613556,
              41.88448
            ],
            [
              -87.614188,
              41.888421
            ],
            [
              -87.61268,
              41.889248
            ],
            [
              -87.61255834881749,
              41.8894219576165
            ],
            [
              -87.6117629987361,
              41.890559284863194
            ],
            [
              -87.611659,
              41.890708
            ],
            [
              -87.611659,
              41.892216
            ],
            [
              -87.612291,
              41.893335
            ],
            [
              -87.614163,
              41.893418
            ],
            [
              -87.617433,
              41.898032
            ],
            [
              -87.619852,
              41.901392
            ],
            [
              -87.622944,
              41.90202
            ],
            [
              -87.624052,
              41.904232
            ],
            [
              -87.62476277573829,
              41.907208585833395
            ],
            [
              -87.6248820949634,
              41.9077082707494
            ],
            [
              -87.62552627663969,
              41.9104059740453
            ],
            [
              -87.62732952027311,
              41.9179575956953
            ],
            [
              -87.62791998798379,
              41.9204303557067
            ],
            [
              -87.63092473354409,
              41.9330136257679
            ],
            [
              -87.630953,
              41.933132
            ],
            [
              -87.6355825499074,
              41.9448200035396
            ],
            [
              -87.63996930359349,
              41.9558950310555
            ],
            [
              -87.64157375437469,
              41.9599457113127
            ],
            [
              -87.64843961133519,
              41.977279612461295
            ],
            [
              -87.66659740885119,
              42.023121736617
            ],
            [
              -87.668982,
              42.029142
            ],
            [
              -87.671894,
              42.047972
            ],
            [
              -87.670512,
              42.05298
            ],
            [
              -87.671462,
              42.058334
            ],
            [
              -87.682359,
              42.075729
            ],
            [
              -87.71096,
              42.095328
            ],
            [
              -87.724661,
              42.107727
            ],
            [
              -87.741662,
              42.128227
            ],
            [
              -87.74168992918979,
              42.1282651594679
            ],
            [
              -87.74959750958759,
              42.1390692344113
            ],
            [
              -87.7593265652386,
              42.1523619789799
            ],
            [
              -87.821174,
              42.152544
            ],
            [
              -87.848183,
              42.152811
            ],
            [
              -87.887268,
              42.152953
            ],
            [
              -87.934405,
              42.153106
            ],
            [
              -87.936462,
              42.153095
            ],
            [
              -87.946149,
              42.153173
            ],
            [
              -87.984994,
              42.153626
            ],
            [
              -88.004466,
              42.153645
            ],
            [
              -88.02375,
              42.153735
            ],
            [
              -88.121545,
              42.154193
            ],
            [
              -88.133659,
              42.154204
            ],
            [
              -88.150745,
              42.154215
            ],
            [
              -88.199584,
              42.15426
            ],
            [
              -88.238369,
              42.154253
            ],
            [
              -88.238291,
              42.092348
            ],
            [
              -88.237978,
              42.066926
            ],
            [
              -88.243326,
              42.067051
            ],
            [
              -88.263378,
              42.066626
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "091",
      "COUNTYNS": "00424247",
      "AFFGEOID": "0500000US17091",
      "GEOID": "17091",
      "NAME": "Kankakee",
      "LSAD": "06",
      "ALAND": 1752331448,
      "AWATER": 12418602,
      "County_state": "Kankakee,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.251995,
              41.114229
            ],
            [
              -88.2473,
              40.99456
            ],
            [
              -88.131938,
              40.997839
            ],
            [
              -87.52630654473128,
              41.0103549293006
            ],
            [
              -87.526346,
              41.010583
            ],
            [
              -87.52652,
              41.024837
            ],
            [
              -87.526711,
              41.121485
            ],
            [
              -87.5267,
              41.139658
            ],
            [
              -87.526696,
              41.149222
            ],
            [
              -87.526693,
              41.153958
            ],
            [
              -87.526719,
              41.159448
            ],
            [
              -87.52666,
              41.16009
            ],
            [
              -87.5266425829641,
              41.1607969818351
            ],
            [
              -87.526567,
              41.163865
            ],
            [
              -87.5265703397201,
              41.166096664112004
            ],
            [
              -87.52676781234179,
              41.2980516032755
            ],
            [
              -87.78468,
              41.295818
            ],
            [
              -87.897299,
              41.294366
            ],
            [
              -87.958129,
              41.293277
            ],
            [
              -88.013919,
              41.292447
            ],
            [
              -88.011812,
              41.205604
            ],
            [
              -88.244155,
              41.201546
            ],
            [
              -88.240889,
              41.114383
            ],
            [
              -88.251995,
              41.114229
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "153",
      "COUNTYNS": "01784966",
      "AFFGEOID": "0500000US17153",
      "GEOID": "17153",
      "NAME": "Pulaski",
      "LSAD": "06",
      "ALAND": 515887465,
      "AWATER": 10473563,
      "County_state": "Pulaski,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.273631,
              37.126968
            ],
            [
              -89.261865,
              37.106154
            ],
            [
              -89.228211,
              37.091714
            ],
            [
              -89.19721,
              37.085808
            ],
            [
              -89.171880551089,
              37.0681839790285
            ],
            [
              -89.168087,
              37.074218
            ],
            [
              -89.154504,
              37.088907
            ],
            [
              -89.151294,
              37.090487
            ],
            [
              -89.149797,
              37.089828
            ],
            [
              -89.146596,
              37.090714
            ],
            [
              -89.14132,
              37.093865
            ],
            [
              -89.138231,
              37.096906
            ],
            [
              -89.135847,
              37.102197
            ],
            [
              -89.134931,
              37.103278
            ],
            [
              -89.125072,
              37.108813
            ],
            [
              -89.12202,
              37.111342
            ],
            [
              -89.120465,
              37.113487
            ],
            [
              -89.115579,
              37.115781
            ],
            [
              -89.111189,
              37.119052
            ],
            [
              -89.099047,
              37.140967
            ],
            [
              -89.096669,
              37.1462
            ],
            [
              -89.095753,
              37.150391
            ],
            [
              -89.092934,
              37.156439
            ],
            [
              -89.086526,
              37.165602
            ],
            [
              -89.076221,
              37.175125
            ],
            [
              -89.058036,
              37.188767
            ],
            [
              -89.041263,
              37.202881
            ],
            [
              -89.037568,
              37.203932
            ],
            [
              -89.029981,
              37.211144
            ],
            [
              -89.014003,
              37.21609
            ],
            [
              -89.00592,
              37.221198
            ],
            [
              -89.008532,
              37.220583
            ],
            [
              -89.000968,
              37.224401
            ],
            [
              -88.98326,
              37.228685
            ],
            [
              -88.966831,
              37.229891
            ],
            [
              -88.942111,
              37.228811
            ],
            [
              -88.9330768781631,
              37.22774949504169
            ],
            [
              -88.931745,
              37.227593
            ],
            [
              -88.92801898699109,
              37.2266247234989
            ],
            [
              -88.929129,
              37.302647
            ],
            [
              -88.963578,
              37.29738
            ],
            [
              -89.003541,
              37.311242
            ],
            [
              -89.044588,
              37.294163
            ],
            [
              -89.044787,
              37.329846
            ],
            [
              -89.099277,
              37.333556
            ],
            [
              -89.169134,
              37.33419
            ],
            [
              -89.248439,
              37.335109
            ],
            [
              -89.228734,
              37.332142
            ],
            [
              -89.206569,
              37.321393
            ],
            [
              -89.202723,
              37.289376
            ],
            [
              -89.210394,
              37.280429
            ],
            [
              -89.238125,
              37.270729
            ],
            [
              -89.250714,
              37.256378
            ],
            [
              -89.250367,
              37.243318
            ],
            [
              -89.261279,
              37.220051
            ],
            [
              -89.262581,
              37.201677
            ],
            [
              -89.251919,
              37.190892
            ],
            [
              -89.26731,
              37.180525
            ],
            [
              -89.261836,
              37.169907
            ],
            [
              -89.268784,
              37.159113
            ],
            [
              -89.256577,
              37.159939
            ],
            [
              -89.257881,
              37.146585
            ],
            [
              -89.273631,
              37.126968
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "025",
      "COUNTYNS": "00424214",
      "AFFGEOID": "0500000US17025",
      "GEOID": "17025",
      "NAME": "Clay",
      "LSAD": "06",
      "ALAND": 1212958827,
      "AWATER": 3278251,
      "County_state": "Clay,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.695165,
              38.826299
            ],
            [
              -88.698457,
              38.606304
            ],
            [
              -88.564109,
              38.607093
            ],
            [
              -88.253851,
              38.599502
            ],
            [
              -88.285969,
              38.619117
            ],
            [
              -88.296989,
              38.63419
            ],
            [
              -88.272258,
              38.662546
            ],
            [
              -88.284338,
              38.681543
            ],
            [
              -88.283589,
              38.731801
            ],
            [
              -88.257783,
              38.731139
            ],
            [
              -88.258608,
              38.847521
            ],
            [
              -88.36176,
              38.851949
            ],
            [
              -88.361745,
              38.910847
            ],
            [
              -88.57991,
              38.915156
            ],
            [
              -88.693531,
              38.914617
            ],
            [
              -88.695165,
              38.826299
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "183",
      "COUNTYNS": "01785114",
      "AFFGEOID": "0500000US17183",
      "GEOID": "17183",
      "NAME": "Vermilion",
      "LSAD": "06",
      "ALAND": 2326763522,
      "AWATER": 7534491,
      "County_state": "Vermilion,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.942103,
              40.225482
            ],
            [
              -87.940933,
              40.141905
            ],
            [
              -87.940337,
              40.136681
            ],
            [
              -87.939949,
              40.083635
            ],
            [
              -87.937645,
              39.879803
            ],
            [
              -87.80371,
              39.88069
            ],
            [
              -87.615182,
              39.88163
            ],
            [
              -87.617424,
              39.868489
            ],
            [
              -87.557513,
              39.868719
            ],
            [
              -87.581553,
              39.88229
            ],
            [
              -87.5332268504449,
              39.88300000135369
            ],
            [
              -87.533227,
              39.883127
            ],
            [
              -87.532776,
              39.971077
            ],
            [
              -87.53279,
              39.97501
            ],
            [
              -87.532683,
              39.977691
            ],
            [
              -87.532542,
              39.987462
            ],
            [
              -87.532331,
              39.997776
            ],
            [
              -87.532287,
              40.000037
            ],
            [
              -87.532308,
              40.011492
            ],
            [
              -87.532308,
              40.011587
            ],
            [
              -87.531561,
              40.133005
            ],
            [
              -87.531759,
              40.144273
            ],
            [
              -87.531439,
              40.148027
            ],
            [
              -87.5314376605081,
              40.1481233164708
            ],
            [
              -87.531133,
              40.17003
            ],
            [
              -87.53103269801801,
              40.17719320580729
            ],
            [
              -87.530828,
              40.191812
            ],
            [
              -87.5308277456112,
              40.1918297171436
            ],
            [
              -87.529992,
              40.250036
            ],
            [
              -87.530054,
              40.250671
            ],
            [
              -87.526809,
              40.46217
            ],
            [
              -87.526549,
              40.475659
            ],
            [
              -87.526510746795,
              40.4768790330699
            ],
            [
              -87.526502,
              40.477158
            ],
            [
              -87.5263789480033,
              40.4912367109821
            ],
            [
              -87.935309,
              40.485923
            ],
            [
              -87.931875,
              40.404328
            ],
            [
              -87.932858,
              40.399401
            ],
            [
              -87.929442,
              40.269693
            ],
            [
              -87.92876,
              40.225619
            ],
            [
              -87.942103,
              40.225482
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "129",
      "COUNTYNS": "00424266",
      "AFFGEOID": "0500000US17129",
      "GEOID": "17129",
      "NAME": "Menard",
      "LSAD": "06",
      "ALAND": 814389209,
      "AWATER": 2641080,
      "County_state": "Menard,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.994724,
              40.108373
            ],
            [
              -89.994506,
              39.901925
            ],
            [
              -89.769236,
              39.902416
            ],
            [
              -89.769325,
              39.916344
            ],
            [
              -89.701864,
              39.916787
            ],
            [
              -89.698259,
              39.975309
            ],
            [
              -89.578289,
              39.976127
            ],
            [
              -89.579127,
              40.092324
            ],
            [
              -89.60112,
              40.092265
            ],
            [
              -89.601604,
              40.122432
            ],
            [
              -89.62029,
              40.136224
            ],
            [
              -89.634524,
              40.153575
            ],
            [
              -89.683807,
              40.155006
            ],
            [
              -89.694344,
              40.14091
            ],
            [
              -89.708102,
              40.146603
            ],
            [
              -89.749196,
              40.126852
            ],
            [
              -89.772433,
              40.131774
            ],
            [
              -89.794367,
              40.123805
            ],
            [
              -89.831769,
              40.123534
            ],
            [
              -89.841505,
              40.1298
            ],
            [
              -89.868249,
              40.12972
            ],
            [
              -89.870559,
              40.136684
            ],
            [
              -89.898118,
              40.127795
            ],
            [
              -89.926058,
              40.13995
            ],
            [
              -89.93677,
              40.132445
            ],
            [
              -89.958872,
              40.134361
            ],
            [
              -89.970083,
              40.142264
            ],
            [
              -89.982562,
              40.114377
            ],
            [
              -89.994724,
              40.108373
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "133",
      "COUNTYNS": "01784865",
      "AFFGEOID": "0500000US17133",
      "GEOID": "17133",
      "NAME": "Monroe",
      "LSAD": "06",
      "ALAND": 997207732,
      "AWATER": 33717847,
      "County_state": "Monroe,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.373819,
              38.294454
            ],
            [
              -90.373929,
              38.281853
            ],
            [
              -90.371869,
              38.273926
            ],
            [
              -90.370892,
              38.267441
            ],
            [
              -90.367764,
              38.255807
            ],
            [
              -90.367013,
              38.250054
            ],
            [
              -90.363926,
              38.236355
            ],
            [
              -90.359559,
              38.224525
            ],
            [
              -90.356176,
              38.217501
            ],
            [
              -90.3554305754695,
              38.216305829446696
            ],
            [
              -90.353902,
              38.213855
            ],
            [
              -90.334258,
              38.189932
            ],
            [
              -90.331554,
              38.18758
            ],
            [
              -90.322353,
              38.181593
            ],
            [
              -90.316839,
              38.179456
            ],
            [
              -90.31063,
              38.178572
            ],
            [
              -90.30049,
              38.175246
            ],
            [
              -90.290765,
              38.170453
            ],
            [
              -90.283091,
              38.164447
            ],
            [
              -90.274928,
              38.157615
            ],
            [
              -90.25266473951389,
              38.1278129416306
            ],
            [
              -90.252484,
              38.127571
            ],
            [
              -90.250118,
              38.125054
            ],
            [
              -90.243116,
              38.112669
            ],
            [
              -90.218708,
              38.094365
            ],
            [
              -90.2071279620056,
              38.0882050714256
            ],
            [
              -90.2066844548659,
              38.0879691505846
            ],
            [
              -90.035999,
              38.13563
            ],
            [
              -90.036385,
              38.223125
            ],
            [
              -89.899061,
              38.220755
            ],
            [
              -89.896723,
              38.230221
            ],
            [
              -89.908938,
              38.238158
            ],
            [
              -89.913418,
              38.256294
            ],
            [
              -89.924976,
              38.273234
            ],
            [
              -89.92,
              38.28569
            ],
            [
              -89.908756,
              38.276372
            ],
            [
              -89.904082,
              38.29763
            ],
            [
              -89.913226,
              38.307829
            ],
            [
              -90.036018,
              38.30885
            ],
            [
              -90.036078,
              38.323347
            ],
            [
              -90.145608,
              38.412153
            ],
            [
              -90.145336,
              38.426266
            ],
            [
              -90.2637229314809,
              38.5207549526996
            ],
            [
              -90.263814,
              38.520552
            ],
            [
              -90.268814,
              38.506152
            ],
            [
              -90.269989207833,
              38.5014041603547
            ],
            [
              -90.271314,
              38.496052
            ],
            [
              -90.274914,
              38.486253
            ],
            [
              -90.275915,
              38.479553
            ],
            [
              -90.279215,
              38.472453
            ],
            [
              -90.280587257131,
              38.4663350202911
            ],
            [
              -90.284015,
              38.451053
            ],
            [
              -90.285215,
              38.443453
            ],
            [
              -90.288815,
              38.438453
            ],
            [
              -90.295316,
              38.426753
            ],
            [
              -90.322317,
              38.401753
            ],
            [
              -90.328517,
              38.398153
            ],
            [
              -90.3407573658894,
              38.387506287665495
            ],
            [
              -90.343118,
              38.385453
            ],
            [
              -90.34348267213109,
              38.38501539344259
            ],
            [
              -90.346118,
              38.381853
            ],
            [
              -90.349743,
              38.377609
            ],
            [
              -90.356318,
              38.360354
            ],
            [
              -90.368219,
              38.340254
            ],
            [
              -90.370819,
              38.333554
            ],
            [
              -90.372519,
              38.323354
            ],
            [
              -90.371719,
              38.304354
            ],
            [
              -90.373819,
              38.294454
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "161",
      "COUNTYNS": "00424282",
      "AFFGEOID": "0500000US17161",
      "GEOID": "17161",
      "NAME": "Rock Island",
      "LSAD": "06",
      "ALAND": 1107115477,
      "AWATER": 61715041,
      "County_state": "Rock Island,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.071940564762,
              41.333592302215294
            ],
            [
              -91.0719555691705,
              41.33335834590849
            ],
            [
              -90.549953,
              41.327274
            ],
            [
              -90.540597,
              41.326969
            ],
            [
              -90.433771,
              41.326983
            ],
            [
              -90.432273,
              41.414171
            ],
            [
              -90.43193,
              41.456834
            ],
            [
              -90.376528,
              41.478734
            ],
            [
              -90.369427,
              41.496734
            ],
            [
              -90.332657,
              41.514001
            ],
            [
              -90.241089,
              41.524995
            ],
            [
              -90.192498,
              41.543213
            ],
            [
              -90.179098,
              41.573487
            ],
            [
              -90.185609,
              41.584653
            ],
            [
              -90.164842,
              41.597322
            ],
            [
              -90.162465,
              41.635526
            ],
            [
              -90.173913,
              41.640069
            ],
            [
              -90.161233,
              41.643927
            ],
            [
              -90.189532,
              41.663374
            ],
            [
              -90.232703,
              41.676573
            ],
            [
              -90.22679,
              41.692521
            ],
            [
              -90.244956,
              41.702379
            ],
            [
              -90.234705,
              41.736111
            ],
            [
              -90.247917,
              41.746877
            ],
            [
              -90.236773,
              41.762598
            ],
            [
              -90.24717,
              41.762609
            ],
            [
              -90.2423802567555,
              41.7829645079628
            ],
            [
              -90.248631,
              41.779805
            ],
            [
              -90.258622,
              41.775295
            ],
            [
              -90.263286,
              41.772112
            ],
            [
              -90.278633,
              41.767358
            ],
            [
              -90.302782,
              41.750031
            ],
            [
              -90.310708,
              41.742214
            ],
            [
              -90.31522,
              41.734264
            ],
            [
              -90.31635774707429,
              41.728884798759104
            ],
            [
              -90.317668,
              41.72269
            ],
            [
              -90.317421,
              41.718333
            ],
            [
              -90.31332,
              41.709494
            ],
            [
              -90.312893,
              41.707528
            ],
            [
              -90.31277,
              41.702426
            ],
            [
              -90.313435,
              41.698082
            ],
            [
              -90.314687,
              41.69483
            ],
            [
              -90.317315,
              41.69167
            ],
            [
              -90.319924,
              41.689721
            ],
            [
              -90.330222,
              41.683954
            ],
            [
              -90.332481,
              41.682146
            ],
            [
              -90.334525,
              41.679559
            ],
            [
              -90.336729,
              41.664532
            ],
            [
              -90.343452,
              41.646959
            ],
            [
              -90.34333,
              41.640855
            ],
            [
              -90.339528,
              41.598633
            ],
            [
              -90.341528,
              41.590633
            ],
            [
              -90.343228,
              41.587833
            ],
            [
              -90.364128,
              41.579633
            ],
            [
              -90.381329,
              41.576633
            ],
            [
              -90.39793,
              41.572233
            ],
            [
              -90.40573,
              41.56862091946309
            ],
            [
              -90.41283,
              41.565333
            ],
            [
              -90.41583,
              41.562933
            ],
            [
              -90.42223,
              41.554233
            ],
            [
              -90.42516586823469,
              41.5526479481636
            ],
            [
              -90.427231,
              41.551533
            ],
            [
              -90.432731,
              41.549533
            ],
            [
              -90.438431,
              41.544133
            ],
            [
              -90.445231,
              41.536133
            ],
            [
              -90.461432,
              41.523533
            ],
            [
              -90.474332,
              41.519733
            ],
            [
              -90.489933,
              41.518233
            ],
            [
              -90.499851992491,
              41.518047598271195
            ],
            [
              -90.500633,
              41.518033
            ],
            [
              -90.513134,
              41.519533
            ],
            [
              -90.533035,
              41.524933
            ],
            [
              -90.540935,
              41.526133
            ],
            [
              -90.5455141325201,
              41.525564050266595
            ],
            [
              -90.556235,
              41.524232
            ],
            [
              -90.567236,
              41.517532
            ],
            [
              -90.571136,
              41.516332
            ],
            [
              -90.582036,
              41.515132
            ],
            [
              -90.591037,
              41.512832
            ],
            [
              -90.595237,
              41.511032
            ],
            [
              -90.602137,
              41.506032
            ],
            [
              -90.604237,
              41.497032
            ],
            [
              -90.605937,
              41.494232
            ],
            [
              -90.618537,
              41.485032
            ],
            [
              -90.632538,
              41.478732
            ],
            [
              -90.640238,
              41.473332
            ],
            [
              -90.650238,
              41.465032
            ],
            [
              -90.655839,
              41.462132
            ],
            [
              -90.666239,
              41.460632
            ],
            [
              -90.676439,
              41.460832
            ],
            [
              -90.690951,
              41.456643
            ],
            [
              -90.701159,
              41.454743
            ],
            [
              -90.723545,
              41.452248
            ],
            [
              -90.737537,
              41.450127
            ],
            [
              -90.750142,
              41.449632
            ],
            [
              -90.771672,
              41.450761
            ],
            [
              -90.777583,
              41.451261
            ],
            [
              -90.786282,
              41.452888
            ],
            [
              -90.807283,
              41.454466
            ],
            [
              -90.824736,
              41.454467
            ],
            [
              -90.837414,
              41.455623
            ],
            [
              -90.846558,
              41.455141
            ],
            [
              -90.853604,
              41.453909
            ],
            [
              -90.857554,
              41.452751
            ],
            [
              -90.867282,
              41.448215
            ],
            [
              -90.879778,
              41.441065
            ],
            [
              -90.890787,
              41.435432
            ],
            [
              -90.900471,
              41.431154
            ],
            [
              -90.919351,
              41.425589
            ],
            [
              -90.924343,
              41.42286
            ],
            [
              -90.930016,
              41.421404
            ],
            [
              -90.949791,
              41.424163
            ],
            [
              -90.953198,
              41.425075
            ],
            [
              -90.966662,
              41.430051
            ],
            [
              -90.975168,
              41.433985
            ],
            [
              -90.979815,
              41.434321
            ],
            [
              -90.984898,
              41.433869
            ],
            [
              -91.005846,
              41.426135
            ],
            [
              -91.01198,
              41.425024
            ],
            [
              -91.027787,
              41.423603
            ],
            [
              -91.037131,
              41.420017
            ],
            [
              -91.039872,
              41.418523
            ],
            [
              -91.043988,
              41.415897
            ],
            [
              -91.04589,
              41.414085
            ],
            [
              -91.047819,
              41.4109
            ],
            [
              -91.050328,
              41.400049
            ],
            [
              -91.05101,
              41.387556
            ],
            [
              -91.05158,
              41.385283
            ],
            [
              -91.065058,
              41.369101
            ],
            [
              -91.06652,
              41.365246
            ],
            [
              -91.071552,
              41.339651
            ],
            [
              -91.071940564762,
              41.333592302215294
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "199",
      "COUNTYNS": "01785215",
      "AFFGEOID": "0500000US17199",
      "GEOID": "17199",
      "NAME": "Williamson",
      "LSAD": "06",
      "ALAND": 1088248004,
      "AWATER": 62617689,
      "County_state": "Williamson,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.152055,
              37.737379
            ],
            [
              -89.153668,
              37.600469
            ],
            [
              -89.041401,
              37.596576
            ],
            [
              -88.836759,
              37.597508
            ],
            [
              -88.818894,
              37.600677
            ],
            [
              -88.708546,
              37.599277
            ],
            [
              -88.70676,
              37.863338
            ],
            [
              -89.151176,
              37.861999
            ],
            [
              -89.152055,
              37.737379
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "203",
      "COUNTYNS": "01785231",
      "AFFGEOID": "0500000US17203",
      "GEOID": "17203",
      "NAME": "Woodford",
      "LSAD": "06",
      "ALAND": 1366998850,
      "AWATER": 38437077,
      "County_state": "Woodford,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.562394,
              40.793637
            ],
            [
              -89.555994,
              40.760437
            ],
            [
              -89.554994,
              40.747637
            ],
            [
              -89.330167,
              40.748257
            ],
            [
              -89.327343,
              40.615566
            ],
            [
              -89.269831,
              40.616097
            ],
            [
              -89.26939,
              40.594329
            ],
            [
              -89.155175,
              40.596417
            ],
            [
              -89.133752,
              40.596734
            ],
            [
              -89.134217,
              40.61149
            ],
            [
              -89.101134,
              40.612326
            ],
            [
              -89.101569,
              40.625036
            ],
            [
              -89.044368,
              40.627428
            ],
            [
              -89.04532,
              40.663939
            ],
            [
              -88.9847,
              40.664954
            ],
            [
              -88.986896,
              40.752297
            ],
            [
              -88.929331,
              40.753337
            ],
            [
              -88.93139,
              40.92774
            ],
            [
              -89.047718,
              40.925749
            ],
            [
              -89.143408,
              40.926848
            ],
            [
              -89.27582,
              40.92447
            ],
            [
              -89.47233,
              40.921206
            ],
            [
              -89.499571,
              40.875036
            ],
            [
              -89.524973,
              40.849236
            ],
            [
              -89.528285,
              40.835621
            ],
            [
              -89.562394,
              40.793637
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "069",
      "COUNTYNS": "00424236",
      "AFFGEOID": "0500000US17069",
      "GEOID": "17069",
      "NAME": "Hardin",
      "LSAD": "06",
      "ALAND": 459696734,
      "AWATER": 10588858,
      "County_state": "Hardin,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.415149,
              37.424863
            ],
            [
              -88.41488228248849,
              37.4236655948133
            ],
            [
              -88.413108,
              37.424468
            ],
            [
              -88.408808,
              37.425216
            ],
            [
              -88.404127,
              37.424146
            ],
            [
              -88.39734,
              37.421644
            ],
            [
              -88.387669,
              37.416482
            ],
            [
              -88.377507,
              37.409825
            ],
            [
              -88.373445,
              37.404342
            ],
            [
              -88.371214,
              37.40273
            ],
            [
              -88.365471,
              37.401663
            ],
            [
              -88.361557,
              37.402931
            ],
            [
              -88.35850600784909,
              37.4048167301695
            ],
            [
              -88.358436,
              37.40486
            ],
            [
              -88.348405,
              37.410726
            ],
            [
              -88.333183,
              37.42721
            ],
            [
              -88.330622,
              37.429316
            ],
            [
              -88.321199,
              37.434705
            ],
            [
              -88.317525,
              37.436178
            ],
            [
              -88.312585,
              37.440591
            ],
            [
              -88.297821,
              37.446816
            ],
            [
              -88.281667,
              37.452596
            ],
            [
              -88.255193,
              37.456748
            ],
            [
              -88.237784,
              37.456811
            ],
            [
              -88.225012,
              37.45739
            ],
            [
              -88.206923,
              37.460188
            ],
            [
              -88.188615,
              37.461896
            ],
            [
              -88.175283,
              37.46379
            ],
            [
              -88.171764,
              37.465612
            ],
            [
              -88.157061,
              37.466937
            ],
            [
              -88.135142,
              37.471626
            ],
            [
              -88.132628,
              37.471555
            ],
            [
              -88.12801,
              37.470507
            ],
            [
              -88.109417,
              37.472369
            ],
            [
              -88.095818,
              37.473025
            ],
            [
              -88.091156,
              37.472699
            ],
            [
              -88.091156,
              37.471715
            ],
            [
              -88.09038,
              37.471059
            ],
            [
              -88.087664,
              37.471059
            ],
            [
              -88.084171,
              37.472699
            ],
            [
              -88.077987,
              37.480146
            ],
            [
              -88.072386,
              37.483563
            ],
            [
              -88.068504,
              37.481921
            ],
            [
              -88.067728,
              37.481593
            ],
            [
              -88.064234,
              37.484548
            ],
            [
              -88.062294,
              37.487837
            ],
            [
              -88.062174,
              37.489057
            ],
            [
              -88.06295,
              37.489385
            ],
            [
              -88.064115,
              37.492013
            ],
            [
              -88.062563,
              37.495951
            ],
            [
              -88.061292,
              37.505232
            ],
            [
              -88.061342239857,
              37.505326559522494
            ],
            [
              -88.062828,
              37.508123
            ],
            [
              -88.062568,
              37.513563
            ],
            [
              -88.063311,
              37.515755
            ],
            [
              -88.069018,
              37.525297
            ],
            [
              -88.072242,
              37.528826
            ],
            [
              -88.078046,
              37.532029
            ],
            [
              -88.086194,
              37.534186
            ],
            [
              -88.088049,
              37.535124
            ],
            [
              -88.092814,
              37.539637
            ],
            [
              -88.101174,
              37.55133
            ],
            [
              -88.105585,
              37.55618
            ],
            [
              -88.11433,
              37.562189
            ],
            [
              -88.121517,
              37.568166
            ],
            [
              -88.131622,
              37.572968
            ],
            [
              -88.133393,
              37.574235
            ],
            [
              -88.133410402099,
              37.5742729944782
            ],
            [
              -88.15282,
              37.573799
            ],
            [
              -88.179647,
              37.599093
            ],
            [
              -88.375332,
              37.599563
            ],
            [
              -88.412112,
              37.599912
            ],
            [
              -88.415149,
              37.424863
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "001",
      "COUNTYNS": "00424202",
      "AFFGEOID": "0500000US17001",
      "GEOID": "17001",
      "NAME": "Adams",
      "LSAD": "06",
      "ALAND": 2214972287,
      "AWATER": 41784365,
      "County_state": "Adams,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.512974,
              40.181062
            ],
            [
              -91.513079,
              40.178537
            ],
            [
              -91.511956,
              40.170441
            ],
            [
              -91.508224,
              40.157665
            ],
            [
              -91.508324,
              40.156326
            ],
            [
              -91.51159,
              40.149269
            ],
            [
              -91.511749,
              40.147091
            ],
            [
              -91.510322,
              40.127994
            ],
            [
              -91.509245,
              40.121876
            ],
            [
              -91.506006,
              40.108126
            ],
            [
              -91.500823,
              40.090956
            ],
            [
              -91.497663,
              40.078257
            ],
            [
              -91.495365,
              40.070951
            ],
            [
              -91.489606,
              40.057435
            ],
            [
              -91.494878,
              40.036453
            ],
            [
              -91.487351,
              40.023201
            ],
            [
              -91.484064,
              40.019332
            ],
            [
              -91.477298,
              40.008993
            ],
            [
              -91.469247,
              39.995327
            ],
            [
              -91.467294,
              39.990631
            ],
            [
              -91.466682,
              39.987253
            ],
            [
              -91.465315,
              39.983995
            ],
            [
              -91.463683,
              39.981845
            ],
            [
              -91.459533,
              39.979892
            ],
            [
              -91.458852,
              39.979015
            ],
            [
              -91.454647,
              39.971306
            ],
            [
              -91.449806,
              39.965278
            ],
            [
              -91.447236,
              39.959502
            ],
            [
              -91.44156,
              39.951299
            ],
            [
              -91.43709,
              39.946417
            ],
            [
              -91.43547750086269,
              39.945277915357394
            ],
            [
              -91.429055,
              39.940741
            ],
            [
              -91.425782,
              39.937765
            ],
            [
              -91.421832,
              39.932973
            ],
            [
              -91.41936,
              39.927717
            ],
            [
              -91.418807,
              39.922126
            ],
            [
              -91.41988,
              39.916533
            ],
            [
              -91.420878,
              39.914865
            ],
            [
              -91.428956,
              39.907729
            ],
            [
              -91.443513,
              39.893583
            ],
            [
              -91.446922,
              39.883034
            ],
            [
              -91.447844,
              39.877951
            ],
            [
              -91.446385,
              39.870394
            ],
            [
              -91.436051,
              39.84551
            ],
            [
              -91.432919,
              39.840554
            ],
            [
              -91.429519,
              39.837801
            ],
            [
              -91.414513,
              39.829984
            ],
            [
              -91.406223,
              39.826472
            ],
            [
              -91.397853,
              39.821122
            ],
            [
              -91.385773,
              39.815553
            ],
            [
              -91.377971,
              39.811273
            ],
            [
              -91.375148,
              39.80886
            ],
            [
              -91.367966,
              39.800403
            ],
            [
              -91.363444,
              39.792804
            ],
            [
              -91.361571,
              39.787548
            ],
            [
              -91.361744,
              39.785079
            ],
            [
              -91.364848,
              39.779388
            ],
            [
              -91.365694,
              39.77491
            ],
            [
              -91.365906,
              39.764956
            ],
            [
              -91.365125,
              39.758723
            ],
            [
              -90.916071,
              39.757168
            ],
            [
              -90.916609,
              39.845075
            ],
            [
              -90.916631,
              39.911459
            ],
            [
              -90.914275,
              39.938302
            ],
            [
              -90.913616,
              40.104452
            ],
            [
              -90.911969,
              40.193088
            ],
            [
              -91.499121,
              40.200056
            ],
            [
              -91.50522531361149,
              40.200484700146596
            ],
            [
              -91.504477,
              40.198262
            ],
            [
              -91.505495,
              40.195606
            ],
            [
              -91.509551,
              40.191338
            ],
            [
              -91.511073,
              40.188794
            ],
            [
              -91.512974,
              40.181062
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "075",
      "COUNTYNS": "00424239",
      "AFFGEOID": "0500000US17075",
      "GEOID": "17075",
      "NAME": "Iroquois",
      "LSAD": "06",
      "ALAND": 2893780721,
      "AWATER": 4225839,
      "County_state": "Iroquois,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.131938,
              40.997839
            ],
            [
              -88.118191,
              40.516982
            ],
            [
              -88.117905,
              40.488086
            ],
            [
              -88.004076,
              40.488672
            ],
            [
              -87.935309,
              40.485923
            ],
            [
              -87.5263789480033,
              40.4912367109821
            ],
            [
              -87.526376,
              40.491574
            ],
            [
              -87.526352,
              40.535111
            ],
            [
              -87.526292,
              40.535409
            ],
            [
              -87.5261290525058,
              40.7368850793018
            ],
            [
              -87.526129,
              40.73695
            ],
            [
              -87.525783,
              40.854357
            ],
            [
              -87.526113,
              40.879703
            ],
            [
              -87.525962,
              40.880618
            ],
            [
              -87.526437,
              40.894209
            ],
            [
              -87.526014,
              40.895582
            ],
            [
              -87.5260836726485,
              40.9118376242294
            ],
            [
              -87.526084,
              40.911914
            ],
            [
              -87.526305,
              41.010346
            ],
            [
              -87.52630654473128,
              41.0103549293006
            ],
            [
              -88.131938,
              40.997839
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "105",
      "COUNTYNS": "00424254",
      "AFFGEOID": "0500000US17105",
      "GEOID": "17105",
      "NAME": "Livingston",
      "LSAD": "06",
      "ALAND": 2704538434,
      "AWATER": 4401361,
      "County_state": "Livingston,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.93073,
              41.072154
            ],
            [
              -88.93139,
              40.92774
            ],
            [
              -88.929331,
              40.753337
            ],
            [
              -88.584272,
              40.757608
            ],
            [
              -88.58215,
              40.670604
            ],
            [
              -88.575216,
              40.670616
            ],
            [
              -88.574502,
              40.61655
            ],
            [
              -88.459475,
              40.617345
            ],
            [
              -88.38723,
              40.61727
            ],
            [
              -88.234949,
              40.618166
            ],
            [
              -88.2473,
              40.99456
            ],
            [
              -88.251995,
              41.114229
            ],
            [
              -88.35599,
              41.11175
            ],
            [
              -88.58624,
              41.108293
            ],
            [
              -88.777931,
              41.106657
            ],
            [
              -88.81221,
              41.10652
            ],
            [
              -88.930881,
              41.1059
            ],
            [
              -88.93073,
              41.072154
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "071",
      "COUNTYNS": "00424237",
      "AFFGEOID": "0500000US17071",
      "GEOID": "17071",
      "NAME": "Henderson",
      "LSAD": "06",
      "ALAND": 981273713,
      "AWATER": 42340542,
      "County_state": "Henderson,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.154293,
              40.653596
            ],
            [
              -91.185428463588,
              40.6380706559482
            ],
            [
              -91.02263,
              40.634946
            ],
            [
              -90.904216,
              40.639201
            ],
            [
              -90.789652,
              40.63575
            ],
            [
              -90.78957,
              40.679038
            ],
            [
              -90.785194,
              41.068749
            ],
            [
              -90.94899002615979,
              41.0702495855382
            ],
            [
              -90.945549,
              41.06173
            ],
            [
              -90.944577,
              41.052255
            ],
            [
              -90.943652,
              41.048637
            ],
            [
              -90.94232,
              41.038472
            ],
            [
              -90.942253,
              41.034702
            ],
            [
              -90.945324,
              41.019279
            ],
            [
              -90.945054,
              41.011917
            ],
            [
              -90.945949,
              41.006495
            ],
            [
              -90.949634,
              40.995248
            ],
            [
              -90.955201,
              40.986805
            ],
            [
              -90.958142,
              40.979767
            ],
            [
              -90.958089,
              40.976643
            ],
            [
              -90.955111,
              40.969858
            ],
            [
              -90.952715,
              40.962087
            ],
            [
              -90.951967,
              40.958238
            ],
            [
              -90.952233,
              40.954047
            ],
            [
              -90.960462,
              40.936356
            ],
            [
              -90.962916,
              40.924957
            ],
            [
              -90.965344,
              40.921633
            ],
            [
              -90.968995,
              40.919127
            ],
            [
              -90.97919,
              40.915522
            ],
            [
              -90.985462,
              40.912141
            ],
            [
              -90.9985,
              40.90812
            ],
            [
              -91.003536,
              40.905146
            ],
            [
              -91.009536,
              40.900565
            ],
            [
              -91.0117926988919,
              40.898162687977596
            ],
            [
              -91.01324,
              40.896622
            ],
            [
              -91.021562,
              40.884021
            ],
            [
              -91.027489,
              40.879173
            ],
            [
              -91.036789,
              40.875038
            ],
            [
              -91.039097,
              40.873565
            ],
            [
              -91.044653,
              40.868356
            ],
            [
              -91.047344,
              40.864654
            ],
            [
              -91.050241,
              40.858514
            ],
            [
              -91.05643,
              40.848387
            ],
            [
              -91.058749,
              40.846309
            ],
            [
              -91.067159,
              40.841997
            ],
            [
              -91.077521,
              40.833405
            ],
            [
              -91.090072,
              40.824638
            ],
            [
              -91.092993,
              40.821079
            ],
            [
              -91.096846,
              40.811617
            ],
            [
              -91.097649,
              40.805575
            ],
            [
              -91.097031,
              40.802471
            ],
            [
              -91.092256,
              40.792909
            ],
            [
              -91.091246,
              40.786724
            ],
            [
              -91.091703,
              40.779708
            ],
            [
              -91.096133,
              40.767134
            ],
            [
              -91.098105,
              40.763233
            ],
            [
              -91.102486,
              40.757076
            ],
            [
              -91.1082,
              40.750935
            ],
            [
              -91.110424,
              40.745528
            ],
            [
              -91.115735,
              40.725168
            ],
            [
              -91.115158,
              40.721895
            ],
            [
              -91.113885,
              40.719532
            ],
            [
              -91.111095,
              40.708282
            ],
            [
              -91.110927,
              40.703262
            ],
            [
              -91.11194,
              40.697018
            ],
            [
              -91.11243358457939,
              40.6962786908794
            ],
            [
              -91.115407,
              40.691825
            ],
            [
              -91.119632,
              40.675892
            ],
            [
              -91.12082,
              40.672777
            ],
            [
              -91.122421,
              40.670675
            ],
            [
              -91.123928,
              40.669152
            ],
            [
              -91.138055,
              40.660893
            ],
            [
              -91.154293,
              40.653596
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "007",
      "COUNTYNS": "00424205",
      "AFFGEOID": "0500000US17007",
      "GEOID": "17007",
      "NAME": "Boone",
      "LSAD": "06",
      "ALAND": 727092582,
      "AWATER": 3348032,
      "County_state": "Boone,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.9403879812716,
              42.4950459398454
            ],
            [
              -88.940371,
              42.370234
            ],
            [
              -88.94028,
              42.34438
            ],
            [
              -88.939732,
              42.15232
            ],
            [
              -88.822448,
              42.153336
            ],
            [
              -88.705633,
              42.153561
            ],
            [
              -88.70561,
              42.327882
            ],
            [
              -88.7073777163598,
              42.493587048379496
            ],
            [
              -88.70738,
              42.493587
            ],
            [
              -88.76536,
              42.492068
            ],
            [
              -88.7764931385154,
              42.492023615741594
            ],
            [
              -88.786681,
              42.491983
            ],
            [
              -88.9403879812716,
              42.4950459398454
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "003",
      "COUNTYNS": "00424203",
      "AFFGEOID": "0500000US17003",
      "GEOID": "17003",
      "NAME": "Alexander",
      "LSAD": "06",
      "ALAND": 609996941,
      "AWATER": 44237191,
      "County_state": "Alexander,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.518393,
              37.289354
            ],
            [
              -89.51834,
              37.285497
            ],
            [
              -89.517032,
              37.28192
            ],
            [
              -89.513905,
              37.277164
            ],
            [
              -89.506773,
              37.268537
            ],
            [
              -89.502303,
              37.263738
            ],
            [
              -89.496386,
              37.258474
            ],
            [
              -89.489915,
              37.251315
            ],
            [
              -89.4887281875826,
              37.251507483022294
            ],
            [
              -89.479205,
              37.253052
            ],
            [
              -89.470525,
              37.253357
            ],
            [
              -89.46266,
              37.25152
            ],
            [
              -89.460692,
              37.250577
            ],
            [
              -89.458827,
              37.248661
            ],
            [
              -89.458246,
              37.247066
            ],
            [
              -89.457832,
              37.242594
            ],
            [
              -89.458302,
              37.240368
            ],
            [
              -89.4675,
              37.221844
            ],
            [
              -89.467631,
              37.2182
            ],
            [
              -89.462676,
              37.203351
            ],
            [
              -89.461862,
              37.199517
            ],
            [
              -89.456105,
              37.18812
            ],
            [
              -89.438275,
              37.161287
            ],
            [
              -89.435202,
              37.15209
            ],
            [
              -89.42558,
              37.138235
            ],
            [
              -89.414471,
              37.12505
            ],
            [
              -89.41173,
              37.122507
            ],
            [
              -89.407451,
              37.119307
            ],
            [
              -89.393427,
              37.111197
            ],
            [
              -89.38805,
              37.107481
            ],
            [
              -89.384175,
              37.103267
            ],
            [
              -89.37871,
              37.094586
            ],
            [
              -89.375615,
              37.085936
            ],
            [
              -89.375712,
              37.080505
            ],
            [
              -89.378889,
              37.070499
            ],
            [
              -89.385186,
              37.057748
            ],
            [
              -89.385434,
              37.05513
            ],
            [
              -89.384681,
              37.048251
            ],
            [
              -89.383937,
              37.046441
            ],
            [
              -89.381644,
              37.04301
            ],
            [
              -89.378277,
              37.039605
            ],
            [
              -89.362397,
              37.030156
            ],
            [
              -89.345996,
              37.025521
            ],
            [
              -89.331164,
              37.019936
            ],
            [
              -89.322982,
              37.01609
            ],
            [
              -89.317168,
              37.012767
            ],
            [
              -89.3126416196891,
              37.0090469003899
            ],
            [
              -89.29213,
              36.992189
            ],
            [
              -89.278628,
              36.98867
            ],
            [
              -89.274198,
              36.990495
            ],
            [
              -89.269564,
              36.993401
            ],
            [
              -89.266242,
              36.996302
            ],
            [
              -89.263527,
              37.00005
            ],
            [
              -89.257608,
              37.015496
            ],
            [
              -89.260003,
              37.023288
            ],
            [
              -89.266286,
              37.028683
            ],
            [
              -89.277715,
              37.03614
            ],
            [
              -89.291185,
              37.040408
            ],
            [
              -89.301368,
              37.044982
            ],
            [
              -89.304752,
              37.047565
            ],
            [
              -89.307397,
              37.050432
            ],
            [
              -89.309401,
              37.053769
            ],
            [
              -89.310819,
              37.057897
            ],
            [
              -89.30829,
              37.068371
            ],
            [
              -89.307726,
              37.069654
            ],
            [
              -89.294036,
              37.067345
            ],
            [
              -89.283685,
              37.066736
            ],
            [
              -89.283488,
              37.065811
            ],
            [
              -89.280375,
              37.065224
            ],
            [
              -89.264484,
              37.064814
            ],
            [
              -89.259936,
              37.064071
            ],
            [
              -89.25493,
              37.072014
            ],
            [
              -89.245648,
              37.057783
            ],
            [
              -89.238253,
              37.042853
            ],
            [
              -89.234053,
              37.037277
            ],
            [
              -89.225482,
              37.031077
            ],
            [
              -89.205038,
              37.020047
            ],
            [
              -89.200793,
              37.016164
            ],
            [
              -89.198488,
              37.011723
            ],
            [
              -89.195029,
              37.000051
            ],
            [
              -89.195039,
              36.989768
            ],
            [
              -89.192097,
              36.979995
            ],
            [
              -89.185491,
              36.973518
            ],
            [
              -89.177235,
              36.970885
            ],
            [
              -89.170008,
              36.970298
            ],
            [
              -89.161767,
              36.972768
            ],
            [
              -89.149882,
              36.977636
            ],
            [
              -89.14411,
              36.979133
            ],
            [
              -89.140814,
              36.979416
            ],
            [
              -89.132915,
              36.982057
            ],
            [
              -89.138437,
              36.985089
            ],
            [
              -89.160667,
              37.000051
            ],
            [
              -89.166447,
              37.003337
            ],
            [
              -89.17112,
              37.008072
            ],
            [
              -89.173595,
              37.011409
            ],
            [
              -89.178975,
              37.020928
            ],
            [
              -89.180849,
              37.026843
            ],
            [
              -89.182509,
              37.037275
            ],
            [
              -89.181369,
              37.046305
            ],
            [
              -89.179384,
              37.053012
            ],
            [
              -89.175725,
              37.062069
            ],
            [
              -89.171880551089,
              37.0681839790285
            ],
            [
              -89.19721,
              37.085808
            ],
            [
              -89.228211,
              37.091714
            ],
            [
              -89.261865,
              37.106154
            ],
            [
              -89.273631,
              37.126968
            ],
            [
              -89.257881,
              37.146585
            ],
            [
              -89.256577,
              37.159939
            ],
            [
              -89.268784,
              37.159113
            ],
            [
              -89.261836,
              37.169907
            ],
            [
              -89.26731,
              37.180525
            ],
            [
              -89.251919,
              37.190892
            ],
            [
              -89.262581,
              37.201677
            ],
            [
              -89.261279,
              37.220051
            ],
            [
              -89.250367,
              37.243318
            ],
            [
              -89.250714,
              37.256378
            ],
            [
              -89.238125,
              37.270729
            ],
            [
              -89.210394,
              37.280429
            ],
            [
              -89.202723,
              37.289376
            ],
            [
              -89.206569,
              37.321393
            ],
            [
              -89.228734,
              37.332142
            ],
            [
              -89.248439,
              37.335109
            ],
            [
              -89.4859297365844,
              37.3348292953819
            ],
            [
              -89.489005,
              37.333368
            ],
            [
              -89.4893198828342,
              37.333079297465396
            ],
            [
              -89.491194,
              37.331361
            ],
            [
              -89.49516,
              37.324795
            ],
            [
              -89.49909,
              37.32149
            ],
            [
              -89.508174,
              37.315662
            ],
            [
              -89.509699,
              37.31426
            ],
            [
              -89.511842,
              37.310825
            ],
            [
              -89.514042,
              37.303776
            ],
            [
              -89.514605,
              37.299323
            ],
            [
              -89.515741,
              37.295362
            ],
            [
              -89.517692,
              37.29204
            ],
            [
              -89.518393,
              37.289354
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "015",
      "COUNTYNS": "00424209",
      "AFFGEOID": "0500000US17015",
      "GEOID": "17015",
      "NAME": "Carroll",
      "LSAD": "06",
      "ALAND": 1152707885,
      "AWATER": 55841450,
      "County_state": "Carroll,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.31794761822601,
              42.1939090229626
            ],
            [
              -90.317774,
              42.193789
            ],
            [
              -90.298442,
              42.187576
            ],
            [
              -90.282173,
              42.178846
            ],
            [
              -90.26908,
              42.1745
            ],
            [
              -90.255456,
              42.171821
            ],
            [
              -90.250129,
              42.171469
            ],
            [
              -90.234919,
              42.165431
            ],
            [
              -90.224244,
              42.160028
            ],
            [
              -90.216107,
              42.15673
            ],
            [
              -90.209479,
              42.15268
            ],
            [
              -90.207421,
              42.149109
            ],
            [
              -90.206369,
              42.1455
            ],
            [
              -90.20536,
              42.139079
            ],
            [
              -90.201404,
              42.130937
            ],
            [
              -90.197342,
              42.128163
            ],
            [
              -90.190452,
              42.125779
            ],
            [
              -90.187474,
              42.125423
            ],
            [
              -90.17097,
              42.125198
            ],
            [
              -90.167533,
              42.122475
            ],
            [
              -90.162895,
              42.116718
            ],
            [
              -90.161884,
              42.11378
            ],
            [
              -90.161159,
              42.106372
            ],
            [
              -90.161504,
              42.098912
            ],
            [
              -90.163405,
              42.087613
            ],
            [
              -90.168358,
              42.075779
            ],
            [
              -90.165555,
              42.062638
            ],
            [
              -90.165294,
              42.050973
            ],
            [
              -90.164485,
              42.042105
            ],
            [
              -90.163446,
              42.040407
            ],
            [
              -90.158829,
              42.037769
            ],
            [
              -90.154221,
              42.033073
            ],
            [
              -90.151579,
              42.030633
            ],
            [
              -90.150916,
              42.02944
            ],
            [
              -90.149733,
              42.026564
            ],
            [
              -90.149112,
              42.022679
            ],
            [
              -90.148096,
              42.020014
            ],
            [
              -90.143776,
              42.014881
            ],
            [
              -90.141167,
              42.008931
            ],
            [
              -90.140061,
              42.003252
            ],
            [
              -90.140613,
              41.995999
            ],
            [
              -90.146033,
              41.988139
            ],
            [
              -90.146225,
              41.981329
            ],
            [
              -90.148599,
              41.978269
            ],
            [
              -90.153834,
              41.974116
            ],
            [
              -90.162141,
              41.961293
            ],
            [
              -90.164135,
              41.956178
            ],
            [
              -90.164939,
              41.948861
            ],
            [
              -90.163847,
              41.944934
            ],
            [
              -90.160648,
              41.940845
            ],
            [
              -90.156902,
              41.938181
            ],
            [
              -90.152659,
              41.933058
            ],
            [
              -90.1516,
              41.931002
            ],
            [
              -90.1518383787674,
              41.928917335739996
            ],
            [
              -90.063551,
              41.930106
            ],
            [
              -90.017534,
              41.930442
            ],
            [
              -89.987718,
              41.931009
            ],
            [
              -89.978437,
              41.931187
            ],
            [
              -89.685366,
              41.93034
            ],
            [
              -89.686549,
              41.937493
            ],
            [
              -89.684695,
              42.025788
            ],
            [
              -89.688486,
              42.199112
            ],
            [
              -89.913238,
              42.19628
            ],
            [
              -89.91965,
              42.196824
            ],
            [
              -90.096038,
              42.197108
            ],
            [
              -90.31794761822601,
              42.1939090229626
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "197",
      "COUNTYNS": "01785190",
      "AFFGEOID": "0500000US17197",
      "GEOID": "17197",
      "NAME": "Will",
      "LSAD": "06",
      "ALAND": 2166099738,
      "AWATER": 33374154,
      "County_state": "Will,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.261273,
              41.724534
            ],
            [
              -88.26081,
              41.695843
            ],
            [
              -88.25912,
              41.6362
            ],
            [
              -88.257274,
              41.593469
            ],
            [
              -88.255348,
              41.550089
            ],
            [
              -88.25429,
              41.520721
            ],
            [
              -88.252231,
              41.463066
            ],
            [
              -88.252219,
              41.462753
            ],
            [
              -88.25071,
              41.4191
            ],
            [
              -88.249188,
              41.375691
            ],
            [
              -88.246993,
              41.288579
            ],
            [
              -88.244155,
              41.201546
            ],
            [
              -88.011812,
              41.205604
            ],
            [
              -88.013919,
              41.292447
            ],
            [
              -87.958129,
              41.293277
            ],
            [
              -87.897299,
              41.294366
            ],
            [
              -87.78468,
              41.295818
            ],
            [
              -87.52676781234179,
              41.2980516032755
            ],
            [
              -87.526768,
              41.298177
            ],
            [
              -87.526404,
              41.355812
            ],
            [
              -87.52535,
              41.380851
            ],
            [
              -87.5255553386101,
              41.4355838937009
            ],
            [
              -87.525623,
              41.453619
            ],
            [
              -87.525671,
              41.470115
            ],
            [
              -87.5256689455744,
              41.470282557937196
            ],
            [
              -87.557849,
              41.470066
            ],
            [
              -87.577103,
              41.469993
            ],
            [
              -87.577339,
              41.469992
            ],
            [
              -87.653236,
              41.469754
            ],
            [
              -87.654065,
              41.469756
            ],
            [
              -87.698645,
              41.469767
            ],
            [
              -87.787302,
              41.469839
            ],
            [
              -87.790303,
              41.470113
            ],
            [
              -87.790317,
              41.499036
            ],
            [
              -87.790408,
              41.528041
            ],
            [
              -87.79229,
              41.543734
            ],
            [
              -87.792793,
              41.55854
            ],
            [
              -87.812262,
              41.558291
            ],
            [
              -87.909377,
              41.556819
            ],
            [
              -87.910461,
              41.600302
            ],
            [
              -87.91193,
              41.643933
            ],
            [
              -88.027688,
              41.641506
            ],
            [
              -88.028557,
              41.669915
            ],
            [
              -88.028985,
              41.685518
            ],
            [
              -88.029586,
              41.705045
            ],
            [
              -88.030352,
              41.728894
            ],
            [
              -88.063367,
              41.728147
            ],
            [
              -88.117293,
              41.726848
            ],
            [
              -88.148194,
              41.726117
            ],
            [
              -88.20011,
              41.725326
            ],
            [
              -88.206038,
              41.725255
            ],
            [
              -88.206298,
              41.725251
            ],
            [
              -88.261273,
              41.724534
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "175",
      "COUNTYNS": "00424288",
      "AFFGEOID": "0500000US17175",
      "GEOID": "17175",
      "NAME": "Stark",
      "LSAD": "06",
      "ALAND": 746121490,
      "AWATER": 708705,
      "County_state": "Stark,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.984559,
              41.149366
            ],
            [
              -89.985479,
              40.974495
            ],
            [
              -89.638727,
              40.973707
            ],
            [
              -89.638528,
              41.141321
            ],
            [
              -89.638429,
              41.148591
            ],
            [
              -89.638864,
              41.233862
            ],
            [
              -89.857798,
              41.234483
            ],
            [
              -89.867738,
              41.23443
            ],
            [
              -89.868384,
              41.148955
            ],
            [
              -89.875105,
              41.149507
            ],
            [
              -89.984559,
              41.149366
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "157",
      "COUNTYNS": "01784967",
      "AFFGEOID": "0500000US17157",
      "GEOID": "17157",
      "NAME": "Randolph",
      "LSAD": "06",
      "ALAND": 1490498589,
      "AWATER": 56254913,
      "County_state": "Randolph,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.2066844548659,
              38.0879691505846
            ],
            [
              -90.17222,
              38.069636
            ],
            [
              -90.163411,
              38.074347
            ],
            [
              -90.161562,
              38.07489
            ],
            [
              -90.158533,
              38.074735
            ],
            [
              -90.144553,
              38.069023
            ],
            [
              -90.130788,
              38.062341
            ],
            [
              -90.128159,
              38.059644
            ],
            [
              -90.126396,
              38.054897
            ],
            [
              -90.126006,
              38.05057
            ],
            [
              -90.126612,
              38.043981
            ],
            [
              -90.126194,
              38.040702
            ],
            [
              -90.117423,
              38.031708
            ],
            [
              -90.11052,
              38.026547
            ],
            [
              -90.093774,
              38.017833
            ],
            [
              -90.08826,
              38.015772
            ],
            [
              -90.080959,
              38.015428
            ],
            [
              -90.072283,
              38.017001
            ],
            [
              -90.065045,
              38.016875
            ],
            [
              -90.059367,
              38.015543
            ],
            [
              -90.057269,
              38.014362
            ],
            [
              -90.055399,
              38.011953
            ],
            [
              -90.053541,
              38.00844
            ],
            [
              -90.052883,
              38.005907
            ],
            [
              -90.051357,
              38.003584
            ],
            [
              -90.049106,
              38.001715
            ],
            [
              -90.045908,
              38.000052
            ],
            [
              -90.03241,
              37.995258
            ],
            [
              -90.008353,
              37.970179
            ],
            [
              -90.00011,
              37.964563
            ],
            [
              -89.997103,
              37.963225
            ],
            [
              -89.986296,
              37.962198
            ],
            [
              -89.978919,
              37.962791
            ],
            [
              -89.95491,
              37.966647
            ],
            [
              -89.942099,
              37.970121
            ],
            [
              -89.93774,
              37.964994
            ],
            [
              -89.93693,
              37.961042
            ],
            [
              -89.935886,
              37.959581
            ],
            [
              -89.933797,
              37.959143
            ],
            [
              -89.925085,
              37.960021
            ],
            [
              -89.924811,
              37.955823
            ],
            [
              -89.925389,
              37.95413
            ],
            [
              -89.932467,
              37.947497
            ],
            [
              -89.937927,
              37.946193
            ],
            [
              -89.947429,
              37.940336
            ],
            [
              -89.959646,
              37.940196
            ],
            [
              -89.962273,
              37.934363
            ],
            [
              -89.968365,
              37.931456
            ],
            [
              -89.974918,
              37.926719
            ],
            [
              -89.974221,
              37.919217
            ],
            [
              -89.973642,
              37.917661
            ],
            [
              -89.971649,
              37.91526
            ],
            [
              -89.952499,
              37.883218
            ],
            [
              -89.950594,
              37.881526
            ],
            [
              -89.93819072186169,
              37.8751107703036
            ],
            [
              -89.937383,
              37.874693
            ],
            [
              -89.923185,
              37.870672
            ],
            [
              -89.913317,
              37.869641
            ],
            [
              -89.901832,
              37.869822
            ],
            [
              -89.897301,
              37.871605
            ],
            [
              -89.881475,
              37.879591
            ],
            [
              -89.876594,
              37.883294
            ],
            [
              -89.866988,
              37.893519
            ],
            [
              -89.862949,
              37.896906
            ],
            [
              -89.851048,
              37.90398
            ],
            [
              -89.844786,
              37.905572
            ],
            [
              -89.842649,
              37.905196
            ],
            [
              -89.836254,
              37.901802
            ],
            [
              -89.813647,
              37.88771
            ],
            [
              -89.803913,
              37.882985
            ],
            [
              -89.799333,
              37.881517
            ],
            [
              -89.798041,
              37.879655
            ],
            [
              -89.797678,
              37.874202
            ],
            [
              -89.799835,
              37.871367
            ],
            [
              -89.80036,
              37.868625
            ],
            [
              -89.796087,
              37.859505
            ],
            [
              -89.793718,
              37.857054
            ],
            [
              -89.786369,
              37.851734
            ],
            [
              -89.782035,
              37.855092
            ],
            [
              -89.779828,
              37.853896
            ],
            [
              -89.774306,
              37.852123
            ],
            [
              -89.765222,
              37.851782
            ],
            [
              -89.761992,
              37.850657
            ],
            [
              -89.757363,
              37.847613
            ],
            [
              -89.754104,
              37.846358
            ],
            [
              -89.749961,
              37.846984
            ],
            [
              -89.739873,
              37.84693
            ],
            [
              -89.736439,
              37.843494
            ],
            [
              -89.732737,
              37.838507
            ],
            [
              -89.729426,
              37.835081
            ],
            [
              -89.71748,
              37.825724
            ],
            [
              -89.702844,
              37.816812
            ],
            [
              -89.696559,
              37.814337
            ],
            [
              -89.68285,
              37.807789
            ],
            [
              -89.677605,
              37.805066
            ],
            [
              -89.67438986535349,
              37.8029885408088
            ],
            [
              -89.680618,
              37.822734
            ],
            [
              -89.66454,
              37.828947
            ],
            [
              -89.656492,
              37.858594
            ],
            [
              -89.595084,
              37.95531
            ],
            [
              -89.592401,
              38.139436
            ],
            [
              -89.592797,
              38.219271
            ],
            [
              -89.703256,
              38.219404
            ],
            [
              -89.899061,
              38.220755
            ],
            [
              -90.036385,
              38.223125
            ],
            [
              -90.035999,
              38.13563
            ],
            [
              -90.2066844548659,
              38.0879691505846
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "097",
      "COUNTYNS": "01784796",
      "AFFGEOID": "0500000US17097",
      "GEOID": "17097",
      "NAME": "Lake",
      "LSAD": "06",
      "ALAND": 1149926934,
      "AWATER": 2394188156,
      "County_state": "Lake,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.198683,
              42.241671
            ],
            [
              -88.199584,
              42.15426
            ],
            [
              -88.150745,
              42.154215
            ],
            [
              -88.133659,
              42.154204
            ],
            [
              -88.121545,
              42.154193
            ],
            [
              -88.02375,
              42.153735
            ],
            [
              -88.004466,
              42.153645
            ],
            [
              -87.984994,
              42.153626
            ],
            [
              -87.946149,
              42.153173
            ],
            [
              -87.936462,
              42.153095
            ],
            [
              -87.934405,
              42.153106
            ],
            [
              -87.887268,
              42.152953
            ],
            [
              -87.848183,
              42.152811
            ],
            [
              -87.821174,
              42.152544
            ],
            [
              -87.7593265652386,
              42.1523619789799
            ],
            [
              -87.800066,
              42.208024
            ],
            [
              -87.812267,
              42.231823
            ],
            [
              -87.828569,
              42.269922
            ],
            [
              -87.834769,
              42.301522
            ],
            [
              -87.8316962204565,
              42.3249110264225
            ],
            [
              -87.830986,
              42.330317
            ],
            [
              -87.820858,
              42.361584
            ],
            [
              -87.81657,
              42.364621
            ],
            [
              -87.80537,
              42.384721
            ],
            [
              -87.8037503855864,
              42.4137930787247
            ],
            [
              -87.8035294563849,
              42.4177587578908
            ],
            [
              -87.80337,
              42.420621
            ],
            [
              -87.8009969518012,
              42.4435050843479
            ],
            [
              -87.798071,
              42.471721
            ],
            [
              -87.800477,
              42.49192
            ],
            [
              -87.815872,
              42.49192
            ],
            [
              -87.843594,
              42.492307
            ],
            [
              -87.8979977686293,
              42.4929917529839
            ],
            [
              -87.900242,
              42.49302
            ],
            [
              -87.971279,
              42.494019
            ],
            [
              -87.99018,
              42.494519
            ],
            [
              -88.049782,
              42.495319
            ],
            [
              -88.0942078450277,
              42.495929403501
            ],
            [
              -88.115285,
              42.496219
            ],
            [
              -88.1995371176412,
              42.496017518266896
            ],
            [
              -88.198601,
              42.415567
            ],
            [
              -88.19882,
              42.41557
            ],
            [
              -88.198804,
              42.396642
            ],
            [
              -88.198647,
              42.328861
            ],
            [
              -88.198993,
              42.300977
            ],
            [
              -88.198924,
              42.295655
            ],
            [
              -88.198547,
              42.282687
            ],
            [
              -88.198302,
              42.258434
            ],
            [
              -88.198314,
              42.241612
            ],
            [
              -88.198683,
              42.241671
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "115",
      "COUNTYNS": "00424256",
      "AFFGEOID": "0500000US17115",
      "GEOID": "17115",
      "NAME": "Macon",
      "LSAD": "06",
      "ALAND": 1503973504,
      "AWATER": 13409564,
      "County_state": "Macon,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.217846,
              39.91699
            ],
            [
              -89.21747,
              39.85296
            ],
            [
              -89.217523,
              39.813224
            ],
            [
              -89.18797,
              39.817815
            ],
            [
              -89.141817,
              39.80091
            ],
            [
              -89.139673,
              39.687581
            ],
            [
              -89.138951,
              39.655136
            ],
            [
              -89.025691,
              39.654637
            ],
            [
              -89.02568,
              39.654183
            ],
            [
              -88.810575,
              39.653222
            ],
            [
              -88.812709,
              39.740486
            ],
            [
              -88.75784,
              39.739907
            ],
            [
              -88.75866,
              39.792067
            ],
            [
              -88.745671,
              39.792146
            ],
            [
              -88.745164,
              40.055191
            ],
            [
              -89.144764,
              40.048853
            ],
            [
              -89.143457,
              39.91792
            ],
            [
              -89.217846,
              39.91699
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "189",
      "COUNTYNS": "01785150",
      "AFFGEOID": "0500000US17189",
      "GEOID": "17189",
      "NAME": "Washington",
      "LSAD": "06",
      "ALAND": 1457058107,
      "AWATER": 3454759,
      "County_state": "Washington,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.704417,
              38.415667
            ],
            [
              -89.703256,
              38.219404
            ],
            [
              -89.592797,
              38.219271
            ],
            [
              -89.479459,
              38.216269
            ],
            [
              -89.147416,
              38.212896
            ],
            [
              -89.145472,
              38.387436
            ],
            [
              -89.144388,
              38.473878
            ],
            [
              -89.143898,
              38.503088
            ],
            [
              -89.25859,
              38.508809
            ],
            [
              -89.297931,
              38.505709
            ],
            [
              -89.310625,
              38.511979
            ],
            [
              -89.3419,
              38.510556
            ],
            [
              -89.351494,
              38.518605
            ],
            [
              -89.366909,
              38.497999
            ],
            [
              -89.405856,
              38.495629
            ],
            [
              -89.412524,
              38.488676
            ],
            [
              -89.429087,
              38.498606
            ],
            [
              -89.455253,
              38.479729
            ],
            [
              -89.481556,
              38.468465
            ],
            [
              -89.539337,
              38.481055
            ],
            [
              -89.568353,
              38.476411
            ],
            [
              -89.577639,
              38.483159
            ],
            [
              -89.588244,
              38.475882
            ],
            [
              -89.615212,
              38.472072
            ],
            [
              -89.627675,
              38.451087
            ],
            [
              -89.64555,
              38.441739
            ],
            [
              -89.66269,
              38.445304
            ],
            [
              -89.668884,
              38.432078
            ],
            [
              -89.696735,
              38.42484
            ],
            [
              -89.704417,
              38.415667
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "033",
      "COUNTYNS": "00424218",
      "AFFGEOID": "0500000US17033",
      "GEOID": "17033",
      "NAME": "Crawford",
      "LSAD": "06",
      "ALAND": 1149004930,
      "AWATER": 5659945,
      "County_state": "Crawford,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.950385,
              39.174882
            ],
            [
              -87.948222,
              39.083828
            ],
            [
              -87.945923,
              38.850108
            ],
            [
              -87.908113,
              38.850107
            ],
            [
              -87.759592,
              38.849514
            ],
            [
              -87.684141,
              38.852179
            ],
            [
              -87.5343549340073,
              38.852494647453
            ],
            [
              -87.550515,
              38.85956
            ],
            [
              -87.553384,
              38.863344
            ],
            [
              -87.54737,
              38.875614
            ],
            [
              -87.544089,
              38.895093
            ],
            [
              -87.527645,
              38.907688
            ],
            [
              -87.518826,
              38.923205
            ],
            [
              -87.512187,
              38.954417
            ],
            [
              -87.529496,
              38.971925
            ],
            [
              -87.578319,
              38.988786
            ],
            [
              -87.579117,
              39.001607
            ],
            [
              -87.569696,
              39.019413
            ],
            [
              -87.575027,
              39.034062
            ],
            [
              -87.572588,
              39.057286
            ],
            [
              -87.596373,
              39.079639
            ],
            [
              -87.608517,
              39.082445
            ],
            [
              -87.613513,
              39.085568
            ],
            [
              -87.616636,
              39.08994
            ],
            [
              -87.61726,
              39.096186
            ],
            [
              -87.619134,
              39.100557
            ],
            [
              -87.625379,
              39.101806
            ],
            [
              -87.630376,
              39.104305
            ],
            [
              -87.632249,
              39.106803
            ],
            [
              -87.632874,
              39.11055
            ],
            [
              -87.632874,
              39.114297
            ],
            [
              -87.632245,
              39.118702
            ],
            [
              -87.643145,
              39.128562
            ],
            [
              -87.64599,
              39.1449
            ],
            [
              -87.6427769752148,
              39.157524787036294
            ],
            [
              -87.657032,
              39.157594
            ],
            [
              -87.656794,
              39.172177
            ],
            [
              -87.747113,
              39.172296
            ],
            [
              -87.746174,
              39.17922
            ],
            [
              -87.950385,
              39.174882
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "013",
      "COUNTYNS": "00424208",
      "AFFGEOID": "0500000US17013",
      "GEOID": "17013",
      "NAME": "Calhoun",
      "LSAD": "06",
      "ALAND": 657402915,
      "AWATER": 77057164,
      "County_state": "Calhoun,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.9367782192347,
              39.399486555729595
            ],
            [
              -90.935729,
              39.397331
            ],
            [
              -90.934007,
              39.392127
            ],
            [
              -90.928745,
              39.387544
            ],
            [
              -90.924601,
              39.385136
            ],
            [
              -90.920976,
              39.383687
            ],
            [
              -90.914658,
              39.381956
            ],
            [
              -90.907999,
              39.380812
            ],
            [
              -90.904862,
              39.379403
            ],
            [
              -90.902905,
              39.377534
            ],
            [
              -90.902656,
              39.375366
            ],
            [
              -90.900095,
              39.372354
            ],
            [
              -90.893777,
              39.367343
            ],
            [
              -90.859113,
              39.35137
            ],
            [
              -90.8475,
              39.345272
            ],
            [
              -90.840106,
              39.340438
            ],
            [
              -90.821306,
              39.323659
            ],
            [
              -90.816851,
              39.320496
            ],
            [
              -90.799346,
              39.313087
            ],
            [
              -90.793461,
              39.309498
            ],
            [
              -90.791689,
              39.306957
            ],
            [
              -90.790675,
              39.302908
            ],
            [
              -90.783789,
              39.297164
            ],
            [
              -90.775673,
              39.292811
            ],
            [
              -90.773887,
              39.290544
            ],
            [
              -90.767648,
              39.280025
            ],
            [
              -90.751599,
              39.265432
            ],
            [
              -90.748877,
              39.264126
            ],
            [
              -90.739087,
              39.261893
            ],
            [
              -90.733976,
              39.259098
            ],
            [
              -90.72996,
              39.255894
            ],
            [
              -90.726981,
              39.251173
            ],
            [
              -90.721593,
              39.23273
            ],
            [
              -90.721188,
              39.230176
            ],
            [
              -90.721835,
              39.224108
            ],
            [
              -90.717113,
              39.213912
            ],
            [
              -90.716597,
              39.210414
            ],
            [
              -90.716677,
              39.206723
            ],
            [
              -90.717427,
              39.202791
            ],
            [
              -90.717404,
              39.197414
            ],
            [
              -90.71437,
              39.185308
            ],
            [
              -90.71048,
              39.176366
            ],
            [
              -90.709953,
              39.172924
            ],
            [
              -90.709146,
              39.155111
            ],
            [
              -90.707902,
              39.15086
            ],
            [
              -90.705168,
              39.143152
            ],
            [
              -90.702923,
              39.138749
            ],
            [
              -90.700464,
              39.135439
            ],
            [
              -90.694945,
              39.12968
            ],
            [
              -90.6876585183957,
              39.1199349248164
            ],
            [
              -90.686051,
              39.117785
            ],
            [
              -90.684518,
              39.113567
            ],
            [
              -90.681086,
              39.10059
            ],
            [
              -90.681994,
              39.090066
            ],
            [
              -90.682744,
              39.088348
            ],
            [
              -90.700424,
              39.071787
            ],
            [
              -90.701187,
              39.070408
            ],
            [
              -90.702136,
              39.065568
            ],
            [
              -90.712541,
              39.057064
            ],
            [
              -90.713585,
              39.055567
            ],
            [
              -90.713629,
              39.053977
            ],
            [
              -90.71158,
              39.046798
            ],
            [
              -90.707885,
              39.042262
            ],
            [
              -90.700595,
              39.029074
            ],
            [
              -90.692403,
              39.016656
            ],
            [
              -90.69,
              39.012169
            ],
            [
              -90.688813,
              39.007342
            ],
            [
              -90.687719,
              39.005374
            ],
            [
              -90.684978937801,
              39.001758828739
            ],
            [
              -90.683683,
              39.000049
            ],
            [
              -90.682068,
              38.998778
            ],
            [
              -90.678193,
              38.991851
            ],
            [
              -90.676397,
              38.984096
            ],
            [
              -90.676417,
              38.965812
            ],
            [
              -90.675949,
              38.96214
            ],
            [
              -90.671844,
              38.952296
            ],
            [
              -90.669229,
              38.948176
            ],
            [
              -90.6655700805267,
              38.934198408333195
            ],
            [
              -90.665565,
              38.934179
            ],
            [
              -90.663372,
              38.928042
            ],
            [
              -90.6614,
              38.924989
            ],
            [
              -90.657254,
              38.92027
            ],
            [
              -90.653164,
              38.916141
            ],
            [
              -90.647988,
              38.912106
            ],
            [
              -90.639917,
              38.908272
            ],
            [
              -90.635896,
              38.903941
            ],
            [
              -90.628485,
              38.891617
            ],
            [
              -90.625122,
              38.888654
            ],
            [
              -90.595354,
              38.87505
            ],
            [
              -90.583388,
              38.86903
            ],
            [
              -90.576719,
              38.868336
            ],
            [
              -90.566557,
              38.868847
            ],
            [
              -90.5646273539146,
              38.8691912244213
            ],
            [
              -90.555693,
              38.870785
            ],
            [
              -90.545872,
              38.874008
            ],
            [
              -90.54403,
              38.87505
            ],
            [
              -90.531118,
              38.887078
            ],
            [
              -90.516963,
              38.898818
            ],
            [
              -90.507451,
              38.902767
            ],
            [
              -90.500117,
              38.910408
            ],
            [
              -90.486974,
              38.925982
            ],
            [
              -90.482725,
              38.934712
            ],
            [
              -90.483452,
              38.940436
            ],
            [
              -90.483339,
              38.942133
            ],
            [
              -90.482419,
              38.94446
            ],
            [
              -90.472122,
              38.958838
            ],
            [
              -90.467784,
              38.961809
            ],
            [
              -90.462411,
              38.964322
            ],
            [
              -90.450809944078,
              38.967758684265696
            ],
            [
              -90.491802,
              38.966614
            ],
            [
              -90.519644,
              38.953943
            ],
            [
              -90.534657,
              38.958499
            ],
            [
              -90.570169,
              38.99329
            ],
            [
              -90.576101,
              39.009383
            ],
            [
              -90.57681,
              39.035938
            ],
            [
              -90.588094,
              39.045747
            ],
            [
              -90.581406,
              39.06568
            ],
            [
              -90.58927,
              39.076036
            ],
            [
              -90.603569,
              39.117592
            ],
            [
              -90.61454,
              39.14229
            ],
            [
              -90.613326,
              39.162742
            ],
            [
              -90.59123,
              39.198624
            ],
            [
              -90.597989,
              39.24333
            ],
            [
              -90.609796,
              39.259812
            ],
            [
              -90.60243,
              39.292693
            ],
            [
              -90.611258,
              39.311213
            ],
            [
              -90.62229,
              39.365089
            ],
            [
              -90.613694,
              39.395775
            ],
            [
              -90.908735,
              39.400208
            ],
            [
              -90.9367782192347,
              39.399486555729595
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "113",
      "COUNTYNS": "01784833",
      "AFFGEOID": "0500000US17113",
      "GEOID": "17113",
      "NAME": "McLean",
      "LSAD": "06",
      "ALAND": 3064581023,
      "AWATER": 7842778,
      "County_state": "McLean,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.26939,
              40.594329
            ],
            [
              -89.266499,
              40.485552
            ],
            [
              -89.26374,
              40.325344
            ],
            [
              -89.26265,
              40.280919
            ],
            [
              -89.148772,
              40.28252
            ],
            [
              -89.148764,
              40.282038
            ],
            [
              -89.052067,
              40.283159
            ],
            [
              -89.034711,
              40.282797
            ],
            [
              -88.919544,
              40.282865
            ],
            [
              -88.910853,
              40.282436
            ],
            [
              -88.574885,
              40.281501
            ],
            [
              -88.460418,
              40.281935
            ],
            [
              -88.459957,
              40.39885
            ],
            [
              -88.459227,
              40.486243
            ],
            [
              -88.459475,
              40.617345
            ],
            [
              -88.574502,
              40.61655
            ],
            [
              -88.575216,
              40.670616
            ],
            [
              -88.58215,
              40.670604
            ],
            [
              -88.584272,
              40.757608
            ],
            [
              -88.929331,
              40.753337
            ],
            [
              -88.986896,
              40.752297
            ],
            [
              -88.9847,
              40.664954
            ],
            [
              -89.04532,
              40.663939
            ],
            [
              -89.044368,
              40.627428
            ],
            [
              -89.101569,
              40.625036
            ],
            [
              -89.101134,
              40.612326
            ],
            [
              -89.134217,
              40.61149
            ],
            [
              -89.133752,
              40.596734
            ],
            [
              -89.155175,
              40.596417
            ],
            [
              -89.26939,
              40.594329
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "019",
      "COUNTYNS": "00424211",
      "AFFGEOID": "0500000US17019",
      "GEOID": "17019",
      "NAME": "Champaign",
      "LSAD": "06",
      "ALAND": 2579733580,
      "AWATER": 6091992,
      "County_state": "Champaign,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.46366,
              40.223352
            ],
            [
              -88.463174,
              40.142196
            ],
            [
              -88.462207,
              39.879092
            ],
            [
              -88.349187,
              39.879451
            ],
            [
              -87.937645,
              39.879803
            ],
            [
              -87.939949,
              40.083635
            ],
            [
              -87.940337,
              40.136681
            ],
            [
              -87.940933,
              40.141905
            ],
            [
              -87.942103,
              40.225482
            ],
            [
              -87.92876,
              40.225619
            ],
            [
              -87.929442,
              40.269693
            ],
            [
              -87.932858,
              40.399401
            ],
            [
              -88.117411,
              40.40061
            ],
            [
              -88.346987,
              40.398651
            ],
            [
              -88.459957,
              40.39885
            ],
            [
              -88.460418,
              40.281935
            ],
            [
              -88.46366,
              40.223352
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "027",
      "COUNTYNS": "00424215",
      "AFFGEOID": "0500000US17027",
      "GEOID": "17027",
      "NAME": "Clinton",
      "LSAD": "06",
      "ALAND": 1227823898,
      "AWATER": 75642557,
      "County_state": "Clinton,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.707024,
              38.655152
            ],
            [
              -89.704417,
              38.415667
            ],
            [
              -89.696735,
              38.42484
            ],
            [
              -89.668884,
              38.432078
            ],
            [
              -89.66269,
              38.445304
            ],
            [
              -89.64555,
              38.441739
            ],
            [
              -89.627675,
              38.451087
            ],
            [
              -89.615212,
              38.472072
            ],
            [
              -89.588244,
              38.475882
            ],
            [
              -89.577639,
              38.483159
            ],
            [
              -89.568353,
              38.476411
            ],
            [
              -89.539337,
              38.481055
            ],
            [
              -89.481556,
              38.468465
            ],
            [
              -89.455253,
              38.479729
            ],
            [
              -89.429087,
              38.498606
            ],
            [
              -89.412524,
              38.488676
            ],
            [
              -89.405856,
              38.495629
            ],
            [
              -89.366909,
              38.497999
            ],
            [
              -89.351494,
              38.518605
            ],
            [
              -89.3419,
              38.510556
            ],
            [
              -89.310625,
              38.511979
            ],
            [
              -89.297931,
              38.505709
            ],
            [
              -89.25859,
              38.508809
            ],
            [
              -89.143898,
              38.503088
            ],
            [
              -89.139589,
              38.648838
            ],
            [
              -89.138393,
              38.736331
            ],
            [
              -89.254237,
              38.742019
            ],
            [
              -89.500076,
              38.740752
            ],
            [
              -89.597321,
              38.743236
            ],
            [
              -89.595388,
              38.666865
            ],
            [
              -89.595103,
              38.655948
            ],
            [
              -89.707024,
              38.655152
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "067",
      "COUNTYNS": "00424235",
      "AFFGEOID": "0500000US17067",
      "GEOID": "17067",
      "NAME": "Hancock",
      "LSAD": "06",
      "ALAND": 2055740566,
      "AWATER": 53535827,
      "County_state": "Hancock,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.506947,
              40.21555
            ],
            [
              -91.507269,
              40.209338
            ],
            [
              -91.506664,
              40.204758
            ],
            [
              -91.50522531361149,
              40.200484700146596
            ],
            [
              -91.499121,
              40.200056
            ],
            [
              -90.911969,
              40.193088
            ],
            [
              -90.909756,
              40.284394
            ],
            [
              -90.904216,
              40.639201
            ],
            [
              -91.02263,
              40.634946
            ],
            [
              -91.185428463588,
              40.6380706559482
            ],
            [
              -91.18698,
              40.637297
            ],
            [
              -91.197906,
              40.636107
            ],
            [
              -91.218437,
              40.638437
            ],
            [
              -91.247851,
              40.63839
            ],
            [
              -91.253074,
              40.637962
            ],
            [
              -91.258249,
              40.636672
            ],
            [
              -91.264953,
              40.633893
            ],
            [
              -91.276175,
              40.63224
            ],
            [
              -91.306524,
              40.626231
            ],
            [
              -91.339719,
              40.613488
            ],
            [
              -91.348733,
              40.609695
            ],
            [
              -91.353989,
              40.606553
            ],
            [
              -91.359873,
              40.601805
            ],
            [
              -91.374252,
              40.58259
            ],
            [
              -91.379752,
              40.57445
            ],
            [
              -91.401482,
              40.559458
            ],
            [
              -91.405241,
              40.554641
            ],
            [
              -91.406373,
              40.551831
            ],
            [
              -91.406851,
              40.547557
            ],
            [
              -91.406202,
              40.542698
            ],
            [
              -91.404125,
              40.539127
            ],
            [
              -91.400725,
              40.536789
            ],
            [
              -91.394475,
              40.534543
            ],
            [
              -91.388067,
              40.533069
            ],
            [
              -91.384531,
              40.530948
            ],
            [
              -91.381857,
              40.528247
            ],
            [
              -91.369059,
              40.512532
            ],
            [
              -91.367876,
              40.510479
            ],
            [
              -91.364211,
              40.500043
            ],
            [
              -91.363683,
              40.494211
            ],
            [
              -91.36391,
              40.490122
            ],
            [
              -91.364915,
              40.484168
            ],
            [
              -91.368074,
              40.474642
            ],
            [
              -91.378144,
              40.456394
            ],
            [
              -91.379907,
              40.45211
            ],
            [
              -91.381468,
              40.44604
            ],
            [
              -91.381769,
              40.442555
            ],
            [
              -91.380965,
              40.435395
            ],
            [
              -91.377625,
              40.426335
            ],
            [
              -91.373721,
              40.417891
            ],
            [
              -91.37245,
              40.411475
            ],
            [
              -91.372554,
              40.4012
            ],
            [
              -91.372921,
              40.399108
            ],
            [
              -91.375746,
              40.391879
            ],
            [
              -91.378422,
              40.38967
            ],
            [
              -91.381958,
              40.387632
            ],
            [
              -91.38836,
              40.384929
            ],
            [
              -91.396996,
              40.383127
            ],
            [
              -91.413011,
              40.382277
            ],
            [
              -91.415695,
              40.381381
            ],
            [
              -91.419422,
              40.378264
            ],
            [
              -91.426632,
              40.371988
            ],
            [
              -91.429442,
              40.370386
            ],
            [
              -91.439342,
              40.366569
            ],
            [
              -91.444833,
              40.36317
            ],
            [
              -91.446308,
              40.361823
            ],
            [
              -91.447835,
              40.359129
            ],
            [
              -91.452237,
              40.35367
            ],
            [
              -91.46214,
              40.342414
            ],
            [
              -91.466603,
              40.334461
            ],
            [
              -91.469656,
              40.322409
            ],
            [
              -91.471826,
              40.31734
            ],
            [
              -91.486078,
              40.293426
            ],
            [
              -91.489868,
              40.286048
            ],
            [
              -91.492727,
              40.278217
            ],
            [
              -91.493061,
              40.275262
            ],
            [
              -91.492891,
              40.269923
            ],
            [
              -91.490525,
              40.264814
            ],
            [
              -91.489969,
              40.26234
            ],
            [
              -91.490524,
              40.259498
            ],
            [
              -91.4972633213575,
              40.248761318639396
            ],
            [
              -91.498104,
              40.247422
            ],
            [
              -91.500855,
              40.245722
            ],
            [
              -91.503231,
              40.243474
            ],
            [
              -91.505828,
              40.238839
            ],
            [
              -91.506501,
              40.236304
            ],
            [
              -91.505968,
              40.234305
            ],
            [
              -91.504289,
              40.231712
            ],
            [
              -91.504282,
              40.224299
            ],
            [
              -91.506947,
              40.21555
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "195",
      "COUNTYNS": "01785167",
      "AFFGEOID": "0500000US17195",
      "GEOID": "17195",
      "NAME": "Whiteside",
      "LSAD": "06",
      "ALAND": 1772197821,
      "AWATER": 31797486,
      "County_state": "Whiteside,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.236773,
              41.762598
            ],
            [
              -90.247917,
              41.746877
            ],
            [
              -90.234705,
              41.736111
            ],
            [
              -90.244956,
              41.702379
            ],
            [
              -90.22679,
              41.692521
            ],
            [
              -90.232703,
              41.676573
            ],
            [
              -90.189532,
              41.663374
            ],
            [
              -90.161233,
              41.643927
            ],
            [
              -90.173913,
              41.640069
            ],
            [
              -90.162465,
              41.635526
            ],
            [
              -90.164842,
              41.597322
            ],
            [
              -90.185609,
              41.584653
            ],
            [
              -89.862351,
              41.584005
            ],
            [
              -89.631494,
              41.584949
            ],
            [
              -89.631467,
              41.758134
            ],
            [
              -89.630263,
              41.843623
            ],
            [
              -89.62933,
              41.901617
            ],
            [
              -89.628907,
              41.930044
            ],
            [
              -89.685366,
              41.93034
            ],
            [
              -89.978437,
              41.931187
            ],
            [
              -89.987718,
              41.931009
            ],
            [
              -90.017534,
              41.930442
            ],
            [
              -90.063551,
              41.930106
            ],
            [
              -90.1518383787674,
              41.928917335739996
            ],
            [
              -90.153362,
              41.915593
            ],
            [
              -90.153584,
              41.906614
            ],
            [
              -90.157019,
              41.898019
            ],
            [
              -90.165065,
              41.883777
            ],
            [
              -90.170041,
              41.876439
            ],
            [
              -90.172765,
              41.866149
            ],
            [
              -90.173006,
              41.857402
            ],
            [
              -90.175051,
              41.853629
            ],
            [
              -90.181401,
              41.844647
            ],
            [
              -90.181901,
              41.843216
            ],
            [
              -90.183765,
              41.83624
            ],
            [
              -90.183973,
              41.83307
            ],
            [
              -90.18172,
              41.822599
            ],
            [
              -90.180643,
              41.811979
            ],
            [
              -90.180954,
              41.809354
            ],
            [
              -90.181973,
              41.80707
            ],
            [
              -90.187969,
              41.803163
            ],
            [
              -90.216889,
              41.795335
            ],
            [
              -90.222263,
              41.793133
            ],
            [
              -90.2423797480977,
              41.7829647650696
            ],
            [
              -90.2423802567555,
              41.7829645079628
            ],
            [
              -90.24717,
              41.762609
            ],
            [
              -90.236773,
              41.762598
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "193",
      "COUNTYNS": "00424297",
      "AFFGEOID": "0500000US17193",
      "GEOID": "17193",
      "NAME": "White",
      "LSAD": "06",
      "ALAND": 1281447206,
      "AWATER": 18366965,
      "County_state": "White,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.370491,
              38.255413
            ],
            [
              -88.37452,
              37.908432
            ],
            [
              -88.357462,
              37.909081
            ],
            [
              -88.135985,
              37.907794
            ],
            [
              -88.136053,
              37.916023
            ],
            [
              -88.09202942327529,
              37.891093081727895
            ],
            [
              -88.094956,
              37.893689
            ],
            [
              -88.097892,
              37.901517
            ],
            [
              -88.095662,
              37.905808
            ],
            [
              -88.086237,
              37.905713
            ],
            [
              -88.071449,
              37.895813
            ],
            [
              -88.055373,
              37.891238
            ],
            [
              -88.040861,
              37.891767
            ],
            [
              -88.026569,
              37.891024
            ],
            [
              -88.018693,
              37.888403
            ],
            [
              -88.013355,
              37.894854
            ],
            [
              -88.02006,
              37.9129
            ],
            [
              -88.030199,
              37.914346
            ],
            [
              -88.06519,
              37.919684
            ],
            [
              -88.068948,
              37.928565
            ],
            [
              -88.059902,
              37.933831
            ],
            [
              -88.054122,
              37.933029
            ],
            [
              -88.036124,
              37.942746
            ],
            [
              -88.036688,
              37.956872
            ],
            [
              -88.031989,
              37.95859
            ],
            [
              -88.028218,
              37.958409
            ],
            [
              -88.020868,
              37.959351
            ],
            [
              -88.016311,
              37.961574
            ],
            [
              -88.012929,
              37.966544
            ],
            [
              -88.012574,
              37.977062
            ],
            [
              -88.021879,
              37.995959
            ],
            [
              -88.023331,
              38.015445
            ],
            [
              -88.007631,
              38.029145
            ],
            [
              -88.017631,
              38.034745
            ],
            [
              -88.030884,
              38.030713
            ],
            [
              -88.041845,
              38.038181
            ],
            [
              -88.035332,
              38.051145
            ],
            [
              -88.00133,
              38.051545
            ],
            [
              -87.98877,
              38.055591
            ],
            [
              -87.967226,
              38.067105
            ],
            [
              -87.96126,
              38.078295
            ],
            [
              -87.956878,
              38.088284
            ],
            [
              -87.96221,
              38.100054
            ],
            [
              -88.004697,
              38.083965
            ],
            [
              -88.012131,
              38.085275
            ],
            [
              -88.016729,
              38.097257
            ],
            [
              -88.013118,
              38.103527
            ],
            [
              -87.99831,
              38.108488
            ],
            [
              -87.986958,
              38.10715
            ],
            [
              -87.979926,
              38.10802
            ],
            [
              -87.974219,
              38.11138
            ],
            [
              -87.974445,
              38.120836
            ],
            [
              -87.970793,
              38.128919
            ],
            [
              -87.967349,
              38.132139
            ],
            [
              -87.960482,
              38.132095
            ],
            [
              -87.955872,
              38.132897
            ],
            [
              -87.945472,
              38.126616
            ],
            [
              -87.935135,
              38.139428
            ],
            [
              -87.92783,
              38.141545
            ],
            [
              -87.9255,
              38.146251
            ],
            [
              -87.927468,
              38.151946
            ],
            [
              -87.933768,
              38.156317
            ],
            [
              -87.933759,
              38.164351
            ],
            [
              -87.937162,
              38.172189
            ],
            [
              -87.9595,
              38.184376
            ],
            [
              -87.975819,
              38.197834
            ],
            [
              -87.984234,
              38.20996
            ],
            [
              -87.984929,
              38.22826
            ],
            [
              -87.98526853288558,
              38.2300649614472
            ],
            [
              -87.987277,
              38.240742
            ],
            [
              -87.988794,
              38.248739
            ],
            [
              -87.988037,
              38.255486
            ],
            [
              -87.98749671787239,
              38.256784643872095
            ],
            [
              -87.9906,
              38.259715
            ],
            [
              -88.027749,
              38.255661
            ],
            [
              -88.1509,
              38.256097
            ],
            [
              -88.370491,
              38.255413
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "167",
      "COUNTYNS": "01785010",
      "AFFGEOID": "0500000US17167",
      "GEOID": "17167",
      "NAME": "Sangamon",
      "LSAD": "06",
      "ALAND": 2248891495,
      "AWATER": 22577723,
      "County_state": "Sangamon,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.994506,
              39.901925
            ],
            [
              -89.994405,
              39.87286
            ],
            [
              -89.993755,
              39.785329
            ],
            [
              -89.984745,
              39.718079
            ],
            [
              -89.970848,
              39.681658
            ],
            [
              -89.923955,
              39.558678
            ],
            [
              -89.926037,
              39.522104
            ],
            [
              -89.813147,
              39.522838
            ],
            [
              -89.701645,
              39.523369
            ],
            [
              -89.533655,
              39.524592
            ],
            [
              -89.533811,
              39.539114
            ],
            [
              -89.534998,
              39.612226
            ],
            [
              -89.535028,
              39.641138
            ],
            [
              -89.478473,
              39.640842
            ],
            [
              -89.478923,
              39.683964
            ],
            [
              -89.425051,
              39.683696
            ],
            [
              -89.425535,
              39.747676
            ],
            [
              -89.425171,
              39.762022
            ],
            [
              -89.409475,
              39.74353
            ],
            [
              -89.397461,
              39.742095
            ],
            [
              -89.373718,
              39.758707
            ],
            [
              -89.362605,
              39.755799
            ],
            [
              -89.353958,
              39.772107
            ],
            [
              -89.329152,
              39.768804
            ],
            [
              -89.303122,
              39.775813
            ],
            [
              -89.296367,
              39.789879
            ],
            [
              -89.260966,
              39.805093
            ],
            [
              -89.246532,
              39.826085
            ],
            [
              -89.233523,
              39.813159
            ],
            [
              -89.217523,
              39.813224
            ],
            [
              -89.21747,
              39.85296
            ],
            [
              -89.217846,
              39.91699
            ],
            [
              -89.404984,
              39.918187
            ],
            [
              -89.404969,
              39.932733
            ],
            [
              -89.483419,
              39.93314
            ],
            [
              -89.483826,
              39.976733
            ],
            [
              -89.578289,
              39.976127
            ],
            [
              -89.698259,
              39.975309
            ],
            [
              -89.701864,
              39.916787
            ],
            [
              -89.769325,
              39.916344
            ],
            [
              -89.769236,
              39.902416
            ],
            [
              -89.994506,
              39.901925
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "139",
      "COUNTYNS": "01784885",
      "AFFGEOID": "0500000US17139",
      "GEOID": "17139",
      "NAME": "Moultrie",
      "LSAD": "06",
      "ALAND": 870088233,
      "AWATER": 22103176,
      "County_state": "Moultrie,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.812709,
              39.740486
            ],
            [
              -88.810575,
              39.653222
            ],
            [
              -88.809051,
              39.580241
            ],
            [
              -88.717539,
              39.579299
            ],
            [
              -88.716875,
              39.521209
            ],
            [
              -88.641468,
              39.520958
            ],
            [
              -88.641626,
              39.506689
            ],
            [
              -88.62239,
              39.506496
            ],
            [
              -88.622316,
              39.491786
            ],
            [
              -88.603409,
              39.491562
            ],
            [
              -88.603126,
              39.477014
            ],
            [
              -88.584518,
              39.476791
            ],
            [
              -88.584273,
              39.447582
            ],
            [
              -88.470505,
              39.447041
            ],
            [
              -88.472073,
              39.651588
            ],
            [
              -88.473182,
              39.791837
            ],
            [
              -88.745671,
              39.792146
            ],
            [
              -88.75866,
              39.792067
            ],
            [
              -88.75784,
              39.739907
            ],
            [
              -88.812709,
              39.740486
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "087",
      "COUNTYNS": "00424245",
      "AFFGEOID": "0500000US17087",
      "GEOID": "17087",
      "NAME": "Johnson",
      "LSAD": "06",
      "ALAND": 890741064,
      "AWATER": 12811451,
      "County_state": "Johnson,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.044787,
              37.329846
            ],
            [
              -89.044588,
              37.294163
            ],
            [
              -89.003541,
              37.311242
            ],
            [
              -88.963578,
              37.29738
            ],
            [
              -88.929129,
              37.302647
            ],
            [
              -88.902441,
              37.316503
            ],
            [
              -88.908291,
              37.335727
            ],
            [
              -88.728407,
              37.335192
            ],
            [
              -88.71065,
              37.33709
            ],
            [
              -88.708546,
              37.599277
            ],
            [
              -88.818894,
              37.600677
            ],
            [
              -88.836759,
              37.597508
            ],
            [
              -89.041401,
              37.596576
            ],
            [
              -89.044787,
              37.329846
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "143",
      "COUNTYNS": "01784920",
      "AFFGEOID": "0500000US17143",
      "GEOID": "17143",
      "NAME": "Peoria",
      "LSAD": "06",
      "ALAND": 1602484321,
      "AWATER": 30706228,
      "County_state": "Peoria,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.986068,
              40.712257
            ],
            [
              -89.989018,
              40.625835
            ],
            [
              -89.87351,
              40.624482
            ],
            [
              -89.872463,
              40.513127
            ],
            [
              -89.812459,
              40.541104
            ],
            [
              -89.770412,
              40.553149
            ],
            [
              -89.73905,
              40.551142
            ],
            [
              -89.691848,
              40.560518
            ],
            [
              -89.675404,
              40.553938
            ],
            [
              -89.658003,
              40.567438
            ],
            [
              -89.654294,
              40.579782
            ],
            [
              -89.658502,
              40.601558
            ],
            [
              -89.636401,
              40.623238
            ],
            [
              -89.610339,
              40.65199
            ],
            [
              -89.615399,
              40.664437
            ],
            [
              -89.592157,
              40.685015
            ],
            [
              -89.557996,
              40.700737
            ],
            [
              -89.554879,
              40.703553
            ],
            [
              -89.553374,
              40.706503
            ],
            [
              -89.546488,
              40.720205
            ],
            [
              -89.554994,
              40.747637
            ],
            [
              -89.555994,
              40.760437
            ],
            [
              -89.562394,
              40.793637
            ],
            [
              -89.528285,
              40.835621
            ],
            [
              -89.524973,
              40.849236
            ],
            [
              -89.499571,
              40.875036
            ],
            [
              -89.47233,
              40.921206
            ],
            [
              -89.455306,
              40.935507
            ],
            [
              -89.447693,
              40.973246
            ],
            [
              -89.638727,
              40.973707
            ],
            [
              -89.985479,
              40.974495
            ],
            [
              -89.986068,
              40.712257
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "077",
      "COUNTYNS": "00424240",
      "AFFGEOID": "0500000US17077",
      "GEOID": "17077",
      "NAME": "Jackson",
      "LSAD": "06",
      "ALAND": 1512739207,
      "AWATER": 47416095,
      "County_state": "Jackson,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.680618,
              37.822734
            ],
            [
              -89.67438986535349,
              37.8029885408088
            ],
            [
              -89.669644,
              37.799922
            ],
            [
              -89.663982,
              37.790801
            ],
            [
              -89.66132,
              37.788204
            ],
            [
              -89.66038,
              37.786296
            ],
            [
              -89.660227,
              37.781032
            ],
            [
              -89.66119,
              37.775732
            ],
            [
              -89.66413,
              37.76851
            ],
            [
              -89.666474,
              37.764195
            ],
            [
              -89.667993,
              37.759484
            ],
            [
              -89.6660352292041,
              37.753572284262
            ],
            [
              -89.665546,
              37.752095
            ],
            [
              -89.663352,
              37.750052
            ],
            [
              -89.658455,
              37.74771
            ],
            [
              -89.64953,
              37.745498
            ],
            [
              -89.645429,
              37.746108
            ],
            [
              -89.63337,
              37.745782
            ],
            [
              -89.62801,
              37.748135
            ],
            [
              -89.624023,
              37.74912
            ],
            [
              -89.617278,
              37.74972
            ],
            [
              -89.616389,
              37.749167
            ],
            [
              -89.615933,
              37.748184
            ],
            [
              -89.616194,
              37.744283
            ],
            [
              -89.615586,
              37.74235
            ],
            [
              -89.612478,
              37.740036
            ],
            [
              -89.608757,
              37.739684
            ],
            [
              -89.602406,
              37.736492
            ],
            [
              -89.596566,
              37.732886
            ],
            [
              -89.591289,
              37.723599
            ],
            [
              -89.587213,
              37.71751
            ],
            [
              -89.583316,
              37.713261
            ],
            [
              -89.573516,
              37.709065
            ],
            [
              -89.566704,
              37.707189
            ],
            [
              -89.538652,
              37.701054
            ],
            [
              -89.531427,
              37.700334
            ],
            [
              -89.52573,
              37.698441
            ],
            [
              -89.521948,
              37.696475
            ],
            [
              -89.516685,
              37.692762
            ],
            [
              -89.514255,
              37.689923
            ],
            [
              -89.512009,
              37.685525
            ],
            [
              -89.51204,
              37.680985
            ],
            [
              -89.513927,
              37.676575
            ],
            [
              -89.516146,
              37.667975
            ],
            [
              -89.516827,
              37.656089
            ],
            [
              -89.515903,
              37.650803
            ],
            [
              -89.51586,
              37.645555
            ],
            [
              -89.517136,
              37.643789
            ],
            [
              -89.517718,
              37.641217
            ],
            [
              -89.515649,
              37.636446
            ],
            [
              -89.510526,
              37.631755
            ],
            [
              -89.506563,
              37.62505
            ],
            [
              -89.485792,
              37.607157
            ],
            [
              -89.478399,
              37.598869
            ],
            [
              -89.476514,
              37.595554
            ],
            [
              -89.475932,
              37.592998
            ],
            [
              -89.47603,
              37.590226
            ],
            [
              -89.477548,
              37.585885
            ],
            [
              -89.481118,
              37.582973
            ],
            [
              -89.486062,
              37.580853
            ],
            [
              -89.494051,
              37.580116
            ],
            [
              -89.509542,
              37.584147
            ],
            [
              -89.513943,
              37.584815
            ],
            [
              -89.516538,
              37.584504
            ],
            [
              -89.519808,
              37.582748
            ],
            [
              -89.520804,
              37.581155
            ],
            [
              -89.521274,
              37.578971
            ],
            [
              -89.5215174256744,
              37.57215208971179
            ],
            [
              -89.52156646342159,
              37.5707784301758
            ],
            [
              -89.472848,
              37.561925
            ],
            [
              -89.453087,
              37.580113
            ],
            [
              -89.450594,
              37.600022
            ],
            [
              -89.232598,
              37.5985
            ],
            [
              -89.153668,
              37.600469
            ],
            [
              -89.152055,
              37.737379
            ],
            [
              -89.151176,
              37.861999
            ],
            [
              -89.150834,
              37.950196
            ],
            [
              -89.177597,
              37.950311
            ],
            [
              -89.23774,
              37.950498
            ],
            [
              -89.595084,
              37.95531
            ],
            [
              -89.656492,
              37.858594
            ],
            [
              -89.66454,
              37.828947
            ],
            [
              -89.680618,
              37.822734
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "029",
      "COUNTYNS": "00424216",
      "AFFGEOID": "0500000US17029",
      "GEOID": "17029",
      "NAME": "Coles",
      "LSAD": "06",
      "ALAND": 1316459192,
      "AWATER": 4631825,
      "County_state": "Coles,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.472073,
              39.651588
            ],
            [
              -88.470505,
              39.447041
            ],
            [
              -88.47083,
              39.374515
            ],
            [
              -88.026521,
              39.377627
            ],
            [
              -88.021796,
              39.3787
            ],
            [
              -88.012121,
              39.378968
            ],
            [
              -88.014205,
              39.480762
            ],
            [
              -87.960179,
              39.481309
            ],
            [
              -87.965698,
              39.642338
            ],
            [
              -87.9666,
              39.685928
            ],
            [
              -88.026224,
              39.684867
            ],
            [
              -88.063467,
              39.681313
            ],
            [
              -88.063437,
              39.652555
            ],
            [
              -88.472073,
              39.651588
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "145",
      "COUNTYNS": "01784940",
      "AFFGEOID": "0500000US17145",
      "GEOID": "17145",
      "NAME": "Perry",
      "LSAD": "06",
      "ALAND": 1144186125,
      "AWATER": 13416186,
      "County_state": "Perry,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.592401,
              38.139436
            ],
            [
              -89.595084,
              37.95531
            ],
            [
              -89.23774,
              37.950498
            ],
            [
              -89.177597,
              37.950311
            ],
            [
              -89.164814,
              37.95506
            ],
            [
              -89.145778,
              37.985725
            ],
            [
              -89.145253,
              38.008768
            ],
            [
              -89.136072,
              38.01803
            ],
            [
              -89.1403,
              38.033089
            ],
            [
              -89.132037,
              38.052492
            ],
            [
              -89.119654,
              38.0593
            ],
            [
              -89.115414,
              38.085954
            ],
            [
              -89.11793,
              38.09631
            ],
            [
              -89.13744,
              38.104332
            ],
            [
              -89.131662,
              38.103365
            ],
            [
              -89.129637,
              38.124747
            ],
            [
              -89.149739,
              38.124945
            ],
            [
              -89.147416,
              38.212896
            ],
            [
              -89.479459,
              38.216269
            ],
            [
              -89.592797,
              38.219271
            ],
            [
              -89.592401,
              38.139436
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "17",
      "COUNTYFP": "043",
      "COUNTYNS": "00422191",
      "AFFGEOID": "0500000US17043",
      "GEOID": "17043",
      "NAME": "DuPage",
      "LSAD": "06",
      "ALAND": 848222200,
      "AWATER": 22887251,
      "County_state": "DuPage,17"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.262876,
              41.986227
            ],
            [
              -88.26241,
              41.899234
            ],
            [
              -88.262492,
              41.882886
            ],
            [
              -88.26279,
              41.812301
            ],
            [
              -88.26279,
              41.812132
            ],
            [
              -88.26237,
              41.77208
            ],
            [
              -88.26237,
              41.771272
            ],
            [
              -88.261952,
              41.724652
            ],
            [
              -88.261273,
              41.724534
            ],
            [
              -88.206298,
              41.725251
            ],
            [
              -88.206038,
              41.725255
            ],
            [
              -88.20011,
              41.725326
            ],
            [
              -88.148194,
              41.726117
            ],
            [
              -88.117293,
              41.726848
            ],
            [
              -88.063367,
              41.728147
            ],
            [
              -88.030352,
              41.728894
            ],
            [
              -88.029586,
              41.705045
            ],
            [
              -88.028985,
              41.685518
            ],
            [
              -88.004941,
              41.685652
            ],
            [
              -87.982659,
              41.686231
            ],
            [
              -87.978956,
              41.687432
            ],
            [
              -87.966758,
              41.68683
            ],
            [
              -87.955558,
              41.693631
            ],
            [
              -87.949758,
              41.695231
            ],
            [
              -87.942462,
              41.69918
            ],
            [
              -87.94138,
              41.703324
            ],
            [
              -87.921321,
              41.716293
            ],
            [
              -87.914265,
              41.716746
            ],
            [
              -87.914552,
              41.720169
            ],
            [
              -87.916322,
              41.771515
            ],
            [
              -87.916677,
              41.780625
            ],
            [
              -87.916716,
              41.781616
            ],
            [
              -87.917089,
              41.791233
            ],
            [
              -87.917304,
              41.796397
            ],
            [
              -87.918382,
              41.81873
            ],
            [
              -87.920082,
              41.862199
            ],
            [
              -87.920445,
              41.906841
            ],
            [
              -87.920288,
              41.9313
            ],
            [
              -87.920251,
              41.9416
            ],
            [
              -87.92067,
              41.994031
            ],
            [
              -87.939946,
              41.993481
            ],
            [
              -87.970636,
              41.992997
            ],
            [
              -88.023466,
              41.992488
            ],
            [
              -88.03131,
              41.992526
            ],
            [
              -88.069933,
              41.991045
            ],
            [
              -88.129192,
              41.988865
            ],
            [
              -88.159879,
              41.987912
            ],
            [
              -88.15997,
              41.987909
            ],
            [
              -88.174096,
              41.987699
            ],
            [
              -88.184215,
              41.987599
            ],
            [
              -88.207389,
              41.987127
            ],
            [
              -88.21078,
              41.987023
            ],
            [
              -88.216763,
              41.987112
            ],
            [
              -88.253302,
              41.986377
            ],
            [
              -88.262876,
              41.986227
            ]
          ]
        ]
      ]
    }
  }
]
};