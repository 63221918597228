export const counties_fl = {
  "type":"FeatureCollection","name":"fl_counties","features":[
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "091",
      "COUNTYNS": "00306915",
      "AFFGEOID": "0500000US12091",
      "GEOID": "12091",
      "NAME": "Okaloosa",
      "LSAD": "06",
      "ALAND": 2409367483,
      "AWATER": 393201898,
      "County_state": "Okaloosa,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.792429,
              30.685144
            ],
            [
              -86.7986648610927,
              30.3866539097768
            ],
            [
              -86.7654551209078,
              30.3902886343968
            ],
            [
              -86.750906,
              30.391881
            ],
            [
              -86.632953,
              30.396299
            ],
            [
              -86.529067,
              30.386896
            ],
            [
              -86.50615,
              30.3823
            ],
            [
              -86.470849,
              30.3839
            ],
            [
              -86.412076,
              30.380346
            ],
            [
              -86.39732470104829,
              30.3785530924929
            ],
            [
              -86.391611,
              30.729726
            ],
            [
              -86.38864587273319,
              30.994164394815197
            ],
            [
              -86.388713,
              30.994163
            ],
            [
              -86.392003,
              30.994154
            ],
            [
              -86.404977,
              30.994031
            ],
            [
              -86.454767,
              30.993772
            ],
            [
              -86.458382,
              30.993979
            ],
            [
              -86.500011,
              30.99332
            ],
            [
              -86.512894,
              30.99368
            ],
            [
              -86.519998,
              30.993225
            ],
            [
              -86.563494,
              30.995202
            ],
            [
              -86.567644,
              30.995088
            ],
            [
              -86.664734,
              30.994512
            ],
            [
              -86.678435,
              30.994515
            ],
            [
              -86.68831401792629,
              30.994573472502697
            ],
            [
              -86.706312,
              30.99468
            ],
            [
              -86.725429,
              30.996849
            ],
            [
              -86.727343,
              30.996859
            ],
            [
              -86.728442,
              30.996716
            ],
            [
              -86.745289,
              30.996267
            ],
            [
              -86.78569442138259,
              30.9969494300445
            ],
            [
              -86.792429,
              30.685144
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "043",
      "COUNTYNS": "00295743",
      "AFFGEOID": "0500000US12043",
      "GEOID": "12043",
      "NAME": "Glades",
      "LSAD": "06",
      "ALAND": 2089270942,
      "AWATER": 466758592,
      "County_state": "Glades,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.562202,
              27.033836
            ],
            [
              -81.565931,
              26.769552
            ],
            [
              -81.459445,
              26.768856
            ],
            [
              -81.459327,
              26.783569
            ],
            [
              -81.434791,
              26.78346
            ],
            [
              -81.43506,
              26.768906
            ],
            [
              -80.945354,
              26.769049
            ],
            [
              -80.885639,
              26.958919
            ],
            [
              -80.871169,
              27.154951
            ],
            [
              -80.878669,
              27.169221
            ],
            [
              -80.890522,
              27.164233
            ],
            [
              -80.910183,
              27.174944
            ],
            [
              -80.906782,
              27.189113
            ],
            [
              -80.917465,
              27.189957
            ],
            [
              -80.943186,
              27.210599
            ],
            [
              -81.169138,
              27.209585
            ],
            [
              -81.168281,
              27.121734
            ],
            [
              -81.267677,
              27.121097
            ],
            [
              -81.267152,
              27.032461
            ],
            [
              -81.562202,
              27.033836
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "027",
      "COUNTYNS": "00294452",
      "AFFGEOID": "0500000US12027",
      "GEOID": "12027",
      "NAME": "DeSoto",
      "LSAD": "06",
      "ALAND": 1649978043,
      "AWATER": 6247257,
      "County_state": "DeSoto,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.056497,
              27.207769
            ],
            [
              -82.057469,
              27.032119
            ],
            [
              -81.992522,
              27.0317
            ],
            [
              -81.983242,
              27.03525
            ],
            [
              -81.562202,
              27.033836
            ],
            [
              -81.563696,
              27.34068
            ],
            [
              -81.858829,
              27.340262
            ],
            [
              -82.055753,
              27.338264
            ],
            [
              -82.056497,
              27.207769
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "029",
      "COUNTYNS": "00295725",
      "AFFGEOID": "0500000US12029",
      "GEOID": "12029",
      "NAME": "Dixie",
      "LSAD": "06",
      "ALAND": 1826212966,
      "AWATER": 410630395,
      "County_state": "Dixie,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.4122777424356,
              29.6669224627392
            ],
            [
              -83.404081,
              29.640798
            ],
            [
              -83.39948,
              29.612956
            ],
            [
              -83.405068,
              29.59557
            ],
            [
              -83.405256,
              29.578319
            ],
            [
              -83.39983,
              29.533042
            ],
            [
              -83.401552,
              29.523291
            ],
            [
              -83.400252,
              29.517242
            ],
            [
              -83.383973,
              29.512995
            ],
            [
              -83.379254,
              29.503558
            ],
            [
              -83.370288,
              29.499901
            ],
            [
              -83.356722,
              29.499901
            ],
            [
              -83.350067,
              29.489358
            ],
            [
              -83.33113,
              29.475594
            ],
            [
              -83.323214,
              29.476789
            ],
            [
              -83.311546,
              29.475666
            ],
            [
              -83.307828,
              29.468861
            ],
            [
              -83.307094,
              29.459651
            ],
            [
              -83.294747,
              29.437923
            ],
            [
              -83.272019,
              29.432256
            ],
            [
              -83.263965,
              29.435806
            ],
            [
              -83.240509,
              29.433178
            ],
            [
              -83.218075,
              29.420492
            ],
            [
              -83.202446,
              29.394422
            ],
            [
              -83.200702,
              29.373855
            ],
            [
              -83.189581,
              29.363417
            ],
            [
              -83.175518,
              29.34469
            ],
            [
              -83.176852,
              29.329269
            ],
            [
              -83.17826,
              29.327916
            ],
            [
              -83.176736,
              29.31422
            ],
            [
              -83.169576,
              29.290355
            ],
            [
              -83.1660914958036,
              29.2888802109754
            ],
            [
              -83.148028,
              29.307769
            ],
            [
              -83.13957,
              29.323985
            ],
            [
              -83.119523,
              29.317153
            ],
            [
              -83.10452,
              29.32544
            ],
            [
              -83.098798,
              29.336865
            ],
            [
              -83.090389,
              29.332753
            ],
            [
              -83.060528,
              29.349457
            ],
            [
              -83.072051,
              29.353862
            ],
            [
              -83.063262,
              29.361818
            ],
            [
              -83.065602,
              29.372664
            ],
            [
              -83.035866,
              29.388198
            ],
            [
              -83.022901,
              29.399257
            ],
            [
              -83.026074,
              29.420018
            ],
            [
              -83.000842,
              29.46213
            ],
            [
              -82.984371,
              29.471004
            ],
            [
              -82.981429,
              29.517642
            ],
            [
              -82.969401,
              29.522315
            ],
            [
              -82.975976,
              29.541277
            ],
            [
              -82.950048,
              29.55611
            ],
            [
              -82.944462,
              29.582302
            ],
            [
              -82.937329,
              29.591196
            ],
            [
              -82.954879,
              29.585605
            ],
            [
              -82.971718,
              29.608564
            ],
            [
              -82.973054,
              29.624281
            ],
            [
              -82.956989,
              29.638878
            ],
            [
              -82.951833,
              29.661864
            ],
            [
              -82.959135,
              29.670023
            ],
            [
              -82.952474,
              29.703915
            ],
            [
              -82.939319,
              29.700932
            ],
            [
              -82.949689,
              29.726283
            ],
            [
              -82.938538,
              29.744487
            ],
            [
              -82.949707,
              29.754409
            ],
            [
              -82.934999,
              29.786398
            ],
            [
              -82.920364,
              29.79465
            ],
            [
              -82.935355,
              29.809455
            ],
            [
              -82.920608,
              29.824143
            ],
            [
              -82.959199,
              29.825409
            ],
            [
              -83.318859,
              29.822623
            ],
            [
              -83.319462,
              29.778328
            ],
            [
              -83.336561,
              29.760721
            ],
            [
              -83.345676,
              29.74349
            ],
            [
              -83.344902,
              29.726811
            ],
            [
              -83.351311,
              29.694292
            ],
            [
              -83.364994,
              29.676715
            ],
            [
              -83.366398,
              29.665701
            ],
            [
              -83.398512,
              29.673935
            ],
            [
              -83.4122777424356,
              29.6669224627392
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "085",
      "COUNTYNS": "00308550",
      "AFFGEOID": "0500000US12085",
      "GEOID": "12085",
      "NAME": "Martin",
      "LSAD": "06",
      "ALAND": 1408247995,
      "AWATER": 542262212,
      "County_state": "Martin,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.677427,
              27.121619
            ],
            [
              -80.885639,
              26.958919
            ],
            [
              -80.580237,
              26.957934
            ],
            [
              -80.530222,
              26.958071
            ],
            [
              -80.167843,
              26.956634
            ],
            [
              -80.16711,
              26.956627
            ],
            [
              -80.142007,
              26.95664
            ],
            [
              -80.141758,
              26.97094
            ],
            [
              -80.0795307959029,
              26.970500015894796
            ],
            [
              -80.093909,
              27.018587
            ],
            [
              -80.116772,
              27.072397
            ],
            [
              -80.138605,
              27.111517
            ],
            [
              -80.14982,
              27.143557
            ],
            [
              -80.159554,
              27.163325
            ],
            [
              -80.158277777587,
              27.1645607402001
            ],
            [
              -80.153375,
              27.169308
            ],
            [
              -80.16147,
              27.192814
            ],
            [
              -80.19309,
              27.249546
            ],
            [
              -80.1992879876763,
              27.2630216473883
            ],
            [
              -80.283936,
              27.263727
            ],
            [
              -80.28551,
              27.206025
            ],
            [
              -80.331821,
              27.205724
            ],
            [
              -80.481534,
              27.20637
            ],
            [
              -80.677862,
              27.205988
            ],
            [
              -80.677427,
              27.121619
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "049",
      "COUNTYNS": "00295746",
      "AFFGEOID": "0500000US12049",
      "GEOID": "12049",
      "NAME": "Hardee",
      "LSAD": "06",
      "ALAND": 1651867901,
      "AWATER": 1439673,
      "County_state": "Hardee,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.054349,
              27.646382
            ],
            [
              -82.055753,
              27.338264
            ],
            [
              -81.858829,
              27.340262
            ],
            [
              -81.563696,
              27.34068
            ],
            [
              -81.563318,
              27.646647
            ],
            [
              -82.054349,
              27.646382
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "005",
      "COUNTYNS": "00295738",
      "AFFGEOID": "0500000US12005",
      "GEOID": "12005",
      "NAME": "Bay",
      "LSAD": "06",
      "ALAND": 1964404472,
      "AWATER": 711449254,
      "County_state": "Bay,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.994712,
              30.311702
            ],
            [
              -85.9960829280096,
              30.269147612111702
            ],
            [
              -85.9226,
              30.238024
            ],
            [
              -85.878138,
              30.215619
            ],
            [
              -85.811219,
              30.17832
            ],
            [
              -85.775405,
              30.15629
            ],
            [
              -85.74993,
              30.136537
            ],
            [
              -85.730054,
              30.118153
            ],
            [
              -85.69681,
              30.09689
            ],
            [
              -85.653251,
              30.077839
            ],
            [
              -85.637285,
              30.073319
            ],
            [
              -85.618254,
              30.065481
            ],
            [
              -85.601178,
              30.056342
            ],
            [
              -85.588242,
              30.055543
            ],
            [
              -85.58139,
              30.037783
            ],
            [
              -85.571907,
              30.02644
            ],
            [
              -85.541176,
              29.995791
            ],
            [
              -85.509148,
              29.971466
            ],
            [
              -85.487764,
              29.961227
            ],
            [
              -85.469425,
              29.957788
            ],
            [
              -85.460488,
              29.959579
            ],
            [
              -85.425956,
              29.949888
            ],
            [
              -85.405052,
              29.938487
            ],
            [
              -85.3886772298017,
              29.9243554814616
            ],
            [
              -85.391387,
              30.022674
            ],
            [
              -85.389679,
              30.200973
            ],
            [
              -85.388587,
              30.348556
            ],
            [
              -85.383948,
              30.566856
            ],
            [
              -85.434782,
              30.56756
            ],
            [
              -85.486358,
              30.567574
            ],
            [
              -85.487968,
              30.436655
            ],
            [
              -85.8535,
              30.440623
            ],
            [
              -85.870358,
              30.419764
            ],
            [
              -85.883984,
              30.410253
            ],
            [
              -85.907638,
              30.405819
            ],
            [
              -85.921516,
              30.41583
            ],
            [
              -85.940246,
              30.408622
            ],
            [
              -85.964967,
              30.410708
            ],
            [
              -85.981834,
              30.39219
            ],
            [
              -85.992736,
              30.38932
            ],
            [
              -85.994712,
              30.311702
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "035",
      "COUNTYNS": "00308547",
      "AFFGEOID": "0500000US12035",
      "GEOID": "12035",
      "NAME": "Flagler",
      "LSAD": "06",
      "ALAND": 1257367545,
      "AWATER": 221045256,
      "County_state": "Flagler,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.52366,
              29.622432
            ],
            [
              -81.520596,
              29.500249
            ],
            [
              -81.514736,
              29.486024
            ],
            [
              -81.486694,
              29.445551
            ],
            [
              -81.485994,
              29.424151
            ],
            [
              -81.476893,
              29.396552
            ],
            [
              -81.451692,
              29.390152
            ],
            [
              -81.433992,
              29.398552
            ],
            [
              -81.420745,
              29.399645
            ],
            [
              -81.41729,
              29.261156
            ],
            [
              -81.268404,
              29.263182
            ],
            [
              -81.19534,
              29.264823
            ],
            [
              -81.150081,
              29.265957
            ],
            [
              -81.15254,
              29.346178
            ],
            [
              -81.155881,
              29.410954
            ],
            [
              -81.148731,
              29.416788
            ],
            [
              -81.10192298992641,
              29.4270550536747
            ],
            [
              -81.123896,
              29.474465
            ],
            [
              -81.163581,
              29.55529
            ],
            [
              -81.211565,
              29.667085
            ],
            [
              -81.21287790507309,
              29.670666936087198
            ],
            [
              -81.227309,
              29.670538
            ],
            [
              -81.24726,
              29.659093
            ],
            [
              -81.268391,
              29.663127
            ],
            [
              -81.28212,
              29.650416
            ],
            [
              -81.297733,
              29.651124
            ],
            [
              -81.305914,
              29.642647
            ],
            [
              -81.324345,
              29.653478
            ],
            [
              -81.324059,
              29.62561
            ],
            [
              -81.52366,
              29.622432
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "095",
      "COUNTYNS": "00295750",
      "AFFGEOID": "0500000US12095",
      "GEOID": "12095",
      "NAME": "Orange",
      "LSAD": "06",
      "ALAND": 2339138134,
      "AWATER": 259141175,
      "County_state": "Orange,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.658597,
              28.76687
            ],
            [
              -81.658395,
              28.740288
            ],
            [
              -81.657199,
              28.592567
            ],
            [
              -81.648179,
              28.584007
            ],
            [
              -81.657074,
              28.563228
            ],
            [
              -81.657392,
              28.553299
            ],
            [
              -81.657281,
              28.546294
            ],
            [
              -81.65749,
              28.474163
            ],
            [
              -81.657268,
              28.347098
            ],
            [
              -81.487591,
              28.347088
            ],
            [
              -81.457592,
              28.348185
            ],
            [
              -81.419781,
              28.347923
            ],
            [
              -81.319697,
              28.348123
            ],
            [
              -81.309571,
              28.348051
            ],
            [
              -81.094674,
              28.348105
            ],
            [
              -80.862908,
              28.347487
            ],
            [
              -80.888388,
              28.385468
            ],
            [
              -80.88299,
              28.408156
            ],
            [
              -80.90118,
              28.433158
            ],
            [
              -80.896835,
              28.452827
            ],
            [
              -80.894669,
              28.466969
            ],
            [
              -80.87088,
              28.471745
            ],
            [
              -80.882433,
              28.491597
            ],
            [
              -80.88089,
              28.503633
            ],
            [
              -80.898646,
              28.509689
            ],
            [
              -80.934077,
              28.540611
            ],
            [
              -80.93012,
              28.562742
            ],
            [
              -80.947156,
              28.584761
            ],
            [
              -80.952011,
              28.604792
            ],
            [
              -80.957504,
              28.601915
            ],
            [
              -80.98725,
              28.612997
            ],
            [
              -81.129006,
              28.612535
            ],
            [
              -81.244698,
              28.611536
            ],
            [
              -81.251955,
              28.611786
            ],
            [
              -81.282099,
              28.611168
            ],
            [
              -81.32789,
              28.610277
            ],
            [
              -81.328269,
              28.632408
            ],
            [
              -81.32865,
              28.639334
            ],
            [
              -81.407633,
              28.640104
            ],
            [
              -81.445419,
              28.63998
            ],
            [
              -81.45663,
              28.640323
            ],
            [
              -81.459727,
              28.642611
            ],
            [
              -81.459622,
              28.67274
            ],
            [
              -81.459378,
              28.713724
            ],
            [
              -81.441786,
              28.714957
            ],
            [
              -81.422918,
              28.737344
            ],
            [
              -81.414446,
              28.784955
            ],
            [
              -81.646513,
              28.785872
            ],
            [
              -81.658597,
              28.76687
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "131",
      "COUNTYNS": "00295727",
      "AFFGEOID": "0500000US12131",
      "GEOID": "12131",
      "NAME": "Walton",
      "LSAD": "06",
      "ALAND": 2687686788,
      "AWATER": 522848570,
      "County_state": "Walton,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.391611,
              30.729726
            ],
            [
              -86.39732470104829,
              30.3785530924929
            ],
            [
              -86.364175,
              30.374524
            ],
            [
              -86.2987,
              30.363049
            ],
            [
              -86.222561,
              30.343585
            ],
            [
              -86.089963,
              30.303569
            ],
            [
              -85.999937,
              30.27078
            ],
            [
              -85.9960829280096,
              30.269147612111702
            ],
            [
              -85.994712,
              30.311702
            ],
            [
              -85.992736,
              30.38932
            ],
            [
              -85.98659,
              30.423515
            ],
            [
              -85.963701,
              30.439935
            ],
            [
              -85.933193,
              30.444887
            ],
            [
              -85.916153,
              30.438502
            ],
            [
              -85.901219,
              30.443849
            ],
            [
              -85.889359,
              30.47114
            ],
            [
              -85.87802,
              30.466845
            ],
            [
              -85.859769,
              30.490134
            ],
            [
              -85.867935,
              30.506613
            ],
            [
              -85.880411,
              30.505045
            ],
            [
              -85.886701,
              30.519937
            ],
            [
              -85.879827,
              30.556205
            ],
            [
              -85.888434,
              30.559777
            ],
            [
              -85.89535,
              30.58635
            ],
            [
              -85.915921,
              30.596788
            ],
            [
              -85.908264,
              30.614042
            ],
            [
              -85.915174,
              30.636974
            ],
            [
              -85.890601,
              30.656452
            ],
            [
              -85.867122,
              30.66313
            ],
            [
              -85.8756,
              30.679754
            ],
            [
              -85.844421,
              30.70313
            ],
            [
              -86.037591,
              30.704572
            ],
            [
              -86.0350402418067,
              30.993306001451096
            ],
            [
              -86.035121,
              30.993307
            ],
            [
              -86.052544,
              30.993233
            ],
            [
              -86.056294,
              30.993119
            ],
            [
              -86.116997,
              30.992902
            ],
            [
              -86.162962,
              30.993667
            ],
            [
              -86.169055,
              30.993691
            ],
            [
              -86.17528,
              30.993783
            ],
            [
              -86.180308,
              30.99399
            ],
            [
              -86.18724655883341,
              30.994033470489097
            ],
            [
              -86.238408,
              30.994354
            ],
            [
              -86.25652,
              30.993837
            ],
            [
              -86.289318,
              30.993781
            ],
            [
              -86.304666,
              30.994012
            ],
            [
              -86.364974,
              30.994437
            ],
            [
              -86.369337,
              30.994459
            ],
            [
              -86.374612,
              30.994456
            ],
            [
              -86.38864587273319,
              30.994164394815197
            ],
            [
              -86.391611,
              30.729726
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "101",
      "COUNTYNS": "00295739",
      "AFFGEOID": "0500000US12101",
      "GEOID": "12101",
      "NAME": "Pasco",
      "LSAD": "06",
      "ALAND": 1936400029,
      "AWATER": 312907704,
      "County_state": "Pasco,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.797762,
              28.187789
            ],
            [
              -82.805097,
              28.172181
            ],
            [
              -82.699024,
              28.173247
            ],
            [
              -82.672144,
              28.173319
            ],
            [
              -82.651165,
              28.173266
            ],
            [
              -82.474056,
              28.171935
            ],
            [
              -82.461461,
              28.171823
            ],
            [
              -82.410512,
              28.171213
            ],
            [
              -82.394083,
              28.171127
            ],
            [
              -82.259741,
              28.171462
            ],
            [
              -82.124806,
              28.172149
            ],
            [
              -82.105853,
              28.17165
            ],
            [
              -82.106206,
              28.25924
            ],
            [
              -82.055531,
              28.258928
            ],
            [
              -82.055819,
              28.312794
            ],
            [
              -82.055513,
              28.345696
            ],
            [
              -82.054694,
              28.478397
            ],
            [
              -82.251907,
              28.478794
            ],
            [
              -82.253439,
              28.434824
            ],
            [
              -82.452909,
              28.433751
            ],
            [
              -82.63986,
              28.433844
            ],
            [
              -82.67874283849301,
              28.4334559859077
            ],
            [
              -82.684137,
              28.428019
            ],
            [
              -82.697433,
              28.420166
            ],
            [
              -82.706322,
              28.401325
            ],
            [
              -82.706112,
              28.368057
            ],
            [
              -82.715822,
              28.345501
            ],
            [
              -82.7182213340819,
              28.3423670443818
            ],
            [
              -82.73146,
              28.325075
            ],
            [
              -82.735463,
              28.30039
            ],
            [
              -82.732792,
              28.291933
            ],
            [
              -82.746188,
              28.261192
            ],
            [
              -82.759072,
              28.25402
            ],
            [
              -82.764103,
              28.244345
            ],
            [
              -82.76446,
              28.220069
            ],
            [
              -82.762643,
              28.219013
            ],
            [
              -82.797762,
              28.187789
            ]
          ]
        ],
        [
          [
            [
              -82.860813,
              28.217078
            ],
            [
              -82.859624,
              28.174135
            ],
            [
              -82.85938503658261,
              28.172175073687498
            ],
            [
              -82.852306,
              28.172257
            ],
            [
              -82.847773,
              28.171682
            ],
            [
              -82.829914,
              28.171936640674698
            ],
            [
              -82.829914,
              28.218125
            ],
            [
              -82.846552,
              28.228596
            ],
            [
              -82.860813,
              28.217078
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "111",
      "COUNTYNS": "00308372",
      "AFFGEOID": "0500000US12111",
      "GEOID": "12111",
      "NAME": "St. Lucie",
      "LSAD": "06",
      "ALAND": 1480655532,
      "AWATER": 300844721,
      "County_state": "St. Lucie,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.679821,
              27.55847
            ],
            [
              -80.679038,
              27.471813
            ],
            [
              -80.677862,
              27.205988
            ],
            [
              -80.481534,
              27.20637
            ],
            [
              -80.331821,
              27.205724
            ],
            [
              -80.28551,
              27.206025
            ],
            [
              -80.283936,
              27.263727
            ],
            [
              -80.1992879876763,
              27.2630216473883
            ],
            [
              -80.226753,
              27.322736
            ],
            [
              -80.233538,
              27.341307
            ],
            [
              -80.253665,
              27.37979
            ],
            [
              -80.265535,
              27.420542
            ],
            [
              -80.293171,
              27.500314
            ],
            [
              -80.30117,
              27.500314
            ],
            [
              -80.311757,
              27.524625
            ],
            [
              -80.32127113933309,
              27.5573775459518
            ],
            [
              -80.348422,
              27.557248
            ],
            [
              -80.679821,
              27.55847
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "133",
      "COUNTYNS": "00295762",
      "AFFGEOID": "0500000US12133",
      "GEOID": "12133",
      "NAME": "Washington",
      "LSAD": "06",
      "ALAND": 1513019369,
      "AWATER": 82294771,
      "County_state": "Washington,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.98659,
              30.423515
            ],
            [
              -85.992736,
              30.38932
            ],
            [
              -85.981834,
              30.39219
            ],
            [
              -85.964967,
              30.410708
            ],
            [
              -85.940246,
              30.408622
            ],
            [
              -85.921516,
              30.41583
            ],
            [
              -85.907638,
              30.405819
            ],
            [
              -85.883984,
              30.410253
            ],
            [
              -85.870358,
              30.419764
            ],
            [
              -85.8535,
              30.440623
            ],
            [
              -85.487968,
              30.436655
            ],
            [
              -85.486358,
              30.567574
            ],
            [
              -85.434782,
              30.56756
            ],
            [
              -85.432274,
              30.785457
            ],
            [
              -85.482718,
              30.785892
            ],
            [
              -85.482276,
              30.829509
            ],
            [
              -85.599934,
              30.830552
            ],
            [
              -85.603221,
              30.796528
            ],
            [
              -85.621458,
              30.770882
            ],
            [
              -85.619011,
              30.743868
            ],
            [
              -85.736051,
              30.74637
            ],
            [
              -85.735115,
              30.775292
            ],
            [
              -85.751979,
              30.775706
            ],
            [
              -85.751927,
              30.789837
            ],
            [
              -85.790185,
              30.790351
            ],
            [
              -85.821616,
              30.790775
            ],
            [
              -85.832743,
              30.750477
            ],
            [
              -85.822585,
              30.738787
            ],
            [
              -85.844421,
              30.70313
            ],
            [
              -85.8756,
              30.679754
            ],
            [
              -85.867122,
              30.66313
            ],
            [
              -85.890601,
              30.656452
            ],
            [
              -85.915174,
              30.636974
            ],
            [
              -85.908264,
              30.614042
            ],
            [
              -85.915921,
              30.596788
            ],
            [
              -85.89535,
              30.58635
            ],
            [
              -85.888434,
              30.559777
            ],
            [
              -85.879827,
              30.556205
            ],
            [
              -85.886701,
              30.519937
            ],
            [
              -85.880411,
              30.505045
            ],
            [
              -85.867935,
              30.506613
            ],
            [
              -85.859769,
              30.490134
            ],
            [
              -85.87802,
              30.466845
            ],
            [
              -85.889359,
              30.47114
            ],
            [
              -85.901219,
              30.443849
            ],
            [
              -85.916153,
              30.438502
            ],
            [
              -85.933193,
              30.444887
            ],
            [
              -85.963701,
              30.439935
            ],
            [
              -85.98659,
              30.423515
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "119",
      "COUNTYNS": "00295740",
      "AFFGEOID": "0500000US12119",
      "GEOID": "12119",
      "NAME": "Sumter",
      "LSAD": "06",
      "ALAND": 1418509811,
      "AWATER": 83231177,
      "County_state": "Sumter,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.311697,
              28.960391
            ],
            [
              -82.297658,
              28.949002
            ],
            [
              -82.295679,
              28.93804
            ],
            [
              -82.277853,
              28.91119
            ],
            [
              -82.273585,
              28.897216
            ],
            [
              -82.242235,
              28.876425
            ],
            [
              -82.236224,
              28.860583
            ],
            [
              -82.2224,
              28.852737
            ],
            [
              -82.205469,
              28.835132
            ],
            [
              -82.182602,
              28.821785
            ],
            [
              -82.179307,
              28.803316
            ],
            [
              -82.169141,
              28.792954
            ],
            [
              -82.184148,
              28.783321
            ],
            [
              -82.189439,
              28.758969
            ],
            [
              -82.210702,
              28.754063
            ],
            [
              -82.249076,
              28.717772
            ],
            [
              -82.248603,
              28.701494
            ],
            [
              -82.25997,
              28.682723
            ],
            [
              -82.263052,
              28.667634
            ],
            [
              -82.274823,
              28.656198
            ],
            [
              -82.257102,
              28.648963
            ],
            [
              -82.260795,
              28.637287
            ],
            [
              -82.22165,
              28.593239
            ],
            [
              -82.211951,
              28.574055
            ],
            [
              -82.197098,
              28.575508
            ],
            [
              -82.16539,
              28.563911
            ],
            [
              -82.156164,
              28.57335
            ],
            [
              -82.138025,
              28.565516
            ],
            [
              -82.130688,
              28.546384
            ],
            [
              -82.109423,
              28.543838
            ],
            [
              -82.097142,
              28.525356
            ],
            [
              -82.067783,
              28.531435
            ],
            [
              -82.054469,
              28.521367
            ],
            [
              -82.054694,
              28.478397
            ],
            [
              -82.055513,
              28.345696
            ],
            [
              -82.055819,
              28.312794
            ],
            [
              -82.02056,
              28.323783
            ],
            [
              -81.994703,
              28.317927
            ],
            [
              -81.985781,
              28.30532
            ],
            [
              -81.957805,
              28.308888
            ],
            [
              -81.957641,
              28.34518
            ],
            [
              -81.954247,
              28.55169
            ],
            [
              -81.954222,
              28.571249
            ],
            [
              -81.95358,
              28.836434
            ],
            [
              -81.95419,
              28.960053
            ],
            [
              -82.095165,
              28.960117
            ],
            [
              -82.311697,
              28.960391
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "099",
      "COUNTYNS": "00295761",
      "AFFGEOID": "0500000US12099",
      "GEOID": "12099",
      "NAME": "Palm Beach",
      "LSAD": "06",
      "ALAND": 5092730552,
      "AWATER": 1079713751,
      "County_state": "Palm Beach,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.885639,
              26.958919
            ],
            [
              -80.886057,
              26.743773
            ],
            [
              -80.880017,
              26.346059
            ],
            [
              -80.881901,
              26.344106
            ],
            [
              -80.881233,
              26.333806
            ],
            [
              -80.297471,
              26.334356
            ],
            [
              -80.296935,
              26.334356
            ],
            [
              -80.296881,
              26.355431
            ],
            [
              -80.24977,
              26.341526
            ],
            [
              -80.204083,
              26.327822
            ],
            [
              -80.170332,
              26.327782
            ],
            [
              -80.170149,
              26.327781
            ],
            [
              -80.169917,
              26.327806
            ],
            [
              -80.120125,
              26.327791
            ],
            [
              -80.099017,
              26.327321
            ],
            [
              -80.098823,
              26.322834
            ],
            [
              -80.0927,
              26.326327
            ],
            [
              -80.0748371491155,
              26.3210321365904
            ],
            [
              -80.072264,
              26.335356
            ],
            [
              -80.070564,
              26.336455
            ],
            [
              -80.0622515253692,
              26.426393481763
            ],
            [
              -80.06172090887449,
              26.432134593050296
            ],
            [
              -80.060564,
              26.444652
            ],
            [
              -80.050363,
              26.509549
            ],
            [
              -80.038863,
              26.569347
            ],
            [
              -80.03682624871449,
              26.5943693624357
            ],
            [
              -80.035363,
              26.612346
            ],
            [
              -80.035763,
              26.676043
            ],
            [
              -80.032862,
              26.700842
            ],
            [
              -80.032862,
              26.715242
            ],
            [
              -80.037462,
              26.76634
            ],
            [
              -80.036362,
              26.77124
            ],
            [
              -80.03212,
              26.77153
            ],
            [
              -80.0318113374771,
              26.7816323859244
            ],
            [
              -80.031362,
              26.796339
            ],
            [
              -80.0357210263756,
              26.814739
            ],
            [
              -80.046263,
              26.859238
            ],
            [
              -80.0566225332655,
              26.8938847965062
            ],
            [
              -80.0795307959029,
              26.970500015894796
            ],
            [
              -80.141758,
              26.97094
            ],
            [
              -80.142007,
              26.95664
            ],
            [
              -80.16711,
              26.956627
            ],
            [
              -80.167843,
              26.956634
            ],
            [
              -80.530222,
              26.958071
            ],
            [
              -80.580237,
              26.957934
            ],
            [
              -80.885639,
              26.958919
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "001",
      "COUNTYNS": "00308548",
      "AFFGEOID": "0500000US12001",
      "GEOID": "12001",
      "NAME": "Alachua",
      "LSAD": "06",
      "ALAND": 2266400894,
      "AWATER": 242801104,
      "County_state": "Alachua,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.658554,
              29.830144
            ],
            [
              -82.658097,
              29.821778
            ],
            [
              -82.656301,
              29.564811
            ],
            [
              -82.656331,
              29.53562
            ],
            [
              -82.557565,
              29.537606
            ],
            [
              -82.556511,
              29.511982
            ],
            [
              -82.556766,
              29.480055
            ],
            [
              -82.496331,
              29.478371
            ],
            [
              -82.40662,
              29.485048
            ],
            [
              -82.211292,
              29.483995
            ],
            [
              -82.18938,
              29.460949
            ],
            [
              -82.192045,
              29.45234
            ],
            [
              -82.211661,
              29.466056
            ],
            [
              -82.211837,
              29.430052
            ],
            [
              -82.193626,
              29.420935
            ],
            [
              -82.151217,
              29.426795
            ],
            [
              -82.14502,
              29.418077
            ],
            [
              -82.135466,
              29.434667
            ],
            [
              -82.118465,
              29.428393
            ],
            [
              -82.101143,
              29.438313
            ],
            [
              -82.099941,
              29.418898
            ],
            [
              -82.094537,
              29.430352
            ],
            [
              -82.071291,
              29.443271
            ],
            [
              -82.056747,
              29.439951
            ],
            [
              -82.055899,
              29.471232
            ],
            [
              -82.055027,
              29.669608
            ],
            [
              -82.049052,
              29.713368
            ],
            [
              -82.055625,
              29.718232
            ],
            [
              -82.05807,
              29.748059
            ],
            [
              -82.082095,
              29.763572
            ],
            [
              -82.079348,
              29.776005
            ],
            [
              -82.109123,
              29.783085
            ],
            [
              -82.124054,
              29.805289
            ],
            [
              -82.123923,
              29.823686
            ],
            [
              -82.133126,
              29.835949
            ],
            [
              -82.173204,
              29.836976
            ],
            [
              -82.190271,
              29.848087
            ],
            [
              -82.233514,
              29.845297
            ],
            [
              -82.24245,
              29.852459
            ],
            [
              -82.272563,
              29.843011
            ],
            [
              -82.313914,
              29.859959
            ],
            [
              -82.335957,
              29.878848
            ],
            [
              -82.375003,
              29.879433
            ],
            [
              -82.398771,
              29.887319
            ],
            [
              -82.406629,
              29.914054
            ],
            [
              -82.418728,
              29.923093
            ],
            [
              -82.426292,
              29.921807
            ],
            [
              -82.448948,
              29.931515
            ],
            [
              -82.472129,
              29.926547
            ],
            [
              -82.484248,
              29.939625
            ],
            [
              -82.525368,
              29.94496
            ],
            [
              -82.529705,
              29.940881
            ],
            [
              -82.541554,
              29.925495
            ],
            [
              -82.554735,
              29.928541
            ],
            [
              -82.578786,
              29.915362
            ],
            [
              -82.575876,
              29.894865
            ],
            [
              -82.592553,
              29.887515
            ],
            [
              -82.586485,
              29.866702
            ],
            [
              -82.61108,
              29.852117
            ],
            [
              -82.631251,
              29.849112
            ],
            [
              -82.634734,
              29.833596
            ],
            [
              -82.651494,
              29.825587
            ],
            [
              -82.658554,
              29.830144
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "067",
      "COUNTYNS": "00295732",
      "AFFGEOID": "0500000US12067",
      "GEOID": "12067",
      "NAME": "Lafayette",
      "LSAD": "06",
      "ALAND": 1407329163,
      "AWATER": 11745118,
      "County_state": "Lafayette,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.368564,
              30.187217
            ],
            [
              -83.37089,
              29.887934
            ],
            [
              -83.352986,
              29.887859
            ],
            [
              -83.35286,
              29.822222
            ],
            [
              -83.318859,
              29.822623
            ],
            [
              -82.959199,
              29.825409
            ],
            [
              -82.920608,
              29.824143
            ],
            [
              -82.893371,
              29.826839
            ],
            [
              -82.890246,
              29.845129
            ],
            [
              -82.875616,
              29.866527
            ],
            [
              -82.879802,
              29.886847
            ],
            [
              -82.895034,
              29.900256
            ],
            [
              -82.918607,
              29.896388
            ],
            [
              -82.912101,
              29.904836
            ],
            [
              -82.925884,
              29.921331
            ],
            [
              -82.920546,
              29.930072
            ],
            [
              -82.930782,
              29.957399
            ],
            [
              -82.950569,
              29.958317
            ],
            [
              -82.960377,
              29.975154
            ],
            [
              -82.963112,
              29.99305
            ],
            [
              -82.977608,
              30.006116
            ],
            [
              -83.003192,
              30.010795
            ],
            [
              -83.013293,
              30.032123
            ],
            [
              -83.041499,
              30.054569
            ],
            [
              -83.066529,
              30.065373
            ],
            [
              -83.069553,
              30.081252
            ],
            [
              -83.111149,
              30.090929
            ],
            [
              -83.116453,
              30.104073
            ],
            [
              -83.132356,
              30.097023
            ],
            [
              -83.160557,
              30.109823
            ],
            [
              -83.184358,
              30.094122
            ],
            [
              -83.201059,
              30.105822
            ],
            [
              -83.212159,
              30.103222
            ],
            [
              -83.225359,
              30.113121
            ],
            [
              -83.224594,
              30.135278
            ],
            [
              -83.238586,
              30.152011
            ],
            [
              -83.232979,
              30.168728
            ],
            [
              -83.248851,
              30.183931
            ],
            [
              -83.250076,
              30.200644
            ],
            [
              -83.240132,
              30.227529
            ],
            [
              -83.253048,
              30.238725
            ],
            [
              -83.247253,
              30.26067
            ],
            [
              -83.36726,
              30.26044
            ],
            [
              -83.36678,
              30.20109
            ],
            [
              -83.368564,
              30.187217
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "093",
      "COUNTYNS": "00295760",
      "AFFGEOID": "0500000US12093",
      "GEOID": "12093",
      "NAME": "Okeechobee",
      "LSAD": "06",
      "ALAND": 1991433070,
      "AWATER": 318578493,
      "County_state": "Okeechobee,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.213717,
              27.528654
            ],
            [
              -81.198459,
              27.510376
            ],
            [
              -81.205096,
              27.489702
            ],
            [
              -81.183382,
              27.482247
            ],
            [
              -81.172934,
              27.463583
            ],
            [
              -81.147162,
              27.447216
            ],
            [
              -81.139058,
              27.434044
            ],
            [
              -81.142257,
              27.412078
            ],
            [
              -81.125073,
              27.403634
            ],
            [
              -81.125637,
              27.393991
            ],
            [
              -81.09845,
              27.379108
            ],
            [
              -81.081607,
              27.385857
            ],
            [
              -81.034429,
              27.35916
            ],
            [
              -81.033308,
              27.348809
            ],
            [
              -81.046655,
              27.340491
            ],
            [
              -81.042218,
              27.32823
            ],
            [
              -81.029787,
              27.323937
            ],
            [
              -81.024673,
              27.301807
            ],
            [
              -81.004102,
              27.302012
            ],
            [
              -80.997768,
              27.295148
            ],
            [
              -80.998871,
              27.268153
            ],
            [
              -80.993216,
              27.251199
            ],
            [
              -80.980853,
              27.246326
            ],
            [
              -80.981778,
              27.231552
            ],
            [
              -80.970916,
              27.217698
            ],
            [
              -80.951656,
              27.224825
            ],
            [
              -80.943186,
              27.210599
            ],
            [
              -80.917465,
              27.189957
            ],
            [
              -80.906782,
              27.189113
            ],
            [
              -80.910183,
              27.174944
            ],
            [
              -80.890522,
              27.164233
            ],
            [
              -80.878669,
              27.169221
            ],
            [
              -80.871169,
              27.154951
            ],
            [
              -80.885639,
              26.958919
            ],
            [
              -80.677427,
              27.121619
            ],
            [
              -80.677862,
              27.205988
            ],
            [
              -80.679038,
              27.471813
            ],
            [
              -80.679821,
              27.55847
            ],
            [
              -80.777166,
              27.558733
            ],
            [
              -80.778047,
              27.643195
            ],
            [
              -80.87315,
              27.642288
            ],
            [
              -81.142164,
              27.643238
            ],
            [
              -81.142477,
              27.597766
            ],
            [
              -81.155346,
              27.591359
            ],
            [
              -81.156578,
              27.574494
            ],
            [
              -81.165939,
              27.574971
            ],
            [
              -81.177958,
              27.557707
            ],
            [
              -81.190626,
              27.556664
            ],
            [
              -81.213717,
              27.528654
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "053",
      "COUNTYNS": "00295751",
      "AFFGEOID": "0500000US12053",
      "GEOID": "12053",
      "NAME": "Hernando",
      "LSAD": "06",
      "ALAND": 1224692722,
      "AWATER": 300863005,
      "County_state": "Hernando,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.680396,
              28.457194
            ],
            [
              -82.674787,
              28.441956
            ],
            [
              -82.677839,
              28.434367
            ],
            [
              -82.67874283849301,
              28.4334559859077
            ],
            [
              -82.63986,
              28.433844
            ],
            [
              -82.452909,
              28.433751
            ],
            [
              -82.253439,
              28.434824
            ],
            [
              -82.251907,
              28.478794
            ],
            [
              -82.054694,
              28.478397
            ],
            [
              -82.054469,
              28.521367
            ],
            [
              -82.067783,
              28.531435
            ],
            [
              -82.097142,
              28.525356
            ],
            [
              -82.109423,
              28.543838
            ],
            [
              -82.130688,
              28.546384
            ],
            [
              -82.138025,
              28.565516
            ],
            [
              -82.156164,
              28.57335
            ],
            [
              -82.16539,
              28.563911
            ],
            [
              -82.197098,
              28.575508
            ],
            [
              -82.211951,
              28.574055
            ],
            [
              -82.22165,
              28.593239
            ],
            [
              -82.260795,
              28.637287
            ],
            [
              -82.257102,
              28.648963
            ],
            [
              -82.274823,
              28.656198
            ],
            [
              -82.263052,
              28.667634
            ],
            [
              -82.417785,
              28.665898
            ],
            [
              -82.418353,
              28.694859
            ],
            [
              -82.507397,
              28.694671
            ],
            [
              -82.6688895903084,
              28.6943024445354
            ],
            [
              -82.674665,
              28.647588
            ],
            [
              -82.664055,
              28.606584
            ],
            [
              -82.654138,
              28.590837
            ],
            [
              -82.65705,
              28.568028
            ],
            [
              -82.66165,
              28.554143
            ],
            [
              -82.661729,
              28.549743
            ],
            [
              -82.656694,
              28.544814
            ],
            [
              -82.66267020268918,
              28.5323510047754
            ],
            [
              -82.663705,
              28.530193
            ],
            [
              -82.66804,
              28.528199
            ],
            [
              -82.669416,
              28.519879
            ],
            [
              -82.670146,
              28.500769
            ],
            [
              -82.66639,
              28.49733
            ],
            [
              -82.66447,
              28.488788
            ],
            [
              -82.665055,
              28.484434
            ],
            [
              -82.67241,
              28.464746
            ],
            [
              -82.680396,
              28.457194
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "015",
      "COUNTYNS": "00295742",
      "AFFGEOID": "0500000US12015",
      "GEOID": "12015",
      "NAME": "Charlotte",
      "LSAD": "06",
      "ALAND": 1764104254,
      "AWATER": 458735963,
      "County_state": "Charlotte,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.3757372343028,
              26.946041019759097
            ],
            [
              -82.351649,
              26.908384
            ],
            [
              -82.301736,
              26.841588
            ],
            [
              -82.289086,
              26.827784
            ],
            [
              -82.2716991509411,
              26.789516421354598
            ],
            [
              -82.264676,
              26.789429
            ],
            [
              -82.242631,
              26.785739
            ],
            [
              -82.233914,
              26.783221
            ],
            [
              -82.222403,
              26.781988
            ],
            [
              -82.2200002071999,
              26.769131457675797
            ],
            [
              -82.208861,
              26.767164
            ],
            [
              -82.1882311810468,
              26.7708501673122
            ],
            [
              -82.17869,
              26.772555
            ],
            [
              -82.172941,
              26.778879
            ],
            [
              -82.151093,
              26.783479
            ],
            [
              -82.147068,
              26.789803
            ],
            [
              -82.156267,
              26.851898
            ],
            [
              -82.172941,
              26.897319
            ],
            [
              -82.175241,
              26.916867
            ],
            [
              -82.169491,
              26.923191
            ],
            [
              -82.162017,
              26.925491
            ],
            [
              -82.137294,
              26.926066
            ],
            [
              -82.124645,
              26.945615
            ],
            [
              -82.117171,
              26.954239
            ],
            [
              -82.107972,
              26.957688
            ],
            [
              -82.0787899821341,
              26.9582186156997
            ],
            [
              -82.076349,
              26.958263
            ],
            [
              -82.063126,
              26.950214
            ],
            [
              -82.0616627832629,
              26.9404600699941
            ],
            [
              -82.061401,
              26.938715
            ],
            [
              -82.061976,
              26.931241
            ],
            [
              -82.067725,
              26.927791
            ],
            [
              -82.083249,
              26.927791
            ],
            [
              -82.090148,
              26.923191
            ],
            [
              -82.093023,
              26.906518
            ],
            [
              -82.090723,
              26.888694
            ],
            [
              -82.066575,
              26.88237
            ],
            [
              -82.059101,
              26.876621
            ],
            [
              -82.056801,
              26.858797
            ],
            [
              -82.058526,
              26.838674
            ],
            [
              -82.057951,
              26.822
            ],
            [
              -82.055076,
              26.802452
            ],
            [
              -82.061401,
              26.789228
            ],
            [
              -82.061401,
              26.774279
            ],
            [
              -82.0620292851376,
              26.7704391019286
            ],
            [
              -81.931333,
              26.770051
            ],
            [
              -81.931098,
              26.770049
            ],
            [
              -81.565931,
              26.769552
            ],
            [
              -81.562202,
              27.033836
            ],
            [
              -81.983242,
              27.03525
            ],
            [
              -81.992522,
              27.0317
            ],
            [
              -82.057469,
              27.032119
            ],
            [
              -82.073937,
              27.0321
            ],
            [
              -82.255237,
              27.032975
            ],
            [
              -82.255521,
              26.945264
            ],
            [
              -82.3757372343028,
              26.946041019759097
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "071",
      "COUNTYNS": "00295758",
      "AFFGEOID": "0500000US12071",
      "GEOID": "12071",
      "NAME": "Lee",
      "LSAD": "06",
      "ALAND": 2030703925,
      "AWATER": 1109326687,
      "County_state": "Lee,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.17984,
              26.696661
            ],
            [
              -82.181565,
              26.681712
            ],
            [
              -82.149943,
              26.654115
            ],
            [
              -82.137869,
              26.637441
            ],
            [
              -82.122345,
              26.579371
            ],
            [
              -82.118896,
              26.560973
            ],
            [
              -82.111996,
              26.54085
            ],
            [
              -82.105672,
              26.48393
            ],
            [
              -82.094748,
              26.48393
            ],
            [
              -82.07175,
              26.492554
            ],
            [
              -82.056892,
              26.493748
            ],
            [
              -82.06715,
              26.513252
            ],
            [
              -82.068214,
              26.531093
            ],
            [
              -82.0589292737718,
              26.546953262062
            ],
            [
              -82.05808,
              26.548404
            ],
            [
              -82.0415785949818,
              26.5253225680563
            ],
            [
              -82.041205,
              26.5248
            ],
            [
              -82.015298,
              26.528841
            ],
            [
              -82.00908,
              26.505203
            ],
            [
              -82.01368,
              26.490829
            ],
            [
              -82.008961,
              26.484052
            ],
            [
              -81.997012,
              26.484856
            ],
            [
              -81.980712,
              26.480957
            ],
            [
              -81.969509,
              26.476505
            ],
            [
              -81.966212,
              26.465057
            ],
            [
              -81.967112,
              26.462857
            ],
            [
              -81.964212,
              26.457957
            ],
            [
              -81.956611,
              26.452358
            ],
            [
              -81.938411,
              26.445058
            ],
            [
              -81.923611,
              26.436658
            ],
            [
              -81.91171,
              26.427158
            ],
            [
              -81.90271,
              26.416159
            ],
            [
              -81.90191,
              26.410859
            ],
            [
              -81.88791495817239,
              26.397168281370696
            ],
            [
              -81.868983,
              26.378648
            ],
            [
              -81.8458335535129,
              26.3303779735441
            ],
            [
              -81.819119,
              26.330424
            ],
            [
              -81.819019,
              26.316245
            ],
            [
              -81.754404,
              26.316753
            ],
            [
              -81.746167,
              26.316896
            ],
            [
              -81.6579,
              26.317563
            ],
            [
              -81.658356,
              26.421097
            ],
            [
              -81.56218,
              26.422625
            ],
            [
              -81.563763,
              26.513324
            ],
            [
              -81.563827,
              26.518469
            ],
            [
              -81.565095,
              26.600333
            ],
            [
              -81.566275,
              26.709552
            ],
            [
              -81.565931,
              26.769552
            ],
            [
              -81.931098,
              26.770049
            ],
            [
              -81.931333,
              26.770051
            ],
            [
              -82.0620292851376,
              26.7704391019286
            ],
            [
              -82.066575,
              26.742657
            ],
            [
              -82.079799,
              26.716784
            ],
            [
              -82.084974,
              26.702411
            ],
            [
              -82.086698,
              26.685162
            ],
            [
              -82.091716475598,
              26.6696519350214
            ],
            [
              -82.093023,
              26.665614
            ],
            [
              -82.099922,
              26.662739
            ],
            [
              -82.106247,
              26.667339
            ],
            [
              -82.118896,
              26.690912
            ],
            [
              -82.125795,
              26.699536
            ],
            [
              -82.139019,
              26.702986
            ],
            [
              -82.151668,
              26.704136
            ],
            [
              -82.173516,
              26.701836
            ],
            [
              -82.17984,
              26.696661
            ]
          ]
        ],
        [
          [
            [
              -82.264351,
              26.698496
            ],
            [
              -82.268007,
              26.682791
            ],
            [
              -82.263008,
              26.673388
            ],
            [
              -82.265663,
              26.655304
            ],
            [
              -82.25259,
              26.63406
            ],
            [
              -82.243083,
              26.590498
            ],
            [
              -82.227634,
              26.566053
            ],
            [
              -82.218127,
              26.547982
            ],
            [
              -82.195547,
              26.491624
            ],
            [
              -82.180717,
              26.476257
            ],
            [
              -82.177017,
              26.471558
            ],
            [
              -82.172917,
              26.467658
            ],
            [
              -82.148716,
              26.455458
            ],
            [
              -82.126671,
              26.436279
            ],
            [
              -82.098115,
              26.424959
            ],
            [
              -82.082915,
              26.422059
            ],
            [
              -82.075015,
              26.422059
            ],
            [
              -82.063114,
              26.425459
            ],
            [
              -82.013913,
              26.452058
            ],
            [
              -82.013713,
              26.454258
            ],
            [
              -82.015607,
              26.454858
            ],
            [
              -82.038403,
              26.456907
            ],
            [
              -82.062551,
              26.470131
            ],
            [
              -82.076924,
              26.466106
            ],
            [
              -82.088423,
              26.455182
            ],
            [
              -82.120046,
              26.473581
            ],
            [
              -82.131545,
              26.47703
            ],
            [
              -82.149368,
              26.477605
            ],
            [
              -82.166042,
              26.489679
            ],
            [
              -82.177541,
              26.502328
            ],
            [
              -82.187315,
              26.527626
            ],
            [
              -82.196514,
              26.559823
            ],
            [
              -82.214337,
              26.602944
            ],
            [
              -82.218342,
              26.626407
            ],
            [
              -82.23744,
              26.661976
            ],
            [
              -82.246535,
              26.683437
            ],
            [
              -82.24596,
              26.688612
            ],
            [
              -82.24251,
              26.694361
            ],
            [
              -82.246535,
              26.706435
            ],
            [
              -82.255159,
              26.70816
            ],
            [
              -82.255777,
              26.703437
            ],
            [
              -82.264351,
              26.698496
            ]
          ]
        ],
        [
          [
            [
              -82.2716991509411,
              26.789516421354598
            ],
            [
              -82.269499,
              26.784674
            ],
            [
              -82.264682,
              26.756836
            ],
            [
              -82.267463,
              26.720177
            ],
            [
              -82.252922,
              26.717694
            ],
            [
              -82.249888,
              26.762946
            ],
            [
              -82.242364,
              26.766234
            ],
            [
              -82.230636,
              26.77101
            ],
            [
              -82.2200002071999,
              26.769131457675797
            ],
            [
              -82.222403,
              26.781988
            ],
            [
              -82.233914,
              26.783221
            ],
            [
              -82.242631,
              26.785739
            ],
            [
              -82.264676,
              26.789429
            ],
            [
              -82.2716991509411,
              26.789516421354598
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "069",
      "COUNTYNS": "00308551",
      "AFFGEOID": "0500000US12069",
      "GEOID": "12069",
      "NAME": "Lake",
      "LSAD": "06",
      "ALAND": 2436872973,
      "AWATER": 559601970,
      "County_state": "Lake,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.954247,
              28.55169
            ],
            [
              -81.957641,
              28.34518
            ],
            [
              -81.857512,
              28.345965
            ],
            [
              -81.857624,
              28.361868
            ],
            [
              -81.790887,
              28.361837
            ],
            [
              -81.790727,
              28.346413
            ],
            [
              -81.657268,
              28.347098
            ],
            [
              -81.65749,
              28.474163
            ],
            [
              -81.657281,
              28.546294
            ],
            [
              -81.657392,
              28.553299
            ],
            [
              -81.657074,
              28.563228
            ],
            [
              -81.648179,
              28.584007
            ],
            [
              -81.657199,
              28.592567
            ],
            [
              -81.658395,
              28.740288
            ],
            [
              -81.658597,
              28.76687
            ],
            [
              -81.646513,
              28.785872
            ],
            [
              -81.414446,
              28.784955
            ],
            [
              -81.416846,
              28.824569
            ],
            [
              -81.382965,
              28.851994
            ],
            [
              -81.36694,
              28.879227
            ],
            [
              -81.352788,
              28.896685
            ],
            [
              -81.352966,
              28.946246
            ],
            [
              -81.368078,
              28.955407
            ],
            [
              -81.360762,
              28.976775
            ],
            [
              -81.354682,
              28.984807
            ],
            [
              -81.374787,
              28.993354
            ],
            [
              -81.382546,
              29.008322
            ],
            [
              -81.392084,
              29.019979
            ],
            [
              -81.43221,
              29.04442
            ],
            [
              -81.446699,
              29.062323
            ],
            [
              -81.455778,
              29.063998
            ],
            [
              -81.454383,
              29.081984
            ],
            [
              -81.466351,
              29.096703
            ],
            [
              -81.488841,
              29.093193
            ],
            [
              -81.502055,
              29.097802
            ],
            [
              -81.504534,
              29.123711
            ],
            [
              -81.531262,
              29.176621
            ],
            [
              -81.553588,
              29.188034
            ],
            [
              -81.560905,
              29.20019
            ],
            [
              -81.5931,
              29.198999
            ],
            [
              -81.614725,
              29.20648
            ],
            [
              -81.619303,
              29.22859
            ],
            [
              -81.63399,
              29.244779
            ],
            [
              -81.641916,
              29.276766
            ],
            [
              -81.640925,
              29.168809
            ],
            [
              -81.638696,
              29.04776
            ],
            [
              -81.659197,
              29.04776
            ],
            [
              -81.658698,
              28.960345
            ],
            [
              -81.696067,
              28.960068
            ],
            [
              -81.719094,
              28.961104
            ],
            [
              -81.761654,
              28.960932
            ],
            [
              -81.842194,
              28.961024
            ],
            [
              -81.848774,
              28.959828
            ],
            [
              -81.95419,
              28.960053
            ],
            [
              -81.95358,
              28.836434
            ],
            [
              -81.954222,
              28.571249
            ],
            [
              -81.954247,
              28.55169
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "121",
      "COUNTYNS": "00295729",
      "AFFGEOID": "0500000US12121",
      "GEOID": "12121",
      "NAME": "Suwannee",
      "LSAD": "06",
      "ALAND": 1783341106,
      "AWATER": 9594316,
      "County_state": "Suwannee,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.247253,
              30.26067
            ],
            [
              -83.253048,
              30.238725
            ],
            [
              -83.240132,
              30.227529
            ],
            [
              -83.250076,
              30.200644
            ],
            [
              -83.248851,
              30.183931
            ],
            [
              -83.232979,
              30.168728
            ],
            [
              -83.238586,
              30.152011
            ],
            [
              -83.224594,
              30.135278
            ],
            [
              -83.225359,
              30.113121
            ],
            [
              -83.212159,
              30.103222
            ],
            [
              -83.201059,
              30.105822
            ],
            [
              -83.184358,
              30.094122
            ],
            [
              -83.160557,
              30.109823
            ],
            [
              -83.132356,
              30.097023
            ],
            [
              -83.116453,
              30.104073
            ],
            [
              -83.111149,
              30.090929
            ],
            [
              -83.069553,
              30.081252
            ],
            [
              -83.066529,
              30.065373
            ],
            [
              -83.041499,
              30.054569
            ],
            [
              -83.013293,
              30.032123
            ],
            [
              -83.003192,
              30.010795
            ],
            [
              -82.977608,
              30.006116
            ],
            [
              -82.963112,
              29.99305
            ],
            [
              -82.960377,
              29.975154
            ],
            [
              -82.950569,
              29.958317
            ],
            [
              -82.930782,
              29.957399
            ],
            [
              -82.920546,
              29.930072
            ],
            [
              -82.925884,
              29.921331
            ],
            [
              -82.912101,
              29.904836
            ],
            [
              -82.918607,
              29.896388
            ],
            [
              -82.895034,
              29.900256
            ],
            [
              -82.879802,
              29.886847
            ],
            [
              -82.863266,
              29.91105
            ],
            [
              -82.832,
              29.912659
            ],
            [
              -82.800477,
              29.932126
            ],
            [
              -82.785838,
              29.954741
            ],
            [
              -82.761519,
              29.965517
            ],
            [
              -82.760411,
              29.986205
            ],
            [
              -82.793975,
              29.986202
            ],
            [
              -82.794594,
              30.337024
            ],
            [
              -82.799669,
              30.344262
            ],
            [
              -82.810906,
              30.33704
            ],
            [
              -82.832122,
              30.346765
            ],
            [
              -82.844387,
              30.340979
            ],
            [
              -82.85708,
              30.358324
            ],
            [
              -82.882063,
              30.365072
            ],
            [
              -82.893357,
              30.374622
            ],
            [
              -82.8888,
              30.385966
            ],
            [
              -82.905881,
              30.388084
            ],
            [
              -82.908074,
              30.380335
            ],
            [
              -82.966174,
              30.417503
            ],
            [
              -82.982742,
              30.419009
            ],
            [
              -83.002058,
              30.429371
            ],
            [
              -83.007557,
              30.422787
            ],
            [
              -83.034304,
              30.43085
            ],
            [
              -83.075921,
              30.425767
            ],
            [
              -83.084116,
              30.438071
            ],
            [
              -83.122103,
              30.43261
            ],
            [
              -83.134548,
              30.419156
            ],
            [
              -83.15521,
              30.417217
            ],
            [
              -83.158412,
              30.400525
            ],
            [
              -83.170969,
              30.385225
            ],
            [
              -83.192343,
              30.374846
            ],
            [
              -83.1983,
              30.348279
            ],
            [
              -83.212755,
              30.344341
            ],
            [
              -83.209924,
              30.311878
            ],
            [
              -83.228016,
              30.299326
            ],
            [
              -83.247253,
              30.26067
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "075",
      "COUNTYNS": "00295724",
      "AFFGEOID": "0500000US12075",
      "GEOID": "12075",
      "NAME": "Levy",
      "LSAD": "06",
      "ALAND": 2896206276,
      "AWATER": 762912636,
      "County_state": "Levy,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.1660914958036,
              29.2888802109754
            ],
            [
              -83.16073,
              29.286611
            ],
            [
              -83.149764,
              29.289768
            ],
            [
              -83.146445,
              29.289194
            ],
            [
              -83.128027,
              29.282733
            ],
            [
              -83.125567,
              29.278845
            ],
            [
              -83.107477,
              29.268889
            ],
            [
              -83.089013,
              29.266502
            ],
            [
              -83.077265,
              29.255331
            ],
            [
              -83.074734,
              29.247975
            ],
            [
              -83.087839,
              29.21642
            ],
            [
              -83.078986,
              29.196944
            ],
            [
              -83.065242,
              29.184489
            ],
            [
              -83.061162,
              29.176113
            ],
            [
              -83.060947,
              29.170959
            ],
            [
              -83.068249,
              29.153135
            ],
            [
              -83.056867,
              29.146263
            ],
            [
              -83.053207,
              29.130839
            ],
            [
              -83.030453,
              29.134023
            ],
            [
              -83.019071,
              29.141324
            ],
            [
              -83.018212,
              29.151417
            ],
            [
              -82.996144,
              29.178074
            ],
            [
              -82.991653,
              29.180664
            ],
            [
              -82.987162,
              29.180094
            ],
            [
              -82.979522,
              29.171817
            ],
            [
              -82.974676,
              29.17091
            ],
            [
              -82.945302,
              29.167821
            ],
            [
              -82.932405,
              29.167891
            ],
            [
              -82.922613,
              29.169769
            ],
            [
              -82.887211,
              29.161741
            ],
            [
              -82.858179,
              29.162275
            ],
            [
              -82.827073,
              29.158425
            ],
            [
              -82.804736,
              29.146624
            ],
            [
              -82.805703,
              29.129848
            ],
            [
              -82.798876,
              29.114504
            ],
            [
              -82.799117,
              29.110647
            ],
            [
              -82.801166,
              29.105103
            ],
            [
              -82.809483,
              29.10462
            ],
            [
              -82.823659,
              29.098902
            ],
            [
              -82.816925,
              29.076215
            ],
            [
              -82.780558,
              29.07358
            ],
            [
              -82.783328,
              29.064619
            ],
            [
              -82.759704,
              29.054192
            ],
            [
              -82.753513,
              29.026496
            ],
            [
              -82.759378,
              29.006619
            ],
            [
              -82.764055,
              28.999707
            ],
            [
              -82.760551,
              28.993087
            ],
            [
              -82.758906169124,
              28.993276729598797
            ],
            [
              -82.755421,
              29.001865
            ],
            [
              -82.728082,
              29.014823
            ],
            [
              -82.712039,
              29.030834
            ],
            [
              -82.689814,
              29.033962
            ],
            [
              -82.675472,
              29.026196
            ],
            [
              -82.652692,
              29.023035
            ],
            [
              -82.612653,
              29.009271
            ],
            [
              -82.602131,
              29.013451
            ],
            [
              -82.593616,
              29.030039
            ],
            [
              -82.576974,
              29.028065
            ],
            [
              -82.535591,
              29.044855
            ],
            [
              -82.53486,
              29.21468
            ],
            [
              -82.44005,
              29.21523
            ],
            [
              -82.403237,
              29.215623
            ],
            [
              -82.405478,
              29.361097
            ],
            [
              -82.40662,
              29.485048
            ],
            [
              -82.496331,
              29.478371
            ],
            [
              -82.556766,
              29.480055
            ],
            [
              -82.556511,
              29.511982
            ],
            [
              -82.557565,
              29.537606
            ],
            [
              -82.656331,
              29.53562
            ],
            [
              -82.656301,
              29.564811
            ],
            [
              -82.756458,
              29.562467
            ],
            [
              -82.756868,
              29.57768
            ],
            [
              -82.77291,
              29.584879
            ],
            [
              -82.856612,
              29.591315
            ],
            [
              -82.937329,
              29.591196
            ],
            [
              -82.944462,
              29.582302
            ],
            [
              -82.950048,
              29.55611
            ],
            [
              -82.975976,
              29.541277
            ],
            [
              -82.969401,
              29.522315
            ],
            [
              -82.981429,
              29.517642
            ],
            [
              -82.984371,
              29.471004
            ],
            [
              -83.000842,
              29.46213
            ],
            [
              -83.026074,
              29.420018
            ],
            [
              -83.022901,
              29.399257
            ],
            [
              -83.035866,
              29.388198
            ],
            [
              -83.065602,
              29.372664
            ],
            [
              -83.063262,
              29.361818
            ],
            [
              -83.072051,
              29.353862
            ],
            [
              -83.060528,
              29.349457
            ],
            [
              -83.090389,
              29.332753
            ],
            [
              -83.098798,
              29.336865
            ],
            [
              -83.10452,
              29.32544
            ],
            [
              -83.119523,
              29.317153
            ],
            [
              -83.13957,
              29.323985
            ],
            [
              -83.148028,
              29.307769
            ],
            [
              -83.1660914958036,
              29.2888802109754
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "089",
      "COUNTYNS": "00295730",
      "AFFGEOID": "0500000US12089",
      "GEOID": "12089",
      "NAME": "Nassau",
      "LSAD": "06",
      "ALAND": 1679966574,
      "AWATER": 200159166,
      "County_state": "Nassau,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.050454,
              30.676263
            ],
            [
              -82.049529,
              30.655544
            ],
            [
              -82.046136,
              30.651763
            ],
            [
              -82.042293,
              30.649448
            ],
            [
              -82.039616,
              30.643305
            ],
            [
              -82.039113,
              30.641128
            ],
            [
              -82.039962,
              30.63714
            ],
            [
              -82.03763,
              30.633266
            ],
            [
              -82.033948,
              30.629598
            ],
            [
              -82.02852,
              30.621824
            ],
            [
              -82.026562,
              30.613298
            ],
            [
              -82.027359,
              30.60672
            ],
            [
              -82.026962,
              30.606147
            ],
            [
              -82.016523,
              30.602478
            ],
            [
              -82.015728,
              30.601698
            ],
            [
              -82.012129,
              30.593767
            ],
            [
              -82.008111,
              30.577011
            ],
            [
              -82.005497,
              30.563488
            ],
            [
              -82.013236,
              30.550083
            ],
            [
              -82.018381,
              30.531176
            ],
            [
              -82.018888,
              30.523819
            ],
            [
              -82.01701,
              30.519349
            ],
            [
              -82.015846,
              30.518157
            ],
            [
              -82.01479,
              30.513
            ],
            [
              -82.015912,
              30.495489
            ],
            [
              -82.018242,
              30.492075
            ],
            [
              -82.017317,
              30.487628
            ],
            [
              -82.017002,
              30.478769
            ],
            [
              -82.017799,
              30.475071
            ],
            [
              -82.023754,
              30.467278
            ],
            [
              -82.025477,
              30.457744
            ],
            [
              -82.028232,
              30.447385
            ],
            [
              -82.030084,
              30.444842
            ],
            [
              -82.036224,
              30.438448
            ],
            [
              -82.03723,
              30.434506
            ],
            [
              -82.034484,
              30.428036
            ],
            [
              -82.034025,
              30.422345
            ],
            [
              -82.039992,
              30.414267
            ],
            [
              -82.041185,
              30.409953
            ],
            [
              -82.042011,
              30.403253
            ],
            [
              -82.041185,
              30.396828
            ],
            [
              -82.035891,
              30.385273
            ],
            [
              -82.036845,
              30.37787
            ],
            [
              -82.040766,
              30.370144
            ],
            [
              -82.047938,
              30.363251
            ],
            [
              -82.04996655046949,
              30.3623762347418
            ],
            [
              -82.049236,
              30.273432
            ],
            [
              -82.037542,
              30.283457
            ],
            [
              -81.935495,
              30.373981
            ],
            [
              -81.831227,
              30.467437
            ],
            [
              -81.809526,
              30.484537
            ],
            [
              -81.794918,
              30.505406
            ],
            [
              -81.781624,
              30.503336
            ],
            [
              -81.765322,
              30.51844
            ],
            [
              -81.763748,
              30.534916
            ],
            [
              -81.738424,
              30.534572
            ],
            [
              -81.73521,
              30.559031
            ],
            [
              -81.721883,
              30.571934
            ],
            [
              -81.704592,
              30.563643
            ],
            [
              -81.69756,
              30.569643
            ],
            [
              -81.667833,
              30.560671
            ],
            [
              -81.666669,
              30.551701
            ],
            [
              -81.649078,
              30.576795
            ],
            [
              -81.624513,
              30.586232
            ],
            [
              -81.604608,
              30.584864
            ],
            [
              -81.608717,
              30.574892
            ],
            [
              -81.60146,
              30.567665
            ],
            [
              -81.614269,
              30.562383
            ],
            [
              -81.610212,
              30.55438
            ],
            [
              -81.592127,
              30.565326
            ],
            [
              -81.583239,
              30.564489
            ],
            [
              -81.588803,
              30.550468
            ],
            [
              -81.577218,
              30.538037
            ],
            [
              -81.555251,
              30.544528
            ],
            [
              -81.543018,
              30.523889
            ],
            [
              -81.530238,
              30.52629
            ],
            [
              -81.521375,
              30.538801
            ],
            [
              -81.523814,
              30.556337
            ],
            [
              -81.514014,
              30.567337
            ],
            [
              -81.499575,
              30.563793
            ],
            [
              -81.479939,
              30.533454
            ],
            [
              -81.450811,
              30.519238
            ],
            [
              -81.4427844968023,
              30.5099198266455
            ],
            [
              -81.434064,
              30.522569
            ],
            [
              -81.442564,
              30.555189
            ],
            [
              -81.443099,
              30.600938
            ],
            [
              -81.430843,
              30.669393
            ],
            [
              -81.42742,
              30.69802
            ],
            [
              -81.432725,
              30.703017
            ],
            [
              -81.444124,
              30.709714
            ],
            [
              -81.448718,
              30.709353
            ],
            [
              -81.459979,
              30.710434
            ],
            [
              -81.464466,
              30.711045
            ],
            [
              -81.472598,
              30.713312
            ],
            [
              -81.475755,
              30.714754
            ],
            [
              -81.483788,
              30.723891
            ],
            [
              -81.487334,
              30.726081
            ],
            [
              -81.489539,
              30.7261
            ],
            [
              -81.507218,
              30.722936
            ],
            [
              -81.516119,
              30.722236
            ],
            [
              -81.52142,
              30.722536
            ],
            [
              -81.528281,
              30.723359
            ],
            [
              -81.530534,
              30.722858
            ],
            [
              -81.532788,
              30.721606
            ],
            [
              -81.53404,
              30.719853
            ],
            [
              -81.53452,
              30.717936
            ],
            [
              -81.535542,
              30.716348
            ],
            [
              -81.537671,
              30.714345
            ],
            [
              -81.539298,
              30.713468
            ],
            [
              -81.540927,
              30.713343
            ],
            [
              -81.542679,
              30.713593
            ],
            [
              -81.544683,
              30.713969
            ],
            [
              -81.546936,
              30.714345
            ],
            [
              -81.54919,
              30.715972
            ],
            [
              -81.55257,
              30.716974
            ],
            [
              -81.56171,
              30.715597
            ],
            [
              -81.566223,
              30.717836
            ],
            [
              -81.571424,
              30.721636
            ],
            [
              -81.573724,
              30.722336
            ],
            [
              -81.586825,
              30.723735
            ],
            [
              -81.593654,
              30.725459
            ],
            [
              -81.601212,
              30.728141
            ],
            [
              -81.604016,
              30.727287
            ],
            [
              -81.605722,
              30.725337
            ],
            [
              -81.607673,
              30.721924
            ],
            [
              -81.609501,
              30.720705
            ],
            [
              -81.617669,
              30.722046
            ],
            [
              -81.619619,
              30.724849
            ],
            [
              -81.620829,
              30.729535
            ],
            [
              -81.621936,
              30.731188
            ],
            [
              -81.625105,
              30.733017
            ],
            [
              -81.629616,
              30.732407
            ],
            [
              -81.633273,
              30.729603
            ],
            [
              -81.646144,
              30.727591
            ],
            [
              -81.649196,
              30.728686
            ],
            [
              -81.650448,
              30.729703
            ],
            [
              -81.651778,
              30.732284
            ],
            [
              -81.652169,
              30.735648
            ],
            [
              -81.651731,
              30.740235
            ],
            [
              -81.652131,
              30.742436
            ],
            [
              -81.654542677328,
              30.743897854206498
            ],
            [
              -81.656549,
              30.745114
            ],
            [
              -81.662181,
              30.746522
            ],
            [
              -81.664606,
              30.7466
            ],
            [
              -81.667344,
              30.745661
            ],
            [
              -81.668283,
              30.744644
            ],
            [
              -81.669332,
              30.741335
            ],
            [
              -81.670132,
              30.740235
            ],
            [
              -81.672832,
              30.738935
            ],
            [
              -81.688934,
              30.741434
            ],
            [
              -81.690999,
              30.742841
            ],
            [
              -81.691827,
              30.743991
            ],
            [
              -81.692824,
              30.747101
            ],
            [
              -81.694787,
              30.748415
            ],
            [
              -81.719937,
              30.744634
            ],
            [
              -81.727137,
              30.746935
            ],
            [
              -81.732238,
              30.749635
            ],
            [
              -81.742747,
              30.759202
            ],
            [
              -81.743105,
              30.759913
            ],
            [
              -81.743449,
              30.762272
            ],
            [
              -81.744194,
              30.763869
            ],
            [
              -81.745046,
              30.76504
            ],
            [
              -81.746323,
              30.765892
            ],
            [
              -81.747583,
              30.766456
            ],
            [
              -81.751294,
              30.767083
            ],
            [
              -81.755086,
              30.76832
            ],
            [
              -81.75935,
              30.771378
            ],
            [
              -81.763384,
              30.773821
            ],
            [
              -81.768204,
              30.773955
            ],
            [
              -81.77048,
              30.772482
            ],
            [
              -81.772623,
              30.769536
            ],
            [
              -81.775033,
              30.768331
            ],
            [
              -81.779183,
              30.768063
            ],
            [
              -81.782666,
              30.769938
            ],
            [
              -81.784363,
              30.773591
            ],
            [
              -81.792782,
              30.784434
            ],
            [
              -81.806666,
              30.789685
            ],
            [
              -81.808543,
              30.790016
            ],
            [
              -81.827028,
              30.788935
            ],
            [
              -81.84039,
              30.786386
            ],
            [
              -81.842073,
              30.787122
            ],
            [
              -81.846301,
              30.79055
            ],
            [
              -81.852641,
              30.794441
            ],
            [
              -81.868624,
              30.792756
            ],
            [
              -81.876898,
              30.799518
            ],
            [
              -81.882741,
              30.805126
            ],
            [
              -81.891298,
              30.815948
            ],
            [
              -81.892921,
              30.819271
            ],
            [
              -81.895737,
              30.821101
            ],
            [
              -81.899397,
              30.821665
            ],
            [
              -81.902354,
              30.82082
            ],
            [
              -81.903762,
              30.818989
            ],
            [
              -81.906296,
              30.817018
            ],
            [
              -81.910943,
              30.815891
            ],
            [
              -81.924466,
              30.817568
            ],
            [
              -81.934673,
              30.820427
            ],
            [
              -81.935462,
              30.821134
            ],
            [
              -81.9384,
              30.825748
            ],
            [
              -81.943187,
              30.827437
            ],
            [
              -81.949806,
              30.827496
            ],
            [
              -81.959778,
              30.821171
            ],
            [
              -81.962194,
              30.818003
            ],
            [
              -81.962758,
              30.813638
            ],
            [
              -81.96246,
              30.808443
            ],
            [
              -81.962008,
              30.800445
            ],
            [
              -81.962553,
              30.796528
            ],
            [
              -81.973876,
              30.778488
            ],
            [
              -81.9789666841348,
              30.776461506719002
            ],
            [
              -81.979081,
              30.776416
            ],
            [
              -81.981293,
              30.776768
            ],
            [
              -81.988625,
              30.780057
            ],
            [
              -81.990875,
              30.781612
            ],
            [
              -81.994992,
              30.786074
            ],
            [
              -82.004994,
              30.791745
            ],
            [
              -82.007886,
              30.792938
            ],
            [
              -82.017072,
              30.791658
            ],
            [
              -82.022887,
              30.787992
            ],
            [
              -82.023869,
              30.786686
            ],
            [
              -82.024056,
              30.783157
            ],
            [
              -82.017902,
              30.775845
            ],
            [
              -82.011618,
              30.763122
            ],
            [
              -82.012681,
              30.761289
            ],
            [
              -82.017938,
              30.755263
            ],
            [
              -82.028421,
              30.750981
            ],
            [
              -82.032667,
              30.750674
            ],
            [
              -82.035986,
              30.750998
            ],
            [
              -82.038989,
              30.749262
            ],
            [
              -82.039656,
              30.747727
            ],
            [
              -82.040048,
              30.737547
            ],
            [
              -82.04119,
              30.734247
            ],
            [
              -82.043817,
              30.72964
            ],
            [
              -82.041032,
              30.725079
            ],
            [
              -82.039176,
              30.723177
            ],
            [
              -82.037585,
              30.718639
            ],
            [
              -82.036448,
              30.706583
            ],
            [
              -82.041834,
              30.692374
            ],
            [
              -82.050454,
              30.676263
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "079",
      "COUNTYNS": "00295731",
      "AFFGEOID": "0500000US12079",
      "GEOID": "12079",
      "NAME": "Madison",
      "LSAD": "06",
      "ALAND": 1802951600,
      "AWATER": 50683945,
      "County_state": "Madison,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.820521,
              30.313144
            ],
            [
              -83.820235,
              30.30354
            ],
            [
              -83.468768,
              30.304212
            ],
            [
              -83.469267,
              30.260351
            ],
            [
              -83.36726,
              30.26044
            ],
            [
              -83.247253,
              30.26067
            ],
            [
              -83.228016,
              30.299326
            ],
            [
              -83.209924,
              30.311878
            ],
            [
              -83.212755,
              30.344341
            ],
            [
              -83.1983,
              30.348279
            ],
            [
              -83.192343,
              30.374846
            ],
            [
              -83.170969,
              30.385225
            ],
            [
              -83.180043,
              30.410202
            ],
            [
              -83.195522,
              30.416916
            ],
            [
              -83.207287,
              30.413919
            ],
            [
              -83.221868,
              30.42341
            ],
            [
              -83.217659,
              30.455857
            ],
            [
              -83.225866,
              30.468021
            ],
            [
              -83.243766,
              30.473132
            ],
            [
              -83.239476,
              30.515108
            ],
            [
              -83.246867,
              30.540022
            ],
            [
              -83.260807,
              30.552821
            ],
            [
              -83.255991,
              30.559437
            ],
            [
              -83.262733,
              30.580955
            ],
            [
              -83.256619,
              30.5862
            ],
            [
              -83.2723449732595,
              30.632217954571797
            ],
            [
              -83.309256456485,
              30.634393291556
            ],
            [
              -83.309523,
              30.634409
            ],
            [
              -83.311715,
              30.634569
            ],
            [
              -83.34108,
              30.636338
            ],
            [
              -83.3577037830355,
              30.6373471003798
            ],
            [
              -83.37953,
              30.638672
            ],
            [
              -83.390133,
              30.639325
            ],
            [
              -83.429549,
              30.641511
            ],
            [
              -83.429656,
              30.641488
            ],
            [
              -83.440094,
              30.642015
            ],
            [
              -83.448968,
              30.642402
            ],
            [
              -83.499951,
              30.645663
            ],
            [
              -83.6117204626719,
              30.651244724675497
            ],
            [
              -83.604401,
              30.638236
            ],
            [
              -83.604186,
              30.581916
            ],
            [
              -83.62073,
              30.58198
            ],
            [
              -83.621189,
              30.567351
            ],
            [
              -83.637343,
              30.567471
            ],
            [
              -83.637412,
              30.552671
            ],
            [
              -83.671117,
              30.552861
            ],
            [
              -83.671073,
              30.53802
            ],
            [
              -83.704293,
              30.538094
            ],
            [
              -83.70436,
              30.523021
            ],
            [
              -83.726036,
              30.522951
            ],
            [
              -83.736954,
              30.507569
            ],
            [
              -83.727729,
              30.49024
            ],
            [
              -83.728339,
              30.45459
            ],
            [
              -83.72172,
              30.434354
            ],
            [
              -83.751073,
              30.407598
            ],
            [
              -83.757261,
              30.385467
            ],
            [
              -83.788396,
              30.382206
            ],
            [
              -83.795007,
              30.386081
            ],
            [
              -83.806667,
              30.371689
            ],
            [
              -83.804086,
              30.353094
            ],
            [
              -83.787107,
              30.331672
            ],
            [
              -83.79295,
              30.31901
            ],
            [
              -83.820521,
              30.313144
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "023",
      "COUNTYNS": "00306909",
      "AFFGEOID": "0500000US12023",
      "GEOID": "12023",
      "NAME": "Columbia",
      "LSAD": "06",
      "ALAND": 2065628007,
      "AWATER": 9773963,
      "County_state": "Columbia,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.800477,
              29.932126
            ],
            [
              -82.791854,
              29.935453
            ],
            [
              -82.780486,
              29.925753
            ],
            [
              -82.770379,
              29.928109
            ],
            [
              -82.774296,
              29.911702
            ],
            [
              -82.768445,
              29.897007
            ],
            [
              -82.758321,
              29.89874
            ],
            [
              -82.752854,
              29.880206
            ],
            [
              -82.736237,
              29.860133
            ],
            [
              -82.711086,
              29.847475
            ],
            [
              -82.693668,
              29.834216
            ],
            [
              -82.658554,
              29.830144
            ],
            [
              -82.651494,
              29.825587
            ],
            [
              -82.634734,
              29.833596
            ],
            [
              -82.631251,
              29.849112
            ],
            [
              -82.61108,
              29.852117
            ],
            [
              -82.586485,
              29.866702
            ],
            [
              -82.592553,
              29.887515
            ],
            [
              -82.575876,
              29.894865
            ],
            [
              -82.578786,
              29.915362
            ],
            [
              -82.554735,
              29.928541
            ],
            [
              -82.541554,
              29.925495
            ],
            [
              -82.529705,
              29.940881
            ],
            [
              -82.533301,
              29.958357
            ],
            [
              -82.555357,
              29.964019
            ],
            [
              -82.56928,
              29.97498
            ],
            [
              -82.575991,
              29.990857
            ],
            [
              -82.573116,
              30.010651
            ],
            [
              -82.544961,
              30.019995
            ],
            [
              -82.535415,
              30.055061
            ],
            [
              -82.519019,
              30.076011
            ],
            [
              -82.498186,
              30.089026
            ],
            [
              -82.468172,
              30.096968
            ],
            [
              -82.455985,
              30.105584
            ],
            [
              -82.452518,
              30.123887
            ],
            [
              -82.458364,
              30.136449
            ],
            [
              -82.458352,
              30.164595
            ],
            [
              -82.458316,
              30.442757
            ],
            [
              -82.45979242039849,
              30.5842766709258
            ],
            [
              -82.524938,
              30.588181
            ],
            [
              -82.536272,
              30.588877
            ],
            [
              -82.545095,
              30.589353
            ],
            [
              -82.553199,
              30.589926
            ],
            [
              -82.565516,
              30.590614
            ],
            [
              -82.569278,
              30.590957
            ],
            [
              -82.58400213818601,
              30.5917854612315
            ],
            [
              -82.689316,
              30.597711
            ],
            [
              -82.6895370493431,
              30.597723132054398
            ],
            [
              -82.706656,
              30.584318
            ],
            [
              -82.706724,
              30.574274
            ],
            [
              -82.72668,
              30.55925
            ],
            [
              -82.714468,
              30.531892
            ],
            [
              -82.727738,
              30.530396
            ],
            [
              -82.715014,
              30.505468
            ],
            [
              -82.707897,
              30.506514
            ],
            [
              -82.697944,
              30.474744
            ],
            [
              -82.68201,
              30.471052
            ],
            [
              -82.680214,
              30.461012
            ],
            [
              -82.66512,
              30.453322
            ],
            [
              -82.670228,
              30.444356
            ],
            [
              -82.652749,
              30.42944
            ],
            [
              -82.647198,
              30.394797
            ],
            [
              -82.657814,
              30.391489
            ],
            [
              -82.662281,
              30.370351
            ],
            [
              -82.686744,
              30.352443
            ],
            [
              -82.681473,
              30.342805
            ],
            [
              -82.689922,
              30.333109
            ],
            [
              -82.703126,
              30.332614
            ],
            [
              -82.717773,
              30.321391
            ],
            [
              -82.724638,
              30.333796
            ],
            [
              -82.738443,
              30.325783
            ],
            [
              -82.746741,
              30.317426
            ],
            [
              -82.762994,
              30.330855
            ],
            [
              -82.788503,
              30.324013
            ],
            [
              -82.794594,
              30.337024
            ],
            [
              -82.793975,
              29.986202
            ],
            [
              -82.760411,
              29.986205
            ],
            [
              -82.761519,
              29.965517
            ],
            [
              -82.785838,
              29.954741
            ],
            [
              -82.800477,
              29.932126
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "013",
      "COUNTYNS": "00306919",
      "AFFGEOID": "0500000US12013",
      "GEOID": "12013",
      "NAME": "Calhoun",
      "LSAD": "06",
      "ALAND": 1469389207,
      "AWATER": 18074798,
      "County_state": "Calhoun,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.388587,
              30.348556
            ],
            [
              -85.389679,
              30.200973
            ],
            [
              -85.383066,
              30.201339
            ],
            [
              -85.11566,
              30.199976
            ],
            [
              -85.11823,
              30.210491
            ],
            [
              -85.102962,
              30.211726
            ],
            [
              -85.092076,
              30.227568
            ],
            [
              -85.081336,
              30.228531
            ],
            [
              -85.076768,
              30.250989
            ],
            [
              -85.059092,
              30.263229
            ],
            [
              -85.063951,
              30.270863
            ],
            [
              -85.052777,
              30.287406
            ],
            [
              -85.057253,
              30.301301
            ],
            [
              -85.047564,
              30.312791
            ],
            [
              -85.03429,
              30.312478
            ],
            [
              -85.046271,
              30.32017
            ],
            [
              -85.049744,
              30.336544
            ],
            [
              -85.04277,
              30.352466
            ],
            [
              -85.027898,
              30.360843
            ],
            [
              -85.040199,
              30.372089
            ],
            [
              -85.026242,
              30.375589
            ],
            [
              -85.014572,
              30.394269
            ],
            [
              -85.027303,
              30.405603
            ],
            [
              -85.014269,
              30.409018
            ],
            [
              -85.032931,
              30.420299
            ],
            [
              -85.015176,
              30.434361
            ],
            [
              -84.983203,
              30.441837
            ],
            [
              -84.999916,
              30.456199
            ],
            [
              -84.987441,
              30.464901
            ],
            [
              -84.999916,
              30.472418
            ],
            [
              -84.991013,
              30.504295
            ],
            [
              -84.990017,
              30.524795
            ],
            [
              -84.973718,
              30.552994
            ],
            [
              -84.962218,
              30.563594
            ],
            [
              -84.965418,
              30.580194
            ],
            [
              -84.946818,
              30.579894
            ],
            [
              -84.944919,
              30.595493
            ],
            [
              -84.932519,
              30.606393
            ],
            [
              -85.167687,
              30.607979
            ],
            [
              -85.171891,
              30.564336
            ],
            [
              -85.383948,
              30.566856
            ],
            [
              -85.388587,
              30.348556
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "017",
      "COUNTYNS": "00295752",
      "AFFGEOID": "0500000US12017",
      "GEOID": "12017",
      "NAME": "Citrus",
      "LSAD": "06",
      "ALAND": 1507111917,
      "AWATER": 495578435,
      "County_state": "Citrus,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.755421,
              29.001865
            ],
            [
              -82.758906169124,
              28.993276729598797
            ],
            [
              -82.737872,
              28.995703
            ],
            [
              -82.735754,
              28.973709
            ],
            [
              -82.723861,
              28.953506
            ],
            [
              -82.708793,
              28.935979
            ],
            [
              -82.702618,
              28.932955
            ],
            [
              -82.688864,
              28.905609
            ],
            [
              -82.730245,
              28.850155
            ],
            [
              -82.698281,
              28.75701
            ],
            [
              -82.712373,
              28.720921
            ],
            [
              -82.668722,
              28.695658
            ],
            [
              -82.6688895903084,
              28.6943024445354
            ],
            [
              -82.507397,
              28.694671
            ],
            [
              -82.418353,
              28.694859
            ],
            [
              -82.417785,
              28.665898
            ],
            [
              -82.263052,
              28.667634
            ],
            [
              -82.25997,
              28.682723
            ],
            [
              -82.248603,
              28.701494
            ],
            [
              -82.249076,
              28.717772
            ],
            [
              -82.210702,
              28.754063
            ],
            [
              -82.189439,
              28.758969
            ],
            [
              -82.184148,
              28.783321
            ],
            [
              -82.169141,
              28.792954
            ],
            [
              -82.179307,
              28.803316
            ],
            [
              -82.182602,
              28.821785
            ],
            [
              -82.205469,
              28.835132
            ],
            [
              -82.2224,
              28.852737
            ],
            [
              -82.236224,
              28.860583
            ],
            [
              -82.242235,
              28.876425
            ],
            [
              -82.273585,
              28.897216
            ],
            [
              -82.277853,
              28.91119
            ],
            [
              -82.295679,
              28.93804
            ],
            [
              -82.297658,
              28.949002
            ],
            [
              -82.311697,
              28.960391
            ],
            [
              -82.341364,
              28.987776
            ],
            [
              -82.341887,
              28.987845
            ],
            [
              -82.342901,
              28.987244
            ],
            [
              -82.345585,
              28.98706
            ],
            [
              -82.346702,
              28.987755
            ],
            [
              -82.348339,
              28.98789
            ],
            [
              -82.349293,
              28.988393
            ],
            [
              -82.350285,
              28.989492
            ],
            [
              -82.352394,
              28.991242
            ],
            [
              -82.35527,
              28.991345
            ],
            [
              -82.356336,
              28.991703
            ],
            [
              -82.357609,
              28.993144
            ],
            [
              -82.358711,
              28.993341
            ],
            [
              -82.360954,
              28.99338
            ],
            [
              -82.361575,
              28.993649
            ],
            [
              -82.362119,
              28.994327
            ],
            [
              -82.362074,
              28.995671
            ],
            [
              -82.36318,
              28.996465
            ],
            [
              -82.362763,
              28.998929
            ],
            [
              -82.393503,
              29.008006
            ],
            [
              -82.401415,
              29.018606
            ],
            [
              -82.418821,
              29.013246
            ],
            [
              -82.426997,
              29.021385
            ],
            [
              -82.458566,
              29.04765
            ],
            [
              -82.487118,
              29.048375
            ],
            [
              -82.508131,
              29.037479
            ],
            [
              -82.535591,
              29.044855
            ],
            [
              -82.576974,
              29.028065
            ],
            [
              -82.593616,
              29.030039
            ],
            [
              -82.602131,
              29.013451
            ],
            [
              -82.612653,
              29.009271
            ],
            [
              -82.652692,
              29.023035
            ],
            [
              -82.675472,
              29.026196
            ],
            [
              -82.689814,
              29.033962
            ],
            [
              -82.712039,
              29.030834
            ],
            [
              -82.728082,
              29.014823
            ],
            [
              -82.755421,
              29.001865
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "037",
      "COUNTYNS": "00306911",
      "AFFGEOID": "0500000US12037",
      "GEOID": "12037",
      "NAME": "Franklin",
      "LSAD": "06",
      "ALAND": 1411918808,
      "AWATER": 1275529258,
      "County_state": "Franklin,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.669555,
              29.794712
            ],
            [
              -84.681439,
              29.783367
            ],
            [
              -84.662425,
              29.773052
            ],
            [
              -84.624396,
              29.77821
            ],
            [
              -84.583991,
              29.793681
            ],
            [
              -84.564976,
              29.81018
            ],
            [
              -84.564976,
              29.834925
            ],
            [
              -84.57686,
              29.836987
            ],
            [
              -84.598251,
              29.820491
            ],
            [
              -84.638657,
              29.8009
            ],
            [
              -84.669555,
              29.794712
            ]
          ]
        ],
        [
          [
            [
              -85.222414,
              29.68958
            ],
            [
              -85.2250062255299,
              29.6780789361752
            ],
            [
              -85.221608,
              29.677764
            ],
            [
              -85.14101,
              29.634038
            ],
            [
              -85.084231,
              29.615163
            ],
            [
              -85.045074,
              29.586991
            ],
            [
              -85.004452,
              29.598825
            ],
            [
              -84.948404,
              29.622598
            ],
            [
              -84.876731,
              29.655758
            ],
            [
              -84.776954,
              29.692191
            ],
            [
              -84.692619,
              29.763039
            ],
            [
              -84.70188,
              29.774187
            ],
            [
              -84.751792,
              29.738077
            ],
            [
              -84.798587,
              29.702074
            ],
            [
              -84.901531,
              29.667884
            ],
            [
              -85.012052,
              29.622439
            ],
            [
              -85.070046,
              29.64093
            ],
            [
              -85.074308,
              29.673981
            ],
            [
              -85.100408,
              29.68469
            ],
            [
              -85.121473,
              29.715854
            ],
            [
              -85.101682,
              29.718748
            ],
            [
              -85.072123,
              29.719027
            ],
            [
              -85.037212,
              29.711074
            ],
            [
              -84.993264,
              29.714961
            ],
            [
              -84.977004,
              29.721209
            ],
            [
              -84.968841,
              29.72708
            ],
            [
              -84.964007,
              29.742422
            ],
            [
              -84.946595,
              29.745032
            ],
            [
              -84.93837,
              29.750211
            ],
            [
              -84.920917,
              29.772901
            ],
            [
              -84.91511,
              29.783303
            ],
            [
              -84.90413,
              29.786279
            ],
            [
              -84.893992,
              29.785176
            ],
            [
              -84.877111,
              29.772888
            ],
            [
              -84.890066,
              29.755802
            ],
            [
              -84.901781,
              29.735723
            ],
            [
              -84.892493,
              29.72266
            ],
            [
              -84.888031,
              29.722406
            ],
            [
              -84.881777,
              29.733882
            ],
            [
              -84.868271,
              29.742454
            ],
            [
              -84.837168,
              29.755926
            ],
            [
              -84.824197,
              29.758288
            ],
            [
              -84.762998,
              29.788846
            ],
            [
              -84.755595,
              29.78854
            ],
            [
              -84.730327,
              29.8069
            ],
            [
              -84.692053,
              29.829059
            ],
            [
              -84.683934,
              29.831327
            ],
            [
              -84.669728,
              29.82891
            ],
            [
              -84.65645,
              29.834277
            ],
            [
              -84.656318,
              29.837943
            ],
            [
              -84.647958,
              29.847104
            ],
            [
              -84.613154,
              29.867984
            ],
            [
              -84.603303,
              29.876117
            ],
            [
              -84.57744,
              29.887828
            ],
            [
              -84.535873,
              29.910092
            ],
            [
              -84.511996,
              29.916574
            ],
            [
              -84.494562,
              29.913957
            ],
            [
              -84.470323,
              29.924524
            ],
            [
              -84.451705,
              29.929085
            ],
            [
              -84.443652,
              29.913785
            ],
            [
              -84.434287,
              29.906328
            ],
            [
              -84.423834,
              29.902996
            ],
            [
              -84.404958,
              29.901229
            ],
            [
              -84.378937,
              29.893112
            ],
            [
              -84.349066,
              29.896812
            ],
            [
              -84.343389,
              29.899539
            ],
            [
              -84.335953,
              29.912962
            ],
            [
              -84.333746,
              29.923721
            ],
            [
              -84.336511,
              29.942508
            ],
            [
              -84.339426,
              29.946007
            ],
            [
              -84.3412610838955,
              29.960775491222797
            ],
            [
              -84.437406,
              29.977028
            ],
            [
              -84.444191,
              29.981799
            ],
            [
              -84.452486,
              29.971247
            ],
            [
              -84.467713,
              30.001853
            ],
            [
              -84.491511,
              29.987117
            ],
            [
              -84.51413,
              29.989076
            ],
            [
              -84.523992,
              29.981728
            ],
            [
              -84.51896,
              29.995129
            ],
            [
              -84.531218,
              30.007366
            ],
            [
              -84.54445,
              30.01103
            ],
            [
              -85.007626,
              30.013883
            ],
            [
              -85.005234,
              30.011034
            ],
            [
              -85.025439,
              29.971399
            ],
            [
              -85.017939,
              29.950973
            ],
            [
              -85.053757,
              29.910296
            ],
            [
              -85.051302,
              29.893089
            ],
            [
              -85.041214,
              29.875852
            ],
            [
              -85.032573,
              29.83007
            ],
            [
              -85.021421,
              29.823213
            ],
            [
              -85.020534,
              29.807373
            ],
            [
              -85.036005,
              29.792371
            ],
            [
              -85.039355,
              29.78017
            ],
            [
              -85.06867,
              29.784467
            ],
            [
              -85.079193,
              29.768617
            ],
            [
              -85.109401,
              29.776793
            ],
            [
              -85.206226,
              29.711188
            ],
            [
              -85.222414,
              29.68958
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "039",
      "COUNTYNS": "00306918",
      "AFFGEOID": "0500000US12039",
      "GEOID": "12039",
      "NAME": "Gadsden",
      "LSAD": "06",
      "ALAND": 1337193230,
      "AWATER": 31687293,
      "County_state": "Gadsden,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.908119,
              30.619493
            ],
            [
              -84.932519,
              30.606393
            ],
            [
              -84.883316,
              30.605589
            ],
            [
              -84.882616,
              30.533094
            ],
            [
              -84.815816,
              30.533093
            ],
            [
              -84.815618,
              30.518491
            ],
            [
              -84.781813,
              30.518693
            ],
            [
              -84.781111,
              30.459794
            ],
            [
              -84.714097,
              30.460401
            ],
            [
              -84.714124,
              30.417199
            ],
            [
              -84.680525,
              30.417269
            ],
            [
              -84.680632,
              30.387973
            ],
            [
              -84.646408,
              30.388281
            ],
            [
              -84.647668,
              30.394612
            ],
            [
              -84.60692,
              30.416136
            ],
            [
              -84.591357,
              30.411751
            ],
            [
              -84.568828,
              30.426861
            ],
            [
              -84.561463,
              30.437912
            ],
            [
              -84.559462,
              30.434025
            ],
            [
              -84.529375,
              30.44083
            ],
            [
              -84.519796,
              30.451499
            ],
            [
              -84.420474,
              30.463905
            ],
            [
              -84.407955,
              30.473094
            ],
            [
              -84.397233,
              30.483321
            ],
            [
              -84.404956,
              30.507737
            ],
            [
              -84.388303,
              30.526218
            ],
            [
              -84.388336,
              30.526709
            ],
            [
              -84.389414,
              30.527277
            ],
            [
              -84.3897,
              30.527898
            ],
            [
              -84.389611,
              30.52872
            ],
            [
              -84.388804,
              30.52939
            ],
            [
              -84.38949,
              30.529642
            ],
            [
              -84.390992,
              30.529416
            ],
            [
              -84.39108,
              30.53068
            ],
            [
              -84.391587,
              30.531492
            ],
            [
              -84.391453,
              30.532717
            ],
            [
              -84.390948,
              30.533672
            ],
            [
              -84.390878,
              30.535168
            ],
            [
              -84.390174,
              30.537437
            ],
            [
              -84.389222,
              30.540958
            ],
            [
              -84.388282,
              30.543005
            ],
            [
              -84.388253,
              30.543801
            ],
            [
              -84.387534,
              30.54422
            ],
            [
              -84.387474,
              30.54498
            ],
            [
              -84.388069,
              30.545361
            ],
            [
              -84.388434,
              30.546295
            ],
            [
              -84.388284,
              30.549325
            ],
            [
              -84.387626,
              30.550345
            ],
            [
              -84.371403,
              30.581107
            ],
            [
              -84.336224,
              30.596048
            ],
            [
              -84.322658,
              30.620609
            ],
            [
              -84.303969,
              30.634585
            ],
            [
              -84.29993,
              30.642624
            ],
            [
              -84.305324,
              30.670208
            ],
            [
              -84.298073,
              30.676832
            ],
            [
              -84.301153,
              30.680139
            ],
            [
              -84.2825636638089,
              30.685307571749597
            ],
            [
              -84.375012,
              30.689785
            ],
            [
              -84.3807060980397,
              30.689956554824498
            ],
            [
              -84.474519,
              30.692783
            ],
            [
              -84.535155,
              30.696513
            ],
            [
              -84.539483,
              30.696765
            ],
            [
              -84.606364,
              30.699862
            ],
            [
              -84.606501,
              30.699855
            ],
            [
              -84.644932,
              30.701982
            ],
            [
              -84.836448,
              30.710699
            ],
            [
              -84.86346069871338,
              30.7115052207476
            ],
            [
              -84.864693,
              30.711542
            ],
            [
              -84.857921,
              30.698409
            ],
            [
              -84.88752,
              30.663792
            ],
            [
              -84.908119,
              30.619493
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "045",
      "COUNTYNS": "00306917",
      "AFFGEOID": "0500000US12045",
      "GEOID": "12045",
      "NAME": "Gulf",
      "LSAD": "06",
      "ALAND": 1433785086,
      "AWATER": 494266609,
      "County_state": "Gulf,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.416548,
              29.842628
            ],
            [
              -85.417971,
              29.828855
            ],
            [
              -85.413983,
              29.799865
            ],
            [
              -85.397871,
              29.740498
            ],
            [
              -85.380303,
              29.698485
            ],
            [
              -85.369419,
              29.681048
            ],
            [
              -85.352615,
              29.659787
            ],
            [
              -85.347711,
              29.66719
            ],
            [
              -85.343619,
              29.672004
            ],
            [
              -85.319215,
              29.681494
            ],
            [
              -85.29074,
              29.684081
            ],
            [
              -85.259719,
              29.681296
            ],
            [
              -85.2250062255299,
              29.6780789361752
            ],
            [
              -85.222414,
              29.68958
            ],
            [
              -85.206226,
              29.711188
            ],
            [
              -85.109401,
              29.776793
            ],
            [
              -85.079193,
              29.768617
            ],
            [
              -85.06867,
              29.784467
            ],
            [
              -85.039355,
              29.78017
            ],
            [
              -85.036005,
              29.792371
            ],
            [
              -85.020534,
              29.807373
            ],
            [
              -85.021421,
              29.823213
            ],
            [
              -85.032573,
              29.83007
            ],
            [
              -85.041214,
              29.875852
            ],
            [
              -85.051302,
              29.893089
            ],
            [
              -85.053757,
              29.910296
            ],
            [
              -85.017939,
              29.950973
            ],
            [
              -85.025439,
              29.971399
            ],
            [
              -85.029525,
              29.986823
            ],
            [
              -85.050625,
              29.990988
            ],
            [
              -85.054002,
              30.00128
            ],
            [
              -85.071919,
              30.004796
            ],
            [
              -85.085238,
              30.01469
            ],
            [
              -85.113691,
              30.023173
            ],
            [
              -85.135254,
              30.04327
            ],
            [
              -85.136849,
              30.086136
            ],
            [
              -85.154015,
              30.092043
            ],
            [
              -85.144928,
              30.099401
            ],
            [
              -85.149077,
              30.110495
            ],
            [
              -85.130182,
              30.119414
            ],
            [
              -85.144904,
              30.123149
            ],
            [
              -85.133203,
              30.145749
            ],
            [
              -85.139855,
              30.163499
            ],
            [
              -85.133629,
              30.181779
            ],
            [
              -85.11566,
              30.199976
            ],
            [
              -85.383066,
              30.201339
            ],
            [
              -85.389679,
              30.200973
            ],
            [
              -85.391387,
              30.022674
            ],
            [
              -85.3886772298017,
              29.9243554814616
            ],
            [
              -85.38473,
              29.920949
            ],
            [
              -85.363731,
              29.898915
            ],
            [
              -85.347044,
              29.871981
            ],
            [
              -85.336654,
              29.849295
            ],
            [
              -85.332289,
              29.845905
            ],
            [
              -85.325008,
              29.844966
            ],
            [
              -85.317464,
              29.838894
            ],
            [
              -85.312911,
              29.832273
            ],
            [
              -85.314783,
              29.830575
            ],
            [
              -85.314547,
              29.822279
            ],
            [
              -85.31142,
              29.814373
            ],
            [
              -85.304877,
              29.811096
            ],
            [
              -85.302591,
              29.808094
            ],
            [
              -85.301331,
              29.797117
            ],
            [
              -85.305998,
              29.725202
            ],
            [
              -85.323412,
              29.697826
            ],
            [
              -85.34005,
              29.691632
            ],
            [
              -85.356687,
              29.696793
            ],
            [
              -85.366195,
              29.710212
            ],
            [
              -85.378079,
              29.753554
            ],
            [
              -85.386397,
              29.788627
            ],
            [
              -85.388774,
              29.817501
            ],
            [
              -85.387586,
              29.845336
            ],
            [
              -85.387047,
              29.878546
            ],
            [
              -85.405815,
              29.865817
            ],
            [
              -85.413575,
              29.85294
            ],
            [
              -85.416548,
              29.842628
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "065",
      "COUNTYNS": "00295733",
      "AFFGEOID": "0500000US12065",
      "GEOID": "12065",
      "NAME": "Jefferson",
      "LSAD": "06",
      "ALAND": 1549056458,
      "AWATER": 99567329,
      "County_state": "Jefferson,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.075539,
              30.273617
            ],
            [
              -84.0760425771092,
              30.0954641099777
            ],
            [
              -84.06299,
              30.101378
            ],
            [
              -84.048715,
              30.103208
            ],
            [
              -84.024274,
              30.103271
            ],
            [
              -84.000716,
              30.096209
            ],
            [
              -83.991607,
              30.08392
            ],
            [
              -83.992184,
              30.096208
            ],
            [
              -83.968628,
              30.13617
            ],
            [
              -83.97306,
              30.146037
            ],
            [
              -83.960354,
              30.152398
            ],
            [
              -83.951299,
              30.186282
            ],
            [
              -83.93101,
              30.189123
            ],
            [
              -83.916561,
              30.223423
            ],
            [
              -83.921603,
              30.228779
            ],
            [
              -83.915533,
              30.245386
            ],
            [
              -83.902462,
              30.247553
            ],
            [
              -83.88167,
              30.267609
            ],
            [
              -83.856987,
              30.273318
            ],
            [
              -83.837652,
              30.280764
            ],
            [
              -83.820235,
              30.30354
            ],
            [
              -83.820521,
              30.313144
            ],
            [
              -83.79295,
              30.31901
            ],
            [
              -83.787107,
              30.331672
            ],
            [
              -83.804086,
              30.353094
            ],
            [
              -83.806667,
              30.371689
            ],
            [
              -83.795007,
              30.386081
            ],
            [
              -83.788396,
              30.382206
            ],
            [
              -83.757261,
              30.385467
            ],
            [
              -83.751073,
              30.407598
            ],
            [
              -83.72172,
              30.434354
            ],
            [
              -83.728339,
              30.45459
            ],
            [
              -83.727729,
              30.49024
            ],
            [
              -83.736954,
              30.507569
            ],
            [
              -83.726036,
              30.522951
            ],
            [
              -83.70436,
              30.523021
            ],
            [
              -83.704293,
              30.538094
            ],
            [
              -83.671073,
              30.53802
            ],
            [
              -83.671117,
              30.552861
            ],
            [
              -83.637412,
              30.552671
            ],
            [
              -83.637343,
              30.567471
            ],
            [
              -83.621189,
              30.567351
            ],
            [
              -83.62073,
              30.58198
            ],
            [
              -83.604186,
              30.581916
            ],
            [
              -83.604401,
              30.638236
            ],
            [
              -83.6117204626719,
              30.651244724675497
            ],
            [
              -83.611746,
              30.651246
            ],
            [
              -83.674139,
              30.654738
            ],
            [
              -83.676854,
              30.654896
            ],
            [
              -83.7437296744174,
              30.658383065883196
            ],
            [
              -83.810622,
              30.661871
            ],
            [
              -83.820973,
              30.662603
            ],
            [
              -83.855304,
              30.664403
            ],
            [
              -83.8796588473596,
              30.665786078714298
            ],
            [
              -83.880309,
              30.665823
            ],
            [
              -83.880406,
              30.665798
            ],
            [
              -84.007454,
              30.6720865150852
            ],
            [
              -84.007687,
              30.609299
            ],
            [
              -83.998316,
              30.572578
            ],
            [
              -83.979013,
              30.550593
            ],
            [
              -83.986734,
              30.549561
            ],
            [
              -83.977165,
              30.521987
            ],
            [
              -84.041152,
              30.521901
            ],
            [
              -84.041293,
              30.47354
            ],
            [
              -84.074691,
              30.434302
            ],
            [
              -84.075539,
              30.273617
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "103",
      "COUNTYNS": "00295745",
      "AFFGEOID": "0500000US12103",
      "GEOID": "12103",
      "NAME": "Pinellas",
      "LSAD": "06",
      "ALAND": 709243774,
      "AWATER": 865801424,
      "County_state": "Pinellas,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.852306,
              28.172257
            ],
            [
              -82.85938503658261,
              28.172175073687498
            ],
            [
              -82.84774,
              28.076665
            ],
            [
              -82.828163,
              28.02013
            ],
            [
              -82.8282290722283,
              28.0189414579283
            ],
            [
              -82.831388,
              27.962117
            ],
            [
              -82.840882,
              27.937162
            ],
            [
              -82.8409197799417,
              27.937015303489698
            ],
            [
              -82.847826,
              27.910199
            ],
            [
              -82.851126,
              27.8863
            ],
            [
              -82.849126,
              27.8632
            ],
            [
              -82.846526,
              27.854301
            ],
            [
              -82.8365569208995,
              27.836517583471597
            ],
            [
              -82.828561,
              27.822254
            ],
            [
              -82.820433,
              27.813742
            ],
            [
              -82.790224,
              27.791603
            ],
            [
              -82.783124,
              27.783804
            ],
            [
              -82.770023,
              27.767904
            ],
            [
              -82.760923,
              27.745205
            ],
            [
              -82.753723,
              27.736306
            ],
            [
              -82.746223,
              27.731306
            ],
            [
              -82.740323,
              27.718206
            ],
            [
              -82.738022,
              27.706807
            ],
            [
              -82.738467,
              27.678504
            ],
            [
              -82.745688,
              27.660321
            ],
            [
              -82.744975,
              27.646847
            ],
            [
              -82.736552,
              27.617326
            ],
            [
              -82.733076,
              27.612972
            ],
            [
              -82.705017,
              27.62531
            ],
            [
              -82.698091,
              27.638858
            ],
            [
              -82.712555,
              27.646647
            ],
            [
              -82.716322,
              27.651409
            ],
            [
              -82.721622,
              27.663908
            ],
            [
              -82.723022,
              27.671208
            ],
            [
              -82.718822,
              27.692007
            ],
            [
              -82.713629,
              27.698661
            ],
            [
              -82.693748,
              27.700217
            ],
            [
              -82.679251,
              27.694665
            ],
            [
              -82.67919632557589,
              27.694991970052897
            ],
            [
              -82.677321,
              27.706207
            ],
            [
              -82.671621,
              27.705907
            ],
            [
              -82.6632462324423,
              27.7024415789416
            ],
            [
              -82.662921,
              27.702307
            ],
            [
              -82.652521,
              27.700307
            ],
            [
              -82.63982,
              27.703907
            ],
            [
              -82.63362,
              27.710607
            ],
            [
              -82.62572,
              27.727006
            ],
            [
              -82.62502,
              27.732706
            ],
            [
              -82.63052,
              27.753905
            ],
            [
              -82.6292094396548,
              27.758268996183297
            ],
            [
              -82.622723,
              27.779868
            ],
            [
              -82.6162664633263,
              27.7878995383991
            ],
            [
              -82.60742,
              27.798904
            ],
            [
              -82.586519,
              27.816703
            ],
            [
              -82.589319,
              27.835702
            ],
            [
              -82.594819,
              27.843402
            ],
            [
              -82.598443,
              27.857582
            ],
            [
              -82.61002,
              27.873501
            ],
            [
              -82.63212,
              27.8911
            ],
            [
              -82.63422,
              27.9037
            ],
            [
              -82.628063,
              27.910397
            ],
            [
              -82.671221,
              27.913
            ],
            [
              -82.685121,
              27.916299
            ],
            [
              -82.691621,
              27.924899
            ],
            [
              -82.69923245224889,
              27.926305387568398
            ],
            [
              -82.710022,
              27.928299
            ],
            [
              -82.720122,
              27.936399
            ],
            [
              -82.7214435074278,
              27.9402640788495
            ],
            [
              -82.724122,
              27.948098
            ],
            [
              -82.720522,
              27.955798
            ],
            [
              -82.716522,
              27.958398
            ],
            [
              -82.684793,
              27.971824
            ],
            [
              -82.6894262800539,
              27.978869615043898
            ],
            [
              -82.692685,
              27.983825
            ],
            [
              -82.679237,
              28.000022
            ],
            [
              -82.690071,
              28.027473
            ],
            [
              -82.66963,
              28.030411
            ],
            [
              -82.6485177745241,
              28.0203156831794
            ],
            [
              -82.648557,
              28.03863
            ],
            [
              -82.650602,
              28.144928
            ],
            [
              -82.651165,
              28.173266
            ],
            [
              -82.672144,
              28.173319
            ],
            [
              -82.699024,
              28.173247
            ],
            [
              -82.805097,
              28.172181
            ],
            [
              -82.808474,
              28.154803
            ],
            [
              -82.799024,
              28.15179
            ],
            [
              -82.790724,
              28.15249
            ],
            [
              -82.786624,
              28.144991
            ],
            [
              -82.781324,
              28.127591
            ],
            [
              -82.783824,
              28.106292
            ],
            [
              -82.782724,
              28.055894
            ],
            [
              -82.812487,
              28.061778
            ],
            [
              -82.829914,
              28.138511
            ],
            [
              -82.829914,
              28.171936640674698
            ],
            [
              -82.847773,
              28.171682
            ],
            [
              -82.852306,
              28.172257
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "019",
      "COUNTYNS": "00295726",
      "AFFGEOID": "0500000US12019",
      "GEOID": "12019",
      "NAME": "Clay",
      "LSAD": "06",
      "ALAND": 1566439483,
      "AWATER": 100339826,
      "County_state": "Clay,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.049411,
              30.186933
            ],
            [
              -82.049425,
              30.143136
            ],
            [
              -82.049201,
              30.055904
            ],
            [
              -82.046112,
              29.747129
            ],
            [
              -82.049244,
              29.71867
            ],
            [
              -82.028917,
              29.718681
            ],
            [
              -82.00707,
              29.73949
            ],
            [
              -81.976228,
              29.747114
            ],
            [
              -81.939427,
              29.747497
            ],
            [
              -81.93111,
              29.75613
            ],
            [
              -81.923497,
              29.780919
            ],
            [
              -81.90926,
              29.79356
            ],
            [
              -81.861994,
              29.800187
            ],
            [
              -81.821872,
              29.82634
            ],
            [
              -81.81243,
              29.83649
            ],
            [
              -81.581207,
              29.840176
            ],
            [
              -81.583457,
              29.844556
            ],
            [
              -81.599013,
              29.875422
            ],
            [
              -81.608356,
              29.915695
            ],
            [
              -81.599341,
              29.9381
            ],
            [
              -81.604266,
              29.962409
            ],
            [
              -81.624812,
              29.981572
            ],
            [
              -81.671088,
              30.007242
            ],
            [
              -81.682807,
              30.016694
            ],
            [
              -81.690458,
              30.042834
            ],
            [
              -81.680073,
              30.086621
            ],
            [
              -81.680215,
              30.12124
            ],
            [
              -81.692116,
              30.14474
            ],
            [
              -81.690516,
              30.17194
            ],
            [
              -81.679626,
              30.190495
            ],
            [
              -81.703501,
              30.189025
            ],
            [
              -81.711348,
              30.191193
            ],
            [
              -81.73349,
              30.193466
            ],
            [
              -81.738924,
              30.189321
            ],
            [
              -81.837661,
              30.188624
            ],
            [
              -82.049411,
              30.186933
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "113",
      "COUNTYNS": "00306914",
      "AFFGEOID": "0500000US12113",
      "GEOID": "12113",
      "NAME": "Santa Rosa",
      "LSAD": "06",
      "ALAND": 2621316069,
      "AWATER": 418753385,
      "County_state": "Santa Rosa,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.313611,
              30.847266
            ],
            [
              -87.302532,
              30.842238
            ],
            [
              -87.293084,
              30.812349
            ],
            [
              -87.311883,
              30.78649
            ],
            [
              -87.302044,
              30.760623
            ],
            [
              -87.312304,
              30.735071
            ],
            [
              -87.302661,
              30.71937
            ],
            [
              -87.273086,
              30.717318
            ],
            [
              -87.2655,
              30.687997
            ],
            [
              -87.264776,
              30.649645
            ],
            [
              -87.25226,
              30.62336
            ],
            [
              -87.258884,
              30.611281
            ],
            [
              -87.23658,
              30.596609
            ],
            [
              -87.209715,
              30.555266
            ],
            [
              -87.167869,
              30.541595
            ],
            [
              -87.157856,
              30.53481
            ],
            [
              -87.124968,
              30.500196
            ],
            [
              -87.124568,
              30.485096
            ],
            [
              -87.138016,
              30.451909
            ],
            [
              -87.134432,
              30.424549
            ],
            [
              -87.14568,
              30.410982
            ],
            [
              -87.152568,
              30.405798
            ],
            [
              -87.228226,
              30.384246
            ],
            [
              -87.23407,
              30.370498
            ],
            [
              -87.23317,
              30.349199
            ],
            [
              -87.210969,
              30.339899
            ],
            [
              -87.178968,
              30.334599
            ],
            [
              -87.152968,
              30.348399
            ],
            [
              -87.140367,
              30.347899
            ],
            [
              -87.074605,
              30.362942
            ],
            [
              -86.920113,
              30.386567
            ],
            [
              -86.91929208669279,
              30.370675169612497
            ],
            [
              -86.909679,
              30.372423
            ],
            [
              -86.850625,
              30.380967
            ],
            [
              -86.80028272816939,
              30.3864768381929
            ],
            [
              -86.7986648610927,
              30.3866539097768
            ],
            [
              -86.792429,
              30.685144
            ],
            [
              -86.78569442138259,
              30.9969494300445
            ],
            [
              -86.785965,
              30.996954
            ],
            [
              -86.830542,
              30.997377
            ],
            [
              -86.831979,
              30.997354
            ],
            [
              -86.873032,
              30.997606
            ],
            [
              -86.888178,
              30.997552
            ],
            [
              -86.927851,
              30.997678
            ],
            [
              -86.998514,
              30.998634
            ],
            [
              -87.004396,
              30.999289
            ],
            [
              -87.027143,
              30.999228
            ],
            [
              -87.036402,
              30.999321
            ],
            [
              -87.040024,
              30.999567
            ],
            [
              -87.053772,
              30.999104
            ],
            [
              -87.064097,
              30.999164
            ],
            [
              -87.068667,
              30.999115
            ],
            [
              -87.118905,
              30.999399
            ],
            [
              -87.125,
              30.998774
            ],
            [
              -87.140786,
              30.999503
            ],
            [
              -87.162644,
              30.999026
            ],
            [
              -87.1630788827798,
              30.999012791614096
            ],
            [
              -87.204893,
              30.969878
            ],
            [
              -87.227635,
              30.961112
            ],
            [
              -87.255496,
              30.931591
            ],
            [
              -87.26721,
              30.934982
            ],
            [
              -87.286882,
              30.925441
            ],
            [
              -87.282179,
              30.9104
            ],
            [
              -87.284486,
              30.887986
            ],
            [
              -87.298484,
              30.880434
            ],
            [
              -87.313611,
              30.847266
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "117",
      "COUNTYNS": "00303665",
      "AFFGEOID": "0500000US12117",
      "GEOID": "12117",
      "NAME": "Seminole",
      "LSAD": "06",
      "ALAND": 801709341,
      "AWATER": 92719341,
      "County_state": "Seminole,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.459622,
              28.67274
            ],
            [
              -81.459727,
              28.642611
            ],
            [
              -81.45663,
              28.640323
            ],
            [
              -81.445419,
              28.63998
            ],
            [
              -81.407633,
              28.640104
            ],
            [
              -81.32865,
              28.639334
            ],
            [
              -81.328269,
              28.632408
            ],
            [
              -81.32789,
              28.610277
            ],
            [
              -81.282099,
              28.611168
            ],
            [
              -81.251955,
              28.611786
            ],
            [
              -81.244698,
              28.611536
            ],
            [
              -81.129006,
              28.612535
            ],
            [
              -80.98725,
              28.612997
            ],
            [
              -81.007099,
              28.636326
            ],
            [
              -81.001225,
              28.641053
            ],
            [
              -81.011042,
              28.66808
            ],
            [
              -81.021287,
              28.674253
            ],
            [
              -81.016646,
              28.693037
            ],
            [
              -81.034979,
              28.709864
            ],
            [
              -81.036449,
              28.724945
            ],
            [
              -81.06111,
              28.750001
            ],
            [
              -81.053087,
              28.773557
            ],
            [
              -81.067413,
              28.812989
            ],
            [
              -81.097331,
              28.82153
            ],
            [
              -81.10575,
              28.828541
            ],
            [
              -81.126237,
              28.821441
            ],
            [
              -81.123142,
              28.814125
            ],
            [
              -81.137863,
              28.791294
            ],
            [
              -81.156392,
              28.791774
            ],
            [
              -81.178936,
              28.781886
            ],
            [
              -81.183198,
              28.797468
            ],
            [
              -81.195021,
              28.792302
            ],
            [
              -81.216345,
              28.808302
            ],
            [
              -81.226003,
              28.83263
            ],
            [
              -81.27298,
              28.833699
            ],
            [
              -81.320473,
              28.83453
            ],
            [
              -81.358715,
              28.848284
            ],
            [
              -81.352123,
              28.862316
            ],
            [
              -81.36694,
              28.879227
            ],
            [
              -81.382965,
              28.851994
            ],
            [
              -81.416846,
              28.824569
            ],
            [
              -81.414446,
              28.784955
            ],
            [
              -81.422918,
              28.737344
            ],
            [
              -81.441786,
              28.714957
            ],
            [
              -81.459378,
              28.713724
            ],
            [
              -81.459622,
              28.67274
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "127",
      "COUNTYNS": "00306921",
      "AFFGEOID": "0500000US12127",
      "GEOID": "12127",
      "NAME": "Volusia",
      "LSAD": "06",
      "ALAND": 2852082296,
      "AWATER": 857948738,
      "County_state": "Volusia,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.680903,
              29.32443
            ],
            [
              -81.673264,
              29.306644
            ],
            [
              -81.655677,
              29.29994
            ],
            [
              -81.641916,
              29.276766
            ],
            [
              -81.63399,
              29.244779
            ],
            [
              -81.619303,
              29.22859
            ],
            [
              -81.614725,
              29.20648
            ],
            [
              -81.5931,
              29.198999
            ],
            [
              -81.560905,
              29.20019
            ],
            [
              -81.553588,
              29.188034
            ],
            [
              -81.531262,
              29.176621
            ],
            [
              -81.504534,
              29.123711
            ],
            [
              -81.502055,
              29.097802
            ],
            [
              -81.488841,
              29.093193
            ],
            [
              -81.466351,
              29.096703
            ],
            [
              -81.454383,
              29.081984
            ],
            [
              -81.455778,
              29.063998
            ],
            [
              -81.446699,
              29.062323
            ],
            [
              -81.43221,
              29.04442
            ],
            [
              -81.392084,
              29.019979
            ],
            [
              -81.382546,
              29.008322
            ],
            [
              -81.374787,
              28.993354
            ],
            [
              -81.354682,
              28.984807
            ],
            [
              -81.360762,
              28.976775
            ],
            [
              -81.368078,
              28.955407
            ],
            [
              -81.352966,
              28.946246
            ],
            [
              -81.352788,
              28.896685
            ],
            [
              -81.36694,
              28.879227
            ],
            [
              -81.352123,
              28.862316
            ],
            [
              -81.358715,
              28.848284
            ],
            [
              -81.320473,
              28.83453
            ],
            [
              -81.27298,
              28.833699
            ],
            [
              -81.226003,
              28.83263
            ],
            [
              -81.216345,
              28.808302
            ],
            [
              -81.195021,
              28.792302
            ],
            [
              -81.183198,
              28.797468
            ],
            [
              -81.178936,
              28.781886
            ],
            [
              -81.156392,
              28.791774
            ],
            [
              -81.137863,
              28.791294
            ],
            [
              -81.123142,
              28.814125
            ],
            [
              -81.126237,
              28.821441
            ],
            [
              -81.10575,
              28.828541
            ],
            [
              -81.097331,
              28.82153
            ],
            [
              -81.067413,
              28.812989
            ],
            [
              -81.053087,
              28.773557
            ],
            [
              -81.06111,
              28.750001
            ],
            [
              -81.036449,
              28.724945
            ],
            [
              -81.034979,
              28.709864
            ],
            [
              -81.016646,
              28.693037
            ],
            [
              -81.021287,
              28.674253
            ],
            [
              -81.011042,
              28.66808
            ],
            [
              -81.001225,
              28.641053
            ],
            [
              -81.007099,
              28.636326
            ],
            [
              -80.98725,
              28.612997
            ],
            [
              -80.964466,
              28.612992
            ],
            [
              -80.967895,
              28.790197
            ],
            [
              -80.842922,
              28.791148
            ],
            [
              -80.73224445687619,
              28.791237410883298
            ],
            [
              -80.787021,
              28.875266
            ],
            [
              -80.8668081913352,
              28.9935618295096
            ],
            [
              -80.878275,
              29.010563
            ],
            [
              -80.893675,
              29.036163
            ],
            [
              -80.907275,
              29.064262
            ],
            [
              -80.944376,
              29.110861
            ],
            [
              -80.9625320045125,
              29.1417586885967
            ],
            [
              -80.966176,
              29.14796
            ],
            [
              -80.97159146963038,
              29.158716402723098
            ],
            [
              -80.98113753962019,
              29.177677154097
            ],
            [
              -81.046678,
              29.307856
            ],
            [
              -81.0588576172039,
              29.334135285176004
            ],
            [
              -81.0704197173936,
              29.359082188106896
            ],
            [
              -81.10192298992641,
              29.4270550536747
            ],
            [
              -81.148731,
              29.416788
            ],
            [
              -81.155881,
              29.410954
            ],
            [
              -81.15254,
              29.346178
            ],
            [
              -81.150081,
              29.265957
            ],
            [
              -81.19534,
              29.264823
            ],
            [
              -81.268404,
              29.263182
            ],
            [
              -81.41729,
              29.261156
            ],
            [
              -81.420745,
              29.399645
            ],
            [
              -81.433992,
              29.398552
            ],
            [
              -81.445886,
              29.380142
            ],
            [
              -81.680903,
              29.32443
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "087",
      "COUNTYNS": "00295759",
      "AFFGEOID": "0500000US12087",
      "GEOID": "12087",
      "NAME": "Monroe",
      "LSAD": "06",
      "ALAND": 2545998106,
      "AWATER": 7133386423,
      "County_state": "Monroe,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.668455,
              24.907924
            ],
            [
              -80.657695,
              24.897354
            ],
            [
              -80.571534,
              24.953726
            ],
            [
              -80.49249,
              25.027337
            ],
            [
              -80.448839,
              25.07338
            ],
            [
              -80.407076,
              25.104349
            ],
            [
              -80.358183,
              25.153228
            ],
            [
              -80.327405,
              25.223812
            ],
            [
              -80.283921,
              25.290376
            ],
            [
              -80.2516616347018,
              25.3425592802016
            ],
            [
              -80.253575,
              25.344591
            ],
            [
              -80.255375,
              25.348892
            ],
            [
              -80.256475,
              25.350691
            ],
            [
              -80.259875,
              25.352791
            ],
            [
              -80.262374,
              25.353991
            ],
            [
              -80.265472,
              25.354296
            ],
            [
              -80.2699420002125,
              25.352315270448596
            ],
            [
              -80.271792,
              25.348442
            ],
            [
              -80.290194,
              25.319316
            ],
            [
              -80.338325,
              25.290085
            ],
            [
              -80.365913,
              25.285383
            ],
            [
              -80.377064,
              25.30219
            ],
            [
              -80.3763480504136,
              25.3058717488906
            ],
            [
              -80.379021,
              25.305895
            ],
            [
              -80.379149,
              25.287859
            ],
            [
              -80.395081,
              25.272937
            ],
            [
              -80.394931,
              25.253537
            ],
            [
              -80.410955,
              25.253466
            ],
            [
              -80.41887,
              25.23631
            ],
            [
              -80.450659,
              25.240241
            ],
            [
              -80.482826,
              25.224786
            ],
            [
              -80.494176,
              25.227117
            ],
            [
              -80.487215,
              25.207126
            ],
            [
              -80.501235,
              25.210137
            ],
            [
              -80.495394,
              25.199808
            ],
            [
              -80.46455,
              25.209067
            ],
            [
              -80.442922,
              25.189281
            ],
            [
              -80.461121,
              25.161776
            ],
            [
              -80.461342,
              25.132487
            ],
            [
              -80.468829,
              25.092027
            ],
            [
              -80.496741,
              25.047011
            ],
            [
              -80.544763,
              25.012151
            ],
            [
              -80.598839,
              24.966149
            ],
            [
              -80.668455,
              24.907924
            ]
          ]
        ],
        [
          [
            [
              -80.751643,
              24.857254
            ],
            [
              -80.73144,
              24.843236
            ],
            [
              -80.70173,
              24.859411
            ],
            [
              -80.683904,
              24.880975
            ],
            [
              -80.693404,
              24.899532
            ],
            [
              -80.698471,
              24.909273
            ],
            [
              -80.704659,
              24.898619
            ],
            [
              -80.729063,
              24.87235
            ],
            [
              -80.751643,
              24.857254
            ]
          ]
        ],
        [
          [
            [
              -80.850866,
              24.803701
            ],
            [
              -80.850338,
              24.8026
            ],
            [
              -80.846191,
              24.802968
            ],
            [
              -80.82057,
              24.80117
            ],
            [
              -80.796053,
              24.81194
            ],
            [
              -80.790497,
              24.817789
            ],
            [
              -80.788263,
              24.824218
            ],
            [
              -80.780564,
              24.84052
            ],
            [
              -80.79278,
              24.843918
            ],
            [
              -80.817005,
              24.836765
            ],
            [
              -80.833642,
              24.823822
            ],
            [
              -80.85028,
              24.818429
            ],
            [
              -80.850866,
              24.803701
            ]
          ]
        ],
        [
          [
            [
              -81.124761,
              24.707129
            ],
            [
              -81.106645,
              24.688781
            ],
            [
              -81.0773,
              24.689974
            ],
            [
              -81.023794,
              24.716901
            ],
            [
              -80.915642,
              24.75909
            ],
            [
              -80.908386,
              24.776872
            ],
            [
              -80.937033,
              24.784987
            ],
            [
              -80.988683,
              24.76016
            ],
            [
              -81.041593,
              24.743278
            ],
            [
              -81.084394,
              24.724552
            ],
            [
              -81.124761,
              24.707129
            ]
          ]
        ],
        [
          [
            [
              -81.468489,
              25.80332
            ],
            [
              -81.423989,
              25.79372
            ],
            [
              -81.414376,
              25.783528
            ],
            [
              -81.38381,
              25.776751
            ],
            [
              -81.382257,
              25.749466
            ],
            [
              -81.355248,
              25.728727
            ],
            [
              -81.355986,
              25.703526
            ],
            [
              -81.350556,
              25.689832
            ],
            [
              -81.338242,
              25.692895
            ],
            [
              -81.311899,
              25.702911
            ],
            [
              -81.290328,
              25.687506
            ],
            [
              -81.289424,
              25.657957
            ],
            [
              -81.272823,
              25.637961
            ],
            [
              -81.271489,
              25.61434
            ],
            [
              -81.240519,
              25.599041
            ],
            [
              -81.233051,
              25.586587
            ],
            [
              -81.232705,
              25.573366
            ],
            [
              -81.225557,
              25.55847
            ],
            [
              -81.209321,
              25.548611
            ],
            [
              -81.204389,
              25.538908
            ],
            [
              -81.203175,
              25.53416
            ],
            [
              -81.210149,
              25.516888
            ],
            [
              -81.208201,
              25.504937
            ],
            [
              -81.191924,
              25.484745
            ],
            [
              -81.179406,
              25.475427
            ],
            [
              -81.168652,
              25.463848
            ],
            [
              -81.146765,
              25.407577
            ],
            [
              -81.147144,
              25.404297
            ],
            [
              -81.150656,
              25.399206
            ],
            [
              -81.150508,
              25.387255
            ],
            [
              -81.141395,
              25.381358
            ],
            [
              -81.128492,
              25.380511
            ],
            [
              -81.117265,
              25.354953
            ],
            [
              -81.118208,
              25.34522
            ],
            [
              -81.12141,
              25.33875
            ],
            [
              -81.133913,
              25.342996
            ],
            [
              -81.140099,
              25.341117
            ],
            [
              -81.148103,
              25.332793
            ],
            [
              -81.151916,
              25.324766
            ],
            [
              -81.148915,
              25.318067
            ],
            [
              -81.1523,
              25.305543
            ],
            [
              -81.159293,
              25.298595
            ],
            [
              -81.16207,
              25.289833
            ],
            [
              -81.168307,
              25.253178
            ],
            [
              -81.170907,
              25.245857
            ],
            [
              -81.172044,
              25.222276
            ],
            [
              -81.155481,
              25.208098
            ],
            [
              -81.146737,
              25.193139
            ],
            [
              -81.142278,
              25.183
            ],
            [
              -81.141024,
              25.163868
            ],
            [
              -81.133567,
              25.156295
            ],
            [
              -81.120616,
              25.152302
            ],
            [
              -81.111943,
              25.14547
            ],
            [
              -81.094524,
              25.127054
            ],
            [
              -81.079859,
              25.118797
            ],
            [
              -81.050505,
              25.128273
            ],
            [
              -81.022989,
              25.129393
            ],
            [
              -81.009598,
              25.125403
            ],
            [
              -80.999176,
              25.124222
            ],
            [
              -80.971765,
              25.133958
            ],
            [
              -80.957427,
              25.135704
            ],
            [
              -80.943216,
              25.134443
            ],
            [
              -80.915924,
              25.141301
            ],
            [
              -80.900577,
              25.139669
            ],
            [
              -80.898911,
              25.147652
            ],
            [
              -80.901617,
              25.153803
            ],
            [
              -80.900066,
              25.162034
            ],
            [
              -80.87546,
              25.174321
            ],
            [
              -80.858167,
              25.176576
            ],
            [
              -80.8579854115429,
              25.1766094650017
            ],
            [
              -80.857989,
              25.243
            ],
            [
              -80.862191,
              25.364193
            ],
            [
              -80.87319,
              25.363993
            ],
            [
              -80.873153,
              25.66618
            ],
            [
              -80.873125,
              25.760885
            ],
            [
              -80.873096,
              25.805377
            ],
            [
              -81.34417,
              25.803328
            ],
            [
              -81.468489,
              25.80332
            ]
          ]
        ],
        [
          [
            [
              -81.811693,
              24.568745
            ],
            [
              -81.812536,
              24.545469
            ],
            [
              -81.775219,
              24.550409
            ],
            [
              -81.685243,
              24.558676
            ],
            [
              -81.665142,
              24.573258
            ],
            [
              -81.644131,
              24.574917
            ],
            [
              -81.611852,
              24.585578
            ],
            [
              -81.559184,
              24.609571
            ],
            [
              -81.517404,
              24.621239
            ],
            [
              -81.508011,
              24.642403
            ],
            [
              -81.477304,
              24.645653
            ],
            [
              -81.443915,
              24.642677
            ],
            [
              -81.412537,
              24.646732
            ],
            [
              -81.401889,
              24.623544
            ],
            [
              -81.363099,
              24.630963
            ],
            [
              -81.313072,
              24.647251
            ],
            [
              -81.257711,
              24.664309
            ],
            [
              -81.243232,
              24.673998
            ],
            [
              -81.292364,
              24.710518
            ],
            [
              -81.305054,
              24.755185
            ],
            [
              -81.325126,
              24.767115
            ],
            [
              -81.377831,
              24.782174
            ],
            [
              -81.44351,
              24.813364
            ],
            [
              -81.498101,
              24.792574
            ],
            [
              -81.53607,
              24.765977
            ],
            [
              -81.571151,
              24.756354
            ],
            [
              -81.584599,
              24.736696
            ],
            [
              -81.672338,
              24.699514
            ],
            [
              -81.745651,
              24.659882
            ],
            [
              -81.76139,
              24.642035
            ],
            [
              -81.799495,
              24.594168
            ],
            [
              -81.811693,
              24.568745
            ]
          ]
        ],
        [
          [
            [
              -82.01491,
              24.543071
            ],
            [
              -82.016099,
              24.521449
            ],
            [
              -81.972128,
              24.514962
            ],
            [
              -81.91152,
              24.526855
            ],
            [
              -81.887752,
              24.551719
            ],
            [
              -81.874679,
              24.563609
            ],
            [
              -81.886563,
              24.572256
            ],
            [
              -81.91865,
              24.562528
            ],
            [
              -81.976882,
              24.548476
            ],
            [
              -82.01491,
              24.543071
            ]
          ]
        ],
        [
          [
            [
              -82.164426,
              24.563375
            ],
            [
              -82.165206,
              24.552159
            ],
            [
              -82.159439,
              24.548212
            ],
            [
              -82.116787,
              24.549144
            ],
            [
              -82.104429,
              24.561167
            ],
            [
              -82.099417,
              24.572522
            ],
            [
              -82.101051,
              24.584679
            ],
            [
              -82.104187,
              24.588256
            ],
            [
              -82.125268,
              24.597426
            ],
            [
              -82.135423,
              24.596879
            ],
            [
              -82.143075,
              24.593395
            ],
            [
              -82.15068,
              24.576331
            ],
            [
              -82.164426,
              24.563375
            ]
          ]
        ],
        [
          [
            [
              -82.933543,
              24.632764
            ],
            [
              -82.934731,
              24.615479
            ],
            [
              -82.894325,
              24.611158
            ],
            [
              -82.863492,
              24.624434
            ],
            [
              -82.880065,
              24.643566
            ],
            [
              -82.91672,
              24.636246
            ],
            [
              -82.933543,
              24.632764
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "109",
      "COUNTYNS": "00308371",
      "AFFGEOID": "0500000US12109",
      "GEOID": "12109",
      "NAME": "St. Johns",
      "LSAD": "06",
      "ALAND": 1555793861,
      "AWATER": 571962027,
      "County_state": "St. Johns,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.690458,
              30.042834
            ],
            [
              -81.682807,
              30.016694
            ],
            [
              -81.671088,
              30.007242
            ],
            [
              -81.624812,
              29.981572
            ],
            [
              -81.604266,
              29.962409
            ],
            [
              -81.599341,
              29.9381
            ],
            [
              -81.608356,
              29.915695
            ],
            [
              -81.599013,
              29.875422
            ],
            [
              -81.583457,
              29.844556
            ],
            [
              -81.581207,
              29.840176
            ],
            [
              -81.566524,
              29.802902
            ],
            [
              -81.541235,
              29.770001
            ],
            [
              -81.52523,
              29.759497
            ],
            [
              -81.524804,
              29.721586
            ],
            [
              -81.52366,
              29.622432
            ],
            [
              -81.324059,
              29.62561
            ],
            [
              -81.324345,
              29.653478
            ],
            [
              -81.305914,
              29.642647
            ],
            [
              -81.297733,
              29.651124
            ],
            [
              -81.28212,
              29.650416
            ],
            [
              -81.268391,
              29.663127
            ],
            [
              -81.24726,
              29.659093
            ],
            [
              -81.227309,
              29.670538
            ],
            [
              -81.21287790507309,
              29.670666936087198
            ],
            [
              -81.229015,
              29.714693
            ],
            [
              -81.240924,
              29.739218
            ],
            [
              -81.256711,
              29.784693
            ],
            [
              -81.26221292112179,
              29.8142972038518
            ],
            [
              -81.2631825411995,
              29.819514441577397
            ],
            [
              -81.263396,
              29.820663
            ],
            [
              -81.2641129487715,
              29.8414191367931
            ],
            [
              -81.264693,
              29.858212
            ],
            [
              -81.270442,
              29.883106
            ],
            [
              -81.27654,
              29.90046
            ],
            [
              -81.288955,
              29.91518
            ],
            [
              -81.295268,
              29.928614
            ],
            [
              -81.308978,
              29.96944
            ],
            [
              -81.355591,
              30.162563
            ],
            [
              -81.3798792601458,
              30.252913638915
            ],
            [
              -81.430807,
              30.252242
            ],
            [
              -81.436929,
              30.252335
            ],
            [
              -81.433783,
              30.105536
            ],
            [
              -81.538707,
              30.103748
            ],
            [
              -81.539009,
              30.119942
            ],
            [
              -81.567787,
              30.117789
            ],
            [
              -81.580882,
              30.1261
            ],
            [
              -81.623813,
              30.133641
            ],
            [
              -81.650514,
              30.121541
            ],
            [
              -81.680215,
              30.12124
            ],
            [
              -81.680073,
              30.086621
            ],
            [
              -81.690458,
              30.042834
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "097",
      "COUNTYNS": "00295748",
      "AFFGEOID": "0500000US12097",
      "GEOID": "12097",
      "NAME": "Osceola",
      "LSAD": "06",
      "ALAND": 3438742026,
      "AWATER": 461876563,
      "County_state": "Osceola,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.657268,
              28.347098
            ],
            [
              -81.657173,
              28.264513
            ],
            [
              -81.657189,
              28.259217
            ],
            [
              -81.557459,
              28.259362
            ],
            [
              -81.551345,
              28.249045
            ],
            [
              -81.524083,
              28.201012
            ],
            [
              -81.524227,
              28.142805
            ],
            [
              -81.47009,
              28.143281
            ],
            [
              -81.469609,
              28.14326
            ],
            [
              -81.456214,
              28.143163
            ],
            [
              -81.455758,
              28.08489
            ],
            [
              -81.346418,
              28.084696
            ],
            [
              -81.346692,
              28.075282
            ],
            [
              -81.366811,
              28.061667
            ],
            [
              -81.363723,
              28.037763
            ],
            [
              -81.375813,
              28.029489
            ],
            [
              -81.382476,
              28.012361
            ],
            [
              -81.393971,
              28.028027
            ],
            [
              -81.405847,
              28.028965
            ],
            [
              -81.432532,
              28.04326
            ],
            [
              -81.44343,
              28.059179
            ],
            [
              -81.459687,
              28.051809
            ],
            [
              -81.458817,
              28.039775
            ],
            [
              -81.440297,
              28.033872
            ],
            [
              -81.424547,
              28.012476
            ],
            [
              -81.41543,
              27.998172
            ],
            [
              -81.386752,
              28.003321
            ],
            [
              -81.376505,
              27.978251
            ],
            [
              -81.349831,
              27.963758
            ],
            [
              -81.33665,
              27.94958
            ],
            [
              -81.338664,
              27.931336
            ],
            [
              -81.308696,
              27.92188
            ],
            [
              -81.314909,
              27.901244
            ],
            [
              -81.306108,
              27.886176
            ],
            [
              -81.301862,
              27.862081
            ],
            [
              -81.281324,
              27.857472
            ],
            [
              -81.259107,
              27.845985
            ],
            [
              -81.230079,
              27.842351
            ],
            [
              -81.208124,
              27.821125
            ],
            [
              -81.196307,
              27.790769
            ],
            [
              -81.172957,
              27.759492
            ],
            [
              -81.1785,
              27.751936
            ],
            [
              -81.165317,
              27.742657
            ],
            [
              -81.168735,
              27.725993
            ],
            [
              -81.145954,
              27.710533
            ],
            [
              -81.149001,
              27.692032
            ],
            [
              -81.144358,
              27.669794
            ],
            [
              -81.131842,
              27.661509
            ],
            [
              -81.131495,
              27.6494
            ],
            [
              -81.142164,
              27.643238
            ],
            [
              -80.87315,
              27.642288
            ],
            [
              -80.880073,
              27.733699
            ],
            [
              -80.881566,
              27.822511
            ],
            [
              -80.868881,
              27.822522
            ],
            [
              -80.869313,
              28.084727
            ],
            [
              -80.862827,
              28.091885
            ],
            [
              -80.861398,
              28.252176
            ],
            [
              -80.862908,
              28.347487
            ],
            [
              -81.094674,
              28.348105
            ],
            [
              -81.309571,
              28.348051
            ],
            [
              -81.319697,
              28.348123
            ],
            [
              -81.419781,
              28.347923
            ],
            [
              -81.457592,
              28.348185
            ],
            [
              -81.487591,
              28.347088
            ],
            [
              -81.657268,
              28.347098
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "115",
      "COUNTYNS": "00295741",
      "AFFGEOID": "0500000US12115",
      "GEOID": "12115",
      "NAME": "Sarasota",
      "LSAD": "06",
      "ALAND": 1439588493,
      "AWATER": 438890089,
      "County_state": "Sarasota,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.6428368302769,
              27.3897369565301
            ],
            [
              -82.642821,
              27.38972
            ],
            [
              -82.623863,
              27.362206
            ],
            [
              -82.597629,
              27.335754
            ],
            [
              -82.57602,
              27.309324
            ],
            [
              -82.569248,
              27.298588
            ],
            [
              -82.5695982657174,
              27.2853415874133
            ],
            [
              -82.569754,
              27.279452
            ],
            [
              -82.55902,
              27.268826
            ],
            [
              -82.54512,
              27.261026
            ],
            [
              -82.539719,
              27.254326
            ],
            [
              -82.5126067566589,
              27.2080194757709
            ],
            [
              -82.512319,
              27.207528
            ],
            [
              -82.4976494808182,
              27.179977146935098
            ],
            [
              -82.477019,
              27.141231
            ],
            [
              -82.46889,
              27.113612
            ],
            [
              -82.465319,
              27.110732
            ],
            [
              -82.460319,
              27.099933
            ],
            [
              -82.445718,
              27.060634
            ],
            [
              -82.419218,
              27.020736
            ],
            [
              -82.400618,
              26.984937
            ],
            [
              -82.3757372343028,
              26.946041019759097
            ],
            [
              -82.255521,
              26.945264
            ],
            [
              -82.255237,
              27.032975
            ],
            [
              -82.073937,
              27.0321
            ],
            [
              -82.057469,
              27.032119
            ],
            [
              -82.056497,
              27.207769
            ],
            [
              -82.249813,
              27.208871
            ],
            [
              -82.253589,
              27.21051
            ],
            [
              -82.252264,
              27.386243
            ],
            [
              -82.43624,
              27.386353
            ],
            [
              -82.447088,
              27.386123
            ],
            [
              -82.447169,
              27.388603
            ],
            [
              -82.449001,
              27.388608
            ],
            [
              -82.522112,
              27.388885
            ],
            [
              -82.531583,
              27.388788
            ],
            [
              -82.543666,
              27.389044
            ],
            [
              -82.544418,
              27.389033
            ],
            [
              -82.559339,
              27.389041
            ],
            [
              -82.6428368302769,
              27.3897369565301
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "041",
      "COUNTYNS": "00303633",
      "AFFGEOID": "0500000US12041",
      "GEOID": "12041",
      "NAME": "Gilchrist",
      "LSAD": "06",
      "ALAND": 905705520,
      "AWATER": 14362154,
      "County_state": "Gilchrist,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.973054,
              29.624281
            ],
            [
              -82.971718,
              29.608564
            ],
            [
              -82.954879,
              29.585605
            ],
            [
              -82.937329,
              29.591196
            ],
            [
              -82.856612,
              29.591315
            ],
            [
              -82.77291,
              29.584879
            ],
            [
              -82.756868,
              29.57768
            ],
            [
              -82.756458,
              29.562467
            ],
            [
              -82.656301,
              29.564811
            ],
            [
              -82.658097,
              29.821778
            ],
            [
              -82.658554,
              29.830144
            ],
            [
              -82.693668,
              29.834216
            ],
            [
              -82.711086,
              29.847475
            ],
            [
              -82.736237,
              29.860133
            ],
            [
              -82.752854,
              29.880206
            ],
            [
              -82.758321,
              29.89874
            ],
            [
              -82.768445,
              29.897007
            ],
            [
              -82.774296,
              29.911702
            ],
            [
              -82.770379,
              29.928109
            ],
            [
              -82.780486,
              29.925753
            ],
            [
              -82.791854,
              29.935453
            ],
            [
              -82.800477,
              29.932126
            ],
            [
              -82.832,
              29.912659
            ],
            [
              -82.863266,
              29.91105
            ],
            [
              -82.879802,
              29.886847
            ],
            [
              -82.875616,
              29.866527
            ],
            [
              -82.890246,
              29.845129
            ],
            [
              -82.893371,
              29.826839
            ],
            [
              -82.920608,
              29.824143
            ],
            [
              -82.935355,
              29.809455
            ],
            [
              -82.920364,
              29.79465
            ],
            [
              -82.934999,
              29.786398
            ],
            [
              -82.949707,
              29.754409
            ],
            [
              -82.938538,
              29.744487
            ],
            [
              -82.949689,
              29.726283
            ],
            [
              -82.939319,
              29.700932
            ],
            [
              -82.952474,
              29.703915
            ],
            [
              -82.959135,
              29.670023
            ],
            [
              -82.951833,
              29.661864
            ],
            [
              -82.956989,
              29.638878
            ],
            [
              -82.973054,
              29.624281
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "051",
      "COUNTYNS": "00307626",
      "AFFGEOID": "0500000US12051",
      "GEOID": "12051",
      "NAME": "Hendry",
      "LSAD": "06",
      "ALAND": 2994620710,
      "AWATER": 87035817,
      "County_state": "Hendry,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.565931,
              26.769552
            ],
            [
              -81.566275,
              26.709552
            ],
            [
              -81.565095,
              26.600333
            ],
            [
              -81.563827,
              26.518469
            ],
            [
              -81.563763,
              26.513324
            ],
            [
              -81.470419,
              26.513331
            ],
            [
              -81.417885,
              26.516267
            ],
            [
              -81.271768,
              26.517069
            ],
            [
              -81.26855,
              26.253045
            ],
            [
              -80.879809,
              26.259455
            ],
            [
              -80.881233,
              26.333806
            ],
            [
              -80.881901,
              26.344106
            ],
            [
              -80.880017,
              26.346059
            ],
            [
              -80.886057,
              26.743773
            ],
            [
              -80.885639,
              26.958919
            ],
            [
              -80.945354,
              26.769049
            ],
            [
              -81.43506,
              26.768906
            ],
            [
              -81.434791,
              26.78346
            ],
            [
              -81.459327,
              26.783569
            ],
            [
              -81.459445,
              26.768856
            ],
            [
              -81.565931,
              26.769552
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "055",
      "COUNTYNS": "00295756",
      "AFFGEOID": "0500000US12055",
      "GEOID": "12055",
      "NAME": "Highlands",
      "LSAD": "06",
      "ALAND": 2632952088,
      "AWATER": 231653040,
      "County_state": "Highlands,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.563318,
              27.646647
            ],
            [
              -81.563696,
              27.34068
            ],
            [
              -81.562202,
              27.033836
            ],
            [
              -81.267152,
              27.032461
            ],
            [
              -81.267677,
              27.121097
            ],
            [
              -81.168281,
              27.121734
            ],
            [
              -81.169138,
              27.209585
            ],
            [
              -80.943186,
              27.210599
            ],
            [
              -80.951656,
              27.224825
            ],
            [
              -80.970916,
              27.217698
            ],
            [
              -80.981778,
              27.231552
            ],
            [
              -80.980853,
              27.246326
            ],
            [
              -80.993216,
              27.251199
            ],
            [
              -80.998871,
              27.268153
            ],
            [
              -80.997768,
              27.295148
            ],
            [
              -81.004102,
              27.302012
            ],
            [
              -81.024673,
              27.301807
            ],
            [
              -81.029787,
              27.323937
            ],
            [
              -81.042218,
              27.32823
            ],
            [
              -81.046655,
              27.340491
            ],
            [
              -81.033308,
              27.348809
            ],
            [
              -81.034429,
              27.35916
            ],
            [
              -81.081607,
              27.385857
            ],
            [
              -81.09845,
              27.379108
            ],
            [
              -81.125637,
              27.393991
            ],
            [
              -81.125073,
              27.403634
            ],
            [
              -81.142257,
              27.412078
            ],
            [
              -81.139058,
              27.434044
            ],
            [
              -81.147162,
              27.447216
            ],
            [
              -81.172934,
              27.463583
            ],
            [
              -81.183382,
              27.482247
            ],
            [
              -81.205096,
              27.489702
            ],
            [
              -81.198459,
              27.510376
            ],
            [
              -81.213717,
              27.528654
            ],
            [
              -81.190626,
              27.556664
            ],
            [
              -81.177958,
              27.557707
            ],
            [
              -81.165939,
              27.574971
            ],
            [
              -81.156578,
              27.574494
            ],
            [
              -81.155346,
              27.591359
            ],
            [
              -81.142477,
              27.597766
            ],
            [
              -81.142164,
              27.643238
            ],
            [
              -81.529961,
              27.646422
            ],
            [
              -81.563318,
              27.646647
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "061",
      "COUNTYNS": "00307624",
      "AFFGEOID": "0500000US12061",
      "GEOID": "12061",
      "NAME": "Indian River",
      "LSAD": "06",
      "ALAND": 1302197699,
      "AWATER": 295585012,
      "County_state": "Indian River,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.881566,
              27.822511
            ],
            [
              -80.880073,
              27.733699
            ],
            [
              -80.87315,
              27.642288
            ],
            [
              -80.778047,
              27.643195
            ],
            [
              -80.777166,
              27.558733
            ],
            [
              -80.679821,
              27.55847
            ],
            [
              -80.348422,
              27.557248
            ],
            [
              -80.32127113933309,
              27.5573775459518
            ],
            [
              -80.324699,
              27.569178
            ],
            [
              -80.330956,
              27.597541
            ],
            [
              -80.34437,
              27.616226
            ],
            [
              -80.350553,
              27.628361
            ],
            [
              -80.351717,
              27.642623
            ],
            [
              -80.383695,
              27.740045
            ],
            [
              -80.447179,
              27.859731
            ],
            [
              -80.4470841049745,
              27.860755037095497
            ],
            [
              -80.4549,
              27.854641
            ],
            [
              -80.491079,
              27.854601
            ],
            [
              -80.509075,
              27.822058
            ],
            [
              -80.868881,
              27.822522
            ],
            [
              -80.881566,
              27.822511
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "081",
      "COUNTYNS": "00295744",
      "AFFGEOID": "0500000US12081",
      "GEOID": "12081",
      "NAME": "Manatee",
      "LSAD": "06",
      "ALAND": 1924699210,
      "AWATER": 387514585,
      "County_state": "Manatee,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.745748,
              27.538834
            ],
            [
              -82.743017,
              27.531086
            ],
            [
              -82.724522,
              27.513614
            ],
            [
              -82.714521,
              27.500415
            ],
            [
              -82.707821,
              27.487615
            ],
            [
              -82.691004,
              27.444331
            ],
            [
              -82.691821,
              27.437218
            ],
            [
              -82.675121,
              27.424318
            ],
            [
              -82.6428368302769,
              27.3897369565301
            ],
            [
              -82.559339,
              27.389041
            ],
            [
              -82.544418,
              27.389033
            ],
            [
              -82.543666,
              27.389044
            ],
            [
              -82.531583,
              27.388788
            ],
            [
              -82.522112,
              27.388885
            ],
            [
              -82.449001,
              27.388608
            ],
            [
              -82.447169,
              27.388603
            ],
            [
              -82.447088,
              27.386123
            ],
            [
              -82.43624,
              27.386353
            ],
            [
              -82.252264,
              27.386243
            ],
            [
              -82.253589,
              27.21051
            ],
            [
              -82.249813,
              27.208871
            ],
            [
              -82.056497,
              27.207769
            ],
            [
              -82.055753,
              27.338264
            ],
            [
              -82.054349,
              27.646382
            ],
            [
              -82.453735,
              27.64591
            ],
            [
              -82.52475,
              27.645422
            ],
            [
              -82.524947,
              27.645422
            ],
            [
              -82.549145,
              27.645554
            ],
            [
              -82.55440720935509,
              27.645292633205898
            ],
            [
              -82.558538,
              27.638678
            ],
            [
              -82.565667,
              27.615713
            ],
            [
              -82.570607,
              27.608882
            ],
            [
              -82.584629,
              27.596021
            ],
            [
              -82.596488,
              27.594045
            ],
            [
              -82.611717,
              27.585283
            ],
            [
              -82.61274938786569,
              27.5833193758014
            ],
            [
              -82.613003,
              27.582837
            ],
            [
              -82.612019,
              27.571231
            ],
            [
              -82.632053,
              27.551908
            ],
            [
              -82.646014,
              27.53354
            ],
            [
              -82.65072,
              27.523115
            ],
            [
              -82.66202,
              27.522814
            ],
            [
              -82.674621,
              27.519614
            ],
            [
              -82.683621,
              27.513115
            ],
            [
              -82.686921,
              27.508015
            ],
            [
              -82.686421,
              27.497215
            ],
            [
              -82.690421,
              27.496415
            ],
            [
              -82.703028,
              27.497952
            ],
            [
              -82.708121,
              27.523514
            ],
            [
              -82.742437,
              27.53936
            ],
            [
              -82.745748,
              27.538834
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "125",
      "COUNTYNS": "00306913",
      "AFFGEOID": "0500000US12125",
      "GEOID": "12125",
      "NAME": "Union",
      "LSAD": "06",
      "ALAND": 630806849,
      "AWATER": 16049467,
      "County_state": "Union,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.573116,
              30.010651
            ],
            [
              -82.575991,
              29.990857
            ],
            [
              -82.56928,
              29.97498
            ],
            [
              -82.555357,
              29.964019
            ],
            [
              -82.533301,
              29.958357
            ],
            [
              -82.529705,
              29.940881
            ],
            [
              -82.525368,
              29.94496
            ],
            [
              -82.484248,
              29.939625
            ],
            [
              -82.472129,
              29.926547
            ],
            [
              -82.448948,
              29.931515
            ],
            [
              -82.426292,
              29.921807
            ],
            [
              -82.418728,
              29.923093
            ],
            [
              -82.390432,
              29.925572
            ],
            [
              -82.370062,
              29.935367
            ],
            [
              -82.359294,
              29.933265
            ],
            [
              -82.347686,
              29.941734
            ],
            [
              -82.34207,
              29.961416
            ],
            [
              -82.325209,
              29.955709
            ],
            [
              -82.306677,
              29.956612
            ],
            [
              -82.273215,
              29.999241
            ],
            [
              -82.259971,
              30.02809
            ],
            [
              -82.225638,
              30.034264
            ],
            [
              -82.207644,
              30.052717
            ],
            [
              -82.18644,
              30.06485
            ],
            [
              -82.177565,
              30.075243
            ],
            [
              -82.169681,
              30.099412
            ],
            [
              -82.14734,
              30.109985
            ],
            [
              -82.137909,
              30.127354
            ],
            [
              -82.142578,
              30.143117
            ],
            [
              -82.359381,
              30.139294
            ],
            [
              -82.443667,
              30.138086
            ],
            [
              -82.458364,
              30.136449
            ],
            [
              -82.452518,
              30.123887
            ],
            [
              -82.455985,
              30.105584
            ],
            [
              -82.468172,
              30.096968
            ],
            [
              -82.498186,
              30.089026
            ],
            [
              -82.519019,
              30.076011
            ],
            [
              -82.535415,
              30.055061
            ],
            [
              -82.544961,
              30.019995
            ],
            [
              -82.573116,
              30.010651
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "031",
      "COUNTYNS": "00293656",
      "AFFGEOID": "0500000US12031",
      "GEOID": "12031",
      "NAME": "Duval",
      "LSAD": "06",
      "ALAND": 1975764931,
      "AWATER": 403035529,
      "County_state": "Duval,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.049236,
              30.273432
            ],
            [
              -82.049411,
              30.186933
            ],
            [
              -81.837661,
              30.188624
            ],
            [
              -81.738924,
              30.189321
            ],
            [
              -81.73349,
              30.193466
            ],
            [
              -81.711348,
              30.191193
            ],
            [
              -81.703501,
              30.189025
            ],
            [
              -81.679626,
              30.190495
            ],
            [
              -81.690516,
              30.17194
            ],
            [
              -81.692116,
              30.14474
            ],
            [
              -81.680215,
              30.12124
            ],
            [
              -81.650514,
              30.121541
            ],
            [
              -81.623813,
              30.133641
            ],
            [
              -81.580882,
              30.1261
            ],
            [
              -81.567787,
              30.117789
            ],
            [
              -81.539009,
              30.119942
            ],
            [
              -81.538707,
              30.103748
            ],
            [
              -81.433783,
              30.105536
            ],
            [
              -81.436929,
              30.252335
            ],
            [
              -81.430807,
              30.252242
            ],
            [
              -81.3798792601458,
              30.252913638915
            ],
            [
              -81.385505,
              30.273841
            ],
            [
              -81.391606,
              30.303441
            ],
            [
              -81.396407,
              30.34004
            ],
            [
              -81.397422,
              30.400626
            ],
            [
              -81.407008,
              30.42204
            ],
            [
              -81.410809,
              30.482039
            ],
            [
              -81.42601,
              30.496739
            ],
            [
              -81.440108,
              30.497678
            ],
            [
              -81.447087,
              30.503679
            ],
            [
              -81.4427844968023,
              30.5099198266455
            ],
            [
              -81.450811,
              30.519238
            ],
            [
              -81.479939,
              30.533454
            ],
            [
              -81.499575,
              30.563793
            ],
            [
              -81.514014,
              30.567337
            ],
            [
              -81.523814,
              30.556337
            ],
            [
              -81.521375,
              30.538801
            ],
            [
              -81.530238,
              30.52629
            ],
            [
              -81.543018,
              30.523889
            ],
            [
              -81.555251,
              30.544528
            ],
            [
              -81.577218,
              30.538037
            ],
            [
              -81.588803,
              30.550468
            ],
            [
              -81.583239,
              30.564489
            ],
            [
              -81.592127,
              30.565326
            ],
            [
              -81.610212,
              30.55438
            ],
            [
              -81.614269,
              30.562383
            ],
            [
              -81.60146,
              30.567665
            ],
            [
              -81.608717,
              30.574892
            ],
            [
              -81.604608,
              30.584864
            ],
            [
              -81.624513,
              30.586232
            ],
            [
              -81.649078,
              30.576795
            ],
            [
              -81.666669,
              30.551701
            ],
            [
              -81.667833,
              30.560671
            ],
            [
              -81.69756,
              30.569643
            ],
            [
              -81.704592,
              30.563643
            ],
            [
              -81.721883,
              30.571934
            ],
            [
              -81.73521,
              30.559031
            ],
            [
              -81.738424,
              30.534572
            ],
            [
              -81.763748,
              30.534916
            ],
            [
              -81.765322,
              30.51844
            ],
            [
              -81.781624,
              30.503336
            ],
            [
              -81.794918,
              30.505406
            ],
            [
              -81.809526,
              30.484537
            ],
            [
              -81.831227,
              30.467437
            ],
            [
              -81.935495,
              30.373981
            ],
            [
              -82.037542,
              30.283457
            ],
            [
              -82.049236,
              30.273432
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "129",
      "COUNTYNS": "00306912",
      "AFFGEOID": "0500000US12129",
      "GEOID": "12129",
      "NAME": "Wakulla",
      "LSAD": "06",
      "ALAND": 1570625279,
      "AWATER": 334901690,
      "County_state": "Wakulla,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.729898,
              30.2862
            ],
            [
              -84.737846,
              30.265646
            ],
            [
              -84.69825,
              30.244781
            ],
            [
              -84.701027,
              30.231972
            ],
            [
              -84.681871,
              30.21771
            ],
            [
              -84.678255,
              30.18809
            ],
            [
              -84.668251,
              30.182081
            ],
            [
              -84.672538,
              30.150406
            ],
            [
              -84.670364,
              30.128208
            ],
            [
              -84.648436,
              30.10808
            ],
            [
              -84.654549,
              30.09992
            ],
            [
              -84.637886,
              30.083738
            ],
            [
              -84.626084,
              30.062392
            ],
            [
              -84.609777,
              30.059719
            ],
            [
              -84.591048,
              30.029637
            ],
            [
              -84.54445,
              30.01103
            ],
            [
              -84.531218,
              30.007366
            ],
            [
              -84.51896,
              29.995129
            ],
            [
              -84.523992,
              29.981728
            ],
            [
              -84.51413,
              29.989076
            ],
            [
              -84.491511,
              29.987117
            ],
            [
              -84.467713,
              30.001853
            ],
            [
              -84.452486,
              29.971247
            ],
            [
              -84.444191,
              29.981799
            ],
            [
              -84.437406,
              29.977028
            ],
            [
              -84.3412610838955,
              29.960775491222797
            ],
            [
              -84.343041,
              29.9751
            ],
            [
              -84.3477,
              29.984123
            ],
            [
              -84.359986,
              29.984739
            ],
            [
              -84.361962,
              29.987739
            ],
            [
              -84.366115,
              30.008662
            ],
            [
              -84.337132,
              30.032348
            ],
            [
              -84.297836,
              30.057451
            ],
            [
              -84.289727,
              30.057197
            ],
            [
              -84.277168,
              30.060263
            ],
            [
              -84.270792,
              30.068094
            ],
            [
              -84.270368,
              30.075469
            ],
            [
              -84.274003,
              30.083079
            ],
            [
              -84.272511,
              30.092358
            ],
            [
              -84.269363,
              30.09766
            ],
            [
              -84.256439,
              30.103791
            ],
            [
              -84.247491,
              30.10114
            ],
            [
              -84.245668,
              30.093021
            ],
            [
              -84.237014,
              30.08556
            ],
            [
              -84.20801,
              30.084776
            ],
            [
              -84.203349,
              30.085875
            ],
            [
              -84.201585,
              30.087982
            ],
            [
              -84.19853,
              30.087937
            ],
            [
              -84.179149,
              30.073187
            ],
            [
              -84.167881,
              30.071422
            ],
            [
              -84.157278,
              30.072714
            ],
            [
              -84.135683,
              30.083018
            ],
            [
              -84.124889,
              30.090601
            ],
            [
              -84.11384,
              30.085478
            ],
            [
              -84.10273,
              30.093611
            ],
            [
              -84.094725,
              30.094964
            ],
            [
              -84.087034,
              30.092103
            ],
            [
              -84.083057,
              30.092286
            ],
            [
              -84.0760425771092,
              30.0954641099777
            ],
            [
              -84.075539,
              30.273617
            ],
            [
              -84.241222,
              30.274244
            ],
            [
              -84.253997,
              30.303201
            ],
            [
              -84.285987,
              30.303301
            ],
            [
              -84.713351,
              30.30017
            ],
            [
              -84.729898,
              30.2862
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "063",
      "COUNTYNS": "00295734",
      "AFFGEOID": "0500000US12063",
      "GEOID": "12063",
      "NAME": "Jackson",
      "LSAD": "06",
      "ALAND": 2378066857,
      "AWATER": 94243008,
      "County_state": "Jackson,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.594882,
              30.859478
            ],
            [
              -85.599934,
              30.830552
            ],
            [
              -85.482276,
              30.829509
            ],
            [
              -85.482718,
              30.785892
            ],
            [
              -85.432274,
              30.785457
            ],
            [
              -85.434782,
              30.56756
            ],
            [
              -85.383948,
              30.566856
            ],
            [
              -85.171891,
              30.564336
            ],
            [
              -85.167687,
              30.607979
            ],
            [
              -84.932519,
              30.606393
            ],
            [
              -84.908119,
              30.619493
            ],
            [
              -84.88752,
              30.663792
            ],
            [
              -84.857921,
              30.698409
            ],
            [
              -84.864693,
              30.711542
            ],
            [
              -84.869877,
              30.721887
            ],
            [
              -84.875546,
              30.727481
            ],
            [
              -84.883947,
              30.732582
            ],
            [
              -84.885347,
              30.734982
            ],
            [
              -84.887648,
              30.741782
            ],
            [
              -84.896248,
              30.750582
            ],
            [
              -84.897748,
              30.751382
            ],
            [
              -84.900348,
              30.751882
            ],
            [
              -84.903248,
              30.751782
            ],
            [
              -84.906448,
              30.750582
            ],
            [
              -84.911249,
              30.751182
            ],
            [
              -84.913649,
              30.752282
            ],
            [
              -84.914449,
              30.753582
            ],
            [
              -84.915149,
              30.761183
            ],
            [
              -84.92025,
              30.765982
            ],
            [
              -84.91815,
              30.772082
            ],
            [
              -84.91755,
              30.775882
            ],
            [
              -84.91815,
              30.778082
            ],
            [
              -84.92685,
              30.790183
            ],
            [
              -84.92845,
              30.793083
            ],
            [
              -84.92915,
              30.797283
            ],
            [
              -84.92805,
              30.802783
            ],
            [
              -84.928451,
              30.805083
            ],
            [
              -84.931051,
              30.810482
            ],
            [
              -84.935541,
              30.817203
            ],
            [
              -84.93617,
              30.820665
            ],
            [
              -84.935698,
              30.824597
            ],
            [
              -84.935384,
              30.830888
            ],
            [
              -84.934283,
              30.834033
            ],
            [
              -84.932081,
              30.837493
            ],
            [
              -84.929564,
              30.840325
            ],
            [
              -84.928463,
              30.842526
            ],
            [
              -84.928463,
              30.844258
            ],
            [
              -84.928935,
              30.846774
            ],
            [
              -84.930193,
              30.848819
            ],
            [
              -84.933352,
              30.851483
            ],
            [
              -84.935384,
              30.854323
            ],
            [
              -84.935541,
              30.858413
            ],
            [
              -84.934755,
              30.860615
            ],
            [
              -84.934125,
              30.863288
            ],
            [
              -84.934755,
              30.86549
            ],
            [
              -84.935856,
              30.867535
            ],
            [
              -84.9379,
              30.870523
            ],
            [
              -84.938529,
              30.873041
            ],
            [
              -84.937743,
              30.875872
            ],
            [
              -84.935698,
              30.878703
            ],
            [
              -84.935541,
              30.882477
            ],
            [
              -84.936956,
              30.884679
            ],
            [
              -84.938215,
              30.885623
            ],
            [
              -84.940102,
              30.886724
            ],
            [
              -84.941453,
              30.887684
            ],
            [
              -84.942653,
              30.888484
            ],
            [
              -84.949754,
              30.897383
            ],
            [
              -84.952454,
              30.902284
            ],
            [
              -84.956254,
              30.907584
            ],
            [
              -84.966855,
              30.917284
            ],
            [
              -84.969555,
              30.921984
            ],
            [
              -84.971156,
              30.928184
            ],
            [
              -84.975356,
              30.930785
            ],
            [
              -84.980757,
              30.932685
            ],
            [
              -84.983257,
              30.934784
            ],
            [
              -84.983757,
              30.936984
            ],
            [
              -84.983157,
              30.942584
            ],
            [
              -84.982357,
              30.946884
            ],
            [
              -84.979757,
              30.954684
            ],
            [
              -84.979757,
              30.958985
            ],
            [
              -84.980257,
              30.961285
            ],
            [
              -84.982657,
              30.965585
            ],
            [
              -84.984957,
              30.967485
            ],
            [
              -84.988157,
              30.968785
            ],
            [
              -84.997759,
              30.971185
            ],
            [
              -84.999959,
              30.971485
            ],
            [
              -85.000059,
              30.971185
            ],
            [
              -85.004157,
              30.973467
            ],
            [
              -85.005236,
              30.974703
            ],
            [
              -85.006062,
              30.977039
            ],
            [
              -85.006065,
              30.979803
            ],
            [
              -85.002671,
              30.986898
            ],
            [
              -85.001846,
              30.997073
            ],
            [
              -85.0019,
              31.000681
            ],
            [
              -85.002499,
              31.000682
            ],
            [
              -85.024238,
              31.000681
            ],
            [
              -85.027642,
              31.00067
            ],
            [
              -85.030237,
              31.000653
            ],
            [
              -85.031285,
              31.000647
            ],
            [
              -85.052217,
              31.000584
            ],
            [
              -85.054931,
              31.000584
            ],
            [
              -85.057662,
              31.000584
            ],
            [
              -85.145959,
              31.000693
            ],
            [
              -85.152209,
              31.000886
            ],
            [
              -85.152342,
              31.000832
            ],
            [
              -85.154576,
              31.000833
            ],
            [
              -85.243752,
              31.000881
            ],
            [
              -85.4882980047342,
              30.9970787565989
            ],
            [
              -85.4979938168177,
              30.996928004442598
            ],
            [
              -85.52921,
              30.974439
            ],
            [
              -85.530294,
              30.939315
            ],
            [
              -85.547745,
              30.932185
            ],
            [
              -85.575574,
              30.898601
            ],
            [
              -85.594882,
              30.859478
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "073",
      "COUNTYNS": "00306916",
      "AFFGEOID": "0500000US12073",
      "GEOID": "12073",
      "NAME": "Leon",
      "LSAD": "06",
      "ALAND": 1727201409,
      "AWATER": 90424118,
      "County_state": "Leon,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.715144,
              30.333077
            ],
            [
              -84.701161,
              30.319345
            ],
            [
              -84.713351,
              30.30017
            ],
            [
              -84.285987,
              30.303301
            ],
            [
              -84.253997,
              30.303201
            ],
            [
              -84.241222,
              30.274244
            ],
            [
              -84.075539,
              30.273617
            ],
            [
              -84.074691,
              30.434302
            ],
            [
              -84.041293,
              30.47354
            ],
            [
              -84.041152,
              30.521901
            ],
            [
              -83.977165,
              30.521987
            ],
            [
              -83.986734,
              30.549561
            ],
            [
              -83.979013,
              30.550593
            ],
            [
              -83.998316,
              30.572578
            ],
            [
              -84.007687,
              30.609299
            ],
            [
              -84.007454,
              30.6720865150852
            ],
            [
              -84.007484,
              30.672088
            ],
            [
              -84.039802,
              30.67381
            ],
            [
              -84.041905,
              30.673869
            ],
            [
              -84.0467,
              30.674191
            ],
            [
              -84.057323,
              30.674696
            ],
            [
              -84.08375705716549,
              30.675802634493497
            ],
            [
              -84.107796,
              30.676809
            ],
            [
              -84.124993,
              30.678037
            ],
            [
              -84.250002,
              30.684136
            ],
            [
              -84.281313,
              30.685247
            ],
            [
              -84.2825636638089,
              30.685307571749597
            ],
            [
              -84.301153,
              30.680139
            ],
            [
              -84.298073,
              30.676832
            ],
            [
              -84.305324,
              30.670208
            ],
            [
              -84.29993,
              30.642624
            ],
            [
              -84.303969,
              30.634585
            ],
            [
              -84.322658,
              30.620609
            ],
            [
              -84.336224,
              30.596048
            ],
            [
              -84.371403,
              30.581107
            ],
            [
              -84.387626,
              30.550345
            ],
            [
              -84.388284,
              30.549325
            ],
            [
              -84.388434,
              30.546295
            ],
            [
              -84.388069,
              30.545361
            ],
            [
              -84.387474,
              30.54498
            ],
            [
              -84.387534,
              30.54422
            ],
            [
              -84.388253,
              30.543801
            ],
            [
              -84.388282,
              30.543005
            ],
            [
              -84.389222,
              30.540958
            ],
            [
              -84.390174,
              30.537437
            ],
            [
              -84.390878,
              30.535168
            ],
            [
              -84.390948,
              30.533672
            ],
            [
              -84.391453,
              30.532717
            ],
            [
              -84.391587,
              30.531492
            ],
            [
              -84.39108,
              30.53068
            ],
            [
              -84.390992,
              30.529416
            ],
            [
              -84.38949,
              30.529642
            ],
            [
              -84.388804,
              30.52939
            ],
            [
              -84.389611,
              30.52872
            ],
            [
              -84.3897,
              30.527898
            ],
            [
              -84.389414,
              30.527277
            ],
            [
              -84.388336,
              30.526709
            ],
            [
              -84.388303,
              30.526218
            ],
            [
              -84.404956,
              30.507737
            ],
            [
              -84.397233,
              30.483321
            ],
            [
              -84.407955,
              30.473094
            ],
            [
              -84.420474,
              30.463905
            ],
            [
              -84.519796,
              30.451499
            ],
            [
              -84.529375,
              30.44083
            ],
            [
              -84.559462,
              30.434025
            ],
            [
              -84.561463,
              30.437912
            ],
            [
              -84.568828,
              30.426861
            ],
            [
              -84.591357,
              30.411751
            ],
            [
              -84.60692,
              30.416136
            ],
            [
              -84.647668,
              30.394612
            ],
            [
              -84.646408,
              30.388281
            ],
            [
              -84.679396,
              30.368235
            ],
            [
              -84.68489,
              30.350114
            ],
            [
              -84.715144,
              30.333077
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "033",
      "COUNTYNS": "00295737",
      "AFFGEOID": "0500000US12033",
      "GEOID": "12033",
      "NAME": "Escambia",
      "LSAD": "06",
      "ALAND": 1701448275,
      "AWATER": 564025766,
      "County_state": "Escambia,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.629993,
              30.877657
            ],
            [
              -87.634943,
              30.865857
            ],
            [
              -87.62825,
              30.860102
            ],
            [
              -87.626233,
              30.857099
            ],
            [
              -87.625385,
              30.854327
            ],
            [
              -87.626502,
              30.851852
            ],
            [
              -87.627328,
              30.847933
            ],
            [
              -87.62608,
              30.846466
            ],
            [
              -87.624142,
              30.845685
            ],
            [
              -87.617287,
              30.840326
            ],
            [
              -87.615373,
              30.837004
            ],
            [
              -87.615928,
              30.834666
            ],
            [
              -87.610988,
              30.832605
            ],
            [
              -87.605782,
              30.831277
            ],
            [
              -87.603576,
              30.828597
            ],
            [
              -87.601636,
              30.825113
            ],
            [
              -87.600492,
              30.820601
            ],
            [
              -87.594303,
              30.816958
            ],
            [
              -87.587876,
              30.815011
            ],
            [
              -87.581876,
              30.812377
            ],
            [
              -87.576856,
              30.808138
            ],
            [
              -87.57205,
              30.800507
            ],
            [
              -87.568147,
              30.799063
            ],
            [
              -87.564216,
              30.796221
            ],
            [
              -87.560075,
              30.792234
            ],
            [
              -87.559491,
              30.790423
            ],
            [
              -87.554845,
              30.787101
            ],
            [
              -87.552962,
              30.786917
            ],
            [
              -87.552059,
              30.78623
            ],
            [
              -87.545052,
              30.778642
            ],
            [
              -87.545372,
              30.774082
            ],
            [
              -87.546168,
              30.771997
            ],
            [
              -87.542268,
              30.767481
            ],
            [
              -87.537093,
              30.762507
            ],
            [
              -87.536536,
              30.76136
            ],
            [
              -87.535424,
              30.754738
            ],
            [
              -87.535373,
              30.749753
            ],
            [
              -87.532615,
              30.743467
            ],
            [
              -87.523621,
              30.738285
            ],
            [
              -87.511738,
              30.733514
            ],
            [
              -87.505162,
              30.726292
            ],
            [
              -87.502326,
              30.72157
            ],
            [
              -87.497524,
              30.720103
            ],
            [
              -87.496781,
              30.720333
            ],
            [
              -87.487045,
              30.71848
            ],
            [
              -87.481235,
              30.716488
            ],
            [
              -87.479829,
              30.71493
            ],
            [
              -87.479589,
              30.712845
            ],
            [
              -87.474439,
              30.706567
            ],
            [
              -87.470407,
              30.705262
            ],
            [
              -87.467727,
              30.701664
            ],
            [
              -87.466348,
              30.700816
            ],
            [
              -87.456958,
              30.697541
            ],
            [
              -87.451415,
              30.699787
            ],
            [
              -87.449373,
              30.698894
            ],
            [
              -87.443591,
              30.694586
            ],
            [
              -87.442291,
              30.692661
            ],
            [
              -87.439825,
              30.690461
            ],
            [
              -87.436032,
              30.68865
            ],
            [
              -87.430384,
              30.688627
            ],
            [
              -87.424895,
              30.683356
            ],
            [
              -87.419539,
              30.679964
            ],
            [
              -87.412751,
              30.678038
            ],
            [
              -87.40697,
              30.675148
            ],
            [
              -87.406573,
              30.674002
            ],
            [
              -87.40713,
              30.671779
            ],
            [
              -87.405886,
              30.666599
            ],
            [
              -87.400719,
              30.657132
            ],
            [
              -87.400189,
              30.657201
            ],
            [
              -87.397274,
              30.654335
            ],
            [
              -87.396189,
              30.650438
            ],
            [
              -87.397197,
              30.648101
            ],
            [
              -87.395953,
              30.643953
            ],
            [
              -87.3936,
              30.630865
            ],
            [
              -87.393787,
              30.626991
            ],
            [
              -87.394491,
              30.625178
            ],
            [
              -87.395671,
              30.623358
            ],
            [
              -87.396442,
              30.61772
            ],
            [
              -87.396442,
              30.616895
            ],
            [
              -87.395065,
              30.615886
            ],
            [
              -87.395038,
              30.615267
            ],
            [
              -87.39732,
              30.608714
            ],
            [
              -87.399281,
              30.605597
            ],
            [
              -87.401189,
              30.604383
            ],
            [
              -87.404608,
              30.603375
            ],
            [
              -87.406569,
              30.599915
            ],
            [
              -87.408747,
              30.583688
            ],
            [
              -87.412722,
              30.573215
            ],
            [
              -87.414523,
              30.573444
            ],
            [
              -87.416271,
              30.572436
            ],
            [
              -87.418364,
              30.570031
            ],
            [
              -87.418523,
              30.569549
            ],
            [
              -87.416961,
              30.567991
            ],
            [
              -87.41667,
              30.566294
            ],
            [
              -87.418657,
              30.561825
            ],
            [
              -87.420935,
              30.560656
            ],
            [
              -87.422418,
              30.560427
            ],
            [
              -87.422815,
              30.561367
            ],
            [
              -87.423372,
              30.561413
            ],
            [
              -87.426046,
              30.560061
            ],
            [
              -87.4279,
              30.554147
            ],
            [
              -87.43145,
              30.550252
            ],
            [
              -87.434972,
              30.549588
            ],
            [
              -87.435449,
              30.549129
            ],
            [
              -87.446594,
              30.527057
            ],
            [
              -87.446435,
              30.520296
            ],
            [
              -87.444952,
              30.514933
            ],
            [
              -87.44519,
              30.51397
            ],
            [
              -87.446507,
              30.513559
            ],
            [
              -87.447313,
              30.512619
            ],
            [
              -87.447789,
              30.511903
            ],
            [
              -87.447709,
              30.510448
            ],
            [
              -87.444722,
              30.507484
            ],
            [
              -87.443228,
              30.506772
            ],
            [
              -87.439698,
              30.506639
            ],
            [
              -87.438277,
              30.505348
            ],
            [
              -87.431186,
              30.495786
            ],
            [
              -87.430586,
              30.491087
            ],
            [
              -87.432986,
              30.484888
            ],
            [
              -87.435585,
              30.480488
            ],
            [
              -87.434686,
              30.479188
            ],
            [
              -87.431586,
              30.477688
            ],
            [
              -87.430586,
              30.476588
            ],
            [
              -87.429586,
              30.470588
            ],
            [
              -87.425086,
              30.465589
            ],
            [
              -87.414685,
              30.457289
            ],
            [
              -87.407885,
              30.456389
            ],
            [
              -87.404685,
              30.45289
            ],
            [
              -87.399886,
              30.450991
            ],
            [
              -87.396886,
              30.450591
            ],
            [
              -87.391985,
              30.451591
            ],
            [
              -87.381185,
              30.450091
            ],
            [
              -87.370778,
              30.446859
            ],
            [
              -87.36869,
              30.444625
            ],
            [
              -87.366949,
              30.440475
            ],
            [
              -87.366601,
              30.436643
            ],
            [
              -87.368201,
              30.433402
            ],
            [
              -87.371179,
              30.430485
            ],
            [
              -87.382085,
              30.422892
            ],
            [
              -87.3843547793814,
              30.421625146391797
            ],
            [
              -87.386385,
              30.420492
            ],
            [
              -87.395684,
              30.417592
            ],
            [
              -87.398784,
              30.415093
            ],
            [
              -87.401785,
              30.411393
            ],
            [
              -87.403485,
              30.410193
            ],
            [
              -87.408884,
              30.408793
            ],
            [
              -87.413184,
              30.408993
            ],
            [
              -87.419184,
              30.410193
            ],
            [
              -87.422684,
              30.410093
            ],
            [
              -87.426184,
              30.409193
            ],
            [
              -87.429584,
              30.406493
            ],
            [
              -87.4306873846154,
              30.404837923076897
            ],
            [
              -87.431784,
              30.403193
            ],
            [
              -87.434284,
              30.397494
            ],
            [
              -87.437284,
              30.395894
            ],
            [
              -87.440684,
              30.391494
            ],
            [
              -87.441184,
              30.388594
            ],
            [
              -87.440884,
              30.386694
            ],
            [
              -87.438684,
              30.382094
            ],
            [
              -87.438684,
              30.380794
            ],
            [
              -87.441828,
              30.376301
            ],
            [
              -87.449083,
              30.370396
            ],
            [
              -87.451383,
              30.367196
            ],
            [
              -87.451883,
              30.364996
            ],
            [
              -87.451983,
              30.360296
            ],
            [
              -87.450966,
              30.34626
            ],
            [
              -87.452282,
              30.344097
            ],
            [
              -87.459982,
              30.336298
            ],
            [
              -87.462982,
              30.333998
            ],
            [
              -87.464881,
              30.333298
            ],
            [
              -87.481257,
              30.32657
            ],
            [
              -87.490642,
              30.323234
            ],
            [
              -87.496163,
              30.317992
            ],
            [
              -87.492851,
              30.311797
            ],
            [
              -87.485674,
              30.308937
            ],
            [
              -87.464143,
              30.311797
            ],
            [
              -87.466903,
              30.302264
            ],
            [
              -87.509407,
              30.292188
            ],
            [
              -87.51838,
              30.283901
            ],
            [
              -87.518324,
              30.280435
            ],
            [
              -87.419859,
              30.297128
            ],
            [
              -87.350486,
              30.313064
            ],
            [
              -87.319518,
              30.317814
            ],
            [
              -87.295422,
              30.323503
            ],
            [
              -87.282787,
              30.318744
            ],
            [
              -87.267827,
              30.31548
            ],
            [
              -87.206254,
              30.320943
            ],
            [
              -87.155392,
              30.327748
            ],
            [
              -86.91929208669279,
              30.370675169612497
            ],
            [
              -86.920113,
              30.386567
            ],
            [
              -87.074605,
              30.362942
            ],
            [
              -87.140367,
              30.347899
            ],
            [
              -87.152968,
              30.348399
            ],
            [
              -87.178968,
              30.334599
            ],
            [
              -87.210969,
              30.339899
            ],
            [
              -87.23317,
              30.349199
            ],
            [
              -87.23407,
              30.370498
            ],
            [
              -87.228226,
              30.384246
            ],
            [
              -87.152568,
              30.405798
            ],
            [
              -87.14568,
              30.410982
            ],
            [
              -87.134432,
              30.424549
            ],
            [
              -87.138016,
              30.451909
            ],
            [
              -87.124568,
              30.485096
            ],
            [
              -87.124968,
              30.500196
            ],
            [
              -87.157856,
              30.53481
            ],
            [
              -87.167869,
              30.541595
            ],
            [
              -87.209715,
              30.555266
            ],
            [
              -87.23658,
              30.596609
            ],
            [
              -87.258884,
              30.611281
            ],
            [
              -87.25226,
              30.62336
            ],
            [
              -87.264776,
              30.649645
            ],
            [
              -87.2655,
              30.687997
            ],
            [
              -87.273086,
              30.717318
            ],
            [
              -87.302661,
              30.71937
            ],
            [
              -87.312304,
              30.735071
            ],
            [
              -87.302044,
              30.760623
            ],
            [
              -87.311883,
              30.78649
            ],
            [
              -87.293084,
              30.812349
            ],
            [
              -87.302532,
              30.842238
            ],
            [
              -87.313611,
              30.847266
            ],
            [
              -87.298484,
              30.880434
            ],
            [
              -87.284486,
              30.887986
            ],
            [
              -87.282179,
              30.9104
            ],
            [
              -87.286882,
              30.925441
            ],
            [
              -87.26721,
              30.934982
            ],
            [
              -87.255496,
              30.931591
            ],
            [
              -87.227635,
              30.961112
            ],
            [
              -87.204893,
              30.969878
            ],
            [
              -87.1630788827798,
              30.999012791614096
            ],
            [
              -87.224773,
              30.997139
            ],
            [
              -87.2295403631042,
              30.996853061387498
            ],
            [
              -87.237711,
              30.996363
            ],
            [
              -87.255005,
              30.998255
            ],
            [
              -87.255617,
              30.998186
            ],
            [
              -87.257027,
              30.998164
            ],
            [
              -87.257985,
              30.998233
            ],
            [
              -87.259714,
              30.998142
            ],
            [
              -87.260565,
              30.998165
            ],
            [
              -87.265589,
              30.998237
            ],
            [
              -87.288929,
              30.998315
            ],
            [
              -87.291019,
              30.998322
            ],
            [
              -87.30159,
              30.998403
            ],
            [
              -87.304053,
              30.99816
            ],
            [
              -87.312206,
              30.998404
            ],
            [
              -87.333995,
              30.998241
            ],
            [
              -87.355677,
              30.998213
            ],
            [
              -87.364031,
              30.998187
            ],
            [
              -87.367862,
              30.99826
            ],
            [
              -87.425791,
              30.998058
            ],
            [
              -87.432309,
              30.998173
            ],
            [
              -87.449827,
              30.998239
            ],
            [
              -87.455721,
              30.998285
            ],
            [
              -87.458674,
              30.998353
            ],
            [
              -87.461654,
              30.998169
            ],
            [
              -87.461799,
              30.998168
            ],
            [
              -87.466842,
              30.998145
            ],
            [
              -87.466894,
              30.998145
            ],
            [
              -87.478721,
              30.99818
            ],
            [
              -87.479718,
              30.998164
            ],
            [
              -87.480258,
              30.998169
            ],
            [
              -87.519533,
              30.997552
            ],
            [
              -87.548555,
              30.997893
            ],
            [
              -87.571292,
              30.997836
            ],
            [
              -87.5988285301321,
              30.9974236243995
            ],
            [
              -87.598937,
              30.997422
            ],
            [
              -87.599181,
              30.995688
            ],
            [
              -87.596731,
              30.987576
            ],
            [
              -87.593404,
              30.982925
            ],
            [
              -87.592685,
              30.980106
            ],
            [
              -87.594173,
              30.977538
            ],
            [
              -87.59412,
              30.976301
            ],
            [
              -87.593055,
              30.972933
            ],
            [
              -87.590926,
              30.969381
            ],
            [
              -87.589196,
              30.964431
            ],
            [
              -87.592064,
              30.95146
            ],
            [
              -87.596898,
              30.941099
            ],
            [
              -87.598307,
              30.938761
            ],
            [
              -87.600699,
              30.937042
            ],
            [
              -87.602692,
              30.934245
            ],
            [
              -87.607818,
              30.924459
            ],
            [
              -87.608269,
              30.921869
            ],
            [
              -87.610207,
              30.916597
            ],
            [
              -87.611854,
              30.91451
            ],
            [
              -87.614216,
              30.908505
            ],
            [
              -87.614958,
              30.904196
            ],
            [
              -87.61602,
              30.901423
            ],
            [
              -87.620721,
              30.8989
            ],
            [
              -87.622209,
              30.897478
            ],
            [
              -87.622525,
              30.89365
            ],
            [
              -87.620928,
              30.889893
            ],
            [
              -87.620794,
              30.887464
            ],
            [
              -87.622068,
              30.885378
            ],
            [
              -87.624406,
              30.884666
            ],
            [
              -87.62946,
              30.880085
            ],
            [
              -87.629993,
              30.877657
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "086",
      "COUNTYNS": "00295755",
      "AFFGEOID": "0500000US12086",
      "GEOID": "12086",
      "NAME": "Miami-Dade",
      "LSAD": "06",
      "ALAND": 4917514967,
      "AWATER": 1379206478,
      "County_state": "Miami-Dade,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.2699420002125,
              25.352315270448596
            ],
            [
              -80.265472,
              25.354296
            ],
            [
              -80.262374,
              25.353991
            ],
            [
              -80.259875,
              25.352791
            ],
            [
              -80.256475,
              25.350691
            ],
            [
              -80.255375,
              25.348892
            ],
            [
              -80.253575,
              25.344591
            ],
            [
              -80.2516616347018,
              25.3425592802016
            ],
            [
              -80.250282,
              25.344791
            ],
            [
              -80.203829,
              25.414497
            ],
            [
              -80.176668,
              25.485896
            ],
            [
              -80.176024,
              25.521154
            ],
            [
              -80.200226,
              25.48537
            ],
            [
              -80.249584,
              25.394938
            ],
            [
              -80.2699420002125,
              25.352315270448596
            ]
          ]
        ],
        [
          [
            [
              -80.873153,
              25.66618
            ],
            [
              -80.87319,
              25.363993
            ],
            [
              -80.862191,
              25.364193
            ],
            [
              -80.857989,
              25.243
            ],
            [
              -80.8579854115429,
              25.1766094650017
            ],
            [
              -80.848937,
              25.178277
            ],
            [
              -80.8407,
              25.176818
            ],
            [
              -80.822268,
              25.174239
            ],
            [
              -80.812133,
              25.186039
            ],
            [
              -80.794576,
              25.182373
            ],
            [
              -80.785123,
              25.168928
            ],
            [
              -80.747746,
              25.147441
            ],
            [
              -80.743931,
              25.149804
            ],
            [
              -80.743504,
              25.163984
            ],
            [
              -80.724407,
              25.166335
            ],
            [
              -80.713035,
              25.152196
            ],
            [
              -80.703095,
              25.153558
            ],
            [
              -80.677623,
              25.169346
            ],
            [
              -80.664026,
              25.187262
            ],
            [
              -80.638888,
              25.190679
            ],
            [
              -80.609773,
              25.200357
            ],
            [
              -80.58762,
              25.200691
            ],
            [
              -80.58341562832071,
              25.201219741871597
            ],
            [
              -80.5692154773562,
              25.2030055531583
            ],
            [
              -80.542391,
              25.206379
            ],
            [
              -80.521627,
              25.221538
            ],
            [
              -80.514541,
              25.219182
            ],
            [
              -80.50487,
              25.206584
            ],
            [
              -80.495394,
              25.199808
            ],
            [
              -80.501235,
              25.210137
            ],
            [
              -80.487215,
              25.207126
            ],
            [
              -80.494176,
              25.227117
            ],
            [
              -80.482826,
              25.224786
            ],
            [
              -80.450659,
              25.240241
            ],
            [
              -80.41887,
              25.23631
            ],
            [
              -80.410955,
              25.253466
            ],
            [
              -80.394931,
              25.253537
            ],
            [
              -80.395081,
              25.272937
            ],
            [
              -80.379149,
              25.287859
            ],
            [
              -80.379021,
              25.305895
            ],
            [
              -80.3763480504136,
              25.3058717488906
            ],
            [
              -80.374116,
              25.31735
            ],
            [
              -80.361662,
              25.327433
            ],
            [
              -80.352469,
              25.329805
            ],
            [
              -80.335269,
              25.338701
            ],
            [
              -80.31036,
              25.3731
            ],
            [
              -80.306801,
              25.384369
            ],
            [
              -80.31036,
              25.389707
            ],
            [
              -80.320442,
              25.391486
            ],
            [
              -80.32578,
              25.39801
            ],
            [
              -80.326373,
              25.422919
            ],
            [
              -80.320442,
              25.437153
            ],
            [
              -80.328152,
              25.443084
            ],
            [
              -80.337049,
              25.465621
            ],
            [
              -80.339421,
              25.478669
            ],
            [
              -80.339421,
              25.499427
            ],
            [
              -80.328746,
              25.53264
            ],
            [
              -80.3256323530842,
              25.5348634978577
            ],
            [
              -80.3250431006848,
              25.5352842910572
            ],
            [
              -80.324594,
              25.535605
            ],
            [
              -80.313918,
              25.539164
            ],
            [
              -80.302057,
              25.567632
            ],
            [
              -80.305615,
              25.575342
            ],
            [
              -80.305615,
              25.593134
            ],
            [
              -80.301464,
              25.613299
            ],
            [
              -80.296719,
              25.622195
            ],
            [
              -80.288416,
              25.630498
            ],
            [
              -80.277147,
              25.637022
            ],
            [
              -80.267065,
              25.651849
            ],
            [
              -80.2663298657793,
              25.6558928580574
            ],
            [
              -80.265879,
              25.658373
            ],
            [
              -80.250459,
              25.688028
            ],
            [
              -80.244528,
              25.717089
            ],
            [
              -80.240376,
              25.724206
            ],
            [
              -80.229107,
              25.732509
            ],
            [
              -80.197674,
              25.74437
            ],
            [
              -80.184626,
              25.745557
            ],
            [
              -80.172765,
              25.737847
            ],
            [
              -80.166241,
              25.72895
            ],
            [
              -80.164461,
              25.721833
            ],
            [
              -80.170392,
              25.710565
            ],
            [
              -80.176916,
              25.685062
            ],
            [
              -80.160903,
              25.664897
            ],
            [
              -80.154972,
              25.66549
            ],
            [
              -80.152303,
              25.676759
            ],
            [
              -80.154082,
              25.683283
            ],
            [
              -80.152896,
              25.702855
            ],
            [
              -80.144,
              25.740812
            ],
            [
              -80.137476,
              25.750301
            ],
            [
              -80.13631241692109,
              25.7529918701742
            ],
            [
              -80.132488387425,
              25.7618352154438
            ],
            [
              -80.127987,
              25.772245
            ],
            [
              -80.127394,
              25.791224
            ],
            [
              -80.122056,
              25.817913
            ],
            [
              -80.119684,
              25.841043
            ],
            [
              -80.1205716954667,
              25.8725606799379
            ],
            [
              -80.12087,
              25.883152
            ],
            [
              -80.117904,
              25.915772
            ],
            [
              -80.11779801968059,
              25.975152100031597
            ],
            [
              -80.123874,
              25.974484
            ],
            [
              -80.147864,
              25.974408
            ],
            [
              -80.148063,
              25.974407
            ],
            [
              -80.165724,
              25.973783
            ],
            [
              -80.206228,
              25.972696
            ],
            [
              -80.229593,
              25.972333
            ],
            [
              -80.246715,
              25.971875
            ],
            [
              -80.295187,
              25.97057
            ],
            [
              -80.294972,
              25.95677
            ],
            [
              -80.310617,
              25.957165
            ],
            [
              -80.326712,
              25.957118
            ],
            [
              -80.374977,
              25.957073
            ],
            [
              -80.375067,
              25.957072
            ],
            [
              -80.431359,
              25.956965
            ],
            [
              -80.44049,
              25.956861
            ],
            [
              -80.680016,
              25.956857
            ],
            [
              -80.680038,
              25.978749
            ],
            [
              -80.872932,
              25.979434
            ],
            [
              -80.873096,
              25.805377
            ],
            [
              -80.873125,
              25.760885
            ],
            [
              -80.873153,
              25.66618
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "007",
      "COUNTYNS": "00303634",
      "AFFGEOID": "0500000US12007",
      "GEOID": "12007",
      "NAME": "Bradford",
      "LSAD": "06",
      "ALAND": 761362376,
      "AWATER": 16904945,
      "County_state": "Bradford,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.418728,
              29.923093
            ],
            [
              -82.406629,
              29.914054
            ],
            [
              -82.398771,
              29.887319
            ],
            [
              -82.375003,
              29.879433
            ],
            [
              -82.335957,
              29.878848
            ],
            [
              -82.313914,
              29.859959
            ],
            [
              -82.272563,
              29.843011
            ],
            [
              -82.24245,
              29.852459
            ],
            [
              -82.233514,
              29.845297
            ],
            [
              -82.190271,
              29.848087
            ],
            [
              -82.173204,
              29.836976
            ],
            [
              -82.133126,
              29.835949
            ],
            [
              -82.123923,
              29.823686
            ],
            [
              -82.124054,
              29.805289
            ],
            [
              -82.109123,
              29.783085
            ],
            [
              -82.079348,
              29.776005
            ],
            [
              -82.082095,
              29.763572
            ],
            [
              -82.05807,
              29.748059
            ],
            [
              -82.055625,
              29.718232
            ],
            [
              -82.049244,
              29.71867
            ],
            [
              -82.046112,
              29.747129
            ],
            [
              -82.049201,
              30.055904
            ],
            [
              -82.049425,
              30.143136
            ],
            [
              -82.142578,
              30.143117
            ],
            [
              -82.137909,
              30.127354
            ],
            [
              -82.14734,
              30.109985
            ],
            [
              -82.169681,
              30.099412
            ],
            [
              -82.177565,
              30.075243
            ],
            [
              -82.18644,
              30.06485
            ],
            [
              -82.207644,
              30.052717
            ],
            [
              -82.225638,
              30.034264
            ],
            [
              -82.259971,
              30.02809
            ],
            [
              -82.273215,
              29.999241
            ],
            [
              -82.306677,
              29.956612
            ],
            [
              -82.325209,
              29.955709
            ],
            [
              -82.34207,
              29.961416
            ],
            [
              -82.347686,
              29.941734
            ],
            [
              -82.359294,
              29.933265
            ],
            [
              -82.370062,
              29.935367
            ],
            [
              -82.390432,
              29.925572
            ],
            [
              -82.418728,
              29.923093
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "123",
      "COUNTYNS": "00295728",
      "AFFGEOID": "0500000US12123",
      "GEOID": "12123",
      "NAME": "Taylor",
      "LSAD": "06",
      "ALAND": 2702180626,
      "AWATER": 489194665,
      "County_state": "Taylor,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.992184,
              30.096208
            ],
            [
              -83.991607,
              30.08392
            ],
            [
              -83.95968,
              30.064943
            ],
            [
              -83.933432,
              30.046305
            ],
            [
              -83.931879,
              30.044175
            ],
            [
              -83.933668,
              30.041152
            ],
            [
              -83.93151,
              30.039068
            ],
            [
              -83.845427,
              29.998068
            ],
            [
              -83.82869,
              29.983187
            ],
            [
              -83.788729,
              29.976982
            ],
            [
              -83.757249,
              29.957943
            ],
            [
              -83.686423,
              29.923735
            ],
            [
              -83.679219,
              29.918513
            ],
            [
              -83.659951,
              29.899524
            ],
            [
              -83.63798,
              29.886073
            ],
            [
              -83.618568,
              29.842336
            ],
            [
              -83.605244,
              29.836387
            ],
            [
              -83.595493,
              29.827984
            ],
            [
              -83.585899,
              29.811754
            ],
            [
              -83.581903,
              29.792063
            ],
            [
              -83.583045,
              29.787307
            ],
            [
              -83.586089,
              29.784644
            ],
            [
              -83.584716,
              29.77608
            ],
            [
              -83.578955,
              29.768378
            ],
            [
              -83.566018,
              29.761434
            ],
            [
              -83.554993,
              29.7426
            ],
            [
              -83.547172,
              29.732223
            ],
            [
              -83.537645,
              29.72306
            ],
            [
              -83.512716,
              29.71648
            ],
            [
              -83.493728,
              29.708388
            ],
            [
              -83.483567,
              29.698542
            ],
            [
              -83.483143,
              29.690478
            ],
            [
              -83.455356,
              29.676444
            ],
            [
              -83.448194,
              29.675254
            ],
            [
              -83.444635,
              29.677155
            ],
            [
              -83.436259,
              29.677389
            ],
            [
              -83.414701,
              29.670536
            ],
            [
              -83.412768,
              29.668485
            ],
            [
              -83.4122777424356,
              29.6669224627392
            ],
            [
              -83.398512,
              29.673935
            ],
            [
              -83.366398,
              29.665701
            ],
            [
              -83.364994,
              29.676715
            ],
            [
              -83.351311,
              29.694292
            ],
            [
              -83.344902,
              29.726811
            ],
            [
              -83.345676,
              29.74349
            ],
            [
              -83.336561,
              29.760721
            ],
            [
              -83.319462,
              29.778328
            ],
            [
              -83.318859,
              29.822623
            ],
            [
              -83.35286,
              29.822222
            ],
            [
              -83.352986,
              29.887859
            ],
            [
              -83.37089,
              29.887934
            ],
            [
              -83.368564,
              30.187217
            ],
            [
              -83.36678,
              30.20109
            ],
            [
              -83.36726,
              30.26044
            ],
            [
              -83.469267,
              30.260351
            ],
            [
              -83.468768,
              30.304212
            ],
            [
              -83.820235,
              30.30354
            ],
            [
              -83.837652,
              30.280764
            ],
            [
              -83.856987,
              30.273318
            ],
            [
              -83.88167,
              30.267609
            ],
            [
              -83.902462,
              30.247553
            ],
            [
              -83.915533,
              30.245386
            ],
            [
              -83.921603,
              30.228779
            ],
            [
              -83.916561,
              30.223423
            ],
            [
              -83.93101,
              30.189123
            ],
            [
              -83.951299,
              30.186282
            ],
            [
              -83.960354,
              30.152398
            ],
            [
              -83.97306,
              30.146037
            ],
            [
              -83.968628,
              30.13617
            ],
            [
              -83.992184,
              30.096208
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "011",
      "COUNTYNS": "00295753",
      "AFFGEOID": "0500000US12011",
      "GEOID": "12011",
      "NAME": "Broward",
      "LSAD": "06",
      "ALAND": 3120024303,
      "AWATER": 306053086,
      "County_state": "Broward,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.881233,
              26.333806
            ],
            [
              -80.879809,
              26.259455
            ],
            [
              -80.879149,
              26.172879
            ],
            [
              -80.879142,
              26.172544
            ],
            [
              -80.872932,
              25.979434
            ],
            [
              -80.680038,
              25.978749
            ],
            [
              -80.680016,
              25.956857
            ],
            [
              -80.44049,
              25.956861
            ],
            [
              -80.431359,
              25.956965
            ],
            [
              -80.375067,
              25.957072
            ],
            [
              -80.374977,
              25.957073
            ],
            [
              -80.326712,
              25.957118
            ],
            [
              -80.310617,
              25.957165
            ],
            [
              -80.294972,
              25.95677
            ],
            [
              -80.295187,
              25.97057
            ],
            [
              -80.246715,
              25.971875
            ],
            [
              -80.229593,
              25.972333
            ],
            [
              -80.206228,
              25.972696
            ],
            [
              -80.165724,
              25.973783
            ],
            [
              -80.148063,
              25.974407
            ],
            [
              -80.147864,
              25.974408
            ],
            [
              -80.123874,
              25.974484
            ],
            [
              -80.11779801968059,
              25.975152100031597
            ],
            [
              -80.117778,
              25.986369
            ],
            [
              -80.109566,
              26.087165
            ],
            [
              -80.1066626745893,
              26.0933085716075
            ],
            [
              -80.105266,
              26.096264
            ],
            [
              -80.101366,
              26.147762
            ],
            [
              -80.089365,
              26.231859
            ],
            [
              -80.085565,
              26.249259
            ],
            [
              -80.079865,
              26.264358
            ],
            [
              -80.075264,
              26.318656
            ],
            [
              -80.0748371491155,
              26.3210321365904
            ],
            [
              -80.0927,
              26.326327
            ],
            [
              -80.098823,
              26.322834
            ],
            [
              -80.099017,
              26.327321
            ],
            [
              -80.120125,
              26.327791
            ],
            [
              -80.169917,
              26.327806
            ],
            [
              -80.170149,
              26.327781
            ],
            [
              -80.170332,
              26.327782
            ],
            [
              -80.204083,
              26.327822
            ],
            [
              -80.24977,
              26.341526
            ],
            [
              -80.296881,
              26.355431
            ],
            [
              -80.296935,
              26.334356
            ],
            [
              -80.297471,
              26.334356
            ],
            [
              -80.881233,
              26.333806
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "105",
      "COUNTYNS": "00295747",
      "AFFGEOID": "0500000US12105",
      "GEOID": "12105",
      "NAME": "Polk",
      "LSAD": "06",
      "ALAND": 4653579490,
      "AWATER": 553462009,
      "County_state": "Polk,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.106206,
              28.25924
            ],
            [
              -82.105853,
              28.17165
            ],
            [
              -82.056261,
              28.171592
            ],
            [
              -82.056502,
              28.113252
            ],
            [
              -82.056159,
              28.047552
            ],
            [
              -82.055389,
              27.952862
            ],
            [
              -82.05526,
              27.92718
            ],
            [
              -82.055256,
              27.927001
            ],
            [
              -82.054874,
              27.907726
            ],
            [
              -82.054349,
              27.646382
            ],
            [
              -81.563318,
              27.646647
            ],
            [
              -81.529961,
              27.646422
            ],
            [
              -81.142164,
              27.643238
            ],
            [
              -81.131495,
              27.6494
            ],
            [
              -81.131842,
              27.661509
            ],
            [
              -81.144358,
              27.669794
            ],
            [
              -81.149001,
              27.692032
            ],
            [
              -81.145954,
              27.710533
            ],
            [
              -81.168735,
              27.725993
            ],
            [
              -81.165317,
              27.742657
            ],
            [
              -81.1785,
              27.751936
            ],
            [
              -81.172957,
              27.759492
            ],
            [
              -81.196307,
              27.790769
            ],
            [
              -81.208124,
              27.821125
            ],
            [
              -81.230079,
              27.842351
            ],
            [
              -81.259107,
              27.845985
            ],
            [
              -81.281324,
              27.857472
            ],
            [
              -81.301862,
              27.862081
            ],
            [
              -81.306108,
              27.886176
            ],
            [
              -81.314909,
              27.901244
            ],
            [
              -81.308696,
              27.92188
            ],
            [
              -81.338664,
              27.931336
            ],
            [
              -81.33665,
              27.94958
            ],
            [
              -81.349831,
              27.963758
            ],
            [
              -81.376505,
              27.978251
            ],
            [
              -81.386752,
              28.003321
            ],
            [
              -81.41543,
              27.998172
            ],
            [
              -81.424547,
              28.012476
            ],
            [
              -81.440297,
              28.033872
            ],
            [
              -81.458817,
              28.039775
            ],
            [
              -81.459687,
              28.051809
            ],
            [
              -81.44343,
              28.059179
            ],
            [
              -81.432532,
              28.04326
            ],
            [
              -81.405847,
              28.028965
            ],
            [
              -81.393971,
              28.028027
            ],
            [
              -81.382476,
              28.012361
            ],
            [
              -81.375813,
              28.029489
            ],
            [
              -81.363723,
              28.037763
            ],
            [
              -81.366811,
              28.061667
            ],
            [
              -81.346692,
              28.075282
            ],
            [
              -81.346418,
              28.084696
            ],
            [
              -81.455758,
              28.08489
            ],
            [
              -81.456214,
              28.143163
            ],
            [
              -81.469609,
              28.14326
            ],
            [
              -81.47009,
              28.143281
            ],
            [
              -81.524227,
              28.142805
            ],
            [
              -81.524083,
              28.201012
            ],
            [
              -81.551345,
              28.249045
            ],
            [
              -81.557459,
              28.259362
            ],
            [
              -81.657189,
              28.259217
            ],
            [
              -81.657173,
              28.264513
            ],
            [
              -81.657268,
              28.347098
            ],
            [
              -81.790727,
              28.346413
            ],
            [
              -81.790887,
              28.361837
            ],
            [
              -81.857624,
              28.361868
            ],
            [
              -81.857512,
              28.345965
            ],
            [
              -81.957641,
              28.34518
            ],
            [
              -81.957805,
              28.308888
            ],
            [
              -81.985781,
              28.30532
            ],
            [
              -81.994703,
              28.317927
            ],
            [
              -82.02056,
              28.323783
            ],
            [
              -82.055819,
              28.312794
            ],
            [
              -82.055531,
              28.258928
            ],
            [
              -82.106206,
              28.25924
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "009",
      "COUNTYNS": "00295749",
      "AFFGEOID": "0500000US12009",
      "GEOID": "12009",
      "NAME": "Brevard",
      "LSAD": "06",
      "ALAND": 2629333877,
      "AWATER": 1403340249,
      "County_state": "Brevard,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.964466,
              28.612992
            ],
            [
              -80.98725,
              28.612997
            ],
            [
              -80.957504,
              28.601915
            ],
            [
              -80.952011,
              28.604792
            ],
            [
              -80.947156,
              28.584761
            ],
            [
              -80.93012,
              28.562742
            ],
            [
              -80.934077,
              28.540611
            ],
            [
              -80.898646,
              28.509689
            ],
            [
              -80.88089,
              28.503633
            ],
            [
              -80.882433,
              28.491597
            ],
            [
              -80.87088,
              28.471745
            ],
            [
              -80.894669,
              28.466969
            ],
            [
              -80.896835,
              28.452827
            ],
            [
              -80.90118,
              28.433158
            ],
            [
              -80.88299,
              28.408156
            ],
            [
              -80.888388,
              28.385468
            ],
            [
              -80.862908,
              28.347487
            ],
            [
              -80.861398,
              28.252176
            ],
            [
              -80.862827,
              28.091885
            ],
            [
              -80.869313,
              28.084727
            ],
            [
              -80.868881,
              27.822522
            ],
            [
              -80.509075,
              27.822058
            ],
            [
              -80.491079,
              27.854601
            ],
            [
              -80.4549,
              27.854641
            ],
            [
              -80.4470841049745,
              27.860755037095497
            ],
            [
              -80.446973,
              27.861954
            ],
            [
              -80.508871,
              27.970477
            ],
            [
              -80.54424508622289,
              28.0418724150295
            ],
            [
              -80.547675,
              28.048795
            ],
            [
              -80.566432,
              28.09563
            ],
            [
              -80.589975,
              28.17799
            ],
            [
              -80.604214,
              28.257733
            ],
            [
              -80.60648408853609,
              28.2892272438565
            ],
            [
              -80.608074,
              28.311285
            ],
            [
              -80.606874,
              28.336484
            ],
            [
              -80.603374,
              28.363983
            ],
            [
              -80.6014258502471,
              28.3712071180905
            ],
            [
              -80.596174,
              28.390682
            ],
            [
              -80.587813,
              28.410856
            ],
            [
              -80.574136,
              28.427764
            ],
            [
              -80.562877,
              28.437779
            ],
            [
              -80.526732,
              28.451705
            ],
            [
              -80.525094,
              28.459454
            ],
            [
              -80.536115,
              28.478647
            ],
            [
              -80.560973,
              28.530736
            ],
            [
              -80.567361,
              28.562353
            ],
            [
              -80.574868,
              28.585166
            ],
            [
              -80.583884,
              28.597705
            ],
            [
              -80.61679,
              28.634561
            ],
            [
              -80.647288,
              28.677875
            ],
            [
              -80.709725,
              28.756692
            ],
            [
              -80.73224445687619,
              28.791237410883298
            ],
            [
              -80.842922,
              28.791148
            ],
            [
              -80.967895,
              28.790197
            ],
            [
              -80.964466,
              28.612992
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "047",
      "COUNTYNS": "00295736",
      "AFFGEOID": "0500000US12047",
      "GEOID": "12047",
      "NAME": "Hamilton",
      "LSAD": "06",
      "ALAND": 1330689579,
      "AWATER": 13659798,
      "County_state": "Hamilton,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.2723449732595,
              30.632217954571797
            ],
            [
              -83.256619,
              30.5862
            ],
            [
              -83.262733,
              30.580955
            ],
            [
              -83.255991,
              30.559437
            ],
            [
              -83.260807,
              30.552821
            ],
            [
              -83.246867,
              30.540022
            ],
            [
              -83.239476,
              30.515108
            ],
            [
              -83.243766,
              30.473132
            ],
            [
              -83.225866,
              30.468021
            ],
            [
              -83.217659,
              30.455857
            ],
            [
              -83.221868,
              30.42341
            ],
            [
              -83.207287,
              30.413919
            ],
            [
              -83.195522,
              30.416916
            ],
            [
              -83.180043,
              30.410202
            ],
            [
              -83.170969,
              30.385225
            ],
            [
              -83.158412,
              30.400525
            ],
            [
              -83.15521,
              30.417217
            ],
            [
              -83.134548,
              30.419156
            ],
            [
              -83.122103,
              30.43261
            ],
            [
              -83.084116,
              30.438071
            ],
            [
              -83.075921,
              30.425767
            ],
            [
              -83.034304,
              30.43085
            ],
            [
              -83.007557,
              30.422787
            ],
            [
              -83.002058,
              30.429371
            ],
            [
              -82.982742,
              30.419009
            ],
            [
              -82.966174,
              30.417503
            ],
            [
              -82.908074,
              30.380335
            ],
            [
              -82.905881,
              30.388084
            ],
            [
              -82.8888,
              30.385966
            ],
            [
              -82.893357,
              30.374622
            ],
            [
              -82.882063,
              30.365072
            ],
            [
              -82.85708,
              30.358324
            ],
            [
              -82.844387,
              30.340979
            ],
            [
              -82.832122,
              30.346765
            ],
            [
              -82.810906,
              30.33704
            ],
            [
              -82.799669,
              30.344262
            ],
            [
              -82.794594,
              30.337024
            ],
            [
              -82.788503,
              30.324013
            ],
            [
              -82.762994,
              30.330855
            ],
            [
              -82.746741,
              30.317426
            ],
            [
              -82.738443,
              30.325783
            ],
            [
              -82.724638,
              30.333796
            ],
            [
              -82.717773,
              30.321391
            ],
            [
              -82.703126,
              30.332614
            ],
            [
              -82.689922,
              30.333109
            ],
            [
              -82.681473,
              30.342805
            ],
            [
              -82.686744,
              30.352443
            ],
            [
              -82.662281,
              30.370351
            ],
            [
              -82.657814,
              30.391489
            ],
            [
              -82.647198,
              30.394797
            ],
            [
              -82.652749,
              30.42944
            ],
            [
              -82.670228,
              30.444356
            ],
            [
              -82.66512,
              30.453322
            ],
            [
              -82.680214,
              30.461012
            ],
            [
              -82.68201,
              30.471052
            ],
            [
              -82.697944,
              30.474744
            ],
            [
              -82.707897,
              30.506514
            ],
            [
              -82.715014,
              30.505468
            ],
            [
              -82.727738,
              30.530396
            ],
            [
              -82.714468,
              30.531892
            ],
            [
              -82.72668,
              30.55925
            ],
            [
              -82.706724,
              30.574274
            ],
            [
              -82.706656,
              30.584318
            ],
            [
              -82.6895370493431,
              30.597723132054398
            ],
            [
              -82.698663,
              30.598224
            ],
            [
              -82.698947,
              30.598263
            ],
            [
              -82.877311,
              30.609016
            ],
            [
              -82.878831,
              30.609074
            ],
            [
              -83.131431,
              30.623575
            ],
            [
              -83.1366140447318,
              30.623976460785002
            ],
            [
              -83.156232,
              30.625496
            ],
            [
              -83.163371,
              30.625887
            ],
            [
              -83.174474,
              30.626436
            ],
            [
              -83.187454,
              30.627215
            ],
            [
              -83.2723449732595,
              30.632217954571797
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "021",
      "COUNTYNS": "00295754",
      "AFFGEOID": "0500000US12021",
      "GEOID": "12021",
      "NAME": "Collier",
      "LSAD": "06",
      "ALAND": 5176983078,
      "AWATER": 792880431,
      "County_state": "Collier,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.8458335535129,
              26.3303779735441
            ],
            [
              -81.844555,
              26.327712
            ],
            [
              -81.833142,
              26.294518
            ],
            [
              -81.820675,
              26.236735
            ],
            [
              -81.81681,
              26.207166
            ],
            [
              -81.81461,
              26.173167
            ],
            [
              -81.808833,
              26.152246
            ],
            [
              -81.801663,
              26.088227
            ],
            [
              -81.762439,
              26.00607
            ],
            [
              -81.757463,
              26.000374
            ],
            [
              -81.750668,
              25.998425
            ],
            [
              -81.747834,
              25.994273
            ],
            [
              -81.749724,
              25.960463
            ],
            [
              -81.745579,
              25.949643
            ],
            [
              -81.738118,
              25.942009
            ],
            [
              -81.73195,
              25.931506
            ],
            [
              -81.727086,
              25.907207
            ],
            [
              -81.717687,
              25.902039
            ],
            [
              -81.713172,
              25.897568
            ],
            [
              -81.68954,
              25.85271
            ],
            [
              -81.6848,
              25.847205
            ],
            [
              -81.678287,
              25.845301
            ],
            [
              -81.672633,
              25.856654
            ],
            [
              -81.663821,
              25.885605
            ],
            [
              -81.654493,
              25.893579
            ],
            [
              -81.644553,
              25.897953
            ],
            [
              -81.64008387399029,
              25.897784381748497
            ],
            [
              -81.623482,
              25.897158
            ],
            [
              -81.614735,
              25.893977
            ],
            [
              -81.582483,
              25.876712
            ],
            [
              -81.547894,
              25.849608
            ],
            [
              -81.514982,
              25.838641
            ],
            [
              -81.472239,
              25.81693
            ],
            [
              -81.468489,
              25.80332
            ],
            [
              -81.34417,
              25.803328
            ],
            [
              -80.873096,
              25.805377
            ],
            [
              -80.872932,
              25.979434
            ],
            [
              -80.879142,
              26.172544
            ],
            [
              -80.879149,
              26.172879
            ],
            [
              -80.879809,
              26.259455
            ],
            [
              -81.26855,
              26.253045
            ],
            [
              -81.271768,
              26.517069
            ],
            [
              -81.417885,
              26.516267
            ],
            [
              -81.470419,
              26.513331
            ],
            [
              -81.563763,
              26.513324
            ],
            [
              -81.56218,
              26.422625
            ],
            [
              -81.658356,
              26.421097
            ],
            [
              -81.6579,
              26.317563
            ],
            [
              -81.746167,
              26.316896
            ],
            [
              -81.754404,
              26.316753
            ],
            [
              -81.819019,
              26.316245
            ],
            [
              -81.819119,
              26.330424
            ],
            [
              -81.8458335535129,
              26.3303779735441
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "003",
      "COUNTYNS": "00306920",
      "AFFGEOID": "0500000US12003",
      "GEOID": "12003",
      "NAME": "Baker",
      "LSAD": "06",
      "ALAND": 1515738965,
      "AWATER": 9686120,
      "County_state": "Baker,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.459581,
              30.584264
            ],
            [
              -82.45979242039849,
              30.5842766709258
            ],
            [
              -82.458316,
              30.442757
            ],
            [
              -82.458352,
              30.164595
            ],
            [
              -82.458364,
              30.136449
            ],
            [
              -82.443667,
              30.138086
            ],
            [
              -82.359381,
              30.139294
            ],
            [
              -82.142578,
              30.143117
            ],
            [
              -82.049425,
              30.143136
            ],
            [
              -82.049411,
              30.186933
            ],
            [
              -82.049236,
              30.273432
            ],
            [
              -82.04996655046949,
              30.3623762347418
            ],
            [
              -82.05009,
              30.362323
            ],
            [
              -82.060055,
              30.360313
            ],
            [
              -82.068554,
              30.359169
            ],
            [
              -82.081128,
              30.358791
            ],
            [
              -82.094709,
              30.360766
            ],
            [
              -82.101821,
              30.365321
            ],
            [
              -82.101439,
              30.366541
            ],
            [
              -82.102523,
              30.367809
            ],
            [
              -82.104857,
              30.368305
            ],
            [
              -82.116408,
              30.36732
            ],
            [
              -82.143306,
              30.363378
            ],
            [
              -82.158134,
              30.359898
            ],
            [
              -82.161782,
              30.357836
            ],
            [
              -82.165217,
              30.35802
            ],
            [
              -82.170079,
              30.358914
            ],
            [
              -82.171533,
              30.359854
            ],
            [
              -82.180043,
              30.36861
            ],
            [
              -82.183823,
              30.373698
            ],
            [
              -82.189873,
              30.375924
            ],
            [
              -82.192966,
              30.378765
            ],
            [
              -82.204177,
              30.401316
            ],
            [
              -82.210318,
              30.424577
            ],
            [
              -82.209897,
              30.432806
            ],
            [
              -82.206513,
              30.437069
            ],
            [
              -82.204002,
              30.444495
            ],
            [
              -82.20485,
              30.451828
            ],
            [
              -82.206067,
              30.455495
            ],
            [
              -82.207549,
              30.456916
            ],
            [
              -82.207735,
              30.460492
            ],
            [
              -82.204641,
              30.468857
            ],
            [
              -82.200965,
              30.474427
            ],
            [
              -82.201443,
              30.485154
            ],
            [
              -82.206472,
              30.491867
            ],
            [
              -82.212879,
              30.498741
            ],
            [
              -82.218541,
              30.504177
            ],
            [
              -82.225054,
              30.50782
            ],
            [
              -82.226961,
              30.510271
            ],
            [
              -82.230405,
              30.51733
            ],
            [
              -82.229427,
              30.520814
            ],
            [
              -82.23078,
              30.526749
            ],
            [
              -82.23498,
              30.533057
            ],
            [
              -82.235848,
              30.537178
            ],
            [
              -82.235631,
              30.544877
            ],
            [
              -82.231944,
              30.556262
            ],
            [
              -82.227282,
              30.561033
            ],
            [
              -82.223053,
              30.563202
            ],
            [
              -82.218607,
              30.564395
            ],
            [
              -82.214413,
              30.56695
            ],
            [
              -82.21484616135339,
              30.568508999232296
            ],
            [
              -82.214867,
              30.568584
            ],
            [
              -82.24987,
              30.570856
            ],
            [
              -82.258129,
              30.571552
            ],
            [
              -82.287373,
              30.573451
            ],
            [
              -82.374877,
              30.578996
            ],
            [
              -82.4189159951919,
              30.5817349193742
            ],
            [
              -82.459581,
              30.584264
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "077",
      "COUNTYNS": "00308549",
      "AFFGEOID": "0500000US12077",
      "GEOID": "12077",
      "NAME": "Liberty",
      "LSAD": "06",
      "ALAND": 2164099093,
      "AWATER": 19582444,
      "County_state": "Liberty,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.144928,
              30.099401
            ],
            [
              -85.154015,
              30.092043
            ],
            [
              -85.136849,
              30.086136
            ],
            [
              -85.135254,
              30.04327
            ],
            [
              -85.113691,
              30.023173
            ],
            [
              -85.085238,
              30.01469
            ],
            [
              -85.071919,
              30.004796
            ],
            [
              -85.054002,
              30.00128
            ],
            [
              -85.050625,
              29.990988
            ],
            [
              -85.029525,
              29.986823
            ],
            [
              -85.025439,
              29.971399
            ],
            [
              -85.005234,
              30.011034
            ],
            [
              -85.007626,
              30.013883
            ],
            [
              -84.54445,
              30.01103
            ],
            [
              -84.591048,
              30.029637
            ],
            [
              -84.609777,
              30.059719
            ],
            [
              -84.626084,
              30.062392
            ],
            [
              -84.637886,
              30.083738
            ],
            [
              -84.654549,
              30.09992
            ],
            [
              -84.648436,
              30.10808
            ],
            [
              -84.670364,
              30.128208
            ],
            [
              -84.672538,
              30.150406
            ],
            [
              -84.668251,
              30.182081
            ],
            [
              -84.678255,
              30.18809
            ],
            [
              -84.681871,
              30.21771
            ],
            [
              -84.701027,
              30.231972
            ],
            [
              -84.69825,
              30.244781
            ],
            [
              -84.737846,
              30.265646
            ],
            [
              -84.729898,
              30.2862
            ],
            [
              -84.713351,
              30.30017
            ],
            [
              -84.701161,
              30.319345
            ],
            [
              -84.715144,
              30.333077
            ],
            [
              -84.68489,
              30.350114
            ],
            [
              -84.679396,
              30.368235
            ],
            [
              -84.646408,
              30.388281
            ],
            [
              -84.680632,
              30.387973
            ],
            [
              -84.680525,
              30.417269
            ],
            [
              -84.714124,
              30.417199
            ],
            [
              -84.714097,
              30.460401
            ],
            [
              -84.781111,
              30.459794
            ],
            [
              -84.781813,
              30.518693
            ],
            [
              -84.815618,
              30.518491
            ],
            [
              -84.815816,
              30.533093
            ],
            [
              -84.882616,
              30.533094
            ],
            [
              -84.883316,
              30.605589
            ],
            [
              -84.932519,
              30.606393
            ],
            [
              -84.944919,
              30.595493
            ],
            [
              -84.946818,
              30.579894
            ],
            [
              -84.965418,
              30.580194
            ],
            [
              -84.962218,
              30.563594
            ],
            [
              -84.973718,
              30.552994
            ],
            [
              -84.990017,
              30.524795
            ],
            [
              -84.991013,
              30.504295
            ],
            [
              -84.999916,
              30.472418
            ],
            [
              -84.987441,
              30.464901
            ],
            [
              -84.999916,
              30.456199
            ],
            [
              -84.983203,
              30.441837
            ],
            [
              -85.015176,
              30.434361
            ],
            [
              -85.032931,
              30.420299
            ],
            [
              -85.014269,
              30.409018
            ],
            [
              -85.027303,
              30.405603
            ],
            [
              -85.014572,
              30.394269
            ],
            [
              -85.026242,
              30.375589
            ],
            [
              -85.040199,
              30.372089
            ],
            [
              -85.027898,
              30.360843
            ],
            [
              -85.04277,
              30.352466
            ],
            [
              -85.049744,
              30.336544
            ],
            [
              -85.046271,
              30.32017
            ],
            [
              -85.03429,
              30.312478
            ],
            [
              -85.047564,
              30.312791
            ],
            [
              -85.057253,
              30.301301
            ],
            [
              -85.052777,
              30.287406
            ],
            [
              -85.063951,
              30.270863
            ],
            [
              -85.059092,
              30.263229
            ],
            [
              -85.076768,
              30.250989
            ],
            [
              -85.081336,
              30.228531
            ],
            [
              -85.092076,
              30.227568
            ],
            [
              -85.102962,
              30.211726
            ],
            [
              -85.11823,
              30.210491
            ],
            [
              -85.11566,
              30.199976
            ],
            [
              -85.133629,
              30.181779
            ],
            [
              -85.139855,
              30.163499
            ],
            [
              -85.133203,
              30.145749
            ],
            [
              -85.144904,
              30.123149
            ],
            [
              -85.130182,
              30.119414
            ],
            [
              -85.149077,
              30.110495
            ],
            [
              -85.144928,
              30.099401
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "059",
      "COUNTYNS": "00295735",
      "AFFGEOID": "0500000US12059",
      "GEOID": "12059",
      "NAME": "Holmes",
      "LSAD": "06",
      "ALAND": 1239988859,
      "AWATER": 26637585,
      "County_state": "Holmes,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.0350402418067,
              30.993306001451096
            ],
            [
              -86.037591,
              30.704572
            ],
            [
              -85.844421,
              30.70313
            ],
            [
              -85.822585,
              30.738787
            ],
            [
              -85.832743,
              30.750477
            ],
            [
              -85.821616,
              30.790775
            ],
            [
              -85.790185,
              30.790351
            ],
            [
              -85.751927,
              30.789837
            ],
            [
              -85.751979,
              30.775706
            ],
            [
              -85.735115,
              30.775292
            ],
            [
              -85.736051,
              30.74637
            ],
            [
              -85.619011,
              30.743868
            ],
            [
              -85.621458,
              30.770882
            ],
            [
              -85.603221,
              30.796528
            ],
            [
              -85.599934,
              30.830552
            ],
            [
              -85.594882,
              30.859478
            ],
            [
              -85.575574,
              30.898601
            ],
            [
              -85.547745,
              30.932185
            ],
            [
              -85.530294,
              30.939315
            ],
            [
              -85.52921,
              30.974439
            ],
            [
              -85.4979938168177,
              30.996928004442598
            ],
            [
              -85.49838,
              30.996922
            ],
            [
              -85.57949030335179,
              30.9963927426443
            ],
            [
              -85.749715,
              30.995282
            ],
            [
              -85.750028,
              30.994827
            ],
            [
              -85.893632,
              30.993455
            ],
            [
              -85.998727,
              30.992857
            ],
            [
              -86.0350402418067,
              30.993306001451096
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "107",
      "COUNTYNS": "00306910",
      "AFFGEOID": "0500000US12107",
      "GEOID": "12107",
      "NAME": "Putnam",
      "LSAD": "06",
      "ALAND": 1885167090,
      "AWATER": 256548320,
      "County_state": "Putnam,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.055027,
              29.669608
            ],
            [
              -82.055899,
              29.471232
            ],
            [
              -82.032026,
              29.491368
            ],
            [
              -82.004385,
              29.501715
            ],
            [
              -81.964158,
              29.503814
            ],
            [
              -81.931821,
              29.516445
            ],
            [
              -81.909954,
              29.504854
            ],
            [
              -81.906692,
              29.514235
            ],
            [
              -81.86115,
              29.514892
            ],
            [
              -81.843009,
              29.521004
            ],
            [
              -81.842779,
              29.486533
            ],
            [
              -81.776205,
              29.487448
            ],
            [
              -81.774905,
              29.429249
            ],
            [
              -81.74183,
              29.42994
            ],
            [
              -81.741422,
              29.371049
            ],
            [
              -81.668525,
              29.371704
            ],
            [
              -81.656413,
              29.337825
            ],
            [
              -81.675403,
              29.338646
            ],
            [
              -81.680903,
              29.32443
            ],
            [
              -81.445886,
              29.380142
            ],
            [
              -81.433992,
              29.398552
            ],
            [
              -81.451692,
              29.390152
            ],
            [
              -81.476893,
              29.396552
            ],
            [
              -81.485994,
              29.424151
            ],
            [
              -81.486694,
              29.445551
            ],
            [
              -81.514736,
              29.486024
            ],
            [
              -81.520596,
              29.500249
            ],
            [
              -81.52366,
              29.622432
            ],
            [
              -81.524804,
              29.721586
            ],
            [
              -81.52523,
              29.759497
            ],
            [
              -81.541235,
              29.770001
            ],
            [
              -81.566524,
              29.802902
            ],
            [
              -81.581207,
              29.840176
            ],
            [
              -81.81243,
              29.83649
            ],
            [
              -81.821872,
              29.82634
            ],
            [
              -81.861994,
              29.800187
            ],
            [
              -81.90926,
              29.79356
            ],
            [
              -81.923497,
              29.780919
            ],
            [
              -81.93111,
              29.75613
            ],
            [
              -81.939427,
              29.747497
            ],
            [
              -81.976228,
              29.747114
            ],
            [
              -82.00707,
              29.73949
            ],
            [
              -82.028917,
              29.718681
            ],
            [
              -82.049244,
              29.71867
            ],
            [
              -82.055625,
              29.718232
            ],
            [
              -82.049052,
              29.713368
            ],
            [
              -82.055027,
              29.669608
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "083",
      "COUNTYNS": "00306922",
      "AFFGEOID": "0500000US12083",
      "GEOID": "12083",
      "NAME": "Marion",
      "LSAD": "06",
      "ALAND": 4114226894,
      "AWATER": 192048864,
      "County_state": "Marion,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.53486,
              29.21468
            ],
            [
              -82.535591,
              29.044855
            ],
            [
              -82.508131,
              29.037479
            ],
            [
              -82.487118,
              29.048375
            ],
            [
              -82.458566,
              29.04765
            ],
            [
              -82.426997,
              29.021385
            ],
            [
              -82.418821,
              29.013246
            ],
            [
              -82.401415,
              29.018606
            ],
            [
              -82.393503,
              29.008006
            ],
            [
              -82.362763,
              28.998929
            ],
            [
              -82.36318,
              28.996465
            ],
            [
              -82.362074,
              28.995671
            ],
            [
              -82.362119,
              28.994327
            ],
            [
              -82.361575,
              28.993649
            ],
            [
              -82.360954,
              28.99338
            ],
            [
              -82.358711,
              28.993341
            ],
            [
              -82.357609,
              28.993144
            ],
            [
              -82.356336,
              28.991703
            ],
            [
              -82.35527,
              28.991345
            ],
            [
              -82.352394,
              28.991242
            ],
            [
              -82.350285,
              28.989492
            ],
            [
              -82.349293,
              28.988393
            ],
            [
              -82.348339,
              28.98789
            ],
            [
              -82.346702,
              28.987755
            ],
            [
              -82.345585,
              28.98706
            ],
            [
              -82.342901,
              28.987244
            ],
            [
              -82.341887,
              28.987845
            ],
            [
              -82.341364,
              28.987776
            ],
            [
              -82.311697,
              28.960391
            ],
            [
              -82.095165,
              28.960117
            ],
            [
              -81.95419,
              28.960053
            ],
            [
              -81.848774,
              28.959828
            ],
            [
              -81.842194,
              28.961024
            ],
            [
              -81.761654,
              28.960932
            ],
            [
              -81.719094,
              28.961104
            ],
            [
              -81.696067,
              28.960068
            ],
            [
              -81.658698,
              28.960345
            ],
            [
              -81.659197,
              29.04776
            ],
            [
              -81.638696,
              29.04776
            ],
            [
              -81.640925,
              29.168809
            ],
            [
              -81.641916,
              29.276766
            ],
            [
              -81.655677,
              29.29994
            ],
            [
              -81.673264,
              29.306644
            ],
            [
              -81.680903,
              29.32443
            ],
            [
              -81.675403,
              29.338646
            ],
            [
              -81.656413,
              29.337825
            ],
            [
              -81.668525,
              29.371704
            ],
            [
              -81.741422,
              29.371049
            ],
            [
              -81.74183,
              29.42994
            ],
            [
              -81.774905,
              29.429249
            ],
            [
              -81.776205,
              29.487448
            ],
            [
              -81.842779,
              29.486533
            ],
            [
              -81.843009,
              29.521004
            ],
            [
              -81.86115,
              29.514892
            ],
            [
              -81.906692,
              29.514235
            ],
            [
              -81.909954,
              29.504854
            ],
            [
              -81.931821,
              29.516445
            ],
            [
              -81.964158,
              29.503814
            ],
            [
              -82.004385,
              29.501715
            ],
            [
              -82.032026,
              29.491368
            ],
            [
              -82.055899,
              29.471232
            ],
            [
              -82.056747,
              29.439951
            ],
            [
              -82.071291,
              29.443271
            ],
            [
              -82.094537,
              29.430352
            ],
            [
              -82.099941,
              29.418898
            ],
            [
              -82.101143,
              29.438313
            ],
            [
              -82.118465,
              29.428393
            ],
            [
              -82.135466,
              29.434667
            ],
            [
              -82.14502,
              29.418077
            ],
            [
              -82.151217,
              29.426795
            ],
            [
              -82.193626,
              29.420935
            ],
            [
              -82.211837,
              29.430052
            ],
            [
              -82.211661,
              29.466056
            ],
            [
              -82.192045,
              29.45234
            ],
            [
              -82.18938,
              29.460949
            ],
            [
              -82.211292,
              29.483995
            ],
            [
              -82.40662,
              29.485048
            ],
            [
              -82.405478,
              29.361097
            ],
            [
              -82.403237,
              29.215623
            ],
            [
              -82.44005,
              29.21523
            ],
            [
              -82.53486,
              29.21468
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "12",
      "COUNTYFP": "057",
      "COUNTYNS": "00295757",
      "AFFGEOID": "0500000US12057",
      "GEOID": "12057",
      "NAME": "Hillsborough",
      "LSAD": "06",
      "ALAND": 2642602901,
      "AWATER": 635590801,
      "County_state": "Hillsborough,12"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.651165,
              28.173266
            ],
            [
              -82.650602,
              28.144928
            ],
            [
              -82.648557,
              28.03863
            ],
            [
              -82.6485177745241,
              28.0203156831794
            ],
            [
              -82.641109,
              28.016773
            ],
            [
              -82.62959,
              27.998474
            ],
            [
              -82.576003,
              27.969424
            ],
            [
              -82.553918,
              27.966998
            ],
            [
              -82.541218,
              27.948998
            ],
            [
              -82.533718,
              27.932999
            ],
            [
              -82.531318,
              27.9039
            ],
            [
              -82.535818,
              27.898
            ],
            [
              -82.541747,
              27.893706
            ],
            [
              -82.542818,
              27.890601
            ],
            [
              -82.539318,
              27.885001
            ],
            [
              -82.529918,
              27.877501
            ],
            [
              -82.533218,
              27.870701
            ],
            [
              -82.538618,
              27.864901
            ],
            [
              -82.552918,
              27.862702
            ],
            [
              -82.553946,
              27.848462
            ],
            [
              -82.511193,
              27.828015
            ],
            [
              -82.489849,
              27.822607
            ],
            [
              -82.475273,
              27.820991
            ],
            [
              -82.47244,
              27.822559
            ],
            [
              -82.46884,
              27.843295
            ],
            [
              -82.471624,
              27.847342
            ],
            [
              -82.480137,
              27.853246
            ],
            [
              -82.488057,
              27.863566
            ],
            [
              -82.487417,
              27.895001
            ],
            [
              -82.491117,
              27.9145
            ],
            [
              -82.489817,
              27.9196
            ],
            [
              -82.478063,
              27.92768
            ],
            [
              -82.462078,
              27.920066
            ],
            [
              -82.459616,
              27.9169
            ],
            [
              -82.460016,
              27.9116
            ],
            [
              -82.451591,
              27.907506
            ],
            [
              -82.43752033972189,
              27.9029763788832
            ],
            [
              -82.432316,
              27.901301
            ],
            [
              -82.413915,
              27.901401
            ],
            [
              -82.402615,
              27.882602
            ],
            [
              -82.397463,
              27.851631
            ],
            [
              -82.393383,
              27.837519
            ],
            [
              -82.393492057914,
              27.8372329675904
            ],
            [
              -82.402857,
              27.812671
            ],
            [
              -82.410837,
              27.810868
            ],
            [
              -82.418401,
              27.803187
            ],
            [
              -82.419066,
              27.793767
            ],
            [
              -82.433981,
              27.774087
            ],
            [
              -82.43198,
              27.768092
            ],
            [
              -82.434635,
              27.764355
            ],
            [
              -82.457543,
              27.752571
            ],
            [
              -82.478339,
              27.74625
            ],
            [
              -82.482305,
              27.742649
            ],
            [
              -82.477129,
              27.735216
            ],
            [
              -82.476297,
              27.729929
            ],
            [
              -82.477638,
              27.723004
            ],
            [
              -82.482449,
              27.719886
            ],
            [
              -82.4923563029643,
              27.7191510345092
            ],
            [
              -82.494891,
              27.718963
            ],
            [
              -82.514265,
              27.705588
            ],
            [
              -82.5225403077326,
              27.693777756828396
            ],
            [
              -82.537146,
              27.672933
            ],
            [
              -82.55440720935509,
              27.645292633205898
            ],
            [
              -82.549145,
              27.645554
            ],
            [
              -82.524947,
              27.645422
            ],
            [
              -82.52475,
              27.645422
            ],
            [
              -82.453735,
              27.64591
            ],
            [
              -82.054349,
              27.646382
            ],
            [
              -82.054874,
              27.907726
            ],
            [
              -82.055256,
              27.927001
            ],
            [
              -82.05526,
              27.92718
            ],
            [
              -82.055389,
              27.952862
            ],
            [
              -82.056159,
              28.047552
            ],
            [
              -82.056502,
              28.113252
            ],
            [
              -82.056261,
              28.171592
            ],
            [
              -82.105853,
              28.17165
            ],
            [
              -82.124806,
              28.172149
            ],
            [
              -82.259741,
              28.171462
            ],
            [
              -82.394083,
              28.171127
            ],
            [
              -82.410512,
              28.171213
            ],
            [
              -82.461461,
              28.171823
            ],
            [
              -82.474056,
              28.171935
            ],
            [
              -82.651165,
              28.173266
            ]
          ]
        ]
      ]
    }
  }
]
};